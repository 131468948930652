import { createStore, combineReducers } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const reducer = combineReducers({
    todos: (state = {}, action) => {
        switch (action.type) {
            case "addToken":
                return { ...state, token: action.item };
            case "addAuth":
                return { ...state, auth: action.item };
            case "addAuthEx":
                return { ...state, authEx: action.item };
            case "addAuthReq":
                return { ...state, authReq: action.item };
            case "addAuthStatus":
                return { ...state, authStatus: action.item };
            case "addAuthInvoice":
                return { ...state, authInvoice: action.item };
            case "addDateFormat":
                return { ...state, dateFormat: action.item };
            case "addAmountFormat":
                return { ...state, amountFormat: action.item };
            case "addTutorial":
                return { ...state, isTutorial: action.item };
            case "addUserInfo":
                return { ...state, userInfo: action.item };
            case "addConfig":
                return { ...state, configName: action.item };
            case "addSelected":
                return { ...state, selected: action.item };
            case "addRedirect":
                return { ...state, redirect: action.item };
            case "addRemind":
                return { ...state, remind: action.item };
            case "clearInfo":
                return {};
            default:
                return state;
        }
    },
});

const persistConfig = {
    // configuration object for redux-persist
    key: "root",
    storage, // define which storage to use
};

const persistedReducer = persistReducer(persistConfig, reducer);

let store = createStore(persistedReducer);

let persistor = persistStore(store);

export { store, persistor };
