/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

/** MUI imports */
import { styled } from "@mui/material/styles";
import { Box, Chip, Grid, Typography } from "@mui/material";

/** Components */
import { Onboarding, CleanUp, CatchUp, Recurring, Consulting } from "./";

/** Component styles */
const PREFIX = "PropBookkeeperDetails";

const classes = {
    cardContentRoot: `${PREFIX}-cardContentRoot`,
    chip: `${PREFIX}-chip`,
    selectedChip: `${PREFIX}-selectedChip`,
    projectBox: `${PREFIX}-projectBox`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.cardContentRoot}`]: {
        padding: "24px 48px 48px",
    },

    [`& .${classes.chip}`]: {
        padding: 24,
        width: 200,
        backgroundColor: "#EAEAF4",
        color: "#031851",
        fontWeight: "700 !important",
        "&:hover": {
            backgroundColor: "#EAEAF4",
            color: "#031851",
        },
        "& .MuiChip-label": {
            padding: 0,
        },
    },

    [`& .${classes.selectedChip}`]: {
        padding: 24,
        width: 200,
        backgroundColor: "#2f3190",
        color: "#fff",
        fontWeight: "700 !important",
        "&:hover": {
            backgroundColor: "#2f3190",
            color: "#fff",
        },
        "& .MuiChip-label": {
            padding: 0,
        },
    },

    [`& .${classes.projectBox}`]: {
        backgroundColor: "#F8F9F8",
        marginTop: 16,
        padding: 30,
        borderRadius: 30,
    },
}));

export const PropBookkeeperDetails = (props) => {
    // Properties
    const {
        isError,
        isErrorFocus,
        setIsErrorFocus,
        monthlyAverage,
        services,
        setAutoAlertType,
        setServices,
        setShow,
        setTexto,
        setValuesBookCatchUp,
        setValuesBookCleanUp,
        setValuesBookConsul,
        setValuesBookOnboard,
        setValuesBookRecu,
        tariff,
        tariffAdditionals,
        valuesBookCatchUp,
        valuesBookCleanUp,
        valuesBookConsul,
        valuesBookOnboard,
        valuesBookRecu,
        showtutorial,
        showFinalTutorial,
    } = props;

    /** Internationalization i18n */
    const [t] = useTranslation("global");
    

    /** Component functions */
    useEffect(() => {
        if (
            !valuesBookRecu.chip &&
            !valuesBookOnboard.chip &&
            !valuesBookCatchUp.chip &&
            !valuesBookCleanUp.chip &&
            !valuesBookConsul.chip
        ) {
            const updateServices = services.map((service) => {
                if (service.name === "bookkeeper") {
                    return {
                        ...service,
                        isActive: false,
                    };
                } else {
                    return {
                        ...service,
                    };
                }
            });
            setServices(updateServices);
        } else {
            const updateServices = services.map((service) => {
                if (service.name === "bookkeeper") {
                    return {
                        ...service,
                        isActive: true,
                    };
                } else {
                    return {
                        ...service,
                    };
                }
            });
            setServices(updateServices);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        valuesBookRecu.chip,
        valuesBookOnboard.chip,
        valuesBookCatchUp.chip,
        valuesBookCleanUp.chip,
        valuesBookConsul.chip,
    ]);

    useEffect(() => {
        setValuesBookOnboard({
            ...valuesBookOnboard,
            onboardOriginalFee: Math.ceil(monthlyAverage / 10000) * tariff,
            onboardProjectTotal: !valuesBookOnboard.chip
                ? 0
                : Math.ceil(monthlyAverage / 10000) * tariff * (1 - valuesBookOnboard.onboardDiscount / 100),
        });
        setValuesBookCleanUp({
            ...valuesBookCleanUp,
            cleanUpOriginalFee: Math.ceil(monthlyAverage / 10000) * tariff,
            projectTotal: !valuesBookCleanUp.chip
                ? 0
                : valuesBookCleanUp.additionalsOnly
                ? valuesBookCleanUp.projectTotal
                : Math.ceil(monthlyAverage / 10000) *
                  tariff *
                  valuesBookCleanUp.months *
                  (1 - valuesBookCleanUp.discount / 100),
        });
        setValuesBookCatchUp({
            ...valuesBookCatchUp,
            catchUpOriginalFee: Math.ceil(monthlyAverage / 10000) * tariff,
            projectTotal: !valuesBookCatchUp.chip
                ? 0
                : valuesBookCatchUp.additionalsOnly
                ? valuesBookCatchUp.projectTotal
                : Math.ceil(monthlyAverage / 10000) *
                  tariff *
                  valuesBookCatchUp.months *
                  (1 - valuesBookCatchUp.discount / 100),
        });
        setValuesBookRecu({
            ...valuesBookRecu,
            recuOriginalFee: Math.ceil(monthlyAverage / 10000) * tariff,
            recuProjectTotal: !valuesBookRecu.chip
                ? 0
                : valuesBookRecu.recuAdditionalsOnly
                ? valuesBookRecu.recuProjectTotal
                : Math.ceil(monthlyAverage / 10000) * tariff * (1 - valuesBookRecu.recuDiscount / 100),
        });

        let aux = [...valuesBookConsul.consultingArray];

        aux.forEach((element) => {
            element.total = element.hours * 25 * (1 - element.discount / 100);
        });

        let sumHours = aux.map((obj) => obj.hours).reduce((a, b) => parseInt(a) + parseInt(b));
        let sumTotal = aux.map((obj) => obj.total).reduce((a, b) => parseFloat(a) + parseFloat(b));

        setValuesBookConsul({
            ...valuesBookConsul,
            consulOriginalFee: 25,
            consulProjectTotal: sumTotal,
            consulHours: sumHours,
            consultingArray: aux,
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [monthlyAverage, tariff]);

    const handleClickChip = (value, option) => {
        if (option === "onboarding") {
            setValuesBookOnboard({
                ...valuesBookOnboard,
                chip: !value,
                onboardOriginalFee: valuesBookOnboard.onboardOriginalFee,
                onboardDiscount: 0,
                onboardProjectTotal: !value ? valuesBookOnboard.onboardOriginalFee : 0,
                meetingDate: null,
                description: "",
            });
        } else if (option === "recurring") {
            setValuesBookRecu({
                ...valuesBookRecu,
                chip: !value,
                recuOriginalFee: valuesBookRecu.recuOriginalFee,
                recuDiscount: 0,
                recuProjectTotal: !value ? valuesBookRecu.recuOriginalFee : 0,
                recuAdditionalsDiscount: 0,
                recuAdditionalsOnly: false,
                initDate: null,
                recuChecks: false,
                recuHours: 0,
                recuHourRate: !value ? tariffAdditionals : 0,
                // Additionals
                checkedBook: false,
                tothBook: 0,
                startDateBook: null,
                finishDateBook: null,
                checkedChecks: false,
                tothChecks: 0,
                startDateChecks: null,
                finishDateChecks: null,
                checkedAR: false,
                tothAR: 0,
                startDateAR: null,
                finishDateAR: null,
                checkedAP: false,
                tothAP: 0,
                startDateAP: null,
                finishDateAP: null,
                checkedAccrual: false,
                tothAccrual: 0,
                startDateAccrual: null,
                finishDateAccrual: null,
                checkedClass: false,
                tothClass: 0,
                startDateClass: null,
                finishDateClass: null,
                checkedMulti: false,
                tothMulti: 0,
                startDateMulti: null,
                finishDateMulti: null,
                checkedPer: false,
                tothPer: 0,
                startDatePer: null,
                finishDatePer: null,
                // Otros
                checkedOthers: false,
                nameOthers: "",
                tothOthers: 0,
                startDateOthers: null,
                finishDateOthers: null,
            });
        } else if (option === "catchUp") {
            setValuesBookCatchUp({
                ...valuesBookCatchUp,
                chip: !value,
                catchUpOriginalFee: valuesBookCatchUp.catchUpOriginalFee,
                sinceDate: null,
                untilDate: null,
                discount: 30,
                catchUpAdditionalsDiscount: 30,
                additionalsOnly: false,
                projectTotal: !value
                    ? valuesBookCatchUp.catchUpOriginalFee * (1 - valuesBookCatchUp.discount / 100)
                    : 0,
                hourRate: !value ? tariffAdditionals : 0,
                catchUpChecks: false,
                totalHours: 0,
                months: 1,
                // Additionals
                checkedBook: false,
                tothBook: 0,
                startDateBook: null,
                finishDateBook: null,
                checkedChecks: false,
                tothChecks: 0,
                startDateChecks: null,
                finishDateChecks: null,
                checkedAR: false,
                tothAR: 0,
                startDateAR: null,
                finishDateAR: null,
                checkedAP: false,
                tothAP: 0,
                startDateAP: null,
                finishDateAP: null,
                checkedAccrual: false,
                tothAccrual: 0,
                startDateAccrual: null,
                finishDateAccrual: null,
                checkedClass: false,
                tothClass: 0,
                startDateClass: null,
                finishDateClass: null,
                checkedMulti: false,
                tothMulti: 0,
                startDateMulti: null,
                finishDateMulti: null,
                checkedPer: false,
                tothPer: 0,
                startDatePer: null,
                finishDatePer: null,
                // Otros
                checkedOthers: false,
                nameOthers: "",
                tothOthers: 0,
                startDateOthers: null,
                finishDateOthers: null,
            });
        } else if (option === "cleanUp") {
            setValuesBookCleanUp({
                ...valuesBookCleanUp,
                chip: !value,
                cleanUpOriginalFee: valuesBookCleanUp.cleanUpOriginalFee,
                sinceDate: null,
                untilDate: null,
                discount: 20,
                cleanUpAdditionalsDiscount: 20,
                additionalsOnly: false,
                projectTotal: !value
                    ? valuesBookCleanUp.cleanUpOriginalFee * (1 - valuesBookCleanUp.discount / 100)
                    : 0,
                hourRate: !value ? tariffAdditionals : 0,
                cleanUpChecks: false,
                totalHours: 0,
                months: 1,
                // Additionals
                checkedBook: false,
                tothBook: 0,
                startDateBook: null,
                finishDateBook: null,
                checkedChecks: false,
                tothChecks: 0,
                startDateChecks: null,
                finishDateChecks: null,
                checkedAR: false,
                tothAR: 0,
                startDateAR: null,
                finishDateAR: null,
                checkedAP: false,
                tothAP: 0,
                startDateAP: null,
                finishDateAP: null,
                checkedAccrual: false,
                tothAccrual: 0,
                startDateAccrual: null,
                finishDateAccrual: null,
                checkedClass: false,
                tothClass: 0,
                startDateClass: null,
                finishDateClass: null,
                checkedMulti: false,
                tothMulti: 0,
                startDateMulti: null,
                finishDateMulti: null,
                checkedPer: false,
                tothPer: 0,
                startDatePer: null,
                finishDatePer: null,
                // Otros
                checkedOthers: false,
                nameOthers: "",
                tothOthers: 0,
                startDateOthers: null,
                finishDateOthers: null,
            });
        } else {
            setValuesBookConsul({
                ...valuesBookConsul,
                chip: !value,
                consulOriginalFee: valuesBookConsul.consulOriginalFee,
                consulProjectTotal: 0,
                consulHours: 0,
                consultingArray: [
                    {
                        hours: 0,
                        discount: 0,
                        description: "",
                        total: 0,
                    },
                ],
            });
        }
        if (showtutorial && !value) {
            setTimeout(() => showFinalTutorial("projectsBook"), 500);
        }
    };

    return (
        <StyledContainer>
            <Grid id="bookkeeper" container justifyContent="space-around" alignItems="center" spacing={2}>
                <Grid item>
                    <Chip
                        id="onboarding-chip"
                        label={
                            <Typography variant="h2" align="center" sx={{ fontWeight: 700 }}>
                                {t(`invoicing.smbOnboarding`)}
                            </Typography>
                        }
                        className={valuesBookOnboard.chip ? classes.selectedChip : classes.chip}
                        onClick={() => handleClickChip(valuesBookOnboard.chip, "onboarding")}
                    />
                </Grid>
                <Grid item>
                    <Chip
                        id="clean-up-chip"
                        label={
                            <Typography variant="h2" align="center" sx={{ fontWeight: 700 }}>
                                {t("invoicing.cleanUp")}
                            </Typography>
                        }
                        className={valuesBookCleanUp.chip ? classes.selectedChip : classes.chip}
                        onClick={() => handleClickChip(valuesBookCleanUp.chip, "cleanUp")}
                    />
                </Grid>
                <Grid item>
                    <Chip
                        id="catch-up-chip"
                        label={
                            <Typography variant="h2" align="center" sx={{ fontWeight: 700 }}>
                                {t("invoicing.catchUp")}
                            </Typography>
                        }
                        className={valuesBookCatchUp.chip ? classes.selectedChip : classes.chip}
                        onClick={() => handleClickChip(valuesBookCatchUp.chip, "catchUp")}
                    />
                </Grid>
                <Grid item>
                    <Chip
                        id="recurring-chip"
                        label={
                            <Typography variant="h2" align="center" sx={{ fontWeight: 700 }}>
                                {t("invoicing.recurring")}
                            </Typography>
                        }
                        className={valuesBookRecu.chip ? classes.selectedChip : classes.chip}
                        onClick={() => handleClickChip(valuesBookRecu.chip, "recurring")}
                    />
                </Grid>
                <Grid item>
                    <Chip
                        id="consulting-chip"
                        label={
                            <Typography variant="h2" align="center" sx={{ fontWeight: 700 }}>
                                {t("invoicing.consulting")}
                            </Typography>
                        }
                        className={valuesBookConsul.chip ? classes.selectedChip : classes.chip}
                        onClick={() => handleClickChip(valuesBookConsul.chip, "consulting")}
                    />
                </Grid>
            </Grid>
            <Box id="projectsBook">
                {valuesBookOnboard.chip && (
                    <Onboarding
                        valuesBookOnboard={valuesBookOnboard}
                        setValuesBookOnboard={setValuesBookOnboard}
                        isError={isError}
                        isErrorFocus={isErrorFocus}
                        setIsErrorFocus={setIsErrorFocus}
                    />
                )}
                {valuesBookCleanUp.chip && (
                    <CleanUp
                        values={valuesBookCleanUp}
                        setValues={setValuesBookCleanUp}
                        setShow={setShow}
                        setTexto={setTexto}
                        setAutoAlertType={setAutoAlertType}
                        tariffAdditionals={tariffAdditionals}
                        isError={isError}
                        isErrorFocus={isErrorFocus}
                        setIsErrorFocus={setIsErrorFocus}
                    />
                )}
                {valuesBookCatchUp.chip && (
                    <CatchUp
                        values={valuesBookCatchUp}
                        setValues={setValuesBookCatchUp}
                        setShow={setShow}
                        setTexto={setTexto}
                        setAutoAlertType={setAutoAlertType}
                        tariffAdditionals={tariffAdditionals}
                        isError={isError}
                        isErrorFocus={isErrorFocus}
                        setIsErrorFocus={setIsErrorFocus}
                    />
                )}
                {valuesBookRecu.chip && (
                    <Recurring
                        valuesBookRecu={valuesBookRecu}
                        setValuesBookRecu={setValuesBookRecu}
                        setShow={setShow}
                        setTexto={setTexto}
                        setAutoAlertType={setAutoAlertType}
                        tariffAdditionals={tariffAdditionals}
                        isError={isError}
                        isErrorFocus={isErrorFocus}
                        setIsErrorFocus={setIsErrorFocus}
                    />
                )}
                {valuesBookConsul.chip && (
                    <Consulting
                        valuesBookConsul={valuesBookConsul}
                        setValuesBookConsul={setValuesBookConsul}
                        setShow={setShow}
                        setTexto={setTexto}
                        setAutoAlertType={setAutoAlertType}
                        isError={isError}
                        isErrorFocus={isErrorFocus}
                        setIsErrorFocus={setIsErrorFocus}
                    />
                )}
            </Box>
        </StyledContainer>
    );
};
