/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

/** MUI icons */
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";
import InfoIcon from "@mui/icons-material/Info";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import WarningAmberIcon from "@mui/icons-material/Warning";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

/** MUI imports */
import {
    Grid,
    Table,
    TableHeaderRow,
    PagingPanel,
    Toolbar,
    SearchPanel,
    TableColumnVisibility,
    TableBandHeader,
} from "@devexpress/dx-react-grid-material-ui";
import { SearchState, IntegratedFiltering, PagingState, IntegratedPaging } from "@devexpress/dx-react-grid";
import { styled } from "@mui/material/styles";
import GridUI from "@mui/material/Grid";
import {
    Box,
    Breadcrumbs,
    Card,
    CardContent,
    FormControl,
    FormControlLabel,
    InputLabel,
    ListItem,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    Stack,
    Switch,
    Typography,
    Skeleton,
    Chip,
    Checkbox,
    Button,
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

/** Components imports */
import BankValidations from "../BankValidations";
import moment from "moment";

const PREFIX = "Banking";

const classes = {
    tableStriped: `${PREFIX}-tableStriped`,
    tableHeader: `${PREFIX}-tableHeader`,
    tableBody: `${PREFIX}-tableBody`,
    pager: `${PREFIX}-pager`,
    cardContentRoot: `${PREFIX}-cardContentRoot`,
    formRounded: `${PREFIX}-formRounded`,
    button: `${PREFIX}-button`,
    spacing: `${PREFIX}-spacing`,
    toolbarSearch: `${PREFIX}-toolbarSearch`,
    searchInput: `${PREFIX}-searchInput`,
    filterSwitches: `${PREFIX}-filterSwitches`,
    tableHeaderBand: `${PREFIX}-tableHeaderBand`,
    tableHeaderBand2: `${PREFIX}-tableHeaderBand2`,
    accountCel: `${PREFIX}-accountCel`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.tableHeaderBand}`]: {
        backgroundColor: "transparent",
        color: "#4a22d4",
        fontSize: "14px",
        fontWeight: 600,
        border: "none !important",
        textAlign: "center",
        [theme.breakpoints.up("md")]: {
            whiteSpace: "pre-wrap",
        },
    },

    [`& .${classes.tableHeaderBand2}`]: {
        backgroundColor: "#2f3190",
        color: "white",
        fontSize: "14px",
        fontWeight: 600,
        border: "none !important",
        borderRadius: 40,
        textAlign: "center",
        [theme.breakpoints.up("md")]: {
            whiteSpace: "pre-wrap",
        },
    },

    [`& .${classes.cardContentRoot}`]: {
        "&:last-child": {
            paddingBottom: "18px",
        },
        padding: 30,
    },

    [`& .${classes.formRounded}`]: {
        backgroundColor: "#FFFFFF !important",
        borderRadius: 40,
        verticalAlign: "middle !important",
        "&:focus-within": {
            backgroundColor: "#FFFFFF",
            borderRadius: 40,
        },
        "& .MuiOutlinedInput-notchedOutline": {
            border: "2px solid #031851",
            borderRadius: 40,
        },
    },

    [`& .${classes.button}`]: {
        [theme.breakpoints.up("xl")]: {
            paddingLeft: 30,
            paddingRight: 30,
        },
        borderRadius: 40,
        "&:hover": {
            backgroundColor: theme.palette.text.secondary,
            color: "#fff",
        },
    },

    [`& .${classes.spacing}`]: {
        padding: "20px 0 15px 0",
    },

    [`& .${classes.tableStriped}`]: {
        "& tbody tr:nth-of-type(odd)": {
            backgroundColor: "rgba(47,49,144,0.1)",
        },
    },
    [`& .${classes.tableHeader}`]: {
        border: "none",
        backgroundColor: "rgba(47,49,144,0.3)",
        fontSize: "15px",
        fontWeight: 600,
        color: "#031851",
        "&:first-of-type": {
            borderRadius: "20px 0 0 0",
        },
        "&:last-child": {
            borderRadius: "0 20px 0 0",
            paddingRight: "24px",
        },
    },

    [`& .${classes.tableBody}`]: {
        border: "none",
        fontSize: "14px",
        fontWeight: 400,
        color: "#031851",
    },

    [`& .${classes.pager}`]: {
        "& .Pagination-button": {
            paddingTop: 0,
            paddingBottom: 0,
            color: "#031851",
            fontWeight: 600,
        },
        "& .Pagination-activeButton": {
            backgroundColor: "rgba(47,49,144,0.3)",
        },
    },

    [`& .${classes.toolbarSearch}`]: {
        border: "none !important",
        padding: "0 0 5px !important",
    },

    [`& .${classes.searchInput}`]: {
        color: "#031851",
        "&:before": {
            borderBottomColor: "#031851",
        },
        "&:hover:before": {
            borderBottomColor: "#031851 !important",
        },
    },

    [`& .${classes.filterSwitches}`]: {
        paddingTop: 12,
        paddingBottom: 15,
        [theme.breakpoints.down("md")]: {
            paddingBottom: 30,
        },
    },

    [`& .${classes.accountCel}`]: {
        color: "#2f3190",
        cursor: "pointer",
        padding: "7px",
        borderRadius: "6px",
        "&:hover": {
            borderRadius: "6px",
            backgroundColor: "rgba(47,49,144,0.3)",
        },
    },
}));

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "rgba(19, 31, 62, 0.80)",
        fontSize: 12,
        maxWidth: 320,
        borderRadius: 6,
        fontWeight: "normal",
    },
});

const CustomSwitch = styled(Switch)({
    "& .MuiSwitch-switchBase .MuiSwitch-thumb": {
        color: "#FFF",
    },
    "& .MuiSwitch-switchBase + .MuiSwitch-track": {
        backgroundColor: "#031851",
    },
    "& .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb": {
        color: "#FF5968",
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
        backgroundColor: "#FFA8B1",
    },
});

const tableHeaderCell = ({ ...props }) => <TableHeaderRow.Cell {...props} className={classes.tableHeader} />;

const TableComponent = ({ ...props }) => <Table.Table {...props} className={classes.tableStriped} />;

const TableCellComponent = ({ ...props }) => <Table.Cell {...props} className={classes.tableBody} />;

const PagingPanelContainer = ({ ...props }) => <PagingPanel.Container {...props} className={classes.pager} />;

const inputComponent = ({ ...props }) => <SearchPanel.Input {...props} className={classes.searchInput} />;

const toolRootComponent = ({ ...props }) => <Toolbar.Root {...props} className={classes.toolbarSearch} />;

const tableBandCellBase = ({ ...props }) => (
    <TableBandHeader.Cell {...props} className={classes.tableHeaderBand}>
        {props.column.title === " " ? (
            ""
        ) : (
            <Chip label={props.column.title} sx={{ width: "100%", backgroundColor: "#2f3190", color: "white" }} />
        )}
    </TableBandHeader.Cell>
);

const tableColumnExtensions = [
    { columnName: "company_name", width: "18%", align: "left" },
    { columnName: "account", width: "16%", align: "left" },
    { columnName: "last_transaction_date", align: "center" },
    { columnName: "validated_month", align: "center" },
    { columnName: "verifiedAmount", align: "right" },
    { columnName: "last_reconciled_date", align: "center" },
    { columnName: "amount", align: "right" },
];

const rowsSkeleton = [];

for (let i = 0; i < 10; i++) {
    rowsSkeleton.push({
        company_name: <Skeleton variant="text" animation="wave" />,
        account: <Skeleton variant="text" animation="wave" />,
        last_transaction_date: <Skeleton variant="text" animation="wave" />,
        validated_month: <Skeleton variant="text" animation="wave" />,
        // date: <Skeleton variant="text" animation="wave" />,
        verifiedAmount: <Skeleton variant="text" animation="wave" />,
        last_reconciled_date: <Skeleton variant="text" animation="wave" />,
        amount: <Skeleton variant="text" animation="wave" />,
    });
}

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 48 * 4.5 + 8,
            boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
        },
        sx: {
            borderRadius: 2,
            mt: 0.7,
        },
    },
    variant: "menu",
    getContentAnchorEl: null,
};

const Banking = ({ typeData, franqDataOG, groupDataOG }) => {
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    const tableMessages = {
        noData: t("miscellaneous.noData"),
    };

    const markets = [t("miscellaneous.all"), "Global", "LATAM", "USA", "Venezuela"];

    const noColumnsMessage = {
        noColumns: "",
    };

    const pagingMessages = {
        info: ({ from, to, count }) => `${from}${from < to ? `-${to}` : ""} ${t("miscellaneous.pagingOf")} ${count}`,
    };

    const searchMessages = {
        searchPlaceholder: t("miscellaneous.search"),
    };

    /* React redux */
    const todos = useSelector((state) => state.todos);

    /** Component states */
    const [clientsData, setClients] = useState([]);
    const [columnDefs, setColumnsDefs] = useState([]);
    const [searchValue, setSearchState] = useState("");
    const [franqData, setFranqData] = useState(franqDataOG);
    const [groupData, setGroupData] = useState(groupDataOG);
    const [franqF, setfranqF] = useState(["all"].concat(franqDataOG.map((item) => item.id)));
    const [groupF, setgroupF] = useState(["all"].concat(groupDataOG.map((item) => item.id)));
    const [clientT, setTClient] = useState("");
    const [clientMarket, setclientMarket] = useState(markets);
    const [clientStatus, setClientStatus] = useState("");
    const [service, setService] = useState(0);
    const [notValidSwitch, setNotValidSwitch] = useState(false);
    const [notActiveSwitch, setNotActiveSwitch] = useState(false);
    const [notReconciledSwitch, setNotReconciledSwitch] = useState(false);
    const [notVerifiedSwitch, setNotVerifiedSwitch] = useState(false);
    const [columnBands, setColumnBands] = useState([]);
    const [defaultHiddenColumnNames] = useState(["hiddenCompany", "hiddenAcc"]);
    const [showCard, setShowCard] = useState(false);
    const [idOrganisation, setIdOrganisation] = useState("");
    const [accountIdXeroSelected, setAccountIdXeroSelected] = useState("");
    const [accountIdPlatformSelected, setAccountIdPlatformSelected] = useState("");
    const [accountIdSelected, setAccountIdSelected] = useState("");
    const [accountSelected, setAccountSelected] = useState("");
    const [accountNameSelected, setAccountNameSelected] = useState("");
    const [oldestYear, setOldestYear] = useState("");
    const [active, setActive] = useState(false);
    const [anyFilter, setAnyFilter] = useState(true);
    const controllerRef = useRef(null);

    /** Global variables */
    const breadcrumbs = [
        <Typography
            key="1"
            variant="h1"
            color="primary"
            sx={{
                fontSize: 24,
                fontWeight: 700,
            }}
        >
            {t("principal.dashboard")}
        </Typography>,
        <Typography
            key="2"
            variant="h1"
            color="secondary"
            sx={{
                fontSize: 24,
                fontWeight: 700,
            }}
        >
            {t("miscellaneous.banks")}
        </Typography>,
    ];

    /** Component functions */
    const cleanFilters = () => {
        setAnyFilter(true);
        setTClient("");
        setFranqData(franqDataOG);
        setfranqF(["all"].concat(franqDataOG.map((item) => item.id)));
        setGroupData(groupDataOG);
        setgroupF(["all"].concat(groupDataOG.map((item) => item.id)));
        setClientStatus("");
        setService(0);
        setclientMarket(markets);
        setNotValidSwitch(false);
        setNotActiveSwitch(false);
        setNotReconciledSwitch(false);
        setNotVerifiedSwitch(false);
        setActive(false);
    };

    const handleSearch = (value) => {
        let itemS = value.replace(/ +(?= )/g, "");
        setSearchState(itemS);
    };

    const handleChangeTClient = async (event) => {
        setTClient(event.target.value);
        setAnyFilter(false);

        await fetch(`/getClientFranchiseFilter?client_type=${event.target.value}`, {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then((res) => res.json())
            .then((data) => {
                setFranqData(data);
                setfranqF(["all"].concat(data.map((item) => item.id)));
            });
    };

    const handleChangeFranqF = async (event) => {
        const {
            target: { value },
        } = event;

        let final = [];

        if (value.includes("all") && !franqF.includes("all")) {
            final = ["all"].concat(franqData.map((item) => item.id));
        } else if (!value.includes("all") && franqF.includes("all")) {
            final = [];
        } else {
            if (!value.includes("all") && value.length === franqData.length) {
                final = ["all"].concat(franqData.map((item) => item.id));
            } else {
                final =
                    typeof value === "string"
                        ? value.split(",").filter((item) => item !== "all")
                        : value.filter((item) => item !== "all");
            }
        }

        setfranqF(final);
        setAnyFilter(false);

        let finalGroup = [];
        if (clientT) {
            if (value.includes("all") && !franqF.includes("all")) {
                finalGroup = franqData.map((item) => item.id);
            } else if (!value.includes("all") && franqF.includes("all")) {
                finalGroup = franqData.map((item) => item.id);
            } else {
                if (!value.includes("all") && value.length === franqData.length) {
                    finalGroup = franqData.map((item) => item.id);
                } else {
                    finalGroup =
                        typeof value === "string"
                            ? value.split(",").filter((item) => item !== "all")
                            : value.filter((item) => item !== "all");
                }
            }
        } else {
            if (value.includes("all") && !franqF.includes("all")) {
                finalGroup = [];
            } else if (!value.includes("all") && franqF.includes("all")) {
                finalGroup = ["none"];
            } else {
                if (!value.includes("all") && value.length === franqData.length) {
                    finalGroup = [];
                } else {
                    finalGroup =
                        typeof value === "string"
                            ? value.split(",").filter((item) => item !== "all")
                            : value.filter((item) => item !== "all");
                }
            }
        }

        await fetch(`/getFranchiseGroups?id_franchise=${finalGroup}`, {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then((res) => res.json())
            .then((data) => {
                setGroupData(data);
                setgroupF(["all"].concat(data.map((item) => item.id)));
            });
    };

    const handleChangeGroupF = (event) => {
        const {
            target: { value },
        } = event;

        let final = [];

        if (value.includes("all") && !groupF.includes("all")) {
            final = ["all"].concat(groupData.map((item) => item.id));
        } else if (!value.includes("all") && groupF.includes("all")) {
            final = [];
        } else {
            if (!value.includes("all") && value.length === groupData.length) {
                final = ["all"].concat(groupData.map((item) => item.id));
            } else {
                final = event.target.value.filter((item) => item !== "all");
            }
        }

        setgroupF(final);
        setAnyFilter(false);
    };

    const handleChangeClasif = (event) => {
        setClientStatus(event.target.value);
        setAnyFilter(false);
    };

    const handleChangeService = (event) => {
        setService(event.target.value);
        setAnyFilter(false);
    };

    const handleChangeclientMarket = (event) => {
        const {
            target: { value },
        } = event;

        let final = [];

        if (value.includes(t("miscellaneous.all")) && !clientMarket.includes(t("miscellaneous.all"))) {
            final = markets;
        } else if (!value.includes(t("miscellaneous.all")) && clientMarket.includes(t("miscellaneous.all"))) {
            final = [];
        } else {
            if (
                !value.includes(t("miscellaneous.all")) &&
                value.length === markets.filter((item) => item !== t("miscellaneous.all")).length
            ) {
                final = markets;
            } else {
                final =
                    typeof value === "string"
                        ? value.split(",").filter((item) => item !== t("miscellaneous.all"))
                        : value.filter((item) => item !== t("miscellaneous.all"));
            }
        }
        setclientMarket(final);
        setAnyFilter(false);
    };

    /** On change switches state */
    const onNotValidClick = (event) => {
        setNotValidSwitch(event.target.checked);
        setAnyFilter(false);
    };

    const onNotActiveClick = (event) => {
        setNotActiveSwitch(event.target.checked);
        setAnyFilter(false);
    };

    const onNotReconciledClick = (event) => {
        setNotReconciledSwitch(event.target.checked);
        setAnyFilter(false);
    };

    const onNotVerifiedClick = (event) => {
        setNotVerifiedSwitch(event.target.checked);
        setAnyFilter(false);
    };

    const handleActive = (event) => {
        setActive(event.target.checked);
        setAnyFilter(false);
    };

    const tableContentComponent = ({ column, children, ...props }) => (
        <TableHeaderRow.Content {...props}>
            {children}
            {column.name === "last_transaction_date" ? (
                <LightTooltip title={t("bankingDashboard.dateLastRecorded")} aria-label="lastTransaction">
                    <InfoIcon sx={{ ml: 1 }} />
                </LightTooltip>
            ) : column.name === "last_reconciled_date" ? (
                <LightTooltip title={t("bankingDashboard.monthWithoutItems")} aria-label="lastReconciled">
                    <InfoIcon sx={{ ml: 1 }} />
                </LightTooltip>
            ) : column.name === "amount" ? (
                <LightTooltip title={t("bankingDashboard.outstandingBalance")} aria-label="amount">
                    <InfoIcon sx={{ ml: 1 }} />
                </LightTooltip>
            ) : column.name === "validated_month" ? (
                <LightTooltip title={t("bankingDashboard.monthWithSuccessfully")} aria-label="validatedMonth">
                    <InfoIcon sx={{ ml: 1 }} />
                </LightTooltip>
            ) : column.name === "verifiedAmount" ? (
                <LightTooltip title={t("bankingDashboard.verifiedBalanceAccount")} aria-label="verifiedAmount">
                    <InfoIcon sx={{ ml: 1 }} />
                </LightTooltip>
            ) : null}
        </TableHeaderRow.Content>
    );

    const handleBankCard = (clientData) => {
        setIdOrganisation(clientData.id_organisation);
        setAccountIdXeroSelected(clientData.account_id_xero);
        setAccountIdPlatformSelected(clientData.account_platform_id);
        setAccountIdSelected(clientData.account_id);
        setAccountSelected(clientData.account_id);
        setAccountNameSelected(clientData.account_name);
        setOldestYear(clientData.oldest_year);
        setShowCard(true);
        setTimeout(() => {
            window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
        }, 50);
    };

    const nFormatter = (num, digits) => {
        const lookup = [
            { value: 1, symbol: "" },
            { value: 1e3, symbol: "K" },
            { value: 1e6, symbol: "M" },
            { value: 1e9, symbol: "B" },
            { value: 1e12, symbol: "T" },
        ];

        let amount = Math.abs(num);
        const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
        var item = lookup
            .slice()
            .reverse()
            .find(function (item) {
                return amount >= item.value;
            });

        return item ? `${(amount / item.value).toFixed(digits).replace(rx, "$1")} ${item.symbol}` : "0";
    };

    const loadInitData = async () => {
        if (controllerRef.current) {
            controllerRef.current.abort();
        }
        const controller = new AbortController();
        controllerRef.current = controller;

        let finalFranq = [];
        if (franqF.length === 0) {
            finalFranq = ["none"];
        } else if (!franqF.includes("all")) {
            finalFranq = [...franqF];
        }

        let finalGroup = [];
        if (groupF.length === 0) {
            finalGroup = ["none"];
        } else if (!groupF.includes("all")) {
            finalGroup = [...groupF];
        }

        let finalMarket = [];
        if (clientMarket.length === 0) {
            finalMarket = ["none"];
        } else if (!clientMarket.includes(t("miscellaneous.all"))) {
            finalMarket = [...clientMarket];
        }

        const resultT = await fetch(
            `/getBankingDashboard?year=${new Date().getFullYear()}&client_type=${clientT}&franchises=${finalFranq}&practices=${finalGroup}&client_market=${finalMarket}&client_status=${clientStatus}&service=${service}&notValid=${notValidSwitch}&notActive=${notActiveSwitch}&notReconciled=${notReconciledSwitch}&notVerified=${notVerifiedSwitch}&inactive_clients=${active}&contact_email=${
                todos.userInfo.role === "External" ? todos.userInfo.email : ""
            }`,
            {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
                signal: controllerRef.current.signal,
            }
        )
            .then((res) => res.json())
            .then((data) => {
                controllerRef.current = null;
                return data;
            });

        let clientsdata = resultT.map((clientData) => {
            return {
                id: clientData.account_id,
                hiddenCompany: clientData.company_name,
                hiddenAcc: clientData.account_name,
                company_name: (
                    <LightTooltip title={clientData.company_name} aria-label="company-name">
                        <Typography variant="body1">{clientData.company_name}</Typography>
                    </LightTooltip>
                ),
                account: (
                    <LightTooltip title={clientData.account_name} aria-label="company-name">
                        {todos.userInfo.role !== "External" ? (
                            <Typography
                                component="span"
                                variant="h4"
                                className={classes.accountCel}
                                onClick={() => handleBankCard(clientData)}
                            >
                                {clientData.account_name}
                            </Typography>
                        ) : (
                            <Typography variant="body1">{clientData.account_name}</Typography>
                        )}
                    </LightTooltip>
                ),
                last_transaction_date: (
                    <LightTooltip
                        title={
                            clientData.is_active
                                ? t("manageAccount.updatedMovement")
                                : t("manageAccount.noLoadedMovement") +
                                  " " +
                                  clientData.frequency +
                                  " " +
                                  (clientData.frequency > 1 ? t("clientsTable.days") : t("clientsTable.day")) +
                                  " " +
                                  t("clientsTable.orMore")
                        }
                        aria-label="last_transaction_date-tooltip"
                    >
                        {clientData.last_transaction_date === "Sin movimientos" ? (
                            <ListItem
                                alignItems="center"
                                secondaryAction={<WarningAmberIcon sx={{ color: "#FFB039" }} />}
                                sx={{ ml: "auto", mr: "auto" }}
                            >
                                <ListItemText primary={t("miscellaneous.withoutMovements")} />
                            </ListItem>
                        ) : (
                            <ListItem
                                alignItems="center"
                                secondaryAction={
                                    clientData.is_active ? (
                                        <CheckIcon sx={{ color: "green" }} />
                                    ) : (
                                        <WarningAmberIcon sx={{ color: "#FFB039" }} />
                                    )
                                }
                                sx={{ ml: "auto", mr: "auto" }}
                            >
                                <ListItemText
                                    primary={moment(clientData.last_transaction_date, "DD/MM/YYYY").format(
                                        todos.dateFormat
                                    )}
                                />
                            </ListItem>
                        )}
                    </LightTooltip>
                ),
                last_reconciled_date:
                    clientData.last_reconciled_date === "-"
                        ? "-"
                        : (
                              moment(clientData.last_reconciled_date, "MM/YYYY")
                                  .locale(t("language.locale"))
                                  .format("MMM-YYYY")
                                  .charAt(0)
                                  .toUpperCase() +
                              moment(clientData.last_reconciled_date, "MM/YYYY")
                                  .locale(t("language.locale"))
                                  .format("MMM-YYYY")
                                  .slice(1)
                          ).replace(".", ""),
                amount: (
                    <ListItem
                        alignItems="center"
                        secondaryAction={
                            <LightTooltip
                                title={
                                    clientData.pending_items !== 0 ? (
                                        <>
                                            <Typography variant="body1" sx={{ fontSize: 12 }}>
                                                {t("bankingDashboard.notGreatMsg")}
                                            </Typography>
                                            <Typography variant="body1" sx={{ fontSize: 12 }}>
                                                <span style={{ fontWeight: 600 }}>{t("miscellaneous.banks")}: </span>
                                                {parseFloat(clientData.unreconciled_bank_amount).toLocaleString(
                                                    todos.amountFormat,
                                                    { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                                                )}
                                            </Typography>
                                            <Typography variant="body1" sx={{ fontSize: 12 }}>
                                                <span style={{ fontWeight: 600 }}>{t("miscellaneous.books")}: </span>
                                                {parseFloat(clientData.unreconciled_accounting_amount).toLocaleString(
                                                    todos.amountFormat,
                                                    { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                                                )}
                                            </Typography>
                                        </>
                                    ) : (
                                        t("bankingDashboard.greatMsg")
                                    )
                                }
                                aria-label="pending-amount-tooltip"
                            >
                                {clientData.pending_items !== 0 ? (
                                    <WarningAmberIcon sx={{ color: "#FFB039" }} />
                                ) : (
                                    <CheckIcon sx={{ color: "green" }} />
                                )}
                            </LightTooltip>
                        }
                        sx={{ ml: "auto", width: 130 }}
                    >
                        <ListItemText
                            primary={
                                <LightTooltip
                                    title={parseFloat(clientData.total_pending_amount).toLocaleString(
                                        todos.amountFormat,
                                        { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                                    )}
                                    aria-label="pending-amount"
                                >
                                    {(clientData.total_pending_amount >= 0 && clientData.total_pending_amount < 999) ||
                                    (clientData.total_pending_amount < 0 && clientData.total_pending_amount > -999) ? (
                                        <Typography variant="body1">
                                            {parseFloat(clientData.total_pending_amount).toLocaleString(
                                                todos.amountFormat,
                                                { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                                            )}
                                        </Typography>
                                    ) : clientData.total_pending_amount < 0 &&
                                      clientData.total_pending_amount < -999 ? (
                                        <Typography variant="body1">
                                            {`-${nFormatter(clientData.total_pending_amount, 1)}`}
                                        </Typography>
                                    ) : (
                                        <Typography variant="body1">
                                            {nFormatter(clientData.total_pending_amount, 1)}
                                        </Typography>
                                    )}
                                </LightTooltip>
                            }
                            sx={{ textAlign: "right" }}
                        />
                    </ListItem>
                ),
                validated_month:
                    clientData.last_verification_update === "-" ? (
                        <LightTooltip
                            title={t("bankingDashboard.noValidatedMonths")}
                            aria-label="validated_month-tooltip"
                        >
                            <ListItem
                                alignItems="center"
                                secondaryAction={<CancelIcon color="secondary" />}
                                sx={{ ml: "auto", mr: "auto" }}
                            >
                                <ListItemText primary={t("bankingDashboard.withoutValidation")} />
                            </ListItem>
                        </LightTooltip>
                    ) : (
                        <LightTooltip
                            title={
                                moment(clientData.last_verification_update, "MM/YYYY").format(
                                    t("language.shortDateFormat")
                                ) === moment().subtract(1, "months").format(t("language.shortDateFormat"))
                                    ? t("bankingDashboard.lastSuccessfulValIn")
                                    : t("bankingDashboard.lastSuccessfulValIs")
                            }
                            aria-label="last-verification-update"
                        >
                            <ListItem
                                alignItems="center"
                                secondaryAction={
                                    moment(clientData.last_verification_update, "MM/YYYY").format(
                                        t("language.shortDateFormat")
                                    ) === moment().subtract(1, "months").format(t("language.shortDateFormat")) ? (
                                        <CheckIcon sx={{ color: "green" }} />
                                    ) : (
                                        <WarningAmberIcon sx={{ color: "#FFB039" }} />
                                    )
                                }
                                sx={{ ml: "auto", mr: "auto" }}
                            >
                                <ListItemText
                                    primary={(
                                        moment(clientData.last_verification_update, "MM/YYYY")
                                            .locale(t("language.locale"))
                                            .format("MMM-YYYY")
                                            .charAt(0)
                                            .toUpperCase() +
                                        moment(clientData.last_verification_update, "MM/YYYY")
                                            .locale(t("language.locale"))
                                            .format("MMM-YYYY")
                                            .slice(1)
                                    ).replace(".", "")}
                                />
                            </ListItem>
                        </LightTooltip>
                    ),
                // date: moment(clientData.last_updated_date, "YYYY-MM-DD").format(todos.dateFormat),
                verifiedAmount: (
                    <ListItem
                        alignItems="center"
                        secondaryAction={
                            <LightTooltip
                                title={
                                    clientData.is_verified ? (
                                        <>
                                            <Typography variant="body1" sx={{ fontSize: 12 }}>
                                                {t("bankingDashboard.verifiedAmount")}
                                            </Typography>
                                            <Typography variant="body1" sx={{ fontSize: 12 }}>
                                                <span style={{ fontWeight: 600 }}>
                                                    {t("bankingDashboard.verifiedMonth")}:{" "}
                                                </span>
                                                {(
                                                    moment(clientData.verification_month, "MM/YYYY")
                                                        .locale(t("language.locale"))
                                                        .format("MMM-YYYY")
                                                        .charAt(0)
                                                        .toUpperCase() +
                                                    moment(clientData.verification_month, "MM/YYYY")
                                                        .locale(t("language.locale"))
                                                        .format("MMM-YYYY")
                                                        .slice(1)
                                                ).replace(".", "")}
                                            </Typography>
                                        </>
                                    ) : (
                                        <>
                                            <Typography variant="body1" sx={{ fontSize: 12 }}>
                                                {t("manageAccount.noXeroAmount")}
                                            </Typography>
                                            <Typography variant="body1" sx={{ fontSize: 12 }}>
                                                <span style={{ fontWeight: 600 }}>
                                                    {t("bankingDashboard.verifiedMonth")}:{" "}
                                                </span>
                                                {clientData.verification_month !== null
                                                    ? (
                                                          moment(clientData.verification_month, "MM/YYYY")
                                                              .locale(t("language.locale"))
                                                              .format("MMM-YYYY")
                                                              .charAt(0)
                                                              .toUpperCase() +
                                                          moment(clientData.verification_month, "MM/YYYY")
                                                              .locale(t("language.locale"))
                                                              .format("MMM-YYYY")
                                                              .slice(1)
                                                      ).replace(".", "")
                                                    : t("bankingDashboard.withoutVerification")}
                                            </Typography>
                                        </>
                                    )
                                }
                                aria-label="verified-amount-tooltip"
                            >
                                {clientData.is_verified ? (
                                    <CheckIcon sx={{ color: "green" }} />
                                ) : (
                                    <CancelIcon color="secondary" />
                                )}
                            </LightTooltip>
                        }
                        sx={{ ml: "auto", width: 130 }}
                    >
                        <ListItemText
                            primary={
                                <LightTooltip
                                    title={parseFloat(clientData.verified_amount).toLocaleString(todos.amountFormat, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    })}
                                    aria-label="verified-amount"
                                >
                                    {(clientData.verified_amount >= 0 && clientData.verified_amount < 999) ||
                                    (clientData.verified_amount < 0 && clientData.verified_amount > -999) ? (
                                        <Typography variant="body1">
                                            {parseFloat(clientData.verified_amount).toLocaleString(todos.amountFormat, {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                            })}
                                        </Typography>
                                    ) : clientData.verified_amount < 0 && clientData.verified_amount < -999 ? (
                                        <Typography variant="body1">
                                            {`-${nFormatter(clientData.verified_amount, 1)}`}
                                        </Typography>
                                    ) : (
                                        <Typography variant="body1">
                                            {nFormatter(clientData.verified_amount, 1)}
                                        </Typography>
                                    )}
                                </LightTooltip>
                            }
                            sx={{ textAlign: "right" }}
                        />
                    </ListItem>
                ),
            };
        });

        setClients(clientsdata);
    };

    /* Component events */
    useEffect(() => {
        const columnBandsTemplate = [
            {
                title: " ",
                children: [{ columnName: "company_name" }],
            },
            {
                title: " ",
                children: [{ columnName: "account" }],
            },
            {
                title: t("bankingDashboard.transactions"),
                children: [{ columnName: "last_transaction_date" }],
            },
            {
                title: t("bankingDashboard.xeroBanksValidation"),
                children: [{ columnName: "validated_month" }, { columnName: "verifiedAmount" }],
            },
            {
                title: t("bankingDashboard.bankReconciliation"),
                children: [{ columnName: "last_reconciled_date" }, { columnName: "amount" }],
            },
        ];
        setColumnBands(columnBandsTemplate);
    }, [t, todos.dateFormat, todos.amountFormat]);

    useEffect(() => {
        (async () => {
            const columnsTemplate = [];

            if (todos.userInfo.role !== "External") {
                columnsTemplate.push(
                    { name: "hiddenCompany", title: "Hidden Companies" },
                    { name: "hiddenAcc", title: "Hidden Accounts" },
                    { name: "company_name", title: t("miscellaneous.smb") },
                    { name: "account", title: t("miscellaneous.bank2") },
                    { name: "last_transaction_date", title: t("bankingDashboard.lastMovement") },
                    { name: "validated_month", title: t("bankingDashboard.validatedMonth") },
                    // { name: 'date', title: t("miscellaneous.date") },
                    { name: "verifiedAmount", title: t("bankingDashboard.verifiedBalance") },
                    { name: "last_reconciled_date", title: t("bankingDashboard.reconciledMonth") },
                    { name: "amount", title: t("bankingDashboard.reconcileAmount") }
                );
            } else {
                columnsTemplate.push(
                    { name: "hiddenCompany", title: "Hidden Companies" },
                    { name: "hiddenAcc", title: "Hidden Accounts" },
                    { name: "company_name", title: t("miscellaneous.smb") },
                    { name: "account", title: t("miscellaneous.bank2") },
                    { name: "last_transaction_date", title: t("bankingDashboard.lastMovement") },
                    { name: "validated_month", title: t("bankingDashboard.validatedMonth") },
                    // { name: 'date', title: t("miscellaneous.date") },
                    { name: "verifiedAmount", title: t("bankingDashboard.verifiedBalance") },
                    { name: "last_reconciled_date", title: t("bankingDashboard.reconciledMonth") },
                    { name: "amount", title: t("bankingDashboard.reconcileAmount") }
                );
            }
            setColumnsDefs(columnsTemplate);
            setClients(rowsSkeleton);

            await loadInitData();
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        t,
        todos.dateFormat,
        todos.amountFormat,
        clientT,
        franqF,
        groupF,
        clientMarket,
        clientStatus,
        service,
        notValidSwitch,
        notActiveSwitch,
        notReconciledSwitch,
        notVerifiedSwitch,
        active,
    ]);

    return (
        <StyledContainer component="main" sx={{ flexGrow: 1, p: 4, pt: 10, width: "90%" }}>
            <Stack sx={{ pb: 4 }}>
                <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small" sx={{ color: "#031851" }} />}
                    aria-label="breadcrumb"
                >
                    {breadcrumbs}
                </Breadcrumbs>
            </Stack>
            <Card
                elevation={0}
                sx={{
                    borderRadius: 8,
                    boxShadow: "10px 10px 15px rgba(3,24,81,0.15)",
                }}
            >
                <CardContent classes={{ root: classes.cardContentRoot }}>
                    {!todos.authEx && (
                        <GridUI container spacing={2} className={classes.spacing} alignItems="center">
                            <GridUI item xs={12} md={2}>
                                <FormControl fullWidth classes={{ root: classes.formRounded }}>
                                    <InputLabel shrink id="clientMarket-simple-select-label">
                                        {t("generalDashboard.market")}
                                    </InputLabel>
                                    <Select
                                        size="small"
                                        IconComponent={KeyboardArrowDownIcon}
                                        value={clientMarket}
                                        displayEmpty
                                        onChange={handleChangeclientMarket}
                                        className="ddlGeneral"
                                        multiple
                                        input={
                                            <OutlinedInput
                                                notched
                                                label={t("generalDashboard.market")}
                                                sx={{
                                                    fontSize: 16,
                                                    fontWeight: 600,
                                                }}
                                            />
                                        }
                                        MenuProps={MenuProps}
                                        renderValue={(selected) => {
                                            if (selected.length === 0) {
                                                return <span>{t("miscellaneous.none")}</span>;
                                            }

                                            return selected.includes(t("miscellaneous.all"))
                                                ? t("miscellaneous.all")
                                                : selected.join(", ");
                                        }}
                                    >
                                        {markets.map((name) => (
                                            <MenuItem value={name}>
                                                <Checkbox
                                                    checked={clientMarket.indexOf(name) > -1}
                                                    icon={
                                                        name === t("miscellaneous.all") &&
                                                        !clientMarket.includes(t("miscellaneous.all")) &&
                                                        clientMarket.length > 0 ? (
                                                            <IndeterminateCheckBoxIcon style={{ fill: "#031851" }} />
                                                        ) : (
                                                            <CheckBoxOutlineBlankIcon />
                                                        )
                                                    }
                                                />
                                                <ListItemText primary={name} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </GridUI>
                            <GridUI item xs={12} md={2}>
                                <FormControl fullWidth classes={{ root: classes.formRounded }}>
                                    <InputLabel shrink id="type-simple-select-label">
                                        {t("miscellaneous.type")}
                                    </InputLabel>
                                    <Select
                                        size="small"
                                        IconComponent={KeyboardArrowDownIcon}
                                        value={clientT}
                                        displayEmpty
                                        onChange={handleChangeTClient}
                                        className="ddlGeneral"
                                        input={<OutlinedInput notched label={t("miscellaneous.type")} />}
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    mt: 0.7,
                                                    borderRadius: 2,
                                                    boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                },
                                            },
                                        }}
                                    >
                                        <MenuItem value="">
                                            <Typography
                                                variant="body1"
                                                component="span"
                                                align="center"
                                                color="textPrimary"
                                            >
                                                {t("miscellaneous.all")}
                                            </Typography>
                                        </MenuItem>
                                        {typeData.map((value) => (
                                            <MenuItem value={value.id}>
                                                <Typography
                                                    variant="body1"
                                                    component="span"
                                                    align="center"
                                                    color="textPrimary"
                                                >
                                                    {value.id === 1 ? t("miscellaneous.firm") : value.client_type}
                                                </Typography>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </GridUI>
                            <GridUI item xs={12} md={2}>
                                <FormControl fullWidth classes={{ root: classes.formRounded }}>
                                    <InputLabel shrink id="franq-simple-select-label">
                                        {t("miscellaneous.franchise")}
                                    </InputLabel>
                                    <Select
                                        size="small"
                                        IconComponent={KeyboardArrowDownIcon}
                                        value={franqF}
                                        displayEmpty
                                        onChange={handleChangeFranqF}
                                        className="ddlGeneral"
                                        multiple
                                        input={
                                            <OutlinedInput
                                                notched
                                                label={t("miscellaneous.franchise")}
                                                sx={{
                                                    fontSize: 16,
                                                    fontWeight: 600,
                                                }}
                                            />
                                        }
                                        renderValue={(selected) => {
                                            if (selected.length === 0) {
                                                return <span>{t("miscellaneous.none")}</span>;
                                            }
                                            return selected.includes("all")
                                                ? t("miscellaneous.all")
                                                : selected
                                                      .map((item) => {
                                                          return franqData.filter((item2) => item2.id === item)[0]
                                                              .franchise_name;
                                                      })
                                                      .join(", ");
                                        }}
                                        MenuProps={MenuProps}
                                    >
                                        {[
                                            {
                                                id: "all",
                                                franchise_name: t("miscellaneous.all"),
                                            },
                                        ]
                                            .concat(franqData)
                                            .map((value) => (
                                                <MenuItem value={value.id}>
                                                    <Checkbox
                                                        defaultChecked
                                                        checked={franqF.indexOf(value.id) > -1}
                                                        icon={
                                                            value.id === "all" &&
                                                            !franqF.includes("all") &&
                                                            franqF.length > 0 ? (
                                                                <IndeterminateCheckBoxIcon
                                                                    style={{ fill: "#031851" }}
                                                                />
                                                            ) : (
                                                                <CheckBoxOutlineBlankIcon />
                                                            )
                                                        }
                                                    />
                                                    <ListItemText primary={value.franchise_name} />
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>
                            </GridUI>
                            <GridUI item xs={12} md={2}>
                                <FormControl fullWidth classes={{ root: classes.formRounded }}>
                                    <InputLabel shrink id="group-simple-select-label">
                                        {t("miscellaneous.group")}
                                    </InputLabel>
                                    <Select
                                        size="small"
                                        IconComponent={KeyboardArrowDownIcon}
                                        value={groupF}
                                        displayEmpty
                                        multiple
                                        onChange={handleChangeGroupF}
                                        className="ddlGeneral"
                                        input={
                                            <OutlinedInput
                                                notched
                                                label={t("miscellaneous.group")}
                                                sx={{
                                                    fontSize: 16,
                                                    fontWeight: 600,
                                                }}
                                            />
                                        }
                                        renderValue={(selected) => {
                                            if (selected.length === 0) {
                                                return <span>{t("miscellaneous.none")}</span>;
                                            }
                                            return selected.includes("all")
                                                ? t("miscellaneous.all")
                                                : selected
                                                      .map((item) => {
                                                          return groupData.filter((item2) => item2.id === item)[0]
                                                              .group_name;
                                                      })
                                                      .join(", ");
                                        }}
                                        MenuProps={MenuProps}
                                    >
                                        {[
                                            {
                                                id: "all",
                                                group_name: t("miscellaneous.all"),
                                            },
                                        ]
                                            .concat(groupData)
                                            .map((value) => (
                                                <MenuItem value={value.id}>
                                                    <Checkbox
                                                        defaultChecked
                                                        checked={groupF.indexOf(value.id) > -1}
                                                        icon={
                                                            value.id === "all" &&
                                                            !groupF.includes("all") &&
                                                            groupF.length > 0 ? (
                                                                <IndeterminateCheckBoxIcon
                                                                    style={{ fill: "#031851" }}
                                                                />
                                                            ) : (
                                                                <CheckBoxOutlineBlankIcon />
                                                            )
                                                        }
                                                    />
                                                    <ListItemText primary={value.group_name} />
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>
                            </GridUI>
                            <GridUI item xs={12} md={2}>
                                <FormControl fullWidth classes={{ root: classes.formRounded }}>
                                    <InputLabel shrink id="clientStatus-simple-select-label">
                                        {t("miscellaneous.classification")}
                                    </InputLabel>
                                    <Select
                                        size="small"
                                        IconComponent={KeyboardArrowDownIcon}
                                        value={clientStatus}
                                        displayEmpty
                                        onChange={handleChangeClasif}
                                        className="ddlGeneral"
                                        input={<OutlinedInput notched label={t("miscellaneous.classification")} />}
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    mt: 0.7,
                                                    borderRadius: 2,
                                                    boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                },
                                            },
                                        }}
                                    >
                                        <MenuItem value="">
                                            <Typography
                                                variant="body1"
                                                component="span"
                                                align="center"
                                                color="textPrimary"
                                            >
                                                {t("miscellaneous.all")}
                                            </Typography>
                                        </MenuItem>
                                        <MenuItem value={1}>
                                            <Typography
                                                variant="body1"
                                                component="span"
                                                align="center"
                                                color="textPrimary"
                                                sx={{
                                                    fontStyle: "italic",
                                                    ...(moment.locale() === "en" && {
                                                        fontStyle: "normal",
                                                    }),
                                                }}
                                            >
                                                Standard
                                            </Typography>
                                        </MenuItem>
                                        <MenuItem value={2}>
                                            <Typography
                                                variant="body1"
                                                component="span"
                                                align="center"
                                                color="textPrimary"
                                                sx={{
                                                    fontStyle: "italic",
                                                    ...(moment.locale() === "en" && {
                                                        fontStyle: "normal",
                                                    }),
                                                }}
                                            >
                                                VIP
                                            </Typography>
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </GridUI>
                            <GridUI item xs={12} md={2}>
                                <FormControl fullWidth classes={{ root: classes.formRounded }}>
                                    <InputLabel shrink id="service-simple-select-label">
                                        {t("miscellaneous.service")}
                                    </InputLabel>
                                    <Select
                                        size="small"
                                        IconComponent={KeyboardArrowDownIcon}
                                        value={service}
                                        displayEmpty
                                        onChange={handleChangeService}
                                        className="ddlGeneral"
                                        input={<OutlinedInput notched label={t("miscellaneous.service")} />}
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    mt: 0.7,
                                                    borderRadius: 2,
                                                    boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                },
                                            },
                                        }}
                                    >
                                        <MenuItem value={0}>
                                            <Typography
                                                variant="body1"
                                                component="span"
                                                align="center"
                                                color="textPrimary"
                                            >
                                                {t("miscellaneous.all")}
                                            </Typography>
                                        </MenuItem>
                                        <MenuItem value={1}>
                                            <Typography
                                                variant="body1"
                                                component="span"
                                                align="center"
                                                color="textPrimary"
                                                sx={{
                                                    fontStyle: "italic",
                                                    ...(moment.locale() === "en" && {
                                                        fontStyle: "normal",
                                                    }),
                                                }}
                                            >
                                                Accountant
                                            </Typography>
                                        </MenuItem>
                                        <MenuItem value={2}>
                                            <Typography
                                                variant="body1"
                                                component="span"
                                                align="center"
                                                color="textPrimary"
                                                sx={{
                                                    fontStyle: "italic",
                                                    ...(moment.locale() === "en" && {
                                                        fontStyle: "normal",
                                                    }),
                                                }}
                                            >
                                                Bookkeeper
                                            </Typography>
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </GridUI>
                        </GridUI>
                    )}
                    <GridUI container spacing={1} alignItems="center" className={classes.filterSwitches}>
                        <GridUI item xs={12} sm={6} md={2}>
                            <Typography variant="h3" color="textSecondary">
                                {`${t("miscellaneous.closeMonth")}: `}
                                <Typography component="span" variant="h3" color="primary">
                                    {(
                                        moment()
                                            .locale(t("language.locale"))
                                            .subtract(1, "months")
                                            .format("MMM-YYYY")
                                            .charAt(0)
                                            .toUpperCase() +
                                        moment()
                                            .locale(t("language.locale"))
                                            .subtract(1, "months")
                                            .format("MMM-YYYY")
                                            .slice(1)
                                    ).replace(".", "")}
                                </Typography>
                            </Typography>
                        </GridUI>
                        <GridUI item xs={12} sm={6} md={1}>
                            <LightTooltip title={t("miscellaneous.filterNotValid")} aria-label="notValid">
                                <FormControlLabel
                                    control={
                                        <CustomSwitch
                                            checked={notValidSwitch}
                                            inputProps={{ "aria-label": "controlled" }}
                                            color={"secondary"}
                                            onChange={onNotValidClick}
                                        />
                                    }
                                    label={
                                        <Typography variant="h4" color="textPrimary">
                                            {t("miscellaneous.all")}
                                        </Typography>
                                    }
                                />
                            </LightTooltip>
                        </GridUI>
                        <GridUI item xs={12} sm={6} md={3}>
                            <LightTooltip title={t("miscellaneous.filterNotActive")} aria-label="notActive">
                                <FormControlLabel
                                    control={
                                        <CustomSwitch
                                            checked={notActiveSwitch}
                                            inputProps={{ "aria-label": "controlled" }}
                                            color={"secondary"}
                                            onChange={onNotActiveClick}
                                        />
                                    }
                                    label={
                                        <Typography variant="h4" color="textPrimary">
                                            {t("miscellaneous.notActive")}
                                        </Typography>
                                    }
                                />
                            </LightTooltip>
                        </GridUI>
                        <GridUI item xs={12} sm={6} md={2}>
                            <LightTooltip title={t("miscellaneous.filterNotVerified")} aria-label="notVerified">
                                <FormControlLabel
                                    control={
                                        <CustomSwitch
                                            checked={notVerifiedSwitch}
                                            inputProps={{ "aria-label": "controlled" }}
                                            color={"secondary"}
                                            onChange={onNotVerifiedClick}
                                        />
                                    }
                                    label={
                                        <Typography variant="h4" color="textPrimary">
                                            {t("miscellaneous.notVerified")}
                                        </Typography>
                                    }
                                />
                            </LightTooltip>
                        </GridUI>
                        <GridUI item xs={12} sm={6} md={2}>
                            <LightTooltip title={t("miscellaneous.filterNotReconciled")} aria-label="notReconciled">
                                <FormControlLabel
                                    control={
                                        <CustomSwitch
                                            checked={notReconciledSwitch}
                                            inputProps={{ "aria-label": "controlled" }}
                                            color={"secondary"}
                                            onChange={onNotReconciledClick}
                                        />
                                    }
                                    label={
                                        <Typography variant="h4" color="textPrimary">
                                            {t("miscellaneous.notReconciled")}
                                        </Typography>
                                    }
                                />
                            </LightTooltip>
                        </GridUI>
                        <GridUI item xs={12} sm={6} md={2}>
                            <LightTooltip title={t("dynamicLabels.disableClientsTool")} aria-label="activeClients">
                                <FormControlLabel
                                    control={
                                        <CustomSwitch
                                            checked={active}
                                            onChange={handleActive}
                                            inputProps={{ "aria-label": "controlled" }}
                                            color={"secondary"}
                                        />
                                    }
                                    label={
                                        <Typography variant="h4" color="textPrimary">
                                            {t("filtersServ.inactiveClients")}
                                        </Typography>
                                    }
                                />
                            </LightTooltip>
                        </GridUI>
                    </GridUI>
                    <GridUI container>
                        <GridUI item xs={12} md={6} sx={{ mb: { sm: -5 }, zIndex: 1 }}>
                            <Button
                                variant="text"
                                color="primary"
                                disabled={anyFilter}
                                onClick={cleanFilters}
                                sx={{
                                    "&:hover": {
                                        color: "#2f3190",
                                    },
                                }}
                            >
                                {t("miscellaneous.cleanFilters")}
                            </Button>
                        </GridUI>
                        <GridUI item xs={12}>
                            <Grid rows={clientsData} columns={columnDefs}>
                                <SearchState value={searchValue} onValueChange={handleSearch} />
                                <IntegratedFiltering />
                                <PagingState defaultCurrentPage={0} pageSize={10} />
                                <IntegratedPaging />
                                <Table
                                    tableComponent={TableComponent}
                                    columnExtensions={tableColumnExtensions}
                                    cellComponent={TableCellComponent}
                                    messages={tableMessages}
                                />
                                <TableColumnVisibility
                                    defaultHiddenColumnNames={defaultHiddenColumnNames}
                                    messages={noColumnsMessage}
                                />
                                <TableHeaderRow
                                    cellComponent={tableHeaderCell}
                                    contentComponent={tableContentComponent}
                                />
                                <TableBandHeader cellComponent={tableBandCellBase} columnBands={columnBands} />
                                <Toolbar rootComponent={toolRootComponent} />
                                <SearchPanel messages={searchMessages} inputComponent={inputComponent} />
                                <PagingPanel containerComponent={PagingPanelContainer} messages={pagingMessages} />
                            </Grid>
                        </GridUI>
                    </GridUI>
                </CardContent>
            </Card>
            {showCard && (
                <GridUI container justifyContent="center" alignItems="center">
                    <GridUI item xs={12}>
                        <BankValidations
                            open={showCard}
                            onClose={() => setShowCard(false)}
                            idOrganisation={idOrganisation}
                            accountIdXeroSelected={accountIdXeroSelected}
                            accountIdPlatformSelected={accountIdPlatformSelected}
                            accountIdSelected={accountIdSelected}
                            accountSelected={accountSelected}
                            accountNameSelected={accountNameSelected}
                            oldestYear={oldestYear}
                            loadInitDataTable={loadInitData}
                        />
                    </GridUI>
                </GridUI>
            )}
        </StyledContainer>
    );
};

export default Banking;
