/** React imports */
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { NumericFormat } from "react-number-format";
import moment from "moment";

/** Icons/Images */
import { ReactComponent as AccountCircle } from "../../../assets/images/avatar-image.svg";

/** MUI icons */
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import { ReactComponent as CreditCardIcon } from "../../../assets/images/credit_card.svg";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import InsertChartOutlinedIcon from "@mui/icons-material/InsertChartOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import LockIcon from "@mui/icons-material/Lock";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

/** MUI imports */
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import {
    Box,
    Card,
    CardHeader,
    CardContent,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Grid,
    InputAdornment,
    IconButton,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Switch,
    TextField,
    Typography,
} from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

/** Components imports */
import Countries from "../../Js/countries";
import MainCountries from "../../Js/mainCountries";
import Staff from "../../Js/staff";
import CardHeaderGCA from "../../ui/CardHeaderGCA";

/** Component styles */
const PREFIX = "EditFraq";

const classes = {
    avatarImage: `${PREFIX}-avatarImage`,
};

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 48 * 4.5 + 8,
            boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
        },
        sx: {
            borderRadius: 2,
            mt: 0.7,
        },
    },
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.avatarImage}`]: {
        display: "block",
        width: 65,
        height: 65,
        marginLeft: "auto",
        marginRight: "auto",
    },
}));

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "rgba(19, 31, 62, 0.80)",
        fontSize: 12,
        maxWidth: 320,
        borderRadius: 6,
        fontWeight: "normal",
    },
});

const NumberFormatCustom = (props) => {
    const todos = useSelector((state) => state.todos);

    const { inputRef, value, onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            value={value}
            getInputRef={inputRef}
            onValueChange={({ value: v }) => onChange({ target: { value: v } })}
            thousandSeparator={todos.amountFormat === "de-DE" ? "." : ","}
            decimalSeparator={todos.amountFormat === "de-DE" ? "," : "."}
            fixedDecimalScale={true}
            decimalScale={2}
            valueIsNumericString
        />
    );
};

export default function EditFraq(props) {
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    /** React redux */
    const todos = useSelector((state) => state.todos);

    /** Component variables */
    let isRequired2 =
        props.valueStaff.teamCompany2.contact_name.trim() !== "" ||
        props.valueStaff.teamCompany2.contact_email.trim() !== "" ||
        props.valueStaff.teamCompany2.contact_role.trim() !== "" ||
        props.valueStaff.teamCompany2.contact_closure ||
        props.valueStaff.teamCompany2.contact_status ||
        props.valueStaff.teamCompany2.contact_requirements ||
        props.valueStaff.teamCompany2.contact_invoices;
    let isRequired3 =
        props.valueStaff.teamCompany3.contact_name.trim() !== "" ||
        props.valueStaff.teamCompany3.contact_email.trim() !== "" ||
        props.valueStaff.teamCompany3.contact_role.trim() !== "" ||
        props.valueStaff.teamCompany3.contact_closure ||
        props.valueStaff.teamCompany3.contact_status ||
        props.valueStaff.teamCompany3.contact_requirements ||
        props.valueStaff.teamCompany3.contact_invoices;
    let isRequired4 =
        props.valueStaff.teamCompany4.contact_name.trim() !== "" ||
        props.valueStaff.teamCompany4.contact_email.trim() !== "" ||
        props.valueStaff.teamCompany4.contact_role.trim() !== "" ||
        props.valueStaff.teamCompany4.contact_closure ||
        props.valueStaff.teamCompany4.contact_status ||
        props.valueStaff.teamCompany4.contact_requirements ||
        props.valueStaff.teamCompany4.contact_invoices;

    /** Component functions */
    const sortCountries = () => {
        if (moment.locale() === "en") {
            Countries.sort((a, b) => {
                if (a.name_en > b.name_en) return 1;

                if (a.name_en < b.name_en) return -1;

                return 0;
            });
        } else {
            Countries.sort((a, b) => {
                if (a.name_es > b.name_es) return 1;

                if (a.name_es < b.name_es) return -1;

                return 0;
            });
        }
    };

    const handleChange = (event) => {
        props.setValuesGen({
            ...props.valuesGen,
            [event.target.name]: event.target.value,
        });
    };

    const handleChangeSwitch = (event) => {
        props.setValuesGen({
            ...props.valuesGen,
            disabledData: event.target.checked,
        });
    };

    const handleChangeFranq = async (event) => {
        props.setBackDrop(true);

        let teamCompany1 = props.teamCompanyDefault;
        let teamCompany2 = props.teamCompanyDefault;
        let teamCompany3 = props.teamCompanyDefault;
        let teamCompany4 = props.teamCompanyDefault;

        await fetch(`/getFranchiseDetails?franchise_id=${event.target.value}`, {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then((res) => res.json())
            .then((data) => {
                props.setShowEdit();

                if (data.team_company !== undefined) {
                    if (data.team_company.length !== 0) {
                        data.team_company.forEach((element, index) => {
                            if (index === 0) {
                                teamCompany1 = {
                                    contact_name:
                                        element.contact_name !== undefined && element.contact_name !== null
                                            ? element.contact_name
                                            : "",
                                    contact_email:
                                        element.contact_email !== undefined && element.contact_email !== null
                                            ? element.contact_email
                                            : "",
                                    contact_role:
                                        element.contact_role !== undefined && element.contact_role !== null
                                            ? element.contact_role
                                            : "",
                                    contact_closure: element.contact_closure,
                                    contact_status: element.contact_status,
                                    contact_requirements: element.contact_requirements,
                                    contact_invoices: element.contact_invoices,
                                };
                            }
                            if (index === 1) {
                                teamCompany2 = {
                                    contact_name:
                                        element.contact_name !== undefined && element.contact_name !== null
                                            ? element.contact_name
                                            : "",
                                    contact_email:
                                        element.contact_email !== undefined && element.contact_email !== null
                                            ? element.contact_email
                                            : "",
                                    contact_role:
                                        element.contact_role !== undefined && element.contact_role !== null
                                            ? element.contact_role
                                            : "",
                                    contact_closure: element.contact_closure,
                                    contact_status: element.contact_status,
                                    contact_requirements: element.contact_requirements,
                                    contact_invoices: element.contact_invoices,
                                };
                            }
                            if (index === 2) {
                                teamCompany3 = {
                                    contact_name:
                                        element.contact_name !== undefined && element.contact_name !== null
                                            ? element.contact_name
                                            : "",
                                    contact_email:
                                        element.contact_email !== undefined && element.contact_email !== null
                                            ? element.contact_email
                                            : "",
                                    contact_role:
                                        element.contact_role !== undefined && element.contact_role !== null
                                            ? element.contact_role
                                            : "",
                                    contact_closure: element.contact_closure,
                                    contact_status: element.contact_status,
                                    contact_requirements: element.contact_requirements,
                                    contact_invoices: element.contact_invoices,
                                };
                            }
                            if (index === 3) {
                                teamCompany4 = {
                                    contact_name:
                                        element.contact_name !== undefined && element.contact_name !== null
                                            ? element.contact_name
                                            : "",
                                    contact_email:
                                        element.contact_email !== undefined && element.contact_email !== null
                                            ? element.contact_email
                                            : "",
                                    contact_role:
                                        element.contact_role !== undefined && element.contact_role !== null
                                            ? element.contact_role
                                            : "",
                                    contact_closure: element.contact_closure,
                                    contact_status: element.contact_status,
                                    contact_requirements: element.contact_requirements,
                                    contact_invoices: element.contact_invoices,
                                };
                            }
                        });
                    }
                }

                props.setValuesGen({
                    ...props.valuesGen,
                    franchiseId: event.target.value,
                    firmaDate: !data.bsa_date
                            ? null
                            : moment(data.bsa_date, "YYYY-MM-DD"),
                    langEsc:
                        data.typing_communication === undefined || data.typing_communication === null
                            ? ""
                            : data.typing_communication,
                    langVer:
                        data.verbal_communication === undefined || data.verbal_communication === null
                            ? ""
                            : data.verbal_communication,
                    typeClient: data.client_type === undefined || data.client_type === null ? "" : data.client_type,
                    franqName:
                        data.franchise_name === undefined || data.franchise_name === null ? "" : data.franchise_name,
                    country: data.country_name === undefined || data.country_name === null ? "" : data.country_name,
                    user:
                        data.xero_master_user === undefined || data.xero_master_user === null
                            ? ""
                            : data.xero_master_user,
                    password:
                        data.xero_master_password === undefined || data.xero_master_password === null
                            ? ""
                            : data.xero_master_password,
                    disabledData: data.disabled_software !== undefined ? data.disabled_software : false,
                    end_date: data.end_date ? moment(data.end_date, "YYYY-MM-DD") : null,
                    client_delivery_date: data.client_delivery_date ? moment(data.client_delivery_date, "YYYY-MM-DD") : null,
                    endService: data.end_date && data.client_delivery_date
                });
                props.setValuesFact({
                    ...props.valuesFact,
                    contactFact:
                        data.invoicing_client_name === undefined || data.invoicing_client_name === null
                            ? ""
                            : data.invoicing_client_name,
                    onboardingFee: data.onboarding_fee === undefined ? false : data.onboarding_fee,
                    platformFee: data.platform_fee === undefined ? false : data.platform_fee,
                    emailCont:
                        data.invoicing_client_email === undefined || data.invoicing_client_email === null
                            ? ""
                            : data.invoicing_client_email,
                    contactGCA:
                        data.invoicing_contact_name === undefined || data.invoicing_contact_name === null
                            ? ""
                            : data.invoicing_contact_name,
                    phoneNumber:
                        data.invoicing_phone_number === undefined || data.invoicing_phone_number === null
                            ? ""
                            : data.invoicing_phone_number,
                    typeFact:
                        data.invoicing_contact_type === undefined || data.invoicing_contact_type === null
                            ? 1
                            : data.invoicing_contact_type,
                    subsInv:
                        data.invoicing_subscription !== undefined && data.invoicing_subscription !== null
                            ? data.invoicing_subscription
                            : 2,
                });
                props.setValuesSettings({
                    ...props.valuesSettings,
                    anticipatedInvoice:
                        data.anticipated_invoice !== undefined && data.anticipated_invoice !== null
                            ? data.anticipated_invoice
                            : true,
                    onboardingFee:
                        data.invoice_onboarding !== undefined && data.invoice_onboarding !== null
                            ? data.invoice_onboarding
                            : false,
                    platformFee:
                        data.invoice_platform_fee !== undefined && data.invoice_platform_fee !== null
                            ? data.invoice_platform_fee
                            : false,
                    discountUpd:
                        data.discount_updates !== undefined && data.discount_updates !== null
                            ? data.discount_updates
                            : false,
                    clientVip: data.client_vip !== undefined && data.client_vip !== null ? data.client_vip : false,
                    manageReq:
                        data.manage_requirements !== undefined && data.manage_requirements !== null
                            ? data.manage_requirements
                            : false,
                    onboardingTariff: 499,
                    discountOnboarding:
                        data.discount_onboarding !== undefined && data.discount_onboarding !== null
                            ? data.discount_onboarding
                            : 0,
                    tariff: data.tariff !== undefined && data.tariff !== null ? data.tariff : "",
                    tariffAdditionals:
                        data.tariff_additionals !== undefined && data.tariff_additionals !== null
                            ? data.tariff_additionals
                            : "",
                    monthlyStandardReportDate:
                        data.monthly_standard_report_date !== undefined && data.monthly_standard_report_date !== null
                            ? data.monthly_standard_report_date
                            : 1,
                    monthlyVipReportDate:
                        data.monthly_vip_report_date !== undefined && data.monthly_vip_report_date !== null
                            ? data.monthly_vip_report_date
                            : 1,
                    eeccReceptionDays:
                        data.eecc_reception_days !== undefined && data.eecc_reception_days !== null
                            ? data.eecc_reception_days
                            : 1,
                    discount: data.discount ?? 0,
                    personalReports: data.custom_reports ?? false,
                });

                if (
                    data.supervisor_name_request ||
                    data.manager_name_request ||
                    data.analyst_name_request ||
                    data.backup_analyst_name_request ||
                    data.advisor_name_request
                ) {
                    props.setIsReq(true);
                } else {
                    props.setIsReq(false);
                }

                props.setValueStaff({
                    ...props.valueStaff,
                    teamCompany1: teamCompany1,
                    teamCompany2: teamCompany2,
                    teamCompany3: teamCompany3,
                    teamCompany4: teamCompany4,
                    supervisorOG: data.supervisor_name ?? "Por asignar",
                    managerOG: data.manager_name ?? "Por asignar",
                    advisorOG: data.advisor_name ?? "Por asignar",
                    analystOG: data.analyst_name ?? "Por asignar",
                    backupAnalystOG: data.backup_analyst_name ?? "Por asignar",
                    supervisor: data.supervisor_name ?? "Por asignar",
                    manager: data.manager_name ?? "Por asignar",
                    analyst: data.analyst_name ?? "Por asignar",
                    backupAnalyst: data.backup_analyst_name ?? "Por asignar",
                    advisor: data.advisor_name ?? "Por asignar",
                    supervisorReq: data.supervisor_name_request,
                    managerReq: data.manager_name_request,
                    advisorReq: data.advisor_name_request,
                    analystReq: data.analyst_name_request,
                    backupAnalystReq: data.backup_analyst_name_request,
                });
            });
        props.setBackDrop(false);
    };

    const handleChangeFirmaDate = (date) => {
        props.setValuesGen({
            ...props.valuesGen,
            firmaDate: date,
        });
    };

    const handleChangeEndDate = (date) => {
        props.setValuesGen({
            ...props.valuesGen,
            end_date: date.endOf("month"),
        });
    };

    const handleChangeDeliveryDate = (date) => {
        props.setValuesGen({
            ...props.valuesGen,
            client_delivery_date: date,
        });
    };

    const handleChangeEndSwitch = (event) => {
        props.setValuesGen({
            ...props.valuesGen,
            endService: event.target.checked,
            client_delivery_date: null,
            end_date: null,
        });
    };

    const handleClickShowPassword = () => {
        props.setValuesGen({
            ...props.valuesGen,
            showPassword: !props.valuesGen.showPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleChangeFact = (event) => {
        props.setValuesFact({
            ...props.valuesFact,
            [event.target.name]: event.target.value,
        });
    };

    const handleChangePhone = (event) => {
        const regex = /^\+[0-9\s.()-]{0,20}$/;
        if (event.target.value === "" || regex.test(event.target.value)) {
            props.setValuesFact({
                ...props.valuesFact,
                [event.target.name]: event.target.value,
            });
        }
    };

    const handleChangeSettings = (event) => {
        props.setValuesSettings({
            ...props.valuesSettings,
            [event.target.name]: event.target.value,
        });
    };

    const handleChangeSwitchSettings = (event) => {
        props.setValuesSettings({
            ...props.valuesSettings,
            anticipatedInvoice: event.target.checked,
        });
    };

    const handleChangeCheckPlatformFee = (event) => {
        props.setValuesSettings({
            ...props.valuesSettings,
            platformFee: event.target.checked,
        });
    };

    const handleChangeCheckPersonalRep = (event) => {
        props.setValuesSettings({
            ...props.valuesSettings,
            personalReports: event.target.checked,
        });
    };

    // const handleChangeCheckDiscountUpd = (event) => {
    //     props.setValuesSettings({
    //         ...props.valuesSettings,
    //         discountUpd: event.target.checked,
    //     });
    // };

    const handleChangeCheckManageReq = (event) => {
        props.setValuesSettings({
            ...props.valuesSettings,
            manageReq: event.target.checked,
        });
    };

    const handleChangeCheckOnbardingFee = (event) => {
        if (event.target.checked) {
            props.setValuesSettings({
                ...props.valuesSettings,
                onboardingFee: event.target.checked,
            });
        } else {
            props.setValuesSettings({
                ...props.valuesSettings,
                onboardingFee: event.target.checked,
                discountOnboarding: 0,
            });
        }
    };

    const handleChangeCheckClientVip = (event) => {
        if (event.target.checked) {
            props.setValuesSettings({
                ...props.valuesSettings,
                clientVip: event.target.checked,
            });
        } else {
            props.setValuesSettings({
                ...props.valuesSettings,
                clientVip: event.target.checked,
                monthlyVipReportDate: 0,
            });
        }
    };

    const handleChangeDiscount = (event) => {
        if (event.target.value >= 0 && event.target.value <= 100) {
            props.setValuesSettings({
                ...props.valuesSettings,
                [event.target.name]: event.target.value,
            });
        } else {
            props.setValuesSettings({
                ...props.valuesSettings,
                [event.target.name]: 0,
            });
            props.setTexto(t("services.discountPerMsg"));
            props.setalertType("warning");
            props.setShow(true);
        }
    };

    const handleChangeMonthlyReports = (event) => {
        if (event.target.value >= 1 && event.target.value <= 25) {
            props.setValuesSettings({
                ...props.valuesSettings,
                [event.target.name]: event.target.value,
            });
        } else {
            props.setValuesSettings({
                ...props.valuesSettings,
                [event.target.name]: "",
            });
            props.setTexto(t("services.monthlyReportsMsg"));
            props.setalertType("warning");
            props.setShow(true);
        }
    };

    const handleChangeReceptionDays = (event) => {
        if (event.target.value >= 1 && event.target.value <= 15) {
            props.setValuesSettings({
                ...props.valuesSettings,
                [event.target.name]: event.target.value,
            });
        } else {
            props.setValuesSettings({
                ...props.valuesSettings,
                [event.target.name]: "",
            });
            props.setTexto(t("services.receptionDaysMsg"));
            props.setalertType("warning");
            props.setShow(true);
        }
    };

    const handleChangeStaff = (event) => {
        props.setValueStaff({
            ...props.valueStaff,
            [event.target.name]: event.target.value,
        });
    };

    const handleChangeTeamStaff = (event, team) => {
        props.setValueStaff({
            ...props.valueStaff,
            [team]: {
                ...props.valueStaff[team],
                [event.target.name]: event.target.value,
            },
        });
    };

    const handleChangeCheck = (event, value, team) => {
        switch (value) {
            case 0:
                props.setValueStaff({
                    ...props.valueStaff,
                    [team]: {
                        ...props.valueStaff[team],
                        contact_closure: event.target.checked,
                    },
                });
                break;
            case 1:
                props.setValueStaff({
                    ...props.valueStaff,
                    [team]: {
                        ...props.valueStaff[team],
                        contact_status: event.target.checked,
                    },
                });
                break;
            case 4:
                props.setValueStaff({
                    ...props.valueStaff,
                    [team]: {
                        ...props.valueStaff[team],
                        contact_invoices: event.target.checked,
                    },
                });
                break;
            default:
                props.setValueStaff({
                    ...props.valueStaff,
                    [team]: {
                        ...props.valueStaff[team],
                        contact_requirements: event.target.checked,
                    },
                });
                break;
        }
    };

    const clearTeamStaff = (event, team) => {
        props.setValueStaff({
            ...props.valueStaff,
            [team]: props.teamCompanyDefault,
        });
    };

    /** Component events */
    useEffect(() => {
        sortCountries();
    }, [t]);

    return (
        <StyledContainer>
            {props.activeStep === 0 && (
                <LocalizationProvider
                    dateAdapter={AdapterMoment}
                    adapterLocale={t("language.locale")}
                    localeText={{
                        clearButtonLabel: t("miscellaneous.clear"),
                    }}
                >
                    <Card elevation={0}>
                        <CardContent>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={12} sm={6} lg={4}>
                                    <FormControl fullWidth>
                                        <InputLabel id="franq-simple-select-label">
                                            {t("miscellaneous.franchise")}
                                        </InputLabel>
                                        <Select
                                            name="franchiseId"
                                            labelId="franq-simple-select-label"
                                            IconComponent={KeyboardArrowDownIcon}
                                            value={props.valuesGen.franchiseId}
                                            onChange={handleChangeFranq}
                                            label={t("miscellaneous.franchise")}
                                            MenuProps={MenuProps}
                                        >
                                            {props.franqData.map((value) => (
                                                <MenuItem value={value.id}>{value.franchise_name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                {props.showEdit && (
                                    <>
                                        {props.valuesGen.endService && (
                                            <>
                                                <Grid item xs={12} sm={6} lg>
                                                    <DatePicker
                                                        componentsProps={{
                                                            actionBar: {
                                                                actions: ["clear"],
                                                            },
                                                        }}
                                                        value={props.valuesGen.end_date}
                                                        onChange={(newValue) => handleChangeEndDate(newValue)}
                                                        inputFormat={todos.dateFormat}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                fullWidth
                                                                required
                                                                {...params}
                                                                error={
                                                                    props.isError && !props.valuesGen.end_date
                                                                }
                                                            />
                                                        )}
                                                        inputProps={{
                                                            readOnly: true,
                                                        }}
                                                        label={t("invoicing.endDate")}
                                                        views={["year", "month"]}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6} lg>
                                                    <DatePicker
                                                        componentsProps={{
                                                            actionBar: {
                                                                actions: ["clear"],
                                                            },
                                                        }}
                                                        value={props.valuesGen.client_delivery_date}
                                                        onChange={(newValue) => handleChangeDeliveryDate(newValue)}
                                                        inputFormat={todos.dateFormat}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                fullWidth
                                                                required
                                                                {...params}
                                                                error={
                                                                    props.isError &&
                                                                    !props.valuesGen.client_delivery_date
                                                                }
                                                            />
                                                        )}
                                                        inputProps={{
                                                            readOnly: true,
                                                        }}
                                                        label={t("services.deliveryDate")}
                                                        views={["year", "month", "day"]}
                                                    />
                                                </Grid>
                                            </>
                                        )}
                                        <Grid item xs={12} sm={6} lg>
                                            <FormControlLabel
                                                inputProps={{
                                                    readOnly: true,
                                                }}
                                                control={
                                                    <Switch
                                                        checked={props.valuesGen.endService}
                                                        inputProps={{
                                                            "aria-label": "controlled",
                                                        }}
                                                        color={"secondary"}
                                                        onChange={handleChangeEndSwitch}
                                                    />
                                                }
                                                label={
                                                    <Typography variant="body1" color="textPrimary">
                                                        {t("principal.endServices")}
                                                    </Typography>
                                                }
                                            />
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </CardContent>
                    </Card>
                    {props.showEdit && (
                        <>
                            <Card elevation={0}>
                                <CardContent>
                                    <Grid container spacing={2}>
                                        <Grid item lg={2} sm={6} xs={12}>
                                            <FormControl
                                                fullWidth
                                                required
                                                error={
                                                    props.isError && !props.valuesGen.typeClient
                                                }
                                            >
                                                <InputLabel id="type-simple-select-label">
                                                    {t("miscellaneous.type")}
                                                </InputLabel>
                                                <Select
                                                    labelId="type-simple-select-label"
                                                    name="typeClient"
                                                    onChange={handleChange}
                                                    value={props.valuesGen.typeClient}
                                                    IconComponent={KeyboardArrowDownIcon}
                                                    label={t("miscellaneous.type")}
                                                    MenuProps={{
                                                        PaperProps: {
                                                            sx: {
                                                                mt: 0.7,
                                                                borderRadius: 2,
                                                                boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                            },
                                                        },
                                                    }}
                                                >
                                                    <MenuItem value={1}>{t("miscellaneous.firm")}</MenuItem>
                                                    <MenuItem value={2}>SMB</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item lg={5} sm={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label={t("miscellaneous.franchiseName")}
                                                name="franqName"
                                                onChange={handleChange}
                                                value={props.valuesGen.franqName}
                                                variant="outlined"
                                                required
                                                error={
                                                    props.isError && props.valuesGen.franqName.trim() === ""
                                                        ? true
                                                        : false
                                                }
                                            />
                                        </Grid>
                                        <Grid item lg={5} sm={6} xs={12}>
                                            <FormControl
                                                fullWidth
                                                required
                                                error={props.isError && props.valuesGen.country === "" ? true : false}
                                            >
                                                <InputLabel id="country-simple-select-label">
                                                    {t("miscellaneous.country")}
                                                </InputLabel>
                                                <Select
                                                    name="country"
                                                    labelId="country-simple-select-label"
                                                    id="demo-simple-select"
                                                    IconComponent={KeyboardArrowDownIcon}
                                                    value={props.valuesGen.country}
                                                    label={t("miscellaneous.country")}
                                                    onChange={handleChange}
                                                    MenuProps={MenuProps}
                                                >
                                                    {MainCountries.map((option) => (
                                                        <MenuItem value={option.code}>
                                                            {moment.locale() === "en" ? option.name_en : option.name_es}
                                                        </MenuItem>
                                                    ))}
                                                    <Divider />
                                                    {Countries.map((option) => (
                                                        <MenuItem value={option.code}>
                                                            {moment.locale() === "en" ? option.name_en : option.name_es}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item lg={3} sm={6} xs={12}>
                                            <DatePicker
                                                componentsProps={{
                                                    actionBar: {
                                                        actions: ["clear"],
                                                    },
                                                }}
                                                value={props.valuesGen.firmaDate}
                                                onChange={(newValue) => handleChangeFirmaDate(newValue)}
                                                inputFormat={todos.dateFormat}
                                                renderInput={(params) => (
                                                    <TextField
                                                        fullWidth
                                                        required
                                                        {...params}
                                                        error={
                                                            props.isError && props.valuesGen.firmaDate === null
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                )}
                                                inputProps={{
                                                    readOnly: true,
                                                }}
                                                label={t("miscellaneous.firmDate")}
                                            />
                                        </Grid>
                                        <Grid item lg={3} sm={6} xs={12}>
                                            <FormControl
                                                fullWidth
                                                required
                                                error={props.isError && props.valuesGen.langVer === "" ? true : false}
                                            >
                                                <InputLabel id="ver-simple-select-label">
                                                    {t("miscellaneous.verbalCommunication")}
                                                </InputLabel>
                                                <Select
                                                    name="langVer"
                                                    labelId="ver-simple-select-label"
                                                    IconComponent={KeyboardArrowDownIcon}
                                                    value={props.valuesGen.langVer}
                                                    label={t("miscellaneous.verbalCommunication")}
                                                    onChange={handleChange}
                                                    MenuProps={{
                                                        PaperProps: {
                                                            sx: {
                                                                mt: 0.7,
                                                                borderRadius: 2,
                                                                boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                            },
                                                        },
                                                    }}
                                                >
                                                    <MenuItem value={1}>{t("language.english")}</MenuItem>
                                                    <MenuItem value={2}>{t("language.spanish")}</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item lg={3} sm={6} xs={12}>
                                            <FormControl
                                                fullWidth
                                                required
                                                error={props.isError && props.valuesGen.langEsc === "" ? true : false}
                                            >
                                                <InputLabel id="esc-simple-select-label">
                                                    {t("miscellaneous.writtenCommunication")}
                                                </InputLabel>
                                                <Select
                                                    name="langEsc"
                                                    labelId="esc-simple-select-label"
                                                    IconComponent={KeyboardArrowDownIcon}
                                                    value={props.valuesGen.langEsc}
                                                    label={t("miscellaneous.writtenCommunication")}
                                                    onChange={handleChange}
                                                    MenuProps={{
                                                        PaperProps: {
                                                            sx: {
                                                                mt: 0.7,
                                                                borderRadius: 2,
                                                                boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                            },
                                                        },
                                                    }}
                                                >
                                                    <MenuItem value={1}>{t("language.english")}</MenuItem>
                                                    <MenuItem value={2}>{t("language.spanish")}</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                            <Card elevation={0}>
                                <CardHeader
                                    title={
                                        <Box
                                            sx={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <Box>
                                                <Typography variant="h1" sx={{ fontWeight: 700 }} color="secondary">
                                                    {t("miscellaneous.accountingSystemAccess")}
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <FormControlLabel
                                                    inputProps={{
                                                        readOnly: true,
                                                    }}
                                                    control={
                                                        <Switch
                                                            checked={props.valuesGen.disabledData}
                                                            inputProps={{
                                                                "aria-label": "controlled",
                                                            }}
                                                            color={"secondary"}
                                                            onChange={handleChangeSwitch}
                                                        />
                                                    }
                                                    label={
                                                        <Typography variant="body1" color="textPrimary">
                                                            {t("miscellaneous.notApply")}
                                                        </Typography>
                                                    }
                                                />
                                            </Box>
                                        </Box>
                                    }
                                    sx={{ color: "#ff5968" }}
                                />
                                <Divider />
                                <CardContent>
                                    <Grid container spacing={2}>
                                        <Grid item lg={6} sm={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label={t("login.userAccess")}
                                                name="user"
                                                onChange={handleChange}
                                                value={props.valuesGen.user}
                                                variant="outlined"
                                                disabled={props.valuesGen.disabledData}
                                                required={!props.valuesGen.disabledData}
                                                error={
                                                    props.isError && props.valuesGen.user.trim() === "" ? true : false
                                                }
                                            />
                                        </Grid>
                                        <Grid item lg={6} sm={6} xs={12}>
                                            <FormControl
                                                fullWidth
                                                disabled={props.valuesGen.disabledData}
                                                required={!props.valuesGen.disabledData}
                                                variant="outlined"
                                                error={
                                                    props.isError && props.valuesGen.password.trim() === ""
                                                        ? true
                                                        : false
                                                }
                                            >
                                                <InputLabel htmlFor="outlined-adornment-password">
                                                    {t("login.password")}
                                                </InputLabel>
                                                <OutlinedInput
                                                    id="outlined-adornment-password"
                                                    fullWidth
                                                    type={props.valuesGen.showPassword ? "text" : "password"}
                                                    label={t("login.password")}
                                                    name="password"
                                                    onChange={handleChange}
                                                    value={props.valuesGen.password}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowPassword}
                                                                onMouseDown={handleMouseDownPassword}
                                                                edge="end"
                                                            >
                                                                {props.valuesGen.showPassword ? (
                                                                    <VisibilityOff />
                                                                ) : (
                                                                    <Visibility />
                                                                )}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </>
                    )}
                </LocalizationProvider>
            )}
            {props.activeStep === 1 && (
                <>
                    <Card elevation={0}>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item lg={4} sm={6} xs={12}>
                                    <FormControl
                                        fullWidth
                                        required
                                        error={props.isError1 && props.valuesFact.typeFact === "" ? true : false}
                                    >
                                        <InputLabel id="nivel-simple-select-label">
                                            {t("miscellaneous.invoicingServicesTo")}
                                        </InputLabel>
                                        <Select
                                            name="typeFact"
                                            labelId="nivel-simple-select-label"
                                            IconComponent={KeyboardArrowDownIcon}
                                            label={t("miscellaneous.invoicingServicesTo")}
                                            value={props.valuesFact.typeFact}
                                            onChange={handleChangeFact}
                                            MenuProps={{
                                                PaperProps: {
                                                    sx: {
                                                        mt: 0.7,
                                                        borderRadius: 2,
                                                        boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                    },
                                                },
                                            }}
                                        >
                                            <MenuItem value={1}>{t("miscellaneous.franchise")}</MenuItem>
                                            {props.valuesGen.typeClient === 2 ? (
                                                <MenuItem value={2}>{t("miscellaneous.client")}</MenuItem>
                                            ) : null}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item lg={4} sm={6} xs={12}>
                                    <FormControl fullWidth required error={props.isError1 && !props.valuesFact.subsInv}>
                                        <InputLabel id="subsInv-simple-select-label">
                                            {t("invoicing.subsInv")}
                                        </InputLabel>
                                        <Select
                                            name="subsInv"
                                            labelId="subsInv-simple-select-label"
                                            label={t("invoicing.subsInv")}
                                            IconComponent={KeyboardArrowDownIcon}
                                            value={props.valuesFact.subsInv}
                                            onChange={handleChangeFact}
                                            MenuProps={{
                                                PaperProps: {
                                                    sx: {
                                                        mt: 0.7,
                                                        borderRadius: 2,
                                                        boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                    },
                                                },
                                            }}
                                        >
                                            <MenuItem value={1}>GCA SC (Venezuela)</MenuItem>
                                            <MenuItem value={2}>GCA INC (Estados Unidos)</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    <Card elevation={0}>
                        <CardHeader title={t("miscellaneous.contact")} sx={{ color: "#ff5968" }} />
                        <Divider />
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item lg={4} sm={6} xs={12}>
                                    <TextField
                                        fullWidth
                                        required={props.valuesFact.typeFact !== 2}
                                        disabled={props.valuesFact.typeFact === 2}
                                        label={t("miscellaneous.invoicingNameGCA")}
                                        name="contactGCA"
                                        onChange={handleChangeFact}
                                        value={props.valuesFact.contactGCA}
                                        error={
                                            props.isError1 && props.valuesFact.contactGCA.trim() === "" ? true : false
                                        }
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item lg={4} sm={6} xs={12}>
                                    <TextField
                                        fullWidth
                                        required={props.valuesFact.typeFact !== 2}
                                        disabled={props.valuesFact.typeFact === 2}
                                        label={t("miscellaneous.contactName")}
                                        name="contactFact"
                                        onChange={handleChangeFact}
                                        value={props.valuesFact.contactFact}
                                        error={
                                            props.isError1 && props.valuesFact.contactFact.trim() === "" ? true : false
                                        }
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item lg={4} sm={6} xs={12}>
                                    <TextField
                                        fullWidth
                                        required={props.valuesFact.typeFact !== 2}
                                        disabled={props.valuesFact.typeFact === 2}
                                        label={t("miscellaneous.contactEmail")}
                                        name="emailCont"
                                        onChange={handleChangeFact}
                                        value={props.valuesFact.emailCont}
                                        variant="outlined"
                                        error={
                                            props.isError1 && props.valuesFact.emailCont.trim() === ""
                                                ? true
                                                : props.isError1 && !/.+@.+\..+/.test(props.valuesFact.emailCont)
                                                ? true
                                                : false
                                        }
                                    />
                                </Grid>
                                <Grid item lg={4} sm={6} xs={12}>
                                    <FormControl fullWidth disabled={props.valuesFact.typeFact === 2}>
                                        <InputLabel htmlFor="invoicing-phone-number-mask-input">
                                            {t("miscellaneous.phoneNumber")}
                                        </InputLabel>
                                        <OutlinedInput
                                            id="invoicing-phone-number-mask-input"
                                            name="phoneNumber"
                                            label={t("miscellaneous.phoneNumber")}
                                            value={props.valuesFact.phoneNumber}
                                            onChange={handleChangePhone}
                                            placeholder="e.g. +99 (999) 999-9999"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </>
            )}
            {props.activeStep === 2 && (
                <>
                    <Card elevation={0}>
                        <CardHeader
                            title={t("services.franchiseServices")}
                            action={
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={props.valuesSettings.anticipatedInvoice}
                                            inputProps={{
                                                "aria-label": "controlled",
                                            }}
                                            color="secondary"
                                            onChange={handleChangeSwitchSettings}
                                            sx={{
                                                "& .MuiSwitch-switchBase .MuiSwitch-thumb": {
                                                    color: "#FFF",
                                                },
                                                "& .MuiSwitch-switchBase + .MuiSwitch-track": {
                                                    backgroundColor: "#031851",
                                                },
                                                "& .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb": {
                                                    color: "#FF5968",
                                                },
                                                "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                                                    backgroundColor: "#FFA8B1",
                                                },
                                            }}
                                        />
                                    }
                                    label={
                                        <Typography variant="body1" color="textPrimary">
                                            {t("services.advanceInvoicing")}
                                        </Typography>
                                    }
                                />
                            }
                            sx={{ color: "#ff5968" }}
                        />
                        <Divider />
                        <CardContent>
                            <Grid container spacing={2} sx={{ pb: 2 }}>
                                <Grid item lg={3} sm={6} xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name="onboardingFee"
                                                checked={props.valuesSettings.onboardingFee}
                                                onChange={handleChangeCheckOnbardingFee}
                                            />
                                        }
                                        label={t("miscellaneous.onboardingFee")}
                                    />
                                </Grid>
                                <Grid item lg={3} sm={6} xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name="platformFee"
                                                checked={props.valuesSettings.platformFee}
                                                onChange={handleChangeCheckPlatformFee}
                                            />
                                        }
                                        label={t("miscellaneous.platformFee")}
                                    />
                                </Grid>
                                {/* <Grid item lg={3} sm={6} xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name="discountUpd"
                                                checked={props.valuesSettings.discountUpd}
                                                onChange={handleChangeCheckDiscountUpd}
                                            />
                                        }
                                        label={t("miscellaneous.discountUpdate")}
                                    />
                                </Grid> */}
                                <Grid item lg={3} sm={6} xs={12}>
                                    <LightTooltip title={t("proposals.personalRepTool")}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    name="personalReports"
                                                    checked={props.valuesSettings.personalReports}
                                                    onChange={handleChangeCheckPersonalRep}
                                                />
                                            }
                                            label={t("proposals.addPerRep")}
                                        />
                                    </LightTooltip>
                                </Grid>
                            </Grid>
                            {props.valuesSettings.onboardingFee && (
                                <Grid container spacing={2}>
                                    <Grid item lg={3} sm={6} xs={12}>
                                        <FormControl fullWidth disabled variant="outlined">
                                            <InputLabel htmlFor="outlined-adornment-onboardingTariff">
                                                {t("services.onboardingTariff")}
                                            </InputLabel>
                                            <OutlinedInput
                                                id="outlined-adornment-onboardingTariff"
                                                name="onboardingTariff"
                                                value={
                                                    parseFloat(props.valuesSettings.onboardingTariff) -
                                                    parseFloat(props.valuesSettings.onboardingTariff) *
                                                        (props.valuesSettings.discountOnboarding === ""
                                                            ? 0
                                                            : parseFloat(props.valuesSettings.discountOnboarding) / 100)
                                                }
                                                inputComponent={NumberFormatCustom}
                                                startAdornment={
                                                    <InputAdornment disableTypography position="start">
                                                        $
                                                    </InputAdornment>
                                                }
                                                label={t("services.onboardingTariff")}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item lg={3} sm={6} xs={12}>
                                        <TextField
                                            fullWidth
                                            name="discountOnboarding"
                                            label={t("services.discountRateOnboarding")}
                                            value={props.valuesSettings.discountOnboarding}
                                            onChange={handleChangeDiscount}
                                            onBlur={() =>
                                                props.setValuesSettings({
                                                    ...props.valuesSettings,
                                                    discountOnboarding: parseFloat(
                                                        props.valuesSettings.discountOnboarding
                                                    ).toFixed(2),
                                                })
                                            }
                                            variant="outlined"
                                            type="number"
                                            InputProps={{
                                                inputProps: {
                                                    min: 0,
                                                    max: 100,
                                                },
                                            }}
                                            sx={{
                                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                                    {
                                                        display: "none",
                                                    },
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        </CardContent>
                    </Card>
                    <Card elevation={0}>
                        <CardHeader title={t("services.generalConsiderations")} sx={{ color: "#ff5968" }} />
                        <Divider />
                        <CardContent>
                            <Grid container spacing={2} sx={{ pb: 2 }}>
                                <Grid item lg={3} sm={6} xs={12}>
                                    <LightTooltip title={t("miscellaneous.manageReqTool")}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    name="manageReq"
                                                    checked={props.valuesSettings.manageReq}
                                                    onChange={handleChangeCheckManageReq}
                                                />
                                            }
                                            label={t("miscellaneous.manageReq")}
                                        />
                                    </LightTooltip>
                                </Grid>
                                <Grid item lg={3} sm={6} xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name="clientVip"
                                                checked={props.valuesSettings.clientVip}
                                                onChange={handleChangeCheckClientVip}
                                            />
                                        }
                                        label={t("miscellaneous.clientVip")}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{ pb: 2 }}>
                                <Grid item lg={3} sm={6} xs={12}>
                                    <FormControl
                                        fullWidth
                                        required
                                        error={props.isError2 && props.valuesSettings.tariff === "" ? true : false}
                                    >
                                        <InputLabel id="tariff-simple-select-label">
                                            {t("services.baseRate")}
                                        </InputLabel>
                                        <Select
                                            name="tariff"
                                            labelId="tariff-simple-select-label"
                                            label={t("services.baseRate")}
                                            value={props.valuesSettings.tariff}
                                            onChange={handleChangeSettings}
                                            IconComponent={KeyboardArrowDownIcon}
                                            MenuProps={{
                                                PaperProps: {
                                                    sx: {
                                                        mt: 0.7,
                                                        borderRadius: 2,
                                                        boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                    },
                                                },
                                            }}
                                        >
                                            <MenuItem value={0}>$0</MenuItem>
                                            <MenuItem value={5}>$5</MenuItem>
                                            <MenuItem value={10}>$10</MenuItem>
                                            <MenuItem value={15}>$15</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item lg={3} sm={6} xs={12}>
                                    <FormControl
                                        fullWidth
                                        required
                                        error={
                                            props.isError2 && props.valuesSettings.tariffAdditionals === ""
                                                ? true
                                                : false
                                        }
                                    >
                                        <InputLabel id="tariffAdditionals-simple-select-label">
                                            {t("services.additionalsRate")}
                                        </InputLabel>
                                        <Select
                                            name="tariffAdditionals"
                                            labelId="tariffAdditionals-simple-select-label"
                                            label={t("services.additionalsRate")}
                                            value={props.valuesSettings.tariffAdditionals}
                                            onChange={handleChangeSettings}
                                            IconComponent={KeyboardArrowDownIcon}
                                            MenuProps={{
                                                PaperProps: {
                                                    sx: {
                                                        mt: 0.7,
                                                        borderRadius: 2,
                                                        boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                    },
                                                },
                                            }}
                                        >
                                            <MenuItem value={15}>$15</MenuItem>
                                            <MenuItem value={25}>$25</MenuItem>
                                            <MenuItem value={30}>$30</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item lg={3} sm={6} xs={12}>
                                    <LightTooltip title={t("miscellaneous.applyOnly")}>
                                        <TextField
                                            fullWidth
                                            label={t("miscellaneous.discountFran")}
                                            value={props.valuesSettings.discount}
                                            name="discount"
                                            onChange={handleChangeDiscount}
                                            onBlur={() =>
                                                props.setValuesSettings({
                                                    ...props.valuesSettings,
                                                    discount: parseFloat(props.valuesSettings.discount).toFixed(2),
                                                })
                                            }
                                            variant="outlined"
                                            type="number"
                                            InputProps={{
                                                inputProps: {
                                                    min: 0,
                                                    max: 100,
                                                },
                                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                            }}
                                            sx={{
                                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                                    {
                                                        display: "none",
                                                    },
                                            }}
                                        />
                                    </LightTooltip>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item lg={3} sm={6} xs={12}>
                                    <TextField
                                        fullWidth
                                        required
                                        name="eeccReceptionDays"
                                        label={t("services.receptionDays")}
                                        value={props.valuesSettings.eeccReceptionDays}
                                        onChange={handleChangeReceptionDays}
                                        variant="outlined"
                                        type="number"
                                        InputProps={{
                                            inputProps: { min: 1, max: 15 },
                                        }}
                                        error={
                                            props.isError2 && props.valuesSettings.eeccReceptionDays === ""
                                                ? true
                                                : false
                                        }
                                    />
                                </Grid>
                                {props.valuesSettings.clientVip && (
                                    <Grid item lg={3} sm={6} xs={12}>
                                        <TextField
                                            fullWidth
                                            required={props.valuesSettings.clientVip}
                                            name="monthlyVipReportDate"
                                            label={t("services.vipMonthlyReportDate")}
                                            value={props.valuesSettings.monthlyVipReportDate}
                                            onChange={handleChangeMonthlyReports}
                                            variant="outlined"
                                            type="number"
                                            InputProps={{
                                                inputProps: { min: 1, max: 25 },
                                            }}
                                            error={
                                                props.isError2 &&
                                                props.valuesSettings.clientVip &&
                                                props.valuesSettings.monthlyVipReportDate === ""
                                                    ? true
                                                    : false
                                            }
                                        />
                                    </Grid>
                                )}
                                <Grid item lg={3} sm={6} xs={12}>
                                    <TextField
                                        fullWidth
                                        required
                                        name="monthlyStandardReportDate"
                                        label={t("services.stdMonthlyReportDate")}
                                        value={props.valuesSettings.monthlyStandardReportDate}
                                        onChange={handleChangeMonthlyReports}
                                        variant="outlined"
                                        type="number"
                                        InputProps={{
                                            inputProps: { min: 1, max: 25 },
                                        }}
                                        error={
                                            props.isError2 && props.valuesSettings.monthlyStandardReportDate === ""
                                                ? true
                                                : false
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </>
            )}
            {props.activeStep === 3 && (
                <Card elevation={0}>
                    <CardHeader title={t("miscellaneous.clientStaff")} sx={{ color: "#ff5968" }} />
                    <Divider />
                    <CardContent sx={{ pb: 5 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Card
                                    sx={{
                                        borderRadius: "20px",
                                        backgroundColor: "#F8F9F8",
                                        boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                    }}
                                >
                                    <CardContent sx={{ p: "24px 24px 16px !important" }}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} lg={4}>
                                                <AccountCircle className={classes.avatarImage} />
                                                <Typography variant="h2" color="primary" align="center" sx={{ pt: 1 }}>
                                                    {`${t("miscellaneous.contact")} 1`}
                                                </Typography>
                                                <LightTooltip title={t("recurrent.clear")}>
                                                    <IconButton
                                                        aria-label="clear"
                                                        color="primary"
                                                        onClick={(event) => clearTeamStaff(event, "teamCompany1")}
                                                        sx={{
                                                            display: "block",
                                                            m: "auto",
                                                        }}
                                                    >
                                                        <ClearAllIcon />
                                                    </IconButton>
                                                </LightTooltip>
                                            </Grid>
                                            <Grid item xs={12} lg={8}>
                                                <TextField
                                                    fullWidth
                                                    required
                                                    label={t("miscellaneous.name")}
                                                    name="contact_name"
                                                    onChange={(event) => handleChangeTeamStaff(event, "teamCompany1")}
                                                    value={props.valueStaff.teamCompany1.contact_name}
                                                    variant="outlined"
                                                    error={
                                                        props.isError3 &&
                                                        props.valueStaff.teamCompany1.contact_name.trim() === ""
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                <TextField
                                                    fullWidth
                                                    required
                                                    label={t("miscellaneous.email")}
                                                    name="contact_email"
                                                    onChange={(event) => handleChangeTeamStaff(event, "teamCompany1")}
                                                    value={props.valueStaff.teamCompany1.contact_email}
                                                    variant="outlined"
                                                    error={
                                                        props.isError3 &&
                                                        props.valueStaff.teamCompany1.contact_email.trim() === ""
                                                            ? true
                                                            : props.isError3 &&
                                                              !/.+@.+\..+/.test(
                                                                  props.valueStaff.teamCompany1.contact_email.trim()
                                                              )
                                                            ? true
                                                            : false
                                                    }
                                                    sx={{ mt: 2 }}
                                                />
                                                <TextField
                                                    fullWidth
                                                    required
                                                    label={t("miscellaneous.role")}
                                                    name="contact_role"
                                                    onChange={(event) => handleChangeTeamStaff(event, "teamCompany1")}
                                                    value={props.valueStaff.teamCompany1.contact_role}
                                                    variant="outlined"
                                                    error={
                                                        props.isError3 &&
                                                        props.valueStaff.teamCompany1.contact_role.trim() === ""
                                                            ? true
                                                            : false
                                                    }
                                                    sx={{ mt: 2 }}
                                                />
                                                <FormGroup
                                                    sx={{
                                                        mt: 2,
                                                        display: "block",
                                                    }}
                                                >
                                                    <FormLabel id="permissions-checkboxes-group-label">
                                                        <Typography variant="h4" color="primary" sx={{ pb: 1 }}>
                                                            {t("miscellaneous.permissions")}:
                                                        </Typography>
                                                    </FormLabel>
                                                    <LightTooltip
                                                        title={t("services.toolRequirements")}
                                                        aria-label="req"
                                                    >
                                                        <Checkbox
                                                            icon={<AssignmentTurnedInOutlinedIcon />}
                                                            checkedIcon={<AssignmentTurnedInIcon />}
                                                            checked={props.valueStaff.teamCompany1.contact_requirements}
                                                            onChange={(event) =>
                                                                handleChangeCheck(event, 3, "teamCompany1")
                                                            }
                                                            sx={{
                                                                ml: {
                                                                    xs: 0,
                                                                    lg: 0,
                                                                    xl: 0,
                                                                },
                                                                mr: {
                                                                    xs: 1,
                                                                    lg: 0,
                                                                    xl: 1,
                                                                },
                                                            }}
                                                        />
                                                    </LightTooltip>
                                                    <LightTooltip
                                                        title={t("services.toolAccountingClosures")}
                                                        aria-label="cierres"
                                                    >
                                                        <Checkbox
                                                            icon={<LockOutlinedIcon />}
                                                            checkedIcon={<LockIcon />}
                                                            checked={props.valueStaff.teamCompany1.contact_closure}
                                                            onChange={(event) =>
                                                                handleChangeCheck(event, 0, "teamCompany1")
                                                            }
                                                            sx={{
                                                                ml: {
                                                                    xs: 1,
                                                                    lg: 0,
                                                                    xl: 1,
                                                                },
                                                                mr: {
                                                                    xs: 1,
                                                                    lg: 0,
                                                                    xl: 1,
                                                                },
                                                            }}
                                                        />
                                                    </LightTooltip>
                                                    <LightTooltip title={t("services.toolStatus")} aria-label="status">
                                                        <Checkbox
                                                            icon={<InsertChartOutlinedIcon />}
                                                            checkedIcon={<InsertChartIcon />}
                                                            checked={props.valueStaff.teamCompany1.contact_status}
                                                            onChange={(event) =>
                                                                handleChangeCheck(event, 1, "teamCompany1")
                                                            }
                                                            sx={{
                                                                ml: {
                                                                    xs: 1,
                                                                    lg: 0,
                                                                    xl: 1,
                                                                },
                                                                mr: {
                                                                    xs: 1,
                                                                    lg: 0,
                                                                    xl: 1,
                                                                },
                                                            }}
                                                        />
                                                    </LightTooltip>
                                                    <LightTooltip
                                                        title={t("services.toolInvoicing")}
                                                        aria-label="invoicing"
                                                    >
                                                        <Checkbox
                                                            icon={<CreditCardOutlinedIcon />}
                                                            checkedIcon={
                                                                <CreditCardIcon className="icon-normal-size" />
                                                            }
                                                            checked={props.valueStaff.teamCompany1.contact_invoices}
                                                            onChange={(event) =>
                                                                handleChangeCheck(event, 4, "teamCompany1")
                                                            }
                                                            sx={{
                                                                ml: {
                                                                    xs: 1,
                                                                    lg: 0,
                                                                    xl: 1,
                                                                },
                                                                mr: {
                                                                    xs: 1,
                                                                    lg: 0,
                                                                    xl: 1,
                                                                },
                                                            }}
                                                        />
                                                    </LightTooltip>
                                                </FormGroup>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Card
                                    sx={{
                                        borderRadius: "20px",
                                        backgroundColor: "#F8F9F8",
                                        boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                    }}
                                >
                                    <CardContent sx={{ p: "24px 24px 16px !important" }}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} lg={4}>
                                                <AccountCircle className={classes.avatarImage} />
                                                <Typography variant="h2" color="primary" align="center" sx={{ pt: 1 }}>
                                                    {`${t("miscellaneous.contact")} 2`}
                                                </Typography>
                                                <LightTooltip title={t("recurrent.clear")}>
                                                    <IconButton
                                                        aria-label="clear"
                                                        color="primary"
                                                        onClick={(event) => clearTeamStaff(event, "teamCompany2")}
                                                        sx={{
                                                            display: "block",
                                                            m: "auto",
                                                        }}
                                                    >
                                                        <ClearAllIcon />
                                                    </IconButton>
                                                </LightTooltip>
                                            </Grid>
                                            <Grid item xs={12} lg={8}>
                                                <TextField
                                                    fullWidth
                                                    required={isRequired2}
                                                    label={t("miscellaneous.name")}
                                                    name="contact_name"
                                                    onChange={(event) => handleChangeTeamStaff(event, "teamCompany2")}
                                                    value={props.valueStaff.teamCompany2.contact_name}
                                                    variant="outlined"
                                                    error={
                                                        isRequired2 &&
                                                        props.isError3 &&
                                                        props.valueStaff.teamCompany2.contact_name.trim() === ""
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                <TextField
                                                    fullWidth
                                                    required={isRequired2}
                                                    label={t("miscellaneous.email")}
                                                    name="contact_email"
                                                    onChange={(event) => handleChangeTeamStaff(event, "teamCompany2")}
                                                    value={props.valueStaff.teamCompany2.contact_email}
                                                    variant="outlined"
                                                    error={
                                                        isRequired2 &&
                                                        props.isError3 &&
                                                        props.valueStaff.teamCompany2.contact_email.trim() === ""
                                                            ? true
                                                            : isRequired2 &&
                                                              props.isError3 &&
                                                              !/.+@.+\..+/.test(
                                                                  props.valueStaff.teamCompany2.contact_email.trim()
                                                              )
                                                            ? true
                                                            : false
                                                    }
                                                    sx={{ mt: 2 }}
                                                />
                                                <TextField
                                                    fullWidth
                                                    required={isRequired2}
                                                    label={t("miscellaneous.role")}
                                                    name="contact_role"
                                                    onChange={(event) => handleChangeTeamStaff(event, "teamCompany2")}
                                                    value={props.valueStaff.teamCompany2.contact_role}
                                                    variant="outlined"
                                                    error={
                                                        isRequired2 &&
                                                        props.isError3 &&
                                                        props.valueStaff.teamCompany2.contact_role.trim() === ""
                                                            ? true
                                                            : false
                                                    }
                                                    sx={{ mt: 2 }}
                                                />
                                                <FormGroup
                                                    sx={{
                                                        mt: 2,
                                                        display: "block",
                                                    }}
                                                >
                                                    <FormLabel id="permissions-checkboxes-group-label">
                                                        <Typography variant="h4" color="primary" sx={{ pb: 1 }}>
                                                            {t("miscellaneous.permissions")}:
                                                        </Typography>
                                                    </FormLabel>
                                                    <LightTooltip
                                                        title={t("services.toolRequirements")}
                                                        aria-label="req"
                                                    >
                                                        <Checkbox
                                                            icon={<AssignmentTurnedInOutlinedIcon />}
                                                            checkedIcon={<AssignmentTurnedInIcon />}
                                                            checked={props.valueStaff.teamCompany2.contact_requirements}
                                                            onChange={(event) =>
                                                                handleChangeCheck(event, 3, "teamCompany2")
                                                            }
                                                            sx={{
                                                                ml: {
                                                                    xs: 0,
                                                                    lg: 0,
                                                                    xl: 0,
                                                                },
                                                                mr: {
                                                                    xs: 1,
                                                                    lg: 0,
                                                                    xl: 1,
                                                                },
                                                            }}
                                                        />
                                                    </LightTooltip>
                                                    <LightTooltip
                                                        title={t("services.toolAccountingClosures")}
                                                        aria-label="cierres"
                                                    >
                                                        <Checkbox
                                                            icon={<LockOutlinedIcon />}
                                                            checkedIcon={<LockIcon />}
                                                            checked={props.valueStaff.teamCompany2.contact_closure}
                                                            onChange={(event) =>
                                                                handleChangeCheck(event, 0, "teamCompany2")
                                                            }
                                                            sx={{
                                                                ml: {
                                                                    xs: 1,
                                                                    lg: 0,
                                                                    xl: 1,
                                                                },
                                                                mr: {
                                                                    xs: 1,
                                                                    lg: 0,
                                                                    xl: 1,
                                                                },
                                                            }}
                                                        />
                                                    </LightTooltip>
                                                    <LightTooltip title={t("services.toolStatus")} aria-label="status">
                                                        <Checkbox
                                                            icon={<InsertChartOutlinedIcon />}
                                                            checkedIcon={<InsertChartIcon />}
                                                            checked={props.valueStaff.teamCompany2.contact_status}
                                                            onChange={(event) =>
                                                                handleChangeCheck(event, 1, "teamCompany2")
                                                            }
                                                            sx={{
                                                                ml: {
                                                                    xs: 1,
                                                                    lg: 0,
                                                                    xl: 1,
                                                                },
                                                                mr: {
                                                                    xs: 1,
                                                                    lg: 0,
                                                                    xl: 1,
                                                                },
                                                            }}
                                                        />
                                                    </LightTooltip>
                                                    <LightTooltip
                                                        title={t("services.toolInvoicing")}
                                                        aria-label="invoicing"
                                                    >
                                                        <Checkbox
                                                            icon={<CreditCardOutlinedIcon />}
                                                            checkedIcon={
                                                                <CreditCardIcon className="icon-normal-size" />
                                                            }
                                                            checked={props.valueStaff.teamCompany2.contact_invoices}
                                                            onChange={(event) =>
                                                                handleChangeCheck(event, 4, "teamCompany2")
                                                            }
                                                            sx={{
                                                                ml: {
                                                                    xs: 1,
                                                                    lg: 0,
                                                                    xl: 1,
                                                                },
                                                                mr: {
                                                                    xs: 1,
                                                                    lg: 0,
                                                                    xl: 1,
                                                                },
                                                            }}
                                                        />
                                                    </LightTooltip>
                                                </FormGroup>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Card
                                    sx={{
                                        borderRadius: "20px",
                                        backgroundColor: "#F8F9F8",
                                        boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                    }}
                                >
                                    <CardContent sx={{ p: "24px 24px 16px !important" }}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} lg={4}>
                                                <AccountCircle className={classes.avatarImage} />
                                                <Typography variant="h2" color="primary" align="center" sx={{ pt: 1 }}>
                                                    {`${t("miscellaneous.contact")} 3`}
                                                </Typography>
                                                <LightTooltip title={t("recurrent.clear")}>
                                                    <IconButton
                                                        aria-label="clear"
                                                        color="primary"
                                                        onClick={(event) => clearTeamStaff(event, "teamCompany3")}
                                                        sx={{
                                                            display: "block",
                                                            m: "auto",
                                                        }}
                                                    >
                                                        <ClearAllIcon />
                                                    </IconButton>
                                                </LightTooltip>
                                            </Grid>
                                            <Grid item xs={12} lg={8}>
                                                <TextField
                                                    fullWidth
                                                    required={isRequired3}
                                                    label={t("miscellaneous.name")}
                                                    name="contact_name"
                                                    onChange={(event) => handleChangeTeamStaff(event, "teamCompany3")}
                                                    value={props.valueStaff.teamCompany3.contact_name}
                                                    variant="outlined"
                                                    error={
                                                        isRequired3 &&
                                                        props.isError3 &&
                                                        props.valueStaff.teamCompany3.contact_name.trim() === ""
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                <TextField
                                                    fullWidth
                                                    required={isRequired3}
                                                    label={t("miscellaneous.email")}
                                                    name="contact_email"
                                                    onChange={(event) => handleChangeTeamStaff(event, "teamCompany3")}
                                                    value={props.valueStaff.teamCompany3.contact_email}
                                                    variant="outlined"
                                                    error={
                                                        isRequired3 &&
                                                        props.isError3 &&
                                                        props.valueStaff.teamCompany3.contact_email.trim() === ""
                                                            ? true
                                                            : isRequired3 &&
                                                              props.isError3 &&
                                                              !/.+@.+\..+/.test(
                                                                  props.valueStaff.teamCompany3.contact_email.trim()
                                                              )
                                                            ? true
                                                            : false
                                                    }
                                                    sx={{ mt: 2 }}
                                                />
                                                <TextField
                                                    fullWidth
                                                    required={isRequired3}
                                                    label={t("miscellaneous.role")}
                                                    name="contact_role"
                                                    onChange={(event) => handleChangeTeamStaff(event, "teamCompany3")}
                                                    value={props.valueStaff.teamCompany3.contact_role}
                                                    variant="outlined"
                                                    error={
                                                        isRequired3 &&
                                                        props.isError3 &&
                                                        props.valueStaff.teamCompany3.contact_role.trim() === ""
                                                            ? true
                                                            : false
                                                    }
                                                    sx={{ mt: 2 }}
                                                />
                                                <FormGroup
                                                    sx={{
                                                        mt: 2,
                                                        display: "block",
                                                    }}
                                                >
                                                    <FormLabel id="permissions-checkboxes-group-label">
                                                        <Typography variant="h4" color="primary" sx={{ pb: 1 }}>
                                                            {t("miscellaneous.permissions")}:
                                                        </Typography>
                                                    </FormLabel>
                                                    <LightTooltip
                                                        title={t("services.toolRequirements")}
                                                        aria-label="req"
                                                    >
                                                        <Checkbox
                                                            icon={<AssignmentTurnedInOutlinedIcon />}
                                                            checkedIcon={<AssignmentTurnedInIcon />}
                                                            checked={props.valueStaff.teamCompany3.contact_requirements}
                                                            onChange={(event) =>
                                                                handleChangeCheck(event, 3, "teamCompany3")
                                                            }
                                                            sx={{
                                                                ml: {
                                                                    xs: 0,
                                                                    lg: 0,
                                                                    xl: 0,
                                                                },
                                                                mr: {
                                                                    xs: 1,
                                                                    lg: 0,
                                                                    xl: 1,
                                                                },
                                                            }}
                                                        />
                                                    </LightTooltip>
                                                    <LightTooltip
                                                        title={t("services.toolAccountingClosures")}
                                                        aria-label="cierres"
                                                    >
                                                        <Checkbox
                                                            icon={<LockOutlinedIcon />}
                                                            checkedIcon={<LockIcon />}
                                                            checked={props.valueStaff.teamCompany3.contact_closure}
                                                            onChange={(event) =>
                                                                handleChangeCheck(event, 0, "teamCompany3")
                                                            }
                                                            sx={{
                                                                ml: {
                                                                    xs: 1,
                                                                    lg: 0,
                                                                    xl: 1,
                                                                },
                                                                mr: {
                                                                    xs: 1,
                                                                    lg: 0,
                                                                    xl: 1,
                                                                },
                                                            }}
                                                        />
                                                    </LightTooltip>
                                                    <LightTooltip title={t("services.toolStatus")} aria-label="status">
                                                        <Checkbox
                                                            icon={<InsertChartOutlinedIcon />}
                                                            checkedIcon={<InsertChartIcon />}
                                                            checked={props.valueStaff.teamCompany3.contact_status}
                                                            onChange={(event) =>
                                                                handleChangeCheck(event, 1, "teamCompany3")
                                                            }
                                                            sx={{
                                                                ml: {
                                                                    xs: 1,
                                                                    lg: 0,
                                                                    xl: 1,
                                                                },
                                                                mr: {
                                                                    xs: 1,
                                                                    lg: 0,
                                                                    xl: 1,
                                                                },
                                                            }}
                                                        />
                                                    </LightTooltip>
                                                    <LightTooltip
                                                        title={t("services.toolInvoicing")}
                                                        aria-label="invoicing"
                                                    >
                                                        <Checkbox
                                                            icon={<CreditCardOutlinedIcon />}
                                                            checkedIcon={
                                                                <CreditCardIcon className="icon-normal-size" />
                                                            }
                                                            checked={props.valueStaff.teamCompany3.contact_invoices}
                                                            onChange={(event) =>
                                                                handleChangeCheck(event, 4, "teamCompany3")
                                                            }
                                                            sx={{
                                                                ml: {
                                                                    xs: 1,
                                                                    lg: 0,
                                                                    xl: 1,
                                                                },
                                                                mr: {
                                                                    xs: 1,
                                                                    lg: 0,
                                                                    xl: 1,
                                                                },
                                                            }}
                                                        />
                                                    </LightTooltip>
                                                </FormGroup>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Card
                                    sx={{
                                        borderRadius: "20px",
                                        backgroundColor: "#F8F9F8",
                                        boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                    }}
                                >
                                    <CardContent sx={{ p: "24px 24px 16px !important" }}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} lg={4}>
                                                <AccountCircle className={classes.avatarImage} />
                                                <Typography variant="h2" color="primary" align="center" sx={{ pt: 1 }}>
                                                    {`${t("miscellaneous.contact")} 4`}
                                                </Typography>
                                                <LightTooltip title={t("recurrent.clear")}>
                                                    <IconButton
                                                        aria-label="clear"
                                                        color="primary"
                                                        onClick={(event) => clearTeamStaff(event, "teamCompany4")}
                                                        sx={{
                                                            display: "block",
                                                            m: "auto",
                                                        }}
                                                    >
                                                        <ClearAllIcon />
                                                    </IconButton>
                                                </LightTooltip>
                                            </Grid>
                                            <Grid item xs={12} lg={8}>
                                                <TextField
                                                    fullWidth
                                                    required={isRequired4}
                                                    label={t("miscellaneous.name")}
                                                    name="contact_name"
                                                    onChange={(event) => handleChangeTeamStaff(event, "teamCompany4")}
                                                    value={props.valueStaff.teamCompany4.contact_name}
                                                    variant="outlined"
                                                    error={
                                                        isRequired4 &&
                                                        props.isError3 &&
                                                        props.valueStaff.teamCompany4.contact_name.trim() === ""
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                <TextField
                                                    fullWidth
                                                    required={isRequired4}
                                                    label={t("miscellaneous.email")}
                                                    name="contact_email"
                                                    onChange={(event) => handleChangeTeamStaff(event, "teamCompany4")}
                                                    value={props.valueStaff.teamCompany4.contact_email}
                                                    variant="outlined"
                                                    error={
                                                        isRequired4 &&
                                                        props.isError3 &&
                                                        props.valueStaff.teamCompany4.contact_email.trim() === ""
                                                            ? true
                                                            : isRequired4 &&
                                                              props.isError3 &&
                                                              !/.+@.+\..+/.test(
                                                                  props.valueStaff.teamCompany4.contact_email.trim()
                                                              )
                                                            ? true
                                                            : false
                                                    }
                                                    sx={{ mt: 2 }}
                                                />
                                                <TextField
                                                    fullWidth
                                                    required={isRequired4}
                                                    label={t("miscellaneous.role")}
                                                    name="contact_role"
                                                    onChange={(event) => handleChangeTeamStaff(event, "teamCompany4")}
                                                    value={props.valueStaff.teamCompany4.contact_role}
                                                    variant="outlined"
                                                    error={
                                                        isRequired4 &&
                                                        props.isError3 &&
                                                        props.valueStaff.teamCompany4.contact_role.trim() === ""
                                                            ? true
                                                            : false
                                                    }
                                                    sx={{ mt: 2 }}
                                                />
                                                <FormGroup
                                                    sx={{
                                                        mt: 2,
                                                        display: "block",
                                                    }}
                                                >
                                                    <FormLabel id="permissions-checkboxes-group-label">
                                                        <Typography variant="h4" color="primary" sx={{ pb: 1 }}>
                                                            {t("miscellaneous.permissions")}:
                                                        </Typography>
                                                    </FormLabel>
                                                    <LightTooltip
                                                        title={t("services.toolRequirements")}
                                                        aria-label="req"
                                                    >
                                                        <Checkbox
                                                            icon={<AssignmentTurnedInOutlinedIcon />}
                                                            checkedIcon={<AssignmentTurnedInIcon />}
                                                            checked={props.valueStaff.teamCompany4.contact_requirements}
                                                            onChange={(event) =>
                                                                handleChangeCheck(event, 3, "teamCompany4")
                                                            }
                                                            sx={{
                                                                ml: {
                                                                    xs: 0,
                                                                    lg: 0,
                                                                    xl: 0,
                                                                },
                                                                mr: {
                                                                    xs: 1,
                                                                    lg: 0,
                                                                    xl: 1,
                                                                },
                                                            }}
                                                        />
                                                    </LightTooltip>
                                                    <LightTooltip
                                                        title={t("services.toolAccountingClosures")}
                                                        aria-label="cierres"
                                                    >
                                                        <Checkbox
                                                            icon={<LockOutlinedIcon />}
                                                            checkedIcon={<LockIcon />}
                                                            checked={props.valueStaff.teamCompany4.contact_closure}
                                                            onChange={(event) =>
                                                                handleChangeCheck(event, 0, "teamCompany4")
                                                            }
                                                            sx={{
                                                                ml: {
                                                                    xs: 1,
                                                                    lg: 0,
                                                                    xl: 1,
                                                                },
                                                                mr: {
                                                                    xs: 1,
                                                                    lg: 0,
                                                                    xl: 1,
                                                                },
                                                            }}
                                                        />
                                                    </LightTooltip>
                                                    <LightTooltip title={t("services.toolStatus")} aria-label="status">
                                                        <Checkbox
                                                            icon={<InsertChartOutlinedIcon />}
                                                            checkedIcon={<InsertChartIcon />}
                                                            checked={props.valueStaff.teamCompany4.contact_status}
                                                            onChange={(event) =>
                                                                handleChangeCheck(event, 1, "teamCompany4")
                                                            }
                                                            sx={{
                                                                ml: {
                                                                    xs: 1,
                                                                    lg: 0,
                                                                    xl: 1,
                                                                },
                                                                mr: {
                                                                    xs: 1,
                                                                    lg: 0,
                                                                    xl: 1,
                                                                },
                                                            }}
                                                        />
                                                    </LightTooltip>
                                                    <LightTooltip
                                                        title={t("services.toolInvoicing")}
                                                        aria-label="invoicing"
                                                    >
                                                        <Checkbox
                                                            icon={<CreditCardOutlinedIcon />}
                                                            checkedIcon={
                                                                <CreditCardIcon className="icon-normal-size" />
                                                            }
                                                            checked={props.valueStaff.teamCompany4.contact_invoices}
                                                            onChange={(event) =>
                                                                handleChangeCheck(event, 4, "teamCompany4")
                                                            }
                                                            sx={{
                                                                ml: {
                                                                    xs: 1,
                                                                    lg: 0,
                                                                    xl: 1,
                                                                },
                                                                mr: {
                                                                    xs: 1,
                                                                    lg: 0,
                                                                    xl: 1,
                                                                },
                                                            }}
                                                        />
                                                    </LightTooltip>
                                                </FormGroup>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            )}
            {props.activeStep === 4 && (
                <Card elevation={0}>
                    <CardHeaderGCA
                        setValueStaff={props.setValueStaff}
                        valueStaff={props.valueStaff}
                        isReq={props.isReq}
                    />
                    <Divider />
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item lg={4} sm={6} xs={12}>
                                <FormControl
                                    fullWidth
                                    required
                                    error={props.isError4 && props.valueStaff.manager === "" ? true : false}
                                >
                                    <InputLabel id="manager-simple-select-label">
                                        {t("miscellaneous.manager")}
                                    </InputLabel>
                                    <Select
                                        name="manager"
                                        labelId="manager-simple-select-label"
                                        label={t("miscellaneous.manager")}
                                        IconComponent={KeyboardArrowDownIcon}
                                        value={props.valueStaff.manager}
                                        onChange={handleChangeStaff}
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    mt: 0.7,
                                                    borderRadius: 2,
                                                    boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                },
                                            },
                                        }}
                                    >
                                        {Staff.manager.map((option) => (
                                            <MenuItem value={option.name_es}>
                                                {moment.locale() === "en" ? option.name_en : option.name_es}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item lg={4} sm={6} xs={12}>
                                <FormControl
                                    fullWidth
                                    required
                                    error={props.isError4 && props.valueStaff.supervisor === "" ? true : false}
                                >
                                    <InputLabel id="supervisor-simple-select-label">
                                        {t("miscellaneous.supervisor")}
                                    </InputLabel>
                                    <Select
                                        name="supervisor"
                                        labelId="supervisor-simple-select-label"
                                        label={t("miscellaneous.supervisor")}
                                        IconComponent={KeyboardArrowDownIcon}
                                        value={props.valueStaff.supervisor}
                                        onChange={handleChangeStaff}
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    mt: 0.7,
                                                    borderRadius: 2,
                                                    boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                },
                                            },
                                        }}
                                    >
                                        {Staff.supervisor.map((option) => (
                                            <MenuItem value={option.name_es}>
                                                {moment.locale() === "en" ? option.name_en : option.name_es}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item lg={4} sm={6} xs={12}>
                                <FormControl
                                    fullWidth
                                    required
                                    error={props.isError4 && props.valueStaff.advisor === "" ? true : false}
                                >
                                    <InputLabel id="advisor-simple-select-label">
                                        {t("miscellaneous.advisor")}
                                    </InputLabel>
                                    <Select
                                        name="advisor"
                                        labelId="advisor-simple-select-label"
                                        label={t("miscellaneous.advisor")}
                                        IconComponent={KeyboardArrowDownIcon}
                                        value={props.valueStaff.advisor}
                                        onChange={handleChangeStaff}
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    mt: 0.7,
                                                    borderRadius: 2,
                                                    boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                },
                                            },
                                        }}
                                    >
                                        {Staff.advisor.map((option) => (
                                            <MenuItem value={option.name_es}>
                                                {moment.locale() === "en" ? option.name_en : option.name_es}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item lg={4} sm={6} xs={12}>
                                <FormControl
                                    fullWidth
                                    required
                                    error={props.isError4 && props.valueStaff.analyst === "" ? true : false}
                                >
                                    <InputLabel id="analyst-simple-select-label">
                                        {t("miscellaneous.analyst")}
                                    </InputLabel>
                                    <Select
                                        name="analyst"
                                        labelId="analyst-simple-select-label"
                                        label={t("miscellaneous.analyst")}
                                        IconComponent={KeyboardArrowDownIcon}
                                        value={props.valueStaff.analyst}
                                        onChange={handleChangeStaff}
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    mt: 0.7,
                                                    borderRadius: 2,
                                                    boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                },
                                            },
                                        }}
                                    >
                                        {Staff.analyst.map((option) => (
                                            <MenuItem value={option.name_es}>
                                                {moment.locale() === "en" ? option.name_en : option.name_es}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item lg={4} sm={6} xs={12}>
                                <FormControl
                                    fullWidth
                                    required
                                    error={props.isError4 && props.valueStaff.backupAnalyst === "" ? true : false}
                                >
                                    <InputLabel id="backup-analyst-simple-select-label" shrink>
                                        {t("miscellaneous.backupAnalyst")}
                                    </InputLabel>
                                    <Select
                                        name="backupAnalyst"
                                        labelId="backup-analyst-simple-select-label"
                                        label={t("miscellaneous.backupAnalyst")}
                                        IconComponent={KeyboardArrowDownIcon}
                                        value={props.valueStaff.backupAnalyst}
                                        onChange={handleChangeStaff}
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    mt: 0.7,
                                                    borderRadius: 2,
                                                    boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                },
                                            },
                                        }}
                                    >
                                        {Staff.backupAnalyst.map((option) => (
                                            <MenuItem value={option.name_es}>
                                                {moment.locale() === "en" ? option.name_en : option.name_es}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            )}
        </StyledContainer>
    );
}
