/** React imports */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

/** Images */

/** MUI icons */

/** MUI imports */
import { Box, Typography, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";

import { Footer } from "./";

/** Global variables */
const PREFIX = "TermsConditions";

const classes = {
    body2: `${PREFIX}-body2`,
    footer: `${PREFIX}-footer`,
    logoGCAF: `${PREFIX}-logoGCAF`,
    logoGCAF2: `${PREFIX}-logoGCAF2`,
    logoGCAF3: `${PREFIX}-logoGCAF3`,
    serviceName: `${PREFIX}-serviceName`,
    serviceBody: `${PREFIX}-serviceBody`,
    ListText: `${PREFIX}-ListText`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.logoGCAF}`]: {
        display: "block",
        [theme.breakpoints.down("md")]: {
            marginRight: 20,
            width: 80,
        },
        [theme.breakpoints.up("md")]: {
            marginRight: 45,
            width: 80,
        },
    },
    [`& .${classes.logoGCAF2}`]: {
        fontWeight: 700,
        [theme.breakpoints.down("md")]: {
            paddingLeft: 25,
            fontSize: 14,
        },
        [theme.breakpoints.up("md")]: {
            paddingLeft: 50,
        },
    },
    [`& .${classes.logoGCAF3}`]: {
        color: "white",
        [theme.breakpoints.down("md")]: {
            fontSize: 14,
        },
    },

    [`& .${classes.footer}`]: {
        backgroundColor: "#031851",
        padding: 6,
    },

    [`& .${classes.serviceName}`]: {
        fontSize: 22,
    },

    [`& .${classes.body2}`]: {
        backgroundColor: "white",
        marginTop: 15,
        [theme.breakpoints.down("md")]: {
            padding: 30,
        },
        [theme.breakpoints.up("md")]: {
            padding: 60,
        },
    },

    [`& .${classes.serviceBody}`]: {
        marginTop: 40,
    },

    [`& .${classes.ListText}`]: {
        "& li": {
            fontSize: 12,
            paddingBottom: 10,
            textAlign: "justify",
        },
        "& li::marker": {
            fontWeight: 700,
        },
    },
}));

export const TermsConditions = (props) => {
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    const { proposalDetails, mode, market } = props;
    const [services, setServices] = useState({});

    useEffect(() => {
        let services = {};

        if (mode === "preview") {
            proposalDetails.forEach((item) => {
                if (item.name === "Accountant") {
                    services.isAccountant = true;
                } else if (item.name === "Bookkeeper") {
                    services.isBookkeeper = true;
                } else if (item.name === "Controller") {
                    services.isController = true;
                } else if (item.name === "CFO") {
                    services.isCFO = true;
                } else if (item.name.includes("Software")) {
                    services.isSoftware = true;
                }
            });
        } else {
            proposalDetails.services.forEach((item) => {
                if (item.service_name === "Accountant") {
                    services.isAccountant = true;
                } else if (item.service_name === "Bookkeeper") {
                    services.isBookkeeper = true;
                } else if (item.service_name === "Controller") {
                    services.isController = true;
                } else if (item.service_name === "CFO") {
                    services.isCFO = true;
                } else if (item.service_name.includes("Software")) {
                    services.isSoftware = true;
                }
            });
        }

        setServices(services);
    }, [proposalDetails, mode, t]);

    return (
        <StyledContainer>
            <Box className={classes.body2}>
                <Typography variant="h5" className={classes.serviceName}>
                    {services.isBookkeeper
                        ? t("proposals.termCondB")
                        : t("proposals.termCond")}
                </Typography>
                <Box className={classes.serviceBody}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h5">General</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <ol className={classes.ListText}>
                                <li>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={3}>
                                            <b>{services.isBookkeeper
                                                ? t("proposals.invoicesB")
                                                : t("proposals.invoices")}</b>
                                        </Grid>
                                        <Grid item xs={12} md={9}>
                                            {services.isBookkeeper
                                                ? t("proposals.invoicesBookDetB")
                                                : t("proposals.invoicesDet")}
                                        </Grid>
                                    </Grid>
                                </li>
                                {services.isBookkeeper && props.includeToolbox && (
                                    <li>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={3}>
                                                <b>{t("proposals.freqInvB")}</b>
                                            </Grid>
                                            <Grid item xs={12} md={9}>
                                                {t("proposals.freqInvDetB")}
                                            </Grid>
                                        </Grid>
                                    </li>
                                )}
                                <li>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={3}>
                                            <b>{services.isBookkeeper
                                                ? t("proposals.oldInvB")
                                                : t("proposals.oldInv")}</b>
                                        </Grid>
                                        <Grid item xs={12} md={9}>
                                            {services.isBookkeeper
                                                ? t("proposals.overduInvDetB")
                                                : t("proposals.oldInvDet")}
                                        </Grid>
                                    </Grid>
                                </li>
                                <li>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={3}>
                                            <b>{services.isBookkeeper
                                                ? t("proposals.creditNoteB")
                                                : t("proposals.creditNote")}</b>
                                        </Grid>
                                        <Grid item xs={12} md={9}>
                                            {services.isBookkeeper
                                                ? t("proposals.creditNotesDetB")
                                                : t("proposals.creditNoteDet")}
                                        </Grid>
                                    </Grid>
                                </li>
                                {services.isBookkeeper && (
                                    <li>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={3}>
                                                <b>{t("proposals.feesExpressBookB")}</b>
                                            </Grid>
                                            <Grid item xs={12} md={9}>
                                                {t("proposals.feeExpressBookDetB")}
                                            </Grid>
                                        </Grid>
                                    </li>
                                )}
                                {services.isBookkeeper && (
                                    <li>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={3}>
                                                <b>{t("proposals.onSiteB")}</b>
                                            </Grid>
                                            <Grid item xs={12} md={9}>
                                                {t("proposals.onSiteDetB")}
                                            </Grid>
                                        </Grid>
                                    </li>
                                )}
                                {!services.isBookkeeper && (
                                    <>
                                        <li>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} md={3} sx={{ textAlign: "left" }}>
                                                    <b>{t("proposals.requestExpress")}</b>
                                                </Grid>
                                                <Grid item xs={12} md={9}>
                                                    {t("proposals.requestExpressDet")}
                                                </Grid>
                                            </Grid>
                                        </li>
                                        <li>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} md={3}>
                                                    <b>{t("proposals.presentServices")}</b>
                                                </Grid>
                                                <Grid item xs={12} md={9}>
                                                    {t("proposals.presentServicesDet")}
                                                </Grid>
                                            </Grid>
                                        </li>
                                        <li>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} md={3}>
                                                    <b>{t("proposals.customerSupp")}</b>
                                                </Grid>
                                                <Grid item xs={12} md={9}>
                                                    <Typography
                                                        component="span"
                                                        sx={{ fontSize: 12 }}
                                                        dangerouslySetInnerHTML={{
                                                            __html: t("proposals.custoDet"),
                                                        }}
                                                    ></Typography>
                                                </Grid>
                                            </Grid>
                                        </li>
                                        <li>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} md={3}>
                                                    <b>{t("proposals.cancel")}</b>
                                                </Grid>
                                                <Grid item xs={12} md={9}>
                                                    <Typography
                                                        component="span"
                                                        sx={{ fontSize: 12 }}
                                                        dangerouslySetInnerHTML={{
                                                            __html: t("proposals.cancelDet"),
                                                        }}
                                                    ></Typography>
                                                </Grid>
                                            </Grid>
                                        </li>
                                        <li>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} md={3} sx={{ textAlign: "left" }}>
                                                    <b>{t("proposals.reqCan")}</b>
                                                </Grid>
                                                <Grid item xs={12} md={9}>
                                                    {t("proposals.reqCanDet")}
                                                </Grid>
                                            </Grid>
                                        </li>
                                    </>
                                )}
                            </ol>
                        </Grid>
                        {(services.isAccountant || services.isController || services.isCFO) && (
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant="h5">Accountant, Controller & CFO</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <ol className={classes.ListText}>
                                            <li>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} md={3} sx={{ textAlign: "left" }}>
                                                        <b>{t("proposals.finannState")}</b>
                                                    </Grid>
                                                    <Grid item xs={12} md={9}>
                                                        {
                                                            market === "US" ?
                                                                t("proposals.financialStateUS") :
                                                                market === "VE" ?
                                                                    t("proposals.financialStateVE") :
                                                                    t("proposals.financialStateLAGB")
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </li>
                                            <li>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} md={3} sx={{ textAlign: "left" }}>
                                                        <b>{t("proposals.susModel")}</b>
                                                    </Grid>
                                                    <Grid item xs={12} md={9}>
                                                        {t("proposals.susModelDet")}
                                                    </Grid>
                                                </Grid>
                                            </li>
                                            <li>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} md={3} sx={{ textAlign: "left" }}>
                                                        <b>{t("proposals.egreCalc")}</b>
                                                    </Grid>
                                                    <Grid item xs={12} md={9}>
                                                        {t("proposals.egreCalcDet")}
                                                    </Grid>
                                                </Grid>
                                            </li>
                                            <li>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} md={3} sx={{ textAlign: "left" }}>
                                                        <b>{t("proposals.honServReq")}</b>
                                                    </Grid>
                                                    <Grid item xs={12} md={9}>
                                                        {t("proposals.honServReqDet")}
                                                    </Grid>
                                                </Grid>
                                            </li>
                                            <li>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} md={3} sx={{ textAlign: "left" }}>
                                                        <b>{t("proposals.honorOnboard")}</b>
                                                    </Grid>
                                                    <Grid item xs={12} md={9}>
                                                        {t("proposals.honorOnboardDet")}
                                                    </Grid>
                                                </Grid>
                                            </li>
                                            <li>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} md={3} sx={{ textAlign: "left" }}>
                                                        <b>{t("proposals.requirements")}</b>
                                                    </Grid>
                                                    <Grid item xs={12} md={9}>
                                                        {t("proposals.requiremetnsDet")}
                                                    </Grid>
                                                </Grid>
                                            </li>
                                            <li>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} md={3} sx={{ textAlign: "left" }}>
                                                        <b>{t("proposals.scheduleRecuServ")}</b>
                                                    </Grid>
                                                    <Grid item xs={12} md={9}>
                                                        {t("proposals.scheduleRecuServDet")}
                                                    </Grid>
                                                </Grid>
                                            </li>
                                            <li>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} md={3} sx={{ textAlign: "left" }}>
                                                        <b>{t("proposals.revHonorMen")}</b>
                                                    </Grid>
                                                    <Grid item xs={12} md={9}>
                                                        {t("proposals.revHonorMenDet")}
                                                    </Grid>
                                                </Grid>
                                            </li>
                                            <li>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} md={3} sx={{ textAlign: "left" }}>
                                                        <b>{t("proposals.honClosedPeriod")}</b>
                                                    </Grid>
                                                    <Grid item xs={12} md={9}>
                                                        {t("proposals.honClosedPeriodDet")}
                                                    </Grid>
                                                </Grid>
                                            </li>
                                            <li>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} md={3} sx={{ textAlign: "left" }}>
                                                        <b>{t("proposals.honorCatchClean")}</b>
                                                    </Grid>
                                                    <Grid item xs={12} md={9}>
                                                        {t("proposals.honorCatchCleanDet2")}
                                                    </Grid>
                                                </Grid>
                                            </li>
                                            <li>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} md={3} sx={{ textAlign: "left" }}>
                                                        <b>{t("proposals.cronoCatch")}</b>
                                                    </Grid>
                                                    <Grid item xs={12} md={9}>
                                                        {t("proposals.cronoCatchDet")}
                                                    </Grid>
                                                </Grid>
                                            </li>
                                            <li>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} md={3} sx={{ textAlign: "left" }}>
                                                        <b>{t("proposals.honorPerClose2")}</b>
                                                    </Grid>
                                                    <Grid item xs={12} md={9}>
                                                        {t("proposals.honorPerCloseDet2")}
                                                    </Grid>
                                                </Grid>
                                            </li>
                                            <li>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} md={3} sx={{ textAlign: "left" }}>
                                                        <b>{t("proposals.prepaidHonor")}</b>
                                                    </Grid>
                                                    <Grid item xs={12} md={9}>
                                                        {t("proposals.prepaidHonorDet")}
                                                    </Grid>
                                                </Grid>
                                            </li>
                                            <li>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} md={3} sx={{ textAlign: "left" }}>
                                                        <b>{t("proposals.settings")}</b>
                                                    </Grid>
                                                    <Grid item xs={12} md={9}>
                                                        {t("proposals.settingsDet")}
                                                    </Grid>
                                                </Grid>
                                            </li>
                                        </ol>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                        {services.isBookkeeper && (
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant="h5">Practice</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <ol className={classes.ListText}>
                                            {props.includeOnboarding && (
                                                <li>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} md={3} sx={{ textAlign: "left" }}>
                                                            <b>{t("proposals.practOnbB")}</b>
                                                        </Grid>
                                                        <Grid item xs={12} md={9}>
                                                            {t("proposals.practOnbDetB")}
                                                        </Grid>
                                                    </Grid>
                                                </li>
                                            )}
                                            {props.includeToolbox && (
                                                <li>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} md={3} sx={{ textAlign: "left" }}>
                                                            <b>{t("proposals.practToolB")}</b>
                                                        </Grid>
                                                        <Grid item xs={12} md={9}>
                                                            {t("proposals.practToolDetB")}
                                                        </Grid>
                                                    </Grid>
                                                </li>
                                            )}
                                            <li>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} md={3} sx={{ textAlign: "left" }}>
                                                        <b>{t("proposals.customerSuppB")}</b>
                                                    </Grid>
                                                    <Grid item xs={12} md={9}>
                                                        <Typography
                                                            component="span"
                                                            sx={{ fontSize: 12 }}
                                                            dangerouslySetInnerHTML={{
                                                                __html: t("proposals.custoDetB"),
                                                            }}
                                                        ></Typography>
                                                    </Grid>
                                                </Grid>
                                            </li>
                                        </ol>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="h5">SMBs</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <ol className={classes.ListText}>
                                            <li>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} md={3} sx={{ textAlign: "left" }}>
                                                        <b>{t("proposals.finannStateB")}</b>
                                                    </Grid>
                                                    <Grid item xs={12} md={9}>
                                                        {t("proposals.finannStateDetB")}
                                                    </Grid>
                                                </Grid>
                                            </li>
                                            <li>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} md={3} sx={{ textAlign: "left" }}>
                                                        <b>{t("proposals.susModelB")}</b>
                                                    </Grid>
                                                    <Grid item xs={12} md={9}>
                                                        {t("proposals.susModelDetB")}
                                                    </Grid>
                                                </Grid>
                                            </li>
                                            <li>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} md={3} sx={{ textAlign: "left" }}>
                                                        <b>{t("proposals.smbAccSoftB")}</b>
                                                    </Grid>
                                                    <Grid item xs={12} md={9}>
                                                        {t("proposals.smbAccSoftDetB")}
                                                    </Grid>
                                                </Grid>
                                            </li>
                                            <li>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} md={3} sx={{ textAlign: "left" }}>
                                                        <b>{t("proposals.egreCalcB")}</b>
                                                    </Grid>
                                                    <Grid item xs={12} md={9}>
                                                        {t("proposals.cashOutDetB")}
                                                    </Grid>
                                                </Grid>
                                            </li>
                                            <li>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} md={3} sx={{ textAlign: "left" }}>
                                                        <b>{t("proposals.recurringServFeesB")}</b>
                                                    </Grid>
                                                    <Grid item xs={12} md={9}>
                                                        {t("proposals.feeDetB")}
                                                    </Grid>
                                                </Grid>
                                            </li>
                                            {props.includeOnboarding && (
                                                <li>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} md={3} sx={{ textAlign: "left" }}>
                                                            <b>{t("proposals.honorOnboardB")}</b>
                                                        </Grid>
                                                        <Grid item xs={12} md={9}>
                                                            {t("proposals.honorOnboardDetBookB")}
                                                        </Grid>
                                                    </Grid>
                                                </li>
                                            )}
                                            <li>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} md={3} sx={{ textAlign: "left" }}>
                                                        <b>{t("proposals.manageReqB")}</b>
                                                    </Grid>
                                                    <Grid item xs={12} md={9}>
                                                        {t("proposals.manageReqDetBookB")}
                                                    </Grid>
                                                </Grid>
                                            </li>
                                            <li>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} md={3} sx={{ textAlign: "left" }}>
                                                        <b>{t("proposals.recServStandBookB")}</b>
                                                    </Grid>
                                                    <Grid item xs={12} md={9}>
                                                        {t("proposals.recServStandBookDetB")}
                                                    </Grid>
                                                </Grid>
                                            </li>
                                            <li>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} md={3} sx={{ textAlign: "left" }}>
                                                        <b>{t("proposals.recServFeesReviewBookB")}</b>
                                                    </Grid>
                                                    <Grid item xs={12} md={9}>
                                                        {t("proposals.revHonorMenDetB")}
                                                    </Grid>
                                                </Grid>
                                            </li>
                                            <li>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} md={3} sx={{ textAlign: "left" }}>
                                                        <b>{t("proposals.honorPerCloseB")}</b>
                                                    </Grid>
                                                    <Grid item xs={12} md={9}>
                                                        {t("proposals.honorPerCloseDetB")}
                                                    </Grid>
                                                </Grid>
                                            </li>
                                            <li>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} md={3} sx={{ textAlign: "left" }}>
                                                        <b>{t("proposals.honorCatchCleanB")}</b>
                                                    </Grid>
                                                    <Grid item xs={12} md={9}>
                                                        {t("proposals.honorCatchCleanDetB")}
                                                    </Grid>
                                                </Grid>
                                            </li>
                                            <li>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} md={3} sx={{ textAlign: "left" }}>
                                                        <b>{t("proposals.standarScheduleBookB")}</b>
                                                    </Grid>
                                                    <Grid item xs={12} md={9}>
                                                        {t("proposals.standarScheduleBookDetB")}
                                                    </Grid>
                                                </Grid>
                                            </li>
                                            <li>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} md={3} sx={{ textAlign: "left" }}>
                                                        <b>{t("proposals.oldPeriodHonorB")}</b>
                                                    </Grid>
                                                    <Grid item xs={12} md={9}>
                                                        {t("proposals.oldPeriodHonorDetB")}
                                                    </Grid>
                                                </Grid>
                                            </li>
                                            <li>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} md={3} sx={{ textAlign: "left" }}>
                                                        <b>{t("proposals.cancelB")}</b>
                                                    </Grid>
                                                    <Grid item xs={12} md={9}>
                                                        <Typography
                                                            component="span"
                                                            sx={{ fontSize: 12 }}
                                                            dangerouslySetInnerHTML={{
                                                                __html: t("proposals.cancelDetB"),
                                                            }}
                                                        ></Typography>
                                                    </Grid>
                                                </Grid>
                                            </li>
                                            <li>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} md={3} sx={{ textAlign: "left" }}>
                                                        <b>{t("proposals.reqCanB")}</b>
                                                    </Grid>
                                                    <Grid item xs={12} md={9}>
                                                        {t("proposals.reqCanDetB")}
                                                    </Grid>
                                                </Grid>
                                            </li>
                                        </ol>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                        {services.isSoftware &&
                            !services.isBookkeeper &&
                            !(services.isAccountant || services.isController || services.isCFO) && (
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography variant="h5">{t("proposals.softwareProposal")}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <ol className={classes.ListText}>
                                                <li>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} md={3} sx={{ textAlign: "left" }}>
                                                            <b>{t("proposals.susModel")}</b>
                                                        </Grid>
                                                        <Grid item xs={12} md={9}>
                                                            {t("proposals.susModelTerms")}
                                                        </Grid>
                                                    </Grid>
                                                </li>
                                                <li>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} md={3} sx={{ textAlign: "left" }}>
                                                            <b>{t("proposals.accSystem")}</b>
                                                        </Grid>
                                                        <Grid item xs={12} md={9}>
                                                            {t("proposals.accSystemDet")}
                                                        </Grid>
                                                    </Grid>
                                                </li>
                                                <li>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} md={3} sx={{ textAlign: "left" }}>
                                                            <b>{t("proposals.servHon")}</b>
                                                        </Grid>
                                                        <Grid item xs={12} md={9}>
                                                            {t("proposals.servHonDet")}
                                                        </Grid>
                                                    </Grid>
                                                </li>
                                                <li>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} md={3} sx={{ textAlign: "left" }}>
                                                            <b>{t("proposals.servHonSub")}</b>
                                                        </Grid>
                                                        <Grid item xs={12} md={9}>
                                                            {t("proposals.servHonSubDet")}
                                                        </Grid>
                                                    </Grid>
                                                </li>
                                                <li>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} md={3} sx={{ textAlign: "left" }}>
                                                            <b>{t("proposals.impSysTerms")}</b>
                                                        </Grid>
                                                        <Grid item xs={12} md={9}>
                                                            {t("proposals.impSysTermsDet")}
                                                            <br />
                                                            {t("proposals.impSysTermsDet1")}
                                                        </Grid>
                                                    </Grid>
                                                </li>
                                                <li>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} md={3} sx={{ textAlign: "left" }}>
                                                            <b>{t("proposals.depoWarrant")}</b>
                                                        </Grid>
                                                        <Grid item xs={12} md={9}>
                                                            {t("proposals.depoWarrantDet")}
                                                        </Grid>
                                                    </Grid>
                                                </li>
                                            </ol>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                    </Grid>
                </Box>
            </Box>
            <Footer />
            {((services.isSoftware &&
                (services.isAccountant || services.isController || services.isCFO))) && (
                    <>
                        <Box className={classes.body2}>
                            <Typography variant="h5" className={classes.serviceName}>
                                {t("proposals.termCond")}
                            </Typography>
                            <Box className={classes.serviceBody}>
                                <Grid container spacing={2}>
                                    {services.isSoftware &&
                                        (services.isBookkeeper ||
                                            services.isAccountant ||
                                            services.isController ||
                                            services.isCFO) && (
                                            <Grid item xs={12}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <Typography variant="h5">{t("proposals.softwareProposal")}</Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <ol className={classes.ListText}>
                                                            <li>
                                                                <Grid container spacing={2}>
                                                                    <Grid item xs={12} md={3} sx={{ textAlign: "left" }}>
                                                                        <b>{t("proposals.susModel")}</b>
                                                                    </Grid>
                                                                    <Grid item xs={12} md={9}>
                                                                        {t("proposals.susModelTerms")}
                                                                    </Grid>
                                                                </Grid>
                                                            </li>
                                                            <li>
                                                                <Grid container spacing={2}>
                                                                    <Grid item xs={12} md={3} sx={{ textAlign: "left" }}>
                                                                        <b>{t("proposals.accSystem")}</b>
                                                                    </Grid>
                                                                    <Grid item xs={12} md={9}>
                                                                        {t("proposals.accSystemDet")}
                                                                    </Grid>
                                                                </Grid>
                                                            </li>
                                                            <li>
                                                                <Grid container spacing={2}>
                                                                    <Grid item xs={12} md={3} sx={{ textAlign: "left" }}>
                                                                        <b>{t("proposals.servHon")}</b>
                                                                    </Grid>
                                                                    <Grid item xs={12} md={9}>
                                                                        {t("proposals.servHonDet")}
                                                                    </Grid>
                                                                </Grid>
                                                            </li>
                                                            <li>
                                                                <Grid container spacing={2}>
                                                                    <Grid item xs={12} md={3} sx={{ textAlign: "left" }}>
                                                                        <b>{t("proposals.servHonSub")}</b>
                                                                    </Grid>
                                                                    <Grid item xs={12} md={9}>
                                                                        {t("proposals.servHonSubDet")}
                                                                    </Grid>
                                                                </Grid>
                                                            </li>
                                                            <li>
                                                                <Grid container spacing={2}>
                                                                    <Grid item xs={12} md={3} sx={{ textAlign: "left" }}>
                                                                        <b>{t("proposals.impSysTerms")}</b>
                                                                    </Grid>
                                                                    <Grid item xs={12} md={9}>
                                                                        {t("proposals.impSysTermsDet")}
                                                                        <br />
                                                                        {t("proposals.impSysTermsDet1")}
                                                                    </Grid>
                                                                </Grid>
                                                            </li>
                                                            <li>
                                                                <Grid container spacing={2}>
                                                                    <Grid item xs={12} md={3} sx={{ textAlign: "left" }}>
                                                                        <b>{t("proposals.depoWarrant")}</b>
                                                                    </Grid>
                                                                    <Grid item xs={12} md={9}>
                                                                        {t("proposals.depoWarrantDet")}
                                                                    </Grid>
                                                                </Grid>
                                                            </li>
                                                        </ol>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )}
                                </Grid>
                            </Box>
                        </Box>
                        <Footer />
                    </>
                )
            }
        </StyledContainer >
    );
};
