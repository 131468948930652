const staff = {
    supervisor: [
        {
            name_es: "Deyanira Goncalves",
            name_en: "Deyanira Goncalves",
        },
        {
            name_es: "María Fernanda Barrientos",
            name_en: "María Fernanda Barrientos",
        },
        {
            name_es: "María Fernanda Cruz",
            name_en: "María Fernanda Cruz",
        },
        {
            name_es: "N/A",
            name_en: "N/A",
        },
        {
            name_es: "Por asignar",
            name_en: "Pending",
        },
    ],
    manager: [
        {
            name_es: "Deyanira Goncalves",
            name_en: "Deyanira Goncalves",
        },
        {
            name_es: "Geraldine Calles",
            name_en: "Geraldine Calles",
        },
        {
            name_es: "María Fernanda Cruz",
            name_en: "María Fernanda Cruz",
        },
        {
            name_es: "N/A",
            name_en: "N/A",
        },
        {
            name_es: "Por asignar",
            name_en: "Pending",
        },
    ],
    advisor: [
        {
            name_es: "Geraldine Calles",
            name_en: "Geraldine Calles",
        },
        {
            name_es: "N/A",
            name_en: "N/A",
        },
        {
            name_es: "Por asignar",
            name_en: "Pending",
        },
    ],
    analyst: [
        {
            name_es: "Christian Gil",
            name_en: "Christian Gil",
        },
        {
            name_es: "Iraixy Guevara",
            name_en: "Iraixy Guevara",
        },
        {
            name_es: "María Fernanda Barrientos",
            name_en: "María Fernanda Barrientos",
        },
        {
            name_es: "Yaritza Rosas",
            name_en: "Yaritza Rosas",
        },
        {
            name_es: "Soporte",
            name_en: "Support",
        },
        {
            name_es: "N/A",
            name_en: "N/A",
        },
        {
            name_es: "Por asignar",
            name_en: "Pending",
        },
    ],
    backupAnalyst: [
        {
            name_es: "Christian Gil",
            name_en: "Christian Gil",
        },
        {
            name_es: "Iraixy Guevara",
            name_en: "Iraixy Guevara",
        },
        {
            name_es: "María Fernanda Barrientos",
            name_en: "María Fernanda Barrientos",
        },
        {
            name_es: "Yaritza Rosas",
            name_en: "Yaritza Rosas",
        },
        {
            name_es: "Soporte",
            name_en: "Support",
        },
        {
            name_es: "N/A",
            name_en: "N/A",
        },
        {
            name_es: "Por asignar",
            name_en: "Pending",
        },
    ],
};

export default staff;
