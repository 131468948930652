/** React imports */
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';

/** MUI icons */
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloseIcon from '@mui/icons-material/Close';

/** MUI imports */
import { 
    Grid, PagingPanel, SearchPanel, Table, TableHeaderRow, TableSelection, Toolbar,
} from '@devexpress/dx-react-grid-material-ui';
import { 
    IntegratedFiltering, IntegratedPaging, PagingState, SearchState, SelectionState 
} from '@devexpress/dx-react-grid';
import { styled } from '@mui/material/styles';
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Skeleton,
} from '@mui/material';

/** Component styles */
const PREFIX = 'ModProspectDirectory';

const classes = {
    selectedRow: `${PREFIX}-selectedRow`,
    customRow: `${PREFIX}-customRow`,
    tableHeader: `${PREFIX}-tableHeader`,
    tableBody: `${PREFIX}-tableBody`,
    pager: `${PREFIX}-pager`,
    toolbarSearch: `${PREFIX}-toolbarSearch`,
    searchInput: `${PREFIX}-searchInput`,
};

const CustomDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(4),
    },
    '& .MuiDialogTitle-root': {
        backgroundColor: "#031851",
        fontSize: "18px",
        color: "#fff"
    },
    '& .MuiDialog-paper': {
        borderRadius: 8,
        boxShadow: '10px 10px 15px rgba(3,24,81,0.15)',
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(2),
    },

    [`& .${classes.selectedRow}`]: {
        backgroundColor: "#F4F4F4",
    },
    [`& .${classes.customRow}`]: {
        "&:hover": {
            backgroundColor: "rgba(47,49,144,0.1)",
        },
    },
    [`& .${classes.tableHeader}`]: {
        fontSize: "15px",
        fontWeight: 600,
        color: "#031851",
        borderBottom: "2px solid #031851",
    },
    [`& .${classes.tableBody}`]: {
        color: "#031851",
        cursor: 'pointer',
        fontSize: "14px",
        fontWeight: 400,
    },
    [`& .${classes.pager}`]: {
        "& .Pagination-button": {
            paddingTop: 0,
            paddingBottom: 0,
            color: "#031851",
            fontWeight: 600,
        },
        "& .Pagination-activeButton": {
            backgroundColor: "rgba(47,49,144,0.3)",
        },
    },
    [`& .${classes.toolbarSearch}`]: {
        border: 'none !important',
        padding: '0 0 5px !important',
    },
    [`& .${classes.searchInput}`]: {
        color: '#031851',
        '&:before': {
            borderBottomColor: '#031851',
        },
        '&:hover:before': {
            borderBottomColor: '#031851 !important',
        }
    },
}));

const CustomDialogTitle = ((props) => {
    const { children, onClose, ...other } = props;
    return (
    <DialogTitle {...other}>
        {children}
        {onClose ? (
            <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{
                position: 'absolute',
                right: 16,
                top: 10,
                color: '#fff',
              }}
            >
              <CloseIcon />
            </IconButton>
        ) : null}
    </DialogTitle>
  );
});

const CustomTableRow = (props) => {
    const {
        className,
        highlighted,
        selectByRowClick,
        onToggle,
        ...restProps
    } = props;

    return (
        <Table.Row
            {...restProps}
            className={
                { [classes.selectedRow]: highlighted, [classes.customRow]: true }
            }
            onClick={e => {
                if (!selectByRowClick) return;
                e.stopPropagation();
                onToggle();
            }}
        />
    );
};

const tableHeaderCell = ({ ...props }) => (
    <TableHeaderRow.Cell
        {...props}
        className={classes.tableHeader}
    />
);

const TableCellComponent = ({ ...props }) => (
    <Table.Cell
        {...props}
        className={classes.tableBody}
    />
);

const PagingPanelContainer = ({ ...props }) => (
    <PagingPanel.Container
        {...props}
        className={classes.pager}
    />
);

const inputComponent = ({ ...props }) => (
    <SearchPanel.Input
        {...props}
        className={classes.searchInput}
    />
);

const toolRootComponent = ({ ...props }) => (
    <Toolbar.Root
        {...props}
        className={classes.toolbarSearch}
    />
);

const tableColumnExtensions = [
    { columnName: 'name', align: 'center' },
    { columnName: 'company', align: 'center' },
    { columnName: 'email', align: 'center' },
];

const rowsSkeleton = []

for (let i = 0; i < 5; i++) {
    rowsSkeleton.push({
        name: <Skeleton variant="text" animation="wave" />,
        company: <Skeleton variant="text" animation="wave" />,
        email: <Skeleton variant="text" animation="wave" />,
    });
}
const ModProspectDirectory = (props) => {
    /** Properties */
    const {
        onClose,
        open,
        prospectsData,
        setOpenAdd,
        setProspectAndAverage,
        startOverProposal,
    } = props;

    /** Internationalization i18n */
    const [t] = useTranslation("global");
    

    const tableMessages = {
        noData: t("miscellaneous.noData")
    };

    const pagingMessages = {
        info: ({ from, to, count }) => `${from}${from < to ? `-${to}` : ''} ${t("miscellaneous.pagingOf")} ${count}`,
    };

    const searchMessages = {
        searchPlaceholder: t("miscellaneous.search")
    };

    /** Component states */
    const [rowsData, setRowsData] = useState([]);
    const [columnDefs, setColumnsDefs] = useState([]);
    const [selectionRow, setSelectionRow] = useState([]);
    const [searchValue, setSearchState] = useState('');

    /** Component functions */
    const handleSearch = (value) => {
        let itemS = value.replace(/ +(?= )/g, '');
        setSearchState(itemS);
    };

    const handleReset = () => {
        setSelectionRow([]);
        onClose();
    }

    const setSelection = (gridSelectedRow) => {
        const lastSelected = gridSelectedRow.find(selected => selectionRow.indexOf(selected) === -1);

        if (lastSelected >= 0) {
            setSelectionRow([lastSelected]);
            startOverProposal();
            setProspectAndAverage(prospectsData[lastSelected]);
            onClose();
        } else {
            setSelectionRow([]);
        }
    }

    /** Component events */
    useEffect(() => {
        (async () => {
            const columnsTemplate = [
                { name: 'name', title: t("proposals.name") },
                { name: 'company', title: t("proposals.company") },
                { name: 'email', title: t("proposals.email") },
            ];
            setColumnsDefs(columnsTemplate);
            setRowsData(rowsSkeleton);

            let prospects = prospectsData.map((item) => {
                return {
                    companyId: item.company_id,
                    name: `${item.prospect_name} ${item.prospect_last_name}`,
                    company: item.company_legal_name,
                    email: item.prospect_email,
                }
            });

            setRowsData(prospects);
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prospectsData]);

    return (
        <CustomDialog
            open={open}
            onClose={handleReset}
            aria-labelledby="prospect-directory-dialog-title"
            aria-describedby="prospect-directory-dialog-description"
            fullWidth={true}
            maxWidth="md"
        >
            <CustomDialogTitle id="prospect-directory-dialog-title" onClose={onClose}>{t('proposals.prospects')}</CustomDialogTitle>
            <DialogContent dividers sx={{ p:'16px 32px 12px !important' }}>
                <Button
                    disableElevation
                    variant="outlined"
                    color="primary"
                    startIcon={<AddCircleIcon />}
                    onClick={() => {
                        handleReset();
                        setOpenAdd(true);
                    }}
                    sx={{
                        pl: 3,
                        pr: 3,
                        border: "none",
                        borderRadius: 40,
                        "&:hover": {
                            border: "none",
                            background:
                                "transparent",
                            color: "#2f3190",
                        },
                    }}
                >
                    {t("proposals.createNew")}
                </Button>
                <Grid
                    rows={rowsData}
                    columns={columnDefs}
                >
                    <SelectionState
                        selection={selectionRow}
                        onSelectionChange={setSelection}
                    />
                    <SearchState
                        value={searchValue}
                        onValueChange={handleSearch}
                    />
                    <IntegratedFiltering />
                    <PagingState
                        defaultCurrentPage={0}
                        pageSize={10}
                    />
                    <IntegratedPaging />
                    <Table
                        columnExtensions={tableColumnExtensions}
                        cellComponent={TableCellComponent}
                        messages={tableMessages}
                    />
                    <TableHeaderRow
                        cellComponent={tableHeaderCell}
                    />
                    <TableSelection
                        selectByRowClick
                        highlightRow
                        rowComponent={CustomTableRow}
                        showSelectionColumn={false}
                    />
                    <Toolbar
                        rootComponent={toolRootComponent}
                    />
                    <SearchPanel
                        messages={searchMessages}
                        inputComponent={inputComponent}
                    />
                    <PagingPanel
                        containerComponent={PagingPanelContainer}
                        messages={pagingMessages}
                    />
                </Grid>
            </DialogContent>
        </CustomDialog>
    );
}

export default ModProspectDirectory;