/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';

/** MUI icons */
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import SettingsIcon from '@mui/icons-material/Settings';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import {ReactComponent as CheckIcon} from "../../../assets/images/check-icon.svg";

/** MUI imports */
import {
    Box,
    Breadcrumbs,
    Stack,
    Card,
    CardContent,
    Grid,
    Typography,
    CardActionArea,
    Stepper,
    Step,
    StepLabel,
    Button,
    Alert
} from '@mui/material';
import { styled } from '@mui/material/styles';

/** Components imports */
import FileSetting from "./FileSetting";
import FileMatch from "./FileMatch";
import FilePreview from "./FilePreview";

const steps = ['Documento', 'Match', 'Preview'];

const PREFIX = 'ImportFiles';

const classes = {
    alertRoot: `${PREFIX}-alertRoot`,
    icon: `${PREFIX}-icon`,
};

const CustomBox = styled(Box)(({ theme }) => ({
    [`& .${classes.alertRoot}`]: {
        borderRadius: 40,
        padding: '6px 22px',
    },

    [`& .${classes.icon}`]: {
        height: 20,
        fill: 'green',
        paddingRight: 22,
    },
}));

const CustomButton = styled(Button)(({ theme }) => ({
    border: '1px solid #ff5968',
    borderRadius: 40,
    '&:hover': {
        backgroundColor: '#2f3190',
        border: '1px solid #2f3190',
    },
}));

const ImportFiles = () => {
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    const breadcrumbs = [
        <Typography key="1" variant="h1" color="primary" sx={{
            fontSize: 24,
            fontWeight: 700
        }}>
            {t('principal.automation')}
        </Typography>,
        <Typography key="2" variant="h1" color="secondary" sx={{
            fontSize: 24,
            fontWeight: 700
        }}>
            {t("reports.import")}
        </Typography>
    ];

    const [showNew, setShowNew] = useState(false);
    const [showLoad, setShowLoad] = useState(false);
    const [showAdmin, setShowAdmin] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [matchData, setMatchData] = useState({});

    const handleReset = () => {
        setActiveStep(0);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const showNewSetting = () => {
        setShowNew(true);
        setShowLoad(false);
        setShowAdmin(false);
        handleReset();
    }

    const showLoadDoc = () => {
        setShowLoad(true);
        setShowNew(false);
        setShowAdmin(false);
    }

    const showAdminImp = () => {
        setShowAdmin(true);
        setShowNew(false);
        setShowLoad(false);
    }

    /** Component events */
    useEffect(() => {

    }, [t]);

    return (
        <CustomBox component="main" sx={{ flexGrow: 1, p: 4, pt: 10, width: '100%' }}>
            <Stack sx={{ pb: 4 }}>
                <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small" sx={{ color: '#031851' }} />}
                    aria-label="breadcrumb"
                >
                    {breadcrumbs}
                </Breadcrumbs>
            </Stack>
            {activeStep === 0 &&
                <Grid container spacing={8}>
                    <Grid item xs={12} lg={4}>
                        <Card
                            elevation={0}
                            sx={{
                                borderRadius: 4,
                                boxShadow: '10px 10px 15px rgba(3,24,81,0.15)',
                            }}
                        >
                            <CardActionArea onClick={showNewSetting}>
                                <CardContent sx={{ p: 3, textAlign: "center", backgroundColor: showNew ? 'rgba(47, 49, 144, 0.2)' : 'transparent' }}>
                                    <Typography sx={{ fontSize: 26, fontWeight: 700 }}>
                                        Configurar nuevo
                                    </Typography>
                                    <SettingsIcon sx={{ fontSize: 40, mt: 2, mb: 2 }} />
                                    <Typography sx={{ fontSize: 18, width: 300, margin: 'auto' }}>
                                        Preparar nuevos documentos antes de importarlos
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <Card
                            elevation={0}
                            sx={{
                                borderRadius: 4,
                                boxShadow: '10px 10px 15px rgba(3,24,81,0.15)',
                            }}
                        >
                            <CardActionArea onClick={showLoadDoc}>
                                <CardContent sx={{ p: 3, textAlign: "center", backgroundColor: showLoad ? 'rgba(47, 49, 144, 0.2)' : 'transparent' }}>
                                    <Typography sx={{ fontSize: 26, fontWeight: 700 }}>
                                        Cargar
                                    </Typography>
                                    <CloudUploadIcon sx={{ fontSize: 40, mt: 2, mb: 2 }} />
                                    <Typography sx={{ fontSize: 18, width: 300, margin: 'auto' }}>
                                        Importa documentos que ya fueron configurados
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <Card
                            elevation={0}
                            sx={{
                                borderRadius: 4,
                                boxShadow: '10px 10px 15px rgba(3,24,81,0.15)',
                            }}
                        >
                            <CardActionArea onClick={showAdminImp}>
                                <CardContent sx={{ p: 3, textAlign: "center", backgroundColor: showAdmin ? 'rgba(47, 49, 144, 0.2)' : 'transparent' }}>
                                    <Typography sx={{ fontSize: 26, fontWeight: 700 }}>
                                        Administrar
                                    </Typography>
                                    <BackupTableIcon sx={{ fontSize: 40, mt: 2, mb: 2 }} />
                                    <Typography sx={{ fontSize: 18, width: 300, margin: 'auto' }}>
                                        Revisar importaciones pasadas y administra tus configuraciones
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                </Grid>
            }
            {showNew &&
                <Box sx={{ width: '100%', pt: 6 }}>
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item xs={12} md={4}>
                            <Stepper activeStep={activeStep} alternativeLabel>
                                {steps.map((label) => (
                                    <Step key={label}>
                                        <StepLabel>{label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        </Grid>
                    </Grid>
                    {activeStep === 0 &&
                        <FileSetting handleNext={handleNext} setMatchData={setMatchData} />
                    }
                    {activeStep === 1 &&
                        <FileMatch handleNext={handleNext} handleBack={handleBack} handleReset={handleReset} matchData={matchData} />
                    }
                    {activeStep === 2 &&
                        <FilePreview handleNext={handleNext} handleBack={handleBack} handleReset={handleReset} matchData={matchData} />
                    }
                    {activeStep === steps.length &&
                        <Grid container spacing={2} justifyContent="center" sx={{ pt: 6 }}>
                            <Grid item xs={12} md={4}>
                                <Alert severity="success"
                                    classes={{
                                        root: classes.alertRoot
                                    }}
                                    iconMapping={{
                                        success: <CheckIcon className={classes.icon} />
                                    }}>El archivo fue cargado exitosamente en Company A</Alert>
                                <Box sx={{ pt: 6, textAlign: 'center' }}>
                                    <CustomButton onClick={handleReset}
                                        color="secondary"
                                        variant="contained"
                                        disableElevation>Volver</CustomButton>
                                </Box>
                            </Grid>
                        </Grid>
                    }
                </Box>
            }
            {showLoad &&
                <>
                </>
            }
            {showAdmin &&
                <>
                </>
            }
        </CustomBox>
    );
};

export default ImportFiles;