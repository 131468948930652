import { FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const ModalRep = (props) => {
    const [t] = useTranslation("global");
    const { motiveDec, setMotiveDec } = props;

    const handleChangeMotive = (event) => setMotiveDec(event.target.value);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h5">{t("invoicing.toWhoReport")}</Typography>
            </Grid>
            <Grid item xs={12}>
                <FormControl>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                        value={motiveDec}
                        onChange={handleChangeMotive}
                    >
                        <FormControlLabel value={"default"} control={<Radio />} label={t("invoicing.clientApply")} />
                        <FormControlLabel value={"corpo"} control={<Radio />} label={t("invoicing.corpoMail")} />
                        <FormControlLabel value={"both"} control={<Radio />} label={t("invoicing.both")} />
                    </RadioGroup>
                </FormControl>
            </Grid>
        </Grid>
    );
};

export default ModalRep;
