/** React imports */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import moment from "moment";

/** MUI imports */
import { styled } from "@mui/material/styles";
import { Dialog, DialogContent, ListItem, ListItemText, ToggleButtonGroup, ToggleButton, Typography, IconButton, Badge, TextField, Button, Box, AppBar, Slide, Toolbar } from "@mui/material";
import { Grid, Table, TableHeaderRow, PagingPanel } from "@devexpress/dx-react-grid-material-ui";
import { PagingState, IntegratedPaging } from "@devexpress/dx-react-grid";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import CommentIcon from "@mui/icons-material/Comment";
import GridUI from "@mui/material/Grid";
import CloseIcon from '@mui/icons-material/Close';

/** MUI icons */
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
import WarningAmberIcon from "@mui/icons-material/Warning";
import { useNavigate } from "react-router-dom";
import CommDialProp from "../CommentDialProposal";

const PREFIX = "ProposalHistory";

const classes = {
    paper: `${PREFIX}-paper`,
    tableHeader: `${PREFIX}-tableHeader`,
    tableBody: `${PREFIX}-tableBody`,
    pager: `${PREFIX}-pager`,
    detailsCel: `${PREFIX}-detailsCel`,
    btnRoot: `${PREFIX}-btnRoot`,
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "rgba(19, 31, 62, 0.80)",
        fontSize: 12,
        maxWidth: 320,
        borderRadius: 6,
        fontWeight: "normal",
    },
});

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    width: "100%",
    "& .MuiToggleButtonGroup-grouped": {
        border: 0,
        borderRadius: 30,
        height: 35,
        width: "50%",
        backgroundColor: "rgba(47,49,144,0.1)",
        color: "#031851",
        "&:hover": {
            backgroundColor: "rgba(47, 49, 144, 0.05)",
        },
    },
    "& .Mui-selected": {
        backgroundColor: "#2f3190 !important",
        color: "white !important",
        "&:hover": {
            backgroundColor: "rgba(47, 49, 144, 0.8) !important",
        },
    },
}));



const tableHeaderCell = ({ ...props }) => <TableHeaderRow.Cell {...props} className={classes.tableHeader} />;

const TableCellComponent = ({ ...props }) => <Table.Cell {...props} className={classes.tableBody} />;

const PagingPanelContainer = ({ ...props }) => <PagingPanel.Container {...props} className={classes.pager} />;

const tableColumnExtensions = [
    { columnName: "reference" },
    { columnName: "services", align: "center" },
    { columnName: "status", align: "center" },
    { columnName: "deliveryDate", align: "center" },
];

const tableColumnExtensions2 = [
    { columnName: "reference", align: "center" },
    { columnName: "name" },
    { columnName: "company", wordWrapEnabled: true, width: "20%" },
    { columnName: "businessEmail", align: "center" },
    { columnName: "services", align: "center", wordWrapEnabled: true, width: "20%" },
    { columnName: "status", align: "center" },
    { columnName: "deliveryDate", align: "center" },
    { columnName: "user", align: "center" },
    { columnName: "details", align: "center" },
    { columnName: "comments", align: "center" },
];

export default function ProposalHistory({ open, onClose, propContData, showProposalHist, setTexto, setAlertType, setShow, setBackDrop, compInfo }) {
    const navigate = useNavigate();
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    const todos = useSelector((state) => state.todos);

    const tableMessages = {
        noData: t("miscellaneous.noData"),
    };
    const pagingMessages = {
        info: ({ from, to, count }) => `${from}${from < to ? `-${to}` : ""} ${t("miscellaneous.pagingOf")} ${count}`,
    };

    const [columnDefs, setColumnsDefs] = useState([]);
    const [columnDefs2, setColumnsDefs2] = useState([]);
    const [history, setHistory] = useState([]);
    const [buttonTabs, setButtomTabs] = useState("proposals");
    const [contracts, setContracts] = useState([]);
    const [comments, setComments] = useState([]);
    const [commentsProposal, setCommentsProposal] = useState();
    const [commentsProspect, setCommentsProspect] = useState();
    const [companyName, setCompanyName] = useState("");
    const [dialogComment, setDialogComment] = useState(false);
    const [proposalID, setProposalID] = useState("");
    const [motiveArea, setMotiveArea] = useState("");

    const handleOnClose = () => {
        setMotiveArea("")
        onClose();
        setButtomTabs("proposals");
    };

    const handleChangeTabs = (event, newAlignment) => {
        if (newAlignment !== null) {
            setButtomTabs(newAlignment);
        }
    };

    const getComments = async (proposalID) => {
        await fetch(`/getComments?proposal_id=${proposalID}&comment_type=2`, {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then((res) => res.json())
            .then((data) => {
                setComments(data.all);
                setCommentsProposal(data.proposal);
                setCommentsProspect(data.prospect);
            });
    };

    useEffect(() => {
        (async () => {
            const columnsTemplate = [
                { name: "reference", title: t("proposals.reference") },
                { name: "services", title: t("proposals.services") },
                { name: "status", title: t("miscellaneous.status") },
                { name: "deliveryDate", title: t("proposals.dateSent") },
            ];
            setColumnsDefs(columnsTemplate);

            let initValues = [];

            if (propContData.length > 0) {
                initValues = propContData.map((item) => {
                    return {
                        id: item._id,
                        reference: item.reference,
                        services: item.services.join(", "),
                        status: (
                            <ListItem
                                alignItems="center"
                                secondaryAction={
                                    item.status === 1 ? (
                                        moment().diff(item.creation_date, "weeks") < 1 ? (
                                            <WarningAmberIcon
                                                sx={{
                                                    color: "#FFB039",
                                                }}
                                            />
                                        ) : (
                                            <LightTooltip title={t("proposals.dueDraft")} aria-label="comment-tooltip">
                                                <WarningAmberIcon
                                                    sx={{
                                                        color: "#ff5968",
                                                    }}
                                                />
                                            </LightTooltip>
                                        )
                                    ) : item.status === 2 ? (
                                        item.delivery_date && moment().diff(item.delivery_date, "weeks") <= 2 ? (
                                            <AccessTimeFilledIcon
                                                sx={{
                                                    color: "#FFB039",
                                                }}
                                            />
                                        ) : (
                                            <LightTooltip title={t("proposals.dueProposal")} aria-label="comment-tooltip">
                                                <AccessTimeFilledIcon
                                                    sx={{
                                                        color: "#ff5968",
                                                    }}
                                                />
                                            </LightTooltip>
                                        )
                                    ) : item.status === 3 ? (
                                        <CheckIcon sx={{ color: "green" }} />
                                    ) : (
                                        <CancelIcon color="secondary" />
                                    )
                                }
                                sx={{ ml: "auto", mr: "auto", pt: 0, pb: 0, width: 145 }}
                            >
                                <ListItemText
                                    primary={
                                        item.status === 1
                                            ? t("proposals.draft")
                                            : item.status === 2
                                                ? item.delivery_date && moment().diff(item.delivery_date, "weeks") <= 2
                                                    ? t("proposals.sent")
                                                    : t("proposals.overdue")
                                                : item.status === 3
                                                    ? t("proposals.accepted")
                                                    : t("proposals.rejected")
                                    }
                                    sx={{ textAlign: "left" }}
                                />
                            </ListItem>
                        ),
                        deliveryDate: item.delivery_date
                            ? moment(item.delivery_date, "YYYY-MM-DD").format(todos.dateFormat)
                            : "-",
                    };
                });
            }

            const columnsTemplate2 = [
                { name: "reference", title: t("proposals.reference") },
                { name: "name", title: t("proposals.name") },
                { name: "company", title: t("proposals.company") },
                { name: "businessEmail", title: t("proposals.businessEmail") },
                { name: "services", title: t("proposals.services") },
                { name: "status", title: t("miscellaneous.status") },
                { name: "deliveryDate", title: t("proposals.dateSent") },
                { name: "user", title: t("proposals.responsible") },
                { name: "details", title: " " },
                { name: "comments", title: " " },
            ];
            setColumnsDefs2(columnsTemplate2);

            let initValues2 = [];

            const handleOpenProposalDetails = (item) => {
                if (item.status === 1) {
                    //Borrador
                    navigate(`/proposals/createProposal?proposalID=${item._id}&edit=true`);
                } else {
                    navigate(`/proposals/createProposal?proposalID=${item._id}`);
                }
            };

            const openComments = async (proposalID, company_name) => {
                setBackDrop(true);

                setProposalID(proposalID);
                setCompanyName(company_name);

                await getComments(proposalID);

                setDialogComment(true);

                setBackDrop(false);
            };

            const getComments = async (proposalID) => {
                await fetch(`/getComments?proposal_id=${proposalID}&comment_type=2`, {
                    method: "GET",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                    },
                })
                    .then((res) => res.json())
                    .then((data) => {
                        setComments(data.all);
                        setCommentsProposal(data.proposal);
                        setCommentsProspect(data.prospect);
                    });
            };
            if (propContData.length > 0) {
                initValues2 = propContData.map((item) => {
                    let cont = 0;

                    item.comments.forEach((data) => {
                        if (data.status === 0) {
                            cont++;
                        }
                    });

                    return {
                        id: item._id,
                        reference: item.reference,
                        name: item.prospect_name,
                        company: item.company_name,
                        businessEmail: item.prospect_email,
                        services: item.services.join(", "),
                        status: (
                            <ListItem
                                alignItems="center"
                                secondaryAction={
                                    item.status === 1 ? (
                                        moment().diff(item.creation_date, "weeks") < 1 ? (
                                            <WarningAmberIcon
                                                sx={{
                                                    color: "#FFB039",
                                                }}
                                            />
                                        ) : (
                                            <LightTooltip title={t("proposals.dueDraft")} aria-label="comment-tooltip">
                                                <WarningAmberIcon
                                                    sx={{
                                                        color: "#ff5968",
                                                    }}
                                                />
                                            </LightTooltip>
                                        )
                                    ) : item.status === 2 ? (
                                        item.delivery_date && moment().diff(item.delivery_date, "weeks") <= 2 ? (
                                            <AccessTimeFilledIcon
                                                sx={{
                                                    color: "#FFB039",
                                                }}
                                            />
                                        ) : (
                                            <LightTooltip title={t("proposals.dueProposal")} aria-label="comment-tooltip">
                                                <AccessTimeFilledIcon
                                                    sx={{
                                                        color: "#ff5968",
                                                    }}
                                                />
                                            </LightTooltip>
                                        )
                                    ) : item.status === 3 ? (
                                        <CheckIcon sx={{ color: "green" }} />
                                    ) : (
                                        <CancelIcon color="secondary" />
                                    )
                                }
                                sx={{ ml: "auto", mr: "auto", width: 145 }}
                            >
                                <ListItemText
                                    primary={
                                        item.status === 1
                                            ? t("proposals.draft")
                                            : item.status === 2
                                                ? item.delivery_date && moment().diff(item.delivery_date, "weeks") <= 2
                                                    ? t("proposals.sent")
                                                    : t("proposals.overdue")
                                                : item.status === 3
                                                    ? t("proposals.accepted")
                                                    : t("proposals.rejected")
                                    }
                                    sx={{ textAlign: "left" }}
                                />
                            </ListItem>
                        ),
                        deliveryDate: item.delivery_date
                            ? moment(item.delivery_date, "YYYY-MM-DD").format(todos.dateFormat)
                            : "-",
                        user: item.creation_user ?? "-",
                        details: (
                            <Typography
                                component="span"
                                variant="h4"
                                className={classes.detailsCel}
                                onClick={() => handleOpenProposalDetails(item)}
                            >
                                {t("proposals.viewDetail")}
                            </Typography>
                        ),
                        comments: (
                            <LightTooltip title={t("miscellaneous.comments")} aria-label="comment-tooltip">
                                <IconButton aria-label="comment" onClick={() => openComments(item._id, item.company_name)}>
                                    <Badge badgeContent={cont} color="secondary">
                                        <CommentIcon sx={{ color: "#031851" }} />
                                    </Badge>
                                </IconButton>
                            </LightTooltip>
                        ),
                    };
                });
            }

            setHistory(initValues);
            setContracts(initValues2);
            setMotiveArea(compInfo?.comment_gen_info ?? "")
        })();
    }, [compInfo?.comment_gen_info, navigate, propContData, setBackDrop, t, todos.amountFormat, todos.dateFormat]);

    const closeCommentsDialog = () => {
        setDialogComment(false);
    };

    const handleChangeMotiveArea = (event) => {
        setMotiveArea(event.target.value);
    };

    const updateClientData = async () => {
        setBackDrop(true);
        const params = {
            company_id: compInfo._id,
            comment_gen_info: motiveArea
        };

        await fetch(`/updateGenComment`, {
            method: "POST",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
            body: JSON.stringify(params),
        })
            .then((response) => {
                setTexto(t("miscellaneous.successfulUpdate"));
                setAlertType("success");
                setShow(true);
            })
            .catch((error) => {
                console.log(error);
                setTexto(t("miscellaneous.updatingError"));
                setAlertType("error");
                setShow(true);
            });
        setBackDrop(false);
    };

    return (
        <>
            <Dialog
                fullScreen
                open={open}
                onClose={handleOnClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleOnClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            {t('principal.historyPropCont')}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <GridUI container spacing={2} sx={{ mt: 1, display: "flex", justifyContent: "center" }}>
                        <GridUI item xs={12} lg={6}>
                            <StyledToggleButtonGroup
                                color="primary"
                                value={buttonTabs}
                                exclusive
                                onChange={handleChangeTabs}
                                sx={{ pb: 4, justifyContent: "center" }}
                            >
                                <ToggleButton value="proposals">{t("proposals.proposals")}</ToggleButton>
                                <ToggleButton value="contracts">{t("proposals.contracts")}</ToggleButton>
                                <ToggleButton value="info">{t("miscellaneous.genInfo")}</ToggleButton>
                            </StyledToggleButtonGroup>
                        </GridUI>
                    </GridUI>
                    {buttonTabs === "proposals" && (
                        <Grid rows={history} columns={columnDefs}>
                            <PagingState defaultCurrentPage={0} pageSize={10} />
                            <IntegratedPaging />
                            <Table
                                columnExtensions={tableColumnExtensions}
                                cellComponent={TableCellComponent}
                                messages={tableMessages}
                            />
                            <TableHeaderRow cellComponent={tableHeaderCell} />
                            <PagingPanel containerComponent={PagingPanelContainer} messages={pagingMessages} />
                        </Grid>
                    )}
                    {buttonTabs === "contracts" && (
                        <Grid rows={contracts} columns={columnDefs2}>
                            <PagingState defaultCurrentPage={0} pageSize={10} />
                            <IntegratedPaging />
                            <Table
                                columnExtensions={tableColumnExtensions2}
                                cellComponent={TableCellComponent}
                                messages={tableMessages}
                            />
                            <TableHeaderRow cellComponent={tableHeaderCell} />
                            <PagingPanel containerComponent={PagingPanelContainer} messages={pagingMessages} />
                        </Grid>
                    )}
                    {buttonTabs === "info" && (
                        <GridUI container spacing={2} sx={{ mt: 1, display: "flex", justifyContent: "center" }}>
                            <GridUI item xs={12}>
                                <TextField
                                    sx={{
                                        backgroundColor: "#FFFFFF !important",
                                        borderRadius: 3,
                                        "&:focus-within": {
                                            backgroundColor: "#FFFFFF",
                                            borderRadius: 3,
                                        },
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            border: "2px solid #031851",
                                            borderRadius: 3,
                                        }
                                    }}
                                    value={motiveArea}
                                    label={t("miscellaneous.genInfo")}
                                    onChange={handleChangeMotiveArea}
                                    multiline
                                    rows={4}
                                    // inputProps={{
                                    //     maxLength: 500,
                                    // }}
                                    fullWidth
                                />
                            </GridUI>
                            <GridUI item xs={12}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        p: 2,
                                        pb: 0,
                                        width: "100%"
                                    }}
                                >
                                    <Button
                                        color="secondary"
                                        variant="contained"
                                        disableElevation
                                        classes={{ root: classes.btnRoot }}
                                        onClick={updateClientData}
                                    >
                                        {t("miscellaneous.save")}
                                    </Button>
                                </Box>
                            </GridUI>
                        </GridUI>)}
                </DialogContent>
            </Dialog>
            <CommDialProp
                open={dialogComment}
                onClose={closeCommentsDialog}
                proposalID={proposalID}
                setTexto={setTexto}
                setAutoAlertType={setAlertType}
                setBackDrop={setBackDrop}
                setShow={setShow}
                comments={comments}
                getComments={getComments}
                commentType={2}
                loadInitData={showProposalHist}
                proposal={commentsProposal}
                prospect={commentsProspect}
                company_name={companyName}
            />
        </>
    );
}
