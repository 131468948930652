/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";

/** Icons */
import { ReactComponent as GCALogo } from "../../../assets/images/gca-logo-desktop.svg";
import { ReactComponent as GcaLogoMobile } from "../../../assets/images/gca-logo-mobile.svg";

/** MUI icons */
import CommentIcon from "@mui/icons-material/Comment";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

/** MUI imports */
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import {
    AppBar,
    Box,
    Typography,
    Toolbar,
    Divider,
    IconButton,
    Button,
    Fab,
    Container,
    Hidden,
    Select,
    TextField,
    MenuItem,
    FormControl,
    InputLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    FormHelperText,
    Badge,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

/** Components imports */
import Dialog from "./FaqDialog";
import DeclineDialog from "./DeclineDialog";
import AcceptDialog from "./AcceptDialog";
import Backdrop from "../Backdrop";
import CommDialProp from "../CommentDialProposal";
import SnackBar from "../SnackBar";
import { FrontPage, Team, ServiceLevels, Pricing, Schedule, TermsConditions, FinalPage } from "./Core";

/** Global variables */
const PREFIX = "ProposalPresent";

const classes = {
    body2: `${PREFIX}-body2`,
    navbarLogo: `${PREFIX}-navbarLogo`,
    toolbar: `${PREFIX}-toolbar`,
    comment: `${PREFIX}-comment`,
    languageBtn: `${PREFIX}-languageBtn`,
    listLang: `${PREFIX}-listLang`,
    divider: `${PREFIX}-divider`,
    spacing: `${PREFIX}-spacing`,
    headerText: `${PREFIX}-headerText`,
    textBoxM: `${PREFIX}-textBoxM`,
    textBoxM2: `${PREFIX}-textBoxM2`,
    ListText: `${PREFIX}-ListText`,
    headerLogo: `${PREFIX}-headerLogo`,
    showAsk: `${PREFIX}-showAsk`,
};

const ColorButton = styled(Button)(({ theme }) => ({
    margin: "0px 10px",
    padding: "4px 30px",
    fontSize: "16px",
    borderRadius: "50px",
}));

const ColorButtonSec = styled(Button)(({ theme }) => ({
    margin: "0px 10px",
    padding: "4px 30px",
    fontSize: "16px",
    borderRadius: "50px",
    backgroundColor: "white",
    color: "#031851",
    "&:hover": {
        backgroundColor: "rgba(255, 255, 255, 0.8)",
    },
}));

const BackModal = styled(Box)(({ theme }) => ({
    padding: 15,
    borderRadius: 15,
    backgroundColor: "#031851",
    color: "white",
    marginBottom: 15,
    boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
}));

const BackModalForm = styled(Box)(({ theme }) => ({
    [theme.breakpoints.down("sm")]: {
        padding: 15,
    },
    padding: 40,
    borderRadius: 15,
    backgroundColor: "#F8F9F8",
    boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
    textAlign: "right",
}));

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "rgba(3, 24, 81, 1)",
        fontSize: 12,
        maxWidth: 230,
        borderRadius: 40,
        fontWeight: "normal",
        textAlign: "center",
    },
});

const CustomSelect = styled(FormControl)(({ theme }) => ({
    backgroundColor: "#FFFFFF !important",
    borderRadius: 40,
    marginBottom: 30,
    textAlign: "left",
    "&:focus-within": {
        backgroundColor: "#FFFFFF",
        borderRadius: 40,
    },
    "& .MuiOutlinedInput-notchedOutline": {
        border: "2px solid #031851",
        borderRadius: 40,
    },
}));

const CustomTextArea = styled(TextField)(({ theme }) => ({
    backgroundColor: "#FFFFFF !important",
    borderRadius: 20,
    "&:focus-within": {
        backgroundColor: "#FFFFFF",
        borderRadius: 20,
    },
    "& .MuiOutlinedInput-notchedOutline": {
        border: "2px solid #031851",
        borderRadius: 20,
    },
}));

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.headerLogo}`]: {
        display: "block",
        height: 40,
    },

    [`& .${classes.showAsk}`]: {
        bottom: 100,
        position: "relative",
    },

    [`& .${classes.languageBtn}`]: {
        cursor: "pointer",
        [theme.breakpoints.down("sm")]: {
            padding: 5,
        },
        padding: "10px 20px",
        fontWeight: 700,
        "&:hover": {
            color: theme.palette.secondary.main,
        },
    },

    [`& .${classes.headerText}`]: {
        fontWeight: 700,
        fontSize: 34,
    },

    [`& .${classes.listLang}`]: {
        listStyle: "none",
        display: "inline-flex",
        justifyContent: "space-around",
        alignItems: "center",
        paddingLeft: 15,
        [theme.breakpoints.down("sm")]: {
            paddingLeft: 10,
        },
    },

    [`& .${classes.divider}`]: {
        background: "#4F5D85",
        height: 20,
        margin: "",
    },

    [`& .${classes.navbarLogo}`]: {
        display: "block",
        height: 50,
        [theme.breakpoints.down("sm")]: {
            width: 165,
        },
    },

    [`& .${classes.toolbar}`]: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "#031851",
        minHeight: "90px",
        [theme.breakpoints.down("sm")]: {
            padding: "0 15px",
        },
        [theme.breakpoints.up("sm")]: {
            padding: "0 70px",
        },
    },

    [`& .${classes.comment}`]: {
        backgroundColor: "white",
        [theme.breakpoints.up("sm")]: {
            marginLeft: 15,
        },
        "&:hover": {
            backgroundColor: "white",
            opacity: "0.8",
        },
    },

    [`& .${classes.spacing}`]: {
        padding: "20px 0 25px 0",
    },
}));

const ProposalPresent = () => {
    /** Internationalization i18n */
    const [t, i18n] = useTranslation("global");
    /* React redux */
    const dispatch = useDispatch();
    const params = new URL(document.location).searchParams;
    const proposalID = params.get("proposal_id");
    const finalPageRef = useRef();

    /** Component states */
    const [dialogForm, setDialogForm] = useState(false);
    const [declineForm, setDeclineForm] = useState(false);
    const [dialogAcceptForm, setDialogAcceptForm] = useState(false);
    const [language, setLanguage] = useState(sessionStorage.getItem("lng") === "es" ? "ES" : "EN");
    const [motive, setMotive] = useState("");
    const [motiveArea, setMotiveArea] = useState("");
    const [isAccepted, setIsAccepted] = useState(0);
    const [proposalInfo, setProposalInfo] = useState(null);
    const [proposalInfoServ, setProposalInfoServ] = useState({});
    const [isConsulting, setIsConsulting] = useState(false);
    const [isCatchUp, setIsCatchUp] = useState(false);
    const [showBackdrop, setBackDrop] = useState(false);
    const [motiveDec, setMotiveDec] = useState("");
    const [motiveDecDetails, setMotiveDecDetails] = useState("");
    const [error, setError] = useState(false);
    const [helperText, setHelperText] = useState("");
    const [comments, setComments] = useState([]);
    const [dialogComment, setDialogComment] = useState(false);
    const [show, setShow] = useState(false);
    const [texto, setTexto] = useState("");
    const [alertType, setAutoAlertType] = useState("");
    const [commentNum, setCommentNum] = useState(0);
    const [dialogSendOpen, setDialogSendOpen] = useState(false);
    const [finalPIsVisible, setFinalPIsVisible] = useState(false);
    const [deliveryDate, setDeliveryDate] = useState(null);
    const [includeToolbox, setIncludeToolbox] = useState(true);
    const [includeOnboarding, setIncludeOnboarding] = useState(true);
    const [includeAccModuleSet, setIncludeAccModuleSet] = useState(true);
    const [includeAdmModuleSet, setIncludesAdmModuleSet] = useState(true);
    const [includeAccModuleSub, setIncludeAccModuleSub] = useState(true);
    const [includeAdmModuleSub, setIncludeAdmModuleSub] = useState(true);
    const [accountantOnboarding, setAccountantOnboarding] = useState(false);
    const [isSetup, setIsSetup] = useState(false);

    const openComments = async () => {
        setBackDrop(true);

        await getComments();

        setDialogComment(true);

        setBackDrop(false);
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setShow(false);
    };

    const getComments = async () => {
        await fetch(`/getComments?proposal_id=${proposalInfo?.proposal_id}&comment_type=2`, {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then((res) => res.json())
            .then((data) => {
                setComments(data.all);
            });
    };

    const closeCommentsDialog = () => {
        setDialogComment(false);
    };

    const changeMotiveDecDetails = (event) => {
        setMotiveDecDetails(event.target.value);
    };
    const changeMotiveDec = (event) => {
        setMotiveDec(event.target.value);
    };

    const handleUpdateLanguage = async (value) => {
        setBackDrop(true);

        updateLanguage(value);

        let params = {
            proposal_id: proposalID,
            language: value,
        };

        await fetch("/updateProposalLang", {
            method: "POST",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
            body: JSON.stringify(params),
        })
            .then((res) => { })
            .catch((err) => {
                setTexto(err.message);
                setAutoAlertType("error");
                setShow(true);
            });

        setBackDrop(false);
    };

    const updateLanguage = (lng) => {
        if (lng === "EN") {
            setLanguage(lng);
            sessionStorage.setItem("lng", "en");
            i18n.changeLanguage("en");
            dispatch({
                type: "addAmountFormat",
                item: "en-US",
            });
        } else {
            setLanguage(lng);
            sessionStorage.setItem("lng", "es");
            i18n.changeLanguage("es");
            dispatch({
                type: "addAmountFormat",
                item: "de-DE",
            });
        }
        document.title = t("principal.gcaPlatform");
    };

    const closeDialog = () => {
        setDialogForm(false);
        setDeclineForm(false);
        setDialogAcceptForm(false);
        setDialogSendOpen(false);
        setMotiveDec("");
        setMotiveDecDetails("");
        setError(false);
        setMotive("");
        setMotiveArea("");
        setHelperText("");
    };

    const closeDialogSend = async () => {
        if (motive !== "" && motiveArea !== "") {
            setBackDrop(true);

            let params = {
                proposal_id: proposalID,
                comment_description: motive + "-" + motiveArea,
                user_name: proposalInfo.prospect_name,
                email: proposalInfo.prospect_email,
                comment_type: 2,
                company_name: proposalInfo.company_name
            };

            closeDialog();

            await fetch("/addComment", {
                method: "POST",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
                body: JSON.stringify(params),
            })
                .then(() => {
                    getProposalInfo(proposalID);
                    setError(false);
                    setBackDrop(false);
                    setDialogSendOpen(true);
                })
                .catch((err) => {
                    setTexto(err.message);
                    setAutoAlertType("error");
                    setShow(true);
                });
        } else {
            setTexto(t("miscellaneous.requiredData"));
            setAutoAlertType("warning");
            setShow(true);
            setError(true);
        }
    };

    const handleChangeMotive = (event) => {
        setMotive(event.target.value);
    };

    const handleChangeMotiveArea = (event) => {
        setMotiveArea(event.target.value);
    };

    const acceptProposal = async () => {
        setBackDrop(true);

        let params = {
            proposal_id: proposalID,
        };

        await fetch(`/approveProposal`, {
            method: "POST",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
            body: JSON.stringify(params),
        })
            .then((response) => {
                if (response.ok) {
                    setIsAccepted(3);
                    setDialogAcceptForm(true);
                }
            })
            .catch((error) => {
                console.log(error);
            });

        setBackDrop(false);
    };

    const openModalDecline = () => {
        setDeclineForm(true);
    };

    const declineProposal = async () => {
        setBackDrop(true);

        let params = {
            proposal_id: proposalID,
            closure_comment: motiveDec + ". " + motiveDecDetails,
        };

        if (motiveDec !== "" && motiveDecDetails.trim() !== "") {
            await fetch(`/declineProposal`, {
                method: "POST",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
                body: JSON.stringify(params),
            })
                .then((response) => {
                    if (response.ok) {
                        setIsAccepted(4);
                        setDeclineForm(false);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            setHelperText(t("miscellaneous.requiredData"));
            setError(true);
        }

        setBackDrop(false);
    };

    const getProposalInfo = async (id) => {
        setBackDrop(true);

        await fetch(`/getProposalInfo?proposal_id=${id ?? proposalID}`, {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then((res) => res.json())
            .then((response) => {
                setDeliveryDate(response.delivery_date ?? null);

                if (!response.language) {
                    response.reference.includes("VE") || response.reference.includes("LA")
                        ? updateLanguage("ES")
                        : updateLanguage("EN");
                } else {
                    updateLanguage(response.language);
                }

                let values = {};
                let isConsulting = false;
                let isCatchUp = false;

                let cont = 0;
                response.comments.forEach((data) => {
                    if (data.status === 0) {
                        cont++;
                    }
                });

                setIncludeToolbox((response?.include_toolbox === undefined || response?.include_toolbox === null) ? true : response?.include_toolbox);
                setIncludeOnboarding((response?.include_onboarding === undefined || response?.include_onboarding === null) ? true : response?.include_onboarding);
                setIncludeAdmModuleSub((response?.include_adm_module_sub === undefined || response?.include_adm_module_sub === null) ? true : response?.include_adm_module_sub);
                setIncludeAccModuleSub((response?.include_acc_module_sub === undefined || response?.include_acc_module_sub === null) ? true : response?.include_acc_module_sub);
                setIncludesAdmModuleSet((response?.include_adm_module_set === undefined || response?.include_adm_module_set === null) ? true : response?.include_adm_module_set);
                setIncludeAccModuleSet((response?.include_acc_module_set === undefined || response?.include_acc_module_set === null) ? true : response?.include_acc_module_set);


                response.prospect = {
                    company_legal_name: response.company_name,
                    prospect_id: response.prospect_id
                }

                response.services.forEach((item) => {
                    
                    item.prospect_name = response.prospect_name;
                    item.company_name = response.company_name;
                    item.country_name = response.reference.includes("VE")
                        ? "Venezuela"
                        : response.reference.includes("US")
                            ? "Estados Unidos"
                            : "";
                    item.front_image = response.front_image;

                    if (item.service_name === "Accountant") {
                        item.projects.forEach((value) => {
                            if (value.project_name === "Accountant consulting" && item.projects.length === 1) {
                                isConsulting = true;
                            }

                            if (value.project_name === "Accountant onboarding") {
                                setAccountantOnboarding(true);
                            }
                        });

                        values.accountant = item;
                    } else if (item.service_name === "Bookkeeper") {
                        item.projects.forEach((value) => {
                            if (value.project_name === "Bookkeeper consulting" && item.projects.length === 1) {
                                isConsulting = true;
                            }
                        });

                        values.bookkeeper = item;
                    } else if (item.service_name === "Controller") {
                        item.projects.forEach((value) => {
                            if (value.project_name === "Controller catchup" && item.projects.length === 1) {
                                isCatchUp = true;
                            } else if (value.project_name === "Controller consulting" && item.projects.length === 1) {
                                isConsulting = true;
                            }
                        });

                        values.controller = item;
                    } else if (item.service_name === "CFO") {
                        item.projects.forEach((value) => {
                            if (value.project_name === "CFO consulting" && item.projects.length === 1) {
                                isConsulting = true;
                            }
                        });

                        values.cfo = item;
                    } else if (
                        item.service_name === "Software setup" ||
                        item.service_name === "Software subscription"
                    ) {
                        if (item.service_name === "Software subscription") {
                            values.software = item;
                        }
                        if (item.service_name === "Software setup") {
                            setIsSetup(true);
                            if (values["software"] === undefined) {
                                values.software = item;
                            }
                        }
                    }
                });

                console.log("response", response)

                setCommentNum(cont);
                setIsCatchUp(isCatchUp);
                setIsConsulting(isConsulting);
                setProposalInfoServ(values);
                setProposalInfo(response);
                setIsAccepted(response.status);
            })
            .catch((error) => {
                console.log(error);
            });

        setBackDrop(false);
    };

    useEffect(() => {
        if (proposalID) {
            getProposalInfo(proposalID);
        }

        const observer = new IntersectionObserver((entries) => {
            const entry = entries[0];

            setFinalPIsVisible(entry.isIntersecting);
        });

        observer.observe(finalPageRef.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const dialogBody = (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="body1">{proposalInfoServ.bookkeeper ? t("proposals.answersInB") : t("proposals.answersIn")}</Typography>
            </Grid>
            <Grid item xs={12}>
                <a
                    href="https://gca.digital/#/resources/faq"
                    rel="noreferrer"
                    style={{ textDecoration: "none" }}
                    target="_blank"
                >
                    <BackModal>
                        <Typography variant="h5">{proposalInfoServ.bookkeeper ? t("proposals.faqB") : t("proposals.faq")}</Typography>
                    </BackModal>
                </a>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body1">{proposalInfoServ.bookkeeper ? t("proposals.especialB") : t("proposals.especial")}</Typography>
            </Grid>
            <Grid item xs={12}>
                <BackModalForm>
                    <Typography variant="h4" sx={{ textAlign: "center", pb: 3 }}>
                        {proposalInfoServ.bookkeeper ? t("proposals.sureQuestB") : t("proposals.sureQuest")}
                    </Typography>
                    <CustomSelect fullWidth size="small" required>
                        <InputLabel id="motive-select-label">{proposalInfoServ.bookkeeper ? t("proposals.selectMotiveB") : t("proposals.selectMotive")}</InputLabel>
                        <Select
                            labelId="motive-select-label"
                            label={proposalInfoServ.bookkeeper ? t("proposals.selectMotiveB") : t("proposals.selectMotive")}
                            value={motive}
                            onChange={handleChangeMotive}
                            IconComponent={KeyboardArrowDownIcon}
                            className="ddlGeneral"
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        mt: 0.7,
                                        borderRadius: 2,
                                        boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                    },
                                },
                            }}
                            error={error && motive === ""}
                        >
                            <MenuItem value={t("proposals.faqServices")}>
                                <Typography variant="body1" component="span" color="textPrimary">
                                    {proposalInfoServ.bookkeeper ? t("proposals.faqServicesB") : t("proposals.faqServices")}
                                </Typography>
                            </MenuItem>
                            <MenuItem value={t("proposals.faqServices2")}>
                                <Typography variant="body1" component="span" color="textPrimary">
                                    {proposalInfoServ.bookkeeper ? t("proposals.faqServices2B") : t("proposals.faqServices2")}
                                </Typography>
                            </MenuItem>
                            <MenuItem value={t("proposals.faqServices3")}>
                                <Typography variant="body1" component="span" color="textPrimary">
                                    {proposalInfoServ.bookkeeper ? t("proposals.faqServices3B") : t("proposals.faqServices3")}
                                </Typography>
                            </MenuItem>
                            <MenuItem value={t("proposals.faqServices4")}>
                                <Typography variant="body1" component="span" color="textPrimary">
                                    {proposalInfoServ.bookkeeper ? t("services.otherB") : t("services.other")}
                                </Typography>
                            </MenuItem>
                        </Select>
                    </CustomSelect>
                    <CustomTextArea
                        id="outlined-multiline-static"
                        value={motiveArea}
                        onChange={handleChangeMotiveArea}
                        placeholder={proposalInfoServ.bookkeeper ? t("proposals.explainB") : t("proposals.explain")}
                        multiline
                        rows={4}
                        fullWidth
                        error={error && motiveArea === ""}
                    />
                    <Button
                        disableElevation
                        variant="contained"
                        color="secondary"
                        //onClick={closeDialog}
                        onClick={closeDialogSend}
                        /*setDialogSendOpen*/
                        sx={{
                            borderRadius: 40,
                            fontSize: 16,
                            "&:hover": {
                                backgroundColor: "#2f3190",
                                color: "#fff",
                            },
                            mt: 2,
                        }}
                    >
                        {proposalInfoServ.bookkeeper ? t("miscellaneous.sendB") : t("miscellaneous.send")}
                    </Button>
                </BackModalForm>
            </Grid>
        </Grid>
    );

    const dialogDeclineBody = (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <FormControl error={error && motiveDec === ""}>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="female"
                        name="radio-buttons-group"
                        value={motiveDec}
                        onChange={changeMotiveDec}
                    >
                        <FormControlLabel
                            value={t("proposals.highPrice")}
                            control={<Radio />}
                            label={t("proposals.highPrice")}
                        />
                        <FormControlLabel
                            value={t("proposals.NotUnder")}
                            control={<Radio />}
                            label={t("proposals.NotUnder")}
                        />
                        <FormControlLabel
                            value={t("proposals.notSatisReq")}
                            control={<Radio />}
                            label={t("proposals.notSatisReq")}
                        />
                        <FormControlLabel
                            value={t("proposals.notAgreedT&C")}
                            control={<Radio />}
                            label={t("proposals.notAgreedT&C")}
                        />
                        <FormControlLabel value={t("services.other")} control={<Radio />} label={t("services.other")} />
                    </RadioGroup>
                    <FormHelperText>{helperText}</FormHelperText>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <CustomTextArea
                    id="outlined-multiline-static"
                    placeholder={t("proposals.commentMore")}
                    required
                    multiline
                    rows={4}
                    fullWidth
                    value={motiveDecDetails}
                    onChange={changeMotiveDecDetails}
                    error={error && motiveDecDetails.trim() === ""}
                />
            </Grid>
        </Grid>
    );

    const dialogButtons = (
        <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
            <Grid item>
                <ColorButton
                    onClick={declineProposal}
                    sx={{
                        "&:hover": {
                            backgroundColor: "#2f3190",
                        },
                    }}
                    color="secondary"
                    disableElevation
                    variant="contained"
                >
                    {t("miscellaneous.send")}
                </ColorButton>
            </Grid>
        </Grid>
    );

    const dialogSend = (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h5" sx={{ pb: 4 }}>
                    {proposalInfoServ.bookkeeper ? t("invoicing.sendQuestionB") : t("invoicing.sendQuestion")}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <CheckCircleIcon color="success" sx={{ fontSize: 150 }} />
            </Grid>
        </Grid>
    );

    return (
        <StyledContainer component="main" sx={{ flexGrow: 1, width: "100%", background: "white" }}>
            <AppBar position="relative" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Toolbar className={classes.toolbar}>
                    <Hidden only={["sm", "xs"]}>
                        <Link to="/">
                            <GCALogo className={classes.navbarLogo} />
                        </Link>
                    </Hidden>
                    <Hidden only={["md", "lg", "xl"]}>
                        <Link to="/">
                            <GcaLogoMobile className={classes.headerLogo} />
                        </Link>
                    </Hidden>

                    <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
                        {proposalInfo && isAccepted !== 3 && (
                            <>
                                <Hidden only={["sm", "xs"]}>
                                    <Grid item>
                                        <Typography variant="h5" sx={{ color: "white", fontSize: 16 }}>
                                            {t(proposalInfoServ.bookkeeper ? "miscellaneous.proposalEmitB" : "miscellaneous.proposalEmit") +
                                                moment(deliveryDate, "YYYY-MM-DD").format(
                                                    language === "ES" ? "DD/MM/YYYY" : "MM/DD/YYYY"
                                                )}
                                        </Typography>
                                        <Typography
                                            variant="h5"
                                            sx={{
                                                color:
                                                    moment().diff(moment(deliveryDate, "YYYY-MM-DD"), "weeks") <= 2
                                                        ? "white"
                                                        : "#ff5968",
                                                fontSize: 16,
                                            }}
                                        >
                                            {t(proposalInfoServ.bookkeeper ? "proposals.overdueTextB" : "proposals.overdueText") +
                                                moment(deliveryDate, "YYYY-MM-DD")
                                                    .add(15, "days")
                                                    .format(language === "ES" ? "DD/MM/YYYY" : "MM/DD/YYYY") +
                                                (moment().diff(moment(deliveryDate, "YYYY-MM-DD"), "weeks") <= 2
                                                    ? ""
                                                    : `, ${moment().diff(
                                                        moment(deliveryDate, "YYYY-MM-DD").add(15, "days"),
                                                        "days"
                                                    )} ${t(proposalInfoServ.bookkeeper ? "proposals.overdueDaysB" : "proposals.overdueDays")}`)}
                                        </Typography>
                                    </Grid>
                                </Hidden>
                                {moment().diff(deliveryDate, "weeks") <= 2 && (
                                    <Grid item>
                                        <Hidden only={["sm", "xs"]}>
                                            <ColorButtonSec
                                                variant="contained"
                                                onClick={openModalDecline}
                                                disabled={isAccepted === 4}
                                            >
                                                {proposalInfoServ.bookkeeper ? t("miscellaneous.declineB") : t("miscellaneous.decline")}
                                            </ColorButtonSec>
                                            <ColorButton variant="contained" color="secondary" onClick={acceptProposal}>
                                                {proposalInfoServ.bookkeeper ? t("miscellaneous.acceptB") : t("miscellaneous.accept")}
                                            </ColorButton>
                                        </Hidden>
                                        <Hidden only={["md", "lg", "xl"]}>
                                            <IconButton
                                                size="large"
                                                onClick={openModalDecline}
                                                disabled={isAccepted === 4}
                                            >
                                                <CancelIcon color="secondary" fontSize="inherit" />
                                            </IconButton>
                                            <IconButton size="large" onClick={acceptProposal}>
                                                <CheckCircleIcon sx={{ color: "green" }} fontSize="inherit" />
                                            </IconButton>
                                        </Hidden>
                                    </Grid>
                                )}
                            </>
                        )}
                        {!proposalInfoServ.bookkeeper && (
                            <Grid item>
                                <IconButton aria-label="comment" className={classes.comment} onClick={openComments}>
                                    <Badge badgeContent={commentNum} color="secondary">
                                        <CommentIcon color="primary" />
                                    </Badge>
                                </IconButton>
                                <ul className={classes.listLang}>
                                    <li className={language === "EN" ? "activeList" : "notActiveList"}>
                                        <Typography
                                            variant="body2"
                                            onClick={() => handleUpdateLanguage("EN")}
                                            className={classes.languageBtn}
                                        >
                                            EN
                                        </Typography>
                                    </li>
                                    <Divider classes={{ root: classes.divider }} orientation="vertical" />
                                    <li className={language === "ES" ? "activeList" : "notActiveList"}>
                                        <Typography
                                            variant="body2"
                                            onClick={() => handleUpdateLanguage("ES")}
                                            className={classes.languageBtn}
                                        >
                                            ES
                                        </Typography>
                                    </li>
                                </ul>
                            </Grid>
                        )}
                    </Grid>
                </Toolbar>
            </AppBar>

            <Box sx={{ backgroundColor: "#B3BACB" }}>
                <Container maxWidth="md" disableGutters sx={{ pt: 1, pb: 4 }}>
                    <Box sx={{ width: "fit-content", margin: "20px auto" }}>
                        {isAccepted === 3 && (
                            <Alert severity="success" sx={{ borderRadius: 40 }}>
                                {t("proposals.acceptMess")}
                            </Alert>
                        )}
                        {isAccepted === 4 && (
                            <Alert icon={<CancelIcon fontSize="inherit" />} severity="error" sx={{ borderRadius: 40 }}>
                                {" "}
                                {t("proposals.declineMess")}
                            </Alert>
                        )}
                    </Box>
                    {proposalInfo ? (
                        <>
                            {proposalInfoServ.accountant && (
                                <>
                                    <FrontPage proposalDetails={proposalInfoServ.accountant} mode="final" />
                                    <Team proposalDetails={proposalInfoServ.accountant} mode="final" />
                                    {!isConsulting && <ServiceLevels
                                        proposalDetails={proposalInfo}
                                        mode="final"
                                        includeAccModuleSet={includeAccModuleSet}
                                        includeAccModuleSub={includeAccModuleSub}
                                        includeAdmModuleSet={includeAdmModuleSet}
                                        includeAdmModuleSub={includeAdmModuleSub}
                                        accountantOnboarding={accountantOnboarding}
                                    />}
                                    <Pricing proposalDetails={proposalInfo} mode="final" />
                                    {!isConsulting && <Schedule
                                        proposalDetails={proposalInfo}
                                        mode="final"
                                        includeAccModuleSet={includeAccModuleSet}
                                        includeAccModuleSub={includeAccModuleSub}
                                        includeAdmModuleSet={includeAdmModuleSet}
                                        includeAdmModuleSub={includeAdmModuleSub}
                                        accountantOnboarding={accountantOnboarding}
                                        isAccountant={proposalInfoServ?.accountant}
                                    />}
                                    <TermsConditions proposalDetails={proposalInfo} mode="final" market={proposalInfo?.reference.includes("VE") ? "VE" : proposalInfo?.reference.includes("US") ? "US" : proposalInfo?.reference.includes("GB") ? "GB" : "LA"} />
                                    <FinalPage proposalDetails={proposalInfoServ.accountant} mode="final" />
                                </>
                            )}
                            {proposalInfoServ.bookkeeper && (
                                <>
                                    <FrontPage proposalDetails={proposalInfoServ.bookkeeper} mode="final" />
                                    <Team proposalDetails={proposalInfoServ.bookkeeper} includeToolbox={includeToolbox} includeOnboarding={includeOnboarding} mode="final" />
                                    <ServiceLevels
                                        proposalDetails={proposalInfo}
                                        includeToolbox={includeToolbox}
                                        includeOnboarding={includeOnboarding}
                                        includeAccModuleSet={includeAccModuleSet}
                                        includeAccModuleSub={includeAccModuleSub}
                                        includeAdmModuleSet={includeAdmModuleSet}
                                        includeAdmModuleSub={includeAdmModuleSub}
                                        isBook={proposalInfoServ?.bookkeeper}
                                        mode="final"
                                    />
                                    <Pricing proposalDetails={proposalInfo} includeToolbox={includeToolbox} includeOnboarding={includeOnboarding} mode="final" />
                                    <Schedule
                                        proposalDetails={proposalInfo}
                                        mode="final"
                                        includeToolbox={includeToolbox}
                                        includeOnboarding={includeOnboarding}
                                        includeAccModuleSet={includeAccModuleSet}
                                        includeAccModuleSub={includeAccModuleSub}
                                        includeAdmModuleSet={includeAdmModuleSet}
                                        includeAdmModuleSub={includeAdmModuleSub}
                                        isBook={proposalInfoServ?.bookkeeper}
                                    />
                                    <TermsConditions
                                        proposalDetails={proposalInfo}
                                        includeToolbox={includeToolbox}
                                        includeOnboarding={includeOnboarding}
                                        mode="final"
                                        market={proposalInfo?.reference.includes("VE") ? "VE" : proposalInfo?.reference.includes("US") ? "US" : proposalInfo?.reference.includes("GB") ? "GB" : "LA"}
                                        isBook={proposalInfoServ?.bookkeeper}
                                    />
                                    <FinalPage proposalDetails={proposalInfoServ.bookkeeper} includeToolbox={includeToolbox} includeOnboarding={includeOnboarding} mode="final" />
                                </>
                            )}
                            {proposalInfoServ.controller && !proposalInfoServ.accountant && (
                                <>
                                    <FrontPage proposalDetails={proposalInfoServ.controller} mode="final" />
                                    <Team proposalDetails={proposalInfoServ.controller} mode="final" />
                                    {!isConsulting && <ServiceLevels
                                        proposalDetails={proposalInfo}
                                        mode="final"
                                        includeToolbox={includeToolbox}
                                        includeOnboarding={includeOnboarding}
                                        includeAccModuleSet={includeAccModuleSet}
                                        includeAccModuleSub={includeAccModuleSub}
                                        includeAdmModuleSet={includeAdmModuleSet}
                                        includeAdmModuleSub={includeAdmModuleSub}
                                    />}
                                    <Pricing proposalDetails={proposalInfo} mode="final" />
                                    {!isCatchUp && <Schedule
                                        proposalDetails={proposalInfo}
                                        mode="final"
                                        includeAccModuleSet={includeAccModuleSet}
                                        includeAccModuleSub={includeAccModuleSub}
                                        includeAdmModuleSet={includeAdmModuleSet}
                                        includeAdmModuleSub={includeAdmModuleSub}
                                    />}
                                    <TermsConditions proposalDetails={proposalInfo} mode="final" market={proposalInfo?.reference.includes("VE") ? "VE" : proposalInfo?.reference.includes("US") ? "US" : proposalInfo?.reference.includes("GB") ? "GB" : "LA"} />
                                    <FinalPage proposalDetails={proposalInfoServ.controller} mode="final" />
                                </>
                            )}
                            {proposalInfoServ.cfo && !proposalInfoServ.accountant && (
                                <>
                                    <FrontPage proposalDetails={proposalInfoServ.cfo} mode="final" />
                                    <Team proposalDetails={proposalInfoServ.cfo} mode="final" />
                                    {!isConsulting && <ServiceLevels
                                        proposalDetails={proposalInfo}
                                        mode="final"
                                        includeToolbox={includeToolbox}
                                        includeOnboarding={includeOnboarding}
                                        includeAccModuleSet={includeAccModuleSet}
                                        includeAccModuleSub={includeAccModuleSub}
                                        includeAdmModuleSet={includeAdmModuleSet}
                                        includeAdmModuleSub={includeAdmModuleSub}
                                    />}
                                    <Pricing proposalDetails={proposalInfo} mode="final" />
                                    {!isConsulting && <Schedule
                                        proposalDetails={proposalInfo}
                                        mode="final"
                                        includeToolbox={includeToolbox}
                                        includeOnboarding={includeOnboarding}
                                        includeAccModuleSet={includeAccModuleSet}
                                        includeAccModuleSub={includeAccModuleSub}
                                        includeAdmModuleSet={includeAdmModuleSet}
                                        includeAdmModuleSub={includeAdmModuleSub}
                                    />}
                                    <TermsConditions proposalDetails={proposalInfo} mode="final" market={proposalInfo?.reference.includes("VE") ? "VE" : proposalInfo?.reference.includes("US") ? "US" : proposalInfo?.reference.includes("GB") ? "GB" : "LA"} />
                                    <FinalPage proposalDetails={proposalInfoServ.cfo} mode="final" />
                                </>
                            )}
                            {proposalInfoServ.software &&
                                !proposalInfoServ.accountant &&
                                !proposalInfoServ.bookkeeper &&
                                !proposalInfoServ.controller &&
                                !proposalInfoServ.cfo && (
                                    <>
                                        <FrontPage proposalDetails={proposalInfoServ.software} mode="final" />
                                        <Team proposalDetails={proposalInfoServ.software} mode="final" />
                                        <ServiceLevels
                                            proposalDetails={proposalInfo}
                                            mode="final"
                                            includeAccModuleSet={includeAccModuleSet}
                                            includeAccModuleSub={includeAccModuleSub}
                                            includeAdmModuleSet={includeAdmModuleSet}
                                            includeAdmModuleSub={includeAdmModuleSub}
                                            accountantOnboarding={accountantOnboarding}
                                            isBook={proposalInfoServ?.bookkeeper}
                                            isAccountant={proposalInfoServ?.accountant}
                                        />
                                        <Pricing proposalDetails={proposalInfo} mode="final" />
                                        {isSetup && <Schedule
                                            proposalDetails={proposalInfo}
                                            mode="final"
                                            includeAccModuleSet={includeAccModuleSet}
                                            includeAccModuleSub={includeAccModuleSub}
                                            includeAdmModuleSet={includeAdmModuleSet}
                                            includeAdmModuleSub={includeAdmModuleSub}
                                            accountantOnboarding={accountantOnboarding}
                                            isBook={proposalInfoServ?.bookkeeper}
                                            isAccountant={proposalInfoServ?.accountant}
                                        />}
                                        <TermsConditions proposalDetails={proposalInfo} mode="final" market={proposalInfo?.reference.includes("VE") ? "VE" : proposalInfo?.reference.includes("US") ? "US" : proposalInfo?.reference.includes("GB") ? "GB" : "LA"} />
                                        <FinalPage proposalDetails={proposalInfoServ.software} mode="final" />
                                    </>
                                )}
                        </>
                    ) : (
                        <Box sx={{ height: 1000 }}></Box>
                    )}
                    <Box ref={finalPageRef} className={classes.showAsk}></Box>
                    {isAccepted !== 3 && moment().diff(deliveryDate, "weeks") <= 2 && (
                        <Box
                            sx={{
                                backgroundColor: "#F8F9F8",
                                borderRadius: 10,
                                p: { xs: 4, md: 8 },
                                mb: { xs: 10, md: 0 },
                                textAlign: "center",
                                position: "fixed",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                            }}
                            className={finalPIsVisible ? "ask-modal-show" : "ask-modal-hide"}
                        >
                            <Typography variant="h1" className={classes.headerText}>
                                {t("miscellaneous.areYouReady")}{" "}
                                <Typography component="span" className={classes.headerText} sx={{ color: "#FF5968" }}>
                                    {t("invoicing.accounting")}
                                </Typography>
                                ?
                            </Typography>
                            <Grid
                                container
                                spacing={2}
                                justifyContent="space-around"
                                alignItems="center"
                                sx={{ pt: 7 }}
                            >
                                <Grid item xs={12} md={4}>
                                    <ColorButtonSec
                                        variant="contained"
                                        sx={{ p: "5px 60px", fontSize: "28px" }}
                                        onClick={openModalDecline}
                                        disabled={isAccepted === 4}
                                    >
                                        {t("miscellaneous.decline")}
                                    </ColorButtonSec>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <ColorButton
                                        variant="contained"
                                        color="secondary"
                                        sx={{ p: "5px 60px", fontSize: "28px" }}
                                        onClick={acceptProposal}
                                    >
                                        {t("miscellaneous.accept")}
                                    </ColorButton>
                                </Grid>
                            </Grid>
                        </Box>
                    )}
                </Container>
            </Box>
            <LightTooltip title={proposalInfoServ.bookkeeper ? t("invoicing.haveQuestionB") : t("invoicing.haveQuestion")} aria-label="directory-tooltip" placement="top">
                <Fab
                    color="primary"
                    aria-label="add"
                    sx={{
                        position: "fixed",
                        bottom: 15,
                        right: window.innerWidth > 600 ? 100 : 15,
                    }}
                    onClick={() => setDialogForm(true)}
                >
                    <QuestionMarkIcon />
                </Fab>
            </LightTooltip>
            <Dialog
                open={dialogForm}
                onClose={closeDialog}
                message={dialogBody}
                title={proposalInfoServ.bookkeeper ? t("invoicing.haveQuestionB") : t("invoicing.haveQuestion")}
                maxWidth="md"
            />
            <Dialog open={dialogSendOpen} onClose={closeDialog} message={dialogSend} title="" maxWidth="md" />
            <DeclineDialog
                open={declineForm}
                onClose={closeDialog}
                message={dialogDeclineBody}
                title={t("proposals.declineTitle")}
                maxWidth="sm"
                button={dialogButtons}
            />
            <AcceptDialog open={dialogAcceptForm} onClose={closeDialog} maxWidth="md" />
            <Backdrop open={showBackdrop} />
            <CommDialProp
                open={dialogComment}
                onClose={closeCommentsDialog}
                proposalID={proposalID}
                setTexto={setTexto}
                setAutoAlertType={setAutoAlertType}
                setBackDrop={setBackDrop}
                setShow={setShow}
                comments={comments}
                getComments={getComments}
                commentType={2}
                loadInitData={getProposalInfo}
                proposalInfo={proposalInfo}
            />
            <SnackBar type={alertType} show={show} message={texto} onClose={handleClose} />
        </StyledContainer>
    );
};

export default ProposalPresent;
