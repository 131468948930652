// React
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";

// MUI icons
import CheckIcon from "@mui/icons-material/Check";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditIcon from "@mui/icons-material/Edit";
import EditOffIcon from "@mui/icons-material/EditOff";

// MUI
import { styled } from "@mui/material/styles";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import {
    Box,
    FormControlLabel,
    InputAdornment,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Switch,
    TextField,
    Typography,
    FormControl,
    InputLabel,
    OutlinedInput,
    IconButton,
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Grid from "@mui/material/Grid";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

/** Component imports */
import { UpdateAdd } from "./";

const PREFIX = "catchUp";

const classes = {
    switch: `${PREFIX}-switch`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    backgroundColor: "#F8F9F8",
    marginTop: 16,
    padding: 30,
    borderRadius: 30,

    [`& .${classes.switch}`]: {
        "& .MuiSwitch-switchBase .MuiSwitch-thumb": {
            color: "#FFF",
        },
        "& .MuiSwitch-switchBase + .MuiSwitch-track": {
            backgroundColor: "#031851",
        },
        "& .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb": {
            color: "#FF5968",
        },
        "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
            backgroundColor: "#FFA8B1",
        },
    },
}));

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)({
    backgroundColor: "#F8F9F8",
    boxShadow: "none",
    "&:before": {
        display: "none",
    },
});

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: "#031851" }} />} {...props} />
))({
    "& .MuiAccordionSummary-content": {
        margin: "12px 0",
    },
});

const AccordionDetails = styled(MuiAccordionDetails)({
    padding: "0px 0px 20px 0px",
    "& .MuiCard-root": {
        boxShadow: "none",
    },
});

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "rgba(19, 31, 62, 0.80)",
        fontSize: 12,
        maxWidth: 400,
        borderRadius: 6,
        fontWeight: "normal",
    },
});

export const CatchUp = (props) => {
    // Properties
    const { setTexto, setAutoAlertType, setShow, values, setValues, isError, isErrorFocus, setIsErrorFocus } = props;
    
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    /** React redux */
    const todos = useSelector((state) => state.todos);

    /** Component states */
    const [editMode, setEditMode] = useState(true);
    const [editMode2, setEditMode2] = useState(true);

    /** Global variables */
    const checks = values.checkedExe || values.checkedCons || values.checkedRep || values.checkedOthers;

    const sumTotal =
        parseFloat(values.tothExe) *
            (values.fixedHours ? 1 : (isNaN(moment(values.finishDateExe).diff(moment(values.startDateExe)))
                ? 0
                : Math.ceil(moment(values.finishDateExe).diff(moment(values.startDateExe), "months", true)))) +
        parseFloat(values.tothCons) *
            (values.fixedHours ? 1 : (isNaN(moment(values.finishDateCons).diff(moment(values.startDateCons)))
                ? 0
                : Math.ceil(moment(values.finishDateCons).diff(moment(values.startDateCons), "months", true)))) +
        parseFloat(values.tothRep) *
            (values.fixedHours ? 1 : (isNaN(moment(values.finishDateRep).diff(moment(values.startDateRep)))
                ? 0
                : Math.ceil(moment(values.finishDateRep).diff(moment(values.startDateRep), "months", true)))) +
        parseFloat(values.tothOthers) *
            (values.fixedHours ? 1 : (isNaN(moment(values.finishDateOthers).diff(moment(values.startDateOthers)))
                ? 0
                : Math.ceil(moment(values.finishDateOthers).diff(moment(values.startDateOthers), "months", true))));

    /** Component functions */
    useEffect(() => {
        setValues({
            ...values,
            catchUpChecks: checks,
            totalHours: sumTotal,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sumTotal, checks]);

    const handleChangeInitDate = (date) => {
        let startDate = moment(date).startOf("month");

        if (!date || !values.untilDate) {
            setValues({
                ...values,
                sinceDate: date ? startDate : date,
            });
        } else {
            if (startDate.isAfter(moment(values.untilDate).format("YYYY-MM-DD"))) {
                setValues({
                    ...values,
                    sinceDate: null,
                    isError:false
                });

                setTexto(t("reports.initDateMess"));
                setAutoAlertType("warning");
                setShow(true);
            } else {
                let meses = Math.ceil(values.untilDate.diff(startDate, "months", true));

                let accMonths = 0;
                if (values.additionalsOnly) {
                    if (values.fixedHours){
                        accMonths = 1
                    } else {
                        accMonths = Math.ceil(moment(values.finishDateCont).diff(startDate, "months", true));
                    }
                }

                setValues({
                    ...values,
                    sinceDate: startDate,
                    projectTotal: values.additionalsOnly
                        ? values.tothCont * values.hourRate * accMonths * (1 - values.discount / 100)
                        : values.catchUpOriginalFee * meses * (1 - values.discount / 100),
                    months: values.additionalsOnly ? accMonths : meses,
                    // Adicionales
                    startDateCont: values.checkedCont && values.startDateCont ? startDate : null,
                    startDateExe: values.checkedExe && values.startDateExe ? startDate : null,
                    startDateCons: values.checkedCons && values.startDateCons ? startDate : null,
                    startDateRep: values.checkedRep && values.startDateRep ? startDate : null,
                    // Otros
                    startDateOthers: values.checkedOthers && values.startDateOthers ? startDate : null,
                    isError:false
                });
            }
        }

        setIsErrorFocus(false)
    };

    const handleChangeEndDate = (date) => {
        let untilDate = moment(date).endOf("month").startOf("day");

        if (!date || !values.sinceDate) {
            setValues({
                ...values,
                untilDate: date ? untilDate : date,
            });
        } else {
            if (untilDate.isBefore(moment(values.sinceDate).format("YYYY-MM-DD"))) {
                setValues({
                    ...values,
                    untilDate: null,
                    isError:false
                });

                setTexto(t("reports.endDateMess"));
                setAutoAlertType("warning");
                setShow(true);
            } else {
                let meses = Math.ceil(untilDate.diff(values.sinceDate, "months", true));

                let accMonths = 0;
                if (values.additionalsOnly) {
                    if (values.fixedHours){
                        accMonths = 1
                    } else {
                        accMonths = Math.ceil(untilDate.diff(moment(values.startDateCont), "months", true));
                    }
                }

                setValues({
                    ...values,
                    untilDate: untilDate,
                    projectTotal: values.additionalsOnly
                        ? values.tothCont * values.hourRate * accMonths * (1 - values.discount / 100)
                        : values.catchUpOriginalFee * meses * (1 - values.discount / 100),
                    months: values.additionalsOnly ? accMonths : meses,
                    // Adicionales
                    finishDateCont: values.checkedCont && values.finishDateCont ? untilDate : null,
                    finishDateExe: values.checkedExe && values.finishDateExe ? untilDate : null,
                    finishDateCons: values.checkedCons && values.finishDateCons ? untilDate : null,
                    finishDateRep: values.checkedRep && values.finishDateRep ? untilDate : null,
                    // Otros
                    finishDateOthers: values.checkedOthers && values.finishDateOthers ? untilDate : null,
                    isError:false
                });
            }
        }
        setIsErrorFocus(false)
    };

    const handleChangeSwitchHourly = (event) => {
        let meses = Math.ceil(values.untilDate.diff(values.sinceDate, "months", true));

        setValues({
            ...values,
            discount: event.target.checked ? 0 : 10,
            projectTotal: event.target.checked ? 0 : values.catchUpOriginalFee * meses * 0.7,
            months: meses,
            catchUpAdditionalsDiscount: event.target.checked ? 0 : 10,
            additionalsOnly: event.target.checked,
            checkedCont: event.target.checked,
            tothCont: 0,
            startDateCont: event.target.checked ? values.sinceDate : null,
            finishDateCont: event.target.checked ? values.untilDate : null,
            isError: false,
            fixedHours: false,
        });
        setIsErrorFocus(false)
    };

    const handleChangeSwitchFixedHours = (event) => {

        setValues({
            ...values,
            fixedHours: event.target.checked,
            projectTotal: 0,
            tothCont: 0,
        });

        setIsErrorFocus(false)
    };

    const handleChangeDiscount = (event) => {
        let discountAux = event.target.value >= 0 && event.target.value <= 10 ? event.target.value : values.discount;

        setValues({
            ...values,
            discount: discountAux,
            catchUpAdditionalsDiscount: discountAux,
            projectTotal: values.additionalsOnly
                ? values.fixedHours ? values.tothCont * values.hourRate * (1 - discountAux / 100) : values.tothCont * values.hourRate * values.months * (1 - discountAux / 100)
                : values.catchUpOriginalFee * values.months * (1 - discountAux / 100),
                isError:false
        });
        setIsErrorFocus(false)
    };

    const handleClickShowDiscount = () => {
        setEditMode(!editMode);
    };

    const handleClickShowDiscount2 = () => {
        setEditMode2(!editMode2);
    };

    const handleMouseDownDiscount = (event) => {
        event.preventDefault();
    };

    return (
        <StyledContainer>
            <LocalizationProvider
                dateAdapter={AdapterMoment}
                adapterLocale={t("language.locale")}
                localeText={{ clearButtonLabel: t("miscellaneous.clear") }}
            >
                <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={12}>
                        <Typography
                            variant="h1"
                            color="primary"
                            sx={{
                                display: "inline-block",
                                fontWeight: 700,
                                pr: 6,
                            }}
                        >
                            Catch-Up
                        </Typography>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={values.additionalsOnly}
                                    className={classes.switch}
                                    color="secondary"
                                    disabled={!values.sinceDate || !values.untilDate}
                                    inputProps={{ "aria-label": "controlled" }}
                                    name="payAnnuallyCatchUp"
                                    onChange={handleChangeSwitchHourly}
                                />
                            }
                            label={
                                <Typography variant="body2" align="center" sx={{ lineHeight: 0 }}>
                                    {t("proposals.hourly")}
                                </Typography>
                            }
                        />
                        {values.additionalsOnly && <FormControlLabel
                            control={
                                <Switch
                                    checked={values.fixedHours}
                                    className={classes.switch}
                                    color="secondary"
                                    disabled={!values.additionalsOnly}
                                    inputProps={{ "aria-label": "controlled" }}
                                    name="fixedHoursCatchUp"
                                    onChange={handleChangeSwitchFixedHours}
                                />
                            }
                            label={
                                <Typography variant="body2" align="center" sx={{ lineHeight: 0 }}>
                                    {t("proposals.fixedHours")}
                                </Typography>
                            }
                        />}
                    </Grid>
                    <Grid item xs={12}>
                        <Accordion
                            TransitionProps={{ unmountOnExit: true }}
                            sx={{
                                borderBottom: "3px solid #ff5968",
                            }}
                        >
                            <AccordionSummary aria-controls="panel-content" id="panel-header">
                                <Typography
                                    variant="h2"
                                    align="center"
                                    sx={{
                                        fontWeight: 700,
                                    }}
                                >
                                    {t("invoicing.description")}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <List>
                                    <ListItem disableGutters alignItems="center">
                                        <ListItemIcon sx={{ minWidth: 35 }}>
                                            <CheckIcon fontSize="small" sx={{ color: "#031851" }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={
                                                <Typography variant="body1">
                                                    <span style={{ fontWeight: 600 }}>{t("proposals.contrDed")} </span>
                                                    - {t("proposals.aPubAccCont")}
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                    <ListItem disableGutters alignItems="center">
                                        <ListItemIcon sx={{ minWidth: 35 }}>
                                            <CheckIcon fontSize="small" sx={{ color: "#031851" }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={
                                                <Typography variant="body1">
                                                    <span style={{ fontWeight: 600 }}>{t("proposals.arqFinanCont")} </span>
                                                    - {t("proposals.arqFinanContDet")}
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                    <ListItem disableGutters alignItems="center">
                                        <ListItemIcon sx={{ minWidth: 35 }}>
                                            <CheckIcon fontSize="small" sx={{ color: "#031851" }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={
                                                <Typography variant="body1">
                                                    <span style={{ fontWeight: 600 }}>{t("proposals.interContFin")} </span>
                                                    - {t("proposals.interContFinDet")}
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                    <ListItem disableGutters alignItems="center">
                                        <ListItemIcon sx={{ minWidth: 35 }}>
                                            <CheckIcon fontSize="small" sx={{ color: "#031851" }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={
                                                <Typography variant="body1">
                                                    <span style={{ fontWeight: 600 }}>{t("proposals.politProc")} </span>
                                                    - {t("proposals.politProcDet")}
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                    <ListItem disableGutters alignItems="center">
                                        <ListItemIcon sx={{ minWidth: 35 }}>
                                            <CheckIcon fontSize="small" sx={{ color: "#031851" }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={
                                                <Typography variant="body1">
                                                    <span style={{ fontWeight: 600 }}>{t("proposals.addPerRep")} </span>
                                                    - {t("proposals.addTayMade")}
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                    <ListItem disableGutters alignItems="center">
                                        <ListItemIcon sx={{ minWidth: 35 }}>
                                            <CheckIcon fontSize="small" sx={{ color: "#031851" }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={
                                                <Typography variant="body1">
                                                    <span style={{ fontWeight: 600 }}>
                                                        {t("proposals.virtualMeet")}{" "}
                                                    </span>
                                                    - {t("proposals.meetingFinCont")}
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                    <ListItem disableGutters alignItems="center">
                                        <ListItemIcon sx={{ minWidth: 35 }}>
                                            <CheckIcon fontSize="small" sx={{ color: "#031851" }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={
                                                <Typography variant="body1">
                                                    <span style={{ fontWeight: 600 }}>
                                                        {t("proposals.consoFinanCont")}{" "}
                                                    </span>
                                                    - {t("proposals.finanConDet")}
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                    <ListItem disableGutters alignItems="center">
                                        <ListItemIcon sx={{ minWidth: 35 }}>
                                            <CheckIcon fontSize="small" sx={{ color: "#031851" }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={
                                                <Typography variant="body1">
                                                    <span style={{ fontWeight: 600 }}>
                                                        {t("proposals.writtenSup")}{" "}
                                                    </span>
                                                    - {t("proposals.UnlimSup")}
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                </List>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2} sx={{ mt: 0.5 }}>
                    <Grid item xs={12} sm={6} lg={3}>
                        <DatePicker
                            componentsProps={{
                                actionBar: { actions: ["clear"] },
                            }}
                            value={values.sinceDate}
                            onChange={(newValue) => handleChangeInitDate(newValue)}
                            inputFormat={todos.dateFormat}
                            renderInput={(params) => (
                                <TextField fullWidth required {...params} error={isError && !values.sinceDate} onClick={(e) =>{setIsErrorFocus(false)}}/>
                            )}
                            // inputProps={{ readOnly: true }}
                            label={t("miscellaneous.sinceDate")}
                            views={["year", "month"]}
                            inputProps={{
                                onKeyDown: (event) => {
                                    event.preventDefault();
                                },
                            }}
                            onClick={(e) =>{setIsErrorFocus(false)}}
                            inputRef={(input) => { (input && isErrorFocus && isError && !values.sinceDate) && input.focus()}}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                        <DatePicker
                            componentsProps={{
                                actionBar: { actions: ["clear"] },
                            }}
                            value={values.untilDate}
                            onChange={(newValue) => handleChangeEndDate(newValue)}
                            inputFormat={todos.dateFormat}
                            renderInput={(params) => (
                                <TextField fullWidth required {...params} error={isError && !values.untilDate} onClick={(e) =>{setIsErrorFocus(false)}}/>
                            )}
                            // inputProps={{ readOnly: true }}
                            label={t("miscellaneous.toDate")}
                            views={["year", "month"]}
                            inputProps={{
                                onKeyDown: (event) => {
                                    event.preventDefault();
                                },
                            }}
                            onClick={(e) =>{setIsErrorFocus(false)}}
                            inputRef={(input) => { (input && isErrorFocus && isError && !values.untilDate) && input.focus()}}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                        <FormControl variant="outlined" disabled={editMode} fullWidth>
                            <InputLabel htmlFor="outlined-adornment-discount">{t("miscellaneous.discount")}</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-discount"
                                type="number"
                                name="discount"
                                value={values.discount}
                                onChange={handleChangeDiscount}
                                onBlur={() =>
                                    setValues({
                                        ...values,
                                        discount: parseFloat(values.discount === "" ? 0 : values.discount).toFixed(2),
                                        catchUpAdditionalsDiscount: parseFloat(
                                            values.discount === "" ? 0 : values.discount
                                        ).toFixed(2),
                                    })
                                }
                                inputProps={{
                                    min: 0,
                                    max: 10,
                                }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        %
                                        <LightTooltip
                                            title={
                                                editMode
                                                    ? t("services.catchupMaximunDiscount")
                                                    : t("miscellaneous.save")
                                            }
                                            aria-label="edit"
                                        >
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowDiscount}
                                                onMouseDown={handleMouseDownDiscount}
                                                edge="end"
                                            >
                                                {!editMode ? <EditOffIcon /> : <EditIcon />}
                                            </IconButton>
                                        </LightTooltip>
                                    </InputAdornment>
                                }
                                label={t("miscellaneous.discount")}
                                sx={{
                                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                        display: "none",
                                    },
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                        <FormControl variant="outlined" disabled={editMode2} fullWidth>
                            <InputLabel htmlFor="outlined-adornment-additionalsDiscount">
                                {t("proposals.additionalsDiscount")}
                            </InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-additionalsDiscount"
                                type="number"
                                name="additionalsDiscount"
                                value={values.catchUpAdditionalsDiscount}
                                onChange={handleChangeDiscount}
                                onBlur={() =>
                                    setValues({
                                        ...values,
                                        catchUpAdditionalsDiscount: parseFloat(
                                            values.catchUpAdditionalsDiscount === ""
                                                ? 0
                                                : values.catchUpAdditionalsDiscount
                                        ).toFixed(2),
                                        discount: parseFloat(
                                            values.catchUpAdditionalsDiscount === ""
                                                ? 0
                                                : values.catchUpAdditionalsDiscount
                                        ).toFixed(2),
                                    })
                                }
                                inputProps={{
                                    min: 0,
                                    max: 10,
                                }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        %
                                        <LightTooltip
                                            title={
                                                editMode2
                                                    ? t("services.catchupMaximunDiscount")
                                                    : t("miscellaneous.save")
                                            }
                                            aria-label="edit"
                                        >
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowDiscount2}
                                                onMouseDown={handleMouseDownDiscount}
                                                edge="end"
                                            >
                                                {!editMode2 ? <EditOffIcon /> : <EditIcon />}
                                            </IconButton>
                                        </LightTooltip>
                                    </InputAdornment>
                                }
                                label={t("proposals.additionalsDiscount")}
                                sx={{
                                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                        display: "none",
                                    },
                                }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <UpdateAdd
                    values={values}
                    set={setValues}
                    setTexto={setTexto}
                    setalertType={setAutoAlertType}
                    setShow={setShow}
                    sumTotal={sumTotal}
                    isError={isError}
                    isErrorFocus={isErrorFocus}
                    setIsErrorFocus={setIsErrorFocus}
                />
            </LocalizationProvider>
        </StyledContainer>
    );
};
