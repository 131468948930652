import axios from "axios";

/* Script que contiene las llamadas a la API */
const auth = {
    // Función para iniciar sesión
    login: (params) => {
        const request = axios.post("/login", params);

        return request.then((response) => response.data);
    },

    // Función para reinicar la clave
    forgotPassword: (params) => {
        const request = axios.post("/forgotPassword", params);

        return request.then((response) => response);
    },

    // Función que desloguea al usuario actual y destruye la sesión
    logoutFunction: () => {
        const request = axios.get("/logout");

        return request.then((response) => response);
    },

    // Función para verificar si la sesión esta activa
    checkSession: () => {
        const request = axios.get("/checkSession");

        return request.then((response) => response.data);
    },

    // Función para actualizar el estatus de la verificacion de cuenta
    updateUserVerification: (params) => {
        const request = axios.post("/updateUserVerification", params);

        return request.then((response) => response);
    },

    // Función para verificar la cuenta
    verifyAccount: (params) => {
        const request = axios.post("/verifyAccount", params);

        return request.then((response) => response);
    },

    // Función para creacion de nuevo usuario
    createNewUser: (params) => {
        const request = axios.post("/createNewUser", params);

        return request.then((response) => response);
    },

    // Función para validar nuevo correo
    verifyNewUserEmail: (params) => {
        const request = axios.post("/verifyNewUserEmail", params);

        return request.then((response) => response.data);
    },

    // Función para obtener la configuracion del usuario
    getUserConfig: (id) => {
        const request = axios.get(`/getUserConfig/${id}`);

        return request.then((response) => response);
    },
};

export default auth;
