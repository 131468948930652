/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

/** MUI icons */
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

/** MUI imports */
import { styled } from "@mui/material/styles";
import {
    Box,
    Breadcrumbs,
    Card,
    CardContent,
    FormControlLabel,
    Grid,
    Stack,
    Switch,
    Tabs,
    Tab,
    Typography,
} from "@mui/material";

/** Components imports */
import Backdrop from "../../Backdrop";
import UpInvoice from "./UpInvoice";
import UpInvoicing from "./UpInvoicing";
import UpReport from "./UpReport";
import UpValidate from "./UpValidate";
import UpValidateSkeletons from "./UpValidateSkeletons";

const PREFIX = "Update";

const classes = {
    cardContentRoot: `${PREFIX}-cardContentRoot`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.cardContentRoot}`]: {
        "&:last-child": {
            paddingBottom: "18px",
        },
        padding: 30,
    },
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const CustomTab = styled(Tab)(({ theme }) => ({
    padding: 20,
    marginLeft: 8,
    borderRadius: "20px 20px 0 0",
    backgroundColor: "#E9EAE8",
    color: theme.palette.primary.main,
    fontWeight: 700,
    "&.Mui-selected": {
        borderTop: "2px solid #ff5968",
        borderRight: "2px solid #ff5968",
        borderLeft: "2px solid #ff5968",
        backgroundColor: "#fff !important",
    },
}));

const CustomSwitch = styled(Switch)({
    "& .MuiSwitch-switchBase .MuiSwitch-thumb": {
        color: "#FFF",
    },
    "& .MuiSwitch-switchBase + .MuiSwitch-track": {
        backgroundColor: "#031851",
    },
    "& .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb": {
        color: "#FF5968",
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
        backgroundColor: "#FFA8B1",
    },
});

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 48 * 4.5 + 8,
            boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
        },
        sx: {
            borderRadius: 2,
            mt: 0.7,
        },
    },
    variant: "menu",
    getContentAnchorEl: null
};

const Update = () => {
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    /** Component states */
    const [valueTab, setValueTab] = useState(0);
    const [voidedSwitch, setVoidedSwitch] = useState(false);
    const [showBackdrop, setBackDrop] = useState(false);
    const [loading, showLoading] = useState(false);
    const [typeData, setTypeData] = useState([]);
    const [franqData, setFranqData] = useState([]);
    const [groupData, setGroupData] = useState([]);

    /** Global variables */
    const breadcrumbs = [
        <Typography
            key="1"
            variant="h1"
            color="primary"
            sx={{
                fontSize: 24,
                fontWeight: 700,
            }}
        >
            {t("principal.reports")}
        </Typography>,
        <Typography
            key="2"
            variant="h1"
            color="secondary"
            sx={{
                fontSize: 24,
                fontWeight: 700,
            }}
        >
            {t("principal.updates")}
        </Typography>,
    ];

    /** Component functions */
    const handleChangeTab = async (event, newValue) => {
        setBackDrop(true);
        setValueTab(newValue);
        setBackDrop(false);
    };

    const onVoidedClick = (event) => {
        setVoidedSwitch(event.target.checked);
    };

    /* Component events */
    useEffect(() => {
        (async () => {
            showLoading(true);

            await fetch(`/getClientTypeFilter`, {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
                .then((res) => res.json())
                .then((data) => {
                    setTypeData(data);
                });

            await fetch(`/getClientFranchiseFilter`, {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
                .then((res) => res.json())
                .then((data) => {
                    setFranqData(data);
                });

            await fetch(`/getClientGroupsFilter`, {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
                .then((res) => res.json())
                .then((data) => {
                    setGroupData(data);
                });

            showLoading(false);
        })();
    }, [t]);

    return (
        <StyledContainer component="main" sx={{ flexGrow: 1, p: 4, width: "95%", pt: 10 }}>
            <Stack sx={{ pb: 4 }}>
                <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small" sx={{ color: "#031851" }} />}
                    aria-label="breadcrumb"
                >
                    {breadcrumbs}
                </Breadcrumbs>
            </Stack>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card
                        elevation={0}
                        sx={{
                            borderRadius: 8,
                            boxShadow: "10px 10px 15px rgba(3,24,81,0.15)",
                        }}
                    >
                        <CardContent classes={{ root: classes.cardContentRoot }}>
                            <Box sx={{ width: "100%" }}>
                                <Box sx={{ borderBottom: 2, borderColor: "#031851" }}>
                                    <Grid container alignItems="center">
                                        <Grid item xs={12} lg={10}>
                                            <Tabs
                                                value={valueTab}
                                                onChange={handleChangeTab}
                                                variant="scrollable"
                                                scrollButtons="auto"
                                                aria-label="bookkeper tabs"
                                                TabIndicatorProps={{
                                                    sx: {
                                                        display: "none",
                                                    },
                                                }}
                                            >
                                                <CustomTab label={t("miscellaneous.validate")} {...a11yProps(0)} />
                                                <CustomTab label={t("miscellaneous.report")} {...a11yProps(1)} />
                                                <CustomTab label={t("miscellaneous.invoice2")} {...a11yProps(2)} />
                                                <CustomTab label={t("clientsTable.invoiced")} {...a11yProps(3)} />
                                            </Tabs>
                                        </Grid>
                                        {valueTab === 3 ? (
                                            <Grid item container xs={12} lg={2} justifyContent="flex-end">
                                                <FormControlLabel
                                                    control={
                                                        <CustomSwitch
                                                            checked={voidedSwitch}
                                                            inputProps={{ "aria-label": "controlled" }}
                                                            color={"secondary"}
                                                            onChange={onVoidedClick}
                                                        />
                                                    }
                                                    label={
                                                        <Typography variant="h4" color="textPrimary">
                                                            {t("bills.nulled")}
                                                        </Typography>
                                                    }
                                                />
                                            </Grid>
                                        ) : null}
                                    </Grid>
                                </Box>
                                <TabPanel value={valueTab} index={0}>
                                    {franqData.length > 0 && groupData.length > 0 && typeData.length > 0 && (
                                        <UpValidate
                                            loading={loading}
                                            showLoading={showLoading}
                                            franqData={franqData}
                                            groupData={groupData}
                                            setFranqData={setFranqData}
                                            typeData={typeData}
                                            MenuProps={MenuProps}
                                        />
                                    )}
                                    {(franqData.length === 0 || groupData.length === 0 || typeData.length === 0) && (
                                        <UpValidateSkeletons />
                                    )}
                                </TabPanel>
                                <TabPanel value={valueTab} index={1}>
                                    {franqData.length > 0 && groupData.length > 0 && typeData.length > 0 && (
                                        <UpReport
                                            loading={loading}
                                            showLoading={showLoading}
                                            franqData={franqData}
                                            groupData={groupData}
                                            setFranqData={setFranqData}
                                            typeData={typeData}
                                            MenuProps={MenuProps}
                                        />
                                    )}
                                </TabPanel>
                                <TabPanel value={valueTab} index={2}>
                                    {franqData.length > 0 && groupData.length > 0 && typeData.length > 0 && (
                                        <UpInvoice
                                            loading={loading}
                                            showLoading={showLoading}
                                            franqData={franqData}
                                            groupData={groupData}
                                            setFranqData={setFranqData}
                                            typeData={typeData}
                                            MenuProps={MenuProps}
                                        />
                                    )}
                                </TabPanel>
                                <TabPanel value={valueTab} index={3}>
                                    {franqData.length > 0 && groupData.length > 0 && typeData.length > 0 && (
                                        <UpInvoicing
                                            voidedSwitch={voidedSwitch}
                                            loading={loading}
                                            showLoading={showLoading}
                                            franqData={franqData}
                                            groupData={groupData}
                                            setFranqData={setFranqData}
                                            typeData={typeData}
                                            MenuProps={MenuProps}
                                        />
                                    )}
                                </TabPanel>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Backdrop open={showBackdrop} />
        </StyledContainer>
    );
};

export default Update;
