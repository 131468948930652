/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

/** MUI icons */
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

/** MUI imports */
import { Grid, Table, TableHeaderRow, PagingPanel, TableTreeColumn } from "@devexpress/dx-react-grid-material-ui";
import { PagingState, IntegratedPaging, TreeDataState, CustomTreeData } from "@devexpress/dx-react-grid";
import { styled } from "@mui/material/styles";
import GridUI from "@mui/material/Grid";
import {
    Box,
    Breadcrumbs,
    Card,
    CardContent,
    FormControlLabel,
    Skeleton,
    Stack,
    Switch,
    Typography,
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

/** Components imports */

const PREFIX = "Requirements";

const classes = {
    paper: `${PREFIX}-paper`,
    cardHeaderRoot: `${PREFIX}-cardHeaderRoot`,
    cardContentRoot: `${PREFIX}-cardContentRoot`,
    tableHeader: `${PREFIX}-tableHeader`,
    tableBody: `${PREFIX}-tableBody`,
    tableCellTree: `${PREFIX}-tableCellTree`,
    pager: `${PREFIX}-pager`,
    formRounded: `${PREFIX}-formRounded`,
    spacing: `${PREFIX}-spacing`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.paper}`]: {
        border: "2px solid #ff5968",
        borderRadius: 40,
        boxShadow: "none",
    },

    [`& .${classes.cardHeaderRoot}`]: {
        padding: 30,
    },

    [`& .${classes.cardContentRoot}`]: {
        "&:last-child": {
            paddingBottom: "18px",
        },
        padding: 30,
    },

    [`& .${classes.tableHeader}`]: {
        border: "none",
        backgroundColor: "rgba(47,49,144,0.3)",
        fontSize: "15px",
        fontWeight: 600,
        color: "#031851",
        "&:first-of-type": {
            borderRadius: "20px 0 0 0",
        },
        "&:last-child": {
            borderRadius: "0 20px 0 0",
        },
    },

    [`& .${classes.tableBody}`]: {
        borderBottom: "2px solid #031851",
        fontSize: "14px",
        fontWeight: 400,
        color: "#031851",
    },

    [`& .${classes.tableCellTree}`]: {
        borderBottom: "2px solid #031851",
        fontSize: "14px",
        fontWeight: 400,
        color: "#031851",
        cursor: "pointer",
    },

    [`& .${classes.pager}`]: {
        "& .Pagination-button": {
            paddingTop: 0,
            paddingBottom: 0,
            color: "#031851",
            fontWeight: 600,
        },
        "& .Pagination-activeButton": {
            backgroundColor: "rgba(47,49,144,0.3)",
        },
    },

    [`& .${classes.formRounded}`]: {
        backgroundColor: "#FFFFFF !important",
        borderRadius: 40,
        verticalAlign: "middle !important",
        "&:focus-within": {
            backgroundColor: "#FFFFFF",
            borderRadius: 40,
        },
        "& .MuiOutlinedInput-notchedOutline": {
            border: "2px solid #031851",
            borderRadius: 40,
        },
    },

    [`& .${classes.spacing}`]: {
        padding: "20px 0 25px 0",
    },
}));

const TableRow = ({ row, ...restProps }) => (
    <Table.Row
        {...restProps}
        style={{
            backgroundColor: row.parentId === null ? "rgba(47,49,144,0.1)" : "none",
        }}
    />
);

const tableContentComponent = (props) => <TableHeaderRow.Content {...props} />;

const PagingPanelContainer = ({ ...props }) => <PagingPanel.Container {...props} className={classes.pager} />;

const tableHeaderCell = ({ ...props }) => <TableHeaderRow.Cell {...props} className={classes.tableHeader} />;

const TableCellComponent = ({ ...props }) => <Table.Cell {...props} className={classes.tableBody} />;

const getChildRows = (row, rootRows) => {
    const childRows = rootRows.filter((r) => r.parentId === (row ? row.id : null));
    return childRows.length ? childRows : null;
};

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "rgba(19, 31, 62, 0.80)",
        fontSize: 12,
        maxWidth: 320,
        borderRadius: 6,
        fontWeight: "normal",
    },
});

const tableColumnExtensions = [
    { columnName: "settings", align: "center", width: "5%" },
    { columnName: "comments", align: "center", width: "5%" },
    { columnName: "status", align: "left", width: "10%" },
    { columnName: "jan", align: "center", width: "5%" },
    { columnName: "feb", align: "center", width: "5%" },
    { columnName: "mar", align: "center", width: "5%" },
    { columnName: "apr", align: "center", width: "5%" },
    { columnName: "may", align: "center", width: "5%" },
    { columnName: "jun", align: "center", width: "5%" },
    { columnName: "jul", align: "center", width: "5%" },
    { columnName: "aug", align: "center", width: "5%" },
    { columnName: "sep", align: "center", width: "5%" },
    { columnName: "oct", align: "center", width: "5%" },
    { columnName: "nov", align: "center", width: "5%" },
    { columnName: "dec", align: "center", width: "5%" },
];

const rowsSkeleton = [];

for (let i = 0; i < 15; i++) {
    rowsSkeleton.push({
        id: i,
        parentId: null,
        client: <Skeleton variant="text" animation="wave" />,
        settings: (
            <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />
        ),
        comments: (
            <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />
        ),
        jan: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        feb: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        mar: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        apr: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        may: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        jun: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        jul: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        aug: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        sep: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        oct: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        nov: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        dec: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
    });
}

const Requirements = (props) => {
    /** Internationalization i18n */
    const [t] = useTranslation("global");
    

    const tableMessages = {
        noData: t("miscellaneous.noData"),
    };
    const pagingMessages = {
        info: ({ from, to, count }) => `${from}${from < to ? `-${to}` : ""} ${t("miscellaneous.pagingOf")} ${count}`,
    };

    /** Component states */
    const [clientsData, setClients] = useState([]);
    const [columnDefs, setColumnsDefs] = useState([]);
    const [expandedRowIds, setExpandenRowIds] = useState([]);
    const [active, setActive] = useState(false);
    const [activeBanks, setActiveBanks] = useState(false);


    /** Global variables */
    const breadcrumbs = [
        <Typography
            key="1"
            variant="h1"
            color="primary"
            sx={{
                fontSize: 24,
                fontWeight: 700,
            }}
        >
            {t("principal.requirements")}
        </Typography>,
    ];

    /** Component functions */
    const TableCellTreeComponent = ({ ...props }) => (
        <TableTreeColumn.Cell
            {...props}
            className={props.children[1].props.visible === true ? classes.tableCellTree : classes.tableBody}
            onClick={() => {
                if (props.children[1].props.visible === true) {
                    if (!expandedRowIds.includes(props.tableRow.rowId)) {
                        setExpandenRowIds((prevArray) => [...prevArray, props.tableRow.rowId]);
                    } else {
                        const newExpandedRows = [...expandedRowIds];
                        newExpandedRows.splice(newExpandedRows.indexOf(props.tableRow.rowId), 1);
                        setExpandenRowIds(newExpandedRows);
                    }
                } else {
                    return;
                }
            }}
        />
    );

    const handleActive = (event) => {
        setActive(event.target.checked);
    };

    const handleActiveBanks = (event) => {
        setActiveBanks(event.target.checked);
    };

    useEffect(() => {
        (async () => {
            const columnsTemplate = [
                { name: "client", title: t("miscellaneous.client") },
                { name: "settings", title: " " },
                { name: "comments", title: " " },
                { name: "status", title: " " },
                { name: "jan", title: t("monthNamesShort.jan") },
                { name: "feb", title: t("monthNamesShort.feb") },
                { name: "mar", title: t("monthNamesShort.mar") },
                { name: "apr", title: t("monthNamesShort.apr") },
                { name: "may", title: t("monthNamesShort.may") },
                { name: "jun", title: t("monthNamesShort.jun") },
                { name: "jul", title: t("monthNamesShort.jul") },
                { name: "aug", title: t("monthNamesShort.aug") },
                { name: "sep", title: t("monthNamesShort.sep") },
                { name: "oct", title: t("monthNamesShort.oct") },
                { name: "nov", title: t("monthNamesShort.nov") },
                { name: "dec", title: t("monthNamesShort.dec") },
            ];
            setColumnsDefs(columnsTemplate);
            setClients(rowsSkeleton);
            setExpandenRowIds([]);

        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t]);

    return (
        <StyledContainer component="main" sx={{ flexGrow: 1, p: 4, width: "100%", pt: 10 }}>
            <Stack sx={{ pb: 2 }}>
                <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small" sx={{ color: "#031851" }} />}
                    aria-label="breadcrumb"
                >
                    {breadcrumbs}
                </Breadcrumbs>
            </Stack>
            <GridUI container justifyContent="flex-end" spacing={2}>
                <GridUI item>
                    <LightTooltip title={t("dynamicLabels.disableBanksTool")} aria-label="banks">
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={activeBanks}
                                    onChange={handleActiveBanks}
                                    inputProps={{ "aria-label": "controlled" }}
                                    color="secondary"
                                    sx={{
                                        "& .MuiSwitch-switchBase .MuiSwitch-thumb": {
                                            color: "#FFF",
                                        },
                                        "& .MuiSwitch-switchBase + .MuiSwitch-track": {
                                            backgroundColor: "#031851",
                                        },
                                        "& .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb": {
                                            color: "#FF5968",
                                        },
                                        "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                                            backgroundColor: "#FFA8B1",
                                        },
                                    }}
                                />
                            }
                            label={
                                <Typography variant="body1" color="textPrimary">
                                    {t("dynamicLabels.disableBanks")}
                                </Typography>
                            }
                        />
                    </LightTooltip>
                </GridUI>
                <GridUI item>
                    <LightTooltip title={t("dynamicLabels.disableClientsTool")} aria-label="banks">
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={active}
                                    onChange={handleActive}
                                    inputProps={{ "aria-label": "controlled" }}
                                    color="secondary"
                                    sx={{
                                        "& .MuiSwitch-switchBase .MuiSwitch-thumb": {
                                            color: "#FFF",
                                        },
                                        "& .MuiSwitch-switchBase + .MuiSwitch-track": {
                                            backgroundColor: "#031851",
                                        },
                                        "& .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb": {
                                            color: "#FF5968",
                                        },
                                        "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                                            backgroundColor: "#FFA8B1",
                                        },
                                    }}
                                />
                            }
                            label={
                                <Typography variant="body1" color="textPrimary">
                                    {t("filtersServ.inactiveClients")}
                                </Typography>
                            }
                        />
                    </LightTooltip>
                </GridUI>
                <GridUI item xs={12}>
                    <Card
                        elevation={0}
                        sx={{
                            borderRadius: 8,
                            boxShadow: "10px 10px 15px rgba(3,24,81,0.15)",
                        }}
                    >
                        <CardContent classes={{ root: classes.cardContentRoot }}>
                            <GridUI container spacing={2} className={classes.spacing} alignItems="center">
                                <GridUI item xs={12} md={1}>
                                    <Skeleton
                                        variant="rectangular"
                                        animation="wave"
                                        width="100%"
                                        height={40}
                                        sx={{ borderRadius: 8 }}
                                    />
                                </GridUI>
                                <GridUI item xs={12} md={1}>
                                    <Skeleton
                                        variant="rectangular"
                                        animation="wave"
                                        width="100%"
                                        height={40}
                                        sx={{ borderRadius: 8 }}
                                    />
                                </GridUI>
                                <GridUI item xs={12} md={2}>
                                    <Skeleton
                                        variant="rectangular"
                                        animation="wave"
                                        width="100%"
                                        height={40}
                                        sx={{ borderRadius: 8 }}
                                    />
                                </GridUI>
                                <GridUI item xs={12} md={2}>
                                    <Skeleton
                                        variant="rectangular"
                                        animation="wave"
                                        width="100%"
                                        height={40}
                                        sx={{ borderRadius: 8 }}
                                    />
                                </GridUI>
                                <GridUI item xs={12} md={2}>
                                    <Skeleton
                                        variant="rectangular"
                                        animation="wave"
                                        width="100%"
                                        height={40}
                                        sx={{ borderRadius: 8 }}
                                    />
                                </GridUI>
                                <GridUI item xs={12} md={1}>
                                    <Skeleton
                                        variant="rectangular"
                                        animation="wave"
                                        width="100%"
                                        height={40}
                                        sx={{ borderRadius: 8 }}
                                    />
                                </GridUI>
                                <GridUI item xs={12} md={1}>
                                    <Skeleton
                                        variant="rectangular"
                                        animation="wave"
                                        width="100%"
                                        height={40}
                                        sx={{ borderRadius: 8 }}
                                    />
                                </GridUI>
                                <GridUI item xs={12} md={2}>
                                    <Skeleton
                                        variant="rectangular"
                                        animation="wave"
                                        width="100%"
                                        height={40}
                                        sx={{ borderRadius: 8 }}
                                    />
                                </GridUI>
                                <GridUI item xs={12} md={1}>
                                    <Skeleton
                                        variant="rectangular"
                                        animation="wave"
                                        width="100%"
                                        height={40}
                                        sx={{ borderRadius: 8 }}
                                    />
                                </GridUI>
                            </GridUI>
                            <Grid rows={clientsData} columns={columnDefs}>
                                <TreeDataState
                                    expandedRowIds={expandedRowIds}
                                    onExpandedRowIdsChange={setExpandenRowIds}
                                />
                                <CustomTreeData getChildRows={getChildRows} />
                                <PagingState defaultCurrentPage={0} pageSize={15} />
                                <IntegratedPaging />
                                <Table
                                    columnExtensions={tableColumnExtensions}
                                    cellComponent={TableCellComponent}
                                    rowComponent={TableRow}
                                    messages={tableMessages}
                                />
                                <TableHeaderRow
                                    cellComponent={tableHeaderCell}
                                    contentComponent={tableContentComponent}
                                />
                                <TableTreeColumn cellComponent={TableCellTreeComponent} for="client" />
                                <PagingPanel containerComponent={PagingPanelContainer} messages={pagingMessages} />
                            </Grid>
                        </CardContent>
                    </Card>
                </GridUI>
            </GridUI>
        </StyledContainer>
    );
};

export default Requirements;
