/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import moment from "moment";

/** MUI icons */
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import CommentIcon from "@mui/icons-material/Comment";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

/** MUI imports */
import {
    Grid,
    Table,
    TableHeaderRow,
    PagingPanel,
    Toolbar,
    SearchPanel,
    TableColumnVisibility,
} from "@devexpress/dx-react-grid-material-ui";
import { SearchState, IntegratedFiltering, PagingState, IntegratedPaging } from "@devexpress/dx-react-grid";
import { styled } from "@mui/material/styles";
import GridUI from "@mui/material/Grid";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import {
    Box,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Radio,
    Select,
    Typography,
    Skeleton,
    Badge,
    Checkbox,
    ListItemText,
} from "@mui/material";

/** Components imports */
import Backdrop from "../../Backdrop";
import DialogAlerts from "../../DialogAlerts";
import SnackBar from "../../SnackBar";
import CommentDialog from "../../CommentDialog";

const PREFIX = "Validations";

const classes = {
    paper: `${PREFIX}-paper`,
    tableStriped: `${PREFIX}-tableStriped`,
    tableHeader: `${PREFIX}-tableHeader`,
    tableBody: `${PREFIX}-tableBody`,
    pager: `${PREFIX}-pager`,
    cardContentRoot: `${PREFIX}-cardContentRoot`,
    cardContentRoot2: `${PREFIX}-cardContentRoot2`,
    cardHeaderRoot: `${PREFIX}-cardHeaderRoot`,
    formRounded: `${PREFIX}-formRounded`,
    button: `${PREFIX}-button`,
    spacing: `${PREFIX}-spacing`,
    radio: `${PREFIX}-radio`,
    toolbarSearch: `${PREFIX}-toolbarSearch`,
    searchInput: `${PREFIX}-searchInput`,
};

const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
        backgroundColor: "#2f3190",
        color: "white",
    },
}));

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "rgba(19, 31, 62, 0.80)",
        fontSize: 12,
        maxWidth: 320,
        borderRadius: 6,
        fontWeight: "normal",
    },
});

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.paper}`]: {
        border: "2px solid #ff5968",
        borderRadius: 40,
        boxShadow: "none",
    },

    [`& .${classes.radio}`]: {
        [theme.breakpoints.down("sm")]: {
            padding: 9,
        },
        [theme.breakpoints.between("md", "lg")]: {
            padding: 0,
        },
        [theme.breakpoints.between("lg", "xl")]: {
            padding: 6,
        },
    },

    [`& .${classes.cardContentRoot}`]: {
        "&:last-child": {
            paddingBottom: "5px",
        },
        padding: "10px",
    },

    [`& .${classes.cardContentRoot2}`]: {
        "&:last-child": {
            paddingBottom: "18px",
        },
        padding: 30,
    },

    [`& .${classes.cardHeaderRoot}`]: {
        padding: 30,
    },

    [`& .${classes.formRounded}`]: {
        backgroundColor: "#FFFFFF !important",
        borderRadius: 40,
        verticalAlign: "middle !important",
        "&:focus-within": {
            backgroundColor: "#FFFFFF",
            borderRadius: 40,
        },
        "& .MuiOutlinedInput-notchedOutline": {
            border: "2px solid #031851",
            borderRadius: 40,
        },
    },

    [`& .${classes.button}`]: {
        borderRadius: 40,
        width: "100%",
        "&:hover": {
            backgroundColor: theme.palette.text.secondary,
            color: "#fff",
        },
    },

    [`& .${classes.spacing}`]: {
        padding: "30px 0 20px 0",
    },

    [`& .${classes.tableStriped}`]: {
        "& tbody tr:nth-of-type(odd)": {
            backgroundColor: "rgba(47,49,144,0.1)",
        },
    },
    [`& .${classes.tableHeader}`]: {
        border: "none",
        backgroundColor: "rgba(47,49,144,0.3)",
        fontSize: "15px",
        fontWeight: 600,
        color: "#031851",
        "&:first-of-type": {
            borderRadius: "20px 0 0 0",
        },
        "&:last-child": {
            borderRadius: "0 20px 0 0",
        },
    },

    [`& .${classes.tableBody}`]: {
        border: "none",
        fontSize: "14px",
        fontWeight: 400,
        color: "#031851",
    },

    [`& .${classes.pager}`]: {
        "& .Pagination-button": {
            paddingTop: 0,
            paddingBottom: 0,
            color: "#031851",
            fontWeight: 600,
        },
        "& .Pagination-activeButton": {
            backgroundColor: "rgba(47,49,144,0.3)",
        },
    },

    [`& .${classes.toolbarSearch}`]: {
        border: "none !important",
        padding: "0 0 5px !important",
    },

    [`& .${classes.searchInput}`]: {
        color: "#031851",
        "&:before": {
            borderBottomColor: "#031851",
        },
        "&:hover:before": {
            borderBottomColor: "#031851 !important",
        },
    },
}));

const tableHeaderCell = ({ ...props }) => <TableHeaderRow.Cell {...props} className={classes.tableHeader} />;

const TableComponent = ({ ...props }) => <Table.Table {...props} className={classes.tableStriped} />;

const TableCellComponent = ({ ...props }) => <Table.Cell {...props} className={classes.tableBody} />;

const tableContentComponent = (props) => <TableHeaderRow.Content {...props} />;

const PagingPanelContainer = ({ ...props }) => <PagingPanel.Container {...props} className={classes.pager} />;

const inputComponent = ({ ...props }) => <SearchPanel.Input {...props} className={classes.searchInput} />;

const toolRootComponent = ({ ...props }) => <Toolbar.Root {...props} className={classes.toolbarSearch} />;

let selectedValueCont = 0;
let resultTable = [];

const tableColumnExtensions = [
    { columnName: "client", align: "left" },
    { columnName: "validated", align: "center" },
    { columnName: "buttonClear", align: "center", width: "5%" },
    { columnName: "comments", align: "center", width: "5%" },
    { columnName: "jan", align: "center", width: "5%" },
    { columnName: "feb", align: "center", width: "5%" },
    { columnName: "mar", align: "center", width: "5%" },
    { columnName: "apr", align: "center", width: "5%" },
    { columnName: "may", align: "center", width: "5%" },
    { columnName: "jun", align: "center", width: "5%" },
    { columnName: "jul", align: "center", width: "5%" },
    { columnName: "aug", align: "center", width: "5%" },
    { columnName: "sep", align: "center", width: "5%" },
    { columnName: "oct", align: "center", width: "5%" },
    { columnName: "nov", align: "center", width: "5%" },
    { columnName: "dec", align: "center", width: "5%" },
];

const rowsSkeleton = [];

for (let i = 0; i < 10; i++) {
    rowsSkeleton.push({
        id: i,
        client: <Skeleton variant="text" animation="wave" />,
        buttonClear: (
            <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />
        ),
        comments: (
            <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />
        ),
        validated: <Skeleton variant="text" animation="wave" />,
        jan: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        feb: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        mar: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        apr: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        may: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        jun: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        jul: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        aug: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        sep: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        oct: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        nov: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        dec: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
    });
}

const Validations = (props) => {
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    const tableMessages = {
        noData: t("miscellaneous.noData"),
    };
    const pagingMessages = {
        info: ({ from, to, count }) => `${from}${from < to ? `-${to}` : ""} ${t("miscellaneous.pagingOf")} ${count}`,
    };

    const searchMessages = {
        searchPlaceholder: t("miscellaneous.search"),
    };

    const statusArray = [
        {
            id: "all",
            name: t("miscellaneous.all"),
        },
        {
            id: "1",
            name: t("clientsTable.awaitingRequirements"),
        },
        {
            id: "2",
            name: t("recurrent.toValid"),
        },
        {
            id: "3",
            name: t("recurrent.validated"),
        },
    ];

    /** React redux */
    const todos = useSelector((state) => state.todos);

    const franqDataProps = props.franqData;
    const groupDataProps = props.groupData;

    /** Component states */
    const [clientsData, setClients] = useState([]);
    const [columnDefs, setColumnsDefs] = useState([]);
    const [showBackdrop, setBackDrop] = useState(false);
    const [selectedValue, setSelectedValue] = useState([]);
    const [yearsToNoti, setyearsToNoti] = useState([]);
    const [selectedYear, setselectedYear] = useState(new Date().getFullYear());
    const [clientT, setTClient] = useState("");
    const [franqF, setfranqF] = useState(["all"].concat(franqDataProps.map((item) => item.id)));
    const [groupF, setgroupF] = useState(["all"].concat(groupDataProps.map((item) => item.id)));
    const [clientMarket, setclientMarket] = useState(props.markets);
    const [clientStatus, setClientStatus] = useState("");
    const [idStatus, setIdStatus] = useState(statusArray.map((item) => item.id));
    const [groupData, setGroupData] = useState(groupDataProps);
    const [franqData, setFranqData] = useState(franqDataProps);
    const [open, setOpen] = useState(false);
    const [dialogText, setDialogText] = useState("");
    const [dialogType, setDialogType] = useState("info");
    const [show, setShow] = useState(false);
    const [texto, setTexto] = useState("");
    const [alertType, seAutoAlertType] = useState("");
    const [searchValue, setSearchState] = useState("");
    const [dialogComment, setDialogComment] = useState(false);
    const [clientID, setclientID] = useState("");
    const [comments, setComments] = useState([]);
    const [commentsP, setCommentsPend] = useState([]);
    const [isValidated, setisValidated] = useState(false);
    const [defaultHiddenColumnNames] = useState(["hiddenCompany"]);
    const [anyFilter, setAnyFilter] = useState(true);
    const controllerRef = useRef(null);

    /** Component functions */
    const cleanFilters = () => {
        selectedValueCont = 0;
        setAnyFilter(true);
        setTClient("");
        setfranqF(["all"].concat(franqDataProps.map((item) => item.id)));
        setgroupF(["all"].concat(groupDataProps.map((item) => item.id)));
        setGroupData(groupDataProps);
        setIdStatus(statusArray.map((item) => item.id));
        setClientStatus("");
        setclientMarket(props.markets);
        setSelectedValue([]);
        setselectedYear(new Date().getFullYear());
    };

    const handleSearch = (value) => {
        let itemS = value.replace(/ +(?= )/g, "");
        setSearchState(itemS);
    };

    const closeDialog = () => {
        setDialogComment(false);
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setShow(false);
    };

    const handleOpenDialog = () => {
        if (selectedValueCont > 0) {
            setDialogType("info");
            if (selectedValueCont === 1) {
                setDialogText(t("recurrent.newmessageModal1part3"));
            } else {
                setDialogText(
                    t("recurrent.newmessageModal1") + selectedValueCont + t("recurrent.newmessageModal1part2")
                );
            }
            setOpen(true);
        } else {
            setDialogType("info");
            if (resultTable.pendingCont === 1) {
                setDialogText(t("recurrent.newmessageModal2part3"));
            } else {
                setDialogText(
                    t("recurrent.newmessageModal1") + resultTable.pendingCont + t("recurrent.newmessageModal2part2")
                );
            }
            setOpen(true);
        }
    };

    const handleCloseDialog = () => {
        setDialogText("");
        setDialogType("");
        setOpen(false);
    };

    const handleChangeTClient = async (event) => {
        setBackDrop(true);
        selectedValueCont = 0;
        setSelectedValue([]);
        setTClient(event.target.value);
        setAnyFilter(false);

        let finalFranq = [];

        await fetch(`/getClientFranchiseFilter?client_type=${event.target.value}`, {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then((res) => res.json())
            .then((data) => {
                setFranqData(data);
                if (event.target.value === "") {
                    finalFranq = [];
                } else {
                    finalFranq = data.map((item) => item.id);
                }
                setfranqF(["all"].concat(data.map((item) => item.id)));
            });

        await fetch(`/getFranchiseGroups?id_franchise=${finalFranq}`, {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then((res) => res.json())
            .then((data) => {
                setGroupData(data);
                setgroupF(["all"].concat(data.map((item) => item.id)));
            });
        setBackDrop(false);
    };

    const getColorStatus = (valItem) => {
        const status = valItem === 0 ? "#F8F9F8" : valItem === 1 ? "#FF8B9D" : valItem === 2 || valItem === 4 ? "#FFB23E" : "#9AD2DD";

        return status;
    };

    const handleChangeFranqF = async (event) => {
        setBackDrop(true);
        selectedValueCont = 0;
        setSelectedValue([]);

        const {
            target: { value },
        } = event;

        let final = [];

        if (value.includes("all") && !franqF.includes("all")) {
            final = ["all"].concat(franqData.map((item) => item.id));
        } else if (!value.includes("all") && franqF.includes("all")) {
            final = [];
        } else {
            if (!value.includes("all") && value.length === franqData.length) {
                final = ["all"].concat(franqData.map((item) => item.id));
            } else {
                final =
                    typeof value === "string"
                        ? value.split(",").filter((item) => item !== "all")
                        : value.filter((item) => item !== "all");
            }
        }
        setfranqF(final);
        setAnyFilter(false);

        let finalFranq = [];
        if (final.includes("all")) {
            finalFranq = [];
        } else if (final.length === 0) {
            finalFranq = ["none"];
        } else {
            finalFranq = final.filter((item) => item !== "all");
        }

        await fetch(`/getFranchiseGroups?id_franchise=${finalFranq}`, {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then((res) => res.json())
            .then((data) => {
                setgroupF(["all"].concat(data.map((item) => item.id)));
                setGroupData(data);
            });

        setBackDrop(false);
    };

    const handleChangeGroupF = (event) => {
        selectedValueCont = 0;
        setSelectedValue([]);
        const {
            target: { value },
        } = event;

        let final = [];

        if (value.includes("all") && !groupF.includes("all")) {
            final = ["all"].concat(groupDataProps.map((item) => item.id));
        } else if (!value.includes("all") && groupF.includes("all")) {
            final = [];
        } else {
            if (!value.includes("all") && value.length === groupData.length) {
                final = ["all"].concat(groupData.map((item) => item.id));
            } else {
                final = event.target.value.filter((item) => item !== "all");
            }
        }
        setgroupF(final);
        setAnyFilter(false);
    };

    const handleChangeClasif = (event) => {
        selectedValueCont = 0;
        setSelectedValue([]);
        setClientStatus(event.target.value);
        setAnyFilter(false);
    };

    const handleChangeIdStatus = (event) => {
        selectedValueCont = 0;
        setSelectedValue([]);

        const {
            target: { value },
        } = event;

        let final = [];

        if (value.includes("all") && !idStatus.includes("all")) {
            final = statusArray.map((item) => item.id);
        } else if (!value.includes("all") && idStatus.includes("all")) {
            final = [];
        } else {
            if (!value.includes("all") && value.length === statusArray.filter((item) => item.id !== "all").length) {
                final = statusArray.map((item) => item.id);
            } else {
                final =
                    typeof value === "string"
                        ? value.split(",").filter((item) => item !== "all")
                        : value.filter((item) => item !== "all");
            }
        }

        setIdStatus(final);
        setAnyFilter(false);
    };

    const handleChangeclientMarket = (event) => {
        selectedValueCont = 0;
        setSelectedValue([]);
        const {
            target: { value },
        } = event;

        let final = [];

        if (value.includes(t("miscellaneous.all")) && !clientMarket.includes(t("miscellaneous.all"))) {
            final = props.markets;
        } else if (!value.includes(t("miscellaneous.all")) && clientMarket.includes(t("miscellaneous.all"))) {
            final = [];
        } else {
            if (
                !value.includes(t("miscellaneous.all")) &&
                value.length === props.markets.filter((item) => item !== t("miscellaneous.all")).length
            ) {
                final = props.markets;
            } else {
                final =
                    typeof value === "string"
                        ? value.split(",").filter((item) => item !== t("miscellaneous.all"))
                        : value.filter((item) => item !== t("miscellaneous.all"));
            }
        }
        setclientMarket(final);
        setAnyFilter(false);
    };

    const handleChange = (pos, id, isDisconnected, event) => {
        let items = [...selectedValue];

        let item = {
            ...items[pos],
            id_organisation: id,
            month: event.target.value,
            is_disconnected: isDisconnected,
        };
        if (!items[pos]) {
            selectedValueCont++;
        }
        items[pos] = item;

        setSelectedValue(items);
    };

    const clearRow = (index) => {
        let items = [...selectedValue];

        items.splice(index, 1, undefined);

        selectedValueCont--;

        setSelectedValue(items);
    };

    const openComments = async (clientId) => {
        setBackDrop(true);

        setclientID(clientId);

        await getComments(clientId);

        setDialogComment(true);

        setBackDrop(false);
    };

    const getComments = async (clientId) => {
        await fetch(`/getComments?company_id=${clientId}&period=${selectedYear}&comment_type=1`, {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then((res) => res.json())
            .then((data) => {
                setComments(data.history);
                setCommentsPend(data.pendings);
            });
    };

    const handleClick = (event) => {
        selectedValueCont = 0;
        setSelectedValue([]);
        setselectedYear(event.target.value);
        setAnyFilter(false);
    };

    const loadInitData = async (year) => {
        selectedValueCont = 0;

        if (controllerRef.current) {
            controllerRef.current.abort();
        }
        const controller = new AbortController();
        controllerRef.current = controller;

        let finalClientMarket = [];
        if (clientMarket.includes(t("miscellaneous.all"))) {
            finalClientMarket = [];
        } else if (clientMarket.length === 0) {
            finalClientMarket = ["none"];
        } else {
            finalClientMarket = clientMarket.filter((item) => item !== t("miscellaneous.all"));
        }

        let finalFranq = [];
        if (franqF.includes("all")) {
            finalFranq = [];
        } else if (franqF.length === 0) {
            finalFranq = ["none"];
        } else {
            finalFranq = franqF.filter((item) => item !== "all");
        }

        let finalGroup = [];
        if (groupF.includes("all")) {
            finalGroup = [];
        } else if (groupF.length === 0) {
            finalGroup = ["none"];
        } else {
            finalGroup = groupF.filter((item) => item !== "all");
        }

        resultTable = await fetch(
            `/getValidations?client_type=${clientT}&practice_id=${finalGroup}&franchise_id=${finalFranq}&client_status=${clientStatus}&id_status=${idStatus}&year=${year}&service_type=${props.serviceType}&client_market=${finalClientMarket}`,
            {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
                signal: controllerRef.current.signal,
            }
        )
            .then((res) => res.json())
            .then((data) => {
                controllerRef.current = null;
                return data;
            });

        let clientsdata = resultTable.resultArray.map((valItem, index) => {
            let cont = 0;
            valItem.comments.forEach((data) => {
                if (data.status === 0) {
                    cont++;
                }
            });

            return {
                ID: valItem.id_organisation,
                hiddenCompany: valItem.company_name,
                client: valItem.invalid ? (
                    <Typography variant="h4" color="secondary">
                        {valItem.company_name}
                    </Typography>
                ) : (
                    valItem.company_name
                ),
                buttonClear: (
                    <LightTooltip title={t("recurrent.clear")}>
                        <span>
                            <IconButton
                                aria-label="clear"
                                color="primary"
                                disabled={selectedValue[index] === undefined}
                                onClick={() => clearRow(index)}
                            >
                                <ClearAllIcon />
                            </IconButton>
                        </span>
                    </LightTooltip>
                ),
                comments: (
                    <LightTooltip title={t("miscellaneous.comments")}>
                        <IconButton
                            aria-label="clear"
                            sx={valItem.comments.length === 0 ? { color: "rgba(0, 0, 0, 0.54)" } : { color: "#2f3190" }}
                            onClick={() => openComments(valItem.id_organisation)}
                        >
                            <StyledBadge badgeContent={cont}>
                                <CommentIcon />
                            </StyledBadge>
                        </IconButton>
                    </LightTooltip>
                ),
                validated: valItem.disconnected_company ? (
                    <Typography color="secondary">{t("clientsTable.disconnected")}</Typography>
                ) : valItem.validation_date !== "" ? (
                    moment(valItem.validation_date, "DD/MM/YYYY").format(todos.dateFormat)
                ) : (
                    ""
                ),
                jan: (
                    <LightTooltip
                        disableHoverListener={valItem.notify_jan !== 1}
                        title={t("clientsTable.missingTool")}
                    >
                        <div
                            style={{
                                borderRadius: 40,
                                width: 45,
                                marginLeft: "auto",
                                marginRight: "auto",
                                background: getColorStatus(valItem.notify_jan),
                            }}
                        >
                            <Radio
                                checked={selectedValue[index] !== undefined ? selectedValue[index].month === "01" : false}
                                onChange={(event) =>
                                    handleChange(index, valItem.id_organisation, valItem.disconnected_company, event)
                                }
                                value="01"
                                name="radio-button-jan"
                                color="primary"
                                disabled={valItem.notify_jan === 0 || valItem.notify_jan === 1}
                                className={classes.radio}
                            />
                        </div>
                    </LightTooltip>
                ),
                feb: (
                    <LightTooltip
                        disableHoverListener={valItem.notify_feb !== 1}
                        title={t("clientsTable.missingTool")}
                    >
                        <div
                            style={{
                                borderRadius: 40,
                                width: 45,
                                marginLeft: "auto",
                                marginRight: "auto",
                                background: getColorStatus(valItem.notify_feb),
                            }}
                        >
                            <Radio
                                checked={selectedValue[index] !== undefined ? selectedValue[index].month === "02" : false}
                                onChange={(event) =>
                                    handleChange(index, valItem.id_organisation, valItem.disconnected_company, event)
                                }
                                value="02"
                                name="radio-button-feb"
                                color="primary"
                                disabled={valItem.notify_feb === 0 || valItem.notify_feb === 1}
                                className={classes.radio}
                            />
                        </div>
                    </LightTooltip>
                ),
                mar: (
                    <LightTooltip
                        disableHoverListener={valItem.notify_mar !== 1}
                        title={t("clientsTable.missingTool")}
                        >
                        <div
                            style={{
                                borderRadius: 40,
                                width: 45,
                                marginLeft: "auto",
                                marginRight: "auto",
                                background: getColorStatus(valItem.notify_mar),
                            }}
                        >
                            <Radio
                                checked={selectedValue[index] !== undefined ? selectedValue[index].month === "03" : false}
                                onChange={(event) =>
                                    handleChange(index, valItem.id_organisation, valItem.disconnected_company, event)
                                }
                                value="03"
                                name="radio-button-mar"
                                color="primary"
                                disabled={valItem.notify_mar === 0 || valItem.notify_mar === 1}
                                className={classes.radio}
                            />
                        </div>
                    </LightTooltip>
                ),
                apr: (
                    <LightTooltip
                        disableHoverListener={valItem.notify_apr !== 1}
                        title={t("clientsTable.missingTool")}
                        >
                        <div
                            style={{
                                borderRadius: 40,
                                width: 45,
                                marginLeft: "auto",
                                marginRight: "auto",
                                background: getColorStatus(valItem.notify_apr),
                            }}
                        >
                            <Radio
                                checked={selectedValue[index] !== undefined ? selectedValue[index].month === "04" : false}
                                onChange={(event) =>
                                    handleChange(index, valItem.id_organisation, valItem.disconnected_company, event)
                                }
                                value="04"
                                name="radio-button-apr"
                                color="primary"
                                disabled={valItem.notify_apr === 0 || valItem.notify_apr === 1}
                                className={classes.radio}
                            />
                        </div>
                    </LightTooltip>
                ),
                may: (
                    <LightTooltip
                        disableHoverListener={valItem.notify_may !== 1}
                        title={t("clientsTable.missingTool")}
                        >
                        <div
                            style={{
                                borderRadius: 40,
                                width: 45,
                                marginLeft: "auto",
                                marginRight: "auto",
                                background: getColorStatus(valItem.notify_may),
                            }}
                        >
                            <Radio
                                checked={selectedValue[index] !== undefined ? selectedValue[index].month === "05" : false}
                                onChange={(event) =>
                                    handleChange(index, valItem.id_organisation, valItem.disconnected_company, event)
                                }
                                value="05"
                                name="radio-button-may"
                                color="primary"
                                disabled={valItem.notify_may === 0 || valItem.notify_may === 1}
                                className={classes.radio}
                            />
                        </div>
                    </LightTooltip>
                ),
                jun: (
                    <LightTooltip
                        disableHoverListener={valItem.notify_jun !== 1}
                        title={t("clientsTable.missingTool")}
                    >
                        <div
                            style={{
                                borderRadius: 40,
                                width: 45,
                                marginLeft: "auto",
                                marginRight: "auto",
                                background: getColorStatus(valItem.notify_jun),
                            }}
                        >
                            <Radio
                                checked={selectedValue[index] !== undefined ? selectedValue[index].month === "06" : false}
                                onChange={(event) =>
                                    handleChange(index, valItem.id_organisation, valItem.disconnected_company, event)
                                }
                                value="06"
                                name="radio-button-jun"
                                color="primary"
                                disabled={valItem.notify_jun === 0 || valItem.notify_jun === 1}
                                className={classes.radio}
                            />
                        </div>
                    </LightTooltip>
                ),
                jul: (
                    <LightTooltip
                        disableHoverListener={valItem.notify_jul !== 1}
                        title={t("clientsTable.missingTool")}
                        >
                        <div
                            style={{
                                borderRadius: 40,
                                width: 45,
                                marginLeft: "auto",
                                marginRight: "auto",
                                background: getColorStatus(valItem.notify_jul),
                            }}
                        >
                            <Radio
                                checked={selectedValue[index] !== undefined ? selectedValue[index].month === "07" : false}
                                onChange={(event) =>
                                    handleChange(index, valItem.id_organisation, valItem.disconnected_company, event)
                                }
                                value="07"
                                name="radio-button-jul"
                                color="primary"
                                disabled={valItem.notify_jul === 0 || valItem.notify_jul === 1}
                                className={classes.radio}
                            />
                        </div>
                    </LightTooltip>
                ),
                aug: (
                    <LightTooltip
                        disableHoverListener={valItem.notify_aug !== 1}
                        title={t("clientsTable.missingTool")}
                        >
                        <div
                            style={{
                                borderRadius: 40,
                                width: 45,
                                marginLeft: "auto",
                                marginRight: "auto",
                                background: getColorStatus(valItem.notify_aug),
                            }}
                        >
                            <Radio
                                checked={selectedValue[index] !== undefined ? selectedValue[index].month === "08" : false}
                                onChange={(event) =>
                                    handleChange(index, valItem.id_organisation, valItem.disconnected_company, event)
                                }
                                value="08"
                                name="radio-button-aug"
                                color="primary"
                                disabled={valItem.notify_aug === 0 || valItem.notify_aug === 1}
                                className={classes.radio}
                            />
                        </div>
                    </LightTooltip>
                ),
                sep: (
                    <LightTooltip
                        disableHoverListener={valItem.notify_sep !== 1}
                        title={t("clientsTable.missingTool")}
                        >
                        <div
                            style={{
                                borderRadius: 40,
                                width: 45,
                                marginLeft: "auto",
                                marginRight: "auto",
                                background: getColorStatus(valItem.notify_sep),
                            }}
                        >
                            <Radio
                                checked={selectedValue[index] !== undefined ? selectedValue[index].month === "09" : false}
                                onChange={(event) =>
                                    handleChange(index, valItem.id_organisation, valItem.disconnected_company, event)
                                }
                                value="09"
                                name="radio-button-sep"
                                color="primary"
                                disabled={valItem.notify_sep === 0 || valItem.notify_sep === 1}
                                className={classes.radio}
                            />
                        </div>
                    </LightTooltip>
                ),
                oct: (
                    <LightTooltip
                        disableHoverListener={valItem.notify_oct !== 1}
                        title={t("clientsTable.missingTool")}
                        >
                        <div
                            style={{
                                borderRadius: 40,
                                width: 45,
                                marginLeft: "auto",
                                marginRight: "auto",
                                background: getColorStatus(valItem.notify_oct),
                            }}
                        >
                            <Radio
                                checked={selectedValue[index] !== undefined ? selectedValue[index].month === "10" : false}
                                onChange={(event) =>
                                    handleChange(index, valItem.id_organisation, valItem.disconnected_company, event)
                                }
                                value="10"
                                name="radio-button-oct"
                                color="primary"
                                disabled={valItem.notify_oct === 0 || valItem.notify_oct === 1}
                                className={classes.radio}
                            />
                        </div>
                    </LightTooltip>
                ),
                nov: (
                    <LightTooltip
                        disableHoverListener={valItem.notify_nov !== 1}
                        title={t("clientsTable.missingTool")}
                        >
                        <div
                            style={{
                                borderRadius: 40,
                                width: 45,
                                marginLeft: "auto",
                                marginRight: "auto",
                                background: getColorStatus(valItem.notify_nov),
                            }}
                        >
                            <Radio
                                checked={selectedValue[index] !== undefined ? selectedValue[index].month === "11" : false}
                                onChange={(event) =>
                                    handleChange(index, valItem.id_organisation, valItem.disconnected_company, event)
                                }
                                value="11"
                                name="radio-button-nov"
                                color="primary"
                                disabled={valItem.notify_nov === 0 || valItem.notify_nov === 1}
                                className={classes.radio}
                            />
                        </div>
                    </LightTooltip>
                ),
                dec: (
                    <LightTooltip
                        disableHoverListener={valItem.notify_dec !== 1}
                        title={t("clientsTable.missingTool")}
                        >
                        <div
                            style={{
                                borderRadius: 40,
                                width: 45,
                                marginLeft: "auto",
                                marginRight: "auto",
                                background: getColorStatus(valItem.notify_dec),
                            }}
                        >
                            <Radio
                                checked={selectedValue[index] !== undefined ? selectedValue[index].month === "12" : false}
                                onChange={(event) =>
                                    handleChange(index, valItem.id_organisation, valItem.disconnected_company, event)
                                }
                                value="12"
                                name="radio-button-dec"
                                color="primary"
                                disabled={valItem.notify_dec === 0 || valItem.notify_dec === 1}
                                className={classes.radio}
                            />
                        </div>
                    </LightTooltip>
                ),
            };
        });

        setClients(clientsdata);
    };

    const validateClients = async () => {
        setBackDrop(true);

        let arrayValidation = [];
        let cont = 0;
        let isDisconnected = false;

        selectedValue.forEach((item) => {
            if (item !== undefined) {
                var temp = Object.assign({}, item);
                temp.year = selectedYear;
                arrayValidation[cont] = temp;
                cont++;

                if (item.is_disconnected) {
                    isDisconnected = true;
                }
            }
        });

        const params = {
            client_type: clientT,
            franchise_id: franqF,
            practice_id: groupF,
            client_status: clientStatus,
            client_market: clientMarket,
            year: selectedYear,
            id_status: idStatus,
            array_clients: arrayValidation,
            service_type: props.serviceType,
        };

        if (!isDisconnected) {
            await fetch("/validateClients", {
                method: "POST",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
                body: JSON.stringify(params),
            })
                .then((response) => {
                    // check for error response
                    if (!response.ok) {
                        // get error message from body or default to response status
                        const error = response.status;
                        throw new Error(error);
                    }

                    if (response.status === 200) {
                        setTexto(t("reports.validatedClients"));
                        seAutoAlertType("success");
                        setShow(true);
                    } else if (response.status === 204) {
                        setTexto(t("recurrent.errorValidate"));
                        seAutoAlertType("warning");
                        setShow(true);
                    }
                    selectedValueCont = 0;
                    setSelectedValue([]);
                    handleCloseDialog();
                    setisValidated(!isValidated);
                })
                .catch((err) => {
                    setTexto(err.error);
                    seAutoAlertType("error");
                    setShow(true);
                });
        } else {
            setTexto(t("miscellaneous.contactSupp"));
            seAutoAlertType("warning");
            setShow(true);
            selectedValueCont = 0;
            setSelectedValue([]);
            handleCloseDialog();
            setisValidated(!isValidated);
        }

        setBackDrop(false);
    };

    const setTableData = () => {
        if (selectedValue.length !== 0) {
            let clientsdata = [];

            clientsdata = resultTable.resultArray.map((valItem, index) => {
                let cont = 0;
                valItem.comments.forEach((data) => {
                    if (data.status === 0) {
                        cont++;
                    }
                });
                return {
                    ID: valItem.id_organisation,
                    hiddenCompany: valItem.company_name,
                    client: valItem.invalid ? (
                        <Typography variant="h4" color="secondary">
                            {valItem.company_name}
                        </Typography>
                    ) : (
                        valItem.company_name
                    ),
                    buttonClear: (
                        <LightTooltip title={t("recurrent.clear")}>
                            <span>
                                <IconButton
                                    aria-label="clear"
                                    color="primary"
                                    disabled={selectedValue[index] === undefined}
                                    onClick={() => clearRow(index)}
                                >
                                    <ClearAllIcon />
                                </IconButton>
                            </span>
                        </LightTooltip>
                    ),
                    comments: (
                        <LightTooltip title={t("miscellaneous.comments")}>
                            <IconButton
                                aria-label="clear"
                                sx={
                                    valItem.comments.length === 0
                                        ? { color: "rgba(0, 0, 0, 0.54)" }
                                        : { color: "#2f3190" }
                                }
                                onClick={() => openComments(valItem.id_organisation)}
                            >
                                <StyledBadge badgeContent={cont}>
                                    <CommentIcon />
                                </StyledBadge>
                            </IconButton>
                        </LightTooltip>
                    ),
                    validated: valItem.disconnected_company ? (
                        <Typography color="secondary">{t("clientsTable.disconnected")}</Typography>
                    ) : valItem.validation_date !== "" ? (
                        moment(valItem.validation_date, "DD/MM/YYYY").format(todos.dateFormat)
                    ) : (
                        ""
                    ),
                    jan: (
                        <LightTooltip
                        disableHoverListener={valItem.notify_jan !== 1}
                        title={t("clientsTable.missingTool")}
                        >
                            <div
                                style={{
                                    borderRadius: 40,
                                    width: 45,
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    background: getColorStatus(valItem.notify_jan),
                                }}
                            >
                                <Radio
                                    checked={
                                        selectedValue[index] !== undefined ? selectedValue[index].month === "01" : false
                                    }
                                    onChange={(event) =>
                                        handleChange(index, valItem.id_organisation, valItem.disconnected_company, event)
                                    }
                                    value="01"
                                    name="radio-button-jan"
                                    color="primary"
                                    disabled={valItem.notify_jan === 0 || valItem.notify_jan === 1}
                                    className={classes.radio}
                                />
                            </div>
                        </LightTooltip>
                    ),
                    feb: (
                        <LightTooltip
                        disableHoverListener={valItem.notify_feb !== 1}
                        title={t("clientsTable.missingTool")}
                        >
                            <div
                                style={{
                                    borderRadius: 40,
                                    width: 45,
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    background: getColorStatus(valItem.notify_feb),
                                }}
                            >
                                <Radio
                                    checked={
                                        selectedValue[index] !== undefined ? selectedValue[index].month === "02" : false
                                    }
                                    onChange={(event) =>
                                        handleChange(index, valItem.id_organisation, valItem.disconnected_company, event)
                                    }
                                    value="02"
                                    name="radio-button-feb"
                                    color="primary"
                                    disabled={valItem.notify_feb === 0 || valItem.notify_feb === 1}
                                    className={classes.radio}
                                />
                            </div>
                        </LightTooltip>
                    ),
                    mar: (
                        <LightTooltip
                        disableHoverListener={valItem.notify_mar !== 1}
                        title={t("clientsTable.missingTool")}
                        >
                            <div
                                style={{
                                    borderRadius: 40,
                                    width: 45,
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    background: getColorStatus(valItem.notify_mar),
                                }}
                            >
                                <Radio
                                    checked={
                                        selectedValue[index] !== undefined ? selectedValue[index].month === "03" : false
                                    }
                                    onChange={(event) =>
                                        handleChange(index, valItem.id_organisation, valItem.disconnected_company, event)
                                    }
                                    value="03"
                                    name="radio-button-mar"
                                    color="primary"
                                    disabled={valItem.notify_mar === 0 || valItem.notify_mar === 1}
                                    className={classes.radio}
                                />
                            </div>
                        </LightTooltip>
                    ),
                    apr: (
                        <LightTooltip
                        disableHoverListener={valItem.notify_apr !== 1}
                        title={t("clientsTable.missingTool")}
                        >
                            <div
                                style={{
                                    borderRadius: 40,
                                    width: 45,
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    background: getColorStatus(valItem.notify_apr),
                                }}
                            >
                                <Radio
                                    checked={
                                        selectedValue[index] !== undefined ? selectedValue[index].month === "04" : false
                                    }
                                    onChange={(event) =>
                                        handleChange(index, valItem.id_organisation, valItem.disconnected_company, event)
                                    }
                                    value="04"
                                    name="radio-button-apr"
                                    color="primary"
                                    disabled={valItem.notify_apr === 0 || valItem.notify_apr === 1}
                                    className={classes.radio}
                                />
                            </div>
                        </LightTooltip>
                    ),
                    may: (
                        <LightTooltip
                        disableHoverListener={valItem.notify_may !== 1}
                        title={t("clientsTable.missingTool")}
                        >
                            <div
                                style={{
                                    borderRadius: 40,
                                    width: 45,
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    background: getColorStatus(valItem.notify_may),
                                }}
                            >
                                <Radio
                                    checked={
                                        selectedValue[index] !== undefined ? selectedValue[index].month === "05" : false
                                    }
                                    onChange={(event) =>
                                        handleChange(index, valItem.id_organisation, valItem.disconnected_company, event)
                                    }
                                    value="05"
                                    name="radio-button-may"
                                    color="primary"
                                    disabled={valItem.notify_may === 0 || valItem.notify_may === 1}
                                    className={classes.radio}
                                />
                            </div>
                        </LightTooltip>
                    ),
                    jun: (
                        <LightTooltip
                        disableHoverListener={valItem.notify_jun !== 1}
                        title={t("clientsTable.missingTool")}
                        >
                            <div
                                style={{
                                    borderRadius: 40,
                                    width: 45,
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    background: getColorStatus(valItem.notify_jun),
                                }}
                            >
                                <Radio
                                    checked={
                                        selectedValue[index] !== undefined ? selectedValue[index].month === "06" : false
                                    }
                                    onChange={(event) =>
                                        handleChange(index, valItem.id_organisation, valItem.disconnected_company, event)
                                    }
                                    value="06"
                                    name="radio-button-jun"
                                    color="primary"
                                    disabled={valItem.notify_jun === 0 || valItem.notify_jun === 1}
                                    className={classes.radio}
                                />
                            </div>
                        </LightTooltip>
                    ),
                    jul: (
                        <LightTooltip
                        disableHoverListener={valItem.notify_jul !== 1}
                        title={t("clientsTable.missingTool")}
                        >
                            <div
                                style={{
                                    borderRadius: 40,
                                    width: 45,
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    background: getColorStatus(valItem.notify_jul),
                                }}
                            >
                                <Radio
                                    checked={
                                        selectedValue[index] !== undefined ? selectedValue[index].month === "07" : false
                                    }
                                    onChange={(event) =>
                                        handleChange(index, valItem.id_organisation, valItem.disconnected_company, event)
                                    }
                                    value="07"
                                    name="radio-button-jul"
                                    color="primary"
                                    disabled={valItem.notify_jul === 0 || valItem.notify_jul === 1}
                                    className={classes.radio}
                                />
                            </div>
                        </LightTooltip>
                    ),
                    aug: (
                        <LightTooltip
                        disableHoverListener={valItem.notify_aug !== 1}
                        title={t("clientsTable.missingTool")}
                        >
                            <div
                                style={{
                                    borderRadius: 40,
                                    width: 45,
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    background: getColorStatus(valItem.notify_aug),
                                }}
                            >
                                <Radio
                                    checked={
                                        selectedValue[index] !== undefined ? selectedValue[index].month === "08" : false
                                    }
                                    onChange={(event) =>
                                        handleChange(index, valItem.id_organisation, valItem.disconnected_company, event)
                                    }
                                    value="08"
                                    name="radio-button-aug"
                                    color="primary"
                                    disabled={valItem.notify_aug === 0 || valItem.notify_aug === 1}
                                    className={classes.radio}
                                />
                            </div>
                        </LightTooltip>
                    ),
                    sep: (
                        <LightTooltip
                        disableHoverListener={valItem.notify_sep !== 1}
                        title={t("clientsTable.missingTool")}
                        >
                            <div
                                style={{
                                    borderRadius: 40,
                                    width: 45,
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    background: getColorStatus(valItem.notify_sep),
                                }}
                            >
                                <Radio
                                    checked={
                                        selectedValue[index] !== undefined ? selectedValue[index].month === "09" : false
                                    }
                                    onChange={(event) =>
                                        handleChange(index, valItem.id_organisation, valItem.disconnected_company, event)
                                    }
                                    value="09"
                                    name="radio-button-sep"
                                    color="primary"
                                    disabled={valItem.notify_sep === 0 || valItem.notify_sep === 1}
                                    className={classes.radio}
                                />
                            </div>
                        </LightTooltip>
                    ),
                    oct: (
                        <LightTooltip
                        disableHoverListener={valItem.notify_oct !== 1}
                        title={t("clientsTable.missingTool")}
                        >
                            <div
                                style={{
                                    borderRadius: 40,
                                    width: 45,
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    background: getColorStatus(valItem.notify_oct),
                                }}
                            >
                                <Radio
                                    checked={
                                        selectedValue[index] !== undefined ? selectedValue[index].month === "10" : false
                                    }
                                    onChange={(event) =>
                                        handleChange(index, valItem.id_organisation, valItem.disconnected_company, event)
                                    }
                                    value="10"
                                    name="radio-button-oct"
                                    color="primary"
                                    disabled={valItem.notify_oct === 0 || valItem.notify_oct === 1}
                                    className={classes.radio}
                                />
                            </div>
                        </LightTooltip>
                    ),
                    nov: (
                        <LightTooltip
                        disableHoverListener={valItem.notify_nov !== 1}
                        title={t("clientsTable.missingTool")}
                        >
                            <div
                                style={{
                                    borderRadius: 40,
                                    width: 45,
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    background: getColorStatus(valItem.notify_nov),
                                }}
                            >
                                <Radio
                                    checked={
                                        selectedValue[index] !== undefined ? selectedValue[index].month === "11" : false
                                    }
                                    onChange={(event) =>
                                        handleChange(index, valItem.id_organisation, valItem.disconnected_company, event)
                                    }
                                    value="11"
                                    name="radio-button-nov"
                                    color="primary"
                                    disabled={valItem.notify_nov === 0 || valItem.notify_nov === 1}
                                    className={classes.radio}
                                />
                            </div>
                        </LightTooltip>
                    ),
                    dec: (
                        <LightTooltip
                        disableHoverListener={valItem.notify_dec !== 1}
                        title={t("clientsTable.missingTool")}
                        >
                            <div
                                style={{
                                    borderRadius: 40,
                                    width: 45,
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    background: getColorStatus(valItem.notify_dec),
                                }}
                            >
                                <Radio
                                    checked={
                                        selectedValue[index] !== undefined ? selectedValue[index].month === "12" : false
                                    }
                                    onChange={(event) =>
                                        handleChange(index, valItem.id_organisation, valItem.disconnected_company, event)
                                    }
                                    value="12"
                                    name="radio-button-dec"
                                    color="primary"
                                    disabled={valItem.notify_dec === 0 || valItem.notify_dec === 1}
                                    className={classes.radio}
                                />
                            </div>
                        </LightTooltip>
                    ),
                };
            });

            setClients(clientsdata);
        }
    };

    useEffect(() => {
        setTableData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedValue]);

    useEffect(() => {
        setClients(rowsSkeleton);

        loadInitData(selectedYear);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t, todos.dateFormat, isValidated, clientT, franqF, groupF, clientMarket, clientStatus, idStatus, selectedYear]);

    useEffect(() => {
        (async () => {
            const columnsTemplate = [
                { name: "hiddenCompany", title: "Hidden Companies" },
                { name: "client", title: t("miscellaneous.client") },
                { name: "buttonClear", title: " " },
                { name: "comments", title: " " },
                { name: "validated", title: t("recurrent.validated") },
                { name: "jan", title: t("monthNamesShort.jan") },
                { name: "feb", title: t("monthNamesShort.feb") },
                { name: "mar", title: t("monthNamesShort.mar") },
                { name: "apr", title: t("monthNamesShort.apr") },
                { name: "may", title: t("monthNamesShort.may") },
                { name: "jun", title: t("monthNamesShort.jun") },
                { name: "jul", title: t("monthNamesShort.jul") },
                { name: "aug", title: t("monthNamesShort.aug") },
                { name: "sep", title: t("monthNamesShort.sep") },
                { name: "oct", title: t("monthNamesShort.oct") },
                { name: "nov", title: t("monthNamesShort.nov") },
                { name: "dec", title: t("monthNamesShort.dec") },
            ];
            setColumnsDefs(columnsTemplate);

            let finalFranq = [];
            if (franqF.includes("all")) {
                finalFranq = [];
            } else {
                finalFranq = franqF.filter((item) => item !== "all");
            }

            let finalGroup = [];
            if (groupF.includes("all")) {
                finalGroup = [];
            } else {
                finalGroup = groupF.filter((item) => item !== "all");
            }

            await fetch(
                `/getNotificationYears?client_type=${clientT}&practice_id=${finalGroup}&franchise_id=${finalFranq}`,
                {
                    method: "GET",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                    },
                }
            )
                .then((res) => res.json())
                .then((data) => {
                    setyearsToNoti(data);
                });
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t, todos.dateFormat, clientT, franqF, groupF]);

    return (
        <StyledContainer component="main" sx={{ flexGrow: 1, width: "100%" }}>
            <GridUI container spacing={2} className={classes.spacing}>
                <GridUI item xs={12} md>
                    <FormControl fullWidth classes={{ root: classes.formRounded }}>
                        <InputLabel id="year-simple-select-label">{t("miscellaneous.year")}</InputLabel>
                        <Select
                            value={selectedYear}
                            labelId="year-simple-select-label"
                            onChange={handleClick}
                            IconComponent={KeyboardArrowDownIcon}
                            className="ddlGeneral"
                            size="small"
                            label={t("miscellaneous.year")}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        mt: 0.7,
                                        borderRadius: 2,
                                        boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                    },
                                },
                            }}
                        >
                            {yearsToNoti.map((year, index) => (
                                <MenuItem key={index} value={year}>
                                    <Typography variant="body1" component="span" align="center" color="textPrimary">
                                        {year}
                                    </Typography>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </GridUI>
                <GridUI item xs={12} md>
                    <FormControl fullWidth classes={{ root: classes.formRounded }}>
                        <InputLabel shrink id="type-simple-select-label">
                            {t("recurrent.typeCli")}
                        </InputLabel>
                        <Select
                            size="small"
                            IconComponent={KeyboardArrowDownIcon}
                            value={clientT}
                            displayEmpty
                            onChange={handleChangeTClient}
                            className="ddlGeneral"
                            input={<OutlinedInput notched label={t("recurrent.typeCli")} />}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        mt: 0.7,
                                        borderRadius: 2,
                                        boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                    },
                                },
                            }}
                        >
                            <MenuItem value="">
                                <Typography variant="body1" component="span" align="center" color="textPrimary">
                                    {t("miscellaneous.all")}
                                </Typography>
                            </MenuItem>
                            {props.typeData.map((value) => (
                                <MenuItem value={value.id}>
                                    <Typography variant="body1" component="span" align="center" color="textPrimary">
                                        {value.id === 1 ? t("miscellaneous.firm") : value.client_type}
                                    </Typography>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </GridUI>
                <GridUI item xs={12} md={2}>
                    <FormControl fullWidth classes={{ root: classes.formRounded }}>
                        <InputLabel shrink id="franq-multiple-select-label">
                            {t("miscellaneous.franchise")}
                        </InputLabel>
                        <Select
                            size="small"
                            labelId="franq-multiple-select-label"
                            IconComponent={KeyboardArrowDownIcon}
                            value={franqF}
                            displayEmpty
                            onChange={handleChangeFranqF}
                            className="ddlGeneral"
                            multiple
                            input={
                                <OutlinedInput
                                    notched
                                    label={t("miscellaneous.franchise")}
                                    sx={{
                                        fontSize: 16,
                                        fontWeight: 600,
                                    }}
                                />
                            }
                            renderValue={(selected) => {
                                if (selected.length === 0) {
                                    return <span>{t("miscellaneous.none")}</span>;
                                }
                                return selected.includes("all")
                                    ? t("miscellaneous.all")
                                    : selected
                                          .map((item) => {
                                              return franqDataProps.filter((item2) => item2.id === item)[0]
                                                  .franchise_name;
                                          })
                                          .join(", ");
                            }}
                            MenuProps={props.MenuProps}
                        >
                            {[
                                {
                                    id: "all",
                                    franchise_name: t("miscellaneous.all"),
                                },
                            ]
                                .concat(franqData)
                                .map((value) => (
                                    <MenuItem value={value.id}>
                                        <Checkbox
                                            defaultChecked
                                            checked={franqF.indexOf(value.id) > -1}
                                            icon={
                                                value.id === "all" && !franqF.includes("all") && franqF.length > 0 ? (
                                                    <IndeterminateCheckBoxIcon style={{ fill: "#031851" }} />
                                                ) : (
                                                    <CheckBoxOutlineBlankIcon />
                                                )
                                            }
                                        />
                                        <ListItemText primary={value.franchise_name} />
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                </GridUI>
                <GridUI item xs={12} md={2}>
                    <FormControl fullWidth classes={{ root: classes.formRounded }}>
                        <InputLabel shrink id="group-simple-select-label">
                            {t("miscellaneous.group")}
                        </InputLabel>
                        <Select
                            size="small"
                            labelId="group-simple-select-label"
                            IconComponent={KeyboardArrowDownIcon}
                            value={groupF}
                            displayEmpty
                            onChange={handleChangeGroupF}
                            className="ddlGeneral"
                            multiple
                            input={
                                <OutlinedInput
                                    notched
                                    label={t("miscellaneous.group")}
                                    sx={{
                                        fontSize: 16,
                                        fontWeight: 600,
                                    }}
                                />
                            }
                            renderValue={(selected) => {
                                if (selected.length === 0) {
                                    return <span>{t("miscellaneous.none")}</span>;
                                }
                                return selected.includes("all")
                                    ? t("miscellaneous.all")
                                    : selected
                                          .map((item) => {
                                              return groupDataProps.filter((item2) => item2.id === item)[0].group_name;
                                          })
                                          .join(", ");
                            }}
                            MenuProps={props.MenuProps}
                        >
                            {[
                                {
                                    id: "all",
                                    group_name: t("miscellaneous.all"),
                                },
                            ]
                                .concat(groupData)
                                .map((value) => (
                                    <MenuItem value={value.id}>
                                        <Checkbox
                                            defaultChecked
                                            checked={groupF.indexOf(value.id) > -1}
                                            icon={
                                                value.id === "all" && !groupF.includes("all") && groupF.length > 0 ? (
                                                    <IndeterminateCheckBoxIcon style={{ fill: "#031851" }} />
                                                ) : (
                                                    <CheckBoxOutlineBlankIcon />
                                                )
                                            }
                                        />
                                        <ListItemText primary={value.group_name} />
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                </GridUI>
                <GridUI item xs={12} md={2}>
                    <FormControl fullWidth classes={{ root: classes.formRounded }}>
                        <InputLabel shrink id="idStatus-simple-select-label">
                            {t("generalDashboard.market")}
                        </InputLabel>
                        <Select
                            size="small"
                            labelId="market-multiple-select-label"
                            IconComponent={KeyboardArrowDownIcon}
                            displayEmpty
                            value={clientMarket}
                            className="ddlGeneral"
                            onChange={handleChangeclientMarket}
                            multiple
                            input={
                                <OutlinedInput
                                    notched
                                    label={t("generalDashboard.market")}
                                    sx={{
                                        fontSize: 16,
                                        fontWeight: 600,
                                    }}
                                />
                            }
                            renderValue={(selected) => {
                                if (selected.length === 0) {
                                    return <span>{t("miscellaneous.none")}</span>;
                                }

                                return selected.includes(t("miscellaneous.all"))
                                    ? t("miscellaneous.all")
                                    : selected.join(", ");
                            }}
                            MenuProps={props.MenuProps}
                        >
                            {props.markets.map((name) => (
                                <MenuItem value={name}>
                                    <Checkbox
                                        checked={clientMarket.indexOf(name) > -1}
                                        icon={
                                            name === t("miscellaneous.all") &&
                                            !clientMarket.includes(t("miscellaneous.all")) &&
                                            clientMarket.length > 0 ? (
                                                <IndeterminateCheckBoxIcon style={{ fill: "#031851" }} />
                                            ) : (
                                                <CheckBoxOutlineBlankIcon />
                                            )
                                        }
                                    />
                                    <ListItemText primary={name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </GridUI>
                <GridUI item xs={12} md={1}>
                    <FormControl fullWidth classes={{ root: classes.formRounded }}>
                        <InputLabel shrink id="idStatus-simple-select-label">
                            {t("recurrent.status")}
                        </InputLabel>
                        <Select
                            size="small"
                            IconComponent={KeyboardArrowDownIcon}
                            value={idStatus}
                            displayEmpty
                            onChange={handleChangeIdStatus}
                            className="ddlGeneral"
                            multiple
                            input={
                                <OutlinedInput
                                    notched
                                    label={t("recurrent.status")}
                                    sx={{
                                        fontSize: 16,
                                        fontWeight: 600,
                                    }}
                                />
                            }
                            renderValue={(selected) => {
                                if (selected.length === 0) {
                                    return <span>{t("miscellaneous.all")}</span>;
                                }
                                return selected.includes("all")
                                    ? t("miscellaneous.all")
                                    : selected
                                          .map((item) => {
                                              return statusArray.filter((item2) => item2.id === item)[0].name;
                                          })
                                          .join(", ");
                            }}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        mt: 0.7,
                                        borderRadius: 2,
                                        boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                    },
                                },
                            }}
                        >
                           {statusArray.map((value) => (
                                <MenuItem value={value.id}>
                                    <Checkbox
                                        defaultChecked
                                        checked={idStatus.indexOf(value.id) > -1}
                                        icon={
                                            value.id === "all" && !idStatus.includes("all") && idStatus.length > 0 ? (
                                                <IndeterminateCheckBoxIcon style={{ fill: "#031851" }} />
                                            ) : (
                                                <CheckBoxOutlineBlankIcon />
                                            )
                                        }
                                    />
                                    <ListItemText primary={value.name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </GridUI>
                <GridUI item xs={12} md>
                    <FormControl fullWidth classes={{ root: classes.formRounded }}>
                        <InputLabel shrink id="clientStatus-simple-select-label">
                            {t("miscellaneous.classification")}
                        </InputLabel>
                        <Select
                            size="small"
                            IconComponent={KeyboardArrowDownIcon}
                            value={clientStatus}
                            displayEmpty
                            onChange={handleChangeClasif}
                            className="ddlGeneral"
                            input={<OutlinedInput notched label={t("miscellaneous.classification")} />}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        mt: 0.7,
                                        borderRadius: 2,
                                        boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                    },
                                },
                            }}
                        >
                            <MenuItem value="">
                                <Typography variant="body1" component="span" align="center" color="textPrimary">
                                    {t("miscellaneous.all")}
                                </Typography>
                            </MenuItem>
                            <MenuItem value={1}>
                                <Typography
                                    variant="body1"
                                    component="span"
                                    align="center"
                                    color="textPrimary"
                                    sx={{
                                        fontStyle: "italic",
                                        ...(moment.locale() === "en" && { fontStyle: "normal" }),
                                    }}
                                >
                                    Standard
                                </Typography>
                            </MenuItem>
                            <MenuItem value={2}>
                                <Typography
                                    variant="body1"
                                    component="span"
                                    align="center"
                                    color="textPrimary"
                                    sx={{
                                        fontStyle: "italic",
                                        ...(moment.locale() === "en" && { fontStyle: "normal" }),
                                    }}
                                >
                                    VIP
                                </Typography>
                            </MenuItem>
                        </Select>
                    </FormControl>
                </GridUI>
                <GridUI item xs={12} md>
                    <Button
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        disableElevation
                        onClick={handleOpenDialog}
                        disabled={resultTable.pendingCont === 0 && selectedValueCont === 0}
                        sx={{
                            width: "150px !important",
                        }}
                    >
                        {t("miscellaneous.validate")}
                    </Button>
                </GridUI>
            </GridUI>
            <GridUI container>
                <GridUI item xs={12} md={6} sx={{ mb: { sm: -5 }, zIndex: 1 }}>
                    <Button
                        variant="text"
                        color="primary"
                        disabled={anyFilter}
                        onClick={cleanFilters}
                        sx={{
                            "&:hover": {
                                color: "#2f3190",
                            },
                        }}
                    >
                        {t("miscellaneous.cleanFilters")}
                    </Button>
                </GridUI>
                <GridUI item xs={12}>
                    <Grid rows={clientsData} columns={columnDefs}>
                        <SearchState value={searchValue} onValueChange={handleSearch} />
                        <IntegratedFiltering />
                        <PagingState defaultCurrentPage={0} pageSize={10} />
                        <IntegratedPaging />
                        <Table
                            tableComponent={TableComponent}
                            columnExtensions={tableColumnExtensions}
                            cellComponent={TableCellComponent}
                            messages={tableMessages}
                        />
                        <TableColumnVisibility defaultHiddenColumnNames={defaultHiddenColumnNames} />
                        <TableHeaderRow cellComponent={tableHeaderCell} contentComponent={tableContentComponent} />
                        <Toolbar rootComponent={toolRootComponent} />
                        <SearchPanel messages={searchMessages} inputComponent={inputComponent} />
                        <PagingPanel containerComponent={PagingPanelContainer} messages={pagingMessages} />
                    </Grid>
                </GridUI>
            </GridUI>
            <DialogAlerts
                open={open}
                onClose={handleCloseDialog}
                agreeBtnLabel={t("miscellaneous.accept")}
                disagreeBtnLabel={t("miscellaneous.cancel")}
                type={dialogType}
                title={dialogText}
                agreeAction={validateClients}
            />
            <CommentDialog
                open={dialogComment}
                onClose={closeDialog}
                maxWidth="xs"
                clientID={clientID}
                setTexto={setTexto}
                seAutoAlertType={seAutoAlertType}
                setBackDrop={setBackDrop}
                setShow={setShow}
                comments={comments}
                commentsP={commentsP}
                getComments={getComments}
                setInitData={loadInitData}
                year={selectedYear}
                commentType={1}
            />
            <SnackBar type={alertType} show={show} message={texto} onClose={handleClose} />
            <Backdrop open={showBackdrop} />
        </StyledContainer>
    );
};

export default Validations;
