/** React imports */
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

/** Images */

/** MUI icons */
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

/** MUI imports */
import { Box, Typography, Grid, Divider } from "@mui/material";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

/** Global variables */
const PREFIX = "ServiceLevelsCont";

const classes = {
    body2: `${PREFIX}-body2`,
    serviceName: `${PREFIX}-serviceName`,
    serviceBody: `${PREFIX}-serviceBody`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.serviceName}`]: {
        fontSize: 22,
    },

    [`& .${classes.body2}`]: {
        backgroundColor: "white",
        marginTop: 15,
        minHeight: 850,
        [theme.breakpoints.down("md")]: {
            padding: 30,
        },
        [theme.breakpoints.up("md")]: {
            padding: 60,
        },
    },

    [`& .${classes.serviceBody}`]: {
        backgroundColor: "#F8F9F8",
        marginTop: 30,
        padding: 30,
        borderRadius: 30,
    },
}));

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)({
    boxShadow: "none",
    "&:before": {
        display: "none",
    },
    backgroundColor: "#F8F9F8",
});

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: "#031851" }} />} {...props} />
))({
    padding: 0,
    "& .MuiAccordionSummary-content": {
        margin: "12px 0",
    },
});

const AccordionDetails = styled(MuiAccordionDetails)({
    padding: "0px 0px 16px 0px",
    "& .MuiCard-root": {
        boxShadow: "none",
    },
});

export const ServiceLevelsCont = (props) => {
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    const { contInfo } = props;
    const [expandedSub, setExpandedSub] = useState(false);
    const handleChangeAccSub = (panel) => (event, isExpanded) => {
        setExpandedSub(isExpanded ? panel : false);
    };

    return (
        <StyledContainer>
            {contInfo.onboarding.isActive && (
                <Accordion
                    defaultExpanded
                    TransitionProps={{ unmountOnExit: true }}
                    sx={{
                        borderBottom: "3px solid #ff5968",
                    }}
                >
                    <AccordionSummary aria-controls="panelAuxbh-content" id="panelAuxbh-header">
                        <Typography variant="h4">Onboarding</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Divider sx={{ mb: 2 }} />
                        <Grid container spacing={3} alignItems="center">
                            <Grid item xs={12} md={4}>
                                <Typography variant="body1">{t("proposals.intel")}</Typography>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <Typography variant="body1">{t("proposals.ïntelDet")}</Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Typography variant="body1">{t("proposals.design")}</Typography>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <Typography variant="body1">{t("proposals.designDet")}</Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Typography variant="body1">{t("proposals.setUpBook")}</Typography>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <Typography variant="body1">{t("proposals.settingDet")}</Typography>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            )}
            {(contInfo.recurring.isActive || contInfo.catchUp.isActive) && (
                <Accordion
                    defaultExpanded
                    TransitionProps={{ unmountOnExit: true }}
                    sx={{
                        borderBottom: "3px solid #ff5968",
                    }}
                >
                    <AccordionSummary aria-controls="panelAuxbh-content" id="panelAuxbh-header">
                        <Typography variant="h4">{t("proposals.recuServi") + " & catch-up"}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Divider sx={{ mb: 2 }} />
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12} md={4}>
                                <Typography variant="body1">{t("proposals.contrDed")}</Typography>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <Typography variant="body1">{t("proposals.aPubAccCont")}</Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Typography variant="body1">{t("proposals.arqFinanCont")}</Typography>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <Typography variant="body1">{t("proposals.arqFinanContDet")}</Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Typography variant="body1" sx={{ pt: 1 }}>
                                    {t("proposals.interContFin")}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <Typography variant="body1" sx={{ pt: 1 }}>
                                    {t("proposals.interContFinDet")}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Typography variant="body1" sx={{ pt: 1 }}>
                                    {t("proposals.politProc")}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <Typography variant="body1" sx={{ pt: 1 }}>
                                    {t("proposals.politProcDet")}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Typography variant="body1" sx={{ pt: 1 }}>
                                    {t("proposals.addPerRep")}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <Typography variant="body1" sx={{ pt: 1 }}>
                                    {t("proposals.addTayMade")}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Typography variant="body1" sx={{ pt: 1 }}>
                                    {t("proposals.virtualMeet")}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <Typography variant="body1" sx={{ pt: 1 }}>
                                    {t("proposals.virtualMeetDet")}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Typography variant="body1" sx={{ pt: 1 }}>
                                    {t("proposals.consoFinanCont")}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <Typography variant="body1" sx={{ pt: 1 }}>
                                    {t("proposals.finanConDet")}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Typography variant="body1" sx={{ pt: 1 }}>
                                    {t("proposals.writtenSup")}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <Typography variant="body1" sx={{ pt: 1 }}>
                                    {t("proposals.UnlimSup")}
                                </Typography>
                            </Grid>
                        </Grid>
                        {((contInfo.recurring.additionals.length !== 0 && !contInfo.recurring.onlyAdd) ||
                            (contInfo.catchUp.additionals.length !== 0 && !contInfo.catchUp.onlyAdd)) && (
                            <Grid container spacing={2} alignItems="center" sx={{ p: "15px 64px 0px 64px" }}>
                                <Grid item xs={12}>
                                    <Typography variant="h4">{t("proposals.addons")}</Typography>
                                    <Divider
                                        sx={{
                                            mt: 1,
                                            backgroundColor: "#CCCCCC",
                                        }}
                                    />
                                </Grid>
                                {contInfo.genAdditionals.exe && (
                                    <>
                                        <Grid item xs={12} md={4}>
                                            <Typography variant="body1">{t("services.executionTaxPlan")}</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={8}>
                                            <Typography variant="body1">{t("services.executionTaxPlanDet")}</Typography>
                                        </Grid>
                                    </>
                                )}
                                {contInfo.genAdditionals.cons && (
                                    <>
                                        <Grid item xs={12} md={4}>
                                            <Typography variant="body1">
                                                {t("services.consolidationStatements")}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={8}>
                                            <Typography variant="body1">
                                                {t("services.consolidationStatementsDet")}
                                            </Typography>
                                        </Grid>
                                    </>
                                )}
                                {contInfo.genAdditionals.rep && (
                                    <>
                                        <Grid item xs={12} md={4}>
                                            <Typography variant="body1">{t("services.reportsAuditors")}</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={8}>
                                            <Typography variant="body1">{t("services.reportsAuditorsDet")}</Typography>
                                        </Grid>
                                    </>
                                )}
                                {contInfo.genAdditionals.other && (
                                    <Accordion
                                        expanded={expandedSub === "Others"}
                                        onChange={handleChangeAccSub("Others")}
                                        TransitionProps={{
                                            unmountOnExit: true,
                                        }}
                                        sx={{ width: "100%", pl: "16px", pt: "5px" }}
                                    >
                                        <AccordionSummary aria-controls="panelAuxbh-content" id="panelAuxbh-header">
                                            <Grid container spacing={2} alignItems="center">
                                                <Grid item xs={12} md={4}>
                                                    <Typography variant="body1">{t("proposals.addOthers")}</Typography>
                                                </Grid>
                                                <Grid item xs={12} md={8} sx={{ pl: "16px" }}>
                                                    <Typography variant="body1" sx={{ pl: "7px" }}>
                                                        {t("services.othersDet")}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Divider sx={{ mb: 2 }} />
                                            <Box sx={{ p: "0 30px" }}>
                                                <Grid container spacing={2} alignItems="center">
                                                    {contInfo.recurring.additionals.filter(
                                                        (item) => item.additional_name === "Others"
                                                    ).length > 0 && (
                                                        <Grid item xs={12}>
                                                            <Typography variant="body1">
                                                                {
                                                                    contInfo.recurring.additionals.filter(
                                                                        (item) => item.additional_name === "Others"
                                                                    )[0].other_name
                                                                }
                                                            </Typography>
                                                        </Grid>
                                                    )}
                                                    {contInfo.catchUp.additionals.filter(
                                                        (item) => item.additional_name === "Others"
                                                    ).length > 0 && (
                                                        <Grid item xs={12}>
                                                            <Typography variant="body1">
                                                                {
                                                                    contInfo.catchUp.additionals.filter(
                                                                        (item) => item.additional_name === "Others"
                                                                    )[0].other_name
                                                                }
                                                            </Typography>
                                                        </Grid>
                                                    )}
                                                </Grid>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                )}
                            </Grid>
                        )}
                    </AccordionDetails>
                </Accordion>
            )}
        </StyledContainer>
    );
};
