/** React imports */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

/** Images */
import { ReactComponent as SecondaryGraph } from "../../../../assets/images/secondary-graphic-version-2.svg";
import { ReactComponent as GcaLogo } from "../../../../assets/images/gca-logo-desktop.svg";

/** MUI icons */

/** MUI imports */
import Grid from "@mui/material/Grid";
import { Box, Typography, Link } from "@mui/material";
import { styled } from "@mui/material/styles";

/** Global variables */
const PREFIX = "FinalPage";

const classes = {
    body: `${PREFIX}-body`,
    logoGCA: `${PREFIX}-logoGCA`,
    panels2: `${PREFIX}-panels2`,
    panelText1: `${PREFIX}-panelText1`,
    panelText2: `${PREFIX}-panelText2`,
    imgGCA: `${PREFIX}-imgGCA`,
    gcaMail: `${PREFIX}-gcaMail`,
    gcaText: `${PREFIX}-gcaText`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.body}`]: {
        marginTop: 15,
        background: "#031851",
    },

    [`& .${classes.logoGCA}`]: {
        width: 250,
        marginTop: 175,
    },

    [`& .${classes.panels2}`]: {
        [theme.breakpoints.down("md")]: {
            padding: "60px 30px 0px 30px",
        },
        [theme.breakpoints.up("md")]: {
            paddingTop: 300,
            paddingBottom: 100,
        },
    },

    [`& .${classes.panelText1}`]: {
        [theme.breakpoints.down("md")]: {
            width: "100%",
            textAlign: "center",
            padding: 5,
        },
        width: 400,
        marginTop: 230,
        fontSize: 22,
    },

    [`& .${classes.panelText2}`]: {
        [theme.breakpoints.down("md")]: {
            width: "100%",
            textAlign: "center",
            padding: 5,
        },
        width: 400,
        color: "white",
        paddingBottom: 50,
        fontSize: 22,
    },

    [`& .${classes.imgGCA}`]: {
        [theme.breakpoints.down("md")]: {
            width: "100%",
        },
        [theme.breakpoints.up("md")]: {
            width: 400,
        },
    },

    [`& .${classes.gcaMail}`]: {
        color: "white",
        paddingBottom: 15,
        [theme.breakpoints.down("md")]: {
            textAlign: "center",
            paddingTop: 15,
        },
    },

    [`& .${classes.gcaText}`]: {
        marginBottom: 100,
        fontWeight: 700,
        fontSize: 16,
        [theme.breakpoints.down("md")]: {
            textAlign: "center",
        },
    },
}));

export const FinalPage = (props) => {
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    const { proposalDetails, mode } = props;
    const [texts, setTexts] = useState({});

    useEffect(() => {
        let name = mode === "preview" ? proposalDetails.name : proposalDetails.service_name;

        setTexts({
            maintitle:
                name === "Accountant"
                    ? t("proposals.weAreThr")
                    : name === "Bookkeeper"
                        ? t("proposals.weAreThrBookB")
                        : name === "CFO"
                            ? t("proposals.cfoFinalMain")
                            : t("proposals.weAreThrBook"),
            secondTitle:
                name === "Accountant"
                    ? t("proposals.crunNumb")
                    : name === "Bookkeeper"
                        ? t("proposals.crunNumbBookB")
                        : name === "CFO"
                            ? t("proposals.cfoFinalSecond")
                            : t("proposals.crunNumbBook"),
        });
    }, [proposalDetails, mode, t]);

    return (
        <StyledContainer>
            <Box className={classes.body}>
                <Grid container alignItems="center">
                    <Grid item xs={12} md={5}>
                        <SecondaryGraph className={classes.logoGCA} />
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <Typography variant="h5" color="secondary" className={classes.panelText1}>
                            {texts.maintitle}
                        </Typography>
                        <Typography className={classes.panelText2} variant="h5">
                            {texts.secondTitle}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container alignItems="center" justifyContent="flex-end">
                    <Grid item xs={12} md={7} className={classes.panels2}>
                        <GcaLogo className={classes.imgGCA} />
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <Typography variant="body1" className={classes.gcaMail}>
                            hola@gca.digital
                        </Typography>
                        <Link href="https://gca.digital" target="_blank" underline="none">
                            <Typography color="secondary" variant="body1" className={classes.gcaText}>
                                www.GCA.digital
                            </Typography>
                        </Link>
                    </Grid>
                </Grid>
            </Box>
        </StyledContainer>
    );
};
