/* React imports */
import React, { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import moment from "moment";

/* MUI icons */
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import InventoryIcon from "@mui/icons-material/Inventory";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import LockIcon from "@mui/icons-material/Lock";
import SyncIcon from "@mui/icons-material/Sync";
import WarningAmberIcon from "@mui/icons-material/Warning";
import SettingsIcon from "@mui/icons-material/Settings";
import BankDialog from "./Requirement/BankDialog";

/** MUI imports */
import { styled } from "@mui/material/styles";
import {
    Box,
    Card,
    CardHeader,
    CardActionArea,
    CardContent,
    Container,
    Divider,
    Grid,
    IconButton,
    ListItem,
    ListItemButton,
    ListItemText,
    Popover,
    Skeleton,
    Typography,
    Button,
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

/** Component imports */
import DialogConversions from "./DialogConversions";
import RegModal from "./Dashboard/RegModal";
import Backdrop from "./Backdrop";
import SnackBar from "./SnackBar";
import DialogAlerts from "./DialogAlerts";

import calls from "../Js/calls";

/** Component styles */
const PREFIX = "BankValidations";

const classes = {
    accountAmount: `${PREFIX}-accountAmount`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.accountAmount}`]: {
        color: "#2f3190",
        cursor: "pointer",
        padding: "7px",
        borderRadius: "6px",
        "&:hover": {
            borderRadius: "6px",
            backgroundColor: "rgba(47,49,144,0.3)",
        },
    },
}));

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "rgba(19, 31, 62, 0.80)",
        fontSize: 12,
        maxWidth: 320,
        borderRadius: 6,
        fontWeight: "normal",
    },
});

const ColorButton = styled(Button)(({ theme }) => ({
    border: "1px solid #ff5968",
    borderRadius: 40,
}));

/** Global variables */
const monthDetails = {
    isActive: false,
    isClosed: false,
    label: "",
    accountingStatus: 0,
    bankAccountingStatus: 0,
    bankBalanceStatus: 0,
    initialDate: new Date(),
    endDate: new Date(),
    oldestAccountingDate: new Date(),
    unreconciledAccountingAmount: 0,
    unreconciledAccountingItems: 0,
    unreconciledAmount: 0,
    oldestBankDate: new Date(),
    unreconciledBankAmount: 0,
    unreconciledBankItems: 0,
    xeroAccountingBalance: 0,
    xeroBankBalance: 0,
    statementBalance: "",
};

var monthsSelected;

const BankValidations = (props) => {
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    const lastMonthClosed = moment().subtract(1, "months").format("MM");
    const secondLastMonthClosed = moment().subtract(2, "months").format("MM");

    /** React redux */
    const todos = useSelector((state) => state.todos);

    /** Component References */
    const yearCardRef = useRef(null);

    /** Component states */
    const [open, setOpen] = useState(false);
    const [filesRejected, setFilesRejected] = useState([]);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [filesData, setFilesData] = useState([]);
    const [fileFormat, setFileFormat] = useState([]);
    const [updatedDate, setUpdateDate] = useState(null);
    const [fileExtensions, setFileExtensions] = useState([]);
    const [openRegModal, setOpenRegModal] = useState(false);
    const [years, setYears] = useState([]);
    const [yearAnchorEl, setYearAnchorEl] = useState(null);
    const [selectedMonth, setSelectedMonth] = useState(0);
    const [mainAmount, setMainAmount] = useState({
        amount: 0,
        bankBalanceStatus: 0,
        initDate: null,
        endDate: null,
    });
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [conversionsData, setConversionData] = useState([]);
    const [showBackdrop, setBackDrop] = useState(false);
    const [loading, showLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [texto, setTexto] = useState("");
    const [alertType, setAlertType] = useState("");
    const [accountNumber, setAccountNumber] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [mode, setMode] = useState("");
    const [openAlert, setOpenAlert] = useState(false);
    const [dialogText, setDialogText] = useState("");
    const [dialogAction, setDialogAction] = useState("");
    const [updating, setUpdating] = useState(false);
    const [valuesValidations, setValuesValidations] = useState({
        jan: monthDetails,
        feb: monthDetails,
        mar: monthDetails,
        apr: monthDetails,
        may: monthDetails,
        jun: monthDetails,
        jul: monthDetails,
        aug: monthDetails,
        sep: monthDetails,
        oct: monthDetails,
        nov: monthDetails,
        dec: monthDetails,
    });
    const [valuesBank, setValuesBank] = useState({
        idAccount: "",
        accountName: "",
        orgName: "",
        monitoringFreq: "",
        receptionDays: 2,
        mode: "",
        bankName: "",
        checkedNA: false,
        manageReq: false,
        software: "",
        initDate: null,
        endDate: null,
        checksInc: false,
        requirements: [],
    });
    const [dialogForm, setDialogForm] = useState(false);
    const [isError, setIsError] = useState(false);
    const [banks, setBanks] = useState([]);

    /** Component variables */
    const openPopoverYear = Boolean(yearAnchorEl);
    const id = openPopoverYear ? "simple-popover" : undefined;

    /** Component functions */
    const handleClickYearPopover = (event) => {
        setYearAnchorEl(event.currentTarget);
    };

    const handleCloseYearPopover = () => {
        setYearAnchorEl(null);
    };

    const showSnack = () => {
        if (updating) {
            setTexto(t("dialogConversions.waitUpdating"));
            setAlertType("info");
            setShow(true);
        }
    };

    const handleOpenDialog = async (accountIdXero) => {
        await fetch(`/getConversions?id_bank_xero=${accountIdXero}`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((res) => res.json())
            .then((data) => {
                setConversionData(data);
            });

        setOpen(true);
    };

    const handleClickValidations = (event, month) => {
        setValuesValidations({
            ...valuesValidations,
            [month]: {
                ...valuesValidations[month],
                isClosed: !event,
            },
        });
        monthsSelected[month] = !event;
    };

    const handleCloseDialog = () => {
        setFilesData([]);
        setFilesRejected([]);
        setUploadedFiles([]);
        setOpenRegModal(false);
    };

    const handleOpenBankDialog = async (month, element) => {
        setBackDrop(true);

        await fetch(
            `/getUploadedFileStatements?account_id=${props.accountIdPlatformSelected}&date=${moment()
                .set({ year: selectedYear, month: month })
                .startOf("month")
                .format("YYYY-MM-DD")}`,
            {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            }
        )
            .then((res) => res.json())
            .then((data) => {
                if (mode !== "Bank feeds") {
                    setDialogAction("Convertir");
                    setDialogText(t("bank.convertBankStatement"));
                } else {
                    setDialogAction("Guardar");
                    setDialogText(t("bank.saveBankStatement"));
                }
                setUploadedFiles(data.files);
                setSelectedMonth(month);

                setMainAmount({
                    amount: element.statementBalance,
                    bankBalanceStatus: element.bankBalanceStatus,
                    initDate: element.initDateModal,
                    endDate: element.endDateModal,
                });
                setOpenRegModal(true);
                setFileExtensions(fileFormat.map((file) => `.${file}`).join("|"));
            });

        setBackDrop(false);
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setShow(false);
    };

    const setIconMonth = (status) => {
        if (status === 0) {
            return <CheckIcon sx={{ fontSize: 30, color: "#4caf50" }} />;
        } else if (status === 1) {
            return <WarningAmberIcon sx={{ fontSize: 30, color: "#FFB039" }} />;
        } else {
            return <CancelIcon color="secondary" sx={{ fontSize: 30 }} />;
        }
    };

    const convertBankStatement = async () => {
        setOpenAlert(false);
        setBackDrop(true);

        let fileName = [];
        let allowedExtensions = new RegExp(`(${fileExtensions})`, "i");
        let valid = false;

        const params = {
            conversion: true,
            account_id: props.accountIdPlatformSelected,
            date: moment().set({ year: selectedYear, month: selectedMonth }).startOf("month").format("YYYY-MM-DD"),
            not_applicable: false,
        };

        if (uploadedFiles.length === 0) {
            fileName = filesData[0].name.toLowerCase();
        } else {
            fileName = uploadedFiles[0].toLowerCase();
        }

        if (allowedExtensions.exec(fileName)) {
            valid = true;
        } else {
            setTexto(`${t("manageAccount.invalidFormat")} ${fileFormat.map((file) => `.${file}`).join(" ")}`);
            setAlertType("error");
            setShow(true);
        }

        if (valid) {
            await fetch(`/convertBankStatement`, {
                method: "POST",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
                body: JSON.stringify(params),
            })
                .then((res) => res.json())
                .then((response) => {
                    if (response.message === "El estado de cuenta se ha guardado exitosamente") {
                        setTimeout(() => {
                            callLoads();
                            handleCloseDialog();
                            setTexto(t("dialogConversions.statementSaved"));
                            setAlertType("success");
                            setShow(true);
                            setBackDrop(false);
                        }, 10000);
                    } else {
                        handleCloseDialog();
                        switch (response.message) {
                            case "No existe la organización asociada a esta cuenta bancaria":
                                setTexto(t("manageAccount.error002"));
                                break;
                            case "No existe un banco asociado a esta cuenta bancaria":
                                setTexto(t("manageAccount.error003"));
                                break;
                            case "Ha ocurrido un error al transformar el archivo":
                                setTexto(t("manageAccount.error204"));
                                break;
                            case "El archivo está vacío o no es editable":
                                setTexto(t("manageAccount.error205"));
                                break;
                            case "El archivo ya está transformado":
                                setTexto(t("manageAccount.error206"));
                                break;
                            case "El archivo no posee transacciones":
                                setTexto(t("manageAccount.error207"));
                                break;
                            default:
                                setTexto(t("manageAccount.error001"));
                                break;
                        }
                        setAlertType("error");
                        setShow(true);
                        setBackDrop(false);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setBackDrop(false);
                });
        }
    };

    const udpateBankMovements = async () => {
        setBackDrop(true);
        setUpdating(true);

        const params = {
            id_organisation: props.idOrganisation,
            year: selectedYear,
        };

        await fetch(`/updateBankMovements`, {
            method: "POST",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
            body: JSON.stringify(params),
        })
            .then(() => {
                setTexto(t("dialogConversions.waitUpdating"));
                setAlertType("info");
                setShow(true);
            })
            .catch((error) => {
                console.log(error);
            });

        setBackDrop(false);
    };

    const uploadAsyncButtons = (
        <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
            <Grid item>
                <ColorButton
                    sx={{
                        mr: 1,
                        "&:hover": {
                            backgroundColor: "white",
                            color: "#2f3190",
                            border: "1px solid #2f3190",
                        },
                    }}
                    color="secondary"
                    disableElevation
                    variant="outlined"
                    onClick={handleCloseDialog}
                >
                    {t("miscellaneous.cancel")}
                </ColorButton>
            </Grid>
            <Grid item>
                <ColorButton
                    disableElevation
                    variant="contained"
                    color="secondary"
                    disabled={filesData.length === 0 && uploadedFiles.length === 0}
                    onClick={() => setOpenAlert(true)}
                    sx={{
                        "&:hover": {
                            backgroundColor: "#2f3190",
                            border: "1px solid #2f3190",
                            color: "#fff",
                        },
                        "&.MuiButton-root.Mui-disabled": {
                            backgroundColor: "#ffcdd2 !important",
                            border: "none",
                            color: "#fff",
                        },
                    }}
                >
                    {dialogAction === "Convertir" ? t("bank.convert") : t("miscellaneous.save")}
                </ColorButton>
            </Grid>
        </Grid>
    );

    const loadInitData = async () => {
        showLoading(true);

        let monthDetailsJan = monthDetails;
        let monthDetailsFeb = monthDetails;
        let monthDetailsMar = monthDetails;
        let monthDetailsApr = monthDetails;
        let monthDetailsMay = monthDetails;
        let monthDetailsJun = monthDetails;
        let monthDetailsJul = monthDetails;
        let monthDetailsAug = monthDetails;
        let monthDetailsSep = monthDetails;
        let monthDetailsOct = monthDetails;
        let monthDetailsNov = monthDetails;
        let monthDetailsDec = monthDetails;

        let currentYear = new Date().getFullYear();
        let generateYearsArray = Array.from(
            { length: parseInt(currentYear) - parseInt(props.oldestYear) + 1 },
            (v, i) => i + parseInt(props.oldestYear)
        );
        setYears(generateYearsArray);

        await fetch(`/getBankAccountsValidations?year=${selectedYear}&account_id=${props.accountIdPlatformSelected}`, {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then((res) => res.json())
            .then((data) => {
                setAccountNumber(data.account_number);
                setFileFormat(data.file_format);
                setUpdateDate(data.last_updated_date);
                setUpdating(data.updating);
                setCompanyName(data.company_name);

                if (data.mode === undefined) {
                    setMode(t("miscellaneous.pendingMode"));
                } else if (data.mode === 0) {
                    setMode(t("miscellaneous.automatic"));
                } else {
                    setMode(t("miscellaneous.manual"));
                }

                Object.values(valuesValidations).forEach((element, index) => {
                    if (index === 0) {
                        if (data.validation_jan !== undefined) {
                            monthDetailsJan = {
                                ...monthDetailsJan,
                                isActive: moment(selectedYear + "-01", "YYYY-MM").isSameOrBefore(
                                    moment(lastMonthClosed, "MM")
                                ),
                                isClosed: monthsSelected.jan
                                    ? monthsSelected.jan
                                    : "01" === lastMonthClosed || "01" === secondLastMonthClosed,
                                label: t("monthNames.january"),
                                initDateModal: data.validation_jan.statement_initial_date,
                                endDateModal: data.validation_jan.statement_end_date,
                                accountingStatus: data.validation_jan.accounting_status,
                                bankAccountingStatus: data.validation_jan.bank_accounting_status,
                                bankBalanceStatus:
                                    data.validation_jan.statement_balance !== undefined &&
                                    data.validation_jan.statement_balance !== null
                                        ? data.validation_jan.bank_balance_status
                                        : 3,
                                initialDate:
                                    data.validation_jan.initial_date !== null
                                        ? moment(data.validation_jan.initial_date, "YYYY-MM-DD").format(
                                              todos.dateFormat
                                          )
                                        : null,
                                endDate:
                                    data.validation_jan.end_date !== null
                                        ? moment(data.validation_jan.end_date, "YYYY-MM-DD").format(todos.dateFormat)
                                        : null,
                                oldestAccountingDate:
                                    data.validation_jan.oldest_accounting_date !== undefined &&
                                    data.validation_jan.oldest_accounting_date !== null
                                        ? moment(data.validation_jan.oldest_accounting_date)
                                        : null,
                                unreconciledAccountingAmount:
                                    data.validation_jan.unreconciled_accounting_amount !== undefined
                                        ? data.validation_jan.unreconciled_accounting_amount
                                        : 0,
                                unreconciledAccountingItems:
                                    data.validation_jan.unreconciled_accounting_items !== undefined
                                        ? data.validation_jan.unreconciled_accounting_items
                                        : 0,
                                unreconciledAmount:
                                    data.validation_jan.unreconciled_amount !== undefined
                                        ? data.validation_jan.unreconciled_amount
                                        : 0,
                                oldestBankDate:
                                    data.validation_jan.oldest_bank_date !== undefined &&
                                    data.validation_jan.oldest_bank_date !== null
                                        ? moment(data.validation_jan.oldest_bank_date)
                                        : null,
                                unreconciledBankAmount:
                                    data.validation_jan.unreconciled_bank_amount !== undefined
                                        ? data.validation_jan.unreconciled_bank_amount
                                        : 0,
                                unreconciledBankItems:
                                    data.validation_jan.unreconciled_bank_items !== undefined
                                        ? data.validation_jan.unreconciled_bank_items
                                        : 0,
                                xeroAccountingBalance:
                                    data.validation_jan.xero_accounting_balance !== undefined
                                        ? data.validation_jan.xero_accounting_balance
                                        : 0,
                                xeroBankBalance:
                                    data.validation_jan.xero_bank_balance !== undefined
                                        ? data.validation_jan.xero_bank_balance
                                        : 0,
                                statementBalance:
                                    data.validation_jan.statement_balance !== undefined
                                        ? parseFloat(data.validation_jan.statement_balance).toLocaleString(
                                              todos.amountFormat,
                                              { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                                          )
                                        : t("bankingDashboard.withoutMovements"),
                            };
                        } else {
                            monthDetailsJan = {
                                ...monthDetailsJan,
                                isActive: false,
                                isClosed: false,
                                label: t("monthNames.january"),
                            };
                        }
                    }
                    if (index === 1) {
                        if (data.validation_feb !== undefined) {
                            monthDetailsFeb = {
                                ...monthDetailsFeb,
                                isActive: moment(selectedYear + "-02", "YYYY-MM").isSameOrBefore(
                                    moment(lastMonthClosed, "MM")
                                ),
                                isClosed: monthsSelected.feb
                                    ? monthsSelected.feb
                                    : "02" === lastMonthClosed || "02" === secondLastMonthClosed,
                                label: t("monthNames.february"),
                                initDateModal: data.validation_feb.statement_initial_date,
                                endDateModal: data.validation_feb.statement_end_date,
                                accountingStatus: data.validation_feb.accounting_status,
                                bankAccountingStatus: data.validation_feb.bank_accounting_status,
                                bankBalanceStatus:
                                    data.validation_feb.statement_balance !== undefined &&
                                    data.validation_feb.statement_balance !== null
                                        ? data.validation_feb.bank_balance_status
                                        : 3,
                                initialDate:
                                    data.validation_feb.initial_date !== null
                                        ? moment(data.validation_feb.initial_date, "YYYY-MM-DD").format(
                                              todos.dateFormat
                                          )
                                        : null,
                                endDate:
                                    data.validation_feb.end_date !== null
                                        ? moment(data.validation_feb.end_date, "YYYY-MM-DD").format(todos.dateFormat)
                                        : null,
                                oldestAccountingDate:
                                    data.validation_feb.oldest_accounting_date !== undefined &&
                                    data.validation_feb.oldest_accounting_date !== null
                                        ? moment(data.validation_feb.oldest_accounting_date)
                                        : null,
                                unreconciledAccountingAmount:
                                    data.validation_feb.unreconciled_accounting_amount !== undefined
                                        ? data.validation_feb.unreconciled_accounting_amount
                                        : 0,
                                unreconciledAccountingItems:
                                    data.validation_feb.unreconciled_accounting_items !== undefined
                                        ? data.validation_feb.unreconciled_accounting_items
                                        : 0,
                                unreconciledAmount:
                                    data.validation_feb.unreconciled_amount !== undefined
                                        ? data.validation_feb.unreconciled_amount
                                        : 0,
                                oldestBankDate:
                                    data.validation_feb.oldest_bank_date !== undefined &&
                                    data.validation_feb.oldest_bank_date !== null
                                        ? moment(data.validation_feb.oldest_bank_date)
                                        : null,
                                unreconciledBankAmount:
                                    data.validation_feb.unreconciled_bank_amount !== undefined
                                        ? data.validation_feb.unreconciled_bank_amount
                                        : 0,
                                unreconciledBankItems:
                                    data.validation_feb.unreconciled_bank_items !== undefined
                                        ? data.validation_feb.unreconciled_bank_items
                                        : 0,
                                xeroAccountingBalance:
                                    data.validation_feb.xero_accounting_balance !== undefined
                                        ? data.validation_feb.xero_accounting_balance
                                        : 0,
                                xeroBankBalance:
                                    data.validation_feb.xero_bank_balance !== undefined
                                        ? data.validation_feb.xero_bank_balance
                                        : 0,
                                statementBalance:
                                    data.validation_feb.statement_balance !== undefined
                                        ? parseFloat(data.validation_feb.statement_balance).toLocaleString(
                                              todos.amountFormat,
                                              { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                                          )
                                        : t("bankingDashboard.withoutMovements"),
                            };
                        } else {
                            monthDetailsFeb = {
                                ...monthDetailsFeb,
                                isActive: false,
                                isClosed: false,
                                label: t("monthNames.february"),
                            };
                        }
                    }
                    if (index === 2) {
                        if (data.validation_mar !== undefined) {
                            monthDetailsMar = {
                                ...monthDetailsMar,
                                isActive: moment(selectedYear + "-03", "YYYY-MM").isSameOrBefore(
                                    moment(lastMonthClosed, "MM")
                                ),
                                isClosed: monthsSelected.mar
                                    ? monthsSelected.mar
                                    : "03" === lastMonthClosed || "03" === secondLastMonthClosed,
                                label: t("monthNames.march"),
                                initDateModal: data.validation_mar.statement_initial_date,
                                endDateModal: data.validation_mar.statement_end_date,
                                accountingStatus: data.validation_mar.accounting_status,
                                bankAccountingStatus: data.validation_mar.bank_accounting_status,
                                bankBalanceStatus:
                                    data.validation_mar.statement_balance !== undefined &&
                                    data.validation_mar.statement_balance !== null
                                        ? data.validation_mar.bank_balance_status
                                        : 3,
                                initialDate:
                                    data.validation_mar.initial_date !== null
                                        ? moment(data.validation_mar.initial_date, "YYYY-MM-DD").format(
                                              todos.dateFormat
                                          )
                                        : null,
                                endDate:
                                    data.validation_mar.end_date !== null
                                        ? moment(data.validation_mar.end_date, "YYYY-MM-DD").format(todos.dateFormat)
                                        : null,
                                oldestAccountingDate:
                                    data.validation_mar.oldest_accounting_date !== undefined &&
                                    data.validation_mar.oldest_accounting_date !== null
                                        ? moment(data.validation_mar.oldest_accounting_date)
                                        : null,
                                unreconciledAccountingAmount:
                                    data.validation_mar.unreconciled_accounting_amount !== undefined
                                        ? data.validation_mar.unreconciled_accounting_amount
                                        : 0,
                                unreconciledAccountingItems:
                                    data.validation_mar.unreconciled_accounting_items !== undefined
                                        ? data.validation_mar.unreconciled_accounting_items
                                        : 0,
                                unreconciledAmount:
                                    data.validation_mar.unreconciled_amount !== undefined
                                        ? data.validation_mar.unreconciled_amount
                                        : 0,
                                oldestBankDate:
                                    data.validation_mar.oldest_bank_date !== undefined &&
                                    data.validation_mar.oldest_bank_date !== null
                                        ? moment(data.validation_mar.oldest_bank_date)
                                        : null,
                                unreconciledBankAmount:
                                    data.validation_mar.unreconciled_bank_amount !== undefined
                                        ? data.validation_mar.unreconciled_bank_amount
                                        : 0,
                                unreconciledBankItems:
                                    data.validation_mar.unreconciled_bank_items !== undefined
                                        ? data.validation_mar.unreconciled_bank_items
                                        : 0,
                                xeroAccountingBalance:
                                    data.validation_mar.xero_accounting_balance !== undefined
                                        ? data.validation_mar.xero_accounting_balance
                                        : 0,
                                xeroBankBalance:
                                    data.validation_mar.xero_bank_balance !== undefined
                                        ? data.validation_mar.xero_bank_balance
                                        : 0,
                                statementBalance:
                                    data.validation_mar.statement_balance !== undefined
                                        ? parseFloat(data.validation_mar.statement_balance).toLocaleString(
                                              todos.amountFormat,
                                              { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                                          )
                                        : t("bankingDashboard.withoutMovements"),
                            };
                        } else {
                            monthDetailsMar = {
                                ...monthDetailsMar,
                                isActive: false,
                                isClosed: false,
                                label: t("monthNames.march"),
                            };
                        }
                    }
                    if (index === 3) {
                        if (data.validation_apr !== undefined) {
                            monthDetailsApr = {
                                ...monthDetailsApr,
                                isActive: moment(selectedYear + "-04", "YYYY-MM").isSameOrBefore(
                                    moment(lastMonthClosed, "MM")
                                ),
                                isClosed: monthsSelected.apr
                                    ? monthsSelected.apr
                                    : "04" === lastMonthClosed || "04" === secondLastMonthClosed,
                                label: t("monthNames.april"),
                                initDateModal: data.validation_apr.statement_initial_date,
                                endDateModal: data.validation_apr.statement_end_date,
                                accountingStatus: data.validation_apr.accounting_status,
                                bankAccountingStatus: data.validation_apr.bank_accounting_status,
                                bankBalanceStatus:
                                    data.validation_apr.statement_balance !== undefined &&
                                    data.validation_apr.statement_balance !== null
                                        ? data.validation_apr.bank_balance_status
                                        : 3,
                                initialDate:
                                    data.validation_apr.initial_date !== null
                                        ? moment(data.validation_apr.initial_date, "YYYY-MM-DD").format(
                                              todos.dateFormat
                                          )
                                        : null,
                                endDate:
                                    data.validation_apr.end_date !== null
                                        ? moment(data.validation_apr.end_date, "YYYY-MM-DD").format(todos.dateFormat)
                                        : null,
                                oldestAccountingDate:
                                    data.validation_apr.oldest_accounting_date !== undefined &&
                                    data.validation_apr.oldest_accounting_date !== null
                                        ? moment(data.validation_apr.oldest_accounting_date)
                                        : null,
                                unreconciledAccountingAmount:
                                    data.validation_apr.unreconciled_accounting_amount !== undefined
                                        ? data.validation_apr.unreconciled_accounting_amount
                                        : 0,
                                unreconciledAccountingItems:
                                    data.validation_apr.unreconciled_accounting_items !== undefined
                                        ? data.validation_apr.unreconciled_accounting_items
                                        : 0,
                                unreconciledAmount:
                                    data.validation_apr.unreconciled_amount !== undefined
                                        ? data.validation_apr.unreconciled_amount
                                        : 0,
                                oldestBankDate:
                                    data.validation_apr.oldest_bank_date !== undefined &&
                                    data.validation_apr.oldest_bank_date !== null
                                        ? moment(data.validation_apr.oldest_bank_date)
                                        : null,
                                unreconciledBankAmount:
                                    data.validation_apr.unreconciled_bank_amount !== undefined
                                        ? data.validation_apr.unreconciled_bank_amount
                                        : 0,
                                unreconciledBankItems:
                                    data.validation_apr.unreconciled_bank_items !== undefined
                                        ? data.validation_apr.unreconciled_bank_items
                                        : 0,
                                xeroAccountingBalance:
                                    data.validation_apr.xero_accounting_balance !== undefined
                                        ? data.validation_apr.xero_accounting_balance
                                        : 0,
                                xeroBankBalance:
                                    data.validation_apr.xero_bank_balance !== undefined
                                        ? data.validation_apr.xero_bank_balance
                                        : 0,
                                statementBalance:
                                    data.validation_apr.statement_balance !== undefined
                                        ? parseFloat(data.validation_apr.statement_balance).toLocaleString(
                                              todos.amountFormat,
                                              { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                                          )
                                        : t("bankingDashboard.withoutMovements"),
                            };
                        } else {
                            monthDetailsApr = {
                                ...monthDetailsApr,
                                isActive: false,
                                isClosed: false,
                                label: t("monthNames.april"),
                            };
                        }
                    }
                    if (index === 4) {
                        if (data.validation_may !== undefined) {
                            monthDetailsMay = {
                                ...monthDetailsMay,
                                isActive: moment(selectedYear + "-05", "YYYY-MM").isSameOrBefore(
                                    moment(lastMonthClosed, "MM")
                                ),
                                isClosed: monthsSelected.may
                                    ? monthsSelected.may
                                    : "05" === lastMonthClosed || "05" === secondLastMonthClosed,
                                label: t("monthNames.may"),
                                initDateModal: data.validation_may.statement_initial_date,
                                endDateModal: data.validation_may.statement_end_date,
                                accountingStatus: data.validation_may.accounting_status,
                                bankAccountingStatus: data.validation_may.bank_accounting_status,
                                bankBalanceStatus:
                                    data.validation_may.statement_balance !== undefined &&
                                    data.validation_may.statement_balance !== null
                                        ? data.validation_may.bank_balance_status
                                        : 3,
                                initialDate:
                                    data.validation_may.initial_date !== null
                                        ? moment(data.validation_may.initial_date, "YYYY-MM-DD").format(
                                              todos.dateFormat
                                          )
                                        : null,
                                endDate:
                                    data.validation_may.end_date !== null
                                        ? moment(data.validation_may.end_date, "YYYY-MM-DD").format(todos.dateFormat)
                                        : null,
                                oldestAccountingDate:
                                    data.validation_may.oldest_accounting_date !== undefined &&
                                    data.validation_may.oldest_accounting_date !== null
                                        ? moment(data.validation_may.oldest_accounting_date)
                                        : null,
                                unreconciledAccountingAmount:
                                    data.validation_may.unreconciled_accounting_amount !== undefined
                                        ? data.validation_may.unreconciled_accounting_amount
                                        : 0,
                                unreconciledAccountingItems:
                                    data.validation_may.unreconciled_accounting_items !== undefined
                                        ? data.validation_may.unreconciled_accounting_items
                                        : 0,
                                unreconciledAmount:
                                    data.validation_may.unreconciled_amount !== undefined
                                        ? data.validation_may.unreconciled_amount
                                        : 0,
                                oldestBankDate:
                                    data.validation_may.oldest_bank_date !== undefined &&
                                    data.validation_may.oldest_bank_date !== null
                                        ? moment(data.validation_may.oldest_bank_date)
                                        : null,
                                unreconciledBankAmount:
                                    data.validation_may.unreconciled_bank_amount !== undefined
                                        ? data.validation_may.unreconciled_bank_amount
                                        : 0,
                                unreconciledBankItems:
                                    data.validation_may.unreconciled_bank_items !== undefined
                                        ? data.validation_may.unreconciled_bank_items
                                        : 0,
                                xeroAccountingBalance:
                                    data.validation_may.xero_accounting_balance !== undefined
                                        ? data.validation_may.xero_accounting_balance
                                        : 0,
                                xeroBankBalance:
                                    data.validation_may.xero_bank_balance !== undefined
                                        ? data.validation_may.xero_bank_balance
                                        : 0,
                                statementBalance:
                                    data.validation_may.statement_balance !== undefined
                                        ? parseFloat(data.validation_may.statement_balance).toLocaleString(
                                              todos.amountFormat,
                                              { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                                          )
                                        : t("bankingDashboard.withoutMovements"),
                            };
                        } else {
                            monthDetailsMay = {
                                ...monthDetailsMay,
                                isActive: false,
                                isClosed: false,
                                label: t("monthNames.may"),
                            };
                        }
                    }
                    if (index === 5) {
                        if (data.validation_jun !== undefined) {
                            monthDetailsJun = {
                                ...monthDetailsJun,
                                isActive: moment(selectedYear + "-06", "YYYY-MM").isSameOrBefore(
                                    moment(lastMonthClosed, "MM")
                                ),
                                isClosed: monthsSelected.jun
                                    ? monthsSelected.jun
                                    : "06" === lastMonthClosed || "06" === secondLastMonthClosed,
                                label: t("monthNames.june"),
                                initDateModal: data.validation_jun.statement_initial_date,
                                endDateModal: data.validation_jun.statement_end_date,
                                accountingStatus: data.validation_jun.accounting_status,
                                bankAccountingStatus: data.validation_jun.bank_accounting_status,
                                bankBalanceStatus:
                                    data.validation_jun.statement_balance !== undefined &&
                                    data.validation_jun.statement_balance !== null
                                        ? data.validation_jun.bank_balance_status
                                        : 3,
                                initialDate:
                                    data.validation_jun.initial_date !== null
                                        ? moment(data.validation_jun.initial_date, "YYYY-MM-DD").format(
                                              todos.dateFormat
                                          )
                                        : null,
                                endDate:
                                    data.validation_jun.end_date !== null
                                        ? moment(data.validation_jun.end_date, "YYYY-MM-DD").format(todos.dateFormat)
                                        : null,
                                oldestAccountingDate:
                                    data.validation_jun.oldest_accounting_date !== undefined &&
                                    data.validation_jun.oldest_accounting_date !== null
                                        ? moment(data.validation_jun.oldest_accounting_date)
                                        : null,
                                unreconciledAccountingAmount:
                                    data.validation_jun.unreconciled_accounting_amount !== undefined
                                        ? data.validation_jun.unreconciled_accounting_amount
                                        : 0,
                                unreconciledAccountingItems:
                                    data.validation_jun.unreconciled_accounting_items !== undefined
                                        ? data.validation_jun.unreconciled_accounting_items
                                        : 0,
                                unreconciledAmount:
                                    data.validation_jun.unreconciled_amount !== undefined
                                        ? data.validation_jun.unreconciled_amount
                                        : 0,
                                oldestBankDate:
                                    data.validation_jun.oldest_bank_date !== undefined &&
                                    data.validation_jun.oldest_bank_date !== null
                                        ? moment(data.validation_jun.oldest_bank_date)
                                        : null,
                                unreconciledBankAmount:
                                    data.validation_jun.unreconciled_bank_amount !== undefined
                                        ? data.validation_jun.unreconciled_bank_amount
                                        : 0,
                                unreconciledBankItems:
                                    data.validation_jun.unreconciled_bank_items !== undefined
                                        ? data.validation_jun.unreconciled_bank_items
                                        : 0,
                                xeroAccountingBalance:
                                    data.validation_jun.xero_accounting_balance !== undefined
                                        ? data.validation_jun.xero_accounting_balance
                                        : 0,
                                xeroBankBalance:
                                    data.validation_jun.xero_bank_balance !== undefined
                                        ? data.validation_jun.xero_bank_balance
                                        : 0,
                                statementBalance:
                                    data.validation_jun.statement_balance !== undefined
                                        ? parseFloat(data.validation_jun.statement_balance).toLocaleString(
                                              todos.amountFormat,
                                              { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                                          )
                                        : t("bankingDashboard.withoutMovements"),
                            };
                        } else {
                            monthDetailsJun = {
                                ...monthDetailsJun,
                                isActive: false,
                                isClosed: false,
                                label: t("monthNames.june"),
                            };
                        }
                    }
                    if (index === 6) {
                        if (data.validation_jul !== undefined) {
                            monthDetailsJul = {
                                ...monthDetailsJul,
                                isActive: moment(selectedYear + "-07", "YYYY-MM").isSameOrBefore(
                                    moment(lastMonthClosed, "MM")
                                ),
                                isClosed: monthsSelected.jul
                                    ? monthsSelected.jul
                                    : "07" === lastMonthClosed || "07" === secondLastMonthClosed,
                                label: t("monthNames.july"),
                                initDateModal: data.validation_jul.statement_initial_date,
                                endDateModal: data.validation_jul.statement_end_date,
                                accountingStatus: data.validation_jul.accounting_status,
                                bankAccountingStatus: data.validation_jul.bank_accounting_status,
                                bankBalanceStatus:
                                    data.validation_jul.statement_balance !== undefined &&
                                    data.validation_jul.statement_balance !== null
                                        ? data.validation_jul.bank_balance_status
                                        : 3,
                                initialDate:
                                    data.validation_jul.initial_date !== null
                                        ? moment(data.validation_jul.initial_date, "YYYY-MM-DD").format(
                                              todos.dateFormat
                                          )
                                        : null,
                                endDate:
                                    data.validation_jul.end_date !== null
                                        ? moment(data.validation_jul.end_date, "YYYY-MM-DD").format(todos.dateFormat)
                                        : null,
                                oldestAccountingDate:
                                    data.validation_jul.oldest_accounting_date !== undefined &&
                                    data.validation_jul.oldest_accounting_date !== null
                                        ? moment(data.validation_jul.oldest_accounting_date)
                                        : null,
                                unreconciledAccountingAmount:
                                    data.validation_jul.unreconciled_accounting_amount !== undefined
                                        ? data.validation_jul.unreconciled_accounting_amount
                                        : 0,
                                unreconciledAccountingItems:
                                    data.validation_jul.unreconciled_accounting_items !== undefined
                                        ? data.validation_jul.unreconciled_accounting_items
                                        : 0,
                                unreconciledAmount:
                                    data.validation_jul.unreconciled_amount !== undefined
                                        ? data.validation_jul.unreconciled_amount
                                        : 0,
                                oldestBankDate:
                                    data.validation_jul.oldest_bank_date !== undefined &&
                                    data.validation_jul.oldest_bank_date !== null
                                        ? moment(data.validation_jul.oldest_bank_date)
                                        : null,
                                unreconciledBankAmount:
                                    data.validation_jul.unreconciled_bank_amount !== undefined
                                        ? data.validation_jul.unreconciled_bank_amount
                                        : 0,
                                unreconciledBankItems:
                                    data.validation_jul.unreconciled_bank_items !== undefined
                                        ? data.validation_jul.unreconciled_bank_items
                                        : 0,
                                xeroAccountingBalance:
                                    data.validation_jul.xero_accounting_balance !== undefined
                                        ? data.validation_jul.xero_accounting_balance
                                        : 0,
                                xeroBankBalance:
                                    data.validation_jul.xero_bank_balance !== undefined
                                        ? data.validation_jul.xero_bank_balance
                                        : 0,
                                statementBalance:
                                    data.validation_jul.statement_balance !== undefined
                                        ? parseFloat(data.validation_jul.statement_balance).toLocaleString(
                                              todos.amountFormat,
                                              { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                                          )
                                        : t("bankingDashboard.withoutMovements"),
                            };
                        } else {
                            monthDetailsJul = {
                                ...monthDetailsJul,
                                isActive: false,
                                isClosed: false,
                                label: t("monthNames.july"),
                            };
                        }
                    }
                    if (index === 7) {
                        if (data.validation_aug !== undefined) {
                            monthDetailsAug = {
                                ...monthDetailsAug,
                                isActive: moment(selectedYear + "-08", "YYYY-MM").isSameOrBefore(
                                    moment(lastMonthClosed, "MM")
                                ),
                                isClosed: monthsSelected.aug
                                    ? monthsSelected.aug
                                    : "08" === lastMonthClosed || "08" === secondLastMonthClosed,
                                label: t("monthNames.august"),
                                initDateModal: data.validation_aug.statement_initial_date,
                                endDateModal: data.validation_aug.statement_end_date,
                                accountingStatus: data.validation_aug.accounting_status,
                                bankAccountingStatus: data.validation_aug.bank_accounting_status,
                                bankBalanceStatus:
                                    data.validation_aug.statement_balance !== undefined &&
                                    data.validation_aug.statement_balance !== null
                                        ? data.validation_aug.bank_balance_status
                                        : 3,
                                initialDate:
                                    data.validation_aug.initial_date !== null
                                        ? moment(data.validation_aug.initial_date, "YYYY-MM-DD").format(
                                              todos.dateFormat
                                          )
                                        : null,
                                endDate:
                                    data.validation_aug.end_date !== null
                                        ? moment(data.validation_aug.end_date, "YYYY-MM-DD").format(todos.dateFormat)
                                        : null,
                                oldestAccountingDate:
                                    data.validation_aug.oldest_accounting_date !== undefined &&
                                    data.validation_aug.oldest_accounting_date !== null
                                        ? moment(data.validation_aug.oldest_accounting_date)
                                        : null,
                                unreconciledAccountingAmount:
                                    data.validation_aug.unreconciled_accounting_amount !== undefined
                                        ? data.validation_aug.unreconciled_accounting_amount
                                        : 0,
                                unreconciledAccountingItems:
                                    data.validation_aug.unreconciled_accounting_items !== undefined
                                        ? data.validation_aug.unreconciled_accounting_items
                                        : 0,
                                unreconciledAmount:
                                    data.validation_aug.unreconciled_amount !== undefined
                                        ? data.validation_aug.unreconciled_amount
                                        : 0,
                                oldestBankDate:
                                    data.validation_aug.oldest_bank_date !== undefined &&
                                    data.validation_aug.oldest_bank_date !== null
                                        ? moment(data.validation_aug.oldest_bank_date)
                                        : null,
                                unreconciledBankAmount:
                                    data.validation_aug.unreconciled_bank_amount !== undefined
                                        ? data.validation_aug.unreconciled_bank_amount
                                        : 0,
                                unreconciledBankItems:
                                    data.validation_aug.unreconciled_bank_items !== undefined
                                        ? data.validation_aug.unreconciled_bank_items
                                        : 0,
                                xeroAccountingBalance:
                                    data.validation_aug.xero_accounting_balance !== undefined
                                        ? data.validation_aug.xero_accounting_balance
                                        : 0,
                                xeroBankBalance:
                                    data.validation_aug.xero_bank_balance !== undefined
                                        ? data.validation_aug.xero_bank_balance
                                        : 0,
                                statementBalance:
                                    data.validation_aug.statement_balance !== undefined
                                        ? parseFloat(data.validation_aug.statement_balance).toLocaleString(
                                              todos.amountFormat,
                                              { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                                          )
                                        : t("bankingDashboard.withoutMovements"),
                            };
                        } else {
                            monthDetailsAug = {
                                ...monthDetailsAug,
                                isActive: false,
                                isClosed: false,
                                label: t("monthNames.august"),
                            };
                        }
                    }
                    if (index === 8) {
                        if (data.validation_sep !== undefined) {
                            monthDetailsSep = {
                                ...monthDetailsSep,
                                isActive: moment(selectedYear + "-09", "YYYY-MM").isSameOrBefore(
                                    moment(lastMonthClosed, "MM")
                                ),
                                isClosed: monthsSelected.sep
                                    ? monthsSelected.sep
                                    : "09" === lastMonthClosed || "09" === secondLastMonthClosed,
                                label: t("monthNames.september"),
                                initDateModal: data.validation_sep.statement_initial_date,
                                endDateModal: data.validation_sep.statement_end_date,
                                accountingStatus: data.validation_sep.accounting_status,
                                bankAccountingStatus: data.validation_sep.bank_accounting_status,
                                bankBalanceStatus:
                                    data.validation_sep.statement_balance !== undefined &&
                                    data.validation_sep.statement_balance !== null
                                        ? data.validation_sep.bank_balance_status
                                        : 3,
                                initialDate:
                                    data.validation_sep.initial_date !== null
                                        ? moment(data.validation_sep.initial_date, "YYYY-MM-DD").format(
                                              todos.dateFormat
                                          )
                                        : null,
                                endDate:
                                    data.validation_sep.end_date !== null
                                        ? moment(data.validation_sep.end_date, "YYYY-MM-DD").format(todos.dateFormat)
                                        : null,
                                oldestAccountingDate:
                                    data.validation_sep.oldest_accounting_date !== undefined &&
                                    data.validation_sep.oldest_accounting_date !== null
                                        ? moment(data.validation_sep.oldest_accounting_date)
                                        : null,
                                unreconciledAccountingAmount:
                                    data.validation_sep.unreconciled_accounting_amount !== undefined
                                        ? data.validation_sep.unreconciled_accounting_amount
                                        : 0,
                                unreconciledAccountingItems:
                                    data.validation_sep.unreconciled_accounting_items !== undefined
                                        ? data.validation_sep.unreconciled_accounting_items
                                        : 0,
                                unreconciledAmount:
                                    data.validation_sep.unreconciled_amount !== undefined
                                        ? data.validation_sep.unreconciled_amount
                                        : 0,
                                oldestBankDate:
                                    data.validation_sep.oldest_bank_date !== undefined &&
                                    data.validation_sep.oldest_bank_date !== null
                                        ? moment(data.validation_sep.oldest_bank_date)
                                        : null,
                                unreconciledBankAmount:
                                    data.validation_sep.unreconciled_bank_amount !== undefined
                                        ? data.validation_sep.unreconciled_bank_amount
                                        : 0,
                                unreconciledBankItems:
                                    data.validation_sep.unreconciled_bank_items !== undefined
                                        ? data.validation_sep.unreconciled_bank_items
                                        : 0,
                                xeroAccountingBalance:
                                    data.validation_sep.xero_accounting_balance !== undefined
                                        ? data.validation_sep.xero_accounting_balance
                                        : 0,
                                xeroBankBalance:
                                    data.validation_sep.xero_bank_balance !== undefined
                                        ? data.validation_sep.xero_bank_balance
                                        : 0,
                                statementBalance:
                                    data.validation_sep.statement_balance !== undefined
                                        ? parseFloat(data.validation_sep.statement_balance).toLocaleString(
                                              todos.amountFormat,
                                              { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                                          )
                                        : t("bankingDashboard.withoutMovements"),
                            };
                        } else {
                            monthDetailsSep = {
                                ...monthDetailsSep,
                                isActive: false,
                                isClosed: false,
                                label: t("monthNames.september"),
                            };
                        }
                    }
                    if (index === 9) {
                        if (data.validation_oct !== undefined) {
                            monthDetailsOct = {
                                ...monthDetailsOct,
                                isActive: moment(selectedYear + "-10", "YYYY-MM").isSameOrBefore(
                                    moment(lastMonthClosed, "MM")
                                ),
                                isClosed: monthsSelected.oct
                                    ? monthsSelected.oct
                                    : "10" === lastMonthClosed || "10" === secondLastMonthClosed,
                                label: t("monthNames.october"),
                                initDateModal: data.validation_oct.statement_initial_date,
                                endDateModal: data.validation_oct.statement_end_date,
                                accountingStatus: data.validation_oct.accounting_status,
                                bankAccountingStatus: data.validation_oct.bank_accounting_status,
                                bankBalanceStatus:
                                    data.validation_oct.statement_balance !== undefined &&
                                    data.validation_oct.statement_balance !== null
                                        ? data.validation_oct.bank_balance_status
                                        : 3,
                                initialDate:
                                    data.validation_oct.initial_date !== null
                                        ? moment(data.validation_oct.initial_date, "YYYY-MM-DD").format(
                                              todos.dateFormat
                                          )
                                        : null,
                                endDate:
                                    data.validation_oct.end_date !== null
                                        ? moment(data.validation_oct.end_date, "YYYY-MM-DD").format(todos.dateFormat)
                                        : null,
                                oldestAccountingDate:
                                    data.validation_oct.oldest_accounting_date !== undefined &&
                                    data.validation_oct.oldest_accounting_date !== null
                                        ? moment(data.validation_oct.oldest_accounting_date)
                                        : null,
                                unreconciledAccountingAmount:
                                    data.validation_oct.unreconciled_accounting_amount !== undefined
                                        ? data.validation_oct.unreconciled_accounting_amount
                                        : 0,
                                unreconciledAccountingItems:
                                    data.validation_oct.unreconciled_accounting_items !== undefined
                                        ? data.validation_oct.unreconciled_accounting_items
                                        : 0,
                                unreconciledAmount:
                                    data.validation_oct.unreconciled_amount !== undefined
                                        ? data.validation_oct.unreconciled_amount
                                        : 0,
                                oldestBankDate:
                                    data.validation_oct.oldest_bank_date !== undefined &&
                                    data.validation_oct.oldest_bank_date !== null
                                        ? moment(data.validation_oct.oldest_bank_date)
                                        : null,
                                unreconciledBankAmount:
                                    data.validation_oct.unreconciled_bank_amount !== undefined
                                        ? data.validation_oct.unreconciled_bank_amount
                                        : 0,
                                unreconciledBankItems:
                                    data.validation_oct.unreconciled_bank_items !== undefined
                                        ? data.validation_oct.unreconciled_bank_items
                                        : 0,
                                xeroAccountingBalance:
                                    data.validation_oct.xero_accounting_balance !== undefined
                                        ? data.validation_oct.xero_accounting_balance
                                        : 0,
                                xeroBankBalance:
                                    data.validation_oct.xero_bank_balance !== undefined
                                        ? data.validation_oct.xero_bank_balance
                                        : 0,
                                statementBalance:
                                    data.validation_oct.statement_balance !== undefined
                                        ? parseFloat(data.validation_oct.statement_balance).toLocaleString(
                                              todos.amountFormat,
                                              { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                                          )
                                        : t("bankingDashboard.withoutMovements"),
                            };
                        } else {
                            monthDetailsOct = {
                                ...monthDetailsOct,
                                isActive: false,
                                isClosed: false,
                                label: t("monthNames.october"),
                            };
                        }
                    }
                    if (index === 10) {
                        if (data.validation_nov !== undefined) {
                            monthDetailsNov = {
                                ...monthDetailsNov,
                                isActive: moment(selectedYear + "-11", "YYYY-MM").isSameOrBefore(
                                    moment(lastMonthClosed, "MM")
                                ),
                                isClosed: monthsSelected.nov
                                    ? monthsSelected.nov
                                    : "11" === lastMonthClosed || "11" === secondLastMonthClosed,
                                label: t("monthNames.november"),
                                initDateModal: data.validation_nov.statement_initial_date,
                                endDateModal: data.validation_nov.statement_end_date,
                                accountingStatus: data.validation_nov.accounting_status,
                                bankAccountingStatus: data.validation_nov.bank_accounting_status,
                                bankBalanceStatus:
                                    data.validation_nov.statement_balance !== undefined &&
                                    data.validation_nov.statement_balance !== null
                                        ? data.validation_nov.bank_balance_status
                                        : 3,
                                initialDate:
                                    data.validation_nov.initial_date !== null
                                        ? moment(data.validation_nov.initial_date, "YYYY-MM-DD").format(
                                              todos.dateFormat
                                          )
                                        : null,
                                endDate:
                                    data.validation_nov.end_date !== null
                                        ? moment(data.validation_nov.end_date, "YYYY-MM-DD").format(todos.dateFormat)
                                        : null,
                                oldestAccountingDate:
                                    data.validation_nov.oldest_accounting_date !== undefined &&
                                    data.validation_nov.oldest_accounting_date !== null
                                        ? moment(data.validation_nov.oldest_accounting_date)
                                        : null,
                                unreconciledAccountingAmount:
                                    data.validation_nov.unreconciled_accounting_amount !== undefined
                                        ? data.validation_nov.unreconciled_accounting_amount
                                        : 0,
                                unreconciledAccountingItems:
                                    data.validation_nov.unreconciled_accounting_items !== undefined
                                        ? data.validation_nov.unreconciled_accounting_items
                                        : 0,
                                unreconciledAmount:
                                    data.validation_nov.unreconciled_amount !== undefined
                                        ? data.validation_nov.unreconciled_amount
                                        : 0,
                                oldestBankDate:
                                    data.validation_nov.oldest_bank_date !== undefined &&
                                    data.validation_nov.oldest_bank_date !== null
                                        ? moment(data.validation_nov.oldest_bank_date)
                                        : null,
                                unreconciledBankAmount:
                                    data.validation_nov.unreconciled_bank_amount !== undefined
                                        ? data.validation_nov.unreconciled_bank_amount
                                        : 0,
                                unreconciledBankItems:
                                    data.validation_nov.unreconciled_bank_items !== undefined
                                        ? data.validation_nov.unreconciled_bank_items
                                        : 0,
                                xeroAccountingBalance:
                                    data.validation_nov.xero_accounting_balance !== undefined
                                        ? data.validation_nov.xero_accounting_balance
                                        : 0,
                                xeroBankBalance:
                                    data.validation_nov.xero_bank_balance !== undefined
                                        ? data.validation_nov.xero_bank_balance
                                        : 0,
                                statementBalance:
                                    data.validation_nov.statement_balance !== undefined
                                        ? parseFloat(data.validation_nov.statement_balance).toLocaleString(
                                              todos.amountFormat,
                                              { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                                          )
                                        : t("bankingDashboard.withoutMovements"),
                            };
                        } else {
                            monthDetailsNov = {
                                ...monthDetailsNov,
                                isActive: false,
                                isClosed: false,
                                label: t("monthNames.november"),
                            };
                        }
                    }
                    if (index === 11) {
                        if (data.validation_dec !== undefined) {
                            monthDetailsDec = {
                                ...monthDetailsDec,
                                isActive: moment(selectedYear + "-12", "YYYY-MM").isSameOrBefore(
                                    moment(lastMonthClosed, "MM")
                                ),
                                isClosed: monthsSelected.dec
                                    ? monthsSelected.dec
                                    : "12" === lastMonthClosed || "12" === secondLastMonthClosed,
                                label: t("monthNames.december"),
                                initDateModal: data.validation_dec.statement_initial_date,
                                endDateModal: data.validation_dec.statement_end_date,
                                accountingStatus: data.validation_dec.accounting_status,
                                bankAccountingStatus: data.validation_dec.bank_accounting_status,
                                bankBalanceStatus:
                                    data.validation_dec.statement_balance !== undefined &&
                                    data.validation_dec.statement_balance !== null
                                        ? data.validation_dec.bank_balance_status
                                        : 3,
                                initialDate:
                                    data.validation_dec.initial_date !== null
                                        ? moment(data.validation_dec.initial_date, "YYYY-MM-DD").format(
                                              todos.dateFormat
                                          )
                                        : null,
                                endDate:
                                    data.validation_dec.end_date !== null
                                        ? moment(data.validation_dec.end_date, "YYYY-MM-DD").format(todos.dateFormat)
                                        : null,
                                oldestAccountingDate:
                                    data.validation_dec.oldest_accounting_date !== undefined &&
                                    data.validation_dec.oldest_accounting_date !== null
                                        ? moment(data.validation_dec.oldest_accounting_date)
                                        : null,
                                unreconciledAccountingAmount:
                                    data.validation_dec.unreconciled_accounting_amount !== undefined
                                        ? data.validation_dec.unreconciled_accounting_amount
                                        : 0,
                                unreconciledAccountingItems:
                                    data.validation_dec.unreconciled_accounting_items !== undefined
                                        ? data.validation_dec.unreconciled_accounting_items
                                        : 0,
                                unreconciledAmount:
                                    data.validation_dec.unreconciled_amount !== undefined
                                        ? data.validation_dec.unreconciled_amount
                                        : 0,
                                oldestBankDate:
                                    data.validation_dec.oldest_bank_date !== undefined &&
                                    data.validation_dec.oldest_bank_date !== null
                                        ? moment(data.validation_dec.oldest_bank_date)
                                        : null,
                                unreconciledBankAmount:
                                    data.validation_dec.unreconciled_bank_amount !== undefined
                                        ? data.validation_dec.unreconciled_bank_amount
                                        : 0,
                                unreconciledBankItems:
                                    data.validation_dec.unreconciled_bank_items !== undefined
                                        ? data.validation_dec.unreconciled_bank_items
                                        : 0,
                                xeroAccountingBalance:
                                    data.validation_dec.xero_accounting_balance !== undefined
                                        ? data.validation_dec.xero_accounting_balance
                                        : 0,
                                xeroBankBalance:
                                    data.validation_dec.xero_bank_balance !== undefined
                                        ? data.validation_dec.xero_bank_balance
                                        : 0,
                                statementBalance:
                                    data.validation_dec.statement_balance !== undefined
                                        ? parseFloat(data.validation_dec.statement_balance).toLocaleString(
                                              todos.amountFormat,
                                              { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                                          )
                                        : t("bankingDashboard.withoutMovements"),
                            };
                        } else {
                            monthDetailsDec = {
                                ...monthDetailsDec,
                                isActive: false,
                                isClosed: false,
                                label: t("monthNames.december"),
                            };
                        }
                    }
                });
            });

        setValuesValidations({
            jan: monthDetailsJan,
            feb: monthDetailsFeb,
            mar: monthDetailsMar,
            apr: monthDetailsApr,
            may: monthDetailsMay,
            jun: monthDetailsJun,
            jul: monthDetailsJul,
            aug: monthDetailsAug,
            sep: monthDetailsSep,
            oct: monthDetailsOct,
            nov: monthDetailsNov,
            dec: monthDetailsDec,
        });

        showLoading(false);
    };

    const callLoads = () => {
        loadInitData();
        props.loadInitDataTable();
    };

    /** Component events */
    useEffect(() => {
        monthsSelected = {
            jan: "",
            feb: "",
            mar: "",
            apr: "",
            may: "",
            jun: "",
            jul: "",
            aug: "",
            sep: "",
            oct: "",
            nov: "",
            dec: "",
        };

        loadInitData();

        // La interfaz completa se recarga cada 30 segundos
        // const intervalId = setInterval(callLoads, 30000);
        // return () => clearInterval(intervalId);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t, todos.dateFormat, todos.amountFormat, props.accountIdPlatformSelected, selectedYear]);

    const formatBankInfo = (bankInfo) => {
        let bankName = bankInfo.split(" - ");
        switch (bankName[0]) {
            case "Estados Unidos":
                bankName[0] = t("countries.EstadosUnidos");
                break;

            case "Panamá":
                bankName[0] = t("countries.Panamá");
                break;

            case "Europa":
                bankName[0] = t("countries.Europa");
                break;

            case "España":
                bankName[0] = t("countries.España");
                break;

            case "Suiza":
                bankName[0] = t("countries.Suiza");
                break;

            case "Emiratos Árabes Unidos":
                bankName[0] = t("countries.EmiratosÁrabesUnidos");
                break;

            case "República Dominicana":
                bankName[0] = t("countries.RepúblicaDominicana");
                break;

            case "Francia":
                bankName[0] = t("countries.Francia");
                break;

            case "Rusia":
                bankName[0] = t("countries.Rusia");
                break;

            default:
                break;
        }

        return bankName.join(" - ");
    };

    useEffect(() => {
        (async () => {
            calls.getBanks().then((result) => {
                const banksTranslate = result.map((bankInfo) => formatBankInfo(bankInfo));
                setBanks(banksTranslate);
            });
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleOpenDialogForm = async (id_account) => {
        setBackDrop(true);

        await fetch(`/getAccountDetails?account_id=${id_account}`, {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then((res) => res.json())
            .then((data) => {
                let requirements = [];

                if (data.requirement_bank_statements) {
                    requirements.push(t("clientsTable.accStatements"));
                }
                if (data.requirement_checks) {
                    requirements.push(t("services.checks"));
                }
                if (data.requirement_petty_cash) {
                    requirements.push(t("services.smallReport"));
                }

                setValuesBank({
                    idAccount: id_account,
                    accountName: data.account_name,
                    orgName: data.company_name,
                    monitoringFreq: data.frequency !== undefined && data.frequency !== null ? data.frequency : "",
                    receptionDays:
                        data.reception_days !== undefined && data.reception_days !== null ? data.reception_days : 1,
                    mode: data.mode !== undefined && data.mode !== null ? data.mode : "",
                    bankName: data.bank !== undefined && data.bank !== null ? formatBankInfo(data.bank) : "",
                    checkedNA: data.cash !== undefined ? data.cash : false,
                    manageReq: data.manage_requirements !== undefined ? data.manage_requirements : false,
                    software: data.software_provider,
                    requirements: requirements,
                    checksInc: data.included_checks,
                    initDate: data.initial_date ? moment(data.initial_date, "YYYY-MM-DD") : null,
                    endDate: data.end_date ? moment(data.end_date, "YYYY-MM-DD") : null,
                });
                setDialogForm(true);
            });

        setBackDrop(false);
    };

    const closeDialog = () => {
        setDialogForm(false);
        setValuesBank({
            idAccount: "",
            accountName: "",
            orgName: "",
            monitoringFreq: "",
            receptionDays: "",
            mode: "",
            bankName: "",
            checkedNA: false,
            manageReq: false,
            software: "",
            initDate: null,
            endDate: null,
            checksInc: false,
            requirements: [],
        });
        setIsError(false);
    };

    const updateBankAccount = async () => {
        setBackDrop(true);

        if (
            !valuesBank.checkedNA &&
            (!valuesBank.monitoringFreq ||
                !valuesBank.receptionDays ||
                valuesBank.mode === "" ||
                !valuesBank.bankName ||
                !valuesBank.initDate ||
                valuesBank.requirements.length === 0)
        ) {
            setTexto(t("miscellaneous.requiredData"));
            setAlertType("warning");
            setShow(true);
            setBackDrop(false);
            setIsError(true);
            return;
        } else if (valuesBank.checkedNA && !valuesBank.endDate){
            setTexto(t("miscellaneous.requiredData"));
            setAlertType("warning");
            setShow(true);
            setBackDrop(false);
            setIsError(true);
            return;
        }

        let params = {
            account_id: valuesBank.idAccount,
            frequency: parseFloat(valuesBank.monitoringFreq),
            reception_days: parseFloat(valuesBank.receptionDays),
            mode_number: parseInt(valuesBank.mode),
            bank_name: valuesBank.bankName,
            cash: valuesBank.checkedNA,
            manage_requirements: valuesBank.manageReq,
            requirement_bank_statements: valuesBank.requirements.includes(t("clientsTable.accStatements")),
            requirement_checks: valuesBank.requirements.includes(t("services.checks")),
            requirement_petty_cash: valuesBank.requirements.includes(t("services.smallReport")),
            included_checks: valuesBank.checksInc,
            initial_date: valuesBank.initDate.format("YYYY-MM-DD"),
            end_date: valuesBank.endDate ? valuesBank.endDate.format("YYYY-MM-DD") : null,
        };

        await fetch(`/updateBankAccounts`, {
            method: "POST",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
            body: JSON.stringify(params),
        })
            .then((response) => {
                if (response.ok) {
                    setTexto(t("miscellaneous.successfulUpdate"));
                    setAlertType("success");
                    setShow(true);
                    loadInitData();
                    closeDialog();
                } else {
                    setTexto(t("miscellaneous.updatingError"));
                    setAlertType("error");
                    setShow(true);
                }
            })
            .catch((error) => {
                console.log(error);
            });

        setBackDrop(false);
    };

    const handleChangeReceptionDays = (event) => {
        if (event.target.value >= 1 && event.target.value <= 15) {
            setValuesBank({
                ...valuesBank,
                [event.target.name]: parseInt(event.target.value),
            });
        } else {
            setValuesBank({
                ...valuesBank,
                [event.target.name]: "",
            });
            setTexto(t("services.receptionDaysMsg"));
            setAlertType("warning");
            setShow(true);
        }
    };

    const handleChangeCheckedNA = (event) => {
        setValuesBank({
            ...valuesBank,
            checkedNA: event.target.checked,
            endDate: null,
        });
        setIsError(false);
    };

    return (
        <StyledContainer>
            <Card
                elevation={0}
                sx={{
                    mt: 6,
                    borderRadius: 8,
                    boxShadow: "10px 10px 15px rgba(3,24,81,0.15)",
                }}
            >
                <CardHeader
                    title={
                        loading ? (
                            <Skeleton variant="text" animation="wave" width="20%" />
                        ) : (
                            <Typography
                                variant="h1"
                                color="primary"
                                sx={{ fontSize: { xs: 14, md: 20 } }}
                            >{`${companyName} - ${props.accountNameSelected}`}</Typography>
                        )
                    }
                    subheader={
                        loading ? (
                            <Skeleton variant="text" animation="wave" width="25%" />
                        ) : (
                            <Typography
                                variant="body1"
                                color="textSecondary"
                                sx={{ fontSize: { xs: 12, md: 18 }, pt: 1 }}
                            >
                                {`${accountNumber} - ${mode}`}
                            </Typography>
                        )
                    }
                    action={
                        loading ? (
                            <Box sx={{ textAlign: "right" }}>
                                <Skeleton
                                    variant="circular"
                                    width={35}
                                    height={35}
                                    animation="wave"
                                    sx={{ display: "inline-block", ml: "auto", mr: 1 }}
                                />
                                <Skeleton
                                    variant="circular"
                                    width={35}
                                    height={35}
                                    animation="wave"
                                    sx={{ display: "inline-block", ml: "auto", mr: "auto" }}
                                />
                                <Skeleton variant="text" width={200} animation="wave" />
                            </Box>
                        ) : (
                            <Grid container sx={{ textAlign: { xs: "center", md: "right" }, marginTop: "-15px" }}>
                                <Grid item xs={6} md={12}>
                                    <LightTooltip title={t("bank.bankConfig")} aria-label="view-history">
                                        <IconButton
                                            onClick={() => handleOpenDialogForm(props.accountIdPlatformSelected)}
                                            sx={{ color: "#2f3190" }}
                                        >
                                            <SettingsIcon fontSize="inherit" />
                                        </IconButton>
                                    </LightTooltip>
                                    <LightTooltip title={t("bank.convHistory")} aria-label="view-history">
                                        <IconButton
                                            onClick={() => handleOpenDialog(props.accountIdXeroSelected)}
                                            sx={{ color: "#2f3190" }}
                                        >
                                            <InventoryIcon fontSize="inherit" />
                                        </IconButton>
                                    </LightTooltip>
                                    <LightTooltip title={t("bankingDashboard.update")} aria-label="update">
                                        <span onClick={showSnack}>
                                            <IconButton
                                                disabled={updating}
                                                onClick={udpateBankMovements}
                                                sx={{ color: "#2f3190" }}
                                            >
                                                <SyncIcon fontSize="inherit" />
                                            </IconButton>
                                        </span>
                                    </LightTooltip>
                                </Grid>
                                <Grid item xs={6} md={12}></Grid>
                                <Grid item xs={6} md={12}>
                                    <Typography variant="h3" sx={{ pt: 1, pr: 1 }}>
                                        {`${t("services.lastUpdate")}: ${
                                            updatedDate ? moment(updatedDate).format(todos.dateFormat + " HH:mm") : ""
                                        }`}
                                    </Typography>
                                </Grid>
                            </Grid>
                        )
                    }
                    sx={{
                        m: 2,
                        mb: 0,
                        borderBottom: "2px solid #FF5968",
                        "& > .MuiCardHeader-action": {
                            display: "block",
                            mt: "auto",
                            mb: "auto",
                        },
                    }}
                />
                <CardContent sx={{ p: "48px 48px 68px !important" }}>
                    <Container disableGutters maxWidth="xl">
                        <Grid container justifyContent="center" alignItems="center" spacing={4}>
                            <Grid item>
                                {loading ? (
                                    <Skeleton
                                        variant="rectangular"
                                        width={168}
                                        height={80}
                                        animation="wave"
                                        sx={{ borderRadius: 4 }}
                                    />
                                ) : (
                                    <Card
                                        elevation={0}
                                        ref={yearCardRef}
                                        sx={{
                                            borderRadius: 4,
                                            backgroundColor: "rgba(47,49,144,0.1)",
                                            boxShadow: "10px 10px 15px rgba(3,24,81,0.15)",
                                        }}
                                    >
                                        <CardActionArea onClick={handleClickYearPopover}>
                                            <CardContent
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    height: 80,
                                                    p: "10px 32px",
                                                }}
                                            >
                                                <ListItem
                                                    alignItems="center"
                                                    secondaryAction={<InsertInvitationIcon />}
                                                    sx={{ ml: "auto", mr: "auto" }}
                                                >
                                                    <ListItemText
                                                        primary={selectedYear}
                                                        primaryTypographyProps={{ fontSize: 16, fontWeight: 600 }}
                                                    />
                                                </ListItem>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                )}
                                <Popover
                                    id={id}
                                    open={openPopoverYear}
                                    anchorEl={yearAnchorEl}
                                    onClose={handleCloseYearPopover}
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "center",
                                    }}
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "center",
                                    }}
                                    PaperProps={{
                                        sx: {
                                            width: 169,
                                            p: "8px 0px",
                                            mt: 1,
                                            borderRadius: 5,
                                            boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                            backgroundColor: "#F8F9F8",
                                        },
                                    }}
                                >
                                    {years.map((year) => (
                                        <ListItemButton
                                            onClick={() => {
                                                handleCloseYearPopover();
                                                setSelectedYear(year);
                                            }}
                                            sx={{ pl: 4, pr: 4 }}
                                        >
                                            <ListItemText primary={year} />
                                        </ListItemButton>
                                    ))}
                                </Popover>
                            </Grid>
                            <Grid item>
                                {loading ? (
                                    <Skeleton
                                        variant="rectangular"
                                        width={80}
                                        height={80}
                                        animation="wave"
                                        sx={{ borderRadius: 4 }}
                                    />
                                ) : (
                                    <Card
                                        elevation={0}
                                        sx={{
                                            borderRadius: 4,
                                            backgroundColor: "rgba(47,49,144,0.1)",
                                            ...(valuesValidations.jan.isClosed && {
                                                backgroundColor: "#2f3190",
                                            }),
                                            ...(!valuesValidations.jan.isActive && {
                                                backgroundColor: "#E9EAE8",
                                            }),
                                            boxShadow: "10px 10px 15px rgba(3,24,81,0.15)",
                                        }}
                                    >
                                        <CardActionArea
                                            disabled={!valuesValidations.jan.isActive}
                                            onClick={() =>
                                                handleClickValidations(valuesValidations.jan.isClosed, "jan")
                                            }
                                        >
                                            <CardContent sx={{ width: 80, height: 80, textAlign: "center" }}>
                                                {!valuesValidations.jan.isActive ? (
                                                    <LockIcon sx={{ fontSize: 30, color: "rgba(0, 0, 0, 0.54)" }} />
                                                ) : valuesValidations.jan.accountingStatus <
                                                  valuesValidations.jan.bankBalanceStatus ? (
                                                    setIconMonth(valuesValidations.jan.bankBalanceStatus)
                                                ) : (
                                                    setIconMonth(valuesValidations.jan.accountingStatus)
                                                )}
                                                <Typography
                                                    variant="h2"
                                                    color={valuesValidations.jan.isClosed ? "#fff" : "primary"}
                                                    align="center"
                                                >
                                                    {t("monthNamesShort.jan")}
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                )}
                            </Grid>
                            <Grid item>
                                {loading ? (
                                    <Skeleton
                                        variant="rectangular"
                                        width={80}
                                        height={80}
                                        animation="wave"
                                        sx={{ borderRadius: 4 }}
                                    />
                                ) : (
                                    <Card
                                        elevation={0}
                                        sx={{
                                            borderRadius: 4,
                                            backgroundColor: "rgba(47,49,144,0.1)",
                                            ...(valuesValidations.feb.isClosed && {
                                                backgroundColor: "#2f3190",
                                            }),
                                            ...(!valuesValidations.feb.isActive && {
                                                backgroundColor: "#E9EAE8",
                                            }),
                                            boxShadow: "10px 10px 15px rgba(3,24,81,0.15)",
                                        }}
                                    >
                                        <CardActionArea
                                            disabled={!valuesValidations.feb.isActive}
                                            onClick={() =>
                                                handleClickValidations(valuesValidations.feb.isClosed, "feb")
                                            }
                                        >
                                            <CardContent sx={{ width: 80, height: 80, textAlign: "center" }}>
                                                {!valuesValidations.feb.isActive ? (
                                                    <LockIcon sx={{ fontSize: 30, color: "rgba(0, 0, 0, 0.54)" }} />
                                                ) : valuesValidations.feb.accountingStatus <
                                                  valuesValidations.feb.bankBalanceStatus ? (
                                                    setIconMonth(valuesValidations.feb.bankBalanceStatus)
                                                ) : (
                                                    setIconMonth(valuesValidations.feb.accountingStatus)
                                                )}
                                                <Typography
                                                    variant="h2"
                                                    color={valuesValidations.feb.isClosed ? "#fff" : "primary"}
                                                    align="center"
                                                >
                                                    {t("monthNamesShort.feb")}
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                )}
                            </Grid>
                            <Grid item>
                                {loading ? (
                                    <Skeleton
                                        variant="rectangular"
                                        width={80}
                                        height={80}
                                        animation="wave"
                                        sx={{ borderRadius: 4 }}
                                    />
                                ) : (
                                    <Card
                                        elevation={0}
                                        sx={{
                                            borderRadius: 4,
                                            backgroundColor: "rgba(47,49,144,0.1)",
                                            ...(valuesValidations.mar.isClosed && {
                                                backgroundColor: "#2f3190",
                                            }),
                                            ...(!valuesValidations.mar.isActive && {
                                                backgroundColor: "#E9EAE8",
                                            }),
                                            boxShadow: "10px 10px 15px rgba(3,24,81,0.15)",
                                        }}
                                    >
                                        <CardActionArea
                                            disabled={!valuesValidations.mar.isActive}
                                            onClick={() =>
                                                handleClickValidations(valuesValidations.mar.isClosed, "mar")
                                            }
                                        >
                                            <CardContent sx={{ width: 80, height: 80, textAlign: "center" }}>
                                                {!valuesValidations.mar.isActive ? (
                                                    <LockIcon sx={{ fontSize: 30, color: "rgba(0, 0, 0, 0.54)" }} />
                                                ) : valuesValidations.mar.accountingStatus <
                                                  valuesValidations.mar.bankBalanceStatus ? (
                                                    setIconMonth(valuesValidations.mar.bankBalanceStatus)
                                                ) : (
                                                    setIconMonth(valuesValidations.mar.accountingStatus)
                                                )}
                                                <Typography
                                                    variant="h2"
                                                    color={valuesValidations.mar.isClosed ? "#fff" : "primary"}
                                                    align="center"
                                                >
                                                    {t("monthNamesShort.mar")}
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                )}
                            </Grid>
                            <Grid item>
                                {loading ? (
                                    <Skeleton
                                        variant="rectangular"
                                        width={80}
                                        height={80}
                                        animation="wave"
                                        sx={{ borderRadius: 4 }}
                                    />
                                ) : (
                                    <Card
                                        elevation={0}
                                        sx={{
                                            borderRadius: 4,
                                            backgroundColor: "rgba(47,49,144,0.1)",
                                            ...(valuesValidations.apr.isClosed && {
                                                backgroundColor: "#2f3190",
                                            }),
                                            ...(!valuesValidations.apr.isActive && {
                                                backgroundColor: "#E9EAE8",
                                            }),
                                            boxShadow: "10px 10px 15px rgba(3,24,81,0.15)",
                                        }}
                                    >
                                        <CardActionArea
                                            disabled={!valuesValidations.apr.isActive}
                                            onClick={() =>
                                                handleClickValidations(valuesValidations.apr.isClosed, "apr")
                                            }
                                        >
                                            <CardContent sx={{ width: 80, height: 80, textAlign: "center" }}>
                                                {!valuesValidations.apr.isActive ? (
                                                    <LockIcon sx={{ fontSize: 30, color: "rgba(0, 0, 0, 0.54)" }} />
                                                ) : valuesValidations.apr.accountingStatus <
                                                  valuesValidations.apr.bankBalanceStatus ? (
                                                    setIconMonth(valuesValidations.apr.bankBalanceStatus)
                                                ) : (
                                                    setIconMonth(valuesValidations.apr.accountingStatus)
                                                )}
                                                <Typography
                                                    variant="h2"
                                                    color={valuesValidations.apr.isClosed ? "#fff" : "primary"}
                                                    align="center"
                                                >
                                                    {t("monthNamesShort.apr")}
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                )}
                            </Grid>
                            <Grid item>
                                {loading ? (
                                    <Skeleton
                                        variant="rectangular"
                                        width={80}
                                        height={80}
                                        animation="wave"
                                        sx={{ borderRadius: 4 }}
                                    />
                                ) : (
                                    <Card
                                        elevation={0}
                                        sx={{
                                            borderRadius: 4,
                                            backgroundColor: "rgba(47,49,144,0.1)",
                                            ...(valuesValidations.may.isClosed && {
                                                backgroundColor: "#2f3190",
                                            }),
                                            ...(!valuesValidations.may.isActive && {
                                                backgroundColor: "#E9EAE8",
                                            }),
                                            boxShadow: "10px 10px 15px rgba(3,24,81,0.15)",
                                        }}
                                    >
                                        <CardActionArea
                                            disabled={!valuesValidations.may.isActive}
                                            onClick={() =>
                                                handleClickValidations(valuesValidations.may.isClosed, "may")
                                            }
                                        >
                                            <CardContent sx={{ width: 80, height: 80, textAlign: "center" }}>
                                                {!valuesValidations.may.isActive ? (
                                                    <LockIcon sx={{ fontSize: 30, color: "rgba(0, 0, 0, 0.54)" }} />
                                                ) : valuesValidations.may.accountingStatus <
                                                  valuesValidations.may.bankBalanceStatus ? (
                                                    setIconMonth(valuesValidations.may.bankBalanceStatus)
                                                ) : (
                                                    setIconMonth(valuesValidations.may.accountingStatus)
                                                )}
                                                <Typography
                                                    variant="h2"
                                                    color={valuesValidations.may.isClosed ? "#fff" : "primary"}
                                                    align="center"
                                                >
                                                    {t("monthNamesShort.may")}
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                )}
                            </Grid>
                            <Grid item>
                                {loading ? (
                                    <Skeleton
                                        variant="rectangular"
                                        width={80}
                                        height={80}
                                        animation="wave"
                                        sx={{ borderRadius: 4 }}
                                    />
                                ) : (
                                    <Card
                                        elevation={0}
                                        sx={{
                                            borderRadius: 4,
                                            backgroundColor: "rgba(47,49,144,0.1)",
                                            ...(valuesValidations.jun.isClosed && {
                                                backgroundColor: "#2f3190",
                                            }),
                                            ...(!valuesValidations.jun.isActive && {
                                                backgroundColor: "#E9EAE8",
                                            }),
                                            boxShadow: "10px 10px 15px rgba(3,24,81,0.15)",
                                        }}
                                    >
                                        <CardActionArea
                                            disabled={!valuesValidations.jun.isActive}
                                            onClick={() =>
                                                handleClickValidations(valuesValidations.jun.isClosed, "jun")
                                            }
                                        >
                                            <CardContent sx={{ width: 80, height: 80, textAlign: "center" }}>
                                                {!valuesValidations.jun.isActive ? (
                                                    <LockIcon sx={{ fontSize: 30, color: "rgba(0, 0, 0, 0.54)" }} />
                                                ) : valuesValidations.jun.accountingStatus <
                                                  valuesValidations.jun.bankBalanceStatus ? (
                                                    setIconMonth(valuesValidations.jun.bankBalanceStatus)
                                                ) : (
                                                    setIconMonth(valuesValidations.jun.accountingStatus)
                                                )}
                                                <Typography
                                                    variant="h2"
                                                    color={valuesValidations.jun.isClosed ? "#fff" : "primary"}
                                                    align="center"
                                                >
                                                    {t("monthNamesShort.jun")}
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                )}
                            </Grid>
                            <Grid item>
                                {loading ? (
                                    <Skeleton
                                        variant="rectangular"
                                        width={80}
                                        height={80}
                                        animation="wave"
                                        sx={{ borderRadius: 4 }}
                                    />
                                ) : (
                                    <Card
                                        elevation={0}
                                        sx={{
                                            borderRadius: 4,
                                            backgroundColor: "rgba(47,49,144,0.1)",
                                            ...(valuesValidations.jul.isClosed && {
                                                backgroundColor: "#2f3190",
                                            }),
                                            ...(!valuesValidations.jul.isActive && {
                                                backgroundColor: "#E9EAE8",
                                            }),
                                            boxShadow: "10px 10px 15px rgba(3,24,81,0.15)",
                                        }}
                                    >
                                        <CardActionArea
                                            disabled={!valuesValidations.jul.isActive}
                                            onClick={() =>
                                                handleClickValidations(valuesValidations.jul.isClosed, "jul")
                                            }
                                        >
                                            <CardContent sx={{ width: 80, height: 80, textAlign: "center" }}>
                                                {!valuesValidations.jul.isActive ? (
                                                    <LockIcon sx={{ fontSize: 30, color: "rgba(0, 0, 0, 0.54)" }} />
                                                ) : valuesValidations.jul.accountingStatus <
                                                  valuesValidations.jul.bankBalanceStatus ? (
                                                    setIconMonth(valuesValidations.jul.bankBalanceStatus)
                                                ) : (
                                                    setIconMonth(valuesValidations.jul.accountingStatus)
                                                )}
                                                <Typography
                                                    variant="h2"
                                                    color={valuesValidations.jul.isClosed ? "#fff" : "primary"}
                                                    align="center"
                                                >
                                                    {t("monthNamesShort.jul")}
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                )}
                            </Grid>
                            <Grid item>
                                {loading ? (
                                    <Skeleton
                                        variant="rectangular"
                                        width={80}
                                        height={80}
                                        animation="wave"
                                        sx={{ borderRadius: 4 }}
                                    />
                                ) : (
                                    <Card
                                        elevation={0}
                                        sx={{
                                            borderRadius: 4,
                                            backgroundColor: "rgba(47,49,144,0.1)",
                                            ...(valuesValidations.aug.isClosed && {
                                                backgroundColor: "#2f3190",
                                            }),
                                            ...(!valuesValidations.aug.isActive && {
                                                backgroundColor: "#E9EAE8",
                                            }),
                                            boxShadow: "10px 10px 15px rgba(3,24,81,0.15)",
                                        }}
                                    >
                                        <CardActionArea
                                            disabled={!valuesValidations.aug.isActive}
                                            onClick={() =>
                                                handleClickValidations(valuesValidations.aug.isClosed, "aug")
                                            }
                                        >
                                            <CardContent sx={{ width: 80, height: 80, textAlign: "center" }}>
                                                {!valuesValidations.aug.isActive ? (
                                                    <LockIcon sx={{ fontSize: 30, color: "rgba(0, 0, 0, 0.54)" }} />
                                                ) : valuesValidations.aug.accountingStatus <
                                                  valuesValidations.aug.bankBalanceStatus ? (
                                                    setIconMonth(valuesValidations.aug.bankBalanceStatus)
                                                ) : (
                                                    setIconMonth(valuesValidations.aug.accountingStatus)
                                                )}
                                                <Typography
                                                    variant="h2"
                                                    color={valuesValidations.aug.isClosed ? "#fff" : "primary"}
                                                    align="center"
                                                >
                                                    {t("monthNamesShort.aug")}
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                )}
                            </Grid>
                            <Grid item>
                                {loading ? (
                                    <Skeleton
                                        variant="rectangular"
                                        width={80}
                                        height={80}
                                        animation="wave"
                                        sx={{ borderRadius: 4 }}
                                    />
                                ) : (
                                    <Card
                                        elevation={0}
                                        sx={{
                                            borderRadius: 4,
                                            backgroundColor: "rgba(47,49,144,0.1)",
                                            ...(valuesValidations.sep.isClosed && {
                                                backgroundColor: "#2f3190",
                                            }),
                                            ...(!valuesValidations.sep.isActive && {
                                                backgroundColor: "#E9EAE8",
                                            }),
                                            boxShadow: "10px 10px 15px rgba(3,24,81,0.15)",
                                        }}
                                    >
                                        <CardActionArea
                                            disabled={!valuesValidations.sep.isActive}
                                            onClick={() =>
                                                handleClickValidations(valuesValidations.sep.isClosed, "sep")
                                            }
                                        >
                                            <CardContent sx={{ width: 80, height: 80, textAlign: "center" }}>
                                                {!valuesValidations.sep.isActive ? (
                                                    <LockIcon sx={{ fontSize: 30, color: "rgba(0, 0, 0, 0.54)" }} />
                                                ) : valuesValidations.sep.accountingStatus <
                                                  valuesValidations.sep.bankBalanceStatus ? (
                                                    setIconMonth(valuesValidations.sep.bankBalanceStatus)
                                                ) : (
                                                    setIconMonth(valuesValidations.sep.accountingStatus)
                                                )}
                                                <Typography
                                                    variant="h2"
                                                    color={valuesValidations.sep.isClosed ? "#fff" : "primary"}
                                                    align="center"
                                                >
                                                    {t("monthNamesShort.sep")}
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                )}
                            </Grid>
                            <Grid item>
                                {loading ? (
                                    <Skeleton
                                        variant="rectangular"
                                        width={80}
                                        height={80}
                                        animation="wave"
                                        sx={{ borderRadius: 4 }}
                                    />
                                ) : (
                                    <Card
                                        elevation={0}
                                        sx={{
                                            borderRadius: 4,
                                            backgroundColor: "rgba(47,49,144,0.1)",
                                            ...(valuesValidations.oct.isClosed && {
                                                backgroundColor: "#2f3190",
                                            }),
                                            ...(!valuesValidations.oct.isActive && {
                                                backgroundColor: "#E9EAE8",
                                            }),
                                            boxShadow: "10px 10px 15px rgba(3,24,81,0.15)",
                                        }}
                                    >
                                        <CardActionArea
                                            disabled={!valuesValidations.oct.isActive}
                                            onClick={() =>
                                                handleClickValidations(valuesValidations.oct.isClosed, "oct")
                                            }
                                        >
                                            <CardContent sx={{ width: 80, height: 80, textAlign: "center" }}>
                                                {!valuesValidations.oct.isActive ? (
                                                    <LockIcon sx={{ fontSize: 30, color: "rgba(0, 0, 0, 0.54)" }} />
                                                ) : valuesValidations.oct.accountingStatus <
                                                  valuesValidations.oct.bankBalanceStatus ? (
                                                    setIconMonth(valuesValidations.oct.bankBalanceStatus)
                                                ) : (
                                                    setIconMonth(valuesValidations.oct.accountingStatus)
                                                )}
                                                <Typography
                                                    variant="h2"
                                                    color={valuesValidations.oct.isClosed ? "#fff" : "primary"}
                                                    align="center"
                                                >
                                                    {t("monthNamesShort.oct")}
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                )}
                            </Grid>
                            <Grid item>
                                {loading ? (
                                    <Skeleton
                                        variant="rectangular"
                                        width={80}
                                        height={80}
                                        animation="wave"
                                        sx={{ borderRadius: 4 }}
                                    />
                                ) : (
                                    <Card
                                        elevation={0}
                                        sx={{
                                            borderRadius: 4,
                                            backgroundColor: "rgba(47,49,144,0.1)",
                                            ...(valuesValidations.nov.isClosed && {
                                                backgroundColor: "#2f3190",
                                            }),
                                            ...(!valuesValidations.nov.isActive && {
                                                backgroundColor: "#E9EAE8",
                                            }),
                                            boxShadow: "10px 10px 15px rgba(3,24,81,0.15)",
                                        }}
                                    >
                                        <CardActionArea
                                            disabled={!valuesValidations.nov.isActive}
                                            onClick={() =>
                                                handleClickValidations(valuesValidations.nov.isClosed, "nov")
                                            }
                                        >
                                            <CardContent sx={{ width: 80, height: 80, textAlign: "center" }}>
                                                {!valuesValidations.nov.isActive ? (
                                                    <LockIcon sx={{ fontSize: 30, color: "rgba(0, 0, 0, 0.54)" }} />
                                                ) : valuesValidations.nov.accountingStatus <
                                                  valuesValidations.nov.bankBalanceStatus ? (
                                                    setIconMonth(valuesValidations.nov.bankBalanceStatus)
                                                ) : (
                                                    setIconMonth(valuesValidations.nov.accountingStatus)
                                                )}
                                                <Typography
                                                    variant="h2"
                                                    color={valuesValidations.nov.isClosed ? "#fff" : "primary"}
                                                    align="center"
                                                >
                                                    {t("monthNamesShort.nov")}
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                )}
                            </Grid>
                            <Grid item>
                                {loading ? (
                                    <Skeleton
                                        variant="rectangular"
                                        width={80}
                                        height={80}
                                        animation="wave"
                                        sx={{ borderRadius: 4 }}
                                    />
                                ) : (
                                    <Card
                                        elevation={0}
                                        sx={{
                                            borderRadius: 4,
                                            backgroundColor: "rgba(47,49,144,0.1)",
                                            ...(valuesValidations.dec.isClosed && {
                                                backgroundColor: "#2f3190",
                                            }),
                                            ...(!valuesValidations.dec.isActive && {
                                                backgroundColor: "#E9EAE8",
                                            }),
                                            boxShadow: "10px 10px 15px rgba(3,24,81,0.15)",
                                        }}
                                    >
                                        <CardActionArea
                                            disabled={!valuesValidations.dec.isActive}
                                            onClick={() =>
                                                handleClickValidations(valuesValidations.dec.isClosed, "dec")
                                            }
                                        >
                                            <CardContent sx={{ width: 80, height: 80, textAlign: "center" }}>
                                                {!valuesValidations.dec.isActive ? (
                                                    <LockIcon sx={{ fontSize: 30, color: "rgba(0, 0, 0, 0.54)" }} />
                                                ) : valuesValidations.dec.accountingStatus <
                                                  valuesValidations.dec.bankBalanceStatus ? (
                                                    setIconMonth(valuesValidations.dec.bankBalanceStatus)
                                                ) : (
                                                    setIconMonth(valuesValidations.dec.accountingStatus)
                                                )}
                                                <Typography
                                                    variant="h2"
                                                    color={valuesValidations.dec.isClosed ? "#fff" : "primary"}
                                                    align="center"
                                                >
                                                    {t("monthNamesShort.dec")}
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                )}
                            </Grid>
                        </Grid>
                    </Container>
                    <Grid container justifyContent="center" spacing={2}>
                        {loading ? (
                            <>
                                <Grid item xs={12}>
                                    <Skeleton
                                        variant="rectangular"
                                        width="90%"
                                        height={200}
                                        animation="wave"
                                        sx={{ mt: 6, ml: "auto", mr: "auto", borderRadius: 4 }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Skeleton
                                        variant="rectangular"
                                        width="90%"
                                        height={200}
                                        animation="wave"
                                        sx={{ mt: 6, ml: "auto", mr: "auto", borderRadius: 4 }}
                                    />
                                </Grid>
                            </>
                        ) : (
                            Object.values(valuesValidations).map((element, index) =>
                                element.isClosed ? (
                                    <Grid item xs={12}>
                                        <Card
                                            elevation={0}
                                            sx={{
                                                width: "90%",
                                                ml: "auto",
                                                mr: "auto",
                                                mt: 6,
                                                borderRadius: 4,
                                                backgroundColor: "rgba(47,49,144,0.1)",
                                                boxShadow: "10px 10px 15px rgba(3,24,81,0.15)",
                                            }}
                                        >
                                            <CardHeader
                                                title={
                                                    <Typography variant="h1" sx={{ fontSize: 20 }}>
                                                        {element.label}
                                                    </Typography>
                                                }
                                                subheader={t("bankingDashboard.seeDetail")}
                                                sx={{ p: 3, pb: 0 }}
                                            />
                                            <CardContent sx={{ p: 3 }}>
                                                <Grid container alignItems="center" spacing={8}>
                                                    <Grid item xs={12} md={6} xl={4} sx={{ pt: "48px !important" }}>
                                                        <Box
                                                            sx={{
                                                                p: 0,
                                                                display: "flex",
                                                                justifyContent: "space-between",
                                                            }}
                                                        >
                                                            <Box sx={{ mt: "auto", mb: "auto" }}>
                                                                <Typography variant="h2" color="primary">
                                                                    {t("bankingDashboard.xeroBalance")}
                                                                </Typography>
                                                            </Box>
                                                            <Box sx={{ mt: "auto", mb: "auto" }}>
                                                                <ListItem
                                                                    alignItems="center"
                                                                    secondaryAction={
                                                                        <HorizontalRuleIcon
                                                                            fontSize="small"
                                                                            sx={{ opacity: 0 }}
                                                                        />
                                                                    }
                                                                    sx={{ ml: "auto", mr: "auto" }}
                                                                >
                                                                    <ListItemText
                                                                        primary={parseFloat(
                                                                            element.xeroBankBalance
                                                                        ).toLocaleString(todos.amountFormat, {
                                                                            minimumFractionDigits: 2,
                                                                            maximumFractionDigits: 2,
                                                                        })}
                                                                        primaryTypographyProps={{
                                                                            fontSize: 16,
                                                                            textAlign: "left",
                                                                        }}
                                                                    />
                                                                </ListItem>
                                                            </Box>
                                                        </Box>
                                                        <Divider sx={{ borderColor: "#031851" }} />
                                                        <Box
                                                            sx={{
                                                                p: 0,
                                                                display: "flex",
                                                                justifyContent: "space-between",
                                                            }}
                                                        >
                                                            <Box sx={{ mt: "auto", mb: "auto" }}>
                                                                <Typography variant="h2" color="primary">
                                                                    {t("bankingDashboard.accountStatement")}
                                                                </Typography>
                                                            </Box>
                                                            <Box sx={{ mt: "auto", mb: "auto" }}>
                                                                <ListItem
                                                                    alignItems="center"
                                                                    secondaryAction={
                                                                        element.bankBalanceStatus === 0 ? (
                                                                            <LightTooltip
                                                                                title={t(
                                                                                    "bankingDashboard.verifiedBalance"
                                                                                )}
                                                                                aria-label="check"
                                                                            >
                                                                                <CheckIcon
                                                                                    fontSize="small"
                                                                                    sx={{ color: "#4caf50" }}
                                                                                />
                                                                            </LightTooltip>
                                                                        ) : element.bankBalanceStatus === 3 ? (
                                                                            <LightTooltip
                                                                                title={t(
                                                                                    "bankingDashboard.noMovementsLoaded"
                                                                                )}
                                                                                aria-label="cancel"
                                                                            >
                                                                                <CancelIcon
                                                                                    fontSize="small"
                                                                                    color="secondary"
                                                                                />
                                                                            </LightTooltip>
                                                                        ) : (
                                                                            <LightTooltip
                                                                                title={t(
                                                                                    "bankingDashboard.noXeroAmount"
                                                                                )}
                                                                                aria-label="cancel"
                                                                            >
                                                                                <CancelIcon
                                                                                    fontSize="small"
                                                                                    color="secondary"
                                                                                />
                                                                            </LightTooltip>
                                                                        )
                                                                    }
                                                                    sx={{ ml: "auto", mr: "auto" }}
                                                                >
                                                                    <ListItemText
                                                                        primary={
                                                                            <Typography
                                                                                component="span"
                                                                                variant="h2"
                                                                                className={classes.accountAmount}
                                                                                onClick={() =>
                                                                                    handleOpenBankDialog(index, element)
                                                                                }
                                                                            >
                                                                                {element.statementBalance}
                                                                            </Typography>
                                                                        }
                                                                        primaryTypographyProps={{ textAlign: "left" }}
                                                                    />
                                                                </ListItem>
                                                            </Box>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12} md={6} xl={4} sx={{ pt: "48px !important" }}>
                                                        <Box
                                                            sx={{
                                                                p: 0,
                                                                display: "flex",
                                                                justifyContent: "space-between",
                                                            }}
                                                        >
                                                            <Box sx={{ mt: "auto", mb: "auto" }}>
                                                                <Typography variant="h2" color="primary">
                                                                    {t("bankingDashboard.xeroBalance")}
                                                                </Typography>
                                                            </Box>
                                                            <Box sx={{ mt: "auto", mb: "auto" }}>
                                                                <ListItem
                                                                    alignItems="center"
                                                                    secondaryAction={
                                                                        <HorizontalRuleIcon
                                                                            fontSize="small"
                                                                            sx={{ opacity: 0 }}
                                                                        />
                                                                    }
                                                                    sx={{ ml: "auto", mr: "auto" }}
                                                                >
                                                                    <ListItemText
                                                                        primary={parseFloat(
                                                                            element.xeroBankBalance
                                                                        ).toLocaleString(todos.amountFormat, {
                                                                            minimumFractionDigits: 2,
                                                                            maximumFractionDigits: 2,
                                                                        })}
                                                                        primaryTypographyProps={{
                                                                            fontSize: 16,
                                                                            textAlign: "left",
                                                                        }}
                                                                    />
                                                                </ListItem>
                                                            </Box>
                                                        </Box>
                                                        <Divider sx={{ borderColor: "#031851" }} />
                                                        <Box
                                                            sx={{
                                                                p: 0,
                                                                display: "flex",
                                                                justifyContent: "space-between",
                                                            }}
                                                        >
                                                            <Box sx={{ mt: "auto", mb: "auto" }}>
                                                                <Typography variant="h2" color="primary">
                                                                    {t("bankingDashboard.xeroBooks")}
                                                                </Typography>
                                                            </Box>
                                                            <Box sx={{ mt: "auto", mb: "auto" }}>
                                                                <ListItem
                                                                    alignItems="center"
                                                                    secondaryAction={
                                                                        element.bankAccountingStatus === 0 ? (
                                                                            <LightTooltip
                                                                                title={t(
                                                                                    "bankingDashboard.greatMsgBooks"
                                                                                )}
                                                                                aria-label="check"
                                                                            >
                                                                                <CheckIcon
                                                                                    fontSize="small"
                                                                                    sx={{ color: "#4caf50" }}
                                                                                />
                                                                            </LightTooltip>
                                                                        ) : (
                                                                            <LightTooltip
                                                                                title={
                                                                                    <>
                                                                                        <Typography
                                                                                            variant="body1"
                                                                                            sx={{ fontSize: 12 }}
                                                                                        >
                                                                                            {t(
                                                                                                "bankingDashboard.warningMsg"
                                                                                            )}
                                                                                        </Typography>
                                                                                        <Typography
                                                                                            variant="body1"
                                                                                            sx={{ fontSize: 12 }}
                                                                                        >
                                                                                            <span
                                                                                                style={{
                                                                                                    fontWeight: 600,
                                                                                                }}
                                                                                            >
                                                                                                {t(
                                                                                                    "miscellaneous.difference"
                                                                                                )}
                                                                                                :{" "}
                                                                                            </span>
                                                                                            {parseFloat(
                                                                                                Math.abs(
                                                                                                    element.xeroAccountingBalance -
                                                                                                        element.xeroBankBalance
                                                                                                )
                                                                                            ).toLocaleString(
                                                                                                todos.amountFormat,
                                                                                                {
                                                                                                    minimumFractionDigits: 2,
                                                                                                    maximumFractionDigits: 2,
                                                                                                }
                                                                                            )}
                                                                                        </Typography>
                                                                                    </>
                                                                                }
                                                                                aria-label="warning"
                                                                            >
                                                                                <WarningAmberIcon
                                                                                    fontSize="small"
                                                                                    sx={{ color: "#FFB039" }}
                                                                                />
                                                                            </LightTooltip>
                                                                        )
                                                                    }
                                                                    sx={{ ml: "auto", mr: "auto" }}
                                                                >
                                                                    <ListItemText
                                                                        primary={parseFloat(
                                                                            element.xeroAccountingBalance
                                                                        ).toLocaleString(todos.amountFormat, {
                                                                            minimumFractionDigits: 2,
                                                                            maximumFractionDigits: 2,
                                                                        })}
                                                                        primaryTypographyProps={{
                                                                            fontSize: 16,
                                                                            textAlign: "left",
                                                                        }}
                                                                    />
                                                                </ListItem>
                                                            </Box>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12} md={6} xl={4} sx={{ pt: "48px !important" }}>
                                                        <Box
                                                            sx={{
                                                                p: 0,
                                                                display: "flex",
                                                                justifyContent: "space-between",
                                                            }}
                                                        >
                                                            <Box sx={{ mt: "auto", mb: "auto" }}>
                                                                <Typography variant="h2" color="primary">
                                                                    {t("bankingDashboard.reconcileAmount")}
                                                                </Typography>
                                                            </Box>
                                                            <Box sx={{ mt: "auto", mb: "auto" }}>
                                                                <ListItem
                                                                    alignItems="center"
                                                                    secondaryAction={
                                                                        <HorizontalRuleIcon
                                                                            fontSize="small"
                                                                            sx={{ opacity: 0 }}
                                                                        />
                                                                    }
                                                                    sx={{ ml: "auto", mr: "auto" }}
                                                                >
                                                                    <ListItemText
                                                                        primary={parseFloat(
                                                                            element.unreconciledAmount
                                                                        ).toLocaleString(todos.amountFormat, {
                                                                            minimumFractionDigits: 2,
                                                                            maximumFractionDigits: 2,
                                                                        })}
                                                                        primaryTypographyProps={{
                                                                            fontSize: 16,
                                                                            textAlign: "left",
                                                                        }}
                                                                    />
                                                                </ListItem>
                                                            </Box>
                                                        </Box>
                                                        <Divider sx={{ borderColor: "#031851" }} />
                                                        <Box
                                                            sx={{
                                                                p: 0,
                                                                display: "flex",
                                                                justifyContent: "space-between",
                                                            }}
                                                        >
                                                            <Box sx={{ mt: "auto", mb: "auto" }}>
                                                                <Typography variant="h2" color="primary">
                                                                    {t("bankingDashboard.itemsReconciled")}
                                                                </Typography>
                                                            </Box>
                                                            <Box sx={{ mt: "auto", mb: "auto" }}>
                                                                <ListItem
                                                                    alignItems="center"
                                                                    secondaryAction={
                                                                        element.accountingStatus === 0 ? (
                                                                            <LightTooltip
                                                                                title={t("bankingDashboard.greatMsg")}
                                                                                aria-label="check"
                                                                            >
                                                                                <CheckIcon
                                                                                    fontSize="small"
                                                                                    sx={{ color: "#4caf50" }}
                                                                                />
                                                                            </LightTooltip>
                                                                        ) : element.accountingStatus === 1 ? (
                                                                            <LightTooltip
                                                                                title={
                                                                                    <>
                                                                                        <Typography
                                                                                            variant="body1"
                                                                                            sx={{ fontSize: 12 }}
                                                                                        >
                                                                                            {t(
                                                                                                "bankingDashboard.pendingItemsReconciled"
                                                                                            )}
                                                                                        </Typography>
                                                                                        <Typography
                                                                                            variant="body1"
                                                                                            sx={{ fontSize: 12 }}
                                                                                        >
                                                                                            <span
                                                                                                style={{
                                                                                                    fontWeight: 600,
                                                                                                }}
                                                                                            >
                                                                                                {t(
                                                                                                    "miscellaneous.banks"
                                                                                                )}
                                                                                                :{" "}
                                                                                            </span>
                                                                                            {
                                                                                                element.unreconciledBankItems
                                                                                            }
                                                                                            {element.oldestBankDate ===
                                                                                            null
                                                                                                ? ` (0 ${t(
                                                                                                      "bankingDashboard.weeks"
                                                                                                  )})`
                                                                                                : ` (${moment().diff(
                                                                                                      element.oldestBankDate,
                                                                                                      "weeks"
                                                                                                  )} ${t(
                                                                                                      "bankingDashboard.weeks"
                                                                                                  )})`}
                                                                                        </Typography>
                                                                                        <Typography
                                                                                            variant="body1"
                                                                                            sx={{ fontSize: 12 }}
                                                                                        >
                                                                                            <span
                                                                                                style={{
                                                                                                    fontWeight: 600,
                                                                                                }}
                                                                                            >
                                                                                                {t(
                                                                                                    "miscellaneous.books"
                                                                                                )}
                                                                                                :{" "}
                                                                                            </span>
                                                                                            {
                                                                                                element.unreconciledAccountingItems
                                                                                            }
                                                                                            {element.oldestAccountingDate ===
                                                                                            null
                                                                                                ? ` (0 ${t(
                                                                                                      "bankingDashboard.weeks"
                                                                                                  )})`
                                                                                                : ` (${moment().diff(
                                                                                                      element.oldestAccountingDate,
                                                                                                      "weeks"
                                                                                                  )} ${t(
                                                                                                      "bankingDashboard.weeks"
                                                                                                  )})`}
                                                                                        </Typography>
                                                                                    </>
                                                                                }
                                                                                aria-label="warning"
                                                                            >
                                                                                <WarningAmberIcon
                                                                                    fontSize="small"
                                                                                    sx={{ color: "#FFB039" }}
                                                                                />
                                                                            </LightTooltip>
                                                                        ) : (
                                                                            <LightTooltip
                                                                                title={
                                                                                    <>
                                                                                        <Typography
                                                                                            variant="body1"
                                                                                            sx={{ fontSize: 12 }}
                                                                                        >
                                                                                            {t(
                                                                                                "bankingDashboard.pendingItemsReconciled"
                                                                                            )}
                                                                                        </Typography>
                                                                                        <Typography
                                                                                            variant="body1"
                                                                                            sx={{ fontSize: 12 }}
                                                                                        >
                                                                                            <span
                                                                                                style={{
                                                                                                    fontWeight: 600,
                                                                                                }}
                                                                                            >
                                                                                                {t(
                                                                                                    "miscellaneous.banks"
                                                                                                )}
                                                                                                :{" "}
                                                                                            </span>
                                                                                            {
                                                                                                element.unreconciledBankItems
                                                                                            }
                                                                                            {element.oldestBankDate ===
                                                                                            null
                                                                                                ? ` (0 ${t(
                                                                                                      "bankingDashboard.weeks"
                                                                                                  )})`
                                                                                                : ` (${moment().diff(
                                                                                                      element.oldestBankDate,
                                                                                                      "weeks"
                                                                                                  )} ${t(
                                                                                                      "bankingDashboard.weeks"
                                                                                                  )})`}
                                                                                        </Typography>
                                                                                        <Typography
                                                                                            variant="body1"
                                                                                            sx={{ fontSize: 12 }}
                                                                                        >
                                                                                            <span
                                                                                                style={{
                                                                                                    fontWeight: 600,
                                                                                                }}
                                                                                            >
                                                                                                {t(
                                                                                                    "miscellaneous.books"
                                                                                                )}
                                                                                                :{" "}
                                                                                            </span>
                                                                                            {
                                                                                                element.unreconciledAccountingItems
                                                                                            }
                                                                                            {element.oldestAccountingDate ===
                                                                                            null
                                                                                                ? ` (0 ${t(
                                                                                                      "bankingDashboard.weeks"
                                                                                                  )})`
                                                                                                : ` (${moment().diff(
                                                                                                      element.oldestAccountingDate,
                                                                                                      "weeks"
                                                                                                  )} ${t(
                                                                                                      "bankingDashboard.weeks"
                                                                                                  )})`}
                                                                                        </Typography>
                                                                                    </>
                                                                                }
                                                                                aria-label="cancel"
                                                                            >
                                                                                <CancelIcon
                                                                                    fontSize="small"
                                                                                    color="secondary"
                                                                                />
                                                                            </LightTooltip>
                                                                        )
                                                                    }
                                                                    sx={{ ml: "auto", mr: "auto" }}
                                                                >
                                                                    <ListItemText
                                                                        primary={
                                                                            element.unreconciledBankItems +
                                                                            element.unreconciledAccountingItems
                                                                        }
                                                                        primaryTypographyProps={{
                                                                            fontSize: 16,
                                                                            textAlign: "left",
                                                                        }}
                                                                    />
                                                                </ListItem>
                                                            </Box>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                ) : null
                            )
                        )}
                    </Grid>
                </CardContent>
            </Card>
            <DialogConversions open={open} onClose={() => setOpen(false)} conversionsData={conversionsData} />
            <RegModal
                open={openRegModal}
                onClose={handleCloseDialog}
                selectedMonth={selectedMonth}
                selectedYear={selectedYear}
                accountIdSelected={props.accountIdPlatformSelected}
                uploadedFiles={uploadedFiles}
                filesRejected={filesRejected}
                setFilesRejected={setFilesRejected}
                fileFormat={fileFormat}
                filesData={filesData}
                setBackDrop={setBackDrop}
                setShow={setShow}
                setTexto={setTexto}
                setAlertType={setAlertType}
                fileExtensions={fileExtensions}
                setFilesData={setFilesData}
                loadInitData={callLoads}
                actions={uploadAsyncButtons}
                mainAmount={mainAmount}
                setMainAmount={setMainAmount}
            />
            <Backdrop open={showBackdrop} />
            <DialogAlerts
                open={openAlert}
                onClose={() => setOpenAlert(false)}
                agreeBtnLabel={t("miscellaneous.accept")}
                disagreeBtnLabel={t("miscellaneous.cancel")}
                type={"info"}
                title={dialogText}
                agreeAction={convertBankStatement}
            />
            <SnackBar type={alertType} show={show} message={texto} onClose={handleClose} />
            <BankDialog
                open={dialogForm}
                onClose={closeDialog}
                maxWidth="md"
                valuesBank={valuesBank}
                isError={isError}
                setIsError={setIsError}
                handleChangeReceptionDays={handleChangeReceptionDays}
                banks={banks}
                setValuesBank={setValuesBank}
                updateBankAccount={updateBankAccount}
                handleChangeCheckedNA={handleChangeCheckedNA}
                selectedYear={selectedYear}
                setTexto={setTexto}
                setAlertType={setAlertType}
                setBackDrop={setBackDrop}
                setShow={setShow}
            />
        </StyledContainer>
    );
};

export default BankValidations;
