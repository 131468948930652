/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {useDropzone} from 'react-dropzone';

/** Icons */
import {ReactComponent as CloudUploadIcon} from '../../assets/images/CloudUploadIcon.svg';

/** MUI Icons */
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import DeleteIcon from '@mui/icons-material/Delete';

/** MUI imports */
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    IconButton,
    List,
    ListSubheader,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
} from '@mui/material';

const PREFIX = 'UploadFiles';

const classes = {
    dropzone: `${PREFIX}-dropzone`,
};

const CustomDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(4),
    },
    '& .MuiDialogTitle-root': {
        backgroundColor: "#031851",
        fontSize: "18px",
        color: "#fff"
    },
    '& .MuiDialog-paper': {
        borderRadius: 8,
        boxShadow: '10px 10px 15px rgba(3,24,81,0.15)',
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(2),
    },

    [`& .${classes.dropzone}`]: {
        flex: 1,
        display: "flex",
        padding: "64px 24px",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: 'rgba(47,49,144,0.1)',
        border: '2px dashed #C1C1DE',
        borderRadius: '16px',
        '&:hover': {
            border: '2px dashed #2f3190',
        },
    },
}));

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: 'rgba(19, 31, 62, 0.80)',
        fontSize: 12,
        maxWidth: 320,
        borderRadius: 6,
        fontWeight: 'normal'
    },
});

const UploadFiles = (props) => {
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    /** Component states */
    const [myFiles, setMyFiles] = useState([]);
    const [filesRejected, setFilesRejected] = useState([]);

    /** Component functions */
    const formatBytes = (bytes, decimals = 0) => {
        if (bytes === 0) return '0 Bytes';
    
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    
        const i = Math.floor(Math.log(bytes) / Math.log(k));
    
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    };

    const onDrop = useCallback(
        acceptedFiles => {
          setMyFiles([...myFiles, ...acceptedFiles]);
          setFilesRejected([]);
        },
        [myFiles]
    );

    const onDropRejected = useCallback(
        fileRejections => {
            setFilesRejected([...filesRejected, ...fileRejections]);
        },
        [filesRejected]
    );

    const { getRootProps, getInputProps } = useDropzone({ 
        noKeyboard: true,
        maxSize: 5000000,
        multiple: true,
        onDrop,
        onDropRejected,
        // accept: 'image/jpeg,image/png',
    });

    const removeFile = file => () => {
        const newFiles = [...myFiles];
        newFiles.splice(newFiles.indexOf(file), 1);

        if(newFiles.length === 0) {
            for (let i = 0; i < props.filesData.length; i++) {
                if(props.filesData[i].id_organisation === props.idOrganisation) {
                    const index = props.filesData.indexOf(props.filesData[i]);
                    const newFilesData = [...props.filesData];
                    newFilesData.splice(index, 1);
                    props.setFilesData(newFilesData);
                }
            }
        }

        setMyFiles(newFiles);
    };

    const acceptedFileItems = myFiles.map((file) => (
        <ListItem 
            disablePadding 
            secondaryAction={
                <LightTooltip title={t('dialogConversions.remove')} aria-label="removeFile">
                    <IconButton 
                        edge="end"
                        aria-label="delete" 
                        onClick={removeFile(file)}
                    >
                        <DeleteIcon fontSize='medium' color='primary' />
                    </IconButton>
                </LightTooltip>
            }
            sx={{ height: 50 }}
        >
            <ListItemIcon sx={{ minWidth: '20px' }}>
                <DescriptionOutlinedIcon fontSize='large' color='primary' sx={{ mr: 1 }} />
            </ListItemIcon>
            <ListItemText 
                key={file.path} 
                primary={file.name} 
                secondary={formatBytes(file.size)}
                secondaryTypographyProps={{ fontSize: '12px' }}
            />
        </ListItem>
    ));
   
    const fileRejectionItems = useMemo(() =>
        filesRejected.map(({ file }) => (
        <ListItem disablePadding>
            <ListItemIcon sx={{ minWidth: '20px' }}>
              <DescriptionOutlinedIcon fontSize='large' color='primary' sx={{ mr: 1 }} />
            </ListItemIcon>
            <ListItemText 
                key={file.path} 
                primary={file.name} 
                secondary={formatBytes(file.size)}
                secondaryTypographyProps={{ fontSize: '12px' }}
                sx={{ color: '#ff5968' }} 
            />
        </ListItem>
        )),
        [filesRejected]
    );

    const handleCloseDialog = () => {
        setMyFiles([]);
        setFilesRejected([]);
        props.onClose();
    };

    const saveFilesInfo = () => {
        const params = {
            id_organisation: props.idOrganisation,
            files: myFiles
        };

        if(myFiles.length > 0) {
            if(props.filesData.some(element => element.id_organisation === props.idOrganisation)) {
                for (let i = 0; i < props.filesData.length; i++) {
                    if(props.filesData[i].id_organisation === props.idOrganisation) {
                        const index = props.filesData.indexOf(props.filesData[i]);
                        const newFiles = [...props.filesData];
                        newFiles[index] = params;
                        props.setFilesData(newFiles);
                    }
                }
            } else {
                props.setFilesData(prevArray => [...prevArray, params]);
            }
    
            handleCloseDialog();
        } else {
            return
        }
    };

    /** Component events */
    useEffect(() => {
        if(props.filesData.length > 0) {
            const preloadedFiles = props.filesData.filter(element => element.id_organisation === props.idOrganisation);
            if(preloadedFiles.length > 0) {
                setMyFiles(preloadedFiles[0].files);
            } else {
                setMyFiles([]);
            }
        } else {
            setMyFiles([]);
        }
    }, [props])

    return (
        <CustomDialog
            open={props.open}
            onClose={handleCloseDialog}
            maxWidth="sm"
            fullWidth={true}
        >
            <DialogContent>
                <Box>
                    <div {...getRootProps({ className: classes.dropzone })}>
                        <input {...getInputProps()} />
                        <CloudUploadIcon fill="#031851" />
                        <Typography variant="h1" color="primary" sx={{ cursor: 'default', pt: 3 }}>
                            {t('dialogConversions.selectFile')}
                        </Typography>
                    </div>
                </Box>
                <Typography variant="body1" color="primary" sx={{ pt: 1 }}>
                    {`${t('dialogConversions.validFormats')} .pdf .doc .xls`}
                </Typography>
                <Typography variant="body1" color="primary">
                    {t('dialogConversions.maximunUploadSize')}
                </Typography>
                {(acceptedFileItems.length > 0) ? (
                    <List 
                        component="nav"
                        aria-labelledby="acceptedFiles-list-subheader"
                        subheader={
                        <ListSubheader component="div" id="acceptedFiles-list-subheader" sx={ {p: 0} }>
                            <Typography variant="h4" color="primary" sx={{ pt: 3, pb: 1}}>
                                {`${t('dialogConversions.attachedFiles')}:`}
                            </Typography>
                        </ListSubheader>
                    }
                    >
                        {acceptedFileItems}
                    </List>
                    
                ) : null}
                {(fileRejectionItems.length > 0) ? (
                    <List 
                        component="nav"
                        aria-labelledby="fileRejections-list-subheader"
                        subheader={
                        <ListSubheader component="div" id="fileRejections-list-subheader" sx={{ p: 0 }}>
                            <Typography variant="h4" color="primary" sx={{ pt: 3, pb: 1}}>
                                {`${t('dialogConversions.rejectedFiles')}:`}
                            </Typography>
                        </ListSubheader>
                        }
                    >
                        {fileRejectionItems}
                    </List>
                ) : null}
            </DialogContent>
            <DialogActions sx={{ p: '16px 32px !important' }}>
                <Button disableElevation variant="contained" color="secondary" disabled={myFiles.length === 0}
                    onClick={() => saveFilesInfo(myFiles) }
                    sx={{
                        borderRadius: 40,
                        '&:hover': {
                            backgroundColor: '#2f3190',
                            color: '#fff',
                        },
                        '&.MuiButton-root.Mui-disabled': {
                            backgroundColor: '#ffcdd2 !important',
                            color: '#fff',
                        }
                    }}
                >
                    {t('miscellaneous.upload')}
                </Button>
            </DialogActions>
        </CustomDialog>
    );
};

export default UploadFiles;