/** React imports */
import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import moment from "moment";

/** MUI imports */
import { Grid, Table, TableHeaderRow, PagingPanel, Toolbar, SearchPanel } from "@devexpress/dx-react-grid-material-ui";
import { SearchState, IntegratedFiltering, PagingState, IntegratedPaging } from "@devexpress/dx-react-grid";
import { styled } from "@mui/material/styles";
import GridUI from "@mui/material/Grid";
import { Box, Skeleton, FormControl, Select, InputLabel, MenuItem, OutlinedInput, Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const PREFIX = "MainData";

const classes = {
    tableStriped: `${PREFIX}-tableStriped`,
    tableHeader: `${PREFIX}-tableHeader`,
    tableBody: `${PREFIX}-tableBody`,
    pager: `${PREFIX}-pager`,
    spacing: `${PREFIX}-spacing`,
    toolbarSearch: `${PREFIX}-toolbarSearch`,
    searchInput: `${PREFIX}-searchInput`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.spacing}`]: {
        padding: "10px 0 10px 0",
    },

    [`& .${classes.tableStriped}`]: {
        "& tbody tr:nth-of-type(odd)": {
            backgroundColor: "rgba(47,49,144,0.1)",
        },
    },
    [`& .${classes.tableHeader}`]: {
        border: "none",
        backgroundColor: "rgba(47,49,144,0.3)",
        fontSize: "15px",
        fontWeight: 600,
        color: "#031851",
        "&:first-of-type": {
            borderRadius: "20px 0 0 0",
        },
        "&:last-child": {
            borderRadius: "0 20px 0 0",
        },
    },

    [`& .${classes.tableBody}`]: {
        border: "none",
        fontSize: "14px",
        fontWeight: 400,
        color: "#031851",
    },

    [`& .${classes.pager}`]: {
        "& .Pagination-button": {
            paddingTop: 0,
            paddingBottom: 0,
            color: "#031851",
            fontWeight: 600,
        },
        "& .Pagination-activeButton": {
            backgroundColor: "rgba(47,49,144,0.3)",
        },
    },

    [`& .${classes.toolbarSearch}`]: {
        border: "none !important",
        padding: "0 0 5px !important",
    },

    [`& .${classes.searchInput}`]: {
        color: "#031851",
        "&:before": {
            borderBottomColor: "#031851",
        },
        "&:hover:before": {
            borderBottomColor: "#031851 !important",
        },
    },
}));

const tableHeaderCell = ({ ...props }) => <TableHeaderRow.Cell {...props} className={classes.tableHeader} />;

const TableComponent = ({ ...props }) => <Table.Table {...props} className={classes.tableStriped} />;

const TableCellComponent = ({ ...props }) => <Table.Cell {...props} className={classes.tableBody} />;

const tableContentComponent = (props) => <TableHeaderRow.Content {...props} />;

const PagingPanelContainer = ({ ...props }) => <PagingPanel.Container {...props} className={classes.pager} />;

const inputComponent = ({ ...props }) => <SearchPanel.Input {...props} className={classes.searchInput} />;

const toolRootComponent = ({ ...props }) => <Toolbar.Root {...props} className={classes.toolbarSearch} />;

const clientPassAccess = [
    "atorrealba@gca.digital",
    "btorres@gca.digital",
    "cserrano@gca.digital",
    "galbani@gca.digital",
    "dloyo@gca.digital",
    "murbaneja@gca.digital",
    "jalvarez@gca.digital",
    "yrosas@gca.digital",
    "kgarcia@gca.digital",
    "jrojas@gca.digital",
    "mmedina@gca.digital",
];

const tableColumnExtensions = [
    { columnName: "clientRS", width: "320px" },
    { columnName: "franq", width: "250px" },
    { columnName: "group", width: "150px" },
    { columnName: "clientNC", width: "250px" },
    { columnName: "typeClient" },
    { columnName: "clasi", width: "180px" },
    { columnName: "country", width: "180px" },
    { columnName: "market", width: "120px" },
    { columnName: "dateBSA", width: "180px", align: "center" },
    { columnName: "dateInit", width: "150px", align: "center" },
    { columnName: "dateEnd", width: "150px", align: "center" },
    { columnName: "econoAct", width: "350px" },
    { columnName: "dateFiscal", width: "160px", align: "center" },
    { columnName: "status", width: "120px" },
    { columnName: "comunE", width: "130px" },
    { columnName: "comunV", width: "130px" },
    { columnName: "expenseE", width: "200px", align: "right" },
    { columnName: "expenseA", width: "200px", align: "right" },
    { columnName: "expenseS", width: "200px", align: "right" },
    { columnName: "provider", width: "150px" },
    { columnName: "susName", width: "280px" },
    { columnName: "currency", width: "200px" },
    { columnName: "userAcc", width: "250px" },
    { columnName: "userPass", width: "250px" },
    { columnName: "factServ", width: "230px" },
    { columnName: "ambassador", width: "230px" },
    { columnName: "invoicingSub", width: "230px" },
    { columnName: "nameFact", width: "250px" },
    { columnName: "nameContF", width: "250px" },
    { columnName: "mailContF", width: "300px" },
    { columnName: "phoneContF", width: "250px" },
    { columnName: "supervisor", width: "220px" },
    { columnName: "manager", width: "220px" },
    { columnName: "advisor", width: "220px" },
    { columnName: "analyst", width: "220px" },
    { columnName: "analystB", width: "220px" },
];

const tableColumnExtensions2 = [
    { columnName: "franchise", width: "180px", align: "center" },
    { columnName: "group", width: "180px", align: "center" },
    { columnName: "clientRS", width: "320px" },
    { columnName: "statusSMB" },
    { columnName: "service", width: "180px" },
    { columnName: "project", width: "180px" },
    { columnName: "projectStatus", width: "180px" },
    { columnName: "description", width: "180px" },
    { columnName: "billingMode", width: "180px" },
    { columnName: "paymentType", width: "180px", align: "center" },
    { columnName: "frequency", width: "140px" },
    { columnName: "period", align: "center" },
    { columnName: "add", align: "center", width: "250px" },
    { columnName: "hours", align: "center" },
    { columnName: "sinceD", align: "center", width: "140px" },
    { columnName: "endD", align: "center", width: "140px" },
    { columnName: "versionSoftware", align: "center" },
    { columnName: "monthlyReport", align: "center" },
    { columnName: "commD", align: "center", width: "250px" },
    { columnName: "deliDate", align: "center", width: "180px" },
    { columnName: "discount", align: "right" },
    { columnName: "tariff", align: "right" },
];

const tableColumnExtensions3 = [
    { columnName: "clientRS", width: "320px" },
    { columnName: "contact", width: "230px" },
    { columnName: "mail", width: "300px" },
    { columnName: "rol" },
    { columnName: "require", width: "180px" },
    { columnName: "accClose", width: "180px" },
    { columnName: "status", width: "120px" },
    { columnName: "invoices", width: "150px" },
];

const rowsSkeleton = [];
const rowsSkeleton2 = [];
const rowsSkeleton3 = [];

for (let i = 0; i < 10; i++) {
    rowsSkeleton.push({
        id: i,
        clientRS: <Skeleton variant="text" animation="wave" />,
        franq: <Skeleton variant="text" animation="wave" />,
        group: <Skeleton variant="text" animation="wave" />,
        clientNC: <Skeleton variant="text" animation="wave" />,
        typeClient: <Skeleton variant="text" animation="wave" />,
        clasi: <Skeleton variant="text" animation="wave" />,
        country: <Skeleton variant="text" animation="wave" />,
        market: <Skeleton variant="text" animation="wave" />,
        dateBSA: <Skeleton variant="text" animation="wave" />,
        dateInit: <Skeleton variant="text" animation="wave" />,
        dateEnd: <Skeleton variant="text" animation="wave" />,
        econoAct: <Skeleton variant="text" animation="wave" />,
        dateFiscal: <Skeleton variant="text" animation="wave" />,
        status: <Skeleton variant="text" animation="wave" />,
        comunE: <Skeleton variant="text" animation="wave" />,
        comunV: <Skeleton variant="text" animation="wave" />,
        expenseE: <Skeleton variant="text" animation="wave" />,
        expenseA: <Skeleton variant="text" animation="wave" />,
        expenseS: <Skeleton variant="text" animation="wave" />,
        provider: <Skeleton variant="text" animation="wave" />,
        susName: <Skeleton variant="text" animation="wave" />,
        currency: <Skeleton variant="text" animation="wave" />,
        userAcc: <Skeleton variant="text" animation="wave" />,
        userPass: <Skeleton variant="text" animation="wave" />,
        factServ: <Skeleton variant="text" animation="wave" />,
        ambassador: <Skeleton variant="text" animation="wave" />,
        invoicingSub: <Skeleton variant="text" animation="wave" />,
        nameFact: <Skeleton variant="text" animation="wave" />,
        nameContF: <Skeleton variant="text" animation="wave" />,
        mailContF: <Skeleton variant="text" animation="wave" />,
        phoneContF: <Skeleton variant="text" animation="wave" />,
        supervisor: <Skeleton variant="text" animation="wave" />,
        manager: <Skeleton variant="text" animation="wave" />,
        advisor: <Skeleton variant="text" animation="wave" />,
        analyst: <Skeleton variant="text" animation="wave" />,
        analystB: <Skeleton variant="text" animation="wave" />,
    });

    rowsSkeleton2.push({
        id: i,
        franchise: <Skeleton variant="text" animation="wave" />,
        group: <Skeleton variant="text" animation="wave" />,
        clientRS: <Skeleton variant="text" animation="wave" />,
        statusSMB: <Skeleton variant="text" animation="wave" />,
        service: <Skeleton variant="text" animation="wave" />,
        project: <Skeleton variant="text" animation="wave" />,
        projectStatus: <Skeleton variant="text" animation="wave" />,
        description: <Skeleton variant="text" animation="wave" />,
        billingMode: <Skeleton variant="text" animation="wave" />,
        paymentType: <Skeleton variant="text" animation="wave" />,
        frequency: <Skeleton variant="text" animation="wave" />,
        period: <Skeleton variant="text" animation="wave" />,
        add: <Skeleton variant="text" animation="wave" />,
        hours: <Skeleton variant="text" animation="wave" />,
        sinceD: <Skeleton variant="text" animation="wave" />,
        endD: <Skeleton variant="text" animation="wave" />,
        versionSoftware: <Skeleton variant="text" animation="wave" />,
        monthlyReport: <Skeleton variant="text" animation="wave" />,
        commD: <Skeleton variant="text" animation="wave" />,
        deliDate: <Skeleton variant="text" animation="wave" />,
        discount: <Skeleton variant="text" animation="wave" />,
        tariff: <Skeleton variant="text" animation="wave" />,
    });

    rowsSkeleton3.push({
        id: i,
        clientRS: <Skeleton variant="text" animation="wave" />,
        contact: <Skeleton variant="text" animation="wave" />,
        mail: <Skeleton variant="text" animation="wave" />,
        rol: <Skeleton variant="text" animation="wave" />,
        require: <Skeleton variant="text" animation="wave" />,
        accClose: <Skeleton variant="text" animation="wave" />,
        status: <Skeleton variant="text" animation="wave" />,
        invoices: <Skeleton variant="text" animation="wave" />,
    });
}

const MainData = (props) => {
    const { reportSelected, setReportSelected } = props;

    /** Internationalization i18n */
    const [t] = useTranslation("global");

    const tableMessages = {
        noData: t("miscellaneous.noDataReports"),
    };
    const pagingMessages = {
        info: ({ from, to, count }) => `${from}${from < to ? `-${to}` : ""} ${t("miscellaneous.pagingOf")} ${count}`,
    };

    const searchMessages = {
        searchPlaceholder: t("miscellaneous.search"),
    };

    /** React redux */
    const todos = useSelector((state) => state.todos);

    /** Component states */
    const [clientData, setclientData] = useState([]);
    const [columnDefs, setColumnsDefs] = useState([]);
    const [searchValue, setSearchState] = useState("");
    const [columnExt, setColumnsExt] = useState([]);
    const controllerRef = useRef(null);

    /** Component functions */
    const handleSearch = (value) => {
        let itemS = value.replace(/ +(?= )/g, "");
        setSearchState(itemS);
    };

    const reportTypeList = [
        { id: 0, data: t("miscellaneous.generalData") },
        { id: 1, data: t("principal.services") },
        { id: 2, data: t("reports.contacts") },
    ];

    const handleChangeReportType = (event) => {
        event.preventDefault();
        setReportSelected(event.target.value);
    };

    const loadInitData = async () => {
        if (controllerRef.current) {
            controllerRef.current.abort();
        }
        const controller = new AbortController();
        controllerRef.current = controller;

        if (reportSelected === 0) {
            //Datos generales
            const requests = await fetch(`/getRequestsAcc?token_set_id=${todos.userInfo._id}`, {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
                signal: controllerRef.current.signal,
            })
                .then((res) => res.json())
                .then((data) => {
                    if (data.error) {
                        console.log(data.error);
                    } else {
                        return data;
                    }
                });

            await fetch(`/getClientsReport`, {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
                .then((res) => res.json())
                .then((data) => {
                    const resultTable = data.map((data) => {
                        let hasAccess = false;

                        requests.forEach((item) => {
                            if (item.company_id === data.id && item.has_access) hasAccess = true;
                        });

                        return {
                            id: data.id,
                            clientRS: data.company_legal_name,
                            franq:
                                data.franchise_name === "" ||
                                    data.franchise_name === null ||
                                    data.franchise_name === undefined ? (
                                    <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                                ) : (
                                    data.franchise_name
                                ),
                            group:
                                data.practice_name === "" ||
                                    data.practice_name === null ||
                                    data.practice_name === undefined ? (
                                    <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                                ) : (
                                    data.practice_name
                                ),
                            clientNC:
                                data.company_name === "" ||
                                    data.company_name === null ||
                                    data.company_name === undefined ? (
                                    <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                                ) : (
                                    data.company_name
                                ),
                            typeClient:
                                data.client_type === "" ||
                                    data.client_type === null ||
                                    data.client_type === undefined ? (
                                    <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                                ) : data.client_type === "Firm" ? (
                                    t("miscellaneous.firm")
                                ) : (
                                    data.client_type
                                ),
                            clasi:
                                data.client_status === "" ||
                                    data.client_status === null ||
                                    data.client_status === undefined ? (
                                    <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                                ) : (
                                    data.client_status
                                ),
                            country:
                                data.country_name === "" ||
                                    data.country_name === null ||
                                    data.country_name === undefined ? (
                                    <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                                ) : (
                                    t(`countries.${data.country_name.replace(/\s/g, "")}`)
                                ),
                            market:
                                data.market_name === "" ||
                                    data.market_name === null ||
                                    data.market_name === undefined ? (
                                    <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                                ) : (
                                    data.market_name
                                ),
                            dateBSA:
                                data.bsa_date === "" || data.bsa_date === null || data.bsa_date === undefined ? (
                                    <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                                ) : (
                                    moment(data.bsa_date, "DD/MM/YYYY").format(todos.dateFormat)
                                ),
                            dateInit:
                                data.since_date === "" || data.since_date === null || data.since_date === undefined ? (
                                    <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                                ) : (
                                    moment(data.since_date, "DD/MM/YYYY").format(todos.dateFormat)
                                ),
                            dateEnd:
                                data.end_date === "" || data.end_date === null || data.end_date === undefined ? (
                                    <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                                ) : (
                                    moment(data.end_date, "DD/MM/YYYY").format(todos.dateFormat)
                                ),
                            econoAct:
                                data.social_activity === "" ||
                                    data.social_activity === null ||
                                    data.social_activity === undefined ? (
                                    <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                                ) : (
                                    data.social_activity
                                ),
                            dateFiscal:
                                data.financial_end_date === "" ||
                                    data.financial_end_date === null ||
                                    data.financial_end_date === undefined ? (
                                    <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                                ) : (
                                    moment(data.financial_end_date, "DD/MM").format("MMMM")
                                ),
                            status:
                                data.status === "" || data.status === null || data.status === undefined ? (
                                    <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                                ) : (
                                    t(`dynamicLabels.${data.status.toLowerCase()}`)
                                ),
                            comunE:
                                data.typing_communication === "" ||
                                    data.typing_communication === null ||
                                    data.typing_communication === undefined ? (
                                    <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                                ) : (
                                    t(
                                        `dynamicLabels.${data.typing_communication
                                            .normalize("NFD")
                                            .replace(/[\u0300-\u036f]/g, "")
                                            .toLowerCase()}`
                                    )
                                ),
                            comunV:
                                data.verbal_communication === "" ||
                                    data.verbal_communication === null ||
                                    data.verbal_communication === undefined ? (
                                    <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                                ) : (
                                    t(
                                        `dynamicLabels.${data.verbal_communication
                                            .normalize("NFD")
                                            .replace(/[\u0300-\u036f]/g, "")
                                            .toLowerCase()}`
                                    )
                                ),
                            expenseE:
                                data.expense_estimation === "N/A" ||
                                    data.expense_estimation === "" ||
                                    data.expense_estimation === null ||
                                    data.expense_estimation === undefined ? (
                                    <Typography sx={{ marginLeft: "25%" }}>N/A</Typography>
                                ) : (
                                    `$ ${parseFloat(data.expense_estimation).toLocaleString(todos.amountFormat, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    })}`
                                ),
                            expenseA:
                                data.expense_average === "" ||
                                    data.expense_average === null ||
                                    data.expense_average === undefined ||
                                    data.expense_estimation === "N/A" ||
                                    data.expense_estimation === "" ||
                                    data.expense_estimation === null ||
                                    data.expense_estimation === undefined ? (
                                    <Typography sx={{ marginLeft: "25%" }}>N/A</Typography>
                                ) : (
                                    `$ ${parseFloat(data.expense_average).toLocaleString(todos.amountFormat, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    })}`
                                ),
                            expenseS:
                                data.expense_scale === "" ||
                                    data.expense_scale === null ||
                                    data.expense_scale === undefined ||
                                    data.expense_estimation === "N/A" ||
                                    data.expense_estimation === "" ||
                                    data.expense_estimation === null ||
                                    data.expense_estimation === undefined ? (
                                    <Typography sx={{ marginLeft: "25%" }}>N/A</Typography>
                                ) : (
                                    `$ ${parseFloat(data.expense_scale).toLocaleString(todos.amountFormat, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    })}`
                                ),
                            provider:
                                data.software_provider === "" ||
                                    data.software_provider === null ||
                                    data.software_provider === undefined ? (
                                    <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                                ) : (
                                    data.software_provider
                                ),
                            susName:
                                data.company_subscription_name === "" ||
                                    data.company_subscription_name === null ||
                                    data.company_subscription_name === undefined ? (
                                    <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                                ) : (
                                    data.company_subscription_name
                                ),
                            currency:
                                data.base_currency === "" ||
                                    data.base_currency === null ||
                                    data.base_currency === undefined ? (
                                    <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                                ) : (
                                    data.base_currency
                                ),
                            userAcc:
                                data.xero_master_user === "" ||
                                    data.xero_master_user === null ||
                                    data.xero_master_user === undefined ? (
                                    <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                                ) : (
                                    data.xero_master_user
                                ),
                            userPass:
                                data.xero_master_password &&
                                    (clientPassAccess.includes(todos.userInfo.email) || hasAccess) ? (
                                    data.xero_master_password
                                ) : (
                                    <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                                ),
                            factServ:
                                data.invoicing_level === "" ||
                                    data.invoicing_level === null ||
                                    data.invoicing_level === undefined ? (
                                    <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                                ) : (
                                    t(`dynamicLabels.${data.invoicing_level.toLowerCase()}`)
                                ),
                            ambassador:
                                data.ambassador_name === "" ||
                                    data.ambassador_name === null ||
                                    data.ambassador_name === undefined ? (
                                    <Typography sx={{ marginLeft: "25%" }}>N/A</Typography>
                                ) : (
                                    data.ambassador_name
                                ),
                            invoicingSub:
                                data.invoicing_subscription === "" ||
                                    data.invoicing_subscription === null ||
                                    data.invoicing_subscription === undefined ? (
                                    <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                                ) : (
                                    data.invoicing_subscription
                                ),
                            nameFact:
                                data.invoicing_contact_name === "" ||
                                    data.invoicing_contact_name === null ||
                                    data.invoicing_contact_name === undefined ? (
                                    <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                                ) : (
                                    data.invoicing_contact_name
                                ),
                            nameContF:
                                data.invoicing_client_name === "" ||
                                    data.invoicing_client_name === null ||
                                    data.invoicing_client_name === undefined ? (
                                    <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                                ) : (
                                    data.invoicing_client_name
                                ),
                            mailContF:
                                data.invoicing_client_email === "" ||
                                    data.invoicing_client_email === null ||
                                    data.invoicing_client_email === undefined ? (
                                    <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                                ) : (
                                    data.invoicing_client_email
                                ),
                            phoneContF:
                                data.invoicing_phone_number === "" ||
                                    data.invoicing_phone_number === null ||
                                    data.invoicing_phone_number === undefined ? (
                                    <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                                ) : (
                                    data.invoicing_phone_number
                                ),
                            supervisor:
                                data.supervisor_name === "" ||
                                    data.supervisor_name === null ||
                                    data.supervisor_name === undefined ? (
                                    <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                                ) : (
                                    data.supervisor_name
                                ),
                            manager:
                                data.manager_name === "" ||
                                    data.manager_name === null ||
                                    data.manager_name === undefined ? (
                                    <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                                ) : (
                                    data.manager_name
                                ),
                            advisor:
                                data.advisor_name === "" ||
                                    data.advisor_name === null ||
                                    data.advisor_name === undefined ? (
                                    <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                                ) : (
                                    data.advisor_name
                                ),
                            analyst:
                                data.analyst_name === "" ||
                                    data.analyst_name === null ||
                                    data.analyst_name === undefined ? (
                                    <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                                ) : (
                                    data.analyst_name
                                ),
                            analystB:
                                data.backup_analyst_name === "" ||
                                    data.backup_analyst_name === null ||
                                    data.backup_analyst_name === undefined ? (
                                    <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                                ) : (
                                    data.backup_analyst_name
                                ),
                        };
                    });
                    setclientData(resultTable);
                    controllerRef.current = null;
                });
        } else if (reportSelected === 1) {
            //Servicios
            await fetch(`/getServiceReportInfo`, {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
                signal: controllerRef.current.signal,
            })
                .then((res) => res.json())
                .then((data) => {
                    let resultTable = data.map((data, index) => {
                        return {
                            id: index,
                            franchise:
                                data.franchise_name === undefined ||
                                    data.franchise_name === null ||
                                    data.franchise_name === "" ? (
                                    <Typography>N/A</Typography>
                                ) : (
                                    data.franchise_name
                                ),
                            group:
                                data.practice_name === undefined ||
                                    data.practice_name === null ||
                                    data.practice_name === "" ? (
                                    <Typography>N/A</Typography>
                                ) : (
                                    data.practice_name
                                ),
                            clientRS:
                                data.company_name === undefined ||
                                    data.company_name === null ||
                                    data.company_name === "" ? (
                                    <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                                ) : (
                                    data.company_name
                                ),
                            statusSMB:
                                data.statusSMB === undefined || data.statusSMB === null || data.statusSMB === "" ? (
                                    <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                                ) : (
                                    t(`dynamicLabels.${data.statusSMB.toLowerCase()}`)
                                ),
                            service:
                                data.service_name === undefined ||
                                    data.service_name === null ||
                                    data.service_name === "" ? (
                                    <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                                ) : (
                                    data.service_name
                                ),
                            project:
                                data.project_name === undefined ||
                                    data.project_name === null ||
                                    data.project_name === "" ? (
                                    <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                                ) : (
                                    data.project_name
                                ),
                            projectStatus: data.project_status,
                            description: data.description,
                            billingMode:
                                data.billing_mode === undefined ||
                                    data.billing_mode === null ||
                                    data.billing_mode === "" ? (
                                    <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                                ) : (
                                    data.billing_mode
                                ),
                            paymentType:
                                data.payment_type === undefined ||
                                    data.payment_type === null ||
                                    data.payment_type === "" ? (
                                    <Typography>-</Typography>
                                ) : (
                                    data.payment_type
                                ),
                            frequency:
                                data.frequency === undefined || data.frequency === null || data.frequency === "" ? (
                                    <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                                ) : (
                                    data.frequency
                                ),
                            period:
                                data.period === undefined || data.period === null || data.period === "" ? (
                                    <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                                ) : (
                                    data.period
                                ),
                            add:
                                data.additional === undefined || data.additional === null || data.additional === "" ? (
                                    <Typography>-</Typography>
                                ) : (
                                    data.additional
                                ),
                            hours:
                                data.hours === undefined || data.hours === null || data.hours === "" ? (
                                    <Typography>-</Typography>
                                ) : (
                                    data.hours
                                ),
                            versionSoftware:
                                data.version_software === undefined ||
                                    data.version_software === null ||
                                    data.version_software === "" ? (
                                    <Typography>-</Typography>
                                ) : (
                                    data.version_software
                                ),
                            monthlyReport:
                                data.monthly_report === undefined ||
                                    data.monthly_report === null ||
                                    data.monthly_report === "" ? (
                                    <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                                ) : (
                                    data.monthly_report
                                ),
                            sinceD:
                                data.since_date !== null && data.since_date !== undefined && data.since_date !== "" ? (
                                    moment(data.since_date, "YYYY-MM-DD").format(todos.dateFormat)
                                ) : (
                                    <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                                ),
                            endD:
                                data.end_date !== null && data.end_date !== undefined && data.end_date !== "" ? (
                                    moment(data.end_date, "YYYY-MM-DD").format(todos.dateFormat)
                                ) : (
                                    <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                                ),
                            tariff:
                                data.tariff !== "N/A" &&
                                    data.tariff !== undefined &&
                                    data.tariff !== null &&
                                    data.tariff !== "" ? (
                                    parseFloat(data.tariff).toLocaleString(todos.amountFormat, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    })
                                ) : (
                                    <Typography>-</Typography>
                                ),
                            discount:
                                data.discount === undefined || data.discount === null || data.discount === "" ? (
                                    <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                                ) : (
                                    data.discount
                                ),
                            commD:
                                data.client_commitment_date !== null &&
                                    data.client_commitment_date !== undefined &&
                                    data.client_commitment_date !== "" &&
                                    data.client_commitment_date !== "N/A" ? (
                                    moment(data.client_commitment_date, "YYYY-MM-DD").format(todos.dateFormat)
                                ) : data.client_commitment_date === "N/A" ? (
                                    data.client_commitment_date
                                ) : (
                                    <Typography>-</Typography>
                                ),
                            deliDate:
                                data.client_delivery_date !== null &&
                                    data.client_delivery_date !== undefined &&
                                    data.client_delivery_date !== "" ? (
                                    moment(data.client_delivery_date, "YYYY-MM-DD").format(todos.dateFormat)
                                ) : (
                                    <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                                ),
                        };
                    });
                    setclientData(resultTable);
                    controllerRef.current = null;
                });
        } else {
            //Contactos
            await fetch(`/getContactReportInfo`, {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
                signal: controllerRef.current.signal,
            })
                .then((res) => res.json())
                .then((data) => {
                    let resultTable = data.map((data, index) => {
                        return {
                            id: index,
                            clientRS: data.company_name,
                            contact: data.contact_name,
                            mail: data.contact_email,
                            rol: data.contact_role,
                            require: data.contact_requirements ? t("miscellaneous.yes") : t("miscellaneous.no"),
                            accClose: data.contact_closure ? t("miscellaneous.yes") : t("miscellaneous.no"),
                            status: data.contact_status ? t("miscellaneous.yes") : t("miscellaneous.no"),
                            invoices: data.contact_invoices ? t("miscellaneous.yes") : t("miscellaneous.no"),
                        };
                    });
                    setclientData(resultTable);
                    controllerRef.current = null;
                });
        }
    };

    /* Component events */
    useEffect(() => {
        (async () => {
            let columnsTemplate = [
                { name: "clientRS", title: t("miscellaneous.businessName") },
                { name: "franq", title: t("miscellaneous.franchise") },
                { name: "group", title: t("miscellaneous.group") },
                { name: "clientNC", title: t("miscellaneous.tradeName") },
                { name: "typeClient", title: t("miscellaneous.type2") },
                { name: "clasi", title: t("miscellaneous.classification") },
                { name: "country", title: t("miscellaneous.country") },
                { name: "market", title: t("generalDashboard.market") },
                { name: "dateBSA", title: t("miscellaneous.firmDate") },
                { name: "dateInit", title: t("principal.initDate") },
                { name: "dateEnd", title: t("principal.endDate") },
                { name: "econoAct", title: t("miscellaneous.economicActivity") },
                { name: "dateFiscal", title: t("miscellaneous.endDateF") },
                { name: "status", title: t("miscellaneous.status") },
                { name: "comunE", title: t("miscellaneous.writtenCommAbbrev") },
                { name: "comunV", title: t("miscellaneous.verbalCommAbbrev") },
                { name: "expenseE", title: t("miscellaneous.expenseEstimate") },
                { name: "expenseA", title: t("miscellaneous.averageExpenses") },
                { name: "expenseS", title: t("miscellaneous.expenseScale") },
                { name: "factServ", title: t("miscellaneous.invoicingServices") },
                { name: "ambassador", title: t("proposals.ambassador") },
                { name: "invoicingSub", title: t("invoicing.subsInv") },
                { name: "nameFact", title: t("miscellaneous.invoicingNameGCA") },
                { name: "nameContF", title: t("miscellaneous.invoicingContactName") },
                { name: "mailContF", title: t("miscellaneous.invoicingContactEmail") },
                { name: "phoneContF", title: t("miscellaneous.phoneContF") },
                { name: "supervisor", title: t("miscellaneous.supervisor") },
                { name: "manager", title: t("miscellaneous.manager") },
                { name: "advisor", title: t("miscellaneous.advisor") },
                { name: "analyst", title: t("miscellaneous.analyst") },
                { name: "analystB", title: t("miscellaneous.backupAnalyst") },
                { name: "provider", title: t("miscellaneous.supplier") },
                { name: "susName", title: t("miscellaneous.subscriptionName") },
                { name: "currency", title: t("services.functionalCurrency") },
                { name: "userAcc", title: t("miscellaneous.accessUser") },
                { name: "userPass", title: t("miscellaneous.accessPassword") },
            ];

            const columnsTemplate2 = [
                { name: "franchise", title: t("miscellaneous.franchise") },
                { name: "group", title: t("miscellaneous.group") },
                { name: "clientRS", title: t("miscellaneous.businessName") },
                { name: "statusSMB", title: t("miscellaneous.statusSMB") },
                { name: "service", title: t("miscellaneous.service") },
                { name: "project", title: t("miscellaneous.project") },
                { name: "projectStatus", title: t("miscellaneous.projectStatus") },
                { name: "description", title: t("miscellaneous.description") },
                { name: "billingMode", title: t("miscellaneous.billingMode") },
                { name: "paymentType", title: t("miscellaneous.paymentType") },
                { name: "frequency", title: t("miscellaneous.frequency") },
                { name: "period", title: t("miscellaneous.projectPeriod") },
                { name: "add", title: t("services.additionals") },
                { name: "hours", title: t("miscellaneous.hoursUsers") },
                { name: "sinceD", title: t("reports.initDate") },
                { name: "endD", title: t("reports.endDate") },
                { name: "versionSoftware", title: t("miscellaneous.versionSoftware") },
                { name: "monthlyReport", title: t("miscellaneous.monthlyReport") },
                { name: "commD", title: t("services.estimDeliveryDate") },
                { name: "deliDate", title: t("services.deliveryDate") },
                { name: "discount", title: t("miscellaneous.discount") },
                { name: "tariff", title: t("services.fee") },
            ];

            const columnsTemplate3 = [
                { name: "clientRS", title: t("miscellaneous.businessName") },
                { name: "contact", title: t("miscellaneous.contact") },
                { name: "mail", title: t("miscellaneous.email") },
                { name: "rol", title: t("miscellaneous.role") },
                { name: "require", title: t("principal.requirements") },
                { name: "accClose", title: t("generalDashboard.accountingClosures") },
                { name: "status", title: t("miscellaneous.status") },
                { name: "invoices", title: t("miscellaneous.invoicing") },
            ];

            if (reportSelected === 0) {
                setclientData(rowsSkeleton);
                setColumnsDefs(columnsTemplate);
                setColumnsExt(tableColumnExtensions);
            } else if (reportSelected === 1) {
                setclientData(rowsSkeleton2);
                setColumnsDefs(columnsTemplate2);
                setColumnsExt(tableColumnExtensions2);
            } else {
                setclientData(rowsSkeleton3);
                setColumnsDefs(columnsTemplate3);
                setColumnsExt(tableColumnExtensions3);
            }

            await loadInitData();
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t, todos.dateFormat, todos.amountFormat, reportSelected]);

    return (
        <StyledContainer component="main" sx={{ flexGrow: 1, width: "100%" }}>
            <GridUI container justifyContent="left" alignItems="center">
                <GridUI item xs={12} sm={6} md={2} sx={{ mb: { sm: -5 }, zIndex: 1 }}>
                    <FormControl fullWidth>
                        <InputLabel shrink id="type-simple-select-label">
                            {t("invoicing.reportType")}
                        </InputLabel>
                        <Select
                            size="small"
                            IconComponent={KeyboardArrowDownIcon}
                            value={reportSelected}
                            onChange={handleChangeReportType}
                            input={<OutlinedInput notched label={t("invoicing.reportType")} />}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        mt: 0.7,
                                        borderRadius: 2,
                                        boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                    },
                                },
                            }}
                        >
                            {reportTypeList.map((report) => (
                                <MenuItem value={report.id}>{report.data}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </GridUI>
                <GridUI item xs={12}>
                    <Grid rows={clientData} columns={columnDefs}>
                        <SearchState value={searchValue} onValueChange={handleSearch} />
                        <IntegratedFiltering />
                        <PagingState defaultCurrentPage={0} pageSize={10} />
                        <IntegratedPaging />
                        <Table
                            tableComponent={TableComponent}
                            columnExtensions={columnExt}
                            cellComponent={TableCellComponent}
                            messages={tableMessages}
                        />
                        <TableHeaderRow cellComponent={tableHeaderCell} contentComponent={tableContentComponent} />
                        <Toolbar rootComponent={toolRootComponent} />
                        <SearchPanel messages={searchMessages} inputComponent={inputComponent} />
                        <PagingPanel containerComponent={PagingPanelContainer} messages={pagingMessages} />
                    </Grid>
                </GridUI>
            </GridUI>
        </StyledContainer>
    );
};

export default MainData;
