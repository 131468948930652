/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { NumericFormat } from "react-number-format";
import PropTypes from "prop-types";
import moment from "moment";

/** MUI icons */
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

/** MUI imports */
import { styled } from "@mui/material/styles";
import {
    Box,
    Breadcrumbs,
    Button,
    Card,
    CardContent,
    FormControl,
    Grid,
    Hidden,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Skeleton,
    Stack,
    Tab,
    Tabs,
    Typography,
} from "@mui/material";

/** Components imports */
import Backdrop from "../Backdrop";
import DialogAlerts from "../DialogAlerts";
import ExchangeRateDialog from "../Dialog";
import InInvoiced from "./InInvoiced";
import InPendings from "./InPendings";
import InPendingsSkeletons from "./InPendingsSkeletons";
import InVoided from "./InVoided";
import SnackBar from "../SnackBar";
import InDeleted from "./InDeleted";
import VoidFormInv from "./VoidFormInv";

const PREFIX = "Invoicing";

const classes = {
    cardContentRoot: `${PREFIX}-cardContentRoot`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.cardContentRoot}`]: {
        "&:last-child": {
            paddingBottom: "18px",
        },
        padding: 30,
    },
}));

const NumberFormatCustom = (props) => {
    const todos = useSelector((state) => state.todos);

    const { inputRef, value, onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            value={value}
            getInputRef={inputRef}
            onValueChange={({ value: v }) => onChange({ target: { value: v } })}
            thousandSeparator={todos.amountFormat === "de-DE" ? "." : ","}
            decimalSeparator={todos.amountFormat === "de-DE" ? "," : "."}
            fixedDecimalScale={true}
            decimalScale={2}
            valueIsNumericString
        />
    );
};

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const CustomTab = styled(Tab)(({ theme }) => ({
    padding: 20,
    marginLeft: 8,
    borderRadius: "20px 20px 0 0",
    backgroundColor: "#E9EAE8",
    color: theme.palette.primary.main,
    fontWeight: 700,
    "&.Mui-selected": {
        borderTop: "2px solid #ff5968",
        borderRight: "2px solid #ff5968",
        borderLeft: "2px solid #ff5968",
        backgroundColor: "#fff !important",
    },
}));

const Invoicing = () => {
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    /** React redux */
    const todos = useSelector((state) => state.todos);

    /** Global variables */
    const team = [
        "kgarcia@gca.digital",
        "dloyo@gca.digital",
        "galbani@gca.digital",
        "jalvarez@gca.digital",
        "btorres@gca.digital",
        "jrojas@gca.digital",
        "atorrealba@gca.digital",
        "murbaneja@gca.digital",
        "mmedina@gca.digital",
        "yrosas@gca.digital",
        "dgoncalves@gca.digital",
    ];

    /** Component states */
    const [showBackdrop, setBackDrop] = useState(false);
    const [loading, showLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [texto, setTexto] = useState("");
    const [alertType, setAutoAlertType] = useState("");
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [dialogText, setDialogText] = useState("");
    const [dialogType, setDialogType] = useState("info");
    const [valueTab, setValueTab] = useState(0);
    const [pendingsData, setPendingsData] = useState([]);
    const [invoicedData, setInvoicedData] = useState([]);
    const [selectionPendings, setSelectionPendings] = useState([]);
    const [selectionInvoiced, setSelectionInvoiced] = useState([]);
    const [selectionSF, setSelectionSF] = useState([]);
    const [lineItemsSelected, setLineItemsSelected] = useState([]);
    const [currencyRate, setCurrencyRate] = useState("");
    const [documentType, setDocumentType] = useState(0);
    const [openModInvoice, setModInvoice] = useState(false);
    const [openDeleteMess, setOpenDeleteMess] = useState(false);
    const [reloadData, setReloadData] = useState(false);
    const [isSelected, setIsSelected] = useState(false);
    const [isError, setIsError] = useState(false);
    const [franqData, setFranqData] = useState([]);
    const [groupData, setGroupData] = useState([]);
    const [deletedData, setDeletedData] = useState([]);
    const [selectionDeleted, setSelectionDeleted] = useState([]);
    const [openDialogDeletePermanently, setOpenDialogDeletePermanently] = useState(false);
    const [openDialogRetrieve, setOpenDialogRetrieve] = useState(false);
    const [notSameContactSubs, setNotSameContactSubs] = useState(false);

    /** Global variables */
    const breadcrumbs = [
        <Typography
            key="1"
            variant="h1"
            color="primary"
            sx={{
                fontSize: 24,
                fontWeight: 700,
            }}
        >
            {t("miscellaneous.invoicing")}
        </Typography>,
    ];

    /** Component functions */
    const handleChangeTab = async (event, newValue) => {
        setBackDrop(true);
        setSelectionPendings([]);
        setIsSelected(false);
        setSelectionInvoiced([]);
        setSelectionDeleted([]);
        setValueTab(newValue);
        setBackDrop(false);
        setNotSameContactSubs(false);
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setShow(false);
    };

    const handleOpenAlertInvoiced = () => {
        setDocumentType(invoicedData[selectionInvoiced[0]].hiddenDocumentType);

        setDialogText(`Anular factura ${invoicedData[selectionInvoiced[0]].hiddenInvoice}`);

        setOpen2(true);
    };

    const handleOpenCurrencyRateDialog = () => {
        setOpen(true);
    };

    const handleCloseCurrencyRateDialog = () => {
        setCurrencyRate("");
        setIsError(false);
        setOpen(false);
    };

    const handleChangeCurrencyRate = (event) => {
        setCurrencyRate(event.target.value);
    };

    const handleCloseAlert = () => {
        setDialogText("");
        setDialogType("");
        setOpenDeleteMess(false);
        setOpenDialogDeletePermanently(false);
        setOpenDialogRetrieve(false);
        setSelectionPendings([]);
        setSelectionDeleted([]);
        setIsSelected(false);
    };

    const handleCloseVoid = () => {
        setDocumentType(0);
        setDialogText("");
        setOpen2(false);
    };

    const deletePendings = () => {
        let arrayInvoicing = [];

        selectionPendings.forEach((selectedRow) => {
            arrayInvoicing.push(pendingsData[selectedRow].id);
        });

        setLineItemsSelected(arrayInvoicing);
        if (arrayInvoicing.length === 1) {
            setDialogText(t("miscellaneous.sureDelete1Single"));
        } else {
            setDialogText(t("miscellaneous.sureDelete1") + arrayInvoicing.length + t("miscellaneous.sureDelete1part2"));
        }

        setDialogType("info");
        setOpenDeleteMess(true);
    };

    const deletePermanently = () => {
        let arrayInvoicing = [];

        selectionDeleted.forEach((selectedRow) => {
            arrayInvoicing.push(deletedData[selectedRow].id);
        });

        setLineItemsSelected(arrayInvoicing);
        if (arrayInvoicing.length === 1) {
            setDialogText(t("miscellaneous.sureDelete1Single"));
        } else {
            setDialogText(t("miscellaneous.sureDelete1") + arrayInvoicing.length + t("miscellaneous.sureDelete1part2"));
        }

        setDialogType("info");
        setOpenDialogDeletePermanently(true);
    };

    const retrieveInvoice = () => {
        let arrayInvoicing = [];

        selectionDeleted.forEach((selectedRow) => {
            arrayInvoicing.push(deletedData[selectedRow].id);
        });

        setLineItemsSelected(arrayInvoicing);
        if (arrayInvoicing.length === 1) {
            setDialogText(t("miscellaneous.sureRetrieve1Single"));
        } else {
            setDialogText(
                t("miscellaneous.sureRetrieve1") + arrayInvoicing.length + t("miscellaneous.sureDelete1part2")
            );
        }

        setDialogType("info");
        setOpenDialogRetrieve(true);
    };

    const reportPendings = async () => {
        setBackDrop(true);

        let filename =
            valueTab !== 3
                ? "Plataforma GCA - Líneas de facturación pendientes.xlsx"
                : "Plataforma GCA - Líneas de facturación borradas.xlsx";

        // Se determina a que url se hará la petición (con o sin query) para traer solo los eliminados en la pestaña de borrados
        let url = valueTab === 3 ? `/generatePendingLineItemsReport?deleted=true` : "/generatePendingLineItemsReport";

        await fetch(url, {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then((res) => res.blob())
            .then((blob) => {
                const link = document.createElement("a");
                link.href = URL.createObjectURL(blob);
                link.setAttribute("download", filename);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch((err) => {
                setTexto(t("reports.donwloadingReptError"));
                setAutoAlertType("warning");
                setShow(true);
            });

        setBackDrop(false);
    };

    const invoicePendings = () => {
        let arrayInvoicing = [];
        let pivot = pendingsData[selectionPendings[0]].contact;
        let pivot2 = pendingsData[selectionPendings[0]].hiddenDocumentType;
        let pivot3 = pendingsData[selectionPendings[0]].hiddenInvSubs;

        selectionPendings.forEach((selectedRow) => {
            if (
                pendingsData[selectedRow].contact === pivot &&
                pendingsData[selectedRow].hiddenDocumentType === pivot2 &&
                pendingsData[selectedRow].hiddenInvSubs === pivot3
            )
                arrayInvoicing.push({
                    id: pendingsData[selectedRow].id,
                    franchiseId: pendingsData[selectedRow].hiddenFranchiseId,
                    practiceId: pendingsData[selectedRow].hiddenPracticeId,
                    companyId: pendingsData[selectedRow].companyId,
                    contact: pendingsData[selectedRow].contact,
                    smb: pendingsData[selectedRow].smb,
                    description: pendingsData[selectedRow].hiddenDescription,
                    service: pendingsData[selectedRow].hiddenService,
                    project: pendingsData[selectedRow].hiddenProject,
                    project_type: pendingsData[selectedRow].hiddenProjectType,
                    initDate: pendingsData[selectedRow].hiddenInitDate,
                    endDate: pendingsData[selectedRow].hiddenEndDate,
                    period: pendingsData[selectedRow].period,
                    initial: pendingsData[selectedRow].initial,
                    amount: pendingsData[selectedRow].hiddenAmount,
                    documentType: pendingsData[selectedRow].hiddenDocumentType,
                    serviceCode: pendingsData[selectedRow].serviceCode,
                    market: pendingsData[selectedRow].hiddenCountry,
                    invoicing_subscription: pendingsData[selectedRow].hiddenInvSubs,
                });
        });

        setLineItemsSelected(arrayInvoicing);
        setModInvoice(true);
        setSelectionPendings([]);
        setSelectionDeleted([]);
        setIsSelected(false);
    };

    const invoiceDelete = () => {
        let arrayInvoicing = [];
        let pivot = deletedData[selectionPendings[0]].contact;
        let pivot2 = deletedData[selectionPendings[0]].hiddenDocumentType;
        let pivot3 = deletedData[selectionPendings[0]].hiddenCountry;

        selectionPendings.forEach((selectedRow) => {
            if (
                deletedData[selectedRow].contact === pivot &&
                deletedData[selectedRow].hiddenDocumentType === pivot2 &&
                deletedData[selectedRow].hiddenCountry === pivot3
            )
                arrayInvoicing.push({
                    id: deletedData[selectedRow].id,
                    franchiseId: deletedData[selectedRow].hiddenFranchiseId,
                    practiceId: deletedData[selectedRow].hiddenPracticeId,
                    companyId: deletedData[selectedRow].companyId,
                    contact: deletedData[selectedRow].contact,
                    smb: deletedData[selectedRow].smb,
                    description: deletedData[selectedRow].hiddenDescription,
                    service: deletedData[selectedRow].hiddenService,
                    project: deletedData[selectedRow].hiddenProject,
                    project_type: deletedData[selectedRow].hiddenProjectType,
                    initDate: deletedData[selectedRow].hiddenInitDate,
                    endDate: deletedData[selectedRow].hiddenEndDate,
                    period: deletedData[selectedRow].period,
                    initial: deletedData[selectedRow].initial,
                    amount: deletedData[selectedRow].hiddenAmount,
                    documentType: deletedData[selectedRow].hiddenDocumentType,
                    serviceCode: deletedData[selectedRow].serviceCode,
                    market: deletedData[selectedRow].hiddenCountry,
                    removedBy: deletedData[selectedRow].deleted_user,
                });
        });

        setLineItemsSelected(arrayInvoicing);
        setModInvoice(true);
        setSelectionPendings([]);
        setSelectionDeleted([]);
        setIsSelected(false);
    };

    const deleteInvoices = async () => {
        setBackDrop(true);

        const currentDate = moment();
        const params = {
            invoice_ids: lineItemsSelected,
            deletedDate: currentDate.format("YYYY-MM-DD HH:mm:ss"),
            deletePermanently: false,
        };

        await fetch(`/deleteLineItem`, {
            method: "POST",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
            body: JSON.stringify(params),
        })
            .then((response) => {
                if (response.ok) {
                    handleCloseAlert();
                    setTexto(t("miscellaneous.deleteRegInv"));
                    setAutoAlertType("success");
                    setShow(true);
                    setReloadData(!reloadData);
                } else {
                    setTexto(t("miscellaneous.deleteRegInvErr"));
                    setAutoAlertType("error");
                    setShow(true);
                }
            })
            .catch((error) => {
                console.log(error);
            });

        setSelectionPendings([]);
        setSelectionDeleted([]);
        setIsSelected(false);
        setBackDrop(false);
    };

    const deleteInvoicesPermanently = async (automate, ids) => {
        let varAutomate = automate === true ? true : false;

        !varAutomate && setBackDrop(true);

        const currentDate = moment();
        const params = {
            invoice_ids: varAutomate && ids ? ids : lineItemsSelected,
            deletedDate: currentDate.format("YYYY-MM-DD"),
            deletePermanently: true,
            automate: varAutomate,
        };

        await fetch(`/deleteLineItem`, {
            method: "POST",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
            body: JSON.stringify(params),
        })
            .then((response) => {
                if (!varAutomate) {
                    if (response.ok) {
                        handleCloseAlert();
                        setTexto(t("miscellaneous.deleteRegInv"));
                        setAutoAlertType("success");
                        setShow(true);
                        setReloadData(!reloadData);
                    } else {
                        setTexto(t("miscellaneous.deleteRegInvErr"));
                        setAutoAlertType("error");
                        setShow(true);
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });

        setSelectionPendings([]);
        setSelectionDeleted([]);
        setIsSelected(false);
        setBackDrop(false);
    };

    const retrieveInvoices = async () => {
        setBackDrop(true);

        const params = {
            invoice_ids: lineItemsSelected,
        };

        await fetch(`/retrieveLineItem`, {
            method: "POST",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
            body: JSON.stringify(params),
        })
            .then((response) => {
                if (response.ok) {
                    handleCloseAlert();
                    setTexto(t("miscellaneous.retrieveRegInv"));
                    setAutoAlertType("success");
                    setShow(true);
                    setReloadData(!reloadData);
                } else {
                    setTexto(t("miscellaneous.retrieveRegInvErr"));
                    setAutoAlertType("error");
                    setShow(true);
                }
            })
            .catch((error) => {
                console.log(error);
            });
            
        setSelectionPendings([]);
        setSelectionDeleted([]);
        setIsSelected(false);
        setBackDrop(false);
    };

    const invoiceSF = async () => {
        setBackDrop(true);

        if (!!currencyRate) {
            const formData = new FormData();
            formData.append("invoice_id", invoicedData[selectionSF[0]].id);
            formData.append("currency_rate", parseFloat(currencyRate));

            await fetch("/generateInvoicesXero", {
                signal: AbortSignal.timeout(29000),
                method: "POST",
                body: formData,
            })
                .then((response) => response.text())
                .then((data) => {
                    handleCloseCurrencyRateDialog();
                    if (data) {
                        setTexto(t("reports.successInvNorm") + data);
                        setAutoAlertType("success");
                        setShow(true);
                        setSelectionInvoiced([]);
                        setReloadData(!reloadData);
                    } else {
                        setTexto(t("invoicing.errorInvoice"));
                        setAutoAlertType("error");
                        setShow(true);
                    }
                })
                .catch((error) => {
                    if (error.name === "AbortError") {
                        setTexto(t("invoicing.errorInvoice"));
                        setAutoAlertType("error");
                        setShow(true);
                    } else {
                        throw error;
                    }
                });
        } else {
            setIsError(true);
            setTexto(t("miscellaneous.requiredData"));
            setAutoAlertType("warning");
            setShow(true);
        }

        setBackDrop(false);
    };

    useEffect(() => {
        (async () => {
            showLoading(true);
            await fetch(`/getClientFranchiseFilter`, {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
                .then((res) => res.json())
                .then((data) => {
                    setFranqData(data);
                });

            await fetch(`/getClientGroupsFilter`, {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
                .then((res) => res.json())
                .then((data) => {
                    setGroupData(data);
                });
            showLoading(false);
        })();
    }, []);

    const dialogBody = (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <FormControl fullWidth required variant="outlined" error={isError && !currencyRate ? true : false}>
                    <InputLabel htmlFor="outlined-adornment-currencyRate">{t("invoicing.currencyRate")}</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-currencyRate"
                        value={currencyRate}
                        onChange={handleChangeCurrencyRate}
                        inputComponent={NumberFormatCustom}
                        startAdornment={
                            <InputAdornment disableTypography position="start">
                                1 USD =
                            </InputAdornment>
                        }
                        label={t("invoicing.currencyRate")}
                    />
                </FormControl>
            </Grid>
        </Grid>
    );

    const dialogButtons = (
        <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
            <Grid item>
                <Button
                    onClick={handleCloseCurrencyRateDialog}
                    sx={{
                        pl: 3,
                        pr: 3,
                        border: "1px solid #ff5968",
                        borderRadius: 40,
                        "&:hover": {
                            border: "1px solid #2f3190",
                            color: "#2f3190",
                        },
                        "&.Mui-disabled": {
                            color: "#ffcdd2",
                            borderColor: "#ffcdd2",
                            background: "transparent",
                        },
                    }}
                    color="secondary"
                    disableElevation
                    variant="outlined"
                >
                    {t("miscellaneous.cancel")}
                </Button>
            </Grid>
            <Grid item>
                <Button
                    onClick={invoiceSF}
                    sx={{
                        pl: 3,
                        pr: 3,
                        borderRadius: 40,
                        "&:hover": {
                            backgroundColor: "#2f3190",
                            color: "#fff",
                        },
                        "&.Mui-disabled": {
                            color: "#fff",
                            borderColor: "#ffcdd2",
                            background: "#ffcdd2",
                        },
                    }}
                    color="secondary"
                    disableElevation
                    variant="contained"
                >
                    {t("miscellaneous.invoice2")}
                </Button>
            </Grid>
        </Grid>
    );

    return (
        <StyledContainer component="main" sx={{ flexGrow: 1, p: 4, width: "95%", pt: 10 }}>
            <Stack sx={{ pb: 4 }}>
                <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small" sx={{ color: "#031851" }} />}
                    aria-label="breadcrumb"
                >
                    {breadcrumbs}
                </Breadcrumbs>
            </Stack>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card
                        elevation={0}
                        sx={{
                            borderRadius: 8,
                            boxShadow: "10px 10px 15px rgba(3,24,81,0.15)",
                        }}
                    >
                        <CardContent classes={{ root: classes.cardContentRoot }}>
                            <Box sx={{ width: "100%" }}>
                                <Box sx={{ borderBottom: 2, borderColor: "#031851" }}>
                                    <Grid container alignItems="center">
                                        <Grid item xs={12} md={7}>
                                            <Tabs
                                                value={valueTab}
                                                onChange={handleChangeTab}
                                                variant="scrollable"
                                                scrollButtons="auto"
                                                aria-label="bookkeper tabs"
                                                TabIndicatorProps={{
                                                    sx: {
                                                        display: "none",
                                                    },
                                                }}
                                            >
                                                <CustomTab label={t("miscellaneous.pendings")} {...a11yProps(0)} />
                                                <CustomTab label={t("invoicing.invoiced")} {...a11yProps(1)} />
                                                <CustomTab label={t("bills.nulled")} {...a11yProps(2)} />
                                                <CustomTab label={t("miscellaneous.deleted")} {...a11yProps(3)} />
                                            </Tabs>
                                        </Grid>
                                        {valueTab === 0 ? (
                                            loading || franqData.length === 0 ? (
                                                <Hidden only={["xs", "sm"]}>
                                                    <Grid
                                                        item
                                                        container
                                                        xs={12}
                                                        md={5}
                                                        justifyContent="flex-end"
                                                        spacing={2}
                                                    >
                                                        <Grid item>
                                                            <Skeleton
                                                                variant="rectangular"
                                                                animation="wave"
                                                                width={112}
                                                                height={40}
                                                                sx={{ borderRadius: 8 }}
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            <Skeleton
                                                                variant="rectangular"
                                                                animation="wave"
                                                                width={112}
                                                                height={40}
                                                                sx={{ borderRadius: 8 }}
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            <Skeleton
                                                                variant="rectangular"
                                                                animation="wave"
                                                                width={112}
                                                                height={40}
                                                                sx={{ borderRadius: 8 }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Hidden>
                                            ) : (
                                                <Hidden only={["xs", "sm"]}>
                                                    <Grid
                                                        item
                                                        container
                                                        xs={12}
                                                        md={5}
                                                        justifyContent="flex-end"
                                                        spacing={2}
                                                    >
                                                        <Grid item>
                                                            <Button
                                                                disableElevation
                                                                variant="outlined"
                                                                color="secondary"
                                                                onClick={reportPendings}
                                                                sx={{
                                                                    pl: 3,
                                                                    pr: 3,
                                                                    border: "1px solid #ff5968",
                                                                    borderRadius: 40,
                                                                    "&:hover": {
                                                                        border: "1px solid #2f3190",
                                                                        color: "#2f3190",
                                                                    },
                                                                    "&.Mui-disabled": {
                                                                        color: "#ffcdd2",
                                                                        borderColor: "#ffcdd2",
                                                                        background: "transparent",
                                                                    },
                                                                }}
                                                            >
                                                                {t("proposals.export")}
                                                            </Button>
                                                        </Grid>
                                                        {team.includes(todos.userInfo.email) && (
                                                            <Grid item>
                                                                <Button
                                                                    disableElevation
                                                                    variant="outlined"
                                                                    color="secondary"
                                                                    onClick={deletePendings}
                                                                    disabled={selectionPendings.length === 0}
                                                                    sx={{
                                                                        pl: 3,
                                                                        pr: 3,
                                                                        border: "1px solid #ff5968",
                                                                        borderRadius: 40,
                                                                        "&:hover": {
                                                                            border: "1px solid #2f3190",
                                                                            color: "#2f3190",
                                                                        },
                                                                        "&.Mui-disabled": {
                                                                            color: "#ffcdd2",
                                                                            borderColor: "#ffcdd2",
                                                                            background: "transparent",
                                                                        },
                                                                    }}
                                                                >
                                                                    {t("miscellaneous.clear")}
                                                                </Button>
                                                            </Grid>
                                                        )}
                                                        <Grid item>
                                                            <Button
                                                                disableElevation
                                                                variant="contained"
                                                                color="secondary"
                                                                onClick={invoicePendings}
                                                                disabled={
                                                                    selectionPendings.length === 0 || notSameContactSubs
                                                                }
                                                                sx={{
                                                                    pl: 3,
                                                                    pr: 3,
                                                                    borderRadius: 40,
                                                                    "&:hover": {
                                                                        backgroundColor: "#2f3190",
                                                                        color: "#fff",
                                                                    },
                                                                    "&.Mui-disabled": {
                                                                        color: "#fff",
                                                                        borderColor: "#ffcdd2",
                                                                        background: "#ffcdd2",
                                                                    },
                                                                }}
                                                            >
                                                                {t("miscellaneous.invoice2")}
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Hidden>
                                            )
                                        ) : valueTab === 1 ? (
                                            loading ? (
                                                <Hidden only={["xs", "sm"]}>
                                                    <Grid
                                                        item
                                                        container
                                                        xs={12}
                                                        md={5}
                                                        justifyContent="flex-end"
                                                        spacing={2}
                                                    >
                                                        <Grid item>
                                                            <Skeleton
                                                                variant="rectangular"
                                                                animation="wave"
                                                                width={112}
                                                                height={40}
                                                                sx={{ borderRadius: 8 }}
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            <Skeleton
                                                                variant="rectangular"
                                                                animation="wave"
                                                                width={112}
                                                                height={40}
                                                                sx={{ borderRadius: 8 }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Hidden>
                                            ) : (
                                                <Hidden only={["xs", "sm"]}>
                                                    <Grid
                                                        item
                                                        container
                                                        xs={12}
                                                        md={5}
                                                        justifyContent="flex-end"
                                                        spacing={2}
                                                    >
                                                        <Grid item>
                                                            <Button
                                                                disableElevation
                                                                variant="outlined"
                                                                color="secondary"
                                                                onClick={handleOpenAlertInvoiced}
                                                                disabled={selectionInvoiced.length === 0}
                                                                sx={{
                                                                    pl: 3,
                                                                    pr: 3,
                                                                    border: "1px solid #ff5968",
                                                                    borderRadius: 40,
                                                                    "&:hover": {
                                                                        border: "1px solid #2f3190",
                                                                        color: "#2f3190",
                                                                    },
                                                                    "&.Mui-disabled": {
                                                                        color: "#ffcdd2",
                                                                        borderColor: "#ffcdd2",
                                                                        background: "transparent",
                                                                    },
                                                                }}
                                                            >
                                                                {t("invoicing.cancel")}
                                                            </Button>
                                                        </Grid>
                                                        <Grid item>
                                                            <Button
                                                                disableElevation
                                                                variant="contained"
                                                                color="secondary"
                                                                onClick={handleOpenCurrencyRateDialog}
                                                                disabled={selectionSF.length === 0}
                                                                sx={{
                                                                    pl: 3,
                                                                    pr: 3,
                                                                    borderRadius: 40,
                                                                    "&:hover": {
                                                                        backgroundColor: "#2f3190",
                                                                        color: "#fff",
                                                                    },
                                                                    "&.Mui-disabled": {
                                                                        color: "#fff",
                                                                        borderColor: "#ffcdd2",
                                                                        background: "#ffcdd2",
                                                                    },
                                                                }}
                                                            >
                                                                {t("miscellaneous.invoice2")}
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Hidden>
                                            )
                                        ) : valueTab === 3 ? (
                                            loading ? (
                                                <Hidden only={["xs", "sm"]}>
                                                    <Grid
                                                        item
                                                        container
                                                        xs={12}
                                                        md={5}
                                                        justifyContent="flex-end"
                                                        spacing={2}
                                                    >
                                                        <Grid item>
                                                            <Skeleton
                                                                variant="rectangular"
                                                                animation="wave"
                                                                width={112}
                                                                height={40}
                                                                sx={{ borderRadius: 8 }}
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            <Skeleton
                                                                variant="rectangular"
                                                                animation="wave"
                                                                width={112}
                                                                height={40}
                                                                sx={{ borderRadius: 8 }}
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            <Skeleton
                                                                variant="rectangular"
                                                                animation="wave"
                                                                width={112}
                                                                height={40}
                                                                sx={{ borderRadius: 8 }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Hidden>
                                            ) : (
                                                <Hidden only={["xs", "sm"]}>
                                                    <Grid
                                                        item
                                                        container
                                                        xs={12}
                                                        md={5}
                                                        justifyContent="flex-end"
                                                        spacing={2}
                                                    >
                                                        <Grid item>
                                                            <Button
                                                                disableElevation
                                                                variant="outlined"
                                                                color="secondary"
                                                                onClick={reportPendings}
                                                                sx={{
                                                                    pl: 3,
                                                                    pr: 3,
                                                                    border: "1px solid #ff5968",
                                                                    borderRadius: 40,
                                                                    "&:hover": {
                                                                        border: "1px solid #2f3190",
                                                                        color: "#2f3190",
                                                                    },
                                                                    "&.Mui-disabled": {
                                                                        color: "#ffcdd2",
                                                                        borderColor: "#ffcdd2",
                                                                        background: "transparent",
                                                                    },
                                                                }}
                                                            >
                                                                {t("proposals.export")}
                                                            </Button>
                                                        </Grid>
                                                        {team.includes(todos.userInfo.email) && (
                                                            <Grid item>
                                                                <Button
                                                                    disableElevation
                                                                    variant="outlined"
                                                                    color="secondary"
                                                                    onClick={deletePermanently}
                                                                    disabled={selectionDeleted.length === 0}
                                                                    sx={{
                                                                        pl: 3,
                                                                        pr: 3,
                                                                        border: "1px solid #ff5968",
                                                                        borderRadius: 40,
                                                                        "&:hover": {
                                                                            border: "1px solid #2f3190",
                                                                            color: "#2f3190",
                                                                        },
                                                                        "&.Mui-disabled": {
                                                                            color: "#ffcdd2",
                                                                            borderColor: "#ffcdd2",
                                                                            background: "transparent",
                                                                        },
                                                                    }}
                                                                >
                                                                    {t("miscellaneous.clear")}
                                                                </Button>
                                                            </Grid>
                                                        )}
                                                        <Grid item>
                                                            <Button
                                                                disableElevation
                                                                variant="contained"
                                                                color="secondary"
                                                                onClick={retrieveInvoice}
                                                                disabled={
                                                                    selectionDeleted.length === 0 || notSameContactSubs
                                                                }
                                                                sx={{
                                                                    pl: 3,
                                                                    pr: 3,
                                                                    borderRadius: 40,
                                                                    "&:hover": {
                                                                        backgroundColor: "#2f3190",
                                                                        color: "#fff",
                                                                    },
                                                                    "&.Mui-disabled": {
                                                                        color: "#fff",
                                                                        borderColor: "#ffcdd2",
                                                                        background: "#ffcdd2",
                                                                    },
                                                                }}
                                                            >
                                                                {t("invoicing.retrieve")}
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Hidden>
                                            )
                                        ) : null}
                                    </Grid>
                                </Box>
                                <TabPanel value={valueTab} index={0}>
                                    {franqData.length > 0 && groupData.length > 0 && (
                                        <InPendings
                                            setBackDrop={setBackDrop}
                                            pendingsData={pendingsData}
                                            setPendingsData={setPendingsData}
                                            selectionPendings={selectionPendings}
                                            setSelectionPendings={setSelectionPendings}
                                            lineItemsSelected={lineItemsSelected}
                                            handleOpenAlertPendings={invoicePendings}
                                            openModInvoice={openModInvoice}
                                            setModInvoice={setModInvoice}
                                            isSelected={isSelected}
                                            setIsSelected={setIsSelected}
                                            reloadData={reloadData}
                                            franqData={franqData}
                                            groupData={groupData}
                                            setNotSameContactSubs={setNotSameContactSubs}
                                        />
                                    )}
                                    {(franqData.length === 0 ||
                                        groupData.length === 0 ||
                                        (franqData.length === 0 && groupData.length === 0)) && (
                                        <InPendingsSkeletons
                                            pendingsData={pendingsData}
                                            setPendingsData={setPendingsData}
                                        />
                                    )}
                                </TabPanel>
                                <TabPanel value={valueTab} index={1}>
                                    {franqData.length > 0 && groupData.length > 0 && (
                                        <InInvoiced
                                            setBackDrop={setBackDrop}
                                            invoicedData={invoicedData}
                                            setInvoicedData={setInvoicedData}
                                            selectionInvoiced={selectionInvoiced}
                                            setSelectionInvoiced={setSelectionInvoiced}
                                            selectionSF={selectionSF}
                                            setSelectionSF={setSelectionSF}
                                            reloadData={reloadData}
                                            handleOpenAlertInvoiced={handleOpenAlertInvoiced}
                                            handleOpenCurrencyRateDialog={handleOpenCurrencyRateDialog}
                                            franqData={franqData}
                                            groupData={groupData}
                                        />
                                    )}
                                </TabPanel>
                                <TabPanel value={valueTab} index={2}>
                                    {franqData.length > 0 && groupData.length > 0 && (
                                        <InVoided
                                            setBackDrop={setBackDrop}
                                            franqData={franqData}
                                            groupData={groupData}
                                        />
                                    )}
                                </TabPanel>
                                <TabPanel value={valueTab} index={3}>
                                    {franqData.length > 0 && groupData.length > 0 && (
                                        <InDeleted
                                            setBackDrop={setBackDrop}
                                            pendingsData={pendingsData}
                                            lineItemsSelected={lineItemsSelected}
                                            handleOpenAlertPendings={invoiceDelete}
                                            openModInvoice={openModInvoice}
                                            setModInvoice={setModInvoice}
                                            isSelected={isSelected}
                                            setIsSelected={setIsSelected}
                                            reloadData={reloadData}
                                            franqData={franqData}
                                            groupDataProps={groupData}
                                            deletedData={deletedData}
                                            setDeletedData={setDeletedData}
                                            selectionDeleted={selectionDeleted}
                                            setSelectionDeleted={setSelectionDeleted}
                                            deleteInvoicesPermanently={deleteInvoicesPermanently}
                                        />
                                    )}
                                </TabPanel>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Backdrop open={showBackdrop} />
            <SnackBar type={alertType} show={show} message={texto} onClose={handleClose} />
            <DialogAlerts
                open={openDeleteMess}
                onClose={handleCloseAlert}
                agreeBtnLabel={t("miscellaneous.accept")}
                disagreeBtnLabel={t("miscellaneous.cancel")}
                type={dialogType}
                title={dialogText}
                agreeAction={deleteInvoices}
            />
            <DialogAlerts
                open={openDialogDeletePermanently}
                onClose={handleCloseAlert}
                agreeBtnLabel={t("miscellaneous.accept")}
                disagreeBtnLabel={t("miscellaneous.cancel")}
                type={dialogType}
                title={dialogText}
                agreeAction={deleteInvoicesPermanently}
            />
            <DialogAlerts
                open={openDialogRetrieve}
                onClose={handleCloseAlert}
                agreeBtnLabel={t("miscellaneous.accept")}
                disagreeBtnLabel={t("miscellaneous.cancel")}
                type={dialogType}
                title={dialogText}
                agreeAction={retrieveInvoices}
            />
            <ExchangeRateDialog
                open={open}
                onClose={handleCloseCurrencyRateDialog}
                message={dialogBody}
                title={t("invoicing.specifyCurrencyRate")}
                button={dialogButtons}
                maxWidth="xs"
            />
            <VoidFormInv
                open={open2}
                onClose={handleCloseVoid}
                title={dialogText}
                setBackDrop={setBackDrop}
                invoicedData={invoicedData}
                selectionInvoiced={selectionInvoiced}
                documentType={documentType}
                setTexto={setTexto}
                setAutoAlertType={setAutoAlertType}
                setShow={setShow}
                setSelectionInvoiced={setSelectionInvoiced}
                setReloadData={setReloadData}
                reloadData={reloadData}
                maxWidth="sm"
            />
        </StyledContainer>
    );
};

export default Invoicing;
