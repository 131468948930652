/** React imports */
import React from 'react';

/** MUI imports */
import { styled } from '@mui/material/styles';
import {
    Dialog,
    DialogContent,
    DialogTitle,
} from '@mui/material';

const CustomDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        [theme.breakpoints.down("sm")]: {
            padding: 30,
        },
        padding: "30px 80px 30px 80px",
        textAlign: "center"
    },
    '& .MuiDialogTitle-root': {
        fontSize: "18px",
        fontWeight: 700,
        textAlign: "center",
        marginTop: 5
    },
    '& .MuiDialog-paper': {
        borderRadius: 8
    },
}));

export default function FaqDialog(props) {
    return (
        <CustomDialog
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={props.maxWidth}
            fullWidth={true}
        >
            <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
            <DialogContent>
                {props.message}
            </DialogContent>
        </CustomDialog>
    );
}