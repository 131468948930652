/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";

/** MUI imports */
import { styled } from "@mui/material/styles";
import { Box, Chip, Grid, Typography, Divider, FormControlLabel, Checkbox } from "@mui/material";

/** Component imports */
import { Recurring, Consulting } from "./";

/** Component styles */
const PREFIX = "TaxExpertRoot";

const classes = {
    cardContentRoot: `${PREFIX}-cardContentRoot`,
    chip: `${PREFIX}-chip`,
    chipProActive: `${PREFIX}-chipProActive`,
    chipProWasActive: `${PREFIX}-chipProWasActive`,
    selectedChip: `${PREFIX}-selectedChip`,
    selectedChipActive: `${PREFIX}-selectedChipActive`,
    selectedChipWasActive: `${PREFIX}-selectedChipWasActive`,
    divider: `${PREFIX}-divider`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.cardContentRoot}`]: {
        padding: "24px 48px 48px",
    },

    [`& .${classes.chip}`]: {
        padding: 20,
        width: 200,
        backgroundColor: "#EAEAF4",
        color: "#031851",
        fontWeight: "700 !important",
        "&:hover": {
            backgroundColor: "rgba(213, 214, 233, 0.3)",
            color: "#031851",
        },
        borderRadius: 40,
    },

    [`& .${classes.chipProActive}`]: {
        padding: 20,
        width: 200,
        backgroundColor: "#EAEAF4",
        color: "#031851",
        border: "2px solid #ff5968",
        fontWeight: "700 !important",
        "&:hover": {
            backgroundColor: "rgba(213, 214, 233, 0.3)",
            color: "#031851",
        },
        borderRadius: 40,
    },

    [`& .${classes.chipProWasActive}`]: {
        padding: 20,
        width: 200,
        backgroundColor: "#EAEAF4",
        color: "#031851",
        border: "2px solid #031851",
        fontWeight: "700 !important",
        "&:hover": {
            backgroundColor: "rgba(213, 214, 233, 0.3)",
            color: "#031851",
        },
        borderRadius: 40,
    },

    [`& .${classes.selectedChip}`]: {
        padding: 20,
        width: 200,
        backgroundColor: "#2f3190",
        color: "#fff",
        fontWeight: "700 !important",
        borderRadius: 40,
        "&:hover": {
            backgroundColor: "rgba(47, 49, 144, 0.9)",
            color: "#fff",
        },
    },

    [`& .${classes.selectedChipActive}`]: {
        padding: 20,
        width: 200,
        backgroundColor: "#2f3190",
        color: "#fff",
        fontWeight: "700 !important",
        border: "2px solid #ff5968",
        borderRadius: 40,
        "&:hover": {
            backgroundColor: "rgba(47, 49, 144, 0.9)",
            color: "#fff",
        },
    },

    [`& .${classes.selectedChipWasActive}`]: {
        padding: 20,
        width: 200,
        backgroundColor: "#2f3190",
        color: "#fff",
        fontWeight: "700 !important",
        border: "2px solid #031851",
        borderRadius: 40,
        "&:hover": {
            backgroundColor: "rgba(47, 49, 144, 0.9)",
            color: "#fff",
        },
    },

    [`& .${classes.divider}`]: {
        marginBottom: 10,
    },
}));

export const TaxExpertRoot = (props) => {
    // Properties
    const {
        setTexto,
        setalertType,
        setShow,
        setBackDrop,
        actives,
        clientMarket,
        expenseEst,
        accountSystem,
        serviceData,
        companyID,
        setServiceData,
        setAuxExpanded,
        setExpanded,
        lastChipSelected,
        setLastChipSelected,
    } = props;
    const [renderState, setRenderState] = useState(false);

    /** Internationalization i18n */
    const [t] = useTranslation("global");
    

    /* Component states */
    const [valuesTaxExpert, setvaluesTaxExpert] = useState({
        taxExpertRecu: false,
        taxExpertConsul: false,
    });
    const [projectsData, setProjectsData] = useState({
        taxExpertRecu: {},
        taxExpertConsul: {},
    });
    const [active, setActive] = useState({
        taxExpertRecu: {
            isActive: false,
            wasActive: false,
        },
        taxExpertConsul: {
            isActive: false,
            wasActive: false,
        },
    });
    const [open, setOpen] = useState(false);
    const [infoAdd, setInfoAdd] = useState({});
    const [checkRemind, setCheckRemind] = useState(false);

    const handleCloseDialog = () => {
        setInfoAdd({});
        setCheckRemind(false);
        setOpen(false);
    };

    const showConfirm = (value) => {
        setInfoAdd(value);
        setOpen(true);
    };

    const handleChangeCheck = (event) => {
        setCheckRemind(event.target.checked);
    };

    const bodyMess = (
        <>
            <Typography variant="h1" color="primary" align="center" sx={{ fontSize: 18, pt: 3, pb: 2 }}>
                {t("miscellaneous.confRemoveCheckDet")}
            </Typography>
            <FormControlLabel
                control={
                    <Checkbox
                        name="checkedConf"
                        checked={checkRemind}
                        onChange={handleChangeCheck}
                        inputProps={{
                            "aria-label": "controlled",
                        }}
                    />
                }
                label={t("miscellaneous.dontShowAgain")}
            />
        </>
    );

    const getProjects = async (isReload) => {
        setBackDrop(true);

        let serviceCompID = serviceData._id ?? "";

        if (isReload) {
            await fetch(`/getNewServicesCompany?company_id=${companyID}`, {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
                .then((res) => res.json())
                .then(async (data) => {
                    setServiceData(data);

                    let lastProject = data.filter(function (o) {
                        return o.hasOwnProperty("_id") && o.service_name === "Tax Expert";
                    })[0];

                    await fetch(
                        `/getProjectsCompany?company_id=${lastProject.company_id}&service_id=${lastProject.service_id}&service_company_id=${lastProject._id}`,
                        {
                            method: "GET",
                            headers: {
                                "Content-type": "application/json; charset=UTF-8",
                                "Access-Control-Allow-Origin": "*",
                            },
                        }
                    )
                        .then((res) => res.json())
                        .then(async (data) => {
                            let result = {};
                            let lastProyects = {
                                recurring: {},
                                consulting: {},
                            };
                            let recurringisActive = false;
                            let wasRecurringActive = false;
        
                            await data.forEach((item) => {
                                switch (item.project_type) {
                                    case 17:
                                        result.taxExpertRecu = item;
                                        if (item.projects_array.length !== 0) {
                                            let indexExact = 0;
                                            let dateMax = null;
                                            item.projects_array.forEach((item, index) => {
                                                if (index === 0) {
                                                    dateMax = item.end_date;
                                                } else {
                                                    if (moment(dateMax).isBefore(item.end_date)) {
                                                        dateMax = item.end_date;
                                                        indexExact = index;
                                                    }
                                                }
                                                if (!item.client_delivery_date && item.client_commitment_date) {
                                                    recurringisActive = true;
                                                } else if (item.client_delivery_date) {
                                                    wasRecurringActive = moment(item.client_delivery_date).isSameOrBefore(
                                                        moment()
                                                    );
                                                }
                                            });
                                            lastProyects.recurring = item.projects_array[indexExact];
                                            result.taxExpertRecu.project_company_id =
                                                item.projects_array[0].project_company_id;
                                        }
                                        break;
                                    case 19:
                                        result.taxExpertConsul = item;
                                        if (item.projects_array.length !== 0) {
                                            lastProyects.consulting = item.projects_array[0];
                                            result.taxExpertConsul.project_company_id = item.projects_array[0].project_company_id;
                                        }
                                        break;
                                    default:
                                        break;
                                }
                            });
        
                            setProjectsData({
                                taxExpertRecu: result.taxExpertRecu,
                                taxExpertConsul: result.taxExpertConsul,
                            });
        
                            setActive({
                                taxExpertRecu: {
                                    isActive: recurringisActive,
                                    wasActive: wasRecurringActive,
                                },
                                taxExpertConsul: {
                                    isActive: false,
                                    wasActive: lastProyects.consulting.since_date ? true : false,
                                },
                            });

                            setAuxExpanded(lastProject.service_name);
                            setExpanded(true);
                            setRenderState(!renderState)
                        });
                });
        } else {
            await fetch(
                `/getProjectsCompany?company_id=${companyID}&service_id=${serviceData.service_id}&service_company_id=${serviceCompID}`,
                {
                    method: "GET",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                    },
                }
            )
                .then((res) => res.json())
                .then((data) => {
                    let result = {};
                    let lastProyects = {
                        recurring: {},
                        consulting: {},
                    };
                    let recurringisActive = false;
                    let wasRecurringActive = false;

                    data.forEach((item) => {
                        switch (item.project_type) {
                            case 17:
                                result.taxExpertRecu = item;
                                if (item.projects_array.length !== 0) {
                                    let indexExact = 0;
                                    let dateMax = null;
                                    item.projects_array.forEach((item, index) => {
                                        if (index === 0) {
                                            dateMax = item.end_date;
                                        } else {
                                            if (moment(dateMax).isBefore(item.end_date)) {
                                                dateMax = item.end_date;
                                                indexExact = index;
                                            }
                                        }
                                        if (!item.client_delivery_date && item.client_commitment_date) {
                                            recurringisActive = true;
                                        } else if (item.client_delivery_date) {
                                            wasRecurringActive = moment(item.client_delivery_date).isSameOrBefore(
                                                moment()
                                            );
                                        }
                                    });
                                    lastProyects.recurring = item.projects_array[indexExact];
                                    result.taxExpertRecu.project_company_id =
                                        item.projects_array[0].project_company_id;
                                }
                                break;
                            case 19:
                                result.taxExpertConsul = item;
                                if (item.projects_array.length !== 0) {
                                    lastProyects.consulting = item.projects_array[0];
                                    result.taxExpertConsul.project_company_id = item.projects_array[0].project_company_id;
                                }
                                break;
                            default:
                                break;
                        }
                    });

                    setProjectsData({
                        taxExpertRecu: result.taxExpertRecu,
                        taxExpertConsul: result.taxExpertConsul,
                    });

                    setActive({
                        taxExpertRecu: {
                            isActive: recurringisActive,
                            wasActive: wasRecurringActive,
                        },
                        taxExpertConsul: {
                            isActive: false,
                            wasActive: lastProyects.consulting.since_date ? true : false,
                        },
                    });
                });
        }

        setBackDrop(false);
    };

    /* Component functions */
    useEffect(() => {
        getProjects(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /* Component functions */
    useEffect(() => {
        if (lastChipSelected){
            handleClickChip(valuesTaxExpert[lastChipSelected], lastChipSelected, false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [renderState]);

    const handleClickChip = async (value, option, setChip = false) => {
        switch (option) {
            case "taxExpertRecu":
                setvaluesTaxExpert({
                    ...valuesTaxExpert,
                    taxExpertRecu: setChip ? !value : true,
                    taxExpertConsul: false,
                });
                break;
            default:
                setvaluesTaxExpert({
                    ...valuesTaxExpert,
                    taxExpertConsul: setChip ? !value : true,
                    taxExpertRecu: false,
                });
                break;
        }
        if (setChip) {
            setLastChipSelected(option);
        }
    };

    return (
        <StyledContainer>
            <Divider className={classes.divider} />
            <Grid container justifyContent="space-around" alignItems="center" spacing={2} sx={{ pb: 2 }}>
                <Grid item>
                    <Chip
                        id="recurring-chip"
                        label={
                            <Typography variant="h2" align="center" sx={{ fontWeight: 700 }}>
                                {t(`invoicing.recurring`)}
                            </Typography>
                        }
                        className={
                            valuesTaxExpert.taxExpertRecu
                                ? active.taxExpertRecu.isActive
                                    ? classes.selectedChipActive
                                    : active.taxExpertRecu.wasActive
                                    ? classes.selectedChipWasActive
                                    : classes.selectedChip
                                : active.taxExpertRecu.isActive
                                ? classes.chipProActive
                                : active.taxExpertRecu.wasActive
                                ? classes.chipProWasActive
                                : classes.chip
                        }
                        onClick={() => handleClickChip(valuesTaxExpert.taxExpertRecu, "taxExpertRecu", true)}
                    />
                    {/* </span>
                    </LightTooltip> */}
                </Grid>
                <Grid item>
                    <Chip
                        id="consulting-chip"
                        label={
                            <Typography variant="h2" align="center" sx={{ fontWeight: 700 }}>
                                {t(`invoicing.consulting`)}
                            </Typography>
                        }
                        className={
                            valuesTaxExpert.taxExpertConsul
                                ? active.taxExpertConsul.isActive
                                    ? classes.selectedChipActive
                                    : active.taxExpertConsul.wasActive
                                    ? classes.selectedChipWasActive
                                    : classes.selectedChip
                                : active.taxExpertConsul.isActive
                                ? classes.chipProActive
                                : active.taxExpertConsul.wasActive
                                ? classes.chipProWasActive
                                : classes.chip
                        }
                        onClick={() => handleClickChip(valuesTaxExpert.taxExpertConsul, "taxExpertConsul", true)}
                    />
                </Grid>
            </Grid>            
            {valuesTaxExpert.taxExpertRecu && (
                <Recurring
                    setTexto={setTexto}
                    setalertType={setalertType}
                    setShow={setShow}
                    setBackDrop={setBackDrop}
                    actives={actives}
                    clientMarket={clientMarket}
                    companyID={companyID}
                    expenseEst={expenseEst}
                    accountSystem={accountSystem}
                    data={projectsData.taxExpertRecu}
                    reloadProjects={getProjects}
                    serviceID={serviceData.service_id}
                    handleCloseDialog={handleCloseDialog}
                    infoAdd={infoAdd}
                    checkRemind={checkRemind}
                    open={open}
                    bodyMess={bodyMess}
                    showConfirm={showConfirm}
                />
            )}
            {valuesTaxExpert.taxExpertConsul && (
                <Consulting
                    setTexto={setTexto}
                    setalertType={setalertType}
                    setShow={setShow}
                    companyID={companyID}
                    reloadProjects={getProjects}
                    setBackDrop={setBackDrop}
                    data={projectsData.taxExpertConsul}
                    serviceID={serviceData.service_id}
                />
            )}
        </StyledContainer>
    );
};
