/** React imports */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

/** MUI icons */
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

/** MUI imports */
import { styled } from "@mui/material/styles";
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Paper,
    Select,
    Step,
    StepButton,
    StepLabel,
    Stepper,
    TextField,
    Typography,
} from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
/** Component styles */
const CustomDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(4),
    },
    "& .MuiDialogTitle-root": {
        backgroundColor: "#031851",
        fontSize: "18px",
        color: "#fff",
    },
    "& .MuiDialog-paper": {
        borderRadius: 8,
        boxShadow: "10px 10px 15px rgba(3,24,81,0.15)",
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(2),
    },
}));
const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: 'rgba(19, 31, 62, 0.80)',
        fontSize: 12,
        maxWidth: 320,
        borderRadius: 6,
        fontWeight: 'normal'
    },
});
let companies = [];
const latam = [
    "Argentina",
    "Bolivia",
    "Brasil",
    "Chile",
    "Colombia",
    "Costa Rica",
    "Cuba",
    "Ecuador",
    "El Salvador",
    "Guatemala",
    "Haití",
    "Honduras",
    "México",
    "Nicaragua",
    "Panamá",
    "Paraguay",
    "Perú",
    "República Dominicana",
    "Uruguay",
];
const notGlobal = [
    "Argentina",
    "Bolivia",
    "Brasil",
    "Chile",
    "Colombia",
    "Costa Rica",
    "Cuba",
    "Ecuador",
    "El Salvador",
    "Estados Unidos",
    "Guatemala",
    "Haití",
    "Honduras",
    "México",
    "Nicaragua",
    "Panamá",
    "Paraguay",
    "Perú",
    "República Dominicana",
    "Uruguay",
    "Venezuela",
];

const filter = createFilterOptions();

const ModAddProspect = (props) => {
    // Properties
    const { loadInitData, onClose, open, setAutoAlertType, setBackDrop, setShow, setTexto } = props;

    /** Internationalization i18n */
    const [t] = useTranslation("global");
    

    /** Component states */
    const [activeStep, setActiveStep] = useState(0);
    const [ambassadors, setAmbassadors] = useState([]);
    const [relatedCompanies, setRelatedCompanies] = useState([]);
    const [selectedChip, setSelectedChip] = useState({});
    const [isError, setIsError] = useState(false);
    const [isError1, setIsError1] = useState(false);
    const [isErrorCompany, setIsErrorCompany] = useState(false);
    const [valuesNewProspect, setValuesNewProspect] = useState({
        name: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        type: "",
        ambassador: "N/A",
        market: "",
        activity: "",
    });
    const [invalidEmail, setInvalidEmail] = useState(false);
    const [invalidEmailMsg, setInvalidEmailMsg] = useState("");
    const [invalidEmailColor, setInvalidEmailColor] = useState("primary");
    const [invalidEmailLoading, setInvalidEmailLoading] = useState(false);
    /** Component variables */
    const steps = [t("proposals.prospect"), t("proposals.companies")];
    let requiredFields =
        valuesNewProspect.name &&
        valuesNewProspect.lastName &&
        valuesNewProspect.email &&
        !invalidEmail &&
        valuesNewProspect.type &&
        valuesNewProspect.ambassador;

    /** Component functions */
    useEffect(() => {
        (async () => {
            setInvalidEmail(false);
            setInvalidEmailColor("primary");
            setInvalidEmailLoading(true);
            await fetch(`/getAllCompaniesData`, {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
                .then((res) => res.json())
                .then((data) => {
                    companies = data;
                });

            await fetch(`/getAllAmbassadorsData`, {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
                .then((res) => res.json())
                .then((data) => {
                    setAmbassadors(data);
                });
                
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const onSaveProspect = async () => {
        setBackDrop(true);

        if (requiredFields) {
            if (/.+@.+\..+/.test(valuesNewProspect.email)) {
                if (relatedCompanies.length > 0) {
                    try {
                        relatedCompanies.forEach((element, index) => {
                            if (!(element.country_name && element.social_activity)) {
                                setIsErrorCompany(true);
                                setBackDrop(false);
                                setTexto(t("proposals.completeRelatedCompaniesData"));
                                setAutoAlertType("warning");
                                setShow(true);
                                throw new Error(t("proposals.completeRelatedCompaniesData"));
                            }
                        });
                    } catch (error) {
                        return;
                    }

                    const params = {
                        prospect_source: 1,
                        prospect_name: valuesNewProspect.name,
                        prospect_last_name: valuesNewProspect.lastName,
                        prospect_email: valuesNewProspect.email,
                        prospect_phone: valuesNewProspect.phoneNumber,
                        client_type: valuesNewProspect.type,
                        ambassador_name: valuesNewProspect.ambassador,
                        companies: relatedCompanies,
                    };

                    await fetch(`/addProspect`, {
                        method: "POST",
                        headers: {
                            "Content-type": "application/json; charset=UTF-8",
                            "Access-Control-Allow-Origin": "*",
                        },
                        body: JSON.stringify(params),
                    })
                        .then((res) => {
                            if (res.ok) {
                                handleReset();
                                onClose();
                                loadInitData();
                                setTexto(t("proposals.prospectCreationSuccessfully"));
                                setAutoAlertType("success");
                                setShow(true);
                            } else {
                                switch (res.status) {
                                    case 406:
                                        setTexto(t("proposals.prospectEmailCreationError"));
                                        setAutoAlertType("error");
                                        setShow(true);
                                        break;
                                    default:
                                        setTexto(t("proposals.prospectCreationError"));
                                        setAutoAlertType("error");
                                        setShow(true);
                                        break;
                                }                                 
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                } else {
                    setIsError1(true);
                    setIsError(false);
                    setTexto(t("miscellaneous.requiredData"));
                    setAutoAlertType("warning");
                    setShow(true);
                }
            } else {
                setIsError(true);
                setTexto(t("login.invalidEmailFormat"));
                setAutoAlertType("warning");
                setShow(true);
            }
        } else {
            setIsError(true);
            setTexto(t("miscellaneous.requiredData"));
            setAutoAlertType("warning");
            setShow(true);
        }

        setBackDrop(false);
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleReset = () => {
        setValuesNewProspect({
            name: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            type: "",
            ambassador: "N/A",
            market: "",
            activity: "",
        });
        setRelatedCompanies([]);
        setSelectedChip({});
        setActiveStep(0);
        setIsError(false);
        setIsError1(false);
        setIsErrorCompany(false);
    };

    const handleStep = (step) => {
        setActiveStep(step);
    };

    const handleBack = () => {
        if (activeStep === 0) {
            handleReset();
            onClose();
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }
    };

    const handleChangeNewProspect = (event) => {
        (event.target.name==='email')&&setInvalidEmailLoading(true);
        setValuesNewProspect({
            ...valuesNewProspect,
            [event.target.name]: event.target.value,
        });
    };

    const handleChangePhone = (event) => {
        const regex = /^\+[0-9\s.()-]{0,20}$/;
        if (event.target.value === "" || regex.test(event.target.value)) {
            setValuesNewProspect({
                ...valuesNewProspect,
                [event.target.name]: event.target.value,
            });
        }
    };

    const handleChangeClientType = (event) => {
        setValuesNewProspect({
            ...valuesNewProspect,
            [event.target.name]: event.target.value,
            market: "",
            activity: "",
        });
        setRelatedCompanies([]);
        setSelectedChip({});
        setIsError1(false);
        setIsErrorCompany(false);
    };

    const handleChangeAmbassador = (event, value, reason) => {
        if (reason === "clear" || reason === "createOption") {
            setValuesNewProspect({
                ...valuesNewProspect,
                ambassador: "",
            });
        } else {
            if (value && value.inputValue) {
                // Create a new value from the user input
                setValuesNewProspect({
                    ...valuesNewProspect,
                    ambassador: value.inputValue,
                });
            } else {
                setValuesNewProspect({
                    ...valuesNewProspect,
                    ambassador: value.ambassador_name,
                });
            }
        }
    };

    const handleChangeCompanies = (event, values, reason) => {
        if (reason === "removeOption") {
            return;
        } else if (reason === "clear") {
            setRelatedCompanies([]);
            setSelectedChip({});
            setIsErrorCompany(false);
            setValuesNewProspect({
                ...valuesNewProspect,
                market: "",
                activity: "",
            });
            return;
        } else {
            if (values && values[values.length - 1].inputValue) {
                // Create a new value from the user input
                values.forEach((element, index) => {
                    if (element.inputValue === values[values.length - 1].inputValue) {
                        if (
                            relatedCompanies.some( item =>
                                item.company_legal_name.toLowerCase().trim() === element.inputValue.toLowerCase().trim()
                            )
                        ) {
                            setTexto(t('proposals.companyAlreadyExists'));
                            setAutoAlertType("error");
                            setShow(true);
                            return;
                        } else {
                            values[index] = {
                                company_legal_name: element.inputValue,
                                country_name: "",
                                social_activity: "",
                                company_prospect: true,
                            };

                            setRelatedCompanies(values);
                            setSelectedChip(values[values.length - 1]);
                            setValuesNewProspect({
                                ...valuesNewProspect,
                                market: "",
                                activity: "",
                            });
                        }
                    }
                });
            } else {
                setRelatedCompanies(values);
                setSelectedChip(values[values.length - 1]);
                setValuesNewProspect({
                    ...valuesNewProspect,
                    market: values[values.length - 1].company_prospect
                        ? values[values.length - 1].country_name
                        : latam.includes(values[values.length - 1].country_name)
                            ? "LATAM"
                            : !notGlobal.includes(values[values.length - 1].country_name)
                                ? "Global"
                                : values[values.length - 1].country_name,
                    activity: values[values.length - 1].social_activity,
                });
            }
        }
    };

    const onSelectedChip = (values) => {
        setSelectedChip(values);
        setValuesNewProspect({
            ...valuesNewProspect,
            market: values.company_prospect
                ? values.country_name
                : latam.includes(values.country_name)
                    ? "LATAM"
                    : !notGlobal.includes(values.country_name)
                        ? "Global"
                        : values.country_name,
            activity: values.social_activity,
        });
    };

    const onSaveCompanyDetails = () => {
        if (valuesNewProspect.market && valuesNewProspect.activity) {
            relatedCompanies.forEach((element, index) => {
                if (element.company_legal_name === selectedChip.company_legal_name)
                    relatedCompanies[index] = {
                        _id: element._id ?? undefined,
                        company_legal_name: element.company_legal_name,
                        country_name: valuesNewProspect.market,
                        social_activity: valuesNewProspect.activity,
                        company_prospect: true,
                    };
            });

            setIsErrorCompany(false);
        } else {
            setIsErrorCompany(true);
            setTexto(t("miscellaneous.requiredData"));
            setAutoAlertType("warning");
            setShow(true);
        }
    };

    const onDeleteChip = (value) => {
        if (value.company_legal_name === selectedChip.company_legal_name) {
            setIsErrorCompany(false);
            setSelectedChip({});
            setValuesNewProspect({
                ...valuesNewProspect,
                market: "",
                activity: "",
            });
        }

        setRelatedCompanies(relatedCompanies.filter((entry) => entry !== value));
    };
    
    const verifyEmail = async (e) => {
        setInvalidEmailLoading(true);
        if (valuesNewProspect.email.trim() !== "") {
            if (/.+@.+\..+/.test(valuesNewProspect.email)) {
                const params = {
                    email :valuesNewProspect.email
                };
               
                await fetch(`/verifyEmailProspect`, {
                    method: "POST",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                    },
                    body: JSON.stringify(params),
                })
                .then((res) => res.json())
                .then((data) => {    
                    setInvalidEmailLoading(false);                
                    if(data){
                        //setIsError(true)
                        setInvalidEmail(true);
                        setInvalidEmailMsg(t("proposals.invalidEmailAdd"))
                        // setTexto(t("proposals.invalidEmailAdd"));
                        // setAutoAlertType("error");
                        // setShow(true);
                        setInvalidEmailColor("error")
                    }else{
                        //setIsError(false)
                        setInvalidEmail(false);
                        setInvalidEmailColor("green")
                        setInvalidEmailMsg(t("proposals.validEmailAdd"))
                    }
                    setInvalidEmailLoading(false);
                }).catch((error) => {
                        setInvalidEmail(true);
                        setInvalidEmailColor("error")
                        setInvalidEmailLoading(false);
                });
            } else {
                setTexto(t("login.invalidEmailFormat"));
                setAutoAlertType("error");
                setShow(true);

                setInvalidEmail(true);
                setInvalidEmailMsg(t("login.invalidEmailFormat"))
                setInvalidEmailColor("error")
            }
        } else {
            setTexto(t("login.invalidEmailFormat"));
            setAutoAlertType("error");
            setShow(true);

            setInvalidEmail(true);
            setInvalidEmailMsg(t("login.invalidEmailFormat"))
            setInvalidEmailColor("error")
        }
    };
    return (
        <CustomDialog
            open={open}
            onClose={() => {
                handleReset();
                onClose();
            }}
            aria-labelledby="add-prospect-dialog-title"
            aria-describedby="add-prospect-dialog-description"
            fullWidth={true}
            maxWidth="md"
        >
            <DialogTitle id="add-prospect-dialog-title">{t("proposals.newProspect")}</DialogTitle>
            <DialogContent dividers>
                <Box
                    sx={{
                        display: "block",
                        ml: "auto",
                        mr: "auto",
                        p: 2,
                        width: "70%",
                    }}
                >
                    <Stepper
                        nonLinear
                        activeStep={activeStep}
                        orientation={window.innerWidth < 600 ? "vertical" : "horizontal"}
                    >
                        {steps.map((label, index) => {
                            const labelProps = {};
                            if ((isError && index === 0)|| (invalidEmail && index === 0) || (isError1 && index === 1)) {
                                labelProps.optional = (
                                    <Typography variant="caption" color="error">
                                        {t("miscellaneous.required")}
                                    </Typography>
                                );

                                labelProps.error = true;
                            }
                            return (
                                <Step key={label}>
                                    <StepButton
                                        disabled={index === 1 && !requiredFields}
                                        onClick={() => handleStep(index)}
                                    >
                                        <StepLabel {...labelProps}>{label}</StepLabel>
                                    </StepButton>
                                </Step>
                            );
                        })}
                    </Stepper>
                </Box>
                {activeStep === 0 && (
                    <Card elevation={0}>
                        <CardHeader title={t("miscellaneous.generalData")} sx={{ color: "#ff5968" }} />
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        name="name"
                                        required
                                        label={t("proposals.name")}
                                        value={valuesNewProspect.name}
                                        onChange={handleChangeNewProspect}
                                        variant="outlined"
                                        error={isError && valuesNewProspect.name === "" ? true : false}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        name="lastName"
                                        required
                                        label={t("proposals.lastName")}
                                        value={valuesNewProspect.lastName}
                                        onChange={handleChangeNewProspect}
                                        variant="outlined"
                                        error={(isError && valuesNewProspect.lastName === "") }
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        //color={invalidEmailColor}
                                        sx={{ 
                                            fieldset:{
                                                borderColor: (invalidEmailColor)
                                            },
                                            label :{
                                                color: (invalidEmailColor)
                                            }
                                        }}
                                        
                                        name="email"
                                        required
                                        label={t("proposals.email")}
                                        value={valuesNewProspect.email}
                                        onChange={handleChangeNewProspect}
                                        onBlur={verifyEmail}
                                        variant="outlined"
                                        error={
                                           invalidEmail
                                        }                                      
                                        InputProps={{
                                            endAdornment: (    
                                                ((invalidEmail && !invalidEmailLoading ) &&                                       
                                                    <InputAdornment position="end">                                                    
                                                        <LightTooltip title={invalidEmailMsg} aria-label="validatedMonth" >
                                                            <ErrorIcon color={invalidEmailColor} />
                                                        </LightTooltip>                                           
                                                    </InputAdornment>)
                                                ||
                                                ((valuesNewProspect.email && !invalidEmail && !invalidEmailLoading) &&           
                                                    <InputAdornment                             position="end">                                             
                                                        <LightTooltip title={invalidEmailMsg} aria-label="validatedMonth" >
                                                            <CheckCircleIcon style={{ color: (invalidEmailColor)}} />
                                                        </LightTooltip>  
                                                    </InputAdornment>)
                                                    

                                            ),
                                          }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="prospect-phone-number-mask-input">
                                            {t("miscellaneous.phoneNumber")}
                                        </InputLabel>
                                        <OutlinedInput
                                            id="prospect-phone-number-mask-input"
                                            name="phoneNumber"
                                            label={t("miscellaneous.phoneNumber")}
                                            value={valuesNewProspect.phoneNumber}
                                            onChange={handleChangePhone}
                                            placeholder="e.g. +99 (999) 999-9999"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl
                                        fullWidth
                                        required
                                        error={isError && valuesNewProspect.type === "" ? true : false}
                                    >
                                        <InputLabel id="type-simple-select-label">{t("proposals.type")}</InputLabel>
                                        <Select
                                            name="type"
                                            labelId="type-simple-select-label"
                                            IconComponent={KeyboardArrowDownIcon}
                                            value={valuesNewProspect.type}
                                            label={t("proposals.type")}
                                            onChange={handleChangeClientType}
                                            MenuProps={{
                                                PaperProps: {
                                                    sx: {
                                                        mt: 0.7,
                                                        borderRadius: 2,
                                                        boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                    },
                                                },
                                            }}
                                        >
                                            <MenuItem value={1}>{t("miscellaneous.firm")}</MenuItem>
                                            <MenuItem value={2}>SMB</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Autocomplete
                                        id="ambassador-autocomplete"
                                        value={valuesNewProspect.ambassador}
                                        onChange={(event, value, reason) =>
                                            handleChangeAmbassador(event, value, reason)
                                        }
                                        filterOptions={(options, params) => {
                                            const filtered = filter(options, params);

                                            const { inputValue } = params;
                                            // Suggest the creation of a new value
                                            const isExisting = options.some(
                                                (option) => inputValue === option.ambassador_name
                                            );
                                            if (inputValue !== "" && !isExisting) {
                                                filtered.push({
                                                    inputValue,
                                                    ambassador_name: `${t("proposals.add")} "${inputValue}"`,
                                                });
                                            }

                                            return filtered;
                                        }}
                                        selectOnFocus
                                        clearOnBlur
                                        handleHomeEndKeys
                                        options={ambassadors}
                                        getOptionLabel={(option) => {
                                            // Value selected with enter, right from the input
                                            if (typeof option === "string") {
                                                return option;
                                            }
                                            // Add "xxx" option created dynamically
                                            if (option.inputValue) {
                                                return option.inputValue;
                                            }
                                            // Regular option
                                            return option.ambassador_name;
                                        }}
                                        renderOption={(props, option) => <li {...props}>{option.ambassador_name}</li>}
                                        renderInput={(params) => (
                                            <TextField
                                                required
                                                {...params}
                                                error={isError && valuesNewProspect.ambassador === "" ? true : false}
                                                label={t("proposals.ambassador")}
                                            />
                                        )}
                                        popupIcon={<KeyboardArrowDownIcon color="primary" />}
                                        PaperComponent={({ children }) => (
                                            <Paper
                                                sx={{
                                                    mt: 0.7,
                                                    borderRadius: 2,
                                                    boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                }}
                                            >
                                                {children}
                                            </Paper>
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                )}
                {activeStep === 1 && (
                    <Grid container spacing={2} sx={{ p: "24px 16px 24px" }}>
                        <Grid item xs={12}>
                            <Autocomplete
                                multiple
                                id="tags-company"
                                options={companies.filter((company) => company.client_type === valuesNewProspect.type)}
                                value={relatedCompanies}
                                onChange={(event, values, reason) => handleChangeCompanies(event, values, reason)}
                                filterSelectedOptions
                                filterOptions={(options, params) => {
                                    const filtered = filter(options, params);

                                    const { inputValue } = params;
                                    // Suggest the creation of a new value
                                    const isExisting = options.some((option) => inputValue === option.title);
                                    if (inputValue !== "" && !isExisting) {
                                        filtered.push({
                                            inputValue,
                                            company_legal_name: `${t("proposals.plusAdd")} "${inputValue}"`,
                                        });
                                    }

                                    return filtered;
                                }}
                                getOptionLabel={(option) => {
                                    // Value selected with enter, right from the input
                                    if (typeof option === "string") {
                                        return option;
                                    }
                                    // Add "xxx" option created dynamically
                                    if (option.inputValue) {
                                        return option.inputValue;
                                    }
                                    // Regular option
                                    return option.company_legal_name;
                                }}
                                renderTags={(relatedCompanies) =>
                                    relatedCompanies.map((value, index) => (
                                        <Chip
                                            variant={
                                                selectedChip &&
                                                selectedChip.company_legal_name === value.company_legal_name
                                                    ? "filled"
                                                    : "outlined"
                                            }
                                            label={value.inputValue ? value.inputValue : value.company_legal_name}
                                            color={
                                                isErrorCompany && !value.country_name && !value.social_activity
                                                    ? "error"
                                                    : "default"
                                            }
                                            sx={{
                                                "&:hover": {
                                                    backgroundColor:
                                                        selectedChip &&
                                                        selectedChip.company_legal_name === value.company_legal_name
                                                            ? "rgba(47, 49, 144, 0.8)"
                                                            : "transparent",
                                                },
                                                backgroundColor:
                                                    selectedChip &&
                                                    selectedChip.company_legal_name === value.company_legal_name
                                                        ? "#2f3190"
                                                        : "transparent",
                                                color:
                                                    selectedChip &&
                                                    selectedChip.company_legal_name === value.company_legal_name
                                                        ? "white"
                                                        : "#031851",
                                                m: "3px",
                                            }}
                                            deleteIcon={
                                                <CancelRoundedIcon
                                                    sx={{
                                                        color:
                                                            selectedChip &&
                                                            selectedChip.company_legal_name === value.company_legal_name
                                                                ? "white !important"
                                                                : "transparent",
                                                    }}
                                                />
                                            }
                                            onClick={() => onSelectedChip(value)}
                                            onDelete={() => onDeleteChip(value)}
                                        />
                                    ))
                                }
                                renderOption={(props, option) => <li {...props}>{option.company_legal_name}</li>}
                                renderInput={(params) => (
                                    <TextField
                                        required
                                        {...params}
                                        error={isError1 && relatedCompanies.length === 0 ? true : false}
                                        label={t("proposals.relatedCompanies")}
                                    />
                                )}
                                popupIcon={<KeyboardArrowDownIcon color="primary" />}
                                PaperComponent={({ children }) => (
                                    <Paper
                                        sx={{
                                            mt: 0.7,
                                            borderRadius: 2,
                                            boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                        }}
                                    >
                                        {children}
                                    </Paper>
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl
                                fullWidth
                                required={selectedChip && selectedChip.company_prospect}
                                disabled={selectedChip && !selectedChip.company_prospect}
                                error={isErrorCompany && valuesNewProspect.market === "" ? true : false}
                            >
                                <InputLabel id="market-simple-select-label">{t("proposals.market")}</InputLabel>
                                <Select
                                    name="market"
                                    labelId="market-simple-select-label"
                                    IconComponent={KeyboardArrowDownIcon}
                                    value={valuesNewProspect.market}
                                    label={t("proposals.market")}
                                    onChange={handleChangeNewProspect}
                                    MenuProps={{
                                        PaperProps: {
                                            sx: {
                                                mt: 0.7,
                                                borderRadius: 2,
                                                boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                            },
                                        },
                                    }}
                                >
                                    <MenuItem value="Global">
                                        <Typography variant="body1" component="span" align="center" color="textPrimary">
                                            Global
                                        </Typography>
                                    </MenuItem>
                                    <MenuItem value="LATAM">
                                        <Typography variant="body1" component="span" align="center" color="textPrimary">
                                            LATAM
                                        </Typography>
                                    </MenuItem>
                                    <MenuItem value="Estados Unidos">
                                        <Typography variant="body1" component="span" align="center" color="textPrimary">
                                            USA
                                        </Typography>
                                    </MenuItem>
                                    <MenuItem value="Venezuela">
                                        <Typography variant="body1" component="span" align="center" color="textPrimary">
                                            Venezuela
                                        </Typography>
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                disabled={selectedChip && !selectedChip.company_prospect}
                                error={isErrorCompany && valuesNewProspect.activity === "" ? true : false}
                                label={t("proposals.economicActivity")}
                                name="activity"
                                onChange={handleChangeNewProspect}
                                onBlur={onSaveCompanyDetails}
                                required={selectedChip && selectedChip.company_prospect}
                                value={valuesNewProspect.activity}
                                variant="outlined"
                            />
                        </Grid>
                    </Grid>
                )}
            </DialogContent>
            <DialogActions>
                <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                    <Grid item>
                        <Button
                            variant="outlined"
                            color="secondary"
                            disableElevation
                            onClick={handleBack}
                            sx={{
                                pl: 3,
                                pr: 3,
                                borderRadius: 40,
                                "&:hover": {
                                    border: "1px solid #2f3190",
                                    color: "#2f3190",
                                },
                            }}
                        >
                            {activeStep === 0 ? t("miscellaneous.cancel") : t("miscellaneous.back")}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            disabled={!requiredFields}
                            disableElevation
                            variant="contained"
                            color="secondary"
                            sx={{
                                pl: 3,
                                pr: 3,
                                borderRadius: 40,
                                "&:hover": {
                                    backgroundColor: "#2f3190",
                                    color: "#fff",
                                },
                                "&.Mui-disabled": {
                                    color: "#fff",
                                    borderColor: "#ffcdd2",
                                    background: "#ffcdd2",
                                },
                            }}
                            onClick={activeStep === steps.length - 1 ? onSaveProspect : handleNext}
                        >
                            {activeStep === steps.length - 1 ? t("miscellaneous.save") : t("miscellaneous.next")}
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </CustomDialog>
    );
};

export default ModAddProspect;
