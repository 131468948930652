// React
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { NumericFormat } from "react-number-format";

// MUI
import {
    Box,
    TextField,
    Grid,
    Divider,
    Card,
    CardHeader,
    CardContent,
    Button,
    FormControl,
    InputLabel,
    OutlinedInput,
    InputAdornment,
    Select,
    MenuItem,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

/** MUI icons */
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const PREFIX = "Xero";

const classes = {
    btnRoot: `${PREFIX}-btnRoot`,
    buttonContainer: `${PREFIX}-buttonContainer`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.btnRoot}`]: {
        border: "1px solid #ff5968",
        borderRadius: 40,
        "&:hover": {
            backgroundColor: "#2f3190",
            border: "1px solid #2f3190",
        },
    },
    [`& .${classes.buttonContainer}`]: {
        display: "flex",
        justifyContent: "flex-end",
        padding: 15,
    },
}));

const NumberFormatCustom = (props) => {
    const todos = useSelector((state) => state.todos);

    const { inputRef, value, onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            value={value}
            getInputRef={inputRef}
            onValueChange={({ value: v }) => onChange({ target: { value: v } })}
            thousandSeparator={todos.amountFormat === "de-DE" ? "." : ","}
            decimalSeparator={todos.amountFormat === "de-DE" ? "," : "."}
            fixedDecimalScale={true}
            decimalScale={2}
            valueIsNumericString
        />
    );
};

const defaultState = {
    ogFee: 0,
    hours: 1,
    fee: 0,
    discount: 0,
    totalFee: 0,
    package: "",
    version: "",
    estimatedDate: null,
    deliveryDate: null,
    isCustom: true,
};

export const Xero = (props) => {
    // Properties
    const { setTexto, setalertType, setShow, companyID, setBackDrop, data, reloadProjects, serviceID } = props;

    /** Internationalization i18n */
    const [t] = useTranslation("global");

    /** React redux */
    const todos = useSelector((state) => state.todos);

    /** Component states */
    const [xero, setXero] = useState(defaultState);
    const [isError, setIsError] = useState(false);

    /** Component functions */
    useEffect(() => {
        setXero({
            hours: data.consulting_hours ?? 1,
            ogFee: data.original_fee ?? data.fee,
            fee: (data.original_fee ?? data.fee) * (data.consulting_hours ?? 1),
            discount: data.discount ?? 0,
            totalFee: (data.original_fee ?? data.fee) * (data.consulting_hours ?? 1) * (1 - (data.discount ?? 0) / 100),
            package: data.package ?? "",
            version: data.version ?? "",
            estimatedDate: data.client_commitment_date
                ? moment(data.client_commitment_date).utc()
                : null,
            deliveryDate: data.client_delivery_date ? moment(data.client_delivery_date).utc() : null,
            isCustom: data.package !== 4,
        });
    }, [data]);

    const handleChange = (event) => {
        setXero({
            ...xero,
            [event.target.name]: event.target.value,
            fee: event.target.value * xero.ogFee,
            totalFee: event.target.value * xero.ogFee * (1 - xero.discount / 100),
        });
    };

    const handleChangeDiscount = (event) => {
        let discountAux = event.target.value >= 0 && event.target.value <= 100 ? event.target.value : xero.discount;

        setXero({
            ...xero,
            discount: discountAux,
            totalFee: xero.fee * (1 - discountAux / 100),
        });
    };

    const updateProject = async () => {
        setBackDrop(true);

        if (xero.version && xero.package && xero.estimatedDate) {
            let params = {
                company_id: companyID,
                service_id: serviceID,
                project_id: data.project_id,
                project_company_id: data.project_company_id,
                client_delivery_date: xero.deliveryDate ? moment(xero.deliveryDate).startOf("day").format() : null,
                client_commitment_date: moment(xero.estimatedDate).startOf("day").format(),
                discount: xero.discount ? parseFloat(xero.discount) : 0,
                fee: xero.totalFee,
                package: xero.package,
                version: xero.version,
                consulting_hours: parseFloat(xero.hours),
            };

            await fetch(`/addServicesAndProjects`, {
                method: "POST",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
                body: JSON.stringify(params),
            })
                .then((response) => {
                    if (response.ok) {
                        setTexto(t("miscellaneous.successfulUpdate"));
                        setalertType("success");
                        setShow(true);
                        reloadProjects(true);
                    } else {
                        setTexto(t("miscellaneous.updatingError"));
                        setalertType("error");
                        setShow(true);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            setTexto(t("miscellaneous.requiredData"));
            setalertType("warning");
            setShow(true);
            setIsError(true);
        }

        setBackDrop(false);
    };

    const handleChangeEstimatedDate = (date) => {
        if (!date || !xero.deliveryDate) {
            setXero({
                ...xero,
                estimatedDate: date,
            });
        } else {
            if (date.isAfter(xero.deliveryDate.format("YYYY-MM-DD"))) {
                setXero({
                    ...xero,
                    estimatedDate: null,
                });

                setTexto(t("reports.meetingDateMess"));
                setalertType("warning");
                setShow(true);
            } else {
                setXero({
                    ...xero,
                    estimatedDate: date,
                });
            }
        }
    };

    const handleChangeDeliveryDate = (date) => {
        if (!date || !xero.estimatedDate) {
            setXero({
                ...xero,
                deliveryDate: date,
            });
        } else {
            if (date.isBefore(xero.estimatedDate.format("YYYY-MM-DD"))) {
                setXero({
                    ...xero,
                    deliveryDate: null,
                });

                setTexto(t("reports.meetingDateMess"));
                setalertType("warning");
                setShow(true);
            } else {
                setXero({
                    ...xero,
                    deliveryDate: date,
                });
            }
        }
    };

    const handleChangePackage = (event) => {
        let hoursNew = 1;

        if (event.target.value === 1) {
            hoursNew = 20;
        } else if (event.target.value === 2) {
            hoursNew = 40;
        } else if (event.target.value === 3) {
            hoursNew = 60;
        }

        setXero({
            ...xero,
            package: event.target.value,
            hours: hoursNew,
            fee: hoursNew * xero.ogFee,
            totalFee: hoursNew * xero.ogFee * (1 - xero.discount / 100),
            isCustom: event.target.value !== 4,
        });
    };

    const handleChangeVersion = (event) => {
        setXero({
            ...xero,
            version: event.target.value,
        });
    };

    return (
        <StyledContainer>
            <LocalizationProvider
                dateAdapter={AdapterMoment}
                adapterLocale={t("language.locale")}
                localeText={{ clearButtonLabel: t("miscellaneous.clear") }}
            >
                <Card elevation={0}>
                    <CardHeader title="Xero setup" />
                    <Divider />
                    <CardContent>
                        <Grid container alignItems="flex-start" spacing={2}>
                            <Grid item xs={12} sm={6} lg={2}>
                                <FormControl fullWidth variant="outlined" disabled>
                                    <InputLabel htmlFor="outlined-adornment-ogFee">
                                        {t("services.hourlyRate")}
                                    </InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-ogFee"
                                        value={xero.ogFee}
                                        inputComponent={NumberFormatCustom}
                                        startAdornment={
                                            <InputAdornment disableTypography position="start">
                                                $
                                            </InputAdornment>
                                        }
                                        label={t("services.hourlyRate")}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={1}>
                                <TextField
                                    fullWidth
                                    label={t("miscellaneous.hours")}
                                    name="hours"
                                    onChange={handleChange}
                                    value={xero.hours}
                                    disabled={xero.isCustom}
                                    variant="outlined"
                                    type="number"
                                    inputProps={{
                                        min: 1,
                                        step: 0.5,
                                        onKeyDown: (event) => {
                                            event.preventDefault();
                                        },
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} lg={3}>
                                <FormControl fullWidth variant="outlined" disabled>
                                    <InputLabel htmlFor="outlined-adornment-fee">{t("services.projectFee")}</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-fee"
                                        value={xero.fee}
                                        inputComponent={NumberFormatCustom}
                                        startAdornment={
                                            <InputAdornment disableTypography position="start">
                                                $
                                            </InputAdornment>
                                        }
                                        label={t("services.projectFee")}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={3}>
                                <TextField
                                    fullWidth
                                    label={t("miscellaneous.discount")}
                                    value={xero.discount}
                                    name="discount"
                                    onChange={handleChangeDiscount}
                                    onBlur={() =>
                                        setXero({
                                            ...xero,
                                            discount: parseFloat(xero.discount === "" ? 0 : xero.discount).toFixed(2),
                                        })
                                    }
                                    variant="outlined"
                                    type="number"
                                    InputProps={{
                                        inputProps: {
                                            min: 0,
                                            max: 100,
                                        },
                                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                    }}
                                    sx={{
                                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                            display: "none",
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} lg={3}>
                                <FormControl fullWidth variant="outlined" disabled>
                                    <InputLabel htmlFor="outlined-adornment-totalFee">
                                        {t("proposals.feeTotal")}
                                    </InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-totalFee"
                                        value={xero.totalFee}
                                        inputComponent={NumberFormatCustom}
                                        startAdornment={
                                            <InputAdornment disableTypography position="start">
                                                $
                                            </InputAdornment>
                                        }
                                        label={t("proposals.feeTotal")}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                <Card elevation={0}>
                    <CardHeader title={t("services.serviceResponsibility")} sx={{ color: "#ff5968" }} />
                    <Divider />
                    <CardContent>
                        <Grid container alignItems="center" spacing={2}>
                            <Grid item xs={12} sm={6} lg={3}>
                                <FormControl fullWidth required error={isError && !xero.package}>
                                    <InputLabel id="ver-simple-select-label">{t("services.package")}</InputLabel>
                                    <Select
                                        name="package"
                                        labelId="ver-simple-select-label"
                                        IconComponent={KeyboardArrowDownIcon}
                                        value={xero.package}
                                        label={t("services.package")}
                                        onChange={handleChangePackage}
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    mt: 0.7,
                                                    borderRadius: 2,
                                                    boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                },
                                            },
                                        }}
                                    >
                                        <MenuItem value={1}>Basic (20 hrs)</MenuItem>
                                        <MenuItem value={2}>Professional (40 hrs)</MenuItem>
                                        <MenuItem value={3}>Advanced (60 hrs)</MenuItem>
                                        <MenuItem value={4}>{t("currentView.personal")}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={3}>
                                <FormControl fullWidth required error={isError && !xero.version}>
                                    <InputLabel id="ver-simple-select-label">{t("services.version")}</InputLabel>
                                    <Select
                                        name="version"
                                        labelId="ver-simple-select-label"
                                        IconComponent={KeyboardArrowDownIcon}
                                        value={xero.version}
                                        label={t("services.version")}
                                        onChange={handleChangeVersion}
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    mt: 0.7,
                                                    borderRadius: 2,
                                                    boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                },
                                            },
                                        }}
                                    >
                                        <MenuItem value={"Ledger"}>Ledger</MenuItem>
                                        <MenuItem value={"Cashbook"}>Cashbook</MenuItem>
                                        <MenuItem value={"Growing US"}>Growing - US</MenuItem>
                                        <MenuItem value={"Established US"}>Established - US</MenuItem>
                                        <MenuItem value={"Standard GB"}>Standard - Global</MenuItem>
                                        <MenuItem value={"Premium GB"}>Premium - Global</MenuItem>
                                        <MenuItem value={"Early"}>Early</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    value={xero.estimatedDate}
                                    onChange={(newValue) => handleChangeEstimatedDate(newValue)}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => (
                                        <TextField
                                            required
                                            fullWidth
                                            {...params}
                                            error={isError && !xero.estimatedDate}
                                        />
                                    )}
                                    inputProps={{ readOnly: true }}
                                    label={t("services.estimDeliveryDate")}
                                    views={["year", "month", "day"]}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} lg={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    value={xero.deliveryDate}
                                    onChange={(newValue) => handleChangeDeliveryDate(newValue)}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => <TextField fullWidth {...params} />}
                                    inputProps={{ readOnly: true }}
                                    label={t("services.deliveryDate")}
                                    views={["year", "month", "day"]}
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                    <Divider />
                    <Box className={classes.buttonContainer}>
                        <Button
                            color="secondary"
                            variant="contained"
                            disableElevation
                            classes={{ root: classes.btnRoot }}
                            onClick={updateProject}
                        >
                            {t("miscellaneous.save")}
                        </Button>
                    </Box>
                </Card>
            </LocalizationProvider>
        </StyledContainer>
    );
};
