// React
import React from "react";
import { useTranslation } from "react-i18next";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useSelector } from "react-redux";

// MUI
import {
    Box,
    TextField,
    Grid,
    Typography,
    InputAdornment,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { styled } from "@mui/material/styles";

/** MUI icons */
import CheckIcon from "@mui/icons-material/Check";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const PREFIX = "CustomerSupp";

const classes = {
    btnRoot: `${PREFIX}-btnRoot`,
    buttonContainer: `${PREFIX}-buttonContainer`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    backgroundColor: "#F8F9F8",
    marginTop: 16,
    padding: 30,
    borderRadius: 30,

    [`& .${classes.btnRoot}`]: {
        border: "1px solid #ff5968",
        borderRadius: 40,
        "&:hover": {
            backgroundColor: "#2f3190",
            border: "1px solid #2f3190",
        },
    },
    [`& .${classes.buttonContainer}`]: {
        display: "flex",
        justifyContent: "flex-end",
        padding: 15,
    },
}));

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)({
    backgroundColor: "#F8F9F8",
    boxShadow: "none",
    "&:before": {
        display: "none",
    },
});

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: "#031851" }} />} {...props} />
))({
    "& .MuiAccordionSummary-content": {
        margin: "12px 0",
    },
});

const AccordionDetails = styled(MuiAccordionDetails)({
    padding: "0px 0px 20px 0px",
    "& .MuiCard-root": {
        boxShadow: "none",
    },
});

export const CustomerSupp = (props) => {
    // Properties
    const { support, setSupport, isError, isErrorFocus, setIsErrorFocus } = props;

    /** Internationalization i18n */
    const [t] = useTranslation("global");

    /** React redux */

    /** Component states */
    const todos = useSelector((state) => state.todos);

    /** Component functions */

    const handleChangeDiscount = (event) => {
        let discountAux = event.target.value >= 0 && event.target.value <= 100 ? event.target.value : support.discount;

        setSupport({
            ...support,
            discount: discountAux,
            projectTotal: support.originalFee * support.hours * (1 - discountAux / 100),
            isError: false,
        });
        setIsErrorFocus(false);
    };

    const handleChange = (event) => {
        setSupport({
            ...support,
            hours: event.target.value,
            projectTotal: event.target.value * support.originalFee * (1 - support.discount / 100),
            isError: false,
        });
        setIsErrorFocus(false);
    };

    const handleChangeSinceDate = (date) => {
        setSupport({
            ...support,
            sinceDateProposal: date,
            isError: false,
        });
        setIsErrorFocus(false);
    };

    return (
        <StyledContainer>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography
                        variant="h1"
                        color="primary"
                        sx={{
                            display: "inline-block",
                            fontWeight: 700,
                            pr: 6,
                        }}
                    >
                        Customer Support
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Accordion
                        TransitionProps={{ unmountOnExit: true }}
                        sx={{
                            borderBottom: "3px solid #ff5968",
                        }}
                    >
                        <AccordionSummary aria-controls="panel-content" id="panel-header">
                            <Typography
                                variant="h2"
                                align="center"
                                sx={{
                                    fontWeight: 700,
                                }}
                            >
                                {t("invoicing.description")}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <List>
                                <ListItem disableGutters alignItems="center">
                                    <ListItemIcon sx={{ minWidth: 35 }}>
                                        <CheckIcon fontSize="small" sx={{ color: "#031851" }} />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <Typography variant="body1">
                                                <span style={{ fontWeight: 600 }}>{t("proposals.writtenSup")} </span>-{" "}
                                                {t("proposals.UnlimSup")}
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                            </List>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <TextField
                        fullWidth
                        label={t("miscellaneous.hours")}
                        name="hours"
                        onChange={handleChange}
                        value={support.hours}
                        variant="outlined"
                        type="number"
                        inputProps={{
                            min: 1,
                            step: 0.5,
                            onKeyDown: (event) => {
                                event.preventDefault();
                            },
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <TextField
                        fullWidth
                        label={t("miscellaneous.discount")}
                        value={support.discount}
                        name="discount"
                        onChange={handleChangeDiscount}
                        onBlur={() =>
                            setSupport({
                                ...support,
                                discount: parseFloat(support.discount === "" ? 0 : support.discount).toFixed(2),
                            })
                        }
                        variant="outlined"
                        type="number"
                        InputProps={{
                            inputProps: {
                                min: 0,
                                max: 100,
                            },
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}
                        sx={{
                            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                display: "none",
                            },
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <LocalizationProvider
                        dateAdapter={AdapterMoment}
                        adapterLocale={t("language.locale")}
                        localeText={{ clearButtonLabel: t("miscellaneous.clear") }}
                    >
                        <DatePicker
                            componentsProps={{
                                actionBar: { actions: ["clear"] },
                            }}
                            value={support.sinceDateProposal}
                            onChange={(newValue) => handleChangeSinceDate(newValue)}
                            inputFormat={todos.dateFormat}
                            renderInput={(params) => (
                                <TextField fullWidth required {...params} error={isError && !support.sinceDateProposal} />
                            )}
                            onClick={(e) => {
                                setIsErrorFocus(false);
                            }}
                            label={t("reports.initDate")}
                            views={["year", "month", "day"]}
                            inputRef={(input) => {
                                input && isErrorFocus && isError && !support.sinceDateProposal && input.focus();
                            }}
                        />
                    </LocalizationProvider>
                </Grid>
            </Grid>
        </StyledContainer>
    );
};
