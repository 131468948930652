/** React imports */
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { NumericFormat } from "react-number-format";
import moment from "moment";

/** MUI imports */
import { styled } from "@mui/material/styles";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Autocomplete,
    FormControlLabel,
    Paper,
    ToggleButtonGroup,
    Button,
    Switch,
    Typography,
    ToggleButton,
    OutlinedInput,
    InputAdornment,
    Checkbox,
    Box,
    Chip,
    ListItemText,
    IconButton,
} from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

/** MUI icons */
import LockOpenIcon from "@mui/icons-material/LockOpen";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DialogAlerts from "../DialogAlerts";

import classes from "./BankDialog.module.css";

const CustomDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(4),
    },
    "& .MuiDialogTitle-root": {
        backgroundColor: "#031851",
        fontSize: "18px",
        color: "#fff",
    },
    "& .MuiDialog-paper": {
        borderRadius: 8,
        boxShadow: "10px 10px 15px rgba(3,24,81,0.15)",
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(2),
    },
}));

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 48 * 4.5 + 8,
            width: 250,
        },
    },
};

const NumberFormatCustom = (props) => {
    const todos = useSelector((state) => state.todos);

    const { inputRef, value, onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            value={value}
            getInputRef={inputRef}
            onValueChange={({ value: v }) => onChange({ target: { value: v } })}
            thousandSeparator={todos.amountFormat === "de-DE" ? "." : ","}
            decimalSeparator={todos.amountFormat === "de-DE" ? "," : "."}
            fixedDecimalScale={true}
            decimalScale={2}
            valueIsNumericString
        />
    );
};

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    width: "100%",
    "& .MuiToggleButtonGroup-grouped": {
        border: 0,
        borderRadius: 30,
        height: 35,
        width: "50%",
        backgroundColor: "rgba(47,49,144,0.1)",
        color: "#031851",
        "&:hover": {
            backgroundColor: "rgba(47, 49, 144, 0.05)",
        },
    },
    "& .Mui-selected": {
        backgroundColor: "#2f3190 !important",
        color: "white !important",
        "&:hover": {
            backgroundColor: "rgba(47, 49, 144, 0.8) !important",
        },
    },
}));

const ColorButton = styled(Button)(({ theme }) => ({
    border: "1px solid #ff5968",
    borderRadius: 40,
}));

const usersAccess = [
    "atorrealba@gca.digital",
    "dleon@gca.digital",
    "dloyo@gca.digital",
    "kgarcia@gca.digital",
    "btorres@gca.digital",
    "jalvarez@gca.digital",
    "jrojas@gca.digital",
    "mmedina@gca.digital",
];

export default function BankDialog(props) {
    const {
        valuesBank,
        isError,
        setIsError,
        handleChangeReceptionDays,
        banks,
        setValuesBank,
        updateBankAccount,
        onClose,
        open,
        handleChangeCheckedNA,
        selectedYear,
        setBackDrop,
        setTexto,
        setAlertType,
        setShow,
    } = props;
    const [t] = useTranslation("global");

    const requireNames = [t("clientsTable.accStatements"), t("services.checks"), t("services.smallReport")];

    /** React redux */
    const todos = useSelector((state) => state.todos);

    const [bankTab, setBankTab] = useState("data");
    const [bankTabExp, setBankTabExp] = useState({
        monthExp: "",
        regExpen: "",
        newExpen: "",
    });
    const [openAlert, setOpenAlert] = useState(false);
    const [dialogText, setDialogText] = useState("");

    const handleChange = (event) => {
        if (event.target.name === "mode") {
            setValuesBank({
                ...valuesBank,
                [event.target.name]: event.target.value,
                monitoringFreq: event.target.value === "0" ? 3 : 30,
            });
        } else {
            setValuesBank({
                ...valuesBank,
                [event.target.name]: event.target.value,
            });
        }
    };

    const handleChangeExp = (event) => {
        getExpenses(event.target.value);
    };

    const handleChangeNewExp = (event) => {
        setBankTabExp({
            ...bankTabExp,
            newExpen: event.target.value,
        });
    };

    /** Component functions */
    const handleChangeChat = (event, newAlignment) => {
        if (newAlignment !== null) {
            if (newAlignment === "expen") {
                getExpenses(moment().subtract(1, "months").format("MM"));
            }
            setBankTab(newAlignment);
        }
        setIsError(false);
    };

    const getExpenses = async (value) => {
        setBackDrop(true);

        await fetch(`/getRealExpenses?id_account=${valuesBank.idAccount}&year=${selectedYear}&month=${value}`, {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then((res) => res.json())
            .then((data) => {
                setBankTabExp({
                    newExpen: "",
                    monthExp: value,
                    regExpen: data,
                });
            });

        setBackDrop(false);
    };

    const updateExpenses = () => {
        if (bankTabExp.newExpen < bankTabExp.regExpen) {
            setDialogText(t("alert.sureReg"));
            setOpenAlert(true);
        } else {
            insertExpenses();
        }
    };

    const handleChangeChecksInc = (event) => {
        setValuesBank({
            ...valuesBank,
            checksInc: event.target.checked,
        });
    };

    const handleMouseDown = (event) => {
        event.preventDefault();
    };

    const insertExpenses = async () => {
        setBackDrop(true);
        setOpenAlert(false);

        const params = {
            id_account: valuesBank.idAccount,
            year: selectedYear,
            month: bankTabExp.monthExp,
            account_expenses: bankTabExp.newExpen,
        };

        if (bankTabExp.newExpen !== "") {
            await fetch(`/addRealExpenses`, {
                method: "POST",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
                body: JSON.stringify(params),
            })
                .then((response) => {
                    if (response.ok) {
                        setTexto(t("miscellaneous.successfulUpdate"));
                        setAlertType("success");
                        setShow(true);
                        setBankTabExp({
                            monthExp: "",
                            regExpen: "",
                            newExpen: "",
                        });
                    } else {
                        setTexto(t("miscellaneous.updatingError"));
                        setAlertType("error");
                        setShow(true);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            setIsError(true);
            setTexto(t("miscellaneous.requiredData"));
            setAlertType("warning");
            setShow(true);
        }

        setBackDrop(false);
    };

    const closeDialog = () => {
        setBankTab("data");
        setBankTabExp({
            monthExp: "",
            regExpen: "",
            newExpen: "",
        });
        onClose();
    };

    const handleChangeInitDate = (date) => {
        setValuesBank({
            ...valuesBank,
            initDate: date ? date.startOf("month") : null,
        });
    };

    const handleChangeToDate = (date) => {
        setValuesBank({
            ...valuesBank,
            endDate: date ? date : null,
        });
    };

    const handleChangeMultiSelect = (event) => {
        const {
            target: { value },
        } = event;

        setValuesBank({
            ...valuesBank,
            requirements: typeof value === "string" ? value.split(",") : value,
            checksInc: value.includes("Estados de cuenta") && value.includes("Cheques") ? valuesBank.checksInc : false,
        });
    };

    const handleClickShowPassword = () => {
        setValuesBank({
            ...valuesBank,
            showPassword: !valuesBank.showPassword,
        });
    };

    const handleClickShowPIN = () => {
        setValuesBank({
            ...valuesBank,
            showPin: !valuesBank.showPin,
        });
    };

    const requestAccess = async () => {
        setBackDrop(true);

        setValuesBank({ ...valuesBank, isResquested: true });

        const params = {
            id_account: valuesBank.idAccount,
            company_id: valuesBank.orgID,
            company_name: valuesBank.orgName,
            account_name: valuesBank.accountName,
            token_set_id: todos.userInfo._id,
            user_name: todos.userInfo.user_name,
        };

        await fetch(`/requestAccessBank`, {
            method: "POST",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
            body: JSON.stringify(params),
        }).then((response) => {
            if (response.ok) {
                setTexto(t("principal.succReq"));
                setAlertType("success");
                setShow(true);
            } else {
                setTexto(t("principal.errorReq"));
                setAlertType("error");
                setShow(true);
            }
        });

        setBackDrop(false);
    };

    return (
        <CustomDialog
            open={open}
            onClose={closeDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="md"
            fullWidth={true}
        >
            <DialogTitle id="alert-dialog-title">
                <Grid container alignItems="center" justifyContent="space-between">
                    {/* <Grid item>{t("bank.bankData")}</Grid> */}
                    <Grid item>
                        {valuesBank.orgName} / <span style={{ color: "#ff5968" }}>{valuesBank.accountName}</span>
                    </Grid>
                    <Grid item>
                        {" "}
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={valuesBank.checkedNA}
                                    onChange={handleChangeCheckedNA}
                                    inputProps={{ "aria-label": "controlled" }}
                                    color="secondary"
                                    sx={{
                                        "& .MuiSwitch-switchBase .MuiSwitch-thumb": {
                                            color: "#FFF",
                                        },
                                        "& .MuiSwitch-switchBase + .MuiSwitch-track": {
                                            backgroundColor: "#ccc",
                                        },
                                        "& .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb": {
                                            color: "#FF5968",
                                        },
                                        "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                                            backgroundColor: "#FFA8B1",
                                        },
                                    }}
                                />
                            }
                            label={
                                <Typography variant="body1" sx={{ color: "white" }}>
                                    {t("dynamicLabels.disable")}
                                </Typography>
                            }
                        />
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent dividers>
                {valuesBank.software !== "Xero" && (
                    <StyledToggleButtonGroup
                        color="primary"
                        value={bankTab}
                        exclusive
                        onChange={handleChangeChat}
                        sx={{ pb: 4 }}
                    >
                        <ToggleButton value="data">{t("miscellaneous.data")}</ToggleButton>

                        <ToggleButton value="expen">{t("miscellaneous.expenses")}</ToggleButton>
                    </StyledToggleButtonGroup>
                )}
                <Typography variant="h5" color="secondary" sx={{ pb: 4 }}>
                    Datos de la cuenta bancaria
                </Typography>
                {bankTab === "data" && (
                    <LocalizationProvider
                        dateAdapter={AdapterMoment}
                        adapterLocale={t("language.locale")}
                        localeText={{ clearButtonLabel: t("miscellaneous.clear") }}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>
                                <FormControl
                                    fullWidth
                                    required={!valuesBank.checkedNA}
                                    disabled={valuesBank.checkedNA}
                                    error={isError && valuesBank.mode === "" ? true : false}
                                >
                                    <InputLabel id="mode-simple-select-label">{t("bank.mode")}</InputLabel>
                                    <Select
                                        name="mode"
                                        labelId="mode-simple-select-label"
                                        IconComponent={KeyboardArrowDownIcon}
                                        value={valuesBank.mode}
                                        label={t("bank.mode")}
                                        onChange={handleChange}
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    mt: 0.7,
                                                    borderRadius: 2,
                                                    boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                },
                                            },
                                        }}
                                    >
                                        <MenuItem value="0">Bank feeds</MenuItem>
                                        <MenuItem value="1">{t("bank.importM")}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    name="monitoringFreq"
                                    type="number"
                                    fullWidth
                                    required={!valuesBank.checkedNA}
                                    disabled={valuesBank.checkedNA}
                                    label={t("bank.monitoringfreq")}
                                    value={valuesBank.monitoringFreq}
                                    onChange={handleChange}
                                    variant="outlined"
                                    InputProps={{ inputProps: { min: 0 } }}
                                    error={isError && valuesBank.monitoringFreq === "" ? true : false}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    id="banks-autocomplete"
                                    value={valuesBank.bankName}
                                    options={banks}
                                    getOptionLabel={(option) => option}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={t("miscellaneous.bank")}
                                            required={!valuesBank.checkedNA}
                                            disabled={valuesBank.checkedNA}
                                            error={isError && valuesBank.bankName === "" ? true : false}
                                        />
                                    )}
                                    popupIcon={<KeyboardArrowDownIcon color="primary" />}
                                    PaperComponent={({ children }) => (
                                        <Paper
                                            sx={{
                                                mt: 0.7,
                                                borderRadius: 2,
                                                boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                            }}
                                        >
                                            {children}
                                        </Paper>
                                    )}
                                    onChange={(event, value, reason) => {
                                        if (reason === "clear") {
                                            setValuesBank({
                                                ...valuesBank,
                                                bankName: "",
                                            });
                                        } else {
                                            setValuesBank({
                                                ...valuesBank,
                                                bankName: value,
                                            });
                                        }
                                    }}
                                    disabled={valuesBank.checkedNA}
                                    clearText={t("miscellaneous.clear")}
                                />
                            </Grid>
                            <Grid item xs={12} sx={{ pb: 2, pt: 2 }}>
                                <Typography variant="h5" color="secondary">
                                    {t("miscellaneous.reqBankAcc")}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    disabled={valuesBank.checkedNA}
                                    value={valuesBank.initDate}
                                    onChange={(newValue) => handleChangeInitDate(newValue)}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => (
                                        <TextField
                                            required={!valuesBank.checkedNA}
                                            fullWidth
                                            {...params}
                                            error={isError && !valuesBank.initDate}
                                        />
                                    )}
                                    inputProps={{ readOnly: true }}
                                    views={["year", "month"]}
                                    label={t("miscellaneous.sinceDate")}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    name="receptionDays"
                                    type="number"
                                    fullWidth
                                    required={!valuesBank.checkedNA}
                                    disabled={valuesBank.checkedNA}
                                    label={t("bank.receptionDays")}
                                    value={valuesBank.receptionDays}
                                    onChange={handleChangeReceptionDays}
                                    variant="outlined"
                                    inputProps={{
                                        min: 1,
                                        max: 15,
                                        step: 1,
                                    }}
                                    error={isError && !valuesBank.receptionDays}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormControl
                                    fullWidth
                                    disabled={valuesBank.checkedNA}
                                    required={!valuesBank.checkedNA}
                                    error={isError && valuesBank.requirements.length === 0}
                                >
                                    <InputLabel id="demo-multiple-chip-label">{t("principal.requirements")}</InputLabel>
                                    <Select
                                        labelId="demo-multiple-chip-label"
                                        id="demo-multiple-chip"
                                        multiple
                                        value={valuesBank.requirements}
                                        onChange={handleChangeMultiSelect}
                                        input={
                                            <OutlinedInput
                                                id="select-multiple-chip"
                                                label={t("principal.requirements")}
                                            />
                                        }
                                        renderValue={(selected) => (
                                            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                                                {selected.map((value, index) => (
                                                    <Chip
                                                        key={index}
                                                        label={value}
                                                        sx={{
                                                            fontSize: 12,
                                                            backgroundColor:
                                                                index === 0
                                                                    ? "#C1BFDE"
                                                                    : index === 1
                                                                    ? "#FFCDD2"
                                                                    : "#ffe7e9",
                                                            height: "auto",
                                                        }}
                                                    />
                                                ))}
                                            </Box>
                                        )}
                                        MenuProps={MenuProps}
                                    >
                                        {requireNames.map((name) => (
                                            <MenuItem key={name} value={name}>
                                                <Checkbox checked={valuesBank.requirements.indexOf(name) > -1} />
                                                <ListItemText primary={name} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    disabled={!valuesBank.checkedNA}
                                    value={valuesBank.endDate}
                                    onChange={(newValue) => handleChangeToDate(newValue)}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => (
                                        <TextField
                                            required={valuesBank.checkedNA}
                                            fullWidth
                                            {...params}
                                            error={isError && !valuesBank.endDate}
                                        />
                                    )}
                                    inputProps={{ readOnly: true }}
                                    views={["year", "month", "day"]}
                                    label={t("miscellaneous.toDate")}
                                />
                            </Grid>
                            {valuesBank.requirements.includes(t("clientsTable.accStatements")) &&
                                valuesBank.requirements.includes(t("services.checks")) && (
                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    name="checkedMulti"
                                                    checked={valuesBank.checksInc}
                                                    onChange={(value) => handleChangeChecksInc(value)}
                                                    inputProps={{ "aria-label": "controlled" }}
                                                    disabled={valuesBank.checkedNA}
                                                />
                                            }
                                            label={t("services.checksInStatement")}
                                        />
                                    </Grid>
                                )}
                            <Grid item xs={12} className={classes.bankDiv}>
                                <Typography variant="h5" color="secondary">
                                    Acceso al banco
                                </Typography>
                                {!usersAccess.includes(todos.userInfo.email) &&
                                    !valuesBank.hasAccess &&
                                    (!valuesBank.isResquested ? (
                                        <Button
                                            variant="contained"
                                            className={classes.accessButton}
                                            size="small"
                                            endIcon={<LockOpenIcon />}
                                            onClick={requestAccess}
                                        >
                                            {t("principal.getAccess")}
                                        </Button>
                                    ) : (
                                        <Button
                                            variant="contained"
                                            className={classes.accessButton}
                                            disabled
                                            size="small"
                                            endIcon={<AccessTimeIcon />}
                                        >
                                            {t("principal.requested")}
                                        </Button>
                                    ))}
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    fullWidth
                                    label={t("miscellaneous.user")}
                                    name="user"
                                    variant="outlined"
                                    disabled={!usersAccess.includes(todos.userInfo.email) && !valuesBank.hasAccess}
                                    value={
                                        usersAccess.includes(todos.userInfo.email) || valuesBank.hasAccess
                                            ? valuesBank.user
                                            : valuesBank.user
                                            ? "••••••"
                                            : ""
                                    }
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormControl
                                    fullWidth
                                    variant="outlined"
                                    disabled={!usersAccess.includes(todos.userInfo.email) && !valuesBank.hasAccess}
                                >
                                    <InputLabel htmlFor="outlined-adornment-password">{t("login.password")}</InputLabel>
                                    <OutlinedInput
                                        fullWidth
                                        label={t("login.password")}
                                        name="password"
                                        type={valuesBank.showPassword ? "text" : "password"}
                                        variant="outlined"
                                        value={
                                            usersAccess.includes(todos.userInfo.email) || valuesBank.hasAccess
                                                ? valuesBank.password
                                                : valuesBank.password
                                                ? "••••••••"
                                                : ""
                                        }
                                        onChange={handleChange}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                {(usersAccess.includes(todos.userInfo.email) ||
                                                    valuesBank.hasAccess) && (
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDown}
                                                        edge="end"
                                                    >
                                                        {valuesBank.showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                )}
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormControl
                                    fullWidth
                                    variant="outlined"
                                    disabled={!usersAccess.includes(todos.userInfo.email) && !valuesBank.hasAccess}
                                >
                                    <InputLabel htmlFor="outlined-adornment-pin">PIN</InputLabel>
                                    <OutlinedInput
                                        fullWidth
                                        label="PIN"
                                        name="pin"
                                        type={valuesBank.showPin ? "text" : "password"}
                                        variant="outlined"
                                        value={
                                            usersAccess.includes(todos.userInfo.email) || valuesBank.hasAccess
                                                ? valuesBank.pin
                                                : valuesBank.pin
                                                ? "••••"
                                                : ""
                                        }
                                        onChange={handleChange}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                {(usersAccess.includes(todos.userInfo.email) ||
                                                    valuesBank.hasAccess) && (
                                                    <IconButton
                                                        aria-label="toggle pin visibility"
                                                        onClick={handleClickShowPIN}
                                                        onMouseDown={handleMouseDown}
                                                        edge="end"
                                                    >
                                                        {valuesBank.showPin ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                )}
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label={t("principal.securityQuestCom")}
                                    name="description"
                                    value={
                                        usersAccess.includes(todos.userInfo.email) || valuesBank.hasAccess
                                            ? valuesBank.description
                                            : valuesBank.description
                                            ? "••••••••••••••••••••••••••••••••••••"
                                            : ""
                                    }
                                    onChange={handleChange}
                                    variant="outlined"
                                    multiline
                                    rows={4}
                                    inputProps={{ maxLength: 2000 }}
                                    disabled={!usersAccess.includes(todos.userInfo.email) && !valuesBank.hasAccess}
                                />
                            </Grid>
                        </Grid>
                    </LocalizationProvider>
                )}
                {bankTab === "expen" && (
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <FormControl
                                fullWidth
                                required={!valuesBank.checkedNA}
                                error={isError && bankTabExp.monthExp === ""}
                            >
                                <InputLabel id="mode-simple-select-label">{t("bankingDashboard.month")}</InputLabel>
                                <Select
                                    name="monthExp"
                                    labelId="monthExp-simple-select-label"
                                    IconComponent={KeyboardArrowDownIcon}
                                    value={bankTabExp.monthExp}
                                    label={t("bankingDashboard.month")}
                                    onChange={handleChangeExp}
                                    MenuProps={{
                                        PaperProps: {
                                            sx: {
                                                mt: 0.7,
                                                borderRadius: 2,
                                                boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                            },
                                        },
                                    }}
                                >
                                    <MenuItem value={"01"}>{t("monthNames.january")}</MenuItem>
                                    <MenuItem value={"02"}>{t("monthNames.february")}</MenuItem>
                                    <MenuItem value={"03"}>{t("monthNames.march")}</MenuItem>
                                    <MenuItem value={"04"}>{t("monthNames.april")}</MenuItem>
                                    <MenuItem value={"05"}>{t("monthNames.may")}</MenuItem>
                                    <MenuItem value={"06"}>{t("monthNames.june")}</MenuItem>
                                    <MenuItem value={"07"}>{t("monthNames.july")}</MenuItem>
                                    <MenuItem value={"08"}>{t("monthNames.august")}</MenuItem>
                                    <MenuItem value={"09"}>{t("monthNames.september")}</MenuItem>
                                    <MenuItem value={"10"}>{t("monthNames.october")}</MenuItem>
                                    <MenuItem value={"11"}>{t("monthNames.november")}</MenuItem>
                                    <MenuItem value={"12"}>{t("monthNames.december")}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FormControl fullWidth variant="outlined" shrink disabled>
                                <InputLabel shrink htmlFor="outlined-adornment-regExpen">
                                    {t("miscellaneous.regExpen")}
                                </InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-regExpen"
                                    name="regExpen"
                                    fullWidth
                                    notched
                                    inputComponent={NumberFormatCustom}
                                    startAdornment={
                                        <InputAdornment disableTypography position="start">
                                            $
                                        </InputAdornment>
                                    }
                                    label={t("miscellaneous.regExpen")}
                                    value={bankTabExp.regExpen}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FormControl
                                fullWidth
                                variant="outlined"
                                required={!valuesBank.checkedNA}
                                error={isError && bankTabExp.newExpen === ""}
                                disabled={valuesBank.checkedNA}
                            >
                                <InputLabel htmlFor="outlined-adornment-newExpen">
                                    {t("miscellaneous.newExpen")}
                                </InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-newExpen"
                                    name="newExpen"
                                    disabled={valuesBank.checkedNA}
                                    fullWidth
                                    inputComponent={NumberFormatCustom}
                                    startAdornment={
                                        <InputAdornment disableTypography={valuesBank.checkedNA} position="start">
                                            $
                                        </InputAdornment>
                                    }
                                    label={t("miscellaneous.newExpen")}
                                    value={bankTabExp.newExpen}
                                    onChange={handleChangeNewExp}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                )}
            </DialogContent>
            <DialogActions>
                <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                    <Grid item>
                        <ColorButton
                            sx={{
                                mr: 1,
                                "&:hover": {
                                    backgroundColor: "white",
                                    color: "#2f3190",
                                    border: "1px solid #2f3190",
                                },
                            }}
                            color="secondary"
                            disableElevation
                            variant="outlined"
                            onClick={closeDialog}
                        >
                            {t("miscellaneous.cancel")}
                        </ColorButton>
                    </Grid>
                    <Grid item>
                        <ColorButton
                            sx={{
                                "&:hover": {
                                    backgroundColor: "#2f3190",
                                    border: "1px solid #2f3190",
                                },
                            }}
                            color="secondary"
                            disableElevation
                            variant="contained"
                            onClick={bankTab === "expen" ? updateExpenses : updateBankAccount}
                        >
                            {t("miscellaneous.save")}
                        </ColorButton>
                    </Grid>
                </Grid>
            </DialogActions>
            <DialogAlerts
                open={openAlert}
                onClose={() => setOpenAlert(false)}
                agreeBtnLabel={t("miscellaneous.accept")}
                disagreeBtnLabel={t("miscellaneous.cancel")}
                type={"info"}
                title={dialogText}
                agreeAction={insertExpenses}
            />
        </CustomDialog>
    );
}
