/** React imports */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { NumericFormat } from "react-number-format";
import moment from "moment";

/** MUI icons */
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

/** MUI imports */
import { styled } from "@mui/material/styles";
import {
    Button,
    Card,
    CardHeader,
    CardContent,
    Divider,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    FormControl,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
    Typography,
    Checkbox,
    FormControlLabel,
} from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

/** Component imports */
import Services from "../../Js/servicesInvoicing";
import Projects from "../../Js/projects";

var filterServices = Services.filter((item) => item.code !== 1 && item.code !== 6 && item.code !== 7);
var filterFranqServices = Services.filter(
    (item) =>
        item.code === 5 ||
        item.code === 8 ||
        item.code === 9 ||
        item.code === 10 ||
        item.code === 11 ||
        item.code === 13
);

const latinCountries = [
    "Argentina",
    "Bolivia",
    "Brasil",
    "Chile",
    "Colombia",
    "Costa Rica",
    "Cuba",
    "Ecuador",
    "El Salvador",
    "Guatemala",
    "Haití",
    "Honduras",
    "México",
    "Nicaragua",
    "Panamá",
    "Paraguay",
    "Perú",
    "República Dominicana",
    "Uruguay",
];

const CustomDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(4),
    },
    "& .MuiDialogTitle-root": {
        backgroundColor: "#031851",
        fontSize: "18px",
        color: "#fff",
    },
    "& .MuiDialog-paper": {
        borderRadius: 8,
        boxShadow: "10px 10px 15px rgba(3,24,81,0.15)",
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(2),
    },
}));

const ColorButton = styled(Button)(({ theme }) => ({
    border: "1px solid #ff5968",
    borderRadius: 40,
}));

const NumberFormatCustom = (props) => {
    const todos = useSelector((state) => state.todos);

    const { inputRef, value, onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            value={value}
            getInputRef={inputRef}
            onValueChange={({ value: v }) => onChange({ target: { value: v } })}
            thousandSeparator={todos.amountFormat === "de-DE" ? "." : ","}
            decimalSeparator={todos.amountFormat === "de-DE" ? "," : "."}
            fixedDecimalScale={true}
            decimalScale={2}
            valueIsNumericString
        />
    );
};

const defaultAddNew = {
    disabledFranq: false,
    franq: "",
    group: "",
    client: "",
    subsInv: "",
    documentType: "",
    project: "",
    service: "",
    sinceDate: null,
    untilDate: null,
    description: "",
    hours: 1,
    baseFee: 0,
    fee: 0,
    discount: 0,
    originalFee: "",
    invoiceOverload: false,
    scheduledShipDate: null,
    package: "",
    estimatedDeliveryDate: null,
    deliveryDate: null,
};

const ModAddInvoice = (props) => {
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    /** React redux */
    const todos = useSelector((state) => state.todos);

    /** Component states */
    const [isError, setisError] = useState(false);
    const [groupData, setGroupData] = useState([]);
    const [franqData, setFranqData] = useState([]);
    const [clientsData, setClientsData] = useState([]);
    const [projects, setProjects] = useState([]);
    const [country, setCountry] = useState("US");
    const [isCheck, setIsCheck] = useState(false);
    const [valuesAddNew, setValuesAddNew] = useState(defaultAddNew);

    /** Component variables */
    let isRequired =
        valuesAddNew.project === 5 ||
        valuesAddNew.project === 6 ||
        valuesAddNew.project === 10 ||
        valuesAddNew.project === 11 ||
        valuesAddNew.project === 15 ||
        valuesAddNew.project === 16 ||
        valuesAddNew.project === 19 ||
        valuesAddNew.project === 20 ||
        valuesAddNew.project === 26 ||
        valuesAddNew.project === 27 ||
        valuesAddNew.project === 30 ||
        valuesAddNew.project === 33 ||
        valuesAddNew.project === 35 ||
        (valuesAddNew.project >= 45 && valuesAddNew.project <= 55) ||
        valuesAddNew.project === 67 ||
        valuesAddNew.project === 50 ||
        valuesAddNew.project === 68;

    let isRequiredCheck =
        valuesAddNew.service === 11 ||
        valuesAddNew.project === 5 ||
        valuesAddNew.project === 6 ||
        valuesAddNew.project === 10 ||
        valuesAddNew.project === 11 ||
        valuesAddNew.project === 15 ||
        valuesAddNew.project === 16 ||
        valuesAddNew.project === 19 ||
        valuesAddNew.project === 20 ||
        valuesAddNew.project === 26 ||
        valuesAddNew.project === 27 ||
        valuesAddNew.project === 30 ||
        valuesAddNew.project === 33 ||
        valuesAddNew.project === 35 ||
        valuesAddNew.project === 50 ||
        valuesAddNew.project === 67;

    let isRequiredConsulting =
        valuesAddNew.service === 11 ||
        valuesAddNew.project === 5 ||
        valuesAddNew.project === 10 ||
        valuesAddNew.project === 15 ||
        valuesAddNew.project === 19 ||
        valuesAddNew.project === 26 ||
        valuesAddNew.project === 50 ||
        valuesAddNew.project === 35 ||
        valuesAddNew.project === 36;

    let isRequiredFee =
        valuesAddNew.project === 6 ||
        valuesAddNew.project === 11 ||
        valuesAddNew.project === 16 ||
        valuesAddNew.project === 20 ||
        valuesAddNew.project === 27 ||
        valuesAddNew.project === 30 ||
        valuesAddNew.project === 33 ||
        valuesAddNew.project === 67;

    /** Component functions */
    const handleReset = () => {
        setProjects([]);
        setValuesAddNew(defaultAddNew);
    };

    const handleCloseAddDialog = () => {
        handleReset();
        props.onClose();
    };

    const handleChangeValuesAddNew = (event) => {
        setValuesAddNew({
            ...valuesAddNew,
            [event.target.name]: event.target.value,
        });
    };

    const handleChangePackage = (event) => {
        let hoursNew = 1;
        let fee = 0;

        if (event.target.value === 1) {
            hoursNew = 4;
        } else if (event.target.value === 2) {
            hoursNew = 8;
        } else if (event.target.value === 3) {
            hoursNew = 16;
        }

        if (country === "US") {
            fee = 60;
        }

        if (country === "VE") {
            fee = 36;
        }

        if (country === "LA") {
            fee = 42;
        }

        setValuesAddNew({
            ...valuesAddNew,
            package: event.target.value,
            hours: hoursNew,
            fee: hoursNew * fee,
            originalFee: fee,
        });
    };

    const handleChangeDiscount = (event) => {
        let discountAux = 0;

        discountAux = event.target.value >= 0 && event.target.value <= 100 ? event.target.value : valuesAddNew.discount;

        setValuesAddNew({
            ...valuesAddNew,
            discount: discountAux,
            fee: (valuesAddNew.baseFee ?? valuesAddNew.originalFee) * valuesAddNew.hours * (1 - discountAux / 100),
        });
    };

    const handleChangeInvCheck = (event) => {
        setIsCheck(true);

        setValuesAddNew({
            ...valuesAddNew,
            invoiceOverload: event.target.checked,
            fee: event.target.checked ? valuesAddNew.fee * 1.3 : valuesAddNew.originalFee * valuesAddNew.hours,
        });
    };

    const handleChangeFranq = async (event) => {
        props.setBackDrop(true);

        if (event.target.value.trim()) {
            let franqInfo = franqData.find((item) => item.id === event.target.value);

            if (franqInfo.country_name === "Venezuela") {
                setCountry("VE");
            } else if (franqInfo.country_name === "Estados Unidos") {
                setCountry("US");
            } else if (latinCountries.includes(franqInfo.country_name)) {
                setCountry("LA");
            } else {
                setCountry("GB");
            }

            setValuesAddNew({
                ...valuesAddNew,
                franq: event.target.value,
                group: " ",
                client: " ",
                project: "",
                service: "",
                baseFee: 0,
                fee: 0,
                discount: 0,
                invoiceOverload: false,
                hours: 1,
                subsInv: franqInfo.invoicing_subscription ?? "",
            });
        } else {
            if (valuesAddNew.group.trim()) {
                await fetch(`/getPracticeDetails?practice_id=${valuesAddNew.group}`, {
                    method: "GET",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                    },
                })
                    .then((res) => res.json())
                    .then((data) => {
                        setValuesAddNew({
                            ...valuesAddNew,
                            franq: data.franchise_id ? data.franchise_id : event.target.value,
                            group: " ",
                            client: " ",
                            baseFee: 0,
                            fee: 0,
                            discount: 0,
                            disabledFranq: data.franchise_id ? true : false,
                            invoiceOverload: false,
                            hours: 1,
                            subsInv: data.invoicing_subscription ?? "",
                        });
                    });
            } else {
                setValuesAddNew({
                    ...valuesAddNew,
                    franq: event.target.value,
                    group: " ",
                    client: " ",
                    project: "",
                    service: "",
                    baseFee: 0,
                    fee: 0,
                    discount: 0,
                    invoiceOverload: false,
                    hours: 1,
                });
            }
        }

        await fetch(`/getFranchiseGroups?id_franchise=${event.target.value.trim()}`, {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then((res) => res.json())
            .then((data) => {
                setGroupData(data);
            });
    };

    const handleChangeGroup = async (event) => {
        props.setBackDrop(true);

        if (event.target.value.trim()) {
            let servicesAll = [];

            await fetch(`/getPracticeDetails?practice_id=${event.target.value}`, {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
                .then((res) => res.json())
                .then((data) => {
                    if (!valuesAddNew.franq.trim() || data.franchise_id) {
                        if (data.franchise_id) {
                            servicesAll = Services.filter(
                                (item) =>
                                    item.code === 5 ||
                                    item.code === 8 ||
                                    item.code === 9 ||
                                    item.code === 10 ||
                                    item.code === 11 ||
                                    item.code === 13
                            );
                        } else {
                            servicesAll = Services.filter(
                                (item) =>
                                    item.code !== 5 &&
                                    item.code !== 6 &&
                                    item.code !== 7 &&
                                    item.code !== 9 &&
                                    item.code !== 12
                            );
                        }

                        setValuesAddNew({
                            ...valuesAddNew,
                            group: event.target.value,
                            franq: data.franchise_id ?? " ",
                            client: " ",
                            project: "",
                            service: "",
                            baseFee: 0,
                            fee: 0,
                            discount: 0,
                            disabledFranq: data.franchise_id ? true : false,
                            invoiceOverload: false,
                            hours: 1,
                            subsInv: data.invoicing_subscription ?? "",
                        });
                        let country =
                            data.country_name === "Venezuela"
                                ? "VE"
                                : data.country_name === "Estados Unidos"
                                ? "US"
                                : latinCountries.includes(data.country_name)
                                ? "LA"
                                : "";

                        setCountry(country);
                    } else {
                        servicesAll = Services.filter(
                            (item) =>
                                item.code !== 5 &&
                                item.code !== 6 &&
                                item.code !== 7 &&
                                item.code !== 9 &&
                                item.code !== 12
                        );

                        if (valuesAddNew.client.trim()) {
                            setValuesAddNew({
                                ...valuesAddNew,
                                group: event.target.value,
                                franq: data.franchise_id ?? " ",
                                client: " ",
                                project: "",
                                service: "",
                                baseFee: 0,
                                fee: 0,
                                discount: 0,
                                disabledFranq: false,
                                invoiceOverload: false,
                                hours: 1,
                                subsInv: data.invoicing_subscription ?? "",
                            });
                        } else {
                            setValuesAddNew({
                                ...valuesAddNew,
                                group: event.target.value,
                                franq:
                                    data.franchise_id !== undefined && data.franchise_id !== null
                                        ? data.franchise_id
                                        : " ",
                                client: " ",
                                baseFee: 0,
                                fee: 0,
                                discount: 0,
                                disabledFranq: false,
                                invoiceOverload: false,
                                hours: 1,
                                subsInv: data.invoicing_subscription ?? "",
                            });
                        }
                    }
                });
            filterServices = servicesAll;
        } else {
            if (valuesAddNew.client.trim()) {
                setValuesAddNew({
                    ...valuesAddNew,
                    group: event.target.value,
                    client: " ",
                    project: "",
                    service: "",
                    baseFee: 0,
                    fee: 0,
                    disabledFranq: false,
                    invoiceOverload: false,
                    hours: 1,
                });
            } else {
                setValuesAddNew({
                    ...valuesAddNew,
                    group: event.target.value,
                    client: " ",
                    baseFee: 0,
                    fee: 0,
                    discount: 0,
                    disabledFranq: false,
                    invoiceOverload: false,
                    hours: 1,
                });
            }
        }
    };

    const handleChangeClient = async (event) => {
        props.setBackDrop(true);

        let subsInv = "";
        if (event.target.value.trim()) {
            await fetch(`/getInvoicingInfo?id_organisation=${event.target.value}`, {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
                .then((res) => res.json())
                .then((res) => {
                    subsInv = res.invoicing_subscription;
                });

            let countryAux = "";
            let servicesAll = [];
            let isFirm = false;

            clientsData.forEach((value) => {
                if (value._id === event.target.value) {
                    if (value.franchise_id) {
                        isFirm = true;
                    }
                    if (value.country_name === "Venezuela") {
                        setCountry("VE");
                        countryAux = "VE";
                    } else if (value.country_name === "Estados Unidos") {
                        setCountry("US");
                        countryAux = "US";
                    } else if (latinCountries.includes(value.country_name)) {
                        setCountry("LA");
                        countryAux = "LA";
                    } else {
                        setCountry("GB");
                        countryAux = "GB";
                    }
                }
            });

            if (isFirm) {
                servicesAll = Services.filter(
                    (item) => item.code === 5 || item.code === 10 || item.code === 11 || item.code === 13
                );
            } else {
                servicesAll = Services.filter(
                    (item) =>
                        item.code !== 5 && item.code !== 6 && item.code !== 7 && item.code !== 8 && item.code !== 9
                );
            }

            filterServices = servicesAll.filter((service) => service.country.includes(countryAux));
        } else {
            filterServices = Services.filter((item) => item.code !== 1 && item.code !== 6 && item.code !== 7);
        }

        setValuesAddNew({
            ...valuesAddNew,
            client: event.target.value,
            subsInv: event.target.value.trim() ? subsInv : "",
            project: "",
            service: "",
            baseFee: 0,
            fee: 0,
            discount: 0,
            invoiceOverload: false,
            hours: 1,
        });

        props.setBackDrop(false);
    };

    const handleChangeFact = (event) => {
        setValuesAddNew({
            ...valuesAddNew,
            [event.target.name]: event.target.value,
        });
    };

    const handleChangeDocumentType = (event) => {
        setValuesAddNew({
            ...valuesAddNew,
            documentType: event.target.value,
        });
    };

    const handleChangeType = async (event) => {
        props.setBackDrop(true);

        let serviceName = filterServices.filter((item) => item.code === valuesAddNew.service);
        let projectName = projects.filter((item) => item.code === event.target.value);

        serviceName = t(`invoicing.${serviceName[0].name}`);
        projectName = event.target.value === 35 ? projectName[0].alt_name : t(`invoicing.${projectName[0].name}`);

        await fetch(
            `/getManualServiceFee?service=${serviceName}&project=${projectName}&franchise_id=${valuesAddNew.franq.trim()}&practice_id=${valuesAddNew.group.trim()}&company_id=${valuesAddNew.client.trim()}`,
            {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            }
        )
            .then((res) => res.json())
            .then((data) => {
                let fee = data;

                if (projectName === "Kiiper Banking Basic Plus") {
                    fee = 69.99;
                }

                if (projectName === "Kiiper Banking Professional Plus") {
                    fee = 209.99;
                }

                if (projectName === "Kiiper Banking Advanced Plus") {
                    fee = 329.99;
                }

                if (projectName === "Xero subscription warranty deposit" && data === -1) {
                    fee = 0;
                    props.setTexto(t("invoicing.notHaveXeroService"));
                    props.setAutoAlertType("warning");
                    props.setShow(true);
                }

                if (projectName === "Customer support" && country === "VE") {
                    fee = 25;
                }
                if (projectName === "Analyst fee" && country === "VE") {
                    fee = 15;
                }
                if (projectName === "Leader fee" && country === "VE") {
                    fee = 40;
                }
                if (projectName === "Manager fee" && country === "VE") {
                    fee = 70;
                }
                if (projectName === "Director fee" && country === "VE") {
                    fee = 160;
                }
                if (projectName === "Partner fee" && country === "VE") {
                    fee = 220;
                }

                if (projectName === "Customer support" && country === "LA") {
                    fee = 30;
                }
                if (projectName === "Analyst fee" && country === "LA") {
                    fee = 18;
                }
                if (projectName === "Leader fee" && country === "LA") {
                    fee = 45;
                }
                if (projectName === "Manager fee" && country === "LA") {
                    fee = 80;
                }
                if (projectName === "Director fee" && country === "LA") {
                    fee = 190;
                }
                if (projectName === "Partner fee" && country === "LA") {
                    fee = 255;
                }

                setValuesAddNew({
                    ...valuesAddNew,
                    baseFee: fee,
                    fee: fee,
                    originalFee: fee,
                    project: event.target.value,
                    description: "",
                    invoiceOverload: false,
                    hours: 1,
                    discount: 0,
                });
            });

        props.setBackDrop(false);
    };

    const handleChangeService = (event) => {
        let projects = [];
        let aux = [];

        switch (event.target.value) {
            case 1:
                if (valuesAddNew.client.trim()) {
                    projects = Projects.filter((project) => project.code === 6 && project.country.includes(country));
                } else {
                    projects = Projects.filter(
                        (project) => (project.code === 5 || project.code === 6) && project.country.includes(country)
                    );
                }
                break;
            case 2:
                if (valuesAddNew.client.trim()) {
                    projects = Projects.filter((project) => project.code === 11 && project.country.includes(country));
                } else {
                    projects = Projects.filter(
                        (project) => (project.code === 10 || project.code === 11) && project.country.includes(country)
                    );
                }
                break;
            case 3:
                if (valuesAddNew.client.trim()) {
                    projects = Projects.filter((project) => project.code === 16 && project.country.includes(country));
                } else {
                    projects = Projects.filter(
                        (project) => (project.code === 15 || project.code === 16) && project.country.includes(country)
                    );
                }
                break;
            case 4:
                if (valuesAddNew.client.trim()) {
                    projects = Projects.filter((project) => project.code === 20 && project.country.includes(country));
                } else {
                    projects = Projects.filter(
                        (project) => (project.code === 19 || project.code === 20) && project.country.includes(country)
                    );
                }
                break;
            case 5:
                if (valuesAddNew.client.trim()) {
                    projects = Projects.filter((project) => project.code === 27 && project.country.includes(country));
                } else {
                    projects = Projects.filter(
                        (project) => (project.code === 26 || project.code === 27) && project.country.includes(country)
                    );
                }
                break;
            case 6:
                projects = Projects.filter((project) => project.code === 30 && project.country.includes(country));
                break;
            case 7:
                projects = Projects.filter((project) => project.code === 33 && project.country.includes(country));
                break;
            case 8:
                projects = Projects.filter(
                    (project) => (project.code === 50 || project.code === 36) && project.country.includes(country)
                );
                break;
            case 9:
                if (valuesAddNew.client.trim()) {
                    projects = Projects.filter(
                        (project) => (project.code === 43 || project.code === 44) && project.country.includes(country)
                    );
                } else {
                    projects = Projects.filter(
                        (project) =>
                            (project.code === 71 || project.code === 72 || project.code === 73) &&
                            project.country.includes(country)
                    );
                }
                break;
            case 10:
                if (valuesAddNew.client.trim()) {
                    projects = Projects.filter(
                        (project) =>
                            (project.code === 27 || project.code === 45 || project.code === 68) &&
                            project.country.includes(country)
                    );
                    projects.push(projects.shift());
                } else {
                    projects = Projects.filter((project) => project.code === 27 && project.country.includes(country));
                }
                break;
            case 11:
                aux = Projects.slice(48, 58);
                projects = aux.filter((project) => project.country.includes(country));
                break;
            case 12:
                aux = Projects.slice(58, 67);
                projects = aux.filter((project) => project.country.includes(country));
                break;
            case 13:
                aux = Projects.slice(67, 69);
                projects = aux.filter((project) => project.country.includes(country));
                break;
            default:
                break;
        }

        setProjects(projects);

        if (event.target.value !== 6) {
            setValuesAddNew({
                ...valuesAddNew,
                service: event.target.value,
                project: "",
                baseFee: 0,
                fee: 0,
                invoiceOverload: false,
                hours: 1,
                discount: 0,
            });
        } else {
            setValuesAddNew({
                ...valuesAddNew,
                service: event.target.value,
                project: projects[0].code,
                baseFee: 0,
                fee: 0,
                invoiceOverload: false,
                hours: 1,
                discount: 0,
            });
        }
    };

    const handleChangeScheduledShipDate = (date) => {
        setValuesAddNew({
            ...valuesAddNew,
            scheduledShipDate: date,
        });
    };

    const handleChangeSinceDate = (date) => {
        if (date === null) {
            setValuesAddNew({
                ...valuesAddNew,
                sinceDate: date,
            });
        } else if (valuesAddNew.untilDate === null) {
            setValuesAddNew({
                ...valuesAddNew,
                sinceDate: moment(date).format("YYYY-MM-DD"),
            });
        } else {
            if (moment(date).isAfter(moment(valuesAddNew.untilDate))) {
                setValuesAddNew({
                    ...valuesAddNew,
                    sinceDate: null,
                });
                props.setTexto(t("reports.initDateMess"));
                props.setAutoAlertType("warning");
                props.setShow(true);
            } else {
                setValuesAddNew({
                    ...valuesAddNew,
                    sinceDate: moment(date).format("YYYY-MM-DD"),
                });
            }
        }
    };

    const handleChangeUntilDate = (date) => {
        if (date === null) {
            setValuesAddNew({
                ...valuesAddNew,
                untilDate: date,
            });
        } else if (valuesAddNew.sinceDate === null) {
            setValuesAddNew({
                ...valuesAddNew,
                untilDate: moment(date).format("YYYY-MM-DD"),
            });
        } else {
            if (moment(date).isBefore(moment(valuesAddNew.sinceDate))) {
                setValuesAddNew({
                    ...valuesAddNew,
                    untilDate: null,
                });
                props.setTexto(t("reports.endDateMess"));
                props.setAutoAlertType("warning");
                props.setShow(true);
            } else {
                setValuesAddNew({
                    ...valuesAddNew,
                    untilDate: moment(date).format("YYYY-MM-DD"),
                });
            }
        }
    };

    const handleChangeEstimatedDate = (date) => {
        if (date === null) {
            setValuesAddNew({
                ...valuesAddNew,
                estimatedDeliveryDate: date,
                sinceDate: date,
                untilDate: date,
            });
        } else if (valuesAddNew.sinceDate === null) {
            setValuesAddNew({
                ...valuesAddNew,
                estimatedDeliveryDate: moment(date).format("YYYY-MM-DD"),
                sinceDate: moment(date).format("YYYY-MM-DD"),
                untilDate: moment(date).format("YYYY-MM-DD"),
            });
        } else {
            if (moment(date).isBefore(moment(valuesAddNew.sinceDate))) {
                setValuesAddNew({
                    ...valuesAddNew,
                    estimatedDeliveryDate: null,
                    sinceDate: null,
                    untilDate: null,
                });
                props.setTexto(t("reports.endDateMess"));
                props.setAutoAlertType("warning");
                props.setShow(true);
            } else {
                setValuesAddNew({
                    ...valuesAddNew,
                    estimatedDeliveryDate: moment(date).format("YYYY-MM-DD"),
                    sinceDate: moment(date).format("YYYY-MM-DD"),
                    untilDate: moment(date).format("YYYY-MM-DD"),
                });
            }
        }
    };

    const handleChangeDeliveryDate = (date) => {
        setValuesAddNew({
            ...valuesAddNew,
            deliveryDate: date,
        });
    };

    const handleChangeFee = (event) => {
        if (isCheck) {
            setIsCheck(false);
        } else {
            setValuesAddNew({
                ...valuesAddNew,
                fee: event.target.value * (1 - valuesAddNew.discount / 100),
                originalFee: event.target.value,
            });
        }
    };

    const handleChangeBaseFee = (event) => {
        setValuesAddNew({
            ...valuesAddNew,
            baseFee: event.target.value,
            fee: event.target.value * (1 - valuesAddNew.discount / 100),
        });
    };

    const handleChangeH = (event) => {
        setIsCheck(true);

        setValuesAddNew({
            ...valuesAddNew,
            fee: valuesAddNew.invoiceOverload
                ? event.target.value * valuesAddNew.originalFee * 1.3 * (1 - valuesAddNew.discount / 100)
                : event.target.value * valuesAddNew.originalFee * (1 - valuesAddNew.discount / 100),
            hours: Math.round(event.target.value * 2) / 2,
        });
    };

    const addNewInvoicingRecord = async () => {
        props.setBackDrop(true);

        let isValid = true;
        let isValidHours = true;
        let isValidFee = true;

        if (isRequired && !valuesAddNew.description) {
            isValid = false;
        }

        if (isRequiredFee && (!parseInt(valuesAddNew.fee) || !valuesAddNew.fee)) {
            isValidFee = false;
        }

        if (isRequiredConsulting && (!valuesAddNew.hours || valuesAddNew.hours <= 0)) {
            isValidHours = false;
        }

        if (!valuesAddNew.franq.trim() && !valuesAddNew.group.trim() && !valuesAddNew.client.trim()) {
            props.setTexto(t("invoicing.mustSelectOne"));
            props.setAutoAlertType("warning");
            props.setShow(true);
        } else {
            if (
                valuesAddNew.subsInv &&
                valuesAddNew.documentType &&
                valuesAddNew.project &&
                valuesAddNew.service &&
                ((valuesAddNew.sinceDate && valuesAddNew.untilDate) || (isRequiredFee && valuesAddNew.deliveryDate)) &&
                isValid &&
                isValidHours &&
                isValidFee
            ) {
                if (isRequiredFee && parseInt(valuesAddNew.baseFee) === 0) {
                    props.setTexto(t("invoicing.errorZeroFee"));
                    props.setAutoAlertType("error");
                    props.setShow(true);
                    props.setBackDrop(false);
                    return;
                }

                let isSoftFranq =
                    valuesAddNew.project === 71 || valuesAddNew.project === 72 || valuesAddNew.project === 73;
                let newDesc =
                    valuesAddNew.project === 71
                        ? "Basic Plus"
                        : valuesAddNew.project === 72
                        ? "Professional Plus"
                        : "Advanced Plus";

                const params = {
                    franchise_id: valuesAddNew.franq.trim(),
                    practice_id: valuesAddNew.group.trim(),
                    company_id: valuesAddNew.client.trim(),
                    invoicing_subscription: valuesAddNew.subsInv,
                    document_type: valuesAddNew.documentType,
                    project_type: isSoftFranq ? 41 : valuesAddNew.project,
                    service_type: valuesAddNew.service,
                    initial_date: valuesAddNew.deliveryDate
                        ? valuesAddNew.deliveryDate.format("YYYY-MM-DD")
                        : valuesAddNew.sinceDate,
                    end_date: valuesAddNew.deliveryDate
                        ? valuesAddNew.deliveryDate.format("YYYY-MM-DD")
                        : valuesAddNew.untilDate,
                    amount: parseFloat(valuesAddNew.fee),
                    discount: parseFloat(valuesAddNew.discount),
                    description: isSoftFranq ? newDesc : valuesAddNew.description,
                    estimated_delivery_date: valuesAddNew.scheduledShipDate,
                    hours: isRequiredConsulting ? parseInt(valuesAddNew.hours) : undefined,
                    surcharge: isRequiredCheck ? valuesAddNew.invoiceOverload : undefined,
                    package: isRequiredConsulting ? valuesAddNew.package : undefined,
                    estimatedDeliveryDate: isRequiredConsulting ? valuesAddNew.estimatedDeliveryDate : undefined,
                };

                await fetch(`/addLineItem`, {
                    method: "POST",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                    },
                    body: JSON.stringify(params),
                })
                    .then((response) => {
                        if (response.ok) {
                            handleCloseAddDialog();
                            props.loadInitData();
                            props.setTexto(t("invoicing.lineItemCreation"));
                            props.setAutoAlertType("success");
                            props.setShow(true);
                        } else {
                            props.setTexto(t("invoicing.newLineItemError"));
                            props.setAutoAlertType("error");
                            props.setShow(true);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } else {
                props.setTexto(t("miscellaneous.requiredData"));
                props.setAutoAlertType("warning");
                props.setShow(true);
                setisError(true);
            }
        }

        props.setBackDrop(false);
    };

    /** Component events */
    useEffect(() => {
        (async () => {
            props.setBackDrop(true);

            await fetch(
                `/getCompaniesFilter?franchise_id=${valuesAddNew.franq.trim()}&practice_id=${valuesAddNew.group.trim()}`,
                {
                    method: "GET",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                    },
                }
            )
                .then((res) => res.json())
                .then((data) => {
                    setClientsData(data);
                });

            props.setBackDrop(false);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [valuesAddNew.franq, valuesAddNew.group]);

    useEffect(() => {
        setFranqData(props.franqData);
        setGroupData(props.groupData);
    }, [props.franqData, props.groupData]);

    return (
        <CustomDialog
            open={props.open}
            onClose={handleCloseAddDialog}
            aria-labelledby="add-invoice-dialog-title"
            aria-describedby="add-invoice-dialog-description"
            fullWidth={true}
            maxWidth="md"
        >
            <DialogTitle id="add-invoice-dialog-title">{t("invoicing.addNewInvoicingRe")}</DialogTitle>
            <DialogContent dividers>
                <Card elevation={0}>
                    <CardHeader title={t("invoicing.invoicingData")} sx={{ pt: 0, color: "#ff5968" }} />
                    <Divider />
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6} xl={4}>
                                <FormControl fullWidth disabled={valuesAddNew.disabledFranq}>
                                    <InputLabel id="franq-simple-select-label">
                                        {t("miscellaneous.franchise")}
                                    </InputLabel>
                                    <Select
                                        name="franq"
                                        labelId="franq-simple-select-label"
                                        label={t("miscellaneous.franchise")}
                                        IconComponent={KeyboardArrowDownIcon}
                                        value={valuesAddNew.franq}
                                        onChange={handleChangeFranq}
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    mt: 0.7,
                                                    borderRadius: 2,
                                                    boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                },
                                            },
                                        }}
                                    >
                                        <MenuItem value=" ">
                                            <Typography
                                                variant="body1"
                                                component="span"
                                                align="center"
                                                color="textPrimary"
                                            >
                                                N/A
                                            </Typography>
                                        </MenuItem>
                                        {franqData.map((value) => (
                                            <MenuItem value={value.id}>
                                                <Typography
                                                    variant="body1"
                                                    component="span"
                                                    align="center"
                                                    color="textPrimary"
                                                >
                                                    {value.franchise_name}
                                                </Typography>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6} xl={4}>
                                <FormControl fullWidth>
                                    <InputLabel id="group-simple-select-label">{t("miscellaneous.group")}</InputLabel>
                                    <Select
                                        name="group"
                                        labelId="group-simple-select-label"
                                        IconComponent={KeyboardArrowDownIcon}
                                        value={valuesAddNew.group}
                                        label={t("miscellaneous.group")}
                                        onChange={handleChangeGroup}
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    mt: 0.7,
                                                    borderRadius: 2,
                                                    boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                },
                                            },
                                        }}
                                    >
                                        <MenuItem value=" ">
                                            <Typography
                                                variant="body1"
                                                component="span"
                                                align="center"
                                                color="textPrimary"
                                            >
                                                N/A
                                            </Typography>
                                        </MenuItem>
                                        {groupData.map((value) => (
                                            <MenuItem value={value.id}>
                                                <Typography
                                                    variant="body1"
                                                    component="span"
                                                    align="center"
                                                    color="textPrimary"
                                                >
                                                    {value.group_name}
                                                </Typography>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6} xl={4}>
                                <FormControl fullWidth>
                                    <InputLabel id="client-simple-select-label">{t("miscellaneous.client")}</InputLabel>
                                    <Select
                                        name="client"
                                        labelId="client-simple-select-label"
                                        IconComponent={KeyboardArrowDownIcon}
                                        value={valuesAddNew.client}
                                        label={t("miscellaneous.client")}
                                        onChange={handleChangeClient}
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    mt: 0.7,
                                                    borderRadius: 2,
                                                    boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                },
                                            },
                                        }}
                                    >
                                        <MenuItem value=" ">
                                            <Typography
                                                variant="body1"
                                                component="span"
                                                align="center"
                                                color="textPrimary"
                                            >
                                                N/A
                                            </Typography>
                                        </MenuItem>
                                        {clientsData.map((value) => (
                                            <MenuItem value={value._id}>
                                                <Typography
                                                    variant="body1"
                                                    component="span"
                                                    align="center"
                                                    color="textPrimary"
                                                >
                                                    {value.company_legal_name}
                                                </Typography>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6} xl={4}>
                                <FormControl
                                    fullWidth
                                    required
                                    error={isError && !valuesAddNew.subsInv}
                                    disabled={valuesAddNew.client.trim()}
                                >
                                    <InputLabel id="subsInv-simple-select-label">{t("invoicing.subsInv")}</InputLabel>
                                    <Select
                                        name="subsInv"
                                        labelId="subsInv-simple-select-label"
                                        label={t("invoicing.subsInv")}
                                        IconComponent={KeyboardArrowDownIcon}
                                        value={valuesAddNew.subsInv}
                                        onChange={handleChangeFact}
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    mt: 0.7,
                                                    borderRadius: 2,
                                                    boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                },
                                            },
                                        }}
                                    >
                                        <MenuItem value={1}>GCA SC (Venezuela)</MenuItem>
                                        <MenuItem value={2}>GCA INC (Estados Unidos)</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                <LocalizationProvider
                    dateAdapter={AdapterMoment}
                    adapterLocale={t("language.locale")}
                    localeText={{
                        clearButtonLabel: t("miscellaneous.clear"),
                    }}
                >
                    <Card elevation={0}>
                        <CardHeader title={t("miscellaneous.service")} sx={{ color: "#ff5968" }} />
                        <Divider />
                        <CardContent sx={{ pb: "0px !important" }}>
                            <Grid container alignItems="center" spacing={2}>
                                <Grid item xs={12} md={6} xl={4}>
                                    <FormControl
                                        fullWidth
                                        required
                                        error={isError && valuesAddNew.documentType === "" ? true : false}
                                    >
                                        <InputLabel id="document-type-simple-select-label">
                                            {t("invoicing.documentType")}
                                        </InputLabel>
                                        <Select
                                            name="documentType"
                                            label={t("invoicing.documentType")}
                                            IconComponent={KeyboardArrowDownIcon}
                                            value={valuesAddNew.documentType}
                                            onChange={handleChangeDocumentType}
                                            MenuProps={{
                                                PaperProps: {
                                                    sx: {
                                                        mt: 0.7,
                                                        borderRadius: 2,
                                                        boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                    },
                                                },
                                            }}
                                        >
                                            <MenuItem value={1}>
                                                <Typography
                                                    variant="body1"
                                                    component="span"
                                                    align="center"
                                                    color="textPrimary"
                                                >
                                                    {t("miscellaneous.invoice")}
                                                </Typography>
                                            </MenuItem>
                                            <MenuItem value={2}>
                                                <Typography
                                                    variant="body1"
                                                    component="span"
                                                    align="center"
                                                    color="textPrimary"
                                                >
                                                    {t("invoicing.creditNote")}
                                                </Typography>
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6} xl={4}>
                                    <DatePicker
                                        componentsProps={{
                                            actionBar: { actions: ["clear"] },
                                        }}
                                        value={valuesAddNew.scheduledShipDate}
                                        onChange={(newValue) => handleChangeScheduledShipDate(newValue)}
                                        inputFormat={todos.dateFormat}
                                        renderInput={(params) => <TextField fullWidth {...params} />}
                                        label={t("invoicing.scheduledShipDate")}
                                    />
                                </Grid>
                                <Grid item xs={12} xl={4}></Grid>
                                <Grid item xs={12} md={6} xl={4}>
                                    <FormControl
                                        fullWidth
                                        required
                                        disabled={
                                            valuesAddNew.franq.trim() === "" &&
                                            valuesAddNew.group.trim() === "" &&
                                            valuesAddNew.client.trim() === ""
                                                ? true
                                                : false
                                        }
                                        error={isError && valuesAddNew.service === "" ? true : false}
                                    >
                                        <InputLabel id="service-simple-select-label">
                                            {t("miscellaneous.service")}
                                        </InputLabel>
                                        <Select
                                            name="service"
                                            labelId="service-simple-select-label"
                                            IconComponent={KeyboardArrowDownIcon}
                                            value={valuesAddNew.service}
                                            label={t("miscellaneous.service")}
                                            onChange={handleChangeService}
                                            MenuProps={{
                                                PaperProps: {
                                                    sx: {
                                                        mt: 0.7,
                                                        borderRadius: 2,
                                                        boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                    },
                                                },
                                            }}
                                        >
                                            {valuesAddNew.franq.trim() &&
                                            !valuesAddNew.group.trim() &&
                                            !valuesAddNew.client.trim()
                                                ? filterFranqServices.map((value) => (
                                                      <MenuItem value={value.code}>
                                                          <Typography
                                                              variant="body1"
                                                              component="span"
                                                              align="center"
                                                              color="textPrimary"
                                                          >
                                                              {t(`invoicing.${value.name}`)}
                                                          </Typography>
                                                      </MenuItem>
                                                  ))
                                                : filterServices.map((value) => (
                                                      <MenuItem value={value.code}>
                                                          <Typography
                                                              variant="body1"
                                                              component="span"
                                                              align="center"
                                                              color="textPrimary"
                                                          >
                                                              {t(`invoicing.${value.name}`)}
                                                          </Typography>
                                                      </MenuItem>
                                                  ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6} xl={4}>
                                    <FormControl
                                        fullWidth
                                        required
                                        disabled={valuesAddNew.service === "" ? true : false}
                                        error={isError && valuesAddNew.project === "" ? true : false}
                                    >
                                        <InputLabel id="project-simple-select-label">
                                            {t("invoicing.project")}
                                        </InputLabel>
                                        <Select
                                            name="project"
                                            labelId="project-simple-select-label"
                                            IconComponent={KeyboardArrowDownIcon}
                                            value={valuesAddNew.project}
                                            label={t("invoicing.project")}
                                            onChange={handleChangeType}
                                            MenuProps={{
                                                PaperProps: {
                                                    sx: {
                                                        mt: 0.7,
                                                        borderRadius: 2,
                                                        boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                    },
                                                },
                                            }}
                                        >
                                            {projects.map((value) => (
                                                <MenuItem value={value.code}>
                                                    <Typography
                                                        variant="body1"
                                                        component="span"
                                                        align="center"
                                                        color="textPrimary"
                                                    >
                                                        {t(`invoicing.${value.name}`)}
                                                    </Typography>
                                                </MenuItem>
                                            ))}
                                            {projects.length === 0 && (
                                                <MenuItem disabled>
                                                    <Typography
                                                        variant="body1"
                                                        component="span"
                                                        align="center"
                                                        color="disable"
                                                    >
                                                        {t("invoicing.noProjectsAvailable")}
                                                    </Typography>
                                                </MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                {valuesAddNew.project === 36 && (
                                    <Grid item xs={12} md={6} xl={4}>
                                        <FormControl fullWidth required error={isError && valuesAddNew.package === ""}>
                                            <InputLabel id="ver-simple-select-label">
                                                {t("services.package")}
                                            </InputLabel>
                                            <Select
                                                name="package"
                                                labelId="ver-simple-select-label"
                                                IconComponent={KeyboardArrowDownIcon}
                                                value={valuesAddNew.package}
                                                label={t("services.package")}
                                                onChange={handleChangePackage}
                                                disabled={!valuesAddNew.project}
                                                MenuProps={{
                                                    PaperProps: {
                                                        sx: {
                                                            mt: 0.7,
                                                            borderRadius: 2,
                                                            boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                        },
                                                    },
                                                }}
                                            >
                                                <MenuItem value={1}>Basic (4 hrs)</MenuItem>
                                                <MenuItem value={2}>Professional (8 hrs)</MenuItem>
                                                <MenuItem value={3}>Advanced (16 hrs)</MenuItem>
                                                <MenuItem value={4}>{t("currentView.personal")}</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                )}
                                {isRequiredConsulting && (
                                    <Grid item xs={12} md={6} xl={4}>
                                        <TextField
                                            fullWidth
                                            label={t("miscellaneous.hours")}
                                            value={valuesAddNew.hours}
                                            name="hours"
                                            onChange={handleChangeH}
                                            required
                                            variant="outlined"
                                            type="number"
                                            error={isError && (!valuesAddNew.hours || valuesAddNew.hours < 0)}
                                            inputProps={{
                                                min: 1,
                                                step: 0.5,
                                            }}
                                        />
                                    </Grid>
                                )}
                                {valuesAddNew.project !== 36 && !isRequiredFee && (
                                    <>
                                        <Grid item xs={12} md={6} xl={4}>
                                            <DatePicker
                                                componentsProps={{
                                                    actionBar: { actions: ["clear"] },
                                                }}
                                                value={valuesAddNew.sinceDate}
                                                onChange={(newValue) => handleChangeSinceDate(newValue)}
                                                inputFormat={todos.dateFormat}
                                                renderInput={(params) => (
                                                    <TextField
                                                        fullWidth
                                                        required
                                                        {...params}
                                                        error={isError && valuesAddNew.sinceDate === null}
                                                    />
                                                )}
                                                label={t("invoicing.initDate")}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6} xl={4}>
                                            <DatePicker
                                                componentsProps={{
                                                    actionBar: { actions: ["clear"] },
                                                }}
                                                value={valuesAddNew.untilDate}
                                                onChange={(newValue) => handleChangeUntilDate(newValue)}
                                                inputFormat={todos.dateFormat}
                                                renderInput={(params) => (
                                                    <TextField
                                                        fullWidth
                                                        required
                                                        {...params}
                                                        error={isError && valuesAddNew.untilDate === null}
                                                    />
                                                )}
                                                label={t("invoicing.endDate")}
                                            />
                                        </Grid>
                                    </>
                                )}
                                {valuesAddNew.project === 36 && !isRequiredFee && (
                                    <Grid item xs={12} md={6} xl={4}>
                                        <DatePicker
                                            componentsProps={{
                                                actionBar: { actions: ["clear"] },
                                            }}
                                            value={valuesAddNew.estimatedDeliveryDate}
                                            onChange={(newValue) => handleChangeEstimatedDate(newValue)}
                                            inputFormat={todos.dateFormat}
                                            renderInput={(params) => (
                                                <TextField
                                                    fullWidth
                                                    required
                                                    {...params}
                                                    error={isError && valuesAddNew.estimatedDeliveryDate === ""}
                                                />
                                            )}
                                            label={t("services.estimDeliveryDate")}
                                        />
                                    </Grid>
                                )}
                                {isRequiredFee && (
                                    <>
                                        <Grid item xs={12} md={6} xl={4}>
                                            <DatePicker
                                                componentsProps={{
                                                    actionBar: { actions: ["clear"] },
                                                }}
                                                value={valuesAddNew.deliveryDate}
                                                onChange={handleChangeDeliveryDate}
                                                inputFormat={todos.dateFormat}
                                                renderInput={(params) => (
                                                    <TextField
                                                        fullWidth
                                                        required
                                                        {...params}
                                                        error={isError && !valuesAddNew.deliveryDate}
                                                    />
                                                )}
                                                label={t("services.deliveryDate")}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6} xl={4}>
                                            <FormControl
                                                fullWidth
                                                required
                                                variant="outlined"
                                                error={isRequiredFee && isError && !valuesAddNew.baseFee}
                                            >
                                                <InputLabel htmlFor="outlined-adornment-fee">
                                                    {t("invoicing.baseFee")}
                                                </InputLabel>
                                                <OutlinedInput
                                                    id="outlined-adornment-fee"
                                                    value={valuesAddNew.baseFee}
                                                    onChange={handleChangeBaseFee}
                                                    inputComponent={NumberFormatCustom}
                                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                    label={t("invoicing.baseFee")}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </>
                                )}
                                <Grid item xs={12} md={6} xl={4}>
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel htmlFor="outlined-adornment-discount">
                                            {t("proposals.discount")}
                                        </InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-discount"
                                            type="number"
                                            value={valuesAddNew.discount}
                                            onChange={handleChangeDiscount}
                                            onBlur={() =>
                                                setValuesAddNew({
                                                    ...valuesAddNew,
                                                    discount: valuesAddNew.discount
                                                        ? parseFloat(valuesAddNew.discount).toFixed(2)
                                                        : parseFloat(0).toFixed(2),
                                                })
                                            }
                                            inputProps={{
                                                min: 0,
                                                max: 100,
                                            }}
                                            endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                            label={t("proposals.discount")}
                                            sx={{
                                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                                    {
                                                        display: "none",
                                                    },
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                {valuesAddNew.project !== 36 && (
                                    <Grid item xs={12} md={6} xl={4}>
                                        <FormControl fullWidth disabled variant="outlined">
                                            <InputLabel htmlFor="outlined-adornment-fee">
                                                {t("proposals.feeTotal")}
                                            </InputLabel>
                                            <OutlinedInput
                                                id="outlined-adornment-fee"
                                                value={valuesAddNew.fee}
                                                onChange={handleChangeFee}
                                                inputComponent={NumberFormatCustom}
                                                startAdornment={
                                                    <InputAdornment disableTypography position="start">
                                                        $
                                                    </InputAdornment>
                                                }
                                                label={t("proposals.feeTotal")}
                                            />
                                        </FormControl>
                                    </Grid>
                                )}
                                {valuesAddNew.project === 36 && (
                                    <Grid item xs={12} md={6} xl={4}>
                                        <FormControl
                                            fullWidth
                                            required={isRequiredFee}
                                            disabled={!isRequiredFee}
                                            variant="outlined"
                                            error={
                                                isRequiredFee &&
                                                isError &&
                                                (valuesAddNew.fee === "" || parseInt(valuesAddNew.fee) === 0)
                                            }
                                        >
                                            <InputLabel htmlFor="outlined-adornment-fee">
                                                {t("invoicing.fee")}
                                            </InputLabel>
                                            <OutlinedInput
                                                id="outlined-adornment-fee"
                                                value={valuesAddNew.fee}
                                                inputComponent={NumberFormatCustom}
                                                startAdornment={
                                                    <InputAdornment disableTypography={!isRequiredFee} position="start">
                                                        $
                                                    </InputAdornment>
                                                }
                                                label={t("invoicing.fee")}
                                            />
                                        </FormControl>
                                    </Grid>
                                )}
                                {isRequired ? (
                                    <Grid item xs={12} md={6} xl={8}>
                                        <TextField
                                            fullWidth
                                            required={isRequired}
                                            label={t("invoicing.description")}
                                            name="description"
                                            onChange={handleChangeValuesAddNew}
                                            value={valuesAddNew.description}
                                            variant="outlined"
                                            inputProps={{ maxLength: 70 }}
                                            error={
                                                isRequired && isError && valuesAddNew.description === "" ? true : false
                                            }
                                        />
                                    </Grid>
                                ) : null}
                                {isRequiredCheck && (
                                    <Grid item xs={12} md={6} xl={4}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={valuesAddNew.invoiceOverload}
                                                    onChange={handleChangeInvCheck}
                                                />
                                            }
                                            label={t("invoicing.invoiceLoad")}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </CardContent>
                    </Card>
                </LocalizationProvider>
            </DialogContent>
            <DialogActions>
                <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                    <Grid item>
                        <ColorButton
                            sx={{
                                mr: 1,
                                "&:hover": {
                                    backgroundColor: "white",
                                    color: "#2f3190",
                                    border: "1px solid #2f3190",
                                },
                            }}
                            color="secondary"
                            disableElevation
                            variant="outlined"
                            onClick={handleCloseAddDialog}
                        >
                            {t("miscellaneous.cancel")}
                        </ColorButton>
                    </Grid>
                    <Grid item>
                        <ColorButton
                            sx={{
                                "&:hover": {
                                    backgroundColor: "#2f3190",
                                    border: "1px solid #2f3190",
                                },
                            }}
                            color="secondary"
                            disableElevation
                            onClick={addNewInvoicingRecord}
                            variant="contained"
                        >
                            {t("miscellaneous.save")}
                        </ColorButton>
                    </Grid>
                </Grid>
            </DialogActions>
        </CustomDialog>
    );
};

export default ModAddInvoice;
