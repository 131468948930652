/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import moment from "moment";

/** MUI icons */
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CommentIcon from "@mui/icons-material/Comment";
import CircleIcon from "@mui/icons-material/Circle";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

/** MUI imports */
import { Grid, Table, TableHeaderRow, PagingPanel, Toolbar, SearchPanel, TableColumnVisibility } from "@devexpress/dx-react-grid-material-ui";
import { SearchState, IntegratedFiltering, PagingState, IntegratedPaging } from "@devexpress/dx-react-grid";
import { styled } from "@mui/material/styles";
import GridUI from "@mui/material/Grid";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import {
    Box,
    FormControl,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Typography,
    Skeleton,
    Checkbox,
    ListItemText,
    Button,
} from "@mui/material";

/** Components imports */
import Backdrop from "../../Backdrop";
import SnackBar from "../../SnackBar";
import CommentDialog from "../../CommentDialog";

const PREFIX = "Validations";

const classes = {
    paper: `${PREFIX}-paper`,
    tableStriped: `${PREFIX}-tableStriped`,
    tableHeader: `${PREFIX}-tableHeader`,
    tableBody: `${PREFIX}-tableBody`,
    pager: `${PREFIX}-pager`,
    cardContentRoot: `${PREFIX}-cardContentRoot`,
    cardContentRoot2: `${PREFIX}-cardContentRoot2`,
    cardHeaderRoot: `${PREFIX}-cardHeaderRoot`,
    formRounded: `${PREFIX}-formRounded`,
    button: `${PREFIX}-button`,
    spacing: `${PREFIX}-spacing`,
    radio: `${PREFIX}-radio`,
    toolbarSearch: `${PREFIX}-toolbarSearch`,
    searchInput: `${PREFIX}-searchInput`,
};

const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
        backgroundColor: "#2f3190",
        color: "white",
    },
}));

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "rgba(19, 31, 62, 0.80)",
        fontSize: 12,
        maxWidth: 320,
        borderRadius: 6,
        fontWeight: "normal",
    },
});

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.paper}`]: {
        border: "2px solid #ff5968",
        borderRadius: 40,
        boxShadow: "none",
    },

    [`& .${classes.radio}`]: {
        [theme.breakpoints.down("sm")]: {
            padding: 9,
        },
        [theme.breakpoints.between("md", "lg")]: {
            padding: 0,
        },
        [theme.breakpoints.between("lg", "xl")]: {
            padding: 6,
        },
    },

    [`& .${classes.cardContentRoot}`]: {
        "&:last-child": {
            paddingBottom: "5px",
        },
        padding: "10px",
    },

    [`& .${classes.cardContentRoot2}`]: {
        "&:last-child": {
            paddingBottom: "18px",
        },
        padding: 30,
    },

    [`& .${classes.cardHeaderRoot}`]: {
        padding: 30,
    },

    [`& .${classes.formRounded}`]: {
        backgroundColor: "#FFFFFF !important",
        borderRadius: 40,
        verticalAlign: "middle !important",
        "&:focus-within": {
            backgroundColor: "#FFFFFF",
            borderRadius: 40,
        },
        "& .MuiOutlinedInput-notchedOutline": {
            border: "2px solid #031851",
            borderRadius: 40,
        },
    },

    [`& .${classes.button}`]: {
        borderRadius: 40,
        width: "100%",
        "&:hover": {
            backgroundColor: theme.palette.text.secondary,
            color: "#fff",
        },
    },

    [`& .${classes.spacing}`]: {
        padding: "30px 0 20px 0",
    },

    [`& .${classes.tableStriped}`]: {
        "& tbody tr:nth-of-type(odd)": {
            backgroundColor: "rgba(47,49,144,0.1)",
        },
    },
    [`& .${classes.tableHeader}`]: {
        border: "none",
        backgroundColor: "rgba(47,49,144,0.3)",
        fontSize: "15px",
        fontWeight: 600,
        color: "#031851",
        "&:first-of-type": {
            borderRadius: "20px 0 0 0",
        },
        "&:last-child": {
            borderRadius: "0 20px 0 0",
        },
    },

    [`& .${classes.tableBody}`]: {
        border: "none",
        fontSize: "14px",
        fontWeight: 400,
        color: "#031851",
    },

    [`& .${classes.pager}`]: {
        "& .Pagination-button": {
            paddingTop: 0,
            paddingBottom: 0,
            color: "#031851",
            fontWeight: 600,
        },
        "& .Pagination-activeButton": {
            backgroundColor: "rgba(47,49,144,0.3)",
        },
    },

    [`& .${classes.toolbarSearch}`]: {
        border: "none !important",
        padding: "0 0 5px !important",
    },

    [`& .${classes.searchInput}`]: {
        color: "#031851",
        "&:before": {
            borderBottomColor: "#031851",
        },
        "&:hover:before": {
            borderBottomColor: "#031851 !important",
        },
    },
}));

const tableHeaderCell = ({ ...props }) => <TableHeaderRow.Cell {...props} className={classes.tableHeader} />;

const TableComponent = ({ ...props }) => <Table.Table {...props} className={classes.tableStriped} />;

const TableCellComponent = ({ ...props }) => <Table.Cell {...props} className={classes.tableBody} />;

const tableContentComponent = (props) => <TableHeaderRow.Content {...props} />;

const PagingPanelContainer = ({ ...props }) => <PagingPanel.Container {...props} className={classes.pager} />;

const inputComponent = ({ ...props }) => <SearchPanel.Input {...props} className={classes.searchInput} />;

const toolRootComponent = ({ ...props }) => <Toolbar.Root {...props} className={classes.toolbarSearch} />;

const tableColumnExtensions = [
    { columnName: "client", align: "left" },
    { columnName: "reported", align: "center" },
    { columnName: "comments", align: "center", width: "5%" },
    { columnName: "jan", align: "center", width: "5%" },
    { columnName: "feb", align: "center", width: "5%" },
    { columnName: "mar", align: "center", width: "5%" },
    { columnName: "apr", align: "center", width: "5%" },
    { columnName: "may", align: "center", width: "5%" },
    { columnName: "jun", align: "center", width: "5%" },
    { columnName: "jul", align: "center", width: "5%" },
    { columnName: "aug", align: "center", width: "5%" },
    { columnName: "sep", align: "center", width: "5%" },
    { columnName: "oct", align: "center", width: "5%" },
    { columnName: "nov", align: "center", width: "5%" },
    { columnName: "dec", align: "center", width: "5%" },
];

const rowsSkeleton = [];

for (let i = 0; i < 10; i++) {
    rowsSkeleton.push({
        id: i,
        client: <Skeleton variant="text" animation="wave" />,
        comments: (
            <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />
        ),
        reported: <Skeleton variant="text" animation="wave" />,
        jan: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        feb: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        mar: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        apr: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        may: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        jun: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        jul: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        aug: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        sep: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        oct: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        nov: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        dec: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
    });
}

const GlobalReport = (props) => {
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    const tableMessages = {
        noData: t("miscellaneous.noData"),
    };
    const pagingMessages = {
        info: ({ from, to, count }) => `${from}${from < to ? `-${to}` : ""} ${t("miscellaneous.pagingOf")} ${count}`,
    };

    const searchMessages = {
        searchPlaceholder: t("miscellaneous.search"),
    };

    /** React redux */
    const todos = useSelector((state) => state.todos);

    const franqDataProps = props.franqData;
    const groupDataProps = props.groupData;

    const statusArray = [
        {
            id: "all",
            name: t("miscellaneous.all"),
        },
        {
            id: "7",
            name: t("recurrent.toClose"),
        },
        {
            id: "1",
            name: t("clientsTable.awaitingRequirements"),
        },
        {
            id: "2",
            name: t("recurrent.toValid"),
        },
        {
            id: "3",
            name: t("recurrent.validated"),
        },
        {
            id: "5",
            name: t("recurrent.partReport"),
        },
        {
            id: "6",
            name: t("miscellaneous.closed"),
        },
    ];

    /** Component states */
    const [clientsData, setClients] = useState([]);
    const [columnDefs, setColumnsDefs] = useState([]);
    const [showBackdrop, setBackDrop] = useState(false);
    const [yearsToNoti, setyearsToNoti] = useState([]);
    const [selectedYear, setselectedYear] = useState(new Date().getFullYear());
    const [clientT, setTClient] = useState("");
    const [franqF, setfranqF] = useState(["all"].concat(franqDataProps.map((item) => item.id)));
    const [groupF, setgroupF] = useState(["all"].concat(groupDataProps.map((item) => item.id)));
    const [clientMarket, setclientMarket] = useState(props.markets);
    const [clientStatus, setClientStatus] = useState("");
    const [idStatus, setIdStatus] = useState(statusArray.map((item) => item.id));
    const [groupData, setGroupData] = useState(groupDataProps);
    const [franqData, setFranqData] = useState(franqDataProps);
    const [show, setShow] = useState(false);
    const [texto, setTexto] = useState("");
    const [alertType, seAutoAlertType] = useState("");
    const [searchValue, setSearchState] = useState("");
    const [dialogComment, setDialogComment] = useState(false);
    const [clientID, setclientID] = useState("");
    const [comments, setComments] = useState([]);
    const [commentsP, setCommentsPend] = useState([]);
    const [anyFilter, setAnyFilter] = useState(true);
    const [defaultHiddenColumnNames] = useState(["hiddenCompany"]);
    const controllerRef = useRef(null);

    /** Component functions */
    const cleanFilters = () => {
        setAnyFilter(true);
        setTClient("");
        setfranqF(["all"].concat(franqDataProps.map((item) => item.id)));
        setgroupF(["all"].concat(groupDataProps.map((item) => item.id)));
        setGroupData(groupDataProps);
        setIdStatus(statusArray.map((item) => item.id));
        setClientStatus("");
        setclientMarket(props.markets);
        setselectedYear(new Date().getFullYear());
    };

    const closeDialog = () => {
        setDialogComment(false);
    };

    const handleSearch = (value) => {
        let itemS = value.replace(/ +(?= )/g, "");
        setSearchState(itemS);
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setShow(false);
    };

    const handleChangeTClient = async (event) => {
        setBackDrop(true);
        setTClient(event.target.value);
        setAnyFilter(false);

        let finalFranq = [];

        await fetch(`/getClientFranchiseFilter?client_type=${event.target.value}`, {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then((res) => res.json())
            .then((data) => {
                setFranqData(data);
                if (event.target.value === "") {
                    finalFranq = [];
                } else {
                    finalFranq = data.map((item) => item.id);
                }
                setfranqF(["all"].concat(data.map((item) => item.id)));
            });

        await fetch(`/getFranchiseGroups?id_franchise=${finalFranq}`, {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then((res) => res.json())
            .then((data) => {
                setGroupData(data);
                setgroupF(["all"].concat(data.map((item) => item.id)));
            });
        setBackDrop(false);
    };

    const handleChangeFranqF = async (event) => {
        setBackDrop(true);
        const {
            target: { value },
        } = event;

        let final = [];

        if (value.includes("all") && !franqF.includes("all")) {
            final = ["all"].concat(franqData.map((item) => item.id));
        } else if (!value.includes("all") && franqF.includes("all")) {
            final = [];
        } else {
            if (!value.includes("all") && value.length === franqData.length) {
                final = ["all"].concat(franqData.map((item) => item.id));
            } else {
                final =
                    typeof value === "string"
                        ? value.split(",").filter((item) => item !== "all")
                        : value.filter((item) => item !== "all");
            }
        }
        setfranqF(final);
        setAnyFilter(false);

        let finalFranq = [];
        if (final.includes("all")) {
            finalFranq = [];
        } else if (final.length === 0) {
            finalFranq = ["none"];
        } else {
            finalFranq = final.filter((item) => item !== "all");
        }

        await fetch(`/getFranchiseGroups?id_franchise=${finalFranq}`, {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then((res) => res.json())
            .then((data) => {
                setgroupF(["all"].concat(data.map((item) => item.id)));
                setGroupData(data);
            });

        setBackDrop(false);
    };

    const handleChangeGroupF = (event) => {
        const {
            target: { value },
        } = event;

        let final = [];

        if (value.includes("all") && !groupF.includes("all")) {
            final = ["all"].concat(groupDataProps.map((item) => item.id));
        } else if (!value.includes("all") && groupF.includes("all")) {
            final = [];
        } else {
            if (!value.includes("all") && value.length === groupData.length) {
                final = ["all"].concat(groupData.map((item) => item.id));
            } else {
                final = event.target.value.filter((item) => item !== "all");
            }
        }
        setgroupF(final);
        setAnyFilter(false);
    };

    const handleChangeClasif = (event) => {
        setClientStatus(event.target.value);
        setAnyFilter(false);
    };

    const handleChangeIdStatus = (event) => {
        const {
            target: { value },
        } = event;

        let final = [];

        if (value.includes("all") && !idStatus.includes("all")) {
            final = statusArray.map((item) => item.id);
        } else if (!value.includes("all") && idStatus.includes("all")) {
            final = [];
        } else {
            if (!value.includes("all") && value.length === statusArray.filter((item) => item.id !== "all").length) {
                final = statusArray.map((item) => item.id);
            } else {
                final =
                    typeof value === "string"
                        ? value.split(",").filter((item) => item !== "all")
                        : value.filter((item) => item !== "all");
            }
        }

        setIdStatus(final);
        setAnyFilter(false);
    };

    const handleChangeclientMarket = (event) => {
        const {
            target: { value },
        } = event;

        let final = [];

        if (value.includes(t("miscellaneous.all")) && !clientMarket.includes(t("miscellaneous.all"))) {
            final = props.markets;
        } else if (!value.includes(t("miscellaneous.all")) && clientMarket.includes(t("miscellaneous.all"))) {
            final = [];
        } else {
            if (
                !value.includes(t("miscellaneous.all")) &&
                value.length === props.markets.filter((item) => item !== t("miscellaneous.all")).length
            ) {
                final = props.markets;
            } else {
                final =
                    typeof value === "string"
                        ? value.split(",").filter((item) => item !== t("miscellaneous.all"))
                        : value.filter((item) => item !== t("miscellaneous.all"));
            }
        }
        setclientMarket(final);
        setAnyFilter(false);
    };

    const handleClick = (event) => {
        setselectedYear(event.target.value);
        setAnyFilter(false);
    };

    const openComments = async (clientId) => {
        setBackDrop(true);

        setclientID(clientId);

        await getComments(clientId);

        setDialogComment(true);

        setBackDrop(false);
    };

    const getComments = async (clientId) => {
        await fetch(`/getComments?company_id=${clientId}&period=${selectedYear}&comment_type=1`, {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then((res) => res.json())
            .then((data) => {
                setComments(data.history);
                setCommentsPend(data.pendings);
            });
    };

    const getColorStatus = (id_status) => {
        const colors = ["#F8F9F8", "#f1919e", "#ffb23e", "#9ad2dd", "#FFB23E", "#8bc985", "#318a16"];

        return colors[id_status];
    };

    const loadInitData = async (year) => {
        if (controllerRef.current) {
            controllerRef.current.abort();
        }
        const controller = new AbortController();
        controllerRef.current = controller;

        let finalClientMarket = [];
        if (clientMarket.includes(t("miscellaneous.all"))) {
            finalClientMarket = [];
        } else if (clientMarket.length === 0) {
            finalClientMarket = ["none"];
        } else {
            finalClientMarket = clientMarket.filter((item) => item !== t("miscellaneous.all"));
        }

        let finalFranq = [];
        if (franqF.includes("all")) {
            finalFranq = [];
        } else if (franqF.length === 0) {
            finalFranq = ["none"];
        } else {
            finalFranq = franqF.filter((item) => item !== "all");
        }

        let finalGroup = [];
        if (groupF.includes("all")) {
            finalGroup = [];
        } else if (groupF.length === 0) {
            finalGroup = ["none"];
        } else {
            finalGroup = groupF.filter((item) => item !== "all");
        }

        let finaStatus = [];
        if (idStatus.includes("all")) {
            finaStatus = [];
        } else {
            finaStatus = idStatus.filter((item) => item !== "all");
        }

        let resultTable = await fetch(
            `/getMonitoring?client_type=${clientT}&practice_id=${finalGroup}&franchise_id=${finalFranq}&client_status=${clientStatus}&id_status=${finaStatus}&year=${year}&client_market=${finalClientMarket}&service_type=${props.serviceType}`,
            {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
                signal: controllerRef.current.signal,
            }
        )
            .then((res) => res.json())
            .then((data) => {
                controllerRef.current = null;
                return data;
            });

        let clientsdata = resultTable.map((valItem, index) => {
            let cont = 0;
            valItem.comments.forEach((data) => {
                if (data.status === 0) {
                    cont++;
                }
            });
            return {
                ID: valItem.id_organisation,
                hiddenCompany: valItem.company_name,
                client: valItem.invalid ? (
                    <Typography variant="h4" color="secondary">
                        {valItem.company_name}
                    </Typography>
                ) : (
                    valItem.company_name
                ),
                comments: (
                    <LightTooltip title={t("miscellaneous.comments")}>
                        <IconButton
                            aria-label="clear"
                            sx={valItem.comments.length === 0 ? { color: "rgba(0, 0, 0, 0.54)" } : { color: "#2f3190" }}
                            onClick={() => openComments(valItem.id_organisation)}
                        >
                            <StyledBadge badgeContent={cont}>
                                <CommentIcon />
                            </StyledBadge>
                        </IconButton>
                    </LightTooltip>
                ),
                reported:
                    valItem.report_date !== ""
                        ? moment(valItem.report_date, "DD/MM/YYYY").format(todos.dateFormat)
                        : "",
                jan: (
                    <CircleIcon
                        fontSize="large"
                        sx={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            color: getColorStatus(valItem.notify_jan),
                        }}
                    />
                ),
                feb: (
                    <CircleIcon
                        fontSize="large"
                        sx={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            color: getColorStatus(valItem.notify_feb),
                        }}
                    />
                ),
                mar: (
                    <CircleIcon
                        fontSize="large"
                        sx={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            color: getColorStatus(valItem.notify_mar),
                        }}
                    />
                ),
                apr: (
                    <CircleIcon
                        fontSize="large"
                        sx={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            color: getColorStatus(valItem.notify_apr),
                        }}
                    />
                ),
                may: (
                    <CircleIcon
                        fontSize="large"
                        sx={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            color: getColorStatus(valItem.notify_may),
                        }}
                    />
                ),
                jun: (
                    <CircleIcon
                        fontSize="large"
                        sx={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            color: getColorStatus(valItem.notify_jun),
                        }}
                    />
                ),
                jul: (
                    <CircleIcon
                        fontSize="large"
                        sx={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            color: getColorStatus(valItem.notify_jul),
                        }}
                    />
                ),
                aug: (
                    <CircleIcon
                        fontSize="large"
                        sx={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            color: getColorStatus(valItem.notify_aug),
                        }}
                    />
                ),
                sep: (
                    <CircleIcon
                        fontSize="large"
                        sx={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            color: getColorStatus(valItem.notify_sep),
                        }}
                    />
                ),
                oct: (
                    <CircleIcon
                        fontSize="large"
                        sx={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            color: getColorStatus(valItem.notify_oct),
                        }}
                    />
                ),
                nov: (
                    <CircleIcon
                        fontSize="large"
                        sx={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            color: getColorStatus(valItem.notify_nov),
                        }}
                    />
                ),
                dec: (
                    <CircleIcon
                        fontSize="large"
                        sx={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            color: getColorStatus(valItem.notify_dec),
                        }}
                    />
                ),
            };
        });

        setClients(clientsdata);
    };

    useEffect(() => {
        setClients(rowsSkeleton);

        loadInitData(selectedYear);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t, todos.dateFormat, clientT, franqF, groupF, clientMarket, clientStatus, idStatus, selectedYear]);

    useEffect(() => {
        (async () => {
            const columnsTemplate = [
                { name: "hiddenCompany", title: "Hidden Companies" },
                { name: "client", title: t("miscellaneous.client") },
                { name: "comments", title: " " },
                { name: "reported", title: t("clientsTable.reported") },
                { name: "jan", title: t("monthNamesShort.jan") },
                { name: "feb", title: t("monthNamesShort.feb") },
                { name: "mar", title: t("monthNamesShort.mar") },
                { name: "apr", title: t("monthNamesShort.apr") },
                { name: "may", title: t("monthNamesShort.may") },
                { name: "jun", title: t("monthNamesShort.jun") },
                { name: "jul", title: t("monthNamesShort.jul") },
                { name: "aug", title: t("monthNamesShort.aug") },
                { name: "sep", title: t("monthNamesShort.sep") },
                { name: "oct", title: t("monthNamesShort.oct") },
                { name: "nov", title: t("monthNamesShort.nov") },
                { name: "dec", title: t("monthNamesShort.dec") },
            ];
            setColumnsDefs(columnsTemplate);

            let finalFranq = [];
            if (franqF.includes("all")) {
                finalFranq = [];
            } else {
                finalFranq = franqF.filter((item) => item !== "all");
            }

            let finalGroup = [];
            if (groupF.includes("all")) {
                finalGroup = [];
            } else {
                finalGroup = groupF.filter((item) => item !== "all");
            }

            await fetch(
                `/getNotificationYears?client_type=${clientT}&practice_id=${finalGroup}&franchise_id=${finalFranq}`,
                {
                    method: "GET",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                    },
                }
            )
                .then((res) => res.json())
                .then((data) => {
                    setyearsToNoti(data);
                });
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t, todos.dateFormat, clientT, franqF, groupF]);

    const downloadReport = async () => {
        setBackDrop(true);

        let finalClientMarket = [];
        if (clientMarket.includes(t("miscellaneous.all"))) {
            finalClientMarket = [];
        } else if (clientMarket.length === 0) {
            finalClientMarket = ["none"];
        } else {
            finalClientMarket = clientMarket.filter((item) => item !== t("miscellaneous.all"));
        }

        let finalFranq = [];
        if (franqF.includes("all")) {
            finalFranq = [];
        } else if (franqF.length === 0) {
            finalFranq = ["none"];
        } else {
            finalFranq = franqF.filter((item) => item !== "all");
        }

        let finalGroup = [];
        if (groupF.includes("all")) {
            finalGroup = [];
        } else if (groupF.length === 0) {
            finalGroup = ["none"];
        } else {
            finalGroup = groupF.filter((item) => item !== "all");
        }

        let finaStatus = [];
        if (idStatus.includes("all")) {
            finaStatus = [];
        } else {
            finaStatus = idStatus.filter((item) => item !== "all");
        }

        let filename = "Status report.pdf";
        await fetch(
            `/generateMonitoringReport?client_type=${clientT}&practice_id=${finalGroup}&franchise_id=${finalFranq}&client_status=${clientStatus}&contact_email=&client_market=${finalClientMarket}&id_status=${finaStatus}&year=${selectedYear}&service_type=${
                props.serviceType
            }&lang=${t("language.locale")}&portal=internal`,
            {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            }
        )
            .then((res) => res.blob())
            .then((blob) => {
                const link = document.createElement("a");
                link.href = URL.createObjectURL(blob);
                link.setAttribute("download", filename);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch((err) => {
                setTexto(t("manageAccount.dnldError"));
                seAutoAlertType("warning");
                setShow(true);
            });

        setBackDrop(false);
    };

    return (
        <StyledContainer component="main" sx={{ flexGrow: 1, width: "100%" }}>
            <GridUI container spacing={2} className={classes.spacing}>
                <GridUI item xs={12} md={4} lg>
                    <FormControl fullWidth classes={{ root: classes.formRounded }}>
                        <InputLabel id="year-simple-select-label">{t("miscellaneous.year")}</InputLabel>
                        <Select
                            value={selectedYear}
                            labelId="year-simple-select-label"
                            onChange={handleClick}
                            IconComponent={KeyboardArrowDownIcon}
                            className="ddlGeneral"
                            size="small"
                            label={t("miscellaneous.year")}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        mt: 0.7,
                                        borderRadius: 2,
                                        boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                    },
                                },
                            }}
                        >
                            {yearsToNoti.map((year, index) => (
                                <MenuItem key={index} value={year}>
                                    <Typography variant="body1" component="span" align="center" color="textPrimary">
                                        {year}
                                    </Typography>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </GridUI>
                <GridUI item xs={12} md={4} lg>
                    <FormControl fullWidth classes={{ root: classes.formRounded }}>
                        <InputLabel shrink id="type-simple-select-label">
                            {t("recurrent.typeCli")}
                        </InputLabel>
                        <Select
                            size="small"
                            IconComponent={KeyboardArrowDownIcon}
                            value={clientT}
                            displayEmpty
                            onChange={handleChangeTClient}
                            className="ddlGeneral"
                            input={<OutlinedInput notched label={t("recurrent.typeCli")} />}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        mt: 0.7,
                                        borderRadius: 2,
                                        boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                    },
                                },
                            }}
                        >
                            <MenuItem value="">
                                <Typography variant="body1" component="span" align="center" color="textPrimary">
                                    {t("miscellaneous.all")}
                                </Typography>
                            </MenuItem>
                            {props.typeData.map((value) => (
                                <MenuItem value={value.id}>
                                    <Typography variant="body1" component="span" align="center" color="textPrimary">
                                        {value.id === 1 ? t("miscellaneous.firm") : value.client_type}
                                    </Typography>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </GridUI>
                <GridUI item xs={12} md={4} lg={2}>
                    <FormControl fullWidth classes={{ root: classes.formRounded }}>
                        <InputLabel shrink id="franq-simple-select-label">
                            {t("miscellaneous.franchise")}
                        </InputLabel>
                        <Select
                            size="small"
                            labelId="franq-multiple-select-label"
                            IconComponent={KeyboardArrowDownIcon}
                            value={franqF}
                            displayEmpty
                            onChange={handleChangeFranqF}
                            className="ddlGeneral"
                            multiple
                            input={
                                <OutlinedInput
                                    notched
                                    label={t("miscellaneous.franchise")}
                                    sx={{
                                        fontSize: 16,
                                        fontWeight: 600,
                                    }}
                                />
                            }
                            renderValue={(selected) => {
                                if (selected.length === 0) {
                                    return <span>{t("miscellaneous.none")}</span>;
                                }
                                return selected.includes("all")
                                    ? t("miscellaneous.all")
                                    : selected
                                          .map((item) => {
                                              return franqDataProps.filter((item2) => item2.id === item)[0]
                                                  .franchise_name;
                                          })
                                          .join(", ");
                            }}
                            MenuProps={props.MenuProps}
                        >
                            {[
                                {
                                    id: "all",
                                    franchise_name: t("miscellaneous.all"),
                                },
                            ]
                                .concat(franqData)
                                .map((value) => (
                                    <MenuItem value={value.id}>
                                        <Checkbox
                                            defaultChecked
                                            checked={franqF.indexOf(value.id) > -1}
                                            icon={
                                                value.id === "all" && !franqF.includes("all") && franqF.length > 0 ? (
                                                    <IndeterminateCheckBoxIcon style={{ fill: "#031851" }} />
                                                ) : (
                                                    <CheckBoxOutlineBlankIcon />
                                                )
                                            }
                                        />
                                        <ListItemText primary={value.franchise_name} />
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                </GridUI>
                <GridUI item xs={12} md={4} lg={2}>
                    <FormControl fullWidth classes={{ root: classes.formRounded }}>
                        <InputLabel shrink id="group-simple-select-label">
                            {t("miscellaneous.group")}
                        </InputLabel>
                        <Select
                            size="small"
                            labelId="group-simple-select-label"
                            IconComponent={KeyboardArrowDownIcon}
                            value={groupF}
                            displayEmpty
                            onChange={handleChangeGroupF}
                            className="ddlGeneral"
                            multiple
                            input={
                                <OutlinedInput
                                    notched
                                    label={t("miscellaneous.group")}
                                    sx={{
                                        fontSize: 16,
                                        fontWeight: 600,
                                    }}
                                />
                            }
                            renderValue={(selected) => {
                                if (selected.length === 0) {
                                    return <span>{t("miscellaneous.none")}</span>;
                                }
                                return selected.includes("all")
                                    ? t("miscellaneous.all")
                                    : selected
                                          .map((item) => {
                                              return groupDataProps.filter((item2) => item2.id === item)[0].group_name;
                                          })
                                          .join(", ");
                            }}
                            MenuProps={props.MenuProps}
                        >
                            {[
                                {
                                    id: "all",
                                    group_name: t("miscellaneous.all"),
                                },
                            ]
                                .concat(groupData)
                                .map((value) => (
                                    <MenuItem value={value.id}>
                                        <Checkbox
                                            defaultChecked
                                            checked={groupF.indexOf(value.id) > -1}
                                            icon={
                                                value.id === "all" && !groupF.includes("all") && groupF.length > 0 ? (
                                                    <IndeterminateCheckBoxIcon style={{ fill: "#031851" }} />
                                                ) : (
                                                    <CheckBoxOutlineBlankIcon />
                                                )
                                            }
                                        />
                                        <ListItemText primary={value.group_name} />
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                </GridUI>
                <GridUI item xs={12} md={4} lg={2}>
                    <FormControl fullWidth classes={{ root: classes.formRounded }}>
                        <InputLabel shrink id="idStatus-simple-select-label">
                            {t("generalDashboard.market")}
                        </InputLabel>
                        <Select
                            size="small"
                            labelId="market-multiple-select-label"
                            IconComponent={KeyboardArrowDownIcon}
                            displayEmpty
                            value={clientMarket}
                            className="ddlGeneral"
                            onChange={handleChangeclientMarket}
                            multiple
                            input={
                                <OutlinedInput
                                    notched
                                    label={t("generalDashboard.market")}
                                    sx={{
                                        fontSize: 16,
                                        fontWeight: 600,
                                    }}
                                />
                            }
                            renderValue={(selected) => {
                                if (selected.length === 0) {
                                    return <span>{t("miscellaneous.none")}</span>;
                                }

                                return selected.includes(t("miscellaneous.all"))
                                    ? t("miscellaneous.all")
                                    : selected.join(", ");
                            }}
                            MenuProps={props.MenuProps}
                        >
                            {props.markets.map((name) => (
                                <MenuItem value={name}>
                                    <Checkbox
                                        checked={clientMarket.indexOf(name) > -1}
                                        icon={
                                            name === t("miscellaneous.all") &&
                                            !clientMarket.includes(t("miscellaneous.all")) &&
                                            clientMarket.length > 0 ? (
                                                <IndeterminateCheckBoxIcon style={{ fill: "#031851" }} />
                                            ) : (
                                                <CheckBoxOutlineBlankIcon />
                                            )
                                        }
                                    />
                                    <ListItemText primary={name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </GridUI>
                <GridUI item xs={12} md={4} lg={1}>
                    <FormControl fullWidth classes={{ root: classes.formRounded }}>
                        <InputLabel shrink id="idStatus-simple-select-label">
                            {t("miscellaneous.status")}
                        </InputLabel>
                        <Select
                            size="small"
                            labelId="idStatus-simple-select-label"
                            IconComponent={KeyboardArrowDownIcon}
                            value={idStatus}
                            displayEmpty
                            onChange={handleChangeIdStatus}
                            className="ddlGeneral"
                            multiple
                            input={
                                <OutlinedInput
                                    notched
                                    label={t("miscellaneous.status")}
                                    sx={{
                                        fontSize: 16,
                                        fontWeight: 600,
                                    }}
                                />
                            }
                            renderValue={(selected) => {
                                if (selected.length === 0) {
                                    return <span>{t("miscellaneous.all")}</span>;
                                }
                                return selected.includes("all")
                                    ? t("miscellaneous.all")
                                    : selected
                                          .map((item) => {
                                              return statusArray.filter((item2) => item2.id === item)[0].name;
                                          })
                                          .join(", ");
                            }}
                            MenuProps={props.MenuProps}
                        >
                            {statusArray.map((value) => (
                                <MenuItem value={value.id}>
                                    <Checkbox
                                        defaultChecked
                                        checked={idStatus.indexOf(value.id) > -1}
                                        icon={
                                            value.id === "all" && !idStatus.includes("all") && idStatus.length > 0 ? (
                                                <IndeterminateCheckBoxIcon style={{ fill: "#031851" }} />
                                            ) : (
                                                <CheckBoxOutlineBlankIcon />
                                            )
                                        }
                                    />
                                    <ListItemText primary={value.name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </GridUI>
                <GridUI item xs={12} md={4} lg>
                    <FormControl fullWidth classes={{ root: classes.formRounded }}>
                        <InputLabel shrink id="clientStatus-simple-select-label">
                            {t("miscellaneous.classification")}
                        </InputLabel>
                        <Select
                            size="small"
                            IconComponent={KeyboardArrowDownIcon}
                            value={clientStatus}
                            displayEmpty
                            onChange={handleChangeClasif}
                            className="ddlGeneral"
                            input={<OutlinedInput notched label={t("miscellaneous.classification")} />}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        mt: 0.7,
                                        borderRadius: 2,
                                        boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                    },
                                },
                            }}
                        >
                            <MenuItem value="">
                                <Typography variant="body1" component="span" align="center" color="textPrimary">
                                    {t("miscellaneous.all")}
                                </Typography>
                            </MenuItem>
                            <MenuItem value={1}>
                                <Typography
                                    variant="body1"
                                    component="span"
                                    align="center"
                                    color="textPrimary"
                                    sx={{
                                        fontStyle: "italic",
                                        ...(moment.locale() === "en" && { fontStyle: "normal" }),
                                    }}
                                >
                                    Standard
                                </Typography>
                            </MenuItem>
                            <MenuItem value={2}>
                                <Typography
                                    variant="body1"
                                    component="span"
                                    align="center"
                                    color="textPrimary"
                                    sx={{
                                        fontStyle: "italic",
                                        ...(moment.locale() === "en" && { fontStyle: "normal" }),
                                    }}
                                >
                                    VIP
                                </Typography>
                            </MenuItem>
                        </Select>
                    </FormControl>
                </GridUI>
                <GridUI item xs={12} md={4} lg>
                    <Button
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        disableElevation
                        onClick={downloadReport}
                        sx={{
                            width: "150px !important",
                        }}
                    >
                        {t("miscellaneous.downloadPdf")}
                    </Button>
                </GridUI>
            </GridUI>
            <GridUI container>
                <GridUI item xs={12} md={6} sx={{ mb: { sm: -5 }, zIndex: 1 }}>
                    <Button
                        variant="text"
                        color="primary"
                        disabled={anyFilter}
                        onClick={cleanFilters}
                        sx={{
                            "&:hover": {
                                color: "#2f3190",
                            },
                        }}
                    >
                        {t("miscellaneous.cleanFilters")}
                    </Button>
                </GridUI>
                <GridUI item xs={12}>
                    <Grid rows={clientsData} columns={columnDefs}>
                        <SearchState value={searchValue} onValueChange={handleSearch} />
                        <IntegratedFiltering />
                        <PagingState defaultCurrentPage={0} pageSize={10} />
                        <IntegratedPaging />
                        <Table
                            tableComponent={TableComponent}
                            columnExtensions={tableColumnExtensions}
                            cellComponent={TableCellComponent}
                            messages={tableMessages}
                        />
                        <TableColumnVisibility defaultHiddenColumnNames={defaultHiddenColumnNames} />
                        <TableHeaderRow cellComponent={tableHeaderCell} contentComponent={tableContentComponent} />
                        <Toolbar rootComponent={toolRootComponent} />
                        <SearchPanel messages={searchMessages} inputComponent={inputComponent} />
                        <PagingPanel containerComponent={PagingPanelContainer} messages={pagingMessages} />
                    </Grid>
                </GridUI>
            </GridUI>
            <CommentDialog
                open={dialogComment}
                onClose={closeDialog}
                maxWidth="xs"
                clientID={clientID}
                setTexto={setTexto}
                seAutoAlertType={seAutoAlertType}
                setBackDrop={setBackDrop}
                setShow={setShow}
                comments={comments}
                commentsP={commentsP}
                getComments={getComments}
                setInitData={loadInitData}
                year={selectedYear}
                commentType={1}
            />
            <SnackBar type={alertType} show={show} message={texto} onClose={handleClose} />
            <Backdrop open={showBackdrop} />
        </StyledContainer>
    );
};

export default GlobalReport;
