/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

/** MUI imports */
import {
    Grid,
    Table,
    TableHeaderRow,
    PagingPanel,
    Toolbar,
    SearchPanel,
    TableSelection,
} from "@devexpress/dx-react-grid-material-ui";
import {
    SearchState,
    IntegratedFiltering,
    PagingState,
    IntegratedPaging,
    SelectionState,
    IntegratedSelection,
} from "@devexpress/dx-react-grid";
import { styled } from "@mui/material/styles";
import GridUI from "@mui/material/Grid";
import { Box, Skeleton } from "@mui/material";

const PREFIX = "UpValidate";

const classes = {
    tableStriped: `${PREFIX}-tableStriped`,
    tableHeader: `${PREFIX}-tableHeader`,
    tableBody: `${PREFIX}-tableBody`,
    checkBoxDisabled: `${PREFIX}-checkBoxDisabled`,
    pager: `${PREFIX}-pager`,
    formRounded: `${PREFIX}-formRounded`,
    button: `${PREFIX}-button`,
    spacing: `${PREFIX}-spacing`,
    toolbarSearch: `${PREFIX}-toolbarSearch`,
    searchInput: `${PREFIX}-searchInput`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.formRounded}`]: {
        backgroundColor: "#FFFFFF !important",
        borderRadius: 40,
        verticalAlign: "middle !important",
        "&:focus-within": {
            backgroundColor: "#FFFFFF",
            borderRadius: 40,
        },
        "& .MuiOutlinedInput-notchedOutline": {
            border: "2px solid #031851",
            borderRadius: 40,
        },
    },

    [`& .${classes.button}`]: {
        [theme.breakpoints.up("xl")]: {
            paddingLeft: 30,
            paddingRight: 30,
        },
        borderRadius: 40,
        "&:hover": {
            backgroundColor: theme.palette.text.secondary,
            color: "#fff",
        },
    },

    [`& .${classes.spacing}`]: {
        padding: "30px 0 20px 0",
    },

    [`& .${classes.tableStriped}`]: {
        "& tbody tr:nth-of-type(odd)": {
            backgroundColor: "rgba(47,49,144,0.1)",
        },
    },
    [`& .${classes.tableHeader}`]: {
        border: "none",
        backgroundColor: "rgba(47,49,144,0.3)",
        fontSize: "15px",
        fontWeight: 600,
        color: "#031851",
        "&:first-of-type": {
            borderRadius: "20px 0 0 0",
        },
        "&:last-child": {
            borderRadius: "0 20px 0 0",
        },
    },

    [`& .${classes.tableBody}`]: {
        border: "none",
        fontSize: "14px",
        fontWeight: 400,
        color: "#031851",
    },

    [`& .${classes.checkBoxDisabled}`]: {
        border: "none",
        fontSize: "14px",
        fontWeight: 400,
        "& .MuiCheckbox-root": {
            color: "#cccccc",
        },
        pointerEvents: "none",
    },

    [`& .${classes.pager}`]: {
        "& .Pagination-button": {
            paddingTop: 0,
            paddingBottom: 0,
            color: "#031851",
            fontWeight: 600,
        },
        "& .Pagination-activeButton": {
            backgroundColor: "rgba(47,49,144,0.3)",
        },
    },

    [`& .${classes.toolbarSearch}`]: {
        border: "none !important",
        padding: "0 0 5px !important",
    },

    [`& .${classes.searchInput}`]: {
        color: "#031851",
        "&:before": {
            borderBottomColor: "#031851",
        },
        "&:hover:before": {
            borderBottomColor: "#031851 !important",
        },
    },
}));

const tableHeaderCell = ({ ...props }) => <TableHeaderRow.Cell {...props} className={classes.tableHeader} />;

const TableComponent = ({ ...props }) => <Table.Table {...props} className={classes.tableStriped} />;

const TableCellComponent = ({ ...props }) => <Table.Cell {...props} className={classes.tableBody} />;

const tableContentComponent = (props) => <TableHeaderRow.Content {...props} />;

const PagingPanelContainer = ({ ...props }) => <PagingPanel.Container {...props} className={classes.pager} />;

const inputComponent = ({ ...props }) => <SearchPanel.Input {...props} className={classes.searchInput} />;

const toolRootComponent = ({ ...props }) => <Toolbar.Root {...props} className={classes.toolbarSearch} />;

const tableHeaderSelCellBase = ({ ...props }) => (
    <TableSelection.HeaderCell {...props} className={classes.tableHeader} disabled />
);

const TableSelCellComponent = ({ ...props }) => (
    <TableSelection.Cell
        {...props}
        className={classes.tableBody}
        disabled={props.row.paidStatus === 0 || props.row.disconnectedCompany}
    />
);

const tableColumnExtensions = [
    { columnName: "client", width: "20%" },
    { columnName: "adjusted", align: "center", width: "5%" },
    { columnName: "service", align: "center", width: "10%" },
    { columnName: "type", align: "center", width: "9%" },
    // { columnName: "pay", align: 'center' },
    { columnName: "period", align: "center", width: "10%" },
    { columnName: "commit", align: "center" },
    { columnName: "estimate", align: "center" },
    { columnName: "validate", align: "center" },
    // { columnName: "invoice", align: 'center' },
    { columnName: "report", align: "center" },
];

const rowsSkeleton = [];

for (let i = 0; i < 10; i++) {
    rowsSkeleton.push({
        id: i,
        client: <Skeleton variant="text" animation="wave" />,
        adjusted: (
            <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />
        ),
        service: <Skeleton height={30} width="100%" animation="wave" />,
        type: <Skeleton height={30} width="100%" animation="wave" />,
        // pay: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: 'auto', mr: 'auto' }} />,
        period: <Skeleton variant="text" animation="wave" />,
        commit: <Skeleton variant="text" animation="wave" />,
        estimate: <Skeleton variant="text" animation="wave" />,
        validate: <Skeleton variant="text" animation="wave" />,
        // invoice: <Skeleton variant="text" animation="wave" />,
        report: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
    });
}

const UpValidateSkeletons = (props) => {
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    const tableMessages = {
        noData: t("miscellaneous.noData"),
    };

    const pagingMessages = {
        info: ({ from, to, count }) => `${from}${from < to ? `-${to}` : ""} ${t("miscellaneous.pagingOf")} ${count}`,
    };

    const searchMessages = {
        searchPlaceholder: t("miscellaneous.search"),
    };

    /** Component states */
    const [clientsData, setClients] = useState([]);
    const [columnDefs, setColumnsDefs] = useState([]);
    //const [franqData, setFranqData] = useState([]);
    const [selection, setSelection] = useState([]);

    useEffect(() => {
        (async () => {
            const columnsTemplate = [
                { name: "client", title: t("miscellaneous.client") },
                { name: "adjusted", title: " " },
                { name: "service", title: " " },
                { name: "type", title: " " },
                // { name: "pay", title: t('clientsTable.payment') },
                { name: "period", title: t("services.period") },
                { name: "commit", title: t("clientsTable.commitment") },
                { name: "estimate", title: t("miscellaneous.expenses") },
                { name: "validate", title: t("clientsTable.validated") },
                // { name: "invoice", title: t('bills.numBills') },
                { name: "report", title: t("miscellaneous.report2") },
            ];
            setColumnsDefs(columnsTemplate);
            setClients(rowsSkeleton);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t]);

    return (
        <StyledContainer component="main" sx={{ flexGrow: 1, width: "100%" }}>
            <GridUI container spacing={2} className={classes.spacing} alignItems="center">
                <GridUI item xs={12} md={4} xl={1}>
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width="100%"
                        height={40}
                        sx={{ borderRadius: 8 }}
                    />
                </GridUI>
                <GridUI item xs={12} md={4} xl={2}>
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width="100%"
                        height={40}
                        sx={{ borderRadius: 8 }}
                    />
                </GridUI>
                <GridUI item xs={12} md={4} xl={2}>
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width="100%"
                        height={40}
                        sx={{ borderRadius: 8 }}
                    />
                </GridUI>
                <GridUI item xs={12} md={4} xl={2}>
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width="100%"
                        height={40}
                        sx={{ borderRadius: 8 }}
                    />
                </GridUI>
                <GridUI item xs={12} md={4} xl={2}>
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width="100%"
                        height={40}
                        sx={{ borderRadius: 8 }}
                    />
                </GridUI>
                <GridUI item xs={12} md={4} xl={2}>
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width="100%"
                        height={40}
                        sx={{ borderRadius: 8 }}
                    />
                </GridUI>
                <GridUI item xs={12} md={2} xl={1}>
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width="100%"
                        height={40}
                        sx={{ borderRadius: 8 }}
                    />
                </GridUI>
            </GridUI>
            <GridUI container>
                <GridUI item xs={12} md={6} sx={{ mb: { sm: -5 }, zIndex: 1 }}>
                    <GridUI item xs={12} md={2}>
                        <Skeleton
                            variant="rectangular"
                            animation="wave"
                            width="100%"
                            height={40}
                            sx={{ borderRadius: 8 }}
                        />
                    </GridUI>
                </GridUI>
                <GridUI item xs={12}>
                    <Grid rows={clientsData} columns={columnDefs}>
                        <SelectionState selection={selection} onSelectionChange={setSelection} />
                        <SearchState />
                        <IntegratedFiltering />
                        <PagingState defaultCurrentPage={0} pageSize={10} />
                        <IntegratedPaging />
                        <IntegratedSelection />
                        <Table
                            tableComponent={TableComponent}
                            columnExtensions={tableColumnExtensions}
                            cellComponent={TableCellComponent}
                            messages={tableMessages}
                        />
                        <TableHeaderRow cellComponent={tableHeaderCell} contentComponent={tableContentComponent} />
                        <TableSelection
                            showSelectAll
                            headerCellComponent={tableHeaderSelCellBase}
                            cellComponent={TableSelCellComponent}
                        />
                        <Toolbar rootComponent={toolRootComponent} />
                        <SearchPanel messages={searchMessages} inputComponent={inputComponent} />
                        <PagingPanel containerComponent={PagingPanelContainer} messages={pagingMessages} />
                    </Grid>
                </GridUI>
            </GridUI>
        </StyledContainer>
    );
};

export default UpValidateSkeletons;
