/** React */
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

/** MUI icons */
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

/** MUI */
import { styled } from "@mui/material/styles";
import { Box, Card, CardContent, Chip, Grid, Typography } from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

/** Component styles */
const PREFIX = "CFOSummary";

const classes = {
    cardContentRoot: `${PREFIX}-cardContentRoot`,
    summaryLabels: `${PREFIX}-summaryLabels`,
    summaryDetails: `${PREFIX}-summaryDetails`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.cardContentRoot}`]: {
        padding: "24px 30px 30px !important",
    },

    [`& .${classes.summaryLabels}`]: {
        display: "flex",
        justifyContent: "space-between",
        padding: "24px 0px 8px",
        borderBottom: "1px solid #cccc",
    },

    [`& .${classes.summaryDetails}`]: {
        display: "flex",
        justifyContent: "space-between",
        paddingLeft: "16px",
    },
}));

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)({
    boxShadow: "none",
    "&:before": {
        display: "none",
    },
});

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: "#031851" }} />} {...props} />
))({
    "& .MuiAccordionSummary-content": {
        margin: "12px 0",
    },
});

const AccordionDetails = styled(MuiAccordionDetails)({
    padding: "0px 0px 20px 0px",
    "& .MuiCard-root": {
        boxShadow: "none",
    },
});

export const CFOSummary = (props) => {
    /** Properties */
    const { valuesCFORecu, valuesCFOOnboard, valuesCFOCatchUp, valuesCFOConsul } = props;

    /** Internationalization i18n */
    const [t] = useTranslation("global");

    /** React redux */
    const todos = useSelector((state) => state.todos);

    /** Component states */
    const [expanded, setExpanded] = useState(true);

    /** Component functions */
    const handleChangeSumaryAccordion = (event, isExpanded) => {
        setExpanded(isExpanded);
    };

    return (
        <StyledContainer>
            <Card
                elevation={0}
                sx={{
                    mt: 3,
                    borderRadius: 8,
                }}
            >
                <CardContent sx={{ pt: 1 }}>
                    <Accordion
                        expanded={expanded}
                        onChange={handleChangeSumaryAccordion}
                        TransitionProps={{ unmountOnExit: true }}
                    >
                        <AccordionSummary
                            aria-controls="panel-content"
                            id="panel-header"
                            sx={{
                                borderBottom: "2px solid #ff5968",
                                
                            }}
                        >
                            <Grid container spacing={2} alignItems="center" justifyContent="space-between">
                                <Grid item>
                                    <Typography
                                        variant="h2"
                                        align="center"
                                        sx={{
                                            fontWeight: 700,
                                        }}
                                    >
                                        CFO
                                    </Typography>
                                </Grid>
                                <Grid item sx={{ pr: 1 }}>
                                    {!valuesCFORecu.payAnnually && (
                                        <Typography variant="h3" sx={{ pb: 0.5 }}>
                                            {`$${parseFloat(
                                                !valuesCFORecu.chip
                                                    ? 0
                                                    : valuesCFORecu.recuProjectTotal +
                                                          valuesCFORecu.recuHours *
                                                              valuesCFORecu.recuHourRate *
                                                              (1 - valuesCFORecu.recuDiscount / 100)
                                            ).toLocaleString(todos.amountFormat, {
                                                maximumFractionDigits: 2,
                                            })}/${t(`proposals.month`)}`}
                                        </Typography>
                                    )}
                                    <Typography variant="h3">
                                        {`$${parseFloat(
                                            (!valuesCFOOnboard.chip ? 0 : valuesCFOOnboard.onboardProjectTotal) +
                                                (!valuesCFOCatchUp.chip
                                                    ? 0
                                                    : valuesCFOCatchUp.projectTotal +
                                                      valuesCFOCatchUp.totalHours *
                                                          valuesCFOCatchUp.hourRate *
                                                          (1 - valuesCFOCatchUp.discount / 100)) +
                                                (!valuesCFORecu.chip || !valuesCFORecu.payAnnually
                                                    ? 0
                                                    : valuesCFORecu.recuProjectTotal +
                                                      valuesCFORecu.recuHours *
                                                          valuesCFORecu.recuHourRate *
                                                          (1 - valuesCFORecu.recuDiscount / 100)) +
                                                (!valuesCFOConsul.chip ? 0 : valuesCFOConsul.consulProjectTotal)
                                        ).toLocaleString(todos.amountFormat, {
                                            maximumFractionDigits: 2,
                                        })}/${t(`proposals.oneTime`)}`}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            {valuesCFOOnboard.chip && (
                                <Grid container justifyContent="flex-end" spacing={2}>
                                    <Grid item xs={11}>
                                        <Box className={classes.summaryLabels}>
                                            <Typography
                                                variant="h2"
                                                align="center"
                                                sx={{
                                                    fontWeight: 700,
                                                    color: valuesCFOOnboard.isError? "#ff5968": "primary"
                                                }}
                                            >
                                                {t(`invoicing.onboarding`)}
                                            </Typography>
                                            <Chip
                                                id="hour-addons-chip"
                                                label={
                                                    <Typography variant="h3" align="center">
                                                        {`$${parseFloat(
                                                            valuesCFOOnboard.onboardProjectTotal
                                                        ).toLocaleString(todos.amountFormat, {
                                                            maximumFractionDigits: 2,
                                                        })}/${t(`proposals.oneTime`)}`}
                                                    </Typography>
                                                }
                                                sx={{
                                                    //backgroundColor: "#2f3190",
                                                    backgroundColor: valuesCFOOnboard.isError? "#ff5968": "#2f3190",
                                                    color: "#fff",
                                                }}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                            )}
                            {valuesCFOCatchUp.chip && (
                                <Grid container justifyContent="flex-end" spacing={2}>
                                    <Grid item xs={11}>
                                        <Box className={classes.summaryLabels}>
                                            <Typography
                                                variant="h2"
                                                align="center"
                                                sx={{
                                                    fontWeight: 700,
                                                    color: valuesCFOCatchUp.isError? "#ff5968": "primary"
                                                }}
                                            >
                                                {t(`invoicing.catchUp`)}
                                            </Typography>
                                            <Chip
                                                id="hour-addons-chip"
                                                label={
                                                    <Typography variant="h3" align="center">
                                                        {`$${parseFloat(
                                                            valuesCFOCatchUp.projectTotal +
                                                                valuesCFOCatchUp.totalHours *
                                                                    valuesCFOCatchUp.hourRate *
                                                                    (1 - valuesCFOCatchUp.discount / 100)
                                                        ).toLocaleString(todos.amountFormat, {
                                                            maximumFractionDigits: 2,
                                                        })}/${t(`proposals.oneTime`)}`}
                                                    </Typography>
                                                }
                                                sx={{
                                                    //backgroundColor: "#2f3190",
                                                    backgroundColor: valuesCFOCatchUp.isError? "#ff5968": "#2f3190",
                                                    color: "#fff",
                                                }}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item container justifyContent="flex-end" spacing={1}>
                                        <Grid item xs={11}>
                                            <Box className={classes.summaryDetails}>
                                                <Typography
                                                    variant="h3"
                                                    align="center"
                                                    sx={{
                                                        display: "block",
                                                        mt: "auto",
                                                        mb: "auto",
                                                        fontWeight: 700,
                                                        color: valuesCFOCatchUp.isError? "#ff5968": "primary"
                                                    }}
                                                >
                                                    {`${t("proposals.basicService")} (${valuesCFOCatchUp.months} ${t(
                                                        "proposals.months"
                                                    )})`}
                                                </Typography>
                                                <>
                                                    <Typography
                                                        variant="body2"
                                                        align="center"
                                                        sx={{
                                                            display: "block",
                                                            mt: "auto !important",
                                                            mb: "auto !important",
                                                            color: valuesCFOCatchUp.isError? "#ff5968": "primary"
                                                        }}
                                                    >
                                                        {`$${parseFloat(valuesCFOCatchUp.projectTotal).toLocaleString(
                                                            todos.amountFormat,
                                                            {
                                                                maximumFractionDigits: 2,
                                                            }
                                                        )}/${t("proposals.oneTime")}`}
                                                    </Typography>
                                                </>
                                            </Box>
                                        </Grid>
                                        {valuesCFOCatchUp.catchUpChecks && (
                                            <Grid item xs={11}>
                                                <Box className={classes.summaryDetails}>
                                                    <Typography
                                                        variant="h3"
                                                        align="center"
                                                        sx={{
                                                            display: "block",
                                                            mt: "auto",
                                                            mb: "auto",
                                                            fontWeight: 700,
                                                            color: valuesCFOCatchUp.isError? "#ff5968": "primary"
                                                        }}
                                                    >
                                                        {`${t(`proposals.addons`)} (${
                                                            valuesCFOCatchUp.totalHours
                                                        } hrs)`}
                                                    </Typography>
                                                    <Typography variant="body2" align="center" sx={{color: valuesCFOCatchUp.isError? "#ff5968": "primary"}}>
                                                        {`$${parseFloat(
                                                            valuesCFOCatchUp.totalHours *
                                                                valuesCFOCatchUp.hourRate *
                                                                (1 - valuesCFOCatchUp.discount / 100)
                                                        ).toLocaleString(todos.amountFormat, {
                                                            maximumFractionDigits: 2,
                                                        })}/${t(`proposals.oneTime`)}`}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            )}
                            {valuesCFORecu.chip && (
                                <Grid container justifyContent="flex-end" spacing={2}>
                                    <Grid item xs={11}>
                                        <Box className={classes.summaryLabels}>
                                            <Typography
                                                variant="h2"
                                                align="center"
                                                sx={{
                                                    fontWeight: 700,
                                                    color: valuesCFORecu.isError? "#ff5968": "primary"
                                                }}
                                            >
                                                {`${t(`invoicing.recurring`)}${valuesCFORecu.payAnnually ? "**" : ""}`}
                                            </Typography>
                                            <Chip
                                                id="hour-addons-chip"
                                                label={
                                                    <Typography variant="h3" align="center">
                                                        {`$${parseFloat(
                                                            valuesCFORecu.recuProjectTotal +
                                                                valuesCFORecu.recuHours *
                                                                    valuesCFORecu.recuHourRate *
                                                                    (1 - valuesCFORecu.recuDiscount / 100)
                                                        ).toLocaleString(todos.amountFormat, {
                                                            maximumFractionDigits: 2,
                                                        })}/${
                                                            valuesCFORecu.payAnnually
                                                                ? t(`proposals.oneTime`)
                                                                : t(`proposals.month`)
                                                        }`}
                                                    </Typography>
                                                }
                                                sx={{
                                                    //backgroundColor: "#2f3190",
                                                    backgroundColor: valuesCFORecu.isError? "#ff5968": "#2f3190",
                                                    color: "#fff",
                                                }}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item container justifyContent="flex-end" spacing={1}>
                                        <Grid item xs={11}>
                                            <Box className={classes.summaryDetails}>
                                                <Typography
                                                    variant="h3"
                                                    align="center"
                                                    sx={{
                                                        fontWeight: 700,
                                                        color: valuesCFORecu.isError? "#ff5968": "primary"
                                                    }}
                                                >
                                                    {t(`proposals.basicService`)}
                                                </Typography>
                                                <Typography variant="body2" align="center" sx={{color: valuesCFORecu.isError? "#ff5968": "primary"}}>
                                                    {`$${parseFloat(valuesCFORecu.recuProjectTotal).toLocaleString(
                                                        todos.amountFormat,
                                                        {
                                                            maximumFractionDigits: 2,
                                                        }
                                                    )}/${
                                                        valuesCFORecu.payAnnually
                                                            ? t(`proposals.oneTime`)
                                                            : t(`proposals.month`)
                                                    }`}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        {valuesCFORecu.recuChecks && (
                                            <Grid item xs={11}>
                                                <Box className={classes.summaryDetails}>
                                                    <Typography
                                                        variant="h3"
                                                        align="center"
                                                        sx={{
                                                            display: "block",
                                                            mt: "auto",
                                                            mb: "auto",
                                                            fontWeight: 700,
                                                            color: valuesCFORecu.isError? "#ff5968": "primary"
                                                        }}
                                                    >
                                                        {`${t(`proposals.addons`)} (${valuesCFORecu.recuHours} hrs)*`}
                                                    </Typography>
                                                    <Typography variant="body2" align="center" sx={{color: valuesCFORecu.isError? "#ff5968": "primary"}}>
                                                        {`$${parseFloat(
                                                            valuesCFORecu.recuHourRate *
                                                                valuesCFORecu.recuHours *
                                                                (1 - valuesCFORecu.recuDiscount / 100)
                                                        ).toLocaleString(todos.amountFormat, {
                                                            maximumFractionDigits: 2,
                                                        })}/${t("proposals.month")}`}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            )}
                            {valuesCFOConsul.chip && (
                                <Grid container justifyContent="flex-end" spacing={2}>
                                    <Grid item xs={11}>
                                        <Box className={classes.summaryLabels}>
                                            <Typography
                                                variant="h2"
                                                align="center"
                                                sx={{
                                                    fontWeight: 700,
                                                    color: valuesCFOConsul.isError? "#ff5968": "primary"
                                                }}
                                            >
                                                {t(`invoicing.consulting`)}
                                            </Typography>
                                            <Chip
                                                id="hour-addons-chip"
                                                label={
                                                    <Typography variant="h3" align="center">
                                                        {`$${parseFloat(
                                                            valuesCFOConsul.consulProjectTotal
                                                        ).toLocaleString(todos.amountFormat, {
                                                            maximumFractionDigits: 2,
                                                        })}/${t(`proposals.oneTime`)}`}
                                                    </Typography>
                                                }
                                                sx={{
                                                    //backgroundColor: "#2f3190",
                                                    backgroundColor: valuesCFOConsul.isError? "#ff5968": "#2f3190",
                                                    color: "#fff",
                                                }}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item container justifyContent="flex-end" spacing={1}>
                                        <Grid item xs={11}>
                                            <Box className={classes.summaryDetails}>
                                                <Typography
                                                    variant="h3"
                                                    align="center"
                                                    sx={{
                                                        display: "block",
                                                        mt: "auto",
                                                        mb: "auto",
                                                        fontWeight: 700,
                                                        color: valuesCFOConsul.isError? "#ff5968": "primary"
                                                    }}
                                                >
                                                    {`${t(`proposals.query`)} (${valuesCFOConsul.consulHours} hrs)`}
                                                </Typography>
                                                <Typography variant="body2" align="center" sx={{color: valuesCFOConsul.isError? "#ff5968": "primary"}}>
                                                    {`$${parseFloat(valuesCFOConsul.consulProjectTotal).toLocaleString(
                                                        todos.amountFormat,
                                                        {
                                                            maximumFractionDigits: 2,
                                                        }
                                                    )}/${t(`proposals.oneTime`)}`}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                        </AccordionDetails>
                    </Accordion>
                </CardContent>
            </Card>
        </StyledContainer>
    );
};
