/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import moment from "moment";

/** MUI imports */
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { Box, Chip, Grid, Skeleton, Typography } from "@mui/material";

/** MUI icons */
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

/** Components imports */
import {
    AccountantRoot,
    BookkeeperRoot,
    ControllerRoot,
    TaxExpertRoot,
    CFORoot,
    CompilationRoot,
    SetupRoot,
    SubscripRoot,
} from "./Services";

import Compilation from "./Services/ServiceComp";
import Review from "./Services/ServiceRev";
import Backdrop from "../Backdrop";
import SnackBar from "../SnackBar";

const PREFIX = "ClientServ";

const classes = {
    btnRoot: `${PREFIX}-btnRoot`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.btnRoot}`]: {
        border: "1px solid #ff5968",
        borderRadius: 40,
        "&:hover": {
            backgroundColor: "#2f3190",
            border: "1px solid #2f3190",
        },
    },
}));

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)({
    boxShadow: "none",
    "&:before": {
        display: "none",
    },
});

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: "#031851" }} />} {...props} />
))({
    "& .MuiAccordionSummary-content": {
        margin: "12px 0",
    },
});

const AccordionSummarySkeleton = styled((props) => (
    <MuiAccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: "#cccccc" }} />} {...props} />
))({
    "& .MuiAccordionSummary-content": {
        margin: "12px 0",
    },
});

const AccordionDetails = styled(MuiAccordionDetails)({
    padding: "0px 0px 20px 0px",
    "& .MuiCard-root": {
        boxShadow: "none",
    },
});

// eslint-disable-next-line no-extend-native
Array.prototype.move = function (from, to) {
    this.splice(to, 0, this.splice(from, 1)[0]);
};

var services = [
    {
        name: "Compilation",
        isActive: false,
        data: {},
    },
    {
        name: "Review",
        isActive: false,
        data: {},
    },
];

let servicesAux = [
    {
        name: "Accountant",
        isActive: false,
        data: {},
    },
    {
        name: "Bookkeeper",
        isActive: false,
        data: {},
    },
    {
        name: "CFO",
        isActive: false,
        data: {},
    },
    {
        name: "Controller",
        isActive: false,
        data: {},
    },
    {
        name: "Tax Expert",
        isActive: false,
        data: {},
    },
    {
        name: "Software setup",
        isActive: false,
        data: {},
    },
    {
        name: "Software subscription",
        isActive: false,
        data: {},
    },
    // {
    //     name: "Compilation",
    //     isActive: false,
    //     data: {},
    // },
];

const ClientServ = (props) => {
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    /** React redux */
    const todos = useSelector((state) => state.todos);

    /** Component states */
    const [showBackdrop, setBackDrop] = useState(false);
    const [texto, setTexto] = useState("");
    const [alertType, setalertType] = useState("");
    const [show, setShow] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [auxExpanded, setAuxExpanded] = useState(false);
    const [activeServices, setActiveServices] = useState([]);
    const [isDisabled, setisDisabled] = useState(false);
    const [lastChipSelected, setLastChipSelected] = useState("");
    const [renderState, setRenderState] = useState(false);

    /** Component functions */
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setShow(false);
    };

    const handleChangeAcc = async (panel, setChip = false, isExpanded) => {
        if (setChip) {
            if (panel === "Compilation") {
                setLastChipSelected("Compilation");
            } else if (panel === "Review") {
                setLastChipSelected("Review");
            }
            setExpanded(!isExpanded ? panel : false);
            setAuxExpanded(false);
        } else {
            setExpanded(panel);
            setAuxExpanded(false);
        }
    };

    const handleChangeAccordion = (panel) => (event, isExpanded) => {
        setLastChipSelected("");
        setAuxExpanded(isExpanded ? panel : false);
        setExpanded(false);
    };

    const reloadServices = async () => {
        setBackDrop(true);
        await fetch(`/getServicesCompany?id_organisation=${props.data._id}`, {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then((res) => res.json())
            .then((data) => {
                props.setServices(data);
                if (lastChipSelected === "Compilation" || lastChipSelected === "Review") {
                    setRenderState(!renderState);
                }
            });
        setBackDrop(false);
    };

    useEffect(() => {
        if (!props.loadingCard) {
            servicesAux = [
                {
                    name: "Accountant",
                    isActive: false,
                    lastestDate: null,
                    data: {},
                },
                {
                    name: "Bookkeeper",
                    isActive: false,
                    lastestDate: null,
                    data: {},
                },
                {
                    name: "CFO",
                    isActive: false,
                    data: {},
                },
                {
                    name: "Controller",
                    isActive: false,
                    lastestDate: null,
                    data: {},
                },
                {
                    name: "Software setup",
                    isActive: false,
                    lastestDate: null,
                    data: {},
                },
                {
                    name: "Software subscription",
                    isActive: false,
                    lastestDate: null,
                    data: {},
                },
            ];

            if (props.data.country_name === "Venezuela" || props.data.country_name === "Estados Unidos") {
                servicesAux.push({
                    name: "Tax Expert",
                    isActive: false,
                    lastestDate: null,
                    data: {},
                });
            }

            setAuxExpanded(false);

            services = [
                {
                    name: "Compilation",
                    isActive: false,
                    data: {},
                },
                {
                    name: "Review",
                    isActive: false,
                    data: {},
                },
            ];
            setActiveServices([]);
            setExpanded(false);

            let cont = 0;

            if (
                (props.data.franchise_id !== undefined && props.data.franchise_id !== null) ||
                (props.data.practice_id !== undefined && props.data.practice_id !== null)
            ) {
                setisDisabled(true);
            }

            props.services.forEach((val) => {
                services.forEach((aux, index) => {
                    if (
                        val.service_name === aux.name &&
                        val.since_date !== undefined &&
                        (val.end_date === undefined || moment(val.end_date).isSameOrAfter(moment()))
                    ) {
                        services[index].isActive = true;
                        services[index].data = val;
                        services.move(index, 0);
                        setActiveServices((prevArray) => [...prevArray, val.service_name]);
                        cont++;
                    } else if (val.service_name === aux.name) {
                        services[index].data = val;
                    }
                });
            });
            services.forEach((aux, index) => {
                if (aux.isActive === false && aux.data.end_date !== undefined) {
                    services.move(index, cont);
                    cont++;
                }
            });

            // Recorremos cada servicio
            props.serviceData.forEach((val) => {
                servicesAux.forEach((aux, index) => {
                    if (val.service_name === aux.name) {
                        servicesAux[index].data = val;

                        // Verificamos que el servicio se ha creado
                        if (val.since_date) {
                            // Validamos si el servicio esta activo y en caso contrario se evaluan las fechas fin y de entrega
                            if (
                                (!val.client_delivery_date && !val.end_date) ||
                                (val.client_delivery_date && !val.end_date)
                            ) {
                                servicesAux[index].isActive = true;
                                setActiveServices((prevArray) => [...prevArray, val.service_name]);
                            } else if (val.end_date && !val.client_delivery_date) {
                                if (
                                    moment(val.end_date, "YYYY-MM-DD").isAfter(moment()) ||
                                    val.client_commitment_date
                                ) {
                                    servicesAux[index].isActive = true;
                                    setActiveServices((prevArray) => [...prevArray, val.service_name]);
                                }
                                if (moment(val.end_date, "YYYY-MM-DD").isBefore(moment())) {
                                    servicesAux[index].lastestDate = moment(val.end_date, "YYYY-MM-DD");
                                }
                            } else if (val.end_date && val.client_delivery_date) {
                                let endDate = moment(val.end_date, "YYYY-MM-DD");
                                let deliveryDate = moment(val.client_delivery_date, "YYYY-MM-DD");
                                let lastDate = null;

                                if (val.service_name === "Accountant" || val.service_name === "Tax Expert") {
                                    lastDate = endDate;
                                } else {
                                    lastDate = endDate.isBefore(deliveryDate.format("YYYY-MM-DD"))
                                        ? deliveryDate
                                        : endDate;
                                }
                                if (lastDate.isAfter(moment())) {
                                    servicesAux[index].isActive = true;
                                    setActiveServices((prevArray) => [...prevArray, val.service_name]);
                                } else {
                                    servicesAux[index].lastestDate = lastDate;
                                }
                            }
                        }
                    }
                });
            });
        }
    }, [
        t,
        todos.dateFormat,
        todos.amountFormat,
        props.data.franchise_id,
        props.data.practice_id,
        props.services,
        props.serviceData,
        props.loadingCard,
        props.data.country_name,
    ]);

    /* Component functions */
    useEffect(() => {
        if (lastChipSelected && lastChipSelected === "Compilation") {
            handleChangeAcc("Compilation", false, true);
        }
        if (lastChipSelected && lastChipSelected === "Review") {
            handleChangeAcc("Review", false, true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [renderState]);

    return (
        <StyledContainer>
            {props.loadingCard
                ? Array(1).fill(
                      <>
                          <Accordion sx={{ borderBottom: "3px solid #cccccc" }}>
                              <AccordionSummarySkeleton aria-controls="accordion-content" id="accordion-header">
                                  <Skeleton variant="text" animation="wave" height={15} width="25%" />
                              </AccordionSummarySkeleton>
                          </Accordion>
                          <Accordion sx={{ borderBottom: "3px solid #cccccc" }}>
                              <AccordionSummarySkeleton aria-controls="accordion-content" id="accordion-header">
                                  <Skeleton variant="text" animation="wave" height={15} width="15%" />
                              </AccordionSummarySkeleton>
                          </Accordion>
                      </>
                  )
                : servicesAux.map((value) => (
                      <Accordion
                          expanded={auxExpanded === value.name}
                          onChange={handleChangeAccordion(value.name)}
                          TransitionProps={{ unmountOnExit: true }}
                          sx={{
                              borderBottom: value.isActive ? "3px solid #ff5968" : "3px solid #cccccc",
                          }}
                      >
                          <AccordionSummary aria-controls="panelAuxbh-content" id="panelAuxbh-header">
                              <Grid container spacing={2} alignItems="center" justifyContent="space-between">
                                  <Grid item>
                                      <Typography variant="h5">{value.name}</Typography>
                                  </Grid>
                                  {value.data.since_date && (
                                      <Grid item>
                                          <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
                                              <Grid item>
                                                  <Chip
                                                      size="small"
                                                      label={moment(value.data.since_date, "YYYY-MM-DD").format(
                                                          todos.dateFormat
                                                      )}
                                                  />
                                              </Grid>
                                              <Grid item>
                                                  <Chip
                                                      size="small"
                                                      label={
                                                          value.lastestDate
                                                              ? value.lastestDate.format(todos.dateFormat)
                                                              : "Ongoing"
                                                      }
                                                  />
                                              </Grid>
                                          </Grid>
                                      </Grid>
                                  )}
                              </Grid>
                          </AccordionSummary>
                          <AccordionDetails>
                              {value.name === "Accountant" && (
                                  <AccountantRoot
                                      setTexto={setTexto}
                                      setalertType={setalertType}
                                      setShow={setShow}
                                      setBackDrop={setBackDrop}
                                      actives={activeServices}
                                      clientMarket={props.data.country_name}
                                      expenseEst={props.data.expense_estimation}
                                      accountSystem={props.accountSystem}
                                      serviceData={value.data}
                                      companyID={props.data._id}
                                      setServiceData={props.setServiceData}
                                      setAuxExpanded={setAuxExpanded}
                                      setExpanded={setExpanded}
                                      lastChipSelected={lastChipSelected}
                                      setLastChipSelected={setLastChipSelected}
                                  />
                              )}
                              {value.name === "Bookkeeper" && (
                                  <BookkeeperRoot
                                      setTexto={setTexto}
                                      setalertType={setalertType}
                                      setShow={setShow}
                                      setBackDrop={setBackDrop}
                                      actives={activeServices}
                                      clientMarket={props.data.country_name}
                                      expenseEst={props.data.expense_estimation}
                                      bookkeeperSystem={props.accountSystem}
                                      serviceData={value.data}
                                      companyID={props.data._id}
                                      setServiceData={props.setServiceData}
                                      setAuxExpanded={setAuxExpanded}
                                      setExpanded={setExpanded}
                                      lastChipSelected={lastChipSelected}
                                      setLastChipSelected={setLastChipSelected}
                                  />
                              )}
                              {value.name === "CFO" && (
                                  <CFORoot
                                      setTexto={setTexto}
                                      setalertType={setalertType}
                                      setShow={setShow}
                                      setBackDrop={setBackDrop}
                                      actives={activeServices}
                                      clientMarket={props.data.country_name}
                                      expenseEst={props.data.expense_estimation}
                                      cfoSystem={props.accountSystem}
                                      serviceData={value.data}
                                      companyID={props.data._id}
                                      setServiceData={props.setServiceData}
                                      setAuxExpanded={setAuxExpanded}
                                      setExpanded={setExpanded}
                                      lastChipSelected={lastChipSelected}
                                      setLastChipSelected={setLastChipSelected}
                                  />
                              )}
                              {value.name === "Controller" && (
                                  <ControllerRoot
                                      setTexto={setTexto}
                                      setalertType={setalertType}
                                      setShow={setShow}
                                      setBackDrop={setBackDrop}
                                      actives={activeServices}
                                      clientMarket={props.data.country_name}
                                      expenseEst={props.data.expense_estimation}
                                      controlSystem={props.accountSystem}
                                      serviceData={value.data}
                                      companyID={props.data._id}
                                      setServiceData={props.setServiceData}
                                      setAuxExpanded={setAuxExpanded}
                                      setExpanded={setExpanded}
                                      lastChipSelected={lastChipSelected}
                                      setLastChipSelected={setLastChipSelected}
                                  />
                              )}
                              {value.name === "Tax Expert" && (
                                  <TaxExpertRoot
                                      setTexto={setTexto}
                                      setalertType={setalertType}
                                      setShow={setShow}
                                      setBackDrop={setBackDrop}
                                      actives={activeServices}
                                      clientMarket={props.data.country_name}
                                      expenseEst={props.data.expense_estimation}
                                      accountSystem={props.accountSystem}
                                      serviceData={value.data}
                                      companyID={props.data._id}
                                      setServiceData={props.setServiceData}
                                      setAuxExpanded={setAuxExpanded}
                                      setExpanded={setExpanded}
                                      lastChipSelected={lastChipSelected}
                                      setLastChipSelected={setLastChipSelected}
                                  />
                              )}
                              {value.name === "Compilation" && (
                                  <CompilationRoot
                                      setTexto={setTexto}
                                      setalertType={setalertType}
                                      setShow={setShow}
                                      setBackDrop={setBackDrop}
                                      clientMarket={props.data.country_name}
                                      expenseEst={props.data.expense_estimation}
                                      accountSystem={props.accountSystem}
                                      //   reloadServices={loadNewServices}
                                      //   serviceData={serviceData[1]}
                                      companyID={props.data._id}
                                      setAuxExpanded={setAuxExpanded}
                                      setExpanded={setExpanded}
                                      lastChipSelected={lastChipSelected}
                                      setLastChipSelected={setLastChipSelected}
                                  />
                              )}
                              {value.name === "Software setup" && (
                                  <SetupRoot
                                      setTexto={setTexto}
                                      setalertType={setalertType}
                                      setShow={setShow}
                                      setBackDrop={setBackDrop}
                                      actives={activeServices}
                                      clientMarket={props.data.country_name}
                                      serviceData={value.data}
                                      companyID={props.data._id}
                                      setServiceData={props.setServiceData}
                                      setAuxExpanded={setAuxExpanded}
                                      setExpanded={setExpanded}
                                      lastChipSelected={lastChipSelected}
                                      setLastChipSelected={setLastChipSelected}
                                  />
                              )}
                              {value.name === "Software subscription" && (
                                  <SubscripRoot
                                      setTexto={setTexto}
                                      setalertType={setalertType}
                                      setShow={setShow}
                                      setBackDrop={setBackDrop}
                                      actives={activeServices}
                                      clientMarket={props.data.country_name}
                                      serviceData={value.data}
                                      companyID={props.data._id}
                                      setServiceData={props.setServiceData}
                                      setAuxExpanded={setAuxExpanded}
                                      setExpanded={setExpanded}
                                      lastChipSelected={lastChipSelected}
                                      setLastChipSelected={setLastChipSelected}
                                  />
                              )}
                          </AccordionDetails>
                      </Accordion>
                  ))}
            {props.loadingCard
                ? Array(5).fill(
                      <>
                          <Accordion sx={{ borderBottom: "3px solid #cccccc" }}>
                              <AccordionSummarySkeleton aria-controls="accordion-content" id="accordion-header">
                                  <Skeleton variant="text" animation="wave" height={15} width="25%" />
                              </AccordionSummarySkeleton>
                          </Accordion>
                          <Accordion sx={{ borderBottom: "3px solid #cccccc" }}>
                              <AccordionSummarySkeleton aria-controls="accordion-content" id="accordion-header">
                                  <Skeleton variant="text" animation="wave" height={15} width="15%" />
                              </AccordionSummarySkeleton>
                          </Accordion>
                      </>
                  )
                : services.map((value) => (
                      <Accordion
                          expanded={expanded === value.name}
                          onChange={() => handleChangeAcc(value.name, true, expanded === value.name)}
                          TransitionProps={{ unmountOnExit: true }}
                          sx={{
                              borderBottom: value.isActive ? "3px solid #ff5968" : "3px solid #cccccc",
                          }}
                      >
                          <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
                              <Grid container spacing={2} alignItems="center" justifyContent="space-between">
                                  <Grid item>
                                      <Typography variant="h5">{value.name}</Typography>
                                  </Grid>
                                  {value.data.since_date !== undefined && (
                                      <Grid item>
                                          <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
                                              <Grid item>
                                                  <Chip
                                                      size="small"
                                                      label={moment(value.data.since_date, "YYYY/MM/DD").format(
                                                          todos.dateFormat
                                                      )}
                                                  />
                                              </Grid>
                                              <Grid item>
                                                  <Chip
                                                      size="small"
                                                      label={
                                                          moment(value.data.end_date).isSameOrAfter(moment())
                                                              ? "Ongoing"
                                                              : moment(value.data.end_date, "YYYY/MM/DD").format(
                                                                    todos.dateFormat
                                                                )
                                                      }
                                                  />
                                              </Grid>
                                          </Grid>
                                      </Grid>
                                  )}
                              </Grid>
                          </AccordionSummary>
                          <AccordionDetails>
                              {value.name === "Compilation" && (
                                  <Compilation
                                      reloadServices={reloadServices}
                                      isDisabled={isDisabled}
                                      companyID={props.data._id}
                                      data={value.data}
                                      setTexto={setTexto}
                                      bsaFirm={props.data.bsa_date}
                                      setalertType={setalertType}
                                      setShow={setShow}
                                      setBackDrop={setBackDrop}
                                      clientType={props.data.client_type}
                                      clientMarket={props.data.country_name}
                                      expenseEst={props.data.expense_estimation}
                                  />
                              )}
                              {value.name === "Review" && (
                                  <Review
                                      reloadServices={reloadServices}
                                      isDisabled={isDisabled}
                                      companyID={props.data._id}
                                      data={value.data}
                                      setTexto={setTexto}
                                      bsaFirm={props.data.bsa_date}
                                      setalertType={setalertType}
                                      setShow={setShow}
                                      setBackDrop={setBackDrop}
                                      clientType={props.data.client_type}
                                      clientMarket={props.data.country_name}
                                      expenseEst={props.data.expense_estimation}
                                  />
                              )}
                          </AccordionDetails>
                      </Accordion>
                  ))}
            <SnackBar type={alertType} show={show} message={texto} onClose={handleClose} />
            <Backdrop open={showBackdrop} />
        </StyledContainer>
    );
};

export default ClientServ;
