/* React imports */
import React from "react";
import { useTranslation } from "react-i18next";
import { utils, writeFileXLSX } from "xlsx";
import moment from "moment";

/* Components */
import { styled, alpha } from "@mui/material/styles";
import {
    Grid,
    Card,
    CardHeader,
    CardContent,
    Divider,
    InputBase,
    List,
    ListItemButton,
    ListItemText,
    Typography,
    IconButton,
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

/* MUI icons */
import SearchIcon from "@mui/icons-material/Search";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

const Search = styled("div")(({ theme }) => ({
    width: "100%",
    position: "relative",
    marginLeft: 0,
    marginBottom: 10,
    borderRadius: 25,
    backgroundColor: alpha(theme.palette.primary.main, 0.15),
    [theme.breakpoints.up("sm")]: {
        width: "auto",
    },
    "&:hover": {
        backgroundColor: alpha(theme.palette.primary.light, 0.25),
    },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    width: "100%",
    "& .MuiInputBase-input": {
        padding: theme.spacing(2),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create("width"),
        width: "100%",
    },
}));

const style = {
    width: "100%",
    maxHeight: 620,
    bgcolor: "background.paper",
    overflowY: "auto",
};

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "rgba(19, 31, 62, 0.80)",
        fontSize: 12,
        maxWidth: 320,
        borderRadius: 6,
        fontWeight: "normal",
    },
});

const ClientsList = ({
    totalClients,
    searchValue,
    handleSearch,
    clientSearch,
    selectedIndex,
    handleChangeClient,
    isFilterComp,
    filtersXLSX,
    franqSearch,
    groupSearch,
    serviceSearch,
    projectsSearch
}) => {
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    /* get state data and export to XLSX */
    const exportFile = () => {
        const filterArray = [];

        if (filtersXLSX.status.length !== 0) {
            filterArray.push("Estatus: " + filtersXLSX.status);
        }
        if (filtersXLSX.franchises.length !== 0) {
            let aux = franqSearch.filter((r) => filtersXLSX.franchises.includes(r.id));
            aux = aux.map((item) => item.franchise_name);

            filterArray.push("Franquicias: " + aux.join(", "));
        }
        if (filtersXLSX.groups.length !== 0) {
            let aux = groupSearch.filter((r) => filtersXLSX.groups.includes(r.id));
            aux = aux.map((item) => item.group_name);

            filterArray.push("Grupos: " + aux.join(", "));
        }
        if (filtersXLSX.clientT.length !== 0) {
            const aux = filtersXLSX.clientT.map((item) => (item === 1 ? "Firma" : "SMB"));

            filterArray.push("Tipo de cliente: " + aux.join(", "));
        }
        if (filtersXLSX.clientStat.length !== 0) {
            const aux = filtersXLSX.clientStat.map((item) => (item === 1 ? "Standard" : "VIP"));

            filterArray.push("Clasificación: " + aux.join(", "));
        }
        if (filtersXLSX.market.length !== 0) {
            const aux = filtersXLSX.market.map((item) =>
                item === 1 ? "Venezuela" : item === 2 ? "USA" : item === 3 ? "Global" : "LATAM"
            );

            filterArray.push("Mercado: " + aux.join(", "));
        }
        if (filtersXLSX.software.length !== 0) {
            filterArray.push("Sistema contable: " + filtersXLSX.software);
        }
        if (filtersXLSX.servicesF.length !== 0) {
            let aux = serviceSearch.filter((r) => filtersXLSX.servicesF.includes(r._id));
            aux = aux.map((item) => item.service_name);

            filterArray.push("Servicios: " + aux.join(", "));
        }
        if (filtersXLSX.projectsF.length !== 0) {
            let aux = projectsSearch.filter((r) => filtersXLSX.projectsF.includes(r._id));
            aux = aux.map((item) => item.concat_name);

            filterArray.push("Proyectos: " + aux.join(", "));
        }
        if (filtersXLSX.additionalsF.length !== 0) {
            filterArray.push("Adicionales: " + filtersXLSX.additionalsF.join(", "));
        }
        if (filtersXLSX.advisor.length !== 0) {
            filterArray.push("Advisor: " + filtersXLSX.advisor.join(", "));
        }
        if (filtersXLSX.analyst.length !== 0) {
            filterArray.push("Especialista: " + filtersXLSX.analyst.join(", "));
        }
        if (filtersXLSX.backupAnalyst.length !== 0) {
            filterArray.push("Especialista backup: " + filtersXLSX.backupAnalyst.join(", "));
        }
        if (filtersXLSX.manager.length !== 0) {
            filterArray.push("Gerente: " + filtersXLSX.manager.join(", "));
        }
        if (filtersXLSX.supervisor.length !== 0) {
            filterArray.push("Supervisor: " + filtersXLSX.supervisor.join(", "));
        }

        let newArray = [];

        if (clientSearch.length > filterArray.length) {
            newArray = clientSearch.map((item, index) => {
                let obj = {
                    SMB: item.company_legal_name,
                    " ": "",
                    Filtros: "",
                };
                if (index < filterArray.length) {
                    obj.Filtros = filterArray[index];
                } 
                else if (filterArray.length === index) {
                    obj.Filtros = "Generado: " + moment().format("DD/MM/YYYY HH:mm");
                }
                return obj;
            });
        } else if(clientSearch.length === 1) {
            newArray = clientSearch.map((item, index) => {
                let obj = {
                    SMB: item.company_legal_name,
                    " ": "",
                    Filtros: "",
                };
                if (index < filterArray.length) {
                    obj.Filtros = filterArray[index];
                } 
                return obj;
            });
            newArray.push({
                SMB: "",
                " ": "",
                Filtros: "Generado: " + moment().format("DD/MM/YYYY HH:mm"),
            });
         } else {
            newArray = filterArray.map((item, index) => {
                let obj = {
                    SMB: "",
                    " ": "",
                    Filtros: item,
                };
                if (index < clientSearch.length) {
                    obj.SMB = clientSearch[index].company_legal_name;
                }
                return obj;
            });
            newArray.push({
                SMB: "",
                " ": "",
                Filtros: "Generado: " + moment().format("DD/MM/YYYY HH:mm"),
            });
        }

        const ws = utils.json_to_sheet(newArray);
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, "Data");
        writeFileXLSX(wb, "Impresión de coincidencias.xlsx");
    };

    return (
        <Card
            elevation={0}
            sx={{
                borderRadius: 8,
                boxShadow: "10px 10px 15px rgba(3,24,81,0.15)",
            }}
        >
            <CardHeader
                title={
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item>
                            <Typography variant="h3" color="primary">
                                {t("miscellaneous.clients")}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Grid container alignItems="center" justifyContent="flex-end" spacing={1}>
                                <Grid item>
                                    <Typography variant="body2" color="primary">
                                        {`${t("miscellaneous.quantity")}: ${totalClients}`}
                                    </Typography>
                                </Grid>
                                {isFilterComp && (
                                    <Grid item>
                                        <LightTooltip title={t("dialogConversions.download")} aria-label="downloadFile">
                                            <IconButton edge="end" aria-label="downloadFile" onClick={exportFile}>
                                                <FileDownloadIcon fontSize="medium" color="primary" />
                                            </IconButton>
                                        </LightTooltip>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                }
                sx={{
                    m: 2,
                    borderBottom: "2px solid #FF5968",
                }}
            />
            <CardContent sx={{ pt: 0 }}>
                <Search>
                    <SearchIconWrapper>
                        <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase
                        value={searchValue}
                        placeholder={t("miscellaneous.search")}
                        inputProps={{ "aria-label": "search" }}
                        onChange={handleSearch}
                    />
                </Search>
                <List sx={style} component="nav">
                    {clientSearch.map((option, index) => (
                        <>
                            <ListItemButton
                                selected={selectedIndex === index}
                                key={index}
                                onClick={() => {
                                    handleChangeClient(option, index);
                                    if (window.innerWidth < 600) {
                                        setTimeout(() => {
                                            window.scrollTo({ top: 1170, behavior: "smooth" });
                                        }, 50);
                                    }
                                }}
                                sx={{
                                    "&:hover": { backgroundColor: "#F8F9F8" },
                                    "&.Mui-selected": { backgroundColor: "#D9DCE5" },
                                }}
                            >
                                <ListItemText primary={option.company_legal_name} />
                            </ListItemButton>
                            {clientSearch.length !== index + 1 ? <Divider /> : null}
                        </>
                    ))}
                </List>
            </CardContent>
        </Card>
    );
};

export default ClientsList;
