import axios from "axios";

/* Script que contiene las llamadas a la API */
const projects = {
    // Función para obtener la info del cliente
    getCompanyInfo: (id) => {
        const request = axios.get(`/getCompanyInfo?id_organisation=${id}`);

        return request.then((response) => response.data);
    },

    // Función para obtener el tamano de los archivos
    getTotalSizeFileDocS3: (params) => {
        const request = axios.get("/getTotalSizeFileDocS3", { params });

        return request.then((response) => response.data);
    },

    // Función para guardar y subir a la nube el archivo
    uploadFilesDocS3: (url, data, options) => {
        const request = axios.post(url, data, options);

        return request.then((response) => response);
    },

    // Función para descargar un archivo
    getFileDocS3: (params) => {
        const request = axios.get("/getFileDocS3", { params, responseType: "blob" });

        return request.then((response) => response);
    },

    // Función para descargar masivamente
    getBulkFilesDocS3: (params) => {
        const request = axios.get("/getBulkFilesDocS3", { params, responseType: "blob" });

        return request.then((response) => response);
    },

    // Función para eliminar un archivo
    deleteFileDocS3: (params) => {
        const request = axios.post("/deleteFileDocS3", params);

        return request.then((response) => response);
    },

    // Función para eliminar masivamente
    deleteBulkFilesDocS3: (params) => {
        const request = axios.post("/deleteBulkFilesDocS3", params);

        return request.then((response) => response);
    },

    // Función para listar archivos
    getUploadedFileDoc: (params) => {
        const request = axios.get("/getUploadedFileDoc", { params });

        return request.then((response) => response.data);
    },

    // Función para eliminar archivos cancelados
    deleteFileDocS3multiple: (params) => {
        const request = axios.post("/deleteFileDocS3multiple", params);

        return request.then((response) => response);
    },
};

export default projects;
