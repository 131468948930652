/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

/** Icons */
import { ReactComponent as GCAAutomateES } from "../assets/images/gca-automate-logo.svg";
import { ReactComponent as GCAAutomateEN } from "../assets/images/gca-automate-logo-en.svg";
import { ReactComponent as LogoutIcon } from "../assets/images/logout-icon.svg";

/** Images */
import AndreaTorrealba from "../assets/images/our-team-andrea-torrealba.jpg";
import BrianTorres from "../assets/images/our-team-brian-torres.jpg";
import DanielaLeon from "../assets/images/our-team-daniela-leon.jpg";
import DavidLoyo from "../assets/images/our-team-david-loyo.jpg";
import DeyaniraGoncalves from "../assets/images/our-team-deyanira-goncalvez.jpg";
import FeliceCalo from "../assets/images/our-team-felice-calo.jpg";
import GabrielJimenez from "../assets/images/our-team-gabriel-jimenez.jpg";
import GeraldineCalles from "../assets/images/our-team-geraldine-calles.jpg";
import IraixyGuevara from "../assets/images/our-team-iraixy-guevara.jpg";
import JoseAlvarez from "../assets/images/our-team-jose-manuel-alvarez.jpg";
import GiancarloAlbani from "../assets/images/our-team-giancarlo-albani.jpg";
import KeniaGarcia from "../assets/images/our-team-kenia-garcia.jpg";
import PedroHerrera from "../assets/images/our-team-pedro-herrera.jpg";
import RodrigoQuintero from "../assets/images/our-team-rodrigo-quintero.jpg";
import TrinidadAguilar from "../assets/images/our-team-trinidad-aguilar.jpg";
import YaritzaRosas from "../assets/images/our-team-yaritza-rosas.jpg";
import MicaelaMedina from "../assets/images/our-team-micaela-medina.jpg";
import JohannaRojas from "../assets/images/our-team-johanna-rojas.jpg";
import MariaBarrientos from "../assets/images/our-team-maria-barrientos.jpg";
import MariaCruz from "../assets/images/our-team-maria-cruz.jpg";

/** MUI icons */
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import InsertChartIcon from "@mui/icons-material/InsertChart";
// import PersonIcon from "@mui/icons-material/Person";
import MenuIcon from "@mui/icons-material/Menu";
import SettingsIcon from "@mui/icons-material/Settings";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import StorageIcon from "@mui/icons-material/Storage";

/** MUI imports */
import { styled } from "@mui/material/styles";
import {
    AppBar,
    Box,
    Button,
    Collapse,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    IconButton,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Popover,
    Radio,
    RadioGroup,
    Toolbar,
    Typography,
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

/** Components imports */
import Backdrop from "./internos/Backdrop";
import authService from "../services/auth";
import SnackBar from "./internos/SnackBar";
import DialogUserProfile from "./internos/UserProfile/DialogUserProfile";

const usersAvatar = {
    atorrealba: AndreaTorrealba,
    btorres: BrianTorres,
    mcruz: MariaCruz,
    dleon: DanielaLeon,
    dloyo: DavidLoyo,
    dgoncalves: DeyaniraGoncalves,
    fcalo: FeliceCalo,
    gjimenez: GabrielJimenez,
    gcalles: GeraldineCalles,
    iguevara: IraixyGuevara,
    jalvarez: JoseAlvarez,
    galbani: GiancarloAlbani,
    jrojas: JohannaRojas,
    kgarcia: KeniaGarcia,
    mmedina: MicaelaMedina,
    pherrera: PedroHerrera,
    rquintero: RodrigoQuintero,
    taguilar: TrinidadAguilar,
    yrosas: YaritzaRosas,
    mbarrientos: MariaBarrientos
};

const InvoicingAccess = [
    "ac@gca.digital",
    "atorrealba@gca.digital",
    "btorres@gca.digital",
    "galbani@gca.digital",
    "dloyo@gca.digital",
    "dgoncalves@gca.digital",
    "murbaneja@gca.digital",
    "yrosas@gca.digital",
    "gcalles@gca.digital",
    "jalvarez@gca.digital",
    "kgarcia@gca.digital",
    "jrojas@gca.digital",
    "mmedina@gca.digital",
    "pherrera@gca.digital" // Eliminar al publicar en Produccion
];

const PREFIX = "NavBar";

const classes = {
    root: `${PREFIX}-root`,
    tooltip: `${PREFIX}-tooltip`,
    offset: `${PREFIX}-offset`,
    toolbar: `${PREFIX}-toolbar`,
    commonWhite: `${PREFIX}-commonWhite`,
    navbarLogo: `${PREFIX}-navbarLogo`,
    settingsBtn: `${PREFIX}-settingsBtn`,
    selectedItem: `${PREFIX}-selectedItem`,
};

const Root = styled("div")(({ theme }) => ({
    [`& .${classes.offset}`]: theme.mixins.toolbar,

    [`& .${classes.root}`]: {
        flexGrow: 1,
    },

    [`& .${classes.toolbar}`]: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "#031851",
    },

    [`& .${classes.commonWhite}`]: {
        color: "#fff",
    },

    [`& .${classes.navbarLogo}`]: {
        display: "block",
        height: 50,
        [theme.breakpoints.down("sm")]: {
            width: "165px",
        },
    },

    [`& .${classes.settingsBtn}`]: {
        borderBottom: "1px solid #FFEEF0",
        borderBottomStyle: "dashed",
        "&:hover": {
            backgroundColor: "#f8f9f8",
        },
    },

    [`& .${classes.selectedItem}`]: {
        color: theme.palette.secondary.main,
        fontWeight: 600,
    },
}));

/** Global variables ----------------------------------------------------------- */
const drawerWidth = 260;

const openedMixin = (theme) => ({
    display: "flex",
    justifyContent: "space-between",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
});

const closedMixin = (theme) => ({
    display: "flex",
    justifyContent: "space-between",
    width: `calc(${theme.spacing(7)} + 1px)`,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
    }),
}));

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "rgba(19, 31, 62, 0.80)",
        fontSize: 12,
        maxWidth: 320,
        borderRadius: 6,
        fontWeight: "normal",
    },
});

const NavBar = ({ window }) => {
    /** Internationalization i18n */
    const [t, i18n] = useTranslation("global");
    const navigate = useNavigate();

    /* React redux */
    const dispatch = useDispatch();
    const todos = useSelector((state) => state.todos);

    /** Component states */
    const [showBackdrop, setBackdrop] = useState(false);
    const [mobileOpen, setMobileOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const [openReports, setOpenReports] = useState(false);
    const [openRecurrents, setOpenRecurrents] = useState(false);
    const [openAutomation, setOpenAutomation] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [selected, setSelected] = useState(parseInt(sessionStorage.getItem("option")));
    const [show, setShow] = useState(false);
    const [texto, setTexto] = useState("");
    const [alertType, setalertType] = useState("");
    const [profileAnchorEl, setProfileAnchorEl] = useState(null);
    const [analyticsAnchorEl, setAnalyticsAnchorEl] = useState(null);
    const [settingsAnchorEl, setSettingsAnchorEl] = useState(null);
    // const [notifiAnchorEl, setNotifiAnchorEl] = useState(null);
    const [language, setLanguage] = useState(sessionStorage.getItem("lng"));
    const [dateFormat, setDateFotmat] = useState(todos.dateFormat);
    const [amountFormat, setAmountFormat] = useState(todos.amountFormat);
    // const [invisible, setInvisible] = useState(false);
    const [openUserProfile, setOpenUserProfile] = useState(false);

    /** Global variables */
    const openPopoverProfile = Boolean(profileAnchorEl);
    const openPopoverAnalytics = Boolean(analyticsAnchorEl);
    const openPopoverSettings = Boolean(settingsAnchorEl);
    // const openPopoverNotify = Boolean(notifiAnchorEl);
    const id = openPopoverProfile || openPopoverAnalytics || openPopoverSettings ? "simple-popover" : undefined;

    /** Component functions */
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setShow(false);
    };

    // const handleBadgeVisibility = () => {
    //     setInvisible(true);
    // };

    /** Functions */
    const handleDrawerOpen = () => {
        setOpenDrawer(true);
    };

    const handleDrawerClose = () => {
        handleCloseAnalyticsPopover();
        handleCloseProfilePopover();
        setOpenDrawer(false);
        setMobileOpen(false);
        setOpen(false);
        setOpenReports(false);
        setOpenRecurrents(false);
        setOpenAutomation(false);
    };

    const handleDrawerMobile = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleClickProfilePopover = (event) => {
        setProfileAnchorEl(event.currentTarget);
    };

    const handleClickAnalyticsPopover = (event) => {
        setAnalyticsAnchorEl(event.currentTarget);
    };

    // const handleClickNotifiPopover = (event) => {
    //     handleBadgeVisibility();
    //     setNotifiAnchorEl(event.currentTarget);
    // }

    // const seeNotifications = () => {
    //     dispatch({
    //         type: 'addSelected',
    //         item: ''
    //     })
    //     navigate(`/notifications`);
    // }

    const handleClickSettingsPopover = (event) => {
        setSettingsAnchorEl(event.currentTarget);
        setLanguage(sessionStorage.getItem("lng"));
        setDateFotmat(todos.dateFormat);
        setAmountFormat(todos.amountFormat);
    };

    const handleCloseProfilePopover = () => {
        setProfileAnchorEl(null);
        setOpenDrawer(false);
        setMobileOpen(false);
    };

    const handleCloseAnalyticsPopover = () => {
        setAnalyticsAnchorEl(null);
        setOpenDrawer(false);
        setMobileOpen(false);
    };

    const handleCloseSettingsPopover = () => {
        setSettingsAnchorEl(null);
    };

    // const handleCloseNotifiPopover = () => {
    //     setNotifiAnchorEl(null);
    // }

    const handleClickLogout = () => {
        handleDrawerClose();
        setBackdrop(true);

        authService.logoutFunction().then(() => {
            sessionStorage.removeItem("redirect");
            sessionStorage.removeItem("option");
            dispatch({
                type: "clearInfo",
            });
            navigate("/");
        });
    };

    const updateLanguage = (event) => {
        setLanguage(event.target.value);
    };

    const updateDateFormat = (event) => {
        setDateFotmat(event.target.value);
    };

    const updateAmountFormat = (event) => {
        setAmountFormat(event.target.value);
    };

    // const handleOpenUserProfile = () => {
    //     setOpenUserProfile(true);
    // };

    const handleCloseUserProfile = () => {
        setOpenUserProfile(false);
    };

    const saveUserConfig = async () => {
        setBackdrop(true);

        const params = {
            token_set_id: todos.userInfo._id,
            language,
            date_format: dateFormat,
            amount_format: amountFormat,
            tutorial: todos.isTutorial,
        };

        await fetch("/saveUserConfig", {
            method: "POST",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
            body: JSON.stringify(params),
        })
            .then((res) => {
                sessionStorage.setItem("lng", language);
                i18n.changeLanguage(language);
                document.title = t("principal.gcaPlatform");

                dispatch({
                    type: "addDateFormat",
                    item: dateFormat,
                });
                dispatch({
                    type: "addAmountFormat",
                    item: amountFormat,
                });

                handleCloseSettingsPopover();
                setTexto(t("principal.userSettingsSaved"));
                setalertType("success");
                setShow(true);
            })
            .catch((error) => {
                console.log(error);
            });

        setBackdrop(false);
    };

    const selectMenuOption = (index) => {
        switch (index) {
            case 1:
                dispatch({
                    type: "addSelected",
                    item: "ddlPrincipal",
                });
                navigate(`/dashboard/general`);
                break;
            case 2:
                dispatch({
                    type: "addSelected",
                    item: "ddlAnalytics",
                });
                navigate(`/analytics/filters`);
                break;
            case 3:
                dispatch({
                    type: "addSelected",
                    item: "ddlAnalytics",
                });
                navigate(`/analytics/reports`);
                break;
            case 4:
                dispatch({
                    type: "addSelected",
                    item: "ddlAutomation",
                });
                navigate(`/importFiles`);
                break;
            case 5:
                dispatch({
                    type: "addSelected",
                    item: "ddlManage",
                });
                navigate(`/clientManagement`);
                break;
            case 6:
                dispatch({
                    type: "addSelected",
                    item: "ddlReports",
                });
                navigate(`/reports/updates`);
                break;
            case 7:
                dispatch({
                    type: "addSelected",
                    item: "ddlReports",
                });
                navigate(`/reports/accountant`);
                break;
            case 8:
                dispatch({
                    type: "addSelected",
                    item: "ddlReports",
                });
                navigate(`/reports/bookkeeper`);
                break;
            case 9:
                dispatch({
                    type: "addSelected",
                    item: "ddlRequirements",
                });
                navigate(`/requirements`);
                break;
            case 10:
                dispatch({
                    type: "addSelected",
                    item: "ddlAutomation",
                });
                navigate(`/automation`);
                break;
            case 11:
                dispatch({
                    type: "addSelected",
                    item: "ddlRequirements",
                });
                navigate(`/clientRequirements`);
                break;
            case 12:
                dispatch({
                    type: "addSelected",
                    item: "ddlReports",
                });
                navigate(`/clientGlobalReport`);
                break;
            case 13:
                dispatch({
                    type: "addSelected",
                    item: "ddlPrincipal",
                });
                navigate(`/dashboard/banks`);
                break;
            case 14:
                dispatch({
                    type: "addSelected",
                    item: "ddlInvoicing",
                });
                navigate(`/invoicing`);
                break;
            case 15:
                dispatch({
                    type: "addSelected",
                    item: "ddlInvoicing",
                });
                navigate(`/clientInvoicing`);
                break;
            case 16:
                dispatch({
                    type: "addSelected",
                    item: "ddlProposals",
                });
                navigate(`/proposals`);
                break;
            default:
                break;
        }
    };

    const handleListItemClick = (index) => {
        handleDrawerClose();
        selectMenuOption(index);
        setSelected(index);
        sessionStorage.setItem("option", index);
    };

    const handleClickCollapse = () => {
        setOpen(!open);
    };

    const handleClickCollapseInvoicing = () => {
        setOpenReports(!openReports);
    };

    const handleClickCollapseRecurrents = () => {
        setOpenRecurrents(!openRecurrents);
    };

    const handleClickCollapseAutomation = () => {
        setOpenAutomation(!openAutomation);
    };

    const listDrawer = (
        <>
            <List disablePadding sx={{ pt: "50px" }}>
                {todos.auth || todos.authEx ? (
                    <ListItemButton
                        key={"Principal"}
                        sx={{
                            p: "16px 8px 16px 16px",
                            alignItems: "flex-start",
                            backgroundColor: "inherit",
                        }}
                        className={
                            todos.selected === "ddlPrincipal" ? "navBarOptionSelected submenuKiiper" : "submenuKiiper"
                        }
                        onClick={handleClickCollapse}
                    >
                        <ListItemIcon sx={{ my: "auto", minWidth: 40 }}>
                            <DashboardIcon
                                strokeWidth={1.5}
                                size="1.3rem"
                                style={{ marginTop: "auto", marginBottom: "auto" }}
                            />
                        </ListItemIcon>
                        <ListItemText primary={t("principal.dashboard")} />
                        {open ? (
                            <ExpandLessIcon
                                stroke={1.5}
                                size="1rem"
                                style={{ marginTop: "auto", marginBottom: "auto" }}
                            />
                        ) : (
                            <ExpandMoreIcon
                                stroke={1.5}
                                size="1rem"
                                style={{ marginTop: "auto", marginBottom: "auto" }}
                            />
                        )}
                    </ListItemButton>
                ) : null}
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {todos.auth ? (
                            <ListItemButton
                                sx={{
                                    mb: 0.5,
                                    alignItems: "flex-start",
                                    backgroundColor: "transparent !important",
                                    py: 1,
                                    pl: 9,
                                }}
                                onClick={() => handleListItemClick(1)}
                            >
                                <ListItemIcon sx={{ my: "auto", minWidth: 18 }}>
                                    <FiberManualRecordIcon
                                        sx={{
                                            width: 6,
                                            height: 6,
                                        }}
                                        fontSize={"inherit"}
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    primary={
                                        <Typography
                                            variant={"body1"}
                                            color="inherit"
                                            sx={{ my: "auto" }}
                                            className={
                                                todos.selected === "ddlPrincipal" && selected === 1
                                                    ? classes.selectedItem
                                                    : null
                                            }
                                        >
                                            {t("principal.general")}
                                        </Typography>
                                    }
                                />
                            </ListItemButton>
                        ) : null}
                        {todos.auth || todos.authEx ? (
                            <ListItemButton
                                sx={{
                                    mb: 0.5,
                                    alignItems: "flex-start",
                                    backgroundColor: "transparent !important",
                                    py: 1,
                                    pl: 9,
                                }}
                                onClick={() => handleListItemClick(13)}
                            >
                                <ListItemIcon sx={{ my: "auto", minWidth: 18 }}>
                                    <FiberManualRecordIcon
                                        sx={{
                                            width: 6,
                                            height: 6,
                                        }}
                                        fontSize={"inherit"}
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    primary={
                                        <Typography
                                            variant={"body1"}
                                            color="inherit"
                                            sx={{ my: "auto" }}
                                            className={
                                                todos.selected === "ddlPrincipal" && selected === 13
                                                    ? classes.selectedItem
                                                    : null
                                            }
                                        >
                                            {t("miscellaneous.banks")}
                                        </Typography>
                                    }
                                />
                            </ListItemButton>
                        ) : null}
                    </List>
                </Collapse>
                {todos.auth ? (
                    <ListItemButton
                        key={"Proposals"}
                        sx={{ p: "16px" }}
                        className={
                            todos.selected === "ddlProposals" ? "navBarOptionSelected submenuKiiper" : "submenuKiiper"
                        }
                        onClick={() => handleListItemClick(16)}
                    >
                        <ListItemIcon sx={{ my: "auto", minWidth: 40 }}>
                            <FactCheckIcon />
                        </ListItemIcon>
                        <ListItemText primary={t("proposals.proposals")} />
                    </ListItemButton>
                ) : null}
                {todos.auth ? (
                    <ListItemButton
                        key={"Management"}
                        sx={{ p: "16px" }}
                        className={
                            todos.selected === "ddlManage" ? "navBarOptionSelected submenuKiiper" : "submenuKiiper"
                        }
                        onClick={() => handleListItemClick(5)}
                    >
                        <ListItemIcon sx={{ my: "auto", minWidth: 40 }}>
                            <SupervisorAccountIcon />
                        </ListItemIcon>
                        <ListItemText primary={t("principal.admCustom")} />
                    </ListItemButton>
                ) : null}
                {todos.auth ? (
                    <ListItemButton
                        key={"Requirements"}
                        sx={{ p: "16px" }}
                        className={
                            todos.selected === "ddlRequirements"
                                ? "navBarOptionSelected submenuKiiper"
                                : "submenuKiiper"
                        }
                        onClick={() => handleListItemClick(9)}
                    >
                        <ListItemIcon sx={{ my: "auto", minWidth: 40 }}>
                            <AssignmentTurnedInIcon />
                        </ListItemIcon>
                        <ListItemText primary={t("principal.requirements")} />
                    </ListItemButton>
                ) : null}
                {todos.authReq ? (
                    <ListItemButton
                        key={"Requirements"}
                        sx={{ p: "16px" }}
                        className={
                            todos.selected === "ddlRequirements"
                                ? "navBarOptionSelected submenuKiiper"
                                : "submenuKiiper"
                        }
                        onClick={() => handleListItemClick(11)}
                    >
                        <ListItemIcon sx={{ my: "auto", minWidth: 40 }}>
                            <AssignmentTurnedInIcon />
                        </ListItemIcon>
                        <ListItemText primary={t("principal.requirements")} />
                    </ListItemButton>
                ) : null}
                {todos.authStatus ? (
                    <ListItemButton
                        key={"ClientGlobalReport"}
                        sx={{ p: "16px" }}
                        className={
                            todos.selected === "ddlReports" ? "navBarOptionSelected submenuKiiper" : "submenuKiiper"
                        }
                        onClick={() => handleListItemClick(12)}
                    >
                        <ListItemIcon sx={{ my: "auto", minWidth: 40 }}>
                            <InsertChartIcon />
                        </ListItemIcon>
                        <ListItemText primary={t("miscellaneous.status")} />
                    </ListItemButton>
                ) : null}
                {todos.auth ? (
                    <ListItemButton
                        key={"Reports"}
                        sx={{
                            p: "16px 8px 16px 16px",
                            alignItems: "flex-start",
                            backgroundColor: "inherit",
                        }}
                        className={
                            todos.selected === "ddlReports" ? "navBarOptionSelected submenuKiiper" : "submenuKiiper"
                        }
                        onClick={handleClickCollapseInvoicing}
                    >
                        <ListItemIcon sx={{ my: "auto", minWidth: 40 }}>
                            <InsertChartIcon
                                strokeWidth={1.5}
                                size="1.3rem"
                                style={{ marginTop: "auto", marginBottom: "auto" }}
                            />
                        </ListItemIcon>
                        <ListItemText primary={t("principal.closingReport")} />
                        {openReports ? (
                            <ExpandLessIcon
                                stroke={1.5}
                                size="1rem"
                                style={{ marginTop: "auto", marginBottom: "auto" }}
                            />
                        ) : (
                            <ExpandMoreIcon
                                stroke={1.5}
                                size="1rem"
                                style={{ marginTop: "auto", marginBottom: "auto" }}
                            />
                        )}
                    </ListItemButton>
                ) : null}
                <Collapse in={openReports} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItemButton
                            key={"Updates"}
                            sx={{
                                p: "8px 16px 8px 72px",
                                alignItems: "flex-start",
                                backgroundColor: "inherit",
                            }}
                            onClick={() => handleListItemClick(6)}
                        >
                            <ListItemText
                                primary={
                                    <Typography
                                        variant={"h2"}
                                        color="inherit"
                                        className={
                                            todos.selected === "ddlReports" && selected === 6
                                                ? classes.selectedItem
                                                : null
                                        }
                                    >
                                        {t("principal.updates")}
                                    </Typography>
                                }
                            />
                        </ListItemButton>
                        <ListItemButton
                            key={"Recurrents"}
                            sx={{
                                p: "8px 8px 8px 72px",
                                alignItems: "flex-start",
                                backgroundColor: "inherit",
                            }}
                            onClick={handleClickCollapseRecurrents}
                        >
                            <ListItemText
                                primary={
                                    <Typography
                                        variant={"h2"}
                                        color="inherit"
                                        className={
                                            (todos.selected === "ddlReports" && selected === 7) ||
                                            (todos.selected === "ddlReports" && selected === 8)
                                                ? classes.selectedItem
                                                : null
                                        }
                                    >
                                        {t("principal.recurring")}
                                    </Typography>
                                }
                            />
                            {openRecurrents ? (
                                <ExpandLessIcon
                                    stroke={1.5}
                                    size="1rem"
                                    style={{ marginTop: "auto", marginBottom: "auto" }}
                                />
                            ) : (
                                <ExpandMoreIcon
                                    stroke={1.5}
                                    size="1rem"
                                    style={{ marginTop: "auto", marginBottom: "auto" }}
                                />
                            )}
                        </ListItemButton>
                        <Collapse in={openRecurrents} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItemButton
                                    sx={{
                                        mb: 0.5,
                                        py: 1,
                                        pl: 11,
                                        alignItems: "flex-start",
                                        backgroundColor: "transparent !important",
                                    }}
                                    onClick={() => {
                                        handleListItemClick(7);
                                    }}
                                >
                                    <ListItemIcon sx={{ my: "auto", minWidth: 18 }}>
                                        <FiberManualRecordIcon
                                            sx={{
                                                width: 6,
                                                height: 6,
                                            }}
                                            fontSize={"inherit"}
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <Typography
                                                variant={"body1"}
                                                color="inherit"
                                                sx={{ my: "auto" }}
                                                className={
                                                    todos.selected === "ddlReports" && selected === 7
                                                        ? classes.selectedItem
                                                        : null
                                                }
                                            >
                                                {t("principal.accountant")}
                                            </Typography>
                                        }
                                    />
                                </ListItemButton>
                                <ListItemButton
                                    sx={{
                                        mb: 0.5,
                                        py: 1,
                                        pl: 11,
                                        alignItems: "flex-start",
                                        backgroundColor: "transparent !important",
                                    }}
                                    onClick={() => {
                                        handleListItemClick(8);
                                    }}
                                >
                                    <ListItemIcon sx={{ my: "auto", minWidth: 18 }}>
                                        <FiberManualRecordIcon
                                            sx={{
                                                width: 6,
                                                height: 6,
                                            }}
                                            fontSize={"inherit"}
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <Typography
                                                variant={"body1"}
                                                color="inherit"
                                                sx={{ my: "auto" }}
                                                className={
                                                    todos.selected === "ddlReports" && selected === 8
                                                        ? classes.selectedItem
                                                        : null
                                                }
                                            >
                                                {t("principal.bookkeeper")}
                                            </Typography>
                                        }
                                    />
                                </ListItemButton>
                            </List>
                        </Collapse>
                    </List>
                </Collapse>
                {todos.auth ? (
                    <>
                        <ListItemButton
                            key={"Automation"}
                            sx={{
                                p: "16px 8px 16px 16px",
                                alignItems: "flex-start",
                                backgroundColor: "inherit",
                            }}
                            onClick={handleClickCollapseAutomation}
                            className={
                                todos.selected === "ddlAutomation"
                                    ? "navBarOptionSelected submenuKiiper"
                                    : "submenuKiiper"
                            }
                        >
                            <ListItemIcon sx={{ my: "auto", minWidth: 40 }}>
                                <MiscellaneousServicesIcon />
                            </ListItemIcon>
                            <ListItemText primary={t("principal.automation")} />
                            {openAutomation ? (
                                <ExpandLessIcon
                                    stroke={1.5}
                                    size="1rem"
                                    style={{ marginTop: "auto", marginBottom: "auto" }}
                                />
                            ) : (
                                <ExpandMoreIcon
                                    stroke={1.5}
                                    size="1rem"
                                    style={{ marginTop: "auto", marginBottom: "auto" }}
                                />
                            )}
                        </ListItemButton>
                        <Collapse in={openAutomation} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItemButton
                                    key={"FuncReports"}
                                    sx={{
                                        p: "8px 16px 8px 72px",
                                        alignItems: "flex-start",
                                        backgroundColor: "inherit",
                                    }}
                                    onClick={() => handleListItemClick(10)}
                                >
                                    <ListItemText
                                        primary={
                                            <Typography
                                                variant={"h2"}
                                                color="inherit"
                                                className={
                                                    todos.selected === "ddlAutomation" && selected === 10
                                                        ? classes.selectedItem
                                                        : null
                                                }
                                            >
                                                {t("reports.functionalReports")}
                                            </Typography>
                                        }
                                    />
                                </ListItemButton>
                                {/* <ListItemButton
                                    key={'importFiles'}
                                    sx={{
                                        p: '8px 16px 8px 72px',
                                        alignItems: 'flex-start',
                                        backgroundColor: 'inherit',
                                    }}
                                    onClick={() => handleListItemClick(4)}
                                >
                                    <ListItemText primary={
                                        <Typography variant={'h2'} color="inherit"
                                            className={todos.selected === "ddlAutomation" && selected === 4 ? classes.selectedItem : null}
                                        >
                                            {t("reports.import")}
                                        </Typography>} />
                                </ListItemButton> */}
                            </List>
                        </Collapse>
                    </>
                ) : null}
                {todos.auth && InvoicingAccess.includes(todos.userInfo.email) && (
                    <ListItemButton
                        key={"Invoicing"}
                        sx={{ p: "16px" }}
                        className={
                            todos.selected === "ddlInvoicing" ? "navBarOptionSelected submenuKiiper" : "submenuKiiper"
                        }
                        onClick={() => handleListItemClick(14)}
                    >
                        <ListItemIcon sx={{ my: "auto", minWidth: 40 }}>
                            <CreditCardIcon />
                        </ListItemIcon>
                        <ListItemText primary={t("miscellaneous.invoicing")} />
                    </ListItemButton>
                )}
                {todos.authInvoice && (
                    <ListItemButton
                        key={"Invoicing"}
                        sx={{ p: "16px" }}
                        className={
                            todos.selected === "ddlInvoicing" ? "navBarOptionSelected submenuKiiper" : "submenuKiiper"
                        }
                        onClick={() => handleListItemClick(15)}
                    >
                        <ListItemIcon sx={{ my: "auto", minWidth: 40 }}>
                            <CreditCardIcon />
                        </ListItemIcon>
                        <ListItemText primary={t("miscellaneous.invoicing")} />
                    </ListItemButton>
                )}
            </List>
            <List disablePadding>
                {todos.auth ? (
                    <ListItemButton
                        key={"Analytics"}
                        sx={{
                            p: "16px 8px 16px 16px",
                            alignItems: "flex-start",
                            backgroundColor: "inherit",
                        }}
                        className={
                            todos.selected === "ddlAnalytics" ? "navBarOptionSelected submenuKiiper" : "submenuKiiper"
                        }
                        onClick={handleClickAnalyticsPopover}
                    >
                        <ListItemIcon sx={{ my: "auto" }}>
                            <StorageIcon
                                strokeWidth={1.5}
                                size="1.3rem"
                                style={{ marginTop: "auto", marginBottom: "auto" }}
                            />
                        </ListItemIcon>
                        <ListItemText primary={t("principal.analytics")} />
                    </ListItemButton>
                ) : null}
                <Popover
                    id={id}
                    open={openPopoverAnalytics}
                    anchorEl={analyticsAnchorEl}
                    onClose={handleCloseAnalyticsPopover}
                    anchorOrigin={{
                        vertical: "center",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "center",
                        horizontal: "left",
                    }}
                    PaperProps={{
                        sx: {
                            ml: 1,
                            p: "12px 0px",
                            borderRadius: 5,
                            boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                            backgroundColor: "#F8F9F8",
                        },
                    }}
                >
                    <List component="div" disablePadding>
                        <ListItemButton
                            sx={{
                                alignItems: "flex-start",
                                pl: 4,
                                pr: 4,
                            }}
                            onClick={() => handleListItemClick(2)}
                        >
                            <ListItemIcon sx={{ my: "auto", minWidth: 18 }}>
                                <FiberManualRecordIcon
                                    sx={{
                                        width: 6,
                                        height: 6,
                                    }}
                                    fontSize={"inherit"}
                                />
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    <Typography
                                        variant={"body1"}
                                        sx={{
                                            my: "auto",
                                            color: "#031851",
                                            ...(todos.selected === "ddlAnalytics" &&
                                                selected === 2 && {
                                                    color: "#FF5968",
                                                    fontWeight: 600,
                                                }),
                                        }}
                                    >
                                        {t("miscellaneous.filters")}
                                    </Typography>
                                }
                            />
                        </ListItemButton>
                        <ListItemButton
                            sx={{
                                alignItems: "flex-start",
                                pl: 4,
                                pr: 4,
                            }}
                            onClick={() => handleListItemClick(3)}
                        >
                            <ListItemIcon sx={{ my: "auto", minWidth: 18 }}>
                                <FiberManualRecordIcon
                                    sx={{
                                        width: 6,
                                        height: 6,
                                    }}
                                    fontSize={"inherit"}
                                />
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    <Typography
                                        variant={"body1"}
                                        sx={{
                                            my: "auto",
                                            color: "#031851",
                                            ...(todos.selected === "ddlAnalytics" &&
                                                selected === 3 && {
                                                    color: "#FF5968",
                                                    fontWeight: 600,
                                                }),
                                        }}
                                    >
                                        {t("principal.reports")}
                                    </Typography>
                                }
                            />
                        </ListItemButton>
                    </List>
                </Popover>
                <Divider />
                <ListItemButton
                    key={"Profile"}
                    aria-describedby={id}
                    sx={{ p: "16px 16px 16px 0px" }}
                    onClick={handleClickProfilePopover}
                >
                    <ListItemIcon>
                        {usersAvatar[todos.userInfo.email.split("@")[0]] ? (
                            <img
                                src={usersAvatar[todos.userInfo.email.split("@")[0]]}
                                alt="UserAvatar"
                                style={{ marginLeft: 8, borderRadius: 50, height: 40, width: 40 }}
                            />
                        ) : (
                            <AccountCircleIcon strokeWidth={1.5} fontSize="large" sx={{ margin: "auto" }} />
                        )}
                    </ListItemIcon>
                    <ListItemText
                        primary={
                            <Typography variant={"h2"} color="primary" sx={{ pl: 2 }}>
                                {" "}
                                {t("principal.profile")}{" "}
                            </Typography>
                        }
                    />
                </ListItemButton>
                <Popover
                    id={id}
                    open={openPopoverProfile}
                    anchorEl={profileAnchorEl}
                    onClose={handleCloseProfilePopover}
                    anchorOrigin={{
                        vertical: "center",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "center",
                        horizontal: "left",
                    }}
                    PaperProps={{
                        sx: {
                            ml: 1,
                            p: 2,
                            borderRadius: 5,
                            boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                            backgroundColor: "#F8F9F8",
                        },
                    }}
                >
                    <Grid container alignItems="center">
                        <Grid item xs={12} md={4}>
                            {usersAvatar[todos.userInfo.email.split("@")[0]] ? (
                                <img
                                    src={usersAvatar[todos.userInfo.email.split("@")[0]]}
                                    alt="UserAvatar"
                                    style={{
                                        display: "block",
                                        margin: "auto",
                                        borderRadius: 50,
                                        height: 90,
                                        width: 90,
                                    }}
                                />
                            ) : (
                                <AccountCircleIcon sx={{ display: "block", height: 90, width: 90, margin: "auto" }} />
                            )}
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Typography
                                variant="h4"
                                align="center"
                                color="textPrimary"
                                sx={{ m: "10px 0px", textTransform: "uppercase" }}
                            >
                                {todos.userInfo.user_name}
                            </Typography>
                            <Typography variant="body1" align="center" color="textPrimary" sx={{ m: "10px 0px" }}>
                                {todos.userInfo.position}
                            </Typography>
                            <Typography variant="body1" align="center" color="textPrimary">
                                {todos.userInfo.email}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider sx={{ mt: 2, mb: 1, borderColor: "#FF5968" }} />
                    {/* <Button
                        fullWidth
                        className={classes.settingsBtn}
                        onClick={() => {
                            handleOpenUserProfile();
                        }}
                    >
                        <PersonIcon className="icon-text-size" />
                        <Typography variant="button" align="center" color="primary">
                            {t("principal.profile")}
                        </Typography>
                    </Button> */}
                    <Button fullWidth className={classes.settingsBtn} onClick={() => handleClickLogout()}>
                        <LogoutIcon className="icon-text-size" />
                        <Typography variant="button" align="center" color="primary">
                            {t("principal.logout")}
                        </Typography>
                    </Button>
                </Popover>
            </List>
        </>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    const checkSession = async () => {
        await authService
            .checkSession()
            .then((isActive) => {
                if (!isActive) {
                    sessionStorage.removeItem("redirect");
                    sessionStorage.removeItem("option");
                    dispatch({
                        type: "clearInfo",
                    });
                    navigate("/");
                }
            })
            .catch((error) => {
                console.log(error.response);
            });
    };

    useEffect(() => {
        const intervalId = setInterval(checkSession, 60000);

        return () => clearInterval(intervalId);
    });

    return (
        <Root className={classes.root}>
            <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Toolbar className={classes.toolbar}>
                    <div style={{ display: "flex" }}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerMobile}
                            sx={{ mr: 2, display: { md: "none" } }}
                        >
                            <MenuIcon sx={{ color: "#fff" }} />
                        </IconButton>
                        {sessionStorage.getItem("lng") === "en" ? (
                            <GCAAutomateEN className={classes.navbarLogo} />
                        ) : (
                            <GCAAutomateES className={classes.navbarLogo} />
                        )}
                    </div>
                    <div>
                        <LightTooltip title={t("miscellaneous.settings")} aria-label="settings">
                            <IconButton
                                aria-controls="settings-menu"
                                aria-haspopup="true"
                                onClick={handleClickSettingsPopover}
                                sx={{ p: "6px 20px" }}
                            >
                                <SettingsIcon sx={{ color: "#fff" }} />
                            </IconButton>
                        </LightTooltip>
                        <Popover
                            id={id}
                            open={openPopoverSettings}
                            anchorEl={settingsAnchorEl}
                            onClose={handleCloseSettingsPopover}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "center",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "center",
                            }}
                            PaperProps={{
                                sx: {
                                    p: "16px 24px",
                                    borderRadius: 5,
                                    boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                    backgroundColor: "#F8F9F8",
                                },
                            }}
                        >
                            <FormControl sx={{ display: "block" }}>
                                <FormLabel id="language-radio-buttons-group-label">
                                    <Typography variant="h4" color="primary">
                                        Idioma / Language
                                    </Typography>
                                </FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="language-radio-buttons-group-label"
                                    name="language-radio-buttons-group"
                                    value={language}
                                    onChange={updateLanguage}
                                >
                                    <FormControlLabel value="es" control={<Radio size="small" />} label="Español" />
                                    <FormControlLabel value="en" control={<Radio size="small" />} label="English" />
                                </RadioGroup>
                            </FormControl>
                            <FormControl sx={{ display: "block", pt: 1 }}>
                                <FormLabel id="date-format-radio-buttons-group-label">
                                    <Typography variant="h4" color="primary">
                                        Fecha / Date
                                    </Typography>
                                </FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="date-format-radio-buttons-group-label"
                                    name="date-format-radio-buttons-group"
                                    value={dateFormat}
                                    onChange={updateDateFormat}
                                >
                                    <FormControlLabel
                                        value="DD/MM/YYYY"
                                        control={<Radio size="small" />}
                                        label="dd/MM/yyyy"
                                    />
                                    <FormControlLabel
                                        value="MM/DD/YYYY"
                                        control={<Radio size="small" />}
                                        label="MM/dd/yyyy"
                                    />
                                </RadioGroup>
                            </FormControl>
                            <FormControl sx={{ display: "block", pt: 1, pb: 1 }}>
                                <FormLabel id="amount-format-radio-buttons-group-label">
                                    <Typography variant="h4" color="primary">
                                        Montos / Amounts
                                    </Typography>
                                </FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="amount-format-radio-buttons-group-label"
                                    name="amount-format-radio-buttons-group"
                                    value={amountFormat}
                                    onChange={updateAmountFormat}
                                >
                                    <FormControlLabel value="de-DE" control={<Radio size="small" />} label="9.999,99" />
                                    <FormControlLabel value="en-US" control={<Radio size="small" />} label="9,999.99" />
                                </RadioGroup>
                            </FormControl>
                            <Button
                                disableElevation
                                variant="contained"
                                color="secondary"
                                onClick={saveUserConfig}
                                sx={{
                                    float: "right",
                                    borderRadius: 40,
                                    fontSize: 14,
                                    "&:hover": {
                                        backgroundColor: "#2f3190",
                                        color: "#fff",
                                    },
                                }}
                            >
                                {t("miscellaneous.save")}
                            </Button>
                        </Popover>
                        {/* <LightTooltip title={t("miscellaneous.notifi")} aria-label="notifi">
                            <IconButton aria-controls="notifi-menu" aria-haspopup="true" onClick={handleClickNotifiPopover}
                                sx={{ p: '6px 20px' }}
                            >
                                <Badge variant="dot" color="secondary" invisible={invisible}>
                                    <NotificationsIcon sx={{ color: '#fff' }} />
                                </Badge>
                            </IconButton>
                        </LightTooltip>
                        <Popover
                            id={id}
                            open={openPopoverNotify}
                            anchorEl={notifiAnchorEl}
                            onClose={handleCloseNotifiPopover}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            PaperProps={{
                                sx: {
                                    borderRadius: 5,
                                    boxShadow: '10px 10px 15px 0px rgba(3,24,81,0.15)',
                                }
                            }}
                        >
                            <Card sx={{ maxWidth: 530 }}>
                                <CardContent sx={{ maxHeight: 500, overflow: 'auto' }}>
                                    {false ?
                                        <Typography variant="body1" sx={{ fontWeight: 700, p: 4, textAlign: 'center' }}>
                                            No hay notificaciones nuevas
                                        </Typography>
                                        :
                                        <List>
                                            <ListItem alignItems="center" key={0}>
                                                <ListItemIcon size="small" sx={{ minWidth: 30 }}>
                                                    <CircleIcon fontSize="inherit" sx={{ color: '#ff5968' }} />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={
                                                        <Typography
                                                            sx={{ fontWeight: 600 }}
                                                            component="span"
                                                            variant="body2"
                                                            color="text.secondary"
                                                        >
                                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit
                                                        </Typography>
                                                    }
                                                />
                                                <Box sx={{ float: "right", pl: 2 }}>
                                                    <Typography
                                                        sx={{ fontWeight: 400, color: '#A0A0A0' }}
                                                        component="span"
                                                        variant="body2"
                                                    >
                                                        5m
                                                    </Typography>
                                                </Box>
                                            </ListItem>
                                            <Divider variant="middle" component="li" sx={{ marginLeft: '40px' }} />
                                            <ListItem alignItems="center" key={1}>
                                                <ListItemIcon size="small" sx={{ minWidth: 30 }}>
                                                    <CircleIcon fontSize="inherit" sx={{ color: '#ff5968' }} />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={
                                                        <Typography
                                                            sx={{ fontWeight: 600 }}
                                                            component="span"
                                                            variant="body2"
                                                            color="text.secondary"
                                                        >
                                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit
                                                        </Typography>
                                                    }
                                                />
                                                <Box sx={{ float: "right", pl: 2 }}>
                                                    <Typography
                                                        sx={{ fontWeight: 400, color: '#A0A0A0' }}
                                                        component="span"
                                                        variant="body2"
                                                    >
                                                        12m
                                                    </Typography>
                                                </Box>
                                            </ListItem>
                                            <Divider variant="middle" component="li" sx={{ marginLeft: '40px' }} />
                                            <ListItem alignItems="center" key={2}>
                                                <ListItemIcon size="small" sx={{ minWidth: 30 }}>
                                                    <CircleIcon fontSize="inherit" sx={{ color: '#ff5968' }} />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={
                                                        <Typography
                                                            sx={{ fontWeight: 600 }}
                                                            component="span"
                                                            variant="body2"
                                                            color="text.secondary"
                                                        >
                                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit
                                                        </Typography>
                                                    }
                                                />
                                                <Box sx={{ float: "right", pl: 2 }}>
                                                    <Typography
                                                        sx={{ fontWeight: 400, color: '#A0A0A0' }}
                                                        component="span"
                                                        variant="body2"
                                                    >
                                                        2h
                                                    </Typography>
                                                </Box>
                                            </ListItem>
                                        </List>
                                    }
                                </CardContent>
                                <CardActions sx={{ p: 2, backgroundColor: '#031851' }}>
                                    <Button sx={{ margin: '0 auto', color: 'white' }} onClick={seeNotifications}>Ver todas las notificaciones</Button>
                                </CardActions>
                            </Card>
                        </Popover> */}
                    </div>
                </Toolbar>
            </AppBar>
            <Box
                component="nav"
                sx={{
                    flexShrink: { sm: 0 },
                }}
                aria-label="mailbox folders"
            >
                <MuiDrawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerMobile}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    sx={{
                        display: { xs: "block", md: "none" },
                        "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
                    }}
                >
                    {listDrawer}
                </MuiDrawer>
                <Drawer
                    variant="permanent"
                    open={openDrawer}
                    PaperProps={{ onMouseEnter: handleDrawerOpen, onMouseLeave: handleDrawerClose }}
                    sx={{
                        display: { xs: "none", md: "block" },
                    }}
                >
                    {listDrawer}
                </Drawer>
            </Box>
            <Backdrop open={showBackdrop} />
            <DialogUserProfile
                open={openUserProfile}
                onClose={handleCloseUserProfile}
                setBackDrop={setBackdrop}
                setTexto={setTexto}
                setShow={setShow}
                setAlertType={setalertType}
                usersAvatar={usersAvatar}
            />
            <SnackBar type={alertType} show={show} message={texto} onClose={handleClose} />
        </Root>
    );
};

export default NavBar;
