// React
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";
import PropTypes from "prop-types";
import moment from "moment";

/** MUI icons */
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import EditOffIcon from "@mui/icons-material/EditOff";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

// MUI
import {
    Alert,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Switch,
    Tab,
    Tabs,
    TextField,
    Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

/** Components imports */
import { Additionals } from "./";
import NewPeriodDialog from "../../../Dialog";
import DialogAlerts from "../../../DialogAlerts";

/** Component styles */
const PREFIX = "Recurring";

const classes = {
    btnRoot: `${PREFIX}-btnRoot`,
    switch: `${PREFIX}-switch`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.btnRoot}`]: {
        border: "1px solid #ff5968",
        borderRadius: 40,
        "&:hover": {
            backgroundColor: "#2f3190",
            border: "1px solid #2f3190",
        },
    },
    [`& .${classes.switch}`]: {
        "& .MuiSwitch-switchBase .MuiSwitch-thumb": {
            color: "#FFF",
        },
        "& .MuiSwitch-switchBase + .MuiSwitch-track": {
            backgroundColor: "#031851",
        },
        "& .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb": {
            color: "#FF5968",
        },
        "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
            backgroundColor: "#FFA8B1",
        },
    },
}));

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "rgba(19, 31, 62, 0.80)",
        fontSize: 12,
        maxWidth: 320,
        borderRadius: 6,
        fontWeight: "normal",
    },
});

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
        </div>
    );
};

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
};

const ColorButton = styled(Button)(({ theme }) => ({
    border: "1px solid #ff5968",
    borderRadius: 40,
}));

const CustomTab = styled(Tab)(({ theme }) => ({
    padding: 10,
    marginLeft: 8,
    borderRadius: "20px 20px 0 0",
    backgroundColor: "#E9EAE8",
    color: theme.palette.primary.main,
    fontWeight: 700,
    "&.Mui-selected": {
        backgroundColor: "#2f3190 !important",
        color: "#fff",
    },
}));

const latam = [
    "Argentina",
    "Bolivia",
    "Brasil",
    "Chile",
    "Colombia",
    "Costa Rica",
    "Cuba",
    "Ecuador",
    "El Salvador",
    "Guatemala",
    "Haití",
    "Honduras",
    "México",
    "Nicaragua",
    "Panamá",
    "Paraguay",
    "Perú",
    "República Dominicana",
    "Uruguay",
];

const NumberFormatCustom = (props) => {
    const todos = useSelector((state) => state.todos);

    const { id, inputRef, value, onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            value={value}
            getInputRef={inputRef}
            onValueChange={({ value: v }) => onChange({ target: { value: v } })}
            thousandSeparator={todos.amountFormat === "de-DE" ? "." : ","}
            decimalSeparator={todos.amountFormat === "de-DE" ? "," : "."}
            fixedDecimalScale={true}
            decimalScale={id !== "outlined-adornment-expenses" ? 2 : 0}
            valueIsNumericString
        />
    );
};

const defaultState = {
    endProject: false,
    originalExpenses: 0,
    expenses: 0,
    originalFee: 0,
    projectFee: 0,
    projectDiscount: 0,
    projectTotal: 0,
    additionalsDiscount: 0,
    // Service Responsibility
    respIniDate: null,
    initDate: null,
    endDate: null,
    repDays: 1,
    // Additionals
    additionalsOnly: false,
    checkedAcc: false,
    tothAcc: 0,
    startDateAcc: null,
    finishDateAcc: null,
    checkedAR: false,
    tothAR: 0,
    startDateAR: null,
    finishDateAR: null,
    checkedAP: false,
    tothAP: 0,
    startDateAP: null,
    finishDateAP: null,
    checkedClass: false,
    tothClass: 0,
    startDateClass: null,
    finishDateClass: null,
    checkedMulti: false,
    tothMulti: 0,
    startDateMulti: null,
    finishDateMulti: null,
    checkedPer: false,
    tothPer: 0,
    startDatePer: null,
    finishDatePer: null,
    // USA
    checkedChecks: false,
    tothChecks: 0,
    startDateChecks: null,
    finishDateChecks: null,
    checkedSales: false,
    tothSales: 0,
    startDateSales: null,
    finishDateSales: null,
    // Venezuela
    checkedOrd: false,
    tothOrd: 0,
    startDateOrd: null,
    finishDateOrd: null,
    checkedEsp: false,
    tothEsp: 0,
    startDateEsp: null,
    finishDateEsp: null,
    checkedMuni: false,
    tothMuni: 0,
    startDateMuni: null,
    finishDateMuni: null,
    // Others
    checkedOthers: false,
    nameOthers: "",
    tothOthers: 0,
    startDateOthers: null,
    finishDateOthers: null,
    // Total
    addHourFee: 0,
};

export const Recurring = (props) => {
    /** Properties */
    const {
        accountSystem,
        actives,
        clientMarket,
        companyID,
        data,
        expenseEst,
        reloadProjects,
        serviceID,
        setalertType,
        setBackDrop,
        setShow,
        setTexto,
        handleCloseDialog,
        infoAdd,
        checkRemind,
        open,
        bodyMess,
        showConfirm,
    } = props;

    /** Internationalization i18n */
    const [t] = useTranslation("global");

    /** React redux */
    const todos = useSelector((state) => state.todos);
    const dispatch = useDispatch();

    /** Component states */
    const [valuesRecurring, setValuesRecurring] = useState(defaultState);
    const [editMode, setEditMode] = useState(true);
    const [editMode2, setEditMode2] = useState(true);
    const [isError, setIsError] = useState(false);
    const [isErrorNewPeriod, setIsErrorNewPeriod] = useState(false);
    const [dialogNewPeriod, setDialogNewPeriod] = useState(false);
    const [newPeriod, setNewPeriod] = useState(null);
    const [valueTab, setValueTab] = useState(0);
    const [showTabs, setshowTabs] = useState(false);
    const [periods, setPeriods] = useState([]);

    /** Component events */
    const validateDate = () => {
        let isSameYear = valuesRecurring.endDate?.format("YYYY") === moment().format("YYYY");
        return isSameYear ? moment() : valuesRecurring.endDate;
    };

    const getTotalHours = () => {
        let sumTotal =
            parseFloat(valuesRecurring.tothAR) +
            parseFloat(valuesRecurring.tothAP) +
            parseFloat(valuesRecurring.tothClass) +
            parseFloat(valuesRecurring.tothMulti) +
            parseFloat(valuesRecurring.tothPer) +
            (clientMarket === "Estados Unidos"
                ? parseFloat(valuesRecurring.tothSales) +
                parseFloat(valuesRecurring.tothChecks)
                : clientMarket === "Venezuela"
                    ? parseFloat(valuesRecurring.tothOrd) +
                    parseFloat(valuesRecurring.tothEsp) +
                    parseFloat(valuesRecurring.tothMuni)
                    : 0) +
            parseFloat(valuesRecurring.tothOthers)
        return sumTotal;
    };

    const sumTotal =
        (valuesRecurring.finishDateAR?.isBefore(validateDate()) ? 0 : parseFloat(valuesRecurring.tothAR)) +
        (valuesRecurring.finishDateAP?.isBefore(validateDate()) ? 0 : parseFloat(valuesRecurring.tothAP)) +
        (valuesRecurring.finishDateClass?.isBefore(validateDate()) ? 0 : parseFloat(valuesRecurring.tothClass)) +
        (valuesRecurring.finishDateMulti?.isBefore(validateDate()) ? 0 : parseFloat(valuesRecurring.tothMulti)) +
        (valuesRecurring.finishDatePer?.isBefore(validateDate()) ? 0 : parseFloat(valuesRecurring.tothPer)) +
        (clientMarket === "Estados Unidos"
            ? (valuesRecurring.finishDateSales?.isBefore(validateDate()) ? 0 : parseFloat(valuesRecurring.tothSales)) +
            (valuesRecurring.finishDateChecks?.isBefore(validateDate()) ? 0 : parseFloat(valuesRecurring.tothChecks))
            : clientMarket === "Venezuela"
                ? (valuesRecurring.finishDateOrd?.isBefore(validateDate()) ? 0 : parseFloat(valuesRecurring.tothOrd)) +
                (valuesRecurring.finishDateEsp?.isBefore(validateDate()) ? 0 : parseFloat(valuesRecurring.tothEsp)) +
                (valuesRecurring.finishDateMuni?.isBefore(validateDate()) ? 0 : parseFloat(valuesRecurring.tothMuni))
                : 0) +
        (valuesRecurring.finishDateOthers?.isBefore(validateDate()) ? 0 : parseFloat(valuesRecurring.tothOthers));

    useEffect(() => {
        if (data.projects_array && data.projects_array.length !== 0) {
            let accAdditionalData = {};
            let arAdditionalData = {};
            let apAdditionalData = {};
            let classesAdditionalData = {};
            let multiAdditionalData = {};
            let perAdditionalData = {};
            // USA
            let checksAdditionalData = {};
            let salesAdditionalData = {};
            // Venezuela
            let OrdAdditionalData = {};
            let EspAdditionalData = {};
            let MuniAdditionalData = {};
            // Others
            let othersAdditionalData = {};

            let aux = [];
            data.projects_array &&
                data.projects_array.forEach((item, index) => {
                    let obj = {
                        project_company_id: item.project_company_id,
                        year: moment(item.since_date).utc().year(),
                        fee: item.fee ?? 0,
                        discount: item.discount ?? 0,
                        additional_discount: item.additional_discount ?? 0,
                        originalExpenses: item.expenses ?? 0,
                        expenses: item.additionals_only ? "N/A" : item.expenses ?? 0,
                        since_date: item.since_date ? moment(item.since_date).utc() : null,
                        end_date: item.end_date ? moment(item.end_date).utc() : moment().year(moment(item.since_date).utc().year()).endOf("year"),
                        client_commitment_date: item.client_commitment_date
                            ? moment(item.client_commitment_date).utc()
                            : null,
                        client_delivery_date: item.client_delivery_date
                            ? moment(item.client_delivery_date).utc()
                            : null,
                        servFreq: item.service_frequency ? item.service_frequency : 1,
                        paymentType: item.payment_type ? item.payment_type : 2,
                        repDays: item.report_days ? item.report_days : 1,
                        additionals_only: item.additionals_only,
                        additionals: item.additionals,
                        add_hour_fee: clientMarket === "Venezuela" ? 30 : latam.includes(clientMarket) ? 35 : 50,
                    };
                    aux[index] = obj;
                });

            data.projects_array.sort((a, b) => moment(a.since_date).utc().year() - moment(b.since_date).utc().year());
            aux.sort((a, b) => a.year - b.year);

            let indexActive = valueTab;

            aux.forEach((item, index) => {
                if (item.client_commitment_date) indexActive = index;
            });

            if (aux[indexActive].additionals) {
                if (aux[indexActive].additionals.length !== 0) {
                    aux[indexActive].additionals.forEach((element) => {
                        if (element.additional_name === "Accountant") {
                            accAdditionalData = {
                                since_date: element.since_date,
                                end_date: element.end_date,
                                hours: element.hours,
                            };
                        }
                        if (element.additional_name === "A/R reconciliation") {
                            arAdditionalData = {
                                since_date: element.since_date,
                                end_date: element.end_date,
                                hours: element.hours,
                            };
                        }
                        if (element.additional_name === "A/P reconciliation") {
                            apAdditionalData = {
                                since_date: element.since_date,
                                end_date: element.end_date,
                                hours: element.hours,
                            };
                        }
                        if (element.additional_name === "Classes/Categories accounting") {
                            classesAdditionalData = {
                                since_date: element.since_date,
                                end_date: element.end_date,
                                hours: element.hours,
                            };
                        }
                        if (element.additional_name === "Multi-entity accounting") {
                            multiAdditionalData = {
                                since_date: element.since_date,
                                end_date: element.end_date,
                                hours: element.hours,
                            };
                        }
                        if (element.additional_name === "Personalized reporting") {
                            perAdditionalData = {
                                since_date: element.since_date,
                                end_date: element.end_date,
                                hours: element.hours,
                            };
                        }
                        if (element.additional_name === "OthersAcc") {
                            othersAdditionalData = {
                                since_date: element.since_date,
                                end_date: element.end_date,
                                hours: element.hours,
                                other_name: element.other_name,
                            };
                        }
                    });

                    if (clientMarket === "Venezuela") {
                        aux[indexActive].additionals.forEach((element) => {
                            if (element.additional_name === "Ordinario") {
                                OrdAdditionalData = {
                                    since_date: element.since_date,
                                    end_date: element.end_date,
                                    hours: element.hours,
                                };
                            }
                            if (element.additional_name === "Especial") {
                                EspAdditionalData = {
                                    since_date: element.since_date,
                                    end_date: element.end_date,
                                    hours: element.hours,
                                };
                            }
                            if (element.additional_name === "Municipal") {
                                MuniAdditionalData = {
                                    since_date: element.since_date,
                                    end_date: element.end_date,
                                    hours: element.hours,
                                };
                            }
                        });
                    } else if (clientMarket === "Estados Unidos") {
                        aux[indexActive].additionals.forEach((element) => {
                            if (element.additional_name === "Checks") {
                                checksAdditionalData = {
                                    since_date: element.since_date,
                                    end_date: element.end_date,
                                    hours: element.hours,
                                };
                            }
                            if (element.additional_name === "Sales taxes") {
                                salesAdditionalData = {
                                    since_date: element.since_date,
                                    end_date: element.end_date,
                                    hours: element.hours,
                                };
                            }
                        });
                    }
                }
            }

            setshowTabs(true);
            setPeriods(aux);

            setValueTab(indexActive);

            let initObj = {
                endProject: data.finished_service ? data.finished_service : false,
                originalExpenses: aux[indexActive].originalExpenses,
                expenses: aux[indexActive].additionals_only ? "N/A" : aux[indexActive].expenses,
                originalFee: aux[indexActive].fee,
                projectFee: aux[indexActive].additionals_only
                    ? aux[indexActive].add_hour_fee * accAdditionalData.hours
                    : aux[indexActive].paymentType === 1
                        ? aux[indexActive].fee * 12
                        : aux[indexActive].fee,
                projectDiscount: aux[indexActive].discount,
                projectTotal: aux[indexActive].additionals_only
                    ? aux[indexActive].add_hour_fee * accAdditionalData.hours * (1 - aux[indexActive].discount / 100)
                    : aux[indexActive].paymentType === 1
                        ? aux[indexActive].fee * 12 * (1 - aux[indexActive].discount / 100)
                        : aux[indexActive].fee * (1 - aux[indexActive].discount / 100),
                additionalsDiscount: aux[indexActive].additional_discount,
                repDays: aux[indexActive].repDays,
                // Service Responsibility
                initDate: aux[indexActive].since_date,
                endDate: aux[indexActive].end_date,
                // Additionals
                additionalsOnly: aux[indexActive].additionals_only,
                checkedAcc: Object.keys(accAdditionalData).length !== 0 ? true : false,
                tothAcc: accAdditionalData.hours ? accAdditionalData.hours : 0,
                startDateAcc: accAdditionalData.since_date ? moment(accAdditionalData.since_date).utc() : null,
                finishDateAcc: accAdditionalData.end_date ? moment(accAdditionalData.end_date).utc() : null,
                checkedAR: Object.keys(arAdditionalData).length !== 0 ? true : false,
                checkedAROG: Object.keys(arAdditionalData).length !== 0 ? true : false,
                tothAR: arAdditionalData.hours ? arAdditionalData.hours : 0,
                startDateAR: arAdditionalData.since_date ? moment(arAdditionalData.since_date).utc() : null,
                finishDateAR: arAdditionalData.end_date ? moment(arAdditionalData.end_date).utc() : null,
                checkedAP: Object.keys(apAdditionalData).length !== 0 ? true : false,
                checkedAPOG: Object.keys(apAdditionalData).length !== 0 ? true : false,
                tothAP: apAdditionalData.hours ? apAdditionalData.hours : 0,
                startDateAP: apAdditionalData.since_date ? moment(apAdditionalData.since_date).utc() : null,
                finishDateAP: apAdditionalData.end_date ? moment(apAdditionalData.end_date).utc() : null,
                checkedClass: Object.keys(classesAdditionalData).length !== 0 ? true : false,
                checkedClassOG: Object.keys(classesAdditionalData).length !== 0 ? true : false,
                tothClass: classesAdditionalData.hours ? classesAdditionalData.hours : 0,
                startDateClass: classesAdditionalData.since_date
                    ? moment(classesAdditionalData.since_date).utc()
                    : null,
                finishDateClass: classesAdditionalData.end_date ? moment(classesAdditionalData.end_date).utc() : null,
                checkedMulti: Object.keys(multiAdditionalData).length !== 0 ? true : false,
                checkedMultiOG: Object.keys(multiAdditionalData).length !== 0 ? true : false,
                tothMulti: multiAdditionalData.hours ? multiAdditionalData.hours : 0,
                startDateMulti: multiAdditionalData.since_date ? moment(multiAdditionalData.since_date).utc() : null,
                finishDateMulti: multiAdditionalData.end_date ? moment(multiAdditionalData.end_date).utc() : null,
                checkedPer: Object.keys(perAdditionalData).length !== 0 ? true : false,
                checkedPerOG: Object.keys(perAdditionalData).length !== 0 ? true : false,
                tothPer: perAdditionalData.hours ? perAdditionalData.hours : 0,
                startDatePer: perAdditionalData.since_date ? moment(perAdditionalData.since_date).utc() : null,
                finishDatePer: perAdditionalData.end_date ? moment(perAdditionalData.end_date).utc() : null,
                // Others
                checkedOthers: Object.keys(othersAdditionalData).length !== 0 ? true : false,
                checkedOthersOG: Object.keys(othersAdditionalData).length !== 0 ? true : false,
                nameOthers: othersAdditionalData.other_name ? othersAdditionalData.other_name : "",
                tothOthers: othersAdditionalData.hours ? othersAdditionalData.hours : 0,
                startDateOthers: othersAdditionalData.since_date ? moment(othersAdditionalData.since_date).utc() : null,
                finishDateOthers: othersAdditionalData.end_date ? moment(othersAdditionalData.end_date).utc() : null,
                // Total
                addHourFee: aux[indexActive].add_hour_fee,
            };
            let merged = {};

            if (clientMarket === "Venezuela") {
                let objVen = {
                    // Venezuela
                    checkedOrd: Object.keys(OrdAdditionalData).length !== 0 ? true : false,
                    checkedOrdOG: Object.keys(OrdAdditionalData).length !== 0 ? true : false,
                    tothOrd: OrdAdditionalData.hours ? OrdAdditionalData.hours : 0,
                    startDateOrd: OrdAdditionalData.since_date ? moment(OrdAdditionalData.since_date).utc() : null,
                    finishDateOrd: OrdAdditionalData.end_date ? moment(OrdAdditionalData.end_date).utc() : null,
                    finishDateOrdOG: OrdAdditionalData.end_date ? moment(OrdAdditionalData.end_date).utc() : null,
                    checkedEsp: Object.keys(EspAdditionalData).length !== 0 ? true : false,
                    checkedEspOG: Object.keys(EspAdditionalData).length !== 0 ? true : false,
                    tothEsp: EspAdditionalData.hours ? EspAdditionalData.hours : 0,
                    startDateEsp: EspAdditionalData.since_date ? moment(EspAdditionalData.since_date).utc() : null,
                    finishDateEsp: EspAdditionalData.end_date ? moment(EspAdditionalData.end_date).utc() : null,
                    finishDateEspOG: EspAdditionalData.end_date ? moment(EspAdditionalData.end_date).utc() : null,
                    checkedMuni: Object.keys(MuniAdditionalData).length !== 0 ? true : false,
                    checkedMuniOG: Object.keys(MuniAdditionalData).length !== 0 ? true : false,
                    tothMuni: MuniAdditionalData.hours ? MuniAdditionalData.hours : 0,
                    startDateMuni: MuniAdditionalData.since_date ? moment(MuniAdditionalData.since_date).utc() : null,
                    finishDateMuni: MuniAdditionalData.end_date ? moment(MuniAdditionalData.end_date).utc() : null,
                    finishDateMuniOG: MuniAdditionalData.end_date ? moment(MuniAdditionalData.end_date).utc() : null,
                };
                merged = { ...initObj, ...objVen };
            } else if (clientMarket === "Estados Unidos") {
                let objUsa = {
                    // USA
                    checkedChecks: Object.keys(checksAdditionalData).length !== 0 ? true : false,
                    tothChecks: checksAdditionalData.hours ? checksAdditionalData.hours : 0,
                    startDateChecks: checksAdditionalData.since_date
                        ? moment(checksAdditionalData.since_date).utc()
                        : null,
                    finishDateChecks: checksAdditionalData.end_date
                        ? moment(checksAdditionalData.end_date).utc()
                        : null,
                    checkedSales: Object.keys(salesAdditionalData).length !== 0 ? true : false,
                    tothSales: salesAdditionalData.hours ? salesAdditionalData.hours : 0,
                    startDateSales: salesAdditionalData.since_date
                        ? moment(salesAdditionalData.since_date).utc()
                        : null,
                    finishDateSales: salesAdditionalData.end_date ? moment(salesAdditionalData.end_date).utc() : null,
                };

                merged = { ...initObj, ...objUsa };
            } else {
                merged = initObj;
            }
            setValuesRecurring(merged);
        } else {
            setValuesRecurring(defaultState);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    /** Component functions */
    const removeCheck = () => {
        setValuesRecurring({
            ...valuesRecurring,
            [infoAdd.check]: false,
            [infoAdd.toth]: 0,
            nameOthers: infoAdd.nameOthers ? "" : valuesRecurring.nameOthers,
            [infoAdd.startDate]: null,
            [infoAdd.finishDate]: null,
        });
        if (checkRemind) {
            dispatch({
                type: "addRemind",
                item: true,
            });
        }
        handleCloseDialog();
    };

    const updateProject = async () => {
        setBackDrop(true);

        if (!actives.includes("Bookkeeper")) {
            if (
                (valuesRecurring.respIniDate && !showTabs) ||
                (showTabs &&
                    periods[valueTab].since_date &&
                    periods[valueTab].end_date &&
                    data.projects_array[valueTab].client_commitment_date &&
                    !periods[valueTab].client_commitment_date &&
                    periods[valueTab].servFreq &&
                    periods[valueTab].paymentType &&
                    periods[valueTab].repDays) ||
                (showTabs &&
                    periods[valueTab].since_date &&
                    periods[valueTab].end_date &&
                    periods[valueTab].client_commitment_date &&
                    periods[valueTab].servFreq &&
                    periods[valueTab].paymentType &&
                    periods[valueTab].repDays)
            ) {
                let additionalsF = [];
                let params = {};

                if (valuesRecurring.checkedAcc && showTabs) {
                    if (valuesRecurring.startDateAcc && valuesRecurring.finishDateAcc) {
                        if (valuesRecurring.tothAcc > 0) {
                            additionalsF.push({
                                additional_name: "Accountant",
                                hours: valuesRecurring.tothAcc,
                                since_date: moment(valuesRecurring.startDateAcc).startOf("day").format(),
                                end_date: moment(valuesRecurring.finishDateAcc).startOf("day").format(),
                            });
                        } else {
                            setTexto(t("services.hoursValidationError"));
                            setalertType("warning");
                            setShow(true);
                            setBackDrop(false);
                            setIsError(true);
                            return;
                        }
                    } else {
                        setTexto(t("miscellaneous.requiredData"));
                        setalertType("warning");
                        setShow(true);
                        setBackDrop(false);
                        setIsError(true);
                        return;
                    }
                }
                if (valuesRecurring.checkedAR) {
                    if (valuesRecurring.startDateAR && valuesRecurring.finishDateAR) {
                        if (valuesRecurring.tothAR > 0) {
                            additionalsF.push({
                                additional_name: "A/R reconciliation",
                                hours: valuesRecurring.tothAR,
                                since_date: moment(valuesRecurring.startDateAR).startOf("day").format(),
                                end_date: moment(valuesRecurring.finishDateAR).startOf("day").format(),
                            });
                        } else {
                            setTexto(t("services.hoursValidationError"));
                            setalertType("warning");
                            setShow(true);
                            setBackDrop(false);
                            setIsError(true);
                            return;
                        }
                    } else {
                        setTexto(t("miscellaneous.requiredData"));
                        setalertType("warning");
                        setShow(true);
                        setBackDrop(false);
                        setIsError(true);
                        return;
                    }
                }
                if (valuesRecurring.checkedAP) {
                    if (valuesRecurring.startDateAP && valuesRecurring.finishDateAP) {
                        if (valuesRecurring.tothAP > 0) {
                            additionalsF.push({
                                additional_name: "A/P reconciliation",
                                hours: valuesRecurring.tothAP,
                                since_date: moment(valuesRecurring.startDateAP).startOf("day").format(),
                                end_date: moment(valuesRecurring.finishDateAP).startOf("day").format(),
                            });
                        } else {
                            setTexto(t("services.hoursValidationError"));
                            setalertType("warning");
                            setShow(true);
                            setBackDrop(false);
                            setIsError(true);
                            return;
                        }
                    } else {
                        setTexto(t("miscellaneous.requiredData"));
                        setalertType("warning");
                        setShow(true);
                        setBackDrop(false);
                        setIsError(true);
                        return;
                    }
                }
                if (valuesRecurring.checkedClass) {
                    if (valuesRecurring.startDateClass && valuesRecurring.finishDateClass) {
                        if (valuesRecurring.tothClass > 0) {
                            additionalsF.push({
                                additional_name: "Classes/Categories accounting",
                                hours: valuesRecurring.tothClass,
                                since_date: moment(valuesRecurring.startDateClass).startOf("day").format(),
                                end_date: moment(valuesRecurring.finishDateClass).startOf("day").format(),
                            });
                        } else {
                            setTexto(t("services.hoursValidationError"));
                            setalertType("warning");
                            setShow(true);
                            setBackDrop(false);
                            setIsError(true);
                            return;
                        }
                    } else {
                        setTexto(t("miscellaneous.requiredData"));
                        setalertType("warning");
                        setShow(true);
                        setBackDrop(false);
                        setIsError(true);
                        return;
                    }
                }
                if (valuesRecurring.checkedMulti) {
                    if (valuesRecurring.startDateMulti && valuesRecurring.finishDateMulti) {
                        if (valuesRecurring.tothMulti > 0) {
                            additionalsF.push({
                                additional_name: "Multi-entity accounting",
                                hours: valuesRecurring.tothMulti,
                                since_date: moment(valuesRecurring.startDateMulti).startOf("day").format(),
                                end_date: moment(valuesRecurring.finishDateMulti).startOf("day").format(),
                            });
                        } else {
                            setTexto(t("services.hoursValidationError"));
                            setalertType("warning");
                            setShow(true);
                            setBackDrop(false);
                            setIsError(true);
                            return;
                        }
                    } else {
                        setTexto(t("miscellaneous.requiredData"));
                        setalertType("warning");
                        setShow(true);
                        setBackDrop(false);
                        setIsError(true);
                        return;
                    }
                }
                if (valuesRecurring.checkedPer) {
                    if (valuesRecurring.startDatePer && valuesRecurring.finishDatePer) {
                        if (valuesRecurring.tothPer > 0) {
                            additionalsF.push({
                                additional_name: "Personalized reporting",
                                hours: valuesRecurring.tothPer,
                                since_date: moment(valuesRecurring.startDatePer).startOf("day").format(),
                                end_date: moment(valuesRecurring.finishDatePer).startOf("day").format(),
                            });
                        } else {
                            setTexto(t("services.hoursValidationError"));
                            setalertType("warning");
                            setShow(true);
                            setBackDrop(false);
                            setIsError(true);
                            return;
                        }
                    } else {
                        setTexto(t("miscellaneous.requiredData"));
                        setalertType("warning");
                        setShow(true);
                        setBackDrop(false);
                        setIsError(true);
                        return;
                    }
                }
                if (valuesRecurring.checkedOthers) {
                    if (
                        valuesRecurring.startDateOthers &&
                        valuesRecurring.finishDateOthers &&
                        valuesRecurring.nameOthers
                    ) {
                        if (valuesRecurring.tothOthers > 0) {
                            additionalsF.push({
                                additional_name: "OthersAcc",
                                hours: valuesRecurring.tothOthers,
                                since_date: moment(valuesRecurring.startDateOthers).startOf("day").format(),
                                end_date: moment(valuesRecurring.finishDateOthers).startOf("day").format(),
                                other_name: valuesRecurring.nameOthers,
                            });
                        } else {
                            setTexto(t("services.hoursValidationError"));
                            setalertType("warning");
                            setShow(true);
                            setBackDrop(false);
                            setIsError(true);
                            return;
                        }
                    } else {
                        setTexto(t("miscellaneous.requiredData"));
                        setalertType("warning");
                        setShow(true);
                        setBackDrop(false);
                        setIsError(true);
                        return;
                    }
                }
                // USA
                if (clientMarket === "Estados Unidos") {
                    if (valuesRecurring.checkedChecks) {
                        if (valuesRecurring.startDateChecks && valuesRecurring.finishDateChecks) {
                            if (valuesRecurring.tothChecks > 0) {
                                additionalsF.push({
                                    additional_name: "Checks",
                                    hours: valuesRecurring.tothChecks,
                                    since_date: moment(valuesRecurring.startDateChecks).startOf("day").format(),
                                    end_date: moment(valuesRecurring.finishDateChecks).startOf("day").format(),
                                });
                            } else {
                                setTexto(t("services.hoursValidationError"));
                                setalertType("warning");
                                setShow(true);
                                setBackDrop(false);
                                setIsError(true);
                                return;
                            }
                        } else {
                            setTexto(t("miscellaneous.requiredData"));
                            setalertType("warning");
                            setShow(true);
                            setBackDrop(false);
                            setIsError(true);
                            return;
                        }
                    }
                    if (valuesRecurring.checkedSales) {
                        if (valuesRecurring.startDateSales && valuesRecurring.finishDateSales) {
                            if (valuesRecurring.tothSales > 0) {
                                additionalsF.push({
                                    additional_name: "Sales taxes",
                                    hours: valuesRecurring.tothSales,
                                    since_date: moment(valuesRecurring.startDateSales).startOf("day").format(),
                                    end_date: moment(valuesRecurring.finishDateSales).startOf("day").format(),
                                });
                            } else {
                                setTexto(t("services.hoursValidationError"));
                                setalertType("warning");
                                setShow(true);
                                setBackDrop(false);
                                setIsError(true);
                                return;
                            }
                        } else {
                            setTexto(t("miscellaneous.requiredData"));
                            setalertType("warning");
                            setShow(true);
                            setBackDrop(false);
                            setIsError(true);
                            return;
                        }
                    }
                }
                // Venezuela
                if (clientMarket === "Venezuela") {
                    if (valuesRecurring.checkedOrd) {
                        if (valuesRecurring.startDateOrd && valuesRecurring.finishDateOrd) {
                            if (valuesRecurring.tothOrd > 0) {
                                additionalsF.push({
                                    additional_name: "Ordinario",
                                    hours: valuesRecurring.tothOrd,
                                    since_date: moment(valuesRecurring.startDateOrd).startOf("day").format(),
                                    end_date: moment(valuesRecurring.finishDateOrd).startOf("day").format(),
                                });
                            } else {
                                setTexto(t("services.hoursValidationError"));
                                setalertType("warning");
                                setShow(true);
                                setBackDrop(false);
                                setIsError(true);
                                return;
                            }
                        } else {
                            setTexto(t("miscellaneous.requiredData"));
                            setalertType("warning");
                            setShow(true);
                            setBackDrop(false);
                            setIsError(true);
                            return;
                        }
                    }
                    if (valuesRecurring.checkedEsp) {
                        if (valuesRecurring.startDateEsp && valuesRecurring.finishDateEsp) {
                            if (valuesRecurring.tothEsp > 0) {
                                additionalsF.push({
                                    additional_name: "Especial",
                                    hours: valuesRecurring.tothEsp,
                                    since_date: moment(valuesRecurring.startDateEsp).startOf("day").format(),
                                    end_date: moment(valuesRecurring.finishDateEsp).startOf("day").format(),
                                });
                            } else {
                                setTexto(t("services.hoursValidationError"));
                                setalertType("warning");
                                setShow(true);
                                setBackDrop(false);
                                setIsError(true);
                                return;
                            }
                        } else {
                            setTexto(t("miscellaneous.requiredData"));
                            setalertType("warning");
                            setShow(true);
                            setBackDrop(false);
                            setIsError(true);
                            return;
                        }
                    }
                    if (valuesRecurring.checkedMuni && valuesRecurring.finishDateMuni) {
                        if (valuesRecurring.startDateMuni) {
                            if (valuesRecurring.tothMuni > 0) {
                                additionalsF.push({
                                    additional_name: "Municipal",
                                    hours: valuesRecurring.tothMuni,
                                    since_date: moment(valuesRecurring.startDateMuni).startOf("day").format(),
                                    end_date: moment(valuesRecurring.finishDateMuni).startOf("day").format(),
                                });
                            } else {
                                setTexto(t("services.hoursValidationError"));
                                setalertType("warning");
                                setShow(true);
                                setBackDrop(false);
                                setIsError(true);
                                return;
                            }
                        } else {
                            setTexto(t("miscellaneous.requiredData"));
                            setalertType("warning");
                            setShow(true);
                            setBackDrop(false);
                            setIsError(true);
                            return;
                        }
                    }
                }

                if (showTabs) {
                    params = {
                        company_id: companyID,
                        service_id: serviceID,
                        project_id: data.project_id,
                        project_company_id: periods[valueTab].project_company_id,
                        total_fee:
                            valuesRecurring.projectTotal +
                            valuesRecurring.addHourFee * (sumTotal * (1 - valuesRecurring.additionalsDiscount / 100)),
                        finished_service: valuesRecurring.endProject,
                        discount: parseFloat(valuesRecurring.projectDiscount),
                        additional_discount: parseFloat(valuesRecurring.additionalsDiscount),
                        since_date: moment(periods[valueTab].since_date).startOf("day").format(),
                        end_date: moment(periods[valueTab].end_date).startOf("day").format(),
                        client_commitment_date: periods[valueTab].client_commitment_date
                            ? moment(periods[valueTab].client_commitment_date).startOf("day").format()
                            : null,
                        client_delivery_date: periods[valueTab].client_delivery_date
                            ? moment(periods[valueTab].client_delivery_date).startOf("day").format()
                            : null,
                        service_frequency: periods[valueTab].servFreq,
                        payment_type: periods[valueTab].paymentType,
                        report_days: parseInt(periods[valueTab].repDays),
                        additionals_only: valuesRecurring.additionalsOnly,
                        additionals: additionalsF,
                    };
                } else {
                    const additionalsHours = [
                        {
                            additional_name: "Accountant",
                            hours: "0",
                            since_date: moment(valuesRecurring.respIniDate).startOf("day").format(),
                            end_date: moment(valuesRecurring.respIniDate).endOf("year").startOf("day").format(),
                            other_name: "Accountant",
                        },
                    ];

                    params = {
                        company_id: companyID,
                        service_id: serviceID,
                        project_id: data.project_id,
                        project_company_id: data.project_company_id,
                        since_date: moment(valuesRecurring.respIniDate).startOf("day").format(),
                        additionals: !valuesRecurring.checkedAcc ? [] : additionalsHours,
                        client_commitment_date: null,
                        client_delivery_date: null,
                        additionals_only: valuesRecurring?.additionalsOnly ? valuesRecurring?.additionalsOnly : false,
                    };
                }

                await fetch(`/addServicesAndProjects`, {
                    method: "POST",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                    },
                    body: JSON.stringify(params),
                })
                    .then((response) => {
                        if (!response.ok) {
                            // get error message from body or default to response status
                            const error = response.status;
                            throw new Error(error);
                        }

                        if (response.status === 200) {
                            setTexto(t("miscellaneous.successfulUpdate"));
                            setalertType("success");
                            setShow(true);
                            reloadProjects(true);
                        } else if (response.status === 201) {
                            setTexto(t("services.notPossibleGenProject"));
                            setalertType("error");
                            setShow(true);
                            reloadProjects(true);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } else {
                setTexto(t("miscellaneous.requiredData"));
                setalertType("warning");
                setShow(true);
                setIsError(true);
            }
        } else {
            setTexto(t("services.notPosibleContractAcc"));
            setalertType("warning");
            setShow(true);
        }

        setBackDrop(false);
    };

    const handleChangeSwitch = (event) => {
        setValuesRecurring({
            ...valuesRecurring,
            endProject: event.target.checked,
        });
    };

    const handleMouseDownDiscount = (event) => {
        event.preventDefault();
    };

    const handleClickShowDiscount = () => {
        setEditMode(!editMode);
    };

    const handleClickShowDiscount2 = () => {
        setEditMode2(!editMode2);
    };

    const handleChangeDiscount = (event) => {
        let discountAux = 0;

        if (event.target.name === "projectDiscount") {
            discountAux =
                event.target.value >= 0 && event.target.value <= 100
                    ? event.target.value
                    : valuesRecurring.projectDiscount;
            setValuesRecurring({
                ...valuesRecurring,
                projectDiscount: discountAux,
                projectTotal: valuesRecurring.projectFee * (1 - discountAux / 100),
            });
        } else {
            discountAux =
                event.target.value >= 0 && event.target.value <= 100
                    ? event.target.value
                    : valuesRecurring.additionalsDiscount;
            setValuesRecurring({
                ...valuesRecurring,
                additionalsDiscount: discountAux,
            });
        }
    };

    const handleChangeTab = (event, newValue) => {
        if (periods.length !== newValue) {
            setValueTab(newValue);

            let accAdditionalData = {};
            let arAdditionalData = {};
            let apAdditionalData = {};
            let classesAdditionalData = {};
            let multiAdditionalData = {};
            let perAdditionalData = {};
            // USA
            let checksAdditionalData = {};
            let salesAdditionalData = {};
            // Venezuela
            let OrdAdditionalData = {};
            let EspAdditionalData = {};
            let MuniAdditionalData = {};
            // Others
            let othersAdditionalData = {};

            if (periods[newValue].additionals !== undefined) {
                if (periods[newValue].additionals.length !== 0) {
                    periods[newValue].additionals.forEach((element) => {
                        if (element.additional_name === "Accountant") {
                            accAdditionalData = {
                                since_date: element.since_date,
                                end_date: element.end_date,
                                hours: element.hours,
                            };
                        }
                        if (element.additional_name === "A/R reconciliation") {
                            arAdditionalData = {
                                since_date: element.since_date,
                                end_date: element.end_date,
                                hours: element.hours,
                            };
                        }
                        if (element.additional_name === "A/P reconciliation") {
                            apAdditionalData = {
                                since_date: element.since_date,
                                end_date: element.end_date,
                                hours: element.hours,
                            };
                        }
                        if (element.additional_name === "Classes/Categories accounting") {
                            classesAdditionalData = {
                                since_date: element.since_date,
                                end_date: element.end_date,
                                hours: element.hours,
                            };
                        }
                        if (element.additional_name === "Multi-entity accounting") {
                            multiAdditionalData = {
                                since_date: element.since_date,
                                end_date: element.end_date,
                                hours: element.hours,
                            };
                        }
                        if (element.additional_name === "Personalized reporting") {
                            perAdditionalData = {
                                since_date: element.since_date,
                                end_date: element.end_date,
                                hours: element.hours,
                            };
                        }
                        if (element.additional_name === "OthersAcc") {
                            othersAdditionalData = {
                                since_date: element.since_date,
                                end_date: element.end_date,
                                hours: element.hours,
                                other_name: element.other_name,
                            };
                        }
                    });

                    if (clientMarket === "Venezuela") {
                        periods[newValue].additionals.forEach((element) => {
                            if (element.additional_name === "Ordinario") {
                                OrdAdditionalData = {
                                    since_date: element.since_date,
                                    end_date: element.end_date,
                                    hours: element.hours,
                                };
                            }
                            if (element.additional_name === "Especial") {
                                EspAdditionalData = {
                                    since_date: element.since_date,
                                    end_date: element.end_date,
                                    hours: element.hours,
                                };
                            }
                            if (element.additional_name === "Municipal") {
                                MuniAdditionalData = {
                                    since_date: element.since_date,
                                    end_date: element.end_date,
                                    hours: element.hours,
                                };
                            }
                        });
                    } else if (clientMarket === "Estados Unidos") {
                        periods[newValue].additionals.forEach((element) => {
                            if (element.additional_name === "Checks") {
                                checksAdditionalData = {
                                    since_date: element.since_date,
                                    end_date: element.end_date,
                                    hours: element.hours,
                                };
                            }
                            if (element.additional_name === "Sales taxes") {
                                salesAdditionalData = {
                                    since_date: element.since_date,
                                    end_date: element.end_date,
                                    hours: element.hours,
                                };
                            }
                        });
                    }
                }
            }

            let initObj = {
                ...valuesRecurring,
                originalExpenses: periods[newValue].originalExpenses,
                expenses: periods[newValue].additionals_only ? "N/A" : periods[newValue].expenses,
                originalFee: periods[newValue].fee,
                projectFee: periods[newValue].additionals_only
                    ? periods[newValue].add_hour_fee * accAdditionalData.hours
                    : periods[newValue].paymentType === 1
                        ? periods[newValue].fee * 12
                        : periods[newValue].fee,
                projectDiscount: periods[newValue].discount,
                projectTotal: periods[newValue].additionals_only
                    ? periods[newValue].add_hour_fee * accAdditionalData.hours * (1 - periods[newValue].discount / 100)
                    : periods[newValue].paymentType === 1
                        ? periods[newValue].fee * 12 * (1 - periods[newValue].discount / 100)
                        : periods[newValue].fee * (1 - periods[newValue].discount / 100),
                additionalsDiscount: periods[newValue].additional_discount,
                // Service Responsibility
                initDate: periods[newValue].since_date,
                endDate: periods[newValue].end_date,
                // Additionals
                additionalsOnly: periods[newValue].additionals_only,
                checkedAcc: Object.keys(accAdditionalData).length !== 0 ? true : false,
                tothAcc: accAdditionalData.hours ? accAdditionalData.hours : 0,
                startDateAcc: accAdditionalData.since_date ? moment(accAdditionalData.since_date).utc() : null,
                finishDateAcc: accAdditionalData.end_date ? moment(accAdditionalData.end_date).utc() : null,
                checkedAR: Object.keys(arAdditionalData).length !== 0 ? true : false,
                tothAR: arAdditionalData.hours ? arAdditionalData.hours : 0,
                startDateAR: arAdditionalData.since_date ? moment(arAdditionalData.since_date).utc() : null,
                finishDateAR: arAdditionalData.end_date ? moment(arAdditionalData.end_date).utc() : null,
                checkedAP: Object.keys(apAdditionalData).length !== 0 ? true : false,
                tothAP: apAdditionalData.hours ? apAdditionalData.hours : 0,
                startDateAP: apAdditionalData.since_date ? moment(apAdditionalData.since_date).utc() : null,
                finishDateAP: apAdditionalData.end_date ? moment(apAdditionalData.end_date).utc() : null,
                checkedClass: Object.keys(classesAdditionalData).length !== 0 ? true : false,
                tothClass: classesAdditionalData.hours ? classesAdditionalData.hours : 0,
                startDateClass: classesAdditionalData.since_date
                    ? moment(classesAdditionalData.since_date).utc()
                    : null,
                finishDateClass: classesAdditionalData.end_date ? moment(classesAdditionalData.end_date).utc() : null,
                checkedMulti: Object.keys(multiAdditionalData).length !== 0 ? true : false,
                tothMulti: multiAdditionalData.hours ? multiAdditionalData.hours : 0,
                startDateMulti: multiAdditionalData.since_date ? moment(multiAdditionalData.since_date).utc() : null,
                finishDateMulti: multiAdditionalData.end_date ? moment(multiAdditionalData.end_date).utc() : null,
                checkedPer: Object.keys(perAdditionalData).length !== 0 ? true : false,
                tothPer: perAdditionalData.hours ? perAdditionalData.hours : 0,
                startDatePer: perAdditionalData.since_date ? moment(perAdditionalData.since_date).utc() : null,
                finishDatePer: perAdditionalData.end_date ? moment(perAdditionalData.end_date).utc() : null,

                // Others
                checkedOthers: Object.keys(othersAdditionalData).length !== 0 ? true : false,
                nameOthers: othersAdditionalData.other_name ? othersAdditionalData.other_name : "",
                tothOthers: othersAdditionalData.hours ? othersAdditionalData.hours : 0,
                startDateOthers: othersAdditionalData.since_date ? moment(othersAdditionalData.since_date).utc() : null,
                finishDateOthers: othersAdditionalData.end_date ? moment(othersAdditionalData.end_date).utc() : null,
                // Total
                addHourFee: periods[newValue].add_hour_fee,
            };

            let merged = {};

            if (clientMarket === "Venezuela") {
                let objVen = {
                    // Venezuela
                    checkedOrd: Object.keys(OrdAdditionalData).length !== 0 ? true : false,
                    tothOrd: OrdAdditionalData.hours ? OrdAdditionalData.hours : 0,
                    startDateOrd: OrdAdditionalData.since_date ? moment(OrdAdditionalData.since_date).utc() : null,
                    finishDateOrd: OrdAdditionalData.end_date ? moment(OrdAdditionalData.end_date).utc() : null,
                    checkedEsp: Object.keys(EspAdditionalData).length !== 0 ? true : false,
                    tothEsp: EspAdditionalData.hours ? EspAdditionalData.hours : 0,
                    startDateEsp: EspAdditionalData.since_date ? moment(EspAdditionalData.since_date).utc() : null,
                    finishDateEsp: EspAdditionalData.end_date ? moment(EspAdditionalData.end_date).utc() : null,
                    checkedMuni: Object.keys(MuniAdditionalData).length !== 0 ? true : false,
                    tothMuni: MuniAdditionalData.hours ? MuniAdditionalData.hours : 0,
                    startDateMuni: MuniAdditionalData.since_date ? moment(MuniAdditionalData.since_date).utc() : null,
                    finishDateMuni: MuniAdditionalData.end_date ? moment(MuniAdditionalData.end_date).utc() : null,
                };

                merged = { ...initObj, ...objVen };
            } else if (clientMarket === "Estados Unidos") {
                let objUsa = {
                    // USA
                    checkedChecks: Object.keys(checksAdditionalData).length !== 0 ? true : false,
                    tothChecks: checksAdditionalData.hours ? checksAdditionalData.hours : 0,
                    startDateChecks: checksAdditionalData.since_date
                        ? moment(checksAdditionalData.since_date).utc()
                        : null,
                    finishDateChecks: checksAdditionalData.end_date
                        ? moment(checksAdditionalData.end_date).utc()
                        : null,
                    checkedSales: Object.keys(salesAdditionalData).length !== 0 ? true : false,
                    tothSales: salesAdditionalData.hours ? salesAdditionalData.hours : 0,
                    startDateSales: salesAdditionalData.since_date
                        ? moment(salesAdditionalData.since_date).utc()
                        : null,
                    finishDateSales: salesAdditionalData.end_date ? moment(salesAdditionalData.end_date).utc() : null,
                };
                merged = { ...initObj, ...objUsa };
            } else {
                merged = initObj;
            }

            setValuesRecurring(merged);
        }
    };

    const addTab = async () => {
        setBackDrop(true);

        if (newPeriod !== null) {
            let FinalIndex = periods.length;
            let MinDate = moment(newPeriod).year();
            let items = [...periods];
            let exits = false;
            let ready = false;
            let error = false;

            items.forEach((value, index) => {
                if (MinDate < value.year && !ready) {
                    FinalIndex = index;
                    ready = true;
                }

                if (MinDate === value.year) {
                    exits = true;
                }
            });

            if (MinDate > moment().year()) {
                error = true;
                setTexto(t("services.cannotEnterFuturePeriod"));
                setalertType("warning");
                setShow(true);
                setBackDrop(false);
                return;
            }

            if (MinDate > moment().year() - 10) {
                if (!error) {
                    if (!exits) {
                        if (periods.length === 10) {
                            items.splice(0, 1);
                        }

                        let params = {
                            company_id: companyID,
                            service_id: serviceID,
                            project_id: data.project_id,
                            year: moment(newPeriod).year(),
                        };

                        await fetch(`/addNewProjectTab`, {
                            method: "POST",
                            headers: {
                                "Content-type": "application/json; charset=UTF-8",
                                "Access-Control-Allow-Origin": "*",
                            },
                            body: JSON.stringify(params),
                        })
                            .then((res) => {
                                if (res.ok) {
                                    items.splice(FinalIndex, 0, {
                                        year: moment(newPeriod).year(),
                                        since_date: moment(newPeriod).startOf("year"),
                                        end_date: moment(newPeriod).endOf("year"),
                                        client_commitment_date: null,
                                        client_delivery_date: null,
                                        servFreq: 1,
                                        paymentType: 2,
                                        repDays: 1,
                                        additionals_only: false,
                                        additionals: [],
                                        add_hour_fee:
                                            clientMarket === "Venezuela" ? 30 : latam.includes(clientMarket) ? 35 : 50,
                                    });

                                    setPeriods(items);
                                    setValueTab(0);
                                    reloadProjects(true);
                                } else {
                                    switch (res.status) {
                                        case 406:
                                            setTexto(t("services.cannotEnterYear"));
                                            setalertType("error");
                                            setShow(true);
                                            break;
                                        default:
                                            setTexto(t("services.noServiceInCompany"));
                                            setalertType("error");
                                            setShow(true);
                                            break;
                                    }
                                }
                            })
                            .catch((error) => {
                                console.log(error);
                            });

                        closeDialog();
                    } else {
                        setTexto(t("services.cannotEnterYear"));
                        setalertType("warning");
                        setShow(true);
                    }
                }
            } else {
                setTexto(t("services.cannotEnterLessTenPeriod"));
                setalertType("warning");
                setShow(true);
            }
        } else {
            setTexto(t("miscellaneous.requiredData"));
            setalertType("warning");
            setShow(true);
            setIsErrorNewPeriod(true);
        }

        setBackDrop(false);
    };

    const handleChangeStartDate = (date) => {
        if (date === null) {
            setValuesRecurring({
                ...valuesRecurring,
                respIniDate: date,
            });
        } else {
            if (moment(date).year() > new Date().getFullYear() - 10) {
                setValuesRecurring({
                    ...valuesRecurring,
                    respIniDate: moment(date).startOf("year"),
                });
            } else {
                setTexto(t("services.cannotEnterLessTenPeriod"));
                setalertType("warning");
                setShow(true);
            }
        }
    };

    const handleChangeStartDatePeriods = (date, index) => {
        let startDate = moment(date).startOf("month");
        let items = [...periods];

        if (date === null) {
            items[index].since_date = date;

            setPeriods(items);
        } else if (items[index].end_date === null) {
            items[index].since_date = moment(date).startOf("month");

            setPeriods(items);
        } else {
            if (startDate.isAfter(moment(items[index].end_date))) {
                setTexto(t("reports.initDateMess"));
                setalertType("warning");
                setShow(true);
            } else {
                items[index].since_date = moment(date).startOf("month");

                setPeriods(items);
                setValuesRecurring({
                    ...valuesRecurring,
                    initDate: date ? startDate : null,
                    // Additionals
                    startDateAcc: valuesRecurring.checkedAcc ? startDate : null,
                    startDateAR: valuesRecurring.checkedAR ? startDate : null,
                    startDateAP: valuesRecurring.checkedAP ? startDate : null,
                    startDateClass: valuesRecurring.checkedClass ? startDate : null,
                    startDateMulti: valuesRecurring.checkedMulti ? startDate : null,
                    startDatePer: valuesRecurring.checkedPer ? startDate : null,
                    // USA
                    startDateChecks: valuesRecurring.checkedChecks ? startDate : null,
                    startDateSales: valuesRecurring.checkedSales ? startDate : null,
                    // Venezuela
                    startDateOrd: valuesRecurring.checkedOrd ? startDate : null,
                    startDateEsp: valuesRecurring.checkedEsp ? startDate : null,
                    startDateMuni: valuesRecurring.checkedMuni ? startDate : null,
                    // Others
                    startDateOthers: valuesRecurring.checkedOthers ? startDate : null,
                });
            }
        }
    };

    const handleChangeEndDatePeriods = (date, index) => {
        let endDate = moment(date).endOf("month").startOf("day");
        let items = [...periods];

        if (date === null) {
            items[index].end_date = date;
            items[index].client_delivery_date = null;

            setPeriods(items);
        } else if (items[index].since_date === null) {
            items[index].end_date = null;
            items[index].client_delivery_date = null;

            setTexto(t("reports.selectStartDate"));
            setalertType("warning");
            setShow(true);
            setPeriods(items);
        } else {
            if (endDate.isBefore(moment(items[index].since_date))) {
                setTexto(t("reports.endDateMess"));
                setalertType("warning");
                setShow(true);
            } else {
                items[index].end_date = moment(date).endOf("month").startOf("day");
                items[index].client_delivery_date = null;

                setPeriods(items);
                setValuesRecurring({
                    ...valuesRecurring,
                    endDate: date ? endDate : null,
                    // Additionals
                    finishDateAcc: valuesRecurring.checkedAcc ? endDate : null,
                    finishDateAR: valuesRecurring.checkedAR ? endDate : null,
                    finishDateAP: valuesRecurring.checkedAP ? endDate : null,
                    finishDateClass: valuesRecurring.checkedClass ? endDate : null,
                    finishDateMulti: valuesRecurring.checkedMulti ? endDate : null,
                    finishDatePer: valuesRecurring.checkedPer ? endDate : null,
                    // USA
                    finishDateChecks: valuesRecurring.checkedChecks ? endDate : null,
                    finishDateSales: valuesRecurring.checkedSales ? endDate : null,
                    // Venezuela
                    finishDateOrd: valuesRecurring.checkedOrd ? endDate : null,
                    finishDateEsp: valuesRecurring.checkedEsp ? endDate : null,
                    finishDateMuni: valuesRecurring.checkedMuni ? endDate : null,
                    // Others
                    finishDateOthers: valuesRecurring.checkedOthers ? endDate : null,
                });
            }
        }
    };

    const handleChangeCommitmentDatePeriods = (date, index) => {
        let items = [...periods];

        if (!date) {
            items[index].client_commitment_date = date;
            items[index].client_delivery_date = null;

            setPeriods(items);
        } else {
            if (date.isAfter(moment(items[index].end_date).format("YYYY-MM-DD"))) {
                items[index].client_commitment_date = date;

                setPeriods(items);
            } else {
                setTexto(t("reports.selectEntDate"));
                setalertType("warning");
                setShow(true);
                items[index].client_commitment_date = null;

                setPeriods(items);
            }
        }
    };

    const handleChangeDeliverDatePeriods = (date, index) => {
        let items = [...periods];

        if (!date) {
            items[index].client_delivery_date = date;

            setPeriods(items);
        } else {
            if (date.isAfter(moment(items[index].end_date).format("YYYY-MM-DD"))) {
                items[index].client_delivery_date = date;

                setPeriods(items);
            } else {
                setTexto(t("reports.selectEntDate"));
                setalertType("warning");
                setShow(true);
                items[index].client_delivery_date = null;

                setPeriods(items);
            }
        }

        if (
            periods
                .filter((period) => period.client_commitment_date)
                .some((element) => element.client_delivery_date === null) &&
            valuesRecurring.endProject
        ) {
            setValuesRecurring({
                ...valuesRecurring,
                endProject: false,
            });
        }
    };

    const handleChangeFreq = (event) => {
        let items = [...periods];

        items[valueTab].servFreq = event.target.value;

        setValuesRecurring({
            ...valuesRecurring,
            projectFee: valuesRecurring.additionalsOnly
                ? valuesRecurring.projectFee
                : event.target.value === 2 || (event.target.value === 1 && items[valueTab].paymentType === 1)
                    ? items[valueTab].fee * 12
                    : items[valueTab].fee,
            projectDiscount: valuesRecurring.additionalsOnly
                ? 0
                : event.target.value === 2 || (event.target.value === 1 && items[valueTab].paymentType === 1)
                    ? 15
                    : items[valueTab].discount
                        ? items[valueTab].discount
                        : 0,
            projectTotal: valuesRecurring.additionalsOnly
                ? valuesRecurring.projectTotal
                : event.target.value === 2 || (event.target.value === 1 && items[valueTab].paymentType === 1)
                    ? items[valueTab].fee * 12 * 0.85
                    : items[valueTab].fee * (1 - items[valueTab].discount / 100),
            additionalsDiscount: valuesRecurring.additionalsOnly
                ? 0
                : event.target.value === 2 || (event.target.value === 1 && items[valueTab].paymentType === 1)
                    ? 15
                    : items[valueTab].additional_discount
                        ? items[valueTab].additional_discount
                        : 0,
            checkedSales: false,
            tothSales: 0,
            startDateSales: null,
            finishDateSales: null,
            checkedOrd: false,
            tothOrd: 0,
            startDateOrd: null,
            finishDateOrd: null,
            checkedEsp: false,
            tothEsp: 0,
            startDateEsp: null,
            finishDateEsp: null,
            initDate: event.target.value === 2
                ? moment(items[valueTab].year, "YYYY").startOf("year")
                : items[valueTab].client_commitment_date
                    ? moment(items[valueTab].year, "YYYY").startOf("year")
                    : null,
        });

        if (event.target.value === 2) {
            items[valueTab].paymentType = 1;
            items[valueTab].since_date = moment(items[valueTab].year, "YYYY").startOf("year");
            items[valueTab].end_date = moment(items[valueTab].year, "YYYY").endOf("year").startOf("day");
        } else {
            items[valueTab].since_date = items[valueTab].client_commitment_date
                ? moment(items[valueTab].year, "YYYY").startOf("year")
                : null;
        }

        setPeriods(items);
    };

    const handleChangePaymentType = (event) => {
        let items = [...periods];

        items[valueTab].paymentType = event.target.value;

        setValuesRecurring({
            ...valuesRecurring,
            projectFee: valuesRecurring.additionalsOnly
                ? valuesRecurring.projectFee
                : event.target.value === 1
                    ? items[valueTab].fee * 12
                    : items[valueTab].fee,
            projectDiscount:
                //Si esta cheked el switch de 'Pagos por hora', el descuento es 0
                valuesRecurring.additionalsOnly
                    ? 0
                    : //Si el tipo de pago es Anual anticipado  el descuento es 15%
                    event.target.value === 1
                        ? 15
                        : //Si existe un descuento almacenado en BD
                        items[valueTab].discount ?? 0,
            projectTotal: valuesRecurring.additionalsOnly
                ? valuesRecurring.projectTotal
                : event.target.value === 1
                    ? items[valueTab].fee * 12 * 0.85
                    : items[valueTab].fee * (1 - (items[valueTab].discount ?? 0) / 100),
            additionalsDiscount:
                //Si esta cheked el switch de 'Pagos por hora', el descuento es 0
                valuesRecurring.additionalsOnly
                    ? 0
                    : //Si el tipo de pago es Anual anticipado  el descuento es 15%
                    event.target.value === 1
                        ? 15
                        : //Si existe un descuento almacenado en BD
                        items[valueTab].additional_discount ?? 0,
        });

        setPeriods(items);
    };

    const handleChangeRepDaysPeriods = (event) => {
        let items = [...periods];

        if (event.target.value >= 1 && event.target.value <= 25) {
            items[valueTab].repDays = event.target.value;
        } else {
            items[valueTab].repDays = "";
            setTexto(t("services.monthlyReportsMsg"));
            setalertType("warning");
            setShow(true);
        }

        setPeriods(items);
    };

    const closeDialog = () => {
        setDialogNewPeriod(false);
        setNewPeriod(null);
        setIsErrorNewPeriod(false);
    };

    const handleChangeNew = (date) => {
        setNewPeriod(date);
    };

    const dialogBody = (
        <Grid container>
            <Grid item xs={12}>
                <LocalizationProvider
                    dateAdapter={AdapterMoment}
                    adapterLocale={t("language.locale")}
                    localeText={{ clearButtonLabel: t("miscellaneous.clear") }}
                >
                    <DatePicker
                        componentsProps={{
                            actionBar: { actions: ["clear"] },
                        }}
                        value={newPeriod}
                        views={["year"]}
                        mask="____"
                        inputFormat="YYYY"
                        disableFuture
                        onChange={(newValue) => handleChangeNew(newValue)}
                        renderInput={(params) => (
                            <TextField
                                fullWidth
                                required
                                {...params}
                                error={isErrorNewPeriod && newPeriod === null ? true : false}
                            />
                        )}
                        inputProps={{ readOnly: true }}
                        label={t("miscellaneous.year")}
                    />
                </LocalizationProvider>
            </Grid>
        </Grid>
    );

    const dialogButtons = (
        <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
            <Grid item>
                <ColorButton
                    onClick={closeDialog}
                    sx={{
                        mr: 1,
                        "&:hover": {
                            backgroundColor: "white",
                            color: "#2f3190",
                            border: "1px solid #2f3190",
                        },
                    }}
                    color="secondary"
                    disableElevation
                    variant="outlined"
                >
                    {t("miscellaneous.cancel")}
                </ColorButton>
            </Grid>
            <Grid item>
                <ColorButton
                    onClick={addTab}
                    sx={{
                        "&:hover": {
                            backgroundColor: "#2f3190",
                            border: "1px solid #2f3190",
                        },
                    }}
                    color="secondary"
                    disableElevation
                    variant="contained"
                >
                    {t("miscellaneous.accept")}
                </ColorButton>
            </Grid>
        </Grid>
    );

    const handleChangeSwitchHourly = (event) => {
        if (showTabs) {
            setValuesRecurring({
                ...valuesRecurring,
                expenses: event.target.checked ? "N/A" : valuesRecurring.originalExpenses,
                additionalsOnly: event.target.checked,
                projectFee: event.target.checked
                    ? 0
                    : periods[valueTab].servFreq === 2 ||
                        (periods[valueTab].servFreq === 1 && periods[valueTab].paymentType === 1)
                        ? periods[valueTab].fee * 12
                        : valuesRecurring.originalFee,
                projectTotal: event.target.checked
                    ? 0
                    : periods[valueTab].servFreq === 2 ||
                        (periods[valueTab].servFreq === 1 && periods[valueTab].paymentType === 1)
                        ? periods[valueTab].fee * 12 * 0.85
                        : valuesRecurring.originalFee * (1 - valuesRecurring.projectDiscount / 100),
                checkedAcc: event.target.checked,
                tothAcc: 0,
                startDateAcc: event.target.checked ? valuesRecurring.initDate : null,
                finishDateAcc: event.target.checked ? valuesRecurring.endDate : null,
                projectDiscount:
                    //Si esta cheked el switch de 'Pagos por hora' el descuento es 0
                    event.target.checked
                        ? 0
                        : //Si frecuencia de servicio es Anual o frecuencia de servicio es Mensual y el cobro anual, el descuento es 15
                        periods[valueTab].servFreq === 2 ||
                            (periods[valueTab].servFreq === 1 && periods[valueTab].paymentType === 1)
                            ? 15
                            : //Si existe un descuento almacenado en BD
                            periods[valueTab].discount
                                ? periods[valueTab].discount
                                : 0,
                additionalsDiscount:
                    //Si esta cheked el switch de 'Pagos por hora' el descuento es 0
                    event.target.checked
                        ? 0
                        : //Si frecuencia de servicio es Anual o frecuencia de servicio es Mensual y el cobro anual, el descuento es 15
                        periods[valueTab].servFreq === 2 ||
                            (periods[valueTab].servFreq === 1 && periods[valueTab].paymentType === 1)
                            ? 15
                            : //Si existe un descuento almacenado en BD
                            periods[valueTab].additional_discount
                                ? periods[valueTab].additional_discount
                                : 0,
            });
        } else {
            setValuesRecurring({
                ...valuesRecurring,
                expenses: event.target.checked ? "N/A" : valuesRecurring.originalExpenses,
                additionalsOnly: event.target.checked,
                projectFee: 0,
                checkedAcc: event.target.checked,
                tothAcc: 0,
                startDateAcc: event.target.checked ? valuesRecurring.respIniDate : null,
                finishDateAcc: event.target.checked ? valuesRecurring.endDate : null,
                projectDiscount: 0,
                additionalsDiscount: 0,
            });
        }
    };

    return (
        <StyledContainer>
            <LocalizationProvider
                dateAdapter={AdapterMoment}
                adapterLocale={t("language.locale")}
                localeText={{ clearButtonLabel: t("miscellaneous.clear") }}
            >
                <Card elevation={0}>
                    <CardHeader
                        title={
                            <Box display="flex" sx={{ alignItems: "center" }}>
                                Accountant recurring
                                <FormControlLabel
                                    sx={{ ml: 1 }}
                                    control={
                                        <Switch
                                            checked={valuesRecurring.additionalsOnly}
                                            inputProps={{
                                                "aria-label": "controlled",
                                            }}
                                            color="secondary"
                                            onChange={(event) => handleChangeSwitchHourly(event)}
                                            className={classes.switch}
                                        />
                                    }
                                    label={
                                        <Typography variant="body1" color="textPrimary">
                                            {t("services.hourlyInvoicing")}
                                        </Typography>
                                    }
                                />
                            </Box>
                        }
                        action={
                            showTabs && (
                                <LightTooltip title={t("services.toolPreventCreation")} aria-label="endService-tooltip">
                                    <FormControlLabel
                                        disabled={
                                            data.projects_array.filter((period) => period.client_commitment_date)
                                                .length !==
                                                periods.filter((period) => period.client_commitment_date).length ||
                                                (data.projects_array.filter((period) => period.client_commitment_date)
                                                    .length === 0 &&
                                                    periods.filter((period) => period.client_commitment_date).length === 0)
                                                ? true
                                                : data.projects_array
                                                    .filter((period) => period.client_commitment_date)
                                                    .some((element) => !element.client_delivery_date) ||
                                                periods
                                                    .filter((period) => period.client_commitment_date)
                                                    .some((element) => !element.client_delivery_date)
                                        }
                                        control={
                                            <Switch
                                                checked={valuesRecurring.endProject}
                                                className={classes.switch}
                                                color="secondary"
                                                inputProps={{
                                                    "aria-label": "controlled",
                                                }}
                                                onChange={handleChangeSwitch}
                                            />
                                        }
                                        label={
                                            <Typography variant="body1" color="textPrimary">
                                                {t("services.finishProject")}
                                            </Typography>
                                        }
                                    />
                                </LightTooltip>
                            )
                        }
                    />
                    <Divider />
                    <CardContent>
                        {actives.includes("Bookkeeper") && (
                            <Grid container spacing={2} justifyContent="center" sx={{ paddingBottom: 3 }}>
                                <Grid item>
                                    <Alert
                                        severity="warning"
                                        sx={{
                                            borderRadius: 8,
                                            fontSize: 12,
                                            fontWeight: 600,
                                        }}
                                    >
                                        {t("services.notPosibleContractAcc")}
                                    </Alert>
                                </Grid>
                            </Grid>
                        )}
                        {!actives.includes("Bookkeeper") &&
                            accountSystem.disabledData === true &&
                            (accountSystem.prov === "Xero" || accountSystem.prov === "Quickbooks Online") && (
                                <Grid container spacing={2} justifyContent="center" sx={{ paddingBottom: 3 }}>
                                    <Grid item>
                                        <Alert
                                            severity="warning"
                                            sx={{
                                                borderRadius: 8,
                                                fontSize: 12,
                                                fontWeight: 600,
                                            }}
                                        >
                                            {t("services.enterValidCredentials")}
                                        </Alert>
                                    </Grid>
                                </Grid>
                            )}
                        {!actives.includes("Bookkeeper") && expenseEst === -1 && (
                            <Grid container spacing={2} justifyContent="center" sx={{ paddingBottom: 3 }}>
                                <Grid item>
                                    <Alert
                                        severity="warning"
                                        sx={{
                                            borderRadius: 8,
                                            fontSize: 12,
                                            fontWeight: 600,
                                        }}
                                    >
                                        {t("services.ableToSaveInfo")}
                                    </Alert>
                                </Grid>
                            </Grid>
                        )}
                        <Grid container alignItems="center" spacing={2}>
                            <Grid item container spacing={2}>
                                <Grid item xs={12} sm={6} lg={3}>
                                    {valuesRecurring.expenses !== "N/A" ? (
                                        <FormControl fullWidth variant="outlined" disabled>
                                            <InputLabel htmlFor="outlined-adornment-expenses">
                                                {t("miscellaneous.expenseScale")}
                                            </InputLabel>
                                            <OutlinedInput
                                                id="outlined-adornment-expenses"
                                                value={valuesRecurring.expenses}
                                                inputComponent={NumberFormatCustom}
                                                startAdornment={
                                                    <InputAdornment disableTypography position="start">
                                                        $
                                                    </InputAdornment>
                                                }
                                                label={t("miscellaneous.expenseScale")}
                                            />
                                        </FormControl>
                                    ) : (
                                        <TextField
                                            fullWidth
                                            disabled
                                            label={t("miscellaneous.expenseScale")}
                                            value={valuesRecurring.expenses}
                                            variant="outlined"
                                        />
                                    )}
                                </Grid>
                                <Grid item xs={12} sm={6} lg={3}>
                                    <FormControl fullWidth variant="outlined" disabled>
                                        <InputLabel htmlFor="outlined-adornment-projectFee">
                                            {t("services.projectFee")}
                                        </InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-projectFee"
                                            value={valuesRecurring.projectFee}
                                            inputComponent={NumberFormatCustom}
                                            startAdornment={
                                                <InputAdornment disableTypography position="start">
                                                    $
                                                </InputAdornment>
                                            }
                                            label={t("services.projectFee")}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6} lg={3}>
                                    <FormControl variant="outlined" disabled={editMode} fullWidth>
                                        <InputLabel htmlFor="outlined-adornment-discount">
                                            {t("miscellaneous.discount")}
                                        </InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-discount"
                                            type="number"
                                            name="projectDiscount"
                                            value={valuesRecurring.projectDiscount}
                                            onChange={handleChangeDiscount}
                                            onBlur={() =>
                                                setValuesRecurring({
                                                    ...valuesRecurring,
                                                    projectDiscount: parseFloat(
                                                        valuesRecurring.projectDiscount === ""
                                                            ? 0
                                                            : valuesRecurring.projectDiscount
                                                    ).toFixed(2),
                                                })
                                            }
                                            inputProps={{
                                                min: 0,
                                                max: 100,
                                            }}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    %
                                                    <LightTooltip
                                                        title={
                                                            editMode ? t("miscellaneous.edit") : t("miscellaneous.save")
                                                        }
                                                        aria-label="edit"
                                                    >
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowDiscount}
                                                            onMouseDown={handleMouseDownDiscount}
                                                            edge="end"
                                                        >
                                                            {!editMode ? <EditOffIcon /> : <EditIcon />}
                                                        </IconButton>
                                                    </LightTooltip>
                                                </InputAdornment>
                                            }
                                            label={t("miscellaneous.discount")}
                                            sx={{
                                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                                {
                                                    display: "none",
                                                },
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6} lg={3}>
                                    <FormControl fullWidth variant="outlined" disabled>
                                        <InputLabel htmlFor="outlined-adornment-projectTotal">
                                            {t("services.projectTot")}
                                        </InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-projectTotal"
                                            value={valuesRecurring.projectTotal}
                                            inputComponent={NumberFormatCustom}
                                            startAdornment={
                                                <InputAdornment disableTypography position="start">
                                                    $
                                                </InputAdornment>
                                            }
                                            label={t("services.projectTot")}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid item container spacing={2}>
                                <Grid item xs={12} sm={6} lg={3}></Grid>
                                <Grid item xs={12} sm={6} lg={3}>
                                    <FormControl fullWidth variant="outlined" disabled>
                                        <InputLabel htmlFor="outlined-adornment-additionalsFee">
                                            {t("services.hourlyRateAdd")}
                                        </InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-additionalsFee"
                                            value={valuesRecurring.addHourFee * sumTotal}
                                            inputComponent={NumberFormatCustom}
                                            startAdornment={
                                                <InputAdornment disableTypography position="start">
                                                    $
                                                </InputAdornment>
                                            }
                                            label={t("services.hourlyRateAdd")}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6} lg={3}>
                                    <FormControl variant="outlined" disabled={editMode2} fullWidth>
                                        <InputLabel htmlFor="outlined-adornment-discount">
                                            {t("miscellaneous.discount")}
                                        </InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-discount"
                                            type="number"
                                            name="additionalsDiscount"
                                            value={valuesRecurring.additionalsDiscount}
                                            onChange={handleChangeDiscount}
                                            onBlur={() =>
                                                setValuesRecurring({
                                                    ...valuesRecurring,
                                                    additionalsDiscount: parseFloat(
                                                        valuesRecurring.additionalsDiscount === ""
                                                            ? 0
                                                            : valuesRecurring.additionalsDiscount
                                                    ).toFixed(2),
                                                })
                                            }
                                            inputProps={{
                                                min: 0,
                                                max: 100,
                                            }}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    %
                                                    <LightTooltip
                                                        title={
                                                            editMode2
                                                                ? t("miscellaneous.edit")
                                                                : t("miscellaneous.save")
                                                        }
                                                        aria-label="edit"
                                                    >
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowDiscount2}
                                                            onMouseDown={handleMouseDownDiscount}
                                                            edge="end"
                                                        >
                                                            {!editMode2 ? <EditOffIcon /> : <EditIcon />}
                                                        </IconButton>
                                                    </LightTooltip>
                                                </InputAdornment>
                                            }
                                            label={t("miscellaneous.discount")}
                                            sx={{
                                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                                {
                                                    display: "none",
                                                },
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6} lg={3}>
                                    <FormControl fullWidth variant="outlined" disabled>
                                        <InputLabel htmlFor="outlined-adornment-additionalsTotal">
                                            {t("clientsTable.totalAdd")}
                                        </InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-additionalsTotal"
                                            value={
                                                valuesRecurring.addHourFee *
                                                sumTotal *
                                                (1 - valuesRecurring.additionalsDiscount / 100)
                                            }
                                            inputComponent={NumberFormatCustom}
                                            startAdornment={
                                                <InputAdornment disableTypography position="start">
                                                    $
                                                </InputAdornment>
                                            }
                                            label={t("clientsTable.totalAdd")}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid item container spacing={2}>
                                <Grid item xs={12} sm={6} lg={9}></Grid>
                                <Grid item xs={12} sm={6} lg={3}>
                                    <FormControl fullWidth variant="outlined" disabled>
                                        <InputLabel htmlFor="outlined-adornment-totalFee">
                                            {t("proposals.feeTotal")}
                                        </InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-totalFee"
                                            value={
                                                valuesRecurring.projectTotal +
                                                valuesRecurring.addHourFee *
                                                sumTotal *
                                                (1 - valuesRecurring.additionalsDiscount / 100)
                                            }
                                            inputComponent={NumberFormatCustom}
                                            startAdornment={
                                                <InputAdornment disableTypography position="start">
                                                    $
                                                </InputAdornment>
                                            }
                                            label={t("proposals.feeTotal")}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                <Card elevation={0}>
                    <CardHeader title={t("services.serviceResponsibility")} sx={{ color: "#ff5968" }} />
                    <Divider />
                    <CardContent>
                        {showTabs ? (
                            <>
                                <Box
                                    sx={{
                                        borderBottom: 2,
                                        borderColor: "#031851",
                                        mb: 2,
                                    }}
                                >
                                    <Tabs
                                        value={valueTab}
                                        onChange={handleChangeTab}
                                        variant="scrollable"
                                        scrollButtons="auto"
                                        aria-label="years tabs"
                                        TabIndicatorProps={{
                                            sx: {
                                                display: "none",
                                            },
                                        }}
                                    >
                                        {periods.map((item, index) => (
                                            <CustomTab
                                                label={item.year}
                                                {...a11yProps(index)}
                                                sx={{
                                                    borderTop: item.client_commitment_date && "2px solid #ff5968",
                                                    borderRight: item.client_commitment_date && "2px solid #ff5968",
                                                    borderLeft: item.client_commitment_date && "2px solid #ff5968",
                                                    backgroundColor: item.client_commitment_date
                                                        ? "#ff5968"
                                                        : "#E9EAE8",
                                                    color: item.client_commitment_date ? "#fff" : "#031851",
                                                }}
                                            />
                                        ))}
                                        {!valuesRecurring.endProject && (
                                            <CustomTab
                                                icon={<AddIcon fontSize="small" />}
                                                aria-label="add"
                                                onClick={() => setDialogNewPeriod(true)}
                                            />
                                        )}
                                    </Tabs>
                                </Box>
                                {periods.map((values, i) => (
                                    <TabPanel value={valueTab} index={i}>
                                        <Grid container spacing={2}>
                                            <Grid item lg={3} sm={6} xs={12}>
                                                <DatePicker
                                                    componentsProps={{
                                                        actionBar: {
                                                            actions: ["clear"],
                                                        },
                                                    }}
                                                    value={values.since_date}
                                                    minDate={moment(periods[valueTab].year, "YYYY").startOf("year")}
                                                    maxDate={moment(periods[valueTab].year, "YYYY").endOf("year")}
                                                    onChange={(newValue) => handleChangeStartDatePeriods(newValue, i)}
                                                    inputFormat={todos.dateFormat}
                                                    disabled={values.servFreq === 2}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            fullWidth
                                                            required
                                                            {...params}
                                                            error={isError && values.since_date === null ? true : false}
                                                        />
                                                    )}
                                                    inputProps={{
                                                        readOnly: true,
                                                    }}
                                                    label={t("reports.initDate")}
                                                    views={["year", "month"]}
                                                />
                                            </Grid>
                                            <Grid item lg={3} sm={6} xs={12}>
                                                <DatePicker
                                                    componentsProps={{
                                                        actionBar: {
                                                            actions: ["clear"],
                                                        },
                                                    }}
                                                    disabled={values.servFreq === 2}
                                                    value={values.end_date}
                                                    minDate={moment(periods[valueTab].year, "YYYY").startOf("year")}
                                                    maxDate={moment(periods[valueTab].year, "YYYY").endOf("year")}
                                                    onChange={(newValue) => handleChangeEndDatePeriods(newValue, i)}
                                                    inputFormat={todos.dateFormat}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            required
                                                            fullWidth
                                                            {...params}
                                                            error={isError && values.end_date === null ? true : false}
                                                        />
                                                    )}
                                                    inputProps={{
                                                        readOnly: true,
                                                    }}
                                                    label={t("reports.endDate")}
                                                    views={["year", "month"]}
                                                />
                                            </Grid>
                                            <Grid item lg={3} sm={6} xs={12}>
                                                <DatePicker
                                                    componentsProps={{
                                                        actionBar: {
                                                            actions: ["clear"],
                                                        },
                                                    }}
                                                    disabled={valuesRecurring.endProject}
                                                    value={values.client_commitment_date}
                                                    minDate={moment(periods[valueTab].year, "YYYY").startOf("year")}
                                                    onChange={(newValue) =>
                                                        handleChangeCommitmentDatePeriods(newValue, i)
                                                    }
                                                    inputFormat={todos.dateFormat}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            fullWidth
                                                            {...params}
                                                            required={
                                                                !data.projects_array[valueTab].client_commitment_date
                                                            }
                                                            error={
                                                                data.projects_array[valueTab].client_commitment_date
                                                                    ? false
                                                                    : isError && values.client_commitment_date === null
                                                                        ? true
                                                                        : false
                                                            }
                                                        />
                                                    )}
                                                    inputProps={{
                                                        readOnly: true,
                                                    }}
                                                    label={t("services.estimDeliveryDate")}
                                                    views={["year", "month", "day"]}
                                                />
                                            </Grid>
                                            <Grid item lg={3} sm={6} xs={12}>
                                                <DatePicker
                                                    componentsProps={{
                                                        actionBar: {
                                                            actions: ["clear"],
                                                        },
                                                    }}
                                                    disableFuture
                                                    value={values.client_delivery_date}
                                                    minDate={moment(periods[valueTab].year, "YYYY").startOf("year")}
                                                    onChange={(newValue) => handleChangeDeliverDatePeriods(newValue, i)}
                                                    disabled={values.end_date === null}
                                                    inputFormat={todos.dateFormat}
                                                    renderInput={(params) => <TextField fullWidth {...params} />}
                                                    inputProps={{
                                                        readOnly: true,
                                                    }}
                                                    label={t("services.deliveryDate")}
                                                    views={["year", "month", "day"]}
                                                />
                                            </Grid>
                                            <Grid item lg={3} sm={6} xs={12}>
                                                <FormControl
                                                    fullWidth
                                                    required
                                                    error={isError && values.servFreq === "" ? true : false}
                                                >
                                                    <InputLabel id="serv-freq-simple-select-label">
                                                        {t("services.serviceFreq")}
                                                    </InputLabel>
                                                    <Select
                                                        name="servFreq"
                                                        labelId="serv-freq-simple-select-label"
                                                        IconComponent={KeyboardArrowDownIcon}
                                                        value={values.servFreq}
                                                        label={t("services.serviceFreq")}
                                                        onChange={handleChangeFreq}
                                                        MenuProps={{
                                                            PaperProps: {
                                                                sx: {
                                                                    mt: 0.7,
                                                                    borderRadius: 2,
                                                                    boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                                },
                                                            },
                                                        }}
                                                    >
                                                        <MenuItem value={1}>{t("miscellaneous.monthly")}</MenuItem>
                                                        <MenuItem value={2}>{t("miscellaneous.annual")}</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item lg={3} sm={6} xs={12}>
                                                <FormControl
                                                    fullWidth
                                                    required
                                                    disabled={values.servFreq === 2}
                                                    error={isError && values.paymentType === "" ? true : false}
                                                >
                                                    <InputLabel id="payment-type-simple-select-label">
                                                        {t("services.paymentType")}
                                                    </InputLabel>
                                                    <Select
                                                        name="paymentType"
                                                        labelId="payment-type-simple-select-label"
                                                        IconComponent={KeyboardArrowDownIcon}
                                                        value={values.paymentType}
                                                        label={t("services.paymentType")}
                                                        onChange={handleChangePaymentType}
                                                        MenuProps={{
                                                            PaperProps: {
                                                                sx: {
                                                                    mt: 0.7,
                                                                    borderRadius: 2,
                                                                    boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                                },
                                                            },
                                                        }}
                                                    >
                                                        <MenuItem value={1}>
                                                            {t("miscellaneous.anticipatedAnnual")}
                                                        </MenuItem>
                                                        {values.servFreq === 1 && (
                                                            <MenuItem value={2}>{t("miscellaneous.monthly")}</MenuItem>
                                                        )}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item lg={3} sm={6} xs={12}>
                                                <TextField
                                                    fullWidth
                                                    required
                                                    label={t("services.monthlyReportDate")}
                                                    name="repDays"
                                                    onChange={handleChangeRepDaysPeriods}
                                                    value={values.repDays}
                                                    variant="outlined"
                                                    type="number"
                                                    InputProps={{
                                                        inputProps: {
                                                            min: 1,
                                                            max: 25,
                                                            onKeyDown: (event) => {
                                                                event.preventDefault();
                                                            },
                                                        },
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </TabPanel>
                                ))}
                            </>
                        ) : (
                            <Grid container spacing={2}>
                                <Grid item lg={3} sm={6} xs={12}>
                                    <DatePicker
                                        componentsProps={{
                                            actionBar: { actions: ["clear"] },
                                        }}
                                        value={valuesRecurring.respIniDate}
                                        onChange={(newValue) => handleChangeStartDate(newValue)}
                                        inputFormat={todos.dateFormat}
                                        mask="__/__/____"
                                        disableFuture
                                        renderInput={(params) => (
                                            <TextField
                                                fullWidth
                                                required
                                                {...params}
                                                error={isError && valuesRecurring.respIniDate === null ? true : false}
                                            />
                                        )}
                                        inputProps={{ readOnly: true }}
                                        label={t("reports.initDate")}
                                        views={["year"]}
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </CardContent>
                </Card>
                {showTabs ? (
                    <Additionals
                        values={valuesRecurring}
                        set={setValuesRecurring}
                        periods={periods}
                        valueTab={valueTab}
                        setTexto={setTexto}
                        setalertType={setalertType}
                        setShow={setShow}
                        clientMarket={clientMarket}
                        isError={isError}
                        updateProject={updateProject}
                        sumTotal={sumTotal}
                        showConfirm={showConfirm}
                        sumTotalAddHours={getTotalHours()}
                    />
                ) : (
                    <>
                        <Divider />
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                p: 2,
                            }}
                        >
                            <ColorButton
                                color="secondary"
                                variant="contained"
                                disableElevation
                                className="Button"
                                onClick={updateProject}
                                sx={{
                                    "&:hover": {
                                        backgroundColor: "#2f3190",
                                        border: "1px solid #2f3190",
                                    },
                                }}
                                disabled={
                                    actives.includes("Bookkeeper") ||
                                    expenseEst === -1 ||
                                    (accountSystem.disabledData === true &&
                                        (accountSystem.prov === "Xero" || accountSystem.prov === "Quickbooks Online"))
                                }
                            >
                                {t("reports.generate")}
                            </ColorButton>
                        </Box>
                    </>
                )}
            </LocalizationProvider>
            <NewPeriodDialog
                open={dialogNewPeriod}
                onClose={closeDialog}
                message={dialogBody}
                title={t("services.newPeriod")}
                button={dialogButtons}
                maxWidth="xs"
            />
            <DialogAlerts
                open={open}
                onClose={handleCloseDialog}
                agreeBtnLabel={t("miscellaneous.delete")}
                disagreeBtnLabel="No"
                type={"info"}
                title={t("miscellaneous.confRemoveCheck")}
                message={bodyMess}
                agreeAction={removeCheck}
            />
        </StyledContainer>
    );
};
