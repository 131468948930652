// React
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { NumericFormat } from "react-number-format";

// MUI icons
import EditIcon from "@mui/icons-material/Edit";
import EditOffIcon from "@mui/icons-material/EditOff";

import {
    Alert,
    Box,
    Card,
    CardContent,
    CardHeader,
    Divider,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    TextField,
    FormControlLabel,
    Switch,
    Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import GridUI from "@mui/material/Grid";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

/** Component imports */
import { UpdateAdd } from "./";
import DialogAlerts from "../../../DialogAlerts";

const PREFIX = "CatchUp";

const classes = {
    btnRoot: `${PREFIX}-btnRoot`,
    buttonContainer: `${PREFIX}-buttonContainer`,
    tableStriped: `${PREFIX}-tableStriped`,
    tableHeader: `${PREFIX}-tableHeader`,
    tableBody: `${PREFIX}-tableBody`,
    tableBody2: `${PREFIX}-tableBody2`,
    pager: `${PREFIX}-pager`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.btnRoot}`]: {
        border: "1px solid #ff5968",
        borderRadius: 40,
        "&:hover": {
            backgroundColor: "#2f3190",
            border: "1px solid #2f3190",
        },
    },
    [`& .${classes.buttonContainer}`]: {
        display: "flex",
        justifyContent: "flex-end",
        padding: 15,
    },

    [`& .${classes.tableStriped}`]: {
        "& tbody tr:nth-of-type(odd)": {
            backgroundColor: "rgba(47,49,144,0.1)",
        },
    },
    [`& .${classes.tableHeader}`]: {
        fontSize: "15px",
        fontWeight: 600,
        color: "#031851",
        borderBottom: "2px solid #031851",
    },
    [`& .${classes.tableBody}`]: {
        fontSize: "14px",
        fontWeight: 400,
        color: "#031851",
        padding: "5px",
    },
    [`& .${classes.tableBody2}`]: {
        padding: "5px",
        textAlign: "right",
    },
    [`& .${classes.pager}`]: {
        "& .Pagination-button": {
            paddingTop: 0,
            paddingBottom: 0,
            color: "#031851",
            fontWeight: 600,
        },
        "& .Pagination-activeButton": {
            backgroundColor: "rgba(47,49,144,0.3)",
        },
    },
}));

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "rgba(19, 31, 62, 0.80)",
        fontSize: 12,
        maxWidth: 400,
        borderRadius: 6,
        fontWeight: "normal",
    },
});

const NumberFormatCustom = (props) => {
    const todos = useSelector((state) => state.todos);

    const { inputRef, value, onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            value={value}
            getInputRef={inputRef}
            onValueChange={({ value: v }) => onChange({ target: { value: v } })}
            thousandSeparator={todos.amountFormat === "de-DE" ? "." : ","}
            decimalSeparator={todos.amountFormat === "de-DE" ? "," : "."}
            fixedDecimalScale={true}
            decimalScale={2}
            valueIsNumericString
        />
    );
};

const defaultState = {
    projectCompanyId: undefined,
    updateBoolean: false,
    baseFee: 0,
    months: 0,
    projectFee: 0,
    discount: 10,
    projecTot: 0,
    discountAdd: 10,
    discountOG: 10,
    discountAddOG: 10,
    // Responsabilidad del servicio
    initDate: null,
    endDate: null,
    estDeliDate: null,
    deliDate: null,
    // Adicionales
    // CFO
    checkHour: false,
    checkedCFO: false,
    tothCFO: 0,
    startDateCFO: null,
    finishDateCFO: null,
    // Gobierno corporativo
    checkedCorp: false,
    tothCorp: 0,
    startDateCorp: null,
    finishDateCorp: null,
    // Presentaciones financieras
    checkedPre: false,
    tothPre: 0,
    startDatePre: null,
    finishDatePre: null,
    // Reportes para inversionistas
    checkedRep: false,
    tothRep: 0,
    startDateRep: null,
    finishDateRep: null,
    // Otros
    checkedOthers: false,
    nameOthers: "",
    tothOthers: 0,
    startDateOthers: null,
    finishDateOthers: null,
    // Total
    addHourFee: 0,
};

const latam = [
    "Argentina",
    "Bolivia",
    "Brasil",
    "Chile",
    "Colombia",
    "Costa Rica",
    "Cuba",
    "Ecuador",
    "El Salvador",
    "Guatemala",
    "Haití",
    "Honduras",
    "México",
    "Nicaragua",
    "Panamá",
    "Paraguay",
    "Perú",
    "República Dominicana",
    "Uruguay",
];

export const CatchUp = (props) => {
    // Properties
    const {
        cfoSystem,
        clientMarket,
        companyID,
        data,
        expenseEst,
        reloadProjects,
        serviceID,
        setalertType,
        setBackDrop,
        setShow,
        setTexto,
        handleCloseDialog,
        infoAdd,
        checkRemind,
        open,
        bodyMess,
        showConfirm,
    } = props;

    /** Internationalization i18n */
    const [t] = useTranslation("global");

    /** React redux */
    const todos = useSelector((state) => state.todos);
    const dispatch = useDispatch();

    /** Component states */
    const [valuesCatchUp, setCatchUp] = useState(defaultState);
    const [editMode, setEditMode] = useState(true);
    const [editMode2, setEditMode2] = useState(true);
    const [isError, setIsError] = useState(false);

    /** Component functions */
    useEffect(() => {
        let cfoAdditionalData = {};
        let corpAdditionalData = {};
        let preAdditionalData = {};
        let repAdditionalData = {};
        let othersAdditionalData = {};
        const proData = data.projects_array && data.projects_array[0];

        const feeData = clientMarket === "Venezuela" ? 150 : latam.includes(clientMarket) ? 175 : 250;

        if (proData) {
            if (proData.additionals && proData.additionals.length !== 0) {
                proData.additionals.forEach((element) => {
                    if (element.additional_name === "CFO") {
                        cfoAdditionalData = {
                            since_date: element.since_date,
                            end_date: element.end_date,
                            hours: element.hours,
                            update_hours: element.update_hours,
                        };
                    }
                    if (element.additional_name === "Gobierno corporativo") {
                        corpAdditionalData = {
                            since_date: element.since_date,
                            end_date: element.end_date,
                            hours: element.hours,
                        };
                    }
                    if (element.additional_name === "Presentaciones financieras") {
                        preAdditionalData = {
                            since_date: element.since_date,
                            end_date: element.end_date,
                            hours: element.hours,
                        };
                    }
                    if (element.additional_name === "Reportes para inversionistas") {
                        repAdditionalData = {
                            since_date: element.since_date,
                            end_date: element.end_date,
                            hours: element.hours,
                        };
                    }
                    if (element.additional_name === "Others") {
                        othersAdditionalData = {
                            since_date: element.since_date,
                            end_date: element.end_date,
                            hours: element.hours,
                            other_name: element.other_name,
                        };
                    }
                });
            }

            let meses = 0;
            let cfoMonths = 0;

            if (proData.since_date) {
                meses = Math.ceil(moment(proData.end_date).diff(moment(proData.since_date), "months", true));
            }
            if (proData.additionals_only) {
                cfoMonths = Math.ceil(
                    moment(cfoAdditionalData.end_date).diff(moment(cfoAdditionalData.since_date), "months", true)
                );
            }

            setCatchUp({
                projectCompanyId: proData.project_company_id,
                updateBoolean: data.update_boolean ?? false,
                baseFee: proData.additionals_only ? feeData * cfoAdditionalData.hours : proData.fee,
                months: proData.additionals_only ? cfoMonths : meses,
                projectFee: proData.additionals_only
                    ? cfoMonths * (cfoAdditionalData.hours ?? 0) * 150
                    : proData.fee * meses,
                discount: proData.discount ?? 10,
                discountOG: proData.discount ?? 10,
                projecTot: proData.additionals_only
                    ? cfoMonths * (cfoAdditionalData.hours ?? 0) * 150 * (1 - (proData.discount ?? 10) / 100)
                    : proData.fee * meses * (1 - (proData.discount ?? 10) / 100),
                discountAdd: proData.additional_discount ?? 10,
                discountAddOG: proData.additional_discount ?? 10,
                // Responsabilidad del servicio
                initDate: proData.since_date ? moment(proData.since_date).utc() : null,
                endDate: proData.end_date ? moment(proData.end_date).utc() : null,
                estDeliDate: proData.client_commitment_date ? moment(proData.client_commitment_date).utc() : null,
                deliDate: proData.client_delivery_date ? moment(proData.client_delivery_date).utc() : null,
                // Adicionales
                // CFO
                checkHour: proData.additionals_only,
                checkedCFO: Object.keys(cfoAdditionalData).length !== 0,
                tothCFO: cfoAdditionalData.hours ?? 0,
                startDateCFO: !cfoAdditionalData.since_date ? null : moment(cfoAdditionalData.since_date).utc(),
                finishDateCFO: !cfoAdditionalData.end_date ? null : moment(cfoAdditionalData.end_date).utc(),
                // Gobierno corporativo
                checkedCorp: Object.keys(corpAdditionalData).length !== 0,
                checkedCorpOG: Object.keys(corpAdditionalData).length !== 0,
                tothCorp: corpAdditionalData.hours ?? 0,
                startDateCorp: !corpAdditionalData.since_date ? null : moment(corpAdditionalData.since_date).utc(),
                finishDateCorp: !corpAdditionalData.end_date ? null : moment(corpAdditionalData.end_date).utc(),
                // Presentaciones financieras
                checkedPre: Object.keys(preAdditionalData).length !== 0,
                checkedPreOG: Object.keys(preAdditionalData).length !== 0,
                tothPre: preAdditionalData.hours ?? 0,
                startDatePre: !preAdditionalData.since_date ? null : moment(preAdditionalData.since_date).utc(),
                finishDatePre: !preAdditionalData.end_date ? null : moment(preAdditionalData.end_date).utc(),
                // Reportes para inversionistas
                checkedRep: Object.keys(repAdditionalData).length !== 0,
                checkedRepOG: Object.keys(repAdditionalData).length !== 0,
                tothRep: repAdditionalData.hours ?? 0,
                startDateRep: !repAdditionalData.since_date ? null : moment(repAdditionalData.since_date).utc(),
                finishDateRep: !repAdditionalData.end_date ? null : moment(repAdditionalData.end_date).utc(),
                // Otros
                checkedOthers: Object.keys(othersAdditionalData).length !== 0,
                checkedOthersOG: Object.keys(othersAdditionalData).length !== 0,
                nameOthers: othersAdditionalData.other_name ?? "",
                tothOthers: othersAdditionalData.hours ?? 0,
                startDateOthers: !othersAdditionalData.since_date
                    ? null
                    : moment(othersAdditionalData.since_date).utc(),
                finishDateOthers: !othersAdditionalData.end_date ? null : moment(othersAdditionalData.end_date).utc(),
                // Total
                addHourFee: feeData,
            });
        } else {
            setCatchUp({
                ...valuesCatchUp,
                addHourFee: feeData,
                baseFee: data.fee,
                updateBoolean: false,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const removeCheck = () => {
        setCatchUp({
            ...valuesCatchUp,
            [infoAdd.check]: false,
            [infoAdd.toth]: 0,
            nameOthers: infoAdd.nameOthers ? "" : valuesCatchUp.nameOthers,
            [infoAdd.startDate]: null,
            [infoAdd.finishDate]: null,
        });
        if (checkRemind) {
            dispatch({
                type: "addRemind",
                item: true,
            });
        }
        handleCloseDialog();
    };

    const getSumTotal = () => {
        let sumTotal =
            parseFloat(valuesCatchUp.tothCorp) *
                (isNaN(moment(valuesCatchUp.finishDateCorp).diff(moment(valuesCatchUp.startDateCorp)))
                    ? 0
                    : Math.ceil(
                          moment(valuesCatchUp.finishDateCorp).diff(moment(valuesCatchUp.startDateCorp), "months", true)
                      )) +
            parseFloat(valuesCatchUp.tothPre) *
                (isNaN(moment(valuesCatchUp.finishDatePre).diff(moment(valuesCatchUp.startDatePre)))
                    ? 0
                    : Math.ceil(
                          moment(valuesCatchUp.finishDatePre).diff(moment(valuesCatchUp.startDatePre), "months", true)
                      )) +
            parseFloat(valuesCatchUp.tothRep) *
                (isNaN(moment(valuesCatchUp.finishDateRep).diff(moment(valuesCatchUp.startDateRep)))
                    ? 0
                    : Math.ceil(
                          moment(valuesCatchUp.finishDateRep).diff(moment(valuesCatchUp.startDateRep), "months", true)
                      )) +
            parseFloat(valuesCatchUp.tothOthers) *
                (isNaN(moment(valuesCatchUp.finishDateOthers).diff(moment(valuesCatchUp.startDateOthers)))
                    ? 0
                    : Math.ceil(
                          moment(valuesCatchUp.finishDateOthers).diff(
                              moment(valuesCatchUp.startDateOthers),
                              "months",
                              true
                          )
                      ));

        return sumTotal;
    };

    const handleChangeDiscount = (event) => {
        let discountAux = 0;

        if (event.target.name === "discount") {
            discountAux =
                event.target.value >= 0 && event.target.value <= 10 ? event.target.value : valuesCatchUp.discount;

            setCatchUp({
                ...valuesCatchUp,
                discount: discountAux,
                projecTot: valuesCatchUp.projectFee * (1 - discountAux / 100),
            });
        } else {
            discountAux =
                event.target.value >= 0 && event.target.value <= 10 ? event.target.value : valuesCatchUp.discountAdd;

            setCatchUp({
                ...valuesCatchUp,
                discountAdd: discountAux,
            });
        }
    };

    const updateProject = async () => {
        setBackDrop(true);

        if (valuesCatchUp.initDate && valuesCatchUp.endDate && valuesCatchUp.estDeliDate) {
            let additionalsF = [];

            if (valuesCatchUp.checkedCFO) {
                if (valuesCatchUp.startDateCFO && valuesCatchUp.finishDateCFO) {
                    if (valuesCatchUp.tothCFO > 0) {
                        additionalsF.push({
                            additional_name: "CFO",
                            hours: parseFloat(valuesCatchUp.tothCFO),
                            since_date: moment(valuesCatchUp.startDateCFO).startOf("day").format(),
                            end_date: moment(valuesCatchUp.finishDateCFO).startOf("day").format(),
                        });
                    } else {
                        setTexto(t("services.hoursValidationError"));
                        setalertType("warning");
                        setShow(true);
                        setBackDrop(false);
                        setIsError(true);
                        return;
                    }
                } else {
                    setTexto(t("miscellaneous.requiredData"));
                    setalertType("warning");
                    setShow(true);
                    setBackDrop(false);
                    setIsError(true);
                    return;
                }
            }
            if (valuesCatchUp.checkedCorp) {
                if (valuesCatchUp.startDateCorp && valuesCatchUp.finishDateCorp) {
                    if (valuesCatchUp.tothCorp > 0) {
                        additionalsF.push({
                            additional_name: "Gobierno corporativo",
                            hours: parseFloat(valuesCatchUp.tothCorp),
                            since_date: moment(valuesCatchUp.startDateCorp).startOf("day").format(),
                            end_date: moment(valuesCatchUp.finishDateCorp).startOf("day").format(),
                        });
                    } else {
                        setTexto(t("services.hoursValidationError"));
                        setalertType("warning");
                        setShow(true);
                        setBackDrop(false);
                        setIsError(true);
                        return;
                    }
                } else {
                    setTexto(t("miscellaneous.requiredData"));
                    setalertType("warning");
                    setShow(true);
                    setBackDrop(false);
                    setIsError(true);
                    return;
                }
            }
            if (valuesCatchUp.checkedPre) {
                if (valuesCatchUp.startDatePre && valuesCatchUp.finishDatePre) {
                    if (valuesCatchUp.tothPre > 0) {
                        additionalsF.push({
                            additional_name: "Presentaciones financieras",
                            hours: parseFloat(valuesCatchUp.tothPre),
                            since_date: moment(valuesCatchUp.startDatePre).startOf("day").format(),
                            end_date: moment(valuesCatchUp.finishDatePre).startOf("day").format(),
                        });
                    } else {
                        setTexto(t("services.hoursValidationError"));
                        setalertType("warning");
                        setShow(true);
                        setBackDrop(false);
                        setIsError(true);
                        return;
                    }
                } else {
                    setTexto(t("miscellaneous.requiredData"));
                    setalertType("warning");
                    setShow(true);
                    setBackDrop(false);
                    setIsError(true);
                    return;
                }
            }
            if (valuesCatchUp.checkedRep) {
                if (valuesCatchUp.startDateRep && valuesCatchUp.finishDateRep) {
                    if (valuesCatchUp.tothRep > 0) {
                        additionalsF.push({
                            additional_name: "Reportes para inversionistas",
                            hours: parseFloat(valuesCatchUp.tothRep),
                            since_date: moment(valuesCatchUp.startDateRep).startOf("day").format(),
                            end_date: moment(valuesCatchUp.finishDateRep).startOf("day").format(),
                        });
                    } else {
                        setTexto(t("services.hoursValidationError"));
                        setalertType("warning");
                        setShow(true);
                        setBackDrop(false);
                        setIsError(true);
                        return;
                    }
                } else {
                    setTexto(t("miscellaneous.requiredData"));
                    setalertType("warning");
                    setShow(true);
                    setBackDrop(false);
                    setIsError(true);
                    return;
                }
            }
            if (valuesCatchUp.checkedOthers) {
                if (valuesCatchUp.startDateOthers && valuesCatchUp.finishDateOthers && valuesCatchUp.nameOthers) {
                    if (valuesCatchUp.tothOthers > 0) {
                        additionalsF.push({
                            additional_name: "Others",
                            hours: parseFloat(valuesCatchUp.tothOthers),
                            since_date: moment(valuesCatchUp.startDateOthers).startOf("day").format(),
                            end_date: moment(valuesCatchUp.finishDateOthers).startOf("day").format(),
                            other_name: valuesCatchUp.nameOthers,
                        });
                    } else {
                        setTexto(t("services.hoursValidationError"));
                        setalertType("warning");
                        setShow(true);
                        setBackDrop(false);
                        setIsError(true);
                        return;
                    }
                } else {
                    setTexto(t("miscellaneous.requiredData"));
                    setalertType("warning");
                    setShow(true);
                    setBackDrop(false);
                    setIsError(true);
                    return;
                }
            }

            let params = {
                company_id: companyID,
                service_id: serviceID,
                project_id: data.project_id,
                project_company_id: valuesCatchUp.projectCompanyId,
                fee: valuesCatchUp.baseFee,
                total_fee:
                    valuesCatchUp.projecTot +
                    valuesCatchUp.addHourFee * getSumTotal() * (1 - valuesCatchUp.discountAdd / 100),
                discount: parseFloat(valuesCatchUp.discount),
                additional_discount: parseFloat(valuesCatchUp.discountAdd),
                since_date: moment(valuesCatchUp.initDate).startOf("day").format(),
                end_date: moment(valuesCatchUp.endDate).startOf("day").format(),
                client_commitment_date: moment(valuesCatchUp.estDeliDate).startOf("day").format(),
                client_delivery_date: valuesCatchUp.deliDate
                    ? moment(valuesCatchUp.deliDate).startOf("day").format()
                    : null,
                additionals_only: valuesCatchUp.checkHour,
                additionals: additionalsF,
            };

            await fetch(`/addServicesAndProjects`, {
                method: "POST",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
                body: JSON.stringify(params),
            })
                .then((response) => {
                    if (!response.ok) {
                        // get error message from body or default to response status
                        const error = response.status;
                        throw new Error(error);
                    }

                    if (response.status === 200) {
                        setTexto(t("miscellaneous.successfulUpdate"));
                        setalertType("success");
                        setShow(true);
                        reloadProjects(true);
                    } else if (response.status === 201) {
                        setTexto(t("services.notPossibleGenProject"));
                        setalertType("error");
                        setShow(true);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            setTexto(t("miscellaneous.requiredData"));
            setalertType("warning");
            setShow(true);
            setIsError(true);
        }

        setBackDrop(false);
    };

    const handleChangeDeliDate = (date) => {
        if (!date) {
            setCatchUp({
                ...valuesCatchUp,
                deliDate: date,
            });
        } else {
            if (date.isSameOrBefore(moment(valuesCatchUp.endDate).format("YYYY-MM-DD"))) {
                setCatchUp({
                    ...valuesCatchUp,
                    deliDate: null,
                });

                setTexto(t("reports.selectEntDate"));
                setalertType("warning");
                setShow(true);
            } else {
                setCatchUp({
                    ...valuesCatchUp,
                    deliDate: date,
                });
            }
        }
    };

    const handleChangeEstDeliDate = (date) => {
        if (!date) {
            setCatchUp({
                ...valuesCatchUp,
                estDeliDate: date,
            });
        } else {
            if (date.isSameOrBefore(moment(valuesCatchUp.endDate).format("YYYY-MM-DD"))) {
                setCatchUp({
                    ...valuesCatchUp,
                    estDeliDate: null,
                });

                setTexto(t("reports.selectEntDate"));
                setalertType("warning");
                setShow(true);
            } else {
                setCatchUp({
                    ...valuesCatchUp,
                    estDeliDate: date,
                });
            }
        }
    };

    const handleChangeInitDate = (date) => {
        let startDate = moment(date).startOf("month");

        if (!date || !valuesCatchUp.endDate) {
            setCatchUp({
                ...valuesCatchUp,
                initDate: date ? startDate : date,
            });
        } else {
            if (startDate.isAfter(moment(valuesCatchUp.endDate).format("YYYY-MM-DD"))) {
                setCatchUp({
                    ...valuesCatchUp,
                    initDate: null,
                });

                setTexto(t("reports.initDateMess"));
                setalertType("warning");
                setShow(true);
            } else {
                let meses = Math.ceil(valuesCatchUp.endDate.diff(startDate, "months", true));

                let cfoMonths = 0;
                if (valuesCatchUp.checkHour) {
                    cfoMonths = Math.ceil(moment(valuesCatchUp.finishDateCFO).diff(startDate, "months", true));
                }

                setCatchUp({
                    ...valuesCatchUp,
                    initDate: startDate,
                    months: valuesCatchUp.checkHour ? cfoMonths : meses,
                    projectFee: valuesCatchUp.checkHour
                        ? cfoMonths * (valuesCatchUp.tothCFO ?? 0) * 150
                        : data.fee * meses,
                    projecTot: valuesCatchUp.checkHour
                        ? cfoMonths * (valuesCatchUp.tothCFO ?? 0) * 150 * (1 - (valuesCatchUp.discount ?? 10) / 100)
                        : data.fee * meses * (1 - valuesCatchUp.discount / 100),
                    // Adicionales
                    startDateCFO: valuesCatchUp.checkedCFO ? startDate : null,
                    startDateCorp: valuesCatchUp.checkedCorp ? startDate : null,
                    startDatePre: valuesCatchUp.checkedPre ? startDate : null,
                    startDateRep: valuesCatchUp.checkedRep ? startDate : null,
                    // Otros
                    startDateOthers: valuesCatchUp.checkedOthers ? startDate : null,
                });
            }
        }
    };

    const handleChangeEndDate = (date) => {
        let endDate = moment(date).endOf("month").startOf("day");

        if (!date || !valuesCatchUp.initDate) {
            setCatchUp({
                ...valuesCatchUp,
                endDate: date ? endDate : date,
            });
        } else {
            if (endDate.isBefore(moment(valuesCatchUp.initDate).format("YYYY-MM-DD"))) {
                setCatchUp({
                    ...valuesCatchUp,
                    endDate: null,
                });

                setTexto(t("reports.endDateMess"));
                setalertType("warning");
                setShow(true);
            } else {
                let meses = Math.ceil(endDate.diff(valuesCatchUp.initDate, "months", true));

                let cfoMonths = 0;
                if (valuesCatchUp.checkHour) {
                    cfoMonths = Math.ceil(endDate.diff(moment(valuesCatchUp.startDateCFO), "months", true));
                }

                setCatchUp({
                    ...valuesCatchUp,
                    endDate: endDate,
                    months: valuesCatchUp.checkHour ? cfoMonths : meses,
                    projectFee: valuesCatchUp.checkHour
                        ? cfoMonths * (valuesCatchUp.tothCFO ?? 0) * 150
                        : data.fee * meses,
                    projecTot: valuesCatchUp.checkHour
                        ? cfoMonths * (valuesCatchUp.tothCFO ?? 0) * 150 * (1 - (valuesCatchUp.discount ?? 10) / 100)
                        : data.fee * meses * (1 - valuesCatchUp.discount / 100),
                    // Adicionales
                    finishDateCFO: valuesCatchUp.checkedCFO ? endDate : null,
                    finishDateCorp: valuesCatchUp.checkedCorp ? endDate : null,
                    finishDatePre: valuesCatchUp.checkedPre ? endDate : null,
                    finishDateRep: valuesCatchUp.checkedRep ? endDate : null,
                    // Otros
                    finishDateOthers: valuesCatchUp.checkedOthers ? endDate : null,
                });
            }
        }
    };

    const handleMouseDownDiscount = (event) => {
        event.preventDefault();
    };

    const handleClickShowDiscount = () => {
        setEditMode(!editMode);
    };

    const handleClickShowDiscount2 = () => {
        setEditMode2(!editMode2);
    };

    const handleChangeSwitch = (event) => {
        let meses = 0;

        if (valuesCatchUp.initDate) {
            meses = isNaN(moment(valuesCatchUp.endDate).diff(moment(valuesCatchUp.initDate)))
                ? 0
                : Math.ceil(moment(valuesCatchUp.endDate).diff(moment(valuesCatchUp.initDate), "months", true));
        }

        setCatchUp({
            ...valuesCatchUp,
            months: meses,
            checkHour: event.target.checked,
            checkedCFO: event.target.checked,
            tothCFO: 0,
            startDateCFO: event.target.checked ? valuesCatchUp.initDate : null,
            finishDateCFO: event.target.checked ? valuesCatchUp.endDate : null,
            projectFee: event.target.checked ? 0 : data.fee * meses,
            projecTot: event.target.checked ? 0 : data.fee * meses * (1 - valuesCatchUp.discount / 100),
            discount: event.target.checked ? 0 : valuesCatchUp.discountOG,
            discountAdd: event.target.checked ? 0 : valuesCatchUp.discountAddOG,
        });
    };

    return (
        <StyledContainer>
            <LocalizationProvider
                dateAdapter={AdapterMoment}
                adapterLocale={t("language.locale")}
                localeText={{ clearButtonLabel: t("miscellaneous.clear") }}
            >
                <Card elevation={0}>
                    <CardHeader
                        title={
                            <Box display="flex" sx={{ alignItems: "center" }}>
                                CFO catchup
                                <FormControlLabel
                                    sx={{ ml: 1 }}
                                    control={
                                        <Switch
                                            checked={valuesCatchUp.checkHour}
                                            disabled={
                                                valuesCatchUp.updateBoolean ||
                                                !valuesCatchUp.initDate ||
                                                !valuesCatchUp.endDate
                                            }
                                            inputProps={{
                                                "aria-label": "controlled",
                                            }}
                                            color="secondary"
                                            onChange={handleChangeSwitch}
                                            className={classes.switch}
                                        />
                                    }
                                    label={
                                        <Typography variant="body1" color="textPrimary">
                                            {t("services.hourlyInvoicing")}
                                        </Typography>
                                    }
                                />
                            </Box>
                        }
                    />
                    <Divider />
                    <CardContent>
                        {valuesCatchUp.updateBoolean && (
                            <GridUI container spacing={2} justifyContent="center" sx={{ paddingBottom: 3 }}>
                                <GridUI item>
                                    <Alert
                                        severity="warning"
                                        sx={{
                                            borderRadius: 8,
                                            fontSize: 12,
                                            fontWeight: 600,
                                        }}
                                    >
                                        {t("services.notPossibleEditProject")}
                                    </Alert>
                                </GridUI>
                            </GridUI>
                        )}
                        {cfoSystem.disabledData === true &&
                            (cfoSystem.prov === "Xero" || cfoSystem.prov === "Quickbooks Online") && (
                                <GridUI container spacing={2} justifyContent="center" sx={{ paddingBottom: 3 }}>
                                    <GridUI item>
                                        <Alert
                                            severity="warning"
                                            sx={{
                                                borderRadius: 8,
                                                fontSize: 12,
                                                fontWeight: 600,
                                            }}
                                        >
                                            {t("services.enterValidCredentials")}
                                        </Alert>
                                    </GridUI>
                                </GridUI>
                            )}
                        {expenseEst === -1 && (
                            <GridUI container spacing={2} justifyContent="center" sx={{ paddingBottom: 3 }}>
                                <GridUI item>
                                    <Alert
                                        severity="warning"
                                        sx={{
                                            borderRadius: 8,
                                            fontSize: 12,
                                            fontWeight: 600,
                                        }}
                                    >
                                        {t("services.ableToSaveInfo")}
                                    </Alert>
                                </GridUI>
                            </GridUI>
                        )}
                        <GridUI container alignItems="center" spacing={2}>
                            <GridUI item xs={12} sm={6} lg={2}>
                                <FormControl fullWidth variant="outlined" disabled>
                                    <InputLabel htmlFor="outlined-adornment-baseFee">
                                        {t("services.baseRate")}
                                    </InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-baseFee"
                                        value={valuesCatchUp.baseFee}
                                        inputComponent={NumberFormatCustom}
                                        startAdornment={
                                            <InputAdornment disableTypography position="start">
                                                $
                                            </InputAdornment>
                                        }
                                        label={t("services.baseRate")}
                                    />
                                </FormControl>
                            </GridUI>
                            <GridUI item xs={12} sm={6} lg={1}>
                                <TextField
                                    disabled
                                    fullWidth
                                    label={t("bankingDashboard.months")}
                                    value={valuesCatchUp.months}
                                    name="months"
                                    variant="outlined"
                                    type="number"
                                    InputProps={{ inputProps: { min: 0 } }}
                                />
                            </GridUI>
                            <GridUI item xs={12} sm={6} lg={3}>
                                <FormControl fullWidth variant="outlined" disabled>
                                    <InputLabel htmlFor="outlined-adornment-projectFee">
                                        {t("services.projectFee")}
                                    </InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-projectFee"
                                        value={valuesCatchUp.projectFee}
                                        inputComponent={NumberFormatCustom}
                                        startAdornment={
                                            <InputAdornment disableTypography position="start">
                                                $
                                            </InputAdornment>
                                        }
                                        label={t("services.projectFee")}
                                    />
                                </FormControl>
                            </GridUI>
                            <GridUI item xs={12} sm={6} lg={3}>
                                <FormControl variant="outlined" disabled={editMode} fullWidth>
                                    <InputLabel htmlFor="outlined-adornment-discount">
                                        {t("miscellaneous.discount")}
                                    </InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-discount"
                                        type="number"
                                        name="discount"
                                        value={valuesCatchUp.discount}
                                        onChange={handleChangeDiscount}
                                        onBlur={() =>
                                            setCatchUp({
                                                ...valuesCatchUp,
                                                discount: parseFloat(
                                                    valuesCatchUp.discount === "" ? 0 : valuesCatchUp.discount
                                                ).toFixed(2),
                                            })
                                        }
                                        inputProps={{
                                            min: 0,
                                            max: 10,
                                        }}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                %
                                                <LightTooltip
                                                    title={
                                                        editMode
                                                            ? t("services.catchupMaximunDiscount10")
                                                            : t("miscellaneous.save")
                                                    }
                                                    aria-label="edit"
                                                >
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        disabled={valuesCatchUp.updateBoolean}
                                                        onClick={handleClickShowDiscount}
                                                        onMouseDown={handleMouseDownDiscount}
                                                        edge="end"
                                                    >
                                                        {!editMode ? <EditOffIcon /> : <EditIcon />}
                                                    </IconButton>
                                                </LightTooltip>
                                            </InputAdornment>
                                        }
                                        label={t("miscellaneous.discount")}
                                        sx={{
                                            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                                display: "none",
                                            },
                                        }}
                                    />
                                </FormControl>
                            </GridUI>
                            <GridUI item xs={12} sm={6} lg={3}>
                                <FormControl fullWidth variant="outlined" disabled>
                                    <InputLabel htmlFor="outlined-adornment-projecTot">
                                        {t("services.projectTot")}
                                    </InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-projecTot"
                                        value={valuesCatchUp.projecTot}
                                        inputComponent={NumberFormatCustom}
                                        startAdornment={
                                            <InputAdornment disableTypography position="start">
                                                $
                                            </InputAdornment>
                                        }
                                        label={t("services.projectTot")}
                                    />
                                </FormControl>
                            </GridUI>
                            <GridUI item xs={12} sm={6} lg={3}></GridUI>
                            <GridUI item xs={12} sm={6} lg={3}>
                                <FormControl fullWidth variant="outlined" disabled>
                                    <InputLabel htmlFor="outlined-adornment-feeAdd">
                                        {t("services.hourlyRateAdd")}
                                    </InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-feeAdd"
                                        value={valuesCatchUp.addHourFee * getSumTotal()}
                                        inputComponent={NumberFormatCustom}
                                        startAdornment={
                                            <InputAdornment disableTypography position="start">
                                                $
                                            </InputAdornment>
                                        }
                                        label={t("services.hourlyRateAdd")}
                                    />
                                </FormControl>
                            </GridUI>
                            <GridUI item xs={12} sm={6} lg={3}>
                                <FormControl variant="outlined" disabled={editMode2} fullWidth>
                                    <InputLabel htmlFor="outlined-adornment-discount">
                                        {t("miscellaneous.discount")}
                                    </InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-discount"
                                        type="number"
                                        name="discountAdd"
                                        value={valuesCatchUp.discountAdd}
                                        onChange={handleChangeDiscount}
                                        onBlur={() =>
                                            setCatchUp({
                                                ...valuesCatchUp,
                                                discountAdd: parseFloat(
                                                    valuesCatchUp.discountAdd === "" ? 0 : valuesCatchUp.discountAdd
                                                ).toFixed(2),
                                            })
                                        }
                                        inputProps={{
                                            min: 0,
                                            max: 10,
                                        }}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                %
                                                <LightTooltip
                                                    title={
                                                        editMode2
                                                            ? t("services.catchupMaximunDiscount10")
                                                            : t("miscellaneous.save")
                                                    }
                                                    aria-label="edit"
                                                >
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        disabled={valuesCatchUp.updateBoolean}
                                                        onClick={handleClickShowDiscount2}
                                                        onMouseDown={handleMouseDownDiscount}
                                                        edge="end"
                                                    >
                                                        {!editMode2 ? <EditOffIcon /> : <EditIcon />}
                                                    </IconButton>
                                                </LightTooltip>
                                            </InputAdornment>
                                        }
                                        label={t("miscellaneous.discount")}
                                        sx={{
                                            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                                display: "none",
                                            },
                                        }}
                                    />
                                </FormControl>
                            </GridUI>
                            <GridUI item xs={12} sm={6} lg={3}>
                                <FormControl fullWidth variant="outlined" disabled>
                                    <InputLabel htmlFor="outlined-adornment-totalAdd">
                                        {t("clientsTable.totalAdd")}
                                    </InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-totalAdd"
                                        value={
                                            valuesCatchUp.addHourFee *
                                            getSumTotal() *
                                            (1 - valuesCatchUp.discountAdd / 100)
                                        }
                                        inputComponent={NumberFormatCustom}
                                        startAdornment={
                                            <InputAdornment disableTypography position="start">
                                                $
                                            </InputAdornment>
                                        }
                                        label={t("clientsTable.totalAdd")}
                                    />
                                </FormControl>
                            </GridUI>
                            <GridUI item xs={12} sm={6} lg={9}></GridUI>
                            <GridUI item xs={12} sm={6} lg={3}>
                                <FormControl fullWidth variant="outlined" disabled>
                                    <InputLabel htmlFor="outlined-adornment-feeTotal">
                                        {t("proposals.feeTotal")}
                                    </InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-feeTotal"
                                        value={
                                            valuesCatchUp.projecTot +
                                            valuesCatchUp.addHourFee *
                                                getSumTotal() *
                                                (1 - valuesCatchUp.discountAdd / 100)
                                        }
                                        inputComponent={NumberFormatCustom}
                                        startAdornment={
                                            <InputAdornment disableTypography position="start">
                                                $
                                            </InputAdornment>
                                        }
                                        label={t("proposals.feeTotal")}
                                    />
                                </FormControl>
                            </GridUI>
                        </GridUI>
                    </CardContent>
                </Card>
                <Card elevation={0}>
                    <CardHeader title={t("services.serviceResponsibility")} sx={{ color: "#ff5968" }} />
                    <Divider />
                    <CardContent>
                        <GridUI container alignItems="center" spacing={2}>
                            <GridUI item xs={12} sm={6} lg={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    disabled={valuesCatchUp.updateBoolean}
                                    value={valuesCatchUp.initDate}
                                    onChange={(newValue) => handleChangeInitDate(newValue)}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            required
                                            {...params}
                                            error={isError && valuesCatchUp.initDate === null}
                                        />
                                    )}
                                    inputProps={{ readOnly: true }}
                                    label={t("reports.initDate")}
                                    views={["year", "month"]}
                                />
                            </GridUI>
                            <GridUI item xs={12} sm={6} lg={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    disabled={valuesCatchUp.updateBoolean}
                                    value={valuesCatchUp.endDate}
                                    onChange={(newValue) => handleChangeEndDate(newValue)}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            required
                                            {...params}
                                            error={isError && valuesCatchUp.endDate === null}
                                        />
                                    )}
                                    inputProps={{ readOnly: true }}
                                    label={t("reports.endDate")}
                                    views={["year", "month"]}
                                />
                            </GridUI>
                            <GridUI item xs={12} sm={6} lg={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    disabled={valuesCatchUp.updateBoolean}
                                    value={valuesCatchUp.estDeliDate}
                                    onChange={(newValue) => handleChangeEstDeliDate(newValue)}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            required
                                            {...params}
                                            error={isError && valuesCatchUp.estDeliDate === null}
                                        />
                                    )}
                                    inputProps={{ readOnly: true }}
                                    label={t("services.estimDeliveryDate")}
                                    views={["year", "month", "day"]}
                                />
                            </GridUI>
                            <GridUI item xs={12} sm={6} lg={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    disableFuture
                                    value={valuesCatchUp.deliDate}
                                    onChange={(newValue) => handleChangeDeliDate(newValue)}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => <TextField fullWidth {...params} />}
                                    inputProps={{ readOnly: true }}
                                    label={t("services.deliveryDate")}
                                    views={["year", "month", "day"]}
                                />
                            </GridUI>
                        </GridUI>
                    </CardContent>
                </Card>
                <UpdateAdd
                    values={valuesCatchUp}
                    set={setCatchUp}
                    setTexto={setTexto}
                    setalertType={setalertType}
                    setShow={setShow}
                    clientMarket={clientMarket}
                    isError={isError}
                    updateProject={updateProject}
                    sumTotal={getSumTotal()}
                    originalData={data}
                    cfoSystem={cfoSystem}
                    expenseEst={expenseEst}
                    showConfirm={showConfirm}
                />
            </LocalizationProvider>
            <DialogAlerts
                open={open}
                onClose={handleCloseDialog}
                agreeBtnLabel={t("miscellaneous.delete")}
                disagreeBtnLabel="No"
                type={"info"}
                title={t("miscellaneous.confRemoveCheck")}
                message={bodyMess}
                agreeAction={removeCheck}
            />
        </StyledContainer>
    );
};
