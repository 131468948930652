/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

/** MUI imports */
import { styled } from "@mui/material/styles";
import { Box, Chip, Grid, Typography } from "@mui/material";

/** Components */
import { Onboarding, CatchUp, Recurring, Consulting } from "./";

/** Component styles */
const PREFIX = "PropCFODetails";

const classes = {
    cardContentRoot: `${PREFIX}-cardContentRoot`,
    chip: `${PREFIX}-chip`,
    selectedChip: `${PREFIX}-selectedChip`,
    projectBox: `${PREFIX}-projectBox`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.cardContentRoot}`]: {
        padding: "24px 48px 48px",
    },

    [`& .${classes.chip}`]: {
        padding: 24,
        width: 200,
        backgroundColor: "#EAEAF4",
        color: "#031851",
        fontWeight: "700 !important",
        "&:hover": {
            backgroundColor: "#EAEAF4",
            color: "#031851",
        },
    },

    [`& .${classes.selectedChip}`]: {
        padding: 24,
        width: 200,
        backgroundColor: "#2f3190",
        color: "#fff",
        fontWeight: "700 !important",
        "&:hover": {
            backgroundColor: "#2f3190",
            color: "#fff",
        },
    },

    [`& .${classes.projectBox}`]: {
        backgroundColor: "#F8F9F8",
        marginTop: 16,
        padding: 30,
        borderRadius: 30,
    },
}));

/** Global variables */
const latam = [
    "Argentina",
    "Bolivia",
    "Brasil",
    "Chile",
    "Colombia",
    "Costa Rica",
    "Cuba",
    "Ecuador",
    "El Salvador",
    "Guatemala",
    "Haití",
    "Honduras",
    "México",
    "Nicaragua",
    "Panamá",
    "Paraguay",
    "Perú",
    "República Dominicana",
    "Uruguay",
];

export const PropCFODetails = (props) => {
    // Properties
    const {
        pricingModel,
        services,
        setServices,
        setValuesCFOOnboard,
        valuesCFOOnboard,
        valuesCFOCatchUp,
        setValuesCFOCatchUp,
        valuesCFORecu,
        setValuesCFORecu,
        valuesCFOConsul,
        setValuesCFOConsul,
        isError,
        isErrorFocus,
        setIsErrorFocus,
        clientMarket,
        setAutoAlertType,
        setShow,
        setTexto,
        showtutorial,
        showFinalTutorial,
    } = props;

    /** Internationalization i18n */
    const [t] = useTranslation("global");
    

    /** Component functions */
    useEffect(() => {
        if (!valuesCFORecu.chip && !valuesCFOOnboard.chip && !valuesCFOCatchUp.chip && !valuesCFOConsul.chip) {
            const updateServices = services.map((service) => {
                if (service.name === "cfo") {
                    return {
                        ...service,
                        isActive: false,
                    };
                } else {
                    return {
                        ...service,
                    };
                }
            });
            setServices(updateServices);
        } else {
            const updateServices = services.map((service) => {
                if (service.name === "cfo") {
                    return {
                        ...service,
                        isActive: true,
                    };
                } else {
                    return {
                        ...service,
                    };
                }
            });
            setServices(updateServices);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [valuesCFOOnboard.chip, valuesCFOCatchUp.chip, valuesCFORecu.chip, valuesCFOConsul.chip]);

    useEffect(() => {
        pricingModel.forEach((element) => {
            if (element.service_name === "CFO") {
                element.projects.forEach((item) => {
                    if (item.project_name === "Onboarding") {
                        setValuesCFOOnboard({
                            ...valuesCFOOnboard,
                            onboardOriginalFee: item.fee,
                            onboardProjectTotal: !valuesCFOOnboard.chip
                                ? 0
                                : item.fee * (1 - valuesCFOOnboard.onboardDiscount / 100),
                        });
                    }
                    if (item.project_name === "Catchup") {
                        setValuesCFOCatchUp({
                            ...valuesCFOCatchUp,
                            catchUpOriginalFee: item.fee,
                            projectTotal: !valuesCFOCatchUp.chip
                                ? 0
                                : valuesCFOCatchUp.additionalsOnly
                                ? valuesCFOCatchUp.projectTotal
                                : item.fee * valuesCFOCatchUp.months * (1 - valuesCFOCatchUp.discount / 100),
                            hourRate: item.additional_fee,
                        });
                    }
                    if (item.project_name === "Recurring") {
                        setValuesCFORecu({
                            ...valuesCFORecu,
                            recuOriginalFee: item.fee,
                            recuBaseRate: !valuesCFORecu.chip
                                ? 0
                                : valuesCFORecu.recuAdditionalsOnly
                                ? valuesCFORecu.recuProjectTotal
                                : valuesCFORecu.payAnnually
                                ? item.fee * 12 * (1 - valuesCFORecu.recuDiscount / 100)
                                : item.fee * (1 - valuesCFORecu.recuDiscount / 100),
                            recuProjectTotal: !valuesCFORecu.chip
                                ? 0
                                : valuesCFORecu.recuAdditionalsOnly
                                ? valuesCFORecu.recuProjectTotal
                                : valuesCFORecu.payAnnually
                                ? item.fee * 12 * (1 - valuesCFORecu.recuDiscount / 100)
                                : item.fee * (1 - valuesCFORecu.recuDiscount / 100),
                            recuHourRate: item.additional_fee,
                        });
                    }
                    if (item.project_name === "Consulting") {
                        let aux = [...valuesCFOConsul.consultingArray];

                        aux.forEach((element) => {
                            element.total = element.hours * item.fee * (1 - element.discount / 100);
                        });

                        let sumHours = aux.map((obj) => obj.hours).reduce((a, b) => parseInt(a) + parseInt(b));
                        let sumTotal = aux.map((obj) => obj.total).reduce((a, b) => parseFloat(a) + parseFloat(b));

                        setValuesCFOConsul({
                            ...valuesCFOConsul,
                            consulOriginalFee: item.fee,
                            consulProjectTotal: sumTotal,
                            consulHours: sumHours,
                            consultingArray: aux,
                        });
                    }
                });
            }
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pricingModel, valuesCFORecu.recuHours, valuesCFOCatchUp.totalHours]);

    const handleClickChip = (value, option) => {
        if (option === "onboarding") {
            setValuesCFOOnboard({
                ...valuesCFOOnboard,
                chip: !value,
                onboardOriginalFee: valuesCFOOnboard.onboardOriginalFee,
                onboardDiscount: 0,
                onboardProjectTotal: !value ? valuesCFOOnboard.onboardOriginalFee : 0,
                meetingDate: null,
                description: ""
            });
        } else if (option === "catchUp") {
            setValuesCFOCatchUp({
                ...valuesCFOCatchUp,
                chip: !value,
                catchUpOriginalFee: valuesCFOCatchUp.catchUpOriginalFee,
                sinceDate: null,
                untilDate: null,
                discount: 10,
                catchUpAdditionalsDiscount: 10,
                additionalsOnly: false,
                projectTotal: !value ? valuesCFOCatchUp.catchUpOriginalFee * (1 - valuesCFOCatchUp.discount / 100) : 0,
                hourRate: 0,
                catchUpChecks: false,
                totalHours: 0,
                months: 1,
                // Additionals
                checkedCFO: false,
                tothCFO: 0,
                startDateCFO: null,
                finishDateCFO: null,
                checkedGob: false,
                tothGob: 0,
                startDateGob: null,
                finishDateGob: null,
                checkedPres: false,
                tothPres: 0,
                startDatePres: null,
                finishDatePres: null,
                checkedRep: false,
                tothRep: 0,
                startDateRep: null,
                finishDateRep: null,
                // Others
                checkedOthers: false,
                nameOthers: "",
                tothOthers: 0,
                startDateOthers: null,
                finishDateOthers: null,
            });
        } else if (option === "recurring") {
            setValuesCFORecu({
                ...valuesCFORecu,
                chip: !value,
                recuOriginalFee: valuesCFORecu.recuOriginalFee,
                recuBaseRate: !value ? valuesCFORecu.recuOriginalFee : 0,
                recuDiscount: 0,
                recuProjectTotal: !value ? valuesCFORecu.recuOriginalFee : 0,
                recuAdditionalsDiscount: 0,
                payAnnually: false,
                recuAdditionalsOnly: false,
                initDate: null,
                recuHourRate: 0,
                recuChecks: false,
                recuHours: 0,
                // Additionals
                checkedCFO: false,
                tothCFO: 0,
                startDateCFO: null,
                finishDateCFO: null,
                checkedGob: false,
                tothGob: 0,
                startDateGob: null,
                finishDateGob: null,
                checkedPres: false,
                tothPres: 0,
                startDatePres: null,
                finishDatePres: null,
                checkedRep: false,
                tothRep: 0,
                startDateRep: null,
                finishDateRep: null,
                // Others
                checkedOthers: false,
                nameOthers: "",
                tothOthers: 0,
                startDateOthers: null,
                finishDateOthers: null,
            });
        } else {
            setValuesCFOConsul({
                ...valuesCFOConsul,
                chip: !value,
                consulOriginalFee: valuesCFOConsul.consulOriginalFee,
                consulProjectTotal: 0,
                consulHours: 0,
                consultingArray: [
                    {
                        hours: 0,
                        discount: 0,
                        description: "",
                        total: 0,
                    },
                ],
            });
        }
        if (showtutorial && !value) {
            setTimeout(() => showFinalTutorial("projectsCFO"), 500);
        }
    };

    return (
        <StyledContainer>
            <Grid id="cfo" container justifyContent="space-around" alignItems="center" spacing={2}>
                <Grid item>
                    <Chip
                        id="onboarding-chip"
                        label={
                            <Typography variant="h2" align="center" sx={{ fontWeight: 700 }}>
                                {t(`invoicing.onboarding`)}
                            </Typography>
                        }
                        className={valuesCFOOnboard.chip ? classes.selectedChip : classes.chip}
                        onClick={() => handleClickChip(valuesCFOOnboard.chip, "onboarding")}
                    />
                </Grid>
                <Grid item>
                    <Chip
                        id="catch-up-chip"
                        label={
                            <Typography variant="h2" align="center" sx={{ fontWeight: 700 }}>
                                {t("invoicing.catchUp")}
                            </Typography>
                        }
                        className={valuesCFOCatchUp.chip ? classes.selectedChip : classes.chip}
                        onClick={() => handleClickChip(valuesCFOCatchUp.chip, "catchUp")}
                    />
                </Grid>
                <Grid item>
                    <Chip
                        id="recurring-chip"
                        label={
                            <Typography variant="h2" align="center" sx={{ fontWeight: 700 }}>
                                {t("invoicing.recurring")}
                            </Typography>
                        }
                        className={valuesCFORecu.chip ? classes.selectedChip : classes.chip}
                        onClick={() => handleClickChip(valuesCFORecu.chip, "recurring")}
                    />
                </Grid>
                <Grid item>
                    <Chip
                        id="consulting-chip"
                        label={
                            <Typography variant="h2" align="center" sx={{ fontWeight: 700 }}>
                                {t("invoicing.consulting")}
                            </Typography>
                        }
                        className={valuesCFOConsul.chip ? classes.selectedChip : classes.chip}
                        onClick={() => handleClickChip(valuesCFOConsul.chip, "accountConsul")}
                    />
                </Grid>
            </Grid>
            <Box id="projectsCFO">
                {valuesCFOOnboard.chip && (
                    <Onboarding
                        valuesCFOOnboard={valuesCFOOnboard}
                        setValuesCFOOnboard={setValuesCFOOnboard}
                        isError={isError}
                        isErrorFocus={isErrorFocus}
                        setIsErrorFocus={setIsErrorFocus}
                    />
                )}
                {valuesCFOCatchUp.chip && (
                    <CatchUp
                        clientMarket={clientMarket}
                        values={valuesCFOCatchUp}
                        setValues={setValuesCFOCatchUp}
                        setShow={setShow}
                        setTexto={setTexto}
                        setAutoAlertType={setAutoAlertType}
                        isError={isError}
                        latam={latam}
                        isErrorFocus={isErrorFocus}
                        setIsErrorFocus={setIsErrorFocus}
                    />
                )}
                {valuesCFORecu.chip && (
                    <Recurring
                        clientMarket={clientMarket}
                        valuesCFORecu={valuesCFORecu}
                        setValuesCFORecu={setValuesCFORecu}
                        setShow={setShow}
                        setTexto={setTexto}
                        setAutoAlertType={setAutoAlertType}
                        latam={latam}
                        isError={isError}
                        isErrorFocus={isErrorFocus}
                        setIsErrorFocus={setIsErrorFocus}
                    />
                )}
                {valuesCFOConsul.chip && (
                    <Consulting
                        clientMarket={clientMarket}
                        valuesCFOConsul={valuesCFOConsul}
                        setValuesCFOConsul={setValuesCFOConsul}
                        setShow={setShow}
                        setTexto={setTexto}
                        setAutoAlertType={setAutoAlertType}
                        isError={isError}
                        isErrorFocus={isErrorFocus}
                        setIsErrorFocus={setIsErrorFocus}
                    />
                )}
            </Box>
        </StyledContainer>
    );
};
