/* React imports */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

/* React redux import */
import { useSelector } from "react-redux";

/* MUI imports */
import { styled } from "@mui/material/styles";
import {
    Box,
    Divider,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    CardContent,
    IconButton,
    CardHeader,
    Card,
} from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import GridUI from "@mui/material/Grid";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import {
    Grid,
    Table,
    TableHeaderRow,
    PagingPanel,
    TableEditRow,
    TableEditColumn,
} from "@devexpress/dx-react-grid-material-ui";
import { PagingState, IntegratedPaging, EditingState, DataTypeProvider } from "@devexpress/dx-react-grid";
import { Getter } from "@devexpress/dx-react-core";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";

/* Moment import */
import moment from "moment";

const PREFIX = "Occasional";

const classes = {
    btnRoot: `${PREFIX}-btnRoot`,
    buttonContainer: `${PREFIX}-buttonContainer`,
    tableStriped: `${PREFIX}-tableStriped`,
    tableHeader: `${PREFIX}-tableHeader`,
    tableBody: `${PREFIX}-tableBody`,
    tableBody2: `${PREFIX}-tableBody2`,
    pager: `${PREFIX}-pager`,
    numericInput: `${PREFIX}-numericInput`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.btnRoot}`]: {
        border: "1px solid #ff5968",
        borderRadius: 40,
        "&:hover": {
            backgroundColor: "#2f3190",
            border: "1px solid #2f3190",
        },
        "&.MuiButton-root.Mui-disabled": {
            border: "1px solid #ffcdd2",
            backgroundColor: "#ffcdd2 !important",
            color: "#fff",
        },
    },
    [`& .${classes.buttonContainer}`]: {
        display: "flex",
        justifyContent: "flex-end",
        padding: 15,
    },
    [`& .${classes.tableStriped}`]: {
        "& tbody tr:nth-of-type(odd)": {
            backgroundColor: "rgba(47,49,144,0.1)",
        },
    },
    [`& .${classes.tableHeader}`]: {
        fontSize: "15px",
        fontWeight: 600,
        color: "#031851",
        borderBottom: "2px solid #031851",
    },
    [`& .${classes.tableBody}`]: {
        fontSize: "14px",
        fontWeight: 400,
        color: "#031851",
        padding: "5px",
    },
    [`& .${classes.tableBody2}`]: {
        padding: "5px",
        textAlign: "right",
    },
    [`& .${classes.pager}`]: {
        "& .Pagination-button": {
            paddingTop: 0,
            paddingBottom: 0,
            color: "#031851",
            fontWeight: 600,
        },
        "& .Pagination-activeButton": {
            backgroundColor: "rgba(47,49,144,0.3)",
        },
    },
}));

export default function OccasionalConfig({
    occasionalRequirement,
    setOccasionalRequirement,
    data,
    setShow,
    setAlertType,
    setTexto,
    isError,
    loadInitData,
    setBackDrop,
}) {
    /* React redux */
    const todos = useSelector((state) => state.todos);

    /* i18n */
    const [t] = useTranslation("global");

    /* Component States */
    const [columns, setColumns] = useState([]);
    const [dataTable, setDataTable] = useState([]);
    const [editingRowIds, setEditingRowIds] = useState([]);
    const [dateColumns] = useState(["operationDate"]);

    /* Component functions */
    useEffect(() => {
        const columnsTemplate = [
            { name: "creationDate", title: t("miscellaneous.creationDate") },
            { name: "requirementType", title: t("miscellaneous.requirementType") },
            { name: "name", title: t("miscellaneous.name") },
            { name: "operationDate", title: t("miscellaneous.operationDate") },
        ];

        const dataTable = [];

        data?.forEach((requirement) => {
            dataTable.push({
                creationDate: moment(requirement.creation_date, "YYYY-MM-DD").format(todos.dateFormat),
                requirementType:
                    requirement.requirement_type === 6 ? t("miscellaneous.fixAct") : requirement.requirement_type ===  7 ? t("miscellaneous.loans") : t("invoicing.other"),
                name: requirement.requirement_name,
                operationDate: moment(requirement.initial_date, "YYYY-MM-DD").format(todos.dateFormat),
                id: requirement._id,
                uploaded: requirement.uploaded,
            });
        });

        setDataTable(dataTable);
        setColumns(columnsTemplate);
    }, [t, data, todos.dateFormat]);

    const handleChange = (event) => {
        setOccasionalRequirement({
            ...occasionalRequirement,
            [event.target.name]: event.target.value,
        });
    };

    const handleChangeDate = (value) => {
        let startDate = value ? value.startOf("month") : null;

        setOccasionalRequirement({
            ...occasionalRequirement,
            initialDate: startDate,
        });
    };

    const editRequirement = async ({ changed }) => {
        setBackDrop(true);

        if (Object.values(changed)[0]) {
            const index = Object.keys(changed)[0];

            const name = Object.values(changed)[0].name ?? dataTable[index].name;

            const date = Object.values(changed)[0].operationDate ?? dataTable[index].operationDate;

            const newData = {
                customRequirementID: dataTable[index].id,
                nameRequirement: name,
                dateRequirement: moment(date, "DD-MM-YYYY").format("YYYY-MM-DD"),
            };

            await fetch(`/updateCustomReq`, {
                method: "POST",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
                body: JSON.stringify(newData),
            })
                .then(async (response) => {
                    if (response.ok) {
                        setTexto(t("miscellaneous.successfulUpdate"));
                        setAlertType("success");
                        setShow(true);
                        await loadInitData();
                        const changes = dataTable.map((row, index) => {
                            if (changed) {
                                if (changed[index]) {
                                    changed[index] = changed[index].operationDate
                                        ? {
                                              ...changed[index],
                                              operationDate: moment(changed[index].operationDate, "DD/MM/YYYY").format(
                                                  todos.dateFormat
                                              ),
                                          }
                                        : changed[index];
                                }
                            }
                            return changed[index] ? { ...row, ...changed[index] } : row;
                        });
                        setDataTable(changes);
                    } else {
                        setTexto(t("miscellaneous.updatingError"));
                        setAlertType("error");
                        setShow(true);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
        setBackDrop(false);
    };

    const tableColumnExtensions = [
        {
            columnName: "creationDate",
            align: "center",
            editingEnabled: false,
            width: "10%",
        },
        {
            columnName: "requirementType",
            align: "center",
            editingEnabled: false,
            wordWrapEnabled: true,
            width: "30%",
        },
        { columnName: "name", align: "center", width: "30%" },
        {
            columnName: "operationDate",
            align: "center",
            width: "10%",
            editingEnabled: true,
        },
    ];

    const TableCellComponent = ({ ...props }) => <Table.Cell {...props} className={classes.tableBody} />;

    const tableHeaderCell = ({ ...props }) => <TableHeaderRow.Cell {...props} className={classes.tableHeader} />;

    const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: "rgba(19, 31, 62, 0.80)",
            fontSize: 12,
            maxWidth: 320,
            borderRadius: 6,
            fontWeight: "normal",
        },
    });

    const EditButton = ({ onExecute }) => {
        return (
            <LightTooltip title={t("miscellaneous.edit")} aria-label="edit">
                <IconButton onClick={onExecute} size="large">
                    <EditIcon color="primary" />
                </IconButton>
            </LightTooltip>
        );
    };

    const CommitButton = ({ onExecute }) => {
        return (
            <LightTooltip title={t("miscellaneous.save")} aria-label="save">
                <IconButton onClick={onExecute} size="large">
                    <SaveIcon color="primary" />
                </IconButton>
            </LightTooltip>
        );
    };

    const CancelButton = ({ onExecute }) => {
        return (
            <LightTooltip title={t("miscellaneous.cancel")} aria-label="cancel">
                <IconButton color="secondary" onClick={onExecute} size="large">
                    <CancelIcon />
                </IconButton>
            </LightTooltip>
        );
    };

    const tableMessages = {
        noData: t("miscellaneous.noData"),
    };

    const pagingMessages = {
        info: ({ from, to, count }) => `${from}${from < to ? `-${to}` : ""} ${t("miscellaneous.pagingOf")} ${count}`,
    };

    const commandComponents = {
        edit: EditButton,
        commit: CommitButton,
        cancel: CancelButton,
    };

    const Command = ({ id, onExecute }) => {
        const CommandButton = commandComponents[id];
        return <CommandButton onExecute={onExecute} />;
    };

    const EditCell = ({ ...props }) => {
        const { children, row } = props;

        return (
            <TableEditColumn.Cell {...props} className={classes.tableBody2}>
                {React.Children.map(children, (child) => {
                    return child?.props.id === "edit" && row.uploaded ? (
                        <LightTooltip title={t("miscellaneous.doneReq")} aria-label="doneInv">
                            <span>
                                <IconButton color="secondary" size="large" disabled>
                                    <CheckIcon sx={{ color: "green" }} />
                                </IconButton>
                            </span>
                        </LightTooltip>
                    ) : (
                        child
                    );
                })}
            </TableEditColumn.Cell>
        );
    };

    const PagingPanelContainer = ({ ...props }) => <PagingPanel.Container {...props} className={classes.pager} />;

    const DateTypeProvider = (props) => <DataTypeProvider editorComponent={DatePickerEditor} {...props} />;

    const DatePickerEditor = ({ value, onValueChange }) => {

        return (
            <LocalizationProvider
                dateAdapter={AdapterMoment}
                adapterLocale={t("language.locale")}
                localeText={{ clearButtonLabel: t("miscellaneous.clear") }}
            >
                <GridUI item>
                    <DatePicker
                        value={moment(value).format("DD/MM/YYYY")}
                        onChange={(date) => onValueChange(moment(date).format("DD/MM/YYYY"))}
                        inputFormat={todos.dateFormat}
                        renderInput={(params) => <TextField required fullWidth {...params} error={isError && !value} />}
                        inputProps={{ readOnly: true }}
                        views={["year", "month"]}
                        TextFieldProps={{ variant: "standard" }}
                    />
                </GridUI>
            </LocalizationProvider>
        );
    };

    return (
        <StyledContainer>
            <Card elevation={0}>
                <CardHeader
                    title={t("miscellaneous.selectActiveRequirements")}
                    sx={{ color: "#ff5968", marginTop: "20px" }}
                />
                <Divider sx={{ marginBottom: "30px" }} />
                <CardContent>
                    <GridUI container spacing={1} justifyContent="center">
                        <GridUI item xs={12} md={3.5}>
                            <FormControl
                                fullWidth
                                required
                                error={isError && occasionalRequirement.requirementType === "" ? true : false}
                            >
                                <InputLabel id="mode-simple-select-label">{`${t(
                                    "miscellaneous.requirements"
                                )}`}</InputLabel>
                                <Select
                                    name="requirementType"
                                    labelId="mode-simple-select-label"
                                    value={occasionalRequirement.requirementType}
                                    label={`${t("miscellaneous.requirements")}`}
                                    onChange={(event) => handleChange(event)}
                                    MenuProps={{
                                        PaperProps: {
                                            sx: {
                                                mt: 0.7,
                                                borderRadius: 2,
                                                boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                            },
                                        },
                                    }}
                                >
                                    <MenuItem value={6}>{t("miscellaneous.fixAct")}</MenuItem>
                                    <MenuItem value={7}>{t("miscellaneous.loans")}</MenuItem>
                                    <MenuItem value={8}>{t("invoicing.other")}</MenuItem>
                                </Select>
                            </FormControl>
                        </GridUI>
                        <GridUI item xs={12} md={4}>
                            <TextField
                                name="requirementName"
                                type="text"
                                fullWidth
                                required
                                label={`${t("miscellaneous.name")}`}
                                value={occasionalRequirement.requirementName}
                                onChange={(event) => handleChange(event)}
                                variant="outlined"
                                error={isError && occasionalRequirement.requirementName === "" ? true : false}
                            />
                        </GridUI>
                        <LocalizationProvider
                            dateAdapter={AdapterMoment}
                            adapterLocale={t("language.locale")}
                            localeText={{ clearButtonLabel: t("miscellaneous.clear") }}
                        >
                            <GridUI item xs={12} md={4}>
                                <DatePicker
                                    name="initialDate"
                                    value={occasionalRequirement.initialDate}
                                    onChange={(value) => handleChangeDate(value)}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => (
                                        <TextField
                                            required
                                            fullWidth
                                            {...params}
                                            error={isError && !occasionalRequirement.initialDate}
                                        />
                                    )}
                                    inputProps={{ readOnly: true }}
                                    views={["year", "month"]}
                                    label={`${t("miscellaneous.operationDate")}`}
                                />
                            </GridUI>
                        </LocalizationProvider>
                    </GridUI>
                </CardContent>
            </Card>
            <Card elevation={0}>
                <CardHeader title={t("miscellaneous.reqHist")} sx={{ color: "#ff5968", marginTop: "20px" }} />
                <Divider />
                <CardContent md={500}>
                    <GridUI container spacing={2} alignItems="center">
                        <GridUI item xs={12}>
                            <Grid rows={dataTable} columns={columns}>
                                <EditingState
                                    editingRowIds={editingRowIds}
                                    onEditingRowIdsChange={setEditingRowIds}
                                    onCommitChanges={editRequirement}
                                    columnExtensions={tableColumnExtensions}
                                />
                                <PagingState defaultCurrentPage={0} pageSize={5} />
                                <IntegratedPaging />
                                <DateTypeProvider for={dateColumns} />
                                <Table
                                    columnExtensions={tableColumnExtensions}
                                    cellComponent={TableCellComponent}
                                    messages={tableMessages}
                                />
                                <TableHeaderRow cellComponent={tableHeaderCell} />
                                <TableEditRow />
                                <TableEditColumn
                                    showEditCommand
                                    headerCellComponent={tableHeaderCell}
                                    cellComponent={EditCell}
                                    commandComponent={Command}
                                />
                                <Getter
                                    name="tableColumns"
                                    computed={({ tableColumns }) => [
                                        ...tableColumns.filter((column) => column.type !== TableEditColumn.COLUMN_TYPE),
                                        {
                                            key: "editCommand",
                                            type: TableEditColumn.COLUMN_TYPE,
                                            width: 100,
                                        },
                                    ]}
                                />
                                <PagingPanel containerComponent={PagingPanelContainer} messages={pagingMessages} />
                            </Grid>
                        </GridUI>
                    </GridUI>
                </CardContent>
            </Card>
        </StyledContainer>
    );
}
