/** Imports ---------------------------------------------------------------- */

/** React imports */
import React from "react";

/** Icons */
import { ReactComponent as CheckIcon } from "../../assets/images/check-icon.svg";
import { ReactComponent as ErrorIcon } from "../../assets/images/error-icon.svg";
import { ReactComponent as InfoIcon } from "../../assets/images/info-icon.svg";
import { ReactComponent as WarningIcon } from "../../assets/images/warning-icon.svg";

/** MUI imports */
import { styled } from "@mui/material/styles";
import { Button, Dialog, DialogActions, DialogContent, Slide, Typography } from "@mui/material";

const PREFIX = "DialogAlerts";

const classes = {
    icon: `${PREFIX}-icon`,
};

const CustomDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: "32px 32px 8px",
    },
    "& .MuiDialogTitle-root": {
        backgroundColor: "#031851",
        fontSize: "18px",
        color: "#fff",
    },
    "& .MuiDialog-paper": {
        borderRadius: 8,
        boxShadow: "10px 10px 15px rgba(3,24,81,0.15)",
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(2),
    },

    [`& .${classes.icon}`]: {
        height: 120,
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DialogAlerts = (props) => {
    let dialogIcon = "";
    if (props.type === "success") {
        dialogIcon = <CheckIcon fill="#A5DC86" className={classes.icon} />;
    } else if (props.type === "info") {
        dialogIcon = <InfoIcon fill="#3FC3EE" className={classes.icon} />;
    } else if (props.type === "warning") {
        dialogIcon = <WarningIcon fill="#F8BB86" className={classes.icon} />;
    } else {
        dialogIcon = <ErrorIcon fill="#ff5968" className={classes.icon} />;
    }

    return (
        <CustomDialog
            open={props.open}
            onClose={props.onClose}
            maxWidth="sm"
            fullWidth={true}
            TransitionComponent={Transition}
        >
            <DialogContent>
                {dialogIcon}
                <Typography variant="h1" color="primary" align="center" sx={{ fontSize: 24, pt: 1 }}>
                    {props.title}
                </Typography>
                {props.message}
            </DialogContent>
            <DialogActions sx={{ ml: "auto", mr: "auto" }}>
                <Button
                    disableElevation
                    variant="outlined"
                    color="secondary"
                    onClick={props.onClose}
                    sx={{
                        border: "1px solid #ff5968",
                        borderRadius: 40,
                        fontSize: 18,
                        "&:hover": {
                            border: "1px solid #2f3190",
                            color: "#2f3190",
                        },
                    }}
                >
                    {props.disagreeBtnLabel}
                </Button>
                <Button
                    disableElevation
                    variant="contained"
                    color="secondary"
                    onClick={props.agreeAction}
                    sx={{
                        borderRadius: 40,
                        fontSize: 18,
                        ml: "24px !important",
                        "&:hover": {
                            backgroundColor: "#2f3190",
                            color: "#fff",
                        },
                    }}
                >
                    {props.agreeBtnLabel}
                </Button>
            </DialogActions>
        </CustomDialog>
    );
};

export default DialogAlerts;
