/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useState, useEffect } from "react";

/** Components imports */
import ClientMana from "./ClientMana";
import ClientManaSkeletons from "./ClientManaSkeletons";

const Clients = () => {
    /** Component states */
    const [typeData, setTypeData] = useState([]);
    const [franqData, setFranqData] = useState([]);
    const [groupData, setGroupData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [proveedores, setProveedores] = useState([]);

    useEffect(() => {
        (async () => {
            setLoading(true);
            await fetch(`/getClientTypeFilter`, {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
                .then((res) => res.json())
                .then((data) => {
                    setTypeData(data);
                });

            await fetch(`/getClientFranchiseFilter`, {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
                .then((res) => res.json())
                .then((data) => {
                    setFranqData(data);
                });

            await fetch(`/getClientGroupsFilter`, {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
                .then((res) => res.json())
                .then((data) => {
                    setGroupData(data);
                });

            await fetch(`/getProviders`, {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
                .then((res) => res.json())
                .then((data) => {
                    setProveedores(data.providers);
                });
            setLoading(false);
        })();
    }, []);

    return (
        <>
            {franqData.length > 0 && groupData.length > 0 && typeData.length > 0 && proveedores.length > 0 && (
                <ClientMana
                    loading={loading}
                    typeData={typeData}
                    groupData={groupData}
                    franqData={franqData}
                    setLoading={setLoading}
                    proveedores={proveedores}
                />
            )}
            {(franqData.length === 0 ||
                groupData.length === 0 ||
                typeData.length === 0 ||
                proveedores.length === 0) && <ClientManaSkeletons />}
        </>
    );
};

export default Clients;
