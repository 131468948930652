// React
import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";
import moment from "moment";

// MUI
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

const PREFIX = "Additionals";

const classes = {
    btnRoot: `${PREFIX}-btnRoot`,
    buttonContainer: `${PREFIX}-buttonContainer`,
    switch: `${PREFIX}-switch`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.btnRoot}`]: {
        border: "1px solid #ff5968",
        borderRadius: 40,
        "&:hover": {
            backgroundColor: "#2f3190",
            border: "1px solid #2f3190",
        },
    },
    [`& .${classes.buttonContainer}`]: {
        display: "flex",
        justifyContent: "flex-end",
        padding: 15,
    },
    [`& .${classes.switch}`]: {
        "& .MuiSwitch-switchBase .MuiSwitch-thumb": {
            color: "#FFF",
        },
        "& .MuiSwitch-switchBase + .MuiSwitch-track": {
            backgroundColor: "#031851",
        },
        "& .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb": {
            color: "#FF5968",
        },
        "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
            backgroundColor: "#FFA8B1",
        },
    },
}));

const NumberFormatCustom = (props) => {
    const todos = useSelector((state) => state.todos);

    const { inputRef, value, onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            value={value}
            getInputRef={inputRef}
            onValueChange={({ value: v }) => onChange({ target: { value: v } })}
            thousandSeparator={todos.amountFormat === "de-DE" ? "." : ","}
            decimalSeparator={todos.amountFormat === "de-DE" ? "," : "."}
            fixedDecimalScale={true}
            decimalScale={2}
            valueIsNumericString
        />
    );
};

export const Additionals = (props) => {
    // Properties
    const {
        clientMarket,
        isError,
        set,
        setalertType,
        setShow,
        setTexto,
        sumTotal,
        updateProject,
        values,
        accountSystem,
        expenseEst,
        showConfirm,
        sumTotalAddHours,
    } = props;

    /** Internationalization i18n */
    const [t] = useTranslation("global");

    /** React redux */
    const todos = useSelector((state) => state.todos);

    /** Component functions */
    const handleChange = (event) => {
        set({
            ...values,
            [event.target.name]: event.target.value,
        });
    };

    const handleChangeHoursTaxExpert = (event) => {
        set({
            ...values,
            [event.target.name]: event.target.value,
            projectFee: event.target.value * values.addHourFee,
            projectTotal: event.target.value * values.addHourFee * (1 - values.projectDiscount / 100),
        });
    };

    const handleChangeCheck = (event, value) => {
        switch (value) {
            case 0:
                if (values.checkedInternationalOG && !event.target.checked && !todos.remind) {
                    showConfirm({
                        check: "checkedInternational",
                        toth: "tothInternational",
                        startDate: "startDateInternational",
                        finishDate: "finishDateInternational",
                    });
                } else {
                    set({
                        ...values,
                        checkedInternational: event.target.checked,
                        tothInternational: 0,
                        startDateInternational: event.target.checked ? values.initDate : null,
                        finishDateInternational: event.target.checked ? values.endDate : null,
                    });
                }
                break;
            case 1:
                if (values.checkedIntercompanyOG && !event.target.checked && !todos.remind) {
                    showConfirm({
                        check: "checkedIntercompany",
                        toth: "tothIntercompany",
                        startDate: "startDateIntercompany",
                        finishDate: "finishDateIntercompany",
                    });
                } else {
                    set({
                        ...values,
                        checkedIntercompany: event.target.checked,
                        tothIntercompany: 0,
                        startDateIntercompany: event.target.checked ? values.initDate : null,
                        finishDateIntercompany: event.target.checked ? values.endDate : null,
                    });
                }
                break;
            case 3:
                if (values.checkedTaxPlanningOG && !event.target.checked && !todos.remind) {
                    showConfirm({
                        check: "checkedTaxPlanning",
                        toth: "tothTaxPlanning",
                        startDate: "startDateTaxPlanning",
                        finishDate: "finishDateTaxPlanning",
                    });
                } else {
                    set({
                        ...values,
                        checkedTaxPlanning: event.target.checked,
                        tothTaxPlanning: 0,
                        startDateTaxPlanning: event.target.checked ? values.initDate : null,
                        finishDateTaxPlanning: event.target.checked ? values.endDate : null,
                    });
                }
                break;
            case 4:
                if (values.checkedEstIncomeTaxOG && !event.target.checked && !todos.remind) {
                    showConfirm({
                        check: "checkedEstIncomeTax",
                        toth: "tothEstIncomeTax",
                        startDate: "startDateEstIncomeTax",
                        finishDate: "finishDateEstIncomeTax",
                    });
                } else {
                    set({
                        ...values,
                        checkedEstIncomeTax: event.target.checked,
                        tothEstIncomeTax: 0,
                        startDateEstIncomeTax: event.target.checked ? values.initDate : null,
                        finishDateEstIncomeTax: event.target.checked ? values.endDate : null,
                    });
                }
                break;
            case 10:
                if (values.checkedOthersOG && !event.target.checked && !todos.remind) {
                    showConfirm({
                        check: "checkedOthers",
                        nameOthers: "nameOthers",
                        toth: "tothOthers",
                        startDate: "startDateOthers",
                        finishDate: "finishDateOthers",
                    });
                } else {
                    set({
                        ...values,
                        checkedOthers: event.target.checked,
                        nameOthers: "",
                        tothOthers: 0,
                        startDateOthers: event.target.checked ? values.initDate : null,
                        finishDateOthers: event.target.checked ? values.endDate : null,
                    });
                }
                break;
            default:
                set({
                    ...values,
                    projectFee: 0,
                    projectTotal: 0,
                    checkedTaxExpert: event.target.checked,
                    tothTaxExpert: 0,
                    startDateTaxExpert: event.target.checked ? values.initDate : null,
                    finishDateTaxExpert: event.target.checked ? values.endDate : null,
                });
                break;
        }
    };

    const handleChangeStartDateAdd = (date, val) => {
        let startDate = moment(date).startOf("month");
        switch (val) {
            case 0:
                if (!date || !values.finishDateInternational) {
                    set({
                        ...values,
                        startDateInternational: date ? moment(date).startOf("month") : date,
                    });
                } else {
                    if (startDate.isAfter(moment(values.finishDateInternational))) {
                        setTexto(t("reports.initDateMess"));
                        setalertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            startDateInternational: moment(date).startOf("month"),
                        });
                    }
                }
                break;
            case 1:
                if (!date || !values.finishDateIntercompany) {
                    set({
                        ...values,
                        startDateIntercompany: date ? moment(date).startOf("month") : date,
                    });
                } else {
                    if (startDate.isAfter(moment(values.finishDateIntercompany))) {
                        setTexto(t("reports.initDateMess"));
                        setalertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            startDateIntercompany: moment(date).startOf("month"),
                        });
                    }
                }
                break;
            case 2:
                if (!date || !values.finishDateTaxPlanning) {
                    set({
                        ...values,
                        startDateTaxPlanning: date ? moment(date).startOf("month") : date,
                    });
                } else {
                    if (startDate.isAfter(moment(values.finishDateTaxPlanning))) {
                        setTexto(t("reports.initDateMess"));
                        setalertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            startDateTaxPlanning: moment(date).startOf("month"),
                        });
                    }
                }
                break;
            case 3:
                if (!date || !values.finishDateEstIncomeTax) {
                    set({
                        ...values,
                        startDateEstIncomeTax: date ? moment(date).startOf("month") : date,
                    });
                } else {
                    if (startDate.isAfter(moment(values.finishDateEstIncomeTax))) {
                        setTexto(t("reports.initDateMess"));
                        setalertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            startDateEstIncomeTax: moment(date).startOf("month"),
                        });
                    }
                }
                break;
            case 4:
                if (!date || !values.finishDateOthers) {
                    set({
                        ...values,
                        startDateOthers: date ? moment(date).startOf("month") : date,
                    });
                } else {
                    if (startDate.isAfter(moment(values.finishDateOthers))) {
                        setTexto(t("reports.initDateMess"));
                        setalertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            startDateOthers: moment(date).startOf("month"),
                        });
                    }
                }
                break;
            default:
                if (!date || !values.finishDateTaxExpert) {
                    set({
                        ...values,
                        startDateTaxExpert: date ? moment(date).startOf("month") : date,
                    });
                } else {
                    if (startDate.isAfter(moment(values.finishDateTaxExpert))) {
                        setTexto(t("reports.initDateMess"));
                        setalertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            startDateTaxExpert: moment(date).startOf("month"),
                        });
                    }
                }
                break;
        }
    };

    const handleChangeEndDateAdd = (date, val) => {
        let endDate = moment(date).endOf("month").startOf("day");
        switch (val) {
            case 0:
                if (!date || !values.startDateInternational) {
                    set({
                        ...values,
                        finishDateInternational: date ? moment(date).endOf("month") : date,
                    });
                } else {
                    if (endDate.isBefore(moment(values.startDateInternational))) {
                        setTexto(t("reports.endDateMess"));
                        setalertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            finishDateInternational: moment(date).endOf("month"),
                        });
                    }
                }
                break;
            case 1:
                if (!date || !values.startDateIntercompany) {
                    set({
                        ...values,
                        finishDateIntercompany: date ? moment(date).endOf("month") : date,
                    });
                } else {
                    if (endDate.isBefore(moment(values.startDateIntercompany))) {
                        setTexto(t("reports.endDateMess"));
                        setalertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            finishDateIntercompany: moment(date).endOf("month"),
                        });
                    }
                }
                break;
            case 2:
                if (!date || !values.startDateTaxPlanning) {
                    set({
                        ...values,
                        finishDateTaxPlanning: date ? moment(date).endOf("month") : date,
                    });
                } else {
                    if (endDate.isBefore(moment(values.startDateTaxPlanning))) {
                        setTexto(t("reports.endDateMess"));
                        setalertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            finishDateTaxPlanning: moment(date).endOf("month"),
                        });
                    }
                }
                break;
            case 3:
                if (!date || !values.startDateEstIncomeTax) {
                    set({
                        ...values,
                        finishDateEstIncomeTax: date ? moment(date).endOf("month") : date,
                    });
                } else {
                    if (endDate.isBefore(moment(values.startDateEstIncomeTax))) {
                        setTexto(t("reports.endDateMess"));
                        setalertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            finishDateEstIncomeTax: moment(date).endOf("month"),
                        });
                    }
                }
                break;
            case 4:
                if (!date || !values.startDateOthers) {
                    set({
                        ...values,
                        finishDateOthers: date ? moment(date).endOf("month") : date,
                    });
                } else {
                    if (endDate.isBefore(moment(values.startDateOthers))) {
                        setTexto(t("reports.endDateMess"));
                        setalertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            finishDateOthers: moment(date).endOf("month"),
                        });
                    }
                }
                break;
            default:
                if (!date || !values.startDateTaxExpert) {
                    set({
                        ...values,
                        finishDateTaxExpert: date ? moment(date).endOf("month") : date,
                    });
                } else {
                    if (endDate.isBefore(moment(values.startDateTaxExpert))) {
                        setTexto(t("reports.endDateMess"));
                        setalertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            finishDateTaxExpert: moment(date).endOf("month"),
                        });
                    }
                }
                break;
        }
    };

    return (
        <StyledContainer>
            <LocalizationProvider
                dateAdapter={AdapterMoment}
                adapterLocale={t("language.locale")}
                localeText={{ clearButtonLabel: t("miscellaneous.clear") }}
            >
                <Card elevation={0}>
                    <CardHeader title={t("services.additionals")} sx={{ color: "#ff5968" }} />
                    <Divider />
                    <CardContent sx={{ pb: 5 }}>
                        <Grid container spacing={2} alignItems="center">
                            {values.additionalsOnly && (
                                <>
                                    <Grid item xs={12} md={3}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    name="checkedTaxExpert"
                                                    checked={values.checkedTaxExpert}
                                                    onChange={() => {
                                                        return false;
                                                    }}
                                                    inputProps={{
                                                        "aria-label": "controlled",
                                                    }}
                                                    //disabled={!values.initDate}
                                                />
                                            }
                                            label="Tax expert"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            fullWidth
                                            disabled={!values.checkedTaxExpert}
                                            label={t("miscellaneous.hoursMonth")}
                                            name="tothTaxExpert"
                                            onChange={handleChangeHoursTaxExpert}
                                            value={values.tothTaxExpert}
                                            variant="outlined"
                                            type="number"
                                            inputProps={{
                                                min: 0,
                                                step: 0.5,
                                                onKeyDown: (event) => {
                                                    event.preventDefault();
                                                },
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            error={values.checkedTaxExpert && isError && values.tothTaxExpert === 0}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <DatePicker
                                            componentsProps={{
                                                actionBar: {
                                                    actions: ["clear"],
                                                },
                                            }}
                                            disabled={!values.checkedTaxExpert}
                                            value={values.startDateTaxExpert}
                                            minDate={moment(values.initDate).startOf("month")}
                                            maxDate={moment(values.endDate).endOf("month")}
                                            onChange={(newValue) => handleChangeStartDateAdd(newValue, 5)}
                                            inputFormat={todos.dateFormat}
                                            renderInput={(params) => (
                                                <TextField
                                                    fullWidth
                                                    required={values.checkedTaxExpert}
                                                    {...params}
                                                    error={
                                                        values.checkedTaxExpert && isError && !values.startDateTaxExpert
                                                    }
                                                />
                                            )}
                                            inputProps={{ readOnly: true }}
                                            label={t("reports.initDate")}
                                            views={["year", "month"]}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <DatePicker
                                            componentsProps={{
                                                actionBar: {
                                                    actions: ["clear"],
                                                },
                                            }}
                                            disabled={!values.checkedTaxExpert}
                                            value={values.finishDateTaxExpert}
                                            minDate={moment(values.initDate).startOf("month")}
                                            maxDate={moment(values.endDate).endOf("month")}
                                            onChange={(newValue) => handleChangeEndDateAdd(newValue, 5)}
                                            inputFormat={todos.dateFormat}
                                            renderInput={(params) => <TextField
                                                fullWidth
                                                required={values.checkedTaxExpert}
                                                {...params}
                                                error={
                                                    values.checkedTaxExpert && isError && !values.finishDateTaxExpert
                                                }
                                            />}
                                            inputProps={{ readOnly: true }}
                                            label={t("reports.endDate")}
                                            views={["year", "month"]}
                                        />
                                    </Grid>
                                </>
                            )}
                            {clientMarket === "Estados Unidos" && (
                                <>
                                    <Grid item xs={12} md={3}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    name="checkedInternational"
                                                    checked={values.checkedInternational}
                                                    onChange={(value) => handleChangeCheck(value, 0)}
                                                    inputProps={{
                                                        "aria-label": "controlled",
                                                    }}
                                                    disabled={!values.initDate}
                                                />
                                            }
                                            label={t("services.international")}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            fullWidth
                                            disabled={!values.checkedInternational}
                                            label={t("miscellaneous.hoursMonth")}
                                            name="tothInternational"
                                            onChange={handleChange}
                                            value={values.tothInternational}
                                            variant="outlined"
                                            type="number"
                                            inputProps={{
                                                min: 0,
                                                step: 0.5,
                                                onKeyDown: (event) => {
                                                    event.preventDefault();
                                                },
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            error={
                                                values.checkedInternational && isError && values.tothInternational === 0
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <DatePicker
                                            componentsProps={{
                                                actionBar: { actions: ["clear"] },
                                            }}
                                            disabled={!values.checkedInternational}
                                            value={values.startDateInternational}
                                            onChange={(newValue) => handleChangeStartDateAdd(newValue, 0)}
                                            minDate={moment(values.initDate).startOf("month")}
                                            maxDate={moment(values.endDate).endOf("month")}
                                            inputFormat={todos.dateFormat}
                                            renderInput={(params) => (
                                                <TextField
                                                    fullWidth
                                                    required={values.checkedInternational}
                                                    {...params}
                                                    error={
                                                        values.checkedInternational &&
                                                        isError &&
                                                        !values.startDateInternational
                                                    }
                                                />
                                            )}
                                            inputProps={{ readOnly: true }}
                                            label={t("reports.initDate")}
                                            views={["year", "month"]}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <DatePicker
                                            componentsProps={{
                                                actionBar: { actions: ["clear"] },
                                            }}
                                            disabled={!values.checkedInternational}
                                            value={values.finishDateInternational}
                                            onChange={(newValue) => handleChangeEndDateAdd(newValue, 0)}
                                            minDate={moment(values.initDate).startOf("month")}
                                            maxDate={moment(values.endDate).endOf("month")}
                                            inputFormat={todos.dateFormat}
                                            renderInput={(params) => <TextField
                                                fullWidth
                                                required={values.checkedInternational}
                                                {...params}
                                                error={
                                                    values.checkedInternational &&
                                                    isError &&
                                                    !values.finishDateInternational
                                                }
                                            />}
                                            inputProps={{ readOnly: true }}
                                            label={t("reports.endDate")}
                                            views={["year", "month"]}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    name="checkedIntercompany"
                                                    checked={values.checkedIntercompany}
                                                    onChange={(value) => handleChangeCheck(value, 1)}
                                                    inputProps={{
                                                        "aria-label": "controlled",
                                                    }}
                                                    disabled={!values.initDate}
                                                />
                                            }
                                            label={t("services.intercompany")}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            fullWidth
                                            disabled={!values.checkedIntercompany}
                                            label={t("miscellaneous.hoursMonth")}
                                            name="tothIntercompany"
                                            onChange={handleChange}
                                            value={values.tothIntercompany}
                                            variant="outlined"
                                            type="number"
                                            inputProps={{
                                                min: 0,
                                                step: 0.5,
                                                onKeyDown: (event) => {
                                                    event.preventDefault();
                                                },
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            error={
                                                values.checkedIntercompany && isError && values.tothIntercompany === 0
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <DatePicker
                                            componentsProps={{
                                                actionBar: { actions: ["clear"] },
                                            }}
                                            disabled={!values.checkedIntercompany}
                                            value={values.startDateIntercompany}
                                            onChange={(newValue) => handleChangeStartDateAdd(newValue, 1)}
                                            minDate={moment(values.initDate).startOf("month")}
                                            maxDate={moment(values.endDate).endOf("month")}
                                            inputFormat={todos.dateFormat}
                                            renderInput={(params) => (
                                                <TextField
                                                    fullWidth
                                                    required={values.checkedIntercompany}
                                                    {...params}
                                                    error={
                                                        values.checkedIntercompany &&
                                                        isError &&
                                                        !values.startDateIntercompany
                                                    }
                                                />
                                            )}
                                            inputProps={{ readOnly: true }}
                                            label={t("reports.initDate")}
                                            views={["year", "month"]}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <DatePicker
                                            componentsProps={{
                                                actionBar: { actions: ["clear"] },
                                            }}
                                            disabled={!values.checkedIntercompany}
                                            value={values.finishDateIntercompany}
                                            onChange={(newValue) => handleChangeEndDateAdd(newValue, 1)}
                                            minDate={moment(values.initDate).startOf("month")}
                                            maxDate={moment(values.endDate).endOf("month")}
                                            inputFormat={todos.dateFormat}
                                            renderInput={(params) => <TextField
                                                fullWidth
                                                required={values.checkedIntercompany}
                                                {...params}
                                                error={
                                                    values.checkedIntercompany &&
                                                    isError &&
                                                    !values.finishDateIntercompany
                                                }
                                            />}
                                            inputProps={{ readOnly: true }}
                                            label={t("reports.endDate")}
                                            views={["year", "month"]}
                                        />
                                    </Grid>
                                </>
                            )}
                            <Grid item xs={12} md={3}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="checkedTaxPlanning"
                                            checked={values.checkedTaxPlanning}
                                            onChange={(value) => handleChangeCheck(value, 3)}
                                            inputProps={{
                                                "aria-label": "controlled",
                                            }}
                                            disabled={!values.initDate}
                                        />
                                    }
                                    label={t("services.taxPlanning")}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    fullWidth
                                    disabled={!values.checkedTaxPlanning}
                                    label={t("miscellaneous.hoursMonth")}
                                    name="tothTaxPlanning"
                                    onChange={handleChange}
                                    value={values.tothTaxPlanning}
                                    variant="outlined"
                                    type="number"
                                    inputProps={{
                                        min: 0,
                                        step: 0.5,
                                        onKeyDown: (event) => {
                                            event.preventDefault();
                                        },
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    error={values.checkedTaxPlanning && isError && values.tothTaxPlanning === 0}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    disabled={!values.checkedTaxPlanning}
                                    value={values.startDateTaxPlanning}
                                    onChange={(newValue) => handleChangeStartDateAdd(newValue, 2)}
                                    minDate={moment(values.initDate).startOf("month")}
                                    maxDate={moment(values.endDate).endOf("month")}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            required={values.checkedTaxPlanning}
                                            {...params}
                                            error={values.checkedTaxPlanning && isError && !values.startDateTaxPlanning}
                                        />
                                    )}
                                    inputProps={{ readOnly: true }}
                                    label={t("reports.initDate")}
                                    views={["year", "month"]}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    disabled={!values.checkedTaxPlanning}
                                    value={values.finishDateTaxPlanning}
                                    onChange={(newValue) => handleChangeEndDateAdd(newValue, 2)}
                                    minDate={moment(values.initDate).startOf("month")}
                                    maxDate={moment(values.endDate).endOf("month")}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => <TextField
                                        fullWidth
                                        required={values.checkedTaxPlanning}
                                        {...params}
                                        error={values.checkedTaxPlanning && isError && !values.finishDateTaxPlanning}
                                    />}
                                    inputProps={{ readOnly: true }}
                                    label={t("reports.endDate")}
                                    views={["year", "month"]}
                                />
                            </Grid>
                            {clientMarket === "Venezuela" && (
                                <>
                                    <Grid item xs={12} md={3}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    name="checkedEstIncomeTax"
                                                    checked={values.checkedEstIncomeTax}
                                                    onChange={(value) => handleChangeCheck(value, 4)}
                                                    inputProps={{
                                                        "aria-label": "controlled",
                                                    }}
                                                    disabled={!values.initDate}
                                                />
                                            }
                                            label={t("services.estimatedTaxInc")}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            fullWidth
                                            disabled={!values.checkedEstIncomeTax}
                                            label={t("miscellaneous.hoursMonth")}
                                            name="tothEstIncomeTax"
                                            onChange={handleChange}
                                            value={values.tothEstIncomeTax}
                                            variant="outlined"
                                            type="number"
                                            inputProps={{
                                                min: 0,
                                                step: 0.5,
                                                onKeyDown: (event) => {
                                                    event.preventDefault();
                                                },
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            error={
                                                values.checkedEstIncomeTax && isError && values.tothEstIncomeTax === 0
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <DatePicker
                                            componentsProps={{
                                                actionBar: { actions: ["clear"] },
                                            }}
                                            disabled={!values.checkedEstIncomeTax}
                                            value={values.startDateEstIncomeTax}
                                            onChange={(newValue) => handleChangeStartDateAdd(newValue, 3)}
                                            minDate={moment(values.initDate).startOf("month")}
                                            maxDate={moment(values.endDate).endOf("month")}
                                            inputFormat={todos.dateFormat}
                                            renderInput={(params) => (
                                                <TextField
                                                    fullWidth
                                                    required={values.checkedEstIncomeTax}
                                                    {...params}
                                                    error={
                                                        values.checkedEstIncomeTax &&
                                                        isError &&
                                                        !values.startDateEstIncomeTax
                                                    }
                                                />
                                            )}
                                            inputProps={{ readOnly: true }}
                                            label={t("reports.initDate")}
                                            views={["year", "month"]}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <DatePicker
                                            componentsProps={{
                                                actionBar: { actions: ["clear"] },
                                            }}
                                            disabled={!values.checkedEstIncomeTax}
                                            value={values.finishDateEstIncomeTax}
                                            onChange={(newValue) => handleChangeEndDateAdd(newValue, 3)}
                                            minDate={moment(values.initDate).startOf("month")}
                                            maxDate={moment(values.endDate).endOf("month")}
                                            inputFormat={todos.dateFormat}
                                            renderInput={(params) => <TextField
                                                fullWidth
                                                required={values.checkedEstIncomeTax}
                                                {...params}
                                                error={
                                                    values.checkedEstIncomeTax &&
                                                    isError &&
                                                    !values.finishDateEstIncomeTax
                                                }
                                            />}
                                            inputProps={{ readOnly: true }}
                                            label={t("reports.endDate")}
                                            views={["year", "month"]}
                                        />
                                    </Grid>
                                </>
                            )}
                            <Grid item xs={12} md={3}>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={12} md={3} xl={2} sx={{ paddingLeft: "5px !important" }}>
                                        <Checkbox
                                            name="checkedOthers"
                                            checked={values.checkedOthers}
                                            onChange={(value) => handleChangeCheck(value, 10)}
                                            inputProps={{
                                                "aria-label": "controlled",
                                            }}
                                            disabled={!values.initDate}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={9} xl={10}>
                                        <TextField
                                            fullWidth
                                            disabled={!values.checkedOthers}
                                            label={t("services.others")}
                                            name="nameOthers"
                                            onChange={handleChange}
                                            value={values.nameOthers}
                                            variant="outlined"
                                            error={values.checkedOthers && isError && !values.nameOthers}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    fullWidth
                                    disabled={!values.checkedOthers}
                                    label={t("miscellaneous.hoursMonth")}
                                    name="tothOthers"
                                    onChange={handleChange}
                                    value={values.tothOthers}
                                    variant="outlined"
                                    type="number"
                                    inputProps={{
                                        min: 0,
                                        step: 0.5,
                                        onKeyDown: (event) => {
                                            event.preventDefault();
                                        },
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    error={values.checkedOthers && isError && values.tothOthers === 0}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    disabled={!values.checkedOthers}
                                    value={values.startDateOthers}
                                    onChange={(newValue) => handleChangeStartDateAdd(newValue, 4)}
                                    minDate={moment(values.initDate).startOf("month")}
                                    maxDate={moment(values.endDate).endOf("month")}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            required={values.checkedOthers}
                                            {...params}
                                            error={values.checkedOthers && isError && !values.startDateOthers}
                                        />
                                    )}
                                    inputProps={{ readOnly: true }}
                                    label={t("reports.initDate")}
                                    views={["year", "month"]}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    disabled={!values.checkedOthers}
                                    value={values.finishDateOthers}
                                    onChange={(newValue) => handleChangeEndDateAdd(newValue, 4)}
                                    minDate={moment(values.initDate).startOf("month")}
                                    maxDate={moment(values.endDate).endOf("month")}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => <TextField fullWidth {...params} />}
                                    inputProps={{ readOnly: true }}
                                    label={t("reports.endDate")}
                                    views={["year", "month"]}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}></Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    fullWidth
                                    disabled
                                    label={t("services.hoursTotal")}
                                    value={sumTotalAddHours}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormControl fullWidth variant="outlined" disabled>
                                    <InputLabel htmlFor="outlined-adornment-hourlyRate">
                                        {t("services.hourlyRate")}
                                    </InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-hourlyRate"
                                        value={values.addHourFee}
                                        inputComponent={NumberFormatCustom}
                                        startAdornment={
                                            <InputAdornment disableTypography position="start">
                                                $
                                            </InputAdornment>
                                        }
                                        label={t("services.hourlyRate")}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormControl fullWidth variant="outlined" disabled>
                                    <InputLabel htmlFor="outlined-adornment-hourlyRateAdd">
                                        {t("services.hourlyRateAdd")}
                                    </InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-hourlyRateAdd"
                                        value={values.addHourFee * sumTotal}
                                        inputComponent={NumberFormatCustom}
                                        startAdornment={
                                            <InputAdornment disableTypography position="start">
                                                $
                                            </InputAdornment>
                                        }
                                        label={t("services.hourlyRateAdd")}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <Divider />
                    <Box className={classes.buttonContainer}>
                        <Button
                            color="secondary"
                            variant="contained"
                            disableElevation
                            classes={{ root: classes.btnRoot }}
                            onClick={updateProject}
                            disabled={
                                (accountSystem.disabledData === true &&
                                    (accountSystem.prov === "Xero" || accountSystem.prov === "Quickbooks Online")) ||
                                expenseEst === -1
                            }
                        >
                            {t("miscellaneous.save")}
                        </Button>
                    </Box>
                </Card>
            </LocalizationProvider>
        </StyledContainer>
    );
};
