/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

/** MUI icons */
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

/** MUI imports */
import { styled } from '@mui/material/styles';
import {
    Box,
    Breadcrumbs,
    Card,
    CardContent,
    Grid,
    Stack,
    Tab,
    Tabs,
    Typography,
} from '@mui/material';

/** Components imports */
import Backdrop from "../Backdrop";
import ProspectsActive from "./ProspectsActive";
import ProspectsArchived from "./ProspectsArchived";
import SnackBar from "../SnackBar";

const PREFIX = 'Prospects';

const classes = {
    cardContentRoot: `${PREFIX}-cardContentRoot`
};

const StyledContainer = styled(Box)((
    {
        theme
    }
) => ({
    [`& .${classes.cardContentRoot}`]: {
        '&:last-child': {
            paddingBottom: '18px'
        },
        padding: 30,
    }
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 1 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const CustomTab = styled(Tab)(({ theme }) => ({
    padding: 20,
    marginLeft: 8,
    borderRadius: '20px 20px 0 0',
    backgroundColor: '#E9EAE8',
    color: theme.palette.primary.main,
    fontWeight: 700,
    '&.Mui-selected': {
        borderTop: '2px solid #ff5968',
        borderRight: '2px solid #ff5968',
        borderLeft: '2px solid #ff5968',
        backgroundColor: '#fff !important',
    }
}));

const Prospects = () => {
    const navigate = useNavigate();

    /** Internationalization i18n */
    const [t] = useTranslation("global");

    /** Component states */
    const [showBackdrop, setBackDrop] = useState(false);
    const [show, setShow] = useState(false);
    const [texto, setTexto] = useState('');
    const [alertType, setAutoAlertType] = useState('');
    const [valueTab, setValueTab] = useState(0);

    /** Component functions */
    const handleChangeTab = async (event, newValue) => {
        setValueTab(newValue);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setShow(false);
    };

    return (
        <StyledContainer component="main" sx={{ flexGrow: 1, p: 4, pt: 10, width: '95%' }}>
            <Stack sx={{ pb: 4 }}>
                <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small" sx={{ color: '#031851' }} />}
                    aria-label="breadcrumb"
                >
                    {
                        <Typography key="1" variant="h1" color="primary" onClick= { () => navigate('/proposals') }
                            sx={{
                                cursor: 'pointer',
                                fontSize: 24,
                                fontWeight: 700,
                                '&:hover': {
                                    color: '#ff5968',
                                }
                            }}
                        >
                            {t('proposals.proposals')}
                        </Typography>
                    }
                    {
                        <Typography key="1" variant="h1" color="secondary" sx={{ fontSize: 24, fontWeight: 700 }}>
                            {t('proposals.prospects')}
                        </Typography>
                    }
                </Breadcrumbs>
            </Stack>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card elevation={0}
                        sx={{
                            borderRadius: 8,
                            boxShadow: '10px 10px 15px rgba(3,24,81,0.15)',
                        }}
                    >
                        <CardContent classes={{ root: classes.cardContentRoot }}>
                            <Box sx={{ width: '100%' }}>
                                <Box sx={{ borderBottom: 2, borderColor: '#031851' }}>
                                    <Grid container alignItems="center">
                                        <Grid item xs={12} md={7}>
                                            <Tabs value={valueTab} onChange={handleChangeTab} variant="scrollable"
                                                scrollButtons="auto" aria-label="bookkeper tabs"
                                                TabIndicatorProps={{
                                                    sx: {
                                                        display: 'none'
                                                    }
                                                }}
                                            >
                                                <CustomTab label={t('proposals.active')} {...a11yProps(0)} />
                                                <CustomTab label={t('proposals.archived')} {...a11yProps(1)} />
                                            </Tabs>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <TabPanel value={valueTab} index={0}>
                                    <ProspectsActive 
                                        setBackDrop={setBackDrop}
                                        setShow={setShow}
                                        setTexto={setTexto}
                                        setAutoAlertType={setAutoAlertType}
                                    />
                                </TabPanel>
                                <TabPanel value={valueTab} index={1}>
                                    <ProspectsArchived 
                                        setBackDrop={setBackDrop}
                                        setShow={setShow}
                                        setTexto={setTexto}
                                        setAutoAlertType={setAutoAlertType}
                                    />
                                </TabPanel>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Backdrop
                open={showBackdrop}
            />
            <SnackBar
                type={alertType}
                show={show}
                message={texto}
                onClose={handleClose}
            />
        </StyledContainer >
    );
}

export default Prospects;