/** Imports ---------------------------------------------------------------- */

/** React imports */
import React from "react";
import { useTranslation } from "react-i18next";

/** MUI imports */
import { styled } from "@mui/material/styles";
import {
    Box,
    Card,
    CardHeader,
    CardContent,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemText,
    Skeleton,
    Typography,
} from "@mui/material";

const PREFIX = "ClientMana";

const classes = {
    btnRoot: `${PREFIX}-btnRoot`,
    formRounded: `${PREFIX}-formRounded`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.btnRoot}`]: {
        border: "1px solid #ff5968",
        borderRadius: 40,
        "&:hover": {
            backgroundColor: "#2f3190",
            border: "1px solid #2f3190",
        },
    },

    [`& .${classes.formRounded}`]: {
        backgroundColor: "#E9EAE8 !important",
        borderRadius: 40,
        verticalAlign: "middle !important",
        "&:focus-within": {
            backgroundColor: "#E9EAE8",
            borderRadius: 40,
        },
        "& .MuiOutlinedInput-notchedOutline": {
            border: "2px solid #031851",
            borderRadius: 40,
        },
    },
}));

const ClientManaSkeletons = () => {
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    return (
        <StyledContainer component="main" sx={{ flexGrow: 1, p: 4, pt: 10, width: "100%" }}>
            <Typography
                variant="h1"
                sx={{
                    pb: 4,
                    fontSize: 24,
                    fontWeight: 700,
                }}
            >
                {t("principal.admCustom")}
            </Typography>
            <Grid container>
                <Grid item xs={12}>
                    <Grid container spacing={2} alignItems="center" sx={{ pb: 3 }}>
                        <Grid item xs={12} md={2}>
                            <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width="100%"
                                height={40}
                                sx={{ borderRadius: 8 }}
                            />
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width="100%"
                                height={40}
                                sx={{ borderRadius: 8 }}
                            />
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width="100%"
                                height={40}
                                sx={{ borderRadius: 8 }}
                            />
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width="100%"
                                height={40}
                                sx={{ borderRadius: 8 }}
                            />
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width="100%"
                                height={40}
                                sx={{ borderRadius: 8 }}
                            />
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <Skeleton variant="circular" width={50} height={50} animation="wave" />
                        </Grid>
                        <Grid item xs={12} md={1}>
                            <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width="100%"
                                height={40}
                                sx={{ borderRadius: 8 }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={12} lg={4}>
                    <Card
                        elevation={0}
                        sx={{
                            borderRadius: 8,
                            boxShadow: "10px 10px 15px rgba(3,24,81,0.15)",
                        }}
                    >
                        <CardHeader
                            title={
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Box>
                                        <Typography variant="h3" color="primary">
                                            {t("miscellaneous.clients")}
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            width: "200px",
                                            position: "relative",
                                        }}
                                    >
                                        {
                                            <Skeleton
                                                variant="text"
                                                animation="wave"
                                                height={15}
                                                width="55%"
                                                sx={{
                                                    position: "absolute",
                                                    right: 0,
                                                }}
                                            />
                                        }
                                    </Box>
                                </Box>
                            }
                            sx={{
                                m: 2,
                                borderBottom: "2px solid #FF5968",
                            }}
                        />
                        <CardContent sx={{ pt: 0 }}>
                            <Skeleton
                                variant="rectangular"
                                animation="wave"
                                height={52}
                                sx={{ borderRadius: 25, ml: 0, mb: "10px" }}
                            />
                            <List component="nav">
                                {Array(5).fill(
                                    <>
                                        <ListItem
                                            sx={{
                                                p: "12px 16px !important",
                                            }}
                                        >
                                            <ListItemText
                                                primary={
                                                    <Skeleton variant="text" animation="wave" height={15} width="50%" />
                                                }
                                            />
                                        </ListItem>
                                        <Divider />
                                        <ListItem
                                            sx={{
                                                p: "12px 16px !important",
                                            }}
                                        >
                                            <ListItemText
                                                primary={
                                                    <Skeleton variant="text" animation="wave" height={15} width="35%" />
                                                }
                                            />
                                        </ListItem>
                                        <Divider />
                                    </>
                                )}
                            </List>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </StyledContainer>
    );
};

export default ClientManaSkeletons;
