/** React imports */
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

/** Images */

/** MUI icons */
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

/** MUI imports */
import { Box, Typography, Grid, Divider } from "@mui/material";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

import { Footer } from "../Core";

/** Global variables */
const PREFIX = "ServiceLevelsBook2";

const classes = {
    body2: `${PREFIX}-body2`,
    serviceName: `${PREFIX}-serviceName`,
    serviceBody: `${PREFIX}-serviceBody`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.serviceName}`]: {
        fontSize: 22,
    },

    [`& .${classes.body2}`]: {
        backgroundColor: "white",
        marginTop: 15,
        minHeight: 850,
        [theme.breakpoints.down("md")]: {
            padding: 30,
        },
        [theme.breakpoints.up("md")]: {
            padding: 60,
        },
    },

    [`& .${classes.serviceBody}`]: {
        backgroundColor: "#F8F9F8",
        marginTop: 30,
        padding: 30,
        borderRadius: 30,
    },
}));

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)({
    boxShadow: "none",
    "&:before": {
        display: "none",
    },
    backgroundColor: "#F8F9F8",
});

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: "#031851" }} />} {...props} />
))({
    padding: 0,
    "& .MuiAccordionSummary-content": {
        margin: "12px 0",
    },
});

const AccordionDetails = styled(MuiAccordionDetails)({
    padding: "0px 0px 16px 0px",
    "& .MuiCard-root": {
        boxShadow: "none",
    },
});

export const ServiceLevelsBook2 = (props) => {
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    const { bookInfo } = props;

    const [expandedSubOnb, setExpandedSubOnb] = useState(false);
    const [expandedSub, setExpandedSub] = useState(false);
    const [expandedSubAdd, setExpandedSubAdd] = useState(false);

    const handleChangeOnbSub = (panel) => (event, isExpanded) => {
        setExpandedSubOnb(isExpanded ? panel : false);
    };

    const handleChangeAccSub = (panel) => (event, isExpanded) => {
        setExpandedSub(isExpanded ? panel : false);
    };

    const handleChangeAccSubAdd = (panel) => (event, isExpanded) => {
        setExpandedSubAdd(isExpanded ? panel : false);
    };

    return (
        <StyledContainer>
            <Box className={classes.body2}>
                <Typography variant="h5" className={classes.serviceName}>
                    {bookInfo.name}
                </Typography>
                <Typography variant="h5" sx={{ pt: 2 }}>
                    {t("proposals.scopeWorkSMBB")}
                </Typography>
                <Box className={classes.serviceBody}>
                    {bookInfo.onboarding.isActive && (
                        <Accordion
                            defaultExpanded
                            TransitionProps={{ unmountOnExit: true }}
                            sx={{
                                borderBottom: "3px solid #ff5968",
                            }}
                        >
                            <AccordionSummary aria-controls="panelAuxbh-content" id="panelAuxbh-header">
                                <Typography variant="h4">SMB Onboarding</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Divider sx={{ mb: 2 }} />
                                <Grid container spacing={3} alignItems="center">
                                    <Grid item xs={12} md={4}>
                                        <Typography variant="body1">{t("proposals.intelB")}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <Typography variant="body1">{t("proposals.deepUnderBookB")}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Typography variant="body1">{t("proposals.designB")}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <Typography variant="body1">{t("proposals.finanStateBookB")}</Typography>
                                    </Grid>
                                </Grid>
                                <Accordion
                                    expanded={expandedSubOnb === "SMB Onboarding"}
                                    onChange={handleChangeOnbSub("SMB Onboarding")}
                                    TransitionProps={{
                                        unmountOnExit: true,
                                    }}
                                    sx={{ pt: 1 }}
                                >
                                    <AccordionSummary aria-controls="panelAuxbh-content" id="panelAuxbh-header">
                                        <Grid container spacing={2} alignItems="center">
                                            <Grid item xs={12} md={4}>
                                                <Typography variant="body1">{t("proposals.accModelSetB")}</Typography>
                                            </Grid>
                                            <Grid item xs={12} md={8}>
                                                <Typography variant="body1" sx={{ pl: "10px" }}>
                                                    {t("proposals.impleProcBookB")}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Divider sx={{ mb: 2 }} />
                                        <Box sx={{ p: "0 30px" }}>
                                            <Grid container spacing={2} alignItems="center">
                                                <Grid item xs={12} md={4}>
                                                    <Typography variant="body1">{t("proposals.chartAccB")}</Typography>
                                                </Grid>
                                                <Grid item xs={12} md={8}>
                                                    <Typography variant="body1">{t("proposals.mapPerB")}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </AccordionDetails>
                                </Accordion>
                            </AccordionDetails>
                        </Accordion>
                    )}
                    {(bookInfo.recurring.isActive || bookInfo.cleanUp.isActive || bookInfo.catchUp.isActive) && (
                        <Accordion
                            defaultExpanded
                            TransitionProps={{ unmountOnExit: true }}
                            sx={{
                                borderBottom: "3px solid #ff5968",
                            }}
                        >
                            <AccordionSummary aria-controls="panelAuxbh-content" id="panelAuxbh-header">
                                <Typography variant="h4">SMBs Bookkeeping (Recurring, Catch-ups & Clean-ups)</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Divider sx={{ mb: 2 }} />
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={12} md={4}>
                                        <Typography variant="body1">{t("proposals.dediBookB")}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <Typography variant="body1">{t("proposals.aPubBookB")}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Accordion
                                            expanded={expandedSub === "Bank reconciliations"}
                                            onChange={handleChangeAccSub("Bank reconciliations")}
                                            TransitionProps={{
                                                unmountOnExit: true,
                                            }}
                                        >
                                            <AccordionSummary aria-controls="panelAuxbh-content" id="panelAuxbh-header">
                                                <Grid container spacing={2} alignItems="center">
                                                    <Grid item xs={12} md={4}>
                                                        <Typography variant="body1">
                                                            {t("proposals.bankRecB")}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} md={8}>
                                                        <Typography variant="body1" sx={{ pl: "7px" }}>
                                                            {t("proposals.bankRecDesB")}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Divider sx={{ mb: 2 }} />
                                                <Box sx={{ p: "0 30px" }}>
                                                    <Grid container spacing={2} alignItems="center">
                                                        <Grid item xs={12} md={4}>
                                                            <Typography variant="body1">
                                                                {t("proposals.bankSalB")}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} md={8}>
                                                            <Typography variant="body1">
                                                                {t("proposals.conBankB")}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} md={4}>
                                                            <Typography variant="body1">
                                                                {t("proposals.bankRecB")}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} md={8}>
                                                            <Typography variant="body1">
                                                                {t("proposals.regBankB")}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Divider
                                                        sx={{
                                                            mt: 2,
                                                            backgroundColor: "#ff5968",
                                                        }}
                                                    />
                                                </Box>
                                            </AccordionDetails>
                                        </Accordion>

                                        <Accordion
                                            expanded={expandedSub === "Modified cash accounting"}
                                            onChange={handleChangeAccSub("Modified cash accounting")}
                                            TransitionProps={{
                                                unmountOnExit: true,
                                            }}
                                        >
                                            <AccordionSummary aria-controls="panelAuxbh-content" id="panelAuxbh-header">
                                                <Grid container spacing={2} alignItems="center">
                                                    <Grid item xs={12} md={4}>
                                                        <Typography variant="body1">
                                                            {t("proposals.modCashAccB")}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} md={8}>
                                                        <Typography variant="body1" sx={{ pl: "7px" }}>
                                                            {t("proposals.tranReCashB")}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Divider sx={{ mb: 2 }} />
                                                <Box sx={{ p: "0 30px" }}>
                                                    <Grid container spacing={2} alignItems="center">
                                                        <Grid item xs={12} md={4}>
                                                            <Typography variant="body1">
                                                                {t("proposals.actFijB")}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} md={8}>
                                                            <Typography variant="body1">
                                                                {t("proposals.regActiveDepB")}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} md={4}>
                                                            <Typography variant="body1">
                                                                {t("proposals.loansB")}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} md={8}>
                                                            <Typography variant="body1">
                                                                {t("proposals.recordsLoansB")}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} md={4}>
                                                            <Typography variant="body1">
                                                                {t("principal.taxesB")}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} md={8}>
                                                            <Typography variant="body1">
                                                                {t("proposals.recordReTaxB")}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} md={4}>
                                                            <Typography variant="body1">
                                                                {t("proposals.equityB")}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} md={8}>
                                                            <Typography variant="body1">
                                                                {t("proposals.recordsEqB")}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} md={4}>
                                                            <Typography variant="body1">
                                                                {t("proposals.salesRevenueB")}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} md={8}>
                                                            <Typography variant="body1">
                                                                {t("proposals.recordsSalesB")}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} md={4}>
                                                            <Typography variant="body1">
                                                                {t("proposals.costsB")}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} md={8}>
                                                            <Typography variant="body1">
                                                                {t("proposals.recordCostB")}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} md={4}>
                                                            <Typography variant="body1">
                                                                {t("proposals.expensesB")}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} md={8}>
                                                            <Typography variant="body1">
                                                                {t("proposals.recordOpeB")}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} md={4}>
                                                            <Typography variant="body1">
                                                                {t("invoicing.payroll")}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} md={8}>
                                                            <Typography variant="body1">
                                                                {t("proposals.recordRePayB")}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Divider
                                                        sx={{
                                                            mt: 2,
                                                            backgroundColor: "#ff5968",
                                                        }}
                                                    />
                                                </Box>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Typography variant="body1">{t("proposals.finannStateB")}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <Typography variant="body1">{t("proposals.balanceGen2B")}</Typography>
                                    </Grid>
                                </Grid>
                                {/* {((bookInfo.recurring.additionals.length !== 0 && !bookInfo.recurring.onlyAdd) ||
                                    (bookInfo.catchUp.additionals.length !== 0 && !bookInfo.catchUp.onlyAdd) ||
                                    (bookInfo.cleanUp.additionals.length !== 0 && !bookInfo.cleanUp.onlyAdd)) && ( */}


                                    <Grid container spacing={2} alignItems="center" sx={{ p: "15px 64px 0px 64px" }}>
                                        <Grid item xs={12}>
                                            <Typography variant="h4">{t("proposals.addonsB")}</Typography>
                                            <Divider
                                                sx={{
                                                    mt: 1,
                                                    backgroundColor: "#CCCCCC",
                                                }}
                                            />
                                        </Grid>
                                        {bookInfo.genAdditionals.exe && (
                                            <>
                                                <Grid item xs={12} md={4}>
                                                    <Typography variant="body1">
                                                        {t("services.executionTaxPlanB")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} md={8}>
                                                    <Typography variant="body1">
                                                        {t("services.executionTaxPlanDetB")}
                                                    </Typography>
                                                </Grid>
                                            </>
                                        )}
                                        {bookInfo.genAdditionals.cons && (
                                            <>
                                                <Grid item xs={12} md={4}>
                                                    <Typography variant="body1">
                                                        {t("services.consolidationStatementsB")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} md={8}>
                                                    <Typography variant="body1">
                                                        {t("services.consolidationStatementsDetB")}
                                                    </Typography>
                                                </Grid>
                                            </>
                                        )}
                                        {bookInfo.genAdditionals.rep && (
                                            <>
                                                <Grid item xs={12} md={4}>
                                                    <Typography variant="body1">
                                                        {t("services.reportsAuditorsB")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} md={8}>
                                                    <Typography variant="body1">
                                                        {t("services.reportsAuditorsDetB")}
                                                    </Typography>
                                                </Grid>
                                            </>
                                        )}
                                        {/* {bookInfo.genAdditionals.checks && ( */}
                                            <>
                                                <Grid item xs={12} md={4}>
                                                    <Typography variant="body1">{t("proposals.checksB")}</Typography>
                                                </Grid>
                                                <Grid item xs={12} md={8}>
                                                    <Typography variant="body1">{t("proposals.checkBenB")}</Typography>
                                                </Grid>
                                            </>
                                        {/* )} */}
                                        {/* {bookInfo.genAdditionals.ar && ( */}
                                            <>
                                                <Grid item xs={12} md={4}>
                                                    <Typography variant="body1">{t("proposals.addARB")}</Typography>
                                                </Grid>
                                                <Grid item xs={12} md={8}>
                                                    <Typography variant="body1">{t("proposals.addARAccB")}</Typography>
                                                </Grid>
                                            </>
                                        {/* )} */}
                                        {/* {bookInfo.genAdditionals.ap && ( */}
                                            <>
                                                <Grid item xs={12} md={4}>
                                                    <Typography variant="body1">{t("proposals.addAPB")}</Typography>
                                                </Grid>
                                                <Grid item xs={12} md={8}>
                                                    <Typography variant="body1">{t("proposals.addAPAccB")}</Typography>
                                                </Grid>
                                            </>
                                        {/* )} */}
                                        {/* {bookInfo.genAdditionals.accrual && ( */}
                                            <Grid item xs={12}>
                                                <Accordion
                                                    TransitionProps={{
                                                        unmountOnExit: true,
                                                    }}
                                                >
                                                    <AccordionSummary
                                                        aria-controls="panelAuxbh-content"
                                                        id="panelAuxbh-header"
                                                    >
                                                        <Grid container spacing={2} alignItems="center">
                                                            <Grid item xs={12} md={4}>
                                                                <Typography variant="body1">
                                                                    {t("services.accrualAccountingB")}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12} md={8}>
                                                                <Typography variant="body1">
                                                                    {t("services.accDetB")}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Divider sx={{ mb: 2 }} />
                                                        <Box sx={{ p: "0 30px" }}>
                                                            <Grid container spacing={2} alignItems="center">
                                                                <Grid item xs={12} md={4}>
                                                                    <Typography variant="body1">
                                                                        {t("proposals.prepaidB")}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={12} md={8}>
                                                                    <Typography variant="body1">
                                                                        {t("proposals.regActPayB")}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={12} md={4}>
                                                                    <Typography variant="body1">
                                                                        {t("proposals.inventB")}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={12} md={8}>
                                                                    <Typography variant="body1">
                                                                        {t("proposals.regProdB")}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={12} md={4}>
                                                                    <Typography variant="body1">
                                                                        {t("proposals.liabiProB")}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={12} md={8}>
                                                                    <Typography variant="body1">
                                                                        {t("proposals.recordInLiaB")}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </Grid>
                                        {/* )} */}
                                        {/* {bookInfo.genAdditionals.classes && ( */}
                                            <>
                                                <Grid item xs={12} md={4}>
                                                    <Typography variant="body1">
                                                        {t("services.classesCategoriesB")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} md={8}>
                                                    <Typography variant="body1">
                                                        {t("proposals.addCostsCentB")}
                                                    </Typography>
                                                </Grid>
                                            </>
                                        {/* )} */}
                                        {/* {bookInfo.genAdditionals.multi && ( */}
                                            <>
                                                <Grid item xs={12} md={4}>
                                                    <Typography variant="body1">
                                                        {t("proposals.multiEntityB")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} md={8}>
                                                    <Typography variant="body1">
                                                        {t("proposals.addRecordReB")}
                                                    </Typography>
                                                </Grid>
                                            </>
                                        {/* )} */}
                                        {/* {bookInfo.genAdditionals.person && ( */}
                                            <>
                                                <Grid item xs={12} md={4}>
                                                    <Typography variant="body1">{t("proposals.addPerRepB")}</Typography>
                                                </Grid>
                                                <Grid item xs={12} md={8}>
                                                    <Typography variant="body1">{t("proposals.addTayMadeB")}</Typography>
                                                </Grid>
                                            </>
                                        {/* )} */}
                                        {bookInfo.genAdditionals.sales && (
                                            <>
                                                <Grid item xs={12} md={4}>
                                                    <Typography variant="body1">{t("proposals.addSalesB")}</Typography>
                                                </Grid>
                                                <Grid item xs={12} md={8}>
                                                    <Typography variant="body1">
                                                        {t("proposals.addSalesDetB")}
                                                    </Typography>
                                                </Grid>
                                            </>
                                        )}
                                        {bookInfo.genAdditionals.ordinary && (
                                            <Grid item xs={12}>
                                                <Accordion
                                                    expanded={expandedSubAdd === "Ordinary contributor taxes"}
                                                    onChange={handleChangeAccSubAdd("Ordinary contributor taxes")}
                                                    TransitionProps={{
                                                        unmountOnExit: true,
                                                    }}
                                                >
                                                    <AccordionSummary
                                                        aria-controls="panelAuxbh-content"
                                                        id="panelAuxbh-header"
                                                    >
                                                        <Grid container spacing={2} alignItems="center">
                                                            <Grid item xs={12} md={4}>
                                                                <Typography variant="body1">
                                                                    {t("proposals.addOrdTaxB")}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12} md={8}>
                                                                <Typography variant="body1" sx={{ pl: "7px" }}>
                                                                    {t("proposals.addOrdTaxDetB")}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Divider sx={{ mb: 2 }} />
                                                        <Box sx={{ p: "0 30px" }}>
                                                            <Grid container spacing={2} alignItems="center">
                                                                <Grid item xs={12} md={4}>
                                                                    <Typography variant="body1">
                                                                        {t("proposals.addSubWithB")}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={12} md={8}>
                                                                    <Typography variant="body1">
                                                                        {t("proposals.addSubWithDetB")}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={12} md={4}>
                                                                    <Typography variant="body1">
                                                                        {t("proposals.addSubIvaB")}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={12} md={8}>
                                                                    <Typography variant="body1">
                                                                        {t("proposals.addSubIvaDetB")}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </Grid>
                                        )}
                                        {bookInfo.genAdditionals.special && (
                                            <Grid item xs={12}>
                                                <Accordion
                                                    expanded={expandedSubAdd === "Special contributor taxes"}
                                                    onChange={handleChangeAccSubAdd("Special contributor taxes")}
                                                    TransitionProps={{
                                                        unmountOnExit: true,
                                                    }}
                                                >
                                                    <AccordionSummary
                                                        aria-controls="panelAuxbh-content"
                                                        id="panelAuxbh-header"
                                                    >
                                                        <Grid container spacing={2} alignItems="center">
                                                            <Grid item xs={12} md={4}>
                                                                <Typography variant="body1">
                                                                    {t("proposals.addSpecialB")}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12} md={8}>
                                                                <Typography variant="body1" sx={{ pl: "7px" }}>
                                                                    {t("proposals.addSpecialDetB")}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Divider sx={{ mb: 2 }} />
                                                        <Box sx={{ p: "0 30px" }}>
                                                            <Grid container spacing={2} alignItems="center">
                                                                <Grid item xs={12} md={4}>
                                                                    <Typography variant="body1">
                                                                        {t("proposals.addSubRetISLRB")}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={12} md={8}>
                                                                    <Typography variant="body1">
                                                                        {t("proposals.addSubRetB")}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={12} md={4}>
                                                                    <Typography variant="body1">
                                                                        {t("proposals.addSubVatTaxB")}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={12} md={8}>
                                                                    <Typography variant="body1">
                                                                        {t("proposals.addSubVatTaxDetB")}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={12} md={4}>
                                                                    <Typography variant="body1">
                                                                        {t("proposals.addSubValueB")}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={12} md={8}>
                                                                    <Typography variant="body1">
                                                                        {t("proposals.addSubValueDetB")}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={12} md={4}>
                                                                    <Typography variant="body1">
                                                                        {t("proposals.addSubPrepaidB")}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={12} md={8}>
                                                                    <Typography variant="body1">
                                                                        {t("proposals.addSubPrepaidDetB")}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={12} md={4}>
                                                                    <Typography variant="body1">
                                                                        {t("proposals.addSubPrepaidIGTFB")}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={12} md={8}>
                                                                    <Typography variant="body1">
                                                                        {t("proposals.addSubPrepaidIGTFDetB")}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </Grid>
                                        )}
                                        {bookInfo.genAdditionals.muni && (
                                            <>
                                                <Grid item xs={12} md={4}>
                                                    <Typography variant="body1">{t("proposals.addImpMunB")}</Typography>
                                                </Grid>
                                                <Grid item xs={12} md={8}>
                                                    <Typography variant="body1">
                                                        {t("proposals.addImpMunDetB")}
                                                    </Typography>
                                                </Grid>
                                            </>
                                        )}
                                        {/* {bookInfo.genAdditionals.other && ( */}
                                            <Accordion
                                                expanded={expandedSub === "Others"}
                                                onChange={handleChangeAccSub("Others")}
                                                TransitionProps={{
                                                    unmountOnExit: true,
                                                }}
                                                sx={{ width: "100%", pl: "16px", pt: "5px" }}
                                            >
                                                <AccordionSummary
                                                    aria-controls="panelAuxbh-content"
                                                    id="panelAuxbh-header"
                                                >
                                                    <Grid container spacing={2} alignItems="center">
                                                        <Grid item xs={12} md={4}>
                                                            <Typography variant="body1">
                                                                {t("proposals.addOthersB")}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} md={8} sx={{ pl: "16px" }}>
                                                            <Typography variant="body1" sx={{ pl: "7px" }}>
                                                                {t("proposals.addOthersDetBookB")}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Divider sx={{ mb: 2 }} />
                                                    <Box sx={{ p: "0 30px" }}>
                                                        <Grid container spacing={2} alignItems="center">
                                                            {bookInfo.recurring.additionals.filter(
                                                                (item) => item.additional_name === "Others"
                                                            ).length > 0 && (
                                                                <Grid item xs={12}>
                                                                    <Typography variant="body1">
                                                                        {
                                                                            bookInfo.recurring.additionals.filter(
                                                                                (item) =>
                                                                                    item.additional_name === "Others"
                                                                            )[0].other_name
                                                                        }
                                                                    </Typography>
                                                                </Grid>
                                                            )}
                                                            {bookInfo.catchUp.additionals.filter(
                                                                (item) => item.additional_name === "Others"
                                                            ).length > 0 && (
                                                                <Grid item xs={12}>
                                                                    <Typography variant="body1">
                                                                        {
                                                                            bookInfo.catchUp.additionals.filter(
                                                                                (item) =>
                                                                                    item.additional_name === "Others"
                                                                            )[0].other_name
                                                                        }
                                                                    </Typography>
                                                                </Grid>
                                                            )}
                                                            {bookInfo.cleanUp.additionals.filter(
                                                                (item) => item.additional_name === "Others"
                                                            ).length > 0 && (
                                                                <Grid item xs={12}>
                                                                    <Typography variant="body1">
                                                                        {
                                                                            bookInfo.cleanUp.additionals.filter(
                                                                                (item) =>
                                                                                    item.additional_name === "Others"
                                                                            )[0].other_name
                                                                        }
                                                                    </Typography>
                                                                </Grid>
                                                            )}
                                                        </Grid>
                                                    </Box>
                                                </AccordionDetails>
                                            </Accordion>
                                        {/* )} */}
                                    </Grid>
                                {/* // )} */}
                            </AccordionDetails>
                        </Accordion>
                    )}
                </Box>
            </Box>
            <Footer />
        </StyledContainer>
    );
};
