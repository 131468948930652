/** React imports */
import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, ArcElement, Title, Tooltip, Legend } from "chart.js";
import { Bar, Doughnut } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

/** MUI icons */
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

/** MUI imports */
import { styled } from "@mui/material/styles";
import {
    Box,
    Breadcrumbs,
    Card,
    CardHeader,
    CardContent,
    Grid,
    LinearProgress,
    List,
    ListItem,
    Skeleton,
    Stack,
    Typography,
    Chip,
} from "@mui/material";

import moment from "moment";

ChartJS.register(ChartDataLabels, ArcElement, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const PREFIX = "Dashboard";

const classes = {
    paper: `${PREFIX}-paper`,
    commonGreen: `${PREFIX}-commonGreen`,
    commonRed: `${PREFIX}-commonRed`,
    commonPurple: `${PREFIX}-commonPurple`,
    chip: `${PREFIX}-chip`,
    selectedChip: `${PREFIX}-selectedChip`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.paper}`]: {
        padding: "10px 10px 0px",
        border: "2px solid #ff5968",
        borderRadius: 40,
        boxShadow: "none",
    },

    [`& .${classes.commonPurple}`]: {
        color: theme.palette.text.secondary,
    },

    [`& .${classes.commonGreen}`]: {
        color: "#4caf50",
    },

    [`& .${classes.commonRed}`]: {
        color: "#ff5e77",
    },

    [`& .${classes.chip}`]: {
        padding: 3,
        width: 95,
        backgroundColor: "#EAEAF4",
        color: "#031851",
        borderRadius: 40,
        "&:hover": {
            backgroundColor: "rgba(213, 214, 233, 0.3)",
            color: "#031851",
        },
        marginBottom: 0.5,
        marginTop: 0.5,
    },

    [`& .${classes.selectedChip}`]: {
        padding: 3,
        width: 95,
        backgroundColor: "#2f3190",
        color: "#fff",
        borderRadius: 40,
        "&:hover": {
            backgroundColor: "rgba(47, 49, 144, 0.9)",
            color: "#fff",
        },
        marginBottom: 0.5,
        marginTop: 0.5,
    },
}));

const Dashboard = () => {
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    /* React redux */
    const dispatch = useDispatch();
    const todos = useSelector((state) => state.todos);

    /* React Router */
    const navigate = useNavigate();

    /** Component References */
    const marketCardRef = useRef(null);
    const leftDashboardRef = useRef(null);

    /** Component variables */
    const breadcrumbs = [
        <Typography
            key="1"
            variant="h1"
            color="primary"
            sx={{
                fontSize: 24,
                fontWeight: 700,
            }}
        >
            {t("principal.dashboard")}
        </Typography>,
        <Typography
            key="2"
            variant="h1"
            color="secondary"
            sx={{
                fontSize: 24,
                fontWeight: 700,
            }}
        >
            {t("principal.general")}
        </Typography>,
    ];

    /** Component states */
    const [loading, setLoading] = useState(true);
    const [loadingCierres, setLoadingCierres] = useState(false);
    const [valuesDash, setValuesDash] = useState({
        totalClients: "",
        growth: "",
        smbsCount: "",
        smbsGrowth: "",
        firmsCount: "",
        firmsGrowth: "",
        stdCount: "",
        stdGrowth: "",
        vipCount: "",
        vipGrowth: "",
        pendingCurrentMonth: "",
        pendingLastMonth: "",
        pendingLastTwoMonths: "",
        pendingCurrentPercentage: 0,
        totalBkaOrganisations: "",
        totalServices: "",
        topClients: [],
    });
    const [valuesHeights, setValuesHeights] = useState({
        leftDashboard: 0,
        marketCard: 0,
    });
    const [servicesChartData, setServicesChartData] = useState({});
    const [marketChartData, setMarketChartData] = useState({});
    const [analystsChartData, setAnalystsChartData] = useState({});
    const [chip, setChip] = useState("all");

    /** Charts Options */
    const chartClientsServOptions = {
        maintainAspectRatio: false,
        indexAxis: "y",
        plugins: {
            tooltip: {
                backgroundColor: "rgba(3, 24, 81, 0.80)",
            },
            legend: {
                display: false,
            },
            datalabels: {
                color: "#031851",
                font: {
                    family: "GothamRounded",
                    weight: "600",
                },
            },
        },
        scales: {
            xAxes: {
                display: false,
                grid: {
                    drawBorder: false,
                },
                ticks: {
                    color: "#031851",
                },
            },
            yAxes: {
                ticks: {
                    color: "#031851",
                    font: {
                        size: 14,
                        family: "GothamRounded",
                        style: moment.locale() === "es" ? "italic" : "normal",
                    },
                },
            },
        },
    };

    const chartMarketOptions = {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            tooltip: {
                backgroundColor: "rgba(3, 24, 81, 0.80)",
            },
            datalabels: {
                color: "#031851",
                font: {
                    family: "GothamRounded",
                    weight: "600",
                },
            },
            legend: {
                labels: {
                    color: "#131f3e",
                    font: {
                        size: 10,
                        family: "GothamRounded",
                    },
                    boxWidth: 10,
                    padding: 15,
                },
                position: "right",
            },
        },
    };

    const chartAnalystsOptions = {
        maintainAspectRatio: false,
        plugins: {
            tooltip: {
                backgroundColor: "rgba(3, 24, 81, 0.80)",
            },
            datalabels: {
                display: "auto",
                color: "#031851",
                font: {
                    family: "GothamRounded",
                    weight: "600",
                },
            },
            legend: {
                labels: {
                    color: "#131f3e",
                    font: {
                        size: 10,
                        family: "GothamRounded",
                        style: moment.locale() === "es" ? "italic" : "normal",
                    },
                    boxWidth: 10,
                    padding: 15,
                },
                position: "right",
            },
        },
        scales: {
            xAxes: {
                stacked: true,
                ticks: {
                    color: "#031851",
                },
            },
            yAxes: {
                display: false,
                grid: {
                    drawBorder: false,
                },
                stacked: true,
                ticks: {
                    color: "#031851",
                    font: {
                        size: 14,
                        family: "GothamRounded",
                    },
                },
            },
        },
    };

    const goToClient = (value) => {
        let practice_name = value.practiceName === "SMB's" ? "N/A" : value.practiceName;
        let franchise_name = value.franchiseName === "SMB's" ? "N/A" : value.franchiseName;
        dispatch({
            type: "addSelected",
            item: "ddlManage",
        });
        navigate(`/clientManagement?clientID=${value._id}&groupName=${practice_name}&franqName=${franchise_name}`);
    };

    useEffect(() => {
        (async () => {
            setValuesHeights({
                marketCard: marketCardRef.current.clientHeight,
                leftDashboard: leftDashboardRef.current.scrollHeight,
            });

            await fetch(`/getGeneralDashboard`, {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
                .then((res) => res.json())
                .then((data) => {
                    setValuesDash({
                        totalClients: data.clientsTotal.total,
                        growth: data.clientsTotal.growth,
                        smbsCount: data.clientsTypes.smbsCount,
                        smbsGrowth: data.clientsTypes.smbsGrowth,
                        firmsCount: data.clientsTypes.firmsCount,
                        firmsGrowth: data.clientsTypes.firmsGrowth,
                        stdCount: data.clientsStatus.stdCount,
                        stdGrowth: data.clientsStatus.stdGrowth,
                        vipCount: data.clientsStatus.vipCount,
                        vipGrowth: data.clientsStatus.vipGrowth,
                        pendingCurrentMonth: data.clientsNotifications.pendingCurrentMonth,
                        pendingLastMonth: data.clientsNotifications.pendingLastMonth,
                        pendingLastTwoMonths: data.clientsNotifications.pendingLastTwoMonths,
                        pendingCurrentPercentage: Math.floor(
                            (data.clientsNotifications.pendingCurrentMonth /
                                data.clientsNotifications.totalBkaOrganisations) *
                                100
                        ),
                        totalBkaOrganisations: data.clientsNotifications.totalBkaOrganisations,
                        totalServices: data.clientsServices
                            .map((item) => item.count)
                            .reduce((prev, curr) => prev + curr, 0),
                        topClients: data.topClients,
                    });

                    /** Clients Services Chart */
                    let clientsServicesChartData = [];
                    let clientsServicesChartLabels = [];

                    clientsServicesChartData = data.clientsServices.map((client) => {
                        return client.count;
                    });

                    clientsServicesChartLabels = data.clientsServices.map((client) => {
                        return client.serviceName;
                    });

                    setServicesChartData({
                        labels: clientsServicesChartLabels,
                        datasets: [
                            {
                                data: clientsServicesChartData,
                                backgroundColor: ["#fedbb6", "#f7b5d2", "#8ad3df", "#9ed4b0", "#D9DCE5", "#C1C1DE"],
                                borderColor: ["#FFC68A", "#DF669B", "#3A91A1", "#449961", "#4F5D85", "#6767A3"],
                                borderWidth: 2,
                            },
                        ],
                    });

                    /** Clients Market Chart */
                    setMarketChartData({
                        labels: ["USA", "Global", "Venezuela", "LATAM"],
                        datasets: [
                            {
                                data: Object.values(data.clientsMarket),
                                backgroundColor: ["#fedbb6", "#f7b5d2", "#8ad3df", "#9ed4b0"],
                                borderColor: ["#FFC68A", "#DF669B", "#3A91A1", "#449961"],
                                borderWidth: 2,
                            },
                        ],
                    });

                    /** Clients Analyst Chart */
                    let clientsAnalystsChartVipData = [];
                    let clientsAnalystsChartStdData = [];
                    let clientsAnalystsChartLabels = [];

                    clientsAnalystsChartVipData = data.clientsAnalysts.map((client) => {
                        return client.vipCount;
                    });

                    clientsAnalystsChartStdData = data.clientsAnalysts.map((client) => {
                        return client.stdCount;
                    });

                    clientsAnalystsChartLabels = data.clientsAnalysts.map((client) => {
                        return client.analystName;
                    });

                    setAnalystsChartData({
                        labels: clientsAnalystsChartLabels,
                        datasets: [
                            {
                                label: "VIP",
                                data: clientsAnalystsChartVipData,
                                backgroundColor: "#ACADD3",
                            },
                            {
                                label: "Standard",
                                data: clientsAnalystsChartStdData,
                                backgroundColor: "#E6E8EE",
                            },
                        ],
                    });
                });

            setLoading(false);
        })();
    }, []);

    const handleClickChip = async (value) => {
        setChip(value);
        setLoadingCierres(true);

        let finalValue = value === "all" ? "" : value;

        await fetch(`/getGeneralDashboard?serviceType=${finalValue}`, {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then((res) => res.json())
            .then((data) => {
                setValuesDash({
                    ...valuesDash,
                    pendingCurrentMonth: data.clientsNotifications.pendingCurrentMonth,
                    pendingLastMonth: data.clientsNotifications.pendingLastMonth,
                    pendingLastTwoMonths: data.clientsNotifications.pendingLastTwoMonths,
                    pendingCurrentPercentage: Math.floor(
                        (data.clientsNotifications.pendingCurrentMonth /
                            data.clientsNotifications.totalBkaOrganisations) *
                            100
                    ),
                    totalBkaOrganisations: data.clientsNotifications.totalBkaOrganisations,
                });
            });

        setLoadingCierres(false);
    };

    return (
        <StyledContainer component="main" sx={{ flexGrow: 1, p: 4, pt: 10, width: "100%" }}>
            <Stack sx={{ pb: 4 }}>
                <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small" sx={{ color: "#031851" }} />}
                    aria-label="breadcrumb"
                >
                    {breadcrumbs}
                </Breadcrumbs>
            </Stack>
            <Grid container spacing={2}>
                <Grid container item ref={leftDashboardRef} xs={12} xl={8} spacing={2}>
                    <Grid container item xs={12} spacing={2}>
                        <Grid item xs={12} md={6} xl={3}>
                            <Card
                                elevation={0}
                                sx={{
                                    height: valuesHeights.marketCard,
                                    p: "24px",
                                    borderRadius: 8,
                                    boxShadow: "10px 10px 15px rgba(3,24,81,0.15)",
                                }}
                            >
                                <CardHeader
                                    title={
                                        loading ? (
                                            <Skeleton variant="text" animation="wave" height={15} width="75%" />
                                        ) : (
                                            <Typography variant="h3" color="primary">
                                                {" "}
                                                {t("generalDashboard.totalActiveClients")}{" "}
                                            </Typography>
                                        )
                                    }
                                    sx={{
                                        p: 0,
                                        pb: 1,
                                        borderBottom: "2px solid #FF5968",
                                    }}
                                />
                                <CardContent sx={{ p: "40px 16px !important", textAlign: "center" }}>
                                    {loading ? (
                                        <Skeleton
                                            variant="text"
                                            animation="wave"
                                            height={80}
                                            width="60%"
                                            sx={{ display: "block", ml: "auto", mr: "auto" }}
                                        />
                                    ) : (
                                        <Typography color="primary" sx={{ fontSize: 40 }}>
                                            {valuesDash.totalClients}
                                            {valuesDash.growth > 0 ? (
                                                <ArrowUpwardIcon sx={{ fontSize: 30, color: "#4caf50", ml: 1 }} />
                                            ) : valuesDash.growth < 0 ? (
                                                <ArrowDownwardIcon sx={{ fontSize: 30, color: "#ff5e77", ml: 1 }} />
                                            ) : (
                                                <DragHandleIcon sx={{ fontSize: 30, color: "#2f3190", ml: 1 }} />
                                            )}
                                            <Typography
                                                component="span"
                                                variant="h4"
                                                className={
                                                    valuesDash.growth > 0
                                                        ? classes.commonGreen
                                                        : valuesDash.growth < 0
                                                        ? classes.commonRed
                                                        : classes.commonPurple
                                                }
                                            >
                                                {valuesDash.growth >= 0
                                                    ? Math.floor(valuesDash.growth)
                                                    : Math.floor(valuesDash.growth * -1)}
                                                %
                                            </Typography>
                                        </Typography>
                                    )}
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={6} xl={3}>
                            <Card
                                elevation={0}
                                sx={{
                                    height: valuesHeights.marketCard,
                                    p: "24px",
                                    borderRadius: 8,
                                    boxShadow: "10px 10px 15px rgba(3,24,81,0.15)",
                                }}
                            >
                                <CardHeader
                                    title={
                                        loading ? (
                                            <Skeleton variant="text" animation="wave" height={15} width="50%" />
                                        ) : (
                                            <Typography variant="h3" color="primary">
                                                {t("generalDashboard.clientType")}
                                            </Typography>
                                        )
                                    }
                                    sx={{
                                        p: 0,
                                        pb: 1,
                                        borderBottom: "2px solid #FF5968",
                                    }}
                                />
                                <CardContent sx={{ p: "40px 16px !important", textAlign: "center" }}>
                                    {loading ? (
                                        <Skeleton variant="text" animation="wave" height={30} sx={{ mb: 2 }} />
                                    ) : (
                                        <Grid
                                            container
                                            justifyContent="space-between"
                                            alignItems="flex-end"
                                            sx={{ pb: 2 }}
                                        >
                                            <Grid item>
                                                <Typography variant="h1" color="primary">
                                                    {t("miscellaneous.firms")}:
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="h1" color="primary">
                                                    {valuesDash.firmsCount}
                                                    {valuesDash.firmsGrowth > 0 ? (
                                                        <ArrowUpwardIcon
                                                            sx={{ fontSize: 20, color: "#4caf50", ml: 1 }}
                                                        />
                                                    ) : valuesDash.firmsGrowth < 0 ? (
                                                        <ArrowDownwardIcon
                                                            sx={{ fontSize: 20, color: "#ff5e77", ml: 1 }}
                                                        />
                                                    ) : (
                                                        <DragHandleIcon
                                                            sx={{ fontSize: 20, color: "#2f3190", ml: 1 }}
                                                        />
                                                    )}
                                                    <Typography
                                                        component="span"
                                                        variant="h4"
                                                        className={
                                                            valuesDash.firmsGrowth > 0
                                                                ? classes.commonGreen
                                                                : valuesDash.firmsGrowth < 0
                                                                ? classes.commonRed
                                                                : classes.commonPurple
                                                        }
                                                    >
                                                        {valuesDash.firmsGrowth >= 0
                                                            ? Math.floor(valuesDash.firmsGrowth)
                                                            : Math.floor(valuesDash.firmsGrowth * -1)}
                                                        %
                                                    </Typography>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    )}
                                    {loading ? (
                                        <Skeleton variant="text" animation="wave" height={30} />
                                    ) : (
                                        <Grid container justifyContent="space-between" alignItems="flex-end">
                                            <Grid item>
                                                <Typography variant="h1" color="primary">
                                                    {t("miscellaneous.smbs")}:
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="h1" color="primary">
                                                    {valuesDash.smbsCount}
                                                    {valuesDash.smbsGrowth > 0 ? (
                                                        <ArrowUpwardIcon
                                                            sx={{ fontSize: 20, color: "#4caf50", ml: 1 }}
                                                        />
                                                    ) : valuesDash.smbsGrowth < 0 ? (
                                                        <ArrowDownwardIcon
                                                            sx={{ fontSize: 20, color: "#ff5e77", ml: 1 }}
                                                        />
                                                    ) : (
                                                        <DragHandleIcon
                                                            sx={{ fontSize: 20, color: "#2f3190", ml: 1 }}
                                                        />
                                                    )}
                                                    <Typography
                                                        component="span"
                                                        variant="h4"
                                                        className={
                                                            valuesDash.smbsGrowth > 0
                                                                ? classes.commonGreen
                                                                : valuesDash.smbsGrowth < 0
                                                                ? classes.commonRed
                                                                : classes.commonPurple
                                                        }
                                                    >
                                                        {valuesDash.smbsGrowth >= 0
                                                            ? Math.floor(valuesDash.smbsGrowth)
                                                            : Math.floor(valuesDash.smbsGrowth * -1)}
                                                        %
                                                    </Typography>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    )}
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={6} xl={3}>
                            <Card
                                elevation={0}
                                sx={{
                                    height: valuesHeights.marketCard,
                                    p: "24px",
                                    borderRadius: 8,
                                    boxShadow: "10px 10px 15px rgba(3,24,81,0.15)",
                                }}
                            >
                                <CardHeader
                                    title={
                                        loading ? (
                                            <Skeleton variant="text" animation="wave" height={15} width="80%" />
                                        ) : (
                                            <Typography variant="h3" color="primary">
                                                {t("generalDashboard.clientClassification")}
                                            </Typography>
                                        )
                                    }
                                    sx={{
                                        p: 0,
                                        pb: 1,
                                        borderBottom: "2px solid #FF5968",
                                    }}
                                />
                                <CardContent sx={{ p: "40px 16px !important", textAlign: "center" }}>
                                    {loading ? (
                                        <Skeleton variant="text" animation="wave" height={30} sx={{ mb: 2 }} />
                                    ) : (
                                        <Grid
                                            container
                                            justifyContent="space-between"
                                            alignItems="flex-end"
                                            sx={{ pb: "16px" }}
                                        >
                                            <Grid item>
                                                <Typography variant="h1" color="primary">
                                                    VIP's:
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="h1" color="primary">
                                                    {valuesDash.vipCount}
                                                    {valuesDash.vipGrowth > 0 ? (
                                                        <ArrowUpwardIcon
                                                            sx={{ fontSize: 20, color: "#4caf50", ml: 1 }}
                                                        />
                                                    ) : valuesDash.vipGrowth < 0 ? (
                                                        <ArrowDownwardIcon
                                                            sx={{ fontSize: 20, color: "#ff5e77", ml: 1 }}
                                                        />
                                                    ) : (
                                                        <DragHandleIcon
                                                            sx={{ fontSize: 20, color: "#2f3190", ml: 1 }}
                                                        />
                                                    )}
                                                    <Typography
                                                        component="span"
                                                        variant="h4"
                                                        className={
                                                            valuesDash.vipGrowth > 0
                                                                ? classes.commonGreen
                                                                : valuesDash.vipGrowth < 0
                                                                ? classes.commonRed
                                                                : classes.commonPurple
                                                        }
                                                    >
                                                        {valuesDash.vipGrowth >= 0
                                                            ? Math.floor(valuesDash.vipGrowth)
                                                            : Math.floor(valuesDash.vipGrowth * -1)}
                                                        %
                                                    </Typography>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    )}
                                    {loading ? (
                                        <Skeleton variant="text" animation="wave" height={30} />
                                    ) : (
                                        <Grid container justifyContent="space-between" alignItems="flex-end">
                                            <Grid item>
                                                <Typography variant="h1" color="primary">
                                                    Std:
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="h1" color="primary">
                                                    {valuesDash.stdCount}
                                                    {valuesDash.stdGrowth > 0 ? (
                                                        <ArrowUpwardIcon
                                                            sx={{ fontSize: 20, color: "#4caf50", ml: 1 }}
                                                        />
                                                    ) : valuesDash.stdGrowth < 0 ? (
                                                        <ArrowDownwardIcon
                                                            sx={{ fontSize: 20, color: "#ff5e77", ml: 1 }}
                                                        />
                                                    ) : (
                                                        <DragHandleIcon
                                                            sx={{ fontSize: 20, color: "#2f3190", ml: 1 }}
                                                        />
                                                    )}
                                                    <Typography
                                                        component="span"
                                                        variant="h4"
                                                        className={
                                                            valuesDash.stdGrowth > 0
                                                                ? classes.commonGreen
                                                                : valuesDash.stdGrowth < 0
                                                                ? classes.commonRed
                                                                : classes.commonPurple
                                                        }
                                                    >
                                                        {valuesDash.stdGrowth >= 0
                                                            ? Math.floor(valuesDash.stdGrowth)
                                                            : Math.floor(valuesDash.stdGrowth * -1)}
                                                        %
                                                    </Typography>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    )}
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={6} xl={3}>
                            <Card
                                ref={marketCardRef}
                                elevation={0}
                                sx={{
                                    p: "24px",
                                    borderRadius: 8,
                                    boxShadow: "10px 10px 15px rgba(3,24,81,0.15)",
                                }}
                            >
                                <CardHeader
                                    title={
                                        loading ? (
                                            <Skeleton variant="text" animation="wave" height={15} width="30%" />
                                        ) : (
                                            <Typography variant="h3" color="primary">
                                                {t("generalDashboard.market")}
                                            </Typography>
                                        )
                                    }
                                    sx={{
                                        p: 0,
                                        pb: 1,
                                        borderBottom: "2px solid #FF5968",
                                    }}
                                />
                                <CardContent
                                    sx={{
                                        p: "16px 0px 0px !important",
                                        textAlign: "center",
                                        pt: { xl: "5px !important" },
                                        position: "relative",
                                        flex: 1,
                                    }}
                                >
                                    {loading ? (
                                        <Skeleton
                                            variant="circular"
                                            animation="wave"
                                            width={150}
                                            height={153}
                                            sx={{ display: "block", ml: "auto", mr: "auto" }}
                                        />
                                    ) : (
                                        <Doughnut data={marketChartData} options={chartMarketOptions} />
                                    )}
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Card
                                elevation={0}
                                sx={{
                                    height: "100%",
                                    p: "24px",
                                    borderRadius: 8,
                                    boxShadow: "10px 10px 15px rgba(3,24,81,0.15)",
                                }}
                            >
                                <CardHeader
                                    title={
                                        loading ? (
                                            <Skeleton variant="text" animation="wave" height={15} />
                                        ) : (
                                            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                                <Box>
                                                    <Typography variant="h3" color="primary">
                                                        {t("generalDashboard.clientsActiveServ")}
                                                    </Typography>
                                                </Box>
                                                <Box>
                                                    <Typography variant="body2" color="primary">
                                                        {t("generalDashboard.totalServices")}:{" "}
                                                        {valuesDash.totalServices}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        )
                                    }
                                    sx={{
                                        p: 0,
                                        pb: 1,
                                        borderBottom: "2px solid #FF5968",
                                    }}
                                />
                                <CardContent
                                    sx={{
                                        p: "16px 16px 0px !important",
                                        textAlign: "center",
                                        position: "relative",
                                        flex: 1,
                                    }}
                                >
                                    {loading ? (
                                        <Skeleton variant="rectangular" animation="wave" height={500} width="100%" />
                                    ) : (
                                        <Bar height={500} data={servicesChartData} options={chartClientsServOptions} />
                                    )}
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid container item xs={12} md={6} spacing={2}>
                            <Grid item xs={12}>
                                <Card
                                    elevation={0}
                                    sx={{
                                        height: "100%",
                                        p: "24px",
                                        borderRadius: 8,
                                        boxShadow: "10px 10px 15px rgba(3,24,81,0.15)",
                                    }}
                                >
                                    <CardHeader
                                        title={
                                            <>
                                                <Grid container justifyContent="space-around" alignItems="center">
                                                    {loading ? (
                                                        <Skeleton
                                                            variant="text"
                                                            animation="wave"
                                                            height={15}
                                                            width="30%"
                                                        />
                                                    ) : (
                                                        <Typography variant="h3" color="primary">
                                                            {t("generalDashboard.accountingClosures")}
                                                        </Typography>
                                                    )}
                                                    <Box component="span" display="flex" justifyContent="space-around">
                                                    {loading ? (
                                                        <Skeleton
                                                            variant="text"
                                                            width={95}
                                                            height={15}
                                                            animation="wave"
                                                            sx={{ ml: "auto", mr: "auto" }}
                                                        />
                                                    ) : (
                                                        <Grid item>
                                                            <Chip
                                                                id="all-chip"
                                                                label={
                                                                    <Typography
                                                                        variant="h2"
                                                                        align="center"
                                                                        sx={{
                                                                            fontWeight: 600,
                                                                            fontSize: "11px",
                                                                            fontFamily: "GothamRounded",
                                                                            fontStyle: "normal",
                                                                        }}
                                                                    >
                                                                        {t("miscellaneous.all")}
                                                                    </Typography>
                                                                }
                                                                className={
                                                                    chip === "all" ? classes.selectedChip : classes.chip
                                                                }
                                                                onClick={() => handleClickChip("all")}
                                                            />
                                                        </Grid>
                                                    )}
                                                    {loading ? (
                                                        <Skeleton
                                                            variant="text"
                                                            width={95}
                                                            height={15}
                                                            animation="wave"
                                                            sx={{ ml: "auto", mr: "auto" }}
                                                        />
                                                    ) : (
                                                        <Grid item>
                                                            <Chip
                                                                id="acc-chip"
                                                                label={
                                                                    <Typography
                                                                        variant="h2"
                                                                        align="center"
                                                                        sx={{
                                                                            fontWeight: 600,
                                                                            fontSize: "11px",
                                                                            fontFamily: "GothamRounded",
                                                                            fontStyle: "normal",
                                                                        }}
                                                                    >
                                                                        Accountant
                                                                    </Typography>
                                                                }
                                                                className={
                                                                    chip === "Accountant"
                                                                        ? classes.selectedChip
                                                                        : classes.chip
                                                                }
                                                                onClick={() => handleClickChip("Accountant")}
                                                                sx={{mx: 0.5}}
                                                            />
                                                        </Grid>
                                                    )}
                                                    {loading ? (
                                                        <Skeleton
                                                            variant="text"
                                                            width={95}
                                                            height={15}
                                                            animation="wave"
                                                            sx={{ ml: "auto", mr: "auto" }}
                                                        />
                                                    ) : (
                                                        <Grid item>
                                                            <Chip
                                                                id="report-chip"
                                                                label={
                                                                    <Typography
                                                                        variant="h2"
                                                                        align="center"
                                                                        sx={{
                                                                            fontWeight: 600,
                                                                            fontSize: "11px",
                                                                            fontFamily: "GothamRounded",
                                                                            fontStyle: "normal",
                                                                        }}
                                                                    >
                                                                        Bookkeeper
                                                                    </Typography>
                                                                }
                                                                className={
                                                                    chip === "Bookkeeper"
                                                                        ? classes.selectedChip
                                                                        : classes.chip
                                                                }
                                                                onClick={() => handleClickChip("Bookkeeper")}
                                                            />
                                                        </Grid>
                                                    )}
                                                    </Box>
                                                    
                                                </Grid>
                                            </>
                                        }
                                        sx={{
                                            p: 0,
                                            pb: 1,
                                            borderBottom: "2px solid #FF5968",
                                        }}
                                    />
                                    <CardContent sx={{ p: "16px 16px 0px !important", textAlign: "center" }}>
                                        <Grid container alignItems="center" spacing={2}>
                                            <Grid item xs={12} md={3}>
                                                {loading  || loadingCierres ? (
                                                    <Skeleton
                                                        variant="text"
                                                        animation="wave"
                                                        height={80}
                                                        width="60%"
                                                        sx={{ display: "block", ml: "auto", mr: "auto" }}
                                                    />
                                                ) : (
                                                    <Typography color="primary" sx={{ fontSize: 40 }}>
                                                        {valuesDash.pendingCurrentMonth}
                                                    </Typography>
                                                )}
                                            </Grid>
                                            <Grid item xs={12} md={9}>
                                                {loading || loadingCierres ? (
                                                    <Skeleton variant="text" animation="wave" height={30} />
                                                ) : (
                                                    <Box sx={{ width: "100%" }}>
                                                        <LinearProgress
                                                            variant="determinate"
                                                            value={valuesDash.pendingCurrentPercentage}
                                                            sx={{
                                                                height: 15,
                                                                borderRadius: 5,
                                                            }}
                                                        />
                                                    </Box>
                                                )}
                                                {loading || loadingCierres ? (
                                                    <Skeleton variant="text" animation="wave" height={20} />
                                                ) : (
                                                    <Box
                                                        sx={{ display: "flex", justifyContent: "space-between", pt: 1 }}
                                                    >
                                                        <Box>
                                                            <Typography variant="body2" color="text.secondary">
                                                                {`${
                                                                    moment()
                                                                        .subtract(1, "months")
                                                                        .format("MMMM")
                                                                        .charAt(0)
                                                                        .toUpperCase() +
                                                                    moment()
                                                                        .subtract(1, "months")
                                                                        .format("MMMM")
                                                                        .slice(1)
                                                                }`}
                                                            </Typography>
                                                        </Box>
                                                        <Box>
                                                            <Typography variant="body2" color="text.secondary">
                                                                {Math.floor(valuesDash.pendingCurrentPercentage)}%
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid container item xs={12} spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <Card
                                        elevation={0}
                                        sx={{
                                            p: "24px",
                                            borderRadius: 8,
                                            boxShadow: "10px 10px 15px rgba(3,24,81,0.15)",
                                        }}
                                    >
                                        <CardHeader
                                            title={
                                                loading ? (
                                                    <Skeleton variant="text" animation="wave" height={15} width="70%" />
                                                ) : (
                                                    <Typography variant="h3" color="primary">
                                                        {moment.locale() === "es"
                                                            ? `Pendientes ${moment()
                                                                  .subtract(2, "months")
                                                                  .format("MMMM")}`
                                                            : `${moment()
                                                                  .subtract(2, "months")
                                                                  .format("MMMM")} pendings`}
                                                    </Typography>
                                                )
                                            }
                                            sx={{
                                                p: 0,
                                                pb: 1,
                                                borderBottom: "2px solid #FF5968",
                                            }}
                                        />
                                        <CardContent sx={{ p: "16px 16px 0px !important", textAlign: "center" }}>
                                            {loading || loadingCierres ? (
                                                <Skeleton
                                                    variant="text"
                                                    animation="wave"
                                                    height={60}
                                                    width="50%"
                                                    sx={{ display: "block", ml: "auto", mr: "auto" }}
                                                />
                                            ) : (
                                                <Typography color="secondary" sx={{ fontSize: 40 }}>
                                                    {valuesDash.pendingLastMonth}
                                                </Typography>
                                            )}
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Card
                                        elevation={0}
                                        sx={{
                                            p: "24px",
                                            borderRadius: 8,
                                            boxShadow: "10px 10px 15px rgba(3,24,81,0.15)",
                                        }}
                                    >
                                        <CardHeader
                                            title={
                                                loading ? (
                                                    <Skeleton variant="text" animation="wave" height={15} width="70%" />
                                                ) : (
                                                    <Typography variant="h3" color="primary">
                                                        {moment.locale() === "es"
                                                            ? `Pendientes ${moment()
                                                                  .subtract(3, "months")
                                                                  .format("MMMM")}`
                                                            : `${moment()
                                                                  .subtract(3, "months")
                                                                  .format("MMMM")} pendings`}
                                                    </Typography>
                                                )
                                            }
                                            sx={{
                                                p: 0,
                                                pb: 1,
                                                borderBottom: "2px solid #FF5968",
                                            }}
                                        />
                                        <CardContent sx={{ p: "16px 16px 0px !important", textAlign: "center" }}>
                                            {loading || loadingCierres ? (
                                                <Skeleton
                                                    variant="text"
                                                    animation="wave"
                                                    height={60}
                                                    width="50%"
                                                    sx={{ display: "block", ml: "auto", mr: "auto" }}
                                                />
                                            ) : (
                                                <Typography color="secondary" sx={{ fontSize: 40 }}>
                                                    {valuesDash.pendingLastTwoMonths}
                                                </Typography>
                                            )}
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12}>
                                <Grid item xs={12}>
                                    <Card
                                        elevation={0}
                                        sx={{
                                            height: "100%",
                                            p: "24px",
                                            borderRadius: 8,
                                            boxShadow: "10px 10px 15px rgba(3,24,81,0.15)",
                                        }}
                                    >
                                        <CardHeader
                                            title={
                                                loading ? (
                                                    <Skeleton variant="text" animation="wave" height={15} width="55%" />
                                                ) : (
                                                    <Typography variant="h3" color="primary">
                                                        {t("generalDashboard.clientsByAnalyst")}
                                                    </Typography>
                                                )
                                            }
                                            sx={{
                                                p: 0,
                                                pb: 1,
                                                borderBottom: "2px solid #FF5968",
                                            }}
                                        />
                                        <CardContent
                                            sx={{
                                                p: "16px 16px 0px !important",
                                                textAlign: "center",
                                                position: "relative",
                                                flex: 1,
                                            }}
                                        >
                                            {loading ? (
                                                <Skeleton
                                                    variant="rectangular"
                                                    animation="wave"
                                                    height={150}
                                                    width="100%"
                                                />
                                            ) : (
                                                <Bar data={analystsChartData} options={chartAnalystsOptions} />
                                            )}
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item xs={12} xl={4}>
                    <Grid item xs={12}>
                        <Card
                            elevation={0}
                            sx={{
                                height: "100%",
                                p: "24px",
                                borderRadius: 8,
                                boxShadow: "10px 10px 15px rgba(3,24,81,0.15)",
                            }}
                        >
                            <CardHeader
                                title={
                                    loading ? (
                                        <Skeleton variant="text" animation="wave" height={15} width="25%" />
                                    ) : (
                                        <Typography variant="h3" color="primary">
                                            {t("generalDashboard.newClients")}
                                        </Typography>
                                    )
                                }
                                sx={{
                                    p: 0,
                                    pb: 1,
                                    borderBottom: "2px solid #FF5968",
                                }}
                            />
                            <CardContent sx={{ p: "16px 0px 0px !important", textAlign: "center" }}>
                                {loading ? (
                                    <>
                                        <Skeleton
                                            variant="rectangular"
                                            animation="wave"
                                            height={95}
                                            sx={{ borderRadius: 8, m: "16px 16px" }}
                                        />
                                        <Skeleton
                                            variant="rectangular"
                                            animation="wave"
                                            height={95}
                                            sx={{ borderRadius: 8, m: "32px 16px" }}
                                        />
                                        <Skeleton
                                            variant="rectangular"
                                            animation="wave"
                                            height={95}
                                            sx={{ borderRadius: 8, m: "32px 16px" }}
                                        />
                                        <Skeleton
                                            variant="rectangular"
                                            animation="wave"
                                            height={95}
                                            sx={{ borderRadius: 8, m: "32px 16px" }}
                                        />
                                        <Skeleton
                                            variant="rectangular"
                                            animation="wave"
                                            height={95}
                                            sx={{ borderRadius: 8, m: "32px 16px" }}
                                        />
                                        <Skeleton
                                            variant="rectangular"
                                            animation="wave"
                                            height={95}
                                            sx={{ borderRadius: 8, m: "32px 16px" }}
                                        />
                                    </>
                                ) : (
                                    <List
                                        sx={{
                                            maxHeight: valuesHeights.leftDashboard - 100,
                                            overflowY: "auto",
                                        }}
                                    >
                                        {valuesDash.topClients.map((client) => (
                                            <ListItem>
                                                <Card
                                                    elevation={0}
                                                    sx={{
                                                        width: "100%",
                                                        p: "12px",
                                                        mb: 2,
                                                        borderRadius: 8,
                                                        cursor: "pointer",
                                                        boxShadow: "10px 10px 15px rgba(3,24,81,0.15)",
                                                        backgroundColor: "#f7b5d2",
                                                        ...(client.clientStatus === 1 && {
                                                            backgroundColor: "#E6E8EE",
                                                        }),
                                                        ...(client.clientStatus === 2 && {
                                                            backgroundColor: "#ACADD3",
                                                        }),
                                                    }}
                                                    onClick={() => goToClient(client)}
                                                >
                                                    <CardContent sx={{ p: "10px !important" }}>
                                                        <Grid container spacing={2}>
                                                            <Grid
                                                                container
                                                                item
                                                                md={7}
                                                                direction="column"
                                                                justifyContent="space-between"
                                                            >
                                                                <Grid item>
                                                                    <Typography
                                                                        variant="h3"
                                                                        color="primary"
                                                                        align="left"
                                                                        sx={{ pb: "10px", fontWeight: 700 }}
                                                                    >
                                                                        {client.companyName}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Typography
                                                                        variant="body1"
                                                                        color="text.secondary"
                                                                        align="left"
                                                                        sx={{
                                                                            fontStyle: "italic",
                                                                            ...(moment.locale() === "en" && {
                                                                                fontStyle: "normal",
                                                                            }),
                                                                        }}
                                                                    >
                                                                        {client.services}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid
                                                                container
                                                                item
                                                                md={5}
                                                                direction="column"
                                                                justifyContent="space-between"
                                                            >
                                                                <Grid item>
                                                                    <Typography
                                                                        variant="body1"
                                                                        color="text.secondary"
                                                                        align="right"
                                                                        sx={{ pb: "10px" }}
                                                                    >
                                                                        {client.franchiseName}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Typography
                                                                        variant="body1"
                                                                        color="text.secondary"
                                                                        align="right"
                                                                    >
                                                                        <b>{t("miscellaneous.entry")}</b>:{" "}
                                                                        {moment(client.initDate, "DD/MM/YYYY").format(
                                                                            todos.dateFormat
                                                                        )}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </CardContent>
                                                </Card>
                                            </ListItem>
                                        ))}
                                    </List>
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
        </StyledContainer>
    );
};

export default Dashboard;
