/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

/** MUI imports */
import { styled } from "@mui/material/styles";
import { Box, Chip, Grid, Typography } from "@mui/material";

/** Components */
import { Recurring, Onboarding, CatchUp, CleanUp, Consulting } from "./";

/** Component styles */
const PREFIX = "PropAccountantDetails";

const classes = {
    cardContentRoot: `${PREFIX}-cardContentRoot`,
    chip: `${PREFIX}-chip`,
    selectedChip: `${PREFIX}-selectedChip`,
    projectBox: `${PREFIX}-projectBox`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.cardContentRoot}`]: {
        padding: "24px 48px 48px",
    },

    [`& .${classes.chip}`]: {
        padding: 24,
        width: 200,
        backgroundColor: "#EAEAF4",
        color: "#031851",
        fontWeight: "700 !important",
        "&:hover": {
            backgroundColor: "#EAEAF4",
            color: "#031851",
        },
    },

    [`& .${classes.selectedChip}`]: {
        padding: 24,
        width: 200,
        backgroundColor: "#2f3190",
        color: "#fff",
        fontWeight: "700 !important",
        "&:hover": {
            backgroundColor: "#2f3190",
            color: "#fff",
        },
    },

    [`& .${classes.projectBox}`]: {
        backgroundColor: "#F8F9F8",
        marginTop: 16,
        padding: 30,
        borderRadius: 30,
    },
}));

/** Global variables */
const latam = [
    "Argentina",
    "Bolivia",
    "Brasil",
    "Chile",
    "Colombia",
    "Costa Rica",
    "Cuba",
    "Ecuador",
    "El Salvador",
    "Guatemala",
    "Haití",
    "Honduras",
    "México",
    "Nicaragua",
    "Panamá",
    "Paraguay",
    "Perú",
    "República Dominicana",
    "Uruguay",
];

export const PropAccountantDetails = (props) => {
    // Properties
    const {
        clientMarket,
        pricingModel,
        services,
        setAutoAlertType,
        setServices,
        setShow,
        setTexto,
        setValuesAccCatchUp,
        setValuesAccCleanUp,
        setValuesAccConsul,
        setValuesAccOnboard,
        setValuesAccRecu,
        valuesAccCatchUp,
        valuesAccCleanUp,
        valuesAccConsul,
        valuesAccOnboard,
        valuesAccRecu,
        isError,
        isErrorFocus,
        setIsErrorFocus,
        showtutorial,
        showFinalTutorial,
    } = props;

    /** Internationalization i18n */
    const [t] = useTranslation("global");
    

    /** Component functions */
    useEffect(() => {
        if (
            !valuesAccRecu.chip &&
            !valuesAccOnboard.chip &&
            !valuesAccCatchUp.chip &&
            !valuesAccCleanUp.chip &&
            !valuesAccConsul.chip
        ) {
            const updateServices = services.map((service) => {
                if (service.name === "accountant") {
                    return {
                        ...service,
                        isActive: false,
                    };
                } else {
                    return {
                        ...service,
                    };
                }
            });
            setServices(updateServices);
        } else {
            const updateServices = services.map((service) => {
                if (service.name === "accountant") {
                    return {
                        ...service,
                        isActive: true,
                    };
                } else {
                    return {
                        ...service,
                    };
                }
            });
            setServices(updateServices);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [valuesAccRecu.chip, valuesAccOnboard.chip, valuesAccCatchUp.chip, valuesAccCleanUp.chip, valuesAccConsul.chip]);

    useEffect(() => {
        pricingModel.forEach((element) => {
            if (element.service_name === "Accountant") {
                element.projects.forEach((item) => {
                    if (item.project_name === "Onboarding") {
                        setValuesAccOnboard({
                            ...valuesAccOnboard,
                            onboardOriginalFee: item.fee,
                            onboardProjectTotal: !valuesAccOnboard.chip
                                ? 0
                                : item.fee * (1 - valuesAccOnboard.onboardDiscount / 100),
                        });
                    }
                    if (item.project_name === "Cleanup") {
                        setValuesAccCleanUp({
                            ...valuesAccCleanUp,
                            cleanUpOriginalFee: item.fee,
                            projectTotal: !valuesAccCleanUp.chip
                                ? 0
                                : valuesAccCleanUp.additionalsOnly
                                ? valuesAccCleanUp.projectTotal
                                : item.fee * valuesAccCleanUp.months * (1 - valuesAccCleanUp.discount / 100),
                            hourRate: item.additional_fee,
                        });
                    }
                    if (item.project_name === "Catchup") {
                        setValuesAccCatchUp({
                            ...valuesAccCatchUp,
                            catchUpOriginalFee: item.fee,
                            projectTotal: !valuesAccCatchUp.chip
                                ? 0
                                : valuesAccCatchUp.additionalsOnly
                                ? valuesAccCatchUp.projectTotal
                                : item.fee * valuesAccCatchUp.months * (1 - valuesAccCatchUp.discount / 100),
                            hourRate: item.additional_fee,
                        });
                    }
                    if (item.project_name === "Recurring") {
                        setValuesAccRecu({
                            ...valuesAccRecu,
                            recuOriginalFee: item.fee,
                            recuBaseRate: !valuesAccRecu.chip
                                ? 0
                                : valuesAccRecu.recuAdditionalsOnly
                                ? valuesAccRecu.recuProjectTotal
                                : valuesAccRecu.payAnnually
                                ? item.fee * 12 * (1 - valuesAccRecu.recuDiscount / 100)
                                : item.fee * (1 - valuesAccRecu.recuDiscount / 100),
                            recuProjectTotal: !valuesAccRecu.chip
                                ? 0
                                : valuesAccRecu.recuAdditionalsOnly
                                ? valuesAccRecu.recuProjectTotal
                                : valuesAccRecu.payAnnually
                                ? item.fee * 12 * (1 - valuesAccRecu.recuDiscount / 100)
                                : item.fee * (1 - valuesAccRecu.recuDiscount / 100),
                            recuHourRate: item.additional_fee,
                        });
                    }
                    if (item.project_name === "Consulting") {
                        let aux = [...valuesAccConsul.consultingArray];

                        aux.forEach((element) => {
                            element.total = element.hours * item.fee * (1 - element.discount / 100);
                        });

                        let sumHours = aux.map((obj) => obj.hours).reduce((a, b) => parseInt(a) + parseInt(b));
                        let sumTotal = aux.map((obj) => obj.total).reduce((a, b) => parseFloat(a) + parseFloat(b));

                        setValuesAccConsul({
                            ...valuesAccConsul,
                            consulOriginalFee: item.fee,
                            consulProjectTotal: sumTotal,
                            consulHours: sumHours,
                            consultingArray: aux,
                        });
                    }
                });
            }
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pricingModel, valuesAccRecu.recuHours, valuesAccCatchUp.totalHours, valuesAccCleanUp.totalHours]);

    const handleClickChip = (value, option) => {
        if (option === "accountOnboard") {
            setValuesAccOnboard({
                ...valuesAccOnboard,
                chip: !value,
                onboardOriginalFee: valuesAccOnboard.onboardOriginalFee,
                onboardDiscount: 0,
                onboardProjectTotal: !value ? valuesAccOnboard.onboardOriginalFee : 0,
                meetingDate: null,
                description: "",
            });
        } else if (option === "accountantRecu") {
            setValuesAccRecu({
                ...valuesAccRecu,
                chip: !value,
                recuOriginalFee: valuesAccRecu.recuOriginalFee,
                recuBaseRate: !value ? valuesAccRecu.recuOriginalFee : 0,
                recuDiscount: 0,
                recuProjectTotal: !value ? valuesAccRecu.recuOriginalFee : 0,
                recuAdditionalsDiscount: 0,
                payAnnually: false,
                recuAdditionalsOnly: false,
                initDate: null,
                recuServFreq: 1,
                recuHourRate: 0,
                recuChecks: false,
                recuHours: 0,
                // Additionals
                checkedAcc: false,
                tothAcc: 0,
                startDateAcc: null,
                finishDateAcc: null,
                checkedAR: false,
                tothAR: 0,
                startDateAR: null,
                finishDateAR: null,
                checkedAP: false,
                tothAP: 0,
                startDateAP: null,
                finishDateAP: null,
                checkedClass: false,
                tothClass: 0,
                startDateClass: null,
                finishDateClass: null,
                checkedMulti: false,
                tothMulti: 0,
                startDateMulti: null,
                finishDateMulti: null,
                checkedPer: false,
                tothPer: 0,
                startDatePer: null,
                finishDatePer: null,
                // USA
                checkedChecks: false,
                tothChecks: 0,
                startDateChecks: null,
                finishDateChecks: null,
                checkedSales: false,
                tothSales: 0,
                startDateSales: null,
                finishDateSales: null,
                // Venezuela
                checkedOrd: false,
                tothOrdConTax: 0,
                startDateOrd: null,
                finishDateOrd: null,
                checkedEsp: false,
                tothSpeConTax: 0,
                startDateEsp: null,
                finishDateEsp: null,
                checkedMuni: false,
                tothMuni: 0,
                startDateMuni: null,
                finishDateMuni: null,
                // Others
                checkedOthers: false,
                nameOthers: "",
                tothOthers: 0,
                startDateOthers: null,
                finishDateOthers: null,
            });
        } else if (option === "accountCatchUp") {
            setValuesAccCatchUp({
                ...valuesAccCatchUp,
                chip: !value,
                catchUpOriginalFee: valuesAccCatchUp.catchUpOriginalFee,
                sinceDate: null,
                untilDate: null,
                discount: 30,
                catchUpAdditionalsDiscount: 30,
                additionalsOnly: false,
                projectTotal: !value ? valuesAccCatchUp.catchUpOriginalFee * (1 - valuesAccCatchUp.discount / 100) : 0,
                hourRate: 0,
                catchUpChecks: false,
                totalHours: 0,
                months: 1,
                // Additionals
                checkedAcc: false,
                tothAcc: 0,
                startDateAcc: null,
                finishDateAcc: null,
                checkedAR: false,
                tothAR: 0,
                startDateAR: null,
                finishDateAR: null,
                checkedAP: false,
                tothAP: 0,
                startDateAP: null,
                finishDateAP: null,
                checkedClass: false,
                tothClass: 0,
                startDateClass: null,
                finishDateClass: null,
                checkedMulti: false,
                tothMulti: 0,
                startDateMulti: null,
                finishDateMulti: null,
                checkedPer: false,
                tothPer: 0,
                startDatePer: null,
                finishDatePer: null,
                // USA
                checkedChecks: false,
                tothChecks: 0,
                startDateChecks: null,
                finishDateChecks: null,
                checkedSales: false,
                tothSales: 0,
                startDateSales: null,
                finishDateSales: null,
                // Venezuela
                checkedMuni: false,
                tothMuni: 0,
                startDateMuni: null,
                finishDateMuni: null,
                checkedOrdConTax: false,
                tothOrdConTax: 0,
                startDateOrdConTax: null,
                finishDateOrdConTax: null,
                checkedSpeConTax: false,
                tothSpeConTax: 0,
                startDateSpeConTax: null,
                finishDateSpeConTax: null,
                // Others
                checkedOthers: false,
                nameOthers: "",
                tothOthers: 0,
                startDateOthers: null,
                finishDateOthers: null,
            });
        } else if (option === "accountCleanUp") {
            setValuesAccCleanUp({
                ...valuesAccCleanUp,
                chip: !value,
                cleanUpOriginalFee: valuesAccCleanUp.cleanUpOriginalFee,
                sinceDate: null,
                untilDate: null,
                discount: 20,
                cleanUpAdditionalsDiscount: 20,
                additionalsOnly: false,
                projectTotal: !value ? valuesAccCleanUp.cleanUpOriginalFee * (1 - valuesAccCleanUp.discount / 100) : 0,
                hourRate: 0,
                cleanUpChecks: false,
                totalHours: 0,
                months: 1,
                // Additionals
                checkedAcc: false,
                tothAcc: 0,
                startDateAcc: null,
                finishDateAcc: null,
                checkedAR: false,
                tothAR: 0,
                startDateAR: null,
                finishDateAR: null,
                checkedAP: false,
                tothAP: 0,
                startDateAP: null,
                finishDateAP: null,
                checkedClass: false,
                tothClass: 0,
                startDateClass: null,
                finishDateClass: null,
                checkedMulti: false,
                tothMulti: 0,
                startDateMulti: null,
                finishDateMulti: null,
                checkedPer: false,
                tothPer: 0,
                startDatePer: null,
                finishDatePer: null,
                // USA
                checkedChecks: false,
                tothChecks: 0,
                startDateChecks: null,
                finishDateChecks: null,
                checkedSales: false,
                tothSales: 0,
                startDateSales: null,
                finishDateSales: null,
                // Venezuela
                checkedMuni: false,
                tothMuni: 0,
                startDateMuni: null,
                finishDateMuni: null,
                checkedOrdConTax: false,
                tothOrdConTax: 0,
                startDateOrdConTax: null,
                finishDateOrdConTax: null,
                checkedSpeConTax: false,
                tothSpeConTax: 0,
                startDateSpeConTax: null,
                finishDateSpeConTax: null,
                // Others
                checkedOthers: false,
                nameOthers: "",
                tothOthers: 0,
                startDateOthers: null,
                finishDateOthers: null,
            });
        } else {
            setValuesAccConsul({
                ...valuesAccConsul,
                chip: !value,
                consulOriginalFee: valuesAccConsul.consulOriginalFee,
                consulProjectTotal: 0,
                consulHours: 0,
                consultingArray: [
                    {
                        hours: 0,
                        discount: 0,
                        description: "",
                        total: 0,
                    },
                ],
            });
        }
        if (showtutorial && !value) {
            setTimeout(() => showFinalTutorial("projectsAcc"), 500);
        }
    };

    return (
        <StyledContainer>
            <Grid id="accountant" container justifyContent="space-around" alignItems="center" spacing={2}>
                <Grid item>
                    <Chip
                        id="onboarding-chip"
                        label={
                            <Typography variant="h2" align="center" sx={{ fontWeight: 700 }}>
                                {t(`invoicing.onboarding`)}
                            </Typography>
                        }
                        className={valuesAccOnboard.chip ? classes.selectedChip : classes.chip}
                        onClick={() => handleClickChip(valuesAccOnboard.chip, "accountOnboard")}
                    />
                </Grid>
                <Grid item>
                    <Chip
                        id="clean-up-chip"
                        label={
                            <Typography variant="h2" align="center" sx={{ fontWeight: 700 }}>
                                {t("invoicing.cleanUp")}
                            </Typography>
                        }
                        className={valuesAccCleanUp.chip ? classes.selectedChip : classes.chip}
                        onClick={() => handleClickChip(valuesAccCleanUp.chip, "accountCleanUp")}
                    />
                </Grid>
                <Grid item>
                    <Chip
                        id="catch-up-chip"
                        label={
                            <Typography variant="h2" align="center" sx={{ fontWeight: 700 }}>
                                {t("invoicing.catchUp")}
                            </Typography>
                        }
                        className={valuesAccCatchUp.chip ? classes.selectedChip : classes.chip}
                        onClick={() => handleClickChip(valuesAccCatchUp.chip, "accountCatchUp")}
                    />
                </Grid>
                <Grid item>
                    <Chip
                        id="recurring-chip"
                        label={
                            <Typography variant="h2" align="center" sx={{ fontWeight: 700 }}>
                                {t("invoicing.recurring")}
                            </Typography>
                        }
                        className={valuesAccRecu.chip ? classes.selectedChip : classes.chip}
                        onClick={() => handleClickChip(valuesAccRecu.chip, "accountantRecu")}
                    />
                </Grid>
                <Grid item>
                    <Chip
                        id="consulting-chip"
                        label={
                            <Typography variant="h2" align="center" sx={{ fontWeight: 700 }}>
                                {t("invoicing.consulting")}
                            </Typography>
                        }
                        className={valuesAccConsul.chip ? classes.selectedChip : classes.chip}
                        onClick={() => handleClickChip(valuesAccConsul.chip, "accountConsul")}
                    />
                </Grid>
            </Grid>
            <Box id="projectsAcc">
                {valuesAccOnboard.chip && (
                    <Onboarding
                        valuesAccOnboard={valuesAccOnboard}
                        setValuesAccOnboard={setValuesAccOnboard}
                        isError={isError}
                        isErrorFocus={isErrorFocus}
                        setIsErrorFocus={setIsErrorFocus}
                    />
                )}
                {valuesAccCleanUp.chip && (
                    <CleanUp
                        clientMarket={clientMarket}
                        values={valuesAccCleanUp}
                        setValues={setValuesAccCleanUp}
                        setShow={setShow}
                        setTexto={setTexto}
                        setAutoAlertType={setAutoAlertType}
                        latam={latam}
                        isError={isError}
                        isErrorFocus={isErrorFocus}
                        setIsErrorFocus={setIsErrorFocus}
                    />
                )}
                {valuesAccCatchUp.chip && (
                    <CatchUp
                        clientMarket={clientMarket}
                        values={valuesAccCatchUp}
                        setValues={setValuesAccCatchUp}
                        setShow={setShow}
                        setTexto={setTexto}
                        setAutoAlertType={setAutoAlertType}
                        latam={latam}
                        isError={isError}
                        isErrorFocus={isErrorFocus}
                        setIsErrorFocus={setIsErrorFocus}
                    />
                )}
                {valuesAccRecu.chip && (
                    <Recurring
                        clientMarket={clientMarket}
                        valuesAccRecu={valuesAccRecu}
                        setValuesAccRecu={setValuesAccRecu}
                        setShow={setShow}
                        setTexto={setTexto}
                        setAutoAlertType={setAutoAlertType}
                        latam={latam}
                        isError={isError}
                        isErrorFocus={isErrorFocus}
                        setIsErrorFocus={setIsErrorFocus}
                    />
                )}
                {valuesAccConsul.chip && (
                    <Consulting
                        clientMarket={clientMarket}
                        valuesAccConsul={valuesAccConsul}
                        setValuesAccConsul={setValuesAccConsul}
                        setShow={setShow}
                        setTexto={setTexto}
                        setAutoAlertType={setAutoAlertType}
                        isError={isError}
                        isErrorFocus={isErrorFocus}
                        setIsErrorFocus={setIsErrorFocus}
                    />
                )}
            </Box>
        </StyledContainer>
    );
};
