/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

/** MUI imports */
import { styled } from "@mui/material/styles";
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Skeleton,
    Select,
    TextField,
} from "@mui/material";

/** MUI icons */
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

/** Components imports */
import AlertDialog from "../Dialog";

const PREFIX = "ClientCont";

const classes = {
    btnRoot: `${PREFIX}-btnRoot`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.btnRoot}`]: {
        border: "1px solid #ff5968",
        borderRadius: 40,
        "&:hover": {
            backgroundColor: "#2f3190",
            border: "1px solid #2f3190",
        },
    },
}));

const ClientFact = ({ setBackDrop, data, setTexto, setAlertType, setShow, loadingCard, setLoadingCard }) => {
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    /** Component states */
    const [dialogForm, setDialogForm] = useState(false);
    const [isError, setisError] = useState(false);
    const [valuesFact, setValuesFact] = useState({
        level: "",
        ambassador: "",
        subsInv: "",
        clientName: "",
        disabledData: false,
        disabledSelect: false,
        clientEmail: "",
        contactName: "",
        phoneNumber: "",
    });
    const [showGroup, setShowGroup] = useState(false);
    const [heritableSubsInv, setHeritableSubsInv] = useState(false);

    /** Component functions */
    const handleChangeFact = (event) => {
        setValuesFact({
            ...valuesFact,
            [event.target.name]: event.target.value,
        });
    };

    const handleChangeType = async (event) => {
        setBackDrop(true);

        if (event.target.value === 1) {
            setValuesFact({
                ...valuesFact,
                level: event.target.value,
                disabledData: false,
            });
        } else {
            await fetch(`/getPracticeDetails?practice_id=${data.practice_id}`, {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
                .then((res) => res.json())
                .then((data) => {
                    setValuesFact({
                        level: event.target.value,
                        ambassador: data.ambassador_name ?? "",
                        subsInv: data.invoicing_subscription ?? "",
                        clientName: data.invoicing_client_name ?? "",
                        clientEmail: data.invoicing_client_email ?? "",
                        contactName: data.invoicing_contact_name ?? "",
                        phoneNumber: data.invoicing_phone_number ?? "",
                        disabledData: true,
                        disabledSelect: false,
                    });
                });
        }

        setBackDrop(false);
    };

    const handleChangePhone = (event) => {
        const regex = /^\+[0-9\s.()-]{0,20}$/;
        if (event.target.value === "" || regex.test(event.target.value)) {
            setValuesFact({
                ...valuesFact,
                [event.target.name]: event.target.value,
            });
        }
    };

    const updateClientData = async () => {
        setBackDrop(true);

        if (
            valuesFact.clientName.trim() &&
            valuesFact.clientEmail.trim() &&
            valuesFact.contactName.trim() &&
            valuesFact.subsInv &&
            /.+@.+\..+/.test(valuesFact.clientEmail)
        ) {
            const params = {
                id_organisation: data._id,
                invoicing_level: valuesFact.level,
                ambassador_name: valuesFact.ambassador.trim() ?? undefined,
                invoicing_subscription: valuesFact.subsInv,
                invoicing_client_name: valuesFact.clientName,
                invoicing_client_email: valuesFact.clientEmail,
                invoicing_contact_name: valuesFact.contactName,
                invoicing_phone_number: valuesFact.phoneNumber,
            };

            await fetch(`/updateInvoicingInfo`, {
                method: "POST",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
                body: JSON.stringify(params),
            })
                .then((response) => {
                    if (response.ok) {
                        setTexto(t("miscellaneous.successfulUpdate"));
                        setAlertType("success");
                        setShow(true);
                    } else {
                        setTexto(t("miscellaneous.updatingError"));
                        setAlertType("error");
                        setShow(true);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            setTexto(t("miscellaneous.requiredData"));
            setAlertType("warning");
            setShow(true);
            setisError(true);
        }

        setBackDrop(false);
    };

    useEffect(() => {
        (async () => {
            setLoadingCard(true);
            await fetch(`/getInvoicingInfo?id_organisation=${data._id}`, {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
                .then((res) => res.json())
                .then((res) => {
                    setValuesFact({
                        level: res.invoicing_level ?? "",
                        ambassador: res.ambassador_name ?? "",
                        subsInv: res.invoicing_subscription ?? "",
                        clientName: res.invoicing_client_name ?? "",
                        clientEmail: res.invoicing_client_email ?? "",
                        contactName: res.invoicing_contact_name ?? "",
                        phoneNumber: res.invoicing_phone_number ?? "",
                        disabledData: res.invoicing_level === 3 || res.invoicing_level === 2,
                        disabledSelect: res.invoicing_level === 3 || (!data.franchise_id && !data.practice_id),
                    });
                    setHeritableSubsInv(res.heritable_subscription);
                });

            await fetch(`/getPracticeDetails?practice_id=${data.practice_id}`, {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
                .then((res) => res.json())
                .then((res) => {
                    setShowGroup(res.invoicing_level === 2);
                });

            setLoadingCard(false);
        })();
    }, [data, setBackDrop, setLoadingCard]);

    return (
        <StyledContainer>
            <Card elevation={0}>
                <CardHeader title={t("miscellaneous.invoicing")} sx={{ color: "#ff5968" }} />
                <Divider />
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item lg={4} sm={6} xs={12}>
                            {loadingCard ? (
                                <Skeleton variant="rounded" animation="wave" height={50} />
                            ) : (
                                <FormControl fullWidth disabled={valuesFact.disabledSelect} required>
                                    <InputLabel id="level-simple-select-label">
                                        {t("miscellaneous.invoicingServicesTo")}
                                    </InputLabel>
                                    <Select
                                        name="level"
                                        labelId="level-simple-select-label"
                                        IconComponent={KeyboardArrowDownIcon}
                                        value={valuesFact.level}
                                        label={t("miscellaneous.invoicingServicesTo")}
                                        onChange={handleChangeType}
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    mt: 0.7,
                                                    borderRadius: 2,
                                                    boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                },
                                            },
                                        }}
                                    >
                                        <MenuItem value={1}>{t("miscellaneous.client")}</MenuItem>
                                        {data.practice_id !== null && data.practice_id !== undefined ? (
                                            showGroup ? (
                                                <MenuItem value={2}>{t("miscellaneous.group")}</MenuItem>
                                            ) : null
                                        ) : null}
                                        {data.franchise_id !== null && data.franchise_id !== undefined ? (
                                            <MenuItem value={3}>{t("miscellaneous.franchise")}</MenuItem>
                                        ) : null}
                                    </Select>
                                </FormControl>
                            )}
                        </Grid>
                        <Grid item lg={4} sm={6} xs={12}>
                            {loadingCard ? (
                                <Skeleton variant="rounded" animation="wave" height={50} />
                            ) : (
                                <FormControl fullWidth>
                                    <InputLabel shrink id="ambassador-simple-select-label">
                                        {t("proposals.ambassador")}
                                    </InputLabel>
                                    <Select
                                        name="ambassador"
                                        labelId="ambassador-simple-select-label"
                                        IconComponent={KeyboardArrowDownIcon}
                                        value={valuesFact.ambassador}
                                        displayEmpty
                                        onChange={handleChangeFact}
                                        input={<OutlinedInput notched label={t("proposals.ambassador")} />}
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    mt: 0.7,
                                                    borderRadius: 2,
                                                    boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                },
                                            },
                                        }}
                                    >
                                        <MenuItem value="">N/A</MenuItem>
                                    </Select>
                                </FormControl>
                            )}
                        </Grid>
                        <Grid item lg={4} sm={6} xs={12}>
                            {loadingCard ? (
                                <Skeleton variant="rounded" animation="wave" height={50} />
                            ) : (
                                <FormControl
                                    fullWidth
                                    required
                                    error={isError && !valuesFact.subsInv}
                                    disabled={heritableSubsInv}
                                >
                                    <InputLabel id="subsInv-simple-select-label">{t("invoicing.subsInv")}</InputLabel>
                                    <Select
                                        name="subsInv"
                                        labelId="subsInv-simple-select-label"
                                        label={t("invoicing.subsInv")}
                                        IconComponent={KeyboardArrowDownIcon}
                                        value={valuesFact.subsInv}
                                        onChange={handleChangeFact}
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    mt: 0.7,
                                                    borderRadius: 2,
                                                    boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                },
                                            },
                                        }}
                                    >
                                        <MenuItem value={1}>GCA SC (Venezuela)</MenuItem>
                                        <MenuItem value={2}>GCA INC (Estados Unidos)</MenuItem>
                                    </Select>
                                </FormControl>
                            )}
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <Card elevation={0}>
                <CardHeader title={t("miscellaneous.contact")} sx={{ color: "#ff5968" }} />
                <Divider />
                <CardContent sx={{ pb: 5 }}>
                    <Grid container spacing={2}>
                        <Grid item lg={4} xs={12}>
                            {loadingCard ? (
                                <Skeleton variant="rounded" animation="wave" height={50} />
                            ) : (
                                <TextField
                                    disabled={valuesFact.disabledData}
                                    fullWidth
                                    label={t("miscellaneous.invoicingNameGCA")}
                                    name="contactName"
                                    onChange={handleChangeFact}
                                    value={valuesFact.contactName}
                                    variant="outlined"
                                    required
                                    error={isError && valuesFact.contactName.trim() === "" ? true : false}
                                />
                            )}
                        </Grid>
                        <Grid item lg={4} xs={12}>
                            {loadingCard ? (
                                <Skeleton variant="rounded" animation="wave" height={50} />
                            ) : (
                                <TextField
                                    disabled={valuesFact.disabledData}
                                    fullWidth
                                    label={t("miscellaneous.contactName")}
                                    name="clientName"
                                    onChange={handleChangeFact}
                                    value={valuesFact.clientName}
                                    variant="outlined"
                                    required
                                    error={isError && valuesFact.clientName.trim() === "" ? true : false}
                                />
                            )}
                        </Grid>
                        <Grid item lg={4} xs={12}>
                            {loadingCard ? (
                                <Skeleton variant="rounded" animation="wave" height={50} />
                            ) : (
                                <TextField
                                    disabled={valuesFact.disabledData}
                                    fullWidth
                                    label={t("miscellaneous.contactEmail")}
                                    name="clientEmail"
                                    onChange={handleChangeFact}
                                    value={valuesFact.clientEmail}
                                    variant="outlined"
                                    required
                                    error={
                                        isError && valuesFact.clientEmail.trim() === ""
                                            ? true
                                            : isError && !/.+@.+\..+/.test(valuesFact.clientEmail)
                                            ? true
                                            : false
                                    }
                                />
                            )}
                        </Grid>
                        <Grid item lg={4} xs={12}>
                            {loadingCard ? (
                                <Skeleton variant="rounded" animation="wave" height={50} />
                            ) : (
                                <FormControl fullWidth disabled={valuesFact.disabledData}>
                                    <InputLabel htmlFor="invoicing-phone-number-mask-input">
                                        {t("miscellaneous.phoneNumber")}
                                    </InputLabel>
                                    <OutlinedInput
                                        id="invoicing-phone-number-mask-input"
                                        name="phoneNumber"
                                        label={t("miscellaneous.phoneNumber")}
                                        value={valuesFact.phoneNumber}
                                        onChange={handleChangePhone}
                                        placeholder="e.g. +99 (999) 999-9999"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </FormControl>
                            )}
                        </Grid>
                    </Grid>
                </CardContent>
                <Divider />
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        p: 2,
                        pb: 0,
                    }}
                >
                    <Button
                        color="secondary"
                        variant="contained"
                        disableElevation
                        classes={{ root: classes.btnRoot }}
                        onClick={updateClientData}
                    >
                        {t("miscellaneous.save")}
                    </Button>
                </Box>
            </Card>
            <AlertDialog
                open={dialogForm}
                onClose={() => {
                    setDialogForm(false);
                }}
                message={t("miscellaneous.noAccess")}
                title={t("miscellaneous.attention")}
                maxWidth="xs"
            />
        </StyledContainer>
    );
};

export default ClientFact;
