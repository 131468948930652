/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import moment from "moment";

/** MUI imports */
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import {
    Autocomplete,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Paper,
    Skeleton,
    Select,
    TextField,
    Typography,
    Switch,
    FormControlLabel,
} from "@mui/material";

/** MUI icons */
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

/** Components imports */
import AlertDialog from "../Dialog";
import Currencies from "../../Js/currencies";

const PREFIX = "ClientCont";

const classes = {
    btnRoot: `${PREFIX}-btnRoot`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.btnRoot}`]: {
        border: "1px solid #ff5968",
        borderRadius: 40,
        "&:hover": {
            backgroundColor: "#2f3190",
            border: "1px solid #2f3190",
        },
    },
}));

const ColorButton = styled(Button)(({ theme }) => ({
    border: "1px solid #ff5968",
    borderRadius: 40,
}));

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "rgba(19, 31, 62, 0.80)",
        fontSize: 12,
        maxWidth: 320,
        borderRadius: 6,
        fontWeight: "normal",
    },
});

const clientPassAccess = [
    "atorrealba@gca.digital",
    "dleon@gca.digital",
    "dloyo@gca.digital",
    "kgarcia@gca.digital",
    "btorres@gca.digital",
    "jalvarez@gca.digital",
    "jrojas@gca.digital",
    "mmedina@gca.digital",
    "galbani@gca.digital",
];

const ClientCont = ({
    setTexto,
    setBackDrop,
    data,
    setAlertType,
    setShow,
    prove,
    setProveedores,
    loadingCard,
    setLoadingCard,
}) => {
    /** Internationalization i18n */
    const [t] = useTranslation("global");
    const todos = useSelector((state) => state.todos);

    /** Component states */
    const [dialogForm, setDialogForm] = useState(false);
    const [newProveedor, setNewProveedor] = useState("");
    const [isError, setisError] = useState(false);
    const [isErrorM, setisErrorM] = useState(false);
    const [accessReq, setAccessReq] = useState({
        isResquested: false,
        hasAccess: false,
    });
    const [valuesCont, setValuesCont] = useState({
        prov: "",
        sub: "",
        baseCurrency: null,
        user: "",
        pass: "",
        disabledData: false,
        showPassword: false,
    });
    const [copyCompanySubscriptionName, setCopyCompanySubscriptionName] = useState("");

    /** Component functions */
    const handleChangeCont = (event) => {
        if (event.target.value === "Xero" || event.target.value === "Quickbooks Online") {
            setValuesCont({
                ...valuesCont,
                [event.target.name]: event.target.value,
                disabledData: false,
                sub: copyCompanySubscriptionName,
            });
        } else {
            setValuesCont({
                ...valuesCont,
                [event.target.name]: event.target.value,
                disabledData: true,
                sub: "N/A",
            });
        }
    };

    const handleChange = (event) => {
        setValuesCont({
            ...valuesCont,
            [event.target.name]: event.target.value,
        });
    };

    const handleChangeCurrency = (event, value, reason) => {
        if (reason === "clear") {
            setValuesCont({
                ...valuesCont,
                baseCurrency: null,
            });
        } else {
            setValuesCont({
                ...valuesCont,
                baseCurrency: value,
            });
        }
    };

    const sortCurrencies = () => {
        if (moment.locale() === "en") {
            Currencies.sort((a, b) => {
                if (a.name > b.name) return 1;

                if (a.name < b.name) return -1;

                return 0;
            });
        } else {
            Currencies.sort((a, b) => {
                if (a.name_es > b.name_es) return 1;

                if (a.name_es < b.name_es) return -1;

                return 0;
            });
        }
    };

    const handleClickShowPassword = () => {
        setValuesCont({
            ...valuesCont,
            showPassword: !valuesCont.showPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const updateClientData = async () => {
        setBackDrop(true);

        if (
            valuesCont.sub.trim() !== "" &&
            valuesCont.baseCurrency !== null &&
            ((valuesCont.user.trim() !== "" && valuesCont.pass.trim() !== "") || valuesCont.disabledData)
        ) {
            const params = {
                id_organisation: data._id,
                xero_master_user: valuesCont.user,
                xero_master_password: valuesCont.pass,
                software_provider: valuesCont.prov,
                company_subscription_name: valuesCont.sub,
                base_currency: valuesCont.baseCurrency.code,
                disabled_software: valuesCont.disabledData,
            };

            await fetch(`/updateAccountingServices`, {
                method: "POST",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
                body: JSON.stringify(params),
            })
                .then((response) => {
                    if (response.ok) {
                        setTexto(t("miscellaneous.successfulUpdate"));
                        setAlertType("success");
                        setShow(true);
                    } else {
                        setTexto(t("miscellaneous,updatingError"));
                        setAlertType("error");
                        setShow(true);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            setTexto(t("miscellaneous.requiredData"));
            setAlertType("warning");
            setShow(true);
            setisErrorM(true);
        }

        setBackDrop(false);
    };

    const saveProvee = async () => {
        setBackDrop(true);
        const params = {
            provider_name: newProveedor,
        };

        if (newProveedor !== "") {
            await fetch(`/addProvider`, {
                method: "POST",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
                body: JSON.stringify(params),
            })
                .then((response) => {
                    if (response.ok) {
                        setTexto(t("miscellaneous.supplierCreation"));
                        setAlertType("success");
                        setShow(true);
                        getProviders();
                        closeDialog();
                    } else {
                        setTexto(t("miscellaneous.supplierCreationError"));
                        setAlertType("error");
                        setShow(true);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            setTexto(t("miscellaneous.requiredData"));
            setAlertType("warning");
            setShow(true);
            setisError(true);
        }

        setBackDrop(false);
    };

    const handleChangeNew = (event) => {
        setNewProveedor(event.target.value);
    };

    const closeDialog = () => {
        setisError(false);
        setNewProveedor("");
        setDialogForm(false);
    };

    const getProviders = async () => {
        setBackDrop(true);
        await fetch(`/getProviders`, {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then((res) => res.json())
            .then((data) => {
                setProveedores(data.providers);
            });
        setBackDrop(false);
    };

    const requestAccess = async () => {
        setBackDrop(true);
        setAccessReq({ ...accessReq, isResquested: true });

        const params = {
            company_id: data._id,
            token_set_id: todos.userInfo._id,
            user_name: todos.userInfo.user_name,
            client_name: data.company_legal_name,
        };

        await fetch(`/requestAccess`, {
            method: "POST",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
            body: JSON.stringify(params),
        }).then((response) => {
            if (response.ok) {
                setTexto(t("principal.succReq"));
                setAlertType("success");
                setShow(true);
            } else {
                setTexto(t("principal.errorReq"));
                setAlertType("error");
                setShow(true);
            }
        });

        setBackDrop(false);
    };

    const handleChangeSwitch = (event) => {
        setValuesCont({
            ...valuesCont,
            disabledData: event.target.checked,
            sub: event.target.checked ? "N/A" : copyCompanySubscriptionName,
        });
    };

    const dialogBody = (
        <Grid container>
            <Grid item xs={12}>
                <TextField
                    name="proveedor"
                    label={t("miscellaneous.supplier")}
                    required
                    values={newProveedor}
                    onChange={handleChangeNew}
                    fullWidth
                    error={isError && newProveedor === "" ? true : false}
                />
            </Grid>
        </Grid>
    );

    const dialogButtons = (
        <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
            <Grid item>
                <ColorButton
                    onClick={() => setDialogForm(false)}
                    sx={{
                        mr: 1,
                        "&:hover": {
                            backgroundColor: "white",
                            color: "#2f3190",
                            border: "1px solid #2f3190",
                        },
                    }}
                    color="secondary"
                    disableElevation
                    variant="outlined"
                >
                    {t("miscellaneous.cancel")}
                </ColorButton>
            </Grid>
            <Grid item>
                <ColorButton
                    onClick={saveProvee}
                    sx={{
                        "&:hover": {
                            backgroundColor: "#2f3190",
                            border: "1px solid #2f3190",
                        },
                    }}
                    color="secondary"
                    disableElevation
                    variant="contained"
                >
                    {t("miscellaneous.accept")}
                </ColorButton>
            </Grid>
        </Grid>
    );

    useEffect(() => {
        (async () => {
            setLoadingCard(true);

            sortCurrencies();

            await fetch(`/getAccountingServices?id_organisation=${data._id}&token_set_id=${todos.userInfo._id}`, {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
                .then((res) => res.json())
                .then((data) => {
                    const diabledDefault = data.disabled_software
                        ? data.disabled_software
                        : (data.software_provider && data.software_provider === "Xero") ||
                          (data.software_provider && data.software_provider === "Quickbooks Online")
                        ? false
                        : true;

                    setAccessReq({ isResquested: data.is_requested, hasAccess: data.has_access });

                    setValuesCont({
                        user: data.xero_master_user ? data.xero_master_user : "",
                        pass: data.xero_master_password ? data.xero_master_password : "",
                        prov: data.software_provider ? data.software_provider : "",
                        sub: diabledDefault
                            ? "N/A"
                            : data.company_subscription_name
                            ? data.company_subscription_name
                            : "",
                        baseCurrency: data.base_currency
                            ? Currencies.find((currency) => currency.code === data.base_currency)
                            : null,
                        disabledData: diabledDefault,
                    });

                    setCopyCompanySubscriptionName(
                        data.company_subscription_name ? data.company_subscription_name : ""
                    );
                });

            setLoadingCard(false);
        })();
    }, [t, data._id, setBackDrop, setLoadingCard, setTexto, setAlertType, setShow, todos.userInfo._id]);

    return (
        <StyledContainer>
            <Card elevation={0}>
                <CardHeader title={t("miscellaneous.system")} sx={{ color: "#ff5968" }} />
                <Divider />
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item lg={4} sm={6} xs={12}>
                            {loadingCard ? (
                                <Skeleton variant="rounded" animation="wave" height={50} />
                            ) : (
                                <FormControl fullWidth required>
                                    <InputLabel id="provee-simple-select-label">
                                        {t("miscellaneous.supplier")}
                                    </InputLabel>
                                    <Select
                                        name="prov"
                                        labelId="provee-simple-select-label"
                                        IconComponent={KeyboardArrowDownIcon}
                                        value={valuesCont.prov}
                                        label={t("miscellaneous.supplier")}
                                        onChange={handleChangeCont}
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    mt: 0.7,
                                                    borderRadius: 2,
                                                    boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                },
                                            },
                                        }}
                                    >
                                        {prove.map((value) => (
                                            <MenuItem value={value.provider_name}>{value.provider_name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )}
                        </Grid>
                        <Grid item lg={2} sm={6} xs={12}>
                            {loadingCard ? (
                                <Skeleton variant="rounded" animation="wave" height="100%" width="30%" />
                            ) : (
                                <LightTooltip title={t("miscellaneous.addSupplier")} aria-label="cargar">
                                    <IconButton onClick={() => setDialogForm(true)}>
                                        <AddBoxOutlinedIcon color="primary" fontSize="large" />
                                    </IconButton>
                                </LightTooltip>
                            )}
                        </Grid>
                        <Grid item lg={3} xs={12}>
                            {loadingCard ? (
                                <Skeleton variant="rounded" animation="wave" height={50} />
                            ) : (
                                <TextField
                                    fullWidth
                                    label={t("miscellaneous.subscriptionName")}
                                    name="sub"
                                    required
                                    onChange={handleChange}
                                    value={valuesCont.sub}
                                    error={isErrorM && valuesCont.sub.trim() === "" ? true : false}
                                    variant="outlined"
                                    disabled={valuesCont.disabledData}
                                />
                            )}
                        </Grid>
                        <Grid item lg={3} xs={12}>
                            {loadingCard ? (
                                <Skeleton variant="rounded" animation="wave" height={50} />
                            ) : (
                                <Autocomplete
                                    id="currency-autocomplete"
                                    value={valuesCont.baseCurrency}
                                    options={Currencies}
                                    getOptionLabel={(option) => {
                                        if (option.name === "") {
                                            return `${option.code}`;
                                        } else {
                                            return `${option.code} - ${
                                                moment.locale() === "en" ? option.name : option.name_es
                                            }`;
                                        }
                                    }}
                                    renderOption={(props, option) => {
                                        if (option.name === "") {
                                            return (
                                                <Box component="li" {...props}>
                                                    {option.code}
                                                </Box>
                                            );
                                        } else {
                                            return (
                                                <Box component="li" {...props}>
                                                    {option.code} -{" "}
                                                    {moment.locale() === "en" ? option.name : option.name_es}
                                                </Box>
                                            );
                                        }
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            required
                                            label={t("services.functionalCurrency")}
                                            error={isErrorM && valuesCont.baseCurrency === null ? true : false}
                                        />
                                    )}
                                    popupIcon={<KeyboardArrowDownIcon color="primary" />}
                                    PaperComponent={({ children }) => (
                                        <Paper
                                            sx={{
                                                mt: 0.7,
                                                borderRadius: 2,
                                                boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                            }}
                                        >
                                            {children}
                                        </Paper>
                                    )}
                                    onChange={(event, value, reason) => handleChangeCurrency(event, value, reason)}
                                />
                            )}
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <Card elevation={0}>
                <CardHeader
                    title={t("miscellaneous.accountingSystemAccess")}
                    action={
                        loadingCard ? (
                            <Skeleton variant="rounded" animation="wave" height={30} width={100} />
                        ) : (
                            <FormControlLabel
                                inputProps={{ readOnly: true }}
                                control={
                                    <Switch
                                        checked={valuesCont.disabledData}
                                        inputProps={{ "aria-label": "controlled" }}
                                        color={"secondary"}
                                        onChange={handleChangeSwitch}
                                        sx={{
                                            "& .MuiSwitch-switchBase .MuiSwitch-thumb": {
                                                color: "#FFF",
                                            },
                                            "& .MuiSwitch-switchBase + .MuiSwitch-track": {
                                                backgroundColor: "#031851",
                                            },
                                            "& .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb": {
                                                color: "#FF5968",
                                            },
                                            "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                                                backgroundColor: "#FFA8B1",
                                            },
                                        }}
                                    />
                                }
                                label={
                                    <Typography variant="body1" color="textPrimary">
                                        {t("miscellaneous.notApply")}
                                    </Typography>
                                }
                            />
                        )
                    }
                    sx={{ color: "#ff5968" }}
                />
                <Divider />
                <CardContent sx={{ pb: 5 }}>
                    <Grid container spacing={2}>
                        <Grid item lg={6} xs={12}>
                            {loadingCard ? (
                                <Skeleton variant="rounded" animation="wave" height={50} />
                            ) : (
                                <TextField
                                    fullWidth
                                    label={t("login.userAccess")}
                                    name="user"
                                    onChange={handleChange}
                                    value={valuesCont.user}
                                    variant="outlined"
                                    disabled={valuesCont.disabledData}
                                    required={!valuesCont.disabledData}
                                    error={
                                        !valuesCont.disabledData && isErrorM && !valuesCont.user.trim()
                                    }
                                />
                            )}
                        </Grid>
                        <Grid item lg={6} xs={12}>
                            {loadingCard ? (
                                <Skeleton variant="rounded" animation="wave" height={50} />
                            ) : (
                                <FormControl
                                    fullWidth
                                    variant="outlined"
                                    disabled={
                                        valuesCont.disabledData || !clientPassAccess.includes(todos.userInfo.email)
                                    }
                                    required={!valuesCont.disabledData}
                                    error={!valuesCont.disabledData && isErrorM && !valuesCont.pass.trim()}
                                >
                                    <InputLabel htmlFor="outlined-adornment-password">{t("login.password")}</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-password"
                                        fullWidth
                                        type={valuesCont.showPassword ? "text" : "password"}
                                        label={t("login.password")}
                                        name="pass"
                                        onChange={handleChange}
                                        value={
                                            clientPassAccess.includes(todos.userInfo.email) || accessReq.hasAccess
                                                ? valuesCont.pass
                                                : valuesCont.pass
                                                ? "••••••••"
                                                : ""
                                        }
                                        endAdornment={
                                            <InputAdornment position="end">
                                                {clientPassAccess.includes(todos.userInfo.email) ||
                                                accessReq.hasAccess ? (
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {valuesCont.showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                ) : !accessReq.isResquested ? (
                                                    <Button
                                                        variant="contained"
                                                        sx={{
                                                            pl: 3,
                                                            pr: 3,
                                                            borderRadius: 40,
                                                            "&:hover": {
                                                                backgroundColor: "#2f3190",
                                                                color: "#fff",
                                                            },
                                                            backgroundColor: "#2F3190",
                                                        }}
                                                        endIcon={<LockOpenIcon />}
                                                        onClick={requestAccess}
                                                    >
                                                        {t("principal.getAccess")}
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        variant="contained"
                                                        sx={{
                                                            pl: 3,
                                                            pr: 3,
                                                            borderRadius: 40,
                                                            "&:disabled": {
                                                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                                                                color: "#fff",
                                                            },
                                                        }}
                                                        disabled
                                                        endIcon={<AccessTimeIcon />}
                                                    >
                                                        {t("principal.requested")}
                                                    </Button>
                                                )}
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            )}
                        </Grid>
                    </Grid>
                </CardContent>
                <Divider />
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        p: 2,
                        pb: 0,
                    }}
                >
                    <Button
                        color="secondary"
                        variant="contained"
                        disableElevation
                        classes={{ root: classes.btnRoot }}
                        onClick={updateClientData}
                    >
                        {t("miscellaneous.save")}
                    </Button>
                </Box>
            </Card>
            <AlertDialog
                open={dialogForm}
                onClose={closeDialog}
                message={dialogBody}
                title={t("miscellaneous.newSupplier")}
                button={dialogButtons}
                maxWidth="xs"
            />
        </StyledContainer>
    );
};

export default ClientCont;
