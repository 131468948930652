/** Imports ---------------------------------------------------------------- */

/** React imports */
import React from "react";
import { useTranslation } from 'react-i18next';

/** Components ----------------------------------------------------------- */
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const PREFIX = 'InputFile';

const classes = {
    tooltip: `${PREFIX}-tooltip`,
    inputFileContainer: `${PREFIX}-inputFileContainer`,
    inputFileLabel: `${PREFIX}-inputFileLabel`
};

const Input = styled('input')({
    display: 'none',
});

const Root = styled('div')((
    {
        theme
    }
) => ({
    [`&.${classes.inputFileContainer}`]: {
        display: 'flex',
        alignItems: 'center',
    },

    [`& .${classes.inputFileLabel}`]: {
        borderRadius: '40px !important',
        textTransform: 'none !important',
        border: '2px solid !important',
        '&:label': {
            fontSize: '1em',
            color: theme.palette.primary.main,
        }
    }
}));

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: 'rgba(19, 31, 62, 0.80)',
        fontSize: 12,
        maxWidth: 320,
        borderRadius: 6,
        fontWeight: 'normal'
    },
});

///  Componente InputFile para cambiar el estilo del botón
/// default del input de tipo file que da el explorador web
const InputFile = (props) => {
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    const setFileInfo = (event) => {
        var fileName = "";
        var element = event.target.nextElementSibling;
        var label = element,
            labelVal = label.innerHTML;

        if (event.target.files && event.target.files.length > 1)
            fileName = (
                event.target.getAttribute("data-multiple-caption") || ""
            ).replace("{count}", event.target.files.length);
        else fileName = event.target.value.split("\\").pop();

        if (fileName) label.innerHTML = fileName;
        else label.innerHTML = labelVal;

        if (props.onChange) {
            props.onChange(event);
        }
    };

    return (
        <Root className={classes.inputFileContainer}>
            <label htmlFor={props.id}>
                <Input
                    id={props.id}
                    type="file"
                    onChange={(event) => setFileInfo(event)}
                />
                <LightTooltip
                    title={props.tooltip}
                    placement={props.place}>
                    <Button id={props.id + "label"} variant="outlined" color="primary" component="span" className={classes.inputFileLabel}>
                        {t("bank.browse")}
                    </Button>
                </LightTooltip>
            </label>
        </Root>
    );
}

export default InputFile;
