/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment from "moment";

/** MUI icons */
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CheckIcon from '@mui/icons-material/Check';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import DownloadIcon from '@mui/icons-material/Download';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import WarningAmberIcon from '@mui/icons-material/Warning';

/** MUI imports */
import { Grid, Table, TableHeaderRow, PagingPanel, Toolbar, SearchPanel, TableColumnVisibility } from '@devexpress/dx-react-grid-material-ui';
import { SearchState, IntegratedFiltering, PagingState, IntegratedPaging } from '@devexpress/dx-react-grid';
import { styled } from '@mui/material/styles';
import GridUI from "@mui/material/Grid";
import {
    Box,
    Breadcrumbs,
    Button,
    Card,
    CardContent,
    FormControl,
    IconButton,
    InputLabel,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    Skeleton,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';

/** Components imports */
import Backdrop from "../Backdrop";
import DownloadDialog from "../Dialog";
import SnackBar from "../SnackBar";
import ModInvoice from "./ModInvoice";

const PREFIX = 'ClientInvoicing';

const classes = {
    paper: `${PREFIX}-paper`,
    tableStriped: `${PREFIX}-tableStriped`,
    tableHeader: `${PREFIX}-tableHeader`,
    tableBody: `${PREFIX}-tableBody`,
    pager: `${PREFIX}-pager`,
    cardContentRoot: `${PREFIX}-cardContentRoot`,
    formRounded: `${PREFIX}-formRounded`,
    formControlRounded: `${PREFIX}-formControlRounded`,
    spacing: `${PREFIX}-spacing`,
    toolbarSearch: `${PREFIX}-toolbarSearch`,
    searchInput: `${PREFIX}-searchInput`,
    invoiceCel: `${PREFIX}-invoiceCel`
};

const StyledContainer = styled(Box)((
    {
        theme
    }
) => ({
    [`& .${classes.paper}`]: {
        border: '2px solid #ff5968',
        borderRadius: 40,
        boxShadow: 'none'
    },

    [`& .${classes.cardContentRoot}`]: {
        '&:last-child': {
            paddingBottom: '18px'
        },
        padding: 30,
    },

    [`& .${classes.formRounded}`]: {
        backgroundColor: '#FFFFFF !important',
        borderRadius: 40,
        verticalAlign: 'middle !important',
        "&:focus-within": {
            backgroundColor: "#FFFFFF",
            borderRadius: 40,
        },
        "& .MuiOutlinedInput-notchedOutline": {
            border: '2px solid #031851',
            borderRadius: 40,
        },
    },

    [`& .${classes.formControlRounded}`]: {
        backgroundColor: '#FFFFFF !important',
        borderRadius: 40,
        border: '2px solid #031851',
        verticalAlign: 'middle !important',
    },

    [`& .${classes.spacing}`]: {
        padding: '12px 0 25px 0'
    },

    [`& .${classes.tableStriped}`]: {
        '& tbody tr:nth-of-type(odd)': {
            backgroundColor: 'rgba(47,49,144,0.1)',
        },
    },
    [`& .${classes.tableHeader}`]: {
        border: 'none ',
        backgroundColor: 'rgba(47,49,144,0.3)',
        fontSize: '15px',
        fontWeight: 600,
        color: '#031851',
        '&:first-of-type': {
            borderRadius: '20px 0 0 0',
        },
        '&:last-child': {
            borderRadius: '0 20px 0 0',
        },
        '& > .TableSelectAllCell-checkbox': {
            display: 'none !important',
        }
    },

    [`& .${classes.tableBody}`]: {
        border: 'none',
        fontSize: '14px',
        fontWeight: 400,
        color: '#031851',
    },

    [`& .${classes.pager}`]: {
        "& .Pagination-button": {
            paddingTop: 0,
            paddingBottom: 0,
            color: '#031851',
            fontWeight: 600
        },
        "& .Pagination-activeButton": {
            backgroundColor: 'rgba(47,49,144,0.3)',
        }
    },

    [`& .${classes.toolbarSearch}`]: {
        border: 'none !important',
        padding: '0 0 5px !important',
    },

    [`& .${classes.searchInput}`]: {
        color: '#031851',
        '&:before': {
            borderBottomColor: '#031851',
        },
        '&:hover:before': {
            borderBottomColor: '#031851 !important',
        }
    },

    [`& .${classes.invoiceCel}`]: {
        color: '#2f3190',
        cursor: 'pointer',
        padding: '7px',
        borderRadius: '6px',
        '&:hover': {
            borderRadius: '6px',
            backgroundColor: 'rgba(47,49,144,0.3)',
        }
    },
}));

const ColorButton = styled(Button)(({ theme }) => ({
    border: '1px solid #ff5968',
    borderRadius: 40
}));

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: 'rgba(19, 31, 62, 0.80)',
        fontSize: 12,
        maxWidth: 320,
        borderRadius: 6,
        fontWeight: 'normal'
    },
});

const tableHeaderCell = ({ ...props }) => (
    <TableHeaderRow.Cell
        {...props}
        className={classes.tableHeader}
    />
);

const TableComponent = ({ ...props }) => (
    <Table.Table
        {...props}
        className={classes.tableStriped}
    />
);

const TableCellComponent = ({ ...props }) => (
    <Table.Cell
        {...props}
        className={classes.tableBody}
    />
);

const tableContentComponent = props => (
    <TableHeaderRow.Content {...props} />
);

const PagingPanelContainer = ({ ...props }) => (
    <PagingPanel.Container
        {...props}
        className={classes.pager}
    />
);

const inputComponent = ({ ...props }) => (
    <SearchPanel.Input
        {...props}
        className={classes.searchInput}
    />
);

const toolRootComponent = ({ ...props }) => (
    <Toolbar.Root
        {...props}
        className={classes.toolbarSearch}
    />
);

const tableColumnExtensions = [
    { columnName: "invoice" },
    { columnName: "description", width: "20%" },
    { columnName: "initial", align: 'center' },
    { columnName: "amount", align: 'right' },
    { columnName: "paid", align: 'right' },
    { columnName: "pending", align: 'right' },
    { columnName: "status", align: 'center' },
    { columnName: "attached", align: 'center' },
]

const rowsSkeleton = []

for (let i = 0; i < 10; i++) {
    rowsSkeleton.push({
        id: i,
        invoice: <Skeleton variant="text" animation="wave" />,
        description: <Skeleton variant="text" animation="wave" />,
        initial: <Skeleton variant="text" animation="wave" />,
        amount: <Skeleton variant="text" animation="wave" />,
        paid: <Skeleton variant="text" animation="wave" />,
        pending: <Skeleton variant="text" animation="wave" />,
        status: <Skeleton variant="text" animation="wave" />,
        attached: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: 'auto', mr: 'auto' }} />
    });
}

const ClientInvoicing = (props) => {
    /** Internationalization i18n */
    const [t] = useTranslation("global");
    

    const tableMessages = {
        noData: t("miscellaneous.noData")
    };
    const pagingMessages = {
        info: ({ from, to, count }) => `${from}${from < to ? `-${to}` : ''} ${t("miscellaneous.pagingOf")} ${count}`,
    };

    const noColumnsMessage = {
        noColumns: ""
    };

    const searchMessages = {
        searchPlaceholder: t("miscellaneous.search")
    };

    /** React redux */
    const todos = useSelector(state => state.todos);

    /** Component states */
    const [defaultHiddenColumnNames] = useState(['hiddenInvoice', 'hiddenStatus']);
    const [clientsData, setClients] = useState([]);
    const [columnDefs, setColumnsDefs] = useState([]);
    const [showBackdrop, setBackDrop] = useState(false);
    const [status, setStatus] = useState('');
    const [sinceDateF, setSinceDateF] = useState(null);
    const [untilDateF, setUntilDateF] = useState(null);
    const [show, setShow] = useState(false);
    const [texto, setTexto] = useState('');
    const [alertType, setAutoAlertType] = useState('');
    const [searchValue, setSearchState] = useState('');
    const [loading, showLoading] = useState(false);
    const [openViewInvoice, setViewInvoice] = useState(false);
    const [invoiceFiles, setInvoiceFiles] = useState([]);
    const [dialogForm, setDialogForm] = useState(false);
    const [lineItemsSelected, setLineItemsSelected] = useState({
        lineItems: [],
        contactName: ""
    });
    const [totals, setTotals] = useState({
        total: 0,
        paid: 0,
        pending: 0,
    });

    /** Component variables */
    const breadcrumbs = [
        <Typography key="1" variant="h1" color="primary" sx={{
            fontSize: 24,
            fontWeight: 700
        }}>
            {t('miscellaneous.invoicing')}
        </Typography>
    ];

    /** Component functions */
    const handleSearch = (value) => {
        let itemS = value.replace(/ +(?= )/g, '');
        let newData = []

        if (itemS === "") {
            newData = clientsData;
        }
        else {
            newData = clientsData.filter(item => (item.hiddenInvoice && item.hiddenInvoice.toLowerCase().includes(itemS.toLowerCase()))
                || (item.description && item.description.toLowerCase().includes(itemS.toLowerCase()))
                || (item.initial && item.initial.toLowerCase().includes(itemS.toLowerCase()))
                || (item.amount && item.amount.toLowerCase().includes(itemS.toLowerCase()))
                || (item.paid && item.paid.toLowerCase().includes(itemS.toLowerCase()))
                || (item.pending && item.pending.toLowerCase().includes(itemS.toLowerCase()))
                || (item.hiddenStatus && item.hiddenStatus.toLowerCase().includes(itemS.toLowerCase())))
        }

        let totalPaid = 0;
        let totalPending = 0;

        newData.forEach(data => {
            totalPaid += data.hiddenPaid;
            totalPending += data.hiddenPending;
        })
        setTotals({
            total: totalPaid + totalPending,
            paid: totalPaid,
            pending: totalPending,
        });

        setSearchState(itemS);
    };

    const closeDialog = () => {
        setInvoiceFiles([]);
        setDialogForm(false);
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setShow(false);
    };

    const handleChangeStatus = (event) => {
        setStatus(event.target.value);
    };

    const handleChangeSinceDate = (date) => {
        if (date === null) {
            setSinceDateF(date);
        } else {
            setSinceDateF(moment(date).format("YYYY-MM-DD"));
        }
    };

    const handleChangeUntilDate = (date) => {
        if (date === null) {
            setUntilDateF(date);
        } else {
            setUntilDateF(moment(date).format("YYYY-MM-DD"));
        }
    };

    const showDetails = async (id, name, type, total) => {
        setBackDrop(true);

        const resultT = await fetch(`/getInvoicesDetails?invoice_id=${id}`, {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        }
        ).then((res) => res.json()
        ).then((data) => {
            return data;
        });

        const arrayInvoicing = {
            lineItems: resultT,
            contactName: name,
            invoiceType: type,
            totalAmount: total
        };

        setLineItemsSelected(arrayInvoicing);
        setViewInvoice(true);

        setBackDrop(false);
    }

    const showModalDownload = async(id) => {
        setBackDrop(true);

        const resultT = await fetch(`/getUploadedFilesXero?invoice_id=${id}`, {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        }
        ).then((res) => res.json()
        ).then((data) => {
            return data;
        });

        let filesData = resultT.map(file => {
            return {
                invoiceID: id,
                attachmentID: file.attachmentID,
                fileName: file.fileName,
            }
        });

        setInvoiceFiles(filesData);
        setDialogForm(true)

        setBackDrop(false);
    }

    const loadInitData = async () => {
        setSearchState("");
        
        const resultT = await fetch(`/getInvoicesListByEmail?contact_email=${todos.userInfo.email}&id_status=${status}&init_date=${sinceDateF === null ? "" : sinceDateF}&end_date=${untilDateF === null ? "" : untilDateF}`, {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        }
        ).then((res) => res.json()
        ).then((data) => {
            return data;
        });

        let totalPaid = 0;
        let totalPending = 0;

        let invoicesData = resultT.map((invoiceData, index) => {
            totalPaid += invoiceData.invoice_paid_amount;
            totalPending += invoiceData.invoice_due_amount;

            return {
                hiddenInvoice: invoiceData.invoice_number,
                hiddenPaid: invoiceData.invoice_paid_amount,
                hiddenPending: invoiceData.invoice_due_amount,
                hiddenStatus: invoiceData.id_status === 1 ? t('invoicing.pending') : invoiceData.id_status === 2 ? t('invoicing.partial') : t('invoicing.paid'),
                id: invoiceData._id,
                idInvoiceXero: invoiceData.id_invoice_xero,
                invoice: <LightTooltip title={t('invoicing.invoiceDetail')} aria-label="invoice-number">
                    <Typography component="span" variant="h4" className={classes.invoiceCel}
                        onClick={() => showDetails(invoiceData._id, invoiceData.contact_name, invoiceData.invoice_type_method, invoiceData.invoice_total_amount)}>
                        {invoiceData.invoice_number}
                    </Typography>
                </LightTooltip>,
                description: invoiceData.contact_name,
                initial: moment(invoiceData.invoice_date, "YYYY-MM-DD").format(todos.dateFormat),
                amount: parseFloat(invoiceData.invoice_total_amount).toLocaleString(todos.amountFormat, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                paid: parseFloat(invoiceData.invoice_paid_amount).toLocaleString(todos.amountFormat, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                pending: parseFloat(invoiceData.invoice_due_amount).toLocaleString(todos.amountFormat, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                status:
                    <ListItem alignItems="center"
                        secondaryAction={
                            invoiceData.id_status === 1 ? <AccessTimeFilledIcon color="secondary" /> : invoiceData.id_status === 2 ? <WarningAmberIcon sx={{ color: '#FFB039' }} />
                                : <CheckIcon sx={{ color: 'green' }} />
                        }
                        sx={{
                            width: { xs: "100%", lg: "70%" },
                            ml: "auto",
                            mr: "auto",
                        }}
                    >
                        <ListItemText
                            primary={
                                invoiceData.id_status === 1 ? t('invoicing.pending') : invoiceData.id_status === 2 ? t('invoicing.partial') : t('invoicing.paid')
                            }
                        />
                    </ListItem>
                ,
                attached: <LightTooltip title={t('dialogConversions.download')} aria-label="toolUpload">
                    <IconButton aria-label="upload" onClick={() => showModalDownload(invoiceData._id)}>
                        <AttachFileIcon color="primary" />
                    </IconButton>
                </LightTooltip>,
            }
        });

        setTotals({
            total: totalPaid + totalPending,
            paid: totalPaid,
            pending: totalPending,
        });
        setClients(invoicesData);

        showLoading(false);
    }

    const downloadFileXero = (invoiceID, attachmentID, fileName) => async () => {
        setBackDrop(true);

        await fetch(`/downloadFileXero?invoice_id=${invoiceID}&attachmentID=${attachmentID}&fileName=${fileName}`, {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        }
        ).then(res => res.blob())
            .then(blob => {
                const link = document.createElement("a");
                link.href = URL.createObjectURL(blob);
                link.setAttribute("download", fileName);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch((err) => {
                setTexto(t('reports.donwloadingReptError'));
                setAutoAlertType("warning");
                setShow(true);
            })

        setBackDrop(false);
    }

    const dialogBody = (
        invoiceFiles.length !== 0 ?
            <GridUI container spacing={2}>
                <GridUI item xs={12}>
                    <List>
                        {invoiceFiles.map( ({ invoiceID, attachmentID, fileName }) => (
                            <ListItem
                                disablePadding
                                secondaryAction={
                                    <Box>
                                        <LightTooltip title={t('dialogConversions.download')} aria-label="tooltip-downloadFileXero">
                                            <IconButton
                                                edge="end"
                                                aria-label="downloadFileXero"
                                                onClick={downloadFileXero(invoiceID, attachmentID, fileName)}
                                                sx={{ mr: 1 }}
                                            >
                                                <DownloadIcon fontSize='medium' color='primary' />
                                            </IconButton>
                                        </LightTooltip>
                                    </Box>
                                }
                                sx={{ height: 50 }}
                            >
                                <ListItemIcon sx={{ minWidth: '20px' }}>
                                    <DescriptionOutlinedIcon fontSize='large' color='primary' sx={{ mr: 1 }} />
                                </ListItemIcon>
                                <ListItemText
                                    key={0}
                                    primary={fileName}
                                    primaryTypographyProps={{ width: '80%' }}
                                />
                            </ListItem>
                        ))}
                    </List>
                </GridUI>
            </GridUI>
        :
            <GridUI container justifyContent="center" spacing={2}>
                <GridUI item>
                    <Typography variant="h1">{t('invoicing.withoutFiles')}</Typography>
                </GridUI>
            </GridUI>                          
    );

    const dialogButtons = (
        <GridUI container justifyContent="flex-end" alignItems="center" spacing={2}>
            <GridUI item>
                <ColorButton onClick={() => setDialogForm(false)}
                    sx={{
                        mr: 1,
                        '&:hover': {
                            backgroundColor: 'white',
                            color: '#2f3190',
                            border: '1px solid #2f3190',
                        },
                    }}
                    color="secondary"
                    disableElevation
                    variant="outlined">
                    {t("miscellaneous.cancel")}
                </ColorButton>
            </GridUI>
        </GridUI>
    );

    /* Component events */
    useEffect(() => {
        (async () => {
            showLoading(true);

            const columnsTemplate = [
                { name: 'hiddenInvoice', title: 'Hidden Invoice' },
                { name: 'hiddenStatus', title: 'Hidden Status' },
                { name: "invoice", title: t("invoicing.number") },
                { name: "description", title: t("invoicing.description") },
                { name: "initial", title: t('invoicing.creationDate') },
                { name: "amount", title: t('invoicing.amount') },
                { name: "paid", title: t('invoicing.paid') },
                { name: "pending", title: t('recurrent.pend') },
                { name: "status", title: t('miscellaneous.status') },
                { name: "attached", title: t('invoicing.attached') },
            ];
            setColumnsDefs(columnsTemplate);
            setClients(rowsSkeleton);

            await loadInitData();
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t, todos.dateFormat, todos.amountFormat, sinceDateF, untilDateF, status]);

    return (
        <StyledContainer component="main" sx={{ flexGrow: 1, p: 4, width: '100%', pt: 10 }}>
            <Stack sx={{ pb: 4 }}>
                <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small" sx={{ color: '#031851' }} />}
                    aria-label="breadcrumb"
                >
                    {breadcrumbs}
                </Breadcrumbs>
            </Stack>
            <GridUI container spacing={2} justifyContent="center" alignItems="center">
                <GridUI item xs={12}>
                    <Card elevation={0}
                        sx={{
                            borderRadius: 8,
                            boxShadow: '10px 10px 15px rgba(3,24,81,0.15)',
                        }}
                    >
                        <CardContent classes={{ root: classes.cardContentRoot }}>
                            <GridUI container spacing={2}>
                                <GridUI item xs={12}>
                                    {loading ?
                                        <GridUI
                                            container
                                            spacing={2}
                                            className={classes.spacing}
                                            alignItems="center"
                                        >
                                            <GridUI item xs={12} md={5} xl={4}>
                                                <Skeleton variant="rectangular" animation="wave" width="100%" height={40} sx={{ borderRadius: 8 }} />
                                            </GridUI>
                                            <GridUI item xs={12} md={3} xl={2}>
                                                <Skeleton variant="rectangular" animation="wave" width="100%" height={40} sx={{ borderRadius: 8 }} />
                                            </GridUI>
                                        </GridUI>
                                        :
                                        <GridUI
                                            container
                                            spacing={2}
                                            className={classes.spacing}
                                        >
                                            <GridUI item container xs={12} md={5} xl={4}>
                                                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={t("language.locale")} localeText={{ clearButtonLabel: t('miscellaneous.clear') }}>
                                                    <GridUI item xs={6} md={6} xl={6}>
                                                        <DatePicker
                                                            componentsProps={{
                                                                actionBar: { actions: ['clear'] }
                                                            }}
                                                            value={sinceDateF}
                                                            onChange={(newValue) => handleChangeSinceDate(newValue)}
                                                            inputFormat={todos.dateFormat}
                                                            renderInput={({ inputProps, ...params }) =>
                                                                <TextField
                                                                    fullWidth
                                                                    {...params}
                                                                    inputProps={{
                                                                        ...inputProps,
                                                                        placeholder: t("invoicing.initDate"),
                                                                        sx: {
                                                                            p: '10.45px 0px 10.45px 18px !important',
                                                                        }
                                                                    }}
                                                                    sx={{
                                                                        "& .MuiOutlinedInput-notchedOutline": {
                                                                            mr: '-6px !important',
                                                                            border: '2px solid #031851',
                                                                            borderRadius: '40px 0px 0px 40px',
                                                                        },
                                                                    }}
                                                                    InputLabelProps={{ shrink: true }}
                                                                />
                                                            }
                                                            label={t('invoicing.range')}
                                                        />
                                                    </GridUI>
                                                    <GridUI item xs={6} md={6} xl={6}>
                                                        <DatePicker
                                                            componentsProps={{
                                                                actionBar: { actions: ['clear'] }
                                                            }}
                                                            value={untilDateF}
                                                            onChange={(newValue) => handleChangeUntilDate(newValue)}
                                                            inputFormat={todos.dateFormat}
                                                            renderInput={({ inputProps, ...params }) =>
                                                                <TextField
                                                                    fullWidth
                                                                    {...params}
                                                                    inputProps={{
                                                                        ...inputProps,
                                                                        placeholder: t("invoicing.endDate"),
                                                                        sx: {
                                                                            p: '10.45px 0px 10.45px 18px !important',
                                                                        }
                                                                    }}
                                                                    sx={{
                                                                        ml: 0.5,
                                                                        "& .MuiOutlinedInput-notchedOutline": {
                                                                            border: '2px solid #031851',
                                                                            borderRadius: '0px 40px 40px 0px',
                                                                        }
                                                                    }}
                                                                />
                                                            }
                                                        />
                                                    </GridUI>
                                                </LocalizationProvider>
                                            </GridUI>
                                            <GridUI item xs={12} md={3} xl={2}>
                                                <FormControl fullWidth classes={{ root: classes.formRounded }}>
                                                    <InputLabel shrink id="status-simple-select-label">{t("miscellaneous.status")}</InputLabel>
                                                    <Select
                                                        size="small"
                                                        IconComponent={KeyboardArrowDownIcon}
                                                        value={status}
                                                        displayEmpty
                                                        onChange={handleChangeStatus}
                                                        className="ddlGeneral"
                                                        input={<OutlinedInput notched label={t("miscellaneous.status")} />}
                                                        MenuProps={{
                                                            PaperProps: {
                                                                sx: {
                                                                    mt: 0.7,
                                                                    borderRadius: 2,
                                                                    boxShadow: '10px 10px 15px 0px rgba(3,24,81,0.15)',
                                                                },
                                                            },
                                                        }}
                                                    >
                                                        <MenuItem value="">
                                                            <Typography variant="body1" component="span" align="center" color="textPrimary">{t("miscellaneous.all")}</Typography>
                                                        </MenuItem>
                                                        <MenuItem value={1}>
                                                            <Typography variant="body1" component="span" align="center" color="textPrimary">{t('invoicing.pending')}</Typography>
                                                        </MenuItem>
                                                        <MenuItem value={2}>
                                                            <Typography variant="body1" component="span" align="center" color="textPrimary">{t('invoicing.partial')}</Typography>
                                                        </MenuItem>
                                                        <MenuItem value={3}>
                                                            <Typography variant="body1" component="span" align="center" color="textPrimary">{t('invoicing.paid')}</Typography>
                                                        </MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </GridUI>
                                        </GridUI>
                                    }
                                    <Grid
                                        rows={clientsData}
                                        columns={columnDefs}
                                    >
                                        <SearchState
                                            value={searchValue}
                                            onValueChange={handleSearch}
                                        />
                                        <IntegratedFiltering />
                                        <PagingState
                                            defaultCurrentPage={0}
                                            pageSize={10}
                                        />
                                        <IntegratedPaging />
                                        <Table
                                            tableComponent={TableComponent}
                                            columnExtensions={tableColumnExtensions}
                                            cellComponent={TableCellComponent}
                                            messages={tableMessages}
                                        />
                                        <TableColumnVisibility
                                            defaultHiddenColumnNames={defaultHiddenColumnNames}
                                            messages={noColumnsMessage}
                                        />
                                        <TableHeaderRow
                                            cellComponent={tableHeaderCell}
                                            contentComponent={tableContentComponent}
                                        />
                                        <Toolbar
                                            rootComponent={toolRootComponent}
                                        />
                                        <SearchPanel
                                            messages={searchMessages}
                                            inputComponent={inputComponent}
                                        />
                                        <PagingPanel
                                            containerComponent={PagingPanelContainer}
                                            messages={pagingMessages}
                                        />
                                    </Grid>
                                    {loading ? 
                                        <Skeleton variant="rectangular" animation="wave" height={20} width={500} sx={{ mb: 1, borderRadius: 8 }} />
                                    :
                                        <Box sx={{ pb: 1 }}>
                                            <Typography component="span" variant="body1" sx={{ pr: 3 }}>
                                                <strong>Total: </strong>$ { parseFloat(totals.total).toLocaleString(todos.amountFormat, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }
                                            </Typography>
                                            <Typography component="span" variant="body1" sx={{ pr: 3 }}>
                                                <strong>{t('invoicing.paid')}: </strong>$ { parseFloat(totals.paid).toLocaleString(todos.amountFormat, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }
                                            </Typography>
                                            <Typography component="span" variant="body1">
                                                <strong>{t('invoicing.pending')}: </strong>$ { parseFloat(totals.pending).toLocaleString(todos.amountFormat, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }
                                            </Typography>
                                        </Box>
                                    }
                                </GridUI>
                            </GridUI>
                        </CardContent>
                    </Card>
                </GridUI>
            </GridUI>
            <SnackBar
                type={alertType}
                show={show}
                message={texto}
                onClose={handleClose}
            />
            <Backdrop
                open={showBackdrop}
            />
            <ModInvoice
                open={openViewInvoice}
                onClose={() => setViewInvoice(false)}
                lineItemsSelected={lineItemsSelected}
                isEditMode={false}
            />
            <DownloadDialog
                open={dialogForm}
                onClose={closeDialog}
                message={dialogBody}
                title={t('invoicing.downloadDocuments')}
                button={dialogButtons}
                maxWidth="sm"
            />
        </StyledContainer>
    );
}

export default ClientInvoicing;