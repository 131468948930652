// React
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";

// MUI icons
import CheckIcon from "@mui/icons-material/Check";
import EditIcon from "@mui/icons-material/Edit";
import EditOffIcon from "@mui/icons-material/EditOff";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// MUI
import { styled } from "@mui/material/styles";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import {
    Box,
    FormControl,
    FormControlLabel,
    IconButton,
    InputAdornment,
    InputLabel,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    OutlinedInput,
    Switch,
    TextField,
    Typography,
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Grid from "@mui/material/Grid";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

/** Component imports */
import { UpdateAdd } from "./";

const PREFIX = "CatchUp";

const classes = {
    switch: `${PREFIX}-switch`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    backgroundColor: "#F8F9F8",
    marginTop: 16,
    padding: 30,
    borderRadius: 30,

    [`& .${classes.switch}`]: {
        "& .MuiSwitch-switchBase .MuiSwitch-thumb": {
            color: "#FFF",
        },
        "& .MuiSwitch-switchBase + .MuiSwitch-track": {
            backgroundColor: "#031851",
        },
        "& .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb": {
            color: "#FF5968",
        },
        "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
            backgroundColor: "#FFA8B1",
        },
    },
}));

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)({
    backgroundColor: "#F8F9F8",
    boxShadow: "none",
    "&:before": {
        display: "none",
    },
});

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: "#031851" }} />} {...props} />
))({
    "& .MuiAccordionSummary-content": {
        margin: "12px 0",
    },
});

const AccordionDetails = styled(MuiAccordionDetails)({
    padding: "0px 0px 20px 0px",
    "& .MuiCard-root": {
        boxShadow: "none",
    },
});

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "rgba(19, 31, 62, 0.80)",
        fontSize: 12,
        maxWidth: 400,
        borderRadius: 6,
        fontWeight: "normal",
    },
});

export const CatchUp = (props) => {
    // Properties
    const { isError, setAutoAlertType, setShow, setTexto, setValues, tariffAdditionals, values, isErrorFocus, setIsErrorFocus } = props;

    /** Internationalization i18n */
    const [t] = useTranslation("global");

    /** React redux */
    const todos = useSelector((state) => state.todos);

    /** Component states */
    const [editMode, setEditMode] = useState(true);
    const [editMode2, setEditMode2] = useState(true);

    /** Global variables */
    const checks =
        values.checkedChecks ||
        values.checkedAR ||
        values.checkedAP ||
        values.checkedAccrual ||
        values.checkedClass ||
        values.checkedMulti ||
        values.checkedPer ||
        values.checkedOthers;

    const sumTotal =
        parseFloat(values.tothChecks) *
        (values.fixedHours ? 1 : (isNaN(moment(values.finishDateChecks).diff(moment(values.startDateChecks)))
            ? 0
            : Math.ceil(moment(values.finishDateChecks).diff(moment(values.startDateChecks), "months", true)))) +
        parseFloat(values.tothAR) *
        (values.fixedHours ? 1 : (isNaN(moment(values.finishDateAR).diff(moment(values.startDateAR)))
            ? 0
            : Math.ceil(moment(values.finishDateAR).diff(moment(values.startDateAR), "months", true)))) +
        parseFloat(values.tothAP) *
        (values.fixedHours ? 1 : (isNaN(moment(values.finishDateAP).diff(moment(values.startDateAP)))
            ? 0
            : Math.ceil(moment(values.finishDateAP).diff(moment(values.startDateAP), "months", true)))) +
        parseFloat(values.tothAccrual) *
        (values.fixedHours ? 1 : (isNaN(moment(values.finishDateAccrual).diff(moment(values.startDateAccrual)))
            ? 0
            : Math.ceil(moment(values.finishDateAccrual).diff(moment(values.startDateAccrual), "months", true)))) +
        parseFloat(values.tothClass) *
        (values.fixedHours ? 1 : (isNaN(moment(values.finishDateClass).diff(moment(values.startDateClass)))
            ? 0
            : Math.ceil(moment(values.finishDateClass).diff(moment(values.startDateClass), "months", true)))) +
        parseFloat(values.tothMulti) *
        (values.fixedHours ? 1 : (isNaN(moment(values.finishDateMulti).diff(moment(values.startDateMulti)))
            ? 0
            : Math.ceil(moment(values.finishDateMulti).diff(moment(values.startDateMulti), "months", true)))) +
        parseFloat(values.tothPer) *
        (values.fixedHours ? 1 : (isNaN(moment(values.finishDatePer).diff(moment(values.startDatePer)))
            ? 0
            : Math.ceil(moment(values.finishDatePer).diff(moment(values.startDatePer), "months", true)))) +
        parseFloat(values.tothOthers) *
        (values.fixedHours ? 1 : (isNaN(moment(values.finishDateOthers).diff(moment(values.startDateOthers)))
            ? 0
            : Math.ceil(moment(values.finishDateOthers).diff(moment(values.startDateOthers), "months", true))));

    /** Component functions */
    useEffect(() => {
        setValues({
            ...values,
            catchUpChecks: checks,
            totalHours: sumTotal,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sumTotal, checks]);

    const handleChangeSwitchHourly = (event) => {
        let meses = Math.ceil(values.untilDate.diff(values.sinceDate, "months", true));

        setValues({
            ...values,
            discount: event.target.checked ? 0 : 30,
            projectTotal: event.target.checked ? 0 : values.catchUpOriginalFee * meses * 0.7,
            months: meses,
            catchUpAdditionalsDiscount: event.target.checked ? 0 : 30,
            additionalsOnly: event.target.checked,
            checkedBook: event.target.checked,
            tothBook: 0,
            startDateBook: event.target.checked ? values.sinceDate : null,
            finishDateBook: event.target.checked ? values.untilDate : null,
            isError: false,
            fixedHours: false
        });
        setIsErrorFocus(false)
    };

    const handleChangeSwitchFixedHours = (event) => {

        setValues({
            ...values,
            fixedHours: event.target.checked,
            projectTotal: 0,
            tothBook: 0,
        });

        setIsErrorFocus(false)
    };

    const handleChangeInitDate = (date) => {
        let startDate = moment(date).startOf("month");

        if (!date || !values.untilDate) {
            setValues({
                ...values,
                sinceDate: date ? startDate : date,
            });
        } else {
            if (startDate.isAfter(moment(values.untilDate).format("YYYY-MM-DD"))) {
                setValues({
                    ...values,
                    sinceDate: null,
                    isError: false
                });

                setTexto(t("reports.initDateMess"));
                setAutoAlertType("warning");
                setShow(true);
            } else {
                let meses = Math.ceil(values.untilDate.diff(startDate, "months", true));

                let bookMonths = 0;
                if (values.additionalsOnly) {
                    if (values.fixedHours) {
                        bookMonths = 1;
                    } else {
                        bookMonths = Math.ceil(moment(values.finishDateBook).diff(startDate, "months", true));
                    }
                }

                setValues({
                    ...values,
                    sinceDate: startDate,
                    projectTotal: values.additionalsOnly
                        ? values.tothBook * values.hourRate * bookMonths * (1 - values.discount / 100)
                        : values.catchUpOriginalFee * meses * (1 - values.discount / 100),
                    months: values.additionalsOnly ? 0 : meses,
                    // Adicionales
                    startDateBook: values.checkedBook && values.startDateBook ? startDate : null,
                    startDateChecks: values.checkedChecks && values.startDateChecks ? startDate : null,
                    startDateAR: values.checkedAR && values.startDateAR ? startDate : null,
                    startDateAP: values.checkedAP && values.startDateAP ? startDate : null,
                    startDateAccrual: values.checkedAccrual && values.startDateAccrual ? startDate : null,
                    startDateClass: values.checkedClass && values.startDateClass ? startDate : null,
                    startDateMulti: values.checkedMulti && values.startDateMulti ? startDate : null,
                    startDatePer: values.checkedPer && values.startDatePer ? startDate : null,
                    // Otros
                    startDateOthers: values.checkedOthers && values.startDateOthers ? startDate : null,
                    isError: false
                });
            }
        }
        setIsErrorFocus(false)
    };

    const handleChangeEndDate = (date) => {
        let untilDate = moment(date).endOf("month").startOf("day");

        if (!date || !values.sinceDate) {
            setValues({
                ...values,
                untilDate: date ? untilDate : date,
            });
        } else {
            if (untilDate.isBefore(moment(values.sinceDate).format("YYYY-MM-DD"))) {
                setValues({
                    ...values,
                    untilDate: null,
                    isError: false
                });

                setTexto(t("reports.endDateMess"));
                setAutoAlertType("warning");
                setShow(true);
            } else {
                let meses = Math.ceil(untilDate.diff(values.sinceDate, "months", true));

                let bookMonths = 0;
                if (values.additionalsOnly) {
                    if (values.fixedHours) {
                        bookMonths = 1;
                    } else {
                        bookMonths = Math.ceil(untilDate.diff(moment(values.startDateBook), "months", true));
                    }
                }

                setValues({
                    ...values,
                    untilDate: untilDate,
                    projectTotal: values.additionalsOnly
                        ? values.tothBook * values.hourRate * bookMonths * (1 - values.discount / 100)
                        : values.catchUpOriginalFee * meses * (1 - values.discount / 100),
                    months: values.additionalsOnly ? 0 : meses,
                    // Adicionales
                    finishDateChecks: values.checkedChecks && values.finishDateChecks ? untilDate : null,
                    finishDateAR: values.checkedAR && values.finishDateAR ? untilDate : null,
                    finishDateAP: values.checkedAP && values.finishDateAP ? untilDate : null,
                    finishDateAccrual: values.checkedAccrual && values.finishDateAccrual ? untilDate : null,
                    finishDateClass: values.checkedClass && values.finishDateClass ? untilDate : null,
                    finishDateMulti: values.checkedMulti && values.finishDateMulti ? untilDate : null,
                    finishDatePer: values.checkedPer && values.finishDatePer ? untilDate : null,
                    // Otros
                    finishDateOthers: values.checkedOthers && values.finishDateOthers ? untilDate : null,
                    isError: false
                });
            }
        }
        setIsErrorFocus(false)
    };

    const handleChangeDiscount = (event) => {
        let discountAux = event.target.value >= 0 && event.target.value <= 100 ? event.target.value : values.discount;

        setValues({
            ...values,
            discount: discountAux,
            catchUpAdditionalsDiscount: discountAux,
            projectTotal: values.additionalsOnly
                ? (values.fixedHours ? values.tothBook * values.hourRate * (1 - discountAux / 100) : values.tothBook * values.hourRate * values.months * (1 - discountAux / 100))
                : values.catchUpOriginalFee * values.months * (1 - discountAux / 100),
            isError: false
        });
        setIsErrorFocus(false)
    };

    const handleClickShowDiscount = () => {
        setEditMode(!editMode);
    };

    const handleClickShowDiscount2 = () => {
        setEditMode2(!editMode2);
    };

    const handleMouseDownDiscount = (event) => {
        event.preventDefault();
    };

    return (
        <StyledContainer>
            <LocalizationProvider
                dateAdapter={AdapterMoment}
                adapterLocale={t("language.locale")}
                localeText={{ clearButtonLabel: t("miscellaneous.clear") }}
            >
                <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={12}>
                        <Typography
                            variant="h1"
                            color="primary"
                            sx={{
                                display: "inline-block",
                                fontWeight: 700,
                                pr: 6,
                            }}
                        >
                            Catch-Up
                        </Typography>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={values.additionalsOnly}
                                    className={classes.switch}
                                    color="secondary"
                                    disabled={!values.sinceDate || !values.untilDate}
                                    inputProps={{ "aria-label": "controlled" }}
                                    name="switchHourlyCatchUp"
                                    onChange={handleChangeSwitchHourly}
                                />
                            }
                            label={
                                <Typography variant="body2" align="center" sx={{ lineHeight: 0 }}>
                                    {t("proposals.hourly")}
                                </Typography>
                            }
                        />
                        {values.additionalsOnly && <FormControlLabel
                            control={
                                <Switch
                                    checked={values.fixedHours}
                                    className={classes.switch}
                                    color="secondary"
                                    disabled={!values.additionalsOnly}
                                    inputProps={{ "aria-label": "controlled" }}
                                    name="fixedHoursCatchUp"
                                    onChange={handleChangeSwitchFixedHours}
                                />
                            }
                            label={
                                <Typography variant="body2" align="center" sx={{ lineHeight: 0 }}>
                                    {t("proposals.fixedHours")}
                                </Typography>
                            }
                        />}
                    </Grid>
                    <Grid item xs={12}>
                        <Accordion
                            TransitionProps={{ unmountOnExit: true }}
                            sx={{
                                borderBottom: "3px solid #ff5968",
                            }}
                        >
                            <AccordionSummary aria-controls="panel-content" id="panel-header">
                                <Typography
                                    variant="h2"
                                    align="center"
                                    sx={{
                                        fontWeight: 700,
                                    }}
                                >
                                    {t("invoicing.description")}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <List>
                                    <ListItem disableGutters alignItems="center">
                                        <ListItemIcon sx={{ minWidth: 35 }}>
                                            <CheckIcon fontSize="small" sx={{ color: "#031851" }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={
                                                <Typography variant="body1">
                                                    <span style={{ fontWeight: 600 }}>{t("proposals.dediBook")} </span>-{" "}
                                                    {t("proposals.aPubBook")}
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                    <ListItem disableGutters alignItems="center">
                                        <ListItemIcon sx={{ minWidth: 35 }}>
                                            <CheckIcon fontSize="small" sx={{ color: "#031851" }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={
                                                <Typography variant="body1">
                                                    <span style={{ fontWeight: 600 }}>{t("proposals.bankRec")} </span>-{" "}
                                                    {t("proposals.bankRecDes")}
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                    <ListItem disableGutters alignItems="center">
                                        <ListItemIcon sx={{ minWidth: 35 }}>
                                            <CheckIcon fontSize="small" sx={{ color: "#031851" }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={
                                                <Typography variant="body1">
                                                    <span style={{ fontWeight: 600 }}>{t("proposals.modCashAcc")} </span>-{" "}
                                                    {t("proposals.tranReCash")}
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                    <ListItem disableGutters alignItems="center">
                                        <ListItemIcon sx={{ minWidth: 35 }}>
                                            <CheckIcon fontSize="small" sx={{ color: "#031851" }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={
                                                <Typography variant="body1">
                                                    <span style={{ fontWeight: 600 }}>
                                                        {t("proposals.finannState")}{" "}
                                                    </span>
                                                    - {t("proposals.balanceGen2")}
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                    <ListItem disableGutters alignItems="center">
                                        <ListItemIcon sx={{ minWidth: 35 }}>
                                            <CheckIcon fontSize="small" sx={{ color: "#031851" }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={
                                                <Typography variant="body1">
                                                    <span style={{ fontWeight: 600 }}>
                                                        {t("proposals.writtenSup")}{" "}
                                                    </span>
                                                    - {t("proposals.UnlimSup")}
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                </List>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2} sx={{ mt: 0.5 }}>
                    <Grid item xs={12} sm={6} lg={3}>
                        <DatePicker
                            componentsProps={{
                                actionBar: { actions: ["clear"] },
                            }}
                            value={values.sinceDate}
                            onChange={(newValue) => handleChangeInitDate(newValue)}
                            inputFormat={todos.dateFormat}
                            renderInput={(params) => (
                                <TextField fullWidth required {...params} error={isError && !values.sinceDate} onClick={(e) => { setIsErrorFocus(false) }} />
                            )}
                            // inputProps={{ readOnly: true }}
                            inputProps={{
                                onKeyDown: (event) => {
                                    event.preventDefault();
                                },
                            }}
                            label={t("miscellaneous.sinceDate")}
                            views={["year", "month"]}
                            onClick={(e) => { setIsErrorFocus(false) }}
                            inputRef={(input) => { (input && isErrorFocus && isError && !values.sinceDate) && input.focus() }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                        <DatePicker
                            componentsProps={{
                                actionBar: { actions: ["clear"] },
                            }}
                            value={values.untilDate}
                            onChange={(newValue) => handleChangeEndDate(newValue)}
                            inputFormat={todos.dateFormat}
                            renderInput={(params) => (
                                <TextField fullWidth required {...params} error={isError && !values.untilDate} onClick={(e) => { setIsErrorFocus(false) }} />
                            )}
                            // inputProps={{ readOnly: true }}
                            inputProps={{
                                onKeyDown: (event) => {
                                    event.preventDefault();
                                },
                            }}
                            label={t("miscellaneous.toDate")}
                            views={["year", "month"]}
                            onClick={(e) => { setIsErrorFocus(false) }}
                            inputRef={(input) => { (input && isErrorFocus && isError && !values.untilDate) && input.focus() }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                        <FormControl variant="outlined" disabled={editMode} fullWidth>
                            <InputLabel htmlFor="outlined-adornment-discount">{t("miscellaneous.discount")}</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-discount"
                                type="number"
                                name="discount"
                                value={values.discount}
                                onChange={handleChangeDiscount}
                                onBlur={() =>
                                    setValues({
                                        ...values,
                                        discount: parseFloat(
                                            values.discount === "" ? 0 : values.discount
                                        ).toFixed(2),
                                        catchUpAdditionalsDiscount: parseFloat(
                                            values.discount === "" ? 0 : values.discount
                                        ).toFixed(2),
                                    })
                                }
                                inputProps={{
                                    min: 0,
                                    max: 100,
                                }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        %
                                        <LightTooltip
                                            title={
                                                editMode
                                                    ? t("services.catchupMaximunDiscount")
                                                    : t("miscellaneous.save")
                                            }
                                            aria-label="edit"
                                        >
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowDiscount}
                                                onMouseDown={handleMouseDownDiscount}
                                                edge="end"
                                            >
                                                {!editMode ? <EditOffIcon /> : <EditIcon />}
                                            </IconButton>
                                        </LightTooltip>
                                    </InputAdornment>
                                }
                                label={t("miscellaneous.discount")}
                                sx={{
                                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                        display: "none",
                                    },
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                        <FormControl variant="outlined" disabled={editMode2} fullWidth>
                            <InputLabel htmlFor="outlined-adornment-additionalsDiscount">
                                {t("proposals.additionalsDiscount")}
                            </InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-additionalsDiscount"
                                type="number"
                                name="additionalsDiscount"
                                value={values.catchUpAdditionalsDiscount}
                                onChange={handleChangeDiscount}
                                onBlur={() =>
                                    setValues({
                                        ...values,
                                        catchUpAdditionalsDiscount: parseFloat(
                                            values.catchUpAdditionalsDiscount === ""
                                                ? 0
                                                : values.catchUpAdditionalsDiscount === ""
                                        ).toFixed(2),
                                        discount: parseFloat(
                                            values.catchUpAdditionalsDiscount === ""
                                                ? 0
                                                : values.catchUpAdditionalsDiscount === ""
                                        ).toFixed(2),
                                    })
                                }
                                inputProps={{
                                    min: 0,
                                    max: 100,
                                }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        %
                                        <LightTooltip
                                            title={
                                                editMode2
                                                    ? t("services.catchupMaximunDiscount")
                                                    : t("miscellaneous.save")
                                            }
                                            aria-label="edit"
                                        >
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowDiscount2}
                                                onMouseDown={handleMouseDownDiscount}
                                                edge="end"
                                            >
                                                {!editMode2 ? <EditOffIcon /> : <EditIcon />}
                                            </IconButton>
                                        </LightTooltip>
                                    </InputAdornment>
                                }
                                label={t("proposals.additionalsDiscount")}
                                sx={{
                                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                        display: "none",
                                    },
                                }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <UpdateAdd
                    addHourFee={tariffAdditionals}
                    values={values}
                    set={setValues}
                    setTexto={setTexto}
                    setalertType={setAutoAlertType}
                    setShow={setShow}
                    sumTotal={sumTotal}
                    isError={isError}
                    isErrorFocus={isErrorFocus}
                    setIsErrorFocus={setIsErrorFocus}
                />
            </LocalizationProvider>
        </StyledContainer>
    );
};
