/** Imports ---------------------------------------------------------------- */

/** React imports */
import React from "react";
import { useTranslation } from "react-i18next";

/** MUI imports */
import { styled } from "@mui/material/styles";
import { Box, Card, CardContent, Skeleton, Typography, Stack, Breadcrumbs, Chip } from "@mui/material";
import GridUI from "@mui/material/Grid";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

import {
    Grid,
    Table,
    TableHeaderRow,
    PagingPanel,
    Toolbar,
    SearchPanel,
    TableBandHeader,
} from "@devexpress/dx-react-grid-material-ui";
import { SearchState, IntegratedFiltering, PagingState, IntegratedPaging } from "@devexpress/dx-react-grid";

import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import InfoIcon from "@mui/icons-material/Info";

const PREFIX = "BankingSkeletons";

const classes = {
    cardContentRoot: `${PREFIX}-cardContentRoot`,
    spacing: `${PREFIX}-spacing`,
    filterSwitches: `${PREFIX}-filterSwitches`,
    tableStriped: `${PREFIX}-tableStriped`,
    tableHeader: `${PREFIX}-tableHeader`,
    tableBody: `${PREFIX}-tableBody`,
    pager: `${PREFIX}-pager`,
    toolbarSearch: `${PREFIX}-toolbarSearch`,
    searchInput: `${PREFIX}-searchInput`,
    tableHeaderBand: `${PREFIX}-tableHeaderBand`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.cardContentRoot}`]: {
        "&:last-child": {
            paddingBottom: "18px",
        },
        padding: 30,
    },

    [`& .${classes.spacing}`]: {
        padding: "20px 0 25px 0",
    },

    [`& .${classes.filterSwitches}`]: {
        paddingTop: 12,
        paddingBottom: 15,
        [theme.breakpoints.down("sm")]: {
            paddingBottom: 30,
        },
    },

    [`& .${classes.tableStriped}`]: {
        "& tbody tr:nth-of-type(odd)": {
            backgroundColor: "rgba(47,49,144,0.1)",
        },
    },
    [`& .${classes.tableHeader}`]: {
        border: "none",
        backgroundColor: "rgba(47,49,144,0.3)",
        fontSize: "15px",
        fontWeight: 600,
        color: "#031851",
        "&:first-of-type": {
            borderRadius: "20px 0 0 0",
        },
        "&:last-child": {
            borderRadius: "0 20px 0 0",
            paddingRight: "24px",
        },
    },

    [`& .${classes.tableBody}`]: {
        border: "none",
        fontSize: "14px",
        fontWeight: 400,
        color: "#031851",
    },

    [`& .${classes.pager}`]: {
        "& .Pagination-button": {
            paddingTop: 0,
            paddingBottom: 0,
            color: "#031851",
            fontWeight: 600,
        },
        "& .Pagination-activeButton": {
            backgroundColor: "rgba(47,49,144,0.3)",
        },
    },

    [`& .${classes.toolbarSearch}`]: {
        border: "none !important",
        padding: "0 0 5px !important",
    },

    [`& .${classes.searchInput}`]: {
        color: "#031851",
        "&:before": {
            borderBottomColor: "#031851",
        },
        "&:hover:before": {
            borderBottomColor: "#031851 !important",
        },
    },

    [`& .${classes.tableHeaderBand}`]: {
        backgroundColor: "transparent",
        color: "#4a22d4",
        fontSize: "14px",
        fontWeight: 600,
        border: "none !important",
        textAlign: "center",
        [theme.breakpoints.up("md")]: {
            whiteSpace: "pre-wrap",
        },
    },
}));

const rowsSkeleton = [];

for (let i = 0; i < 10; i++) {
    rowsSkeleton.push({
        company_name: <Skeleton variant="text" animation="wave" />,
        account: <Skeleton variant="text" animation="wave" />,
        last_transaction_date: <Skeleton variant="text" animation="wave" />,
        validated_month: <Skeleton variant="text" animation="wave" />,
        verifiedAmount: <Skeleton variant="text" animation="wave" />,
        last_reconciled_date: <Skeleton variant="text" animation="wave" />,
        amount: <Skeleton variant="text" animation="wave" />,
    });
}

const tableColumnExtensions = [
    { columnName: "company_name", width: "18%", align: "left" },
    { columnName: "account", width: "16%", align: "left" },
    { columnName: "last_transaction_date", align: "center" },
    { columnName: "validated_month", align: "center" },
    { columnName: "verifiedAmount", align: "right" },
    { columnName: "last_reconciled_date", align: "center" },
    { columnName: "amount", align: "right" },
];

const tableHeaderCell = ({ ...props }) => <TableHeaderRow.Cell {...props} className={classes.tableHeader} />;

const TableComponent = ({ ...props }) => <Table.Table {...props} className={classes.tableStriped} />;

const TableCellComponent = ({ ...props }) => <Table.Cell {...props} className={classes.tableBody} />;

const PagingPanelContainer = ({ ...props }) => <PagingPanel.Container {...props} className={classes.pager} />;

const inputComponent = ({ ...props }) => <SearchPanel.Input {...props} className={classes.searchInput} />;

const toolRootComponent = ({ ...props }) => <Toolbar.Root {...props} className={classes.toolbarSearch} />;

const tableBandCellBase = ({ ...props }) => (
    <TableBandHeader.Cell {...props} className={classes.tableHeaderBand}>
        {props.column.title === " " ? (
            ""
        ) : (
            <Chip label={props.column.title} sx={{ width: "100%", backgroundColor: "#2f3190", color: "white" }} />
        )}
    </TableBandHeader.Cell>
);

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "rgba(19, 31, 62, 0.80)",
        fontSize: 12,
        maxWidth: 320,
        borderRadius: 6,
        fontWeight: "normal",
    },
});

const BankingSkeletons = () => {
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    const columnsTemplate = [
        { name: "company_name", title: t("miscellaneous.smb") },
        { name: "account", title: t("miscellaneous.bank2") },
        { name: "last_transaction_date", title: t("bankingDashboard.lastMovement") },
        { name: "validated_month", title: t("bankingDashboard.validatedMonth") },
        { name: "verifiedAmount", title: t("bankingDashboard.verifiedBalance") },
        { name: "last_reconciled_date", title: t("bankingDashboard.reconciledMonth") },
        { name: "amount", title: t("bankingDashboard.reconcileAmount") },
    ];

    const columnBandsTemplate = [
        {
            title: " ",
            children: [{ columnName: "company_name" }],
        },
        {
            title: " ",
            children: [{ columnName: "account" }],
        },
        {
            title: t("bankingDashboard.transactions"),
            children: [{ columnName: "last_transaction_date" }],
        },
        {
            title: t("bankingDashboard.xeroBanksValidation"),
            children: [{ columnName: "validated_month" }, { columnName: "verifiedAmount" }],
        },
        {
            title: t("bankingDashboard.bankReconciliation"),
            children: [{ columnName: "last_reconciled_date" }, { columnName: "amount" }],
        },
    ];

    const pagingMessages = {
        info: ({ from, to, count }) => `${from}${from < to ? `-${to}` : ""} ${t("miscellaneous.pagingOf")} ${count}`,
    };

    const searchMessages = {
        searchPlaceholder: t("miscellaneous.search"),
    };

    const tableContentComponent = ({ column, children, ...props }) => (
        <TableHeaderRow.Content {...props}>
            {children}
            {column.name === "last_transaction_date" ? (
                <LightTooltip title={t("bankingDashboard.dateLastRecorded")} aria-label="lastTransaction">
                    <InfoIcon sx={{ ml: 1 }} />
                </LightTooltip>
            ) : column.name === "last_reconciled_date" ? (
                <LightTooltip title={t("bankingDashboard.monthWithoutItems")} aria-label="lastReconciled">
                    <InfoIcon sx={{ ml: 1 }} />
                </LightTooltip>
            ) : column.name === "amount" ? (
                <LightTooltip title={t("bankingDashboard.outstandingBalance")} aria-label="amount">
                    <InfoIcon sx={{ ml: 1 }} />
                </LightTooltip>
            ) : column.name === "validated_month" ? (
                <LightTooltip title={t("bankingDashboard.monthWithSuccessfully")} aria-label="validatedMonth">
                    <InfoIcon sx={{ ml: 1 }} />
                </LightTooltip>
            ) : column.name === "verifiedAmount" ? (
                <LightTooltip title={t("bankingDashboard.verifiedBalanceAccount")} aria-label="verifiedAmount">
                    <InfoIcon sx={{ ml: 1 }} />
                </LightTooltip>
            ) : null}
        </TableHeaderRow.Content>
    );

    const breadcrumbs = [
        <Typography
            key="1"
            variant="h1"
            color="primary"
            sx={{
                fontSize: 24,
                fontWeight: 700,
            }}
        >
            {t("principal.dashboard")}
        </Typography>,
        <Typography
            key="2"
            variant="h1"
            color="secondary"
            sx={{
                fontSize: 24,
                fontWeight: 700,
            }}
        >
            {t("miscellaneous.banks")}
        </Typography>,
    ];

    return (
        <StyledContainer component="main" sx={{ flexGrow: 1, p: 4, pt: 10, width: "100%" }}>
            <Stack sx={{ pb: 4 }}>
                <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small" sx={{ color: "#031851" }} />}
                    aria-label="breadcrumb"
                >
                    {breadcrumbs}
                </Breadcrumbs>
            </Stack>
            <Card
                elevation={0}
                sx={{
                    borderRadius: 8,
                    boxShadow: "10px 10px 15px rgba(3,24,81,0.15)",
                }}
            >
                <CardContent classes={{ root: classes.cardContentRoot }}>
                    <GridUI container spacing={2} className={classes.spacing} alignItems="center">
                        <GridUI item xs={12} md={2}>
                            <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width="100%"
                                height={40}
                                sx={{ borderRadius: 8 }}
                            />
                        </GridUI>
                        <GridUI item xs={12} md={2}>
                            <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width="100%"
                                height={40}
                                sx={{ borderRadius: 8 }}
                            />
                        </GridUI>
                        <GridUI item xs={12} md={2}>
                            <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width="100%"
                                height={40}
                                sx={{ borderRadius: 8 }}
                            />
                        </GridUI>
                        <GridUI item xs={12} md={2}>
                            <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width="100%"
                                height={40}
                                sx={{ borderRadius: 8 }}
                            />
                        </GridUI>
                        <GridUI item xs={12} md={2}>
                            <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width="100%"
                                height={40}
                                sx={{ borderRadius: 8 }}
                            />
                        </GridUI>
                        <GridUI item xs={12} md={2}>
                            <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width="100%"
                                height={40}
                                sx={{ borderRadius: 8 }}
                            />
                        </GridUI>
                    </GridUI>
                    <GridUI container spacing={2} alignItems="center" className={classes.filterSwitches}>
                        <GridUI item xs={12} sm={6} md={2}>
                            <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width="100%"
                                height={20}
                                sx={{ borderRadius: 8 }}
                            />
                        </GridUI>
                        <GridUI item xs={12} sm={6} md={1}>
                            <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width="100%"
                                height={20}
                                sx={{ borderRadius: 8 }}
                            />
                        </GridUI>
                        <GridUI item xs={12} sm={6} md={3}>
                            <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width="100%"
                                height={20}
                                sx={{ borderRadius: 8 }}
                            />
                        </GridUI>
                        <GridUI item xs={12} sm={6} md={2}>
                            <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width="100%"
                                height={20}
                                sx={{ borderRadius: 8 }}
                            />
                        </GridUI>
                        <GridUI item xs={12} sm={6} md={2}>
                            <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width="100%"
                                height={20}
                                sx={{ borderRadius: 8 }}
                            />
                        </GridUI>
                        <GridUI item xs={12} sm={6} md={2}>
                            <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width="100%"
                                height={20}
                                sx={{ borderRadius: 8 }}
                            />
                        </GridUI>
                    </GridUI>
                    <GridUI container>
                        <GridUI item xs={12} md={1} sx={{ mb: { sm: -5 }, zIndex: 1 }}>
                            <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width="100%"
                                height={20}
                                sx={{ borderRadius: 8 }}
                            />
                        </GridUI>
                        <GridUI item xs={12}>
                            <Grid rows={rowsSkeleton} columns={columnsTemplate}>
                                <SearchState />
                                <IntegratedFiltering />
                                <PagingState defaultCurrentPage={0} pageSize={10} />
                                <IntegratedPaging />
                                <Table
                                    tableComponent={TableComponent}
                                    columnExtensions={tableColumnExtensions}
                                    cellComponent={TableCellComponent}
                                />
                                <TableHeaderRow
                                    cellComponent={tableHeaderCell}
                                    contentComponent={tableContentComponent}
                                />
                                <TableBandHeader cellComponent={tableBandCellBase} columnBands={columnBandsTemplate} />
                                <Toolbar rootComponent={toolRootComponent} />
                                <SearchPanel messages={searchMessages} inputComponent={inputComponent} />
                                <PagingPanel containerComponent={PagingPanelContainer} messages={pagingMessages} />
                            </Grid>
                        </GridUI>
                    </GridUI>
                </CardContent>
            </Card>
        </StyledContainer>
    );
};

export default BankingSkeletons;
