/** React imports */
import React from "react";
import { Link } from "react-router-dom";

/** Images */
import { ReactComponent as GcaLogo } from "../../../../assets/images/gca-logo-mobile.svg";

/** MUI icons */

/** MUI imports */
import { Box, Typography, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";

/** Global variables */
const PREFIX = "Team";

const classes = {
    footer: `${PREFIX}-footer`,
    logoGCAF: `${PREFIX}-logoGCAF`,
    logoGCAF2: `${PREFIX}-logoGCAF2`,
    logoGCAF3: `${PREFIX}-logoGCAF3`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.logoGCAF}`]: {
        display: "block",
        padding: "5px 0",
        width: 75,
        marginRight: 45,
        [theme.breakpoints.down("md")]: {
            marginRight: 5,
        },
    },
    
    [`& .${classes.logoGCAF2}`]: {
        fontWeight: 700,
        fontSize: 14,
        paddingLeft: 50,
        [theme.breakpoints.down("md")]: {
            paddingLeft: 5,
        }
    },
    [`& .${classes.logoGCAF3}`]: {
        color: "white",
        fontSize: 14,
    },

    [`& .${classes.footer}`]: {
        backgroundColor: "#031851",
        padding: 6,
    },
}));

export const Footer = () => {
    return (
        <StyledContainer>
            <Box className={classes.footer}>
                <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item>
                        <Link to="/" style={{ textDecoration: "none" }}>
                            <Typography variant="body2" className={classes.logoGCAF2} color="secondary">
                                www.GCA.digital
                            </Typography>
                        </Link>
                    </Grid>
                    <Grid item>
                        <Typography variant="body2" className={classes.logoGCAF3}>
                            GCA 360 INC
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Link to="/" style={{ textDecoration: "none" }}>
                            <GcaLogo className={classes.logoGCAF} />
                        </Link>
                    </Grid>
                </Grid>
            </Box>
        </StyledContainer>
    );
};
