// React
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";

// MUI
import {
    Box,
    TextField,
    Grid,
    FormControl,
    FormControlLabel,
    Checkbox,
    InputLabel,
    InputAdornment,
    Select,
    MenuItem,
    Typography,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Divider,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

/** MUI icons */
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CheckIcon from "@mui/icons-material/Check";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const PREFIX = "Xero";

const classes = {
    btnRoot: `${PREFIX}-btnRoot`,
    buttonContainer: `${PREFIX}-buttonContainer`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    backgroundColor: "#F8F9F8",
    marginTop: 16,
    padding: 30,
    borderRadius: 30,

    [`& .${classes.btnRoot}`]: {
        border: "1px solid #ff5968",
        borderRadius: 40,
        "&:hover": {
            backgroundColor: "#2f3190",
            border: "1px solid #2f3190",
        },
    },
    [`& .${classes.buttonContainer}`]: {
        display: "flex",
        justifyContent: "flex-end",
        padding: 15,
    },
}));

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "rgba(19, 31, 62, 0.80)",
        fontSize: 12,
        maxWidth: 320,
        borderRadius: 6,
        fontWeight: "normal",
    },
});

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)({
    backgroundColor: "#F8F9F8",
    boxShadow: "none",
    "&:before": {
        display: "none",
    },
});

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: "#031851" }} />} {...props} />
))({
    "& .MuiAccordionSummary-content": {
        margin: "12px 0",
    },
});

const AccordionDetails = styled(MuiAccordionDetails)({
    padding: "0px 0px 20px 0px",
    "& .MuiCard-root": {
        boxShadow: "none",
    },
});

export const Xero = (props) => {
    // Properties
    const {
        xero,
        setXero,
        isError,
        setTexto,
        setShow,
        setAutoAlertType,
        isErrorFocus,
        setIsErrorFocus,
        includeAccModuleSub,
        handleChangeCheckAccSub,
        includeAdmModuleSub,
        handleChangeCheckAdmSub,
        accountantOnboarding,
    } = props;

    /** Internationalization i18n */
    const [t] = useTranslation("global");

    /** React redux */
    const todos = useSelector((state) => state.todos);

    /** Component states */
    const checks = xero.checkedExp || xero.checkedPro || xero.checkedPlus;
    const totalUsers = parseFloat(xero.tothExp) + parseFloat(xero.tothPro) + (xero.checkedPlus ? 1 : 0);
    const sumTotal =
        parseFloat(xero.tothExp) * 4 +
        (parseFloat(xero.tothPro) !== 0 ? parseFloat(xero.tothPro - 1) * 5 + 7 : 0) +
        (xero.checkedPlus ? 7 : 0);

    /** Component functions */
    useEffect(() => {
        setXero({
            ...xero,
            xeroChecks: checks,
            totalUsers: totalUsers,
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [totalUsers, checks]);

    const handleChangeDiscount = (event) => {
        let discountAux = 0;

        discountAux = event.target.value >= 0 && event.target.value <= 100 ? event.target.value : xero.discount;

        setXero({
            ...xero,
            discount: discountAux,
            additionalsTotal: sumTotal * (1 - discountAux / 100),
            projectTotal: xero.originalFee * (1 - discountAux / 100),
            totalFee: (xero.originalFee + sumTotal) * (1 - discountAux / 100),
        });
        setIsErrorFocus(false)
    };

    const handleChangeVersion = (event) => {
        let newFee = 0;
        switch (event.target.value) {
            case "Ledger":
                newFee = 5;
                break;
            case "Cashbook":
                newFee = 13;
                break;
            case "Growing US":
                newFee = 47;
                break;
            case "Established US":
                newFee = 80;
                break;
            case "Standard GB":
                newFee = 46;
                break;
            case "Early":
                newFee = 20;
                break;
            default:
                newFee = 69;
                break;
        }
        setXero({
            ...xero,
            version: event.target.value,
            originalFee: newFee,
            addFee: 0,
            projectTotal: newFee * (1 - (xero.discount ?? 0) / 100),
            totalFee: newFee * (1 - (xero.discount ?? 0) / 100),
            additionalsTotal: 0,
            checkedExp: false,
            tothExp: 0,
            startDateExp: null,
            finishDateExp: null,
            checkedPro: false,
            tothPro: 0,
            startDatePro: null,
            finishDatePro: null,
            checkedPlus: false,
            startDatePlus: null,
            finishDatePlus: null,
            isError: false
        });
        setIsErrorFocus(false)
    };

    const handleChangeCheckAdd = (event, value) => {
        switch (value) {
            case 0:
                if (xero.tothExp === 0) {
                    let expValue = 4 * (1 - (xero.additionalsDiscount ?? 0) / 100);
                    setXero({
                        ...xero,
                        checkedExp: event.target.checked,
                        tothExp: 1,
                        startDateExp: event.target.checked ? xero.sinceDateProposal : null,
                        finishDateExp: null,
                        addFee: xero.addFee + 4,
                        additionalsTotal: xero.additionalsTotal + expValue,
                        totalFee: xero.totalFee + expValue,
                    });
                } else {
                    let expValue = xero.tothExp * 4 * (1 - (xero.additionalsDiscount ?? 0) / 100);
                    setXero({
                        ...xero,
                        checkedExp: event.target.checked,
                        tothExp: 0,
                        startDateExp: event.target.checked ? xero.sinceDateProposal : null,
                        finishDateExp: null,
                        addFee: xero.addFee - xero.tothExp * 4,
                        additionalsTotal: xero.additionalsTotal - expValue,
                        totalFee: xero.totalFee - expValue,
                    });
                }
                break;
            case 1:
                if (xero.tothPro === 0) {
                    let proValue = 7 * (1 - (xero.additionalsDiscount ?? 0) / 100);
                    setXero({
                        ...xero,
                        checkedPro: event.target.checked,
                        tothPro: 1,
                        startDatePro: event.target.checked ? xero.sinceDateProposal : null,
                        finishDatePro: null,
                        addFee: xero.addFee + 7,
                        additionalsTotal: xero.additionalsTotal + proValue,
                        totalFee: xero.totalFee + proValue,
                    });
                } else {
                    let newUsers = 0;

                    if (xero.tothPro >= 1) {
                        newUsers = (xero.tothPro - 1) * 5 + 7;
                    }
                    let proValue = newUsers * (1 - (xero.additionalsDiscount ?? 0) / 100);

                    setXero({
                        ...xero,
                        checkedPro: event.target.checked,
                        tothPro: 0,
                        startDatePro: event.target.checked ? xero.sinceDateProposal : null,
                        finishDatePro: null,
                        addFee: xero.addFee - newUsers,
                        additionalsTotal: xero.additionalsTotal - proValue,
                        totalFee: xero.totalFee - proValue,
                    });
                }

                break;
            default:
                let plusValue = 7 * (1 - (xero.additionalsDiscount ?? 0) / 100);

                setXero({
                    ...xero,
                    checkedPlus: event.target.checked,
                    startDatePlus: event.target.checked ? xero.sinceDateProposal : null,
                    finishDatePlus: null,
                    addFee: event.target.checked ? xero.addFee + 7 : xero.addFee - 7,
                    totalFee: event.target.checked ? xero.totalFee + plusValue : xero.totalFee - plusValue,
                    additionalsTotal: event.target.checked
                        ? xero.additionalsTotal + plusValue
                        : xero.additionalsTotal - plusValue,
                });
                break;
        }
        setIsErrorFocus(false)
    };

    const handleChange = (event) => {
        let newUsers = 0;

        if (xero.tothPro >= 1) {
            newUsers = (xero.tothPro - 1) * 5 + 7;
        }

        let newFee = xero.tothExp * 4 + newUsers + (xero.checkedPlus ? 7 : 0);

        if (event.target.name === "tothExp") {
            newFee = newFee - xero.tothExp * 4;
            newFee += event.target.value * 4;
        } else if (event.target.name === "tothPro") {
            newFee = newFee - newUsers;
            newFee += parseInt(event.target.value) !== 0 ? (event.target.value - 1) * 5 + 7 : 0;
        } else {
            newFee = newFee - xero.checkedPlus * 7;
            newFee += event.target.value * 7;
        }

        setXero({
            ...xero,
            [event.target.name]: event.target.value,
            additionalsTotal: newFee * (1 - xero.discount / 100),
            addFee: newFee,
            totalFee: xero.originalFee * (1 - xero.discount / 100) + newFee * (1 - xero.discount / 100),
            isError: false
        });
        setIsErrorFocus(false)
    };

    const handleChangeStartDateAdd = (date, val) => {
        // Si es la misma fecha de la Desde, se debe tomar en cuenta ese dia y no al empezar el mes
        let startDate = moment(date).startOf("month");
        let auxSinceDate = xero.sinceDateProposal.clone()
        if (auxSinceDate && auxSinceDate.startOf("month").isSame(startDate)) {
            startDate = xero.sinceDateProposal.clone()
        }

        switch (val) {
            case 0:
                if (!date || !xero.finishDateExp) {
                    setXero({
                        ...xero,
                        startDateExp: startDate ? startDate : date,
                    });
                } else {
                    if (startDate.isAfter(moment(xero.finishDateExp))) {
                        setTexto(t("reports.initDateMess"));
                        setAutoAlertType("warning");
                        setShow(true);
                    } else {
                        setXero({
                            ...xero,
                            startDateExp: startDate,
                        });
                    }
                }
                break;
            case 1:
                if (!date || !xero.finishDatePro) {
                    setXero({
                        ...xero,
                        startDatePro: startDate ? startDate : date,
                    });
                } else {
                    if (startDate.isAfter(moment(xero.finishDatePro))) {
                        setTexto(t("reports.initDateMess"));
                        setAutoAlertType("warning");
                        setShow(true);
                    } else {
                        setXero({
                            ...xero,
                            startDatePro: startDate,
                        });
                    }
                }
                break;
            default:
                if (!date || !xero.finishDatePlus) {
                    setXero({
                        ...xero,
                        startDatePlus: startDate ? startDate : date,
                    });
                } else {
                    if (startDate.isAfter(moment(xero.finishDatePlus))) {
                        setTexto(t("reports.initDateMess"));
                        setAutoAlertType("warning");
                        setShow(true);
                    } else {
                        setXero({
                            ...xero,
                            startDatePlus: startDate,
                        });
                    }
                }
                break;
        }
        setIsErrorFocus(false)
    };

    const handleChangeEndDateAdd = (date, val) => {
        let endDate = moment(date).endOf("month").startOf("day");
        switch (val) {
            case 0:
                if (!date || !xero.startDateExp) {
                    setXero({
                        ...xero,
                        finishDateExp: date ? moment(date).endOf("month") : date,
                    });
                } else {
                    if (endDate.isBefore(moment(xero.startDateExp))) {
                        setTexto(t("reports.endDateMess"));
                        setAutoAlertType("warning");
                        setShow(true);
                    } else {
                        setXero({
                            ...xero,
                            finishDateExp: moment(date).endOf("month"),
                        });
                    }
                }
                break;
            case 1:
                if (!date || !xero.startDatePro) {
                    setXero({
                        ...xero,
                        finishDatePro: date ? moment(date).endOf("month") : date,
                    });
                } else {
                    if (endDate.isBefore(moment(xero.startDatePro))) {
                        setTexto(t("reports.endDateMess"));
                        setAutoAlertType("warning");
                        setShow(true);
                    } else {
                        setXero({
                            ...xero,
                            finishDatePro: moment(date).endOf("month"),
                        });
                    }
                }
                break;
            default:
                if (!date || !xero.startDatePlus) {
                    setXero({
                        ...xero,
                        finishDatePlus: date ? moment(date).endOf("month") : date,
                    });
                } else {
                    if (endDate.isBefore(moment(xero.startDatePlus))) {
                        setTexto(t("reports.endDateMess"));
                        setAutoAlertType("warning");
                        setShow(true);
                    } else {
                        setXero({
                            ...xero,
                            finishDatePlus: moment(date).endOf("month"),
                        });
                    }
                }
                break;
        }
        setIsErrorFocus(false)
    };

    const handleChangeSinceDate = (date) => {
        setXero({
            ...xero,
            sinceDateProposal: date,
            startDateExp: xero.checkedExp ? date : xero.startDateExp,
            startDatePro: xero.checkedPro ? date : xero.startDatePro,
            startDatePlus: xero.checkedPlus ? date : xero.startDatePlus,
            isError: false,
        });
        setIsErrorFocus(false);
    };

    return (
        <StyledContainer>
            <LocalizationProvider
                dateAdapter={AdapterMoment}
                adapterLocale={t("language.locale")}
                localeText={{ clearButtonLabel: t("miscellaneous.clear") }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <Grid item xs={6}>
                            <Typography
                                variant="h1"
                                color="primary"
                                sx={{
                                    display: "inline-block",
                                    fontWeight: 700,
                                    pr: 6,
                                }}
                            >
                                Xero subscription
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <LightTooltip title={accountantOnboarding ? t("proposals.includeConfigInAcc") : t("proposals.includeConfigAcc")}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={includeAccModuleSub || accountantOnboarding}
                                            onChange={handleChangeCheckAccSub}
                                            disabled={accountantOnboarding || !includeAdmModuleSub}
                                        />
                                    }
                                    label={t("proposals.accModule")}
                                />
                            </ LightTooltip>
                        </Grid>
                        <Grid item xs={6}>
                            <LightTooltip title={t("proposals.includeConfigAdm")}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={includeAdmModuleSub}
                                            onChange={handleChangeCheckAdmSub}
                                            disabled={!includeAccModuleSub && (!accountantOnboarding && !includeAccModuleSub)}
                                        />
                                    }
                                    label={t("proposals.admModule")}
                                />
                            </ LightTooltip>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Accordion
                            TransitionProps={{ unmountOnExit: true }}
                            sx={{
                                borderBottom: "3px solid #ff5968",
                            }}
                        >
                            <AccordionSummary aria-controls="panel-content" id="panel-header">
                                <Typography
                                    variant="h2"
                                    align="center"
                                    sx={{
                                        fontWeight: 700,
                                    }}
                                >
                                    {t("invoicing.description")}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <List>
                                    <ListItem disableGutters alignItems="center">
                                        <ListItemIcon sx={{ minWidth: 35 }}>
                                            <CheckIcon fontSize="small" sx={{ color: "#031851" }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={
                                                <Typography variant="body1">
                                                    <span style={{ fontWeight: 600 }}>{t("proposals.unlimitedReceiveSpend")} </span>-{" "}
                                                    {t("proposals.unlimitedReceiveSpendDet")}
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                    <ListItem disableGutters alignItems="center">
                                        <ListItemIcon sx={{ minWidth: 35 }}>
                                            <CheckIcon fontSize="small" sx={{ color: "#031851" }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={
                                                <Typography variant="body1">
                                                    <span style={{ fontWeight: 600 }}>{t("proposals.bankReconciliation")} </span>-{" "}
                                                    {t("proposals.bankReconciliationDet")}
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                    {(xero.version === "Cashbook" || xero.version === "Growing US" || xero.version === "Established US" || xero.version === "Standard GB" || xero.version === "Premium GB") && (
                                        <ListItem disableGutters alignItems="center">
                                            <ListItemIcon sx={{ minWidth: 35 }}>
                                                <CheckIcon fontSize="small" sx={{ color: "#031851" }} />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={
                                                    <Typography variant="body1">
                                                        <span style={{ fontWeight: 600 }}>{t("proposals.dailyBankFeeds")} </span>-{" "}
                                                        {t("proposals.dailyBankFeedsDet")}
                                                    </Typography>
                                                }
                                            />
                                        </ListItem>
                                    )}
                                    {(xero.version === "Growing US" || xero.version === "Established US" || xero.version === "Standard GB" || xero.version === "Premium GB") && (
                                        <ListItem disableGutters alignItems="center">
                                            <ListItemIcon sx={{ minWidth: 35 }}>
                                                <CheckIcon fontSize="small" sx={{ color: "#031851" }} />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={
                                                    <Typography variant="body1">
                                                        <span style={{ fontWeight: 600 }}>{t("proposals.unlimitedInvoicing")} </span>-{" "}
                                                        {t("proposals.unlimitedInvoicingDet")}
                                                    </Typography>
                                                }
                                            />
                                        </ListItem>
                                    )}
                                    {(xero.version === "Growing US" || xero.version === "Established US" || xero.version === "Standard GB" || xero.version === "Premium GB") && (
                                        <ListItem disableGutters alignItems="center">
                                            <ListItemIcon sx={{ minWidth: 35 }}>
                                                <CheckIcon fontSize="small" sx={{ color: "#031851" }} />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={
                                                    <Typography variant="body1">
                                                        <span style={{ fontWeight: 600 }}>{t("proposals.unlimitedBilling")} </span>-{" "}
                                                        {t("proposals.unlimitedBillingDet")}
                                                    </Typography>
                                                }
                                            />
                                        </ListItem>
                                    )}
                                    {(xero.version === "Established US" || xero.version === "Premium GB") && (
                                        <ListItem disableGutters alignItems="center">
                                            <ListItemIcon sx={{ minWidth: 35 }}>
                                                <CheckIcon fontSize="small" sx={{ color: "#031851" }} />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={
                                                    <Typography variant="body1">
                                                        <span style={{ fontWeight: 600 }}>{t("proposals.multiCurrency")} </span>-{" "}
                                                        {t("proposals.multiCurrencyDet")}
                                                    </Typography>
                                                }
                                            />
                                        </ListItem>
                                    )}
                                    <ListItem disableGutters alignItems="center">
                                        <ListItemIcon sx={{ minWidth: 35 }}>
                                            <CheckIcon fontSize="small" sx={{ color: "#031851" }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={
                                                <Typography variant="body1">
                                                    <span style={{ fontWeight: 600 }}>{t("proposals.fixedAssetsSub")} </span>-{" "}
                                                    {t("proposals.fixedAssetsSubDet")}
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                    <ListItem disableGutters alignItems="center">
                                        <ListItemIcon sx={{ minWidth: 35 }}>
                                            <CheckIcon fontSize="small" sx={{ color: "#031851" }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={
                                                <Typography variant="body1">
                                                    <span style={{ fontWeight: 600 }}>{t("proposals.manualJournals")} </span>-{" "}
                                                    {t("proposals.manualJournalsDet")}
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                    <ListItem disableGutters alignItems="center">
                                        <ListItemIcon sx={{ minWidth: 35 }}>
                                            <CheckIcon fontSize="small" sx={{ color: "#031851" }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={
                                                <Typography variant="body1">
                                                    <span style={{ fontWeight: 600 }}>{t("proposals.usersAccessProfiles")} </span>-{" "}
                                                    {(xero.version === "Growing US" || xero.version === "Established US" || xero.version === "Standard GB" || xero.version === "Premium GB") ?
                                                        t("proposals.usersAccessProfiles2Det") :
                                                        t("proposals.usersAccessProfilesDet")}
                                                    {(xero.version === "Ledger" || xero.version === "Cashbook") ?
                                                        " " + t("proposals.usersAccessProfiles3DetBasic") :
                                                        (xero.version === "Growing US" || xero.version === "Established US" || xero.version === "Standard GB" || xero.version === "Premium GB") ? " " + t("proposals.usersAccessUnlimited") : ""}
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                </List>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                        <FormControl fullWidth required error={isError && xero.version === ""}>
                            <InputLabel id="ver-simple-select-label">{t("services.version")}</InputLabel>
                            <Select
                                name="version"
                                labelId="ver-simple-select-label"
                                IconComponent={KeyboardArrowDownIcon}
                                value={xero.version}
                                label={t("services.version")}
                                onChange={handleChangeVersion}
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            mt: 0.7,
                                            borderRadius: 2,
                                            boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                        },
                                    },
                                }}
                                onClick={(e) => { setIsErrorFocus(false) }}
                                inputRef={(input) => { (input && isErrorFocus && isError && xero.version === "") && input.focus() }}
                            >
                                <MenuItem value={"Ledger"}>Ledger</MenuItem>
                                <MenuItem value={"Cashbook"}>Cashbook</MenuItem>
                                <MenuItem value={"Growing US"}>Growing - US</MenuItem>
                                <MenuItem value={"Established US"}>Established - US</MenuItem>
                                <MenuItem value={"Standard GB"}>Standard - Global</MenuItem>
                                <MenuItem value={"Premium GB"}>Premium - Global</MenuItem>
                                <MenuItem value={"Early"}>Early</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                        <TextField
                            fullWidth
                            label={t("miscellaneous.discount")}
                            value={xero.discount}
                            name="discount"
                            onChange={handleChangeDiscount}
                            onBlur={() =>
                                setXero({
                                    ...xero,
                                    discount: parseFloat(xero.discount === "" ? 0 : xero.discount).toFixed(2),
                                })
                            }
                            variant="outlined"
                            type="number"
                            InputProps={{
                                inputProps: {
                                    min: 0,
                                    max: 100,
                                },
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                            sx={{
                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                    display: "none",
                                },
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                        <LocalizationProvider
                            dateAdapter={AdapterMoment}
                            adapterLocale={t("language.locale")}
                            localeText={{ clearButtonLabel: t("miscellaneous.clear") }}
                        >
                            <DatePicker
                                componentsProps={{
                                    actionBar: { actions: ["clear"] },
                                }}
                                value={xero.sinceDateProposal}
                                onChange={(newValue) => handleChangeSinceDate(newValue)}
                                inputFormat={todos.dateFormat}
                                renderInput={(params) => (
                                    <TextField fullWidth required {...params} error={isError && !xero.sinceDateProposal} />
                                )}
                                onClick={(e) => {
                                    setIsErrorFocus(false);
                                }}
                                label={t("reports.initDate")}
                                views={["year", "month", "day"]}
                                inputRef={(input) => {
                                    input && isErrorFocus && isError && !xero.sinceDateProposal && input.focus();
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            variant="h1"
                            color="primary"
                            sx={{
                                display: "inline-block",
                                fontWeight: 700,
                                pr: 6,
                            }}
                        >
                            {t("services.additionals")}
                        </Typography>
                        <Divider />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <LightTooltip title={t("services.reclaimTool")}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="checkedExp"
                                        checked={xero.checkedExp}
                                        onChange={(value) => handleChangeCheckAdd(value, 0)}
                                        inputProps={{
                                            "aria-label": "controlled",
                                        }}
                                        disabled={xero.version !== "Standard GB" && xero.version !== "Premium GB"}
                                    />
                                }
                                label="Expenses claims"
                            />
                        </LightTooltip>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            fullWidth
                            disabled={!xero.checkedExp}
                            label={t("proposals.users")}
                            name="tothExp"
                            onChange={handleChange}
                            value={xero.tothExp}
                            variant="outlined"
                            type="number"
                            required={xero.checkedExp}
                            inputProps={{
                                min: 1,
                                step: 1,
                                onKeyDown: (event) => {
                                    event.preventDefault();
                                },
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={xero.checkedExp && isError && xero.tothExp === 0}
                            onClick={(e) => { setIsErrorFocus(false) }}
                            inputRef={(input) => { (input && isErrorFocus && xero.checkedExp && isError && xero.tothExp === 0) && input.focus() }}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <DatePicker
                            componentsProps={{
                                actionBar: { actions: ["clear"] },
                            }}
                            disabled={!xero.checkedExp}
                            value={xero.startDateExp}
                            onChange={(newValue) => handleChangeStartDateAdd(newValue, 0)}
                            minDate={moment().startOf("month")}
                            inputFormat={todos.dateFormat}
                            renderInput={(params) => (
                                <TextField
                                    fullWidth
                                    required={xero.checkedExp}
                                    {...params}
                                    error={xero.checkedExp && isError && !xero.startDateExp}
                                    onClick={(e) => { setIsErrorFocus(false) }}
                                />
                            )}
                            // inputProps={{ readOnly: true }}
                            inputProps={{
                                onKeyDown: (event) => {
                                    event.preventDefault();
                                },
                            }}
                            label={t("reports.initDate")}
                            views={["year", "month"]}
                            onClick={(e) => { setIsErrorFocus(false) }}
                            inputRef={(input) => { (input && isErrorFocus && xero.checkedExp && isError && !xero.startDateExp) && input.focus() }}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <DatePicker
                            componentsProps={{
                                actionBar: { actions: ["clear"] },
                            }}
                            disabled={!xero.checkedExp}
                            value={xero.finishDateExp}
                            onChange={(newValue) => handleChangeEndDateAdd(newValue, 0)}
                            minDate={moment().startOf("month")}
                            inputFormat={todos.dateFormat}
                            renderInput={(params) => <TextField fullWidth {...params} />}
                            inputProps={{ readOnly: true }}
                            label={t("reports.endDate")}
                            views={["year", "month"]}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <LightTooltip title={t("services.workTool")}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="checkedPro"
                                        checked={xero.checkedPro}
                                        onChange={(value) => handleChangeCheckAdd(value, 1)}
                                        inputProps={{
                                            "aria-label": "controlled",
                                        }}
                                        disabled={xero.version !== "Standard GB" && xero.version !== "Premium GB"}
                                    />
                                }
                                label="Projects"
                            />
                        </LightTooltip>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            fullWidth
                            disabled={!xero.checkedPro}
                            label={t("proposals.users")}
                            name="tothPro"
                            onChange={handleChange}
                            value={xero.tothPro}
                            variant="outlined"
                            type="number"
                            required={xero.checkedPro}
                            inputProps={{
                                min: 1,
                                step: 1,
                                onKeyDown: (event) => {
                                    event.preventDefault();
                                },
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={xero.checkedPro && isError && xero.tothPro === 0}
                            onClick={(e) => { setIsErrorFocus(false) }}
                            inputRef={(input) => { (input && isErrorFocus && xero.checkedPro && isError && xero.tothPro === 0) && input.focus() }}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <DatePicker
                            componentsProps={{
                                actionBar: { actions: ["clear"] },
                            }}
                            disabled={!xero.checkedPro}
                            value={xero.startDatePro}
                            onChange={(newValue) => handleChangeStartDateAdd(newValue, 1)}
                            minDate={moment().startOf("month")}
                            inputFormat={todos.dateFormat}
                            renderInput={(params) => (
                                <TextField
                                    fullWidth
                                    required={xero.checkedPro}
                                    {...params}
                                    error={xero.checkedPro && isError && !xero.startDatePro}
                                    onClick={(e) => { setIsErrorFocus(false) }}
                                />
                            )}
                            // inputProps={{ readOnly: true }}
                            label={t("reports.initDate")}
                            views={["year", "month"]}
                            inputProps={{
                                onKeyDown: (event) => {
                                    event.preventDefault();
                                },
                            }}
                            onClick={(e) => { setIsErrorFocus(false) }}
                            inputRef={(input) => { (input && isErrorFocus && xero.checkedPro && isError && !xero.startDatePro) && input.focus() }}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <DatePicker
                            componentsProps={{
                                actionBar: { actions: ["clear"] },
                            }}
                            disabled={!xero.checkedPro}
                            value={xero.finishDatePro}
                            onChange={(newValue) => handleChangeEndDateAdd(newValue, 1)}
                            minDate={moment().startOf("month")}
                            inputFormat={todos.dateFormat}
                            renderInput={(params) => <TextField fullWidth {...params} />}
                            inputProps={{ readOnly: true }}
                            label={t("reports.endDate")}
                            views={["year", "month"]}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <LightTooltip title={t("services.customTool")}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="checkedPlus"
                                        checked={xero.checkedPlus}
                                        onChange={(value) => handleChangeCheckAdd(value, 2)}
                                        inputProps={{
                                            "aria-label": "controlled",
                                        }}
                                        disabled={xero.version !== "Standard GB" && xero.version !== "Premium GB"}
                                    />
                                }
                                label="Analytics Plus"
                            />
                        </LightTooltip>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            fullWidth
                            disabled
                            label={t("proposals.users")}
                            name="tothPlus"
                            value="N/A"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <DatePicker
                            componentsProps={{
                                actionBar: { actions: ["clear"] },
                            }}
                            disabled={!xero.checkedPlus}
                            value={xero.startDatePlus}
                            onChange={(newValue) => handleChangeStartDateAdd(newValue, 2)}
                            minDate={moment().startOf("month")}
                            inputFormat={todos.dateFormat}
                            renderInput={(params) => (
                                <TextField
                                    fullWidth
                                    required={xero.checkedPlus}
                                    {...params}
                                    error={xero.checkedPlus && isError && !xero.startDatePlus}
                                    onClick={(e) => { setIsErrorFocus(false) }}
                                />
                            )}
                            // inputProps={{ readOnly: true }}
                            label={t("reports.initDate")}
                            views={["year", "month"]}
                            inputProps={{
                                onKeyDown: (event) => {
                                    event.preventDefault();
                                },
                            }}
                            onClick={(e) => { setIsErrorFocus(false) }}
                            inputRef={(input) => { (input && isErrorFocus && xero.checkedPlus && isError && !xero.startDatePlus) && input.focus() }}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <DatePicker
                            componentsProps={{
                                actionBar: { actions: ["clear"] },
                            }}
                            disabled={!xero.checkedPlus}
                            value={xero.finishDatePlus}
                            onChange={(newValue) => handleChangeEndDateAdd(newValue, 2)}
                            minDate={moment().startOf("month")}
                            inputFormat={todos.dateFormat}
                            renderInput={(params) => <TextField fullWidth {...params} />}
                            inputProps={{ readOnly: true }}
                            label={t("reports.endDate")}
                            views={["year", "month"]}
                        />
                    </Grid>
                </Grid>
            </LocalizationProvider>
        </StyledContainer >
    );
};
