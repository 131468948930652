/** React imports */
import React from "react";
import { useTranslation } from "react-i18next";

/** Images */

/** MUI icons */

/** MUI imports */
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { DescriptionIconContainer } from "../AccountantProposal/DescriptionIconContainer";

/** Global variables */
const PREFIX = "ScheduleCont";

const classes = {
    body2: `${PREFIX}-body2`,
    scheduleTitle: `${PREFIX}-scheduleTitle`,
    scheduleBox: `${PREFIX}-scheduleBox`,
    fieldset: `${PREFIX}-fieldset`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.scheduleTitle}`]: {
        fontSize: 22,
    },

    [`& .${classes.body2}`]: {
        backgroundColor: "white",
        marginTop: 15,
        [theme.breakpoints.down("md")]: {
            padding: 30,
        },
        [theme.breakpoints.up("md")]: {
            padding: 60,
        },
        minHeight: 850,
    },

    [`& .${classes.scheduleBox}`]: {
        backgroundColor: "#F8F9F8",
        marginTop: 16,
        padding: 20,
        borderRadius: 30,
    },

    [`& .${classes.fieldset}`]: {
        border: "2px solid #031851",
        borderStyle: "solid solid none solid",
        lineHeight: 0,
    },
}));

const CustomBox = styled(Box)(({ theme }) => ({
    borderRadius: "24px",
    color: "#fff",
    height: 30,
}));

const CustomBoxBar = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 6,
    height: 12,
    width: "100%",
}));

const CustomTableCell = styled(TableCell)(({ theme }) => ({
    borderBottom: 0,
    paddingRight: 0,
    [`& h2`]: {
        fontSize: 12,
    },
    [`& span`]: {
        fontSize: 12,
    },
}));

export const ScheduleCont = (props) => {
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    const { contInfo } = props;

    /** Global variables */
    const weeksOnboarding = [
        { color: "#595aa6", label: t("proposals.week1") + " *" },
        { color: "#44469b", label: t("proposals.week2") },
        { color: "#2f3190", label: t("proposals.week3") },
    ];

    const weeksRecurring = [
        { color: "#9798c8", label: t("proposals.week1") },
        { color: "#8283bc", label: t("proposals.week2") },
        { color: "#6d6fb1", label: t("proposals.week3") },
        { color: "#595aa6", label: t("proposals.week4") },
        { color: "#44469b", label: t("proposals.week1") },
        { color: "#2f3190", label: t("proposals.week2") },
    ];

    const monthsRecurring = [
        { color: "#6d6fb1", label: t("monthNamesShort.apr") },
        { color: "#595aa6", label: t("monthNamesShort.jul") },
        { color: "#44469b", label: t("monthNamesShort.oct") },
        { color: "#2f3190", label: t("monthNamesShort.jan") },
    ];

    const weeksConsulting = [
        { color: "#6d6fb1", label: t("proposals.week1") },
        { color: "#595aa6", label: t("proposals.week2") },
        { color: "#44469b", label: t("proposals.week3") },
        { color: "#2f3190", label: t("proposals.week4") },
    ];

    return (
        <StyledContainer>
            {contInfo.onboarding.isActive && (
                <Box className={classes.scheduleBox}>
                    <TableContainer sx={{ overflowX: "auto" }}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow sx={{ "& th": { border: 0 } }}>
                                    <TableCell />
                                    <TableCell colSpan={7} sx={{ pt: 0, pb: 0, pr: 0 }}>
                                        <fieldset className={classes.fieldset}>
                                            <legend align="center" style={{ paddingLeft: 8, paddingRight: 8 }}>
                                                {t("proposals.project")}
                                            </legend>
                                        </fieldset>
                                    </TableCell>
                                </TableRow>
                                <TableRow sx={{ "& th": { border: 0, pt: 1 } }}>
                                    <TableCell>
                                        <Typography variant="h2" sx={{ fontWeight: 700, fontSize: 12 }}>
                                            {t("proposals.onboard")}
                                        </Typography>
                                    </TableCell>
                                    {weeksOnboarding.map((week, index) => (
                                        <TableCell align="left">
                                            <CustomBox
                                                sx={{
                                                    backgroundColor: week.color,
                                                    width: "220%",
                                                    ...(index === 2 && {
                                                        pr: "14px",
                                                        pl: "14px",
                                                        width:
                                                            sessionStorage.getItem("lng") === "en"
                                                                ? "113%"
                                                                : "115% !important",
                                                    }),
                                                    display: "flex",
                                                    justifyContent: "space-evenly",
                                                }}
                                            >
                                                <Typography
                                                    variant="span"
                                                    sx={{ display: "flex", alignItems: "center", fontSize: 12 }}
                                                >
                                                    {week.label}
                                                </Typography>
                                                {index !== 2 && (
                                                    <Typography
                                                        variant="span"
                                                        sx={{ display: "flex", alignItems: "center", fontSize: 12 }}
                                                    >
                                                        &nbsp;
                                                    </Typography>
                                                )}
                                            </CustomBox>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <CustomTableCell component="th" scope="row">
                                        <Typography variant="h2">{t("proposals.intel")}</Typography>
                                    </CustomTableCell>
                                    <CustomTableCell align="right">
                                        <CustomBoxBar />
                                    </CustomTableCell>
                                </TableRow>
                                <TableRow>
                                    <CustomTableCell component="th" scope="row">
                                        <Typography variant="h2">{t("proposals.designCont")}</Typography>
                                    </CustomTableCell>
                                    <CustomTableCell />
                                    <CustomTableCell align="right">
                                        <CustomBoxBar />
                                    </CustomTableCell>
                                </TableRow>
                                <TableRow>
                                    <CustomTableCell component="th" scope="row">
                                        <Typography variant="h2">{t("proposals.settingCont")}</Typography>
                                    </CustomTableCell>
                                    <CustomTableCell colSpan={2} />
                                    <CustomTableCell align="right">
                                        <CustomBoxBar />
                                    </CustomTableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Typography variant="body1" sx={{ pt: 2, pl: 2, fontSize: 13 }}>
                        * {t("proposals.weekOneBegins")}
                    </Typography>
                </Box>
            )}
            {contInfo.recurring.isActive && (
                <Box className={classes.scheduleBox}>
                    <TableContainer sx={{ overflowX: "auto" }}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow sx={{ "& th": { border: 0 } }}>
                                    <TableCell />
                                    <TableCell colSpan={4} sx={{ pt: 0, pb: 0, pr: 0 }}>
                                        <fieldset className={classes.fieldset}>
                                            <legend align="center" style={{ paddingLeft: 8, paddingRight: 8 }}>
                                                {t("proposals.eachMonth")}
                                            </legend>
                                        </fieldset>
                                    </TableCell>
                                    <TableCell colSpan={6} sx={{ pt: 0, pb: 0, pr: 0 }}>
                                        <fieldset className={classes.fieldset}>
                                            <legend align="center" style={{ paddingLeft: 8, paddingRight: 8 }}>
                                                {t("proposals.nextMonth")}
                                            </legend>
                                        </fieldset>
                                    </TableCell>
                                </TableRow>
                                <TableRow sx={{ "& th": { border: 0, pt: 1 } }}>
                                    <TableCell>
                                        <Typography variant="h2" sx={{ fontWeight: 700, fontSize: 12 }}>
                                            {t("proposals.recurring2")}
                                        </Typography>
                                    </TableCell>
                                    {weeksRecurring.map((week, index) => (
                                        <TableCell align="left">
                                            <CustomBox
                                                sx={{
                                                    backgroundColor: week.color,
                                                    width: sessionStorage.getItem("lng") === "en" ? "240%" : "260%",
                                                    ...(index === 5 && {
                                                        pr: sessionStorage.getItem("lng") === "en" ? "17px" : "21px",
                                                        pl: sessionStorage.getItem("lng") === "en" ? "17px" : "21px",
                                                        width:
                                                            sessionStorage.getItem("lng") === "en"
                                                                ? "117%"
                                                                : "120% !important",
                                                    }),
                                                    display: "flex",
                                                    justifyContent: "space-evenly",
                                                }}
                                            >
                                                <Typography
                                                    variant="span"
                                                    sx={{ display: "flex", alignItems: "center", fontSize: 12 }}
                                                    noWrap
                                                >
                                                    {week.label}
                                                </Typography>
                                                {index !== 5 && (
                                                    <Typography
                                                        variant="span"
                                                        sx={{ display: "flex", alignItems: "center", fontSize: 12 }}
                                                    >
                                                        &nbsp;
                                                    </Typography>
                                                )}
                                            </CustomBox>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <CustomTableCell component="th" scope="row">
                                        <Typography variant="h2">{t("proposals.interContFin")}</Typography>
                                    </CustomTableCell>
                                    <CustomTableCell colSpan={3} />
                                    <CustomTableCell align="right" colSpan={2}>
                                        <CustomBoxBar />
                                    </CustomTableCell>
                                </TableRow>
                                <DescriptionIconContainer key={"proposals.personalizedReporting"} top={23} />
                                <TableRow>
                                    <CustomTableCell component="th" scope="row">
                                        <Typography variant="h2">
                                            {" " + t("proposals.personalizedReporting")}
                                        </Typography>
                                    </CustomTableCell>
                                    <CustomTableCell colSpan={5} />
                                    <CustomTableCell align="right">
                                        <CustomBoxBar />
                                    </CustomTableCell>
                                </TableRow>
                                <TableRow>
                                    <CustomTableCell component="th" scope="row">
                                        <Typography variant="h2">{t("proposals.virtualMeet")}</Typography>
                                    </CustomTableCell>
                                    <CustomTableCell colSpan={5} />
                                    <CustomTableCell align="right">
                                        <CustomBoxBar />
                                    </CustomTableCell>
                                </TableRow>
                                <DescriptionIconContainer key={"proposals.consoFinanCont"} top={71.7}/>
                                <TableRow>
                                    <CustomTableCell component="th" scope="row">
                                        <Typography variant="h2">
                                            {" " + t("proposals.consoFinanCont")}
                                        </Typography>
                                    </CustomTableCell>
                                    <CustomTableCell align="right" colSpan={5} sx={{ pl: 0 }}>
                                        <Table aria-label="simple table">
                                            <TableHead>
                                                <TableRow sx={{ "& th": { border: 0 } }}>
                                                    <TableCell colSpan={3} sx={{ pt: 0, pb: 0, pr: 0 }}>
                                                        <fieldset className={classes.fieldset}>
                                                            <legend
                                                                align="center"
                                                                style={{
                                                                    paddingLeft: 8,
                                                                    paddingRight: 8,
                                                                }}
                                                            >
                                                                {t("proposals.fiscalYear")}
                                                            </legend>
                                                        </fieldset>
                                                    </TableCell>
                                                    <TableCell colSpan={2} sx={{ pt: 0, pb: 0, pr: 0 }}>
                                                        <fieldset className={classes.fieldset}>
                                                            <legend
                                                                align="center"
                                                                style={{
                                                                    paddingLeft: 8,
                                                                    paddingRight: 8,
                                                                    whiteSpace: "nowrap",
                                                                }}
                                                            >
                                                                {t("proposals.nextYear")}
                                                            </legend>
                                                        </fieldset>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow sx={{ "& th": { border: 0, pt: 1 } }}>
                                                    {monthsRecurring.map((month, index) => (
                                                        <TableCell align="left">
                                                            <CustomBox
                                                                sx={{
                                                                    backgroundColor: month.color,
                                                                    width: "280%",
                                                                    mr: 2.2,
                                                                    pr: 1.4,
                                                                    ...(index === 3 && {
                                                                        pr:
                                                                            sessionStorage.getItem("lng") === "en"
                                                                                ? "17px"
                                                                                : "0px !important",
                                                                        pl:
                                                                            sessionStorage.getItem("lng") === "en"
                                                                                ? "17px"
                                                                                : "0px !important",
                                                                        width:
                                                                            sessionStorage.getItem("lng") === "en"
                                                                                ? "119% !important"
                                                                                : "115% !important",
                                                                    }),
                                                                    display: "flex",
                                                                    justifyContent: "space-evenly",
                                                                }}
                                                            >
                                                                <Typography
                                                                    variant="span"
                                                                    sx={{
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        fontSize: 12,
                                                                    }}
                                                                    noWrap
                                                                >
                                                                    {month.label}
                                                                </Typography>
                                                                {index !== 3 && (
                                                                    <Typography
                                                                        variant="span"
                                                                        sx={{
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            fontSize: 12,
                                                                        }}
                                                                    >
                                                                        &nbsp;
                                                                    </Typography>
                                                                )}
                                                            </CustomBox>
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody sx={{ "&: before": { display: "none" } }}>
                                                <TableRow>
                                                    <CustomTableCell
                                                        component="th"
                                                        scope="row"
                                                        align="right"
                                                        colSpan={4}
                                                    >
                                                        <CustomBoxBar />
                                                    </CustomTableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </CustomTableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            )}
            {contInfo.consulting.isActive && (
                <Box className={classes.scheduleBox}>
                    <TableContainer sx={{ overflowX: "auto" }}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow sx={{ "& th": { border: 0 } }}>
                                    <TableCell />
                                    <TableCell colSpan={7} sx={{ pt: 0, pb: 0, pr: 0 }}>
                                        <fieldset className={classes.fieldset}>
                                            <legend align="center" style={{ paddingLeft: 8, paddingRight: 8 }}>
                                                {t("proposals.project")}
                                            </legend>
                                        </fieldset>
                                    </TableCell>
                                </TableRow>
                                <TableRow sx={{ "& th": { border: 0, pt: 1 } }}>
                                    <TableCell>
                                        <Typography variant="h2" sx={{ fontWeight: 700, fontSize: 12 }}>
                                            {t("proposals.consulting") + "**"}
                                        </Typography>
                                        <Typography variant="body1" sx={{ fontSize: 12 }}>
                                            {t("proposals.auditFinan")}
                                        </Typography>
                                    </TableCell>
                                    {weeksConsulting.map((week, index) => (
                                        <TableCell align="left">
                                            <CustomBox
                                                sx={{
                                                    backgroundColor: week.color,
                                                    width: "225%",
                                                    ...(index === 3 && {
                                                        pr: "14px",
                                                        pl: "14px",
                                                        width: "115% !important",
                                                    }),
                                                    display: "flex",
                                                    justifyContent: "space-evenly",
                                                }}
                                            >
                                                <Typography
                                                    variant="span"
                                                    sx={{ display: "flex", alignItems: "center", fontSize: 12 }}
                                                    noWrap
                                                >
                                                    {week.label}
                                                </Typography>
                                                {index !== 3 && (
                                                    <Typography
                                                        variant="span"
                                                        sx={{ display: "flex", alignItems: "center", fontSize: 12 }}
                                                    >
                                                        &nbsp;
                                                    </Typography>
                                                )}
                                            </CustomBox>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <CustomTableCell component="th" scope="row">
                                        <Typography variant="h2">{t("proposals.diagFinanStates")}</Typography>
                                    </CustomTableCell>
                                    <CustomTableCell colSpan={3} align="right">
                                        <CustomBoxBar />
                                    </CustomTableCell>
                                </TableRow>
                                <TableRow>
                                    <CustomTableCell component="th" scope="row">
                                        <Typography variant="h2">{t("proposals.inventoryRev")}</Typography>
                                    </CustomTableCell>
                                    <CustomTableCell align="right">
                                        <CustomBoxBar />
                                    </CustomTableCell>
                                </TableRow>
                                <DescriptionIconContainer key={"proposals.auditInf"} top={23} />
                                <TableRow>
                                    <CustomTableCell component="th" scope="row">
                                        <Typography variant="h2">
                                            {" " + t("proposals.auditInf")}
                                        </Typography>
                                    </CustomTableCell>
                                    <CustomTableCell colSpan={3} />
                                    <CustomTableCell align="right">
                                        <CustomBoxBar />
                                    </CustomTableCell>
                                </TableRow>
                                <DescriptionIconContainer key={"proposals.reportIntCont"} top={23} />
                                <TableRow>
                                    <CustomTableCell component="th" scope="row">
                                        <Typography variant="h2">
                                            {" " + t("proposals.reportIntCont")}
                                        </Typography>
                                    </CustomTableCell>
                                    <CustomTableCell colSpan={3} />
                                    <CustomTableCell align="right">
                                        <CustomBoxBar />
                                    </CustomTableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Typography variant="body1" sx={{ pt: 2, pl: 2, fontSize: 13 }}>
                        ** {t("proposals.legendConsultCont")}
                    </Typography>
                </Box>
            )}
        </StyledContainer>
    );
};
