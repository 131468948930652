// React
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
// import moment from "moment";
import { NumericFormat } from "react-number-format";

// MUI icons
import EditIcon from "@mui/icons-material/Edit";
import EditOffIcon from "@mui/icons-material/EditOff";

// MUI
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers";

const PREFIX = "Recurring";

const classes = {
    btnRoot: `${PREFIX}-btnRoot`,
    buttonContainer: `${PREFIX}-buttonContainer`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.btnRoot}`]: {
        border: "1px solid #ff5968",
        borderRadius: 40,
        "&:hover": {
            backgroundColor: "#2f3190",
            border: "1px solid #2f3190",
        },
    },
    [`& .${classes.buttonContainer}`]: {
        display: "flex",
        justifyContent: "flex-end",
        padding: 15,
    },
}));

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "rgba(19, 31, 62, 0.80)",
        fontSize: 12,
        maxWidth: 320,
        borderRadius: 6,
        fontWeight: "normal",
    },
});

const NumberFormatCustom = (props) => {
    const todos = useSelector((state) => state.todos);

    const { inputRef, value, onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            value={value}
            getInputRef={inputRef}
            onValueChange={({ value: v }) => onChange({ target: { value: v } })}
            thousandSeparator={todos.amountFormat === "de-DE" ? "." : ","}
            decimalSeparator={todos.amountFormat === "de-DE" ? "," : "."}
            fixedDecimalScale={true}
            decimalScale={2}
            valueIsNumericString
        />
    );
};

const defaultState = {
    discount: 0,
    totalFee: 0,
};

export const Recurring = (props) => {
    // Properties
    const { companyID, setBackDrop } = props;

    /** Internationalization i18n */
    const [t] = useTranslation("global");

    /** React redux */
    // const todos = useSelector((state) => state.todos);

    /** Component states */
    const [valuesRecurring, setValuesRecurring] = useState(defaultState);
    const [editMode, setEditMode] = useState(true);
    // const [isError, setIsError] = useState(false);

    /** Component events */
    // useEffect(() => {
    //     setValuesRecurring({
    //         discount: data.discount ?? 0,
    //         totalFee: 0,
    //     });
    // }, [data])

    /** Component functions */
    const handleMouseDownDiscount = (event) => {
        event.preventDefault();
    };

    const handleClickShowDiscount = () => {
        setEditMode(!editMode);
    };

    const handleChangeDiscount = (event) => {
        setValuesRecurring({
            ...valuesRecurring,
            discount: event.target.value,
        });
    };

    const updateProject = async () => {
        setBackDrop(true);

        let params = {
            company_id: companyID,
            service_id: '61e9cf2b75932f2c88117877',
            // project_id: data.project_id,
            discount: valuesRecurring.discount,
        };

        console.log(params);

        // if () {
        //     await fetch(`/addServicesAndProjects`, {
        //         method: "POST",
        //         headers: {
        //             "Content-type": "application/json; charset=UTF-8",
        //             "Access-Control-Allow-Origin": "*",
        //         },
        //         body: JSON.stringify(params),
        //     })
        //         .then((response) => {
        //             if (response.ok) {
        //                 setTexto(t("miscellaneous.successfulUpdate"));
        //                 setalertType("success");
        //                 setShow(true);
        //                 // reloadServices();
        //             } else {
        //                 setTexto(t("miscellaneous.updatingError"));
        //                 setalertType("error");
        //                 setShow(true);
        //             }
        //         })
        //         .catch((error) => {
        //             console.log(error);
        //         });
        // } else {
        //     setTexto(t("miscellaneous.requiredData"));
        //     setalertType("warning");
        //     setShow(true);
        //     setIsError(true);
        // }
        
        setBackDrop(false);
    };

    return (
        <StyledContainer>
            <LocalizationProvider
                dateAdapter={AdapterMoment}
                adapterLocale={t("language.locale")}
                localeText={{ clearButtonLabel: t("miscellaneous.clear") }}
            >
                <Card elevation={0}>
                    <CardHeader title="Compilation" />
                    <Divider />
                    <CardContent>
                        <Grid container alignItems="center" spacing={2}>
                            <Grid item xs={12} sm={6} lg={3}>
                                <FormControl
                                    variant="outlined"
                                    disabled={editMode}
                                    fullWidth
                                >
                                    <InputLabel htmlFor="outlined-adornment-discount">
                                        {t("miscellaneous.discount")}
                                    </InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-discount"
                                        type="number"
                                        name="discount"
                                        value={valuesRecurring.discount}
                                        onChange={handleChangeDiscount}
                                        inputProps={{ 
                                            min: 0,
                                            max: 100,
                                            onKeyDown: (event) => {
                                                event.preventDefault();
                                            },
                                        }}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <LightTooltip
                                                    title={t(
                                                        "miscellaneous.edit"
                                                    )}
                                                    aria-label="edit"
                                                >
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={
                                                            handleClickShowDiscount
                                                        }
                                                        onMouseDown={
                                                            handleMouseDownDiscount
                                                        }
                                                        edge="end"
                                                    >
                                                        {!editMode ? (
                                                            <EditOffIcon />
                                                        ) : (
                                                            <EditIcon />
                                                        )}
                                                    </IconButton>
                                                </LightTooltip>
                                            </InputAdornment>
                                        }
                                        label={t("miscellaneous.discount")}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={3}>
                                <FormControl
                                    fullWidth
                                    variant="outlined"
                                    disabled
                                >
                                    <InputLabel htmlFor="outlined-adornment-totalFee">
                                        {t("invoicing.fee")}
                                    </InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-totalFee"
                                        value={valuesRecurring.totalFee}
                                        inputComponent={NumberFormatCustom}
                                        startAdornment={
                                            <InputAdornment
                                                disableTypography
                                                position="start"
                                            >
                                                $
                                            </InputAdornment>
                                        }
                                        label={t("invoicing.fee")}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                <Card elevation={0}>
                    <CardHeader
                        title={t('services.serviceResponsibility')}
                        sx={{ color: '#ff5968' }}
                    />
                    <Divider />
                    <CardContent>
                    </CardContent>
                    <Divider />
                    <Box className={classes.buttonContainer}>
                        <Button
                            color="secondary"
                            variant="contained"
                            disableElevation
                            classes={{ root: classes.btnRoot }}
                            onClick={updateProject}
                        >
                            {t("miscellaneous.save")}
                        </Button>
                    </Box>
                </Card>
            </LocalizationProvider>
        </StyledContainer>
    );
};
