// React
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { NumericFormat } from "react-number-format";

// MUI icons
import EditIcon from "@mui/icons-material/Edit";
import EditOffIcon from "@mui/icons-material/EditOff";
import {
    Alert,
    Box,
    Card,
    CardContent,
    Grid,
    CardHeader,
    Divider,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    TextField,
    Switch,
    Typography,
    FormControlLabel,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import GridUI from "@mui/material/Grid";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

/** Component imports */
import { UpdateAdd } from "./";
import DialogAlerts from "../../../DialogAlerts";

const PREFIX = "CleanUp";

const classes = {
    btnRoot: `${PREFIX}-btnRoot`,
    buttonContainer: `${PREFIX}-buttonContainer`,
    tableStriped: `${PREFIX}-tableStriped`,
    tableHeader: `${PREFIX}-tableHeader`,
    tableBody: `${PREFIX}-tableBody`,
    tableBody2: `${PREFIX}-tableBody2`,
    pager: `${PREFIX}-pager`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.btnRoot}`]: {
        border: "1px solid #ff5968",
        borderRadius: 40,
        "&:hover": {
            backgroundColor: "#2f3190",
            border: "1px solid #2f3190",
        },
        "&.MuiButton-root.Mui-disabled": {
            border: "1px solid #ffcdd2",
            backgroundColor: "#ffcdd2 !important",
            color: "#fff",
        },
    },
    [`& .${classes.buttonContainer}`]: {
        display: "flex",
        justifyContent: "flex-end",
        padding: 15,
    },

    [`& .${classes.tableStriped}`]: {
        "& tbody tr:nth-of-type(odd)": {
            backgroundColor: "rgba(47,49,144,0.1)",
        },
    },
    [`& .${classes.tableHeader}`]: {
        fontSize: "15px",
        fontWeight: 600,
        color: "#031851",
        borderBottom: "2px solid #031851",
    },
    [`& .${classes.tableBody}`]: {
        fontSize: "14px",
        fontWeight: 400,
        color: "#031851",
        padding: "5px",
    },
    [`& .${classes.tableBody2}`]: {
        padding: "5px",
        textAlign: "right",
    },
    [`& .${classes.pager}`]: {
        "& .Pagination-button": {
            paddingTop: 0,
            paddingBottom: 0,
            color: "#031851",
            fontWeight: 600,
        },
        "& .Pagination-activeButton": {
            backgroundColor: "rgba(47,49,144,0.3)",
        },
    },
}));

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "rgba(19, 31, 62, 0.80)",
        fontSize: 12,
        maxWidth: 400,
        borderRadius: 6,
        fontWeight: "normal",
    },
});

const NumberFormatCustom = (props) => {
    const todos = useSelector((state) => state.todos);

    const { inputRef, value, onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            value={value}
            getInputRef={inputRef}
            onValueChange={({ value: v }) => onChange({ target: { value: v } })}
            thousandSeparator={todos.amountFormat === "de-DE" ? "." : ","}
            decimalSeparator={todos.amountFormat === "de-DE" ? "," : "."}
            fixedDecimalScale={true}
            decimalScale={2}
            valueIsNumericString
        />
    );
};

// const EditButton = ({ onExecute }) => {
//     /** Internationalization i18n */
//     const [t] = useTranslation("global");
//     return (
//         <LightTooltip title={t("miscellaneous.edit")} aria-label="edit">
//             <IconButton onClick={onExecute} size="large">
//                 <EditIcon />
//             </IconButton>
//         </LightTooltip>
//     );
// };
// const CommitButton = ({ onExecute }) => {
//     /** Internationalization i18n */
//     const [t] = useTranslation("global");
//     return (
//         <LightTooltip title={t("miscellaneous.save")} aria-label="save">
//             <IconButton onClick={onExecute} size="large">
//                 <SaveIcon />
//             </IconButton>
//         </LightTooltip>
//     );
// };

// const CancelButton = ({ onExecute }) => {
//     /** Internationalization i18n */
//     const [t] = useTranslation("global");
//     return (
//         <LightTooltip title={t("miscellaneous.cancel")} aria-label="cancel">
//             <IconButton color="secondary" onClick={onExecute} size="large">
//                 <CancelIcon />
//             </IconButton>
//         </LightTooltip>
//     );
// };

// const tableHeaderCell = ({ ...props }) => (
//     <TableHeaderRow.Cell {...props} className={classes.tableHeader} />
// );

// const TableCellComponent = ({ ...props }) => (
//     <Table.Cell {...props} className={classes.tableBody} />
// );

// const TableCellComponent2 = ({ ...props }) => (
//     <Table.Cell {...props} className={classes.tableBody2} />
// );

// const PagingPanelContainer = ({ ...props }) => (
//     <PagingPanel.Container {...props} className={classes.pager} />
// );

// const commandComponents = {
//     edit: EditButton,
//     commit: CommitButton,
//     cancel: CancelButton,
// };

// const Command = ({ id, onExecute }) => {
//     const CommandButton = commandComponents[id];
//     return <CommandButton onExecute={onExecute} />;
// };

// const tableColumnExtensions = [
//     { columnName: "creationDate", align: "center", editingEnabled: false, width: "10%" },
//     { columnName: "initDate", align: "center" },
//     { columnName: "endDate", align: "center" },
//     { columnName: "estimDeliveryDate", align: "center" },
//     { columnName: "deliveryDate", align: "center" },
//     { columnName: "discount", align: "center", width: "5%" },
//     { columnName: "additionalsHours", align: "center", width: "5%" },
//     { columnName: "fee", align: "right", editingEnabled: false, width: "10%"  },
// ];

const defaultState = {
    projectCompanyId: undefined,
    updateBoolean: false,
    baseFee: 0,
    months: 0,
    projectFee: 0,
    discount: 20,
    discountOG: 20,
    projecTot: 0,
    discountAdd: 20,
    discountAddOG: 20,
    // Responsabilidad del servicio
    initDate: null,
    endDate: null,
    estDeliDate: null,
    deliDate: null,
    // Adicionales
    checkHour: false,
    checkedAcc: false,
    tothAcc: 0,
    startDateAcc: null,
    finishDateAcc: null,
    checkedAR: false,
    tothAR: 0,
    startDateAR: null,
    finishDateAR: null,
    checkedAP: false,
    tothAP: 0,
    startDateAP: null,
    finishDateAP: null,
    checkedClass: false,
    tothClass: 0,
    startDateClass: null,
    finishDateClass: null,
    checkedMulti: false,
    tothMulti: 0,
    startDateMulti: null,
    finishDateMulti: null,
    checkedPer: false,
    tothPer: 0,
    startDatePer: null,
    finishDatePer: null,
    // USA
    checkedChecks: false,
    tothChecks: 0,
    startDateChecks: null,
    finishDateChecks: null,
    checkedSales: false,
    tothSales: 0,
    startDateSales: null,
    finishDateSales: null,
    // Venezuela
    disabledOrdConTax: false,
    checkedOrdConTax: false,
    tothOrdConTax: 0,
    startDateOrdConTax: null,
    finishDateOrdConTax: null,
    disabledSpeConTax: false,
    checkedSpeConTax: false,
    tothSpeConTax: 0,
    startDateSpeConTax: null,
    finishDateSpeConTax: null,
    checkedMuni: false,
    tothMuni: 0,
    startDateMuni: null,
    finishDateMuni: null,
    // Otros
    checkedOthers: false,
    nameOthers: "",
    tothOthers: 0,
    startDateOthers: null,
    finishDateOthers: null,
    // Total
    addHourFee: 0,
};

const latam = [
    "Argentina",
    "Bolivia",
    "Brasil",
    "Chile",
    "Colombia",
    "Costa Rica",
    "Cuba",
    "Ecuador",
    "El Salvador",
    "Guatemala",
    "Haití",
    "Honduras",
    "México",
    "Nicaragua",
    "Panamá",
    "Paraguay",
    "Perú",
    "República Dominicana",
    "Uruguay",
];

export const CleanUp = (props) => {
    // Properties
    const {
        accountSystem,
        actives,
        clientMarket,
        companyID,
        data,
        expenseEst,
        reloadProjects,
        serviceID,
        setalertType,
        setBackDrop,
        setShow,
        setTexto,
        handleCloseDialog,
        infoAdd,
        checkRemind,
        open,
        bodyMess,
        showConfirm,
    } = props;

    /** Internationalization i18n */
    const [t] = useTranslation("global");

    /** React redux */
    const todos = useSelector((state) => state.todos);
    const dispatch = useDispatch();

    /** Component states */
    const [valuesCleanUp, setCleanUp] = useState(defaultState);
    const [editMode, setEditMode] = useState(true);
    const [editMode2, setEditMode2] = useState(true);
    const [isError, setIsError] = useState(false);
    // const [columnDefs, setColumnsDefs] = useState([]);
    // const [dataTable, setDataTable] = useState([]);

    /** Component functions */
    const removeCheck = () => {
        setCleanUp({
            ...valuesCleanUp,
            [infoAdd.check]: false,
            [infoAdd.toth]: 0,
            nameOthers: infoAdd.nameOthers ? "" : valuesCleanUp.nameOthers,
            [infoAdd.startDate]: null,
            [infoAdd.finishDate]: null,
        });
        if (checkRemind) {
            dispatch({
                type: "addRemind",
                item: true,
            });
        }
        handleCloseDialog();
    };

    useEffect(() => {
        let accAdditionalData = {};
        let checksAdditionalData = {};
        let arAdditionalData = {};
        let apAdditionalData = {};
        let classesAdditionalData = {};
        let multiAdditionalData = {};
        let perAdditionalData = {};
        let salesAdditionalData = {};
        let ordinaryAdditionalData = {};
        let specialAdditionalData = {};
        let muniAdditionalData = {};
        let othersAdditionalData = {};
        const proData = data.projects_array && data.projects_array[0];

        const feeAdd = clientMarket === "Venezuela" ? 30 : latam.includes(clientMarket) ? 35 : 50;

        if (proData) {
            if (proData.additionals && proData.additionals.length !== 0) {
                proData.additionals.forEach((element) => {
                    if (element.additional_name === "Accountant") {
                        accAdditionalData = {
                            since_date: element.since_date,
                            end_date: element.end_date,
                            hours: element.hours,
                            update_hours: element.update_hours,
                        };
                    }
                    if (element.additional_name === "Checks") {
                        checksAdditionalData = {
                            since_date: element.since_date,
                            end_date: element.end_date,
                            hours: element.hours,
                        };
                    }
                    if (element.additional_name === "A/R reconciliation") {
                        arAdditionalData = {
                            since_date: element.since_date,
                            end_date: element.end_date,
                            hours: element.hours,
                        };
                    }
                    if (element.additional_name === "A/P reconciliation") {
                        apAdditionalData = {
                            since_date: element.since_date,
                            end_date: element.end_date,
                            hours: element.hours,
                        };
                    }
                    if (element.additional_name === "Classes/Categories accounting") {
                        classesAdditionalData = {
                            since_date: element.since_date,
                            end_date: element.end_date,
                            hours: element.hours,
                        };
                    }
                    if (element.additional_name === "Multi-entity accounting") {
                        multiAdditionalData = {
                            since_date: element.since_date,
                            end_date: element.end_date,
                            hours: element.hours,
                        };
                    }
                    if (element.additional_name === "Personalized reporting") {
                        perAdditionalData = {
                            since_date: element.since_date,
                            end_date: element.end_date,
                            hours: element.hours,
                        };
                    }
                    if (element.additional_name === "Sales taxes") {
                        salesAdditionalData = {
                            since_date: element.since_date,
                            end_date: element.end_date,
                            hours: element.hours,
                        };
                    }
                    if (element.additional_name === "Ordinario") {
                        ordinaryAdditionalData = {
                            since_date: element.since_date,
                            end_date: element.end_date,
                            hours: element.hours,
                        };
                    }
                    if (element.additional_name === "Especial") {
                        specialAdditionalData = {
                            since_date: element.since_date,
                            end_date: element.end_date,
                            hours: element.hours,
                        };
                    }
                    if (element.additional_name === "Municipal") {
                        muniAdditionalData = {
                            since_date: element.since_date,
                            end_date: element.end_date,
                            hours: element.hours,
                        };
                    }
                    if (element.additional_name === "OthersAcc") {
                        othersAdditionalData = {
                            since_date: element.since_date,
                            end_date: element.end_date,
                            hours: element.hours,
                            other_name: element.other_name,
                        };
                    }
                });
            }

            let meses = 0;
            let accMonths = 0;

            if (proData.since_date) {
                meses = Math.ceil(moment(proData.end_date).diff(moment(proData.since_date), "months", true));
            }
            if (proData.additionals_only) {
                accMonths = Math.ceil(
                    moment(accAdditionalData.end_date).diff(moment(accAdditionalData.since_date), "months", true)
                );
            }

            setCleanUp({
                projectCompanyId: proData.project_company_id,
                updateBoolean: data.update_boolean ?? false,
                baseFee: proData.additionals_only ? feeAdd * parseFloat(accAdditionalData.hours) : proData.fee,
                months: proData.additionals_only ? accMonths : meses,
                projectFee: proData.additionals_only
                    ? accMonths * (accAdditionalData.hours ?? 0) * feeAdd
                    : proData.fee * meses,
                discount: proData.discount ?? 20,
                discountOG: proData.discount ?? 20,
                projecTot: proData.additionals_only
                    ? accMonths * (accAdditionalData.hours ?? 0) * feeAdd * (1 - (proData.discount ?? 20) / 100)
                    : proData.fee * meses * (1 - (proData.discount ?? 20) / 100),
                discountAdd: proData.additional_discount ?? 20,
                discountAddOG: proData.additional_discount ?? 20,
                // Responsabilidad del servicio
                initDate: proData.since_date ? moment(proData.since_date).utc() : null,
                endDate: proData.end_date ? moment(proData.end_date).utc() : null,
                estDeliDate: proData.client_commitment_date ? moment(proData.client_commitment_date).utc() : null,
                deliDate: proData.client_delivery_date ? moment(proData.client_delivery_date).utc() : null,
                // Adicionales
                checkHour: proData.additionals_only,
                checkedAcc: Object.keys(accAdditionalData).length !== 0,
                tothAcc: accAdditionalData.hours ?? 0,
                startDateAcc: !accAdditionalData.since_date ? null : moment(accAdditionalData.since_date).utc(),
                finishDateAcc: !accAdditionalData.end_date ? null : moment(accAdditionalData.end_date).utc(),
                checkedAR: Object.keys(arAdditionalData).length !== 0,
                checkedAROG: Object.keys(arAdditionalData).length !== 0,
                tothAR: arAdditionalData.hours ?? 0,
                startDateAR: !arAdditionalData.since_date ? null : moment(arAdditionalData.since_date).utc(),
                finishDateAR: !arAdditionalData.end_date ? null : moment(arAdditionalData.end_date).utc(),
                checkedAP: Object.keys(apAdditionalData).length !== 0,
                checkedAPOG: Object.keys(apAdditionalData).length !== 0,
                tothAP: apAdditionalData.hours ?? 0,
                startDateAP: !apAdditionalData.since_date ? null : moment(apAdditionalData.since_date).utc(),
                finishDateAP: !apAdditionalData.end_date ? null : moment(apAdditionalData.end_date).utc(),
                checkedClass: Object.keys(classesAdditionalData).length !== 0,
                checkedClassOG: Object.keys(classesAdditionalData).length !== 0,
                tothClass: classesAdditionalData.hours ?? 0,
                startDateClass: !classesAdditionalData.since_date
                    ? null
                    : moment(classesAdditionalData.since_date).utc(),
                finishDateClass: !classesAdditionalData.end_date ? null : moment(classesAdditionalData.end_date).utc(),
                checkedMulti: Object.keys(multiAdditionalData).length !== 0,
                checkedMultiOG: Object.keys(multiAdditionalData).length !== 0,
                tothMulti: multiAdditionalData.hours ?? 0,
                startDateMulti: !multiAdditionalData.since_date ? null : moment(multiAdditionalData.since_date).utc(),
                finishDateMulti: !multiAdditionalData.end_date ? null : moment(multiAdditionalData.end_date).utc(),
                checkedPer: Object.keys(perAdditionalData).length !== 0,
                checkedPerOG: Object.keys(perAdditionalData).length !== 0,
                tothPer: perAdditionalData.hours ?? 0,
                startDatePer: !perAdditionalData.since_date ? null : moment(perAdditionalData.since_date).utc(),
                finishDatePer: !perAdditionalData.end_date ? null : moment(perAdditionalData.end_date).utc(),
                // USA
                checkedChecks: Object.keys(checksAdditionalData).length !== 0,
                checkedChecksOG: Object.keys(checksAdditionalData).length !== 0,
                tothChecks: checksAdditionalData.hours ?? 0,
                startDateChecks: !checksAdditionalData.since_date
                    ? null
                    : moment(checksAdditionalData.since_date).utc(),
                finishDateChecks: !checksAdditionalData.end_date ? null : moment(checksAdditionalData.end_date).utc(),
                checkedSales: Object.keys(salesAdditionalData).length !== 0,
                checkedSalesOG: Object.keys(salesAdditionalData).length !== 0,
                tothSales: salesAdditionalData.hours ?? 0,
                startDateSales: !salesAdditionalData.since_date ? null : moment(salesAdditionalData.since_date).utc(),
                finishDateSales: !salesAdditionalData.end_date ? null : moment(salesAdditionalData.end_date).utc(),
                // Venezuela
                disabledOrdConTax: Object.keys(specialAdditionalData).length !== 0,
                checkedOrdConTax: Object.keys(ordinaryAdditionalData).length !== 0,
                checkedOrdConTaxOG: Object.keys(ordinaryAdditionalData).length !== 0,
                tothOrdConTax: ordinaryAdditionalData.hours ?? 0,
                startDateOrdConTax: !ordinaryAdditionalData.since_date
                    ? null
                    : moment(ordinaryAdditionalData.since_date).utc(),
                finishDateOrdConTax: !ordinaryAdditionalData.end_date
                    ? null
                    : moment(ordinaryAdditionalData.end_date).utc(),
                disabledSpeConTax: Object.keys(ordinaryAdditionalData).length !== 0,
                checkedSpeConTax: Object.keys(specialAdditionalData).length !== 0,
                checkedSpeConTaxOG: Object.keys(specialAdditionalData).length !== 0,
                tothSpeConTax: specialAdditionalData.hours ?? 0,
                startDateSpeConTax: !specialAdditionalData.since_date
                    ? null
                    : moment(specialAdditionalData.since_date).utc(),
                finishDateSpeConTax: !specialAdditionalData.end_date
                    ? null
                    : moment(specialAdditionalData.end_date).utc(),
                checkedMuni: Object.keys(muniAdditionalData).length !== 0,
                checkedMuniOG: Object.keys(muniAdditionalData).length !== 0,
                tothMuni: muniAdditionalData.hours ?? 0,
                startDateMuni: !muniAdditionalData.since_date ? null : moment(muniAdditionalData.since_date).utc(),
                finishDateMuni: !muniAdditionalData.end_date ? null : moment(muniAdditionalData.end_date).utc(),
                // Otros
                checkedOthers: Object.keys(othersAdditionalData).length !== 0,
                checkedOthersOG: Object.keys(othersAdditionalData).length !== 0,
                nameOthers: othersAdditionalData.other_name ?? "",
                tothOthers: othersAdditionalData.hours ?? 0,
                startDateOthers: !othersAdditionalData.since_date
                    ? null
                    : moment(othersAdditionalData.since_date).utc(),
                finishDateOthers: !othersAdditionalData.end_date ? null : moment(othersAdditionalData.end_date).utc(),
                // Total
                addHourFee: feeAdd,
            });

            // setColumnsDefs(columnsTemplate);
            // setDataTable(dataTable);
        } else {
            setCleanUp({
                ...valuesCleanUp,
                baseFee: data.fee,
                updateBoolean: false,
                addHourFee: feeAdd,
            });

            // setColumnsDefs(columnsTemplate);
            // setDataTable(dataTable);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const getSumTotal = () => {
        let sumTotal =
            parseFloat(valuesCleanUp.tothAR) *
                (isNaN(moment(valuesCleanUp.finishDateAR).diff(moment(valuesCleanUp.startDateAR)))
                    ? 0
                    : Math.ceil(
                          moment(valuesCleanUp.finishDateAR).diff(moment(valuesCleanUp.startDateAR), "months", true)
                      )) +
            parseFloat(valuesCleanUp.tothAP) *
                (isNaN(moment(valuesCleanUp.finishDateAP).diff(moment(valuesCleanUp.startDateAP)))
                    ? 0
                    : Math.ceil(
                          moment(valuesCleanUp.finishDateAP).diff(moment(valuesCleanUp.startDateAP), "months", true)
                      )) +
            parseFloat(valuesCleanUp.tothClass) *
                (isNaN(moment(valuesCleanUp.finishDateClass).diff(moment(valuesCleanUp.startDateClass)))
                    ? 0
                    : Math.ceil(
                          moment(valuesCleanUp.finishDateClass).diff(
                              moment(valuesCleanUp.startDateClass),
                              "months",
                              true
                          )
                      )) +
            parseFloat(valuesCleanUp.tothMulti) *
                (isNaN(moment(valuesCleanUp.finishDateMulti).diff(moment(valuesCleanUp.startDateMulti)))
                    ? 0
                    : Math.ceil(
                          moment(valuesCleanUp.finishDateMulti).diff(
                              moment(valuesCleanUp.startDateMulti),
                              "months",
                              true
                          )
                      )) +
            parseFloat(valuesCleanUp.tothPer) *
                (isNaN(moment(valuesCleanUp.finishDatePer).diff(moment(valuesCleanUp.startDatePer)))
                    ? 0
                    : Math.ceil(
                          moment(valuesCleanUp.finishDatePer).diff(moment(valuesCleanUp.startDatePer), "months", true)
                      )) +
            (clientMarket === "Estados Unidos"
                ? parseFloat(valuesCleanUp.tothSales) *
                      (isNaN(moment(valuesCleanUp.finishDateSales).diff(moment(valuesCleanUp.startDateSales)))
                          ? 0
                          : Math.ceil(
                                moment(valuesCleanUp.finishDateSales).diff(
                                    moment(valuesCleanUp.startDateSales),
                                    "months",
                                    true
                                )
                            )) +
                  parseFloat(valuesCleanUp.tothChecks) *
                      (isNaN(moment(valuesCleanUp.finishDateChecks).diff(moment(valuesCleanUp.startDateChecks)))
                          ? 0
                          : Math.ceil(
                                moment(valuesCleanUp.finishDateChecks).diff(
                                    moment(valuesCleanUp.startDateChecks),
                                    "months",
                                    true
                                )
                            ))
                : clientMarket === "Venezuela"
                ? parseFloat(valuesCleanUp.tothOrdConTax) *
                      (isNaN(moment(valuesCleanUp.finishDateOrdConTax).diff(moment(valuesCleanUp.startDateOrdConTax)))
                          ? 0
                          : Math.ceil(
                                moment(valuesCleanUp.finishDateOrdConTax).diff(
                                    moment(valuesCleanUp.startDateOrdConTax),
                                    "months",
                                    true
                                )
                            )) +
                  parseFloat(valuesCleanUp.tothMuni) *
                      (isNaN(moment(valuesCleanUp.finishDateMuni).diff(moment(valuesCleanUp.startDateMuni)))
                          ? 0
                          : Math.ceil(
                                moment(valuesCleanUp.finishDateMuni).diff(
                                    moment(valuesCleanUp.startDateMuni),
                                    "months",
                                    true
                                )
                            )) +
                  parseFloat(valuesCleanUp.tothSpeConTax) *
                      (isNaN(moment(valuesCleanUp.finishDateSpeConTax).diff(moment(valuesCleanUp.startDateSpeConTax)))
                          ? 0
                          : Math.ceil(
                                moment(valuesCleanUp.finishDateSpeConTax).diff(
                                    moment(valuesCleanUp.startDateSpeConTax),
                                    "months",
                                    true
                                )
                            ))
                : 0) +
            parseFloat(valuesCleanUp.tothOthers) *
                (isNaN(moment(valuesCleanUp.finishDateOthers).diff(moment(valuesCleanUp.startDateOthers)))
                    ? 0
                    : Math.ceil(
                          moment(valuesCleanUp.finishDateOthers).diff(
                              moment(valuesCleanUp.startDateOthers),
                              "months",
                              true
                          )
                      ));

        return sumTotal;
    };

    const handleChangeDiscount = (event) => {
        let discountAux = 0;

        if (event.target.name === "discount") {
            discountAux =
                event.target.value >= 0 && event.target.value <= 20 ? event.target.value : valuesCleanUp.discount;

            setCleanUp({
                ...valuesCleanUp,
                discount: discountAux,
                projecTot: valuesCleanUp.projectFee * (1 - discountAux / 100),
            });
        } else {
            discountAux =
                event.target.value >= 0 && event.target.value <= 20 ? event.target.value : valuesCleanUp.discountAdd;

            setCleanUp({
                ...valuesCleanUp,
                discountAdd: discountAux,
            });
        }
    };

    const updateProject = async () => {
        setBackDrop(true);

        if (valuesCleanUp.initDate && valuesCleanUp.endDate && valuesCleanUp.estDeliDate) {
            let additionalsF = [];

            if (valuesCleanUp.checkedAcc) {
                if (valuesCleanUp.startDateAcc && valuesCleanUp.finishDateAcc) {
                    if (valuesCleanUp.tothAcc > 0) {
                        additionalsF.push({
                            additional_name: "Accountant",
                            hours: parseFloat(valuesCleanUp.tothAcc),
                            since_date: moment(valuesCleanUp.startDateAcc).startOf("day").format(),
                            end_date: moment(valuesCleanUp.finishDateAcc).startOf("day").format(),
                        });
                    } else {
                        setTexto(t("services.hoursValidationError"));
                        setalertType("warning");
                        setShow(true);
                        setBackDrop(false);
                        setIsError(true);
                        return;
                    }
                } else {
                    setTexto(t("miscellaneous.requiredData"));
                    setalertType("warning");
                    setShow(true);
                    setBackDrop(false);
                    setIsError(true);
                    return;
                }
            }
            if (valuesCleanUp.checkedChecks) {
                if (valuesCleanUp.startDateChecks && valuesCleanUp.finishDateChecks) {
                    if (valuesCleanUp.tothChecks > 0) {
                        additionalsF.push({
                            additional_name: "Checks",
                            hours: parseFloat(valuesCleanUp.tothChecks),
                            since_date: moment(valuesCleanUp.startDateChecks).startOf("day").format(),
                            end_date: moment(valuesCleanUp.finishDateChecks).startOf("day").format(),
                        });
                    } else {
                        setTexto(t("services.hoursValidationError"));
                        setalertType("warning");
                        setShow(true);
                        setBackDrop(false);
                        setIsError(true);
                        return;
                    }
                } else {
                    setTexto(t("miscellaneous.requiredData"));
                    setalertType("warning");
                    setShow(true);
                    setBackDrop(false);
                    setIsError(true);
                    return;
                }
            }
            if (valuesCleanUp.checkedAR) {
                if (valuesCleanUp.startDateAR && valuesCleanUp.finishDateAR) {
                    if (valuesCleanUp.tothAR > 0) {
                        additionalsF.push({
                            additional_name: "A/R reconciliation",
                            hours: parseFloat(valuesCleanUp.tothAR),
                            since_date: moment(valuesCleanUp.startDateAR).startOf("day").format(),
                            end_date: moment(valuesCleanUp.finishDateAR).startOf("day").format(),
                        });
                    } else {
                        setTexto(t("services.hoursValidationError"));
                        setalertType("warning");
                        setShow(true);
                        setBackDrop(false);
                        setIsError(true);
                        return;
                    }
                } else {
                    setTexto(t("miscellaneous.requiredData"));
                    setalertType("warning");
                    setShow(true);
                    setBackDrop(false);
                    setIsError(true);
                    return;
                }
            }
            if (valuesCleanUp.checkedAP) {
                if (valuesCleanUp.startDateAP && valuesCleanUp.finishDateAP) {
                    if (valuesCleanUp.tothAP > 0) {
                        additionalsF.push({
                            additional_name: "A/P reconciliation",
                            hours: parseFloat(valuesCleanUp.tothAP),
                            since_date: moment(valuesCleanUp.startDateAP).startOf("day").format(),
                            end_date: moment(valuesCleanUp.finishDateAP).startOf("day").format(),
                        });
                    } else {
                        setTexto(t("services.hoursValidationError"));
                        setalertType("warning");
                        setShow(true);
                        setBackDrop(false);
                        setIsError(true);
                        return;
                    }
                } else {
                    setTexto(t("miscellaneous.requiredData"));
                    setalertType("warning");
                    setShow(true);
                    setBackDrop(false);
                    setIsError(true);
                    return;
                }
            }
            if (valuesCleanUp.checkedClass) {
                if (valuesCleanUp.startDateClass && valuesCleanUp.finishDateClass) {
                    if (valuesCleanUp.tothClass > 0) {
                        additionalsF.push({
                            additional_name: "Classes/Categories accounting",
                            hours: parseFloat(valuesCleanUp.tothClass),
                            since_date: moment(valuesCleanUp.startDateClass).startOf("day").format(),
                            end_date: moment(valuesCleanUp.finishDateClass).startOf("day").format(),
                        });
                    } else {
                        setTexto(t("services.hoursValidationError"));
                        setalertType("warning");
                        setShow(true);
                        setBackDrop(false);
                        setIsError(true);
                        return;
                    }
                } else {
                    setTexto(t("miscellaneous.requiredData"));
                    setalertType("warning");
                    setShow(true);
                    setBackDrop(false);
                    setIsError(true);
                    return;
                }
            }
            if (valuesCleanUp.checkedMulti) {
                if (valuesCleanUp.startDateMulti && valuesCleanUp.finishDateMulti) {
                    if (valuesCleanUp.tothMulti > 0) {
                        additionalsF.push({
                            additional_name: "Multi-entity accounting",
                            hours: parseFloat(valuesCleanUp.tothMulti),
                            since_date: moment(valuesCleanUp.startDateMulti).startOf("day").format(),
                            end_date: moment(valuesCleanUp.finishDateMulti).startOf("day").format(),
                        });
                    } else {
                        setTexto(t("services.hoursValidationError"));
                        setalertType("warning");
                        setShow(true);
                        setBackDrop(false);
                        setIsError(true);
                        return;
                    }
                } else {
                    setTexto(t("miscellaneous.requiredData"));
                    setalertType("warning");
                    setShow(true);
                    setBackDrop(false);
                    setIsError(true);
                    return;
                }
            }
            if (valuesCleanUp.checkedPer) {
                if (valuesCleanUp.startDatePer && valuesCleanUp.finishDatePer) {
                    if (valuesCleanUp.tothPer > 0) {
                        additionalsF.push({
                            additional_name: "Personalized reporting",
                            hours: parseFloat(valuesCleanUp.tothPer),
                            since_date: moment(valuesCleanUp.startDatePer).startOf("day").format(),
                            end_date: moment(valuesCleanUp.finishDatePer).startOf("day").format(),
                        });
                    } else {
                        setTexto(t("services.hoursValidationError"));
                        setalertType("warning");
                        setShow(true);
                        setBackDrop(false);
                        setIsError(true);
                        return;
                    }
                } else {
                    setTexto(t("miscellaneous.requiredData"));
                    setalertType("warning");
                    setShow(true);
                    setBackDrop(false);
                    setIsError(true);
                    return;
                }
            }
            if (valuesCleanUp.checkedSales) {
                if (valuesCleanUp.startDateSales && valuesCleanUp.finishDateSales) {
                    if (valuesCleanUp.tothSales > 0) {
                        additionalsF.push({
                            additional_name: "Sales taxes",
                            hours: parseFloat(valuesCleanUp.tothSales),
                            since_date: moment(valuesCleanUp.startDateSales).startOf("day").format(),
                            end_date: moment(valuesCleanUp.finishDateSales).startOf("day").format(),
                        });
                    } else {
                        setTexto(t("services.hoursValidationError"));
                        setalertType("warning");
                        setShow(true);
                        setBackDrop(false);
                        setIsError(true);
                        return;
                    }
                } else {
                    setTexto(t("miscellaneous.requiredData"));
                    setalertType("warning");
                    setShow(true);
                    setBackDrop(false);
                    setIsError(true);
                    return;
                }
            }
            if (valuesCleanUp.checkedOrdConTax) {
                if (valuesCleanUp.startDateOrdConTax && valuesCleanUp.finishDateOrdConTax) {
                    if (valuesCleanUp.tothOrdConTax > 0) {
                        additionalsF.push({
                            additional_name: "Ordinario",
                            hours: parseFloat(valuesCleanUp.tothOrdConTax),
                            since_date: moment(valuesCleanUp.startDateOrdConTax).startOf("day").format(),
                            end_date: moment(valuesCleanUp.finishDateOrdConTax).startOf("day").format(),
                        });
                    } else {
                        setTexto(t("services.hoursValidationError"));
                        setalertType("warning");
                        setShow(true);
                        setBackDrop(false);
                        setIsError(true);
                        return;
                    }
                } else {
                    setTexto(t("miscellaneous.requiredData"));
                    setalertType("warning");
                    setShow(true);
                    setBackDrop(false);
                    setIsError(true);
                    return;
                }
            }
            if (valuesCleanUp.checkedSpeConTax) {
                if (valuesCleanUp.startDateSpeConTax && valuesCleanUp.finishDateSpeConTax) {
                    if (valuesCleanUp.tothSpeConTax > 0) {
                        additionalsF.push({
                            additional_name: "Especial",
                            hours: parseFloat(valuesCleanUp.tothSpeConTax),
                            since_date: moment(valuesCleanUp.startDateSpeConTax).startOf("day").format(),
                            end_date: moment(valuesCleanUp.finishDateSpeConTax).startOf("day").format(),
                        });
                    } else {
                        setTexto(t("services.hoursValidationError"));
                        setalertType("warning");
                        setShow(true);
                        setBackDrop(false);
                        setIsError(true);
                        return;
                    }
                } else {
                    setTexto(t("miscellaneous.requiredData"));
                    setalertType("warning");
                    setShow(true);
                    setBackDrop(false);
                    setIsError(true);
                    return;
                }
            }
            if (valuesCleanUp.checkedMuni) {
                if (valuesCleanUp.startDateMuni && valuesCleanUp.finishDateMuni) {
                    if (valuesCleanUp.tothMuni > 0) {
                        additionalsF.push({
                            additional_name: "Municipal",
                            hours: parseFloat(valuesCleanUp.tothMuni),
                            since_date: moment(valuesCleanUp.startDateMuni).startOf("day").format(),
                            end_date: moment(valuesCleanUp.finishDateMuni).startOf("day").format(),
                        });
                    } else {
                        setTexto(t("services.hoursValidationError"));
                        setalertType("warning");
                        setShow(true);
                        setBackDrop(false);
                        setIsError(true);
                        return;
                    }
                } else {
                    setTexto(t("miscellaneous.requiredData"));
                    setalertType("warning");
                    setShow(true);
                    setBackDrop(false);
                    setIsError(true);
                    return;
                }
            }
            if (valuesCleanUp.checkedOthers) {
                if (valuesCleanUp.startDateOthers && valuesCleanUp.finishDateOthers && valuesCleanUp.nameOthers) {
                    if (valuesCleanUp.tothOthers > 0) {
                        additionalsF.push({
                            additional_name: "OthersAcc",
                            hours: parseFloat(valuesCleanUp.tothOthers),
                            since_date: moment(valuesCleanUp.startDateOthers).startOf("day").format(),
                            end_date: moment(valuesCleanUp.finishDateOthers).startOf("day").format(),
                            other_name: valuesCleanUp.nameOthers,
                        });
                    } else {
                        setTexto(t("services.hoursValidationError"));
                        setalertType("warning");
                        setShow(true);
                        setBackDrop(false);
                        setIsError(true);
                        return;
                    }
                } else {
                    setTexto(t("miscellaneous.requiredData"));
                    setalertType("warning");
                    setShow(true);
                    setBackDrop(false);
                    setIsError(true);
                    return;
                }
            }

            let params = {
                company_id: companyID,
                service_id: serviceID,
                project_id: data.project_id,
                project_company_id: valuesCleanUp.projectCompanyId,
                fee: valuesCleanUp.baseFee,
                total_fee:
                    valuesCleanUp.projecTot +
                    valuesCleanUp.addHourFee * getSumTotal() * (1 - valuesCleanUp.discountAdd / 100),
                discount: parseFloat(valuesCleanUp.discount),
                additional_discount: parseFloat(valuesCleanUp.discountAdd),
                since_date: moment(valuesCleanUp.initDate).startOf("day").format(),
                end_date: moment(valuesCleanUp.endDate).startOf("day").format(),
                client_commitment_date: moment(valuesCleanUp.estDeliDate).startOf("day").format(),
                client_delivery_date: valuesCleanUp.deliDate
                    ? moment(valuesCleanUp.deliDate).startOf("day").format()
                    : null,
                additionals_only: valuesCleanUp.checkHour,
                additionals: additionalsF,
            };

            await fetch(`/addServicesAndProjects`, {
                method: "POST",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
                body: JSON.stringify(params),
            })
                .then((response) => {
                    if (!response.ok) {
                        // get error message from body or default to response status
                        const error = response.status;
                        throw new Error(error);
                    }

                    if (response.status === 200) {
                        setTexto(t("miscellaneous.successfulUpdate"));
                        setalertType("success");
                        setShow(true);
                        reloadProjects(true);
                    } else if (response.status === 201) {
                        setTexto(t("services.notPossibleGenProject"));
                        setalertType("error");
                        setShow(true);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            setTexto(t("miscellaneous.requiredData"));
            setalertType("warning");
            setShow(true);
            setIsError(true);
        }

        setBackDrop(false);
    };

    const handleChangeDeliDate = (date) => {
        if (!date) {
            setCleanUp({
                ...valuesCleanUp,
                deliDate: date,
            });
        } else {
            if (date.isSameOrBefore(moment(valuesCleanUp.endDate).format("YYYY-MM-DD"))) {
                setCleanUp({
                    ...valuesCleanUp,
                    deliDate: null,
                });

                setTexto(t("reports.selectEntDate"));
                setalertType("warning");
                setShow(true);
            } else {
                setCleanUp({
                    ...valuesCleanUp,
                    deliDate: date,
                });
            }
        }
    };

    const handleChangeEstDeliDate = (date) => {
        if (!date) {
            setCleanUp({
                ...valuesCleanUp,
                estDeliDate: date,
            });
        } else {
            if (date.isSameOrBefore(moment(valuesCleanUp.endDate).format("YYYY-MM-DD"))) {
                setCleanUp({
                    ...valuesCleanUp,
                    estDeliDate: null,
                });

                setTexto(t("reports.selectEntDate"));
                setalertType("warning");
                setShow(true);
            } else {
                setCleanUp({
                    ...valuesCleanUp,
                    estDeliDate: date,
                });
            }
        }
    };

    const handleChangeInitDate = (date) => {
        let startDate = moment(date).startOf("month");

        if (!date || !valuesCleanUp.endDate) {
            setCleanUp({
                ...valuesCleanUp,
                initDate: date ? startDate : date,
            });
        } else {
            if (startDate.isAfter(moment(valuesCleanUp.endDate).format("YYYY-MM-DD"))) {
                setCleanUp({
                    ...valuesCleanUp,
                    initDate: null,
                });

                setTexto(t("reports.initDateMess"));
                setalertType("warning");
                setShow(true);
            } else {
                let meses = Math.ceil(valuesCleanUp.endDate.diff(startDate, "months", true));

                let accMonths = 0;
                if (valuesCleanUp.checkHour) {
                    accMonths = Math.ceil(moment(valuesCleanUp.finishDateAcc).diff(startDate, "months", true));
                }

                setCleanUp({
                    ...valuesCleanUp,
                    initDate: startDate,
                    months: valuesCleanUp.checkHour ? accMonths : meses,
                    projectFee: valuesCleanUp.checkHour
                        ? accMonths * (valuesCleanUp.tothAcc ?? 0) * 50
                        : data.fee * meses,
                    projecTot: valuesCleanUp.checkHour
                        ? accMonths * (valuesCleanUp.tothAcc ?? 0) * 50 * (1 - (valuesCleanUp.discount ?? 20) / 100)
                        : data.fee * meses * (1 - valuesCleanUp.discount / 100),
                    // Adicionales
                    startDateAcc: valuesCleanUp.checkedAcc ? startDate : null,
                    startDateAR: valuesCleanUp.checkedAR ? startDate : null,
                    startDateAP: valuesCleanUp.checkedAP ? startDate : null,
                    startDateClass: valuesCleanUp.checkedClass ? startDate : null,
                    startDateMulti: valuesCleanUp.checkedMulti ? startDate : null,
                    startDatePer: valuesCleanUp.checkedPer ? startDate : null,
                    // USA
                    startDateChecks: valuesCleanUp.checkedChecks ? startDate : null,
                    startDateSales: valuesCleanUp.checkedSales ? startDate : null,
                    // Venezuela
                    startDateOrdConTax: valuesCleanUp.checkedOrdConTax ? startDate : null,
                    startDateSpeConTax: valuesCleanUp.checkedSpeConTax ? startDate : null,
                    startDateMuni: valuesCleanUp.checkedMuni ? startDate : null,
                    // Otros
                    startDateOthers: valuesCleanUp.checkedOthers ? startDate : null,
                });
            }
        }
    };

    const handleChangeEndDate = (date) => {
        let endDate = moment(date).endOf("month").startOf("day");

        if (!date || !valuesCleanUp.initDate) {
            setCleanUp({
                ...valuesCleanUp,
                endDate: date ? endDate : date,
            });
        } else {
            if (endDate.isBefore(moment(valuesCleanUp.initDate).format("YYYY-MM-DD"))) {
                setCleanUp({
                    ...valuesCleanUp,
                    endDate: null,
                });

                setTexto(t("reports.endDateMess"));
                setalertType("warning");
                setShow(true);
            } else {
                let meses = Math.ceil(endDate.diff(valuesCleanUp.initDate, "months", true));

                let accMonths = 0;
                if (valuesCleanUp.checkHour) {
                    accMonths = Math.ceil(endDate.diff(moment(valuesCleanUp.startDateAcc), "months", true));
                }

                setCleanUp({
                    ...valuesCleanUp,
                    endDate: endDate,
                    months: valuesCleanUp.checkHour ? accMonths : meses,
                    projectFee: valuesCleanUp.checkHour
                        ? accMonths * (valuesCleanUp.tothAcc ?? 0) * 50
                        : data.fee * meses,
                    projecTot: valuesCleanUp.checkHour
                        ? accMonths * (valuesCleanUp.tothAcc ?? 0) * 50 * (1 - (valuesCleanUp.discount ?? 20) / 100)
                        : data.fee * meses * (1 - valuesCleanUp.discount / 100),
                    // Adicionales
                    finishDateAcc: valuesCleanUp.checkedAcc ? endDate : null,
                    finishDateAR: valuesCleanUp.checkedAR ? endDate : null,
                    finishDateAP: valuesCleanUp.checkedAP ? endDate : null,
                    finishDateClass: valuesCleanUp.checkedClass ? endDate : null,
                    finishDateMulti: valuesCleanUp.checkedMulti ? endDate : null,
                    finishDatePer: valuesCleanUp.checkedPer ? endDate : null,
                    // USA
                    finishDateChecks: valuesCleanUp.checkedChecks ? endDate : null,
                    finishDateSales: valuesCleanUp.checkedSales ? endDate : null,
                    // Venezuela
                    finishDateOrdConTax: valuesCleanUp.checkedOrdConTax ? endDate : null,
                    finishDateSpeConTax: valuesCleanUp.checkedSpeConTax ? endDate : null,
                    finishDateMuni: valuesCleanUp.checkedMuni ? endDate : null,
                    // Otros
                    finishDateOthers: valuesCleanUp.checkedOthers ? endDate : null,
                });
            }
        }
    };

    const handleMouseDownDiscount = (event) => {
        event.preventDefault();
    };

    const handleClickShowDiscount = () => {
        setEditMode(!editMode);
    };

    const handleClickShowDiscount2 = () => {
        setEditMode2(!editMode2);
    };

    const handleChangeSwitch = (event) => {
        let meses = 0;

        if (valuesCleanUp.initDate) {
            meses = isNaN(moment(valuesCleanUp.endDate).diff(moment(valuesCleanUp.initDate)))
                ? 0
                : Math.ceil(moment(valuesCleanUp.endDate).diff(moment(valuesCleanUp.initDate), "months", true));
        }

        setCleanUp({
            ...valuesCleanUp,
            baseFee: event.target.checked ? 0 : data.fee,
            months: meses,
            checkHour: event.target.checked,
            checkedAcc: event.target.checked,
            tothAcc: 0,
            startDateAcc: event.target.checked ? valuesCleanUp.initDate : null,
            finishDateAcc: event.target.checked ? valuesCleanUp.endDate : null,
            projectFee: event.target.checked ? 0 : data.fee * meses,
            discount: event.target.checked ? 0 : valuesCleanUp.discountOG,
            discountAdd: event.target.checked ? 0 : valuesCleanUp.discountAddOG,
            projecTot: event.target.checked ? 0 : data.fee * meses * (1 - valuesCleanUp.discount / 100),
        });
    };

    return (
        <StyledContainer>
            <LocalizationProvider
                dateAdapter={AdapterMoment}
                adapterLocale={t("language.locale")}
                localeText={{ clearButtonLabel: t("miscellaneous.clear") }}
            >
                <Card elevation={0}>
                    <CardHeader title={
                        <Box display="flex" sx={{ alignItems: "center" }}>
                            Accountant cleanup
                            <FormControlLabel
                                sx={{ml: 1}}
                                control={
                                    <Switch
                                        checked={valuesCleanUp.checkHour}
                                        disabled={valuesCleanUp.updateBoolean || !valuesCleanUp.initDate || !valuesCleanUp.endDate}
                                        inputProps={{
                                            "aria-label": "controlled",
                                        }}
                                        color="secondary"
                                        onChange={handleChangeSwitch}
                                        className={classes.switch}
                                    />
                                }
                                label={
                                    <Typography variant="body1" color="textPrimary">
                                        {t("services.hourlyInvoicing")}
                                    </Typography>
                                }
                            />
                        </Box>
                    } />
                    <Divider />
                    <CardContent>
                        {valuesCleanUp.updateBoolean && (
                            <GridUI container spacing={2} justifyContent="center" sx={{ paddingBottom: 3 }}>
                                <GridUI item>
                                    <Alert
                                        severity="warning"
                                        sx={{
                                            borderRadius: 8,
                                            fontSize: 12,
                                            fontWeight: 600,
                                        }}
                                    >
                                        {t("services.notPossibleEditProject")}
                                    </Alert>
                                </GridUI>
                            </GridUI>
                        )}
                        {actives.includes("Bookkeeper") && (
                            <Grid container spacing={2} justifyContent="center" sx={{ paddingBottom: 3 }}>
                                <Grid item>
                                    <Alert
                                        severity="warning"
                                        sx={{
                                            borderRadius: 8,
                                            fontSize: 12,
                                            fontWeight: 600,
                                        }}
                                    >
                                        {t("services.notPosibleContractAcc")}
                                    </Alert>
                                </Grid>
                            </Grid>
                        )}
                        {!actives.includes("Bookkeeper") &&
                            accountSystem.disabledData === true &&
                            (accountSystem.prov === "Xero" || accountSystem.prov === "Quickbooks Online") && (
                                <Grid container spacing={2} justifyContent="center" sx={{ paddingBottom: 3 }}>
                                    <Grid item>
                                        <Alert
                                            severity="warning"
                                            sx={{
                                                borderRadius: 8,
                                                fontSize: 12,
                                                fontWeight: 600,
                                            }}
                                        >
                                            {t("services.enterValidCredentials")}
                                        </Alert>
                                    </Grid>
                                </Grid>
                            )}
                        {!actives.includes("Bookkeeper") && expenseEst === -1 && (
                            <Grid container spacing={2} justifyContent="center" sx={{ paddingBottom: 3 }}>
                                <Grid item>
                                    <Alert
                                        severity="warning"
                                        sx={{
                                            borderRadius: 8,
                                            fontSize: 12,
                                            fontWeight: 600,
                                        }}
                                    >
                                        {t("services.ableToSaveInfo")}
                                    </Alert>
                                </Grid>
                            </Grid>
                        )}
                        <GridUI container alignItems="center" spacing={2}>
                            <GridUI item xs={12} sm={6} lg={2}>
                                <FormControl fullWidth variant="outlined" disabled>
                                    <InputLabel htmlFor="outlined-adornment-baseFee">
                                        {t("services.baseRate")}
                                    </InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-baseFee"
                                        value={valuesCleanUp.baseFee}
                                        inputComponent={NumberFormatCustom}
                                        startAdornment={
                                            <InputAdornment disableTypography position="start">
                                                $
                                            </InputAdornment>
                                        }
                                        label={t("services.baseRate")}
                                    />
                                </FormControl>
                            </GridUI>
                            <GridUI item xs={12} sm={6} lg={1}>
                                <TextField
                                    disabled
                                    fullWidth
                                    label={t("bankingDashboard.months")}
                                    value={valuesCleanUp.months}
                                    name="months"
                                    variant="outlined"
                                    type="number"
                                    InputProps={{ inputProps: { min: 0 } }}
                                />
                            </GridUI>
                            <GridUI item xs={12} sm={6} lg={3}>
                                <FormControl fullWidth variant="outlined" disabled>
                                    <InputLabel htmlFor="outlined-adornment-projectFee">
                                        {t("services.projectFee")}
                                    </InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-projectFee"
                                        value={valuesCleanUp.projectFee}
                                        inputComponent={NumberFormatCustom}
                                        startAdornment={
                                            <InputAdornment disableTypography position="start">
                                                $
                                            </InputAdornment>
                                        }
                                        label={t("services.projectFee")}
                                    />
                                </FormControl>
                            </GridUI>
                            <GridUI item xs={12} sm={6} lg={3}>
                                <FormControl variant="outlined" disabled={editMode} fullWidth>
                                    <InputLabel htmlFor="outlined-adornment-discount">
                                        {t("miscellaneous.discount")}
                                    </InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-discount"
                                        type="number"
                                        name="discount"
                                        value={valuesCleanUp.discount}
                                        onChange={handleChangeDiscount}
                                        onBlur={() =>
                                            setCleanUp({
                                                ...valuesCleanUp,
                                                discount: parseFloat(
                                                    valuesCleanUp.discount === "" ? 0 : valuesCleanUp.discount
                                                ).toFixed(2),
                                            })
                                        }
                                        inputProps={{
                                            min: 0,
                                            max: 20,
                                        }}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                %
                                                <LightTooltip
                                                    title={
                                                        editMode
                                                            ? t("services.cleanupMaximunDiscount")
                                                            : t("miscellaneous.save")
                                                    }
                                                    aria-label="edit"
                                                >
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        disabled={valuesCleanUp.updateBoolean}
                                                        onClick={handleClickShowDiscount}
                                                        onMouseDown={handleMouseDownDiscount}
                                                        edge="end"
                                                    >
                                                        {!editMode ? <EditOffIcon /> : <EditIcon />}
                                                    </IconButton>
                                                </LightTooltip>
                                            </InputAdornment>
                                        }
                                        label={t("miscellaneous.discount")}
                                        sx={{
                                            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                                display: "none",
                                            },
                                        }}
                                    />
                                </FormControl>
                            </GridUI>
                            <GridUI item xs={12} sm={6} lg={3}>
                                <FormControl fullWidth variant="outlined" disabled>
                                    <InputLabel htmlFor="outlined-adornment-projecTot">
                                        {t("services.projectTot")}
                                    </InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-projecTot"
                                        value={valuesCleanUp.projecTot}
                                        inputComponent={NumberFormatCustom}
                                        startAdornment={
                                            <InputAdornment disableTypography position="start">
                                                $
                                            </InputAdornment>
                                        }
                                        label={t("services.projectTot")}
                                    />
                                </FormControl>
                            </GridUI>
                            <GridUI item xs={12} sm={6} lg={3}></GridUI>
                            <GridUI item xs={12} sm={6} lg={3}>
                                <FormControl fullWidth variant="outlined" disabled>
                                    <InputLabel htmlFor="outlined-adornment-feeAdd">
                                        {t("services.hourlyRateAdd")}
                                    </InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-feeAdd"
                                        value={valuesCleanUp.addHourFee * getSumTotal()}
                                        inputComponent={NumberFormatCustom}
                                        startAdornment={
                                            <InputAdornment disableTypography position="start">
                                                $
                                            </InputAdornment>
                                        }
                                        label={t("services.hourlyRateAdd")}
                                    />
                                </FormControl>
                            </GridUI>
                            <GridUI item xs={12} sm={6} lg={3}>
                                <FormControl variant="outlined" disabled={editMode2} fullWidth>
                                    <InputLabel htmlFor="outlined-adornment-discount">
                                        {t("miscellaneous.discount")}
                                    </InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-discount"
                                        type="number"
                                        name="discountAdd"
                                        value={valuesCleanUp.discountAdd}
                                        onChange={handleChangeDiscount}
                                        onBlur={() =>
                                            setCleanUp({
                                                ...valuesCleanUp,
                                                discountAdd: parseFloat(
                                                    valuesCleanUp.discountAdd === "" ? 0 : valuesCleanUp.discountAdd
                                                ).toFixed(2),
                                            })
                                        }
                                        inputProps={{
                                            min: 0,
                                            max: 20,
                                        }}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                %
                                                <LightTooltip
                                                    title={
                                                        editMode2
                                                            ? t("services.cleanupMaximunDiscount")
                                                            : t("miscellaneous.save")
                                                    }
                                                    aria-label="edit"
                                                >
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        disabled={valuesCleanUp.updateBoolean}
                                                        onClick={handleClickShowDiscount2}
                                                        onMouseDown={handleMouseDownDiscount}
                                                        edge="end"
                                                    >
                                                        {!editMode2 ? <EditOffIcon /> : <EditIcon />}
                                                    </IconButton>
                                                </LightTooltip>
                                            </InputAdornment>
                                        }
                                        label={t("miscellaneous.discount")}
                                        sx={{
                                            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                                display: "none",
                                            },
                                        }}
                                    />
                                </FormControl>
                            </GridUI>
                            <GridUI item xs={12} sm={6} lg={3}>
                                <FormControl fullWidth variant="outlined" disabled>
                                    <InputLabel htmlFor="outlined-adornment-totalAdd">
                                        {t("clientsTable.totalAdd")}
                                    </InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-totalAdd"
                                        value={
                                            valuesCleanUp.addHourFee *
                                            getSumTotal() *
                                            (1 - valuesCleanUp.discountAdd / 100)
                                        }
                                        inputComponent={NumberFormatCustom}
                                        startAdornment={
                                            <InputAdornment disableTypography position="start">
                                                $
                                            </InputAdornment>
                                        }
                                        label={t("clientsTable.totalAdd")}
                                    />
                                </FormControl>
                            </GridUI>
                            <GridUI item xs={12} sm={6} lg={9}></GridUI>
                            <GridUI item xs={12} sm={6} lg={3}>
                                <FormControl fullWidth variant="outlined" disabled>
                                    <InputLabel htmlFor="outlined-adornment-feeTotal">
                                        {t("proposals.feeTotal")}
                                    </InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-feeTotal"
                                        value={
                                            valuesCleanUp.projecTot +
                                            valuesCleanUp.addHourFee *
                                                getSumTotal() *
                                                (1 - valuesCleanUp.discountAdd / 100)
                                        }
                                        inputComponent={NumberFormatCustom}
                                        startAdornment={
                                            <InputAdornment disableTypography position="start">
                                                $
                                            </InputAdornment>
                                        }
                                        label={t("proposals.feeTotal")}
                                    />
                                </FormControl>
                            </GridUI>
                        </GridUI>
                    </CardContent>
                </Card>
                <Card elevation={0}>
                    <CardHeader title={t("services.serviceResponsibility")} sx={{ color: "#ff5968" }} />
                    <Divider />
                    <CardContent>
                        <GridUI container alignItems="center" spacing={2}>
                            <GridUI item xs={12} sm={6} lg={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    disabled={valuesCleanUp.updateBoolean}
                                    value={valuesCleanUp.initDate}
                                    onChange={(newValue) => handleChangeInitDate(newValue)}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            required
                                            {...params}
                                            error={isError && !valuesCleanUp.initDate}
                                        />
                                    )}
                                    inputProps={{ readOnly: true }}
                                    label={t("reports.initDate")}
                                    views={["year", "month"]}
                                />
                            </GridUI>
                            <GridUI item xs={12} sm={6} lg={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    disabled={valuesCleanUp.updateBoolean}
                                    value={valuesCleanUp.endDate}
                                    onChange={(newValue) => handleChangeEndDate(newValue)}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            required
                                            {...params}
                                            error={isError && valuesCleanUp.endDate === null}
                                        />
                                    )}
                                    inputProps={{ readOnly: true }}
                                    label={t("reports.endDate")}
                                    views={["year", "month"]}
                                />
                            </GridUI>
                            <GridUI item xs={12} sm={6} lg={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    disabled={valuesCleanUp.updateBoolean}
                                    value={valuesCleanUp.estDeliDate}
                                    onChange={(newValue) => handleChangeEstDeliDate(newValue)}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            required
                                            {...params}
                                            error={isError && valuesCleanUp.estDeliDate === null}
                                        />
                                    )}
                                    inputProps={{ readOnly: true }}
                                    label={t("services.estimDeliveryDate")}
                                    views={["year", "month", "day"]}
                                />
                            </GridUI>
                            <GridUI item xs={12} sm={6} lg={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    disableFuture
                                    value={valuesCleanUp.deliDate}
                                    onChange={(newValue) => handleChangeDeliDate(newValue)}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => <TextField fullWidth {...params} />}
                                    inputProps={{ readOnly: true }}
                                    label={t("services.deliveryDate")}
                                    views={["year", "month", "day"]}
                                />
                            </GridUI>
                        </GridUI>
                    </CardContent>
                </Card>
                <UpdateAdd
                    values={valuesCleanUp}
                    actives={actives}
                    expenseEst={expenseEst}
                    accountSystem={accountSystem}
                    set={setCleanUp}
                    setTexto={setTexto}
                    setalertType={setalertType}
                    setShow={setShow}
                    clientMarket={clientMarket}
                    isError={isError}
                    updateProject={updateProject}
                    sumTotal={getSumTotal()}
                    originalData={data}
                    showConfirm={showConfirm}
                />
            </LocalizationProvider>
            <DialogAlerts
                open={open}
                onClose={handleCloseDialog}
                agreeBtnLabel={t("miscellaneous.delete")}
                disagreeBtnLabel="No"
                type={"info"}
                title={t("miscellaneous.confRemoveCheck")}
                message={bodyMess}
                agreeAction={removeCheck}
            />
        </StyledContainer>
    );
};
