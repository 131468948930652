/** React imports */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { NumericFormat } from "react-number-format";
import moment from "moment";
import PropTypes from "prop-types";

/** Icons/Images */
import { ReactComponent as AccountCircle } from "../../../assets/images/avatar-image.svg";

/** MUI icons */
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import { ReactComponent as CreditCardIcon } from "../../../assets/images/credit_card.svg";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import InsertChartOutlinedIcon from "@mui/icons-material/InsertChartOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import LockIcon from "@mui/icons-material/Lock";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

/** MUI imports */
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import {
    Box,
    Button,
    Card,
    CardHeader,
    CardContent,
    Checkbox,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Grid,
    InputAdornment,
    IconButton,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Stepper,
    Step,
    StepLabel,
    StepButton,
    Switch,
    Tabs,
    Tab,
    TextField,
    Typography,
} from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

/** Components imports */
import Countries from "../../Js/countries";
import DialogAlerts from "../DialogAlerts";
import EditFranq from "./EditFranq";
import MainCountries from "../../Js/mainCountries";
import Staff from "../../Js/staff";

/** Component styles */
const PREFIX = "NewFraq";

const classes = {
    avatarImage: `${PREFIX}-avatarImage`,
};

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 48 * 4.5 + 8,
            boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
        },
        sx: {
            borderRadius: 2,
            mt: 0.7,
        },
    },
};

const CustomDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(0),
    },
    "& .MuiDialogTitle-root": {
        backgroundColor: "#031851",
        fontSize: "18px",
        color: "#fff",
    },
    "& .MuiDialog-paper": {
        borderRadius: 8,
        boxShadow: "10px 10px 15px rgba(3,24,81,0.15)",
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(2),
    },

    [`& .${classes.avatarImage}`]: {
        display: "block",
        width: 65,
        height: 65,
        marginLeft: "auto",
        marginRight: "auto",
    },
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            style={{ width: "100%" }}
            {...other}
        >
            {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
        </div>
    );
}

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "rgba(19, 31, 62, 0.80)",
        fontSize: 12,
        maxWidth: 320,
        borderRadius: 6,
        fontWeight: "normal",
    },
});

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        "aria-controls": `vertical-tabpanel-${index}`,
    };
}

const ColorButton = styled(Button)(({ theme }) => ({
    border: "1px solid #ff5968",
    borderRadius: 40,
}));

const NumberFormatCustom = (props) => {
    const todos = useSelector((state) => state.todos);

    const { inputRef, value, onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            value={value}
            getInputRef={inputRef}
            onValueChange={({ value: v }) => onChange({ target: { value: v } })}
            thousandSeparator={todos.amountFormat === "de-DE" ? "." : ","}
            decimalSeparator={todos.amountFormat === "de-DE" ? "," : "."}
            fixedDecimalScale={true}
            decimalScale={2}
            valueIsNumericString
        />
    );
};

const teamCompanyDefault = {
    contact_name: "",
    contact_email: "",
    contact_role: "",
    contact_closure: false,
    contact_status: false,
    contact_requirements: false,
    contact_invoices: false,
};

const genDefault = {
    franchiseId: "",
    typeClient: 1,
    franqName: "",
    country: "",
    firmaDate: null,
    langEsc: "",
    langVer: "",
    user: "",
    password: "",
    showPassword: false,
    disabledData: false,
    endService: false,
    end_date: null,
    client_delivery_date: null,
};

export default function NewFraq(props) {
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    /** React redux */
    const todos = useSelector((state) => state.todos);

    /** Component states */
    const [activeStep, setActiveStep] = useState(0);
    const [isError, setisError] = useState(false);
    const [isError1, setisError1] = useState(false);
    const [isError2, setisError2] = useState(false);
    const [isError3, setisError3] = useState(false);
    const [isError4, setisError4] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [open, setOpen] = useState(false);
    const [dialogText, setDialogText] = useState("");
    const [dialogType, setDialogType] = useState("info");
    const [valuesGen, setValuesGen] = useState(genDefault);
    const [valuesFact, setValuesFact] = useState({
        contactGCA: "",
        contactFact: "",
        emailCont: "",
        phoneNumber: "",
        typeFact: 1,
        subsInv: 2,
    });
    const [valuesSettings, setValuesSettings] = useState({
        anticipatedInvoice: true,
        onboardingFee: true,
        platformFee: true,
        discountUpd: false,
        clientVip: false,
        manageReq: false,
        onboardingTariff: 499,
        discountOnboarding: 0,
        tariff: "",
        tariffAdditionals: "",
        monthlyStandardReportDate: 15,
        monthlyVipReportDate: 1,
        eeccReceptionDays: 2,
        updateType: 0,
        discount: 0,
        personalReports: false,
    });
    const [valueStaff, setValueStaff] = useState({
        teamCompany1: teamCompanyDefault,
        teamCompany2: teamCompanyDefault,
        teamCompany3: teamCompanyDefault,
        teamCompany4: teamCompanyDefault,
        supervisor: "Por asignar",
        manager: "Por asignar",
        advisor: "Por asignar",
        analyst: "Por asignar",
        backupAnalyst: "Por asignar",
    });
    const [valueTab, setValueTab] = useState(0);
    const [isReq, setIsReq] = useState(false);

    /** Component variables */
    const steps = [
        t("miscellaneous.generalData"),
        t("miscellaneous.invoicing"),
        t("miscellaneous.settings"),
        t("miscellaneous.staff"),
    ];
    const stepsEdit = [
        t("miscellaneous.generalData"),
        t("miscellaneous.invoicing"),
        t("miscellaneous.settings"),
        t("miscellaneous.staffClient"),
        t("miscellaneous.staffGCA"),
    ];

    let isRequired2 =
        valueStaff.teamCompany2.contact_name.trim() !== "" ||
        valueStaff.teamCompany2.contact_email.trim() !== "" ||
        valueStaff.teamCompany2.contact_role.trim() !== "" ||
        valueStaff.teamCompany2.contact_closure ||
        valueStaff.teamCompany2.contact_status ||
        valueStaff.teamCompany2.contact_requirements ||
        valueStaff.teamCompany2.contact_invoices;
    let isRequired3 =
        valueStaff.teamCompany3.contact_name.trim() !== "" ||
        valueStaff.teamCompany3.contact_email.trim() !== "" ||
        valueStaff.teamCompany3.contact_role.trim() !== "" ||
        valueStaff.teamCompany3.contact_closure ||
        valueStaff.teamCompany3.contact_status ||
        valueStaff.teamCompany3.contact_requirements ||
        valueStaff.teamCompany3.contact_invoices;
    let isRequired4 =
        valueStaff.teamCompany4.contact_name.trim() !== "" ||
        valueStaff.teamCompany4.contact_email.trim() !== "" ||
        valueStaff.teamCompany4.contact_role.trim() !== "" ||
        valueStaff.teamCompany4.contact_closure ||
        valueStaff.teamCompany4.contact_status ||
        valueStaff.teamCompany4.contact_requirements ||
        valueStaff.teamCompany4.contact_invoices;

    /** Component functions */
    const handleChangeTab = (event, newValue) => {
        handleReset();
        setValueTab(newValue);
    };

    const handleNext = async () => {
        if (activeStep === steps.length - 1) {
            props.setBackDrop(true);

            if (
                valuesGen.typeClient !== "" &&
                valuesGen.franqName.trim() !== "" &&
                valuesGen.country !== "" &&
                valuesGen.firmaDate !== null &&
                valuesGen.langEsc !== "" &&
                valuesGen.langVer !== "" &&
                ((valuesGen.user.trim() !== "" && valuesGen.password.trim() !== "") || valuesGen.disabledData)
            ) {
                if (
                    (valuesFact.typeFact !== "" &&
                        valuesFact.contactGCA.trim() !== "" &&
                        valuesFact.contactFact.trim() !== "" &&
                        valuesFact.emailCont.trim() !== "" &&
                        /.+@.+\..+/.test(valuesFact.emailCont) &&
                        valuesFact.subsInv !== "") ||
                    valuesFact.typeFact === 2
                ) {
                    if (
                        (valuesSettings.tariff !== "" &&
                            valuesSettings.tariffAdditionals !== "" &&
                            valuesSettings.monthlyStandardReportDate !== "" &&
                            valuesSettings.monthlyVipReportDate !== "") ||
                        (valuesSettings.tariff !== "" &&
                            valuesSettings.tariffAdditionals !== "" &&
                            valuesSettings.monthlyStandardReportDate !== "" &&
                            valuesSettings.monthlyVipReportDate !== "" &&
                            valuesSettings.clientVip &&
                            valuesSettings.monthlyVipReportDate !== "")
                    ) {
                        if (
                            valueStaff.teamCompany1.contact_name.trim() !== "" &&
                            valueStaff.teamCompany1.contact_email.trim() !== "" &&
                            /.+@.+\..+/.test(valueStaff.teamCompany1.contact_email) &&
                            valueStaff.teamCompany1.contact_role.trim() !== "" &&
                            valueStaff.supervisor !== "" &&
                            valueStaff.manager !== "" &&
                            valueStaff.advisor !== "" &&
                            valueStaff.analyst !== "" &&
                            valueStaff.backupAnalyst !== ""
                        ) {
                            if (
                                valueStaff.teamCompany1.contact_closure ||
                                valueStaff.teamCompany1.contact_status ||
                                valueStaff.teamCompany1.contact_requirements ||
                                valueStaff.teamCompany1.contact_invoices
                            ) {
                                let teamCompany = [];
                                teamCompany.push(valueStaff.teamCompany1);

                                if (isRequired2) {
                                    if (
                                        valueStaff.teamCompany2.contact_name.trim() !== "" &&
                                        valueStaff.teamCompany2.contact_email.trim() !== "" &&
                                        /.+@.+\..+/.test(valueStaff.teamCompany2.contact_email) &&
                                        valueStaff.teamCompany2.contact_role.trim() !== ""
                                    ) {
                                        if (
                                            valueStaff.teamCompany2.contact_closure ||
                                            valueStaff.teamCompany2.contact_status ||
                                            valueStaff.teamCompany2.contact_requirements ||
                                            valueStaff.teamCompany2.contact_invoices
                                        ) {
                                            teamCompany.push(valueStaff.teamCompany2);
                                        } else {
                                            handleCloseDialog();
                                            props.setTexto(t("miscellaneous.mustSelectIcon"));
                                            props.setalertType("warning");
                                            props.setShow(true);
                                            props.setBackDrop(false);
                                            setisError3(true);
                                            setisError2(false);
                                            setisError1(false);
                                            setisError(false);
                                            return;
                                        }
                                    } else {
                                        handleCloseDialog();
                                        props.setTexto(t("miscellaneous.requiredData"));
                                        props.setalertType("warning");
                                        props.setShow(true);
                                        props.setBackDrop(false);
                                        setisError3(true);
                                        setisError2(false);
                                        setisError1(false);
                                        setisError(false);
                                        return;
                                    }
                                }
                                if (isRequired3) {
                                    if (
                                        valueStaff.teamCompany3.contact_name.trim() !== "" &&
                                        valueStaff.teamCompany3.contact_email.trim() !== "" &&
                                        /.+@.+\..+/.test(valueStaff.teamCompany3.contact_email) &&
                                        valueStaff.teamCompany3.contact_role.trim() !== ""
                                    ) {
                                        if (
                                            valueStaff.teamCompany3.contact_closure ||
                                            valueStaff.teamCompany3.contact_status ||
                                            valueStaff.teamCompany3.contact_requirements ||
                                            valueStaff.teamCompany3.contact_invoices
                                        ) {
                                            teamCompany.push(valueStaff.teamCompany3);
                                        } else {
                                            handleCloseDialog();
                                            props.setTexto(t("miscellaneous.mustSelectIcon"));
                                            props.setalertType("warning");
                                            props.setShow(true);
                                            props.setBackDrop(false);
                                            setisError3(true);
                                            setisError2(false);
                                            setisError1(false);
                                            setisError(false);
                                            return;
                                        }
                                    } else {
                                        handleCloseDialog();
                                        props.setTexto(t("miscellaneous.requiredData"));
                                        props.setalertType("warning");
                                        props.setShow(true);
                                        props.setBackDrop(false);
                                        setisError3(true);
                                        setisError2(false);
                                        setisError1(false);
                                        setisError(false);
                                        return;
                                    }
                                }
                                if (isRequired4) {
                                    if (
                                        valueStaff.teamCompany4.contact_name.trim() !== "" &&
                                        valueStaff.teamCompany4.contact_email.trim() !== "" &&
                                        /.+@.+\..+/.test(valueStaff.teamCompany4.contact_email) &&
                                        valueStaff.teamCompany4.contact_role.trim() !== ""
                                    ) {
                                        if (
                                            valueStaff.teamCompany4.contact_closure ||
                                            valueStaff.teamCompany4.contact_status ||
                                            valueStaff.teamCompany4.contact_requirements ||
                                            valueStaff.teamCompany4.contact_invoices
                                        ) {
                                            teamCompany.push(valueStaff.teamCompany4);
                                        } else {
                                            handleCloseDialog();
                                            props.setTexto(t("miscellaneous.mustSelectIcon"));
                                            props.setalertType("warning");
                                            props.setShow(true);
                                            props.setBackDrop(false);
                                            setisError3(true);
                                            setisError2(false);
                                            setisError1(false);
                                            setisError(false);
                                            return;
                                        }
                                    } else {
                                        handleCloseDialog();
                                        props.setTexto(t("miscellaneous.requiredData"));
                                        props.setalertType("warning");
                                        props.setShow(true);
                                        props.setBackDrop(false);
                                        setisError3(true);
                                        setisError2(false);
                                        setisError1(false);
                                        setisError(false);
                                        return;
                                    }
                                }

                                const params = {
                                    id_franchise: valuesGen.franchiseId,
                                    franchise_name: valuesGen.franqName.trim(),
                                    country_name: valuesGen.country === "" ? undefined : valuesGen.country,
                                    client_type: valuesGen.typeClient,
                                    bsa_date: moment(valuesGen.firmaDate).format("DD/MM/YYYY"),
                                    typing_communication: valuesGen.langEsc === "" ? undefined : valuesGen.langEsc,
                                    verbal_communication: valuesGen.langVer === "" ? undefined : valuesGen.langVer,
                                    xero_master_user: valuesGen.user === "" ? undefined : valuesGen.user,
                                    xero_master_password: valuesGen.password === "" ? undefined : valuesGen.password,
                                    invoicing_contact_type: valuesFact.typeFact,
                                    invoicing_contact_name:
                                        valuesFact.contactGCA === "" ? undefined : valuesFact.contactGCA,
                                    invoicing_client_name:
                                        valuesFact.contactFact === "" ? undefined : valuesFact.contactFact,
                                    invoicing_client_email:
                                        valuesFact.emailCont === "" ? undefined : valuesFact.emailCont,
                                    invoicing_phone_number:
                                        valuesFact.phoneNumber === "" ? undefined : valuesFact.phoneNumber,
                                    anticipated_invoice: valuesSettings.anticipatedInvoice,
                                    invoice_onboarding: valuesSettings.onboardingFee,
                                    invoice_platform_fee: valuesSettings.platformFee,
                                    discount_updates: valuesSettings.discountUpd,
                                    client_vip: valuesSettings.clientVip,
                                    manage_requirements: valuesSettings.manageReq,
                                    discount_onboarding:
                                        valuesSettings.discountOnboarding === ""
                                            ? 0
                                            : parseFloat(valuesSettings.discountOnboarding),
                                    tariff: parseFloat(valuesSettings.tariff),
                                    tariff_additionals: parseFloat(valuesSettings.tariffAdditionals),
                                    monthly_standard_report_date: parseInt(valuesSettings.monthlyStandardReportDate),
                                    monthly_vip_report_date: parseInt(valuesSettings.monthlyVipReportDate),
                                    eecc_reception_days: parseInt(valuesSettings.eeccReceptionDays),
                                    discount: valuesSettings.discount === "" ? 0 : parseFloat(valuesSettings.discount),
                                    custom_reports: valuesSettings.personalReports,
                                    team_company: teamCompany,
                                    supervisor_name:
                                        valueStaff.supervisor === "" ? "Por asignar" : valueStaff.supervisor,
                                    manager_name: valueStaff.manager === "" ? "Por asignar" : valueStaff.manager,
                                    advisor_name: valueStaff.advisor === "" ? "Por asignar" : valueStaff.advisor,
                                    analyst_name: valueStaff.analyst === "" ? "Por asignar" : valueStaff.analyst,
                                    backup_analyst_name:
                                        valueStaff.backupAnalyst === "" ? "Por asignar" : valueStaff.backupAnalyst,
                                    invoicing_subscription: valuesFact.subsInv,
                                };

                                await fetch(`/addFranchise`, {
                                    method: "POST",
                                    headers: {
                                        "Content-type": "application/json; charset=UTF-8",
                                        "Access-Control-Allow-Origin": "*",
                                    },
                                    body: JSON.stringify(params),
                                })
                                    .then((response) => {
                                        if (response.ok) {
                                            handleCloseDialog();
                                            handleReset();
                                            props.setTexto(t("miscellaneous.franchiseCreation"));
                                            props.setalertType("success");
                                            props.setShow(true);
                                            props.setFranqData();
                                            props.setClientData();
                                            props.onClose();
                                        } else {
                                            handleCloseDialog();
                                            props.setTexto(t("miscellaneous.existFranchise"));
                                            props.setalertType("error");
                                            props.setShow(true);
                                        }
                                    })
                                    .catch((error) => {
                                        console.log(error);
                                    });
                            } else {
                                handleCloseDialog();
                                props.setTexto(t("miscellaneous.mustSelectIcon"));
                                props.setalertType("warning");
                                props.setShow(true);
                                props.setBackDrop(false);
                                setisError3(true);
                                setisError2(false);
                                setisError1(false);
                                setisError(false);
                                return;
                            }
                        } else {
                            handleCloseDialog();
                            props.setTexto(t("miscellaneous.requiredData"));
                            props.setalertType("warning");
                            props.setShow(true);
                            setisError3(true);
                            setisError2(false);
                            setisError1(false);
                            setisError(false);
                        }
                    } else {
                        handleCloseDialog();
                        props.setTexto(t("miscellaneous.requiredData"));
                        props.setalertType("warning");
                        props.setShow(true);
                        setisError2(true);
                        setisError1(false);
                        setisError(false);
                    }
                } else {
                    handleCloseDialog();
                    props.setTexto(t("miscellaneous.requiredData"));
                    props.setalertType("warning");
                    props.setShow(true);
                    setisError1(true);
                    setisError(false);
                }
            } else {
                handleCloseDialog();
                props.setTexto(t("miscellaneous.requiredData"));
                props.setalertType("warning");
                props.setShow(true);
                setisError(true);
            }
            props.setBackDrop(false);
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const resetErrors = () => {
        setisError(false);
        setisError1(false);
        setisError2(false);
        setisError3(false);
        setShowEdit(true);
    };

    const updateFranq = async () => {
        props.setBackDrop(true);

        let isValid = false;
        let teamCompany = [];

        switch (activeStep) {
            case 0:
                let checkReq = false;
                if (
                    valuesGen.typeClient &&
                    valuesGen.franqName.trim() &&
                    valuesGen.country &&
                    valuesGen.firmaDate !== null &&
                    valuesGen.langEsc &&
                    valuesGen.langVer &&
                    ((valuesGen.user.trim() && valuesGen.password.trim()) || valuesGen.disabledData)
                ) {
                    if(valuesGen.endService && valuesGen.end_date && valuesGen.client_delivery_date){
                        isValid = true;
                        props.setTexto(t("miscellaneous.successfulGenDataFranchise"));
                    }
                    else if(!valuesGen.endService){
                        isValid = true;
                        props.setTexto(t("miscellaneous.successfulGenDataFranchise"));
                    }
                    else {
                        checkReq = true;
                    }
                } else {
                    checkReq = true;
                }
                if(checkReq){
                    handleCloseDialog();
                    props.setTexto(t("miscellaneous.requiredData"));
                    props.setalertType("warning");
                    props.setShow(true);
                    setisError4(false);
                    setisError3(false);
                    setisError2(false);
                    setisError1(false);
                    setisError(true); 
                }
                break;
            case 1:
                if (
                    (valuesFact.typeFact !== "" &&
                        valuesFact.contactGCA.trim() !== "" &&
                        valuesFact.contactFact.trim() !== "" &&
                        valuesFact.emailCont.trim() !== "" &&
                        /.+@.+\..+/.test(valuesFact.emailCont)) ||
                    valuesFact.typeFact === 2
                ) {
                    isValid = true;
                    props.setTexto(t("miscellaneous.successfulInvoicingFranchise"));
                } else {
                    handleCloseDialog();
                    props.setTexto(t("miscellaneous.requiredData"));
                    props.setalertType("warning");
                    props.setShow(true);
                    setisError4(false);
                    setisError3(false);
                    setisError2(false);
                    setisError1(true);
                    setisError(false);
                }
                break;
            case 2:
                if (
                    (valuesSettings.tariff !== "" &&
                        valuesSettings.tariffAdditionals !== "" &&
                        valuesSettings.monthlyStandardReportDate !== "" &&
                        valuesSettings.monthlyVipReportDate !== "") ||
                    (valuesSettings.tariff !== "" &&
                        valuesSettings.tariffAdditionals !== "" &&
                        valuesSettings.monthlyStandardReportDate !== "" &&
                        valuesSettings.monthlyVipReportDate !== "" &&
                        valuesSettings.clientVip &&
                        valuesSettings.monthlyVipReportDate !== "")
                ) {
                    isValid = true;
                    props.setTexto(t("miscellaneous.successfulSettingsFranchise"));
                } else {
                    handleCloseDialog();
                    props.setTexto(t("miscellaneous.requiredData"));
                    props.setalertType("warning");
                    props.setShow(true);
                    setisError4(false);
                    setisError3(false);
                    setisError2(true);
                    setisError1(false);
                    setisError(false);
                }
                break;
            case 3:
                if (
                    valueStaff.teamCompany1.contact_name.trim() !== "" &&
                    valueStaff.teamCompany1.contact_email.trim() !== "" &&
                    /.+@.+\..+/.test(valueStaff.teamCompany1.contact_email) &&
                    valueStaff.teamCompany1.contact_role.trim() !== ""
                ) {
                    if (
                        valueStaff.teamCompany1.contact_closure ||
                        valueStaff.teamCompany1.contact_status ||
                        valueStaff.teamCompany1.contact_requirements ||
                        valueStaff.teamCompany1.contact_invoices
                    ) {
                        teamCompany.push(valueStaff.teamCompany1);

                        if (isRequired2) {
                            if (
                                valueStaff.teamCompany2.contact_name.trim() !== "" &&
                                valueStaff.teamCompany2.contact_email.trim() !== "" &&
                                /.+@.+\..+/.test(valueStaff.teamCompany2.contact_email) &&
                                valueStaff.teamCompany2.contact_role.trim() !== ""
                            ) {
                                if (
                                    valueStaff.teamCompany2.contact_closure ||
                                    valueStaff.teamCompany2.contact_status ||
                                    valueStaff.teamCompany2.contact_requirements ||
                                    valueStaff.teamCompany2.contact_invoices
                                ) {
                                    teamCompany.push(valueStaff.teamCompany2);
                                } else {
                                    handleCloseDialog();
                                    props.setTexto(t("miscellaneous.mustSelectIcon"));
                                    props.setalertType("warning");
                                    props.setShow(true);
                                    props.setBackDrop(false);
                                    setisError4(false);
                                    setisError3(true);
                                    setisError2(false);
                                    setisError1(false);
                                    setisError(false);
                                    return;
                                }
                            } else {
                                handleCloseDialog();
                                props.setTexto(t("miscellaneous.requiredData"));
                                props.setalertType("warning");
                                props.setShow(true);
                                props.setBackDrop(false);
                                setisError4(false);
                                setisError3(true);
                                setisError2(false);
                                setisError1(false);
                                setisError(false);
                                return;
                            }
                        }
                        if (isRequired3) {
                            if (
                                valueStaff.teamCompany3.contact_name.trim() !== "" &&
                                valueStaff.teamCompany3.contact_email.trim() !== "" &&
                                /.+@.+\..+/.test(valueStaff.teamCompany3.contact_email) &&
                                valueStaff.teamCompany3.contact_role.trim() !== ""
                            ) {
                                if (
                                    valueStaff.teamCompany3.contact_closure ||
                                    valueStaff.teamCompany3.contact_status ||
                                    valueStaff.teamCompany3.contact_requirements ||
                                    valueStaff.teamCompany3.contact_invoices
                                ) {
                                    teamCompany.push(valueStaff.teamCompany3);
                                } else {
                                    handleCloseDialog();
                                    props.setTexto(t("miscellaneous.mustSelectIcon"));
                                    props.setalertType("warning");
                                    props.setShow(true);
                                    props.setBackDrop(false);
                                    setisError4(false);
                                    setisError3(true);
                                    setisError2(false);
                                    setisError1(false);
                                    setisError(false);
                                    return;
                                }
                            } else {
                                handleCloseDialog();
                                props.setTexto(t("miscellaneous.requiredData"));
                                props.setalertType("warning");
                                props.setShow(true);
                                props.setBackDrop(false);
                                setisError4(false);
                                setisError3(true);
                                setisError2(false);
                                setisError1(false);
                                setisError(false);
                                return;
                            }
                        }
                        if (isRequired4) {
                            if (
                                valueStaff.teamCompany4.contact_name.trim() !== "" &&
                                valueStaff.teamCompany4.contact_email.trim() !== "" &&
                                /.+@.+\..+/.test(valueStaff.teamCompany4.contact_email) &&
                                valueStaff.teamCompany4.contact_role.trim() !== ""
                            ) {
                                if (
                                    valueStaff.teamCompany4.contact_closure ||
                                    valueStaff.teamCompany4.contact_status ||
                                    valueStaff.teamCompany4.contact_requirements ||
                                    valueStaff.teamCompany4.contact_invoices
                                ) {
                                    teamCompany.push(valueStaff.teamCompany4);
                                } else {
                                    handleCloseDialog();
                                    props.setTexto(t("miscellaneous.mustSelectIcon"));
                                    props.setalertType("warning");
                                    props.setShow(true);
                                    props.setBackDrop(false);
                                    setisError4(false);
                                    setisError3(true);
                                    setisError2(false);
                                    setisError1(false);
                                    setisError(false);
                                    return;
                                }
                            } else {
                                handleCloseDialog();
                                props.setTexto(t("miscellaneous.requiredData"));
                                props.setalertType("warning");
                                props.setShow(true);
                                props.setBackDrop(false);
                                setisError4(false);
                                setisError3(true);
                                setisError2(false);
                                setisError1(false);
                                setisError(false);
                                return;
                            }
                        }

                        isValid = true;
                        props.setTexto(t("miscellaneous.successfulStaffFranchise"));
                    } else {
                        handleCloseDialog();
                        props.setTexto(t("miscellaneous.mustSelectIcon"));
                        props.setalertType("warning");
                        props.setShow(true);
                        props.setBackDrop(false);
                        setisError4(false);
                        setisError3(true);
                        setisError2(false);
                        setisError1(false);
                        setisError(false);
                        return;
                    }
                } else {
                    handleCloseDialog();
                    props.setTexto(t("miscellaneous.requiredData"));
                    props.setalertType("warning");
                    props.setShow(true);
                    setisError4(false);
                    setisError3(true);
                    setisError2(false);
                    setisError1(false);
                    setisError(false);
                }
                break;
            default:
                if (
                    valueStaff.supervisor &&
                    valueStaff.manager &&
                    valueStaff.advisor &&
                    valueStaff.analyst &&
                    valueStaff.backupAnalyst
                ) {
                    isValid = true;
                    props.setTexto(t("miscellaneous.successfulStaffFranchise"));
                } else {
                    handleCloseDialog();
                    props.setTexto(t("miscellaneous.requiredData"));
                    props.setalertType("warning");
                    props.setShow(true);
                    setisError4(true);
                    setisError3(false);
                    setisError2(false);
                    setisError1(false);
                    setisError(false);
                }
                break;
        }

        if (isValid) {
            const params = {
                id_franchise: valuesGen.franchiseId,
                franchise_name: valuesGen.franqName.trim(),
                country_name: valuesGen.country === "" ? undefined : valuesGen.country,
                client_type: valuesGen.typeClient,
                bsa_date: moment(valuesGen.firmaDate).format("YYYY-MM-DD"),
                typing_communication: valuesGen.langEsc === "" ? undefined : valuesGen.langEsc,
                verbal_communication: valuesGen.langVer === "" ? undefined : valuesGen.langVer,
                xero_master_user: valuesGen.user === "" ? undefined : valuesGen.user,
                xero_master_password: valuesGen.password === "" ? undefined : valuesGen.password,
                invoicing_contact_type: valuesFact.typeFact,
                invoicing_contact_name: valuesFact.contactGCA === "" ? undefined : valuesFact.contactGCA,
                invoicing_client_name: valuesFact.contactFact === "" ? undefined : valuesFact.contactFact,
                invoicing_client_email: valuesFact.emailCont === "" ? undefined : valuesFact.emailCont,
                invoicing_phone_number: valuesFact.phoneNumber === "" ? undefined : valuesFact.phoneNumber,
                anticipated_invoice: valuesSettings.anticipatedInvoice,
                invoice_onboarding: valuesSettings.onboardingFee,
                invoice_platform_fee: valuesSettings.platformFee,
                discount_updates: valuesSettings.discountUpd,
                client_vip: valuesSettings.clientVip,
                manage_requirements: valuesSettings.manageReq,
                discount_onboarding:
                    valuesSettings.discountOnboarding === "" ? 0 : parseFloat(valuesSettings.discountOnboarding),
                tariff: parseFloat(valuesSettings.tariff),
                tariff_additionals: parseFloat(valuesSettings.tariffAdditionals),
                monthly_standard_report_date: parseInt(valuesSettings.monthlyStandardReportDate),
                monthly_vip_report_date: parseInt(valuesSettings.monthlyVipReportDate),
                eecc_reception_days: parseInt(valuesSettings.eeccReceptionDays),
                discount: valuesSettings.discount === "" ? 0 : parseFloat(valuesSettings.discount),
                custom_reports: valuesSettings.personalReports,
                team_company: teamCompany,
                supervisor_name: valueStaff.supervisor === "" ? "Por asignar" : valueStaff.supervisor,
                manager_name: valueStaff.manager === "" ? "Por asignar" : valueStaff.manager,
                advisor_name: valueStaff.advisor === "" ? "Por asignar" : valueStaff.advisor,
                analyst_name: valueStaff.analyst === "" ? "Por asignar" : valueStaff.analyst,
                backup_analyst_name: valueStaff.backupAnalyst === "" ? "Por asignar" : valueStaff.backupAnalyst,
                update_type: activeStep,
                invoicing_subscription: valuesFact.subsInv,
                end_date: valuesGen.endService ? valuesGen.end_date.format("YYYY-MM-DD") : null,
                client_delivery_date: valuesGen.endService ? valuesGen.client_delivery_date.format("YYYY-MM-DD") : null
            };

            await fetch(`/updateFranchise`, {
                method: "POST",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
                body: JSON.stringify(params),
            })
                .then((response) => {
                    if (response.ok) {
                        handleCloseDialog();
                        props.setalertType("success");
                        props.setShow(true);
                        props.setFranqData();
                        props.setClientData();
                        resetErrors();
                        getTeam();
                    } else {
                        handleCloseDialog();
                        props.setTexto(t("miscellaneous.updatingFranchiseError"));
                        props.setalertType("error");
                        props.setShow(true);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }

        props.setBackDrop(false);
    };

    const getTeam = async () => {
        await fetch(`/getFranchiseDetails?franchise_id=${valuesGen.franchiseId}`, {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then((res) => res.json())
            .then((data) => {
                setValueStaff({
                    ...valueStaff,
                    supervisorOG: data.supervisor_name ?? "Por asignar",
                    managerOG: data.manager_name ?? "Por asignar",
                    advisorOG: data.advisor_name ?? "Por asignar",
                    analystOG: data.analyst_name ?? "Por asignar",
                    backupAnalystOG: data.backup_analyst_name ?? "Por asignar",
                    supervisor: data.supervisor_name ?? "Por asignar",
                    manager: data.manager_name ?? "Por asignar",
                    analyst: data.analyst_name ?? "Por asignar",
                    backupAnalyst: data.backup_analyst_name ?? "Por asignar",
                    advisor: data.advisor_name ?? "Por asignar",
                    supervisorReq: data.supervisor_name_request,
                    managerReq: data.manager_name_request,
                    advisorReq: data.advisor_name_request,
                    analystReq: data.analyst_name_request,
                    backupAnalystReq: data.backup_analyst_name_request,
                });

                if (
                    data.supervisor_name_request ||
                    data.manager_name_request ||
                    data.analyst_name_request ||
                    data.backup_analyst_name_request ||
                    data.advisor_name_request
                ) {
                    setIsReq(true);
                }
            });
    };

    const sortCountries = () => {
        if (moment.locale() === "en") {
            Countries.sort((a, b) => {
                if (a.name_en > b.name_en) return 1;

                if (a.name_en < b.name_en) return -1;

                return 0;
            });
        } else {
            Countries.sort((a, b) => {
                if (a.name_es > b.name_es) return 1;

                if (a.name_es < b.name_es) return -1;

                return 0;
            });
        }
    };

    const handleBack = () => {
        if (activeStep === 0) {
            handleReset();
            props.onClose();
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }
    };

    const handleStep = (step) => {
        setActiveStep(step);
    };

    const handleReset = () => {
        setValueTab(0);
        setActiveStep(0);
        setShowEdit(false);
        setisError(false);
        setisError1(false);
        setisError2(false);
        setisError3(false);
        setisError4(false);
        setValuesGen(genDefault);
        setValuesFact({
            ...valuesFact,
            contactGCA: "",
            contactFact: "",
            emailCont: "",
            phoneNumber: "",
            typeFact: 1,
        });
        setValuesSettings({
            ...valuesSettings,
            anticipatedInvoice: true,
            onboardingFee: true,
            platformFee: true,
            discountUpd: false,
            clientVip: false,
            manageReq: false,
            onboardingTariff: 499,
            discountOnboarding: 0,
            tariff: "",
            tariffAdditionals: "",
            monthlyStandardReportDate: 15,
            monthlyVipReportDate: 1,
            eeccReceptionDays: 2,
            discount: 0,
            personalReports: false,
        });
        setValueStaff({
            ...valueStaff,
            teamCompany1: teamCompanyDefault,
            teamCompany2: teamCompanyDefault,
            teamCompany3: teamCompanyDefault,
            teamCompany4: teamCompanyDefault,
            supervisor: "Por asignar",
            manager: "Por asignar",
            advisor: "Por asignar",
            analyst: "Por asignar",
            backupAnalyst: "Por asignar",
        });
    };

    const handleChangeSwitch = (event) => {
        setValuesGen({
            ...valuesGen,
            disabledData: event.target.checked,
        });
    };

    const handleOpenDialog = () => {
        if (valueTab === 0) {
            setDialogType("info");
            setDialogText(t("miscellaneous.franchiseSureCreation"));
            setOpen(true);
        } else {
            setDialogType("info");
            setDialogText(t("miscellaneous.registeredChangesFranchise"));
            setOpen(true);
        }
    };

    const handleCloseDialog = () => {
        setDialogText("");
        setDialogType("");
        setOpen(false);
    };

    const handleChange = (event) => {
        setValuesGen({
            ...valuesGen,
            [event.target.name]: event.target.value,
        });
    };

    const handleChangeFirmaDate = (date) => {
        setValuesGen({
            ...valuesGen,
            firmaDate: date,
        });
    };

    const handleClickShowPassword = () => {
        setValuesGen({
            ...valuesGen,
            showPassword: !valuesGen.showPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleChangeFact = (event) => {
        setValuesFact({
            ...valuesFact,
            [event.target.name]: event.target.value,
        });
    };

    const handleChangeTypeFact = (event) => {
        if (event.target.value === 2) {
            setValuesFact({
                ...valuesFact,
                contactGCA: "",
                contactFact: "",
                emailCont: "",
                phoneNumber: "",
                [event.target.name]: event.target.value,
            });
        } else {
            setValuesFact({
                ...valuesFact,
                [event.target.name]: event.target.value,
            });
        }
    };

    const handleChangePhone = (event) => {
        const regex = /^\+[0-9\s.()-]{0,20}$/;
        if (event.target.value === "" || regex.test(event.target.value)) {
            setValuesFact({
                ...valuesFact,
                [event.target.name]: event.target.value,
            });
        }
    };

    const handleChangeSettings = (event) => {
        setValuesSettings({
            ...valuesSettings,
            [event.target.name]: event.target.value,
        });
    };

    const handleChangeSwitchSettings = (event) => {
        setValuesSettings({
            ...valuesSettings,
            anticipatedInvoice: event.target.checked,
        });
    };

    const handleChangeCheckPlatformFee = (event) => {
        setValuesSettings({
            ...valuesSettings,
            platformFee: event.target.checked,
        });
    };

    // const handleChangeCheckDiscountUpd = (event) => {
    //     setValuesSettings({
    //         ...valuesSettings,
    //         discountUpd: event.target.checked,
    //     });
    // };

    const handleChangeCheckPersonalRep = (event) => {
        setValuesSettings({
            ...valuesSettings,
            personalReports: event.target.checked,
        });
    };

    const handleChangeCheckManageReq = (event) => {
        setValuesSettings({
            ...valuesSettings,
            manageReq: event.target.checked,
        });
    };

    const handleChangeCheckOnbardingFee = (event) => {
        if (event.target.checked) {
            setValuesSettings({
                ...valuesSettings,
                onboardingFee: event.target.checked,
            });
        } else {
            setValuesSettings({
                ...valuesSettings,
                onboardingFee: event.target.checked,
                discountOnboarding: 0,
            });
        }
    };

    const handleChangeCheckClientVip = (event) => {
        if (event.target.checked) {
            setValuesSettings({
                ...valuesSettings,
                clientVip: event.target.checked,
            });
        } else {
            setValuesSettings({
                ...valuesSettings,
                clientVip: event.target.checked,
                monthlyVipReportDate: 0,
            });
        }
    };

    const handleChangeDiscount = (event) => {
        if (event.target.value >= 0 && event.target.value <= 100) {
            setValuesSettings({
                ...valuesSettings,
                [event.target.name]: event.target.value,
            });
        } else {
            setValuesSettings({
                ...valuesSettings,
                [event.target.name]: 0,
            });
            props.setTexto(t("services.discountPerMsg"));
            props.setalertType("warning");
            props.setShow(true);
        }
    };

    const handleChangeMonthlyReports = (event) => {
        if (event.target.value >= 1 && event.target.value <= 25) {
            setValuesSettings({
                ...valuesSettings,
                [event.target.name]: event.target.value,
            });
        } else {
            setValuesSettings({
                ...valuesSettings,
                [event.target.name]: "",
            });
            props.setTexto(t("services.monthlyReportsMsg"));
            props.setalertType("warning");
            props.setShow(true);
        }
    };

    const handleChangeReceptionDays = (event) => {
        if (event.target.value >= 1 && event.target.value <= 15) {
            setValuesSettings({
                ...valuesSettings,
                [event.target.name]: event.target.value,
            });
        } else {
            setValuesSettings({
                ...valuesSettings,
                [event.target.name]: "",
            });
            props.setTexto(t("services.receptionDaysMsg"));
            props.setalertType("warning");
            props.setShow(true);
        }
    };

    const handleChangeStaff = (event) => {
        setValueStaff({
            ...valueStaff,
            [event.target.name]: event.target.value,
        });
    };

    const handleChangeTeamStaff = (event, team) => {
        setValueStaff({
            ...valueStaff,
            [team]: {
                ...valueStaff[team],
                [event.target.name]: event.target.value,
            },
        });
    };

    const handleChangeCheck = (event, value, team) => {
        switch (value) {
            case 0:
                setValueStaff({
                    ...valueStaff,
                    [team]: {
                        ...valueStaff[team],
                        contact_closure: event.target.checked,
                    },
                });
                break;
            case 1:
                setValueStaff({
                    ...valueStaff,
                    [team]: {
                        ...valueStaff[team],
                        contact_status: event.target.checked,
                    },
                });
                break;
            case 4:
                setValueStaff({
                    ...valueStaff,
                    [team]: {
                        ...valueStaff[team],
                        contact_invoices: event.target.checked,
                    },
                });
                break;
            default:
                setValueStaff({
                    ...valueStaff,
                    [team]: {
                        ...valueStaff[team],
                        contact_requirements: event.target.checked,
                    },
                });
                break;
        }
    };

    const clearTeamStaff = (event, team) => {
        setValueStaff({
            ...valueStaff,
            [team]: teamCompanyDefault,
        });
    };

    /** Component events */
    useEffect(() => {
        sortCountries();
    }, [t]);

    return (
        <CustomDialog
            open={props.open}
            onClose={() => {
                handleReset();
                props.onClose();
            }}
            aria-labelledby="customized-dialog-title"
            fullWidth={true}
            maxWidth="lg"
        >
            <DialogTitle id="alert-dialog-title">
                {valueTab === 0 ? t("miscellaneous.newFranchise") : t("miscellaneous.updateFranchise")}
            </DialogTitle>
            <DialogContent dividers>
                <Box
                    sx={{
                        flexGrow: 1,
                        display: window.innerWidth < 600 ? "block" : "flex",
                    }}
                >
                    <Tabs
                        orientation={window.innerWidth < 600 ? "horizontal" : "vertical"}
                        variant="scrollable"
                        value={valueTab}
                        onChange={handleChangeTab}
                        indicatorColor="secondary"
                        aria-label="Vertical tabs example"
                        sx={{
                            borderBottom: window.innerWidth < 600 ? 1 : 0,
                            borderRight: window.innerWidth < 600 ? 0 : 1,
                            borderColor: "divider",
                        }}
                    >
                        <Tab label={t("miscellaneous.new")} {...a11yProps(0)} />
                        <Tab label={t("miscellaneous.edit")} {...a11yProps(1)} />
                    </Tabs>
                    <TabPanel value={valueTab} index={0}>
                        <Box sx={{ width: "100%", p: 4 }}>
                            <Stepper
                                nonLinear
                                activeStep={activeStep}
                                orientation={window.innerWidth < 600 ? "vertical" : "horizontal"}
                            >
                                {steps.map((label, index) => {
                                    const labelProps = {};
                                    if (
                                        (isError && index === 0) ||
                                        (isError1 && index === 1) ||
                                        (isError2 && index === 2) ||
                                        (isError3 && index === 3)
                                    ) {
                                        labelProps.optional = (
                                            <Typography variant="caption" color="error">
                                                {t("miscellaneous.required")}
                                            </Typography>
                                        );

                                        labelProps.error = true;
                                    }
                                    return (
                                        <Step key={label}>
                                            <StepButton color="inherit" onClick={() => handleStep(index)}>
                                                <StepLabel {...labelProps}>{label}</StepLabel>
                                            </StepButton>
                                        </Step>
                                    );
                                })}
                            </Stepper>
                        </Box>
                        {activeStep === 0 && (
                            <>
                                <Card elevation={0}>
                                    <CardContent>
                                        <Grid container spacing={2}>
                                            <Grid item lg={2} sm={6} xs={12}>
                                                <FormControl
                                                    fullWidth
                                                    required
                                                    error={isError && valuesGen.typeClient === "" ? true : false}
                                                >
                                                    <InputLabel id="type-simple-select-label">
                                                        {t("miscellaneous.type")}
                                                    </InputLabel>
                                                    <Select
                                                        labelId="type-simple-select-label"
                                                        name="typeClient"
                                                        onChange={handleChange}
                                                        value={valuesGen.typeClient}
                                                        IconComponent={KeyboardArrowDownIcon}
                                                        label={t("miscellaneous.type")}
                                                        MenuProps={{
                                                            PaperProps: {
                                                                sx: {
                                                                    mt: 0.7,
                                                                    borderRadius: 2,
                                                                    boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                                },
                                                            },
                                                        }}
                                                    >
                                                        <MenuItem value={1}>{t("miscellaneous.firm")}</MenuItem>
                                                        <MenuItem value={2}>SMB</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item lg={5} sm={6} xs={12}>
                                                <TextField
                                                    fullWidth
                                                    required
                                                    label={t("miscellaneous.franchiseName")}
                                                    name="franqName"
                                                    onChange={handleChange}
                                                    value={valuesGen.franqName}
                                                    variant="outlined"
                                                    error={isError && valuesGen.franqName.trim() === "" ? true : false}
                                                />
                                            </Grid>
                                            <Grid item lg={5} sm={6} xs={12}>
                                                <FormControl
                                                    fullWidth
                                                    required
                                                    error={isError && valuesGen.country === "" ? true : false}
                                                >
                                                    <InputLabel id="country-simple-select-label">
                                                        {t("miscellaneous.country")}
                                                    </InputLabel>
                                                    <Select
                                                        name="country"
                                                        labelId="country-simple-select-label"
                                                        id="demo-simple-select"
                                                        IconComponent={KeyboardArrowDownIcon}
                                                        value={valuesGen.country}
                                                        label={t("miscellaneous.country")}
                                                        onChange={handleChange}
                                                        MenuProps={MenuProps}
                                                    >
                                                        {MainCountries.map((option) => (
                                                            <MenuItem value={option.code}>
                                                                {moment.locale() === "en"
                                                                    ? option.name_en
                                                                    : option.name_es}
                                                            </MenuItem>
                                                        ))}
                                                        <Divider />
                                                        {Countries.map((option) => (
                                                            <MenuItem value={option.code}>
                                                                {moment.locale() === "en"
                                                                    ? option.name_en
                                                                    : option.name_es}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <LocalizationProvider
                                                dateAdapter={AdapterMoment}
                                                adapterLocale={t("language.locale")}
                                                localeText={{
                                                    clearButtonLabel: t("miscellaneous.clear"),
                                                }}
                                            >
                                                <Grid item lg={3} sm={6} xs={12}>
                                                    <DatePicker
                                                        componentsProps={{
                                                            actionBar: {
                                                                actions: ["clear"],
                                                            },
                                                        }}
                                                        value={valuesGen.firmaDate}
                                                        onChange={(newValue) => handleChangeFirmaDate(newValue)}
                                                        inputFormat={todos.dateFormat}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                fullWidth
                                                                required
                                                                {...params}
                                                                error={
                                                                    isError && valuesGen.firmaDate === null
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                        )}
                                                        inputProps={{
                                                            readOnly: true,
                                                        }}
                                                        label={t("miscellaneous.firmDate")}
                                                    />
                                                </Grid>
                                            </LocalizationProvider>
                                            <Grid item lg={3} sm={6} xs={12}>
                                                <FormControl
                                                    fullWidth
                                                    required
                                                    error={isError && valuesGen.langVer === "" ? true : false}
                                                >
                                                    <InputLabel id="ver-simple-select-label">
                                                        {t("miscellaneous.verbalCommunication")}
                                                    </InputLabel>
                                                    <Select
                                                        name="langVer"
                                                        labelId="ver-simple-select-label"
                                                        IconComponent={KeyboardArrowDownIcon}
                                                        value={valuesGen.langVer}
                                                        label={t("miscellaneous.verbalCommunication")}
                                                        onChange={handleChange}
                                                        MenuProps={{
                                                            PaperProps: {
                                                                sx: {
                                                                    mt: 0.7,
                                                                    borderRadius: 2,
                                                                    boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                                },
                                                            },
                                                        }}
                                                    >
                                                        <MenuItem value={1}>{t("language.english")}</MenuItem>
                                                        <MenuItem value={2}>{t("language.spanish")}</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item lg={3} sm={6} xs={12}>
                                                <FormControl
                                                    fullWidth
                                                    required
                                                    error={isError && valuesGen.langEsc === "" ? true : false}
                                                >
                                                    <InputLabel id="esc-simple-select-label">
                                                        {t("miscellaneous.writtenCommunication")}
                                                    </InputLabel>
                                                    <Select
                                                        name="langEsc"
                                                        labelId="esc-simple-select-label"
                                                        IconComponent={KeyboardArrowDownIcon}
                                                        value={valuesGen.langEsc}
                                                        label={t("miscellaneous.writtenCommunication")}
                                                        onChange={handleChange}
                                                        MenuProps={{
                                                            PaperProps: {
                                                                sx: {
                                                                    mt: 0.7,
                                                                    borderRadius: 2,
                                                                    boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                                },
                                                            },
                                                        }}
                                                    >
                                                        <MenuItem value={1}>{t("language.english")}</MenuItem>
                                                        <MenuItem value={2}>{t("language.spanish")}</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                                <Card elevation={0}>
                                    <CardHeader
                                        title={
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                }}
                                            >
                                                <Box>
                                                    <Typography variant="h1" sx={{ fontWeight: 700 }} color="secondary">
                                                        {t("miscellaneous.accountingSystemAccess")}
                                                    </Typography>
                                                </Box>
                                                <Box>
                                                    <FormControlLabel
                                                        inputProps={{
                                                            readOnly: true,
                                                        }}
                                                        control={
                                                            <Switch
                                                                checked={valuesGen.disabledData}
                                                                inputProps={{
                                                                    "aria-label": "controlled",
                                                                }}
                                                                color={"secondary"}
                                                                onChange={handleChangeSwitch}
                                                            />
                                                        }
                                                        label={
                                                            <Typography variant="body1" color="textPrimary">
                                                                {t("miscellaneous.notApply")}
                                                            </Typography>
                                                        }
                                                    />
                                                </Box>
                                            </Box>
                                        }
                                        sx={{ color: "#ff5968" }}
                                    />
                                    <Divider />
                                    <CardContent>
                                        <Grid container spacing={2}>
                                            <Grid item lg={6} sm={6} xs={12}>
                                                <TextField
                                                    fullWidth
                                                    label={t("login.userAccess")}
                                                    name="user"
                                                    onChange={handleChange}
                                                    value={valuesGen.user}
                                                    variant="outlined"
                                                    disabled={valuesGen.disabledData}
                                                    required={!valuesGen.disabledData}
                                                    error={isError && valuesGen.user.trim() === "" ? true : false}
                                                />
                                            </Grid>
                                            <Grid item lg={6} sm={6} xs={12}>
                                                <FormControl
                                                    fullWidth
                                                    disabled={valuesGen.disabledData}
                                                    required={!valuesGen.disabledData}
                                                    variant="outlined"
                                                    error={isError && valuesGen.password.trim() === "" ? true : false}
                                                >
                                                    <InputLabel htmlFor="outlined-adornment-password">
                                                        {t("login.password")}
                                                    </InputLabel>
                                                    <OutlinedInput
                                                        id="outlined-adornment-password"
                                                        fullWidth
                                                        type={valuesGen.showPassword ? "text" : "password"}
                                                        label={t("login.password")}
                                                        name="password"
                                                        onChange={handleChange}
                                                        value={valuesGen.password}
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={handleClickShowPassword}
                                                                    onMouseDown={handleMouseDownPassword}
                                                                    edge="end"
                                                                >
                                                                    {valuesGen.showPassword ? (
                                                                        <VisibilityOff />
                                                                    ) : (
                                                                        <Visibility />
                                                                    )}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </>
                        )}
                        {activeStep === 1 && (
                            <>
                                <Card elevation={0}>
                                    <CardContent>
                                        <Grid container spacing={2}>
                                            <Grid item lg={4} sm={6} xs={12}>
                                                <FormControl
                                                    fullWidth
                                                    required
                                                    error={isError1 && valuesFact.typeFact === "" ? true : false}
                                                >
                                                    <InputLabel id="nivel-simple-select-label">
                                                        {t("miscellaneous.invoicingServicesTo")}
                                                    </InputLabel>
                                                    <Select
                                                        name="typeFact"
                                                        labelId="nivel-simple-select-label"
                                                        IconComponent={KeyboardArrowDownIcon}
                                                        label={t("miscellaneous.invoicingServicesTo")}
                                                        value={valuesFact.typeFact}
                                                        onChange={handleChangeTypeFact}
                                                        MenuProps={{
                                                            PaperProps: {
                                                                sx: {
                                                                    mt: 0.7,
                                                                    borderRadius: 2,
                                                                    boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                                },
                                                            },
                                                        }}
                                                    >
                                                        <MenuItem value={1}>{t("miscellaneous.franchise")}</MenuItem>
                                                        {valuesGen.typeClient === 2 ? (
                                                            <MenuItem value={2}>{t("miscellaneous.client")}</MenuItem>
                                                        ) : null}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item lg={4} sm={6} xs={12}>
                                                <FormControl fullWidth required error={isError1 && !valuesFact.subsInv}>
                                                    <InputLabel id="subsInv-simple-select-label">
                                                        {t("invoicing.subsInv")}
                                                    </InputLabel>
                                                    <Select
                                                        name="subsInv"
                                                        labelId="subsInv-simple-select-label"
                                                        label={t("invoicing.subsInv")}
                                                        IconComponent={KeyboardArrowDownIcon}
                                                        value={valuesFact.subsInv}
                                                        onChange={handleChangeFact}
                                                        MenuProps={{
                                                            PaperProps: {
                                                                sx: {
                                                                    mt: 0.7,
                                                                    borderRadius: 2,
                                                                    boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                                },
                                                            },
                                                        }}
                                                    >
                                                        <MenuItem value={1}>GCA SC (Venezuela)</MenuItem>
                                                        <MenuItem value={2}>GCA INC (Estados Unidos)</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                                <Card elevation={0}>
                                    <CardHeader title={t("miscellaneous.contact")} sx={{ color: "#ff5968" }} />
                                    <Divider />
                                    <CardContent>
                                        <Grid container spacing={2}>
                                            <Grid item lg={4} sm={6} xs={12}>
                                                <TextField
                                                    fullWidth
                                                    required={valuesFact.typeFact !== 2}
                                                    disabled={valuesFact.typeFact === 2}
                                                    label={t("miscellaneous.invoicingNameGCA")}
                                                    name="contactGCA"
                                                    onChange={handleChangeFact}
                                                    value={valuesFact.contactGCA}
                                                    variant="outlined"
                                                    error={isError1 && valuesFact.contactGCA === "" ? true : false}
                                                />
                                            </Grid>
                                            <Grid item lg={4} sm={6} xs={12}>
                                                <TextField
                                                    fullWidth
                                                    required={valuesFact.typeFact !== 2}
                                                    disabled={valuesFact.typeFact === 2}
                                                    label={t("miscellaneous.contactName")}
                                                    name="contactFact"
                                                    onChange={handleChangeFact}
                                                    value={valuesFact.contactFact}
                                                    variant="outlined"
                                                    error={isError1 && valuesFact.contactFact === "" ? true : false}
                                                />
                                            </Grid>
                                            <Grid item lg={4} sm={6} xs={12}>
                                                <TextField
                                                    fullWidth
                                                    required={valuesFact.typeFact !== 2}
                                                    disabled={valuesFact.typeFact === 2}
                                                    label={t("miscellaneous.contactEmail")}
                                                    name="emailCont"
                                                    onChange={handleChangeFact}
                                                    value={valuesFact.emailCont}
                                                    variant="outlined"
                                                    error={
                                                        isError1 && valuesFact.emailCont.trim() === ""
                                                            ? true
                                                            : isError1 && !/.+@.+\..+/.test(valuesFact.emailCont)
                                                            ? true
                                                            : false
                                                    }
                                                />
                                            </Grid>
                                            <Grid item lg={4} sm={6} xs={12}>
                                                <FormControl fullWidth disabled={valuesFact.typeFact === 2}>
                                                    <InputLabel htmlFor="invoicing-phone-number-mask-input">
                                                        {t("miscellaneous.phoneNumber")}
                                                    </InputLabel>
                                                    <OutlinedInput
                                                        id="invoicing-phone-number-mask-input"
                                                        name="phoneNumber"
                                                        label={t("miscellaneous.phoneNumber")}
                                                        value={valuesFact.phoneNumber}
                                                        onChange={handleChangePhone}
                                                        placeholder="e.g. +99 (999) 999-9999"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </>
                        )}
                        {activeStep === 2 && (
                            <>
                                <Card elevation={0}>
                                    <CardHeader
                                        title={t("services.franchiseServices")}
                                        action={
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={valuesSettings.anticipatedInvoice}
                                                        inputProps={{
                                                            "aria-label": "controlled",
                                                        }}
                                                        color="secondary"
                                                        onChange={handleChangeSwitchSettings}
                                                        sx={{
                                                            "& .MuiSwitch-switchBase .MuiSwitch-thumb": {
                                                                color: "#FFF",
                                                            },
                                                            "& .MuiSwitch-switchBase + .MuiSwitch-track": {
                                                                backgroundColor: "#031851",
                                                            },
                                                            "& .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb": {
                                                                color: "#FF5968",
                                                            },
                                                            "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                                                                backgroundColor: "#FFA8B1",
                                                            },
                                                        }}
                                                    />
                                                }
                                                label={
                                                    <Typography variant="body1" color="textPrimary">
                                                        {t("services.advanceInvoicing")}
                                                    </Typography>
                                                }
                                            />
                                        }
                                        sx={{ color: "#ff5968" }}
                                    />
                                    <Divider />
                                    <CardContent>
                                        <Grid container spacing={2} sx={{ pb: 2 }}>
                                            <Grid item lg={3} sm={6} xs={12}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            name="onboardingFee"
                                                            checked={valuesSettings.onboardingFee}
                                                            onChange={handleChangeCheckOnbardingFee}
                                                        />
                                                    }
                                                    label={t("miscellaneous.onboardingFee")}
                                                />
                                            </Grid>
                                            <Grid item lg={3} sm={6} xs={12}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            name="platformFee"
                                                            checked={valuesSettings.platformFee}
                                                            onChange={handleChangeCheckPlatformFee}
                                                        />
                                                    }
                                                    label={t("miscellaneous.platformFee")}
                                                />
                                            </Grid>
                                            {/* <Grid item lg={3} sm={6} xs={12}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            name="discountUpdates"
                                                            checked={valuesSettings.discountUpd}
                                                            onChange={handleChangeCheckDiscountUpd}
                                                        />
                                                    }
                                                    label={t("miscellaneous.discountUpdate")}
                                                />
                                            </Grid> */}
                                            <Grid item lg={3} sm={6} xs={12}>
                                                <LightTooltip title={t("proposals.personalRepTool")}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                name="personalReports"
                                                                checked={valuesSettings.personalReports}
                                                                onChange={handleChangeCheckPersonalRep}
                                                            />
                                                        }
                                                        label={t("proposals.addPerRep")}
                                                    />
                                                </LightTooltip>
                                            </Grid>
                                        </Grid>
                                        {valuesSettings.onboardingFee && (
                                            <Grid container spacing={2}>
                                                <Grid item lg={3} sm={6} xs={12}>
                                                    <FormControl fullWidth disabled variant="outlined">
                                                        <InputLabel htmlFor="outlined-adornment-onboardingTariff">
                                                            {t("services.onboardingTariff")}
                                                        </InputLabel>
                                                        <OutlinedInput
                                                            id="outlined-adornment-onboardingTariff"
                                                            name="onboardingTariff"
                                                            value={
                                                                parseFloat(valuesSettings.onboardingTariff) -
                                                                parseFloat(valuesSettings.onboardingTariff) *
                                                                    (valuesSettings.discountOnboarding === ""
                                                                        ? 0
                                                                        : parseFloat(
                                                                              valuesSettings.discountOnboarding
                                                                          ) / 100)
                                                            }
                                                            inputComponent={NumberFormatCustom}
                                                            startAdornment={
                                                                <InputAdornment disableTypography position="start">
                                                                    $
                                                                </InputAdornment>
                                                            }
                                                            label={t("services.onboardingTariff")}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item lg={3} sm={6} xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        name="discountOnboarding"
                                                        label={t("services.discountRateOnboarding")}
                                                        value={valuesSettings.discountOnboarding}
                                                        onChange={handleChangeDiscount}
                                                        onBlur={() =>
                                                            setValuesSettings({
                                                                ...valuesSettings,
                                                                discountOnboarding: parseFloat(
                                                                    valuesSettings.discountOnboarding
                                                                ).toFixed(2),
                                                            })
                                                        }
                                                        variant="outlined"
                                                        type="number"
                                                        InputProps={{
                                                            inputProps: {
                                                                min: 0,
                                                                max: 100,
                                                            },
                                                        }}
                                                        sx={{
                                                            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                                                {
                                                                    display: "none",
                                                                },
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        )}
                                    </CardContent>
                                </Card>
                                <Card elevation={0}>
                                    <CardHeader title={t("services.generalConsiderations")} sx={{ color: "#ff5968" }} />
                                    <Divider />
                                    <CardContent>
                                        <Grid container spacing={2} sx={{ pb: 2 }}>
                                            <Grid item lg={3} sm={6} xs={12}>
                                                <LightTooltip title={t("miscellaneous.manageReqTool")}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                name="manageReq"
                                                                checked={valuesSettings.manageReq}
                                                                onChange={handleChangeCheckManageReq}
                                                            />
                                                        }
                                                        label={t("miscellaneous.manageReq")}
                                                    />
                                                </LightTooltip>
                                            </Grid>
                                            <Grid item lg={3} sm={6} xs={12}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            name="clientVip"
                                                            checked={valuesSettings.clientVip}
                                                            onChange={handleChangeCheckClientVip}
                                                        />
                                                    }
                                                    label={t("miscellaneous.clientVip")}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2} sx={{ pb: 2 }}>
                                            <Grid item lg={3} sm={6} xs={12}>
                                                <FormControl
                                                    fullWidth
                                                    required
                                                    error={isError2 && valuesSettings.tariff === "" ? true : false}
                                                >
                                                    <InputLabel id="tariff-simple-select-label">
                                                        {t("services.baseRate")}
                                                    </InputLabel>
                                                    <Select
                                                        name="tariff"
                                                        labelId="tariff-simple-select-label"
                                                        label={t("services.baseRate")}
                                                        value={valuesSettings.tariff}
                                                        onChange={handleChangeSettings}
                                                        IconComponent={KeyboardArrowDownIcon}
                                                        MenuProps={{
                                                            PaperProps: {
                                                                sx: {
                                                                    mt: 0.7,
                                                                    borderRadius: 2,
                                                                    boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                                },
                                                            },
                                                        }}
                                                    >
                                                        <MenuItem value={0}>$0</MenuItem>
                                                        <MenuItem value={5}>$5</MenuItem>
                                                        <MenuItem value={10}>$10</MenuItem>
                                                        <MenuItem value={15}>$15</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item lg={3} sm={6} xs={12}>
                                                <FormControl
                                                    fullWidth
                                                    required
                                                    error={
                                                        isError2 && valuesSettings.tariffAdditionals === ""
                                                            ? true
                                                            : false
                                                    }
                                                >
                                                    <InputLabel id="tariffAdditionals-simple-select-label">
                                                        {t("services.additionalsRate")}
                                                    </InputLabel>
                                                    <Select
                                                        name="tariffAdditionals"
                                                        labelId="tariffAdditionals-simple-select-label"
                                                        label={t("services.additionalsRate")}
                                                        value={valuesSettings.tariffAdditionals}
                                                        onChange={handleChangeSettings}
                                                        IconComponent={KeyboardArrowDownIcon}
                                                        MenuProps={{
                                                            PaperProps: {
                                                                sx: {
                                                                    mt: 0.7,
                                                                    borderRadius: 2,
                                                                    boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                                },
                                                            },
                                                        }}
                                                    >
                                                        <MenuItem value={15}>$15</MenuItem>
                                                        <MenuItem value={25}>$25</MenuItem>
                                                        <MenuItem value={30}>$30</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item lg={3} sm={6} xs={12}>
                                                <LightTooltip title={t("miscellaneous.applyOnly")}>
                                                    <TextField
                                                        fullWidth
                                                        label={t("miscellaneous.discountFran")}
                                                        value={valuesSettings.discount}
                                                        name="discount"
                                                        onChange={handleChangeDiscount}
                                                        onBlur={() =>
                                                            setValuesSettings({
                                                                ...valuesSettings,
                                                                discount: parseFloat(valuesSettings.discount).toFixed(
                                                                    2
                                                                ),
                                                            })
                                                        }
                                                        variant="outlined"
                                                        type="number"
                                                        InputProps={{
                                                            inputProps: {
                                                                min: 0,
                                                                max: 100,
                                                            },
                                                            endAdornment: (
                                                                <InputAdornment position="end">%</InputAdornment>
                                                            ),
                                                        }}
                                                        sx={{
                                                            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                                                {
                                                                    display: "none",
                                                                },
                                                        }}
                                                    />
                                                </LightTooltip>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2}>
                                            <Grid item lg={3} sm={6} xs={12}>
                                                <TextField
                                                    fullWidth
                                                    required
                                                    name="eeccReceptionDays"
                                                    label={t("services.receptionDays")}
                                                    value={valuesSettings.eeccReceptionDays}
                                                    onChange={handleChangeReceptionDays}
                                                    variant="outlined"
                                                    type="number"
                                                    InputProps={{
                                                        inputProps: {
                                                            min: 1,
                                                            max: 15,
                                                        },
                                                    }}
                                                    error={
                                                        isError2 && valuesSettings.eeccReceptionDays === ""
                                                            ? true
                                                            : false
                                                    }
                                                />
                                            </Grid>
                                            {valuesSettings.clientVip && (
                                                <Grid item lg={3} sm={6} xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        required={valuesSettings.clientVip}
                                                        name="monthlyVipReportDate"
                                                        label={t("services.vipMonthlyReportDate")}
                                                        value={valuesSettings.monthlyVipReportDate}
                                                        onChange={handleChangeMonthlyReports}
                                                        variant="outlined"
                                                        type="number"
                                                        InputProps={{
                                                            inputProps: {
                                                                min: 1,
                                                                max: 25,
                                                            },
                                                        }}
                                                        error={
                                                            isError2 &&
                                                            valuesSettings.clientVip &&
                                                            valuesSettings.monthlyVipReportDate === ""
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                </Grid>
                                            )}
                                            <Grid item lg={3} sm={6} xs={12}>
                                                <TextField
                                                    fullWidth
                                                    required
                                                    name="monthlyStandardReportDate"
                                                    label={t("services.stdMonthlyReportDate")}
                                                    value={valuesSettings.monthlyStandardReportDate}
                                                    onChange={handleChangeMonthlyReports}
                                                    variant="outlined"
                                                    type="number"
                                                    InputProps={{
                                                        inputProps: {
                                                            min: 1,
                                                            max: 25,
                                                        },
                                                    }}
                                                    error={
                                                        isError2 && valuesSettings.monthlyStandardReportDate === ""
                                                            ? true
                                                            : false
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </>
                        )}
                        {activeStep === 3 && (
                            <Box>
                                <Card elevation={0}>
                                    <CardHeader title={t("miscellaneous.clientStaff")} sx={{ color: "#ff5968" }} />
                                    <Divider />
                                    <CardContent sx={{ pb: 5 }}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={6}>
                                                <Card
                                                    sx={{
                                                        borderRadius: "20px",
                                                        backgroundColor: "#F8F9F8",
                                                        boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                    }}
                                                >
                                                    <CardContent
                                                        sx={{
                                                            p: "24px 24px 16px !important",
                                                        }}
                                                    >
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} lg={4}>
                                                                <AccountCircle className={classes.avatarImage} />
                                                                <Typography
                                                                    variant="h2"
                                                                    color="primary"
                                                                    align="center"
                                                                    sx={{
                                                                        pt: 1,
                                                                    }}
                                                                >
                                                                    {`${t("miscellaneous.contact")} 1`}
                                                                </Typography>
                                                                <LightTooltip title={t("recurrent.clear")}>
                                                                    <IconButton
                                                                        aria-label="clear"
                                                                        color="primary"
                                                                        onClick={(event) =>
                                                                            clearTeamStaff(event, "teamCompany1")
                                                                        }
                                                                        sx={{
                                                                            display: "block",
                                                                            m: "auto",
                                                                        }}
                                                                    >
                                                                        <ClearAllIcon />
                                                                    </IconButton>
                                                                </LightTooltip>
                                                            </Grid>
                                                            <Grid item xs={12} lg={8}>
                                                                <TextField
                                                                    fullWidth
                                                                    required
                                                                    label={t("miscellaneous.name")}
                                                                    name="contact_name"
                                                                    onChange={(event) =>
                                                                        handleChangeTeamStaff(event, "teamCompany1")
                                                                    }
                                                                    value={valueStaff.teamCompany1.contact_name}
                                                                    variant="outlined"
                                                                    error={
                                                                        isError3 &&
                                                                        valueStaff.teamCompany1.contact_name.trim() ===
                                                                            ""
                                                                            ? true
                                                                            : false
                                                                    }
                                                                />
                                                                <TextField
                                                                    fullWidth
                                                                    required
                                                                    label={t("miscellaneous.email")}
                                                                    name="contact_email"
                                                                    onChange={(event) =>
                                                                        handleChangeTeamStaff(event, "teamCompany1")
                                                                    }
                                                                    value={valueStaff.teamCompany1.contact_email}
                                                                    variant="outlined"
                                                                    error={
                                                                        isError3 &&
                                                                        valueStaff.teamCompany1.contact_email.trim() ===
                                                                            ""
                                                                            ? true
                                                                            : isError3 &&
                                                                              !/.+@.+\..+/.test(
                                                                                  valueStaff.teamCompany1.contact_email.trim()
                                                                              )
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    sx={{
                                                                        mt: 2,
                                                                    }}
                                                                />
                                                                <TextField
                                                                    fullWidth
                                                                    required
                                                                    label={t("miscellaneous.role")}
                                                                    name="contact_role"
                                                                    onChange={(event) =>
                                                                        handleChangeTeamStaff(event, "teamCompany1")
                                                                    }
                                                                    value={valueStaff.teamCompany1.contact_role}
                                                                    variant="outlined"
                                                                    error={
                                                                        isError3 &&
                                                                        valueStaff.teamCompany1.contact_role.trim() ===
                                                                            ""
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    sx={{
                                                                        mt: 2,
                                                                    }}
                                                                />
                                                                <FormGroup
                                                                    sx={{
                                                                        mt: 2,
                                                                        display: "block",
                                                                    }}
                                                                >
                                                                    <FormLabel id="permissions-checkboxes-group-label">
                                                                        <Typography
                                                                            variant="h4"
                                                                            color="primary"
                                                                            sx={{
                                                                                pb: 1,
                                                                            }}
                                                                        >
                                                                            {t("miscellaneous.permissions")}:
                                                                        </Typography>
                                                                    </FormLabel>
                                                                    <LightTooltip
                                                                        title={t("services.toolRequirements")}
                                                                        aria-label="req"
                                                                    >
                                                                        <Checkbox
                                                                            icon={<AssignmentTurnedInOutlinedIcon />}
                                                                            checkedIcon={<AssignmentTurnedInIcon />}
                                                                            checked={
                                                                                valueStaff.teamCompany1
                                                                                    .contact_requirements
                                                                            }
                                                                            onChange={(event) =>
                                                                                handleChangeCheck(
                                                                                    event,
                                                                                    3,
                                                                                    "teamCompany1"
                                                                                )
                                                                            }
                                                                            sx={{
                                                                                ml: {
                                                                                    xs: 0,
                                                                                    lg: 0,
                                                                                    xl: 0,
                                                                                },
                                                                                mr: {
                                                                                    xs: 1,
                                                                                    lg: 0,
                                                                                    xl: 1,
                                                                                },
                                                                            }}
                                                                        />
                                                                    </LightTooltip>
                                                                    <LightTooltip
                                                                        title={t("services.toolAccountingClosures")}
                                                                        aria-label="cierres"
                                                                    >
                                                                        <Checkbox
                                                                            icon={<LockOutlinedIcon />}
                                                                            checkedIcon={<LockIcon />}
                                                                            checked={
                                                                                valueStaff.teamCompany1.contact_closure
                                                                            }
                                                                            onChange={(event) =>
                                                                                handleChangeCheck(
                                                                                    event,
                                                                                    0,
                                                                                    "teamCompany1"
                                                                                )
                                                                            }
                                                                            sx={{
                                                                                ml: {
                                                                                    xs: 1,
                                                                                    lg: 0,
                                                                                    xl: 1,
                                                                                },
                                                                                mr: {
                                                                                    xs: 1,
                                                                                    lg: 0,
                                                                                    xl: 1,
                                                                                },
                                                                            }}
                                                                        />
                                                                    </LightTooltip>
                                                                    <LightTooltip
                                                                        title={t("services.toolStatus")}
                                                                        aria-label="status"
                                                                    >
                                                                        <Checkbox
                                                                            icon={<InsertChartOutlinedIcon />}
                                                                            checkedIcon={<InsertChartIcon />}
                                                                            checked={
                                                                                valueStaff.teamCompany1.contact_status
                                                                            }
                                                                            onChange={(event) =>
                                                                                handleChangeCheck(
                                                                                    event,
                                                                                    1,
                                                                                    "teamCompany1"
                                                                                )
                                                                            }
                                                                            sx={{
                                                                                ml: {
                                                                                    xs: 1,
                                                                                    lg: 0,
                                                                                    xl: 1,
                                                                                },
                                                                                mr: {
                                                                                    xs: 1,
                                                                                    lg: 0,
                                                                                    xl: 1,
                                                                                },
                                                                            }}
                                                                        />
                                                                    </LightTooltip>
                                                                    <LightTooltip
                                                                        title={t("services.toolInvoicing")}
                                                                        aria-label="invoicing"
                                                                    >
                                                                        <Checkbox
                                                                            icon={<CreditCardOutlinedIcon />}
                                                                            checkedIcon={
                                                                                <CreditCardIcon className="icon-normal-size" />
                                                                            }
                                                                            checked={
                                                                                valueStaff.teamCompany1.contact_invoices
                                                                            }
                                                                            onChange={(event) =>
                                                                                handleChangeCheck(
                                                                                    event,
                                                                                    4,
                                                                                    "teamCompany1"
                                                                                )
                                                                            }
                                                                            sx={{
                                                                                ml: {
                                                                                    xs: 1,
                                                                                    lg: 0,
                                                                                    xl: 1,
                                                                                },
                                                                                mr: {
                                                                                    xs: 1,
                                                                                    lg: 0,
                                                                                    xl: 1,
                                                                                },
                                                                            }}
                                                                        />
                                                                    </LightTooltip>
                                                                </FormGroup>
                                                            </Grid>
                                                        </Grid>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Card
                                                    sx={{
                                                        borderRadius: "20px",
                                                        backgroundColor: "#F8F9F8",
                                                        boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                    }}
                                                >
                                                    <CardContent
                                                        sx={{
                                                            p: "24px 24px 16px !important",
                                                        }}
                                                    >
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} lg={4}>
                                                                <AccountCircle className={classes.avatarImage} />
                                                                <Typography
                                                                    variant="h2"
                                                                    color="primary"
                                                                    align="center"
                                                                    sx={{
                                                                        pt: 1,
                                                                    }}
                                                                >
                                                                    {`${t("miscellaneous.contact")} 2`}
                                                                </Typography>
                                                                <LightTooltip title={t("recurrent.clear")}>
                                                                    <IconButton
                                                                        aria-label="clear"
                                                                        color="primary"
                                                                        onClick={(event) =>
                                                                            clearTeamStaff(event, "teamCompany2")
                                                                        }
                                                                        sx={{
                                                                            display: "block",
                                                                            m: "auto",
                                                                        }}
                                                                    >
                                                                        <ClearAllIcon />
                                                                    </IconButton>
                                                                </LightTooltip>
                                                            </Grid>
                                                            <Grid item xs={12} lg={8}>
                                                                <TextField
                                                                    fullWidth
                                                                    required={isRequired2}
                                                                    label={t("miscellaneous.name")}
                                                                    name="contact_name"
                                                                    onChange={(event) =>
                                                                        handleChangeTeamStaff(event, "teamCompany2")
                                                                    }
                                                                    value={valueStaff.teamCompany2.contact_name}
                                                                    variant="outlined"
                                                                    error={
                                                                        isRequired2 &&
                                                                        isError3 &&
                                                                        valueStaff.teamCompany2.contact_name.trim() ===
                                                                            ""
                                                                            ? true
                                                                            : false
                                                                    }
                                                                />
                                                                <TextField
                                                                    fullWidth
                                                                    required={isRequired2}
                                                                    label={t("miscellaneous.email")}
                                                                    name="contact_email"
                                                                    onChange={(event) =>
                                                                        handleChangeTeamStaff(event, "teamCompany2")
                                                                    }
                                                                    value={valueStaff.teamCompany2.contact_email}
                                                                    variant="outlined"
                                                                    error={
                                                                        isRequired2 &&
                                                                        isError3 &&
                                                                        valueStaff.teamCompany2.contact_email.trim() ===
                                                                            ""
                                                                            ? true
                                                                            : isRequired2 &&
                                                                              isError3 &&
                                                                              !/.+@.+\..+/.test(
                                                                                  valueStaff.teamCompany2.contact_email.trim()
                                                                              )
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    sx={{
                                                                        mt: 2,
                                                                    }}
                                                                />
                                                                <TextField
                                                                    fullWidth
                                                                    required={isRequired2}
                                                                    label={t("miscellaneous.role")}
                                                                    name="contact_role"
                                                                    onChange={(event) =>
                                                                        handleChangeTeamStaff(event, "teamCompany2")
                                                                    }
                                                                    value={valueStaff.teamCompany2.contact_role}
                                                                    variant="outlined"
                                                                    error={
                                                                        isRequired2 &&
                                                                        isError3 &&
                                                                        valueStaff.teamCompany2.contact_role.trim() ===
                                                                            ""
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    sx={{
                                                                        mt: 2,
                                                                    }}
                                                                />
                                                                <FormGroup
                                                                    sx={{
                                                                        mt: 2,
                                                                        display: "block",
                                                                    }}
                                                                >
                                                                    <FormLabel id="permissions-checkboxes-group-label">
                                                                        <Typography
                                                                            variant="h4"
                                                                            color="primary"
                                                                            sx={{
                                                                                pb: 1,
                                                                            }}
                                                                        >
                                                                            {t("miscellaneous.permissions")}:
                                                                        </Typography>
                                                                    </FormLabel>
                                                                    <LightTooltip
                                                                        title={t("services.toolRequirements")}
                                                                        aria-label="req"
                                                                    >
                                                                        <Checkbox
                                                                            icon={<AssignmentTurnedInOutlinedIcon />}
                                                                            checkedIcon={<AssignmentTurnedInIcon />}
                                                                            checked={
                                                                                valueStaff.teamCompany2
                                                                                    .contact_requirements
                                                                            }
                                                                            onChange={(event) =>
                                                                                handleChangeCheck(
                                                                                    event,
                                                                                    3,
                                                                                    "teamCompany2"
                                                                                )
                                                                            }
                                                                            sx={{
                                                                                ml: {
                                                                                    xs: 0,
                                                                                    lg: 0,
                                                                                    xl: 0,
                                                                                },
                                                                                mr: {
                                                                                    xs: 1,
                                                                                    lg: 0,
                                                                                    xl: 1,
                                                                                },
                                                                            }}
                                                                        />
                                                                    </LightTooltip>
                                                                    <LightTooltip
                                                                        title={t("services.toolAccountingClosures")}
                                                                        aria-label="cierres"
                                                                    >
                                                                        <Checkbox
                                                                            icon={<LockOutlinedIcon />}
                                                                            checkedIcon={<LockIcon />}
                                                                            checked={
                                                                                valueStaff.teamCompany2.contact_closure
                                                                            }
                                                                            onChange={(event) =>
                                                                                handleChangeCheck(
                                                                                    event,
                                                                                    0,
                                                                                    "teamCompany2"
                                                                                )
                                                                            }
                                                                            sx={{
                                                                                ml: {
                                                                                    xs: 1,
                                                                                    lg: 0,
                                                                                    xl: 1,
                                                                                },
                                                                                mr: {
                                                                                    xs: 1,
                                                                                    lg: 0,
                                                                                    xl: 1,
                                                                                },
                                                                            }}
                                                                        />
                                                                    </LightTooltip>
                                                                    <LightTooltip
                                                                        title={t("services.toolStatus")}
                                                                        aria-label="status"
                                                                    >
                                                                        <Checkbox
                                                                            icon={<InsertChartOutlinedIcon />}
                                                                            checkedIcon={<InsertChartIcon />}
                                                                            checked={
                                                                                valueStaff.teamCompany2.contact_status
                                                                            }
                                                                            onChange={(event) =>
                                                                                handleChangeCheck(
                                                                                    event,
                                                                                    1,
                                                                                    "teamCompany2"
                                                                                )
                                                                            }
                                                                            sx={{
                                                                                ml: {
                                                                                    xs: 1,
                                                                                    lg: 0,
                                                                                    xl: 1,
                                                                                },
                                                                                mr: {
                                                                                    xs: 1,
                                                                                    lg: 0,
                                                                                    xl: 1,
                                                                                },
                                                                            }}
                                                                        />
                                                                    </LightTooltip>
                                                                    <LightTooltip
                                                                        title={t("services.toolInvoicing")}
                                                                        aria-label="invoicing"
                                                                    >
                                                                        <Checkbox
                                                                            icon={<CreditCardOutlinedIcon />}
                                                                            checkedIcon={
                                                                                <CreditCardIcon className="icon-normal-size" />
                                                                            }
                                                                            checked={
                                                                                valueStaff.teamCompany2.contact_invoices
                                                                            }
                                                                            onChange={(event) =>
                                                                                handleChangeCheck(
                                                                                    event,
                                                                                    4,
                                                                                    "teamCompany2"
                                                                                )
                                                                            }
                                                                            sx={{
                                                                                ml: {
                                                                                    xs: 1,
                                                                                    lg: 0,
                                                                                    xl: 1,
                                                                                },
                                                                                mr: {
                                                                                    xs: 1,
                                                                                    lg: 0,
                                                                                    xl: 1,
                                                                                },
                                                                            }}
                                                                        />
                                                                    </LightTooltip>
                                                                </FormGroup>
                                                            </Grid>
                                                        </Grid>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Card
                                                    sx={{
                                                        borderRadius: "20px",
                                                        backgroundColor: "#F8F9F8",
                                                        boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                    }}
                                                >
                                                    <CardContent
                                                        sx={{
                                                            p: "24px 24px 16px !important",
                                                        }}
                                                    >
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} lg={4}>
                                                                <AccountCircle className={classes.avatarImage} />
                                                                <Typography
                                                                    variant="h2"
                                                                    color="primary"
                                                                    align="center"
                                                                    sx={{
                                                                        pt: 1,
                                                                    }}
                                                                >
                                                                    {`${t("miscellaneous.contact")} 3`}
                                                                </Typography>
                                                                <LightTooltip title={t("recurrent.clear")}>
                                                                    <IconButton
                                                                        aria-label="clear"
                                                                        color="primary"
                                                                        onClick={(event) =>
                                                                            clearTeamStaff(event, "teamCompany3")
                                                                        }
                                                                        sx={{
                                                                            display: "block",
                                                                            m: "auto",
                                                                        }}
                                                                    >
                                                                        <ClearAllIcon />
                                                                    </IconButton>
                                                                </LightTooltip>
                                                            </Grid>
                                                            <Grid item xs={12} lg={8}>
                                                                <TextField
                                                                    fullWidth
                                                                    required={isRequired3}
                                                                    label={t("miscellaneous.name")}
                                                                    name="contact_name"
                                                                    onChange={(event) =>
                                                                        handleChangeTeamStaff(event, "teamCompany3")
                                                                    }
                                                                    value={valueStaff.teamCompany3.contact_name}
                                                                    variant="outlined"
                                                                    error={
                                                                        isRequired3 &&
                                                                        isError3 &&
                                                                        valueStaff.teamCompany3.contact_name.trim() ===
                                                                            ""
                                                                            ? true
                                                                            : false
                                                                    }
                                                                />
                                                                <TextField
                                                                    fullWidth
                                                                    required={isRequired3}
                                                                    label={t("miscellaneous.email")}
                                                                    name="contact_email"
                                                                    onChange={(event) =>
                                                                        handleChangeTeamStaff(event, "teamCompany3")
                                                                    }
                                                                    value={valueStaff.teamCompany3.contact_email}
                                                                    variant="outlined"
                                                                    error={
                                                                        isRequired3 &&
                                                                        isError3 &&
                                                                        valueStaff.teamCompany3.contact_email.trim() ===
                                                                            ""
                                                                            ? true
                                                                            : isRequired3 &&
                                                                              isError3 &&
                                                                              !/.+@.+\..+/.test(
                                                                                  valueStaff.teamCompany3.contact_email.trim()
                                                                              )
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    sx={{
                                                                        mt: 2,
                                                                    }}
                                                                />
                                                                <TextField
                                                                    fullWidth
                                                                    required={isRequired3}
                                                                    label={t("miscellaneous.role")}
                                                                    name="contact_role"
                                                                    onChange={(event) =>
                                                                        handleChangeTeamStaff(event, "teamCompany3")
                                                                    }
                                                                    value={valueStaff.teamCompany3.contact_role}
                                                                    variant="outlined"
                                                                    error={
                                                                        isRequired3 &&
                                                                        isError3 &&
                                                                        valueStaff.teamCompany3.contact_role.trim() ===
                                                                            ""
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    sx={{
                                                                        mt: 2,
                                                                    }}
                                                                />
                                                                <FormGroup
                                                                    sx={{
                                                                        mt: 2,
                                                                        display: "block",
                                                                    }}
                                                                >
                                                                    <FormLabel id="permissions-checkboxes-group-label">
                                                                        <Typography
                                                                            variant="h4"
                                                                            color="primary"
                                                                            sx={{
                                                                                pb: 1,
                                                                            }}
                                                                        >
                                                                            {t("miscellaneous.permissions")}:
                                                                        </Typography>
                                                                    </FormLabel>
                                                                    <LightTooltip
                                                                        title={t("services.toolRequirements")}
                                                                        aria-label="req"
                                                                    >
                                                                        <Checkbox
                                                                            icon={<AssignmentTurnedInOutlinedIcon />}
                                                                            checkedIcon={<AssignmentTurnedInIcon />}
                                                                            checked={
                                                                                valueStaff.teamCompany3
                                                                                    .contact_requirements
                                                                            }
                                                                            onChange={(event) =>
                                                                                handleChangeCheck(
                                                                                    event,
                                                                                    3,
                                                                                    "teamCompany3"
                                                                                )
                                                                            }
                                                                            sx={{
                                                                                ml: {
                                                                                    xs: 0,
                                                                                    lg: 0,
                                                                                    xl: 0,
                                                                                },
                                                                                mr: {
                                                                                    xs: 1,
                                                                                    lg: 0,
                                                                                    xl: 1,
                                                                                },
                                                                            }}
                                                                        />
                                                                    </LightTooltip>
                                                                    <LightTooltip
                                                                        title={t("services.toolAccountingClosures")}
                                                                        aria-label="cierres"
                                                                    >
                                                                        <Checkbox
                                                                            icon={<LockOutlinedIcon />}
                                                                            checkedIcon={<LockIcon />}
                                                                            checked={
                                                                                valueStaff.teamCompany3.contact_closure
                                                                            }
                                                                            onChange={(event) =>
                                                                                handleChangeCheck(
                                                                                    event,
                                                                                    0,
                                                                                    "teamCompany3"
                                                                                )
                                                                            }
                                                                            sx={{
                                                                                ml: {
                                                                                    xs: 1,
                                                                                    lg: 0,
                                                                                    xl: 1,
                                                                                },
                                                                                mr: {
                                                                                    xs: 1,
                                                                                    lg: 0,
                                                                                    xl: 1,
                                                                                },
                                                                            }}
                                                                        />
                                                                    </LightTooltip>
                                                                    <LightTooltip
                                                                        title={t("services.toolStatus")}
                                                                        aria-label="status"
                                                                    >
                                                                        <Checkbox
                                                                            icon={<InsertChartOutlinedIcon />}
                                                                            checkedIcon={<InsertChartIcon />}
                                                                            checked={
                                                                                valueStaff.teamCompany3.contact_status
                                                                            }
                                                                            onChange={(event) =>
                                                                                handleChangeCheck(
                                                                                    event,
                                                                                    1,
                                                                                    "teamCompany3"
                                                                                )
                                                                            }
                                                                            sx={{
                                                                                ml: {
                                                                                    xs: 1,
                                                                                    lg: 0,
                                                                                    xl: 1,
                                                                                },
                                                                                mr: {
                                                                                    xs: 1,
                                                                                    lg: 0,
                                                                                    xl: 1,
                                                                                },
                                                                            }}
                                                                        />
                                                                    </LightTooltip>
                                                                    <LightTooltip
                                                                        title={t("services.toolInvoicing")}
                                                                        aria-label="invoicing"
                                                                    >
                                                                        <Checkbox
                                                                            icon={<CreditCardOutlinedIcon />}
                                                                            checkedIcon={
                                                                                <CreditCardIcon className="icon-normal-size" />
                                                                            }
                                                                            checked={
                                                                                valueStaff.teamCompany3.contact_invoices
                                                                            }
                                                                            onChange={(event) =>
                                                                                handleChangeCheck(
                                                                                    event,
                                                                                    4,
                                                                                    "teamCompany3"
                                                                                )
                                                                            }
                                                                            sx={{
                                                                                ml: {
                                                                                    xs: 1,
                                                                                    lg: 0,
                                                                                    xl: 1,
                                                                                },
                                                                                mr: {
                                                                                    xs: 1,
                                                                                    lg: 0,
                                                                                    xl: 1,
                                                                                },
                                                                            }}
                                                                        />
                                                                    </LightTooltip>
                                                                </FormGroup>
                                                            </Grid>
                                                        </Grid>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Card
                                                    sx={{
                                                        borderRadius: "20px",
                                                        backgroundColor: "#F8F9F8",
                                                        boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                    }}
                                                >
                                                    <CardContent
                                                        sx={{
                                                            p: "24px 24px 16px !important",
                                                        }}
                                                    >
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} lg={4}>
                                                                <AccountCircle className={classes.avatarImage} />
                                                                <Typography
                                                                    variant="h2"
                                                                    color="primary"
                                                                    align="center"
                                                                    sx={{
                                                                        pt: 1,
                                                                    }}
                                                                >
                                                                    {`${t("miscellaneous.contact")} 4`}
                                                                </Typography>
                                                                <LightTooltip title={t("recurrent.clear")}>
                                                                    <IconButton
                                                                        aria-label="clear"
                                                                        color="primary"
                                                                        onClick={(event) =>
                                                                            clearTeamStaff(event, "teamCompany4")
                                                                        }
                                                                        sx={{
                                                                            display: "block",
                                                                            m: "auto",
                                                                        }}
                                                                    >
                                                                        <ClearAllIcon />
                                                                    </IconButton>
                                                                </LightTooltip>
                                                            </Grid>
                                                            <Grid item xs={12} lg={8}>
                                                                <TextField
                                                                    fullWidth
                                                                    required={isRequired4}
                                                                    label={t("miscellaneous.name")}
                                                                    name="contact_name"
                                                                    onChange={(event) =>
                                                                        handleChangeTeamStaff(event, "teamCompany4")
                                                                    }
                                                                    value={valueStaff.teamCompany4.contact_name}
                                                                    variant="outlined"
                                                                    error={
                                                                        isRequired4 &&
                                                                        isError3 &&
                                                                        valueStaff.teamCompany4.contact_name.trim() ===
                                                                            ""
                                                                            ? true
                                                                            : false
                                                                    }
                                                                />
                                                                <TextField
                                                                    fullWidth
                                                                    required={isRequired4}
                                                                    label={t("miscellaneous.email")}
                                                                    name="contact_email"
                                                                    onChange={(event) =>
                                                                        handleChangeTeamStaff(event, "teamCompany4")
                                                                    }
                                                                    value={valueStaff.teamCompany4.contact_email}
                                                                    variant="outlined"
                                                                    error={
                                                                        isRequired4 &&
                                                                        isError3 &&
                                                                        valueStaff.teamCompany4.contact_email.trim() ===
                                                                            ""
                                                                            ? true
                                                                            : isRequired4 &&
                                                                              isError3 &&
                                                                              !/.+@.+\..+/.test(
                                                                                  valueStaff.teamCompany4.contact_email.trim()
                                                                              )
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    sx={{
                                                                        mt: 2,
                                                                    }}
                                                                />
                                                                <TextField
                                                                    fullWidth
                                                                    required={isRequired4}
                                                                    label={t("miscellaneous.role")}
                                                                    name="contact_role"
                                                                    onChange={(event) =>
                                                                        handleChangeTeamStaff(event, "teamCompany4")
                                                                    }
                                                                    value={valueStaff.teamCompany4.contact_role}
                                                                    variant="outlined"
                                                                    error={
                                                                        isRequired4 &&
                                                                        isError3 &&
                                                                        valueStaff.teamCompany4.contact_role.trim() ===
                                                                            ""
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    sx={{
                                                                        mt: 2,
                                                                    }}
                                                                />
                                                                <FormGroup
                                                                    sx={{
                                                                        mt: 2,
                                                                        display: "block",
                                                                    }}
                                                                >
                                                                    <FormLabel id="permissions-checkboxes-group-label">
                                                                        <Typography
                                                                            variant="h4"
                                                                            color="primary"
                                                                            sx={{
                                                                                pb: 1,
                                                                            }}
                                                                        >
                                                                            {t("miscellaneous.permissions")}:
                                                                        </Typography>
                                                                    </FormLabel>
                                                                    <LightTooltip
                                                                        title={t("services.toolRequirements")}
                                                                        aria-label="req"
                                                                    >
                                                                        <Checkbox
                                                                            icon={<AssignmentTurnedInOutlinedIcon />}
                                                                            checkedIcon={<AssignmentTurnedInIcon />}
                                                                            checked={
                                                                                valueStaff.teamCompany4
                                                                                    .contact_requirements
                                                                            }
                                                                            onChange={(event) =>
                                                                                handleChangeCheck(
                                                                                    event,
                                                                                    3,
                                                                                    "teamCompany4"
                                                                                )
                                                                            }
                                                                            sx={{
                                                                                ml: {
                                                                                    xs: 0,
                                                                                    lg: 0,
                                                                                    xl: 0,
                                                                                },
                                                                                mr: {
                                                                                    xs: 1,
                                                                                    lg: 0,
                                                                                    xl: 1,
                                                                                },
                                                                            }}
                                                                        />
                                                                    </LightTooltip>
                                                                    <LightTooltip
                                                                        title={t("services.toolAccountingClosures")}
                                                                        aria-label="cierres"
                                                                    >
                                                                        <Checkbox
                                                                            icon={<LockOutlinedIcon />}
                                                                            checkedIcon={<LockIcon />}
                                                                            checked={
                                                                                valueStaff.teamCompany4.contact_closure
                                                                            }
                                                                            onChange={(event) =>
                                                                                handleChangeCheck(
                                                                                    event,
                                                                                    0,
                                                                                    "teamCompany4"
                                                                                )
                                                                            }
                                                                            sx={{
                                                                                ml: {
                                                                                    xs: 1,
                                                                                    lg: 0,
                                                                                    xl: 1,
                                                                                },
                                                                                mr: {
                                                                                    xs: 1,
                                                                                    lg: 0,
                                                                                    xl: 1,
                                                                                },
                                                                            }}
                                                                        />
                                                                    </LightTooltip>
                                                                    <LightTooltip
                                                                        title={t("services.toolStatus")}
                                                                        aria-label="status"
                                                                    >
                                                                        <Checkbox
                                                                            icon={<InsertChartOutlinedIcon />}
                                                                            checkedIcon={<InsertChartIcon />}
                                                                            checked={
                                                                                valueStaff.teamCompany4.contact_status
                                                                            }
                                                                            onChange={(event) =>
                                                                                handleChangeCheck(
                                                                                    event,
                                                                                    1,
                                                                                    "teamCompany4"
                                                                                )
                                                                            }
                                                                            sx={{
                                                                                ml: {
                                                                                    xs: 1,
                                                                                    lg: 0,
                                                                                    xl: 1,
                                                                                },
                                                                                mr: {
                                                                                    xs: 1,
                                                                                    lg: 0,
                                                                                    xl: 1,
                                                                                },
                                                                            }}
                                                                        />
                                                                    </LightTooltip>
                                                                    <LightTooltip
                                                                        title={t("services.toolInvoicing")}
                                                                        aria-label="invoicing"
                                                                    >
                                                                        <Checkbox
                                                                            icon={<CreditCardOutlinedIcon />}
                                                                            checkedIcon={
                                                                                <CreditCardIcon className="icon-normal-size" />
                                                                            }
                                                                            checked={
                                                                                valueStaff.teamCompany4.contact_invoices
                                                                            }
                                                                            onChange={(event) =>
                                                                                handleChangeCheck(
                                                                                    event,
                                                                                    4,
                                                                                    "teamCompany4"
                                                                                )
                                                                            }
                                                                            sx={{
                                                                                ml: {
                                                                                    xs: 1,
                                                                                    lg: 0,
                                                                                    xl: 1,
                                                                                },
                                                                                mr: {
                                                                                    xs: 1,
                                                                                    lg: 0,
                                                                                    xl: 1,
                                                                                },
                                                                            }}
                                                                        />
                                                                    </LightTooltip>
                                                                </FormGroup>
                                                            </Grid>
                                                        </Grid>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                                <Card elevation={0}>
                                    <CardHeader title={t("miscellaneous.gcaStaff")} sx={{ color: "#ff5968" }} />
                                    <Divider />
                                    <CardContent>
                                        <Grid container spacing={2}>
                                            <Grid item lg={4} sm={6} xs={12}>
                                                <FormControl
                                                    fullWidth
                                                    required
                                                    error={isError3 && valueStaff.manager === "" ? true : false}
                                                >
                                                    <InputLabel id="manager-simple-select-label">
                                                        {t("miscellaneous.manager")}
                                                    </InputLabel>
                                                    <Select
                                                        name="manager"
                                                        labelId="manager-simple-select-label"
                                                        label={t("miscellaneous.manager")}
                                                        IconComponent={KeyboardArrowDownIcon}
                                                        value={valueStaff.manager}
                                                        onChange={handleChangeStaff}
                                                        MenuProps={{
                                                            PaperProps: {
                                                                sx: {
                                                                    mt: 0.7,
                                                                    borderRadius: 2,
                                                                    boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                                },
                                                            },
                                                        }}
                                                    >
                                                        {Staff.manager.map((option) => (
                                                            <MenuItem value={option.name_es}>
                                                                {moment.locale() === "en"
                                                                    ? option.name_en
                                                                    : option.name_es}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item lg={4} sm={6} xs={12}>
                                                <FormControl
                                                    fullWidth
                                                    required
                                                    error={isError3 && valueStaff.supervisor === "" ? true : false}
                                                >
                                                    <InputLabel id="supervisor-simple-select-label">
                                                        {t("miscellaneous.supervisor")}
                                                    </InputLabel>
                                                    <Select
                                                        name="supervisor"
                                                        labelId="supervisor-simple-select-label"
                                                        label={t("miscellaneous.supervisor")}
                                                        IconComponent={KeyboardArrowDownIcon}
                                                        value={valueStaff.supervisor}
                                                        onChange={handleChangeStaff}
                                                        MenuProps={{
                                                            PaperProps: {
                                                                sx: {
                                                                    mt: 0.7,
                                                                    borderRadius: 2,
                                                                    boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                                },
                                                            },
                                                        }}
                                                    >
                                                        {Staff.supervisor.map((option) => (
                                                            <MenuItem value={option.name_es}>
                                                                {moment.locale() === "en"
                                                                    ? option.name_en
                                                                    : option.name_es}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item lg={4} sm={6} xs={12}>
                                                <FormControl
                                                    fullWidth
                                                    required
                                                    error={isError3 && valueStaff.advisor === "" ? true : false}
                                                >
                                                    <InputLabel id="advisor-simple-select-label">
                                                        {t("miscellaneous.advisor")}
                                                    </InputLabel>
                                                    <Select
                                                        name="advisor"
                                                        labelId="advisor-simple-select-label"
                                                        label={t("miscellaneous.advisor")}
                                                        IconComponent={KeyboardArrowDownIcon}
                                                        value={valueStaff.advisor}
                                                        onChange={handleChangeStaff}
                                                        MenuProps={{
                                                            PaperProps: {
                                                                sx: {
                                                                    mt: 0.7,
                                                                    borderRadius: 2,
                                                                    boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                                },
                                                            },
                                                        }}
                                                    >
                                                        {Staff.advisor.map((option) => (
                                                            <MenuItem value={option.name_es}>
                                                                {moment.locale() === "en"
                                                                    ? option.name_en
                                                                    : option.name_es}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item lg={4} sm={6} xs={12}>
                                                <FormControl
                                                    fullWidth
                                                    required
                                                    error={isError3 && valueStaff.analyst === "" ? true : false}
                                                >
                                                    <InputLabel id="analyst-simple-select-label">
                                                        {t("miscellaneous.analyst")}
                                                    </InputLabel>
                                                    <Select
                                                        name="analyst"
                                                        labelId="analyst-simple-select-label"
                                                        label={t("miscellaneous.analyst")}
                                                        IconComponent={KeyboardArrowDownIcon}
                                                        value={valueStaff.analyst}
                                                        onChange={handleChangeStaff}
                                                        MenuProps={{
                                                            PaperProps: {
                                                                sx: {
                                                                    mt: 0.7,
                                                                    borderRadius: 2,
                                                                    boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                                },
                                                            },
                                                        }}
                                                    >
                                                        {Staff.analyst.map((option) => (
                                                            <MenuItem value={option.name_es}>
                                                                {moment.locale() === "en"
                                                                    ? option.name_en
                                                                    : option.name_es}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item lg={4} sm={6} xs={12}>
                                                <FormControl
                                                    fullWidth
                                                    required
                                                    error={isError3 && valueStaff.backupAnalyst === "" ? true : false}
                                                >
                                                    <InputLabel id="backup-analyst-simple-select-label">
                                                        {t("miscellaneous.backupAnalyst")}
                                                    </InputLabel>
                                                    <Select
                                                        name="backupAnalyst"
                                                        labelId="backup-analyst-simple-select-label"
                                                        label={t("miscellaneous.backupAnalyst")}
                                                        IconComponent={KeyboardArrowDownIcon}
                                                        value={valueStaff.backupAnalyst}
                                                        onChange={handleChangeStaff}
                                                        MenuProps={{
                                                            PaperProps: {
                                                                sx: {
                                                                    mt: 0.7,
                                                                    borderRadius: 2,
                                                                    boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                                },
                                                            },
                                                        }}
                                                    >
                                                        {Staff.backupAnalyst.map((option) => (
                                                            <MenuItem value={option.name_es}>
                                                                {moment.locale() === "en"
                                                                    ? option.name_en
                                                                    : option.name_es}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Box>
                        )}
                    </TabPanel>
                    <TabPanel value={valueTab} index={1}>
                        <Box sx={{ width: "100%", p: 4 }}>
                            <Stepper
                                nonLinear
                                activeStep={activeStep}
                                orientation={window.innerWidth < 600 ? "vertical" : "horizontal"}
                            >
                                {stepsEdit.map((label, index) => {
                                    const labelProps = {};
                                    if (
                                        (isError && index === 0) ||
                                        (isError1 && index === 1) ||
                                        (isError2 && index === 2) ||
                                        (isError3 && index === 3) ||
                                        (isError4 && index === 4)
                                    ) {
                                        labelProps.optional = (
                                            <Typography variant="caption" color="error">
                                                {t("miscellaneous.required")}
                                            </Typography>
                                        );

                                        labelProps.error = true;
                                    }
                                    return (
                                        <Step key={label}>
                                            <StepButton
                                                color="inherit"
                                                onClick={() => handleStep(index)}
                                                disabled={!showEdit}
                                            >
                                                <StepLabel {...labelProps}>{label}</StepLabel>
                                            </StepButton>
                                        </Step>
                                    );
                                })}
                            </Stepper>
                        </Box>
                        <EditFranq
                            setBackDrop={props.setBackDrop}
                            setTexto={props.setTexto}
                            setalertType={props.setalertType}
                            setShow={props.setShow}
                            valuesGen={valuesGen}
                            setValuesGen={setValuesGen}
                            valuesFact={valuesFact}
                            setValuesFact={setValuesFact}
                            valuesSettings={valuesSettings}
                            setValuesSettings={setValuesSettings}
                            valueStaff={valueStaff}
                            setValueStaff={setValueStaff}
                            teamCompanyDefault={teamCompanyDefault}
                            activeStep={activeStep}
                            franqData={props.franqData}
                            isError={isError}
                            isError1={isError1}
                            isError2={isError2}
                            isError3={isError3}
                            isError4={isError4}
                            showEdit={showEdit}
                            setShowEdit={resetErrors}
                            isReq={isReq}
                            setIsReq={setIsReq}
                        />
                    </TabPanel>
                </Box>
            </DialogContent>
            <DialogActions sx={{ p: "24px 32px !important" }}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                    }}
                >
                    <ColorButton
                        onClick={handleBack}
                        sx={{
                            mr: 1,
                            "&:hover": {
                                backgroundColor: "white",
                                color: "#2f3190",
                                border: "1px solid #2f3190",
                            },
                        }}
                        color="secondary"
                        disableElevation
                        variant="outlined"
                    >
                        {activeStep === 0 ? t("miscellaneous.cancel") : t("miscellaneous.back")}
                    </ColorButton>
                    <Box sx={{ flex: "1 1 auto" }} />
                    {valueTab === 0 ? (
                        <ColorButton
                            onClick={activeStep === steps.length - 1 ? handleOpenDialog : handleNext}
                            sx={{
                                "&:hover": {
                                    backgroundColor: "#2f3190",
                                    border: "1px solid #2f3190",
                                },
                            }}
                            className="Button"
                            color="secondary"
                            disableElevation
                            variant="contained"
                        >
                            {activeStep === steps.length - 1 ? t("miscellaneous.save") : t("miscellaneous.next")}
                        </ColorButton>
                    ) : (
                        <ColorButton
                            onClick={handleOpenDialog}
                            sx={{
                                "&:hover": {
                                    backgroundColor: "#2f3190",
                                    border: "1px solid #2f3190",
                                },
                            }}
                            className="Button"
                            color="secondary"
                            disableElevation
                            disabled={!showEdit || (activeStep === 4 && isReq)}
                            variant="contained"
                        >
                            {t("miscellaneous.save")}
                        </ColorButton>
                    )}
                </Box>
            </DialogActions>
            <DialogAlerts
                open={open}
                onClose={handleCloseDialog}
                agreeBtnLabel={t("miscellaneous.accept")}
                disagreeBtnLabel={t("miscellaneous.cancel")}
                type={dialogType}
                title={dialogText}
                agreeAction={valueTab === 0 ? handleNext : updateFranq}
            />
        </CustomDialog>
    );
}
