/** React imports */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

/** Images */
import GeraldineCallesImg from "../../../../assets/images/our-team-geraldine-calles.jpg";
import DeyaniraGoncalves from "../../../../assets/images/our-team-deyanira-goncalvez.jpg";
import FeliceCalo from "../../../../assets/images/our-team-felice-calo.jpg";
import JoseAlvarez from "../../../../assets/images/our-team-jose-manuel-alvarez.jpg";
import KeniaGarcia from "../../../../assets/images/our-team-kenia-garcia.jpg";
import DavidLoyo from "../../../../assets/images/our-team-david-loyo.jpg";
import BrianTorres from "../../../../assets/images/our-team-brian-torres.jpg";
import PedroHerrera from "../../../../assets/images/our-team-pedro-herrera.jpg";
import RodrigoQuintero from "../../../../assets/images/our-team-rodrigo-quintero.jpg";
import MicaelaMedina from "../../../../assets/images/our-team-micaela-medina.jpg";
import JohannaRojas from "../../../../assets/images/our-team-johanna-rojas.jpg";
import MariaBarrientos from "../../../../assets/images/our-team-maria-barrientos.jpg";
import IraixyGuevara from "../../../../assets/images/our-team-iraixy-guevara.jpg";
import YaritzaRosas from "../../../../assets/images/our-team-yaritza-rosas.jpg";
import GiancarloAlbani from "../../../../assets/images/our-team-giancarlo-albani.jpg";
import GabrielLoyo from "../../../../assets/images/our-team-gabriel-loyo.png";

/** MUI icons */

/** MUI imports */
import { Box, Typography, Grid, Divider } from "@mui/material";
import { styled } from "@mui/material/styles";

import { Footer } from "./";

/** Global variables */
const PREFIX = "Team";

const classes = {
    body2: `${PREFIX}-body2`,
    textBoxM: `${PREFIX}-textBoxM`,
    textBoxM2: `${PREFIX}-textBoxM2`,
    ListText: `${PREFIX}-ListText`,
    divider4: `${PREFIX}-divider4`,
    teamImage: `${PREFIX}-teamImage`,
    teamImage2: `${PREFIX}-teamImage2`,
    teamName: `${PREFIX}-teamName`,
    positionReview: `${PREFIX}-positionReview`,
    textBoxTeam: `${PREFIX}-textBoxTeam`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.teamName}`]: {
        fontSize: 16,
        [theme.breakpoints.down("sm")]: {
            paddingTop: 10,
            paddingBottom: 10,
        },
        [theme.breakpoints.up("md")]: {
            paddingTop: 0,
            paddingBottom: 10,
        },
    },
    [`& .${classes.positionReview}`]: {
        fontSize: 14,
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            paddingBottom: 30,
        },
        [theme.breakpoints.up("md")]: {
            width: "85%",
            paddingBottom: 10,
        },
        [theme.breakpoints.up("lg")]: {
            width: "90%",
        },
    },

    [`& .${classes.teamImage}`]: {
        height: 80,
        width: 80,
        marginLeft: 15,
    },

    [`& .${classes.teamImage2}`]: {
        height: 70,
        width: 70,
        marginLeft: 15,
    },

    [`& .${classes.divider4}`]: {
        background: "#ff5968",
        borderRadius: 20,
        height: 3,
        marginTop: 50,
        marginBottom: 50,
        [theme.breakpoints.down("md")]: {
            width: 300,
        },
        [theme.breakpoints.up("md")]: {
            width: 450,
        },
    },

    [`& .${classes.ListText}`]: {
        "& li": {
            fontSize: 12,
            paddingBottom: 10,
        },
        paddingTop: 15,
    },

    [`& .${classes.textBoxM}`]: {
        [theme.breakpoints.down("md")]: {
            width: "100%",
        },
        [theme.breakpoints.up("md")]: {
            width: 450,
        },
        fontSize: 22,
    },

    [`& .${classes.textBoxTeam}`]: {
        [theme.breakpoints.down("md")]: {
            width: "100%",
        },
        [theme.breakpoints.up("md")]: {
            width: 450,
        },
        fontSize: 18,
    },

    [`& .${classes.textBoxM2}`]: {
        [theme.breakpoints.down("md")]: {
            width: "100%",
        },
        [theme.breakpoints.up("md")]: {
            width: 700,
        },
        fontSize: 16,
    },

    [`& .${classes.body2}`]: {
        backgroundColor: "white",
        marginTop: 15,
        [theme.breakpoints.down("md")]: {
            padding: 30,
        },
        [theme.breakpoints.up("md")]: {
            padding: 60,
        },
    },
}));

export const Team = (props) => {
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    const { proposalDetails, mode } = props;
    const [info, setInfo] = useState({});
    const [textService, setTextService] = useState(false);
    const [isBook, setIsBook] = useState(false);

    useEffect(() => {
        let values = {};

        if (mode === "preview") {
            if (proposalDetails.name && proposalDetails.name === "Software setup") {
                let priority_name = proposalDetails.xero.isActive
                    ? "Xero"
                    : proposalDetails.taxes.isActive
                        ? "Kiiper Taxes"
                        : proposalDetails.banking.isActive
                            ? "Kiiper Banking"
                            : proposalDetails.reporting.isActive
                                ? "Kiiper Banking"
                                : proposalDetails.other.isActive
                                    ? proposalDetails.other.software
                                    : "Consulting";
                values = {
                    name: proposalDetails.name,
                    country_name: proposalDetails.prospect.country_name,
                    priority_name: priority_name,
                };
                if (proposalDetails.consulting.isActive && !proposalDetails.other.isActive && !proposalDetails.banking.isActive && !proposalDetails.taxes.isActive && !proposalDetails.xero.isActive && !proposalDetails.reporting.isActive && priority_name === "Consulting") {
                    setTextService(true);
                } else {
                    setTextService(false);
                }
            } else {
                values = {
                    name: proposalDetails.name,
                    country_name: proposalDetails.prospect.country_name,
                };
                setTextService(false);
            }
        } else {
            let objSetup = {
                xero: {
                    isActive: false,
                },
                banking: {
                    isActive: false,
                },
                taxes: {
                    isActive: false,
                },
                reporting: {
                    isActive: false,
                },
                other: {
                    isActive: false,
                },
                consulting: {
                    isActive: false,
                },
            };
            if (proposalDetails.service_name && proposalDetails.service_name === "Software setup") {
                proposalDetails.projects.forEach((value) => {
                    if (value.project_name === "Xero setup") {
                        objSetup.xero.isActive = true;
                        objSetup.xero.package = value.package;
                    };

                    if (value.project_name === "Kiiper Banking setup") {
                        objSetup.banking.isActive = true;
                    };

                    if (value.project_name === "Kiiper Taxes setup") {
                        objSetup.taxes.isActive = true;
                    };

                    if (value.project_name === "Xero consulting") {
                        objSetup.consulting.isActive = true;
                    };

                    if (value.project_name === "Kiiper Reporting setup") {
                        objSetup.reporting.isActive = true;
                    };

                    if (value.project_name === "Other setup") {
                        objSetup.other.isActive = true;
                        objSetup.other.software = value.version;
                    };
                })

                let priority_name = objSetup.xero.isActive ? "Xero" : objSetup.taxes.isActive ? "Kiiper Taxes" : objSetup.banking.isActive ? "Kiiper Banking" : objSetup.reporting.isActive ? "Kiiper Banking" : objSetup.other.isActive ? objSetup.other.software : "Consulting";
                values = {
                    name: proposalDetails.service_name,
                    country_name: proposalDetails.country_name,
                    priority_name: priority_name,
                };

                if (objSetup.consulting.isActive && !objSetup.banking.isActive && !objSetup.other.isActive && !objSetup.taxes.isActive && !objSetup.xero.isActive && !objSetup.reporting.isActive && priority_name === "Consulting") {
                    setTextService(true);
                } else {
                    setTextService(false);
                };
            } else {
                values = {
                    name: proposalDetails.service_name,
                    country_name: proposalDetails.country_name,
                };
                setTextService(false);
            };
        };
        setInfo(values);

        if (values.name === "Bookkeeper") {
            setIsBook(true);
        }
    }, [mode, proposalDetails, t]);

    return (
        <StyledContainer>
            <Box className={classes.body2}>
                {info.name === "Accountant" && (
                    <>
                        <Typography className={classes.textBoxM} variant="h5" color="secondary">
                            {t("proposals.accChose") + info.name}
                        </Typography>
                        <Typography variant="h5" className={classes.textBoxM}>
                            {t("proposals.accChose2")}
                        </Typography>
                        <Typography variant="h5" className={classes.textBoxM2} style={{ paddingTop: 60 }}>
                            {t("proposals.teamUsual") + " "}
                            <span style={{ color: "#FF5968" }}>{t("proposals.teamSecChalle")}</span>
                        </Typography>
                        <ul className={classes.ListText}>
                            <li>{t("proposals.teamChall1")}</li>
                            <li>{t("proposals.teamChall2")}</li>
                            <li>{t("proposals.teamChall3")}</li>
                        </ul>

                        <Typography variant="h5" className={classes.textBoxM2} style={{ paddingTop: 30 }}>
                            {t("proposals.teamServ1")}{" "}
                            <span style={{ color: "#FF5968" }}>{t("proposals.teamServ2")}</span>
                        </Typography>
                        <ul className={classes.ListText}>
                            <li>{t("proposals.teamServ3")}</li>
                            <li>{t("proposals.teamServ4")}</li>
                            <li>{t("proposals.teamServ5")}</li>
                        </ul>
                    </>
                )}
                {info.name === "Bookkeeper" && (
                    <>
                        <Typography className={classes.textBoxM} variant="h5" color="secondary">
                            {info.name}
                            <span style={{ color: "#031851" }}> {t("proposals.bookTeamB")}</span>
                        </Typography>
                        <Typography variant="h5" className={classes.textBoxM2} style={{ paddingTop: 60 }}>
                            {t("proposals.teamUsualBookB") + " "}
                            <span style={{ color: "#FF5968" }}>{t("proposals.teamSecChalleBookB")}</span>
                        </Typography>
                        <ul className={classes.ListText}>
                            <li>{t("proposals.teamChallBook1B")}</li>
                            <li>{t("proposals.teamChallBook2B")}</li>
                            <li>{t("proposals.teamChallBook3B")}</li>
                        </ul>

                        <Typography variant="h5" className={classes.textBoxM2} style={{ paddingTop: 30 }}>
                            {t("proposals.teamServBook1B")}{" "}
                            <span style={{ color: "#FF5968" }}>{t("proposals.teamServBook2B")}</span>
                        </Typography>
                        <ul className={classes.ListText}>
                            <li>{t("proposals.teamServBook3B")}</li>
                            <li>{t("proposals.teamServBook4B")}</li>
                            <li>{t("proposals.teamServBook5B")}</li>
                        </ul>
                    </>
                )}
                {info.name === "Controller" && (
                    <>
                        <Typography className={classes.textBoxM} variant="h5" color="secondary">
                            {t("proposals.accChose") + info.name}
                        </Typography>
                        <Typography variant="h5" className={classes.textBoxM}>
                            {t("proposals.accChoseCont")}
                        </Typography>
                        <Typography variant="h5" className={classes.textBoxM2} style={{ paddingTop: 60 }}>
                            {t("proposals.teamUsualCont") + " "}
                            <span style={{ color: "#FF5968" }}>{t("proposals.teamSecChalleCont")}</span>
                        </Typography>
                        <ul className={classes.ListText}>
                            <li>{t("proposals.teamChallCont1")}</li>
                            <li>{t("proposals.teamChallCont2")}</li>
                            <li>{t("proposals.teamChallCont3")}</li>
                        </ul>

                        <Typography variant="h5" className={classes.textBoxM2} style={{ paddingTop: 30 }}>
                            {t("proposals.teamServ1")}{" "}
                            <span style={{ color: "#FF5968" }}>{t("proposals.teamServCont")}</span>
                        </Typography>
                        <ul className={classes.ListText}>
                            <li>{t("proposals.teamServCont2")}</li>
                            <li>{t("proposals.teamServCont3")}</li>
                            <li>{t("proposals.teamServCont4")}</li>
                        </ul>
                    </>
                )}

                {info.name === "CFO" && (
                    <>
                        <Typography className={classes.textBoxM} variant="h5" color="secondary">
                            {t("proposals.accChose") + info.name}
                        </Typography>
                        <Typography variant="h5" className={classes.textBoxM}>
                            {t("proposals.cfoTeam1")}
                        </Typography>
                        <Typography variant="h5" className={classes.textBoxM2} style={{ paddingTop: 60 }}>
                            {t("proposals.cfoTeam2") + " "}
                            <span style={{ color: "#FF5968" }}>{t("proposals.cfoTeam3")}</span>
                        </Typography>
                        <ul className={classes.ListText}>
                            <li>{t("proposals.cfoTeam4")}</li>
                            <li>{t("proposals.cfoTeam5")}</li>
                            <li>{t("proposals.cfoTeam6")}</li>
                        </ul>
                        <Typography variant="h5" className={classes.textBoxM2} style={{ paddingTop: 30 }}>
                            {t("proposals.cfoTeamHelp1")}{" "}
                            <span style={{ color: "#FF5968" }}>{t("proposals.cfoTeamHelp2")}</span>
                        </Typography>
                        <ul className={classes.ListText}>
                            <li>{t("proposals.cfoTeamHelpDet1")}</li>
                            <li>{t("proposals.cfoTeamHelpDet2")}</li>
                            <li>{t("proposals.cfoTeamHelpDet3")}</li>
                        </ul>
                    </>
                )}

                {info.name && info.name === "Software subscription" && (
                    <>
                        <Typography className={classes.textBoxM} variant="h5" color="secondary">
                            {t("proposals.chooseSoft")}
                        </Typography>
                        <Typography variant="h5" className={classes.textBoxM}>
                            {t("proposals.accChose2")}
                        </Typography>
                        <Typography variant="h5" className={classes.textBoxM2} style={{ paddingTop: 60 }}>
                            {t("proposals.teamUsualSoft") + " "}
                            <span style={{ color: "#FF5968" }}>{t("proposals.teamSecChalleSoft")}</span>
                        </Typography>
                        <ul className={classes.ListText}>
                            <li>{t("proposals.teamChallSoft1")}</li>
                            <li>{t("proposals.teamChallSoft2")}</li>
                            <li>{t("proposals.teamChallSoft3")}</li>
                        </ul>

                        <Typography variant="h5" className={classes.textBoxM2} style={{ paddingTop: 30 }}>
                            {t("proposals.teamServ1")}{" "}
                            <span style={{ color: "#FF5968" }}>{t("proposals.teamServ2")}</span>
                        </Typography>
                        <ul className={classes.ListText}>
                            <li>{t("proposals.teamServSoft1")}</li>
                            <li>{t("proposals.teamServSoft2")}</li>
                            <li>{t("proposals.teamServSoft3")}</li>
                        </ul>
                    </>
                )}
                {info.name && info.name === "Software setup" && (
                    <>
                        <Grid container>
                            <Grid item xs={12} md={7}>
                                <Typography
                                    className={classes.textBoxM}
                                    variant="h5"
                                    color="secondary"
                                    sx={{ display: "inline" }}
                                >
                                    {t("proposals.accChose")} {info.priority_name}{" "}
                                </Typography>
                                <Typography variant="h5" className={classes.textBoxM} sx={{ display: "inline" }}>
                                    {textService ? t("proposals.accChose2") : t("proposals.accChose3")}
                                </Typography>
                            </Grid>
                        </Grid>

                        <Typography variant="h5" className={classes.textBoxM2} style={{ paddingTop: 60 }}>
                            {t("proposals.teamUsualSoftSetup") + " "}
                            <span style={{ color: "#FF5968" }}>{t("proposals.teamSecChalleSoftSetup")}</span>
                        </Typography>
                        <ul className={classes.ListText}>
                            <li>{t("proposals.teamChallSoftSetup1")}</li>
                            <li>{t("proposals.teamChallSoftSetup2")}</li>
                            <li>{t("proposals.teamChallSoftSetup3")}</li>
                        </ul>

                        <Typography variant="h5" className={classes.textBoxM2} style={{ paddingTop: 30 }}>
                            {t("proposals.teamServ6")}{" "}
                            <span style={{ color: "#FF5968" }}>{t("proposals.teamServ7")}</span>
                        </Typography>
                        <ul className={classes.ListText}>
                            <li>{t("proposals.teamServSoftSetup1")}</li>
                            <li>{t("proposals.teamServSoftSetup2")}</li>
                            <li>{t("proposals.teamServSoftSetup3")}</li>
                        </ul>
                    </>
                )}

                <Divider classes={{ root: classes.divider4 }} />

                {info.name === "Accountant" && (
                    <Typography variant="h5" className={classes.textBoxTeam} style={{ paddingBottom: 50 }}>
                        <span style={{ color: "#ff5968" }}>{t("proposals.teamExpert2")}</span>{" "}
                        {t("proposals.teamExpert")}
                    </Typography>
                )}

                {info.name === "Bookkeeper" && (
                    <Typography variant="h5" className={classes.textBoxTeam} style={{ paddingBottom: 50 }}>
                        <span style={{ color: "#ff5968" }}>{t("proposals.teamExpert2B")}</span>{" "}
                        {t("proposals.teamExpertBookB")}
                    </Typography>
                )}

                {info.name === "Controller" && (
                    <Typography variant="h5" className={classes.textBoxTeam} style={{ paddingBottom: 50 }}>
                        <span style={{ color: "#ff5968" }}>{t("proposals.teamExpertCont")}</span>{" "}
                        {t("proposals.teamExpertCont2")}
                    </Typography>
                )}

                {info.name === "CFO" && (
                    <Typography variant="h5" className={classes.textBoxTeam} style={{ paddingBottom: 50 }}>
                        <span style={{ color: "#ff5968" }}>{t("proposals.teamExpert2")}</span>{" "}
                        {t("proposals.teamExpertCFO")}
                    </Typography>
                )}

                {info.name && info.name === "Software subscription" && (
                    <Typography variant="h5" className={classes.textBoxTeam} style={{ paddingBottom: 50 }}>
                        <span style={{ color: "#ff5968" }}>{t("proposals.teamExpert2")}</span>{" "}
                        {t("proposals.teamExpertSoft")}
                    </Typography>
                )}
                {info.name && info.name === "Software setup" && (
                    <Typography variant="h5" className={classes.textBoxTeam} style={{ paddingBottom: 50 }}>
                        {t("proposals.teamExpert3")}{" "}
                        <span style={{ color: "#ff5968" }}>{t("proposals.teamExpert4")} </span>{" "}
                        {t("proposals.teamExpert5")}
                    </Typography>
                )}
                <Grid container>
                    <Grid item xs={12} md={2}>
                        <img src={GeraldineCallesImg} alt="GeraldineC" className={classes.teamImage} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography variant="h6" className={classes.teamName} color="secondary">
                            Geraldine Calles
                        </Typography>
                        <Typography variant="h4" style={{ paddingBottom: 10, fontSize: 14 }}>
                            CEO
                        </Typography>
                        <Typography variant="body2" className={classes.positionReview}>
                            {isBook ? t("proposals.quoteLeadsB") : t("proposals.quoteLeads")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        {info.name && info.name.includes("Software") ? (
                            <Grid container>
                                <Grid item md={4}>
                                    <img src={KeniaGarcia} alt="KeniaG" className={classes.teamImage2} />
                                </Grid>
                                <Grid item md={4}>
                                    <img src={DavidLoyo} alt="DavidL" className={classes.teamImage2} />
                                </Grid>
                                <Grid item md={4}>
                                    <img src={JoseAlvarez} alt="JoseA" className={classes.teamImage2} />
                                </Grid>
                                <Grid item md={4}>
                                    <img src={BrianTorres} alt="BrianT" className={classes.teamImage2} />
                                </Grid>
                                <Grid item md={4}>
                                    <img src={PedroHerrera} alt="PedroH" className={classes.teamImage2} />
                                </Grid>
                                <Grid item md={4}>
                                    <img src={MicaelaMedina} alt="MicaelaM" className={classes.teamImage2} />
                                </Grid>
                                <Grid item md={4}>
                                    <img src={JohannaRojas} alt="JohannaR" className={classes.teamImage2} />
                                </Grid>
                                <Grid item md={4}>
                                    <img src={GiancarloAlbani} alt="GiancarloA" className={classes.teamImage2} />
                                </Grid>
                                <Grid item md={4}>
                                    <img src={GabrielLoyo} alt="GabrielL" className={classes.teamImage2} />
                                </Grid>
                            </Grid>
                        ) : info.name === "CFO" ? (
                            <Grid container>
                                <Grid item md={4}>
                                    <img src={RodrigoQuintero} alt={"RodrigoQ"} className={classes.teamImage2} />
                                </Grid>
                                <Grid item md={4}>
                                    <img src={KeniaGarcia} alt="KeniaG" className={classes.teamImage2} />
                                </Grid>
                                <Grid item md={4}>
                                    <img src={JoseAlvarez} alt="JoseA" className={classes.teamImage2} />
                                </Grid>
                                <Grid item md={4}>
                                    <img src={DavidLoyo} alt="DavidL" className={classes.teamImage2} />
                                </Grid>
                                <Grid item md={4}>
                                    <img src={FeliceCalo} alt="FeliceC" className={classes.teamImage2} />
                                </Grid>
                                <Grid item md={4}>
                                    <img src={GiancarloAlbani} alt="GiancarloA" className={classes.teamImage2} />
                                </Grid>
                            </Grid>
                        ) : info.name === "Bookkeeper" ? (
                            <Grid container>
                                <Grid item md={4}>
                                    <img src={MariaBarrientos} alt="MariaB" className={classes.teamImage2} />
                                </Grid>
                                <Grid item md={4}>
                                    <img src={IraixyGuevara} alt="IraixyG" className={classes.teamImage2} />
                                </Grid>
                                <Grid item md={4}>
                                    <img src={KeniaGarcia} alt="KeniaG" className={classes.teamImage2} />
                                </Grid>
                                <Grid item md={4}>
                                    <img src={JoseAlvarez} alt="JoseA" className={classes.teamImage2} />
                                </Grid>
                                <Grid item md={4}>
                                    <img src={DavidLoyo} alt="DavidL" className={classes.teamImage2} />
                                </Grid>
                                <Grid item md={4}>
                                    <img src={GiancarloAlbani} alt="GiancarloA" className={classes.teamImage2} />
                                </Grid>
                            </Grid>
                        ) : info.name === "Accountant" ? (
                            <Grid container>
                                <Grid item md={4}>
                                    <img src={DeyaniraGoncalves} alt="DeyaniraG" className={classes.teamImage2} />
                                </Grid>
                                <Grid item md={4}>
                                    <img src={YaritzaRosas} alt={"YaritzaR"} className={classes.teamImage2} />
                                </Grid>
                                <Grid item md={4}>
                                    <img src={KeniaGarcia} alt="KeniaG" className={classes.teamImage2} />
                                </Grid>
                                <Grid item md={4}>
                                    <img src={DavidLoyo} alt="DavidL" className={classes.teamImage2} />
                                </Grid>
                                <Grid item md={4}>
                                    <img src={FeliceCalo} alt="FeliceC" className={classes.teamImage2} />
                                </Grid>
                                <Grid item md={4}>
                                    <img src={GiancarloAlbani} alt="GiancarloA" className={classes.teamImage2} />
                                </Grid>
                            </Grid>
                        ) : (
                            <Grid container>
                                <Grid item md={4}>
                                    <img src={DeyaniraGoncalves} alt={"DeyaniraG"} className={classes.teamImage2} />
                                </Grid>
                                <Grid item md={4}>
                                    <img src={KeniaGarcia} alt="KeniaG" className={classes.teamImage2} />
                                </Grid>
                                <Grid item md={4}>
                                    <img src={JoseAlvarez} alt="JoseA" className={classes.teamImage2} />
                                </Grid>
                                <Grid item md={4}>
                                    <img src={DavidLoyo} alt="DavidL" className={classes.teamImage2} />
                                </Grid>
                                <Grid item md={4}>
                                    <img src={FeliceCalo} alt="FeliceC" className={classes.teamImage2} />
                                </Grid>
                                <Grid item md={4}>
                                    <img src={GiancarloAlbani} alt="GiancarloA" className={classes.teamImage2} />
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Box>
            <Footer />
        </StyledContainer>
    );
};
