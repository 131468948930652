// React
import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

// MUI
import {
    Box,
    Grid,
    InputAdornment,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    TextField,
    Typography,
} from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { styled } from "@mui/material/styles";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

/** MUI icons */
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckIcon from "@mui/icons-material/Check";

const PREFIX = "Onboarding";

const classes = {
    btnRoot: `${PREFIX}-btnRoot`,
    buttonContainer: `${PREFIX}-buttonContainer`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    backgroundColor: "#F8F9F8",
    marginTop: 16,
    padding: 30,
    borderRadius: 30,

    [`& .${classes.btnRoot}`]: {
        border: "1px solid #ff5968",
        borderRadius: 40,
        "&:hover": {
            backgroundColor: "#2f3190",
            border: "1px solid #2f3190",
        },
    },
    [`& .${classes.buttonContainer}`]: {
        display: "flex",
        justifyContent: "flex-end",
        padding: 15,
    },
}));

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)({
    backgroundColor: "#F8F9F8",
    boxShadow: "none",
    "&:before": {
        display: "none",
    },
});

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: "#031851" }} />} {...props} />
))({
    "& .MuiAccordionSummary-content": {
        margin: "12px 0",
    },
});

const AccordionDetails = styled(MuiAccordionDetails)({
    padding: "0px 0px 20px 0px",
    "& .MuiCard-root": {
        boxShadow: "none",
    },
});

export const Onboarding = (props) => {
    // Properties
    const { valuesCFOOnboard, setValuesCFOOnboard, isError, isErrorFocus, setIsErrorFocus } = props;
    
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    /** React redux */
    const todos = useSelector((state) => state.todos);

    /** Component functions */
    const handleChangeDiscount = (event) => {
        let discountAux =
            event.target.value >= 0 && event.target.value <= 100
                ? event.target.value
                : valuesCFOOnboard.onboardDiscount;

        setValuesCFOOnboard({
            ...valuesCFOOnboard,
            onboardDiscount: discountAux,
            onboardProjectTotal: valuesCFOOnboard.onboardOriginalFee * (1 - discountAux / 100),
            isError:false
        });
        setIsErrorFocus(false)
    };

    const handleChangeMeetingDate = (date) => {
        setValuesCFOOnboard({
            ...valuesCFOOnboard,
            meetingDate: date,
            isError:false
        });
        setIsErrorFocus(false)
    };

    const handleChange = (event) => {
        setValuesCFOOnboard({
            ...valuesCFOOnboard,
            [event.target.name]: event.target.value,
            isError : false
        });
        setIsErrorFocus(false);
    };

    return (
        <StyledContainer>
            <LocalizationProvider
                dateAdapter={AdapterMoment}
                adapterLocale={t("language.locale")}
                localeText={{ clearButtonLabel: t("miscellaneous.clear") }}
            >
                <Grid container alignItems="flex-start" spacing={2}>
                    <Grid item xs={12}>
                        <Typography
                            variant="h1"
                            color="primary"
                            sx={{
                                display: "inline-block",
                                fontWeight: 700,
                                pr: 6,
                            }}
                        >
                            Onboarding
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Accordion
                            TransitionProps={{ unmountOnExit: true }}
                            sx={{
                                borderBottom: "3px solid #ff5968",
                            }}
                        >
                            <AccordionSummary aria-controls="panel-content" id="panel-header">
                                <Typography
                                    variant="h2"
                                    align="center"
                                    sx={{
                                        fontWeight: 700,
                                    }}
                                >
                                    {t("invoicing.description")}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <List>
                                    <ListItem disableGutters alignItems="center">
                                        <ListItemIcon sx={{ minWidth: 35 }}>
                                            <CheckIcon fontSize="small" sx={{ color: "#031851" }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={
                                                <Typography variant="body1">
                                                    <span style={{ fontWeight: 600 }}>{t("proposals.intel")} </span>-{" "}
                                                    {t("proposals.deepUnderBook3")}
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                    <ListItem disableGutters alignItems="center">
                                        <ListItemIcon sx={{ minWidth: 35 }}>
                                            <CheckIcon fontSize="small" sx={{ color: "#031851" }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={
                                                <Typography variant="body1">
                                                    <span style={{ fontWeight: 600 }}>{t("proposals.design")} </span>-{" "}
                                                    {t("proposals.designDet2")}
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                    <ListItem disableGutters alignItems="center">
                                        <ListItemIcon sx={{ minWidth: 35 }}>
                                            <CheckIcon fontSize="small" sx={{ color: "#031851" }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={
                                                <Typography variant="body1">
                                                    <span style={{ fontWeight: 600 }}>{t("proposals.setUp")} </span>-{" "}
                                                    {t("proposals.impleProcBook2")} 
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                </List>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                        <DatePicker
                            componentsProps={{
                                actionBar: { actions: ["clear"] },
                            }}
                            value={valuesCFOOnboard.meetingDate}
                            onChange={(newValue) => handleChangeMeetingDate(newValue)}
                            inputFormat={todos.dateFormat}
                            renderInput={(params) => (
                                <TextField
                                    fullWidth
                                    required
                                    {...params}
                                    error={isError && !valuesCFOOnboard.meetingDate}
                                    onClick={(e) =>{setIsErrorFocus(false)}}
                                />
                            )}
                            // inputProps={{ readOnly: true }}
                            label={t("services.meetingDate")}
                            inputProps={{
                                onKeyDown: (event) => {
                                    event.preventDefault();
                                },
                            }}
                            onClick={(e) =>{setIsErrorFocus(false)}}
                            inputRef={(input) => { (input && isErrorFocus && isError && !valuesCFOOnboard.meetingDate) && input.focus()}}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                        <TextField
                            fullWidth
                            label={t("miscellaneous.discount")}
                            value={valuesCFOOnboard.onboardDiscount}
                            name="onboardDiscount"
                            onChange={handleChangeDiscount}
                            onBlur={() =>
                                setValuesCFOOnboard({
                                    ...valuesCFOOnboard,
                                    onboardDiscount: parseFloat(
                                        valuesCFOOnboard.onboardDiscount === "" ? 0 : valuesCFOOnboard.onboardDiscount
                                    ).toFixed(2),
                                })
                            }
                            variant="outlined"
                            type="number"
                            InputProps={{
                                inputProps: {
                                    min: 0,
                                    max: 100,
                                },
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                            sx={{
                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                    display: "none",
                                },
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={6}>
                        <TextField
                            fullWidth
                            inputProps={{ maxLength: 100 }}
                            label={t("miscellaneous.comments")}
                            name="description"
                            onChange={handleChange}
                            value={valuesCFOOnboard.description}
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
            </LocalizationProvider>
        </StyledContainer>
    );
};
