/** React imports */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

/** Images */
import { ReactComponent as GcaLogo } from "../../../../assets/images/gca-logo-mobile.svg";
import FirstPerson from "../../../../assets/images/gca-proposal-front-1.png";
import SecondPerson from "../../../../assets/images/gca-proposal-front-2.png";
import ThirdPerson from "../../../../assets/images/gca-proposal-front-3.png";
import FourthPerson from "../../../../assets/images/gca-proposal-front-4.png";

/** MUI icons */

/** MUI imports */
import Grid from "@mui/material/Grid";
import { Box, Typography, Link } from "@mui/material";
import { styled } from "@mui/material/styles";

/** Global variables */
const PREFIX = "FrontPage";

const classes = {
    body: `${PREFIX}-body`,
    panelsL: `${PREFIX}-panelsL`,
    logoGCA: `${PREFIX}-logoGCA`,
    logoGCAText: `${PREFIX}-logoGCAText`,
    panels2: `${PREFIX}-panels2`,
    panelText1: `${PREFIX}-panelText1`,
    panelText2: `${PREFIX}-panelText2`,
    panel2Text: `${PREFIX}-panel2Text`,
    panels: `${PREFIX}-panels`,
    gcaText: `${PREFIX}-gcaText`,
    imgGCA: `${PREFIX}-imgGCA`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.body}`]: {
        [theme.breakpoints.down("md")]: {
            background: "#031851",
        },
        [theme.breakpoints.up("md")]: {
            background: "linear-gradient(90deg, #031851 35%, #2f3190 35%)",
        },
    },

    [`& .${classes.panelsL}`]: {
        [theme.breakpoints.down("md")]: {
            width: "100%",
        },
        [theme.breakpoints.up("md")]: {
            width: "35%",
        },
    },

    [`& .${classes.logoGCA}`]: {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        width: 150,
        marginTop: 50,
        paddingBottom: 15,
    },

    [`& .${classes.logoGCAText}`]: {
        textAlign: "center",
        fontSize: 12,
        fontWeight: 700,
    },

    [`& .${classes.panels2}`]: {
        [theme.breakpoints.down("md")]: {
            width: "100%",
            padding: "60px 30px 0px 30px",
        },
        [theme.breakpoints.up("md")]: {
            width: "65%",
            paddingTop: 60,
        },
    },

    [`& .${classes.panelText1}`]: {
        [theme.breakpoints.down("md")]: {
            width: "100%",
            fontSize: 36,
        },
        width: 400,
        fontSize: 46,
        margin: "0 auto",
        paddingBottom: 5,
    },

    [`& .${classes.panelText2}`]: {
        [theme.breakpoints.down("md")]: {
            width: "100%",
            fontSize: 30,
        },
        [theme.breakpoints.up("md")]: {
            width: 400,
        },
        color: "white",
        margin: "0 auto",
        paddingBottom: 100,
        fontSize: 30,
    },

    [`& .${classes.panel2Text}`]: {
        [theme.breakpoints.down("md")]: {
            width: "100%",
        },
        [theme.breakpoints.up("md")]: {
            width: 400,
        },
        margin: "0 auto",
    },

    [`& .${classes.panels}`]: {
        [theme.breakpoints.down("md")]: {
            width: "100%",
            padding: "60px 30px 0 30px",
        },
        [theme.breakpoints.up("md")]: {
            width: "35%",
            padding: "170px 45px 0 45px",
        },
    },

    [`& .${classes.gcaText}`]: {
        [theme.breakpoints.up("md")]: {
            marginTop: 300,
            textAlign: "center",
            fontSize: 16,
        },
    },

    [`& .${classes.imgGCA}`]: {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        filter: "drop-shadow(2px 0px 6px black)",
        [theme.breakpoints.down("md")]: {
            width: "100%",
        },
        [theme.breakpoints.up("md")]: {
            width: 400,
        },
    },
}));

export const FrontPage = (props) => {
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    const { proposalDetails, mode, randomNum, setRandomNum } = props;
    const [info, setInfo] = useState({});
    const [texts, setTexts] = useState({});
    const [image, setImage] = useState(1);
    // const [isBook, setIsBook] = useState(false);

    const randomIntFromInterval = (min, max) => {
        // min and max included
        return Math.floor(Math.random() * (max - min + 1) + min);
    };

    useEffect(() => {
        let values = {};
        let name = "";
        if (mode === "preview") {
            let random = 0;

            values = {
                prospect_name: proposalDetails.prospect.prospect_name,
                prospect_last_name: proposalDetails.prospect.prospect_last_name,
                company_legal_name: proposalDetails.prospect.company_legal_name,
            };
            name = proposalDetails.name;

            // Validamos si ya tiene una imagen asociada para mostrar
            random = randomNum ?? randomIntFromInterval(1, 4);

            setRandomNum(random);
            setImage(random);
        } else {
            values = {
                prospect_name: proposalDetails.prospect_name,
                company_legal_name: proposalDetails.company_name,
            };
            name = proposalDetails.service_name;

            setImage(proposalDetails.front_image);
        }
        setTexts({
            maintitle:
                name === "Accountant"
                    ? t("proposals.insightBook")
                    : name === "Bookkeeper"
                    ? t("proposals.bookServicesB")
                    : name === "Controller"
                    ? t("proposals.expAudi")
                    : name === "CFO"
                    ? t("proposals.cfoFrontMain")
                    : name === "Software subscription"
                    ? t("proposals.softwareImp")
                    : t("proposals.softwareImp3"),
            secondTitle:
                name === "Accountant"
                    ? t("proposals.bussGrow")
                    : name === "Bookkeeper"
                    ? t("proposals.bookServicesSubB")
                    : name === "Controller"
                    ? t("proposals.expAudiSub")
                    : name === "CFO"
                    ? t("proposals.cfoFrontSecond")
                    : name === "Software subscription"
                    ? t("proposals.softwareImp2")
                    : t("proposals.softwareImp4"),
        });
        setInfo(values);

        if(name === "Bookkeeper") {
            // setIsBook(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mode, proposalDetails, t]);

    return (
        <StyledContainer>
            <Box className={classes.body}>
                <Grid container alignItems="center">
                    <Grid item className={classes.panelsL}>
                        <Link href="https://gca.digital" target="_blank" underline="none">
                            <GcaLogo className={classes.logoGCA} />
                            <Typography color="secondary" className={classes.logoGCAText}>
                                Growing Companies Advisors
                            </Typography>
                        </Link>
                    </Grid>
                </Grid>
                <Grid container alignItems="center" justifyContent="flex-end">
                    <Grid item className={classes.panels2}>
                        <Typography variant="h5" color="secondary" className={classes.panelText1}>
                            {texts.maintitle}
                        </Typography>
                        <Typography className={classes.panelText2} variant="h5">
                            {texts.secondTitle}
                        </Typography>
                        <Typography className={classes.panel2Text} variant="h6" color="secondary">
                            {info.prospect_name + " " + (info.prospect_last_name ?? "")}
                        </Typography>
                        <Typography className={classes.panel2Text} variant="body1" sx={{ color: "white" }}>
                            {info.company_legal_name}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container alignItems="center">
                    <Grid item className={classes.panels}>
                        <Link href="https://gca.digital" target="_blank" underline="none">
                            <Typography color="secondary" variant="h6" className={classes.gcaText}>
                                www.GCA.digital
                            </Typography>
                        </Link>
                    </Grid>
                    <Grid item className={classes.panels2}>
                        <img
                            alt="gca-img"
                            src={
                                image === 1
                                    ? FirstPerson
                                    : image === 2
                                    ? SecondPerson
                                    : image === 3
                                    ? ThirdPerson
                                    : FourthPerson
                            }
                            className={classes.imgGCA}
                        />
                    </Grid>
                </Grid>
            </Box>
        </StyledContainer>
    );
};
