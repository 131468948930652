/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

/** Icons */

/** MUI icons */
import CancelIcon from "@mui/icons-material/Cancel";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";

/** MUI imports */
import { styled } from "@mui/material/styles";
import { Container, Box, Alert } from "@mui/material";

/** Components imports */
import { FrontPage, Team, ServiceLevels, Pricing, Schedule, TermsConditions, FinalPage } from "./Core";

/** Global variables */
const PREFIX = "ProposalPreview";

const classes = {
    divider: `${PREFIX}-divider`,
    spacing: `${PREFIX}-spacing`,
};

const StyledContainer = styled(Container)(({ theme }) => ({
    [`& .${classes.divider}`]: {
        background: "#4F5D85",
        height: 20,
        margin: "",
    },

    [`& .${classes.spacing}`]: {
        padding: "20px 0 25px 0",
    },
}));

const ProposalPreview = (props) => {
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    const { proposalDetails, tariff, tariffAdditionals, preferentialFee, randomNum, setRandomNum } = props;
    const [activeServ, setActiveServ] = useState({});
    const [status, setStatus] = useState(0);
    const [isSetup, setIsSetup] = useState(false);

    useEffect(() => {
        let values = {};
        let status = 0;

        proposalDetails.forEach((item) => {
            status = item.status;
            if (item.name === "Accountant") {
                values.accountant = item;
            } else if (item.name === "Bookkeeper") {
                values.bookkeeper = item;
            } else if (item.name === "Controller") {
                values.controller = item;
            } else if (item.name === "CFO") {
                values.cfo = item;
            } else if (item.name === "Software setup" || item.name === "Software subscription") {
                if (item.name === "Software subscription") {
                    values.software = item;
                }
                if (item.name === "Software setup") {
                    setIsSetup(true);
                    if (values["software"] === undefined) {
                        values.software = item;
                    }
                }
            }
        });

        setStatus(status);
        setActiveServ(values);
    }, [proposalDetails]);

    return (
        <StyledContainer maxWidth="md" disableGutters sx={{ flexGrow: 1, width: "100%", backgroundColor: "#e9eae8" }}>
            <Box sx={{ margin: "20px auto", width: "fit-content" }}>
                {status === 2 && (
                    <Alert
                        icon={<AccessTimeFilledIcon fontSize="inherit" />}
                        severity="warning"
                        sx={{ borderRadius: 40 }}
                    >
                        {t("miscellaneous.proposalWaiting")}
                    </Alert>
                )}
                {status === 3 && (
                    <Alert severity="success" sx={{ borderRadius: 40 }}>
                        {t("proposals.acceptMess")}
                    </Alert>
                )}
                {status === 4 && (
                    <Alert icon={<CancelIcon fontSize="inherit" />} severity="error" sx={{ borderRadius: 40 }}>
                        {" "}
                        {t("proposals.declineMess")}
                    </Alert>
                )}
            </Box>
            {activeServ.accountant && (
                <>
                    <FrontPage
                        proposalDetails={activeServ.accountant}
                        randomNum={randomNum}
                        setRandomNum={setRandomNum}
                        mode="preview"
                    />
                    <Team proposalDetails={activeServ.accountant} mode="preview" />
                    {(activeServ.accountant.onboarding.isActive ||
                        activeServ.accountant.cleanUp.isActive ||
                        activeServ.accountant.catchUp.isActive ||
                        activeServ.accountant.recurring.isActive) && (
                            <ServiceLevels
                                proposalDetails={proposalDetails}
                                mode="preview"
                                includeAccModuleSet={props.includeAccModuleSet}
                                includeAccModuleSub={props.includeAccModuleSub}
                                includeAdmModuleSet={props.includeAdmModuleSet}
                                includeAdmModuleSub={props.includeAdmModuleSub}
                                accountantOnboarding={props.accountantOnboarding}
                            />
                        )}
                    <Pricing proposalDetails={proposalDetails} mode="preview" />
                    {(activeServ.accountant.onboarding.isActive ||
                        activeServ.accountant.cleanUp.isActive ||
                        activeServ.accountant.catchUp.isActive ||
                        activeServ.accountant.recurring.isActive) && (
                            <Schedule
                                proposalDetails={proposalDetails}
                                mode="preview"
                                includeAccModuleSet={props.includeAccModuleSet}
                                includeAccModuleSub={props.includeAccModuleSub}
                                includeAdmModuleSet={props.includeAdmModuleSet}
                                includeAdmModuleSub={props.includeAdmModuleSub}
                                accountantOnboarding={props.accountantOnboarding}
                                isAccountant={activeServ?.accountant}
                            />
                        )}
                    <TermsConditions
                        proposalDetails={proposalDetails}
                        mode="preview"
                        market={
                            proposalDetails[0]?.prospect?.country_name === "Estados Unidos" ? "US"
                                : proposalDetails[0]?.prospect?.country_name === "Venezuela" ? "VE"
                                    : proposalDetails[0]?.prospect?.country_name === "LATAM" ? "LA"
                                        : "GB"
                        }
                    />
                    <FinalPage proposalDetails={activeServ.accountant} mode="preview" />
                </>
            )}
            {activeServ.bookkeeper && (
                <>
                    <FrontPage
                        proposalDetails={activeServ.bookkeeper}
                        randomNum={randomNum}
                        setRandomNum={setRandomNum}
                        mode="preview"
                    />
                    <Team proposalDetails={activeServ.bookkeeper} mode="preview" />
                    <ServiceLevels
                        proposalDetails={proposalDetails}
                        mode="preview"
                        includeToolbox={props.includeToolbox}
                        includeOnboarding={props.includeOnboarding}
                        includeAccModuleSet={props.includeAccModuleSet}
                        includeAccModuleSub={props.includeAccModuleSub}
                        includeAdmModuleSet={props.includeAdmModuleSet}
                        includeAdmModuleSub={props.includeAdmModuleSub}
                        isBook={activeServ?.bookkeeper}
                    />
                    <Pricing
                        proposalDetails={proposalDetails}
                        tariff={tariff}
                        tariffAdditionals={tariffAdditionals}
                        preferentialFee={preferentialFee}
                        mode="preview"
                        includeToolbox={props.includeToolbox}
                        includeOnboarding={props.includeOnboarding}
                    />
                    <Schedule
                        proposalDetails={proposalDetails}
                        mode="preview"
                        includeToolbox={props.includeToolbox}
                        includeOnboarding={props.includeOnboarding}
                        isBook={activeServ?.bookkeeper}
                        includeAccModuleSet={props.includeAccModuleSet}
                        includeAccModuleSub={props.includeAccModuleSub}
                        includeAdmModuleSet={props.includeAdmModuleSet}
                        includeAdmModuleSub={props.includeAdmModuleSub}
                    />
                    <TermsConditions
                        proposalDetails={proposalDetails}
                        mode="preview"
                        includeToolbox={props.includeToolbox}
                        includeOnboarding={props.includeOnboarding}
                        market={
                            proposalDetails[0]?.prospect?.country_name === "Estados Unidos" ? "US"
                                : proposalDetails[0]?.prospect?.country_name === "Venezuela" ? "VE"
                                    : proposalDetails[0]?.prospect?.country_name === "LATAM" ? "LA"
                                        : "GB"
                        }
                        isBook={activeServ?.bookkeeper}
                    />
                    <FinalPage proposalDetails={activeServ.bookkeeper} mode="preview" />
                </>
            )}
            {activeServ.controller && !activeServ.accountant && (
                <>
                    <FrontPage
                        proposalDetails={activeServ.controller}
                        randomNum={randomNum}
                        setRandomNum={setRandomNum}
                        mode="preview"
                    />
                    <Team proposalDetails={activeServ.controller} mode="preview" />
                    {(activeServ.controller.onboarding.isActive ||
                        activeServ.controller.catchUp.isActive ||
                        activeServ.controller.recurring.isActive) && (
                            <ServiceLevels
                                proposalDetails={proposalDetails}
                                mode="preview"
                                includeToolbox={props.includeToolbox}
                                includeOnboarding={props.includeOnboarding}
                                includeAccModuleSet={props.includeAccModuleSet}
                                includeAccModuleSub={props.includeAccModuleSub}
                                includeAdmModuleSet={props.includeAdmModuleSet}
                                includeAdmModuleSub={props.includeAdmModuleSub}
                            />
                        )}
                    <Pricing proposalDetails={proposalDetails} mode="preview" />
                    {(activeServ.controller.onboarding.isActive ||
                        activeServ.controller.consulting.isActive ||
                        activeServ.controller.recurring.isActive) && (
                            <Schedule
                                proposalDetails={proposalDetails}
                                mode="preview"
                                includeAccModuleSet={props.includeAccModuleSet}
                                includeAccModuleSub={props.includeAccModuleSub}
                                includeAdmModuleSet={props.includeAdmModuleSet}
                                includeAdmModuleSub={props.includeAdmModuleSub}
                            />
                        )}
                    <TermsConditions
                        proposalDetails={proposalDetails}
                        mode="preview"
                        market={
                            proposalDetails[0]?.prospect?.country_name === "Estados Unidos" ? "US"
                                : proposalDetails[0]?.prospect?.country_name === "Venezuela" ? "VE"
                                    : proposalDetails[0]?.prospect?.country_name === "LATAM" ? "LA"
                                        : "GB"
                        }
                    />
                    <FinalPage proposalDetails={activeServ.controller} mode="preview" />
                </>
            )}
            {activeServ.cfo && !activeServ.accountant && !activeServ.controller && (
                <>
                    <FrontPage
                        proposalDetails={activeServ.cfo}
                        randomNum={randomNum}
                        setRandomNum={setRandomNum}
                        mode="preview"
                    />
                    <Team proposalDetails={activeServ.cfo} mode="preview" />
                    {(activeServ.cfo.onboarding.isActive ||
                        activeServ.cfo.catchUp.isActive ||
                        activeServ.cfo.recurring.isActive) && (
                            <ServiceLevels
                                proposalDetails={proposalDetails}
                                mode="preview"
                                includeToolbox={props.includeToolbox}
                                includeOnboarding={props.includeOnboarding}
                                includeAccModuleSet={props.includeAccModuleSet}
                                includeAccModuleSub={props.includeAccModuleSub}
                                includeAdmModuleSet={props.includeAdmModuleSet}
                                includeAdmModuleSub={props.includeAdmModuleSub}
                            />
                        )}
                    <Pricing proposalDetails={proposalDetails} mode="preview" />
                    {(activeServ.cfo.onboarding.isActive ||
                        activeServ.cfo.catchUp.isActive ||
                        activeServ.cfo.recurring.isActive) && (
                            <Schedule
                                proposalDetails={proposalDetails}
                                mode="preview"
                                includeToolbox={props.includeToolbox}
                                includeOnboarding={props.includeOnboarding}
                                includeAccModuleSet={props.includeAccModuleSet}
                                includeAccModuleSub={props.includeAccModuleSub}
                                includeAdmModuleSet={props.includeAdmModuleSet}
                                includeAdmModuleSub={props.includeAdmModuleSub}
                            />
                        )}
                    <TermsConditions
                        proposalDetails={proposalDetails}
                        mode="preview"
                        market={
                            proposalDetails[0]?.prospect?.country_name === "Estados Unidos" ? "US"
                                : proposalDetails[0]?.prospect?.country_name === "Venezuela" ? "VE"
                                    : proposalDetails[0]?.prospect?.country_name === "LATAM" ? "LA"
                                        : "GB"
                        }
                    />
                    <FinalPage proposalDetails={activeServ.cfo} mode="preview" />
                </>
            )}
            {activeServ.software &&
                !activeServ.accountant &&
                !activeServ.bookkeeper &&
                !activeServ.controller &&
                !activeServ.cfo && (
                    <>
                        <FrontPage
                            proposalDetails={activeServ.software}
                            setRandomNum={setRandomNum}
                            mode="preview"
                        />
                        <Team proposalDetails={activeServ.software} mode="preview" />
                        <ServiceLevels
                            proposalDetails={proposalDetails}
                            mode="preview"
                            includeAccModuleSet={props.includeAccModuleSet}
                            includeAccModuleSub={props.includeAccModuleSub}
                            includeAdmModuleSet={props.includeAdmModuleSet}
                            includeAdmModuleSub={props.includeAdmModuleSub}
                            accountantOnboarding={props.accountantOnboarding}
                            isBook={activeServ?.bookkeeper}
                            isAccountant={activeServ?.accountant}
                        />
                        <Pricing proposalDetails={proposalDetails} mode="preview" />
                        {isSetup && <Schedule
                            proposalDetails={proposalDetails}
                            mode="preview"
                            includeAccModuleSet={props.includeAccModuleSet}
                            includeAccModuleSub={props.includeAccModuleSub}
                            includeAdmModuleSet={props.includeAdmModuleSet}
                            includeAdmModuleSub={props.includeAdmModuleSub}
                            accountantOnboarding={props.accountantOnboarding}
                            isAccountant={activeServ?.accountant}
                            isBook={activeServ?.bookkeeper}
                        />}
                        <TermsConditions
                            proposalDetails={proposalDetails}
                            mode="preview"
                            market={
                                proposalDetails[0]?.prospect?.country_name === "Estados Unidos" ? "US"
                                    : proposalDetails[0]?.prospect?.country_name === "Venezuela" ? "VE"
                                        : proposalDetails[0]?.prospect?.country_name === "LATAM" ? "LA"
                                            : "GB"
                            }
                        />
                        <FinalPage proposalDetails={activeServ.software} mode="preview" />
                    </>
                )}
        </StyledContainer>
    );
};

export default ProposalPreview;
