import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

/** MUI */
import {
    Box,
    FormControlLabel,
    Card,
    Checkbox,
    Grid,
    Divider,
    Select,
    FormControl,
    InputLabel,
    MenuItem,
    TextField,
    CardHeader,
    IconButton,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

/** MUI icons */
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "rgba(19, 31, 62, 0.80)",
        fontSize: 12,
        maxWidth: 320,
        borderRadius: 6,
        fontWeight: "normal",
    },
});

const RecurrentConfig = ({
    recurrentVal,
    setRecurrentVal,
    isError,
    setTexto,
    setAlertType,
    setShow,
    rowsRecu,
    setRecuConfig,
}) => {
    const todos = useSelector((state) => state.todos);

    /** Internationalization i18n */
    const [t] = useTranslation("global");

    const handleChangeCheck = (event, name, index) => {
        let obj = {
            ...recurrentVal,
            [event.target.name]: event.target.checked,
            ["period" + name]: "",
            ["recepDays" + name]: event.target.checked ? 2 : "",
            ["initDate" + name]: null,
            ["endDate" + name]: null,
        };

        if (name.includes("Other")) {
            obj[`nameOther${index}`] = "";
        }

        setRecurrentVal(obj);
    };

    const handleChange = (event) => {
        //recepDays
        if (event.target.name.includes("recepDays")) {
            if (event.target.value > 0 && event.target.value <= 31) {
                setRecurrentVal({
                    ...recurrentVal,
                    [event.target.name]: parseInt(event.target.value),
                });
            } else {
                setRecurrentVal({
                    ...recurrentVal,
                    [event.target.name]: 2,
                });
            }
        } else {
            setRecurrentVal({
                ...recurrentVal,
                [event.target.name]: event.target.value,
            });
        }
    };

    // Función para determinar el tipo de requerimiento a comparar
    const checkType = (type) => {
        let keyName = "";

        switch (type) {
            case 0:
                keyName = "Nom";
                break;
            case 1:
                keyName = "Cont";
                break;
            case 2:
                keyName = "Sells";
                break;
            case 3:
                keyName = "ISLR";
                break;
            default:
                keyName = `Other${type}`;
                break;
        }
        return keyName;
    };

    const handleChangeInitDate = (date, type) => {
        let startDate = date ? date.startOf("month") : null;
        const keyName = checkType(type);

        if (
            recurrentVal["endDate" + keyName] &&
            startDate &&
            startDate.isAfter(recurrentVal["endDate" + keyName].format("YYYY-MM-DD"))
        ) {
            setRecurrentVal({
                ...recurrentVal,
                ["initDate" + keyName]: null,
            });

            setTexto(t("reports.initDateMess"));
            setAlertType("warning");
            setShow(true);
        } else {
            setRecurrentVal({
                ...recurrentVal,
                ["initDate" + keyName]: startDate,
            });
        }
    };

    const handleChangeEndDate = (date, type) => {
        let endDate = date ? date.endOf("month").startOf("day") : null;

        const keyName = checkType(type);

        if (
            recurrentVal["initDate" + keyName] &&
            endDate &&
            endDate.isBefore(recurrentVal["initDate" + keyName].format("YYYY-MM-DD"))
        ) {
            setRecurrentVal({
                ...recurrentVal,
                ["endDate" + keyName]: null,
            });

            setTexto(t("reports.endDateMess"));
            setAlertType("warning");
            setShow(true);
        } else {
            setRecurrentVal({
                ...recurrentVal,
                ["endDate" + keyName]: endDate,
            });
        }
    };

    const addOthers = () => {
        const newObj = { ...recurrentVal };

        newObj[`checkedOther${rowsRecu.length}`] = false;
        newObj[`nameOther${rowsRecu.length}`] = "";
        newObj[`periodOther${rowsRecu.length}`] = "";
        newObj[`recepDaysOther${rowsRecu.length}`] = "";
        newObj[`initDateOther${rowsRecu.length}`] = null;
        newObj[`endDateOther${rowsRecu.length}`] = null;

        setRecurrentVal(newObj);
        setRecuConfig([...rowsRecu, `Other${rowsRecu.length}`]);
    };

    return (
        <Box sx={{ p: 2 }}>
            <LocalizationProvider
                dateAdapter={AdapterMoment}
                adapterLocale={t("language.locale")}
                localeText={{ clearButtonLabel: t("miscellaneous.clear") }}
            >
                <Card elevation={0}>
                    <CardHeader title={t("principal.selectReqAct")} sx={{ color: "#ff5968" }} />
                    <Divider sx={{ marginBottom: "30px" }} />
                    {rowsRecu.map((name, index) => (
                        <Grid container alignItems="center" spacing={2} sx={{ pt: 2 }}>
                            <Grid item xs={12} md>
                                {name.includes("Other") ? (
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={12} md={3} sx={{ paddingLeft: "16px !important" }}>
                                            <LightTooltip
                                                disableHoverListener={!recurrentVal[`uploadedOther${index}`]}
                                                title={t("bank.toolCantDisable")}
                                                aria-label="toolUpload"
                                            >
                                                <span>
                                                    <Checkbox
                                                        name={`checkedOther${index}`}
                                                        checked={recurrentVal[`checkedOther${index}`]}
                                                        onChange={(value) => handleChangeCheck(value, name, index)}
                                                        inputProps={{
                                                            "aria-label": "controlled",
                                                        }}
                                                        disabled={recurrentVal[`uploadedOther${index}`]}
                                                    />
                                                </span>
                                            </LightTooltip>
                                        </Grid>
                                        <Grid item xs={12} md={9}>
                                            <TextField
                                                fullWidth
                                                disabled={!recurrentVal[`checkedOther${index}`]}
                                                label={t("services.others")}
                                                name={`nameOther${index}`}
                                                onChange={handleChange}
                                                value={recurrentVal[`nameOther${index}`]}
                                                variant="outlined"
                                                required={recurrentVal[`checkedOther${index}`]}
                                                error={
                                                    recurrentVal[`checkedOther${index}`] &&
                                                    isError &&
                                                    !recurrentVal[`nameOther${index}`]
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                ) : (
                                    <LightTooltip
                                        disableHoverListener={!recurrentVal["uploaded" + name]}
                                        title={t("bank.toolCantDisable")}
                                        aria-label="toolUpload"
                                    >
                                        <span>
                                            <FormControlLabel
                                                sx={{ paddingLeft: "11px !important" }}
                                                control={
                                                    <Checkbox
                                                        name={"checked" + name}
                                                        checked={recurrentVal["checked" + name]}
                                                        onChange={(event) => handleChangeCheck(event, name)}
                                                        inputProps={{ "aria-label": "controlled" }}
                                                        disabled={recurrentVal["uploaded" + name]}
                                                    />
                                                }
                                                label={t("miscellaneous.report" + name)}
                                            />
                                        </span>
                                    </LightTooltip>
                                )}
                            </Grid>
                            <Grid item xs={12} md>
                                <FormControl
                                    fullWidth
                                    required={recurrentVal["checked" + name]}
                                    error={recurrentVal["checked" + name] && isError && !recurrentVal["period" + name]}
                                    disabled={!recurrentVal["checked" + name]}
                                >
                                    <InputLabel id="period-simple-select-label">{t("principal.period")}</InputLabel>
                                    <Select
                                        name={"period" + name}
                                        labelId="period-simple-select-label"
                                        IconComponent={KeyboardArrowDownIcon}
                                        value={recurrentVal["period" + name]}
                                        label={t("principal.period")}
                                        onChange={handleChange}
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    mt: 0.7,
                                                    borderRadius: 2,
                                                    boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                },
                                            },
                                        }}
                                    >
                                        <MenuItem value={1}>{t("miscellaneous.weekly")}</MenuItem>
                                        <MenuItem value={2}>{t("miscellaneous.quince")}</MenuItem>
                                        <MenuItem value={3}>{t("miscellaneous.monthly")}</MenuItem>
                                        <MenuItem value={4}>{t("miscellaneous.annual")}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md>
                                <TextField
                                    fullWidth
                                    required={recurrentVal[`checked${name}`]}
                                    label={t("principal.recepDays")}
                                    name={`recepDays${name}`}
                                    onChange={handleChange}
                                    value={recurrentVal[`recepDays${name}`]}
                                    variant="outlined"
                                    type="number"
                                    inputProps={{
                                        min: 1,
                                        max: 31,
                                        step: 1,
                                    }}
                                    disabled={!recurrentVal[`checked${name}`]}
                                    error={
                                        recurrentVal[`checked${name}`] && isError && !recurrentVal[`recepDays${name}`]
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} md>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    value={recurrentVal["initDate" + name]}
                                    onChange={(newValue) => handleChangeInitDate(newValue, index)}
                                    inputFormat={todos.dateFormat}
                                    disabled={!recurrentVal["checked" + name]}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            required={recurrentVal["checked" + name]}
                                            {...params}
                                            error={
                                                recurrentVal["checked" + name] &&
                                                isError &&
                                                !recurrentVal["initDate" + name]
                                            }
                                        />
                                    )}
                                    inputProps={{ readOnly: true }}
                                    label={t("reports.initDate")}
                                    views={["year", "month"]}
                                />
                            </Grid>
                            <Grid item xs={12} md>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    value={recurrentVal["endDate" + name]}
                                    onChange={(newValue) => handleChangeEndDate(newValue, index)}
                                    inputFormat={todos.dateFormat}
                                    disabled={!recurrentVal["checked" + name]}
                                    renderInput={(params) => <TextField fullWidth {...params} />}
                                    inputProps={{ readOnly: true }}
                                    label={t("reports.endDate")}
                                    views={["year", "month"]}
                                />
                            </Grid>
                        </Grid>
                    ))}
                    <Grid container spacing={2} justifyContent="center" sx={{ pt: 2 }}>
                        <Grid item>
                            <LightTooltip title={t("principal.addOtherRecu")} aria-label="add">
                                <IconButton variant="outlined" onClick={addOthers}>
                                    <AddBoxOutlinedIcon color="primary" fontSize="large" />
                                </IconButton>
                            </LightTooltip>
                        </Grid>
                    </Grid>
                </Card>
            </LocalizationProvider>
        </Box>
    );
};

export default RecurrentConfig;
