/** React imports */
import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import moment from "moment";

/** MUI imports */
import { Grid, Table, TableHeaderRow, PagingPanel, Toolbar, SearchPanel } from "@devexpress/dx-react-grid-material-ui";
import { SearchState, IntegratedFiltering, PagingState, IntegratedPaging } from "@devexpress/dx-react-grid";
import { styled } from "@mui/material/styles";
import GridUI from "@mui/material/Grid";
import { Box, Skeleton, FormControl, Select, InputLabel, MenuItem, OutlinedInput, Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const PREFIX = "Monitoring";

const classes = {
    tableStriped: `${PREFIX}-tableStriped`,
    tableHeader: `${PREFIX}-tableHeader`,
    tableBody: `${PREFIX}-tableBody`,
    pager: `${PREFIX}-pager`,
    spacing: `${PREFIX}-spacing`,
    toolbarSearch: `${PREFIX}-toolbarSearch`,
    searchInput: `${PREFIX}-searchInput`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.spacing}`]: {
        padding: "10px 0 10px 0",
    },

    [`& .${classes.tableStriped}`]: {
        "& tbody tr:nth-of-type(odd)": {
            backgroundColor: "rgba(47,49,144,0.1)",
        },
    },
    [`& .${classes.tableHeader}`]: {
        border: "none",
        backgroundColor: "rgba(47,49,144,0.3)",
        fontSize: "15px",
        fontWeight: 600,
        color: "#031851",
        "&:first-of-type": {
            borderRadius: "20px 0 0 0",
        },
        "&:last-child": {
            borderRadius: "0 20px 0 0",
        },
    },

    [`& .${classes.tableBody}`]: {
        border: "none",
        fontSize: "14px",
        fontWeight: 400,
        color: "#031851",
    },

    [`& .${classes.pager}`]: {
        "& .Pagination-button": {
            paddingTop: 0,
            paddingBottom: 0,
            color: "#031851",
            fontWeight: 600,
        },
        "& .Pagination-activeButton": {
            backgroundColor: "rgba(47,49,144,0.3)",
        },
    },

    [`& .${classes.toolbarSearch}`]: {
        border: "none !important",
        padding: "0 0 5px !important",
    },

    [`& .${classes.searchInput}`]: {
        color: "#031851",
        "&:before": {
            borderBottomColor: "#031851",
        },
        "&:hover:before": {
            borderBottomColor: "#031851 !important",
        },
    },
}));

const tableHeaderCell = ({ ...props }) => <TableHeaderRow.Cell {...props} className={classes.tableHeader} />;

const TableComponent = ({ ...props }) => <Table.Table {...props} className={classes.tableStriped} />;

const TableCellComponent = ({ ...props }) => <Table.Cell {...props} className={classes.tableBody} />;

const tableContentComponent = (props) => <TableHeaderRow.Content {...props} />;

const PagingPanelContainer = ({ ...props }) => <PagingPanel.Container {...props} className={classes.pager} />;

const inputComponent = ({ ...props }) => <SearchPanel.Input {...props} className={classes.searchInput} />;

const toolRootComponent = ({ ...props }) => <Toolbar.Root {...props} className={classes.toolbarSearch} />;

const tableColumnExtensions = [
    { columnName: "typeClient" },
    { columnName: "franq", width: "250px" },
    { columnName: "group", width: "150px" },
    { columnName: "clientRS", width: "320px" },
    { columnName: "clasi", width: "180px" },
    { columnName: "analyst", width: "220px" },
    { columnName: "supervisor", width: "220px" },
    { columnName: "manager", width: "220px" },
    { columnName: "dependency", width: "220px" },
    { columnName: "closureStatus", width: "220px" },
    { columnName: "lastClosedMonth", width: "150px", align: "center" },
    { columnName: "registeredReportDays", width: "150px", align: "center" },
    { columnName: "lastReportDate", width: "220px" },
];

const tableColumnExtensions2 = [
    { columnName: "franq", width: "320px" },
    { columnName: "smb" },
    { columnName: "bank", width: "320px" },
    { columnName: "date", width: "150px" },
    { columnName: "hour", width: "150px" },
    { columnName: "lastValidationDate", width: "150px" },
];

const tableColumnExtensions3 = [
    { columnName: "franq", width: "250px" },
    { columnName: "group", width: "150px" },
    { columnName: "smb", width: "320px" },
    { columnName: "status", width: "150px" },
    { columnName: "bank", width: "220px" },
    { columnName: "month", width: "150px" },
    { columnName: "initialReport", width: "150px" },
    { columnName: "endReport", width: "150px" },
    { columnName: "finalBalance", width: "150px" },
    { columnName: "validationDate", width: "150px" },
    { columnName: "endDateValidation", width: "150px" },
    { columnName: "validationBalance", width: "150px" },
    { columnName: "xeroBankBalance", width: "150px" },
    { columnName: "xeroBooks", width: "150px" },
    { columnName: "itemsReconciled", width: "150px" },
    { columnName: "reconcileAmount", width: "150px" },
    { columnName: "validationDate2", width: "150px" },
    { columnName: "user", width: "150px" },
];

//Tipo de cliente	Franquicia	Grupo	SMB	Clasificación	Especialista	Supervisor	Gerente	Mes	Estatus	Fecha carga requerimientos principales	Diferencia en días esperada	Fecha de validación	Usuario validación	Fecha de reporte acordada	Fecha de reporte estimada	Fecha primer envío	Diferencia en días acordada	Diferencia en días reales	Usuario reporte	Cantidad de destinatarios	Correos cliente	Fecha último reporte	Cantidad de reportes	Reportable	Cantidad de transacciones sin categoría	Monto
const tableColumnExtensions4 = [
    { columnName: "clientType", width: "150px" },
    { columnName: "franq", width: "250px" },
    { columnName: "group", width: "150px" },
    { columnName: "smb", width: "320px" },
    { columnName: "clasi", width: "150px" },
    { columnName: "analyst", width: "220px" },
    { columnName: "supervisor", width: "220px" },
    { columnName: "manager", width: "220px" },
    { columnName: "month", width: "150px" },
    { columnName: "status", width: "150px" },
    { columnName: "uploadDateReq", width: "150px" },
    { columnName: "diffDaysReq", width: "150px" },
    { columnName: "validationDate", width: "150px" },
    { columnName: "validationUser", width: "150px" },
    { columnName: "agreedReportDate", width: "150px" },
    { columnName: "estimatedReportDate", width: "150px" },
    { columnName: "firstDateReport", width: "150px" },
    { columnName: "diffDaysAgreed", width: "150px" },
    { columnName: "diffDaysReal", width: "150px" },
    { columnName: "reportUser", width: "150px" },
    { columnName: "recipientsNum", width: "150px" },
    { columnName: "clientEmails", width: "150px" },
    { columnName: "lastDateReport", width: "150px" },
    { columnName: "reportNumber", width: "150px" },
    { columnName: "reportable", width: "150px" },
    { columnName: "withoutCategory", width: "150px" },
    { columnName: "amount", width: "150px" },
];

const rowsSkeleton = [];
const rowsSkeleton2 = [];
const rowsSkeleton3 = [];
const rowsSkeleton4 = [];

for (let i = 0; i < 10; i++) {
    rowsSkeleton.push({
        typeClient: <Skeleton variant="text" animation="wave" />,
        franq: <Skeleton variant="text" animation="wave" />,
        group: <Skeleton variant="text" animation="wave" />,
        clientRS: <Skeleton variant="text" animation="wave" />,
        clasi: <Skeleton variant="text" animation="wave" />,
        analyst: <Skeleton variant="text" animation="wave" />,
        supervisor: <Skeleton variant="text" animation="wave" />,
        manager: <Skeleton variant="text" animation="wave" />,
        advisor: <Skeleton variant="text" animation="wave" />,
        dependency: <Skeleton variant="text" animation="wave" />,
        closureStatus: <Skeleton variant="text" animation="wave" />,
        lastClosedMonth: <Skeleton variant="text" animation="wave" />,
        registeredReportDays: <Skeleton variant="text" animation="wave" />,
        lastReportDate: <Skeleton variant="text" animation="wave" />,
        lastSuccessfulValidation: <Skeleton variant="text" animation="wave" />,
        validationUser: <Skeleton variant="text" animation="wave" />,
        reportable: <Skeleton variant="text" animation="wave" />,
        reportUser: <Skeleton variant="text" animation="wave" />,
    });

    rowsSkeleton2.push({
        franq: <Skeleton variant="text" animation="wave" />,
        smb: <Skeleton variant="text" animation="wave" />,
        bank: <Skeleton variant="text" animation="wave" />,
        date: <Skeleton variant="text" animation="wave" />,
        hour: <Skeleton variant="text" animation="wave" />,
        lastValidationDate: <Skeleton variant="text" animation="wave" />,
    });

    rowsSkeleton3.push({
        franq: <Skeleton variant="text" animation="wave" />,
        group: <Skeleton variant="text" animation="wave" />,
        smb: <Skeleton variant="text" animation="wave" />,
        status: <Skeleton variant="text" animation="wave" />,
        bank: <Skeleton variant="text" animation="wave" />,
        month: <Skeleton variant="text" animation="wave" />,
        initialReport: <Skeleton variant="text" animation="wave" />,
        endReport: <Skeleton variant="text" animation="wave" />,
        finalBalance: <Skeleton variant="text" animation="wave" />,
        validationDate: <Skeleton variant="text" animation="wave" />,
        endDateValidation: <Skeleton variant="text" animation="wave" />,
        validationBalance: <Skeleton variant="text" animation="wave" />,
        xeroBankBalance: <Skeleton variant="text" animation="wave" />,
        xeroBooks: <Skeleton variant="text" animation="wave" />,
        itemsReconciled: <Skeleton variant="text" animation="wave" />,
        reconcileAmount: <Skeleton variant="text" animation="wave" />,
        validationDate2: <Skeleton variant="text" animation="wave" />,
        user: <Skeleton variant="text" animation="wave" />,
    });

    rowsSkeleton4.push({
        clientType: <Skeleton variant="text" animation="wave" />,
        franq: <Skeleton variant="text" animation="wave" />,
        group: <Skeleton variant="text" animation="wave" />,
        smb: <Skeleton variant="text" animation="wave" />,
        clasi: <Skeleton variant="text" animation="wave" />,
        analyst: <Skeleton variant="text" animation="wave" />,
        supervisor: <Skeleton variant="text" animation="wave" />,
        manager: <Skeleton variant="text" animation="wave" />,
        month: <Skeleton variant="text" animation="wave" />,
        status: <Skeleton variant="text" animation="wave" />,
        uploadDateReq: <Skeleton variant="text" animation="wave" />,
        diffDaysReq: <Skeleton variant="text" animation="wave" />,
        validationDate: <Skeleton variant="text" animation="wave" />,
        validationUser: <Skeleton variant="text" animation="wave" />,
        agreedReportDate: <Skeleton variant="text" animation="wave" />,
        estimatedReportDate: <Skeleton variant="text" animation="wave" />,
        firstDateReport: <Skeleton variant="text" animation="wave" />,
        diffDaysAgreed: <Skeleton variant="text" animation="wave" />,
        diffDaysReal: <Skeleton variant="text" animation="wave" />,
        reportUser: <Skeleton variant="text" animation="wave" />,
        recipientsNum: <Skeleton variant="text" animation="wave" />,
        clientEmails: <Skeleton variant="text" animation="wave" />,
        lastDateReport: <Skeleton variant="text" animation="wave" />,
        reportNumber: <Skeleton variant="text" animation="wave" />,
        reportable: <Skeleton variant="text" animation="wave" />,
        withoutCategory: <Skeleton variant="text" animation="wave" />,
        amount: <Skeleton variant="text" animation="wave" />,
    });
}

let yearsToNoti = [];
for (let i = 2022; i <= new Date().getFullYear(); i++) {
    yearsToNoti.push(i);
}

const Monitoring = (props) => {
    const {
        reportSelected,
        setReportSelected,
        selectedYear,
        setSelectedYear,
        franqValue,
        setFranqData,
        setFranqValue,
        franqData,
        groupValue,
        setGroupValue,
        groupData,
        setGroupData,
    } = props;
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    const meses = [
        t("monthNames.january"),
        t("monthNames.february"),
        t("monthNames.march"),
        t("monthNames.april"),
        t("monthNames.may"),
        t("monthNames.june"),
        t("monthNames.july"),
        t("monthNames.august"),
        t("monthNames.september"),
        t("monthNames.october"),
        t("monthNames.november"),
        t("monthNames.december"),
    ];

    const tableMessages = {
        noData: t("miscellaneous.noDataReports"),
    };
    const pagingMessages = {
        info: ({ from, to, count }) => `${from}${from < to ? `-${to}` : ""} ${t("miscellaneous.pagingOf")} ${count}`,
    };

    const searchMessages = {
        searchPlaceholder: t("miscellaneous.search"),
    };

    /** React redux */
    const todos = useSelector((state) => state.todos);

    /** Component states */
    const [clientData, setclientData] = useState([]);
    const [columnDefs, setColumnsDefs] = useState([]);
    const [columnExt, setColumnsExt] = useState([]);
    const [searchValue, setSearchState] = useState("");
    const controllerRef = useRef(null);

    /** Component functions */
    const handleSearch = (value) => {
        let itemS = value.replace(/ +(?= )/g, "");
        setSearchState(itemS);
    };

    const reportTypeList = [
        { id: 0, data: t("proposals.follow") },
        { id: 1, data: t("miscellaneous.bankValidSummary") },
        { id: 2, data: t("miscellaneous.bankValidDetailed") },
        { id: 3, data: t("miscellaneous.closuresHistory") },
        { id: 4, data: t("reports.firmStatus") },
        { id: 5, data: t("miscellaneous.uncategTrans") },
    ];

    const handleChangeReportType = (event) => {
        event.preventDefault();
        setSelectedYear(new Date().getFullYear());
        setReportSelected(event.target.value);
    };

    const handleChangeFranqF = async (event, report) => {
        const {
            target: { value },
        } = event;

        setFranqValue(value);

        if (value === "N/A") {
            await fetch(`/getClientGroupsFilter`, {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
                .then((res) => res.json())
                .then((data) => {
                    setGroupData(data);
                    setGroupValue("");
                });
        } else if (report === 5) {
            await fetch(`/getFranchiseGroups?id_franchise=${value}`, {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
                .then((res) => res.json())
                .then((data) => {
                    setGroupData(data);
                    if (data.length) {
                        setGroupValue("all");
                    } else {
                        setGroupValue("");
                    }
                });
        }
    };

    const handleChangeGroup = (event) => {
        const {
            target: { value },
        } = event;

        setGroupValue(value);
    };

    const loadInitData = async () => {
        if (controllerRef.current) {
            controllerRef.current.abort();
        }
        const controller = new AbortController();
        controllerRef.current = controller;

        switch (reportSelected) {
            case 0:
                await fetch(`/getAnalyticsMonitoringReport`, {
                    method: "GET",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                    },
                    signal: controllerRef.current.signal,
                })
                    .then((res) => res.json())
                    .then((data) => {
                        const resultTable = data.map((data) => {
                            var lastClosedMonth = "-",
                                closureStatus = "";
                            if (data.last_closed_month != null) {
                                var months = [];
                                if (sessionStorage.getItem("lng") === "en") {
                                    months = [
                                        "January",
                                        "February",
                                        "March",
                                        "April",
                                        "May",
                                        "June",
                                        "July",
                                        "August",
                                        "September",
                                        "October",
                                        "November",
                                        "December",
                                    ];
                                    lastClosedMonth =
                                        months[parseInt(moment.utc(data.last_closed_month).format("M")) - 1];
                                } else {
                                    months = [
                                        "Enero",
                                        "Febrero",
                                        "Marzo",
                                        "Abril",
                                        "Mayo",
                                        "Junio",
                                        "Julio",
                                        "Agosto",
                                        "Septiembre",
                                        "Octubre",
                                        "Noviembre",
                                        "Diciembre",
                                    ];
                                    lastClosedMonth =
                                        months[parseInt(moment.utc(data.last_closed_month).format("M")) - 1];
                                }
                            }

                            if (data.closure_status) {
                                if (data.closure_status === "Faltan requerimientos") {
                                    closureStatus = t("clientsTable.awaitingRequirements");
                                } else if (data.closure_status === "Por validar") {
                                    closureStatus = t("recurrent.toValid");
                                } else if (data.closure_status === "Validado") {
                                    closureStatus = t("clientsTable.validated");
                                } else if (data.closure_status === "Reportado parcial") {
                                    closureStatus = t("recurrent.partReport");
                                } else if (data.closure_status === "Cerrado") {
                                    closureStatus = t("miscellaneous.closed");
                                }
                            }

                            return {
                                typeClient:
                                    data.client_type === "" ||
                                    data.client_type === null ||
                                    data.client_type === undefined ? (
                                        <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                                    ) : data.client_type === "Firm" ? (
                                        t("miscellaneous.firm")
                                    ) : (
                                        data.client_type
                                    ),
                                franq:
                                    data.franchise_name === "" ||
                                    data.franchise_name === null ||
                                    data.franchise_name === undefined ? (
                                        <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                                    ) : (
                                        data.franchise_name
                                    ),
                                group:
                                    data.practice_name === "" ||
                                    data.practice_name === null ||
                                    data.practice_name === undefined ? (
                                        <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                                    ) : (
                                        data.practice_name
                                    ),
                                clientRS: data.company_legal_name,
                                clasi:
                                    data.client_status === "" ||
                                    data.client_status === null ||
                                    data.client_status === undefined ? (
                                        <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                                    ) : (
                                        data.client_status
                                    ),
                                analyst:
                                    data.analyst_name === "" ||
                                    data.analyst_name === null ||
                                    data.analyst_name === undefined ? (
                                        <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                                    ) : (
                                        data.analyst_name
                                    ),
                                supervisor:
                                    data.supervisor_name === "" ||
                                    data.supervisor_name === null ||
                                    data.supervisor_name === undefined ? (
                                        <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                                    ) : (
                                        data.supervisor_name
                                    ),
                                manager:
                                    data.manager_name === "" ||
                                    data.manager_name === null ||
                                    data.manager_name === undefined ? (
                                        <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                                    ) : (
                                        data.manager_name
                                    ),
                                dependency:
                                    data.client_dependency === 1
                                        ? t("miscellaneous.gca")
                                        : data.client_dependency === 2
                                        ? t("miscellaneous.hybrid")
                                        : t("miscellaneous.client"),
                                closureStatus: closureStatus,
                                lastClosedMonth: lastClosedMonth,
                                registeredReportDays: data.report_days,
                                lastReportDate: !data.last_report_date ? (
                                    <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                                ) : (
                                    moment(data.last_report_date).format(todos.dateFormat)
                                ),
                                validationUser: data.validation_user,
                                reportable: data.reportable ? t("miscellaneous.yes") : t("miscellaneous.no"),
                                lastSuccessfulValidation: data.last_validation_date ? moment.utc(data.last_validation_date).format(todos.dateFormat) : t("bankingDashboard.withoutValidation"),
                                reportUser: data.report_user
                            };
                        });
                        setclientData(resultTable);
                        controllerRef.current = null;
                    });
                break;
            case 1:
                await fetch(`/getReportBankAccountsValidations`, {
                    method: "GET",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                    },
                    signal: controllerRef.current.signal,
                })
                    .then((res) => res.json())
                    .then((data) => {
                        const resultTable = data.map((data) => {
                            return {
                                franq: data.franchise_name,
                                smb: data.company_legal_name,
                                bank: data.account_name,
                                date: moment(data.date, "DD/MM/YYYY").utc().format(todos.dateFormat),
                                hour: data.hour,
                                lastValidationDate:
                                    data.last_verification_update === "Sin validación"
                                        ? t("bankingDashboard.withoutValidation")
                                        : data.last_verification_update,
                            };
                        });
                        setclientData(resultTable);
                        controllerRef.current = null;
                    });
                break;
            case 2:
                await fetch(`/getBankAccountsValidationsReport?year=${selectedYear}`, {
                    method: "GET",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                    },
                    signal: controllerRef.current.signal,
                })
                    .then((res) => res.json())
                    .then((data) => {
                        const resultTable = data.map((data) => {
                            return {
                                franq: data.franchise,
                                group: data.practice,
                                smb: data.company_name,
                                status: data.status ? t("miscellaneous.active") : t("miscellaneous.inactive"),
                                bank: data.account_name,
                                month: meses[data.month],
                                initialReport: moment(data.statement_initial_date).utc().format(todos.dateFormat),
                                endReport: moment(data.statement_end_date).utc().format(todos.dateFormat),
                                finalBalance:
                                    data.statement_balance !== undefined && data.statement_balance !== null
                                        ? parseFloat(data.statement_balance).toLocaleString(todos.amountFormat, {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2,
                                          })
                                        : t("bankingDashboard.withoutMovements"),
                                validationDate: moment(data.statement_initial_date).utc().format(todos.dateFormat),
                                endDateValidation: moment(data.statement_end_date).utc().format(todos.dateFormat),
                                validationBalance:
                                    data.validation_user !== "System generated" &&
                                    data.validation_user !== null &&
                                    data.validation_user !== undefined
                                        ? data.statement_balance !== undefined && data.statement_balance !== null
                                            ? parseFloat(data.statement_balance).toLocaleString(todos.amountFormat, {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                              })
                                            : t("bankingDashboard.withoutMovements")
                                        : "",
                                xeroBankBalance: parseFloat(data.xero_bank_balance).toLocaleString(todos.amountFormat, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                }),
                                xeroBooks: parseFloat(data.xero_accounting_balance).toLocaleString(todos.amountFormat, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                }),
                                itemsReconciled: data.unreconciled_bank_items,
                                reconcileAmount: parseFloat(data.unreconciled_amount).toLocaleString(
                                    todos.amountFormat,
                                    {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    }
                                ),
                                validationDate2: moment(data.verification_date).utc().format(todos.dateFormat),
                                user: data.validation_user ?? "",
                            };
                        });
                        setclientData(resultTable);
                        controllerRef.current = null;
                    });
                break;
            case 3:
                await fetch(`/generateClosuresReport?year=${selectedYear}`, {
                    method: "GET",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                    },
                    signal: controllerRef.current.signal,
                })
                    .then((res) => res.json())
                    .then((data) => {
                        const resultTable = data.map((data) => {
                            return {
                                clientType: data.client_type === "Firm" ? t("miscellaneous.firm") : data.client_type,
                                franq: data.franchise,
                                group: data.practice,
                                smb: data.company_name,
                                month: meses[data.month],
                                clasi: data.client_status,
                                analyst: data.analyst_name,
                                supervisor: data.supervisor_name,
                                manager: data.manager_name,
                                status:
                                    data.status === 1
                                        ? t("clientsTable.awaitingRequirements")
                                        : data.status === 2
                                        ? t("recurrent.toValid")
                                        : data.status === 3 || data.status === 4
                                        ? t("clientsTable.validated")
                                        : data.status === 5
                                        ? t("recurrent.partReport")
                                        : data.status === 6
                                        ? t("miscellaneous.closed")
                                        : "",
                                uploadDateReq: data.bank_requirements_date,
                                diffDaysReq: data.all_requirements_date,
                                validationDate: moment(data.validation_date).utc().format(todos.dateFormat),
                                validationUser: data.validation_user,
                                agreedReportDate: data.report_days,
                                estimatedReportDate: data.estimatedReportDate,
                                firstDateReport: moment(data.report_date).utc().format(todos.dateFormat),
                                diffDaysAgreed:
                                    data.report_days - parseInt(moment(data.report_date).utc().format("DD")),
                                diffDaysReal: data.diffDaysReal,
                                reportUser: data.report_user,
                                recipientsNum: data.recipientsNum,
                                clientEmails: data.clientEmails,
                                lastDateReport: moment(data.lastDateReport).utc().format(todos.dateFormat),
                                reportNumber: data.reportNumber,
                                reportable: data.reportable ? t("miscellaneous.yes") : t("miscellaneous.no"),
                                withoutCategory: data.withoutCategory,
                                amount: data.amount.toLocaleString(todos.amountFormat),
                            };
                        });
                        setclientData(resultTable);
                        controllerRef.current = null;
                    });
                break;
            default:
                setclientData([]);
                break;
        }
    };

    /* Component events */
    useEffect(() => {
        (async () => {
            let columnsTemplate = [
                { name: "typeClient", title: t("miscellaneous.type2") },
                { name: "franq", title: t("miscellaneous.franchise") },
                { name: "group", title: t("miscellaneous.group") },
                { name: "clientRS", title: t("miscellaneous.businessName") },
                { name: "clasi", title: t("miscellaneous.classification") },
                { name: "analyst", title: t("miscellaneous.analyst") },
                { name: "supervisor", title: t("miscellaneous.supervisor") },
                { name: "manager", title: t("miscellaneous.manager") },
                { name: "dependency", title: t("miscellaneous.dependency") },
                { name: "closureStatus", title: t("miscellaneous.closureStatus") },
                { name: "lastSuccessfulValidation", title: t("miscellaneous.lastSuccessfulVal") },
                { name: "validationUser", title: t("miscellaneous.validationUser") },
                { name: "lastClosedMonth", title: t("miscellaneous.lastClosedMonth") },
                { name: "reportable", title: t("miscellaneous.reportable") },
                { name: "registeredReportDays", title: t("miscellaneous.registeredReportDays") },
                { name: "lastReportDate", title: t("miscellaneous.lastReport") },
                { name: "reportUser", title: t("miscellaneous.reportUser") },
            ];

            //Franquicia	Grupo	SMB	Estatus	Banco	Mes	Fecha inicio reporte	Fecha final reporte	Balance final reporte	Fecha de validación	Fecha final validación	Saldo validación	Balance en bancos Xero	Libros en Xero	Partidas por conciliar	Monto por conciliar	Fecha de validación	Usuario
            let columnsTemplate2 = [
                { name: "franq", title: t("miscellaneous.franchise") },
                { name: "smb", title: t("miscellaneous.smb") },
                { name: "bank", title: t("miscellaneous.bank") },
                { name: "date", title: t("miscellaneous.date") },
                { name: "hour", title: t("miscellaneous.capHour") },
                { name: "lastValidationDate", title: t("miscellaneous.lastValidatedMonth") },
            ];

            let columnsTemplate3 = [
                { name: "franq", title: t("miscellaneous.franchise") },
                { name: "group", title: t("miscellaneous.group") },
                { name: "smb", title: t("miscellaneous.smb") },
                { name: "status", title: t("recurrent.status") },
                { name: "bank", title: t("miscellaneous.bank") },
                { name: "month", title: t("bankingDashboard.month") },
                { name: "initialReport", title: t("miscellaneous.initialDateReport") },
                { name: "endReport", title: t("miscellaneous.endDateReport") },
                { name: "finalBalance", title: t("miscellaneous.finalBalanceReport") },
                { name: "validationDate", title: t("miscellaneous.validationDateReport") },
                { name: "endDateValidation", title: t("miscellaneous.endDateValidationReport") },
                { name: "validationBalance", title: t("miscellaneous.validationBalance") },
                { name: "xeroBankBalance", title: t("miscellaneous.xeroBankBalance") },
                { name: "xeroBooks", title: t("bankingDashboard.xeroBooks") },
                { name: "itemsReconciled", title: t("bankingDashboard.itemsReconciled") },
                { name: "reconcileAmount", title: t("bankingDashboard.reconcileAmount") },
                { name: "validationDate2", title: t("miscellaneous.validationDateReport") },
                { name: "user", title: t("proposals.user") },
            ];

            //Tipo de cliente	Franquicia	Grupo	SMB	Clasificación	Especialista	Supervisor	Gerente	Mes	Estatus	Fecha carga requerimientos principales	Diferencia en días esperada	Fecha de validación	Usuario validación	Fecha de reporte acordada	Fecha de reporte estimada	Fecha primer envío	Diferencia en días acordada	Diferencia en días reales	Usuario reporte	Cantidad de destinatarios	Correos cliente	Fecha último reporte	Cantidad de reportes	Reportable	Cantidad de transacciones sin categoría	Monto
            let columnsTemplate4 = [
                { name: "clientType", title: t("miscellaneous.type") },
                { name: "franq", title: t("miscellaneous.franchise") },
                { name: "group", title: t("miscellaneous.group") },
                { name: "smb", title: t("miscellaneous.smb") },
                { name: "clasi", title: t("miscellaneous.classification") },
                { name: "analyst", title: t("miscellaneous.analyst") },
                { name: "supervisor", title: t("miscellaneous.supervisor") },
                { name: "manager", title: t("miscellaneous.manager") },
                { name: "month", title: t("bankingDashboard.month") },
                { name: "status", title: t("miscellaneous.status") },
                { name: "uploadDateReq", title: t("miscellaneous.uploadDateReq") },
                { name: "diffDaysReq", title: t("miscellaneous.diffDaysReq") },
                { name: "validationDate", title: t("miscellaneous.validationDateReport") },
                { name: "validationUser", title: t("miscellaneous.validationUser") },
                { name: "agreedReportDate", title: t("miscellaneous.agreedReportDate") },
                { name: "estimatedReportDate", title: t("miscellaneous.estimatedReportDate") },
                { name: "firstDateReport", title: t("miscellaneous.firstDateReport") },
                { name: "diffDaysAgreed", title: t("miscellaneous.diffDaysAgreed") },
                { name: "diffDaysReal", title: t("miscellaneous.diffDaysReal") },
                { name: "reportUser", title: t("miscellaneous.reportUser") },
                { name: "recipientsNum", title: t("miscellaneous.recipientsNum") },
                { name: "clientEmails", title: t("miscellaneous.clientEmails") },
                { name: "lastDateReport", title: t("miscellaneous.lastDateReport") },
                { name: "reportNumber", title: t("miscellaneous.reportNumber") },
                { name: "reportable", title: t("miscellaneous.reportable") },
                { name: "withoutCategory", title: t("miscellaneous.withoutCategory") },
                { name: "amount", title: t("invoicing.amount") },
            ];

            // Date 	 Source 	 Desciption 	 Account 	 Amount 	 Related account 	 Status
            let columnsTemplate5 = [
                { name: "date", title: "Date" },
                { name: "source", title: "Source" },
                { name: "desc", title: "Description" },
                { name: "acc", title: "Account" },
                { name: "amount", title: "Amount" },
                { name: "related", title: "Related account" },
                { name: "status", title: "Status" },
            ];

            switch (reportSelected) {
                case 0:
                    //Prospectos
                    setclientData(rowsSkeleton);
                    setColumnsDefs(columnsTemplate);
                    setColumnsExt(tableColumnExtensions);
                    break;
                case 1:
                    setclientData(rowsSkeleton2);
                    setColumnsDefs(columnsTemplate2);
                    setColumnsExt(tableColumnExtensions2);
                    break;
                case 2:
                    setclientData(rowsSkeleton3);
                    setColumnsDefs(columnsTemplate3);
                    setColumnsExt(tableColumnExtensions3);
                    break;
                case 3:
                    setclientData(rowsSkeleton4);
                    setColumnsDefs(columnsTemplate4);
                    setColumnsExt(tableColumnExtensions4);
                    break;
                case 4:
                    setColumnsDefs(columnsTemplate);
                    setColumnsExt(tableColumnExtensions);
                    await fetch(`/getClientFranchiseFilter`, {
                        method: "GET",
                        headers: {
                            "Content-type": "application/json; charset=UTF-8",
                            "Access-Control-Allow-Origin": "*",
                        },
                    })
                        .then((res) => res.json())
                        .then((data) => {
                            console.log(data);
                            setFranqData(data);
                        });
                    break;
                default:
                    setColumnsDefs(columnsTemplate5);
                    setColumnsExt(tableColumnExtensions);
                    await fetch(`/getClientFranchiseFilter`, {
                        method: "GET",
                        headers: {
                            "Content-type": "application/json; charset=UTF-8",
                            "Access-Control-Allow-Origin": "*",
                        },
                    })
                        .then((res) => res.json())
                        .then((data) => {
                            setFranqData(data);
                        });
                    await fetch(`/getClientGroupsFilter`, {
                        method: "GET",
                        headers: {
                            "Content-type": "application/json; charset=UTF-8",
                            "Access-Control-Allow-Origin": "*",
                        },
                    })
                        .then((res) => res.json())
                        .then((data) => {
                            setGroupData(data);
                        });
                    break;
            }

            await loadInitData();
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t, todos.dateFormat, todos.amountFormat, reportSelected, selectedYear]);

    const handleClick = (event) => {
        setSelectedYear(event.target.value);
    };

    return (
        <StyledContainer component="main" sx={{ flexGrow: 1, width: "100%" }}>
            <GridUI container justifyContent="left" alignItems="center" spacing={2}>
                <GridUI item xs={12} sm={6} md={3} xl={2} sx={{ mb: { md: -7 }, zIndex: 1 }}>
                    <FormControl fullWidth>
                        <InputLabel shrink id="type-simple-select-label">
                            {t("invoicing.reportType")}
                        </InputLabel>
                        <Select
                            size="small"
                            IconComponent={KeyboardArrowDownIcon}
                            value={reportSelected}
                            onChange={handleChangeReportType}
                            input={<OutlinedInput notched label={t("invoicing.reportType")} />}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        mt: 0.7,
                                        borderRadius: 2,
                                        boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                    },
                                },
                            }}
                        >
                            {reportTypeList.map((report) => (
                                <MenuItem value={report.id}>{report.data}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </GridUI>
                {(reportSelected === 2 || reportSelected === 3 || reportSelected === 4) && (
                    <GridUI item xs={12} sm={6} md={3} xl={2} sx={{ mb: { md: -7 }, zIndex: 1 }}>
                        <FormControl fullWidth>
                            <InputLabel id="year-simple-select-label">{t("miscellaneous.year")}</InputLabel>
                            <Select
                                value={selectedYear}
                                labelId="year-simple-select-label"
                                onChange={handleClick}
                                IconComponent={KeyboardArrowDownIcon}
                                size="small"
                                label={t("miscellaneous.year")}
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            mt: 0.7,
                                            borderRadius: 2,
                                            boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                        },
                                    },
                                }}
                            >
                                {yearsToNoti.map((year, index) => (
                                    <MenuItem key={index} value={year}>
                                        {year}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </GridUI>
                )}
                {(reportSelected === 4 || reportSelected === 5) && (
                    <GridUI item xs={12} sm={6} md={3} xl={2} sx={{ mb: { md: -7 }, zIndex: 1 }}>
                        <FormControl fullWidth size="small">
                            <InputLabel id="franchise-simple-select-label">{t("miscellaneous.franchise")}</InputLabel>
                            <Select
                                value={franqValue}
                                labelId="franchise-simple-select-label"
                                onChange={(e) => handleChangeFranqF(e, reportSelected)}
                                IconComponent={KeyboardArrowDownIcon}
                                label={t("miscellaneous.franchise")}
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            mt: 0.7,
                                            borderRadius: 2,
                                            boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                        },
                                    },
                                }}
                            >
                                <MenuItem key="N/A" value="N/A">
                                    N/A
                                </MenuItem>
                                {franqData.map((value) => (
                                    <MenuItem key={value.id} value={value.id}>
                                        {value.franchise_name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </GridUI>
                )}
                {reportSelected === 5 && groupData.length > 0 && (
                    <GridUI item xs={12} sm={6} md={3} xl={2} sx={{ mb: { md: -7 }, zIndex: 1 }}>
                        <FormControl fullWidth size="small">
                            <InputLabel id="group-simple-select-label">{t("miscellaneous.group")}</InputLabel>
                            <Select
                                value={groupValue}
                                labelId="group-simple-select-label"
                                onChange={handleChangeGroup}
                                IconComponent={KeyboardArrowDownIcon}
                                label={t("miscellaneous.group")}
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            mt: 0.7,
                                            borderRadius: 2,
                                            boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                        },
                                    },
                                }}
                            >
                                {franqValue !== "" && franqValue !== "N/A" && (
                                    <MenuItem key="all" value="all">
                                        {t("miscellaneous.all")}
                                    </MenuItem>
                                )}
                                {groupData.map((value) => (
                                    <MenuItem key={value.id} value={value.id}>
                                        {value.group_name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </GridUI>
                )}
                <GridUI item xs={12}>
                    <Grid rows={clientData} columns={columnDefs}>
                        <SearchState value={searchValue} onValueChange={handleSearch} />
                        <IntegratedFiltering />
                        <PagingState defaultCurrentPage={0} pageSize={10} />
                        <IntegratedPaging />
                        <Table
                            tableComponent={TableComponent}
                            columnExtensions={columnExt}
                            cellComponent={TableCellComponent}
                            messages={tableMessages}
                        />
                        <TableHeaderRow cellComponent={tableHeaderCell} contentComponent={tableContentComponent} />
                        <Toolbar rootComponent={toolRootComponent} />
                        <SearchPanel messages={searchMessages} inputComponent={inputComponent} />
                        <PagingPanel containerComponent={PagingPanelContainer} messages={pagingMessages} />
                    </Grid>
                </GridUI>
            </GridUI>
        </StyledContainer>
    );
};

export default Monitoring;
