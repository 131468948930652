const currencies = [
    {
        code: "N/A",
        name: "",
        name_es: "",
        symbol: "N/A"
    },
    {
        code: "AFN",
        name: "Afghan Afghani",
        name_es: "Afgani afgano",
        symbol: "؋"
    },
    {
        code: "ALL",
        name: "Albanian Lek",
        name_es: "Lek albanés",
        symbol: "Lek"
    },
    {
        code: "DZD",
        name: "Algerian Dinar",
        name_es: "Dinar argelino",
        symbol: "دج"
    },
    {
        code: "AOA",
        name: "Angolan Kwanza",
        name_es: "Kwanza angoleño",
        symbol: "Kz"
    },
    {
        code: "ARS",
        name: "Argentine Peso",
        name_es: "Peso argentino",
        symbol: "$"
    },
    {
        code: "AMD",
        name: "Armenian Dram",
        name_es: "Dram armenio",
        symbol: "֏"
    },
    {
        code: "AWG",
        name: "Aruban Florin",
        name_es: "Florín arubeño",
        symbol: "ƒ"
    },
    {
        code: "AUD",
        name: "Australian Dollar",
        name_es: "Dólar australiano",
        symbol: "$"
    },
    {
        code: "AZN",
        name: "Azerbaijani Manat",
        name_es: "Manat azerbaiyano",
        symbol: "m"
    },
    {
        code: "BSD",
        name: "Bahamian Dollar",
        name_es: "Bahamian Dollar",
        symbol: "B$"
    },
    {
        code: "BHD",
        name: "Bahraini Dinar",
        name_es: "Dinar bareiní",
        symbol: ".د.ب"
    },
    {
        code: "BDT",
        name: "Bangladeshi Taka",
        name_es: "Taka bangladesí",
        symbol: "৳"
    },
    {
        code: "BBD",
        name: "Barbadian Dollar",
        name_es: "Dólar de Barbados",
        symbol: "Bds$"
    },
    {
        code: "BYR",
        name: "Belarusian Ruble",
        name_es: "Rublo bielorruso",
        symbol: "Br"
    },
    {
        code: "BEF",
        name: "Belgian Franc",
        name_es: "Franco belga",
        symbol: "fr"
    },
    {
        code: "BZD",
        name: "Belize Dollar",
        name_es: "Dólar beliceño",
        symbol: "$"
    },
    {
        code: "BMD",
        name: "Bermudan Dollar",
        name_es: "Dólar bermudeño",
        symbol: "$"
    },
    {
        code: "BTN",
        name: "Bhutanese Ngultrum",
        name_es: "Ngultrum butanés",
        symbol: "Nu."
    },
    {
        code: "BTC",
        name: "Bitcoin",
        name_es: "Bitcoin",
        symbol: "฿"
    },
    {
        code: "BOB",
        name: "Bolivian Boliviano",
        name_es: "Boliviano",
        symbol: "Bs."
    },
    {
        code: "BAM",
        name: "Bosnia-Herzegovina Convertible Mark",
        name_es: "Marco bosnioherzegovino",
        symbol: "KM"
    },
    {
        code: "BWP",
        name: "Botswanan Pula",
        name_es: "Pula",
        symbol: "P"
    },
    {
        code: "BRL",
        name: "Brazilian Real",
        name_es: "Real brasileño",
        symbol: "R$"
    },
    {
        code: "GBP",
        name: "British Pound Sterling",
        name_es: "Libra esterlina",
        symbol: "£"
    },
    {
        code: "BND",
        name: "Brunei Dollar",
        name_es: "Dólar de Brunéi",
        symbol: "B$"
    },
    {
        code: "BGN",
        name: "Bulgarian Lev",
        name_es: "Lev",
        symbol: "Лв."
    },
    {
        code: "BIF",
        name: "Burundian Franc",
        name_es: "Franco burundés",
        symbol: "FBu"
    },
    {
        code: "KHR",
        name: "Cambodian Riel",
        name_es: "Riel camboyano",
        symbol: "KHR"
    },
    {
        code: "CAD",
        name: "Canadian Dollar",
        name_es: "Dólar canadiense",
        symbol: "$"
    },
    {
        code: "CVE",
        name: "Cape Verdean Escudo",
        name_es: "Escudo caboverdiano",
        symbol: "$"
    },
    {
        code: "KYD",
        name: "Cayman Islands Dollar",
        name_es: "Dólar de las Islas Caimán",
        symbol: "$"
    },
    {
        code: "XOF",
        name: "CFA Franc BCEAO",
        name_es: "Franco CFA de África Occidental",
        symbol: "CFA"
    },
    {
        code: "XAF",
        name: "CFA Franc BEAC",
        name_es: "Franco CFA de África Central",
        symbol: "FCFA"
    },
    {
        code: "XPF",
        name: "CFP Franc",
        name_es: "Franco CFP",
        symbol: "₣"
    },
    {
        code: "CLP",
        name: "Chilean Peso",
        name_es: "Peso chileno",
        symbol: "$"
    },
    {
        code: "CNY",
        name: "Chinese Yuan",
        name_es: "Yuan chino",
        symbol: "¥"
    },
    {
        code: "COP",
        name: "Colombian Peso",
        name_es: "Peso colombiano",
        symbol: "$"
    },
    {
        code: "KMF",
        name: "Comorian Franc",
        name_es: "Franco comorense",
        symbol: "CF"
    },
    {
        code: "CDF",
        name: "Congolese Franc",
        name_es: "Franco congoleño",
        symbol: "FC"
    },
    {
        code: "CRC",
        name: "Costa Rican Colón",
        name_es: "Colón costarricense",
        symbol: "₡"
    },
    {
        code: "HRK",
        name: "Croatian Kuna",
        name_es: "Kuna croata",
        symbol: "kn"
    },
    {
        code: "CUC",
        name: "Cuban Convertible Peso",
        name_es: "Peso cubano convertible",
        symbol: "$, CUC"
    },
    {
        code: "CZK",
        name: "Czech Republic Koruna",
        name_es: "Corona checa",
        symbol: "Kč"
    },
    {
        code: "DKK",
        name: "Danish Krone",
        name_es: "Corona danesa",
        symbol: "Kr."
    },
    {
        code: "DJF",
        name: "Djiboutian Franc",
        name_es: "Franco yibutiano",
        symbol: "Fdj"
    },
    {
        code: "DOP",
        name: "Dominican Peso",
        name_es: "Peso dominicano",
        symbol: "$"
    },
    {
        code: "XCD",
        name: "East Caribbean Dollar",
        name_es: "Dólar del Caribe Oriental",
        symbol: "$"
    },
    {
        code: "EGP",
        name: "Egyptian Pound",
        name_es: "Libra egipcia",
        symbol: "ج.م"
    },
    {
        code: "ERN",
        name: "Eritrean Nakfa",
        name_es: "Nakfa",
        symbol: "Nfk"
    },
    {
        code: "EEK",
        name: "Estonian Kroon",
        name_es: "Corona estonia",
        symbol: "kr"
    },
    {
        code: "ETB",
        name: "Ethiopian Birr",
        name_es: "Birr etíope",
        symbol: "Nkf"
    },
    {
        code: "EUR",
        name: "Euro",
        name_es: "Euro",
        symbol: "€"
    },
    {
        code: "FKP",
        name: "Falkland Islands Pound",
        name_es: "Libra malvinense",
        symbol: "£"
    },
    {
        code: "FJD",
        name: "Fijian Dollar",
        name_es: "Dólar fiyiano",
        symbol: "FJ$"
    },
    {
        code: "GMD",
        name: "Gambian Dalasi",
        name_es: "Dalasi",
        symbol: "D"
    },
    {
        code: "GEL",
        name: "Georgian Lari",
        name_es: "Lari georgiano",
        symbol: "ლ"
    },
    {
        code: "DEM",
        name: "German Mark",
        name_es: "Marco alemán",
        symbol: "DM"
    },
    {
        code: "GHS",
        name: "Ghanaian Cedi",
        name_es: "Cedi",
        symbol: "GH₵"
    },
    {
        code: "GIP",
        name: "Gibraltar Pound",
        name_es: "Libra gibraltareña",
        symbol: "£"
    },
    {
        code: "GRD",
        name: "Greek Drachma",
        name_es: "Dracma griega",
        symbol: "₯, Δρχ, Δρ"
    },
    {
        code: "GTQ",
        name: "Guatemalan Quetzal",
        name_es: "Quetzal",
        symbol: "Q"
    },
    {
        code: "GNF",
        name: "Guinean Franc",
        name_es: "Franco guineano",
        symbol: "FG"
    },
    {
        code: "GYD",
        name: "Guyanaese Dollar",
        name_es: "Dólar guyanés",
        symbol: "$"
    },
    {
        code: "HTG",
        name: "Haitian Gourde",
        name_es: "Gourde",
        symbol: "G"
    },
    {
        code: "HNL",
        name: "Honduran Lempira",
        name_es: "Lempira hondureño",
        symbol: "L"
    },
    {
        code: "HKD",
        name: "Hong Kong Dollar",
        name_es: "Dólar de Hong Kong",
        symbol: "$"
    },
    {
        code: "HUF",
        name: "Hungarian Forint",
        name_es: "Forinto húngaro",
        symbol: "Ft"
    },
    {
        code: "ISK",
        name: "Icelandic Króna",
        name_es: "Corona islandesa",
        symbol: "kr"
    },
    {
        code: "INR",
        name: "Indian Rupee",
        name_es: "Rupia india",
        symbol: "₹"
    },
    {
        code: "IDR",
        name: "Indonesian Rupiah",
        name_es: "Rupia indonesia",
        symbol: "Rp"
    },
    {
        code: "IRR",
        name: "Iranian Rial",
        name_es: "Rial iraní",
        symbol: "﷼"
    },
    {
        code: "IQD",
        name: "Iraqi Dinar",
        name_es: "Dinar iraquí",
        symbol: "د.ع"
    },
    {
        code: "ILS",
        name: "Israeli New Sheqel",
        name_es: "Nuevo séquel",
        symbol: "₪"
    },
    {
        code: "ITL",
        name: "Italian Lira",
        name_es: "Lira italiana",
        symbol: "L,£"
    },
    {
        code: "JMD",
        name: "Jamaican Dollar",
        name_es: "Dólar jamaiquino",
        symbol: "J$"
    },
    {
        code: "JPY",
        name: "Japanese Yen",
        name_es: "Yen japonés",
        symbol: "¥"
    },
    {
        code: "JOD",
        name: "Jordanian Dinar",
        name_es: "Dinar jordano",
        symbol: "ا.د"
    },
    {
        code: "KZT",
        name: "Kazakhstani Tenge",
        name_es: "Tenge kazajo",
        symbol: "лв"
    },
    {
        code: "KES",
        name: "Kenyan Shilling",
        name_es: "Chelín keniano",
        symbol: "KSh"
    },
    {
        code: "KWD",
        name: "Kuwaiti Dinar",
        name_es: "Dinar kuwaití",
        symbol: "ك.د"
    },
    {
        code: "KGS",
        name: "Kyrgystani Som",
        name_es: "Som kirguís",
        symbol: "лв"
    },
    {
        code: "LAK",
        name: "Laotian Kip",
        name_es: "Kip laosiano",
        symbol: "₭"
    },
    {
        code: "LVL",
        name: "Latvian Lats",
        name_es: "Lats letón",
        symbol: "Ls"
    },
    {
        code: "LBP",
        name: "Lebanese Pound",
        name_es: "Libra libanesa",
        symbol: "£"
    },
    {
        code: "LSL",
        name: "Lesotho Loti",
        name_es: "Loti",
        symbol: "L"
    },
    {
        code: "LRD",
        name: "Liberian Dollar",
        name_es: "Dólar liberiano",
        symbol: "$"
    },
    {
        code: "LYD",
        name: "Libyan Dinar",
        name_es: "Dinar libio",
        symbol: "د.ل"
    },
    {
        code: "LTL",
        name: "Lithuanian Litas",
        name_es: "Litas lituana",
        symbol: "Lt"
    },
    {
        code: "MOP",
        name: "Macanese Pataca",
        name_es: "Pataca macaense",
        symbol: "$"
    },
    {
        code: "MKD",
        name: "Macedonian Denar",
        name_es: "Denar macedonio",
        symbol: "ден"
    },
    {
        code: "MGA",
        name: "Malagasy Ariary",
        name_es: "Ariary malgache",
        symbol: "Ar"
    },
    {
        code: "MWK",
        name: "Malawian Kwacha",
        name_es: "Kwacha malauí",
        symbol: "MK"
    },
    {
        code: "MYR",
        name: "Malaysian Ringgit",
        name_es: "Ringgit malayo",
        symbol: "RM"
    },
    {
        code: "MVR",
        name: "Maldivian Rufiyaa",
        name_es: "Rupia de Maldivas",
        symbol: "Rf"
    },
    {
        code: "MRU",
        name: "Mauritanian Ouguiya",
        name_es: "Uguiya",
        symbol: "MRU"
    },
    {
        code: "MUR",
        name: "Mauritian Rupee",
        name_es: "Rupia de Mauricio",
        symbol: "₨"
    },
    {
        code: "MXN",
        name: "Mexican Peso",
        name_es: "Peso mexicano",
        symbol: "$"
    },
    {
        code: "MDL",
        name: "Moldovan Leu",
        name_es: "Leu moldavo",
        symbol: "L"
    },
    {
        code: "MNT",
        name: "Mongolian Tugrik",
        name_es: "Tugrik mongol",
        symbol: "₮"
    },
    {
        code: "MAD",
        name: "Moroccan Dirham",
        name_es: "Moroccan Dirham",
        symbol: "MAD"
    },
    {
        code: "MZM",
        name: "Mozambican Metical",
        name_es: "Metical mozambiqueño",
        symbol: "MT"
    },
    {
        code: "MMK",
        name: "Myanmar Kyat",
        name_es: "Kyat birmano",
        symbol: "K"
    },
    {
        code: "NAD",
        name: "Namibian Dollar",
        name_es: "Dólar namibio",
        symbol: "$"
    },
    {
        code: "NPR",
        name: "Nepalese Rupee",
        name_es: "Rupia nepalí",
        symbol: "₨"
    },
    {
        code: "ANG",
        name: "Netherlands Antillean Guilder",
        name_es: "Florín antillano neerlandés",
        symbol: "ƒ"
    },
    {
        code: "TWD",
        name: "New Taiwan Dollar",
        name_es: "Nuevo dólar taiwanés",
        symbol: "$"
    },
    {
        code: "NZD",
        name: "New Zealand Dollar",
        name_es: "Dólar neozelandés",
        symbol: "$"
    },
    {
        code: "NIO",
        name: "Nicaraguan Córdoba",
        name_es: "Córdoba",
        symbol: "C$"
    },
    {
        code: "NGN",
        name: "Nigerian Naira",
        name_es: "Naira",
        symbol: "₦"
    },
    {
        code: "KPW",
        name: "North Korean Won",
        name_es: "Won norcoreano",
        symbol: "₩"
    },
    {
        code: "NOK",
        name: "Norwegian Krone",
        name_es: "Corona noruega",
        symbol: "kr"
    },
    {
        code: "OMR",
        name: "Omani Rial",
        name_es: "Rial",
        symbol: ".ع.ر"
    },
    {
        code: "PKR",
        name: "Pakistani Rupee",
        name_es: "Rupia pakistaní",
        symbol: "₨"
    },
    {
        code: "PAB",
        name: "Panamanian Balboa",
        name_es: "Balboa",
        symbol: "B/."
    },
    {
        code: "PGK",
        name: "Papua New Guinean Kina",
        name_es: "Kina",
        symbol: "K"
    },
    {
        code: "PYG",
        name: "Paraguayan Guarani",
        name_es: "Guaraní paraguayo",
        symbol: "₲"
    },
    {
        code: "PEN",
        name: "Peruvian Nuevo Sol",
        name_es: "Sol",
        symbol: "S/."
    },
    {
        code: "PHP",
        name: "Philippine Peso",
        name_es: "Peso filipino",
        symbol: "₱"
    },
    {
        code: "PLN",
        name: "Polish Zloty",
        name_es: "Esloti",
        symbol: "zł"
    },
    {
        code: "QAR",
        name: "Qatari Riyal",
        name_es: "Riyal catarí",
        symbol: "ق.ر"
    },
    {
        code: "RON",
        name: "Romanian Leu",
        name_es: "Leu rumano",
        symbol: "lei"
    },
    {
        code: "RUB",
        name: "Russian Ruble",
        name_es: "Rublo ruso",
        symbol: "₽"
    },
    {
        code: "RWF",
        name: "Rwandan Franc",
        name_es: "Franco ruandés",
        symbol: "FRw"
    },
    {
        code: "SVC",
        name: "Salvadoran Colón",
        name_es: "Colón salvadoreño",
        symbol: "₡"
    },
    {
        code: "WST",
        name: "Samoan Tala",
        name_es: "Tālā",
        symbol: "SAT"
    },
    {
        code: "SAR",
        name: "Saudi Riyal",
        name_es: "Riyal saudí",
        symbol: "﷼"
    },
    {
        code: "RSD",
        name: "Serbian Dinar",
        name_es: "Dinar serbio",
        symbol: "din"
    },
    {
        code: "SCR",
        name: "Seychellois Rupee",
        name_es: "Rupia de Seychelles",
        symbol: "SRe"
    },
    {
        code: "SLL",
        name: "Sierra Leonean Leone",
        name_es: "Leone",
        symbol: "Le"
    },
    {
        code: "SGD",
        name: "Singapore Dollar",
        name_es: "Dólar de Singapur",
        symbol: "$"
    },
    {
        code: "SKK",
        name: "Slovak Koruna",
        name_es: "Corona eslovaca",
        symbol: "Sk"
    },
    {
        code: "SBD",
        name: "Solomon Islands Dollar",
        name_es: "Dólar de las Islas Salomón",
        symbol: "Si$"
    },
    {
        code: "SOS",
        name: "Somali Shilling",
        name_es: "Chelín somalí",
        symbol: "Sh.so."
    },
    {
        code: "ZAR",
        name: "South African Rand",
        name_es: "Rand sudafricano",
        symbol: "R"
    },
    {
        code: "KRW",
        name: "South Korean Won",
        name_es: "Won surcoreano",
        symbol: "₩"
    },
    {
        code: "XDR",
        name: "Special Drawing Rights",
        name_es: "Derechos especiales de giro",
        symbol: "SDR"
    },
    {
        code: "LKR",
        name: "Sri Lankan Rupee",
        name_es: "Rupia de Sri Lanka",
        symbol: "Rs"
    },
    {
        code: "SHP",
        name: "St. Helena Pound",
        name_es: "Libra de Santa Helena",
        symbol: "£"
    },
    {
        code: "SDG",
        name: "Sudanese Pound",
        name_es: "Libra sudanesa",
        symbol: ".س.ج"
    },
    {
        code: "SRD",
        name: "Surinamese Dollar",
        name_es: "Dólar surinamés",
        symbol: "$"

    },
    {
        code: "SZL",
        name: "Swazi Lilangeni",
        name_es: "Lilangeni",
        symbol: "E"
    },
    {
        code: "SEK",
        name: "Swedish Krona",
        name_es: "Corona sueca",
        symbol: "kr"
    },
    {
        code: "CHF",
        name: "Swiss Franc",
        name_es: "Franco suizo",
        symbol: "CHf"
    },
    {
        code: "SYP",
        name: "Syrian Pound",
        name_es: "Dólar de las Islas Salomón",
        symbol: "LS"
    },
    {
        code: "STD",
        name: "São Tomé and Príncipe Dobra",
        name_es: "Dobra santotomense",
        symbol: "Db"
    },
    {
        code: "TJS",
        name: "Tajikistani Somoni",
        name_es: "Somoni tayiko",
        symbol: "SM"
    },
    {
        code: "TZS",
        name: "Tanzanian Shilling",
        name_es: "Chelín tanzano",
        symbol: "TSh"
    },
    {
        code: "THB",
        name: "Thai Baht",
        name_es: "Baht tailandés",
        symbol: "฿"
    },
    {
        code: "TOP",
        name: "Tongan Pa'anga",
        name_es: "Pa'anga",
        symbol: "$"
    },
    {
        code: "TTD",
        name: "Trinidad & Tobago Dollar",
        name_es: "Dólar trinitense",
        symbol: "$"
    },
    {
        code: "TND",
        name: "Tunisian Dinar",
        name_es: "Dinar tunecino",
        symbol: "ت.د"
    },
    {
        code: "TRY",
        name: "Turkish Lira",
        name_es: "Lira turca",
        symbol: "₺"
    },
    {
        code: "TMT",
        name: "Turkmenistani Manat",
        name_es: "Manat turcomano",
        symbol: "T"
    },
    {
        code: "UGX",
        name: "Ugandan Shilling",
        name_es: "Chelín ugandés",
        symbol: "USh"
    },
    {
        code: "UAH",
        name: "Ukrainian Hryvnia",
        name_es: "Grivna",
        symbol: "₴"
    },
    {
        code: "AED",
        name: "United Arab Emirates Dirham",
        name_es: "Dírham de los Emiratos Árabes Unidos",
        symbol: "إ.د"
    },
    {
        code: "UYU",
        name: "Uruguayan Peso",
        name_es: "Peso uruguayo",
        symbol: "$"
    },
    {
        code: "USD",
        name: "US Dollar",
        name_es: "Dólar estadounidense",
        symbol: "$"
    },
    {
        code: "UZS",
        name: "Uzbekistan Som",
        name_es: "Som uzbeko",
        symbol: "лв"
    },
    {
        code: "VUV",
        name: "Vanuatu Vatu",
        name_es: "Vatu",
        symbol: "VT"
    },
    {
        code: "VEF",
        name: "Venezuelan Bolívar",
        name_es: "Bolívar",
        symbol: "Bs"
    },
    {
        code: "VND",
        name: "Vietnamese Đồng",
        name_es: "Đồng vietnamita",
        symbol: "₫"
    },
    {
        code: "YER",
        name: "Yemeni Rial",
        name_es: "Rial yemení",
        symbol: "﷼"
    },
    {
        code: "ZMK",
        name: "Zambian Kwacha",
        name_es: "Kwacha zambiano",
        symbol: "ZK"
    }
];

export default currencies;