const projects = [
    {
        code: 1,
        name: "accountOnboard",
        country: ["US", "GB", "LA", "VE"],
        parent: 1,
    },
    {
        code: 4,
        name: "accountCleanUp",
        country: ["US", "GB", "LA", "VE"],
        parent: 1,
    },
    {
        code: 3,
        name: "accountCatchUp",
        country: ["US", "GB", "LA", "VE"],
        parent: 1,
    },
    {
        code: 2,
        name: "accountantRecu",
        country: ["US", "GB", "LA", "VE"],
        parent: 1,
    },
    {
        code: 74,
        name: "accountExpress",
        country: ["VE", "US", "GB", "LA"],
        parent: 1,
    },
    {
        code: 5,
        name: "accountConsul",
        country: ["US", "GB", "LA", "VE"],
        parent: 1,
    },
    {
        code: 6,
        name: "others",
        country: ["US", "GB", "LA", "VE"],
        parent: 1,
    },
    {
        code: 22,
        name: "smbOnboard",
        country: ["US"],
        parent: 5,
    },
    {
        code: 25,
        name: "bookCleanUp",
        country: ["US"],
        parent: 5,
    },
    {
        code: 24,
        name: "bookCatchUp",
        country: ["US"],
        parent: 5,
    },
    {
        code: 23,
        name: "bookRecu",
        country: ["US"],
        parent: 5,
    },
    {
        code: 75,
        name: "bookExpress",
        country: ["VE", "US", "GB", "LA"]
    },
    {
        code: 26,
        name: "bookConsul",
        country: ["US"],
        parent: 5,
    },
    {
        code: 27,
        name: "others",
        country: ["US"],
        parent: 5,
    },
    {
        code: 21,
        name: "practOnboard",
        country: ["US"],
        parent: 5,
    },
    {
        code: 39,
        name: "gcaPlatSubs",
        country: ["US"],
        parent: 5,
    },
    {
        code: 12,
        name: "cfoOnboar",
        country: ["US", "GB", "LA", "VE"],
        parent: 3,
    },
    {
        code: 14,
        name: "cfoCatch",
        country: ["US", "GB", "LA", "VE"],
        parent: 3,
    },
    {
        code: 13,
        name: "cfoRecu",
        country: ["US", "GB", "LA", "VE"],
        parent: 3,
    },
    {
        code: 15,
        name: "cfoConsult",
        country: ["US", "GB", "LA", "VE"],
        parent: 3,
    },
    {
        code: 16,
        name: "others",
        country: ["US", "GB", "LA", "VE"],
        parent: 3,
    },
    {
        code: 7,
        name: "contOnBoard",
        country: ["US", "GB", "LA", "VE"],
        parent: 2,
    },
    {
        code: 9,
        name: "contCatchUp",
        country: ["US", "GB", "LA", "VE"],
        parent: 2,
    },
    {
        code: 8,
        name: "contRecu",
        country: ["US", "GB", "LA", "VE"],
        parent: 2,
    },
    {
        code: 10,
        name: "contConsul",
        country: ["US", "GB", "LA", "VE"],
        parent: 2,
    },
    {
        code: 11,
        name: "others",
        country: ["US", "GB", "LA", "VE"],
        parent: 2,
    },
    {
        code: 34,
        name: "xeroUpda",
        country: ["US", "GB", "LA", "VE"],
        parent: 8,
    },
    {
        code: 36,
        name: "kiipBankSet",
        country: ["US", "GB", "LA", "VE"],
        parent: 8,
    },
    {
        code: 37,
        name: "kiipTaxSet",
        country: ["VE"],
        parent: 8,
    },
    {
        code: 70,
        name: "kiipRepSetup",
        country: ["US", "GB", "LA", "VE"],
        parent: 8,
    },
    {
        code: 38,
        name: "otherSetup",
        country: ["US", "GB", "LA", "VE"],
        parent: 8,
    },
    {
        code: 35,
        name: "xeroConsul",
        alt_name: "Xero consulting",
        country: ["US", "GB", "LA", "VE"],
        parent: 8,
    },
    {
        code: 40,
        name: "xeroSusb",
        country: ["US", "GB", "LA", "VE"],
        parent: 9,
    },
    {
        code: 44,
        name: "xeroSubs",
        country: ["US", "GB", "LA", "VE"],
        parent: 9,
    },
    {
        code: 41,
        name: "kiipBankSusb",
        country: ["US", "GB", "LA", "VE"],
        parent: 9,
    },
    {
        code: 42,
        name: "kiipTaxSusb",
        country: ["VE"],
        parent: 9,
    },
    {
        code: 69,
        name: "kiipRepSusb",
        country: ["US", "GB", "LA", "VE"],
        parent: 9,
    },
    {
        code: 43,
        name: "customerSup",
        country: ["US", "GB", "LA", "VE"],
        parent: 9,
    },
    {
        code: 17,
        name: "taxExRecu",
        country: ["US", "VE"],
        parent: 4,
    },
    {
        code: 19,
        name: "taxExConsult",
        country: ["US", "VE"],
        parent: 4,
    },
    {
        code: 20,
        name: "others",
        country: ["US", "VE"],
        parent: 4,
    },
    {
        code: 28,
        name: "compStand",
        country: ["US"],
        parent: 6,
    },
    {
        code: 29,
        name: "compUpda",
        country: ["US"],
        parent: 6,
    },
    {
        code: 30,
        name: "others",
        country: ["US"],
        parent: 6,
    },
    {
        code: 31,
        name: "revStd",
        country: ["US"],
        parent: 7,
    },
    {
        code: 32,
        name: "revUpda",
        country: ["US"],
        parent: 7,
    },
    {
        code: 33,
        name: "others",
        country: ["US"],
        parent: 7,
    },
    {
        code: 45,
        name: "arAuto",
        country: ["US", "GB", "LA", "VE"],
        parent: 10,
    },
    {
        code: 68,
        name: "apAuto",
        country: ["US", "GB", "LA", "VE"],
        parent: 10,
    },
    {
        code: 46,
        name: "accoServ",
        country: ["US", "GB", "LA", "VE"],
        parent: 11,
    },
    {
        code: 47,
        name: "contServ",
        country: ["US", "GB", "LA", "VE"],
        parent: 11,
    },
    {
        code: 48,
        name: "finanServ",
        country: ["US", "GB", "LA", "VE"],
        parent: 11,
    },
    {
        code: 49,
        name: "taxConsult",
        country: ["US", "VE"],
        parent: 11,
    },
    {
        code: 50,
        name: "softConsult",
        alt_name: "Xero consulting",
        country: ["US", "GB", "LA", "VE"],
        parent: 11,
    },
    {
        code: 51,
        name: "analyFee",
        country: ["US", "GB", "LA", "VE"],
        parent: 11,
    },
    {
        code: 52,
        name: "leadFee",
        country: ["US", "GB", "LA", "VE"],
        parent: 11,
    },
    {
        code: 53,
        name: "manaFee",
        country: ["US", "GB", "LA", "VE"],
        parent: 11,
    },
    {
        code: 54,
        name: "directFee",
        country: ["US", "GB", "LA", "VE"],
        parent: 11,
    },
    {
        code: 55,
        name: "partnFee",
        country: ["US", "GB", "LA", "VE"],
        parent: 11,
    },
    {
        code: 56,
        name: "munBar",
        country: ["VE"],
        parent: 12,
    },
    {
        code: 57,
        name: "munSuc",
        country: ["VE"],
        parent: 12,
    },
    {
        code: 58,
        name: "munLib",
        country: ["VE"],
        parent: 12,
    },
    {
        code: 59,
        name: "munChac",
        country: ["VE"],
        parent: 12,
    },
    {
        code: 60,
        name: "munOther",
        country: ["VE"],
        parent: 12,
    },
    {
        code: 61,
        name: "igp",
        country: ["VE"],
        parent: 12,
    },
    {
        code: 62,
        name: "fonacit",
        country: ["VE"],
        parent: 12,
    },
    {
        code: 63,
        name: "fnd",
        country: ["VE"],
        parent: 12,
    },
    {
        code: 64,
        name: "fona",
        country: ["VE"],
        parent: 12,
    },
    {
        code: 65,
        name: "sunbiz",
        country: ["US"],
        parent: 13,
    },
    {
        code: 66,
        name: "eeffVis",
        country: ["VE"],
        parent: 13,
    },
    {
        code: 67,
        name: "others",
        country: ["VE", "US", "GB", "LA"],
        parent: 13,
    },
]

export default projects;