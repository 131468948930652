/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

/** MUI icons */
import DownloadIcon from "@mui/icons-material/Download";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

/** MUI imports */
import {
    Grid,
    Table,
    TableHeaderRow,
    PagingPanel,
    Toolbar,
    SearchPanel,
    TableSelection,
} from "@devexpress/dx-react-grid-material-ui";
import {
    SearchState,
    IntegratedFiltering,
    PagingState,
    IntegratedPaging,
    SelectionState,
    IntegratedSelection,
} from "@devexpress/dx-react-grid";
import { styled } from "@mui/material/styles";
import GridUI from "@mui/material/Grid";
import {
    Box,
    Button,
    Chip,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Typography,
    Skeleton,
    Checkbox,
    ListItemText,
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

/** Components imports */
import Backdrop from "../../Backdrop";
import DialogAlerts from "../../DialogAlerts";
import SnackBar from "../../SnackBar";

import moment from "moment";

const PREFIX = "UpInvoice";

const classes = {
    paper: `${PREFIX}-paper`,
    tableStriped: `${PREFIX}-tableStriped`,
    tableHeader: `${PREFIX}-tableHeader`,
    tableBody: `${PREFIX}-tableBody`,
    pager: `${PREFIX}-pager`,
    cardContentRoot: `${PREFIX}-cardContentRoot`,
    cardContentRoot2: `${PREFIX}-cardContentRoot2`,
    cardHeaderRoot: `${PREFIX}-cardHeaderRoot`,
    formRounded: `${PREFIX}-formRounded`,
    spacing: `${PREFIX}-spacing`,
    toolbarSearch: `${PREFIX}-toolbarSearch`,
    searchInput: `${PREFIX}-searchInput`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.paper}`]: {
        border: "2px solid #ff5968",
        borderRadius: 40,
        boxShadow: "none",
    },

    [`& .${classes.cardContentRoot}`]: {
        "&:last-child": {
            paddingBottom: "5px",
        },
        padding: "10px",
    },

    [`& .${classes.cardContentRoot2}`]: {
        "&:last-child": {
            paddingBottom: "18px",
        },
        padding: 30,
    },

    [`& .${classes.cardHeaderRoot}`]: {
        padding: 30,
    },

    [`& .${classes.formRounded}`]: {
        backgroundColor: "#FFFFFF !important",
        borderRadius: 40,
        verticalAlign: "middle !important",
        "&:focus-within": {
            backgroundColor: "#FFFFFF",
            borderRadius: 40,
        },
        "& .MuiOutlinedInput-notchedOutline": {
            border: "2px solid #031851",
            borderRadius: 40,
        },
    },

    [`& .${classes.spacing}`]: {
        padding: "30px 0 20px 0",
    },

    [`& .${classes.tableStriped}`]: {
        "& tbody tr:nth-of-type(odd)": {
            backgroundColor: "rgba(47,49,144,0.1)",
        },
    },
    [`& .${classes.tableHeader}`]: {
        border: "none ",
        backgroundColor: "rgba(47,49,144,0.3)",
        fontSize: "15px",
        fontWeight: 600,
        color: "#031851",
        "&:first-of-type": {
            borderRadius: "20px 0 0 0",
        },
        "&:last-child": {
            borderRadius: "0 20px 0 0",
        },
    },

    [`& .${classes.tableBody}`]: {
        border: "none",
        fontSize: "14px",
        fontWeight: 400,
        color: "#031851",
    },

    [`& .${classes.pager}`]: {
        "& .Pagination-button": {
            paddingTop: 0,
            paddingBottom: 0,
            color: "#031851",
            fontWeight: 600,
        },
        "& .Pagination-activeButton": {
            backgroundColor: "rgba(47,49,144,0.3)",
        },
    },

    [`& .${classes.toolbarSearch}`]: {
        border: "none !important",
        padding: "0 0 5px !important",
    },

    [`& .${classes.searchInput}`]: {
        color: "#031851",
        "&:before": {
            borderBottomColor: "#031851",
        },
        "&:hover:before": {
            borderBottomColor: "#031851 !important",
        },
    },
}));

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "rgba(19, 31, 62, 0.80)",
        fontSize: 12,
        maxWidth: 320,
        borderRadius: 6,
        fontWeight: "normal",
    },
});

const tableHeaderCell = ({ ...props }) => <TableHeaderRow.Cell {...props} className={classes.tableHeader} />;

const TableComponent = ({ ...props }) => <Table.Table {...props} className={classes.tableStriped} />;

const TableCellComponent = ({ ...props }) => <Table.Cell {...props} className={classes.tableBody} />;

const tableContentComponent = (props) => <TableHeaderRow.Content {...props} />;

const PagingPanelContainer = ({ ...props }) => <PagingPanel.Container {...props} className={classes.pager} />;

const inputComponent = ({ ...props }) => <SearchPanel.Input {...props} className={classes.searchInput} />;

const toolRootComponent = ({ ...props }) => <Toolbar.Root {...props} className={classes.toolbarSearch} />;

const tableHeaderSelCellBase = ({ ...props }) => (
    <TableSelection.HeaderCell {...props} className={classes.tableHeader} disabled />
);

const TableSelCellComponent = ({ ...props }) => <TableSelection.Cell {...props} className={classes.tableBody} />;

const tableColumnExtensions = [
    { columnName: "client", width: "20%" },
    { columnName: "adjusted", align: "center", width: "5%" },
    { columnName: "service", align: "center", width: "10%" },
    { columnName: "type", align: "center", width: "9%" },
    { columnName: "period", align: "center", width: "9%" },
    { columnName: "validate", align: "center" },
    { columnName: "initial", align: "right" },
    { columnName: "total", align: "right" },
    { columnName: "difference", align: "right" },
    { columnName: "report", align: "center" },
];

const rowsSkeleton = [];

for (let i = 0; i < 10; i++) {
    rowsSkeleton.push({
        id: i,
        client: <Skeleton variant="text" animation="wave" />,
        adjusted: (
            <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />
        ),
        service: <Skeleton height={30} width="100%" animation="wave" />,
        type: <Skeleton height={30} width="100%" animation="wave" />,
        period: <Skeleton variant="text" animation="wave" />,
        validate: <Skeleton variant="text" animation="wave" />,
        initial: <Skeleton variant="text" animation="wave" />,
        total: <Skeleton variant="text" animation="wave" />,
        difference: <Skeleton variant="text" animation="wave" />,
        report: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
    });
}

const UpInvoice = (props) => {
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    const tableMessages = {
        noData: t("miscellaneous.noData"),
    };
    const pagingMessages = {
        info: ({ from, to, count }) => `${from}${from < to ? `-${to}` : ""} ${t("miscellaneous.pagingOf")} ${count}`,
    };

    const searchMessages = {
        searchPlaceholder: t("miscellaneous.search"),
    };

    /** React redux */
    const todos = useSelector((state) => state.todos);

    const franqDataProps = props.franqData;
    const groupDataProps = props.groupData;

    /** Component states */
    const [selection, setSelection] = useState([]);
    const [clientsData, setClients] = useState([]);
    const [columnDefs, setColumnsDefs] = useState([]);
    const [showBackdrop, setBackDrop] = useState(false);
    const [clientT, setTClient] = useState("");
    const [franqF, setfranqF] = useState(["all"].concat(franqDataProps.map((item) => item.id)));
    const [groupF, setgroupF] = useState(["all"].concat(groupDataProps.map((item) => item.id)));
    const [updateTypeF, setUpdateTypeF] = useState("");
    const [servicesF, setServicesF] = useState("");
    const [groupData, setGroupData] = useState(groupDataProps);
    const [franqData, setFranqData] = useState(franqDataProps);
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [dialogText, setDialogText] = useState("");
    const [dialogType, setDialogType] = useState("info");
    const [show, setShow] = useState(false);
    const [texto, setTexto] = useState("");
    const [alertType, seAutoAlertType] = useState("");
    const [searchValue, setSearchState] = useState("");
    const [anyFilter, setAnyFilter] = useState(true);
    const controllerRef = useRef(null);

    /** Component functions */
    const cleanFilters = () => {
        setAnyFilter(true);
        setTClient("");
        setfranqF(["all"].concat(franqDataProps.map((item) => item.id)));
        setgroupF(["all"].concat(groupDataProps.map((item) => item.id)));
        setGroupData(groupDataProps);
        setUpdateTypeF("");
        setServicesF("");
    };

    const handleSearch = (value) => {
        let itemS = value.replace(/ +(?= )/g, "");
        setSearchState(itemS);
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setShow(false);
    };

    const handleOpenDialog = () => {
        setDialogType("info");
        setDialogText(t("clientsTable.clientSureAdjust"));
        setOpen(true);
    };

    const handleOpenDialog2 = () => {
        setDialogType("info");
        setDialogText(t("clientsTable.clientSureInvoice"));
        setOpen2(true);
    };

    const handleCloseDialog = () => {
        setDialogText("");
        setDialogType("");
        setOpen(false);
        setOpen2(false);
    };

    const handleChangeTClient = async (event) => {
        setBackDrop(true);
        setTClient(event.target.value);
        setAnyFilter(false);

        let finalFranq = [];

        await fetch(`/getClientFranchiseFilter?client_type=${event.target.value}`, {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then((res) => res.json())
            .then((data) => {
                setFranqData(data);
                if (event.target.value === "") {
                    finalFranq = [];
                } else {
                    finalFranq = data.map((item) => item.id);
                }
                setfranqF(["all"].concat(data.map((item) => item.id)));
            });

        await fetch(`/getFranchiseGroups?id_franchise=${finalFranq}`, {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then((res) => res.json())
            .then((data) => {
                setGroupData(data);
                setgroupF(["all"].concat(data.map((item) => item.id)));
            });
        setBackDrop(false);
    };

    const handleChangeFranqF = async (event) => {
        setBackDrop(true);

        const {
            target: { value },
        } = event;

        let final = [];

        if (value.includes("all") && !franqF.includes("all")) {
            final = ["all"].concat(franqData.map((item) => item.id));
        } else if (!value.includes("all") && franqF.includes("all")) {
            final = [];
        } else {
            if (!value.includes("all") && value.length === franqData.length) {
                final = ["all"].concat(franqData.map((item) => item.id));
            } else {
                final =
                    typeof value === "string"
                        ? value.split(",").filter((item) => item !== "all")
                        : value.filter((item) => item !== "all");
            }
        }
        setfranqF(final);
        setAnyFilter(false);

        let finalFranq = [];
        if (final.includes("all")) {
            finalFranq = [];
        } else if (final.length === 0) {
            finalFranq = ["none"];
        } else {
            finalFranq = final.filter((item) => item !== "all");
        }

        await fetch(`/getFranchiseGroups?id_franchise=${finalFranq}`, {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then((res) => res.json())
            .then((data) => {
                setgroupF(["all"].concat(data.map((item) => item.id)));
                setGroupData(data);
            });

        setBackDrop(false);
    };

    const handleChangeGroupF = (event) => {
        const {
            target: { value },
        } = event;

        let final = [];

        if (value.includes("all") && !groupF.includes("all")) {
            final = ["all"].concat(groupData.map((item) => item.id));
        } else if (!value.includes("all") && groupF.includes("all")) {
            final = [];
        } else {
            if (!value.includes("all") && value.length === groupData.length) {
                final = ["all"].concat(groupData.map((item) => item.id));
            } else {
                final = event.target.value.filter((item) => item !== "all");
            }
        }
        setgroupF(final);
        setAnyFilter(false);
    };

    const handleChangeUpdateTypeF = (event) => {
        setUpdateTypeF(event.target.value);
        setAnyFilter(false);
    };

    const handleChangeServicesF = (event) => {
        setServicesF(event.target.value);
        setAnyFilter(false);
    };

    const adjustUpdates = async () => {
        setBackDrop(true);

        let arrayValidation = [];

        selection.forEach((selectedRow) => {
            arrayValidation.push(clientsData[selectedRow].id);
        });

        const params = {
            array_updates: arrayValidation,
        };

        await fetch("/adjustUpdates", {
            method: "POST",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
            body: JSON.stringify(params),
        })
            .then(() => {
                handleCloseDialog();
                setSelection([]);
                loadInitData();
                setTexto(t("clientsTable.clientsBeingAdjusted"));
                seAutoAlertType("success");
                setShow(true);
            })
            .catch((err) => {
                setTexto(err.error);
                seAutoAlertType("error");
                setShow(true);
            });

        setBackDrop(false);
    };

    const billUpdates = async () => {
        setBackDrop(true);

        let arrayValidation = [];

        selection.forEach((selectedRow) => {
            arrayValidation.push(clientsData[selectedRow].id);
        });

        const params = {
            array_updates: arrayValidation,
        };

        await fetch("/billUpdate", {
            method: "POST",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
            body: JSON.stringify(params),
        })
            .then(() => {
                handleCloseDialog();
                setSelection([]);
                loadInitData();
                setTexto(t("clientsTable.clientsBeingInvoiced"));
                seAutoAlertType("success");
                setShow(true);
            })
            .catch((err) => {
                setTexto(err.error);
                seAutoAlertType("error");
                setShow(true);
            });

        setBackDrop(false);
    };

    const downloadRep = async (id, compName, type, date) => {
        setBackDrop(true);

        let dateAux = moment(date, "YYYY-MM-DD").locale("en").format("MMM YYYY");
        let aux = type === 1 ? "Catch Up invoicing report.pdf" : "Clean Up invoicing report.pdf";
        let filename = compName + " - " + dateAux + " - " + aux;

        await fetch("/generateUpdatesOnlineInvoicingReport?update_id=" + id, {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then((res) => res.blob())
            .then((blob) => {
                const link = document.createElement("a");
                link.href = URL.createObjectURL(blob);
                link.setAttribute("download", filename);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch((err) => {
                setTexto(t("manageAccount.dnldError"));
                seAutoAlertType("warning");
                setShow(true);
            });

        setBackDrop(false);
    };

    const loadInitData = async () => {
        if (controllerRef.current) {
            controllerRef.current.abort();
        }
        const controller = new AbortController();
        controllerRef.current = controller;

        let finalFranq = [];
        if (franqF.includes("all")) {
            finalFranq = [];
        } else if (franqF.length === 0) {
            finalFranq = ["none"];
        } else {
            finalFranq = franqF.filter((item) => item !== "all");
        }

        let finalGroup = [];
        if (groupF.includes("all")) {
            finalGroup = [];
        } else if (groupF.length === 0) {
            finalGroup = ["none"];
        } else {
            finalGroup = groupF.filter((item) => item !== "all");
        }

        const resultT = await fetch(
            `/getUpdatesInfo?update_status=2&client_type=${clientT}&franchise_id=${finalFranq}&practice_id=${finalGroup}&service_name=${servicesF}&service_type=${updateTypeF}`,
            {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
                signal: controllerRef.current.signal,
            }
        )
            .then((res) => res.json())
            .then((data) => {
                controllerRef.current = null;
                return data;
            });

        let clientsdata = resultT.map((clientData, index) => {
            return {
                id: clientData._id,
                client: clientData.company_name,
                adjusted: clientData.adjusted ? (
                    <LightTooltip title={t("clientsTable.adjusted")} aria-label="ajusted">
                        <WarningAmberIcon color="secondary" />
                    </LightTooltip>
                ) : null,
                service: (
                    <Chip
                        label={clientData.service_name}
                        sx={{
                            backgroundColor: "#fff",
                            color: "#848484",
                            borderWidth: 2,
                            fontStyle: "italic",
                            ...(moment.locale() === "en" && { fontStyle: "normal" }),
                        }}
                        variant="outlined"
                    />
                ),
                type: (
                    <Chip
                        label={clientData.service_type === 1 ? "Catch Up" : "Clean Up"}
                        sx={{
                            backgroundColor: "#fff",
                            color: clientData.service_type === 1 ? "#FFB039" : "#2f3190",
                            borderWidth: 2,
                            borderColor: clientData.service_type === 1 ? "#FFB039" : "#2f3190",
                            fontStyle: "italic",
                            ...(moment.locale() === "en" && { fontStyle: "normal" }),
                        }}
                        variant="outlined"
                    />
                ),
                period:
                    moment(clientData.init_date, "YYYY-MM-DD").format("MM/YY") +
                    " - " +
                    moment(clientData.end_date, "YYYY-MM-DD").format("MM/YY"),
                validate:
                    clientData.validation_date !== undefined
                        ? moment(clientData.validation_date, "YYYY-MM-DD").format(todos.dateFormat)
                        : null,
                initial:
                    "$" +
                    parseFloat(
                        clientData.invoice_initial !== undefined ? clientData.invoice_initial : 0
                    ).toLocaleString(todos.amountFormat, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                total:
                    "$" +
                    parseFloat(clientData.invoice_total !== undefined ? clientData.invoice_total : 0).toLocaleString(
                        todos.amountFormat,
                        { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                    ),
                difference:
                    "$" +
                    parseFloat(
                        (clientData.invoice_total !== undefined ? clientData.invoice_total : 0) -
                            (clientData.invoice_initial !== undefined ? clientData.invoice_initial : 0)
                    ).toLocaleString(todos.amountFormat, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                report: (
                    <LightTooltip title={t("clientsTable.downloadInvoicingRept")} aria-label="toolDownload">
                        <IconButton
                            aria-label="download"
                            onClick={() =>
                                downloadRep(
                                    clientData._id,
                                    clientData.company_name,
                                    clientData.service_type,
                                    clientData.end_date
                                )
                            }
                        >
                            <DownloadIcon sx={{ color: "#031851" }} />
                        </IconButton>
                    </LightTooltip>
                ),
            };
        });

        setClients(clientsdata);
    };

    useEffect(() => {
        (async () => {
            const columnsTemplate = [
                { name: "client", title: t("miscellaneous.client") },
                { name: "adjusted", title: " " },
                { name: "service", title: " " },
                { name: "type", title: " " },
                { name: "period", title: t("services.period") },
                { name: "validate", title: t("clientsTable.validated") },
                { name: "initial", title: t("clientsTable.initialInvoice") },
                { name: "total", title: t("clientsTable.totalInvoice") },
                { name: "difference", title: t("miscellaneous.difference") },
                { name: "report", title: t("miscellaneous.report2") },
            ];
            setColumnsDefs(columnsTemplate);
            setClients(rowsSkeleton);

            await loadInitData();
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t, todos.dateFormat, todos.amountFormat, clientT, franqF, groupF, updateTypeF, servicesF]);

    return (
        <StyledContainer>
            <GridUI container spacing={2} className={classes.spacing}>
                <GridUI item xs={12} md={4} xl>
                    <FormControl fullWidth classes={{ root: classes.formRounded }}>
                        <InputLabel shrink id="type-simple-select-label">
                            {t("miscellaneous.type")}
                        </InputLabel>
                        <Select
                            size="small"
                            IconComponent={KeyboardArrowDownIcon}
                            value={clientT}
                            displayEmpty
                            onChange={handleChangeTClient}
                            className="ddlGeneral"
                            input={<OutlinedInput notched label={t("miscellaneous.type")} />}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        mt: 0.7,
                                        borderRadius: 2,
                                        boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                    },
                                },
                            }}
                        >
                            <MenuItem value="">
                                <Typography variant="body1" component="span" align="center" color="textPrimary">
                                    {t("miscellaneous.all")}
                                </Typography>
                            </MenuItem>
                            {props.typeData.map((value) => (
                                <MenuItem value={value.id}>
                                    <Typography variant="body1" component="span" align="center" color="textPrimary">
                                        {value.id === 1 ? t("miscellaneous.firm") : value.client_type}
                                    </Typography>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </GridUI>
                <GridUI item xs={12} md={4} xl={2}>
                    <FormControl fullWidth classes={{ root: classes.formRounded }}>
                        <InputLabel shrink id="franq-simple-select-label">
                            {t("miscellaneous.franchise")}
                        </InputLabel>
                        <Select
                            size="small"
                            labelId="franq-multiple-select-label"
                            IconComponent={KeyboardArrowDownIcon}
                            value={franqF}
                            displayEmpty
                            onChange={handleChangeFranqF}
                            className="ddlGeneral"
                            multiple
                            input={
                                <OutlinedInput
                                    notched
                                    label={t("miscellaneous.franchise")}
                                    sx={{
                                        fontSize: 16,
                                        fontWeight: 600,
                                    }}
                                />
                            }
                            renderValue={(selected) => {
                                if (selected.length === 0) {
                                    return <span>{t("miscellaneous.none")}</span>;
                                }
                                return selected.includes("all")
                                    ? t("miscellaneous.all")
                                    : selected
                                          .map((item) => {
                                              return franqDataProps.filter((item2) => item2.id === item)[0]
                                                  .franchise_name;
                                          })
                                          .join(", ");
                            }}
                            MenuProps={props.MenuProps}
                        >
                            {[
                                {
                                    id: "all",
                                    franchise_name: t("miscellaneous.all"),
                                },
                            ]
                                .concat(franqData)
                                .map((value) => (
                                    <MenuItem value={value.id}>
                                        <Checkbox
                                            defaultChecked
                                            checked={franqF.indexOf(value.id) > -1}
                                            icon={
                                                value.id === "all" && !franqF.includes("all") && franqF.length > 0 ? (
                                                    <IndeterminateCheckBoxIcon style={{ fill: "#031851" }} />
                                                ) : (
                                                    <CheckBoxOutlineBlankIcon />
                                                )
                                            }
                                        />
                                        <ListItemText primary={value.franchise_name} />
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                </GridUI>
                <GridUI item xs={12} md={4} xl={2}>
                    <FormControl fullWidth classes={{ root: classes.formRounded }}>
                        <InputLabel shrink id="group-simple-select-label">
                            {t("miscellaneous.group")}
                        </InputLabel>
                        <Select
                            size="small"
                            labelId="group-simple-select-label"
                            IconComponent={KeyboardArrowDownIcon}
                            value={groupF}
                            displayEmpty
                            onChange={handleChangeGroupF}
                            className="ddlGeneral"
                            multiple
                            input={
                                <OutlinedInput
                                    notched
                                    label={t("miscellaneous.group")}
                                    sx={{
                                        fontSize: 16,
                                        fontWeight: 600,
                                    }}
                                />
                            }
                            renderValue={(selected) => {
                                if (selected.length === 0) {
                                    return <span>{t("miscellaneous.none")}</span>;
                                }
                                return selected.includes("all")
                                    ? t("miscellaneous.all")
                                    : selected
                                          .map((item) => {
                                              return groupDataProps.filter((item2) => item2.id === item)[0].group_name;
                                          })
                                          .join(", ");
                            }}
                            MenuProps={props.MenuProps}
                        >
                            {[
                                {
                                    id: "all",
                                    group_name: t("miscellaneous.all"),
                                },
                            ]
                                .concat(groupData)
                                .map((value) => (
                                    <MenuItem value={value.id}>
                                        <Checkbox
                                            defaultChecked
                                            checked={groupF.indexOf(value.id) > -1}
                                            icon={
                                                value.id === "all" && !groupF.includes("all") && groupF.length > 0 ? (
                                                    <IndeterminateCheckBoxIcon style={{ fill: "#031851" }} />
                                                ) : (
                                                    <CheckBoxOutlineBlankIcon />
                                                )
                                            }
                                        />
                                        <ListItemText primary={value.group_name} />
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                </GridUI>
                <GridUI item xs={12} md={4} xl>
                    <FormControl fullWidth classes={{ root: classes.formRounded }}>
                        <InputLabel shrink id="updateTypeF-simple-select-label">
                            {t("clientsTable.updateType")}
                        </InputLabel>
                        <Select
                            size="small"
                            IconComponent={KeyboardArrowDownIcon}
                            value={updateTypeF}
                            displayEmpty
                            onChange={handleChangeUpdateTypeF}
                            className="ddlGeneral"
                            input={<OutlinedInput notched label={t("clientsTable.updateType")} />}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        mt: 0.7,
                                        borderRadius: 2,
                                        boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                    },
                                },
                            }}
                        >
                            <MenuItem value="">
                                <Typography variant="body1" component="span" align="center" color="textPrimary">
                                    {t("miscellaneous.all")}
                                </Typography>
                            </MenuItem>
                            <MenuItem value={1}>
                                <Typography
                                    variant="body1"
                                    component="span"
                                    align="center"
                                    color="textPrimary"
                                    sx={{
                                        fontStyle: "italic",
                                        ...(moment.locale() === "en" && { fontStyle: "normal" }),
                                    }}
                                >
                                    Catch Up
                                </Typography>
                            </MenuItem>
                            <MenuItem value={2}>
                                <Typography
                                    variant="body1"
                                    component="span"
                                    align="center"
                                    color="textPrimary"
                                    sx={{
                                        fontStyle: "italic",
                                        ...(moment.locale() === "en" && { fontStyle: "normal" }),
                                    }}
                                >
                                    Clean Up
                                </Typography>
                            </MenuItem>
                        </Select>
                    </FormControl>
                </GridUI>
                <GridUI item xs={12} md={4} xl>
                    <FormControl fullWidth classes={{ root: classes.formRounded }}>
                        <InputLabel shrink id="servicesF-simple-select-label">
                            {t("principal.services")}
                        </InputLabel>
                        <Select
                            size="small"
                            IconComponent={KeyboardArrowDownIcon}
                            value={servicesF}
                            displayEmpty
                            onChange={handleChangeServicesF}
                            className="ddlGeneral"
                            input={<OutlinedInput notched label={t("principal.services")} />}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        mt: 0.7,
                                        borderRadius: 2,
                                        boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                    },
                                },
                            }}
                        >
                            <MenuItem value="">
                                <Typography variant="body1" component="span" align="center" color="textPrimary">
                                    {t("miscellaneous.all")}
                                </Typography>
                            </MenuItem>
                            <MenuItem value="Accountant">
                                <Typography
                                    variant="body1"
                                    component="span"
                                    align="center"
                                    color="textPrimary"
                                    sx={{
                                        fontStyle: "italic",
                                        ...(moment.locale() === "en" && { fontStyle: "normal" }),
                                    }}
                                >
                                    Accountant
                                </Typography>
                            </MenuItem>
                            <MenuItem value="Bookkeeper">
                                <Typography
                                    variant="body1"
                                    component="span"
                                    align="center"
                                    color="textPrimary"
                                    sx={{
                                        fontStyle: "italic",
                                        ...(moment.locale() === "en" && { fontStyle: "normal" }),
                                    }}
                                >
                                    Bookkeeper
                                </Typography>
                            </MenuItem>
                            <MenuItem value="CFO">
                                <Typography
                                    variant="body1"
                                    component="span"
                                    align="center"
                                    color="textPrimary"
                                    sx={{
                                        fontStyle: "italic",
                                        ...(moment.locale() === "en" && { fontStyle: "normal" }),
                                    }}
                                >
                                    CFO
                                </Typography>
                            </MenuItem>
                            <MenuItem value="Controller">
                                <Typography
                                    variant="body1"
                                    component="span"
                                    align="center"
                                    color="textPrimary"
                                    sx={{
                                        fontStyle: "italic",
                                        ...(moment.locale() === "en" && { fontStyle: "normal" }),
                                    }}
                                >
                                    Controller
                                </Typography>
                            </MenuItem>
                        </Select>
                    </FormControl>
                </GridUI>
                <GridUI item xs={12} md={2} xl={1} sx={{ textAlign: "right !important" }}>
                    <Button
                        disableElevation
                        variant="outlined"
                        color="secondary"
                        onClick={handleOpenDialog}
                        disabled={selection.length === 0}
                        sx={{
                            pl: 3,
                            pr: 3,
                            border: "1px solid #ff5968",
                            borderRadius: 40,
                            "&:hover": {
                                border: "1px solid #2f3190",
                                color: "#2f3190",
                            },
                            "&.Mui-disabled": {
                                color: "#ffcdd2",
                                borderColor: "#ffcdd2",
                                background: "transparent",
                            },
                        }}
                    >
                        {t("miscellaneous.adjust")}
                    </Button>
                </GridUI>
                <GridUI item xs={12} md={2} xl={1} sx={{ textAlign: "right !important" }}>
                    <Button
                        disableElevation
                        variant="contained"
                        color="secondary"
                        onClick={handleOpenDialog2}
                        disabled={selection.length === 0}
                        sx={{
                            pl: 3,
                            pr: 3,
                            borderRadius: 40,
                            "&:hover": {
                                backgroundColor: "#2f3190",
                                color: "#fff",
                            },
                            "&.Mui-disabled": {
                                color: "#fff",
                                borderColor: "#ffcdd2",
                                background: "#ffcdd2",
                            },
                        }}
                    >
                        {t("miscellaneous.invoice2")}
                    </Button>
                </GridUI>
            </GridUI>
            <GridUI container>
                <GridUI item xs={12} md={6} sx={{ mb: { sm: -5 }, zIndex: 1 }}>
                    <Button
                        variant="text"
                        color="primary"
                        disabled={anyFilter}
                        onClick={cleanFilters}
                        sx={{
                            "&:hover": {
                                color: "#2f3190",
                            },
                        }}
                    >
                        {t("miscellaneous.cleanFilters")}
                    </Button>
                </GridUI>
                <GridUI item xs={12}>
                    <Grid rows={clientsData} columns={columnDefs}>
                        <SelectionState selection={selection} onSelectionChange={setSelection} />
                        <SearchState value={searchValue} onValueChange={handleSearch} />
                        <IntegratedFiltering />
                        <PagingState defaultCurrentPage={0} pageSize={10} />
                        <IntegratedPaging />
                        <IntegratedSelection />
                        <Table
                            tableComponent={TableComponent}
                            columnExtensions={tableColumnExtensions}
                            cellComponent={TableCellComponent}
                            messages={tableMessages}
                        />
                        <TableHeaderRow cellComponent={tableHeaderCell} contentComponent={tableContentComponent} />
                        <TableSelection
                            showSelectAll
                            headerCellComponent={tableHeaderSelCellBase}
                            cellComponent={TableSelCellComponent}
                        />
                        <Toolbar rootComponent={toolRootComponent} />
                        <SearchPanel messages={searchMessages} inputComponent={inputComponent} />
                        <PagingPanel containerComponent={PagingPanelContainer} messages={pagingMessages} />
                    </Grid>
                </GridUI>
            </GridUI>
            <DialogAlerts
                open={open}
                onClose={handleCloseDialog}
                agreeBtnLabel={t("miscellaneous.accept")}
                disagreeBtnLabel={t("miscellaneous.cancel")}
                type={dialogType}
                title={dialogText}
                agreeAction={adjustUpdates}
            />
            <DialogAlerts
                open={open2}
                onClose={handleCloseDialog}
                agreeBtnLabel={t("miscellaneous.accept")}
                disagreeBtnLabel={t("miscellaneous.cancel")}
                type={dialogType}
                title={dialogText}
                agreeAction={billUpdates}
            />
            <SnackBar type={alertType} show={show} message={texto} onClose={handleClose} />
            <Backdrop open={showBackdrop} />
        </StyledContainer>
    );
};

export default UpInvoice;
