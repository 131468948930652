// React
import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { NumericFormat } from "react-number-format";

// MUI
import {
    Box,
    TextField,
    Grid,
    Divider,
    Card,
    CardHeader,
    CardContent,
    FormControlLabel,
    Checkbox,
    FormControl,
    InputLabel,
    InputAdornment,
    OutlinedInput,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const PREFIX = "UpdateAdd";

const classes = {
    btnRoot: `${PREFIX}-btnRoot`,
    buttonContainer: `${PREFIX}-buttonContainer`,
    switch: `${PREFIX}-switch`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.btnRoot}`]: {
        border: "1px solid #ff5968",
        borderRadius: 40,
        "&:hover": {
            backgroundColor: "#2f3190",
            border: "1px solid #2f3190",
        },
        "&.Mui-disabled": {
            color: "#fff",
            borderColor: "#ffcdd2",
            background: "#ffcdd2",
        },
    },
    [`& .${classes.buttonContainer}`]: {
        display: "flex",
        justifyContent: "flex-end",
        padding: 15,
    },
    [`& .${classes.switch}`]: {
        "& .MuiSwitch-switchBase .MuiSwitch-thumb": {
            color: "#FFF",
        },
        "& .MuiSwitch-switchBase + .MuiSwitch-track": {
            backgroundColor: "#031851",
        },
        "& .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb": {
            color: "#FF5968",
        },
        "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
            backgroundColor: "#FFA8B1",
        },
    },
}));

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "rgba(19, 31, 62, 0.80)",
        fontSize: 12,
        maxWidth: 320,
        borderRadius: 6,
        fontWeight: "normal",
    },
});

const NumberFormatCustom = (props) => {
    const todos = useSelector((state) => state.todos);

    const { inputRef, value, onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            value={value}
            getInputRef={inputRef}
            onValueChange={({ value: v }) => onChange({ target: { value: v } })}
            thousandSeparator={todos.amountFormat === "de-DE" ? "." : ","}
            decimalSeparator={todos.amountFormat === "de-DE" ? "," : "."}
            fixedDecimalScale={true}
            decimalScale={2}
            valueIsNumericString
        />
    );
};

export const UpdateAdd = (props) => {
    // Properties
    const { values, set, setTexto, setalertType, setShow, sumTotal, isError, isErrorFocus, setIsErrorFocus } = props;

    /** Internationalization i18n */
    const [t] = useTranslation("global");

    /** React redux */
    const todos = useSelector((state) => state.todos);

    /** Component functions */
    const handleChange = (event) => {
        set({
            ...values,
            [event.target.name]: event.target.value,
        });
        setIsErrorFocus(false)
    };

    const handleChangeHoursCFO = (event) => {
        let accMonths = 0;
        if (values.additionalsOnly) {
            if (values.fixedHours) {
                accMonths = 1
            } else {
                accMonths = isNaN(moment(values.finishDateCFO).diff(moment(values.startDateCFO)))
                ? 0
                : Math.ceil(moment(values.finishDateCFO).diff(moment(values.startDateCFO), "months", true));
            }
        }

        set({
            ...values,
            [event.target.name]: event.target.value,
            projectTotal: values.hourRate * event.target.value * accMonths * (1 - values.discount / 100),
        });
        setIsErrorFocus(false)
    };

    const handleChangeCheck = (event, value) => {
        switch (value) {
            case 0:
                set({
                    ...values,
                    checkedGob: event.target.checked,
                    tothGob: 0,
                    startDateGob: event.target.checked ? values.sinceDate : null,
                    finishDateGob: event.target.checked ? values.untilDate : null,
                });
                break;
            case 1:
                set({
                    ...values,
                    checkedPres: event.target.checked,
                    tothPres: 0,
                    startDatePres: event.target.checked ? values.sinceDate : null,
                    finishDatePres: event.target.checked ? values.untilDate : null,
                });
                break;
            case 4:
                set({
                    ...values,
                    checkedRep: event.target.checked,
                    tothRep: 0,
                    startDateRep: event.target.checked ? values.sinceDate : null,
                    finishDateRep: event.target.checked ? values.untilDate : null,
                });
                break;
            case 10:
                set({
                    ...values,
                    checkedOthers: event.target.checked,
                    nameOthers: "",
                    tothOthers: 0,
                    startDateOthers: event.target.checked ? values.sinceDate : null,
                    finishDateOthers: event.target.checked ? values.untilDate : null,
                });
                break;
            case 11:
                let meses = Math.ceil(values.untilDate.diff(values.sinceDate, "months", true));
                set({
                    ...values,
                    checkedCFO: event.target.checked,
                    tothCFO: 0,
                    startDateCFO: event.target.checked ? values.sinceDate : null,
                    finishDateCFO: event.target.checked ? values.untilDate : null,
                    projectTotal: 0,
                    months: event.target.checked ? meses : 1,
                });
                break;
            default:
                break;
        }
        setIsErrorFocus(false)
    };

    const handleChangeStartDateAdd = (date, val) => {
        let startDate = moment(date).startOf("month");
        switch (val) {
            case 0:
                if (!date || !values.finishDateGob) {
                    set({
                        ...values,
                        startDateGob: date ? startDate : date,
                    });
                } else {
                    if (startDate.isAfter(moment(values.finishDateGob))) {
                        setTexto(t("reports.initDateMess"));
                        setalertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            startDateGob: startDate,
                        });
                    }
                }
                break;
            case 1:
                if (!date || !values.finishDatePres) {
                    set({
                        ...values,
                        startDatePres: date ? startDate : date,
                    });
                } else {
                    if (startDate.isAfter(moment(values.finishDatePres))) {
                        setTexto(t("reports.initDateMess"));
                        setalertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            startDatePres: startDate,
                        });
                    }
                }
                break;
            case 4:
                if (!date || !values.finishDateRep) {
                    set({
                        ...values,
                        startDateRep: date ? startDate : date,
                    });
                } else {
                    if (startDate.isAfter(moment(values.finishDateRep))) {
                        setTexto(t("reports.initDateMess"));
                        setalertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            startDateRep: startDate,
                        });
                    }
                }
                break;
            case 10:
                if (!date || !values.finishDateOthers) {
                    set({
                        ...values,
                        startDateOthers: date ? startDate : date,
                    });
                } else {
                    if (startDate.isAfter(moment(values.finishDateOthers))) {
                        setTexto(t("reports.initDateMess"));
                        setalertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            startDateOthers: startDate,
                        });
                    }
                }
                break;
            case 11:
                let accMonths = 0;
                if (values.additionalsOnly) {
                    if (values.fixedHours) {
                        accMonths = 1
                    } else {
                        accMonths = isNaN(moment(values.finishDateCFO).diff(startDate))
                        ? 0
                        : Math.ceil(moment(values.finishDateCFO).diff(startDate, "months", true));
                    }
                }

                if (!date || !values.finishDateCFO) {
                    set({
                        ...values,
                        startDateCFO: date ? startDate : date,
                        projectTotal:
                            values.hourRate * parseFloat(values.tothCFO) * accMonths * (1 - values.discount / 100),
                        months: values.additionalsOnly ? accMonths : values.months,
                    });
                } else {
                    if (startDate.isAfter(moment(values.finishDateCFO))) {
                        setTexto(t("reports.initDateMess"));
                        setalertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            startDateCFO: startDate,
                            projectTotal:
                                values.hourRate * parseFloat(values.tothCFO) * accMonths * (1 - values.discount / 100),
                            months: values.additionalsOnly ? accMonths : values.months,
                        });
                    }
                }
                break;
            default:
                break;
        }
        setIsErrorFocus(false)
    };

    const handleChangeEndDateAdd = (date, val) => {
        let endDate = moment(date).endOf("month").startOf("day");
        switch (val) {
            case 0:
                if (!date || !values.startDateGob) {
                    set({
                        ...values,
                        finishDateGob: date ? endDate : date,
                    });
                } else {
                    if (endDate.isBefore(moment(values.startDateGob))) {
                        setTexto(t("reports.endDateMess"));
                        setalertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            finishDateGob: endDate,
                        });
                    }
                }
                break;
            case 1:
                if (!date || !values.startDatePres) {
                    set({
                        ...values,
                        finishDatePres: date ? endDate : date,
                    });
                } else {
                    if (endDate.isBefore(moment(values.startDatePres))) {
                        setTexto(t("reports.endDateMess"));
                        setalertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            finishDatePres: endDate,
                        });
                    }
                }
                break;
            case 4:
                if (!date || !values.startDateRep) {
                    set({
                        ...values,
                        finishDateRep: date ? endDate : date,
                    });
                } else {
                    if (endDate.isBefore(moment(values.startDateRep))) {
                        setTexto(t("reports.endDateMess"));
                        setalertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            finishDateRep: endDate,
                        });
                    }
                }
                break;
            case 10:
                if (!date || !values.startDateOthers) {
                    set({
                        ...values,
                        finishDateOthers: date ? endDate : date,
                    });
                } else {
                    if (endDate.isBefore(moment(values.startDateOthers))) {
                        setTexto(t("reports.endDateMess"));
                        setalertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            finishDateOthers: endDate,
                        });
                    }
                }
                break;
            case 11:
                let accMonths = 0;
                if (values.additionalsOnly) {
                    if (values.fixedHours) {
                        accMonths = 1
                    } else {
                        accMonths = isNaN(endDate.diff(moment(values.startDateCFO)))
                        ? 0
                        : Math.ceil(endDate.diff(moment(values.startDateCFO), "months", true));
                    }
                }

                if (!date || !values.startDateCFO) {
                    set({
                        ...values,
                        finishDateCFO: date ? endDate : date,
                        projectTotal:
                            values.hourRate * parseFloat(values.tothCFO) * accMonths * (1 - values.discount / 100),
                        months: values.additionalsOnly ? accMonths : values.months,
                    });
                } else {
                    if (endDate.isBefore(moment(values.startDateCFO))) {
                        setTexto(t("reports.endDateMess"));
                        setalertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            finishDateCFO: endDate,
                            projectTotal:
                                values.hourRate * parseFloat(values.tothCFO) * accMonths * (1 - values.discount / 100),
                            months: values.additionalsOnly ? accMonths : values.months,
                        });
                    }
                }
                break;
            default:
                break;
        }
        setIsErrorFocus(false)
    };

    return (
        <StyledContainer>
            <LocalizationProvider
                dateAdapter={AdapterMoment}
                adapterLocale={t("language.locale")}
                localeText={{ clearButtonLabel: t("miscellaneous.clear") }}
            >
                <Card elevation={0} sx={{ backgroundColor: "inherit" }}>
                    <CardHeader title={t("proposals.additionals")} />
                    <Divider />
                    <CardContent sx={{ pb: 5 }}>
                        <Grid container spacing={2} alignItems="center">
                            {values.additionalsOnly && (
                                <>
                                    <Grid item xs={12} md={3}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    name="checkedCFO"
                                                    checked={values.checkedCFO}
                                                    onChange={() => {
                                                        return false;
                                                    }}
                                                    inputProps={{
                                                        "aria-label": "controlled",
                                                    }}
                                                />
                                            }
                                            label="CFO"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            fullWidth
                                            disabled={!values.checkedCFO || !values.sinceDate}
                                            label={values.fixedHours ? t("miscellaneous.hours") : t("miscellaneous.hoursMonth")}
                                            name="tothCFO"
                                            onChange={handleChangeHoursCFO}
                                            value={values.tothCFO}
                                            variant="outlined"
                                            type="number"
                                            inputProps={{
                                                min: 0,
                                                step: 0.5,
                                                onKeyDown: (event) => {
                                                    event.preventDefault();
                                                },
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            error={values.checkedCFO && values.tothCFO === 0 && isError}
                                            onClick={(e) => { setIsErrorFocus(false) }}
                                            inputRef={(input) => { (input && isErrorFocus && values.checkedCFO && values.tothCFO === 0 && isError) && input.focus() }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <DatePicker
                                            componentsProps={{
                                                actionBar: {
                                                    actions: ["clear"],
                                                },
                                            }}
                                            disabled={!values.checkedCFO || !values.sinceDate}
                                            value={values.startDateCFO}
                                            minDate={moment(values.sinceDate).startOf("month")}
                                            maxDate={moment(values.untilDate).endOf("month")}
                                            onChange={(newValue) => handleChangeStartDateAdd(newValue, 11)}
                                            inputFormat={todos.dateFormat}
                                            renderInput={(params) => (
                                                <TextField
                                                    fullWidth
                                                    required={values.checkedCFO}
                                                    {...params}
                                                    error={values.checkedCFO && !values.startDateCFO}
                                                />
                                            )}
                                            // inputProps={{ readOnly: true }}
                                            label={t("reports.initDate")}
                                            views={["year", "month"]}
                                            inputProps={{
                                                onKeyDown: (event) => {
                                                    event.preventDefault();
                                                },
                                            }}
                                            onClick={(e) => { setIsErrorFocus(false) }}
                                            inputRef={(input) => { (input && isErrorFocus && values.checkedCFO && !values.startDateCFO) && input.focus() }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <DatePicker
                                            componentsProps={{
                                                actionBar: {
                                                    actions: ["clear"],
                                                },
                                            }}
                                            disabled={!values.checkedCFO || !values.sinceDate}
                                            value={values.finishDateCFO}
                                            minDate={moment(values.sinceDate).startOf("month")}
                                            maxDate={moment(values.untilDate).endOf("month")}
                                            onChange={(newValue) => handleChangeEndDateAdd(newValue, 11)}
                                            inputFormat={todos.dateFormat}
                                            renderInput={(params) => (
                                                <TextField
                                                    fullWidth
                                                    required={values.checkedCFO}
                                                    {...params}
                                                    error={values.checkedCFO && !values.finishDateCFO}
                                                />
                                            )}
                                            // inputProps={{ readOnly: true }}
                                            label={t("reports.endDate")}
                                            views={["year", "month"]}
                                            inputProps={{
                                                onKeyDown: (event) => {
                                                    event.preventDefault();
                                                },
                                            }}
                                            onClick={(e) => { setIsErrorFocus(false) }}
                                            inputRef={(input) => { (input && isErrorFocus && values.checkedCFO && !values.finishDateCFO) && input.focus() }}
                                        />
                                    </Grid>
                                </>
                            )}
                            <Grid item xs={12} md={3}>
                                <LightTooltip title={t("services.corporateGovernanceTool")}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name="checkedGob"
                                                checked={values.checkedGob}
                                                onChange={(value) => handleChangeCheck(value, 0)}
                                                inputProps={{
                                                    "aria-label": "controlled",
                                                }}
                                                disabled={!values.sinceDate || !values.untilDate}
                                            />
                                        }
                                        label={t("services.corporateGovernance")}
                                    />
                                </LightTooltip>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    fullWidth
                                    disabled={!values.checkedGob}
                                    label={values.fixedHours ? t("miscellaneous.hours") : t("miscellaneous.hoursMonth")}
                                    name="tothGob"
                                    onChange={handleChange}
                                    value={values.tothGob}
                                    variant="outlined"
                                    type="number"
                                    inputProps={{
                                        min: 0,
                                        step: 0.5,
                                        onKeyDown: (event) => {
                                            event.preventDefault();
                                        },
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    error={values.checkedGob && values.tothGob === 0 && isError}
                                    onClick={(e) => { setIsErrorFocus(false) }}
                                    inputRef={(input) => { (input && isErrorFocus && values.checkedGob && values.tothGob === 0 && isError) && input.focus() }}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    disabled={!values.checkedGob}
                                    value={values.startDateGob}
                                    onChange={(newValue) => handleChangeStartDateAdd(newValue, 0)}
                                    minDate={moment(values.sinceDate).startOf("month")}
                                    maxDate={moment(values.untilDate).endOf("month")}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            required={values.checkedGob}
                                            {...params}
                                            error={values.checkedGob && !values.startDateGob}
                                            onClick={(e) => { setIsErrorFocus(false) }}
                                        />
                                    )}
                                    // inputProps={{ readOnly: true }}
                                    label={t("reports.initDate")}
                                    views={["year", "month"]}
                                    inputProps={{
                                        onKeyDown: (event) => {
                                            event.preventDefault();
                                        },
                                    }}
                                    onClick={(e) => { setIsErrorFocus(false) }}
                                    inputRef={(input) => { (input && isErrorFocus && values.checkedGob && !values.startDateGob) && input.focus() }}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    disabled={!values.checkedGob}
                                    value={values.finishDateGob}
                                    onChange={(newValue) => handleChangeEndDateAdd(newValue, 0)}
                                    minDate={moment(values.sinceDate).startOf("month")}
                                    maxDate={moment(values.untilDate).endOf("month")}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            required={values.checkedGob}
                                            {...params}
                                            error={values.checkedGob && !values.finishDateGob}
                                            onClick={(e) => { setIsErrorFocus(false) }}
                                        />
                                    )}
                                    // inputProps={{ readOnly: true }}
                                    label={t("reports.endDate")}
                                    views={["year", "month"]}
                                    inputProps={{
                                        onKeyDown: (event) => {
                                            event.preventDefault();
                                        },
                                    }}
                                    onClick={(e) => { setIsErrorFocus(false) }}
                                    inputRef={(input) => { (input && isErrorFocus && values.checkedGob && !values.finishDateGob) && input.focus() }}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>

                                <LightTooltip title={t("services.investorPresentationsTool")}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name="checkedPres"
                                                checked={values.checkedPres}
                                                onChange={(value) => handleChangeCheck(value, 1)}
                                                inputProps={{
                                                    "aria-label": "controlled",
                                                }}
                                                disabled={!values.sinceDate || !values.untilDate}
                                            />
                                        }
                                        label={t("services.investorPresentations")}
                                    />
                                </LightTooltip>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    fullWidth
                                    disabled={!values.checkedPres}
                                    label={values.fixedHours ? t("miscellaneous.hours") : t("miscellaneous.hoursMonth")}
                                    name="tothPres"
                                    onChange={handleChange}
                                    value={values.tothPres}
                                    variant="outlined"
                                    type="number"
                                    inputProps={{
                                        min: 0,
                                        step: 0.5,
                                        onKeyDown: (event) => {
                                            event.preventDefault();
                                        },
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    error={values.checkedPres && values.tothPres === 0 && isError}
                                    onClick={(e) => { setIsErrorFocus(false) }}
                                    inputRef={(input) => { (input && isErrorFocus && values.checkedPres && values.tothPres === 0 && isError) && input.focus() }}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    disabled={!values.checkedPres}
                                    value={values.startDatePres}
                                    onChange={(newValue) => handleChangeStartDateAdd(newValue, 1)}
                                    minDate={moment(values.sinceDate).startOf("month")}
                                    maxDate={moment(values.untilDate).endOf("month")}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            required={values.checkedPres}
                                            {...params}
                                            error={values.checkedPres && !values.startDatePres}
                                            onClick={(e) => { setIsErrorFocus(false) }}
                                        />
                                    )}
                                    // inputProps={{ readOnly: true }}
                                    label={t("reports.initDate")}
                                    views={["year", "month"]}
                                    inputProps={{
                                        onKeyDown: (event) => {
                                            event.preventDefault();
                                        },
                                    }}
                                    onClick={(e) => { setIsErrorFocus(false) }}
                                    inputRef={(input) => { (input && isErrorFocus && values.checkedPres && !values.startDatePres) && input.focus() }}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    disabled={!values.checkedPres}
                                    value={values.finishDatePres}
                                    onChange={(newValue) => handleChangeEndDateAdd(newValue, 1)}
                                    minDate={moment(values.sinceDate).startOf("month")}
                                    maxDate={moment(values.untilDate).endOf("month")}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            required={values.checkedPres}
                                            {...params}
                                            error={values.checkedPres && !values.finishDatePres}
                                            onClick={(e) => { setIsErrorFocus(false) }}
                                        />
                                    )}
                                    // inputProps={{ readOnly: true }}
                                    label={t("reports.endDate")}
                                    views={["year", "month"]}
                                    inputProps={{
                                        onKeyDown: (event) => {
                                            event.preventDefault();
                                        },
                                    }}
                                    onClick={(e) => { setIsErrorFocus(false) }}
                                    inputRef={(input) => { (input && isErrorFocus && values.checkedPres && !values.finishDatePres) && input.focus() }}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>

                                <LightTooltip title={t("services.investorReportsTool")}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name="checkedRep"
                                                checked={values.checkedRep}
                                                onChange={(value) => handleChangeCheck(value, 4)}
                                                inputProps={{
                                                    "aria-label": "controlled",
                                                }}
                                                disabled={!values.sinceDate || !values.untilDate}
                                            />
                                        }
                                        label={t("services.investorReports")}
                                    />
                                </LightTooltip>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    fullWidth
                                    disabled={!values.checkedRep}
                                    label={values.fixedHours ? t("miscellaneous.hours") : t("miscellaneous.hoursMonth")}
                                    name="tothRep"
                                    onChange={handleChange}
                                    value={values.tothRep}
                                    variant="outlined"
                                    type="number"
                                    inputProps={{
                                        min: 0,
                                        step: 0.5,
                                        onKeyDown: (event) => {
                                            event.preventDefault();
                                        },
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    error={values.checkedRep && values.tothRep === 0 && isError}
                                    onClick={(e) => { setIsErrorFocus(false) }}
                                    inputRef={(input) => { (input && isErrorFocus && values.checkedRep && values.tothRep === 0 && isError) && input.focus() }}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    disabled={!values.checkedRep}
                                    value={values.startDateRep}
                                    onChange={(newValue) => handleChangeStartDateAdd(newValue, 4)}
                                    minDate={moment(values.sinceDate).startOf("month")}
                                    maxDate={moment(values.untilDate).endOf("month")}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            required={values.checkedRep}
                                            {...params}
                                            error={values.checkedRep && !values.startDateRep}
                                            onClick={(e) => { setIsErrorFocus(false) }}
                                        />
                                    )}
                                    // inputProps={{ readOnly: true }}
                                    label={t("reports.initDate")}
                                    views={["year", "month"]}
                                    inputProps={{
                                        onKeyDown: (event) => {
                                            event.preventDefault();
                                        },
                                    }}
                                    onClick={(e) => { setIsErrorFocus(false) }}
                                    inputRef={(input) => { (input && isErrorFocus && values.checkedRep && !values.startDateRep) && input.focus() }}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    disabled={!values.checkedRep}
                                    value={values.finishDateRep}
                                    onChange={(newValue) => handleChangeEndDateAdd(newValue, 4)}
                                    minDate={moment(values.sinceDate).startOf("month")}
                                    maxDate={moment(values.untilDate).endOf("month")}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            required={values.checkedRep}
                                            {...params}
                                            error={values.checkedRep && !values.finishDateRep}
                                            onClick={(e) => { setIsErrorFocus(false) }}
                                        />
                                    )}
                                    // inputProps={{ readOnly: true }}
                                    label={t("reports.endDate")}
                                    views={["year", "month"]}
                                    inputProps={{
                                        onKeyDown: (event) => {
                                            event.preventDefault();
                                        },
                                    }}
                                    onClick={(e) => { setIsErrorFocus(false) }}
                                    inputRef={(input) => { (input && isErrorFocus && values.checkedRep && !values.finishDateRep) && input.focus() }}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <LightTooltip title={t("services.othersTool")}>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={12} md={3} xl={2} sx={{ paddingLeft: "5px !important" }}>
                                            <Checkbox
                                                name="checkedOthers"
                                                checked={values.checkedOthers}
                                                onChange={(value) => handleChangeCheck(value, 10)}
                                                inputProps={{
                                                    "aria-label": "controlled",
                                                }}
                                                disabled={!values.sinceDate}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={9} xl={10}>
                                            <TextField
                                                fullWidth
                                                disabled={!values.checkedOthers}
                                                label={t("services.others")}
                                                name="nameOthers"
                                                onChange={handleChange}
                                                value={values.nameOthers}
                                                variant="outlined"
                                                required={values.checkedOthers}
                                                error={values.checkedOthers && isError && values.nameOthers === ""}
                                                onClick={(e) => { setIsErrorFocus(false) }}
                                                inputRef={(input) => { (input && isErrorFocus && values.checkedOthers && isError && values.nameOthers === "") && input.focus() }}
                                            />
                                        </Grid>
                                    </Grid>
                                </LightTooltip>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    fullWidth
                                    disabled={!values.checkedOthers}
                                    label={values.fixedHours ? t("miscellaneous.hours") : t("miscellaneous.hoursMonth")}
                                    name="tothOthers"
                                    onChange={handleChange}
                                    value={values.tothOthers}
                                    variant="outlined"
                                    type="number"
                                    inputProps={{
                                        min: 0,
                                        step: 0.5,
                                        onKeyDown: (event) => {
                                            event.preventDefault();
                                        },
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    error={values.checkedOthers && values.tothOthers === 0 && isError}
                                    onClick={(e) => { setIsErrorFocus(false) }}
                                    inputRef={(input) => { (input && isErrorFocus && values.checkedOthers && values.tothOthers === 0 && isError) && input.focus() }}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    disabled={!values.checkedOthers}
                                    value={values.startDateOthers}
                                    onChange={(newValue) => handleChangeStartDateAdd(newValue, 10)}
                                    minDate={moment(values.sinceDate).startOf("month")}
                                    maxDate={moment(values.untilDate).endOf("month")}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            required={values.checkedOthers}
                                            {...params}
                                            error={values.checkedOthers && !values.startDateOthers}
                                            onClick={(e) => { setIsErrorFocus(false) }}
                                        />
                                    )}
                                    // inputProps={{ readOnly: true }}
                                    label={t("reports.initDate")}
                                    views={["year", "month"]}
                                    inputProps={{
                                        onKeyDown: (event) => {
                                            event.preventDefault();
                                        },
                                    }}
                                    onClick={(e) => { setIsErrorFocus(false) }}
                                    inputRef={(input) => { (input && isErrorFocus && values.checkedOthers && !values.startDateOthers) && input.focus() }}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    disabled={!values.checkedOthers}
                                    value={values.finishDateOthers}
                                    onChange={(newValue) => handleChangeEndDateAdd(newValue, 10)}
                                    minDate={moment(values.sinceDate).startOf("month")}
                                    maxDate={moment(values.untilDate).endOf("month")}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            required={values.checkedOthers}
                                            {...params}
                                            error={values.checkedOthers && !values.finishDateOthers}
                                            onClick={(e) => { setIsErrorFocus(false) }}
                                        />
                                    )}
                                    // inputProps={{ readOnly: true }}
                                    label={t("reports.endDate")}
                                    views={["year", "month"]}
                                    inputProps={{
                                        onKeyDown: (event) => {
                                            event.preventDefault();
                                        },
                                    }}
                                    onClick={(e) => { setIsErrorFocus(false) }}
                                    inputRef={(input) => { (input && isErrorFocus && values.checkedOthers && !values.finishDateOthers) && input.focus() }}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}></Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    fullWidth
                                    disabled
                                    label={t("services.hoursTotal")}
                                    value={sumTotal}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormControl fullWidth variant="outlined" disabled>
                                    <InputLabel htmlFor="outlined-adornment-hourlyRate">
                                        {t("services.hourlyRate")}
                                    </InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-hourlyRate"
                                        value={values.hourRate}
                                        inputComponent={NumberFormatCustom}
                                        startAdornment={
                                            <InputAdornment disableTypography position="start">
                                                $
                                            </InputAdornment>
                                        }
                                        label={t("services.hourlyRate")}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormControl fullWidth variant="outlined" disabled>
                                    <InputLabel htmlFor="outlined-adornment-hourlyRateAdd">
                                        {t("services.hourlyRateAdd")}
                                    </InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-hourlyRateAdd"
                                        value={values.hourRate * sumTotal}
                                        inputComponent={NumberFormatCustom}
                                        startAdornment={
                                            <InputAdornment disableTypography position="start">
                                                $
                                            </InputAdornment>
                                        }
                                        label={t("services.hourlyRateAdd")}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </LocalizationProvider>
        </StyledContainer>
    );
};
