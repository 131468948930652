/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

/** MUI imports */
import { styled } from "@mui/material/styles";
import { Box, Chip, Grid, Typography } from "@mui/material";

/** Components */
import { Onboarding, CatchUp, Recurring, Consulting } from "./";

/** Component styles */
const PREFIX = "PropControllerDetails";

const classes = {
    cardContentRoot: `${PREFIX}-cardContentRoot`,
    chip: `${PREFIX}-chip`,
    selectedChip: `${PREFIX}-selectedChip`,
    projectBox: `${PREFIX}-projectBox`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.cardContentRoot}`]: {
        padding: "24px 48px 48px",
    },

    [`& .${classes.chip}`]: {
        padding: 24,
        width: 200,
        backgroundColor: "#EAEAF4",
        color: "#031851",
        fontWeight: "700 !important",
        "&:hover": {
            backgroundColor: "#EAEAF4",
            color: "#031851",
        },
    },

    [`& .${classes.selectedChip}`]: {
        padding: 24,
        width: 200,
        backgroundColor: "#2f3190",
        color: "#fff",
        fontWeight: "700 !important",
        "&:hover": {
            backgroundColor: "#2f3190",
            color: "#fff",
        },
    },

    [`& .${classes.projectBox}`]: {
        backgroundColor: "#F8F9F8",
        marginTop: 16,
        padding: 30,
        borderRadius: 30,
    },
}));

/** Global variables */
const latam = [
    "Argentina",
    "Bolivia",
    "Brasil",
    "Chile",
    "Colombia",
    "Costa Rica",
    "Cuba",
    "Ecuador",
    "El Salvador",
    "Guatemala",
    "Haití",
    "Honduras",
    "México",
    "Nicaragua",
    "Panamá",
    "Paraguay",
    "Perú",
    "República Dominicana",
    "Uruguay",
];

export const PropControllerDetails = (props) => {
    // Properties
    const {
        pricingModel,
        services,
        setAutoAlertType,
        setShow,
        setTexto,
        setServices,
        setValuesContCatchUp,
        setValuesContConsul,
        setValuesContOnboard,
        setValuesContRecu,
        valuesContCatchUp,
        valuesContConsul,
        valuesContOnboard,
        valuesContRecu,
        isError,
        isErrorFocus,
        setIsErrorFocus,
        clientMarket,
        showtutorial,
        showFinalTutorial,
    } = props;

    /** Internationalization i18n */
    const [t] = useTranslation("global");
    

    /** Component functions */
    useEffect(() => {
        if (!valuesContRecu.chip && !valuesContOnboard.chip && !valuesContCatchUp.chip && !valuesContConsul.chip) {
            const updateServices = services.map((service) => {
                if (service.name === "controller") {
                    return {
                        ...service,
                        isActive: false,
                    };
                } else {
                    return {
                        ...service,
                    };
                }
            });
            setServices(updateServices);
        } else {
            const updateServices = services.map((service) => {
                if (service.name === "controller") {
                    return {
                        ...service,
                        isActive: true,
                    };
                } else {
                    return {
                        ...service,
                    };
                }
            });
            setServices(updateServices);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [valuesContRecu.chip, valuesContOnboard.chip, valuesContCatchUp.chip, valuesContConsul.chip]);

    useEffect(() => {
        pricingModel.forEach((element) => {
            if (element.service_name === "Controller") {
                element.projects.forEach((item) => {
                    if (item.project_name === "Onboarding") {
                        setValuesContOnboard({
                            ...valuesContOnboard,
                            onboardOriginalFee: item.fee,
                            onboardProjectTotal: !valuesContOnboard.chip
                                ? 0
                                : item.fee * (1 - valuesContOnboard.onboardDiscount / 100),
                        });
                    }
                    if (item.project_name === "Catchup") {
                        setValuesContCatchUp({
                            ...valuesContCatchUp,
                            catchUpOriginalFee: item.fee,
                            projectTotal: !valuesContCatchUp.chip
                                ? 0
                                : valuesContCatchUp.additionalsOnly
                                ? valuesContCatchUp.projectTotal
                                : item.fee * valuesContCatchUp.months * (1 - valuesContCatchUp.discount / 100),
                            hourRate: item.additional_fee,
                        });
                    }
                    if (item.project_name === "Recurring") {
                        setValuesContRecu({
                            ...valuesContRecu,
                            recuOriginalFee: item.fee,
                            recuBaseRate: !valuesContRecu.chip
                                ? 0
                                : valuesContRecu.recuAdditionalsOnly
                                ? valuesContRecu.recuProjectTotal
                                : valuesContRecu.payAnnually
                                ? item.fee * 12 * (1 - valuesContRecu.recuDiscount / 100)
                                : item.fee * (1 - valuesContRecu.recuDiscount / 100),
                            recuProjectTotal: !valuesContRecu.chip
                                ? 0
                                : valuesContRecu.recuAdditionalsOnly
                                ? valuesContRecu.recuProjectTotal
                                : valuesContRecu.payAnnually
                                ? item.fee * 12 * (1 - valuesContRecu.recuDiscount / 100)
                                : item.fee * (1 - valuesContRecu.recuDiscount / 100),
                            recuHourRate: item.additional_fee,
                        });
                    }
                    if (item.project_name === "Consulting") {
                        let aux = [...valuesContConsul.consultingArray];

                        aux.forEach((element) => {
                            element.total = element.hours * item.fee * (1 - element.discount / 100);
                        });

                        let sumHours = aux.map((obj) => obj.hours).reduce((a, b) => parseInt(a) + parseInt(b));
                        let sumTotal = aux.map((obj) => obj.total).reduce((a, b) => parseFloat(a) + parseFloat(b));

                        setValuesContConsul({
                            ...valuesContConsul,
                            consulOriginalFee: item.fee,
                            consulProjectTotal: sumTotal,
                            consulHours: sumHours,
                            consultingArray: aux,
                        });
                    }
                });
            }
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pricingModel, valuesContCatchUp.totalHours, valuesContRecu.recuHourRate]);

    const handleClickChip = (value, option) => {
        if (option === "onboarding") {
            setValuesContOnboard({
                ...valuesContOnboard,
                chip: !value,
                onboardOriginalFee: valuesContOnboard.onboardOriginalFee,
                onboardDiscount: 0,
                onboardProjectTotal: !value ? valuesContOnboard.onboardOriginalFee : 0,
                meetingDate: null,
                description: ""
            });
        } else if (option === "recurring") {
            setValuesContRecu({
                ...valuesContRecu,
                chip: !value,
                recuOriginalFee: valuesContRecu.recuOriginalFee,
                recuBaseRate: !value ? valuesContRecu.recuOriginalFee : 0,
                recuDiscount: 0,
                recuProjectTotal: !value ? valuesContRecu.recuOriginalFee : 0,
                recuAdditionalsDiscount: 0,
                payAnnually: false,
                recuAdditionalsOnly: false,
                initDate: null,
                recuHourRate: 0,
                recuChecks: false,
                recuHours: 0,
                // Additionals
                checkedCont: false,
                tothCont: 0,
                startDateCont: null,
                finishDateCont: null,
                checkedExe: false,
                tothExe: 0,
                startDateExe: null,
                finishDateExe: null,
                checkedCons: false,
                tothCons: 0,
                startDateCons: null,
                finishDateCons: null,
                checkedRep: false,
                tothRep: 0,
                startDateRep: null,
                finishDateRep: null,
                // Others
                checkedOthers: false,
                nameOthers: "",
                tothOthers: 0,
                startDateOthers: null,
                finishDateOthers: null,
            });
        } else if (option === "catchUp") {
            setValuesContCatchUp({
                ...valuesContCatchUp,
                chip: !value,
                catchUpOriginalFee: valuesContCatchUp.catchUpOriginalFee,
                sinceDate: null,
                untilDate: null,
                discount: 10,
                catchUpAdditionalsDiscount: 10,
                additionalsOnly: false,
                projectTotal: !value
                    ? valuesContCatchUp.catchUpOriginalFee * (1 - valuesContCatchUp.discount / 100)
                    : 0,
                hourRate: 0,
                catchUpChecks: false,
                totalHours: 0,
                months: 1,
                // Additionals
                checkedCont: false,
                tothCont: 0,
                startDateCont: null,
                finishDateCont: null,
                checkedExe: false,
                tothExe: 0,
                startDateExe: null,
                finishDateExe: null,
                checkedCons: false,
                tothCons: 0,
                startDateCons: null,
                finishDateCons: null,
                checkedRep: false,
                tothRep: 0,
                startDateRep: null,
                finishDateRep: null,
                // Others
                checkedOthers: false,
                nameOthers: "",
                tothOthers: 0,
                startDateOthers: null,
                finishDateOthers: null,
            });
        } else {
            setValuesContConsul({
                ...valuesContConsul,
                chip: !value,
                consulOriginalFee: valuesContConsul.consulOriginalFee,
                consulProjectTotal: 0,
                consulHours: 0,
                consultingArray: [
                    {
                        hours: 0,
                        discount: 0,
                        description: "",
                        total: 0,
                    },
                ],
            });
        }
        if (showtutorial && !value) {
            setTimeout(() => showFinalTutorial("projectsCont"), 500);
        }
    };

    return (
        <StyledContainer>
            <Grid id="controller" container justifyContent="space-around" alignItems="center" spacing={2}>
                <Grid item>
                    <Chip
                        id="onboarding-chip"
                        label={
                            <Typography variant="h2" align="center" sx={{ fontWeight: 700 }}>
                                {t(`invoicing.onboarding`)}
                            </Typography>
                        }
                        className={valuesContOnboard.chip ? classes.selectedChip : classes.chip}
                        onClick={() => handleClickChip(valuesContOnboard.chip, "onboarding")}
                    />
                </Grid>
                <Grid item>
                    <Chip
                        id="catch-up-chip"
                        label={
                            <Typography variant="h2" align="center" sx={{ fontWeight: 700 }}>
                                {t("invoicing.catchUp")}
                            </Typography>
                        }
                        className={valuesContCatchUp.chip ? classes.selectedChip : classes.chip}
                        onClick={() => handleClickChip(valuesContCatchUp.chip, "catchUp")}
                    />
                </Grid>
                <Grid item>
                    <Chip
                        id="recurring-chip"
                        label={
                            <Typography variant="h2" align="center" sx={{ fontWeight: 700 }}>
                                {t("invoicing.recurring")}
                            </Typography>
                        }
                        className={valuesContRecu.chip ? classes.selectedChip : classes.chip}
                        onClick={() => handleClickChip(valuesContRecu.chip, "recurring")}
                    />
                </Grid>
                <Grid item>
                    <Chip
                        id="consulting-chip"
                        label={
                            <Typography variant="h2" align="center" sx={{ fontWeight: 700 }}>
                                {t("invoicing.consulting")}
                            </Typography>
                        }
                        className={valuesContConsul.chip ? classes.selectedChip : classes.chip}
                        onClick={() => handleClickChip(valuesContConsul.chip, "accountConsul")}
                    />
                </Grid>
            </Grid>
            <Box id="projectsCont">
                {valuesContOnboard.chip && (
                    <Onboarding
                        valuesContOnboard={valuesContOnboard}
                        setValuesContOnboard={setValuesContOnboard}
                        isError={isError}
                        isErrorFocus={isErrorFocus}
                        setIsErrorFocus={setIsErrorFocus}
                    />
                )}
                {valuesContCatchUp.chip && (
                    <CatchUp
                        clientMarket={clientMarket}
                        values={valuesContCatchUp}
                        setValues={setValuesContCatchUp}
                        setShow={setShow}
                        setTexto={setTexto}
                        setAutoAlertType={setAutoAlertType}
                        isError={isError}
                        latam={latam}
                        isErrorFocus={isErrorFocus}
                        setIsErrorFocus={setIsErrorFocus}
                    />
                )}
                {valuesContRecu.chip && (
                    <Recurring
                        clientMarket={clientMarket}
                        valuesContRecu={valuesContRecu}
                        setValuesContRecu={setValuesContRecu}
                        setShow={setShow}
                        setTexto={setTexto}
                        setAutoAlertType={setAutoAlertType}
                        latam={latam}
                        isError={isError}
                        isErrorFocus={isErrorFocus}
                        setIsErrorFocus={setIsErrorFocus}
                    />
                )}
                {valuesContConsul.chip && (
                    <Consulting
                        clientMarket={clientMarket}
                        valuesContConsul={valuesContConsul}
                        setValuesContConsul={setValuesContConsul}
                        setShow={setShow}
                        setTexto={setTexto}
                        setAutoAlertType={setAutoAlertType}
                        isError={isError}
                        isErrorFocus={isErrorFocus}
                        setIsErrorFocus={setIsErrorFocus}
                    />
                )}
            </Box>
        </StyledContainer>
    );
};
