/** React imports */
import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";

/* MUI Imports */
import {
    Grid,
    Dialog,
    DialogContent,
    IconButton,
    Typography,
    Divider,
    TextField,
    Button,
    FormControl,
    InputLabel,
    InputAdornment,
    OutlinedInput
} from "@mui/material";
import GridUI from "@mui/material/Grid";

/* MUI Icons */
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

export default function UserDataContent({ userName, userEmail, role, position, setTexto, setAlertType, setShow, setBackDrop, usersAvatar }) {
    /* Internationalization i18n */
    const [t] = useTranslation("global");

    /** Component states */
    const [showChangePassword, setShowChangePassword] = useState(false);
    const [valuesChange, setValuesChange] = useState({
        curPass: "",
        newPass: "",
        showNewPass: false,
        confirmPass: "",
        showConfirmPass: false,
        showCurPass: false
    });
    const [isError, setIsError] = useState(false);
    // const [selectedImage, setSelectedImage] = useState(null);
    const fileInputRef = useRef(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    /* Component functions */
    const handleCancelChangePassword = () => {
        setValuesChange({
            curPass: "",
            newPass: "",
            showNewPass: false,
            confirmPass: "",
            showConfirmPass: false,
            showCurPass: false
        });
        setTexto("");
        setAlertType("");
        setShow(false);
        setIsError(false);
        setShowChangePassword(false);
    };

    const handleImageClick = () => {
        setIsDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
    };

    const handleReset = () => {
        setValuesChange({
            curPass: "",
            newPass: "",
            showNewPass: false,
            confirmPass: "",
            showConfirmPass: false,
            showCurPass: false
        });
        setIsError(false);
        setShowChangePassword(false);
    };

    const handleChange = (event) => {
        setValuesChange({
            ...valuesChange,
            [event.target.name]: event.target.value
        });
    };

    const handleClickShowNewPass = () => {
        setValuesChange({
            ...valuesChange,
            showNewPass: !valuesChange.showNewPass,
        });
    };

    const handleClickShowCurPass = () => {
        setValuesChange({
            ...valuesChange,
            showCurPass: !valuesChange.showCurPass,
        });
    };


    const handleClickShowConfirmPass = () => {
        setValuesChange({
            ...valuesChange,
            showConfirmPass: !valuesChange.showConfirmPass,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleKeyDownChangePass = (event) => {
        if (event.key === "Enter") {
            document.getElementById("changePassBtn").click();
        }
    };

    const updatePassword = async () => {
        setBackDrop(true);

        if (valuesChange.curPass !== "" && valuesChange.newPass !== "" && valuesChange.confirmPass !== "") {
            const paramsComp = {
                user_email: userEmail,
                current_password: valuesChange.curPass,
            };

            await fetch(`/comparePass`, {
                method: "POST",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
                body: JSON.stringify(paramsComp),
            })
                .then((res) => res.json())
                .then(async (response) => {
                    if (response.valid) {
                        if (valuesChange.newPass === valuesChange.confirmPass) {
                            const params = {
                                user_email: userEmail,
                                new_password: valuesChange.newPass,
                            };

                            await fetch(`/updatePassword`, {
                                method: "POST",
                                headers: {
                                    "Content-type": "application/json; charset=UTF-8",
                                    "Access-Control-Allow-Origin": "*",
                                },
                                body: JSON.stringify(params),
                            })
                                .then((res) => {
                                    if (res.ok) {
                                        setTexto(t("login.changePassSuccess"));
                                        setAlertType("success");
                                        setShow(true);
                                        handleReset();
                                    }
                                    else {
                                        setTexto(t("login.changePassError"));
                                        setAlertType("error");
                                        setShow(true);
                                    }
                                })
                                .catch((error) => {
                                    setTexto(t("login.changePassError"));
                                    setAlertType("error");
                                    setShow(true);
                                });
                        } else {
                            setTexto(t("login.noConfirmationPass"));
                            setAlertType("error");
                            setShow(true);
                            setIsError(true);
                        }
                    } else {
                        setTexto(t("login.currentPassIncorrect"));
                        setAlertType("error");
                        setShow(true);
                    }
                })
                .catch((error) => {
                    setTexto(t("login.comparePassError"));
                    setAlertType("error");
                    setShow(true);
                })
        }
        else {
            setTexto(t("miscellaneous.requiredData"));
            setAlertType("warning");
            setShow(true);
            setIsError(true);
        }

        setBackDrop(false);
    };

    const handleCameraClick = () => {
        fileInputRef.current.click();
    };

    // TODO: Agregar petición para subir las imagenes a S3.
    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            const fileSize = selectedFile.size / 1024 / 1024;

            if (fileSize <= 5) {
                // setSelectedImage(selectedFile);
            } else {
                console.log("Supera máximo de 5mb permitido");
            };
        };
    };

    return (
        <>
            <Grid container spacing={0}>
                <Grid sx={{ mt: 3 }}>
                    {usersAvatar[userEmail.split("@")[0]] ? (
                        <img
                            src={usersAvatar[userEmail.split("@")[0]]}
                            alt="UserAvatar"
                            style={{ ml: 0, borderRadius: 50, height: 100, width: 100, border: "3.5px solid #2f3190", cursor: "pointer" }}
                            onClick={handleImageClick}
                        />
                    ) : (
                        <AccountCircleIcon sx={{ width: 100, height: 100, ml: 0, cursor: "pointer" }} onClick={handleImageClick} />
                    )}
                    <Dialog open={isDialogOpen} onClose={handleCloseDialog} >
                        <DialogContent sx={{ p: 0 }}>
                            <div
                                style={{
                                    backgroundColor: "rgba(255, 255, 255, 0.8)",
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                {usersAvatar[userEmail.split("@")[0]] ? (
                                    <img
                                        src={usersAvatar[userEmail.split("@")[0]]}
                                        alt="UserAvatar"
                                        style={{ maxWidth: "100%", maxHeight: "100%", margin: "auto" }}
                                    />
                                ) : (
                                    <AccountCircleIcon sx={{ width: "100%", height: "100" }} />
                                )}
                            </div>
                        </DialogContent>
                    </Dialog>
                    <PhotoCameraIcon
                        sx={{
                            width: 25,
                            height: 25,
                            position: "relative",
                            bottom: 1,
                            right: 30,
                            backgroundColor: "#2f3190",
                            color: "#ffffff",
                            borderRadius: 50,
                            border: "3.5px solid #2f3190",
                        }}
                        onClick={handleCameraClick}
                    />
                    <input
                        ref={fileInputRef}
                        type="file"
                        style={{ display: "none" }}
                        onChange={handleFileChange}
                        accept="image/jpeg"
                    />
                </Grid>
                <Grid sx={{ pt: 5.5 }}>
                    <Typography variant="body2" sx={{ fontWeight: "bold", fontSize: "17px" }}>
                        {position}
                    </Typography>
                    <Typography variant="body2" sx={{ fontSize: "14px" }}>{role}</Typography>
                    <Typography variant="caption" sx={{ fontWeight: 600, fontSize: "11px" }}>{t("principal.dateOfAdmission")}</Typography>
                </Grid>
            </Grid>

            <Divider sx={{ mt: 2 }} />

            <Grid container spacing={2} mt={3}>
                <GridUI item xs={6}>
                    <TextField
                        name="names"
                        type="text"
                        fullWidth
                        label={`${t("principal.names")}`}
                        value={userName}
                        variant="outlined"
                        disabled
                    />
                </GridUI>
                <GridUI item xs={6}>
                    <TextField
                        name="surnames"
                        type="text"
                        fullWidth
                        readOnly
                        label={`${t("principal.surnames")}`}
                        variant="outlined"
                        disabled
                    />
                </GridUI>
                <GridUI item xs={6}>
                    <TextField
                        name="city"
                        type="text"
                        fullWidth
                        readOnly
                        label={`${t("principal.country")}`}
                        disabled
                    />
                </GridUI>
                <GridUI item xs={6}>
                    <TextField
                        name="email"
                        type="text"
                        fullWidth
                        readOnly
                        label={`${t("principal.email")}`}
                        value={userEmail}
                        disabled
                        variant="outlined"
                    />
                </GridUI>
            </Grid>

            <Grid mt={showChangePassword ? 1 : 3} mb={showChangePassword ? 0 : 3}>
                <Typography
                    variant="body2"
                    sx={{ textDecoration: "underline", cursor: showChangePassword ? "default" : "pointer", fontSize: "11px", color: "#FF5968", textAlign: "left", top: 4 }}
                    onClick={(() => setShowChangePassword(true))}
                >
                    {t("principal.changePass")}
                </Typography>
            </Grid>

            {showChangePassword &&
                <Grid>
                    <Grid item xs={1} mb={1.5} mt={1.5}>
                        <Divider />
                    </Grid>
                    <Typography variant="body2" color="primary" align="start" sx={{ mb: 3, fontSize: 14, fontWeight: "bold" }}>{t('principal.changePass')}</Typography>

                    <form>
                        <Grid container spacing={2} xs={6}>
                            <Grid item xs={12}>
                                <FormControl fullWidth required variant="outlined">
                                    <InputLabel
                                        htmlFor="outlined-adornment-current-password"
                                        error={(isError && valuesChange.curPass === "") ? true : false}
                                    >
                                        {t("login.currentPass")}
                                    </InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-current-password"
                                        fullWidth
                                        type={valuesChange.showCurPass ? "text" : "password"}
                                        label={t("login.currentPass")}
                                        name="curPass"
                                        onChange={handleChange}
                                        value={valuesChange.curPass}
                                        error={(isError && valuesChange.curPass === "") ? true : false}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle current password visibility"
                                                    onClick={handleClickShowCurPass}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {valuesChange.showCurPass ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl fullWidth required variant="outlined">
                                    <InputLabel
                                        htmlFor="outlined-adornment-new-password"
                                        error={(isError && valuesChange.newPass === "") ? true : false}
                                    >
                                        {t("login.newPass")}
                                    </InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-new-password"
                                        fullWidth
                                        type={valuesChange.showNewPass ? "text" : "password"}
                                        label={t("login.newPass")}
                                        name="newPass"
                                        onChange={handleChange}
                                        value={valuesChange.newPass}
                                        error={(isError && valuesChange.newPass === "") ? true : false}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle new password visibility"
                                                    onClick={handleClickShowNewPass}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {valuesChange.showNewPass ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl fullWidth required variant="outlined">
                                    <InputLabel
                                        htmlFor="outlined-adornment-confirm-password"
                                        error={(isError && valuesChange.confirmPass === "") ? true : false}
                                    >
                                        {t("login.confirmNewPass")}
                                    </InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-confirm-password"
                                        fullWidth
                                        type={valuesChange.showConfirmPass ? "text" : "password"}
                                        label={t("login.confirmNewPass")}
                                        name="confirmPass"
                                        onChange={handleChange}
                                        value={valuesChange.confirmPass}
                                        error={(isError && valuesChange.confirmPass === "") ? true : false}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle confirm password visibility"
                                                    onClick={handleClickShowConfirmPass}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {valuesChange.showConfirmPass ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        onKeyDown={handleKeyDownChangePass}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container justifyContent="space-between">
                                    <Grid item>
                                        <Button
                                            fullWidth
                                            disableElevation
                                            id="cancelChangePassBtn"
                                            variant="outlined"
                                            color="secondary"
                                            sx={{
                                                backgroundColor: "white",
                                                color: "#2f3190",
                                                border: "1px solid #2f3190",
                                                borderRadius: 50,
                                                mb: 3,
                                                "&:hover": {
                                                    backgroundColor: "white",
                                                    color: "#2f3190",
                                                    border: "1px solid #2f3190",
                                                },
                                            }}
                                            onClick={() => handleCancelChangePassword()}
                                        >
                                            {t("principal.cancel")}
                                        </Button>
                                    </Grid>

                                    <Grid item>
                                        <Button
                                            fullWidth
                                            disableElevation
                                            id="changePassBtn"
                                            variant="contained"
                                            color="secondary"
                                            sx={{
                                                borderRadius: 50,
                                                "&:hover": {
                                                    color: "#fff",
                                                    backgroundColor: "#2F3190",
                                                },
                                                mb: 3,
                                            }}
                                            onClick={updatePassword}
                                        >
                                            {t("principal.save")}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
            }
        </>
    )
};
