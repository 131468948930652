// React
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { NumericFormat } from "react-number-format";

// MUI
import {
    Alert,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    FormControl,
    Grid,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

const PREFIX = "Onboarding";

const classes = {
    btnRoot: `${PREFIX}-btnRoot`,
    buttonContainer: `${PREFIX}-buttonContainer`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.btnRoot}`]: {
        border: "1px solid #ff5968",
        borderRadius: 40,
        "&:hover": {
            backgroundColor: "#2f3190",
            border: "1px solid #2f3190",
        },
        "&.MuiButton-root.Mui-disabled": {
            border: "1px solid #ffcdd2",
            backgroundColor: "#ffcdd2 !important",
            color: "#fff",
        },
    },
    [`& .${classes.buttonContainer}`]: {
        display: "flex",
        justifyContent: "flex-end",
        padding: 15,
    },
}));

const NumberFormatCustom = (props) => {
    const todos = useSelector((state) => state.todos);

    const { inputRef, value, onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            value={value}
            getInputRef={inputRef}
            onValueChange={({ value: v }) => onChange({ target: { value: v } })}
            thousandSeparator={todos.amountFormat === "de-DE" ? "." : ","}
            decimalSeparator={todos.amountFormat === "de-DE" ? "," : "."}
            fixedDecimalScale={true}
            decimalScale={2}
            valueIsNumericString
        />
    );
};

const defaultState = {
    initDate: null,
    meetingDate: null,
    fee: 0,
    discount: 0,
    totalFee: 0,
    description: "",
};

export const Onboarding = (props) => {
    // Parámetros
    const {
        actives,
        companyID,
        data,
        reloadProjects,
        serviceID,
        setalertType,
        setBackDrop,
        setShow,
        setTexto,
        accountSystem,
        expenseEst,
    } = props;

    /** Internationalization i18n */
    const [t] = useTranslation("global");

    /** React redux */
    const todos = useSelector((state) => state.todos);

    /** Component states */
    const [onboard, setOnboard] = useState(defaultState);
    const [isError, setIsError] = useState(false);

    /** Component functions */
    useEffect(() => {
        setOnboard({
            billed: data.billed,
            initDate: data.since_date ? moment(data.since_date).utc() : moment().utc(),
            meetingDate: data.end_date ? moment(data.end_date).utc() : null,
            fee: data.fee,
            discount: data.discount ?? 0,
            totalFee: data.fee * (1 - (data.discount ?? 0) / 100),
            description: data.description ?? "",
        });
    }, [data]);

    const handleChange = (event) => {
        setOnboard({
            ...onboard,
            [event.target.name]: event.target.value,
        });
    };

    const handleChangeDiscount = (event) => {
        let discountAux = event.target.value >= 0 && event.target.value <= 100 ? event.target.value : onboard.discount;
        setOnboard({
            ...onboard,
            discount: discountAux,
            totalFee: onboard.fee * (1 - discountAux / 100),
        });
    };

    const updateProject = async () => {
        setBackDrop(true);

        if (onboard.meetingDate) {
            let params = {
                company_id: companyID,
                service_id: serviceID,
                project_id: data.project_id,
                project_company_id: data.project_company_id,
                total_fee: onboard.totalFee,
                since_date: onboard.initDate.startOf("day").format(),
                end_date: onboard.meetingDate.startOf("day").format(),
                discount: onboard.discount ? parseFloat(onboard.discount) : 0,
                fee: onboard.fee,
                description: onboard.description,
            };

            await fetch(`/addServicesAndProjects`, {
                method: "POST",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
                body: JSON.stringify(params),
            })
                .then((response) => {
                    if (response.ok) {
                        setTexto(t("miscellaneous.successfulUpdate"));
                        setalertType("success");
                        setShow(true);
                        reloadProjects(true);
                    } else {
                        setTexto(t("miscellaneous.updatingError"));
                        setalertType("error");
                        setShow(true);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            setTexto(t("miscellaneous.requiredData"));
            setalertType("warning");
            setShow(true);
            setIsError(true);
        }

        setBackDrop(false);
    };

    const handleChangeMeetingDate = (date) => {
        if (!date || !onboard.initDate) {
            setOnboard({
                ...onboard,
                meetingDate: date,
            });
        } else {
            if (date.isBefore(onboard.initDate.format("YYYY-MM-DD"))) {
                setOnboard({
                    ...onboard,
                    meetingDate: null,
                });

                setTexto(t("reports.meetingDateMess"));
                setalertType("warning");
                setShow(true);
            } else {
                setOnboard({
                    ...onboard,
                    meetingDate: date,
                });
            }
        }
    };

    return (
        <StyledContainer>
            <LocalizationProvider
                dateAdapter={AdapterMoment}
                adapterLocale={t("language.locale")}
                localeText={{ clearButtonLabel: t("miscellaneous.clear") }}
            >
                <Card elevation={0}>
                    <CardHeader title="Accountant onboarding" />
                    <Divider />
                    <CardContent>
                        {actives.includes("Bookkeeper") && (
                            <Grid container spacing={2} justifyContent="center" sx={{ paddingBottom: 3 }}>
                                <Grid item>
                                    <Alert
                                        severity="warning"
                                        sx={{
                                            borderRadius: 8,
                                            fontSize: 12,
                                            fontWeight: 600,
                                        }}
                                    >
                                        {t("services.notPosibleContractAcc")}
                                    </Alert>
                                </Grid>
                            </Grid>
                        )}
                        {!actives.includes("Bookkeeper") &&
                            accountSystem.disabledData === true &&
                            (accountSystem.prov === "Xero" || accountSystem.prov === "Quickbooks Online") && (
                                <Grid container spacing={2} justifyContent="center" sx={{ paddingBottom: 3 }}>
                                    <Grid item>
                                        <Alert
                                            severity="warning"
                                            sx={{
                                                borderRadius: 8,
                                                fontSize: 12,
                                                fontWeight: 600,
                                            }}
                                        >
                                            {t("services.enterValidCredentials")}
                                        </Alert>
                                    </Grid>
                                </Grid>
                            )}
                        {!actives.includes("Bookkeeper") && expenseEst === -1 && (
                            <Grid container spacing={2} justifyContent="center" sx={{ paddingBottom: 3 }}>
                                <Grid item>
                                    <Alert
                                        severity="warning"
                                        sx={{
                                            borderRadius: 8,
                                            fontSize: 12,
                                            fontWeight: 600,
                                        }}
                                    >
                                        {t("services.ableToSaveInfo")}
                                    </Alert>
                                </Grid>
                            </Grid>
                        )}
                        <Grid container alignItems="flex-start" spacing={2}>
                            <Grid item xs={12} sm={6} lg={3}>
                                <DatePicker
                                    disabled
                                    value={onboard.initDate}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => <TextField fullWidth required {...params} />}
                                    label={t("reports.initDate")}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} lg={3}>
                                <FormControl
                                    fullWidth
                                    variant="outlined"
                                    disabled
                                >
                                    <InputLabel htmlFor="outlined-adornment-fee">{t("services.projectFee")}</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-fee"
                                        value={onboard.fee}
                                        inputComponent={NumberFormatCustom}
                                        startAdornment={
                                            <InputAdornment disableTypography position="start">
                                                $
                                            </InputAdornment>
                                        }
                                        label={t("services.projectFee")}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={3}>
                                <TextField
                                    fullWidth
                                    label={t("miscellaneous.discount")}
                                    value={onboard.discount}
                                    disabled={
                                        onboard.billed ||
                                        (accountSystem.disabledData === true &&
                                            (accountSystem.prov === "Xero" ||
                                                accountSystem.prov === "Quickbooks Online")) ||
                                        expenseEst === -1
                                    }
                                    name="discount"
                                    onChange={handleChangeDiscount}
                                    onBlur={() =>
                                        setOnboard({
                                            ...onboard,
                                            discount: parseFloat(
                                                onboard.discount === "" ? 0 : onboard.discount
                                            ).toFixed(2),
                                        })
                                    }
                                    variant="outlined"
                                    type="number"
                                    InputProps={{
                                        inputProps: {
                                            min: 0,
                                            max: 100,
                                        },
                                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                    }}
                                    sx={{
                                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                            display: "none",
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} lg={3}>
                                <FormControl
                                    fullWidth
                                    variant="outlined"
                                    disabled
                                >
                                    <InputLabel htmlFor="outlined-adornment-totalFee">
                                        {t("proposals.feeTotal")}
                                    </InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-totalFee"
                                        value={onboard.totalFee}
                                        inputComponent={NumberFormatCustom}
                                        startAdornment={
                                            <InputAdornment disableTypography position="start">
                                                $
                                            </InputAdornment>
                                        }
                                        label={t("proposals.feeTotal")}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    value={onboard.meetingDate}
                                    onChange={(newValue) => handleChangeMeetingDate(newValue)}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            required
                                            {...params}
                                            error={isError && !onboard.meetingDate}
                                        />
                                    )}
                                    inputProps={{ readOnly: true }}
                                    label={t("services.meetingDate")}
                                    disabled={
                                        (accountSystem.disabledData === true &&
                                            (accountSystem.prov === "Xero" ||
                                                accountSystem.prov === "Quickbooks Online")) ||
                                        expenseEst === -1
                                    }
                                    views={["year", "month", "day"]}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} lg={6}>
                                <TextField
                                    fullWidth
                                    label={t("miscellaneous.comments")}
                                    value={onboard.description}
                                    name="description"
                                    onChange={handleChange}
                                    variant="outlined"
                                    inputProps={{ maxLength: 100 }}
                                    disabled={
                                        (accountSystem.disabledData === true &&
                                            (accountSystem.prov === "Xero" ||
                                                accountSystem.prov === "Quickbooks Online")) ||
                                        expenseEst === -1
                                    }
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                    <Divider />
                    <Box className={classes.buttonContainer}>
                        <Button
                            color="secondary"
                            variant="contained"
                            disableElevation
                            classes={{ root: classes.btnRoot }}
                            onClick={updateProject}
                            disabled={
                                actives.includes("Bookkeeper") ||
                                (accountSystem.disabledData === true &&
                                    (accountSystem.prov === "Xero" || accountSystem.prov === "Quickbooks Online")) ||
                                expenseEst === -1
                            }
                        >
                            {t("miscellaneous.save")}
                        </Button>
                    </Box>
                </Card>
            </LocalizationProvider>
        </StyledContainer>
    );
};
