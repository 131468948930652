/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import axios from "axios";

/** Icons */
import { ReactComponent as GCAAutomate } from '../../../assets/images/gca-automate-logo.svg';
import { ReactComponent as TranslateIcon } from '../../../assets/images/translate-icon.svg';

/** MUI icons */
import CommentIcon from '@mui/icons-material/Comment';
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import UploadFileIcon from '@mui/icons-material/UploadFile';

/** MUI imports */
import { Grid, Table, TableHeaderRow, TableTreeColumn } from '@devexpress/dx-react-grid-material-ui';
import { TreeDataState, CustomTreeData } from '@devexpress/dx-react-grid';
import { styled } from '@mui/material/styles';
import GridUI from "@mui/material/Grid";
import {
    AppBar,
    Badge,
    Box,
    Button,
    Breadcrumbs,
    Card,
    CardContent,
    Chip,
    Hidden,
    IconButton,
    ListItemButton,
    ListItemText,
    Popover,
    Stack,
    Skeleton,
    Toolbar,
    Typography,
} from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

/** Components imports */
import CommentDialog from "../CommentDialog";
import DialogAlerts from "../DialogAlerts";
import Backdrop from "../Backdrop";
import SnackBar from "../SnackBar";
import UploadAsync from "./UploadAsync";
import UploadAsyncCustom from "./UploadAsyncCustom";
import moment from "moment";

/** Global variables */
var params = (new URL(document.location)).searchParams;
var contact_email = params.get('contact_email');

const PREFIX = 'ClientFilesMana';

const classes = {
    paper: `${PREFIX}-paper`,
    navbarLogo: `${PREFIX}-navbarLogo`,
    toolbar: `${PREFIX}-toolbar`,
    commonWhite: `${PREFIX}-commonWhite`,
    tableHeader: `${PREFIX}-tableHeader`,
    tableBody: `${PREFIX}-tableBody`,
    tableCellTree: `${PREFIX}-tableCellTree`,
    pager: `${PREFIX}-pager`,
    cardContentRoot: `${PREFIX}-cardContentRoot`,
    cardHeaderRoot: `${PREFIX}-cardHeaderRoot`,
    spacing: `${PREFIX}-spacing`,
};

const StyledContainer = styled(Box)((
    {
        theme
    }
) => ({
    [`& .${classes.paper}`]: {
        border: '2px solid #ff5968',
        borderRadius: 40,
        boxShadow: 'none'
    },

    [`& .${classes.navbarLogo}`]: {
        display: 'block',
        height: 50,
        [theme.breakpoints.down('sm')]: {
            width: '165px'
        },
    },

    [`& .${classes.toolbar}`]: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: 'center',
        backgroundColor: '#031851',
    },

    [`& .${classes.commonWhite}`]: {
        color: '#fff',
    },

    [`& .${classes.cardHeaderRoot}`]: {
        padding: 30,
    },

    [`& .${classes.cardContentRoot}`]: {
        padding: 30,
    },

    [`& .${classes.spacing}`]: {
        padding: '20px 0 25px 0'
    },

    [`& .${classes.tableHeader}`]: {
        border: 'none',
        backgroundColor: 'rgba(47,49,144,0.3)',
        fontSize: '15px',
        fontWeight: 600,
        color: '#031851',
        '&:first-of-type': {
            borderRadius: '20px 0 0 0',
        },
        '&:last-child': {
            borderRadius: '0 20px 0 0',
        }
    },

    [`& .${classes.tableBody}`]: {
        borderBottom: '2px solid #031851',
        fontSize: '14px',
        fontWeight: 400,
        color: '#031851',
    },

    [`& .${classes.tableCellTree}`]: {
        borderBottom: '2px solid #031851',
        fontSize: '14px',
        fontWeight: 400,
        color: '#031851',
        cursor: 'pointer'
    },

    [`& .${classes.pager}`]: {
        "& .Pagination-button": {
            paddingTop: 0,
            paddingBottom: 0,
            color: '#031851',
            fontWeight: 600
        },
        "& .Pagination-activeButton": {
            backgroundColor: 'rgba(47,49,144,0.3)',
        }
    },
}));

const TableRow = ({ row, ...restProps }) => (
    <Table.Row
        {...restProps}
        style={{
            backgroundColor: row.parentId === null ? 'rgba(47,49,144,0.1)' : 'none'
        }}
    />
);

const tableContentComponent = props => (
    <TableHeaderRow.Content {...props} />
);

const tableHeaderCell = ({ ...props }) => (
    <TableHeaderRow.Cell
        {...props}
        className={classes.tableHeader}
    />
);

const TableCellComponent = ({ ...props }) => (
    <Table.Cell
        {...props}
        className={classes.tableBody}
    />
);

const getChildRows = (row, rootRows) => {
    const childRows = rootRows.filter(r => r.parentId === (row ? row.id : null));
    return childRows.length ? childRows : null;
};

const ColorButton = styled(Button)(({ theme }) => ({
    border: '1px solid #ff5968',
    borderRadius: 40
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: "#2f3190",
        color: "white"
    },
}));

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: 'rgba(19, 31, 62, 0.80)',
        fontSize: 12,
        maxWidth: 320,
        borderRadius: 6,
        fontWeight: 'normal'
    },
});

const tableColumnExtensions = [
    { columnName: "comments", align: 'center', width: '5%' },
    { columnName: "status", align: 'left', width: '10%' },
    { columnName: "jan", align: 'center', width: '5%' },
    { columnName: "feb", align: 'center', width: '5%' },
    { columnName: "mar", align: 'center', width: '5%' },
    { columnName: "apr", align: 'center', width: '5%' },
    { columnName: "may", align: 'center', width: '5%' },
    { columnName: "jun", align: 'center', width: '5%' },
    { columnName: "jul", align: 'center', width: '5%' },
    { columnName: "aug", align: 'center', width: '5%' },
    { columnName: "sep", align: 'center', width: '5%' },
    { columnName: "oct", align: 'center', width: '5%' },
    { columnName: "nov", align: 'center', width: '5%' },
    { columnName: "dec", align: 'center', width: '5%' }
];

const rowsSkeleton = []

for (let i = 0; i < 15; i++) {
    rowsSkeleton.push({
        id: i,
        parentId: null,
        client: <Skeleton variant="text" animation="wave" />,
        comments: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: 'auto', mr: 'auto' }} />,
        jan: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: 'auto', mr: 'auto' }} />,
        feb: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: 'auto', mr: 'auto' }} />,
        mar: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: 'auto', mr: 'auto' }} />,
        apr: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: 'auto', mr: 'auto' }} />,
        may: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: 'auto', mr: 'auto' }} />,
        jun: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: 'auto', mr: 'auto' }} />,
        jul: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: 'auto', mr: 'auto' }} />,
        aug: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: 'auto', mr: 'auto' }} />,
        sep: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: 'auto', mr: 'auto' }} />,
        oct: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: 'auto', mr: 'auto' }} />,
        nov: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: 'auto', mr: 'auto' }} />,
        dec: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: 'auto', mr: 'auto' }} />
    });
}

const ClientFilesMana = () => {
    /** Internationalization i18n */
    const [t, i18n] = useTranslation("global");

    const tableMessages = {
        noData: t("miscellaneous.noData")
    };

    /** Component states */
    const [open, setOpen] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [dialogText, setDialogText] = useState('');
    const [dialogAction, setDialogAction] = useState('');
    const [show, setShow] = useState(false);
    const [texto, setTexto] = useState("");
    const [alertType, setAlertType] = useState("");
    const [showBackdrop, setBackDrop] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [language, setLanguage] = useState(sessionStorage.getItem('lng') === 'es' ? 'Español' : 'English');
    const [languageMobile, setlanguageMobile] = useState(sessionStorage.getItem('lng') === 'es' ? 'ES' : 'EN');
    const [clientsData, setClients] = useState([]);
    const [columnDefs, setColumnsDefs] = useState([]);
    const [expandedRowIds, setExpandenRowIds] = useState([]);
    const [filesData, setFilesData] = useState([]);
    const [filesRejected, setFilesRejected] = useState([]);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [fileFormat, setFileFormat] = useState([]);
    const [fileExtensions, setFileExtensions] = useState([]);
    const [dialogComment, setDialogComment] = useState(false);
    const [clientID, setclientID] = useState("");
    const [comments, setComments] = useState([]);
    const [commentsP, setCommentsPend] = useState([]);
    const [selectedBank, setSelectedBank] = useState({
        idOrganisation: '',
        idXero: '',
        idAccount: '',
        mode: '',
        month: '',
        showCheck: 0,
        company: '',
        account: '',
        NotApply: false
    });
    const [showTabs, setShowTabs] = useState({
        requirement_bank_statements: false,
        requirement_checks: false,
        requirement_petty_cash: false,
        initial_date: "",
        included_checks: false,
        current_date: "",
    });
    const [checkStatus, setCheckStatus] = useState(false);
    const [openCustom, setOpenCustom] = useState(false);
    const [filesDataCustom, setFilesDataCustom] = useState([]);
    const [uploadedFilesCustom, setUploadedFilesCustom] = useState([]);
    const [uploadedFilesCustomSizes, setUploadedFilesCustomSizes] = useState([]);
    const [filesRejectedCustom, setFilesRejectedCustom] = useState([]);
    const [customRequirementInfo, setCustomRequirementInfo] = useState({
        idOrganisation: "",
        month: "",
        company: "",
        current_date: "",
        req_type: "",
        req_name: "",
        custom_requirement_id: "",
    });
    const [buttonTabs, setButtomTabs] = useState("");
    const [checkStatusChecks, setCheckStatusChecks] = useState(false);
    const [checkStatusPetty, setCheckStatusPetty] = useState(false);
    const [page, setPage] = useState(0);
    const [filesRejectedChecks, setFilesRejectedChecks] = useState([]);
    const [filesRejectedPetty, setFilesRejectedPetty] = useState([]);
    const [filesDataChecks, setFilesDataChecks] = useState([]);
    const [filesDataPetty, setFilesDataPetty] = useState([]);
    

    /** Global variables */
    const breadcrumbs = [
        <Typography key="1" variant="h1" color="primary" sx={{
            fontSize: 24,
            fontWeight: 700
        }}>
            {t('principal.requirements')}
        </Typography>
    ];

    const openPopoverLanguage = Boolean(anchorEl);
    const id = openPopoverLanguage ? 'simple-popover' : undefined;

    const handleCloseDialogCustomDiscard = async () => {
        setBackDrop(true);
        let discard = {};
        if (filesDataCustom.length > 0) {
            let folder_name =
                customRequirementInfo.req_type.toString() === "1"
                    ? "Reporte de nómina"
                    : customRequirementInfo.req_type.toString() === "2"
                    ? "Reporte de contratistas"
                    : customRequirementInfo.req_type.toString() === "3"
                    ? "Reporte de ventas"
                    : customRequirementInfo.req_type.toString() === "4"
                    ? "Declaración de ISLR"
                    : customRequirementInfo.req_name;

            discard[folder_name] = filesDataCustom.map((item) => item.name);
        }

        if (Object.keys(discard).length > 0) {
            let params = {
                id_organisation: customRequirementInfo.idOrganisation,
                month: customRequirementInfo.month,
                year: moment().year(),
                CustomRequirement: true,
                custom_req_type: customRequirementInfo.req_type,
                custom_requirement_id: customRequirementInfo.custom_requirement_id,
                discard: discard,
            };
            await axios.post("/deleteFileS3multiple", params).catch((err) => {
                console.log(err);
            });
        }
        setOpenCustom(false);
        setFilesDataCustom([]);
        setUploadedFilesCustom([]);
        setUploadedFilesCustomSizes([]);
        setFilesRejectedCustom([]);
        setCustomRequirementInfo({
            idOrganisation: "",
            month: "",
            company: "",
            current_date: "",
            req_type: "",
            req_name: "",
            custom_requirement_id: "",
        });
        setBackDrop(false);
    };

    const handleCloseDialogCustom = () => {
        setOpenCustom(false);
        setFilesDataCustom([]);
        setUploadedFilesCustom([]);
        setUploadedFilesCustomSizes([]);
        setFilesRejectedCustom([]);
        setCustomRequirementInfo({
            idOrganisation: "",
            month: "",
            company: "",
            current_date: "",
            req_type: "",
            req_name: "",
            custom_requirement_id: "",
        });
    };

    const handleChangeTabs = (event, newAlignment) => {
        if (newAlignment !== null) {
            setPage(0);
            if (newAlignment.toString() === "1") {
                if (checkStatus) {
                    setDialogAction("Guardar");
                    setDialogText(t("bank.saveWithNoBankStatement"));
                } else if (selectedBank.mode !== undefined && selectedBank.mode !== 0) {
                    setDialogAction("Convertir");
                    setDialogText(t("bank.convertBankStatement"));
                } else {
                    setDialogAction("Guardar");
                    setDialogText(t("bank.saveBankStatement"));
                }
            } else {
                setDialogAction("Guardar");
                setDialogText(t("miscellaneous.saveSure"));
            }
            setButtomTabs(newAlignment);
        }
    };

    const handleChangeStatus = (event) => {
        setCheckStatus(event.target.checked);
        if (event.target.checked) {
            setDialogAction('Guardar');
            setDialogText(t('bank.saveWithNoBankStatement'));
        }
        else {
            if (selectedBank.mode !== undefined && selectedBank.mode !== 0) {
                setDialogAction('Convertir');
                setDialogText(t('bank.convertBankStatement'));
            } else {
                setDialogAction('Guardar');
                setDialogText(t('bank.saveBankStatement'));
            }
        }
    }

    /** Component functions */
    const TableCellTreeComponent = ({ ...props }) => (
        <TableTreeColumn.Cell
            {...props}
            className={props.children[1].props.visible === true ? classes.tableCellTree : classes.tableBody}
            onClick={() => {
                if (props.children[1].props.visible === true) {
                    if (!expandedRowIds.includes(props.tableRow.rowId)) {
                        setExpandenRowIds(prevArray => [...prevArray, props.tableRow.rowId]);
                    } else {
                        const newExpandedRows = [...expandedRowIds];
                        newExpandedRows.splice(newExpandedRows.indexOf(props.tableRow.rowId), 1);
                        setExpandenRowIds(newExpandedRows);
                    }
                } else {
                    return;
                }
            }}
        />
    );

    const handleOpenDialog = async (bnkDatta, accDatta, month, showCheck) => {
        setBackDrop(true);

        await fetch(
            `/getUploadedFile?organisationId=${bnkDatta.id_organisation}&account_id=${accDatta._id}&date=${moment()
                .set({ year: moment().year(), month: parseInt(month) - 1 })
                .startOf("month")
                .format("YYYY-MM-DD")}`,
            {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            }
        )
            .then((res) => res.json())
            .then((data) => {
                setShowTabs({
                    requirement_bank_statements: data.tabs.requirement_bank_statements,
                    requirement_checks: data.tabs.requirement_checks,
                    requirement_petty_cash: data.tabs.requirement_petty_cash,
                    initial_date: data.initial_date,
                    included_checks: data.included_checks,
                    current_date: moment()
                        .set({ year: moment().year(), month: parseInt(month) - 1 })
                        .startOf("month")
                        .format("YYYY-MM-DD"),
                });
                setButtomTabs(data.tabs.requirement_bank_statements ? "1" : data.tabs.requirement_checks ? "2" : "3");

                let finalFiles = [];
                data.requirements.forEach((item) => {
                    finalFiles.push({
                        req_type: item.requirement_type,
                        files: item.files,
                        sizes: item.sizes,
                    });
                });
                if (
                    data.tabs.requirement_bank_statements &&
                    finalFiles.filter((item) => item.req_type.toString() === "1").length === 0
                ) {
                    finalFiles.push({
                        req_type: 1,
                        files: [],
                        sizes: [],
                    });
                }
                if (
                    data.tabs.requirement_checks &&
                    finalFiles.filter((item) => item.req_type.toString() === "2").length === 0
                ) {
                    finalFiles.push({
                        req_type: 2,
                        files: [],
                        sizes: [],
                    });
                }
                if (
                    data.tabs.requirement_petty_cash &&
                    finalFiles.filter((item) => item.req_type.toString() === "3").length === 0
                ) {
                    finalFiles.push({
                        req_type: 3,
                        files: [],
                        sizes: [],
                    });
                }
                setUploadedFiles(finalFiles);
                let finalCheck1 = false;
                let finalCheck2 = false;
                let finalCheck3 = false;
                if (
                    data.requirements.length > 0 &&
                    data.requirements.filter((item) => item.requirement_type === 1).length > 0 &&
                    data.requirements.filter((item) => item.requirement_type === 1)[0].not_applicable
                ) {
                    finalCheck1 = true;
                    setCheckStatus(true);
                } else {
                    setCheckStatus(false);
                }
                if (
                    data.requirements.length > 0 &&
                    data.requirements.filter((item) => item.requirement_type === 2).length > 0 &&
                    data.requirements.filter((item) => item.requirement_type === 2)[0].not_applicable
                ) {
                    finalCheck2 = true;
                    setCheckStatusChecks(true);
                } else {
                    setCheckStatusChecks(false);
                }
                if (
                    data.requirements.length > 0 &&
                    data.requirements.filter((item) => item.requirement_type === 3).length > 0 &&
                    data.requirements.filter((item) => item.requirement_type === 3)[0].not_applicable
                ) {
                    finalCheck3 = true;
                    setCheckStatusPetty(true);
                } else {
                    setCheckStatusPetty(false);
                }
                setSelectedBank({
                    idOrganisation: bnkDatta.id_organisation,
                    idXero: accDatta.account_id_xero,
                    idAccount: accDatta._id,
                    mode: accDatta.mode,
                    month: month,
                    showCheck: showCheck,
                    company: bnkDatta.company_name,
                    account: accDatta.account_name,
                    NotApply: finalCheck1,
                    NotApplyChecks: finalCheck2,
                    NotApplyPetty: finalCheck3,
                });
                if (data.tabs.requirement_bank_statements) {
                    if (finalCheck1) {
                        setDialogAction("Guardar");
                        setDialogText(t("bank.saveWithNoBankStatement"));
                    } else if (accDatta.mode !== undefined && accDatta.mode !== 0) {
                        setDialogAction("Convertir");
                        setDialogText(t("bank.convertBankStatement"));
                    } else {
                        setDialogAction("Guardar");
                        setDialogText(t("bank.saveBankStatement"));
                    }
                } else {
                    setDialogAction("Guardar");
                    setDialogText(t("miscellaneous.saveSure"));
                }
                setFileFormat(accDatta.file_format);
                setFileExtensions(accDatta.file_format.map((file) => `.${file}`).join("|"));
            });

        setOpen(true);

        setBackDrop(false);
    };

    const handleCloseDialog = () => {
        setSelectedBank({
            idOrganisation: '',
            idXero: '',
            idAccount: '',
            mode: '',
            month: '',
            showCheck: 0,
            company: '',
            account: '',
            NotApply: false
        });
        setCheckStatus(false);
        setFilesData([]);
        setFilesRejected([]);
        setUploadedFiles([]);
        setFileFormat([]);
        setFileExtensions([]);
        setDialogAction('');
        setDialogText('');
        setOpen(false);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setShow(false);
    };

    const handleClickLanguagePopover = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleCloseLanguagePopover = () => {
        setAnchorEl(null);
    }

    const openComments = async (clientId) => {
        setBackDrop(true);

        setclientID(clientId);

        await getComments(clientId);

        setDialogComment(true);

        setBackDrop(false);
    }

    const closeDialog = () => {
        setDialogComment(false);
    };

    const updateLanguage = (lng) => {
        if (lng === 'English') {
            setLanguage(lng);
            setlanguageMobile("EN");
            sessionStorage.setItem('lng', 'en');
            i18n.changeLanguage("en");
        } else {
            setLanguage(lng);
            setlanguageMobile("ES");
            sessionStorage.setItem('lng', 'es');
            i18n.changeLanguage("es");
        }
        document.title = t('principal.gcaPlatform');
    }

    const convertBankStatement = async () => {
        setBackDrop(true);

        const params = {
            conversion: dialogAction === 'Convertir' ? true : false,
            account_id: selectedBank.idAccount,
            organisationId: selectedBank.idOrganisation,
            date: moment().set({ 'year': moment().year(), 'month': parseInt(selectedBank.month) - 1 }).startOf('month').format('YYYY-MM-DD'),
            not_applicable: checkStatus
        }

        let allowedExtensions = new RegExp(`(${fileExtensions})`, 'i');
        let valid = false;

        if (checkStatus) {
            valid = true;
        }
        else {
            let fileName = filesData[0].name.toLowerCase();

            if (allowedExtensions.exec(fileName)) {
                valid = true;
            } else {
                setOpenAlert(false);
                setTexto(`${t('manageAccount.invalidFormat')} ${(fileFormat).map(file => `.${file}`).join(' ')}`);
                setAlertType("error");
                setShow(true);
            }
        }
        if (valid) {
            await fetch(`/convertBankStatement`, {
                method: "POST",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
                body: JSON.stringify(params),
            })
                .then((res) => res.json())
                .then((response) => {
                    setOpenAlert(false);
                    loadInitData();
                    handleCloseDialog();
                    setTexto(t('dialogConversions.statementSaved'));
                    setAlertType("success");
                    setShow(true);
                })
                .catch((error) => {
                    console.log(error);
                });
        }

        setBackDrop(false);
    };

    const getComments = async (clientId) => {
        await fetch(`/getComments?company_id=${clientId}&period=${moment().year()}&comment_type=1`, {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        }
        ).then((res) => res.json()
        ).then((data) => {
            setComments(data.history);
            setCommentsPend(data.pendings);
        });
    }

    const loadInitData = async () => {
        await fetch(`/getRequirementsByEmail?contact_email=${contact_email}`, {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            }
        }
        ).then((res) => res.json()
        ).then((data) => {
            let clientsdata = [];

            data.forEach((bnkDatta, index) => {
                if (bnkDatta.accounts.length > 0) {
                    let notifyNum = {
                        contNotiJan: 0,
                        contNotiFeb: 0,
                        contNotiMar: 0,
                        contNotiApr: 0,
                        contNotiMay: 0,
                        contNotiJun: 0,
                        contNotiJul: 0,
                        contNotiAug: 0,
                        contNotiSep: 0,
                        contNotiOct: 0,
                        contNotiNov: 0,
                        contNotiDec: 0,
                    }

                    let notifyCheck = {
                        contNotiJan: false,
                        contNotiFeb: false,
                        contNotiMar: false,
                        contNotiApr: false,
                        contNotiMay: false,
                        contNotiJun: false,
                        contNotiJul: false,
                        contNotiAug: false,
                        contNotiSep: false,
                        contNotiOct: false,
                        contNotiNov: false,
                        contNotiDec: false,
                    }

                    let unlockCheck = {
                        contNotiJan: false,
                        contNotiFeb: false,
                        contNotiMar: false,
                        contNotiApr: false,
                        contNotiMay: false,
                        contNotiJun: false,
                        contNotiJul: false,
                        contNotiAug: false,
                        contNotiSep: false,
                        contNotiOct: false,
                        contNotiNov: false,
                        contNotiDec: false,
                    }

                    bnkDatta.accounts.forEach(accDatta => {
                        // Alerta
                        if (accDatta.notify_jan === 2) {
                            notifyNum.contNotiJan++;
                        }
                        if (accDatta.notify_feb === 2) {
                            notifyNum.contNotiFeb++;
                        }
                        if (accDatta.notify_mar === 2) {
                            notifyNum.contNotiMar++;
                        }
                        if (accDatta.notify_apr === 2) {
                            notifyNum.contNotiApr++;
                        }
                        if (accDatta.notify_may === 2) {
                            notifyNum.contNotiMay++;
                        }
                        if (accDatta.notify_jun === 2) {
                            notifyNum.contNotiJun++;
                        }
                        if (accDatta.notify_jul === 2) {
                            notifyNum.contNotiJul++;
                        }
                        if (accDatta.notify_aug === 2) {
                            notifyNum.contNotiAug++;
                        }
                        if (accDatta.notify_sep === 2) {
                            notifyNum.contNotiSep++;
                        }
                        if (accDatta.notify_oct === 2) {
                            notifyNum.contNotiOct++;
                        }
                        if (accDatta.notify_nov === 2) {
                            notifyNum.contNotiNov++;
                        }
                        if (accDatta.notify_dec === 2) {
                            notifyNum.contNotiDec++;
                        }

                        // Check verde
                        if (accDatta.notify_jan === 1) {
                            notifyCheck.contNotiJan = true;
                        }
                        if (accDatta.notify_feb === 1) {
                            notifyCheck.contNotiFeb = true;
                        }
                        if (accDatta.notify_mar === 1) {
                            notifyCheck.contNotiMar = true;
                        }
                        if (accDatta.notify_apr === 1) {
                            notifyCheck.contNotiApr = true;
                        }
                        if (accDatta.notify_may === 1) {
                            notifyCheck.contNotiMay = true;
                        }
                        if (accDatta.notify_jun === 1) {
                            notifyCheck.contNotiJun = true;
                        }
                        if (accDatta.notify_jul === 1) {
                            notifyCheck.contNotiJul = true;
                        }
                        if (accDatta.notify_aug === 1) {
                            notifyCheck.contNotiAug = true;
                        }
                        if (accDatta.notify_sep === 1) {
                            notifyCheck.contNotiSep = true;
                        }
                        if (accDatta.notify_oct === 1) {
                            notifyCheck.contNotiOct = true;
                        }
                        if (accDatta.notify_nov === 1) {
                            notifyCheck.contNotiNov = true;
                        }
                        if (accDatta.notify_dec === 1) {
                            notifyCheck.contNotiDec = true;
                        }

                        // Unlock morado
                        if (accDatta.notify_jan === 3) {
                            unlockCheck.contNotiJan = true;
                        }
                        if (accDatta.notify_feb === 3) {
                            unlockCheck.contNotiFeb = true;
                        }
                        if (accDatta.notify_mar === 3) {
                            unlockCheck.contNotiMar = true;
                        }
                        if (accDatta.notify_apr === 3) {
                            unlockCheck.contNotiApr = true;
                        }
                        if (accDatta.notify_may === 3) {
                            unlockCheck.contNotiMay = true;
                        }
                        if (accDatta.notify_jun === 3) {
                            unlockCheck.contNotiJun = true;
                        }
                        if (accDatta.notify_jul === 3) {
                            unlockCheck.contNotiJul = true;
                        }
                        if (accDatta.notify_aug === 3) {
                            unlockCheck.contNotiAug = true;
                        }
                        if (accDatta.notify_sep === 3) {
                            unlockCheck.contNotiSep = true;
                        }
                        if (accDatta.notify_oct === 3) {
                            unlockCheck.contNotiOct = true;
                        }
                        if (accDatta.notify_nov === 3) {
                            unlockCheck.contNotiNov = true;
                        }
                        if (accDatta.notify_dec === 3) {
                            unlockCheck.contNotiDec = true;
                        }

                        clientsdata.push({
                            id: accDatta._id,
                            parentId: index + 0.1,
                            client: accDatta.account_name,
                            status: <Chip label={accDatta.mode === 0 ? "Bank Feed" : accDatta.mode === 1 ? "Manual" : t('miscellaneous.pendingMode')} sx={{ color: accDatta.mode === 0 ? "green" : accDatta.mode === 1 ? "#031851" : "#2f3190" }} variant="outlined" />,
                            jan: <LightTooltip title={t('bills.uploadDoc')} aria-label="edit">
                                <span>
                                    <IconButton aria-label="upload" onClick={() => handleOpenDialog(bnkDatta, accDatta, '01', accDatta.notify_jan)} disabled={accDatta.notify_jan === 0}>
                                        <UploadFileIcon sx={{ color: accDatta.notify_jan === 2 ? "#ff5968" : accDatta.notify_jan === 1 ? "green" : accDatta.notify_jan === 3 ? "#2f3190" : "rgba(0, 0, 0, 0.26)" }} />
                                    </IconButton>
                                </span>
                            </LightTooltip>,
                            feb: <LightTooltip title={t('bills.uploadDoc')} aria-label="edit">
                                <span>
                                    <IconButton aria-label="upload" onClick={() => handleOpenDialog(bnkDatta, accDatta, '02', accDatta.notify_feb)} disabled={accDatta.notify_feb === 0}>
                                        <UploadFileIcon sx={{ color: accDatta.notify_feb === 2 ? "#ff5968" : accDatta.notify_feb === 1 ? "green" : accDatta.notify_feb === 3 ? "#2f3190" : "rgba(0, 0, 0, 0.26)" }} />
                                    </IconButton>
                                </span>
                            </LightTooltip>,
                            mar: <LightTooltip title={t('bills.uploadDoc')} aria-label="edit">
                                <span>
                                    <IconButton aria-label="upload" onClick={() => handleOpenDialog(bnkDatta, accDatta, '03', accDatta.notify_mar)} disabled={accDatta.notify_mar === 0}>
                                        <UploadFileIcon sx={{ color: accDatta.notify_mar === 2 ? "#ff5968" : accDatta.notify_mar === 1 ? "green" : accDatta.notify_mar === 3 ? "#2f3190" : "rgba(0, 0, 0, 0.26)" }} />
                                    </IconButton>
                                </span>
                            </LightTooltip>,
                            apr: <LightTooltip title={t('bills.uploadDoc')} aria-label="edit">
                                <span>
                                    <IconButton aria-label="upload" onClick={() => handleOpenDialog(bnkDatta, accDatta, '04', accDatta.notify_apr)} disabled={accDatta.notify_apr === 0}>
                                        <UploadFileIcon sx={{ color: accDatta.notify_apr === 2 ? "#ff5968" : accDatta.notify_apr === 1 ? "green" : accDatta.notify_apr === 3 ? "#2f3190" : "rgba(0, 0, 0, 0.26)" }} />
                                    </IconButton>
                                </span>
                            </LightTooltip>,
                            may: <LightTooltip title={t('bills.uploadDoc')} aria-label="edit">
                                <span>
                                    <IconButton aria-label="upload" onClick={() => handleOpenDialog(bnkDatta, accDatta, '05', accDatta.notify_may)} disabled={accDatta.notify_may === 0}>
                                        <UploadFileIcon sx={{ color: accDatta.notify_may === 2 ? "#ff5968" : accDatta.notify_may === 1 ? "green" : accDatta.notify_may === 3 ? "#2f3190" : "rgba(0, 0, 0, 0.26)" }} />
                                    </IconButton>
                                </span>
                            </LightTooltip>,
                            jun: <LightTooltip title={t('bills.uploadDoc')} aria-label="edit">
                                <span>
                                    <IconButton aria-label="upload" onClick={() => handleOpenDialog(bnkDatta, accDatta, '06', accDatta.notify_jun)} disabled={accDatta.notify_jun === 0}>
                                        <UploadFileIcon sx={{ color: accDatta.notify_jun === 2 ? "#ff5968" : accDatta.notify_jun === 1 ? "green" : accDatta.notify_jun === 3 ? "#2f3190" : "rgba(0, 0, 0, 0.26)" }} />
                                    </IconButton>
                                </span>
                            </LightTooltip>,
                            jul: <LightTooltip title={t('bills.uploadDoc')} aria-label="edit">
                                <span>
                                    <IconButton aria-label="upload" onClick={() => handleOpenDialog(bnkDatta, accDatta, '07', accDatta.notify_jul)} disabled={accDatta.notify_jul === 0}>
                                        <UploadFileIcon sx={{ color: accDatta.notify_jul === 2 ? "#ff5968" : accDatta.notify_jul === 1 ? "green" : accDatta.notify_jul === 3 ? "#2f3190" : "rgba(0, 0, 0, 0.26)" }} />
                                    </IconButton>
                                </span>
                            </LightTooltip>,
                            aug: <LightTooltip title={t('bills.uploadDoc')} aria-label="edit">
                                <span>
                                    <IconButton aria-label="upload" onClick={() => handleOpenDialog(bnkDatta, accDatta, '08', accDatta.notify_aug)} disabled={accDatta.notify_aug === 0}>
                                        <UploadFileIcon sx={{ color: accDatta.notify_aug === 2 ? "#ff5968" : accDatta.notify_aug === 1 ? "green" : accDatta.notify_aug === 3 ? "#2f3190" : "rgba(0, 0, 0, 0.26)" }} />
                                    </IconButton>
                                </span>
                            </LightTooltip>,
                            sep: <LightTooltip title={t('bills.uploadDoc')} aria-label="edit">
                                <span>
                                    <IconButton aria-label="upload" onClick={() => handleOpenDialog(bnkDatta, accDatta, '09', accDatta.notify_sep)} disabled={accDatta.notify_sep === 0}>
                                        <UploadFileIcon sx={{ color: accDatta.notify_sep === 2 ? "#ff5968" : accDatta.notify_sep === 1 ? "green" : accDatta.notify_sep === 3 ? "#2f3190" : "rgba(0, 0, 0, 0.26)" }} />
                                    </IconButton>
                                </span>
                            </LightTooltip>,
                            oct: <LightTooltip title={t('bills.uploadDoc')} aria-label="edit">
                                <span>
                                    <IconButton aria-label="upload" onClick={() => handleOpenDialog(bnkDatta, accDatta, '10', accDatta.notify_oct)} disabled={accDatta.notify_oct === 0}>
                                        <UploadFileIcon sx={{ color: accDatta.notify_oct === 2 ? "#ff5968" : accDatta.notify_oct === 1 ? "green" : accDatta.notify_oct === 3 ? "#2f3190" : "rgba(0, 0, 0, 0.26)" }} />
                                    </IconButton>
                                </span>
                            </LightTooltip>,
                            nov: <LightTooltip title={t('bills.uploadDoc')} aria-label="edit">
                                <span>
                                    <IconButton aria-label="upload" onClick={() => handleOpenDialog(bnkDatta, accDatta, '11', accDatta.notify_nov)} disabled={accDatta.notify_nov === 0}>
                                        <UploadFileIcon sx={{ color: accDatta.notify_nov === 2 ? "#ff5968" : accDatta.notify_nov === 1 ? "green" : accDatta.notify_nov === 3 ? "#2f3190" : "rgba(0, 0, 0, 0.26)" }} />
                                    </IconButton>
                                </span>
                            </LightTooltip>,
                            dec: <LightTooltip title={t('bills.uploadDoc')} aria-label="edit">
                                <span>
                                    <IconButton aria-label="upload" onClick={() => handleOpenDialog(bnkDatta, accDatta, '12', accDatta.notify_dec)} disabled={accDatta.notify_dec === 0}>
                                        <UploadFileIcon sx={{ color: accDatta.notify_dec === 2 ? "#ff5968" : accDatta.notify_dec === 1 ? "green" : accDatta.notify_dec === 3 ? "#2f3190" : "rgba(0, 0, 0, 0.26)" }} />
                                    </IconButton>
                                </span>
                            </LightTooltip>,
                        });
                    });

                    let cont = 0;
                    bnkDatta.comments.forEach(data => {
                        if (data.status === 0) {
                            cont++;
                        }
                    });

                    clientsdata.push({
                        id: bnkDatta.id_organisation,
                        parentId: null,
                        client: bnkDatta.company_name,
                        comments:
                            <LightTooltip title={t('miscellaneous.comments')}>
                                <IconButton aria-label="comments" sx={bnkDatta.comments.length === 0 ? { color: "rgba(0, 0, 0, 0.54)" } : { color: "#2f3190" }}
                                    onClick={() => openComments(bnkDatta.id_organisation)}
                                >
                                    <StyledBadge badgeContent={cont}>
                                        <CommentIcon />
                                    </StyledBadge>
                                </IconButton>
                            </LightTooltip>,
                        jan: notifyNum.contNotiJan > 0 ? <Badge badgeContent={notifyNum.contNotiJan} color="secondary"><ErrorOutlineIcon color="secondary" /></Badge>
                            : notifyCheck.contNotiJan ? <CheckIcon sx={{ color: "green" }} />
                                : unlockCheck.contNotiJan ? <LockOpenIcon sx={{ color: "#2f3190" }} />
                                    : <LockIcon color="disabled" />,
                        feb: notifyNum.contNotiFeb > 0 ? <Badge badgeContent={notifyNum.contNotiFeb} color="secondary"><ErrorOutlineIcon color="secondary" /></Badge>
                            : notifyCheck.contNotiFeb ? <CheckIcon sx={{ color: "green" }} />
                                : unlockCheck.contNotiFeb ? <LockOpenIcon sx={{ color: "#2f3190" }} />
                                    : <LockIcon color="disabled" />,
                        mar: notifyNum.contNotiMar > 0 ? <Badge badgeContent={notifyNum.contNotiMar} color="secondary"><ErrorOutlineIcon color="secondary" /></Badge>
                            : notifyCheck.contNotiMar ? <CheckIcon sx={{ color: "green" }} />
                                : unlockCheck.contNotiMar ? <LockOpenIcon sx={{ color: "#2f3190" }} />
                                    : <LockIcon color="disabled" />,
                        apr: notifyNum.contNotiApr > 0 ? <Badge badgeContent={notifyNum.contNotiApr} color="secondary"><ErrorOutlineIcon color="secondary" /></Badge>
                            : notifyCheck.contNotiApr ? <CheckIcon sx={{ color: "green" }} />
                                : unlockCheck.contNotiApr ? <LockOpenIcon sx={{ color: "#2f3190" }} />
                                    : <LockIcon color="disabled" />,
                        may: notifyNum.contNotiMay > 0 ? <Badge badgeContent={notifyNum.contNotiMay} color="secondary"><ErrorOutlineIcon color="secondary" /></Badge>
                            : notifyCheck.contNotiMay ? <CheckIcon sx={{ color: "green" }} />
                                : unlockCheck.contNotiMay ? <LockOpenIcon sx={{ color: "#2f3190" }} />
                                    : <LockIcon color="disabled" />,
                        jun: notifyNum.contNotiJun > 0 ? <Badge badgeContent={notifyNum.contNotiJun} color="secondary"><ErrorOutlineIcon color="secondary" /></Badge>
                            : notifyCheck.contNotiJun ? <CheckIcon sx={{ color: "green" }} />
                                : unlockCheck.contNotiJun ? <LockOpenIcon sx={{ color: "#2f3190" }} />
                                    : <LockIcon color="disabled" />,
                        jul: notifyNum.contNotiJul > 0 ? <Badge badgeContent={notifyNum.contNotiJul} color="secondary"><ErrorOutlineIcon color="secondary" /></Badge>
                            : notifyCheck.contNotiJul ? <CheckIcon sx={{ color: "green" }} />
                                : unlockCheck.contNotiJul ? <LockOpenIcon sx={{ color: "#2f3190" }} />
                                    : <LockIcon color="disabled" />,
                        aug: notifyNum.contNotiAug > 0 ? <Badge badgeContent={notifyNum.contNotiAug} color="secondary"><ErrorOutlineIcon color="secondary" /></Badge>
                            : notifyCheck.contNotiAug ? <CheckIcon sx={{ color: "green" }} />
                                : unlockCheck.contNotiAug ? <LockOpenIcon sx={{ color: "#2f3190" }} />
                                    : <LockIcon color="disabled" />,
                        sep: notifyNum.contNotiSep > 0 ? <Badge badgeContent={notifyNum.contNotiSep} color="secondary"><ErrorOutlineIcon color="secondary" /></Badge>
                            : notifyCheck.contNotiSep ? <CheckIcon sx={{ color: "green" }} />
                                : unlockCheck.contNotiSep ? <LockOpenIcon sx={{ color: "#2f3190" }} />
                                    : <LockIcon color="disabled" />,
                        oct: notifyNum.contNotiOct > 0 ? <Badge badgeContent={notifyNum.contNotiOct} color="secondary"><ErrorOutlineIcon color="secondary" /></Badge>
                            : notifyCheck.contNotiOct ? <CheckIcon sx={{ color: "green" }} />
                                : unlockCheck.contNotiOct ? <LockOpenIcon sx={{ color: "#2f3190" }} />
                                    : <LockIcon color="disabled" />,
                        nov: notifyNum.contNotiNov > 0 ? <Badge badgeContent={notifyNum.contNotiNov} color="secondary"><ErrorOutlineIcon color="secondary" /></Badge>
                            : notifyCheck.contNotiNov ? <CheckIcon sx={{ color: "green" }} />
                                : unlockCheck.contNotiNov ? <LockOpenIcon sx={{ color: "#2f3190" }} />
                                    : <LockIcon color="disabled" />,
                        dec: notifyNum.contNotiDec > 0 ? <Badge badgeContent={notifyNum.contNotiDec} color="secondary"><ErrorOutlineIcon color="secondary" /></Badge>
                            : notifyCheck.contNotiDec ? <CheckIcon sx={{ color: "green" }} />
                                : unlockCheck.contNotiDec ? <LockOpenIcon sx={{ color: "#2f3190" }} />
                                    : <LockIcon color="disabled" />,
                    });

                    clientsdata.push({
                        id: index + 0.1,
                        parentId: bnkDatta.id_organisation,
                        client: `${t("miscellaneous.banks")} (${bnkDatta.accounts.length})`,
                        jan: notifyNum.contNotiJan > 0 ? <Badge badgeContent={notifyNum.contNotiJan} color="secondary"><ErrorOutlineIcon color="secondary" /></Badge>
                            : notifyCheck.contNotiJan ? <CheckIcon sx={{ color: "green" }} />
                                : unlockCheck.contNotiJan ? <LockOpenIcon sx={{ color: "#2f3190" }} />
                                    : <LockIcon color="disabled" />,
                        feb: notifyNum.contNotiFeb > 0 ? <Badge badgeContent={notifyNum.contNotiFeb} color="secondary"><ErrorOutlineIcon color="secondary" /></Badge>
                            : notifyCheck.contNotiFeb ? <CheckIcon sx={{ color: "green" }} />
                                : unlockCheck.contNotiFeb ? <LockOpenIcon sx={{ color: "#2f3190" }} />
                                    : <LockIcon color="disabled" />,
                        mar: notifyNum.contNotiMar > 0 ? <Badge badgeContent={notifyNum.contNotiMar} color="secondary"><ErrorOutlineIcon color="secondary" /></Badge>
                            : notifyCheck.contNotiMar ? <CheckIcon sx={{ color: "green" }} />
                                : unlockCheck.contNotiMar ? <LockOpenIcon sx={{ color: "#2f3190" }} />
                                    : <LockIcon color="disabled" />,
                        apr: notifyNum.contNotiApr > 0 ? <Badge badgeContent={notifyNum.contNotiApr} color="secondary"><ErrorOutlineIcon color="secondary" /></Badge>
                            : notifyCheck.contNotiApr ? <CheckIcon sx={{ color: "green" }} />
                                : unlockCheck.contNotiApr ? <LockOpenIcon sx={{ color: "#2f3190" }} />
                                    : <LockIcon color="disabled" />,
                        may: notifyNum.contNotiMay > 0 ? <Badge badgeContent={notifyNum.contNotiMay} color="secondary"><ErrorOutlineIcon color="secondary" /></Badge>
                            : notifyCheck.contNotiMay ? <CheckIcon sx={{ color: "green" }} />
                                : unlockCheck.contNotiMay ? <LockOpenIcon sx={{ color: "#2f3190" }} />
                                    : <LockIcon color="disabled" />,
                        jun: notifyNum.contNotiJun > 0 ? <Badge badgeContent={notifyNum.contNotiJun} color="secondary"><ErrorOutlineIcon color="secondary" /></Badge>
                            : notifyCheck.contNotiJun ? <CheckIcon sx={{ color: "green" }} />
                                : unlockCheck.contNotiJun ? <LockOpenIcon sx={{ color: "#2f3190" }} />
                                    : <LockIcon color="disabled" />,
                        jul: notifyNum.contNotiJul > 0 ? <Badge badgeContent={notifyNum.contNotiJul} color="secondary"><ErrorOutlineIcon color="secondary" /></Badge>
                            : notifyCheck.contNotiJul ? <CheckIcon sx={{ color: "green" }} />
                                : unlockCheck.contNotiJul ? <LockOpenIcon sx={{ color: "#2f3190" }} />
                                    : <LockIcon color="disabled" />,
                        aug: notifyNum.contNotiAug > 0 ? <Badge badgeContent={notifyNum.contNotiAug} color="secondary"><ErrorOutlineIcon color="secondary" /></Badge>
                            : notifyCheck.contNotiAug ? <CheckIcon sx={{ color: "green" }} />
                                : unlockCheck.contNotiAug ? <LockOpenIcon sx={{ color: "#2f3190" }} />
                                    : <LockIcon color="disabled" />,
                        sep: notifyNum.contNotiSep > 0 ? <Badge badgeContent={notifyNum.contNotiSep} color="secondary"><ErrorOutlineIcon color="secondary" /></Badge>
                            : notifyCheck.contNotiSep ? <CheckIcon sx={{ color: "green" }} />
                                : unlockCheck.contNotiSep ? <LockOpenIcon sx={{ color: "#2f3190" }} />
                                    : <LockIcon color="disabled" />,
                        oct: notifyNum.contNotiOct > 0 ? <Badge badgeContent={notifyNum.contNotiOct} color="secondary"><ErrorOutlineIcon color="secondary" /></Badge>
                            : notifyCheck.contNotiOct ? <CheckIcon sx={{ color: "green" }} />
                                : unlockCheck.contNotiOct ? <LockOpenIcon sx={{ color: "#2f3190" }} />
                                    : <LockIcon color="disabled" />,
                        nov: notifyNum.contNotiNov > 0 ? <Badge badgeContent={notifyNum.contNotiNov} color="secondary"><ErrorOutlineIcon color="secondary" /></Badge>
                            : notifyCheck.contNotiNov ? <CheckIcon sx={{ color: "green" }} />
                                : unlockCheck.contNotiNov ? <LockOpenIcon sx={{ color: "#2f3190" }} />
                                    : <LockIcon color="disabled" />,
                        dec: notifyNum.contNotiDec > 0 ? <Badge badgeContent={notifyNum.contNotiDec} color="secondary"><ErrorOutlineIcon color="secondary" /></Badge>
                            : notifyCheck.contNotiDec ? <CheckIcon sx={{ color: "green" }} />
                                : unlockCheck.contNotiDec ? <LockOpenIcon sx={{ color: "#2f3190" }} />
                                    : <LockIcon color="disabled" />,
                    });
                }
                else if (bnkDatta.accounts.length === 0) {
                    let cont = 0;
                    bnkDatta.comments.forEach(data => {
                        if (data.status === 0) {
                            cont++;
                        }
                    });

                    clientsdata.push({
                        id: bnkDatta.id_organisation,
                        parentId: null,
                        client: bnkDatta.company_name,
                        comments:
                            <LightTooltip title={t('miscellaneous.comments')}>
                                <IconButton aria-label="comments" sx={bnkDatta.comments.length === 0 ? { color: "rgba(0, 0, 0, 0.54)" } : { color: "#2f3190" }}
                                    onClick={() => openComments(bnkDatta.id_organisation)}
                                >
                                    <StyledBadge badgeContent={cont}>
                                        <CommentIcon />
                                    </StyledBadge>
                                </IconButton>
                            </LightTooltip>,
                        jan: moment().add(-1, 'months').isAfter(moment('01', 'MM').set('year', moment().year())) ? <CheckIcon sx={{ color: "green" }} /> : <LockIcon color="disabled" />,
                        feb: moment().add(-1, 'months').isAfter(moment('02', 'MM').set('year', moment().year())) ? <CheckIcon sx={{ color: "green" }} /> : <LockIcon color="disabled" />,
                        mar: moment().add(-1, 'months').isAfter(moment('03', 'MM').set('year', moment().year())) ? <CheckIcon sx={{ color: "green" }} /> : <LockIcon color="disabled" />,
                        apr: moment().add(-1, 'months').isAfter(moment('04', 'MM').set('year', moment().year())) ? <CheckIcon sx={{ color: "green" }} /> : <LockIcon color="disabled" />,
                        may: moment().add(-1, 'months').isAfter(moment('05', 'MM').set('year', moment().year())) ? <CheckIcon sx={{ color: "green" }} /> : <LockIcon color="disabled" />,
                        jun: moment().add(-1, 'months').isAfter(moment('06', 'MM').set('year', moment().year())) ? <CheckIcon sx={{ color: "green" }} /> : <LockIcon color="disabled" />,
                        jul: moment().add(-1, 'months').isAfter(moment('07', 'MM').set('year', moment().year())) ? <CheckIcon sx={{ color: "green" }} /> : <LockIcon color="disabled" />,
                        aug: moment().add(-1, 'months').isAfter(moment('08', 'MM').set('year', moment().year())) ? <CheckIcon sx={{ color: "green" }} /> : <LockIcon color="disabled" />,
                        sep: moment().add(-1, 'months').isAfter(moment('09', 'MM').set('year', moment().year())) ? <CheckIcon sx={{ color: "green" }} /> : <LockIcon color="disabled" />,
                        oct: moment().add(-1, 'months').isAfter(moment('10', 'MM').set('year', moment().year())) ? <CheckIcon sx={{ color: "green" }} /> : <LockIcon color="disabled" />,
                        nov: moment().add(-1, 'months').isAfter(moment('11', 'MM').set('year', moment().year())) ? <CheckIcon sx={{ color: "green" }} /> : <LockIcon color="disabled" />,
                        dec: moment().add(-1, 'months').isAfter(moment('12', 'MM').set('year', moment().year())) ? <CheckIcon sx={{ color: "green" }} /> : <LockIcon color="disabled" />,
                    });

                    clientsdata.push({
                        id: index + 0.1,
                        parentId: bnkDatta.id_organisation,
                        client: `${t("miscellaneous.banks")} (${bnkDatta.accounts.length})`,
                        jan: moment().add(-1, 'months').isAfter(moment('01', 'MM').set('year', moment().year())) ? <CheckIcon sx={{ color: "green" }} /> : <LockIcon color="disabled" />,
                        feb: moment().add(-1, 'months').isAfter(moment('02', 'MM').set('year', moment().year())) ? <CheckIcon sx={{ color: "green" }} /> : <LockIcon color="disabled" />,
                        mar: moment().add(-1, 'months').isAfter(moment('03', 'MM').set('year', moment().year())) ? <CheckIcon sx={{ color: "green" }} /> : <LockIcon color="disabled" />,
                        apr: moment().add(-1, 'months').isAfter(moment('04', 'MM').set('year', moment().year())) ? <CheckIcon sx={{ color: "green" }} /> : <LockIcon color="disabled" />,
                        may: moment().add(-1, 'months').isAfter(moment('05', 'MM').set('year', moment().year())) ? <CheckIcon sx={{ color: "green" }} /> : <LockIcon color="disabled" />,
                        jun: moment().add(-1, 'months').isAfter(moment('06', 'MM').set('year', moment().year())) ? <CheckIcon sx={{ color: "green" }} /> : <LockIcon color="disabled" />,
                        jul: moment().add(-1, 'months').isAfter(moment('07', 'MM').set('year', moment().year())) ? <CheckIcon sx={{ color: "green" }} /> : <LockIcon color="disabled" />,
                        aug: moment().add(-1, 'months').isAfter(moment('08', 'MM').set('year', moment().year())) ? <CheckIcon sx={{ color: "green" }} /> : <LockIcon color="disabled" />,
                        sep: moment().add(-1, 'months').isAfter(moment('09', 'MM').set('year', moment().year())) ? <CheckIcon sx={{ color: "green" }} /> : <LockIcon color="disabled" />,
                        oct: moment().add(-1, 'months').isAfter(moment('10', 'MM').set('year', moment().year())) ? <CheckIcon sx={{ color: "green" }} /> : <LockIcon color="disabled" />,
                        nov: moment().add(-1, 'months').isAfter(moment('11', 'MM').set('year', moment().year())) ? <CheckIcon sx={{ color: "green" }} /> : <LockIcon color="disabled" />,
                        dec: moment().add(-1, 'months').isAfter(moment('12', 'MM').set('year', moment().year())) ? <CheckIcon sx={{ color: "green" }} /> : <LockIcon color="disabled" />,
                    });
                }
            });

            setClients(clientsdata);
        });
    }

    const uploadAsyncButtons = (
        <GridUI container justifyContent="space-between" alignItems="center" spacing={2}>
            <GridUI item>
                <ColorButton
                    sx={{
                        mr: 1,
                        '&:hover': {
                            backgroundColor: 'white',
                            color: '#2f3190',
                            border: '1px solid #2f3190',
                        },
                    }}
                    color="secondary"
                    disableElevation
                    variant="outlined"
                    onClick={handleCloseDialog}
                >
                    {t("miscellaneous.cancel")}
                </ColorButton>
            </GridUI>
            <GridUI item>
                <ColorButton disableElevation variant="contained" color="secondary" disabled={(filesData.length === 0 && uploadedFiles.length === 0 && !checkStatus)
                    || (selectedBank.showCheck === 1 && !checkStatus && filesData.length === 0 && uploadedFiles.length === 0)}
                    onClick={() => setOpenAlert(true)}
                    sx={{
                        '&:hover': {
                            backgroundColor: '#2f3190',
                            border: '1px solid #2f3190',
                            color: '#fff',
                        },
                        '&.MuiButton-root.Mui-disabled': {
                            backgroundColor: '#ffcdd2 !important',
                            border: 'none',
                            color: '#fff',
                        }
                    }}
                >
                    {t('miscellaneous.save')}
                </ColorButton>
            </GridUI>
        </GridUI>
    );

    /* Component events */
    useEffect(() => {
        (async () => {
            const columnsTemplate = [
                { name: "client", title: t("miscellaneous.client") },
                { name: "comments", title: " " },
                { name: "status", title: " " },
                { name: "jan", title: t("monthNamesShort.jan") },
                { name: "feb", title: t("monthNamesShort.feb") },
                { name: "mar", title: t("monthNamesShort.mar") },
                { name: "apr", title: t("monthNamesShort.apr") },
                { name: "may", title: t("monthNamesShort.may") },
                { name: "jun", title: t("monthNamesShort.jun") },
                { name: "jul", title: t("monthNamesShort.jul") },
                { name: "aug", title: t("monthNamesShort.aug") },
                { name: "sep", title: t("monthNamesShort.sep") },
                { name: "oct", title: t("monthNamesShort.oct") },
                { name: "nov", title: t("monthNamesShort.nov") },
                { name: "dec", title: t("monthNamesShort.dec") }
            ];
            setColumnsDefs(columnsTemplate);
            setClients(rowsSkeleton);

            await loadInitData();
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t])

    return (
        <StyledContainer component="main" sx={{ flexGrow: 1, p: 4, width: '100%', pt: 10 }}>
            <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Toolbar className={classes.toolbar}>
                    <div style={{ display: 'flex' }}>
                        <GCAAutomate className={classes.navbarLogo} />
                    </div>
                    <div>
                        <LightTooltip title={t("miscellaneous.changeLng")} aria-label="changeLng">
                            <Button aria-controls="language-menu" aria-haspopup="true" onClick={handleClickLanguagePopover}
                                sx={{ p: '6px 20px' }}
                            >
                                <TranslateIcon className="icon-text-size" />
                                <Hidden only={['xs']}>
                                    <Typography variant="button" align="center" className={classes.commonWhite}>
                                        {language}
                                    </Typography>
                                </Hidden>
                                <Hidden only={['sm', 'md', 'lg', 'xl']}>
                                    <Typography variant="button" align="center" className={classes.commonWhite}>
                                        {languageMobile}
                                    </Typography>
                                </Hidden>
                            </Button>
                        </LightTooltip>
                        <Popover
                            id={id}
                            open={openPopoverLanguage}
                            anchorEl={anchorEl}
                            onClose={handleCloseLanguagePopover}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            PaperProps={{
                                sx: {
                                    p: '8px 0px',
                                    borderRadius: 5,
                                    boxShadow: '10px 10px 15px 0px rgba(3,24,81,0.15)',
                                    backgroundColor: '#F8F9F8'
                                }
                            }}
                        >
                            <ListItemButton onClick={() => { handleCloseLanguagePopover(); updateLanguage("English"); }} sx={{ pl: 4, pr: 4 }}>
                                <ListItemText primary="English" />
                            </ListItemButton>
                            <ListItemButton onClick={() => { handleCloseLanguagePopover(); updateLanguage("Español") }} sx={{ pl: 4, pr: 4 }}>
                                <ListItemText primary="Español" />
                            </ListItemButton>
                        </Popover>
                    </div>
                </Toolbar>
            </AppBar>
            <Stack sx={{ pb: 4 }}>
                <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small" sx={{ color: '#031851' }} />}
                    aria-label="breadcrumb"
                >
                    {breadcrumbs}
                </Breadcrumbs>
            </Stack>
            <GridUI container spacing={2} justifyContent="center" alignItems="center">
                <GridUI item xs={12}>
                    <GridUI
                        container
                        spacing={2}
                    >
                        <GridUI item xs={12} lg={12}>
                            <Card elevation={0}
                                sx={{
                                    borderRadius: 8,
                                    boxShadow: '10px 10px 15px rgba(3,24,81,0.15)',
                                }}
                            >
                                <CardContent classes={{ root: classes.cardContentRoot }}>
                                    <Grid
                                        rows={clientsData}
                                        columns={columnDefs}
                                    >
                                        <TreeDataState
                                            expandedRowIds={expandedRowIds}
                                            onExpandedRowIdsChange={setExpandenRowIds}
                                        />
                                        <CustomTreeData
                                            getChildRows={getChildRows}
                                        />
                                        <Table
                                            columnExtensions={tableColumnExtensions}
                                            cellComponent={TableCellComponent}
                                            rowComponent={TableRow}
                                            messages={tableMessages}
                                        />
                                        <TableHeaderRow
                                            cellComponent={tableHeaderCell}
                                            contentComponent={tableContentComponent}
                                        />
                                        <TableTreeColumn
                                            cellComponent={TableCellTreeComponent}
                                            for="client"
                                        />
                                    </Grid>
                                </CardContent>
                            </Card>
                        </GridUI>
                    </GridUI>
                </GridUI>
            </GridUI>
            <Backdrop
                open={showBackdrop}
            />
            <SnackBar
                type={alertType}
                show={show}
                message={texto}
                onClose={handleClose}
            />
            <DialogAlerts
                open={openAlert}
                onClose={() => setOpenAlert(false)}
                agreeBtnLabel={t('miscellaneous.accept')}
                disagreeBtnLabel={t('miscellaneous.cancel')}
                type={'info'}
                title={dialogText}
                agreeAction={convertBankStatement}
            />
            <CommentDialog
                open={dialogComment}
                onClose={closeDialog}
                maxWidth="xs"
                clientID={clientID}
                setTexto={setTexto}
                seAutoAlertType={setAlertType}
                setBackDrop={setBackDrop}
                setShow={setShow}
                comments={comments}
                commentsP={commentsP}
                getComments={getComments}
                setInitData={loadInitData}
                year={moment().year()}
                commentType={1}
            />
            <UploadAsync
                component="requirements"
                open={open}
                onClose={handleCloseDialog}
                filesData={filesData}
                setFilesData={setFilesData}
                filesRejected={filesRejected}
                setFilesRejected={setFilesRejected}
                fileFormat={fileFormat}
                selectedBank={selectedBank}
                selectedYear={moment().year()}
                uploadedFiles={uploadedFiles}
                loadInitData={loadInitData}
                actions={uploadAsyncButtons}
                setShow={setShow}
                setTexto={setTexto}
                setAlertType={setAlertType}
                handleChangeStatus={handleChangeStatus}
                checkStatus={checkStatus}
                showTabs={showTabs}
                buttonTabs={buttonTabs}
                handleChangeTabs={handleChangeTabs}
                filesRejectedChecks={filesRejectedChecks}
                setFilesRejectedChecks={setFilesRejectedChecks}
                filesRejectedPetty={filesRejectedPetty}
                setFilesRejectedPetty={setFilesRejectedPetty}
                filesDataChecks={filesDataChecks}
                setFilesDataChecks={setFilesDataChecks}
                filesDataPetty={filesDataPetty}
                setFilesDataPetty={setFilesDataPetty}
                checkStatusChecks={checkStatusChecks}
                checkStatusPetty={checkStatusPetty}
                page={page}
                setPage={setPage}
            />
            <UploadAsyncCustom
                component="requirements"
                open={openCustom}
                onClose={handleCloseDialogCustom}
                filesDataCustom={filesDataCustom}
                setFilesDataCustom={setFilesDataCustom}
                uploadedFilesCustom={uploadedFilesCustom}
                setUploadedFilesCustom={setUploadedFilesCustom}
                filesRejectedCustom={filesRejectedCustom}
                setFilesRejectedCustom={setFilesRejectedCustom}
                selectedYear={moment().year()}
                loadInitData={loadInitData}
                setShow={setShow}
                setTexto={setTexto}
                setAlertType={setAlertType}
                customRequirementInfo={customRequirementInfo}
                handleCloseDialogCustomDiscard={handleCloseDialogCustomDiscard}
                uploadedFilesCustomSizes={uploadedFilesCustomSizes}
            />
        </StyledContainer>
    );
}

export default ClientFilesMana;
