/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

/** MUI imports */
import {
    Grid,
    Table,
    TableHeaderRow,
    PagingPanel,
    Toolbar,
    SearchPanel,
    TableColumnVisibility,
} from "@devexpress/dx-react-grid-material-ui";
import { SearchState, IntegratedFiltering, PagingState, IntegratedPaging } from "@devexpress/dx-react-grid";
import { styled } from "@mui/material/styles";
import GridUI from "@mui/material/Grid";
import { Box, Skeleton } from "@mui/material";

const PREFIX = "Validations";

const classes = {
    paper: `${PREFIX}-paper`,
    tableStriped: `${PREFIX}-tableStriped`,
    tableHeader: `${PREFIX}-tableHeader`,
    tableBody: `${PREFIX}-tableBody`,
    pager: `${PREFIX}-pager`,
    cardContentRoot: `${PREFIX}-cardContentRoot`,
    cardContentRoot2: `${PREFIX}-cardContentRoot2`,
    cardHeaderRoot: `${PREFIX}-cardHeaderRoot`,
    formRounded: `${PREFIX}-formRounded`,
    button: `${PREFIX}-button`,
    spacing: `${PREFIX}-spacing`,
    radio: `${PREFIX}-radio`,
    toolbarSearch: `${PREFIX}-toolbarSearch`,
    searchInput: `${PREFIX}-searchInput`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.paper}`]: {
        border: "2px solid #ff5968",
        borderRadius: 40,
        boxShadow: "none",
    },

    [`& .${classes.radio}`]: {
        [theme.breakpoints.down("sm")]: {
            padding: 9,
        },
        [theme.breakpoints.between("md", "lg")]: {
            padding: 0,
        },
        [theme.breakpoints.between("lg", "xl")]: {
            padding: 6,
        },
    },

    [`& .${classes.cardContentRoot}`]: {
        "&:last-child": {
            paddingBottom: "5px",
        },
        padding: "10px",
    },

    [`& .${classes.cardContentRoot2}`]: {
        "&:last-child": {
            paddingBottom: "18px",
        },
        padding: 30,
    },

    [`& .${classes.cardHeaderRoot}`]: {
        padding: 30,
    },

    [`& .${classes.formRounded}`]: {
        backgroundColor: "#FFFFFF !important",
        borderRadius: 40,
        verticalAlign: "middle !important",
        "&:focus-within": {
            backgroundColor: "#FFFFFF",
            borderRadius: 40,
        },
        "& .MuiOutlinedInput-notchedOutline": {
            border: "2px solid #031851",
            borderRadius: 40,
        },
    },

    [`& .${classes.button}`]: {
        borderRadius: 40,
        width: "100%",
        "&:hover": {
            backgroundColor: theme.palette.text.secondary,
            color: "#fff",
        },
    },

    [`& .${classes.spacing}`]: {
        padding: "30px 0 20px 0",
    },

    [`& .${classes.tableStriped}`]: {
        "& tbody tr:nth-of-type(odd)": {
            backgroundColor: "rgba(47,49,144,0.1)",
        },
    },
    [`& .${classes.tableHeader}`]: {
        border: "none",
        backgroundColor: "rgba(47,49,144,0.3)",
        fontSize: "15px",
        fontWeight: 600,
        color: "#031851",
        "&:first-of-type": {
            borderRadius: "20px 0 0 0",
        },
        "&:last-child": {
            borderRadius: "0 20px 0 0",
        },
    },

    [`& .${classes.tableBody}`]: {
        border: "none",
        fontSize: "14px",
        fontWeight: 400,
        color: "#031851",
    },

    [`& .${classes.pager}`]: {
        "& .Pagination-button": {
            paddingTop: 0,
            paddingBottom: 0,
            color: "#031851",
            fontWeight: 600,
        },
        "& .Pagination-activeButton": {
            backgroundColor: "rgba(47,49,144,0.3)",
        },
    },

    [`& .${classes.toolbarSearch}`]: {
        border: "none !important",
        padding: "0 0 5px !important",
    },

    [`& .${classes.searchInput}`]: {
        color: "#031851",
        "&:before": {
            borderBottomColor: "#031851",
        },
        "&:hover:before": {
            borderBottomColor: "#031851 !important",
        },
    },
}));

const tableHeaderCell = ({ ...props }) => <TableHeaderRow.Cell {...props} className={classes.tableHeader} />;

const TableComponent = ({ ...props }) => <Table.Table {...props} className={classes.tableStriped} />;

const TableCellComponent = ({ ...props }) => <Table.Cell {...props} className={classes.tableBody} />;

const tableContentComponent = (props) => <TableHeaderRow.Content {...props} />;

const PagingPanelContainer = ({ ...props }) => <PagingPanel.Container {...props} className={classes.pager} />;

const inputComponent = ({ ...props }) => <SearchPanel.Input {...props} className={classes.searchInput} />;

const toolRootComponent = ({ ...props }) => <Toolbar.Root {...props} className={classes.toolbarSearch} />;

const tableColumnExtensions = [
    { columnName: "client", align: "left" },
    { columnName: "validated", align: "center" },
    { columnName: "buttonClear", align: "center", width: "5%" },
    { columnName: "comments", align: "center", width: "5%" },
    { columnName: "jan", align: "center", width: "5%" },
    { columnName: "feb", align: "center", width: "5%" },
    { columnName: "mar", align: "center", width: "5%" },
    { columnName: "apr", align: "center", width: "5%" },
    { columnName: "may", align: "center", width: "5%" },
    { columnName: "jun", align: "center", width: "5%" },
    { columnName: "jul", align: "center", width: "5%" },
    { columnName: "aug", align: "center", width: "5%" },
    { columnName: "sep", align: "center", width: "5%" },
    { columnName: "oct", align: "center", width: "5%" },
    { columnName: "nov", align: "center", width: "5%" },
    { columnName: "dec", align: "center", width: "5%" },
];

const rowsSkeleton = [];

for (let i = 0; i < 10; i++) {
    rowsSkeleton.push({
        id: i,
        client: <Skeleton variant="text" animation="wave" />,
        buttonClear: (
            <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />
        ),
        comments: (
            <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />
        ),
        validated: <Skeleton variant="text" animation="wave" />,
        jan: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        feb: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        mar: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        apr: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        may: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        jun: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        jul: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        aug: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        sep: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        oct: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        nov: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        dec: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
    });
}

const ValidationsSkeletons = (props) => {
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    const tableMessages = {
        noData: t("miscellaneous.noData"),
    };
    const pagingMessages = {
        info: ({ from, to, count }) => `${from}${from < to ? `-${to}` : ""} ${t("miscellaneous.pagingOf")} ${count}`,
    };

    const searchMessages = {
        searchPlaceholder: t("miscellaneous.search"),
    };

    /** Component states */
    const [clientsData, setClients] = useState([]);
    const [columnDefs, setColumnsDefs] = useState([]);
    const [defaultHiddenColumnNames] = useState(["hiddenCompany"]);

    useEffect(() => {
        setClients(rowsSkeleton);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        (async () => {
            const columnsTemplate = [
                { name: "hiddenCompany", title: "Hidden Companies" },
                { name: "client", title: t("miscellaneous.client") },
                { name: "buttonClear", title: " " },
                { name: "comments", title: " " },
                { name: "validated", title: t("recurrent.validated") },
                { name: "jan", title: t("monthNamesShort.jan") },
                { name: "feb", title: t("monthNamesShort.feb") },
                { name: "mar", title: t("monthNamesShort.mar") },
                { name: "apr", title: t("monthNamesShort.apr") },
                { name: "may", title: t("monthNamesShort.may") },
                { name: "jun", title: t("monthNamesShort.jun") },
                { name: "jul", title: t("monthNamesShort.jul") },
                { name: "aug", title: t("monthNamesShort.aug") },
                { name: "sep", title: t("monthNamesShort.sep") },
                { name: "oct", title: t("monthNamesShort.oct") },
                { name: "nov", title: t("monthNamesShort.nov") },
                { name: "dec", title: t("monthNamesShort.dec") },
            ];
            setColumnsDefs(columnsTemplate);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t]);

    return (
        <StyledContainer component="main" sx={{ flexGrow: 1, width: "100%" }}>
            <GridUI container spacing={2} className={classes.spacing} alignItems="center">
                <GridUI item xs={12} md>
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width="100%"
                        height={40}
                        sx={{ borderRadius: 8 }}
                    />
                </GridUI>
                <GridUI item xs={12} md>
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width="100%"
                        height={40}
                        sx={{ borderRadius: 8 }}
                    />
                </GridUI>
                <GridUI item xs={12} md={2}>
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width="100%"
                        height={40}
                        sx={{ borderRadius: 8 }}
                    />
                </GridUI>
                <GridUI item xs={12} md={2}>
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width="100%"
                        height={40}
                        sx={{ borderRadius: 8 }}
                    />
                </GridUI>
                <GridUI item xs={12} md={2}>
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width="100%"
                        height={40}
                        sx={{ borderRadius: 8 }}
                    />
                </GridUI>
                <GridUI item xs={12} md>
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width="100%"
                        height={40}
                        sx={{ borderRadius: 8 }}
                    />
                </GridUI>
                <GridUI item xs={12} md>
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width="100%"
                        height={40}
                        sx={{ borderRadius: 8 }}
                    />
                </GridUI>
                <GridUI item xs={12} md>
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width="100%"
                        height={40}
                        sx={{ borderRadius: 8 }}
                    />
                </GridUI>
            </GridUI>
            <GridUI container>
                <GridUI item xs={12} md={6} sx={{ mb: { sm: -5 }, zIndex: 1 }}>
                    <GridUI item xs={12} md={2}>
                        <Skeleton
                            variant="rectangular"
                            animation="wave"
                            width="100%"
                            height={40}
                            sx={{ borderRadius: 8 }}
                        />
                    </GridUI>
                </GridUI>
                <GridUI item xs={12}>
                    <Grid rows={clientsData} columns={columnDefs}>
                        <SearchState />
                        <IntegratedFiltering />
                        <PagingState defaultCurrentPage={0} pageSize={10} />
                        <IntegratedPaging />
                        <Table
                            tableComponent={TableComponent}
                            columnExtensions={tableColumnExtensions}
                            cellComponent={TableCellComponent}
                            messages={tableMessages}
                        />
                        <TableColumnVisibility defaultHiddenColumnNames={defaultHiddenColumnNames} />
                        <TableHeaderRow cellComponent={tableHeaderCell} contentComponent={tableContentComponent} />
                        <Toolbar rootComponent={toolRootComponent} />
                        <SearchPanel messages={searchMessages} inputComponent={inputComponent} />
                        <PagingPanel containerComponent={PagingPanelContainer} messages={pagingMessages} />
                    </Grid>
                </GridUI>
            </GridUI>
        </StyledContainer>
    );
};

export default ValidationsSkeletons;
