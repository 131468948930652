// React
import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { NumericFormat } from "react-number-format";

// MUI
import {
    Box,
    TextField,
    Grid,
    Divider,
    Card,
    CardHeader,
    CardContent,
    Button,
    FormControl,
    InputLabel,
    OutlinedInput,
    InputAdornment,
    FormControlLabel,
    Checkbox,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

const PREFIX = "UpdateAdd";

const classes = {
    btnRoot: `${PREFIX}-btnRoot`,
    buttonContainer: `${PREFIX}-buttonContainer`,
    switch: `${PREFIX}-switch`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.btnRoot}`]: {
        border: "1px solid #ff5968",
        borderRadius: 40,
        "&:hover": {
            backgroundColor: "#2f3190",
            border: "1px solid #2f3190",
        },
        "&.Mui-disabled": {
            color: "#fff",
            borderColor: "#ffcdd2",
            background: "#ffcdd2",
        },
    },
    [`& .${classes.buttonContainer}`]: {
        display: "flex",
        justifyContent: "flex-end",
        padding: 15,
    },
    [`& .${classes.switch}`]: {
        "& .MuiSwitch-switchBase .MuiSwitch-thumb": {
            color: "#FFF",
        },
        "& .MuiSwitch-switchBase + .MuiSwitch-track": {
            backgroundColor: "#031851",
        },
        "& .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb": {
            color: "#FF5968",
        },
        "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
            backgroundColor: "#FFA8B1",
        },
    },
}));

const NumberFormatCustom = (props) => {
    const todos = useSelector((state) => state.todos);

    const { inputRef, value, onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            value={value}
            getInputRef={inputRef}
            onValueChange={({ value: v }) => onChange({ target: { value: v } })}
            thousandSeparator={todos.amountFormat === "de-DE" ? "." : ","}
            decimalSeparator={todos.amountFormat === "de-DE" ? "," : "."}
            fixedDecimalScale={true}
            decimalScale={2}
            valueIsNumericString
        />
    );
};

export const UpdateAdd = (props) => {
    // Properties
    const {
        values,
        set,
        setTexto,
        setalertType,
        setShow,
        isError,
        updateProject,
        sumTotal,
        controlSystem,
        expenseEst,
        showConfirm,
    } = props;

    /** Internationalization i18n */
    const [t] = useTranslation("global");

    /** React redux */
    const todos = useSelector((state) => state.todos);

    /** Component functions */
    const handleChange = (event) => {
        set({
            ...values,
            [event.target.name]: event.target.value,
        });
    };

    const handleChangeHoursController = (event) => {
        let total =
            values.addHourFee *
            parseFloat(event.target.value) *
            (isNaN(moment(values.finishDateController).diff(moment(values.startDateController)))
                ? 0
                : Math.ceil(
                      moment(values.finishDateController).diff(moment(values.startDateController), "months", true)
                  ));
        set({
            ...values,
            [event.target.name]: event.target.value,
            projectFee: total,
            projecTot: total * (1 - values.discount / 100),
            baseFee: values.addHourFee * parseFloat(event.target.value)
        });
    };

    const handleChangeCheck = (event, value) => {
        switch (value) {
            case 0:
                if (values.checkedConOG && !event.target.checked && !todos.remind) {
                    showConfirm({
                        check: "checkedCon",
                        toth: "tothCon",
                        startDate: "startDateCon",
                        finishDate: "finishDateCon",
                    });
                } else {
                    set({
                        ...values,
                        checkedCon: event.target.checked,
                        tothCon: 0,
                        startDateCon: event.target.checked ? values.initDate : null,
                        finishDateCon: event.target.checked ? values.endDate : null,
                    });
                }
                break;
            case 1:
                if (values.checkedConOG && !event.target.checked && !todos.remind) {
                    showConfirm({
                        check: "checkedExe",
                        toth: "tothExe",
                        startDate: "startDateExe",
                        finishDate: "finishDateExe",
                    });
                } else {
                    set({
                        ...values,
                        checkedExe: event.target.checked,
                        tothExe: 0,
                        startDateExe: event.target.checked ? values.initDate : null,
                        finishDateExe: event.target.checked ? values.endDate : null,
                    });
                }
                break;
            case 2:
                if (values.checkedRepOG && !event.target.checked && !todos.remind) {
                    showConfirm({
                        check: "checkedRep",
                        toth: "tothRep",
                        startDate: "startDateRep",
                        finishDate: "finishDateRep",
                    });
                } else {
                    set({
                        ...values,
                        checkedRep: event.target.checked,
                        tothRep: 0,
                        startDateRep: event.target.checked ? values.initDate : null,
                        finishDateRep: event.target.checked ? values.endDate : null,
                    });
                }
                break;
            case 3:
                if (values.checkedOthersOG && !event.target.checked && !todos.remind) {
                    showConfirm({
                        check: "checkedOthers",
                        nameOthers: "nameOthers",
                        toth: "tothOthers",
                        startDate: "startDateOthers",
                        finishDate: "finishDateOthers",
                    });
                } else {
                    set({
                        ...values,
                        checkedOthers: event.target.checked,
                        nameOthers: "",
                        tothOthers: 0,
                        startDateOthers: event.target.checked ? values.initDate : null,
                        finishDateOthers: event.target.checked ? values.endDate : null,
                    });
                }
                break;
            default:
                set({
                    ...values,
                    checkedController: event.target.checked,
                    tothController: 0,
                    startDateController: event.target.checked ? values.initDate : null,
                    finishDateController: event.target.checked ? values.endDate : null,
                    projectFee: 0,
                    projecTot: 0,
                });
                break;
        }
    };

    const handleChangeStartDateAdd = (date, val) => {
        let startDate = moment(date).startOf("month");
        switch (val) {
            case 0:
                if (!date || !values.finishDateCon) {
                    set({
                        ...values,
                        startDateCon: date ? startDate : date,
                    });
                } else {
                    if (startDate.isAfter(moment(values.finishDateCon))) {
                        setTexto(t("reports.initDateMess"));
                        setalertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            startDateCon: startDate,
                        });
                    }
                }
                break;
            case 1:
                if (!date || !values.finishDateExe) {
                    set({
                        ...values,
                        startDateExe: date ? startDate : date,
                    });
                } else {
                    if (startDate.isAfter(moment(values.finishDateExe))) {
                        setTexto(t("reports.initDateMess"));
                        setalertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            startDateExe: startDate,
                        });
                    }
                }
                break;
            case 2:
                if (!date || !values.finishDateRep) {
                    set({
                        ...values,
                        startDateRep: date ? startDate : date,
                    });
                } else {
                    if (startDate.isAfter(moment(values.finishDateRep))) {
                        setTexto(t("reports.initDateMess"));
                        setalertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            startDateRep: startDate,
                        });
                    }
                }
                break;
            case 3:
                if (!date || !values.finishDateOthers) {
                    set({
                        ...values,
                        startDateOthers: date ? startDate : date,
                    });
                } else {
                    if (startDate.isAfter(moment(values.finishDateOthers))) {
                        setTexto(t("reports.initDateMess"));
                        setalertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            startDateOthers: startDate,
                        });
                    }
                }
                break;
            default:
                let total =
                    values.addHourFee *
                    parseFloat(values.tothController) *
                    (isNaN(moment(values.finishDateController).diff(startDate))
                        ? 0
                        : Math.ceil(moment(values.finishDateController).diff(startDate, "months", true)));

                if (!date || !values.finishDateController) {
                    set({
                        ...values,
                        startDateController: date ? startDate : date,
                        months: Math.ceil(moment(values.finishDateController).diff(startDate, "months", true)),
                        projectFee: total,
                        projecTot: total * (1 - values.discount / 100),
                    });
                } else {
                    if (startDate.isAfter(moment(values.finishDateController))) {
                        setTexto(t("reports.initDateMess"));
                        setalertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            startDateController: startDate,
                            months: Math.ceil(moment(values.finishDateController).diff(startDate, "months", true)),
                            projectFee: total,
                            projecTot: total * (1 - values.discount / 100),
                        });
                    }
                }
                break;
        }
    };

    const handleChangeEndDateAdd = (date, val) => {
        let endDate = moment(date).endOf("month").startOf("day");
        switch (val) {
            case 0:
                if (!date || !values.startDateCon) {
                    set({
                        ...values,
                        finishDateCon: date ? endDate : date,
                    });
                } else {
                    if (endDate.isBefore(moment(values.startDateCon))) {
                        setTexto(t("reports.endDateMess"));
                        setalertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            finishDateCon: endDate,
                        });
                    }
                }
                break;
            case 1:
                if (!date || !values.startDateExe) {
                    set({
                        ...values,
                        finishDateExe: date ? endDate : date,
                    });
                } else {
                    if (endDate.isBefore(moment(values.startDateExe))) {
                        setTexto(t("reports.endDateMess"));
                        setalertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            finishDateExe: endDate,
                        });
                    }
                }
                break;
            case 2:
                if (!date || !values.startDateRep) {
                    set({
                        ...values,
                        finishDateRep: date ? endDate : date,
                    });
                } else {
                    if (endDate.isBefore(moment(values.startDateRep))) {
                        setTexto(t("reports.endDateMess"));
                        setalertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            finishDateRep: endDate,
                        });
                    }
                }
                break;
            case 3:
                if (!date || !values.startDateOthers) {
                    set({
                        ...values,
                        finishDateOthers: date ? endDate : date,
                    });
                } else {
                    if (endDate.isBefore(moment(values.startDateOthers))) {
                        setTexto(t("reports.endDateMess"));
                        setalertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            finishDateOthers: endDate,
                        });
                    }
                }
                break;
            default:
                let total =
                    values.addHourFee *
                    parseFloat(values.tothController) *
                    (isNaN(endDate.diff(moment(values.startDateController)))
                        ? 0
                        : Math.ceil(endDate.diff(moment(values.startDateController), "months", true)));

                if (!date || !values.startDateController) {
                    set({
                        ...values,
                        finishDateController: date ? endDate : date,
                        months: Math.ceil(endDate.diff(moment(values.startDateController), "months", true)),
                        projectFee: total,
                        projecTot: total * (1 - values.discount / 100),
                    });
                } else {
                    if (endDate.isBefore(moment(values.startDateController))) {
                        setTexto(t("reports.endDateMess"));
                        setalertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            finishDateController: endDate,
                            months: Math.ceil(endDate.diff(moment(values.startDateController), "months", true)),
                            projectFee: total,
                            projecTot: total * (1 - values.discount / 100),
                        });
                    }
                }
                break;
        }
    };

    return (
        <StyledContainer>
            <LocalizationProvider
                dateAdapter={AdapterMoment}
                adapterLocale={t("language.locale")}
                localeText={{ clearButtonLabel: t("miscellaneous.clear") }}
            >
                <Card elevation={0}>
                    <CardHeader title={t("services.additionals")} sx={{ color: "#ff5968" }} />
                    <Divider />
                    <CardContent sx={{ pb: 5 }}>
                        <Grid container spacing={2} alignItems="center">
                            {values.checkHour && (
                                <>
                                    <Grid item xs={12} md={3}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    name="checkedController"
                                                    checked={values.checkedController}
                                                    onChange={() => {
                                                        return false;
                                                    }}
                                                    inputProps={{
                                                        "aria-label": "controlled",
                                                    }}
                                                />
                                            }
                                            label="Controller"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            fullWidth
                                            disabled={values.updateBoolean || !values.initDate || !values.endDate}
                                            label={t("miscellaneous.hoursMonth")}
                                            name="tothController"
                                            onChange={handleChangeHoursController}
                                            value={values.tothController}
                                            variant="outlined"
                                            type="number"
                                            inputProps={{
                                                min: 0,
                                                step: 0.5,
                                                onKeyDown: (event) => {
                                                    event.preventDefault();
                                                },
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            error={values.checkedController && isError && values.tothController === 0}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <DatePicker
                                            componentsProps={{
                                                actionBar: {
                                                    actions: ["clear"],
                                                },
                                            }}
                                            disabled={values.updateBoolean || !values.initDate || !values.endDate}
                                            value={values.startDateController}
                                            minDate={moment(values.initDate).startOf("month")}
                                            maxDate={moment(values.endDate).endOf("month")}
                                            onChange={(newValue) => handleChangeStartDateAdd(newValue, 4)}
                                            inputFormat={todos.dateFormat}
                                            renderInput={(params) => (
                                                <TextField
                                                    fullWidth
                                                    required={values.checkedController}
                                                    {...params}
                                                    error={
                                                        values.checkedController &&
                                                        isError &&
                                                        !values.startDateController
                                                    }
                                                />
                                            )}
                                            inputProps={{ readOnly: true }}
                                            label={t("reports.initDate")}
                                            views={["year", "month"]}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <DatePicker
                                            componentsProps={{
                                                actionBar: {
                                                    actions: ["clear"],
                                                },
                                            }}
                                            disabled={values.updateBoolean || !values.initDate || !values.endDate}
                                            value={values.finishDateController}
                                            minDate={moment(values.initDate).startOf("month")}
                                            maxDate={moment(values.endDate).endOf("month")}
                                            onChange={(newValue) => handleChangeEndDateAdd(newValue, 4)}
                                            inputFormat={todos.dateFormat}
                                            renderInput={(params) => (
                                                <TextField
                                                    fullWidth
                                                    required={values.checkedController}
                                                    {...params}
                                                    error={
                                                        values.checkedController &&
                                                        isError &&
                                                        !values.finishDateController
                                                    }
                                                />
                                            )}
                                            inputProps={{ readOnly: true }}
                                            label={t("reports.endDate")}
                                            views={["year", "month"]}
                                        />
                                    </Grid>
                                </>
                            )}
                            <Grid item xs={12} md={3}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="checkedCon"
                                            checked={values.checkedCon}
                                            onChange={(value) => handleChangeCheck(value, 0)}
                                            inputProps={{
                                                "aria-label": "controlled",
                                            }}
                                            disabled={values.updateBoolean || !values.initDate || !values.endDate}
                                        />
                                    }
                                    label={t("services.consolidationStatements")}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    fullWidth
                                    disabled={values.updateBoolean || !values.checkedCon}
                                    label={t("miscellaneous.hoursMonth")}
                                    name="tothCon"
                                    onChange={handleChange}
                                    value={values.tothCon}
                                    variant="outlined"
                                    type="number"
                                    inputProps={{
                                        min: 0,
                                        step: 0.5,
                                        onKeyDown: (event) => {
                                            event.preventDefault();
                                        },
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    error={values.checkedCon && isError && values.tothCon === 0}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    disabled={values.updateBoolean || !values.checkedCon}
                                    value={values.startDateCon}
                                    onChange={(newValue) => handleChangeStartDateAdd(newValue, 0)}
                                    minDate={moment(values.initDate).startOf("month")}
                                    maxDate={moment(values.endDate).endOf("month")}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            required={values.checkedCon}
                                            {...params}
                                            error={values.checkedCon && isError && !values.startDateCon}
                                        />
                                    )}
                                    inputProps={{ readOnly: true }}
                                    label={t("reports.initDate")}
                                    views={["year", "month"]}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    disabled={values.updateBoolean || !values.checkedCon}
                                    value={values.finishDateCon}
                                    onChange={(newValue) => handleChangeEndDateAdd(newValue, 0)}
                                    minDate={moment(values.initDate).startOf("month")}
                                    maxDate={moment(values.endDate).endOf("month")}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            required={values.checkedCon}
                                            {...params}
                                            error={values.checkedCon && isError && !values.finishDateCon}
                                        />
                                    )}
                                    inputProps={{ readOnly: true }}
                                    label={t("reports.endDate")}
                                    views={["year", "month"]}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="checkedExe"
                                            checked={values.checkedExe}
                                            onChange={(value) => handleChangeCheck(value, 1)}
                                            inputProps={{
                                                "aria-label": "controlled",
                                            }}
                                            disabled={values.updateBoolean || !values.initDate || !values.endDate}
                                        />
                                    }
                                    label={t("services.executionTaxPlan")}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    fullWidth
                                    disabled={values.updateBoolean || !values.checkedExe}
                                    label={t("miscellaneous.hoursMonth")}
                                    name="tothExe"
                                    onChange={handleChange}
                                    value={values.tothExe}
                                    variant="outlined"
                                    type="number"
                                    inputProps={{
                                        min: 0,
                                        step: 0.5,
                                        onKeyDown: (event) => {
                                            event.preventDefault();
                                        },
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    error={values.checkedExe && isError && values.tothExe === 0}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    disabled={values.updateBoolean || !values.checkedExe}
                                    value={values.startDateExe}
                                    onChange={(newValue) => handleChangeStartDateAdd(newValue, 1)}
                                    minDate={moment(values.initDate).startOf("month")}
                                    maxDate={moment(values.endDate).endOf("month")}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            required={values.checkedExe}
                                            {...params}
                                            error={values.checkedExe && isError && !values.startDateExe}
                                        />
                                    )}
                                    inputProps={{ readOnly: true }}
                                    label={t("reports.initDate")}
                                    views={["year", "month"]}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    disabled={values.updateBoolean || !values.checkedExe}
                                    value={values.finishDateExe}
                                    onChange={(newValue) => handleChangeEndDateAdd(newValue, 1)}
                                    minDate={moment(values.initDate).startOf("month")}
                                    maxDate={moment(values.endDate).endOf("month")}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            required={values.checkedExe}
                                            {...params}
                                            error={values.checkedExe && isError && !values.finishDateExe}
                                        />
                                    )}
                                    inputProps={{ readOnly: true }}
                                    label={t("reports.endDate")}
                                    views={["year", "month"]}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="checkedRep"
                                            checked={values.checkedRep}
                                            onChange={(value) => handleChangeCheck(value, 2)}
                                            inputProps={{
                                                "aria-label": "controlled",
                                            }}
                                            disabled={values.updateBoolean || !values.initDate || !values.endDate}
                                        />
                                    }
                                    label={t("services.reportsAuditors")}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    fullWidth
                                    disabled={values.updateBoolean || !values.checkedRep}
                                    label={t("miscellaneous.hoursMonth")}
                                    name="tothRep"
                                    onChange={handleChange}
                                    value={values.tothRep}
                                    variant="outlined"
                                    type="number"
                                    inputProps={{
                                        min: 0,
                                        step: 0.5,
                                        onKeyDown: (event) => {
                                            event.preventDefault();
                                        },
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    error={values.checkedRep && isError && values.tothRep === 0}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    disabled={values.updateBoolean || !values.checkedRep}
                                    value={values.startDateRep}
                                    onChange={(newValue) => handleChangeStartDateAdd(newValue, 2)}
                                    minDate={moment(values.initDate).startOf("month")}
                                    maxDate={moment(values.endDate).endOf("month")}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            required={values.checkedRep}
                                            {...params}
                                            error={values.checkedRep && isError && !values.startDateRep}
                                        />
                                    )}
                                    inputProps={{ readOnly: true }}
                                    label={t("reports.initDate")}
                                    views={["year", "month"]}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    disabled={values.updateBoolean || !values.checkedRep}
                                    value={values.finishDateRep}
                                    onChange={(newValue) => handleChangeEndDateAdd(newValue, 2)}
                                    minDate={moment(values.initDate).startOf("month")}
                                    maxDate={moment(values.endDate).endOf("month")}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            required={values.checkedRep}
                                            {...params}
                                            error={values.checkedRep && isError && !values.finishDateRep}
                                        />
                                    )}
                                    inputProps={{ readOnly: true }}
                                    label={t("reports.endDate")}
                                    views={["year", "month"]}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={12} md={3} xl={2} sx={{ paddingLeft: "5px !important" }}>
                                        <Checkbox
                                            name="checkedOthers"
                                            checked={values.checkedOthers}
                                            onChange={(value) => handleChangeCheck(value, 3)}
                                            inputProps={{
                                                "aria-label": "controlled",
                                            }}
                                            disabled={values.updateBoolean || !values.initDate || !values.endDate}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={9} xl={10}>
                                        <TextField
                                            fullWidth
                                            disabled={values.updateBoolean || !values.checkedOthers}
                                            label={t("services.others")}
                                            name="nameOthers"
                                            onChange={handleChange}
                                            value={values.nameOthers}
                                            variant="outlined"
                                            error={values.checkedOthers && isError && !values.nameOthers}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    fullWidth
                                    disabled={values.updateBoolean || !values.checkedOthers}
                                    label={t("miscellaneous.hoursMonth")}
                                    name="tothOthers"
                                    onChange={handleChange}
                                    value={values.tothOthers}
                                    variant="outlined"
                                    type="number"
                                    inputProps={{
                                        min: 0,
                                        step: 0.5,
                                        onKeyDown: (event) => {
                                            event.preventDefault();
                                        },
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    error={values.checkedOthers && isError && values.tothOthers === 0}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    disabled={values.updateBoolean || !values.checkedOthers}
                                    value={values.startDateOthers}
                                    onChange={(newValue) => handleChangeStartDateAdd(newValue, 3)}
                                    minDate={moment(values.initDate).startOf("month")}
                                    maxDate={moment(values.endDate).endOf("month")}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            required={values.checkedOthers}
                                            {...params}
                                            error={values.checkedOthers && isError && !values.startDateOthers}
                                        />
                                    )}
                                    inputProps={{ readOnly: true }}
                                    label={t("reports.initDate")}
                                    views={["year", "month"]}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    disabled={values.updateBoolean || !values.checkedOthers}
                                    value={values.finishDateOthers}
                                    onChange={(newValue) => handleChangeEndDateAdd(newValue, 3)}
                                    minDate={moment(values.initDate).startOf("month")}
                                    maxDate={moment(values.endDate).endOf("month")}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            required={values.checkedOthers}
                                            {...params}
                                            error={values.checkedOthers && isError && !values.finishDateOthers}
                                        />
                                    )}
                                    inputProps={{ readOnly: true }}
                                    label={t("reports.endDate")}
                                    views={["year", "month"]}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}></Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    fullWidth
                                    disabled
                                    label={t("services.hoursTotal")}
                                    value={sumTotal}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormControl fullWidth variant="outlined" disabled>
                                    <InputLabel htmlFor="outlined-adornment-hourlyRate">
                                        {t("services.hourlyRate")}
                                    </InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-hourlyRate"
                                        value={values.addHourFee}
                                        inputComponent={NumberFormatCustom}
                                        startAdornment={
                                            <InputAdornment disableTypography position="start">
                                                $
                                            </InputAdornment>
                                        }
                                        label={t("services.hourlyRate")}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormControl fullWidth variant="outlined" disabled>
                                    <InputLabel htmlFor="outlined-adornment-hourlyRateAdd">
                                        {t("services.hourlyRateAdd")}
                                    </InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-hourlyRateAdd"
                                        value={values.addHourFee * sumTotal}
                                        inputComponent={NumberFormatCustom}
                                        startAdornment={
                                            <InputAdornment disableTypography position="start">
                                                $
                                            </InputAdornment>
                                        }
                                        label={t("services.hourlyRateAdd")}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <Divider />
                    <Box className={classes.buttonContainer}>
                        <Button
                            color="secondary"
                            variant="contained"
                            disableElevation
                            classes={{ root: classes.btnRoot }}
                            onClick={updateProject}
                            disabled={
                                expenseEst === -1 ||
                                (controlSystem.disabledData === true &&
                                    (controlSystem.prov === "Xero" || controlSystem.prov === "Quickbooks Online"))
                            }
                        >
                            {t("miscellaneous.save")}
                        </Button>
                    </Box>
                </Card>
            </LocalizationProvider>
        </StyledContainer>
    );
};
