/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

/** Icons/images */
import { ReactComponent as GCALogo } from "../assets/images/gca-logo.svg";
import { ReactComponent as TranslateIcon } from "../assets/images/translate-icon.svg";

/** MUI icons */
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

/** MUI imports */
import { styled } from "@mui/material/styles";
import {
    AppBar,
    Button,
    Card,
    CardHeader,
    CardContent,
    CardActions,
    FormControl,
    Hidden,
    IconButton,
    InputAdornment,
    InputLabel,
    Grid,
    ListItemButton,
    ListItemText,
    OutlinedInput,
    Popover,
    Toolbar,
    Typography,
    TextField,
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

/** Components imports */
import Backdrop from "./internos/Backdrop";
import DialogAlerts from "./internos/DialogAlerts";
import SnackBar from "./internos/SnackBar";
import Cookies from "js-cookie";
import authService from "../services/auth";

/** Global variables */
const params = new URL(document.location).searchParams;
let user_email = params.get("user_email");

const PREFIX = "Login";

const classes = {
    toolbar: `${PREFIX}-toolbar`,
    gcaLogo: `${PREFIX}-gcaLogo`,
    commonWhite: `${PREFIX}-commonWhite`,
    loginSubtitle: `${PREFIX}-loginSubtitle`,
    loginContainer: `${PREFIX}-loginContainer`,
    centeredContainer: `${PREFIX}-centeredContainer`,
    formFieldInput: `${PREFIX}-formFieldInput`,
    input: `${PREFIX}-input`,
    inputError: `${PREFIX}-inputError`,
    btnRoot: `${PREFIX}-btnRoot`,
    btnBackLogin: `${PREFIX}-btnBackLogin`,
    title: `${PREFIX}-title`,
    spacing: `${PREFIX}-spacing`,
    spacing2: `${PREFIX}-spacing2`,
    spacing3: `${PREFIX}-spacing3`,
};

const Root = styled("div")(({ theme }) => ({
    [`& .${classes.toolbar}`]: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "#031851",
    },

    [`& .${classes.gcaLogo}`]: {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom: "30px",
        width: 200,
    },

    [`& .${classes.commonWhite}`]: {
        color: "#fff",
    },

    [`& .${classes.loginSubtitle}`]: {
        marginBottom: 20,
        fontSize: 20,
    },

    [`& .${classes.centeredContainer}`]: {
        maxWidth: "480px",
        margin: "0 auto",
        padding: 30,
    },

    [`& .${classes.formFieldInput}`]: {
        marginBottom: 30,
        height: 40,
    },

    [`& .${classes.input}`]: {
        "&::placeholder": {
            color: theme.palette.text.primary,
            opacity: 1,
        },
    },

    [`& .${classes.inputError}`]: {
        borderRadius: 50,
        backgroundColor: "#FFDEE1",
        "&::placeholder": {
            color: theme.palette.secondary.main,
            opacity: 1,
        },
    },

    [`& .${classes.btnRoot}`]: {
        borderRadius: 50,
        marginTop: 30,
        "&:hover": {
            backgroundColor: "#fff",
            color: theme.palette.primary.main,
        },
    },

    [`& .${classes.btnBackLogin}`]: {
        paddingTop: 15,
        cursor: "pointer",
        color: theme.palette.secondary.main,
        "&:hover": {
            color: "#fff",
        },
    },

    [`& .${classes.loginContainer}`]: {
        height: "95%",
    },

    [`& .${classes.title}`]: {
        fontSize: 25,
    },

    [`& .${classes.spacing}`]: {
        paddingTop: 8,
        paddingRight: 15,
        cursor: "pointer",
        color: theme.palette.secondary.main,
        "&:hover": {
            color: "#fff",
        },
    },

    [`& .${classes.spacing2}`]: {
        paddingTop: 8,
        paddingBottom: 20,
        paddingLeft: 15,
        paddingRight: 15,
    },

    [`& .${classes.spacing3}`]: {
        paddingTop: 8,
        paddingLeft: 10,
        cursor: "pointer",
        color: theme.palette.secondary.main,
        "&:hover": {
            color: "#fff",
        },
    },
}));

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "rgba(19, 31, 62, 0.80)",
        fontSize: 12,
        maxWidth: 320,
        borderRadius: 6,
        fontWeight: "normal",
    },
});

const CustomTextField = styled(TextField)({
    "& .MuiOutlinedInput-root": {
        backgroundColor: "#F8F9F8",
        borderRadius: 50,
        "& label": {
            color: "#031851",
        },
        "& label.Mui-focused": {
            color: "#031851",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "#F8F9F8",
        },
        "& fieldset": {
            borderColor: "#F8F9F8",
            borderRadius: 50,
        },
        "&:hover fieldset": {
            border: "1px solid #031851 !important",
        },
        "&.Mui-focused fieldset": {
            border: "1px solid #031851 !important",
        },
        "& .MuiOutlinedInput-input": {
            paddingLeft: 20,
            paddingRight: 20,
        },
        "& .Mui-error": {
            borderRadius: 50,
            border: "1px solid #FF5968 !important",
            backgroundColor: "#FFDEE1 !important",
        },
    },
});

const CustomOutlinedInput = styled(OutlinedInput)({
    backgroundColor: "#F8F9F8",
    borderRadius: 50,
    "& label": {
        color: "#031851",
    },
    "& label.Mui-focused": {
        color: "#031851",
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "#F8F9F8",
    },
    "& fieldset": {
        borderColor: "#F8F9F8",
        borderRadius: 50,
    },
    "&:hover fieldset": {
        border: "1px solid #031851 !important",
    },
    "&.Mui-focused fieldset": {
        border: "1px solid #031851 !important",
    },
    "& .MuiOutlinedInput-input": {
        paddingLeft: 20,
        paddingRight: 20,
    },
    "& .Mui-error": {
        borderRadius: 50,
        border: "1px solid #FF5968 !important",
        backgroundColor: "#FFDEE1 !important",
    },
});

const Login = () => {
    /** Internationalization i18n */
    const [t, i18n] = useTranslation("global");

    /* React redux */
    const dispatch = useDispatch();
    const navigate = useNavigate();

    /** Component states */
    const [accessToken] = useState(Cookies.get("accessToken"));
    const [option, setOption] = useState("login");
    const [userLog, setUserLog] = useState("");
    const [passLog, setPassLog] = useState("");
    const [userReset, setUserReset] = useState("");
    const [isError, setisError] = useState(false);
    const [isErrorReset, setisErrorReset] = useState(false);
    const [isErrorUser, setIsErrorUser] = useState(false);
    const [showBackdrop, setBackDrop] = useState(false);
    const [show, setShow] = useState(false);
    const [texto, setTexto] = useState("");
    const [alertType, setAlertType] = useState("");
    const [languageAnchorEl, setLanguageAnchorEl] = useState(null);
    const [language, setLanguage] = useState(sessionStorage.getItem("lng") === "es" ? "Español" : "English");
    const [languageMobile, setlanguageMobile] = useState(sessionStorage.getItem("lng") === "es" ? "ES" : "EN");
    const [open, setOpen] = useState(false);
    const [invalidEmail, setInvalidEmail] = useState(false);
    const [valuesNewUser, setValuesNewUser] = useState({
        fullName: "",
        email: "",
        password: "",
        showPassword: false,
        confirmPass: "",
        showConfirmPass: false,
    });

    /** Global variables */
    const openPopoverLanguage = Boolean(languageAnchorEl);
    const id = openPopoverLanguage ? "simple-popover" : undefined;

    /** Component functions */
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setShow(false);
    };

    const handleClickLanguagePopover = (event) => {
        setLanguageAnchorEl(event.currentTarget);
    };

    const handleCloseLanguagePopover = () => {
        setLanguageAnchorEl(null);
    };

    const updateLanguage = (lng) => {
        if (lng === "English") {
            setLanguage(lng);
            setlanguageMobile("EN");
            sessionStorage.setItem("lng", "en");
            i18n.changeLanguage("en");
        } else {
            setLanguage(lng);
            setlanguageMobile("ES");
            sessionStorage.setItem("lng", "es");
            i18n.changeLanguage("es");
        }
        document.title = t("principal.gcaPlatform");
    };

    const handleKeyDownLogin = (event) => {
        if (event.key === "Enter") {
            document.getElementById("myLoginBtn").click();
        }
    };

    const handleChange = (event) => {
        setValuesNewUser({
            ...valuesNewUser,
            [event.target.name]: event.target.value,
        });
    };

    const handleClickShowPassword = () => {
        setValuesNewUser({
            ...valuesNewUser,
            showPassword: !valuesNewUser.showPassword,
        });
    };

    const handleClickShowConfirmPass = () => {
        setValuesNewUser({
            ...valuesNewUser,
            showConfirmPass: !valuesNewUser.showConfirmPass,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleReset = () => {
        setValuesNewUser({
            fullName: "",
            email: "",
            password: "",
            showPassword: false,
            confirmPass: "",
            showConfirmPass: false,
        });
        setIsErrorUser(false);
        setInvalidEmail(false);
    };

    const onChangeOption = (value) => {
        switch (value) {
            case 1:
                setOption("forgotPass");
                setUserLog("");
                setPassLog("");
                setisError(false);
                break;
            case 2:
                setOption("createUser");
                setUserLog("");
                setPassLog("");
                setisError(false);
                break;
            default:
                setOption("login");
                setUserReset("");
                setisErrorReset(false);
                handleReset();
                break;
        }
    };

    const accessToApp = async () => {
        if (userLog.trim() && passLog) {
            if (/.+@.+\..+/.test(userLog)) {
                const params = {
                    user_email: userLog,
                    password: passLog,
                };

                await authService
                    .login(params)
                    .then((data) => {
                        dispatch({
                            type: "addUserInfo",
                            item: data.user,
                        });
                        navigate("/loading");
                    })
                    .catch((err) => {
                        switch (err.response.status) {
                            case 401:
                                setTexto(t("login.loginError401"));
                                break;
                            case 403:
                                setOpen(true);
                                break;
                            case 404:
                                setTexto(t("login.loginError404"));
                                break;
                            default:
                                setTexto(t("login.loginError500"));
                                break;
                        }
                        setAlertType("error");
                        setShow(true);
                    });
                setisError(false);
            } else {
                setisError(true);
            }
        } else {
            setisError(true);
        }
    };

    const forgotPassword = async () => {
        if (userReset.trim()) {
            if (/.+@.+\..+/.test(userReset)) {
                await authService
                    .forgotPassword({ user_email: userReset })
                    .then(() => {
                        setTexto(t("login.forgotPassEmailSent"));
                        setAlertType("success");
                        setShow(true);
                        setUserReset("");
                    })
                    .catch((err) => {
                        if (err.response.status === 404) {
                            setTexto(t("login.forgotPassError404"));
                            setAlertType("error");
                            setShow(true);
                        } else {
                            setTexto(t("login.forgotPassError500"));
                            setAlertType("error");
                            setShow(true);
                        }
                    });

                setisErrorReset(false);
            } else {
                setisErrorReset(true);
            }
        } else {
            setisErrorReset(true);
        }
    };

    const verifyEmail = async () => {
        if (valuesNewUser.email.trim()) {
            if (/.+@.+\..+/.test(valuesNewUser.email)) {
                await authService
                    .verifyNewUserEmail({ user_email: valuesNewUser.email })
                    .then((response) => {
                        if (response.valid) {
                            setInvalidEmail(true);
                            setIsErrorUser(true);
                            setTexto(t("login.existingEmail"));
                            setAlertType("error");
                            setShow(true);
                        } else {
                            setInvalidEmail(false);
                            setIsErrorUser(false);
                        }
                    })
                    .catch(() => {
                        setTexto(t("login.verifyEmailError500"));
                        setAlertType("error");
                        setShow(true);
                    });
            } else {
                setIsErrorUser(true);
            }
        }
    };

    const createUser = async () => {
        setBackDrop(true);

        if (!invalidEmail) {
            if (
                valuesNewUser.fullName.trim() &&
                valuesNewUser.email.trim() &&
                valuesNewUser.password.trim() &&
                valuesNewUser.confirmPass.trim()
            ) {
                if (/.+@.+\..+/.test(valuesNewUser.email)) {
                    if (valuesNewUser.password === valuesNewUser.confirmPass) {
                        const params = {
                            user_name: valuesNewUser.fullName,
                            user_email: valuesNewUser.email,
                            password: valuesNewUser.password,
                            language: sessionStorage.getItem("lng"),
                        };

                        await authService
                            .createNewUser(params)
                            .then(() => {
                                setTexto(t("login.verifyCheckEmail"));
                                setAlertType("success");
                                setShow(true);
                                handleReset();
                            })
                            .catch(() => {
                                setTexto(t("login.createUserError500"));
                                setAlertType("error");
                                setShow(true);
                            });
                    } else {
                        setTexto(t("login.noConfirmationPass"));
                        setAlertType("error");
                        setShow(true);
                        setIsErrorUser(true);
                    }
                } else {
                    setTexto(t("login.invalidEmailFormat"));
                    setAlertType("warning");
                    setShow(true);
                    setIsErrorUser(true);
                }
            } else {
                setTexto(t("miscellaneous.requiredData"));
                setAlertType("warning");
                setShow(true);
                setIsErrorUser(true);
            }
        } else {
            setTexto(t("login.existingEmail"));
            setAlertType("error");
            setShow(true);
        }

        setBackDrop(false);
    };

    const verifyAccount = async () => {
        setBackDrop(true);

        const params = {
            user_email: userLog,
            language: sessionStorage.getItem("lng"),
        };

        await authService
            .verifyAccount(params)
            .then(() => {
                setOpen(false);
                setTexto(t("login.verifyCheckEmail"));
                setAlertType("success");
                setShow(true);
            })
            .catch(() => {
                setOpen(false);
                setTexto(t("login.verifyError500"));
                setAlertType("error");
                setShow(true);
            });

        setBackDrop(false);
    };

    const bodyMess = (
        <Typography variant="h1" color="primary" align="center" sx={{ fontSize: 18, pt: 3 }}>
            {t("login.loginError403")}
        </Typography>
    );

    /** Component events */
    useEffect(() => {
        (async () => {
            if (accessToken) {
                dispatch({
                    type: "addToken",
                    item: accessToken,
                });
            }

            await authService
                .checkSession()
                .then((isActive) => {
                    if (isActive) {
                        navigate("/loading");
                    } else {
                        sessionStorage.removeItem("redirect");
                        sessionStorage.removeItem("option");
                        dispatch({
                            type: "clearInfo",
                        });
                    }
                })
                .catch((error) => {
                    console.log(error.response);
                });

            if (user_email && user_email.length !== 0) {
                setBackDrop(true);

                await authService
                    .updateUserVerification({ user_email })
                    .then(() => {
                        user_email = "";
                        setTexto(t("login.verifyAccountSuccess"));
                        setAlertType("success");
                        setShow(true);
                    })
                    .catch(() => {
                        setOpen(false);
                        setTexto(t("login.verifyError500"));
                        setAlertType("error");
                        setShow(true);
                    });

                setBackDrop(false);
            }
        })();
    }, [t, dispatch, accessToken, navigate]);

    return (
        <Root className="login-body">
            <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Toolbar className={classes.toolbar}>
                    <div style={{ display: "flex" }}></div>
                    <div>
                        <LightTooltip title={t("miscellaneous.changeLng")} aria-label="changeLng">
                            <Button
                                aria-controls="language-menu"
                                aria-haspopup="true"
                                onClick={handleClickLanguagePopover}
                                sx={{ p: "6px 20px" }}
                            >
                                <TranslateIcon className="icon-text-size" />
                                <Hidden only={["xs"]}>
                                    <Typography variant="button" align="center" className={classes.commonWhite}>
                                        {language}
                                    </Typography>
                                </Hidden>
                                <Hidden only={["sm", "md", "lg", "xl"]}>
                                    <Typography variant="button" align="center" className={classes.commonWhite}>
                                        {languageMobile}
                                    </Typography>
                                </Hidden>
                            </Button>
                        </LightTooltip>
                        <Popover
                            id={id}
                            open={openPopoverLanguage}
                            anchorEl={languageAnchorEl}
                            onClose={handleCloseLanguagePopover}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "center",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "center",
                            }}
                            PaperProps={{
                                sx: {
                                    p: "8px 0px",
                                    borderRadius: 5,
                                    boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                    backgroundColor: "#F8F9F8",
                                },
                            }}
                        >
                            <ListItemButton
                                onClick={() => {
                                    handleCloseLanguagePopover();
                                    updateLanguage("English");
                                }}
                                sx={{ pl: 4, pr: 4 }}
                            >
                                <ListItemText primary="English" />
                            </ListItemButton>
                            <ListItemButton
                                onClick={() => {
                                    handleCloseLanguagePopover();
                                    updateLanguage("Español");
                                }}
                                sx={{ pl: 4, pr: 4 }}
                            >
                                <ListItemText primary="Español" />
                            </ListItemButton>
                        </Popover>
                    </div>
                </Toolbar>
            </AppBar>
            {option === "login" ? (
                <Grid container alignItems="center" justifyContent="center" className={classes.loginContainer}>
                    <Grid item>
                        <GCALogo className={classes.gcaLogo} />
                        <Typography variant="h1" align="center" color="secondary" className={classes.loginSubtitle}>
                            {t("login.routinesDone")}
                        </Typography>
                        <Grid container className={classes.centeredContainer}>
                            <Grid item xs={12} className={`${classes.formFieldInput}`}>
                                <CustomTextField
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    placeholder={t("login.user")}
                                    InputProps={{
                                        classes: {
                                            input:
                                                isError && userLog.trim() === ""
                                                    ? classes.inputError
                                                    : isError && !/.+@.+\..+/.test(userLog)
                                                    ? classes.inputError
                                                    : classes.input,
                                        },
                                    }}
                                    error={
                                        isError && userLog.trim() === ""
                                            ? true
                                            : isError && !/.+@.+\..+/.test(userLog)
                                            ? true
                                            : false
                                    }
                                    value={userLog}
                                    onChange={(event) => setUserLog(event.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} className={`${classes.formFieldInput}`}>
                                <CustomTextField
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    type="password"
                                    autoComplete="off"
                                    placeholder={t("login.pass")}
                                    InputProps={{
                                        classes: {
                                            input: isError && passLog === "" ? classes.inputError : classes.input,
                                        },
                                    }}
                                    error={isError && passLog === "" ? true : false}
                                    value={passLog}
                                    onChange={(event) => setPassLog(event.target.value)}
                                    onKeyDown={handleKeyDownLogin}
                                />
                                <Typography
                                    variant="h4"
                                    align="right"
                                    color="secondary"
                                    className={classes.spacing}
                                    onClick={() => onChangeOption(1)}
                                >
                                    {t("login.forgotPass")}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    id="myLoginBtn"
                                    variant="contained"
                                    color="secondary"
                                    classes={{ root: classes.btnRoot }}
                                    disableElevation
                                    fullWidth
                                    onClick={accessToApp}
                                >
                                    {t("login.login")}
                                </Button>
                                <Typography variant="h4" align="center" sx={{ pt: 1.5, color: "#fff" }}>
                                    {t("login.notRegistered")}
                                    <Typography
                                        component="span"
                                        variant="h4"
                                        align="center"
                                        color="secondary"
                                        className={classes.spacing3}
                                        onClick={() => onChangeOption(2)}
                                    >
                                        {t("login.signUp")}
                                    </Typography>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            ) : option === "forgotPass" ? (
                <Grid container alignItems="center" justifyContent="center" className={classes.loginContainer}>
                    <Grid item>
                        <Typography variant="h1" align="center" color="secondary" className={classes.title}>
                            {" "}
                            {t("login.forgotPass")}{" "}
                        </Typography>
                        <Typography
                            variant="body1"
                            align="center"
                            color="secondary"
                            className={`${classes.spacing2} ${classes.commonWhite}`}
                        >
                            {t("login.pleaseEnter")}
                        </Typography>
                        <Grid container className={classes.centeredContainer}>
                            <Grid item xs={12}>
                                <CustomTextField
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    placeholder={t("login.user")}
                                    InputProps={{
                                        classes: {
                                            input:
                                                isErrorReset && userReset.trim() === ""
                                                    ? classes.inputError
                                                    : isErrorReset && !/.+@.+\..+/.test(userReset)
                                                    ? classes.inputError
                                                    : classes.input,
                                        },
                                    }}
                                    error={
                                        isErrorReset && userReset.trim() === ""
                                            ? true
                                            : isErrorReset && !/.+@.+\..+/.test(userReset)
                                            ? true
                                            : false
                                    }
                                    value={userReset}
                                    onChange={(event) => setUserReset(event.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    classes={{ root: classes.btnRoot }}
                                    disableElevation
                                    fullWidth
                                    onClick={forgotPassword}
                                >
                                    {t("login.resetPass")}
                                </Button>
                                <Typography
                                    variant="body1"
                                    align="center"
                                    color="secondary"
                                    className={classes.btnBackLogin}
                                    onClick={() => onChangeOption(0)}
                                >
                                    {t("login.backLogin")}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            ) : (
                <Grid container alignItems="center" justifyContent="center" className={classes.loginContainer}>
                    <Grid item>
                        <Card
                            elevation={0}
                            sx={{
                                m: 4,
                                p: 5,
                                maxWidth: "480px",
                                borderRadius: 8,
                                boxShadow: "10px 10px 15px rgba(2,12,35,0.6)",
                            }}
                        >
                            <CardHeader
                                title={
                                    <Typography variant="h5" color="secondary" sx={{ fontSize: 25 }}>
                                        {t("login.createUser")}
                                    </Typography>
                                }
                                sx={{
                                    p: "0px 0px 40px",
                                }}
                            />
                            <CardContent sx={{ p: 0 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sx={{ mb: 2 }}>
                                        <FormControl fullWidth required variant="outlined">
                                            <InputLabel
                                                htmlFor="outlined-adornment-fullName"
                                                error={
                                                    isErrorUser && valuesNewUser.fullName.trim() === "" ? true : false
                                                }
                                            >
                                                {t("miscellaneous.fullName")}
                                            </InputLabel>
                                            <CustomOutlinedInput
                                                fullWidth
                                                id="outlined-adornment-fullName"
                                                name="fullName"
                                                label={t("miscellaneous.fullName")}
                                                value={valuesNewUser.fullName}
                                                onChange={handleChange}
                                                error={
                                                    isErrorUser && valuesNewUser.fullName.trim() === "" ? true : false
                                                }
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sx={{ mb: 2 }}>
                                        <FormControl fullWidth required variant="outlined">
                                            <InputLabel
                                                htmlFor="outlined-adornment-email"
                                                error={
                                                    (isErrorUser && valuesNewUser.email.trim() === "") ||
                                                    (isErrorUser && !/.+@.+\..+/.test(valuesNewUser.email)) ||
                                                    (isErrorUser && invalidEmail)
                                                        ? true
                                                        : false
                                                }
                                            >
                                                {t("login.email")}
                                            </InputLabel>
                                            <CustomOutlinedInput
                                                fullWidth
                                                id="outlined-adornment-email"
                                                name="email"
                                                label={t("login.email")}
                                                value={valuesNewUser.email}
                                                onChange={handleChange}
                                                error={
                                                    (isErrorUser && valuesNewUser.email.trim() === "") ||
                                                    (isErrorUser && !/.+@.+\..+/.test(valuesNewUser.email)) ||
                                                    (isErrorUser && invalidEmail)
                                                        ? true
                                                        : false
                                                }
                                                onBlur={verifyEmail}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sx={{ mb: 2 }}>
                                        <FormControl fullWidth required variant="outlined">
                                            <InputLabel
                                                htmlFor="outlined-adornment-password"
                                                error={
                                                    isErrorUser && valuesNewUser.password.trim() === "" ? true : false
                                                }
                                            >
                                                {t("login.password")}
                                            </InputLabel>
                                            <CustomOutlinedInput
                                                fullWidth
                                                id="outlined-adornment-password"
                                                name="password"
                                                type={valuesNewUser.showPassword ? "text" : "password"}
                                                label={t("login.password")}
                                                value={valuesNewUser.password}
                                                onChange={handleChange}
                                                error={
                                                    isErrorUser && valuesNewUser.password.trim() === "" ? true : false
                                                }
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                        >
                                                            {valuesNewUser.showPassword ? (
                                                                <VisibilityOff />
                                                            ) : (
                                                                <Visibility />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sx={{ mb: 2 }}>
                                        <FormControl fullWidth required variant="outlined">
                                            <InputLabel
                                                htmlFor="outlined-adornment-confirm-password"
                                                error={
                                                    isErrorUser && valuesNewUser.confirmPass.trim() === ""
                                                        ? true
                                                        : false
                                                }
                                            >
                                                {t("login.confirmPass")}
                                            </InputLabel>
                                            <CustomOutlinedInput
                                                fullWidth
                                                id="outlined-adornment-confirm-password"
                                                name="confirmPass"
                                                type={valuesNewUser.showConfirmPass ? "text" : "password"}
                                                label={t("login.confirmPass")}
                                                value={valuesNewUser.confirmPass}
                                                onChange={handleChange}
                                                error={
                                                    isErrorUser && valuesNewUser.confirmPass.trim() === ""
                                                        ? true
                                                        : false
                                                }
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle confirm password visibility"
                                                            onClick={handleClickShowConfirmPass}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                        >
                                                            {valuesNewUser.showConfirmPass ? (
                                                                <VisibilityOff />
                                                            ) : (
                                                                <Visibility />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <CardActions sx={{ p: "40px 0px 0px" }}>
                                <Grid container justifyContent="space-between" alignItems="center">
                                    <Grid item>
                                        <Typography
                                            variant="h4"
                                            align="center"
                                            color="secondary"
                                            onClick={() => onChangeOption(0)}
                                            sx={{
                                                cursor: "pointer",
                                                "&:hover": {
                                                    color: "#2f3190",
                                                },
                                            }}
                                        >
                                            {t("login.backLogin")}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            fullWidth
                                            variant="contained"
                                            color="secondary"
                                            disableElevation
                                            onClick={createUser}
                                            sx={{
                                                borderRadius: 50,
                                                "&:hover": {
                                                    backgroundColor: "#2f3190",
                                                    color: "#fff",
                                                },
                                            }}
                                        >
                                            {t("login.createAccount")}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
            )}
            <Backdrop open={showBackdrop} />
            <SnackBar type={alertType} show={show} message={texto} onClose={handleClose} />
            <DialogAlerts
                open={open}
                onClose={() => setOpen(false)}
                agreeBtnLabel={t("miscellaneous.resend")}
                disagreeBtnLabel={t("miscellaneous.cancel")}
                type={"error"}
                title={t("login.unverifiedUser")}
                message={bodyMess}
                agreeAction={verifyAccount}
            />
        </Root>
    );
};

export default Login;
