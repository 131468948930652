/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import PropTypes from "prop-types";

/** Icons */
import { ReactComponent as CloudUploadIcon } from "../../../../../assets/images/CloudUploadIcon.svg";

/** MUI Icons */
import DeleteIcon from "@mui/icons-material/Delete";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

/** MUI imports */
import { styled } from "@mui/material/styles";
import GridUI from "@mui/material/Grid";
import {
    Box,
    Dialog,
    DialogContent,
    IconButton,
    LinearProgress,
    List,
    ListSubheader,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
    DialogTitle,
    Divider,
    DialogActions,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TablePagination,
    Paper,
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

/** Component imports */
import Backdrop from "../../../Backdrop";
import DialogAlerts from "../../../DialogAlerts";
import projectService from "../../../../../services/projects";

const PREFIX = "UploadDocFiles";

const classes = {
    dropzone: `${PREFIX}-dropzone`,
    dropzoneDisabled: `${PREFIX}-dropzoneDisabled`,
};

const ColorButton = styled(Button)(({ theme }) => ({
    border: "1px solid #ff5968",
    borderRadius: 40,
}));

const CustomDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(4),
    },
    "& .MuiDialogTitle-root": {
        backgroundColor: "#031851",
        fontSize: "18px",
        color: "#fff",
    },
    "& .MuiDialog-paper": {
        borderRadius: 8,
        boxShadow: "10px 10px 15px rgba(3,24,81,0.15)",
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(2),
    },

    [`& .${classes.dropzone}`]: {
        flex: 1,
        display: "flex",
        padding: "64px 24px",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "rgba(47,49,144,0.1)",
        border: "2px dashed #C1C1DE",
        borderRadius: "16px",
        "&:hover": {
            border: "2px dashed #2f3190",
        },
    },
    [`& .${classes.dropzoneDisabled}`]: {
        flex: 1,
        display: "flex",
        padding: "64px 24px",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "rgba(47,49,144,0.1)",
        border: "2px dashed #C1C1DE",
        borderRadius: "16px",
    },
}));

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "rgba(19, 31, 62, 0.80)",
        fontSize: 12,
        maxWidth: 320,
        borderRadius: 6,
        fontWeight: "normal",
    },
});

function LinearProgressWithLabel(props) {
    return (
        <Box sx={{ display: "flex", mt: 2, alignItems: "center" }}>
            <Box sx={{ width: "100%", mr: 1 }}>
                <LinearProgress
                    variant="determinate"
                    {...props}
                    sx={{
                        height: 10,
                        borderRadius: 5,
                    }}
                />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(props.value)}%`}</Typography>
            </Box>
        </Box>
    );
}

LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
};

const UploadDocFiles = (props) => {
    const {
        selectedYear,
        ProjectCompID,
        filesRejected,
        setFilesRejected,
        filesData,
        setFilesData,
        onClose,
        companyName,
        setTexto,
        setAlertType,
        setShow,
        uploadedFiles,
        open,
        uploadedFilesSizes,
        handleCloseDialogDiscard,
        setUploadedFiles,
    } = props;

    /** Internationalization i18n */
    const [t] = useTranslation("global");

    /* React redux */
    const todos = useSelector((state) => state.todos);

    /** Component states */
    const [showBackdrop, setBackDrop] = useState(false);
    const [progressChecks, setProgressChecks] = useState(0);
    const [openAlertDelete, setOpenAlertDelete] = useState(false);
    const [page, setPage] = useState(0);
    const rowsPerPage = 10;

    const limit_mb = " 10 MB.";

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    /** Global variables */
    const teamDelete = [
        "kgarcia@gca.digital",
        "dloyo@gca.digital",
        "gloyo@gca.digital",
        "dleon@gca.digital",
        "iguevara@gca.digital",
        "galbani@gca.digital",
        "jalvarez@gca.digital",
        "btorres@gca.digital",
        "jrojas@gca.digital",
        "mmedina@gca.digital",
    ];

    /** Component functions */
    const formatBytes = (bytes, decimals = 0) => {
        if (bytes === 0) return "0 Bytes";

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    };

    const onDropChecks = useCallback(
        async (acceptedFiles) => {
            if (acceptedFiles.length > 0) {
                const folderName = "Projects/Tax Expert";

                const params = {
                    project_company_id: ProjectCompID,
                    year: selectedYear,
                    folder: folderName,
                };

                await projectService
                    .getTotalSizeFileDocS3(params)
                    .then(async (response) => {
                        let finalSize = 0;

                        acceptedFiles.forEach((item) => {
                            finalSize += item.size;
                        });

                        const limit_size = 10000000;

                        if (parseInt(response.size + finalSize) < limit_size) {
                            const data = new FormData();
                            data.append("project_company_id", ProjectCompID);
                            data.append("year", selectedYear);
                            data.append("folder", folderName);

                            if (acceptedFiles.length > 1) {
                                for (let x = 0; x < acceptedFiles.length; x++) {
                                    data.append("files", acceptedFiles[x]);
                                }
                            } else {
                                data.append("file", acceptedFiles[0]);
                            }

                            const options = {
                                onUploadProgress: (progressEvent) => {
                                    const { loaded, total } = progressEvent;
                                    const percent = Math.floor((loaded / total) * 100);

                                    if (percent < 100) {
                                        setProgressChecks(percent);
                                    }
                                },
                            };

                            let urlApi = "";

                            if (acceptedFiles.length > 1) {
                                urlApi = "/uploadFileDocS3multiple";
                            } else {
                                urlApi = "/uploadFileDocS3";
                            }

                            await projectService
                                .uploadFilesDocS3(urlApi, data, options)
                                .then(() => {
                                    setProgressChecks(0);
                                    setFilesData(filesData.concat([...acceptedFiles]));
                                })
                                .catch((err) => {
                                    console.log(err);
                                });
                        } else {
                            setTexto(t("dialogConversions.maximunUploadSizeMonthly") + limit_mb);
                            setAlertType("warning");
                            setShow(true);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            } else {
                setTexto(t("dialogConversions.maximunUploadSize"));
                setAlertType("warning");
                setShow(true);
            }
        },
        [ProjectCompID, selectedYear, setFilesData, filesData, setTexto, t, setAlertType, setShow]
    );

    const onDropRejectedChecks = useCallback(
        (fileRejections) => {
            setFilesRejected([...filesRejected, ...fileRejections]);
        },
        [filesRejected, setFilesRejected]
    );

    const { getRootProps: getRootDocsProps, getInputProps: getInputDocsProps } = useDropzone({
        noKeyboard: true,
        maxSize: 10000000,
        //maxFiles: 1,
        multiple: true,
        onDrop: onDropChecks,
        onDropRejected: onDropRejectedChecks,
        accept: {
            "image/*": ['.jpeg', '.jpg', '.png'],
            "application/pdf": [],
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [],
            "application/zip": ['.zip'],
        },
    });

    const removeFile = (file, val) => async () => {
        setBackDrop(true);
        let params = {};

        const folder_name = "Projects/Tax Expert";
        const fileName = file.name ?? file;

        params = {
            project_company_id: ProjectCompID,
            year: selectedYear,
            file_name: fileName,
            folder: folder_name,
        };

        await projectService
            .deleteFileDocS3(params)
            .then(() => {
                // Validamos si es eliminacion de archivos en la nube o archivos locales
                if (val === 1) {
                    setUploadedFiles(uploadedFiles.filter((item) => item !== fileName));
                } else {
                    setFilesData(filesData.filter((item) => item.name !== fileName));
                }

                setTexto(t("dialogConversions.filesRemoved"));
                setAlertType("success");
                setShow(true);
            })
            .catch((err) => {
                console.log(err);
            });

        setBackDrop(false);
    };

    const downloadFile = (file) => async () => {
        let params = {};

        const folder_name = "Projects/Tax Expert";

        if (uploadedFiles.length === 0) {
            params = {
                project_company_id: ProjectCompID,
                year: selectedYear,
                file_name: file.name,
                folder: folder_name,
            };
        } else {
            params = {
                project_company_id: ProjectCompID,
                year: selectedYear,
                file_name: file,
                folder: folder_name,
            };
        }

        await projectService
            .getFileDocS3(params)
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", response.config.params.file_name);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const downloadAll = async () => {
        const folder_name = "Projects/Tax Expert";

        const params = {
            project_company_id: ProjectCompID,
            year: selectedYear,
            folder: folder_name,
        };

        await projectService
            .getBulkFilesDocS3(params)
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;

                let nameZip = companyName + " - " + selectedYear + ".zip";
                link.setAttribute("download", nameZip);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const deleteAll = () => async () => {
        const folder_name = "Projects/Tax Expert";

        setOpenAlertDelete(false);
        setBackDrop(true);

        const params = {
            project_company_id: ProjectCompID,
            year: selectedYear,
            folder: folder_name,
        };

        await projectService
            .deleteBulkFilesDocS3(params)
            .then(() => {
                setUploadedFiles([]);
                setTexto(t("dialogConversions.filesRemoved"));
                setAlertType("success");
                setShow(true);
            })
            .catch((err) => {
                console.log(err);
            });

        setBackDrop(false);
    };

    const acceptedFileItems = filesData.map((file) => (
        <ListItem
            disablePadding
            secondaryAction={
                <Box>
                    <LightTooltip title={t("dialogConversions.download")} aria-label="downloadFile">
                        <IconButton edge="end" aria-label="delete" onClick={downloadFile(file)} sx={{ mr: 1 }}>
                            <FileDownloadIcon fontSize="medium" color="primary" />
                        </IconButton>
                    </LightTooltip>
                    <LightTooltip title={t("dialogConversions.remove")} aria-label="removeFile">
                        <IconButton edge="end" aria-label="delete" onClick={removeFile(file, 2)}>
                            <DeleteIcon fontSize="medium" color="primary" />
                        </IconButton>
                    </LightTooltip>
                </Box>
            }
            sx={{ height: 50 }}
        >
            <ListItemIcon sx={{ minWidth: "20px" }}>
                <DescriptionOutlinedIcon fontSize="large" color="primary" sx={{ mr: 1 }} />
            </ListItemIcon>
            <ListItemText
                key={file.path}
                primary={file.name}
                secondary={formatBytes(file.size)}
                secondaryTypographyProps={{ fontSize: "12px" }}
            />
        </ListItem>
    ));

    const fileRejectionItems = useMemo(
        () =>
            filesRejected.map(({ file }) => (
                <ListItem disablePadding>
                    <ListItemIcon sx={{ minWidth: "20px" }}>
                        <DescriptionOutlinedIcon fontSize="large" color="primary" sx={{ mr: 1 }} />
                    </ListItemIcon>
                    <ListItemText
                        key={file.path}
                        primary={file.name}
                        secondary={formatBytes(file.size)}
                        secondaryTypographyProps={{ fontSize: "12px" }}
                        sx={{ color: "#ff5968" }}
                    />
                </ListItem>
            )),
        [filesRejected]
    );

    const uploadedFilesItems2 = (files) => {
        let data = files;
        return (
            <>
                <Paper sx={{ boxShadow: 0 }}>
                    <TableContainer>
                        <Table aria-label="simple table">
                            <TableBody>
                                {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((file, index) => (
                                    <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                        <TableCell align="left" sx={{ p: 0, border: 0, display: "flex" }}>
                                            <DescriptionOutlinedIcon fontSize="medium" color="primary" sx={{ mr: 1 }} />
                                            <Typography>
                                                {file}&nbsp;&nbsp;({uploadedFilesSizes[index]})
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="right" sx={{ py: 0, border: 0, whiteSpace: "nowrap" }}>
                                            <Box>
                                                <LightTooltip
                                                    title={t("dialogConversions.download")}
                                                    aria-label="downloadFile"
                                                >
                                                    <IconButton
                                                        edge="end"
                                                        aria-label="delete"
                                                        onClick={downloadFile(file)}
                                                        sx={{
                                                            mr: "-12px",
                                                            ...(teamDelete.includes(todos.userInfo.email) && {
                                                                mr: 1,
                                                            }),
                                                        }}
                                                    >
                                                        <FileDownloadIcon fontSize="medium" color="primary" />
                                                    </IconButton>
                                                </LightTooltip>
                                                {teamDelete.includes(todos.userInfo.email) ? (
                                                    <LightTooltip
                                                        title={t("dialogConversions.remove")}
                                                        aria-label="removeUploadedFile"
                                                    >
                                                        <IconButton
                                                            edge="end"
                                                            aria-label="delete"
                                                            onClick={removeFile(file, 1)}
                                                        >
                                                            <DeleteIcon fontSize="medium" color="primary" />
                                                        </IconButton>
                                                    </LightTooltip>
                                                ) : null}
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        shape="rounded"
                        rowsPerPageOptions={[10]}
                        component="div"
                        count={files.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${t("miscellaneous.of")} ${count}`}
                    />
                </Paper>
            </>
        );
    };

    return (
        <>
            <DialogAlerts
                open={openAlertDelete}
                onClose={() => setOpenAlertDelete(false)}
                agreeBtnLabel={t("miscellaneous.accept")}
                disagreeBtnLabel={t("miscellaneous.cancel")}
                type={"info"}
                title={t("miscellaneous.filesSureDeleteDoc")}
                agreeAction={deleteAll()}
            />
            <CustomDialog
                open={open}
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        onClose(uploadedFiles.length > 0 || filesData.length > 0);
                    }
                }}
                maxWidth="md"
                fullWidth={true}
            >
                <DialogTitle id="alert-dialog-title">
                    {companyName} / <span style={{ color: "#ff5968" }}>{selectedYear}</span>
                </DialogTitle>
                <DialogContent
                    dividers
                    sx={{
                        ...((filesData.length > 0 || uploadedFiles.length > 0) && {
                            pb: "0 !important",
                        }),
                    }}
                >
                    <div
                        {...getRootDocsProps({
                            className: classes.dropzone,
                        })}
                    >
                        <input {...getInputDocsProps()} />
                        <CloudUploadIcon fill={"#031851"} />
                        <Typography variant="h1" color={"#031851"} sx={{ cursor: "default", pt: 3 }}>
                            {t("dialogConversions.selectFile")}
                        </Typography>
                    </div>
                    <Typography variant="body1" color="primary" sx={{ pt: 1 }}>
                        {t("dialogConversions.validFormats")}{" "}
                        <Typography component="span" variant="h4" color="primary">
                            .pdf, .jpg, .jpeg, .png, .xlsx, .zip
                        </Typography>
                        <Typography variant="body1" color="primary">
                            {t("dialogConversions.uploadLimit")} 10 MB.
                        </Typography>
                    </Typography>
                    {progressChecks > 0 && <LinearProgressWithLabel value={progressChecks} />}
                    {acceptedFileItems.length > 0 && (
                        <List
                            component="nav"
                            aria-labelledby="acceptedFiles-list-subheader"
                            subheader={
                                <ListSubheader component="div" id="acceptedFiles-list-subheader" sx={{ p: 0 }}>
                                    <Typography variant="h4" color="primary" sx={{ pt: 3, pb: 1 }}>
                                        {`${t("dialogConversions.uploadedFiles")}:`}
                                    </Typography>
                                </ListSubheader>
                            }
                        >
                            {acceptedFileItems}
                        </List>
                    )}
                    {fileRejectionItems.length > 0 && (
                        <List
                            component="nav"
                            aria-labelledby="fileRejections-list-subheader"
                            subheader={
                                <ListSubheader component="div" id="fileRejections-list-subheader" sx={{ p: 0 }}>
                                    <Typography variant="h4" color="primary" sx={{ pt: 3, pb: 1 }}>
                                        {`${t("dialogConversions.rejectedFiles")}:`}
                                    </Typography>
                                </ListSubheader>
                            }
                        >
                            {fileRejectionItems}
                        </List>
                    )}
                    {uploadedFiles.length > 0 && (
                        <List
                            component="nav"
                            aria-labelledby="uploadedFiles-list-subheader"
                            subheader={
                                <>
                                    <ListItem
                                        disablePadding
                                        secondaryAction={
                                            uploadedFiles.length > 1 && (
                                                <Box>
                                                    <LightTooltip
                                                        title={t("dialogConversions.download")}
                                                        aria-label="downloadFile"
                                                    >
                                                        <IconButton
                                                            edge="end"
                                                            aria-label="download"
                                                            onClick={downloadAll}
                                                            sx={{
                                                                mr: "-12px",
                                                                ...(teamDelete.includes(todos.userInfo.email) && {
                                                                    mr: 1,
                                                                }),
                                                            }}
                                                        >
                                                            <FileDownloadIcon fontSize="medium" color="primary" />
                                                        </IconButton>
                                                    </LightTooltip>
                                                    {teamDelete.includes(todos.userInfo.email) && (
                                                        <LightTooltip
                                                            title={t("dialogConversions.remove")}
                                                            aria-label="removeFile"
                                                        >
                                                            <IconButton
                                                                edge="end"
                                                                aria-label="delete"
                                                                onClick={() => setOpenAlertDelete(true)}
                                                            >
                                                                <DeleteIcon fontSize="medium" color="primary" />
                                                            </IconButton>
                                                        </LightTooltip>
                                                    )}
                                                </Box>
                                            )
                                        }
                                        sx={{ height: 50 }}
                                    >
                                        <ListItemText
                                            primary={
                                                <Typography variant="h4" color="primary" sx={{ pt: 3, pb: 1 }}>
                                                    {`${t("dialogConversions.repositoryFiles")} (${
                                                        uploadedFiles.length
                                                    }):`}
                                                </Typography>
                                            }
                                            secondaryTypographyProps={{ fontSize: "12px" }}
                                        />
                                    </ListItem>
                                    <Divider sx={{ py: 0.5 }} />
                                </>
                            }
                        >
                            {uploadedFilesItems2(uploadedFiles)}
                        </List>
                    )}
                </DialogContent>
                <DialogActions sx={{ p: "16px 32px !important" }}>
                    <GridUI container justifyContent="space-between" alignItems="center" spacing={2}>
                        <GridUI item>
                            <ColorButton
                                sx={{
                                    mr: 1,
                                    "&:hover": {
                                        backgroundColor: "white",
                                        color: "#2f3190",
                                        border: "1px solid #2f3190",
                                    },
                                }}
                                color="secondary"
                                disableElevation
                                variant="outlined"
                                onClick={handleCloseDialogDiscard}
                            >
                                {t("miscellaneous.cancel")}
                            </ColorButton>
                        </GridUI>
                        <GridUI item>
                            <ColorButton
                                disableElevation
                                variant="contained"
                                color="secondary"
                                onClick={() => onClose(uploadedFiles.length > 0 || filesData.length > 0)}
                                sx={{
                                    "&:hover": {
                                        backgroundColor: "#2f3190",
                                        border: "1px solid #2f3190",
                                        color: "#fff",
                                    },
                                    "&.MuiButton-root.Mui-disabled": {
                                        backgroundColor: "#ffcdd2 !important",
                                        border: "none",
                                        color: "#fff",
                                    },
                                }}
                            >
                                {t("miscellaneous.save")}
                            </ColorButton>
                        </GridUI>
                    </GridUI>
                </DialogActions>
                <Backdrop open={showBackdrop} />
            </CustomDialog>
        </>
    );
};

export default UploadDocFiles;
