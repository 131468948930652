/** React imports */
import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';

/** MUI icons */
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CircleIcon from '@mui/icons-material/Circle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

/** MUI imports */
import { styled } from '@mui/material/styles';
import {
    Box,
    Breadcrumbs,
    Card,
    CardHeader,
    CardContent,
    Grid,
    ListItemText,
    List,
    ListItem,
    Skeleton,
    Stack,
    Typography,
    Divider,
    Switch,
    FormControlLabel,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    OutlinedInput,
    Button,
    ListItemIcon,
    IconButton
} from '@mui/material';

const PREFIX = 'Notifications';

const classes = {
    btnRoot: `${PREFIX}-btnRoot`,
    formRounded: `${PREFIX}-formRounded`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.formRounded}`]: {
        borderRadius: 40,
        verticalAlign: 'middle !important',
        "&:focus-within": {
            borderRadius: 40,
        },
        "& .MuiOutlinedInput-notchedOutline": {
            border: '2px solid #031851',
            borderRadius: 40,
        },
    },
    [`& .${classes.btnRoot}`]: {
        border: '1px solid #ff5968',
        borderRadius: 40,
        '&:hover': {
            backgroundColor: '#2f3190',
            border: '1px solid #2f3190',
        },
    },
}));

const Notifications = () => {
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    /* React redux */

    /** Component variables */
    const breadcrumbs = [
        <Typography key="1" variant="h1" color="primary" sx={{
            fontSize: 24,
            fontWeight: 700
        }}>
            {t("miscellaneous.notifi")}
        </Typography>
    ]
    /** Component states */
    const [loading, setLoading] = useState(false);
    const [showAll, setshowAll] = useState(false);
    const [showCircles, setshowCircles] = useState([true, true, true, false]);
    const [type, setType] = useState("");
    const [module, setModule] = useState("");

    const handleChangeSwitch = (event) => {
        setshowAll(!showAll);
    };

    const handleChangeType = (event) => {
        setType(event.target.value);
    }

    const handleChangeModule = (event) => {
        setModule(event.target.value);
    }

    const checkAll = () => {
        let mark = showCircles.map(item => {
            return false
        })
        setshowCircles(mark)
    }

    /** Charts Options */
    useEffect(() => {
        (async () => {
            setLoading(true);

            setLoading(false);
        })();
    }, []);

    return (
        <StyledContainer component="main" sx={{ flexGrow: 1, p: 4, pt: 10, width: '100%' }}>
            <Stack sx={{ pb: 4 }}>
                <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small" sx={{ color: '#031851' }} />}
                    aria-label="breadcrumb"
                >
                    {breadcrumbs}
                </Breadcrumbs>
            </Stack>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    {loading ?
                        <Card elevation={0}
                            sx={{
                                borderRadius: 8,
                                boxShadow: '10px 10px 15px rgba(3,24,81,0.15)',
                            }}
                        >
                            <CardHeader
                                title={
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Skeleton variant="text" animation="wave" height={15} width="55%" sx={{ position: 'absolute', right: 0 }} />
                                    </Box>
                                }
                            />
                            <CardContent sx={{ pt: 0 }}>
                                <Skeleton variant="rectangular" animation="wave" height={52}
                                    sx={{ borderRadius: 25, ml: 0, mb: '10px' }}
                                />
                                <List component="nav">
                                    {Array(5).fill(
                                        <>
                                            <ListItem sx={{ p: '12px 16px !important' }}>
                                                <ListItemText primary={<Skeleton variant="text" animation="wave" height={15} width="50%" />} />
                                            </ListItem>
                                            <Divider />
                                            <ListItem sx={{ p: '12px 16px !important' }}>
                                                <ListItemText primary={<Skeleton variant="text" animation="wave" height={15} width="35%" />} />
                                            </ListItem>
                                            <Divider />
                                        </>
                                    )}
                                </List>
                            </CardContent>
                        </Card>
                        :
                        <Card elevation={0}
                            sx={{
                                borderRadius: 8,
                                boxShadow: '10px 10px 15px rgba(3,24,81,0.15)',
                            }}
                        >
                            <CardHeader
                                title={
                                    <Grid container spacing={3} sx={{ p: 3 }}>
                                        <Grid item xs={12} md={4} lg={3} xl={2}>
                                            <FormControlLabel control={
                                                <Switch
                                                    checked={showAll}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                    color={"secondary"}
                                                    onChange={handleChangeSwitch}
                                                />
                                            } label={<Typography variant="body1" color="textPrimary">{t('miscellaneous.seeAll')}</Typography>}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4} lg={3} xl={2}>
                                            <FormControl fullWidth classes={{ root: classes.formRounded }}>
                                                <InputLabel shrink id="type-simple-select-label">{t('miscellaneous.type2')}</InputLabel>
                                                <Select
                                                    size="small"
                                                    IconComponent={KeyboardArrowDownIcon}
                                                    value={type}
                                                    displayEmpty
                                                    onChange={handleChangeType}
                                                    className="ddlGeneral"
                                                    input={<OutlinedInput notched label={t('miscellaneous.type2')} />}
                                                    MenuProps={{
                                                        PaperProps: {
                                                            sx: {
                                                                mt: 0.7,
                                                                borderRadius: 2,
                                                                boxShadow: '10px 10px 15px 0px rgba(3,24,81,0.15)',
                                                            },
                                                        },
                                                    }}
                                                >
                                                    <MenuItem value="">
                                                        <Typography variant="body1" component="span" align="center" color="textPrimary">{t('miscellaneous.all')}</Typography>
                                                    </MenuItem>
                                                    <MenuItem value={0}>
                                                        <Typography variant="body1" component="span" align="center" color="textPrimary">
                                                            Prueba
                                                        </Typography>
                                                    </MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={4} lg={3} xl={2}>
                                            <FormControl fullWidth classes={{ root: classes.formRounded }}>
                                                <InputLabel shrink id="type-simple-select-label">{t('miscellaneous.module')}</InputLabel>
                                                <Select
                                                    size="small"
                                                    IconComponent={KeyboardArrowDownIcon}
                                                    value={module}
                                                    displayEmpty
                                                    onChange={handleChangeModule}
                                                    className="ddlGeneral"
                                                    input={<OutlinedInput notched label={t('miscellaneous.module')} />}
                                                    MenuProps={{
                                                        PaperProps: {
                                                            sx: {
                                                                mt: 0.7,
                                                                borderRadius: 2,
                                                                boxShadow: '10px 10px 15px 0px rgba(3,24,81,0.15)',
                                                            },
                                                        },
                                                    }}
                                                >
                                                    <MenuItem value="">
                                                        <Typography variant="body1" component="span" align="center" color="textPrimary">{t('miscellaneous.all')}</Typography>
                                                    </MenuItem>
                                                    <MenuItem value={0}>
                                                        <Typography variant="body1" component="span" align="center" color="textPrimary">
                                                            Modulo 1
                                                        </Typography>
                                                    </MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={4} lg={3} xl={6} sx={{ textAlign: "right" }}>
                                            <Button
                                                color="secondary"
                                                variant="contained"
                                                disableElevation
                                                classes={{ root: classes.btnRoot }}
                                                onClick={checkAll}
                                            >
                                                Marcar todos como leídos
                                            </Button>
                                        </Grid>
                                    </Grid>
                                }
                            />
                            <CardContent sx={{ pt: 0 }}>
                                {false ?
                                    <Typography variant="body1" sx={{ fontWeight: 700, p: 4, textAlign: 'center' }}>
                                        No hay notificaciones nuevas
                                    </Typography>
                                    :
                                    <List sx={{ width: '100%', maxHeight: 300 }}>
                                        <ListItem alignItems="center" key={0} sx={{ p: 2 }}>
                                            {showCircles[0] ?
                                                <ListItemIcon size="small" sx={{ minWidth: 30 }}>
                                                    <CircleIcon fontSize="inherit" sx={{ color: '#ff5968' }} />
                                                </ListItemIcon>
                                                :
                                                <Box sx={{ minWidth: 30 }}></Box>
                                            }
                                            <ListItemText
                                                primary={
                                                    <Typography
                                                        sx={{ fontWeight: 600 }}
                                                        component="span"
                                                        variant="body2"
                                                        color="text.secondary"
                                                    >
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit
                                                    </Typography>
                                                }
                                            />
                                            <Box sx={{ float: "right", pl: 2 }}>
                                                <Typography
                                                    sx={{ fontWeight: 400, color: '#A0A0A0' }}
                                                    component="span"
                                                    variant="body2"
                                                >
                                                    5m
                                                </Typography>
                                            </Box>
                                            <IconButton aria-label="check" size="small" sx={{ ml: 2 }}>
                                                <CheckCircleIcon fontSize="large" sx={{ color: '#ccc' }} />
                                            </IconButton>
                                        </ListItem>
                                        <Divider variant="middle" component="li" sx={{ marginLeft: '40px' }} />
                                        <ListItem alignItems="center" key={1} sx={{ p: 2 }}>
                                            {showCircles[1] ?
                                                <ListItemIcon size="small" sx={{ minWidth: 30 }}>
                                                    <CircleIcon fontSize="inherit" sx={{ color: '#ff5968' }} />
                                                </ListItemIcon>
                                                :
                                                <Box sx={{ minWidth: 30 }}></Box>
                                            }
                                            <ListItemText
                                                primary={
                                                    <Typography
                                                        sx={{ fontWeight: 600 }}
                                                        component="span"
                                                        variant="body2"
                                                        color="text.secondary"
                                                    >
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit
                                                    </Typography>
                                                }
                                            />
                                            <Box sx={{ float: "right", pl: 2 }}>
                                                <Typography
                                                    sx={{ fontWeight: 400, color: '#A0A0A0' }}
                                                    component="span"
                                                    variant="body2"
                                                >
                                                    12m
                                                </Typography>
                                            </Box>
                                            <IconButton aria-label="check" size="small" sx={{ ml: 2 }}>
                                                <CheckCircleIcon fontSize="large" sx={{ color: '#ccc' }} />
                                            </IconButton>
                                        </ListItem>
                                        <Divider variant="middle" component="li" sx={{ marginLeft: '40px' }} />
                                        <ListItem alignItems="center" key={2} sx={{ p: 2 }}>
                                            {showCircles[2] ?
                                                <ListItemIcon size="small" sx={{ minWidth: 30 }}>
                                                    <CircleIcon fontSize="inherit" sx={{ color: '#ff5968' }} />
                                                </ListItemIcon>
                                                :
                                                <Box sx={{ minWidth: 30 }}></Box>
                                            }
                                            <ListItemText
                                                primary={
                                                    <Typography
                                                        sx={{ fontWeight: 600 }}
                                                        component="span"
                                                        variant="body2"
                                                        color="text.secondary"
                                                    >
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit
                                                    </Typography>
                                                }
                                            />
                                            <Box sx={{ float: "right", pl: 2 }}>
                                                <Typography
                                                    sx={{ fontWeight: 400, color: '#A0A0A0' }}
                                                    component="span"
                                                    variant="body2"
                                                >
                                                    2h
                                                </Typography>
                                            </Box>
                                            <IconButton aria-label="check" size="small" sx={{ ml: 2 }}>
                                                <CheckCircleIcon fontSize="large" sx={{ color: '#ccc' }} />
                                            </IconButton>
                                        </ListItem>
                                        <Divider variant="middle" component="li" sx={{ marginLeft: '40px' }} />
                                        <ListItem alignItems="center" key={2} sx={{ p: 2 }}>
                                            {showCircles[3] ?
                                                <ListItemIcon size="small" sx={{ minWidth: 30 }}>
                                                    <CircleIcon fontSize="inherit" sx={{ color: '#ff5968' }} />
                                                </ListItemIcon>
                                                :
                                                <Box sx={{ minWidth: 30 }}></Box>
                                            }
                                            <ListItemText
                                                primary={
                                                    <Typography
                                                        sx={{ fontWeight: 600 }}
                                                        component="span"
                                                        variant="body2"
                                                        color="text.secondary"
                                                    >
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit
                                                    </Typography>
                                                }
                                            />
                                            <Box sx={{ float: "right", pl: 2 }}>
                                                <Typography
                                                    sx={{ fontWeight: 400, color: '#A0A0A0' }}
                                                    component="span"
                                                    variant="body2"
                                                >
                                                    1d
                                                </Typography>
                                            </Box>
                                            <IconButton aria-label="check" size="small" sx={{ ml: 2 }}>
                                                <CheckCircleIcon fontSize="large" sx={{ color: '#51D67D' }} />
                                            </IconButton>
                                        </ListItem>
                                    </List>
                                }
                            </CardContent>
                        </Card>
                    }
                </Grid>
            </Grid>
        </StyledContainer>
    );
}

export default Notifications;