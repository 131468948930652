/** React imports */
import React from 'react';

/** Icons */
import {ReactComponent as CheckIcon} from "../../assets/images/check-icon.svg";
import {ReactComponent as CloseIcon} from "../../assets/images/close-icon.svg";
import {ReactComponent as ErrorIcon} from '../../assets/images/error-icon.svg';
import {ReactComponent as InfoIcon} from '../../assets/images/info-icon.svg';
import {ReactComponent as WarningIcon} from "../../assets/images/warning-icon.svg";

/** Components ----------------------------------------------------------- */
import { styled } from '@mui/material/styles';
import MuiAlert from '@mui/material/Alert';
import {Stack, IconButton, Snackbar, Typography}  from "@mui/material";

const PREFIX = 'CustomizedSnackbars';

const classes = {
    root: `${PREFIX}-root`,
    alertRoot: `${PREFIX}-alertRoot`,
    filledSuccess: `${PREFIX}-filledSuccess`,
    filledInfo: `${PREFIX}-filledInfo`,
    filledWarning: `${PREFIX}-filledWarning`,
    filledError: `${PREFIX}-filledError`,
    icon: `${PREFIX}-icon`,
    closeBtn: `${PREFIX}-closeBtn`,
    closeIcon: `${PREFIX}-closeIcon`
};

const Root = styled(Stack)((
    {
        theme
    }
) => ({
    [`&.${classes.root}`]: {
        width: '100%',
    },

    [`& .${classes.alertRoot}`]: {
        borderRadius: 40,
        boxShadow: '10px 10px 15px 0px rgba(3,24,81,0.15)',
        padding: '6px 22px',
    },

    [`& .${classes.filledSuccess}`]: {
        backgroundColor: '#cce7d3'
    },

    [`& .${classes.filledInfo}`]: {
        backgroundColor: '#b6e2e9'
    },

    [`& .${classes.filledWarning}`]: {
        backgroundColor: '#ffe8cb'
    },

    [`& .${classes.filledError}`]: {
        backgroundColor: '#fbd8e7'
    },

    [`& .${classes.icon}`]: {
        height: 20,
        fill: '#031851',
        paddingRight: 22,
    },

    [`& .${classes.closeBtn}`]: {
        display: 'block',
        marginTop: 'auto',
        marginBottom: 'auto',
        background: 'none !important',
        padding: '0 10px 0 24px !important'
    },

    [`& .${classes.closeIcon}`]: {
        height: 20,
        fill: '#031851',
    }
}));

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CustomizedSnackbars = (props) => {
    return (
        <Root className={classes.root} spacing={2}>
            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={props.show} autoHideDuration={6000} 
                onClose={props.onClose}
            >
                <Alert onClose={props.onClose} severity={props.type}
                    classes={{
                        root: classes.alertRoot,
                        filledSuccess: classes.filledSuccess, 
                        filledInfo: classes.filledInfo,
                        filledWarning: classes.filledWarning,
                        filledError: classes.filledError}}
                    iconMapping={{ 
                        success: <CheckIcon className={classes.icon} />,
                        info: <InfoIcon className={classes.icon} />,
                        warning: <WarningIcon className={classes.icon} />,
                        error: <ErrorIcon className={classes.icon} />}}
                    action={
                        <IconButton className={classes.closeBtn} onClick={props.onClose}>
                            <CloseIcon className={classes.closeIcon} />
                        </IconButton>
                    }
                >
                    <Typography variant="h4" color="textPrimary">  {props.message} </Typography>
                </Alert>
            </Snackbar>
        </Root>
    );
}

export default CustomizedSnackbars;