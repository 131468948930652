/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useState, useEffect } from "react";

/** Components imports */
import Banking from "./Banking";
import BankingSkeletons from "./BankingSkeletons";

const Clients = () => {
    /** Component states */
    const [typeData, setTypeData] = useState([]);
    const [franqData, setFranqData] = useState([]);
    const [groupData, setGroupData] = useState([]);

    useEffect(() => {
        (async () => {
            await fetch(`/getClientTypeFilter`, {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
                .then((res) => res.json())
                .then((data) => {
                    setTypeData(data);
                });

            await fetch(`/getClientFranchiseFilter`, {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
                .then((res) => res.json())
                .then((data) => {
                    setFranqData(data);
                });

            await fetch(`/getClientGroupsFilter`, {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
                .then((res) => res.json())
                .then((data) => {
                    setGroupData(data);
                });
        })();
    }, []);

    return (
        <>
            {franqData.length > 0 && groupData.length > 0 && typeData.length > 0 && (
                <Banking typeData={typeData} groupDataOG={groupData} franqDataOG={franqData} />
            )}
            {(franqData.length === 0 || groupData.length === 0 || typeData.length === 0) && <BankingSkeletons />}
        </>
    );
};

export default Clients;
