// React
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";
import moment from "moment";

// MUI
import {
    Alert,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    TextField,
} from "@mui/material";
import GridUI from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import {
    Grid,
    Table,
    TableHeaderRow,
    PagingPanel,
    TableEditRow,
    TableEditColumn,
} from "@devexpress/dx-react-grid-material-ui";
import { PagingState, IntegratedPaging, EditingState, DataTypeProvider } from "@devexpress/dx-react-grid";
import { Getter } from "@devexpress/dx-react-core";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";

const PREFIX = "Consulting";

const classes = {
    btnRoot: `${PREFIX}-btnRoot`,
    buttonContainer: `${PREFIX}-buttonContainer`,
    tableStriped: `${PREFIX}-tableStriped`,
    tableHeader: `${PREFIX}-tableHeader`,
    tableBody: `${PREFIX}-tableBody`,
    tableBody2: `${PREFIX}-tableBody2`,
    pager: `${PREFIX}-pager`,
    numericInput: `${PREFIX}-numericInput`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.btnRoot}`]: {
        border: "1px solid #ff5968",
        borderRadius: 40,
        "&:hover": {
            backgroundColor: "#2f3190",
            border: "1px solid #2f3190",
        },
        "&.MuiButton-root.Mui-disabled": {
            border: "1px solid #ffcdd2",
            backgroundColor: "#ffcdd2 !important",
            color: "#fff",
        },
    },
    [`& .${classes.buttonContainer}`]: {
        display: "flex",
        justifyContent: "flex-end",
        padding: 15,
    },
    [`& .${classes.tableStriped}`]: {
        "& tbody tr:nth-of-type(odd)": {
            backgroundColor: "rgba(47,49,144,0.1)",
        },
    },
    [`& .${classes.tableHeader}`]: {
        fontSize: "15px",
        fontWeight: 600,
        color: "#031851",
        borderBottom: "2px solid #031851",
    },
    [`& .${classes.tableBody}`]: {
        fontSize: "14px",
        fontWeight: 400,
        color: "#031851",
        padding: "5px",
    },
    [`& .${classes.tableBody2}`]: {
        padding: "5px",
        textAlign: "right",
    },
    [`& .${classes.pager}`]: {
        "& .Pagination-button": {
            paddingTop: 0,
            paddingBottom: 0,
            color: "#031851",
            fontWeight: 600,
        },
        "& .Pagination-activeButton": {
            backgroundColor: "rgba(47,49,144,0.3)",
        },
    },
}));

const StyledInput = styled(Input)(() => ({
    [`& .${classes.numericInput}`]: {
        textAlign: "right",
        width: "100%",
    },
}));

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "rgba(19, 31, 62, 0.80)",
        fontSize: 12,
        maxWidth: 320,
        borderRadius: 6,
        fontWeight: "normal",
    },
});

const EditButton = ({ onExecute }) => {
    /** Internationalization i18n */
    const [t] = useTranslation("global");
    return (
        <LightTooltip title={t("miscellaneous.edit")} aria-label="edit">
            <IconButton onClick={onExecute} size="large">
                <EditIcon color="primary" />
            </IconButton>
        </LightTooltip>
    );
};
const CommitButton = ({ onExecute }) => {
    /** Internationalization i18n */
    const [t] = useTranslation("global");
    return (
        <LightTooltip title={t("miscellaneous.save")} aria-label="save">
            <IconButton onClick={onExecute} size="large">
                <SaveIcon color="primary" />
            </IconButton>
        </LightTooltip>
    );
};

const CancelButton = ({ onExecute }) => {
    /** Internationalization i18n */
    const [t] = useTranslation("global");
    return (
        <LightTooltip title={t("miscellaneous.cancel")} aria-label="cancel">
            <IconButton color="secondary" onClick={onExecute} size="large">
                <CancelIcon />
            </IconButton>
        </LightTooltip>
    );
};

const tableHeaderCell = ({ ...props }) => <TableHeaderRow.Cell {...props} className={classes.tableHeader} />;

const TableCellComponent = ({ ...props }) => <Table.Cell {...props} className={classes.tableBody} />;

const EditCell = ({ ...props }) => {
    const { children } = props;
    const [t] = useTranslation("global");

    return (
        <TableEditColumn.Cell {...props} className={classes.tableBody2}>
            {React.Children.map(children, (child) =>
                child?.props.id === "edit" && props.tableRow.row.billed ? (
                    <LightTooltip title={t("miscellaneous.doneInv")} aria-label="doneInv">
                        <span>
                            <IconButton color="secondary" size="large" disabled>
                                <CheckIcon sx={{ color: "green" }} />
                            </IconButton>
                        </span>
                    </LightTooltip>
                ) : (
                    child
                )
            )}
        </TableEditColumn.Cell>
    );
};

const PagingPanelContainer = ({ ...props }) => <PagingPanel.Container {...props} className={classes.pager} />;

const commandComponents = {
    edit: EditButton,
    commit: CommitButton,
    cancel: CancelButton,
};

const Command = ({ id, onExecute }) => {
    const CommandButton = commandComponents[id];
    return <CommandButton onExecute={onExecute} />;
};

const NumberFormatCustom = (props) => {
    const todos = useSelector((state) => state.todos);

    const { inputRef, value, onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            value={value}
            getInputRef={inputRef}
            onValueChange={({ value: v }) => onChange({ target: { value: v } })}
            thousandSeparator={todos.amountFormat === "de-DE" ? "." : ","}
            decimalSeparator={todos.amountFormat === "de-DE" ? "," : "."}
            fixedDecimalScale={true}
            decimalScale={2}
            valueIsNumericString
        />
    );
};

const DescriptionEditor = ({ value, onValueChange }) => (
    <StyledInput
        value={value}
        onChange={(event) => onValueChange(event.target.value)}
        variant="standard"
        inputProps={{
            maxLength: 100,
        }}
        sx={{
            width: "100% !important",
        }}
    />
);

const HourEditor = ({ value, onValueChange }) => (
    <StyledInput
        value={parseFloat(value.replace(",", "."))}
        onChange={(event) => onValueChange(event.target.value)}
        variant="standard"
        type="number"
        classes={{
            input: classes.numericInput,
        }}
        inputProps={{
            min: 1,
            step: 0.5,
            onKeyDown: (event) => {
                event.preventDefault();
            },
        }}
    />
);

const PercentEditor = ({ value, onValueChange }) => (
    <StyledInput
        value={parseFloat(value.replace(",", "."))}
        onChange={(event) => {
            let auxValue = event.target.value >= 0 && event.target.value <= 100 ? event.target.value : value;

            onValueChange(parseFloat(auxValue).toFixed(2));
        }}
        variant="standard"
        type="number"
        classes={{
            input: classes.numericInput,
        }}
        endAdornment={<InputAdornment position="end">%</InputAdornment>}
        sx={{
            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                display: "none",
            },
        }}
    />
);

const CheckEditor = ({ value, onValueChange }) => (
    <Checkbox
        checked={value}
        onChange={(event) => onValueChange(event.target.checked ? 30 : 0)}
        sx={{ display: "flex", justifyContent: "center", borderRadius: "50%", margin: "0 auto", width: "50%" }}
    />
);

const DescriptionTypeProvider = (props) => <DataTypeProvider editorComponent={DescriptionEditor} {...props} />;

const HoursTypeProvider = (props) => <DataTypeProvider editorComponent={HourEditor} {...props} />;

const Formatter = ({ value }) => `${value} %`;

const PercentTypeProvider = (props) => (
    <DataTypeProvider formatterComponent={Formatter} editorComponent={PercentEditor} {...props} />
);

const CheckTypeProvider = (props) => (
    <DataTypeProvider formatterComponent={Formatter} editorComponent={CheckEditor} {...props} />
);

const defaultState = {
    baseFee: 0,
    hours: 0,
    feeProject: 0,
    discount: 0,
    fee: 0,
    description: "",
    invoiceLoad: false,
};

const tableColumnExtensions = [
    {
        columnName: "creationDate",
        align: "center",
        editingEnabled: false,
        width: "10%",
    },
    { columnName: "description", align: "left", wordWrapEnabled: true },
    { columnName: "hours", align: "center", width: "5%" },
    { columnName: "discount", align: "center", width: "5%" },
    {
        columnName: "invLoad",
        align: "center",
        width: "5%",
    },
    { columnName: "fee", align: "right", editingEnabled: false, width: "10%" },
];

export const Consulting = (props) => {
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    const todos = useSelector((state) => state.todos);

    // Properties
    const { actives, companyID, data, reloadProjects, serviceID, setalertType, setBackDrop, setShow, setTexto } = props;
    const tableMessages = {
        noData: t("miscellaneous.noData"),
    };
    const pagingMessages = {
        info: ({ from, to, count }) => `${from}${from < to ? `-${to}` : ""} ${t("miscellaneous.pagingOf")} ${count}`,
    };

    /** Component states */
    const [valuesConsulting, setConsulting] = useState(defaultState);
    const [isError, setIsError] = useState(false);
    const [columnDefs, setColumnsDefs] = useState([]);
    const [dataTable, setDataTable] = useState([]);
    const [descriptionColumns] = useState(["description"]);
    const [currencyColumns] = useState(["hours"]);
    const [percentColumns] = useState(["discount"]);
    const [checkColumns] = useState(["invLoad"]);
    const [editingRowIds, setEditingRowIds] = useState([]);

    /** Component functions */
    useEffect(() => {
        const columnsTemplate = [
            { name: "creationDate", title: t("invoicing.creationDate") },
            { name: "description", title: t("invoicing.description") },
            { name: "hours", title: t("miscellaneous.hours") },
            { name: "discount", title: t("miscellaneous.discount") },
            { name: "invLoad", title: t("miscellaneous.surCharge") },
            { name: "fee", title: t("services.fee") },
        ];

        let dataTable = [];

        data.projects_array && data.projects_array.forEach((data) => {
            if (data.since_date) {
                dataTable.push({
                    id: data.project_company_id,
                    creationDate: moment(data.since_date, "YYYY-MM-DD").format(todos.dateFormat),
                    description: data.description,
                    hours: parseFloat(data.consulting_hours).toLocaleString(todos.amountFormat, {
                        maximumFractionDigits: 1,
                    }),
                    discount: parseFloat(data.discount).toLocaleString(todos.amountFormat, {
                        maximumFractionDigits: 2,
                    }),
                    invLoad: data.surcharge ? 30 : 0,
                    fee: parseFloat(
                        data.fee * data.consulting_hours * (1 - data.discount / 100) * (data.surcharge ? 1.3 : 1)
                    ).toLocaleString(todos.amountFormat, {
                        maximumFractionDigits: 2,
                    }),
                    billed: data.billed,
                });
            }
        });

        setConsulting({
            baseFee: data.fee,
            discount: 0,
            hours: 1,
            feeProject: data.fee,
            fee: data.fee,
            description: "",
            invoiceLoad: false,
        });

        setColumnsDefs(columnsTemplate);
        setDataTable(dataTable);
    }, [data, t, todos.amountFormat, todos.dateFormat]);

    const handleChange = (event) => {
        setConsulting({
            ...valuesConsulting,
            [event.target.name]: event.target.value,
        });
    };

    const handleChangeHour = (event) => {
        let hourAux = event.target.value > 0 ? event.target.value : valuesConsulting.hours;

        setConsulting({
            ...valuesConsulting,
            hours: hourAux,
            feeProject: hourAux * valuesConsulting.baseFee,
            fee: valuesConsulting.invoiceLoad
                ? hourAux * valuesConsulting.baseFee * (1 - valuesConsulting.discount / 100) * (1 + 30 / 100)
                : hourAux * valuesConsulting.baseFee * (1 - valuesConsulting.discount / 100),
        });
    };

    const handleChangeDiscount = (event) => {
        let discountAux =
            event.target.value >= 0 && event.target.value <= 100 ? event.target.value : valuesConsulting.discount;

        setConsulting({
            ...valuesConsulting,
            discount: discountAux,
            fee: valuesConsulting.invoiceLoad
                ? valuesConsulting.feeProject * (1 - discountAux / 100) * (1 + 30 / 100)
                : valuesConsulting.feeProject * (1 - discountAux / 100),
        });
    };

    const handleChangeCheck = (event) => {
        setConsulting({
            ...valuesConsulting,
            invoiceLoad: event.target.checked,
            fee: event.target.checked
                ? valuesConsulting.fee * (1 + 30 / 100)
                : valuesConsulting.hours * valuesConsulting.baseFee * (1 - valuesConsulting.discount / 100),
        });
    };

    const updateProject = async () => {
        setBackDrop(true);

        let params = {
            company_id: companyID,
            service_id: serviceID,
            project_id: data.project_id,
            consulting_hours: parseFloat(valuesConsulting.hours),
            discount: valuesConsulting.discount ? parseFloat(valuesConsulting.discount) : 0,
            fee: valuesConsulting.fee,
            description: valuesConsulting.description,
            surcharge: valuesConsulting.invoiceLoad,
        };

        if (valuesConsulting.description !== "") {
            await fetch(`/addServicesAndProjects`, {
                method: "POST",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
                body: JSON.stringify(params),
            })
                .then((response) => {
                    if (response.ok) {
                        setTexto(t("miscellaneous.successfulUpdate"));
                        setalertType("success");
                        setShow(true);
                        reloadProjects(true);
                    } else {
                        setTexto(t("miscellaneous.updatingError"));
                        setalertType("error");
                        setShow(true);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            setTexto(t("miscellaneous.requiredData"));
            setalertType("warning");
            setShow(true);
            setIsError(true);
        }

        setBackDrop(false);
    };

    const commitChanges = async ({ changed }) => {
        let changedRows;

        if (Object.values(changed)[0]) {
            setBackDrop(true);

            let index = Object.keys(changed)[0];

            let hours = Object.values(changed)[0].hours
                ? parseFloat(Object.values(changed)[0].hours.replace(",", "."))
                : dataTable[index].hours.replace(",", ".");

            let discount = Object.values(changed)[0].discount
                ? parseFloat(Object.values(changed)[0].discount.replace(",", "."))
                : dataTable[index].discount.replace(",", ".");

            let description = Object.values(changed)[0].description ?? dataTable[index].description;

            let invLoad = Object.values(changed)[0].invLoad
                ? parseInt(Object.values(changed)[0].invLoad)
                : dataTable[index].invLoad;

            let newFee = hours * valuesConsulting.baseFee * (1 - discount / 100) * (1 + invLoad / 100);

            let params = {
                company_id: companyID,
                service_id: serviceID,
                project_company_id: dataTable[index].id,
                project_id: data.project_id,
                consulting_hours: hours,
                discount: discount,
                fee: newFee,
                description: description,
                surcharge: invLoad === 30,
            };

            if (isNaN(newFee)) {
                setTexto(t("miscellaneous.mustEnterNumeric"));
                setalertType("warning");
                setShow(true);
                setBackDrop(false);
                return;
            }
            if (discount < 0 || discount > 100) {
                setTexto(t("miscellaneous.discountVal"));
                setalertType("warning");
                setShow(true);
                setBackDrop(false);
                return;
            }
            if (invLoad !== 0 && invLoad !== 30) {
                setTexto(t("miscellaneous.discountInvLoad"));
                setalertType("warning");
                setShow(true);
                setBackDrop(false);
                return;
            }

            changed[index].fee = parseFloat(newFee).toLocaleString(todos.amountFormat, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });

            if (params.description !== "") {
                await fetch(`/addServicesAndProjects`, {
                    method: "POST",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                    },
                    body: JSON.stringify(params),
                })
                    .then((response) => {
                        if (response.ok) {
                            setTexto(t("miscellaneous.successfulUpdate"));
                            setalertType("success");
                            setShow(true);
                            reloadProjects(true);
                        } else {
                            setTexto(t("miscellaneous.updatingError"));
                            setalertType("error");
                            setShow(true);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });

                changedRows = dataTable.map((row, index) => (changed[index] ? { ...row, ...changed[index] } : row));

                setDataTable(changedRows);
            } else {
                setTexto(t("miscellaneous.requiredData"));
                setalertType("warning");
                setShow(true);
            }

            setBackDrop(false);
        }
    };

    return (
        <StyledContainer>
            <Card elevation={0}>
                <CardHeader title="Consulting" />
                <Divider />
                <CardContent>
                    {actives.includes("Accountant") && (
                        <GridUI container spacing={2} justifyContent="center" sx={{ paddingBottom: 4 }}>
                            <GridUI item>
                                <Alert
                                    severity="warning"
                                    sx={{
                                        borderRadius: 8,
                                        fontSize: 12,
                                        fontWeight: 600,
                                    }}
                                >
                                    {t("services.notPosibleContract")}
                                </Alert>
                            </GridUI>
                        </GridUI>
                    )}
                    <GridUI container alignItems="center" spacing={2}>
                        <GridUI item xs={12} sm={6} lg={2}>
                            <FormControl fullWidth variant="outlined" disabled>
                                <InputLabel htmlFor="outlined-adornment-baseFee">{t("services.baseRate")}</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-baseFee"
                                    value={valuesConsulting.baseFee}
                                    inputComponent={NumberFormatCustom}
                                    startAdornment={
                                        <InputAdornment disableTypography position="start">
                                            $
                                        </InputAdornment>
                                    }
                                    label={t("services.baseRate")}
                                />
                            </FormControl>
                        </GridUI>
                        <GridUI item xs={12} sm={6} lg={1}>
                            <TextField
                                fullWidth
                                label={t("miscellaneous.hours")}
                                value={valuesConsulting.hours}
                                name="hours"
                                onChange={handleChangeHour}
                                variant="outlined"
                                type="number"
                                inputProps={{
                                    min: 1,
                                    step: 0.5,
                                    onKeyDown: (event) => {
                                        event.preventDefault();
                                    },
                                }}
                            />
                        </GridUI>
                        <GridUI item xs={12} sm={6} lg={3}>
                            <FormControl fullWidth variant="outlined" disabled>
                                <InputLabel htmlFor="outlined-adornment-feeProject">
                                    {t("services.projectFee")}
                                </InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-feeProject"
                                    value={valuesConsulting.feeProject}
                                    inputComponent={NumberFormatCustom}
                                    startAdornment={
                                        <InputAdornment disableTypography position="start">
                                            $
                                        </InputAdornment>
                                    }
                                    label={t("services.projectFee")}
                                />
                            </FormControl>
                        </GridUI>
                        <GridUI item xs={12} sm={6} lg={3}>
                            <TextField
                                fullWidth
                                label={t("miscellaneous.discount")}
                                value={valuesConsulting.discount}
                                name="discount"
                                onChange={handleChangeDiscount}
                                onBlur={() =>
                                    setConsulting({
                                        ...valuesConsulting,
                                        discount: parseFloat(
                                            valuesConsulting.discount === "" ? 0 : valuesConsulting.discount
                                        ).toFixed(2),
                                    })
                                }
                                variant="outlined"
                                type="number"
                                InputProps={{
                                    inputProps: {
                                        min: 0,
                                        max: 100,
                                    },
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                }}
                                sx={{
                                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                        display: "none",
                                    },
                                }}
                            />
                        </GridUI>
                        <GridUI item xs={12} sm={6} lg={3}>
                            <FormControl fullWidth variant="outlined" disabled>
                                <InputLabel htmlFor="outlined-adornment-feeProject">{t("invoicing.fee")}</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-feeProject"
                                    value={valuesConsulting.fee}
                                    inputComponent={NumberFormatCustom}
                                    startAdornment={
                                        <InputAdornment disableTypography position="start">
                                            $
                                        </InputAdornment>
                                    }
                                    label={t("invoicing.fee")}
                                />
                            </FormControl>
                        </GridUI>
                        <GridUI item xs={12} sm={6}>
                            <TextField
                                label={t("invoicing.description")}
                                multiline
                                fullWidth
                                required
                                maxRows={2}
                                value={valuesConsulting.description}
                                name="description"
                                onChange={handleChange}
                                inputProps={{ maxLength: 100 }}
                                error={isError && valuesConsulting.description === ""}
                            />
                        </GridUI>
                        <GridUI item xs={12} sm={6}></GridUI>
                        <GridUI item xs={12} sm={6} lg={3}>
                            <LightTooltip title={t("miscellaneous.invLoad")} aria-label="invLoad">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="subscrip"
                                            onChange={handleChangeCheck}
                                            checked={valuesConsulting.invoiceLoad}
                                            inputProps={{
                                                "aria-label": "controlled",
                                            }}
                                        />
                                    }
                                    label={t("invoicing.invoiceLoad")}
                                />
                            </LightTooltip>
                        </GridUI>
                    </GridUI>
                </CardContent>
                <Divider />
                <Box className={classes.buttonContainer}>
                    <Button
                        color="secondary"
                        variant="contained"
                        disableElevation
                        disabled={actives.includes("Accountant")}
                        classes={{ root: classes.btnRoot }}
                        onClick={updateProject}
                    >
                        {t("miscellaneous.register")}
                    </Button>
                </Box>
            </Card>
            <Card elevation={0}>
                <CardHeader title={t("invoicing.consultHist")} sx={{ color: "#ff5968" }} />
                <Divider />
                <CardContent>
                    <GridUI container spacing={2} alignItems="center">
                        <GridUI item xs={12}>
                            <Grid rows={dataTable} columns={columnDefs}>
                                <EditingState
                                    editingRowIds={editingRowIds}
                                    onEditingRowIdsChange={setEditingRowIds}
                                    onCommitChanges={commitChanges}
                                    columnExtensions={tableColumnExtensions}
                                />
                                <PagingState defaultCurrentPage={0} pageSize={5} />
                                <IntegratedPaging />
                                <DescriptionTypeProvider for={descriptionColumns} />
                                <HoursTypeProvider for={currencyColumns} />
                                <PercentTypeProvider for={percentColumns} />
                                <CheckTypeProvider for={checkColumns} />
                                <Table
                                    columnExtensions={tableColumnExtensions}
                                    cellComponent={TableCellComponent}
                                    messages={tableMessages}
                                />
                                <TableHeaderRow cellComponent={tableHeaderCell} />
                                <TableEditRow />
                                <TableEditColumn
                                    showEditCommand
                                    headerCellComponent={tableHeaderCell}
                                    cellComponent={EditCell}
                                    commandComponent={Command}
                                />
                                <Getter
                                    name="tableColumns"
                                    computed={({ tableColumns }) => {
                                        const result = [
                                            ...tableColumns.filter((c) => c.type !== TableEditColumn.COLUMN_TYPE),
                                            {
                                                key: "editCommand",
                                                type: TableEditColumn.COLUMN_TYPE,
                                                width: 100,
                                            },
                                        ];
                                        return result;
                                    }}
                                />
                                <PagingPanel containerComponent={PagingPanelContainer} messages={pagingMessages} />
                            </Grid>
                        </GridUI>
                    </GridUI>
                </CardContent>
            </Card>
        </StyledContainer>
    );
};
