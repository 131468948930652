/** React */
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";

/** MUI icons */
import CheckIcon from "@mui/icons-material/Check";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

/** MUI imports */
import { styled } from "@mui/material/styles";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import {
    Box,
    FormControlLabel,
    Grid,
    InputAdornment,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Switch,
    TextField,
    Typography,
} from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

/** Component imports */
import { Additionals } from "./";

/** Component styles */
const PREFIX = "Recurring";

const classes = {
    switch: `${PREFIX}-switch`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    backgroundColor: "#F8F9F8",
    marginTop: 16,
    padding: 30,
    borderRadius: 30,

    [`& .${classes.switch}`]: {
        "& .MuiSwitch-switchBase .MuiSwitch-thumb": {
            color: "#FFF",
        },
        "& .MuiSwitch-switchBase + .MuiSwitch-track": {
            backgroundColor: "#031851",
        },
        "& .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb": {
            color: "#FF5968",
        },
        "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
            backgroundColor: "#FFA8B1",
        },
    },
}));

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)({
    backgroundColor: "#F8F9F8",
    boxShadow: "none",
    "&:before": {
        display: "none",
    },
});

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: "#031851" }} />} {...props} />
))({
    "& .MuiAccordionSummary-content": {
        margin: "12px 0",
    },
});

const AccordionDetails = styled(MuiAccordionDetails)({
    padding: "0px 0px 20px 0px",
    "& .MuiCard-root": {
        boxShadow: "none",
    },
});

export const Recurring = (props) => {
    // Properties
    const { isError, setAutoAlertType, setShow, setTexto, setValuesBookRecu, tariffAdditionals, valuesBookRecu,isErrorFocus, setIsErrorFocus } =
        props;
        
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    /** React redux */
    const todos = useSelector((state) => state.todos);

    /** Global variables */
    const sumTotal =
        parseFloat(valuesBookRecu.tothChecks) +
        parseFloat(valuesBookRecu.tothAR) +
        parseFloat(valuesBookRecu.tothAP) +
        parseFloat(valuesBookRecu.tothAccrual) +
        parseFloat(valuesBookRecu.tothClass) +
        parseFloat(valuesBookRecu.tothMulti) +
        parseFloat(valuesBookRecu.tothPer) +
        parseFloat(valuesBookRecu.tothOthers);

    const checks =
        valuesBookRecu.checkedChecks ||
        valuesBookRecu.checkedAR ||
        valuesBookRecu.checkedAP ||
        valuesBookRecu.checkedAccrual ||
        valuesBookRecu.checkedClass ||
        valuesBookRecu.checkedMulti ||
        valuesBookRecu.checkedPer ||
        valuesBookRecu.checkedOthers;

    /** Component functions */
    useEffect(() => {
        setValuesBookRecu({
            ...valuesBookRecu,
            recuChecks: checks,
            recuHours: sumTotal,
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sumTotal, checks]);

    const handleChangeSwitchHourly = (event) => {
        setValuesBookRecu({
            ...valuesBookRecu,
            recuBaseRate: event.target.checked ? 0 : valuesBookRecu.recuOriginalFee,
            recuDiscount: 0,
            recuProjectTotal: event.target.checked ? 0 : valuesBookRecu.recuOriginalFee,
            recuAdditionalsDiscount: 0,
            recuAdditionalsOnly: event.target.checked,
            checkedBook: event.target.checked,
            tothBook: 0,
            startDateBook: event.target.checked ? valuesBookRecu.initDate : null,
            finishDateBook: null,
            isError:false
        });
        setIsErrorFocus(false)
    };

    const handleChangeSinceDate = (date) => {
        if (date === null) {
            setValuesBookRecu({
                ...valuesBookRecu,
                initDate: date,
                recuAdditionalsOnly: false,
                checkedBook: false,
                tothBook: 0,
                startDateBook: null,
                finishDateBook: null,
            });
        } else {
            setValuesBookRecu({
                ...valuesBookRecu,
                initDate: moment(date).startOf("month"),
                isError:false
            });
        }
        setIsErrorFocus(false)
    };

    const handleChangeDiscount = (event) => {
        let discountAux =
            event.target.value >= 0 && event.target.value <= 100 ? event.target.value : valuesBookRecu.recuDiscount;

        setValuesBookRecu({
            ...valuesBookRecu,
            recuDiscount: discountAux,
            recuAdditionalsDiscount: discountAux,
            recuProjectTotal: valuesBookRecu.recuAdditionalsOnly
                ? (valuesBookRecu.tothBook ?? 0) * valuesBookRecu.recuHourRate * (1 - discountAux / 100)
                : valuesBookRecu.recuOriginalFee * (1 - discountAux / 100),
                isError:false
        });
        setIsErrorFocus(false)
    };

    return (
        <StyledContainer>
            <LocalizationProvider
                dateAdapter={AdapterMoment}
                adapterLocale={t("language.locale")}
                localeText={{ clearButtonLabel: t("miscellaneous.clear") }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography
                            variant="h1"
                            color="primary"
                            sx={{
                                display: "inline-block",
                                fontWeight: 700,
                                pr: 6,
                            }}
                        >
                            {t(`invoicing.recurring`)}
                        </Typography>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={valuesBookRecu.recuAdditionalsOnly}
                                    className={classes.switch}
                                    color="secondary"
                                    disabled={!valuesBookRecu.initDate}
                                    inputProps={{ "aria-label": "controlled" }}
                                    name="payAnnuallyRecu"
                                    onChange={handleChangeSwitchHourly}
                                />
                            }
                            label={
                                <Typography variant="body2" align="center" sx={{ lineHeight: 0 }}>
                                    {t("proposals.hourly")}
                                </Typography>
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Accordion
                            TransitionProps={{ unmountOnExit: true }}
                            sx={{
                                borderBottom: "3px solid #ff5968",
                            }}
                        >
                            <AccordionSummary aria-controls="panel-content" id="panel-header">
                                <Typography
                                    variant="h2"
                                    align="center"
                                    sx={{
                                        fontWeight: 700,
                                    }}
                                >
                                    {t("invoicing.description")}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <List>
                                    <ListItem disableGutters alignItems="center">
                                        <ListItemIcon sx={{ minWidth: 35 }}>
                                            <CheckIcon fontSize="small" sx={{ color: "#031851" }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={
                                                <Typography variant="body1">
                                                    <span style={{ fontWeight: 600 }}>{t("proposals.dediBook")} </span>-{" "}
                                                    {t("proposals.aPubBook")}
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                    <ListItem disableGutters alignItems="center">
                                        <ListItemIcon sx={{ minWidth: 35 }}>
                                            <CheckIcon fontSize="small" sx={{ color: "#031851" }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={
                                                <Typography variant="body1">
                                                    <span style={{ fontWeight: 600 }}>{t("proposals.bankRec")} </span>-{" "}
                                                    {t("proposals.bankRecDes")}
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                    <ListItem disableGutters alignItems="center">
                                        <ListItemIcon sx={{ minWidth: 35 }}>
                                            <CheckIcon fontSize="small" sx={{ color: "#031851" }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={
                                                <Typography variant="body1">
                                                    <span style={{ fontWeight: 600 }}>{t("proposals.modCashAcc")} </span>-{" "}
                                                    {t("proposals.tranReCash")}
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                    <ListItem disableGutters alignItems="center">
                                        <ListItemIcon sx={{ minWidth: 35 }}>
                                            <CheckIcon fontSize="small" sx={{ color: "#031851" }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={
                                                <Typography variant="body1">
                                                    <span style={{ fontWeight: 600 }}>
                                                        {t("proposals.finannState")}{" "}
                                                    </span>
                                                    - {t("proposals.balanceGen2")}
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                    <ListItem disableGutters alignItems="center">
                                        <ListItemIcon sx={{ minWidth: 35 }}>
                                            <CheckIcon fontSize="small" sx={{ color: "#031851" }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={
                                                <Typography variant="body1">
                                                    <span style={{ fontWeight: 600 }}>
                                                        {t("proposals.writtenSup")}{" "}
                                                    </span>
                                                    - {t("proposals.UnlimSup")}
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                </List>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                    <Grid item container xs={12} spacing={2} sx={{ mt: 0.5 }}>
                        <Grid item xs={12} sm={6} lg={3}>
                            <DatePicker
                                componentsProps={{
                                    actionBar: {
                                        actions: ["clear"],
                                    },
                                }}
                                value={valuesBookRecu.initDate}
                                onChange={(newValue) => handleChangeSinceDate(newValue)}
                                inputFormat={todos.dateFormat}
                                renderInput={(params) => (
                                    <TextField
                                        fullWidth
                                        required
                                        {...params}
                                        error={isError && !valuesBookRecu.initDate}
                                        onClick={(e) =>{setIsErrorFocus(false)}}
                                    />
                                )}
                                // inputProps={{
                                //     readOnly: true,
                                // }}
                                label={t("reports.initDate")}
                                views={["year", "month"]}
                                inputProps={{
                                    onKeyDown: (event) => {
                                        event.preventDefault();
                                    },
                                }}
                                onClick={(e) =>{setIsErrorFocus(false)}}
                                inputRef={(input) => { (input && isErrorFocus && isError && !valuesBookRecu.initDate) && input.focus()}}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3}>
                            <TextField
                                fullWidth
                                name="projectDiscount"
                                label={t("miscellaneous.discount")}
                                value={valuesBookRecu.recuDiscount}
                                onChange={handleChangeDiscount}
                                onBlur={() =>
                                    setValuesBookRecu({
                                        ...valuesBookRecu,
                                        recuDiscount: parseFloat(
                                            valuesBookRecu.recuDiscount === "" ? 0 : valuesBookRecu.recuDiscount
                                        ).toFixed(2),
                                        recuAdditionalsDiscount: parseFloat(
                                            valuesBookRecu.recuDiscount === ""
                                                ? 0
                                                : valuesBookRecu.recuAdditionalsDiscount
                                        ).toFixed(2),
                                    })
                                }
                                variant="outlined"
                                type="number"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                    inputProps: {
                                        min: 0,
                                        max: 100,
                                    },
                                }}
                                sx={{
                                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                        display: "none",
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3}>
                            <TextField
                                fullWidth
                                name="additionalsDiscount"
                                label={t("proposals.additionalsDiscount")}
                                value={valuesBookRecu.recuAdditionalsDiscount}
                                onChange={handleChangeDiscount}
                                onBlur={() =>
                                    setValuesBookRecu({
                                        ...valuesBookRecu,
                                        recuDiscount: parseFloat(
                                            valuesBookRecu.recuAdditionalsDiscount === ""
                                                ? 0
                                                : valuesBookRecu.recuDiscount
                                        ).toFixed(2),
                                        recuAdditionalsDiscount: parseFloat(
                                            valuesBookRecu.recuAdditionalsDiscount === ""
                                                ? 0
                                                : valuesBookRecu.recuAdditionalsDiscount
                                        ).toFixed(2),
                                    })
                                }
                                variant="outlined"
                                type="number"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                    inputProps: {
                                        min: 0,
                                        max: 100,
                                    },
                                }}
                                sx={{
                                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                        display: "none",
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Additionals
                                addHourFee={tariffAdditionals}
                                values={valuesBookRecu}
                                set={setValuesBookRecu}
                                setTexto={setTexto}
                                setAutoAlertType={setAutoAlertType}
                                setShow={setShow}
                                sumTotal={sumTotal}
                                isError={isError}
                                isErrorFocus={isErrorFocus}
                                setIsErrorFocus={setIsErrorFocus}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </LocalizationProvider>
        </StyledContainer>
    );
};
