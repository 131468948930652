import React from "react";
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import calls from './calls'

//Imágenes
import check from '../../assets/images/kiiper_check.svg';
import close from '../../assets/images/kiiper_Close.svg';
import AlertIcon from "../../assets/images/alert.svg";

// Declaring momenty object
var moment = require('moment'); // require
var statusName2 = "";

const util = {
    // Check and save the language variable in session storage
    getSessionStorageLanguage: () => {
        let language = 'es';

        if ( sessionStorage.getItem('lng') ) {
            language = sessionStorage.getItem('lng');
            return language;
        }
        sessionStorage.setItem('lng', language);
        return language;
    },

    getAndBuildGridDataDeclaration: (dropDownListEvent, statusName, orgIdSelected) => {
        // Retrieving and setting data to perform a request to Xero
        const statusInfo = util.getStatusInfoConceptDeclaration(statusName);
        const taxInfo = util.getTaxInfoConceptDeclaration(dropDownListEvent);
        return (

            // Getting specific organization data
            calls.getStatements(orgIdSelected, taxInfo.id, statusInfo.id).then(result => {
                return {
                    data: result.data,
                    taxInfo: taxInfo,
                    statusInfo: statusInfo
                }
            })
        );
    },

    getAndBuildGridDataImpRetIVA: async (orgIdSelected, startDate, endDate, contactID) => {
        // Getting specific organization data
        await calls.generateSupplierIVAReport(
            orgIdSelected,
            startDate,
            endDate,
            contactID
        )
    },

    getAndBuildGridDataImpRetISLR: async (orgIdSelected, startDate, endDate, contactID) => {
        // Getting specific organization data
        await calls.generateSupplierISLRReport(
            orgIdSelected,
            startDate,
            endDate,
            contactID
        );
    },

    getAndBuildGridDataReportsEstResult: async (orgIdSelected, startDate, endDate) => {
        return (
            // Getting specific organization data
            await calls.getprofitAndLossReport(
                orgIdSelected,
                startDate,
                endDate
            ).then(result => {
                return { reportId: result.data }
            })
        );
    },

    getAndBuildGridDataReportsLedgerSum: async (data) => {
        return (
            // Getting specific organization data
            await calls.generalLedgerSummaryReport(data).then(result => {
                return { reportId: result.data }
            })
        );
    },

    getAndBuildGridDataReportsLedger: async (data) => {
        return (
            // Getting specific organization data
            await calls.generalLedgerReport(data).then(result => {
                return { reportId: result.data }
            })
        );
    },

    getAndBuildGridDataReportsRetFuente: async (orgIdSelected, startDate, endDate, contactID) => {
        return (
            // Getting specific organization data
            await calls.RetFuenteReport(
                orgIdSelected,
                startDate,
                endDate,
                contactID
            ).then(result => {
                return { reportId: result.data }
            })
        );
    },

    getAndBuildGridDataReportsRetICA: async (orgIdSelected, startDate, endDate, contactID) => {
        return (
            // Getting specific organization data
            await calls.RetICAReport(
                orgIdSelected,
                startDate,
                endDate,
                contactID
            ).then(result => {
                return { reportId: result.data }
            })
        );
    },

    getAndBuildGridDataReportsImpretICA: async (orgIdSelected, startDate, endDate) => {
        return (
            // Getting specific organization data
            await calls.impICAReport(
                orgIdSelected,
                startDate,
                endDate
            ).then(result => {
                return { reportId: result.data }
            })
        );
    },

    getAndBuildGridDataReportsRetF: async (orgIdSelected, startDate, endDate) => {
        return (
            // Getting specific organization data
            await calls.impRetFuenteReport(
                orgIdSelected,
                startDate,
                endDate
            ).then(result => {
                return { reportId: result.data }
            })
        );
    },

    getAndBuildGridDataReportsRetIVA: async (orgIdSelected, startDate, endDate) => {
        return (
            // Getting specific organization data
            await calls.RetIVAReport(
                orgIdSelected,
                startDate,
                endDate
            ).then(result => {
                return { reportId: result.data }
            })
        );
    },

    getAndBuildGridDataReportsBalPrueba: async (orgIdSelected, startDate, endDate) => {
        return (
            // Getting specific organization data
            await calls.balPruebaReport(
                orgIdSelected,
                startDate,
                endDate
            ).then(result => {
                return { reportId: result.data }
            })
        );
    },

    getAndBuildGridDataReportsBalPruebaFiltered: async (orgIdSelected, startDate, endDate, contactID, accountID) => {
        return (
            // Getting specific organization data
            await calls.balPruebaReportFiltered(
                orgIdSelected,
                startDate,
                endDate,
                contactID,
                accountID
            ).then(result => {
                return { reportId: result.data }
            })
        );
    },

    /// Helps to determine the status of a voucher
    /// @param {string} statusName - the name of the status
    getStatusInfoConcept: (statusName) => {

        // Deciding voucher type. Pending by default
        // Pending [Borrador o Aprobado en Compras] = 1
        // Approved [Aprobado o Recibido] = 2
        // Nulified [Anulado] = 3
        // Stored [Archivado] = 4
        let statusInfo = {
            id: 1,
            name: "Pendientes"
        };

        switch (statusName) {

            case "Recibidos":
                statusInfo.id = 2;
                statusInfo.name = "Recibidos";
                break;

            case "Anulados":
                statusInfo.id = 3;
                statusInfo.name = "Anulados";
                break;

            case "Archivados":
                statusInfo.id = 4;
                statusInfo.name = "Archivados";
                break;

            // No cambiar
            case "Aprobados":
                statusInfo.id = 2;
                statusInfo.name = "Aprobados";
                break;

            case "PorAprobar":
                statusInfo.id = 5;
                statusInfo.name = "PorAprobar";
                break;

            case "Declarados":
                statusInfo.id = 6;
                statusInfo.name = "Declarados";
                break;

            case "PorPagar":
                statusInfo.id = 7;
                statusInfo.name = "PorPagar";
                break;

            case "Pagados":
                statusInfo.id = 8;
                statusInfo.name = "Pagados";
                break;

            case "PorGenerar":
                statusInfo.id = 9;
                statusInfo.name = "PorGenerar";
                break;

            default:
                break;
        }

        return statusInfo;
    },

    getStatusInfoConceptDeclaration: (statusName) => {
        let statusInfo = {
            id: 1,
            name: "PorGenerar"
        };

        switch (statusName) {

            case "PorGenerar":
                statusInfo.id = 1;
                statusInfo.name = "PorGenerar";
                break;

            case "PorAprobar":
                statusInfo.id = 2;
                statusInfo.name = "PorAprobar";
                break;

            case "Aprobados":
                statusInfo.id = 3;
                statusInfo.name = "Aprobados";
                break;

            case "PorDeclarar":
                statusInfo.id = 4;
                statusInfo.name = "PorDeclarar";
                break;

            case "PorPagar":
                statusInfo.id = 5;
                statusInfo.name = "PorPagar";
                break;

            case "Pagados":
                statusInfo.id = 6;
                statusInfo.name = "Pagados";
                break;

            default:
                break;
        }

        return statusInfo;
    },

    /// Helps to get the kind of tax of a voucher
    /// @param {float} taxIndex - The index configured by tax in DropDownList events property
    getTaxInfoConceptDeclaration: (taxIndex) => {

        // Defining tax info depending on index configured in the DropDownList
        // taxinfo.id - its the internal id Xero gives the tax, in order to request
        // taxinfo.name - its used to rendering porpuses in Ventas component
        let taxInfo = {};

        switch (taxIndex) {
            case 3:
            case "IVA":
                taxInfo.name = "IVA";
                taxInfo.event = 3;
                taxInfo.id = 3;
                break;
            case 2:
            case "ISLR":
                taxInfo.name = "ISLR";
                taxInfo.event = 2;
                taxInfo.id = 2;
                break;

            case 1:
            case "RetIVA":
            default:
                taxInfo.name = "RetIVA";
                taxInfo.event = 1;
                taxInfo.id = 1;
                break;
        }

        return taxInfo;
    },

    /// Helps to get the kind of tax of a voucher
    /// @param {float} taxIndex - The index configured by tax in DropDownList events property
    getTaxInfoConcept: (taxIndex, kindOfPeople) => {

        // Defining tax info depending on index configured in the DropDownList
        // taxinfo.id - its the internal id Xero gives the tax, in order to request
        // taxinfo.name - its used to rendering porpuses in Ventas component
        let taxInfo = {};

        switch (taxIndex) {
            case 4.1:
            case "ISLR":
                kindOfPeople === "Cliente" ? taxInfo.id = 5 : taxInfo.id = 2;
                taxInfo.name = "ISLR";
                taxInfo.event = 4.1;
                break;

            case 4.2:
            case "IVA":
            default:
                kindOfPeople === "Cliente" ? taxInfo.id = 4 : taxInfo.id = 1;
                taxInfo.name = "IVA";
                taxInfo.event = 4.2;
                break;
        }

        return taxInfo;
    },

    /// Helps to know when a grid data needs to have editable columns
    /// @param {object} voucherStatus - status info of the voucher (sales, purchases)
    knowIfGridHeadersEditable: (voucherStatus) => {

        let isEditableGrid = false;

        // Validating voucher status equal to Pending [Borrador]
        if (voucherStatus.id === 1 && voucherStatus.name !== "Aprobados") {
            isEditableGrid = true;
        }

        return isEditableGrid;
    },

    //Crea el header del componente de ventas
    /// @param {object} Tipo - Maneja variable si es IVA o ISLR)
    /// @param {object} kindOfPeople - Indica si es cliente o proveedor 
    returnHeaderFlow: function (Tipo, kindOfPeople, statusName) {
        var columnDefs = [
            //#region hidden rows
            { headerName: 'withHoldingId', field: 'withHoldingId', xeroField: '_id', hide: true },
            //iid_tax_type: El tipo de impuesto; donde 1 es retenciones de IVA, 2 es retenciones de ISLR, 3 es retenciones 
            //de IVA a notas de crédito, 4 es retenciones de IVA a facturas de venta, y 5 es retenciones de ISLR a facturas de venta
            { headerName: 'ISLR / IVA', field: 'id_tax_type', xeroField: 'id_tax_type', hide: true },
            //id_status: El estatus del comprobante; donde 1 es borrador, 2 es aprobado, 3 es anulado, y 4 es archivado
            { headerName: 'B_A_A_A', field: 'id_status', xeroField: 'id_status', hide: true },
            { headerName: 'reissued', field: 'reissued', xeroField: 'reissued', hide: true },
            //#endregion hidden rows
            {
                headerName: 'No. Factura', field: 'NoFactura', xeroField: 'invoice_number', width: 125, cellClass: "grid-cell-Left",
                headerCheckboxSelection: function (params) {
                    switch (statusName2) {
                        case "Anulados":
                        case "Recibidos":
                        case "Aprobados":
                            break;
                        default:
                            return params.columnApi.getRowGroupColumns().length === 0;
                    }
                },
                checkboxSelection: function (params) {
                    return params.columnApi.getRowGroupColumns().length === 0;
                },
                cellStyle: params => {
                    if (statusName2 === "Anulados")
                        return params.data.reissued === true ? { 'pointer-events': 'none' } : '';
                }
            },
            { headerName: 'No. Control', field: 'Control', xeroField: 'invoice_control', width: 105, cellClass: "grid-cell-Left", headerClass: "grid-cell-Left" },
            { headerName: kindOfPeople, field: 'Contacto', xeroField: 'contact_name', headerClass: "centerHeader", filter: 'agTextColumnFilter', width: 215, sortable: true },
            { headerName: 'Fecha factura', field: 'FechaFactura', xeroField: 'invoice_date', filter: 'agTextColumnFilter', width: 133, sortable: true, headerClass: "centerHeader", cellClass: "grid-cell-cenRight", comparator: util.dateComparator },
            { headerName: 'Base imponible', field: 'invoice_subtotal', xeroField: 'invoice_subtotal', width: 135, sortable: true, type: 'rightAligned', comparator: util.currencyComparator },
            { headerName: 'Total ' + Tipo, field: 'TotalIVA', xeroField: 'invoice_total_tax', width: 110, sortable: true, type: 'rightAligned', comparator: util.currencyComparator },
            { headerName: '% retenido', field: 'Retencion', xeroField: 'retention_percentage', hide: Tipo === "IVA" ? false : true, calculated: true, width: 104, sortable: true, cellClass: "grid-cell-cenLeft", type: 'rightAligned' },
            { headerName: 'Monto retenido', field: 'MontoRetenido', xeroField: true, calculated: true, formulaName: 'retained_amount', width: 129, sortable: true, type: 'rightAligned', comparator: util.currencyComparator },
            { headerName: 'Fecha comprobante', field: 'date', xeroField: 'approval_date', filter: 'agTextColumnFilter', width: 170, sortable: true, headerClass: "centerHeader", cellClass: "grid-cell-centered", comparator: util.dateComparator },
            { headerName: 'No. Comprobante', field: 'Comprobante', xeroField: 'correlative', width: 140, sortable: true, headerClass: "centerHeader", cellClass: "grid-cell-centered" },
            { headerName: '', field: 'file', width: 20, cellRenderer: this.CellRendererP },
            {
                headerName: 'Remitido', field: 'Remitido', xeroField: 'Remitido', hide: statusName2 === "Anulados" ? false : true, width: 88, sortable: true, cellClass: "grid-cell-centered", cellRenderer: this.CellRendererCheck,
                valueGetter: function (params) {
                    return params.data.reissued === true ? "Sí" : 'No';
                }
            },
        ]
        return (columnDefs)
    },

    /// Crea el header del componente de declaracion
    /// @param {object} Tipo - Maneja variable si es IVA o ISLR)
    /// @param {object} kindOfPeople - Indica si es cliente o proveedor 
    returnHeaderDeclaration: function (Tipo, kindOfPeople, statusName) {

        var seeCommitmentColumn = ['PorDeclarar', 'PorPagar', 'Pagados'];
        var seePaymentColumn = ['PorPagar', 'Pagados'];
        var seeCertificateColumn = ['PorDeclarar', 'Pagados'];
        var seeAprobadosColumn = ['Aprobados'];

        var columnDefs = [
            //#region hidden rows
            { headerName: 'statementId', field: '_id', xeroField: '_id', hide: true },
            { headerName: 'warrant_file', field: 'warrant_file', xeroField: 'warrant_file', hide: true },
            { headerName: 'commitment_file', field: 'commitment_file', xeroField: 'commitment_file', hide: true },
            { headerName: 'payment_file', field: 'payment_file', xeroField: 'payment_file', hide: true },
            //#endregion hidden rows
            {
                headerName: 'Nombre', field: 'name', xeroField: 'name', width: 350,

                headerCheckboxSelection: function (params) {
                    return params.columnApi.getRowGroupColumns().length === 0;
                },
                checkboxSelection: function (params) {
                    return params.columnApi.getRowGroupColumns().length === 0;
                },
            },
            { headerName: 'Fecha límite', field: 'FechaLimite', xeroField: 'due_date', headerClass: "centerHeader", cellClass: "grid-cell-centered", filter: 'agTextColumnFilter', width: 150, sortable: true, comparator: util.dateComparator },
            { headerName: 'Base imponible', field: 'invoice_subtotal', xeroField: 'statement_total_amount', formulaName: 'statement_total_amount', width: 175, sortable: true, type: 'rightAligned' },
            { headerName: 'Total retenido', field: 'Retencion', xeroField: 'statement_total_tax', formulaName: 'statement_total_tax', calculated: true, width: 120, sortable: true, type: 'rightAligned', comparator: util.currencyComparator },
            { headerName: 'Aprobación GCA', field: 'AprobacionGCA', xeroField: 'AprobacionGCA', hide: !seeAprobadosColumn.includes(statusName), width: 135, sortable: true, type: 'rightAligned', cellClass: "grid-cell-centered", cellRenderer: this.CellRendererCheck },
            { headerName: 'Aprobación cliente', field: 'AprobacionCliente', xeroField: 'AprobacionCliente', hide: !seeAprobadosColumn.includes(statusName), width: 150, sortable: true, type: 'rightAligned', cellClass: "grid-cell-centered", cellRenderer: this.CellRendererCheck },
            { headerName: 'Cuenta Xero', field: 'cuentaXero', xeroField: 'tax_balance', formulaName: 'tax_balance', type: 'rightAligned', hide: statusName === "PorGenerar" || statusName === "PorAprobar" ? false : true, calculated: true, width: 120, sortable: true },
            { headerName: 'Diferencia ', field: 'Diferencia', xeroField: 'Diferencia', formulaName: 'Diferencia', type: 'rightAligned', hide: statusName === "PorGenerar" || statusName === "PorAprobar" ? false : true, calculated: true, width: 120, sortable: true },
            { headerName: 'Auxiliar', field: 'Auxiliar', xeroField: 'auxiliar', calculated: true, width: 110, sortable: true, headerClass: "centerHeader", cellClass: "grid-cell-centered", cellRenderer: this.CellRendererDecDow },
            { headerName: 'Compromiso', field: 'compromiso', xeroField: 'compromiso', hide: !seeCommitmentColumn.includes(statusName), calculated: true, width: 110, sortable: true, type: 'rightAligned', cellClass: "grid-cell-centered", cellRenderer: statusName === 'PorDeclarar' ? this.CellRendererDecUp : (seePaymentColumn.includes(statusName) ? this.CellRendererDecDow : this.CellRendererDecUp) },
            { headerName: 'Pago', field: 'Pago', xeroField: 'pago', hide: !seePaymentColumn.includes(statusName), calculated: true, width: 110, sortable: true, headerClass: "centerHeader", cellClass: "grid-cell-centered", cellRenderer: statusName === 'PorPagar' ? this.CellRendererDecUp : this.CellRendererDecDow },
            { headerName: 'Certificado', field: 'Certificado', xeroField: 'certificado', hide: !seeCertificateColumn.includes(statusName), calculated: true, width: 110, sortable: true, type: 'rightAligned', cellClass: "grid-cell-centered", cellRenderer: seePaymentColumn.includes(statusName) ? this.CellRendererDecDow : this.CellRendererDecUp },
        ]
        return (columnDefs)
    },

    returnHeaderFinancialBooks: function () {
        var columnDefs = [
            { headerName: '_id', field: '_id', xeroField: '_id', hide: true, },
            { headerName: "Fecha fin", field: "end_date", xeroField: "end_date", flex: 1, headerClass: "centerHeader", cellClass: "grid-cell-centered", comparator: util.dateComparator },
            { headerName: "Fecha creación", field: "creation_date", xeroField: "creation_date", flex: 1, headerClass: "centerHeader", cellClass: "grid-cell-centered" },
            { headerName: "Hora creación", field: "creation_hour", xeroField: "creation_hour", flex: 1, headerClass: "centerHeader", cellClass: "grid-cell-centered" },
            { headerName: "Archivo", field: "file", flex: 1, cellRenderer: this.fileColumnRendererFinancial, headerClass: "centerHeader", cellClass: "grid-cell-cenLeft" },
        ]
        return (columnDefs)
    },

    returnHeaderFinancialBooks2: function () {
        var columnDefs = [
            { headerName: '_id', field: '_id', xeroField: '_id', hide: true, },
            { headerName: "Fecha inicio", field: "init_date", xeroField: "init_date", flex: 1, headerClass: "centerHeader", cellClass: "grid-cell-centered", comparator: util.dateComparator },
            { headerName: "Fecha fin", field: "end_date", xeroField: "end_date", flex: 1, headerClass: "centerHeader", cellClass: "grid-cell-centered", comparator: util.dateComparator },
            { headerName: "Fecha creación", field: "creation_date", xeroField: "creation_date", flex: 1, headerClass: "centerHeader", cellClass: "grid-cell-centered" },
            { headerName: "Hora creación", field: "creation_hour", xeroField: "creation_hour", flex: 1, headerClass: "centerHeader", cellClass: "grid-cell-centered" },
            { headerName: "Archivo", field: "file", flex: 1, cellRenderer: this.fileColumnRendererFinancial, headerClass: "centerHeader", cellClass: "grid-cell-cenLeft" },
        ]
        return (columnDefs)
    },


    returnHeaderInvoice: function (currencyInvoice, header) {
        return (
            [
                {
                    name: "behind",
                    title: header,
                },
                {
                    name: currencyInvoice,
                    title: currencyInvoice,
                },
            ]
        )
    },

    returnHeaderInvoiceBand: function (currencyInvoice, header) {
        return (
            [
                {
                    title: header,
                    children: [
                        { columnName: "behind" },
                        { columnName: currencyInvoice }
                    ]
                },
            ]
        )
    },

    returnHeaderExtension: function (currencyInvoice) {
        return (
            [
                { columnName: 'behind', align: 'left' },
                { columnName: currencyInvoice, align: 'right' }
            ]
        )
    },

    returnExtensionGroup: function (currencyInvoice) {
        var columnDefs = [];
        if (currencyInvoice.length > 0) {
            currencyInvoice.forEach((item) => {
                let array = {};
                array.columnName = item.Currency;
                array.align = "right";
                columnDefs.push(array);
            });
        }
        else {
            columnDefs = [{
                columnName: "/",
                align: "right"
            }];
        }

        return columnDefs;
    },

    returnHeaderInvoiceGroup: function (currencyInvoice, header) {
        var columnDefs = [];
        if (currencyInvoice.length > 0) {
            columnDefs = [{
                name: "behind",
                title: header
            }];

            currencyInvoice.forEach((item) => {
                let array = {};
                array.name = item.Currency;
                array.title = item.Currency;
                columnDefs.push(array);
            });

            columnDefs = this.distinctPanelGroup(columnDefs);
        }
        else {
            columnDefs = [{
                name: "behind",
                title: header
            },
            {
                name: "/",
                title: "/"
            }];
        }

        return columnDefs;
    },

    returnHeaderInvoiceGroupBand: function (currencyInvoice, tipo) {
        if (currencyInvoice.length > 0) {
            var children = [{
                columnName: "behind",
            }];

            currencyInvoice.forEach((item) => {
                let array = {};
                array.columnName = item.Currency;
                children.push(array);
            });

            children = this.distinctPanelGroupBand(children);

            var columnDefs = [
                {
                    title: tipo,
                    children
                },
            ];
        }
        else {
            columnDefs = [
                {
                    title: tipo,
                    children: [
                        { columnName: "behind" },
                        { columnName: "/" }
                    ]
                },
            ];
        }

        return columnDefs;
    },

    /** Función equivalente para obtener las columnas del nuevo estilo de tablas  */
    returnHeaderInvoiceGroup2: function (currencyInvoice, header) {

        let columnDefs = [
            { name: "behind", title: header }
        ];

        if (currencyInvoice.length > 0) {
            currencyInvoice.forEach((item) => {
                let array = {};
                array.name = item.Currency;
                array.title = item.Currency;
                array.comparator = util.currencyComparator;
                columnDefs.push(array);
            });

            columnDefs = this.distinctPanelGroup2(columnDefs);
        }
        else {
            columnDefs.push({
                name: "/",
                title: "/",
                comparator: util.currencyComparator,
            });
        }

        return columnDefs;
    },

    returnHeaderBillGroup: function (currencyBill, tipo, header) {
        var columnDefs = [];
        var children = [];

        if (currencyBill.length > 0) {

            children = [{
                headerName: header,
                field: "behind",
                headerClass: "textClass",
                cellClass: "textClass",
            }];

            currencyBill.forEach((item) => {
                let array = {};
                array.headerName = item.Currency;
                array.field = item.Currency;
                array.headerClass = "amountClass";
                array.cellClass = "amountClass";
                array.comparator = util.currencyComparator;
                children.push(array);
            });

            children = this.distinctPanelGroup(children);

            columnDefs = [
                {
                    headerName: tipo,
                    headerClass: "dashboardGridColumnGroup",
                    children
                },
            ];
        }
        else {
            children = [{
                headerName: header,
                field: "behind",
                headerClass: "textClass",
                cellClass: "textClass",
            },
            {
                headerName: "/",
                field: "/",
                headerClass: "amountClass",
                cellClass: "amountClass",
                comparator: util.currencyComparator,
            }];

            columnDefs = [
                {
                    headerName: tipo,
                    headerClass: "dashboardGridColumnGroup",
                    children
                },
            ];
        }
        return columnDefs;
    },

    //Coloca icono de advertencia o de vista
    // y se ejecuta laa acción para descargar documento en el grid de Declaraciones
    /// @param {object} params - parámetro 
    CellRendererBanksPanel: function (params) {
        var eDiv = document.createElement('div');
        eDiv.className = "file-container";

        var img = document.createElement('img');
        img.setAttribute("border", "0");
        img.setAttribute("width", "18");
        img.setAttribute("height", "21");
        img.setAttribute("src", params.data.isActive === true ? check : AlertIcon);
        img.setAttribute("style", "cursor: pointer");

        var span = document.createElement('span');
        span.appendChild(img);
        eDiv.appendChild(span);
        return eDiv;
    },

    //Coloca icono de descarga en el grid
    // y se ejecuta laa acción para descargar documento en el grid de Declaraciones
    /// @param {object} params - parámetro 
    CellRendererCheck: function (params) {
        var eDiv = document.createElement('div');
        eDiv.className = "file-container";
        var img = document.createElement('img');
        img.setAttribute("border", "0");
        img.setAttribute("width", "18");
        img.setAttribute("height", "21");
        img.setAttribute("src", params.column.colId.includes("GCA") || params.data.reissued === true ? check : close);
        eDiv.appendChild(img);

        if (params.data.reissued === true || params.column.colId.includes("Apro"))
            return eDiv;
    },

    //Action log in ag-grid
    printResult: function (res) {
        console.log('---------------------------------------');
        if (res.add) {
            res.add.forEach(function (rowNode) {
                console.log('Added Row Node', rowNode);
            });
        }
        if (res.remove) {
            res.remove.forEach(function (rowNode) {
                console.log('Removed Row Node', rowNode);
            });
        }
        if (res.update) {
            res.update.forEach(function (rowNode) {
                console.log('Updated Row Node', rowNode);
            });
        }
    },

    /// Ayuda a ordenar las columnas de tipo fecha de acuerdo al formato DD/MM/YYYY
    /// @param {string} date1 - primer valor de fecha a comparar
    /// @param {string} date2- segundo valor de fecha a comparar
    dateComparator: function (date1, date2) {
        var date1Number = parseInt(moment(date1, "DD/MM/YYYY").format("YYYYMMDD"));
        var date2Number = parseInt(moment(date2, "DD/MM/YYYY").format("YYYYMMDD"));
        return date1Number - date2Number;
    },

    //Valida si una cadena contiene algun dato de otra cadena
    contains: function (value, searchFor) {
        if (Object.prototype.toString.call(value) === '[object Array]') {

        }
        else {
            var v = (value || '').toLowerCase();
            var v2 = searchFor;
            if (v2) {
                v2 = v2.toLowerCase();
            }
            return v.indexOf(v2) > -1;
        }
    },

    /// Regresa rango de fechas del mes actual
    getmonthRange() {
        var date = new Date(), y = date.getFullYear(), m = date.getMonth();
        var firstDay = new Date(y, m, 1);
        var lastDay = new Date(y, m + 1, 0);
        return (
            { firstDay: moment(firstDay).format("DD/MM/YYYY"), lastDay: moment(lastDay).format("DD/MM/YYYY") }
        )
    },

    /// Regresa rango de fechas del mes previo al actual
    getPreviousRange() {
        var date = new Date(), y = date.getFullYear(), m = date.getMonth() - 1;
        var firstDay = new Date(y, m, 1);
        var lastDay = new Date(y, m + 1, 0);

        return (
            { firstDay: moment(firstDay).format("DD/MM/YYYY"), lastDay: moment(lastDay).format("DD/MM/YYYY") }
        )
    },

    //filtra por tipo de banco para obtener el estado de cuenta del mismo
    /// @param {object} _bank - Nombre del banco
    bankType: function (_bank) {
        var bankName = _bank;
        const banks = [
            { id: "01", name: "MercantilVES", formatPartial: 'txt', formatMonth: 'txt', url: "/convertBankStatement/MercantilNatural" },
            { id: "02", name: "MercantilVES", formatPartial: 'txt', formatMonth: 'pdf', url: "/convertBankStatement/MercantilJuridica" },
            { id: "03", name: "NacionaldeCréditoVES", formatPartial: 'xlsx', formatMonth: 'pdf', url: "/convertBankStatement/BNC" },
            { id: "04", name: "OccidentaldeDescuentoVES", formatPartial: 'csv', formatMonth: 'pdf', url: "/convertBankStatement/BOD" },
            { id: "05", name: "ProvincialUSD", formatPartial: 'xls', formatMonth: 'pdf', url: "/convertBankStatement/Provincial" },
            { id: "06", name: "BanescoUSD", formatPartial: 'txt', formatMonth: 'txt', url: "/convertBankStatement/BanescoUS" },
            { id: "07", name: "BanplusVES", formatPartial: 'xlsx', formatMonth: 'pdf', url: "/convertBankStatement/Banplus" },
            { id: "08", name: "AmerantUSD", formatPartial: 'pdf', formatMonth: 'pdf', url: "/convertBankStatement/AmerantUS" },
            { id: "09", name: "BicentenarioVES", formatPartial: 'xls', formatMonth: 'pdf', url: "/convertBankStatement/Bicentenario" },
            { id: "10", name: "MiBancoVES", formatPartial: 'txt', formatMonth: 'txt', url: "/convertBankStatement/MiBanco" },
            { id: "11", name: "MercadopagoVES", formatPartial: 'xls', formatMonth: 'xls', url: "/convertBankStatement/Mercadopago" },
            { id: "12", name: "VenezolanodeCreditoVES", formatPartial: 'csv', formatMonth: 'pdf', url: "/convertBankStatement/BVC" },
            { id: "13", name: "BancaribeVES", formatPartial: 'txt', formatMonth: 'pdf', url: "/convertBankStatement/Bancaribe" },
            { id: "14", name: "VenezuelaVES", formatPartial: 'xlsx', formatMonth: 'pdf', url: "/convertBankStatement/Vzla" },
            { id: "15", name: "SafraUSD", formatPartial: 'txt', formatMonth: 'txt', url: "/convertBankStatement/Safra" },
            { id: "16", name: "BanescoPanamaUSD", formatPartial: 'pdf', formatMonth: 'pdf', url: "/convertBankStatement/BanescoPanama" },
            { id: "17", name: "DeutscheUSD", formatPartial: 'xlsx', formatMonth: 'xlsx', url: "/convertBankStatement/Deutsche" },
            { id: "18", name: "MultibanKUSD", formatPartial: 'xlsx', formatMonth: 'xlsx', url: "/convertBankStatement/Multibank" },
            { id: "19", name: "SantanderCOP", formatPartial: 'csv', formatMonth: 'csv', url: "/convertBankStatement/Santander" },
            { id: "20", name: "CaroníVES", formatPartial: 'txt', formatMonth: 'pdf', url: "/convertBankStatement/Caroni" },
            { id: "21", name: "ExteriorVES", formatPartial: 'txt', formatMonth: 'pdf', url: "/convertBankStatement/Exterior" },
            { id: "22", name: "PlazaVES", formatPartial: 'xlsx', formatMonth: 'pdf', url: "/convertBankStatement/Plaza" },
            { id: "23", name: "TDBankUSD", formatPartial: 'pdf', formatMonth: 'pdf', url: "/convertBankStatement/TDBank" },
            { id: "24", name: "KearnyUSD", formatPartial: 'pdf', formatMonth: 'pdf', url: "/convertBankStatement/Kearny" },
            { id: "25", name: "WellsFargoUSD", formatPartial: 'pdf', formatMonth: 'pdf', url: "/convertBankStatement/WellsFargo" },
            { id: "26", name: "ChaseUSD", formatPartial: 'pdf', formatMonth: 'pdf', url: "/convertBankStatement/Chase" },
            { id: "27", name: "RidgewoodUSD", formatPartial: 'pdf', formatMonth: 'pdf', url: "/convertBankStatement/Ridgewood" },
            { id: "28", name: "SantanderCLP", formatPartial: 'xlsx', formatMonth: 'pdf', url: "/convertBankStatement/SantanderCLPChilePesos" },
            { id: "29", name: "ArcaUSD", formatPartial: 'pdf', formatMonth: 'pdf', url: "/convertBankStatement/ArcaUS" },
            { id: "30", name: "BandesUSD", formatPartial: 'pdf', formatMonth: 'pdf', url: "/convertBankStatement/BandesUruguayUS" },
            { id: "31", name: "BandesEUR", formatPartial: 'pdf', formatMonth: 'pdf', url: "/convertBankStatement/BandesUruguayEuros" },
            { id: "32", name: "MercantilPanamaUSD", formatPartial: 'pdf', formatMonth: 'pdf', url: "/convertBankStatement/MercantilPanamaUS" },
            { id: "33", name: "PaypalUSD", formatPartial: 'csv', formatMonth: 'pdf', url: "/convertBankStatement/PaypalUS" },
            { id: "34", name: "BancaribeCucacaoUSD", formatPartial: 'xlsx', formatMonth: 'pdf', url: "/convertBankStatement/BancaribeCuracaoBank" },
            { id: "35", name: "FaceBankUSD", formatPartial: 'xlsx', formatMonth: 'pdf', url: "/convertBankStatement/FacebankUS" },
            { id: "39", name: "CitibankUSD", formatPartial: 'pdf', formatMonth: 'pdf', url: "/convertBankStatement/CitibankUS" },
            { id: "40", name: "BCICLP", formatPartial: 'pdf', formatMonth: 'pdf', url: "/convertBankStatement/BCICLP" },
            { id: "41", name: "BancaribeInternationalUSD", formatPartial: 'pdf', formatMonth: 'pdf', url: "/convertBankStatement/BancaribeInternational" },
            { id: "42", name: "ITAUCOP", formatPartial: 'xls', formatMonth: 'pdf', url: "/convertBankStatement/ItauColombiaPesos" },
            { id: "43", name: "ProvincialVES", formatPartial: 'xls', formatMonth: 'pdf', url: "/convertBankStatement/Provincial" },
            { id: "44", name: "SantanderUSD", formatPartial: 'pdf', formatMonth: 'pdf', url: "/convertBankStatement/SantanderUSD" },
            { id: "47", name: "VeneolanodeCreditoUSD", formatPartial: 'csv', formatMonth: 'pdf', url: "/convertBankStatement/CaymanBranchUS" },
            { id: "48", name: "BankOfAmericaUSD", formatPartial: 'pdf', formatMonth: 'pdf', url: "/convertBankStatement/BankAmericaUS" },
            { id: "52", name: "BanescoVES", formatPartial: 'txt', formatMonth: 'pdf', url: "/convertBankStatement/Banesco" },
            { id: "36", name: "ValleyNationalUSD", formatPartial: 'xls', formatMonth: 'xls', url: "/convertBankStatement/ValleyNationalBankUS" },
            { id: "37", name: "CommerceUSD", formatPartial: 'txt', formatMonth: 'pdf', url: "/convertBankStatement/CommerceBank" },
            { id: "38", name: "VSInternationalRentalUSD", formatPartial: 'pdf', formatMonth: 'pdf', url: "/convertBankStatement/" },
            { id: "53", name: "AmericanExpressUSD", formatPartial: 'pdf', formatMonth: 'pdf', url: "/convertBankStatement/AmericanExpressUSD" },
            { id: "54", name: "AppleUSD", formatPartial: 'pdf', formatMonth: 'pdf', url: "/convertBankStatement/AppleUSD" },
            { id: "55", name: "CapitalOneUSD", formatPartial: 'pdf', formatMonth: 'pdf', url: "/convertBankStatement/CapitalOneUSD" },
            { id: "56", name: "HSBCPrivateUSD", formatPartial: 'pdf', formatMonth: 'pdf', url: "/convertBankStatement/HSBCPrivateUSD" },
            { id: "57", name: "PonceUSD", formatPartial: 'pdf', formatMonth: 'pdf', url: "/convertBankStatement/PonceUSD" },
            { id: "58", name: "USBankUSD", formatPartial: 'pdf', formatMonth: 'pdf', url: "/convertBankStatement/USBankUSD" },
            { id: "59", name: "SpaceCoastCreditUnion", formatPartial: 'pdf', formatMonth: 'pdf', url: "/convertBankStatement/SpaceCoastCreditUnionUSD" },
            { id: "60", name: "CitizensUSD", formatPartial: 'pdf', formatMonth: 'pdf', url: "/convertBankStatement/CitizensUSD" },
            { id: "61", name: "DaviviendaCOPSV", formatPartial: 'pdf', formatMonth: 'pdf', url: "/convertBankStatement/DaviviendaCOPSV" },
            { id: "62", name: "BancolombiaCOPSV", formatPartial: 'pdf', formatMonth: 'pdf', url: "/convertBankStatement/GrupoBancolombiaCOPSV" },
            { id: "63", name: "ProvincialCOPCH", formatPartial: 'pdf', formatMonth: 'pdf', url: "/convertBankStatement/ProvincialCOP" },
            { id: "64", name: "EasternBankUSD", formatPartial: 'pdf', formatMonth: 'pdf', url: "/convertBankStatement/EasternBankUSD" },
            { id: "65", name: "SamsClubUSD", formatPartial: 'pdf', formatMonth: 'pdf', url: "/convertBankStatement/SamsClubUSD" },
            { id: "66", name: "IBCComerceUSD", formatPartial: 'pdf', formatMonth: 'pdf', url: "/convertBankStatement/IBCComerceUSD" },
            { id: "67", name: "CaixaBank", formatPartial: 'pdf', formatMonth: 'pdf', url: "/convertBankStatement/CaixaBank" },
            { id: "68", name: "PNCBankUSD", formatPartial: 'pdf', formatMonth: 'pdf', url: "/convertBankStatement/PNCBank" },
            { id: "69", name: "BBTUSD", formatPartial: 'pdf', formatMonth: 'pdf', url: "/convertBankStatement/BBTBank" },
            { id: "70", name: "KeyBankUSD", formatPartial: 'pdf', formatMonth: 'pdf', url: "/convertBankStatement/KeyBank" },
            { id: "71", name: "RegionsBank", formatPartial: 'pdf', formatMonth: 'pdf', url: "/convertBankStatement/RegionsBank" },
            { id: "72", name: "EmiratesIslamicBank", formatPartial: 'pdf', formatMonth: 'pdf', url: "/convertBankStatement/EmiratesIslamicBank" },
            { id: "73", name: "AlfaBankRussia", formatPartial: 'pdf', formatMonth: 'pdf', url: "/convertBankStatement/AlfaBankRussia" },
            { id: "74", name: "RuveEUR", formatPartial: 'pdf', formatMonth: 'pdf', url: "/convertBankStatement/RuveEUR" },
            { id: "75", name: "BanCrecerVES", formatPartial: 'pdf', formatMonth: 'pdf', url: "/convertBankStatement/BanCrecer" }
            // Actualizar hasta el ID 69
        ];

        // eslint-disable-next-line array-callback-return
        var bankSelected = banks.filter((bank) => {
            if (bankName === bank.id) {
                return bank.id
            }
        });

        return bankSelected;
    },

    //Coloca icono de descarga en el grid
    // y se ejecuta laa acción para descargar documento
    /// @param {object} dateTimeA - Fecha inicial
    /// @param {object} dateTimeB - Fecha fin
    compareDates: function (dateTimeA, dateTimeB) {
        let momentA = '';
        let momentB = '';

        if(sessionStorage.getItem('lng') === 'en'){
            momentA = moment(dateTimeA, "MM/DD/YYYY");
            momentB = moment(dateTimeB, "MM/DD/YYYY");
        }else{
            momentA = moment(dateTimeA, "DD/MM/YYYY");
            momentB = moment(dateTimeB, "DD/MM/YYYY");
        }

        if (momentA > momentB) return 1;
        else if (momentA < momentB) return -1;
        else return 0;
    },

    // Functión que agrupa un array
    /// @param {object} arr - Arreglo
    /// @param {String} dateTimeB - Parámetro a agrupar
    distinct: function (array) {
        const result = [];
        const map = new Map();
        for (const item of array) {
            if (!map.has(item.id)) {
                map.set(item.id, true);    // set any value to Map
                result.push({
                    id: item.id,
                    name: item.name,
                    type: item.type
                });
            }
        }
        return result;
    },

    // Functión que agrupa un array
    /// @param {object} arr - Arreglo
    /// @param {String} dateTimeB - Parámetro a agrupar
    distinctPanelGroup: function (array) {
        const result = [];
        const map = new Map();
        for (const item of array) {
            if (!map.has(item.name)) {
                map.set(item.name, true);    // set any value to Map
                result.push({
                    title: item.title,
                    name: item.name,
                });
            }
        }
        return result;
    },

    // Functión que agrupa un array
    /// @param {object} arr - Arreglo
    /// @param {String} dateTimeB - Parámetro a agrupar
    distinctPanelGroupBand: function (array) {
        const result = [];
        const map = new Map();
        for (const item of array) {
            if (!map.has(item.columnName)) {
                map.set(item.columnName, true);    // set any value to Map
                result.push({
                    columnName: item.columnName,
                });
            }
        }
        return result;
    },

    /** Función equivalente que agrupa un arreglo para el nuevo estilo de tablas  */
    distinctPanelGroup2: function (array) {
        const result = [];
        const map = new Map();
        for (const item of array) {
            if (!map.has(item.name)) {
                map.set(item.name, true);    // set any value to Map
                result.push({
                    title: item.title,
                    name: item.name,
                    comparator: item.comparator,
                });
            }
        }
        return result;
    },

    //Función utilizada para realizar una unión entre 2 arreglos de datos
    mergeArrayObjects: function (arr1, arr2, key) {
        let aux;
        arr1.forEach((item, i) => {
            if (item[key] === arr2[i][key]) {
                //merging two objects
                aux = Object.assign({}, item, arr2[i]);
            }
        });
        return aux
    },

    //Crea el Copyright
    Copyright: function () {
        return (
            <Typography variant="body2" color="textSecondary" align="center">
                {'Copyright © '}
                <Link color="inherit" href="/">kiiper</Link>{' '}
                {new Date().getFullYear()}
                {'.'}
            </Typography>
        );
    },
};

export default util;