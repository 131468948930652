/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useEffect } from "react";

/** MUI imports */
import { styled } from "@mui/material/styles";
import { Box, Chip, Grid, Typography } from "@mui/material";

/** Components */
import { Xero, KiiperBanking, KiiperTaxes, KiiperReporting, CustomerSupp } from "./";

/** Component styles */
const PREFIX = "PropSubsDetails";

const classes = {
    cardContentRoot: `${PREFIX}-cardContentRoot`,
    chip: `${PREFIX}-chip`,
    selectedChip: `${PREFIX}-selectedChip`,
    projectBox: `${PREFIX}-projectBox`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.cardContentRoot}`]: {
        padding: "24px 48px 48px",
    },

    [`& .${classes.chip}`]: {
        padding: 24,
        width: 210,
        backgroundColor: "#EAEAF4",
        color: "#031851",
        fontWeight: "700 !important",
        "&:hover": {
            backgroundColor: "#EAEAF4",
            color: "#031851",
        },
    },

    [`& .${classes.selectedChip}`]: {
        padding: 24,
        width: 210,
        backgroundColor: "#2f3190",
        color: "#fff",
        fontWeight: "700 !important",
        "&:hover": {
            backgroundColor: "#2f3190",
            color: "#fff",
        },
    },

    [`& .${classes.projectBox}`]: {
        backgroundColor: "#F8F9F8",
        marginTop: 16,
        padding: 30,
        borderRadius: 30,
    },
}));

/** Global variables */
const latam = [
    "Argentina",
    "Bolivia",
    "Brasil",
    "Chile",
    "Colombia",
    "Costa Rica",
    "Cuba",
    "Ecuador",
    "El Salvador",
    "Guatemala",
    "Haití",
    "Honduras",
    "México",
    "Nicaragua",
    "Panamá",
    "Paraguay",
    "Perú",
    "República Dominicana",
    "Uruguay",
];

export const PropSubsDetails = (props) => {
    // Properties
    const {
        services,
        setServices,
        setValuesSubsXero,
        valuesSubsXero,
        valuesSubsBanking,
        setValuesSubsBanking,
        valuesSubsTaxes,
        setValuesSubsTaxes,
        valuesSubsReport,
        setValuesSubsReport,
        valuesSubsCustomer,
        setValuesSubsCustomer,
        isError,
        isErrorFocus,
        setIsErrorFocus,
        setShow,
        setTexto,
        setAutoAlertType,
        clientMarket,
        showtutorial,
        showFinalTutorial,
        includeAccModuleSub,
        handleChangeCheckAccSub,
        includeAdmModuleSub,
        handleChangeCheckAdmSub,
        accountantOnboarding,
    } = props;

    /** Component functions */
    useEffect(() => {
        if (
            !valuesSubsXero.chip &&
            !valuesSubsBanking.chip &&
            !valuesSubsReport.chip &&
            !valuesSubsTaxes.chip &&
            !valuesSubsCustomer.chip
        ) {
            const updateServices = services.map((service) => {
                if (service.name === "softSubs") {
                    return {
                        ...service,
                        isActive: false,
                    };
                } else {
                    return {
                        ...service,
                    };
                }
            });
            setServices(updateServices);
        } else {
            const updateServices = services.map((service) => {
                if (service.name === "softSubs") {
                    return {
                        ...service,
                        isActive: true,
                    };
                } else {
                    return {
                        ...service,
                    };
                }
            });
            setServices(updateServices);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        valuesSubsXero.chip,
        valuesSubsBanking.chip,
        valuesSubsReport.chip,
        valuesSubsTaxes.chip,
        valuesSubsCustomer.chip,
    ]);

    const handleClickChip = (value, option) => {
        if (option === "xero") {
            setValuesSubsXero({
                ...valuesSubsXero,
                chip: !value,
                originalFee: valuesSubsXero.originalFee,
                discount: 0,
                version: "",
                projectTotal: !value ? valuesSubsXero.originalFee : 0,
                totalFee: !value ? valuesSubsXero.originalFee : 0,
                xeroChecks: false,
                tothExp: 0,
                checkedExp: false,
                startDateExp: null,
                finishDateExp: null,
                tothPro: 0,
                checkedPro: false,
                startDatePro: null,
                finishDatePro: null,
                checkedPlus: false,
                startDatePlus: null,
                finishDatePlus: null,
                isError: false,
                sinceDateProposal: null,
            });
        }
        if (option === "banking") {
            setValuesSubsBanking({
                ...valuesSubsBanking,
                chip: !value,
                originalFee: valuesSubsBanking.originalFee,
                discount: 0,
                version: "",
                projectTotal: !value ? valuesSubsBanking.originalFee : 0,
                isError: false,
                sinceDateProposal: null,
            });
        }
        if (option === "taxes") {
            setValuesSubsTaxes({
                ...valuesSubsTaxes,
                chip: !value,
                originalFee: valuesSubsTaxes.originalFee,
                discount: 0,
                version: "",
                projectTotal: !value ? valuesSubsTaxes.originalFee : 0,
                isError: false,
                sinceDateProposal: null,
            });
        }
        if (option === "report") {
            setValuesSubsReport({
                ...valuesSubsReport,
                chip: !value,
                originalFee: 10,
                discount: 0,
                version: "",
                projectTotal: !value ? 10 : 0,
                isError: false,
                sinceDateProposal: null,
            });
        }
        if (option === "support") {
            let fee = 0;

            fee = clientMarket === "Venezuela" ? 25 : latam.includes(clientMarket) ? 30 : 35;

            setValuesSubsCustomer({
                ...valuesSubsCustomer,
                chip: !value,
                originalFee: fee,
                discount: 0,
                hours: 1,
                projectTotal: !value ? fee : 0,
                isError: false,
                sinceDateProposal: null,
            });
        }
        if (showtutorial && !value) {
            setTimeout(() => showFinalTutorial("projectsSubs"), 500);
        }
    };

    return (
        <StyledContainer>
            <Grid id="softSubs" container justifyContent="space-around" alignItems="center" spacing={2}>
                <Grid item>
                    <Chip
                        id="xero-chip"
                        label={
                            <Typography variant="h2" align="center" sx={{ fontWeight: 700 }}>
                                Xero
                            </Typography>
                        }
                        className={valuesSubsXero.chip ? classes.selectedChip : classes.chip}
                        onClick={() => handleClickChip(valuesSubsXero.chip, "xero")}
                    />
                </Grid>
                <Grid item>
                    <Chip
                        id="banking-chip"
                        label={
                            <Typography variant="h2" align="center" sx={{ fontWeight: 700 }}>
                                Kiiper Banking
                            </Typography>
                        }
                        className={valuesSubsBanking.chip ? classes.selectedChip : classes.chip}
                        onClick={() => handleClickChip(valuesSubsBanking.chip, "banking")}
                    />
                </Grid>
                {(clientMarket === "Venezuela" || clientMarket === "LATAM") && (
                    <Grid item>
                        <Chip
                            id="taxes-chip"
                            label={
                                <Typography variant="h2" align="center" sx={{ fontWeight: 700 }}>
                                    Kiiper Taxes
                                </Typography>
                            }
                            className={valuesSubsTaxes.chip ? classes.selectedChip : classes.chip}
                            onClick={() => handleClickChip(valuesSubsTaxes.chip, "taxes")}
                        />
                    </Grid>
                )}
                <Grid item>
                    <Chip
                        id="report-chip"
                        label={
                            <Typography variant="h2" align="center" sx={{ fontWeight: 700 }}>
                                Kiiper Reporting
                            </Typography>
                        }
                        className={valuesSubsReport.chip ? classes.selectedChip : classes.chip}
                        onClick={() => handleClickChip(valuesSubsReport.chip, "report")}
                    />
                </Grid>
                <Grid item>
                    <Chip
                        id="support-chip"
                        label={
                            <Typography variant="h2" align="center" sx={{ fontWeight: 700 }}>
                                Customer Support
                            </Typography>
                        }
                        className={valuesSubsCustomer.chip ? classes.selectedChip : classes.chip}
                        onClick={() => handleClickChip(valuesSubsCustomer.chip, "support")}
                    />
                </Grid>
            </Grid>
            <Box id="projectsSubs">
                {valuesSubsXero.chip && (
                    <Xero
                        xero={valuesSubsXero}
                        setXero={setValuesSubsXero}
                        isError={isError}
                        setShow={setShow}
                        setAutoAlertType={setAutoAlertType}
                        setTexto={setTexto}
                        isErrorFocus={isErrorFocus}
                        setIsErrorFocus={setIsErrorFocus}
                        includeAccModuleSub={includeAccModuleSub}
                        handleChangeCheckAccSub={handleChangeCheckAccSub}
                        includeAdmModuleSub={includeAdmModuleSub}
                        handleChangeCheckAdmSub={handleChangeCheckAdmSub}
                        accountantOnboarding={accountantOnboarding}
                    />
                )}
                {valuesSubsBanking.chip && (
                    <KiiperBanking
                        banking={valuesSubsBanking}
                        setBanking={setValuesSubsBanking}
                        isError={isError}
                        isErrorFocus={isErrorFocus}
                        setIsErrorFocus={setIsErrorFocus}
                    />
                )}
                {valuesSubsTaxes.chip && (
                    <KiiperTaxes
                        taxes={valuesSubsTaxes}
                        setTaxes={setValuesSubsTaxes}
                        isError={isError}
                        isErrorFocus={isErrorFocus}
                        clientMarket={clientMarket}
                        setIsErrorFocus={setIsErrorFocus}
                    />
                )}
                {valuesSubsReport.chip && (
                    <KiiperReporting
                        report={valuesSubsReport}
                        setReport={setValuesSubsReport}
                        isError={isError}
                        isErrorFocus={isErrorFocus}
                        setIsErrorFocus={setIsErrorFocus}
                    />
                )}
                {valuesSubsCustomer.chip && (
                    <CustomerSupp
                        support={valuesSubsCustomer}
                        setSupport={setValuesSubsCustomer}
                        isError={isError}
                        isErrorFocus={isErrorFocus}
                        setIsErrorFocus={setIsErrorFocus}
                    />
                )}
            </Box>
        </StyledContainer>
    );
};
