const countries = [
  {
    name_es: "Afganistán",
    name_en: "Afghanistan",
    code: "Afganistán"
  },
  {
    name_es: "Albania",
    name_en: "Albania",
    code: "Albania"
  },
  {
    name_es: "Alemania",
    name_en: "Germany",
    code: "Alemania"
  },
  {
    name_es: "Andorra",
    name_en: "Andorra",
    code: "Andorra"
  },
  {
    name_es: "Angola",
    name_en: "Angola",
    code: "Angola"
  },
  {
    name_es: "Anguilla",
    name_en: "Anguilla",
    code: "Anguilla"
  },
  {
    name_es: "Antigua y Barbuda",
    name_en: "Antigua & Barbuda",
    code: "Antigua y Barbuda"
  },
  {
    name_es: "Antillas Holandesas",
    name_en: "Netherlands Antilles",
    code: "Antillas Holandesas"
  },
  {
    name_es: "Antártida",
    name_en: "Antarctica",
    code: "Antártida"
  },
  {
    name_es: "Arabia Saudita",
    name_en: "Saudi Arabia",
    code: "Arabia Saudita"
  },
  {
    name_es: "Argelia",
    name_en: "Algeria",
    code: "Argelia"
  },
  {
    name_es: "Argentina",
    name_en: "Argentina",
    code: "Argentina"
  },
  {
    name_es: "Armenia",
    name_en: "Armenia",
    code: "Armenia"
  },
  {
    name_es: "Aruba",
    name_en: "Aruba",
    code: "Aruba"
  },
  {
    name_es: "Australia",
    name_en: "Australia",
    code: "Australia"
  },
  {
    name_es: "Austria",
    name_en: "Austria",
    code: "Austria"
  },
  {
    name_es: "Azerbaiyán",
    name_en: "Azerbaijan",
    code: "Azerbaiyán"
  },
  {
    name_es: "Bahamas",
    name_en: "Bahamas",
    code: "Bahamas"
  },
  {
    name_es: "Banglades",
    name_en: "Bangladesh",
    code: "Banglades"
  },
  {
    name_es: "Barbados",
    name_en: "Barbados",
    code: "Barbados"
  },
  {
    name_es: "Baréin",
    name_en: "Bahrain",
    code: "Baréin"
  },
  {
    name_es: "Belice",
    name_en: "Belize",
    code: "Belice"
  },
  {
    name_es: "Benin",
    name_en: "Benin",
    code: "Benin"
  },
  {
    name_es: "Bermudas",
    name_en: "Bermuda",
    code: "Bermudas"
  },
  {
    name_es: "Bielorrusia",
    name_en: "Belarus",
    code: "Bielorrusia"
  },
  {
    name_es: "Birmania",
    name_en: "Myanmar",
    code: "Birmania"
  },
  {
    name_es: "Bolivia",
    name_en: "Bolivia",
    code: "Bolivia"
  },
  {
    name_es: "Bosnia-Herzegovina",
    name_en: "Bosnia & Herzegovina",
    code: "Bosnia-Herzegovina"
  },
  {
    name_es: "Botsuana",
    name_en: "Botswana",
    code: "Botsuana"
  },
  {
    name_es: "Bouvet",
    name_en: "Bouvet",
    code: "Bouvet"
  },
  {
    name_es: "Brasil",
    name_en: "Brazil",
    code: "Brasil"
  },
  {
    name_es: "Brunei",
    name_en: "Brunei Darussalam",
    code: "Brunei"
  },
  {
    name_es: "Bulgaria",
    name_en: "Bulgaria",
    code: "Bulgaria"
  },
  {
    name_es: "Burkina Faso",
    name_en: "Burkina Faso",
    code: "Burkina Faso"
  },
  {
    name_es: "Burundi",
    name_en: "Burundi",
    code: "Burundi"
  },
  {
    name_es: "Butan",
    name_en: "Bhutan",
    code: "Butan"
  },
  {
    name_es: "Bélgica",
    name_en: "Belgium",
    code: "Bélgica"
  },
  {
    name_es: "Cabo Verde",
    name_en: "Cape Verde",
    code: "Cabo Verde"
  },
  {
    name_es: "Camboya",
    name_en: "Cambodia",
    code: "Camboya"
  },
  {
    name_es: "Camerún",
    name_en: "Cameroon",
    code: "Camerún"
  },
  {
    name_es: "Canadá",
    name_en: "Canada",
    code: "Canadá"
  },
  {
    name_es: "Chad",
    name_en: "Chad",
    code: "Chad"
  },
  {
    name_es: "Chile",
    name_en: "Chile",
    code: "Chile"
  },
  {
    name_es: "China",
    name_en: "China",
    code: "China"
  },
  {
    name_es: "Chipre",
    name_en: "Cyprus",
    code: "Chipre"
  },
  {
    name_es: "Ciudad del Vaticano",
    name_en: "Holy See (Vatican City State)",
    code: "Ciudad del Vaticano"
  },
  {
    name_es: "Comoras",
    name_en: "Comoros",
    code: "Comoras"
  },
  {
    name_es: "Congo",
    name_en: "Congo",
    code: "Congo"
  },
  {
    name_es: "Corea del Norte",
    name_en: "Korea, Democratic People's Republic of",
    code: "Corea del Norte"
  },
  {
    name_es: "Corea del Sur",
    name_en: "Korea, Republic of",
    code: "Corea del Sur"
  },
  {
    name_es: "Costa Rica",
    name_en: "Costa Rica",
    code: "Costa Rica"
  },
  {
    name_es: "Costa de Marfil",
    name_en: "Cote d'Ivoire",
    code: "Costa de Marfil"
  },
  {
    name_es: "Croacia",
    name_en: "Croatia",
    code: "Croacia"
  },
  {
    name_es: "Cuba",
    name_en: "Cuba",
    code: "Cuba"
  },
  {
    name_es: "Dinamarca",
    name_en: "Denmark",
    code: "Dinamarca"
  },
  {
    name_es: "Dominica",
    name_en: "Dominica",
    code: "Dominica"
  },
  {
    name_es: "Ecuador",
    name_en: "Ecuador",
    code: "Ecuador"
  },
  {
    name_es: "Egipto",
    name_en: "Egypt",
    code: "Egipto"
  },
  {
    name_es: "El Salvador",
    name_en: "El Salvador",
    code: "El Salvador"
  },
  {
    name_es: "Emiratos Árabes Unidos",
    name_en: "United Arab Emirates",
    code: "Emiratos Árabes Unidos"
  },
  {
    name_es: "Eritrea",
    name_en: "Eritrea",
    code: "Eritrea"
  },
  {
    name_es: "Eslovaquia",
    name_en: "Slovakia",
    code: "Eslovaquia"
  },
  {
    name_es: "Eslovenia",
    name_en: "Slovenia",
    code: "Eslovenia"
  },
  {
    name_es: "España",
    name_en: "Spain",
    code: "España"
  },
  {
    name_es: "Estado de Palestina",
    name_en: "State of Palestine",
    code: "Estado de Palestina"
  },
  {
    name_es: "Estados Federados de Micronesia",
    name_en: "Micronesia, Federated States of",
    code: "Estados Federados de Micronesia"
  },
  {
    name_es: "Estonia",
    name_en: "Estonia",
    code: "Estonia"
  },
  {
    name_es: "Etiopía",
    name_en: "Ethiopia",
    code: "Etiopía"
  },
  {
    name_es: "Filipinas",
    name_en: "Philippines",
    code: "Filipinas"
  },
  {
    name_es: "Finlandia",
    name_en: "Finland",
    code: "Finlandia"
  },
  {
    name_es: "Fiyi",
    name_en: "Fiji",
    code: "Fiyi"
  },
  {
    name_es: "Francia",
    name_en: "France",
    code: "Francia"
  },
  {
    name_es: "Gabón",
    name_en: "Gabon",
    code: "Gabón"
  },
  {
    name_es: "Gambia",
    name_en: "Gambia",
    code: "Gambia"
  },
  {
    name_es: "Georgia",
    name_en: "Georgia",
    code: "Georgia"
  },
  {
    name_es: "Ghana",
    name_en: "Ghana",
    code: "Ghana"
  },
  {
    name_es: "Gibraltar",
    name_en: "Gibraltar",
    code: "Gibraltar"
  },
  {
    name_es: "Granada",
    name_en: "Grenada",
    code: "Granada"
  },
  {
    name_es: "Grecia",
    name_en: "Greece",
    code: "Grecia"
  },
  {
    name_es: "Groenlandia",
    name_en: "Greenland",
    code: "Groenlandia"
  },
  {
    name_es: "Guadalupe",
    name_en: "Guadeloupe",
    code: "Guadalupe"
  },
  {
    name_es: "Guam",
    name_en: "Guam",
    code: "Guam"
  },
  {
    name_es: "Guatemala",
    name_en: "Guatemala",
    code: "Guatemala"
  },
  {
    name_es: "Guayana Francesa",
    name_en: "French Guiana",
    code: "Guayana Francesa"
  },
  {
    name_es: "Guernsey",
    name_en: "Guernsey",
    code: "Guernsey"
  },
  {
    name_es: "Guinea",
    name_en: "Guinea",
    code: "Guinea"
  },
  {
    name_es: "Guinea Ecuatorial",
    name_en: "Equatorial Guinea",
    code: "Guinea Ecuatorial"
  },
  {
    name_es: "Guinea-Bisau",
    name_en: "Guinea-Bissau",
    code: "Guinea-Bisau"
  },
  {
    name_es: "Guyana",
    name_en: "Guyana",
    code: "Guyana"
  },
  {
    name_es: "Haití",
    name_en: "Haiti",
    code: "Haití"
  },
  {
    name_es: "Holanda",
    name_en: "Netherlands",
    code: "Holanda"
  },
  {
    name_es: "Honduras",
    name_en: "Honduras",
    code: "Honduras"
  },
  {
    name_es: "Hong Kong",
    name_en: "Hong Kong",
    code: "Hong Kong"
  },
  {
    name_es: "Hungría",
    name_en: "Hungary",
    code: "Hungría"
  },
  {
    name_es: "India",
    name_en: "India",
    code: "India"
  },
  {
    name_es: "Indonesia",
    name_en: "Indonesia",
    code: "Indonesia"
  },
  {
    name_es: "Iraq",
    name_en: "Iraq",
    code: "Iraq"
  },
  {
    name_es: "Irlanda",
    name_en: "Ireland",
    code: "Irlanda"
  },
  {
    name_es: "Irán",
    name_en: "Iran, Islamic Republic of",
    code: "Irán"
  },
  {
    name_es: "Isla Norfolk",
    name_en: "Norfolk Island",
    code: "Isla Norfolk"
  },
  {
    name_es: "Isla de Man",
    name_en: "Isle of Man",
    code: "Isla de Man"
  },
  {
    name_es: "Isla de Navidad",
    name_en: "Christmas Island",
    code: "Isla de Navidad"
  },
  {
    name_es: "Islandia",
    name_en: "Iceland",
    code: "Islandia"
  },
  {
    name_es: "Islas Caimán",
    name_en: "Cayman Islands",
    code: "Islas Caimán"
  },
  {
    name_es: "Islas Cocos",
    name_en: "Cocos (Keeling) Islands",
    code: "Islas Cocos"
  },
  {
    name_es: "Islas Cook",
    name_en: "Cook Islands",
    code: "Islas Cook"
  },
  {
    name_es: "Islas Feroe",
    name_en: "Faroe Islands",
    code: "Islas Feroe"
  },
  {
    name_es: "Islas Georgias del Sur y Sandwich del Sur",
    name_en: "South Georgia & the South Sandwich Islands",
    code: "Islas Georgias del Sur y Sandwich del Sur"
  },
  {
    name_es: "Islas Heard y McDonald",
    name_en: "Heard Island & McDonald Islands",
    code: "Islas Heard y McDonald"
  },
  {
    name_es: "Islas Malvinas",
    name_en: "Falkland Islands (Malvinas)",
    code: "Islas Malvinas"
  },
  {
    name_es: "Islas Marianas del Norte",
    name_en: "Northern Mariana Islands",
    code: "Islas Marianas del Norte"
  },
  {
    name_es: "Islas Marshall",
    name_en: "Marshall Islands",
    code: "Islas Marshall"
  },
  {
    name_es: "Islas Pitcairn",
    name_en: "Pitcairn",
    code: "Islas Pitcairn"
  },
  {
    name_es: "Islas Salomón",
    name_en: "Solomon Islands",
    code: "Islas Salomón"
  },
  {
    name_es: "Islas Turcas y Caicos",
    name_en: "Turks and Caicos Islands",
    code: "Islas Turcas y Caicos"
  },
  {
    name_es: "Islas Ultramarinas Menores de Estados Unidos",
    name_en: "United States Minor Outlying Islands",
    code: "Islas Ultramarinas Menores de Estados Unidos"
  },
  {
    name_es: "Islas Vírgenes Británicas",
    name_en: "Virgin Islands, British",
    code: "Islas Vírgenes Británicas"
  },
  {
    name_es: "Islas Vírgenes de los Estados Unidos",
    name_en: "Virgin Islands of the United States",
    code: "Islas Vírgenes de los Estados Unidos"
  },
  {
    name_es: "Israel",
    name_en: "Israel",
    code: "Israel"
  },
  {
    name_es: "Italia",
    name_en: "Italy",
    code: "Italia"
  },
  {
    name_es: "Jamaica",
    name_en: "Jamaica",
    code: "Jamaica"
  },
  {
    name_es: "Japón",
    name_en: "Japan",
    code: "Japón"
  },
  {
    name_es: "Jersey",
    name_en: "Jersey",
    code: "Jersey"
  },
  {
    name_es: "Jordania",
    name_en: "Jordan",
    code: "Jordania"
  },
  {
    name_es: "Kazajistán",
    name_en: "Kazakhstan",
    code: "Kazajistán"
  },
  {
    name_es: "Kenia",
    name_en: "Kenya",
    code: "Kenia"
  },
  {
    name_es: "Kirguistán",
    name_en: "Kyrgyzstan",
    code: "Kirguistán"
  },
  {
    name_es: "Kiribati",
    name_en: "Kiribati",
    code: "Kiribati"
  },
  {
    name_es: "Kuwait",
    name_en: "Kuwait",
    code: "Kuwait"
  },
  {
    name_es: "Laos",
    name_en: "Lao People's Democratic Republic",
    code: "Laos"
  },
  {
    name_es: "Lesoto",
    name_en: "Lesotho",
    code: "Lesoto"
  },
  {
    name_es: "Letonia",
    name_en: "Latvia",
    code: "Letonia"
  },
  {
    name_es: "Liberia",
    name_en: "Liberia",
    code: "Liberia"
  },
  {
    name_es: "Libia",
    name_en: "Libyan Arab Jamahiriya",
    code: "Libia"
  },
  {
    name_es: "Liechtenstein",
    name_en: "Liechtenstein",
    code: "Liechtenstein"
  },
  {
    name_es: "Lituania",
    name_en: "Lithuania",
    code: "Lituania"
  },
  {
    name_es: "Luxemburgo",
    name_en: "Luxembourg",
    code: "Luxemburgo"
  },
  {
    name_es: "Líbano",
    name_en: "Lebanon",
    code: "Líbano"
  },
  {
    name_es: "Macao",
    name_en: "Macao",
    code: "Macao"
  },
  {
    name_es: "Madagascar",
    name_en: "Madagascar",
    code: "Madagascar"
  },
  {
    name_es: "Malasia",
    name_en: "Malaysia",
    code: "Malasia"
  },
  {
    name_es: "Malaui",
    name_en: "Malawi",
    code: "Malaui"
  },
  {
    name_es: "Maldivas",
    name_en: "Maldives",
    code: "Maldivas"
  },
  {
    name_es: "Malta",
    name_en: "Malta",
    code: "Malta"
  },
  {
    name_es: "Malí",
    name_en: "Mali",
    code: "Malí"
  },
  {
    name_es: "Marruecos",
    name_en: "Morocco",
    code: "Marruecos"
  },
  {
    name_es: "Martinica",
    name_en: "Martinique",
    code: "Martinica"
  },
  {
    name_es: "Mauricio",
    name_en: "Mauritius",
    code: "Mauricio"
  },
  {
    name_es: "Mauritania",
    name_en: "Mauritania",
    code: "Mauritania"
  },
  {
    name_es: "Mayotte",
    name_en: "Mayotte",
    code: "Mayotte"
  },
  {
    name_es: "Moldavia",
    name_en: "Moldova, Republic of",
    code: "Moldavia"
  },
  {
    name_es: "Monaco",
    name_en: "Monaco",
    code: "Monaco"
  },
  {
    name_es: "Mongolia",
    name_en: "Mongolia",
    code: "Mongolia"
  },
  {
    name_es: "Montserrat",
    name_en: "Montserrat",
    code: "Montserrat"
  },
  {
    name_es: "Mozambique",
    name_en: "Mozambique",
    code: "Mozambique"
  },
  {
    name_es: "México",
    name_en: "Mexico",
    code: "México"
  },
  {
    name_es: "Namibia",
    name_en: "Namibia",
    code: "Namibia"
  },
  {
    name_es: "Nauru",
    name_en: "Nauru",
    code: "Nauru"
  },
  {
    name_es: "Nepal",
    name_en: "Nepal",
    code: "Nepal"
  },
  {
    name_es: "Nicaragua",
    name_en: "Nicaragua",
    code: "Nicaragua"
  },
  {
    name_es: "Niger",
    name_en: "Niger",
    code: "Niger"
  },
  {
    name_es: "Nigeria",
    name_en: "Nigeria",
    code: "Nigeria"
  },
  {
    name_es: "Niue",
    name_en: "Niue",
    code: "Niue"
  },
  {
    name_es: "Noruega",
    name_en: "Norway",
    code: "Noruega"
  },
  {
    name_es: "Nueva Caledonia",
    name_en: "New Caledonia",
    code: "Nueva Caledonia"
  },
  {
    name_es: "Nueva Zelanda",
    name_en: "New Zealand",
    code: "Nueva Zelanda"
  },
  {
    name_es: "Omán",
    name_en: "Oman",
    code: "Omán"
  },
  {
    name_es: "Pakistán",
    name_en: "Pakistan",
    code: "Pakistán"
  },
  {
    name_es: "Palaos",
    name_en: "Palau",
    code: "Palaos"
  },
  {
    name_es: "Papúa Nueva Guinea",
    name_en: "Papua New Guinea",
    code: "Papúa Nueva Guinea"
  },
  {
    name_es: "Paraguay",
    name_en: "Paraguay",
    code: "Paraguay"
  },
  {
    name_es: "Perú",
    name_en: "Peru",
    code: "Perú"
  },
  {
    name_es: "Polinesia Francesa",
    name_en: "French Polynesia",
    code: "Polinesia Francesa"
  },
  {
    name_es: "Polonia",
    name_en: "Poland",
    code: "Polonia"
  },
  {
    name_es: "Portugal",
    name_en: "Portugal",
    code: "Portugal"
  },
  {
    name_es: "Puerto Rico",
    name_en: "Puerto Rico",
    code: "Puerto Rico"
  },
  {
    name_es: "Qatar",
    name_en: "Qatar",
    code: "Qatar"
  },
  {
    name_es: "Ruanda",
    name_en: "Rwanda",
    code: "Ruanda"
  },
  {
    name_es: "Reino Unido",
    name_en: "United Kingdom",
    code: "Reino Unido"
  },
  {
    name_es: "República Centroafricana",
    name_en: "Central African Republic",
    code: "República Centroafricana"
  },
  {
    name_es: "República Checa",
    name_en: "Czech Republic",
    code: "República Checa"
  },
  {
    name_es: "República Democrática del Congo",
    name_en: "Congo, The Democratic Republic of the",
    code: "República Democrática del Congo"
  },
  {
    name_es: "República de Macedonia",
    name_en: "Macedonia, The Former Yugoslav Republic of",
    code: "República de Macedonia"
  },
  {
    name_es: "Reunion",
    name_en: "Réunion",
    code: "Reunion"
  },
  {
    name_es: "Rumania",
    name_en: "Romania",
    code: "Rumania"
  },
  {
    name_es: "Rusia",
    name_en: "Russia",
    code: "Rusia"
  },
  {
    name_es: "Samoa",
    name_en: "Samoa",
    code: "Samoa"
  },
  {
    name_es: "Samoa Americana",
    name_en: "American Samoa",
    code: "Samoa Americana"
  },
  {
    name_es: "San Cristóbal y Nieves",
    name_en: "Saint Kitts & Nevis",
    code: "San Cristóbal y Nieves"
  },
  {
    name_es: "San Marino",
    name_en: "San Marino",
    code: "San Marino"
  },
  {
    name_es: "San Pedro y Miquelon",
    name_en: "Saint Pierre & Miquelon",
    code: "San Pedro y Miquelon"
  },
  {
    name_es: "San Vicente y las Granadinas",
    name_en: "Saint Vincent & the Grenadines",
    code: "San Vicente y las Granadinas"
  },
  {
    name_es: "Santa Elena",
    name_en: "Saint Helena",
    code: "Santa Elena"
  },
  {
    name_es: "Santa Lucía",
    name_en: "Saint Lucia",
    code: "Santa Lucía"
  },
  {
    name_es: "Santo Tomé y Príncipe",
    name_en: "Sao Tome & Principe",
    code: "Santo Tomé y Príncipe"
  },
  {
    name_es: "Senegal",
    name_en: "Senegal",
    code: "Senegal"
  },
  {
    name_es: "Serbia",
    name_en: "Serbia",
    code: "Serbia"
  },
  {
    name_es: "Seychelles",
    name_en: "Seychelles",
    code: "Seychelles"
  },
  {
    name_es: "Sierra Leona",
    name_en: "Sierra Leone",
    code: "Sierra Leona"
  },
  {
    name_es: "Singapur",
    name_en: "Singapore",
    code: "Singapur"
  },
  {
    name_es: "Siria",
    name_en: "Syrian Arab Republic",
    code: "Siria"
  },
  {
    name_es: "Somalia",
    name_en: "Somalia",
    code: "Somalia"
  },
  {
    name_es: "Sri Lanka",
    name_en: "Sri Lanka",
    code: "Sri Lanka"
  },
  {
    name_es: "Suazilandia",
    name_en: "Swaziland",
    code: "Suazilandia"
  },
  {
    name_es: "Sudáfrica",
    name_en: "South Africa",
    code: "Sudáfrica"
  },
  {
    name_es: "Sudán",
    name_en: "Sudan",
    code: "Sudán"
  },
  {
    name_es: "Suecia",
    name_en: "Sweden",
    code: "Suecia"
  },
  {
    name_es: "Suiza",
    name_en: "Switzerland",
    code: "Suiza"
  },
  {
    name_es: "Surinam",
    name_en: "Suriname",
    code: "Surinam"
  },
  {
    name_es: "Svalbard y Jan Mayen",
    name_en: "Svalbard & Jan Mayen",
    code: "Svalbard y Jan Mayen"
  },
  {
    name_es: "Tailandia",
    name_en: "Thailand",
    code: "Tailandia"
  },
  {
    name_es: "Taiwán",
    name_en: "Taiwan, Province of China",
    code: "Taiwán"
  },
  {
    name_es: "Tanzania",
    name_en: "Tanzania, United Republic of",
    code: "Tanzania"
  },
  {
    name_es: "Tayikistán",
    name_en: "Tayikistán",
    code: "Tayikistán"
  },
  {
    name_es: "Territorio Británico del Océano Índico",
    name_en: "British Indian Ocean Territory",
    code: "Territorio Británico del Océano Índico"
  },
  {
    name_es: "Territorios Australes Franceses",
    name_en: "French Southern Territories",
    code: "Territorios Australes Franceses"
  },
  {
    name_es: "Timor Oriental",
    name_en: "Timor-Leste",
    code: "Timor Oriental"
  },
  {
    name_es: "Togo",
    name_en: "Togo",
    code: "Togo"
  },
  {
    name_es: "Tokelau",
    name_en: "Tokelau",
    code: "Tokelau"
  },
  {
    name_es: "Tonga",
    name_en: "Tonga",
    code: "Tonga"
  },
  {
    name_es: "Trinidad y Tobago",
    name_en: "Trinidad & Tobago",
    code: "Trinidad y Tobago"
  },
  {
    name_es: "Turkmenistán",
    name_en: "Turkmenistan",
    code: "Turkmenistán"
  },
  {
    name_es: "Turquía",
    name_en: "Turkey",
    code: "Turquía"
  },
  {
    name_es: "Tuvalu",
    name_en: "Tuvalu",
    code: "Tuvalu"
  },
  {
    name_es: "Túnez",
    name_en: "Tunisia",
    code: "Túnez"
  },
  {
    name_es: "Ucrania",
    name_en: "Ukraine",
    code: "Ucrania"
  },
  {
    name_es: "Uganda",
    name_en: "Uganda",
    code: "Uganda"
  },
  {
    name_es: "Uruguay",
    name_en: "Uruguay",
    code: "Uruguay"
  },
  {
    name_es: "Uzbekistán",
    name_en: "Uzbekistan",
    code: "Uzbekistán"
  },
  {
    name_es: "Vanuatu",
    name_en: "Vanuatu",
    code: "Vanuatu"
  },
  {
    name_es: "Vietnam",
    name_en: "Vietnam",
    code: "Vietnam"
  },
  {
    name_es: "Wallis y Futuna",
    name_en: "Wallis & Futuna",
    code: "Wallis y Futuna"
  },
  {
    name_es: "Yemen",
    name_en: "Yemen",
    code: "Yemen"
  },
  {
    name_es: "Yibuti",
    name_en: "Djibouti",
    code: "Yibuti"
  },
  {
    name_es: "Zambia",
    name_en: "Zambia",
    code: "Zambia"
  },
  {
    name_es: "Zimbabue",
    name_en: "Zimbabwe",
    code: "Zimbabue"
  },
  {
    name_es: "Åland",
    name_en: "Åland Islands",
    code: "Åland"
  }
]

export default countries;