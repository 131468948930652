/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useState, useEffect } from "react";
import moment from "moment";

/** MUI imports */
import { styled } from "@mui/material/styles";
import { Box, Chip, Grid, Typography, Divider } from "@mui/material";
// import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

/** Component imports */
import { Xero, KiiperBanking, KiiperTaxes, KiiperReporting, CustomerSupp } from "./";

/** Component styles */
const PREFIX = "SubscripRoot";

const classes = {
    cardContentRoot: `${PREFIX}-cardContentRoot`,
    chip: `${PREFIX}-chip`,
    chipProActive: `${PREFIX}-chipProActive`,
    chipProWasActive: `${PREFIX}-chipProWasActive`,
    selectedChip: `${PREFIX}-selectedChip`,
    selectedChipActive: `${PREFIX}-selectedChipActive`,
    selectedChipWasActive: `${PREFIX}-selectedChipWasActive`,
    divider: `${PREFIX}-divider`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.cardContentRoot}`]: {
        padding: "24px 48px 48px",
    },

    [`& .${classes.chip}`]: {
        padding: 20,
        width: 210,
        backgroundColor: "#EAEAF4",
        color: "#031851",
        fontWeight: "700 !important",
        "&:hover": {
            backgroundColor: "rgba(213, 214, 233, 0.3)",
            color: "#031851",
        },
        borderRadius: 40,
    },

    [`& .${classes.chipProActive}`]: {
        padding: 20,
        width: 210,
        backgroundColor: "#EAEAF4",
        color: "#031851",
        border: "2px solid #ff5968",
        fontWeight: "700 !important",
        "&:hover": {
            backgroundColor: "rgba(213, 214, 233, 0.3)",
            color: "#031851",
        },
        borderRadius: 40,
    },

    [`& .${classes.chipProWasActive}`]: {
        padding: 20,
        width: 210,
        backgroundColor: "#EAEAF4",
        color: "#031851",
        border: "2px solid #031851",
        fontWeight: "700 !important",
        "&:hover": {
            backgroundColor: "rgba(213, 214, 233, 0.3)",
            color: "#031851",
        },
        borderRadius: 40,
    },

    [`& .${classes.selectedChip}`]: {
        padding: 20,
        width: 210,
        backgroundColor: "#2f3190",
        color: "#fff",
        fontWeight: "700 !important",
        borderRadius: 40,
        "&:hover": {
            backgroundColor: "rgba(47, 49, 144, 0.9)",
            color: "#fff",
        },
    },

    [`& .${classes.selectedChipActive}`]: {
        padding: 20,
        width: 210,
        backgroundColor: "#2f3190",
        color: "#fff",
        fontWeight: "700 !important",
        border: "2px solid #ff5968",
        borderRadius: 40,
        "&:hover": {
            backgroundColor: "rgba(47, 49, 144, 0.9)",
            color: "#fff",
        },
    },

    [`& .${classes.selectedChipWasActive}`]: {
        padding: 20,
        width: 210,
        backgroundColor: "#2f3190",
        color: "#fff",
        fontWeight: "700 !important",
        border: "2px solid #031851",
        borderRadius: 40,
        "&:hover": {
            backgroundColor: "rgba(47, 49, 144, 0.9)",
            color: "#fff",
        },
    },

    [`& .${classes.divider}`]: {
        marginBottom: 10,
    },
}));

export const SubscripRoot = (props) => {
    // Properties
    const {
        setTexto,
        setalertType,
        setShow,
        setBackDrop,
        serviceData,
        companyID,
        setServiceData,
        clientMarket,
        setAuxExpanded,
        setExpanded,
        lastChipSelected,
        setLastChipSelected,
    } = props;
    const [renderState, setRenderState] = useState(false);

    /* Component states */
    const [subscrip, setSubscrip] = useState({
        subsXero: false,
        subsBanking: false,
        subsTaxes: false,
        subsReport: false,
        subsCustomer: false,
    });
    const [projectsData, setProjectsData] = useState({
        subsXero: {},
        subsBanking: {},
        subsTaxes: {},
        subsReport: {},
        subsCustomer: {},
    });
    const [active, setActive] = useState({
        subsXero: {
            isActive: false,
            wasActive: false,
        },
        subsBanking: {
            isActive: false,
            wasActive: false,
        },
        subsTaxes: {
            isActive: false,
            wasActive: false,
        },
        subsReport: {
            isActive: false,
            wasActive: false,
        },
        subsCustomer: {
            isActive: false,
            wasActive: false,
        },
    });

    const getProjects = async (isReload, endService) => {
        setBackDrop(true);

        let serviceCompID = serviceData._id ?? "";

        if (isReload || endService) {
            await fetch(`/getNewServicesCompany?company_id=${companyID}`, {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
                .then((res) => res.json())
                .then(async (data) => {
                    setServiceData(data);

                    let lastProject = data.filter(function (o) {
                        return o.hasOwnProperty("_id") && o.service_name === "Software subscription";
                    })[0];

                    await fetch(
                        `/getProjectsCompany?company_id=${lastProject.company_id}&service_id=${lastProject.service_id}&service_company_id=${lastProject._id}`,
                        {
                            method: "GET",
                            headers: {
                                "Content-type": "application/json; charset=UTF-8",
                                "Access-Control-Allow-Origin": "*",
                            },
                        }
                    )
                        .then((res) => res.json())
                        .then((data) => {
                            let result = {};
        
                            data.forEach((item) => {
                                switch (item.project_type) {
                                    case 40:
                                        result.subsXero = item.projects_array[0];
                                        break;
                                    case 41:
                                        result.subsBanking = item.projects_array[0];
                                        break;
                                    case 42:
                                        result.subsTaxes = item.projects_array[0];
                                        break;
                                    case 43:
                                        result.subsCustomer = item.projects_array[0];
                                        break;
                                    case 69:
                                        result.subsReport = item.projects_array[0];
                                        break;
                                    default:
                                        break;
                                }
                            });
        
                            setProjectsData({
                                subsXero: result.subsXero,
                                subsBanking: result.subsBanking,
                                subsTaxes: result.subsTaxes,
                                subsReport: result.subsReport,
                                subsCustomer: result.subsCustomer,
                            });
        
                            setActive({
                                subsXero: {
                                    isActive:
                                        result.subsXero.since_date &&
                                        (!result.subsXero.end_date || moment(result.subsXero.end_date).isAfter(moment())),
                                    wasActive: result.subsXero.end_date
                                        ? moment(result.subsXero.end_date).isSameOrBefore(moment())
                                        : false,
                                },
                                subsBanking: {
                                    isActive:
                                        result.subsBanking.since_date &&
                                        (!result.subsBanking.end_date || moment(result.subsBanking.end_date).isAfter(moment())),
                                    wasActive: result.subsBanking.end_date
                                        ? moment(result.subsBanking.end_date).isSameOrBefore(moment())
                                        : false,
                                },
                                subsTaxes: {
                                    isActive:
                                        result.subsTaxes.since_date &&
                                        (!result.subsTaxes.end_date || moment(result.subsTaxes.end_date).isAfter(moment())),
                                    wasActive: result.subsTaxes.end_date
                                        ? moment(result.subsTaxes.end_date).isSameOrBefore(moment())
                                        : false,
                                },
                                subsReport: {
                                    isActive:
                                        result.subsReport.since_date &&
                                        (!result.subsReport.end_date || moment(result.subsReport.end_date).isAfter(moment())),
                                    wasActive: result.subsReport.end_date
                                        ? moment(result.subsReport.end_date).isSameOrBefore(moment())
                                        : false,
                                },
                                subsCustomer: {
                                    isActive:
                                        result.subsCustomer.since_date &&
                                        (!result.subsCustomer.end_date ||
                                            moment(result.subsCustomer.end_date).isAfter(moment())),
                                    wasActive: result.subsCustomer.end_date
                                        ? moment(result.subsCustomer.end_date).isSameOrBefore(moment())
                                        : false,
                                },
                            });

                            setAuxExpanded(lastProject.service_name);
                            setExpanded(true);
                            setRenderState(!renderState)
                        });
                });
        } else {
            await fetch(
                `/getProjectsCompany?company_id=${companyID}&service_id=${serviceData.service_id}&service_company_id=${serviceCompID}`,
                {
                    method: "GET",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                    },
                }
            )
                .then((res) => res.json())
                .then((data) => {
                    let result = {};

                    data.forEach((item) => {
                        switch (item.project_type) {
                            case 40:
                                result.subsXero = item.projects_array[0];
                                break;
                            case 41:
                                result.subsBanking = item.projects_array[0];
                                break;
                            case 42:
                                result.subsTaxes = item.projects_array[0];
                                break;
                            case 43:
                                result.subsCustomer = item.projects_array[0];
                                break;
                            case 69:
                                result.subsReport = item.projects_array[0];
                                break;
                            default:
                                break;
                        }
                    });

                    setProjectsData({
                        subsXero: result.subsXero,
                        subsBanking: result.subsBanking,
                        subsTaxes: result.subsTaxes,
                        subsReport: result.subsReport,
                        subsCustomer: result.subsCustomer,
                    });

                    setActive({
                        subsXero: {
                            isActive:
                                result.subsXero.since_date &&
                                (!result.subsXero.end_date || moment(result.subsXero.end_date).isAfter(moment())),
                            wasActive: result.subsXero.end_date
                                ? moment(result.subsXero.end_date).isSameOrBefore(moment())
                                : false,
                        },
                        subsBanking: {
                            isActive:
                                result.subsBanking.since_date &&
                                (!result.subsBanking.end_date || moment(result.subsBanking.end_date).isAfter(moment())),
                            wasActive: result.subsBanking.end_date
                                ? moment(result.subsBanking.end_date).isSameOrBefore(moment())
                                : false,
                        },
                        subsTaxes: {
                            isActive:
                                result.subsTaxes.since_date &&
                                (!result.subsTaxes.end_date || moment(result.subsTaxes.end_date).isAfter(moment())),
                            wasActive: result.subsTaxes.end_date
                                ? moment(result.subsTaxes.end_date).isSameOrBefore(moment())
                                : false,
                        },
                        subsReport: {
                            isActive:
                                result.subsReport.since_date &&
                                (!result.subsReport.end_date || moment(result.subsReport.end_date).isAfter(moment())),
                            wasActive: result.subsReport.end_date
                                ? moment(result.subsReport.end_date).isSameOrBefore(moment())
                                : false,
                        },
                        subsCustomer: {
                            isActive:
                                result.subsCustomer.since_date &&
                                (!result.subsCustomer.end_date ||
                                    moment(result.subsCustomer.end_date).isAfter(moment())),
                            wasActive: result.subsCustomer.end_date
                                ? moment(result.subsCustomer.end_date).isSameOrBefore(moment())
                                : false,
                        },
                    });
                });
        }

        setBackDrop(false);
    };

    /* Component functions */
    useEffect(() => {
        getProjects(false, false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /* Component functions */
    useEffect(() => {
        if (lastChipSelected){
            handleClickChip(subscrip[lastChipSelected], lastChipSelected, false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [renderState]);

    const handleClickChip = async (value, option, setChip = false) => {
        switch (option) {
            case "subsXero":
                setSubscrip({
                    ...subscrip,
                    subsXero: setChip ? !value : true,
                    subsBanking: false,
                    subsTaxes: false,
                    subsReport: false,
                    subsCustomer: false,
                });
                break;
            case "subsBanking":
                setSubscrip({
                    ...subscrip,
                    subsXero: false,
                    subsBanking: setChip ? !value : true,
                    subsTaxes: false,
                    subsReport: false,
                    subsCustomer: false,
                });
                break;
            case "subsTaxes":
                setSubscrip({
                    ...subscrip,
                    subsXero: false,
                    subsBanking: false,
                    subsTaxes: setChip ? !value : true,
                    subsReport: false,
                    subsCustomer: false,
                });
                break;
            case "subsReport":
                setSubscrip({
                    ...subscrip,
                    subsXero: false,
                    subsBanking: false,
                    subsTaxes: false,
                    subsReport: setChip ? !value : true,
                    subsCustomer: false,
                });
                break;
            default:
                setSubscrip({
                    ...subscrip,
                    subsXero: false,
                    subsBanking: false,
                    subsTaxes: false,
                    subsReport: false,
                    subsCustomer: setChip ? !value : true,
                });
                break;
        }
        if (setChip) {
            setLastChipSelected(option);
        }
    };

    return (
        <StyledContainer>
            <Divider className={classes.divider} />
            <Grid container justifyContent="space-around" alignItems="center" spacing={2} sx={{ pb: 2 }}>
                <Grid item>
                    <Chip
                        id="xero-chip"
                        label={
                            <Typography variant="h2" align="center" sx={{ fontWeight: 700 }}>
                                Xero
                            </Typography>
                        }
                        className={
                            subscrip.subsXero
                                ? active.subsXero.isActive
                                    ? classes.selectedChipActive
                                    : active.subsXero.wasActive
                                    ? classes.selectedChipWasActive
                                    : classes.selectedChip
                                : active.subsXero.isActive
                                ? classes.chipProActive
                                : active.subsXero.wasActive
                                ? classes.chipProWasActive
                                : classes.chip
                        }
                        onClick={() => handleClickChip(subscrip.subsXero, "subsXero", true)}
                    />
                </Grid>
                <Grid item>
                    <Chip
                        id="banking-chip"
                        label={
                            <Typography variant="h2" align="center" sx={{ fontWeight: 700 }}>
                                Kiiper Banking
                            </Typography>
                        }
                        className={
                            subscrip.subsBanking
                                ? active.subsBanking.isActive
                                    ? classes.selectedChipActive
                                    : active.subsBanking.wasActive
                                    ? classes.selectedChipWasActive
                                    : classes.selectedChip
                                : active.subsBanking.isActive
                                ? classes.chipProActive
                                : active.subsBanking.wasActive
                                ? classes.chipProWasActive
                                : classes.chip
                        }
                        onClick={() => handleClickChip(subscrip.subsBanking, "subsBanking", true)}
                    />
                </Grid>
                {(clientMarket === "Venezuela" || clientMarket === "LATAM") && (
                    <Grid item>
                        <Chip
                            id="taxes-chip"
                            label={
                                <Typography variant="h2" align="center" sx={{ fontWeight: 700 }}>
                                    Kiiper Taxes
                                </Typography>
                            }
                            className={
                                subscrip.subsTaxes
                                    ? active.subsTaxes.isActive
                                        ? classes.selectedChipActive
                                        : active.subsTaxes.wasActive
                                        ? classes.selectedChipWasActive
                                        : classes.selectedChip
                                    : active.subsTaxes.isActive
                                    ? classes.chipProActive
                                    : active.subsTaxes.wasActive
                                    ? classes.chipProWasActive
                                    : classes.chip
                            }
                            onClick={() => handleClickChip(subscrip.subsTaxes, "subsTaxes", true)}
                        />
                    </Grid>
                )}
                <Grid item>
                    <Chip
                        id="report-chip"
                        label={
                            <Typography variant="h2" align="center" sx={{ fontWeight: 700 }}>
                                Kiiper Reporting
                            </Typography>
                        }
                        className={
                            subscrip.subsReport
                                ? active.subsReport.isActive
                                    ? classes.selectedChipActive
                                    : active.subsReport.wasActive
                                    ? classes.selectedChipWasActive
                                    : classes.selectedChip
                                : active.subsReport.isActive
                                ? classes.chipProActive
                                : active.subsReport.wasActive
                                ? classes.chipProWasActive
                                : classes.chip
                        }
                        onClick={() => handleClickChip(subscrip.subsReport, "subsReport", true)}
                    />
                </Grid>
                <Grid item>
                    <Chip
                        id="customer-chip"
                        label={
                            <Typography variant="h2" align="center" sx={{ fontWeight: 700 }}>
                                Customer Support
                            </Typography>
                        }
                        className={
                            subscrip.subsCustomer
                                ? active.subsCustomer.isActive
                                    ? classes.selectedChipActive
                                    : active.subsCustomer.wasActive
                                    ? classes.selectedChipWasActive
                                    : classes.selectedChip
                                : active.subsCustomer.isActive
                                ? classes.chipProActive
                                : active.subsCustomer.wasActive
                                ? classes.chipProWasActive
                                : classes.chip
                        }
                        onClick={() => handleClickChip(subscrip.subsCustomer, "subsCustomer", true)}
                    />
                </Grid>
            </Grid>
            {subscrip.subsXero && (
                <Xero
                    setTexto={setTexto}
                    setalertType={setalertType}
                    setShow={setShow}
                    companyID={companyID}
                    reloadProjects={getProjects}
                    setBackDrop={setBackDrop}
                    data={projectsData.subsXero}
                    serviceID={serviceData.service_id}
                />
            )}
            {subscrip.subsBanking && (
                <KiiperBanking
                    setTexto={setTexto}
                    setalertType={setalertType}
                    setShow={setShow}
                    companyID={companyID}
                    reloadProjects={getProjects}
                    setBackDrop={setBackDrop}
                    data={projectsData.subsBanking}
                    serviceID={serviceData.service_id}
                />
            )}
            {subscrip.subsTaxes && (
                <KiiperTaxes
                    setTexto={setTexto}
                    setalertType={setalertType}
                    setShow={setShow}
                    companyID={companyID}
                    reloadProjects={getProjects}
                    setBackDrop={setBackDrop}
                    clientMarket={clientMarket}
                    data={projectsData.subsTaxes}
                    serviceID={serviceData.service_id}
                />
            )}
            {subscrip.subsReport && (
                <KiiperReporting
                    setTexto={setTexto}
                    setalertType={setalertType}
                    setShow={setShow}
                    companyID={companyID}
                    reloadProjects={getProjects}
                    setBackDrop={setBackDrop}
                    data={projectsData.subsReport}
                    serviceID={serviceData.service_id}
                />
            )}
            {subscrip.subsCustomer && (
                <CustomerSupp
                    setTexto={setTexto}
                    setalertType={setalertType}
                    setShow={setShow}
                    companyID={companyID}
                    clientMarket={clientMarket}
                    reloadProjects={getProjects}
                    setBackDrop={setBackDrop}
                    data={projectsData.subsCustomer}
                    serviceID={serviceData.service_id}
                />
            )}
        </StyledContainer>
    );
};
