// React
import React from "react";
import { useTranslation } from "react-i18next";

// MUI
import { Box, Grid, InputAdornment, TextField, Typography, Button, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

/** MUI icons */
import CancelIcon from "@mui/icons-material/Cancel";

const PREFIX = "Consulting";

const classes = {
    btnRoot: `${PREFIX}-btnRoot`,
    buttonContainer: `${PREFIX}-buttonContainer`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    backgroundColor: "#F8F9F8",
    marginTop: 16,
    padding: 30,
    borderRadius: 30,

    [`& .${classes.btnRoot}`]: {
        border: "1px solid #ff5968",
        borderRadius: 40,
        "&:hover": {
            backgroundColor: "#2f3190",
            border: "1px solid #2f3190",
        },
    },
    [`& .${classes.buttonContainer}`]: {
        display: "flex",
        justifyContent: "flex-end",
        padding: 15,
    },
}));

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "rgba(19, 31, 62, 0.80)",
        fontSize: 12,
        maxWidth: 400,
        borderRadius: 6,
        fontWeight: "normal",
    },
});

export const Consulting = (props) => {
    // Properties
    const { valuesCFOConsul, setValuesCFOConsul, isError, isErrorFocus, setIsErrorFocus } = props;
    
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    /** Component functions */
    const handleChange = (event, index) => {
        let aux = [...valuesCFOConsul.consultingArray];

        aux[index].description = event.target.value;

        setValuesCFOConsul({
            ...valuesCFOConsul,
            consultingArray: aux,
            isError:false
        });
        setIsErrorFocus(false)
    };

    const handleChangeDiscount = (event, index) => {
        let aux = [...valuesCFOConsul.consultingArray];
        let discountAux =
            event.target.value >= 0 && event.target.value <= 100 ? event.target.value : aux[index].discount;

        aux[index].discount = discountAux;

        aux.forEach((element) => {
            element.total = element.hours * valuesCFOConsul.consulOriginalFee * (1 - element.discount / 100);
        });

        let sumTotal = aux.map((obj) => obj.total).reduce((a, b) => parseFloat(a) + parseFloat(b));

        setValuesCFOConsul({
            ...valuesCFOConsul,
            consulProjectTotal: sumTotal,
            consultingArray: aux,
            isError:false
        });
        setIsErrorFocus(false)
    };

    const handleChangeHours = (event, index) => {
        let aux = [...valuesCFOConsul.consultingArray];

        aux[index].hours = event.target.value;

        aux.forEach((element) => {
            element.total = element.hours * valuesCFOConsul.consulOriginalFee * (1 - element.discount / 100);
        });

        let sumHours = aux.map((obj) => obj.hours).reduce((a, b) => parseInt(a) + parseInt(b));
        let sumTotal = aux.map((obj) => obj.total).reduce((a, b) => parseFloat(a) + parseFloat(b));

        setValuesCFOConsul({
            ...valuesCFOConsul,
            consulProjectTotal: sumTotal,
            consulHours: sumHours,
            consultingArray: aux,
            isError:false
        });
        setIsErrorFocus(false)
    };

    const addConsult = () => {
        setValuesCFOConsul({
            ...valuesCFOConsul,
            consultingArray: [
                ...valuesCFOConsul.consultingArray,
                {
                    hours: 0,
                    discount: 0,
                    description: "",
                    total: 0,
                },
            ],
        });
    };

    const removeConsult = (index) => {
        let array = [...valuesCFOConsul.consultingArray]; // make a separate copy of the array
        let hours = array[index].hours;
        let discount = array[index].discount;

        if (index !== -1) {
            array.splice(index, 1);
            setValuesCFOConsul({
                ...valuesCFOConsul,
                consulProjectTotal:
                    valuesCFOConsul.consulProjectTotal -
                    hours * valuesCFOConsul.consulOriginalFee * (1 - discount / 100),
                consulHours: valuesCFOConsul.consulHours - hours,
                consultingArray: array,
            });
        }
    };

    const round = (index) => {
        let aux = [...valuesCFOConsul.consultingArray];

        aux[index].discount = parseFloat(aux[index].discount === "" ? 0 : aux[index].discount).toFixed(2);

        setValuesCFOConsul({
            ...valuesCFOConsul,
            consultingArray: aux,
        });
    };

    return (
        <StyledContainer>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography
                        variant="h1"
                        color="primary"
                        sx={{
                            display: "inline-block",
                            fontWeight: 700,
                            pr: 6,
                        }}
                    >
                        Consulting
                    </Typography>
                </Grid>
                {valuesCFOConsul.consultingArray.map((value, index) => (
                    <>
                        <Grid item xs={12} sm={6} lg={3}>
                            <TextField
                                fullWidth
                                label={t("miscellaneous.hours")}
                                onChange={(e) => handleChangeHours(e, index)}
                                type="number"
                                required
                                inputProps={{
                                    min: 0.5,
                                    step: 0.5,
                                    onKeyDown: (event) => {
                                        event.preventDefault();
                                    },
                                }}
                                error={isError && value.hours === 0}
                                value={value.hours}
                                variant="outlined"
                                onClick={(e) =>{setIsErrorFocus(false)}}
                                inputRef={(input) => { (input && isErrorFocus && isError && value.hours === 0) && input.focus()}}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3}>
                            <TextField
                                fullWidth
                                label={t("miscellaneous.discount")}
                                value={value.discount}
                                onChange={(e) => handleChangeDiscount(e, index)}
                                onBlur={() => round(index)}
                                variant="outlined"
                                type="number"
                                InputProps={{
                                    inputProps: {
                                        min: 0,
                                        max: 100,
                                    },
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                }}
                                sx={{
                                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                        display: "none",
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={10} lg={5}>
                            <TextField
                                fullWidth
                                label={t("invoicing.description")}
                                value={value.description}
                                error={isError && value.description === ""}
                                onChange={(e) => handleChange(e, index)}
                                variant="outlined"
                                inputProps={{ maxLength: 100 }}
                                required
                                onClick={(e) =>{setIsErrorFocus(false)}}
                                inputRef={(input) => { (input && isErrorFocus && isError && value.description === "") && input.focus()}}
                            />
                        </Grid>
                        {index > 0 && (
                            <Grid item xs={12} sm={2} lg={1}>
                                <LightTooltip title={t("miscellaneous.cancel")} aria-label="cancel">
                                    <IconButton onClick={() => removeConsult(index)} size="large">
                                        <CancelIcon fontSize="inherit" />
                                    </IconButton>
                                </LightTooltip>
                            </Grid>
                        )}
                    </>
                ))}
                <Grid item xs={12}>
                    <Button
                        variant="outlined"
                        disableElevation
                        sx={{
                            borderRadius: 40,
                            "&:hover": {
                                border: "1px solid #2f3190",
                                color: "#2f3190",
                            },
                        }}
                        onClick={addConsult}
                    >
                        {t("proposals.addConsult")}
                    </Button>
                </Grid>
            </Grid>
        </StyledContainer>
    );
};
