/** React imports */
import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";

/** MUI icons */
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import CheckIcon from "@mui/icons-material/Check";
/** MUI imports */
import { styled } from "@mui/material/styles";
import {
    Avatar,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    TextField,
    Typography,
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

/** Images */
import AndreaTorrealba from "../../assets/images/our-team-andrea-torrealba.jpg";
import BrianTorres from "../../assets/images/our-team-brian-torres.jpg";
import DanielaLeon from "../../assets/images/our-team-daniela-leon.jpg";
import DavidLoyo from "../../assets/images/our-team-david-loyo.jpg";
import DeyaniraGoncalves from "../../assets/images/our-team-deyanira-goncalvez.jpg";
import FeliceCalo from "../../assets/images/our-team-felice-calo.jpg";
import GabrielJimenez from "../../assets/images/our-team-gabriel-jimenez.jpg";
import GeraldineCalles from "../../assets/images/our-team-geraldine-calles.jpg";
import IraixyGuevara from "../../assets/images/our-team-iraixy-guevara.jpg";
import JoseAlvarez from "../../assets/images/our-team-jose-manuel-alvarez.jpg";
import GiancarloAlbani from "../../assets/images/our-team-giancarlo-albani.jpg";
import KeniaGarcia from "../../assets/images/our-team-kenia-garcia.jpg";
import PedroHerrera from "../../assets/images/our-team-pedro-herrera.jpg";
import RodrigoQuintero from "../../assets/images/our-team-rodrigo-quintero.jpg";
import TrinidadAguilar from "../../assets/images/our-team-trinidad-aguilar.jpg";
import YaritzaRosas from "../../assets/images/our-team-yaritza-rosas.jpg";
import MicaelaMedina from "../../assets/images/our-team-micaela-medina.jpg";
import MariaBarrientos from "../../assets/images/our-team-maria-barrientos.jpg";
import MariaCruz from "../../assets/images/our-team-maria-cruz.jpg";

const usersAvatar = {
    atorrealba: AndreaTorrealba,
    btorres: BrianTorres,
    mcruz: MariaCruz,
    dleon: DanielaLeon,
    dloyo: DavidLoyo,
    dgoncalves: DeyaniraGoncalves,
    fcalo: FeliceCalo,
    gjimenez: GabrielJimenez,
    gcalles: GeraldineCalles,
    iguevara: IraixyGuevara,
    jalvarez: JoseAlvarez,
    galbani: GiancarloAlbani,
    kgarcia: KeniaGarcia,
    pherrera: PedroHerrera,
    rquintero: RodrigoQuintero,
    taguilar: TrinidadAguilar,
    yrosas: YaritzaRosas,
    mmedina: MicaelaMedina,
    mbarrientos: MariaBarrientos
};

const PREFIX = "CommentDialog";

const classes = {
    button: `${PREFIX}-button`,
    buttonC: `${PREFIX}-buttonC`,
};

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "rgba(19, 31, 62, 0.80)",
        fontSize: 12,
        maxWidth: 320,
        borderRadius: 6,
        fontWeight: "normal",
    },
});

const CustomDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialog-paper": {
        borderRadius: 16,
        boxShadow: "10px 10px 15px rgba(3,24,81,0.15)",
    },
    [`& .${classes.button}`]: {
        borderRadius: 40,
        paddingLeft: 40,
        paddingRight: 40,
        "&:hover": {
            backgroundColor: theme.palette.text.secondary,
            color: "#fff",
        },
    },
    [`& .${classes.buttonC}`]: {
        borderRadius: 40,
        paddingLeft: 40,
        paddingRight: 40,
        "&:hover": {
            backgroundColor: "white",
            color: "#2f3190",
            border: "1px solid #2f3190",
        },
    },
}));

const CustomDialogTitle = (props) => {
    const { children, onClose, proposalHeader, prospectHeader, traslate, dateFormat, ...other } = props;
    const headerColor =
        proposalHeader.status === 1
            ? "#f5f4f9"
            : proposalHeader.status === 2
            ? "#fff9cb"
            : proposalHeader.status === 3
            ? "#dbf6e5"
            : "#ffccd1";

    return (
        <DialogTitle {...other} sx={{ backgroundColor: headerColor }}>
            {children && (
                <>
                    <ListItem alignItems="flex-start">
                        <ListItemAvatar>
                            {(proposalHeader.status === 1 && <BorderColorIcon />) ||
                                (proposalHeader.status === 2 && <EmailOutlinedIcon />) ||
                                (proposalHeader.status === 3 && <CheckIcon />) ||
                                (proposalHeader.status === 4 && <CloseIcon />)}
                        </ListItemAvatar>
                        <ListItemText
                            primary={
                                <>
                                    <Typography
                                        sx={{ fontWeight: 600 }}
                                        component="span"
                                        variant="body2"
                                        color="text.secondary"
                                    >
                                        {`
                                            ${proposalHeader.status === 1 ? traslate("proposals.draftProposals") : ""}
                                            ${proposalHeader.status >= 2 ? traslate("proposals.proposal") : ""}
                                            ${proposalHeader.reference}
                                            ${proposalHeader.status === 2 ? traslate("proposals.sendMess2") : ""}
                                            ${proposalHeader.status === 3 ? traslate("proposals.acceptMess2") : ""}
                                            ${proposalHeader.status === 4 ? traslate("proposals.declineMess2") : ""}
                                        `}
                                    </Typography>
                                </>
                            }
                            secondary={
                                <>
                                    <Typography
                                        sx={{ display: "inline", fontSize: 11 }}
                                        component="span"
                                        color="text.primary"
                                        dangerouslySetInnerHTML={{
                                            __html: `${
                                                proposalHeader.status === 1 || proposalHeader.status === 2
                                                    ? traslate("proposals.creationDate") +
                                                      " " +
                                                      moment(proposalHeader.creation_date)
                                                          .format(" " + dateFormat + " - HH:mm")
                                                          .replace(/\n/g, "<br>") +
                                                      ` ${traslate("proposals._by")} ` +
                                                      proposalHeader.users.user_created
                                                    : ""
                                            }
                                                    ${
                                                        proposalHeader.status === 3 || proposalHeader.status === 4
                                                            ? moment(proposalHeader.update_status_date)
                                                                  .format(" " + dateFormat + " - HH:mm")
                                                                  .replace(/\n/g, "<br>") +
                                                              ` ${traslate("proposals._by")} ` +
                                                              prospectHeader.prospect_name +
                                                              " " +
                                                              prospectHeader.prospect_last_name
                                                            : ""
                                                    }
                                                    `,
                                        }}
                                    ></Typography>
                                    {proposalHeader.update_date &&
                                        proposalHeader.update_user &&
                                        (proposalHeader.status === 1 || proposalHeader.status === 2) && (
                                            <Typography
                                                sx={{ display: "block", fontSize: 11 }}
                                                component="span"
                                                color="text.primary"
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                        `${
                                                            traslate("proposals.updatedDate") +
                                                            " " +
                                                            moment(proposalHeader.update_date).format(
                                                                `${dateFormat} - HH:mm`
                                                            )
                                                        }`.replace(/\n/g, "<br>") +
                                                        ` ${traslate("proposals._by")} ` +
                                                        `${proposalHeader.users.user_updated}`,
                                                }}
                                            ></Typography>
                                        )}
                                </>
                            }
                        />
                    </ListItem>
                </>
            )}
        </DialogTitle>
    );
};
export default function AlertDialog(props) {
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    const {
        setBackDrop,
        proposalID,
        commentType,
        getComments,
        setTexto,
        setAutoAlertType,
        setShow,
        onClose,
        open,
        comments,
        proposalInfo,
        loadInitData,
        proposal,
        prospect,
    } = props;

    /* React redux */
    const todos = useSelector((state) => state.todos);

    /** Component states */
    const [value, setValue] = useState("");
    const [error, setError] = useState(false);
    const [idComment, setidComment] = useState("");
    const [editMode, setEditMode] = useState(false);
    const chatContainerRef = useRef(null);

    /** Component functions */
    useEffect(() => {
        if (open) {
            const lastChildElement = chatContainerRef.current?.lastElementChild;
            lastChildElement?.scrollIntoView();
        }
    });

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const addComment = async () => {
        setBackDrop(true);

        let params = {};

        if (proposalInfo) {
            params = {
                proposal_id: proposalID,
                comment_description: value,
                user_name: proposalInfo.prospect_name,
                email: proposalInfo.prospect_email,
                comment_type: commentType,
                company_name: proposalInfo.company_name,
            };
        } else {
            params = {
                proposal_id: proposalID,
                comment_description: value,
                user_name: todos.userInfo.user_name,
                email: todos.userInfo.email,
                comment_type: commentType,
                company_name: props.company_name,
            };
        }

        if (value.trim() !== "") {
            await fetch("/addComment", {
                method: "POST",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
                body: JSON.stringify(params),
            })
                .then(() => {
                    setValue("");
                    setError(false);
                    getComments(params.proposal_id);
                    loadInitData();
                    setTexto(t("commentDialog.commentCreated"));
                    setAutoAlertType("success");
                    setShow(true);
                })
                .catch((err) => {
                    setTexto(err.error);
                    setAutoAlertType("error");
                    setShow(true);
                });
        } else {
            setTexto(t("commentDialog.mustEnterComment"));
            setAutoAlertType("warning");
            setShow(true);
            setError(true);
        }

        setBackDrop(false);
    };

    const deleteC = async (id) => {
        setBackDrop(true);

        const params = {
            comment_id: id,
        };

        await fetch("/deleteComment", {
            method: "POST",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
            body: JSON.stringify(params),
        })
            .then(() => {
                setValue("");
                setEditMode(false);
                getComments(proposalID);
                loadInitData();
                setTexto(t("commentDialog.commentDeleted"));
                setAutoAlertType("success");
                setShow(true);
            })
            .catch((err) => {
                setTexto(err.error);
                setAutoAlertType("error");
                setShow(true);
            });

        setBackDrop(false);
    };

    const editC = async () => {
        setBackDrop(true);

        const params = {
            comment_id: idComment,
            comment_description: value,
        };

        if (value.trim() !== "") {
            await fetch("/updateComment", {
                method: "POST",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
                body: JSON.stringify(params),
            })
                .then(() => {
                    setValue("");
                    setError(false);
                    setEditMode(false);
                    getComments(proposalID);
                    setTexto(t("commentDialog.commentUpdated"));
                    setAutoAlertType("success");
                    setShow(true);
                })
                .catch((err) => {
                    setTexto(err.error);
                    setAutoAlertType("error");
                    setShow(true);
                });
        } else {
            setTexto(t("commentDialog.mustEnterComment"));
            setAutoAlertType("warning");
            setShow(true);
            setError(true);
        }

        setBackDrop(false);
    };

    const showeditMode = (id, comment) => {
        setidComment(id);
        setEditMode(true);
        setValue(comment);
    };

    const closeEditMode = () => {
        setEditMode(false);
        setValue("");
    };

    const closing = () => {
        setValue("");
        setError(false);
        setEditMode(false);
        onClose();
    };
    const dateFormat = sessionStorage.getItem("lng") === "es" ? "DD/MM/YYYY" : "MM/DD/YYYY";
    return (
        <CustomDialog
            open={open}
            onClose={closing}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="sm"
            fullWidth={true}
        >
            {proposal && (
                <CustomDialogTitle
                    id="history-dialog-title"
                    onClose={closing}
                    proposalHeader={proposal}
                    prospectHeader={prospect}
                    traslate={t}
                    dateFormat={dateFormat}
                >
                    {t("manageAccount.convHistoryTitle")}
                </CustomDialogTitle>
            )}

            <DialogContent>
                <List ref={chatContainerRef} sx={{ width: "100%", maxHeight: 300, bgcolor: "background.paper" }}>
                    {proposal && (proposal.status === 3 || proposal.status === 4) && (
                        <>
                            <ListItem alignItems="flex-start" sx={{ backgroundColor: "#fff9cb", borderRadius: "10px" }}>
                                <ListItemAvatar>
                                    <EmailOutlinedIcon />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={
                                        <>
                                            <Typography
                                                sx={{ fontWeight: 600 }}
                                                component="span"
                                                variant="body2"
                                                color="text.secondary"
                                            >
                                                {`
                                                        ${proposal.status === 1 ? t("proposals.draftProposals") : ""}
                                                        ${proposal.status >= 2 ? t("proposals.proposal") : ""}
                                                        ${proposal.reference}
                                                        ${t("proposals.sendMess2")}
                                                    `}
                                            </Typography>
                                        </>
                                    }
                                    secondary={
                                        <>
                                            <Typography
                                                sx={{ display: "inline", fontSize: 11 }}
                                                component="span"
                                                color="text.primary"
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                        `${
                                                            t("proposals.creationDate") +
                                                            " " +
                                                            moment(proposal.creation_date).format(
                                                                `${dateFormat} - HH:mm`
                                                            )
                                                        }`.replace(/\n/g, "<br>") +
                                                        ` ${t("proposals._by")} ` +
                                                        `${proposal.users.user_created}`,
                                                }}
                                            ></Typography>
                                            {proposal.update_date && proposal.update_user && (
                                                <Typography
                                                    sx={{ display: "block", fontSize: 11 }}
                                                    component="span"
                                                    color="text.primary"
                                                    dangerouslySetInnerHTML={{
                                                        __html:
                                                            `${
                                                                t("proposals.updatedDate") +
                                                                " " +
                                                                moment(proposal.update_date).format(
                                                                    `${dateFormat} - HH:mm`
                                                                )
                                                            }`.replace(/\n/g, "<br>") +
                                                            ` ${t("proposals._by")} ` +
                                                            `${proposal.users.user_updated}`,
                                                    }}
                                                ></Typography>
                                            )}
                                        </>
                                    }
                                />
                            </ListItem>
                        </>
                    )}
                    {comments.length === 0 && (
                        <ListItem sx={{ float: "right" }}>
                            <ListItemText
                                primary={t("commentDialog.noComments")}
                                sx={{ textAlign: "center" }}
                            ></ListItemText>
                        </ListItem>
                    )}
                    {comments.map((option, index) => (
                        <>
                            <ListItem alignItems="flex-start" key={index}>
                                <ListItemAvatar>
                                    <Avatar alt="User" src={usersAvatar[option.email.split("@")[0]]} />
                                </ListItemAvatar>
                                <ListItemText
                                    sx={{ paddingBottom: 1 }}
                                    primary={
                                        <>
                                            <Typography
                                                sx={{ fontWeight: 600 }}
                                                component="span"
                                                variant="body2"
                                                color="text.secondary"
                                            >
                                                {`${option.user_name} ${t("commentDialog.commented")}:`}
                                            </Typography>
                                            <Box sx={{ float: "right" }}>
                                                {((todos.userInfo && option.email === todos.userInfo.email) ||
                                                    (proposalInfo &&
                                                        option.email === proposalInfo.prospect_email &&
                                                        !todos.userInfo)) && (
                                                    <>
                                                        <LightTooltip title={t("miscellaneous.edit")} aria-label="edit">
                                                            <IconButton
                                                                edge="end"
                                                                aria-label="edit"
                                                                size="small"
                                                                sx={{ color: "#2f3190" }}
                                                                onClick={() =>
                                                                    showeditMode(option._id, option.comment_description)
                                                                }
                                                            >
                                                                <EditIcon fontSize="inherit" />
                                                            </IconButton>
                                                        </LightTooltip>
                                                        <LightTooltip
                                                            title={t("miscellaneous.delete")}
                                                            aria-label="delete"
                                                        >
                                                            <IconButton
                                                                edge="end"
                                                                aria-label="delete"
                                                                size="small"
                                                                sx={{ color: "#2f3190" }}
                                                                onClick={() => deleteC(option._id)}
                                                            >
                                                                <DeleteIcon fontSize="inherit" />
                                                            </IconButton>
                                                        </LightTooltip>
                                                    </>
                                                )}
                                            </Box>
                                        </>
                                    }
                                    secondary={
                                        <>
                                            <Typography
                                                sx={{ display: "inline" }}
                                                component="span"
                                                variant="body1"
                                                color="text.primary"
                                                dangerouslySetInnerHTML={{
                                                    __html: option.comment_description.replace(/\n/g, "<br>") + " — ",
                                                }}
                                            ></Typography>
                                            <Typography component="span" sx={{ fontSize: 11, fontWeight: 600 }}>
                                                {/* {moment(option.date).format(`${todos.dateFormat ?? dateFormat} HH:mm`)} */}
                                                {moment(option.date).format(`${dateFormat} HH:mm`)}
                                            </Typography>
                                        </>
                                    }
                                />
                            </ListItem>
                            {index !== comments.length - 1 && <Divider variant="inset" component="li" />}
                        </>
                    ))}
                </List>
            </DialogContent>
            <DialogActions sx={{ p: 2 }}>
                <Grid container spacing={2} justifyContent={editMode ? "space-between" : "center"}>
                    <Grid item xs={12}>
                        <TextField
                            id="standard-multiline-flexible"
                            placeholder={t("commentDialog.writingHere")}
                            fullWidth
                            multiline
                            rows={4}
                            value={value}
                            onChange={handleChange}
                            error={error && value.trim() === ""}
                        />
                    </Grid>
                    {editMode && (
                        <Grid item>
                            <Button
                                variant="outlined"
                                color="secondary"
                                className={classes.buttonC}
                                disableElevation
                                onClick={closeEditMode}
                            >
                                Cancelar
                            </Button>
                        </Grid>
                    )}
                    <Grid item>
                        <Button
                            variant="contained"
                            color="secondary"
                            className={classes.button}
                            disableElevation
                            onClick={editMode ? editC : addComment}
                        >
                            {editMode ? t("miscellaneous.edit") : t("miscellaneous.comment")}
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </CustomDialog>
    );
}
