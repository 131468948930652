// React
import React from "react";
import { useTranslation } from "react-i18next";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useSelector } from "react-redux";
import moment from "moment";

// MUI
import {
    Box,
    TextField,
    Grid,
    Typography,
    FormControl,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    InputLabel,
    InputAdornment,
    Select,
    MenuItem,
    FormControlLabel,
    Checkbox,
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { styled } from "@mui/material/styles";

/** MUI icons */
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CheckIcon from "@mui/icons-material/Check";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const PREFIX = "Xero";

const classes = {
    btnRoot: `${PREFIX}-btnRoot`,
    buttonContainer: `${PREFIX}-buttonContainer`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    backgroundColor: "#F8F9F8",
    marginTop: 16,
    padding: 30,
    borderRadius: 30,

    [`& .${classes.btnRoot}`]: {
        border: "1px solid #ff5968",
        borderRadius: 40,
        "&:hover": {
            backgroundColor: "#2f3190",
            border: "1px solid #2f3190",
        },
    },
    [`& .${classes.buttonContainer}`]: {
        display: "flex",
        justifyContent: "flex-end",
        padding: 15,
    },
}));

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)({
    backgroundColor: "#F8F9F8",
    boxShadow: "none",
    "&:before": {
        display: "none",
    },
});

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: "#031851" }} />} {...props} />
))({
    "& .MuiAccordionSummary-content": {
        margin: "12px 0",
    },
});

const AccordionDetails = styled(MuiAccordionDetails)({
    padding: "0px 0px 20px 0px",
    "& .MuiCard-root": {
        boxShadow: "none",
    },
});

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "rgba(19, 31, 62, 0.80)",
        fontSize: 12,
        maxWidth: 320,
        borderRadius: 6,
        fontWeight: "normal",
    },
});

export const Xero = (props) => {
    // Properties
    const {
        xero,
        setXero,
        isError,
        isErrorFocus,
        setIsErrorFocus,
        includeAccModuleSet,
        handleChangeCheckAccSet,
        includeAdmModuleSet,
        handleChangeCheckAdmSet,
        accountantOnboarding
    } = props;

    /** Internationalization i18n */
    const [t] = useTranslation("global");

    /** React redux */
    const todos = useSelector((state) => state.todos);

    /** Component functions */
    const handleChange = (event) => {
        setXero({
            ...xero,
            [event.target.name]: event.target.value,
            projectTotal: event.target.value * xero.originalFee * (1 - xero.discount / 100),
            isError: false,
        });
        setIsErrorFocus(false);
    };

    const handleChangeDiscount = (event) => {
        let discountAux = event.target.value >= 0 && event.target.value <= 100 ? event.target.value : xero.discount;

        setXero({
            ...xero,
            discount: discountAux,
            projectTotal: xero.originalFee * xero.hours * (1 - discountAux / 100),
            isError: false,
        });
        setIsErrorFocus(false);
    };

    const handleChangePackage = (event) => {
        let hoursNew = 1;

        if (event.target.value === 1) {
            hoursNew = 20;
        } else if (event.target.value === 2) {
            hoursNew = 40;
        } else if (event.target.value === 3) {
            hoursNew = 60;
        }

        setXero({
            ...xero,
            package: event.target.value,
            hours: hoursNew,
            projectTotal: hoursNew * xero.originalFee * (1 - xero.discount / 100),
            isCustom: event.target.value !== 4,
            isError: false,
        });
        setIsErrorFocus(false);
    };

    const handleChangeVersion = (event) => {
        setXero({
            ...xero,
            version: event.target.value,
            isError: false,
        });
        setIsErrorFocus(false);
    };

    const handleChangeEstDeliDate = (date) => {
        setXero({
            ...xero,
            commitmentDateProposal: date,
            isError: false,
        });
        setIsErrorFocus(false);
    };

    return (
        <StyledContainer>
            <Grid container alignItems="flex-start" spacing={2}>
                <Grid item xs={12} sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <Grid item xs={4}>
                        <Typography
                            variant="h1"
                            color="primary"
                            sx={{
                                display: "inline-block",
                                fontWeight: 700,
                                pr: 6,
                            }}
                        >
                            Xero setup
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <LightTooltip title={accountantOnboarding ? t("proposals.includeConfigInAcc") : t("proposals.includeConfigAcc")}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={includeAccModuleSet || accountantOnboarding}
                                        onChange={handleChangeCheckAccSet}
                                        disabled={accountantOnboarding || !includeAdmModuleSet}
                                    />
                                }
                                label={t("proposals.accModule")}
                            />
                        </LightTooltip>
                    </Grid>
                    <Grid item xs={6}>
                        <LightTooltip title={t("proposals.includeConfigAdm")}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={includeAdmModuleSet}
                                        onChange={handleChangeCheckAdmSet}
                                        disabled={!includeAccModuleSet && (!accountantOnboarding && !includeAccModuleSet)}
                                    />
                                }
                                label={t("proposals.admModule")}
                            />
                        </LightTooltip>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Accordion
                        TransitionProps={{ unmountOnExit: true }}
                        sx={{
                            borderBottom: "3px solid #ff5968",
                        }}
                    >
                        <AccordionSummary aria-controls="panel-content" id="panel-header">
                            <Typography
                                variant="h2"
                                align="center"
                                sx={{
                                    fontWeight: 700,
                                }}
                            >
                                {t("invoicing.description")}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <List>
                                <ListItem disableGutters alignItems="center">
                                    <ListItemIcon sx={{ minWidth: 35 }}>
                                        <CheckIcon fontSize="small" sx={{ color: "#031851" }} />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <Typography variant="body1">
                                                <span style={{ fontWeight: 600 }}>{t("proposals.xeroExpert")} </span>-{" "}
                                                {t("proposals.xeroExpertDet")}
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                                <ListItem disableGutters alignItems="center">
                                    <ListItemIcon sx={{ minWidth: 35 }}>
                                        <CheckIcon fontSize="small" sx={{ color: "#031851" }} />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <Typography variant="body1">
                                                <span style={{ fontWeight: 600 }}>{t("proposals.accSetup")} </span>-{" "}
                                                {t("proposals.accSetupDet")}
                                                {xero.package === 1 || xero.package === 2
                                                    ? " " + t("proposals.upTo14hours")
                                                    : xero.package === 3
                                                        ? " " + t("proposals.upTo18hours")
                                                        : ""}
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                                {xero.package >= 2 && (
                                    <ListItem disableGutters alignItems="center">
                                        <ListItemIcon sx={{ minWidth: 35 }}>
                                            <CheckIcon fontSize="small" sx={{ color: "#031851" }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={
                                                <Typography variant="body1">
                                                    <span style={{ fontWeight: 600 }}>
                                                        {t("proposals.businessSetup")}{" "}
                                                    </span>
                                                    - {t("proposals.businessSetupDet")}
                                                    {xero.package === 2
                                                        ? " " + t("proposals.upTo14hours")
                                                        : xero.package === 3
                                                            ? " " + t("proposals.upTo24hours")
                                                            : ""}
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                )}
                                <ListItem disableGutters alignItems="center">
                                    <ListItemIcon sx={{ minWidth: 35 }}>
                                        <CheckIcon fontSize="small" sx={{ color: "#031851" }} />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <Typography variant="body1">
                                                <span style={{ fontWeight: 600 }}>{t("proposals.support")} </span>
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                            </List>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <FormControl fullWidth required error={isError && xero.package === ""}>
                        <InputLabel id="ver-simple-select-label">{t("services.package")}</InputLabel>
                        <Select
                            name="package"
                            labelId="ver-simple-select-label"
                            IconComponent={KeyboardArrowDownIcon}
                            value={xero.package}
                            label={t("services.package")}
                            onChange={handleChangePackage}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        mt: 0.7,
                                        borderRadius: 2,
                                        boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                    },
                                },
                            }}
                            onClick={() => {
                                setIsErrorFocus(false);
                            }}
                            inputRef={(input) => {
                                input && isErrorFocus && isError && xero.package === "" && input.focus();
                            }}
                        >
                            <MenuItem value={1}>Basic (20 hrs)</MenuItem>
                            <MenuItem value={2}>Professional (40 hrs)</MenuItem>
                            <MenuItem value={3}>Advanced (60 hrs)</MenuItem>
                            <MenuItem value={4}>{t("currentView.personal")}</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <FormControl fullWidth required error={isError && xero.version === ""}>
                        <InputLabel id="ver-simple-select-label">{t("services.version")}</InputLabel>
                        <Select
                            name="version"
                            labelId="ver-simple-select-label"
                            IconComponent={KeyboardArrowDownIcon}
                            value={xero.version}
                            label={t("services.version")}
                            onChange={handleChangeVersion}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        mt: 0.7,
                                        borderRadius: 2,
                                        boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                    },
                                },
                            }}
                            onClick={(e) => {
                                setIsErrorFocus(false);
                            }}
                            inputRef={(input) => {
                                input && isErrorFocus && isError && xero.version === "" && input.focus();
                            }}
                        >
                            <MenuItem value={"Ledger"}>Ledger</MenuItem>
                            <MenuItem value={"Cashbook"}>Cashbook</MenuItem>
                            <MenuItem value={"Growing US"}>Growing - US</MenuItem>
                            <MenuItem value={"Established US"}>Established - US</MenuItem>
                            <MenuItem value={"Standard GB"}>Standard - Global</MenuItem>
                            <MenuItem value={"Premium GB"}>Premium - Global</MenuItem>
                            <MenuItem value={"Early"}>Early</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label={t("miscellaneous.hours")}
                                name="hours"
                                onChange={handleChange}
                                value={xero.hours}
                                variant="outlined"
                                type="number"
                                disabled={xero.isCustom}
                                inputProps={{
                                    min: 1,
                                    step: 0.5,
                                    onKeyDown: (event) => {
                                        event.preventDefault();
                                    },
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label={t("miscellaneous.discount")}
                                value={xero.discount}
                                name="discount"
                                onChange={handleChangeDiscount}
                                onBlur={() =>
                                    setXero({
                                        ...xero,
                                        discount: parseFloat(xero.discount === "" ? 0 : xero.discount).toFixed(2),
                                    })
                                }
                                variant="outlined"
                                type="number"
                                InputProps={{
                                    inputProps: {
                                        min: 0,
                                        max: 100,
                                    },
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                }}
                                sx={{
                                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                        display: "none",
                                    },
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <LocalizationProvider
                        dateAdapter={AdapterMoment}
                        adapterLocale={t("language.locale")}
                        localeText={{ clearButtonLabel: t("miscellaneous.clear") }}
                    >
                        <DatePicker
                            componentsProps={{
                                actionBar: { actions: ["clear"] },
                            }}
                            value={xero.commitmentDateProposal}
                            minDate={moment()}
                            onChange={(newValue) => handleChangeEstDeliDate(newValue)}
                            inputFormat={todos.dateFormat}
                            renderInput={(params) => (
                                <TextField
                                    fullWidth
                                    required
                                    {...params}
                                    error={isError && !xero.commitmentDateProposal}
                                />
                            )}
                            onClick={(e) => {
                                setIsErrorFocus(false);
                            }}
                            label={t("services.estimDeliveryDate")}
                            views={["year", "month", "day"]}
                            inputRef={(input) => {
                                input && isErrorFocus && isError && !xero.commitmentDateProposal && input.focus();
                            }}
                        />
                    </LocalizationProvider>
                </Grid>
            </Grid>
        </StyledContainer >
    );
};
