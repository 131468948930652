/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import moment from "moment";

/** MUI icons */
import CommentIcon from "@mui/icons-material/Comment";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import NoteAddIcon from "@mui/icons-material/NoteAdd";

/** MUI imports */
import {
    Grid,
    Table,
    TableHeaderRow,
    PagingPanel,
    Toolbar,
    SearchPanel,
    TableSelection,
    TableColumnVisibility,
} from "@devexpress/dx-react-grid-material-ui";
import {
    SearchState,
    IntegratedFiltering,
    PagingState,
    IntegratedPaging,
    SelectionState,
    IntegratedSelection,
    SortingState,
    IntegratedSorting,
} from "@devexpress/dx-react-grid";
import { styled } from "@mui/material/styles";
import GridUI from "@mui/material/Grid";
import {
    Box,
    Button,
    Chip,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Skeleton,
    Select,
    TextField,
    Typography,
    Checkbox,
    ListItemText,
    Badge,
} from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

/** Components imports */
import Dialog from "../Dialog";
import DialogAlerts from "../DialogAlerts";
import ModAddProspect from "./ModAddProspect";
import ModProspectDetails from "./ModProspectDetails";
import CommDialProp from "../CommentDialProsp";

/** Component styles */
const PREFIX = "ProspectsActive";

const classes = {
    tableStriped: `${PREFIX}-tableStriped`,
    tableHeader: `${PREFIX}-tableHeader`,
    tableBody: `${PREFIX}-tableBody`,
    checkBoxDisabled: `${PREFIX}-checkBoxDisabled`,
    pager: `${PREFIX}-pager`,
    formRounded: `${PREFIX}-formRounded`,
    spacing: `${PREFIX}-spacing`,
    toolbarSearch: `${PREFIX}-toolbarSearch`,
    searchInput: `${PREFIX}-searchInput`,
    detailsCel: `${PREFIX}-detailsCel`,
};

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "rgba(19, 31, 62, 0.80)",
        fontSize: 12,
        maxWidth: 320,
        borderRadius: 6,
        fontWeight: "normal",
    },
});

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.formRounded}`]: {
        backgroundColor: "#FFFFFF !important",
        borderRadius: 40,
        verticalAlign: "middle !important",
        "&:focus-within": {
            backgroundColor: "#FFFFFF",
            borderRadius: 40,
        },
        "& .MuiOutlinedInput-notchedOutline": {
            border: "2px solid #031851",
            borderRadius: 40,
        },
    },

    [`& .${classes.spacing}`]: {
        padding: "30px 0 20px 0",
    },

    [`& .${classes.tableStriped}`]: {
        "& tbody tr:nth-of-type(odd)": {
            backgroundColor: "rgba(47,49,144,0.1)",
        },
    },
    [`& .${classes.tableHeader}`]: {
        border: "none",
        backgroundColor: "rgba(47,49,144,0.3)",
        fontSize: "15px",
        fontWeight: 600,
        color: "#031851",
        "&:first-of-type": {
            borderRadius: "20px 0 0 0",
        },
        "&:last-child": {
            borderRadius: "0 20px 0 0",
        },
    },

    [`& .${classes.tableBody}`]: {
        border: "none",
        fontSize: "14px",
        fontWeight: 400,
        color: "#031851",
    },

    [`& .${classes.checkBoxDisabled}`]: {
        border: "none",
        fontSize: "14px",
        fontWeight: 400,
        "& .MuiCheckbox-root": {
            color: "#cccccc",
        },
        pointerEvents: "none",
    },

    [`& .${classes.pager}`]: {
        "& .Pagination-button": {
            paddingTop: 0,
            paddingBottom: 0,
            color: "#031851",
            fontWeight: 600,
        },
        "& .Pagination-activeButton": {
            backgroundColor: "rgba(47,49,144,0.3)",
        },
    },

    [`& .${classes.toolbarSearch}`]: {
        border: "none !important",
        padding: "0 0 5px !important",
    },

    [`& .${classes.searchInput}`]: {
        color: "#031851",
        "&:before": {
            borderBottomColor: "#031851",
        },
        "&:hover:before": {
            borderBottomColor: "#031851 !important",
        },
    },

    [`& .${classes.detailsCel}`]: {
        color: "#2f3190",
        cursor: "pointer",
        padding: "7px",
        borderRadius: "6px",
        "&:hover": {
            borderRadius: "6px",
            backgroundColor: "rgba(47,49,144,0.3)",
        },
    },
}));

const ColorButton = styled(Button)(({ theme }) => ({
    border: "1px solid #ff5968",
    borderRadius: 40,
}));

const tableHeaderCell = ({ ...props }) => <TableHeaderRow.Cell {...props} className={classes.tableHeader} />;

const TableComponent = ({ ...props }) => <Table.Table {...props} className={classes.tableStriped} />;

const TableCellComponent = ({ ...props }) => <Table.Cell {...props} className={classes.tableBody} />;

const tableContentComponent = (props) => <TableHeaderRow.Content {...props} />;

const PagingPanelContainer = ({ ...props }) => <PagingPanel.Container {...props} className={classes.pager} />;

const inputComponent = ({ ...props }) => <SearchPanel.Input {...props} className={classes.searchInput} />;

const toolRootComponent = ({ ...props }) => <Toolbar.Root {...props} className={classes.toolbarSearch} />;

const tableHeaderSelCellBase = ({ ...props }) => (
    <TableSelection.HeaderCell {...props} className={classes.tableHeader} />
);

const TableSelCellComponent = ({ ...props }) => <TableSelection.Cell {...props} className={classes.tableBody} />;

const tableColumnExtensions = [
    { columnName: "name" },
    { columnName: "clientType" },
    { columnName: "company", align: "center", wordWrapEnabled: true, width: "30%" },
    { columnName: "origin", align: "center" },
    { columnName: "user", align: "center" },
    { columnName: "ambassador", align: "center" },
    { columnName: "createDate", align: "center" },
    { columnName: "details", align: "center" },
    { columnName: "comments", align: "center" },
    { columnName: "add", align: "center" },
];

const rowsSkeleton = [];

for (let i = 0; i < 5; i++) {
    rowsSkeleton.push({
        id: i,
        name: <Skeleton variant="text" animation="wave" />,
        clientType: <Skeleton variant="text" animation="wave" />,
        company: <Skeleton variant="text" animation="wave" />,
        origin: <Skeleton variant="text" animation="wave" />,
        user: <Skeleton variant="text" animation="wave" />,
        ambassador: <Skeleton variant="text" animation="wave" />,
        createDate: <Skeleton variant="text" animation="wave" />,
        details: (
            <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />
        ),
        comments: (
            <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />
        ),
        add: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
    });
}

const labelFormat = (company_name) => {
    return company_name.length > 20 ? `${company_name.substring(0, 20)}...` : company_name;
};

const ProspectsActive = (props) => {
    // Properties
    const { setAutoAlertType, setBackDrop, setShow, setTexto } = props;

    /** Internationalization i18n */
    const [t] = useTranslation("global");

    const tableMessages = {
        noData: t("miscellaneous.noData"),
    };

    const pagingMessages = {
        info: ({ from, to, count }) => `${from}${from < to ? `-${to}` : ""} ${t("miscellaneous.pagingOf")} ${count}`,
    };

    const searchMessages = {
        searchPlaceholder: t("miscellaneous.search"),
    };

    const noColumnsMessage = {
        noColumns: "",
    };

    /** React redux */
    const todos = useSelector((state) => state.todos);

    /* React Router */
    const navigate = useNavigate();

    /** Component states */
    const [loading, showLoading] = useState(false);
    const [clientTypeF, setClientTypeF] = useState("");
    const [sourceF, setSourceF] = useState("");
    const [userF, setUserF] = useState([]);
    const [ambassadorF, setAmbassadorF] = useState([]);
    const [sinceDateF, setSinceDateF] = useState(null);
    const [untilDateF, setUntilDateF] = useState(null);
    const [open, setOpen] = useState(false);
    const [openDialogAlert, setOpenDialogAlert] = useState(false);
    const [dialogAlertText, setDialogAlertText] = useState("");
    const [dialogAlertType, setDialogAlertType] = useState("info");
    const [openProspectDialog, setOpenProspectDialog] = useState(false);
    const [openCreateProposalDialog, setOpenCreateProposalDialog] = useState(false);
    const [searchValue, setSearchState] = useState("");
    const [isError, setIsError] = useState(false);
    const [company, setCompany] = useState("");
    const [prospectsData, setProspectsData] = useState([]);
    const [users, setUsers] = useState([]);
    const [ambassadors, setAmbassadors] = useState([]);
    const [columnDefs, setColumnsDefs] = useState([]);
    const [defaultHiddenColumnNames] = useState(["hiddenCompanies"]);
    const [selection, setSelection] = useState([]);
    const [valuesProspect, setValuesProspect] = useState({
        id: "",
        name: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        type: "",
        ambassador: "",
        creationDate: "",
        relatedCompanies: [],
        market: "",
        countryName: "",
        activity: "",
    });
    const [prospectSelected, setProspectSelected] = useState({
        id: "",
        name: "",
        companies: [],
    });
    const [comments, setComments] = useState([]);
    const [commentsP, setCommentsPend] = useState([]);
    const [dialogComment, setDialogComment] = useState(false);
    const [propectID, setProspectID] = useState("");
    const [sorting, setSorting] = useState([{ columnName: "createDate", direction: "desc" }]);
    const [anyFilter, setAnyFilter] = useState(true);

    const compareDate = (a, b) => {
        if (a === b) {
            return 0;
        }

        const datea = moment(a, todos.dateFormat);
        const dateb = moment(b, todos.dateFormat);
        //console.log(datea + ' > ' + dateb + (datea > dateb ? 'Si' : 'No'))
        return datea > dateb ? 1 : -1;
    };

    const [integratedSortingColumnExtensions] = useState([{ columnName: "createDate", compare: compareDate }]);

    const SortingLabel = React.memo((props) => {
        if (props.column.name === "name" || props.column.name === "user" || props.column.name === "createDate")
            return <TableHeaderRow.SortLabel {...props} />;
        return <TableHeaderRow.SortLabel {...props} disabled />;
    });

    /** Component functions */
    useEffect(() => {
        (async () => {
            const columnsTemplate = [
                { name: "hiddenCompanies", title: "Hidden Companies" },
                { name: "name", title: t("proposals.name") },
                { name: "clientType", title: t("miscellaneous.type2") },
                { name: "company", title: t("proposals.companies") },
                { name: "origin", title: t("proposals.origin") },
                { name: "user", title: t("proposals.responsible") },
                { name: "ambassador", title: t("proposals.ambassador") },
                { name: "createDate", title: t("proposals.createDate") },
                { name: "details", title: " " },
                { name: "comments", title: " " },
                { name: "add", title: " " },
            ];
            setColumnsDefs(columnsTemplate);

            await loadInitData();
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t, todos.dateFormat, clientTypeF, sourceF, userF, ambassadorF, sinceDateF, untilDateF]);

    const cleanFilters = () => {
        setAnyFilter(true);
        setClientTypeF("");
        setSourceF("");
        setUserF([]);
        setAmbassadorF([]);
        setSinceDateF(null);
        setUntilDateF(null);
    };

    const openComments = async (prospectID) => {
        setBackDrop(true);

        setProspectID(prospectID);

        await getComments(prospectID);

        setDialogComment(true);

        setBackDrop(false);
    };

    const getComments = async (prospectID) => {
        await fetch(`/getComments?prospect_id=${prospectID}&comment_type=3`, {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then((res) => res.json())
            .then((data) => {
                setComments(data.history);
                setCommentsPend(data.pendings);
            });
    };

    const closeCommentsDialog = () => {
        setDialogComment(false);
    };

    const loadInitData = async () => {
        setProspectsData(rowsSkeleton);

        const resultT = await fetch(
            `/getProspects?client_type=${clientTypeF}&prospect_source=${sourceF}&update_user=${userF}&ambassador_id=${ambassadorF}&init_date=${
                sinceDateF === null ? "" : sinceDateF.format()
            }&end_date=${untilDateF === null ? "" : untilDateF.format()}&status=1`,
            {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            }
        )
            .then((res) => res.json())
            .then((data) => {
                return data;
            });

        let prospects = resultT.map((item) => {
            let cont = 0;

            item.comments.forEach((data) => {
                if (data.status === 0) {
                    cont++;
                }
            });

            return {
                id: item._id,
                hiddenCompanies: item.companies.map((element) => element.company_legal_name),
                name: `${item.prospect_name} ${item.prospect_last_name}`,
                clientType: item.client_type === 1 ? t("miscellaneous.firm") : t("miscellaneous.smb"),
                company: item.companies.map((company) => (
                    <LightTooltip title={company.company_legal_name}>
                        <Chip
                            label={labelFormat(company.company_legal_name)}
                            sx={{
                                backgroundColor: "#fff",
                                borderColor: "rgba(3,24,81,0.4)",
                                color: "#031851",
                                m: "3px",
                                minWidth: "180px",
                            }}
                            variant="outlined"
                        />
                    </LightTooltip>
                )),
                origin: item.prospect_source === 1 ? t("principal.gcaPlatform") : "-",
                user: item.update_user ?? "-",
                ambassador: item.ambassador_name,
                createDate: moment(item.creation_date, "YYYY-MM-DD").format(todos.dateFormat),
                details: (
                    <Typography
                        component="span"
                        variant="h4"
                        className={classes.detailsCel}
                        onClick={() => handleOpenProspectDetails(item._id)}
                    >
                        {t("proposals.viewDetail")}
                    </Typography>
                ),
                comments: (
                    <LightTooltip title={t("miscellaneous.comments")} aria-label="comment-tooltip">
                        <IconButton aria-label="comment" onClick={() => openComments(item._id)}>
                            <Badge badgeContent={cont} color="secondary">
                                <CommentIcon sx={{ color: "#031851" }} />
                            </Badge>
                        </IconButton>
                    </LightTooltip>
                ),
                add: (
                    <LightTooltip title={t("proposals.createProposal")} aria-label="add-tooltip">
                        <IconButton
                            aria-label="add"
                            onClick={() => {
                                if (item.companies.length === 1) {
                                    navigate(
                                        `/proposals/createProposal?prospectID=${item._id}&companyID=${item.companies[0]._id}`
                                    );
                                } else {
                                    handleOpenCreateProposalDialog(
                                        item._id,
                                        `${item.prospect_name} ${item.prospect_last_name}`,
                                        item.companies
                                    );
                                }
                            }}
                        >
                            <NoteAddIcon sx={{ color: "#031851" }} />
                        </IconButton>
                    </LightTooltip>
                ),
                hiddenDate: item.creation_date
            };
        });

        await prospects.sort(function compare(a, b) {
            let dateA = +new Date(a.hiddenDate) || Number.MIN_SAFE_INTEGER;
            let dateB = +new Date(b.hiddenDate) || Number.MIN_SAFE_INTEGER;
            return dateB - dateA;
        });

        setProspectsData(prospects);
    };

    useEffect(() => {
        (async () => {
            showLoading(true);

            await fetch(`/getAllUserData`, {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
                .then((res) => res.json())
                .then((data) => {
                    setUsers(data);
                });

            await fetch(`/getAllAmbassadorsData`, {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
                .then((res) => res.json())
                .then((data) => {
                    setAmbassadors(data);
                });

            showLoading(false);
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t, todos.dateFormat, todos.amountFormat]);

    const handleSearch = (value) => {
        let itemS = value.replace(/ +(?= )/g, "");
        setSearchState(itemS);
    };

    const handleOpenDialogAlert = () => {
        setDialogAlertType("info");
        setDialogAlertText(t("proposals.prospectsSureArchive"));
        setOpenDialogAlert(true);
    };

    const handleCloseDialogAlert = () => {
        setDialogAlertText("");
        setDialogAlertType("");
        setOpenDialogAlert(false);
    };

    const handleOpenCreateProposalDialog = (prospect_id, prospect_name, companies) => {
        setProspectSelected({
            id: prospect_id,
            name: prospect_name,
            companies,
        });
        setOpenCreateProposalDialog(true);
    };

    const handleCloseCreateProposalDialog = () => {
        setIsError(false);
        setCompany("");
        setProspectSelected({
            id: "",
            name: "",
            companies: [],
        });
        setOpenCreateProposalDialog(false);
    };

    const handleChangeClientTypeF = (event) => {
        setClientTypeF(event.target.value);
        setAnyFilter(false);
    };

    const handleChangeSourceF = (event) => {
        setSourceF(event.target.value);
        setAnyFilter(false);
    };

    const handleChangeUserF = (event) => {
        const {
            target: { value },
        } = event;
        setUserF(typeof value === "string" ? value.split(",") : value);
        setAnyFilter(false);
    };

    const handleChangeAmbassadorF = (event) => {
        const {
            target: { value },
        } = event;
        setAmbassadorF(typeof value === "string" ? value.split(",") : value);
        setAnyFilter(false);
    };

    const handleChangeSinceDate = (date) => {
        let sinceDate = moment(date).utc().startOf("day");

        if (date === null) {
            setSinceDateF(date);
        } else if (untilDateF === null) {
            setSinceDateF(sinceDate);
        } else {
            if (moment(date).isAfter(moment(untilDateF))) {
                setSinceDateF(null);
                setTexto(t("reports.initDateMess"));
                setAutoAlertType("warning");
                setShow(true);
            } else {
                setSinceDateF(sinceDate);
            }
        }
        setAnyFilter(false);
    };

    const handleChangeUntilDate = (date) => {
        let endDate = moment(date).utc().startOf("day");

        if (date === null) {
            setUntilDateF(date);
        } else if (sinceDateF === null) {
            setUntilDateF(endDate);
        } else {
            if (moment(date).isBefore(moment(sinceDateF))) {
                setUntilDateF(null);
                setTexto(t("reports.endDateMess"));
                setAutoAlertType("warning");
                setShow(true);
            } else {
                setUntilDateF(endDate);
            }
        }
        setAnyFilter(false);
    };

    const handleOpenProspectDetails = async (prospect_id) => {
        setBackDrop(true);

        await fetch(`/getProspectInfo?prospect_id=${prospect_id}`, {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then((res) => res.json())
            .then((data) => {
                setValuesProspect({
                    id: data._id,
                    name: data.prospect_name,
                    lastName: data.prospect_last_name,
                    email: data.prospect_email,
                    phoneNumber: data.prospect_phone,
                    type: data.client_type,
                    ambassador: data.ambassador_name,
                    creationDate: moment.utc(data.creation_date).local().format(`${todos.dateFormat} HH:mm`),
                    relatedCompanies: data.companies,
                    market: "",
                    activity: "",
                    countryName: "",
                });
                setOpenProspectDialog(true);
            });

        setBackDrop(false);
    };

    const handleChangeCompany = (event) => {
        setCompany(event.target.value);
    };

    const archiveProspects = async () => {
        setBackDrop(true);

        let arrayValidation = [];

        selection.forEach((selectedRow) => {
            arrayValidation.push(prospectsData[selectedRow].id);
        });

        const params = {
            array_prospects: arrayValidation,
        };

        await fetch("/addArchiveProspect", {
            method: "POST",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
            body: JSON.stringify(params),
        })
            .then(() => {
                handleCloseDialogAlert();
                setSelection([]);
                loadInitData();
                setTexto(t("proposals.prospectsArchived"));
                setAutoAlertType("success");
                setShow(true);
            })
            .catch((error) => {
                console.log(error);
            });

        setBackDrop(false);
    };

    const startCreateProposal = () => {
        if (company !== "") {
            navigate(`/proposals/createProposal?prospectID=${prospectSelected.id}&companyID=${company}`);
        } else {
            setIsError(true);
            setTexto(t("miscellaneous.requiredData"));
            setAutoAlertType("warning");
            setShow(true);
        }
    };

    const dialogBody = (
        <GridUI container spacing={2}>
            <GridUI item xs={12}>
                <Typography variant="body1" color="textPrimary">
                    {t("proposals.selectCompanyProposal")}
                </Typography>
            </GridUI>
            <GridUI item xs={12}>
                <FormControl fullWidth required error={isError && company === "" ? true : false}>
                    <InputLabel id="company-simple-select-label">{t("proposals.company")}</InputLabel>
                    <Select
                        name="company"
                        labelId="company-simple-select-label"
                        IconComponent={KeyboardArrowDownIcon}
                        value={company}
                        label={t("proposals.company")}
                        onChange={handleChangeCompany}
                        MenuProps={{
                            PaperProps: {
                                sx: {
                                    mt: 0.7,
                                    borderRadius: 2,
                                    boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                },
                            },
                        }}
                    >
                        {prospectSelected.companies.map(({ _id, company_legal_name }) => (
                            <MenuItem value={_id}>{company_legal_name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </GridUI>
        </GridUI>
    );

    const dialogButtons = (
        <GridUI container justifyContent="space-between" alignItems="center" spacing={2}>
            <GridUI item>
                <ColorButton
                    sx={{
                        mr: 1,
                        "&:hover": {
                            backgroundColor: "white",
                            color: "#2f3190",
                            border: "1px solid #2f3190",
                        },
                    }}
                    color="secondary"
                    disableElevation
                    variant="outlined"
                    onClick={handleCloseCreateProposalDialog}
                >
                    {t("miscellaneous.cancel")}
                </ColorButton>
            </GridUI>
            <GridUI item>
                <ColorButton
                    sx={{
                        "&:hover": {
                            backgroundColor: "#2f3190",
                            border: "1px solid #2f3190",
                        },
                    }}
                    color="secondary"
                    disableElevation
                    variant="contained"
                    onClick={startCreateProposal}
                >
                    {t("miscellaneous.save")}
                </ColorButton>
            </GridUI>
        </GridUI>
    );

    return (
        <StyledContainer component="main" sx={{ flexGrow: 1, width: "100%" }}>
            {loading ? (
                <GridUI container spacing={2} className={classes.spacing} alignItems="center">
                    <GridUI item xs={12} md={4} xl={1}>
                        <Skeleton
                            variant="rectangular"
                            animation="wave"
                            width="100%"
                            height={40}
                            sx={{ borderRadius: 8 }}
                        />
                    </GridUI>
                    <GridUI item xs={12} md={4} xl={1}>
                        <Skeleton
                            variant="rectangular"
                            animation="wave"
                            width="100%"
                            height={40}
                            sx={{ borderRadius: 8 }}
                        />
                    </GridUI>
                    <GridUI item xs={12} md={4} xl={2}>
                        <Skeleton
                            variant="rectangular"
                            animation="wave"
                            width="100%"
                            height={40}
                            sx={{ borderRadius: 8 }}
                        />
                    </GridUI>
                    <GridUI item xs={12} md={4} xl={2}>
                        <Skeleton
                            variant="rectangular"
                            animation="wave"
                            width="100%"
                            height={40}
                            sx={{ borderRadius: 8 }}
                        />
                    </GridUI>
                    <GridUI item xs={12} md={4} xl={3}>
                        <Skeleton
                            variant="rectangular"
                            animation="wave"
                            width="100%"
                            height={40}
                            sx={{ borderRadius: 8 }}
                        />
                    </GridUI>
                    <GridUI item xs={12} md={4} xl={3}>
                        <GridUI container justifyContent="flex-end" spacing={2}>
                            <GridUI item>
                                <Skeleton
                                    variant="rectangular"
                                    animation="wave"
                                    width={115}
                                    height={40}
                                    sx={{ borderRadius: 8 }}
                                />
                            </GridUI>
                            <GridUI item>
                                <Skeleton
                                    variant="rectangular"
                                    animation="wave"
                                    width={140}
                                    height={40}
                                    sx={{ borderRadius: 8 }}
                                />
                            </GridUI>
                        </GridUI>
                    </GridUI>
                </GridUI>
            ) : (
                <GridUI container spacing={2} className={classes.spacing} alignItems="center">
                    <GridUI item xs={12} md={4} xl={1}>
                        <FormControl fullWidth classes={{ root: classes.formRounded }}>
                            <InputLabel shrink id="origin-simple-select-label">
                                {t("miscellaneous.type2")}
                            </InputLabel>
                            <Select
                                size="small"
                                IconComponent={KeyboardArrowDownIcon}
                                value={clientTypeF}
                                displayEmpty
                                onChange={handleChangeClientTypeF}
                                className="ddlGeneral"
                                input={<OutlinedInput notched label={t("miscellaneous.type2")} />}
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            mt: 0.7,
                                            borderRadius: 2,
                                            boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                        },
                                    },
                                }}
                            >
                                <MenuItem value="">
                                    <Typography variant="body1" component="span" align="center" color="textPrimary">
                                        {t("miscellaneous.all")}
                                    </Typography>
                                </MenuItem>
                                <MenuItem value={1}>
                                    <Typography variant="body1" component="span" align="center" color="textPrimary">
                                        {t("miscellaneous.firm")}
                                    </Typography>
                                </MenuItem>
                                <MenuItem value={2}>
                                    <Typography variant="body1" component="span" align="center" color="textPrimary">
                                        {t("miscellaneous.smb")}
                                    </Typography>
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </GridUI>
                    <GridUI item xs={12} md={4} xl={1}>
                        <FormControl fullWidth classes={{ root: classes.formRounded }}>
                            <InputLabel shrink id="origin-simple-select-label">
                                {t("proposals.origin")}
                            </InputLabel>
                            <Select
                                size="small"
                                IconComponent={KeyboardArrowDownIcon}
                                value={sourceF}
                                displayEmpty
                                onChange={handleChangeSourceF}
                                className="ddlGeneral"
                                input={<OutlinedInput notched label={t("proposals.origin")} />}
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            mt: 0.7,
                                            borderRadius: 2,
                                            boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                        },
                                    },
                                }}
                            >
                                <MenuItem value="">
                                    <Typography variant="body1" component="span" align="center" color="textPrimary">
                                        {t("miscellaneous.all")}
                                    </Typography>
                                </MenuItem>
                                <MenuItem value={1}>
                                    <Typography variant="body1" component="span" align="center" color="textPrimary">
                                        {t("principal.gcaPlatform")}
                                    </Typography>
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </GridUI>
                    <GridUI item xs={12} md={4} xl={2}>
                        <FormControl
                            fullWidth
                            classes={{
                                root: classes.formRounded,
                            }}
                            size="small"
                        >
                            <InputLabel id="user-multiple-select-label" shrink>
                                {t("proposals.responsible")}
                            </InputLabel>
                            <Select
                                labelId="user-multiple-select-label"
                                IconComponent={KeyboardArrowDownIcon}
                                displayEmpty
                                value={userF}
                                onChange={handleChangeUserF}
                                multiple
                                input={
                                    <OutlinedInput
                                        notched
                                        label={t("proposals.responsible")}
                                        sx={{
                                            fontSize: 16,
                                            fontWeight: 600,
                                        }}
                                    />
                                }
                                renderValue={(selected) => {
                                    if (selected.length === 0) {
                                        return <span>{t("miscellaneous.all")}</span>;
                                    }

                                    var selectedString = "";
                                    users.forEach((user) => {
                                        if (userF.indexOf(user.update_user) > -1) {
                                            if (selectedString !== "") selectedString += ", ";

                                            selectedString += user.update_user_name;
                                        }
                                    });

                                    return selectedString;
                                }}
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            mt: 0.7,
                                            borderRadius: 2,
                                            boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                        },
                                    },
                                }}
                            >
                                {users.map((user) => (
                                    <MenuItem value={user.update_user}>
                                        <Checkbox checked={userF.indexOf(user.update_user) > -1} />
                                        <ListItemText primary={user.update_user_name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </GridUI>
                    <GridUI item xs={12} md={4} xl={2}>
                        <FormControl
                            fullWidth
                            classes={{
                                root: classes.formRounded,
                            }}
                            size="small"
                        >
                            <InputLabel id="market-multiple-select-label" shrink>
                                {t("proposals.ambassador")}
                            </InputLabel>
                            <Select
                                labelId="market-multiple-select-label"
                                IconComponent={KeyboardArrowDownIcon}
                                displayEmpty
                                value={ambassadorF}
                                onChange={handleChangeAmbassadorF}
                                multiple
                                input={
                                    <OutlinedInput
                                        notched
                                        label={t("proposals.ambassador")}
                                        sx={{
                                            fontSize: 16,
                                            fontWeight: 600,
                                        }}
                                    />
                                }
                                renderValue={(selected) => {
                                    if (selected.length === 0) {
                                        return <span>{t("miscellaneous.all")}</span>;
                                    }

                                    var selectedString = "";
                                    ambassadors.forEach((ambassador) => {
                                        if (ambassadorF.indexOf(ambassador._id) > -1) {
                                            if (selectedString !== "") selectedString += ", ";

                                            selectedString += ambassador.ambassador_name;
                                        }
                                    });

                                    return selectedString;
                                }}
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            mt: 0.7,
                                            borderRadius: 2,
                                            boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                        },
                                    },
                                }}
                            >
                                {ambassadors.map((ambassador) => (
                                    <MenuItem value={ambassador._id}>
                                        <Checkbox checked={ambassadorF.indexOf(ambassador._id) > -1} />
                                        <ListItemText primary={ambassador.ambassador_name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </GridUI>
                    <GridUI item xs={12} md={4} xl={3}>
                        <LocalizationProvider
                            dateAdapter={AdapterMoment}
                            adapterLocale={t("language.locale")}
                            localeText={{ clearButtonLabel: t("miscellaneous.clear") }}
                        >
                            <GridUI container justifyContent="center">
                                <GridUI item xs={6}>
                                    <DatePicker
                                        componentsProps={{
                                            actionBar: { actions: ["clear"] },
                                        }}
                                        value={sinceDateF}
                                        onChange={(newValue) => handleChangeSinceDate(newValue)}
                                        inputFormat={todos.dateFormat}
                                        renderInput={({ inputProps, ...params }) => (
                                            <TextField
                                                fullWidth
                                                {...params}
                                                inputProps={{
                                                    ...inputProps,
                                                    placeholder: t("invoicing.initDate"),
                                                    sx: {
                                                        p: "10.45px 0px 10.45px 18px !important",
                                                    },
                                                }}
                                                sx={{
                                                    "& .MuiOutlinedInput-notchedOutline": {
                                                        mr: "-6px !important",
                                                        border: "2px solid #031851",
                                                        borderRadius: "40px 0px 0px 40px",
                                                    },
                                                }}
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        )}
                                        label={t("invoicing.range")}
                                    />
                                </GridUI>
                                <GridUI item xs={6}>
                                    <DatePicker
                                        componentsProps={{
                                            actionBar: { actions: ["clear"] },
                                        }}
                                        value={untilDateF}
                                        onChange={(newValue) => handleChangeUntilDate(newValue)}
                                        inputFormat={todos.dateFormat}
                                        renderInput={({ inputProps, ...params }) => (
                                            <TextField
                                                fullWidth
                                                {...params}
                                                inputProps={{
                                                    ...inputProps,
                                                    placeholder: t("invoicing.endDate"),
                                                    sx: {
                                                        p: "10.45px 0px 10.45px 18px !important",
                                                    },
                                                }}
                                                sx={{
                                                    ml: 0.5,
                                                    "& .MuiOutlinedInput-notchedOutline": {
                                                        border: "2px solid #031851",
                                                        borderRadius: "0px 40px 40px 0px",
                                                    },
                                                }}
                                            />
                                        )}
                                    />
                                </GridUI>
                            </GridUI>
                        </LocalizationProvider>
                    </GridUI>
                    <GridUI item xs={12} md={4} xl={3}>
                        <GridUI container justifyContent="flex-end" spacing={2}>
                            <GridUI item sx={{ textAlign: "right !important" }}>
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    disabled={selection.length === 0}
                                    disableElevation
                                    onClick={handleOpenDialogAlert}
                                    sx={{
                                        pl: 3,
                                        pr: 3,
                                        borderRadius: 40,
                                        "&:hover": {
                                            border: "1px solid #2f3190",
                                            color: "#2f3190",
                                        },
                                        "&.Mui-disabled": {
                                            color: "#ffcdd2",
                                            borderColor: "#ffcdd2",
                                            background: "transparent",
                                        },
                                    }}
                                >
                                    {t("proposals.archive")}
                                </Button>
                            </GridUI>
                            <GridUI item sx={{ textAlign: "right !important" }}>
                                <Button
                                    disableElevation
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => setOpen(true)}
                                    sx={{
                                        pl: 3,
                                        pr: 3,
                                        borderRadius: 40,
                                        "&:hover": {
                                            backgroundColor: "#2f3190",
                                            color: "#fff",
                                        },
                                        "&.Mui-disabled": {
                                            color: "#fff",
                                            borderColor: "#ffcdd2",
                                            background: "#ffcdd2",
                                        },
                                    }}
                                >
                                    {t("proposals.createNew")}
                                </Button>
                            </GridUI>
                        </GridUI>
                    </GridUI>
                </GridUI>
            )}
            <GridUI container>
                <GridUI item xs={12} md={6} sx={{ mb: { sm: -5 }, zIndex: 1 }}>
                    {loading ? (
                        <Skeleton
                            variant="rectangular"
                            animation="wave"
                            width="30%"
                            height={40}
                            sx={{ borderRadius: 8 }}
                        />
                    ) : (
                        <Button
                            variant="text"
                            color="primary"
                            disabled={anyFilter}
                            onClick={cleanFilters}
                            sx={{
                                "&:hover": {
                                    color: "#2f3190",
                                },
                            }}
                        >
                            {t("miscellaneous.cleanFilters")}
                        </Button>
                    )}
                </GridUI>
                <GridUI item xs={12}>
                    <Grid rows={prospectsData} columns={columnDefs}>
                        <SelectionState selection={selection} onSelectionChange={setSelection} />
                        <SearchState value={searchValue} onValueChange={handleSearch} />
                        <SortingState sorting={sorting} onSortingChange={setSorting} />
                        <IntegratedSorting columnExtensions={integratedSortingColumnExtensions} />
                        <IntegratedFiltering />
                        <PagingState defaultCurrentPage={0} pageSize={10} />
                        <IntegratedPaging />
                        <IntegratedSelection />
                        <Table
                            tableComponent={TableComponent}
                            columnExtensions={tableColumnExtensions}
                            cellComponent={TableCellComponent}
                            messages={tableMessages}
                        />
                        <TableColumnVisibility
                            defaultHiddenColumnNames={defaultHiddenColumnNames}
                            messages={noColumnsMessage}
                        />
                        <TableHeaderRow
                            cellComponent={tableHeaderCell}
                            contentComponent={tableContentComponent}
                            showSortingControls
                            sortLabelComponent={SortingLabel}
                            messages={{
                                sortingHint: t("miscellaneous.sorting"),
                            }}
                        />
                        <TableSelection
                            showSelectAll
                            headerCellComponent={tableHeaderSelCellBase}
                            cellComponent={TableSelCellComponent}
                        />
                        <Toolbar rootComponent={toolRootComponent} />
                        <SearchPanel messages={searchMessages} inputComponent={inputComponent} />
                        <PagingPanel containerComponent={PagingPanelContainer} messages={pagingMessages} />
                    </Grid>
                </GridUI>
            </GridUI>
            <Dialog
                open={openCreateProposalDialog}
                onClose={handleCloseCreateProposalDialog}
                message={dialogBody}
                title={`${t("proposals.createProposal")} - ${prospectSelected.name}`}
                button={dialogButtons}
                maxWidth="sm"
            />
            <DialogAlerts
                open={openDialogAlert}
                onClose={handleCloseDialogAlert}
                agreeBtnLabel={t("miscellaneous.accept")}
                disagreeBtnLabel={t("miscellaneous.cancel")}
                type={dialogAlertType}
                title={dialogAlertText}
                agreeAction={archiveProspects}
            />
            <ModAddProspect
                open={open}
                onClose={() => setOpen(false)}
                loadInitData={loadInitData}
                setBackDrop={setBackDrop}
                setShow={setShow}
                setTexto={setTexto}
                setAutoAlertType={setAutoAlertType}
            />
            <ModProspectDetails
                open={openProspectDialog}
                onClose={() => setOpenProspectDialog(false)}
                values={valuesProspect}
                set={setValuesProspect}
                editMode={true}
                loadInitData={loadInitData}
                setBackDrop={setBackDrop}
                setShow={setShow}
                setTexto={setTexto}
                setAutoAlertType={setAutoAlertType}
            />
            <CommDialProp
                open={dialogComment}
                onClose={closeCommentsDialog}
                prospectID={propectID}
                setTexto={setTexto}
                setAutoAlertType={setAutoAlertType}
                setBackDrop={setBackDrop}
                setShow={setShow}
                comments={comments}
                commentsP={commentsP}
                getComments={getComments}
                commentType={3}
                loadInitData={loadInitData}
            />
        </StyledContainer>
    );
};

export default ProspectsActive;
