/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";

/** MUI imports */
import { styled } from "@mui/material/styles";
import { Box, Chip, Grid, Typography, Divider } from "@mui/material";
// import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

/** Component imports */
import { Xero, KiiperBanking, KiiperTaxes, KiiperReporting, Other, Consulting } from "./";

/** Component styles */
const PREFIX = "SetupRoot";

const classes = {
    cardContentRoot: `${PREFIX}-cardContentRoot`,
    chip: `${PREFIX}-chip`,
    chipProActive: `${PREFIX}-chipProActive`,
    chipProWasActive: `${PREFIX}-chipProWasActive`,
    selectedChip: `${PREFIX}-selectedChip`,
    selectedChipActive: `${PREFIX}-selectedChipActive`,
    selectedChipWasActive: `${PREFIX}-selectedChipWasActive`,
    divider: `${PREFIX}-divider`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.cardContentRoot}`]: {
        padding: "24px 48px 48px",
    },

    [`& .${classes.chip}`]: {
        padding: 20,
        width: 200,
        backgroundColor: "#EAEAF4",
        color: "#031851",
        fontWeight: "700 !important",
        "&:hover": {
            backgroundColor: "rgba(213, 214, 233, 0.3)",
            color: "#031851",
        },
        borderRadius: 40,
    },

    [`& .${classes.chipProActive}`]: {
        padding: 20,
        width: 200,
        backgroundColor: "#EAEAF4",
        color: "#031851",
        border: "2px solid #ff5968",
        fontWeight: "700 !important",
        "&:hover": {
            backgroundColor: "rgba(213, 214, 233, 0.3)",
            color: "#031851",
        },
        borderRadius: 40,
    },

    [`& .${classes.chipProWasActive}`]: {
        padding: 20,
        width: 200,
        backgroundColor: "#EAEAF4",
        color: "#031851",
        border: "2px solid #031851",
        fontWeight: "700 !important",
        "&:hover": {
            backgroundColor: "rgba(213, 214, 233, 0.3)",
            color: "#031851",
        },
        borderRadius: 40,
    },

    [`& .${classes.selectedChip}`]: {
        padding: 20,
        width: 200,
        backgroundColor: "#2f3190",
        color: "#fff",
        fontWeight: "700 !important",
        borderRadius: 40,
        "&:hover": {
            backgroundColor: "rgba(47, 49, 144, 0.9)",
            color: "#fff",
        },
    },

    [`& .${classes.selectedChipActive}`]: {
        padding: 20,
        width: 200,
        backgroundColor: "#2f3190",
        color: "#fff",
        fontWeight: "700 !important",
        border: "2px solid #ff5968",
        borderRadius: 40,
        "&:hover": {
            backgroundColor: "rgba(47, 49, 144, 0.9)",
            color: "#fff",
        },
    },

    [`& .${classes.selectedChipWasActive}`]: {
        padding: 20,
        width: 200,
        backgroundColor: "#2f3190",
        color: "#fff",
        fontWeight: "700 !important",
        border: "2px solid #031851",
        borderRadius: 40,
        "&:hover": {
            backgroundColor: "rgba(47, 49, 144, 0.9)",
            color: "#fff",
        },
    },

    [`& .${classes.divider}`]: {
        marginBottom: 10,
    },
}));

export const SetupRoot = (props) => {
    // Properties
    const {
        setTexto,
        setalertType,
        setShow,
        setBackDrop,
        serviceData,
        companyID,
        setServiceData,
        clientMarket,
        setAuxExpanded,
        setExpanded,
        lastChipSelected,
        setLastChipSelected,
    } = props;

    /** Internationalization i18n */
    const [t] = useTranslation("global");
    const [renderState, setRenderState] = useState(false);

    /* Component states */
    const [valuesSetup, setValuesSetup] = useState({
        setupXero: false,
        setupBanking: false,
        setupTaxes: false,
        setupReport: false,
        setupOther: false,
        setupConsult: false,
    });
    const [projectsData, setProjectsData] = useState({
        setupXero: {},
        setupBanking: {},
        setupTaxes: {},
        setupReport: {},
        setupOther: {},
        setupConsult: {},
    });
    const [active, setActive] = useState({
        setupXero: {
            isActive: false,
            wasActive: false,
        },
        setupBanking: {
            isActive: false,
            wasActive: false,
        },
        setupTaxes: {
            isActive: false,
            wasActive: false,
        },
        setupReport: {
            isActive: false,
            wasActive: false,
        },
        setupOther: {
            isActive: false,
            wasActive: false,
        },
        setupConsult: {
            isActive: false,
            wasActive: false,
        },
    });

    const getProjects = async (isReload) => {
        setBackDrop(true);

        let serviceCompID = serviceData._id ?? "";

        if (isReload) {
            await fetch(`/getNewServicesCompany?company_id=${companyID}`, {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
                .then((res) => res.json())
                .then(async (data) => {
                    setServiceData(data);
                    let lastProject = data.filter(function (o) {
                        return o.hasOwnProperty("_id") && o.service_name === "Software setup";
                    })[0];

                    await fetch(
                        `/getProjectsCompany?company_id=${lastProject.company_id}&service_id=${lastProject.service_id}&service_company_id=${lastProject._id}`,
                        {
                            method: "GET",
                            headers: {
                                "Content-type": "application/json; charset=UTF-8",
                                "Access-Control-Allow-Origin": "*",
                            },
                        }
                    )
                        .then((res) => res.json())
                        .then(async (data) => {
                            let result = {};
                            let lastProyects = {
                                consulting: {},
                            };

                            await data.forEach((item) => {
                                switch (item.project_type) {
                                    case 34:
                                        result.setupXero = item.projects_array[0];
                                        break;
                                    case 36:
                                        result.setupBanking = item.projects_array[0];
                                        break;
                                    case 37:
                                        result.setupTaxes = item.projects_array[0];
                                        break;
                                    case 70:
                                        result.setupReport = item.projects_array[0];
                                        break;
                                    case 38:
                                        result.setupOther = item.projects_array[0];
                                        break;
                                    default:
                                        result.setupConsult = item;
                                        lastProyects.consulting = item.projects_array[0];
                                        break;
                                }
                            });

                            setProjectsData({
                                setupXero: result.setupXero,
                                setupBanking: result.setupBanking,
                                setupTaxes: result.setupTaxes,
                                setupReport: result.setupReport,
                                setupOther: result.setupOther,
                                setupConsult: result.setupConsult,
                            });

                            setActive({
                                setupXero: {
                                    isActive:
                                        result.setupXero.since_date &&
                                        (!result.setupXero.client_delivery_date ||
                                            moment(result.setupXero.client_delivery_date).isAfter(moment())),
                                    wasActive: result.setupXero.client_delivery_date
                                        ? moment(result.setupXero.client_delivery_date).isSameOrBefore(moment())
                                        : false,
                                },
                                setupBanking: {
                                    isActive:
                                        result.setupBanking.since_date &&
                                        (!result.setupBanking.client_delivery_date ||
                                            moment(result.setupBanking.client_delivery_date).isAfter(moment())),
                                    wasActive: result.setupBanking.client_delivery_date
                                        ? moment(result.setupBanking.client_delivery_date).isSameOrBefore(moment())
                                        : false,
                                },
                                setupTaxes: {
                                    isActive:
                                        result.setupTaxes.since_date &&
                                        (!result.setupTaxes.client_delivery_date ||
                                            moment(result.setupTaxes.client_delivery_date).isAfter(moment())),
                                    wasActive: result.setupTaxes.client_delivery_date
                                        ? moment(result.setupTaxes.client_delivery_date).isSameOrBefore(moment())
                                        : false,
                                },
                                setupReport: {
                                    isActive:
                                        result.setupReport.since_date &&
                                        (!result.setupReport.client_delivery_date ||
                                            moment(result.setupReport.client_delivery_date).isAfter(moment())),
                                    wasActive: result.setupReport.client_delivery_date
                                        ? moment(result.setupReport.client_delivery_date).isSameOrBefore(moment())
                                        : false,
                                },
                                setupOther: {
                                    isActive:
                                        result.setupOther.since_date &&
                                        (!result.setupOther.client_delivery_date ||
                                            moment(result.setupOther.client_delivery_date).isAfter(moment())),
                                    wasActive: result.setupOther.client_delivery_date
                                        ? moment(result.setupOther.client_delivery_date).isSameOrBefore(moment())
                                        : false,
                                },
                                setupConsult: {
                                    isActive: false,
                                    wasActive: lastProyects.consulting.since_date ? true : false,
                                },
                            });

                            setAuxExpanded(lastProject.service_name);
                            setExpanded(true);
                            setRenderState(!renderState);
                        });
                });
        } else {
            await fetch(
                `/getProjectsCompany?company_id=${companyID}&service_id=${serviceData.service_id}&service_company_id=${serviceCompID}`,
                {
                    method: "GET",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                    },
                }
            )
                .then((res) => res.json())
                .then((data) => {
                    let result = {};
                    let lastProyects = {
                        consulting: {},
                    };

                    data.forEach((item) => {
                        switch (item.project_type) {
                            case 34:
                                result.setupXero = item.projects_array[0];
                                break;
                            case 36:
                                result.setupBanking = item.projects_array[0];
                                break;
                            case 37:
                                result.setupTaxes = item.projects_array[0];
                                break;
                            case 70:
                                result.setupReport = item.projects_array[0];
                                break;
                            case 38:
                                result.setupOther = item.projects_array[0];
                                break;
                            default:
                                result.setupConsult = item;
                                lastProyects.consulting = item.projects_array[0];
                                break;
                        }
                    });

                    setProjectsData({
                        setupXero: result.setupXero,
                        setupBanking: result.setupBanking,
                        setupTaxes: result.setupTaxes,
                        setupReport: result.setupReport,
                        setupOther: result.setupOther,
                        setupConsult: result.setupConsult,
                    });

                    setActive({
                        setupXero: {
                            isActive:
                                result.setupXero.since_date &&
                                (!result.setupXero.client_delivery_date ||
                                    moment(result.setupXero.client_delivery_date).isAfter(moment())),
                            wasActive: result.setupXero.client_delivery_date
                                ? moment(result.setupXero.client_delivery_date).isSameOrBefore(moment())
                                : false,
                        },
                        setupBanking: {
                            isActive:
                                result.setupBanking.since_date &&
                                (!result.setupBanking.client_delivery_date ||
                                    moment(result.setupBanking.client_delivery_date).isAfter(moment())),
                            wasActive: result.setupBanking.client_delivery_date
                                ? moment(result.setupBanking.client_delivery_date).isSameOrBefore(moment())
                                : false,
                        },
                        setupTaxes: {
                            isActive:
                                result.setupTaxes.since_date &&
                                (!result.setupTaxes.client_delivery_date ||
                                    moment(result.setupTaxes.client_delivery_date).isAfter(moment())),
                            wasActive: result.setupTaxes.client_delivery_date
                                ? moment(result.setupTaxes.client_delivery_date).isSameOrBefore(moment())
                                : false,
                        },
                        setupReport: {
                            isActive:
                                result.setupReport.since_date &&
                                (!result.setupReport.client_delivery_date ||
                                    moment(result.setupReport.client_delivery_date).isAfter(moment())),
                            wasActive: result.setupReport.client_delivery_date
                                ? moment(result.setupReport.client_delivery_date).isSameOrBefore(moment())
                                : false,
                        },
                        setupOther: {
                            isActive:
                                result.setupOther.since_date &&
                                (!result.setupOther.client_delivery_date ||
                                    moment(result.setupOther.client_delivery_date).isAfter(moment())),
                            wasActive: result.setupOther.client_delivery_date
                                ? moment(result.setupOther.client_delivery_date).isSameOrBefore(moment())
                                : false,
                        },
                        setupConsult: {
                            isActive: false,
                            wasActive: lastProyects.consulting.since_date ? true : false,
                        },
                    });
                });
        }

        setBackDrop(false);
    };

    /* Component functions */
    useEffect(() => {
        getProjects(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /* Component functions */
    useEffect(() => {
        if (lastChipSelected) {
            handleClickChip(valuesSetup[lastChipSelected], lastChipSelected, false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [renderState]);

    const handleClickChip = async (value, option, setChip = false) => {
        switch (option) {
            case "setupXero":
                setValuesSetup({
                    ...valuesSetup,
                    setupXero: setChip ? !value : true,
                    setupBanking: false,
                    setupTaxes: false,
                    setupReport: false,
                    setupOther: false,
                    setupConsult: false,
                });
                break;
            case "setupBanking":
                setValuesSetup({
                    ...valuesSetup,
                    setupXero: false,
                    setupBanking: setChip ? !value : true,
                    setupTaxes: false,
                    setupReport: false,
                    setupOther: false,
                    setupConsult: false,
                });
                break;
            case "setupTaxes":
                setValuesSetup({
                    ...valuesSetup,
                    setupXero: false,
                    setupBanking: false,
                    setupTaxes: setChip ? !value : true,
                    setupReport: false,
                    setupOther: false,
                    setupConsult: false,
                });
                break;
            case "setupReport":
                setValuesSetup({
                    ...valuesSetup,
                    setupXero: false,
                    setupBanking: false,
                    setupTaxes: false,
                    setupReport: setChip ? !value : true,
                    setupOther: false,
                    setupConsult: false,
                });
                break;
            case "setupOther":
                setValuesSetup({
                    ...valuesSetup,
                    setupXero: false,
                    setupBanking: false,
                    setupTaxes: false,
                    setupReport: false,
                    setupOther: setChip ? !value : true,
                    setupConsult: false,
                });
                break;
            default:
                setValuesSetup({
                    ...valuesSetup,
                    setupXero: false,
                    setupBanking: false,
                    setupTaxes: false,
                    setupReport: false,
                    setupOther: false,
                    setupConsult: setChip ? !value : true,
                });
                break;
        }
        if (setChip) {
            setLastChipSelected(option);
        }
    };

    return (
        <StyledContainer>
            <Divider className={classes.divider} />
            <Grid container justifyContent="space-around" alignItems="center" spacing={2} sx={{ pb: 2 }}>
                <Grid item>
                    <Chip
                        id="xero-chip"
                        label={
                            <Typography variant="h2" align="center" sx={{ fontWeight: 700 }}>
                                Xero
                            </Typography>
                        }
                        className={
                            valuesSetup.setupXero
                                ? active.setupXero.isActive
                                    ? classes.selectedChipActive
                                    : active.setupXero.wasActive
                                    ? classes.selectedChipWasActive
                                    : classes.selectedChip
                                : active.setupXero.isActive
                                ? classes.chipProActive
                                : active.setupXero.wasActive
                                ? classes.chipProWasActive
                                : classes.chip
                        }
                        onClick={() => handleClickChip(valuesSetup.setupXero, "setupXero", true)}
                    />
                </Grid>
                <Grid item>
                    <Chip
                        id="banking-chip"
                        label={
                            <Typography variant="h2" align="center" sx={{ fontWeight: 700 }}>
                                Kiiper Banking
                            </Typography>
                        }
                        className={
                            valuesSetup.setupBanking
                                ? active.setupBanking.isActive
                                    ? classes.selectedChipActive
                                    : active.setupBanking.wasActive
                                    ? classes.selectedChipWasActive
                                    : classes.selectedChip
                                : active.setupBanking.isActive
                                ? classes.chipProActive
                                : active.setupBanking.wasActive
                                ? classes.chipProWasActive
                                : classes.chip
                        }
                        onClick={() => handleClickChip(valuesSetup.setupBanking, "setupBanking", true)}
                    />
                </Grid>
                {(clientMarket === "Venezuela" || clientMarket === "LATAM") && (
                    <Grid item>
                        <Chip
                            id="taxes-chip"
                            label={
                                <Typography variant="h2" align="center" sx={{ fontWeight: 700 }}>
                                    Kiiper Taxes
                                </Typography>
                            }
                            className={
                                valuesSetup.setupTaxes
                                    ? active.setupTaxes.isActive
                                        ? classes.selectedChipActive
                                        : active.setupTaxes.wasActive
                                        ? classes.selectedChipWasActive
                                        : classes.selectedChip
                                    : active.setupTaxes.isActive
                                    ? classes.chipProActive
                                    : active.setupTaxes.wasActive
                                    ? classes.chipProWasActive
                                    : classes.chip
                            }
                            onClick={() => handleClickChip(valuesSetup.setupTaxes, "setupTaxes", true)}
                        />
                    </Grid>
                )}
                <Grid item>
                    <Chip
                        id="report-chip"
                        label={
                            <Typography variant="h2" align="center" sx={{ fontWeight: 700 }}>
                                Kiiper Reporting
                            </Typography>
                        }
                        className={
                            valuesSetup.setupReport
                                ? active.setupReport.isActive
                                    ? classes.selectedChipActive
                                    : active.setupReport.wasActive
                                    ? classes.selectedChipWasActive
                                    : classes.selectedChip
                                : active.setupReport.isActive
                                ? classes.chipProActive
                                : active.setupReport.wasActive
                                ? classes.chipProWasActive
                                : classes.chip
                        }
                        onClick={() => handleClickChip(valuesSetup.setupReport, "setupReport", true)}
                    />
                </Grid>
                <Grid item>
                    <Chip
                        id="other-chip"
                        label={
                            <Typography variant="h2" align="center" sx={{ fontWeight: 700 }}>
                                {t("services.other")}
                            </Typography>
                        }
                        className={
                            valuesSetup.setupOther
                                ? active.setupOther.isActive
                                    ? classes.selectedChipActive
                                    : active.setupOther.wasActive
                                    ? classes.selectedChipWasActive
                                    : classes.selectedChip
                                : active.setupOther.isActive
                                ? classes.chipProActive
                                : active.setupOther.wasActive
                                ? classes.chipProWasActive
                                : classes.chip
                        }
                        onClick={() => handleClickChip(valuesSetup.setupOther, "setupOther", true)}
                    />
                </Grid>
                <Grid item>
                    <Chip
                        id="consult-chip"
                        label={
                            <Typography variant="h2" align="center" sx={{ fontWeight: 700 }}>
                                Consulting
                            </Typography>
                        }
                        className={
                            valuesSetup.setupConsult
                                ? active.setupConsult.isActive
                                    ? classes.selectedChipActive
                                    : active.setupConsult.wasActive
                                    ? classes.selectedChipWasActive
                                    : classes.selectedChip
                                : active.setupConsult.isActive
                                ? classes.chipProActive
                                : active.setupConsult.wasActive
                                ? classes.chipProWasActive
                                : classes.chip
                        }
                        onClick={() => handleClickChip(valuesSetup.setupConsult, "setupConsult", true)}
                    />
                </Grid>
            </Grid>
            {valuesSetup.setupXero && (
                <Xero
                    setTexto={setTexto}
                    setalertType={setalertType}
                    setShow={setShow}
                    companyID={companyID}
                    reloadProjects={getProjects}
                    setBackDrop={setBackDrop}
                    data={projectsData.setupXero}
                    serviceID={serviceData.service_id}
                />
            )}
            {valuesSetup.setupBanking && (
                <KiiperBanking
                    setTexto={setTexto}
                    setalertType={setalertType}
                    setShow={setShow}
                    companyID={companyID}
                    reloadProjects={getProjects}
                    setBackDrop={setBackDrop}
                    data={projectsData.setupBanking}
                    serviceID={serviceData.service_id}
                />
            )}
            {valuesSetup.setupTaxes && (
                <KiiperTaxes
                    setTexto={setTexto}
                    setalertType={setalertType}
                    setShow={setShow}
                    companyID={companyID}
                    reloadProjects={getProjects}
                    setBackDrop={setBackDrop}
                    data={projectsData.setupTaxes}
                    clientMarket={clientMarket}
                    serviceID={serviceData.service_id}
                />
            )}
            {valuesSetup.setupReport && (
                <KiiperReporting
                    setTexto={setTexto}
                    setalertType={setalertType}
                    setShow={setShow}
                    companyID={companyID}
                    reloadProjects={getProjects}
                    setBackDrop={setBackDrop}
                    data={projectsData.setupReport}
                    serviceID={serviceData.service_id}
                />
            )}
            {valuesSetup.setupOther && (
                <Other
                    setTexto={setTexto}
                    setalertType={setalertType}
                    setShow={setShow}
                    companyID={companyID}
                    reloadProjects={getProjects}
                    setBackDrop={setBackDrop}
                    data={projectsData.setupOther}
                    serviceID={serviceData.service_id}
                />
            )}
            {valuesSetup.setupConsult && (
                <Consulting
                    setTexto={setTexto}
                    setalertType={setalertType}
                    setShow={setShow}
                    companyID={companyID}
                    reloadProjects={getProjects}
                    setBackDrop={setBackDrop}
                    data={projectsData.setupConsult}
                    serviceID={serviceData.service_id}
                />
            )}
        </StyledContainer>
    );
};
