// React
import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { NumericFormat } from "react-number-format";

// MUI
import {
    Box,
    TextField,
    Grid,
    Divider,
    Card,
    CardHeader,
    CardContent,
    FormControlLabel,
    Checkbox,
    FormControl,
    InputLabel,
    InputAdornment,
    OutlinedInput,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const PREFIX = "UpdateAdd";

const classes = {
    btnRoot: `${PREFIX}-btnRoot`,
    buttonContainer: `${PREFIX}-buttonContainer`,
    switch: `${PREFIX}-switch`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.btnRoot}`]: {
        border: "1px solid #ff5968",
        borderRadius: 40,
        "&:hover": {
            backgroundColor: "#2f3190",
            border: "1px solid #2f3190",
        },
        "&.Mui-disabled": {
            color: "#fff",
            borderColor: "#ffcdd2",
            background: "#ffcdd2",
        },
    },
    [`& .${classes.buttonContainer}`]: {
        display: "flex",
        justifyContent: "flex-end",
        padding: 15,
    },
    [`& .${classes.switch}`]: {
        "& .MuiSwitch-switchBase .MuiSwitch-thumb": {
            color: "#FFF",
        },
        "& .MuiSwitch-switchBase + .MuiSwitch-track": {
            backgroundColor: "#031851",
        },
        "& .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb": {
            color: "#FF5968",
        },
        "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
            backgroundColor: "#FFA8B1",
        },
    },
}));

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "rgba(19, 31, 62, 0.80)",
        fontSize: 12,
        maxWidth: 320,
        borderRadius: 6,
        fontWeight: "normal",
    },
});

const NumberFormatCustom = (props) => {
    const todos = useSelector((state) => state.todos);

    const { inputRef, value, onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            value={value}
            getInputRef={inputRef}
            onValueChange={({ value: v }) => onChange({ target: { value: v } })}
            thousandSeparator={todos.amountFormat === "de-DE" ? "." : ","}
            decimalSeparator={todos.amountFormat === "de-DE" ? "," : "."}
            fixedDecimalScale={true}
            decimalScale={2}
            valueIsNumericString
        />
    );
};

export const UpdateAdd = (props) => {
    // Properties
    const { values, set, setTexto, setalertType, setShow, sumTotal, isError, isErrorFocus, setIsErrorFocus } = props;
    
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    /** React redux */
    const todos = useSelector((state) => state.todos);

    /** Component functions */
    const handleChange = (event) => {
        set({
            ...values,
            [event.target.name]: event.target.value,
        });
        setIsErrorFocus(false)
    };

    const handleChangeHoursCont = (event) => {
        let accMonths = 0;
        if (values.additionalsOnly) {
            if (values.fixedHours) {
                accMonths = 1
            } else {
                accMonths = isNaN(moment(values.finishDateCont).diff(moment(values.startDateCont)))
                    ? 0
                    : Math.ceil(moment(values.finishDateCont).diff(moment(values.startDateCont), "months", true));
            }
        }

        set({
            ...values,
            [event.target.name]: event.target.value,
            projectTotal: values.hourRate * event.target.value * accMonths * (1 - values.discount / 100),
        });
        setIsErrorFocus(false)
    };

    const handleChangeCheck = (event, value) => {
        switch (value) {
            case 0:
                set({
                    ...values,
                    checkedExe: event.target.checked,
                    tothExe: 0,
                    startDateExe: event.target.checked ? values.sinceDate : null,
                    finishDateExe: event.target.checked ? values.untilDate : null,
                });
                break;
            case 1:
                set({
                    ...values,
                    checkedCons: event.target.checked,
                    tothCons: 0,
                    startDateCons: event.target.checked ? values.sinceDate : null,
                    finishDateCons: event.target.checked ? values.untilDate : null,
                });
                break;
            case 4:
                set({
                    ...values,
                    checkedRep: event.target.checked,
                    tothRep: 0,
                    startDateRep: event.target.checked ? values.sinceDate : null,
                    finishDateRep: event.target.checked ? values.untilDate : null,
                });
                break;
            case 10:
                set({
                    ...values,
                    checkedOthers: event.target.checked,
                    nameOthers: "",
                    tothOthers: 0,
                    startDateOthers: event.target.checked ? values.sinceDate : null,
                    finishDateOthers: event.target.checked ? values.untilDate : null,
                });
                break;
            case 11:
                let meses = Math.ceil(values.untilDate.diff(values.sinceDate, "months", true));
                set({
                    ...values,
                    checkedCont: event.target.checked,
                    tothCont: 0,
                    startDateCont: event.target.checked ? values.sinceDate : null,
                    finishDateCont: event.target.checked ? values.untilDate : null,
                    projectTotal: 0,
                    months: event.target.checked ? meses : 1,
                });
                break;
            default:
                break;
        }
        setIsErrorFocus(false)
    };

    const handleChangeStartDateAdd = (date, val) => {
        let startDate = moment(date).startOf("month");
        switch (val) {
            case 0:
                if (!date || !values.finishDateExe) {
                    set({
                        ...values,
                        startDateExe: date ? startDate : date,
                    });
                } else {
                    if (startDate.isAfter(moment(values.finishDateExe))) {
                        setTexto(t("reports.initDateMess"));
                        setalertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            startDateExe: startDate,
                        });
                    }
                }
                break;
            case 1:
                if (!date || !values.finishDateCons) {
                    set({
                        ...values,
                        startDateCons: date ? startDate : date,
                    });
                } else {
                    if (startDate.isAfter(moment(values.finishDateCons))) {
                        setTexto(t("reports.initDateMess"));
                        setalertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            startDateCons: startDate,
                        });
                    }
                }
                break;
            case 4:
                if (!date || !values.finishDateRep) {
                    set({
                        ...values,
                        startDateRep: date ? startDate : date,
                    });
                } else {
                    if (startDate.isAfter(moment(values.finishDateRep))) {
                        setTexto(t("reports.initDateMess"));
                        setalertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            startDateRep: startDate,
                        });
                    }
                }
                break;
            case 10:
                if (!date || !values.finishDateOthers) {
                    set({
                        ...values,
                        startDateOthers: date ? startDate : date,
                    });
                } else {
                    if (startDate.isAfter(moment(values.finishDateOthers))) {
                        setTexto(t("reports.initDateMess"));
                        setalertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            startDateOthers: startDate,
                        });
                    }
                }
                break;
            case 11:
                let accMonths = 0;
                if (values.additionalsOnly) {
                    if (values.fixedHours) {
                        accMonths = 1
                    } else {
                        accMonths = isNaN(moment(values.finishDateCont).diff(startDate))
                        ? 0
                        : Math.ceil(moment(values.finishDateCont).diff(startDate, "months", true));
                    }
                }

                if (!date || !values.finishDateCont) {
                    set({
                        ...values,
                        startDateCont: date ? startDate : date,
                        projectTotal:
                            values.hourRate * parseFloat(values.tothCont) * accMonths * (1 - values.discount / 100),
                        months: values.additionalsOnly ? accMonths : values.months,
                    });
                } else {
                    if (startDate.isAfter(moment(values.finishDateCont))) {
                        setTexto(t("reports.initDateMess"));
                        setalertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            startDateCont: startDate,
                            projectTotal:
                                values.hourRate * parseFloat(values.tothCont) * accMonths * (1 - values.discount / 100),
                            months: values.additionalsOnly ? accMonths : values.months,
                        });
                    }
                }
                break;
            default:
                break;
        }
        setIsErrorFocus(false)
    };

    const handleChangeEndDateAdd = (date, val) => {
        let endDate = moment(date).endOf("month").startOf("day");
        switch (val) {
            case 0:
                if (!date || !values.startDateExe) {
                    set({
                        ...values,
                        finishDateExe: date ? endDate : date,
                    });
                } else {
                    if (endDate.isBefore(moment(values.startDateExe))) {
                        setTexto(t("reports.endDateMess"));
                        setalertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            finishDateExe: endDate,
                        });
                    }
                }
                break;
            case 1:
                if (!date || !values.startDateCons) {
                    set({
                        ...values,
                        finishDateCons: date ? endDate : date,
                    });
                } else {
                    if (endDate.isBefore(moment(values.startDateCons))) {
                        setTexto(t("reports.endDateMess"));
                        setalertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            finishDateCons: endDate,
                        });
                    }
                }
                break;
            case 4:
                if (!date || !values.startDateRep) {
                    set({
                        ...values,
                        finishDateRep: date ? endDate : date,
                    });
                } else {
                    if (endDate.isBefore(moment(values.startDateRep))) {
                        setTexto(t("reports.endDateMess"));
                        setalertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            finishDateRep: endDate,
                        });
                    }
                }
                break;
            case 10:
                if (!date || !values.startDateOthers) {
                    set({
                        ...values,
                        finishDateOthers: date ? endDate : date,
                    });
                } else {
                    if (endDate.isBefore(moment(values.startDateOthers))) {
                        setTexto(t("reports.endDateMess"));
                        setalertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            finishDateOthers: endDate,
                        });
                    }
                }
                break;
            case 11:
                let accMonths = 0;
                if (values.additionalsOnly) {
                    if (values.fixedHours) {
                        accMonths = 1
                    } else {
                        accMonths = isNaN(endDate.diff(moment(values.startDateCont)))
                            ? 0
                            : Math.ceil(endDate.diff(moment(values.startDateCont), "months", true));
                    }
                }

                if (!date || !values.startDateCont) {
                    set({
                        ...values,
                        finishDateCont: date ? endDate : date,
                        projectTotal:
                            values.hourRate * parseFloat(values.tothCont) * accMonths * (1 - values.discount / 100),
                        months: values.additionalsOnly ? accMonths : values.months,
                    });
                } else {
                    if (endDate.isBefore(moment(values.startDateCont))) {
                        setTexto(t("reports.endDateMess"));
                        setalertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            finishDateCont: endDate,
                            projectTotal:
                                values.hourRate * parseFloat(values.tothCont) * accMonths * (1 - values.discount / 100),
                            months: values.additionalsOnly ? accMonths : values.months,
                        });
                    }
                }
                break;
            default:
                break;
        }
        setIsErrorFocus(false)
    };

    return (
        <StyledContainer>
            <LocalizationProvider
                dateAdapter={AdapterMoment}
                adapterLocale={t("language.locale")}
                localeText={{ clearButtonLabel: t("miscellaneous.clear") }}
            >
                <Card elevation={0} sx={{ backgroundColor: "inherit" }}>
                    <CardHeader title={t("proposals.additionals")} />
                    <Divider />
                    <CardContent sx={{ pb: 5 }}>
                        <Grid container spacing={2} alignItems="center">
                            {values.additionalsOnly && (
                                <>
                                    <Grid item xs={12} md={3}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    name="checkedCont"
                                                    checked={values.checkedCont}
                                                    onChange={() => {
                                                        return false;
                                                    }}
                                                    inputProps={{
                                                        "aria-label": "controlled",
                                                    }}
                                                />
                                            }
                                            label="Controller"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            fullWidth
                                            disabled={!values.checkedCont || !values.sinceDate}
                                            label={values.fixedHours ? t("miscellaneous.hours") : t("miscellaneous.hoursMonth")}
                                            name="tothCont"
                                            onChange={handleChangeHoursCont}
                                            value={values.tothCont}
                                            variant="outlined"
                                            type="number"
                                            inputProps={{
                                                min: 0,
                                                step: 0.5,
                                                onKeyDown: (event) => {
                                                    event.preventDefault();
                                                },
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            error={values.checkedCont && values.tothCont === 0 && isError}                    
                                            onClick={(e) =>{setIsErrorFocus(false)}}
                                            inputRef={(input) => { (input &&isErrorFocus &&values.checkedCont && values.tothCont === 0 && isError) && input.focus()}}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <DatePicker
                                            componentsProps={{
                                                actionBar: {
                                                    actions: ["clear"],
                                                },
                                            }}
                                            disabled={!values.checkedCont || !values.sinceDate}
                                            value={values.startDateCont}
                                            minDate={moment(values.sinceDate).startOf("month")}
                                            maxDate={moment(values.untilDate).endOf("month")}
                                            onChange={(newValue) => handleChangeStartDateAdd(newValue, 11)}
                                            inputFormat={todos.dateFormat}
                                            renderInput={(params) => (
                                                <TextField
                                                    fullWidth
                                                    required={values.checkedCont}
                                                    {...params}
                                                    error={values.checkedCont && !values.startDateCont}
                                                    onClick={(e) =>{setIsErrorFocus(false)}}
                                                />
                                            )}
                                            // inputProps={{ readOnly: true }}
                                            label={t("reports.initDate")}
                                            views={["year", "month"]}
                                            inputProps={{
                                                onKeyDown: (event) => {
                                                    event.preventDefault();
                                                },
                                            }}
                                            onClick={(e) =>{setIsErrorFocus(false)}}
                                            inputRef={(input) => { (input &&isErrorFocus &&values.checkedCont && !values.startDateCont) && input.focus()}}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <DatePicker
                                            componentsProps={{
                                                actionBar: {
                                                    actions: ["clear"],
                                                },
                                            }}
                                            disabled={!values.checkedCont || !values.sinceDate}
                                            value={values.finishDateCont}
                                            minDate={moment(values.sinceDate).startOf("month")}
                                            maxDate={moment(values.untilDate).endOf("month")}
                                            onChange={(newValue) => handleChangeEndDateAdd(newValue, 11)}
                                            inputFormat={todos.dateFormat}
                                            renderInput={(params) => (
                                                <TextField
                                                    fullWidth
                                                    required={values.checkedCont}
                                                    {...params}
                                                    error={values.checkedCont && !values.finishDateCont}
                                                    onClick={(e) =>{setIsErrorFocus(false)}}
                                                />
                                            )}
                                            // inputProps={{ readOnly: true }}
                                            label={t("reports.endDate")}
                                            views={["year", "month"]}
                                            inputProps={{
                                                onKeyDown: (event) => {
                                                    event.preventDefault();
                                                },
                                            }}
                                            onClick={(e) =>{setIsErrorFocus(false)}}
                                            inputRef={(input) => { (input &&isErrorFocus &&values.checkedCont && !values.finishDateCont) && input.focus()}}
                                        />
                                    </Grid>
                                </>
                            )}
                            <Grid item xs={12} md={3}>
                                <LightTooltip title={t("services.executionTaxPlanDet")}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name="checkedExe"
                                                checked={values.checkedExe}
                                                onChange={(value) => handleChangeCheck(value, 0)}
                                                inputProps={{
                                                    "aria-label": "controlled",
                                                }}
                                                disabled={!values.sinceDate || !values.untilDate}
                                            />
                                        }
                                        label={t("services.executionTaxPlan")}
                                    />
                                </LightTooltip>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    fullWidth
                                    disabled={!values.checkedExe}
                                    label={values.fixedHours ? t("miscellaneous.hours") : t("miscellaneous.hoursMonth")}
                                    name="tothExe"
                                    onChange={handleChange}
                                    value={values.tothExe}
                                    variant="outlined"
                                    type="number"
                                    inputProps={{
                                        min: 0,
                                        step: 0.5,
                                        onKeyDown: (event) => {
                                            event.preventDefault();
                                        },
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    error={values.checkedExe && values.tothExe === 0 && isError}
                                    onClick={(e) =>{setIsErrorFocus(false)}}
                                    inputRef={(input) => { (input &&isErrorFocus &&values.checkedExe && values.tothExe === 0 && isError) && input.focus()}}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    disabled={!values.checkedExe}
                                    value={values.startDateExe}
                                    onChange={(newValue) => handleChangeStartDateAdd(newValue, 0)}
                                    minDate={moment(values.sinceDate).startOf("month")}
                                    maxDate={moment(values.untilDate).endOf("month")}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            required={values.checkedExe}
                                            {...params}
                                            error={values.checkedExe && !values.startDateExe}
                                            onClick={(e) =>{setIsErrorFocus(false)}}
                                        />
                                    )}
                                    // inputProps={{ readOnly: true }}
                                    label={t("reports.initDate")}
                                    views={["year", "month"]}
                                    inputProps={{
                                        onKeyDown: (event) => {
                                            event.preventDefault();
                                        },
                                    }}
                                    onClick={(e) =>{setIsErrorFocus(false)}}
                                    inputRef={(input) => { (input &&isErrorFocus &&values.checkedExe && !values.startDateExe) && input.focus()}}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    disabled={!values.checkedExe}
                                    value={values.finishDateExe}
                                    onChange={(newValue) => handleChangeEndDateAdd(newValue, 0)}
                                    minDate={moment(values.sinceDate).startOf("month")}
                                    maxDate={moment(values.untilDate).endOf("month")}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            required={values.checkedExe}
                                            {...params}
                                            error={values.checkedExe && !values.finishDateExe}
                                            onClick={(e) =>{setIsErrorFocus(false)}}
                                        />
                                    )}
                                    // inputProps={{ readOnly: true }}
                                    label={t("reports.endDate")}
                                    views={["year", "month"]}
                                    inputProps={{
                                        onKeyDown: (event) => {
                                            event.preventDefault();
                                        },
                                    }}
                                    onClick={(e) =>{setIsErrorFocus(false)}}
                                    inputRef={(input) => { (input &&isErrorFocus &&values.checkedExe && !values.finishDateExe) && input.focus()}}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <LightTooltip title={t("services.consolidationStatementsDet")}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name="checkedCons"
                                                checked={values.checkedCons}
                                                onChange={(value) => handleChangeCheck(value, 1)}
                                                inputProps={{
                                                    "aria-label": "controlled",
                                                }}
                                                disabled={!values.sinceDate || !values.untilDate}
                                            />
                                        }
                                        label={t("services.consolidationStatements")}
                                    />
                                </LightTooltip>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    fullWidth
                                    disabled={!values.checkedCons}
                                    label={values.fixedHours ? t("miscellaneous.hours") : t("miscellaneous.hoursMonth")}
                                    name="tothCons"
                                    onChange={handleChange}
                                    value={values.tothCons}
                                    variant="outlined"
                                    type="number"
                                    inputProps={{
                                        min: 0,
                                        step: 0.5,
                                        onKeyDown: (event) => {
                                            event.preventDefault();
                                        },
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    error={values.checkedCons && values.tothCons === 0 && isError}
                                    onClick={(e) =>{setIsErrorFocus(false)}}
                                    inputRef={(input) => { (input &&isErrorFocus &&values.checkedCons && values.tothCons === 0 && isError) && input.focus()}}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    disabled={!values.checkedCons}
                                    value={values.startDateCons}
                                    onChange={(newValue) => handleChangeStartDateAdd(newValue, 1)}
                                    minDate={moment(values.sinceDate).startOf("month")}
                                    maxDate={moment(values.untilDate).endOf("month")}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            required={values.checkedCons}
                                            {...params}
                                            error={values.checkedCons && !values.startDateCons}
                                            onClick={(e) =>{setIsErrorFocus(false)}}
                                        />
                                    )}
                                    // inputProps={{ readOnly: true }}
                                    label={t("reports.initDate")}
                                    views={["year", "month"]}
                                    inputProps={{
                                        onKeyDown: (event) => {
                                            event.preventDefault();
                                        },
                                    }}
                                    onClick={(e) =>{setIsErrorFocus(false)}}
                                    inputRef={(input) => { (input &&isErrorFocus &&values.checkedCons && !values.startDateCons) && input.focus()}}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    disabled={!values.checkedCons}
                                    value={values.finishDateCons}
                                    onChange={(newValue) => handleChangeEndDateAdd(newValue, 1)}
                                    minDate={moment(values.sinceDate).startOf("month")}
                                    maxDate={moment(values.untilDate).endOf("month")}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            required={values.checkedCons}
                                            {...params}
                                            error={values.checkedCons && !values.finishDateCons}
                                            onClick={(e) =>{setIsErrorFocus(false)}}
                                        />
                                    )}
                                    // inputProps={{ readOnly: true }}
                                    label={t("reports.endDate")}
                                    views={["year", "month"]}
                                    inputProps={{
                                        onKeyDown: (event) => {
                                            event.preventDefault();
                                        },
                                    }}
                                    onClick={(e) =>{setIsErrorFocus(false)}}
                                    inputRef={(input) => { (input &&isErrorFocus &&values.checkedCons && !values.finishDateCons) && input.focus()}}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <LightTooltip title={t("services.reportsAuditorsDet")}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name="checkedRep"
                                                checked={values.checkedRep}
                                                onChange={(value) => handleChangeCheck(value, 4)}
                                                inputProps={{
                                                    "aria-label": "controlled",
                                                }}
                                                disabled={!values.sinceDate || !values.untilDate}
                                            />
                                        }
                                        label={t("services.reportsAuditors")}
                                    />
                                </LightTooltip>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    fullWidth
                                    disabled={!values.checkedRep}
                                    label={values.fixedHours ? t("miscellaneous.hours") : t("miscellaneous.hoursMonth")}
                                    name="tothRep"
                                    onChange={handleChange}
                                    value={values.tothRep}
                                    variant="outlined"
                                    type="number"
                                    inputProps={{
                                        min: 0,
                                        step: 0.5,
                                        onKeyDown: (event) => {
                                            event.preventDefault();
                                        },
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    error={values.checkedRep && values.tothRep === 0 && isError}
                                    onClick={(e) =>{setIsErrorFocus(false)}}
                                    inputRef={(input) => { (input &&isErrorFocus &&values.checkedRep && values.tothRep === 0 && isError) && input.focus()}}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    disabled={!values.checkedRep}
                                    value={values.startDateRep}
                                    onChange={(newValue) => handleChangeStartDateAdd(newValue, 4)}
                                    minDate={moment(values.sinceDate).startOf("month")}
                                    maxDate={moment(values.untilDate).endOf("month")}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            required={values.checkedRep}
                                            {...params}
                                            error={values.checkedRep && !values.startDateRep}
                                            onClick={(e) =>{setIsErrorFocus(false)}}
                                        />
                                    )}
                                    // inputProps={{ readOnly: true }}
                                    label={t("reports.initDate")}
                                    views={["year", "month"]}
                                    inputProps={{
                                        onKeyDown: (event) => {
                                            event.preventDefault();
                                        },
                                    }}
                                    onClick={(e) =>{setIsErrorFocus(false)}}
                                    inputRef={(input) => { (input &&isErrorFocus &&values.checkedRep && !values.startDateRep) && input.focus()}}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    disabled={!values.checkedRep}
                                    value={values.finishDateRep}
                                    onChange={(newValue) => handleChangeEndDateAdd(newValue, 4)}
                                    minDate={moment(values.sinceDate).startOf("month")}
                                    maxDate={moment(values.untilDate).endOf("month")}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            required={values.checkedRep}
                                            {...params}
                                            error={values.checkedRep && !values.finishDateRep}
                                            onClick={(e) =>{setIsErrorFocus(false)}}
                                        />
                                    )}
                                    // inputProps={{ readOnly: true }}
                                    label={t("reports.endDate")}
                                    views={["year", "month"]}
                                    inputProps={{
                                        onKeyDown: (event) => {
                                            event.preventDefault();
                                        },
                                    }}
                                    onClick={(e) =>{setIsErrorFocus(false)}}
                                    inputRef={(input) => { (input &&isErrorFocus &&values.checkedRep && !values.finishDateRep) && input.focus()}}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={12} md={3} xl={2} sx={{ paddingLeft: "5px !important" }}>
                                        <Checkbox
                                            name="checkedOthers"
                                            checked={values.checkedOthers}
                                            onChange={(value) => handleChangeCheck(value, 10)}
                                            inputProps={{
                                                "aria-label": "controlled",
                                            }}
                                            disabled={!values.sinceDate}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={9} xl={10}>
                                        <TextField
                                            fullWidth
                                            disabled={!values.checkedOthers}
                                            label={t("services.others")}
                                            name="nameOthers"
                                            onChange={handleChange}
                                            value={values.nameOthers}
                                            variant="outlined"
                                            required={values.checkedOthers}
                                            error={values.checkedOthers && isError && values.nameOthers === ""}
                                            onClick={(e) =>{setIsErrorFocus(false)}}
                                            inputRef={(input) => { (input &&isErrorFocus &&values.checkedOthers && isError && values.nameOthers === "") && input.focus()}}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    fullWidth
                                    disabled={!values.checkedOthers}
                                    label={values.fixedHours ? t("miscellaneous.hours") : t("miscellaneous.hoursMonth")}
                                    name="tothOthers"
                                    onChange={handleChange}
                                    value={values.tothOthers}
                                    variant="outlined"
                                    type="number"
                                    inputProps={{
                                        min: 0,
                                        step: 0.5,
                                        onKeyDown: (event) => {
                                            event.preventDefault();
                                        },
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    error={values.checkedOthers && values.tothOthers === 0 && isError}
                                    onClick={(e) =>{setIsErrorFocus(false)}}
                                    inputRef={(input) => { (input &&isErrorFocus &&values.checkedOthers && values.tothOthers === 0 && isError) && input.focus()}}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    disabled={!values.checkedOthers}
                                    value={values.startDateOthers}
                                    onChange={(newValue) => handleChangeStartDateAdd(newValue, 10)}
                                    minDate={moment(values.sinceDate).startOf("month")}
                                    maxDate={moment(values.untilDate).endOf("month")}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            required={values.checkedOthers}
                                            {...params}
                                            error={values.checkedOthers && !values.startDateOthers}
                                            onClick={(e) =>{setIsErrorFocus(false)}}
                                        />
                                    )}
                                    // inputProps={{ readOnly: true }}
                                    label={t("reports.initDate")}
                                    views={["year", "month"]}
                                    inputProps={{
                                        onKeyDown: (event) => {
                                            event.preventDefault();
                                        },
                                    }}
                                    onClick={(e) =>{setIsErrorFocus(false)}}
                                    inputRef={(input) => { (input &&isErrorFocus &&values.checkedOthers && !values.startDateOthers) && input.focus()}}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    disabled={!values.checkedOthers}
                                    value={values.finishDateOthers}
                                    onChange={(newValue) => handleChangeEndDateAdd(newValue, 10)}
                                    minDate={moment(values.sinceDate).startOf("month")}
                                    maxDate={moment(values.untilDate).endOf("month")}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            required={values.checkedOthers}
                                            {...params}
                                            error={values.checkedOthers && !values.finishDateOthers}
                                            onClick={(e) =>{setIsErrorFocus(false)}}
                                        />
                                    )}
                                    // inputProps={{ readOnly: true }}
                                    label={t("reports.endDate")}
                                    views={["year", "month"]}
                                    inputProps={{
                                        onKeyDown: (event) => {
                                            event.preventDefault();
                                        },
                                    }}
                                    onClick={(e) =>{setIsErrorFocus(false)}}
                                    inputRef={(input) => { (input &&isErrorFocus &&values.checkedOthers && !values.finishDateOthers) && input.focus()}}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}></Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    fullWidth
                                    disabled
                                    label={t("services.hoursTotal")}
                                    value={sumTotal}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormControl fullWidth variant="outlined" disabled>
                                    <InputLabel htmlFor="outlined-adornment-hourlyRate">
                                        {t("services.hourlyRate")}
                                    </InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-hourlyRate"
                                        value={values.hourRate}
                                        inputComponent={NumberFormatCustom}
                                        startAdornment={
                                            <InputAdornment disableTypography position="start">
                                                $
                                            </InputAdornment>
                                        }
                                        label={t("services.hourlyRate")}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormControl fullWidth variant="outlined" disabled>
                                    <InputLabel htmlFor="outlined-adornment-hourlyRateAdd">
                                        {t("services.hourlyRateAdd")}
                                    </InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-hourlyRateAdd"
                                        value={values.hourRate * sumTotal}
                                        inputComponent={NumberFormatCustom}
                                        startAdornment={
                                            <InputAdornment disableTypography position="start">
                                                $
                                            </InputAdornment>
                                        }
                                        label={t("services.hourlyRateAdd")}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </LocalizationProvider>
        </StyledContainer>
    );
};
