// React
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { NumericFormat } from "react-number-format";

// MUI
import {
    Box,
    TextField,
    Grid,
    Divider,
    Card,
    CardHeader,
    CardContent,
    Button,
    FormControl,
    FormControlLabel,
    Checkbox,
    InputLabel,
    OutlinedInput,
    InputAdornment,
    Select,
    MenuItem,
    Switch,
    Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

/** MUI icons */
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const PREFIX = "KiiperBanking";

const classes = {
    btnRoot: `${PREFIX}-btnRoot`,
    buttonContainer: `${PREFIX}-buttonContainer`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.btnRoot}`]: {
        border: "1px solid #ff5968",
        borderRadius: 40,
        "&:hover": {
            backgroundColor: "#2f3190",
            border: "1px solid #2f3190",
        },
    },
    [`& .${classes.buttonContainer}`]: {
        display: "flex",
        justifyContent: "flex-end",
        padding: 15,
    },
}));

const NumberFormatCustom = (props) => {
    const todos = useSelector((state) => state.todos);

    const { inputRef, value, onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            value={value}
            getInputRef={inputRef}
            onValueChange={({ value: v }) => onChange({ target: { value: v } })}
            thousandSeparator={todos.amountFormat === "de-DE" ? "." : ","}
            decimalSeparator={todos.amountFormat === "de-DE" ? "," : "."}
            fixedDecimalScale={true}
            decimalScale={2}
            valueIsNumericString
        />
    );
};

const defaultState = {
    invoiceGroup: false,
    not_billable: false,
    fee: 0,
    discount: 0,
    totalFee: 0,
    version: "",
    sinceDate: null,
    finishDate: null,
    endProject: false,
};

export const KiiperBanking = (props) => {
    // Properties
    const { setTexto, setalertType, setShow, companyID, setBackDrop, data, reloadProjects, serviceID } = props;

    /** Internationalization i18n */
    const [t] = useTranslation("global");

    /** React redux */
    const todos = useSelector((state) => state.todos);

    /** Component states */
    const [banking, setBanking] = useState(defaultState);
    const [isError, setIsError] = useState(false);

    /** Component functions */
    useEffect(() => {
        let ogFee = 0;
        if (data.version === "Plan 5") {
            ogFee = 7.75;
        }
        if (data.version === "Plan 15") {
            ogFee = 19.75;
        }
        if (data.version === "Plan 30") {
            ogFee = 35.75;
        }
        if (data.version === "Plan 50") {
            ogFee = 55.75;
        }
        if (data.version === "Plan 200") {
            ogFee = 189.75;
        }
        if (data.version === "Plan 500") {
            ogFee = 425.75;
        }

        setBanking({
            invoiceGroup: data.grouped_support ?? false,
            fee: ogFee,
            discount: data.discount ?? 0,
            totalFee: ogFee * (1 - (data.discount ?? 0) / 100),
            version: data.version ?? "",
            sinceDate: data.since_date ? moment(data.since_date).utc() : null,
            finishDate: data.end_date ? moment(data.end_date).utc() : null,
            endProject: data.finished_service ?? false,
            not_billable: data.not_billable ?? false,
        });
    }, [data]);

    const handleChangeDiscount = (event) => {
        let discountAux = event.target.value >= 0 && event.target.value <= 100 ? event.target.value : banking.discount;

        setBanking({
            ...banking,
            discount: discountAux,
            totalFee: banking.fee * (1 - discountAux / 100),
        });
    };

    const handleChangeEndDate = (date) => {
        let endDate = moment(date).endOf("month").startOf("day");

        if (!date) {
            setBanking({
                ...banking,
                finishDate: date,
            });
        } else if (banking.sinceDate) {
            if (endDate.isBefore(moment(banking.sinceDate))) {
                setBanking({
                    ...banking,
                    finishDate: null,
                });
                setTexto(t("reports.endDateMess"));
                setalertType("warning");
                setShow(true);
            } else {
                setBanking({
                    ...banking,
                    finishDate: endDate,
                });
            }
        } else {
            setTexto(t("reports.selectStartDate"));
            setalertType("warning");
            setShow(true);
        }
    };

    const updateProject = async () => {
        setBackDrop(true);

        if (banking.sinceDate && banking.version) {
            if (banking.endProject && !banking.finishDate) {
                setTexto(t("miscellaneous.requiredData"));
                setalertType("warning");
                setShow(true);
                setIsError(true);
                setBackDrop(false);
                return;
            }

            let params = {
                company_id: companyID,
                service_id: serviceID,
                project_id: data.project_id,
                project_company_id: data.project_company_id,
                grouped_support: banking.invoiceGroup,
                since_date: moment(banking.sinceDate).startOf("day").format(),
                end_date: banking.finishDate ? moment(banking.finishDate).startOf("day").format() : null,
                discount: banking.discount ? parseFloat(banking.discount) : 0,
                fee: banking.totalFee,
                version: banking.version,
                finished_service: banking.endProject,
                not_billable: banking.not_billable,
            };

            await fetch(`/addServicesAndProjects`, {
                method: "POST",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
                body: JSON.stringify(params),
            })
                .then((response) => {
                    if (response.ok) {
                        setTexto(t("miscellaneous.successfulUpdate"));
                        setalertType("success");
                        setShow(true);
                        reloadProjects(true, banking.endProject);
                    } else {
                        setTexto(t("miscellaneous.updatingError"));
                        setalertType("error");
                        setShow(true);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            setTexto(t("miscellaneous.requiredData"));
            setalertType("warning");
            setShow(true);
            setIsError(true);
        }

        setBackDrop(false);
    };

    const handleChangeSinceDate = (date) => {
        setBanking({
            ...banking,
            sinceDate: date,
        });
    };

    const handleChangeVersion = (event) => {
        let newFee = 0;
        switch (event.target.value) {
            case "Plan 5":
                newFee = 7.75;
                break;
            case "Plan 15":
                newFee = 19.75;
                break;
            case "Plan 30":
                newFee = 35.75;
                break;
            case "Plan 50":
                newFee = 55.75;
                break;
            case "Plan 200":
                newFee = 189.75;
                break;
            case "Plan 500":
                newFee = 425.75;
                break;
            default:
                break;
        }

        setBanking({
            ...banking,
            version: event.target.value,
            fee: newFee,
            totalFee: newFee * (1 - (banking.discount ?? 0) / 100),
        });
    };

    const handleChangeCheck = async (event) => {
        if (event.target.checked === true) {
            await fetch(`/getOriginalPracticeDetails?organisation_id=${companyID}&project=banking`, {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
                .then((res) => res.json())
                .then((response) => {
                    let ogFee = 0;
                    if (response.banking_kiiper_version && response.banking_kiiper_version === "Plan 5") {
                        ogFee = 7.75;
                    }
                    if (response.banking_kiiper_version && response.banking_kiiper_version === "Plan 15") {
                        ogFee = 19.75;
                    }
                    if (response.banking_kiiper_version && response.banking_kiiper_version === "Plan 30") {
                        ogFee = 35.75;
                    }
                    if (response.banking_kiiper_version && response.banking_kiiper_version === "Plan 50") {
                        ogFee = 55.75;
                    }
                    if (response.banking_kiiper_version && response.banking_kiiper_version === "Plan 200") {
                        ogFee = 189.75;
                    }
                    if (response.banking_kiiper_version && response.banking_kiiper_version === "Plan 500") {
                        ogFee = 425.75;
                    }

                    setBanking({
                        ...banking,
                        invoiceGroup: !banking.invoiceGroup,
                        fee: ogFee,
                        discount: response.banking_kiiper_discount ? response.banking_kiiper_discount : 0,
                        version: response.banking_kiiper_version ? response.banking_kiiper_version : "",
                        sinceDate: response.initial_date_banking ? moment(response.initial_date_banking).utc() : null,
                        finishDate:
                            response.end_date_banking && !data.end_date
                                ? moment(response.end_date_banking).utc()
                                : null,
                        totalFee:
                            ogFee *
                            (1 - (response.banking_kiiper_discount ? response.banking_kiiper_discount : 0) / 100),
                    });
                });
        } else {
            let ogFee = 0;
            if (data.version === "Plan 5") {
                ogFee = 7.75;
            }
            if (data.version === "Plan 15") {
                ogFee = 19.75;
            }
            if (data.version === "Plan 30") {
                ogFee = 35.75;
            }
            if (data.version === "Plan 50") {
                ogFee = 55.75;
            }
            if (data.version === "Plan 200") {
                ogFee = 189.75;
            }
            if (data.version === "Plan 500") {
                ogFee = 425.75;
            }

            setBanking({
                ...banking,
                invoiceGroup: !banking.invoiceGroup,
                fee: ogFee,
                discount: data.discount ?? 0,
                totalFee: ogFee * (1 - (data.discount ? data.discount : 0) / 100),
                version: data.version ?? "",
                sinceDate: data.since_date ? moment(data.since_date).utc() : null,
                finishDate: data.end_date ? moment(data.end_date).utc() : null,
            });
        }
    };

    const handleChangeSwitch = (event) => {
        setBanking({
            ...banking,
            endProject: event.target.checked,
            finishDate: null,
        });
    };

    return (
        <StyledContainer>
            <LocalizationProvider
                dateAdapter={AdapterMoment}
                adapterLocale={t("language.locale")}
                localeText={{ clearButtonLabel: t("miscellaneous.clear") }}
            >
                <Card elevation={0}>
                    <CardHeader
                        title="Kiiper Banking subscription"
                        action={
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={banking.endProject}
                                        inputProps={{
                                            "aria-label": "controlled",
                                        }}
                                        color="secondary"
                                        onChange={handleChangeSwitch}
                                        className={classes.switch}
                                    />
                                }
                                label={
                                    <Typography variant="body1" color="textPrimary">
                                        {t("services.finishProject")}
                                    </Typography>
                                }
                            />
                        }
                    />
                    <Divider />
                    <CardContent>
                        <Grid container alignItems="flex-start" spacing={2}>
                            <Grid item xs={12} sm={6} lg={3}>
                                <FormControl fullWidth required error={isError && banking.version === ""}>
                                    <InputLabel id="ver-simple-select-label">{t("services.version")}</InputLabel>
                                    <Select
                                        name="version"
                                        labelId="ver-simple-select-label"
                                        IconComponent={KeyboardArrowDownIcon}
                                        value={banking.version}
                                        label={t("services.version")}
                                        onChange={handleChangeVersion}
                                        disabled={banking.invoiceGroup}
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    mt: 0.7,
                                                    borderRadius: 2,
                                                    boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                },
                                            },
                                        }}
                                    >
                                        <MenuItem value={"Plan 5"}>Plan 5</MenuItem>
                                        <MenuItem value={"Plan 15"}>Plan 15</MenuItem>
                                        <MenuItem value={"Plan 30"}>Plan 30</MenuItem>
                                        <MenuItem value={"Plan 50"}>Plan 50</MenuItem>
                                        <MenuItem value={"Plan 200"}>Plan 200</MenuItem>
                                        <MenuItem value={"Plan 500"}>Plan 500</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={3}>
                                <FormControl fullWidth variant="outlined" disabled>
                                    <InputLabel htmlFor="outlined-adornment-fee">{t("services.projectFee")}</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-fee"
                                        value={banking.fee}
                                        inputComponent={NumberFormatCustom}
                                        startAdornment={
                                            <InputAdornment disableTypography position="start">
                                                $
                                            </InputAdornment>
                                        }
                                        label={t("services.projectFee")}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={3}>
                                <TextField
                                    fullWidth
                                    label={t("miscellaneous.discount")}
                                    value={banking.discount}
                                    name="discount"
                                    onChange={handleChangeDiscount}
                                    disabled={banking.invoiceGroup}
                                    onBlur={() =>
                                        setBanking({
                                            ...banking,
                                            discount: parseFloat(
                                                banking.discount === "" ? 0 : banking.discount
                                            ).toFixed(2),
                                        })
                                    }
                                    variant="outlined"
                                    type="number"
                                    InputProps={{
                                        inputProps: {
                                            min: 0,
                                            max: 100,
                                        },
                                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                    }}
                                    sx={{
                                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                            display: "none",
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} lg={3}>
                                <FormControl fullWidth variant="outlined" disabled>
                                    <InputLabel htmlFor="outlined-adornment-totalFee">
                                        {t("proposals.feeTotal")}
                                    </InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-totalFee"
                                        value={banking.totalFee}
                                        inputComponent={NumberFormatCustom}
                                        startAdornment={
                                            <InputAdornment disableTypography position="start">
                                                $
                                            </InputAdornment>
                                        }
                                        label={t("proposals.feeTotal")}
                                    />
                                </FormControl>
                            </Grid>
                            {data.banking_group && (
                                <Grid item lg={3} sm={6} xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name="invoiceGroup"
                                                onChange={handleChangeCheck}
                                                checked={banking.invoiceGroup}
                                                inputProps={{ "aria-label": "controlled" }}
                                            />
                                        }
                                        label={t("services.invGroup")}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </CardContent>
                </Card>
                <Card elevation={0}>
                    <CardHeader title={t("services.serviceResponsibility")} sx={{ color: "#ff5968" }} />
                    <Divider />
                    <CardContent>
                        <Grid container alignItems="center" spacing={2}>
                            <Grid item xs={12} sm={6} lg={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    value={banking.sinceDate}
                                    onChange={(newValue) => handleChangeSinceDate(newValue)}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            required
                                            {...params}
                                            error={isError && banking.sinceDate === null ? true : false}
                                        />
                                    )}
                                    inputProps={{ readOnly: true }}
                                    label={t("reports.initDate")}
                                    views={["year", "month", "day"]}
                                />
                            </Grid>
                            {banking.endProject && (
                                <Grid item lg={3} sm={6} xs={12}>
                                    <DatePicker
                                        componentsProps={{
                                            actionBar: { actions: ["clear"] },
                                        }}
                                        value={banking.finishDate}
                                        onChange={(newValue) => handleChangeEndDate(newValue)}
                                        inputFormat={todos.dateFormat}
                                        renderInput={(params) => (
                                            <TextField
                                                fullWidth
                                                required
                                                {...params}
                                                error={isError && !banking.finishDate}
                                            />
                                        )}
                                        views={["year", "month"]}
                                        inputProps={{ readOnly: true }}
                                        label={t("reports.endDate")}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </CardContent>
                    <Divider />
                    <Box className={classes.buttonContainer}>
                        <Button
                            color="secondary"
                            variant="contained"
                            disableElevation
                            classes={{ root: classes.btnRoot }}
                            onClick={updateProject}
                        >
                            {t("miscellaneous.save")}
                        </Button>
                    </Box>
                </Card>
            </LocalizationProvider>
        </StyledContainer>
    );
};
