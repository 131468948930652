/** React imports */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import moment from "moment";
import PropTypes from "prop-types";

/** Icons/Images */
import { ReactComponent as AccountCircle } from "../../../assets/images/avatar-image.svg";
import { ReactComponent as CreditCardIcon } from "../../../assets/images/credit_card.svg";

/** MUI icons */
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import InsertChartOutlinedIcon from "@mui/icons-material/InsertChartOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import LockIcon from "@mui/icons-material/Lock";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

/** MUI imports */
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Step,
    StepButton,
    StepLabel,
    Stepper,
    Switch,
    Tab,
    Tabs,
    TextField,
    Typography,
} from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

/** Components imports */
import Countries from "../../Js/countries";
import DialogAlerts from "../DialogAlerts";
import EditGroup from "./EditGroup";
import MainCountries from "../../Js/mainCountries";
import Staff from "../../Js/staff";
import DialogAlertsCustom from "./DialogAlertsCustom";

/** Component styles */
const PREFIX = "NewFraq";

const classes = {
    avatarImage: `${PREFIX}-avatarImage`,
};

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 48 * 4.5 + 8,
            boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
        },
        sx: {
            borderRadius: 2,
            mt: 0.7,
        },
    },
};

const CustomDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(0),
    },
    "& .MuiDialogTitle-root": {
        backgroundColor: "#031851",
        fontSize: "18px",
        color: "#fff",
    },
    "& .MuiDialog-paper": {
        borderRadius: 8,
        boxShadow: "10px 10px 15px rgba(3,24,81,0.15)",
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(2),
    },

    [`& .${classes.avatarImage}`]: {
        display: "block",
        width: 65,
        height: 65,
        marginLeft: "auto",
        marginRight: "auto",
    },
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            style={{ width: "100%" }}
            {...other}
        >
            {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
        </div>
    );
}

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "rgba(19, 31, 62, 0.80)",
        fontSize: 12,
        maxWidth: 320,
        borderRadius: 6,
        fontWeight: "normal",
    },
});

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        "aria-controls": `vertical-tabpanel-${index}`,
    };
}

const ColorButton = styled(Button)(({ theme }) => ({
    border: "1px solid #ff5968",
    borderRadius: 40,
}));

const teamCompanyDefault = {
    contact_name: "",
    contact_email: "",
    contact_role: "",
    contact_closure: false,
    contact_status: false,
    contact_requirements: false,
    contact_invoices: false,
};

const defaultSettings = {
    anticipatedInvoice: true,
    groupedSupport: false,
    groupedSupportHours: 1,
    groupedSupportDiscount: 0,
    custSuppinitDate: null,
    custSuppEndDate: null,
    kiiperBanking: false,
    version: "",
    kiiperBankingDiscount: 0,
    kiipBankinitDate: null,
    kiipBankEndDate: null,
    factOtherGroup: false,
    invContact: "",
    manageReq: false,
    clientVip: false,
    tariff: "",
    tariffAdditionals: "",
    eeccReceptionDays: 2,
    monthlyVipReportDate: 1,
    monthlyStandardReportDate: 15,
};

const defaultGen = {
    practiceId: "",
    clientType: 2,
    groupName: "",
    franchiseId: "",
    country: "",
    firmaDate: null,
    langEsc: "",
    langVer: "",
};

const defaultFact = {
    typeFact: 2,
    contactGCA: "",
    contactFact: "",
    emailCont: "",
    phoneNumber: "",
    subsInv: 2,
};

export default function NewGroup(props) {
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    /** React redux */
    const todos = useSelector((state) => state.todos);

    /** Component states */
    const [activeStep, setActiveStep] = useState(0);
    const [showFran, setShowFran] = useState(true);
    const [isError, setisError] = useState(false);
    const [isError1, setisError1] = useState(false);
    const [isError2, setisError2] = useState(false);
    const [isError3, setisError3] = useState(false);
    const [isError4, setisError4] = useState(false);
    const [requiredData, setRequiredData] = useState(true);
    const [disabledData, setDisabledData] = useState(false);
    const [disabledData2, setDisabledData2] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [open, setOpen] = useState(false);
    const [openGroupVal, setOpenGroupVal] = useState(false);
    const [dialogText, setDialogText] = useState("");
    const [dialogType, setDialogType] = useState("info");
    const [valuesGen, setValuesGen] = useState(defaultGen);
    const [valuesFact, setValuesFact] = useState(defaultFact);
    const [valuesSettings, setValuesSettings] = useState(defaultSettings);
    const [valueStaff, setValueStaff] = useState({
        teamCompany1: teamCompanyDefault,
        teamCompany2: teamCompanyDefault,
        teamCompany3: teamCompanyDefault,
        teamCompany4: teamCompanyDefault,
        supervisor: "Por asignar",
        manager: "Por asignar",
        advisor: "Por asignar",
        analyst: "Por asignar",
        backupAnalyst: "Por asignar",
    });
    const [valueTab, setValueTab] = useState(0);
    const [showTooltip, setShowTooltip] = useState(false);
    const [showTooltip2, setShowTooltip2] = useState(false);
    const [isReq, setIsReq] = useState(false);

    /** Component variables */
    const steps = [
        t("miscellaneous.generalData"),
        t("miscellaneous.invoicing"),
        t("miscellaneous.settings"),
        t("miscellaneous.staff"),
    ];
    const stepsEdit = [
        t("miscellaneous.generalData"),
        t("miscellaneous.invoicing"),
        t("miscellaneous.settings"),
        t("miscellaneous.staffClient"),
        t("miscellaneous.staffGCA"),
    ];

    let isRequired2 =
        valueStaff.teamCompany2.contact_name.trim() !== "" ||
        valueStaff.teamCompany2.contact_email.trim() !== "" ||
        valueStaff.teamCompany2.contact_role.trim() !== "" ||
        valueStaff.teamCompany2.contact_closure ||
        valueStaff.teamCompany2.contact_status ||
        valueStaff.teamCompany2.contact_requirements ||
        valueStaff.teamCompany2.contact_invoices;
    let isRequired3 =
        valueStaff.teamCompany3.contact_name.trim() !== "" ||
        valueStaff.teamCompany3.contact_email.trim() !== "" ||
        valueStaff.teamCompany3.contact_role.trim() !== "" ||
        valueStaff.teamCompany3.contact_closure ||
        valueStaff.teamCompany3.contact_status ||
        valueStaff.teamCompany3.contact_requirements ||
        valueStaff.teamCompany3.contact_invoices;
    let isRequired4 =
        valueStaff.teamCompany4.contact_name.trim() !== "" ||
        valueStaff.teamCompany4.contact_email.trim() !== "" ||
        valueStaff.teamCompany4.contact_role.trim() !== "" ||
        valueStaff.teamCompany4.contact_closure ||
        valueStaff.teamCompany4.contact_status ||
        valueStaff.teamCompany4.contact_requirements ||
        valueStaff.teamCompany4.contact_invoices;

    /** Component functions */
    const handleChangeTab = (event, newValue) => {
        handleReset();
        setValueTab(newValue);
    };

    const returnIsError2 = () => {
        handleCloseDialog();
        props.setTexto(t("miscellaneous.requiredData"));
        props.setalertType("warning");
        props.setShow(true);
        props.setBackDrop(false);
        setisError2(true);
        setisError4(false);
        setisError3(false);
        setisError1(false);
        setisError(false);
    };

    const handleNext = async () => {
        if (activeStep === steps.length - 1) {
            props.setBackDrop(true);

            let isValid = true;

            if (
                valuesGen.clientType === 1 &&
                (valuesSettings.tariff === "" || valuesSettings.tariffAdditionals === "")
            ) {
                isValid = false;
            }

            if ((valuesGen.clientType === 1 && valuesGen.franchiseId !== "") || valuesGen.clientType === 2) {
                if (
                    valuesGen.clientType !== "" &&
                    valuesGen.groupName.trim() !== "" &&
                    valuesGen.country !== "" &&
                    valuesGen.firmaDate !== null &&
                    valuesGen.langEsc !== "" &&
                    valuesGen.langVer !== ""
                ) {
                    if (
                        (requiredData &&
                            valuesFact.typeFact !== "" &&
                            valuesFact.contactGCA.trim() !== "" &&
                            valuesFact.contactFact.trim() !== "" &&
                            valuesFact.emailCont.trim() !== "" &&
                            /.+@.+\..+/.test(valuesFact.emailCont) &&
                            valuesFact.subsInv !== "") ||
                        (!requiredData && valuesFact.typeFact !== "")
                    ) {
                        if (
                            isValid &&
                            valuesSettings.eeccReceptionDays !== "" &&
                            valuesSettings.monthlyStandardReportDate !== ""
                        ) {
                            if (valuesSettings.groupedSupport) {
                                if (!valuesSettings.groupedSupportHours || !valuesSettings.custSuppinitDate) {
                                    returnIsError2();
                                    return;
                                }
                            }
                            if (valuesSettings.kiiperBanking) {
                                if (!valuesSettings.version || !valuesSettings.kiipBankinitDate) {
                                    returnIsError2();
                                    return;
                                }
                            }
                            if (valuesSettings.clientVip) {
                                if (valuesSettings.monthlyVipReportDate === "") {
                                    returnIsError2();
                                    return;
                                }
                            }

                            if (
                                valueStaff.teamCompany1.contact_name.trim() !== "" &&
                                valueStaff.teamCompany1.contact_email.trim() !== "" &&
                                /.+@.+\..+/.test(valueStaff.teamCompany1.contact_email) &&
                                valueStaff.teamCompany1.contact_role.trim() !== "" &&
                                valueStaff.supervisor !== "" &&
                                valueStaff.manager !== "" &&
                                valueStaff.advisor !== "" &&
                                valueStaff.analyst !== "" &&
                                valueStaff.backupAnalyst !== ""
                            ) {
                                if (
                                    valueStaff.teamCompany1.contact_closure ||
                                    valueStaff.teamCompany1.contact_status ||
                                    valueStaff.teamCompany1.contact_requirements ||
                                    valueStaff.teamCompany1.contact_invoices
                                ) {
                                    let arrayTeam = [];
                                    arrayTeam.push(valueStaff.teamCompany1);

                                    if (isRequired2) {
                                        if (
                                            valueStaff.teamCompany2.contact_name.trim() !== "" &&
                                            valueStaff.teamCompany2.contact_email.trim() !== "" &&
                                            /.+@.+\..+/.test(valueStaff.teamCompany2.contact_email) &&
                                            valueStaff.teamCompany2.contact_role.trim() !== ""
                                        ) {
                                            if (
                                                valueStaff.teamCompany2.contact_closure ||
                                                valueStaff.teamCompany2.contact_status ||
                                                valueStaff.teamCompany2.contact_requirements ||
                                                valueStaff.teamCompany2.contact_invoices
                                            ) {
                                                arrayTeam.push(valueStaff.teamCompany2);
                                            } else {
                                                handleCloseDialog();
                                                props.setTexto(t("miscellaneous.mustSelectIcon"));
                                                props.setalertType("warning");
                                                props.setShow(true);
                                                props.setBackDrop(false);
                                                setisError3(true);
                                                setisError2(false);
                                                setisError1(false);
                                                setisError(false);
                                                return;
                                            }
                                        } else {
                                            handleCloseDialog();
                                            props.setTexto(t("miscellaneous.requiredData"));
                                            props.setalertType("warning");
                                            props.setShow(true);
                                            props.setBackDrop(false);
                                            setisError3(true);
                                            setisError2(false);
                                            setisError1(false);
                                            setisError(false);
                                            return;
                                        }
                                    }
                                    if (isRequired3) {
                                        if (
                                            valueStaff.teamCompany3.contact_name.trim() !== "" &&
                                            valueStaff.teamCompany3.contact_email.trim() !== "" &&
                                            /.+@.+\..+/.test(valueStaff.teamCompany3.contact_email) &&
                                            valueStaff.teamCompany3.contact_role.trim() !== ""
                                        ) {
                                            if (
                                                valueStaff.teamCompany3.contact_closure ||
                                                valueStaff.teamCompany3.contact_status ||
                                                valueStaff.teamCompany3.contact_requirements ||
                                                valueStaff.teamCompany3.contact_invoices
                                            ) {
                                                arrayTeam.push(valueStaff.teamCompany3);
                                            } else {
                                                handleCloseDialog();
                                                props.setTexto(t("miscellaneous.mustSelectIcon"));
                                                props.setalertType("warning");
                                                props.setShow(true);
                                                props.setBackDrop(false);
                                                setisError3(true);
                                                setisError2(false);
                                                setisError1(false);
                                                setisError(false);
                                                return;
                                            }
                                        } else {
                                            handleCloseDialog();
                                            props.setTexto(t("miscellaneous.requiredData"));
                                            props.setalertType("warning");
                                            props.setShow(true);
                                            props.setBackDrop(false);
                                            setisError3(true);
                                            setisError2(false);
                                            setisError1(false);
                                            setisError(false);
                                            return;
                                        }
                                    }
                                    if (isRequired4) {
                                        if (
                                            valueStaff.teamCompany4.contact_name.trim() !== "" &&
                                            valueStaff.teamCompany4.contact_email.trim() !== "" &&
                                            /.+@.+\..+/.test(valueStaff.teamCompany4.contact_email) &&
                                            valueStaff.teamCompany4.contact_role.trim() !== ""
                                        ) {
                                            if (
                                                valueStaff.teamCompany4.contact_closure ||
                                                valueStaff.teamCompany4.contact_status ||
                                                valueStaff.teamCompany4.contact_requirements ||
                                                valueStaff.teamCompany4.contact_invoices
                                            ) {
                                                arrayTeam.push(valueStaff.teamCompany4);
                                            } else {
                                                handleCloseDialog();
                                                props.setTexto(t("miscellaneous.mustSelectIcon"));
                                                props.setalertType("warning");
                                                props.setShow(true);
                                                props.setBackDrop(false);
                                                setisError3(true);
                                                setisError2(false);
                                                setisError1(false);
                                                setisError(false);
                                                return;
                                            }
                                        } else {
                                            handleCloseDialog();
                                            props.setTexto(t("miscellaneous.requiredData"));
                                            props.setalertType("warning");
                                            props.setShow(true);
                                            props.setBackDrop(false);
                                            setisError3(true);
                                            setisError2(false);
                                            setisError1(false);
                                            setisError(false);
                                            return;
                                        }
                                    }

                                    const params = {
                                        id_practice: valuesGen.practiceId,
                                        practice_name: valuesGen.groupName,
                                        client_type: valuesGen.clientType,
                                        franchise_id:
                                            valuesGen.franchiseId.trim() === "" ? undefined : valuesGen.franchiseId,
                                        country_name: valuesGen.country,
                                        bsa_date: moment(valuesGen.firmaDate).format("DD/MM/YYYY"),
                                        typing_communication: valuesGen.langEsc === "" ? undefined : valuesGen.langEsc,
                                        verbal_communication: valuesGen.langVer === "" ? undefined : valuesGen.langVer,
                                        invoicing_level: valuesFact.typeFact,
                                        invoicing_contact_name:
                                            valuesFact.contactGCA === "" ? undefined : valuesFact.contactGCA,
                                        invoicing_client_name:
                                            valuesFact.contactFact === "" ? undefined : valuesFact.contactFact,
                                        invoicing_client_email:
                                            valuesFact.emailCont === "" ? undefined : valuesFact.emailCont,
                                        invoicing_phone_number:
                                            valuesFact.phoneNumber === "" ? undefined : valuesFact.phoneNumber,
                                        anticipated_invoice: valuesSettings.anticipatedInvoice,
                                        grouped_support: valuesSettings.groupedSupport,
                                        grouped_support_hours: parseFloat(valuesSettings.groupedSupportHours),
                                        grouped_support_discount: parseFloat(valuesSettings.groupedSupportDiscount),
                                        initial_date_customer: valuesSettings.groupedSupport
                                            ? valuesSettings.custSuppinitDate.format("YYYY-MM-DD")
                                            : null,
                                        end_date_customer: valuesSettings.custSuppEndDate
                                            ? valuesSettings.custSuppEndDate.format("YYYY-MM-DD")
                                            : null,
                                        banking_kiiper: valuesSettings.kiiperBanking,
                                        banking_kiiper_version: valuesSettings.version,
                                        banking_kiiper_discount: parseFloat(valuesSettings.kiiperBankingDiscount),
                                        initial_date_banking: valuesSettings.kiiperBanking
                                            ? valuesSettings.kiipBankinitDate.format("YYYY-MM-DD")
                                            : null,
                                        end_date_banking: valuesSettings.kiipBankEndDate
                                            ? valuesSettings.kiipBankEndDate.format("YYYY-MM-DD")
                                            : null,
                                        optional_invoicing: valuesSettings.invContact,
                                        manage_requirements: valuesSettings.manageReq,
                                        client_vip: valuesSettings.clientVip,
                                        tariff: parseFloat(valuesSettings.tariff),
                                        tariff_additionals: parseFloat(valuesSettings.tariffAdditionals),
                                        eecc_reception_days: parseInt(valuesSettings.eeccReceptionDays),
                                        monthly_vip_report_date: parseInt(valuesSettings.monthlyVipReportDate),
                                        monthly_standard_report_date: parseInt(
                                            valuesSettings.monthlyStandardReportDate
                                        ),
                                        team_company: arrayTeam,
                                        supervisor_name:
                                            valueStaff.supervisor === "" ? "Por asignar" : valueStaff.supervisor,
                                        manager_name: valueStaff.manager === "" ? "Por asignar" : valueStaff.manager,
                                        advisor_name: valueStaff.advisor === "" ? "Por asignar" : valueStaff.advisor,
                                        analyst_name: valueStaff.analyst === "" ? "Por asignar" : valueStaff.analyst,
                                        backup_analyst_name:
                                            valueStaff.backupAnalyst === "" ? "Por asignar" : valueStaff.backupAnalyst,
                                        invoicing_subscription: valuesFact.subsInv,
                                    };

                                    await fetch(`/addPractice`, {
                                        method: "POST",
                                        headers: {
                                            "Content-type": "application/json; charset=UTF-8",
                                            "Access-Control-Allow-Origin": "*",
                                        },
                                        body: JSON.stringify(params),
                                    })
                                        .then((response) => {
                                            if (response.ok) {
                                                handleCloseDialog();
                                                handleReset();
                                                props.setTexto(t("miscellaneous.groupCreation"));
                                                props.setalertType("success");
                                                props.setShow(true);
                                                props.setGroupData();
                                                props.setClientData();
                                                props.onClose();
                                            } else {
                                                handleCloseDialog();
                                                props.setTexto(t("miscellaneous.existsGroup"));
                                                props.setalertType("error");
                                                props.setShow(true);
                                            }
                                        })
                                        .catch((error) => {
                                            console.log(error);
                                        });
                                } else {
                                    handleCloseDialog();
                                    props.setTexto(t("miscellaneous.mustSelectIcon"));
                                    props.setalertType("warning");
                                    props.setShow(true);
                                    props.setBackDrop(false);
                                    setisError3(true);
                                    setisError2(false);
                                    setisError1(false);
                                    setisError(false);
                                    return;
                                }
                            } else {
                                handleCloseDialog();
                                props.setTexto(t("miscellaneous.requiredData"));
                                props.setalertType("warning");
                                props.setShow(true);
                                setisError3(true);
                                setisError2(false);
                                setisError1(false);
                                setisError(false);
                            }
                        } else {
                            handleCloseDialog();
                            props.setTexto(t("miscellaneous.requiredData"));
                            props.setalertType("warning");
                            props.setShow(true);
                            setisError2(true);
                            setisError1(false);
                            setisError(false);
                        }
                    } else {
                        handleCloseDialog();
                        let message = "";
                        if (
                            requiredData &&
                            valuesFact.typeFact &&
                            valuesFact.contactGCA.trim() &&
                            valuesFact.contactFact.trim() &&
                            valuesFact.emailCont.trim() &&
                            !/.+@.+\..+/.test(valuesFact.emailCont)
                        ) {
                            message = t("miscellaneous.invalidEmailFormat");
                        } else {
                            message = t("miscellaneous.requiredData");
                        }
                        props.setTexto(message);
                        props.setalertType("warning");
                        props.setShow(true);
                        setisError1(true);
                        setisError(false);
                    }
                } else {
                    handleCloseDialog();
                    props.setTexto(t("miscellaneous.requiredData"));
                    props.setalertType("warning");
                    props.setShow(true);
                    setisError(true);
                }
            } else {
                handleCloseDialog();
                props.setTexto(t("miscellaneous.requiredData"));
                props.setalertType("warning");
                props.setShow(true);
                setisError(true);
            }

            props.setBackDrop(false);
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const updateGroup = async (groupType) => {
        props.setBackDrop(true);

        let isValid = false;
        let arrayTeam = [];
        let isValid2 = true;

        if (valuesGen.clientType === 1 && (valuesSettings.tariff === "" || valuesSettings.tariffAdditionals === "")) {
            isValid2 = false;
        }

        switch (activeStep) {
            case 0:
                if ((valuesGen.clientType === 1 && valuesGen.franchiseId !== "") || valuesGen.clientType === 2) {
                    if (
                        valuesGen.clientType !== "" &&
                        valuesGen.groupName.trim() !== "" &&
                        valuesGen.firmaDate !== null &&
                        valuesGen.country !== "" &&
                        valuesGen.langEsc !== "" &&
                        valuesGen.langVer !== ""
                    ) {
                        isValid = true;
                        props.setTexto(t("miscellaneous.successfulGenDataGroup"));
                    } else {
                        handleCloseDialog();
                        props.setTexto(t("miscellaneous.requiredData"));
                        props.setalertType("warning");
                        props.setShow(true);
                        setisError4(false);
                        setisError3(false);
                        setisError2(false);
                        setisError1(false);
                        setisError(true);
                    }
                } else {
                    handleCloseDialog();
                    props.setTexto(t("miscellaneous.requiredData"));
                    props.setalertType("warning");
                    props.setShow(true);
                    setisError4(false);
                    setisError3(false);
                    setisError2(false);
                    setisError1(false);
                    setisError(true);
                }
                break;
            case 1:
                if (
                    (requiredData &&
                        valuesFact.typeFact &&
                        valuesFact.contactGCA.trim() &&
                        valuesFact.contactFact.trim() &&
                        valuesFact.emailCont.trim() &&
                        /.+@.+\..+/.test(valuesFact.emailCont) &&
                        valuesFact.subsInv) ||
                    (!requiredData && valuesFact.typeFact)
                ) {
                    isValid = true;
                    props.setTexto(t("miscellaneous.successfulInvoicingGroup"));
                } else {
                    handleCloseDialog();
                    let message = "";
                    if (
                        requiredData &&
                        valuesFact.typeFact &&
                        valuesFact.contactGCA.trim() &&
                        valuesFact.contactFact.trim() &&
                        valuesFact.emailCont.trim() &&
                        !/.+@.+\..+/.test(valuesFact.emailCont)
                    ) {
                        message = t("miscellaneous.invalidEmailFormat");
                    } else {
                        message = t("miscellaneous.requiredData");
                    }
                    props.setTexto(message);
                    props.setalertType("warning");
                    props.setShow(true);
                    setisError4(false);
                    setisError3(false);
                    setisError2(false);
                    setisError1(true);
                    setisError(false);
                }
                break;
            case 2:
                if (isValid2 && valuesSettings.monthlyStandardReportDate) {
                    if (valuesSettings.groupedSupport) {
                        if (!parseInt(valuesSettings.groupedSupportHours) || !valuesSettings.custSuppinitDate) {
                            returnIsError2();
                            return;
                        }
                    }
                    if (valuesSettings.kiiperBanking) {
                        if (!valuesSettings.version || !valuesSettings.kiipBankinitDate) {
                            returnIsError2();
                            return;
                        }
                    }
                    if (valuesSettings.clientVip) {
                        if (!valuesSettings.monthlyVipReportDate) {
                            returnIsError2();
                            return;
                        }
                    }
                    if ((valuesSettings.groupedSupport || valuesSettings.kiiperBanking) && valuesFact.typeFact !== 1) {
                        if (valuesSettings.factOtherGroup && !valuesSettings.invContact) {
                            returnIsError2();
                            return;
                        }
                    }

                    isValid = true;
                    props.setTexto(t("miscellaneous.successfulSettingsGroup"));
                } else {
                    handleCloseDialog();
                    props.setTexto(t("miscellaneous.requiredData"));
                    props.setalertType("warning");
                    props.setShow(true);
                    props.setBackDrop(false);
                    setisError4(false);
                    setisError3(false);
                    setisError2(true);
                    setisError1(false);
                    setisError(false);
                }
                break;
            case 3:
                if (
                    valueStaff.teamCompany1.contact_name.trim() !== "" &&
                    valueStaff.teamCompany1.contact_email.trim() !== "" &&
                    /.+@.+\..+/.test(valueStaff.teamCompany1.contact_email) &&
                    valueStaff.teamCompany1.contact_role.trim() !== ""
                ) {
                    if (
                        valueStaff.teamCompany1.contact_closure ||
                        valueStaff.teamCompany1.contact_status ||
                        valueStaff.teamCompany1.contact_requirements ||
                        valueStaff.teamCompany1.contact_invoices
                    ) {
                        arrayTeam.push(valueStaff.teamCompany1);

                        if (isRequired2) {
                            if (
                                valueStaff.teamCompany2.contact_name.trim() !== "" &&
                                valueStaff.teamCompany2.contact_email.trim() !== "" &&
                                /.+@.+\..+/.test(valueStaff.teamCompany2.contact_email) &&
                                valueStaff.teamCompany2.contact_role.trim() !== ""
                            ) {
                                if (
                                    valueStaff.teamCompany2.contact_closure ||
                                    valueStaff.teamCompany2.contact_status ||
                                    valueStaff.teamCompany2.contact_requirements ||
                                    valueStaff.teamCompany2.contact_invoices
                                ) {
                                    arrayTeam.push(valueStaff.teamCompany2);
                                } else {
                                    handleCloseDialog();
                                    props.setTexto(t("miscellaneous.mustSelectIcon"));
                                    props.setalertType("warning");
                                    props.setShow(true);
                                    props.setBackDrop(false);
                                    setisError4(false);
                                    setisError3(true);
                                    setisError2(false);
                                    setisError1(false);
                                    setisError(false);
                                    return;
                                }
                            } else {
                                handleCloseDialog();
                                props.setTexto(t("miscellaneous.requiredData"));
                                props.setalertType("warning");
                                props.setShow(true);
                                props.setBackDrop(false);
                                setisError4(false);
                                setisError3(true);
                                setisError2(false);
                                setisError1(false);
                                setisError(false);
                                return;
                            }
                        }
                        if (isRequired3) {
                            if (
                                valueStaff.teamCompany3.contact_name.trim() !== "" &&
                                valueStaff.teamCompany3.contact_email.trim() !== "" &&
                                /.+@.+\..+/.test(valueStaff.teamCompany3.contact_email) &&
                                valueStaff.teamCompany3.contact_role.trim() !== ""
                            ) {
                                if (
                                    valueStaff.teamCompany3.contact_closure ||
                                    valueStaff.teamCompany3.contact_status ||
                                    valueStaff.teamCompany3.contact_requirements ||
                                    valueStaff.teamCompany3.contact_invoices
                                ) {
                                    arrayTeam.push(valueStaff.teamCompany3);
                                } else {
                                    handleCloseDialog();
                                    props.setTexto(t("miscellaneous.mustSelectIcon"));
                                    props.setalertType("warning");
                                    props.setShow(true);
                                    props.setBackDrop(false);
                                    setisError4(false);
                                    setisError3(true);
                                    setisError2(false);
                                    setisError1(false);
                                    setisError(false);
                                    return;
                                }
                            } else {
                                handleCloseDialog();
                                props.setTexto(t("miscellaneous.requiredData"));
                                props.setalertType("warning");
                                props.setShow(true);
                                props.setBackDrop(false);
                                setisError4(false);
                                setisError3(true);
                                setisError2(false);
                                setisError1(false);
                                setisError(false);
                                return;
                            }
                        }
                        if (isRequired4) {
                            if (
                                valueStaff.teamCompany4.contact_name.trim() !== "" &&
                                valueStaff.teamCompany4.contact_email.trim() !== "" &&
                                /.+@.+\..+/.test(valueStaff.teamCompany4.contact_email) &&
                                valueStaff.teamCompany4.contact_role.trim() !== ""
                            ) {
                                if (
                                    valueStaff.teamCompany4.contact_closure ||
                                    valueStaff.teamCompany4.contact_status ||
                                    valueStaff.teamCompany4.contact_requirements ||
                                    valueStaff.teamCompany4.contact_invoices
                                ) {
                                    arrayTeam.push(valueStaff.teamCompany4);
                                } else {
                                    handleCloseDialog();
                                    props.setTexto(t("miscellaneous.mustSelectIcon"));
                                    props.setalertType("warning");
                                    props.setShow(true);
                                    props.setBackDrop(false);
                                    setisError4(false);
                                    setisError3(true);
                                    setisError2(false);
                                    setisError1(false);
                                    setisError(false);
                                    return;
                                }
                            } else {
                                handleCloseDialog();
                                props.setTexto(t("miscellaneous.requiredData"));
                                props.setalertType("warning");
                                props.setShow(true);
                                props.setBackDrop(false);
                                setisError4(false);
                                setisError3(true);
                                setisError2(false);
                                setisError1(false);
                                setisError(false);
                                return;
                            }
                        }

                        isValid = true;
                        props.setTexto(t("miscellaneous.successfulStaffGroup"));
                    } else {
                        handleCloseDialog();
                        props.setTexto(t("miscellaneous.mustSelectIcon"));
                        props.setalertType("warning");
                        props.setShow(true);
                        props.setBackDrop(false);
                        setisError4(false);
                        setisError3(true);
                        setisError2(false);
                        setisError1(false);
                        setisError(false);
                        return;
                    }
                } else {
                    handleCloseDialog();
                    props.setTexto(t("miscellaneous.requiredData"));
                    props.setalertType("warning");
                    props.setShow(true);
                    props.setBackDrop(false);
                    setisError4(false);
                    setisError3(true);
                    setisError2(false);
                    setisError1(false);
                    setisError(false);
                }
                break;
            default:
                if (
                    valueStaff.supervisor &&
                    valueStaff.manager &&
                    valueStaff.advisor &&
                    valueStaff.analyst &&
                    valueStaff.backupAnalyst
                ) {
                    isValid = true;
                    props.setTexto(t("miscellaneous.successfulStaffGroup"));
                } else {
                    handleCloseDialog();
                    props.setTexto(t("miscellaneous.requiredData"));
                    props.setalertType("warning");
                    props.setShow(true);
                    props.setBackDrop(false);
                    setisError4(true);
                    setisError3(false);
                    setisError2(false);
                    setisError1(false);
                    setisError(false);
                }
                break;
        }

        const params = {
            id_practice: valuesGen.practiceId,
            practice_name: valuesGen.groupName,
            client_type: valuesGen.clientType,
            franchise_id: valuesGen.franchiseId.trim() === "" ? undefined : valuesGen.franchiseId,
            country_name: valuesGen.country,
            bsa_date: moment(valuesGen.firmaDate).format("YYYY-MM-DD"),
            typing_communication: valuesGen.langEsc === "" ? undefined : valuesGen.langEsc,
            verbal_communication: valuesGen.langVer === "" ? undefined : valuesGen.langVer,
            invoicing_level: valuesFact.typeFact,
            invoicing_contact_name: valuesFact.contactGCA === "" ? undefined : valuesFact.contactGCA,
            invoicing_client_name: valuesFact.contactFact === "" ? undefined : valuesFact.contactFact,
            invoicing_client_email: valuesFact.emailCont === "" ? undefined : valuesFact.emailCont,
            invoicing_phone_number: valuesFact.phoneNumber === "" ? undefined : valuesFact.phoneNumber,
            anticipated_invoice: valuesSettings.anticipatedInvoice,
            grouped_support: valuesSettings.groupedSupport,
            grouped_support_hours: parseFloat(valuesSettings.groupedSupportHours),
            grouped_support_discount: parseFloat(valuesSettings.groupedSupportDiscount),
            initial_date_customer: valuesSettings.groupedSupport
                ? valuesSettings.custSuppinitDate.format("YYYY-MM-DD")
                : null,
            end_date_customer: valuesSettings.custSuppEndDate
                ? valuesSettings.custSuppEndDate.format("YYYY-MM-DD")
                : null,
            banking_kiiper: valuesSettings.kiiperBanking,
            banking_kiiper_version: valuesSettings.version,
            banking_kiiper_discount: parseFloat(valuesSettings.kiiperBankingDiscount),
            initial_date_banking: valuesSettings.kiiperBanking
                ? valuesSettings.kiipBankinitDate.format("YYYY-MM-DD")
                : null,
            end_date_banking: valuesSettings.kiipBankEndDate
                ? valuesSettings.kiipBankEndDate.format("YYYY-MM-DD")
                : null,
            optional_invoicing: valuesSettings.invContact,
            manage_requirements: valuesSettings.manageReq,
            client_vip: valuesSettings.clientVip,
            tariff: parseFloat(valuesSettings.tariff),
            tariff_additionals: parseFloat(valuesSettings.tariffAdditionals),
            eecc_reception_days: parseInt(valuesSettings.eeccReceptionDays),
            monthly_vip_report_date: parseInt(valuesSettings.monthlyVipReportDate),
            monthly_standard_report_date: parseInt(valuesSettings.monthlyStandardReportDate),
            team_company: arrayTeam,
            supervisor_name: valueStaff.supervisor === "" ? "Por asignar" : valueStaff.supervisor,
            manager_name: valueStaff.manager === "" ? "Por asignar" : valueStaff.manager,
            advisor_name: valueStaff.advisor === "" ? "Por asignar" : valueStaff.advisor,
            analyst_name: valueStaff.analyst === "" ? "Por asignar" : valueStaff.analyst,
            backup_analyst_name: valueStaff.backupAnalyst === "" ? "Por asignar" : valueStaff.backupAnalyst,
            update_type: activeStep,
            allClients: groupType,
            invoicing_subscription: valuesFact.subsInv,
        };

        if (isValid) {
            await fetch(`/updatePractice`, {
                method: "POST",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
                body: JSON.stringify(params),
            })
                .then((response) => {
                    if (response.ok) {
                        handleCloseDialog();
                        props.setalertType("success");
                        props.setShow(true);
                        props.setGroupData();
                        props.setClientData();
                        resetErrors();
                        getTeam();
                    } else {
                        handleCloseDialog();
                        props.setTexto(t("miscellaneous.updatingGroupError"));
                        props.setalertType("error");
                        props.setShow(true);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }

        props.setBackDrop(false);
    };

    const getTeam = async () => {
        await fetch(`/getPracticeDetails?practice_id=${valuesGen.practiceId}`, {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then((res) => res.json())
            .then((data) => {
                setValueStaff({
                    ...valueStaff,
                    supervisorOG: data.supervisor_name ?? "Por asignar",
                    managerOG: data.manager_name ?? "Por asignar",
                    advisorOG: data.advisor_name ?? "Por asignar",
                    analystOG: data.analyst_name ?? "Por asignar",
                    backupAnalystOG: data.backup_analyst_name ?? "Por asignar",
                    supervisor: data.supervisor_name ?? "Por asignar",
                    manager: data.manager_name ?? "Por asignar",
                    analyst: data.analyst_name ?? "Por asignar",
                    backupAnalyst: data.backup_analyst_name ?? "Por asignar",
                    advisor: data.advisor_name ?? "Por asignar",
                    supervisorReq: data.supervisor_name_request,
                    managerReq: data.manager_name_request,
                    advisorReq: data.advisor_name_request,
                    analystReq: data.analyst_name_request,
                    backupAnalystReq: data.backup_analyst_name_request,
                });

                if (
                    data.supervisor_name_request ||
                    data.manager_name_request ||
                    data.analyst_name_request ||
                    data.backup_analyst_name_request ||
                    data.advisor_name_request
                ) {
                    setIsReq(true);
                }
            });
    };

    const sortCountries = () => {
        if (moment.locale() === "en") {
            Countries.sort((a, b) => {
                if (a.name_en > b.name_en) return 1;

                if (a.name_en < b.name_en) return -1;

                return 0;
            });
        } else {
            Countries.sort((a, b) => {
                if (a.name_es > b.name_es) return 1;

                if (a.name_es < b.name_es) return -1;

                return 0;
            });
        }
    };

    const handleBack = () => {
        if (activeStep === 0) {
            handleReset();
            props.onClose();
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }
    };

    const handleStep = (step) => {
        setActiveStep(step);
    };

    const resetErrors = () => {
        setisError(false);
        setisError1(false);
        setisError2(false);
        setisError3(false);
        setisError4(false);
        setShowEdit(true);
    };

    const handleReset = () => {
        setValueTab(0);
        setActiveStep(0);
        setShowEdit(false);
        setShowFran(true);
        setisError(false);
        setisError1(false);
        setisError2(false);
        setisError3(false);
        setisError4(false);
        setRequiredData(true);
        setDisabledData(false);
        setDisabledData2(false);
        setValuesGen(defaultGen);
        setValuesFact(defaultFact);
        setValuesSettings(defaultSettings);
        setValueStaff({
            ...valueStaff,
            teamCompany1: teamCompanyDefault,
            teamCompany2: teamCompanyDefault,
            teamCompany3: teamCompanyDefault,
            teamCompany4: teamCompanyDefault,
            supervisor: "Por asignar",
            manager: "Por asignar",
            advisor: "Por asignar",
            analyst: "Por asignar",
            backupAnalyst: "Por asignar",
        });
    };

    const handleOpenDialog = () => {
        if (valueTab === 0) {
            setDialogType("info");
            setDialogText(t("miscellaneous.groupSureCreation"));
            setOpen(true);
        } else {
            switch (activeStep) {
                case 1:
                    if (
                        (requiredData &&
                            valuesFact.typeFact &&
                            valuesFact.contactGCA.trim() &&
                            valuesFact.contactFact.trim() &&
                            valuesFact.emailCont.trim() &&
                            /.+@.+\..+/.test(valuesFact.emailCont)) ||
                        (!requiredData && valuesFact.typeFact)
                    ) {
                        setDialogType("info");
                        setDialogText(t("miscellaneous.registeredChangesGroup"));
                        setOpen(true);
                    } else {
                        let message = "";
                        if (
                            requiredData &&
                            valuesFact.typeFact &&
                            valuesFact.contactGCA.trim() &&
                            valuesFact.contactFact.trim() &&
                            valuesFact.emailCont.trim() &&
                            !/.+@.+\..+/.test(valuesFact.emailCont)
                        ) {
                            message = t("miscellaneous.invalidEmailFormat");
                        } else {
                            message = t("miscellaneous.requiredData");
                        }
                        props.setTexto(message);
                        props.setalertType("warning");
                        props.setShow(true);
                        setisError4(false);
                        setisError3(false);
                        setisError2(false);
                        setisError1(true);
                        setisError(false);
                    }
                    break;
                case 2:
                    if (valuesSettings.groupedSupport || valuesSettings.kiiperBanking) {
                        setOpenGroupVal(true);
                    } else {
                        setDialogType("info");
                        setDialogText(t("miscellaneous.registeredChangesGroup"));
                        setOpen(true);
                    }
                    break;
                default:
                    setDialogType("info");
                    setDialogText(t("miscellaneous.registeredChangesGroup"));
                    setOpen(true);
                    break;
            }
        }
    };

    const handleCloseDialog = () => {
        setDialogText("");
        setDialogType("");
        setOpen(false);
        setOpenGroupVal(false);
    };

    const handleChange = (event) => {
        setValuesGen({
            ...valuesGen,
            [event.target.name]: event.target.value,
        });
    };

    const handleChangeFirmaDate = (date) => {
        setValuesGen({
            ...valuesGen,
            firmaDate: date,
        });
    };

    const handleChangeInitDate = (date, num) => {
        let startDate = moment(date).startOf("month");

        if (num === 1) {
            if (!date || !valuesSettings.custSuppEndDate) {
                setValuesSettings({
                    ...valuesSettings,
                    custSuppinitDate: date ? startDate : date,
                });
            } else {
                if (startDate.isAfter(valuesSettings.custSuppEndDate)) {
                    setValuesSettings({
                        ...valuesSettings,
                        custSuppinitDate: null,
                    });

                    props.setTexto(t("reports.initDateMess"));
                    props.setalertType("warning");
                    props.setShow(true);
                } else {
                    setValuesSettings({
                        ...valuesSettings,
                        custSuppinitDate: startDate,
                    });
                }
            }
        } else {
            if (!date || !valuesSettings.kiipBankEndDate) {
                setValuesSettings({
                    ...valuesSettings,
                    kiipBankinitDate: date ? startDate : date,
                });
            } else {
                if (startDate.isAfter(valuesSettings.kiipBankEndDate)) {
                    setValuesSettings({
                        ...valuesSettings,
                        kiipBankinitDate: null,
                    });

                    props.setTexto(t("reports.initDateMess"));
                    props.setalertType("warning");
                    props.setShow(true);
                } else {
                    setValuesSettings({
                        ...valuesSettings,
                        kiipBankinitDate: startDate,
                    });
                }
            }
        }
    };

    const handleChangeEndDate = (date, num) => {
        let endDate = moment(date).endOf("month").startOf("day");

        if (num === 1) {
            if (!date || !valuesSettings.custSuppinitDate) {
                setValuesSettings({
                    ...valuesSettings,
                    custSuppEndDate: date ? endDate : date,
                });
            } else {
                if (endDate.isBefore(valuesSettings.custSuppinitDate)) {
                    setValuesSettings({
                        ...valuesSettings,
                        custSuppEndDate: null,
                    });

                    props.setTexto(t("reports.endDateMess"));
                    props.setalertType("warning");
                    props.setShow(true);
                } else {
                    setValuesSettings({
                        ...valuesSettings,
                        custSuppEndDate: endDate,
                    });
                }
            }
        } else {
            if (!date || !valuesSettings.kiipBankinitDate) {
                setValuesSettings({
                    ...valuesSettings,
                    kiipBankEndDate: date ? endDate : date,
                });
            } else {
                if (endDate.isBefore(valuesSettings.kiipBankinitDate)) {
                    setValuesSettings({
                        ...valuesSettings,
                        kiipBankEndDate: null,
                    });

                    props.setTexto(t("reports.endDateMess"));
                    props.setalertType("warning");
                    props.setShow(true);
                } else {
                    setValuesSettings({
                        ...valuesSettings,
                        kiipBankEndDate: endDate,
                    });
                }
            }
        }
    };

    const handleChangeFact = (event) => {
        setValuesFact({
            ...valuesFact,
            [event.target.name]: event.target.value,
        });
    };

    const handleChangePhone = (event) => {
        const regex = /^\+[0-9\s.()-]{0,20}$/;
        if (event.target.value === "" || regex.test(event.target.value)) {
            setValuesFact({
                ...valuesFact,
                [event.target.name]: event.target.value,
            });
        }
    };

    const handleChangeTypeFact = (event) => {
        if (event.target.value === 1) {
            setRequiredData(false);
            setDisabledData2(true);
            setValuesFact({
                [event.target.name]: event.target.value,
                contactGCA: "",
                contactFact: "",
                emailCont: "",
                phoneNumber: "",
            });
            if (valuesSettings.groupedSupport || valuesSettings.kiiperBanking) {
                setValuesSettings({
                    ...valuesSettings,
                    factOtherGroup: true,
                    invContact: "",
                });
            }
        } else {
            setRequiredData(true);
            setDisabledData2(false);
            setValuesFact({
                ...valuesFact,
                [event.target.name]: event.target.value,
            });
            setValuesSettings({
                ...valuesSettings,
                factOtherGroup: false,
                invContact: "",
            });
        }
    };

    const handleChangeSettings = (event) => {
        setValuesSettings({
            ...valuesSettings,
            [event.target.name]: event.target.value,
        });
    };

    const handleChangeSwitchSettings = (event) => {
        setValuesSettings({
            ...valuesSettings,
            anticipatedInvoice: event.target.checked,
        });
    };

    const handleChangeCheckGroupedSupport = (event) => {
        if (event.target.checked) {
            setValuesSettings({
                ...valuesSettings,
                groupedSupport: event.target.checked,
            });
        } else {
            setShowTooltip(false);
            setValuesSettings({
                ...valuesSettings,
                groupedSupport: event.target.checked,
                groupedSupportHours: 1,
                groupedSupportDiscount: 0,
            });
        }
    };

    const handleChangeCheckKiiperBanking = (event) => {
        if (event.target.checked) {
            setValuesSettings({
                ...valuesSettings,
                kiiperBanking: event.target.checked,
            });
        } else {
            setShowTooltip2(false);
            setValuesSettings({
                ...valuesSettings,
                kiiperBanking: event.target.checked,
                version: "",
                kiiperBankingDiscount: 0,
            });
        }
    };

    const handleChangeDiscount = (event) => {
        let discountAux = 0;

        if (event.target.name === "groupedSupportDiscount") {
            discountAux =
                event.target.value >= 0 && event.target.value <= 100
                    ? event.target.value
                    : valuesSettings.groupedSupportDiscount;

            setValuesSettings({
                ...valuesSettings,
                groupedSupportDiscount: discountAux,
            });
        } else {
            discountAux =
                event.target.value >= 0 && event.target.value <= 100
                    ? event.target.value
                    : valuesSettings.kiiperBankingDiscount;

            setValuesSettings({
                ...valuesSettings,
                kiiperBankingDiscount: discountAux,
            });
        }
    };

    const handleChangeCheckManageReq = (event) => {
        setValuesSettings({
            ...valuesSettings,
            manageReq: event.target.checked,
        });
    };

    const handleChangeCheckClientVip = (event) => {
        if (event.target.checked) {
            setValuesSettings({
                ...valuesSettings,
                clientVip: event.target.checked,
            });
        } else {
            setValuesSettings({
                ...valuesSettings,
                clientVip: event.target.checked,
                monthlyVipReportDate: 0,
            });
        }
    };

    const handleChangeReceptionDays = (event) => {
        let daysAux =
            (event.target.value >= 1 && event.target.value <= 15) || event.target.value === ""
                ? event.target.value
                : valuesSettings.eeccReceptionDays;

        setValuesSettings({
            ...valuesSettings,
            eeccReceptionDays: daysAux,
        });
    };

    const handleChangeMonthlyReports = (event) => {
        let daysAux = 0;

        if (event.target.name === "monthlyVipReportDate") {
            daysAux =
                (event.target.value >= 1 && event.target.value <= 25) || event.target.value === ""
                    ? event.target.value
                    : valuesSettings.monthlyVipReportDate;

            setValuesSettings({
                ...valuesSettings,
                monthlyVipReportDate: daysAux,
            });
        } else {
            daysAux =
                (event.target.value >= 1 && event.target.value <= 25) || event.target.value === ""
                    ? event.target.value
                    : valuesSettings.monthlyStandardReportDate;

            setValuesSettings({
                ...valuesSettings,
                monthlyStandardReportDate: daysAux,
            });
        }
    };

    const handleChangeStaff = (event) => {
        setValueStaff({
            ...valueStaff,
            [event.target.name]: event.target.value,
        });
    };

    const handleChangeType = (event) => {
        if (event.target.value === 1) {
            setShowFran(false);
            setRequiredData(true);
            setDisabledData2(false);
            setisError1(false);
            setValuesGen({
                ...valuesGen,
                clientType: event.target.value,
                country: "",
                firmaDate: null,
                langEsc: "",
                langVer: "",
            });
            setValuesFact({
                typeFact: 3,
                contactFact: "",
                contactGCA: "",
                emailCont: "",
                phoneNumber: "",
            });
        } else {
            setShowFran(true);
            setRequiredData(true);
            setDisabledData(false);
            setDisabledData2(false);
            setValuesGen({
                ...valuesGen,
                clientType: event.target.value,
                franchiseId: "",
                groupName: "",
                country: "",
                firmaDate: null,
                langEsc: "",
                langVer: "",
            });
            setValuesFact(defaultFact);
        }
        setValuesSettings({
            ...defaultSettings,
            anticipatedInvoice: event.target.value === 2,
        });
        setValueStaff({
            ...valueStaff,
            teamCompany1: teamCompanyDefault,
            teamCompany2: teamCompanyDefault,
            teamCompany3: teamCompanyDefault,
            teamCompany4: teamCompanyDefault,
            supervisor: "Por asignar",
            manager: "Por asignar",
            advisor: "Por asignar",
            analyst: "Por asignar",
            backupAnalyst: "Por asignar",
        });
    };

    const handleChangeTeamStaff = (event, team) => {
        setValueStaff({
            ...valueStaff,
            [team]: {
                ...valueStaff[team],
                [event.target.name]: event.target.value,
            },
        });
    };

    const handleChangeCheckOtherGroup = (event) => {
        setValuesSettings({
            ...valuesSettings,
            factOtherGroup: event.target.checked,
            invContact: "",
        });
    };

    const handleChangeCheck = (event, value, team) => {
        switch (value) {
            case 0:
                setValueStaff({
                    ...valueStaff,
                    [team]: {
                        ...valueStaff[team],
                        contact_closure: event.target.checked,
                    },
                });
                break;
            case 1:
                setValueStaff({
                    ...valueStaff,
                    [team]: {
                        ...valueStaff[team],
                        contact_status: event.target.checked,
                    },
                });
                break;
            case 4:
                setValueStaff({
                    ...valueStaff,
                    [team]: {
                        ...valueStaff[team],
                        contact_invoices: event.target.checked,
                    },
                });
                break;
            default:
                setValueStaff({
                    ...valueStaff,
                    [team]: {
                        ...valueStaff[team],
                        contact_requirements: event.target.checked,
                    },
                });
                break;
        }
    };

    const clearTeamStaff = (event, team) => {
        setValueStaff({
            ...valueStaff,
            [team]: teamCompanyDefault,
        });
    };

    const handleChangeFranq = async (event) => {
        let teamCompany1 = teamCompanyDefault;
        let teamCompany2 = teamCompanyDefault;
        let teamCompany3 = teamCompanyDefault;
        let teamCompany4 = teamCompanyDefault;

        if (event.target.value.trim() !== "") {
            await fetch(`/getFranchiseDetails?franchise_id=${event.target.value}`, {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
                .then((res) => res.json())
                .then((data) => {
                    if (data.team_company !== undefined) {
                        if (data.team_company.length !== 0) {
                            data.team_company.forEach((element, index) => {
                                if (index === 0) {
                                    teamCompany1 = {
                                        contact_name:
                                            element.contact_name !== undefined && element.contact_name !== null
                                                ? element.contact_name
                                                : "",
                                        contact_email:
                                            element.contact_email !== undefined && element.contact_email !== null
                                                ? element.contact_email
                                                : "",
                                        contact_role:
                                            element.contact_role !== undefined && element.contact_role !== null
                                                ? element.contact_role
                                                : "",
                                        contact_closure: element.contact_closure,
                                        contact_status: element.contact_status,
                                        contact_requirements: element.contact_requirements,
                                        contact_invoices: element.contact_invoices,
                                    };
                                }
                                if (index === 1) {
                                    teamCompany2 = {
                                        contact_name:
                                            element.contact_name !== undefined && element.contact_name !== null
                                                ? element.contact_name
                                                : "",
                                        contact_email:
                                            element.contact_email !== undefined && element.contact_email !== null
                                                ? element.contact_email
                                                : "",
                                        contact_role:
                                            element.contact_role !== undefined && element.contact_role !== null
                                                ? element.contact_role
                                                : "",
                                        contact_closure: element.contact_closure,
                                        contact_status: element.contact_status,
                                        contact_requirements: element.contact_requirements,
                                        contact_invoices: element.contact_invoices,
                                    };
                                }
                                if (index === 2) {
                                    teamCompany3 = {
                                        contact_name:
                                            element.contact_name !== undefined && element.contact_name !== null
                                                ? element.contact_name
                                                : "",
                                        contact_email:
                                            element.contact_email !== undefined && element.contact_email !== null
                                                ? element.contact_email
                                                : "",
                                        contact_role:
                                            element.contact_role !== undefined && element.contact_role !== null
                                                ? element.contact_role
                                                : "",
                                        contact_closure: element.contact_closure,
                                        contact_status: element.contact_status,
                                        contact_requirements: element.contact_requirements,
                                        contact_invoices: element.contact_invoices,
                                    };
                                }
                                if (index === 3) {
                                    teamCompany4 = {
                                        contact_name:
                                            element.contact_name !== undefined && element.contact_name !== null
                                                ? element.contact_name
                                                : "",
                                        contact_email:
                                            element.contact_email !== undefined && element.contact_email !== null
                                                ? element.contact_email
                                                : "",
                                        contact_role:
                                            element.contact_role !== undefined && element.contact_role !== null
                                                ? element.contact_role
                                                : "",
                                        contact_closure: element.contact_closure,
                                        contact_status: element.contact_status,
                                        contact_requirements: element.contact_requirements,
                                        contact_invoices: element.contact_invoices,
                                    };
                                }
                            });
                        }
                    }

                    setDisabledData(true);
                    setValuesGen({
                        ...valuesGen,
                        franchiseId: event.target.value,
                        country: data.country_name === undefined || data.country_name === null ? "" : data.country_name,
                        firmaDate: data.bsa_date ? moment(data.bsa_date, "YYYY-MM-DD") : null,
                        langEsc: data.typing_communication ?? "",
                        langVer: data.verbal_communication ?? "",
                    });
                    setValuesFact({
                        typeFact: 3,
                        contactFact: data.invoicing_client_name ?? "",
                        emailCont: data.invoicing_client_email ?? "",
                        contactGCA: data.invoicing_contact_name ?? "",
                        phoneNumber: data.invoicing_phone_number ?? "",
                    });
                    setValuesSettings({
                        ...valuesSettings,
                        anticipatedInvoice: data.anticipated_invoice ?? true,
                        groupedSupport: data.grouped_support ?? false,
                        groupedSupportHours: data.grouped_support_hours ?? 1,
                        groupedSupportDiscount: data.grouped_support_discount ?? 0,
                        custSuppinitDate: data.initial_date_customer
                            ? moment(data.initial_date_customer, "YYYY-MM-DD")
                            : null,
                        custSuppEndDate: data.end_date_customer ? moment(data.end_date_customer, "YYYY-MM-DD") : null,
                        kiiperBanking: data.banking_kiiper ?? false,
                        version: data.banking_kiiper_version ?? "",
                        kiiperBankingDiscount: data.banking_kiiper_discount ?? 0,
                        manageReq: data.manage_requirements ?? false,
                        clientVip: data.client_vip ?? false,
                        tariff: data.tariff ?? "",
                        tariffAdditionals: data.tariff_additionals ?? "",
                        eeccReceptionDays: data.eecc_reception_days ?? 2,
                        monthlyVipReportDate: data.monthly_vip_report_date ?? 1,
                        monthlyStandardReportDate: data.monthly_standard_report_date ?? 15,
                    });
                    setValueStaff({
                        ...valueStaff,
                        supervisor: data.supervisor_name ?? "Por asignar",
                        manager: data.manager_name ?? "Por asignar",
                        advisor: data.advisor_name ?? "Por asignar",
                        analyst: data.analyst_name ?? "Por asignar",
                        backupAnalyst: data.backup_analyst_name ?? "Por asignar",
                        teamCompany1: teamCompany1,
                        teamCompany2: teamCompany2,
                        teamCompany3: teamCompany3,
                        teamCompany4: teamCompany4,
                    });
                });
        } else {
            setDisabledData(false);
            setValuesGen({
                ...valuesGen,
                franchiseId: event.target.value,
                country: "",
                firmaDate: null,
                langEsc: "",
                langVer: "",
            });
            setValuesFact(defaultFact);
            setValuesSettings(defaultSettings);
            setValueStaff({
                ...valueStaff,
                teamCompany1: teamCompanyDefault,
                teamCompany2: teamCompanyDefault,
                teamCompany3: teamCompanyDefault,
                teamCompany4: teamCompanyDefault,
                supervisor: "Por asignar",
                manager: "Por asignar",
                advisor: "Por asignar",
                analyst: "Por asignar",
                backupAnalyst: "Por asignar",
            });
        }
    };

    /** Component events */
    useEffect(() => {
        sortCountries();
    }, [t]);

    return (
        <CustomDialog
            open={props.open}
            onClose={() => {
                handleReset();
                props.onClose();
            }}
            aria-labelledby="customized-dialog-title"
            fullWidth={true}
            maxWidth="lg"
        >
            <DialogTitle id="alert-dialog-title">
                {valueTab === 0 ? t("miscellaneous.newGroup") : t("miscellaneous.updateGroup")}
            </DialogTitle>
            <DialogContent dividers>
                <LocalizationProvider
                    dateAdapter={AdapterMoment}
                    adapterLocale={t("language.locale")}
                    localeText={{
                        clearButtonLabel: t("miscellaneous.clear"),
                    }}
                >
                    <Box
                        sx={{
                            flexGrow: 1,
                            display: window.innerWidth < 600 ? "block" : "flex",
                        }}
                    >
                        <Tabs
                            orientation={window.innerWidth < 600 ? "horizontal" : "vertical"}
                            variant="scrollable"
                            value={valueTab}
                            onChange={handleChangeTab}
                            indicatorColor="secondary"
                            aria-label="Vertical tabs example"
                            sx={{
                                borderBottom: window.innerWidth < 600 ? 1 : 0,
                                borderRight: window.innerWidth < 600 ? 0 : 1,
                                borderColor: "divider",
                            }}
                        >
                            <Tab label={t("miscellaneous.new")} {...a11yProps(0)} />
                            <Tab label={t("miscellaneous.edit")} {...a11yProps(1)} />
                        </Tabs>
                        <TabPanel value={valueTab} index={0}>
                            <Box sx={{ width: "100%", p: 4 }}>
                                <Stepper
                                    nonLinear
                                    activeStep={activeStep}
                                    orientation={window.innerWidth < 600 ? "vertical" : "horizontal"}
                                >
                                    {steps.map((label, index) => {
                                        const labelProps = {};
                                        if (
                                            (isError && index === 0) ||
                                            (isError1 && index === 1) ||
                                            (isError2 && index === 2) ||
                                            (isError3 && index === 2)
                                        ) {
                                            labelProps.optional = (
                                                <Typography variant="caption" color="error">
                                                    {t("miscellaneous.required")}
                                                </Typography>
                                            );

                                            labelProps.error = true;
                                        }
                                        return (
                                            <Step key={label}>
                                                <StepButton color="inherit" onClick={() => handleStep(index)}>
                                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                                </StepButton>
                                            </Step>
                                        );
                                    })}
                                </Stepper>
                            </Box>
                            {activeStep === 0 && (
                                <>
                                    <Card elevation={0}>
                                        <CardContent>
                                            <Grid container spacing={2}>
                                                <Grid item lg={2} sm={6} xs={12}>
                                                    <FormControl
                                                        fullWidth
                                                        required
                                                        error={isError && valuesGen.clientType === "" ? true : false}
                                                    >
                                                        <InputLabel id="type-simple-select-label">
                                                            {t("miscellaneous.type")}
                                                        </InputLabel>
                                                        <Select
                                                            labelId="type-simple-select-label"
                                                            IconComponent={KeyboardArrowDownIcon}
                                                            onChange={handleChangeType}
                                                            value={valuesGen.clientType}
                                                            label={t("miscellaneous.type")}
                                                            MenuProps={{
                                                                PaperProps: {
                                                                    sx: {
                                                                        mt: 0.7,
                                                                        borderRadius: 2,
                                                                        boxShadow:
                                                                            "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                                    },
                                                                },
                                                            }}
                                                        >
                                                            <MenuItem value={1}>{t("miscellaneous.firm")}</MenuItem>
                                                            <MenuItem value={2}>SMB</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item lg={4} sm={6} xs={12}>
                                                    <FormControl
                                                        fullWidth
                                                        disabled={showFran}
                                                        required={!showFran}
                                                        error={
                                                            isError && valuesGen.franchiseId === "" && !showFran
                                                                ? true
                                                                : false
                                                        }
                                                    >
                                                        <InputLabel id="franq-simple-select-label">
                                                            {t("miscellaneous.franchise")}
                                                        </InputLabel>
                                                        <Select
                                                            name="franchiseId"
                                                            labelId="franq-simple-select-label"
                                                            IconComponent={KeyboardArrowDownIcon}
                                                            value={valuesGen.franchiseId}
                                                            onChange={handleChangeFranq}
                                                            label={t("miscellaneous.franchise")}
                                                            MenuProps={MenuProps}
                                                        >
                                                            <MenuItem value=" ">
                                                                <Typography
                                                                    variant="body1"
                                                                    component="span"
                                                                    align="center"
                                                                    color="textPrimary"
                                                                >
                                                                    N/A
                                                                </Typography>
                                                            </MenuItem>
                                                            {props.franqData.map((value) => (
                                                                <MenuItem value={value.id}>
                                                                    {value.franchise_name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item lg={6} sm={6} xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        required
                                                        label={t("miscellaneous.groupName")}
                                                        name="groupName"
                                                        onChange={handleChange}
                                                        value={valuesGen.groupName}
                                                        variant="outlined"
                                                        error={
                                                            isError && valuesGen.groupName.trim() === "" ? true : false
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item lg={3} sm={6} xs={12}>
                                                    <FormControl
                                                        fullWidth
                                                        required
                                                        disabled={disabledData}
                                                        error={isError && valuesGen.country === "" ? true : false}
                                                    >
                                                        <InputLabel id="country-simple-select-label">
                                                            {t("miscellaneous.country")}
                                                        </InputLabel>
                                                        <Select
                                                            name="country"
                                                            labelId="country-simple-select-label"
                                                            id="demo-simple-select"
                                                            IconComponent={KeyboardArrowDownIcon}
                                                            value={valuesGen.country}
                                                            label={t("miscellaneous.country")}
                                                            onChange={handleChange}
                                                            MenuProps={MenuProps}
                                                        >
                                                            {MainCountries.map((option) => (
                                                                <MenuItem value={option.code}>
                                                                    {moment.locale() === "en"
                                                                        ? option.name_en
                                                                        : option.name_es}
                                                                </MenuItem>
                                                            ))}
                                                            <Divider />
                                                            {Countries.map((option) => (
                                                                <MenuItem value={option.code}>
                                                                    {moment.locale() === "en"
                                                                        ? option.name_en
                                                                        : option.name_es}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item lg={3} sm={6} xs={12}>
                                                    <DatePicker
                                                        componentsProps={{
                                                            actionBar: {
                                                                actions: ["clear"],
                                                            },
                                                        }}
                                                        value={valuesGen.firmaDate}
                                                        onChange={(newValue) => {
                                                            handleChangeFirmaDate(newValue);
                                                        }}
                                                        inputFormat={todos.dateFormat}
                                                        disabled={disabledData}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                fullWidth
                                                                required
                                                                {...params}
                                                                error={
                                                                    isError && valuesGen.firmaDate === null
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                        )}
                                                        inputProps={{
                                                            readOnly: true,
                                                        }}
                                                        label={t("miscellaneous.firmDate")}
                                                    />
                                                </Grid>
                                                <Grid item lg={3} sm={6} xs={12}>
                                                    <FormControl
                                                        fullWidth
                                                        required
                                                        disabled={disabledData}
                                                        error={isError && valuesGen.langVer === "" ? true : false}
                                                    >
                                                        <InputLabel id="ver-simple-select-label">
                                                            {t("miscellaneous.verbalCommunication")}
                                                        </InputLabel>
                                                        <Select
                                                            name="langVer"
                                                            labelId="ver-simple-select-label"
                                                            IconComponent={KeyboardArrowDownIcon}
                                                            value={valuesGen.langVer}
                                                            label={t("miscellaneous.verbalCommunication")}
                                                            onChange={handleChange}
                                                            MenuProps={{
                                                                PaperProps: {
                                                                    sx: {
                                                                        mt: 0.7,
                                                                        borderRadius: 2,
                                                                        boxShadow:
                                                                            "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                                    },
                                                                },
                                                            }}
                                                        >
                                                            <MenuItem value={1}>{t("language.english")}</MenuItem>
                                                            <MenuItem value={2}>{t("language.spanish")}</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item lg={3} sm={6} xs={12}>
                                                    <FormControl
                                                        fullWidth
                                                        required
                                                        disabled={disabledData}
                                                        error={isError && valuesGen.langEsc === "" ? true : false}
                                                    >
                                                        <InputLabel id="esc-simple-select-label">
                                                            {t("miscellaneous.writtenCommunication")}
                                                        </InputLabel>
                                                        <Select
                                                            name="langEsc"
                                                            labelId="esc-simple-select-label"
                                                            IconComponent={KeyboardArrowDownIcon}
                                                            value={valuesGen.langEsc}
                                                            label={t("miscellaneous.writtenCommunication")}
                                                            onChange={handleChange}
                                                            MenuProps={{
                                                                PaperProps: {
                                                                    sx: {
                                                                        mt: 0.7,
                                                                        borderRadius: 2,
                                                                        boxShadow:
                                                                            "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                                    },
                                                                },
                                                            }}
                                                        >
                                                            <MenuItem value={1}>{t("language.english")}</MenuItem>
                                                            <MenuItem value={2}>{t("language.spanish")}</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </>
                            )}
                            {activeStep === 1 && (
                                <>
                                    <Card elevation={0}>
                                        <CardContent>
                                            <Grid container spacing={2}>
                                                <Grid item lg={4} sm={6} xs={12}>
                                                    <FormControl
                                                        fullWidth
                                                        required
                                                        error={isError1 && valuesFact.typeFact === "" ? true : false}
                                                    >
                                                        <InputLabel id="nivel-simple-select-label">
                                                            {t("miscellaneous.invoicingServicesTo")}
                                                        </InputLabel>
                                                        <Select
                                                            name="typeFact"
                                                            labelId="nivel-simple-select-label"
                                                            label={t("miscellaneous.invoicingServicesTo")}
                                                            IconComponent={KeyboardArrowDownIcon}
                                                            value={valuesFact.typeFact}
                                                            onChange={handleChangeTypeFact}
                                                            MenuProps={{
                                                                PaperProps: {
                                                                    sx: {
                                                                        mt: 0.7,
                                                                        borderRadius: 2,
                                                                        boxShadow:
                                                                            "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                                    },
                                                                },
                                                            }}
                                                            s
                                                        >
                                                            {valuesGen.clientType === 2 ||
                                                            (valuesGen.clientType === 1 &&
                                                                valuesGen.franchiseId === " ") ? (
                                                                <MenuItem value={2}>
                                                                    {t("miscellaneous.group")}
                                                                </MenuItem>
                                                            ) : (
                                                                <MenuItem value={3}>
                                                                    {t("miscellaneous.franchise")}
                                                                </MenuItem>
                                                            )}
                                                            {valuesGen.clientType === 2 ||
                                                            (valuesGen.clientType === 1 &&
                                                                valuesGen.franchiseId === " ") ? (
                                                                <MenuItem value={1}>
                                                                    {t("miscellaneous.client")}
                                                                </MenuItem>
                                                            ) : null}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item lg={4} sm={6} xs={12}>
                                                    <FormControl
                                                        fullWidth
                                                        required
                                                        error={isError1 && !valuesFact.subsInv}
                                                    >
                                                        <InputLabel id="subsInv-simple-select-label">
                                                            {t("invoicing.subsInv")}
                                                        </InputLabel>
                                                        <Select
                                                            name="subsInv"
                                                            labelId="subsInv-simple-select-label"
                                                            label={t("invoicing.subsInv")}
                                                            IconComponent={KeyboardArrowDownIcon}
                                                            value={valuesFact.subsInv}
                                                            onChange={handleChangeFact}
                                                            MenuProps={{
                                                                PaperProps: {
                                                                    sx: {
                                                                        mt: 0.7,
                                                                        borderRadius: 2,
                                                                        boxShadow:
                                                                            "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                                    },
                                                                },
                                                            }}
                                                        >
                                                            <MenuItem value={1}>GCA SC (Venezuela)</MenuItem>
                                                            <MenuItem value={2}>GCA INC (Estados Unidos)</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                    <Card elevation={0}>
                                        <CardHeader title={t("miscellaneous.contact")} sx={{ color: "#ff5968" }} />
                                        <Divider />
                                        <CardContent>
                                            <Grid container spacing={2}>
                                                <Grid item lg={4} sm={6} xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        required={requiredData}
                                                        label={t("miscellaneous.invoicingNameGCA")}
                                                        name="contactGCA"
                                                        onChange={handleChangeFact}
                                                        value={valuesFact.contactGCA}
                                                        variant="outlined"
                                                        error={
                                                            requiredData &&
                                                            isError1 &&
                                                            valuesFact.contactGCA.trim() === ""
                                                                ? true
                                                                : false
                                                        }
                                                        disabled={disabledData || disabledData2}
                                                    />
                                                </Grid>
                                                <Grid item lg={4} sm={6} xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        required={requiredData}
                                                        label={t("miscellaneous.contactName")}
                                                        name="contactFact"
                                                        onChange={handleChangeFact}
                                                        value={valuesFact.contactFact}
                                                        error={
                                                            requiredData &&
                                                            isError1 &&
                                                            valuesFact.contactFact.trim() === ""
                                                                ? true
                                                                : false
                                                        }
                                                        variant="outlined"
                                                        disabled={disabledData || disabledData2}
                                                    />
                                                </Grid>
                                                <Grid item lg={4} sm={6} xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        required={requiredData}
                                                        label={t("miscellaneous.contactEmail")}
                                                        name="emailCont"
                                                        onChange={handleChangeFact}
                                                        value={valuesFact.emailCont}
                                                        variant="outlined"
                                                        error={
                                                            requiredData && isError1 && !valuesFact.emailCont.trim()
                                                                ? true
                                                                : requiredData &&
                                                                  isError1 &&
                                                                  !/.+@.+\..+/.test(valuesFact.emailCont)
                                                        }
                                                        disabled={disabledData || disabledData2}
                                                    />
                                                </Grid>
                                                <Grid item lg={4} sm={6} xs={12}>
                                                    <FormControl fullWidth disabled={disabledData || disabledData2}>
                                                        <InputLabel htmlFor="invoicing-phone-number-mask-input">
                                                            {t("miscellaneous.phoneNumber")}
                                                        </InputLabel>
                                                        <OutlinedInput
                                                            id="invoicing-phone-number-mask-input"
                                                            name="phoneNumber"
                                                            label={t("miscellaneous.phoneNumber")}
                                                            value={valuesFact.phoneNumber}
                                                            onChange={handleChangePhone}
                                                            placeholder="e.g. +99 (999) 999-9999"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </>
                            )}
                            {activeStep === 2 && (
                                <>
                                    <Card elevation={0}>
                                        <CardHeader
                                            title={t("services.groupServices")}
                                            action={
                                                <FormControlLabel
                                                    disabled
                                                    control={
                                                        <Switch
                                                            checked={valuesSettings.anticipatedInvoice}
                                                            inputProps={{
                                                                "aria-label": "controlled",
                                                            }}
                                                            color="secondary"
                                                            onChange={handleChangeSwitchSettings}
                                                            sx={{
                                                                "& .MuiSwitch-switchBase .MuiSwitch-thumb": {
                                                                    color: "#FFF",
                                                                },
                                                                "& .MuiSwitch-switchBase + .MuiSwitch-track": {
                                                                    backgroundColor: "#031851",
                                                                },
                                                                "& .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb":
                                                                    {
                                                                        color: "#FF5968",
                                                                    },
                                                                "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                                                                    {
                                                                        backgroundColor: "#FF5968",
                                                                        opacity: 0.3,
                                                                    },
                                                                "& .MuiSwitch-switchBase.Mui-checked.Mui-disabled .MuiSwitch-thumb":
                                                                    {
                                                                        color: "#FF5968",
                                                                        opacity: 0.5,
                                                                    },
                                                                "& .MuiSwitch-switchBase.Mui-checked.Mui-disabled + .MuiSwitch-track":
                                                                    {
                                                                        backgroundColor: "#FF5968",
                                                                        opacity: 0.12,
                                                                    },
                                                            }}
                                                        />
                                                    }
                                                    label={
                                                        <Typography variant="body1" color="textPrimary">
                                                            {t("services.advanceInvoicing")}
                                                        </Typography>
                                                    }
                                                />
                                            }
                                            sx={{ color: "#ff5968" }}
                                        />
                                        <Divider />
                                        <CardContent>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} md={6}>
                                                    <Grid
                                                        container
                                                        alignItems="center"
                                                        sx={{
                                                            backgroundColor: "#F4F4F4",
                                                            p: valuesSettings.groupedSupport
                                                                ? "10px 20px"
                                                                : "10px 15px",
                                                            borderRadius: 4,
                                                        }}
                                                    >
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="body1"
                                                                color="primary"
                                                                sx={{ fontWeight: 700 }}
                                                            >
                                                                Customer Support
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={6}
                                                            sx={{
                                                                textAlign: "right",
                                                            }}
                                                        >
                                                            <LightTooltip
                                                                title={t("miscellaneous.groupTooltipOn")}
                                                                open={showTooltip}
                                                                onMouseEnter={() => {
                                                                    if (valuesSettings.groupedSupport) {
                                                                        setShowTooltip(true);
                                                                    }
                                                                }}
                                                                onMouseLeave={() => {
                                                                    if (valuesSettings.groupedSupport) {
                                                                        setShowTooltip(false);
                                                                    }
                                                                }}
                                                                disableHoverListener
                                                            >
                                                                <Switch
                                                                    inputProps={{
                                                                        "aria-label": "controlled",
                                                                    }}
                                                                    color="secondary"
                                                                    checked={valuesSettings.groupedSupport}
                                                                    onChange={handleChangeCheckGroupedSupport}
                                                                />
                                                            </LightTooltip>
                                                        </Grid>
                                                        {valuesSettings.groupedSupport && (
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                sm={6}
                                                                sx={{
                                                                    pr: { sm: 1 },
                                                                    pt: 2,
                                                                }}
                                                            >
                                                                <TextField
                                                                    fullWidth
                                                                    required
                                                                    label={t("miscellaneous.hours")}
                                                                    name="groupedSupportHours"
                                                                    onChange={handleChangeSettings}
                                                                    value={valuesSettings.groupedSupportHours}
                                                                    variant="outlined"
                                                                    type="number"
                                                                    inputProps={{
                                                                        min: 1,
                                                                        step: 1,
                                                                        onKeyDown: (event) => {
                                                                            event.preventDefault();
                                                                        },
                                                                    }}
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    error={
                                                                        isError2 &&
                                                                        valuesSettings.groupedSupport &&
                                                                        !valuesSettings.groupedSupportHours
                                                                    }
                                                                />
                                                            </Grid>
                                                        )}
                                                        {valuesSettings.groupedSupport && (
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                sm={6}
                                                                sx={{
                                                                    pl: { sm: 1 },
                                                                    pt: 2,
                                                                }}
                                                            >
                                                                <TextField
                                                                    fullWidth
                                                                    name="groupedSupportDiscount"
                                                                    label={t("services.discountRate")}
                                                                    value={valuesSettings.groupedSupportDiscount}
                                                                    onChange={handleChangeDiscount}
                                                                    onBlur={() =>
                                                                        setValuesSettings({
                                                                            ...valuesSettings,
                                                                            groupedSupportDiscount: parseFloat(
                                                                                valuesSettings.groupedSupportDiscount ===
                                                                                    ""
                                                                                    ? 0
                                                                                    : valuesSettings.groupedSupportDiscount
                                                                            ).toFixed(2),
                                                                        })
                                                                    }
                                                                    variant="outlined"
                                                                    InputProps={{
                                                                        endAdornment: (
                                                                            <InputAdornment position="end">
                                                                                %
                                                                            </InputAdornment>
                                                                        ),
                                                                        inputProps: {
                                                                            min: 0,
                                                                            max: 100,
                                                                            pattern: "[0-9]*",
                                                                        },
                                                                    }}
                                                                />
                                                            </Grid>
                                                        )}
                                                        {valuesSettings.groupedSupport && (
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                sm={6}
                                                                sx={{
                                                                    pr: { sm: 1 },
                                                                    pt: 2,
                                                                }}
                                                            >
                                                                <DatePicker
                                                                    componentsProps={{
                                                                        actionBar: {
                                                                            actions: ["clear"],
                                                                        },
                                                                    }}
                                                                    value={valuesSettings.custSuppinitDate}
                                                                    onChange={(newValue) => {
                                                                        handleChangeInitDate(newValue, 1);
                                                                    }}
                                                                    inputFormat={todos.dateFormat}
                                                                    renderInput={(params) => (
                                                                        <TextField
                                                                            fullWidth
                                                                            required
                                                                            {...params}
                                                                            error={
                                                                                isError2 &&
                                                                                !valuesSettings.custSuppinitDate
                                                                            }
                                                                        />
                                                                    )}
                                                                    inputProps={{
                                                                        readOnly: true,
                                                                    }}
                                                                    views={["year", "month"]}
                                                                    label={t("reports.initDate")}
                                                                />
                                                            </Grid>
                                                        )}
                                                        {valuesSettings.groupedSupport && (
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                sm={6}
                                                                sx={{
                                                                    pl: { sm: 1 },
                                                                    pt: 2,
                                                                }}
                                                            >
                                                                <DatePicker
                                                                    componentsProps={{
                                                                        actionBar: {
                                                                            actions: ["clear"],
                                                                        },
                                                                    }}
                                                                    value={valuesSettings.custSuppEndDate}
                                                                    onChange={(newValue) => {
                                                                        handleChangeEndDate(newValue, 1);
                                                                    }}
                                                                    inputFormat={todos.dateFormat}
                                                                    renderInput={(params) => (
                                                                        <TextField fullWidth {...params} />
                                                                    )}
                                                                    inputProps={{
                                                                        readOnly: true,
                                                                    }}
                                                                    views={["year", "month"]}
                                                                    label={t("reports.endDate")}
                                                                />
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <Grid
                                                        container
                                                        alignItems="center"
                                                        sx={{
                                                            backgroundColor: "#F4F4F4",
                                                            p: valuesSettings.groupedSupport
                                                                ? "10px 20px"
                                                                : "10px 15px",
                                                            borderRadius: 4,
                                                        }}
                                                    >
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="body1"
                                                                color="primary"
                                                                sx={{ fontWeight: 700 }}
                                                            >
                                                                {t("invoicing.kiiperBanking")}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={6}
                                                            sx={{
                                                                textAlign: "right",
                                                            }}
                                                        >
                                                            <LightTooltip
                                                                title={t("miscellaneous.groupTooltipOn")}
                                                                open={showTooltip2}
                                                                onMouseEnter={() => {
                                                                    if (valuesSettings.kiiperBanking) {
                                                                        setShowTooltip2(true);
                                                                    }
                                                                }}
                                                                onMouseLeave={() => {
                                                                    if (valuesSettings.kiiperBanking) {
                                                                        setShowTooltip2(false);
                                                                    }
                                                                }}
                                                                disableHoverListener
                                                            >
                                                                <Switch
                                                                    inputProps={{
                                                                        "aria-label": "controlled",
                                                                    }}
                                                                    color="secondary"
                                                                    checked={valuesSettings.kiiperBanking}
                                                                    onChange={handleChangeCheckKiiperBanking}
                                                                />
                                                            </LightTooltip>
                                                        </Grid>
                                                        {valuesSettings.kiiperBanking && (
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                sm={6}
                                                                sx={{
                                                                    pr: { sm: 1 },
                                                                    pt: 2,
                                                                }}
                                                            >
                                                                <FormControl
                                                                    fullWidth
                                                                    required
                                                                    error={
                                                                        isError2 &&
                                                                        valuesSettings.kiiperBanking &&
                                                                        !valuesSettings.version
                                                                    }
                                                                >
                                                                    <InputLabel id="ver-simple-select-label">
                                                                        {t("services.version")}
                                                                    </InputLabel>
                                                                    <Select
                                                                        name="version"
                                                                        labelId="ver-simple-select-label"
                                                                        IconComponent={KeyboardArrowDownIcon}
                                                                        value={valuesSettings.version}
                                                                        label={t("services.version")}
                                                                        onChange={handleChangeSettings}
                                                                        MenuProps={{
                                                                            PaperProps: {
                                                                                sx: {
                                                                                    mt: 0.7,
                                                                                    borderRadius: 2,
                                                                                    boxShadow:
                                                                                        "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                                                },
                                                                            },
                                                                        }}
                                                                    >
                                                                        <MenuItem value={"Plan 5"}>Plan 5</MenuItem>
                                                                        <MenuItem value={"Plan 15"}>Plan 15</MenuItem>
                                                                        <MenuItem value={"Plan 30"}>Plan 30</MenuItem>
                                                                        <MenuItem value={"Plan 50"}>Plan 50</MenuItem>
                                                                        <MenuItem value={"Plan 200"}>Plan 200</MenuItem>
                                                                        <MenuItem value={"Plan 500"}>Plan 500</MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                            </Grid>
                                                        )}
                                                        {valuesSettings.kiiperBanking && (
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                sm={6}
                                                                sx={{
                                                                    pl: { sm: 1 },
                                                                    pt: 2,
                                                                }}
                                                            >
                                                                <TextField
                                                                    fullWidth
                                                                    name="kiiperBankingDiscount"
                                                                    label={t("services.discountRate")}
                                                                    value={valuesSettings.kiiperBankingDiscount}
                                                                    onChange={handleChangeDiscount}
                                                                    onBlur={() =>
                                                                        setValuesSettings({
                                                                            ...valuesSettings,
                                                                            kiiperBankingDiscount: parseFloat(
                                                                                valuesSettings.kiiperBankingDiscount ===
                                                                                    ""
                                                                                    ? 0
                                                                                    : valuesSettings.kiiperBankingDiscount
                                                                            ).toFixed(2),
                                                                        })
                                                                    }
                                                                    variant="outlined"
                                                                    InputProps={{
                                                                        endAdornment: (
                                                                            <InputAdornment position="end">
                                                                                %
                                                                            </InputAdornment>
                                                                        ),
                                                                        inputProps: {
                                                                            min: 0,
                                                                            max: 100,
                                                                            pattern: "[0-9]*",
                                                                        },
                                                                    }}
                                                                />
                                                            </Grid>
                                                        )}
                                                        {valuesSettings.kiiperBanking && (
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                sm={6}
                                                                sx={{
                                                                    pr: { sm: 1 },
                                                                    pt: 2,
                                                                }}
                                                            >
                                                                <DatePicker
                                                                    componentsProps={{
                                                                        actionBar: {
                                                                            actions: ["clear"],
                                                                        },
                                                                    }}
                                                                    value={valuesSettings.kiipBankinitDate}
                                                                    onChange={(newValue) => {
                                                                        handleChangeInitDate(newValue, 2);
                                                                    }}
                                                                    inputFormat={todos.dateFormat}
                                                                    renderInput={(params) => (
                                                                        <TextField
                                                                            fullWidth
                                                                            required
                                                                            {...params}
                                                                            error={
                                                                                isError2 &&
                                                                                !valuesSettings.kiipBankinitDate
                                                                            }
                                                                        />
                                                                    )}
                                                                    inputProps={{
                                                                        readOnly: true,
                                                                    }}
                                                                    views={["year", "month"]}
                                                                    label={t("reports.initDate")}
                                                                />
                                                            </Grid>
                                                        )}
                                                        {valuesSettings.kiiperBanking && (
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                sm={6}
                                                                sx={{
                                                                    pl: { sm: 1 },
                                                                    pt: 2,
                                                                }}
                                                            >
                                                                <DatePicker
                                                                    componentsProps={{
                                                                        actionBar: {
                                                                            actions: ["clear"],
                                                                        },
                                                                    }}
                                                                    value={valuesSettings.kiipBankEndDate}
                                                                    onChange={(newValue) => {
                                                                        handleChangeEndDate(newValue, 2);
                                                                    }}
                                                                    inputFormat={todos.dateFormat}
                                                                    renderInput={(params) => (
                                                                        <TextField fullWidth {...params} />
                                                                    )}
                                                                    inputProps={{
                                                                        readOnly: true,
                                                                    }}
                                                                    views={["year", "month"]}
                                                                    label={t("reports.endDate")}
                                                                />
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                    <Card elevation={0}>
                                        <CardHeader
                                            title={t("services.generalConsiderations")}
                                            sx={{ color: "#ff5968" }}
                                        />
                                        <Divider />
                                        <CardContent>
                                            <Grid container spacing={2} sx={{ pb: 2 }}>
                                                <Grid item lg={3} sm={6} xs={12}>
                                                    <LightTooltip title={t("miscellaneous.manageReqTool")}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    name="manageReq"
                                                                    checked={valuesSettings.manageReq}
                                                                    onChange={handleChangeCheckManageReq}
                                                                />
                                                            }
                                                            label={t("miscellaneous.manageReq")}
                                                        />
                                                    </LightTooltip>
                                                </Grid>
                                                <Grid item lg={3} sm={6} xs={12}>
                                                    <FormControlLabel
                                                        disabled={disabledData}
                                                        control={
                                                            <Checkbox
                                                                name="clientVip"
                                                                checked={valuesSettings.clientVip}
                                                                onChange={handleChangeCheckClientVip}
                                                            />
                                                        }
                                                        label={t("miscellaneous.clientVip")}
                                                    />
                                                </Grid>
                                            </Grid>
                                            {valuesGen.clientType !== 2 && (
                                                <Grid container spacing={2} sx={{ pb: 2 }}>
                                                    <Grid item lg={3} sm={6} xs={12}>
                                                        <FormControl
                                                            fullWidth
                                                            required
                                                            disabled={disabledData}
                                                            error={
                                                                isError2 && valuesSettings.tariff === "" ? true : false
                                                            }
                                                        >
                                                            <InputLabel id="tariff-simple-select-label">
                                                                {t("services.baseRate")}
                                                            </InputLabel>
                                                            <Select
                                                                name="tariff"
                                                                labelId="tariff-simple-select-label"
                                                                label={t("services.baseRate")}
                                                                value={valuesSettings.tariff}
                                                                onChange={handleChangeSettings}
                                                                IconComponent={KeyboardArrowDownIcon}
                                                                MenuProps={{
                                                                    PaperProps: {
                                                                        sx: {
                                                                            mt: 0.7,
                                                                            borderRadius: 2,
                                                                            boxShadow:
                                                                                "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                                        },
                                                                    },
                                                                }}
                                                            >
                                                                <MenuItem value={0}>$0</MenuItem>
                                                                <MenuItem value={5}>$5</MenuItem>
                                                                <MenuItem value={10}>$10</MenuItem>
                                                                <MenuItem value={15}>$15</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item lg={3} sm={6} xs={12}>
                                                        <FormControl
                                                            fullWidth
                                                            required
                                                            disabled={disabledData}
                                                            error={
                                                                isError2 && valuesSettings.tariffAdditionals === ""
                                                                    ? true
                                                                    : false
                                                            }
                                                        >
                                                            <InputLabel id="tariffAdditionals-simple-select-label">
                                                                {t("services.additionalsRate")}
                                                            </InputLabel>
                                                            <Select
                                                                name="tariffAdditionals"
                                                                labelId="tariffAdditionals-simple-select-label"
                                                                label={t("services.additionalsRate")}
                                                                value={valuesSettings.tariffAdditionals}
                                                                onChange={handleChangeSettings}
                                                                IconComponent={KeyboardArrowDownIcon}
                                                                MenuProps={{
                                                                    PaperProps: {
                                                                        sx: {
                                                                            mt: 0.7,
                                                                            borderRadius: 2,
                                                                            boxShadow:
                                                                                "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                                        },
                                                                    },
                                                                }}
                                                            >
                                                                <MenuItem value={15}>$15</MenuItem>
                                                                <MenuItem value={25}>$25</MenuItem>
                                                                <MenuItem value={30}>$30</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            )}
                                            <Grid container spacing={2}>
                                                <Grid item lg={3} sm={6} xs={12}>
                                                    <LightTooltip title={t("services.receptionDaysMsg")}>
                                                        <TextField
                                                            fullWidth
                                                            required
                                                            name="eeccReceptionDays"
                                                            label={t("services.receptionDays")}
                                                            value={valuesSettings.eeccReceptionDays}
                                                            onChange={handleChangeReceptionDays}
                                                            variant="outlined"
                                                            type="number"
                                                            InputProps={{
                                                                inputProps: {
                                                                    min: 1,
                                                                    max: 15,
                                                                },
                                                            }}
                                                            error={
                                                                isError2 && valuesSettings.eeccReceptionDays === ""
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                    </LightTooltip>
                                                </Grid>
                                                {valuesSettings.clientVip && (
                                                    <Grid item lg={3} sm={6} xs={12}>
                                                        <LightTooltip title={t("services.monthlyReportsMsg")}>
                                                            <TextField
                                                                fullWidth
                                                                required={valuesSettings.clientVip}
                                                                name="monthlyVipReportDate"
                                                                label={t("services.vipMonthlyReportDate")}
                                                                value={valuesSettings.monthlyVipReportDate}
                                                                onChange={handleChangeMonthlyReports}
                                                                variant="outlined"
                                                                type="number"
                                                                InputProps={{
                                                                    inputProps: {
                                                                        min: 1,
                                                                        max: 25,
                                                                    },
                                                                }}
                                                                error={
                                                                    isError2 &&
                                                                    valuesSettings.clientVip &&
                                                                    valuesSettings.monthlyVipReportDate === ""
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                        </LightTooltip>
                                                    </Grid>
                                                )}
                                                <Grid item lg={3} sm={6} xs={12}>
                                                    <LightTooltip title={t("services.monthlyReportsMsg")}>
                                                        <TextField
                                                            fullWidth
                                                            required
                                                            name="monthlyStandardReportDate"
                                                            label={t("services.stdMonthlyReportDate")}
                                                            value={valuesSettings.monthlyStandardReportDate}
                                                            onChange={handleChangeMonthlyReports}
                                                            variant="outlined"
                                                            type="number"
                                                            InputProps={{
                                                                inputProps: {
                                                                    min: 1,
                                                                    max: 25,
                                                                },
                                                            }}
                                                            error={
                                                                isError2 &&
                                                                valuesSettings.monthlyStandardReportDate === ""
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                    </LightTooltip>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </>
                            )}
                            {activeStep === 3 && (
                                <>
                                    <Card elevation={0}>
                                        <CardHeader title={t("miscellaneous.clientStaff")} sx={{ color: "#ff5968" }} />
                                        <Divider />
                                        <CardContent sx={{ pb: 5 }}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} md={6}>
                                                    <Card
                                                        sx={{
                                                            borderRadius: "20px",
                                                            backgroundColor: "#F8F9F8",
                                                            boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                        }}
                                                    >
                                                        <CardContent
                                                            sx={{
                                                                p: "24px 24px 16px !important",
                                                            }}
                                                        >
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={12} lg={4}>
                                                                    <AccountCircle className={classes.avatarImage} />
                                                                    <Typography
                                                                        variant="h2"
                                                                        color="primary"
                                                                        align="center"
                                                                        sx={{
                                                                            pt: 1,
                                                                        }}
                                                                    >
                                                                        {`${t("miscellaneous.contact")} 1`}
                                                                    </Typography>
                                                                    <LightTooltip title={t("recurrent.clear")}>
                                                                        <IconButton
                                                                            aria-label="clear"
                                                                            color="primary"
                                                                            onClick={(event) =>
                                                                                clearTeamStaff(event, "teamCompany1")
                                                                            }
                                                                            sx={{
                                                                                display: "block",
                                                                                m: "auto",
                                                                            }}
                                                                        >
                                                                            <ClearAllIcon />
                                                                        </IconButton>
                                                                    </LightTooltip>
                                                                </Grid>
                                                                <Grid item xs={12} lg={8}>
                                                                    <TextField
                                                                        fullWidth
                                                                        required
                                                                        label={t("miscellaneous.name")}
                                                                        name="contact_name"
                                                                        onChange={(event) =>
                                                                            handleChangeTeamStaff(event, "teamCompany1")
                                                                        }
                                                                        value={valueStaff.teamCompany1.contact_name}
                                                                        variant="outlined"
                                                                        error={
                                                                            isError3 &&
                                                                            valueStaff.teamCompany1.contact_name.trim() ===
                                                                                ""
                                                                                ? true
                                                                                : false
                                                                        }
                                                                    />
                                                                    <TextField
                                                                        fullWidth
                                                                        required
                                                                        label={t("miscellaneous.email")}
                                                                        name="contact_email"
                                                                        onChange={(event) =>
                                                                            handleChangeTeamStaff(event, "teamCompany1")
                                                                        }
                                                                        value={valueStaff.teamCompany1.contact_email}
                                                                        variant="outlined"
                                                                        error={
                                                                            isError3 &&
                                                                            valueStaff.teamCompany1.contact_email.trim() ===
                                                                                ""
                                                                                ? true
                                                                                : isError3 &&
                                                                                  !/.+@.+\..+/.test(
                                                                                      valueStaff.teamCompany1.contact_email.trim()
                                                                                  )
                                                                                ? true
                                                                                : false
                                                                        }
                                                                        sx={{
                                                                            mt: 2,
                                                                        }}
                                                                    />
                                                                    <TextField
                                                                        fullWidth
                                                                        required
                                                                        label={t("miscellaneous.role")}
                                                                        name="contact_role"
                                                                        onChange={(event) =>
                                                                            handleChangeTeamStaff(event, "teamCompany1")
                                                                        }
                                                                        value={valueStaff.teamCompany1.contact_role}
                                                                        variant="outlined"
                                                                        error={
                                                                            isError3 &&
                                                                            valueStaff.teamCompany1.contact_role.trim() ===
                                                                                ""
                                                                                ? true
                                                                                : false
                                                                        }
                                                                        sx={{
                                                                            mt: 2,
                                                                        }}
                                                                    />
                                                                    <FormGroup
                                                                        sx={{
                                                                            mt: 2,
                                                                            display: "block",
                                                                        }}
                                                                    >
                                                                        <FormLabel id="permissions-checkboxes-group-label">
                                                                            <Typography
                                                                                variant="h4"
                                                                                color="primary"
                                                                                sx={{
                                                                                    pb: 1,
                                                                                }}
                                                                            >
                                                                                {t("miscellaneous.permissions")}:
                                                                            </Typography>
                                                                        </FormLabel>
                                                                        <LightTooltip
                                                                            title={t("services.toolRequirements")}
                                                                            aria-label="req"
                                                                        >
                                                                            <Checkbox
                                                                                icon={
                                                                                    <AssignmentTurnedInOutlinedIcon />
                                                                                }
                                                                                checkedIcon={<AssignmentTurnedInIcon />}
                                                                                checked={
                                                                                    valueStaff.teamCompany1
                                                                                        .contact_requirements
                                                                                }
                                                                                onChange={(event) =>
                                                                                    handleChangeCheck(
                                                                                        event,
                                                                                        3,
                                                                                        "teamCompany1"
                                                                                    )
                                                                                }
                                                                                sx={{
                                                                                    ml: {
                                                                                        xs: 0,
                                                                                        lg: 0,
                                                                                        xl: 0,
                                                                                    },
                                                                                    mr: {
                                                                                        xs: 1,
                                                                                        lg: 0,
                                                                                        xl: 1,
                                                                                    },
                                                                                }}
                                                                            />
                                                                        </LightTooltip>
                                                                        <LightTooltip
                                                                            title={t("services.toolAccountingClosures")}
                                                                            aria-label="cierres"
                                                                        >
                                                                            <Checkbox
                                                                                icon={<LockOutlinedIcon />}
                                                                                checkedIcon={<LockIcon />}
                                                                                checked={
                                                                                    valueStaff.teamCompany1
                                                                                        .contact_closure
                                                                                }
                                                                                onChange={(event) =>
                                                                                    handleChangeCheck(
                                                                                        event,
                                                                                        0,
                                                                                        "teamCompany1"
                                                                                    )
                                                                                }
                                                                                sx={{
                                                                                    ml: {
                                                                                        xs: 1,
                                                                                        lg: 0,
                                                                                        xl: 1,
                                                                                    },
                                                                                    mr: {
                                                                                        xs: 1,
                                                                                        lg: 0,
                                                                                        xl: 1,
                                                                                    },
                                                                                }}
                                                                            />
                                                                        </LightTooltip>
                                                                        <LightTooltip
                                                                            title={t("services.toolStatus")}
                                                                            aria-label="status"
                                                                        >
                                                                            <Checkbox
                                                                                icon={<InsertChartOutlinedIcon />}
                                                                                checkedIcon={<InsertChartIcon />}
                                                                                checked={
                                                                                    valueStaff.teamCompany1
                                                                                        .contact_status
                                                                                }
                                                                                onChange={(event) =>
                                                                                    handleChangeCheck(
                                                                                        event,
                                                                                        1,
                                                                                        "teamCompany1"
                                                                                    )
                                                                                }
                                                                                sx={{
                                                                                    ml: {
                                                                                        xs: 1,
                                                                                        lg: 0,
                                                                                        xl: 1,
                                                                                    },
                                                                                    mr: {
                                                                                        xs: 1,
                                                                                        lg: 0,
                                                                                        xl: 1,
                                                                                    },
                                                                                }}
                                                                            />
                                                                        </LightTooltip>
                                                                        <LightTooltip
                                                                            title={t("services.toolInvoicing")}
                                                                            aria-label="invoicing"
                                                                        >
                                                                            <Checkbox
                                                                                icon={<CreditCardOutlinedIcon />}
                                                                                checkedIcon={
                                                                                    <CreditCardIcon className="icon-normal-size" />
                                                                                }
                                                                                checked={
                                                                                    valueStaff.teamCompany1
                                                                                        .contact_invoices
                                                                                }
                                                                                onChange={(event) =>
                                                                                    handleChangeCheck(
                                                                                        event,
                                                                                        4,
                                                                                        "teamCompany1"
                                                                                    )
                                                                                }
                                                                                sx={{
                                                                                    ml: {
                                                                                        xs: 1,
                                                                                        lg: 0,
                                                                                        xl: 1,
                                                                                    },
                                                                                    mr: {
                                                                                        xs: 1,
                                                                                        lg: 0,
                                                                                        xl: 1,
                                                                                    },
                                                                                }}
                                                                            />
                                                                        </LightTooltip>
                                                                    </FormGroup>
                                                                </Grid>
                                                            </Grid>
                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <Card
                                                        sx={{
                                                            borderRadius: "20px",
                                                            backgroundColor: "#F8F9F8",
                                                            boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                        }}
                                                    >
                                                        <CardContent
                                                            sx={{
                                                                p: "24px 24px 16px !important",
                                                            }}
                                                        >
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={12} lg={4}>
                                                                    <AccountCircle className={classes.avatarImage} />
                                                                    <Typography
                                                                        variant="h2"
                                                                        color="primary"
                                                                        align="center"
                                                                        sx={{
                                                                            pt: 1,
                                                                        }}
                                                                    >
                                                                        {`${t("miscellaneous.contact")} 2`}
                                                                    </Typography>
                                                                    <LightTooltip title={t("recurrent.clear")}>
                                                                        <IconButton
                                                                            aria-label="clear"
                                                                            color="primary"
                                                                            onClick={(event) =>
                                                                                clearTeamStaff(event, "teamCompany2")
                                                                            }
                                                                            sx={{
                                                                                display: "block",
                                                                                m: "auto",
                                                                            }}
                                                                        >
                                                                            <ClearAllIcon />
                                                                        </IconButton>
                                                                    </LightTooltip>
                                                                </Grid>
                                                                <Grid item xs={12} lg={8}>
                                                                    <TextField
                                                                        fullWidth
                                                                        required={isRequired2}
                                                                        label={t("miscellaneous.name")}
                                                                        name="contact_name"
                                                                        onChange={(event) =>
                                                                            handleChangeTeamStaff(event, "teamCompany2")
                                                                        }
                                                                        value={valueStaff.teamCompany2.contact_name}
                                                                        variant="outlined"
                                                                        error={
                                                                            isRequired2 &&
                                                                            isError3 &&
                                                                            valueStaff.teamCompany2.contact_name.trim() ===
                                                                                ""
                                                                                ? true
                                                                                : false
                                                                        }
                                                                    />
                                                                    <TextField
                                                                        fullWidth
                                                                        required={isRequired2}
                                                                        label={t("miscellaneous.email")}
                                                                        name="contact_email"
                                                                        onChange={(event) =>
                                                                            handleChangeTeamStaff(event, "teamCompany2")
                                                                        }
                                                                        value={valueStaff.teamCompany2.contact_email}
                                                                        variant="outlined"
                                                                        error={
                                                                            isRequired2 &&
                                                                            isError3 &&
                                                                            valueStaff.teamCompany2.contact_email.trim() ===
                                                                                ""
                                                                                ? true
                                                                                : isRequired2 &&
                                                                                  isError3 &&
                                                                                  !/.+@.+\..+/.test(
                                                                                      valueStaff.teamCompany2.contact_email.trim()
                                                                                  )
                                                                                ? true
                                                                                : false
                                                                        }
                                                                        sx={{
                                                                            mt: 2,
                                                                        }}
                                                                    />
                                                                    <TextField
                                                                        fullWidth
                                                                        required={isRequired2}
                                                                        label={t("miscellaneous.role")}
                                                                        name="contact_role"
                                                                        onChange={(event) =>
                                                                            handleChangeTeamStaff(event, "teamCompany2")
                                                                        }
                                                                        value={valueStaff.teamCompany2.contact_role}
                                                                        variant="outlined"
                                                                        error={
                                                                            isRequired2 &&
                                                                            isError3 &&
                                                                            valueStaff.teamCompany2.contact_role.trim() ===
                                                                                ""
                                                                                ? true
                                                                                : false
                                                                        }
                                                                        sx={{
                                                                            mt: 2,
                                                                        }}
                                                                    />
                                                                    <FormGroup
                                                                        sx={{
                                                                            mt: 2,
                                                                            display: "block",
                                                                        }}
                                                                    >
                                                                        <FormLabel id="permissions-checkboxes-group-label">
                                                                            <Typography
                                                                                variant="h4"
                                                                                color="primary"
                                                                                sx={{
                                                                                    pb: 1,
                                                                                }}
                                                                            >
                                                                                {t("miscellaneous.permissions")}:
                                                                            </Typography>
                                                                        </FormLabel>
                                                                        <LightTooltip
                                                                            title={t("services.toolRequirements")}
                                                                            aria-label="req"
                                                                        >
                                                                            <Checkbox
                                                                                icon={
                                                                                    <AssignmentTurnedInOutlinedIcon />
                                                                                }
                                                                                checkedIcon={<AssignmentTurnedInIcon />}
                                                                                checked={
                                                                                    valueStaff.teamCompany2
                                                                                        .contact_requirements
                                                                                }
                                                                                onChange={(event) =>
                                                                                    handleChangeCheck(
                                                                                        event,
                                                                                        3,
                                                                                        "teamCompany2"
                                                                                    )
                                                                                }
                                                                                sx={{
                                                                                    ml: {
                                                                                        xs: 0,
                                                                                        lg: 0,
                                                                                        xl: 0,
                                                                                    },
                                                                                    mr: {
                                                                                        xs: 1,
                                                                                        lg: 0,
                                                                                        xl: 1,
                                                                                    },
                                                                                }}
                                                                            />
                                                                        </LightTooltip>
                                                                        <LightTooltip
                                                                            title={t("services.toolAccountingClosures")}
                                                                            aria-label="cierres"
                                                                        >
                                                                            <Checkbox
                                                                                icon={<LockOutlinedIcon />}
                                                                                checkedIcon={<LockIcon />}
                                                                                checked={
                                                                                    valueStaff.teamCompany2
                                                                                        .contact_closure
                                                                                }
                                                                                onChange={(event) =>
                                                                                    handleChangeCheck(
                                                                                        event,
                                                                                        0,
                                                                                        "teamCompany2"
                                                                                    )
                                                                                }
                                                                                sx={{
                                                                                    ml: {
                                                                                        xs: 1,
                                                                                        lg: 0,
                                                                                        xl: 1,
                                                                                    },
                                                                                    mr: {
                                                                                        xs: 1,
                                                                                        lg: 0,
                                                                                        xl: 1,
                                                                                    },
                                                                                }}
                                                                            />
                                                                        </LightTooltip>
                                                                        <LightTooltip
                                                                            title={t("services.toolStatus")}
                                                                            aria-label="status"
                                                                        >
                                                                            <Checkbox
                                                                                icon={<InsertChartOutlinedIcon />}
                                                                                checkedIcon={<InsertChartIcon />}
                                                                                checked={
                                                                                    valueStaff.teamCompany2
                                                                                        .contact_status
                                                                                }
                                                                                onChange={(event) =>
                                                                                    handleChangeCheck(
                                                                                        event,
                                                                                        1,
                                                                                        "teamCompany2"
                                                                                    )
                                                                                }
                                                                                sx={{
                                                                                    ml: {
                                                                                        xs: 1,
                                                                                        lg: 0,
                                                                                        xl: 1,
                                                                                    },
                                                                                    mr: {
                                                                                        xs: 1,
                                                                                        lg: 0,
                                                                                        xl: 1,
                                                                                    },
                                                                                }}
                                                                            />
                                                                        </LightTooltip>
                                                                        <LightTooltip
                                                                            title={t("services.toolInvoicing")}
                                                                            aria-label="invoicing"
                                                                        >
                                                                            <Checkbox
                                                                                icon={<CreditCardOutlinedIcon />}
                                                                                checkedIcon={
                                                                                    <CreditCardIcon className="icon-normal-size" />
                                                                                }
                                                                                checked={
                                                                                    valueStaff.teamCompany2
                                                                                        .contact_invoices
                                                                                }
                                                                                onChange={(event) =>
                                                                                    handleChangeCheck(
                                                                                        event,
                                                                                        4,
                                                                                        "teamCompany2"
                                                                                    )
                                                                                }
                                                                                sx={{
                                                                                    ml: {
                                                                                        xs: 1,
                                                                                        lg: 0,
                                                                                        xl: 1,
                                                                                    },
                                                                                    mr: {
                                                                                        xs: 1,
                                                                                        lg: 0,
                                                                                        xl: 1,
                                                                                    },
                                                                                }}
                                                                            />
                                                                        </LightTooltip>
                                                                    </FormGroup>
                                                                </Grid>
                                                            </Grid>
                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <Card
                                                        sx={{
                                                            borderRadius: "20px",
                                                            backgroundColor: "#F8F9F8",
                                                            boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                        }}
                                                    >
                                                        <CardContent
                                                            sx={{
                                                                p: "24px 24px 16px !important",
                                                            }}
                                                        >
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={12} lg={4}>
                                                                    <AccountCircle className={classes.avatarImage} />
                                                                    <Typography
                                                                        variant="h2"
                                                                        color="primary"
                                                                        align="center"
                                                                        sx={{
                                                                            pt: 1,
                                                                        }}
                                                                    >
                                                                        {`${t("miscellaneous.contact")} 3`}
                                                                    </Typography>
                                                                    <LightTooltip title={t("recurrent.clear")}>
                                                                        <IconButton
                                                                            aria-label="clear"
                                                                            color="primary"
                                                                            onClick={(event) =>
                                                                                clearTeamStaff(event, "teamCompany3")
                                                                            }
                                                                            sx={{
                                                                                display: "block",
                                                                                m: "auto",
                                                                            }}
                                                                        >
                                                                            <ClearAllIcon />
                                                                        </IconButton>
                                                                    </LightTooltip>
                                                                </Grid>
                                                                <Grid item xs={12} lg={8}>
                                                                    <TextField
                                                                        fullWidth
                                                                        required={isRequired3}
                                                                        label={t("miscellaneous.name")}
                                                                        name="contact_name"
                                                                        onChange={(event) =>
                                                                            handleChangeTeamStaff(event, "teamCompany3")
                                                                        }
                                                                        value={valueStaff.teamCompany3.contact_name}
                                                                        variant="outlined"
                                                                        error={
                                                                            isRequired3 &&
                                                                            isError3 &&
                                                                            valueStaff.teamCompany3.contact_name.trim() ===
                                                                                ""
                                                                                ? true
                                                                                : false
                                                                        }
                                                                    />
                                                                    <TextField
                                                                        fullWidth
                                                                        required={isRequired3}
                                                                        label={t("miscellaneous.email")}
                                                                        name="contact_email"
                                                                        onChange={(event) =>
                                                                            handleChangeTeamStaff(event, "teamCompany3")
                                                                        }
                                                                        value={valueStaff.teamCompany3.contact_email}
                                                                        variant="outlined"
                                                                        error={
                                                                            isRequired3 &&
                                                                            isError3 &&
                                                                            valueStaff.teamCompany3.contact_email.trim() ===
                                                                                ""
                                                                                ? true
                                                                                : isRequired3 &&
                                                                                  isError3 &&
                                                                                  !/.+@.+\..+/.test(
                                                                                      valueStaff.teamCompany3.contact_email.trim()
                                                                                  )
                                                                                ? true
                                                                                : false
                                                                        }
                                                                        sx={{
                                                                            mt: 2,
                                                                        }}
                                                                    />
                                                                    <TextField
                                                                        fullWidth
                                                                        required={isRequired3}
                                                                        label={t("miscellaneous.role")}
                                                                        name="contact_role"
                                                                        onChange={(event) =>
                                                                            handleChangeTeamStaff(event, "teamCompany3")
                                                                        }
                                                                        value={valueStaff.teamCompany3.contact_role}
                                                                        variant="outlined"
                                                                        error={
                                                                            isRequired3 &&
                                                                            isError3 &&
                                                                            valueStaff.teamCompany3.contact_role.trim() ===
                                                                                ""
                                                                                ? true
                                                                                : false
                                                                        }
                                                                        sx={{
                                                                            mt: 2,
                                                                        }}
                                                                    />
                                                                    <FormGroup
                                                                        sx={{
                                                                            mt: 2,
                                                                            display: "block",
                                                                        }}
                                                                    >
                                                                        <FormLabel id="permissions-checkboxes-group-label">
                                                                            <Typography
                                                                                variant="h4"
                                                                                color="primary"
                                                                                sx={{
                                                                                    pb: 1,
                                                                                }}
                                                                            >
                                                                                {t("miscellaneous.permissions")}:
                                                                            </Typography>
                                                                        </FormLabel>
                                                                        <LightTooltip
                                                                            title={t("services.toolRequirements")}
                                                                            aria-label="req"
                                                                        >
                                                                            <Checkbox
                                                                                icon={
                                                                                    <AssignmentTurnedInOutlinedIcon />
                                                                                }
                                                                                checkedIcon={<AssignmentTurnedInIcon />}
                                                                                checked={
                                                                                    valueStaff.teamCompany3
                                                                                        .contact_requirements
                                                                                }
                                                                                onChange={(event) =>
                                                                                    handleChangeCheck(
                                                                                        event,
                                                                                        3,
                                                                                        "teamCompany3"
                                                                                    )
                                                                                }
                                                                                sx={{
                                                                                    ml: {
                                                                                        xs: 0,
                                                                                        lg: 0,
                                                                                        xl: 0,
                                                                                    },
                                                                                    mr: {
                                                                                        xs: 1,
                                                                                        lg: 0,
                                                                                        xl: 1,
                                                                                    },
                                                                                }}
                                                                            />
                                                                        </LightTooltip>
                                                                        <LightTooltip
                                                                            title={t("services.toolAccountingClosures")}
                                                                            aria-label="cierres"
                                                                        >
                                                                            <Checkbox
                                                                                icon={<LockOutlinedIcon />}
                                                                                checkedIcon={<LockIcon />}
                                                                                checked={
                                                                                    valueStaff.teamCompany3
                                                                                        .contact_closure
                                                                                }
                                                                                onChange={(event) =>
                                                                                    handleChangeCheck(
                                                                                        event,
                                                                                        0,
                                                                                        "teamCompany3"
                                                                                    )
                                                                                }
                                                                                sx={{
                                                                                    ml: {
                                                                                        xs: 1,
                                                                                        lg: 0,
                                                                                        xl: 1,
                                                                                    },
                                                                                    mr: {
                                                                                        xs: 1,
                                                                                        lg: 0,
                                                                                        xl: 1,
                                                                                    },
                                                                                }}
                                                                            />
                                                                        </LightTooltip>
                                                                        <LightTooltip
                                                                            title={t("services.toolStatus")}
                                                                            aria-label="status"
                                                                        >
                                                                            <Checkbox
                                                                                icon={<InsertChartOutlinedIcon />}
                                                                                checkedIcon={<InsertChartIcon />}
                                                                                checked={
                                                                                    valueStaff.teamCompany3
                                                                                        .contact_status
                                                                                }
                                                                                onChange={(event) =>
                                                                                    handleChangeCheck(
                                                                                        event,
                                                                                        1,
                                                                                        "teamCompany3"
                                                                                    )
                                                                                }
                                                                                sx={{
                                                                                    ml: {
                                                                                        xs: 1,
                                                                                        lg: 0,
                                                                                        xl: 1,
                                                                                    },
                                                                                    mr: {
                                                                                        xs: 1,
                                                                                        lg: 0,
                                                                                        xl: 1,
                                                                                    },
                                                                                }}
                                                                            />
                                                                        </LightTooltip>
                                                                        <LightTooltip
                                                                            title={t("services.toolInvoicing")}
                                                                            aria-label="invoicing"
                                                                        >
                                                                            <Checkbox
                                                                                icon={<CreditCardOutlinedIcon />}
                                                                                checkedIcon={
                                                                                    <CreditCardIcon className="icon-normal-size" />
                                                                                }
                                                                                checked={
                                                                                    valueStaff.teamCompany3
                                                                                        .contact_invoices
                                                                                }
                                                                                onChange={(event) =>
                                                                                    handleChangeCheck(
                                                                                        event,
                                                                                        4,
                                                                                        "teamCompany3"
                                                                                    )
                                                                                }
                                                                                sx={{
                                                                                    ml: {
                                                                                        xs: 1,
                                                                                        lg: 0,
                                                                                        xl: 1,
                                                                                    },
                                                                                    mr: {
                                                                                        xs: 1,
                                                                                        lg: 0,
                                                                                        xl: 1,
                                                                                    },
                                                                                }}
                                                                            />
                                                                        </LightTooltip>
                                                                    </FormGroup>
                                                                </Grid>
                                                            </Grid>
                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <Card
                                                        sx={{
                                                            borderRadius: "20px",
                                                            backgroundColor: "#F8F9F8",
                                                            boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                        }}
                                                    >
                                                        <CardContent
                                                            sx={{
                                                                p: "24px 24px 16px !important",
                                                            }}
                                                        >
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={12} lg={4}>
                                                                    <AccountCircle className={classes.avatarImage} />
                                                                    <Typography
                                                                        variant="h2"
                                                                        color="primary"
                                                                        align="center"
                                                                        sx={{
                                                                            pt: 1,
                                                                        }}
                                                                    >
                                                                        {`${t("miscellaneous.contact")} 4`}
                                                                    </Typography>
                                                                    <LightTooltip title={t("recurrent.clear")}>
                                                                        <IconButton
                                                                            aria-label="clear"
                                                                            color="primary"
                                                                            onClick={(event) =>
                                                                                clearTeamStaff(event, "teamCompany4")
                                                                            }
                                                                            sx={{
                                                                                display: "block",
                                                                                m: "auto",
                                                                            }}
                                                                        >
                                                                            <ClearAllIcon />
                                                                        </IconButton>
                                                                    </LightTooltip>
                                                                </Grid>
                                                                <Grid item xs={12} lg={8}>
                                                                    <TextField
                                                                        fullWidth
                                                                        required={isRequired4}
                                                                        label={t("miscellaneous.name")}
                                                                        name="contact_name"
                                                                        onChange={(event) =>
                                                                            handleChangeTeamStaff(event, "teamCompany4")
                                                                        }
                                                                        value={valueStaff.teamCompany4.contact_name}
                                                                        variant="outlined"
                                                                        error={
                                                                            isRequired4 &&
                                                                            isError3 &&
                                                                            valueStaff.teamCompany4.contact_name.trim() ===
                                                                                ""
                                                                                ? true
                                                                                : false
                                                                        }
                                                                    />
                                                                    <TextField
                                                                        fullWidth
                                                                        required={isRequired4}
                                                                        label={t("miscellaneous.email")}
                                                                        name="contact_email"
                                                                        onChange={(event) =>
                                                                            handleChangeTeamStaff(event, "teamCompany4")
                                                                        }
                                                                        value={valueStaff.teamCompany4.contact_email}
                                                                        variant="outlined"
                                                                        error={
                                                                            isRequired4 &&
                                                                            isError3 &&
                                                                            valueStaff.teamCompany4.contact_email.trim() ===
                                                                                ""
                                                                                ? true
                                                                                : isRequired4 &&
                                                                                  isError3 &&
                                                                                  !/.+@.+\..+/.test(
                                                                                      valueStaff.teamCompany4.contact_email.trim()
                                                                                  )
                                                                                ? true
                                                                                : false
                                                                        }
                                                                        sx={{
                                                                            mt: 2,
                                                                        }}
                                                                    />
                                                                    <TextField
                                                                        fullWidth
                                                                        required={isRequired4}
                                                                        label={t("miscellaneous.role")}
                                                                        name="contact_role"
                                                                        onChange={(event) =>
                                                                            handleChangeTeamStaff(event, "teamCompany4")
                                                                        }
                                                                        value={valueStaff.teamCompany4.contact_role}
                                                                        variant="outlined"
                                                                        error={
                                                                            isRequired4 &&
                                                                            isError3 &&
                                                                            valueStaff.teamCompany4.contact_role.trim() ===
                                                                                ""
                                                                                ? true
                                                                                : false
                                                                        }
                                                                        sx={{
                                                                            mt: 2,
                                                                        }}
                                                                    />
                                                                    <FormGroup
                                                                        sx={{
                                                                            mt: 2,
                                                                            display: "block",
                                                                        }}
                                                                    >
                                                                        <FormLabel id="permissions-checkboxes-group-label">
                                                                            <Typography
                                                                                variant="h4"
                                                                                color="primary"
                                                                                sx={{
                                                                                    pb: 1,
                                                                                }}
                                                                            >
                                                                                {t("miscellaneous.permissions")}:
                                                                            </Typography>
                                                                        </FormLabel>
                                                                        <LightTooltip
                                                                            title={t("services.toolRequirements")}
                                                                            aria-label="req"
                                                                        >
                                                                            <Checkbox
                                                                                icon={
                                                                                    <AssignmentTurnedInOutlinedIcon />
                                                                                }
                                                                                checkedIcon={<AssignmentTurnedInIcon />}
                                                                                checked={
                                                                                    valueStaff.teamCompany4
                                                                                        .contact_requirements
                                                                                }
                                                                                onChange={(event) =>
                                                                                    handleChangeCheck(
                                                                                        event,
                                                                                        3,
                                                                                        "teamCompany4"
                                                                                    )
                                                                                }
                                                                                sx={{
                                                                                    ml: {
                                                                                        xs: 0,
                                                                                        lg: 0,
                                                                                        xl: 0,
                                                                                    },
                                                                                    mr: {
                                                                                        xs: 1,
                                                                                        lg: 0,
                                                                                        xl: 1,
                                                                                    },
                                                                                }}
                                                                            />
                                                                        </LightTooltip>
                                                                        <LightTooltip
                                                                            title={t("services.toolAccountingClosures")}
                                                                            aria-label="cierres"
                                                                        >
                                                                            <Checkbox
                                                                                icon={<LockOutlinedIcon />}
                                                                                checkedIcon={<LockIcon />}
                                                                                checked={
                                                                                    valueStaff.teamCompany4
                                                                                        .contact_closure
                                                                                }
                                                                                onChange={(event) =>
                                                                                    handleChangeCheck(
                                                                                        event,
                                                                                        0,
                                                                                        "teamCompany4"
                                                                                    )
                                                                                }
                                                                                sx={{
                                                                                    ml: {
                                                                                        xs: 1,
                                                                                        lg: 0,
                                                                                        xl: 1,
                                                                                    },
                                                                                    mr: {
                                                                                        xs: 1,
                                                                                        lg: 0,
                                                                                        xl: 1,
                                                                                    },
                                                                                }}
                                                                            />
                                                                        </LightTooltip>
                                                                        <LightTooltip
                                                                            title={t("services.toolStatus")}
                                                                            aria-label="status"
                                                                        >
                                                                            <Checkbox
                                                                                icon={<InsertChartOutlinedIcon />}
                                                                                checkedIcon={<InsertChartIcon />}
                                                                                checked={
                                                                                    valueStaff.teamCompany4
                                                                                        .contact_status
                                                                                }
                                                                                onChange={(event) =>
                                                                                    handleChangeCheck(
                                                                                        event,
                                                                                        1,
                                                                                        "teamCompany4"
                                                                                    )
                                                                                }
                                                                                sx={{
                                                                                    ml: {
                                                                                        xs: 1,
                                                                                        lg: 0,
                                                                                        xl: 1,
                                                                                    },
                                                                                    mr: {
                                                                                        xs: 1,
                                                                                        lg: 0,
                                                                                        xl: 1,
                                                                                    },
                                                                                }}
                                                                            />
                                                                        </LightTooltip>
                                                                        <LightTooltip
                                                                            title={t("services.toolInvoicing")}
                                                                            aria-label="invoicing"
                                                                        >
                                                                            <Checkbox
                                                                                icon={<CreditCardOutlinedIcon />}
                                                                                checkedIcon={
                                                                                    <CreditCardIcon className="icon-normal-size" />
                                                                                }
                                                                                checked={
                                                                                    valueStaff.teamCompany4
                                                                                        .contact_invoices
                                                                                }
                                                                                onChange={(event) =>
                                                                                    handleChangeCheck(
                                                                                        event,
                                                                                        4,
                                                                                        "teamCompany4"
                                                                                    )
                                                                                }
                                                                                sx={{
                                                                                    ml: {
                                                                                        xs: 1,
                                                                                        lg: 0,
                                                                                        xl: 1,
                                                                                    },
                                                                                    mr: {
                                                                                        xs: 1,
                                                                                        lg: 0,
                                                                                        xl: 1,
                                                                                    },
                                                                                }}
                                                                            />
                                                                        </LightTooltip>
                                                                    </FormGroup>
                                                                </Grid>
                                                            </Grid>
                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                    <Card elevation={0}>
                                        <CardHeader title={t("miscellaneous.gcaStaff")} sx={{ color: "#ff5968" }} />
                                        <Divider />
                                        <CardContent>
                                            <Grid container spacing={2}>
                                                <Grid item lg={4} sm={6} xs={12}>
                                                    <FormControl
                                                        fullWidth
                                                        required
                                                        error={isError3 && valueStaff.manager === "" ? true : false}
                                                    >
                                                        <InputLabel id="manager-simple-select-label">
                                                            {t("miscellaneous.manager")}
                                                        </InputLabel>
                                                        <Select
                                                            name="manager"
                                                            labelId="manager-simple-select-label"
                                                            label={t("miscellaneous.manager")}
                                                            IconComponent={KeyboardArrowDownIcon}
                                                            value={valueStaff.manager}
                                                            onChange={handleChangeStaff}
                                                            MenuProps={{
                                                                PaperProps: {
                                                                    sx: {
                                                                        mt: 0.7,
                                                                        borderRadius: 2,
                                                                        boxShadow:
                                                                            "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                                    },
                                                                },
                                                            }}
                                                        >
                                                            {Staff.manager.map((option) => (
                                                                <MenuItem value={option.name_es}>
                                                                    {moment.locale() === "en"
                                                                        ? option.name_en
                                                                        : option.name_es}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item lg={4} sm={6} xs={12}>
                                                    <FormControl
                                                        fullWidth
                                                        required
                                                        error={isError3 && valueStaff.supervisor === "" ? true : false}
                                                    >
                                                        <InputLabel id="supervisor-simple-select-label">
                                                            {t("miscellaneous.supervisor")}
                                                        </InputLabel>
                                                        <Select
                                                            name="supervisor"
                                                            labelId="supervisor-simple-select-label"
                                                            label={t("miscellaneous.supervisor")}
                                                            IconComponent={KeyboardArrowDownIcon}
                                                            value={valueStaff.supervisor}
                                                            onChange={handleChangeStaff}
                                                            MenuProps={{
                                                                PaperProps: {
                                                                    sx: {
                                                                        mt: 0.7,
                                                                        borderRadius: 2,
                                                                        boxShadow:
                                                                            "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                                    },
                                                                },
                                                            }}
                                                        >
                                                            {Staff.supervisor.map((option) => (
                                                                <MenuItem value={option.name_es}>
                                                                    {moment.locale() === "en"
                                                                        ? option.name_en
                                                                        : option.name_es}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item lg={4} sm={6} xs={12}>
                                                    <FormControl
                                                        fullWidth
                                                        required
                                                        error={isError3 && valueStaff.advisor === "" ? true : false}
                                                    >
                                                        <InputLabel id="advisor-simple-select-label">
                                                            {t("miscellaneous.advisor")}
                                                        </InputLabel>
                                                        <Select
                                                            name="advisor"
                                                            labelId="advisor-simple-select-label"
                                                            label={t("miscellaneous.advisor")}
                                                            IconComponent={KeyboardArrowDownIcon}
                                                            value={valueStaff.advisor}
                                                            onChange={handleChangeStaff}
                                                            MenuProps={{
                                                                PaperProps: {
                                                                    sx: {
                                                                        mt: 0.7,
                                                                        borderRadius: 2,
                                                                        boxShadow:
                                                                            "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                                    },
                                                                },
                                                            }}
                                                        >
                                                            {Staff.advisor.map((option) => (
                                                                <MenuItem value={option.name_es}>
                                                                    {moment.locale() === "en"
                                                                        ? option.name_en
                                                                        : option.name_es}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item lg={4} sm={6} xs={12}>
                                                    <FormControl
                                                        fullWidth
                                                        required
                                                        error={isError3 && valueStaff.analyst === "" ? true : false}
                                                    >
                                                        <InputLabel id="analyst-simple-select-label">
                                                            {t("miscellaneous.analyst")}
                                                        </InputLabel>
                                                        <Select
                                                            name="analyst"
                                                            labelId="analyst-simple-select-label"
                                                            label={t("miscellaneous.analyst")}
                                                            IconComponent={KeyboardArrowDownIcon}
                                                            value={valueStaff.analyst}
                                                            onChange={handleChangeStaff}
                                                            MenuProps={{
                                                                PaperProps: {
                                                                    sx: {
                                                                        mt: 0.7,
                                                                        borderRadius: 2,
                                                                        boxShadow:
                                                                            "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                                    },
                                                                },
                                                            }}
                                                        >
                                                            {Staff.analyst.map((option) => (
                                                                <MenuItem value={option.name_es}>
                                                                    {moment.locale() === "en"
                                                                        ? option.name_en
                                                                        : option.name_es}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item lg={4} sm={6} xs={12}>
                                                    <FormControl
                                                        fullWidth
                                                        required
                                                        error={
                                                            isError3 && valueStaff.backupAnalyst === "" ? true : false
                                                        }
                                                    >
                                                        <InputLabel id="backup-analyst-simple-select-label">
                                                            {t("miscellaneous.backupAnalyst")}
                                                        </InputLabel>
                                                        <Select
                                                            name="backupAnalyst"
                                                            labelId="backup-analyst-simple-select-label"
                                                            label={t("miscellaneous.backupAnalyst")}
                                                            IconComponent={KeyboardArrowDownIcon}
                                                            value={valueStaff.backupAnalyst}
                                                            onChange={handleChangeStaff}
                                                            MenuProps={{
                                                                PaperProps: {
                                                                    sx: {
                                                                        mt: 0.7,
                                                                        borderRadius: 2,
                                                                        boxShadow:
                                                                            "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                                    },
                                                                },
                                                            }}
                                                        >
                                                            {Staff.backupAnalyst.map((option) => (
                                                                <MenuItem value={option.name_es}>
                                                                    {moment.locale() === "en"
                                                                        ? option.name_en
                                                                        : option.name_es}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </>
                            )}
                        </TabPanel>
                        <TabPanel value={valueTab} index={1}>
                            <Box sx={{ width: "100%", p: 4 }}>
                                <Stepper
                                    nonLinear
                                    activeStep={activeStep}
                                    orientation={window.innerWidth < 600 ? "vertical" : "horizontal"}
                                >
                                    {stepsEdit.map((label, index) => {
                                        const labelProps = {};
                                        if (
                                            (isError && index === 0) ||
                                            (isError1 && index === 1) ||
                                            (isError3 && index === 2) ||
                                            (isError3 && index === 3) ||
                                            (isError4 && index === 4)
                                        ) {
                                            labelProps.optional = (
                                                <Typography variant="caption" color="error">
                                                    {t("miscellaneous.required")}
                                                </Typography>
                                            );

                                            labelProps.error = true;
                                        }
                                        return (
                                            <Step key={label}>
                                                <StepButton
                                                    color="inherit"
                                                    onClick={() => handleStep(index)}
                                                    disabled={!showEdit}
                                                >
                                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                                </StepButton>
                                            </Step>
                                        );
                                    })}
                                </Stepper>
                            </Box>
                            <EditGroup
                                setBackDrop={props.setBackDrop}
                                setTexto={props.setTexto}
                                setalertType={props.setalertType}
                                setShow={props.setShow}
                                valuesGen={valuesGen}
                                setValuesGen={setValuesGen}
                                valuesFact={valuesFact}
                                setValuesFact={setValuesFact}
                                valuesSettings={valuesSettings}
                                setValuesSettings={setValuesSettings}
                                valueStaff={valueStaff}
                                setValueStaff={setValueStaff}
                                activeStep={activeStep}
                                franqData={props.franqData}
                                isError={isError}
                                isError1={isError1}
                                isError2={isError2}
                                isError3={isError3}
                                isError4={isError4}
                                showEdit={showEdit}
                                setShowEdit={resetErrors}
                                disabledData={disabledData}
                                setDisabledData={setDisabledData}
                                disabledData2={disabledData2}
                                setDisabledData2={setDisabledData2}
                                requiredData={requiredData}
                                setRequiredData={setRequiredData}
                                showFran={showFran}
                                setShowFran={setShowFran}
                                handleChangeType={handleChangeType}
                                handleChangeFranq={handleChangeFranq}
                                handleChangeTypeFact={handleChangeTypeFact}
                                groupData={props.groupData}
                                teamCompanyDefault={teamCompanyDefault}
                                handleChangeInitDate={handleChangeInitDate}
                                handleChangeEndDate={handleChangeEndDate}
                                handleChangeCheckOtherGroup={handleChangeCheckOtherGroup}
                                isReq={isReq}
                                setIsReq={setIsReq}
                            />
                        </TabPanel>
                    </Box>
                </LocalizationProvider>
            </DialogContent>
            <DialogActions sx={{ p: "24px 32px !important" }}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                    }}
                >
                    <ColorButton
                        onClick={handleBack}
                        sx={{
                            mr: 1,
                            "&:hover": {
                                backgroundColor: "white",
                                color: "#2f3190",
                                border: "1px solid #2f3190",
                            },
                        }}
                        color="secondary"
                        disableElevation
                        variant="outlined"
                    >
                        {activeStep === 0 ? t("miscellaneous.cancel") : t("miscellaneous.back")}
                    </ColorButton>
                    <Box sx={{ flex: "1 1 auto" }} />
                    {valueTab === 0 ? (
                        <ColorButton
                            onClick={activeStep === steps.length - 1 ? handleOpenDialog : handleNext}
                            sx={{
                                "&:hover": {
                                    backgroundColor: "#2f3190",
                                    border: "1px solid #2f3190",
                                },
                            }}
                            className="Button"
                            color="secondary"
                            disableElevation
                            variant="contained"
                        >
                            {activeStep === steps.length - 1 ? t("miscellaneous.save") : t("miscellaneous.next")}
                        </ColorButton>
                    ) : (
                        <ColorButton
                            onClick={handleOpenDialog}
                            sx={{
                                "&:hover": {
                                    backgroundColor: "#2f3190",
                                    border: "1px solid #2f3190",
                                },
                            }}
                            className="Button"
                            color="secondary"
                            disableElevation
                            disabled={!showEdit || (activeStep === 4 && isReq)}
                            variant="contained"
                        >
                            {t("miscellaneous.save")}
                        </ColorButton>
                    )}
                </Box>
            </DialogActions>
            <DialogAlerts
                open={open}
                onClose={handleCloseDialog}
                agreeBtnLabel={t("miscellaneous.accept")}
                disagreeBtnLabel={t("miscellaneous.cancel")}
                type={dialogType}
                title={dialogText}
                agreeAction={valueTab === 0 ? handleNext : () => updateGroup(false)}
            />
            <DialogAlertsCustom
                open={openGroupVal}
                onClose={handleCloseDialog}
                agreeOnlyActive={() => updateGroup(false)}
                title={dialogText}
                agreeAllAction={() => updateGroup(true)}
            />
        </CustomDialog>
    );
}
