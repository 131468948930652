// React
import React from "react";
import { useTranslation } from "react-i18next";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useSelector } from "react-redux";
import moment from "moment";

// MUI
import {
    Box,
    TextField,
    Grid,
    Typography,
    FormControl,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    InputLabel,
    InputAdornment,
    Select,
    MenuItem,
} from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { styled } from "@mui/material/styles";

/** MUI icons */
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CheckIcon from "@mui/icons-material/Check";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const PREFIX = "KiiperBanking";

const classes = {
    btnRoot: `${PREFIX}-btnRoot`,
    buttonContainer: `${PREFIX}-buttonContainer`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    backgroundColor: "#F8F9F8",
    marginTop: 16,
    padding: 30,
    borderRadius: 30,

    [`& .${classes.btnRoot}`]: {
        border: "1px solid #ff5968",
        borderRadius: 40,
        "&:hover": {
            backgroundColor: "#2f3190",
            border: "1px solid #2f3190",
        },
    },
    [`& .${classes.buttonContainer}`]: {
        display: "flex",
        justifyContent: "flex-end",
        padding: 15,
    },
}));

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)({
    backgroundColor: "#F8F9F8",
    boxShadow: "none",
    "&:before": {
        display: "none",
    },
});

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: "#031851" }} />} {...props} />
))({
    "& .MuiAccordionSummary-content": {
        margin: "12px 0",
    },
});

const AccordionDetails = styled(MuiAccordionDetails)({
    padding: "0px 0px 20px 0px",
    "& .MuiCard-root": {
        boxShadow: "none",
    },
});

export const KiiperBanking = (props) => {
    // Properties
    const { banking, setBanking, isError, isErrorFocus, setIsErrorFocus } = props;

    /** Internationalization i18n */
    const [t] = useTranslation("global");

    /** React redux */
    const todos = useSelector((state) => state.todos);

    /** Component functions */
    const handleChange = (event) => {
        setBanking({
            ...banking,
            [event.target.name]: event.target.value,
            projectTotal: event.target.value * banking.originalFee * (1 - banking.discount / 100),
            isError: false,
        });
        setIsErrorFocus(false);
    };

    const handleChangeDiscount = (event) => {
        let discountAux = event.target.value >= 0 && event.target.value <= 100 ? event.target.value : banking.discount;

        setBanking({
            ...banking,
            discount: discountAux,
            projectTotal: banking.originalFee * banking.hours * (1 - discountAux / 100),
            isError: false,
        });
        setIsErrorFocus(false);
    };

    const handleChangePackage = (event) => {
        let hoursNew = 1;

        if (event.target.value === 1) {
            hoursNew = 4;
        } else if (event.target.value === 2) {
            hoursNew = 8;
        } else if (event.target.value === 3) {
            hoursNew = 16;
        }

        setBanking({
            ...banking,
            package: event.target.value,
            hours: hoursNew,
            projectTotal: hoursNew * banking.originalFee * (1 - banking.discount / 100),
            isCustom: event.target.value !== 4,
            isError: false,
        });
        setIsErrorFocus(false);
    };

    const handleChangeVersion = (event) => {
        setBanking({
            ...banking,
            version: event.target.value,
            isError: false,
        });
        setIsErrorFocus(false);
    };

    const handleChangeEstDeliDate = (date) => {
        setBanking({
            ...banking,
            commitmentDateProposal: date,
            isError: false,
        });
        setIsErrorFocus(false);
    };

    return (
        <StyledContainer>
            <Grid container alignItems="flex-start" spacing={2}>
                <Grid item xs={12}>
                    <Typography
                        variant="h1"
                        color="primary"
                        sx={{
                            display: "inline-block",
                            fontWeight: 700,
                            pr: 6,
                        }}
                    >
                        Kiiper Banking setup
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Accordion
                        TransitionProps={{ unmountOnExit: true }}
                        sx={{
                            borderBottom: "3px solid #ff5968",
                        }}
                    >
                        <AccordionSummary aria-controls="panel-content" id="panel-header">
                            <Typography
                                variant="h2"
                                align="center"
                                sx={{
                                    fontWeight: 700,
                                }}
                            >
                                {t("invoicing.description")}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <List>
                                <ListItem disableGutters alignItems="center">
                                    <ListItemIcon sx={{ minWidth: 35 }}>
                                        <CheckIcon fontSize="small" sx={{ color: "#031851" }} />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <Typography variant="body1">
                                                <span style={{ fontWeight: 600 }}>{t("proposals.kiiperExpert")} </span>-{" "}
                                                {t("proposals.kiiperBankConsBasic")}
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                                <ListItem disableGutters alignItems="center">
                                    <ListItemIcon sx={{ minWidth: 35 }}>
                                        <CheckIcon fontSize="small" sx={{ color: "#031851" }} />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <Typography variant="body1">
                                                <span style={{ fontWeight: 600 }}>{t("proposals.bankAccSetup")} </span>-{" "}
                                                {t("proposals.bankAccSetupDet")}
                                                {banking.package === 1
                                                    ? " " + t("proposals.bankAccSetupDetBasic")
                                                    : banking.package === 2
                                                    ? " " + t("proposals.bankAccSetupDetProf")
                                                    : banking.package === 3
                                                    ? " " + t("proposals.bankAccSetupDetAdv")
                                                    : ""}
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                                <ListItem disableGutters alignItems="center">
                                    <ListItemIcon sx={{ minWidth: 35 }}>
                                        <CheckIcon fontSize="small" sx={{ color: "#031851" }} />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <Typography variant="body1">
                                                <span style={{ fontWeight: 600 }}>{t("proposals.bankStatDev")} </span>-{" "}
                                                {t("proposals.bankStatDevDet")}
                                                {banking.package === 1
                                                    ? " " + t("proposals.bankStatDevDetBasic")
                                                    : banking.package === 2
                                                    ? " " + t("proposals.bankStatDevDetProf")
                                                    : banking.package === 3
                                                    ? " " + t("proposals.bankStatDevDetAdv")
                                                    : ""}
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                                <ListItem disableGutters alignItems="center">
                                    <ListItemIcon sx={{ minWidth: 35 }}>
                                        <CheckIcon fontSize="small" sx={{ color: "#031851" }} />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <Typography variant="body1">
                                                <span style={{ fontWeight: 600 }}>
                                                    {t("proposals.premiumSuppKiiper")}{" "}
                                                </span>
                                                - {t("proposals.premiumSuppKiiperDet")}
                                                {banking.package === 1
                                                    ? " " + t("proposals.premiumSuppKiiperDetBasic")
                                                    : banking.package === 2
                                                    ? " " + t("proposals.premiumSuppKiiperDetProf")
                                                    : banking.package === 3
                                                    ? " " + t("proposals.premiumSuppKiiperDetAdv")
                                                    : ""}
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                            </List>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <FormControl fullWidth required error={isError && banking.package === ""}>
                        <InputLabel id="ver-simple-select-label">{t("services.package")}</InputLabel>
                        <Select
                            name="package"
                            labelId="ver-simple-select-label"
                            IconComponent={KeyboardArrowDownIcon}
                            value={banking.package}
                            label={t("services.package")}
                            onChange={handleChangePackage}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        mt: 0.7,
                                        borderRadius: 2,
                                        boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                    },
                                },
                            }}
                            onClick={(e) => {
                                setIsErrorFocus(false);
                            }}
                            inputRef={(input) => {
                                input && isErrorFocus && isError && banking.package === "" && input.focus();
                            }}
                        >
                            <MenuItem value={1}>Basic (4 hrs)</MenuItem>
                            <MenuItem value={2}>Professional (8 hrs)</MenuItem>
                            <MenuItem value={3}>Advanced (16 hrs)</MenuItem>
                            <MenuItem value={4}>{t("currentView.personal")}</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <FormControl fullWidth required error={isError && banking.version === ""}>
                        <InputLabel id="ver-simple-select-label">{t("services.version")}</InputLabel>
                        <Select
                            name="version"
                            labelId="ver-simple-select-label"
                            IconComponent={KeyboardArrowDownIcon}
                            value={banking.version}
                            label={t("services.version")}
                            onChange={handleChangeVersion}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        mt: 0.7,
                                        borderRadius: 2,
                                        boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                    },
                                },
                            }}
                            onClick={(e) => {
                                setIsErrorFocus(false);
                            }}
                            inputRef={(input) => {
                                input && isErrorFocus && isError && banking.version === "" && input.focus();
                            }}
                        >
                            <MenuItem value={"Plan 5"}>Plan 5</MenuItem>
                            <MenuItem value={"Plan 15"}>Plan 15</MenuItem>
                            <MenuItem value={"Plan 30"}>Plan 30</MenuItem>
                            <MenuItem value={"Plan 50"}>Plan 50</MenuItem>
                            <MenuItem value={"Plan 200"}>Plan 200</MenuItem>
                            <MenuItem value={"Plan 500"}>Plan 500</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label={t("miscellaneous.hours")}
                                name="hours"
                                onChange={handleChange}
                                value={banking.hours}
                                variant="outlined"
                                type="number"
                                disabled={banking.isCustom}
                                inputProps={{
                                    min: 1,
                                    step: 0.5,
                                    onKeyDown: (event) => {
                                        event.preventDefault();
                                    },
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label={t("miscellaneous.discount")}
                                value={banking.discount}
                                name="discount"
                                onChange={handleChangeDiscount}
                                onBlur={() =>
                                    setBanking({
                                        ...banking,
                                        discount: parseFloat(banking.discount === "" ? 0 : banking.discount).toFixed(2),
                                    })
                                }
                                variant="outlined"
                                type="number"
                                InputProps={{
                                    inputProps: {
                                        min: 0,
                                        max: 100,
                                    },
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                }}
                                sx={{
                                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                        display: "none",
                                    },
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <LocalizationProvider
                        dateAdapter={AdapterMoment}
                        adapterLocale={t("language.locale")}
                        localeText={{ clearButtonLabel: t("miscellaneous.clear") }}
                    >
                        <DatePicker
                            componentsProps={{
                                actionBar: { actions: ["clear"] },
                            }}
                            value={banking.commitmentDateProposal}
                            minDate={moment()}
                            onChange={(newValue) => handleChangeEstDeliDate(newValue)}
                            inputFormat={todos.dateFormat}
                            renderInput={(params) => (
                                <TextField
                                    fullWidth
                                    required
                                    {...params}
                                    error={isError && !banking.commitmentDateProposal}
                                />
                            )}
                            onClick={(e) => {
                                setIsErrorFocus(false);
                            }}
                            label={t("services.estimDeliveryDate")}
                            views={["year", "month", "day"]}
                            inputRef={(input) => {
                                input && isErrorFocus && isError && !banking.commitmentDateProposal && input.focus();
                            }}
                        />
                    </LocalizationProvider>
                </Grid>
            </Grid>
        </StyledContainer>
    );
};
