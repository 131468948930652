/** React imports */
import React from "react";
import { useTranslation } from "react-i18next";

/** Images */

/** MUI icons */

/** MUI imports */
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { DescriptionIconContainer } from "../AccountantProposal/DescriptionIconContainer";

/** Global variables */
const PREFIX = "ScheduleSoft";

const classes = {
    body2: `${PREFIX}-body2`,
    scheduleTitle: `${PREFIX}-scheduleTitle`,
    scheduleBox: `${PREFIX}-scheduleBox`,
    fieldset: `${PREFIX}-fieldset`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.scheduleTitle}`]: {
        fontSize: 22,
    },

    [`& .${classes.body2}`]: {
        backgroundColor: "white",
        marginTop: 15,
        minHeight: 850,
        [theme.breakpoints.down("md")]: {
            padding: 30,
        },
        [theme.breakpoints.up("md")]: {
            padding: 60,
        },
    },

    [`& .${classes.scheduleBox}`]: {
        backgroundColor: "#F8F9F8",
        marginTop: 16,
        padding: 20,
        borderRadius: 30,
    },

    [`& .${classes.fieldset}`]: {
        border: "2px solid #031851",
        borderStyle: "solid solid none solid",
        lineHeight: 0,
    },
}));

const CustomBox = styled(Box)(({ theme }) => ({
    borderRadius: "24px",
    color: "#fff",
    height: 30,
    //paddingLeft: "20px",
}));

const CustomBoxBar = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 6,
    height: 12,
    width: "100%",
}));

const CustomTableCell = styled(TableCell)(({ theme }) => ({
    borderBottom: 0,
    paddingRight: 0,
    [`& h2`]: {
        fontSize: 12,
    },
    [`& span`]: {
        fontSize: 12,
    },
}));

export const ScheduleSoft = (props) => {
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    const { softInfo, isBook } = props;

    /** Global variables */
    const weeksXero = [
        { color: "#8283bc", label: isBook ? t("proposals.week1B") : t("proposals.week1") },
        { color: "#6d6fb1", label: isBook ? t("proposals.week2B") : t("proposals.week2") },
        { color: "#595aa6", label: isBook ? t("proposals.week3B") : t("proposals.week3") },
        { color: "#44469b", label: isBook ? t("proposals.week4B") : t("proposals.week4") },
        { color: "#2f3190", label: isBook ? t("proposals.week5to8B") : t("proposals.week5to8") },
    ];

    const weeksXero1 = [
        { color: "#6d6fb1", label: isBook ? t("proposals.week2B") : t("proposals.week2") },
        { color: "#595aa6", label: isBook ? t("proposals.week3B") : t("proposals.week3") },
        { color: "#44469b", label: isBook ? t("proposals.week4B") : t("proposals.week4") },
        { color: "#2f3190", label: isBook ? t("proposals.week5to8B") : t("proposals.week5to8") },
    ];

    const weeksXero2 = [
        { color: "#44469b", label: isBook ? t("proposals.week4B") : t("proposals.week4") },
        { color: "#2f3190", label: isBook ? t("proposals.week5to8B") : t("proposals.week5to8") },
    ];

    const weeksBanking = [
        { color: "#595aa6", label: isBook ? t("proposals.week3B") : t("proposals.week3") },
        { color: "#44469b", label: isBook ? t("proposals.week4B") : t("proposals.week4") },
        { color: "#2f3190", label: isBook ? t("proposals.week5to8B") : t("proposals.week5to8") },
    ];

    const weeksBanking1 = [
        { color: "#595aa6", label: t("proposals.week2") },
        { color: "#44469b", label: t("proposals.week3") },
        { color: "#2f3190", label: t("proposals.week4to7") },
    ];

    const weeksTaxes = [
        { color: "#595aa6", label: t("proposals.week3") },
        { color: "#44469b", label: t("proposals.week4") },
        { color: "#2f3190", label: t("proposals.week5to8") },
    ];

    const weeksTaxes1 = [
        { color: "#595aa6", label: t("proposals.week2") },
        { color: "#44469b", label: t("proposals.week3") },
        { color: "#2f3190", label: t("proposals.week4to7") },
    ];

    return (
        <StyledContainer>
            {softInfo.xero.isActive && (
                <Box className={classes.scheduleBox}>
                    <TableContainer sx={{ overflowX: "auto" }}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow sx={{ "& th": { border: 0, pt: 1 } }}>
                                    <TableCell>
                                        <Typography variant="h2" sx={{ fontWeight: 700, fontSize: 12 }}>
                                            Xero
                                        </Typography>
                                    </TableCell>
                                    {((softInfo.name === "Software setup" && props.accountantOnboarding && !props.includeAdmModuleSet) ||
                                        (softInfo.name === "Software subscription" && props.accountantOnboarding && !props.includeAdmModuleSub)
                                        ? weeksXero2 :
                                        props.isAccountant ? weeksXero1 : weeksXero).map((week, index) => (
                                            <TableCell align="left">
                                                <CustomBox
                                                    sx={{
                                                        backgroundColor: week.color,
                                                        width: sessionStorage.getItem("lng") === "en" ? ((softInfo.name === "Software setup" && props.accountantOnboarding && !props.includeAdmModuleSet) ? "190%" : "220%") : ((softInfo.name === "Software setup" && props.accountantOnboarding && !props.includeAdmModuleSet) ? "180%" : "235%"),
                                                        ...(index === ((softInfo.name === "Software setup" && props.accountantOnboarding && !props.includeAdmModuleSet) ||
                                                            (softInfo.name === "Software subscription" && props.accountantOnboarding && !props.includeAdmModuleSub) ? 1 : props.isAccountant ? 3 : 4) && {
                                                            pr: "15px",
                                                            pl: "15px",
                                                            width: (softInfo.name === "Software setup" && props.accountantOnboarding && !props.includeAdmModuleSet) ||
                                                                (softInfo.name === "Software subscription" && props.accountantOnboarding && !props.includeAdmModuleSub) ? "145% !important" : "110% !important",
                                                        }),
                                                        display: "flex",
                                                        justifyContent: "space-evenly",
                                                        ml: (softInfo.name === "Software setup" && props.accountantOnboarding && !props.includeAdmModuleSet) ? -38 : null
                                                    }}
                                                >
                                                    <Typography
                                                        variant="span"
                                                        sx={{ display: "flex", alignItems: "center", fontSize: 12 }}
                                                        noWrap
                                                    >
                                                        {week.label}
                                                    </Typography>
                                                    {index !== ((softInfo.name === "Software setup" && props.accountantOnboarding && !props.includeAdmModuleSet) ||
                                                        (softInfo.name === "Software subscription" && props.accountantOnboarding && !props.includeAdmModuleSub) ? 1 : props.isAccountant ? 3 : 4) && (
                                                            <Typography
                                                                variant="span"
                                                                sx={{ display: "flex", alignItems: "center", fontSize: 12 }}
                                                            >
                                                                &nbsp;
                                                            </Typography>
                                                        )}
                                                </CustomBox>
                                            </TableCell>
                                        ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {((softInfo.name === "Software setup" && props.includeAccModuleSet && (props.isAccountant && !props.accountantOnboarding)) || (softInfo.name === "Software setup" && props.includeAccModuleSet && !props.isAccountant)) && (
                                    <TableRow>
                                        <CustomTableCell component="th" scope="row">
                                            <Typography variant="h2">{isBook ? t("proposals.accSetupB") : t("proposals.accSetup")}</Typography>
                                        </CustomTableCell>
                                        <CustomTableCell colSpan={props.isAccountant ? 2 : 3} align="right">
                                            <CustomBoxBar />
                                        </CustomTableCell>
                                    </TableRow>
                                )}
                                {((softInfo.name === "Software setup" && props.includeAdmModuleSet) || (softInfo.name === "Software subscription" && props.includeAdmModuleSub)) && (
                                    <TableRow>
                                        <CustomTableCell component="th" scope="row">
                                            <Typography variant="h2">{isBook ? t("proposals.businessSetupB") : t("proposals.businessSetup")}</Typography>
                                        </CustomTableCell>
                                        <CustomTableCell colSpan={props.isAccountant ? 2 : 3} align="right">
                                            <CustomBoxBar />
                                        </CustomTableCell>
                                    </TableRow>
                                )}
                                <TableRow>
                                    <CustomTableCell component="th" scope="row">
                                        <Typography variant="h2">{isBook ? t("proposals.capaB") : t("proposals.capa")}</Typography>
                                    </CustomTableCell>
                                    {(softInfo.name === "Software setup" && props.accountantOnboarding && !props.includeAdmModuleSet) ||
                                        (softInfo.name === "Software subscription" && props.accountantOnboarding && !props.includeAdmModuleSub) ?
                                        null : props.isAccountant ? <CustomTableCell colSpan={2} /> : <CustomTableCell colSpan={3} />
                                    }
                                    <CustomTableCell align="right">
                                        <CustomBoxBar sx={{ ml: (softInfo.name === "Software setup" && props.accountantOnboarding && !props.includeAdmModuleSet) ? -37 : null }} />
                                    </CustomTableCell>
                                </TableRow>
                                <TableRow>
                                    <CustomTableCell component="th" scope="row">
                                        <Typography variant="h2">{isBook ? t("proposals.followB") : t("proposals.follow")}</Typography>
                                    </CustomTableCell>
                                    <CustomTableCell colSpan={(softInfo.name === "Software setup" && props.accountantOnboarding && !props.includeAdmModuleSet) ||
                                        (softInfo.name === "Software subscription" && props.accountantOnboarding && !props.includeAdmModuleSub) ? 0 : props.isAccountant ? 3 : 4} />
                                    <CustomTableCell align="right">
                                        <CustomBoxBar sx={{ ml: (softInfo.name === "Software setup" && props.accountantOnboarding && !props.includeAdmModuleSet) ? -37 : null }} />
                                    </CustomTableCell>
                                </TableRow>
                                <DescriptionIconContainer key={"proposals.xeroDocumentAcc"} top={22} />
                                <TableRow>
                                    <CustomTableCell component="th" scope="row">
                                        <Typography variant="h2">
                                            {" " + (isBook ? t("proposals.xeroDocumentAccB") : t("proposals.xeroDocumentAcc"))}
                                        </Typography>
                                    </CustomTableCell>
                                    {(softInfo.name === "Software setup" && props.accountantOnboarding && !props.includeAdmModuleSet) ||
                                        (softInfo.name === "Software subscription" && props.accountantOnboarding && !props.includeAdmModuleSub) ?
                                        null : props.isAccountant ? <CustomTableCell colSpan={2} /> : <CustomTableCell colSpan={3} />
                                    }
                                    <CustomTableCell align="right">
                                        <CustomBoxBar sx={{ ml: (softInfo.name === "Software setup" && props.accountantOnboarding && !props.includeAdmModuleSet) ? -37 : null }} />
                                    </CustomTableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            )}
            {softInfo.banking.isActive && (
                <Box className={classes.scheduleBox}>
                    <TableContainer sx={{ overflowX: "auto" }}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow sx={{ "& th": { border: 0, pt: 1 } }}>
                                    <TableCell>
                                        <Typography variant="h2" sx={{ fontWeight: 700, fontSize: 12 }}>
                                            Kiiper Banking
                                        </Typography>
                                    </TableCell>
                                    {(softInfo.xero.isActive ? weeksBanking : weeksBanking1).map((week, index) => (
                                        <TableCell align="left">
                                            <CustomBox
                                                sx={{
                                                    backgroundColor: week.color,
                                                    width: sessionStorage.getItem("lng") === "en" ? "220%" : "235%",
                                                    ...(index === 2 && {
                                                        pr: "15px",
                                                        pl: "15px",
                                                        width: "110% !important",
                                                    }),
                                                    display: "flex",
                                                    justifyContent: "space-evenly",
                                                }}
                                            >
                                                <Typography
                                                    variant="span"
                                                    sx={{ display: "flex", alignItems: "center", fontSize: 12 }}
                                                    noWrap
                                                >
                                                    {week.label}
                                                </Typography>
                                                {index !== 2 && (
                                                    <Typography
                                                        variant="span"
                                                        sx={{ display: "flex", alignItems: "center", fontSize: 12 }}
                                                    >
                                                        &nbsp;
                                                    </Typography>
                                                )}
                                            </CustomBox>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <CustomTableCell component="th" scope="row">
                                        <Typography variant="h2">{isBook ? t("proposals.bankingAccSetB") : t("proposals.bankingAccSet")}</Typography>
                                    </CustomTableCell>
                                    <CustomTableCell align="right">
                                        <CustomBoxBar />
                                    </CustomTableCell>
                                </TableRow>
                                <TableRow>
                                    <CustomTableCell component="th" scope="row">
                                        <Typography variant="h2">{isBook ? t("proposals.formatAddB") : t("proposals.formatAdd")}</Typography>
                                    </CustomTableCell>
                                    <CustomTableCell align="right">
                                        <CustomBoxBar />
                                    </CustomTableCell>
                                </TableRow>
                                <TableRow>
                                    <CustomTableCell component="th" scope="row">
                                        <Typography variant="h2">{isBook ? t("proposals.capaB") : t("proposals.capa")}</Typography>
                                    </CustomTableCell>
                                    <CustomTableCell colSpan={1} />
                                    <CustomTableCell align="right">
                                        <CustomBoxBar />
                                    </CustomTableCell>
                                </TableRow>
                                <TableRow>
                                    <CustomTableCell component="th" scope="row">
                                        <Typography variant="h2">{isBook ? t("proposals.followB") : t("proposals.follow")}</Typography>
                                    </CustomTableCell>
                                    <CustomTableCell colSpan={2} />
                                    <CustomTableCell colSpan={2} align="right">
                                        <CustomBoxBar />
                                    </CustomTableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            )}
            {softInfo.taxes.isActive && (
                <>
                    <Box className={classes.scheduleBox}>
                        <TableContainer sx={{ overflowX: "auto" }}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow sx={{ "& th": { border: 0, pt: 1 } }}>
                                        <TableCell>
                                            <Typography variant="h2" sx={{ fontWeight: 700, fontSize: 12 }}>
                                                Kiiper Taxes
                                            </Typography>
                                        </TableCell>
                                        {(softInfo.xero.isActive ? weeksTaxes : weeksTaxes1).map((week, index) => (
                                            <TableCell align="left">
                                                <CustomBox
                                                    sx={{
                                                        backgroundColor: week.color,
                                                        width: sessionStorage.getItem("lng") === "en" ? "210%" : "235%",
                                                        ...(index === 2 && {
                                                            pr: "15px",
                                                            pl: "15px",
                                                            width: sessionStorage.getItem("lng") === "en" ? "105% !important" : "110% !important",
                                                        }),
                                                        display: "flex",
                                                        justifyContent: "space-evenly",
                                                    }}
                                                >
                                                    <Typography
                                                        variant="span"
                                                        sx={{ display: "flex", alignItems: "center", fontSize: 12 }}
                                                        noWrap
                                                    >
                                                        {week.label}
                                                    </Typography>
                                                    {index !== 2 && (
                                                        <Typography
                                                            variant="span"
                                                            sx={{ display: "flex", alignItems: "center", fontSize: 12 }}
                                                        >
                                                            &nbsp;
                                                        </Typography>
                                                    )}
                                                </CustomBox>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <CustomTableCell component="th" scope="row">
                                            <Typography variant="h2">{isBook ? t("proposals.orgSetupB") : t("proposals.orgSetup")}</Typography>
                                        </CustomTableCell>
                                        <CustomTableCell align="right">
                                            <CustomBoxBar />
                                        </CustomTableCell>
                                    </TableRow>
                                    <TableRow>
                                        <CustomTableCell component="th" scope="row">
                                            <Typography variant="h2">{isBook ? t("proposals.configFiscalModuleB") : t("proposals.configFiscalModule")}</Typography>
                                        </CustomTableCell>
                                        <CustomTableCell align="right">
                                            <CustomBoxBar />
                                        </CustomTableCell>
                                    </TableRow>
                                    <TableRow>
                                        <CustomTableCell component="th" scope="row">
                                            <Typography variant="h2">{isBook ? t("proposals.capaB") : t("proposals.capa")}</Typography>
                                        </CustomTableCell>
                                        <CustomTableCell colSpan={1} />
                                        <CustomTableCell align="right">
                                            <CustomBoxBar />
                                        </CustomTableCell>
                                    </TableRow>
                                    <TableRow>
                                        <CustomTableCell component="th" scope="row">
                                            <Typography variant="h2">{isBook ? t("proposals.followB") : t("proposals.follow")}</Typography>
                                        </CustomTableCell>
                                        <CustomTableCell colSpan={2} />
                                        <CustomTableCell colSpan={1} align="right">
                                            <CustomBoxBar />
                                        </CustomTableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                    <Typography variant="body1" sx={{ pt: 1, pl: 2, fontSize: 13 }}>
                        ** {isBook ? t("proposals.weekThreeBeginsBookB") : t("proposals.weekThreeBeginsBook")}
                    </Typography>
                </>
            )}
        </StyledContainer>
    );
};
