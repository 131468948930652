/** Components ----------------------------------------------------------- */
import { createTheme } from '@mui/material/styles';

/* Theme settings */
const theme = createTheme({
    mixins: {
        toolbar: {
            minHeight: 50,
        }
    },
    palette: {
        primary: {
            main: '#031851',
        },
        secondary: {
            main: '#ff5968',
        },
        text: {
            primary: '#031851',
            secondary: '#2f3190',
        },
    },
    typography: {
        htmlFontSize: 16,
        fontSize: 16,
        fontFamily: 'GothamRounded',
        fontWeightMedium: 600,
        h1: {
            fontFamily: 'GothamRounded',
            fontWeight: 600,
            fontSize: 18,
        },
        h2: {
            fontFamily: 'GothamRounded',
            fontWeight: 600,
            fontSize: 16,
        },
        h3: {
            fontFamily: 'GothamRounded',
            fontWeight: 600,
            fontSize: 15,
        },
        h4: {
            fontFamily: 'GothamRounded',
            fontWeight: 600,
            fontSize: 14,
        },
        h5: {
            fontFamily: 'GothamRounded',
            fontWeight: 700,
            fontSize: 18,
        },
        body1: {
            fontFamily: 'GothamRounded',
            fontWeight: 400,
            fontSize: 14,
        },
        body2: {
            fontFamily: 'GothamRounded',
            fontWeight: 400,
            fontSize: 15,
        },
        button: {
            fontFamily: 'GothamRounded',
            fontWeight: 600,
            fontSize: 15,
            textTransform: 'none',
        },
    },
    components: {
        MuiSelect: {
            styleOverrides: {
                icon: {
                    fill: '#031851',
                }
            },
        }
    },
    overrides: {
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: "#031851",
                '& button:hover': {
                    backgroundColor: "#FFEEF0"
                },
            }
        },
        MuiPickersCalendarHeader: {
            switchHeader: {
                '& span': {
                    color: "#031851",
                },
                '& p': {
                    fontWeight: "600",
                    color: "#031851",
                    fontSize: 14,
                },
            },
            daysHeader: {
                '& span': {
                    fontWeight: "600",
                    color: "#4F5D85",
                    fontSize: 10,
                },
            },
        },
        MuiPickersDay: {
            day: {
                color: "#031851",
                '& p': {
                    fontWeight: "600",
                    fontSize: 14,
                },
            },
            daySelected: {
                color: "#031851",
                backgroundColor: "#FFEEF0",
                '&:hover': {
                    backgroundColor: "#FFEEF0"
                },
            },
            current: {
                color: "#031851",
            },
        },
    }
});

export default theme;