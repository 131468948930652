/** React imports */
import React from "react";

/** MUI icons */
import DescriptionIcon from "@mui/icons-material/Description";

export const DescriptionIconContainer = ({ left, top }) => {
  return (
    <div style={{ position: "relative" }}>
      <DescriptionIcon
        color="primary"
        fontSize="small"
        sx={{
          position: "absolute",
          transform: "translateY(-50%)",
          left: left ? left : -3.5,
          top: top ? top : 26.5,
        }}
      />
    </div>
  );
};
