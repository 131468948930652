/** React imports */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

/** Images */

/** MUI icons */
import DescriptionIcon from "@mui/icons-material/Description";

/** MUI imports */
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

import { Footer } from "./";
import { ScheduleAcc } from "../AccountantProposal";
import { ScheduleBook, ScheduleBook2 } from "../BookkeeperProposal";
import { ScheduleCont } from "../ControllerProposal";
import { ScheduleSoft } from "../SoftwareProposal";
import { ScheduleCFO } from "../CFOProposal";

/** Global variables */
const PREFIX = "Schedule";

const classes = {
    body2: `${PREFIX}-body2`,
    scheduleTitle: `${PREFIX}-scheduleTitle`,
    scheduleBox: `${PREFIX}-scheduleBox`,
    fieldset: `${PREFIX}-fieldset`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.scheduleTitle}`]: {
        fontSize: 22,
    },

    [`& .${classes.body2}`]: {
        backgroundColor: "white",
        marginTop: 15,
        minHeight: 850,
        [theme.breakpoints.down("md")]: {
            padding: 30,
        },
        [theme.breakpoints.up("md")]: {
            padding: 60,
        },
    },

    [`& .${classes.scheduleBox}`]: {
        backgroundColor: "#F8F9F8",
        marginTop: 16,
        padding: 20,
        borderRadius: 30,
    },

    [`& .${classes.fieldset}`]: {
        border: "2px solid #031851",
        borderStyle: "solid solid none solid",
        lineHeight: 0,
    },
}));

export const Schedule = (props) => {
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    const { proposalDetails, mode } = props;

    const [info, setInfo] = useState([]);

    /** Global variables */

    useEffect(() => {
        let values = [];
        if (mode === "preview") {
            values = proposalDetails;
        } else {
            // Recorrer arreglo de servicios y crear nueva estructura
            let newObj = {
                onboarding: {
                    isActive: false,
                },
                cleanUp: {
                    isActive: false,
                },
                catchUp: {
                    isActive: false,
                },
                recurring: {
                    isActive: false,
                },
                consulting: {
                    isActive: false,
                },
            };
            let newObjCont = {
                onboarding: {
                    isActive: false,
                },
                catchUp: {
                    isActive: false,
                },
                recurring: {
                    isActive: false,
                },
                consulting: {
                    isActive: false,
                },
            };
            let newObjCFO = {
                onboarding: {
                    isActive: false,
                },
                catchUp: {
                    isActive: false,
                },
                recurring: {
                    isActive: false,
                },
                consulting: {
                    isActive: false,
                },
            };
            let newObjSetUp = {
                xero: {
                    isActive: false,
                },
                banking: {
                    isActive: false,
                },
                taxes: {
                    isActive: false,
                },
                reporting: {
                    isActive: false,
                },
                other: {
                    isActive: false,
                },
                consulting: {
                    isActive: false,
                },
            };

            proposalDetails.services.forEach((item) => {
                if (item.service_name === "Accountant") {
                    newObj.name = item.service_name;
                    if (proposalDetails.prospect) {
                        newObj.prospect = {
                            prospect_id: proposalDetails.prospect.prospect_id
                        }
                    }
                    
                    item.projects.forEach((value) => {
                        if (value.project_name === "Accountant onboarding") {
                            newObj.onboarding.isActive = true;
                        }
                        if (value.project_name === "Accountant cleanup") {
                            newObj.cleanUp.isActive = true;
                        }
                        if (value.project_name === "Accountant catchup") {
                            newObj.catchUp.isActive = true;
                        }
                        if (value.project_name === "Accountant recurring") {
                            newObj.recurring.isActive = true;
                        }
                        if (value.project_name === "Accountant consulting") {
                            newObj.consulting.isActive = true;
                        }
                    });
                    values.push(newObj);
                } else if (item.service_name === "Bookkeeper") {
                    newObj.name = item.service_name;
                    item.projects.forEach((value) => {
                        if (value.project_name === "SMBs' onboarding") {
                            newObj.onboarding.isActive = true;
                        }
                        if (value.project_name === "Bookkeeper cleanup") {
                            newObj.cleanUp.isActive = true;
                        }
                        if (value.project_name === "Bookkeeper catchup") {
                            newObj.catchUp.isActive = true;
                        }
                        if (value.project_name === "Bookkeeper recurring") {
                            newObj.recurring.isActive = true;
                        }
                        if (value.project_name === "Bookkeeper consulting") {
                            newObj.consulting.isActive = true;
                        }
                    });
                    values.push(newObj);
                } else if (item.service_name === "Controller") {
                    newObjCont.name = item.service_name;
                    item.projects.forEach((value) => {
                        if (value.project_name === "Controller onboarding") {
                            newObjCont.onboarding.isActive = true;
                        }
                        if (value.project_name === "Controller recurring") {
                            newObjCont.recurring.isActive = true;
                        }
                        if (value.project_name === "Controller consulting") {
                            newObjCont.consulting.isActive = true;
                        }
                    });
                    values.push(newObjCont);
                } else if (item.service_name === "CFO") {
                    newObjCFO.name = item.service_name;
                    item.projects.forEach((value) => {
                        if (value.project_name === "CFO onboarding") {
                            newObjCFO.onboarding.isActive = true;
                        }
                        if (value.project_name === "CFO catchup") {
                            newObjCFO.catchUp.isActive = true;
                        }
                        if (value.project_name === "CFO recurring") {
                            newObjCFO.recurring.isActive = true;
                        }
                    });
                    values.push(newObjCFO);
                } else if (item.service_name === "Software setup") {
                    newObjSetUp.name = item.service_name;
                    item.projects.forEach((value) => {
                        if (value.project_name === "Xero setup") {
                            newObjSetUp.xero.isActive = true;
                            newObjSetUp.xero.package = value.package;
                        }

                        if (value.project_name === "Kiiper Banking setup") {
                            newObjSetUp.banking.isActive = true;
                        }

                        if (value.project_name === "Kiiper Taxes setup") {
                            newObjSetUp.taxes.isActive = true;
                        }

                        if (value.project_name === "Xero consulting") {
                            newObjSetUp.consulting.isActive = true;
                        }

                        if (value.project_name === "Kiiper Reporting setup") {
                            newObjSetUp.reporting.isActive = true;
                        }

                        if (value.project_name === "Other setup") {
                            newObjSetUp.other.isActive = true;
                        }
                    });
                    values.push(newObjSetUp);
                }
            });
        }
        setInfo(values);
    }, [mode, proposalDetails, t]);

    return (
        <StyledContainer>
            {info.map(
                (item, index) =>
                    ((item.name !== "Software subscription" && item.name !== "Software setup") ||
                        ((item.name === "Software setup") && (item.banking.isActive || item.xero.isActive || item.taxes.isActive))) && (
                        <>
                            {
                                (item.name === "Bookkeeper" && (item.cleanUp.isActive || item.catchUp.isActive || props.includeOnboarding)) || item.name !== "Bookkeeper" ?
                                    (
                                        <>
                                            <Box className={classes.body2}>
                                                <Typography variant="h5" className={classes.scheduleTitle} sx={{ pb: 4 }}>
                                                    {(item.name === "Bookkeeper" || props.isBook) ? t("proposals.scheduleDeliverablesB") : t("proposals.scheduleDeliverables")}
                                                </Typography>
                                                <Box
                                                    display="flex"
                                                    justifyContent="space-between"
                                                    sx={{
                                                        pt: index > 0 ? 5 : 0,
                                                    }}
                                                >
                                                    <Typography variant="h5">{item.name}</Typography>
                                                    {item.name === "Software setup" && !item.xero.isActive ? null : (
                                                        <Typography
                                                            variant="h5"
                                                            sx={{ fontSize: 14, display: "flex", alignItems: "center" }}
                                                        >
                                                            {(item.name === "Bookkeeper" || props.isBook) ? t("proposals.deliverablesB") : t("proposals.deliverables")}
                                                            <DescriptionIcon sx={{ ml: 1 }} />
                                                        </Typography>
                                                    )}
                                                </Box>
                                                {item.name === "Accountant" && <ScheduleAcc accInfo={item} />}
                                                {item.name === "Bookkeeper" && (item.cleanUp.isActive || item.catchUp.isActive || props.includeOnboarding) && <ScheduleBook bookInfo={item} includeToolbox={props.includeToolbox} includeOnboarding={props.includeOnboarding} />}
                                                {item.name === "Controller" && <ScheduleCont contInfo={item} />}
                                                {item.name === "CFO" && <ScheduleCFO cfoInfo={item} />}
                                                {item.name === "Software setup" &&
                                                    <ScheduleSoft
                                                        softInfo={item}
                                                        includeAccModuleSet={props.includeAccModuleSet}
                                                        includeAccModuleSub={props.includeAccModuleSub}
                                                        includeAdmModuleSet={props.includeAdmModuleSet}
                                                        includeAdmModuleSub={props.includeAdmModuleSub}
                                                        accountantOnboarding={props.accountantOnboarding}
                                                        isAccountant={props.isAccountant}
                                                        isBook={props.isBook}
                                                    />
                                                }
                                            </Box>
                                            <Footer />
                                        </>
                                    ) : null
                            }
                            {item.name === "Bookkeeper" &&
                                (props.includeToolbox || item.recurring.isActive) && (
                                    <ScheduleBook2 bookInfo={item} includeToolbox={props.includeToolbox} />
                                )}
                        </>
                    )
            )}
        </StyledContainer>
    );
};
