/** React imports */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

/** Images */

/** MUI icons */

/** MUI imports */
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

import { Footer } from ".";
import { ServiceLevelsAcc } from "../AccountantProposal";
import { ServiceLevelsBook, ServiceLevelsBook2, ServiceLevelsTaxExpert } from "../BookkeeperProposal";
import { ServiceLevelsCFO } from "../CFOProposal";
import { ServiceLevelsCont } from "../ControllerProposal";
import { ServiceLevelsSoft } from "../SoftwareProposal";

/** Global variables */
const PREFIX = "ServiceLevels";

const classes = {
    body2: `${PREFIX}-body2`,
    serviceName: `${PREFIX}-serviceName`,
    serviceBody: `${PREFIX}-serviceBody`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.serviceName}`]: {
        fontSize: 22,
    },

    [`& .${classes.body2}`]: {
        backgroundColor: "white",
        marginTop: 15,
        minHeight: 850,
        [theme.breakpoints.down("md")]: {
            padding: 30,
        },
        [theme.breakpoints.up("md")]: {
            padding: 60,
        },
    },

    [`& .${classes.serviceBody}`]: {
        backgroundColor: "#F8F9F8",
        marginTop: 30,
        padding: 30,
        borderRadius: 30,
    },
}));

export const ServiceLevels = (props) => {
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    const { proposalDetails, mode } = props;

    const [info, setInfo] = useState([]);

    const setAllAdditionals = (value) => {
        let additionals = {};

        value.additionals.forEach((aux) => {
            switch (aux.additional_name) {
                case "Ejecución de planificación fiscal":
                    additionals.exe = true;
                    break;
                case "Planificación de administración y contabilidad":
                    additionals.cons = true;
                    break;
                case "Reportes para auditores externos":
                    additionals.rep = true;
                    break;
                case "A/R reconciliation":
                case "A/R":
                    additionals.ar = true;
                    break;
                case "A/P reconciliation":
                case "A/P":
                    additionals.ap = true;
                    break;
                case "Classes/Categories accounting":
                    additionals.classes = true;
                    break;
                case "Multi-entity accounting":
                    additionals.multi = true;
                    break;
                case "Personalized reporting":
                    additionals.person = true;
                    break;
                case "Checks":
                    additionals.checks = true;
                    break;
                case "Sales taxes":
                    additionals.sales = true;
                    break;
                case "Ordinario":
                    additionals.ordinary = true;
                    break;
                case "Especial":
                    additionals.special = true;
                    break;
                case "Municipal":
                    additionals.muni = true;
                    break;
                case "Accrual":
                    additionals.accrual = true;
                    break;
                case "Gobierno corporativo":
                    additionals.gob = true;
                    break;
                case "Presentaciones financieras":
                    additionals.pres = true;
                    break;
                case "Reportes para inversionistas":
                    additionals.repInv = true;
                    break;
                default:
                    additionals.other = true;
                    break;
            }
        });

        return additionals;
    };

    useEffect(() => {
        let values = [];
        if (mode === "preview") {
            values = proposalDetails;
        } else {
            // Recorrer arreglo de servicios y crear nueva estructura
            let newObjSoftSetup = {
                xero: {
                    isActive: false,
                },
                banking: {
                    isActive: false,
                },
                taxes: {
                    isActive: false,
                },
            };
            let newObjSoftSubs = {
                xero: {
                    isActive: false,
                },
                banking: {
                    isActive: false,
                },
                taxes: {
                    isActive: false,
                },
            };
            let newObjCont = {
                onboarding: {
                    isActive: false,
                },
                catchUp: {
                    isActive: false,
                    additionals: [],
                },
                recurring: {
                    isActive: false,
                    additionals: [],
                },
                genAdditionals: {},
            };
            let newObjCFO = {
                onboarding: {
                    isActive: false,
                },
                catchUp: {
                    isActive: false,
                    additionals: [],
                },
                recurring: {
                    isActive: false,
                    additionals: [],
                },
                genAdditionals: {},
            };
            let newObj = {
                onboarding: {
                    isActive: false,
                },
                cleanUp: {
                    isActive: false,
                    additionals: [],
                },
                catchUp: {
                    isActive: false,
                    additionals: [],
                },
                recurring: {
                    isActive: false,
                    additionals: [],
                },
                consulting: {
                    isActive: false,
                },
                genAdditionals: {},
            };
            proposalDetails.services.forEach((item) => {
                if (item.service_name === "Accountant") {
                    newObj.name = item.service_name;
                    item.projects.forEach((value) => {
                        let add = setAllAdditionals(value);

                        if (value.project_name === "Accountant onboarding") {
                            newObj.onboarding.isActive = true;
                        }
                        if (value.project_name === "Accountant cleanup") {
                            newObj.cleanUp.isActive = true;
                            newObj.cleanUp.additionals = value.additionals;
                            newObj.cleanUp.onlyAdd = value.additionals.length === 1 && value.additionals_only;
                            newObj.genAdditionals = { ...newObj.genAdditionals, ...add };
                        }
                        if (value.project_name === "Accountant catchup") {
                            newObj.catchUp.isActive = true;
                            newObj.catchUp.additionals = value.additionals;
                            newObj.catchUp.onlyAdd = value.additionals.length === 1 && value.additionals_only;
                            newObj.genAdditionals = { ...newObj.genAdditionals, ...add };
                        }
                        if (value.project_name === "Accountant recurring") {
                            newObj.recurring.isActive = true;
                            newObj.recurring.additionals = value.additionals;
                            newObj.recurring.onlyAdd = value.additionals.length === 1 && value.additionals_only;
                            newObj.genAdditionals = { ...newObj.genAdditionals, ...add };
                        }
                        if (value.project_name === "Accountant consulting") {
                            newObj.consulting.isActive = true;
                        }
                    });
                    values.push(newObj);
                } else if (item.service_name === "Bookkeeper") {
                    newObj.name = item.service_name;

                    item.projects.forEach((value) => {
                        let add = setAllAdditionals(value);

                        if (value.project_name === "SMBs' onboarding") {
                            newObj.onboarding.isActive = true;
                        }
                        if (value.project_name === "Bookkeeper cleanup") {
                            newObj.cleanUp.isActive = true;
                            newObj.cleanUp.additionals = value.additionals;
                            newObj.cleanUp.onlyAdd = value.additionals.length === 1 && value.additionals_only;
                            newObj.genAdditionals = { ...newObj.genAdditionals, ...add };
                        }
                        if (value.project_name === "Bookkeeper catchup") {
                            newObj.catchUp.isActive = true;
                            newObj.catchUp.additionals = value.additionals;
                            newObj.catchUp.onlyAdd = value.additionals.length === 1 && value.additionals_only;
                            newObj.genAdditionals = { ...newObj.genAdditionals, ...add };
                        }
                        if (value.project_name === "Bookkeeper recurring") {
                            newObj.recurring.isActive = true;
                            newObj.recurring.additionals = value.additionals;
                            newObj.recurring.onlyAdd = value.additionals.length === 1 && value.additionals_only;
                            newObj.genAdditionals = { ...newObj.genAdditionals, ...add };
                        }
                        if (value.project_name === "Bookkeeper consulting") {
                            newObj.consulting.isActive = true;
                        }
                    });

                    if (proposalDetails) {
                        if (proposalDetails.prospect) {
                            proposalDetails.prospect.prospect_id = proposalDetails?.prospect_id;
                        }
                    }

                    values.push(newObj);
                } else if (item.service_name === "CFO") {
                    newObjCFO.name = item.service_name;
                    item.projects.forEach((value) => {
                        let add = setAllAdditionals(value);

                        if (value.project_name === "CFO onboarding") {
                            newObjCFO.onboarding.isActive = true;
                        }
                        if (value.project_name === "CFO catchup") {
                            newObjCFO.catchUp.isActive = true;
                            newObjCFO.catchUp.additionals = value.additionals;
                            newObjCFO.catchUp.onlyAdd = value.additionals.length === 1 && value.additionals_only;
                            newObjCFO.genAdditionals = { ...newObjCFO.genAdditionals, ...add };
                        }
                        if (value.project_name === "CFO recurring") {
                            newObjCFO.recurring.isActive = true;
                            newObjCFO.recurring.additionals = value.additionals;
                            newObjCFO.recurring.onlyAdd = value.additionals.length === 1 && value.additionals_only;
                            newObjCFO.genAdditionals = { ...newObjCFO.genAdditionals, ...add };
                        }
                    });
                    values.push(newObjCFO);
                } else if (item.service_name === "Controller") {
                    newObjCont.name = item.service_name;
                    item.projects.forEach((value) => {
                        let add = setAllAdditionals(value);

                        if (value.project_name === "Controller onboarding") {
                            newObjCont.onboarding.isActive = true;
                        }
                        if (value.project_name === "Controller catchup") {
                            newObjCont.catchUp.isActive = true;
                            newObjCont.catchUp.additionals = value.additionals;
                            newObjCont.catchUp.onlyAdd = value.additionals.length === 1 && value.additionals_only;
                            newObjCont.genAdditionals = { ...newObjCont.genAdditionals, ...add };
                        }
                        if (value.project_name === "Controller recurring") {
                            newObjCont.recurring.isActive = true;
                            newObjCont.recurring.additionals = value.additionals;
                            newObjCont.recurring.onlyAdd = value.additionals.length === 1 && value.additionals_only;
                            newObjCont.genAdditionals = { ...newObjCont.genAdditionals, ...add };
                        }
                    });
                    values.push(newObjCont);
                } else if (item.service_name === "Software setup") {
                    newObjSoftSetup.name = item.service_name;

                    item.projects.forEach((value) => {
                        if (value.project_name === "Xero setup") {
                            newObjSoftSetup.xero.isActive = true;
                            newObjSoftSetup.xero.package = value.package;
                        }
                        if (value.project_name === "Kiiper Banking setup") {
                            newObjSoftSetup.banking.isActive = true;
                            newObjSoftSetup.banking.package = value.package;
                        }
                        if (value.project_name === "Kiiper Taxes setup") {
                            newObjSoftSetup.taxes.isActive = true;
                            newObjSoftSetup.taxes.package = value.package;
                        }
                    });
                    values.push(newObjSoftSetup);
                } else if (item.service_name === "Software subscription") {
                    newObjSoftSubs.name = item.service_name;

                    item.projects.forEach((value) => {
                        if (value.project_name === "Xero subscription") {
                            newObjSoftSubs.xero.isActive = true;
                            newObjSoftSubs.xero.version = value.version;
                        }
                        if (value.project_name === "Kiiper Banking subscription") {
                            newObjSoftSubs.banking.isActive = true;
                            newObjSoftSubs.banking.version = value.version;
                        }
                        if (value.project_name === "Kiiper Taxes subscription") {
                            newObjSoftSubs.taxes.isActive = true;
                            newObjSoftSubs.taxes.version = value.version;
                        }
                    });
                    values.push(newObjSoftSubs);
                }
            });
        }
        setInfo(values);
    }, [mode, proposalDetails]);

    return (
        <StyledContainer>
            {info.map(
                (item, index) =>
                    ((item.name.includes("Software") &&
                        (item.xero.isActive || item.banking.isActive || item.taxes.isActive)) ||
                        !item.name.includes("Software")) && (
                        <>
                            {
                                item.name === "Bookkeeper" &&
                                <>
                                    {(props.includeOnboarding || props.includeToolbox) &&
                                        (
                                            <>
                                                <Box className={classes.body2}>
                                                    <Typography
                                                        variant="h5"
                                                        className={classes.serviceName}
                                                    >
                                                        {item.name}
                                                    </Typography>
                                                    <ServiceLevelsBook
                                                        includeOnboarding={props.includeOnboarding}
                                                        includeToolbox={props.includeToolbox}
                                                        includeAccModuleSet={props.includeAccModuleSet}
                                                        includeAccModuleSub={props.includeAccModuleSub}
                                                        includeAdmModuleSet={props.includeAdmModuleSet}
                                                        includeAdmModuleSub={props.includeAdmModuleSub}
                                                    />
                                                </Box>
                                                <Footer />
                                            </>
                                        )}
                                    {(item.cleanUp.isActive || item.catchUp.isActive || item.recurring.isActive) &&
                                        (
                                            <>
                                                <ServiceLevelsBook2
                                                    bookInfo={item}
                                                    includeAccModuleSet={props.includeAccModuleSet}
                                                    includeAccModuleSub={props.includeAccModuleSub}
                                                    includeAdmModuleSet={props.includeAdmModuleSet}
                                                    includeAdmModuleSub={props.includeAdmModuleSub}
                                                />
                                                {((proposalDetails[0]?.prospect?.prospect_id == "669580b61461f7cc543681dd" || proposalDetails?.prospect?.prospect_id == "669580b61461f7cc543681dd") ||
                                                (proposalDetails[0]?.prospect?.prospect_id == "66b18d9f1a7779f7a5be1fee" || proposalDetails?.prospect?.prospect_id == "66b18d9f1a7779f7a5be1fee") || 
                                                (proposalDetails?.prospect_id == "66b18d9f1a7779f7a5be1fee" || proposalDetails?.prospect_id == "66b18d9f1a7779f7a5be1fee")) &&
                                                    (
                                                        <ServiceLevelsTaxExpert
                                                            bookInfo={item}
                                                            includeAccModuleSet={props.includeAccModuleSet}
                                                            includeAccModuleSub={props.includeAccModuleSub}
                                                            includeAdmModuleSet={props.includeAdmModuleSet}
                                                            includeAdmModuleSub={props.includeAdmModuleSub}
                                                        />
                                                    )
                                                }
                                            </>
                                        )}
                                </>
                            }
                            {
                                item.name !== "Bookkeeper" &&
                                <>
                                    <Box className={classes.body2}>
                                        <Typography
                                            variant="h5"
                                            className={classes.serviceName}
                                        >
                                            {item.name}
                                        </Typography>
                                        {item.name === "Bookkeeper" ? (
                                            null
                                        ) : (
                                            <Box className={classes.serviceBody}>
                                                <Typography variant="h5" sx={{ pb: 4 }}>
                                                    {props.isBook ? t("proposals.scopeWorkB") : t("proposals.scopeWork")}
                                                </Typography>
                                                {item.name === "Accountant" && <ServiceLevelsAcc
                                                    accInfo={item}
                                                    includeAccModuleSet={props.includeAccModuleSet}
                                                    includeAccModuleSub={props.includeAccModuleSub}
                                                    includeAdmModuleSet={props.includeAdmModuleSet}
                                                    includeAdmModuleSub={props.includeAdmModuleSub}
                                                    accountantOnboarding={props.accountantOnboarding}
                                                />                                                
                                                }
                                                {item.name === "CFO" && <ServiceLevelsCFO cfoInfo={item} />}
                                                {item.name === "Controller" && <ServiceLevelsCont contInfo={item} />}
                                                {item.name.includes("Software") && <ServiceLevelsSoft
                                                    softInfo={item}
                                                    includeAccModuleSet={props.includeAccModuleSet}
                                                    includeAccModuleSub={props.includeAccModuleSub}
                                                    includeAdmModuleSet={props.includeAdmModuleSet}
                                                    includeAdmModuleSub={props.includeAdmModuleSub}
                                                    accountantOnboarding={props.accountantOnboarding}
                                                    isBook={props.isBook}
                                                    proposalDetails={proposalDetails}
                                                />}
                                            </Box>
                                            )}
                                    </Box>
                                    <Footer />
                                    {(item.name === "Accountant" && ((proposalDetails[0]?.prospect?.prospect_id == "669580b61461f7cc543681dd" || proposalDetails?.prospect?.prospect_id == "669580b61461f7cc543681dd") ||
                                    (proposalDetails[0]?.prospect?.prospect_id == "66b18d9f1a7779f7a5be1fee" || proposalDetails?.prospect?.prospect_id == "66b18d9f1a7779f7a5be1fee") || 
                                    (proposalDetails?.prospect_id == "66b18d9f1a7779f7a5be1fee" || proposalDetails?.prospect_id == "66b18d9f1a7779f7a5be1fee")) &&
                                        (
                                            <ServiceLevelsTaxExpert
                                                bookInfo={item}
                                                includeAccModuleSet={props.includeAccModuleSet}
                                                includeAccModuleSub={props.includeAccModuleSub}
                                                includeAdmModuleSet={props.includeAdmModuleSet}
                                                includeAdmModuleSub={props.includeAdmModuleSub}
                                            />
                                        )
                                    )}
                                </>
                            }
                        </>
                    )
            )}
        </StyledContainer >
    );
};
