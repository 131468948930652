/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

/** MUI imports */
import { styled } from "@mui/material/styles";
import { Box, Chip, Grid, Typography, Divider } from "@mui/material";

/** Component imports */
import { Recurring } from './';

/** Component styles */
const PREFIX = "CompilationRoot";

const classes = {
    cardContentRoot: `${PREFIX}-cardContentRoot`,
    chip: `${PREFIX}-chip`,
    selectedChip: `${PREFIX}-selectedChip`,
    divider: `${PREFIX}-divider`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.cardContentRoot}`]: {
        padding: "24px 48px 48px",
    },

    [`& .${classes.chip}`]: {
        padding: 20,
        width: 200,
        backgroundColor: "#EAEAF4",
        color: "#031851",
        fontWeight: "700 !important",
        "&:hover": {
            backgroundColor: "#EAEAF4",
            color: "#031851",
        },
        borderRadius: 40,
    },

    [`& .${classes.selectedChip}`]: {
        padding: 20,
        width: 200,
        backgroundColor: "#2f3190",
        color: "#fff",
        fontWeight: "700 !important",
        "&:hover": {
            backgroundColor: "#2f3190",
            color: "#fff",
        },
        borderRadius: 40,
    },

    [`& .${classes.divider}`]: {
        marginBottom: 10,
    },
}));

export const CompilationRoot = (props) => {
    // Properties
    const {
        setTexto,
        setalertType,
        setShow,
        setBackDrop,
        clientMarket,
        expenseEst,
        accountSystem,
        serviceData,
        companyID,
    } = props;

    /** Internationalization i18n */
    const [t] = useTranslation("global");
    

    /* Component states */
    const [valuesCompilation, setValuesCompilation] = useState({
        compilationRecu: false,
    });
    // const [projectsData, setProjectsData] = useState({
    //     compilationRecu: {},
    // });

    /* Component functions */
    useEffect(() => {
        loadProjects()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [serviceData]);

    const loadProjects = async () => {
        setBackDrop(true);

        await fetch(
            `/getProjectsCompany?company_id=${companyID}&service_id=61e9cdcde1143e17c0f74a07&service_company_id=${
                serviceData ? serviceData._id : ""
            }`,
            {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            }
        )
            .then((res) => res.json())
            .then((data) => {
                let result = {};

                data.forEach((item) => {
                    switch (item.project_type) {
                        default:
                            result.compilationRecu = item;
                            break;
                    }
                });

                // setProjectsData({
                //     compilationRecu: result.compilationRecu,
                // });
            });

        setBackDrop(false);
    }

    const handleClickChip = async (value, option) => {
        switch (option) {
            default:
                setValuesCompilation({
                    ...valuesCompilation,
                    compilationRecu: !value,
                });
                break;
        }
    };

    return (
        <StyledContainer>
            <Divider className={classes.divider} />
            <Grid
                container
                justifyContent="space-around"
                alignItems="center"
                spacing={2}
                sx={{ pb: 2 }}
            >
                <Grid item>
                    <Chip
                        id="recurring-chip"
                        label={
                            <Typography
                                variant="h2"
                                align="center"
                                sx={{ fontWeight: 700 }}
                            >{t(`invoicing.compilation`)}</Typography>
                        }
                        className={
                            valuesCompilation.compilationRecu
                                ? classes.selectedChip
                                : classes.chip
                        }
                        onClick={() =>
                            handleClickChip(
                                valuesCompilation.compilationRecu,
                                "compilationRecu"
                            )
                        }
                    />
                </Grid>
            </Grid>
            {valuesCompilation.compilationRecu && (
                <Recurring
                    setTexto={setTexto}
                    setalertType={setalertType}
                    setShow={setShow}
                    setBackDrop={setBackDrop}
                    clientMarket={clientMarket}
                    companyID={companyID}
                    expenseEst={expenseEst}
                    accountSystem={accountSystem}
                    // data={projectsData.compilationRecu}
                    // reloadServices={reloadServices}
                />
            )}
        </StyledContainer>
    );
};
