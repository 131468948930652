// React
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useSelector } from "react-redux";
import moment from "moment";

// MUI
import {
    Box,
    TextField,
    Grid,
    Typography,
    FormControl,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    InputLabel,
    InputAdornment,
    Select,
    MenuItem,
    Paper,
} from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { styled } from "@mui/material/styles";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";

/** MUI icons */
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CheckIcon from "@mui/icons-material/Check";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const PREFIX = "Other";

const classes = {
    btnRoot: `${PREFIX}-btnRoot`,
    buttonContainer: `${PREFIX}-buttonContainer`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    backgroundColor: "#F8F9F8",
    marginTop: 16,
    padding: 30,
    borderRadius: 30,

    [`& .${classes.btnRoot}`]: {
        border: "1px solid #ff5968",
        borderRadius: 40,
        "&:hover": {
            backgroundColor: "#2f3190",
            border: "1px solid #2f3190",
        },
    },
    [`& .${classes.buttonContainer}`]: {
        display: "flex",
        justifyContent: "flex-end",
        padding: 15,
    },
}));

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)({
    backgroundColor: "#F8F9F8",
    boxShadow: "none",
    "&:before": {
        display: "none",
    },
});

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: "#031851" }} />} {...props} />
))({
    "& .MuiAccordionSummary-content": {
        margin: "12px 0",
    },
});

const AccordionDetails = styled(MuiAccordionDetails)({
    padding: "0px 0px 20px 0px",
    "& .MuiCard-root": {
        boxShadow: "none",
    },
});

const filter = createFilterOptions();

export const Other = (props) => {
    // Properties
    const { other, setOther, isError, isErrorFocus, setIsErrorFocus } = props;
    const [softwares, setSoftwares] = useState([]);

    /** Internationalization i18n */
    const [t] = useTranslation("global");

    /** React redux */
    const todos = useSelector((state) => state.todos);

    useEffect(() => {
        (async () => {
            await fetch(`/getSoftwares`, {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
                .then((res) => res.json())
                .then((data) => {
                    setSoftwares(data.softwares);
                });
        })();
    }, []);

    /** Component functions */
    const handleChange = (event) => {
        setOther({
            ...other,
            [event.target.name]: event.target.value,
            projectTotal: event.target.value * other.originalFee * (1 - other.discount / 100),
            isError: false,
        });
        setIsErrorFocus(false);
    };

    const handleChangeDiscount = (event) => {
        let discountAux = event.target.value >= 0 && event.target.value <= 100 ? event.target.value : other.discount;

        setOther({
            ...other,
            discount: discountAux,
            projectTotal: other.originalFee * other.hours * (1 - discountAux / 100),
            isError: false,
        });
        setIsErrorFocus(false);
    };

    const handleChangePackage = (event) => {
        setOther({
            ...other,
            package: event.target.value,
            isError: false,
        });
        setIsErrorFocus(false);
    };

    const handleChangeSoftwares = (event, value, reason) => {
        if (reason === "clear" || reason === "createOption") {
            setOther({
                ...other,
                software: "",
            });
        } else {
            if (value && value.inputValue) {
                // Create a new value from the user input
                setOther({
                    ...other,
                    software: value.inputValue,
                    isError: false,
                });
            } else {
                setOther({
                    ...other,
                    software: value.software_name,
                    isError: false,
                });
            }
        }
        setIsErrorFocus(false);
    };

    const handleChangeEstDeliDate = (date) => {
        setOther({
            ...other,
            commitmentDateProposal: date,
            isError: false,
        });
        setIsErrorFocus(false);
    };

    return (
        <StyledContainer>
            <Grid container alignItems="flex-start" spacing={2}>
                <Grid item xs={12}>
                    <Typography
                        variant="h1"
                        color="primary"
                        sx={{
                            display: "inline-block",
                            fontWeight: 700,
                            pr: 6,
                        }}
                    >
                        Other setup
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Accordion
                        TransitionProps={{ unmountOnExit: true }}
                        sx={{
                            borderBottom: "3px solid #ff5968",
                        }}
                    >
                        <AccordionSummary aria-controls="panel-content" id="panel-header">
                            <Typography
                                variant="h2"
                                align="center"
                                sx={{
                                    fontWeight: 700,
                                }}
                            >
                                {t("invoicing.description")}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <List>
                                <ListItem disableGutters alignItems="center">
                                    <ListItemIcon sx={{ minWidth: 35 }}>
                                        <CheckIcon fontSize="small" sx={{ color: "#031851" }} />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <Typography variant="body1">
                                                <span style={{ fontWeight: 600 }}>{t("proposals.intel")} </span>-{" "}
                                                {t("proposals.ïntelDet")}
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                                <ListItem disableGutters alignItems="center">
                                    <ListItemIcon sx={{ minWidth: 35 }}>
                                        <CheckIcon fontSize="small" sx={{ color: "#031851" }} />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <Typography variant="body1">
                                                <span style={{ fontWeight: 600 }}>{t("proposals.design")} </span>-{" "}
                                                {t("proposals.designDet")}
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                                <ListItem disableGutters alignItems="center">
                                    <ListItemIcon sx={{ minWidth: 35 }}>
                                        <CheckIcon fontSize="small" sx={{ color: "#031851" }} />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <Typography variant="body1">
                                                <span style={{ fontWeight: 600 }}>{t("principal.settings")} </span>-{" "}
                                                {t("proposals.settingDet")}
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                            </List>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <FormControl fullWidth required error={isError && other.package === ""}>
                        <InputLabel id="ver-simple-select-label">{t("services.package")}</InputLabel>
                        <Select
                            name="package"
                            labelId="ver-simple-select-label"
                            IconComponent={KeyboardArrowDownIcon}
                            value={other.package}
                            label={t("services.package")}
                            onChange={handleChangePackage}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        mt: 0.7,
                                        borderRadius: 2,
                                        boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                    },
                                },
                            }}
                            onClick={(e) => {
                                setIsErrorFocus(false);
                            }}
                            inputRef={(input) => {
                                input && isErrorFocus && isError && other.package === "" && input.focus();
                            }}
                        >
                            <MenuItem value={4}>{t("currentView.personal")}</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <Autocomplete
                        id="software-autocomplete"
                        value={other.software}
                        onChange={(event, value, reason) => handleChangeSoftwares(event, value, reason)}
                        filterOptions={(options, params) => {
                            const filtered = filter(options, params);

                            const { inputValue } = params;
                            // Suggest the creation of a new value
                            const isExisting = options.some((option) => inputValue === option.software_name);
                            if (inputValue !== "" && !isExisting) {
                                filtered.push({
                                    inputValue,
                                    software_name: `${t("services.addSoft")} "${inputValue}"`,
                                });
                            }

                            return filtered;
                        }}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        options={softwares}
                        getOptionLabel={(option) => {
                            // Value selected with enter, right from the input
                            if (typeof option === "string") {
                                return option;
                            }
                            // Add "xxx" option created dynamically
                            if (option.inputValue) {
                                return option.inputValue;
                            }
                            // Regular option
                            return option.software_name;
                        }}
                        renderOption={(props, option) => <li {...props}>{option.software_name}</li>}
                        renderInput={(params) => (
                            <TextField required {...params} error={isError && !other.software} label="Software" />
                        )}
                        popupIcon={<KeyboardArrowDownIcon color="primary" />}
                        PaperComponent={({ children }) => (
                            <Paper
                                sx={{
                                    mt: 0.7,
                                    borderRadius: 2,
                                    boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                }}
                            >
                                {children}
                            </Paper>
                        )}
                        onClick={(e) => {
                            setIsErrorFocus(false);
                        }}
                        inputRef={(input) => {
                            input && isErrorFocus && isError && !other.software && input.focus();
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label={t("miscellaneous.hours")}
                                name="hours"
                                onChange={handleChange}
                                value={other.hours}
                                variant="outlined"
                                type="number"
                                inputProps={{
                                    min: 1,
                                    step: 0.5,
                                    onKeyDown: (event) => {
                                        event.preventDefault();
                                    },
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label={t("miscellaneous.discount")}
                                value={other.discount}
                                name="discount"
                                onChange={handleChangeDiscount}
                                onBlur={() =>
                                    setOther({
                                        ...other,
                                        discount: parseFloat(other.discount === "" ? 0 : other.discount).toFixed(2),
                                    })
                                }
                                variant="outlined"
                                type="number"
                                InputProps={{
                                    inputProps: {
                                        min: 0,
                                        max: 100,
                                    },
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                }}
                                sx={{
                                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                        display: "none",
                                    },
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <LocalizationProvider
                        dateAdapter={AdapterMoment}
                        adapterLocale={t("language.locale")}
                        localeText={{ clearButtonLabel: t("miscellaneous.clear") }}
                    >
                        <DatePicker
                            componentsProps={{
                                actionBar: { actions: ["clear"] },
                            }}
                            value={other.commitmentDateProposal}
                            minDate={moment()}
                            onChange={(newValue) => handleChangeEstDeliDate(newValue)}
                            inputFormat={todos.dateFormat}
                            renderInput={(params) => (
                                <TextField
                                    fullWidth
                                    required
                                    {...params}
                                    error={isError && !other.commitmentDateProposal}
                                />
                            )}
                            onClick={(e) => {
                                setIsErrorFocus(false);
                            }}
                            label={t("services.estimDeliveryDate")}
                            views={["year", "month", "day"]}
                            inputRef={(input) => {
                                input && isErrorFocus && isError && !other.commitmentDateProposal && input.focus();
                            }}
                        />
                    </LocalizationProvider>
                </Grid>
            </Grid>
        </StyledContainer>
    );
};
