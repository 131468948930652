/** React imports */
import React from "react";
import { useTranslation } from "react-i18next";

/** Images */

/** MUI icons */


/** MUI imports */
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { DescriptionIconContainer } from "./DescriptionIconContainer";

/** Global variables */
const PREFIX = "ScheduleAcc";

const classes = {
    body2: `${PREFIX}-body2`,
    scheduleTitle: `${PREFIX}-scheduleTitle`,
    scheduleBox: `${PREFIX}-scheduleBox`,
    fieldset: `${PREFIX}-fieldset`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.scheduleTitle}`]: {
        fontSize: 22,
    },

    [`& .${classes.body2}`]: {
        backgroundColor: "white",
        marginTop: 15,
        minHeight: 850,
        [theme.breakpoints.down("md")]: {
            padding: 30,
        },
        [theme.breakpoints.up("md")]: {
            padding: 60,
        },
    },

    [`& .${classes.scheduleBox}`]: {
        backgroundColor: "#F8F9F8",
        marginTop: 16,
        padding: 20,
        borderRadius: 30,
    },

    [`& .${classes.fieldset}`]: {
        border: "2px solid #031851",
        borderStyle: "solid solid none solid",
        lineHeight: 0,
        whiteSpace: "nowrap",
    },
}));

const CustomBox = styled(Box)(({ theme }) => ({
    borderRadius: "24px",
    color: "#fff",
    height: 30,
}));

const CustomBoxBar = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 6,
    height: 12,
    width: "100%",
}));

const CustomTableCell = styled(TableCell)(({ theme }) => ({
    borderBottom: 0,
    paddingRight: 0,
    [`& h2`]: {
        fontSize: 12,
    },
    [`& span`]: {
        fontSize: 12,
    },
}));

export const ScheduleAcc = (props) => {
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    const { accInfo } = props;

    /** Global variables */
    const weeksOnboarding = [
        { color: "#8283bc", label: t("proposals.week1") },
        { color: "#6d6fb1", label: t("proposals.week2") },
        { color: "#595aa6", label: t("proposals.week3") },
        { color: "#44469b", label: t("proposals.week4") },
        { color: "#2f3190", label: t("proposals.week5to8") },
    ];

    const weeksUpdates = [
        { color: "#8283bc", label: t("proposals.week1") },
        { color: "#6d6fb1", label: t("proposals.week2") },
        { color: "#595aa6", label: t("proposals.week3") },
        { color: "#44469b", label: t("proposals.week4") },
        { color: "#2f3190", label: t("proposals.week5") },
    ];

    const weeksUpdatesOnb = [
        { color: "#2D348B80", label: t("proposals.week4") },
        { color: "#6d6fb1", label: t("proposals.week5") },
        { color: "#595aa6", label: t("proposals.week6") },
        { color: "#44469b", label: t("proposals.week7") },
        { color: "#2f3190", label: t("proposals.week8") },
    ];

    const weeksRecurring = [
        { color: "#8a8cc5", label: t("proposals.week1") },
        { color: "#9798c8", label: t("proposals.week2") },
        { color: "#8283bc", label: t("proposals.week3") },
        { color: "#6d6fb1", label: t("proposals.week4") },
        { color: "#595aa6", label: t("proposals.week1") },
        { color: "#44469b", label: t("proposals.week2") },
        { color: "#2f3190", label: t("proposals.week3") },
    ];

    return (
        <StyledContainer>
            {accInfo.onboarding.isActive && (
                <Box className={classes.scheduleBox}>
                    <TableContainer sx={{ overflowX: "auto" }}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow sx={{ "& th": { border: 0, pt: 1 } }}>
                                    <TableCell>
                                        <Typography variant="h2" sx={{ fontWeight: 700, fontSize: 12 }}>
                                            {t("proposals.onboard")}*
                                        </Typography>
                                    </TableCell>
                                    {weeksOnboarding.map((week, index) => (
                                        <TableCell align="left">
                                            <CustomBox
                                                sx={{
                                                    backgroundColor: week.color,
                                                    width: sessionStorage.getItem("lng") === "en" ? "220%" : "230%",
                                                    ...(index === 4 && {
                                                        pr: "14px",
                                                        pl: "14px",
                                                        width: sessionStorage.getItem("lng") === "en" ? "112% !important" : "114% !important",
                                                    }),
                                                    display: "flex",
                                                    justifyContent: "space-evenly",
                                                }}
                                            >
                                                <Typography
                                                    variant="span"
                                                    sx={{ display: "flex", alignItems: "center", fontSize: 12 }}
                                                    noWrap
                                                >
                                                    {week.label}
                                                </Typography>
                                                {index !== 4 && (
                                                    <Typography
                                                        variant="span"
                                                        sx={{ display: "flex", alignItems: "center", fontSize: 12 }}
                                                    >
                                                        &nbsp;
                                                    </Typography>
                                                )}
                                            </CustomBox>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <CustomTableCell component="th" scope="row">
                                        <Typography variant="h2">{t("proposals.intel")}</Typography>
                                    </CustomTableCell>
                                    <CustomTableCell align="right">
                                        <CustomBoxBar />
                                    </CustomTableCell>
                                </TableRow>
                                <TableRow>
                                    <CustomTableCell component="th" scope="row">
                                        <Typography variant="h2">{t("proposals.design")}</Typography>
                                    </CustomTableCell>
                                    <CustomTableCell align="right">
                                        <CustomBoxBar />
                                    </CustomTableCell>
                                </TableRow>
                                <TableRow>
                                    <CustomTableCell component="th" scope="row">
                                        <Typography variant="h2">{t("proposals.accountingModuleConfig")}</Typography>
                                    </CustomTableCell>
                                    <CustomTableCell colSpan={1} />
                                    <CustomTableCell align="right" colSpan={2}>
                                        <CustomBoxBar />
                                    </CustomTableCell>
                                </TableRow>
                                <TableRow>
                                    <CustomTableCell component="th" scope="row">
                                        <Typography variant="h2">{t("proposals.capa")}</Typography>
                                    </CustomTableCell>
                                    <CustomTableCell colSpan={3} />
                                    <CustomTableCell align="right" colSpan={1}>
                                        <CustomBoxBar />
                                    </CustomTableCell>
                                </TableRow>
                                <TableRow>
                                    <CustomTableCell component="th" scope="row">
                                        <Typography variant="h2">{t("proposals.follow")}</Typography>
                                    </CustomTableCell>
                                    <CustomTableCell colSpan={4} />
                                    <CustomTableCell align="right" colSpan={1}>
                                        <CustomBoxBar />
                                    </CustomTableCell>
                                </TableRow>
                                <DescriptionIconContainer key={"proposals.xeroDocument"} top={27} />
                                <TableRow >
                                    <CustomTableCell component="th" scope="row">
                                        <Typography component="span" variant="h2">
                                            {" "  + (accInfo?.prospect?.prospect_id === "66bcba001a7779f7a5c17170" ? "\"Go-live\"" : t("proposals.xeroDocumentAcc"))}
                                        </Typography>
                                    </CustomTableCell>
                                    <CustomTableCell colSpan={3} />
                                    <CustomTableCell align="right">
                                        <CustomBoxBar />
                                    </CustomTableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Typography variant="body1" sx={{ pt: 2, pl: 2, fontSize: 13 }}>
                        * {t("proposals.weekOneBegins")}
                    </Typography>
                </Box>
            )}
            {(accInfo.cleanUp.isActive || accInfo.catchUp.isActive) && (
                <Box className={classes.scheduleBox}>
                    <TableContainer sx={{ overflowX: "auto" }}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow sx={{ "& th": { border: 0, pt: 1 } }}>
                                    <TableCell>
                                        <Typography variant="h2" sx={{ fontWeight: 700, fontSize: 12 }}>
                                            {t("proposals.catchupCleanup")}**
                                        </Typography>
                                    </TableCell>
                                    {(accInfo.onboarding.isActive ? weeksUpdatesOnb : weeksUpdates).map((week, index) => (
                                        <TableCell align="left">
                                            <CustomBox
                                                sx={{
                                                    backgroundColor: week.color,
                                                    width: sessionStorage.getItem("lng") === "en" ? "220%" : "235%",
                                                    ...(index === 4 && {
                                                        pr: "16px",
                                                        pl: "16px",
                                                        width: "115% !important",
                                                    }),
                                                    display: "flex",
                                                    justifyContent: "space-evenly",
                                                }}
                                            >
                                                <Typography
                                                    variant="span"
                                                    sx={{ display: "flex", alignItems: "center", fontSize: 12 }}
                                                    noWrap
                                                >
                                                    {week.label}
                                                </Typography>
                                                {index !== 4 && (
                                                    <Typography
                                                        variant="span"
                                                        sx={{ display: "flex", alignItems: "center", fontSize: 12 }}
                                                    >
                                                        &nbsp;
                                                    </Typography>
                                                )}
                                            </CustomBox>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <CustomTableCell component="th" scope="row">
                                        <Typography variant="h2">{t("proposals.bankRec")}</Typography>
                                    </CustomTableCell>
                                    <CustomTableCell align="right" colSpan={3}>
                                        <CustomBoxBar />
                                    </CustomTableCell>
                                </TableRow>
                                <TableRow>
                                    <CustomTableCell component="th" scope="row">
                                        <Typography variant="h2">{t("proposals.accounting")}</Typography>
                                    </CustomTableCell>
                                    <CustomTableCell colSpan={2} />
                                    <CustomTableCell align="right" colSpan={2}>
                                        <CustomBoxBar />
                                    </CustomTableCell>
                                </TableRow>
                                <DescriptionIconContainer key={"proposals.finannState"} />
                                <TableRow>
                                    <CustomTableCell component="th" scope="row">
                                        <Typography component="span" variant="h2">
                                            {" " + t("proposals.finannState")}
                                        </Typography>
                                    </CustomTableCell>
                                    <CustomTableCell colSpan={3} />
                                    <CustomTableCell align="right">
                                        <CustomBoxBar />
                                    </CustomTableCell>
                                </TableRow>
                                <TableRow>
                                    <CustomTableCell component="th" scope="row">
                                        <Typography variant="h2">{t("proposals.virtualMeet")}</Typography>
                                    </CustomTableCell>
                                    <CustomTableCell colSpan={4} />
                                    <CustomTableCell align="right">
                                        <CustomBoxBar />
                                    </CustomTableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Typography variant="body1" sx={{ pt: 2, pl: 2, fontSize: 13 }}>
                        ** {t("proposals.projectSchedContemplate")}
                    </Typography>
                </Box>
            )}
            {accInfo.recurring.isActive && (
                <Box className={classes.scheduleBox}>
                    <TableContainer sx={{ overflowX: "auto" }}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow sx={{ "& th": { border: 0 } }}>
                                    <TableCell />
                                    <TableCell colSpan={4} sx={{ pt: 0, pb: 0, pr: 0 }}>
                                        <fieldset className={classes.fieldset}>
                                            <legend align="center" style={{ paddingLeft: 8, paddingRight: 8, fontSize: 11 }}>
                                                {t("proposals.eachMonth")}
                                            </legend>
                                        </fieldset>
                                    </TableCell>
                                    <TableCell colSpan={2} sx={{ pt: 0, pb: 0, pr: 0 }}>
                                        <fieldset className={classes.fieldset}>
                                            <legend align="center" style={{ paddingLeft: 8, paddingRight: 8, fontSize: 11 }}>
                                                {t("proposals.nextMonth")}
                                            </legend>
                                        </fieldset>
                                    </TableCell>
                                    <TableCell colSpan={3} sx={{ pt: 0, pb: 0, pr: 0 }}>
                                        <fieldset className={classes.fieldset}>
                                            <legend align="center" style={{
                                                paddingLeft: 2,
                                                paddingRight: 2,
                                                fontSize: 11
                                            }}>
                                                {t("proposals.eachQuarter")}
                                            </legend>
                                        </fieldset>
                                    </TableCell>
                                </TableRow>
                                <TableRow sx={{ "& th": { border: 0, pt: 1 } }}>
                                    <TableCell>
                                        <Typography variant="h2" sx={{ fontWeight: 700, fontSize: 12 }}>
                                            {t("proposals.recurring2")}
                                        </Typography>
                                    </TableCell>
                                    {weeksRecurring.map((week, index) => (
                                        <TableCell align="left">
                                            <CustomBox
                                                sx={{
                                                    backgroundColor: week.color,
                                                    width: sessionStorage.getItem("lng") === "en" ? "260%" : "260%",
                                                    ...(index === 6 && {
                                                        pr: sessionStorage.getItem("lng") === "en" ? "25px" : "21px",
                                                        pl: sessionStorage.getItem("lng") === "en" ? "25px" : "21px",
                                                        width:
                                                            sessionStorage.getItem("lng") === "en"
                                                                ? "117% !important"
                                                                : "112% !important",
                                                    }),
                                                    display: "flex",
                                                    justifyContent: "space-evenly",
                                                }}
                                            >
                                                <Typography
                                                    variant="span"
                                                    sx={{ display: "flex", alignItems: "center", fontSize: 12 }}
                                                    noWrap
                                                >
                                                    {week.label}
                                                </Typography>
                                                {index !== 6 && (
                                                    <Typography
                                                        variant="span"
                                                        sx={{ display: "flex", alignItems: "center", fontSize: 12 }}
                                                    >
                                                        &nbsp;
                                                    </Typography>
                                                )}
                                            </CustomBox>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <CustomTableCell component="th" scope="row">
                                        <Typography variant="h2">{t("proposals.bankRec")}</Typography>
                                    </CustomTableCell>
                                    <CustomTableCell />
                                    <CustomTableCell align="right" colSpan={4}>
                                        <CustomBoxBar />
                                    </CustomTableCell>
                                </TableRow>
                                <TableRow>
                                    <CustomTableCell component="th" scope="row">
                                        <Typography variant="h2">{t("proposals.accounting")}</Typography>
                                    </CustomTableCell>
                                    <CustomTableCell colSpan={3} />
                                    <CustomTableCell align="right" colSpan={2}>
                                        <CustomBoxBar />
                                    </CustomTableCell>
                                </TableRow>
                                <DescriptionIconContainer key={"proposals.finannState"} top={sessionStorage.getItem("lng") === "en" ? 38 : undefined} />
                                <TableRow>
                                    <CustomTableCell component="th" scope="row">
                                        <Typography component="span" variant="h2">
                                            {" " + t("proposals.finannState")}
                                        </Typography>
                                    </CustomTableCell>
                                    <CustomTableCell colSpan={5} />
                                    <CustomTableCell align="right">
                                        <CustomBoxBar />
                                    </CustomTableCell>
                                </TableRow>
                                <TableRow>
                                    <CustomTableCell component="th" scope="row">
                                        <Typography variant="h2">{t("proposals.virtualMeet")}</Typography>
                                    </CustomTableCell>
                                    <CustomTableCell colSpan={6} />
                                    <CustomTableCell align="right">
                                        <CustomBoxBar />
                                    </CustomTableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            )}
        </StyledContainer>
    );
};
