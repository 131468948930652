/** React imports */
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

/** Images */

/** MUI icons */

/** MUI imports */
import { Box, Typography, Grid, Divider } from "@mui/material";
import { styled } from "@mui/material/styles";

/** Global variables */
const PREFIX = "PricingSoft";

const classes = {
    body2: `${PREFIX}-body2`,
    serviceName: `${PREFIX}-serviceName`,
    subName: `${PREFIX}-subName`,
    serviceBody: `${PREFIX}-serviceBody`,
    subInfo: `${PREFIX}-subInfo`,
    roundBack: `${PREFIX}-roundBack`,
    roundBack2: `${PREFIX}-roundBack2`,
    roundBack3: `${PREFIX}-roundBack3`,
    months: `${PREFIX}-months`,
    monthsText1: `${PREFIX}-monthsText1`,
    monthsText2: `${PREFIX}-monthsText2`,
    body3: `${PREFIX}-body3`,
    textFoot: `${PREFIX}-textFoot`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.serviceName}`]: {
        fontSize: 22,
        marginBottom: 10,
    },

    [`& .${classes.subName}`]: {
        fontSize: 12,
        marginBottom: 10,
    },

    [`& .${classes.body2}`]: {
        backgroundColor: "white",
        marginTop: 15,
        padding: 30,
        minHeight: 850,
    },

    [`& .${classes.body3}`]: {
        padding: 30,
    },

    [`& .${classes.textFoot}`]: {
        padding: "15px 30px",
    },

    [`& .${classes.serviceBody}`]: {
        backgroundColor: "#F8F9F8",
        marginTop: 30,
        padding: 30,
        borderRadius: 30,
        overflow: "auto",
    },

    [`& .${classes.subInfo}`]: {
        marginTop: 30,
        fontSize: 12,
    },

    [`& .${classes.roundBack}`]: {
        backgroundColor: "#6D70AD",
        minWidth: "125px",
        marginRight: "-27px",
        justifyContent: "center",
        display: "flex",
    },
    [`& .${classes.roundBack2}`]: {
        backgroundColor: "#2D348B",
        minWidth: "130px",
        justifyContent: "center",
        display: "flex",
    },

    [`& .${classes.roundBack3}`]: {
        color: "#031851",
        minWidth: "115px",
        marginRight: "-25px",
        justifyContent: "center",
        display: "flex",
    },

    [`& .${classes.months}`]: {
        fontSize: 14,
    },
    [`& .${classes.monthsText1}`]: {
        fontSize: 16,
        justifyContent: "center",
        display: "flex",
    },
    [`& .${classes.monthsText2}`]: {
        fontSize: 16,
        justifyContent: "center",
        display: "flex",
        marginRight: "-25px",
    },
}));

export const PricingSoft = (props) => {
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    /** React redux */
    const todos = useSelector((state) => state.todos);

    const { pricingSoft, isBook } = props;

    const getNamesAdd = (values) => {
        let name = "";
        if (values.additional_name === "Módulo de Expenses") {
            name = "Expenses claims";
        } else if (values.additional_name === "Módulo de Projects") {
            name = "Projects";
        } else if (values.additional_name === "Analytics Plus") {
            name = "Analytics Plus";
        } else {
            name = values.additional_name;
        }

        return name;
    };

    return (
        <StyledContainer>
            {pricingSoft.xero.isActive && (
                <>
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={2}
                        sx={{ paddingBottom: 1.5, paddingTop: 1.5 }}
                    >
                        <Grid item xs={3}>
                            <Typography variant="h4">Xero</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="body1" sx={{ textAlign: "center" }}>
                                {pricingSoft.name === "Software setup"
                                    ? t(isBook ? `proposals.oneTime2B` : `proposals.oneTime2`)
                                    : t(isBook ? "miscellaneous.monthlyB" : "miscellaneous.monthly")}
                            </Typography>
                        </Grid>
                        <Grid item xs={1} sx={{ textAlign: "center" }}>
                            <Typography variant="body1">
                                {" "}
                                {pricingSoft.xero.additionals.length > 0 ? "-" : pricingSoft.xero.hours ?? 1}
                            </Typography>
                        </Grid>
                        <Grid item xs={1} sx={{ textAlign: "right" }}>
                            <Typography variant="body1">
                                {pricingSoft.xero.additionals.length > 0
                                    ? "-"
                                    : "$" +
                                    parseFloat(pricingSoft.xero.fee).toLocaleString(todos.amountFormat, {
                                        maximumFractionDigits: 2,
                                    })}
                            </Typography>
                        </Grid>
                        <Grid item xs={2} sx={{ textAlign: "right" }}>
                            <Typography variant="body1">
                                {pricingSoft.xero.additionals.length > 0
                                    ? "-"
                                    : "$" +
                                    parseFloat(pricingSoft.xero.fee * (pricingSoft.xero.hours ?? 1)).toLocaleString(
                                        todos.amountFormat,
                                        {
                                            maximumFractionDigits: 2,
                                        }
                                    )}
                            </Typography>
                        </Grid>
                        <Grid item xs={1} sx={{ textAlign: "center" }}>
                            <Typography variant="body1">
                                {pricingSoft.xero.additionals.length > 0
                                    ? "-"
                                    : parseFloat(pricingSoft.xero.discount).toLocaleString(todos.amountFormat, {
                                        maximumFractionDigits: 2,
                                    }) + "%"}
                            </Typography>
                        </Grid>
                        <Grid item xs={2} sx={{ textAlign: "right" }}>
                            <Typography variant="body1">
                                $
                                {parseFloat(pricingSoft.xero.total).toLocaleString(todos.amountFormat, {
                                    maximumFractionDigits: 2,
                                })}
                            </Typography>
                        </Grid>
                    </Grid>
                    {pricingSoft.xero.additionals.length > 0 && (
                        <>
                            <Grid
                                container
                                alignItems="center"
                                justifyContent="space-between"
                                spacing={2}
                                sx={{ paddingBottom: 1.5, paddingTop: 1.5 }}
                            >
                                <Grid item xs={3}>
                                    <Typography variant="body1" sx={{ paddingLeft: 6, wordBreak: "break-word" }}>
                                        {isBook ? t("proposals.coreServB") : t("proposals.coreServ")}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}></Grid>
                                <Grid item xs={1} sx={{ textAlign: "center" }}>
                                    <Typography variant="body1">1</Typography>
                                </Grid>
                                <Grid item xs={1} sx={{ textAlign: "right" }}>
                                    <Typography variant="body1">
                                        $
                                        {parseFloat(pricingSoft.xero.fee).toLocaleString(todos.amountFormat, {
                                            maximumFractionDigits: 2,
                                        })}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2} sx={{ textAlign: "right" }}>
                                    <Typography variant="body1">
                                        $
                                        {parseFloat(pricingSoft.xero.fee).toLocaleString(todos.amountFormat, {
                                            maximumFractionDigits: 2,
                                        })}
                                    </Typography>
                                </Grid>
                                <Grid item xs={1} sx={{ textAlign: "center" }}>
                                    <Typography variant="body1">
                                        {parseFloat(pricingSoft.xero.discount).toLocaleString(todos.amountFormat, {
                                            maximumFractionDigits: 2,
                                        })}
                                        %
                                    </Typography>
                                </Grid>
                                <Grid item xs={2} sx={{ textAlign: "right" }}>
                                    <Typography variant="body1">
                                        $
                                        {parseFloat(
                                            pricingSoft.xero.fee * (1 - pricingSoft.xero.discount / 100)
                                        ).toLocaleString(todos.amountFormat, {
                                            maximumFractionDigits: 2,
                                        })}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider
                                sx={{
                                    backgroundColor: "#031851",
                                }}
                            />
                            <Grid
                                container
                                alignItems="center"
                                justifyContent="space-between"
                                spacing={2}
                                sx={{ paddingBottom: 1.5, paddingTop: 1.5 }}
                            >
                                <Grid item xs={3}>
                                    <Typography variant="body1" sx={{ paddingLeft: 6, wordBreak: "break-word" }}>
                                        {isBook ? t("proposals.addonsB") : t("proposals.addons")}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}></Grid>
                                <Grid item xs={1} sx={{ textAlign: "center" }}>
                                    <Typography variant="body1">{pricingSoft.xero.totalUsers}</Typography>
                                </Grid>
                                <Grid item xs={1} sx={{ textAlign: "right" }}>
                                    <Typography variant="body1">-</Typography>
                                </Grid>
                                <Grid item xs={2} sx={{ textAlign: "right" }}>
                                    <Typography variant="body1">
                                        $
                                        {parseFloat(pricingSoft.xero.totalAddNoDiscount).toLocaleString(
                                            todos.amountFormat,
                                            {
                                                maximumFractionDigits: 2,
                                            }
                                        )}
                                    </Typography>
                                </Grid>
                                <Grid item xs={1} sx={{ textAlign: "center" }}>
                                    <Typography variant="body1">
                                        {parseFloat(pricingSoft.xero.discount).toLocaleString(todos.amountFormat, {
                                            maximumFractionDigits: 2,
                                        })}
                                        %
                                    </Typography>
                                </Grid>
                                <Grid item xs={2} sx={{ textAlign: "right" }}>
                                    <Typography variant="body1">
                                        $
                                        {parseFloat(pricingSoft.xero.totalAdds).toLocaleString(todos.amountFormat, {
                                            maximumFractionDigits: 2,
                                        })}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </>
                    )}
                    {pricingSoft.xero.additionals.map((value) => (
                        <>
                            <Divider
                                sx={{
                                    backgroundColor: "#031851",
                                }}
                            />
                            <Grid
                                container
                                alignItems="center"
                                justifyContent="space-between"
                                spacing={2}
                                sx={{ paddingBottom: 1, paddingTop: 1 }}
                            >
                                <Grid item xs={3}>
                                    <Typography variant="body1" sx={{ paddingLeft: 8, wordBreak: "break-word" }}>
                                        {getNamesAdd(value)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}></Grid>
                                <Grid item xs={1} sx={{ textAlign: "center" }}>
                                    <Typography variant="body1">
                                        {value.additional_name === "Analytics Plus" ? value.hours / 7 : value.hours}
                                    </Typography>
                                </Grid>
                                <Grid item xs={1} sx={{ textAlign: "right" }}>
                                    <Typography variant="body1">
                                        $
                                        {parseFloat(
                                            value.additional_name === "Módulo de Expenses" ? 4 : 7
                                        ).toLocaleString(todos.amountFormat, {
                                            maximumFractionDigits: 2,
                                        })}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2} sx={{ textAlign: "right" }}>
                                    <Typography variant="body1">
                                        $
                                        {parseFloat(
                                            value.additional_name === "Módulo de Expenses"
                                                ? 4 * value.hours
                                                : value.additional_name === "Módulo de Projects"
                                                    ? 7 + 5 * (value.hours - 1)
                                                    : 7
                                        ).toLocaleString(todos.amountFormat, {
                                            maximumFractionDigits: 2,
                                        })}
                                    </Typography>
                                </Grid>
                                <Grid item xs={1} sx={{ textAlign: "center" }}>
                                    <Typography variant="body1">
                                        {parseFloat(pricingSoft.xero.discount).toLocaleString(todos.amountFormat, {
                                            maximumFractionDigits: 2,
                                        })}
                                        %
                                    </Typography>
                                </Grid>
                                <Grid item xs={2} sx={{ textAlign: "right" }}>
                                    <Typography variant="body1">
                                        $
                                        {parseFloat(
                                            value.additional_name === "Módulo de Expenses"
                                                ? 4 * (value.hours * (1 - pricingSoft.xero.discount / 100))
                                                : value.additional_name === "Módulo de Projects"
                                                    ? (7 + 5 * (value.hours - 1)) * (1 - pricingSoft.xero.discount / 100)
                                                    : 7 * (1 - pricingSoft.xero.discount / 100)
                                        ).toLocaleString(todos.amountFormat, {
                                            maximumFractionDigits: 2,
                                        })}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </>
                    ))}
                    {(pricingSoft.banking.isActive ||
                        pricingSoft.reporting.isActive ||
                        pricingSoft.taxes.isActive ||
                        (pricingSoft.other && pricingSoft.other.isActive) ||
                        (pricingSoft.support && pricingSoft.support.isActive) ||
                        (pricingSoft.consulting && pricingSoft.consulting.isActive)) && (
                            <Divider
                                sx={{
                                    backgroundColor: "#031851",
                                }}
                            />
                        )}
                </>
            )}

            {pricingSoft.banking.isActive && (
                <>
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={2}
                        sx={{ paddingBottom: 1.5, paddingTop: 1.5 }}
                    >
                        <Grid item xs={3}>
                            <Typography variant="h4">Kiiper banking</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="body1" sx={{ textAlign: "center" }}>
                                {pricingSoft.name === "Software setup"
                                    ? t(isBook ? `proposals.oneTime2B` : `proposals.oneTime2`)
                                    : t(isBook ? `miscellaneous.monthlyB` : "miscellaneous.monthly")}
                            </Typography>
                        </Grid>
                        <Grid item xs={1} sx={{ textAlign: "center" }}>
                            <Typography variant="body1">{pricingSoft.banking.hours ?? 1}</Typography>
                        </Grid>
                        <Grid item xs={1} sx={{ textAlign: "right" }}>
                            <Typography variant="body1">
                                {"$" +
                                    parseFloat(pricingSoft.banking.fee).toLocaleString(todos.amountFormat, {
                                        maximumFractionDigits: 2,
                                    })}
                            </Typography>
                        </Grid>
                        <Grid item xs={2} sx={{ textAlign: "right" }}>
                            <Typography variant="body1">
                                {"$" +
                                    parseFloat(
                                        pricingSoft.banking.fee * (pricingSoft.banking.hours ?? 1)
                                    ).toLocaleString(todos.amountFormat, {
                                        maximumFractionDigits: 2,
                                    })}
                            </Typography>
                        </Grid>
                        <Grid item xs={1} sx={{ textAlign: "center" }}>
                            <Typography variant="body1">
                                {parseFloat(pricingSoft.banking.discount).toLocaleString(todos.amountFormat, {
                                    maximumFractionDigits: 2,
                                }) + "%"}
                            </Typography>
                        </Grid>
                        <Grid item xs={2} sx={{ textAlign: "right" }}>
                            <Typography variant="body1">
                                $
                                {parseFloat(pricingSoft.banking.total).toLocaleString(todos.amountFormat, {
                                    maximumFractionDigits: 2,
                                })}
                            </Typography>
                        </Grid>
                    </Grid>
                    {(pricingSoft.reporting.isActive ||
                        pricingSoft.taxes.isActive ||
                        (pricingSoft.other && pricingSoft.other.isActive) ||
                        (pricingSoft.support && pricingSoft.support.isActive) ||
                        (pricingSoft.consulting && pricingSoft.consulting.isActive)) && (
                            <Divider
                                sx={{
                                    backgroundColor: "#031851",
                                }}
                            />
                        )}
                </>
            )}

            {pricingSoft.reporting.isActive && (
                <>
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={2}
                        sx={{ paddingBottom: 1.5, paddingTop: 1.5 }}
                    >
                        <Grid item xs={3}>
                            <Typography variant="h4">Kiiper reporting</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="body1" sx={{ textAlign: "center" }}>
                                {pricingSoft.name === "Software setup"
                                    ? t(isBook ? `proposals.oneTime2B` : `proposals.oneTime2`)
                                    : t(isBook ? "miscellaneous.monthlyB" : "miscellaneous.monthly")}
                            </Typography>
                        </Grid>
                        <Grid item xs={1} sx={{ textAlign: "center" }}>
                            <Typography variant="body1">{pricingSoft.reporting.hours ?? 1}</Typography>
                        </Grid>
                        <Grid item xs={1} sx={{ textAlign: "right" }}>
                            <Typography variant="body1">
                                {"$" +
                                    parseFloat(pricingSoft.reporting.fee).toLocaleString(todos.amountFormat, {
                                        maximumFractionDigits: 2,
                                    })}
                            </Typography>
                        </Grid>
                        <Grid item xs={2} sx={{ textAlign: "right" }}>
                            <Typography variant="body1">
                                {"$" +
                                    parseFloat(
                                        pricingSoft.reporting.fee * (pricingSoft.reporting.hours ?? 1)
                                    ).toLocaleString(todos.amountFormat, {
                                        maximumFractionDigits: 2,
                                    })}
                            </Typography>
                        </Grid>
                        <Grid item xs={1} sx={{ textAlign: "center" }}>
                            <Typography variant="body1">
                                {parseFloat(pricingSoft.reporting.discount).toLocaleString(todos.amountFormat, {
                                    maximumFractionDigits: 2,
                                }) + "%"}
                            </Typography>
                        </Grid>
                        <Grid item xs={2} sx={{ textAlign: "right" }}>
                            <Typography variant="body1">
                                $
                                {parseFloat(pricingSoft.reporting.total).toLocaleString(todos.amountFormat, {
                                    maximumFractionDigits: 2,
                                })}
                            </Typography>
                        </Grid>
                    </Grid>
                    {(pricingSoft.taxes.isActive ||
                        (pricingSoft.other && pricingSoft.other.isActive) ||
                        (pricingSoft.support && pricingSoft.support.isActive) ||
                        (pricingSoft.consulting && pricingSoft.consulting.isActive)) && (
                            <Divider
                                sx={{
                                    backgroundColor: "#031851",
                                }}
                            />
                        )}
                </>
            )}

            {pricingSoft.taxes.isActive && (
                <>
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={2}
                        sx={{ paddingBottom: 1.5, paddingTop: 1.5 }}
                    >
                        <Grid item xs={3}>
                            <Typography variant="h4">Kiiper taxes</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="body1" sx={{ textAlign: "center" }}>
                                {pricingSoft.name === "Software setup"
                                    ? t(isBook ? `proposals.oneTime2B` : `proposals.oneTime2`)
                                    : t(isBook ? "miscellaneous.monthlyB" : "miscellaneous.monthly")}
                            </Typography>
                        </Grid>
                        <Grid item xs={1} sx={{ textAlign: "center" }}>
                            <Typography variant="body1">{pricingSoft.taxes.hours ?? 1}</Typography>
                        </Grid>
                        <Grid item xs={1} sx={{ textAlign: "right" }}>
                            <Typography variant="body1">
                                {"$" +
                                    parseFloat(pricingSoft.taxes.fee).toLocaleString(todos.amountFormat, {
                                        maximumFractionDigits: 2,
                                    })}
                            </Typography>
                        </Grid>
                        <Grid item xs={2} sx={{ textAlign: "right" }}>
                            <Typography variant="body1">
                                {"$" +
                                    parseFloat(pricingSoft.taxes.fee * (pricingSoft.taxes.hours ?? 1)).toLocaleString(
                                        todos.amountFormat,
                                        {
                                            maximumFractionDigits: 2,
                                        }
                                    )}
                            </Typography>
                        </Grid>
                        <Grid item xs={1} sx={{ textAlign: "center" }}>
                            <Typography variant="body1">
                                {parseFloat(pricingSoft.taxes.discount).toLocaleString(todos.amountFormat, {
                                    maximumFractionDigits: 2,
                                }) + "%"}
                            </Typography>
                        </Grid>
                        <Grid item xs={2} sx={{ textAlign: "right" }}>
                            <Typography variant="body1">
                                $
                                {parseFloat(pricingSoft.taxes.total).toLocaleString(todos.amountFormat, {
                                    maximumFractionDigits: 2,
                                })}
                            </Typography>
                        </Grid>
                    </Grid>
                    {((pricingSoft.other && pricingSoft.other.isActive) ||
                        (pricingSoft.support && pricingSoft.support.isActive) ||
                        (pricingSoft.consulting && pricingSoft.consulting.isActive)) && (
                            <Divider
                                sx={{
                                    backgroundColor: "#031851",
                                }}
                            />
                        )}
                </>
            )}

            {pricingSoft.other && pricingSoft.other.isActive && (
                <>
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={2}
                        sx={{ paddingBottom: 1.5, paddingTop: 1.5 }}
                    >
                        <Grid item xs={3}>
                            <Typography variant="h4">{pricingSoft.other.software}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="body1" sx={{ textAlign: "center" }}>
                                {pricingSoft.name === "Software setup"
                                    ? t(isBook ? `proposals.oneTime2B` : `proposals.oneTime2`)
                                    : t(isBook ? "miscellaneous.monthlyB" : "miscellaneous.monthly")}
                            </Typography>
                        </Grid>
                        <Grid item xs={1} sx={{ textAlign: "center" }}>
                            <Typography variant="body1">{pricingSoft.other.hours ?? 1}</Typography>
                        </Grid>
                        <Grid item xs={1} sx={{ textAlign: "right" }}>
                            <Typography variant="body1">
                                {"$" +
                                    parseFloat(pricingSoft.other.fee).toLocaleString(todos.amountFormat, {
                                        maximumFractionDigits: 2,
                                    })}
                            </Typography>
                        </Grid>
                        <Grid item xs={2} sx={{ textAlign: "right" }}>
                            <Typography variant="body1">
                                {"$" +
                                    parseFloat(pricingSoft.other.fee * (pricingSoft.other.hours ?? 1)).toLocaleString(
                                        todos.amountFormat,
                                        {
                                            maximumFractionDigits: 2,
                                        }
                                    )}
                            </Typography>
                        </Grid>
                        <Grid item xs={1} sx={{ textAlign: "center" }}>
                            <Typography variant="body1">
                                {parseFloat(pricingSoft.other.discount).toLocaleString(todos.amountFormat, {
                                    maximumFractionDigits: 2,
                                }) + "%"}
                            </Typography>
                        </Grid>
                        <Grid item xs={2} sx={{ textAlign: "right" }}>
                            <Typography variant="body1">
                                $
                                {parseFloat(pricingSoft.other.total).toLocaleString(todos.amountFormat, {
                                    maximumFractionDigits: 2,
                                })}
                            </Typography>
                        </Grid>
                    </Grid>
                    {((pricingSoft.support && pricingSoft.support.isActive) ||
                        (pricingSoft.consulting && pricingSoft.consulting.isActive)) && (
                            <Divider
                                sx={{
                                    backgroundColor: "#031851",
                                }}
                            />
                        )}
                </>
            )}

            {pricingSoft.support && pricingSoft.support.isActive && (
                <>
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={2}
                        sx={{ paddingBottom: 1.5, paddingTop: 1.5 }}
                    >
                        <Grid item xs={3}>
                            <Typography variant="h4">Customer Support</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="body1" sx={{ textAlign: "center" }}>
                                {pricingSoft.name === "Software setup"
                                    ? t(isBook ? `proposals.oneTime2B` : `proposals.oneTime2`)
                                    : t(isBook ? "miscellaneous.monthlyB" : "miscellaneous.monthly")}
                            </Typography>
                        </Grid>
                        <Grid item xs={1} sx={{ textAlign: "center" }}>
                            <Typography variant="body1">{pricingSoft.support.hours}</Typography>
                        </Grid>
                        <Grid item xs={1} sx={{ textAlign: "right" }}>
                            <Typography variant="body1">
                                {"$" +
                                    parseFloat(pricingSoft.support.fee).toLocaleString(todos.amountFormat, {
                                        maximumFractionDigits: 2,
                                    })}
                            </Typography>
                        </Grid>
                        <Grid item xs={2} sx={{ textAlign: "right" }}>
                            <Typography variant="body1">
                                {"$" +
                                    parseFloat(pricingSoft.support.fee).toLocaleString(todos.amountFormat, {
                                        maximumFractionDigits: 2,
                                    })}
                            </Typography>
                        </Grid>
                        <Grid item xs={1} sx={{ textAlign: "center" }}>
                            <Typography variant="body1">
                                {parseFloat(pricingSoft.support.discount).toLocaleString(todos.amountFormat, {
                                    maximumFractionDigits: 2,
                                }) + "%"}
                            </Typography>
                        </Grid>
                        <Grid item xs={2} sx={{ textAlign: "right" }}>
                            <Typography variant="body1">
                                $
                                {parseFloat(pricingSoft.support.total).toLocaleString(todos.amountFormat, {
                                    maximumFractionDigits: 2,
                                })}
                            </Typography>
                        </Grid>
                    </Grid>
                    {pricingSoft.consulting && pricingSoft.consulting.isActive && (
                        <Divider
                            sx={{
                                backgroundColor: "#031851",
                            }}
                        />
                    )}
                </>
            )}

            {pricingSoft.consulting &&
                pricingSoft.consulting.isActive &&
                pricingSoft.consulting.array.map((consult, index) => (
                    <>
                        <Grid
                            container
                            alignItems="center"
                            justifyContent="space-between"
                            spacing={2}
                            sx={{ paddingBottom: 1.5, paddingTop: 1.5 }}
                        >
                            <Grid item xs={3}>
                                <Typography variant="h4">{consult.description}</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography variant="body1" sx={{ textAlign: "center" }}>
                                    {isBook ? t(`proposals.oneTime2B`) : t(`proposals.oneTime2`)}
                                </Typography>
                            </Grid>
                            <Grid item xs={1} sx={{ textAlign: "center" }}>
                                <Typography variant="body1">{consult.hours}</Typography>
                            </Grid>
                            <Grid item xs={1} sx={{ textAlign: "right" }}>
                                <Typography variant="body1">
                                    $
                                    {parseFloat(pricingSoft.consulting.fee).toLocaleString(todos.amountFormat, {
                                        maximumFractionDigits: 2,
                                    })}
                                </Typography>
                            </Grid>
                            <Grid item xs={2} sx={{ textAlign: "right" }}>
                                <Typography variant="body1">
                                    $
                                    {parseFloat(pricingSoft.consulting.fee * consult.hours).toLocaleString(
                                        todos.amountFormat,
                                        {
                                            maximumFractionDigits: 2,
                                        }
                                    )}
                                </Typography>
                            </Grid>
                            <Grid item xs={1} sx={{ textAlign: "center" }}>
                                <Typography variant="body1">
                                    {parseFloat(consult.discount).toLocaleString(todos.amountFormat, {
                                        maximumFractionDigits: 2,
                                    })}
                                    %
                                </Typography>
                            </Grid>
                            <Grid item xs={2} sx={{ textAlign: "right" }}>
                                <Typography variant="body1">
                                    $
                                    {parseFloat(
                                        pricingSoft.consulting.fee * consult.hours * (1 - consult.discount / 100)
                                    ).toLocaleString(todos.amountFormat, {
                                        maximumFractionDigits: 2,
                                    })}
                                </Typography>
                            </Grid>
                        </Grid>
                        {index < pricingSoft.consulting.array.length - 1 && (
                            <Divider
                                sx={{
                                    backgroundColor: "#031851",
                                }}
                            />
                        )}
                    </>
                ))}
        </StyledContainer>
    );
};
