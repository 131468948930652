/** React imports */
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

/** MUI imports */
import {
    Grid,
    Typography,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    TextField,
    DialogTitle,
    DialogContent,
    Dialog,
    DialogActions,
    Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const CustomDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(4),
    },
    "& .MuiDialogTitle-root": {
        backgroundColor: "#031851",
        fontSize: "18px",
        color: "#fff",
    },
    "& .MuiDialog-paper": {
        borderRadius: 8,
        boxShadow: "10px 10px 15px rgba(3,24,81,0.15)",
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(2),
    },
}));

const CustomTextArea = styled(TextField)(({ theme }) => ({
    backgroundColor: "#FFFFFF !important",
    borderRadius: 20,
    "&:focus-within": {
        backgroundColor: "#FFFFFF",
        borderRadius: 20,
    },
    "& .MuiOutlinedInput-notchedOutline": {
        border: "2px solid #031851",
        borderRadius: 20,
    },
}));

const VoidFormInv = ({
    title,
    open,
    maxWidth,
    onClose,
    setBackDrop,
    invoicedData,
    selectionInvoiced,
    documentType,
    setTexto,
    setAutoAlertType,
    setShow,
    setSelectionInvoiced,
    setReloadData,
    reloadData,
}) => {
    const [t] = useTranslation("global");
    const todos = useSelector((state) => state.todos);

    const [motiveDec, setMotiveDec] = useState("");
    const [motiveArea, setMotiveArea] = useState("");

    const handleChangeMotiveArea = (event) => setMotiveArea(event.target.value);
    const handleChangeMotive = (event) => setMotiveDec(event.target.value);
    const handleClose = () => {
        setMotiveArea("");
        setMotiveDec("");
        onClose();
    };

    const voidInvoices = async () => {
        setBackDrop(true);

        const params = {
            invoice_id: invoicedData[selectionInvoiced[0]].id,
            void_user: todos.userInfo.email,
            reason: motiveDec,
            reason_description: motiveArea
        };

        await fetch("/voidInvoice", {
            method: "POST",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
            body: JSON.stringify(params),
        })
            .then((response) => {
                if (!response.ok) {
                    // get error message from body or default to response status
                    const error = response.status;
                    throw new Error(error);
                }

                if (response.status === 200) {
                    documentType === 1
                        ? setTexto(t("invoicing.voidSuccessfully"))
                        : setTexto(t("invoicing.voidNcSuccessfully"));
                    setAutoAlertType("success");
                    setShow(true);
                } else if (response.status === 204) {
                    documentType === 1 ? setTexto(t("invoicing.voidInfo")) : setTexto(t("invoicing.voidNcInfo"));
                    setAutoAlertType("warning");
                    setShow(true);
                }
                handleClose();
                setSelectionInvoiced([]);
                setReloadData(!reloadData);
            })
            .catch((error) => {
                console.log(error);
            });

        setBackDrop(false);
    };

    return (
        <CustomDialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={maxWidth}
            fullWidth
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} sx={{ mt: 1 }}>
                    <Grid item xs={12}>
                        <Typography variant="h5">{t("invoicing.whyVoid")}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue="female"
                                name="radio-buttons-group"
                                value={motiveDec}
                                onChange={handleChangeMotive}
                            >
                                <FormControlLabel
                                    value={"Error en el cálculo"}
                                    control={<Radio />}
                                    label={t("invoicing.calcError")}
                                />
                                <FormControlLabel
                                    value={"Modificacion por solicitud del cliente"}
                                    control={<Radio />}
                                    label={t("invoicing.modRequest")}
                                />
                                <FormControlLabel
                                    value={"Proyecto erróneo"}
                                    control={<Radio />}
                                    label={t("invoicing.wrongPro")}
                                />
                                <FormControlLabel
                                    value={"Otro"}
                                    control={<Radio />}
                                    label={t("services.other")}
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <CustomTextArea
                            id="outlined-multiline-static"
                            value={motiveArea}
                            label={t("proposals.commentMore")}
                            onChange={handleChangeMotiveArea}
                            multiline
                            rows={2}
                            inputProps={{
                                maxlength: 100,
                            }}
                            fullWidth
                            disabled={!motiveDec}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                    <Grid item>
                        <Button
                            onClick={handleClose}
                            sx={{
                                pl: 3,
                                pr: 3,
                                border: "1px solid #ff5968",
                                borderRadius: 40,
                                "&:hover": {
                                    border: "1px solid #2f3190",
                                    color: "#2f3190",
                                },
                                "&.Mui-disabled": {
                                    color: "#ffcdd2",
                                    borderColor: "#ffcdd2",
                                    background: "transparent",
                                },
                            }}
                            color="secondary"
                            disableElevation
                            variant="outlined"
                        >
                            {t("miscellaneous.goBack")}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            onClick={voidInvoices}
                            sx={{
                                pl: 3,
                                pr: 3,
                                borderRadius: 40,
                                "&:hover": {
                                    backgroundColor: "#2f3190",
                                    color: "#fff",
                                },
                                "&.Mui-disabled": {
                                    color: "#fff",
                                    borderColor: "#ffcdd2",
                                    background: "#ffcdd2",
                                },
                            }}
                            color="secondary"
                            disableElevation
                            variant="contained"
                            disabled={!motiveDec || !motiveArea.trim()}
                        >
                            {t("miscellaneous.send")}
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </CustomDialog>
    );
};

export default VoidFormInv;
