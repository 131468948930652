/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
/** MUI icons */

/** MUI imports */
import { Grid, Table, TableHeaderRow, PagingPanel, Toolbar, SearchPanel } from "@devexpress/dx-react-grid-material-ui";
import { SearchState, IntegratedFiltering, PagingState, IntegratedPaging } from "@devexpress/dx-react-grid";
import { styled } from "@mui/material/styles";
import GridUI from "@mui/material/Grid";
import { Box, Skeleton, FormControl, Select, InputLabel, MenuItem, OutlinedInput, Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

/** Components imports */

const PREFIX = "Requirements";

const classes = {
    tableStriped: `${PREFIX}-tableStriped`,
    tableHeader: `${PREFIX}-tableHeader`,
    tableBody: `${PREFIX}-tableBody`,
    pager: `${PREFIX}-pager`,
    spacing: `${PREFIX}-spacing`,
    toolbarSearch: `${PREFIX}-toolbarSearch`,
    searchInput: `${PREFIX}-searchInput`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.spacing}`]: {
        padding: "10px 0 10px 0",
    },

    [`& .${classes.tableStriped}`]: {
        "& tbody tr:nth-of-type(odd)": {
            backgroundColor: "rgba(47,49,144,0.1)",
        },
    },
    [`& .${classes.tableHeader}`]: {
        border: "none",
        backgroundColor: "rgba(47,49,144,0.3)",
        fontSize: "15px",
        fontWeight: 600,
        color: "#031851",
        "&:first-of-type": {
            borderRadius: "20px 0 0 0",
        },
        "&:last-child": {
            borderRadius: "0 20px 0 0",
        },
    },

    [`& .${classes.tableBody}`]: {
        border: "none",
        fontSize: "14px",
        fontWeight: 400,
        color: "#031851",
    },

    [`& .${classes.pager}`]: {
        "& .Pagination-button": {
            paddingTop: 0,
            paddingBottom: 0,
            color: "#031851",
            fontWeight: 600,
        },
        "& .Pagination-activeButton": {
            backgroundColor: "rgba(47,49,144,0.3)",
        },
    },

    [`& .${classes.toolbarSearch}`]: {
        border: "none !important",
        padding: "0 0 5px !important",
    },

    [`& .${classes.searchInput}`]: {
        color: "#031851",
        "&:before": {
            borderBottomColor: "#031851",
        },
        "&:hover:before": {
            borderBottomColor: "#031851 !important",
        },
    },
}));

const tableHeaderCell = ({ ...props }) => <TableHeaderRow.Cell {...props} className={classes.tableHeader} />;

const TableComponent = ({ ...props }) => <Table.Table {...props} className={classes.tableStriped} />;

const TableCellComponent = ({ ...props }) => <Table.Cell {...props} className={classes.tableBody} />;

const tableContentComponent = (props) => <TableHeaderRow.Content {...props} />;

const PagingPanelContainer = ({ ...props }) => <PagingPanel.Container {...props} className={classes.pager} />;

const inputComponent = ({ ...props }) => <SearchPanel.Input {...props} className={classes.searchInput} />;

const toolRootComponent = ({ ...props }) => <Toolbar.Root {...props} className={classes.toolbarSearch} />;

const tableColumnExtensions = [
    //Franquicia	Grupo	SMB	Estatus SMB	Estatus Requerimientos	Cantidad requerimientos pendientes
    { columnName: "franchise", width: "15%" },
    { columnName: "practice", width: "15%" },
    { columnName: "company", width: "30%" },
    { columnName: "statusCompany", width: "10%", align: "center" },
    { columnName: "statusRequirement", width: "10%", align: "center" },
    { columnName: "contRequirement", width: "10%", align: "center" }, //, wordWrapEnabled: true
    { columnName: "reportable", width: "10%", align: "center" }, //, wordWrapEnabled: true
];

const rowsSkeleton = [];
const rowsSkeleton2 = [];
const rowsSkeleton3 = [];

for (let i = 0; i < 10; i++) {
    rowsSkeleton.push({
        id: i,
        franchise: <Skeleton variant="text" animation="wave" />,
        practice: <Skeleton variant="text" animation="wave" />,
        company: <Skeleton variant="text" animation="wave" />,
        statusCompany: <Skeleton variant="text" animation="wave" />,
        statusRequirement: <Skeleton variant="text" animation="wave" />,
        contRequirement: <Skeleton variant="text" animation="wave" />,
        reportable: <Skeleton variant="text" animation="wave" />,
    });

    rowsSkeleton2.push({
        id: i,
        franchise: <Skeleton variant="text" animation="wave" />,
        practice: <Skeleton variant="text" animation="wave" />,
        company: <Skeleton variant="text" animation="wave" />,
        statusCompany: <Skeleton variant="text" animation="wave" />,
        year: <Skeleton variant="text" animation="wave" />,
        month: <Skeleton variant="text" animation="wave" />,
        reqType: <Skeleton variant="text" animation="wave" />,
        reqName: <Skeleton variant="text" animation="wave" />,
    });

    rowsSkeleton3.push({
        id: i,
        franchise: <Skeleton variant="text" animation="wave" />,
        practice: <Skeleton variant="text" animation="wave" />,
        company: <Skeleton variant="text" animation="wave" />,
        statusSMB: <Skeleton variant="text" animation="wave" />,
        configType: <Skeleton variant="text" animation="wave" />,
        reqType: <Skeleton variant="text" animation="wave" />,
        reqName2: <Skeleton variant="text" animation="wave" />,
        reqStatus2: <Skeleton variant="text" animation="wave" />,
        bank: <Skeleton variant="text" animation="wave" />,
        mode: <Skeleton variant="text" animation="wave" />,
        frequencyMonitoring: <Skeleton variant="text" animation="wave" />,
        periodicity: <Skeleton variant="text" animation="wave" />,
        receptionDays: <Skeleton variant="text" animation="wave" />,
        sinceDate2: <Skeleton variant="text" animation="wave" />,
        toDate: <Skeleton variant="text" animation="wave" />,
        activationDate: <Skeleton variant="text" animation="wave" />,
        user: <Skeleton variant="text" animation="wave" />,
    });
}

const Requirements = (props) => {
    const { reportSelected, setReportSelected } = props;

    /** Internationalization i18n */
    const [t] = useTranslation("global");

    /** React redux */
    const todos = useSelector((state) => state.todos);

    const tableMessages = {
        noData: t("miscellaneous.noDataReports"),
    };
    const pagingMessages = {
        info: ({ from, to, count }) => `${from}${from < to ? `-${to}` : ""} ${t("miscellaneous.pagingOf")} ${count}`,
    };

    const searchMessages = {
        searchPlaceholder: t("miscellaneous.search"),
    };

    const meses = [
        t("monthNames.january"),
        t("monthNames.february"),
        t("monthNames.march"),
        t("monthNames.april"),
        t("monthNames.may"),
        t("monthNames.june"),
        t("monthNames.july"),
        t("monthNames.august"),
        t("monthNames.september"),
        t("monthNames.october"),
        t("monthNames.november"),
        t("monthNames.december"),
    ];

    /** Component states */
    const [clientData, setclientData] = useState([]);
    const [columnDefs, setColumnsDefs] = useState([]);
    const [searchValue, setSearchState] = useState("");
    const controllerRef = useRef(null);

    /** Component functions */
    const handleSearch = (value) => {
        let itemS = value.replace(/ +(?= )/g, "");
        setSearchState(itemS);
    };

    const reportTypeList = [
        { id: 0, data: t("invoicing.summarized") },
        { id: 1, data: t("invoicing.detailed") },
        { id: 2, data: t("principal.settings") },
    ];

    const handleChangeReportType = (event) => {
        event.preventDefault();
        setReportSelected(event.target.value);
    };

    const loadInitData = async () => {
        if (controllerRef.current) {
            controllerRef.current.abort();
        }
        const controller = new AbortController();
        controllerRef.current = controller;

        if (reportSelected === 0) {
            await fetch(`/getRequirementsResume?year=${moment().year()}`, {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
                signal: controllerRef.current.signal,
            })
                .then((res) => res.json())
                .then(async (data) => {
                    let resultTable = data.map((data, index) => {
                        return {
                            id: index,
                            franchise: data.franchise,
                            practice: data.practice,
                            company: data.company_name,
                            statusCompany:
                                data.status === "Activo" ? t("miscellaneous.active") : t("miscellaneous.inactive"),
                            statusRequirement:
                                data.total_notify === 0 ? t("miscellaneous.complete") : t("recurrent.pend"),
                            contRequirement: data.total_notify,
                            reportable: data.notify > 0 ? t("miscellaneous.no") : t("miscellaneous.yes"),
                        };
                    });
                    setclientData(resultTable);
                    controllerRef.current = null;
                });
        } else if (reportSelected === 1) {
            await fetch(`/getRequirementsDetailed?year=${moment().year()}`, {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
                signal: controllerRef.current.signal,
            })
                .then((res) => res.json())
                .then(async (data) => {
                    let resultTable = data.map((data, index) => {
                        return {
                            id: index,
                            franchise: data.franchise,
                            practice: data.practice,
                            company: data.company_name,
                            statusCompany:
                                data.status === "Activo" ? t("miscellaneous.active") : t("miscellaneous.inactive"),
                            year: data.year,
                            month: meses[data.month],
                            reqType:
                                data.reqType.trim() === "Estados de cuenta"
                                    ? t("miscellaneous.bankStatements")
                                    : data.reqType.trim() === "Cheques"
                                        ? t("services.checks")
                                        : data.reqType.trim() === "Reporte de cajas chicas"
                                            ? t("miscellaneous.pettyCash")
                                            : data.reqType.trim() === "Reporte de contratistas"
                                                ? t("miscellaneous.reportCont")
                                                : data.reqType.trim() === "Reporte de ventas"
                                                    ? t("miscellaneous.reportSells")
                                                    : data.reqType.trim() === "Reporte de nómina"
                                                        ? t("miscellaneous.reportNom")
                                                        : data.reqType.trim() === "Declaración de ISLR"
                                                            ? t("miscellaneous.reportISLR")
                                                            : data.reqType.trim() === "Compra de activos fijos"
                                                                ? t("miscellaneous.fixAct")
                                                                : data.reqType.trim() === "Préstamos"
                                                                    ? t("miscellaneous.loans")
                                                                    : data.reqType.trim() === "Por configurar"
                                                                        ? t("miscellaneous.toConfigure")
                                                                        : data.reqType,
                            reqName:
                                data.reqName.trim() === "Reporte de contratistas"
                                    ? t("miscellaneous.reportCont")
                                    : data.reqName.trim() === "Reporte de ventas"
                                        ? t("miscellaneous.reportSells")
                                        : data.reqName.trim() === "Reporte de nómina"
                                            ? t("miscellaneous.reportNom")
                                            : data.reqName.trim() === "Declaración de ISLR"
                                                ? t("miscellaneous.reportISLR")
                                                : data.reqName.trim() === "Compra de activos fijos"
                                                    ? t("miscellaneous.fixAct")
                                                    : data.reqName.trim() === "Préstamos"
                                                        ? t("miscellaneous.loans")
                                                        : data.reqName,
                        };
                    });
                    setclientData(resultTable);
                    controllerRef.current = null;
                });
        } else {
            await fetch(`/getRequirementConfigInfo`, {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
                signal: controllerRef.current.signal,
            })
                .then((res) => res.json())
                .then(async (data) => {
                    let resultTable = data.map((data, index) => {
                        console.log(data)
                        return {
                            id: index,
                            franchise: data.franchise_name === undefined ||
                                data.franchise_name === null ||
                                data.franchise_name === "" ? (
                                <Typography>N/A</Typography>
                            ) : (
                                data.franchise_name
                            ),
                            practice: data.practice_name === undefined ||
                                data.practice_name === null ||
                                data.practice_name === "" ? (
                                <Typography>N/A</Typography>
                            ) : (
                                data.practice_name
                            ),
                            company: data.company_name === undefined ||
                                data.company_name === null ||
                                data.company_name === "" ? (
                                <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                            ) : (
                                data.company_name
                            ),
                            statusSMB: data.statusSMB === undefined || data.statusSMB === null || data.statusSMB === "" ? (
                                <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                            ) : (
                                t(`dynamicLabels.${data.statusSMB.toLowerCase()}`)
                            ),
                            configType: data.req_config === undefined ||
                                data.req_config === null ||
                                data.req_config === "" ? (
                                <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                            ) : (
                                data.req_config
                            ),
                            reqType: data.req_type === undefined ||
                                data.req_type === null ||
                                data.req_type === "" ? (
                                <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                            ) : (
                                data.req_type
                            ),
                            reqName2: data.req_name === undefined ||
                                data.req_name === null ||
                                data.req_name === "" ? (
                                <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                            ) : (
                                data.req_name
                            ),
                            reqStatus2: data.req_status === undefined ||
                                data.req_status === null ||
                                data.req_status === "" ? (
                                <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                            ) : (
                                data.req_status
                            ),
                            bank: data.bank_name === undefined ||
                                data.bank_name === null ||
                                data.bank_name === "" ? (
                                <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                            ) : (
                                data.bank_name
                            ),
                            mode: data.import_mode === undefined ||
                                data.import_mode === null ||
                                data.import_mode === "" ? (
                                <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                            ) : (
                                data.import_mode
                            ),
                            frequencyMonitoring: data.frequency === undefined ||
                                data.frequency === null ||
                                data.frequency === "" ? (
                                <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                            ) : (
                                data.frequency
                            ),
                            periodicity: data.periodicity === undefined ||
                                data.periodicity === null ||
                                data.periodicity === "" ? (
                                <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                            ) : (
                                data.periodicity
                            ),
                            receptionDays: data.reception_days === undefined ||
                                data.reception_days === null ||
                                data.reception_days === "" ? (
                                <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                            ) : (
                                data.reception_days
                            ),
                            sinceDate2: data.since_date === "" || data.since_date === null || data.since_date === undefined ? (
                                <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                            ) : (
                                moment(data.since_date, "DD/MM/YYYY").format(todos.dateFormat)
                            ),
                            toDate: data.end_date === "" || data.end_date === null || data.end_date === undefined ? (
                                <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                            ) : (
                                moment(data.end_date, "DD/MM/YYYY").format(todos.dateFormat)
                            ),
                            activationDate: data.activation_date === "" || data.activation_date === null || data.activation_date === undefined ? (
                                <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                            ) : (
                                moment(data.activation_date, "DD/MM/YYYY").format(todos.dateFormat)
                            ),
                            user: data.user === undefined ||
                                data.user === null ||
                                data.user === "" ? (
                                <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                            ) : (
                                data.user
                            ),
                        };
                    });
                    setclientData(resultTable);
                    controllerRef.current = null;
                });
        };
    };

    /* Component events */
    useEffect(() => {
        (async () => {
            const columnsTemplate = [
                { name: "franchise", title: t("miscellaneous.franchise") },
                { name: "practice", title: t("miscellaneous.group") },
                { name: "company", title: t("miscellaneous.smb") },
                { name: "statusCompany", title: t("miscellaneous.smbStatus") },
                { name: "statusRequirement", title: t("miscellaneous.reqStatus") },
                { name: "contRequirement", title: t("miscellaneous.countReqPend") },
                { name: "reportable", title: t("miscellaneous.reportable") },
            ];

            //Año	Mes	Tipo requerimiento	Nombre requerimiento
            const columnsTemplate2 = [
                { name: "franchise", title: t("miscellaneous.franchise") },
                { name: "practice", title: t("miscellaneous.group") },
                { name: "company", title: t("miscellaneous.smb") },
                { name: "statusCompany", title: t("miscellaneous.smbStatus") },
                { name: "year", title: t("miscellaneous.year") },
                { name: "month", title: t("bankingDashboard.month") },
                { name: "reqType", title: t("miscellaneous.requirementType") },
                { name: "reqName", title: t("miscellaneous.reqName") },
            ];

            // Template para Configuración.
            const columnsTemplateConfig = [
                { name: "franchise", title: t("miscellaneous.franchise") },
                { name: "practice", title: t("miscellaneous.group") },
                { name: "company", title: t("miscellaneous.smb") },
                { name: "statusSMB", title: t("miscellaneous.statusSMB") },
                { name: "configType", title: t("miscellaneous.configType") },
                { name: "reqType", title: t("miscellaneous.reqType") },
                { name: "reqName2", title: t("miscellaneous.reqName2") },
                { name: "reqStatus2", title: t("miscellaneous.reqStatus2") },
                { name: "bank", title: t("miscellaneous.bank") },
                { name: "mode", title: t("miscellaneous.mode") },
                { name: "frequencyMonitoring", title: t("miscellaneous.frequencyMonitoring") },
                { name: "periodicity", title: t("miscellaneous.periodicity") },
                { name: "receptionDays", title: t("miscellaneous.receptionDays") },
                { name: "sinceDate2", title: t("miscellaneous.sinceDate2") },
                { name: "toDate", title: t("miscellaneous.toDate") },
                { name: "activationDate", title: t("miscellaneous.activationDate") },
                { name: "user", title: t("miscellaneous.user") },
            ];

            if (reportSelected === 0) {
                setColumnsDefs(columnsTemplate);
                setclientData(rowsSkeleton);
            } else if (reportSelected === 1) {
                setColumnsDefs(columnsTemplate2);
                setclientData(rowsSkeleton2);
            } else {
                setColumnsDefs(columnsTemplateConfig);
                setclientData(rowsSkeleton3);
            };

            await loadInitData();
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t, reportSelected]);

    return (
        <StyledContainer component="main" sx={{ flexGrow: 1, width: "100%" }}>
            <GridUI container justifyContent="left" alignItems="center">
                <GridUI item xs={12} sm={6} md={2} sx={{ mb: { sm: -5 }, zIndex: 1 }}>
                    <FormControl fullWidth>
                        <InputLabel shrink id="type-simple-select-label">
                            {t("invoicing.reportType")}
                        </InputLabel>
                        <Select
                            size="small"
                            IconComponent={KeyboardArrowDownIcon}
                            value={reportSelected}
                            onChange={handleChangeReportType}
                            input={<OutlinedInput notched label={t("invoicing.reportType")} />}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        mt: 0.7,
                                        borderRadius: 2,
                                        boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                    },
                                },
                            }}
                        >
                            {reportTypeList.map((report) => (
                                <MenuItem value={report.id}>{report.data}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </GridUI>
                <GridUI item xs={12}>
                    <Grid rows={clientData} columns={columnDefs}>
                        <SearchState value={searchValue} onValueChange={handleSearch} />
                        <IntegratedFiltering />
                        <PagingState defaultCurrentPage={0} pageSize={10} />
                        <IntegratedPaging />
                        <Table
                            tableComponent={TableComponent}
                            columnExtensions={tableColumnExtensions}
                            cellComponent={TableCellComponent}
                            messages={tableMessages}
                        />
                        <TableHeaderRow cellComponent={tableHeaderCell} contentComponent={tableContentComponent} />
                        <Toolbar rootComponent={toolRootComponent} />
                        <SearchPanel messages={searchMessages} inputComponent={inputComponent} />
                        <PagingPanel containerComponent={PagingPanelContainer} messages={pagingMessages} />
                    </Grid>
                </GridUI>
            </GridUI>
        </StyledContainer>
    );
};

export default Requirements;
