/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import axios from "axios";

/** MUI icons */
import CommentIcon from "@mui/icons-material/Comment";
import CheckIcon from "@mui/icons-material/Check";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockIcon from "@mui/icons-material/Lock";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import WarningAmberIcon from "@mui/icons-material/Warning";

/** MUI imports */
import { Grid, Table, TableHeaderRow, TableTreeColumn } from "@devexpress/dx-react-grid-material-ui";
import { TreeDataState, CustomTreeData } from "@devexpress/dx-react-grid";
import { styled } from "@mui/material/styles";
import GridUI from "@mui/material/Grid";
import {
    Badge,
    Box,
    Breadcrumbs,
    Button,
    Card,
    CardContent,
    Chip,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Skeleton,
    Stack,
    Typography,
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

/** Components imports */
import CommentDialog from "../CommentDialog";
import DialogAlerts from "../DialogAlerts";
import Backdrop from "../Backdrop";
import SnackBar from "../SnackBar";
import UploadAsync from "./UploadAsync";
import UploadAsyncCustom from "./UploadAsyncCustom";
import moment from "moment";

const PREFIX = "ClientRequirements";

const classes = {
    paper: `${PREFIX}-paper`,
    navbarLogo: `${PREFIX}-navbarLogo`,
    toolbar: `${PREFIX}-toolbar`,
    commonWhite: `${PREFIX}-commonWhite`,
    tableHeader: `${PREFIX}-tableHeader`,
    tableBody: `${PREFIX}-tableBody`,
    tableCellTree: `${PREFIX}-tableCellTree`,
    pager: `${PREFIX}-pager`,
    formRounded: `${PREFIX}-formRounded`,
    cardContentRoot: `${PREFIX}-cardContentRoot`,
    cardHeaderRoot: `${PREFIX}-cardHeaderRoot`,
    spacing: `${PREFIX}-spacing`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.paper}`]: {
        border: "2px solid #ff5968",
        borderRadius: 40,
        boxShadow: "none",
    },

    [`& .${classes.navbarLogo}`]: {
        display: "block",
        height: 50,
        [theme.breakpoints.down("sm")]: {
            width: "165px",
        },
    },

    [`& .${classes.toolbar}`]: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "#031851",
    },

    [`& .${classes.commonWhite}`]: {
        color: "#fff",
    },

    [`& .${classes.cardHeaderRoot}`]: {
        padding: 30,
    },

    [`& .${classes.cardContentRoot}`]: {
        padding: 30,
    },

    [`& .${classes.spacing}`]: {
        padding: "20px 0 25px 0",
    },

    [`& .${classes.tableHeader}`]: {
        border: "none",
        backgroundColor: "rgba(47,49,144,0.3)",
        fontSize: "15px",
        fontWeight: 600,
        color: "#031851",
        "&:first-of-type": {
            borderRadius: "20px 0 0 0",
        },
        "&:last-child": {
            borderRadius: "0 20px 0 0",
        },
    },

    [`& .${classes.tableBody}`]: {
        borderBottom: "2px solid #031851",
        fontSize: "14px",
        fontWeight: 400,
        color: "#031851",
    },

    [`& .${classes.tableCellTree}`]: {
        borderBottom: "2px solid #031851",
        fontSize: "14px",
        fontWeight: 400,
        color: "#031851",
        cursor: "pointer",
    },

    [`& .${classes.pager}`]: {
        "& .Pagination-button": {
            paddingTop: 0,
            paddingBottom: 0,
            color: "#031851",
            fontWeight: 600,
        },
        "& .Pagination-activeButton": {
            backgroundColor: "rgba(47,49,144,0.3)",
        },
    },

    [`& .${classes.formRounded}`]: {
        backgroundColor: "#FFFFFF !important",
        borderRadius: 40,
        verticalAlign: "middle !important",
        "&:focus-within": {
            backgroundColor: "#FFFFFF",
            borderRadius: 40,
        },
        "& .MuiOutlinedInput-notchedOutline": {
            border: "2px solid #031851",
            borderRadius: 40,
        },
    },
}));

const TableRow = ({ row, ...restProps }) => (
    <Table.Row
        {...restProps}
        style={{
            backgroundColor: row.parentId === null ? "rgba(47,49,144,0.1)" : "none",
        }}
    />
);

const tableContentComponent = (props) => <TableHeaderRow.Content {...props} />;

const tableHeaderCell = ({ ...props }) => <TableHeaderRow.Cell {...props} className={classes.tableHeader} />;

const TableCellComponent = ({ ...props }) => <Table.Cell {...props} className={classes.tableBody} />;

const getChildRows = (row, rootRows) => {
    const childRows = rootRows.filter((r) => r.parentId === (row ? row.id : null));
    return childRows.length ? childRows : null;
};

const ColorButton = styled(Button)(({ theme }) => ({
    border: "1px solid #ff5968",
    borderRadius: 40,
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
        backgroundColor: "#2f3190",
        color: "white",
    },
}));

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "rgba(19, 31, 62, 0.80)",
        fontSize: 12,
        maxWidth: 320,
        borderRadius: 6,
        fontWeight: "normal",
    },
});

const tableColumnExtensions = [
    { columnName: "comments", align: "center", width: "5%" },
    { columnName: "status", align: "left", width: "10%" },
    { columnName: "jan", align: "center", width: "5%" },
    { columnName: "feb", align: "center", width: "5%" },
    { columnName: "mar", align: "center", width: "5%" },
    { columnName: "apr", align: "center", width: "5%" },
    { columnName: "may", align: "center", width: "5%" },
    { columnName: "jun", align: "center", width: "5%" },
    { columnName: "jul", align: "center", width: "5%" },
    { columnName: "aug", align: "center", width: "5%" },
    { columnName: "sep", align: "center", width: "5%" },
    { columnName: "oct", align: "center", width: "5%" },
    { columnName: "nov", align: "center", width: "5%" },
    { columnName: "dec", align: "center", width: "5%" },
];

const rowsSkeleton = [];

const allMonths = [
    { num: "01", key: "jan" },
    { num: "02", key: "feb" },
    { num: "03", key: "mar" },
    { num: "04", key: "apr" },
    { num: "05", key: "may" },
    { num: "06", key: "jun" },
    { num: "07", key: "jul" },
    { num: "08", key: "aug" },
    { num: "09", key: "sep" },
    { num: "10", key: "oct" },
    { num: "11", key: "nov" },
    { num: "12", key: "dec" },
];

for (let i = 0; i < 15; i++) {
    rowsSkeleton.push({
        id: i,
        parentId: null,
        client: <Skeleton variant="text" animation="wave" />,
        comments: (
            <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />
        ),
        jan: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        feb: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        mar: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        apr: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        may: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        jun: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        jul: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        aug: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        sep: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        oct: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        nov: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        dec: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
    });
}

const StyledBadgeChecks = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
        transform: "translate(0px, -2px)",
        width: "10px",
        height: "10px",
    },
}));

const ClientRequirements = () => {
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    const tableMessages = {
        noData: t("miscellaneous.noData"),
    };

    /* React redux */
    const todos = useSelector((state) => state.todos);

    /** Component states */
    const [open, setOpen] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [dialogText, setDialogText] = useState("");
    const [dialogAction, setDialogAction] = useState("");
    const [show, setShow] = useState(false);
    const [texto, setTexto] = useState("");
    const [alertType, setAlertType] = useState("");
    const [showBackdrop, setBackDrop] = useState(false);
    const [clientsData, setClients] = useState([]);
    const [columnDefs, setColumnsDefs] = useState([]);
    const [expandedRowIds, setExpandenRowIds] = useState([]);
    const [yearsToNoti, setyearsToNoti] = useState([]);
    const [selectedYear, setselectedYear] = useState(new Date().getFullYear());
    const [filesData, setFilesData] = useState([]);
    const [filesRejected, setFilesRejected] = useState([]);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [fileFormat, setFileFormat] = useState([]);
    const [fileExtensions, setFileExtensions] = useState([]);
    const [dialogComment, setDialogComment] = useState(false);
    const [clientID, setclientID] = useState("");
    const [comments, setComments] = useState([]);
    const [commentsP, setCommentsPend] = useState([]);
    const [selectedBank, setSelectedBank] = useState({
        idOrganisation: "",
        idXero: "",
        idAccount: "",
        mode: "",
        month: "",
        showCheck: 0,
        company: "",
        account: "",
        NotApply: false,
        NotApplyChecks: false,
        NotApplyPetty: false,
        FullLoadChecks: false,
    });
    const [showTabs, setShowTabs] = useState({
        requirement_bank_statements: false,
        requirement_checks: false,
        requirement_petty_cash: false,
        initial_date: "",
        included_checks: false,
        current_date: "",
    });
    const [checkStatus, setCheckStatus] = useState(false);
    const [openCustom, setOpenCustom] = useState(false);
    const [filesDataCustom, setFilesDataCustom] = useState([]);
    const [uploadedFilesCustom, setUploadedFilesCustom] = useState([]);
    const [uploadedFilesCustomSizes, setUploadedFilesCustomSizes] = useState([]);
    const [filesRejectedCustom, setFilesRejectedCustom] = useState([]);
    const [customRequirementInfo, setCustomRequirementInfo] = useState({
        idOrganisation: "",
        month: "",
        company: "",
        current_date: "",
        req_type: "",
        req_name: "",
        custom_requirement_id: "",
    });
    const [buttonTabs, setButtomTabs] = useState("");
    const [checkStatusChecks, setCheckStatusChecks] = useState(false);
    const [prevCheckStatus, setPrevCheckStatus] = useState(false);
    const [prevCheckStatusChecks, setPrevCheckStatusChecks] = useState(false);
    const [customStatus, setCustomStatus] = useState(false);
    const [checkStatusPetty, setCheckStatusPetty] = useState(false);
    const [page, setPage] = useState(0);
    const [filesRejectedChecks, setFilesRejectedChecks] = useState([]);
    const [filesRejectedPetty, setFilesRejectedPetty] = useState([]);
    const [filesDataChecks, setFilesDataChecks] = useState([]);
    const [filesDataPetty, setFilesDataPetty] = useState([]);
    const [fullLoadChecks, setFullLoadChecks] = useState(false);
    const [uploadedFilesCustomUploadDate, setUploadedFilesCustomUploadDate] = useState([]);

    /** Global variables */
    const breadcrumbs = [
        <Typography
            key="1"
            variant="h1"
            color="primary"
            sx={{
                fontSize: 24,
                fontWeight: 700,
            }}
        >
            {t("principal.requirements")}
        </Typography>,
    ];

    /** Component functions */
    const TableCellTreeComponent = ({ ...props }) => (
        <TableTreeColumn.Cell
            {...props}
            className={props.children[1].props.visible === true ? classes.tableCellTree : classes.tableBody}
            onClick={() => {
                if (props.children[1].props.visible === true) {
                    if (!expandedRowIds.includes(props.tableRow.rowId)) {
                        setExpandenRowIds((prevArray) => [...prevArray, props.tableRow.rowId]);
                    } else {
                        const newExpandedRows = [...expandedRowIds];
                        newExpandedRows.splice(newExpandedRows.indexOf(props.tableRow.rowId), 1);
                        setExpandenRowIds(newExpandedRows);
                    }
                } else {
                    return;
                }
            }}
        />
    );

    const handleChangeTabs = (event, newAlignment) => {
        if (newAlignment !== null) {
            setPage(0);
            if (newAlignment.toString() === "1") {
                if (checkStatus) {
                    setDialogAction("Guardar");
                    setDialogText(t("bank.saveWithNoBankStatement"));
                } else if (selectedBank.mode !== undefined && selectedBank.mode !== 0) {
                    setDialogAction("Convertir");
                    setDialogText(t("bank.convertBankStatement"));
                } else {
                    setDialogAction("Guardar");
                    setDialogText(t("bank.saveBankStatement"));
                }
            } else {
                setDialogAction("Guardar");
                setDialogText(t("miscellaneous.saveSure"));
            }
            setButtomTabs(newAlignment);
        }
    };

    const handleChangeStatus = (event, tab) => {
        if (tab.toString() === "1") {
            setCheckStatus(event.target.checked);
        } else if (tab.toString() === "2") {
            setCheckStatusChecks(event.target.checked);
        } else {
            setCheckStatusPetty(event.target.checked);
        }

        if (tab.toString() === "1") {
            if (event.target.checked) {
                setDialogAction("Guardar");
                setDialogText(t("bank.saveWithNoBankStatement"));
            } else if (selectedBank.mode !== undefined && selectedBank.mode !== 0) {
                setDialogAction("Convertir");
                setDialogText(t("bank.convertBankStatement"));
            } else {
                setDialogAction("Guardar");
                setDialogText(t("bank.saveBankStatement"));
            }
        } else {
            setDialogAction("Guardar");
            setDialogText(t("miscellaneous.saveSure"));
        }
    };

    const handleOpenDialog = async (bnkDatta, accDatta, month, showCheck) => {
        setBackDrop(true);

        await fetch(
            `/getUploadedFile?organisationId=${bnkDatta.id_organisation}&account_id=${accDatta._id}&date=${moment()
                .set({ year: selectedYear, month: parseInt(month) - 1 })
                .startOf("month")
                .format("YYYY-MM-DD")}`,
            {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            }
        )
            .then((res) => res.json())
            .then((data) => {
                setShowTabs({
                    requirement_bank_statements: data.tabs.requirement_bank_statements,
                    requirement_checks: data.tabs.requirement_checks,
                    requirement_petty_cash: data.tabs.requirement_petty_cash,
                    initial_date: data.initial_date,
                    included_checks: data.included_checks,
                    current_date: moment()
                        .set({ year: selectedYear, month: parseInt(month) - 1 })
                        .startOf("month")
                        .format("YYYY-MM-DD"),
                });
                setButtomTabs(data.tabs.requirement_bank_statements ? "1" : data.tabs.requirement_checks ? "2" : "3");

                let finalFiles = [];
                data.requirements.forEach((item) => {
                    finalFiles.push({
                        req_type: item.requirement_type,
                        files: item.files,
                        sizes: item.sizes,
                    });
                });
                if (
                    data.tabs.requirement_bank_statements &&
                    finalFiles.filter((item) => item.req_type.toString() === "1").length === 0
                ) {
                    finalFiles.push({
                        req_type: 1,
                        files: [],
                        sizes: [],
                    });
                }
                if (
                    data.tabs.requirement_checks &&
                    finalFiles.filter((item) => item.req_type.toString() === "2").length === 0
                ) {
                    finalFiles.push({
                        req_type: 2,
                        files: [],
                        sizes: [],
                    });
                }
                if (
                    data.tabs.requirement_petty_cash &&
                    finalFiles.filter((item) => item.req_type.toString() === "3").length === 0
                ) {
                    finalFiles.push({
                        req_type: 3,
                        files: [],
                        sizes: [],
                    });
                }
                setUploadedFiles(finalFiles);
                let finalCheck1 = false;
                let finalCheck2 = false;
                let finalCheck3 = false;
                let finalCheck4 = false;

                if (
                    data.requirements.length > 0 &&
                    data.requirements.filter((item) => item.requirement_type === 1).length > 0 &&
                    data.requirements.filter((item) => item.requirement_type === 1)[0].not_applicable
                ) {
                    finalCheck1 = true;
                    setCheckStatus(true);
                    setPrevCheckStatus(true);
                } else {
                    setCheckStatus(false);
                    setPrevCheckStatus(false);
                }
                if (
                    data.requirements.length > 0 &&
                    data.requirements.filter((item) => item.requirement_type === 2).length > 0 &&
                    data.requirements.filter((item) => item.requirement_type === 2)[0].not_applicable
                ) {
                    finalCheck2 = true;
                    setCheckStatusChecks(true);
                    setPrevCheckStatusChecks(true);
                } else {
                    setCheckStatusChecks(false);
                    setPrevCheckStatusChecks(false);
                }
                if (
                    data.requirements.length > 0 &&
                    data.requirements.filter((item) => item.requirement_type === 3).length > 0 &&
                    data.requirements.filter((item) => item.requirement_type === 3)[0].not_applicable
                ) {
                    finalCheck3 = true;
                    setCheckStatusPetty(true);
                } else {
                    setCheckStatusPetty(false);
                }

                if (
                    data.requirements.length > 0 &&
                    data.requirements.filter((item) => item.requirement_type === 2).length > 0 &&
                    data.requirements.filter((item) => item.requirement_type === 2)[0].full_load
                ) {
                    setFullLoadChecks(true);
                    finalCheck4 = true;
                } else {
                    setFullLoadChecks(false);
                }

                setSelectedBank({
                    idOrganisation: bnkDatta.id_organisation,
                    idXero: accDatta.account_id_xero,
                    idAccount: accDatta._id,
                    mode: accDatta.mode,
                    month: month,
                    showCheck: showCheck,
                    company: bnkDatta.company_name,
                    account: accDatta.account_name,
                    NotApply: finalCheck1,
                    NotApplyChecks: finalCheck2,
                    NotApplyPetty: finalCheck3,
                    FullLoadChecks: finalCheck4,
                });
                if (data.tabs.requirement_bank_statements) {
                    if (finalCheck1) {
                        setDialogAction("Guardar");
                        setDialogText(t("bank.saveWithNoBankStatement"));
                    } else if (accDatta.mode !== undefined && accDatta.mode !== 0) {
                        setDialogAction("Convertir");
                        setDialogText(t("bank.convertBankStatement"));
                    } else {
                        setDialogAction("Guardar");
                        setDialogText(t("bank.saveBankStatement"));
                    }
                } else {
                    setDialogAction("Guardar");
                    setDialogText(t("miscellaneous.saveSure"));
                }
                setFileFormat(accDatta.file_format);
                setFileExtensions(accDatta.file_format.map((file) => `.${file}`).join("|"));
            });

        setOpen(true);

        setBackDrop(false);
    };

    const handleCloseDialog = () => {
        setSelectedBank({
            idOrganisation: "",
            idXero: "",
            idAccount: "",
            mode: "",
            month: "",
            showCheck: 0,
            company: "",
            account: "",
            NotApply: false,
            NotApplyChecks: false,
            NotApplyPetty: false,
            FullLoadChecks: false,
        });
        setCheckStatus(false);
        setFilesData([]);
        setFilesRejected([]);
        setUploadedFiles([]);
        setFileFormat([]);
        setFileExtensions([]);
        setDialogAction("");
        setDialogText("");
        setFilesRejectedChecks([]);
        setFilesRejectedPetty([]);
        setFilesDataChecks([]);
        setFilesDataPetty([]);
        setOpen(false);
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setShow(false);
    };

    const openComments = async (clientId) => {
        setBackDrop(true);

        setclientID(clientId);

        await getComments(clientId);

        setDialogComment(true);

        setBackDrop(false);
    };

    const closeDialog = () => {
        setDialogComment(false);
    };

    const updateCustomRequirement = async () => {
        setBackDrop(true);
        let params = {
            id_organisation: customRequirementInfo.idOrganisation,
            month: customRequirementInfo.month,
            year: selectedYear,
            CustomRequirement: true,
            custom_req_type: customRequirementInfo.req_type,
            custom_requirement_id: customRequirementInfo.custom_requirement_id,
            not_applicable: customStatus,
        };

        await fetch(`/updateCustomRequirement`, {
            method: "POST",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
            body: JSON.stringify(params),
        })
            .then((response) => {
                if (response.ok) {
                    setOpenCustom(false);
                    setFilesDataCustom([]);
                    setUploadedFilesCustom([]);
                    setUploadedFilesCustomSizes([]);
                    setUploadedFilesCustomUploadDate([]);
                    setFilesRejectedCustom([]);
                    setCustomRequirementInfo({
                        idOrganisation: "",
                        month: "",
                        company: "",
                        current_date: "",
                        req_type: "",
                        req_name: "",
                        custom_requirement_id: "",
                    });

                    loadInitData();
                    setTexto(t("miscellaneous.successfulUpdate"));
                    setAlertType("success");
                    setShow(true);
                    setOpenAlert(false);
                } else {
                    setTexto(t("miscellaneous.updatingError"));
                    setAlertType("error");
                    setShow(true);
                    setOpenAlert(false);
                }
            })
            .catch((error) => {
                console.log(error);
            });
        setBackDrop(false);
    };

    const handleCloseDialogCustomDiscard = async () => {
        setBackDrop(true);
        let discard = {};
        if (filesDataCustom.length > 0) {
            let folder_name =
                customRequirementInfo.req_type.toString() === "1"
                    ? "Reporte de nómina"
                    : customRequirementInfo.req_type.toString() === "2"
                    ? "Reporte de contratistas"
                    : customRequirementInfo.req_type.toString() === "3"
                    ? "Reporte de ventas"
                    : customRequirementInfo.req_type.toString() === "4"
                    ? "Declaración de ISLR"
                    : customRequirementInfo.req_name;

            discard[folder_name] = filesDataCustom.map((item) => item.name);
        }

        if (Object.keys(discard).length > 0) {
            let params = {
                id_organisation: customRequirementInfo.idOrganisation,
                month: customRequirementInfo.month,
                year: selectedYear,
                CustomRequirement: true,
                custom_req_type: customRequirementInfo.req_type,
                custom_requirement_id: customRequirementInfo.custom_requirement_id,
                discard: discard,
            };
            await axios.post("/deleteFileS3multiple", params).catch((err) => {
                console.log(err);
            });
        }
        setOpenCustom(false);
        setFilesDataCustom([]);
        setUploadedFilesCustom([]);
        setUploadedFilesCustomSizes([]);
        setUploadedFilesCustomUploadDate([]);
        setFilesRejectedCustom([]);
        setCustomRequirementInfo({
            idOrganisation: "",
            month: "",
            company: "",
            current_date: "",
            req_type: "",
            req_name: "",
            custom_requirement_id: "",
        });
        setBackDrop(false);
    };

    const handleChangeFullLoadChecks = () => {
        setFullLoadChecks((checked) => !checked);
    };

    const handleChangeCustomStatus = (event) => {
        setCustomStatus(event.target.checked);
    };

    const handleClick = (event) => {
        setselectedYear(event.target.value);
    };

    const convertBankStatement = async () => {
        setBackDrop(true);
        let changeFullLoad = false;

        const params = {
            conversion: dialogAction === "Convertir" ? true : false,
            account_id: selectedBank.idAccount,
            organisationId: selectedBank.idOrganisation,
            date: moment()
                .set({ year: selectedYear, month: parseInt(selectedBank.month) - 1 })
                .startOf("month")
                .format("YYYY-MM-DD"),
            not_applicable: buttonTabs === "1" ? checkStatus : checkStatusChecks,
            requirement_type: buttonTabs === "1" ? 1 : 2,
        };

        let allowedExtensions = new RegExp(`(${fileExtensions})`, "i");
        let valid = false;

        if (checkStatus || checkStatusChecks || prevCheckStatus || prevCheckStatusChecks) {
            valid = true;
            if (prevCheckStatus || prevCheckStatusChecks) {
                params.prev_status = buttonTabs === "1" ? prevCheckStatus : prevCheckStatusChecks;
            }
        } else if (showTabs.requirement_checks && fullLoadChecks !== selectedBank.FullLoadChecks) {
            changeFullLoad = true;
            valid = false;
        } else {
            let fileName = filesData[0]?.name.toLowerCase();

            if (allowedExtensions.exec(fileName)) {
                valid = true;
            } else {
                setOpenAlert(false);
                setTexto(`${t("manageAccount.invalidFormat")} ${fileFormat.map((file) => `.${file}`).join(" ")}`);
                setAlertType("error");
                setShow(true);
            }
        }

        let finalFolder = [];
        let finalChecked = [];

        if (
            showTabs.requirement_checks &&
            !showTabs.included_checks &&
            selectedBank.NotApplyChecks !== checkStatusChecks
        ) {
            finalFolder.push("Cheques");
            finalChecked.push(checkStatusChecks);
        }
        if (showTabs.requirement_petty_cash && selectedBank.NotApplyPetty !== checkStatusPetty) {
            finalFolder.push("CajasChicas");
            finalChecked.push(checkStatusPetty);
        }

        if (changeFullLoad) {
            let params = {
                id_organisation: selectedBank.idOrganisation,
                bank_id: selectedBank.idAccount,
                month: selectedBank.month,
                year: selectedYear,
                full_load: fullLoadChecks,
            };

            await fetch(`/changeFullLoadChecks`, {
                method: "POST",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
                body: JSON.stringify(params),
            })
                .then((response) => {
                    if (response.ok) {
                        setOpenAlert(false);
                        handleCloseDialog();
                        loadInitData();
                        setTexto(t("miscellaneous.successfulUpdate"));
                        setAlertType("success");
                        setShow(true);
                    } else {
                        setTexto(t("miscellaneous.updatingError"));
                        setAlertType("error");
                        setShow(true);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
        if (finalFolder.length > 0) {
            let params = {
                id_organisation: selectedBank.idOrganisation,
                bank_id: selectedBank.idAccount,
                month: selectedBank.month,
                year: selectedYear,
                folder: finalFolder,
                checked: finalChecked,
            };

            await fetch(`/changeNotAppRequirement`, {
                method: "POST",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
                body: JSON.stringify(params),
            }).catch((error) => {
                console.log(error);
            });
        }
        if (valid && !changeFullLoad) {
            await fetch(`/convertBankStatement`, {
                method: "POST",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
                body: JSON.stringify(params),
            })
                .then((res) => res.json())
                .then((response) => {
                    setOpenAlert(false);
                    loadInitData();
                    handleCloseDialog();
                    setTexto(t("dialogConversions.statementSaved"));
                    setAlertType("success");
                    setShow(true);
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            setOpenAlert(false);
            handleCloseDialog();
            setTexto(t("miscellaneous.successfulUpdate"));
            setAlertType("success");
            setShow(true);
        }

        setBackDrop(false);
    };

    const getComments = async (clientId) => {
        await fetch(`/getComments?company_id=${clientId}&period=${selectedYear}&comment_type=1`, {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then((res) => res.json())
            .then((data) => {
                setComments(data.history);
                setCommentsPend(data.pendings);
            });
    };

    const countNoti = (accDatta, notifyNum, type, checks = false) => {
        let name = type === "bank" ? "notify" : "requirement";
        allMonths.forEach((item) => {
            if (accDatta[name + "_" + item.key] === 2) {
                let variable = "contNoti" + item.key.charAt(0).toUpperCase() + item.key.slice(1);
                notifyNum[variable]++;
            }
        });
        if (checks) {
            let initial = moment.utc(accDatta["initial_date"]).format("YYYY-MM-DD");
            allMonths.forEach((item) => {
                if (
                    accDatta["checks_" + item.key] !== undefined &&
                    accDatta["checks_" + item.key] !== null &&
                    (accDatta["checks_" + item.key] === 0 || !accDatta["checks_full_load_" + item.key]) &&
                    accDatta[name + "_" + item.key] !== 3 &&
                    moment(selectedYear + "-" + item.num + "-01").format("YYYY-MM-DD") >= initial &&
                    moment().add(-1, "months").isAfter(moment(item.num, "MM").set("year", selectedYear))
                ) {
                    let variable = "contNoti" + item.key.charAt(0).toUpperCase() + item.key.slice(1);
                    notifyNum[variable]++;
                }
            });
        }
        return notifyNum;
    };

    const boolCheck = (accDatta, checks, value, type) => {
        let name = type === "bank" ? "notify" : "requirement";

        allMonths.forEach((item) => {
            let variable = "contNoti" + item.key.charAt(0).toUpperCase() + item.key.slice(1);

            if (accDatta[name + "_" + item.key] === value) {
                checks[variable] = true;
            }
        });
        return checks;
    };

    const handleOpenDialogCustom = async (bnkDatta, reqData, month, showCheck) => {
        setBackDrop(true);

        await fetch(
            `/getUploadedFileCustom?organisationId=${bnkDatta.id_organisation}&type=${
                reqData.requirement_type
            }&date=${moment()
                .set({ year: selectedYear, month: parseInt(month) - 1 })
                .startOf("month")
                .format("YYYY-MM-DD")}&requirementId=${reqData._id}`,
            {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            }
        )
            .then((res) => res.json())
            .then((data) => {
                setCustomRequirementInfo({
                    idOrganisation: bnkDatta.id_organisation,
                    month: month,
                    company: bnkDatta.company_name,
                    current_date: moment()
                        .set({ year: selectedYear, month: parseInt(month) - 1 })
                        .startOf("month")
                        .format("YYYY-MM-DD"),
                    req_type: data.requirements.requirement_type,
                    req_name: data.requirements.requirement_name,
                    custom_requirement_id: data.requirements.custom_requirement_id,
                    not_applicable: data.requirements.not_applicable,
                });
                setUploadedFilesCustom(data.requirements.files);
                setUploadedFilesCustomSizes(data.requirements.sizes);
                setCustomStatus(data.requirements.not_applicable);
                setUploadedFilesCustomUploadDate(data.requirements.upload_date);
            });

        setOpenCustom(true);

        setBackDrop(false);
    };

    const loadInitData = async () => {
        console.log("selectedYear en loadInit", selectedYear)
        await fetch(
            `/getRequirementsInfo?contact_email=${
                todos.userInfo.email
            }&client_type=&practice_id=&franchise_id=&client_status=&year=${selectedYear}&status=&service=0&client_market=&inactive_clients=false&disable_banks=false`,
            {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            }
        )
            .then((res) => res.json())
            .then((data) => {
                let clientsdata = [];

                data.forEach((bnkDatta, index) => {
                    let notifyNum = {};
                    let notifyCheck = {};
                    let unlockCheck = {};
                    let warningCheck = {};

                    allMonths.forEach((item) => {
                        let variable = "contNoti" + item.key.charAt(0).toUpperCase() + item.key.slice(1);
                        notifyNum[variable] = 0;
                        notifyCheck[variable] = false;
                        unlockCheck[variable] = false;
                        warningCheck[variable] = false;
                    });

                    if (bnkDatta.accounts.length > 0) {
                        bnkDatta.accounts.forEach((accDatta) => {
                            if (!accDatta.cash) {
                                let checks = accDatta.requirement_checks && !accDatta.included_checks;

                                // Check verde
                                notifyCheck = boolCheck(accDatta, notifyCheck, 1, "bank");

                                // Alerta roja
                                notifyNum = countNoti(accDatta, notifyNum, "bank", checks);

                                // Unlock morado
                                unlockCheck = boolCheck(accDatta, unlockCheck, 3, "bank");
                            } else {
                                // Alerta amarilla
                                allMonths.forEach((item) => {
                                    if (accDatta["notify_" + item.key] !== 0) {
                                        let variable =
                                            "contNoti" + item.key.charAt(0).toUpperCase() + item.key.slice(1);
                                        warningCheck[variable] = true;
                                    }
                                });
                            }

                            let objMonths = {};

                            allMonths.forEach((item) => {
                                objMonths[item.key] = (
                                    <LightTooltip title={t("bills.uploadDoc")} aria-label="toolUpload">
                                        <span>
                                            <IconButton
                                                aria-label="upload"
                                                onClick={() =>
                                                    handleOpenDialog(
                                                        bnkDatta,
                                                        accDatta,
                                                        item.num,
                                                        accDatta["notify_" + item.key]
                                                    )
                                                }
                                                disabled={accDatta["notify_" + item.key] === 0}
                                            >
                                                {accDatta.requirement_checks &&
                                                !accDatta.included_checks &&
                                                moment(selectedYear + "-" + item.num + "-01").format("YYYY-MM-DD") >=
                                                    moment.utc(accDatta.initial_date).format("YYYY-MM-DD") ? (
                                                    <StyledBadgeChecks
                                                        variant="dot"
                                                        sx={{
                                                            "& .MuiBadge-badge": {
                                                                backgroundColor:
                                                                    accDatta["notify_" + item.key] === 3 &&
                                                                    accDatta["checks_" + item.key] === 0
                                                                        ? "#2f3190" //Morado
                                                                        : ((accDatta["checks_" + item.key] > 0 &&
                                                                              accDatta[
                                                                                  "checks_full_load_" + item.key
                                                                              ]) ||
                                                                              accDatta["checks_not_app_" + item.key]) &&
                                                                          moment()
                                                                              .add(-1, "months")
                                                                              .isAfter(
                                                                                  moment(item.num, "MM").set(
                                                                                      "year",
                                                                                      selectedYear
                                                                                  )
                                                                              )
                                                                        ? "green"
                                                                        : accDatta["checks_" + item.key] > 0 &&
                                                                          !accDatta["checks_full_load_" + item.key]
                                                                        ? "#ffb23e"
                                                                        : accDatta["checks_" + item.key] === 0 &&
                                                                          moment()
                                                                              .add(-1, "months")
                                                                              .isAfter(
                                                                                  moment(item.num, "MM").set(
                                                                                      "year",
                                                                                      selectedYear
                                                                                  )
                                                                              )
                                                                        ? "#ff5968"
                                                                        : "rgba(0, 0, 0, 0.26)",
                                                            },
                                                        }}
                                                    >
                                                        <UploadFileIcon
                                                            sx={{
                                                                color:
                                                                    accDatta["notify_" + item.key] === 2
                                                                        ? "#ff5968"
                                                                        : accDatta["notify_" + item.key] === 1
                                                                        ? "green"
                                                                        : accDatta["notify_" + item.key] === 3
                                                                        ? "#2f3190"
                                                                        : "rgba(0, 0, 0, 0.26)",
                                                            }}
                                                        />
                                                    </StyledBadgeChecks>
                                                ) : (
                                                    <UploadFileIcon
                                                        sx={{
                                                            color:
                                                                accDatta["notify_" + item.key] === 2
                                                                    ? "#ff5968"
                                                                    : accDatta["notify_" + item.key] === 1
                                                                    ? "green"
                                                                    : accDatta["notify_" + item.key] === 3
                                                                    ? "#2f3190"
                                                                    : "rgba(0, 0, 0, 0.26)",
                                                        }}
                                                    />
                                                )}
                                            </IconButton>
                                        </span>
                                    </LightTooltip>
                                );
                            });

                            clientsdata.push({
                                id: accDatta._id,
                                parentId: index + 0.1,
                                client: (
                                    <Typography sx={{ color: "#2F3190", fontSize: 15, fontWeight: 600 }}>
                                        {accDatta.account_name}
                                    </Typography>
                                ),
                                status: (
                                    <Chip
                                        label={
                                            accDatta.mode === 0
                                                ? "Bank Feed"
                                                : accDatta.mode === 1
                                                ? "Manual"
                                                : t("miscellaneous.pendingMode")
                                        }
                                        sx={{
                                            color:
                                                accDatta.mode === 0
                                                    ? "green"
                                                    : accDatta.mode === 1
                                                    ? "#031851"
                                                    : "#2f3190",
                                        }}
                                        variant="outlined"
                                    />
                                ),
                                jan: objMonths.jan,
                                feb: objMonths.feb,
                                mar: objMonths.mar,
                                apr: objMonths.apr,
                                may: objMonths.may,
                                jun: objMonths.jun,
                                jul: objMonths.jul,
                                aug: objMonths.aug,
                                sep: objMonths.sep,
                                oct: objMonths.oct,
                                nov: objMonths.nov,
                                dec: objMonths.dec,
                            });
                        });

                        let objMonthsNoti = {};
                        allMonths.forEach((item) => {
                            let variable = "contNoti" + item.key.charAt(0).toUpperCase() + item.key.slice(1);
                            objMonthsNoti[item.key] =
                                notifyNum[variable] > 0 ? (
                                    <Badge badgeContent={notifyNum[variable]} color="secondary">
                                        <ErrorOutlineIcon color="secondary" />
                                    </Badge>
                                ) : unlockCheck[variable] ? (
                                    <LockOpenIcon sx={{ color: "#2f3190" }} />
                                ) : notifyCheck[variable] ? (
                                    <CheckIcon sx={{ color: "green" }} />
                                ) : warningCheck[variable] ? (
                                    <WarningAmberIcon sx={{ color: "#FFB039" }} />
                                ) : (
                                    <LockIcon color="disabled" />
                                );
                        });

                        clientsdata.push({
                            id: index + 0.1,
                            parentId: bnkDatta.id_organisation,
                            client: (
                                <Typography sx={{ color: "#2F3190" }}>
                                    {t("miscellaneous.banks")} ({bnkDatta.accounts.length})
                                </Typography>
                            ),
                            jan: objMonthsNoti.jan,
                            feb: objMonthsNoti.feb,
                            mar: objMonthsNoti.mar,
                            apr: objMonthsNoti.apr,
                            may: objMonthsNoti.may,
                            jun: objMonthsNoti.jun,
                            jul: objMonthsNoti.jul,
                            aug: objMonthsNoti.aug,
                            sep: objMonthsNoti.sep,
                            oct: objMonthsNoti.oct,
                            nov: objMonthsNoti.nov,
                            dec: objMonthsNoti.dec,
                        });
                    }

                    if (bnkDatta.custom_requirements.length > 0) {
                        let recurringRequirements = bnkDatta.custom_requirements.filter((x) =>
                            [1, 2, 3, 4, 5].includes(x.requirement_type)
                        );
                        let occasionalRequirementsFixedAct = bnkDatta.custom_requirements.filter(
                            (x) => x.requirement_type === 6
                        );
                        let occasionalRequirementsPrest = bnkDatta.custom_requirements.filter(
                            (x) => x.requirement_type === 7
                        );

                        if (recurringRequirements.length > 0) {
                            let notifyNumRecu = {};
                            let notifyCheckRecu = {};
                            let unlockCheckRecu = {};

                            allMonths.forEach((item) => {
                                let variable = "contNoti" + item.key.charAt(0).toUpperCase() + item.key.slice(1);
                                notifyNumRecu[variable] = 0;
                                notifyCheckRecu[variable] = false;
                                unlockCheckRecu[variable] = false;
                            });

                            recurringRequirements.forEach((reqData) => {
                                // Check verde
                                notifyCheck = boolCheck(reqData, notifyCheck, 1, "req");
                                notifyCheckRecu = boolCheck(reqData, notifyCheckRecu, 1, "req");

                                // Alerta roja
                                notifyNum = countNoti(reqData, notifyNum, "req");
                                notifyNumRecu = countNoti(reqData, notifyNumRecu, "req");

                                // Unlock morado
                                unlockCheck = boolCheck(reqData, unlockCheck, 3, "req");
                                unlockCheckRecu = boolCheck(reqData, unlockCheckRecu, 3, "req");

                                let objMonthReq = {};
                                allMonths.forEach((item) => {
                                    objMonthReq[item.key] = (
                                        <LightTooltip title={t("bills.uploadDoc")} aria-label="toolUpload">
                                            <span>
                                                <IconButton
                                                    aria-label="upload"
                                                    onClick={() =>
                                                        handleOpenDialogCustom(
                                                            bnkDatta,
                                                            reqData,
                                                            item.num,
                                                            reqData["requirement_" + item.key]
                                                        )
                                                    }
                                                    disabled={reqData["requirement_" + item.key] === 0}
                                                >
                                                    <UploadFileIcon
                                                        sx={{
                                                            color:
                                                                reqData["requirement_" + item.key] === 2
                                                                    ? "#ff5968"
                                                                    : reqData["requirement_" + item.key] === 1
                                                                    ? "green"
                                                                    : reqData["requirement_" + item.key] === 3
                                                                    ? "#2f3190"
                                                                    : "rgba(0, 0, 0, 0.26)",
                                                        }}
                                                    />
                                                </IconButton>
                                            </span>
                                        </LightTooltip>
                                    );
                                });

                                clientsdata.push({
                                    id: reqData._id,
                                    parentId: "REC" + index,
                                    client: (
                                        <Typography sx={{ color: "#2F3190", fontSize: 15, fontWeight: 600 }}>
                                            {reqData.requirement_type === 1
                                                ? t("proposals.payroll")
                                                : reqData.requirement_type === 2
                                                ? t("language.locale") === "en"
                                                    ? "Contractors"
                                                    : "Contratistas"
                                                : reqData.requirement_type === 3
                                                ? t("language.locale") === "en"
                                                    ? "Sales"
                                                    : "Ventas"
                                                : reqData.requirement_type === 4
                                                ? t("miscellaneous.reportISLR")
                                                : reqData.requirement_name}
                                        </Typography>
                                    ),
                                    jan: objMonthReq.jan,
                                    feb: objMonthReq.feb,
                                    mar: objMonthReq.mar,
                                    apr: objMonthReq.apr,
                                    may: objMonthReq.may,
                                    jun: objMonthReq.jun,
                                    jul: objMonthReq.jul,
                                    aug: objMonthReq.aug,
                                    sep: objMonthReq.sep,
                                    oct: objMonthReq.oct,
                                    nov: objMonthReq.nov,
                                    dec: objMonthReq.dec,
                                });
                            });

                            let objMonthOccNot = {};
                            allMonths.forEach((item) => {
                                let variable = "contNoti" + item.key.charAt(0).toUpperCase() + item.key.slice(1);
                                objMonthOccNot[item.key] =
                                    notifyNumRecu[variable] > 0 ? (
                                        <Badge badgeContent={notifyNumRecu[variable]} color="secondary">
                                            <ErrorOutlineIcon color="secondary" />
                                        </Badge>
                                    ) : unlockCheckRecu[variable] ? (
                                        <LockOpenIcon sx={{ color: "#2f3190" }} />
                                    ) : notifyCheckRecu[variable] ? (
                                        <CheckIcon sx={{ color: "green" }} />
                                    ) : warningCheck[variable] ? (
                                        <WarningAmberIcon sx={{ color: "#FFB039" }} />
                                    ) : (
                                        <LockIcon color="disabled" />
                                    );
                            });

                            clientsdata.push({
                                id: "REC" + index,
                                parentId: bnkDatta.id_organisation,
                                client: (
                                    <Typography sx={{ color: "#2F3190" }}>
                                        {t("bank.otherRecu")} ({recurringRequirements.length})
                                    </Typography>
                                ),
                                jan: objMonthOccNot.jan,
                                feb: objMonthOccNot.feb,
                                mar: objMonthOccNot.mar,
                                apr: objMonthOccNot.apr,
                                may: objMonthOccNot.may,
                                jun: objMonthOccNot.jun,
                                jul: objMonthOccNot.jul,
                                aug: objMonthOccNot.aug,
                                sep: objMonthOccNot.sep,
                                oct: objMonthOccNot.oct,
                                nov: objMonthOccNot.nov,
                                dec: objMonthOccNot.dec,
                            });
                        }

                        if (occasionalRequirementsFixedAct.length > 0) {
                            let notifyNumFA = {};
                            let notifyCheckFA = {};
                            let unlockCheckFA = {};

                            allMonths.forEach((item) => {
                                let variable = "contNoti" + item.key.charAt(0).toUpperCase() + item.key.slice(1);
                                notifyNumFA[variable] = 0;
                                notifyCheckFA[variable] = false;
                                unlockCheckFA[variable] = false;
                            });

                            occasionalRequirementsFixedAct.forEach((fixedAct) => {
                                // Check verde
                                notifyCheck = boolCheck(fixedAct, notifyCheck, 1, "req");
                                notifyCheckFA = boolCheck(fixedAct, notifyCheckFA, 1, "req");

                                // Alerta roja
                                notifyNum = countNoti(fixedAct, notifyNum, "req");
                                notifyNumFA = countNoti(fixedAct, notifyNumFA, "req");

                                // Unlock morado
                                unlockCheck = boolCheck(fixedAct, unlockCheck, 3, "req");
                                unlockCheckFA = boolCheck(fixedAct, unlockCheckFA, 3, "req");

                                let objMonthOcc = {};
                                allMonths.forEach((item) => {
                                    objMonthOcc[item.key] = (
                                        <LightTooltip title={t("bills.uploadDoc")} aria-label="toolUpload">
                                            <span>
                                                <IconButton
                                                    aria-label="upload"
                                                    onClick={() =>
                                                        handleOpenDialogCustom(
                                                            bnkDatta,
                                                            fixedAct,
                                                            item.num,
                                                            fixedAct["requirement_" + item.key]
                                                        )
                                                    }
                                                    disabled={fixedAct["requirement_" + item.key] === 0}
                                                >
                                                    <UploadFileIcon
                                                        sx={{
                                                            color:
                                                                fixedAct["requirement_" + item.key] === 2
                                                                    ? "#ff5968"
                                                                    : fixedAct["requirement_" + item.key] === 1
                                                                    ? "green"
                                                                    : fixedAct["requirement_" + item.key] === 3
                                                                    ? "#2f3190"
                                                                    : "rgba(0, 0, 0, 0.26)",
                                                        }}
                                                    />
                                                </IconButton>
                                            </span>
                                        </LightTooltip>
                                    );
                                });

                                clientsdata.push({
                                    id: fixedAct._id,
                                    parentId: "CAF" + index,
                                    isNotAccount: fixedAct.cash,
                                    client: (
                                        <Typography sx={{ color: "#2F3190", fontSize: 15, fontWeight: 600 }}>
                                            {fixedAct.requirement_name}
                                        </Typography>
                                    ),
                                    jan: objMonthOcc.jan,
                                    feb: objMonthOcc.feb,
                                    mar: objMonthOcc.mar,
                                    apr: objMonthOcc.apr,
                                    may: objMonthOcc.may,
                                    jun: objMonthOcc.jun,
                                    jul: objMonthOcc.jul,
                                    aug: objMonthOcc.aug,
                                    sep: objMonthOcc.sep,
                                    oct: objMonthOcc.oct,
                                    nov: objMonthOcc.nov,
                                    dec: objMonthOcc.dec,
                                });
                            });

                            let objMonthOccNot = {};
                            allMonths.forEach((item) => {
                                let variable = "contNoti" + item.key.charAt(0).toUpperCase() + item.key.slice(1);
                                objMonthOccNot[item.key] =
                                    notifyNumFA[variable] > 0 ? (
                                        <Badge badgeContent={notifyNumFA[variable]} color="secondary">
                                            <ErrorOutlineIcon color="secondary" />
                                        </Badge>
                                    ) : unlockCheckFA[variable] ? (
                                        <LockOpenIcon sx={{ color: "#2f3190" }} />
                                    ) : notifyCheckFA[variable] ? (
                                        <CheckIcon sx={{ color: "green" }} />
                                    ) : warningCheck[variable] ? (
                                        <WarningAmberIcon sx={{ color: "#FFB039" }} />
                                    ) : (
                                        <LockIcon color="disabled" />
                                    );
                            });

                            clientsdata.push({
                                id: "CAF" + index,
                                parentId: bnkDatta.id_organisation,
                                client: (
                                    <Typography sx={{ color: "#2F3190" }}>
                                        {t("miscellaneous.fixAct")} ({occasionalRequirementsFixedAct.length})
                                    </Typography>
                                ),
                                jan: objMonthOccNot.jan,
                                feb: objMonthOccNot.feb,
                                mar: objMonthOccNot.mar,
                                apr: objMonthOccNot.apr,
                                may: objMonthOccNot.may,
                                jun: objMonthOccNot.jun,
                                jul: objMonthOccNot.jul,
                                aug: objMonthOccNot.aug,
                                sep: objMonthOccNot.sep,
                                oct: objMonthOccNot.oct,
                                nov: objMonthOccNot.nov,
                                dec: objMonthOccNot.dec,
                            });
                        }

                        if (occasionalRequirementsPrest.length > 0) {
                            let notifyNumPrest = {};
                            let notifyCheckPrest = {};
                            let unlockCheckPrest = {};

                            allMonths.forEach((item) => {
                                let variable = "contNoti" + item.key.charAt(0).toUpperCase() + item.key.slice(1);
                                notifyNumPrest[variable] = 0;
                                notifyCheckPrest[variable] = false;
                                unlockCheckPrest[variable] = false;
                            });

                            occasionalRequirementsPrest.forEach((prest) => {
                                // Check verde
                                notifyCheck = boolCheck(prest, notifyCheck, 1, "req");
                                notifyCheckPrest = boolCheck(prest, notifyCheckPrest, 1, "req");

                                // Alerta roja
                                notifyNum = countNoti(prest, notifyNum, "req");
                                notifyNumPrest = countNoti(prest, notifyNumPrest, "req");

                                // Unlock morado
                                unlockCheck = boolCheck(prest, unlockCheck, 3, "req");
                                unlockCheckPrest = boolCheck(prest, unlockCheckPrest, 3, "req");

                                let objMonthPrest = {};
                                allMonths.forEach((item) => {
                                    objMonthPrest[item.key] = (
                                        <LightTooltip title={t("bills.uploadDoc")} aria-label="toolUpload">
                                            <span>
                                                <IconButton
                                                    aria-label="upload"
                                                    onClick={() =>
                                                        handleOpenDialogCustom(
                                                            bnkDatta,
                                                            prest,
                                                            item.num,
                                                            prest["requirement_" + item.key]
                                                        )
                                                    }
                                                    disabled={prest["requirement_" + item.key] === 0}
                                                >
                                                    <UploadFileIcon
                                                        sx={{
                                                            color:
                                                                prest["requirement_" + item.key] === 2
                                                                    ? "#ff5968"
                                                                    : prest["requirement_" + item.key] === 1
                                                                    ? "green"
                                                                    : prest["requirement_" + item.key] === 3
                                                                    ? "#2f3190"
                                                                    : "rgba(0, 0, 0, 0.26)",
                                                        }}
                                                    />
                                                </IconButton>
                                            </span>
                                        </LightTooltip>
                                    );
                                });

                                clientsdata.push({
                                    id: prest._id,
                                    parentId: "PRE" + index,
                                    isNotAccount: prest.cash,
                                    client: (
                                        <Typography sx={{ color: "#2F3190", fontSize: 15, fontWeight: 600 }}>
                                            {prest.requirement_name}
                                        </Typography>
                                    ),
                                    jan: objMonthPrest.jan,
                                    feb: objMonthPrest.feb,
                                    mar: objMonthPrest.mar,
                                    apr: objMonthPrest.apr,
                                    may: objMonthPrest.may,
                                    jun: objMonthPrest.jun,
                                    jul: objMonthPrest.jul,
                                    aug: objMonthPrest.aug,
                                    sep: objMonthPrest.sep,
                                    oct: objMonthPrest.oct,
                                    nov: objMonthPrest.nov,
                                    dec: objMonthPrest.dec,
                                });
                            });

                            let objMonthPrestNoti = {};
                            allMonths.forEach((item) => {
                                let variable = "contNoti" + item.key.charAt(0).toUpperCase() + item.key.slice(1);
                                objMonthPrestNoti[item.key] =
                                    notifyNumPrest[variable] > 0 ? (
                                        <Badge badgeContent={notifyNumPrest[variable]} color="secondary">
                                            <ErrorOutlineIcon color="secondary" />
                                        </Badge>
                                    ) : unlockCheckPrest[variable] ? (
                                        <LockOpenIcon sx={{ color: "#2f3190" }} />
                                    ) : notifyCheckPrest[variable] ? (
                                        <CheckIcon sx={{ color: "green" }} />
                                    ) : warningCheck[variable] ? (
                                        <WarningAmberIcon sx={{ color: "#FFB039" }} />
                                    ) : (
                                        <LockIcon color="disabled" />
                                    );
                            });

                            clientsdata.push({
                                id: "PRE" + index,
                                parentId: bnkDatta.id_organisation,
                                client: (
                                    <Typography sx={{ color: "#2F3190" }}>
                                        {t("proposals.loans")} ({occasionalRequirementsPrest.length})
                                    </Typography>
                                ),
                                jan: objMonthPrestNoti.jan,
                                feb: objMonthPrestNoti.feb,
                                mar: objMonthPrestNoti.mar,
                                apr: objMonthPrestNoti.apr,
                                may: objMonthPrestNoti.may,
                                jun: objMonthPrestNoti.jun,
                                jul: objMonthPrestNoti.jul,
                                aug: objMonthPrestNoti.aug,
                                sep: objMonthPrestNoti.sep,
                                oct: objMonthPrestNoti.oct,
                                nov: objMonthPrestNoti.nov,
                                dec: objMonthPrestNoti.dec,
                            });
                        }
                    }

                    if (bnkDatta.accounts.length === 0 && bnkDatta.custom_requirements.length === 0) {
                        let cont = 0;
                        bnkDatta.comments.forEach((data) => {
                            if (data.status === 0) {
                                cont++;
                            }
                        });

                        let objMonth0 = {};
                        allMonths.forEach((item) => {
                            objMonth0[item.key] = moment()
                                .add(-1, "months")
                                .isAfter(moment(item.num, "MM").set("year", selectedYear)) ? (
                                <WarningAmberIcon sx={{ color: "#FFB039" }} />
                            ) : (
                                <LockIcon color="disabled" />
                            );
                        });

                        clientsdata.push({
                            id: bnkDatta.id_organisation,
                            parentId: null,
                            client: bnkDatta.company_name,
                            comments: (
                                <LightTooltip title={t("miscellaneous.comments")}>
                                    <IconButton
                                        aria-label="comments"
                                        sx={
                                            bnkDatta.comments.length === 0
                                                ? { color: "rgba(0, 0, 0, 0.54)" }
                                                : { color: "#2f3190" }
                                        }
                                        onClick={() => openComments(bnkDatta.id_organisation)}
                                    >
                                        <StyledBadge badgeContent={cont}>
                                            <CommentIcon />
                                        </StyledBadge>
                                    </IconButton>
                                </LightTooltip>
                            ),
                            jan: objMonth0.jan,
                            feb: objMonth0.feb,
                            mar: objMonth0.mar,
                            apr: objMonth0.apr,
                            may: objMonth0.may,
                            jun: objMonth0.jun,
                            jul: objMonth0.jul,
                            aug: objMonth0.aug,
                            sep: objMonth0.sep,
                            oct: objMonth0.oct,
                            nov: objMonth0.nov,
                            dec: objMonth0.dec,
                        });

                        clientsdata.push({
                            id: index + 0.1,
                            parentId: bnkDatta.id_organisation,
                            client: `${t("miscellaneous.banks")} (${bnkDatta.accounts.length})`,
                            jan: objMonth0.jan,
                            feb: objMonth0.feb,
                            mar: objMonth0.mar,
                            apr: objMonth0.apr,
                            may: objMonth0.may,
                            jun: objMonth0.jun,
                            jul: objMonth0.jul,
                            aug: objMonth0.aug,
                            sep: objMonth0.sep,
                            oct: objMonth0.oct,
                            nov: objMonth0.nov,
                            dec: objMonth0.dec,
                        });
                    } else {
                        let cont = 0;
                        bnkDatta.comments.forEach((data) => {
                            if (data.status === 0) {
                                cont++;
                            }
                        });

                        clientsdata.push({
                            id: bnkDatta.id_organisation,
                            parentId: null,
                            client: bnkDatta.company_name,
                            comments: (
                                <LightTooltip title={t("miscellaneous.comments")}>
                                    <IconButton
                                        aria-label="comments"
                                        sx={
                                            bnkDatta.comments.length === 0
                                                ? { color: "rgba(0, 0, 0, 0.54)" }
                                                : { color: "#2f3190" }
                                        }
                                        onClick={() => openComments(bnkDatta.id_organisation)}
                                    >
                                        <StyledBadge badgeContent={cont}>
                                            <CommentIcon />
                                        </StyledBadge>
                                    </IconButton>
                                </LightTooltip>
                            ),
                            jan:
                                notifyNum.contNotiJan > 0 ? (
                                    <Badge badgeContent={notifyNum.contNotiJan} color="secondary">
                                        <ErrorOutlineIcon color="secondary" />
                                    </Badge>
                                ) : unlockCheck.contNotiJan ? (
                                    <LockOpenIcon sx={{ color: "#2f3190" }} />
                                ) : notifyCheck.contNotiJan ? (
                                    <CheckIcon sx={{ color: "green" }} />
                                ) : (
                                    <LockIcon color="disabled" />
                                ),
                            feb:
                                notifyNum.contNotiFeb > 0 ? (
                                    <Badge badgeContent={notifyNum.contNotiFeb} color="secondary">
                                        <ErrorOutlineIcon color="secondary" />
                                    </Badge>
                                ) : unlockCheck.contNotiFeb ? (
                                    <LockOpenIcon sx={{ color: "#2f3190" }} />
                                ) : notifyCheck.contNotiFeb ? (
                                    <CheckIcon sx={{ color: "green" }} />
                                ) : (
                                    <LockIcon color="disabled" />
                                ),
                            mar:
                                notifyNum.contNotiMar > 0 ? (
                                    <Badge badgeContent={notifyNum.contNotiMar} color="secondary">
                                        <ErrorOutlineIcon color="secondary" />
                                    </Badge>
                                ) : unlockCheck.contNotiMar ? (
                                    <LockOpenIcon sx={{ color: "#2f3190" }} />
                                ) : notifyCheck.contNotiMar ? (
                                    <CheckIcon sx={{ color: "green" }} />
                                ) : (
                                    <LockIcon color="disabled" />
                                ),
                            apr:
                                notifyNum.contNotiApr > 0 ? (
                                    <Badge badgeContent={notifyNum.contNotiApr} color="secondary">
                                        <ErrorOutlineIcon color="secondary" />
                                    </Badge>
                                ) : unlockCheck.contNotiApr ? (
                                    <LockOpenIcon sx={{ color: "#2f3190" }} />
                                ) : notifyCheck.contNotiApr ? (
                                    <CheckIcon sx={{ color: "green" }} />
                                ) : (
                                    <LockIcon color="disabled" />
                                ),
                            may:
                                notifyNum.contNotiMay > 0 ? (
                                    <Badge badgeContent={notifyNum.contNotiMay} color="secondary">
                                        <ErrorOutlineIcon color="secondary" />
                                    </Badge>
                                ) : unlockCheck.contNotiMay ? (
                                    <LockOpenIcon sx={{ color: "#2f3190" }} />
                                ) : notifyCheck.contNotiMay ? (
                                    <CheckIcon sx={{ color: "green" }} />
                                ) : (
                                    <LockIcon color="disabled" />
                                ),
                            jun:
                                notifyNum.contNotiJun > 0 ? (
                                    <Badge badgeContent={notifyNum.contNotiJun} color="secondary">
                                        <ErrorOutlineIcon color="secondary" />
                                    </Badge>
                                ) : unlockCheck.contNotiJun ? (
                                    <LockOpenIcon sx={{ color: "#2f3190" }} />
                                ) : notifyCheck.contNotiJun ? (
                                    <CheckIcon sx={{ color: "green" }} />
                                ) : (
                                    <LockIcon color="disabled" />
                                ),
                            jul:
                                notifyNum.contNotiJul > 0 ? (
                                    <Badge badgeContent={notifyNum.contNotiJul} color="secondary">
                                        <ErrorOutlineIcon color="secondary" />
                                    </Badge>
                                ) : unlockCheck.contNotiJul ? (
                                    <LockOpenIcon sx={{ color: "#2f3190" }} />
                                ) : notifyCheck.contNotiJul ? (
                                    <CheckIcon sx={{ color: "green" }} />
                                ) : (
                                    <LockIcon color="disabled" />
                                ),
                            aug:
                                notifyNum.contNotiAug > 0 ? (
                                    <Badge badgeContent={notifyNum.contNotiAug} color="secondary">
                                        <ErrorOutlineIcon color="secondary" />
                                    </Badge>
                                ) : unlockCheck.contNotiAug ? (
                                    <LockOpenIcon sx={{ color: "#2f3190" }} />
                                ) : notifyCheck.contNotiAug ? (
                                    <CheckIcon sx={{ color: "green" }} />
                                ) : (
                                    <LockIcon color="disabled" />
                                ),
                            sep:
                                notifyNum.contNotiSep > 0 ? (
                                    <Badge badgeContent={notifyNum.contNotiSep} color="secondary">
                                        <ErrorOutlineIcon color="secondary" />
                                    </Badge>
                                ) : unlockCheck.contNotiSep ? (
                                    <LockOpenIcon sx={{ color: "#2f3190" }} />
                                ) : notifyCheck.contNotiSep ? (
                                    <CheckIcon sx={{ color: "green" }} />
                                ) : (
                                    <LockIcon color="disabled" />
                                ),
                            oct:
                                notifyNum.contNotiOct > 0 ? (
                                    <Badge badgeContent={notifyNum.contNotiOct} color="secondary">
                                        <ErrorOutlineIcon color="secondary" />
                                    </Badge>
                                ) : unlockCheck.contNotiOct ? (
                                    <LockOpenIcon sx={{ color: "#2f3190" }} />
                                ) : notifyCheck.contNotiOct ? (
                                    <CheckIcon sx={{ color: "green" }} />
                                ) : (
                                    <LockIcon color="disabled" />
                                ),
                            nov:
                                notifyNum.contNotiNov > 0 ? (
                                    <Badge badgeContent={notifyNum.contNotiNov} color="secondary">
                                        <ErrorOutlineIcon color="secondary" />
                                    </Badge>
                                ) : unlockCheck.contNotiNov ? (
                                    <LockOpenIcon sx={{ color: "#2f3190" }} />
                                ) : notifyCheck.contNotiNov ? (
                                    <CheckIcon sx={{ color: "green" }} />
                                ) : (
                                    <LockIcon color="disabled" />
                                ),
                            dec:
                                notifyNum.contNotiDec > 0 ? (
                                    <Badge badgeContent={notifyNum.contNotiDec} color="secondary">
                                        <ErrorOutlineIcon color="secondary" />
                                    </Badge>
                                ) : unlockCheck.contNotiDec ? (
                                    <LockOpenIcon sx={{ color: "#2f3190" }} />
                                ) : notifyCheck.contNotiDec ? (
                                    <CheckIcon sx={{ color: "green" }} />
                                ) : (
                                    <LockIcon color="disabled" />
                                ),
                        });
                    }
                });

                setClients(clientsdata);
            });
    };

    const uploadAsyncButtons = (
        <GridUI container justifyContent="space-between" alignItems="center" spacing={2}>
            <GridUI item>
                <ColorButton
                    sx={{
                        mr: 1,
                        "&:hover": {
                            backgroundColor: "white",
                            color: "#2f3190",
                            border: "1px solid #2f3190",
                        },
                    }}
                    color="secondary"
                    disableElevation
                    variant="outlined"
                    onClick={handleCloseDialog}
                >
                    {t("miscellaneous.cancel")}
                </ColorButton>
            </GridUI>
            <GridUI item>
                <ColorButton
                    disableElevation
                    variant="contained"
                    color="secondary"
                    disabled={
                        (filesData.length === 0 && uploadedFiles.length === 0 && !checkStatus) ||
                        (selectedBank.showCheck === 1 &&
                            !checkStatus &&
                            filesData.length === 0 &&
                            uploadedFiles.length === 0)
                    }
                    onClick={() => setOpenAlert(true)}
                    sx={{
                        "&:hover": {
                            backgroundColor: "#2f3190",
                            border: "1px solid #2f3190",
                            color: "#fff",
                        },
                        "&.MuiButton-root.Mui-disabled": {
                            backgroundColor: "#ffcdd2 !important",
                            border: "none",
                            color: "#fff",
                        },
                    }}
                >
                    {t("miscellaneous.save")}
                </ColorButton>
            </GridUI>
        </GridUI>
    );

    const setSkeleton = () => {
        setClients(rowsSkeleton);
    };

    /* Component events */
    useEffect(() => {
        (async () => {
            const columnsTemplate = [
                { name: "client", title: "SMB" },
                { name: "comments", title: " " },
                { name: "status", title: " " },
                { name: "jan", title: t("monthNamesShort.jan") },
                { name: "feb", title: t("monthNamesShort.feb") },
                { name: "mar", title: t("monthNamesShort.mar") },
                { name: "apr", title: t("monthNamesShort.apr") },
                { name: "may", title: t("monthNamesShort.may") },
                { name: "jun", title: t("monthNamesShort.jun") },
                { name: "jul", title: t("monthNamesShort.jul") },
                { name: "aug", title: t("monthNamesShort.aug") },
                { name: "sep", title: t("monthNamesShort.sep") },
                { name: "oct", title: t("monthNamesShort.oct") },
                { name: "nov", title: t("monthNamesShort.nov") },
                { name: "dec", title: t("monthNamesShort.dec") },
            ];
            setColumnsDefs(columnsTemplate);
            setSkeleton();

            await fetch(
                `/getNotificationYears`,
                {
                    method: "GET",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                    },
                }
            )
                .then((res) => res.json())
                .then((data) => {
                    setyearsToNoti(data);
                });

            await loadInitData();
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedYear, t]);

    return (
        <StyledContainer component="main" sx={{ flexGrow: 1, p: 4, width: "100%", pt: 10 }}>
            <Stack sx={{ pb: 4 }}>
                <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small" sx={{ color: "#031851" }} />}
                    aria-label="breadcrumb"
                >
                    {breadcrumbs}
                </Breadcrumbs>
            </Stack>
            <GridUI container spacing={2} justifyContent="center" alignItems="center">
                <GridUI item xs={12}>
                    <GridUI container spacing={2}>
                        <GridUI item xs={12} lg={12}>
                            <Card
                                elevation={0}
                                sx={{
                                    borderRadius: 8,
                                    boxShadow: "10px 10px 15px rgba(3,24,81,0.15)",
                                }}
                            >
                            <CardContent classes={{ root: classes.cardContentRoot }}>
                                <GridUI container spacing={2} className={classes.spacing} alignItems="center">
                                    <GridUI item xs={6} md={1}>
                                        <FormControl fullWidth classes={{ root: classes.formRounded }}>
                                            <InputLabel id="year-simple-select-label">{t("miscellaneous.year")}</InputLabel>
                                            <Select
                                                value={selectedYear}
                                                labelId="year-simple-select-label"
                                                onChange={handleClick}
                                                IconComponent={KeyboardArrowDownIcon}
                                                className="ddlGeneral"
                                                size="small"
                                                label={t("miscellaneous.year")}
                                                MenuProps={{
                                                    PaperProps: {
                                                        sx: {
                                                            mt: 0.7,
                                                            borderRadius: 2,
                                                            boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                        },
                                                    },
                                                }}
                                            >
                                                {yearsToNoti.map((year, index) => (
                                                    <MenuItem key={index} value={year}>
                                                        <Typography
                                                            variant="body1"
                                                            component="span"
                                                            align="center"
                                                            color="textPrimary"
                                                        >
                                                            {year}
                                                        </Typography>
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </GridUI>
                                </GridUI>
                                    <Grid rows={clientsData} columns={columnDefs}>
                                        <TreeDataState
                                            expandedRowIds={expandedRowIds}
                                            onExpandedRowIdsChange={setExpandenRowIds}
                                        />
                                        <CustomTreeData getChildRows={getChildRows} />
                                        <Table
                                            columnExtensions={tableColumnExtensions}
                                            cellComponent={TableCellComponent}
                                            rowComponent={TableRow}
                                            messages={tableMessages}
                                        />
                                        <TableHeaderRow
                                            cellComponent={tableHeaderCell}
                                            contentComponent={tableContentComponent}
                                        />
                                        <TableTreeColumn cellComponent={TableCellTreeComponent} for="client" />
                                    </Grid>
                                </CardContent>
                            </Card>
                        </GridUI>
                    </GridUI>
                </GridUI>
            </GridUI>
            <Backdrop open={showBackdrop} />
            <SnackBar type={alertType} show={show} message={texto} onClose={handleClose} />
            <DialogAlerts
                open={openAlert}
                onClose={() => setOpenAlert(false)}
                agreeBtnLabel={t("miscellaneous.accept")}
                disagreeBtnLabel={t("miscellaneous.cancel")}
                type={"info"}
                title={dialogText}
                agreeAction={convertBankStatement}
            />
            <CommentDialog
                open={dialogComment}
                onClose={closeDialog}
                maxWidth="xs"
                clientID={clientID}
                setTexto={setTexto}
                seAutoAlertType={setAlertType}
                setBackDrop={setBackDrop}
                setShow={setShow}
                comments={comments}
                commentsP={commentsP}
                getComments={getComments}
                setInitData={loadInitData}
                year={selectedYear}
                commentType={1}
            />
            <UploadAsync
                component="requirements"
                open={open}
                onClose={handleCloseDialog}
                filesData={filesData}
                setFilesData={setFilesData}
                filesRejected={filesRejected}
                setFilesRejected={setFilesRejected}
                fileFormat={fileFormat}
                selectedBank={selectedBank}
                selectedYear={selectedYear}
                uploadedFiles={uploadedFiles}
                loadInitData={loadInitData}
                actions={uploadAsyncButtons}
                setShow={setShow}
                setTexto={setTexto}
                setAlertType={setAlertType}
                handleChangeStatus={handleChangeStatus}
                checkStatus={checkStatus}
                showTabs={showTabs}
                buttonTabs={buttonTabs}
                handleChangeTabs={handleChangeTabs}
                filesRejectedChecks={filesRejectedChecks}
                setFilesRejectedChecks={setFilesRejectedChecks}
                filesRejectedPetty={filesRejectedPetty}
                setFilesRejectedPetty={setFilesRejectedPetty}
                filesDataChecks={filesDataChecks}
                setFilesDataChecks={setFilesDataChecks}
                filesDataPetty={filesDataPetty}
                setFilesDataPetty={setFilesDataPetty}
                checkStatusChecks={checkStatusChecks}
                checkStatusPetty={checkStatusPetty}
                page={page}
                setPage={setPage}
                fullLoadChecks={fullLoadChecks}
                handleChangeFullLoadChecks={handleChangeFullLoadChecks}
            />
            <UploadAsyncCustom
                component="requirements"
                open={openCustom}
                onClose={updateCustomRequirement}
                filesDataCustom={filesDataCustom}
                setFilesDataCustom={setFilesDataCustom}
                uploadedFilesCustom={uploadedFilesCustom}
                setUploadedFilesCustom={setUploadedFilesCustom}
                filesRejectedCustom={filesRejectedCustom}
                setFilesRejectedCustom={setFilesRejectedCustom}
                selectedYear={selectedYear}
                loadInitData={loadInitData}
                setShow={setShow}
                setTexto={setTexto}
                setAlertType={setAlertType}
                customRequirementInfo={customRequirementInfo}
                handleCloseDialogCustomDiscard={handleCloseDialogCustomDiscard}
                uploadedFilesCustomSizes={uploadedFilesCustomSizes}
                customStatus={customStatus}
                handleChangeCustomStatus={handleChangeCustomStatus}
                uploadedFilesCustomUploadDate={uploadedFilesCustomUploadDate}
            />
        </StyledContainer>
    );
};

export default ClientRequirements;
