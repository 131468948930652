/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import moment from "moment";

/** MUI icons */
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

/** MUI imports */
import {
    Grid,
    Table,
    TableHeaderRow,
    PagingPanel,
    Toolbar,
    SearchPanel,
    TableSelection,
} from "@devexpress/dx-react-grid-material-ui";
import {
    SearchState,
    IntegratedFiltering,
    PagingState,
    IntegratedPaging,
    SelectionState,
    IntegratedSelection,
} from "@devexpress/dx-react-grid";
import { styled } from "@mui/material/styles";
import GridUI from "@mui/material/Grid";
import {
    Box,
    Button,
    Chip,
    FormControl,
    Hidden,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
    Typography,
    Skeleton,
    Checkbox,
    ListItemText,
} from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

/** Components imports */
import SnackBar from "../SnackBar";
import Services from "../../Js/servicesInvoicing";
import Projects from "../../Js/projectsInvoicing";

/** Component styles */
const PREFIX = "InDeleted";

const classes = {
    paper: `${PREFIX}-paper`,
    tableStriped: `${PREFIX}-tableStriped`,
    tableHeader: `${PREFIX}-tableHeader`,
    tableBody: `${PREFIX}-tableBody`,
    pager: `${PREFIX}-pager`,
    cardContentRoot: `${PREFIX}-cardContentRoot`,
    cardContentRoot2: `${PREFIX}-cardContentRoot2`,
    cardHeaderRoot: `${PREFIX}-cardHeaderRoot`,
    formRounded: `${PREFIX}-formRounded`,
    formControlRounded: `${PREFIX}-formControlRounded`,
    spacing: `${PREFIX}-spacing`,
    toolbarSearch: `${PREFIX}-toolbarSearch`,
    searchInput: `${PREFIX}-searchInput`,
    totalTable: `${PREFIX}-totalTable`,
};

const projectsFiltered = Projects.filter(
    (service) =>
        service.code !== 6 &&
        service.code !== 11 &&
        service.code !== 16 &&
        service.code !== 20 &&
        service.code !== 27 &&
        service.code !== 30 &&
        service.code !== 33
);

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.totalTable}`]: {
        background: "#EBE9F4",
        borderRadius: "20px",
        padding: "7px 18px",
        width: 350,
        fontWeight: 600,
        textAlign: "center",
    },

    [`& .${classes.paper}`]: {
        border: "2px solid #ff5968",
        borderRadius: 40,
        boxShadow: "none",
    },

    [`& .${classes.cardContentRoot}`]: {
        "&:last-child": {
            paddingBottom: "5px",
        },
        padding: "10px",
    },

    [`& .${classes.cardContentRoot2}`]: {
        "&:last-child": {
            paddingBottom: "18px",
        },
        padding: 30,
    },

    [`& .${classes.cardHeaderRoot}`]: {
        padding: 30,
    },

    [`& .${classes.formRounded}`]: {
        backgroundColor: "#FFFFFF !important",
        borderRadius: 40,
        "&:focus-within": {
            backgroundColor: "#FFFFFF",
            borderRadius: 40,
        },
        "& .MuiOutlinedInput-notchedOutline": {
            border: "2px solid #031851",
            borderRadius: 40,
        },
    },

    [`& .${classes.formControlRounded}`]: {
        backgroundColor: "#FFFFFF !important",
        borderRadius: 40,
        border: "2px solid #031851",
        verticalAlign: "middle !important",
    },

    [`& .${classes.spacing}`]: {
        padding: "30px 0 15px 0",
    },

    [`& .${classes.tableStriped}`]: {
        "& tbody tr:nth-of-type(odd)": {
            backgroundColor: "rgba(47,49,144,0.1)",
        },
    },
    [`& .${classes.tableHeader}`]: {
        border: "none ",
        backgroundColor: "rgba(47,49,144,0.3)",
        fontSize: "15px",
        fontWeight: 600,
        color: "#031851",
        "&:first-of-type": {
            borderRadius: "20px 0 0 0",
        },
        "&:last-child": {
            borderRadius: "0 20px 0 0",
            paddingRight: "24px",
        },
    },

    [`& .${classes.tableBody}`]: {
        border: "none",
        fontSize: "14px",
        fontWeight: 400,
        color: "#031851",
    },

    [`& .${classes.pager}`]: {
        "& .Pagination-button": {
            paddingTop: 0,
            paddingBottom: 0,
            color: "#031851",
            fontWeight: 600,
        },
        "& .Pagination-activeButton": {
            backgroundColor: "rgba(47,49,144,0.3)",
        },
    },

    [`& .${classes.toolbarSearch}`]: {
        border: "none !important",
        padding: "0 0 5px !important",
    },

    [`& .${classes.searchInput}`]: {
        color: "#031851",
        "&:before": {
            borderBottomColor: "#031851",
        },
        "&:hover:before": {
            borderBottomColor: "#031851 !important",
        },
    },
}));

const tableHeaderCell = ({ ...props }) => <TableHeaderRow.Cell {...props} className={classes.tableHeader} />;

const TableComponent = ({ ...props }) => <Table.Table {...props} className={classes.tableStriped} />;

const TableCellComponent = ({ ...props }) => <Table.Cell {...props} className={classes.tableBody} />;

const tableContentComponent = (props) => <TableHeaderRow.Content {...props} />;

const PagingPanelContainer = ({ ...props }) => <PagingPanel.Container {...props} className={classes.pager} />;

const inputComponent = ({ ...props }) => <SearchPanel.Input {...props} className={classes.searchInput} />;

const toolRootComponent = ({ ...props }) => <Toolbar.Root {...props} className={classes.toolbarSearch} />;

const tableSelectionCell = ({ ...props }) => <TableSelection.Cell {...props} className={classes.tableBody} />;

/** Global variables */
let disabledSelectedAll = true;

const tableColumnExtensions = [
    { columnName: "contact", wordWrapEnabled: true },
    { columnName: "smb", wordWrapEnabled: true },
    { columnName: "service", align: "center" },
    { columnName: "project", align: "center" },
    { columnName: "period", align: "center" },
    { columnName: "initial", align: "center" },
    { columnName: "estimatedDeliveryDate", align: "center" },
    { columnName: "amount", align: "right" },
    { columnName: "documentType", align: "center" },
    { columnName: "removedBy", align: "center" },
];

const rowsSkeleton = [];

for (let i = 0; i < 10; i++) {
    rowsSkeleton.push({
        id: i,
        contact: <Skeleton variant="text" animation="wave" />,
        smb: <Skeleton variant="text" animation="wave" />,
        service: <Skeleton variant="text" animation="wave" />,
        project: <Skeleton variant="text" animation="wave" />,
        period: <Skeleton variant="text" animation="wave" />,
        initial: <Skeleton variant="text" animation="wave" />,
        estimatedDeliveryDate: <Skeleton variant="text" animation="wave" />,
        amount: <Skeleton variant="text" animation="wave" />,
        documentType: (
            <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />
        ),
        removedBy: <Skeleton variant="text" animation="wave" />,
    });
}

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 48 * 4.5 + 8,
            boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
        },
        sx: {
            borderRadius: 2,
            mt: 0.7,
        },
    },
    variant: "menu",
    getContentAnchorEl: null,
};

const InDeleted = (props) => {
    const {
        franqData,
        groupDataProps,
        deletedData,
        setDeletedData,
        selectionDeleted,
        setSelectionDeleted,
        deleteInvoicesPermanently,
    } = props;

    /** Internationalization i18n */
    const [t] = useTranslation("global");

    const markets = [t("miscellaneous.all"), "Global", "LATAM", "USA", "Venezuela"];

    const AllItem = {
        code: "all",
        name: t("miscellaneous.all"),
    };
    const tableMessages = {
        noData: t("miscellaneous.noData"),
    };
    const pagingMessages = {
        info: ({ from, to, count }) => `${from}${from < to ? `-${to}` : ""} ${t("miscellaneous.pagingOf")} ${count}`,
    };

    const searchMessages = {
        searchPlaceholder: t("miscellaneous.search"),
    };

    /** React redux */
    const todos = useSelector((state) => state.todos);

    /** Component states */
    const [show, setShow] = useState(false);
    const [texto, setTexto] = useState("");
    const [alertType, setAutoAlertType] = useState("");
    const [columnDefs, setColumnsDefs] = useState([]);
    const [searchValue, setSearchState] = useState("");
    const [groupData, setGroupData] = useState(groupDataProps);
    const [franqF, setfranqF] = useState(["all"].concat(franqData.map((item) => item.id)));
    const [groupF, setgroupF] = useState(["all"].concat(groupDataProps.map((item) => item.id)));
    const [updateTypeS, setUpdateTypeS] = useState(["all"].concat(Services.map((item) => item.code)));
    const [typeP, setTypeP] = useState(["all"].concat(projectsFiltered.map((item) => item.code)));
    const [clientMarket, setClientMarket] = useState(markets);
    const [documentTypeF, setDocumentTypeF] = useState("");
    const [sinceDateF, setSinceDateF] = useState(null);
    const [untilDateF, setUntilDateF] = useState(null);
    const [projects, setProjects] = useState([]);
    const [anyFilter, setAnyFilter] = useState(true);

    const controllerRef = useRef(null);
    /** Component functions */
    const cleanFilters = () => {
        setAnyFilter(true);
        setfranqF(["all"].concat(franqData.map((item) => item.id)));
        setgroupF(["all"].concat(groupDataProps.map((item) => item.id)));
        setGroupData(groupDataProps);
        setDocumentTypeF("");
        setClientMarket(markets);
        setTypeP(["all"].concat(projectsFiltered.map((item) => item.code)));
        setProjects([]);
        setUpdateTypeS(["all"].concat(Services.map((item) => item.code)));
        setSinceDateF(null);
        setUntilDateF(null);
    };

    const tableHeaderSelCellBase = ({ ...props }) => (
        <TableSelection.HeaderCell {...props} className={classes.tableHeader} disabled={disabledSelectedAll} />
    );

    const setSelection = (gridSelectedRows) => {
        if (gridSelectedRows.length !== 0) {
            props.setIsSelected(true);
        } else {
            props.setIsSelected(false);
        }

        setSelectionDeleted(gridSelectedRows);
    };

    const handleSearch = (value) => {
        let itemS = value.replace(/ +(?= )/g, "");
        setSearchState(itemS);
    };

    const handleChangeFranqF = async (event) => {
        const {
            target: { value },
        } = event;

        let final = [];

        if (value.includes("all") && !franqF.includes("all")) {
            final = ["all"].concat(franqData.map((item) => item.id));
        } else if (!value.includes("all") && franqF.includes("all")) {
            final = [];
        } else {
            if (!value.includes("all") && value.length === franqData.length) {
                final = ["all"].concat(franqData.map((item) => item.id));
            } else {
                final =
                    typeof value === "string"
                        ? value.split(",").filter((item) => item !== "all")
                        : value.filter((item) => item !== "all");
            }
        }
        setfranqF(final);
        setAnyFilter(false);

        let finalFranq = [];
        if (final.includes("all")) {
            finalFranq = [];
        } else if (final.length === 0) {
            finalFranq = ["none"];
        } else {
            finalFranq = final.filter((item) => item !== "all");
        }

        await fetch(`/getFranchiseGroups?id_franchise=${finalFranq}`, {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then((res) => res.json())
            .then((data) => {
                setgroupF(["all"].concat(data.map((item) => item.id)));
                setGroupData(data);
            });
    };

    const handleChangeGroupF = (event) => {
        const {
            target: { value },
        } = event;

        let final = [];

        if (value.includes("all") && !groupF.includes("all")) {
            final = ["all"].concat(groupData.map((item) => item.id));
        } else if (!value.includes("all") && groupF.includes("all")) {
            final = [];
        } else {
            if (!value.includes("all") && value.length === groupData.length) {
                final = ["all"].concat(groupData.map((item) => item.id));
            } else {
                final = event.target.value.filter((item) => item !== "all");
            }
        }
        setgroupF(final);
        setAnyFilter(false);
    };

    const handleChangeUpdateTypeS = (event) => {
        const {
            target: { value },
        } = event;

        let projects_aux = [];
        let final = [];

        if (value.includes("all") && !updateTypeS.includes("all")) {
            projects_aux = projectsFiltered;
            final = ["all"].concat(Services.map((item) => item.code));
        } else if (!value.includes("all") && updateTypeS.includes("all")) {
            projects_aux = projectsFiltered;
            final = [];
        } else {
            if (!value.includes("all") && value.length === Services.length) {
                projects_aux = projectsFiltered;
                final = ["all"].concat(Services.map((item) => item.code));
            } else {
                for (let i = 0; i < value.length; i++) {
                    let concatArr = projectsFiltered.filter((item) => item.parent === value[i]);
                    projects_aux = projects_aux.concat(concatArr);
                }
                final = event.target.value.filter((item) => item !== "all");
            }
        }
        setTypeP(["all"].concat(projects_aux.map((item) => item.code)));
        setProjects(projects_aux);
        setUpdateTypeS(final);
        setAnyFilter(false);
    };

    const handleChangeTypeP = (event) => {
        const {
            target: { value },
        } = event;

        let final = [];

        if (value.includes("all") && !typeP.includes("all")) {
            final = ["all"].concat(projects.map((item) => item.code));
        } else if (!value.includes("all") && typeP.includes("all")) {
            final = [];
        } else {
            if (!value.includes("all") && value.length === projects.length) {
                final = ["all"].concat(projects.map((item) => item.code));
            } else {
                final =
                    typeof value === "string"
                        ? value.split(",").filter((item) => item !== "all")
                        : value.filter((item) => item !== "all");
            }
        }

        setTypeP(final);
        setAnyFilter(false);
    };

    const handleChangeClientMarket = (event) => {
        const {
            target: { value },
        } = event;

        let final = [];

        if (value.includes(t("miscellaneous.all")) && !clientMarket.includes(t("miscellaneous.all"))) {
            final = markets;
        } else if (!value.includes(t("miscellaneous.all")) && clientMarket.includes(t("miscellaneous.all"))) {
            final = [];
        } else {
            if (
                !value.includes(t("miscellaneous.all")) &&
                value.length === markets.filter((item) => item !== t("miscellaneous.all")).length
            ) {
                final = markets;
            } else {
                final =
                    typeof value === "string"
                        ? value.split(",").filter((item) => item !== t("miscellaneous.all"))
                        : value.filter((item) => item !== t("miscellaneous.all"));
            }
        }
        setClientMarket(final);
        setAnyFilter(false);
    };

    const handleChangeDocumentType = (event) => {
        setDocumentTypeF(event.target.value);
        setAnyFilter(false);
    };

    const handleChangeSinceDate = (date) => {
        if (!date) {
            setSinceDateF(date);
        } else if (!untilDateF) {
            setSinceDateF(moment(date).format("YYYY-MM-DD"));
        } else {
            if (moment(date).isAfter(moment(untilDateF))) {
                setSinceDateF(null);
                setTexto(t("reports.initDateMess"));
                setAutoAlertType("warning");
                setShow(true);
            } else {
                setSinceDateF(moment(date).format("YYYY-MM-DD"));
            }
        }
        setAnyFilter(false);
    };

    const handleChangeUntilDate = (date) => {
        if (!date) {
            setUntilDateF(date);
        } else if (!sinceDateF) {
            setUntilDateF(moment(date).format("YYYY-MM-DD"));
        } else {
            if (moment(date).isBefore(moment(sinceDateF))) {
                setUntilDateF(null);
                setTexto(t("reports.initDateMess"));
                setAutoAlertType("warning");
                setShow(true);
            } else {
                setUntilDateF(moment(date).format("YYYY-MM-DD"));
            }
        }
        setAnyFilter(false);
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setShow(false);
    };

    const dateValidation = (date) => {
        const thirtyDaysAgo = moment().subtract(30, "days");
        const lineDate = moment(date);
        return lineDate.isBefore(thirtyDaysAgo);
    };

    const loadInitData = async () => {
        setSearchState("");
        disabledSelectedAll = true;

        if (controllerRef.current) {
            controllerRef.current.abort();
        }
        const controller = new AbortController();
        controllerRef.current = controller;

        let finalClientMarket = [];
        if (clientMarket.includes(t("miscellaneous.all"))) {
            finalClientMarket = [];
        } else if (clientMarket.length === 0) {
            finalClientMarket = ["none"];
        } else {
            finalClientMarket = clientMarket.filter((item) => item !== t("miscellaneous.all"));
        }

        let finalUpdateTypeS = [];
        if (updateTypeS.includes("all")) {
            finalUpdateTypeS = [];
        } else {
            finalUpdateTypeS = updateTypeS.filter((item) => item !== "all");
        }

        let finalTypeP = [];
        if (typeP.includes("all")) {
            finalTypeP = [];
        } else {
            finalTypeP = typeP.filter((item) => item !== "all");
        }

        let finalFranq = [];
        if (franqF.includes("all")) {
            finalFranq = [];
        } else if (franqF.length === 0) {
            finalFranq = ["none"];
        } else {
            finalFranq = franqF.filter((item) => item !== "all");
        }

        let finalGroup = [];
        if (groupF.includes("all")) {
            finalGroup = [];
        } else if (groupF.length === 0) {
            finalGroup = ["none"];
        } else {
            finalGroup = groupF.filter((item) => item !== "all");
        }

        const resultT = await fetch(
            `/getLineItemsInvoices?franchise_id=${finalFranq}&practice_id=${finalGroup}&service_type=${finalUpdateTypeS}&project_type=${finalTypeP}&client_market=${finalClientMarket}&document_type=${documentTypeF}&init_date=${
                sinceDateF === null ? "" : sinceDateF
            }&end_date=${untilDateF === null ? "" : untilDateF}`,
            {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
                signal: controllerRef.current.signal,
            }
        )
            .then((res) => res.json())
            .then((data) => {
                if (data.length !== 0) {
                    for (let i = 0; i < data.length; i++) {
                        let pivot = data[i].invoicing_contact_name;
                        let pivot2 = data[i].document_type;

                        for (let j = i + 1; j < data.length; j++) {
                            if (data[i].invoicing_contact_name === pivot && data[i].document_type === pivot2)
                                disabledSelectedAll = false;
                        }
                    }
                }
                controllerRef.current = null;
                return data;
            });

        let deletedItems = [];
        resultT.forEach((item) => {
            item.deleted &&
                deletedItems.push({
                    hiddenFranchiseId:
                        item.franchise_id !== undefined && item.franchise_id !== null ? item.franchise_id : undefined,
                    hiddenPracticeId:
                        item.practice_id !== undefined && item.practice_id !== null ? item.practice_id : undefined,
                    hiddenCountry: item.country_name,
                    hiddenInvSubs: item.invoicing_subscription,
                    hiddenDescription:
                        item.description !== undefined && item.description !== null ? item.description : undefined,
                    hiddenService: Services.find((service) => service.code === item.service_type).name,
                    hiddenProject: Projects.find((project) => project.code === item.project_type).name,
                    hiddenProjectType: item.project_type,
                    hiddenInitDate:
                        item.initial_date !== undefined && item.initial_date !== null
                            ? moment(item.initial_date)
                            : null,
                    hiddenEndDate: item.end_date !== undefined && item.end_date !== null ? moment(item.end_date) : null,
                    hiddenAmount: item.amount,
                    hiddenDocumentType: item.document_type,
                    id: item._id,
                    companyId: item.company_id,
                    contact: item.invoicing_contact_name,
                    smb: item.franchise_name,
                    service: (
                        <Typography variant="body1" component="span" align="center" color="textPrimary">
                            {t(`invoicing.${Services.find((service) => service.code === item.service_type).name}`)}
                        </Typography>
                    ),
                    project: (
                        <Typography variant="body1" component="span" align="center" color="textPrimary">
                            {t(`invoicing.${Projects.find((project) => project.code === item.project_type).name}`)}
                        </Typography>
                    ),
                    period:
                        item.end_date !== undefined &&
                        item.end_date !== null &&
                        moment(item.end_date, "YYYY-MM-DD").locale(t("language.locale")).format("MMM YY") !==
                            moment(item.initial_date, "YYYY-MM-DD").locale(t("language.locale")).format("MMM YY")
                            ? `${(
                                  moment(item.initial_date, "YYYY-MM-DD")
                                      .locale(t("language.locale"))
                                      .format("MMM")
                                      .charAt(0)
                                      .toUpperCase() +
                                  moment(item.initial_date, "YYYY-MM-DD")
                                      .locale(t("language.locale"))
                                      .format("MMM YY")
                                      .slice(1)
                              ).replace(".", "")} - ${(
                                  moment(item.end_date, "YYYY-MM-DD")
                                      .locale(t("language.locale"))
                                      .format("MMM")
                                      .charAt(0)
                                      .toUpperCase() +
                                  moment(item.end_date, "YYYY-MM-DD")
                                      .locale(t("language.locale"))
                                      .format("MMM YY")
                                      .slice(1)
                              ).replace(".", "")}`
                            : item.initial_date !== undefined && item.initial_date !== null
                            ? (
                                  moment(item.initial_date, "YYYY-MM-DD")
                                      .locale(t("language.locale"))
                                      .format("MMM")
                                      .charAt(0)
                                      .toUpperCase() +
                                  moment(item.initial_date, "YYYY-MM-DD")
                                      .locale(t("language.locale"))
                                      .format("MMM YY")
                                      .slice(1)
                              ).replace(".", "")
                            : "-",
                    initial:
                        item.line_item_date !== undefined && item.line_item_date !== null
                            ? moment(item.line_item_date, "YYYY-MM-DD").format(todos.dateFormat)
                            : "-",
                    estimatedDeliveryDate:
                        item.estimated_delivery_date !== undefined && item.estimated_delivery_date !== null
                            ? moment(item.estimated_delivery_date, "YYYY-MM-DD").format(todos.dateFormat)
                            : "-",
                    amount: item.amount
                        ? parseFloat(item.amount).toLocaleString(todos.amountFormat, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                          })
                        : parseFloat(0).toLocaleString(todos.amountFormat, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                          }),
                    documentType:
                        item.document_type === 1 ? (
                            ""
                        ) : (
                            <Chip
                                label="NC"
                                sx={{
                                    backgroundColor: "#fff",
                                    color: "#FFB039",
                                    borderWidth: 2,
                                    borderColor: "#FFB039",
                                }}
                                variant="outlined"
                            />
                        ),
                    serviceCode: item.service_type,
                    removedBy: item.deleted_user,
                    deletedDate: item.deleted_date,
                    deleted: item.deleted,
                });
        });

        const idsItemsToDelete = [];
        deletedItems.forEach((item) => {
            if (item.deleted && dateValidation(item.deletedDate)) {
                idsItemsToDelete.push(item.id);
            }
        });

        if (idsItemsToDelete.length) {
            await deleteInvoicesPermanently(true, idsItemsToDelete);
        }

        deletedItems = deletedItems.filter((item) => !idsItemsToDelete.includes(item.id));

        deletedItems.sort(function compare(a, b) {
            let dateA = +new Date(a.deletedDate) || Number.MIN_SAFE_INTEGER;
            let dateB = +new Date(b.deletedDate) || Number.MIN_SAFE_INTEGER;
            return dateB - dateA;
        });

        setDeletedData(deletedItems);
    };

    useEffect(() => {
        setProjects(projectsFiltered);
    }, []);

    useEffect(() => {
        (async () => {
            const columnsTemplate = [
                { name: "contact", title: t("invoicing.invoicingContact") },
                { name: "smb", title: t("miscellaneous.smb") },
                { name: "service", title: t("miscellaneous.service") },
                { name: "project", title: t("invoicing.project") },
                { name: "period", title: t("invoicing.period") },
                { name: "initial", title: t("invoicing.creationDate") },
                {
                    name: "estimatedDeliveryDate",
                    title: t("invoicing.scheduledShipDate"),
                },
                { name: "amount", title: t("invoicing.amount") },
                { name: "documentType", title: " " },
                { name: "removedBy", title: t("miscellaneous.removedBy") },
            ];
            setColumnsDefs(columnsTemplate);
            setDeletedData(rowsSkeleton);

            await loadInitData();
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        t,
        todos.dateFormat,
        todos.amountFormat,
        franqF,
        groupF,
        updateTypeS,
        typeP,
        clientMarket,
        documentTypeF,
        sinceDateF,
        untilDateF,
        props.reloadData,
    ]);

    return (
        <StyledContainer component="main" sx={{ flexGrow: 1, width: "100%" }}>
            <GridUI container spacing={2} alignItems="center" className={classes.spacing}>
                <GridUI item xs={12} md={4} xl={2}>
                    <FormControl
                        fullWidth
                        classes={{
                            root: classes.formRounded,
                        }}
                        size="small"
                    >
                        <InputLabel shrink id="franq-simple-select-label">
                            {t("miscellaneous.franchise")}
                        </InputLabel>
                        <Select
                            labelId="franq-multiple-select-label"
                            IconComponent={KeyboardArrowDownIcon}
                            value={franqF}
                            displayEmpty
                            onChange={handleChangeFranqF}
                            className="ddlGeneral"
                            multiple
                            input={
                                <OutlinedInput
                                    notched
                                    label={t("miscellaneous.franchise")}
                                    sx={{
                                        fontSize: 16,
                                        fontWeight: 600,
                                    }}
                                />
                            }
                            renderValue={(selected) => {
                                if (selected.length === 0) {
                                    return <span>{t("miscellaneous.none")}</span>;
                                }
                                return selected.includes("all")
                                    ? t("miscellaneous.all")
                                    : selected
                                          .map((item) => {
                                              return franqData.filter((item2) => item2.id === item)[0].franchise_name;
                                          })
                                          .join(", ");
                            }}
                            MenuProps={MenuProps}
                        >
                            {[
                                {
                                    id: "all",
                                    franchise_name: t("miscellaneous.all"),
                                },
                            ]
                                .concat(franqData)
                                .map((value) => (
                                    <MenuItem value={value.id}>
                                        <Checkbox
                                            defaultChecked
                                            checked={franqF.indexOf(value.id) > -1}
                                            icon={
                                                value.id === "all" && !franqF.includes("all") && franqF.length > 0 ? (
                                                    <IndeterminateCheckBoxIcon style={{ fill: "#031851" }} />
                                                ) : (
                                                    <CheckBoxOutlineBlankIcon />
                                                )
                                            }
                                        />
                                        <ListItemText primary={value.franchise_name} />
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                </GridUI>
                <GridUI item xs={12} md={4} xl={2}>
                    <FormControl
                        fullWidth
                        classes={{
                            root: classes.formRounded,
                        }}
                        size="small"
                    >
                        <InputLabel shrink id="group-simple-select-label">
                            {t("miscellaneous.group")}
                        </InputLabel>
                        <Select
                            labelId="group-simple-select-label"
                            IconComponent={KeyboardArrowDownIcon}
                            value={groupF}
                            displayEmpty
                            onChange={handleChangeGroupF}
                            className="ddlGeneral"
                            multiple
                            input={
                                <OutlinedInput
                                    notched
                                    label={t("miscellaneous.group")}
                                    sx={{
                                        fontSize: 16,
                                        fontWeight: 600,
                                    }}
                                />
                            }
                            renderValue={(selected) => {
                                if (selected.length === 0) {
                                    return <span>{t("miscellaneous.none")}</span>;
                                }
                                return selected.includes("all")
                                    ? t("miscellaneous.all")
                                    : selected
                                          .map((item) => {
                                              return groupDataProps.filter((item2) => item2.id === item)[0].group_name;
                                          })
                                          .join(", ");
                            }}
                            MenuProps={MenuProps}
                        >
                            {[
                                {
                                    id: "all",
                                    group_name: t("miscellaneous.all"),
                                },
                            ]
                                .concat(groupData)
                                .map((value) => (
                                    <MenuItem value={value.id}>
                                        <Checkbox
                                            defaultChecked
                                            checked={groupF.indexOf(value.id) > -1}
                                            icon={
                                                value.id === "all" && !groupF.includes("all") && groupF.length > 0 ? (
                                                    <IndeterminateCheckBoxIcon style={{ fill: "#031851" }} />
                                                ) : (
                                                    <CheckBoxOutlineBlankIcon />
                                                )
                                            }
                                        />
                                        <ListItemText primary={value.group_name} />
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                </GridUI>
                <GridUI item xs={12} md={4} xl={2}>
                    <FormControl
                        fullWidth
                        classes={{
                            root: classes.formRounded,
                        }}
                        size="small"
                    >
                        <InputLabel shrink id="document-type-simple-select-label">
                            {t("invoicing.documentType")}
                        </InputLabel>
                        <Select
                            IconComponent={KeyboardArrowDownIcon}
                            value={documentTypeF}
                            displayEmpty
                            onChange={handleChangeDocumentType}
                            className="ddlGeneral"
                            input={<OutlinedInput notched label={t("invoicing.documentType")} />}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        mt: 0.7,
                                        borderRadius: 2,
                                        boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                    },
                                },
                            }}
                        >
                            <MenuItem value="">
                                <Typography variant="body1" component="span" align="center" color="textPrimary">
                                    {t("miscellaneous.all")}
                                </Typography>
                            </MenuItem>
                            <MenuItem value={1}>
                                <Typography variant="body1" component="span" align="center" color="textPrimary">
                                    {t("miscellaneous.invoice")}
                                </Typography>
                            </MenuItem>
                            <MenuItem value={2}>
                                <Typography variant="body1" component="span" align="center" color="textPrimary">
                                    {t("invoicing.creditNote")}
                                </Typography>
                            </MenuItem>
                        </Select>
                    </FormControl>
                </GridUI>
                <GridUI item xs={12} md={4} xl={1}>
                    <FormControl
                        fullWidth
                        classes={{
                            root: classes.formRounded,
                        }}
                        size="small"
                    >
                        <InputLabel id="market-multiple-select-label" shrink>
                            {t("miscellaneous.market")}
                        </InputLabel>
                        <Select
                            labelId="market-multiple-select-label"
                            IconComponent={KeyboardArrowDownIcon}
                            displayEmpty
                            value={clientMarket}
                            onChange={handleChangeClientMarket}
                            multiple
                            input={
                                <OutlinedInput
                                    notched
                                    label={t("miscellaneous.market")}
                                    sx={{
                                        fontSize: 16,
                                        fontWeight: 600,
                                    }}
                                />
                            }
                            renderValue={(selected) => {
                                if (selected.length === 0) {
                                    return <span>{t("miscellaneous.none")}</span>;
                                }

                                return selected.includes(t("miscellaneous.all"))
                                    ? t("miscellaneous.all")
                                    : selected.join(", ");
                            }}
                            MenuProps={MenuProps}
                        >
                            {markets.map((name) => (
                                <MenuItem value={name}>
                                    <Checkbox
                                        checked={clientMarket.indexOf(name) > -1}
                                        icon={
                                            name === t("miscellaneous.all") &&
                                            !clientMarket.includes(t("miscellaneous.all")) &&
                                            clientMarket.length > 0 ? (
                                                <IndeterminateCheckBoxIcon style={{ fill: "#031851" }} />
                                            ) : (
                                                <CheckBoxOutlineBlankIcon />
                                            )
                                        }
                                    />
                                    <ListItemText primary={name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </GridUI>
                <GridUI item xs={12} md={4} xl={1}>
                    <FormControl
                        fullWidth
                        classes={{
                            root: classes.formRounded,
                        }}
                        size="small"
                    >
                        <InputLabel shrink id="updateTypeF-simple-select-label">
                            {t("miscellaneous.service")}
                        </InputLabel>
                        <Select
                            labelId="updateTypeF-simple-select-label"
                            IconComponent={KeyboardArrowDownIcon}
                            displayEmpty
                            value={updateTypeS}
                            onChange={handleChangeUpdateTypeS}
                            multiple
                            input={
                                <OutlinedInput
                                    notched
                                    label={t("miscellaneous.service")}
                                    sx={{
                                        fontSize: 16,
                                        fontWeight: 600,
                                    }}
                                />
                            }
                            renderValue={(selected) => {
                                if (selected.length === 0) {
                                    return <span>{t("miscellaneous.all")}</span>;
                                }
                                return selected.includes("all")
                                    ? t("miscellaneous.all")
                                    : selected
                                          .map((item) => {
                                              return t(
                                                  `invoicing.${Services.filter((item2) => item2.code === item)[0].name}`
                                              );
                                          })
                                          .join(", ");
                            }}
                            MenuProps={MenuProps}
                        >
                            {[AllItem].concat(Services).map((value) => (
                                <MenuItem value={value.code}>
                                    <Checkbox
                                        checked={updateTypeS.indexOf(value.code) > -1}
                                        icon={
                                            value.code === "all" &&
                                            !updateTypeS.includes("all") &&
                                            updateTypeS.length > 0 ? (
                                                <IndeterminateCheckBoxIcon style={{ fill: "#031851" }} />
                                            ) : (
                                                <CheckBoxOutlineBlankIcon />
                                            )
                                        }
                                    />
                                    <ListItemText
                                        primary={
                                            value.code === "all" ? t("miscellaneous.all") : t(`invoicing.${value.name}`)
                                        }
                                    />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </GridUI>
                <GridUI item xs={12} md={4} xl={1}>
                    <FormControl
                        fullWidth
                        classes={{
                            root: classes.formRounded,
                        }}
                        size="small"
                    >
                        <InputLabel shrink id="updateTypeP-simple-select-label">
                            {t("invoicing.project")}
                        </InputLabel>
                        <Select
                            labelId="updateTypeP-simple-select-label"
                            IconComponent={KeyboardArrowDownIcon}
                            displayEmpty
                            value={typeP}
                            onChange={handleChangeTypeP}
                            multiple
                            input={
                                <OutlinedInput
                                    notched
                                    label={t("invoicing.project")}
                                    sx={{
                                        fontSize: 16,
                                        fontWeight: 600,
                                    }}
                                />
                            }
                            renderValue={(selected) => {
                                if (selected.length === 0) {
                                    return <span>{t("miscellaneous.all")}</span>;
                                }
                                return selected.includes("all")
                                    ? t("miscellaneous.all")
                                    : selected
                                          .map((item) => {
                                              return t(
                                                  `invoicing.${Projects.filter((item2) => item2.code === item)[0].name}`
                                              );
                                          })
                                          .join(", ");
                            }}
                            MenuProps={MenuProps}
                        >
                            {[AllItem].concat(projects).map((value) => (
                                <MenuItem value={value.code}>
                                    <Checkbox
                                        checked={typeP.indexOf(value.code) > -1}
                                        icon={
                                            value.code === "all" && !typeP.includes("all") && typeP.length > 0 ? (
                                                <IndeterminateCheckBoxIcon style={{ fill: "#031851" }} />
                                            ) : (
                                                <CheckBoxOutlineBlankIcon />
                                            )
                                        }
                                    />
                                    <ListItemText
                                        primary={
                                            value.code === "all" ? t("miscellaneous.all") : t(`invoicing.${value.name}`)
                                        }
                                    />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </GridUI>
                <GridUI item xs={12} md={4} xl={3}>
                    <LocalizationProvider
                        dateAdapter={AdapterMoment}
                        adapterLocale={t("language.locale")}
                        localeText={{
                            clearButtonLabel: t("miscellaneous.clear"),
                        }}
                    >
                        <GridUI container>
                            <GridUI item xs={6}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: {
                                            actions: ["clear"],
                                        },
                                    }}
                                    value={sinceDateF}
                                    onChange={(newValue) => handleChangeSinceDate(newValue)}
                                    inputFormat={todos.dateFormat}
                                    renderInput={({ inputProps, ...params }) => (
                                        <TextField
                                            fullWidth
                                            {...params}
                                            inputProps={{
                                                ...inputProps,
                                                placeholder: t("invoicing.initDate"),
                                                sx: {
                                                    p: "10.45px 0px 10.45px 18px !important",
                                                },
                                            }}
                                            sx={{
                                                "& .MuiOutlinedInput-notchedOutline": {
                                                    mr: "-6px !important",
                                                    border: "2px solid #031851",
                                                    borderRadius: "40px 0px 0px 40px",
                                                },
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                    label={t("invoicing.range")}
                                />
                            </GridUI>
                            <GridUI item xs={6}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: {
                                            actions: ["clear"],
                                        },
                                    }}
                                    value={untilDateF}
                                    onChange={(newValue) => handleChangeUntilDate(newValue)}
                                    inputFormat={todos.dateFormat}
                                    renderInput={({ inputProps, ...params }) => (
                                        <TextField
                                            fullWidth
                                            {...params}
                                            inputProps={{
                                                ...inputProps,
                                                placeholder: t("invoicing.endDate"),
                                                sx: {
                                                    p: "10.45px 0px 10.45px 18px !important",
                                                },
                                            }}
                                            sx={{
                                                ml: 0.5,
                                                "& .MuiOutlinedInput-notchedOutline": {
                                                    border: "2px solid #031851",
                                                    borderRadius: "0px 40px 40px 0px",
                                                },
                                            }}
                                        />
                                    )}
                                />
                            </GridUI>
                        </GridUI>
                    </LocalizationProvider>
                </GridUI>
                <GridUI item xs={12}>
                    <Typography
                        variant="h4"
                        align="flex-start"
                        color="primary"
                        sx={{
                            marginBottom: 0,
                        }}
                    >
                        {t("miscellaneous.deletedItemsWarning")}
                    </Typography>
                </GridUI>
                <Hidden only={["md", "lg", "xl"]}>
                    <GridUI item>
                        <Button
                            disableElevation
                            variant="contained"
                            color="secondary"
                            onClick={props.handleOpenAlertPendings}
                            disabled={selectionDeleted.length === 0}
                            sx={{
                                pl: 3,
                                pr: 3,
                                borderRadius: 40,
                                "&:hover": {
                                    backgroundColor: "#2f3190",
                                    color: "#fff",
                                },
                                "&.Mui-disabled": {
                                    color: "#fff",
                                    borderColor: "#ffcdd2",
                                    background: "#ffcdd2",
                                },
                            }}
                        >
                            {t("miscellaneous.invoice2")}
                        </Button>
                    </GridUI>
                </Hidden>
            </GridUI>
            <GridUI container>
                <GridUI item xs={12} md={2} sx={{ mb: { sm: -5 }, zIndex: 1 }}>
                    <Button
                        variant="text"
                        color="primary"
                        disabled={anyFilter}
                        onClick={cleanFilters}
                        sx={{
                            "&:hover": {
                                color: "#2f3190",
                            },
                        }}
                    >
                        {t("miscellaneous.cleanFilters")}
                    </Button>
                </GridUI>
                <GridUI item xs={12}>
                    <Grid rows={deletedData} columns={columnDefs}>
                        <SelectionState selection={selectionDeleted} onSelectionChange={setSelection} />
                        <SearchState value={searchValue} onValueChange={handleSearch} />
                        <IntegratedFiltering />
                        <PagingState defaultCurrentPage={0} pageSize={10} />
                        <IntegratedPaging />
                        <IntegratedSelection />
                        <Table
                            tableComponent={TableComponent}
                            columnExtensions={tableColumnExtensions}
                            cellComponent={TableCellComponent}
                            messages={tableMessages}
                        />
                        <TableHeaderRow cellComponent={tableHeaderCell} contentComponent={tableContentComponent} />
                        <TableSelection
                            showSelectAll
                            headerCellComponent={tableHeaderSelCellBase}
                            cellComponent={tableSelectionCell}
                        />
                        <Toolbar rootComponent={toolRootComponent} />
                        <SearchPanel messages={searchMessages} inputComponent={inputComponent} />
                        <PagingPanel containerComponent={PagingPanelContainer} messages={pagingMessages} />
                    </Grid>
                </GridUI>
            </GridUI>
            <SnackBar type={alertType} show={show} message={texto} onClose={handleClose} />
        </StyledContainer>
    );
};

export default InDeleted;
