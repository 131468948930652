/* React imports */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";

/* MUI icons */
import SearchIcon from "@mui/icons-material/Search";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditIcon from "@mui/icons-material/Edit";
import ClearAllIcon from "@mui/icons-material/ClearAll";

/** MUI imports */
import { styled, alpha } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import {
    Box,
    Breadcrumbs,
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    Divider,
    FormGroup,
    FormControlLabel,
    Grid,
    IconButton,
    InputBase,
    List,
    ListItem,
    ListItemText,
    Skeleton,
    Stack,
    TextField,
    Typography,
} from "@mui/material";

/* Components imports */
import ClientsList from "../Clients/ClientsList";

const PREFIX = "FiltersServ";

const classes = {
    paper: `${PREFIX}-paper`,
};

const StyledContainer = styled(Box)({
    [`& .${classes.paper}`]: {
        padding: "10px 10px 0px",
        border: "2px solid #ff5968",
        borderRadius: 40,
        boxShadow: "none",
    },
});

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "rgba(19, 31, 62, 0.80)",
        fontSize: 12,
        maxWidth: 320,
        borderRadius: 6,
        fontWeight: "normal",
    },
});

const Search = styled("div")(({ theme }) => ({
    width: "100%",
    position: "relative",
    marginLeft: 0,
    marginBottom: 10,
    borderRadius: 25,
    backgroundColor: alpha(theme.palette.primary.main, 0.15),
    [theme.breakpoints.up("sm")]: {
        width: "auto",
    },
    "&:hover": {
        backgroundColor: alpha(theme.palette.primary.light, 0.25),
    },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}));

const StyledInputBaseFilter = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    width: "100%",
    "& .MuiInputBase-input": {
        padding: theme.spacing(1),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create("width"),
        width: "100%",
    },
}));

const styleFilter = {
    width: "100%",
    maxHeight: 700,
    bgcolor: "background.paper",
    overflowY: "auto",
};

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)({
    boxShadow: "none",
    "&:before": {
        display: "none",
    },
});

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: "#031851" }} />} {...props} />
))({
    backgroundColor: "#e9eae8",
    "& .MuiAccordionSummary-content": {
        margin: "12px 0",
    },
});

const AccordionSummarySkeleton = styled((props) => (
    <MuiAccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: "#cccccc" }} />} {...props} />
))({
    backgroundColor: "#e9eae8",
    "& .MuiAccordionSummary-content": {
        margin: "12px 0",
    },
});

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    backgroundColor: "#e9eae8",
    "& .MuiCard-root": {
        boxShadow: "none",
    },
}));

var cont = 0;
var contTaxAdd = 0;

const FiltersServ = () => {
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    /* React redux */
    const dispatch = useDispatch();

    /* React Router */
    const navigate = useNavigate();

    /** Component states */
    const [clientSearch, setClientSearch] = useState([]);
    const [clientsF, setClientsF] = useState([]);
    const [totalClients, setTotalClients] = useState(0);
    const [searchValue, setSearchValue] = useState("");
    const [selectedIndex, setSelectedIndex] = useState("");
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [loadingCard, setLoadingCard] = useState(false);
    const [selectedClient, setSelectedClient] = useState({});
    const [showCard, setShowCard] = useState(false);
    const [searchValueFranq, setSearchValueFranq] = useState("");
    const [searchValueGroup, setSearchValueGroup] = useState("");
    const [searchValueServ, setSearchValueServ] = useState("");
    const [searchValueProject, setSearchValueProject] = useState("");
    const [franqData, setFranqData] = useState([]);
    const [franqSearch, setFranqSearch] = useState([]);
    const [groupData, setGroupData] = useState([]);
    const [groupSearch, setGroupSearch] = useState([]);
    const [groupName, setgroupName] = useState("");
    const [franqName, setfranqName] = useState("");
    const [status, setStatus] = useState([]);
    const [franchises, setFranchises] = useState([]);
    const [groups, setGroups] = useState([]);
    const [clientT, setClientT] = useState([]);
    const [clientStat, setClientStat] = useState([]);
    const [market, setMarket] = useState([]);
    const [software, setSoftware] = useState([]);
    const [servicesF, setServicesF] = useState([]);
    const [projectsF, setProjectsF] = useState([]);
    const [additionalsF, setAdd] = useState([]);
    const [analyst, setAnalyst] = useState([]);
    const [backupAnalyst, setBackupAnalyst] = useState([]);
    const [supervisor, setSuper] = useState([]);
    const [manager, setMana] = useState([]);
    const [advisor, setAdvi] = useState([]);
    const [statusCheck, setstatusCheck] = useState({
        active: false,
        inactive: false,
        noService: false,
    });
    const [franchiseCheck, setFranchiseCheck] = useState([]);
    const [groupCheck, setgroupCheck] = useState([]);
    const [typeC, settypeC] = useState({
        firmCheck: false,
        smbCheck: false,
    });
    const [clasi, setClasi] = useState({
        stdCheck: false,
        vipCheck: false,
    });
    const [marketC, setMarketC] = useState({
        veCheck: false,
        usCheck: false,
        glCheck: false,
        laCheck: false,
    });
    const [accAdd, setaccAdd] = useState({
        arCheck: false,
        apCheck: false,
        classCheck: false,
        multiCheck: false,
        prCheck: false,
        ordCheck: false,
        espCheck: false,
        muniCheck: false,
        salCheck: false,
        checksCheck: false,
        othersCheck: false,
    });
    const [bookAdd, setbookAdd] = useState({
        arCheck: false,
        apCheck: false,
        accruCheck: false,
        otherCheck: false,
        checksCheck: false,
        multiCheck: false,
        personalizedCheck: false,
    });
    const [cfoAdd, setcfoAdd] = useState({
        gobCheck: false,
        preCheck: false,
        repCheck: false,
        otherCheck: false,
    });
    const [contAdd, setcontAdd] = useState({
        ejeCheck: false,
        conCheck: false,
        repCheck: false,
        otherCheck: false,
    });
    const [taxAdd, settaxAdd] = useState({
        interCheck: false,
        compCheck: false,
        plaCheck: false,
        // Venezuela
        decISLRCheck: false,
        otherCheck: false,
    });
    const [superv, setSuperv] = useState({
        mcruzCheck: false,
        deyaCheck: false,
        mariaCheck: false,
        porACheck: false,
        naCheck: false,
    });
    const [gerente, setGerente] = useState({
        deyaCheck: false,
        gerCheck: false,
        mcruzCheck: false,
        porACheck: false,
        naCheck: false,
    });
    const [advi, setAdvisor] = useState({
        gerCheck: false,
        porACheck: false,
        naCheck: false,
    });
    const [analista, setAnalista] = useState({
        cgilCheck: false,
        iraCheck: false,
        mbarCheck: false,
        yarCheck: false,
        suppCheck: false,
        porACheck: false,
        naCheck: false,
    });
    const [analistaBackup, setAnalistaBackup] = useState({
        cgilCheck: false,
        iraCheck: false,
        mbarCheck: false,
        yarCheck: false,
        suppCheck: false,
        porACheck: false,
        naCheck: false,
    });
    const [provCheck, setProvCheck] = useState([]);
    const [servCheck, setServCheck] = useState([]);
    const [projectsCheck, setProjectsCheck] = useState([]);
    const [proveedores, setProveedores] = useState([]);
    const [services, setServices] = useState([]);
    const [projects, setProjects] = useState([]);
    const [serviceSearch, setServiceSearch] = useState([]);
    const [projectsSearch, setProjectsSearch] = useState([]);
    const [clientServ, setClientServ] = useState("");
    const [additionals, setAdditionals] = useState("");
    const [showAdd, setShowAdd] = useState({
        accountant: true,
        book: true,
        cfo: true,
        controller: true,
        tax: true,
        us: true,
        ve: true,
    });
    const [filtersXLSX, setFiltersXLSX] = useState([]);

    /** Global variables */
    const breadcrumbs = [
        <Typography
            key="1"
            variant="h1"
            color="primary"
            sx={{
                fontSize: 24,
                fontWeight: 700,
            }}
        >
            {t("principal.analytics")}
        </Typography>,
        <Typography
            key="2"
            variant="h1"
            color="secondary"
            sx={{
                fontSize: 24,
                fontWeight: 700,
            }}
        >
            {t("miscellaneous.filters")}
        </Typography>,
    ];

    /** Component functions */
    const filterItems = (query) => {
        return clientsF.filter((el) => el.company_legal_name.toLowerCase().indexOf(query.toLowerCase()) > -1);
    };

    const handleSearch = (event) => {
        let filterArr = filterItems(event.target.value.trim());
        setSearchValue(event.target.value);
        setClientSearch(filterArr);
        setTotalClients(filterArr.length);
    };

    const filterItemsFranq = (query) => {
        return franqData.filter((el) => el.franchise_name.toLowerCase().indexOf(query.toLowerCase()) > -1);
    };

    const handleSearchFranq = (event) => {
        let filterArr = filterItemsFranq(event.target.value);
        setSearchValueFranq(event.target.value);
        setFranqSearch(filterArr);
    };

    const filterItemsGroup = (query) => {
        return groupData.filter((el) => el.group_name.toLowerCase().indexOf(query.toLowerCase()) > -1);
    };

    const handleSearchGroup = (event) => {
        let filterArr = filterItemsGroup(event.target.value);
        setSearchValueGroup(event.target.value);
        setGroupSearch(filterArr);
    };

    const filterItemsServ = (query) => {
        return services.filter((el) => el.service_name.toLowerCase().indexOf(query.toLowerCase()) > -1);
    };

    const handleSearchServ = (event) => {
        let filterArr = filterItemsServ(event.target.value);
        setSearchValueServ(event.target.value);
        setServiceSearch(filterArr);
    };

    const handleSearchProject = (event) => {
        event.preventDefault();
        setSearchValueProject(event.target.value);
        let filterProjects = projects.filter((project) => {
            return project.project_name.toLowerCase().includes(event.target.value.toLowerCase());
        });
        setProjectsSearch(filterProjects);
    };

    const handleChangeClient = async (value, index) => {
        setLoadingCard(true);

        let groupExist = false;
        let franqExist = false;

        setSelectedIndex(index);
        setSelectedClient(value);
        setShowCard(true);

        groupData.forEach((val) => {
            if (value.practice_id === val.id) {
                setgroupName(val.group_name);
                groupExist = true;
            }
        });

        franqData.forEach((val) => {
            if (value.franchise_id === val.id) {
                setfranqName(val.franchise_name);
                franqExist = true;
            }
        });

        if (!groupExist) {
            setgroupName("N/A");
        }
        if (!franqExist) {
            setfranqName("N/A");
        }

        await fetch(`/getServicesCompany?id_organisation=${value._id}`, {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then((res) => res.json())
            .then((data) => {
                let auxArr = "";
                let auxAdd = "";
                let cont = 0;
                let contA = 0;

                data.forEach((val) => {
                    if (
                        val.since_date !== undefined &&
                        (val.end_date === undefined ||
                            moment(val.end_date, "YYYY-MM-DD").isAfter(moment().format("YYYY-MM-DD")))
                    ) {
                        if (cont === 0) {
                            auxArr = auxArr.concat(val.service_name);
                            cont++;
                        } else {
                            auxArr = auxArr.concat(",\n", val.service_name);
                        }
                        if (val.periods) {
                            val.periods.forEach((value) => {
                                if (value.additionals) {
                                    value.additionals.forEach((additional) => {
                                        if (!auxAdd.includes(additional.additional_name)) {
                                            if (contA === 0) {
                                                auxAdd = auxAdd.concat(additional.additional_name);
                                            } else {
                                                auxAdd = auxAdd.concat(",\n", additional.additional_name);
                                            }
                                            contA++;
                                        }
                                    });
                                }
                            });
                        }
                        cont++;
                    }
                });
                setClientServ(auxArr === "" ? "N/A" : auxArr);
                setAdditionals(auxAdd === "" ? "N/A" : auxAdd);
            });

        setLoadingCard(false);
    };

    const handleChangeFranq = (event, valueID, index) => {
        setSelectedIndex("");
        setShowCard(false);

        let items = [...franchiseCheck];

        items[index] = event.target.checked;

        setFranchiseCheck(items);

        if (event.target.checked) {
            setFranchises((prevArray) => [...prevArray, valueID]);
        } else {
            setFranchises(franchises.filter((item) => item !== valueID));
        }
    };

    const handleChangeGroup = (event, valueID, index) => {
        setSelectedIndex("");
        setShowCard(false);

        let items = [...groupCheck];

        items[index] = event.target.checked;

        setgroupCheck(items);

        if (event.target.checked) {
            setGroups((prevArray) => [...prevArray, valueID]);
        } else {
            setGroups(groups.filter((item) => item !== valueID));
        }
    };

    const handleChangeTypeC = (event, value) => {
        setSelectedIndex("");
        setShowCard(false);

        if (value === 1) {
            settypeC({
                ...typeC,
                firmCheck: event.target.checked,
            });
        } else {
            settypeC({
                ...typeC,
                smbCheck: event.target.checked,
            });
        }
        if (event.target.checked) {
            setClientT((prevArray) => [...prevArray, value]);
        } else {
            setClientT(clientT.filter((item) => item !== value));
        }
    };

    const handleChangeClasi = (event, value) => {
        setSelectedIndex("");
        setShowCard(false);

        if (value === 1) {
            setClasi({
                ...clasi,
                stdCheck: event.target.checked,
            });
        } else {
            setClasi({
                ...clasi,
                vipCheck: event.target.checked,
            });
        }
        if (event.target.checked) {
            setClientStat((prevArray) => [...prevArray, value]);
        } else {
            setClientStat(clientStat.filter((item) => item !== value));
        }
    };

    const handleChangeMarket = (event, value) => {
        setSelectedIndex("");
        setShowCard(false);

        if (contTaxAdd === 0) {
            switch (value) {
                case 1:
                    setMarketC({
                        ...marketC,
                        veCheck: event.target.checked,
                    });
                    setShowAdd({
                        ...showAdd,
                        ve: event.target.checked,
                        us: !showAdd.us,
                    });
                    break;
                case 2:
                    setMarketC({
                        ...marketC,
                        usCheck: event.target.checked,
                    });
                    setShowAdd({
                        ...showAdd,
                        us: event.target.checked,
                        ve: !showAdd.ve,
                    });
                    break;
                case 3:
                    setMarketC({
                        ...marketC,
                        glCheck: event.target.checked,
                    });
                    setShowAdd({
                        ...showAdd,
                        us: false,
                        ve: false,
                    });
                    break;
                default:
                    setMarketC({
                        ...marketC,
                        laCheck: event.target.checked,
                    });
                    setShowAdd({
                        ...showAdd,
                        us: false,
                        ve: false,
                    });
                    break;
            }
            contTaxAdd++;
        } else {
            if (event.target.checked) {
                contTaxAdd++;
            } else {
                contTaxAdd--;
            }
            if (contTaxAdd === 0) {
                setShowAdd({
                    ...showAdd,
                    ve: true,
                    us: true,
                });
            }
            switch (value) {
                case 1:
                    setMarketC({
                        ...marketC,
                        veCheck: event.target.checked,
                    });
                    setShowAdd({
                        ...showAdd,
                        ve: event.target.checked,
                    });
                    break;
                case 2:
                    setMarketC({
                        ...marketC,
                        usCheck: event.target.checked,
                    });
                    setShowAdd({
                        ...showAdd,
                        us: event.target.checked,
                    });
                    break;
                case 3:
                    setMarketC({
                        ...marketC,
                        glCheck: event.target.checked,
                    });
                    break;
                default:
                    setMarketC({
                        ...marketC,
                        laCheck: event.target.checked,
                    });
                    break;
            }
        }
        if (event.target.checked) {
            setMarket((prevArray) => [...prevArray, value]);
        } else {
            setMarket(market.filter((item) => item !== value));
        }
    };

    const handleChangeProvee = (event, value, index) => {
        setSelectedIndex("");
        setShowCard(false);

        let items = [...provCheck];

        items[index] = event.target.checked;

        setProvCheck(items);

        if (event.target.checked) {
            setSoftware((prevArray) => [...prevArray, value]);
        } else {
            setSoftware(software.filter((item) => item !== value));
        }
    };

    const handleChangeProject = (event, projectID, index) => {
        setSelectedIndex("");
        setShowCard(false);

        let items = [...projectsCheck];

        items[index] = event.target.checked;

        setProjectsCheck(items);

        if (event.target.checked) {
            setProjectsF((prev) => [...prev, projectID]);
        } else {
            setProjectsF(projectsF.filter((project) => project !== projectID));
        }
    };

    const handleChangeService = (event, valueID, servName, index) => {
        setSelectedIndex("");
        setShowCard(false);

        let items = [...servCheck];

        items[index] = event.target.checked;

        setServCheck(items);

        if (event.target.checked) {
            setServicesF((prevArray) => [...prevArray, valueID]);
        } else {
            setServicesF(servicesF.filter((item) => item !== valueID));
        }
        if (cont === 0) {
            switch (servName) {
                case "Accountant":
                    setShowAdd({
                        ...showAdd,
                        accountant: event.target.checked,
                        book: !showAdd.book,
                        cfo: !showAdd.cfo,
                        controller: !showAdd.controller,
                        tax: !showAdd.tax,
                    });
                    cont++;
                    break;
                case "Bookkeeper":
                    setShowAdd({
                        ...showAdd,
                        accountant: !showAdd.accountant,
                        book: event.target.checked,
                        cfo: !showAdd.cfo,
                        controller: !showAdd.controller,
                        tax: !showAdd.tax,
                    });
                    cont++;
                    break;
                case "CFO":
                    setShowAdd({
                        ...showAdd,
                        accountant: !showAdd.accountant,
                        book: !showAdd.book,
                        cfo: event.target.checked,
                        controller: !showAdd.controller,
                        tax: !showAdd.tax,
                    });
                    cont++;
                    break;
                case "Controller":
                    setShowAdd({
                        ...showAdd,
                        accountant: !showAdd.accountant,
                        book: !showAdd.book,
                        cfo: !showAdd.cfo,
                        controller: event.target.checked,
                        tax: !showAdd.tax,
                    });
                    cont++;
                    break;
                case "Tax Expert":
                    setShowAdd({
                        ...showAdd,
                        accountant: !showAdd.accountant,
                        book: !showAdd.book,
                        cfo: !showAdd.cfo,
                        controller: !showAdd.controller,
                        tax: event.target.checked,
                    });
                    cont++;
                    break;
                default:
                    setShowAdd({
                        ...showAdd,
                        accountant: !showAdd.accountant,
                        book: !showAdd.book,
                        cfo: !showAdd.cfo,
                        controller: !showAdd.controller,
                        tax: !showAdd.tax,
                    });
                    cont++;
                    break;
            }
        } else {
            if (event.target.checked) {
                cont++;
            } else {
                cont--;
            }
            if (cont === 0) {
                setShowAdd({
                    ...showAdd,
                    accountant: true,
                    book: true,
                    cfo: true,
                    controller: true,
                    tax: true,
                });
            } else {
                switch (servName) {
                    case "Accountant":
                        setShowAdd({
                            ...showAdd,
                            accountant: event.target.checked,
                        });
                        break;
                    case "Bookkeeper":
                        setShowAdd({
                            ...showAdd,
                            book: event.target.checked,
                        });
                        break;
                    case "CFO":
                        setShowAdd({
                            ...showAdd,
                            cfo: event.target.checked,
                        });
                        break;
                    case "Controller":
                        setShowAdd({
                            ...showAdd,
                            controller: event.target.checked,
                        });
                        break;
                    case "Tax Expert":
                        setShowAdd({
                            ...showAdd,
                            tax: event.target.checked,
                        });
                        break;
                    default:
                        break;
                }
            }
        }
    };

    const handleChangeAdd = (event, value) => {
        setSelectedIndex("");
        setShowCard(false);

        switch (value) {
            case "A/R reconciliation":
                setaccAdd({
                    ...accAdd,
                    arCheck: event.target.checked,
                });
                break;
            case "A/P reconciliation":
                setaccAdd({
                    ...accAdd,
                    apCheck: event.target.checked,
                });
                break;
            case "Classes/Categories accounting":
                setaccAdd({
                    ...accAdd,
                    classCheck: event.target.checked,
                });
                break;
            case "Multi-entity accounting":
                setaccAdd({
                    ...accAdd,
                    multiCheck: event.target.checked,
                });
                break;
            case "Personalized reporting":
                setaccAdd({
                    ...accAdd,
                    prCheck: event.target.checked,
                });
                break;
            case "Sales taxes":
                setaccAdd({
                    ...accAdd,
                    salCheck: event.target.checked,
                });
                break;
            case "Ordinario":
                setaccAdd({
                    ...accAdd,
                    ordCheck: event.target.checked,
                });
                break;
            case "Especial":
                setaccAdd({
                    ...accAdd,
                    espCheck: event.target.checked,
                });
                break;
            case "Municipal":
                setaccAdd({
                    ...accAdd,
                    muniCheck: event.target.checked,
                });
                break;
            case "ChecksA":
                setaccAdd({
                    ...accAdd,
                    checksCheck: event.target.checked,
                });
                break;
            case "Other":
                setaccAdd({
                    ...accAdd,
                    othersCheck: event.target.checked,
                });
                break;
            case "A/R":
                setbookAdd({
                    ...bookAdd,
                    arCheck: event.target.checked,
                });
                break;
            case "A/P":
                setbookAdd({
                    ...bookAdd,
                    apCheck: event.target.checked,
                });
                break;
            case "Accrual":
                setbookAdd({
                    ...bookAdd,
                    accruCheck: event.target.checked,
                });
                break;
            case "ChecksB":
                setbookAdd({
                    ...bookAdd,
                    checksCheck: event.target.checked,
                });
                break;
            case "Multi-entity":
                setbookAdd({
                    ...bookAdd,
                    multiCheck: event.target.checked,
                });
                break;
            case "Personalized reporting package":
                setbookAdd({
                    ...bookAdd,
                    personalizedCheck: event.target.checked,
                });
                break;
            case "Others":
                setbookAdd({
                    ...bookAdd,
                    otherCheck: event.target.checked,
                });
                break;
            case "Gobierno corporativo":
                setcfoAdd({
                    ...cfoAdd,
                    gobCheck: event.target.checked,
                });
                break;
            case "Presentaciones financieras":
                setcfoAdd({
                    ...cfoAdd,
                    preCheck: event.target.checked,
                });
                break;
            case "Reportes para inversionistas":
                setcfoAdd({
                    ...cfoAdd,
                    repCheck: event.target.checked,
                });
                break;
            case "Other CFO":
                setcfoAdd({
                    ...cfoAdd,
                    otherCheck: event.target.checked,
                });
                break;
            case "Ejecución de planificación fiscal":
                setcontAdd({
                    ...contAdd,
                    ejeCheck: event.target.checked,
                });
                break;
            case "Planificación de administración y contabilidad":
                setcontAdd({
                    ...contAdd,
                    conCheck: event.target.checked,
                });
                break;
            case "Reportes para auditores externos":
                setcontAdd({
                    ...contAdd,
                    repCheck: event.target.checked,
                });
                break;
            case "Other Controller":
                setcontAdd({
                    ...contAdd,
                    otherCheck: event.target.checked,
                });
                break;
            case "International":
                settaxAdd({
                    ...taxAdd,
                    interCheck: event.target.checked,
                });
                break;
            case "Intercompany":
                settaxAdd({
                    ...taxAdd,
                    compCheck: event.target.checked,
                });
                break;
            // Venezuela
            case "Declaración estimada de ISLR":
                settaxAdd({
                    ...taxAdd,
                    decISLRCheck: event.target.checked,
                });
                break;
            case "Other Tax":
                settaxAdd({
                    ...taxAdd,
                    otherCheck: event.target.checked,
                });
                break;
            default:
                settaxAdd({
                    ...taxAdd,
                    plaCheck: event.target.checked,
                });
                break;
        }

        if (event.target.checked) {
            setAdd((prevArray) => [...prevArray, value]);
        } else {
            setAdd(additionalsF.filter((item) => item !== value));
        }
    };

    const handleChangeStatus = (event, value) => {
        setSelectedIndex("");
        setShowCard(false);

        switch (value) {
            case "Clientes activos":
                setstatusCheck({
                    ...statusCheck,
                    active: event.target.checked,
                });
                break;
            case "Clientes inactivos":
                setstatusCheck({
                    ...statusCheck,
                    inactive: event.target.checked,
                });
                break;
            default:
                setstatusCheck({
                    ...statusCheck,
                    noService: event.target.checked,
                });
                break;
        }

        if (event.target.checked) {
            setStatus((prevArray) => [...prevArray, value]);
        } else {
            setStatus(status.filter((item) => item !== value));
        }
    };

    const handleChangeAnalyst = (event, value) => {
        setSelectedIndex("");
        setShowCard(false);

        switch (value) {
            case "Christian Gil":
                setAnalista({
                    ...analista,
                    cgilCheck: event.target.checked,
                });
                break;
            case "Iraixy Guevara":
                setAnalista({
                    ...analista,
                    iraCheck: event.target.checked,
                });
                break;
            case "María Fernanda Barrientos":
                setAnalista({
                    ...analista,
                    mbarCheck: event.target.checked,
                });
                break;
            case "Yaritza Rosas":
                setAnalista({
                    ...analista,
                    yarCheck: event.target.checked,
                });
                break;
            case "Soporte":
                setAnalista({
                    ...analista,
                    suppCheck: event.target.checked,
                });
                break;
            case "Por asignar":
                setAnalista({
                    ...analista,
                    porACheck: event.target.checked,
                });
                break;
            default:
                setAnalista({
                    ...analista,
                    naCheck: event.target.checked,
                });
                break;
        }

        if (event.target.checked) {
            setAnalyst((prevArray) => [...prevArray, value]);
        } else {
            setAnalyst(analyst.filter((item) => item !== value));
        }
    };

    const handleChangeBackupAnalyst = (event, value) => {
        setSelectedIndex("");
        setShowCard(false);

        let name = "";

        switch (value) {
            case "Christian Gil":
                name = "cgilCheck";
                break;
            case "Iraixy Guevara":
                name = "iraCheck";
                break;
            case "María Fernanda Barrientos":
                name = "mbarCheck";
                break;
            case "Yaritza Rosas":
                name = "yarCheck";
                break;
            case "Soporte":
                name = "suppCheck";
                break;
            case "Por asignar":
                name = "porACheck";
                break;
            default:
                name = "naCheck";
                break;
        }

        setAnalistaBackup({
            ...analistaBackup,
            [name]: event.target.checked,
        });

        if (event.target.checked) {
            setBackupAnalyst((prevArray) => [...prevArray, value]);
        } else {
            setBackupAnalyst(backupAnalyst.filter((item) => item !== value));
        }
    };

    const handleChangeSuper = (event, value) => {
        setSelectedIndex("");
        setShowCard(false);

        let name = "";

        switch (value) {
            case "María Fernanda Cruz":
                name = "mcruzCheck";
                break;
            case "Deyanira Goncalves":
                name = "deyaCheck";
                break;
            case "María Fernanda Barrientos":
                name = "mariaCheck";
                break;
            case "Por asignar":
                name = "porACheck";
                break;
            default:
                name = "naCheck";
                break;
        }

        setSuperv({
            ...superv,
            [name]: event.target.checked,
        });

        if (event.target.checked) {
            setSuper((prevArray) => [...prevArray, value]);
        } else {
            setSuper(supervisor.filter((item) => item !== value));
        }
    };

    const handleChangeMana = (event, value) => {
        setSelectedIndex("");
        setShowCard(false);

        let name = "";

        switch (value) {
            case "Deyanira Goncalves":
                name = "deyaCheck";
                break;
            case "Geraldine Calles":
                name = "gerCheck";
                break;
            case "María Fernanda Cruz":
                name = "mcruzCheck";
                break;
            case "Por asignar":
                name = "porACheck";
                break;
            default:
                name = "naCheck";
                break;
        }

        setGerente({
            ...gerente,
            [name]: event.target.checked,
        });

        if (event.target.checked) {
            setMana((prevArray) => [...prevArray, value]);
        } else {
            setMana(manager.filter((item) => item !== value));
        }
    };

    const handleChangeAdvi = (event, value) => {
        setSelectedIndex("");
        setShowCard(false);

        let name = "";

        switch (value) {
            case "Geraldine Calles":
                name = "gerCheck";
                break;
            case "Por asignar":
                name = "porACheck";
                break;
            default:
                name = "naCheck";
                break;
        }

        setAdvisor({
            ...advi,
            [name]: event.target.checked,
        });

        if (event.target.checked) {
            setAdvi((prevArray) => [...prevArray, value]);
        } else {
            setAdvi(advisor.filter((item) => item !== value));
        }
    };

    const clearFilters = () => {
        let checksF = [];
        let checksG = [];
        let checksP = [];
        let checkS = [];
        let checkPr = [];
        setSelectedIndex("");
        setShowCard(false);
        setStatus([]);
        setstatusCheck({
            ...statusCheck,
            active: false,
            inactive: false,
            noService: false,
        });
        franchiseCheck.forEach((val, index) => {
            checksF[index] = false;
        });
        groupCheck.forEach((val, index) => {
            checksG[index] = false;
        });
        settypeC({
            ...typeC,
            firmCheck: false,
            smbCheck: false,
        });
        setClasi({
            ...clasi,
            stdCheck: false,
            vipCheck: false,
        });
        setMarketC({
            ...marketC,
            veCheck: false,
            usCheck: false,
            glCheck: false,
            laCheck: false,
        });
        provCheck.forEach((val, index) => {
            checksP[index] = false;
        });
        servCheck.forEach((val, index) => {
            checkS[index] = false;
        });
        projectsSearch.forEach((val, index) => {
            checkPr[index] = false;
        });
        setaccAdd({
            arCheck: false,
            apCheck: false,
            classCheck: false,
            multiCheck: false,
            prCheck: false,
            ordCheck: false,
            espCheck: false,
            muniCheck: false,
            salCheck: false,
            checksCheck: false,
            othersCheck: false,
        });
        setbookAdd({
            arCheck: false,
            apCheck: false,
            accruCheck: false,
            checksCheck: false,
            otherCheck: false,
            multiCheck: false,
            personalizedCheck: false,
        });
        setcfoAdd({
            gobCheck: false,
            preCheck: false,
            repCheck: false,
            otherCheck: false,
        });
        setcontAdd({
            ejeCheck: false,
            conCheck: false,
            repCheck: false,
            otherCheck: false,
        });
        settaxAdd({
            interCheck: false,
            compCheck: false,
            plaCheck: false,
            // Venezuela
            decISLRCheck: false,
            otherCheck: false,
        });
        setAnalista({
            cgilCheck: false,
            iraCheck: false,
            mbarCheck: false,
            yarCheck: false,
            suppCheck: false,
            porACheck: false,
            naCheck: false,
        });
        setAnalistaBackup({
            cgilCheck: false,
            iraCheck: false,
            mbarCheck: false,
            yarCheck: false,
            suppCheck: false,
            porACheck: false,
            naCheck: false,
        });
        setSuperv({
            mcruzCheck: false,
            deyaCheck: false,
            mariaCheck: false,
            porACheck: false,
            naCheck: false,
        });
        setGerente({
            deyaCheck: false,
            gerCheck: false,
            mcruzCheck: false,
            porACheck: false,
            naCheck: false,
        });
        setAdvisor({
            gerCheck: false,
            porACheck: false,
            naCheck: false,
        });
        setFranchiseCheck(checksF);
        setgroupCheck(checksG);
        setProvCheck(checksP);
        setServCheck(checkS);
        setProjectsCheck(checkPr);
        setFranchises([]);
        setGroups([]);
        setClientT([]);
        setClientStat([]);
        setMarket([]);
        setSoftware([]);
        setServicesF([]);
        setProjectsF([]);
        setAdd([]);
        setAnalyst([]);
        setBackupAnalyst([]);
        setSuper([]);
        setMana([]);
        setAdvi([]);
    };

    const goToClient = () => {
        dispatch({
            type: "addSelected",
            item: "ddlManage",
        });
        navigate(`/clientManagement?clientID=${selectedClient._id}&groupName=${groupName}&franqName=${franqName}`);
    };

    useEffect(() => {
        (async () => {
            // Showing loading
            setLoading2(true);

            await fetch(`/getClientFranchiseFilter`, {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
                .then((res) => res.json())
                .then((data) => {
                    let checks = [];
                    data.forEach((val, index) => {
                        checks[index] = false;
                    });
                    setFranchiseCheck(checks);
                    setFranqData(data);
                    setFranqSearch(data);
                });

            await fetch(`/getClientGroupsFilter`, {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
                .then((res) => res.json())
                .then((data) => {
                    let checks = [];
                    data.forEach((val, index) => {
                        checks[index] = false;
                    });
                    setgroupCheck(checks);
                    setGroupData(data);
                    setGroupSearch(data);
                });

            await fetch(`/getProviders`, {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
                .then((res) => res.json())
                .then((data) => {
                    let checks = [];
                    data.providers.forEach((val, index) => {
                        checks[index] = false;
                    });
                    setProvCheck(checks);
                    setProveedores(data.providers);
                });

            await fetch(`/getServices`, {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
                .then((res) => res.json())
                .then((data) => {
                    let checks = [];
                    data.forEach((val, index) => {
                        checks[index] = false;
                    });
                    setServCheck(checks);
                    setServices(data);
                    setServiceSearch(data);
                });

            await fetch(`/getProjects`, {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
                .then((res) => res.json())
                .then((data) => {
                    let checks = [];
                    data.projects.forEach((val, index) => {
                        checks[index] = false;
                    });
                    setProjectsCheck(checks);
                    setProjectsSearch(data.projects);
                    setProjects(data.projects);
                });

            setLoading2(false);
        })();
    }, []);

    useEffect(() => {
        (async () => {
            // Showing loading
            setLoading(true);

            let url =
                `/getServicesDashboard?status=${status.join(",")}&franchises=${franchises.join(
                    ","
                )}&groups=${groups.join(",")}&client_type=${clientT.join(",")}` +
                `&client_status=${clientStat.join(",")}&client_market=${market.join(
                    ","
                )}&software_provider=${software.join(",")}&services=${servicesF.join(",")}` +
                `&additionals=${additionalsF.join(",")}&analyst=${analyst.join(
                    ","
                )}&backup_analyst=${backupAnalyst.join(",")}` +
                `&supervisor=${supervisor.join(",")}&manager=${manager.join(",")}&advisor=${advisor.join(
                    ","
                )}&projects=${projectsF.join(",")}`;

            const newObj = {
                status: status.join(", "),
                franchises: franchises,
                groups: groups,
                clientT: clientT,
                clientStat: clientStat,
                market: market,
                software: software.join(", "),
                servicesF: servicesF,
                additionalsF: additionalsF,
                analyst: analyst,
                backupAnalyst: backupAnalyst,
                supervisor: supervisor,
                manager: manager,
                advisor: advisor,
                projectsF: projectsF,
            };

            setFiltersXLSX(newObj);

            await fetch(url, {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
                .then((res) => res.json())
                .then((data) => {
                    setClientSearch(data);
                    setClientsF(data);
                    setTotalClients(data.length);
                });

            setLoading(false);
        })();
    }, [
        status,
        franchises,
        groups,
        clientT,
        clientStat,
        software,
        servicesF,
        additionalsF,
        analyst,
        backupAnalyst,
        supervisor,
        manager,
        advisor,
        market,
        projectsF,
    ]);

    useEffect(() => {
        const filterProjects = (servicesId) => {
            const filteredProjects = projects.map((project) => {
                const show = servicesId.length === 0 || servicesId.includes(project.service_id);
                return { ...project, show };
            });
            setProjectsSearch(filteredProjects);
        };
        filterProjects(servicesF);
    }, [servicesF, projects]);

    return (
        <StyledContainer component="main" sx={{ flexGrow: 1, p: 4, pt: 10, width: "100%" }}>
            <Stack sx={{ pb: 4 }}>
                <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small" sx={{ color: "#031851" }} />}
                    aria-label="breadcrumb"
                >
                    {breadcrumbs}
                </Breadcrumbs>
            </Stack>
            <Grid container spacing={4}>
                <Grid item xs={12} md={12} lg={3}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", pb: 1 }}>
                        <Typography variant="h2">{t("miscellaneous.filters")}</Typography>
                        <LightTooltip title={t("recurrent.clear")}>
                            <IconButton aria-label="clear" color="primary" onClick={clearFilters}>
                                <ClearAllIcon />
                            </IconButton>
                        </LightTooltip>
                    </Box>
                    {loading2 ? (
                        <Box sx={styleFilter}>
                            {Array(5).fill(
                                <>
                                    <Accordion sx={{ borderBottom: "3px solid #cccccc" }}>
                                        <AccordionSummarySkeleton
                                            aria-controls="accordion-content"
                                            id="accordion-header"
                                        >
                                            <Skeleton variant="text" animation="wave" height={15} width="25%" />
                                        </AccordionSummarySkeleton>
                                    </Accordion>
                                    <Accordion sx={{ borderBottom: "3px solid #cccccc" }}>
                                        <AccordionSummarySkeleton
                                            aria-controls="accordion-content"
                                            id="accordion-header"
                                        >
                                            <Skeleton variant="text" animation="wave" height={15} width="15%" />
                                        </AccordionSummarySkeleton>
                                    </Accordion>
                                </>
                            )}
                        </Box>
                    ) : (
                        <Box sx={styleFilter}>
                            <Accordion
                                sx={{
                                    borderBottom: Object.values(statusCheck).some((element) => element === true)
                                        ? "3px solid #ff5968"
                                        : "3px solid rgba(3, 24, 81, 0.2)",
                                }}
                            >
                                <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                                    <Typography variant="h5">{t("miscellaneous.status")}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={statusCheck.active}
                                                    onChange={(event) => handleChangeStatus(event, "Clientes activos")}
                                                />
                                            }
                                            label={t("filtersServ.activeClients")}
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={statusCheck.inactive}
                                                    onChange={(event) =>
                                                        handleChangeStatus(event, "Clientes inactivos")
                                                    }
                                                />
                                            }
                                            label={t("filtersServ.inactiveClients")}
                                        />
                                    </FormGroup>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                sx={{
                                    borderBottom: Object.values(franchiseCheck).some((element) => element === true)
                                        ? "3px solid #ff5968"
                                        : "3px solid rgba(3, 24, 81, 0.2)",
                                }}
                            >
                                <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                                    <Typography variant="h5">{t("miscellaneous.franchise")}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Search>
                                        <SearchIconWrapper>
                                            <SearchIcon />
                                        </SearchIconWrapper>
                                        <StyledInputBaseFilter
                                            value={searchValueFranq}
                                            placeholder={t("miscellaneous.search")}
                                            inputProps={{ "aria-label": "search" }}
                                            onChange={handleSearchFranq}
                                        />
                                    </Search>
                                    <FormGroup>
                                        {franqSearch.map((value, index) => (
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={franchiseCheck[index]}
                                                        onChange={(event) => handleChangeFranq(event, value.id, index)}
                                                    />
                                                }
                                                label={value.franchise_name}
                                            />
                                        ))}
                                    </FormGroup>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                sx={{
                                    borderBottom: Object.values(groupCheck).some((element) => element === true)
                                        ? "3px solid #ff5968"
                                        : "3px solid rgba(3, 24, 81, 0.2)",
                                }}
                            >
                                <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                                    <Typography variant="h5">{t("miscellaneous.group")}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Search>
                                        <SearchIconWrapper>
                                            <SearchIcon />
                                        </SearchIconWrapper>
                                        <StyledInputBaseFilter
                                            value={searchValueGroup}
                                            placeholder={t("miscellaneous.search")}
                                            inputProps={{ "aria-label": "search" }}
                                            onChange={handleSearchGroup}
                                        />
                                    </Search>
                                    <FormGroup>
                                        {groupSearch.map((value, index) => (
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={groupCheck[index]}
                                                        onChange={(event) => handleChangeGroup(event, value.id, index)}
                                                    />
                                                }
                                                label={value.group_name}
                                            />
                                        ))}
                                    </FormGroup>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                sx={{
                                    borderBottom: Object.values(typeC).some((element) => element === true)
                                        ? "3px solid #ff5968"
                                        : "3px solid rgba(3, 24, 81, 0.2)",
                                }}
                            >
                                <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                                    <Typography variant="h5">{t("miscellaneous.type")}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={typeC.firmCheck}
                                                    onChange={(event) => handleChangeTypeC(event, 1)}
                                                />
                                            }
                                            label={t("miscellaneous.firm")}
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={typeC.smbCheck}
                                                    onChange={(event) => handleChangeTypeC(event, 2)}
                                                />
                                            }
                                            label="SMB"
                                        />
                                    </FormGroup>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                sx={{
                                    borderBottom: Object.values(clasi).some((element) => element === true)
                                        ? "3px solid #ff5968"
                                        : "3px solid rgba(3, 24, 81, 0.2)",
                                }}
                            >
                                <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                                    <Typography variant="h5">{t("miscellaneous.classification")}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={clasi.stdCheck}
                                                    onChange={(event) => handleChangeClasi(event, 1)}
                                                />
                                            }
                                            label="Standard"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={clasi.vipCheck}
                                                    onChange={(event) => handleChangeClasi(event, 2)}
                                                />
                                            }
                                            label="VIP"
                                        />
                                    </FormGroup>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                sx={{
                                    borderBottom: Object.values(marketC).some((element) => element === true)
                                        ? "3px solid #ff5968"
                                        : "3px solid rgba(3, 24, 81, 0.2)",
                                }}
                            >
                                <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                                    <Typography variant="h5">{t("generalDashboard.market")}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={marketC.glCheck}
                                                    onChange={(event) => handleChangeMarket(event, 3)}
                                                />
                                            }
                                            label="Global"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={marketC.laCheck}
                                                    onChange={(event) => handleChangeMarket(event, 4)}
                                                />
                                            }
                                            label="LATAM"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={marketC.usCheck}
                                                    onChange={(event) => handleChangeMarket(event, 2)}
                                                />
                                            }
                                            label="USA"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={marketC.veCheck}
                                                    onChange={(event) => handleChangeMarket(event, 1)}
                                                />
                                            }
                                            label="Venezuela"
                                        />
                                    </FormGroup>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                sx={{
                                    borderBottom: Object.values(provCheck).some((element) => element === true)
                                        ? "3px solid #ff5968"
                                        : "3px solid rgba(3, 24, 81, 0.2)",
                                }}
                            >
                                <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                                    <Typography variant="h5">{t("miscellaneous.accountingSystem")}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <FormGroup>
                                        {proveedores.map((value, index) => (
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={provCheck[index]}
                                                        onChange={(event) =>
                                                            handleChangeProvee(event, value.provider_name, index)
                                                        }
                                                    />
                                                }
                                                label={value.provider_name}
                                            />
                                        ))}
                                    </FormGroup>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                sx={{
                                    borderBottom: Object.values(servCheck).some((element) => element === true)
                                        ? "3px solid #ff5968"
                                        : "3px solid rgba(3, 24, 81, 0.2)",
                                }}
                            >
                                <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                                    <Typography variant="h5">{t("principal.services")}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Search>
                                        <SearchIconWrapper>
                                            <SearchIcon />
                                        </SearchIconWrapper>
                                        <StyledInputBaseFilter
                                            value={searchValueServ}
                                            placeholder={t("miscellaneous.search")}
                                            inputProps={{ "aria-label": "search" }}
                                            onChange={handleSearchServ}
                                        />
                                    </Search>
                                    <FormGroup>
                                        {serviceSearch.map((value, index) =>
                                            value.active ? (
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={servCheck[index]}
                                                            onChange={(event) =>
                                                                handleChangeService(
                                                                    event,
                                                                    value._id,
                                                                    value.service_name,
                                                                    index
                                                                )
                                                            }
                                                        />
                                                    }
                                                    label={value.service_name}
                                                />
                                            ) : null
                                        )}
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={statusCheck.noService}
                                                    onChange={(event) => handleChangeStatus(event, "Sin servicio")}
                                                />
                                            }
                                            label={t("filtersServ.noService")}
                                        />
                                    </FormGroup>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                sx={{
                                    borderBottom: Object.values(projectsCheck).some((element) => element === true)
                                        ? "3px solid #ff5968"
                                        : "3px solid rgba(3, 24, 81, 0.2)",
                                }}
                            >
                                <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                                    <Typography variant="h5">{t("principal.projects")}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Search>
                                        <SearchIconWrapper>
                                            <SearchIcon />
                                        </SearchIconWrapper>
                                        <StyledInputBaseFilter
                                            value={searchValueProject}
                                            placeholder={t("miscellaneous.search")}
                                            inputProps={{ "aria-label": "search" }}
                                            onChange={handleSearchProject}
                                        />
                                    </Search>
                                    <FormGroup>
                                        {projectsSearch.map((value, index) =>
                                            value.show ? (
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={projectsCheck[index]}
                                                            onChange={(event) =>
                                                                handleChangeProject(event, value._id, index)
                                                            }
                                                        />
                                                    }
                                                    label={value.concat_name}
                                                />
                                            ) : null
                                        )}
                                    </FormGroup>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                sx={{
                                    borderBottom:
                                        Object.values(accAdd).some((element) => element === true) ||
                                        Object.values(bookAdd).some((element) => element === true) ||
                                        Object.values(cfoAdd).some((element) => element === true) ||
                                        Object.values(contAdd).some((element) => element === true) ||
                                        Object.values(taxAdd).some((element) => element === true)
                                            ? "3px solid #ff5968"
                                            : "3px solid rgba(3, 24, 81, 0.2)",
                                }}
                            >
                                <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                                    <Typography variant="h5">{t("services.additionals")}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {showAdd.accountant ? (
                                        <>
                                            <Typography
                                                variant="h5"
                                                sx={{
                                                    color: "rgba(3, 24, 81, 0.5)",
                                                    fontStyle: "italic",
                                                    ...(moment.locale() === "en" && { fontStyle: "normal" }),
                                                }}
                                            >
                                                Accountant
                                            </Typography>
                                            <FormGroup sx={{ pb: 2 }}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={accAdd.apCheck}
                                                            onChange={(event) =>
                                                                handleChangeAdd(event, "A/P reconciliation")
                                                            }
                                                        />
                                                    }
                                                    label={t("proposals.apAdd")}
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={accAdd.arCheck}
                                                            onChange={(event) =>
                                                                handleChangeAdd(event, "A/R reconciliation")
                                                            }
                                                        />
                                                    }
                                                    label={t("proposals.arAdd")}
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={accAdd.classCheck}
                                                            onChange={(event) =>
                                                                handleChangeAdd(event, "Classes/Categories accounting")
                                                            }
                                                        />
                                                    }
                                                    label={t("services.classesCategories")}
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={accAdd.checksCheck}
                                                            onChange={(event) => handleChangeAdd(event, "ChecksA")}
                                                        />
                                                    }
                                                    label={t("services.checks")}
                                                />
                                                {showAdd.ve ? (
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={accAdd.espCheck}
                                                                onChange={(event) => handleChangeAdd(event, "Especial")}
                                                            />
                                                        }
                                                        label={t("services.special")}
                                                    />
                                                ) : null}
                                                {showAdd.us ? (
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={accAdd.salCheck}
                                                                onChange={(event) =>
                                                                    handleChangeAdd(event, "Sales taxes")
                                                                }
                                                            />
                                                        }
                                                        label={t("services.salesTaxes")}
                                                    />
                                                ) : null}
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={accAdd.multiCheck}
                                                            onChange={(event) =>
                                                                handleChangeAdd(event, "Multi-entity accounting")
                                                            }
                                                        />
                                                    }
                                                    label={t("services.multiEntity")}
                                                />
                                                {showAdd.ve ? (
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={accAdd.muniCheck}
                                                                onChange={(event) =>
                                                                    handleChangeAdd(event, "Municipal")
                                                                }
                                                            />
                                                        }
                                                        label={t("services.municipal")}
                                                    />
                                                ) : null}
                                                {showAdd.ve ? (
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={accAdd.ordCheck}
                                                                onChange={(event) =>
                                                                    handleChangeAdd(event, "Ordinario")
                                                                }
                                                            />
                                                        }
                                                        label={t("services.ordinary")}
                                                    />
                                                ) : null}
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={accAdd.prCheck}
                                                            onChange={(event) =>
                                                                handleChangeAdd(event, "Personalized reporting")
                                                            }
                                                        />
                                                    }
                                                    label={t("services.personalizedReporting")}
                                                />

                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={accAdd.othersCheck}
                                                            onChange={(event) => handleChangeAdd(event, "Other")}
                                                        />
                                                    }
                                                    label={t("services.others")}
                                                />
                                            </FormGroup>
                                        </>
                                    ) : null}
                                    {showAdd.book ? (
                                        <>
                                            <Typography
                                                variant="h5"
                                                sx={{
                                                    color: "rgba(3, 24, 81, 0.5)",
                                                    fontStyle: "italic",
                                                    ...(moment.locale() === "en" && { fontStyle: "normal" }),
                                                }}
                                            >
                                                Bookkeeper
                                            </Typography>
                                            <FormGroup sx={{ pb: 2 }}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={bookAdd.apCheck}
                                                            onChange={(event) => handleChangeAdd(event, "A/P")}
                                                        />
                                                    }
                                                    label={t("proposals.apAdd")}
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={bookAdd.arCheck}
                                                            onChange={(event) => handleChangeAdd(event, "A/R")}
                                                        />
                                                    }
                                                    label={t("proposals.arAdd")}
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={bookAdd.classCheck}
                                                            onChange={(event) =>
                                                                handleChangeAdd(event, "Classes/Categories accounting")
                                                            }
                                                        />
                                                    }
                                                    label={t("services.classesCategories")}
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={bookAdd.checksCheck}
                                                            onChange={(event) => handleChangeAdd(event, "ChecksB")}
                                                        />
                                                    }
                                                    label={t("services.checks")}
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={bookAdd.accruCheck}
                                                            onChange={(event) => handleChangeAdd(event, "Accrual")}
                                                        />
                                                    }
                                                    label={t("services.accrualAccounting")}
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={bookAdd.multiCheck}
                                                            onChange={(event) => handleChangeAdd(event, "Multi-entity")}
                                                        />
                                                    }
                                                    label={t("services.multiEntity")}
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={bookAdd.personalizedCheck}
                                                            onChange={(event) =>
                                                                handleChangeAdd(event, "Personalized reporting package")
                                                            }
                                                        />
                                                    }
                                                    label={t("services.personalizedReporting")}
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={bookAdd.otherCheck}
                                                            onChange={(event) => handleChangeAdd(event, "Others")}
                                                        />
                                                    }
                                                    label={t("services.others")}
                                                />
                                            </FormGroup>
                                        </>
                                    ) : null}
                                    {showAdd.cfo ? (
                                        <>
                                            <Typography variant="h5" sx={{ color: "rgba(3, 24, 81, 0.5)" }}>
                                                CFO
                                            </Typography>
                                            <FormGroup sx={{ pb: 2 }}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={cfoAdd.gobCheck}
                                                            onChange={(event) =>
                                                                handleChangeAdd(event, "Gobierno corporativo")
                                                            }
                                                        />
                                                    }
                                                    label={t("services.corporateGovernance")}
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={cfoAdd.preCheck}
                                                            onChange={(event) =>
                                                                handleChangeAdd(event, "Presentaciones financieras")
                                                            }
                                                        />
                                                    }
                                                    label={t("services.investorPresentations")}
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={cfoAdd.repCheck}
                                                            onChange={(event) =>
                                                                handleChangeAdd(event, "Reportes para inversionistas")
                                                            }
                                                        />
                                                    }
                                                    label={t("services.investorReports")}
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={cfoAdd.otherCheck}
                                                            onChange={(event) => handleChangeAdd(event, "Other CFO")}
                                                        />
                                                    }
                                                    label={t("services.others")}
                                                />
                                            </FormGroup>
                                        </>
                                    ) : null}
                                    {showAdd.controller ? (
                                        <>
                                            <Typography
                                                variant="h5"
                                                sx={{
                                                    color: "rgba(3, 24, 81, 0.5)",
                                                    fontStyle: "italic",
                                                    ...(moment.locale() === "en" && { fontStyle: "normal" }),
                                                }}
                                            >
                                                Controller
                                            </Typography>
                                            <FormGroup sx={{ pb: 2 }}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={contAdd.ejeCheck}
                                                            onChange={(event) =>
                                                                handleChangeAdd(
                                                                    event,
                                                                    "Ejecución de planificación fiscal"
                                                                )
                                                            }
                                                        />
                                                    }
                                                    label={t("services.executionTaxPlan")}
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={contAdd.conCheck}
                                                            onChange={(event) =>
                                                                handleChangeAdd(
                                                                    event,
                                                                    "Planificación de administración y contabilidad"
                                                                )
                                                            }
                                                        />
                                                    }
                                                    label={t("services.consolidationStatements")}
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={contAdd.repCheck}
                                                            onChange={(event) =>
                                                                handleChangeAdd(
                                                                    event,
                                                                    "Reportes para auditores externos"
                                                                )
                                                            }
                                                        />
                                                    }
                                                    label={t("services.reportsAuditors")}
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={contAdd.otherCheck}
                                                            onChange={(event) =>
                                                                handleChangeAdd(event, "Other Controller")
                                                            }
                                                        />
                                                    }
                                                    label={t("services.others")}
                                                />
                                            </FormGroup>
                                        </>
                                    ) : null}
                                    {showAdd.tax ? (
                                        <>
                                            {showAdd.us || showAdd.ve ? (
                                                <Typography
                                                    variant="h5"
                                                    sx={{
                                                        color: "rgba(3, 24, 81, 0.5)",
                                                        fontStyle: "italic",
                                                        ...(moment.locale() === "en" && { fontStyle: "normal" }),
                                                    }}
                                                >
                                                    Tax Expert
                                                </Typography>
                                            ) : null}
                                            <FormGroup sx={{ pb: 2 }}>
                                                {showAdd.ve ? (
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={taxAdd.decISLRCheck}
                                                                onChange={(event) =>
                                                                    handleChangeAdd(
                                                                        event,
                                                                        "Declaración estimada de ISLR"
                                                                    )
                                                                }
                                                            />
                                                        }
                                                        label={t("services.declarationISLR")}
                                                    />
                                                ) : null}
                                                {showAdd.us ? (
                                                    <>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={taxAdd.compCheck}
                                                                    onChange={(event) =>
                                                                        handleChangeAdd(event, "Intercompany")
                                                                    }
                                                                />
                                                            }
                                                            label={t("services.intercompany")}
                                                        />
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={taxAdd.interCheck}
                                                                    onChange={(event) =>
                                                                        handleChangeAdd(event, "International")
                                                                    }
                                                                />
                                                            }
                                                            label={t("services.international")}
                                                        />
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={taxAdd.plaCheck}
                                                                    onChange={(event) =>
                                                                        handleChangeAdd(event, "Tax planning")
                                                                    }
                                                                />
                                                            }
                                                            label={t("services.taxPlanning")}
                                                        />
                                                    </>
                                                ) : null}
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={taxAdd.otherCheck}
                                                            onChange={(event) => handleChangeAdd(event, "Other Tax")}
                                                        />
                                                    }
                                                    label={t("services.others")}
                                                />
                                            </FormGroup>
                                        </>
                                    ) : null}
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                sx={{
                                    borderBottom:
                                        Object.values(advi).some((element) => element === true) ||
                                        Object.values(analista).some((element) => element === true) ||
                                        Object.values(analistaBackup).some((element) => element === true) ||
                                        Object.values(gerente).some((element) => element === true) ||
                                        Object.values(superv).some((element) => element === true)
                                            ? "3px solid #ff5968"
                                            : "3px solid rgba(3, 24, 81, 0.2)",
                                }}
                            >
                                <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                                    <Typography variant="h5">{t("miscellaneous.gcaStaff")}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography variant="h5" sx={{ color: "rgba(3, 24, 81, 0.5)" }}>
                                        {t("miscellaneous.advisor")}
                                    </Typography>
                                    <FormGroup sx={{ pb: 2 }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={advi.gerCheck}
                                                    onChange={(event) => handleChangeAdvi(event, "Geraldine Calles")}
                                                />
                                            }
                                            label="Geraldine Calles"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={advi.naCheck}
                                                    onChange={(event) => handleChangeAdvi(event, "N/A")}
                                                />
                                            }
                                            label="N/A"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={advi.porACheck}
                                                    onChange={(event) => handleChangeAdvi(event, "Por asignar")}
                                                />
                                            }
                                            label="Por asignar"
                                        />
                                    </FormGroup>
                                    <Typography variant="h5" sx={{ color: "rgba(3, 24, 81, 0.5)" }}>
                                        {t("miscellaneous.analyst")}
                                    </Typography>
                                    <FormGroup sx={{ pb: 2 }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={analista.cgilCheck}
                                                    onChange={(event) => handleChangeAnalyst(event, "Christian Gil")}
                                                />
                                            }
                                            label="Christian Gil"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={analista.iraCheck}
                                                    onChange={(event) => handleChangeAnalyst(event, "Iraixy Guevara")}
                                                />
                                            }
                                            label="Iraixy Guevara"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={analista.mbarCheck}
                                                    onChange={(event) => handleChangeAnalyst(event, "María Fernanda Barrientos")}
                                                />
                                            }
                                            label="María Fernanda Barrientos"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={analista.yarCheck}
                                                    onChange={(event) => handleChangeAnalyst(event, "Yaritza Rosas")}
                                                />
                                            }
                                            label="Yaritza Rosas"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={analista.suppCheck}
                                                    onChange={(event) => handleChangeAnalyst(event, "Soporte")}
                                                />
                                            }
                                            label="Soporte"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={analista.naCheck}
                                                    onChange={(event) => handleChangeAnalyst(event, "N/A")}
                                                />
                                            }
                                            label="N/A"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={analista.porACheck}
                                                    onChange={(event) => handleChangeAnalyst(event, "Por asignar")}
                                                />
                                            }
                                            label="Por asignar"
                                        />
                                    </FormGroup>
                                    <Typography variant="h5" sx={{ color: "rgba(3, 24, 81, 0.5)" }}>
                                        {t("miscellaneous.backupAnalyst")}
                                    </Typography>
                                    <FormGroup sx={{ pb: 2 }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={analistaBackup.cgilCheck}
                                                    onChange={(event) =>
                                                        handleChangeBackupAnalyst(event, "Christian Gil")
                                                    }
                                                />
                                            }
                                            label="Christian Gil"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={analistaBackup.iraCheck}
                                                    onChange={(event) =>
                                                        handleChangeBackupAnalyst(event, "Iraixy Guevara")
                                                    }
                                                />
                                            }
                                            label="Iraixy Guevara"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={analistaBackup.mbarCheck}
                                                    onChange={(event) =>
                                                        handleChangeBackupAnalyst(event, "María Fernanda Barrientos")
                                                    }
                                                />
                                            }
                                            label="María Fernanda Barrientos"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={analistaBackup.yarCheck}
                                                    onChange={(event) =>
                                                        handleChangeBackupAnalyst(event, "Yaritza Rosas")
                                                    }
                                                />
                                            }
                                            label="Yaritza Rosas"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={analistaBackup.suppCheck}
                                                    onChange={(event) => handleChangeBackupAnalyst(event, "Soporte")}
                                                />
                                            }
                                            label="Soporte"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={analistaBackup.naCheck}
                                                    onChange={(event) => handleChangeBackupAnalyst(event, "N/A")}
                                                />
                                            }
                                            label="N/A"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={analistaBackup.porACheck}
                                                    onChange={(event) =>
                                                        handleChangeBackupAnalyst(event, "Por asignar")
                                                    }
                                                />
                                            }
                                            label="Por asignar"
                                        />
                                    </FormGroup>
                                    <Typography variant="h5" sx={{ color: "rgba(3, 24, 81, 0.5)" }}>
                                        {t("miscellaneous.manager")}
                                    </Typography>
                                    <FormGroup sx={{ pb: 2 }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={gerente.deyaCheck}
                                                    onChange={(event) => handleChangeMana(event, "Deyanira Goncalves")}
                                                />
                                            }
                                            label="Deyanira Goncalves"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={gerente.gerCheck}
                                                    onChange={(event) => handleChangeMana(event, "Geraldine Calles")}
                                                />
                                            }
                                            label="Geraldine Calles"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={gerente.mcruzCheck}
                                                    onChange={(event) => handleChangeMana(event, "María Fernanda Cruz")}
                                                />
                                            }
                                            label="María Fernanda Cruz"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={gerente.naCheck}
                                                    onChange={(event) => handleChangeMana(event, "N/A")}
                                                />
                                            }
                                            label="N/A"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={gerente.porACheck}
                                                    onChange={(event) => handleChangeMana(event, "Por asignar")}
                                                />
                                            }
                                            label="Por asignar"
                                        />
                                    </FormGroup>
                                    <Typography variant="h5" sx={{ color: "rgba(3, 24, 81, 0.5)" }}>
                                        {t("miscellaneous.supervisor")}
                                    </Typography>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={superv.deyaCheck}
                                                    onChange={(event) => handleChangeSuper(event, "Deyanira Goncalves")}
                                                />
                                            }
                                            label="Deyanira Goncalves"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={superv.mariaCheck}
                                                    onChange={(event) => handleChangeSuper(event, "María Fernanda Barrientos")}
                                                />
                                            }
                                            label="María Fernanda Barrientos"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={superv.mcruzCheck}
                                                    onChange={(event) => handleChangeSuper(event, "María Fernanda Cruz")}
                                                />
                                            }
                                            label="María Fernanda Cruz"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={superv.naCheck}
                                                    onChange={(event) => handleChangeSuper(event, "N/A")}
                                                />
                                            }
                                            label="N/A"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={superv.porACheck}
                                                    onChange={(event) => handleChangeSuper(event, "Por asignar")}
                                                />
                                            }
                                            label="Por asignar"
                                        />
                                    </FormGroup>
                                </AccordionDetails>
                            </Accordion>
                        </Box>
                    )}
                </Grid>
                <Grid item xs={12} md={12} lg={4}>
                    {loading ? (
                        <Card
                            elevation={0}
                            sx={{
                                borderRadius: 8,
                                boxShadow: "10px 10px 15px rgba(3,24,81,0.15)",
                            }}
                        >
                            <CardHeader
                                title={
                                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                        <Box>
                                            <Typography variant="h3" color="primary">
                                                {t("miscellaneous.clients")}
                                            </Typography>
                                        </Box>
                                        <Box sx={{ width: "200px", position: "relative" }}>
                                            {
                                                <Skeleton
                                                    variant="text"
                                                    animation="wave"
                                                    height={15}
                                                    width="55%"
                                                    sx={{ position: "absolute", right: 0 }}
                                                />
                                            }
                                        </Box>
                                    </Box>
                                }
                                sx={{
                                    m: 2,
                                    borderBottom: "2px solid #FF5968",
                                }}
                            />
                            <CardContent sx={{ pt: 0 }}>
                                <Skeleton
                                    variant="rounded"
                                    animation="wave"
                                    height={52}
                                    sx={{ borderRadius: 25, ml: 0, mb: "10px" }}
                                />
                                <List component="nav">
                                    {Array(5).fill(
                                        <>
                                            <ListItem sx={{ p: "12px 16px !important" }}>
                                                <ListItemText
                                                    primary={
                                                        <Skeleton
                                                            variant="text"
                                                            animation="wave"
                                                            height={15}
                                                            width="50%"
                                                        />
                                                    }
                                                />
                                            </ListItem>
                                            <Divider />
                                            <ListItem sx={{ p: "12px 16px !important" }}>
                                                <ListItemText
                                                    primary={
                                                        <Skeleton
                                                            variant="text"
                                                            animation="wave"
                                                            height={15}
                                                            width="35%"
                                                        />
                                                    }
                                                />
                                            </ListItem>
                                            <Divider />
                                        </>
                                    )}
                                </List>
                            </CardContent>
                        </Card>
                    ) : (
                        <ClientsList
                            totalClients={totalClients}
                            searchValue={searchValue}
                            handleSearch={handleSearch}
                            clientSearch={clientSearch}
                            selectedIndex={selectedIndex}
                            handleChangeClient={handleChangeClient}
                            isFilterComp={true}
                            filtersXLSX={filtersXLSX}
                            franqSearch={franqSearch}
                            groupSearch={groupSearch}
                            serviceSearch={serviceSearch}
                            projectsSearch={projectsSearch}
                        />
                    )}
                </Grid>
                <Grid item xs={12} md={12} lg={5}>
                    {showCard ? (
                        <Card
                            elevation={0}
                            sx={{
                                borderRadius: 8,
                                boxShadow: "10px 10px 15px rgba(3,24,81,0.15)",
                                minHeight: 790,
                            }}
                        >
                            <CardHeader
                                title={
                                    <Box
                                        sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
                                    >
                                        {loadingCard ? (
                                            <Skeleton variant="text" animation="wave" height={15} width="35%" />
                                        ) : (
                                            <Typography variant="h3" color="primary">
                                                {selectedClient.company_legal_name}
                                            </Typography>
                                        )}
                                        <LightTooltip title={t("miscellaneous.edit")}>
                                            <IconButton
                                                aria-label="edit"
                                                color="primary"
                                                size="small"
                                                onClick={goToClient}
                                            >
                                                <EditIcon fontSize="small" />
                                            </IconButton>
                                        </LightTooltip>
                                    </Box>
                                }
                                sx={{
                                    mr: 2,
                                    ml: 2,
                                    mt: 1,
                                    borderBottom: "2px solid #FF5968",
                                }}
                            />
                            <CardContent sx={{ pt: 0 }}>
                                <Card elevation={0}>
                                    <CardHeader title={t("miscellaneous.data")} sx={{ color: "#ff5968" }} />
                                    <Divider />
                                    <CardContent>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={6}>
                                                {loadingCard ? (
                                                    <Skeleton variant="rounded" animation="wave" height={50} />
                                                ) : (
                                                    <TextField
                                                        fullWidth
                                                        label={t("miscellaneous.franchise")}
                                                        value={franqName}
                                                        variant="outlined"
                                                        inputProps={{ readOnly: true }}
                                                    />
                                                )}
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                {loadingCard ? (
                                                    <Skeleton variant="rounded" animation="wave" height={50} />
                                                ) : (
                                                    <TextField
                                                        fullWidth
                                                        label={t("miscellaneous.group")}
                                                        value={groupName}
                                                        variant="outlined"
                                                        inputProps={{ readOnly: true }}
                                                    />
                                                )}
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                {loadingCard ? (
                                                    <Skeleton variant="rounded" animation="wave" height={50} />
                                                ) : (
                                                    <TextField
                                                        fullWidth
                                                        label={t("miscellaneous.type")}
                                                        value={
                                                            selectedClient.client_type === 1
                                                                ? t("miscellaneous.firm")
                                                                : "SMB"
                                                        }
                                                        variant="outlined"
                                                        inputProps={{ readOnly: true }}
                                                    />
                                                )}
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                {loadingCard ? (
                                                    <Skeleton variant="rounded" animation="wave" height={50} />
                                                ) : (
                                                    <TextField
                                                        fullWidth
                                                        label={t("miscellaneous.classification")}
                                                        value={selectedClient.client_status === 1 ? "Standard" : "VIP"}
                                                        variant="outlined"
                                                        inputProps={{ readOnly: true }}
                                                    />
                                                )}
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                {loadingCard ? (
                                                    <Skeleton variant="rounded" animation="wave" height={50} />
                                                ) : (
                                                    <TextField
                                                        fullWidth
                                                        label={t("miscellaneous.country")}
                                                        value={
                                                            selectedClient.country_name === undefined ||
                                                            selectedClient.country_name === " "
                                                                ? "N/A"
                                                                : t(
                                                                      `countries.${selectedClient.country_name.replace(
                                                                          /\s/g,
                                                                          ""
                                                                      )}`
                                                                  )
                                                        }
                                                        variant="outlined"
                                                        inputProps={{ readOnly: true }}
                                                    />
                                                )}
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                {loadingCard ? (
                                                    <Skeleton variant="rounded" animation="wave" height={50} />
                                                ) : (
                                                    <TextField
                                                        fullWidth
                                                        label={t("miscellaneous.economicActivity")}
                                                        value={
                                                            selectedClient.social_activity === undefined ||
                                                            selectedClient.social_activity === null
                                                                ? "N/A"
                                                                : selectedClient.social_activity
                                                        }
                                                        variant="outlined"
                                                        inputProps={{ readOnly: true }}
                                                    />
                                                )}
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                                <Card elevation={0}>
                                    <CardHeader
                                        title={t("miscellaneous.accountingSystem")}
                                        sx={{ color: "#ff5968", pt: 0 }}
                                    />
                                    <Divider />
                                    <CardContent>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} md={6}>
                                                {loadingCard ? (
                                                    <Skeleton variant="rounded" animation="wave" height={50} />
                                                ) : (
                                                    <TextField
                                                        fullWidth
                                                        label={t("miscellaneous.supplier")}
                                                        value={
                                                            selectedClient.software_provider === undefined
                                                                ? "N/A"
                                                                : selectedClient.software_provider
                                                        }
                                                        variant="outlined"
                                                        inputProps={{ readOnly: true }}
                                                    />
                                                )}
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                {loadingCard ? (
                                                    <Skeleton variant="rounded" animation="wave" height={50} />
                                                ) : (
                                                    <TextField
                                                        fullWidth
                                                        label={t("login.userAccess")}
                                                        value={
                                                            selectedClient.xero_master_user === undefined
                                                                ? "N/A"
                                                                : selectedClient.xero_master_user
                                                        }
                                                        variant="outlined"
                                                        inputProps={{ readOnly: true }}
                                                    />
                                                )}
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                                <Card elevation={0}>
                                    <CardHeader title={t("principal.services")} sx={{ color: "#ff5968", pt: 0 }} />
                                    <Divider />
                                    <CardContent>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} md={6}>
                                                {loadingCard ? (
                                                    <Skeleton variant="rounded" animation="wave" height={50} />
                                                ) : (
                                                    <TextField
                                                        fullWidth
                                                        label={t("principal.services")}
                                                        value={clientServ}
                                                        multiline
                                                        inputProps={{ readOnly: true }}
                                                    />
                                                )}
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                {loadingCard ? (
                                                    <Skeleton variant="rounded" animation="wave" height={50} />
                                                ) : (
                                                    <TextField
                                                        fullWidth
                                                        label={t("services.additionals")}
                                                        value={additionals}
                                                        multiline
                                                        inputProps={{ readOnly: true }}
                                                    />
                                                )}
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                                <Card elevation={0}>
                                    <CardHeader title={t("miscellaneous.invoicing")} sx={{ color: "#ff5968", pt: 0 }} />
                                    <Divider />
                                    <CardContent>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} md={6}>
                                                {loadingCard ? (
                                                    <Skeleton variant="rounded" animation="wave" height={50} />
                                                ) : (
                                                    <TextField
                                                        fullWidth
                                                        label={t("miscellaneous.contact")}
                                                        value={
                                                            selectedClient.invoicing_contact_name === null ||
                                                            selectedClient.invoicing_contact_name === undefined
                                                                ? "N/A"
                                                                : selectedClient.invoicing_contact_name
                                                        }
                                                        inputProps={{ readOnly: true }}
                                                    />
                                                )}
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                {loadingCard ? (
                                                    <Skeleton variant="rounded" animation="wave" height={50} />
                                                ) : (
                                                    <TextField
                                                        fullWidth
                                                        label={t("miscellaneous.name")}
                                                        value={
                                                            selectedClient.invoicing_client_name === null ||
                                                            selectedClient.invoicing_client_name === undefined
                                                                ? "N/A"
                                                                : selectedClient.invoicing_client_name
                                                        }
                                                        inputProps={{ readOnly: true }}
                                                    />
                                                )}
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </CardContent>
                        </Card>
                    ) : null}
                </Grid>
            </Grid>
        </StyledContainer>
    );
};

export default FiltersServ;
