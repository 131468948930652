/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';

/** MUI icons */
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import DeleteIcon from '@mui/icons-material/Delete';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';

/** MUI imports */
import { styled } from '@mui/material/styles';
import {
    Box,
    Grid,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    OutlinedInput,
    Card,
    CardContent,
    CardActions,
    Button,
    TextField,
    List,
    ListSubheader,
    ListItem,
    IconButton,
    ListItemIcon,
    ListItemText
} from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

/** Components imports */
import Backdrop from '../Backdrop';

const PREFIX = 'FileSetting';

const classes = {
    dropzone: `${PREFIX}-dropzone`,
    dropzoneDisabled: `${PREFIX}-dropzoneDisabled`,
    formRounded: `${PREFIX}-formRounded`,
    btnRoot: `${PREFIX}-btnRoot`
};

const CustomBox = styled(Box)(({ theme }) => ({
    [`& .${classes.dropzone}`]: {
        flex: 1,
        display: "flex",
        padding: "64px 24px",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: 'rgba(3,24,81,0.1)',
        border: '2px dashed #B3BACB',
        borderRadius: '16px',
        '&:hover': {
            border: '2px dashed #031851',
        },
    },
    [`& .${classes.dropzoneDisabled}`]: {
        flex: 1,
        display: "flex",
        padding: "64px 24px",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: 'rgba(3,24,81,0.1)',
        border: '2px dashed #B3BACB',
        borderRadius: '16px',
    },
    [`& .${classes.formRounded}`]: {
        backgroundColor: '#E9EAE8 !important',
        borderRadius: 40,
        verticalAlign: 'middle !important',
        "&:focus-within": {
            backgroundColor: "#E9EAE8",
            borderRadius: 40,
        },
        "& .MuiOutlinedInput-notchedOutline": {
            border: '2px solid #031851',
            borderRadius: 40,
        },
    },
    [`& .${classes.btnRoot}`]: {
        border: '1px solid #ff5968',
        borderRadius: 40,
        '&:hover': {
            backgroundColor: '#2f3190',
            border: '1px solid #2f3190',
        },
    },
}));

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: 'rgba(19, 31, 62, 0.80)',
        fontSize: 12,
        maxWidth: 320,
        borderRadius: 6,
        fontWeight: 'normal'
    },
});

const CustomTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        backgroundColor: '#F8F9F8',
        borderRadius: 50,
        '& label': {
            color: '#031851',
        },
        '& label.Mui-focused': {
            color: '#031851',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#F8F9F8',
        },
        '& fieldset': {
            borderColor: '#F8F9F8',
            borderRadius: 50
        },
        '&:hover fieldset': {
            border: '1px solid #031851 !important',
        },
        '&.Mui-focused fieldset': {
            border: '1px solid #031851 !important',
        },
        '& .MuiOutlinedInput-input': {
            paddingLeft: 20,
            paddingRight: 20
        },
        '& .Mui-error': {
            borderRadius: 50,
            border: '1px solid #FF5968 !important',
            backgroundColor: '#FFDEE1 !important',
        }
    },
});

const FileSetting = (props) => {
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    const [filesRejected, setFilesRejected] = useState([]);
    const [clientTypeDoc, setClientTypeDoc] = useState("");
    const [statusDoc, setStatusDoc] = useState("");
    const [susDoc, setSusDoc] = useState("");
    const [confName, setConfName] = useState("");
    const [filesData, setFilesData] = useState([]);
    const [showBackdrop, setBackDrop] = useState(false);

    const onDrop = useCallback(
        (acceptedFiles) => {
            if (acceptedFiles[0].size < 5000000) {
                setFilesData([...acceptedFiles]);
                setFilesRejected([]);
            }
        },
        []
    );

    const onDropRejected = useCallback(
        fileRejections => {
            setFilesRejected([...filesRejected, ...fileRejections]);
        },
        [filesRejected]
    );

    /** Component functions */
    const formatBytes = (bytes, decimals = 0) => {
        if (bytes === 0) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    };

    const { getRootProps, getInputProps } = useDropzone({
        noKeyboard: true,
        maxSize: 5000000,
        maxFiles: 1,
        multiple: false,
        onDrop,
        onDropRejected,
        // accept: 'image/jpeg,image/png',
    });

    const handleChangeTypeDoc = (event) => {
        setClientTypeDoc(event.target.value);
    }

    const handleChangeStatusDoc = (event) => {
        setStatusDoc(event.target.value);
    }

    const handleChangeSusDoc = (event) => {
        setSusDoc(event.target.value);
    }

    const handleChangeConfName = (event) => {
        setConfName(event.target.value);
    }

    const downloadFile = file => () => {
        setBackDrop(true);

        setBackDrop(false);
    };

    const removeFile = file => () => {
        setBackDrop(true);

        setBackDrop(false);
    };

    const importFile = async () => {
        let params = {};

        await axios.get("/importAutomationFile", { params })
            .then((response) => {
                console.log(response.data);
                props.setMatchData(response.data);
                props.handleNext();
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const acceptedFileItems = filesData.map((file) => (
        <ListItem
            disablePadding
            secondaryAction={
                <Box>
                    <LightTooltip title={t('dialogConversions.download')} aria-label="downloadFile">
                        <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={downloadFile(file)}
                            sx={{ mr: 1 }}
                        >
                            <FileDownloadIcon fontSize='medium' color='primary' />
                        </IconButton>
                    </LightTooltip>
                    <LightTooltip title={t('dialogConversions.remove')} aria-label="removeFile">
                        <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={removeFile(file)}
                        >
                            <DeleteIcon fontSize='medium' color='primary' />
                        </IconButton>
                    </LightTooltip>
                </Box>
            }
            sx={{ height: 50 }}
        >
            <ListItemIcon sx={{ minWidth: '20px' }}>
                <DescriptionOutlinedIcon fontSize='large' color='primary' sx={{ mr: 1 }} />
            </ListItemIcon>
            <ListItemText
                key={file.path}
                primary={file.name}
                secondary={formatBytes(file.size)}
                secondaryTypographyProps={{ fontSize: '12px' }}
            />
        </ListItem>
    ));

    /** Component events */
    useEffect(() => {

    }, [t]);

    return (
        <CustomBox component="main">
            <Grid container spacing={2} sx={{ pt: 4 }}>
                <Grid item xs={12} md={4}>
                    <Card
                        elevation={0}
                        sx={{
                            borderRadius: 4,
                            boxShadow: '10px 10px 15px rgba(3,24,81,0.15)',
                        }}
                    >
                        <CardContent sx={{ p: 3 }}>
                            <FormControl
                                fullWidth
                                classes={{ root: classes.formRounded }}
                            >
                                <InputLabel shrink id="type-simple-select-label">Selecciona el tipo de documento a importar</InputLabel>
                                <Select
                                    size="small"
                                    labelId="type-simple-select-label"
                                    IconComponent={KeyboardArrowDownIcon}
                                    onChange={handleChangeTypeDoc}
                                    displayEmpty
                                    value={clientTypeDoc}
                                    input={<OutlinedInput notched label="Selecciona el tipo de documento a importar" />}
                                    MenuProps={{
                                        PaperProps: {
                                            sx: {
                                                mt: 0.7,
                                                borderRadius: 2,
                                                boxShadow: '10px 10px 15px 0px rgba(3,24,81,0.15)',
                                            },
                                        },
                                    }}
                                >
                                    <MenuItem value="">
                                        <Typography variant="body1" component="span" align="center" color="textPrimary">Selecciona uno...</Typography>
                                    </MenuItem>
                                    <MenuItem value={1}>Facturas de venta (Invoice)</MenuItem>
                                    <MenuItem value={2}>Facturas de compra (Bills)</MenuItem>
                                    <MenuItem value={3}>Recibos de banco (Spent)</MenuItem>
                                    <MenuItem value={4}>Cobros a clientes (Payments)</MenuItem>
                                </Select>
                            </FormControl>
                            {clientTypeDoc === 1 || clientTypeDoc === 2 ?
                                <FormControl
                                    fullWidth
                                    classes={{ root: classes.formRounded }}
                                    sx={{ mt: 4 }}
                                >
                                    <InputLabel shrink id="type-simple-select-label">Indica el estatus de carga que aplique</InputLabel>
                                    <Select
                                        size="small"
                                        labelId="type-simple-select-label"
                                        IconComponent={KeyboardArrowDownIcon}
                                        onChange={handleChangeStatusDoc}
                                        displayEmpty
                                        value={statusDoc}
                                        input={<OutlinedInput notched label="Indica el estatus de carga que aplique" />}
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    mt: 0.7,
                                                    borderRadius: 2,
                                                    boxShadow: '10px 10px 15px 0px rgba(3,24,81,0.15)',
                                                },
                                            },
                                        }}
                                    >
                                        <MenuItem value="">
                                            <Typography variant="body1" component="span" align="center" color="textPrimary">Selecciona uno...</Typography>
                                        </MenuItem>
                                        <MenuItem value={1}>Firm</MenuItem>
                                        <MenuItem value={2}>SMB</MenuItem>
                                    </Select>
                                </FormControl>
                                : null}
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Card
                        elevation={0}
                        sx={{
                            borderRadius: 4,
                            boxShadow: '10px 10px 15px rgba(3,24,81,0.15)',
                        }}
                    >
                        <CardContent sx={{ p: 3 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <div {...getRootProps({ className: (classes.dropzone) })}>
                                        <input {...getInputProps()} />
                                        <CloudUploadIcon fill="#B3BACB" />
                                        <Typography variant="h1" color="#B3BACB" sx={{ cursor: 'default', pt: 3 }}>
                                            {t('dialogConversions.selectFile')}
                                        </Typography>
                                    </div>
                                    <Typography sx={{ pt: 2, pb: 2 }}>Formatos válidos <span style={{ fontWeight: 700 }}>.csv, .xlsx, .xls</span></Typography>
                                    <Typography>Tamaño máximo de carga de archivo 5 MB.</Typography>
                                    {(acceptedFileItems.length > 0) ? (
                                        <List
                                            component="nav"
                                            aria-labelledby="acceptedFiles-list-subheader"
                                            subheader={
                                                <ListSubheader component="div" id="acceptedFiles-list-subheader" sx={{ p: 0 }}>
                                                    <Typography variant="h4" color="primary" sx={{ pt: 3, pb: 1 }}>
                                                        {`${t('dialogConversions.uploadedFiles')}:`}
                                                    </Typography>
                                                </ListSubheader>
                                            }
                                        >
                                            {acceptedFileItems}
                                        </List>
                                    ) : null}
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl
                                        fullWidth
                                        classes={{ root: classes.formRounded }}
                                    >
                                        <InputLabel shrink id="type-simple-select-label">Selecciona la suscripción</InputLabel>
                                        <Select
                                            size="small"
                                            labelId="type-simple-select-label"
                                            IconComponent={KeyboardArrowDownIcon}
                                            onChange={handleChangeSusDoc}
                                            displayEmpty
                                            value={susDoc}
                                            input={<OutlinedInput notched label="Selecciona la suscripción" />}
                                            MenuProps={{
                                                PaperProps: {
                                                    sx: {
                                                        mt: 0.7,
                                                        borderRadius: 2,
                                                        boxShadow: '10px 10px 15px 0px rgba(3,24,81,0.15)',
                                                    },
                                                },
                                            }}
                                        >
                                            <MenuItem value="">
                                                <Typography variant="body1" component="span" align="center" color="textPrimary">Selecciona uno...</Typography>
                                            </MenuItem>
                                            <MenuItem value={1}>Firm</MenuItem>
                                            <MenuItem value={2}>SMB</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <CustomTextField
                                        fullWidth
                                        size="small"
                                        label="Dale un nombre a esta configuración"
                                        placeholder="Escribe aquí..."
                                        onChange={handleChangeConfName}
                                        value={confName}
                                        variant="outlined"
                                        sx={{ mt: 8 }}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                        <CardActions sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            p: 3
                        }}>
                            <Button
                                color="secondary"
                                variant="contained"
                                disableElevation
                                classes={{ root: classes.btnRoot }}
                                onClick={importFile}
                            >Siguiente</Button>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
            <Backdrop
                open={showBackdrop}
            />
        </CustomBox>
    );
};

export default FileSetting;