/** React imports */
import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useSelector } from "react-redux";

/** MUI icons */

/** MUI imports */
import { Grid, Table, TableHeaderRow, PagingPanel, Toolbar, SearchPanel } from "@devexpress/dx-react-grid-material-ui";
import { SearchState, IntegratedFiltering, PagingState, IntegratedPaging } from "@devexpress/dx-react-grid";
import { styled } from "@mui/material/styles";
import GridUI from "@mui/material/Grid";
import { Box, Skeleton, FormControl, Select, InputLabel, MenuItem, OutlinedInput, Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

/** Components imports */

const PREFIX = "Proposals";

const classes = {
    tableStriped: `${PREFIX}-tableStriped`,
    tableHeader: `${PREFIX}-tableHeader`,
    tableBody: `${PREFIX}-tableBody`,
    pager: `${PREFIX}-pager`,
    spacing: `${PREFIX}-spacing`,
    toolbarSearch: `${PREFIX}-toolbarSearch`,
    searchInput: `${PREFIX}-searchInput`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.spacing}`]: {
        padding: "10px 0 10px 0",
    },

    [`& .${classes.tableStriped}`]: {
        "& tbody tr:nth-of-type(odd)": {
            backgroundColor: "rgba(47,49,144,0.1)",
        },
    },
    [`& .${classes.tableHeader}`]: {
        border: "none",
        backgroundColor: "rgba(47,49,144,0.3)",
        fontSize: "15px",
        fontWeight: 600,
        color: "#031851",
        "&:first-of-type": {
            borderRadius: "20px 0 0 0",
        },
        "&:last-child": {
            borderRadius: "0 20px 0 0",
        },
    },

    [`& .${classes.tableBody}`]: {
        border: "none",
        fontSize: "14px",
        fontWeight: 400,
        color: "#031851",
    },

    [`& .${classes.pager}`]: {
        "& .Pagination-button": {
            paddingTop: 0,
            paddingBottom: 0,
            color: "#031851",
            fontWeight: 600,
        },
        "& .Pagination-activeButton": {
            backgroundColor: "rgba(47,49,144,0.3)",
        },
    },

    [`& .${classes.toolbarSearch}`]: {
        border: "none !important",
        padding: "0 0 5px !important",
    },

    [`& .${classes.searchInput}`]: {
        color: "#031851",
        "&:before": {
            borderBottomColor: "#031851",
        },
        "&:hover:before": {
            borderBottomColor: "#031851 !important",
        },
    },
}));

const tableHeaderCell = ({ ...props }) => <TableHeaderRow.Cell {...props} className={classes.tableHeader} />;

const TableComponent = ({ ...props }) => <Table.Table {...props} className={classes.tableStriped} />;

const TableCellComponent = ({ ...props }) => <Table.Cell {...props} className={classes.tableBody} />;

const tableContentComponent = (props) => <TableHeaderRow.Content {...props} />;

const PagingPanelContainer = ({ ...props }) => <PagingPanel.Container {...props} className={classes.pager} />;

const inputComponent = ({ ...props }) => <SearchPanel.Input {...props} className={classes.searchInput} />;

const toolRootComponent = ({ ...props }) => <Toolbar.Root {...props} className={classes.toolbarSearch} />;

const tableColumnExtensions2 = [
    { columnName: "nameProsp", width: "320px" },
    { columnName: "status", width: "150px" },
    { columnName: "companyEmail", width: "230px" },
    { columnName: "phone", width: "300px" },
    { columnName: "company" },
    { columnName: "market", width: "180px" },
    { columnName: "type", width: "180px" },
    { columnName: "econoActi", width: "120px" },
    { columnName: "origen", width: "160px" },
    { columnName: "user", width: "150px" },
    { columnName: "ambassador", width: "150px" },
    { columnName: "creationDate", width: "150px" },
    { columnName: "amountProp", width: "150px" },
];

const tableColumnExtensions1 = [
    { columnName: "reference" },
    { columnName: "status" },
    { columnName: "nameProsp", width: "10%" },
    { columnName: "company", width: "10%" },
    { columnName: "service_name" },
    { columnName: "project_name" },
    { columnName: "additionals", align: "center", width: "10%" },
    { columnName: "version", align: "center" },
    { columnName: "hours", align: "center" },
    { columnName: "discount", align: "center" },
    { columnName: "fee", align: "right" },
    { columnName: "pay_type", align: "center" },
    { columnName: "user" },
];

const tableColumnExtensions = [
    { columnName: "reference" },
    { columnName: "status" },
    { columnName: "creationDate", align: "center" },
    { columnName: "sendDate", align: "center" },
    { columnName: "nameProsp", width: "10%" },
    { columnName: "company", width: "10%" },
    { columnName: "companyEmail" },
    { columnName: "ambassador" },
    { columnName: "services", width: "10%" },
    { columnName: "monthlyHonor", align: "right" },
    { columnName: "oneFeeHonor", align: "right" },
    { columnName: "user" },
];

const rowsSkeleton = [];
const rowsSkeleton1 = [];
const rowsSkeleton2 = [];

for (let i = 0; i < 10; i++) {
    rowsSkeleton2.push({
        id: i,
        nameProsp: <Skeleton variant="text" animation="wave" />,
        status: <Skeleton variant="text" animation="wave" />,
        companyEmail: <Skeleton variant="text" animation="wave" />,
        phone: <Skeleton variant="text" animation="wave" />,
        company: <Skeleton variant="text" animation="wave" />,
        market: <Skeleton variant="text" animation="wave" />,
        type: <Skeleton variant="text" animation="wave" />,
        econoActi: <Skeleton variant="text" animation="wave" />,
        origen: <Skeleton variant="text" animation="wave" />,
        user: <Skeleton variant="text" animation="wave" />,
        ambassador: <Skeleton variant="text" animation="wave" />,
        creationDate: <Skeleton variant="text" animation="wave" />,
        amountProp: <Skeleton variant="text" animation="wave" />,
    });

    rowsSkeleton1.push({
        id: i,
        reference: <Skeleton variant="text" animation="wave" />,
        status: <Skeleton variant="text" animation="wave" />,
        nameProsp: <Skeleton variant="text" animation="wave" />,
        company: <Skeleton variant="text" animation="wave" />,
        service_name: <Skeleton variant="text" animation="wave" />,
        project_name: <Skeleton variant="text" animation="wave" />,
        additionals: <Skeleton variant="text" animation="wave" />,
        version: <Skeleton variant="text" animation="wave" />,
        hours: <Skeleton variant="text" animation="wave" />,
        discount: <Skeleton variant="text" animation="wave" />,
        fee: <Skeleton variant="text" animation="wave" />,
        pay_type: <Skeleton variant="text" animation="wave" />,
        user: <Skeleton variant="text" animation="wave" />,
    });

    rowsSkeleton.push({
        id: i,
        reference: <Skeleton variant="text" animation="wave" />,
        status: <Skeleton variant="text" animation="wave" />,
        creationDate: <Skeleton variant="text" animation="wave" />,
        sendDate: <Skeleton variant="text" animation="wave" />,
        nameProsp: <Skeleton variant="text" animation="wave" />,
        company: <Skeleton variant="text" animation="wave" />,
        companyEmail: <Skeleton variant="text" animation="wave" />,
        ambassador: <Skeleton variant="text" animation="wave" />,
        services: <Skeleton variant="text" animation="wave" />,
        monthlyHonor: <Skeleton variant="text" animation="wave" />,
        oneFeeHonor: <Skeleton variant="text" animation="wave" />,
        user: <Skeleton variant="text" animation="wave" />,
    });
}

const Proposals = (props) => {
    const { reportSelected, setReportSelected } = props;
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    /** React redux */
    const todos = useSelector((state) => state.todos);

    const tableMessages = {
        noData: t("miscellaneous.noDataReports"),
    };
    const pagingMessages = {
        info: ({ from, to, count }) => `${from}${from < to ? `-${to}` : ""} ${t("miscellaneous.pagingOf")} ${count}`,
    };

    const searchMessages = {
        searchPlaceholder: t("miscellaneous.search"),
    };

    /** Component states */
    const [clientData, setclientData] = useState([]);
    const [columnDefs, setColumnsDefs] = useState([]);
    const [columnExt, setColumnsExt] = useState([]);
    const [searchValue, setSearchState] = useState("");
    const controllerRef = useRef(null);

    /** Component functions */
    const handleSearch = (value) => {
        let itemS = value.replace(/ +(?= )/g, "");
        setSearchState(itemS);
    };

    const reportTypeList = [
        { id: 0, data: t("invoicing.summarized") },
        { id: 1, data: t("invoicing.detailed") },
        { id: 2, data: t("proposals.prospects") },
    ];

    const handleChangeReportType = (event) => {
        event.preventDefault();
        setReportSelected(event.target.value);
    };

    const loadInitData = async () => {
        if (controllerRef.current) {
            controllerRef.current.abort();
        }
        const controller = new AbortController();
        controllerRef.current = controller;

        switch (reportSelected) {
            // Obtiene los datos para la tabla resumida
            case 0:
                await fetch(`/getProposalReportInfo`, {
                    method: "GET",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                    },
                    signal: controllerRef.current.signal,
                })
                    .then((res) => res.json())
                    .then((data) => {
                        const resultTable = data.map((data, index) => {
                            return {
                                id: data._id,
                                reference: data.reference,
                                status: data.status,
                                creationDate: data.creation_date ? (
                                    moment(data.creation_date).format(todos.dateFormat)
                                ) : (
                                    <Typography sx={{ textAlign: "center" }}>-</Typography>
                                ),
                                sendDate: data.delivery_date ? (
                                    moment(data.delivery_date).format(todos.dateFormat)
                                ) : (
                                    <Typography sx={{ textAlign: "center" }}>-</Typography>
                                ),
                                nameProsp: data.prospect_name,
                                company: data.company_name ?? <Typography sx={{ textAlign: "center" }}>-</Typography>,
                                companyEmail: data.prospect_email ?? (
                                    <Typography sx={{ textAlign: "center" }}>-</Typography>
                                ),
                                ambassador: data.ambassador_name ?? (
                                    <Typography sx={{ textAlign: "center" }}>-</Typography>
                                ),
                                services: data.services ?? <Typography sx={{ textAlign: "center" }}>-</Typography>,
                                monthlyHonor: `$${parseFloat(data.monthlyHonor ?? 0).toLocaleString(
                                    todos.amountFormat,
                                    {
                                        maximumFractionDigits: 2,
                                    }
                                )}`,
                                oneFeeHonor: `$${parseFloat(data.oneFeeHonor ?? 0).toLocaleString(todos.amountFormat, {
                                    maximumFractionDigits: 2,
                                })}`,
                                user: data.user,
                            };
                        });
                        setclientData(resultTable);
                        controllerRef.current = null;
                    });
                break;
            case 1:
                // Obtiene los datos para la tabla detallada
                await fetch(`/getProposalReportDetailInfo`, {
                    method: "GET",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                    },
                    signal: controllerRef.current.signal,
                })
                    .then((res) => res.json())
                    .then((data) => {
                        const resultTable = data.map((data, index) => {
                            return {
                                id: index,
                                reference: data.reference,
                                status: data.status,
                                nameProsp: data.prospect_name,
                                company: data.company_name ?? <Typography sx={{ textAlign: "center" }}>-</Typography>,
                                service_name: data.service_name,
                                project_name: data.project_name,
                                additionals: data.additionals ?? (
                                    <Typography sx={{ textAlign: "center" }}>-</Typography>
                                ),
                                version: data.version ?? <Typography sx={{ textAlign: "center" }}>-</Typography>,
                                hours: data.consulting_hours ?? <Typography sx={{ textAlign: "center" }}>-</Typography>,
                                discount: `${data.discount}%`,
                                fee: `$${parseFloat(data.fee).toLocaleString(todos.amountFormat, {
                                    maximumFractionDigits: 2,
                                })}`,
                                pay_type: data.pay_type,
                                user: data.user,
                            };
                        });
                        setclientData(resultTable);
                        controllerRef.current = null;
                    });
                break;
            default:
                  // Obtiene los datos para la tabla de prospectos
                await fetch(`/getProspectReportInfo`, {
                    method: "GET",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                    },
                    signal: controllerRef.current.signal,
                })
                    .then((res) => res.json())
                    .then((data) => {
                        let resultTable = data.map((data, index) => {
                            return {
                                id: index,
                                nameProsp: data.prospect_name, //data.company_name,
                                companyEmail: data.prospect_email,
                                phone: data.prospect_phone ? (
                                    data.prospect_phone
                                ) : (
                                    <Typography sx={{ textAlign: "center" }}>-</Typography>
                                ),
                                company: data.company_name,
                                market: data.market,
                                type: data.client_type,
                                econoActi: data.social_activity,
                                origen: data.source,
                                status: data.status === 'Archivado' ? t("reports.archived") : t("miscellaneous.active"),
                                user: data.user,
                                ambassador: data.ambassador ? (
                                    data.ambassador
                                ) : (
                                    <Typography sx={{ textAlign: "center" }}>-</Typography>
                                ),
                                creationDate: data.creation_date,
                                amountProp: data.proposal_quantity,
                            };
                        });
                        setclientData(resultTable);
                        controllerRef.current = null;
                    });
                break;
        }
    };

    /* Component events */
    useEffect(() => {
        (async () => {
            const columnsTemplate2 = [
                { name: "nameProsp", title: t("proposals.name") },
                { name: "status", title: t("recurrent.status") },
                { name: "companyEmail", title: t("proposals.businessEmail") },
                { name: "phone", title: t("proposals.phone") },
                { name: "company", title: t("proposals.company") },
                { name: "market", title: t("generalDashboard.market") },
                { name: "type", title: t("proposals.type") },
                { name: "econoActi", title: t("proposals.economicActivity") },
                { name: "origen", title: t("proposals.origin") },
                { name: "user", title: t("proposals.userTableHeader") },
                { name: "ambassador", title: t("proposals.ambassador") },
                { name: "creationDate", title: t("proposals.createDate") },
                { name: "amountProp", title: t("proposals.amount") },
            ];

            const columnsTemplate1 = [
                { name: "reference", title: t("proposals.reference") },
                { name: "status", title: t("recurrent.status") },
                { name: "nameProsp", title: t("proposals.prospect") },
                { name: "company", title: t("proposals.company") },
                { name: "service_name", title: t("principal.services") },
                { name: "project_name", title: t("principal.projects") },
                { name: "additionals", title: t("services.additionals") },
                { name: "version", title: t("miscellaneous.versionSoftware") },
                { name: "hours", title: t("miscellaneous.hours") },
                { name: "discount", title: t("miscellaneous.discount") },
                { name: "fee", title: t("services.fee") },
                { name: "pay_type", title: t("services.payType") },
                { name: "user", title: t("login.userAccess") },
            ];

            const columnsTemplate = [
                { name: "reference", title: t("proposals.reference") },
                { name: "status", title: t("recurrent.status") },
                { name: "creationDate", title: t("proposals.createDate") },
                { name: "sendDate", title: t("proposals.dateSent") },
                { name: "nameProsp", title: t("proposals.prospect") },
                { name: "company", title: t("proposals.company") },
                { name: "companyEmail", title: t("proposals.businessEmail") },
                { name: "ambassador", title: t("proposals.ambassador") },
                { name: "services", title: t("proposals.services") },
                { name: "monthlyHonor", title: t("proposals.monthlyFee") },
                { name: "oneFeeHonor", title: t("proposals.fee") },
                { name: "user", title: t("login.userAccess") },
            ];

            switch (reportSelected) {
                case 0:
                    //Propuestas
                    setclientData(rowsSkeleton);
                    setColumnsDefs(columnsTemplate);
                    setColumnsExt(tableColumnExtensions);
                    break;
                case 1:
                    //Propuestas detalladas
                    setclientData(rowsSkeleton1);
                    setColumnsDefs(columnsTemplate1);
                    setColumnsExt(tableColumnExtensions1);
                    break;
                default:
                    //Prospectos
                    setclientData(rowsSkeleton2);
                    setColumnsDefs(columnsTemplate2);
                    setColumnsExt(tableColumnExtensions2);
                    break;
            }

            await loadInitData();
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t, reportSelected]);

    return (
        <StyledContainer component="main" sx={{ flexGrow: 1, width: "100%" }}>
            <GridUI container justifyContent="left" alignItems="center">
                <GridUI item xs={12} sm={6} md={2} sx={{ mb: { sm: -5 }, zIndex: 1 }}>
                    <FormControl fullWidth>
                        <InputLabel shrink id="type-simple-select-label">
                            {t("invoicing.reportType")}
                        </InputLabel>
                        <Select
                            size="small"
                            IconComponent={KeyboardArrowDownIcon}
                            value={reportSelected}
                            onChange={handleChangeReportType}
                            input={<OutlinedInput notched label={t("invoicing.reportType")} />}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        mt: 0.7,
                                        borderRadius: 2,
                                        boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                    },
                                },
                            }}
                        >
                            {reportTypeList.map((report) => (
                                <MenuItem value={report.id}>{report.data}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </GridUI>
                <GridUI item xs={12}>
                    <Grid rows={clientData} columns={columnDefs}>
                        <SearchState value={searchValue} onValueChange={handleSearch} />
                        <IntegratedFiltering />
                        <PagingState defaultCurrentPage={0} pageSize={10} />
                        <IntegratedPaging />
                        <Table
                            tableComponent={TableComponent}
                            columnExtensions={columnExt}
                            cellComponent={TableCellComponent}
                            messages={tableMessages}
                        />
                        <TableHeaderRow cellComponent={tableHeaderCell} contentComponent={tableContentComponent} />
                        <Toolbar rootComponent={toolRootComponent} />
                        <SearchPanel messages={searchMessages} inputComponent={inputComponent} />
                        <PagingPanel containerComponent={PagingPanelContainer} messages={pagingMessages} />
                    </Grid>
                </GridUI>
            </GridUI>
        </StyledContainer>
    );
};

export default Proposals;
