/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import moment from "moment";

/** MUI icons */
import AttachFileIcon from "@mui/icons-material/AttachFile";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DownloadIcon from "@mui/icons-material/Download";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

/** MUI imports */
import {
    Grid,
    Table,
    TableHeaderRow,
    PagingPanel,
    Toolbar,
    SearchPanel,
    TableColumnVisibility,
} from "@devexpress/dx-react-grid-material-ui";
import { SearchState, IntegratedFiltering, PagingState, IntegratedPaging } from "@devexpress/dx-react-grid";
import { styled } from "@mui/material/styles";
import GridUI from "@mui/material/Grid";
import {
    Box,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
    Typography,
    Skeleton,
    Button,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Checkbox,
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

/** Components imports */
import SnackBar from "../SnackBar";
import ModInvoice from "./ModInvoice";
import DownloadDialog from "../Dialog";
import Services from "../../Js/servicesInvoicing";
import Projects from "../../Js/projectsInvoicing";

const PREFIX = "InVoided";

const classes = {
    paper: `${PREFIX}-paper`,
    tableStriped: `${PREFIX}-tableStriped`,
    tableHeader: `${PREFIX}-tableHeader`,
    tableBody: `${PREFIX}-tableBody`,
    pager: `${PREFIX}-pager`,
    cardContentRoot: `${PREFIX}-cardContentRoot`,
    cardContentRoot2: `${PREFIX}-cardContentRoot2`,
    cardHeaderRoot: `${PREFIX}-cardHeaderRoot`,
    formRounded: `${PREFIX}-formRounded`,
    formControlRounded: `${PREFIX}-formControlRounded`,
    spacing: `${PREFIX}-spacing`,
    toolbarSearch: `${PREFIX}-toolbarSearch`,
    searchInput: `${PREFIX}-searchInput`,
    invoiceCel: `${PREFIX}-invoiceCel`,
};

const projectsFiltered = Projects.filter(
    (service) =>
        service.code !== 6 &&
        service.code !== 11 &&
        service.code !== 16 &&
        service.code !== 20 &&
        service.code !== 27 &&
        service.code !== 30 &&
        service.code !== 33
);

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.paper}`]: {
        border: "2px solid #ff5968",
        borderRadius: 40,
        boxShadow: "none",
    },

    [`& .${classes.cardContentRoot}`]: {
        "&:last-child": {
            paddingBottom: "5px",
        },
        padding: "10px",
    },

    [`& .${classes.cardContentRoot2}`]: {
        "&:last-child": {
            paddingBottom: "18px",
        },
        padding: 30,
    },

    [`& .${classes.cardHeaderRoot}`]: {
        padding: 30,
    },

    [`& .${classes.formRounded}`]: {
        backgroundColor: "#FFFFFF !important",
        borderRadius: 40,
        verticalAlign: "middle !important",
        "&:focus-within": {
            backgroundColor: "#FFFFFF",
            borderRadius: 40,
        },
        "& .MuiOutlinedInput-notchedOutline": {
            border: "2px solid #031851",
            borderRadius: 40,
        },
    },

    [`& .${classes.formControlRounded}`]: {
        backgroundColor: "#FFFFFF !important",
        borderRadius: 40,
        border: "2px solid #031851",
        verticalAlign: "middle !important",
    },

    [`& .${classes.spacing}`]: {
        padding: "30px 0 20px 0",
    },

    [`& .${classes.tableStriped}`]: {
        "& tbody tr:nth-of-type(odd)": {
            backgroundColor: "rgba(47,49,144,0.1)",
        },
    },
    [`& .${classes.tableHeader}`]: {
        border: "none ",
        backgroundColor: "rgba(47,49,144,0.3)",
        fontSize: "15px",
        fontWeight: 600,
        color: "#031851",
        "&:first-of-type": {
            borderRadius: "20px 0 0 0",
        },
        "&:last-child": {
            borderRadius: "0 20px 0 0",
            paddingRight: "24px",
        },
        "& > .TableSelectAllCell-checkbox": {
            display: "none !important",
        },
    },

    [`& .${classes.tableBody}`]: {
        border: "none",
        fontSize: "14px",
        fontWeight: 400,
        color: "#031851",
    },

    [`& .${classes.pager}`]: {
        "& .Pagination-button": {
            paddingTop: 0,
            paddingBottom: 0,
            color: "#031851",
            fontWeight: 600,
        },
        "& .Pagination-activeButton": {
            backgroundColor: "rgba(47,49,144,0.3)",
        },
    },

    [`& .${classes.toolbarSearch}`]: {
        border: "none !important",
        padding: "0 0 5px !important",
    },

    [`& .${classes.searchInput}`]: {
        color: "#031851",
        "&:before": {
            borderBottomColor: "#031851",
        },
        "&:hover:before": {
            borderBottomColor: "#031851 !important",
        },
    },

    [`& .${classes.invoiceCel}`]: {
        color: "#2f3190",
        cursor: "pointer",
        padding: "7px",
        borderRadius: "6px",
        "&:hover": {
            borderRadius: "6px",
            backgroundColor: "rgba(47,49,144,0.3)",
        },
    },
}));

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "rgba(19, 31, 62, 0.80)",
        fontSize: 12,
        maxWidth: 320,
        borderRadius: 6,
        fontWeight: "normal",
    },
});

const tableHeaderCell = ({ ...props }) => <TableHeaderRow.Cell {...props} className={classes.tableHeader} />;

const TableComponent = ({ ...props }) => <Table.Table {...props} className={classes.tableStriped} />;

const TableCellComponent = ({ ...props }) => <Table.Cell {...props} className={classes.tableBody} />;

const tableContentComponent = (props) => <TableHeaderRow.Content {...props} />;

const PagingPanelContainer = ({ ...props }) => <PagingPanel.Container {...props} className={classes.pager} />;

const inputComponent = ({ ...props }) => <SearchPanel.Input {...props} className={classes.searchInput} />;

const toolRootComponent = ({ ...props }) => <Toolbar.Root {...props} className={classes.toolbarSearch} />;

const tableColumnExtensions = [
    { columnName: "invoice" },
    { columnName: "contact", width: "20%" },
    { columnName: "initial", align: "center" },
    { columnName: "amount", align: "right" },
    { columnName: "voidDate", align: "center" },
    { columnName: "status", align: "center" },
    { columnName: "attached", align: "center" },
];

const rowsSkeleton = [];

for (let i = 0; i < 10; i++) {
    rowsSkeleton.push({
        id: i,
        invoice: <Skeleton variant="text" animation="wave" />,
        contact: <Skeleton variant="text" animation="wave" />,
        initial: <Skeleton variant="text" animation="wave" />,
        amount: <Skeleton variant="text" animation="wave" />,
        voidDate: <Skeleton variant="text" animation="wave" />,
        status: <Skeleton variant="text" animation="wave" />,
        attached: (
            <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />
        ),
    });
}

const ColorButton = styled(Button)(({ theme }) => ({
    border: "1px solid #ff5968",
    borderRadius: 40,
}));

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 48 * 4.5 + 8,
            boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
        },
        sx: {
            borderRadius: 2,
            mt: 0.7,
        },
    },
    variant: "menu",
    getContentAnchorEl: null,
};

const InVoided = (props) => {
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    const markets = [t("miscellaneous.all"), "Global", "LATAM", "USA", "Venezuela"];

    const tableMessages = {
        noData: t("miscellaneous.noData"),
    };
    const pagingMessages = {
        info: ({ from, to, count }) => `${from}${from < to ? `-${to}` : ""} ${t("miscellaneous.pagingOf")} ${count}`,
    };

    const noColumnsMessage = {
        noColumns: "",
    };

    const searchMessages = {
        searchPlaceholder: t("miscellaneous.search"),
    };

    const AllItem = {
        code: "all",
        name: t("miscellaneous.all"),
    };

    /** React redux */
    const todos = useSelector((state) => state.todos);

    const franqData = props.franqData;
    const groupDataProps = props.groupData;

    /** Component states */
    const [show, setShow] = useState(false);
    const [texto, setTexto] = useState("");
    const [alertType, setAutoAlertType] = useState("");
    const [groupData, setGroupData] = useState(groupDataProps);
    const [franqF, setfranqF] = useState(["all"].concat(franqData.map((item) => item.id)));
    const [groupF, setgroupF] = useState(["all"].concat(groupDataProps.map((item) => item.id)));
    const [documentTypeF, setDocumentTypeF] = useState("");
    const [clientMarket, setClientMarket] = useState(markets);
    const [sinceDateF, setSinceDateF] = useState(null);
    const [untilDateF, setUntilDateF] = useState(null);
    const [searchValue, setSearchState] = useState("");
    const [defaultHiddenColumnNames] = useState(["hiddenInvoice"]);
    const [clientsData, setClients] = useState([]);
    const [columnDefs, setColumnsDefs] = useState([]);
    const [openViewInvoice, setViewInvoice] = useState(false);
    const [dialogForm, setDialogForm] = useState(false);
    const [invoiceFiles, setInvoiceFiles] = useState([]);
    const [invoiceNumber, setInvoiceNumber] = useState("");
    const [updateTypeS, setUpdateTypeS] = useState(["all"].concat(Services.map((item) => item.code)));
    const [typeP, setTypeP] = useState(["all"].concat(projectsFiltered.map((item) => item.code)));
    const [projects, setProjects] = useState([]);
    const [lineItemsSelected, setLineItemsSelected] = useState({
        lineItems: [],
        contactName: "",
    });
    const [anyFilter, setAnyFilter] = useState(true);
    const controllerRef = useRef(null);

    /** Component functions */
    const cleanFilters = () => {
        setAnyFilter(true);
        setfranqF(["all"].concat(franqData.map((item) => item.id)));
        setgroupF(["all"].concat(groupDataProps.map((item) => item.id)));
        setGroupData(groupDataProps);
        setDocumentTypeF("");
        setClientMarket(markets);
        setTypeP(["all"].concat(projectsFiltered.map((item) => item.code)));
        setProjects([]);
        setUpdateTypeS(["all"].concat(Services.map((item) => item.code)));
        setSinceDateF(null);
        setUntilDateF(null);
    };

    const handleChangeTypeP = (event) => {
        const {
            target: { value },
        } = event;

        let final = [];

        if (value.includes("all") && !typeP.includes("all")) {
            final = ["all"].concat(projects.map((item) => item.code));
        } else if (!value.includes("all") && typeP.includes("all")) {
            final = [];
        } else {
            if (!value.includes("all") && value.length === projects.length) {
                final = ["all"].concat(projects.map((item) => item.code));
            } else {
                final =
                    typeof value === "string"
                        ? value.split(",").filter((item) => item !== "all")
                        : value.filter((item) => item !== "all");
            }
        }

        setTypeP(final);
        setAnyFilter(false);
    };

    const handleChangeDocumentType = (event) => {
        setDocumentTypeF(event.target.value);
        setAnyFilter(false);
    };

    const handleChangeUpdateTypeS = (event) => {
        const {
            target: { value },
        } = event;

        let projects_aux = [];
        let final = [];

        if (value.includes("all") && !updateTypeS.includes("all")) {
            projects_aux = projectsFiltered;
            final = ["all"].concat(Services.map((item) => item.code));
        } else if (!value.includes("all") && updateTypeS.includes("all")) {
            projects_aux = projectsFiltered;
            final = [];
        } else {
            if (!value.includes("all") && value.length === Services.length) {
                projects_aux = projectsFiltered;
                final = ["all"].concat(Services.map((item) => item.code));
            } else {
                for (let i = 0; i < value.length; i++) {
                    let concatArr = projectsFiltered.filter((item) => item.parent === value[i]);
                    projects_aux = projects_aux.concat(concatArr);
                }
                final = event.target.value.filter((item) => item !== "all");
            }
        }
        setTypeP(["all"].concat(projects_aux.map((item) => item.code)));
        setProjects(projects_aux);
        setUpdateTypeS(final);
        setAnyFilter(false);
    };

    const handleSearch = (value) => {
        let itemS = value.replace(/ +(?= )/g, "");
        setSearchState(itemS);
    };

    const closeDialog = () => {
        setInvoiceFiles([]);
        setDialogForm(false);
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setShow(false);
    };

    const handleChangeFranqF = async (event) => {
        const {
            target: { value },
        } = event;

        let final = [];

        if (value.includes("all") && !franqF.includes("all")) {
            final = ["all"].concat(franqData.map((item) => item.id));
        } else if (!value.includes("all") && franqF.includes("all")) {
            final = [];
        } else {
            if (!value.includes("all") && value.length === franqData.length) {
                final = ["all"].concat(franqData.map((item) => item.id));
            } else {
                final =
                    typeof value === "string"
                        ? value.split(",").filter((item) => item !== "all")
                        : value.filter((item) => item !== "all");
            }
        }
        setfranqF(final);
        setAnyFilter(false);

        let finalFranq = [];
        if (final.includes("all")) {
            finalFranq = [];
        } else if (final.length === 0) {
            finalFranq = ["none"];
        } else {
            finalFranq = final.filter((item) => item !== "all");
        }

        await fetch(`/getFranchiseGroups?id_franchise=${finalFranq}`, {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then((res) => res.json())
            .then((data) => {
                setgroupF(["all"].concat(data.map((item) => item.id)));
                setGroupData(data);
            });
    };

    const handleChangeGroupF = (event) => {
        const {
            target: { value },
        } = event;

        let final = [];

        if (value.includes("all") && !groupF.includes("all")) {
            final = ["all"].concat(groupData.map((item) => item.id));
        } else if (!value.includes("all") && groupF.includes("all")) {
            final = [];
        } else {
            if (!value.includes("all") && value.length === groupData.length) {
                final = ["all"].concat(groupData.map((item) => item.id));
            } else {
                final = event.target.value.filter((item) => item !== "all");
            }
        }
        setgroupF(final);
        setAnyFilter(false);
    };

    const handleChangeClientMarket = (event) => {
        const {
            target: { value },
        } = event;

        let final = [];

        if (value.includes(t("miscellaneous.all")) && !clientMarket.includes(t("miscellaneous.all"))) {
            final = markets;
        } else if (!value.includes(t("miscellaneous.all")) && clientMarket.includes(t("miscellaneous.all"))) {
            final = [];
        } else {
            if (
                !value.includes(t("miscellaneous.all")) &&
                value.length === markets.filter((item) => item !== t("miscellaneous.all")).length
            ) {
                final = markets;
            } else {
                final =
                    typeof value === "string"
                        ? value.split(",").filter((item) => item !== t("miscellaneous.all"))
                        : value.filter((item) => item !== t("miscellaneous.all"));
            }
        }
        setClientMarket(final);
        setAnyFilter(false);
    };

    const handleChangeSinceDate = (date) => {
        if (!date) {
            setSinceDateF(date);
        } else {
            setSinceDateF(moment(date).format("YYYY-MM-DD"));
        }
        setAnyFilter(false);
    };

    const handleChangeUntilDate = (date) => {
        if (!date) {
            setUntilDateF(date);
        } else {
            setUntilDateF(moment(date).format("YYYY-MM-DD"));
        }
        setAnyFilter(false);
    };

    const showDetails = async (id, name, type, total) => {
        props.setBackDrop(true);

        const resultT = await fetch(`/getInvoicesDetails?invoice_id=${id}`, {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then((res) => res.json())
            .then((data) => {
                return data;
            });

        const arrayInvoicing = {
            lineItems: resultT,
            contactName: name,
            invoiceType: type,
            totalAmount: total,
        };

        setLineItemsSelected(arrayInvoicing);
        setViewInvoice(true);

        props.setBackDrop(false);
    };

    const showModalDownload = async (id) => {
        props.setBackDrop(true);

        const resultT = await fetch(`/getUploadedFilesXero?invoice_id=${id}`, {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then((res) => res.json())
            .then((data) => {
                return data;
            });

        let filesData = resultT.map((file) => {
            return {
                invoiceID: id,
                attachmentID: file.attachmentID,
                fileName: file.fileName,
            };
        });

        setInvoiceFiles(filesData);
        setDialogForm(true);

        props.setBackDrop(false);
    };

    const loadInitData = async () => {
        if (controllerRef.current) {
            controllerRef.current.abort();
        }
        const controller = new AbortController();
        controllerRef.current = controller;

        let finalClientMarket = [];
        if (clientMarket.includes(t("miscellaneous.all"))) {
            finalClientMarket = [];
        } else if (clientMarket.length === 0) {
            finalClientMarket = ["none"];
        } else {
            finalClientMarket = clientMarket.filter((item) => item !== t("miscellaneous.all"));
        }

        let finalFranq = [];
        if (franqF.includes("all")) {
            finalFranq = [];
        } else if (franqF.length === 0) {
            finalFranq = ["none"];
        } else {
            finalFranq = franqF.filter((item) => item !== "all");
        }

        let finalGroup = [];
        if (groupF.includes("all")) {
            finalGroup = [];
        } else if (groupF.length === 0) {
            finalGroup = ["none"];
        } else {
            finalGroup = groupF.filter((item) => item !== "all");
        }

        let finalUpdateTypeS = [];
        if (updateTypeS.includes("all")) {
            finalUpdateTypeS = [];
        } else {
            finalUpdateTypeS = updateTypeS.filter((item) => item !== "all");
        }

        let finalTypeP = [];
        if (typeP.includes("all")) {
            finalTypeP = [];
        } else {
            finalTypeP = typeP.filter((item) => item !== "all");
        }

        const resultT = await fetch(
            `/getInvoicesList?status=2&franchise_id=${finalFranq}&practice_id=${finalGroup}&service_type=${finalUpdateTypeS}&project_type=${finalTypeP}&client_market=${finalClientMarket}&document_type=${documentTypeF}&init_date=${
                sinceDateF === null ? "" : sinceDateF
            }&end_date=${untilDateF === null ? "" : untilDateF}`,
            {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
                signal: controllerRef.current.signal,
            }
        )
            .then((res) => res.json())
            .then((data) => {
                controllerRef.current = null;
                return data;
            });

        let invoicesData = resultT.map((invoiceData, index) => {
            return {
                id: invoiceData._id,
                hiddenInvoice: invoiceData.invoice_number,
                invoice: (
                    <LightTooltip title={t("invoicing.invoiceDetail")} aria-label="invoice-number">
                        <Typography
                            component="span"
                            variant="h4"
                            className={classes.invoiceCel}
                            onClick={() => {
                                showDetails(
                                    invoiceData._id,
                                    invoiceData.contact_name,
                                    invoiceData.invoice_type_method,
                                    invoiceData.invoice_total_amount
                                );
                                setInvoiceNumber(invoiceData.invoice_number);
                            }}
                        >
                            {invoiceData.invoice_number}
                        </Typography>
                    </LightTooltip>
                ),
                contact: invoiceData.contact_name,
                initial: moment(invoiceData.invoice_date, "YYYY-MM-DD").format(todos.dateFormat),
                amount: parseFloat(invoiceData.invoice_total_amount).toLocaleString(todos.amountFormat, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                }),
                voidDate: moment(invoiceData.invoice_void_date, "YYYY-MM-DD").format(todos.dateFormat),
                status: t("invoicing.voided"),
                attached: (
                    <LightTooltip title={t("dialogConversions.download")} aria-label="toolUpload">
                        <IconButton aria-label="upload" onClick={() => showModalDownload(invoiceData._id)}>
                            <AttachFileIcon color="primary" />
                        </IconButton>
                    </LightTooltip>
                ),
            };
        });

        setClients(invoicesData);
    };

    const downloadFileXero = (invoiceID, attachmentID, fileName) => async () => {
        props.setBackDrop(true);

        await fetch(`/downloadFileXero?invoice_id=${invoiceID}&attachmentID=${attachmentID}&fileName=${fileName}`, {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then((res) => res.blob())
            .then((blob) => {
                const link = document.createElement("a");
                link.href = URL.createObjectURL(blob);
                link.setAttribute("download", fileName);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch((err) => {
                setTexto(t("reports.donwloadingReptError"));
                setAutoAlertType("warning");
                setShow(true);
            });

        props.setBackDrop(false);
    };

    const dialogBody =
        invoiceFiles.length !== 0 ? (
            <GridUI container spacing={2}>
                <GridUI item xs={12}>
                    <List>
                        {invoiceFiles.map(({ invoiceID, attachmentID, fileName }) => (
                            <ListItem
                                disablePadding
                                secondaryAction={
                                    <Box>
                                        <LightTooltip
                                            title={t("dialogConversions.download")}
                                            aria-label="tooltip-downloadFileXero"
                                        >
                                            <IconButton
                                                edge="end"
                                                aria-label="downloadFileXero"
                                                onClick={downloadFileXero(invoiceID, attachmentID, fileName)}
                                                sx={{ mr: 1 }}
                                            >
                                                <DownloadIcon fontSize="medium" color="primary" />
                                            </IconButton>
                                        </LightTooltip>
                                    </Box>
                                }
                                sx={{ height: 50 }}
                            >
                                <ListItemIcon sx={{ minWidth: "20px" }}>
                                    <DescriptionOutlinedIcon fontSize="large" color="primary" sx={{ mr: 1 }} />
                                </ListItemIcon>
                                <ListItemText
                                    key={0}
                                    primary={fileName}
                                    primaryTypographyProps={{
                                        width: "80%",
                                    }}
                                />
                            </ListItem>
                        ))}
                    </List>
                </GridUI>
            </GridUI>
        ) : (
            <GridUI container justifyContent="center" spacing={2}>
                <GridUI item>
                    <Typography variant="h1">{t("invoicing.withoutFiles")}</Typography>
                </GridUI>
            </GridUI>
        );

    const dialogButtons = (
        <GridUI container justifyContent="space-between" alignItems="center" spacing={2}>
            <GridUI item>
                <ColorButton
                    onClick={() => setDialogForm(false)}
                    sx={{
                        mr: 1,
                        "&:hover": {
                            backgroundColor: "white",
                            color: "#2f3190",
                            border: "1px solid #2f3190",
                        },
                    }}
                    color="secondary"
                    disableElevation
                    variant="outlined"
                >
                    {t("miscellaneous.cancel")}
                </ColorButton>
            </GridUI>
        </GridUI>
    );

    /* Component events */
    useEffect(() => {
        setProjects(projectsFiltered);
    }, []);

    useEffect(() => {
        (async () => {
            const columnsTemplate = [
                { name: "hiddenInvoice", title: "Hidden Invoice" },
                { name: "invoice", title: t("invoicing.number") },
                { name: "contact", title: t("invoicing.invoicingContact") },
                { name: "initial", title: t("invoicing.creationDate") },
                { name: "amount", title: t("invoicing.amount") },
                { name: "voidDate", title: t("invoicing.cancellationDate") },
                { name: "status", title: t("miscellaneous.status") },
                { name: "attached", title: t("invoicing.attached") },
            ];
            setColumnsDefs(columnsTemplate);
            setClients(rowsSkeleton);

            await loadInitData();
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        t,
        todos.dateFormat,
        todos.amountFormat,
        franqF,
        groupF,
        updateTypeS,
        typeP,
        clientMarket,
        documentTypeF,
        sinceDateF,
        untilDateF,
    ]);

    return (
        <StyledContainer component="main" sx={{ flexGrow: 1, width: "100%" }}>
            <GridUI container spacing={2} className={classes.spacing}>
                <GridUI item xs={12} md={4} xl={2}>
                    <FormControl
                        fullWidth
                        classes={{
                            root: classes.formRounded,
                        }}
                    >
                        <InputLabel shrink id="franq-simple-select-label">
                            {t("miscellaneous.franchise")}
                        </InputLabel>
                        <Select
                            size="small"
                            labelId="franq-multiple-select-label"
                            IconComponent={KeyboardArrowDownIcon}
                            value={franqF}
                            displayEmpty
                            onChange={handleChangeFranqF}
                            className="ddlGeneral"
                            multiple
                            input={
                                <OutlinedInput
                                    notched
                                    label={t("miscellaneous.franchise")}
                                    sx={{
                                        fontSize: 16,
                                        fontWeight: 600,
                                    }}
                                />
                            }
                            renderValue={(selected) => {
                                if (selected.length === 0) {
                                    return <span>{t("miscellaneous.none")}</span>;
                                }
                                return selected.includes("all")
                                    ? t("miscellaneous.all")
                                    : selected
                                          .map((item) => {
                                              return franqData.filter((item2) => item2.id === item)[0].franchise_name;
                                          })
                                          .join(", ");
                            }}
                            MenuProps={MenuProps}
                        >
                            {[
                                {
                                    id: "all",
                                    franchise_name: t("miscellaneous.all"),
                                },
                            ]
                                .concat(franqData)
                                .map((value) => (
                                    <MenuItem value={value.id}>
                                        <Checkbox
                                            defaultChecked
                                            checked={franqF.indexOf(value.id) > -1}
                                            icon={
                                                value.id === "all" && !franqF.includes("all") && franqF.length > 0 ? (
                                                    <IndeterminateCheckBoxIcon style={{ fill: "#031851" }} />
                                                ) : (
                                                    <CheckBoxOutlineBlankIcon />
                                                )
                                            }
                                        />
                                        <ListItemText primary={value.franchise_name} />
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                </GridUI>
                <GridUI item xs={12} md={4} xl={2}>
                    <FormControl
                        fullWidth
                        classes={{
                            root: classes.formRounded,
                        }}
                    >
                        <InputLabel shrink id="group-simple-select-label">
                            {t("miscellaneous.group")}
                        </InputLabel>
                        <Select
                            size="small"
                            labelId="group-simple-select-label"
                            IconComponent={KeyboardArrowDownIcon}
                            value={groupF}
                            displayEmpty
                            onChange={handleChangeGroupF}
                            className="ddlGeneral"
                            multiple
                            input={
                                <OutlinedInput
                                    notched
                                    label={t("miscellaneous.group")}
                                    sx={{
                                        fontSize: 16,
                                        fontWeight: 600,
                                    }}
                                />
                            }
                            renderValue={(selected) => {
                                if (selected.length === 0) {
                                    return <span>{t("miscellaneous.none")}</span>;
                                }
                                return selected.includes("all")
                                    ? t("miscellaneous.all")
                                    : selected
                                          .map((item) => {
                                              return groupDataProps.filter((item2) => item2.id === item)[0].group_name;
                                          })
                                          .join(", ");
                            }}
                            MenuProps={MenuProps}
                        >
                            {[
                                {
                                    id: "all",
                                    group_name: t("miscellaneous.all"),
                                },
                            ]
                                .concat(groupData)
                                .map((value) => (
                                    <MenuItem value={value.id}>
                                        <Checkbox
                                            defaultChecked
                                            checked={groupF.indexOf(value.id) > -1}
                                            icon={
                                                value.id === "all" && !groupF.includes("all") && groupF.length > 0 ? (
                                                    <IndeterminateCheckBoxIcon style={{ fill: "#031851" }} />
                                                ) : (
                                                    <CheckBoxOutlineBlankIcon />
                                                )
                                            }
                                        />
                                        <ListItemText primary={value.group_name} />
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                </GridUI>
                <GridUI item xs={12} md={4} xl={2}>
                    <FormControl
                        fullWidth
                        classes={{
                            root: classes.formRounded,
                        }}
                    >
                        <InputLabel shrink id="document-type-simple-select-label">
                            {t("invoicing.documentType")}
                        </InputLabel>
                        <Select
                            size="small"
                            IconComponent={KeyboardArrowDownIcon}
                            value={documentTypeF}
                            displayEmpty
                            onChange={handleChangeDocumentType}
                            className="ddlGeneral"
                            input={<OutlinedInput notched label={t("invoicing.documentType")} />}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        mt: 0.7,
                                        borderRadius: 2,
                                        boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                    },
                                },
                            }}
                        >
                            <MenuItem value="">
                                <Typography variant="body1" component="span" align="center" color="textPrimary">
                                    {t("miscellaneous.all")}
                                </Typography>
                            </MenuItem>
                            <MenuItem value={1}>
                                <Typography variant="body1" component="span" align="center" color="textPrimary">
                                    {t("miscellaneous.invoice")}
                                </Typography>
                            </MenuItem>
                            <MenuItem value={2}>
                                <Typography variant="body1" component="span" align="center" color="textPrimary">
                                    {t("invoicing.creditNote")}
                                </Typography>
                            </MenuItem>
                        </Select>
                    </FormControl>
                </GridUI>
                <GridUI item xs={12} md={4} xl={1}>
                    <FormControl
                        fullWidth
                        classes={{
                            root: classes.formRounded,
                        }}
                        size="small"
                    >
                        <InputLabel id="market-multiple-select-label" shrink>
                            {t("miscellaneous.market")}
                        </InputLabel>
                        <Select
                            size="small"
                            labelId="market-multiple-select-label"
                            IconComponent={KeyboardArrowDownIcon}
                            displayEmpty
                            value={clientMarket}
                            onChange={handleChangeClientMarket}
                            multiple
                            input={
                                <OutlinedInput
                                    notched
                                    label={t("miscellaneous.market")}
                                    sx={{
                                        fontSize: 16,
                                        fontWeight: 600,
                                    }}
                                />
                            }
                            renderValue={(selected) => {
                                if (selected.length === 0) {
                                    return <span>{t("miscellaneous.none")}</span>;
                                }

                                return selected.includes(t("miscellaneous.all"))
                                    ? t("miscellaneous.all")
                                    : selected.join(", ");
                            }}
                            MenuProps={MenuProps}
                        >
                            {markets.map((name) => (
                                <MenuItem value={name}>
                                    <Checkbox
                                        checked={clientMarket.indexOf(name) > -1}
                                        icon={
                                            name === t("miscellaneous.all") &&
                                            !clientMarket.includes(t("miscellaneous.all")) &&
                                            clientMarket.length > 0 ? (
                                                <IndeterminateCheckBoxIcon style={{ fill: "#031851" }} />
                                            ) : (
                                                <CheckBoxOutlineBlankIcon />
                                            )
                                        }
                                    />
                                    <ListItemText primary={name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </GridUI>
                <GridUI item xs={12} md={4} xl={1}>
                    <FormControl
                        fullWidth
                        classes={{
                            root: classes.formRounded,
                        }}
                    >
                        <InputLabel shrink id="updateTypeF-simple-select-label">
                            {t("miscellaneous.service")}
                        </InputLabel>
                        <Select
                            size="small"
                            labelId="updateTypeF-simple-select-label"
                            IconComponent={KeyboardArrowDownIcon}
                            displayEmpty
                            value={updateTypeS}
                            className="ddlGeneral"
                            onChange={handleChangeUpdateTypeS}
                            multiple
                            input={
                                <OutlinedInput
                                    notched
                                    label={t("miscellaneous.service")}
                                    sx={{
                                        fontSize: 16,
                                        fontWeight: 600,
                                    }}
                                />
                            }
                            renderValue={(selected) => {
                                if (selected.length === 0) {
                                    return <span>{t("miscellaneous.all")}</span>;
                                }
                                return selected.includes("all")
                                    ? t("miscellaneous.all")
                                    : selected
                                          .map((item) => {
                                              return t(
                                                  `invoicing.${Services.filter((item2) => item2.code === item)[0].name}`
                                              );
                                          })
                                          .join(", ");
                            }}
                            MenuProps={MenuProps}
                        >
                            {[AllItem].concat(Services).map((value) => (
                                <MenuItem value={value.code}>
                                    <Checkbox
                                        checked={updateTypeS.indexOf(value.code) > -1}
                                        icon={
                                            value.code === "all" &&
                                            !updateTypeS.includes("all") &&
                                            updateTypeS.length > 0 ? (
                                                <IndeterminateCheckBoxIcon style={{ fill: "#031851" }} />
                                            ) : (
                                                <CheckBoxOutlineBlankIcon />
                                            )
                                        }
                                    />
                                    <ListItemText
                                        primary={
                                            value.code === "all" ? t("miscellaneous.all") : t(`invoicing.${value.name}`)
                                        }
                                    />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </GridUI>
                <GridUI item xs={12} md={4} xl={1}>
                    <FormControl
                        fullWidth
                        classes={{
                            root: classes.formRounded,
                        }}
                    >
                        <InputLabel shrink id="updateTypeF-simple-select-label">
                            {t("invoicing.project")}
                        </InputLabel>
                        <Select
                            size="small"
                            labelId="updateTypeP-simple-select-label"
                            IconComponent={KeyboardArrowDownIcon}
                            displayEmpty
                            value={typeP}
                            className="ddlGeneral"
                            onChange={handleChangeTypeP}
                            multiple
                            input={
                                <OutlinedInput
                                    notched
                                    label={t("invoicing.project")}
                                    sx={{
                                        fontSize: 16,
                                        fontWeight: 600,
                                    }}
                                />
                            }
                            renderValue={(selected) => {
                                if (selected.length === 0) {
                                    return <span>{t("miscellaneous.all")}</span>;
                                }
                                return selected.includes("all")
                                    ? t("miscellaneous.all")
                                    : selected
                                          .map((item) => {
                                              return t(
                                                  `invoicing.${Projects.filter((item2) => item2.code === item)[0].name}`
                                              );
                                          })
                                          .join(", ");
                            }}
                            MenuProps={MenuProps}
                        >
                            {[AllItem].concat(projects).map((value) => (
                                <MenuItem value={value.code}>
                                    <Checkbox
                                        checked={typeP.indexOf(value.code) > -1}
                                        icon={
                                            value.code === "all" && !typeP.includes("all") && typeP.length > 0 ? (
                                                <IndeterminateCheckBoxIcon style={{ fill: "#031851" }} />
                                            ) : (
                                                <CheckBoxOutlineBlankIcon />
                                            )
                                        }
                                    />
                                    <ListItemText
                                        primary={
                                            value.code === "all" ? t("miscellaneous.all") : t(`invoicing.${value.name}`)
                                        }
                                    />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </GridUI>
                <GridUI item xs={12} md={4} xl={3}>
                    <LocalizationProvider
                        dateAdapter={AdapterMoment}
                        adapterLocale={t("language.locale")}
                        localeText={{
                            clearButtonLabel: t("miscellaneous.clear"),
                        }}
                    >
                        <GridUI container>
                            <GridUI item xs={6}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: {
                                            actions: ["clear"],
                                        },
                                    }}
                                    value={sinceDateF}
                                    onChange={(newValue) => handleChangeSinceDate(newValue)}
                                    inputFormat={todos.dateFormat}
                                    renderInput={({ inputProps, ...params }) => (
                                        <TextField
                                            fullWidth
                                            {...params}
                                            inputProps={{
                                                ...inputProps,
                                                placeholder: t("invoicing.initDate"),
                                                sx: {
                                                    p: "10.45px 0px 10.45px 18px !important",
                                                },
                                            }}
                                            sx={{
                                                "& .MuiOutlinedInput-notchedOutline": {
                                                    mr: "-6px !important",
                                                    border: "2px solid #031851",
                                                    borderRadius: "40px 0px 0px 40px",
                                                },
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                    label={t("invoicing.range")}
                                />
                            </GridUI>
                            <GridUI item xs={6}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: {
                                            actions: ["clear"],
                                        },
                                    }}
                                    value={untilDateF}
                                    onChange={(newValue) => handleChangeUntilDate(newValue)}
                                    inputFormat={todos.dateFormat}
                                    renderInput={({ inputProps, ...params }) => (
                                        <TextField
                                            fullWidth
                                            {...params}
                                            inputProps={{
                                                ...inputProps,
                                                placeholder: t("invoicing.endDate"),
                                                sx: {
                                                    p: "10.45px 0px 10.45px 18px !important",
                                                },
                                            }}
                                            sx={{
                                                ml: 0.5,
                                                "& .MuiOutlinedInput-notchedOutline": {
                                                    border: "2px solid #031851",
                                                    borderRadius: "0px 40px 40px 0px",
                                                },
                                            }}
                                        />
                                    )}
                                />
                            </GridUI>
                        </GridUI>
                    </LocalizationProvider>
                </GridUI>
            </GridUI>
            <GridUI container>
                <GridUI item xs={12} md={2} sx={{ mb: { sm: -5 }, zIndex: 1 }}>
                    <Button
                        variant="text"
                        color="primary"
                        disabled={anyFilter}
                        onClick={cleanFilters}
                        sx={{
                            "&:hover": {
                                color: "#2f3190",
                            },
                        }}
                    >
                        {t("miscellaneous.cleanFilters")}
                    </Button>
                </GridUI>
                <GridUI item xs={12}>
                    <Grid rows={clientsData} columns={columnDefs}>
                        <SearchState value={searchValue} onValueChange={handleSearch} />
                        <IntegratedFiltering />
                        <PagingState defaultCurrentPage={0} pageSize={10} />
                        <IntegratedPaging />
                        <Table
                            tableComponent={TableComponent}
                            columnExtensions={tableColumnExtensions}
                            cellComponent={TableCellComponent}
                            messages={tableMessages}
                        />
                        <TableColumnVisibility
                            defaultHiddenColumnNames={defaultHiddenColumnNames}
                            messages={noColumnsMessage}
                        />
                        <TableHeaderRow cellComponent={tableHeaderCell} contentComponent={tableContentComponent} />
                        <Toolbar rootComponent={toolRootComponent} />
                        <SearchPanel messages={searchMessages} inputComponent={inputComponent} />
                        <PagingPanel containerComponent={PagingPanelContainer} messages={pagingMessages} />
                    </Grid>
                </GridUI>
            </GridUI>
            <SnackBar type={alertType} show={show} message={texto} onClose={handleClose} />
            <ModInvoice
                open={openViewInvoice}
                onClose={() => setViewInvoice(false)}
                invoiceNumber={invoiceNumber}
                lineItemsSelected={lineItemsSelected}
                isEditMode={false}
            />
            <DownloadDialog
                open={dialogForm}
                onClose={closeDialog}
                message={dialogBody}
                title={t("invoicing.downloadDocuments")}
                button={dialogButtons}
                maxWidth="sm"
            />
        </StyledContainer>
    );
};

export default InVoided;
