/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import moment from "moment";

/** MUI icons */
import AttachFileIcon from "@mui/icons-material/AttachFile";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import CommentIcon from "@mui/icons-material/Comment";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

/** MUI imports */
import {
    Grid,
    Table,
    TableHeaderRow,
    PagingPanel,
    Toolbar,
    SearchPanel,
    TableColumnVisibility,
} from "@devexpress/dx-react-grid-material-ui";
import { SearchState, IntegratedFiltering, PagingState, IntegratedPaging } from "@devexpress/dx-react-grid";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import GridUI from "@mui/material/Grid";
import {
    Box,
    Button,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Radio,
    Select,
    Typography,
    Skeleton,
    Badge,
    Checkbox,
    ListItemText,
    Grow,
    Popper,
    ClickAwayListener,
    MenuList,
    Paper,
    ButtonGroup,
} from "@mui/material";

/** Components imports */
import Backdrop from "../../Backdrop";
import DialogAlerts from "../../DialogAlerts";
import SnackBar from "../../SnackBar";
import UploadFiles from "../../UploadFiles";
import CommentDialog from "../../CommentDialog";
import ModalRep from "../ModalRep";

/** Global variables */
const PREFIX = "ClientsReport";

const classes = {
    paper: `${PREFIX}-paper`,
    tableStriped: `${PREFIX}-tableStriped`,
    tableHeader: `${PREFIX}-tableHeader`,
    tableBody: `${PREFIX}-tableBody`,
    toolbarSearch: `${PREFIX}-toolbarSearch`,
    tooltip: `${PREFIX}-tooltip`,
    searchInput: `${PREFIX}-searchInput`,
    pager: `${PREFIX}-pager`,
    cardContentRoot: `${PREFIX}-cardContentRoot`,
    cardContentRoot2: `${PREFIX}-cardContentRoot2`,
    cardHeaderRoot: `${PREFIX}-cardHeaderRoot`,
    formRounded: `${PREFIX}-formRounded`,
    selectPaper: `${PREFIX}-selectPaper`,
    button: `${PREFIX}-button`,
    buttonDisabled: `${PREFIX}-buttonDisabled`,
    spacing: `${PREFIX}-spacing`,
    selectIcon: `${PREFIX}-selectIcon`,
    radio: `${PREFIX}-radio`,
};

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "rgba(19, 31, 62, 0.80)",
        fontSize: 12,
        maxWidth: 320,
        borderRadius: 6,
        fontWeight: "normal",
    },
});

const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
        backgroundColor: "#2f3190",
        color: "white",
    },
}));

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.paper}`]: {
        border: "2px solid #ff5968",
        borderRadius: 40,
        boxShadow: "none",
    },

    [`& .${classes.radio}`]: {
        [theme.breakpoints.down("sm")]: {
            padding: 9,
        },
        [theme.breakpoints.between("md", "lg")]: {
            padding: 0,
        },
        [theme.breakpoints.between("lg", "xl")]: {
            padding: 6,
        },
    },

    [`& .${classes.cardContentRoot}`]: {
        "&:last-child": {
            paddingBottom: "5px",
        },
        padding: "10px",
    },

    [`& .${classes.cardContentRoot2}`]: {
        "&:last-child": {
            paddingBottom: "18px",
        },
        padding: 30,
    },

    [`& .${classes.cardHeaderRoot}`]: {
        padding: 30,
    },

    [`& .${classes.formRounded}`]: {
        backgroundColor: "#FFFFFF !important",
        borderRadius: 40,
        verticalAlign: "middle !important",
        "&:focus-within": {
            backgroundColor: "#FFFFFF",
            borderRadius: 40,
        },
        "& .MuiOutlinedInput-notchedOutline": {
            border: "2px solid #031851",
            borderRadius: 40,
        },
    },

    [`& .${classes.selectPaper}`]: {
        marginTop: 10,
        borderRadius: 20,
        boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.8)",
        "& li": {
            background: "none !important",
        },
        "& li:hover": {
            backgroundColor: "#f8f9f8 !important",
        },
    },

    [`& .${classes.button}`]: {
        borderRadius: 40,
        width: "100%",
        "&:hover": {
            backgroundColor: theme.palette.text.secondary,
            color: "#fff",
        },
    },

    [`& .${classes.buttonDisabled}`]: {
        borderRadius: 50,
        paddingLeft: 40,
        paddingRight: 40,
        backgroundColor: "#ffcdd2 !important",
        color: "#fff !important",
        [theme.breakpoints.down("md")]: {
            fontSize: 18,
        },
    },

    [`& .${classes.spacing}`]: {
        padding: "30px 0 20px 0",
    },

    [`& .${classes.selectIcon}`]: {
        [theme.breakpoints.up("xl")]: {
            height: "25px",
        },
        [theme.breakpoints.down("lg")]: {
            height: "25px",
        },
    },

    [`& .${classes.tableStriped}`]: {
        "& tbody tr:nth-of-type(odd)": {
            backgroundColor: "rgba(47,49,144,0.1)",
        },
    },
    [`& .${classes.tableHeader}`]: {
        border: "none",
        backgroundColor: "rgba(47,49,144,0.3)",
        fontSize: "15px",
        fontWeight: 600,
        color: "#031851",
        "&:first-of-type": {
            borderRadius: "20px 0 0 0",
        },
        "&:last-child": {
            borderRadius: "0 20px 0 0",
        },
    },

    [`& .${classes.tableBody}`]: {
        border: "none",
        fontSize: "14px",
        fontWeight: 400,
        color: "#031851",
    },

    [`& .${classes.toolbarSearch}`]: {
        border: "none !important",
        padding: "0 0 5px !important",
    },

    [`& .${classes.searchInput}`]: {
        color: "#031851",
        "&:before": {
            borderBottomColor: "#031851",
        },
        "&:hover:before": {
            borderBottomColor: "#031851 !important",
        },
    },

    [`& .${classes.pager}`]: {
        "& .Pagination-button": {
            paddingTop: 0,
            paddingBottom: 0,
            color: "#031851",
            fontWeight: 600,
        },
        "& .Pagination-activeButton": {
            backgroundColor: "rgba(47,49,144,0.3)",
        },
    },
}));

const tableHeaderCell = ({ ...props }) => <TableHeaderRow.Cell {...props} className={classes.tableHeader} />;

const TableComponent = ({ ...props }) => <Table.Table {...props} className={classes.tableStriped} />;

const TableCellComponent = ({ ...props }) => <Table.Cell {...props} className={classes.tableBody} />;

const tableContentComponent = (props) => <TableHeaderRow.Content {...props} />;

const PagingPanelContainer = ({ ...props }) => <PagingPanel.Container {...props} className={classes.pager} />;

const inputComponent = ({ ...props }) => <SearchPanel.Input {...props} className={classes.searchInput} />;

const toolRootComponent = ({ ...props }) => <Toolbar.Root {...props} className={classes.toolbarSearch} />;

var selectedValueCont = 0;
var resultTable = [];

const tableColumnExtensions = [
    { columnName: "client", align: "left" },
    { columnName: "reported", align: "center" },
    { columnName: "buttonClear", align: "center", width: "5%" },
    { columnName: "buttonAttach", align: "center", width: "5%" },
    { columnName: "comments", align: "center", width: "5%" },
    { columnName: "jan", align: "center", width: "5%" },
    { columnName: "feb", align: "center", width: "5%" },
    { columnName: "mar", align: "center", width: "5%" },
    { columnName: "apr", align: "center", width: "5%" },
    { columnName: "may", align: "center", width: "5%" },
    { columnName: "jun", align: "center", width: "5%" },
    { columnName: "jul", align: "center", width: "5%" },
    { columnName: "aug", align: "center", width: "5%" },
    { columnName: "sep", align: "center", width: "5%" },
    { columnName: "oct", align: "center", width: "5%" },
    { columnName: "nov", align: "center", width: "5%" },
    { columnName: "dec", align: "center", width: "5%" },
];

const rowsSkeleton = [];

for (let i = 0; i < 10; i++) {
    rowsSkeleton.push({
        id: i,
        client: <Skeleton variant="text" animation="wave" />,
        buttonClear: (
            <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />
        ),
        buttonAttach: (
            <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />
        ),
        comments: (
            <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />
        ),
        reported: <Skeleton variant="text" animation="wave" />,
        jan: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        feb: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        mar: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        apr: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        may: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        jun: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        jul: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        aug: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        sep: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        oct: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        nov: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        dec: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
    });
}

const ClientsReport = (props) => {
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    const tableMessages = {
        noData: t("miscellaneous.noData"),
    };
    const pagingMessages = {
        info: ({ from, to, count }) => `${from}${from < to ? `-${to}` : ""} ${t("miscellaneous.pagingOf")} ${count}`,
    };

    const searchMessages = {
        searchPlaceholder: t("miscellaneous.search"),
    };

    /** React redux */
    const todos = useSelector((state) => state.todos);

    const franqDataProps = props.franqData;
    const groupDataProps = props.groupData;

    const statusArray = [
        {
            id: "all",
            name: t("miscellaneous.all"),
        },
        {
            id: "7",
            name: t("recurrent.toClose"),
        },
        {
            id: "1",
            name: t("clientsTable.awaitingRequirements"),
        },
        {
            id: "2",
            name: t("recurrent.toValid"),
        },
        {
            id: "3",
            name: t("recurrent.validated"),
        },
        {
            id: "5",
            name: t("recurrent.partReport"),
        },
        {
            id: "6",
            name: t("miscellaneous.closed"),
        },
    ];

    /** Component states */
    const [clientsData, setClients] = useState([]);
    const [columnDefs, setColumnsDefs] = useState([]);
    const [showBackdrop, setBackDrop] = useState(false);
    const [selectedValue, setSelectedValue] = useState([]);
    const [yearsToNoti, setyearsToNoti] = useState([]);
    const [selectedYear, setselectedYear] = useState(new Date().getFullYear());
    const [clientT, setTClient] = useState("");
    const [franqF, setfranqF] = useState(["all"].concat(franqDataProps.map((item) => item.id)));
    const [groupF, setgroupF] = useState(["all"].concat(groupDataProps.map((item) => item.id)));
    const [clientMarket, setclientMarket] = useState(props.markets);
    const [clientStatus, setClientStatus] = useState("");
    const [idStatus, setIdStatus] = useState(statusArray.map((item) => item.id));
    const [groupData, setGroupData] = useState(groupDataProps);
    const [franqData, setFranqData] = useState(franqDataProps);
    const [filesData, setFilesData] = useState([]);
    const [idOrganisation, setIdOrganisation] = useState("");
    const [open, setOpen] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [dialogText, setDialogText] = useState("");
    const [dialogType, setDialogType] = useState("info");
    const [show, setShow] = useState(false);
    const [texto, setTexto] = useState("");
    const [alertType, seAutoAlertType] = useState("");
    const [searchValue, setSearchState] = useState("");
    const [dialogComment, setDialogComment] = useState(false);
    const [clientID, setclientID] = useState("");
    const [comments, setComments] = useState([]);
    const [commentsP, setCommentsPend] = useState([]);
    const [isValidated, setisValidated] = useState(false);
    const [anyFilter, setAnyFilter] = useState(true);
    const [openModalMails, setOpenModalMails] = useState(false);
    const [motiveDec, setMotiveDec] = useState("default");
    const [motiveDec2, setMotiveDec2] = useState("default");
    const [defaultHiddenColumnNames] = useState(["hiddenCompany"]);
    const controllerRef = useRef(null);
    const [openModalMails2, setOpenModalMails2] = useState(false);

    // Buttons group states.
    const [openBtnGroupSend, setOpenBtnGroupSend] = useState(false);
    const anchorRefBtnGroup = useRef(null);
    const [selectedIndexBtnGroup, setSelectedIndexBtnGroup] = useState(0);
    const optionsGroupSendInitialState = [
        { data: t("miscellaneous.report"), disable: false, id: 0, tooltip: t("miscellaneous.sendFinancialStatements") },
        {
            data: t("miscellaneous.expressService"),
            disable: true,
            id: 1,
            tooltip: t("miscellaneous.generateSurchargeExpressService"),
        },
        {
            data: t("miscellaneous.reportExpressService"),
            disable: true,
            id: 2,
            tooltip: t("miscellaneous.sendFinancialStatementsAndGenerateSurcharge"),
        },
    ];
    const [optionsGroupSend, setOptionsGroupSend] = useState([...optionsGroupSendInitialState]);
    const [isExpress, setIsExpress] = useState(false);
    const [openAlertExpressServices, setOpenAlertExpressServices] = useState(false);

    /** Component functions */
    const cleanFilters = () => {
        selectedValueCont = 0;
        setAnyFilter(true);
        setTClient("");
        setfranqF(["all"].concat(franqDataProps.map((item) => item.id)));
        setgroupF(["all"].concat(groupDataProps.map((item) => item.id)));
        setGroupData(groupDataProps);
        setIdStatus(statusArray.map((item) => item.id));
        setClientStatus("");
        setclientMarket(props.markets);
        setSelectedValue([]);
        setselectedYear(new Date().getFullYear());
    };

    const handleMenuItemClickBtnGroup = (event, index) => {
        setSelectedIndexBtnGroup(index);
        setOpenBtnGroupSend(false);
        switch (index) {
            case 0:
                // Reportar
                setIsExpress(false);
                handleOpenDialog();
                break;
            case 1:
                // Servicio express
                handleOpenDialogExpressService();
                break;
            case 2:
                // Reportar y servicio express
                setIsExpress(true);
                handleOpenDialogReportAndExpress();
                break;
            default:
                break;
        }
    };

    const handleCloseBtnGroup = (event) => {
        if (anchorRefBtnGroup.current && anchorRefBtnGroup.current.contains(event.target)) {
            return;
        }
        setOpenBtnGroupSend(false);
    };

    const handleToggleBtnGroup = () => {
        setOpenBtnGroupSend((prevOpenBtnGroupSend) => !prevOpenBtnGroupSend);
    };

    const handleSearch = (value) => {
        let itemS = value.replace(/ +(?= )/g, "");
        setSearchState(itemS);
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setShow(false);
    };

    const handleOpenDialog = () => {
        let isReported = false;

        selectedValue.forEach((item) => {
            if (item) {
                if (item.notifyNumber === 5 || item.notifyNumber === 6) {
                    isReported = true;
                }
                if (
                    (item.notifyNumber === 3 || item.notifyNumber === 4) &&
                    item.month !== moment().subtract(1, "months").format("MM")
                ) {
                    isReported = true;
                }
            }
        });

        if (!isReported) {
            if (selectedValueCont > 0) {
                setDialogType("info");
                if (selectedValueCont === 1) {
                    setDialogText(t("recurrent.newmessageModal1part4"));
                } else {
                    setDialogText(
                        t("recurrent.newmessageModal3") + selectedValueCont + t("recurrent.newmessageModal3part2")
                    );
                }
                setOpenAlert(true);
            } else {
                setDialogType("info");
                if (resultTable.pendingCont === 1) {
                    setDialogText(t("recurrent.newmessageModal2part4"));
                } else {
                    setDialogText(
                        t("recurrent.newmessageModal3") + resultTable.pendingCont + t("recurrent.newmessageModal4part2")
                    );
                }
                setOpenAlert(true);
            }
        } else {
            setOpenModalMails(true);
        }
    };

    const closeDialog = () => {
        setDialogComment(false);
    };

    const handleCloseDialog = () => {
        setDialogText("");
        setDialogType("");
        setOpenAlert(false);
        setOpenModalMails(false);
        setOpenModalMails2(false);
        setMotiveDec("default");
        setMotiveDec2("default");
    };

    const handleCloseDialogExpressService = () => {
        setDialogText("");
        setDialogType("");
        setOpenAlertExpressServices(false);
    };

    const handleOpenDialogExpressService = () => {
        if (selectedValueCont > 0) {
            setDialogType("info");
            if (selectedValueCont === 1) {
                setDialogText(t("recurrent.newExpressService"));
            } else {
                setDialogText(
                    t("recurrent.newExpressServiceMultipleClients") +
                        selectedValueCont +
                        t("recurrent.newmessageModal3part2")
                );
            }
            setOpenAlertExpressServices(true);
        }
    };

    const handleOpenDialogReportAndExpress = () => {
        setOpenModalMails2(true);
    };

    const handleChangeTClient = async (event) => {
        setBackDrop(true);
        selectedValueCont = 0;
        setSelectedValue([]);
        setTClient(event.target.value);
        setAnyFilter(false);

        let finalFranq = [];

        await fetch(`/getClientFranchiseFilter?client_type=${event.target.value}`, {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then((res) => res.json())
            .then((data) => {
                setFranqData(data);
                if (event.target.value === "") {
                    finalFranq = [];
                } else {
                    finalFranq = data.map((item) => item.id);
                }
                setfranqF(["all"].concat(data.map((item) => item.id)));
            });

        await fetch(`/getFranchiseGroups?id_franchise=${finalFranq}`, {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then((res) => res.json())
            .then((data) => {
                setGroupData(data);
                setgroupF(["all"].concat(data.map((item) => item.id)));
            });
        setBackDrop(false);
    };

    const handleChangeFranqF = async (event) => {
        setBackDrop(true);
        selectedValueCont = 0;
        setSelectedValue([]);

        const {
            target: { value },
        } = event;

        let final = [];

        if (value.includes("all") && !franqF.includes("all")) {
            final = ["all"].concat(franqData.map((item) => item.id));
        } else if (!value.includes("all") && franqF.includes("all")) {
            final = [];
        } else {
            if (!value.includes("all") && value.length === franqData.length) {
                final = ["all"].concat(franqData.map((item) => item.id));
            } else {
                final =
                    typeof value === "string"
                        ? value.split(",").filter((item) => item !== "all")
                        : value.filter((item) => item !== "all");
            }
        }
        setfranqF(final);
        setAnyFilter(false);

        let finalFranq = [];
        if (final.includes("all")) {
            finalFranq = [];
        } else if (final.length === 0) {
            finalFranq = ["none"];
        } else {
            finalFranq = final.filter((item) => item !== "all");
        }

        await fetch(`/getFranchiseGroups?id_franchise=${finalFranq}`, {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then((res) => res.json())
            .then((data) => {
                setgroupF(["all"].concat(data.map((item) => item.id)));
                setGroupData(data);
            });

        setBackDrop(false);
    };

    const handleChangeGroupF = (event) => {
        selectedValueCont = 0;
        setSelectedValue([]);
        const {
            target: { value },
        } = event;

        let final = [];

        if (value.includes("all") && !groupF.includes("all")) {
            final = ["all"].concat(groupDataProps.map((item) => item.id));
        } else if (!value.includes("all") && groupF.includes("all")) {
            final = [];
        } else {
            if (!value.includes("all") && value.length === groupData.length) {
                final = ["all"].concat(groupData.map((item) => item.id));
            } else {
                final = event.target.value.filter((item) => item !== "all");
            }
        }
        setgroupF(final);
        setAnyFilter(false);
    };

    const handleChangeClasif = (event) => {
        selectedValueCont = 0;
        setSelectedValue([]);
        setClientStatus(event.target.value);
        setAnyFilter(false);
    };

    const handleChangeIdStatus = (event) => {
        selectedValueCont = 0;
        setSelectedValue([]);

        const {
            target: { value },
        } = event;

        let final = [];

        if (value.includes("all") && !idStatus.includes("all")) {
            final = statusArray.map((item) => item.id);
        } else if (!value.includes("all") && idStatus.includes("all")) {
            final = [];
        } else {
            if (!value.includes("all") && value.length === statusArray.filter((item) => item.id !== "all").length) {
                final = statusArray.map((item) => item.id);
            } else {
                final =
                    typeof value === "string"
                        ? value.split(",").filter((item) => item !== "all")
                        : value.filter((item) => item !== "all");
            }
        }

        setIdStatus(final);
        setAnyFilter(false);
    };

    const handleChangeclientMarket = (event) => {
        selectedValueCont = 0;
        setSelectedValue([]);
        const {
            target: { value },
        } = event;

        let final = [];

        if (value.includes(t("miscellaneous.all")) && !clientMarket.includes(t("miscellaneous.all"))) {
            final = props.markets;
        } else if (!value.includes(t("miscellaneous.all")) && clientMarket.includes(t("miscellaneous.all"))) {
            final = [];
        } else {
            if (
                !value.includes(t("miscellaneous.all")) &&
                value.length === props.markets.filter((item) => item !== t("miscellaneous.all")).length
            ) {
                final = props.markets;
            } else {
                final =
                    typeof value === "string"
                        ? value.split(",").filter((item) => item !== t("miscellaneous.all"))
                        : value.filter((item) => item !== t("miscellaneous.all"));
            }
        }
        setclientMarket(final);
        setAnyFilter(false);
    };

    const handleChange = (pos, id, event, notifyNumber, expressService) => {
        let items = [...selectedValue];

        let item = {
            ...items[pos],
            id_organisation: id,
            month: event.target.value,
            notifyNumber,
            expressService,
        };
        if (!items[pos]) {
            selectedValueCont++;
        }
        items[pos] = item;

        setSelectedValue(items);
    };

    const openComments = async (clientId) => {
        setBackDrop(true);

        setclientID(clientId);

        await getComments(clientId);

        setDialogComment(true);

        setBackDrop(false);
    };

    const getComments = async (clientId) => {
        await fetch(`/getComments?company_id=${clientId}&period=${selectedYear}&comment_type=1`, {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then((res) => res.json())
            .then((data) => {
                setComments(data.history);
                setCommentsPend(data.pendings);
            });
    };

    const clearRow = (index) => {
        let items = [...selectedValue];
        items.splice(index, 1, undefined);

        selectedValueCont--;

        setSelectedValue(items);
    };

    const handleClick = (event) => {
        selectedValueCont = 0;
        setSelectedValue([]);
        setselectedYear(event.target.value);
        setAnyFilter(false);
    };

    const getColorStatus = (id_status) => {
        const colors = ["#F8F9F8", "#f1919e", "#ffb23e", "#9ad2dd", "#FFB23E", "#8bc985", "#318a16"];

        return colors[id_status];
    };

    const getDisabledStatus = (valItem) => {
        const disabled = valItem === 0 || valItem === 1 || valItem === 2 || valItem === 4;

        return disabled;
    };

    const getDisabledStatusPrev = (valItem, monthVal) => {
        const disabled = (monthVal !== 5 && monthVal !== 6 && monthVal !== 0) || valItem === 0 || valItem === 1 || valItem === 2 || valItem === 4;
        return disabled;
    };

    const showTooltip = (valItem, monthVal) => {
        const show = valItem === 3 && (monthVal === 3 || monthVal === 5 || monthVal === 6);
        return !show;
    };

    const loadInitData = async (year) => {
        if (controllerRef.current) {
            controllerRef.current.abort();
        }
        const controller = new AbortController();
        controllerRef.current = controller;

        let finalClientMarket = [];
        if (clientMarket.includes(t("miscellaneous.all"))) {
            finalClientMarket = [];
        } else if (clientMarket.length === 0) {
            finalClientMarket = ["none"];
        } else {
            finalClientMarket = clientMarket.filter((item) => item !== t("miscellaneous.all"));
        }

        let finalFranq = [];
        if (franqF.includes("all")) {
            finalFranq = [];
        } else if (franqF.length === 0) {
            finalFranq = ["none"];
        } else {
            finalFranq = franqF.filter((item) => item !== "all");
        }

        let finalGroup = [];
        if (groupF.includes("all")) {
            finalGroup = [];
        } else if (groupF.length === 0) {
            finalGroup = ["none"];
        } else {
            finalGroup = groupF.filter((item) => item !== "all");
        }

        let finaStatus = [];
        if (idStatus.includes("all")) {
            finaStatus = [];
        } else {
            finaStatus = idStatus.filter((item) => item !== "all");
        }

        resultTable = await fetch(
            `/getNotifications?client_type=${clientT}&practice_id=${finalGroup}&franchise_id=${finalFranq}&client_status=${clientStatus}&id_status=${finaStatus}&year=${year}&service_type=${props.serviceType}&client_market=${finalClientMarket}`,
            {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
                signal: controllerRef.current.signal,
            }
        )
            .then((res) => res.json())
            .then((data) => {
                controllerRef.current = null;
                return data;
            });

        let clientsdata = resultTable.resultArray.map((valItem, index) => {
            let cont = 0;
            valItem.comments.forEach((data) => {
                if (data.status === 0) {
                    cont++;
                }
            });

            return {
                ID: valItem.id_organisation,
                hiddenCompany: valItem.company_name,
                client: valItem.invalid ? (
                    <Typography variant="h4" color="secondary">
                        {valItem.company_name}
                    </Typography>
                ) : (
                    valItem.company_name
                ),
                buttonClear: (
                    <LightTooltip title={t("recurrent.clear")}>
                        <span>
                            <IconButton
                                aria-label="clear"
                                color="primary"
                                disabled={selectedValue[index] === undefined}
                                onClick={() => clearRow(index)}
                            >
                                <ClearAllIcon />
                            </IconButton>
                        </span>
                    </LightTooltip>
                ),
                buttonAttach: (
                    <LightTooltip
                        title={
                            <>
                                {t("recurrent.validFormat")}
                                <br />
                                {t("dialogConversions.maximunUploadSize")}
                            </>
                        }
                        aria-label="attachFiles"
                    >
                        <span>
                            <IconButton
                                color="primary"
                                onClick={() => {
                                    setIdOrganisation(valItem.id_organisation);
                                    setOpen(true);
                                }}
                            >
                                <AttachFileIcon
                                    color={
                                        filesData.find((element) => element.id_organisation === valItem.id_organisation)
                                            ? "primary"
                                            : "disabled"
                                    }
                                />
                            </IconButton>
                        </span>
                    </LightTooltip>
                ),
                comments: (
                    <LightTooltip title={t("miscellaneous.comments")}>
                        <IconButton
                            aria-label="clear"
                            sx={valItem.comments.length === 0 ? { color: "rgba(0, 0, 0, 0.54)" } : { color: "#2f3190" }}
                            onClick={() => openComments(valItem.id_organisation)}
                        >
                            <StyledBadge badgeContent={cont}>
                                <CommentIcon />
                            </StyledBadge>
                        </IconButton>
                    </LightTooltip>
                ),
                reported:
                    valItem.report_date !== ""
                        ? moment(valItem.report_date, "DD/MM/YYYY").format(todos.dateFormat)
                        : "",
                jan: (
                    <div
                        style={{
                            borderRadius: 40,
                            width: 45,
                            marginLeft: "auto",
                            marginRight: "auto",
                            background: getColorStatus(valItem.notify_jan),
                        }}
                    >
                        <Radio
                            checked={selectedValue[index] !== undefined ? selectedValue[index].month === "01" : false}
                            onChange={(event) =>
                                handleChange(
                                    index,
                                    valItem.id_organisation,
                                    event,
                                    valItem.notify_jan,
                                    valItem.express_jan
                                )
                            }
                            value="01"
                            name="radio-button-jan"
                            color="primary"
                            disabled={getDisabledStatus(valItem.notify_jan)}
                            className={classes.radio}
                        />
                    </div>
                ),
                feb: (
                    <LightTooltip
                        disableHoverListener={showTooltip(valItem.notify_feb, valItem.notify_jan)}
                        title={t("clientsTable.toReportTool")}
                    >
                        <div
                            style={{
                                borderRadius: 40,
                                width: 45,
                                marginLeft: "auto",
                                marginRight: "auto",
                                background: getColorStatus(valItem.notify_feb),
                            }}
                        >
                            <Radio
                                checked={
                                    selectedValue[index] !== undefined ? selectedValue[index].month === "02" : false
                                }
                                onChange={(event) =>
                                    handleChange(
                                        index,
                                        valItem.id_organisation,
                                        event,
                                        valItem.notify_feb,
                                        valItem.express_feb
                                    )
                                }
                                value="02"
                                name="radio-button-feb"
                                color="primary"
                                disabled={getDisabledStatusPrev(valItem.notify_feb, valItem.notify_jan)}
                                className={classes.radio}
                            />
                        </div>
                    </LightTooltip>
                ),
                mar: (
                    <LightTooltip
                        disableHoverListener={showTooltip(valItem.notify_mar, valItem.notify_feb)}
                        title={t("clientsTable.toReportTool")}
                    >
                        <div
                            style={{
                                borderRadius: 40,
                                width: 45,
                                marginLeft: "auto",
                                marginRight: "auto",
                                background: getColorStatus(valItem.notify_mar),
                            }}
                        >
                            <Radio
                                checked={
                                    selectedValue[index] !== undefined ? selectedValue[index].month === "03" : false
                                }
                                onChange={(event) =>
                                    handleChange(
                                        index,
                                        valItem.id_organisation,
                                        event,
                                        valItem.notify_mar,
                                        valItem.express_mar
                                    )
                                }
                                value="03"
                                name="radio-button-mar"
                                color="primary"
                                disabled={getDisabledStatusPrev(valItem.notify_mar, valItem.notify_feb)}
                                className={classes.radio}
                            />
                        </div>
                    </LightTooltip>
                ),
                apr: (
                    <LightTooltip
                        disableHoverListener={showTooltip(valItem.notify_apr, valItem.notify_mar)}
                        title={t("clientsTable.toReportTool")}
                    >
                        <div
                            style={{
                                borderRadius: 40,
                                width: 45,
                                marginLeft: "auto",
                                marginRight: "auto",
                                background: getColorStatus(valItem.notify_apr),
                            }}
                        >
                            <Radio
                                checked={
                                    selectedValue[index] !== undefined ? selectedValue[index].month === "04" : false
                                }
                                onChange={(event) =>
                                    handleChange(
                                        index,
                                        valItem.id_organisation,
                                        event,
                                        valItem.notify_apr,
                                        valItem.express_apr
                                    )
                                }
                                value="04"
                                name="radio-button-apr"
                                color="primary"
                                disabled={getDisabledStatusPrev(valItem.notify_apr, valItem.notify_mar)}
                                className={classes.radio}
                            />
                        </div>
                    </LightTooltip>
                ),
                may: (
                    <LightTooltip
                        disableHoverListener={showTooltip(valItem.notify_may, valItem.notify_apr)}
                        title={t("clientsTable.toReportTool")}
                    >
                        <div
                            style={{
                                borderRadius: 40,
                                width: 45,
                                marginLeft: "auto",
                                marginRight: "auto",
                                background: getColorStatus(valItem.notify_may),
                            }}
                        >
                            <Radio
                                checked={
                                    selectedValue[index] !== undefined ? selectedValue[index].month === "05" : false
                                }
                                onChange={(event) =>
                                    handleChange(
                                        index,
                                        valItem.id_organisation,
                                        event,
                                        valItem.notify_may,
                                        valItem.express_may
                                    )
                                }
                                value="05"
                                name="radio-button-may"
                                color="primary"
                                disabled={getDisabledStatusPrev(valItem.notify_may, valItem.notify_apr)}
                                className={classes.radio}
                            />
                        </div>
                    </LightTooltip>
                ),
                jun: (
                    <LightTooltip
                        disableHoverListener={showTooltip(valItem.notify_jun, valItem.notify_may)}
                        title={t("clientsTable.toReportTool")}
                    >
                        <div
                            style={{
                                borderRadius: 40,
                                width: 45,
                                marginLeft: "auto",
                                marginRight: "auto",
                                background: getColorStatus(valItem.notify_jun),
                            }}
                        >
                            <Radio
                                checked={
                                    selectedValue[index] !== undefined ? selectedValue[index].month === "06" : false
                                }
                                onChange={(event) =>
                                    handleChange(
                                        index,
                                        valItem.id_organisation,
                                        event,
                                        valItem.notify_jun,
                                        valItem.express_jun
                                    )
                                }
                                value="06"
                                name="radio-button-jun"
                                color="primary"
                                disabled={getDisabledStatusPrev(valItem.notify_jun, valItem.notify_may)}
                                className={classes.radio}
                            />
                        </div>
                    </LightTooltip>
                ),
                jul: (
                    <LightTooltip
                        disableHoverListener={showTooltip(valItem.notify_jul, valItem.notify_jun)}
                        title={t("clientsTable.toReportTool")}
                    >
                        <div
                            style={{
                                borderRadius: 40,
                                width: 45,
                                marginLeft: "auto",
                                marginRight: "auto",
                                background: getColorStatus(valItem.notify_jul),
                            }}
                        >
                            <Radio
                                checked={
                                    selectedValue[index] !== undefined ? selectedValue[index].month === "07" : false
                                }
                                onChange={(event) =>
                                    handleChange(
                                        index,
                                        valItem.id_organisation,
                                        event,
                                        valItem.notify_jul,
                                        valItem.express_jul
                                    )
                                }
                                value="07"
                                name="radio-button-jul"
                                color="primary"
                                disabled={getDisabledStatusPrev(valItem.notify_jul, valItem.notify_jun)}
                                className={classes.radio}
                            />
                        </div>
                    </LightTooltip>
                ),
                aug: (
                    <LightTooltip
                        disableHoverListener={showTooltip(valItem.notify_aug, valItem.notify_jul)}
                        title={t("clientsTable.toReportTool")}
                    >
                        <div
                            style={{
                                borderRadius: 40,
                                width: 45,
                                marginLeft: "auto",
                                marginRight: "auto",
                                background: getColorStatus(valItem.notify_aug),
                            }}
                        >
                            <Radio
                                checked={
                                    selectedValue[index] !== undefined ? selectedValue[index].month === "08" : false
                                }
                                onChange={(event) =>
                                    handleChange(
                                        index,
                                        valItem.id_organisation,
                                        event,
                                        valItem.notify_aug,
                                        valItem.express_aug
                                    )
                                }
                                value="08"
                                name="radio-button-aug"
                                color="primary"
                                disabled={getDisabledStatusPrev(valItem.notify_aug, valItem.notify_jul)}
                                className={classes.radio}
                            />
                        </div>
                    </LightTooltip>
                ),
                sep: (
                    <LightTooltip
                        disableHoverListener={showTooltip(valItem.notify_sep, valItem.notify_aug)}
                        title={t("clientsTable.toReportTool")}
                    >
                        <div
                            style={{
                                borderRadius: 40,
                                width: 45,
                                marginLeft: "auto",
                                marginRight: "auto",
                                background: getColorStatus(valItem.notify_sep),
                            }}
                        >
                            <Radio
                                checked={
                                    selectedValue[index] !== undefined ? selectedValue[index].month === "09" : false
                                }
                                onChange={(event) =>
                                    handleChange(
                                        index,
                                        valItem.id_organisation,
                                        event,
                                        valItem.notify_sep,
                                        valItem.express_sep
                                    )
                                }
                                value="09"
                                name="radio-button-sep"
                                color="primary"
                                disabled={getDisabledStatusPrev(valItem.notify_sep, valItem.notify_aug)}
                                className={classes.radio}
                            />
                        </div>
                    </LightTooltip>
                ),
                oct: (
                    <LightTooltip
                        disableHoverListener={showTooltip(valItem.notify_oct, valItem.notify_sep)}
                        title={t("clientsTable.toReportTool")}
                    >
                        <div
                            style={{
                                borderRadius: 40,
                                width: 45,
                                marginLeft: "auto",
                                marginRight: "auto",
                                background: getColorStatus(valItem.notify_oct),
                            }}
                        >
                            <Radio
                                checked={
                                    selectedValue[index] !== undefined ? selectedValue[index].month === "10" : false
                                }
                                onChange={(event) =>
                                    handleChange(
                                        index,
                                        valItem.id_organisation,
                                        event,
                                        valItem.notify_oct,
                                        valItem.express_oct
                                    )
                                }
                                value="10"
                                name="radio-button-oct"
                                color="primary"
                                disabled={getDisabledStatusPrev(valItem.notify_oct, valItem.notify_sep)}
                                className={classes.radio}
                            />
                        </div>
                    </LightTooltip>
                ),
                nov: (
                    <LightTooltip
                        disableHoverListener={showTooltip(valItem.notify_nov, valItem.notify_oct)}
                        title={t("clientsTable.toReportTool")}
                    >
                        <div
                            style={{
                                borderRadius: 40,
                                width: 45,
                                marginLeft: "auto",
                                marginRight: "auto",
                                background: getColorStatus(valItem.notify_nov),
                            }}
                        >
                            <Radio
                                checked={
                                    selectedValue[index] !== undefined ? selectedValue[index].month === "11" : false
                                }
                                onChange={(event) =>
                                    handleChange(
                                        index,
                                        valItem.id_organisation,
                                        event,
                                        valItem.notify_nov,
                                        valItem.express_nov
                                    )
                                }
                                value="11"
                                name="radio-button-nov"
                                color="primary"
                                disabled={getDisabledStatusPrev(valItem.notify_nov, valItem.notify_oct)}
                                className={classes.radio}
                            />
                        </div>
                    </LightTooltip>
                ),
                dec: (
                    <LightTooltip
                        disableHoverListener={showTooltip(valItem.notify_dec, valItem.notify_nov)}
                        title={t("clientsTable.toReportTool")}
                    >
                        <div
                            style={{
                                borderRadius: 40,
                                width: 45,
                                marginLeft: "auto",
                                marginRight: "auto",
                                background: getColorStatus(valItem.notify_dec),
                            }}
                        >
                            <Radio
                                checked={
                                    selectedValue[index] !== undefined ? selectedValue[index].month === "12" : false
                                }
                                onChange={(event) =>
                                    handleChange(
                                        index,
                                        valItem.id_organisation,
                                        event,
                                        valItem.notify_dec,
                                        valItem.express_dec
                                    )
                                }
                                value="12"
                                name="radio-button-dec"
                                color="primary"
                                disabled={getDisabledStatusPrev(valItem.notify_dec, valItem.notify_nov)}
                                className={classes.radio}
                            />
                        </div>
                    </LightTooltip>
                ),
            };
        });

        setClients(clientsdata);
    };

    const notifyMonths = async (val) => {
        setBackDrop(true);

        let arrayNotification = [];
        let cont = 0;

        selectedValue.forEach((item) => {
            if (item !== undefined) {
                var temp = Object.assign({}, item);
                temp.year = selectedYear;
                arrayNotification[cont] = temp;
                cont++;
            }
        });

        let data = new FormData();
        if (arrayNotification.length > 0) {
            for (let i = 0; i < filesData.length; i++) {
                // Merge entre arreglos de archivos cargados y clientes seleccionados
                let inSelectedArray = arrayNotification.find(
                    (element) => element.id_organisation === filesData[i].id_organisation
                );
                for (let j = 0; j < filesData[i].files.length; j++) {
                    data.append("isMassive", "false");
                    data.append("idOrganisation", filesData[i].id_organisation);
                    data.append("clientType", clientT);
                    data.append("franchiseId", franqF);
                    data.append("practiceId", groupF);
                    data.append("clientStatus", clientStatus);
                    data.append("idStatus", idStatus);
                    data.append("serviceType", props.serviceType);
                    data.append("month", inSelectedArray.month);
                    data.append("year", selectedYear);
                    data.append("files", filesData[i].files[j]);
                    data.append("notifyNumber", arrayNotification[i].notifyNumber);
                    data.append("isExpress", isExpress);
                    if (val === 1) {
                        data.append("whoToSend", motiveDec);
                    } else if (val === 3) {
                        data.append("whoToSend", motiveDec2);
                    }
                }
            }

            // Arreglo de seleccionados que no tienen archivos cargados
            const selectedFiltering = arrayNotification.filter(
                ({ id_organisation: id1 }) => !filesData.some(({ id_organisation: id2 }) => id2 === id1)
            );
            for (let i = 0; i < selectedFiltering.length; i++) {
                data.append("isMassive", "false");
                data.append("idOrganisation", selectedFiltering[i].id_organisation);
                data.append("clientType", clientT);
                data.append("franchiseId", franqF);
                data.append("practiceId", groupF);
                data.append("clientStatus", clientStatus);
                data.append("idStatus", idStatus);
                data.append("serviceType", props.serviceType);
                data.append("month", selectedFiltering[i].month);
                data.append("year", selectedYear);
                data.append("notifyNumber", arrayNotification[i].notifyNumber);
                data.append("isExpress", isExpress);
                if (val === 1) {
                    data.append("whoToSend", motiveDec);
                } else if (val === 3) {
                    data.append("whoToSend", motiveDec2);
                }
            }
        } else {
            if (filesData.length > 0) {
                for (let i = 0; i < filesData.length; i++) {
                    for (let j = 0; j < filesData[i].files.length; j++) {
                        data.append("isMassive", "true");
                        data.append("idOrganisation", filesData[i].id_organisation);
                        data.append("clientType", clientT);
                        data.append("franchiseId", franqF);
                        data.append("practiceId", groupF);
                        data.append("clientStatus", clientStatus);
                        data.append("idStatus", idStatus);
                        data.append("serviceType", props.serviceType);
                        data.append("year", selectedYear);
                        data.append("files", filesData[i].files[j]);
                        data.append("notifyNumber", arrayNotification[i].notifyNumber);
                        data.append("isExpress", isExpress);
                        if (val === 1) {
                            data.append("whoToSend", motiveDec);
                        } else if (val === 3) {
                            data.append("whoToSend", motiveDec2);
                        }
                    }
                }
            } else {
                data.append("isMassive", "true");
                data.append("clientType", clientT);
                data.append("franchiseId", franqF);
                data.append("practiceId", groupF);
                data.append("clientStatus", clientStatus);
                data.append("idStatus", idStatus);
                data.append("serviceType", props.serviceType);
                data.append("year", selectedYear);
                data.append("isExpress", isExpress);
                if (val === 1) {
                    data.append("whoToSend", motiveDec);
                } else if (val === 3) {
                    data.append("whoToSend", motiveDec2);
                }
            }
        }

        await fetch("/notificateCustomReports", {
            method: "POST",
            body: data,
        })
            .then(() => {
                selectedValueCont = 0;
                setSelectedValue([]);
                setFilesData([]);
                handleCloseDialog();
                setisValidated(!isValidated);
                setTexto(t("reports.notifyReports"));
                seAutoAlertType("success");
                setShow(true);
            })
            .catch((err) => {
                handleCloseDialog();
                setTexto(err.error);
                seAutoAlertType("error");
                setShow(true);
            });

        setBackDrop(false);
    };

    const expressServices = async () => {
        setBackDrop(true);

        let arrayNotification = [];
        let cont = 0;

        selectedValue.forEach((item) => {
            if (item !== undefined) {
                var temp = Object.assign({}, item);
                temp.year = selectedYear;
                arrayNotification[cont] = temp;
                cont++;
            }
        });

        let data = new FormData();
        if (arrayNotification.length > 0) {
            for (let i = 0; i < filesData.length; i++) {
                // Merge entre arreglos de archivos cargados y clientes seleccionados
                let inSelectedArray = arrayNotification.find(
                    (element) => element.id_organisation === filesData[i].id_organisation
                );
                for (let j = 0; j < filesData[i].files.length; j++) {
                    data.append("isMassive", "false");
                    data.append("idOrganisation", filesData[i].id_organisation);
                    data.append("clientType", clientT);
                    data.append("franchiseId", franqF);
                    data.append("practiceId", groupF);
                    data.append("clientStatus", clientStatus);
                    data.append("idStatus", arrayNotification[i].notifyNumber);
                    data.append("serviceType", props.serviceType);
                    data.append("month", inSelectedArray.month);
                    data.append("year", selectedYear);
                    data.append("files", filesData[i].files[j]);
                }
            }

            // Arreglo de seleccionados que no tienen archivos cargados
            const selectedFiltering = arrayNotification.filter(
                ({ id_organisation: id1 }) => !filesData.some(({ id_organisation: id2 }) => id2 === id1)
            );
            for (let i = 0; i < selectedFiltering.length; i++) {
                data.append("isMassive", "false");
                data.append("idOrganisation", selectedFiltering[i].id_organisation);
                data.append("clientType", clientT);
                data.append("franchiseId", franqF);
                data.append("practiceId", groupF);
                data.append("clientStatus", clientStatus);
                data.append("idStatus", arrayNotification[i].notifyNumber);
                data.append("serviceType", props.serviceType);
                data.append("month", selectedFiltering[i].month);
                data.append("year", selectedYear);
            }
        } else {
            if (filesData.length > 0) {
                for (let i = 0; i < filesData.length; i++) {
                    for (let j = 0; j < filesData[i].files.length; j++) {
                        data.append("isMassive", "true");
                        data.append("idOrganisation", filesData[i].id_organisation);
                        data.append("clientType", clientT);
                        data.append("franchiseId", franqF);
                        data.append("practiceId", groupF);
                        data.append("clientStatus", clientStatus);
                        data.append("idStatus", arrayNotification[i].notifyNumber);
                        data.append("serviceType", props.serviceType);
                        data.append("year", selectedYear);
                        data.append("files", filesData[i].files[j]);
                    }
                }
            } else {
                data.append("isMassive", "true");
                data.append("clientType", clientT);
                data.append("franchiseId", franqF);
                data.append("practiceId", groupF);
                data.append("clientStatus", clientStatus);
                data.append("idStatus", idStatus);
                data.append("serviceType", props.serviceType);
                data.append("year", selectedYear);
            }
        }

        await fetch("/expressService", {
            method: "POST",
            body: data,
        })
            .then(() => {
                selectedValueCont = 0;
                setSelectedValue([]);
                setFilesData([]);
                handleCloseDialogExpressService();
                setisValidated(!isValidated);
                setTexto(t("miscellaneous.notifyExpressService"));
                seAutoAlertType("success");
                setShow(true);
            })
            .catch((err) => {
                handleCloseDialogExpressService();
                setTexto(err.error);
                seAutoAlertType("error");
                setShow(true);
            });

        setBackDrop(false);
    };

    const setTableData = () => {
        if (selectedValue.length !== 0) {
            let clientsdata = [];

            clientsdata = resultTable.resultArray.map((valItem, index) => {
                let cont = 0;
                valItem.comments.forEach((data) => {
                    if (data.status === 0) {
                        cont++;
                    }
                });
                return {
                    ID: valItem.id_organisation,
                    client: valItem.company_name,
                    buttonClear: (
                        <LightTooltip title={t("recurrent.clear")}>
                            <span>
                                <IconButton
                                    aria-label="clear"
                                    color="primary"
                                    disabled={selectedValue[index] === undefined}
                                    onClick={() => clearRow(index)}
                                >
                                    <ClearAllIcon />
                                </IconButton>
                            </span>
                        </LightTooltip>
                    ),
                    buttonAttach: (
                        <LightTooltip
                            title={
                                <>
                                    {t("recurrent.validFormat")}
                                    <br />
                                    {t("dialogConversions.maximunUploadSize")}
                                </>
                            }
                            aria-label="attachFiles"
                        >
                            <span>
                                <IconButton
                                    color="primary"
                                    onClick={() => {
                                        setIdOrganisation(valItem.id_organisation);
                                        setOpen(true);
                                    }}
                                >
                                    <AttachFileIcon
                                        color={
                                            filesData.find(
                                                (element) => element.id_organisation === valItem.id_organisation
                                            )
                                                ? "primary"
                                                : "disabled"
                                        }
                                    />
                                </IconButton>
                            </span>
                        </LightTooltip>
                    ),
                    comments: (
                        <LightTooltip title={t("miscellaneous.comments")}>
                            <IconButton
                                aria-label="clear"
                                sx={
                                    valItem.comments.length === 0
                                        ? { color: "rgba(0, 0, 0, 0.54)" }
                                        : { color: "#2f3190" }
                                }
                                onClick={() => openComments(valItem.id_organisation)}
                            >
                                <StyledBadge badgeContent={cont}>
                                    <CommentIcon />
                                </StyledBadge>
                            </IconButton>
                        </LightTooltip>
                    ),
                    reported:
                        valItem.report_date !== ""
                            ? moment(valItem.report_date, "DD/MM/YYYY").format(todos.dateFormat)
                            : "",
                    jan: (
                        <div
                            style={{
                                borderRadius: 40,
                                width: 45,
                                marginLeft: "auto",
                                marginRight: "auto",
                                background: getColorStatus(valItem.notify_jan),
                            }}
                        >
                            <Radio
                                checked={
                                    selectedValue[index] !== undefined ? selectedValue[index].month === "01" : false
                                }
                                onChange={(event) =>
                                    handleChange(
                                        index,
                                        valItem.id_organisation,
                                        event,
                                        valItem.notify_jan,
                                        valItem.express_jan
                                    )
                                }
                                value="01"
                                name="radio-button-jan"
                                color="primary"
                                disabled={getDisabledStatus(valItem.notify_jan)}
                                className={classes.radio}
                            />
                        </div>
                    ),
                    feb: (
                        <LightTooltip
                            disableHoverListener={showTooltip(valItem.notify_feb, valItem.notify_jan)}
                            title={t("clientsTable.toReportTool")}
                        >
                            <div
                                style={{
                                    borderRadius: 40,
                                    width: 45,
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    background: getColorStatus(valItem.notify_feb),
                                }}
                            >
                                <Radio
                                    checked={
                                        selectedValue[index] !== undefined ? selectedValue[index].month === "02" : false
                                    }
                                    onChange={(event) =>
                                        handleChange(
                                            index,
                                            valItem.id_organisation,
                                            event,
                                            valItem.notify_feb,
                                            valItem.express_feb
                                        )
                                    }
                                    value="02"
                                    name="radio-button-feb"
                                    color="primary"
                                    disabled={getDisabledStatusPrev(valItem.notify_feb, valItem.notify_jan)}
                                    className={classes.radio}
                                />
                            </div>
                        </LightTooltip>
                    ),
                    mar: (
                        <LightTooltip
                            disableHoverListener={showTooltip(valItem.notify_mar, valItem.notify_feb)}
                            title={t("clientsTable.toReportTool")}
                        >
                            <div
                                style={{
                                    borderRadius: 40,
                                    width: 45,
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    background: getColorStatus(valItem.notify_mar),
                                }}
                            >
                                <Radio
                                    checked={
                                        selectedValue[index] !== undefined ? selectedValue[index].month === "03" : false
                                    }
                                    onChange={(event) =>
                                        handleChange(
                                            index,
                                            valItem.id_organisation,
                                            event,
                                            valItem.notify_mar,
                                            valItem.express_mar
                                        )
                                    }
                                    value="03"
                                    name="radio-button-mar"
                                    color="primary"
                                    disabled={getDisabledStatusPrev(valItem.notify_mar, valItem.notify_feb)}
                                    className={classes.radio}
                                />
                            </div>
                        </LightTooltip>
                    ),
                    apr: (
                        <LightTooltip
                            disableHoverListener={showTooltip(valItem.notify_apr, valItem.notify_mar)}
                            title={t("clientsTable.toReportTool")}
                        >
                            <div
                                style={{
                                    borderRadius: 40,
                                    width: 45,
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    background: getColorStatus(valItem.notify_apr),
                                }}
                            >
                                <Radio
                                    checked={
                                        selectedValue[index] !== undefined ? selectedValue[index].month === "04" : false
                                    }
                                    onChange={(event) =>
                                        handleChange(
                                            index,
                                            valItem.id_organisation,
                                            event,
                                            valItem.notify_apr,
                                            valItem.express_apr
                                        )
                                    }
                                    value="04"
                                    name="radio-button-apr"
                                    color="primary"
                                    disabled={getDisabledStatusPrev(valItem.notify_apr, valItem.notify_mar)}
                                    className={classes.radio}
                                />
                            </div>
                        </LightTooltip>
                    ),
                    may: (
                        <LightTooltip
                            disableHoverListener={showTooltip(valItem.notify_may, valItem.notify_apr)}
                            title={t("clientsTable.toReportTool")}
                        >
                            <div
                                style={{
                                    borderRadius: 40,
                                    width: 45,
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    background: getColorStatus(valItem.notify_may),
                                }}
                            >
                                <Radio
                                    checked={
                                        selectedValue[index] !== undefined ? selectedValue[index].month === "05" : false
                                    }
                                    onChange={(event) =>
                                        handleChange(
                                            index,
                                            valItem.id_organisation,
                                            event,
                                            valItem.notify_may,
                                            valItem.express_may
                                        )
                                    }
                                    value="05"
                                    name="radio-button-may"
                                    color="primary"
                                    disabled={getDisabledStatusPrev(valItem.notify_may, valItem.notify_apr)}
                                    className={classes.radio}
                                />
                            </div>
                        </LightTooltip>
                    ),
                    jun: (
                        <LightTooltip
                            disableHoverListener={showTooltip(valItem.notify_jun, valItem.notify_may)}
                            title={t("clientsTable.toReportTool")}
                        >
                            <div
                                style={{
                                    borderRadius: 40,
                                    width: 45,
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    background: getColorStatus(valItem.notify_jun),
                                }}
                            >
                                <Radio
                                    checked={
                                        selectedValue[index] !== undefined ? selectedValue[index].month === "06" : false
                                    }
                                    onChange={(event) =>
                                        handleChange(
                                            index,
                                            valItem.id_organisation,
                                            event,
                                            valItem.notify_jun,
                                            valItem.express_jun
                                        )
                                    }
                                    value="06"
                                    name="radio-button-jun"
                                    color="primary"
                                    disabled={getDisabledStatusPrev(valItem.notify_jun, valItem.notify_may)}
                                    className={classes.radio}
                                />
                            </div>
                        </LightTooltip>
                    ),
                    jul: (
                        <LightTooltip
                            disableHoverListener={showTooltip(valItem.notify_jul, valItem.notify_jun)}
                            title={t("clientsTable.toReportTool")}
                        >
                            <div
                                style={{
                                    borderRadius: 40,
                                    width: 45,
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    background: getColorStatus(valItem.notify_jul),
                                }}
                            >
                                <Radio
                                    checked={
                                        selectedValue[index] !== undefined ? selectedValue[index].month === "07" : false
                                    }
                                    onChange={(event) =>
                                        handleChange(
                                            index,
                                            valItem.id_organisation,
                                            event,
                                            valItem.notify_jul,
                                            valItem.express_jul
                                        )
                                    }
                                    value="07"
                                    name="radio-button-jul"
                                    color="primary"
                                    disabled={getDisabledStatusPrev(valItem.notify_jul, valItem.notify_jun)}
                                    className={classes.radio}
                                />
                            </div>
                        </LightTooltip>
                    ),
                    aug: (
                        <LightTooltip
                            disableHoverListener={showTooltip(valItem.notify_aug, valItem.notify_jul)}
                            title={t("clientsTable.toReportTool")}
                        >
                            <div
                                style={{
                                    borderRadius: 40,
                                    width: 45,
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    background: getColorStatus(valItem.notify_aug),
                                }}
                            >
                                <Radio
                                    checked={
                                        selectedValue[index] !== undefined ? selectedValue[index].month === "08" : false
                                    }
                                    onChange={(event) =>
                                        handleChange(
                                            index,
                                            valItem.id_organisation,
                                            event,
                                            valItem.notify_aug,
                                            valItem.express_aug
                                        )
                                    }
                                    value="08"
                                    name="radio-button-aug"
                                    color="primary"
                                    disabled={getDisabledStatusPrev(valItem.notify_aug, valItem.notify_jul)}
                                    className={classes.radio}
                                />
                            </div>
                        </LightTooltip>
                    ),
                    sep: (
                        <LightTooltip
                            disableHoverListener={showTooltip(valItem.notify_sep, valItem.notify_aug)}
                            title={t("clientsTable.toReportTool")}
                        >
                            <div
                                style={{
                                    borderRadius: 40,
                                    width: 45,
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    background: getColorStatus(valItem.notify_sep),
                                }}
                            >
                                <Radio
                                    checked={
                                        selectedValue[index] !== undefined ? selectedValue[index].month === "09" : false
                                    }
                                    onChange={(event) =>
                                        handleChange(
                                            index,
                                            valItem.id_organisation,
                                            event,
                                            valItem.notify_sep,
                                            valItem.express_sep
                                        )
                                    }
                                    value="09"
                                    name="radio-button-sep"
                                    color="primary"
                                    disabled={getDisabledStatusPrev(valItem.notify_sep, valItem.notify_aug)}
                                    className={classes.radio}
                                />
                            </div>
                        </LightTooltip>
                    ),
                    oct: (
                        <LightTooltip
                            disableHoverListener={showTooltip(valItem.notify_oct, valItem.notify_sep)}
                            title={t("clientsTable.toReportTool")}
                        >
                            <div
                                style={{
                                    borderRadius: 40,
                                    width: 45,
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    background: getColorStatus(valItem.notify_oct),
                                }}
                            >
                                <Radio
                                    checked={
                                        selectedValue[index] !== undefined ? selectedValue[index].month === "10" : false
                                    }
                                    onChange={(event) =>
                                        handleChange(
                                            index,
                                            valItem.id_organisation,
                                            event,
                                            valItem.notify_oct,
                                            valItem.express_oct
                                        )
                                    }
                                    value="10"
                                    name="radio-button-oct"
                                    color="primary"
                                    disabled={getDisabledStatusPrev(valItem.notify_oct, valItem.notify_sep)}
                                    className={classes.radio}
                                />
                            </div>
                        </LightTooltip>
                    ),
                    nov: (
                        <LightTooltip
                            disableHoverListener={showTooltip(valItem.notify_nov, valItem.notify_oct)}
                            title={t("clientsTable.toReportTool")}
                        >
                            <div
                                style={{
                                    borderRadius: 40,
                                    width: 45,
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    background: getColorStatus(valItem.notify_nov),
                                }}
                            >
                                <Radio
                                    checked={
                                        selectedValue[index] !== undefined ? selectedValue[index].month === "11" : false
                                    }
                                    onChange={(event) =>
                                        handleChange(
                                            index,
                                            valItem.id_organisation,
                                            event,
                                            valItem.notify_nov,
                                            valItem.express_nov
                                        )
                                    }
                                    value="11"
                                    name="radio-button-nov"
                                    color="primary"
                                    disabled={getDisabledStatusPrev(valItem.notify_nov, valItem.notify_oct)}
                                    className={classes.radio}
                                />
                            </div>
                        </LightTooltip>
                    ),
                    dec: (
                        <LightTooltip
                            disableHoverListener={showTooltip(valItem.notify_dec, valItem.notify_nov)}
                            title={t("clientsTable.toReportTool")}
                        >
                            <div
                                style={{
                                    borderRadius: 40,
                                    width: 45,
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    background: getColorStatus(valItem.notify_dec),
                                }}
                            >
                                <Radio
                                    checked={
                                        selectedValue[index] !== undefined ? selectedValue[index].month === "12" : false
                                    }
                                    onChange={(event) =>
                                        handleChange(
                                            index,
                                            valItem.id_organisation,
                                            event,
                                            valItem.notify_dec,
                                            valItem.express_dec
                                        )
                                    }
                                    value="12"
                                    name="radio-button-dec"
                                    color="primary"
                                    disabled={getDisabledStatusPrev(valItem.notify_dec, valItem.notify_nov)}
                                    className={classes.radio}
                                />
                            </div>
                        </LightTooltip>
                    ),
                };
            });

            setClients(clientsdata);
        }
    };

    useEffect(() => {
        setTableData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedValue]);

    useEffect(() => {
        setClients(rowsSkeleton);

        loadInitData(selectedYear);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        t,
        todos.dateFormat,
        isValidated,
        clientT,
        franqF,
        groupF,
        clientMarket,
        clientStatus,
        idStatus,
        selectedYear,
        filesData,
    ]);

    useEffect(() => {
        (async () => {
            const columnsTemplate = [
                { name: "hiddenCompany", title: "Hidden Companies" },
                { name: "client", title: t("miscellaneous.client") },
                { name: "buttonClear", title: " " },
                { name: "buttonAttach", title: " " },
                { name: "comments", title: " " },
                { name: "reported", title: t("clientsTable.reported") },
                { name: "jan", title: t("monthNamesShort.jan") },
                { name: "feb", title: t("monthNamesShort.feb") },
                { name: "mar", title: t("monthNamesShort.mar") },
                { name: "apr", title: t("monthNamesShort.apr") },
                { name: "may", title: t("monthNamesShort.may") },
                { name: "jun", title: t("monthNamesShort.jun") },
                { name: "jul", title: t("monthNamesShort.jul") },
                { name: "aug", title: t("monthNamesShort.aug") },
                { name: "sep", title: t("monthNamesShort.sep") },
                { name: "oct", title: t("monthNamesShort.oct") },
                { name: "nov", title: t("monthNamesShort.nov") },
                { name: "dec", title: t("monthNamesShort.dec") },
            ];
            setColumnsDefs(columnsTemplate);

            let finalFranq = [];
            if (franqF.includes("all")) {
                finalFranq = [];
            } else if (franqF.length === 0) {
                finalFranq = ["none"];
            } else {
                finalFranq = franqF.filter((item) => item !== "all");
            }

            let finalGroup = [];
            if (groupF.includes("all")) {
                finalGroup = [];
            } else if (groupF.length === 0) {
                finalGroup = ["none"];
            } else {
                finalGroup = groupF.filter((item) => item !== "all");
            }

            await fetch(
                `/getNotificationYears?client_type=${clientT}&practice_id=${finalGroup}&franchise_id=${finalFranq}`,
                {
                    method: "GET",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                    },
                }
            )
                .then((res) => res.json())
                .then((data) => {
                    setyearsToNoti(data);
                });
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t, todos.dateFormat, clientT, franqF, groupF]);

    useEffect(() => {
        const findItemsNotValid = selectedValue.filter(
            (element) => element && element.notifyNumber !== 5 && element.notifyNumber !== 6
        );

        const findItemsValid = selectedValue.filter(
            (element) => element && (element.notifyNumber === 5 || element.notifyNumber === 6)
        );

        // Validado
        const validStatus = selectedValue.filter((element) => element && element.notifyNumber === 3);
        const findItemsExpress = selectedValue.filter((element) => element && element.expressService);

        setOptionsGroupSend((prevState) => {
            return prevState.map((item, index) => {
                //Solo permite opcion de Reportar porque ya tiene servicio express
                if (findItemsExpress.length) {
                    return {
                        ...item,
                        disable: index === 0 ? false : true,
                    };
                } else if (!findItemsNotValid.length && findItemsValid.length) {
                    //Si es "Reportado parcial" y "Cerrado" permite las 3 opciones.
                    return {
                        ...item,
                        disable: false,
                    };
                } else if (findItemsNotValid.length && findItemsValid.length) {
                    //Si tiene combinacion de ambos, solo debe permitir reportar
                    return {
                        ...item,
                        disable: index === 0 ? false : true,
                    };
                } else if (validStatus.length) {
                    //Si es "Validado", permite Reportar y Reportar con servicio express
                    return {
                        ...item,
                        disable: item.id === 1,
                    };
                } else {
                    return {
                        ...item,
                        disable: index === 0 ? false : true,
                    };
                }
            });
        });
    }, [selectedValue]);

    return (
        <StyledContainer component="main" sx={{ flexGrow: 1, width: "100%" }}>
            <GridUI container spacing={1.5} className={classes.spacing}>
                <GridUI item xs={12} md={4} lg>
                    <FormControl fullWidth classes={{ root: classes.formRounded }}>
                        <InputLabel id="year-simple-select-label">{t("miscellaneous.year")}</InputLabel>
                        <Select
                            value={selectedYear}
                            labelId="year-simple-select-label"
                            onChange={handleClick}
                            IconComponent={KeyboardArrowDownIcon}
                            className="ddlGeneral"
                            size="small"
                            label={t("miscellaneous.year")}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        mt: 0.7,
                                        borderRadius: 2,
                                        boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                    },
                                },
                            }}
                        >
                            {yearsToNoti.map((year, index) => (
                                <MenuItem key={index} value={year}>
                                    <Typography variant="body1" component="span" align="center" color="textPrimary">
                                        {year}
                                    </Typography>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </GridUI>
                <GridUI item xs={12} md={4} lg>
                    <FormControl fullWidth classes={{ root: classes.formRounded }}>
                        <InputLabel shrink id="type-simple-select-label">
                            {t("recurrent.typeCli")}
                        </InputLabel>
                        <Select
                            size="small"
                            IconComponent={KeyboardArrowDownIcon}
                            value={clientT}
                            displayEmpty
                            onChange={handleChangeTClient}
                            className="ddlGeneral"
                            input={<OutlinedInput notched label={t("recurrent.typeCli")} />}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        mt: 0.7,
                                        borderRadius: 2,
                                        boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                    },
                                },
                            }}
                        >
                            <MenuItem value="">
                                <Typography variant="body1" component="span" align="center" color="textPrimary">
                                    {t("miscellaneous.all")}
                                </Typography>
                            </MenuItem>
                            {props.typeData.map((value) => (
                                <MenuItem value={value.id}>
                                    <Typography variant="body1" component="span" align="center" color="textPrimary">
                                        {value.id === 1 ? t("miscellaneous.firm") : value.client_type}
                                    </Typography>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </GridUI>
                <GridUI item xs={12} md={4} lg={2}>
                    <FormControl fullWidth classes={{ root: classes.formRounded }}>
                        <InputLabel shrink id="franq-simple-select-label">
                            {t("miscellaneous.franchise")}
                        </InputLabel>
                        <Select
                            size="small"
                            labelId="franq-multiple-select-label"
                            IconComponent={KeyboardArrowDownIcon}
                            value={franqF}
                            displayEmpty
                            onChange={handleChangeFranqF}
                            className="ddlGeneral"
                            multiple
                            input={
                                <OutlinedInput
                                    notched
                                    label={t("miscellaneous.franchise")}
                                    sx={{
                                        fontSize: 16,
                                        fontWeight: 600,
                                    }}
                                />
                            }
                            renderValue={(selected) => {
                                if (selected.length === 0) {
                                    return <span>{t("miscellaneous.none")}</span>;
                                }
                                return selected.includes("all")
                                    ? t("miscellaneous.all")
                                    : selected
                                          .map((item) => {
                                              return franqDataProps.filter((item2) => item2.id === item)[0]
                                                  .franchise_name;
                                          })
                                          .join(", ");
                            }}
                            MenuProps={props.MenuProps}
                        >
                            {[
                                {
                                    id: "all",
                                    franchise_name: t("miscellaneous.all"),
                                },
                            ]
                                .concat(franqData)
                                .map((value) => (
                                    <MenuItem value={value.id}>
                                        <Checkbox
                                            defaultChecked
                                            checked={franqF.indexOf(value.id) > -1}
                                            icon={
                                                value.id === "all" && !franqF.includes("all") && franqF.length > 0 ? (
                                                    <IndeterminateCheckBoxIcon style={{ fill: "#031851" }} />
                                                ) : (
                                                    <CheckBoxOutlineBlankIcon />
                                                )
                                            }
                                        />
                                        <ListItemText primary={value.franchise_name} />
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                </GridUI>
                <GridUI item xs={12} md={4} lg={2}>
                    <FormControl fullWidth classes={{ root: classes.formRounded }}>
                        <InputLabel shrink id="group-simple-select-label">
                            {t("miscellaneous.group")}
                        </InputLabel>
                        <Select
                            size="small"
                            labelId="group-simple-select-label"
                            IconComponent={KeyboardArrowDownIcon}
                            value={groupF}
                            displayEmpty
                            onChange={handleChangeGroupF}
                            className="ddlGeneral"
                            multiple
                            input={
                                <OutlinedInput
                                    notched
                                    label={t("miscellaneous.group")}
                                    sx={{
                                        fontSize: 16,
                                        fontWeight: 600,
                                    }}
                                />
                            }
                            renderValue={(selected) => {
                                if (selected.length === 0) {
                                    return <span>{t("miscellaneous.none")}</span>;
                                }
                                return selected.includes("all")
                                    ? t("miscellaneous.all")
                                    : selected
                                          .map((item) => {
                                              return groupDataProps.filter((item2) => item2.id === item)[0].group_name;
                                          })
                                          .join(", ");
                            }}
                            MenuProps={props.MenuProps}
                        >
                            {[
                                {
                                    id: "all",
                                    group_name: t("miscellaneous.all"),
                                },
                            ]
                                .concat(groupData)
                                .map((value) => (
                                    <MenuItem value={value.id}>
                                        <Checkbox
                                            defaultChecked
                                            checked={groupF.indexOf(value.id) > -1}
                                            icon={
                                                value.id === "all" && !groupF.includes("all") && groupF.length > 0 ? (
                                                    <IndeterminateCheckBoxIcon style={{ fill: "#031851" }} />
                                                ) : (
                                                    <CheckBoxOutlineBlankIcon />
                                                )
                                            }
                                        />
                                        <ListItemText primary={value.group_name} />
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                </GridUI>
                <GridUI item xs={12} md={4} lg={2}>
                    <FormControl fullWidth classes={{ root: classes.formRounded }}>
                        <InputLabel shrink id="idStatus-simple-select-label">
                            {t("generalDashboard.market")}
                        </InputLabel>
                        <Select
                            size="small"
                            labelId="market-multiple-select-label"
                            IconComponent={KeyboardArrowDownIcon}
                            displayEmpty
                            value={clientMarket}
                            className="ddlGeneral"
                            onChange={handleChangeclientMarket}
                            multiple
                            input={
                                <OutlinedInput
                                    notched
                                    label={t("generalDashboard.market")}
                                    sx={{
                                        fontSize: 16,
                                        fontWeight: 600,
                                    }}
                                />
                            }
                            renderValue={(selected) => {
                                if (selected.length === 0) {
                                    return <span>{t("miscellaneous.none")}</span>;
                                }

                                return selected.includes(t("miscellaneous.all"))
                                    ? t("miscellaneous.all")
                                    : selected.join(", ");
                            }}
                            MenuProps={props.MenuProps}
                        >
                            {props.markets.map((name) => (
                                <MenuItem value={name}>
                                    <Checkbox
                                        checked={clientMarket.indexOf(name) > -1}
                                        icon={
                                            name === t("miscellaneous.all") &&
                                            !clientMarket.includes(t("miscellaneous.all")) &&
                                            clientMarket.length > 0 ? (
                                                <IndeterminateCheckBoxIcon style={{ fill: "#031851" }} />
                                            ) : (
                                                <CheckBoxOutlineBlankIcon />
                                            )
                                        }
                                    />
                                    <ListItemText primary={name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </GridUI>
                <GridUI item xs={12} md={4} lg={1}>
                    <FormControl fullWidth classes={{ root: classes.formRounded }}>
                        <InputLabel shrink id="idStatus-simple-select-label">
                            {t("miscellaneous.status")}
                        </InputLabel>
                        <Select
                            size="small"
                            labelId="idStatus-simple-select-label"
                            IconComponent={KeyboardArrowDownIcon}
                            value={idStatus}
                            displayEmpty
                            onChange={handleChangeIdStatus}
                            className="ddlGeneral"
                            multiple
                            input={
                                <OutlinedInput
                                    notched
                                    label={t("miscellaneous.status")}
                                    sx={{
                                        fontSize: 16,
                                        fontWeight: 600,
                                    }}
                                />
                            }
                            renderValue={(selected) => {
                                if (selected.length === 0) {
                                    return <span>{t("miscellaneous.all")}</span>;
                                }
                                return selected.includes("all")
                                    ? t("miscellaneous.all")
                                    : selected
                                          .map((item) => {
                                              return statusArray.filter((item2) => item2.id === item)[0].name;
                                          })
                                          .join(", ");
                            }}
                            MenuProps={props.MenuProps}
                        >
                            {statusArray.map((value) => (
                                <MenuItem value={value.id}>
                                    <Checkbox
                                        defaultChecked
                                        checked={idStatus.indexOf(value.id) > -1}
                                        icon={
                                            value.id === "all" && !idStatus.includes("all") && idStatus.length > 0 ? (
                                                <IndeterminateCheckBoxIcon style={{ fill: "#031851" }} />
                                            ) : (
                                                <CheckBoxOutlineBlankIcon />
                                            )
                                        }
                                    />
                                    <ListItemText primary={value.name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </GridUI>
                <GridUI item xs={12} md={4} lg>
                    <FormControl fullWidth classes={{ root: classes.formRounded }}>
                        <InputLabel shrink id="clientStatus-simple-select-label">
                            {t("miscellaneous.classification")}
                        </InputLabel>
                        <Select
                            size="small"
                            IconComponent={KeyboardArrowDownIcon}
                            value={clientStatus}
                            displayEmpty
                            onChange={handleChangeClasif}
                            className="ddlGeneral"
                            input={<OutlinedInput notched label={t("miscellaneous.classification")} />}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        mt: 0.7,
                                        borderRadius: 2,
                                        boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                    },
                                },
                            }}
                        >
                            <MenuItem value="">
                                <Typography variant="body1" component="span" align="center" color="textPrimary">
                                    {t("miscellaneous.all")}
                                </Typography>
                            </MenuItem>
                            <MenuItem value={1}>
                                <Typography
                                    variant="body1"
                                    component="span"
                                    align="center"
                                    color="textPrimary"
                                    sx={{
                                        fontStyle: "italic",
                                        ...(moment.locale() === "en" && { fontStyle: "normal" }),
                                    }}
                                >
                                    Standard
                                </Typography>
                            </MenuItem>
                            <MenuItem value={2}>
                                <Typography
                                    variant="body1"
                                    component="span"
                                    align="center"
                                    color="textPrimary"
                                    sx={{
                                        fontStyle: "italic",
                                        ...(moment.locale() === "en" && { fontStyle: "normal" }),
                                    }}
                                >
                                    VIP
                                </Typography>
                            </MenuItem>
                        </Select>
                    </FormControl>
                </GridUI>
                <GridUI item xs={12} md={4} lg>
                    <ButtonGroup
                        disableElevation
                        variant="outlined"
                        ref={anchorRefBtnGroup}
                        aria-label="split button"
                        color="inherit"
                        disabled={resultTable.pendingCont === 0 && selectedValueCont === 0}
                    >
                        <LightTooltip title={t("miscellaneous.sendFinancialStatements")} aria-label="comment-tooltip">
                            <Button
                                disableElevation
                                variant="contained"
                                color="secondary"
                                sx={{
                                    mr: 0.5,
                                    borderRadius: 40,
                                    "&:hover": {
                                        backgroundColor: "#2f3190",
                                        color: "#fff",
                                    },
                                    "&.Mui-disabled": {
                                        color: "#fff",
                                        borderColor: "#ffcdd2",
                                        background: "#ffcdd2",
                                    },
                                    width: 107,
                                }}
                                onClick={handleOpenDialog}
                            >
                                {t("miscellaneous.report")}
                            </Button>
                        </LightTooltip>
                        <Button
                            size="small"
                            aria-controls={openBtnGroupSend ? "split-button-menu" : undefined}
                            aria-expanded={openBtnGroupSend ? "true" : undefined}
                            aria-label="select merge strategy"
                            aria-haspopup="menu"
                            disableElevation
                            variant="contained"
                            color="secondary"
                            sx={{
                                borderRadius: 40,
                                "&:hover": {
                                    backgroundColor: "#2f3190",
                                    color: "#fff",
                                },
                                "&.Mui-disabled": {
                                    color: "#fff",
                                    borderColor: "#ffcdd2",
                                    background: "#ffcdd2",
                                },
                                width: 15,
                            }}
                            onClick={handleToggleBtnGroup}
                        >
                            <ExpandMoreIcon sx={{ color: "#FFFFF" }} />
                        </Button>
                    </ButtonGroup>
                    <Popper
                        sx={{
                            zIndex: 1,
                        }}
                        open={openBtnGroupSend}
                        anchorEl={anchorRefBtnGroup.current}
                        role={undefined}
                        transition
                        disablePortal
                    >
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                                }}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={handleCloseBtnGroup}>
                                        <MenuList id="split-button-menu" autoFocusItem>
                                            {optionsGroupSend.map(({ id, data, disable, tooltip }, index) => (
                                                <LightTooltip title={tooltip} placement="left">
                                                    <MenuItem
                                                        key={id + data}
                                                        selected={index === selectedIndexBtnGroup}
                                                        onClick={(event) => handleMenuItemClickBtnGroup(event, index)}
                                                        disabled={disable}
                                                    >
                                                        {data}
                                                    </MenuItem>
                                                </LightTooltip>
                                            ))}
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </GridUI>
            </GridUI>
            <GridUI container>
                <GridUI item xs={12} md={6} sx={{ mb: { sm: -5 }, zIndex: 1 }}>
                    <Button
                        variant="text"
                        color="primary"
                        disabled={anyFilter}
                        onClick={cleanFilters}
                        sx={{
                            "&:hover": {
                                color: "#2f3190",
                            },
                        }}
                    >
                        {t("miscellaneous.cleanFilters")}
                    </Button>
                </GridUI>
                <GridUI item xs={12}>
                    <Grid rows={clientsData} columns={columnDefs}>
                        <SearchState value={searchValue} onValueChange={handleSearch} />
                        <IntegratedFiltering />
                        <PagingState defaultCurrentPage={0} pageSize={10} />
                        <IntegratedPaging />
                        <Table
                            tableComponent={TableComponent}
                            columnExtensions={tableColumnExtensions}
                            cellComponent={TableCellComponent}
                            messages={tableMessages}
                        />
                        <TableColumnVisibility defaultHiddenColumnNames={defaultHiddenColumnNames} />
                        <TableHeaderRow cellComponent={tableHeaderCell} contentComponent={tableContentComponent} />
                        <Toolbar rootComponent={toolRootComponent} />
                        <SearchPanel messages={searchMessages} inputComponent={inputComponent} />
                        <PagingPanel containerComponent={PagingPanelContainer} messages={pagingMessages} />
                    </Grid>
                </GridUI>
            </GridUI>
            <CommentDialog
                open={dialogComment}
                onClose={closeDialog}
                maxWidth="xs"
                clientID={clientID}
                setTexto={setTexto}
                seAutoAlertType={seAutoAlertType}
                setBackDrop={setBackDrop}
                setShow={setShow}
                comments={comments}
                commentsP={commentsP}
                getComments={getComments}
                setInitData={loadInitData}
                year={selectedYear}
                commentType={1}
            />
            <SnackBar type={alertType} show={show} message={texto} onClose={handleClose} />
            <Backdrop open={showBackdrop} />
            <UploadFiles
                open={open}
                onClose={() => setOpen(false)}
                idOrganisation={idOrganisation}
                filesData={filesData}
                setFilesData={setFilesData}
                setBackDrop={setBackDrop}
                setTexto={setTexto}
                setShow={setShow}
                setalertType={seAutoAlertType}
            />
            <DialogAlerts
                open={openAlert}
                onClose={handleCloseDialog}
                agreeBtnLabel={t("miscellaneous.accept")}
                disagreeBtnLabel={t("miscellaneous.cancel")}
                type={dialogType}
                title={dialogText}
                agreeAction={() => notifyMonths(0)}
            />
            <DialogAlerts
                open={openAlertExpressServices}
                onClose={handleCloseDialogExpressService}
                agreeBtnLabel={t("miscellaneous.accept")}
                disagreeBtnLabel={t("miscellaneous.cancel")}
                type={dialogType}
                title={dialogText}
                agreeAction={() => expressServices()}
            />
            <DialogAlerts
                open={openModalMails}
                onClose={handleCloseDialog}
                agreeBtnLabel={t("miscellaneous.send")}
                disagreeBtnLabel={t("miscellaneous.cancel")}
                message={<ModalRep motiveDec={motiveDec} setMotiveDec={setMotiveDec} />}
                agreeAction={() => notifyMonths(1)}
            />
            <DialogAlerts
                open={openModalMails2}
                onClose={handleCloseDialog}
                agreeBtnLabel={t("miscellaneous.send")}
                disagreeBtnLabel={t("miscellaneous.cancel")}
                message={<ModalRep motiveDec={motiveDec2} setMotiveDec={setMotiveDec2} />}
                agreeAction={() => notifyMonths(3)}
            />
        </StyledContainer>
    );
};

export default ClientsReport;
