/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import moment from "moment";

/** MUI icons */
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
import CommentIcon from "@mui/icons-material/Comment";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import WarningAmberIcon from "@mui/icons-material/Warning";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

/** MUI imports */
import {
    Grid,
    Table,
    TableHeaderRow,
    PagingPanel,
    Toolbar,
    SearchPanel,
    TableSelection,
    TableColumnVisibility,
} from "@devexpress/dx-react-grid-material-ui";
import {
    SearchState,
    IntegratedFiltering,
    PagingState,
    IntegratedPaging,
    SelectionState,
    IntegratedSelection,
} from "@devexpress/dx-react-grid";
import { styled } from "@mui/material/styles";
import GridUI from "@mui/material/Grid";
import {
    Box,
    Breadcrumbs,
    Button,
    Card,
    CardContent,
    FormControl,
    IconButton,
    InputLabel,
    ListItem,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    Skeleton,
    Stack,
    TextField,
    Typography,
    Badge,
    Checkbox,
} from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

/** Components imports */
import Backdrop from "../Backdrop";
import DialogAlerts from "../DialogAlerts";
import ModProspectDetails from "./ModProspectDetails";
import SnackBar from "../SnackBar";
import CommDialProp from "../CommentDialProposal";

/** Component styles */
const PREFIX = "ManageProposals";

const classes = {
    cardContentRoot: `${PREFIX}-cardContentRoot`,
    formRounded: `${PREFIX}-formRounded`,
    spacing: `${PREFIX}-spacing`,
    tableStriped: `${PREFIX}-tableStriped`,
    tableHeader: `${PREFIX}-tableHeader`,
    tableBody: `${PREFIX}-tableBody`,
    checkBoxDisabled: `${PREFIX}-checkBoxDisabled`,
    pager: `${PREFIX}-pager`,
    toolbarSearch: `${PREFIX}-toolbarSearch`,
    searchInput: `${PREFIX}-searchInput`,
    detailsCel: `${PREFIX}-detailsCel`,
};

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "rgba(19, 31, 62, 0.80)",
        fontSize: 12,
        maxWidth: 320,
        borderRadius: 6,
        fontWeight: "normal",
    },
});

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 48 * 4.5 + 8,
            boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
        },
        sx: {
            borderRadius: 2,
            mt: 0.7,
        },
    },
    variant: "menu",
    getContentAnchorEl: null,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.cardContentRoot}`]: {
        "&:last-child": {
            paddingBottom: "18px",
        },
        padding: 30,
    },

    [`& .${classes.formRounded}`]: {
        backgroundColor: "#FFFFFF !important",
        borderRadius: 40,
        verticalAlign: "middle !important",
        "&:focus-within": {
            backgroundColor: "#FFFFFF",
            borderRadius: 40,
        },
        "& .MuiOutlinedInput-notchedOutline": {
            border: "2px solid #031851",
            borderRadius: 40,
        },
    },

    [`& .${classes.spacing}`]: {
        padding: "20px 0",
    },

    [`& .${classes.tableStriped}`]: {
        "& tbody tr:nth-of-type(odd)": {
            backgroundColor: "rgba(47,49,144,0.1)",
        },
    },
    [`& .${classes.tableHeader}`]: {
        border: "none",
        backgroundColor: "rgba(47,49,144,0.3)",
        fontSize: "15px",
        fontWeight: 600,
        color: "#031851",
        "&:first-of-type": {
            borderRadius: "20px 0 0 0",
        },
        "&:last-child": {
            borderRadius: "0 20px 0 0",
        },
    },

    [`& .${classes.tableBody}`]: {
        border: "none",
        fontSize: "14px",
        fontWeight: 400,
        color: "#031851",
    },

    [`& .${classes.checkBoxDisabled}`]: {
        border: "none",
        fontSize: "14px",
        fontWeight: 400,
        "& .MuiCheckbox-root": {
            color: "#cccccc",
        },
        pointerEvents: "none",
    },

    [`& .${classes.pager}`]: {
        "& .Pagination-button": {
            paddingTop: 0,
            paddingBottom: 0,
            color: "#031851",
            fontWeight: 600,
        },
        "& .Pagination-activeButton": {
            backgroundColor: "rgba(47,49,144,0.3)",
        },
    },

    [`& .${classes.toolbarSearch}`]: {
        border: "none !important",
        padding: "0 0 5px !important",
    },

    [`& .${classes.searchInput}`]: {
        color: "#031851",
        "&:before": {
            borderBottomColor: "#031851",
        },
        "&:hover:before": {
            borderBottomColor: "#031851 !important",
        },
    },

    [`& .${classes.detailsCel}`]: {
        color: "#2f3190",
        cursor: "pointer",
        padding: "7px",
        borderRadius: "6px",
        "&:hover": {
            borderRadius: "6px",
            backgroundColor: "rgba(47,49,144,0.3)",
        },
    },
}));

const tableHeaderCell = ({ ...props }) => <TableHeaderRow.Cell {...props} className={classes.tableHeader} />;

const TableComponent = ({ ...props }) => <Table.Table {...props} className={classes.tableStriped} />;

const TableCellComponent = ({ ...props }) => <Table.Cell {...props} className={classes.tableBody} />;

const tableContentComponent = (props) => <TableHeaderRow.Content {...props} />;

const PagingPanelContainer = ({ ...props }) => <PagingPanel.Container {...props} className={classes.pager} />;

const inputComponent = ({ ...props }) => <SearchPanel.Input {...props} className={classes.searchInput} />;

const toolRootComponent = ({ ...props }) => <Toolbar.Root {...props} className={classes.toolbarSearch} />;

const tableHeaderSelCellBase = ({ ...props }) => (
    <TableSelection.HeaderCell {...props} className={classes.tableHeader} />
);

const tableColumnExtensions = [
    { columnName: "reference", align: "center" },
    { columnName: "name" },
    { columnName: "company", wordWrapEnabled: true, width: "20%" },
    { columnName: "businessEmail", align: "center" },
    { columnName: "services", align: "center", wordWrapEnabled: true, width: "20%" },
    { columnName: "status", align: "center" },
    { columnName: "deliveryDate", align: "center" },
    { columnName: "user", align: "center" },
    { columnName: "details", align: "center" },
    { columnName: "comments", align: "center" },
];

const rowsSkeleton = [];

for (let i = 0; i < 10; i++) {
    rowsSkeleton.push({
        id: i,
        reference: <Skeleton variant="text" animation="wave" />,
        name: <Skeleton variant="text" animation="wave" />,
        company: <Skeleton variant="text" animation="wave" />,
        businessEmail: <Skeleton variant="text" animation="wave" />,
        services: <Skeleton variant="text" animation="wave" />,
        status: <Skeleton variant="text" animation="wave" />,
        deliveryDate: <Skeleton variant="text" animation="wave" />,
        user: <Skeleton variant="text" animation="wave" />,
        details: <Skeleton variant="text" animation="wave" />,
        comments: (
            <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />
        ),
    });
}

const ManageProposals = (props) => {
    const navigate = useNavigate();

    /** Internationalization i18n */
    const [t] = useTranslation("global");

    const services = [
        t("miscellaneous.all"),
        "Accountant",
        "Controller",
        "CFO",
        "Tax Expert",
        "Bookkeeper",
        "Compilation",
        "Review",
        "Software setup",
        "Software subscription",
        "Consulting",
        "Other taxes",
        "Other services",
    ];

    const allStatus = [
        t("miscellaneous.all"),
        t("proposals.draft"),
        t("proposals.sent"),
        t("proposals.accepted"),
        t("proposals.rejected"),
        t("proposals.overdue"),
    ];

    const tableMessages = {
        noData: t("miscellaneous.noData"),
    };

    const pagingMessages = {
        info: ({ from, to, count }) => `${from}${from < to ? `-${to}` : ""} ${t("miscellaneous.pagingOf")} ${count}`,
    };

    const searchMessages = {
        searchPlaceholder: t("miscellaneous.search"),
    };

    const noColumnsMessage = {
        noColumns: "",
    };

    /** React redux */
    const todos = useSelector((state) => state.todos);

    /** Component states */
    const [showBackdrop, setBackDrop] = useState(false);
    const [show, setShow] = useState(false);
    const [texto, setTexto] = useState("");
    const [alertType, setAutoAlertType] = useState("");
    const [serviceF, setServiceF] = useState(services);
    const [statusF, setStatusF] = useState(allStatus);
    const [sinceDateF, setSinceDateF] = useState(null);
    const [untilDateF, setUntilDateF] = useState(null);
    const [openDialogAlert, setOpenDialogAlert] = useState(false);
    const [dialogAlertText, setDialogAlertText] = useState("");
    const [dialogAlertType, setDialogAlertType] = useState("info");
    const [openProspectDialog, setOpenProspectDialog] = useState(false);
    const [searchValue, setSearchState] = useState("");
    const [proposalsData, setProposalsData] = useState([]);
    const [columnDefs, setColumnsDefs] = useState([]);
    const [defaultHiddenColumnNames] = useState(["hiddenStatus"]);
    const [selection, setSelection] = useState([]);
    const [valuesProspect, setValuesProspect] = useState({
        id: "",
        name: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        type: "",
        ambassador: "",
        creationDate: "",
        companies: [],
        market: "",
        activity: "",
    });
    const [comments, setComments] = useState([]);
    const [commentsProposal, setCommentsProposal] = useState();
    const [commentsProspect, setCommentsProspect] = useState();
    const [dialogComment, setDialogComment] = useState(false);
    const [proposalID, setProposalID] = useState("");
    const [anyFilter, setAnyFilter] = useState(true);
    const [companyName, setCompanyName] = useState("");

    /** Component functions */
    const rowSelectionEnabled = (row) => row.hiddenStatus === t("proposals.draft");

    class PatchedTableSelection extends React.PureComponent {
        render() {
            const { rowSelectionEnabled, ...restProps } = this.props;
            return (
                <TableSelection
                    cellComponent={(props) =>
                        this.props.rowSelectionEnabled(props.tableRow.row) ? (
                            <TableSelection.Cell {...props} className={classes.tableBody} />
                        ) : (
                            <Table.StubCell {...props} className={classes.tableBody} />
                        )
                    }
                    {...restProps}
                />
            );
        }
    }

    useEffect(() => {
        (async () => {
            const columnsTemplate = [
                { name: "hiddenStatus", title: "Hidden Status" },
                { name: "reference", title: t("proposals.reference") },
                { name: "name", title: t("proposals.name") },
                { name: "company", title: t("proposals.company") },
                { name: "businessEmail", title: t("proposals.businessEmail") },
                { name: "services", title: t("proposals.services") },
                { name: "status", title: t("miscellaneous.status") },
                { name: "deliveryDate", title: t("proposals.dateSent") },
                { name: "user", title: t("proposals.responsible") },
                { name: "details", title: " " },
                { name: "comments", title: " " },
            ];
            setColumnsDefs(columnsTemplate);

            await loadInitData();
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t, todos.dateFormat, serviceF, statusF, sinceDateF, untilDateF]);

    const cleanFilters = () => {
        setAnyFilter(true);
        setServiceF(services);
        setStatusF(allStatus);
        setSinceDateF(null);
        setUntilDateF(null);
    };

    const openComments = async (proposalID, company_name) => {
        setBackDrop(true);

        setProposalID(proposalID);
        setCompanyName(company_name);

        await getComments(proposalID);

        setDialogComment(true);

        setBackDrop(false);
    };

    const getComments = async (proposalID) => {
        await fetch(`/getComments?proposal_id=${proposalID}&comment_type=2`, {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then((res) => res.json())
            .then((data) => {
                setComments(data.all);
                setCommentsProposal(data.proposal);
                setCommentsProspect(data.prospect);
            });
    };

    const closeCommentsDialog = () => {
        setDialogComment(false);
    };

    const loadInitData = async () => {
        setProposalsData(rowsSkeleton);

        let finalServices = [];

        if (serviceF.length === 0) {
            finalServices = ["none"];
        } else if (!serviceF.includes(t("miscellaneous.all"))) {
            finalServices = [...serviceF];
        }

        let finalStatus = [];

        if (statusF.length === 0) {
            finalStatus = ["none"];
        } else if (!statusF.includes(t("miscellaneous.all"))) {
            statusF.forEach((item) => {
                if (item === t("proposals.draft")) {
                    finalStatus.push(1);
                }
                if (item === t("proposals.sent")) {
                    finalStatus.push(2);
                }
                if (item === t("proposals.accepted")) {
                    finalStatus.push(3);
                }
                if (item === t("proposals.rejected")) {
                    finalStatus.push(4);
                }
                if (item === t("proposals.overdue")) {
                    finalStatus.push(5);
                }
            });
        }

        const resultT = await fetch(
            `/getProposals?service=${finalServices}&status=${finalStatus}&since_date=${
                !sinceDateF ? "" : sinceDateF.format()
            }&end_date=${!untilDateF ? "" : untilDateF.format()}`,
            {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            }
        )
            .then((res) => res.json())
            .then((data) => {
                return data;
            });

        let proposals = resultT.map((item) => {
            let cont = 0;

            item.comments.forEach((data) => {
                if (data.status === 0) {
                    cont++;
                }
            });

            return {
                id: item._id,
                hiddenStatus:
                    item.status === 1
                        ? t("proposals.draft")
                        : item.status === 2
                        ? item.delivery_date && moment().diff(item.delivery_date, "weeks") <= 2
                            ? t("proposals.sent")
                            : t("proposals.overdue")
                        : item.status === 3
                        ? t("proposals.accepted")
                        : t("proposals.rejected"),
                reference: item.reference,
                name: item.prospect_name,
                company: item.company_name,
                businessEmail: item.prospect_email,
                services: item.services.join(", "),
                status: (
                    <ListItem
                        alignItems="center"
                        secondaryAction={
                            item.status === 1 ? (
                                moment().diff(item.creation_date, "weeks") < 1 ? (
                                    <WarningAmberIcon
                                        sx={{
                                            color: "#FFB039",
                                        }}
                                    />
                                ) : (
                                    <LightTooltip title={t("proposals.dueDraft")} aria-label="comment-tooltip">
                                        <WarningAmberIcon
                                            sx={{
                                                color: "#ff5968",
                                            }}
                                        />
                                    </LightTooltip>
                                )
                            ) : item.status === 2 ? (
                                item.delivery_date && moment().diff(item.delivery_date, "weeks") <= 2 ? (
                                    <AccessTimeFilledIcon
                                        sx={{
                                            color: "#FFB039",
                                        }}
                                    />
                                ) : (
                                    <LightTooltip title={t("proposals.dueProposal")} aria-label="comment-tooltip">
                                        <AccessTimeFilledIcon
                                            sx={{
                                                color: "#ff5968",
                                            }}
                                        />
                                    </LightTooltip>
                                )
                            ) : item.status === 3 ? (
                                <CheckIcon sx={{ color: "green" }} />
                            ) : (
                                <CancelIcon color="secondary" />
                            )
                        }
                        sx={{ ml: "auto", mr: "auto", width: 145 }}
                    >
                        <ListItemText
                            primary={
                                item.status === 1
                                    ? t("proposals.draft")
                                    : item.status === 2
                                    ? item.delivery_date && moment().diff(item.delivery_date, "weeks") <= 2
                                        ? t("proposals.sent")
                                        : t("proposals.overdue")
                                    : item.status === 3
                                    ? t("proposals.accepted")
                                    : t("proposals.rejected")
                            }
                            sx={{ textAlign: "left" }}
                        />
                    </ListItem>
                ),
                deliveryDate: item.delivery_date
                    ? moment(item.delivery_date, "YYYY-MM-DD").format(todos.dateFormat)
                    : "-",
                user: item.creation_user ?? "-",
                details: (
                    <Typography
                        component="span"
                        variant="h4"
                        className={classes.detailsCel}
                        onClick={() => handleOpenProposalDetails(item)}
                    >
                        {t("proposals.viewDetail")}
                    </Typography>
                ),
                comments: (
                    <LightTooltip title={t("miscellaneous.comments")} aria-label="comment-tooltip">
                        <IconButton aria-label="comment" onClick={() => openComments(item._id, item.company_name)}>
                            <Badge badgeContent={cont} color="secondary">
                                <CommentIcon sx={{ color: "#031851" }} />
                            </Badge>
                        </IconButton>
                    </LightTooltip>
                ),
            };
        });

        setProposalsData(proposals);
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setShow(false);
    };

    const handleSearch = (value) => {
        let itemS = value.replace(/ +(?= )/g, "");
        setSearchState(itemS);
    };

    const handleOpenDialogAlert = () => {
        setDialogAlertType("info");
        setDialogAlertText(t("proposals.proposalsSureDelete"));
        setOpenDialogAlert(true);
    };

    const handleCloseDialogAlert = () => {
        setDialogAlertText("");
        setDialogAlertType("");
        setOpenDialogAlert(false);
    };

    const handleChangeServiceF = (event) => {
        const {
            target: { value },
        } = event;

        let final = [];

        if (value.includes(t("miscellaneous.all")) && !serviceF.includes(t("miscellaneous.all"))) {
            final = [...services];
        } else if (!value.includes(t("miscellaneous.all")) && serviceF.includes(t("miscellaneous.all"))) {
            final = [];
        } else {
            if (
                !value.includes(t("miscellaneous.all")) &&
                value.length === services.filter((item) => item !== t("miscellaneous.all")).length
            ) {
                final = [...services];
            } else {
                final =
                    typeof value === "string"
                        ? value.split(",").filter((item) => item !== t("miscellaneous.all"))
                        : value.filter((item) => item !== t("miscellaneous.all"));
            }
        }

        setServiceF(final);
        setAnyFilter(false);
    };

    const handleChangeStatusF = (event) => {
        const {
            target: { value },
        } = event;

        let final = [];

        if (value.includes(t("miscellaneous.all")) && !statusF.includes(t("miscellaneous.all"))) {
            final = [...allStatus];
        } else if (!value.includes(t("miscellaneous.all")) && statusF.includes(t("miscellaneous.all"))) {
            final = [];
        } else {
            if (
                !value.includes(t("miscellaneous.all")) &&
                value.length === allStatus.filter((item) => item !== t("miscellaneous.all")).length
            ) {
                final = [...allStatus];
            } else {
                final =
                    typeof value === "string"
                        ? value.split(",").filter((item) => item !== t("miscellaneous.all"))
                        : value.filter((item) => item !== t("miscellaneous.all"));
            }
        }

        setStatusF(final);
        setAnyFilter(false);
    };

    const handleChangeSinceDate = (date) => {
        let sinceDate = moment(date).utc().startOf("day");

        if (date === null) {
            setSinceDateF(date);
        } else if (untilDateF === null) {
            setSinceDateF(sinceDate);
        } else {
            if (moment(date).isAfter(moment(untilDateF))) {
                setSinceDateF(null);
                setTexto(t("reports.initDateMess"));
                setAutoAlertType("warning");
                setShow(true);
            } else {
                setSinceDateF(sinceDate);
            }
        }
    };

    const handleChangeUntilDate = (date) => {
        let endDate = moment(date).utc().startOf("day");

        if (date === null) {
            setUntilDateF(date);
        } else if (sinceDateF === null) {
            setUntilDateF(endDate);
        } else {
            if (moment(date).isBefore(moment(sinceDateF))) {
                setUntilDateF(null);
                setTexto(t("reports.endDateMess"));
                setAutoAlertType("warning");
                setShow(true);
            } else {
                setUntilDateF(endDate);
            }
        }
    };

    const handleOpenProposalDetails = (item) => {
        if (item.status === 1) {
            //Borrador
            navigate(`/proposals/createProposal?proposalID=${item._id}&edit=true`);
        } else {
            navigate(`/proposals/createProposal?proposalID=${item._id}`);
        }
    };

    const deleteProposals = async () => {
        setBackDrop(true);

        let selectedProposals = [];

        selection.forEach((selectedRow) => {
            if (proposalsData[selectedRow].hiddenStatus === t("proposals.draft"))
                selectedProposals.push(proposalsData[selectedRow].id);
        });

        const params = {
            array_proposals: selectedProposals,
        };

        await fetch("/deleteProposal", {
            method: "POST",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
            body: JSON.stringify(params),
        })
            .then(() => {
                handleCloseDialogAlert();
                setSelection([]);
                loadInitData();
                setTexto(t("proposals.deletedProposals"));
                setAutoAlertType("success");
                setShow(true);
            })
            .catch((err) => {
                setTexto(err.error);
                setAutoAlertType("error");
                setShow(true);
            });

        setBackDrop(false);
    };

    return (
        <StyledContainer component="main" sx={{ flexGrow: 1, p: 4, pt: 10, width: "90%" }}>
            <Stack sx={{ pb: 4 }}>
                <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small" sx={{ color: "#031851" }} />}
                    aria-label="breadcrumb"
                >
                    {
                        <Typography
                            key="1"
                            variant="h1"
                            color="primary"
                            onClick={() => navigate("/proposals")}
                            sx={{
                                cursor: "pointer",
                                fontSize: 24,
                                fontWeight: 700,
                                "&:hover": {
                                    color: "#ff5968",
                                },
                            }}
                        >
                            {t("proposals.proposals")}
                        </Typography>
                    }
                    {
                        <Typography key="1" variant="h1" color="secondary" sx={{ fontSize: 24, fontWeight: 700 }}>
                            {t("proposals.manageProposals")}
                        </Typography>
                    }
                </Breadcrumbs>
            </Stack>
            <Card
                elevation={0}
                sx={{
                    borderRadius: 8,
                    boxShadow: "10px 10px 15px rgba(3,24,81,0.15)",
                }}
            >
                <CardContent classes={{ root: classes.cardContentRoot }}>
                    <GridUI container spacing={2} className={classes.spacing} alignItems="center">
                        <GridUI item xs={12} md={4} xl={2}>
                            <FormControl fullWidth classes={{ root: classes.formRounded }}>
                                <InputLabel shrink id="service-simple-select-label">
                                    {t("miscellaneous.service")}
                                </InputLabel>
                                <Select
                                    size="small"
                                    IconComponent={KeyboardArrowDownIcon}
                                    value={serviceF}
                                    displayEmpty
                                    onChange={handleChangeServiceF}
                                    className="ddlGeneral"
                                    multiple
                                    input={
                                        <OutlinedInput
                                            notched
                                            sx={{
                                                fontSize: 16,
                                                fontWeight: 600,
                                            }}
                                            label={t("miscellaneous.service")}
                                        />
                                    }
                                    renderValue={(selected) => {
                                        if (selected.length === 0) {
                                            return <span>{t("miscellaneous.none")}</span>;
                                        }

                                        return selected.includes(t("miscellaneous.all"))
                                            ? t("miscellaneous.all")
                                            : selected.join(", ");
                                    }}
                                    MenuProps={MenuProps}
                                >
                                    {services.map((service) => (
                                        <MenuItem value={service}>
                                            <Checkbox
                                                checked={serviceF.indexOf(service) > -1}
                                                icon={
                                                    service === t("miscellaneous.all") &&
                                                    !serviceF.includes(t("miscellaneous.all")) &&
                                                    serviceF.length > 0 ? (
                                                        <IndeterminateCheckBoxIcon style={{ fill: "#031851" }} />
                                                    ) : (
                                                        <CheckBoxOutlineBlankIcon />
                                                    )
                                                }
                                            />
                                            <ListItemText primary={service} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </GridUI>
                        <GridUI item xs={12} md={4} xl={2}>
                            <FormControl fullWidth classes={{ root: classes.formRounded }}>
                                <InputLabel shrink id="status-simple-select-label">
                                    {t("miscellaneous.status")}
                                </InputLabel>
                                <Select
                                    size="small"
                                    IconComponent={KeyboardArrowDownIcon}
                                    value={statusF}
                                    displayEmpty
                                    onChange={handleChangeStatusF}
                                    className="ddlGeneral"
                                    multiple
                                    input={
                                        <OutlinedInput
                                            notched
                                            sx={{
                                                fontSize: 16,
                                                fontWeight: 600,
                                            }}
                                            label={t("miscellaneous.status")}
                                        />
                                    }
                                    MenuProps={MenuProps}
                                    renderValue={(selected) => {
                                        if (selected.length === 0) {
                                            return <span>{t("miscellaneous.none")}</span>;
                                        }

                                        return selected.includes(t("miscellaneous.all"))
                                            ? t("miscellaneous.all")
                                            : selected.join(", ");
                                    }}
                                >
                                    {allStatus.map((item) => (
                                        <MenuItem value={item}>
                                            <Checkbox
                                                checked={statusF.indexOf(item) > -1}
                                                icon={
                                                    item === t("miscellaneous.all") &&
                                                    !statusF.includes(t("miscellaneous.all")) &&
                                                    statusF.length > 0 ? (
                                                        <IndeterminateCheckBoxIcon style={{ fill: "#031851" }} />
                                                    ) : (
                                                        <CheckBoxOutlineBlankIcon />
                                                    )
                                                }
                                            />
                                            <ListItemText primary={item} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </GridUI>
                        <GridUI item xs={12} md={4} xl={3}>
                            <LocalizationProvider
                                dateAdapter={AdapterMoment}
                                adapterLocale={t("language.locale")}
                                localeText={{ clearButtonLabel: t("miscellaneous.clear") }}
                            >
                                <GridUI container justifyContent="center">
                                    <GridUI item xs={6}>
                                        <DatePicker
                                            componentsProps={{
                                                actionBar: { actions: ["clear"] },
                                            }}
                                            value={sinceDateF}
                                            onChange={(newValue) => handleChangeSinceDate(newValue)}
                                            inputFormat={todos.dateFormat}
                                            renderInput={({ inputProps, ...params }) => (
                                                <TextField
                                                    fullWidth
                                                    {...params}
                                                    inputProps={{
                                                        ...inputProps,
                                                        placeholder: t("invoicing.initDate"),
                                                        sx: {
                                                            p: "10.45px 0px 10.45px 18px !important",
                                                        },
                                                    }}
                                                    sx={{
                                                        "& .MuiOutlinedInput-notchedOutline": {
                                                            mr: "-6px !important",
                                                            border: "2px solid #031851",
                                                            borderRadius: "40px 0px 0px 40px",
                                                        },
                                                    }}
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                            )}
                                            label={t("invoicing.range")}
                                        />
                                    </GridUI>
                                    <GridUI item xs={6}>
                                        <DatePicker
                                            componentsProps={{
                                                actionBar: { actions: ["clear"] },
                                            }}
                                            value={untilDateF}
                                            onChange={(newValue) => handleChangeUntilDate(newValue)}
                                            inputFormat={todos.dateFormat}
                                            renderInput={({ inputProps, ...params }) => (
                                                <TextField
                                                    fullWidth
                                                    {...params}
                                                    inputProps={{
                                                        ...inputProps,
                                                        placeholder: t("invoicing.endDate"),
                                                        sx: {
                                                            p: "10.45px 0px 10.45px 18px !important",
                                                        },
                                                    }}
                                                    sx={{
                                                        ml: 0.5,
                                                        "& .MuiOutlinedInput-notchedOutline": {
                                                            border: "2px solid #031851",
                                                            borderRadius: "0px 40px 40px 0px",
                                                        },
                                                    }}
                                                />
                                            )}
                                        />
                                    </GridUI>
                                </GridUI>
                            </LocalizationProvider>
                        </GridUI>
                        <GridUI item xs={6} md={2} xl={5}>
                            <GridUI container justifyContent="flex-end" spacing={2}>
                                <GridUI item>
                                    <Button
                                        color="secondary"
                                        disabled={selection.length === 0}
                                        disableElevation
                                        variant="contained"
                                        onClick={handleOpenDialogAlert}
                                        sx={{
                                            pl: 3,
                                            pr: 3,
                                            borderRadius: 40,
                                            "&:hover": {
                                                backgroundColor: "#2f3190",
                                                color: "#fff",
                                            },
                                            "&.Mui-disabled": {
                                                color: "#fff",
                                                borderColor: "#ffcdd2",
                                                background: "#ffcdd2",
                                            },
                                        }}
                                    >
                                        {t("miscellaneous.delete")}
                                    </Button>
                                </GridUI>
                            </GridUI>
                        </GridUI>
                    </GridUI>
                    <GridUI container>
                        <GridUI item xs={12} md={6} sx={{ mb: { sm: -5 }, zIndex: 1 }}>
                            <Button
                                variant="text"
                                color="primary"
                                disabled={anyFilter}
                                onClick={cleanFilters}
                                sx={{
                                    "&:hover": {
                                        color: "#2f3190",
                                    },
                                }}
                            >
                                {t("miscellaneous.cleanFilters")}
                            </Button>
                        </GridUI>
                        <GridUI item xs={12}>
                            <Grid rows={proposalsData} columns={columnDefs}>
                                <SelectionState selection={selection} onSelectionChange={setSelection} />
                                <SearchState value={searchValue} onValueChange={handleSearch} />
                                <IntegratedFiltering />
                                <PagingState defaultCurrentPage={0} pageSize={10} />
                                <IntegratedPaging />
                                <IntegratedSelection />
                                <Table
                                    tableComponent={TableComponent}
                                    columnExtensions={tableColumnExtensions}
                                    cellComponent={TableCellComponent}
                                    messages={tableMessages}
                                />
                                <TableColumnVisibility
                                    defaultHiddenColumnNames={defaultHiddenColumnNames}
                                    messages={noColumnsMessage}
                                />
                                <TableHeaderRow
                                    cellComponent={tableHeaderCell}
                                    contentComponent={tableContentComponent}
                                />
                                <PatchedTableSelection
                                    showSelectAll
                                    rowSelectionEnabled={rowSelectionEnabled}
                                    headerCellComponent={tableHeaderSelCellBase}
                                />
                                <Toolbar rootComponent={toolRootComponent} />
                                <SearchPanel messages={searchMessages} inputComponent={inputComponent} />
                                <PagingPanel containerComponent={PagingPanelContainer} messages={pagingMessages} />
                            </Grid>
                        </GridUI>
                    </GridUI>
                </CardContent>
            </Card>
            <Backdrop open={showBackdrop} />
            <SnackBar type={alertType} show={show} message={texto} onClose={handleClose} />
            <DialogAlerts
                open={openDialogAlert}
                onClose={handleCloseDialogAlert}
                agreeBtnLabel={t("miscellaneous.accept")}
                disagreeBtnLabel={t("miscellaneous.cancel")}
                type={dialogAlertType}
                title={dialogAlertText}
                agreeAction={deleteProposals}
            />
            <ModProspectDetails
                open={openProspectDialog}
                onClose={() => setOpenProspectDialog(false)}
                values={valuesProspect}
                set={setValuesProspect}
                editMode={false}
                loadInitData={loadInitData}
                setBackDrop={setBackDrop}
                setShow={setShow}
                setTexto={setTexto}
                setAutoAlertType={setAutoAlertType}
            />
            <CommDialProp
                open={dialogComment}
                onClose={closeCommentsDialog}
                proposalID={proposalID}
                setTexto={setTexto}
                setAutoAlertType={setAutoAlertType}
                setBackDrop={setBackDrop}
                setShow={setShow}
                comments={comments}
                getComments={getComments}
                commentType={2}
                loadInitData={loadInitData}
                proposal={commentsProposal}
                prospect={commentsProspect}
                company_name={companyName}
            />
        </StyledContainer>
    );
};

export default ManageProposals;
