// React
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";
import moment from "moment";

// MUI icons
import EditIcon from "@mui/icons-material/Edit";
import EditOffIcon from "@mui/icons-material/EditOff";
import InfoIcon from "@mui/icons-material/Info";

// MUI
import {
    Alert,
    Box,
    Card,
    CardContent,
    CardHeader,
    Divider,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    TextField,
    FormControlLabel,
    Switch,
    Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import GridUI from "@mui/material/Grid";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

/** Component imports */
import { Additionals } from "./";
import DialogAlerts from "../../../DialogAlerts";

const PREFIX = "Recurring";

const classes = {
    btnRoot: `${PREFIX}-btnRoot`,
    buttonContainer: `${PREFIX}-buttonContainer`,
    tableStriped: `${PREFIX}-tableStriped`,
    tableHeader: `${PREFIX}-tableHeader`,
    tableBody: `${PREFIX}-tableBody`,
    tableBody2: `${PREFIX}-tableBody2`,
    pager: `${PREFIX}-pager`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.btnRoot}`]: {
        border: "1px solid #ff5968",
        borderRadius: 40,
        "&:hover": {
            backgroundColor: "#2f3190",
            border: "1px solid #2f3190",
        },
        "&.MuiButton-root.Mui-disabled": {
            border: "1px solid #ffcdd2",
            backgroundColor: "#ffcdd2 !important",
            color: "#fff",
        },
    },
    [`& .${classes.buttonContainer}`]: {
        display: "flex",
        justifyContent: "flex-end",
        padding: 15,
    },

    [`& .${classes.tableStriped}`]: {
        "& tbody tr:nth-of-type(odd)": {
            backgroundColor: "rgba(47,49,144,0.1)",
        },
    },
    [`& .${classes.tableHeader}`]: {
        fontSize: "15px",
        fontWeight: 600,
        color: "#031851",
        borderBottom: "2px solid #031851",
    },
    [`& .${classes.tableBody}`]: {
        fontSize: "14px",
        fontWeight: 400,
        color: "#031851",
        padding: "5px",
    },
    [`& .${classes.tableBody2}`]: {
        padding: "5px",
        textAlign: "right",
    },
    [`& .${classes.pager}`]: {
        "& .Pagination-button": {
            paddingTop: 0,
            paddingBottom: 0,
            color: "#031851",
            fontWeight: 600,
        },
        "& .Pagination-activeButton": {
            backgroundColor: "rgba(47,49,144,0.3)",
        },
    },
}));

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "rgba(19, 31, 62, 0.80)",
        fontSize: 12,
        maxWidth: 400,
        borderRadius: 6,
        fontWeight: "normal",
    },
});

const NumberFormatCustom = (props) => {
    const todos = useSelector((state) => state.todos);

    const { id, inputRef, value, onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            value={value}
            getInputRef={inputRef}
            onValueChange={({ value: v }) => onChange({ target: { value: v } })}
            thousandSeparator={todos.amountFormat === "de-DE" ? "." : ","}
            decimalSeparator={todos.amountFormat === "de-DE" ? "," : "."}
            fixedDecimalScale={true}
            decimalScale={id !== "outlined-adornment-expenses" ? 2 : 0}
            valueIsNumericString
        />
    );
};

const defaultState = {
    projectCompanyId: undefined,
    baseFee: 0,
    expenses: 0,
    projectFee: 0,
    discount: 0,
    projectTotal: 0,
    discountAdd: 0,
    // Responsabilidad del servicio
    initDate: null,
    endDate: null,
    estDeliDate: null,
    deliDate: null,
    repDays: 1,
    // Adicionales
    additionalsOnly: false,
    checkedBook: false,
    tothBook: 0,
    startDateBook: null,
    finishDateBook: null,
    checkedChecks: false,
    tothChecks: 0,
    startDateChecks: null,
    finishDateChecks: null,
    checkedAR: false,
    tothAR: 0,
    startDateAR: null,
    finishDateAR: null,
    checkedAP: false,
    tothAP: 0,
    startDateAP: null,
    finishDateAP: null,
    checkedAccrual: false,
    tothAccrual: 0,
    startDateAccrual: null,
    finishDateAccrual: null,
    checkedClass: false,
    tothClass: 0,
    startDateClass: null,
    finishDateClass: null,
    checkedMulti: false,
    tothMulti: 0,
    startDateMulti: null,
    finishDateMulti: null,
    checkedPer: false,
    tothPer: 0,
    startDatePer: null,
    finishDatePer: null,
    // Otros
    checkedOthers: false,
    nameOthers: "",
    tothOthers: 0,
    startDateOthers: null,
    finishDateOthers: null,
    // Total
    addHourFee: 0,
};

export const Recurring = (props) => {
    // Properties
    const {
        actives,
        bookkeeperSystem,
        companyID,
        data,
        expenseEst,
        reloadProjects,
        serviceID,
        setalertType,
        setBackDrop,
        setShow,
        setTexto,
        handleCloseDialog,
        infoAdd,
        checkRemind,
        open,
        bodyMess,
        showConfirm,
    } = props;

    /** Internationalization i18n */
    const [t] = useTranslation("global");

    /** React redux */
    const todos = useSelector((state) => state.todos);
    const dispatch = useDispatch();

    /** Component states */
    const [valuesRecurring, setValuesRecurring] = useState(defaultState);
    const [editMode, setEditMode] = useState(true);
    const [editMode2, setEditMode2] = useState(true);
    const [isError, setIsError] = useState(false);

    /** Component functions */
    const sumTotal =
        (valuesRecurring.finishDateChecks?.isBefore(moment()) ? 0 : parseFloat(valuesRecurring.tothChecks)) +
        (valuesRecurring.finishDateAR?.isBefore(moment()) ? 0 : parseFloat(valuesRecurring.tothAR)) +
        (valuesRecurring.finishDateAP?.isBefore(moment()) ? 0 : parseFloat(valuesRecurring.tothAP)) +
        (valuesRecurring.finishDateAccrual?.isBefore(moment()) ? 0 : parseFloat(valuesRecurring.tothAccrual)) +
        (valuesRecurring.finishDateClass?.isBefore(moment()) ? 0 : parseFloat(valuesRecurring.tothClass)) +
        (valuesRecurring.finishDateMulti?.isBefore(moment()) ? 0 : parseFloat(valuesRecurring.tothMulti)) +
        (valuesRecurring.finishDatePer?.isBefore(moment()) ? 0 : parseFloat(valuesRecurring.tothPer)) +
        (valuesRecurring.finishDateOthers?.isBefore(moment()) ? 0 : parseFloat(valuesRecurring.tothOthers));

    const getTotalHours = () => {
        let sumTotal =
            parseFloat(valuesRecurring.tothChecks) +
            parseFloat(valuesRecurring.tothAR) +
            parseFloat(valuesRecurring.tothAP) +
            parseFloat(valuesRecurring.tothAccrual) +
            parseFloat(valuesRecurring.tothClass) +
            parseFloat(valuesRecurring.tothMulti) +
            parseFloat(valuesRecurring.tothPer) +
            parseFloat(valuesRecurring.tothOthers)
        return sumTotal;
    };

    useEffect(() => {
        const proData = data.projects_array && data.projects_array[0];

        if (proData) {
            let bookAdditionalData = {};
            let checksAdditionalData = {};
            let arAdditionalData = {};
            let apAdditionalData = {};
            let accrualAdditionalData = {};
            let classesAdditionalData = {};
            let multiAdditionalData = {};
            let perAdditionalData = {};
            let othersAdditionalData = {};

            // Validando y guardando adicionales
            if (proData.additionals && proData.additionals.length !== 0) {
                proData.additionals.forEach((element) => {
                    if (element.additional_name === "Bookkeeper") {
                        bookAdditionalData = {
                            since_date: element.since_date,
                            end_date: element.end_date,
                            hours: element.hours,
                        };
                    }
                    if (element.additional_name === "Checks") {
                        checksAdditionalData = {
                            since_date: element.since_date,
                            end_date: element.end_date,
                            hours: element.hours,
                        };
                    }
                    if (element.additional_name === "A/R") {
                        arAdditionalData = {
                            since_date: element.since_date,
                            end_date: element.end_date,
                            hours: element.hours,
                        };
                    }
                    if (element.additional_name === "A/P") {
                        apAdditionalData = {
                            since_date: element.since_date,
                            end_date: element.end_date,
                            hours: element.hours,
                        };
                    }
                    if (element.additional_name === "Accrual") {
                        accrualAdditionalData = {
                            since_date: element.since_date,
                            end_date: element.end_date,
                            hours: element.hours,
                            update_hours: element.update_hours,
                        };
                    }
                    if (element.additional_name === "Classes/Categories accounting") {
                        classesAdditionalData = {
                            since_date: element.since_date,
                            end_date: element.end_date,
                            hours: element.hours,
                        };
                    }
                    if (element.additional_name === "Multi-entity accounting") {
                        multiAdditionalData = {
                            since_date: element.since_date,
                            end_date: element.end_date,
                            hours: element.hours,
                        };
                    }
                    if (element.additional_name === "Personalized reporting") {
                        perAdditionalData = {
                            since_date: element.since_date,
                            end_date: element.end_date,
                            hours: element.hours,
                        };
                    }
                    if (element.additional_name === "Others") {
                        othersAdditionalData = {
                            since_date: element.since_date,
                            end_date: element.end_date,
                            hours: element.hours,
                            other_name: element.other_name,
                        };
                    }
                });
            }

            setValuesRecurring({
                projectCompanyId: proData.project_company_id,
                baseFee: proData.additionals_only ? 0 : proData.fee,
                expenses: proData.additionals_only ? "N/A" : proData.expenses,
                projectFee: proData.additionals_only
                    ? data.tariff_additionals * bookAdditionalData.hours
                    : Math.ceil(proData.expenses / 10000) * proData.fee,
                discount: proData.discount ?? 0,
                projectTotal: proData.additionals_only
                    ? data.tariff_additionals * bookAdditionalData.hours * (1 - (proData.discount ?? 0) / 100)
                    : Math.ceil(proData.expenses / 10000) * proData.fee * (1 - (proData.discount ?? 0) / 100),
                discountAdd: proData.additional_discount ?? 0,
                // Responsabilidad del servicio
                initDate: proData.since_date ? moment(proData.since_date).utc() : null,
                endDate: proData.end_date ? moment(proData.end_date).utc() : null,
                estDeliDate: proData.client_commitment_date ? moment(proData.client_commitment_date).utc() : null,
                deliDate: proData.client_delivery_date ? moment(proData.client_delivery_date).utc() : null,
                repDays: proData.report_days ?? 1,
                // Adicionales
                additionalsOnly: proData.additionals_only,
                checkedBook: Object.keys(bookAdditionalData).length !== 0,
                tothBook: bookAdditionalData.hours ?? 0,
                startDateBook: !bookAdditionalData.since_date ? null : moment(bookAdditionalData.since_date).utc(),
                finishDateBook: !bookAdditionalData.end_date ? null : moment(bookAdditionalData.end_date).utc(),
                finishDateBookOG: !bookAdditionalData.end_date ? null : moment(bookAdditionalData.end_date).utc(),
                checkedChecks: Object.keys(checksAdditionalData).length !== 0,
                checkedChecksOG: Object.keys(checksAdditionalData).length !== 0,
                tothChecks: checksAdditionalData.hours ?? 0,
                startDateChecks: !checksAdditionalData.since_date
                    ? null
                    : moment(checksAdditionalData.since_date).utc(),
                finishDateChecks: !checksAdditionalData.end_date ? null : moment(checksAdditionalData.end_date).utc(),
                finishDateChecksOG: !checksAdditionalData.end_date ? null : moment(checksAdditionalData.end_date).utc(),
                checkedAR: Object.keys(arAdditionalData).length !== 0,
                checkedAROG: Object.keys(arAdditionalData).length !== 0,
                tothAR: arAdditionalData.hours ?? 0,
                startDateAR: !arAdditionalData.since_date ? null : moment(arAdditionalData.since_date).utc(),
                finishDateAR: !arAdditionalData.end_date ? null : moment(arAdditionalData.end_date).utc(),
                finishDateAROG: !arAdditionalData.end_date ? null : moment(arAdditionalData.end_date).utc(),
                checkedAP: Object.keys(apAdditionalData).length !== 0,
                checkedAPOG: Object.keys(apAdditionalData).length !== 0,
                tothAP: apAdditionalData.hours ?? 0,
                startDateAP: !apAdditionalData.since_date ? null : moment(apAdditionalData.since_date).utc(),
                finishDateAP: !apAdditionalData.end_date ? null : moment(apAdditionalData.end_date).utc(),
                finishDateAPOG: !apAdditionalData.end_date ? null : moment(apAdditionalData.end_date).utc(),
                checkedAccrual: Object.keys(accrualAdditionalData).length !== 0,
                checkedAccrualOG: Object.keys(accrualAdditionalData).length !== 0,
                tothAccrual: accrualAdditionalData.hours ?? 0,
                startDateAccrual: !accrualAdditionalData.since_date
                    ? null
                    : moment(accrualAdditionalData.since_date).utc(),
                finishDateAccrual: !accrualAdditionalData.end_date
                    ? null
                    : moment(accrualAdditionalData.end_date).utc(),
                finishDateAccrualOG: !accrualAdditionalData.end_date
                    ? null
                    : moment(accrualAdditionalData.end_date).utc(),
                checkedClass: Object.keys(classesAdditionalData).length !== 0,
                checkedClassOG: Object.keys(classesAdditionalData).length !== 0,
                tothClass: classesAdditionalData.hours ?? 0,
                startDateClass: !classesAdditionalData.since_date
                    ? null
                    : moment(classesAdditionalData.since_date).utc(),
                finishDateClass: !classesAdditionalData.end_date ? null : moment(classesAdditionalData.end_date).utc(),
                finishDateClassOG: !classesAdditionalData.end_date
                    ? null
                    : moment(classesAdditionalData.end_date).utc(),
                checkedMulti: Object.keys(multiAdditionalData).length !== 0,
                checkedMultiOG: Object.keys(multiAdditionalData).length !== 0,
                tothMulti: multiAdditionalData.hours ?? 0,
                startDateMulti: !multiAdditionalData.since_date ? null : moment(multiAdditionalData.since_date).utc(),
                finishDateMulti: !multiAdditionalData.end_date ? null : moment(multiAdditionalData.end_date).utc(),
                finishDateMultiOG: !multiAdditionalData.end_date ? null : moment(multiAdditionalData.end_date).utc(),
                checkedPer: Object.keys(perAdditionalData).length !== 0,
                checkedPerOG: Object.keys(perAdditionalData).length !== 0,
                tothPer: perAdditionalData.hours ?? 0,
                startDatePer: !perAdditionalData.since_date ? null : moment(perAdditionalData.since_date).utc(),
                finishDatePer: !perAdditionalData.end_date ? null : moment(perAdditionalData.end_date).utc(),
                finishDatePerOG: !perAdditionalData.end_date ? null : moment(perAdditionalData.end_date).utc(),
                // Otros
                checkedOthers: Object.keys(othersAdditionalData).length !== 0,
                checkedOthersOG: Object.keys(othersAdditionalData).length !== 0,
                nameOthers: othersAdditionalData.other_name ?? "",
                tothOthers: othersAdditionalData.hours ?? 0,
                startDateOthers: !othersAdditionalData.since_date
                    ? null
                    : moment(othersAdditionalData.since_date).utc(),
                finishDateOthers: !othersAdditionalData.end_date ? null : moment(othersAdditionalData.end_date).utc(),
                finishDateOthersOG: !perAdditionalData.end_date ? null : moment(perAdditionalData.end_date).utc(),
                // Total
                addHourFee: data.tariff_additionals,
            });
        } else {
            setValuesRecurring({
                ...valuesRecurring,
                baseFee: data.fee,
                expenses: data.expenses,
                discount: data.discount ?? 0,
                projectFee: Math.ceil(data.expenses / 10000) * data.fee,
                projectTotal: Math.ceil(data.expenses / 10000) * data.fee * (1 - (data.discount ?? 0) / 100),
                repDays: data.report_days ?? 1,
                addHourFee: data.tariff_additionals,
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const removeCheck = () => {
        setValuesRecurring({
            ...valuesRecurring,
            [infoAdd.check]: false,
            [infoAdd.toth]: 0,
            nameOthers: infoAdd.nameOthers ? "" : valuesRecurring.nameOthers,
            [infoAdd.startDate]: null,
            [infoAdd.finishDate]: null,
        });
        if (checkRemind) {
            dispatch({
                type: "addRemind",
                item: true,
            });
        }
        handleCloseDialog();
    };

    const handleChangeDiscount = (event) => {
        let discountAux = 0;

        if (event.target.name === "discount") {
            discountAux =
                event.target.value >= 0 && event.target.value <= 100 ? event.target.value : valuesRecurring.discount;

            setValuesRecurring({
                ...valuesRecurring,
                discount: discountAux,
                projectTotal: valuesRecurring.projectFee * (1 - discountAux / 100),
            });
        } else {
            discountAux =
                event.target.value >= 0 && event.target.value <= 100 ? event.target.value : valuesRecurring.discountAdd;

            setValuesRecurring({
                ...valuesRecurring,
                discountAdd: discountAux,
            });
        }
    };

    const updateProject = async () => {
        setBackDrop(true);

        if (valuesRecurring.initDate) {
            let additionalsF = [];

            if (valuesRecurring.endDate !== null && valuesRecurring.estDeliDate === null) {
                setTexto(t("miscellaneous.requiredData"));
                setalertType("warning");
                setShow(true);
                setBackDrop(false);
                setIsError(true);
                return;
            }
            if (valuesRecurring.checkedBook) {
                //La fecha Hasta de los adicionales es requerida si y solo si la fecha Hasta del proyecto base, existe
                if (
                    valuesRecurring.startDateBook &&
                    ((valuesRecurring.endDate && valuesRecurring.finishDateBook) ||
                        (!valuesRecurring.endDate && valuesRecurring.finishDateBook) ||
                        (!valuesRecurring.endDate && !valuesRecurring.finishDateBook))
                ) {
                    if (valuesRecurring.tothBook > 0) {
                        additionalsF.push({
                            additional_name: "Bookkeeper",
                            hours: valuesRecurring.tothBook,
                            since_date: moment(valuesRecurring.startDateBook).startOf("day").format(),
                            end_date: valuesRecurring.finishDateBook
                                ? moment(valuesRecurring.finishDateBook).startOf("day").format()
                                : null,
                        });
                    } else {
                        setTexto(t("services.hoursValidationError"));
                        setalertType("warning");
                        setShow(true);
                        setBackDrop(false);
                        setIsError(true);
                        return;
                    }
                } else {
                    setTexto(t("miscellaneous.requiredData"));
                    setalertType("warning");
                    setShow(true);
                    setBackDrop(false);
                    setIsError(true);
                    return;
                }
            }
            if (valuesRecurring.checkedChecks) {
                if (
                    valuesRecurring.startDateChecks &&
                    ((valuesRecurring.endDate && valuesRecurring.finishDateChecks) ||
                        (!valuesRecurring.endDate && valuesRecurring.finishDateChecks) ||
                        (!valuesRecurring.endDate && !valuesRecurring.finishDateChecks))
                ) {
                    if (valuesRecurring.tothChecks > 0) {
                        additionalsF.push({
                            additional_name: "Checks",
                            hours: valuesRecurring.tothChecks,
                            since_date: moment(valuesRecurring.startDateChecks).startOf("day").format(),
                            end_date: valuesRecurring.finishDateChecks
                                ? moment(valuesRecurring.finishDateChecks).startOf("day").format()
                                : null,
                        });
                    } else {
                        setTexto(t("services.hoursValidationError"));
                        setalertType("warning");
                        setShow(true);
                        setBackDrop(false);
                        setIsError(true);
                        return;
                    }
                } else {
                    setTexto(t("miscellaneous.requiredData"));
                    setalertType("warning");
                    setShow(true);
                    setBackDrop(false);
                    setIsError(true);
                    return;
                }
            }
            if (valuesRecurring.checkedAR) {
                if (
                    valuesRecurring.startDateAR &&
                    ((valuesRecurring.endDate && valuesRecurring.finishDateAR) ||
                        (!valuesRecurring.endDate && valuesRecurring.finishDateAR) ||
                        (!valuesRecurring.endDate && !valuesRecurring.finishDateAR))
                ) {
                    if (valuesRecurring.tothAR > 0) {
                        additionalsF.push({
                            additional_name: "A/R",
                            hours: valuesRecurring.tothAR,
                            since_date: moment(valuesRecurring.startDateAR).startOf("day").format(),
                            end_date: valuesRecurring.finishDateAR
                                ? moment(valuesRecurring.finishDateAR).startOf("day").format()
                                : null,
                        });
                    } else {
                        setTexto(t("services.hoursValidationError"));
                        setalertType("warning");
                        setShow(true);
                        setBackDrop(false);
                        setIsError(true);
                        return;
                    }
                } else {
                    setTexto(t("miscellaneous.requiredData"));
                    setalertType("warning");
                    setShow(true);
                    setBackDrop(false);
                    setIsError(true);
                    return;
                }
            }
            if (valuesRecurring.checkedAP) {
                if (
                    valuesRecurring.startDateAP &&
                    ((valuesRecurring.endDate && valuesRecurring.finishDateAP) ||
                        (!valuesRecurring.endDate && valuesRecurring.finishDateAP) ||
                        (!valuesRecurring.endDate && !valuesRecurring.finishDateAP))
                ) {
                    if (valuesRecurring.tothAP > 0) {
                        additionalsF.push({
                            additional_name: "A/P",
                            hours: valuesRecurring.tothAP,
                            since_date: moment(valuesRecurring.startDateAP).startOf("day").format(),
                            end_date: valuesRecurring.finishDateAP
                                ? moment(valuesRecurring.finishDateAP).startOf("day").format()
                                : null,
                        });
                    } else {
                        setTexto(t("services.hoursValidationError"));
                        setalertType("warning");
                        setShow(true);
                        setBackDrop(false);
                        setIsError(true);
                        return;
                    }
                } else {
                    setTexto(t("miscellaneous.requiredData"));
                    setalertType("warning");
                    setShow(true);
                    setBackDrop(false);
                    setIsError(true);
                    return;
                }
            }
            if (valuesRecurring.checkedAccrual) {
                if (
                    valuesRecurring.startDateAccrual &&
                    ((valuesRecurring.endDate && valuesRecurring.finishDateAccrual) ||
                        (!valuesRecurring.endDate && valuesRecurring.finishDateAccrual) ||
                        (!valuesRecurring.endDate && !valuesRecurring.finishDateAccrual))
                ) {
                    if (valuesRecurring.tothAccrual > 0) {
                        additionalsF.push({
                            additional_name: "Accrual",
                            hours: valuesRecurring.tothAccrual,
                            since_date: moment(valuesRecurring.startDateAccrual).startOf("day").format(),
                            end_date: valuesRecurring.finishDateAccrual
                                ? moment(valuesRecurring.finishDateAccrual).startOf("day").format()
                                : null,
                        });
                    } else {
                        setTexto(t("services.hoursValidationError"));
                        setalertType("warning");
                        setShow(true);
                        setBackDrop(false);
                        setIsError(true);
                        return;
                    }
                } else {
                    setTexto(t("miscellaneous.requiredData"));
                    setalertType("warning");
                    setShow(true);
                    setBackDrop(false);
                    setIsError(true);
                    return;
                }
            }
            if (valuesRecurring.checkedClass) {
                if (
                    valuesRecurring.startDateClass &&
                    ((valuesRecurring.endDate && valuesRecurring.finishDateClass) ||
                        (!valuesRecurring.endDate && valuesRecurring.finishDateClass) ||
                        (!valuesRecurring.endDate && !valuesRecurring.finishDateClass))
                ) {
                    if (valuesRecurring.tothClass > 0) {
                        additionalsF.push({
                            additional_name: "Classes/Categories accounting",
                            hours: valuesRecurring.tothClass,
                            since_date: moment(valuesRecurring.startDateClass).startOf("day").format(),
                            end_date: valuesRecurring.finishDateClass
                                ? moment(valuesRecurring.finishDateClass).startOf("day").format()
                                : null,
                        });
                    } else {
                        setTexto(t("services.hoursValidationError"));
                        setalertType("warning");
                        setShow(true);
                        setBackDrop(false);
                        setIsError(true);
                        return;
                    }
                } else {
                    setTexto(t("miscellaneous.requiredData"));
                    setalertType("warning");
                    setShow(true);
                    setBackDrop(false);
                    setIsError(true);
                    return;
                }
            }
            if (valuesRecurring.checkedMulti) {
                if (
                    valuesRecurring.startDateMulti &&
                    ((valuesRecurring.endDate && valuesRecurring.finishDateMulti) ||
                        (!valuesRecurring.endDate && valuesRecurring.finishDateMulti) ||
                        (!valuesRecurring.endDate && !valuesRecurring.finishDateMulti))
                ) {
                    if (valuesRecurring.tothMulti > 0) {
                        additionalsF.push({
                            additional_name: "Multi-entity accounting",
                            hours: valuesRecurring.tothMulti,
                            since_date: moment(valuesRecurring.startDateMulti).startOf("day").format(),
                            end_date: valuesRecurring.finishDateMulti
                                ? moment(valuesRecurring.finishDateMulti).startOf("day").format()
                                : null,
                        });
                    } else {
                        setTexto(t("services.hoursValidationError"));
                        setalertType("warning");
                        setShow(true);
                        setBackDrop(false);
                        setIsError(true);
                        return;
                    }
                } else {
                    setTexto(t("miscellaneous.requiredData"));
                    setalertType("warning");
                    setShow(true);
                    setBackDrop(false);
                    setIsError(true);
                    return;
                }
            }
            if (valuesRecurring.checkedPer) {
                if (
                    valuesRecurring.startDatePer &&
                    ((valuesRecurring.endDate && valuesRecurring.finishDatePer) ||
                        (!valuesRecurring.endDate && valuesRecurring.finishDatePer) ||
                        (!valuesRecurring.endDate && !valuesRecurring.finishDatePer))
                ) {
                    if (valuesRecurring.tothPer > 0) {
                        additionalsF.push({
                            additional_name: "Personalized reporting",
                            hours: valuesRecurring.tothPer,
                            since_date: moment(valuesRecurring.startDatePer).startOf("day").format(),
                            end_date: valuesRecurring.finishDatePer
                                ? moment(valuesRecurring.finishDatePer).startOf("day").format()
                                : null,
                        });
                    } else {
                        setTexto(t("services.hoursValidationError"));
                        setalertType("warning");
                        setShow(true);
                        setBackDrop(false);
                        setIsError(true);
                        return;
                    }
                } else {
                    setTexto(t("miscellaneous.requiredData"));
                    setalertType("warning");
                    setShow(true);
                    setBackDrop(false);
                    setIsError(true);
                    return;
                }
            }
            if (valuesRecurring.checkedOthers) {
                if (valuesRecurring.startDateOthers && valuesRecurring.nameOthers) {
                    if (
                        valuesRecurring.tothOthers > 0 &&
                        ((valuesRecurring.endDate && valuesRecurring.finishDateOthers) ||
                            (!valuesRecurring.endDate && valuesRecurring.finishDateOthers) ||
                            (!valuesRecurring.endDate && !valuesRecurring.finishDateOthers))
                    ) {
                        additionalsF.push({
                            additional_name: "Others",
                            hours: valuesRecurring.tothOthers,
                            since_date: moment(valuesRecurring.startDateOthers).startOf("day").format(),
                            end_date: valuesRecurring.finishDateOthers
                                ? moment(valuesRecurring.finishDateOthers).startOf("day").format()
                                : null,
                            other_name: valuesRecurring.nameOthers,
                        });
                    } else {
                        setTexto(t("services.hoursValidationError"));
                        setalertType("warning");
                        setShow(true);
                        setBackDrop(false);
                        setIsError(true);
                        return;
                    }
                } else {
                    setTexto(t("miscellaneous.requiredData"));
                    setalertType("warning");
                    setShow(true);
                    setBackDrop(false);
                    setIsError(true);
                    return;
                }
            }

            let params = {
                company_id: companyID,
                service_id: serviceID,
                project_id: data.project_id,
                project_company_id: valuesRecurring.projectCompanyId,
                fee: valuesRecurring.baseFee,
                total_fee:
                    valuesRecurring.projectTotal +
                    valuesRecurring.addHourFee * sumTotal * (1 - valuesRecurring.discountAdd / 100),
                discount: parseFloat(valuesRecurring.discount),
                additional_discount: parseFloat(valuesRecurring.discountAdd),
                since_date: moment(valuesRecurring.initDate).startOf("day").format(),
                end_date: valuesRecurring.endDate ? moment(valuesRecurring.endDate).startOf("day").format() : null,
                client_commitment_date: valuesRecurring.estDeliDate
                    ? moment(valuesRecurring.estDeliDate).startOf("day").format()
                    : null,
                client_delivery_date: valuesRecurring.deliDate
                    ? moment(valuesRecurring.deliDate).startOf("day").format()
                    : null,
                report_days: parseInt(valuesRecurring.repDays),
                additionals_only: valuesRecurring.additionalsOnly,
                additionals: additionalsF,
            };

            await fetch(`/addServicesAndProjects`, {
                method: "POST",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
                body: JSON.stringify(params),
            })
                .then((response) => {
                    if (!response.ok) {
                        // get error message from body or default to response status
                        const error = response.status;
                        throw new Error(error);
                    }

                    if (response.status === 200) {
                        setTexto(t("miscellaneous.successfulUpdate"));
                        setalertType("success");
                        setShow(true);
                        reloadProjects(true);
                    } else if (response.status === 201) {
                        setTexto(t("services.notPossibleGenProject"));
                        setalertType("error");
                        setShow(true);
                        reloadProjects(true);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            setTexto(t("miscellaneous.requiredData"));
            setalertType("warning");
            setShow(true);
            setIsError(true);
        }

        setBackDrop(false);
    };

    const handleChangeInitDate = (date) => {
        let startDate = moment(date).startOf("month");

        if (!date || !valuesRecurring.endDate) {
            setValuesRecurring({
                ...valuesRecurring,
                initDate: date ? startDate : date,
            });
        } else {
            if (startDate.isAfter(moment(valuesRecurring.endDate).format("YYYY-MM-DD"))) {
                setValuesRecurring({
                    ...valuesRecurring,
                    initDate: null,
                });

                setTexto(t("reports.initDateMess"));
                setalertType("warning");
                setShow(true);
            } else {
                setValuesRecurring({
                    ...valuesRecurring,
                    initDate: startDate,
                    // Adicionales
                    startDateBook: valuesRecurring.checkedBook ? startDate : null,
                    startDateChecks: valuesRecurring.checkedChecks ? startDate : null,
                    startDateAR: valuesRecurring.checkedAR ? startDate : null,
                    startDateAP: valuesRecurring.checkedAP ? startDate : null,
                    startDateAccrual: valuesRecurring.checkedAccrual ? startDate : null,
                    startDateClass: valuesRecurring.checkedClass ? startDate : null,
                    startDateMulti: valuesRecurring.checkedMulti ? startDate : null,
                    startDatePer: valuesRecurring.checkedPer ? startDate : null,
                    // Otros
                    startDateOthers: valuesRecurring.checkedOthers ? startDate : null,
                });
            }
        }
    };

    const handleChangeEndDate = (date) => {
        let endDate = moment(date).endOf("month").startOf("day");

        if (!date || !valuesRecurring.initDate) {
            setValuesRecurring({
                ...valuesRecurring,
                endDate: date ? endDate : date,
                estDeliDate: null,
            });
        } else {
            if (endDate.isBefore(moment(valuesRecurring.initDate).format("YYYY-MM-DD"))) {
                setValuesRecurring({
                    ...valuesRecurring,
                    endDate: null,
                    estDeliDate: null,
                });

                setTexto(t("reports.endDateMess"));
                setalertType("warning");
                setShow(true);
            } else {
                setValuesRecurring({
                    ...valuesRecurring,
                    endDate: endDate,
                    // Adicionales
                    finishDateBook: valuesRecurring.checkedBook
                        ? valuesRecurring.finishDateBookOG && valuesRecurring.finishDateBookOG.isBefore(endDate)
                            ? valuesRecurring.finishDateBookOG
                            : endDate
                        : null,
                    finishDateChecks: valuesRecurring.checkedChecks
                        ? valuesRecurring.finishDateChecksOG && valuesRecurring.finishDateChecksOG.isBefore(endDate)
                            ? valuesRecurring.finishDateChecksOG
                            : endDate
                        : null,
                    finishDateAR: valuesRecurring.checkedAR
                        ? valuesRecurring.finishDateAROG && valuesRecurring.finishDateAROG.isBefore(endDate)
                            ? valuesRecurring.finishDateAROG
                            : endDate
                        : null,
                    finishDateAP: valuesRecurring.checkedAP
                        ? valuesRecurring.finishDateAPOG && valuesRecurring.finishDateAPOG.isBefore(endDate)
                            ? valuesRecurring.finishDateAPOG
                            : endDate
                        : null,
                    finishDateAccrual: valuesRecurring.checkedAccrual
                        ? valuesRecurring.finishDateAccrualOG && valuesRecurring.finishDateAccrualOG.isBefore(endDate)
                            ? valuesRecurring.finishDateAccrualOG
                            : endDate
                        : null,
                    finishDateClass: valuesRecurring.checkedClass
                        ? valuesRecurring.finishDateClassOG && valuesRecurring.finishDateClassOG.isBefore(endDate)
                            ? valuesRecurring.finishDateClassOG
                            : endDate
                        : null,
                    finishDateMulti: valuesRecurring.checkedMulti
                        ? valuesRecurring.finishDateMultiOG && valuesRecurring.finishDateMultiOG.isBefore(endDate)
                            ? valuesRecurring.finishDateMultiOG
                            : endDate
                        : null,
                    finishDatePer: valuesRecurring.checkedPer
                        ? valuesRecurring.finishDatePerOG && valuesRecurring.finishDatePerOG.isBefore(endDate)
                            ? valuesRecurring.finishDatePerOG
                            : endDate
                        : null,
                    // Otros
                    finishDateOthers: valuesRecurring.checkedOthers
                        ? valuesRecurring.finishDateOthersOG && valuesRecurring.finishDateOthersOG.isBefore(endDate)
                            ? valuesRecurring.finishDateOthersOG
                            : endDate
                        : null,
                });
            }
        }
    };

    const handleChangeEstDeliDate = (date) => {
        if (!date) {
            setValuesRecurring({
                ...valuesRecurring,
                estDeliDate: date,
            });
        } else {
            if (date.isSameOrBefore(moment(valuesRecurring.endDate).format("YYYY-MM-DD"))) {
                setValuesRecurring({
                    ...valuesRecurring,
                    estDeliDate: null,
                });

                setTexto(t("reports.selectEntDate"));
                setalertType("warning");
                setShow(true);
            } else {
                setValuesRecurring({
                    ...valuesRecurring,
                    estDeliDate: date,
                });
            }
        }
    };

    const handleChangeDeliDate = (date) => {
        if (!date) {
            setValuesRecurring({
                ...valuesRecurring,
                deliDate: date,
            });
        } else {
            if (date.isSameOrBefore(moment(valuesRecurring.endDate).format("YYYY-MM-DD"))) {
                setValuesRecurring({
                    ...valuesRecurring,
                    deliDate: null,
                });

                setTexto(t("reports.selectEntDate"));
                setalertType("warning");
                setShow(true);
            } else {
                setValuesRecurring({
                    ...valuesRecurring,
                    deliDate: date,
                });
            }
        }
    };

    const handleChangeRepDaysPeriods = (event) => {
        if (event.target.value >= 1 && event.target.value <= 25) {
            setValuesRecurring({
                ...valuesRecurring,
                repDays: event.target.value,
            });
        } else {
            setValuesRecurring({
                ...valuesRecurring,
                repDays: "",
            });
            setTexto(t("services.monthlyReportsMsg"));
            setalertType("warning");
            setShow(true);
        }
    };

    const handleMouseDownDiscount = (event) => {
        event.preventDefault();
    };

    const handleClickShowDiscount = () => {
        setEditMode(!editMode);
    };

    const handleClickShowDiscount2 = () => {
        setEditMode2(!editMode2);
    };

    const handleChangeSwitch = (event) => {
        setValuesRecurring({
            ...valuesRecurring,
            baseFee: event.target.checked ? 0 : data.fee,
            expenses: event.target.checked ? "N/A" : data.expenses,
            additionalsOnly: event.target.checked,
            discount: event.target.checked ? 0 : data.discount ?? 0,
            projectFee: event.target.checked ? 0 : Math.ceil(data.expenses / 10000) * data.fee,
            projectTotal: event.target.checked
                ? 0
                : Math.ceil(data.expenses / 10000) * data.fee * (1 - (valuesRecurring.discount ?? 0) / 100),
            checkedBook: event.target.checked,
            tothBook: 0,
            startDateBook: event.target.checked ? valuesRecurring.initDate : null,
            finishDateBook: event.target.checked ? valuesRecurring.endDate : null,
        });
    };

    return (
        <StyledContainer>
            <LocalizationProvider
                dateAdapter={AdapterMoment}
                adapterLocale={t("language.locale")}
                localeText={{ clearButtonLabel: t("miscellaneous.clear") }}
            >
                <Card elevation={0}>
                    <CardHeader
                        title={
                            <Box display="flex" sx={{ alignItems: "center" }}>
                                Bookkeeper recurring
                                <FormControlLabel
                                    sx={{ ml: 1 }}
                                    control={
                                        <Switch
                                            checked={valuesRecurring.additionalsOnly}
                                            inputProps={{
                                                "aria-label": "controlled",
                                            }}
                                            color="secondary"
                                            onChange={handleChangeSwitch}
                                            className={classes.switch}
                                        />
                                    }
                                    label={
                                        <Typography variant="body1" color="textPrimary">
                                            {t("services.hourlyInvoicing")}
                                        </Typography>
                                    }
                                />
                            </Box>
                        }
                    />
                    <Divider />
                    <CardContent>
                        {actives.includes("Accountant") && (
                            <GridUI container spacing={2} justifyContent="center" sx={{ paddingBottom: 3 }}>
                                <GridUI item>
                                    <Alert
                                        severity="warning"
                                        sx={{
                                            borderRadius: 8,
                                            fontSize: 12,
                                            fontWeight: 600,
                                        }}
                                    >
                                        {t("services.notPosibleContract")}
                                    </Alert>
                                </GridUI>
                            </GridUI>
                        )}
                        {!actives.includes("Accountant") &&
                            bookkeeperSystem.disabledData === true &&
                            (bookkeeperSystem.prov === "Xero" || bookkeeperSystem.prov === "Quickbooks Online") && (
                                <GridUI container spacing={2} justifyContent="center" sx={{ paddingBottom: 3 }}>
                                    <GridUI item>
                                        <Alert
                                            severity="warning"
                                            sx={{
                                                borderRadius: 8,
                                                fontSize: 12,
                                                fontWeight: 600,
                                            }}
                                        >
                                            {t("services.enterValidCredentials")}
                                        </Alert>
                                    </GridUI>
                                </GridUI>
                            )}
                        {!actives.includes("Accountant") && expenseEst === -1 && (
                            <GridUI container spacing={2} justifyContent="center" sx={{ paddingBottom: 3 }}>
                                <GridUI item>
                                    <Alert
                                        severity="warning"
                                        sx={{
                                            borderRadius: 8,
                                            fontSize: 12,
                                            fontWeight: 600,
                                        }}
                                    >
                                        {t("services.ableToSaveInfo")}
                                    </Alert>
                                </GridUI>
                            </GridUI>
                        )}
                        <GridUI container alignItems="center" spacing={2}>
                            <GridUI item container xs={12} sm={6} lg={3} spacing={2}>
                                <GridUI item xs={12} lg={6}>
                                    {valuesRecurring.expenses !== "N/A" ? (
                                        <FormControl fullWidth variant="outlined" disabled>
                                            <InputLabel htmlFor="outlined-adornment-expenses">
                                                {t("miscellaneous.expenseScale")}
                                            </InputLabel>
                                            <OutlinedInput
                                                id="outlined-adornment-expenses"
                                                value={valuesRecurring.expenses}
                                                inputComponent={NumberFormatCustom}
                                                startAdornment={
                                                    <InputAdornment disableTypography position="start">
                                                        $
                                                    </InputAdornment>
                                                }
                                                label={t("miscellaneous.expenseScale")}
                                            />
                                        </FormControl>
                                    ) : (
                                        <TextField
                                            fullWidth
                                            disabled
                                            label={t("miscellaneous.expenseScale")}
                                            value={valuesRecurring.expenses}
                                            variant="outlined"
                                        />
                                    )}
                                </GridUI>
                                <GridUI item xs={12} lg={6}>
                                    <FormControl fullWidth variant="outlined" disabled>
                                        <InputLabel htmlFor="outlined-adornment-baseFee">
                                            {t("services.baseRate")}
                                        </InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-baseFee"
                                            value={valuesRecurring.baseFee}
                                            inputComponent={NumberFormatCustom}
                                            startAdornment={
                                                <InputAdornment disableTypography position="start">
                                                    $
                                                </InputAdornment>
                                            }
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <LightTooltip
                                                        aria-label="info"
                                                        title={t("services.modifyBaseRate")}
                                                    >
                                                        <InfoIcon fontSize="small" color="primary" />
                                                    </LightTooltip>
                                                </InputAdornment>
                                            }
                                            label={t("services.baseRate")}
                                        />
                                    </FormControl>
                                </GridUI>
                            </GridUI>
                            <GridUI item xs={12} sm={6} lg={3}>
                                <FormControl fullWidth variant="outlined" disabled>
                                    <InputLabel htmlFor="outlined-adornment-projectFee">
                                        {t("services.projectFee")}
                                    </InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-projectFee"
                                        value={valuesRecurring.projectFee}
                                        inputComponent={NumberFormatCustom}
                                        startAdornment={
                                            <InputAdornment disableTypography position="start">
                                                $
                                            </InputAdornment>
                                        }
                                        label={t("services.projectFee")}
                                    />
                                </FormControl>
                            </GridUI>
                            <GridUI item xs={12} sm={6} lg={3}>
                                <FormControl variant="outlined" disabled={editMode} fullWidth>
                                    <InputLabel htmlFor="outlined-adornment-discount">
                                        {t("miscellaneous.discount")}
                                    </InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-discount"
                                        type="number"
                                        name="discount"
                                        value={valuesRecurring.discount}
                                        onChange={handleChangeDiscount}
                                        onBlur={() =>
                                            setValuesRecurring({
                                                ...valuesRecurring,
                                                discount: parseFloat(
                                                    valuesRecurring.discount === "" ? 0 : valuesRecurring.discount
                                                ).toFixed(2),
                                            })
                                        }
                                        inputProps={{
                                            min: 0,
                                            max: 100,
                                        }}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                %
                                                <LightTooltip
                                                    title={editMode ? t("miscellaneous.edit") : t("miscellaneous.save")}
                                                    aria-label="edit"
                                                >
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowDiscount}
                                                        onMouseDown={handleMouseDownDiscount}
                                                        edge="end"
                                                    >
                                                        {!editMode ? <EditOffIcon /> : <EditIcon />}
                                                    </IconButton>
                                                </LightTooltip>
                                            </InputAdornment>
                                        }
                                        label={t("miscellaneous.discount")}
                                        sx={{
                                            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                                display: "none",
                                            },
                                        }}
                                    />
                                </FormControl>
                            </GridUI>
                            <GridUI item xs={12} sm={6} lg={3}>
                                <FormControl fullWidth variant="outlined" disabled>
                                    <InputLabel htmlFor="outlined-adornment-projectTotal">
                                        {t("services.projectTot")}
                                    </InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-projectTotal"
                                        value={valuesRecurring.projectTotal}
                                        inputComponent={NumberFormatCustom}
                                        startAdornment={
                                            <InputAdornment disableTypography position="start">
                                                $
                                            </InputAdornment>
                                        }
                                        label={t("services.projectTot")}
                                    />
                                </FormControl>
                            </GridUI>
                            <GridUI item xs={12} sm={6} lg={3}></GridUI>
                            <GridUI item xs={12} sm={6} lg={3}>
                                <FormControl fullWidth variant="outlined" disabled>
                                    <InputLabel htmlFor="outlined-adornment-feeAdd">
                                        {t("services.hourlyRateAdd")}
                                    </InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-feeAdd"
                                        value={valuesRecurring.addHourFee * sumTotal}
                                        inputComponent={NumberFormatCustom}
                                        startAdornment={
                                            <InputAdornment disableTypography position="start">
                                                $
                                            </InputAdornment>
                                        }
                                        label={t("services.hourlyRateAdd")}
                                    />
                                </FormControl>
                            </GridUI>
                            <GridUI item xs={12} sm={6} lg={3}>
                                <FormControl variant="outlined" disabled={editMode2} fullWidth>
                                    <InputLabel htmlFor="outlined-adornment-discount">
                                        {t("miscellaneous.discount")}
                                    </InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-discount"
                                        type="number"
                                        name="discountAdd"
                                        value={valuesRecurring.discountAdd}
                                        onChange={handleChangeDiscount}
                                        onBlur={() =>
                                            setValuesRecurring({
                                                ...valuesRecurring,
                                                discountAdd: parseFloat(
                                                    valuesRecurring.discountAdd === "" ? 0 : valuesRecurring.discountAdd
                                                ).toFixed(2),
                                            })
                                        }
                                        inputProps={{
                                            min: 0,
                                            max: 100,
                                        }}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                %
                                                <LightTooltip
                                                    title={
                                                        editMode2 ? t("miscellaneous.edit") : t("miscellaneous.save")
                                                    }
                                                    aria-label="edit"
                                                >
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowDiscount2}
                                                        onMouseDown={handleMouseDownDiscount}
                                                        edge="end"
                                                    >
                                                        {!editMode2 ? <EditOffIcon /> : <EditIcon />}
                                                    </IconButton>
                                                </LightTooltip>
                                            </InputAdornment>
                                        }
                                        label={t("miscellaneous.discount")}
                                        sx={{
                                            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                                display: "none",
                                            },
                                        }}
                                    />
                                </FormControl>
                            </GridUI>
                            <GridUI item xs={12} sm={6} lg={3}>
                                <FormControl fullWidth variant="outlined" disabled>
                                    <InputLabel htmlFor="outlined-adornment-totalAdd">
                                        {t("clientsTable.totalAdd")}
                                    </InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-totalAdd"
                                        value={
                                            valuesRecurring.addHourFee *
                                            sumTotal *
                                            (1 - valuesRecurring.discountAdd / 100)
                                        }
                                        inputComponent={NumberFormatCustom}
                                        startAdornment={
                                            <InputAdornment disableTypography position="start">
                                                $
                                            </InputAdornment>
                                        }
                                        label={t("clientsTable.totalAdd")}
                                    />
                                </FormControl>
                            </GridUI>
                            <GridUI item xs={12} sm={6} lg={9}></GridUI>
                            <GridUI item xs={12} sm={6} lg={3}>
                                <FormControl fullWidth variant="outlined" disabled>
                                    <InputLabel htmlFor="outlined-adornment-feeTotal">
                                        {t("proposals.feeTotal")}
                                    </InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-feeTotal"
                                        value={
                                            valuesRecurring.projectTotal +
                                            valuesRecurring.addHourFee *
                                            sumTotal *
                                            (1 - valuesRecurring.discountAdd / 100)
                                        }
                                        inputComponent={NumberFormatCustom}
                                        startAdornment={
                                            <InputAdornment disableTypography position="start">
                                                $
                                            </InputAdornment>
                                        }
                                        label={t("proposals.feeTotal")}
                                    />
                                </FormControl>
                            </GridUI>
                        </GridUI>
                    </CardContent>
                </Card>
                <Card elevation={0}>
                    <CardHeader title={t("services.serviceResponsibility")} sx={{ color: "#ff5968" }} />
                    <Divider />
                    <CardContent>
                        <GridUI container alignItems="center" spacing={2}>
                            <GridUI item xs={12} sm={6} lg={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    value={valuesRecurring.initDate}
                                    onChange={(newValue) => handleChangeInitDate(newValue)}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            required
                                            {...params}
                                            error={isError && valuesRecurring.initDate === null}
                                        />
                                    )}
                                    inputProps={{ readOnly: true }}
                                    label={t("reports.initDate")}
                                    views={["year", "month"]}
                                />
                            </GridUI>
                            <GridUI item xs={12} sm={6} lg={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    value={valuesRecurring.endDate}
                                    onChange={(newValue) => handleChangeEndDate(newValue)}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => <TextField fullWidth {...params} />}
                                    inputProps={{ readOnly: true }}
                                    label={t("reports.endDate")}
                                    views={["year", "month"]}
                                />
                            </GridUI>
                            <GridUI item xs={12} sm={6} lg={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    value={valuesRecurring.estDeliDate}
                                    onChange={(newValue) => handleChangeEstDeliDate(newValue)}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            required={valuesRecurring.endDate}
                                            {...params}
                                            error={
                                                isError &&
                                                valuesRecurring.endDate &&
                                                valuesRecurring.estDeliDate === null
                                            }
                                        />
                                    )}
                                    inputProps={{ readOnly: true }}
                                    label={t("services.estimDeliveryDate")}
                                    views={["year", "month", "day"]}
                                />
                            </GridUI>
                            <GridUI item xs={12} sm={6} lg={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    disableFuture
                                    value={valuesRecurring.deliDate}
                                    onChange={(newValue) => handleChangeDeliDate(newValue)}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => <TextField fullWidth {...params} />}
                                    inputProps={{ readOnly: true }}
                                    label={t("services.deliveryDate")}
                                    views={["year", "month", "day"]}
                                />
                            </GridUI>
                            <GridUI item xs={12} sm={6} lg={3}>
                                <TextField
                                    fullWidth
                                    required
                                    label={t("services.monthlyReportDate")}
                                    name="repDays"
                                    onChange={handleChangeRepDaysPeriods}
                                    value={valuesRecurring.repDays}
                                    variant="outlined"
                                    type="number"
                                    InputProps={{
                                        inputProps: {
                                            min: 1,
                                            max: 25,
                                            onKeyDown: (event) => {
                                                event.preventDefault();
                                            },
                                        },
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </GridUI>
                        </GridUI>
                    </CardContent>
                </Card>
                <Additionals
                    values={valuesRecurring}
                    actives={actives}
                    set={setValuesRecurring}
                    setTexto={setTexto}
                    setalertType={setalertType}
                    setShow={setShow}
                    isError={isError}
                    updateProject={updateProject}
                    sumTotal={sumTotal}
                    originalData={data}
                    bookkeeperSystem={bookkeeperSystem}
                    expenseEst={expenseEst}
                    showConfirm={showConfirm}
                    sumTotalAddHours={getTotalHours()}
                />
            </LocalizationProvider>
            <DialogAlerts
                open={open}
                onClose={handleCloseDialog}
                agreeBtnLabel={t("miscellaneous.delete")}
                disagreeBtnLabel="No"
                type={"info"}
                title={t("miscellaneous.confRemoveCheck")}
                message={bodyMess}
                agreeAction={removeCheck}
            />
        </StyledContainer>
    );
};
