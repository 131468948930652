// React
import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";
import moment from "moment";

// MUI
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const PREFIX = "Additionals";

const classes = {
    btnRoot: `${PREFIX}-btnRoot`,
    buttonContainer: `${PREFIX}-buttonContainer`,
    switch: `${PREFIX}-switch`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.btnRoot}`]: {
        border: "1px solid #ff5968",
        borderRadius: 40,
        "&:hover": {
            backgroundColor: "#2f3190",
            border: "1px solid #2f3190",
        },
    },
    [`& .${classes.buttonContainer}`]: {
        display: "flex",
        justifyContent: "flex-end",
        padding: 15,
    },
    [`& .${classes.switch}`]: {
        "& .MuiSwitch-switchBase .MuiSwitch-thumb": {
            color: "#FFF",
        },
        "& .MuiSwitch-switchBase + .MuiSwitch-track": {
            backgroundColor: "#031851",
        },
        "& .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb": {
            color: "#FF5968",
        },
        "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
            backgroundColor: "#FFA8B1",
        },
    },
}));

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "rgba(19, 31, 62, 0.80)",
        fontSize: 12,
        maxWidth: 320,
        borderRadius: 6,
        fontWeight: "normal",
    },
});

const NumberFormatCustom = (props) => {
    const todos = useSelector((state) => state.todos);

    const { inputRef, value, onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            value={value}
            getInputRef={inputRef}
            onValueChange={({ value: v }) => onChange({ target: { value: v } })}
            thousandSeparator={todos.amountFormat === "de-DE" ? "." : ","}
            decimalSeparator={todos.amountFormat === "de-DE" ? "," : "."}
            fixedDecimalScale={true}
            decimalScale={2}
            valueIsNumericString
        />
    );
};

export const Additionals = (props) => {
    // Properties
    const { addHourFee, clientMarket, setAutoAlertType, setShow, setTexto, set, sumTotal, values, isError, isErrorFocus, setIsErrorFocus, sumTotalAddHours } = props;

    /** Internationalization i18n */
    const [t] = useTranslation("global");

    /** React redux */
    const todos = useSelector((state) => state.todos);

    /** Component functions */
    const handleChange = (event) => {
        set({
            ...values,
            isError: false,
            [event.target.name]: event.target.value,
            
        });
        setIsErrorFocus(false)
    };

    const handleChangeHoursAcc = (event) => {
        set({
            ...values,
            [event.target.name]: event.target.value,
            recuBaseRate: event.target.value * addHourFee,
            recuProjectTotal: event.target.value * addHourFee * (1 - values.recuDiscount / 100),
        });

    };

    const handleChangeCheck = (event, value) => {
        switch (value) {
            case 0:
                set({
                    ...values,
                    checkedAR: event.target.checked,
                    tothAR: 0,
                    startDateAR: event.target.checked ? values.initDate : null,
                    finishDateAR: null,
                });
                break;
            case 1:
                set({
                    ...values,
                    checkedAP: event.target.checked,
                    tothAP: 0,
                    startDateAP: event.target.checked ? values.initDate : null,
                    finishDateAP: null,
                });
                break;
            case 2:
                set({
                    ...values,
                    checkedClass: event.target.checked,
                    tothClass: 0,
                    startDateClass: event.target.checked ? values.initDate : null,
                    finishDateClass: null,
                });
                break;
            case 3:
                set({
                    ...values,
                    checkedMulti: event.target.checked,
                    tothMulti: 0,
                    startDateMulti: event.target.checked ? values.initDate : null,
                    finishDateMulti: null,
                });
                break;
            case 4:
                set({
                    ...values,
                    checkedPer: event.target.checked,
                    tothPer: 0,
                    startDatePer: event.target.checked ? values.initDate : null,
                    finishDatePer: null,
                });
                break;
            case 5:
                set({
                    ...values,
                    checkedSales: event.target.checked,
                    tothSales: 0,
                    startDateSales: event.target.checked ? values.initDate : null,
                    finishDateSales: null,
                });
                break;
            case 6:
                set({
                    ...values,
                    checkedChecks: event.target.checked,
                    tothChecks: 0,
                    startDateChecks: event.target.checked ? values.initDate : null,
                    finishDateChecks: null,
                });
                break;
            case 7:
                set({
                    ...values,
                    checkedOrd: event.target.checked,
                    tothOrdConTax: event.target.checked ? 1.5 : 0,
                    startDateOrd: event.target.checked ? values.initDate : null,
                    finishDateOrd: null,
                    startDateEsp: !event.target.checked && values.checkedEsp ? values.initDate : null,
                    finishDateEsp: null,
                });
                break;
            case 8:
                set({
                    ...values,
                    checkedEsp: event.target.checked,
                    tothSpeConTax: event.target.checked ? 5 : 0,
                    startDateEsp: event.target.checked
                        ? values.checkedOrd && values.finishDateOrd
                            ? moment(values.finishDateOrd).add(1, "months").startOf("month")
                            : values.initDate
                        : null,
                    finishDateEsp: null,
                });
                break;
            case 9:
                set({
                    ...values,
                    checkedMuni: event.target.checked,
                    tothMuni: 0,
                    startDateMuni: event.target.checked ? values.initDate : null,
                    finishDateMuni: null,
                });
                break;
            case 10:
                set({
                    ...values,
                    checkedOthers: event.target.checked,
                    nameOthers: "",
                    tothOthers: 0,
                    startDateOthers: event.target.checked ? values.initDate : null,
                    finishDateOthers: null,
                });
                break;
            default:
                set({
                    ...values,
                    recuBaseRate: 0,
                    recuProjectTotal: 0,
                    checkedAcc: event.target.checked,
                    tothAcc: 0,
                    startDateAcc: event.target.checked ? values.initDate : null,
                    finishDateAcc: null,
                });
                break;
        }
    };

    const handleChangeStartDateAdd = (date, val) => {
        let startDate = moment(date).startOf("month");
        switch (val) {
            case 0:
                if (!date || !values.finishDateAR) {
                    set({
                        ...values,
                        startDateAR: date ? moment(date).startOf("month") : date,
                    });
                } else {
                    if (startDate.isAfter(moment(values.finishDateAR))) {
                        setTexto(t("reports.initDateMess"));
                        setAutoAlertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            startDateAR: moment(date).startOf("month"),
                        });
                    }
                }
                break;
            case 1:
                if (!date || !values.finishDateAP) {
                    set({
                        ...values,
                        startDateAP: date ? moment(date).startOf("month") : date,
                    });
                } else {
                    if (startDate.isAfter(moment(values.finishDateAP))) {
                        setTexto(t("reports.initDateMess"));
                        setAutoAlertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            startDateAP: moment(date).startOf("month"),
                        });
                    }
                }
                break;
            case 2:
                if (!date || !values.finishDateClass) {
                    set({
                        ...values,
                        startDateClass: date ? moment(date).startOf("month") : date,
                    });
                } else {
                    if (startDate.isAfter(moment(values.finishDateClass))) {
                        setTexto(t("reports.initDateMess"));
                        setAutoAlertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            startDateClass: moment(date).startOf("month"),
                        });
                    }
                }
                break;
            case 3:
                if (!date || !values.finishDateMulti) {
                    set({
                        ...values,
                        startDateMulti: date ? moment(date).startOf("month") : date,
                    });
                } else {
                    if (startDate.isAfter(moment(values.finishDateMulti))) {
                        setTexto(t("reports.initDateMess"));
                        setAutoAlertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            startDateMulti: moment(date).startOf("month"),
                        });
                    }
                }
                break;
            case 4:
                if (!date || !values.finishDatePer) {
                    set({
                        ...values,
                        startDatePer: date ? moment(date).startOf("month") : date,
                    });
                } else {
                    if (startDate.isAfter(moment(values.finishDatePer))) {
                        setTexto(t("reports.initDateMess"));
                        setAutoAlertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            startDatePer: moment(date).startOf("month"),
                        });
                    }
                }
                break;
            case 5:
                if (!date || !values.finishDateSales) {
                    set({
                        ...values,
                        startDateSales: date ? moment(date).startOf("month") : date,
                    });
                } else {
                    if (startDate.isAfter(moment(values.finishDateSales))) {
                        setTexto(t("reports.initDateMess"));
                        setAutoAlertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            startDateSales: moment(date).startOf("month"),
                        });
                    }
                }
                break;
            case 6:
                if (!date || !values.finishDateChecks) {
                    set({
                        ...values,
                        startDateChecks: date ? moment(date).startOf("month") : date,
                    });
                } else {
                    if (startDate.isAfter(moment(values.finishDateChecks))) {
                        setTexto(t("reports.initDateMess"));
                        setAutoAlertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            startDateChecks: moment(date).startOf("month"),
                        });
                    }
                }
                break;
            case 7:
                if (!date || !values.finishDateOrd) {
                    set({
                        ...values,
                        startDateOrd: date ? moment(date).startOf("month") : date,
                    });
                } else {
                    if (startDate.isAfter(moment(values.finishDateOrd))) {
                        setTexto(t("reports.initDateMess"));
                        setAutoAlertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            startDateOrd: moment(date).startOf("month"),
                        });
                    }
                }
                break;
            case 8:
                if (!date || !values.finishDateEsp) {
                    set({
                        ...values,
                        startDateEsp: date ? moment(date).startOf("month") : date,
                    });
                } else {
                    if (startDate.isAfter(moment(values.finishDateEsp))) {
                        setTexto(t("reports.initDateMess"));
                        setAutoAlertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            startDateEsp: moment(date).startOf("month"),
                        });
                    }
                }
                break;
            case 9:
                if (!date || !values.finishDateMuni) {
                    set({
                        ...values,
                        startDateMuni: date ? moment(date).startOf("month") : date,
                    });
                } else {
                    if (startDate.isAfter(moment(values.finishDateMuni))) {
                        setTexto(t("reports.initDateMess"));
                        setAutoAlertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            startDateMuni: moment(date).startOf("month"),
                        });
                    }
                }
                break;
            case 10:
                if (!date || !values.finishDateOthers) {
                    set({
                        ...values,
                        startDateOthers: date ? moment(date).startOf("month") : date,
                    });
                } else {
                    if (startDate.isAfter(moment(values.finishDateOthers))) {
                        setTexto(t("reports.initDateMess"));
                        setAutoAlertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            startDateOthers: moment(date).startOf("month"),
                        });
                    }
                }
                break;
            default:
                if (!date || !values.finishDateAcc) {
                    set({
                        ...values,
                        startDateAcc: date ? moment(date).startOf("month") : date,
                    });
                } else {
                    if (startDate.isAfter(moment(values.finishDateAcc))) {
                        setTexto(t("reports.initDateMess"));
                        setAutoAlertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            startDateAcc: moment(date).startOf("month"),
                        });
                    }
                }
                break;
        }
        setIsErrorFocus(false)
    };

    const handleChangeEndDateAdd = (date, val) => {
        let endDate = moment(date).endOf("month").startOf("day");
        switch (val) {
            case 0:
                if (!date || !values.startDateAR) {
                    set({
                        ...values,
                        finishDateAR: date ? moment(date).endOf("month") : date,
                    });
                } else {
                    if (endDate.isBefore(moment(values.startDateAR))) {
                        setTexto(t("reports.endDateMess"));
                        setAutoAlertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            finishDateAR: moment(date).endOf("month"),
                        });
                    }
                }
                break;
            case 1:
                if (!date || !values.startDateAP) {
                    set({
                        ...values,
                        finishDateAP: date ? moment(date).endOf("month") : date,
                    });
                } else {
                    if (endDate.isBefore(moment(values.startDateAP))) {
                        setTexto(t("reports.endDateMess"));
                        setAutoAlertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            finishDateAP: moment(date).endOf("month"),
                        });
                    }
                }
                break;
            case 2:
                if (!date || !values.startDateClass) {
                    set({
                        ...values,
                        finishDateClass: date ? moment(date).endOf("month") : date,
                    });
                } else {
                    if (endDate.isBefore(moment(values.startDateClass))) {
                        setTexto(t("reports.endDateMess"));
                        setAutoAlertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            finishDateClass: moment(date).endOf("month"),
                        });
                    }
                }
                break;
            case 3:
                if (!date || !values.startDateMulti) {
                    set({
                        ...values,
                        finishDateMulti: date ? moment(date).endOf("month") : date,
                    });
                } else {
                    if (endDate.isBefore(moment(values.startDateMulti))) {
                        setTexto(t("reports.endDateMess"));
                        setAutoAlertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            finishDateMulti: moment(date).endOf("month"),
                        });
                    }
                }
                break;
            case 4:
                if (!date || !values.startDatePer) {
                    set({
                        ...values,
                        finishDatePer: date ? moment(date).endOf("month") : date,
                    });
                } else {
                    if (endDate.isBefore(moment(values.startDatePer))) {
                        setTexto(t("reports.endDateMess"));
                        setAutoAlertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            finishDatePer: moment(date).endOf("month"),
                        });
                    }
                }
                break;
            case 5:
                if (!date || !values.startDateSales) {
                    set({
                        ...values,
                        finishDateSales: date ? moment(date).endOf("month") : date,
                    });
                } else {
                    if (endDate.isBefore(moment(values.startDateSales))) {
                        setTexto(t("reports.endDateMess"));
                        setAutoAlertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            finishDateSales: moment(date).endOf("month"),
                        });
                    }
                }
                break;
            case 6:
                if (!date || !values.startDateChecks) {
                    set({
                        ...values,
                        finishDateChecks: date ? moment(date).endOf("month") : date,
                    });
                } else {
                    if (endDate.isBefore(moment(values.startDateChecks))) {
                        setTexto(t("reports.endDateMess"));
                        setAutoAlertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            finishDateChecks: moment(date).endOf("month"),
                        });
                    }
                }
                break;
            case 7:
                if (!date || !values.startDateOrd) {
                    set({
                        ...values,
                        finishDateOrd: date ? moment(date).endOf("month") : date,
                        checkedEsp: false,
                        tothSpeConTax: 0,
                        startDateEsp: null,
                        finishDateEsp: null,
                    });
                } else {
                    if (endDate.isBefore(moment(values.startDateOrd))) {
                        setTexto(t("reports.endDateMess"));
                        setAutoAlertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            finishDateOrd: moment(date).endOf("month"),
                        });
                    }
                }
                break;
            case 8:
                if (!date || !values.startDateEsp) {
                    set({
                        ...values,
                        finishDateEsp: date ? moment(date).endOf("month") : date,
                    });
                } else {
                    if (endDate.isBefore(moment(values.startDateEsp))) {
                        setTexto(t("reports.endDateMess"));
                        setAutoAlertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            finishDateEsp: moment(date).endOf("month"),
                        });
                    }
                }
                break;
            case 9:
                if (!date || !values.startDateMuni) {
                    set({
                        ...values,
                        finishDateMuni: date ? moment(date).endOf("month") : date,
                    });
                } else {
                    if (endDate.isBefore(moment(values.startDateMuni))) {
                        setTexto(t("reports.endDateMess"));
                        setAutoAlertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            finishDateMuni: moment(date).endOf("month"),
                        });
                    }
                }
                break;
            case 10:
                if (!date || !values.startDateOthers) {
                    set({
                        ...values,
                        finishDateOthers: date ? moment(date).endOf("month") : date,
                    });
                } else {
                    if (endDate.isBefore(moment(values.startDateOthers))) {
                        setTexto(t("reports.endDateMess"));
                        setAutoAlertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            finishDateOthers: moment(date).endOf("month"),
                        });
                    }
                }
                break;
            default:
                if (!date || !values.startDateAcc) {
                    set({
                        ...values,
                        finishDateAcc: date ? moment(date).endOf("month") : date,
                    });
                } else {
                    if (endDate.isBefore(moment(values.startDateAcc))) {
                        setTexto(t("reports.endDateMess"));
                        setAutoAlertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            finishDateAcc: moment(date).endOf("month"),
                        });
                    }
                }
                break;
        }
        setIsErrorFocus(false)
    };

    return (
        <StyledContainer>
            <LocalizationProvider
                dateAdapter={AdapterMoment}
                adapterLocale={t("language.locale")}
                localeText={{ clearButtonLabel: t("miscellaneous.clear") }}
            >
                <Card elevation={0} sx={{ backgroundColor: "inherit" }}>
                    <CardHeader title={t("proposals.additionals")} />
                    <Divider />
                    <CardContent sx={{ pb: 5 }}>
                        <Grid container spacing={2} alignItems="center">
                            {values.recuAdditionalsOnly && (
                                <>
                                    <Grid item xs={12} md={3}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    name="checkedAcc"
                                                    checked={values.checkedAcc}
                                                    onChange={() => {
                                                        return false;
                                                    }}
                                                    inputProps={{
                                                        "aria-label": "controlled",
                                                    }}
                                                />
                                            }
                                            label="Accountant"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            fullWidth
                                            disabled={!values.checkedAcc}
                                            label={t("miscellaneous.hoursMonth")}
                                            name="tothAcc"
                                            onChange={handleChangeHoursAcc}
                                            value={values.tothAcc}
                                            variant="outlined"
                                            type="number"
                                            inputProps={{
                                                min: 0,
                                                step: 0.5,
                                                onKeyDown: (event) => {
                                                    event.preventDefault();
                                                },
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            error={values.checkedAcc && isError && values.tothAcc === 0}
                                            onClick={(e) =>{setIsErrorFocus(false)}}
                                            inputRef={(input) => { (input && isErrorFocus && values.checkedAcc && isError && values.tothAcc === 0) && input.focus()}}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <DatePicker
                                            componentsProps={{
                                                actionBar: {
                                                    actions: ["clear"],
                                                },
                                            }}
                                            disabled={!values.checkedAcc}
                                            value={values.startDateAcc}
                                            minDate={moment(values.initDate).startOf("month")}
                                            onChange={(newValue) => handleChangeStartDateAdd(newValue, 11)}
                                            inputFormat={todos.dateFormat}
                                            renderInput={(params) => (
                                                <TextField
                                                    fullWidth
                                                    required={values.checkedAcc}
                                                    {...params}
                                                    error={values.checkedAcc && isError && !values.startDateAcc}
                                                    onClick={(e) =>{setIsErrorFocus(false)}}
                                                />
                                            )}
                                            // inputProps={{ readOnly: true }}
                                            inputProps={{
                                                onKeyDown: (event) => {
                                                    event.preventDefault();
                                                },
                                            }}
                                            label={t("reports.initDate")}
                                            views={["year", "month"]}
                                            onClick={(e) =>{setIsErrorFocus(false)}}
                                            inputRef={(input) => { (input && isErrorFocus && values.checkedAcc && isError && !values.startDateAcc) && input.focus()}}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <DatePicker
                                            componentsProps={{
                                                actionBar: {
                                                    actions: ["clear"],
                                                },
                                            }}
                                            disabled={!values.checkedAcc}
                                            value={values.finishDateAcc}
                                            minDate={moment(values.initDate).startOf("month")}
                                            onChange={(newValue) => handleChangeEndDateAdd(newValue, 11)}
                                            inputFormat={todos.dateFormat}
                                            renderInput={(params) => <TextField fullWidth {...params} />}
                                            inputProps={{ readOnly: true }}
                                            label={t("reports.endDate")}
                                            views={["year", "month"]}
                                        />
                                    </Grid>
                                </>
                            )}
                            {clientMarket === "Estados Unidos" && (
                                <>
                                    <Grid item xs={12} md={3}>
                                        <LightTooltip title={t("proposals.checkBen")}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        name="checkedChecks"
                                                        checked={values.checkedChecks}
                                                        onChange={(value) => handleChangeCheck(value, 6)}
                                                        inputProps={{
                                                            "aria-label": "controlled",
                                                        }}
                                                        disabled={!values.initDate}
                                                    />
                                                }
                                                label={t("proposals.checks")}
                                            />
                                        </LightTooltip>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            fullWidth
                                            disabled={!values.checkedChecks}
                                            label={t("miscellaneous.hours")}
                                            name="tothChecks"
                                            onChange={handleChange}
                                            value={values.tothChecks}
                                            variant="outlined"
                                            type="number"
                                            inputProps={{
                                                min: 0,
                                                step: 0.5,
                                                onKeyDown: (event) => {
                                                    event.preventDefault();
                                                },
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            error={values.checkedChecks && isError && values.tothChecks === 0}
                                            onClick={(e) =>{setIsErrorFocus(false)}}
                                            inputRef={(input) => { (input && isErrorFocus && values.checkedChecks && isError && values.tothChecks === 0) && input.focus()}}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <DatePicker
                                            componentsProps={{
                                                actionBar: {
                                                    actions: ["clear"],
                                                },
                                            }}
                                            disabled={!values.checkedChecks}
                                            value={values.startDateChecks}
                                            onChange={(newValue) => handleChangeStartDateAdd(newValue, 6)}
                                            minDate={moment(values.initDate).startOf("month")}
                                            inputFormat={todos.dateFormat}
                                            renderInput={(params) => (
                                                <TextField
                                                    fullWidth
                                                    required={values.checkedChecks}
                                                    {...params}
                                                    error={values.checkedChecks && isError && !values.startDateChecks}
                                            onClick={(e) =>{setIsErrorFocus(false)}}
                                                    />
                                            )}
                                            // inputProps={{ readOnly: true }}
                                            inputProps={{
                                                onKeyDown: (event) => {
                                                    event.preventDefault();
                                                },
                                            }}
                                            label={t("reports.initDate")}
                                            views={["year", "month"]}
                                            onClick={(e) =>{setIsErrorFocus(false)}}
                                            inputRef={(input) => { (input && isErrorFocus && values.checkedChecks && isError && !values.startDateChecks) && input.focus()}}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <DatePicker
                                            componentsProps={{
                                                actionBar: {
                                                    actions: ["clear"],
                                                },
                                            }}
                                            disabled={!values.checkedChecks}
                                            value={values.finishDateChecks}
                                            onChange={(newValue) => handleChangeEndDateAdd(newValue, 6)}
                                            minDate={moment(values.initDate).startOf("month")}
                                            inputFormat={todos.dateFormat}
                                            renderInput={(params) => <TextField fullWidth {...params} />}
                                            inputProps={{ readOnly: true }}
                                            label={t("reports.endDate")}
                                            views={["year", "month"]}
                                        />
                                    </Grid>
                                </>
                            )}
                            <Grid item xs={12} md={3}>
                                <LightTooltip title={t("proposals.regAnali")}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name="checkedAR"
                                                checked={values.checkedAR}
                                                onChange={(value) => handleChangeCheck(value, 0)}
                                                inputProps={{
                                                    "aria-label": "controlled",
                                                }}
                                                disabled={!values.initDate}
                                            />
                                        }
                                        label={t("proposals.arAdd")}
                                    />
                                </LightTooltip>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    fullWidth
                                    disabled={!values.checkedAR}
                                    label={t("miscellaneous.hoursMonth")}
                                    name="tothAR"
                                    onChange={handleChange}
                                    value={values.tothAR}
                                    variant="outlined"
                                    type="number"
                                    inputProps={{
                                        min: 0,
                                        step: 0.5,
                                        onKeyDown: (event) => {
                                            event.preventDefault();
                                        },
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    error={values.checkedAR && isError && values.tothAR === 0}
                                    onClick={(e) =>{setIsErrorFocus(false)}}
                                    inputRef={(input) => { (input && isErrorFocus &&values.checkedAR && isError && values.tothAR === 0) && input.focus()}}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    disabled={!values.checkedAR}
                                    value={values.startDateAR}
                                    onChange={(newValue) => handleChangeStartDateAdd(newValue, 0)}
                                    minDate={moment(values.initDate).startOf("month")}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            required={values.checkedAR}
                                            {...params}
                                            error={values.checkedAR && isError && !values.startDateAR}
                                            onClick={(e) =>{setIsErrorFocus(false)}}
                                        />       
                                    )}
                                    // inputProps={{ readOnly: true }}
                                    inputProps={{
                                        onKeyDown: (event) => {
                                            event.preventDefault();
                                        },
                                    }}
                                    label={t("reports.initDate")}
                                    views={["year", "month"]}
                                    onClick={(e) =>{setIsErrorFocus(false)}}
                                    inputRef={(input) => { (input && isErrorFocus &&values.checkedAR && isError && !values.startDateAR) && input.focus()}}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    disabled={!values.checkedAR}
                                    value={values.finishDateAR}
                                    onChange={(newValue) => handleChangeEndDateAdd(newValue, 0)}
                                    minDate={moment(values.initDate).startOf("month")}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => <TextField fullWidth {...params} />}
                                    inputProps={{ readOnly: true }}
                                    label={t("reports.endDate")}
                                    views={["year", "month"]}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <LightTooltip title={t("proposals.addAPAcc")}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name="checkedAP"
                                                checked={values.checkedAP}
                                                onChange={(value) => handleChangeCheck(value, 1)}
                                                inputProps={{
                                                    "aria-label": "controlled",
                                                }}
                                                disabled={!values.initDate}
                                            />
                                        }
                                        label={t("proposals.apAdd")}
                                    />
                                </LightTooltip>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    fullWidth
                                    disabled={!values.checkedAP}
                                    label={t("miscellaneous.hoursMonth")}
                                    name="tothAP"
                                    onChange={handleChange}
                                    value={values.tothAP}
                                    variant="outlined"
                                    type="number"
                                    inputProps={{
                                        min: 0,
                                        step: 0.5,
                                        onKeyDown: (event) => {
                                            event.preventDefault();
                                        },
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    error={values.checkedAP && isError && values.tothAP === 0}
                                    onClick={(e) =>{setIsErrorFocus(false)}}
                                    inputRef={(input) => { (input && isErrorFocus && values.checkedAP && isError && values.tothAP === 0) && input.focus()}}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    disabled={!values.checkedAP}
                                    value={values.startDateAP}
                                    onChange={(newValue) => handleChangeStartDateAdd(newValue, 1)}
                                    minDate={moment(values.initDate).startOf("month")}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            required={values.checkedAP}
                                            {...params}
                                            error={values.checkedAP && isError && !values.startDateAP}
                                            onClick={(e) =>{setIsErrorFocus(false)}}
                                        />
        
                                        )}
                                    // inputProps={{ readOnly: true }}
                                    inputProps={{
                                        onKeyDown: (event) => {
                                            event.preventDefault();
                                        },
                                    }}
                                    label={t("reports.initDate")}
                                    views={["year", "month"]}
                                    onClick={(e) =>{setIsErrorFocus(false)}}
                                    inputRef={(input) => { (input && isErrorFocus && values.checkedAP && isError && !values.startDateAP) && input.focus()}}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    disabled={!values.checkedAP}
                                    value={values.finishDateAP}
                                    onChange={(newValue) => handleChangeEndDateAdd(newValue, 1)}
                                    minDate={moment(values.initDate).startOf("month")}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => <TextField fullWidth {...params} />}
                                    inputProps={{ readOnly: true }}
                                    label={t("reports.endDate")}
                                    views={["year", "month"]}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <LightTooltip title={t("proposals.addCostsCent")}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name="checkedClass"
                                                checked={values.checkedClass}
                                                onChange={(value) => handleChangeCheck(value, 2)}
                                                inputProps={{
                                                    "aria-label": "controlled",
                                                }}
                                                disabled={!values.initDate}
                                            />
                                        }
                                        label={t("services.classesCategories")}
                                    />
                                </LightTooltip>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    fullWidth
                                    disabled={!values.checkedClass}
                                    label={t("miscellaneous.hoursMonth")}
                                    name="tothClass"
                                    onChange={handleChange}
                                    value={values.tothClass}
                                    variant="outlined"
                                    type="number"
                                    inputProps={{
                                        min: 0,
                                        step: 0.5,
                                        onKeyDown: (event) => {
                                            event.preventDefault();
                                        },
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    error={values.checkedClass && isError && values.tothClass === 0}
                                    onClick={(e) =>{setIsErrorFocus(false)}}
                                    inputRef={(input) => { (input && isErrorFocus && values.checkedClass && isError && values.tothClass === 0) && input.focus()}}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    disabled={!values.checkedClass}
                                    value={values.startDateClass}
                                    onChange={(newValue) => handleChangeStartDateAdd(newValue, 2)}
                                    minDate={moment(values.initDate).startOf("month")}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            required={values.checkedClass}
                                            {...params}
                                            error={values.checkedClass && isError && !values.startDateClass}
                                            onClick={(e) =>{setIsErrorFocus(false)}}
                                        />
    
                                        )}
                                    // inputProps={{ readOnly: true }}
                                    inputProps={{
                                        onKeyDown: (event) => {
                                            event.preventDefault();
                                        },
                                    }}
                                    label={t("reports.initDate")}
                                    views={["year", "month"]}
                                    onClick={(e) =>{setIsErrorFocus(false)}}
                                    inputRef={(input) => { (input && isErrorFocus && values.checkedClass && isError && !values.startDateClass) && input.focus()}}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    disabled={!values.checkedClass}
                                    value={values.finishDateClass}
                                    onChange={(newValue) => handleChangeEndDateAdd(newValue, 2)}
                                    minDate={moment(values.initDate).startOf("month")}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => <TextField fullWidth {...params} />}
                                    inputProps={{ readOnly: true }}
                                    label={t("reports.endDate")}
                                    views={["year", "month"]}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <LightTooltip title={t("proposals.addRecordRe")}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name="checkedMulti"
                                                checked={values.checkedMulti}
                                                onChange={(value) => handleChangeCheck(value, 3)}
                                                inputProps={{
                                                    "aria-label": "controlled",
                                                }}
                                                disabled={!values.initDate}
                                            />
                                        }
                                        label={t("proposals.multiEntity")}
                                    />
                                </LightTooltip>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    fullWidth
                                    disabled={!values.checkedMulti}
                                    label={t("miscellaneous.hoursMonth")}
                                    name="tothMulti"
                                    onChange={handleChange}
                                    value={values.tothMulti}
                                    variant="outlined"
                                    type="number"
                                    inputProps={{
                                        min: 0,
                                        step: 0.5,
                                        onKeyDown: (event) => {
                                            event.preventDefault();
                                        },
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    error={values.checkedMulti && isError && values.tothMulti === 0}
                                    onClick={(e) =>{setIsErrorFocus(false)}}
                                    inputRef={(input) => { (input && isErrorFocus && values.checkedMulti && isError && values.tothMulti === 0) && input.focus()}}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    disabled={!values.checkedMulti}
                                    value={values.startDateMulti}
                                    onChange={(newValue) => handleChangeStartDateAdd(newValue, 3)}
                                    minDate={moment(values.initDate).startOf("month")}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            required={values.checkedMulti}
                                            {...params}
                                            error={values.checkedMulti && isError && !values.startDateMulti}
                                            onClick={(e) =>{setIsErrorFocus(false)}}
                                        />
    
                                        )}
                                    // inputProps={{ readOnly: true }}
                                    inputProps={{
                                        onKeyDown: (event) => {
                                            event.preventDefault();
                                        },
                                    }}
                                    label={t("reports.initDate")}
                                    views={["year", "month"]}
                                    onClick={(e) =>{setIsErrorFocus(false)}}
                                    inputRef={(input) => { (input && isErrorFocus && values.checkedMulti && isError && !values.startDateMulti) && input.focus()}}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    disabled={!values.checkedMulti}
                                    value={values.finishDateMulti}
                                    onChange={(newValue) => handleChangeEndDateAdd(newValue, 3)}
                                    minDate={moment(values.initDate).startOf("month")}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => <TextField fullWidth {...params} />}
                                    inputProps={{ readOnly: true }}
                                    label={t("reports.endDate")}
                                    views={["year", "month"]}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <LightTooltip title={t("proposals.addTayMade")}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name="checkedPer"
                                                checked={values.checkedPer}
                                                onChange={(value) => handleChangeCheck(value, 4)}
                                                inputProps={{
                                                    "aria-label": "controlled",
                                                }}
                                                disabled={!values.initDate}
                                            />
                                        }
                                        label={t("services.personalizedReporting")}
                                    />
                                </LightTooltip>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    fullWidth
                                    disabled={!values.checkedPer}
                                    label={t("miscellaneous.hoursMonth")}
                                    name="tothPer"
                                    onChange={handleChange}
                                    value={values.tothPer}
                                    variant="outlined"
                                    type="number"
                                    inputProps={{
                                        min: 0,
                                        step: 0.5,
                                        onKeyDown: (event) => {
                                            event.preventDefault();
                                        },
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    error={values.checkedPer && isError && values.tothPer === 0}
                                    onClick={(e) =>{setIsErrorFocus(false)}}
                                    inputRef={(input) => { (input && isErrorFocus && values.checkedPer && isError && values.tothPer === 0) && input.focus()}}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    disabled={!values.checkedPer}
                                    value={values.startDatePer}
                                    onChange={(newValue) => handleChangeStartDateAdd(newValue, 4)}
                                    minDate={moment(values.initDate).startOf("month")}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            required={values.checkedPer}
                                            {...params}
                                            error={values.checkedPer && isError && !values.startDatePer}
                                            onClick={(e) =>{setIsErrorFocus(false)}}
                                        />   
                                        )}
                                    // inputProps={{ readOnly: true }}
                                    inputProps={{
                                        onKeyDown: (event) => {
                                            event.preventDefault();
                                        },
                                    }}
                                    label={t("reports.initDate")}
                                    views={["year", "month"]}
                                    onClick={(e) =>{setIsErrorFocus(false)}}
                                    inputRef={(input) => { (input && isErrorFocus && values.checkedPer && isError && !values.startDatePer) && input.focus()}}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    disabled={!values.checkedPer}
                                    value={values.finishDatePer}
                                    onChange={(newValue) => handleChangeEndDateAdd(newValue, 4)}
                                    minDate={moment(values.initDate).startOf("month")}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => <TextField fullWidth {...params} />}
                                    inputProps={{ readOnly: true }}
                                    label={t("reports.endDate")}
                                    views={["year", "month"]}
                                />
                            </Grid>
                            {clientMarket === "Estados Unidos" && (
                                <>
                                    <Grid item xs={12} md={3}>
                                        <LightTooltip title={t("proposals.addSalesDet")}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        name="checkedSales"
                                                        checked={values.checkedSales}
                                                        onChange={(value) => handleChangeCheck(value, 5)}
                                                        inputProps={{
                                                            "aria-label": "controlled",
                                                        }}
                                                        disabled={!values.initDate}
                                                    />
                                                }
                                                label={t("services.salesTaxes")}
                                            />
                                        </LightTooltip>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            fullWidth
                                            disabled={!values.checkedSales}
                                            label={t("miscellaneous.hours")}
                                            name="tothSales"
                                            onChange={handleChange}
                                            value={values.tothSales}
                                            variant="outlined"
                                            type="number"
                                            inputProps={{
                                                min: 0,
                                                step: 0.5,
                                                onKeyDown: (event) => {
                                                    event.preventDefault();
                                                },
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            error={values.checkedSales && isError && values.tothSales === 0}
                                            onClick={(e) =>{setIsErrorFocus(false)}}
                                            inputRef={(input) => { (input && isErrorFocus && values.checkedSales && isError && values.tothSales === 0) && input.focus()}}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <DatePicker
                                            componentsProps={{
                                                actionBar: {
                                                    actions: ["clear"],
                                                },
                                            }}
                                            disabled={!values.checkedSales}
                                            value={values.startDateSales}
                                            onChange={(newValue) => handleChangeStartDateAdd(newValue, 5)}
                                            minDate={moment(values.initDate).startOf("month")}
                                            inputFormat={todos.dateFormat}
                                            renderInput={(params) => (
                                                <TextField
                                                    fullWidth
                                                    required={values.checkedSales}
                                                    {...params}
                                                    error={values.checkedSales && isError && !values.startDateSales}
                                                    onClick={(e) =>{setIsErrorFocus(false)}}
                                                    />
                                            )}
                                            // inputProps={{ readOnly: true }}
                                            inputProps={{
                                                onKeyDown: (event) => {
                                                    event.preventDefault();
                                                },
                                            }}
                                            label={t("reports.initDate")}
                                            views={["year", "month"]}
                                            onClick={(e) =>{setIsErrorFocus(false)}}
                                            inputRef={(input) => { (input && isErrorFocus && values.checkedSales && isError && !values.startDateSales) && input.focus()}}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <DatePicker
                                            componentsProps={{
                                                actionBar: {
                                                    actions: ["clear"],
                                                },
                                            }}
                                            disabled={!values.checkedSales}
                                            value={values.finishDateSales}
                                            onChange={(newValue) => handleChangeEndDateAdd(newValue, 5)}
                                            minDate={moment(values.initDate).startOf("month")}
                                            inputFormat={todos.dateFormat}
                                            renderInput={(params) => <TextField fullWidth {...params} />}
                                            inputProps={{ readOnly: true }}
                                            label={t("reports.endDate")}
                                            views={["year", "month"]}
                                        />
                                    </Grid>
                                </>
                            )}
                            {clientMarket === "Venezuela" && (
                                <>
                                    <Grid item xs={12} md={3}>
                                        <LightTooltip title={t("proposals.addOrdTaxDet")}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        name="checkedOrd"
                                                        checked={values.checkedOrd}
                                                        onChange={(value) => handleChangeCheck(value, 7)}
                                                        inputProps={{
                                                            "aria-label": "controlled",
                                                        }}
                                                        disabled={
                                                            (!values.checkedOrd &&
                                                                values.checkedEsp &&
                                                                !values.finishDateOrd) ||
                                                            !values.initDate
                                                        }
                                                    />
                                                }
                                                label={t("proposals.addOrdTax")}
                                            />
                                        </LightTooltip>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            fullWidth
                                            disabled={!values.checkedOrd}
                                            label={t("miscellaneous.hours")}
                                            name="tothOrdConTax"
                                            onChange={handleChange}
                                            value={values.tothOrdConTax}
                                            variant="outlined"
                                            type="number"
                                            inputProps={{
                                                min: 1.5,
                                                step: 0.5,
                                                onKeyDown: (event) => {
                                                    event.preventDefault();
                                                },
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            error={values.checkedOrd && isError && values.tothOrdConTax === 0}
                                            onClick={(e) =>{setIsErrorFocus(false)}}
                                            inputRef={(input) => { (input && isErrorFocus && values.checkedOrd && isError && values.tothOrdConTax === 0) && input.focus()}}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <DatePicker
                                            componentsProps={{
                                                actionBar: {
                                                    actions: ["clear"],
                                                },
                                            }}
                                            disabled={!values.checkedOrd}
                                            value={values.startDateOrd}
                                            onChange={(newValue) => handleChangeStartDateAdd(newValue, 7)}
                                            minDate={moment(values.initDate).startOf("month")}
                                            inputFormat={todos.dateFormat}
                                            renderInput={(params) => (
                                                <TextField
                                                    fullWidth
                                                    required={values.checkedOrd}
                                                    {...params}
                                                    error={values.checkedOrd && isError && !values.startDateOrd}
                                                    onClick={(e) =>{setIsErrorFocus(false)}}
                                                />
                                            )}
                                            // inputProps={{ readOnly: true }}
                                            inputProps={{
                                                onKeyDown: (event) => {
                                                    event.preventDefault();
                                                },
                                            }}
                                            label={t("reports.initDate")}
                                            views={["year", "month"]}
                                            onClick={(e) =>{setIsErrorFocus(false)}}
                                            inputRef={(input) => { (input && isErrorFocus && values.checkedOrd && isError && !values.startDateOrd) && input.focus()}}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <DatePicker
                                            componentsProps={{
                                                actionBar: {
                                                    actions: ["clear"],
                                                },
                                            }}
                                            disabled={!values.checkedOrd}
                                            value={values.finishDateOrd}
                                            onChange={(newValue) => handleChangeEndDateAdd(newValue, 7)}
                                            minDate={moment(values.initDate).startOf("month")}
                                            inputFormat={todos.dateFormat}
                                            renderInput={(params) => <TextField fullWidth {...params} />}
                                            inputProps={{ readOnly: true }}
                                            label={t("reports.endDate")}
                                            views={["year", "month"]}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <LightTooltip title={t("proposals.addSpecialDet")}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        name="checkedEsp"
                                                        checked={values.checkedEsp}
                                                        onChange={(value) => handleChangeCheck(value, 8)}
                                                        inputProps={{
                                                            "aria-label": "controlled",
                                                        }}
                                                        disabled={
                                                            (values.checkedOrd && !values.finishDateOrd) ||
                                                            !values.initDate
                                                        }
                                                    />
                                                }
                                                label={t("proposals.addSpecial")}
                                            />
                                        </LightTooltip>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            fullWidth
                                            disabled={!values.checkedEsp}
                                            label={t("miscellaneous.hours")}
                                            name="tothSpeConTax"
                                            onChange={handleChange}
                                            value={values.tothSpeConTax}
                                            variant="outlined"
                                            type="number"
                                            inputProps={{
                                                min: 5,
                                                step: 0.5,
                                                onKeyDown: (event) => {
                                                    event.preventDefault();
                                                },
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            error={values.checkedEsp && isError && values.tothSpeConTax === 0}
                                            onClick={(e) =>{setIsErrorFocus(false)}}
                                            inputRef={(input) => { (input && isErrorFocus && values.checkedEsp && isError && values.tothSpeConTax === 0) && input.focus()}}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <DatePicker
                                            componentsProps={{
                                                actionBar: {
                                                    actions: ["clear"],
                                                },
                                            }}
                                            disabled={!values.checkedEsp}
                                            value={values.startDateEsp}
                                            onChange={(newValue) => handleChangeStartDateAdd(newValue, 8)}
                                            minDate={moment(values.initDate).startOf("month")}
                                            inputFormat={todos.dateFormat}
                                            renderInput={(params) => (
                                                <TextField
                                                    fullWidth
                                                    required={values.checkedEsp}
                                                    {...params}
                                                    error={values.checkedEsp && isError && !values.startDateEsp}
                                                    onClick={(e) =>{setIsErrorFocus(false)}}
                                                />
                                            )}
                                            // inputProps={{ readOnly: true }}
                                            inputProps={{
                                                onKeyDown: (event) => {
                                                    event.preventDefault();
                                                },
                                            }}
                                            label={t("reports.initDate")}
                                            views={["year", "month"]}
                                            onClick={(e) =>{setIsErrorFocus(false)}}
                                            inputRef={(input) => { (input && isErrorFocus && values.checkedEsp && isError && !values.startDateEsp) && input.focus()}}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <DatePicker
                                            componentsProps={{
                                                actionBar: {
                                                    actions: ["clear"],
                                                },
                                            }}
                                            disabled={!values.checkedEsp}
                                            value={values.finishDateEsp}
                                            onChange={(newValue) => handleChangeEndDateAdd(newValue, 8)}
                                            minDate={moment(values.initDate).startOf("month")}
                                            inputFormat={todos.dateFormat}
                                            renderInput={(params) => <TextField fullWidth {...params} />}
                                            inputProps={{ readOnly: true }}
                                            label={t("reports.endDate")}
                                            views={["year", "month"]}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <LightTooltip title={t("proposals.addImpMunDet")}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        name="checkedMuni"
                                                        checked={values.checkedMuni}
                                                        onChange={(value) => handleChangeCheck(value, 9)}
                                                        inputProps={{
                                                            "aria-label": "controlled",
                                                        }}
                                                        disabled={!values.initDate}
                                                    />
                                                }
                                                label={t("proposals.addImpMun")}
                                            />
                                        </LightTooltip>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            fullWidth
                                            disabled={!values.checkedMuni}
                                            label={t("miscellaneous.hours")}
                                            name="tothMuni"
                                            onChange={handleChange}
                                            value={values.tothMuni}
                                            variant="outlined"
                                            type="number"
                                            inputProps={{
                                                min: 0,
                                                step: 0.5,
                                                onKeyDown: (event) => {
                                                    event.preventDefault();
                                                },
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            error={values.checkedMuni && isError && values.tothMuni === 0}
                                            onClick={(e) =>{setIsErrorFocus(false)}}
                                            inputRef={(input) => { (input && isErrorFocus && values.checkedMuni && isError && values.tothMuni === 0) && input.focus()}}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <DatePicker
                                            componentsProps={{
                                                actionBar: {
                                                    actions: ["clear"],
                                                },
                                            }}
                                            disabled={!values.checkedMuni}
                                            value={values.startDateMuni}
                                            onChange={(newValue) => handleChangeStartDateAdd(newValue, 9)}
                                            minDate={moment(values.initDate).startOf("month")}
                                            inputFormat={todos.dateFormat}
                                            renderInput={(params) => (
                                                <TextField
                                                    fullWidth
                                                    required={values.checkedMuni}
                                                    {...params}
                                                    error={values.checkedMuni && isError && !values.startDateMuni}
                                                />
                                            )}
                                            // inputProps={{ readOnly: true }}
                                            inputProps={{
                                                min: 0,
                                                step: 0.5,
                                                onKeyDown: (event) => {
                                                    event.preventDefault();
                                                },
                                            }}
                                            label={t("reports.initDate")}
                                            views={["year", "month"]}
                                            onClick={(e) =>{setIsErrorFocus(false)}}
                                            inputRef={(input) => { (input && isErrorFocus && values.checkedMuni && isError && !values.startDateMuni) && input.focus()}}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <DatePicker
                                            componentsProps={{
                                                actionBar: {
                                                    actions: ["clear"],
                                                },
                                            }}
                                            disabled={!values.checkedMuni}
                                            value={values.finishDateMuni}
                                            onChange={(newValue) => handleChangeEndDateAdd(newValue, 9)}
                                            minDate={moment(values.initDate).startOf("month")}
                                            inputFormat={todos.dateFormat}
                                            renderInput={(params) => <TextField fullWidth {...params} />}
                                            inputProps={{ readOnly: true }}
                                            label={t("reports.endDate")}
                                            views={["year", "month"]}
                                        />
                                    </Grid>
                                </>
                            )}
                            <Grid item xs={12} md={3}>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={12} md={3} xl={2} sx={{ paddingLeft: "5px !important" }}>
                                        <Checkbox
                                            name="checkedOthers"
                                            checked={values.checkedOthers}
                                            onChange={(value) => handleChangeCheck(value, 10)}
                                            inputProps={{
                                                "aria-label": "controlled",
                                            }}
                                            disabled={!values.initDate}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={9} xl={10}>
                                        <TextField
                                            fullWidth
                                            disabled={!values.checkedOthers}
                                            label={t("services.others")}
                                            name="nameOthers"
                                            onChange={handleChange}
                                            value={values.nameOthers}
                                            variant="outlined"
                                            required={values.checkedOthers}
                                            error={values.checkedOthers && isError && values.nameOthers === ""}
                                            onClick={(e) =>{setIsErrorFocus(false)}}
                                            inputRef={(input) => { (input && isErrorFocus && values.checkedOthers && isError && values.nameOthers === "") && input.focus()}}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    fullWidth
                                    disabled={!values.checkedOthers}
                                    label={t("miscellaneous.hoursMonth")}
                                    name="tothOthers"
                                    onChange={handleChange}
                                    value={values.tothOthers}
                                    variant="outlined"
                                    type="number"
                                    inputProps={{
                                        min: 0,
                                        step: 0.5,
                                        onKeyDown: (event) => {
                                            event.preventDefault();
                                        },
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    error={values.checkedOthers && isError && values.tothOthers === 0}
                                    onClick={(e) =>{setIsErrorFocus(false)}}
                                    inputRef={(input) => { (input &&  isErrorFocus && values.checkedOthers && isError && values.tothOthers === 0) && input.focus()}}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    disabled={!values.checkedOthers}
                                    value={values.startDateOthers}
                                    onChange={(newValue) => handleChangeStartDateAdd(newValue, 10)}
                                    minDate={moment(values.initDate).startOf("month")}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            required={values.checkedOthers}
                                            {...params}
                                            error={values.checkedOthers && isError && !values.startDateOthers}
                                            onClick={(e) =>{setIsErrorFocus(false)}}/>

                                    )}
                                    // inputProps={{ readOnly: true }}
                                    inputProps={{
                                        onKeyDown: (event) => {
                                            event.preventDefault();
                                        },
                                    }}
                                    label={t("reports.initDate")}
                                    views={["year", "month"]}
                                    onClick={(e) =>{setIsErrorFocus(false)}}
                                    inputRef={(input) => { (input && isErrorFocus && values.checkedOthers && isError && !values.startDateOthers) && input.focus()}}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    disabled={!values.checkedOthers}
                                    value={values.finishDateOthers}
                                    onChange={(newValue) => handleChangeEndDateAdd(newValue, 10)}
                                    minDate={moment(values.initDate).startOf("month")}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => <TextField fullWidth {...params} />}
                                    inputProps={{ readOnly: true }}
                                    label={t("reports.endDate")}
                                    views={["year", "month"]}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}></Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    fullWidth
                                    disabled
                                    label={t("services.hoursTotal")}
                                    value={sumTotalAddHours}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormControl fullWidth variant="outlined" disabled>
                                    <InputLabel htmlFor="outlined-adornment-hourlyRate">
                                        {t("services.hourlyRate")}
                                    </InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-hourlyRate"
                                        value={addHourFee}
                                        inputComponent={NumberFormatCustom}
                                        startAdornment={
                                            <InputAdornment disableTypography position="start">
                                                $
                                            </InputAdornment>
                                        }
                                        label={t("services.hourlyRate")}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormControl fullWidth variant="outlined" disabled>
                                    <InputLabel htmlFor="outlined-adornment-hourlyRateAdd">
                                        {t("services.hourlyRateAdd")}
                                    </InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-hourlyRateAdd"
                                        value={addHourFee * sumTotal}
                                        inputComponent={NumberFormatCustom}
                                        startAdornment={
                                            <InputAdornment disableTypography position="start">
                                                $
                                            </InputAdornment>
                                        }
                                        label={t("services.hourlyRateAdd")}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </LocalizationProvider>
        </StyledContainer>
    );
};
