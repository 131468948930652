// React
import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { NumericFormat } from "react-number-format";

// MUI
import {
    Box,
    TextField,
    Grid,
    Divider,
    Card,
    CardHeader,
    CardContent,
    Button,
    FormControl,
    InputLabel,
    OutlinedInput,
    InputAdornment,
    FormControlLabel,
    Checkbox,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

const PREFIX = "UpdateAdd";

const classes = {
    btnRoot: `${PREFIX}-btnRoot`,
    buttonContainer: `${PREFIX}-buttonContainer`,
    switch: `${PREFIX}-switch`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.btnRoot}`]: {
        border: "1px solid #ff5968",
        borderRadius: 40,
        "&:hover": {
            backgroundColor: "#2f3190",
            border: "1px solid #2f3190",
        },
        "&.Mui-disabled": {
            color: "#fff",
            borderColor: "#ffcdd2",
            background: "#ffcdd2",
        },
    },
    [`& .${classes.buttonContainer}`]: {
        display: "flex",
        justifyContent: "flex-end",
        padding: 15,
    },
    [`& .${classes.switch}`]: {
        "& .MuiSwitch-switchBase .MuiSwitch-thumb": {
            color: "#FFF",
        },
        "& .MuiSwitch-switchBase + .MuiSwitch-track": {
            backgroundColor: "#031851",
        },
        "& .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb": {
            color: "#FF5968",
        },
        "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
            backgroundColor: "#FFA8B1",
        },
    },
}));

const NumberFormatCustom = (props) => {
    const todos = useSelector((state) => state.todos);

    const { inputRef, value, onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            value={value}
            getInputRef={inputRef}
            onValueChange={({ value: v }) => onChange({ target: { value: v } })}
            thousandSeparator={todos.amountFormat === "de-DE" ? "." : ","}
            decimalSeparator={todos.amountFormat === "de-DE" ? "," : "."}
            fixedDecimalScale={true}
            decimalScale={2}
            valueIsNumericString
        />
    );
};

export const UpdateAdd = (props) => {
    // Properties
    const {
        values,
        set,
        setTexto,
        setalertType,
        setShow,
        isError,
        updateProject,
        sumTotal,
        cfoSystem,
        expenseEst,
        showConfirm,
    } = props;

    /** Internationalization i18n */
    const [t] = useTranslation("global");

    /** React redux */
    const todos = useSelector((state) => state.todos);

    /** Component functions */
    const handleChange = (event) => {
        set({
            ...values,
            [event.target.name]: event.target.value,
        });
    };

    const handleChangeHoursCFO = (event) => {
        let total =
            values.addHourFee *
            parseFloat(event.target.value) *
            (isNaN(moment(values.finishDateCFO).diff(moment(values.startDateCFO)))
                ? 0
                : Math.ceil(moment(values.finishDateCFO).diff(moment(values.startDateCFO), "months", true)));
        set({
            ...values,
            [event.target.name]: event.target.value,
            projectFee: total,
            projecTot: total * (1 - values.discount / 100),
        });
    };

    const handleChangeCheck = (event, value) => {
        switch (value) {
            case 0:
                if (values.checkedCorpOG && !event.target.checked && !todos.remind) {
                    showConfirm({
                        check: "checkedCorp",
                        toth: "tothCorp",
                        startDate: "startDateCorp",
                        finishDate: "finishDateCorp",
                    });
                } else {
                    set({
                        ...values,
                        checkedCorp: event.target.checked,
                        tothCorp: 0,
                        startDateCorp: event.target.checked ? values.initDate : null,
                        finishDateCorp: event.target.checked ? values.endDate : null,
                    });
                }
                break;
            case 1:
                if (values.checkedPreOG && !event.target.checked && !todos.remind) {
                    showConfirm({
                        check: "checkedPre",
                        toth: "tothPre",
                        startDate: "startDatePre",
                        finishDate: "finishDatePre",
                    });
                } else {
                    set({
                        ...values,
                        checkedPre: event.target.checked,
                        tothPre: 0,
                        startDatePre: event.target.checked ? values.initDate : null,
                        finishDatePre: event.target.checked ? values.endDate : null,
                    });
                }
                break;
            case 2:
                if (values.checkedRepOG && !event.target.checked && !todos.remind) {
                    showConfirm({
                        check: "checkedRep",
                        toth: "tothRep",
                        startDate: "startDateRep",
                        finishDate: "finishDateRep",
                    });
                } else {
                    set({
                        ...values,
                        checkedRep: event.target.checked,
                        tothRep: 0,
                        startDateRep: event.target.checked ? values.initDate : null,
                        finishDateRep: event.target.checked ? values.endDate : null,
                    });
                }
                break;
            case 3:
                if (values.checkedOthersOG && !event.target.checked && !todos.remind) {
                    showConfirm({
                        check: "checkedOthers",
                        nameOthers: "nameOthers",
                        toth: "tothOthers",
                        startDate: "startDateOthers",
                        finishDate: "finishDateOthers",
                    });
                } else {
                    set({
                        ...values,
                        checkedOthers: event.target.checked,
                        nameOthers: "",
                        tothOthers: 0,
                        startDateOthers: event.target.checked ? values.initDate : null,
                        finishDateOthers: event.target.checked ? values.endDate : null,
                    });
                }
                break;
            default:
                set({
                    ...values,
                    checkedCFO: event.target.checked,
                    tothCFO: 0,
                    startDateCFO: event.target.checked ? values.initDate : null,
                    finishDateCFO: event.target.checked ? values.endDate : null,
                    projectFee: 0,
                    projecTot: 0,
                });
                break;
        }
    };

    const handleChangeStartDateAdd = (date, val) => {
        let startDate = moment(date).startOf("month");
        switch (val) {
            case 0:
                if (!date || !values.finishDateCorp) {
                    set({
                        ...values,
                        startDateCorp: date ? startDate : date,
                    });
                } else {
                    if (startDate.isAfter(moment(values.finishDateCorp))) {
                        setTexto(t("reports.initDateMess"));
                        setalertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            startDateCorp: startDate,
                        });
                    }
                }
                break;
            case 1:
                if (!date || !values.finishDatePre) {
                    set({
                        ...values,
                        startDatePre: date ? startDate : date,
                    });
                } else {
                    if (startDate.isAfter(moment(values.finishDatePre))) {
                        setTexto(t("reports.initDateMess"));
                        setalertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            startDatePre: startDate,
                        });
                    }
                }
                break;
            case 2:
                if (!date || !values.finishDateRep) {
                    set({
                        ...values,
                        startDateRep: date ? startDate : date,
                    });
                } else {
                    if (startDate.isAfter(moment(values.finishDateRep))) {
                        setTexto(t("reports.initDateMess"));
                        setalertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            startDateRep: startDate,
                        });
                    }
                }
                break;
            case 3:
                if (!date || !values.finishDateOthers) {
                    set({
                        ...values,
                        startDateOthers: date ? startDate : date,
                    });
                } else {
                    if (startDate.isAfter(moment(values.finishDateOthers))) {
                        setTexto(t("reports.initDateMess"));
                        setalertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            startDateOthers: startDate,
                        });
                    }
                }
                break;
            default:
                let total =
                    values.addHourFee *
                    parseFloat(values.tothCFO) *
                    (isNaN(moment(values.finishDateCFO).diff(startDate))
                        ? 0
                        : Math.ceil(moment(values.finishDateCFO).diff(startDate, "months", true)));

                if (!date || !values.finishDateCFO) {
                    set({
                        ...values,
                        startDateCFO: date ? startDate : date,
                        months: Math.ceil(moment(values.finishDateCFO).diff(startDate, "months", true)),
                        projectFee: total,
                        projecTot: total * (1 - values.discount / 100),
                    });
                } else {
                    if (startDate.isAfter(moment(values.finishDateCFO))) {
                        setTexto(t("reports.initDateMess"));
                        setalertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            startDateCFO: startDate,
                            months: Math.ceil(moment(values.finishDateCFO).diff(startDate, "months", true)),
                            projectFee: total,
                            projecTot: total * (1 - values.discount / 100),
                        });
                    }
                }
                break;
        }
    };

    const handleChangeEndDateAdd = (date, val) => {
        let endDate = moment(date).endOf("month").startOf("day");
        switch (val) {
            case 0:
                if (!date || !values.startDateCorp) {
                    set({
                        ...values,
                        finishDateCorp: date ? endDate : date,
                    });
                } else {
                    if (endDate.isBefore(moment(values.startDateCorp))) {
                        setTexto(t("reports.endDateMess"));
                        setalertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            finishDateCorp: endDate,
                        });
                    }
                }
                break;
            case 1:
                if (!date || !values.startDatePre) {
                    set({
                        ...values,
                        finishDatePre: date ? endDate : date,
                    });
                } else {
                    if (endDate.isBefore(moment(values.startDatePre))) {
                        setTexto(t("reports.endDateMess"));
                        setalertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            finishDatePre: endDate,
                        });
                    }
                }
                break;
            case 2:
                if (!date || !values.startDateRep) {
                    set({
                        ...values,
                        finishDateRep: date ? endDate : date,
                    });
                } else {
                    if (endDate.isBefore(moment(values.startDateRep))) {
                        setTexto(t("reports.endDateMess"));
                        setalertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            finishDateRep: endDate,
                        });
                    }
                }
                break;
            case 3:
                if (!date || !values.startDateOthers) {
                    set({
                        ...values,
                        finishDateOthers: date ? endDate : date,
                    });
                } else {
                    if (endDate.isBefore(moment(values.startDateOthers))) {
                        setTexto(t("reports.endDateMess"));
                        setalertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            finishDateOthers: endDate,
                        });
                    }
                }
                break;
            default:
                let total =
                    values.addHourFee *
                    parseFloat(values.tothCFO) *
                    (isNaN(endDate.diff(moment(values.startDateCFO)))
                        ? 0
                        : Math.ceil(endDate.diff(moment(values.startDateCFO), "months", true)));

                if (!date || !values.startDateCFO) {
                    set({
                        ...values,
                        finishDateCFO: date ? endDate : date,
                        months: Math.ceil(endDate.diff(moment(values.startDateCFO), "months", true)),
                        projectFee: total,
                        projecTot: total * (1 - values.discount / 100),
                    });
                } else {
                    if (endDate.isBefore(moment(values.startDateCFO))) {
                        setTexto(t("reports.endDateMess"));
                        setalertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            finishDateCFO: endDate,
                            months: Math.ceil(endDate.diff(moment(values.startDateCFO), "months", true)),
                            projectFee: total,
                            projecTot: total * (1 - values.discount / 100),
                        });
                    }
                }
                break;
        }
    };

    return (
        <StyledContainer>
            <LocalizationProvider
                dateAdapter={AdapterMoment}
                adapterLocale={t("language.locale")}
                localeText={{ clearButtonLabel: t("miscellaneous.clear") }}
            >
                <Card elevation={0}>
                    <CardHeader title={t("services.additionals")} sx={{ color: "#ff5968" }} />
                    <Divider />
                    <CardContent sx={{ pb: 5 }}>
                        <Grid container spacing={2} alignItems="center">
                            {values.checkHour && (
                                <>
                                    <Grid item xs={12} md={3}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    name="checkedCFO"
                                                    checked={values.checkedCFO}
                                                    onChange={() => {
                                                        return false;
                                                    }}
                                                    inputProps={{
                                                        "aria-label": "controlled",
                                                    }}
                                                />
                                            }
                                            label="CFO"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            fullWidth
                                            disabled={values.updateBoolean || !values.initDate || !values.endDate}
                                            label={t("miscellaneous.hoursMonth")}
                                            name="tothCFO"
                                            onChange={handleChangeHoursCFO}
                                            value={values.tothCFO}
                                            variant="outlined"
                                            type="number"
                                            inputProps={{
                                                min: 0,
                                                step: 0.5,
                                                onKeyDown: (event) => {
                                                    event.preventDefault();
                                                },
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            error={values.checkedCFO && isError && values.tothCFO === 0}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <DatePicker
                                            componentsProps={{
                                                actionBar: {
                                                    actions: ["clear"],
                                                },
                                            }}
                                            disabled={values.updateBoolean || !values.initDate || !values.endDate}
                                            value={values.startDateCFO}
                                            minDate={moment(values.initDate).startOf("month")}
                                            maxDate={moment(values.endDate).endOf("month")}
                                            onChange={(newValue) => handleChangeStartDateAdd(newValue, 4)}
                                            inputFormat={todos.dateFormat}
                                            renderInput={(params) => (
                                                <TextField
                                                    fullWidth
                                                    required={values.checkedCFO}
                                                    {...params}
                                                    error={values.checkedCFO && isError && !values.startDateCFO}
                                                />
                                            )}
                                            inputProps={{ readOnly: true }}
                                            label={t("reports.initDate")}
                                            views={["year", "month"]}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <DatePicker
                                            componentsProps={{
                                                actionBar: {
                                                    actions: ["clear"],
                                                },
                                            }}
                                            disabled={values.updateBoolean || !values.initDate || !values.endDate}
                                            value={values.finishDateCFO}
                                            minDate={moment(values.initDate).startOf("month")}
                                            maxDate={moment(values.endDate).endOf("month")}
                                            onChange={(newValue) => handleChangeEndDateAdd(newValue, 4)}
                                            inputFormat={todos.dateFormat}
                                            renderInput={(params) => (
                                                <TextField
                                                    fullWidth
                                                    required={values.checkedCFO}
                                                    {...params}
                                                    error={values.checkedCFO && isError && !values.finishDateCFO}
                                                />
                                            )}
                                            inputProps={{ readOnly: true }}
                                            label={t("reports.endDate")}
                                            views={["year", "month"]}
                                        />
                                    </Grid>
                                </>
                            )}
                            <Grid item xs={12} md={3}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="checkedCorp"
                                            checked={values.checkedCorp}
                                            onChange={(value) => handleChangeCheck(value, 0)}
                                            inputProps={{
                                                "aria-label": "controlled",
                                            }}
                                            disabled={values.updateBoolean || !values.initDate || !values.endDate}
                                        />
                                    }
                                    label={t("services.corporateGovernance")}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    fullWidth
                                    disabled={values.updateBoolean || !values.checkedCorp}
                                    label={t("miscellaneous.hoursMonth")}
                                    name="tothCorp"
                                    onChange={handleChange}
                                    value={values.tothCorp}
                                    variant="outlined"
                                    type="number"
                                    inputProps={{
                                        min: 0,
                                        step: 0.5,
                                        onKeyDown: (event) => {
                                            event.preventDefault();
                                        },
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    error={values.checkedCorp && isError && values.tothCorp === 0}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    disabled={values.updateBoolean || !values.checkedCorp}
                                    value={values.startDateCorp}
                                    onChange={(newValue) => handleChangeStartDateAdd(newValue, 0)}
                                    minDate={moment(values.initDate).startOf("month")}
                                    maxDate={moment(values.endDate).endOf("month")}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            required={values.checkedCorp}
                                            {...params}
                                            error={values.checkedCorp && isError && !values.startDateCorp}
                                        />
                                    )}
                                    inputProps={{ readOnly: true }}
                                    label={t("reports.initDate")}
                                    views={["year", "month"]}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    disabled={values.updateBoolean || !values.checkedCorp}
                                    value={values.finishDateCorp}
                                    onChange={(newValue) => handleChangeEndDateAdd(newValue, 0)}
                                    minDate={moment(values.initDate).startOf("month")}
                                    maxDate={moment(values.endDate).endOf("month")}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            required={values.checkedCorp}
                                            {...params}
                                            error={values.checkedCorp && isError && !values.finishDateCorp}
                                        />
                                    )}
                                    inputProps={{ readOnly: true }}
                                    label={t("reports.endDate")}
                                    views={["year", "month"]}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="checkedPre"
                                            checked={values.checkedPre}
                                            onChange={(value) => handleChangeCheck(value, 1)}
                                            inputProps={{
                                                "aria-label": "controlled",
                                            }}
                                            disabled={values.updateBoolean || !values.initDate || !values.endDate}
                                        />
                                    }
                                    label={t("services.investorPresentations")}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    fullWidth
                                    disabled={values.updateBoolean || !values.checkedPre}
                                    label={t("miscellaneous.hoursMonth")}
                                    name="tothPre"
                                    onChange={handleChange}
                                    value={values.tothPre}
                                    variant="outlined"
                                    type="number"
                                    inputProps={{
                                        min: 0,
                                        step: 0.5,
                                        onKeyDown: (event) => {
                                            event.preventDefault();
                                        },
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    error={values.checkedPre && isError && values.tothPre === 0}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    disabled={values.updateBoolean || !values.checkedPre}
                                    value={values.startDatePre}
                                    onChange={(newValue) => handleChangeStartDateAdd(newValue, 1)}
                                    minDate={moment(values.initDate).startOf("month")}
                                    maxDate={moment(values.endDate).endOf("month")}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            required={values.checkedPre}
                                            {...params}
                                            error={values.checkedPre && isError && !values.startDatePre}
                                        />
                                    )}
                                    inputProps={{ readOnly: true }}
                                    label={t("reports.initDate")}
                                    views={["year", "month"]}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    disabled={values.updateBoolean || !values.checkedPre}
                                    value={values.finishDatePre}
                                    onChange={(newValue) => handleChangeEndDateAdd(newValue, 1)}
                                    minDate={moment(values.initDate).startOf("month")}
                                    maxDate={moment(values.endDate).endOf("month")}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            required={values.checkedPre}
                                            {...params}
                                            error={values.checkedPre && isError && !values.finishDatePre}
                                        />
                                    )}
                                    inputProps={{ readOnly: true }}
                                    label={t("reports.endDate")}
                                    views={["year", "month"]}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="checkedRep"
                                            checked={values.checkedRep}
                                            onChange={(value) => handleChangeCheck(value, 2)}
                                            inputProps={{
                                                "aria-label": "controlled",
                                            }}
                                            disabled={values.updateBoolean || !values.initDate || !values.endDate}
                                        />
                                    }
                                    label={t("services.investorReports")}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    fullWidth
                                    disabled={values.updateBoolean || !values.checkedRep}
                                    label={t("miscellaneous.hoursMonth")}
                                    name="tothRep"
                                    onChange={handleChange}
                                    value={values.tothRep}
                                    variant="outlined"
                                    type="number"
                                    inputProps={{
                                        min: 0,
                                        step: 0.5,
                                        onKeyDown: (event) => {
                                            event.preventDefault();
                                        },
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    error={values.checkedRep && isError && values.tothRep === 0}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    disabled={values.updateBoolean || !values.checkedRep}
                                    value={values.startDateRep}
                                    onChange={(newValue) => handleChangeStartDateAdd(newValue, 2)}
                                    minDate={moment(values.initDate).startOf("month")}
                                    maxDate={moment(values.endDate).endOf("month")}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            required={values.checkedRep}
                                            {...params}
                                            error={values.checkedRep && isError && !values.startDateRep}
                                        />
                                    )}
                                    inputProps={{ readOnly: true }}
                                    label={t("reports.initDate")}
                                    views={["year", "month"]}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    disabled={values.updateBoolean || !values.checkedRep}
                                    value={values.finishDateRep}
                                    onChange={(newValue) => handleChangeEndDateAdd(newValue, 2)}
                                    minDate={moment(values.initDate).startOf("month")}
                                    maxDate={moment(values.endDate).endOf("month")}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            required={values.checkedRep}
                                            {...params}
                                            error={values.checkedRep && isError && !values.finishDateRep}
                                        />
                                    )}
                                    inputProps={{ readOnly: true }}
                                    label={t("reports.endDate")}
                                    views={["year", "month"]}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={12} md={3} xl={2} sx={{ paddingLeft: "5px !important" }}>
                                        <Checkbox
                                            name="checkedOthers"
                                            checked={values.checkedOthers}
                                            onChange={(value) => handleChangeCheck(value, 3)}
                                            inputProps={{
                                                "aria-label": "controlled",
                                            }}
                                            disabled={values.updateBoolean || !values.initDate || !values.endDate}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={9} xl={10}>
                                        <TextField
                                            fullWidth
                                            disabled={values.updateBoolean || !values.checkedOthers}
                                            label={t("services.others")}
                                            name="nameOthers"
                                            onChange={handleChange}
                                            value={values.nameOthers}
                                            variant="outlined"
                                            error={values.checkedOthers && isError && !values.nameOthers}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    fullWidth
                                    disabled={values.updateBoolean || !values.checkedOthers}
                                    label={t("miscellaneous.hoursMonth")}
                                    name="tothOthers"
                                    onChange={handleChange}
                                    value={values.tothOthers}
                                    variant="outlined"
                                    type="number"
                                    inputProps={{
                                        min: 0,
                                        step: 0.5,
                                        onKeyDown: (event) => {
                                            event.preventDefault();
                                        },
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    error={values.checkedOthers && isError && values.tothOthers === 0}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    disabled={values.updateBoolean || !values.checkedOthers}
                                    value={values.startDateOthers}
                                    onChange={(newValue) => handleChangeStartDateAdd(newValue, 3)}
                                    minDate={moment(values.initDate).startOf("month")}
                                    maxDate={moment(values.endDate).endOf("month")}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            required={values.checkedOthers}
                                            {...params}
                                            error={values.checkedOthers && isError && !values.startDateOthers}
                                        />
                                    )}
                                    inputProps={{ readOnly: true }}
                                    label={t("reports.initDate")}
                                    views={["year", "month"]}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    disabled={values.updateBoolean || !values.checkedOthers}
                                    value={values.finishDateOthers}
                                    onChange={(newValue) => handleChangeEndDateAdd(newValue, 3)}
                                    minDate={moment(values.initDate).startOf("month")}
                                    maxDate={moment(values.endDate).endOf("month")}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            required={values.checkedOthers}
                                            {...params}
                                            error={values.checkedOthers && isError && !values.finishDateOthers}
                                        />
                                    )}
                                    inputProps={{ readOnly: true }}
                                    label={t("reports.endDate")}
                                    views={["year", "month"]}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}></Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    fullWidth
                                    disabled
                                    label={t("services.hoursTotal")}
                                    value={sumTotal}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormControl fullWidth variant="outlined" disabled>
                                    <InputLabel htmlFor="outlined-adornment-hourlyRate">
                                        {t("services.hourlyRate")}
                                    </InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-hourlyRate"
                                        value={values.addHourFee}
                                        inputComponent={NumberFormatCustom}
                                        startAdornment={
                                            <InputAdornment disableTypography position="start">
                                                $
                                            </InputAdornment>
                                        }
                                        label={t("services.hourlyRate")}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormControl fullWidth variant="outlined" disabled>
                                    <InputLabel htmlFor="outlined-adornment-hourlyRateAdd">
                                        {t("services.hourlyRateAdd")}
                                    </InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-hourlyRateAdd"
                                        value={values.addHourFee * sumTotal}
                                        inputComponent={NumberFormatCustom}
                                        startAdornment={
                                            <InputAdornment disableTypography position="start">
                                                $
                                            </InputAdornment>
                                        }
                                        label={t("services.hourlyRateAdd")}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <Divider />
                    <Box className={classes.buttonContainer}>
                        <Button
                            color="secondary"
                            variant="contained"
                            disableElevation
                            classes={{ root: classes.btnRoot }}
                            onClick={updateProject}
                            disabled={
                                expenseEst === -1 ||
                                (cfoSystem.disabledData === true &&
                                    (cfoSystem.prov === "Xero" || cfoSystem.prov === "Quickbooks Online"))
                            }
                        >
                            {t("miscellaneous.save")}
                        </Button>
                    </Box>
                </Card>
            </LocalizationProvider>
        </StyledContainer>
    );
};
