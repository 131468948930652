/** React imports */
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import moment from "moment";
import axios from "axios";

/** MUI imports */
import { styled } from "@mui/material/styles";
import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    TablePagination,
    Paper,
    Link,
} from "@mui/material";

import SnackBar from "../../internos/SnackBar";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

/** MUI icons */
import CloseIcon from "@mui/icons-material/Close";
import FindInPageIcon from "@mui/icons-material/FindInPage";

import classes from "./History.module.css";

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "rgba(19, 31, 62, 0.80)",
        fontSize: 12,
        maxWidth: 320,
        borderRadius: 6,
        fontWeight: "normal",
    },
});

const CustomDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(3),
    },
    "& .MuiDialogTitle-root": {
        backgroundColor: "#031851",
        fontSize: "18px",
        color: "#fff",
    },
    "& .MuiDialog-paper": {
        borderRadius: 8,
        boxShadow: "10px 10px 15px rgba(3,24,81,0.15)",
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(2),
    },
}));

const CustomTableCell = styled(TableCell)(({ theme }) => ({
    borderBottom: 0,
    paddingRight: 0,
    [`& h2`]: {
        fontSize: 12,
    },
    [`& span`]: {
        fontSize: 12,
    },
}));

export default function History({ open, onClose, report, data, type, setData, page, setPage }) {
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    const handleOnClose = () => {
        onClose();
        setData([]);
    };

    const todos = useSelector((state) => state.todos);

    const rowsPerPage = 10;

    const [show, setShow] = useState(false);
    const [texto, setTexto] = useState("");
    const [alertType, setAlertType] = useState("");

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    /** Component functions */
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setShow(false);
    };

    const downloadFile = async (id, name) => {
        let params = {
            id_history: id,
            file_name: name,
            type: type,
        };
        await axios
            .get(`/getFileAutomationS3`, { params, responseType: "blob" })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", response.config.params.file_name);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch((err) => {
                setTexto(t("miscellaneous.unfoundFile"));
                setAlertType("error");
                setShow(true);
                console.log(err);
            });
    };

    const downloadErrors = async (item) => {
        const params = {
            history_automation_id: item._id,
        };

        await axios
            .get(`/generateErrorListAutomation`, { params, responseType: "blob" })
            .then((response) => {
                const filename =
                    "Plataforma GCA - " +
                    (item.type === "miami" ? "Tealca Miami" : item.type === "tealca" ? "Tealca Madrid" : "Pana Technologies") +
                    " al " +
                    moment.utc(item.creation_date).format("DD-MM-YYYY HHmmss") +
                    ".xlsx";
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", filename);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch((err) => {
                setTexto(t("miscellaneous.unfoundFile"));
                setAlertType("error");
                setShow(true);
                console.log(err);
            });
    };

    return (
        <>
            <CustomDialog
                open={open}
                onClose={handleOnClose}
                aria-labelledby="customized-dialog-title"
                fullWidth={true}
                maxWidth="lg"
            >
                <DialogTitle id="alert-dialog-title">
                    <IconButton
                        aria-label="close"
                        onClick={handleOnClose}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: "white",
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    {t("miscellaneous.uploadHistory")} / <span style={{ color: "#ff5968" }}>{report}</span>
                </DialogTitle>
                <DialogContent dividers>
                    <TableContainer component={Paper} sx={{ boxShadow: 0 }}>
                        <Table aria-label="simple table" sx={{ minWidth: 900 }}>
                            <TableHead>
                                <TableRow sx={{ "& th": { borderBottom: "0.5px solid black", py: 2 } }}>
                                    <TableCell sx={{ p: 0 }}>
                                        <Typography variant="h2" sx={{ fontWeight: 700, fontSize: 16 }}>
                                            {t("miscellaneous.filenameHistory")}
                                        </Typography>
                                    </TableCell>
                                    <TableCell sx={{ p: 0 }}>
                                        <Typography variant="h2" sx={{ fontWeight: 700, fontSize: 16 }}>
                                            {t("miscellaneous.uploadDate")}
                                        </Typography>
                                    </TableCell>
                                    <TableCell sx={{ p: 0 }}>
                                        <Typography variant="h2" sx={{ fontWeight: 700, fontSize: 16 }}>
                                            {t("services.startDate")}
                                        </Typography>
                                    </TableCell>
                                    <TableCell sx={{ p: 0 }}>
                                        <Typography variant="h2" sx={{ fontWeight: 700, fontSize: 16 }}>
                                            {t("bankingDashboard.endDate")}
                                        </Typography>
                                    </TableCell>
                                    <TableCell sx={{ p: 0 }}>
                                        <Typography variant="h2" sx={{ fontWeight: 700, fontSize: 16 }}>
                                            {t("proposals.user")}
                                        </Typography>
                                    </TableCell>
                                    <TableCell sx={{ p: 0 }}>
                                        <Typography variant="h2" sx={{ fontWeight: 700, fontSize: 16 }}>
                                            {t("recurrent.status")}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody
                                sx={{
                                    "&:before": {
                                        display: "none",
                                    },
                                }}
                            >
                                {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, i) => (
                                    <TableRow key={i} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                        <CustomTableCell
                                            align="left"
                                            sx={{
                                                px: 0,
                                                py: 1.5,
                                                ...(i !== data.length - 1 && {
                                                    borderBottom: "0.5px solid black",
                                                }),
                                            }}
                                        >
                                            <Link
                                                onClick={() => downloadFile(item._id, item.uploaded_file)}
                                                target="_blank"
                                                sx={{ cursor: "pointer" }}
                                            >
                                                {item.uploaded_file}
                                            </Link>
                                        </CustomTableCell>
                                        <CustomTableCell
                                            sx={{
                                                px: 0,
                                                py: 1.5,
                                                ...(i !== data.length - 1 && {
                                                    borderBottom: "0.5px solid black",
                                                }),
                                            }}
                                        >
                                            <Typography>
                                                {moment(item.creation_date).format(`${todos.dateFormat} HH:mm`)}
                                            </Typography>
                                        </CustomTableCell>
                                        <CustomTableCell
                                            sx={{
                                                px: 0,
                                                py: 1.5,
                                                ...(i !== data.length - 1 && {
                                                    borderBottom: "0.5px solid black",
                                                }),
                                            }}
                                        >
                                            <Typography>
                                                {item.initial_date
                                                    ? moment(item.initial_date, "YYYY-MM-DD").format(todos.dateFormat)
                                                    : ""}
                                            </Typography>
                                        </CustomTableCell>
                                        <CustomTableCell
                                            sx={{
                                                px: 0,
                                                py: 1.5,
                                                ...(i !== data.length - 1 && {
                                                    borderBottom: "0.5px solid black",
                                                }),
                                            }}
                                        >
                                            <Typography>
                                                {item.end_date
                                                    ? moment(item.end_date, "YYYY-MM-DD").format(todos.dateFormat)
                                                    : ""}
                                            </Typography>
                                        </CustomTableCell>
                                        <CustomTableCell
                                            sx={{
                                                px: 0,
                                                py: 1.5,
                                                ...(i !== data.length - 1 && {
                                                    borderBottom: "0.5px solid black",
                                                }),
                                            }}
                                        >
                                            <Typography>{item.token_set_id.user_name}</Typography>
                                        </CustomTableCell>
                                        <CustomTableCell
                                            sx={{
                                                px: 0,
                                                py: 1.5,
                                                ...(i !== data.length - 1 && {
                                                    borderBottom: "0.5px solid black",
                                                }),
                                            }}
                                        >
                                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                                {item.id_status_file === 1 ? (
                                                    <Typography sx={{ color: "green", fontWeight: 600 }}>
                                                        Exitoso
                                                    </Typography>
                                                ) : item.id_status_file === 0 ? (
                                                    <LightTooltip title={item.error_msg} placement="right">
                                                        <Typography className={classes.failedEstatus}>
                                                            Fallido
                                                        </Typography>
                                                    </LightTooltip>
                                                ) : (
                                                    <>
                                                        <LightTooltip
                                                            title={`${item.failed_invoices} ${
                                                                item.failed_invoices === 1
                                                                    ? t("principal.errorInvXeroSin")
                                                                    : t("principal.errorInvXero")
                                                            }`}
                                                        >
                                                            <Typography className={classes.parcialEstatus}>
                                                                Parcial
                                                            </Typography>
                                                        </LightTooltip>
                                                        <LightTooltip title={t("reports.downloadErrorList")}>
                                                            <IconButton
                                                                sx={{ color: "#2F3190", ml: 1 }}
                                                                aria-label="download"
                                                                fontSize="small"
                                                                onClick={() => downloadErrors(item)}
                                                            >
                                                                <FindInPageIcon fontSize="small" />
                                                            </IconButton>
                                                        </LightTooltip>
                                                    </>
                                                )}
                                            </Box>
                                        </CustomTableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        shape="rounded"
                        rowsPerPageOptions={[10]}
                        component="div"
                        count={data.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${t("miscellaneous.of")} ${count}`}
                    />
                </DialogContent>
            </CustomDialog>
            <SnackBar type={alertType} show={show} message={texto} onClose={handleClose} />
        </>
    );
}
