/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

/** MUI icons */
import CircleIcon from "@mui/icons-material/Circle";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

/** MUI imports */
import { styled } from "@mui/material/styles";
import {
    Box,
    Breadcrumbs,
    Card,
    CardContent,
    Grid,
    Hidden,
    Stack,
    Tabs,
    Tab,
    Typography,
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

/** Components imports */
import Backdrop from "../../Backdrop";
import ClientsReport from "./ClientsReport";
import Validations from "./Validations";
import GlobalReport from "./GlobalReport";
import ValidationsSkeletons from "./ValidationsSkeletons";

const PREFIX = "RecuBookkeeper";

const classes = {
    btnRoot: `${PREFIX}-btnRoot`,
    cardContentRoot: `${PREFIX}-cardContentRoot`,
    formRounded: `${PREFIX}-formRounded`,
    statusRow: `${PREFIX}-statusRow`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.btnRoot}`]: {
        border: "1px solid #ff5968",
        borderRadius: 40,
        "&:hover": {
            backgroundColor: "#2f3190",
            border: "1px solid #2f3190",
        },
    },

    [`& .${classes.cardContentRoot}`]: {
        "&:last-child": {
            paddingBottom: "18px",
        },
        padding: 30,
    },

    [`& .${classes.statusRow}`]: {
        "& svg, span": {
            display: "inline-block",
            verticalAlign: "middle",
        },
        "& span": {
            paddingLeft: 10,
        },
    },

    [`& .${classes.formRounded}`]: {
        backgroundColor: "#FFFFFF !important",
        borderRadius: 40,
        verticalAlign: "middle !important",
        "&:focus-within": {
            backgroundColor: "#FFFFFF",
            borderRadius: 40,
        },
        "& .MuiOutlinedInput-notchedOutline": {
            border: "2px solid #031851",
            borderRadius: 40,
        },
    },
}));

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "rgba(19, 31, 62, 0.80)",
        fontSize: 12,
        maxWidth: 320,
        borderRadius: 6,
        fontWeight: "normal",
    },
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 48 * 4.5 + 8,
            boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
        },
        sx: {
            borderRadius: 2,
            mt: 0.7,
        },
    },
    variant: "menu",
    getContentAnchorEl: null,
};

const CustomTab = styled(Tab)(({ theme }) => ({
    padding: 20,
    marginLeft: 8,
    borderRadius: "20px 20px 0 0",
    backgroundColor: "#E9EAE8",
    color: theme.palette.primary.main,
    fontWeight: 700,
    "&.Mui-selected": {
        borderTop: "2px solid #ff5968",
        borderRight: "2px solid #ff5968",
        borderLeft: "2px solid #ff5968",
        backgroundColor: "#fff !important",
    },
}));

const RecuBookkeeper = () => {
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    const markets = [t("miscellaneous.all"), "Global", "LATAM", "USA", "Venezuela"];

    /** Component states */
    const [valueTab, setValueTab] = useState(0);
    const [showBackdrop, setBackDrop] = useState(false);
    const [loading, showLoading] = useState(false);
    const [franqData, setFranqData] = useState([]);
    const [groupData, setGroupData] = useState([]);
    const [typeData, setTypeData] = useState([]);

    /** Component variables */
    const breadcrumbs = [
        <Typography
            key="1"
            variant="h1"
            color="primary"
            sx={{
                fontSize: 24,
                fontWeight: 700,
            }}
        >
            {t("principal.reports")}
        </Typography>,
        <Typography
            key="2"
            variant="h1"
            color="primary"
            sx={{
                fontSize: 24,
                fontWeight: 700,
            }}
        >
            {t("principal.recurring")}
        </Typography>,
        <Typography
            key="3"
            variant="h1"
            color="secondary"
            sx={{
                fontSize: 24,
                fontWeight: 700,
            }}
        >
            Bookkeeper
        </Typography>,
    ];

    /** Component functions */
    const handleChangeTab = async (event, newValue) => {
        setBackDrop(true);
        setValueTab(newValue);
        setBackDrop(false);
    };

    useEffect(() => {
        (async () => {
            showLoading(true);

            await fetch(`/getClientTypeFilter`, {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
                .then((res) => res.json())
                .then((data) => {
                    setTypeData(data);
                });

            await fetch(`/getClientFranchiseFilter`, {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
                .then((res) => res.json())
                .then((data) => {
                    setFranqData(data);
                });

            await fetch(`/getClientGroupsFilter`, {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
                .then((res) => res.json())
                .then((data) => {
                    setGroupData(data);
                });

            showLoading(false);
        })();
    }, [t]);

    return (
        <StyledContainer component="main" sx={{ flexGrow: 1, p: 4, width: "95%", pt: 10 }}>
            <Stack sx={{ pb: 4 }}>
                <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small" sx={{ color: "#031851" }} />}
                    aria-label="breadcrumb"
                >
                    {breadcrumbs}
                </Breadcrumbs>
            </Stack>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <Card
                        elevation={0}
                        sx={{
                            borderRadius: 8,
                            boxShadow: "10px 10px 15px rgba(3,24,81,0.15)",
                        }}
                    >
                        <CardContent classes={{ root: classes.cardContentRoot }}>
                            <Box sx={{ width: "100%" }}>
                                <Box sx={{ borderBottom: 2, borderColor: "#031851" }}>
                                    <Grid container alignItems="center" justifyContent="space-between">
                                        <Grid item xs={12} lg={5}>
                                            <Tabs
                                                value={valueTab}
                                                onChange={handleChangeTab}
                                                variant="scrollable"
                                                scrollButtons="auto"
                                                aria-label="bookkeeper tabs"
                                                TabIndicatorProps={{
                                                    sx: {
                                                        display: "none",
                                                    },
                                                }}
                                            >
                                                <CustomTab label={t("miscellaneous.validate")} {...a11yProps(0)} />
                                                <CustomTab label={t("miscellaneous.report")} {...a11yProps(1)} />
                                                <CustomTab label={t("recurrent.follow")} {...a11yProps(2)} />
                                            </Tabs>
                                        </Grid>
                                        <Hidden only={["sm", "xs", "md"]}>
                                            {valueTab !== 0 ? (
                                                <Grid item xs={12} lg={7}>
                                                    <Grid container alignItems="center" justifyContent="space-between">
                                                        <LightTooltip
                                                            title={t("clientsTable.awaitingRequirementsTool")}
                                                        >
                                                            <Grid item className={classes.statusRow}>
                                                                <CircleIcon
                                                                    fontSize="small"
                                                                    sx={{ color: "#F1919E" }}
                                                                />
                                                                <Typography component="span">
                                                                    {t("clientsTable.awaitingRequirements")}
                                                                </Typography>
                                                            </Grid>
                                                        </LightTooltip>
                                                        <LightTooltip title={t("recurrent.toValidTool")}>
                                                            <Grid item className={classes.statusRow}>
                                                                <CircleIcon
                                                                    fontSize="small"
                                                                    sx={{ color: "#FFB23E" }}
                                                                />
                                                                <Typography component="span">
                                                                    {t("recurrent.toValid")}
                                                                </Typography>
                                                            </Grid>
                                                        </LightTooltip>
                                                        <LightTooltip title={t("recurrent.validatedTool")}>
                                                            <Grid item className={classes.statusRow}>
                                                                <CircleIcon
                                                                    fontSize="small"
                                                                    sx={{ color: "#9AD2DD" }}
                                                                />
                                                                <Typography component="span">
                                                                    {t("recurrent.validated")}
                                                                </Typography>
                                                            </Grid>
                                                        </LightTooltip>
                                                        <LightTooltip title={t("recurrent.partReportTool")}>
                                                            <Grid item className={classes.statusRow}>
                                                                <CircleIcon
                                                                    fontSize="small"
                                                                    sx={{ color: "#8BC985" }}
                                                                />
                                                                <Typography component="span">
                                                                    {t("recurrent.partReport")}
                                                                </Typography>
                                                            </Grid>
                                                        </LightTooltip>
                                                        <LightTooltip title={t("miscellaneous.closedTool")}>
                                                            <Grid item className={classes.statusRow}>
                                                                <CircleIcon
                                                                    fontSize="small"
                                                                    sx={{ color: "#318A16" }}
                                                                />
                                                                <Typography component="span">
                                                                    {t("miscellaneous.closed")}
                                                                </Typography>
                                                            </Grid>
                                                        </LightTooltip>
                                                    </Grid>
                                                </Grid>
                                            ) : (
                                                <Grid item xs={12} lg={7} xl={4}>
                                                    <Grid container alignItems="center" justifyContent="space-between">
                                                        <LightTooltip
                                                            title={t("clientsTable.awaitingRequirementsTool")}
                                                        >
                                                            <Grid item className={classes.statusRow}>
                                                                <CircleIcon
                                                                    fontSize="small"
                                                                    sx={{ color: "#F1919E" }}
                                                                />
                                                                <Typography component="span">
                                                                    {t("clientsTable.awaitingRequirements")}
                                                                </Typography>
                                                            </Grid>
                                                        </LightTooltip>
                                                        <LightTooltip title={t("recurrent.toValidTool")}>
                                                            <Grid item className={classes.statusRow}>
                                                                <CircleIcon
                                                                    fontSize="small"
                                                                    sx={{ color: "#FFB23E" }}
                                                                />
                                                                <Typography component="span">
                                                                    {t("recurrent.toValid")}
                                                                </Typography>
                                                            </Grid>
                                                        </LightTooltip>
                                                        <LightTooltip title={t("recurrent.validatedTool")}>
                                                            <Grid item className={classes.statusRow}>
                                                                <CircleIcon
                                                                    fontSize="small"
                                                                    sx={{ color: "#9AD2DD" }}
                                                                />
                                                                <Typography component="span">
                                                                    {t("recurrent.validated")}
                                                                </Typography>
                                                            </Grid>
                                                        </LightTooltip>
                                                    </Grid>
                                                </Grid>
                                            )}
                                        </Hidden>
                                    </Grid>
                                </Box>
                                <TabPanel value={valueTab} index={0}>
                                    {franqData.length > 0 && groupData.length > 0 && typeData.length > 0 && (
                                        <Validations
                                            serviceType="Bookkeeper"
                                            loading={loading}
                                            showLoading={showLoading}
                                            franqData={franqData}
                                            groupData={groupData}
                                            typeData={typeData}
                                            MenuProps={MenuProps}
                                            markets={markets}
                                        />
                                    )}
                                    {(franqData.length === 0 || groupData.length === 0 || typeData.length === 0) && (
                                        <ValidationsSkeletons serviceType="Bookkeeper" />
                                    )}
                                </TabPanel>
                                <TabPanel value={valueTab} index={1}>
                                    {franqData.length > 0 && groupData.length > 0 && typeData.length > 0 && (
                                        <ClientsReport
                                            serviceType="Bookkeeper"
                                            franqData={franqData}
                                            groupData={groupData}
                                            setFranqData={setFranqData}
                                            typeData={typeData}
                                            loading={loading}
                                            MenuProps={MenuProps}
                                            markets={markets}
                                        />
                                    )}
                                </TabPanel>
                                <TabPanel value={valueTab} index={2}>
                                    {franqData.length > 0 && groupData.length > 0 && typeData.length > 0 && (
                                        <GlobalReport
                                            serviceType="Bookkeeper"
                                            franqData={franqData}
                                            groupData={groupData}
                                            setFranqData={setFranqData}
                                            typeData={typeData}
                                            loading={loading}
                                            MenuProps={MenuProps}
                                            markets={markets}
                                        />
                                    )}
                                </TabPanel>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Backdrop open={showBackdrop} />
        </StyledContainer>
    );
};

export default RecuBookkeeper;
