// React
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";
import moment from "moment";

// MUI icons
import EditIcon from "@mui/icons-material/Edit";
import EditOffIcon from "@mui/icons-material/EditOff";
import InfoIcon from "@mui/icons-material/Info";

import {
    Alert,
    Box,
    Card,
    CardContent,
    CardHeader,
    Divider,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    TextField,
    FormControlLabel,
    Switch,
    Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import GridUI from "@mui/material/Grid";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

/** Component imports */
import { UpdateAdd } from "./";
import DialogAlerts from "../../../DialogAlerts";

const PREFIX = "CatchUp";

const classes = {
    btnRoot: `${PREFIX}-btnRoot`,
    buttonContainer: `${PREFIX}-buttonContainer`,
    tableStriped: `${PREFIX}-tableStriped`,
    tableHeader: `${PREFIX}-tableHeader`,
    tableBody: `${PREFIX}-tableBody`,
    tableBody2: `${PREFIX}-tableBody2`,
    pager: `${PREFIX}-pager`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.btnRoot}`]: {
        border: "1px solid #ff5968",
        borderRadius: 40,
        "&:hover": {
            backgroundColor: "#2f3190",
            border: "1px solid #2f3190",
        },
    },
    [`& .${classes.buttonContainer}`]: {
        display: "flex",
        justifyContent: "flex-end",
        padding: 15,
    },

    [`& .${classes.tableStriped}`]: {
        "& tbody tr:nth-of-type(odd)": {
            backgroundColor: "rgba(47,49,144,0.1)",
        },
    },
    [`& .${classes.tableHeader}`]: {
        fontSize: "15px",
        fontWeight: 600,
        color: "#031851",
        borderBottom: "2px solid #031851",
    },
    [`& .${classes.tableBody}`]: {
        fontSize: "14px",
        fontWeight: 400,
        color: "#031851",
        padding: "5px",
    },
    [`& .${classes.tableBody2}`]: {
        padding: "5px",
        textAlign: "right",
    },
    [`& .${classes.pager}`]: {
        "& .Pagination-button": {
            paddingTop: 0,
            paddingBottom: 0,
            color: "#031851",
            fontWeight: 600,
        },
        "& .Pagination-activeButton": {
            backgroundColor: "rgba(47,49,144,0.3)",
        },
    },
}));

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "rgba(19, 31, 62, 0.80)",
        fontSize: 12,
        maxWidth: 400,
        borderRadius: 6,
        fontWeight: "normal",
    },
});

const NumberFormatCustom = (props) => {
    const todos = useSelector((state) => state.todos);

    const { inputRef, value, onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            value={value}
            getInputRef={inputRef}
            onValueChange={({ value: v }) => onChange({ target: { value: v } })}
            thousandSeparator={todos.amountFormat === "de-DE" ? "." : ","}
            decimalSeparator={todos.amountFormat === "de-DE" ? "," : "."}
            fixedDecimalScale={true}
            decimalScale={2}
            valueIsNumericString
        />
    );
};

const defaultState = {
    projectCompanyId: undefined,
    updateBoolean: false,
    baseFee: 0,
    months: 0,
    projectFee: 0,
    discount: 30,
    discountOG: 30,
    projecTot: 0,
    discountAdd: 30,
    discountAddOG: 30,
    // Responsabilidad del servicio
    initDate: null,
    endDate: null,
    estDeliDate: null,
    deliDate: null,
    // Adicionales
    additionalsOnly: false,
    checkedBook: false,
    tothBook: 0,
    startDateBook: null,
    finishDateBook: null,
    checkedChecks: false,
    tothChecks: 0,
    startDateChecks: null,
    finishDateChecks: null,
    checkedAR: false,
    tothAR: 0,
    startDateAR: null,
    finishDateAR: null,
    checkedAP: false,
    tothAP: 0,
    startDateAP: null,
    finishDateAP: null,
    checkedAccrual: false,
    tothAccrual: 0,
    startDateAccrual: null,
    finishDateAccrual: null,
    checkedClass: false,
    tothClass: 0,
    startDateClass: null,
    finishDateClass: null,
    checkedMulti: false,
    tothMulti: 0,
    startDateMulti: null,
    finishDateMulti: null,
    checkedPer: false,
    tothPer: 0,
    startDatePer: null,
    finishDatePer: null,
    // Otros
    checkedOthers: false,
    nameOthers: "",
    tothOthers: 0,
    startDateOthers: null,
    finishDateOthers: null,
    // Total
    addHourFee: 0,
};

export const CatchUp = (props) => {
    // Properties
    const {
        actives,
        bookkeeperSystem,
        companyID,
        data,
        expenseEst,
        reloadProjects,
        serviceID,
        setalertType,
        setBackDrop,
        setShow,
        setTexto,
        handleCloseDialog,
        infoAdd,
        checkRemind,
        open,
        bodyMess,
        showConfirm,
    } = props;

    /** Internationalization i18n */
    const [t] = useTranslation("global");

    /** React redux */
    const todos = useSelector((state) => state.todos);
    const dispatch = useDispatch();

    /** Component states */
    const [valuesCatchUp, setCatchUp] = useState(defaultState);
    const [editMode, setEditMode] = useState(true);
    const [editMode2, setEditMode2] = useState(true);
    const [isError, setIsError] = useState(false);
    // const [columnDefs, setColumnsDefs] = useState([]);
    // const [dataTable, setDataTable] = useState([]);

    /** Component functions */
    useEffect(() => {
        let bookAdditionalData = {};
        let checksAdditionalData = {};
        let arAdditionalData = {};
        let apAdditionalData = {};
        let accrualAdditionalData = {};
        let classesAdditionalData = {};
        let multiAdditionalData = {};
        let perAdditionalData = {};
        let othersAdditionalData = {};
        const proData = data.projects_array && data.projects_array[0];

        if (proData) {
            if (proData.additionals && proData.additionals.length !== 0) {
                proData.additionals.forEach((element) => {
                    if (element.additional_name === "Bookkeeper") {
                        bookAdditionalData = {
                            since_date: element.since_date,
                            end_date: element.end_date,
                            hours: element.hours,
                        };
                    }
                    if (element.additional_name === "Checks") {
                        checksAdditionalData = {
                            since_date: element.since_date,
                            end_date: element.end_date,
                            hours: element.hours,
                        };
                    }
                    if (element.additional_name === "A/R") {
                        arAdditionalData = {
                            since_date: element.since_date,
                            end_date: element.end_date,
                            hours: element.hours,
                        };
                    }
                    if (element.additional_name === "A/P") {
                        apAdditionalData = {
                            since_date: element.since_date,
                            end_date: element.end_date,
                            hours: element.hours,
                        };
                    }
                    if (element.additional_name === "Accrual") {
                        accrualAdditionalData = {
                            since_date: element.since_date,
                            end_date: element.end_date,
                            hours: element.hours,
                            update_hours: element.update_hours,
                        };
                    }
                    if (element.additional_name === "Classes/Categories accounting") {
                        classesAdditionalData = {
                            since_date: element.since_date,
                            end_date: element.end_date,
                            hours: element.hours,
                        };
                    }
                    if (element.additional_name === "Multi-entity accounting") {
                        multiAdditionalData = {
                            since_date: element.since_date,
                            end_date: element.end_date,
                            hours: element.hours,
                        };
                    }
                    if (element.additional_name === "Personalized reporting") {
                        perAdditionalData = {
                            since_date: element.since_date,
                            end_date: element.end_date,
                            hours: element.hours,
                        };
                    }
                    if (element.additional_name === "Others") {
                        othersAdditionalData = {
                            since_date: element.since_date,
                            end_date: element.end_date,
                            hours: element.hours,
                            other_name: element.other_name,
                        };
                    }
                });
            }

            let meses = 0;
            let bookMonths = 0;

            if (proData.since_date) {
                meses = Math.ceil(moment(proData.end_date).diff(moment(proData.since_date), "months", true));
            }
            if (proData.additionals_only) {
                bookMonths = Math.ceil(
                    moment(bookAdditionalData.end_date).diff(moment(bookAdditionalData.since_date), "months", true)
                );
            }

            setCatchUp({
                projectCompanyId: proData.project_company_id,
                updateBoolean: data.update_boolean ?? false,
                baseFee: proData.additionals_only
                    ? data.tariff_additionals * parseFloat(bookAdditionalData.hours)
                    : Math.ceil(proData.expense_scale / 10000) * proData.fee,
                months: proData.additionals_only ? bookMonths : meses,
                projectFee: proData.additionals_only
                    ? bookMonths * (bookAdditionalData.hours ?? 0) * data.tariff_additionals
                    : Math.ceil(proData.expense_scale / 10000) * proData.fee * meses,
                discount: proData.discount ?? 30,
                discountOG: proData.discount ?? 30,
                projecTot: proData.additionals_only
                    ? bookMonths *
                    (bookAdditionalData.hours ?? 0) *
                    data.tariff_additionals *
                    (1 - (proData.discount ?? 30) / 100)
                    : Math.ceil(proData.expense_scale / 10000) *
                    proData.fee *
                    meses *
                    (1 - (proData.discount ?? 30) / 100),
                discountAdd: proData.additional_discount ?? 30,
                discountAddOG: proData.additional_discount ?? 30,
                // Responsabilidad del servicio
                initDate: proData.since_date ? moment(proData.since_date).utc() : null,
                endDate: proData.end_date ? moment(proData.end_date).utc() : null,
                estDeliDate: proData.client_commitment_date ? moment(proData.client_commitment_date).utc() : null,
                deliDate: proData.client_delivery_date ? moment(proData.client_delivery_date).utc() : null,
                // Adicionales
                additionalsOnly: proData.additionals_only,
                checkedBook: Object.keys(bookAdditionalData).length !== 0,
                tothBook: bookAdditionalData.hours ?? 0,
                startDateBook: !bookAdditionalData.since_date ? null : moment(bookAdditionalData.since_date).utc(),
                finishDateBook: !bookAdditionalData.end_date ? null : moment(bookAdditionalData.end_date).utc(),
                checkedChecks: Object.keys(checksAdditionalData).length !== 0,
                checkedChecksOG: Object.keys(checksAdditionalData).length !== 0,
                tothChecks: checksAdditionalData.hours ?? 0,
                startDateChecks: !checksAdditionalData.since_date
                    ? null
                    : moment(checksAdditionalData.since_date).utc(),
                finishDateChecks: !checksAdditionalData.end_date ? null : moment(checksAdditionalData.end_date).utc(),
                checkedAR: Object.keys(arAdditionalData).length !== 0,
                checkedAROG: Object.keys(arAdditionalData).length !== 0,
                tothAR: arAdditionalData.hours ?? 0,
                startDateAR: !arAdditionalData.since_date ? null : moment(arAdditionalData.since_date).utc(),
                finishDateAR: !arAdditionalData.end_date ? null : moment(arAdditionalData.end_date).utc(),
                checkedAP: Object.keys(apAdditionalData).length !== 0,
                checkedAPOG: Object.keys(apAdditionalData).length !== 0,
                tothAP: apAdditionalData.hours ?? 0,
                startDateAP: !apAdditionalData.since_date ? null : moment(apAdditionalData.since_date).utc(),
                finishDateAP: !apAdditionalData.end_date ? null : moment(apAdditionalData.end_date).utc(),
                checkedAccrual: Object.keys(accrualAdditionalData).length !== 0,
                checkedAccrualOG: Object.keys(accrualAdditionalData).length !== 0,
                tothAccrual: accrualAdditionalData.hours ?? 0,
                startDateAccrual: !accrualAdditionalData.since_date
                    ? null
                    : moment(accrualAdditionalData.since_date).utc(),
                finishDateAccrual: !accrualAdditionalData.end_date
                    ? null
                    : moment(accrualAdditionalData.end_date).utc(),
                checkedClass: Object.keys(classesAdditionalData).length !== 0,
                checkedClassOG: Object.keys(classesAdditionalData).length !== 0,
                tothClass: classesAdditionalData.hours ?? 0,
                startDateClass: !classesAdditionalData.since_date
                    ? null
                    : moment(classesAdditionalData.since_date).utc(),
                finishDateClass: !classesAdditionalData.end_date ? null : moment(classesAdditionalData.end_date).utc(),
                checkedMulti: Object.keys(multiAdditionalData).length !== 0,
                checkedMultiOG: Object.keys(multiAdditionalData).length !== 0,
                tothMulti: multiAdditionalData.hours ?? 0,
                startDateMulti: !multiAdditionalData.since_date ? null : moment(multiAdditionalData.since_date).utc(),
                finishDateMulti: !multiAdditionalData.end_date ? null : moment(multiAdditionalData.end_date).utc(),
                checkedPer: Object.keys(perAdditionalData).length !== 0,
                checkedPerOG: Object.keys(perAdditionalData).length !== 0,
                tothPer: perAdditionalData.hours ?? 0,
                startDatePer: !perAdditionalData.since_date ? null : moment(perAdditionalData.since_date).utc(),
                finishDatePer: !perAdditionalData.end_date ? null : moment(perAdditionalData.end_date).utc(),
                // Otros
                checkedOthers: Object.keys(othersAdditionalData).length !== 0,
                checkedOthersOG: Object.keys(othersAdditionalData).length !== 0,
                nameOthers: othersAdditionalData.other_name ?? "",
                tothOthers: othersAdditionalData.hours ?? 0,
                startDateOthers: !othersAdditionalData.since_date
                    ? null
                    : moment(othersAdditionalData.since_date).utc(),
                finishDateOthers: !othersAdditionalData.end_date ? null : moment(othersAdditionalData.end_date).utc(),
                // Total
                addHourFee: data.tariff_additionals,
            });
        } else {
            setCatchUp({
                ...valuesCatchUp,
                baseFee: Math.ceil(data.expense_scale / 10000) * data.fee,
                updateBoolean: false,
                addHourFee: data.tariff_additionals,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const removeCheck = () => {
        setCatchUp({
            ...valuesCatchUp,
            [infoAdd.check]: false,
            [infoAdd.toth]: 0,
            nameOthers: infoAdd.nameOthers ? "" : valuesCatchUp.nameOthers,
            [infoAdd.startDate]: null,
            [infoAdd.finishDate]: null,
        });
        if (checkRemind) {
            dispatch({
                type: "addRemind",
                item: true,
            });
        }
        handleCloseDialog();
    };

    const getSumTotal = () => {
        let sumTotal =
            parseFloat(valuesCatchUp.tothChecks) *
            (isNaN(moment(valuesCatchUp.finishDateChecks).diff(moment(valuesCatchUp.startDateChecks)))
                ? 0
                : Math.ceil(
                    moment(valuesCatchUp.finishDateChecks).diff(
                        moment(valuesCatchUp.startDateChecks),
                        "months",
                        true
                    )
                )) +
            parseFloat(valuesCatchUp.tothAR) *
            (isNaN(moment(valuesCatchUp.finishDateAR).diff(moment(valuesCatchUp.startDateAR)))
                ? 0
                : Math.ceil(
                    moment(valuesCatchUp.finishDateAR).diff(moment(valuesCatchUp.startDateAR), "months", true)
                )) +
            parseFloat(valuesCatchUp.tothAP) *
            (isNaN(moment(valuesCatchUp.finishDateAP).diff(moment(valuesCatchUp.startDateAP)))
                ? 0
                : Math.ceil(
                    moment(valuesCatchUp.finishDateAP).diff(moment(valuesCatchUp.startDateAP), "months", true)
                )) +
            parseFloat(valuesCatchUp.tothAccrual) *
            (isNaN(moment(valuesCatchUp.finishDateAccrual).diff(moment(valuesCatchUp.startDateAccrual)))
                ? 0
                : Math.ceil(
                    moment(valuesCatchUp.finishDateAccrual).diff(
                        moment(valuesCatchUp.startDateAccrual),
                        "months",
                        true
                    )
                )) +
            parseFloat(valuesCatchUp.tothClass) *
            (isNaN(moment(valuesCatchUp.finishDateClass).diff(moment(valuesCatchUp.startDateClass)))
                ? 0
                : Math.ceil(
                    moment(valuesCatchUp.finishDateClass).diff(
                        moment(valuesCatchUp.startDateClass),
                        "months",
                        true
                    )
                )) +
            parseFloat(valuesCatchUp.tothMulti) *
            (isNaN(moment(valuesCatchUp.finishDateMulti).diff(moment(valuesCatchUp.startDateMulti)))
                ? 0
                : Math.ceil(
                    moment(valuesCatchUp.finishDateMulti).diff(
                        moment(valuesCatchUp.startDateMulti),
                        "months",
                        true
                    )
                )) +
            parseFloat(valuesCatchUp.tothPer) *
            (isNaN(moment(valuesCatchUp.finishDatePer).diff(moment(valuesCatchUp.startDatePer)))
                ? 0
                : Math.ceil(
                    moment(valuesCatchUp.finishDatePer).diff(moment(valuesCatchUp.startDatePer), "months", true)
                )) +
            parseFloat(valuesCatchUp.tothOthers) *
            (isNaN(moment(valuesCatchUp.finishDateOthers).diff(moment(valuesCatchUp.startDateOthers)))
                ? 0
                : Math.ceil(
                    moment(valuesCatchUp.finishDateOthers).diff(
                        moment(valuesCatchUp.startDateOthers),
                        "months",
                        true
                    )
                ));

        return sumTotal;
    };

    const handleChangeDiscount = (event) => {
        let discountAux = 0;

        if (event.target.name === "discount") {
            discountAux =
                event.target.value >= 0 && event.target.value <= 30 ? event.target.value : valuesCatchUp.discount;

            setCatchUp({
                ...valuesCatchUp,
                discount: discountAux,
                projecTot: valuesCatchUp.projectFee * (1 - discountAux / 100),
            });
        } else {
            discountAux =
                event.target.value >= 0 && event.target.value <= 30 ? event.target.value : valuesCatchUp.discountAdd;

            setCatchUp({
                ...valuesCatchUp,
                discountAdd: discountAux,
            });
        }
    };

    const updateProject = async () => {
        setBackDrop(true);

        if (valuesCatchUp.initDate && valuesCatchUp.endDate && valuesCatchUp.estDeliDate) {
            let additionalsF = [];

            if (valuesCatchUp.checkedBook) {
                if (valuesCatchUp.startDateBook && valuesCatchUp.finishDateBook) {
                    if (valuesCatchUp.tothBook > 0) {
                        additionalsF.push({
                            additional_name: "Bookkeeper",
                            hours: valuesCatchUp.tothBook,
                            since_date: moment(valuesCatchUp.startDateBook).startOf("day").format(),
                            end_date: valuesCatchUp.finishDateBook
                                ? moment(valuesCatchUp.finishDateBook).startOf("day").format()
                                : null,
                        });
                    } else {
                        setTexto(t("services.hoursValidationError"));
                        setalertType("warning");
                        setShow(true);
                        setBackDrop(false);
                        setIsError(true);
                        return;
                    }
                } else {
                    setTexto(t("miscellaneous.requiredData"));
                    setalertType("warning");
                    setShow(true);
                    setBackDrop(false);
                    setIsError(true);
                    return;
                }
            }
            if (valuesCatchUp.checkedChecks) {
                if (valuesCatchUp.startDateChecks && valuesCatchUp.finishDateChecks) {
                    if (valuesCatchUp.tothChecks > 0) {
                        additionalsF.push({
                            additional_name: "Checks",
                            hours: parseFloat(valuesCatchUp.tothChecks),
                            since_date: moment(valuesCatchUp.startDateChecks).startOf("day").format(),
                            end_date: moment(valuesCatchUp.finishDateChecks).startOf("day").format(),
                        });
                    } else {
                        setTexto(t("services.hoursValidationError"));
                        setalertType("warning");
                        setShow(true);
                        setBackDrop(false);
                        setIsError(true);
                        return;
                    }
                } else {
                    setTexto(t("miscellaneous.requiredData"));
                    setalertType("warning");
                    setShow(true);
                    setBackDrop(false);
                    setIsError(true);
                    return;
                }
            }
            if (valuesCatchUp.checkedAR) {
                if (valuesCatchUp.startDateAR && valuesCatchUp.finishDateAR) {
                    if (valuesCatchUp.tothAR > 0) {
                        additionalsF.push({
                            additional_name: "A/R",
                            hours: parseFloat(valuesCatchUp.tothAR),
                            since_date: moment(valuesCatchUp.startDateAR).startOf("day").format(),
                            end_date: moment(valuesCatchUp.finishDateAR).startOf("day").format(),
                        });
                    } else {
                        setTexto(t("services.hoursValidationError"));
                        setalertType("warning");
                        setShow(true);
                        setBackDrop(false);
                        setIsError(true);
                        return;
                    }
                } else {
                    setTexto(t("miscellaneous.requiredData"));
                    setalertType("warning");
                    setShow(true);
                    setBackDrop(false);
                    setIsError(true);
                    return;
                }
            }
            if (valuesCatchUp.checkedAP) {
                if (valuesCatchUp.startDateAP && valuesCatchUp.finishDateAP) {
                    if (valuesCatchUp.tothAP > 0) {
                        additionalsF.push({
                            additional_name: "A/P",
                            hours: parseFloat(valuesCatchUp.tothAP),
                            since_date: moment(valuesCatchUp.startDateAP).startOf("day").format(),
                            end_date: moment(valuesCatchUp.finishDateAP).startOf("day").format(),
                        });
                    } else {
                        setTexto(t("services.hoursValidationError"));
                        setalertType("warning");
                        setShow(true);
                        setBackDrop(false);
                        setIsError(true);
                        return;
                    }
                } else {
                    setTexto(t("miscellaneous.requiredData"));
                    setalertType("warning");
                    setShow(true);
                    setBackDrop(false);
                    setIsError(true);
                    return;
                }
            }
            if (valuesCatchUp.checkedAccrual) {
                if (valuesCatchUp.startDateAccrual && valuesCatchUp.finishDateAccrual) {
                    if (valuesCatchUp.tothAccrual > 0) {
                        additionalsF.push({
                            additional_name: "Accrual",
                            hours: parseFloat(valuesCatchUp.tothAccrual),
                            since_date: moment(valuesCatchUp.startDateAccrual).startOf("day").format(),
                            end_date: moment(valuesCatchUp.finishDateAccrual).startOf("day").format(),
                        });
                    } else {
                        setTexto(t("services.hoursValidationError"));
                        setalertType("warning");
                        setShow(true);
                        setBackDrop(false);
                        setIsError(true);
                        return;
                    }
                } else {
                    setTexto(t("miscellaneous.requiredData"));
                    setalertType("warning");
                    setShow(true);
                    setBackDrop(false);
                    setIsError(true);
                    return;
                }
            }
            if (valuesCatchUp.checkedClass) {
                if (valuesCatchUp.startDateClass && valuesCatchUp.finishDateClass) {
                    if (valuesCatchUp.tothClass > 0) {
                        additionalsF.push({
                            additional_name: "Classes/Categories accounting",
                            hours: parseFloat(valuesCatchUp.tothClass),
                            since_date: moment(valuesCatchUp.startDateClass).startOf("day").format(),
                            end_date: moment(valuesCatchUp.finishDateClass).startOf("day").format(),
                        });
                    } else {
                        setTexto(t("services.hoursValidationError"));
                        setalertType("warning");
                        setShow(true);
                        setBackDrop(false);
                        setIsError(true);
                        return;
                    }
                } else {
                    setTexto(t("miscellaneous.requiredData"));
                    setalertType("warning");
                    setShow(true);
                    setBackDrop(false);
                    setIsError(true);
                    return;
                }
            }
            if (valuesCatchUp.checkedMulti) {
                if (valuesCatchUp.startDateMulti && valuesCatchUp.finishDateMulti) {
                    if (valuesCatchUp.tothMulti > 0) {
                        additionalsF.push({
                            additional_name: "Multi-entity accounting",
                            hours: parseFloat(valuesCatchUp.tothMulti),
                            since_date: moment(valuesCatchUp.startDateMulti).startOf("day").format(),
                            end_date: moment(valuesCatchUp.finishDateMulti).startOf("day").format(),
                        });
                    } else {
                        setTexto(t("services.hoursValidationError"));
                        setalertType("warning");
                        setShow(true);
                        setBackDrop(false);
                        setIsError(true);
                        return;
                    }
                } else {
                    setTexto(t("miscellaneous.requiredData"));
                    setalertType("warning");
                    setShow(true);
                    setBackDrop(false);
                    setIsError(true);
                    return;
                }
            }
            if (valuesCatchUp.checkedPer) {
                if (valuesCatchUp.startDatePer && valuesCatchUp.finishDatePer) {
                    if (valuesCatchUp.tothPer > 0) {
                        additionalsF.push({
                            additional_name: "Personalized reporting",
                            hours: parseFloat(valuesCatchUp.tothPer),
                            since_date: moment(valuesCatchUp.startDatePer).startOf("day").format(),
                            end_date: moment(valuesCatchUp.finishDatePer).startOf("day").format(),
                        });
                    } else {
                        setTexto(t("services.hoursValidationError"));
                        setalertType("warning");
                        setShow(true);
                        setBackDrop(false);
                        setIsError(true);
                        return;
                    }
                } else {
                    setTexto(t("miscellaneous.requiredData"));
                    setalertType("warning");
                    setShow(true);
                    setBackDrop(false);
                    setIsError(true);
                    return;
                }
            }
            if (valuesCatchUp.checkedOthers) {
                if (valuesCatchUp.startDateOthers && valuesCatchUp.finishDateOthers && valuesCatchUp.nameOthers) {
                    if (valuesCatchUp.tothOthers > 0) {
                        additionalsF.push({
                            additional_name: "Others",
                            hours: parseFloat(valuesCatchUp.tothOthers),
                            since_date: moment(valuesCatchUp.startDateOthers).startOf("day").format(),
                            end_date: moment(valuesCatchUp.finishDateOthers).startOf("day").format(),
                            other_name: valuesCatchUp.nameOthers,
                        });
                    } else {
                        setTexto(t("services.hoursValidationError"));
                        setalertType("warning");
                        setShow(true);
                        setBackDrop(false);
                        setIsError(true);
                        return;
                    }
                } else {
                    setTexto(t("miscellaneous.requiredData"));
                    setalertType("warning");
                    setShow(true);
                    setBackDrop(false);
                    setIsError(true);
                    return;
                }
            }

            let params = {
                company_id: companyID,
                service_id: serviceID,
                project_id: data.project_id,
                project_company_id: valuesCatchUp.projectCompanyId,
                fee: data.fee,
                total_fee:
                    valuesCatchUp.projecTot +
                    valuesCatchUp.addHourFee * getSumTotal() * (1 - valuesCatchUp.discountAdd / 100),
                discount: parseFloat(valuesCatchUp.discount),
                additional_discount: parseFloat(valuesCatchUp.discountAdd),
                since_date: moment(valuesCatchUp.initDate).startOf("day").format(),
                end_date: moment(valuesCatchUp.endDate).startOf("day").format(),
                client_commitment_date: moment(valuesCatchUp.estDeliDate).startOf("day").format(),
                client_delivery_date: valuesCatchUp.deliDate
                    ? moment(valuesCatchUp.deliDate).startOf("day").format()
                    : null,
                additionals_only: valuesCatchUp.additionalsOnly,
                additionals: additionalsF,
            };

            await fetch(`/addServicesAndProjects`, {
                method: "POST",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
                body: JSON.stringify(params),
            })
                .then((response) => {
                    if (!response.ok) {
                        // get error message from body or default to response status
                        const error = response.status;
                        throw new Error(error);
                    }

                    if (response.status === 200) {
                        setTexto(t("miscellaneous.successfulUpdate"));
                        setalertType("success");
                        setShow(true);
                        reloadProjects(true);
                    } else if (response.status === 201) {
                        setTexto(t("services.notPossibleGenProject"));
                        setalertType("error");
                        setShow(true);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            setTexto(t("miscellaneous.requiredData"));
            setalertType("warning");
            setShow(true);
            setIsError(true);
        }

        setBackDrop(false);
    };

    const handleChangeDeliDate = (date) => {
        if (!date) {
            setCatchUp({
                ...valuesCatchUp,
                deliDate: date,
            });
        } else {
            if (date.isSameOrBefore(moment(valuesCatchUp.endDate).format("YYYY-MM-DD"))) {
                setCatchUp({
                    ...valuesCatchUp,
                    deliDate: null,
                });

                setTexto(t("reports.selectEntDate"));
                setalertType("warning");
                setShow(true);
            } else {
                setCatchUp({
                    ...valuesCatchUp,
                    deliDate: date,
                });
            }
        }
    };

    const handleChangeEstDeliDate = (date) => {
        if (!date) {
            setCatchUp({
                ...valuesCatchUp,
                estDeliDate: date,
            });
        } else {
            if (date.isSameOrBefore(moment(valuesCatchUp.endDate).format("YYYY-MM-DD"))) {
                setCatchUp({
                    ...valuesCatchUp,
                    estDeliDate: null,
                });

                setTexto(t("reports.selectEntDate"));
                setalertType("warning");
                setShow(true);
            } else {
                setCatchUp({
                    ...valuesCatchUp,
                    estDeliDate: date,
                });
            }
        }
    };

    const handleChangeInitDate = (date) => {
        let startDate = moment(date).startOf("month");

        if (!date || !valuesCatchUp.endDate) {
            setCatchUp({
                ...valuesCatchUp,
                initDate: date ? startDate : date,
            });
        } else {
            if (startDate.isAfter(moment(valuesCatchUp.endDate).format("YYYY-MM-DD"))) {
                setCatchUp({
                    ...valuesCatchUp,
                    initDate: null,
                });

                setTexto(t("reports.initDateMess"));
                setalertType("warning");
                setShow(true);
            } else {
                let meses = Math.ceil(valuesCatchUp.endDate.diff(startDate, "months", true));

                let bookMonths = 0;
                if (valuesCatchUp.additionalsOnly) {
                    bookMonths = Math.ceil(moment(valuesCatchUp.finishDateBook).diff(startDate, "months", true));
                }

                setCatchUp({
                    ...valuesCatchUp,
                    initDate: startDate,
                    months: valuesCatchUp.additionalsOnly ? bookMonths : meses,
                    projectFee: valuesCatchUp.additionalsOnly
                        ? bookMonths * valuesCatchUp.tothBook * valuesCatchUp.addHourFee
                        : Math.ceil(data.expense_scale / 10000) * data.fee * meses,
                    projecTot: valuesCatchUp.additionalsOnly
                        ? bookMonths *
                        valuesCatchUp.tothBook *
                        valuesCatchUp.addHourFee *
                        (1 - valuesCatchUp.discount / 100)
                        : Math.ceil(data.expense_scale / 10000) * data.fee * meses * (1 - valuesCatchUp.discount / 100),
                    // Adicionales
                    startDateBook: valuesCatchUp.checkedBook ? startDate : null,
                    startDateChecks: valuesCatchUp.checkedChecks ? startDate : null,
                    startDateAR: valuesCatchUp.checkedAR ? startDate : null,
                    startDateAP: valuesCatchUp.checkedAP ? startDate : null,
                    startDateAccrual: valuesCatchUp.checkedAccrual ? startDate : null,
                    startDateClass: valuesCatchUp.checkedClass ? startDate : null,
                    startDateMulti: valuesCatchUp.checkedMulti ? startDate : null,
                    startDatePer: valuesCatchUp.checkedPer ? startDate : null,
                    // Otros
                    startDateOthers: valuesCatchUp.checkedOthers ? startDate : null,
                });
            }
        }
    };

    const handleChangeEndDate = (date) => {
        let endDate = moment(date).endOf("month").startOf("day");

        if (!date || !valuesCatchUp.initDate) {
            setCatchUp({
                ...valuesCatchUp,
                endDate: date ? endDate : date,
            });
        } else {
            if (endDate.isBefore(moment(valuesCatchUp.initDate).format("YYYY-MM-DD"))) {
                setCatchUp({
                    ...valuesCatchUp,
                    endDate: null,
                });

                setTexto(t("reports.endDateMess"));
                setalertType("warning");
                setShow(true);
            } else {
                let meses = Math.ceil(endDate.diff(valuesCatchUp.initDate, "months", true));

                let bookMonths = 0;
                if (valuesCatchUp.additionalsOnly) {
                    bookMonths = Math.ceil(endDate.diff(moment(valuesCatchUp.startDateBook), "months", true));
                }

                setCatchUp({
                    ...valuesCatchUp,
                    endDate: endDate,
                    months: valuesCatchUp.additionalsOnly ? bookMonths : meses,
                    projectFee: valuesCatchUp.additionalsOnly
                        ? bookMonths * valuesCatchUp.tothBook * valuesCatchUp.addHourFee
                        : Math.ceil(data.expense_scale / 10000) * data.fee * meses,
                    projecTot: valuesCatchUp.additionalsOnly
                        ? bookMonths *
                        valuesCatchUp.tothBook *
                        valuesCatchUp.addHourFee *
                        (1 - valuesCatchUp.discount / 100)
                        : Math.ceil(data.expense_scale / 10000) * data.fee * meses * (1 - valuesCatchUp.discount / 100),
                    // Adicionales
                    finishDateBook: valuesCatchUp.checkedBook ? endDate : null,
                    finishDateChecks: valuesCatchUp.checkedChecks ? endDate : null,
                    finishDateAR: valuesCatchUp.checkedAR ? endDate : null,
                    finishDateAP: valuesCatchUp.checkedAP ? endDate : null,
                    finishDateAccrual: valuesCatchUp.checkedAccrual ? endDate : null,
                    finishDateClass: valuesCatchUp.checkedClass ? endDate : null,
                    finishDateMulti: valuesCatchUp.checkedMulti ? endDate : null,
                    finishDatePer: valuesCatchUp.checkedPer ? endDate : null,
                    // Otros
                    finishDateOthers: valuesCatchUp.checkedOthers ? endDate : null,
                });
            }
        }
    };

    const handleMouseDownDiscount = (event) => {
        event.preventDefault();
    };

    const handleClickShowDiscount = () => {
        setEditMode(!editMode);
    };

    const handleClickShowDiscount2 = () => {
        setEditMode2(!editMode2);
    };

    const handleChangeSwitch = (event) => {
        let meses = 0;

        if (valuesCatchUp.initDate) {
            meses = isNaN(moment(valuesCatchUp.endDate).diff(moment(valuesCatchUp.initDate)))
                ? 0
                : Math.ceil(moment(valuesCatchUp.endDate).diff(moment(valuesCatchUp.initDate), "months", true));
        }

        setCatchUp({
            ...valuesCatchUp,
            baseFee: event.target.checked ? 0 : Math.ceil(data.expense_scale / 10000) * data.fee,
            months: meses,
            additionalsOnly: event.target.checked,
            projectFee: event.target.checked ? 0 : Math.ceil(data.expense_scale / 10000) * data.fee * meses,
            projecTot: event.target.checked
                ? 0
                : Math.ceil(data.expense_scale / 10000) * data.fee * meses * (1 - valuesCatchUp.discount / 100),
            discount: event.target.checked ? 0 : valuesCatchUp.discountOG,
            discountAdd: event.target.checked ? 0 : valuesCatchUp.discountAddOG,
            checkedBook: event.target.checked,
            tothBook: 0,
            startDateBook: event.target.checked ? valuesCatchUp.initDate : null,
            finishDateBook: event.target.checked ? valuesCatchUp.endDate : null,
        });
    };

    return (
        <StyledContainer>
            <LocalizationProvider
                dateAdapter={AdapterMoment}
                adapterLocale={t("language.locale")}
                localeText={{ clearButtonLabel: t("miscellaneous.clear") }}
            >
                <Card elevation={0}>
                    <CardHeader
                        title={
                            <Box display="flex" sx={{ alignItems: "center" }}>
                                Bookkeeper catchup
                                <FormControlLabel
                                    sx={{ ml: 1 }}
                                    control={
                                        <Switch
                                            checked={valuesCatchUp.additionalsOnly}
                                            disabled={
                                                valuesCatchUp.updateBoolean ||
                                                !valuesCatchUp.initDate ||
                                                !valuesCatchUp.endDate
                                            }
                                            inputProps={{
                                                "aria-label": "controlled",
                                            }}
                                            color="secondary"
                                            onChange={handleChangeSwitch}
                                            className={classes.switch}
                                        />
                                    }
                                    label={
                                        <Typography variant="body1" color="textPrimary">
                                            {t("services.hourlyInvoicing")}
                                        </Typography>
                                    }
                                />
                            </Box>
                        }
                    />
                    <Divider />
                    <CardContent>
                        {valuesCatchUp.updateBoolean && (
                            <GridUI container spacing={2} justifyContent="center" sx={{ paddingBottom: 3 }}>
                                <GridUI item>
                                    <Alert
                                        severity="warning"
                                        sx={{
                                            borderRadius: 8,
                                            fontSize: 12,
                                            fontWeight: 600,
                                        }}
                                    >
                                        {t("services.notPossibleEditProject")}
                                    </Alert>
                                </GridUI>
                            </GridUI>
                        )}
                        {actives.includes("Accountant") && (
                            <GridUI container spacing={2} justifyContent="center" sx={{ paddingBottom: 3 }}>
                                <GridUI item>
                                    <Alert
                                        severity="warning"
                                        sx={{
                                            borderRadius: 8,
                                            fontSize: 12,
                                            fontWeight: 600,
                                        }}
                                    >
                                        {t("services.notPosibleContract")}
                                    </Alert>
                                </GridUI>
                            </GridUI>
                        )}
                        {!actives.includes("Accountant") &&
                            bookkeeperSystem.disabledData === true &&
                            (bookkeeperSystem.prov === "Xero" || bookkeeperSystem.prov === "Quickbooks Online") && (
                                <GridUI container spacing={2} justifyContent="center" sx={{ paddingBottom: 3 }}>
                                    <GridUI item>
                                        <Alert
                                            severity="warning"
                                            sx={{
                                                borderRadius: 8,
                                                fontSize: 12,
                                                fontWeight: 600,
                                            }}
                                        >
                                            {t("services.enterValidCredentials")}
                                        </Alert>
                                    </GridUI>
                                </GridUI>
                            )}
                        {!actives.includes("Accountant") && expenseEst === -1 && (
                            <GridUI container spacing={2} justifyContent="center" sx={{ paddingBottom: 3 }}>
                                <GridUI item>
                                    <Alert
                                        severity="warning"
                                        sx={{
                                            borderRadius: 8,
                                            fontSize: 12,
                                            fontWeight: 600,
                                        }}
                                    >
                                        {t("services.ableToSaveInfo")}
                                    </Alert>
                                </GridUI>
                            </GridUI>
                        )}
                        <GridUI container alignItems="center" spacing={2}>
                            <GridUI item xs={12} sm={6} lg={2}>
                                <FormControl fullWidth variant="outlined" disabled>
                                    <InputLabel htmlFor="outlined-adornment-baseFee">
                                        {t("services.monthlyRate")}
                                    </InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-baseFee"
                                        value={valuesCatchUp.baseFee}
                                        inputComponent={NumberFormatCustom}
                                        startAdornment={
                                            <InputAdornment disableTypography position="start">
                                                $
                                            </InputAdornment>
                                        }
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <LightTooltip aria-label="info" title={t("services.monthlyBaseRate")}>
                                                    <InfoIcon fontSize="small" color="primary" />
                                                </LightTooltip>
                                            </InputAdornment>
                                        }
                                        label={t("services.monthlyRate")}
                                    />
                                </FormControl>
                            </GridUI>
                            <GridUI item xs={12} sm={6} lg={1}>
                                <TextField
                                    disabled
                                    fullWidth
                                    label={t("bankingDashboard.months")}
                                    value={valuesCatchUp.months}
                                    name="months"
                                    variant="outlined"
                                    type="number"
                                    InputProps={{ inputProps: { min: 0 } }}
                                />
                            </GridUI>
                            <GridUI item xs={12} sm={6} lg={3}>
                                <FormControl fullWidth variant="outlined" disabled>
                                    <InputLabel htmlFor="outlined-adornment-projectFee">
                                        {t("services.projectFee")}
                                    </InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-projectFee"
                                        value={valuesCatchUp.projectFee}
                                        inputComponent={NumberFormatCustom}
                                        startAdornment={
                                            <InputAdornment disableTypography position="start">
                                                $
                                            </InputAdornment>
                                        }
                                        label={t("services.projectFee")}
                                    />
                                </FormControl>
                            </GridUI>
                            <GridUI item xs={12} sm={6} lg={3}>
                                <FormControl variant="outlined" disabled={editMode} fullWidth>
                                    <InputLabel htmlFor="outlined-adornment-discount">
                                        {t("miscellaneous.discount")}
                                    </InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-discount"
                                        type="number"
                                        name="discount"
                                        value={valuesCatchUp.discount}
                                        onChange={handleChangeDiscount}
                                        onBlur={() =>
                                            setCatchUp({
                                                ...valuesCatchUp,
                                                discount: parseFloat(
                                                    valuesCatchUp.discount === "" ? 0 : valuesCatchUp.discount
                                                ).toFixed(2),
                                            })
                                        }
                                        inputProps={{
                                            min: 0,
                                            max: 30,
                                        }}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                %
                                                <LightTooltip
                                                    title={
                                                        editMode
                                                            ? t("services.catchupMaximunDiscount")
                                                            : t("miscellaneous.save")
                                                    }
                                                    aria-label="edit"
                                                >
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        disabled={valuesCatchUp.updateBoolean}
                                                        onClick={handleClickShowDiscount}
                                                        onMouseDown={handleMouseDownDiscount}
                                                        edge="end"
                                                    >
                                                        {!editMode ? <EditOffIcon /> : <EditIcon />}
                                                    </IconButton>
                                                </LightTooltip>
                                            </InputAdornment>
                                        }
                                        label={t("miscellaneous.discount")}
                                        sx={{
                                            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                                display: "none",
                                            },
                                        }}
                                    />
                                </FormControl>
                            </GridUI>
                            <GridUI item xs={12} sm={6} lg={3}>
                                <FormControl fullWidth variant="outlined" disabled>
                                    <InputLabel htmlFor="outlined-adornment-projecTot">
                                        {t("services.projectTot")}
                                    </InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-projecTot"
                                        value={valuesCatchUp.projecTot}
                                        inputComponent={NumberFormatCustom}
                                        startAdornment={
                                            <InputAdornment disableTypography position="start">
                                                $
                                            </InputAdornment>
                                        }
                                        label={t("services.projectTot")}
                                    />
                                </FormControl>
                            </GridUI>
                            <GridUI item xs={12} sm={6} lg={3}></GridUI>
                            <GridUI item xs={12} sm={6} lg={3}>
                                <FormControl fullWidth variant="outlined" disabled>
                                    <InputLabel htmlFor="outlined-adornment-feeAdd">
                                        {t("services.hourlyRateAdd")}
                                    </InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-feeAdd"
                                        value={valuesCatchUp.addHourFee * getSumTotal()}
                                        inputComponent={NumberFormatCustom}
                                        startAdornment={
                                            <InputAdornment disableTypography position="start">
                                                $
                                            </InputAdornment>
                                        }
                                        label={t("services.hourlyRateAdd")}
                                    />
                                </FormControl>
                            </GridUI>
                            <GridUI item xs={12} sm={6} lg={3}>
                                <FormControl variant="outlined" disabled={editMode2} fullWidth>
                                    <InputLabel htmlFor="outlined-adornment-discount">
                                        {t("miscellaneous.discount")}
                                    </InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-discount"
                                        type="number"
                                        name="discountAdd"
                                        value={valuesCatchUp.discountAdd}
                                        onChange={handleChangeDiscount}
                                        onBlur={() =>
                                            setCatchUp({
                                                ...valuesCatchUp,
                                                discountAdd: parseFloat(
                                                    valuesCatchUp.discountAdd === "" ? 0 : valuesCatchUp.discountAdd
                                                ).toFixed(2),
                                            })
                                        }
                                        inputProps={{
                                            min: 0,
                                            max: 30,
                                        }}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                %
                                                <LightTooltip
                                                    title={
                                                        editMode2
                                                            ? t("services.catchupMaximunDiscount")
                                                            : t("miscellaneous.save")
                                                    }
                                                    aria-label="edit"
                                                >
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        disabled={valuesCatchUp.updateBoolean}
                                                        onClick={handleClickShowDiscount2}
                                                        onMouseDown={handleMouseDownDiscount}
                                                        edge="end"
                                                    >
                                                        {!editMode2 ? <EditOffIcon /> : <EditIcon />}
                                                    </IconButton>
                                                </LightTooltip>
                                            </InputAdornment>
                                        }
                                        label={t("miscellaneous.discount")}
                                        sx={{
                                            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                                display: "none",
                                            },
                                        }}
                                    />
                                </FormControl>
                            </GridUI>
                            <GridUI item xs={12} sm={6} lg={3}>
                                <FormControl fullWidth variant="outlined" disabled>
                                    <InputLabel htmlFor="outlined-adornment-totalAdd">
                                        {t("clientsTable.totalAdd")}
                                    </InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-totalAdd"
                                        value={
                                            valuesCatchUp.addHourFee *
                                            getSumTotal() *
                                            (1 - valuesCatchUp.discountAdd / 100)
                                        }
                                        inputComponent={NumberFormatCustom}
                                        startAdornment={
                                            <InputAdornment disableTypography position="start">
                                                $
                                            </InputAdornment>
                                        }
                                        label={t("clientsTable.totalAdd")}
                                    />
                                </FormControl>
                            </GridUI>
                            <GridUI item xs={12} sm={6} lg={9}></GridUI>
                            <GridUI item xs={12} sm={6} lg={3}>
                                <FormControl fullWidth variant="outlined" disabled>
                                    <InputLabel htmlFor="outlined-adornment-feeTotal">
                                        {t("proposals.feeTotal")}
                                    </InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-feeTotal"
                                        value={
                                            valuesCatchUp.projecTot +
                                            valuesCatchUp.addHourFee *
                                            getSumTotal() *
                                            (1 - valuesCatchUp.discountAdd / 100)
                                        }
                                        inputComponent={NumberFormatCustom}
                                        startAdornment={
                                            <InputAdornment disableTypography position="start">
                                                $
                                            </InputAdornment>
                                        }
                                        label={t("proposals.feeTotal")}
                                    />
                                </FormControl>
                            </GridUI>
                        </GridUI>
                    </CardContent>
                </Card>
                <Card elevation={0}>
                    <CardHeader title={t("services.serviceResponsibility")} sx={{ color: "#ff5968" }} />
                    <Divider />
                    <CardContent>
                        <GridUI container alignItems="center" spacing={2}>
                            <GridUI item xs={12} sm={6} lg={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    disabled={valuesCatchUp.updateBoolean}
                                    value={valuesCatchUp.initDate}
                                    onChange={(newValue) => handleChangeInitDate(newValue)}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            required
                                            {...params}
                                            error={isError && valuesCatchUp.initDate === null}
                                        />
                                    )}
                                    inputProps={{ readOnly: true }}
                                    label={t("reports.initDate")}
                                    views={["year", "month"]}
                                />
                            </GridUI>
                            <GridUI item xs={12} sm={6} lg={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    disabled={valuesCatchUp.updateBoolean}
                                    value={valuesCatchUp.endDate}
                                    onChange={(newValue) => handleChangeEndDate(newValue)}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            required
                                            {...params}
                                            error={isError && valuesCatchUp.endDate === null}
                                        />
                                    )}
                                    inputProps={{ readOnly: true }}
                                    label={t("reports.endDate")}
                                    views={["year", "month"]}
                                />
                            </GridUI>
                            <GridUI item xs={12} sm={6} lg={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    disabled={valuesCatchUp.updateBoolean}
                                    value={valuesCatchUp.estDeliDate}
                                    onChange={(newValue) => handleChangeEstDeliDate(newValue)}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            required
                                            {...params}
                                            error={isError && valuesCatchUp.estDeliDate === null}
                                        />
                                    )}
                                    inputProps={{ readOnly: true }}
                                    label={t("services.estimDeliveryDate")}
                                    views={["year", "month", "day"]}
                                />
                            </GridUI>
                            <GridUI item xs={12} sm={6} lg={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    disableFuture
                                    value={valuesCatchUp.deliDate}
                                    onChange={(newValue) => handleChangeDeliDate(newValue)}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => <TextField fullWidth {...params} />}
                                    inputProps={{ readOnly: true }}
                                    label={t("services.deliveryDate")}
                                    views={["year", "month", "day"]}
                                />
                            </GridUI>
                        </GridUI>
                    </CardContent>
                </Card>
                <UpdateAdd
                    values={valuesCatchUp}
                    actives={actives}
                    set={setCatchUp}
                    setTexto={setTexto}
                    setalertType={setalertType}
                    setShow={setShow}
                    isError={isError}
                    updateProject={updateProject}
                    sumTotal={getSumTotal()}
                    originalData={data}
                    bookkeeperSystem={bookkeeperSystem}
                    expenseEst={expenseEst}
                    showConfirm={showConfirm}
                />
            </LocalizationProvider>
            <DialogAlerts
                open={open}
                onClose={handleCloseDialog}
                agreeBtnLabel={t("miscellaneous.delete")}
                disagreeBtnLabel="No"
                type={"info"}
                title={t("miscellaneous.confRemoveCheck")}
                message={bodyMess}
                agreeAction={removeCheck}
            />
        </StyledContainer>
    );
};
