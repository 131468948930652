/** React imports */
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

/** Images */

/** MUI icons */
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

/** MUI imports */
import { Box, Typography, Grid, Divider } from "@mui/material";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

/** Global variables */
const PREFIX = "ServiceLevelsAcc";

const classes = {
    body2: `${PREFIX}-body2`,
    serviceName: `${PREFIX}-serviceName`,
    serviceBody: `${PREFIX}-serviceBody`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.serviceName}`]: {
        fontSize: 22,
    },

    [`& .${classes.body2}`]: {
        backgroundColor: "white",
        marginTop: 15,
        minHeight: 850,
        [theme.breakpoints.down("md")]: {
            padding: 30,
        },
        [theme.breakpoints.up("md")]: {
            padding: 60,
        },
    },

    [`& .${classes.serviceBody}`]: {
        backgroundColor: "#F8F9F8",
        marginTop: 30,
        padding: 30,
        borderRadius: 30,
    },
}));

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)({
    boxShadow: "none",
    "&:before": {
        display: "none",
    },
    backgroundColor: "#F8F9F8",
});

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: "#031851" }} />} {...props} />
))({
    padding: 0,
    "& .MuiAccordionSummary-content": {
        margin: "12px 0",
    },
});

const AccordionDetails = styled(MuiAccordionDetails)({
    padding: "0px 0px 16px 0px",
    "& .MuiCard-root": {
        boxShadow: "none",
    },
});

export const ServiceLevelsAcc = (props) => {
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    const { accInfo } = props;

    const [expandedSub, setExpandedSub] = useState(false);
    const [expandedSubAdd, setExpandedSubAdd] = useState(false);

    const handleChangeAccSub = (panel) => (event, isExpanded) => {
        setExpandedSub(isExpanded ? panel : false);
    };
    const handleChangeAccSubAdd = (panel) => (event, isExpanded) => {
        setExpandedSubAdd(isExpanded ? panel : false);
    };

    return (
        <StyledContainer>
            {accInfo.onboarding.isActive && (
                <Accordion
                    defaultExpanded
                    TransitionProps={{ unmountOnExit: true }}
                    sx={{
                        borderBottom: "3px solid #ff5968",
                    }}
                >
                    <AccordionSummary aria-controls="panelAuxbh-content" id="panelAuxbh-header">
                        <Typography variant="h4">Onboarding</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Divider sx={{ mb: 2 }} />
                        <Grid container spacing={3} alignItems="center">
                            <Grid item xs={12} md={4}>
                                <Typography variant="body1">{t("proposals.intel")}</Typography>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <Typography variant="body1">{t("proposals.deepUnder")}</Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Typography variant="body1">{t("proposals.design")}</Typography>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <Typography variant="body1">{t("proposals.finanState")}</Typography>
                            </Grid>
                        </Grid>
                        <Accordion
                            expanded={expandedSub === "Accounting Model Set up"}
                            onChange={handleChangeAccSub("Accounting Model Set up")}
                            TransitionProps={{
                                unmountOnExit: true,
                            }}
                            sx={{ pt: 1 }}
                        >
                            <AccordionSummary aria-controls="panelAuxbh-content" id="panelAuxbh-header">
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={12} md={4}>
                                        <Typography variant="body1">{t("proposals.accModelSet")}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <Typography variant="body1" sx={{ pl: "10px" }}>
                                            {t("proposals.impleProcBook")}{" "}{t("proposals.threeHours")}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Divider sx={{ mb: 2 }} />
                                <Box sx={{ p: "0 30px" }}>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={12} md={4}>
                                            <Typography variant="body1">{t("proposals.chartAcc")}</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={8}>
                                            <Typography variant="body1">{t("proposals.mapPer")}</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <Typography variant="body1">{t("proposals.convBal")}</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={8}>
                                            <Typography variant="body1">{t("proposals.regSald")}</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <Typography variant="body1">{t("miscellaneous.banks")}</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={8}>
                                            <Typography variant="body1">{t("proposals.banksReg")}</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <Typography variant="body1">{t("proposals.actFij")}</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={8}>
                                            <Typography variant="body1">{t("proposals.fixedAssets")}</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <Typography variant="body1">{t("proposals.multiCoin")}</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={8}>
                                            <Typography variant="body1">{t("proposals.currenSet")}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Divider
                                        sx={{
                                            mt: 2,
                                            backgroundColor: "#ff5968",
                                        }}
                                    />
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion
                            expanded={expandedSub === "Premium support"}
                            onChange={handleChangeAccSub("Premium support")}
                            TransitionProps={{
                                unmountOnExit: true,
                            }}
                        >
                            <AccordionSummary aria-controls="panelAuxbh-content" id="panelAuxbh-header">
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={12} md={4}>
                                        <Typography variant="body1">{t("proposals.support")}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <Typography variant="body1" sx={{ pl: "10px" }}>
                                            {t("proposals.dediTeam")}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Divider sx={{ mb: 2 }} />
                                <Box sx={{ p: "0 30px" }}>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={12} md={4}>
                                            <Typography variant="body1">{t("proposals.capa")}</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={8}>
                                            <Typography variant="body1">{t("proposals.meetVirt")}</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <Typography variant="body1">{t("proposals.follow")}</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={8}>
                                            <Typography variant="body1">{t("proposals.acompUser")}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Divider
                                        sx={{
                                            mt: 2,
                                            backgroundColor: "#ff5968",
                                        }}
                                    />
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    </AccordionDetails>
                </Accordion>
            )}
            {(accInfo.recurring.isActive || accInfo.cleanUp.isActive || accInfo.catchUp.isActive) && (
                <Accordion
                    defaultExpanded
                    TransitionProps={{ unmountOnExit: true }}
                    sx={{
                        borderBottom: "3px solid #ff5968",
                    }}
                >
                    <AccordionSummary aria-controls="panelAuxbh-content" id="panelAuxbh-header">
                        <Typography variant="h4">{t("proposals.recuServi") + ", catch-up & clean-up"}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Divider sx={{ mb: 2 }} />
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12} md={4}>
                                <Typography variant="body1">{t("proposals.dediAcc")}</Typography>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <Typography variant="body1">{t("proposals.aPubAcc")}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Accordion
                                    expanded={expandedSub === "Bank reconciliations"}
                                    onChange={handleChangeAccSub("Bank reconciliations")}
                                    TransitionProps={{
                                        unmountOnExit: true,
                                    }}
                                >
                                    <AccordionSummary aria-controls="panelAuxbh-content" id="panelAuxbh-header">
                                        <Grid container spacing={2} alignItems="center">
                                            <Grid item xs={12} md={4}>
                                                <Typography variant="body1">{t("proposals.bankRec")}</Typography>
                                            </Grid>
                                            <Grid item xs={12} md={8}>
                                                <Typography variant="body1" sx={{ pl: "7px" }}>
                                                    {t("proposals.bankRecDes")}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Divider sx={{ mb: 2 }} />
                                        <Box sx={{ p: "0 30px" }}>
                                            <Grid container spacing={2} alignItems="center">
                                                <Grid item xs={12} md={4}>
                                                    <Typography variant="body1">{t("proposals.bankSal")}</Typography>
                                                </Grid>
                                                <Grid item xs={12} md={8}>
                                                    <Typography variant="body1">{t("proposals.conBank")}</Typography>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <Typography variant="body1">{t("proposals.bankRec")}</Typography>
                                                </Grid>
                                                <Grid item xs={12} md={8}>
                                                    <Typography variant="body1">{t("proposals.regBank")}</Typography>
                                                </Grid>
                                            </Grid>
                                            <Divider
                                                sx={{
                                                    mt: 2,
                                                    backgroundColor: "#ff5968",
                                                }}
                                            />
                                        </Box>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion
                                    expanded={expandedSub === "Cash and accrual accounting"}
                                    onChange={handleChangeAccSub("Cash and accrual accounting")}
                                    TransitionProps={{
                                        unmountOnExit: true,
                                    }}
                                >
                                    <AccordionSummary aria-controls="panelAuxbh-content" id="panelAuxbh-header">
                                        <Grid container spacing={2} alignItems="center">
                                            <Grid item xs={12} md={4}>
                                                <Typography variant="body1">{t("proposals.cashAccru")}</Typography>
                                            </Grid>
                                            <Grid item xs={12} md={8}>
                                                <Typography variant="body1" sx={{ pl: "7px" }}>
                                                    {t("proposals.tranReCash")}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Divider sx={{ mb: 2 }} />
                                        <Box sx={{ p: "0 30px" }}>
                                            <Grid container spacing={2} alignItems="center">
                                                <Grid item xs={12} md={4}>
                                                    <Typography variant="body1">{t("proposals.prepaid")}</Typography>
                                                </Grid>
                                                <Grid item xs={12} md={8}>
                                                    <Typography variant="body1">{t("proposals.regActPay")}</Typography>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <Typography variant="body1">{t("proposals.invent")}</Typography>
                                                </Grid>
                                                <Grid item xs={12} md={8}>
                                                    <Typography variant="body1">{t("proposals.regProd")}</Typography>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <Typography variant="body1">{t("proposals.actFij")}</Typography>
                                                </Grid>
                                                <Grid item xs={12} md={8}>
                                                    <Typography variant="body1">
                                                        {t("proposals.regActiveDep")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <Typography variant="body1">{t("proposals.loans")}</Typography>
                                                </Grid>
                                                <Grid item xs={12} md={8}>
                                                    <Typography variant="body1">
                                                        {t("proposals.recordsLoans")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <Typography variant="body1">{t("proposals.liabiPro")}</Typography>
                                                </Grid>
                                                <Grid item xs={12} md={8}>
                                                    <Typography variant="body1">
                                                        {t("proposals.recordInLia")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <Typography variant="body1">{t("proposals.taxes")}</Typography>
                                                </Grid>
                                                <Grid item xs={12} md={8}>
                                                    <Typography variant="body1">
                                                        {t("proposals.recordReTax")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <Typography variant="body1">{t("proposals.equity")}</Typography>
                                                </Grid>
                                                <Grid item xs={12} md={8}>
                                                    <Typography variant="body1">{t("proposals.recordsEq")}</Typography>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <Typography variant="body1">
                                                        {t("proposals.salesRevenue")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} md={8}>
                                                    <Typography variant="body1">
                                                        {t("proposals.recordsSales")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <Typography variant="body1">{t("proposals.costs")}</Typography>
                                                </Grid>
                                                <Grid item xs={12} md={8}>
                                                    <Typography variant="body1">{t("proposals.recordCost")}</Typography>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <Typography variant="body1">{t("proposals.expenses")}</Typography>
                                                </Grid>
                                                <Grid item xs={12} md={8}>
                                                    <Typography variant="body1">{t("proposals.recordOpe")}</Typography>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <Typography variant="body1">{t("proposals.payroll")}</Typography>
                                                </Grid>
                                                <Grid item xs={12} md={8}>
                                                    <Typography variant="body1">
                                                        {t("proposals.recordRePay")}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Divider
                                                sx={{
                                                    mt: 2,
                                                    backgroundColor: "#ff5968",
                                                }}
                                            />
                                        </Box>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Typography variant="body1">{t("proposals.finannState")}</Typography>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <Typography variant="body1">{t("proposals.balanceGen")}</Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Typography variant="body1" sx={{ pt: 1 }}>
                                    {t("proposals.virtualMeet")}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <Typography variant="body1" sx={{ pt: 1 }}>
                                    {t("proposals.virtualMeetDet")}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Typography variant="body1" sx={{ pt: 1 }}>
                                    {t("proposals.writtenSup")}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <Typography variant="body1" sx={{ pt: 1 }}>
                                    {t("proposals.UnlimSup")}
                                </Typography>
                            </Grid>
                        </Grid>
                        {((accInfo.recurring.additionals.length !== 0 && !accInfo.recurring.onlyAdd) ||
                            (accInfo.catchUp.additionals.length !== 0 && !accInfo.catchUp.onlyAdd) ||
                            (accInfo.cleanUp.additionals.length !== 0 && !accInfo.cleanUp.onlyAdd)) && (
                            <Grid container spacing={2} alignItems="center" sx={{ p: "15px 64px 0px 64px" }}>
                                <Grid item xs={12}>
                                    <Typography variant="h4">{t("proposals.addons")}</Typography>
                                    <Divider
                                        sx={{
                                            mt: 1,
                                            backgroundColor: "#CCCCCC",
                                        }}
                                    />
                                </Grid>
                                {accInfo.genAdditionals.exe && (
                                    <>
                                        <Grid item xs={12} md={4}>
                                            <Typography variant="body1">{t("services.executionTaxPlan")}</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={8}>
                                            <Typography variant="body1">{t("services.executionTaxPlanDet")}</Typography>
                                        </Grid>
                                    </>
                                )}
                                {accInfo.genAdditionals.cons && (
                                    <>
                                        <Grid item xs={12} md={4}>
                                            <Typography variant="body1">
                                                {t("services.consolidationStatements")}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={8}>
                                            <Typography variant="body1">
                                                {t("services.consolidationStatementsDet")}
                                            </Typography>
                                        </Grid>
                                    </>
                                )}
                                {accInfo.genAdditionals.rep && (
                                    <>
                                        <Grid item xs={12} md={4}>
                                            <Typography variant="body1">{t("services.reportsAuditors")}</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={8}>
                                            <Typography variant="body1">{t("services.reportsAuditorsDet")}</Typography>
                                        </Grid>
                                    </>
                                )}
                                {accInfo.genAdditionals.checks && (
                                    <>
                                        <Grid item xs={12} md={4}>
                                            <Typography variant="body1">{t("proposals.checks")}</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={8}>
                                            <Typography variant="body1">{t("proposals.checkBen")}</Typography>
                                        </Grid>
                                    </>
                                )}
                                {accInfo.genAdditionals.ar && (
                                    <>
                                        <Grid item xs={12} md={4}>
                                            <Typography variant="body1">{t("proposals.addAR")}</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={8}>
                                            <Typography variant="body1">{t("proposals.addARAcc")}</Typography>
                                        </Grid>
                                    </>
                                )}
                                {accInfo.genAdditionals.ap && (
                                    <>
                                        <Grid item xs={12} md={4}>
                                            <Typography variant="body1">{t("proposals.addAP")}</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={8}>
                                            <Typography variant="body1">{t("proposals.addAPAcc")}</Typography>
                                        </Grid>
                                    </>
                                )}
                                {accInfo.genAdditionals.classes && (
                                    <>
                                        <Grid item xs={12} md={4}>
                                            <Typography variant="body1">{t("services.classesCategories")}</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={8}>
                                            <Typography variant="body1">{t("proposals.addCostsCent")}</Typography>
                                        </Grid>
                                    </>
                                )}
                                {accInfo.genAdditionals.multi && (
                                    <>
                                        <Grid item xs={12} md={4}>
                                            <Typography variant="body1">{t("proposals.multiEntity")}</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={8}>
                                            <Typography variant="body1">{t("proposals.addRecordRe")}</Typography>
                                        </Grid>
                                    </>
                                )}
                                {accInfo.genAdditionals.person && (
                                    <>
                                        <Grid item xs={12} md={4}>
                                            <Typography variant="body1">{t("proposals.addPerRep")}</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={8}>
                                            <Typography variant="body1">{t("proposals.addTayMade")}</Typography>
                                        </Grid>
                                    </>
                                )}
                                {accInfo.genAdditionals.sales && (
                                    <>
                                        <Grid item xs={12} md={4}>
                                            <Typography variant="body1">{t("proposals.addSales")}</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={8}>
                                            <Typography variant="body1">{t("proposals.addSalesDet")}</Typography>
                                        </Grid>
                                    </>
                                )}
                                {accInfo.genAdditionals.ordinary && (
                                    <Grid item xs={12}>
                                        <Accordion
                                            expanded={expandedSubAdd === "Ordinary contributor taxes"}
                                            onChange={handleChangeAccSubAdd("Ordinary contributor taxes")}
                                            TransitionProps={{
                                                unmountOnExit: true,
                                            }}
                                        >
                                            <AccordionSummary aria-controls="panelAuxbh-content" id="panelAuxbh-header">
                                                <Grid container spacing={2} alignItems="center">
                                                    <Grid item xs={12} md={4}>
                                                        <Typography variant="body1">
                                                            {t("proposals.addOrdTax")}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} md={8}>
                                                        <Typography variant="body1" sx={{ pl: "7px" }}>
                                                            {t("proposals.addOrdTaxDet")}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Divider sx={{ mb: 2 }} />
                                                <Box sx={{ p: "0 30px" }}>
                                                    <Grid container spacing={2} alignItems="center">
                                                        <Grid item xs={12} md={4}>
                                                            <Typography variant="body1">
                                                                {t("proposals.addSubWith")}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} md={8}>
                                                            <Typography variant="body1">
                                                                {t("proposals.addSubWithDet")}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} md={4}>
                                                            <Typography variant="body1">
                                                                {t("proposals.addSubIva")}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} md={8}>
                                                            <Typography variant="body1">
                                                                {t("proposals.addSubIvaDet")}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                )}
                                {accInfo.genAdditionals.special && (
                                    <Grid item xs={12}>
                                        <Accordion
                                            expanded={expandedSubAdd === "Special contributor taxes"}
                                            onChange={handleChangeAccSubAdd("Special contributor taxes")}
                                            TransitionProps={{
                                                unmountOnExit: true,
                                            }}
                                        >
                                            <AccordionSummary aria-controls="panelAuxbh-content" id="panelAuxbh-header">
                                                <Grid container spacing={2} alignItems="center">
                                                    <Grid item xs={12} md={4}>
                                                        <Typography variant="body1">
                                                            {t("proposals.addSpecial")}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} md={8}>
                                                        <Typography variant="body1" sx={{ pl: "7px" }}>
                                                            {t("proposals.addSpecialDet")}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Divider sx={{ mb: 2 }} />
                                                <Box sx={{ p: "0 30px" }}>
                                                    <Grid container spacing={2} alignItems="center">
                                                        <Grid item xs={12} md={4}>
                                                            <Typography variant="body1">
                                                                {t("proposals.addSubRetISLR")}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} md={8}>
                                                            <Typography variant="body1">
                                                                {t("proposals.addSubRet")}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} md={4}>
                                                            <Typography variant="body1">
                                                                {t("proposals.addSubVatTax")}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} md={8}>
                                                            <Typography variant="body1">
                                                                {t("proposals.addSubVatTaxDet")}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} md={4}>
                                                            <Typography variant="body1">
                                                                {t("proposals.addSubValue")}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} md={8}>
                                                            <Typography variant="body1">
                                                                {t("proposals.addSubValueDet")}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} md={4}>
                                                            <Typography variant="body1">
                                                                {t("proposals.addSubPrepaid")}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} md={8}>
                                                            <Typography variant="body1">
                                                                {t("proposals.addSubPrepaidDet")}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} md={4}>
                                                            <Typography variant="body1">
                                                                {t("proposals.addSubPrepaidIGTF")}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} md={8}>
                                                            <Typography variant="body1">
                                                                {t("proposals.addSubPrepaidIGTFDet")}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                )}
                                {accInfo.genAdditionals.muni && (
                                    <>
                                        <Grid item xs={12} md={4}>
                                            <Typography variant="body1">{t("proposals.addImpMun")}</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={8}>
                                            <Typography variant="body1">{t("proposals.addImpMunDet")}</Typography>
                                        </Grid>
                                    </>
                                )}
                                {accInfo.genAdditionals.other && (
                                    <Accordion
                                        expanded={expandedSub === "Others"}
                                        onChange={handleChangeAccSub("Others")}
                                        TransitionProps={{
                                            unmountOnExit: true,
                                        }}
                                        sx={{ width: "100%", pl: "16px", pt: "5px" }}
                                    >
                                        <AccordionSummary aria-controls="panelAuxbh-content" id="panelAuxbh-header">
                                            <Grid container spacing={2} alignItems="center">
                                                <Grid item xs={12} md={4}>
                                                    <Typography variant="body1">{t("proposals.addOthers")}</Typography>
                                                </Grid>
                                                <Grid item xs={12} md={8} sx={{ pl: "16px" }}>
                                                    <Typography variant="body1" sx={{ pl: "7px" }}>
                                                        {t("proposals.addOthersDet")}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Divider sx={{ mb: 2 }} />
                                            <Box sx={{ p: "0 30px" }}>
                                                <Grid container spacing={2} alignItems="center">
                                                    {accInfo.recurring.additionals.filter(
                                                        (item) => item.additional_name === "OthersAcc"
                                                    ).length > 0 && (
                                                        <Grid item xs={12}>
                                                            <Typography variant="body1">
                                                                {
                                                                    accInfo.recurring.additionals.filter(
                                                                        (item) => item.additional_name === "OthersAcc"
                                                                    )[0].other_name
                                                                }
                                                            </Typography>
                                                        </Grid>
                                                    )}
                                                    {accInfo.catchUp.additionals.filter(
                                                        (item) => item.additional_name === "OthersAcc"
                                                    ).length > 0 && (
                                                        <Grid item xs={12}>
                                                            <Typography variant="body1">
                                                                {
                                                                    accInfo.catchUp.additionals.filter(
                                                                        (item) => item.additional_name === "OthersAcc"
                                                                    )[0].other_name
                                                                }
                                                            </Typography>
                                                        </Grid>
                                                    )}
                                                    {accInfo.cleanUp.additionals.filter(
                                                        (item) => item.additional_name === "OthersAcc"
                                                    ).length > 0 && (
                                                        <Grid item xs={12}>
                                                            <Typography variant="body1">
                                                                {
                                                                    accInfo.cleanUp.additionals.filter(
                                                                        (item) => item.additional_name === "OthersAcc"
                                                                    )[0].other_name
                                                                }
                                                            </Typography>
                                                        </Grid>
                                                    )}
                                                </Grid>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                )}
                            </Grid>
                        )}
                    </AccordionDetails>
                </Accordion>
            )}
        </StyledContainer>
    );
};
