/** React imports */
import React from 'react';

/** MUI imports */
import { styled } from '@mui/material/styles';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const PREFIX = 'SimpleBackdrop';

const classes = {
  backdrop: `${PREFIX}-backdrop`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.backdrop}`]: {
    zIndex: theme.zIndex.drawer + 999,
    color: '#ff5968',
  }
}));

const SimpleBackdrop = (props) => {
  return (
    <Root>
      <Backdrop className={classes.backdrop} open={props.open}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Root>
  );
}

export default SimpleBackdrop;