/** React imports */
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

/** Images */

/** MUI icons */
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

/** MUI imports */
import { Box, Typography, Grid, Divider } from "@mui/material";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

/** Global variables */
const PREFIX = "ServiceLevelsBook";

const classes = {
    body2: `${PREFIX}-body2`,
    serviceName: `${PREFIX}-serviceName`,
    serviceBody: `${PREFIX}-serviceBody`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.serviceName}`]: {
        fontSize: 22,
    },

    [`& .${classes.body2}`]: {
        backgroundColor: "white",
        marginTop: 15,
        minHeight: 850,
        [theme.breakpoints.down("md")]: {
            padding: 30,
        },
        [theme.breakpoints.up("md")]: {
            padding: 60,
        },
    },

    [`& .${classes.serviceBody}`]: {
        backgroundColor: "#F8F9F8",
        marginTop: 30,
        padding: 30,
        borderRadius: 30,
    },
}));

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)({
    boxShadow: "none",
    "&:before": {
        display: "none",
    },
    backgroundColor: "#F8F9F8",
});

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: "#031851" }} />} {...props} />
))({
    padding: 0,
    "& .MuiAccordionSummary-content": {
        margin: "12px 0",
    },
});

const AccordionDetails = styled(MuiAccordionDetails)({
    padding: "0px 0px 16px 0px",
    "& .MuiCard-root": {
        boxShadow: "none",
    },
});

export const ServiceLevelsBook = (props) => {
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    const [expandedSub, setExpandedSub] = useState(false);
    const handleChangeAccSub = (panel) => (event, isExpanded) => {
        setExpandedSub(isExpanded ? panel : false);
    };

    return (
        <StyledContainer>
            <Typography variant="h5" sx={{ pt: 2 }}>
                {t("proposals.scopeWorkPracticeB")}
            </Typography>
            <Box className={classes.serviceBody}>
                {
                    props.includeOnboarding && (
                        <>
                            <Accordion
                                defaultExpanded
                                TransitionProps={{ unmountOnExit: true }}
                                sx={{
                                    borderBottom: "3px solid #ff5968",
                                }}
                            >
                                <AccordionSummary aria-controls="panelAuxbh-content" id="panelAuxbh-header">
                                    <Typography variant="h4">Practice Onboarding</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Divider sx={{ mb: 2 }} />
                                    <Grid container spacing={3} alignItems="center">
                                        <Grid item xs={12} md={4}>
                                            <Typography variant="body1">{t("proposals.intelB")}</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={8}>
                                            <Typography variant="body1">{t("proposals.understandingProcessesB")}</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <Typography variant="body1">{t("proposals.designB")}</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={8}>
                                            <Typography variant="body1">{t("proposals.setUpWorkflowB")}</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <Typography variant="body1">{t("proposals.setUpBookB")}</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={8}>
                                            <Typography variant="body1">{t("proposals.appImplementationB")}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Accordion
                                        expanded={expandedSub === "Bookkeeper Model Set up"}
                                        onChange={handleChangeAccSub("Bookkeeper Model Set up")}
                                        TransitionProps={{
                                            unmountOnExit: true,
                                        }}
                                        sx={{ pt: 1 }}
                                    >
                                        <AccordionSummary aria-controls="panelAuxbh-content" id="panelAuxbh-header">
                                            <Grid container spacing={2} alignItems="center">
                                                <Grid item xs={12} md={4}>
                                                    <Typography variant="body1">{t("proposals.supportB")}</Typography>
                                                </Grid>
                                                <Grid item xs={12} md={8}>
                                                    <Typography variant="body1" sx={{ pl: "10px" }}>
                                                        {t("proposals.dediTeamB")}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Divider sx={{ mb: 2 }} />
                                            <Box sx={{ p: "0 30px" }}>
                                                <Grid container spacing={2} alignItems="center">
                                                    <Grid item xs={12} md={4}>
                                                        <Typography variant="body1">{t("proposals.capaB")}</Typography>
                                                    </Grid>
                                                    <Grid item xs={12} md={8}>
                                                        <Typography variant="body1">{t("proposals.meetVirtB")}</Typography>
                                                    </Grid>
                                                    <Grid item xs={12} md={4}>
                                                        <Typography variant="body1">{t("proposals.followB")}</Typography>
                                                    </Grid>
                                                    <Grid item xs={12} md={8}>
                                                        <Typography variant="body1">{t("proposals.acompUserB")}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                </AccordionDetails>
                            </Accordion>
                        </>
                    )
                }
                {
                    props.includeToolbox && (
                        <>
                            <Accordion
                                defaultExpanded
                                TransitionProps={{ unmountOnExit: true }}
                                sx={{
                                    borderBottom: "3px solid #ff5968",
                                }}
                            >
                                <AccordionSummary aria-controls="panelAuxbh-content" id="panelAuxbh-header">
                                    <Typography variant="h4">Practice Toolbox</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Divider sx={{ mb: 2 }} />
                                    <Grid container spacing={3} alignItems="center">
                                        <Grid item xs={12} md={4}>
                                            <Typography variant="body1">{t("proposals.custSuccManaB")}</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={8}>
                                            <Typography variant="body1">{t("proposals.custSuccManaDetB")}</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <Typography variant="body1">{t("proposals.gcaPlatB")}</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={8}>
                                            <Typography variant="body1">{t("proposals.gcaPlatDetB")}</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <Typography variant="body1">{t("proposals.virtMeetB")}</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={8}>
                                            <Typography variant="body1">{t("proposals.virtMeetDetB")}</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <Typography variant="body1">{t("proposals.writtenSupB")}</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={8}>
                                            <Typography variant="body1">{t("proposals.UnlimSupB")}</Typography>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </>
                    )
                }
            </Box>
        </StyledContainer >
    );
};
