/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

/** MUI icons */
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

/** MUI imports */
import { Box, Breadcrumbs, Card, CardActionArea, CardContent, Container, Grid, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

/** Component imports */
// import ProposalsDashboard from "./ProposalsDashboard";

/** Component styles */
const PREFIX = "Proposals";

const classes = {
    alertRoot: `${PREFIX}-alertRoot`,
    icon: `${PREFIX}-icon`,
    cardcontent: `${PREFIX}-cardcontent`,
    cardcontentTitle: `${PREFIX}-cardcontentTitle`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.alertRoot}`]: {
        borderRadius: 40,
        padding: "6px 22px",
    },

    [`& .${classes.icon}`]: {
        height: 20,
        fill: "green",
        paddingRight: 22,
    },

    [`& .${classes.cardcontent}`]: {
        height: 260,
        padding: "30px",
        borderRadius: 4,
        backgroundColor: "#fff",
    },

    [`& .${classes.cardcontentTitle}`]: {
        paddingTop: "8px",
        paddingBottom: "16px",
        fontSize: 25,
    },
}));

const Proposals = () => {
    const navigate = useNavigate();

    /** Internationalization i18n */
    const [t] = useTranslation("global");

    /** Component events */
    useEffect(() => {}, [t]);

    return (
        <StyledContainer component="main" sx={{ flexGrow: 1, p: 4, pt: 10, width: "100%" }}>
            <Stack sx={{ pb: 4 }}>
                <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small" sx={{ color: "#031851" }} />}
                    aria-label="breadcrumb"
                >
                    {
                        <Typography
                            key="1"
                            variant="h1"
                            color="primary"
                            sx={{
                                fontSize: 24,
                                fontWeight: 700,
                            }}
                        >
                            {t("proposals.proposals")}
                        </Typography>
                    }
                </Breadcrumbs>
            </Stack>
            <Container maxWidth="xl">
                <Grid container justifyContent="center" alignItems="center" spacing={4}>
                    <Grid item xs={12} md={4}>
                        <Card
                            elevation={0}
                            sx={{
                                borderRadius: 4,
                                boxShadow: "10px 10px 15px rgba(3,24,81,0.15)",
                            }}
                        >
                            <CardActionArea onClick={() => navigate("/proposals/prospects")}>
                                <CardContent
                                    className={classes.cardcontent}
                                    sx={{
                                        textAlign: "center",
                                    }}
                                >
                                    <Typography variant="h5" color="secondary" className={classes.cardcontentTitle}>
                                        {t("proposals.prospects")}
                                    </Typography>
                                    <AssignmentIndIcon sx={{ fontSize: "50px", mb: 2 }} />
                                    <Typography variant="h1">{t("proposals.prospectsDescription")}</Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Card
                            elevation={0}
                            sx={{
                                borderRadius: 4,
                                boxShadow: "10px 10px 15px rgba(3,24,81,0.15)",
                            }}
                        >
                            <CardActionArea onClick={() => navigate("/proposals/createProposal")}>
                                <CardContent
                                    className={classes.cardcontent}
                                    sx={{
                                        textAlign: "center",
                                    }}
                                >
                                    <Typography variant="h5" color="secondary" className={classes.cardcontentTitle}>
                                        {t("proposals.createProposal")}
                                    </Typography>
                                    <AddCircleIcon sx={{ fontSize: "50px", mb: 2 }} />
                                    <Typography variant="h1">{t("proposals.createDescription")}</Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Card
                            elevation={0}
                            sx={{
                                borderRadius: 4,
                                boxShadow: "10px 10px 15px rgba(3,24,81,0.15)",
                            }}
                        >
                            <CardActionArea onClick={() => navigate("/proposals/manageProposals")}>
                                <CardContent
                                    className={classes.cardcontent}
                                    sx={{
                                        textAlign: "center",
                                    }}
                                >
                                    <Typography variant="h5" color="secondary" className={classes.cardcontentTitle}>
                                        {t("proposals.manageProposals")}
                                    </Typography>
                                    <EventAvailableIcon sx={{ fontSize: "50px", mb: 2 }} />
                                    <Typography variant="h1">{t("proposals.manageDescription")}</Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                    {/* <Grid item xs={12}>
                        <ProposalsDashboard />
                    </Grid> */}
                </Grid>
            </Container>
        </StyledContainer>
    );
};

export default Proposals;
