/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import moment from "moment";

/** MUI icons */
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import CheckIcon from "@mui/icons-material/Check";
import WarningAmberIcon from "@mui/icons-material/Warning";
import CancelIcon from "@mui/icons-material/Cancel";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

/** MUI imports */
import {
    Grid,
    Table,
    TableHeaderRow,
    PagingPanel,
    Toolbar,
    SearchPanel,
    TableColumnVisibility,
} from "@devexpress/dx-react-grid-material-ui";
import { SearchState, IntegratedFiltering, PagingState, IntegratedPaging } from "@devexpress/dx-react-grid";
import { styled } from "@mui/material/styles";
import GridUI from "@mui/material/Grid";
import {
    Box,
    ListItem,
    ListItemText,
    Skeleton,
    Typography,
    FormControl,
    Select,
    InputLabel,
    MenuItem,
    OutlinedInput,
} from "@mui/material";

/** Components imports */
import Services from "../../Js/servicesInvoicing";
import Projects from "../../Js/projects";

/** Component styles */
const PREFIX = "Invoicing";

const classes = {
    tableStriped: `${PREFIX}-tableStriped`,
    tableHeader: `${PREFIX}-tableHeader`,
    tableBody: `${PREFIX}-tableBody`,
    pager: `${PREFIX}-pager`,
    spacing: `${PREFIX}-spacing`,
    toolbarSearch: `${PREFIX}-toolbarSearch`,
    searchInput: `${PREFIX}-searchInput`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.spacing}`]: {
        padding: "10px 0 10px 0",
    },

    [`& .${classes.tableStriped}`]: {
        "& tbody tr:nth-of-type(odd)": {
            backgroundColor: "rgba(47,49,144,0.1)",
        },
    },
    [`& .${classes.tableHeader}`]: {
        border: "none",
        backgroundColor: "rgba(47,49,144,0.3)",
        fontSize: "15px",
        fontWeight: 600,
        color: "#031851",
        "&:first-of-type": {
            borderRadius: "20px 0 0 0",
        },
        "&:last-child": {
            borderRadius: "0 20px 0 0",
        },
    },

    [`& .${classes.tableBody}`]: {
        border: "none",
        fontSize: "14px",
        fontWeight: 400,
        color: "#031851",
    },

    [`& .${classes.pager}`]: {
        "& .Pagination-button": {
            paddingTop: 0,
            paddingBottom: 0,
            color: "#031851",
            fontWeight: 600,
        },
        "& .Pagination-activeButton": {
            backgroundColor: "rgba(47,49,144,0.3)",
        },
    },

    [`& .${classes.toolbarSearch}`]: {
        border: "none !important",
        padding: "0 0 5px !important",
    },

    [`& .${classes.searchInput}`]: {
        color: "#031851",
        "&:before": {
            borderBottomColor: "#031851",
        },
        "&:hover:before": {
            borderBottomColor: "#031851 !important",
        },
    },
}));

const tableHeaderCell = ({ ...props }) => <TableHeaderRow.Cell {...props} className={classes.tableHeader} />;

const TableComponent = ({ ...props }) => <Table.Table {...props} className={classes.tableStriped} />;

const TableCellComponent = ({ ...props }) => <Table.Cell {...props} className={classes.tableBody} />;

const tableContentComponent = (props) => <TableHeaderRow.Content {...props} />;

const PagingPanelContainer = ({ ...props }) => <PagingPanel.Container {...props} className={classes.pager} />;

const inputComponent = ({ ...props }) => <SearchPanel.Input {...props} className={classes.searchInput} />;

const toolRootComponent = ({ ...props }) => <Toolbar.Root {...props} className={classes.toolbarSearch} />;

const tableColumnExtensions = [
    { columnName: "invoice" },
    { columnName: "market", align: "center" },
    { columnName: "invoiceDate", width: "180px", align: "center" },
    { columnName: "contact", width: "280px" },
    { columnName: "franchise", width: "200px" },
    { columnName: "practice", width: "200px" },
    { columnName: "smb", width: "280px" },
    { columnName: "service", width: "200px" },
    { columnName: "project", width: "200px" },
    { columnName: "period", width: "180px", align: "center" },
    { columnName: "creationDate", width: "180px", align: "center" },
    { columnName: "amount", width: "120px", align: "right" },
    { columnName: "lineItemAmount", width: "140px", align: "right" },
    { columnName: "pending", width: "150px", align: "right" },
    { columnName: "paid", width: "120px", align: "right" },
    { columnName: "sentDate", width: "220px", align: "center" },
    { columnName: "paymentDate", width: "220px", align: "center" },
    { columnName: "voidDate", width: "220px", align: "center" },
    { columnName: "status", width: "280px", align: "center" },
    { columnName: "voidReason", width: "280px", align: "center" },
    { columnName: "voidReasonDesc", width: "280px", align: "center" },
];

const summarizedTableColumnExtensions = [
    { columnName: "invoice", width: "80px", align: "center" },
    { columnName: "contact" },
    { columnName: "invoiceDate", width: "180px", align: "center" },
    { columnName: "amount", width: "120px", align: "right" },
    { columnName: "paid", width: "120px", align: "right" },
    { columnName: "pending", width: "150px", align: "right" },
    { columnName: "sentDate", width: "220px", align: "center" },
    { columnName: "paymentDate", width: "220px", align: "center" },
    { columnName: "status", width: "300px", align: "center" },
];

const tableColumnExtensionsExpnesesSummarized = [
    { columnName: "franchise", width: "180px", align: "center" },
    { columnName: "group", width: "180px", align: "center" },
    { columnName: "clientRS", width: "320px" },
    { columnName: "status" },
    { columnName: "month", width: "140px", align: "center" },
    { columnName: "realExpense", width: "180px" },
    { columnName: "expenseAverage", width: "180px" },
    { columnName: "expenseScale", width: "180px" },
];

const tableColumnExtensionsExpensesDetailed = [
    { columnName: "franchise", width: "180px", align: "center" },
    { columnName: "group", width: "180px", align: "center" },
    { columnName: "clientRS", width: "320px" },
    { columnName: "status" },
    { columnName: "account", width: "180px", align: "center" },
    { columnName: "january", width: "120px" },
    { columnName: "february", width: "120px" },
    { columnName: "march", width: "120px" },
    { columnName: "april", width: "120px" },
    { columnName: "may", width: "120px" },
    { columnName: "june", width: "120px" },
    { columnName: "july", width: "120px" },
    { columnName: "august", width: "120px" },
    { columnName: "september", width: "120px" },
    { columnName: "october", width: "120px" },
    { columnName: "november", width: "120px" },
    { columnName: "december", width: "120px" },
];

const rowsSkeletonDetailed = [];
const summarizedRowsSkeleton = [];
const rowsSkeletonExpensesDetailed = [];
const rowsSkeletonExpensesSummarized = [];

for (let i = 0; i < 10; i++) {
    rowsSkeletonDetailed.push({
        id: i,
        documentType: <Skeleton variant="text" animation="wave" />,
        invoice: <Skeleton variant="text" animation="wave" />,
        market: <Skeleton variant="text" animation="wave" />,
        invoiceDate: <Skeleton variant="text" animation="wave" />,
        contact: <Skeleton variant="text" animation="wave" />,
        franchise: <Skeleton variant="text" animation="wave" />,
        practice: <Skeleton variant="text" animation="wave" />,
        smb: <Skeleton variant="text" animation="wave" />,
        service: <Skeleton variant="text" animation="wave" />,
        project: <Skeleton variant="text" animation="wave" />,
        period: <Skeleton variant="text" animation="wave" />,
        creationDate: <Skeleton variant="text" animation="wave" />,
        amount: <Skeleton variant="text" animation="wave" />,
        lineItemAmount: <Skeleton variant="text" animation="wave" />,
        pending: <Skeleton variant="text" animation="wave" />,
        paid: <Skeleton variant="text" animation="wave" />,
        sentDate: <Skeleton variant="text" animation="wave" />,
        paymentDate: <Skeleton variant="text" animation="wave" />,
        voidDate: <Skeleton variant="text" animation="wave" />,
        status: <Skeleton variant="text" animation="wave" />,
        voidReason: <Skeleton variant="text" animation="wave" />,
        voidReasonDesc: <Skeleton variant="text" animation="wave" />,
    });

    summarizedRowsSkeleton.push({
        id: i,
        invoice: <Skeleton variant="text" animation="wave" />,
        contact: <Skeleton variant="text" animation="wave" />,
        invoiceDate: <Skeleton variant="text" animation="wave" />,
        amount: <Skeleton variant="text" animation="wave" />,
        paid: <Skeleton variant="text" animation="wave" />,
        pending: <Skeleton variant="text" animation="wave" />,
        sentDate: <Skeleton variant="text" animation="wave" />,
        paymentDate: <Skeleton variant="text" animation="wave" />,
        status: <Skeleton variant="text" animation="wave" />,
    });

    rowsSkeletonExpensesSummarized.push({
        id: i,
        franchise: <Skeleton variant="text" animation="wave" />,
        group: <Skeleton variant="text" animation="wave" />,
        clientRS: <Skeleton variant="text" animation="wave" />,
        status: <Skeleton variant="text" animation="wave" />,
        month: <Skeleton variant="text" animation="wave" />,
        realExpense: <Skeleton variant="text" animation="wave" />,
        expenseAverage: <Skeleton variant="text" animation="wave" />,
        expenseScale: <Skeleton variant="text" animation="wave" />,
    });

    rowsSkeletonExpensesDetailed.push({
        id: i,
        franchise: <Skeleton variant="text" animation="wave" />,
        group: <Skeleton variant="text" animation="wave" />,
        clientRS: <Skeleton variant="text" animation="wave" />,
        status: <Skeleton variant="text" animation="wave" />,
        account: <Skeleton variant="text" animation="wave" />,
        january: <Skeleton variant="text" animation="wave" />,
        february: <Skeleton variant="text" animation="wave" />,
        march: <Skeleton variant="text" animation="wave" />,
        april: <Skeleton variant="text" animation="wave" />,
        may: <Skeleton variant="text" animation="wave" />,
        june: <Skeleton variant="text" animation="wave" />,
        july: <Skeleton variant="text" animation="wave" />,
        august: <Skeleton variant="text" animation="wave" />,
        september: <Skeleton variant="text" animation="wave" />,
        october: <Skeleton variant="text" animation="wave" />,
        november: <Skeleton variant="text" animation="wave" />,
        december: <Skeleton variant="text" animation="wave" />,
    });
};

const Invoicing = ({ reportSelected, setReportSelected, selectedYear, setSelectedYear, }) => {
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    const tableMessages = {
        noData: t("miscellaneous.noDataReports"),
    };
    const pagingMessages = {
        info: ({ from, to, count }) => `${from}${from < to ? `-${to}` : ""} ${t("miscellaneous.pagingOf")} ${count}`,
    };

    const noColumnsMessage = {
        noColumns: "",
    };

    const searchMessages = {
        searchPlaceholder: t("miscellaneous.search"),
    };

    /** React redux */
    const todos = useSelector((state) => state.todos);

    const reportTypeList = [
        { id: 1, data: t("invoicing.invoiceSummarized") },
        { id: 0, data: t("invoicing.invoiceDetailed") },
        { id: 2, data: t("invoicing.expensesSummarized") },
        { id: 3, data: t("invoicing.detailedExpenses") },
    ];

    /** Component states */
    const [invoicesData, setInvoicesData] = useState([]);
    const [columnDefs, setColumnsDefs] = useState([]);
    const [searchValue, setSearchState] = useState("");
    const [defaultHiddenColumnNames] = useState(["hiddenService", "hiddenProject"]);

    const yearList = [];

    for (let i = 2022; i <= new Date().getFullYear(); i++) {
        yearList.push(i);
    };

    /** Component functions */
    const handleSearch = (value) => {
        let itemS = value.replace(/ +(?= )/g, "");
        setSearchState(itemS);
    };

    const handleChangeReportType = (event) => {
        event.preventDefault();
        setReportSelected(event.target.value);
    };

    const handleChangeYearSelected = (event) => {
        event.preventDefault();
        setSelectedYear(event.target.value);
    };

    const loadInitData = async () => {
        if (reportSelected === 0 || reportSelected === 1) {
            await fetch(`/getInvoicesReportInfo?reportType=${reportSelected}`, {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
                .then((res) => res.json())
                .then((data) => {
                    let resultTable = data.map((data) => {
                        return {
                            hiddenService: Services.find((service) => service.code === data.service_type).name,
                            hiddenProject: Projects.find((project) => project.code === data.project_type).name,
                            documentType: data.document_type === 1 ? t("miscellaneous.invoice") : t("invoicing.creditNote"),
                            market: data.market ? data.market : "",
                            voidDate:
                                data.invoice_void_date !== undefined && data.invoice_void_date !== null ? (
                                    moment(data.invoice_void_date, "YYYY-MM-DD").format(todos.dateFormat)
                                ) : (
                                    <Typography sx={{ textAlign: "center" }}>-</Typography>
                                ),
                            franchise:
                                data.franchise_name === null ||
                                    data.franchise_name === undefined ||
                                    data.franchise_name === "" ? (
                                    <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                                ) : (
                                    data.franchise_name
                                ),
                            practice:
                                data.practice_name === null ||
                                    data.practice_name === undefined ||
                                    data.practice_name === "" ? (
                                    <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                                ) : (
                                    data.practice_name
                                ),
                            smb: data.smb_name,
                            creationDate:
                                data.line_item_date !== undefined && data.line_item_date !== null
                                    ? moment(data.line_item_date, "YYYY-MM-DD").format(todos.dateFormat)
                                    : "-",
                            service: (
                                <Typography variant="body1" component="span" align="center" color="textPrimary">
                                    {t(`invoicing.${Services.find((service) => service.code === data.service_type).name}`)}
                                </Typography>
                            ),
                            project: (
                                <Typography variant="body1" component="span" align="center" color="textPrimary">
                                    {t(`invoicing.${Projects.find((project) => project.code === data.project_type).name}`)}
                                </Typography>
                            ),
                            period:
                                data.end_date !== undefined &&
                                    data.end_date !== null &&
                                    moment(data.end_date, "YYYY-MM-DD").locale(t("language.locale")).format("MMM YY") !==
                                    moment(data.initial_date, "YYYY-MM-DD").locale(t("language.locale")).format("MMM YY")
                                    ? `${(
                                        moment(data.initial_date, "YYYY-MM-DD")
                                            .locale(t("language.locale"))
                                            .format("MMM")
                                            .charAt(0)
                                            .toUpperCase() +
                                        moment(data.initial_date, "YYYY-MM-DD")
                                            .locale(t("language.locale"))
                                            .format("MMM YY")
                                            .slice(1)
                                    ).replace(".", "")} - ${(
                                        moment(data.end_date, "YYYY-MM-DD")
                                            .locale(t("language.locale"))
                                            .format("MMM")
                                            .charAt(0)
                                            .toUpperCase() +
                                        moment(data.end_date, "YYYY-MM-DD")
                                            .locale(t("language.locale"))
                                            .format("MMM YY")
                                            .slice(1)
                                    ).replace(".", "")}`
                                    : data.initial_date !== undefined && data.initial_date !== null
                                        ? (
                                            moment(data.initial_date, "YYYY-MM-DD")
                                                .locale(t("language.locale"))
                                                .format("MMM")
                                                .charAt(0)
                                                .toUpperCase() +
                                            moment(data.initial_date, "YYYY-MM-DD")
                                                .locale(t("language.locale"))
                                                .format("MMM YY")
                                                .slice(1)
                                        ).replace(".", "")
                                        : "-",
                            lineItemAmount: parseFloat(data.amount).toLocaleString(todos.amountFormat, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }),
                            sentDate:
                                data.invoice_sent_date !== undefined && data.invoice_sent_date !== null
                                    ? moment(data.invoice_sent_date, "YYYY-MM-DD").format(todos.dateFormat)
                                    : "-",
                            paymentDate:
                                data.invoice_payment_date !== undefined && data.invoice_payment_date !== null
                                    ? moment(data.invoice_payment_date, "YYYY-MM-DD").format(todos.dateFormat)
                                    : "-",
                            voidReason: data.void_reason ?? "-",
                            voidReasonDesc: data.void_reason_description ?? "-",
                            id: data._id,
                            invoice: data.invoice_number,
                            contact: data.contact_name,
                            invoiceDate:
                                data.invoice_date !== undefined && data.invoice_date !== null ? (
                                    moment(data.invoice_date, "YYYY-MM-DD").format(todos.dateFormat)
                                ) : (
                                    <Typography sx={{ textAlign: "center" }}>-</Typography>
                                ),
                            amount: parseFloat(data.invoice_total_amount).toLocaleString(todos.amountFormat, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }),
                            pending: parseFloat(data.invoice_due_amount).toLocaleString(todos.amountFormat, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }),
                            paid: parseFloat(data.invoice_paid_amount).toLocaleString(todos.amountFormat, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }),
                            status: (
                                <ListItem
                                    alignItems="center"
                                    secondaryAction={
                                        data.id_status === 1 ? (
                                            <AccessTimeFilledIcon color="secondary" />
                                        ) : data.id_status === 2 ? (
                                            <WarningAmberIcon sx={{ color: "#FFB039" }} />
                                        ) : data.id_status === 3 ? (
                                            <CheckIcon sx={{ color: "green" }} />
                                        ) : (
                                            <CancelIcon color="secondary" />
                                        )
                                    }
                                    sx={{ width: { xs: "100%", lg: "80%", xl: "60%" }, ml: "auto", mr: "auto" }}
                                >
                                    <ListItemText
                                        primary={
                                            data.id_status === 1
                                                ? t("invoicing.pending")
                                                : data.id_status === 2
                                                    ? t("invoicing.partial")
                                                    : data.id_status === 3
                                                        ? t("invoicing.paid")
                                                        : data.id_status === 4
                                                            ? t("invoicing.voided")
                                                            : data.id_status === 5
                                                                ? t("invoicing.noInvoice")
                                                                : t("invoicing.pending")
                                        }
                                    />
                                </ListItem>
                            ),
                        };
                    });
                    setInvoicesData(resultTable);
                });
        } else {
            await fetch(`/getExpensesReportInfo?year=${selectedYear}&reportType=${reportSelected}`, {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
                .then((res) => res.json())
                .then((data) => {
                    let resultTable = []
                    data.forEach((expense, index) => {
                        let obj = {};
                        if (reportSelected === 2) {
                            const keys = Object.keys(expense.expensesData);
                            keys.forEach((month) => {
                                obj = {
                                    id: index,
                                    franchise:
                                        expense.clientData.franchise_name === undefined ||
                                            expense.clientData.franchise_name === null ||
                                            expense.clientData.franchise_name === "" ? (
                                            <Typography>N/A</Typography>
                                        ) : (
                                            expense.clientData.franchise_name
                                        ),
                                    group:
                                        expense.clientData.practice_name === undefined ||
                                            expense.clientData.practice_name === null ||
                                            expense.clientData.practice_name === "" ? (
                                            <Typography>N/A</Typography>
                                        ) : (
                                            expense.clientData.practice_name
                                        ),
                                    clientRS:
                                        expense.clientData.company_name === undefined ||
                                            expense.clientData.company_name === null ||
                                            expense.clientData.company_name === "" ? (
                                            <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                                        ) : (
                                            expense.clientData.company_name
                                        ),
                                    status:
                                        expense.clientData.status === undefined || expense.clientData.status === null || expense.clientData.status === "" ? (
                                            <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                                        ) : (
                                            t(`dynamicLabels.${expense.clientData.status.toLowerCase()}`)
                                        ),
                                    month:
                                        expense.expensesData[month].month === undefined ||
                                            expense.expensesData[month].month === null ||
                                            expense.expensesData[month].month === "" ? (
                                            <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                                        ) : (
                                            expense.expensesData[month].month
                                        ),
                                    realExpense:
                                        expense.expensesData[month].realExpense === undefined ||
                                            expense.expensesData[month].realExpense === null ||
                                            expense.expensesData[month].realExpense === "" ||
                                            expense.expensesData[month].realExpense === "-" ||
                                            expense.expensesData[month].realExpense === -1 ? (
                                            <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                                        ) :
                                            (
                                                parseFloat(expense.expensesData[month].realExpense).toLocaleString(todos.amountFormat)
                                            ),
                                    expenseAverage: expense.expensesData[month].expenseAverage === undefined ||
                                        expense.expensesData[month].expenseAverage === null ||
                                        expense.expensesData[month].expenseAverage === "" ||
                                        expense.expensesData[month].expenseAverage === -1 ? (
                                        <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                                    ) : (
                                        parseFloat(expense.expensesData[month].expenseAverage).toLocaleString(todos.amountFormat)
                                    ),
                                    expenseScale: expense.expensesData[month].expenseScale === undefined ||
                                        expense.expensesData[month].expenseScale === null ||
                                        expense.expensesData[month].expenseScale === "" ||
                                        expense.expensesData[month].expenseScale === 0 ? (
                                        <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                                    ) : (
                                        parseFloat(expense.expensesData[month].expenseScale).toLocaleString(todos.amountFormat)
                                    ),
                                };
                                resultTable.push(obj);
                            });
                        } else {
                            obj = {
                                id: index,
                                franchise:
                                    expense.clientData.franchise_name === undefined ||
                                        expense.clientData.franchise_name === null ||
                                        expense.clientData.franchise_name === "" ? (
                                        <Typography>N/A</Typography>
                                    ) : (
                                        expense.clientData.franchise_name
                                    ),
                                group:
                                    expense.clientData.practice_name === undefined ||
                                        expense.clientData.practice_name === null ||
                                        expense.clientData.practice_name === "" ? (
                                        <Typography>N/A</Typography>
                                    ) : (
                                        expense.clientData.practice_name
                                    ),
                                clientRS:
                                    expense.clientData.company_name === undefined ||
                                        expense.clientData.company_name === null ||
                                        expense.clientData.company_name === "" ? (
                                        <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                                    ) : (
                                        expense.clientData.company_name
                                    ),
                                status:
                                    expense.clientData.status === undefined || expense.clientData.status === null || expense.clientData.status === "" ? (
                                        <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                                    ) : (
                                        t(`dynamicLabels.${expense.clientData.status.toLowerCase()}`)
                                    ),
                                account: expense.clientData.account_name === undefined ||
                                    expense.clientData.account_name === null ||
                                    expense.clientData.account_name === "" ? (
                                    <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                                ) : (
                                    expense.clientData.account_name
                                ),
                                january: expense.expensesData["01"].realExpense === undefined ||
                                    expense.expensesData["01"].realExpense === null ||
                                    expense.expensesData["01"].realExpense === "" ? (
                                    <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                                ) : (
                                    expense.expensesData["01"].realExpense.toLocaleString(todos.amountFormat)
                                ),
                                february: expense.expensesData["02"].realExpense === undefined ||
                                    expense.expensesData["02"].realExpense === null ||
                                    expense.expensesData["02"].realExpense === "" ? (
                                    <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                                ) : (
                                    expense.expensesData["02"].realExpense.toLocaleString(todos.amountFormat)
                                ),
                                march: expense.expensesData["03"].realExpense === undefined ||
                                    expense.expensesData["03"].realExpense === null ||
                                    expense.expensesData["03"].realExpense === "" ? (
                                    <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                                ) : (
                                    expense.expensesData["03"].realExpense.toLocaleString(todos.amountFormat)
                                ),
                                april: expense.expensesData["04"].realExpense === undefined ||
                                    expense.expensesData["04"].realExpense === null ||
                                    expense.expensesData["04"].realExpense === "" ? (
                                    <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                                ) : (
                                    expense.expensesData["04"].realExpense.toLocaleString(todos.amountFormat)
                                ),
                                may: expense.expensesData["05"].realExpense === undefined ||
                                    expense.expensesData["05"].realExpense === null ||
                                    expense.expensesData["05"].realExpense === "" ? (
                                    <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                                ) : (
                                    expense.expensesData["05"].realExpense.toLocaleString(todos.amountFormat)
                                ),
                                june: expense.expensesData["06"].realExpense === undefined ||
                                    expense.expensesData["06"].realExpense === null ||
                                    expense.expensesData["06"].realExpense === "" ? (
                                    <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                                ) : (
                                    expense.expensesData["06"].realExpense.toLocaleString(todos.amountFormat)
                                ),
                                july: expense.expensesData["07"].realExpense === undefined ||
                                    expense.expensesData["07"].realExpense === null ||
                                    expense.expensesData["07"].realExpense === "" ? (
                                    <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                                ) : (
                                    expense.expensesData["07"].realExpense.toLocaleString(todos.amountFormat)
                                ),
                                august: expense.expensesData["08"].realExpense === undefined ||
                                    expense.expensesData["08"].realExpense === null ||
                                    expense.expensesData["08"].realExpense === "" ? (
                                    <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                                ) : (
                                    expense.expensesData["08"].realExpense.toLocaleString(todos.amountFormat)
                                ),
                                september: expense.expensesData["09"].realExpense === undefined ||
                                    expense.expensesData["09"].realExpense === null ||
                                    expense.expensesData["09"].realExpense === "" ? (
                                    <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                                ) : (
                                    expense.expensesData["09"].realExpense.toLocaleString(todos.amountFormat)
                                ),
                                october: expense.expensesData["10"].realExpense === undefined ||
                                    expense.expensesData["10"].realExpense === null ||
                                    expense.expensesData["10"].realExpense === "" ? (
                                    <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                                ) : (
                                    expense.expensesData["10"].realExpense.toLocaleString(todos.amountFormat)
                                ),
                                november: expense.expensesData["11"].realExpense === undefined ||
                                    expense.expensesData["11"].realExpense === null ||
                                    expense.expensesData["11"].realExpense === "" ? (
                                    <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                                ) : (
                                    expense.expensesData["11"].realExpense.toLocaleString(todos.amountFormat)
                                ),
                                december: expense.expensesData["12"].realExpense === undefined ||
                                    expense.expensesData["12"].realExpense === null ||
                                    expense.expensesData["12"].realExpense === "" ? (
                                    <Typography sx={{ marginLeft: "25%" }}>-</Typography>
                                ) : (
                                    expense.expensesData["12"].realExpense.toLocaleString(todos.amountFormat)
                                ),
                            };
                            resultTable.push(obj);
                        };
                    });
                    setInvoicesData(resultTable);
                });
        }

    };

    /* Component events */
    useEffect(() => {
        (async () => {
            const columnsTemplateDetailed = [
                { name: "hiddenService", title: "Hidden Service" },
                { name: "hiddenProject", title: "Hidden Project" },
                { name: "documentType", title: t("invoicing.documentType") },
                { name: "invoice", title: t("invoicing.number") },
                { name: "market", title: t("proposals.market") },
                { name: "invoiceDate", title: t("invoicing.creationDate") },
                { name: "contact", title: t("invoicing.invoicingContact") },
                { name: "franchise", title: t("dynamicLabels.franquicia") },
                { name: "practice", title: t("dynamicLabels.grupo") },
                { name: "smb", title: t("miscellaneous.smb") },
                { name: "status", title: t("miscellaneous.status") },
                { name: "amount", title: t("invoicing.amount") },
                { name: "paid", title: t("invoicing.paid") },
                { name: "pending", title: t("recurrent.pend") },
                { name: "sentDate", title: t("invoicing.sentDate") },
                { name: "paymentDate", title: t("invoicing.fullPaymentDate") },
                { name: "service", title: t("miscellaneous.service") },
                { name: "project", title: t("invoicing.project") },
                { name: "period", title: t("invoicing.period") },
                { name: "creationDate", title: t("invoicing.recordCreationDate") },
                { name: "lineItemAmount", title: t("invoicing.lineItemAmount") },
                { name: "voidDate", title: t("invoicing.cancellationDate") },
                { name: "voidReason", title: t("invoicing.voidReason") },
                { name: "voidReasonDesc", title: t("invoicing.description") },
            ];

            const summarizedColumnsTemplate = [
                { name: "invoice", title: t("invoicing.invoiceNumber") },
                { name: "contact", title: t("invoicing.invoicingContact") },
                { name: "invoiceDate", title: t("invoicing.creationDate") },
                { name: "amount", title: t("invoicing.amount") },
                { name: "paid", title: t("invoicing.paid") },
                { name: "pending", title: t("recurrent.pend") },
                { name: "sentDate", title: t("invoicing.sentDate") },
                { name: "paymentDate", title: t("invoicing.fullPaymentDate") },
                { name: "status", title: t("miscellaneous.status") },
            ];

            const columnsTemplate = [
                { name: "franchise", title: t("miscellaneous.franchise") },
                { name: "group", title: t("miscellaneous.group") },
                { name: "clientRS", title: t("miscellaneous.businessName") },
                { name: "status", title: t("miscellaneous.status") },
                { name: "month", title: t("bankingDashboard.month") },
                { name: "realExpense", title: t("currentView.realExpense") },
                { name: "expenseAverage", title: t("currentView.expenseAvg") },
                { name: "expenseScale", title: t("miscellaneous.expenseScale") },
            ];

            const columnsTemplate2 = [
                { name: "franchise", title: t("miscellaneous.franchise") },
                { name: "group", title: t("miscellaneous.group") },
                { name: "clientRS", title: t("miscellaneous.businessName") },
                { name: "status", title: t("miscellaneous.status") },
                { name: "account", title: t("miscellaneous.bank2") },
                { name: "january", title: t("monthNames.january") },
                { name: "february", title: t("monthNames.february") },
                { name: "march", title: t("monthNames.march") },
                { name: "april", title: t("monthNames.april") },
                { name: "may", title: t("monthNames.may") },
                { name: "june", title: t("monthNames.june") },
                { name: "july", title: t("monthNames.july") },
                { name: "august", title: t("monthNames.august") },
                { name: "september", title: t("monthNames.september") },
                { name: "october", title: t("monthNames.october") },
                { name: "november", title: t("monthNames.november") },
                { name: "december", title: t("monthNames.december") },
            ];

            if (reportSelected === 1) {
                setColumnsDefs(summarizedColumnsTemplate);
                setInvoicesData(summarizedRowsSkeleton);
            } else if (reportSelected === 0) {
                setColumnsDefs(columnsTemplateDetailed);
                setInvoicesData(rowsSkeletonDetailed);
            } else if (reportSelected === 2) {
                setColumnsDefs(columnsTemplate);
                setInvoicesData(rowsSkeletonExpensesSummarized);
            } else {
                setColumnsDefs(columnsTemplate2);
                setInvoicesData(rowsSkeletonExpensesDetailed);
            };

            await loadInitData();
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t, todos.dateFormat, todos.amountFormat, reportSelected, selectedYear]);

    return (
        <StyledContainer component="main" sx={{ flexGrow: 1, width: "100%" }}>
            <GridUI container justifyContent="left" alignItems="center">
                <GridUI item xs={12} sm={6} md={2} sx={{ mb: { sm: -5 }, zIndex: 1 }}>
                    <FormControl fullWidth>
                        <InputLabel shrink id="type-simple-select-label">
                            {t("invoicing.reportType")}
                        </InputLabel>
                        <Select
                            size="small"
                            IconComponent={KeyboardArrowDownIcon}
                            value={reportSelected}
                            onChange={handleChangeReportType}
                            input={<OutlinedInput notched label={t("invoicing.reportType")} />}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        mt: 0.7,
                                        borderRadius: 2,
                                        boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                    },
                                },
                            }}
                        >
                            {reportTypeList.map((report) => (
                                <MenuItem value={report.id}>{report.data}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </GridUI>
                {
                    reportSelected === 2 || reportSelected === 3 ?
                        (<GridUI item xs={12} sm={6} md={1} sx={{ mb: { sm: -5 }, zIndex: 1, ml: 2 }}>
                            <FormControl fullWidth>
                                <InputLabel shrink id="type-simple-select-label">
                                    {t("miscellaneous.year")}
                                </InputLabel>
                                <Select
                                    size="small"
                                    IconComponent={KeyboardArrowDownIcon}
                                    value={selectedYear}
                                    onChange={handleChangeYearSelected}
                                    input={<OutlinedInput notched label={t("miscellaneous.year")} />}
                                    MenuProps={{
                                        PaperProps: {
                                            sx: {
                                                mt: 0.7,
                                                borderRadius: 2,
                                                boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                            },
                                        },
                                    }}
                                >
                                    {yearList.map((year) => (
                                        <MenuItem value={year}>{year}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </GridUI>) : null
                }
                <GridUI item xs={12}>
                    <Grid rows={invoicesData} columns={columnDefs}>
                        <SearchState value={searchValue} onValueChange={handleSearch} />
                        <IntegratedFiltering />
                        <PagingState defaultCurrentPage={0} pageSize={10} />
                        <IntegratedPaging />
                        <Table
                            tableComponent={TableComponent}
                            columnExtensions={
                                reportSelected === 0 ?
                                    tableColumnExtensions : reportSelected === 1 ?
                                        summarizedTableColumnExtensions : reportSelected === 2 ?
                                            tableColumnExtensionsExpnesesSummarized : tableColumnExtensionsExpensesDetailed
                            }
                            cellComponent={TableCellComponent}
                            messages={tableMessages}
                        />
                        <TableColumnVisibility
                            defaultHiddenColumnNames={defaultHiddenColumnNames}
                            messages={noColumnsMessage}
                        />
                        <TableHeaderRow cellComponent={tableHeaderCell} contentComponent={tableContentComponent} />
                        <Toolbar rootComponent={toolRootComponent} />
                        <SearchPanel messages={searchMessages} inputComponent={inputComponent} />
                        <PagingPanel containerComponent={PagingPanelContainer} messages={pagingMessages} />
                    </Grid>
                </GridUI>
            </GridUI>
        </StyledContainer>
    );
};

export default Invoicing;
