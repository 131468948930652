/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';

/** MUI icons */

/** MUI imports */
import { styled } from '@mui/material/styles';
import {
    Box,
    Button
} from '@mui/material';
import GridUI from "@mui/material/Grid";
import { Grid, Table, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
// import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

/** Components imports */
import Backdrop from '../Backdrop';

const PREFIX = 'FilePreview';

const classes = {
    formRounded: `${PREFIX}-formRounded`,
    btnRoot: `${PREFIX}-btnRoot`,
    tableStriped: `${PREFIX}-tableStriped`,
    tableHeader: `${PREFIX}-tableHeader`,
    tableBody: `${PREFIX}-tableBody`,
    btnRoot2: `${PREFIX}-btnRoot2`,
};

const CustomBox = styled(Box)(({ theme }) => ({
    [`& .${classes.formRounded}`]: {
        backgroundColor: '#E9EAE8 !important',
        borderRadius: 40,
        verticalAlign: 'middle !important',
        "&:focus-within": {
            backgroundColor: "#E9EAE8",
            borderRadius: 40,
        },
        "& .MuiOutlinedInput-notchedOutline": {
            border: '2px solid #031851',
            borderRadius: 40,
        },
    },
    [`& .${classes.btnRoot}`]: {
        border: '1px solid #ff5968',
        borderRadius: 40,
        '&:hover': {
            backgroundColor: '#2f3190',
            border: '1px solid #2f3190',
        },
    },
    [`& .${classes.btnRoot2}`]: {
        border: '1px solid #ff5968',
        borderRadius: 40,
        '&:hover': {
            backgroundColor: 'white',
            color: '#2f3190',
            border: '1px solid #2f3190',
        },
    },
    [`& .${classes.tableStriped}`]: {
        '& tbody tr:nth-of-type(odd)': {
            backgroundColor: 'rgba(47,49,144,0.1)',
        },
    },
    [`& .${classes.tableHeader}`]: {
        border: 'none',
        backgroundColor: 'rgba(47,49,144,0.3)',
        fontSize: '15px',
        fontWeight: 600,
        color: '#031851',
        '&:first-of-type': {
            borderRadius: '20px 0 0 0',
        },
        '&:last-child': {
            borderRadius: '0 20px 0 0',
            paddingRight: '24px'
        }
    },

    [`& .${classes.tableBody}`]: {
        border: 'none',
        fontSize: '14px',
        fontWeight: 400,
        color: '#031851',
    },
}));

// const LightTooltip = styled(({ className, ...props }) => (
//     <Tooltip {...props} classes={{ popper: className }} />
// ))({
//     [`& .${tooltipClasses.tooltip}`]: {
//         backgroundColor: 'rgba(19, 31, 62, 0.80)',
//         fontSize: 12,
//         maxWidth: 320,
//         borderRadius: 6,
//         fontWeight: 'normal'
//     },
// });

const tableHeaderCell = ({ ...props }) => (
    <TableHeaderRow.Cell
        {...props}
        className={classes.tableHeader}
    />
);

const TableComponent = ({ ...props }) => (
    <Table.Table
        {...props}
        className={classes.tableStriped}
    />
);

const TableCellComponent = ({ ...props }) => (
    <Table.Cell
        {...props}
        className={classes.tableBody}
    />
);

const FilePreview = (props) => {
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    const tableMessages = {
        noData: t("miscellaneous.noData")
    };

    const [showBackdrop, setBackDrop] = useState(false);
    const [rowsData, setRowsData] = useState([]);
    const [columnDefs, setColumnsDefs] = useState([]);

    /** Component events */
    useEffect(() => {
        setBackDrop(true);

        let recordsdata = [];
        const columnsTemplate = [
            { name: 'source_header', title: "Encabezado de la fuente" },
            { name: 'first_reg', title: "Primer registro" },
            { name: 'xero_field', title: "Campo en Xero" },
        ];

        setColumnsDefs(columnsTemplate);

        props.matchData.records[0].forEach((source, index) => {
            props.matchData.records[1].forEach((reg, secondIndex) => {
                if (index === secondIndex) {
                    recordsdata.push({
                        source_header: source,
                        first_reg: reg
                    });
                }
            });
        });

        setRowsData(recordsdata);

        setBackDrop(false);
    }, [props.matchData.records, t]);

    return (
        <CustomBox component="main">
            <GridUI container spacing={2} sx={{ pt: 6 }}>
                <GridUI item xs={12}>
                    <Grid
                        rows={rowsData}
                        columns={columnDefs}
                    >
                        <Table
                            tableComponent={TableComponent}
                            cellComponent={TableCellComponent}
                            messages={tableMessages}
                        />
                        <TableHeaderRow
                            cellComponent={tableHeaderCell}
                        />
                    </Grid>
                    <Box sx={{ textAlign: "right", pt: 2 }}>
                        <Button
                            sx={{
                                mr: 2,
                                borderRadius: 40
                            }}
                            disableElevation
                            onClick={props.handleReset}
                        >Cancelar</Button>
                        <Button
                            sx={{
                                mr: 2,
                            }}
                            color="secondary"
                            disableElevation
                            variant="outlined"
                            classes={{ root: classes.btnRoot2 }}
                            onClick={props.handleBack}
                        >Volver</Button>
                        <Button
                            color="secondary"
                            variant="contained"
                            disableElevation
                            classes={{ root: classes.btnRoot }}
                            onClick={props.handleNext}
                        >Cargar</Button>
                    </Box>
                </GridUI>
            </GridUI>
            <Backdrop
                open={showBackdrop}
            />
        </CustomBox>
    );
};

export default FilePreview;