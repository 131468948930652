/** React imports */
import React from "react";
import { useTranslation } from "react-i18next";

/** Images */

/** MUI icons */

/** MUI imports */
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { DescriptionIconContainer } from "../AccountantProposal/DescriptionIconContainer";

/** Global variables */
const PREFIX = "ScheduleBook";

const classes = {
    body2: `${PREFIX}-body2`,
    scheduleTitle: `${PREFIX}-scheduleTitle`,
    scheduleBox: `${PREFIX}-scheduleBox`,
    fieldset: `${PREFIX}-fieldset`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.scheduleTitle}`]: {
        fontSize: 22,
    },

    [`& .${classes.body2}`]: {
        backgroundColor: "white",
        marginTop: 15,
        minHeight: 850,
        [theme.breakpoints.down("md")]: {
            padding: 30,
        },
        [theme.breakpoints.up("md")]: {
            padding: 60,
        },
    },

    [`& .${classes.scheduleBox}`]: {
        backgroundColor: "#F8F9F8",
        marginTop: 16,
        padding: 15,
        borderRadius: 30,
    },

    [`& .${classes.fieldset}`]: {
        border: "2px solid #031851",
        borderStyle: "solid solid none solid",
        lineHeight: 0,
    },
}));

const CustomBox = styled(Box)(({ theme }) => ({
    borderRadius: "24px",
    color: "#fff",
    height: 30,
}));

const CustomBoxBar = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 6,
    height: 12,
    width: "100%",
}));

const CustomTableCell = styled(TableCell)(({ theme }) => ({
    borderBottom: 0,
    paddingRight: 0,
    [`& h2`]: {
        fontSize: 12,
    },
    [`& span`]: {
        fontSize: 12,
    },
}));

export const ScheduleBook = (props) => {
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    const { bookInfo } = props;

    /** Global variables */
    const weeksOnboarding = [
        { color: "#595aa6", label: t("proposals.week1B") },
        { color: "#44469b", label: t("proposals.week2B") },
        { color: "#2f3190", label: t("proposals.week3to6B") },
    ];

    const weeksUpdatesOnb = [
        { color: "#595aa6", label: t("proposals.week4B") },
        { color: "#44469b", label: t("proposals.week5B") },
        { color: "#2f3190", label: t("proposals.week6B") },
    ];

    const weeksUpdatesOnSmb = [
        { color: "#595aa6", label: t("proposals.week2B") },
        { color: "#44469b", label: t("proposals.week3B") },
        { color: "#2f3190", label: t("proposals.week4B") },
    ];

    const weeksUpdates = [
        { color: "#595aa6", label: t("proposals.week1B") },
        { color: "#44469b", label: t("proposals.week2B") },
        { color: "#2f3190", label: t("proposals.week3B") },
    ];

    const weeksUpdates3 = [
        { color: "#595aa6", label: t("proposals.week3B") },
        { color: "#44469b", label: t("proposals.week4B") },
        { color: "#2f3190", label: t("proposals.week5B") },
    ];

    const weeksOnboardingSub2 = [
        { color: "#2f3190", label: t("proposals.week3B") },
    ];

    return (
        <StyledContainer>
            {props.includeOnboarding && (
                <Box className={classes.scheduleBox}>
                    <TableContainer sx={{ overflowX: "auto" }}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow sx={{ "& th": { border: 0, pt: 1 } }}>
                                    <TableCell>
                                        <Typography variant="h2" sx={{ fontWeight: 700, fontSize: 12 }}>
                                            {t("proposals.practOnboardB")}
                                        </Typography>
                                    </TableCell>
                                    {weeksOnboarding.map((week, index) => (
                                        <TableCell>
                                            <CustomBox
                                                sx={{
                                                    backgroundColor: week.color,
                                                    width: sessionStorage.getItem("lng") === "en" ? "220%" : "240%",
                                                    ...(index === 2 && {
                                                        pr:
                                                            sessionStorage.getItem("lng") === "en"
                                                                ? "12px"
                                                                : "14px",
                                                        pl:
                                                            sessionStorage.getItem("lng") === "en"
                                                                ? "12px"
                                                                : "14px",
                                                        width: "105% !important",
                                                    }),
                                                    display: "flex",
                                                    justifyContent: "space-evenly",
                                                }}
                                            >
                                                <Typography
                                                    variant="span"
                                                    sx={{ display: "flex", alignItems: "center", fontSize: 12 }}
                                                    noWrap
                                                >
                                                    {week.label}
                                                </Typography>
                                                {index !== 2 && (
                                                    <Typography
                                                        variant="span"
                                                        sx={{ display: "flex", alignItems: "center", fontSize: 12 }}
                                                    >
                                                        &nbsp;
                                                    </Typography>
                                                )}
                                            </CustomBox>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <CustomTableCell component="th" scope="row">
                                        <Typography variant="h2">{t("proposals.intelB")}</Typography>
                                    </CustomTableCell>
                                    <CustomTableCell align="right">
                                        <CustomBoxBar />
                                    </CustomTableCell>
                                </TableRow>
                                <TableRow>
                                    <CustomTableCell component="th" scope="row">
                                        <Typography variant="h2">{t("proposals.designB")}</Typography>
                                    </CustomTableCell>
                                    <CustomTableCell align="right">
                                        <CustomBoxBar />
                                    </CustomTableCell>
                                </TableRow>
                                <TableRow>
                                    <CustomTableCell component="th" scope="row">
                                        <Typography variant="h2">{t("proposals.setUpBookB")}</Typography>
                                    </CustomTableCell>
                                    <CustomTableCell />
                                    <CustomTableCell align="right">
                                        <CustomBoxBar />
                                    </CustomTableCell>
                                </TableRow>
                                <TableRow>
                                    <CustomTableCell component="th" scope="row">
                                        <Typography variant="h2">{t("proposals.capaB")}</Typography>
                                    </CustomTableCell>
                                    <CustomTableCell />
                                    <CustomTableCell align="right">
                                        <CustomBoxBar />
                                    </CustomTableCell>
                                </TableRow>
                                <TableRow>
                                    <CustomTableCell component="th" scope="row">
                                        <Typography component="span" variant="h2">
                                            {t("proposals.followB")}
                                        </Typography>
                                    </CustomTableCell>
                                    <CustomTableCell colSpan={2} />
                                    <CustomTableCell align="right" colSpan={4}>
                                        <CustomBoxBar />
                                    </CustomTableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            )
            }
            {
                bookInfo.onboarding.isActive && (
                    <Box className={classes.scheduleBox}>
                        <TableContainer sx={{ overflowX: "auto" }}>
                            <Table aria-label="simple table" >
                                <TableHead>
                                    <TableRow sx={{ "& th": { border: 0, pt: 1 } }}>
                                        <TableCell>
                                            <Typography variant="h2" sx={{ fontWeight: 700, fontSize: 12 }}>
                                                SMBs Onboarding
                                            </Typography>
                                        </TableCell>
                                        {weeksOnboardingSub2.map((week, index) => (
                                            <TableCell align="left" >
                                                <CustomBox
                                                    sx={{
                                                        backgroundColor: week.color,
                                                        width: "125%",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        ml: -47
                                                    }}
                                                >
                                                    <Typography
                                                        variant="span"
                                                        sx={{ display: "flex", alignItems: "center", fontSize: 12 }}
                                                        noWrap
                                                    >
                                                        {props.includeOnboarding ? week.label : t("proposals.week1B")}
                                                    </Typography>
                                                    {index !== 1 && (
                                                        <Typography
                                                            variant="span"
                                                            sx={{ display: "flex", alignItems: "center", fontSize: 12 }}
                                                        >
                                                            &nbsp;
                                                        </Typography>
                                                    )}
                                                </CustomBox>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <CustomTableCell component="th" scope="row">
                                            <Typography variant="h2">{t("proposals.intelB")}</Typography>
                                        </CustomTableCell>
                                        <CustomTableCell align="left">
                                            <CustomBoxBar sx={{ ml: -46.5 }} />
                                        </CustomTableCell>
                                    </TableRow>
                                    <TableRow>
                                        <CustomTableCell component="th" scope="row">
                                            <Typography variant="h2">{t("proposals.designB")}</Typography>
                                        </CustomTableCell>
                                        <CustomTableCell align="left">
                                            <CustomBoxBar sx={{ ml: -46.5 }} />
                                        </CustomTableCell>
                                    </TableRow>
                                    <TableRow>
                                        <CustomTableCell component="th" scope="row">
                                            <Typography variant="h2">{t("proposals.accountingModuleConfigB")}</Typography>
                                        </CustomTableCell>
                                        <CustomTableCell align="left">
                                            <CustomBoxBar sx={{ ml: -46.5 }} />
                                        </CustomTableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                )
            }
            {(bookInfo.cleanUp.isActive || bookInfo.catchUp.isActive) && (
                <Box className={classes.scheduleBox}>
                    <TableContainer sx={{ overflowX: "auto" }}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow sx={{ "& th": { border: 0, pt: 1 } }}>
                                    <TableCell>
                                        <Typography variant="h2" sx={{ fontWeight: 700, fontSize: 12 }}>
                                            SMBs Catch-ups & Clean-ups**
                                        </Typography>
                                    </TableCell>
                                    {((props.includeOnboarding && bookInfo.onboarding.isActive) ?
                                        weeksUpdatesOnb : (!props.includeOnboarding && bookInfo.onboarding.isActive) ?
                                            weeksUpdatesOnSmb : (props.includeOnboarding && !bookInfo.onboarding.isActive) ?
                                                weeksUpdates3 : weeksUpdates).map((week, index) => (
                                                    <TableCell align="left">
                                                        <CustomBox
                                                            sx={{
                                                                backgroundColor: week.color,
                                                                width: sessionStorage.getItem("lng") === "en" ? "220%" : "240%",
                                                                ...(index === 2 && {
                                                                    pr:
                                                                        sessionStorage.getItem("lng") === "en"
                                                                            ? "12px"
                                                                            : "14px",
                                                                    pl:
                                                                        sessionStorage.getItem("lng") === "en"
                                                                            ? "12px"
                                                                            : "14px",
                                                                    width: "112% !important",
                                                                }),
                                                                display: "flex",
                                                                justifyContent: "space-evenly",
                                                            }}
                                                        >
                                                            <Typography
                                                                variant="span"
                                                                sx={{ display: "flex", alignItems: "center", fontSize: 12 }}
                                                                noWrap
                                                            >
                                                                {week.label}
                                                            </Typography>
                                                            {index !== 2 && (
                                                                <Typography
                                                                    variant="span"
                                                                    sx={{ display: "flex", alignItems: "center", fontSize: 12 }}
                                                                >
                                                                    &nbsp;
                                                                </Typography>
                                                            )}
                                                        </CustomBox>
                                                    </TableCell>
                                                ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <CustomTableCell component="th" scope="row">
                                        <Typography variant="h2">{t("proposals.bankRecB")}</Typography>
                                    </CustomTableCell>
                                    <CustomTableCell align="right" colSpan={2}>
                                        <CustomBoxBar />
                                    </CustomTableCell>
                                </TableRow>
                                <TableRow>
                                    <CustomTableCell component="th" scope="row">
                                        <Typography variant="h2">{t("proposals.accountingB")}</Typography>
                                    </CustomTableCell>
                                    <CustomTableCell />
                                    <CustomTableCell align="right" colSpan={2}>
                                        <CustomBoxBar />
                                    </CustomTableCell>
                                </TableRow>
                                <DescriptionIconContainer key={"proposals.finannStateB"} />
                                <TableRow>
                                    <CustomTableCell component="th" scope="row">
                                        <Typography component="span" variant="h2">
                                            {t("proposals.finannStateB")}
                                        </Typography>
                                    </CustomTableCell>
                                    <CustomTableCell colSpan={2} />
                                    <CustomTableCell align="right">
                                        <CustomBoxBar />
                                    </CustomTableCell>
                                </TableRow>
                                <TableRow>
                                    <CustomTableCell component="th" scope="row">
                                        <Typography variant="h2">{t("proposals.virtualMeetB")}</Typography>
                                    </CustomTableCell>
                                    <CustomTableCell colSpan={2} />
                                    <CustomTableCell align="right">
                                        <CustomBoxBar />
                                    </CustomTableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Typography variant="body1" sx={{ pt: 2, pl: 2, fontSize: 13 }}>
                        * {t("proposals.weekOneBeginsBookB")}
                    </Typography>
                    <Typography variant="body1" sx={{ pt: 1, pl: 2, fontSize: 13 }}>
                        ** {t("proposals.projectSchedContemplateBookB")}
                    </Typography>
                </Box>
            )}

        </StyledContainer >
    );
};
