/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";

/** MUI icons */
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import InfoIcon from "@mui/icons-material/Info";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

/** MUI imports */
import { styled } from "@mui/material/styles";
import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    Breadcrumbs,
    Stack,
    Skeleton,
    Typography,
    TextField,
    IconButton,
} from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

/** Components imports */
import Backdrop from "../Backdrop";
import SnackBar from "../SnackBar";
import InputFile from "../InputFile";
import History from "./History";

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)({
    boxShadow: "none",
    "&:before": {
        display: "none",
    },
});

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: "#031851" }} />} {...props} />
))({
    "& .MuiAccordionSummary-content": {
        margin: "24px 0",
    },
});

const AccordionSummarySkeleton = styled((props) => (
    <MuiAccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: "#cccccc" }} />} {...props} />
))({
    "& .MuiAccordionSummary-content": {
        margin: "24px 0",
    },
});

const AccordionDetails = styled(MuiAccordionDetails)({
    padding: "0px 0px 20px 0px",
    "& .MuiCard-root": {
        boxShadow: "none",
    },
});

const Features = () => {
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    /** React redux */
    const todos = useSelector((state) => state.todos);

    /** Component states */
    const [loading, setLoading] = useState(false);
    const [taxes] = useState(true);
    const [expanded, setExpanded] = useState(false);
    const breadcrumbs = [
        <Typography
            key="1"
            variant="h1"
            color="primary"
            sx={{
                fontSize: 24,
                fontWeight: 700,
            }}
        >
            {t("principal.automation")}
        </Typography>,
        <Typography
            key="2"
            variant="h1"
            color="secondary"
            sx={{
                fontSize: 24,
                fontWeight: 700,
            }}
        >
            {t("reports.functionalReports")}
        </Typography>,
    ];
    const [clients, setClients] = useState([]);
    const [openHistory, setOpenHistory] = useState(false);
    const [dataHistory, setDataHistory] = useState([]);
    const [dataBookName, setDataBookName] = useState("");
    const [dataBookType, setDataBookType] = useState("");

    const lastMonth = moment().subtract(1, "months");
    const [month, setMonth] = useState(lastMonth);

    const [page, setPage] = useState(0);

    /** Global variables */
    const reports = [
        { id: "tealca", book: t("reports.invoicingTM") },
        { id: "miami", book: t("reports.invoicingTMiami") },
        { id: "pana", book: t("reports.invoicingPana") },
        // { id: "kardex", book: t("reports.kardexInvest") },
    ];
    const reportsMdm = [{ id: "mDm", book: t("reports.invoicingMdM") }];

    /** Component functions */
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
        setMonth(lastMonth)
    };

    /** Component events */
    useEffect(() => {
        (async () => {
            setLoading(true);

            await fetch(`/getClientsByEmail?contact_email=${todos.userInfo.email}`, {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
                .then((res) => res.json())
                .then((data) => {
                    setClients(data);
                });

            setLoading(false);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t, todos.orgID]);

    return (
        <>
            <Box component="main" sx={{ flexGrow: 1, p: 4, pt: 10, width: "100%" }}>
                <Stack sx={{ pb: 4 }}>
                    <Breadcrumbs
                        separator={<NavigateNextIcon fontSize="small" sx={{ color: "#031851" }} />}
                        aria-label="breadcrumb"
                    >
                        {breadcrumbs}
                    </Breadcrumbs>
                </Stack>
                <Card
                    elevation={0}
                    sx={{
                        p: 3,
                        pt: 1,
                        borderRadius: 8,
                        boxShadow: "10px 10px 15px rgba(3,24,81,0.15)",
                    }}
                >
                    <CardContent sx={{ p: "0px !important" }}>
                        <Grid
                            id={taxes === true ? "" : "overlayLF"}
                            container
                            spacing={2}
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Grid item xs={12}>
                                {loading ? (
                                    Array(2).fill(
                                        <>
                                            <Accordion sx={{ borderBottom: "3px solid #cccccc" }}>
                                                <AccordionSummarySkeleton
                                                    aria-controls="accordion-content"
                                                    id="accordion-header"
                                                >
                                                    <Skeleton variant="text" animation="wave" height={15} width="25%" />
                                                </AccordionSummarySkeleton>
                                            </Accordion>
                                            <Accordion sx={{ borderBottom: "3px solid #cccccc" }}>
                                                <AccordionSummarySkeleton
                                                    aria-controls="accordion-content"
                                                    id="accordion-header"
                                                >
                                                    <Skeleton variant="text" animation="wave" height={15} width="20%" />
                                                </AccordionSummarySkeleton>
                                            </Accordion>
                                        </>
                                    )
                                ) : todos.auth ? (
                                    reports.map((report, index) => (
                                        <ReportBook
                                            key={`report${index}`}
                                            reportId={report.id}
                                            bookName={report.book}
                                            orgIdSelected={todos.orgID}
                                            expanded={expanded}
                                            handleChange={handleChange}
                                            openHistory={openHistory}
                                            setOpenHistory={setOpenHistory}
                                            dataHistory={dataHistory}
                                            setDataHistory={setDataHistory}
                                            setExpanded={setExpanded}
                                            month={month}
                                            setMonth={setMonth}
                                            setPage={setPage}
                                            page={page}
                                            dataBookName={dataBookName}
                                            setDataBookName={setDataBookName}
                                            dataBookType={dataBookType}
                                            setDataBookType={setDataBookType}
                                        />
                                    ))
                                ) : clients.includes("US Hispanic Ventures Inc") ? (
                                    reportsMdm.map((report, index) => (
                                        <ReportBook
                                            key={`report${index}`}
                                            reportId={report.id}
                                            bookName={report.book}
                                            orgIdSelected={todos.orgID}
                                            expanded={expanded}
                                            handleChange={handleChange}
                                            openHistory={openHistory}
                                            setOpenHistory={setOpenHistory}
                                            dataHistory={dataHistory}
                                            setDataHistory={setDataHistory}
                                            setExpanded={setExpanded}
                                            month={month}
                                            setMonth={setMonth}
                                            setPage={setPage}
                                            page={page}
                                            dataBookName={dataBookName}
                                            setDataBookName={setDataBookName}
                                            dataBookType={dataBookType}
                                            setDataBookType={setDataBookType}
                                        />
                                    ))
                                ) : (
                                    <Typography
                                        variant="h1"
                                        color="primary"
                                        align="center"
                                        sx={{ p: 5, fontWeight: 400 }}
                                    >
                                        {t("reports.noAutomations")}
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Box>
        </>
    );
};

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "rgba(19, 31, 62, 0.80)",
        fontSize: 12,
        maxWidth: 320,
        borderRadius: 6,
        fontWeight: "normal",
    },
});

const handleOpenHistory = async (report, setOpenHistory, setDataHistory, setPage, setDataBookName, bookName, setDataBookType, event) => {
    event.stopPropagation();
    await fetch(`/getHistoryAutomations?type=${report}`, {
        method: "GET",
        headers: {
            "Content-type": "application/json; charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
        },
    })
        .then((res) => res.json())
        .then((data) => {
            setDataHistory(data);
            setDataBookName(bookName)
            setDataBookType(report)
            setPage(0)
            setOpenHistory(true);
        });
};

const ReportBook = (props) => {
    const [t] = useTranslation("global");

    return (
        <>
            <Accordion
                expanded={props.expanded === props.reportId}
                onChange={props.handleChange(props.reportId)}
                sx={{ borderBottom: "3px solid #ff5968" }}
            >
                <AccordionSummary aria-controls="panel1bh-content" id={props.reportId} sx={{ p: 0 }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography variant="h5">{props.bookName}</Typography>
                        <LightTooltip title={t("miscellaneous.uploadHistory")} placement="right">
                            <IconButton
                                onClick={(e) =>
                                    handleOpenHistory(props.reportId, props.setOpenHistory, props.setDataHistory, props.setPage, props.setDataBookName, props.bookName, props.setDataBookType, e)
                                }
                                sx={{ color: "#2f3190", ml: 1 }}
                            >
                                <WorkHistoryIcon />
                            </IconButton>
                        </LightTooltip>
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container direction="row">
                        <Grid item xs={12}>
                            <Card>
                                <CardContent sx={{ p: "16px 0px 24px" }}>
                                    <BookTable
                                        orgIdSelected={props.orgIdSelected}
                                        bookType={props.reportId}
                                        bookName={props.bookName}
                                        month={props.month}
                                        setMonth={props.setMonth}
                                    />
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <History
                open={props.openHistory}
                onClose={() => props.setOpenHistory(false)}
                report={props.dataBookName}
                data={props.dataHistory}
                type={props.dataBookType}
                setData={props.setDataHistory}
                page={props.page}
                setPage={props.setPage}
            />
        </>
    );
};

const BookTable = (props) => {
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    /** Component states */
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [texto, setTexto] = useState("");
    const [alertType, setalertType] = useState("");

    const todos = useSelector((state) => state.todos);

    /** Component functions */
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setShow(false);
    };

    /// Funcion utilizada para obtener el periodo y enviar los parámetros
    /// solicitados por medio de post para generar el guardado en Xero
    const onGetPeriodo = async () => {
        setLoading(true);
        let fileUp = document.getElementById("fileExcel" + props.bookType).files[0];

        switch (props.bookType) {
            case "tealca":
                if (fileUp === undefined) {
                    setTexto(t("reports.chooseFile"));
                    setalertType("warning");
                    setShow(true);
                } else {
                    let thisExt = fileUp.name.split(".")[1].toLowerCase();
                    if (thisExt !== "xls" && thisExt !== "xlsx") {
                        setTexto(t("reports.invalidExt"));
                        setalertType("warning");
                        setShow(true);
                    } else {
                        let data = new FormData();
                        data.append("organisationId", props.orgIdSelected);
                        data.append("file", fileUp);
                        data.append("month", props.month.format("MMMM"));
                        data.append("year", props.month.format("YYYY"));
                        data.append("month_num", props.month.format("MM"));
                        data.append("token_set_id", todos.userInfo._id);

                        await fetch("/invoicesTealcaMadrid", {
                            method: "POST",
                            body: data,
                        })
                            .then((res) => {
                                if (res.ok) {
                                    setTexto(t("miscellaneous.automationMsg"));
                                    setalertType("success");
                                    setShow(true);
                                    document.getElementById("fileExcel" + props.bookType).value = "";
                                    document.getElementById(
                                        "fileExcel" + props.bookType + "label"
                                    ).firstChild.textContent = t("bank.browse");
                                } else {
                                    setTexto(t("reports.errorInv"));
                                    setalertType("error");
                                    setShow(true);
                                    document.getElementById("fileExcel" + props.bookType).value = "";
                                    document.getElementById(
                                        "fileExcel" + props.bookType + "label"
                                    ).firstChild.textContent = t("bank.browse");
                                }
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    }
                }
                break;
            case "kardex":
                if (fileUp === undefined) {
                    setTexto(t("reports.chooseFile"));
                    setalertType("warning");
                    setShow(true);
                } else {
                    let thisExt = fileUp.name.split(".")[1].toLowerCase();
                    if (thisExt !== "xls" && thisExt !== "xlsx") {
                        setTexto(t("reports.invalidExt"));
                        setalertType("warning");
                        setShow(true);
                    } else {
                        let data = new FormData();
                        data.append("organisationId", props.orgIdSelected);
                        data.append("file", fileUp);
                        data.append("month", props.month.format("MMMM"));
                        data.append("year", props.month.format("YYYY"));
                        data.append("month_num", props.month.format("MM"));
                        data.append("token_set_id", todos.userInfo._id);

                        await fetch("/kardexInvestment", {
                            method: "POST",
                            body: data,
                        })
                            .then((res) => {
                                if (res.ok) {
                                    setTexto(t("reports.successKar"));
                                    setalertType("success");
                                    setShow(true);
                                    document.getElementById("fileExcel" + props.bookType).value = "";
                                    document.getElementById(
                                        "fileExcel" + props.bookType + "label"
                                    ).firstChild.textContent = t("bank.browse");
                                } else {
                                    setTexto(t("reports.errorKar"));
                                    setalertType("error");
                                    setShow(true);
                                    document.getElementById("fileExcel" + props.bookType).value = "";
                                    document.getElementById(
                                        "fileExcel" + props.bookType + "label"
                                    ).firstChild.textContent = t("bank.browse");
                                }
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    }
                }
                break;
            case "miami":
                if (fileUp === undefined) {
                    setTexto(t("reports.chooseFile"));
                    setalertType("warning");
                    setShow(true);
                } else {
                    let thisExt = fileUp.name.split(".")[1].toLowerCase();
                    if (thisExt !== "xls" && thisExt !== "xlsx") {
                        setTexto(t("reports.invalidExt"));
                        setalertType("warning");
                        setShow(true);
                    } else {
                        let data = new FormData();
                        data.append("organisationId", props.orgIdSelected);
                        data.append("file", fileUp);
                        data.append("month", props.month.format("MMMM"));
                        data.append("year", props.month.format("YYYY"));
                        data.append("month_num", props.month.format("MM"));
                        data.append("token_set_id", todos.userInfo._id);

                        await fetch("/invoicesTealcaMiami", {
                            method: "POST",
                            body: data,
                        })
                            .then((res) => {
                                if (res.ok) {
                                    setTexto(t("miscellaneous.automationMsg"));
                                    setalertType("success");
                                    setShow(true);
                                    document.getElementById("fileExcel" + props.bookType).value = "";
                                    document.getElementById(
                                        "fileExcel" + props.bookType + "label"
                                    ).firstChild.textContent = t("bank.browse");
                                } else {
                                    setTexto(t("reports.errorInv"));
                                    setalertType("error");
                                    setShow(true);
                                    document.getElementById("fileExcel" + props.bookType).value = "";
                                    document.getElementById(
                                        "fileExcel" + props.bookType + "label"
                                    ).firstChild.textContent = t("bank.browse");
                                }
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    }
                }
                break;
            case "pana":
                if (fileUp === undefined) {
                    setTexto(t("reports.chooseFile"));
                    setalertType("warning");
                    setShow(true);
                } else {
                    let thisExt = fileUp.name.split(".")[1].toLowerCase();
                    if (thisExt !== "xls" && thisExt !== "xlsx") {
                        setTexto(t("reports.invalidExt"));
                        setalertType("warning");
                        setShow(true);
                    } else {
                        let data = new FormData();
                        data.append("organisationId", props.orgIdSelected);
                        data.append("file", fileUp);
                        data.append("month", props.month.format("MMMM"));
                        data.append("year", props.month.format("YYYY"));
                        data.append("month_num", props.month.format("MM"));
                        data.append("token_set_id", todos.userInfo._id);

                        await fetch("/invoicesPanaTechnologies", {
                            method: "POST",
                            body: data,
                        })
                            .then((res) => {
                                if (res.ok) {
                                    setTexto(t("miscellaneous.automationMsg"));
                                    setalertType("success");
                                    setShow(true);
                                    document.getElementById("fileExcel" + props.bookType).value = "";
                                    document.getElementById(
                                        "fileExcel" + props.bookType + "label"
                                    ).firstChild.textContent = t("bank.browse");
                                } else {
                                    setTexto(t("reports.errorInv"));
                                    setalertType("error");
                                    setShow(true);
                                    document.getElementById("fileExcel" + props.bookType).value = "";
                                    document.getElementById(
                                        "fileExcel" + props.bookType + "label"
                                    ).firstChild.textContent = t("bank.browse");
                                }
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    }
                }
                break;
            default:
                break;
        }
        setLoading(false);
    };

    const handleChangeMonth = (date) => {
        props.setMonth(date);
    };

    return (
        <Box>
            {/** Botón para generar un periodo específico de libro */}
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
                    <Box>
                        <InputFile
                            id={`fileExcel${props.bookType}`}
                            tooltip={t("reports.validFormat")}
                        />
                    </Box>
                    <Box sx={{ ml: 2 }}>
                        <LocalizationProvider
                            dateAdapter={AdapterMoment}
                            adapterLocale={t("language.locale")}
                            localeText={{ clearButtonLabel: t("miscellaneous.clear") }}
                        >
                            <DatePicker
                                componentsProps={{
                                    actionBar: { actions: ["clear"] },
                                }}
                                value={props.month}
                                inputFormat="MMMM YYYY"
                                disableFuture
                                onChange={(newValue) => handleChangeMonth(newValue)}
                                renderInput={(params) => (
                                    <TextField
                                        id={`month_${props.bookType}`}
                                        fullWidth
                                        required
                                        {...params}
                                        //error={isErrorMod && newPeriodInit === null ? true : false}
                                    />
                                )}
                                label={t("miscellaneous.monthAndYear")}
                                views={['year', 'month']}
                                openTo="year"
                            />
                        </LocalizationProvider>
                    </Box>
                    <Box sx={{ ml: 2 }}>
                        <Button
                            variant="contained"
                            id="btnGenerar"
                            color="secondary"
                            className="btnRoot"
                            onClick={() => onGetPeriodo()}
                            disableElevation
                        >
                            {t("miscellaneous.send")}
                        </Button>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <InfoIcon />
                        <Typography>{t("reports.selectDate")}</Typography>
                    </Stack>
                </Grid>
            </Grid>
            <Backdrop open={loading} />
            <SnackBar type={alertType} show={show} message={texto} onClose={handleClose} />
        </Box>
    );
};

export default Features;
