/** React imports */
import React from "react";
import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";
import { useSelector } from "react-redux";

/** Components imports */
import AnalytReports from "./components/internos/Analytics/AnalytReports";
import Automation from "./components/internos/Automation/Features";
import BanksDashboard from "./components/internos/Dashboard/BankingDashboard";
import ClientFilesManagement from "./components/internos/Requirement/ClientFilesMana";
import ClientGlobalReport from "./components/internos/Reports/Recurrents/ClientGlobalReport";
import ClientInvoicing from "./components/internos/Invoicing/ClientInvoicing";
import ClientManagement from "./components/internos/Clients/Clients";
import ClientRequirements from "./components/internos/Requirement/ClientRequirements";
import ComebackSoon from "./components/ComebackSoon";
import CreateProposal from "./components/internos/Proposals/CreateProposal";
import Error from "./components/Error";
import GeneralDashboard from "./components/internos/Dashboard/Dashboard";
import Imports from "./components/internos/Automation/ImportFiles";
import Invoicing from "./components/internos/Invoicing/Invoicing";
import Loading from "./components/Loading";
import Login from "./components/Login";
import ManageProposals from "./components/internos/Proposals/ManageProposals";
import NavBar from "./components/NavBar";
import Notifications from "./components/internos/Notifications";
import ProposalPresent from "./components/internos/Proposals/ProposalPresent";
import Proposals from "./components/internos/Proposals/Proposals";
import Prospects from "./components/internos/Proposals/Prospects";
import RecuAccountant from "./components/internos/Reports/Recurrents/RecuAccountant";
import RecuBookkeeper from "./components/internos/Reports/Recurrents/RecuBookkeeper";
import Requirements from "./components/internos/Requirement/Requirements";
import ServicesFilters from "./components/internos/Analytics/FiltersServ";
import Updates from "./components/internos/Reports/Updates/Update";

const RequireAuth = ({ children, redirectTo, isAuthenticated, isExternal }) => {
    return isAuthenticated || isExternal ? (
        <div style={{ display: "flex", position: "relative" }}>
            <NavBar />
            {children}
        </div>
    ) : (
        <Navigate to={redirectTo} />
    );
};

const App = () => {
    /* React redux */
    const todos = useSelector((state) => state.todos);

    const InvoicingAccess = [
        "ac@gca.digital",
        "atorrealba@gca.digital",
        "btorres@gca.digital",
        "cgil@gca.digital",
        "galbani@gca.digital",
        "dloyo@gca.digital",
        "dgoncalves@gca.digital",
        "murbaneja@gca.digital",
        "yrosas@gca.digital",
        "gcalles@gca.digital",
        "jalvarez@gca.digital",
        "kgarcia@gca.digital",
        "jrojas@gca.digital",
        "mmedina@gca.digital",
        "pherrera@gca.digital" // Eliminar al publicar en Produccion
    ];

    let params = new URL(document.location).searchParams;
    let status = params.get("unassigned");

    if (status && !todos?.auth) {
        sessionStorage.setItem("redirect", true);
    }

    if (!sessionStorage.getItem("option")) {
        sessionStorage.setItem("option", 1);
    }

    return (
        <BrowserRouter>
            <Routes>
                <Route exact path="/" element={<Login />} />
                <Route path="/loading" element={<Loading />} />
                <Route path="/comebackSoon" element={<ComebackSoon />} />
                <Route path="/clientFilesManagement" element={<ClientFilesManagement />} />
                <Route path="/proposalPresent" element={<ProposalPresent />} />
                <Route
                    path="/dashboard/general"
                    element={
                        <RequireAuth redirectTo="/" isAuthenticated={todos?.auth} isExternal={false}>
                            <GeneralDashboard />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/dashboard/banks"
                    element={
                        <RequireAuth redirectTo="/" isAuthenticated={todos?.auth} isExternal={todos?.authEx}>
                            <BanksDashboard />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/analytics/filters"
                    element={
                        <RequireAuth redirectTo="/" isAuthenticated={todos?.auth} isExternal={false}>
                            <ServicesFilters />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/analytics/reports"
                    element={
                        <RequireAuth redirectTo="/" isAuthenticated={todos?.auth} isExternal={false}>
                            <AnalytReports />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/reports/bookkeeper"
                    element={
                        <RequireAuth redirectTo="/" isAuthenticated={todos?.auth} isExternal={false}>
                            <RecuBookkeeper />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/reports/accountant"
                    element={
                        <RequireAuth redirectTo="/" isAuthenticated={todos?.auth} isExternal={false}>
                            <RecuAccountant />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/reports/updates"
                    element={
                        <RequireAuth redirectTo="/" isAuthenticated={todos?.auth} isExternal={false}>
                            <Updates />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/requirements"
                    element={
                        <RequireAuth redirectTo="/" isAuthenticated={todos?.auth} isExternal={false}>
                            <Requirements />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/clientRequirements"
                    element={
                        <RequireAuth redirectTo="/" isAuthenticated={todos?.auth} isExternal={todos?.authReq}>
                            <ClientRequirements />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/clientGlobalReport"
                    element={
                        <RequireAuth redirectTo="/" isAuthenticated={todos?.auth} isExternal={todos?.authStatus}>
                            <ClientGlobalReport />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/automation"
                    element={
                        <RequireAuth redirectTo="/" isAuthenticated={todos?.auth} isExternal={false}>
                            <Automation />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/importFiles"
                    element={
                        <RequireAuth redirectTo="/" isAuthenticated={todos?.auth} isExternal={false}>
                            <Imports />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/clientManagement"
                    element={
                        <RequireAuth redirectTo="/" isAuthenticated={todos?.auth} isExternal={false}>
                            <ClientManagement />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/invoicing"
                    element={
                        <RequireAuth
                            redirectTo="/"
                            isAuthenticated={todos?.auth && InvoicingAccess.includes(todos?.userInfo?.email)}
                            isExternal={false}
                        >
                            <Invoicing />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/clientInvoicing"
                    element={
                        <RequireAuth redirectTo="/" isAuthenticated={todos?.auth} isExternal={todos?.authInvoice}>
                            <ClientInvoicing />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/proposals"
                    element={
                        <RequireAuth redirectTo="/" isAuthenticated={todos?.auth} isExternal={false}>
                            <Proposals />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/proposals/createProposal"
                    element={
                        <RequireAuth redirectTo="/" isAuthenticated={todos?.auth} isExternal={false}>
                            <CreateProposal />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/proposals/manageProposals"
                    element={
                        <RequireAuth redirectTo="/" isAuthenticated={todos?.auth} isExternal={false}>
                            <ManageProposals />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/proposals/prospects"
                    element={
                        <RequireAuth redirectTo="/" isAuthenticated={todos?.auth} isExternal={false}>
                            <Prospects />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/notifications"
                    element={
                        <RequireAuth redirectTo="/" isAuthenticated={todos?.auth} isExternal={false}>
                            <Notifications />
                        </RequireAuth>
                    }
                />
                <Route path="*" element={<Error />} />
            </Routes>
        </BrowserRouter>
    );
};

export default App;
