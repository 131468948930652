/** React imports */
import React, { useEffect, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";

/** MUI imports */
import { styled } from "@mui/material/styles";
import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Tabs,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    DialogActions,
    Grid,
    Button,
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

/** MUI icons */
import CloseIcon from "@mui/icons-material/Close";
import SyncIcon from "@mui/icons-material/Sync";
import InventoryIcon from "@mui/icons-material/Inventory";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import RemoveIcon from "@mui/icons-material/Remove";
import WarningAmberIcon from "@mui/icons-material/Warning";
import CancelIcon from "@mui/icons-material/Cancel";

import DialogMonth from "./ExpenseHistoryMonth";

const CustomDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(4),
    },
    "& .MuiDialogTitle-root": {
        backgroundColor: "#031851",
        fontSize: "18px",
        color: "#fff",
    },
    "& .MuiDialog-paper": {
        borderRadius: 8,
        boxShadow: "10px 10px 15px rgba(3,24,81,0.15)",
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(2),
    },
}));

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "rgba(19, 31, 62, 0.80)",
        fontSize: 12,
        maxWidth: 320,
        borderRadius: 6,
        fontWeight: "normal",
    },
});

const CustomTableCell = styled(TableCell)(({ theme }) => ({
    borderBottom: 0,
    paddingRight: 0,
    [`& h2`]: {
        fontSize: 12,
    },
    [`& span`]: {
        fontSize: 12,
    },
}));

const CustomTab = styled(Tab)(({ theme }) => ({
    padding: 10,
    marginLeft: 8,
    borderRadius: "20px 20px 0 0",
    backgroundColor: "#FF2B5D",
    color: "#FFF",
    fontWeight: 700,
    border: "1px solid #FF2B5D",
    "&.Mui-selected": {
        backgroundColor: "#FFF !important",
        color: "#2f3190",
        border: "1px solid #FF2B5D",
        borderWidth: "1px 1px 0 1px;",
    },
}));

const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
};

const ColorButton = styled(Button)(({ theme }) => ({
    border: "1px solid #ff5968",
    borderRadius: 40,
}));

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
        </div>
    );
};

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

export default function ExpenseHistory({
    open,
    onClose,
    expenseHist,
    valueTab,
    setValueTab,
    client,
    handleUpdateMonthExpenses,
    setExpenseHist,
    meses,
    isDisabled,
    setIsDisabled,
    expenses,
}) {
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    const handleChangeTab = (event, newValue) => {
        setValueTab(newValue);
    };

    const todos = useSelector((state) => state.todos);
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    const [openMonth, setOpenMonth] = useState(false);
    const [dateHistory, setDateHistory] = useState("");

    const MINUTE_MS = 10000;

    useEffect(() => {
        if (open && !openMonth) {
            const interval = setInterval(async () => {
                await fetch(`/getHistoricalExpenses?company_id=${client._id}`, {
                    method: "GET",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                    },
                })
                    .then((res) => res.json())
                    .then((data) => {
                        let isDisabled = false;

                        data.forEach((item) => {
                            meses.forEach((month) => {
                                if (item[Object.keys(item)[0]][month.num].status === 2) {
                                    isDisabled = true;
                                }
                            });
                        });

                        setIsDisabled(isDisabled);

                        // Validación para mostrar realExpenses de un mes o - dependiendo de la fecha actual.
                        const currentDate = moment();
                        const currentMonth = currentDate.month() + 1;

                        //Para el mes anterior, se valida que abra 3 días hábiles antes del primero de cada mes
                        let nextMonthDate = moment().add(1, "months").startOf("month");
                        let finalDate = nextMonthDate.clone().subtract(3, "days").startOf("day");
                        while ([6, 7].includes(finalDate.isoWeekday())) {
                            finalDate.subtract(1, "days");
                        }

                        const lastDayMonth = currentDate.daysInMonth();
                        let expensesData;

                        if (currentDate.date() !== lastDayMonth) {
                            expensesData = data.map((obj) => {
                                const year = Object.keys(obj)[0];
                                const months = obj[year];

                                if (year === currentDate.year().toString()) {
                                    const updatedMonths = Object.keys(months).reduce((acc, month) => {
                                        const monthData = months[month];
                                        return {
                                            ...acc,
                                            [month]:
                                                parseInt(month) === currentMonth ||
                                                (parseInt(month) === currentMonth - 1 &&
                                                    currentDate.isBefore(finalDate))
                                                    ? { ...monthData, realExpense: "-" }
                                                    : monthData,
                                        };
                                    }, {});
                                    return { [year]: updatedMonths };
                                }
                                return obj;
                            });
                        } else {
                            expensesData = data;
                        }

                        setExpenseHist(expensesData);
                    });
            }, MINUTE_MS);

            return () => clearInterval(interval);
        }
    }, [open, setExpenseHist, openMonth, client._id, meses, setIsDisabled]);

    const handleOpenHistoryMonth = (month, year) => {
        setDateHistory(month + " " + year);
        setOpenMonth(true);
    };

    const handleBack = () => {
        setOpenMonth(false);
    };

    const toolTipAverage = (item, month) => {
        return (
            <>
                <Box sx={{ pb: 1 }}>{t("miscellaneous.expensesConsid")}:</Box>
                <Box>
                    {item[Object.keys(item)[0]][month.num] &&
                    item[Object.keys(item)[0]][month.num].expenseAverageInfo &&
                    item[Object.keys(item)[0]][month.num].expenseAverageInfo[0] ? (
                        item[Object.keys(item)[0]][month.num].expenseAverageInfo[0].expenses !== -1 ? (
                            <>
                                {meses.map((item3) => {
                                    if (
                                        item3.num ===
                                        item[Object.keys(item)[0]][month.num].expenseAverageInfo[0].month.toString()
                                    ) {
                                        return item3.name;
                                    }
                                    return "";
                                })}{" "}
                                {item[Object.keys(item)[0]][month.num].expenseAverageInfo[0].year}:{" $ "}
                                {parseFloat(
                                    item[Object.keys(item)[0]][month.num].expenseAverageInfo[0].expenses
                                ).toLocaleString(todos.amountFormat)}
                            </>
                        ) : (
                            <>
                                {meses.map((item3) => {
                                    if (
                                        item3.num ===
                                        item[Object.keys(item)[0]][month.num].expenseAverageInfo[0].month.toString()
                                    ) {
                                        return item3.name;
                                    }
                                    return "";
                                })}{" "}
                                {item[Object.keys(item)[0]][month.num].expenseAverageInfo[0].year}:{" N/A"}
                            </>
                        )
                    ) : (
                        ""
                    )}
                </Box>
                <Box>
                    {item[Object.keys(item)[0]][month.num] &&
                    item[Object.keys(item)[0]][month.num].expenseAverageInfo &&
                    item[Object.keys(item)[0]][month.num].expenseAverageInfo[1] ? (
                        item[Object.keys(item)[0]][month.num].expenseAverageInfo[1].expenses !== -1 ? (
                            <>
                                {meses.map((item3) => {
                                    if (
                                        item3.num ===
                                        item[Object.keys(item)[0]][month.num].expenseAverageInfo[1].month.toString()
                                    ) {
                                        return item3.name;
                                    }
                                    return "";
                                })}{" "}
                                {item[Object.keys(item)[0]][month.num].expenseAverageInfo[1].year}:{" $ "}
                                {parseFloat(
                                    item[Object.keys(item)[0]][month.num].expenseAverageInfo[1].expenses
                                ).toLocaleString(todos.amountFormat)}
                            </>
                        ) : (
                            <>
                                {meses.map((item3) => {
                                    if (
                                        item3.num ===
                                        item[Object.keys(item)[0]][month.num].expenseAverageInfo[1].month.toString()
                                    ) {
                                        return item3.name;
                                    }
                                    return "";
                                })}{" "}
                                {item[Object.keys(item)[0]][month.num].expenseAverageInfo[1].year}:{" N/A"}
                            </>
                        )
                    ) : (
                        ""
                    )}
                </Box>
                <Box>
                    {item[Object.keys(item)[0]][month.num] &&
                    item[Object.keys(item)[0]][month.num].expenseAverageInfo &&
                    item[Object.keys(item)[0]][month.num].expenseAverageInfo[2] ? (
                        item[Object.keys(item)[0]][month.num].expenseAverageInfo[2].expenses !== -1 ? (
                            <>
                                {meses.map((item3) => {
                                    if (
                                        item3.num ===
                                        item[Object.keys(item)[0]][month.num].expenseAverageInfo[2].month.toString()
                                    ) {
                                        return item3.name;
                                    }
                                    return "";
                                })}{" "}
                                {item[Object.keys(item)[0]][month.num].expenseAverageInfo[2].year}:{" $ "}
                                {parseFloat(
                                    item[Object.keys(item)[0]][month.num].expenseAverageInfo[2].expenses
                                ).toLocaleString(todos.amountFormat)}
                            </>
                        ) : (
                            <>
                                {meses.map((item3) => {
                                    if (
                                        item3.num ===
                                        item[Object.keys(item)[0]][month.num].expenseAverageInfo[2].month.toString()
                                    ) {
                                        return item3.name;
                                    }
                                    return "";
                                })}{" "}
                                {item[Object.keys(item)[0]][month.num].expenseAverageInfo[2].year}:{" N/A"}
                            </>
                        )
                    ) : (
                        ""
                    )}
                </Box>
            </>
        );
    };

    const handleOnClose = () => {
        onClose();
        setOpenMonth(false);
    };

    return (
        <CustomDialog
            open={open}
            onClose={handleOnClose}
            aria-labelledby="customized-dialog-title"
            fullWidth={true}
            maxWidth="lg"
        >
            <DialogTitle id="alert-dialog-title">
                <IconButton
                    aria-label="close"
                    onClick={handleOnClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: "white",
                    }}
                >
                    <CloseIcon />
                </IconButton>
                {openMonth ? t("miscellaneous.updateHist") : t("currentView.expenseHist")} /{" "}
                <span style={{ color: "#ff5968" }}>{client.company_legal_name}</span>
                {openMonth && " / " + dateHistory}
            </DialogTitle>
            <DialogContent dividers>
                {openMonth ? (
                    <DialogMonth dateHistory={dateHistory} id={client._id} months={meses} />
                ) : (
                    <>
                        <Box
                            sx={{
                                borderBottom: 2,
                                borderColor: "#031851",
                                mb: 2,
                            }}
                        >
                            <Tabs
                                value={valueTab}
                                onChange={handleChangeTab}
                                variant="scrollable"
                                scrollButtons="auto"
                                aria-label="years tabs"
                                TabIndicatorProps={{
                                    sx: {
                                        display: "none",
                                    },
                                }}
                            >
                                {expenseHist.map((item, index) => (
                                    <CustomTab label={Object.keys(item)[0]} {...a11yProps(index)} />
                                ))}
                            </Tabs>
                        </Box>
                        {expenseHist.map((item, i) => (
                            <TabPanel value={valueTab} index={i}>
                                <Box sx={{ width: "100%" }}>
                                    <TableContainer sx={{ overflowX: "auto" }}>
                                        <Table aria-label="simple table">
                                            <TableHead>
                                                <TableRow sx={{ "& th": { borderBottom: "0.5px solid black", py: 2 } }}>
                                                    <TableCell sx={{ p: 0 }}></TableCell>
                                                    <TableCell sx={{ p: 0 }}>
                                                        <Typography variant="h2" sx={{ fontWeight: 700, fontSize: 16 }}>
                                                            {t("bankingDashboard.month")}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="right" sx={{ p: 0 }}>
                                                        <Typography variant="h2" sx={{ fontWeight: 700, fontSize: 16 }}>
                                                            {t("currentView.realExpense")}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="right" sx={{ p: 0 }}></TableCell>
                                                    <TableCell align="right" sx={{ p: 0 }}>
                                                        <Typography variant="h2" sx={{ fontWeight: 700, fontSize: 16 }}>
                                                            {t("currentView.expenseAvg")}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="right" sx={{ p: 0 }}>
                                                        <Typography variant="h2" sx={{ fontWeight: 700, fontSize: 16 }}>
                                                            {t("miscellaneous.expenseScale")}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="center" sx={{ p: 0 }}>
                                                        <Typography variant="h2" sx={{ fontWeight: 700, fontSize: 16 }}>
                                                            {t("bankingDashboard.update")}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody
                                                sx={{
                                                    "&:before": {
                                                        display: "none",
                                                    },
                                                }}
                                            >
                                                {meses.map((month, i) => (
                                                    <TableRow>
                                                        <CustomTableCell
                                                            align="center"
                                                            sx={{
                                                                px: 0,
                                                                py: 0.8,
                                                                ...(month.num !== "12" && {
                                                                    borderBottom: "0.5px solid black",
                                                                }),
                                                            }}
                                                        >
                                                            {item[Object.keys(item)[0]][month.num] &&
                                                            item[Object.keys(item)[0]][month.num].haveHistory &&
                                                            moment({
                                                                year: Object.keys(item)[0],
                                                                month: parseInt(month.num) - 1,
                                                            }).isSameOrAfter(
                                                                moment.utc(client.since_date).subtract(4, "month")
                                                            ) ? (
                                                                <LightTooltip title={t("miscellaneous.updateHist")}>
                                                                    <IconButton
                                                                        onClick={() =>
                                                                            handleOpenHistoryMonth(
                                                                                month.name,
                                                                                Object.keys(item)[0]
                                                                            )
                                                                        }
                                                                        sx={{ color: "#2f3190" }}
                                                                    >
                                                                        <InventoryIcon fontSize="small" />
                                                                    </IconButton>
                                                                </LightTooltip>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </CustomTableCell>
                                                        <CustomTableCell
                                                            align="left"
                                                            sx={{
                                                                px: 0,
                                                                height: "39.934px !important",
                                                                ...(month.num !== "12" && {
                                                                    borderBottom: "0.5px solid black",
                                                                }),
                                                            }}
                                                        >
                                                            <Typography>{month.name}</Typography>
                                                        </CustomTableCell>
                                                        <CustomTableCell
                                                            align="right"
                                                            sx={{
                                                                px: 0,
                                                                py: 0.8,
                                                                ...(month.num !== "12" && {
                                                                    borderBottom: "0.5px solid black",
                                                                }),
                                                            }}
                                                        >
                                                            {item[Object.keys(item)[0]][month.num] &&
                                                            item[Object.keys(item)[0]][month.num].realExpense &&
                                                            !isNaN(item[Object.keys(item)[0]][month.num].realExpense)
                                                                ? moment({
                                                                      year: Object.keys(item)[0],
                                                                      month: parseInt(month.num) - 1,
                                                                  }).isSameOrAfter(
                                                                      moment.utc(client.since_date).subtract(4, "month")
                                                                  )
                                                                    ? item[Object.keys(item)[0]][month.num]
                                                                          .realExpense !== -1
                                                                        ? "$ " +
                                                                          parseFloat(
                                                                              item[Object.keys(item)[0]][month.num]
                                                                                  .realExpense
                                                                          ).toLocaleString(todos.amountFormat)
                                                                        : "N/A"
                                                                    : "-"
                                                                : "-"}
                                                        </CustomTableCell>
                                                        <CustomTableCell
                                                            align="right"
                                                            sx={{
                                                                px: 0,
                                                                py: 0.8,
                                                                ...(month.num !== "12" && {
                                                                    borderBottom: "0.5px solid black",
                                                                }),
                                                            }}
                                                        >
                                                            {item[Object.keys(item)[0]][month.num] &&
                                                            item[Object.keys(item)[0]][month.num].realExpense &&
                                                            item[Object.keys(item)[0]][month.num].realExpense !== -1 &&
                                                            !isNaN(item[Object.keys(item)[0]][month.num].realExpense) &&
                                                            parseFloat(
                                                                item[Object.keys(item)[0]][month.num].realExpense
                                                            ) < 100 ? (
                                                                moment({
                                                                    year: Object.keys(item)[0],
                                                                    month: parseInt(month.num) - 1,
                                                                }).isSameOrAfter(
                                                                    moment.utc(client.since_date).subtract(4, "month")
                                                                ) ? (
                                                                    <LightTooltip
                                                                        title={t("miscellaneous.lowExpenses")}
                                                                    >
                                                                        <WarningAmberIcon
                                                                            fontSize="small"
                                                                            sx={{ color: "#FFB039" }}
                                                                        />
                                                                    </LightTooltip>
                                                                ) : null
                                                            ) : null}
                                                            {item[Object.keys(item)[0]][month.num].disconnected &&
                                                            moment({
                                                                year: Object.keys(item)[0],
                                                                month: parseInt(month.num) - 1,
                                                            }).isSameOrAfter(
                                                                moment.utc(client.since_date).subtract(4, "month")
                                                            ) &&
                                                            (!isNaN(
                                                                item[Object.keys(item)[0]][month.num].expenseScale
                                                            ) ||
                                                                !isNaN(
                                                                    item[Object.keys(item)[0]][month.num].expenseAverage
                                                                )) &&
                                                            !isNaN(
                                                                item[Object.keys(item)[0]][month.num].realExpense
                                                            ) ? (
                                                                <LightTooltip
                                                                    title={t("miscellaneous.connectionError")}
                                                                >
                                                                    <CancelIcon fontSize="small" color="secondary" />
                                                                </LightTooltip>
                                                            ) : null}
                                                        </CustomTableCell>
                                                        <CustomTableCell
                                                            align="right"
                                                            sx={{
                                                                px: 0,
                                                                py: 0.8,
                                                                ...(month.num !== "12" && {
                                                                    borderBottom: "0.5px solid black",
                                                                }),
                                                            }}
                                                        >
                                                            {item[Object.keys(item)[0]][month.num] &&
                                                            item[Object.keys(item)[0]][month.num].expenseAverage &&
                                                            !isNaN(
                                                                item[Object.keys(item)[0]][month.num].expenseAverage
                                                            ) ? (
                                                                moment({
                                                                    year: Object.keys(item)[0],
                                                                    month: parseInt(month.num) - 1,
                                                                }).isSameOrAfter(
                                                                    moment.utc(client.since_date).subtract(4, "month")
                                                                ) ? (
                                                                    item[Object.keys(item)[0]][month.num]
                                                                        .expenseAverage !== -1 ? (
                                                                        <>
                                                                            <LightTooltip
                                                                                title={toolTipAverage(item, month)}
                                                                                placement="right"
                                                                            >
                                                                                <Typography>
                                                                                    ${" "}
                                                                                    {parseFloat(
                                                                                        item[Object.keys(item)[0]][
                                                                                            month.num
                                                                                        ].expenseAverage
                                                                                    ).toLocaleString(
                                                                                        todos.amountFormat
                                                                                    )}
                                                                                </Typography>
                                                                            </LightTooltip>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <LightTooltip
                                                                                title={toolTipAverage(item, month)}
                                                                                placement="right"
                                                                            >
                                                                                <Typography>N/A</Typography>
                                                                            </LightTooltip>
                                                                        </>
                                                                    )
                                                                ) : (
                                                                    "-"
                                                                )
                                                            ) : (
                                                                "-"
                                                            )}
                                                        </CustomTableCell>
                                                        <CustomTableCell
                                                            align="right"
                                                            sx={{
                                                                px: 0,
                                                                py: 0.8,
                                                                ...(month.num !== "12" && {
                                                                    borderBottom: "0.5px solid black",
                                                                }),
                                                            }}
                                                        >
                                                            {item[Object.keys(item)[0]][month.num] &&
                                                            item[Object.keys(item)[0]][month.num].expenseScale ? (
                                                                <Box sx={{ display: "flex", justifyContent: "end" }}>
                                                                    {moment({
                                                                        year: Object.keys(item)[0],
                                                                        month: parseInt(month.num) - 1,
                                                                    }).isSameOrAfter(
                                                                        moment
                                                                            .utc(client.since_date)
                                                                            .subtract(4, "month")
                                                                    )
                                                                        ? !isNaN(
                                                                              item[Object.keys(item)[0]][month.num]
                                                                                  .expenseScale
                                                                          )
                                                                            ? "$ " +
                                                                              item[Object.keys(item)[0]][month.num]
                                                                                  .expenseScale /
                                                                                  1000 +
                                                                              "k"
                                                                            : "-"
                                                                        : "-"}

                                                                    {item[Object.keys(item)[0]][month.num] &&
                                                                    item[Object.keys(item)[0]][month.num].indicator ? (
                                                                        <>
                                                                            {item[Object.keys(item)[0]][month.num]
                                                                                .indicator === "higher" && (
                                                                                <ArrowDropUpIcon
                                                                                    sx={{ color: "#51D67D" }}
                                                                                />
                                                                            )}
                                                                            {item[Object.keys(item)[0]][month.num]
                                                                                .indicator === "lower" && (
                                                                                <ArrowDropDownIcon
                                                                                    sx={{ color: "#FF5968" }}
                                                                                />
                                                                            )}
                                                                            {item[Object.keys(item)[0]][month.num]
                                                                                .indicator === "equal" && (
                                                                                <RemoveIcon sx={{ color: "#D9D9D9" }} />
                                                                            )}
                                                                        </>
                                                                    ) : (
                                                                        <RemoveIcon sx={{ color: "#D9D9D9" }} />
                                                                    )}
                                                                </Box>
                                                            ) : (
                                                                "-"
                                                            )}
                                                        </CustomTableCell>
                                                        <CustomTableCell
                                                            align="center"
                                                            sx={{
                                                                px: 0,
                                                                py: 0.8,
                                                                ...(month.num !== "12" && {
                                                                    borderBottom: "0.5px solid black",
                                                                }),
                                                            }}
                                                        >
                                                            {item[Object.keys(item)[0]][month.num] &&
                                                            item[Object.keys(item)[0]][month.num].status &&
                                                            (!isNaN(
                                                                item[Object.keys(item)[0]][month.num].expenseScale
                                                            ) ||
                                                                !isNaN(
                                                                    item[Object.keys(item)[0]][month.num].expenseAverage
                                                                ) ||
                                                                !isNaN(
                                                                    item[Object.keys(item)[0]][month.num].realExpense
                                                                )) &&
                                                            moment(Object.keys(item)[0] + "-" + month.num + "-01")
                                                                .utc()
                                                                .isSameOrAfter(moment.utc(client.since_date)) ? (
                                                                <>
                                                                    {item[Object.keys(item)[0]][month.num].status ===
                                                                        1 && (
                                                                        <IconButton
                                                                            onClick={() => {
                                                                                handleUpdateMonthExpenses(
                                                                                    client._id,
                                                                                    month.num,
                                                                                    Object.keys(item)[0]
                                                                                );
                                                                                forceUpdate();
                                                                            }}
                                                                            sx={{ color: "#747474" }}
                                                                            disabled={isDisabled || expenses === -1}
                                                                        >
                                                                            <SyncIcon />
                                                                        </IconButton>
                                                                    )}
                                                                    {item[Object.keys(item)[0]][month.num].status ===
                                                                        2 && (
                                                                        <Box
                                                                            sx={{
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                                justifyContent: "center",
                                                                            }}
                                                                        >
                                                                            <Typography>
                                                                                {t("bankingDashboard.updating")}
                                                                            </Typography>
                                                                        </Box>
                                                                    )}
                                                                </>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </CustomTableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            </TabPanel>
                        ))}
                    </>
                )}
            </DialogContent>
            {openMonth && (
                <DialogActions>
                    <Grid container spacing={2}>
                        <Grid item>
                            <ColorButton
                                onClick={handleBack}
                                sx={{
                                    mr: 1,
                                    "&:hover": {
                                        backgroundColor: "white",
                                        color: "#2f3190",
                                        border: "1px solid #2f3190",
                                    },
                                }}
                                color="secondary"
                                disableElevation
                                variant="outlined"
                            >
                                {t("miscellaneous.goBack")}
                            </ColorButton>
                        </Grid>
                    </Grid>
                </DialogActions>
            )}
        </CustomDialog>
    );
}
