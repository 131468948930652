/** React imports */
import React from "react";

/** MUI imports */
import { styled } from "@mui/material/styles";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

const CustomDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(4),
    },
    "& .MuiDialogTitle-root": {
        backgroundColor: "#031851",
        fontSize: "18px",
        color: "#fff",
    },
    "& .MuiDialog-paper": {
        borderRadius: 8,
        boxShadow: "10px 10px 15px rgba(3,24,81,0.15)",
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(2),
    },
}));

export default function AlertDialog(props) {
    const { open, onClose, maxWidth, title, message, button, companyName } = props;

    return (
        <CustomDialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={maxWidth}
            fullWidth
        >
            <DialogTitle id="alert-dialog-title">
                {title}
                {companyName && " / "}
                {companyName && <span style={{ color: "#ff5968" }}>{companyName}</span>}
            </DialogTitle>
            <DialogContent dividers>{message}</DialogContent>
            <DialogActions>{button}</DialogActions>
        </CustomDialog>
    );
}
