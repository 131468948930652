/** React imports */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import moment from "moment";

/** MUI imports */
import { styled } from "@mui/material/styles";
import { Box, Skeleton } from "@mui/material";
import { Grid, Table, TableHeaderRow, PagingPanel } from "@devexpress/dx-react-grid-material-ui";
import { PagingState, IntegratedPaging } from "@devexpress/dx-react-grid";

/** MUI icons */
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import RemoveIcon from "@mui/icons-material/Remove";

const PREFIX = "ExpenseHistoryMonth";

const classes = {
    paper: `${PREFIX}-paper`,
    tableHeader: `${PREFIX}-tableHeader`,
    tableBody: `${PREFIX}-tableBody`,
    pager: `${PREFIX}-pager`,
};

const CustomContainer = styled(Box)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(4),
    },
    "& .MuiDialogTitle-root": {
        backgroundColor: "#031851",
        fontSize: "18px",
        color: "#fff",
    },
    "& .MuiDialog-paper": {
        borderRadius: 8,
        boxShadow: "10px 10px 15px rgba(3,24,81,0.15)",
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(2),
    },
    [`& .${classes.tableHeader}`]: {
        fontSize: 16,
        fontWeight: 700,
        color: "#031851",
        borderBottom: "0.5px solid black",
    },

    [`& .${classes.tableBody}`]: {
        fontSize: "14px",
        fontWeight: 400,
        color: "#031851",
        borderBottom: "0.5px solid black",
    },

    [`& .${classes.pager}`]: {
        "& .Pagination-button": {
            paddingTop: 0,
            paddingBottom: 0,
            color: "#031851",
            fontWeight: 600,
        },
        "& .Pagination-activeButton": {
            backgroundColor: "rgba(47,49,144,0.3)",
        },
    },
}));

const tableHeaderCell = ({ ...props }) => <TableHeaderRow.Cell {...props} className={classes.tableHeader} />;

const TableCellComponent = ({ ...props }) => <Table.Cell {...props} className={classes.tableBody} />;

const PagingPanelContainer = ({ ...props }) => <PagingPanel.Container {...props} className={classes.pager} />;

const tableColumnExtensions = [
    { columnName: "number", align: "center" },
    { columnName: "realExpense", align: "right" },
    { columnName: "expenseAvg", align: "right" },
    { columnName: "expenseScale", align: "right" },
    { columnName: "date", align: "center" },
    { columnName: "user", align: "center" },
];

const rowsSkeleton = [];

for (let i = 0; i < 10; i++) {
    rowsSkeleton.push({
        number: <Skeleton variant="text" animation="wave" />,
        realExpense: <Skeleton variant="text" animation="wave" />,
        expenseAvg: <Skeleton variant="text" animation="wave" />,
        expenseScale: <Skeleton variant="text" animation="wave" />,
        date: <Skeleton variant="text" animation="wave" />,
        user: <Skeleton variant="text" animation="wave" />,
    });
}

export default function ExpenseHistoryMonth(props) {
    const { dateHistory, id, months } = props;

    /** Internationalization i18n */
    const [t] = useTranslation("global");
    const todos = useSelector((state) => state.todos);

    const tableMessages = {
        noData: t("miscellaneous.noData"),
    };
    const pagingMessages = {
        info: ({ from, to, count }) => `${from}${from < to ? `-${to}` : ""} ${t("miscellaneous.pagingOf")} ${count}`,
    };

    const [columnDefs, setColumnsDefs] = useState([]);
    const [history, setHistory] = useState([]);

    useEffect(() => {
        (async () => {
            const columnsTemplate = [
                { name: "number", title: "#" },
                { name: "realExpense", title: t("currentView.realExpense") },
                { name: "expenseAvg", title: t("currentView.expenseAvg") },
                { name: "expenseScale", title: t("miscellaneous.expenseScale") },
                { name: "date", title: t("miscellaneous.date") },
                { name: "user", title: t("login.userAccess") },
            ];
            setColumnsDefs(columnsTemplate);
            setHistory(rowsSkeleton);

            let initValues = [];
            let queries = dateHistory.split(" ");
            let month = "";

            months.forEach((element) => {
                if (element.name === queries[0]) month = element.num;
            });

            await fetch(`/getHistoryMonthlyExp?id_organisation=${id}&year=${queries[1]}&month=${month}`, {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
                .then((res) => res.json())
                .then((data) => {
                    if (data.error) {
                        console.log(data.error);
                    } else {
                        initValues = data.map((item, index) => {
                            return {
                                number: index + 1,
                                realExpense:
                                    "$ " +
                                    parseFloat(item.expenses).toLocaleString(todos.amountFormat, {
                                        maximumFractionDigits: 2,
                                    }),
                                expenseAvg:
                                    "$ " +
                                    parseFloat(item.expenses_average).toLocaleString(todos.amountFormat, {
                                        maximumFractionDigits: 2,
                                    }),
                                expenseScale: (
                                    <Box sx={{ display: "flex", justifyContent: "end" }}>
                                        $ {item.expenses_scale / 1000}k
                                        {item.indicator === "up" && <ArrowDropUpIcon sx={{ color: "#51D67D" }} />}
                                        {item.indicator === "down" && <ArrowDropDownIcon sx={{ color: "#FF5968" }} />}
                                        {item.indicator === "equal" && <RemoveIcon sx={{ color: "#D9D9D9" }} />}
                                    </Box>
                                ),
                                date: moment(item.current_date, "YYYY-MM-DD").format(todos.dateFormat),
                                user: item.user_name,
                            };
                        });

                        setHistory(initValues);
                    }
                });
        })();
    }, [dateHistory, id, months, t, todos.amountFormat, todos.dateFormat]);

    return (
        <CustomContainer>
            <Grid rows={history} columns={columnDefs}>
                <PagingState defaultCurrentPage={0} pageSize={10} />
                <IntegratedPaging />
                <Table
                    columnExtensions={tableColumnExtensions}
                    cellComponent={TableCellComponent}
                    messages={tableMessages}
                />
                <TableHeaderRow cellComponent={tableHeaderCell} />
                <PagingPanel containerComponent={PagingPanelContainer} messages={pagingMessages} />
            </Grid>
        </CustomContainer>
    );
}
