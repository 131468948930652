/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";

/** MUI imports */
import { styled } from "@mui/material/styles";
import { Box, Chip, Grid, Typography, Divider, FormControlLabel, Checkbox } from "@mui/material";

/** Component imports */
import { Onboarding, CleanUp, CatchUp, Recurring, Consulting } from "./";

/** Component styles */
const PREFIX = "BookkeeperRoot";

const classes = {
    cardContentRoot: `${PREFIX}-cardContentRoot`,
    chip: `${PREFIX}-chip`,
    chipProActive: `${PREFIX}-chipProActive`,
    chipProWasActive: `${PREFIX}-chipProWasActive`,
    selectedChip: `${PREFIX}-selectedChip`,
    selectedChipActive: `${PREFIX}-selectedChipActive`,
    selectedChipWasActive: `${PREFIX}-selectedChipWasActive`,
    divider: `${PREFIX}-divider`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.cardContentRoot}`]: {
        padding: "24px 48px 48px",
    },

    [`& .${classes.chip}`]: {
        padding: 20,
        width: 200,
        backgroundColor: "#EAEAF4",
        color: "#031851",
        fontWeight: "700 !important",
        "&:hover": {
            backgroundColor: "rgba(213, 214, 233, 0.3)",
            color: "#031851",
        },
        "& .MuiChip-label": {
            padding: 0,
        },
        borderRadius: 40,
    },

    [`& .${classes.chipProActive}`]: {
        padding: 20,
        width: 200,
        backgroundColor: "#EAEAF4",
        color: "#031851",
        border: "2px solid #ff5968",
        fontWeight: "700 !important",
        "&:hover": {
            backgroundColor: "rgba(213, 214, 233, 0.3)",
            color: "#031851",
        },
        "& .MuiChip-label": {
            padding: 0,
        },
        borderRadius: 40,
    },

    [`& .${classes.chipProWasActive}`]: {
        padding: 20,
        width: 200,
        backgroundColor: "#EAEAF4",
        color: "#031851",
        border: "2px solid #031851",
        fontWeight: "700 !important",
        "&:hover": {
            backgroundColor: "rgba(213, 214, 233, 0.3)",
            color: "#031851",
        },
        "& .MuiChip-label": {
            padding: 0,
        },
        borderRadius: 40,
    },

    [`& .${classes.selectedChip}`]: {
        padding: 20,
        width: 200,
        backgroundColor: "#2f3190",
        color: "#fff",
        fontWeight: "700 !important",
        borderRadius: 40,
        "&:hover": {
            backgroundColor: "rgba(47, 49, 144, 0.9)",
            color: "#fff",
        },
        "& .MuiChip-label": {
            padding: 0,
        },
    },

    [`& .${classes.selectedChipActive}`]: {
        padding: 20,
        width: 200,
        backgroundColor: "#2f3190",
        color: "#fff",
        fontWeight: "700 !important",
        border: "2px solid #ff5968",
        borderRadius: 40,
        "&:hover": {
            backgroundColor: "rgba(47, 49, 144, 0.9)",
            color: "#fff",
        },
        "& .MuiChip-label": {
            padding: 0,
        },
    },

    [`& .${classes.selectedChipWasActive}`]: {
        padding: 20,
        width: 200,
        backgroundColor: "#2f3190",
        color: "#fff",
        fontWeight: "700 !important",
        border: "2px solid #031851",
        borderRadius: 40,
        "&:hover": {
            backgroundColor: "rgba(47, 49, 144, 0.9)",
            color: "#fff",
        },
        "& .MuiChip-label": {
            padding: 0,
        },
    },

    [`& .${classes.divider}`]: {
        marginBottom: 10,
    },
}));

export const BookkeeperRoot = (props) => {
    // Properties
    const {
        actives,
        bookkeeperSystem,
        clientMarket,
        companyID,
        expenseEst,
        serviceData,
        setalertType,
        setBackDrop,
        setServiceData,
        setShow,
        setTexto,
        setAuxExpanded,
        setExpanded,
        lastChipSelected,
        setLastChipSelected,
    } = props;

    /** Internationalization i18n */
    const [t] = useTranslation("global");
    const [renderState, setRenderState] = useState(false);

    /* Component states */
    const [valuesBook, setValuesBook] = useState({
        bookOnboard: false,
        bookCleanUp: false,
        bookCatchUp: false,
        bookRecu: false,
        bookConsul: false,
    });
    const [projectsData, setProjectsData] = useState({
        bookOnboard: {},
        bookCleanUp: {},
        bookCatchUp: {},
        bookRecu: {},
        bookConsul: {},
    });
    const [active, setActive] = useState({
        bookOnboard: {
            isActive: false,
            wasActive: false,
        },
        bookCleanUp: {
            isActive: false,
            wasActive: false,
        },
        bookCatchUp: {
            isActive: false,
            wasActive: false,
        },
        bookRecu: {
            isActive: false,
            wasActive: false,
        },
        bookConsul: {
            isActive: false,
            wasActive: false,
        },
    });
    const [open, setOpen] = useState(false);
    const [infoAdd, setInfoAdd] = useState({});
    const [checkRemind, setCheckRemind] = useState(false);

    const handleCloseDialog = () => {
        setInfoAdd({});
        setCheckRemind(false);
        setOpen(false);
    };

    const showConfirm = (value) => {
        setInfoAdd(value);
        setOpen(true);
    };

    const handleChangeCheck = (event) => {
        setCheckRemind(event.target.checked);
    };

    const bodyMess = (
        <>
            <Typography variant="h1" color="primary" align="center" sx={{ fontSize: 18, pt: 3, pb: 2 }}>
                {t("miscellaneous.confRemoveCheckDet")}
            </Typography>
            <FormControlLabel
                control={
                    <Checkbox
                        name="checkedConf"
                        checked={checkRemind}
                        onChange={handleChangeCheck}
                        inputProps={{
                            "aria-label": "controlled",
                        }}
                    />
                }
                label={t("miscellaneous.dontShowAgain")}
            />
        </>
    );

    /* Component functions */
    const getProjects = async (isReload) => {
        setBackDrop(true);

        let serviceCompID = serviceData._id ?? "";

        if (isReload) {
            await fetch(`/getNewServicesCompany?company_id=${companyID}`, {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
                .then((res) => res.json())
                .then(async (data) => {
                    setServiceData(data);

                    let lastProject = data.filter(function (o) {
                        return o.hasOwnProperty("_id") && o.service_name === "Bookkeeper";
                    })[0];

                    await fetch(
                        `/getProjectsCompany?company_id=${lastProject.company_id}&service_id=${lastProject.service_id}&service_company_id=${lastProject._id}`,
                        {
                            method: "GET",
                            headers: {
                                "Content-type": "application/json; charset=UTF-8",
                                "Access-Control-Allow-Origin": "*",
                            },
                        }
                    )
                        .then((res) => res.json())
                        .then(async(data) => {
                            let result = {};
                            let lastProyects = {
                                cleanUp: {},
                                catchUp: {},
                                recurring: {},
                                consulting: {},
                            };
                            let recurringisActive = false;
                            let wasRecurringActive = false;

                            await data.forEach((item) => {
                                switch (item.project_type) {
                                    case 22:
                                        result.bookOnboard = item;
                                        break;
                                    case 23:
                                        result.bookRecu = item;
                                        if (item.projects_array.length !== 0) {
                                            let indexExact = 0;
                                            let dateMax = null;
                                            item.projects_array.forEach((item, index) => {
                                                if (index === 0) {
                                                    dateMax = item.end_date;
                                                } else {
                                                    if (moment(dateMax).isBefore(item.end_date)) {
                                                        dateMax = item.end_date;
                                                        indexExact = index;
                                                    }
                                                }

                                                if (item.since_date && !item.client_delivery_date) {
                                                    recurringisActive = true;
                                                } else if (item.client_delivery_date) {
                                                    wasRecurringActive = moment(
                                                        item.client_delivery_date
                                                    ).isSameOrBefore(moment());
                                                }
                                            });
                                            lastProyects.recurring = item.projects_array[indexExact];
                                            result.bookRecu.project_company_id =
                                                item.projects_array[0].project_company_id;
                                        }
                                        break;
                                    case 24:
                                        result.bookCatchUp = item;
                                        if (item.projects_array.length !== 0) {
                                            lastProyects.catchUp = item.projects_array[0];
                                            result.bookCatchUp.project_company_id =
                                                item.projects_array[0].project_company_id;
                                        }
                                        break;
                                    case 25:
                                        result.bookCleanUp = item;
                                        if (item.projects_array.length !== 0) {
                                            lastProyects.cleanUp = item.projects_array[0];
                                            result.bookCleanUp.project_company_id =
                                                item.projects_array[0].project_company_id;
                                        }
                                        break;
                                    case 26:
                                        result.bookConsul = item;
                                        if (item.projects_array.length !== 0) {
                                            lastProyects.consulting = item.projects_array[0];
                                            result.bookConsul.project_company_id =
                                                item.projects_array[0].project_company_id;
                                        }
                                        break;
                                    default:
                                        break;
                                }
                            });

                            setProjectsData({
                                bookOnboard: result.bookOnboard,
                                bookCleanUp: result.bookCleanUp,
                                bookCatchUp: result.bookCatchUp,
                                bookRecu: result.bookRecu,
                                bookConsul: result.bookConsul,
                            });

                            setActive({
                                bookOnboard: {
                                    isActive:
                                        result.bookOnboard.since_date &&
                                        (!result.bookOnboard.end_date ||
                                            moment(result.bookOnboard.end_date).isAfter(moment())),
                                    wasActive: result.bookOnboard.end_date
                                        ? moment(result.bookOnboard.end_date).isSameOrBefore(moment())
                                        : false,
                                },
                                bookCleanUp: {
                                    isActive:
                                        lastProyects.cleanUp.since_date && !lastProyects.cleanUp.client_delivery_date,
                                    wasActive: lastProyects.cleanUp.client_delivery_date
                                        ? moment(lastProyects.cleanUp.client_delivery_date).isSameOrBefore(moment())
                                        : false,
                                },
                                bookCatchUp: {
                                    isActive:
                                        lastProyects.catchUp.since_date && !lastProyects.catchUp.client_delivery_date,
                                    wasActive: lastProyects.catchUp.client_delivery_date
                                        ? moment(lastProyects.catchUp.client_delivery_date).isSameOrBefore(moment())
                                        : false,
                                },
                                bookRecu: {
                                    isActive: recurringisActive,
                                    wasActive: wasRecurringActive,
                                },
                                bookConsul: {
                                    isActive: false,
                                    wasActive: lastProyects.consulting.since_date ? true : false,
                                },
                            });

                            setAuxExpanded(lastProject.service_name);
                            setExpanded(true);
                            setRenderState(!renderState);
                        });
                });
        } else {
            await fetch(
                `/getProjectsCompany?company_id=${companyID}&service_id=${serviceData.service_id}&service_company_id=${serviceCompID}`,
                {
                    method: "GET",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                    },
                }
            )
                .then((res) => res.json())
                .then((data) => {
                    let result = {};
                    let lastProyects = {
                        cleanUp: {},
                        catchUp: {},
                        recurring: {},
                        consulting: {},
                    };
                    let recurringisActive = false;
                    let wasRecurringActive = false;

                    data.forEach((item) => {
                        switch (item.project_type) {
                            case 22:
                                result.bookOnboard = item;
                                break;
                            case 23:
                                result.bookRecu = item;
                                if (item.projects_array.length !== 0) {
                                    let indexExact = 0;
                                    let dateMax = null;
                                    item.projects_array.forEach((item, index) => {
                                        if (index === 0) {
                                            dateMax = item.end_date;
                                        } else {
                                            if (moment(dateMax).isBefore(item.end_date)) {
                                                dateMax = item.end_date;
                                                indexExact = index;
                                            }
                                        }

                                        if (item.since_date && !item.client_delivery_date) {
                                            recurringisActive = true;
                                        } else if (item.client_delivery_date) {
                                            wasRecurringActive = moment(item.client_delivery_date).isSameOrBefore(
                                                moment()
                                            );
                                        }
                                    });
                                    lastProyects.recurring = item.projects_array[indexExact];
                                    result.bookRecu.project_company_id = item.projects_array[0].project_company_id;
                                }
                                break;
                            case 24:
                                result.bookCatchUp = item;
                                if (item.projects_array.length !== 0) {
                                    lastProyects.catchUp = item.projects_array[0];
                                    result.bookCatchUp.project_company_id = item.projects_array[0].project_company_id;
                                }
                                break;
                            case 25:
                                result.bookCleanUp = item;
                                if (item.projects_array.length !== 0) {
                                    lastProyects.cleanUp = item.projects_array[0];
                                    result.bookCleanUp.project_company_id = item.projects_array[0].project_company_id;
                                }
                                break;
                            case 26:
                                result.bookConsul = item;
                                if (item.projects_array.length !== 0) {
                                    lastProyects.consulting = item.projects_array[0];
                                    result.bookConsul.project_company_id = item.projects_array[0].project_company_id;
                                }
                                break;
                            default:
                                break;
                        }
                    });

                    setProjectsData({
                        bookOnboard: result.bookOnboard,
                        bookCleanUp: result.bookCleanUp,
                        bookCatchUp: result.bookCatchUp,
                        bookRecu: result.bookRecu,
                        bookConsul: result.bookConsul,
                    });

                    setActive({
                        bookOnboard: {
                            isActive:
                                result.bookOnboard.since_date &&
                                (!result.bookOnboard.end_date || moment(result.bookOnboard.end_date).isAfter(moment())),
                            wasActive: result.bookOnboard.end_date
                                ? moment(result.bookOnboard.end_date).isSameOrBefore(moment())
                                : false,
                        },
                        bookCleanUp: {
                            isActive: lastProyects.cleanUp.since_date && !lastProyects.cleanUp.client_delivery_date,
                            wasActive: lastProyects.cleanUp.client_delivery_date
                                ? moment(lastProyects.cleanUp.client_delivery_date).isSameOrBefore(moment())
                                : false,
                        },
                        bookCatchUp: {
                            isActive: lastProyects.catchUp.since_date && !lastProyects.catchUp.client_delivery_date,
                            wasActive: lastProyects.catchUp.client_delivery_date
                                ? moment(lastProyects.catchUp.client_delivery_date).isSameOrBefore(moment())
                                : false,
                        },
                        bookRecu: {
                            isActive: recurringisActive,
                            wasActive: wasRecurringActive,
                        },
                        bookConsul: {
                            isActive: false,
                            wasActive: lastProyects.consulting.since_date ? true : false,
                        },
                    });
                });
        }

        setBackDrop(false);
    };

    useEffect(() => {
        getProjects(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /* Component functions */
    useEffect(() => {
        if (lastChipSelected) {
            handleClickChip(valuesBook[lastChipSelected], lastChipSelected, false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [renderState]);

    const handleClickChip = async (value, option, setChip = false) => {
        switch (option) {
            case "bookOnboard":
                setValuesBook({
                    ...valuesBook,
                    bookOnboard: setChip ? !value : true,
                    bookCleanUp: false,
                    bookCatchUp: false,
                    bookRecu: false,
                    bookConsul: false,
                });
                break;
            case "bookCleanUp":
                setValuesBook({
                    ...valuesBook,
                    bookCleanUp: setChip ? !value : true,
                    bookOnboard: false,
                    bookCatchUp: false,
                    bookRecu: false,
                    bookConsul: false,
                });
                break;
            case "bookCatchUp":
                setValuesBook({
                    ...valuesBook,
                    bookCatchUp: setChip ? !value : true,
                    bookCleanUp: false,
                    bookOnboard: false,
                    bookRecu: false,
                    bookConsul: false,
                });
                break;
            case "bookRecu":
                setValuesBook({
                    ...valuesBook,
                    bookRecu: setChip ? !value : true,
                    bookCatchUp: false,
                    bookCleanUp: false,
                    bookOnboard: false,
                    bookConsul: false,
                });
                break;
            default:
                setValuesBook({
                    ...valuesBook,
                    bookConsul: setChip ? !value : true,
                    bookRecu: false,
                    bookCatchUp: false,
                    bookCleanUp: false,
                    bookOnboard: false,
                });
                break;
        }
        if (setChip) {
            setLastChipSelected(option);
        }
    };

    return (
        <StyledContainer>
            <Divider className={classes.divider} />
            <Grid container justifyContent="space-around" alignItems="center" spacing={2} sx={{ pb: 2 }}>
                <Grid item>
                    <Chip
                        id="onboarding-chip"
                        label={
                            <Typography variant="h2" align="center" sx={{ fontWeight: 700 }}>
                                {t(`invoicing.smbOnboarding`)}
                            </Typography>
                        }
                        className={
                            valuesBook.bookOnboard
                                ? active.bookOnboard.isActive
                                    ? classes.selectedChipActive
                                    : active.bookOnboard.wasActive
                                    ? classes.selectedChipWasActive
                                    : classes.selectedChip
                                : active.bookOnboard.isActive
                                ? classes.chipProActive
                                : active.bookOnboard.wasActive
                                ? classes.chipProWasActive
                                : classes.chip
                        }
                        onClick={() => handleClickChip(valuesBook.bookOnboard, "bookOnboard", true)}
                    />
                </Grid>
                <Grid item>
                    <Chip
                        id="clean-up-chip"
                        label={
                            <Typography variant="h2" align="center" sx={{ fontWeight: 700 }}>
                                {t(`invoicing.cleanUp`)}
                            </Typography>
                        }
                        className={
                            valuesBook.bookCleanUp
                                ? active.bookCleanUp.isActive
                                    ? classes.selectedChipActive
                                    : active.bookCleanUp.wasActive
                                    ? classes.selectedChipWasActive
                                    : classes.selectedChip
                                : active.bookCleanUp.isActive
                                ? classes.chipProActive
                                : active.bookCleanUp.wasActive
                                ? classes.chipProWasActive
                                : classes.chip
                        }
                        onClick={() => handleClickChip(valuesBook.bookCleanUp, "bookCleanUp", true)}
                    />
                </Grid>
                <Grid item>
                    <Chip
                        id="catch-up-chip"
                        label={
                            <Typography variant="h2" align="center" sx={{ fontWeight: 700 }}>
                                {t(`invoicing.catchUp`)}
                            </Typography>
                        }
                        className={
                            valuesBook.bookCatchUp
                                ? active.bookCatchUp.isActive
                                    ? classes.selectedChipActive
                                    : active.bookCatchUp.wasActive
                                    ? classes.selectedChipWasActive
                                    : classes.selectedChip
                                : active.bookCatchUp.isActive
                                ? classes.chipProActive
                                : active.bookCatchUp.wasActive
                                ? classes.chipProWasActive
                                : classes.chip
                        }
                        onClick={() => handleClickChip(valuesBook.bookCatchUp, "bookCatchUp", true)}
                    />
                </Grid>
                <Grid item>
                    <Chip
                        id="recurring-chip"
                        label={
                            <Typography variant="h2" align="center" sx={{ fontWeight: 700 }}>
                                {t(`invoicing.recurring`)}
                            </Typography>
                        }
                        className={
                            valuesBook.bookRecu
                                ? active.bookRecu.isActive
                                    ? classes.selectedChipActive
                                    : active.bookRecu.wasActive
                                    ? classes.selectedChipWasActive
                                    : classes.selectedChip
                                : active.bookRecu.isActive
                                ? classes.chipProActive
                                : active.bookRecu.wasActive
                                ? classes.chipProWasActive
                                : classes.chip
                        }
                        onClick={() => handleClickChip(valuesBook.bookRecu, "bookRecu", true)}
                    />
                </Grid>
                <Grid item>
                    <Chip
                        id="consulting-chip"
                        label={
                            <Typography variant="h2" align="center" sx={{ fontWeight: 700 }}>
                                {t(`invoicing.consulting`)}
                            </Typography>
                        }
                        className={
                            valuesBook.bookConsul
                                ? active.bookConsul.isActive
                                    ? classes.selectedChipActive
                                    : active.bookConsul.wasActive
                                    ? classes.selectedChipWasActive
                                    : classes.selectedChip
                                : active.bookConsul.isActive
                                ? classes.chipProActive
                                : active.bookConsul.wasActive
                                ? classes.chipProWasActive
                                : classes.chip
                        }
                        onClick={() => handleClickChip(valuesBook.bookConsul, "bookConsul", true)}
                    />
                </Grid>
            </Grid>
            {valuesBook.bookOnboard && (
                <Onboarding
                    setTexto={setTexto}
                    setalertType={setalertType}
                    setShow={setShow}
                    setBackDrop={setBackDrop}
                    companyID={companyID}
                    reloadProjects={getProjects}
                    actives={actives}
                    data={projectsData.bookOnboard}
                    serviceID={serviceData.service_id}
                    bookkeeperSystem={bookkeeperSystem}
                    expenseEst={expenseEst}
                />
            )}
            {valuesBook.bookCleanUp && (
                <CleanUp
                    setTexto={setTexto}
                    setalertType={setalertType}
                    setShow={setShow}
                    setBackDrop={setBackDrop}
                    clientMarket={clientMarket}
                    companyID={companyID}
                    expenseEst={expenseEst}
                    bookkeeperSystem={bookkeeperSystem}
                    reloadProjects={getProjects}
                    actives={actives}
                    data={projectsData.bookCleanUp}
                    serviceID={serviceData.service_id}
                    handleCloseDialog={handleCloseDialog}
                    infoAdd={infoAdd}
                    checkRemind={checkRemind}
                    open={open}
                    bodyMess={bodyMess}
                    showConfirm={showConfirm}
                />
            )}
            {valuesBook.bookCatchUp && (
                <CatchUp
                    setTexto={setTexto}
                    setalertType={setalertType}
                    setShow={setShow}
                    setBackDrop={setBackDrop}
                    clientMarket={clientMarket}
                    companyID={companyID}
                    expenseEst={expenseEst}
                    bookkeeperSystem={bookkeeperSystem}
                    reloadProjects={getProjects}
                    actives={actives}
                    data={projectsData.bookCatchUp}
                    serviceID={serviceData.service_id}
                    handleCloseDialog={handleCloseDialog}
                    infoAdd={infoAdd}
                    checkRemind={checkRemind}
                    open={open}
                    bodyMess={bodyMess}
                    showConfirm={showConfirm}
                />
            )}
            {valuesBook.bookRecu && (
                <Recurring
                    setTexto={setTexto}
                    setalertType={setalertType}
                    setShow={setShow}
                    setBackDrop={setBackDrop}
                    clientMarket={clientMarket}
                    companyID={companyID}
                    expenseEst={expenseEst}
                    bookkeeperSystem={bookkeeperSystem}
                    reloadProjects={getProjects}
                    data={projectsData.bookRecu}
                    actives={actives}
                    serviceID={serviceData.service_id}
                    handleCloseDialog={handleCloseDialog}
                    infoAdd={infoAdd}
                    checkRemind={checkRemind}
                    open={open}
                    bodyMess={bodyMess}
                    showConfirm={showConfirm}
                />
            )}
            {valuesBook.bookConsul && (
                <Consulting
                    setTexto={setTexto}
                    setalertType={setalertType}
                    setShow={setShow}
                    setBackDrop={setBackDrop}
                    companyID={companyID}
                    reloadProjects={getProjects}
                    data={projectsData.bookConsul}
                    actives={actives}
                    serviceID={serviceData.service_id}
                />
            )}
        </StyledContainer>
    );
};
