/** React imports */
import React from "react";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import { Provider } from "react-redux";
import { store, persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";

/** Components */
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "./themeSettings";
import util from "./components/Js/util";

/** Global variables */
import global_es from "./i18n/es/global.json";
import global_en from "./i18n/en/global.json";
import "moment/locale/es";

i18next.init({
    interpolation: { escapeValue: false },
    lng: util.getSessionStorageLanguage(),
    fallbackLng: util.getSessionStorageLanguage(),
    resources: {
        es: {
            global: global_es,
        },
        en: {
            global: global_en,
        },
    },
});

ReactDOM.render(
    <React.StrictMode>
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Provider store={store}>
                    <PersistGate loading={null} persistor={persistor}>
                        <I18nextProvider i18n={i18next}>
                            <App />
                        </I18nextProvider>
                    </PersistGate>
                </Provider>
            </ThemeProvider>
        </StyledEngineProvider>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
