/** React */
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

/** MUI icons */
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

/** MUI */
import { styled } from "@mui/material/styles";
import { Box, Card, CardContent, Chip, Grid, Typography } from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

import moment from "moment";

/** Component styles */
const PREFIX = "BookkeeperSummary";

const classes = {
    cardContentRoot: `${PREFIX}-cardContentRoot`,
    summaryLabels: `${PREFIX}-summaryLabels`,
    summaryDetails: `${PREFIX}-summaryDetails`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.cardContentRoot}`]: {
        padding: "24px 30px 30px !important",
    },

    [`& .${classes.summaryLabels}`]: {
        display: "flex",
        justifyContent: "space-between",
        padding: "24px 0px 8px",
        borderBottom: "1px solid #cccc",
    },

    [`& .${classes.summaryDetails}`]: {
        display: "flex",
        justifyContent: "space-between",
        paddingLeft: "16px",
    },
}));

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)({
    boxShadow: "none",
    "&:before": {
        display: "none",
    },
});

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: "#031851" }} />} {...props} />
))({
    "& .MuiAccordionSummary-content": {
        margin: "12px 0",
    },
});

const AccordionDetails = styled(MuiAccordionDetails)({
    padding: "0px 0px 20px 0px",
    "& .MuiCard-root": {
        boxShadow: "none",
    },
});

export const BookkeeperSummary = (props) => {
    /** Properties */
    const {
        tariffAdditionals,
        valuesBookRecu,
        valuesBookCleanUp,
        valuesBookCatchUp,
        valuesBookOnboard,
        valuesBookConsul,
    } = props;

    /** Internationalization i18n */
    const [t] = useTranslation("global");

    /** React redux */
    const todos = useSelector((state) => state.todos);

    /** Component states */
    const [expanded, setExpanded] = useState(true);

    /** Component functions */
    const handleChangeSumaryAccordion = (event, isExpanded) => {
        setExpanded(isExpanded);
    };

    const hoursBookCleanUp =
        valuesBookCleanUp.tothBook *
        (valuesBookCleanUp.fixedHours ? 1 : Math.ceil(
            moment(valuesBookCleanUp.finishDateBook).diff(moment(valuesBookCleanUp.startDateBook), "months", true) ?? 0
        )) *
        tariffAdditionals *
        (1 - valuesBookCleanUp.discount / 100);

    const hoursBookCatchUp =
        valuesBookCatchUp.tothBook *
        (valuesBookCatchUp.fixedHours ? 1 : Math.ceil(
            moment(valuesBookCatchUp.finishDateBook).diff(moment(valuesBookCatchUp.startDateBook), "months", true) ?? 0
        )) *
        tariffAdditionals *
        (1 - valuesBookCatchUp.discount / 100);

    const hoursBookRecurring = valuesBookRecu.tothBook * tariffAdditionals * (1 - valuesBookRecu.recuDiscount / 100);

    return (
        <StyledContainer>
            <Card
                elevation={0}
                sx={{
                    mt: 3,
                    borderRadius: 8,
                }}
            >
                <CardContent sx={{ pt: 1 }}>
                    <Accordion
                        expanded={expanded}
                        onChange={handleChangeSumaryAccordion}
                        TransitionProps={{ unmountOnExit: true }}
                    >
                        <AccordionSummary
                            aria-controls="panel-content"
                            id="panel-header"
                            sx={{
                                borderBottom: "2px solid #ff5968",
                            }}
                        >
                            <Grid container spacing={2} alignItems="center" justifyContent="space-between">
                                <Grid item>
                                    <Typography
                                        variant="h2"
                                        align="center"
                                        sx={{
                                            fontWeight: 700,
                                        }}
                                    >
                                        {t(`invoicing.bookkeeper`)}
                                    </Typography>
                                </Grid>
                                <Grid item sx={{ pr: 1 }}>
                                    {valuesBookRecu.chip && (
                                        <Typography variant="h3" sx={{ pb: 0.5 }}>
                                            {`$${parseFloat(
                                                !valuesBookRecu.chip
                                                    ? 0
                                                    : (valuesBookRecu.recuAdditionalsOnly
                                                          ? hoursBookRecurring
                                                          : valuesBookRecu.recuProjectTotal) +
                                                          valuesBookRecu.recuHours *
                                                              tariffAdditionals *
                                                              (1 - valuesBookRecu.recuDiscount / 100)
                                            ).toLocaleString(todos.amountFormat, {
                                                maximumFractionDigits: 2,
                                            })}/${t(`proposals.month`)}`}
                                        </Typography>
                                    )}
                                    <Typography variant="h3">
                                        {`$${parseFloat(
                                            (!valuesBookOnboard.chip ? 0 : valuesBookOnboard.onboardProjectTotal) +
                                                (!valuesBookCleanUp.chip
                                                    ? 0
                                                    : (valuesBookCleanUp.additionalsOnly
                                                          ? hoursBookCleanUp
                                                          : valuesBookCleanUp.projectTotal) +
                                                      valuesBookCleanUp.totalHours *
                                                          tariffAdditionals *
                                                          (1 - valuesBookCleanUp.discount / 100)) +
                                                (!valuesBookCatchUp.chip
                                                    ? 0
                                                    : (valuesBookCatchUp.additionalsOnly
                                                          ? hoursBookCatchUp
                                                          : valuesBookCatchUp.projectTotal) +
                                                      valuesBookCatchUp.totalHours *
                                                          tariffAdditionals *
                                                          (1 - valuesBookCatchUp.discount / 100)) +
                                                (!valuesBookConsul.chip ? 0 : valuesBookConsul.consulProjectTotal)
                                        ).toLocaleString(todos.amountFormat, {
                                            maximumFractionDigits: 2,
                                        })}/${t(`proposals.oneTime`)}`}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            {valuesBookOnboard.chip && (
                                <Grid container justifyContent="flex-end" spacing={2}>
                                    <Grid item xs={11}>
                                        <Box className={classes.summaryLabels}>
                                            <Typography
                                                variant="h2"
                                                align="center"
                                                sx={{
                                                    display: "block",
                                                    mt: "auto",
                                                    mb: "auto",
                                                    fontWeight: 700,
                                                    color: valuesBookOnboard.isError? "#ff5968": "primary"
                                                }}
                                            >
                                                {t(`invoicing.onboarding`)}
                                            </Typography>
                                            <Chip
                                                id="project-total-chip"
                                                label={
                                                    <Typography variant="h3" align="center">
                                                        {`$${parseFloat(
                                                            valuesBookOnboard.onboardProjectTotal
                                                        ).toLocaleString(todos.amountFormat, {
                                                            maximumFractionDigits: 2,
                                                        })}/${t(`proposals.oneTime`)}`}
                                                    </Typography>
                                                }
                                                sx={{
                                                     //backgroundColor: "#2f3190",
                                                     backgroundColor: valuesBookOnboard.isError? "#ff5968": "#2f3190",
                                                    color: "#fff",
                                                }}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                            )}
                            {valuesBookCleanUp.chip && (
                                <Grid container justifyContent="flex-end" spacing={2}>
                                    <Grid item xs={11}>
                                        <Box className={classes.summaryLabels}>
                                            <Typography
                                                variant="h2"
                                                align="center"
                                                sx={{
                                                    display: "block",
                                                    mt: "auto",
                                                    mb: "auto",
                                                    fontWeight: 700,
                                                    color: valuesBookCleanUp.isError? "#ff5968": "primary"
                                                }}
                                            >
                                                {t(`invoicing.cleanUp`)}
                                            </Typography>
                                            <Chip
                                                id="project-total-chip"
                                                label={
                                                    <Typography variant="h3" align="center">
                                                        {`$${parseFloat(
                                                            (valuesBookCleanUp.additionalsOnly
                                                                ? hoursBookCleanUp
                                                                : valuesBookCleanUp.projectTotal) +
                                                                valuesBookCleanUp.totalHours *
                                                                    tariffAdditionals *
                                                                    (1 - valuesBookCleanUp.discount / 100)
                                                        ).toLocaleString(todos.amountFormat, {
                                                            maximumFractionDigits: 2,
                                                        })}/${t(`proposals.oneTime`)}`}
                                                    </Typography>
                                                }
                                                sx={{
                                                    //backgroundColor: "#2f3190",
                                                    backgroundColor: valuesBookCleanUp.isError? "#ff5968": "#2f3190",
                                                    color: "#fff",
                                                }}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item container justifyContent="flex-end" spacing={1}>
                                        <Grid item xs={11}>
                                            <Box className={classes.summaryDetails}>
                                                <Typography
                                                    variant="h3"
                                                    align="center"
                                                    sx={{
                                                        display: "block",
                                                        mt: "auto",
                                                        mb: "auto",
                                                        fontWeight: 700,
                                                        color: valuesBookCleanUp.isError? "#ff5968": "primary"
                                                    }}
                                                >
                                                    {`${t("proposals.basicService")} (${valuesBookCleanUp.months} ${t(
                                                        "proposals.months"
                                                    )})`}
                                                </Typography>
                                                <>
                                                    <Typography
                                                        variant="body2"
                                                        align="center"
                                                        sx={{
                                                            display: "block",
                                                            mt: "auto !important",
                                                            mb: "auto !important",
                                                            color: valuesBookCleanUp.isError? "#ff5968": "primary"
                                                        }}
                                                    >
                                                        {`$${parseFloat(
                                                            valuesBookCleanUp.additionalsOnly
                                                                ? hoursBookCleanUp
                                                                : valuesBookCleanUp.projectTotal
                                                        ).toLocaleString(todos.amountFormat, {
                                                            maximumFractionDigits: 2,
                                                        })}/${t("proposals.oneTime")}`}
                                                    </Typography>
                                                </>
                                            </Box>
                                        </Grid>
                                        {valuesBookCleanUp.cleanUpChecks && (
                                            <Grid item xs={11}>
                                                <Box className={classes.summaryDetails}>
                                                    <Typography
                                                        variant="h3"
                                                        align="center"
                                                        sx={{
                                                            display: "block",
                                                            mt: "auto",
                                                            mb: "auto",
                                                            fontWeight: 700,
                                                            color: valuesBookCleanUp.isError? "#ff5968": "primary"
                                                        }}
                                                    >
                                                        {`${t(`proposals.addons`)} (${
                                                            valuesBookCleanUp.totalHours
                                                        } hrs)`}
                                                    </Typography>
                                                    <Typography
                                                        variant="body2"
                                                        align="center"
                                                        sx={{
                                                            display: "block",
                                                            mt: "auto !important",
                                                            mb: "auto !important",
                                                            color: valuesBookCleanUp.isError? "#ff5968": "primary"
                                                        }}
                                                    >
                                                        {`$${parseFloat(
                                                            valuesBookCleanUp.totalHours *
                                                                tariffAdditionals *
                                                                (1 - valuesBookCleanUp.discount / 100)
                                                        ).toLocaleString(todos.amountFormat, {
                                                            maximumFractionDigits: 2,
                                                        })}/${t(`proposals.oneTime`)}`}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            )}
                            {valuesBookCatchUp.chip && (
                                <Grid container justifyContent="flex-end" spacing={2}>
                                    <Grid item xs={11}>
                                        <Box className={classes.summaryLabels}>
                                            <Typography
                                                variant="h2"
                                                align="center"
                                                sx={{
                                                    display: "block",
                                                    mt: "auto",
                                                    mb: "auto",
                                                    fontWeight: 700,
                                                    color: valuesBookCatchUp.isError? "#ff5968": "primary"
                                                }}
                                            >
                                                {t(`invoicing.catchUp`)}
                                            </Typography>
                                            <Chip
                                                id="project-total-chip"
                                                label={
                                                    <Typography variant="h3" align="center">
                                                        {`$${parseFloat(
                                                            (valuesBookCatchUp.additionalsOnly
                                                                ? hoursBookCatchUp
                                                                : valuesBookCatchUp.projectTotal) +
                                                                valuesBookCatchUp.totalHours *
                                                                    tariffAdditionals *
                                                                    (1 - valuesBookCatchUp.discount / 100)
                                                        ).toLocaleString(todos.amountFormat, {
                                                            maximumFractionDigits: 2,
                                                        })}/${t(`proposals.oneTime`)}`}
                                                    </Typography>
                                                }
                                                sx={{
                                                    //backgroundColor: "#2f3190",
                                                    backgroundColor: valuesBookCatchUp.isError? "#ff5968": "#2f3190",
                                                    color: "#fff",
                                                }}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item container justifyContent="flex-end" spacing={1}>
                                        <Grid item xs={11}>
                                            <Box className={classes.summaryDetails}>
                                                <Typography
                                                    variant="h3"
                                                    align="center"
                                                    sx={{
                                                        display: "block",
                                                        mt: "auto",
                                                        mb: "auto",
                                                        fontWeight: 700,
                                                        color: valuesBookCatchUp.isError? "#ff5968": "primary"
                                                    }}
                                                >
                                                    {`${t("proposals.basicService")} (${valuesBookCatchUp.months} ${t(
                                                        "proposals.months"
                                                    )})`}
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    align="center"
                                                    sx={{
                                                        display: "block",
                                                        mt: "auto !important",
                                                        mb: "auto !important",
                                                        color: valuesBookCatchUp.isError? "#ff5968": "primary"
                                                    }}
                                                >
                                                    {`$${parseFloat(
                                                        valuesBookCatchUp.additionalsOnly
                                                            ? hoursBookCatchUp
                                                            : valuesBookCatchUp.projectTotal
                                                    ).toLocaleString(todos.amountFormat, {
                                                        maximumFractionDigits: 2,
                                                    })}/${t("proposals.oneTime")}`}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        {valuesBookCatchUp.catchUpChecks && (
                                            <Grid item xs={11}>
                                                <Box className={classes.summaryDetails}>
                                                    <Typography
                                                        variant="h3"
                                                        align="center"
                                                        sx={{
                                                            display: "block",
                                                            mt: "auto",
                                                            mb: "auto",
                                                            fontWeight: 700,
                                                            color: valuesBookCatchUp.isError? "#ff5968": "primary"
                                                        }}
                                                    >
                                                        {`${t(`proposals.addons`)} (${
                                                            valuesBookCatchUp.totalHours
                                                        } hrs)`}
                                                    </Typography>
                                                    <Typography
                                                        variant="body2"
                                                        align="center"
                                                        sx={{
                                                            display: "block",
                                                            mt: "auto !important",
                                                            mb: "auto !important",
                                                            color: valuesBookCatchUp.isError? "#ff5968": "primary"
                                                        }}
                                                    >
                                                        {`$${parseFloat(
                                                            valuesBookCatchUp.totalHours *
                                                                tariffAdditionals *
                                                                (1 - valuesBookCatchUp.discount / 100)
                                                        ).toLocaleString(todos.amountFormat, {
                                                            maximumFractionDigits: 2,
                                                        })}/${t(`proposals.oneTime`)}`}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            )}
                            {valuesBookRecu.chip && (
                                <Grid container justifyContent="flex-end" spacing={2}>
                                    <Grid item xs={11}>
                                        <Box className={classes.summaryLabels}>
                                            <Typography
                                                variant="h2"
                                                align="center"
                                                sx={{
                                                    display: "block",
                                                    mt: "auto",
                                                    mb: "auto",
                                                    fontWeight: 700,
                                                    color: valuesBookRecu.isError? "#ff5968": "primary"
                                                }}
                                            >
                                                {`${t(`invoicing.recurring`)}`}
                                            </Typography>
                                            <Chip
                                                id="project-total-chip"
                                                label={
                                                    <Typography variant="h3" align="center">
                                                        {`$${parseFloat(
                                                            (valuesBookRecu.recuAdditionalsOnly
                                                                ? hoursBookRecurring
                                                                : valuesBookRecu.recuProjectTotal) +
                                                                valuesBookRecu.recuHours *
                                                                    tariffAdditionals *
                                                                    (1 - valuesBookRecu.recuDiscount / 100)
                                                        ).toLocaleString(todos.amountFormat, {
                                                            maximumFractionDigits: 2,
                                                        })}/${t(`proposals.month`)}`}
                                                    </Typography>
                                                }
                                                sx={{
                                                    //backgroundColor: "#2f3190",
                                                    backgroundColor: valuesBookRecu.isError? "#ff5968": "#2f3190",
                                                    color: "#fff",
                                                }}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item container justifyContent="flex-end" spacing={1}>
                                        <Grid item xs={11}>
                                            <Box className={classes.summaryDetails}>
                                                <Typography
                                                    variant="h3"
                                                    align="center"
                                                    sx={{
                                                        fontWeight: 700,
                                                        color: valuesBookRecu.isError? "#ff5968": "primary"
                                                    }}
                                                >
                                                    {t(`proposals.basicService`)}
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    align="center"
                                                    sx={{
                                                        display: "block",
                                                        mt: "auto !important",
                                                        mb: "auto !important",
                                                        color: valuesBookRecu.isError? "#ff5968": "primary"
                                                    }}
                                                >
                                                    {`$${parseFloat(
                                                        valuesBookRecu.recuAdditionalsOnly
                                                            ? hoursBookRecurring
                                                            : valuesBookRecu.recuProjectTotal
                                                    ).toLocaleString(todos.amountFormat, {
                                                        maximumFractionDigits: 2,
                                                    })}/${t(`proposals.month`)}`}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        {valuesBookRecu.recuChecks && (
                                            <Grid item xs={11}>
                                                <Box className={classes.summaryDetails}>
                                                    <Typography
                                                        variant="h3"
                                                        align="center"
                                                        sx={{
                                                            display: "block",
                                                            mt: "auto",
                                                            mb: "auto",
                                                            fontWeight: 700,
                                                            color: valuesBookRecu.isError? "#ff5968": "primary"
                                                        }}
                                                    >
                                                        {`${t(`proposals.addons`)} (${valuesBookRecu.recuHours} hrs)*`}
                                                    </Typography>
                                                    <Typography
                                                        variant="body2"
                                                        align="center"
                                                        sx={{
                                                            display: "block",
                                                            mt: "auto !important",
                                                            mb: "auto !important",
                                                            color: valuesBookRecu.isError? "#ff5968": "primary"
                                                        }}
                                                    >
                                                        {`$${parseFloat(
                                                            valuesBookRecu.recuHours *
                                                                tariffAdditionals *
                                                                (1 - valuesBookRecu.recuDiscount / 100)
                                                        ).toLocaleString(todos.amountFormat, {
                                                            maximumFractionDigits: 2,
                                                        })}/${t(`proposals.month`)}`}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            )}
                            {valuesBookConsul.chip && (
                                <Grid container justifyContent="flex-end" spacing={2}>
                                    <Grid item xs={11}>
                                        <Box className={classes.summaryLabels}>
                                            <Typography
                                                variant="h2"
                                                align="center"
                                                sx={{
                                                    display: "block",
                                                    mt: "auto",
                                                    mb: "auto",
                                                    fontWeight: 700,
                                                    color: valuesBookConsul.isError? "#ff5968": "primary"
                                                }}
                                            >
                                                {t(`invoicing.consulting`)}
                                            </Typography>
                                            <Chip
                                                id="project-total-chip"
                                                label={
                                                    <Typography variant="h3" align="center">
                                                        {`$${parseFloat(
                                                            valuesBookConsul.consulProjectTotal
                                                        ).toLocaleString(todos.amountFormat, {
                                                            maximumFractionDigits: 2,
                                                        })}/${t(`proposals.oneTime`)}`}
                                                    </Typography>
                                                }
                                                sx={{
                                                     //backgroundColor: "#2f3190",
                                                    backgroundColor: valuesBookConsul.isError? "#ff5968": "#2f3190",
                                                    color: "#fff",
                                                }}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item container justifyContent="flex-end" spacing={1}>
                                        <Grid item xs={11}>
                                            <Box className={classes.summaryDetails}>
                                                <Typography
                                                    variant="h3"
                                                    align="center"
                                                    sx={{
                                                        display: "block",
                                                        mt: "auto",
                                                        mb: "auto",
                                                        fontWeight: 700,
                                                        color: valuesBookConsul.isError? "#ff5968": "primary"
                                                    }}
                                                >
                                                    {`${t(`proposals.query`)} (${valuesBookConsul.consulHours} hrs)`}
                                                </Typography>
                                                <Typography variant="body2" align="center" sx={{color: valuesBookConsul.isError? "#ff5968": "primary"}}>
                                                    {`$${parseFloat(valuesBookConsul.consulProjectTotal).toLocaleString(
                                                        todos.amountFormat,
                                                        {
                                                            maximumFractionDigits: 2,
                                                        }
                                                    )}/${t(`proposals.oneTime`)}`}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                        </AccordionDetails>
                    </Accordion>
                </CardContent>
            </Card>
        </StyledContainer>
    );
};
