/** React imports */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import moment from "moment";

/** Icons/Images */
import { ReactComponent as AccountCircle } from "../../../assets/images/avatar-image.svg";
import { ReactComponent as CreditCardIcon } from "../../../assets/images/credit_card.svg";

/** MUI icons */
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import InsertChartOutlinedIcon from "@mui/icons-material/InsertChartOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import LockIcon from "@mui/icons-material/Lock";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

/** MUI imports */
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Switch,
    TextField,
    Typography,
} from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

/** Components imports */
import Countries from "../../Js/countries";
import MainCountries from "../../Js/mainCountries";
import Staff from "../../Js/staff";
import CardHeaderGCA from "../../ui/CardHeaderGCA";

/** Component styles */
const PREFIX = "EditGroup";

const classes = {
    avatarImage: `${PREFIX}-avatarImage`,
};

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 48 * 4.5 + 8,
            boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
        },
        sx: {
            borderRadius: 2,
            mt: 0.7,
        },
    },
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.avatarImage}`]: {
        display: "block",
        width: 65,
        height: 65,
        marginLeft: "auto",
        marginRight: "auto",
    },
}));

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "rgba(19, 31, 62, 0.80)",
        fontSize: 12,
        maxWidth: 320,
        borderRadius: 6,
        fontWeight: "normal",
    },
});

export default function EditGroup(props) {
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    /** React redux */
    const todos = useSelector((state) => state.todos);

    const [groupCompanies, setGroupCompanies] = useState([]);
    const [showTooltip, setShowTooltip] = useState(false);
    const [showTooltip2, setShowTooltip2] = useState(false);
    const [editableSubsInv, setEditableSubsInv] = useState(false);

    /** Component variables */
    let isRequired2 =
        props.valueStaff.teamCompany2.contact_name.trim() !== "" ||
        props.valueStaff.teamCompany2.contact_email.trim() !== "" ||
        props.valueStaff.teamCompany2.contact_role.trim() !== "" ||
        props.valueStaff.teamCompany2.contact_closure ||
        props.valueStaff.teamCompany2.contact_status ||
        props.valueStaff.teamCompany2.contact_requirements ||
        props.valueStaff.teamCompany2.contact_invoices;
    let isRequired3 =
        props.valueStaff.teamCompany3.contact_name.trim() !== "" ||
        props.valueStaff.teamCompany3.contact_email.trim() !== "" ||
        props.valueStaff.teamCompany3.contact_role.trim() !== "" ||
        props.valueStaff.teamCompany3.contact_closure ||
        props.valueStaff.teamCompany3.contact_status ||
        props.valueStaff.teamCompany3.contact_requirements ||
        props.valueStaff.teamCompany3.contact_invoices;
    let isRequired4 =
        props.valueStaff.teamCompany4.contact_name.trim() !== "" ||
        props.valueStaff.teamCompany4.contact_email.trim() !== "" ||
        props.valueStaff.teamCompany4.contact_role.trim() !== "" ||
        props.valueStaff.teamCompany4.contact_closure ||
        props.valueStaff.teamCompany4.contact_status ||
        props.valueStaff.teamCompany4.contact_requirements ||
        props.valueStaff.teamCompany4.contact_invoices;

    /** Component functions */
    const handleChange = (event) => {
        props.setValuesGen({
            ...props.valuesGen,
            [event.target.name]: event.target.value,
        });
    };

    const handleChangeGroup = async (event) => {
        props.setBackDrop(true);

        let teamCompany1 = props.teamCompanyDefault;
        let teamCompany2 = props.teamCompanyDefault;
        let teamCompany3 = props.teamCompanyDefault;
        let teamCompany4 = props.teamCompanyDefault;

        await fetch(`/getPracticeDetails?practice_id=${event.target.value}`, {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then((res) => res.json())
            .then((data) => {
                props.setShowEdit();

                if (data.team_company !== undefined) {
                    if (data.team_company.length !== 0) {
                        data.team_company.forEach((element, index) => {
                            if (index === 0) {
                                teamCompany1 = {
                                    contact_name: element.contact_name ?? "",
                                    contact_email: element.contact_email ?? "",
                                    contact_role: element.contact_role ?? "",
                                    contact_closure: element.contact_closure,
                                    contact_status: element.contact_status,
                                    contact_requirements: element.contact_requirements,
                                    contact_invoices: element.contact_invoices,
                                };
                            }
                            if (index === 1) {
                                teamCompany2 = {
                                    contact_name: element.contact_name ?? "",
                                    contact_email: element.contact_email ?? "",
                                    contact_role: element.contact_role ?? "",
                                    contact_closure: element.contact_closure,
                                    contact_status: element.contact_status,
                                    contact_requirements: element.contact_requirements,
                                    contact_invoices: element.contact_invoices,
                                };
                            }
                            if (index === 2) {
                                teamCompany3 = {
                                    contact_name: element.contact_name ?? "",
                                    contact_email: element.contact_email ?? "",
                                    contact_role: element.contact_role ?? "",
                                    contact_closure: element.contact_closure,
                                    contact_status: element.contact_status,
                                    contact_requirements: element.contact_requirements,
                                    contact_invoices: element.contact_invoices,
                                };
                            }
                            if (index === 3) {
                                teamCompany4 = {
                                    contact_name: element.contact_name ?? "",
                                    contact_email: element.contact_email ?? "",
                                    contact_role: element.contact_role ?? "",
                                    contact_closure: element.contact_closure,
                                    contact_status: element.contact_status,
                                    contact_requirements: element.contact_requirements,
                                    contact_invoices: element.contact_invoices,
                                };
                            }
                        });
                    }
                }

                if (data.franchise_id !== undefined && data.franchise_id !== null) {
                    props.setDisabledData(true);
                } else {
                    props.setDisabledData(false);
                }
                props.setValuesGen({
                    ...props.valuesGen,
                    practiceId: event.target.value,
                    clientType: data.client_type ?? "",
                    groupName: data.practice_name ?? "",
                    franchiseId: data.franchise_id ?? " ",
                    country: data.country_name ?? "",
                    firmaDate: data.bsa_date ? moment(data.bsa_date, "YYYY-MM-DD") : null,
                    langEsc: data.typing_communication ?? "",
                    langVer: data.verbal_communication ?? "",
                });
                props.setShowFran(data.franchise_id ? false : true);
                props.setValuesFact({
                    typeFact: data.invoicing_level ?? "",
                    contactFact: data.invoicing_client_name ?? "",
                    emailCont: data.invoicing_client_email ?? "",
                    contactGCA: data.invoicing_contact_name ?? "",
                    phoneNumber: data.invoicing_phone_number ?? "",
                    subsInv:
                        data.invoicing_subscription !== undefined && data.invoicing_subscription !== null
                            ? data.invoicing_subscription
                            : 2,
                });
                setEditableSubsInv(data.invoicing_editable);
                props.setDisabledData2(data.invoicing_level === 1);
                props.setValuesSettings({
                    ...props.valuesSettings,
                    anticipatedInvoice: data.anticipated_invoice ?? true,
                    groupedSupport: data.grouped_support ?? false,
                    groupedSupportHours: data.grouped_support_hours ?? 1,
                    groupedSupportDiscount: data.grouped_support_discount ?? 0,
                    custSuppinitDate: data.initial_date_customer
                        ? moment(data.initial_date_customer, "YYYY-MM-DD")
                        : null,
                    custSuppEndDate: data.end_date_customer ? moment(data.end_date_customer, "YYYY-MM-DD") : null,
                    kiiperBanking: data.banking_kiiper ?? false,
                    version: data.banking_kiiper_version ?? "",
                    kiiperBankingDiscount: data.banking_kiiper_discount ?? 0,
                    kiipBankinitDate: data.initial_date_banking
                        ? moment(data.initial_date_banking, "YYYY-MM-DD")
                        : null,
                    kiipBankEndDate: data.end_date_banking ? moment(data.end_date_banking, "YYYY-MM-DD") : null,
                    factOtherGroup: data.optional_invoicing ? true : false,
                    invContact: data.optional_invoicing ?? "",
                    manageReq: data.manage_requirements ?? false,
                    clientVip: data.client_vip ?? false,
                    tariff: data.tariff ?? "",
                    tariffAdditionals: data.tariff_additionals ?? "",
                    eeccReceptionDays: data.eecc_reception_days ?? 1,
                    monthlyVipReportDate: data.monthly_vip_report_date ?? 1,
                    monthlyStandardReportDate: data.monthly_standard_report_date ?? 1,
                });

                if (
                    data.supervisor_name_request ||
                    data.manager_name_request ||
                    data.analyst_name_request ||
                    data.backup_analyst_name_request ||
                    data.advisor_name_request
                ) {
                    props.setIsReq(true);
                } else {
                    props.setIsReq(false);
                }

                props.setValueStaff({
                    ...props.valueStaff,
                    supervisorOG: data.supervisor_name ?? "Por asignar",
                    managerOG: data.manager_name ?? "Por asignar",
                    advisorOG: data.advisor_name ?? "Por asignar",
                    analystOG: data.analyst_name ?? "Por asignar",
                    backupAnalystOG: data.backup_analyst_name ?? "Por asignar",
                    supervisor: data.supervisor_name ?? "Por asignar",
                    manager: data.manager_name ?? "Por asignar",
                    analyst: data.analyst_name ?? "Por asignar",
                    backupAnalyst: data.backup_analyst_name ?? "Por asignar",
                    advisor: data.advisor_name ?? "Por asignar",
                    supervisorReq: data.supervisor_name_request,
                    managerReq: data.manager_name_request,
                    advisorReq: data.advisor_name_request,
                    analystReq: data.analyst_name_request,
                    backupAnalystReq: data.backup_analyst_name_request,
                    teamCompany1: teamCompany1,
                    teamCompany2: teamCompany2,
                    teamCompany3: teamCompany3,
                    teamCompany4: teamCompany4,
                });
            });

        // Obtenemos las compañias del grupo
        await fetch(`/getOrganisationsByPractice?practice_id=${event.target.value}`, {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then((res) => res.json())
            .then((data) => {
                // Filtramos los contactos repetidos de un arreglo de objetos
                data = data.filter(
                    (value, index, self) =>
                        index === self.findIndex((t) => t.invoicing_contact_name === value.invoicing_contact_name)
                );

                setGroupCompanies(data);
            });

        props.setBackDrop(false);
    };

    const sortCountries = () => {
        if (moment.locale() === "en") {
            Countries.sort((a, b) => {
                if (a.name_en > b.name_en) return 1;

                if (a.name_en < b.name_en) return -1;

                return 0;
            });
        } else {
            Countries.sort((a, b) => {
                if (a.name_es > b.name_es) return 1;

                if (a.name_es < b.name_es) return -1;

                return 0;
            });
        }
    };

    const handleChangeFirmaDate = (date) => {
        props.setValuesGen({
            ...props.valuesGen,
            firmaDate: date,
        });
    };

    const handleChangeFact = (event) => {
        props.setValuesFact({
            ...props.valuesFact,
            [event.target.name]: event.target.value,
        });
    };

    const handleChangePhone = (event) => {
        const regex = /^\+[0-9\s.()-]{0,20}$/;
        if (event.target.value === "" || regex.test(event.target.value)) {
            props.setValuesFact({
                ...props.valuesFact,
                [event.target.name]: event.target.value,
            });
        }
    };

    const handleChangeSettings = (event) => {
        props.setValuesSettings({
            ...props.valuesSettings,
            [event.target.name]: event.target.value,
        });
    };

    const handleChangeSwitchSettings = (event) => {
        props.setValuesSettings({
            ...props.valuesSettings,
            anticipatedInvoice: event.target.checked,
        });
    };

    const handleChangeCheckGroupedSupport = (event) => {
        if (event.target.checked) {
            if (props.valuesFact.typeFact === 1) {
                props.setValuesSettings({
                    ...props.valuesSettings,
                    groupedSupport: event.target.checked,
                    factOtherGroup: true,
                    invContact: "",
                });
            } else {
                props.setValuesSettings({
                    ...props.valuesSettings,
                    groupedSupport: event.target.checked,
                });
            }
        } else {
            setShowTooltip(false);
            if (!props.valuesSettings.kiiperBanking) {
                props.setValuesSettings({
                    ...props.valuesSettings,
                    groupedSupport: event.target.checked,
                    groupedSupportHours: 1,
                    groupedSupportDiscount: 0,
                    factOtherGroup: false,
                    invContact: "",
                });
            } else {
                props.setValuesSettings({
                    ...props.valuesSettings,
                    groupedSupport: event.target.checked,
                    groupedSupportHours: 1,
                    groupedSupportDiscount: 0,
                });
            }
        }
    };

    const handleChangeCheckKiiperBanking = (event) => {
        if (event.target.checked) {
            if (props.valuesFact.typeFact === 1) {
                props.setValuesSettings({
                    ...props.valuesSettings,
                    kiiperBanking: event.target.checked,
                    factOtherGroup: true,
                    invContact: "",
                });
            } else {
                props.setValuesSettings({
                    ...props.valuesSettings,
                    kiiperBanking: event.target.checked,
                });
            }
        } else {
            setShowTooltip2(false);
            if (!props.valuesSettings.groupedSupport) {
                props.setValuesSettings({
                    ...props.valuesSettings,
                    kiiperBanking: event.target.checked,
                    version: "",
                    kiiperBankingDiscount: 0,
                    factOtherGroup: false,
                    invContact: "",
                });
            } else {
                props.setValuesSettings({
                    ...props.valuesSettings,
                    kiiperBanking: event.target.checked,
                    version: "",
                    kiiperBankingDiscount: 0,
                });
            }
        }
    };

    const handleChangeDiscount = (event) => {
        let discountAux = 0;

        if (event.target.name === "groupedSupportDiscount") {
            discountAux =
                event.target.value >= 0 && event.target.value <= 100
                    ? event.target.value
                    : props.valuesSettings.groupedSupportDiscount;

            props.setValuesSettings({
                ...props.valuesSettings,
                groupedSupportDiscount: discountAux,
            });
        } else {
            discountAux =
                event.target.value >= 0 && event.target.value <= 100
                    ? event.target.value
                    : props.valuesSettings.kiiperBankingDiscount;

            props.setValuesSettings({
                ...props.valuesSettings,
                kiiperBankingDiscount: discountAux,
            });
        }
    };

    const handleChangeCheckManageReq = (event) => {
        props.setValuesSettings({
            ...props.valuesSettings,
            manageReq: event.target.checked,
        });
    };

    const handleChangeCheckClientVip = (event) => {
        if (event.target.checked) {
            props.setValuesSettings({
                ...props.valuesSettings,
                clientVip: event.target.checked,
            });
        } else {
            props.setValuesSettings({
                ...props.valuesSettings,
                clientVip: event.target.checked,
                monthlyVipReportDate: 0,
            });
        }
    };

    const handleChangeReceptionDays = (event) => {
        let daysAux =
            (event.target.value >= 1 && event.target.value <= 15) || event.target.value === ""
                ? event.target.value
                : props.valuesSettings.eeccReceptionDays;

        props.setValuesSettings({
            ...props.valuesSettings,
            eeccReceptionDays: daysAux,
        });
    };

    const handleChangeMonthlyReports = (event) => {
        let daysAux = 0;

        if (event.target.name === "monthlyVipReportDate") {
            daysAux =
                (event.target.value >= 1 && event.target.value <= 25) || event.target.value === ""
                    ? event.target.value
                    : props.valuesSettings.monthlyVipReportDate;

            props.setValuesSettings({
                ...props.valuesSettings,
                monthlyVipReportDate: daysAux,
            });
        } else {
            daysAux =
                (event.target.value >= 1 && event.target.value <= 25) || event.target.value === ""
                    ? event.target.value
                    : props.valuesSettings.monthlyStandardReportDate;

            props.setValuesSettings({
                ...props.valuesSettings,
                monthlyStandardReportDate: daysAux,
            });
        }
    };

    const handleChangeStaff = (event) => {
        props.setValueStaff({
            ...props.valueStaff,
            [event.target.name]: event.target.value,
        });
    };

    const handleChangeTeamStaff = (event, team) => {
        props.setValueStaff({
            ...props.valueStaff,
            [team]: {
                ...props.valueStaff[team],
                [event.target.name]: event.target.value,
            },
        });
    };

    const handleChangeCheck = (event, value, team) => {
        switch (value) {
            case 0:
                props.setValueStaff({
                    ...props.valueStaff,
                    [team]: {
                        ...props.valueStaff[team],
                        contact_closure: event.target.checked,
                    },
                });
                break;
            case 1:
                props.setValueStaff({
                    ...props.valueStaff,
                    [team]: {
                        ...props.valueStaff[team],
                        contact_status: event.target.checked,
                    },
                });
                break;
            case 4:
                props.setValueStaff({
                    ...props.valueStaff,
                    [team]: {
                        ...props.valueStaff[team],
                        contact_invoices: event.target.checked,
                    },
                });
                break;
            default:
                props.setValueStaff({
                    ...props.valueStaff,
                    [team]: {
                        ...props.valueStaff[team],
                        contact_requirements: event.target.checked,
                    },
                });
                break;
        }
    };

    const clearTeamStaff = (event, team) => {
        props.setValueStaff({
            ...props.valueStaff,
            [team]: props.teamCompanyDefault,
        });
    };

    /** Component events */
    useEffect(() => {
        sortCountries();
    }, [t]);

    return (
        <StyledContainer>
            {props.activeStep === 0 && (
                <>
                    <Card elevation={0}>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item lg={4} sm={6} xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel id="group-simple-select-label">
                                            {t("miscellaneous.group")}
                                        </InputLabel>
                                        <Select
                                            labelId="group-simple-select-label"
                                            IconComponent={KeyboardArrowDownIcon}
                                            value={props.valuesGen.practiceId}
                                            onChange={handleChangeGroup}
                                            label={t("miscellaneous.group")}
                                            MenuProps={MenuProps}
                                        >
                                            {props.groupData.map((value) => (
                                                <MenuItem value={value.id}>
                                                    <Typography
                                                        variant="body1"
                                                        component="span"
                                                        align="center"
                                                        color="textPrimary"
                                                    >
                                                        {value.group_name}
                                                    </Typography>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    {props.showEdit ? (
                        <>
                            <Card elevation={0}>
                                <CardContent>
                                    <Grid container spacing={2}>
                                        <Grid item lg={2} sm={6} xs={12}>
                                            <FormControl
                                                fullWidth
                                                required
                                                error={
                                                    props.isError && props.valuesGen.clientType === "" ? true : false
                                                }
                                            >
                                                <InputLabel id="type-simple-select-label">
                                                    {t("miscellaneous.type")}
                                                </InputLabel>
                                                <Select
                                                    labelId="type-simple-select-label"
                                                    IconComponent={KeyboardArrowDownIcon}
                                                    onChange={props.handleChangeType}
                                                    value={props.valuesGen.clientType}
                                                    label={t("miscellaneous.type")}
                                                    MenuProps={{
                                                        PaperProps: {
                                                            sx: {
                                                                mt: 0.7,
                                                                borderRadius: 2,
                                                                boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                            },
                                                        },
                                                    }}
                                                >
                                                    <MenuItem value={1}>{t("miscellaneous.firm")}</MenuItem>
                                                    <MenuItem value={2}>SMB</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item lg={4} sm={6} xs={12}>
                                            <FormControl
                                                fullWidth
                                                disabled={props.showFran}
                                                required={!props.showFran}
                                                error={
                                                    props.isError &&
                                                    props.valuesGen.franchiseId === "" &&
                                                    !props.showFran
                                                        ? true
                                                        : false
                                                }
                                            >
                                                <InputLabel id="franq-simple-select-label">
                                                    {t("miscellaneous.franchise")}
                                                </InputLabel>
                                                <Select
                                                    name="franchiseId"
                                                    labelId="franq-simple-select-label"
                                                    IconComponent={KeyboardArrowDownIcon}
                                                    value={props.valuesGen.franchiseId}
                                                    onChange={props.handleChangeFranq}
                                                    label={t("miscellaneous.franchise")}
                                                    MenuProps={MenuProps}
                                                >
                                                    <MenuItem value=" ">
                                                        <Typography
                                                            variant="body1"
                                                            component="span"
                                                            align="center"
                                                            color="textPrimary"
                                                        >
                                                            N/A
                                                        </Typography>
                                                    </MenuItem>
                                                    {props.franqData.map((value) => (
                                                        <MenuItem value={value.id}>{value.franchise_name}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item lg={6} sm={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                required
                                                label={t("miscellaneous.groupName")}
                                                name="groupName"
                                                onChange={handleChange}
                                                value={props.valuesGen.groupName}
                                                variant="outlined"
                                                error={
                                                    props.isError && props.valuesGen.groupName.trim() === ""
                                                        ? true
                                                        : false
                                                }
                                            />
                                        </Grid>
                                        <Grid item lg={3} sm={6} xs={12}>
                                            <FormControl
                                                fullWidth
                                                required
                                                disabled={props.disabledData}
                                                error={props.isError && props.valuesGen.country === "" ? true : false}
                                            >
                                                <InputLabel id="country-simple-select-label">
                                                    {t("miscellaneous.country")}
                                                </InputLabel>
                                                <Select
                                                    name="country"
                                                    labelId="country-simple-select-label"
                                                    id="demo-simple-select"
                                                    IconComponent={KeyboardArrowDownIcon}
                                                    value={props.valuesGen.country}
                                                    label={t("miscellaneous.country")}
                                                    onChange={handleChange}
                                                    MenuProps={MenuProps}
                                                >
                                                    {MainCountries.map((option) => (
                                                        <MenuItem value={option.code}>
                                                            {moment.locale() === "en" ? option.name_en : option.name_es}
                                                        </MenuItem>
                                                    ))}
                                                    <Divider />
                                                    {Countries.map((option) => (
                                                        <MenuItem value={option.code}>
                                                            {moment.locale() === "en" ? option.name_en : option.name_es}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <LocalizationProvider
                                            dateAdapter={AdapterMoment}
                                            adapterLocale={t("language.locale")}
                                            localeText={{
                                                clearButtonLabel: t("miscellaneous.clear"),
                                            }}
                                        >
                                            <Grid item lg={3} sm={6} xs={12}>
                                                <DatePicker
                                                    componentsProps={{
                                                        actionBar: {
                                                            actions: ["clear"],
                                                        },
                                                    }}
                                                    value={props.valuesGen.firmaDate}
                                                    onChange={(newValue) => {
                                                        handleChangeFirmaDate(newValue);
                                                    }}
                                                    inputFormat={todos.dateFormat}
                                                    disabled={props.disabledData}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            fullWidth
                                                            required
                                                            {...params}
                                                            error={
                                                                props.isError && props.valuesGen.firmaDate === null
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                    )}
                                                    inputProps={{
                                                        readOnly: true,
                                                    }}
                                                    label={t("miscellaneous.firmDate")}
                                                />
                                            </Grid>
                                        </LocalizationProvider>
                                        <Grid item lg={3} sm={6} xs={12}>
                                            <FormControl
                                                fullWidth
                                                required
                                                disabled={props.disabledData}
                                                error={props.isError && props.valuesGen.langVer === "" ? true : false}
                                            >
                                                <InputLabel id="ver-simple-select-label">
                                                    {t("miscellaneous.verbalCommunication")}
                                                </InputLabel>
                                                <Select
                                                    name="langVer"
                                                    labelId="ver-simple-select-label"
                                                    IconComponent={KeyboardArrowDownIcon}
                                                    value={props.valuesGen.langVer}
                                                    label={t("miscellaneous.verbalCommunication")}
                                                    onChange={handleChange}
                                                    MenuProps={{
                                                        PaperProps: {
                                                            sx: {
                                                                mt: 0.7,
                                                                borderRadius: 2,
                                                                boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                            },
                                                        },
                                                    }}
                                                >
                                                    <MenuItem value={1}>{t("language.english")}</MenuItem>
                                                    <MenuItem value={2}>{t("language.spanish")}</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item lg={3} sm={6} xs={12}>
                                            <FormControl
                                                fullWidth
                                                required
                                                disabled={props.disabledData}
                                                error={props.isError && props.valuesGen.langEsc === "" ? true : false}
                                            >
                                                <InputLabel id="esc-simple-select-label">
                                                    {t("miscellaneous.writtenCommunication")}
                                                </InputLabel>
                                                <Select
                                                    name="langEsc"
                                                    labelId="esc-simple-select-label"
                                                    IconComponent={KeyboardArrowDownIcon}
                                                    value={props.valuesGen.langEsc}
                                                    label={t("miscellaneous.writtenCommunication")}
                                                    onChange={handleChange}
                                                    MenuProps={{
                                                        PaperProps: {
                                                            sx: {
                                                                mt: 0.7,
                                                                borderRadius: 2,
                                                                boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                            },
                                                        },
                                                    }}
                                                >
                                                    <MenuItem value={1}>{t("language.english")}</MenuItem>
                                                    <MenuItem value={2}>{t("language.spanish")}</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </>
                    ) : null}
                </>
            )}
            {props.activeStep === 1 && (
                <>
                    <Card elevation={0}>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item lg={4} sm={6} xs={12}>
                                    <FormControl
                                        fullWidth
                                        required
                                        error={props.isError1 && props.valuesFact.typeFact === "" ? true : false}
                                    >
                                        <InputLabel id="nivel-simple-select-label">
                                            {t("miscellaneous.invoicingServicesTo")}
                                        </InputLabel>
                                        <Select
                                            name="typeFact"
                                            labelId="nivel-simple-select-label"
                                            label={t("miscellaneous.invoicingServicesTo")}
                                            IconComponent={KeyboardArrowDownIcon}
                                            value={props.valuesFact.typeFact}
                                            onChange={props.handleChangeTypeFact}
                                            MenuProps={{
                                                PaperProps: {
                                                    sx: {
                                                        mt: 0.7,
                                                        borderRadius: 2,
                                                        boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                    },
                                                },
                                            }}
                                            s
                                        >
                                            {props.valuesGen.clientType === 2 ||
                                            (props.valuesGen.clientType === 1 &&
                                                props.valuesGen.franchiseId === " ") ? (
                                                <MenuItem value={2}>{t("miscellaneous.group")}</MenuItem>
                                            ) : (
                                                <MenuItem value={3}>{t("miscellaneous.franchise")}</MenuItem>
                                            )}
                                            {props.valuesGen.clientType === 2 ||
                                            (props.valuesGen.clientType === 1 &&
                                                props.valuesGen.franchiseId === " ") ? (
                                                <MenuItem value={1}>{t("miscellaneous.client")}</MenuItem>
                                            ) : null}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item lg={3} sm={6} xs={12}>
                                    <FormControl
                                        fullWidth
                                        required
                                        error={props.isError1 && !props.valuesFact.subsInv}
                                        disabled={!editableSubsInv}
                                    >
                                        <InputLabel id="subsInv-simple-select-label">
                                            {t("invoicing.subsInv")}
                                        </InputLabel>
                                        <Select
                                            name="subsInv"
                                            labelId="subsInv-simple-select-label"
                                            label={t("invoicing.subsInv")}
                                            IconComponent={KeyboardArrowDownIcon}
                                            value={props.valuesFact.subsInv}
                                            onChange={handleChangeFact}
                                            MenuProps={{
                                                PaperProps: {
                                                    sx: {
                                                        mt: 0.7,
                                                        borderRadius: 2,
                                                        boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                    },
                                                },
                                            }}
                                        >
                                            <MenuItem value={1}>GCA SC (Venezuela)</MenuItem>
                                            <MenuItem value={2}>GCA INC (Estados Unidos)</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    <Card elevation={0}>
                        <CardHeader title={t("miscellaneous.contact")} sx={{ color: "#ff5968" }} />
                        <Divider />
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item lg={4} sm={6} xs={12}>
                                    <TextField
                                        fullWidth
                                        required={props.requiredData}
                                        label={t("miscellaneous.invoicingNameGCA")}
                                        name="contactGCA"
                                        onChange={handleChangeFact}
                                        value={props.valuesFact.contactGCA}
                                        error={
                                            props.requiredData &&
                                            props.isError1 &&
                                            props.valuesFact.contactGCA.trim() === ""
                                                ? true
                                                : false
                                        }
                                        variant="outlined"
                                        disabled={props.disabledData || props.disabledData2}
                                    />
                                </Grid>
                                <Grid item lg={4} sm={6} xs={12}>
                                    <TextField
                                        fullWidth
                                        required={props.requiredData}
                                        label={t("miscellaneous.contactName")}
                                        name="contactFact"
                                        onChange={handleChangeFact}
                                        value={props.valuesFact.contactFact}
                                        error={
                                            props.requiredData &&
                                            props.isError1 &&
                                            props.valuesFact.contactFact.trim() === ""
                                                ? true
                                                : false
                                        }
                                        variant="outlined"
                                        disabled={props.disabledData || props.disabledData2}
                                    />
                                </Grid>
                                <Grid item lg={4} sm={6} xs={12}>
                                    <TextField
                                        fullWidth
                                        required={props.requiredData}
                                        label={t("miscellaneous.contactEmail")}
                                        name="emailCont"
                                        onChange={handleChangeFact}
                                        value={props.valuesFact.emailCont}
                                        error={
                                            props.requiredData &&
                                            props.isError1 &&
                                            props.valuesFact.emailCont.trim() === ""
                                                ? true
                                                : props.requiredData &&
                                                  props.isError1 &&
                                                  !/.+@.+\..+/.test(props.valuesFact.emailCont)
                                                ? true
                                                : false
                                        }
                                        variant="outlined"
                                        disabled={props.disabledData || props.disabledData2}
                                    />
                                </Grid>
                                <Grid item lg={4} sm={6} xs={12}>
                                    <FormControl fullWidth disabled={props.disabledData || props.disabledData2}>
                                        <InputLabel htmlFor="invoicing-phone-number-mask-input">
                                            {t("miscellaneous.phoneNumber")}
                                        </InputLabel>
                                        <OutlinedInput
                                            id="invoicing-phone-number-mask-input"
                                            name="phoneNumber"
                                            label={t("miscellaneous.phoneNumber")}
                                            value={props.valuesFact.phoneNumber}
                                            onChange={handleChangePhone}
                                            placeholder="e.g. +99 (999) 999-9999"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </>
            )}
            {props.activeStep === 2 && (
                <>
                    <Card elevation={0}>
                        <CardHeader
                            title={t("services.groupServices")}
                            action={
                                <FormControlLabel
                                    disabled
                                    control={
                                        <Switch
                                            checked={props.valuesSettings.anticipatedInvoice}
                                            inputProps={{
                                                "aria-label": "controlled",
                                            }}
                                            color="secondary"
                                            onChange={handleChangeSwitchSettings}
                                            sx={{
                                                "& .MuiSwitch-switchBase .MuiSwitch-thumb": {
                                                    color: "#FFF",
                                                },
                                                "& .MuiSwitch-switchBase + .MuiSwitch-track": {
                                                    backgroundColor: "#031851",
                                                },
                                                "& .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb": {
                                                    color: "#FF5968",
                                                },
                                                "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                                                    backgroundColor: "#FF5968",
                                                    opacity: 0.3,
                                                },
                                                "& .MuiSwitch-switchBase.Mui-checked.Mui-disabled .MuiSwitch-thumb": {
                                                    color: "#FF5968",
                                                    opacity: 0.5,
                                                },
                                                "& .MuiSwitch-switchBase.Mui-checked.Mui-disabled + .MuiSwitch-track": {
                                                    backgroundColor: "#FF5968",
                                                    opacity: 0.12,
                                                },
                                            }}
                                        />
                                    }
                                    label={
                                        <Typography variant="body1" color="textPrimary">
                                            {t("services.advanceInvoicing")}
                                        </Typography>
                                    }
                                />
                            }
                            sx={{ color: "#ff5968" }}
                        />
                        <Divider />
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <Grid
                                        container
                                        alignItems="center"
                                        sx={{
                                            backgroundColor: "#F4F4F4",
                                            p: props.valuesSettings.groupedSupport ? "10px 20px" : "10px 15px",
                                            borderRadius: 4,
                                        }}
                                    >
                                        <Grid item xs={6}>
                                            <Typography variant="body1" color="primary" sx={{ fontWeight: 700 }}>
                                                Customer Support
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={6}
                                            sx={{
                                                textAlign: "right",
                                            }}
                                        >
                                            <LightTooltip
                                                title={t("miscellaneous.groupTooltipOn")}
                                                open={showTooltip}
                                                onMouseEnter={() => {
                                                    if (props.valuesSettings.groupedSupport) {
                                                        setShowTooltip(true);
                                                    }
                                                }}
                                                onMouseLeave={() => {
                                                    if (props.valuesSettings.groupedSupport) {
                                                        setShowTooltip(false);
                                                    }
                                                }}
                                                disableHoverListener
                                            >
                                                <Switch
                                                    inputProps={{
                                                        "aria-label": "controlled",
                                                    }}
                                                    color="secondary"
                                                    checked={props.valuesSettings.groupedSupport}
                                                    onChange={handleChangeCheckGroupedSupport}
                                                />
                                            </LightTooltip>
                                        </Grid>
                                        {props.valuesSettings.groupedSupport && (
                                            <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                sx={{
                                                    pr: { sm: 1 },
                                                    pt: 2,
                                                }}
                                            >
                                                <TextField
                                                    fullWidth
                                                    required
                                                    label={t("miscellaneous.hours")}
                                                    name="groupedSupportHours"
                                                    onChange={handleChangeSettings}
                                                    value={props.valuesSettings.groupedSupportHours}
                                                    variant="outlined"
                                                    type="number"
                                                    inputProps={{
                                                        min: 1,
                                                        step: 1,
                                                        onKeyDown: (event) => {
                                                            event.preventDefault();
                                                        },
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    error={props.isError2 && !props.valuesSettings.groupedSupportHours}
                                                />
                                            </Grid>
                                        )}
                                        {props.valuesSettings.groupedSupport && (
                                            <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                sx={{
                                                    pl: { sm: 1 },
                                                    pt: 2,
                                                }}
                                            >
                                                <TextField
                                                    fullWidth
                                                    name="groupedSupportDiscount"
                                                    label={t("services.discountRate")}
                                                    value={props.valuesSettings.groupedSupportDiscount}
                                                    onChange={handleChangeDiscount}
                                                    onBlur={() =>
                                                        props.setValuesSettings({
                                                            ...props.valuesSettings,
                                                            groupedSupportDiscount: parseFloat(
                                                                props.valuesSettings.groupedSupportDiscount === ""
                                                                    ? 0
                                                                    : props.valuesSettings.groupedSupportDiscount
                                                            ).toFixed(2),
                                                        })
                                                    }
                                                    variant="outlined"
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                                        inputProps: {
                                                            min: 0,
                                                            max: 100,
                                                            pattern: "[0-9]*",
                                                        },
                                                    }}
                                                />
                                            </Grid>
                                        )}
                                        {props.valuesSettings.groupedSupport && (
                                            <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                sx={{
                                                    pr: { sm: 1 },
                                                    pt: 2,
                                                }}
                                            >
                                                <DatePicker
                                                    componentsProps={{
                                                        actionBar: {
                                                            actions: ["clear"],
                                                        },
                                                    }}
                                                    value={props.valuesSettings.custSuppinitDate}
                                                    onChange={(newValue) => {
                                                        props.handleChangeInitDate(newValue, 1);
                                                    }}
                                                    inputFormat={todos.dateFormat}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            fullWidth
                                                            required
                                                            {...params}
                                                            error={
                                                                props.isError2 && !props.valuesSettings.custSuppinitDate
                                                            }
                                                        />
                                                    )}
                                                    inputProps={{
                                                        readOnly: true,
                                                    }}
                                                    views={["year", "month"]}
                                                    label={t("reports.initDate")}
                                                />
                                            </Grid>
                                        )}
                                        {props.valuesSettings.groupedSupport && (
                                            <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                sx={{
                                                    pl: { sm: 1 },
                                                    pt: 2,
                                                }}
                                            >
                                                <DatePicker
                                                    componentsProps={{
                                                        actionBar: {
                                                            actions: ["clear"],
                                                        },
                                                    }}
                                                    value={props.valuesSettings.custSuppEndDate}
                                                    onChange={(newValue) => {
                                                        props.handleChangeEndDate(newValue, 1);
                                                    }}
                                                    inputFormat={todos.dateFormat}
                                                    renderInput={(params) => <TextField fullWidth {...params} />}
                                                    inputProps={{
                                                        readOnly: true,
                                                    }}
                                                    views={["year", "month"]}
                                                    label={t("reports.endDate")}
                                                />
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Grid
                                        container
                                        alignItems="center"
                                        sx={{
                                            backgroundColor: "#F4F4F4",
                                            p: props.valuesSettings.kiiperBanking ? "10px 20px" : "10px 15px",
                                            borderRadius: 4,
                                        }}
                                    >
                                        <Grid item xs={6}>
                                            <Typography variant="body1" color="primary" sx={{ fontWeight: 700 }}>
                                                {t("invoicing.kiiperBanking")}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={6}
                                            sx={{
                                                textAlign: "right",
                                            }}
                                        >
                                            <LightTooltip
                                                title={t("miscellaneous.groupTooltipOn")}
                                                open={showTooltip2}
                                                onMouseEnter={() => {
                                                    if (props.valuesSettings.kiiperBanking) {
                                                        setShowTooltip2(true);
                                                    }
                                                }}
                                                onMouseLeave={() => {
                                                    if (props.valuesSettings.kiiperBanking) {
                                                        setShowTooltip2(false);
                                                    }
                                                }}
                                                disableHoverListener
                                            >
                                                <Switch
                                                    inputProps={{
                                                        "aria-label": "controlled",
                                                    }}
                                                    color="secondary"
                                                    checked={props.valuesSettings.kiiperBanking}
                                                    onChange={handleChangeCheckKiiperBanking}
                                                />
                                            </LightTooltip>
                                        </Grid>
                                        {props.valuesSettings.kiiperBanking && (
                                            <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                sx={{
                                                    pr: { sm: 1 },
                                                    pt: 2,
                                                }}
                                            >
                                                <FormControl
                                                    fullWidth
                                                    required
                                                    error={props.isError2 && !props.valuesSettings.version}
                                                >
                                                    <InputLabel id="ver-simple-select-label">
                                                        {t("services.version")}
                                                    </InputLabel>
                                                    <Select
                                                        name="version"
                                                        labelId="ver-simple-select-label"
                                                        IconComponent={KeyboardArrowDownIcon}
                                                        value={props.valuesSettings.version}
                                                        label={t("services.version")}
                                                        onChange={handleChangeSettings}
                                                        MenuProps={{
                                                            PaperProps: {
                                                                sx: {
                                                                    mt: 0.7,
                                                                    borderRadius: 2,
                                                                    boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                                },
                                                            },
                                                        }}
                                                    >
                                                        <MenuItem value={"Plan 5"}>Plan 5</MenuItem>
                                                        <MenuItem value={"Plan 15"}>Plan 15</MenuItem>
                                                        <MenuItem value={"Plan 30"}>Plan 30</MenuItem>
                                                        <MenuItem value={"Plan 50"}>Plan 50</MenuItem>
                                                        <MenuItem value={"Plan 200"}>Plan 200</MenuItem>
                                                        <MenuItem value={"Plan 500"}>Plan 500</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        )}
                                        {props.valuesSettings.kiiperBanking && (
                                            <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                sx={{
                                                    pl: { sm: 1 },
                                                    pt: 2,
                                                }}
                                            >
                                                <TextField
                                                    fullWidth
                                                    name="kiiperBankingDiscount"
                                                    label={t("services.discountRate")}
                                                    value={props.valuesSettings.kiiperBankingDiscount}
                                                    onChange={handleChangeDiscount}
                                                    onBlur={() =>
                                                        props.setValuesSettings({
                                                            ...props.valuesSettings,
                                                            kiiperBankingDiscount: parseFloat(
                                                                props.valuesSettings.kiiperBankingDiscount === ""
                                                                    ? 0
                                                                    : props.valuesSettings.kiiperBankingDiscount
                                                            ).toFixed(2),
                                                        })
                                                    }
                                                    variant="outlined"
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                                        inputProps: {
                                                            min: 0,
                                                            max: 100,
                                                            pattern: "[0-9]*",
                                                        },
                                                    }}
                                                />
                                            </Grid>
                                        )}
                                        {props.valuesSettings.kiiperBanking && (
                                            <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                sx={{
                                                    pr: { sm: 1 },
                                                    pt: 2,
                                                }}
                                            >
                                                <DatePicker
                                                    componentsProps={{
                                                        actionBar: {
                                                            actions: ["clear"],
                                                        },
                                                    }}
                                                    value={props.valuesSettings.kiipBankinitDate}
                                                    onChange={(newValue) => {
                                                        props.handleChangeInitDate(newValue, 2);
                                                    }}
                                                    inputFormat={todos.dateFormat}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            fullWidth
                                                            required
                                                            {...params}
                                                            error={
                                                                props.isError2 && !props.valuesSettings.kiipBankinitDate
                                                            }
                                                        />
                                                    )}
                                                    inputProps={{
                                                        readOnly: true,
                                                    }}
                                                    views={["year", "month"]}
                                                    label={t("reports.initDate")}
                                                />
                                            </Grid>
                                        )}
                                        {props.valuesSettings.kiiperBanking && (
                                            <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                sx={{
                                                    pl: { sm: 1 },
                                                    pt: 2,
                                                }}
                                            >
                                                <DatePicker
                                                    componentsProps={{
                                                        actionBar: {
                                                            actions: ["clear"],
                                                        },
                                                    }}
                                                    value={props.valuesSettings.kiipBankEndDate}
                                                    onChange={(newValue) => {
                                                        props.handleChangeEndDate(newValue, 2);
                                                    }}
                                                    inputFormat={todos.dateFormat}
                                                    renderInput={(params) => <TextField fullWidth {...params} />}
                                                    inputProps={{
                                                        readOnly: true,
                                                    }}
                                                    views={["year", "month"]}
                                                    label={t("reports.endDate")}
                                                />
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                            {(props.valuesSettings.groupedSupport || props.valuesSettings.kiiperBanking) &&
                                groupCompanies.length > 1 && (
                                    <Grid container alignItems="center" spacing={2} pt={5}>
                                        <Grid item xs={12} md={3}>
                                            <LightTooltip title={t("principal.tooltipFactOther")}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            name="groupedSupport"
                                                            checked={props.valuesSettings.factOtherGroup}
                                                            onChange={props.handleChangeCheckOtherGroup}
                                                            disabled={
                                                                props.valuesFact.typeFact === 1 &&
                                                                (props.valuesSettings.groupedSupport ||
                                                                    props.valuesSettings.kiiperBanking)
                                                            }
                                                        />
                                                    }
                                                    label={
                                                        <Typography variant="body1" color="primary" align="center">
                                                            {t("principal.invOtherGroup")}
                                                        </Typography>
                                                    }
                                                />
                                            </LightTooltip>
                                        </Grid>
                                        {props.valuesSettings.factOtherGroup && (
                                            <Grid xs={12} md={3}>
                                                <FormControl
                                                    fullWidth
                                                    required
                                                    error={props.isError2 && !props.valuesSettings.invContact}
                                                >
                                                    <InputLabel id="invContact-simple-select-label">
                                                        {t("principal.contactInv")}
                                                    </InputLabel>
                                                    <Select
                                                        name="invContact"
                                                        labelId="invContact-simple-select-label"
                                                        label={t("principal.contactInv")}
                                                        value={props.valuesSettings.invContact}
                                                        onChange={handleChangeSettings}
                                                        IconComponent={KeyboardArrowDownIcon}
                                                        MenuProps={{
                                                            PaperProps: {
                                                                sx: {
                                                                    mt: 0.7,
                                                                    borderRadius: 2,
                                                                    boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                                },
                                                            },
                                                        }}
                                                    >
                                                        {groupCompanies.map((option) => (
                                                            <MenuItem value={option._id}>
                                                                {option.invoicing_contact_name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        )}
                                    </Grid>
                                )}
                        </CardContent>
                    </Card>
                    <Card elevation={0}>
                        <CardHeader title={t("services.generalConsiderations")} sx={{ color: "#ff5968" }} />
                        <Divider />
                        <CardContent>
                            <Grid container spacing={2} sx={{ pb: 2 }}>
                                <Grid item lg={3} sm={6} xs={12}>
                                    <LightTooltip title={t("miscellaneous.manageReqTool")}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    name="manageReq"
                                                    checked={props.valuesSettings.manageReq}
                                                    onChange={handleChangeCheckManageReq}
                                                />
                                            }
                                            label={t("miscellaneous.manageReq")}
                                        />
                                    </LightTooltip>
                                </Grid>
                                <Grid item lg={3} sm={6} xs={12}>
                                    <FormControlLabel
                                        disabled={props.disabledData}
                                        control={
                                            <Checkbox
                                                name="clientVip"
                                                checked={props.valuesSettings.clientVip}
                                                onChange={handleChangeCheckClientVip}
                                            />
                                        }
                                        label={t("miscellaneous.clientVip")}
                                    />
                                </Grid>
                            </Grid>

                            {props.valuesGen.clientType !== 2 && (
                                <Grid container spacing={2} sx={{ pb: 2 }}>
                                    <Grid item lg={3} sm={6} xs={12}>
                                        <FormControl
                                            fullWidth
                                            required
                                            disabled={props.disabledData}
                                            error={props.isError2 && props.valuesSettings.tariff === "" ? true : false}
                                        >
                                            <InputLabel id="tariff-simple-select-label">
                                                {t("services.baseRate")}
                                            </InputLabel>
                                            <Select
                                                name="tariff"
                                                labelId="tariff-simple-select-label"
                                                label={t("services.baseRate")}
                                                value={props.valuesSettings.tariff}
                                                onChange={handleChangeSettings}
                                                IconComponent={KeyboardArrowDownIcon}
                                                MenuProps={{
                                                    PaperProps: {
                                                        sx: {
                                                            mt: 0.7,
                                                            borderRadius: 2,
                                                            boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                        },
                                                    },
                                                }}
                                            >
                                                <MenuItem value={0}>$0</MenuItem>
                                                <MenuItem value={5}>$5</MenuItem>
                                                <MenuItem value={10}>$10</MenuItem>
                                                <MenuItem value={15}>$15</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item lg={3} sm={6} xs={12}>
                                        <FormControl
                                            fullWidth
                                            required
                                            disabled={props.disabledData}
                                            error={
                                                props.isError2 && props.valuesSettings.tariffAdditionals === ""
                                                    ? true
                                                    : false
                                            }
                                        >
                                            <InputLabel id="tariffAdditionals-simple-select-label">
                                                {t("services.additionalsRate")}
                                            </InputLabel>
                                            <Select
                                                name="tariffAdditionals"
                                                labelId="tariffAdditionals-simple-select-label"
                                                label={t("services.additionalsRate")}
                                                value={props.valuesSettings.tariffAdditionals}
                                                onChange={handleChangeSettings}
                                                IconComponent={KeyboardArrowDownIcon}
                                                MenuProps={{
                                                    PaperProps: {
                                                        sx: {
                                                            mt: 0.7,
                                                            borderRadius: 2,
                                                            boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                        },
                                                    },
                                                }}
                                            >
                                                <MenuItem value={15}>$15</MenuItem>
                                                <MenuItem value={25}>$25</MenuItem>
                                                <MenuItem value={30}>$30</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            )}
                            <Grid container spacing={2}>
                                <Grid item lg={3} sm={6} xs={12}>
                                    <LightTooltip title={t("services.receptionDaysMsg")}>
                                        <TextField
                                            fullWidth
                                            required
                                            name="eeccReceptionDays"
                                            label={t("services.receptionDays")}
                                            value={props.valuesSettings.eeccReceptionDays}
                                            onChange={handleChangeReceptionDays}
                                            variant="outlined"
                                            type="number"
                                            InputProps={{
                                                inputProps: { min: 1, max: 15 },
                                            }}
                                            error={
                                                props.isError2 && props.valuesSettings.eeccReceptionDays === ""
                                                    ? true
                                                    : false
                                            }
                                        />
                                    </LightTooltip>
                                </Grid>
                                {props.valuesSettings.clientVip && (
                                    <Grid item lg={3} sm={6} xs={12}>
                                        <LightTooltip title={t("services.monthlyReportsMsg")}>
                                            <TextField
                                                fullWidth
                                                required={props.valuesSettings.clientVip}
                                                name="monthlyVipReportDate"
                                                label={t("services.vipMonthlyReportDate")}
                                                value={props.valuesSettings.monthlyVipReportDate}
                                                onChange={handleChangeMonthlyReports}
                                                variant="outlined"
                                                type="number"
                                                InputProps={{
                                                    inputProps: { min: 1, max: 25 },
                                                }}
                                                error={
                                                    props.isError2 &&
                                                    props.valuesSettings.clientVip &&
                                                    props.valuesSettings.monthlyVipReportDate === ""
                                                        ? true
                                                        : false
                                                }
                                            />
                                        </LightTooltip>
                                    </Grid>
                                )}
                                <Grid item lg={3} sm={6} xs={12}>
                                    <LightTooltip title={t("services.monthlyReportsMsg")}>
                                        <TextField
                                            fullWidth
                                            required
                                            name="monthlyStandardReportDate"
                                            label={t("services.stdMonthlyReportDate")}
                                            value={props.valuesSettings.monthlyStandardReportDate}
                                            onChange={handleChangeMonthlyReports}
                                            variant="outlined"
                                            type="number"
                                            InputProps={{
                                                inputProps: { min: 1, max: 25 },
                                            }}
                                            error={
                                                props.isError2 && props.valuesSettings.monthlyStandardReportDate === ""
                                                    ? true
                                                    : false
                                            }
                                        />
                                    </LightTooltip>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </>
            )}
            {props.activeStep === 3 && (
                <Card elevation={0}>
                    <CardHeader title={t("miscellaneous.clientStaff")} sx={{ color: "#ff5968" }} />
                    <Divider />
                    <CardContent sx={{ pb: 5 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Card
                                    sx={{
                                        borderRadius: "20px",
                                        backgroundColor: "#F8F9F8",
                                        boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                    }}
                                >
                                    <CardContent sx={{ p: "24px 24px 16px !important" }}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} lg={4}>
                                                <AccountCircle className={classes.avatarImage} />
                                                <Typography variant="h2" color="primary" align="center" sx={{ pt: 1 }}>
                                                    {`${t("miscellaneous.contact")} 1`}
                                                </Typography>
                                                <LightTooltip title={t("recurrent.clear")}>
                                                    <IconButton
                                                        aria-label="clear"
                                                        color="primary"
                                                        onClick={(event) => clearTeamStaff(event, "teamCompany1")}
                                                        sx={{
                                                            display: "block",
                                                            m: "auto",
                                                        }}
                                                    >
                                                        <ClearAllIcon />
                                                    </IconButton>
                                                </LightTooltip>
                                            </Grid>
                                            <Grid item xs={12} lg={8}>
                                                <TextField
                                                    fullWidth
                                                    required
                                                    label={t("miscellaneous.name")}
                                                    name="contact_name"
                                                    onChange={(event) => handleChangeTeamStaff(event, "teamCompany1")}
                                                    value={props.valueStaff.teamCompany1.contact_name}
                                                    variant="outlined"
                                                    error={
                                                        props.isError3 &&
                                                        props.valueStaff.teamCompany1.contact_name.trim() === ""
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                <TextField
                                                    fullWidth
                                                    required
                                                    label={t("miscellaneous.email")}
                                                    name="contact_email"
                                                    onChange={(event) => handleChangeTeamStaff(event, "teamCompany1")}
                                                    value={props.valueStaff.teamCompany1.contact_email}
                                                    variant="outlined"
                                                    error={
                                                        props.isError3 &&
                                                        props.valueStaff.teamCompany1.contact_email.trim() === ""
                                                            ? true
                                                            : props.isError3 &&
                                                              !/.+@.+\..+/.test(
                                                                  props.valueStaff.teamCompany1.contact_email.trim()
                                                              )
                                                            ? true
                                                            : false
                                                    }
                                                    sx={{ mt: 2 }}
                                                />
                                                <TextField
                                                    fullWidth
                                                    required
                                                    label={t("miscellaneous.role")}
                                                    name="contact_role"
                                                    onChange={(event) => handleChangeTeamStaff(event, "teamCompany1")}
                                                    value={props.valueStaff.teamCompany1.contact_role}
                                                    variant="outlined"
                                                    error={
                                                        props.isError3 &&
                                                        props.valueStaff.teamCompany1.contact_role.trim() === ""
                                                            ? true
                                                            : false
                                                    }
                                                    sx={{ mt: 2 }}
                                                />
                                                <FormGroup
                                                    sx={{
                                                        mt: 2,
                                                        display: "block",
                                                    }}
                                                >
                                                    <FormLabel id="permissions-checkboxes-group-label">
                                                        <Typography variant="h4" color="primary" sx={{ pb: 1 }}>
                                                            {t("miscellaneous.permissions")}:
                                                        </Typography>
                                                    </FormLabel>
                                                    <LightTooltip
                                                        title={t("services.toolRequirements")}
                                                        aria-label="req"
                                                    >
                                                        <Checkbox
                                                            icon={<AssignmentTurnedInOutlinedIcon />}
                                                            checkedIcon={<AssignmentTurnedInIcon />}
                                                            checked={props.valueStaff.teamCompany1.contact_requirements}
                                                            onChange={(event) =>
                                                                handleChangeCheck(event, 3, "teamCompany1")
                                                            }
                                                            sx={{
                                                                ml: {
                                                                    xs: 0,
                                                                    lg: 0,
                                                                    xl: 0,
                                                                },
                                                                mr: {
                                                                    xs: 1,
                                                                    lg: 0,
                                                                    xl: 1,
                                                                },
                                                            }}
                                                        />
                                                    </LightTooltip>
                                                    <LightTooltip
                                                        title={t("services.toolAccountingClosures")}
                                                        aria-label="cierres"
                                                    >
                                                        <Checkbox
                                                            icon={<LockOutlinedIcon />}
                                                            checkedIcon={<LockIcon />}
                                                            checked={props.valueStaff.teamCompany1.contact_closure}
                                                            onChange={(event) =>
                                                                handleChangeCheck(event, 0, "teamCompany1")
                                                            }
                                                            sx={{
                                                                ml: {
                                                                    xs: 1,
                                                                    lg: 0,
                                                                    xl: 1,
                                                                },
                                                                mr: {
                                                                    xs: 1,
                                                                    lg: 0,
                                                                    xl: 1,
                                                                },
                                                            }}
                                                        />
                                                    </LightTooltip>
                                                    <LightTooltip title={t("services.toolStatus")} aria-label="status">
                                                        <Checkbox
                                                            icon={<InsertChartOutlinedIcon />}
                                                            checkedIcon={<InsertChartIcon />}
                                                            checked={props.valueStaff.teamCompany1.contact_status}
                                                            onChange={(event) =>
                                                                handleChangeCheck(event, 1, "teamCompany1")
                                                            }
                                                            sx={{
                                                                ml: {
                                                                    xs: 1,
                                                                    lg: 0,
                                                                    xl: 1,
                                                                },
                                                                mr: {
                                                                    xs: 1,
                                                                    lg: 0,
                                                                    xl: 1,
                                                                },
                                                            }}
                                                        />
                                                    </LightTooltip>
                                                    <LightTooltip
                                                        title={t("services.toolInvoicing")}
                                                        aria-label="invoicing"
                                                    >
                                                        <Checkbox
                                                            icon={<CreditCardOutlinedIcon />}
                                                            checkedIcon={
                                                                <CreditCardIcon className="icon-normal-size" />
                                                            }
                                                            checked={props.valueStaff.teamCompany1.contact_invoices}
                                                            onChange={(event) =>
                                                                handleChangeCheck(event, 4, "teamCompany1")
                                                            }
                                                            sx={{
                                                                ml: {
                                                                    xs: 1,
                                                                    lg: 0,
                                                                    xl: 1,
                                                                },
                                                                mr: {
                                                                    xs: 1,
                                                                    lg: 0,
                                                                    xl: 1,
                                                                },
                                                            }}
                                                        />
                                                    </LightTooltip>
                                                </FormGroup>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Card
                                    sx={{
                                        borderRadius: "20px",
                                        backgroundColor: "#F8F9F8",
                                        boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                    }}
                                >
                                    <CardContent sx={{ p: "24px 24px 16px !important" }}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} lg={4}>
                                                <AccountCircle className={classes.avatarImage} />
                                                <Typography variant="h2" color="primary" align="center" sx={{ pt: 1 }}>
                                                    {`${t("miscellaneous.contact")} 2`}
                                                </Typography>
                                                <LightTooltip title={t("recurrent.clear")}>
                                                    <IconButton
                                                        aria-label="clear"
                                                        color="primary"
                                                        onClick={(event) => clearTeamStaff(event, "teamCompany2")}
                                                        sx={{
                                                            display: "block",
                                                            m: "auto",
                                                        }}
                                                    >
                                                        <ClearAllIcon />
                                                    </IconButton>
                                                </LightTooltip>
                                            </Grid>
                                            <Grid item xs={12} lg={8}>
                                                <TextField
                                                    fullWidth
                                                    required={isRequired2}
                                                    label={t("miscellaneous.name")}
                                                    name="contact_name"
                                                    onChange={(event) => handleChangeTeamStaff(event, "teamCompany2")}
                                                    value={props.valueStaff.teamCompany2.contact_name}
                                                    variant="outlined"
                                                    error={
                                                        isRequired2 &&
                                                        props.isError3 &&
                                                        props.valueStaff.teamCompany2.contact_name.trim() === ""
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                <TextField
                                                    fullWidth
                                                    required={isRequired2}
                                                    label={t("miscellaneous.email")}
                                                    name="contact_email"
                                                    onChange={(event) => handleChangeTeamStaff(event, "teamCompany2")}
                                                    value={props.valueStaff.teamCompany2.contact_email}
                                                    variant="outlined"
                                                    error={
                                                        isRequired2 &&
                                                        props.isError3 &&
                                                        props.valueStaff.teamCompany2.contact_email.trim() === ""
                                                            ? true
                                                            : isRequired2 &&
                                                              props.isError3 &&
                                                              !/.+@.+\..+/.test(
                                                                  props.valueStaff.teamCompany2.contact_email.trim()
                                                              )
                                                            ? true
                                                            : false
                                                    }
                                                    sx={{ mt: 2 }}
                                                />
                                                <TextField
                                                    fullWidth
                                                    required={isRequired2}
                                                    label={t("miscellaneous.role")}
                                                    name="contact_role"
                                                    onChange={(event) => handleChangeTeamStaff(event, "teamCompany2")}
                                                    value={props.valueStaff.teamCompany2.contact_role}
                                                    variant="outlined"
                                                    error={
                                                        isRequired2 &&
                                                        props.isError3 &&
                                                        props.valueStaff.teamCompany2.contact_role.trim() === ""
                                                            ? true
                                                            : false
                                                    }
                                                    sx={{ mt: 2 }}
                                                />
                                                <FormGroup
                                                    sx={{
                                                        mt: 2,
                                                        display: "block",
                                                    }}
                                                >
                                                    <FormLabel id="permissions-checkboxes-group-label">
                                                        <Typography variant="h4" color="primary" sx={{ pb: 1 }}>
                                                            {t("miscellaneous.permissions")}:
                                                        </Typography>
                                                    </FormLabel>
                                                    <LightTooltip
                                                        title={t("services.toolRequirements")}
                                                        aria-label="req"
                                                    >
                                                        <Checkbox
                                                            icon={<AssignmentTurnedInOutlinedIcon />}
                                                            checkedIcon={<AssignmentTurnedInIcon />}
                                                            checked={props.valueStaff.teamCompany2.contact_requirements}
                                                            onChange={(event) =>
                                                                handleChangeCheck(event, 3, "teamCompany2")
                                                            }
                                                            sx={{
                                                                ml: {
                                                                    xs: 0,
                                                                    lg: 0,
                                                                    xl: 0,
                                                                },
                                                                mr: {
                                                                    xs: 1,
                                                                    lg: 0,
                                                                    xl: 1,
                                                                },
                                                            }}
                                                        />
                                                    </LightTooltip>
                                                    <LightTooltip
                                                        title={t("services.toolAccountingClosures")}
                                                        aria-label="cierres"
                                                    >
                                                        <Checkbox
                                                            icon={<LockOutlinedIcon />}
                                                            checkedIcon={<LockIcon />}
                                                            checked={props.valueStaff.teamCompany2.contact_closure}
                                                            onChange={(event) =>
                                                                handleChangeCheck(event, 0, "teamCompany2")
                                                            }
                                                            sx={{
                                                                ml: {
                                                                    xs: 1,
                                                                    lg: 0,
                                                                    xl: 1,
                                                                },
                                                                mr: {
                                                                    xs: 1,
                                                                    lg: 0,
                                                                    xl: 1,
                                                                },
                                                            }}
                                                        />
                                                    </LightTooltip>
                                                    <LightTooltip title={t("services.toolStatus")} aria-label="status">
                                                        <Checkbox
                                                            icon={<InsertChartOutlinedIcon />}
                                                            checkedIcon={<InsertChartIcon />}
                                                            checked={props.valueStaff.teamCompany2.contact_status}
                                                            onChange={(event) =>
                                                                handleChangeCheck(event, 1, "teamCompany2")
                                                            }
                                                            sx={{
                                                                ml: {
                                                                    xs: 1,
                                                                    lg: 0,
                                                                    xl: 1,
                                                                },
                                                                mr: {
                                                                    xs: 1,
                                                                    lg: 0,
                                                                    xl: 1,
                                                                },
                                                            }}
                                                        />
                                                    </LightTooltip>
                                                    <LightTooltip
                                                        title={t("services.toolInvoicing")}
                                                        aria-label="invoicing"
                                                    >
                                                        <Checkbox
                                                            icon={<CreditCardOutlinedIcon />}
                                                            checkedIcon={
                                                                <CreditCardIcon className="icon-normal-size" />
                                                            }
                                                            checked={props.valueStaff.teamCompany2.contact_invoices}
                                                            onChange={(event) =>
                                                                handleChangeCheck(event, 4, "teamCompany2")
                                                            }
                                                            sx={{
                                                                ml: {
                                                                    xs: 1,
                                                                    lg: 0,
                                                                    xl: 1,
                                                                },
                                                                mr: {
                                                                    xs: 1,
                                                                    lg: 0,
                                                                    xl: 1,
                                                                },
                                                            }}
                                                        />
                                                    </LightTooltip>
                                                </FormGroup>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Card
                                    sx={{
                                        borderRadius: "20px",
                                        backgroundColor: "#F8F9F8",
                                        boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                    }}
                                >
                                    <CardContent sx={{ p: "24px 24px 16px !important" }}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} lg={4}>
                                                <AccountCircle className={classes.avatarImage} />
                                                <Typography variant="h2" color="primary" align="center" sx={{ pt: 1 }}>
                                                    {`${t("miscellaneous.contact")} 3`}
                                                </Typography>
                                                <LightTooltip title={t("recurrent.clear")}>
                                                    <IconButton
                                                        aria-label="clear"
                                                        color="primary"
                                                        onClick={(event) => clearTeamStaff(event, "teamCompany3")}
                                                        sx={{
                                                            display: "block",
                                                            m: "auto",
                                                        }}
                                                    >
                                                        <ClearAllIcon />
                                                    </IconButton>
                                                </LightTooltip>
                                            </Grid>
                                            <Grid item xs={12} lg={8}>
                                                <TextField
                                                    fullWidth
                                                    required={isRequired3}
                                                    label={t("miscellaneous.name")}
                                                    name="contact_name"
                                                    onChange={(event) => handleChangeTeamStaff(event, "teamCompany3")}
                                                    value={props.valueStaff.teamCompany3.contact_name}
                                                    variant="outlined"
                                                    error={
                                                        isRequired3 &&
                                                        props.isError3 &&
                                                        props.valueStaff.teamCompany3.contact_name.trim() === ""
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                <TextField
                                                    fullWidth
                                                    required={isRequired3}
                                                    label={t("miscellaneous.email")}
                                                    name="contact_email"
                                                    onChange={(event) => handleChangeTeamStaff(event, "teamCompany3")}
                                                    value={props.valueStaff.teamCompany3.contact_email}
                                                    variant="outlined"
                                                    error={
                                                        isRequired3 &&
                                                        props.isError3 &&
                                                        props.valueStaff.teamCompany3.contact_email.trim() === ""
                                                            ? true
                                                            : isRequired3 &&
                                                              props.isError3 &&
                                                              !/.+@.+\..+/.test(
                                                                  props.valueStaff.teamCompany3.contact_email.trim()
                                                              )
                                                            ? true
                                                            : false
                                                    }
                                                    sx={{ mt: 2 }}
                                                />
                                                <TextField
                                                    fullWidth
                                                    required={isRequired3}
                                                    label={t("miscellaneous.role")}
                                                    name="contact_role"
                                                    onChange={(event) => handleChangeTeamStaff(event, "teamCompany3")}
                                                    value={props.valueStaff.teamCompany3.contact_role}
                                                    variant="outlined"
                                                    error={
                                                        isRequired3 &&
                                                        props.isError3 &&
                                                        props.valueStaff.teamCompany3.contact_role.trim() === ""
                                                            ? true
                                                            : false
                                                    }
                                                    sx={{ mt: 2 }}
                                                />
                                                <FormGroup
                                                    sx={{
                                                        mt: 2,
                                                        display: "block",
                                                    }}
                                                >
                                                    <FormLabel id="permissions-checkboxes-group-label">
                                                        <Typography variant="h4" color="primary" sx={{ pb: 1 }}>
                                                            {t("miscellaneous.permissions")}:
                                                        </Typography>
                                                    </FormLabel>
                                                    <LightTooltip
                                                        title={t("services.toolRequirements")}
                                                        aria-label="req"
                                                    >
                                                        <Checkbox
                                                            icon={<AssignmentTurnedInOutlinedIcon />}
                                                            checkedIcon={<AssignmentTurnedInIcon />}
                                                            checked={props.valueStaff.teamCompany3.contact_requirements}
                                                            onChange={(event) =>
                                                                handleChangeCheck(event, 3, "teamCompany3")
                                                            }
                                                            sx={{
                                                                ml: {
                                                                    xs: 0,
                                                                    lg: 0,
                                                                    xl: 0,
                                                                },
                                                                mr: {
                                                                    xs: 1,
                                                                    lg: 0,
                                                                    xl: 1,
                                                                },
                                                            }}
                                                        />
                                                    </LightTooltip>
                                                    <LightTooltip
                                                        title={t("services.toolAccountingClosures")}
                                                        aria-label="cierres"
                                                    >
                                                        <Checkbox
                                                            icon={<LockOutlinedIcon />}
                                                            checkedIcon={<LockIcon />}
                                                            checked={props.valueStaff.teamCompany3.contact_closure}
                                                            onChange={(event) =>
                                                                handleChangeCheck(event, 0, "teamCompany3")
                                                            }
                                                            sx={{
                                                                ml: {
                                                                    xs: 1,
                                                                    lg: 0,
                                                                    xl: 1,
                                                                },
                                                                mr: {
                                                                    xs: 1,
                                                                    lg: 0,
                                                                    xl: 1,
                                                                },
                                                            }}
                                                        />
                                                    </LightTooltip>
                                                    <LightTooltip title={t("services.toolStatus")} aria-label="status">
                                                        <Checkbox
                                                            icon={<InsertChartOutlinedIcon />}
                                                            checkedIcon={<InsertChartIcon />}
                                                            checked={props.valueStaff.teamCompany3.contact_status}
                                                            onChange={(event) =>
                                                                handleChangeCheck(event, 1, "teamCompany3")
                                                            }
                                                            sx={{
                                                                ml: {
                                                                    xs: 1,
                                                                    lg: 0,
                                                                    xl: 1,
                                                                },
                                                                mr: {
                                                                    xs: 1,
                                                                    lg: 0,
                                                                    xl: 1,
                                                                },
                                                            }}
                                                        />
                                                    </LightTooltip>
                                                    <LightTooltip
                                                        title={t("services.toolInvoicing")}
                                                        aria-label="invoicing"
                                                    >
                                                        <Checkbox
                                                            icon={<CreditCardOutlinedIcon />}
                                                            checkedIcon={
                                                                <CreditCardIcon className="icon-normal-size" />
                                                            }
                                                            checked={props.valueStaff.teamCompany3.contact_invoices}
                                                            onChange={(event) =>
                                                                handleChangeCheck(event, 4, "teamCompany3")
                                                            }
                                                            sx={{
                                                                ml: {
                                                                    xs: 1,
                                                                    lg: 0,
                                                                    xl: 1,
                                                                },
                                                                mr: {
                                                                    xs: 1,
                                                                    lg: 0,
                                                                    xl: 1,
                                                                },
                                                            }}
                                                        />
                                                    </LightTooltip>
                                                </FormGroup>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Card
                                    sx={{
                                        borderRadius: "20px",
                                        backgroundColor: "#F8F9F8",
                                        boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                    }}
                                >
                                    <CardContent sx={{ p: "24px 24px 16px !important" }}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} lg={4}>
                                                <AccountCircle className={classes.avatarImage} />
                                                <Typography variant="h2" color="primary" align="center" sx={{ pt: 1 }}>
                                                    {`${t("miscellaneous.contact")} 4`}
                                                </Typography>
                                                <LightTooltip title={t("recurrent.clear")}>
                                                    <IconButton
                                                        aria-label="clear"
                                                        color="primary"
                                                        onClick={(event) => clearTeamStaff(event, "teamCompany4")}
                                                        sx={{
                                                            display: "block",
                                                            m: "auto",
                                                        }}
                                                    >
                                                        <ClearAllIcon />
                                                    </IconButton>
                                                </LightTooltip>
                                            </Grid>
                                            <Grid item xs={12} lg={8}>
                                                <TextField
                                                    fullWidth
                                                    required={isRequired4}
                                                    label={t("miscellaneous.name")}
                                                    name="contact_name"
                                                    onChange={(event) => handleChangeTeamStaff(event, "teamCompany4")}
                                                    value={props.valueStaff.teamCompany4.contact_name}
                                                    variant="outlined"
                                                    error={
                                                        isRequired4 &&
                                                        props.isError3 &&
                                                        props.valueStaff.teamCompany4.contact_name.trim() === ""
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                <TextField
                                                    fullWidth
                                                    required={isRequired4}
                                                    label={t("miscellaneous.email")}
                                                    name="contact_email"
                                                    onChange={(event) => handleChangeTeamStaff(event, "teamCompany4")}
                                                    value={props.valueStaff.teamCompany4.contact_email}
                                                    variant="outlined"
                                                    error={
                                                        isRequired4 &&
                                                        props.isError3 &&
                                                        props.valueStaff.teamCompany4.contact_email.trim() === ""
                                                            ? true
                                                            : isRequired4 &&
                                                              props.isError3 &&
                                                              !/.+@.+\..+/.test(
                                                                  props.valueStaff.teamCompany4.contact_email.trim()
                                                              )
                                                            ? true
                                                            : false
                                                    }
                                                    sx={{ mt: 2 }}
                                                />
                                                <TextField
                                                    fullWidth
                                                    required={isRequired4}
                                                    label={t("miscellaneous.role")}
                                                    name="contact_role"
                                                    onChange={(event) => handleChangeTeamStaff(event, "teamCompany4")}
                                                    value={props.valueStaff.teamCompany4.contact_role}
                                                    variant="outlined"
                                                    error={
                                                        isRequired4 &&
                                                        props.isError3 &&
                                                        props.valueStaff.teamCompany4.contact_role.trim() === ""
                                                            ? true
                                                            : false
                                                    }
                                                    sx={{ mt: 2 }}
                                                />
                                                <FormGroup
                                                    sx={{
                                                        mt: 2,
                                                        display: "block",
                                                    }}
                                                >
                                                    <FormLabel id="permissions-checkboxes-group-label">
                                                        <Typography variant="h4" color="primary" sx={{ pb: 1 }}>
                                                            {t("miscellaneous.permissions")}:
                                                        </Typography>
                                                    </FormLabel>
                                                    <LightTooltip
                                                        title={t("services.toolRequirements")}
                                                        aria-label="req"
                                                    >
                                                        <Checkbox
                                                            icon={<AssignmentTurnedInOutlinedIcon />}
                                                            checkedIcon={<AssignmentTurnedInIcon />}
                                                            checked={props.valueStaff.teamCompany4.contact_requirements}
                                                            onChange={(event) =>
                                                                handleChangeCheck(event, 3, "teamCompany4")
                                                            }
                                                            sx={{
                                                                ml: {
                                                                    xs: 0,
                                                                    lg: 0,
                                                                    xl: 0,
                                                                },
                                                                mr: {
                                                                    xs: 1,
                                                                    lg: 0,
                                                                    xl: 1,
                                                                },
                                                            }}
                                                        />
                                                    </LightTooltip>
                                                    <LightTooltip
                                                        title={t("services.toolAccountingClosures")}
                                                        aria-label="cierres"
                                                    >
                                                        <Checkbox
                                                            icon={<LockOutlinedIcon />}
                                                            checkedIcon={<LockIcon />}
                                                            checked={props.valueStaff.teamCompany4.contact_closure}
                                                            onChange={(event) =>
                                                                handleChangeCheck(event, 0, "teamCompany4")
                                                            }
                                                            sx={{
                                                                ml: {
                                                                    xs: 1,
                                                                    lg: 0,
                                                                    xl: 1,
                                                                },
                                                                mr: {
                                                                    xs: 1,
                                                                    lg: 0,
                                                                    xl: 1,
                                                                },
                                                            }}
                                                        />
                                                    </LightTooltip>
                                                    <LightTooltip title={t("services.toolStatus")} aria-label="status">
                                                        <Checkbox
                                                            icon={<InsertChartOutlinedIcon />}
                                                            checkedIcon={<InsertChartIcon />}
                                                            checked={props.valueStaff.teamCompany4.contact_status}
                                                            onChange={(event) =>
                                                                handleChangeCheck(event, 1, "teamCompany4")
                                                            }
                                                            sx={{
                                                                ml: {
                                                                    xs: 1,
                                                                    lg: 0,
                                                                    xl: 1,
                                                                },
                                                                mr: {
                                                                    xs: 1,
                                                                    lg: 0,
                                                                    xl: 1,
                                                                },
                                                            }}
                                                        />
                                                    </LightTooltip>
                                                    <LightTooltip
                                                        title={t("services.toolInvoicing")}
                                                        aria-label="invoicing"
                                                    >
                                                        <Checkbox
                                                            icon={<CreditCardOutlinedIcon />}
                                                            checkedIcon={
                                                                <CreditCardIcon className="icon-normal-size" />
                                                            }
                                                            checked={props.valueStaff.teamCompany4.contact_invoices}
                                                            onChange={(event) =>
                                                                handleChangeCheck(event, 4, "teamCompany4")
                                                            }
                                                            sx={{
                                                                ml: {
                                                                    xs: 1,
                                                                    lg: 0,
                                                                    xl: 1,
                                                                },
                                                                mr: {
                                                                    xs: 1,
                                                                    lg: 0,
                                                                    xl: 1,
                                                                },
                                                            }}
                                                        />
                                                    </LightTooltip>
                                                </FormGroup>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            )}
            {props.activeStep === 4 && (
                <Card elevation={0}>
                    <CardHeaderGCA
                        setValueStaff={props.setValueStaff}
                        valueStaff={props.valueStaff}
                        isReq={props.isReq}
                    />
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item lg={4} sm={6} xs={12}>
                                <FormControl
                                    fullWidth
                                    required
                                    error={props.isError4 && props.valueStaff.manager === "" ? true : false}
                                >
                                    <InputLabel id="manager-simple-select-label">
                                        {t("miscellaneous.manager")}
                                    </InputLabel>
                                    <Select
                                        name="manager"
                                        labelId="manager-simple-select-label"
                                        label={t("miscellaneous.manager")}
                                        IconComponent={KeyboardArrowDownIcon}
                                        value={props.valueStaff.manager}
                                        onChange={handleChangeStaff}
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    mt: 0.7,
                                                    borderRadius: 2,
                                                    boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                },
                                            },
                                        }}
                                    >
                                        {Staff.manager.map((option) => (
                                            <MenuItem value={option.name_es}>
                                                {moment.locale() === "en" ? option.name_en : option.name_es}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item lg={4} sm={6} xs={12}>
                                <FormControl
                                    fullWidth
                                    required
                                    error={props.isError4 && props.valueStaff.supervisor === "" ? true : false}
                                >
                                    <InputLabel id="supervisor-simple-select-label">
                                        {t("miscellaneous.supervisor")}
                                    </InputLabel>
                                    <Select
                                        name="supervisor"
                                        labelId="supervisor-simple-select-label"
                                        label={t("miscellaneous.supervisor")}
                                        IconComponent={KeyboardArrowDownIcon}
                                        value={props.valueStaff.supervisor}
                                        onChange={handleChangeStaff}
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    mt: 0.7,
                                                    borderRadius: 2,
                                                    boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                },
                                            },
                                        }}
                                    >
                                        {Staff.supervisor.map((option) => (
                                            <MenuItem value={option.name_es}>
                                                {moment.locale() === "en" ? option.name_en : option.name_es}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item lg={4} sm={6} xs={12}>
                                <FormControl
                                    fullWidth
                                    required
                                    error={props.isError4 && props.valueStaff.advisor === "" ? true : false}
                                >
                                    <InputLabel id="advisor-simple-select-label">
                                        {t("miscellaneous.advisor")}
                                    </InputLabel>
                                    <Select
                                        name="advisor"
                                        labelId="advisor-simple-select-label"
                                        label={t("miscellaneous.advisor")}
                                        IconComponent={KeyboardArrowDownIcon}
                                        value={props.valueStaff.advisor}
                                        onChange={handleChangeStaff}
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    mt: 0.7,
                                                    borderRadius: 2,
                                                    boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                },
                                            },
                                        }}
                                    >
                                        {Staff.advisor.map((option) => (
                                            <MenuItem value={option.name_es}>
                                                {moment.locale() === "en" ? option.name_en : option.name_es}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item lg={4} sm={6} xs={12}>
                                <FormControl
                                    fullWidth
                                    required
                                    error={props.isError4 && props.valueStaff.analyst === "" ? true : false}
                                >
                                    <InputLabel id="analyst-simple-select-label">
                                        {t("miscellaneous.analyst")}
                                    </InputLabel>
                                    <Select
                                        name="analyst"
                                        labelId="analyst-simple-select-label"
                                        label={t("miscellaneous.analyst")}
                                        IconComponent={KeyboardArrowDownIcon}
                                        value={props.valueStaff.analyst}
                                        onChange={handleChangeStaff}
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    mt: 0.7,
                                                    borderRadius: 2,
                                                    boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                },
                                            },
                                        }}
                                    >
                                        {Staff.analyst.map((option) => (
                                            <MenuItem value={option.name_es}>
                                                {moment.locale() === "en" ? option.name_en : option.name_es}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item lg={4} sm={6} xs={12}>
                                <FormControl
                                    fullWidth
                                    required
                                    error={props.isError4 && props.valueStaff.backupAnalyst === "" ? true : false}
                                >
                                    <InputLabel id="backup-analyst-simple-select-label">
                                        {t("miscellaneous.backupAnalyst")}
                                    </InputLabel>
                                    <Select
                                        name="backupAnalyst"
                                        labelId="backup-analyst-simple-select-label"
                                        label={t("miscellaneous.backupAnalyst")}
                                        IconComponent={KeyboardArrowDownIcon}
                                        value={props.valueStaff.backupAnalyst}
                                        onChange={handleChangeStaff}
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    mt: 0.7,
                                                    borderRadius: 2,
                                                    boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                },
                                            },
                                        }}
                                    >
                                        {Staff.backupAnalyst.map((option) => (
                                            <MenuItem value={option.name_es}>
                                                {moment.locale() === "en" ? option.name_en : option.name_es}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            )}
        </StyledContainer>
    );
}
