/** React imports */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { NumericFormat } from "react-number-format";
import moment from "moment";

/** Icons/Images */
import { ReactComponent as AccountCircle } from "../../../assets/images/avatar-image.svg";

/** MUI icons */
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import { ReactComponent as CreditCardIcon } from "../../../assets/images/credit_card.svg";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import InfoIcon from "@mui/icons-material/Info";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import InsertChartOutlinedIcon from "@mui/icons-material/InsertChartOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import LockIcon from "@mui/icons-material/Lock";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

/** MUI imports */
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import {
    Autocomplete,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Paper,
    Select,
    Step,
    StepButton,
    StepLabel,
    Stepper,
    Switch,
    TextField,
    Typography,
} from "@mui/material";

import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

/** Components imports */
import AlertDialog from "../Dialog";
import Backdrop from "../Backdrop";
import Countries from "../../Js/countries";
import Currencies from "../../Js/currencies";
import MainCountries from "../../Js/mainCountries";
import Staff from "../../Js/staff";

const PREFIX = "NewClient";

const classes = {
    avatarImage: `${PREFIX}-avatarImage`,
};

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 48 * 4.5 + 8,
            boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
        },
        sx: {
            borderRadius: 2,
            mt: 0.7,
        },
    },
};

const CustomDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(4),
    },
    "& .MuiDialogTitle-root": {
        backgroundColor: "#031851",
        fontSize: "18px",
        color: "#fff",
    },
    "& .MuiDialog-paper": {
        borderRadius: 8,
        boxShadow: "10px 10px 15px rgba(3,24,81,0.15)",
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(2),
    },

    [`& .${classes.avatarImage}`]: {
        display: "block",
        width: 80,
        height: 80,
        marginLeft: "auto",
        marginRight: "auto",
    },
}));

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "rgba(19, 31, 62, 0.80)",
        fontSize: 12,
        maxWidth: 320,
        borderRadius: 6,
        fontWeight: "normal",
    },
});

const ColorButton = styled(Button)(({ theme }) => ({
    border: "1px solid #ff5968",
    borderRadius: 40,
}));

const teamCompanyDefault = {
    contact_name: "",
    contact_email: "",
    contact_role: "",
    contact_closure: false,
    contact_status: false,
    contact_requirements: false,
    contact_invoices: false,
};

const NumberFormatCustom = (props) => {
    const todos = useSelector((state) => state.todos);

    const { inputRef, value, onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            getInputRef={inputRef}
            value={value}
            onValueChange={(values) => {
                const { value } = values;

                onChange(value);
            }}
            isAllowed={(values) => {
                const { floatValue } = values;

                return floatValue > 0 && floatValue <= 1000000;
            }}
            thousandSeparator={todos.amountFormat === "de-DE" ? "." : ","}
            decimalSeparator={todos.amountFormat === "de-DE" ? "," : "."}
            valueIsNumericString
        />
    );
};

const defaultFact = {
    level: "",
    ambassador: "",
    subsInv: "",
    clientName: "",
    clientEmail: "",
    contactName: "",
    phoneNumber: "",
}

export default function NewClient({
    setTexto,
    setAlertType,
    setShow,
    setClientData,
    onClose,
    groupData,
    open,
    franqData,
    prove,
    setProveedores,
}) {
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    /** React redux */
    const todos = useSelector((state) => state.todos);

    /** Component states */
    const [activeStep, setActiveStep] = useState(0);
    const [isErrorP, setisErrorP] = useState(false);
    const [isError, setisError] = useState(false);
    const [isError2, setisError2] = useState(false);
    const [isError3, setisError3] = useState(false);
    const [isError4, setisError4] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [changeFranqGroup, setChangeFranqGroup] = useState(false);
    const [groups, setGroups] = useState([]);
    const [dialogForm, setDialogForm] = useState(false);
    const [newProveedor, setNewProveedor] = useState("");
    const [showBackdrop, setBackDrop] = useState(false);
    const [valuesGen, setValuesGen] = useState({
        country: "",
        disableCountry: false,
        disabledFranq: false,
        clientRS: "",
        clientNC: "",
        type: "",
        clasif: "",
        franq: "",
        group: "",
        active: true,
        firmaDate: null,
        fiscalDate: null,
        clientActivity: "",
        langEsc: "",
        langVer: "",
        expenseEstim: "",
        expenseEstim2: 10000,
        franqData: [],
        groupData: [],
    });
    const [valuesCont, setValuesCont] = useState({
        proveedor: "",
        suscripcion: "",
        baseCurrency: null,
        user: "",
        password: "",
        disabledData: false,
    });
    const [valuesFact, setValuesFact] = useState(defaultFact);
    const [valueStaff, setValueStaff] = useState({
        teamCompany1: teamCompanyDefault,
        teamCompany2: teamCompanyDefault,
        teamCompany3: teamCompanyDefault,
        teamCompany4: teamCompanyDefault,
        supervisor: "Por asignar",
        manager: "Por asignar",
        advisor: "Por asignar",
        analyst: "Por asignar",
        backupAnalyst: "Por asignar",
    });

    /** Component variables */
    const steps = [
        t("miscellaneous.generalData"),
        t("miscellaneous.accountingSystem"),
        t("miscellaneous.invoicing"),
        t("miscellaneous.staff"),
    ];

    let isRequired2 =
        valueStaff.teamCompany2.contact_name.trim() !== "" ||
        valueStaff.teamCompany2.contact_email.trim() !== "" ||
        valueStaff.teamCompany2.contact_role.trim() !== "" ||
        valueStaff.teamCompany2.contact_closure ||
        valueStaff.teamCompany2.contact_status ||
        valueStaff.teamCompany2.contact_requirements ||
        valueStaff.teamCompany2.contact_invoices;
    let isRequired3 =
        valueStaff.teamCompany3.contact_name.trim() !== "" ||
        valueStaff.teamCompany3.contact_email.trim() !== "" ||
        valueStaff.teamCompany3.contact_role.trim() !== "" ||
        valueStaff.teamCompany3.contact_closure ||
        valueStaff.teamCompany3.contact_status ||
        valueStaff.teamCompany3.contact_requirements ||
        valueStaff.teamCompany3.contact_invoices;
    let isRequired4 =
        valueStaff.teamCompany4.contact_name.trim() !== "" ||
        valueStaff.teamCompany4.contact_email.trim() !== "" ||
        valueStaff.teamCompany4.contact_role.trim() !== "" ||
        valueStaff.teamCompany4.contact_closure ||
        valueStaff.teamCompany4.contact_status ||
        valueStaff.teamCompany4.contact_requirements ||
        valueStaff.teamCompany4.contact_invoices;

    const handleChangeEstim = (value) => {
        setValuesGen({
            ...valuesGen,
            expenseEstim2: value,
        });
    };

    /** Component functions */
    const handleNext = async () => {
        if (activeStep === steps.length - 1) {
            setBackDrop(true);

            let expenseValid = false;

            if (valuesGen.type === 1 && valuesGen.expenseEstim2) {
                expenseValid = true;
            } else if (valuesGen.type === 2 && valuesGen.expenseEstim) {
                expenseValid = true;
            }

            if (
                valuesGen.clientRS.trim() !== "" &&
                valuesGen.type !== "" &&
                valuesGen.clasif !== "" &&
                valuesGen.country !== "" &&
                valuesGen.fiscalDate !== null &&
                valuesGen.clientActivity.trim() !== "" &&
                valuesGen.langEsc !== "" &&
                valuesGen.langVer !== "" &&
                expenseValid
            ) {
                if (
                    (valuesCont.proveedor !== "" &&
                        valuesCont.suscripcion.trim() !== "" &&
                        valuesCont.baseCurrency !== null &&
                        valuesCont.user.trim() !== "" &&
                        valuesCont.password.trim() !== "") ||
                    (valuesCont.disabledData &&
                        valuesCont.proveedor !== "" &&
                        valuesCont.suscripcion.trim() !== "" &&
                        valuesCont.baseCurrency !== null)
                ) {
                    if (
                        valuesFact.level &&
                        valuesFact.subsInv &&
                        valuesFact.clientName.trim() &&
                        valuesFact.clientEmail.trim() &&
                        /.+@.+\..+/.test(valuesFact.clientEmail) &&
                        valuesFact.contactName.trim()
                    ) {
                        if (
                            valueStaff.teamCompany1.contact_name.trim() !== "" &&
                            valueStaff.teamCompany1.contact_email.trim() !== "" &&
                            /.+@.+\..+/.test(valueStaff.teamCompany1.contact_email) &&
                            valueStaff.teamCompany1.contact_role.trim() !== "" &&
                            valueStaff.supervisor !== "" &&
                            valueStaff.manager !== "" &&
                            valueStaff.advisor !== "" &&
                            valueStaff.analyst !== "" &&
                            valueStaff.backupAnalyst !== ""
                        ) {
                            if (
                                valueStaff.teamCompany1.contact_closure ||
                                valueStaff.teamCompany1.contact_status ||
                                valueStaff.teamCompany1.contact_requirements ||
                                valueStaff.teamCompany1.contact_invoices
                            ) {
                                let arrayTeam = [];
                                arrayTeam.push(valueStaff.teamCompany1);

                                if (isRequired2) {
                                    if (
                                        valueStaff.teamCompany2.contact_name.trim() !== "" &&
                                        valueStaff.teamCompany2.contact_email.trim() !== "" &&
                                        /.+@.+\..+/.test(valueStaff.teamCompany2.contact_email) &&
                                        valueStaff.teamCompany2.contact_role.trim() !== ""
                                    ) {
                                        if (
                                            valueStaff.teamCompany2.contact_closure ||
                                            valueStaff.teamCompany2.contact_status ||
                                            valueStaff.teamCompany2.contact_requirements ||
                                            valueStaff.teamCompany2.contact_invoices
                                        ) {
                                            arrayTeam.push(valueStaff.teamCompany2);
                                        } else {
                                            setTexto(t("miscellaneous.mustSelectIcon"));
                                            setAlertType("warning");
                                            setShow(true);
                                            setBackDrop(false);
                                            setisError4(true);
                                            setisError3(false);
                                            setisError2(false);
                                            setisError(false);
                                            return;
                                        }
                                    } else {
                                        setTexto(t("miscellaneous.requiredData"));
                                        setAlertType("warning");
                                        setShow(true);
                                        setBackDrop(false);
                                        setisError4(true);
                                        setisError3(false);
                                        setisError2(false);
                                        setisError(false);
                                        return;
                                    }
                                }
                                if (isRequired3) {
                                    if (
                                        valueStaff.teamCompany3.contact_name.trim() !== "" &&
                                        valueStaff.teamCompany3.contact_email.trim() !== "" &&
                                        /.+@.+\..+/.test(valueStaff.teamCompany3.contact_email) &&
                                        valueStaff.teamCompany3.contact_role.trim() !== ""
                                    ) {
                                        if (
                                            valueStaff.teamCompany3.contact_closure ||
                                            valueStaff.teamCompany3.contact_status ||
                                            valueStaff.teamCompany3.contact_requirements ||
                                            valueStaff.teamCompany3.contact_invoices
                                        ) {
                                            arrayTeam.push(valueStaff.teamCompany3);
                                        } else {
                                            setTexto(t("miscellaneous.mustSelectIcon"));
                                            setAlertType("warning");
                                            setShow(true);
                                            setBackDrop(false);
                                            setisError4(true);
                                            setisError3(false);
                                            setisError2(false);
                                            setisError(false);
                                            return;
                                        }
                                    } else {
                                        setTexto(t("miscellaneous.requiredData"));
                                        setAlertType("warning");
                                        setShow(true);
                                        setBackDrop(false);
                                        setisError4(true);
                                        setisError3(false);
                                        setisError2(false);
                                        setisError(false);
                                        return;
                                    }
                                }
                                if (isRequired4) {
                                    if (
                                        valueStaff.teamCompany4.contact_name.trim() !== "" &&
                                        valueStaff.teamCompany4.contact_email.trim() !== "" &&
                                        /.+@.+\..+/.test(valueStaff.teamCompany4.contact_email) &&
                                        valueStaff.teamCompany4.contact_role.trim() !== ""
                                    ) {
                                        if (
                                            valueStaff.teamCompany4.contact_closure ||
                                            valueStaff.teamCompany4.contact_status ||
                                            valueStaff.teamCompany4.contact_requirements ||
                                            valueStaff.teamCompany4.contact_invoices
                                        ) {
                                            arrayTeam.push(valueStaff.teamCompany4);
                                        } else {
                                            setTexto(t("miscellaneous.mustSelectIcon"));
                                            setAlertType("warning");
                                            setShow(true);
                                            setBackDrop(false);
                                            setisError4(true);
                                            setisError3(false);
                                            setisError2(false);
                                            setisError(false);
                                            return;
                                        }
                                    } else {
                                        setTexto(t("miscellaneous.requiredData"));
                                        setAlertType("warning");
                                        setShow(true);
                                        setBackDrop(false);
                                        setisError4(true);
                                        setisError3(false);
                                        setisError2(false);
                                        setisError(false);
                                        return;
                                    }
                                }

                                const params = {
                                    // Datos Generales
                                    company_name: valuesGen.clientNC === "" ? undefined : valuesGen.clientNC,
                                    company_legal_name: valuesGen.clientRS,
                                    country_name: valuesGen.country,
                                    franchise_id: valuesGen.franq.trim() === "" ? undefined : valuesGen.franq,
                                    practice_id: valuesGen.group.trim() === "" ? undefined : valuesGen.group,
                                    client_type: valuesGen.type,
                                    client_status: valuesGen.clasif,
                                    bsa_date: valuesGen.firmaDate,
                                    financial_end_date: valuesGen.fiscalDate,
                                    social_activity: valuesGen.clientActivity,
                                    typing_communication: valuesGen.langEsc,
                                    verbal_communication: valuesGen.langVer,
                                    expense_estimation:
                                        valuesGen.type === 1 ? valuesGen.expenseEstim2 : valuesGen.expenseEstim,
                                    // Sistema Contable
                                    xero_master_user: valuesCont.user,
                                    xero_master_password: valuesCont.password,
                                    software_provider: valuesCont.proveedor,
                                    company_subscription_name: valuesCont.suscripcion,
                                    base_currency: valuesCont.baseCurrency.code,
                                    // Facturación
                                    invoicing_level: valuesFact.level,
                                    ambassador_name: valuesFact.ambassador.trim() ?? undefined,
                                    invoicing_subscription: valuesFact.subsInv,
                                    invoicing_client_name: valuesFact.clientName,
                                    invoicing_client_email: valuesFact.clientEmail,
                                    invoicing_contact_name: valuesFact.contactName,
                                    invoicing_phone_number: valuesFact.phoneNumber,
                                    // Equipo
                                    supervisor_name:
                                        valueStaff.supervisor === "" ? "Por asignar" : valueStaff.supervisor,
                                    manager_name: valueStaff.manager === "" ? "Por asignar" : valueStaff.manager,
                                    advisor_name: valueStaff.advisor === "" ? "Por asignar" : valueStaff.advisor,
                                    analyst_name: valueStaff.analyst === "" ? "Por asignar" : valueStaff.analyst,
                                    backup_analyst_name:
                                        valueStaff.backupAnalyst === "" ? "Por asignar" : valueStaff.backupAnalyst,
                                    team_company: arrayTeam,
                                };

                                await fetch(`/addCompany`, {
                                    method: "POST",
                                    headers: {
                                        "Content-type": "application/json; charset=UTF-8",
                                        "Access-Control-Allow-Origin": "*",
                                    },
                                    body: JSON.stringify(params),
                                })
                                    .then((response) => {
                                        if (response.ok) {
                                            setTexto(t("miscellaneous.clientCreation"));
                                            setAlertType("success");
                                            setShow(true);
                                            setClientData();
                                            handleReset();
                                        } else {
                                            setTexto(t("miscellaneous.clientExist"));
                                            setAlertType("error");
                                            setShow(true);
                                        }
                                    })
                                    .catch((error) => {
                                        console.log(error);
                                    });
                            } else {
                                setTexto(t("miscellaneous.mustSelectIcon"));
                                setAlertType("warning");
                                setShow(true);
                                setBackDrop(false);
                                setisError4(true);
                                setisError3(false);
                                setisError2(false);
                                setisError(false);
                                return;
                            }
                        } else {
                            setTexto(t("miscellaneous.requiredData"));
                            setAlertType("warning");
                            setShow(true);
                            setisError4(true);
                            setisError3(false);
                            setisError2(false);
                            setisError(false);
                        }
                    } else {
                        setTexto(t("miscellaneous.requiredData"));
                        setAlertType("warning");
                        setShow(true);
                        setisError3(true);
                        setisError2(false);
                        setisError(false);
                    }
                } else {
                    setTexto(t("miscellaneous.requiredData"));
                    setAlertType("warning");
                    setShow(true);
                    setisError2(true);
                    setisError(false);
                }
            } else {
                setTexto(t("miscellaneous.requiredData"));
                setAlertType("warning");
                setShow(true);
                setisError(true);
            }

            setBackDrop(false);
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        if (activeStep === 0) {
            handleReset();
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }
    };

    const handleReset = () => {
        setActiveStep(0);
        setisError(false);
        setisError2(false);
        setisError3(false);
        setisError4(false);
        setIsDisabled(false);
        setChangeFranqGroup(false);
        setValuesGen({
            ...valuesGen,
            country: "",
            disableCountry: false,
            disabledFranq: false,
            clientRS: "",
            clientNC: "",
            type: "",
            clasif: "",
            franq: "",
            group: "",
            active: true,
            firmaDate: null,
            fiscalDate: null,
            clientActivity: "",
            langEsc: "",
            langVer: "",
            expenseEstim: "",
            expenseEstim2: 10000,
            franqData: [],
            groupData: [],
        });
        setValuesCont({
            ...valuesCont,
            proveedor: "",
            suscripcion: "",
            baseCurrency: null,
            user: "",
            password: "",
            disabledData: false,
        });
        setValuesFact(defaultFact);
        setValueStaff({
            ...valueStaff,
            teamCompany1: teamCompanyDefault,
            teamCompany2: teamCompanyDefault,
            teamCompany3: teamCompanyDefault,
            teamCompany4: teamCompanyDefault,
            supervisor: "Por asignar",
            manager: "Por asignar",
            advisor: "Por asignar",
            analyst: "Por asignar",
            backupAnalyst: "Por asignar",
        });
        onClose();
    };

    const handleChangeFranq = async (event) => {
        setBackDrop(true);

        let teamCompany1 = teamCompanyDefault;
        let teamCompany2 = teamCompanyDefault;
        let teamCompany3 = teamCompanyDefault;
        let teamCompany4 = teamCompanyDefault;

        if (event.target.value.trim() !== "") {
            setChangeFranqGroup(true);

            await fetch(`/getFranchiseDetails?franchise_id=${event.target.value}`, {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
                .then((res) => res.json())
                .then((data) => {
                    if (data.team_company !== undefined) {
                        if (data.team_company.length !== 0) {
                            data.team_company.forEach((element, index) => {
                                if (index === 0) {
                                    teamCompany1 = {
                                        contact_name:
                                            element.contact_name !== undefined && element.contact_name !== null
                                                ? element.contact_name
                                                : "",
                                        contact_email:
                                            element.contact_email !== undefined && element.contact_email !== null
                                                ? element.contact_email
                                                : "",
                                        contact_role:
                                            element.contact_role !== undefined && element.contact_role !== null
                                                ? element.contact_role
                                                : "",
                                        contact_closure: element.contact_closure,
                                        contact_status: element.contact_status,
                                        contact_requirements: element.contact_requirements,
                                        contact_invoices: element.contact_invoices,
                                    };
                                }
                                if (index === 1) {
                                    teamCompany2 = {
                                        contact_name:
                                            element.contact_name !== undefined && element.contact_name !== null
                                                ? element.contact_name
                                                : "",
                                        contact_email:
                                            element.contact_email !== undefined && element.contact_email !== null
                                                ? element.contact_email
                                                : "",
                                        contact_role:
                                            element.contact_role !== undefined && element.contact_role !== null
                                                ? element.contact_role
                                                : "",
                                        contact_closure: element.contact_closure,
                                        contact_status: element.contact_status,
                                        contact_requirements: element.contact_requirements,
                                        contact_invoices: element.contact_invoices,
                                    };
                                }
                                if (index === 2) {
                                    teamCompany3 = {
                                        contact_name:
                                            element.contact_name !== undefined && element.contact_name !== null
                                                ? element.contact_name
                                                : "",
                                        contact_email:
                                            element.contact_email !== undefined && element.contact_email !== null
                                                ? element.contact_email
                                                : "",
                                        contact_role:
                                            element.contact_role !== undefined && element.contact_role !== null
                                                ? element.contact_role
                                                : "",
                                        contact_closure: element.contact_closure,
                                        contact_status: element.contact_status,
                                        contact_requirements: element.contact_requirements,
                                        contact_invoices: element.contact_invoices,
                                    };
                                }
                                if (index === 3) {
                                    teamCompany4 = {
                                        contact_name:
                                            element.contact_name !== undefined && element.contact_name !== null
                                                ? element.contact_name
                                                : "",
                                        contact_email:
                                            element.contact_email !== undefined && element.contact_email !== null
                                                ? element.contact_email
                                                : "",
                                        contact_role:
                                            element.contact_role !== undefined && element.contact_role !== null
                                                ? element.contact_role
                                                : "",
                                        contact_closure: element.contact_closure,
                                        contact_status: element.contact_status,
                                        contact_requirements: element.contact_requirements,
                                        contact_invoices: element.contact_invoices,
                                    };
                                }
                            });
                        }
                    }

                    setIsDisabled(true);
                    setValueStaff({
                        ...valueStaff,
                        supervisor: data.supervisor_name !== undefined ? data.supervisor_name : "Por asignar",
                        manager: data.manager_name !== undefined ? data.manager_name : "Por asignar",
                        analyst: data.analyst_name !== undefined ? data.analyst_name : "Por asignar",
                        backupAnalyst:
                            data.backup_analyst_name !== undefined ? data.backup_analyst_name : "Por asignar",
                        advisor: data.advisor_name !== undefined ? data.advisor_name : "Por asignar",
                        teamCompany1: teamCompany1,
                        teamCompany2: teamCompany2,
                        teamCompany3: teamCompany3,
                        teamCompany4: teamCompany4,
                    });
                    setValuesFact({
                        level: 3,
                        ambassador: data.ambassador_name ?? "",
                        subsInv: data.invoicing_subscription ?? "",
                        clientName: data.invoicing_client_name ?? "",
                        clientEmail: data.invoicing_client_email ?? "",
                        contactName: data.invoicing_contact_name ?? "",
                        phoneNumber: data.invoicing_phone_number ?? "",
                    });
                    setValuesCont({
                        ...valuesCont,
                        user: data.xero_master_user !== undefined ? data.xero_master_user : "",
                        password: data.xero_master_password !== undefined ? data.xero_master_password : "",
                        proveedor: data.software_provider !== undefined ? data.software_provider : "",
                        suscripcion: data.company_subscription_name !== undefined ? data.company_subscription_name : "",
                        baseCurrency:
                            data.base_currency !== undefined
                                ? Currencies.find((currency) => currency.code === data.base_currency)
                                : null,
                        disabledData: data.disabled_software !== undefined ? data.disabled_software : false,
                    });
                    setValuesGen({
                        ...valuesGen,
                        franq: event.target.value,
                        group: " ",
                        type: data.client_type !== undefined ? data.client_type : "",
                        firmaDate: data.bsa_date !== undefined ? moment(data.bsa_date, "YYYY-MM-DD") : null,
                        disableCountry: true,
                        country: data.country_name !== undefined ? data.country_name : "",
                        langEsc: data.typing_communication !== undefined ? data.typing_communication : "",
                        langVer: data.verbal_communication !== undefined ? data.verbal_communication : "",
                    });
                });
        } else {
            setIsDisabled(false);
            if (valuesGen.group.trim() !== "") {
                setChangeFranqGroup(true);
                await fetch(`/getPracticeDetails?practice_id=${valuesGen.group}`, {
                    method: "GET",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                    },
                })
                    .then((res) => res.json())
                    .then((data) => {
                        if (data.team_company !== undefined) {
                            if (data.team_company.length !== 0) {
                                data.team_company.forEach((element, index) => {
                                    if (index === 0) {
                                        teamCompany1 = {
                                            contact_name:
                                                element.contact_name !== undefined && element.contact_name !== null
                                                    ? element.contact_name
                                                    : "",
                                            contact_email:
                                                element.contact_email !== undefined && element.contact_email !== null
                                                    ? element.contact_email
                                                    : "",
                                            contact_role:
                                                element.contact_role !== undefined && element.contact_role !== null
                                                    ? element.contact_role
                                                    : "",
                                            contact_closure: element.contact_closure,
                                            contact_status: element.contact_status,
                                            contact_requirements: element.contact_requirements,
                                            contact_invoices: element.contact_invoices,
                                        };
                                    }
                                    if (index === 1) {
                                        teamCompany2 = {
                                            contact_name:
                                                element.contact_name !== undefined && element.contact_name !== null
                                                    ? element.contact_name
                                                    : "",
                                            contact_email:
                                                element.contact_email !== undefined && element.contact_email !== null
                                                    ? element.contact_email
                                                    : "",
                                            contact_role:
                                                element.contact_role !== undefined && element.contact_role !== null
                                                    ? element.contact_role
                                                    : "",
                                            contact_closure: element.contact_closure,
                                            contact_status: element.contact_status,
                                            contact_requirements: element.contact_requirements,
                                            contact_invoices: element.contact_invoices,
                                        };
                                    }
                                    if (index === 2) {
                                        teamCompany3 = {
                                            contact_name:
                                                element.contact_name !== undefined && element.contact_name !== null
                                                    ? element.contact_name
                                                    : "",
                                            contact_email:
                                                element.contact_email !== undefined && element.contact_email !== null
                                                    ? element.contact_email
                                                    : "",
                                            contact_role:
                                                element.contact_role !== undefined && element.contact_role !== null
                                                    ? element.contact_role
                                                    : "",
                                            contact_closure: element.contact_closure,
                                            contact_status: element.contact_status,
                                            contact_requirements: element.contact_requirements,
                                            contact_invoices: element.contact_invoices,
                                        };
                                    }
                                    if (index === 3) {
                                        teamCompany4 = {
                                            contact_name:
                                                element.contact_name !== undefined && element.contact_name !== null
                                                    ? element.contact_name
                                                    : "",
                                            contact_email:
                                                element.contact_email !== undefined && element.contact_email !== null
                                                    ? element.contact_email
                                                    : "",
                                            contact_role:
                                                element.contact_role !== undefined && element.contact_role !== null
                                                    ? element.contact_role
                                                    : "",
                                            contact_closure: element.contact_closure,
                                            contact_status: element.contact_status,
                                            contact_requirements: element.contact_requirements,
                                            contact_invoices: element.contact_invoices,
                                        };
                                    }
                                });
                            }
                        }

                        setValueStaff({
                            ...valueStaff,
                            supervisor: data.supervisor_name !== undefined ? data.supervisor_name : "Por asignar",
                            manager: data.manager_name !== undefined ? data.manager_name : "Por asignar",
                            analyst: data.analyst_name !== undefined ? data.analyst_name : "Por asignar",
                            backupAnalyst:
                                data.backup_analyst_name !== undefined ? data.backup_analyst_name : "Por asignar",
                            advisor: data.advisor_name !== undefined ? data.advisor_name : "Por asignar",
                            teamCompany1: teamCompany1,
                            teamCompany2: teamCompany2,
                            teamCompany3: teamCompany3,
                            teamCompany4: teamCompany4,
                        });
                        setValuesFact({
                            ...valuesFact,
                            level: data.franchise_id ? 3 : 2,
                            ambassador: data.ambassador_name ?? "",
                            subsInv: data.invoicing_subscription ?? "",
                            clientName: data.invoicing_client_name ?? "",
                            clientEmail: data.invoicing_client_email ?? "",
                            contactName: data.invoicing_contact_name ?? "",
                            phoneNumber: data.invoicing_phone_number ?? "",
                        });
                        setValuesCont({
                            ...valuesCont,
                            user: data.xero_master_user !== undefined ? data.xero_master_user : "",
                            password: data.xero_master_password !== undefined ? data.xero_master_password : "",
                            proveedor: data.software_provider !== undefined ? data.software_provider : "",
                            suscripcion:
                                data.company_subscription_name !== undefined ? data.company_subscription_name : "",
                            baseCurrency:
                                data.base_currency !== undefined
                                    ? Currencies.find((currency) => currency.code === data.base_currency)
                                    : null,
                            disabledData: data.disabled_software !== undefined ? data.disabled_software : false,
                        });
                        setValuesGen({
                            ...valuesGen,
                            franq:
                                data.franchise_id !== undefined && data.franchise_id !== null
                                    ? data.franchise_id
                                    : event.target.value,
                            disabledFranq: data.franchise_id !== undefined ? true : false,
                            type: data.client_type !== undefined ? data.client_type : "",
                            firmaDate: data.bsa_date !== undefined ? moment(data.bsa_date, "YYYY-MM-DD") : null,
                            disableCountry: data.franchise_id !== undefined ? true : false,
                            langEsc: data.typing_communication !== undefined ? data.typing_communication : "",
                            langVer: data.verbal_communication !== undefined ? data.verbal_communication : "",
                        });
                    });
            } else {
                setChangeFranqGroup(false);
                setValueStaff({
                    ...valueStaff,
                    teamCompany1: teamCompanyDefault,
                    teamCompany2: teamCompanyDefault,
                    teamCompany3: teamCompanyDefault,
                    teamCompany4: teamCompanyDefault,
                    supervisor: "Por asignar",
                    manager: "Por asignar",
                    advisor: "Por asignar",
                    analyst: "Por asignar",
                    backupAnalyst: "Por asignar",
                });
                setValuesFact(defaultFact);
                setValuesCont({
                    ...valuesCont,
                    user: "",
                    password: "",
                    proveedor: "",
                    suscripcion: "",
                    baseCurrency: null,
                    disabledData: false,
                });
                setValuesGen({
                    ...valuesGen,
                    franq: event.target.value,
                    type: "",
                    firmaDate: null,
                    country: "",
                    langEsc: "",
                    langVer: "",
                    disableCountry: false,
                });
            }
        }
        await fetch(`/getFranchiseGroups?id_franchise=${event.target.value}`, {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then((res) => res.json())
            .then((data) => {
                setGroups(data);
            });
        setBackDrop(false);
    };

    const handleStep = (step) => {
        setActiveStep(step);
    };

    const handleChangeGroup = async (event) => {
        setBackDrop(true);
        if (event.target.value.trim() !== "") {
            setChangeFranqGroup(true);

            await fetch(`/getPracticeDetails?practice_id=${event.target.value}`, {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
                .then((res) => res.json())
                .then((data) => {
                    setIsDisabled(data.franchise_id !== undefined && data.franchise_id !== null ? true : false);
                    if (
                        valuesGen.franq.trim() === "" ||
                        (data.franchise_id !== undefined && data.franchise_id !== null)
                    ) {
                        let teamCompany1 = teamCompanyDefault;
                        let teamCompany2 = teamCompanyDefault;
                        let teamCompany3 = teamCompanyDefault;
                        let teamCompany4 = teamCompanyDefault;

                        if (data.team_company !== undefined) {
                            if (data.team_company.length !== 0) {
                                data.team_company.forEach((element, index) => {
                                    if (index === 0) {
                                        teamCompany1 = {
                                            contact_name:
                                                element.contact_name !== undefined && element.contact_name !== null
                                                    ? element.contact_name
                                                    : "",
                                            contact_email:
                                                element.contact_email !== undefined && element.contact_email !== null
                                                    ? element.contact_email
                                                    : "",
                                            contact_role:
                                                element.contact_role !== undefined && element.contact_role !== null
                                                    ? element.contact_role
                                                    : "",
                                            contact_closure: element.contact_closure,
                                            contact_status: element.contact_status,
                                            contact_requirements: element.contact_requirements,
                                            contact_invoices: element.contact_invoices,
                                        };
                                    }
                                    if (index === 1) {
                                        teamCompany2 = {
                                            contact_name:
                                                element.contact_name !== undefined && element.contact_name !== null
                                                    ? element.contact_name
                                                    : "",
                                            contact_email:
                                                element.contact_email !== undefined && element.contact_email !== null
                                                    ? element.contact_email
                                                    : "",
                                            contact_role:
                                                element.contact_role !== undefined && element.contact_role !== null
                                                    ? element.contact_role
                                                    : "",
                                            contact_closure: element.contact_closure,
                                            contact_status: element.contact_status,
                                            contact_requirements: element.contact_requirements,
                                            contact_invoices: element.contact_invoices,
                                        };
                                    }
                                    if (index === 2) {
                                        teamCompany3 = {
                                            contact_name:
                                                element.contact_name !== undefined && element.contact_name !== null
                                                    ? element.contact_name
                                                    : "",
                                            contact_email:
                                                element.contact_email !== undefined && element.contact_email !== null
                                                    ? element.contact_email
                                                    : "",
                                            contact_role:
                                                element.contact_role !== undefined && element.contact_role !== null
                                                    ? element.contact_role
                                                    : "",
                                            contact_closure: element.contact_closure,
                                            contact_status: element.contact_status,
                                            contact_requirements: element.contact_requirements,
                                            contact_invoices: element.contact_invoices,
                                        };
                                    }
                                    if (index === 3) {
                                        teamCompany4 = {
                                            contact_name:
                                                element.contact_name !== undefined && element.contact_name !== null
                                                    ? element.contact_name
                                                    : "",
                                            contact_email:
                                                element.contact_email !== undefined && element.contact_email !== null
                                                    ? element.contact_email
                                                    : "",
                                            contact_role:
                                                element.contact_role !== undefined && element.contact_role !== null
                                                    ? element.contact_role
                                                    : "",
                                            contact_closure: element.contact_closure,
                                            contact_status: element.contact_status,
                                            contact_requirements: element.contact_requirements,
                                            contact_invoices: element.contact_invoices,
                                        };
                                    }
                                });
                            }
                        }

                        setValueStaff({
                            ...valueStaff,
                            supervisor: data.supervisor_name !== undefined ? data.supervisor_name : "Por asignar",
                            manager: data.manager_name !== undefined ? data.manager_name : "Por asignar",
                            analyst: data.analyst_name !== undefined ? data.analyst_name : "Por asignar",
                            backupAnalyst:
                                data.backup_analyst_name !== undefined ? data.backup_analyst_name : "Por asignar",
                            advisor: data.advisor_name !== undefined ? data.advisor_name : "Por asignar",
                            teamCompany1: teamCompany1,
                            teamCompany2: teamCompany2,
                            teamCompany3: teamCompany3,
                            teamCompany4: teamCompany4,
                        });
                        setValuesFact({
                            ...valuesFact,
                            level: data.franchise_id ? 3 : 2,
                            ambassador: data.ambassador_name ?? "",
                            subsInv: data.invoicing_subscription ?? "",
                            clientName: data.invoicing_client_name ?? "",
                            clientEmail: data.invoicing_client_email ?? "",
                            contactName: data.invoicing_contact_name ?? "",
                            phoneNumber: data.invoicing_phone_number ?? "",
                        });
                        setValuesCont({
                            ...valuesCont,
                            user: data.xero_master_user !== undefined ? data.xero_master_user : "",
                            password: data.xero_master_password !== undefined ? data.xero_master_password : "",
                            proveedor: data.software_provider !== undefined ? data.software_provider : "",
                            suscripcion:
                                data.company_subscription_name !== undefined ? data.company_subscription_name : "",
                            baseCurrency:
                                data.base_currency !== undefined
                                    ? Currencies.find((currency) => currency.code === data.base_currency)
                                    : null,
                            disabledData: data.disabled_software !== undefined ? data.disabled_software : false,
                        });
                        setValuesGen({
                            ...valuesGen,
                            group: event.target.value,
                            franq:
                                data.franchise_id !== undefined && data.franchise_id !== null ? data.franchise_id : " ",
                            disabledFranq: data.franchise_id !== undefined && data.franchise_id !== null ? true : false,
                            type: data.client_type !== undefined ? data.client_type : "",
                            firmaDate: data.bsa_date !== undefined ? moment(data.bsa_date, "YYYY-MM-DD") : null,
                            disableCountry:
                                data.franchise_id !== undefined && data.franchise_id !== null ? true : false,
                            country: data.country_name !== undefined ? data.country_name : valuesGen.country,
                            langEsc: data.typing_communication !== undefined ? data.typing_communication : "",
                            langVer: data.verbal_communication !== undefined ? data.verbal_communication : "",
                        });
                    } else {
                        setValuesGen({
                            ...valuesGen,
                            group: event.target.value,
                            franq:
                                data.franchise_id !== undefined && data.franchise_id !== null ? data.franchise_id : " ",
                            disabledFranq: false,
                            type: data.client_type !== undefined ? data.client_type : "",
                            country:
                                data.franchise_id !== undefined && data.franchise_id !== null
                                    ? data.country_name
                                    : valuesGen.country,
                            disableCountry:
                                data.franchise_id !== undefined && data.franchise_id !== null ? true : false,
                        });
                    }
                });
        } else {
            if (valuesGen.franq.trim() === "") {
                setChangeFranqGroup(false);
                setIsDisabled(false);
                setValueStaff({
                    ...valueStaff,
                    teamCompany1: teamCompanyDefault,
                    teamCompany2: teamCompanyDefault,
                    teamCompany3: teamCompanyDefault,
                    teamCompany4: teamCompanyDefault,
                    supervisor: "Por asignar",
                    manager: "Por asignar",
                    advisor: "Por asignar",
                    analyst: "Por asignar",
                    backupAnalyst: "Por asignar",
                });
                setValuesCont({
                    ...valuesCont,
                    user: "",
                    password: "",
                    proveedor: "",
                    suscripcion: "",
                    baseCurrency: null,
                    disabledData: false,
                });
                setValuesFact(defaultFact);
                setValuesGen({
                    ...valuesGen,
                    group: event.target.value,
                    firmaDate: null,
                    type: "",
                    country: "",
                    langEsc: "",
                    langVer: "",
                    disableCountry: false,
                    disabledFranq: false,
                });
            } else {
                setValuesGen({
                    ...valuesGen,
                    group: event.target.value,
                    disabledFranq: false,
                    type: 1,
                });
            }
        }
        setBackDrop(false);
    };

    const handleChange = (event) => {
        setValuesGen({
            ...valuesGen,
            [event.target.name]: event.target.value,
        });
    };

    const handleChangeFirmaDate = (date) => {
        setValuesGen({
            ...valuesGen,
            firmaDate: date,
        });
    };

    const handleChangeFact = (event) => {
        setValuesFact({
            ...valuesFact,
            [event.target.name]: event.target.value,
        });
    };

    const handleChangeSwitch = (event) => {
        setValuesCont({
            ...valuesCont,
            disabledData: event.target.checked,
        });
    };

    const handleChangePhone = (event) => {
        const regex = /^\+[0-9\s.()-]{0,20}$/;
        if (event.target.value === "" || regex.test(event.target.value)) {
            setValuesFact({
                ...valuesFact,
                [event.target.name]: event.target.value,
            });
        }
    };

    const handleChangeStaff = (event) => {
        setValueStaff({
            ...valueStaff,
            [event.target.name]: event.target.value,
        });
    };

    const handleChangeTeamStaff = (event, team) => {
        setValueStaff({
            ...valueStaff,
            [team]: {
                ...valueStaff[team],
                [event.target.name]: event.target.value,
            },
        });
    };

    const handleChangeCheck = (event, value, team) => {
        switch (value) {
            case 0:
                setValueStaff({
                    ...valueStaff,
                    [team]: {
                        ...valueStaff[team],
                        contact_closure: event.target.checked,
                    },
                });
                break;
            case 1:
                setValueStaff({
                    ...valueStaff,
                    [team]: {
                        ...valueStaff[team],
                        contact_status: event.target.checked,
                    },
                });
                break;
            case 4:
                setValueStaff({
                    ...valueStaff,
                    [team]: {
                        ...valueStaff[team],
                        contact_invoices: event.target.checked,
                    },
                });
                break;
            default:
                setValueStaff({
                    ...valueStaff,
                    [team]: {
                        ...valueStaff[team],
                        contact_requirements: event.target.checked,
                    },
                });
                break;
        }
    };

    const clearTeamStaff = (event, team) => {
        setValueStaff({
            ...valueStaff,
            [team]: teamCompanyDefault,
        });
    };

    const handleClickShowPassword = () => {
        setValuesCont({
            ...valuesCont,
            showPassword: !valuesCont.showPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleChangeFiscalDate = (date) => {
        setValuesGen({
            ...valuesGen,
            fiscalDate: date,
        });
    };

    const handleChangeCont = (event) => {
        setValuesCont({
            ...valuesCont,
            [event.target.name]: event.target.value,
        });
    };

    const handleChangeCurrency = (event, value, reason) => {
        if (reason === "clear") {
            setValuesCont({
                ...valuesCont,
                baseCurrency: null,
            });
        } else {
            setValuesCont({
                ...valuesCont,
                baseCurrency: value,
            });
        }
    };

    const sortCurrencies = () => {
        if (moment.locale() === "en") {
            Currencies.sort((a, b) => {
                if (a.name > b.name) return 1;

                if (a.name < b.name) return -1;

                return 0;
            });
        } else {
            Currencies.sort((a, b) => {
                if (a.name_es > b.name_es) return 1;

                if (a.name_es < b.name_es) return -1;

                return 0;
            });
        }
    };

    const handleChangeProv = (event) => {
        if (
            event.target.value === "Profit" ||
            event.target.value === "Excel" ||
            event.target.value === "Quickbooks Desktop"
        ) {
            setValuesCont({
                ...valuesCont,
                [event.target.name]: event.target.value,
                disabledData: true,
            });
        } else {
            setValuesCont({
                ...valuesCont,
                [event.target.name]: event.target.value,
                disabledData: false,
            });
        }
    };

    const handleChangeNew = (event) => {
        setNewProveedor(event.target.value);
    };

    const closeDialog = () => {
        setisErrorP(false);
        setNewProveedor("");
        setDialogForm(false);
    };

    const saveProvee = async () => {
        setBackDrop(true);
        const params = {
            provider_name: newProveedor,
        };

        if (newProveedor !== "") {
            await fetch(`/addProvider`, {
                method: "POST",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
                body: JSON.stringify(params),
            })
                .then((response) => {
                    if (response.ok) {
                        setTexto(t("miscellaneous.supplierCreation"));
                        setAlertType("success");
                        setShow(true);
                        getProviders();
                        closeDialog();
                    } else {
                        setTexto(t("miscellaneous.supplierCreationError"));
                        setAlertType("error");
                        setShow(true);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            setTexto(t("miscellaneous.requiredData"));
            setAlertType("warning");
            setShow(true);
            setisErrorP(true);
        }

        setBackDrop(false);
    };

    const getProviders = async () => {
        setBackDrop(true);
        await fetch(`/getProviders`, {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then((res) => res.json())
            .then((data) => {
                setProveedores(data.providers);
            });
        setBackDrop(false);
    };

    const sortCountries = () => {
        if (moment.locale() === "en") {
            Countries.sort((a, b) => {
                if (a.name_en > b.name_en) return 1;

                if (a.name_en < b.name_en) return -1;

                return 0;
            });
        } else {
            Countries.sort((a, b) => {
                if (a.name_es > b.name_es) return 1;

                if (a.name_es < b.name_es) return -1;

                return 0;
            });
        }
    };

    const dialogBody = (
        <Grid container>
            <Grid item xs={12}>
                <TextField
                    name="proveedor"
                    label={t("miscellaneous.supplier")}
                    required
                    value={newProveedor}
                    onChange={handleChangeNew}
                    fullWidth
                    error={isErrorP && newProveedor === "" ? true : false}
                />
            </Grid>
        </Grid>
    );

    const dialogButtons = (
        <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
            <Grid item>
                <ColorButton
                    onClick={() => setDialogForm(false)}
                    sx={{
                        mr: 1,
                        "&:hover": {
                            backgroundColor: "white",
                            color: "#2f3190",
                            border: "1px solid #2f3190",
                        },
                    }}
                    color="secondary"
                    disableElevation
                    variant="outlined"
                >
                    {t("miscellaneous.cancel")}
                </ColorButton>
            </Grid>
            <Grid item>
                <ColorButton
                    onClick={saveProvee}
                    sx={{
                        "&:hover": {
                            backgroundColor: "#2f3190",
                            border: "1px solid #2f3190",
                        },
                    }}
                    color="secondary"
                    disableElevation
                    variant="contained"
                >
                    {t("miscellaneous.accept")}
                </ColorButton>
            </Grid>
        </Grid>
    );

    useEffect(() => {
        sortCountries();
        sortCurrencies();
        setGroups(groupData);
        // eslint-disable-next-line react-hooks/rules-of-hooks
    }, [t, groupData]);

    return (
        <CustomDialog
            open={open}
            onClose={() => handleReset()}
            aria-labelledby="customized-dialog-title"
            fullWidth={true}
            maxWidth="lg"
        >
            <DialogTitle id="alert-dialog-title">{t("miscellaneous.newClient")}</DialogTitle>
            <DialogContent dividers>
                <Box sx={{ width: "100%" }}>
                    <Stepper
                        nonLinear
                        activeStep={activeStep}
                        orientation={window.innerWidth < 600 ? "vertical" : "horizontal"}
                    >
                        {steps.map((label, index) => {
                            const labelProps = {};
                            if (
                                (isError && index === 0) ||
                                (isError2 && index === 1) ||
                                (isError3 && index === 2) ||
                                (isError4 && index === 3)
                            ) {
                                labelProps.optional = (
                                    <Typography variant="caption" color="error">
                                        {t("miscellaneous.required")}
                                    </Typography>
                                );

                                labelProps.error = true;
                            }
                            return (
                                <Step key={label}>
                                    <StepButton color="inherit" onClick={() => handleStep(index)}>
                                        <StepLabel {...labelProps}>{label}</StepLabel>
                                    </StepButton>
                                </Step>
                            );
                        })}
                    </Stepper>
                </Box>
                <Box sx={{ width: "100%", pt: 4 }}>
                    {activeStep === 0 && (
                        <>
                            <Card elevation={0}>
                                <CardHeader title={t("miscellaneous.data")} sx={{ color: "#ff5968" }} />
                                <Divider />
                                <CardContent>
                                    <Grid container spacing={2}>
                                        {changeFranqGroup ? (
                                            <Grid item xs={12}>
                                                <Typography variant="h4" color="secondary">
                                                    {t("miscellaneous.inheritData")}
                                                </Typography>
                                            </Grid>
                                        ) : null}
                                        <Grid item xl={3} sm={6} xs={12}>
                                            <FormControl fullWidth disabled={valuesGen.disabledFranq}>
                                                <InputLabel id="franq-simple-select-label">
                                                    {t("miscellaneous.franchise")}
                                                </InputLabel>
                                                <Select
                                                    name="franq"
                                                    labelId="franq-simple-select-label"
                                                    IconComponent={KeyboardArrowDownIcon}
                                                    value={valuesGen.franq}
                                                    label={t("miscellaneous.franchise")}
                                                    onChange={handleChangeFranq}
                                                    MenuProps={MenuProps}
                                                >
                                                    <MenuItem value=" ">
                                                        <Typography
                                                            variant="body1"
                                                            component="span"
                                                            align="center"
                                                            color="textPrimary"
                                                        >
                                                            N/A
                                                        </Typography>
                                                    </MenuItem>
                                                    {franqData.map((value) => (
                                                        <MenuItem value={value.id}>
                                                            <Typography
                                                                variant="body1"
                                                                component="span"
                                                                align="center"
                                                                color="textPrimary"
                                                            >
                                                                {value.franchise_name}
                                                            </Typography>
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xl={3} sm={6} xs={12}>
                                            <FormControl fullWidth>
                                                <InputLabel id="group-simple-select-label">
                                                    {t("miscellaneous.group")}
                                                </InputLabel>
                                                <Select
                                                    name="group"
                                                    labelId="group-simple-select-label"
                                                    IconComponent={KeyboardArrowDownIcon}
                                                    value={valuesGen.group}
                                                    label={t("miscellaneous.group")}
                                                    onChange={handleChangeGroup}
                                                    MenuProps={MenuProps}
                                                >
                                                    <MenuItem value=" ">
                                                        <Typography
                                                            variant="body1"
                                                            component="span"
                                                            align="center"
                                                            color="textPrimary"
                                                        >
                                                            N/A
                                                        </Typography>
                                                    </MenuItem>
                                                    {groups.map((value) => (
                                                        <MenuItem value={value.id}>
                                                            <Typography
                                                                variant="body1"
                                                                component="span"
                                                                align="center"
                                                                color="textPrimary"
                                                            >
                                                                {value.group_name}
                                                            </Typography>
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xl={6} sm={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                required
                                                label={t("miscellaneous.businessName")}
                                                name="clientRS"
                                                onChange={handleChange}
                                                value={valuesGen.clientRS}
                                                variant="outlined"
                                                error={isError && valuesGen.clientRS.trim() === "" ? true : false}
                                            />
                                        </Grid>
                                        <Grid item xl={6} sm={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label={t("miscellaneous.commercialName")}
                                                name="clientNC"
                                                onChange={handleChange}
                                                value={valuesGen.clientNC}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item xl={3} sm={6} xs={12}>
                                            <FormControl
                                                fullWidth
                                                required
                                                disabled={isDisabled}
                                                error={isError && valuesGen.type === "" ? true : false}
                                            >
                                                <InputLabel id="type-simple-select-label">
                                                    {t("miscellaneous.type")}
                                                </InputLabel>
                                                <Select
                                                    name="type"
                                                    labelId="type-simple-select-label"
                                                    IconComponent={KeyboardArrowDownIcon}
                                                    value={valuesGen.type}
                                                    label={t("miscellaneous.type")}
                                                    onChange={handleChange}
                                                    MenuProps={{
                                                        PaperProps: {
                                                            sx: {
                                                                mt: 0.7,
                                                                borderRadius: 2,
                                                                boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                            },
                                                        },
                                                    }}
                                                >
                                                    <MenuItem value={1}>{t("miscellaneous.firm")}</MenuItem>
                                                    <MenuItem value={2}>SMB</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xl={3} sm={6} xs={12}>
                                            <FormControl
                                                fullWidth
                                                required
                                                error={isError && valuesGen.clasif === "" ? true : false}
                                            >
                                                <InputLabel id="clasi-simple-select-label">
                                                    {t("miscellaneous.classification")}
                                                </InputLabel>
                                                <Select
                                                    name="clasif"
                                                    labelId="clasi-simple-select-label"
                                                    IconComponent={KeyboardArrowDownIcon}
                                                    value={valuesGen.clasif}
                                                    label={t("miscellaneous.classification")}
                                                    onChange={handleChange}
                                                    MenuProps={{
                                                        PaperProps: {
                                                            sx: {
                                                                mt: 0.7,
                                                                borderRadius: 2,
                                                                boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                            },
                                                        },
                                                    }}
                                                >
                                                    <MenuItem
                                                        value={1}
                                                        sx={{
                                                            fontStyle: "italic",
                                                            ...(moment.locale() === "en" && { fontStyle: "normal" }),
                                                        }}
                                                    >
                                                        Standard
                                                    </MenuItem>
                                                    <MenuItem
                                                        value={2}
                                                        sx={{
                                                            fontStyle: "italic",
                                                            ...(moment.locale() === "en" && { fontStyle: "normal" }),
                                                        }}
                                                    >
                                                        VIP
                                                    </MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item sm={6} xs={12}>
                                            <FormControl
                                                fullWidth
                                                disabled={valuesGen.disableCountry}
                                                required
                                                error={isError && valuesGen.country === "" ? true : false}
                                            >
                                                <InputLabel id="country-simple-select-label">
                                                    {t("miscellaneous.country")}
                                                </InputLabel>
                                                <Select
                                                    name="country"
                                                    labelId="country-simple-select-label"
                                                    id="demo-simple-select"
                                                    IconComponent={KeyboardArrowDownIcon}
                                                    value={valuesGen.country}
                                                    label={t("miscellaneous.country")}
                                                    onChange={handleChange}
                                                    MenuProps={MenuProps}
                                                >
                                                    {MainCountries.map((option) => (
                                                        <MenuItem value={option.code}>
                                                            {moment.locale() === "en" ? option.name_en : option.name_es}
                                                        </MenuItem>
                                                    ))}
                                                    <Divider />
                                                    {Countries.map((option) => (
                                                        <MenuItem value={option.code}>
                                                            {moment.locale() === "en" ? option.name_en : option.name_es}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <LocalizationProvider
                                            dateAdapter={AdapterMoment}
                                            adapterLocale={t("language.locale")}
                                            localeText={{ clearButtonLabel: t("miscellaneous.clear") }}
                                        >
                                            <Grid item xl={3} sm={6} xs={12}>
                                                <DatePicker
                                                    componentsProps={{
                                                        actionBar: { actions: ["clear"] },
                                                    }}
                                                    value={valuesGen.firmaDate}
                                                    onChange={(newValue) => {
                                                        handleChangeFirmaDate(newValue);
                                                    }}
                                                    inputFormat={todos.dateFormat}
                                                    disabled
                                                    renderInput={(params) => <TextField fullWidth {...params} />}
                                                    inputProps={{ readOnly: true }}
                                                    label={t("miscellaneous.firmDate")}
                                                />
                                            </Grid>
                                            <Grid item xl={3} sm={6} xs={12}>
                                                <DatePicker
                                                    componentsProps={{
                                                        actionBar: { actions: ["clear"] },
                                                    }}
                                                    value={valuesGen.fiscalDate}
                                                    onChange={(newValue) => {
                                                        handleChangeFiscalDate(newValue);
                                                    }}
                                                    inputFormat={"MMMM"}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            fullWidth
                                                            required
                                                            {...params}
                                                            error={
                                                                isError && valuesGen.fiscalDate === null ? true : false
                                                            }
                                                        />
                                                    )}
                                                    inputProps={{ readOnly: true }}
                                                    label={t("miscellaneous.endDateF")}
                                                    views={["month"]}
                                                />
                                            </Grid>
                                            <Grid item xl={6} sm={6} xs={12}>
                                                <TextField
                                                    fullWidth
                                                    label={t("miscellaneous.economicActivity")}
                                                    name="clientActivity"
                                                    required
                                                    onChange={handleChange}
                                                    value={valuesGen.clientActivity}
                                                    variant="outlined"
                                                    error={
                                                        isError && valuesGen.clientActivity.trim() === "" ? true : false
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xl={3} sm={6} xs={12}>
                                                <FormControl
                                                    fullWidth
                                                    disabled={isDisabled}
                                                    required
                                                    error={isError && valuesGen.langVer === "" ? true : false}
                                                >
                                                    <InputLabel id="ver-simple-select-label">
                                                        {t("miscellaneous.verbalCommunication")}
                                                    </InputLabel>
                                                    <Select
                                                        name="langVer"
                                                        labelId="ver-simple-select-label"
                                                        IconComponent={KeyboardArrowDownIcon}
                                                        value={valuesGen.langVer}
                                                        label={t("miscellaneous.verbalCommunication")}
                                                        onChange={handleChange}
                                                        MenuProps={{
                                                            PaperProps: {
                                                                sx: {
                                                                    mt: 0.7,
                                                                    borderRadius: 2,
                                                                    boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                                },
                                                            },
                                                        }}
                                                    >
                                                        <MenuItem value={1}>{t("language.english")}</MenuItem>
                                                        <MenuItem value={2}>{t("language.spanish")}</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xl={3} sm={6} xs={12}>
                                                <FormControl
                                                    fullWidth
                                                    disabled={isDisabled}
                                                    required
                                                    error={isError && valuesGen.langEsc === "" ? true : false}
                                                >
                                                    <InputLabel id="esc-simple-select-label">
                                                        {t("miscellaneous.writtenCommunication")}
                                                    </InputLabel>
                                                    <Select
                                                        name="langEsc"
                                                        labelId="esc-simple-select-label"
                                                        IconComponent={KeyboardArrowDownIcon}
                                                        value={valuesGen.langEsc}
                                                        label={t("miscellaneous.writtenCommunication")}
                                                        onChange={handleChange}
                                                        MenuProps={{
                                                            PaperProps: {
                                                                sx: {
                                                                    mt: 0.7,
                                                                    borderRadius: 2,
                                                                    boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                                },
                                                            },
                                                        }}
                                                    >
                                                        <MenuItem value={1}>{t("language.english")}</MenuItem>
                                                        <MenuItem value={2}>{t("language.spanish")}</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </LocalizationProvider>
                                    </Grid>
                                </CardContent>
                            </Card>
                            <Card elevation={0}>
                                <CardHeader title={t("miscellaneous.expenses")} sx={{ color: "#ff5968" }} />
                                <Divider />
                                <CardContent sx={{ pb: 5 }}>
                                    <Grid container spacing={2}>
                                        <Grid item lg={3} sm={6} xs={12}>
                                            {valuesGen.type === 1 ? (
                                                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                                                    <LightTooltip
                                                        title={t("currentView.amountx10")}
                                                        aria-label="validatedMonth"
                                                    >
                                                        <InfoIcon sx={{ mr: 1, mb: 2 }} />
                                                    </LightTooltip>
                                                    <FormControl
                                                        fullWidth
                                                        required
                                                        error={isError && !valuesGen.expenseEstim2}
                                                        variant="outlined"
                                                    >
                                                        <InputLabel htmlFor="outlined-adornment-expenseEstim2">
                                                            {t("miscellaneous.expenseEstimate")}
                                                        </InputLabel>
                                                        <OutlinedInput
                                                            id="outlined-adornment-expenseEstim2"
                                                            variant="standard"
                                                            name="expenseEstim2"
                                                            inputComponent={NumberFormatCustom}
                                                            onChange={handleChangeEstim}
                                                            inputProps={{
                                                                onBlur: () =>
                                                                    handleChangeEstim(
                                                                        Math.ceil(valuesGen.expenseEstim2 / 10000) *
                                                                            10000
                                                                    ),
                                                            }}
                                                            value={valuesGen.expenseEstim2}
                                                            startAdornment={
                                                                <InputAdornment disableTypography position="start">
                                                                    $
                                                                </InputAdornment>
                                                            }
                                                            label={t("miscellaneous.expenseEstimate")}
                                                        />
                                                    </FormControl>
                                                </Box>
                                            ) : (
                                                <FormControl
                                                    fullWidth
                                                    required
                                                    error={isError && !valuesGen.expenseEstim}
                                                    disabled={!valuesGen.type}
                                                >
                                                    <InputLabel id="estim-simple-select-label">
                                                        {t("miscellaneous.expenseEstimate")}
                                                    </InputLabel>
                                                    <Select
                                                        name="expenseEstim"
                                                        labelId="estim-simple-select-label"
                                                        IconComponent={KeyboardArrowDownIcon}
                                                        value={valuesGen.expenseEstim}
                                                        label={t("miscellaneous.expenseEstimate")}
                                                        onChange={handleChange}
                                                        MenuProps={MenuProps}
                                                    >
                                                        <MenuItem value={-1}>N/A</MenuItem>
                                                        <MenuItem value={5000}>
                                                            ${parseFloat(5000).toLocaleString(todos.amountFormat)}
                                                        </MenuItem>
                                                        <MenuItem value={10000}>
                                                            ${parseFloat(10000).toLocaleString(todos.amountFormat)}
                                                        </MenuItem>
                                                        <MenuItem value={25000}>
                                                            ${parseFloat(25000).toLocaleString(todos.amountFormat)}
                                                        </MenuItem>
                                                        <MenuItem value={50000}>
                                                            ${parseFloat(50000).toLocaleString(todos.amountFormat)}
                                                        </MenuItem>
                                                        <MenuItem value={75000}>
                                                            ${parseFloat(75000).toLocaleString(todos.amountFormat)}
                                                        </MenuItem>
                                                        <MenuItem value={100000}>
                                                            ${parseFloat(100000).toLocaleString(todos.amountFormat)}
                                                        </MenuItem>
                                                        <MenuItem value={150000}>
                                                            ${parseFloat(150000).toLocaleString(todos.amountFormat)}
                                                        </MenuItem>
                                                        <MenuItem value={200000}>
                                                            ${parseFloat(200000).toLocaleString(todos.amountFormat)}
                                                        </MenuItem>
                                                        <MenuItem value={250000}>
                                                            ${parseFloat(250000).toLocaleString(todos.amountFormat)}
                                                        </MenuItem>
                                                        <MenuItem value={300000}>
                                                            ${parseFloat(300000).toLocaleString(todos.amountFormat)}
                                                        </MenuItem>
                                                        <MenuItem value={350000}>
                                                            ${parseFloat(350000).toLocaleString(todos.amountFormat)}
                                                        </MenuItem>
                                                        <MenuItem value={400000}>
                                                            ${parseFloat(400000).toLocaleString(todos.amountFormat)}
                                                        </MenuItem>
                                                        <MenuItem value={450000}>
                                                            ${parseFloat(450000).toLocaleString(todos.amountFormat)}
                                                        </MenuItem>
                                                        <MenuItem value={500000}>
                                                            ${parseFloat(500000).toLocaleString(todos.amountFormat)}
                                                        </MenuItem>
                                                        <MenuItem value={550000}>
                                                            ${parseFloat(550000).toLocaleString(todos.amountFormat)}
                                                        </MenuItem>
                                                        <MenuItem value={600000}>
                                                            ${parseFloat(600000).toLocaleString(todos.amountFormat)}
                                                        </MenuItem>
                                                        <MenuItem value={650000}>
                                                            ${parseFloat(650000).toLocaleString(todos.amountFormat)}
                                                        </MenuItem>
                                                        <MenuItem value={700000}>
                                                            ${parseFloat(700000).toLocaleString(todos.amountFormat)}
                                                        </MenuItem>
                                                        <MenuItem value={750000}>
                                                            ${parseFloat(750000).toLocaleString(todos.amountFormat)}
                                                        </MenuItem>
                                                        <MenuItem value={800000}>
                                                            ${parseFloat(800000).toLocaleString(todos.amountFormat)}
                                                        </MenuItem>
                                                        <MenuItem value={850000}>
                                                            ${parseFloat(850000).toLocaleString(todos.amountFormat)}
                                                        </MenuItem>
                                                        <MenuItem value={900000}>
                                                            ${parseFloat(900000).toLocaleString(todos.amountFormat)}
                                                        </MenuItem>
                                                        <MenuItem value={950000}>
                                                            ${parseFloat(950000).toLocaleString(todos.amountFormat)}
                                                        </MenuItem>
                                                        <MenuItem value={1000000}>
                                                            ${parseFloat(1000000).toLocaleString(todos.amountFormat)}
                                                        </MenuItem>
                                                    </Select>
                                                </FormControl>
                                            )}
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </>
                    )}
                    {activeStep === 1 && (
                        <>
                            <Card elevation={0}>
                                <CardHeader title={t("miscellaneous.system")} sx={{ color: "#ff5968" }} />
                                <Divider />
                                <CardContent>
                                    <Grid container spacing={2}>
                                        <Grid item lg={4} sm={6} xs={12}>
                                            <FormControl
                                                fullWidth
                                                required
                                                error={isError2 && valuesCont.proveedor === "" ? true : false}
                                            >
                                                <InputLabel id="provee-simple-select-label">
                                                    {t("miscellaneous.supplier")}
                                                </InputLabel>
                                                <Select
                                                    name="proveedor"
                                                    labelId="provee-simple-select-label"
                                                    IconComponent={KeyboardArrowDownIcon}
                                                    value={valuesCont.proveedor}
                                                    label={t("miscellaneous.supplier")}
                                                    onChange={handleChangeProv}
                                                    MenuProps={{
                                                        PaperProps: {
                                                            sx: {
                                                                mt: 0.7,
                                                                borderRadius: 2,
                                                                boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                            },
                                                        },
                                                    }}
                                                >
                                                    {prove.map((value) => (
                                                        <MenuItem value={value.provider_name}>
                                                            {value.provider_name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item lg={2} sm={6} xs={12}>
                                            <LightTooltip title={t("miscellaneous.addSupplier")} aria-label="cargar">
                                                <IconButton onClick={() => setDialogForm(true)}>
                                                    <AddBoxOutlinedIcon color="primary" fontSize="large" />
                                                </IconButton>
                                            </LightTooltip>
                                        </Grid>
                                        <Grid item lg={3} xs={12}>
                                            <TextField
                                                fullWidth
                                                label={t("miscellaneous.subscriptionName")}
                                                name="suscripcion"
                                                onChange={handleChangeCont}
                                                value={valuesCont.suscripcion}
                                                variant="outlined"
                                                required
                                                error={isError2 && valuesCont.suscripcion.trim() === "" ? true : false}
                                            />
                                        </Grid>
                                        <Grid item lg={3} xs={12}>
                                            <Autocomplete
                                                id="currency-autocomplete"
                                                value={valuesCont.baseCurrency}
                                                options={Currencies}
                                                getOptionLabel={(option) => {
                                                    if (option.name === "") {
                                                        return `${option.code}`;
                                                    } else {
                                                        return `${option.code} - ${
                                                            moment.locale() === "en" ? option.name : option.name_es
                                                        }`;
                                                    }
                                                }}
                                                renderOption={(props, option) => {
                                                    if (option.name === "") {
                                                        return (
                                                            <Box component="li" {...props}>
                                                                {option.code}
                                                            </Box>
                                                        );
                                                    } else {
                                                        return (
                                                            <Box component="li" {...props}>
                                                                {option.code} -{" "}
                                                                {moment.locale() === "en"
                                                                    ? option.name
                                                                    : option.name_es}
                                                            </Box>
                                                        );
                                                    }
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        required
                                                        label={t("services.functionalCurrency")}
                                                        error={
                                                            isError2 && valuesCont.baseCurrency === null ? true : false
                                                        }
                                                    />
                                                )}
                                                popupIcon={<KeyboardArrowDownIcon color="primary" />}
                                                PaperComponent={({ children }) => (
                                                    <Paper
                                                        sx={{
                                                            mt: 0.7,
                                                            borderRadius: 2,
                                                            boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                        }}
                                                    >
                                                        {children}
                                                    </Paper>
                                                )}
                                                onChange={(event, value, reason) =>
                                                    handleChangeCurrency(event, value, reason)
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                            <Card elevation={0}>
                                <CardHeader
                                    title={
                                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                            <Box>
                                                <Typography variant="h1" sx={{ fontWeight: 700 }} color="secondary">
                                                    {t("miscellaneous.accountingSystemAccess")}
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <FormControlLabel
                                                    inputProps={{ readOnly: true }}
                                                    control={
                                                        <Switch
                                                            checked={valuesCont.disabledData}
                                                            inputProps={{ "aria-label": "controlled" }}
                                                            color={"secondary"}
                                                            onChange={handleChangeSwitch}
                                                        />
                                                    }
                                                    label={
                                                        <Typography variant="body1" color="textPrimary">
                                                            {t("miscellaneous.notApply")}
                                                        </Typography>
                                                    }
                                                />
                                            </Box>
                                        </Box>
                                    }
                                    sx={{ color: "#ff5968" }}
                                />
                                <Divider />
                                <CardContent>
                                    <Grid container spacing={2}>
                                        <Grid item lg={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label={t("login.userAccess")}
                                                name="user"
                                                onChange={handleChangeCont}
                                                value={valuesCont.user}
                                                variant="outlined"
                                                disabled={valuesCont.disabledData}
                                                required={!valuesCont.disabledData}
                                                error={
                                                    !valuesCont.disabledData &&
                                                    isError2 &&
                                                    valuesCont.user.trim() === ""
                                                        ? true
                                                        : false
                                                }
                                            />
                                        </Grid>
                                        <Grid item lg={6} xs={12}>
                                            <FormControl
                                                fullWidth
                                                variant="outlined"
                                                disabled={valuesCont.disabledData}
                                                required={!valuesCont.disabledData}
                                                error={
                                                    !valuesCont.disabledData &&
                                                    isError2 &&
                                                    valuesCont.password.trim() === ""
                                                        ? true
                                                        : false
                                                }
                                            >
                                                <InputLabel htmlFor="outlined-adornment-password">
                                                    {t("login.password")}
                                                </InputLabel>
                                                <OutlinedInput
                                                    id="outlined-adornment-password"
                                                    fullWidth
                                                    type={valuesCont.showPassword ? "text" : "password"}
                                                    label={t("login.password")}
                                                    name="password"
                                                    onChange={handleChangeCont}
                                                    value={valuesCont.password}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowPassword}
                                                                onMouseDown={handleMouseDownPassword}
                                                                edge="end"
                                                            >
                                                                {valuesCont.showPassword ? (
                                                                    <VisibilityOff />
                                                                ) : (
                                                                    <Visibility />
                                                                )}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </>
                    )}
                    {activeStep === 2 && (
                        <>
                            <Card elevation={0}>
                                <CardHeader title={t("miscellaneous.invoicing")} sx={{ color: "#ff5968" }} />
                                <Divider />
                                <CardContent>
                                    <Grid container spacing={2}>
                                        <Grid item lg={4} sm={6} xs={12}>
                                            <FormControl
                                                fullWidth
                                                disabled={isDisabled}
                                                required
                                                error={isError3 && !valuesFact.level}
                                            >
                                                <InputLabel id="level-simple-select-label">
                                                    {t("miscellaneous.invoicingServicesTo")}
                                                </InputLabel>
                                                <Select
                                                    name="level"
                                                    labelId="level-simple-select-label"
                                                    IconComponent={KeyboardArrowDownIcon}
                                                    value={valuesFact.level}
                                                    label={t("miscellaneous.invoicingServicesTo")}
                                                    onChange={handleChangeFact}
                                                    MenuProps={{
                                                        PaperProps: {
                                                            sx: {
                                                                mt: 0.7,
                                                                borderRadius: 2,
                                                                boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                            },
                                                        },
                                                    }}
                                                >
                                                    <MenuItem value={1}>{t("miscellaneous.client")}</MenuItem>
                                                    {valuesGen.group.trim() !== "" ? (
                                                        <MenuItem value={2}>{t("miscellaneous.group")}</MenuItem>
                                                    ) : null}
                                                    {valuesGen.franq.trim() !== "" ? (
                                                        <MenuItem value={3}>{t("miscellaneous.franchise")}</MenuItem>
                                                    ) : null}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item lg={4} sm={6} xs={12}>
                                            <FormControl fullWidth>
                                                <InputLabel shrink id="ambassador-simple-select-label">
                                                    {t("proposals.ambassador")}
                                                </InputLabel>
                                                <Select
                                                    name="ambassador"
                                                    labelId="ambassador-simple-select-label"
                                                    IconComponent={KeyboardArrowDownIcon}
                                                    value={valuesFact.ambassador}
                                                    displayEmpty
                                                    onChange={handleChangeFact}
                                                    input={<OutlinedInput notched label={t("proposals.ambassador")} />}
                                                    MenuProps={{
                                                        PaperProps: {
                                                            sx: {
                                                                mt: 0.7,
                                                                borderRadius: 2,
                                                                boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                            },
                                                        },
                                                    }}
                                                >
                                                    <MenuItem value="">N/A</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item lg={4} sm={6} xs={12}>
                                            <FormControl fullWidth required error={isError3 && !valuesFact.subsInv}>
                                                <InputLabel id="subsInv-simple-select-label">
                                                    {t("invoicing.subsInv")}
                                                </InputLabel>
                                                <Select
                                                    name="subsInv"
                                                    labelId="subsInv-simple-select-label"
                                                    label={t("invoicing.subsInv")}
                                                    IconComponent={KeyboardArrowDownIcon}
                                                    value={valuesFact.subsInv}
                                                    onChange={handleChangeFact}
                                                    MenuProps={{
                                                        PaperProps: {
                                                            sx: {
                                                                mt: 0.7,
                                                                borderRadius: 2,
                                                                boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                            },
                                                        },
                                                    }}
                                                >
                                                    <MenuItem value={1}>GCA SC (Venezuela)</MenuItem>
                                                    <MenuItem value={2}>GCA INC (Estados Unidos)</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                            <Card elevation={0}>
                                <CardHeader title={t("miscellaneous.contact")} sx={{ color: "#ff5968" }} />
                                <Divider />
                                <CardContent sx={{ pb: 5 }}>
                                    <Grid container spacing={2}>
                                        <Grid item lg={4} xs={12}>
                                            <TextField
                                                disabled={isDisabled}
                                                fullWidth
                                                label={t("miscellaneous.invoicingNameGCA")}
                                                name="contactName"
                                                onChange={handleChangeFact}
                                                value={valuesFact.contactName}
                                                variant="outlined"
                                                required
                                                error={isError3 && valuesFact.contactName === "" ? true : false}
                                            />
                                        </Grid>
                                        <Grid item lg={4} xs={12}>
                                            <TextField
                                                disabled={isDisabled}
                                                fullWidth
                                                label={t("miscellaneous.contactName")}
                                                name="clientName"
                                                onChange={handleChangeFact}
                                                value={valuesFact.clientName}
                                                variant="outlined"
                                                required
                                                error={isError3 && valuesFact.clientName === "" ? true : false}
                                            />
                                        </Grid>
                                        <Grid item lg={4} xs={12}>
                                            <TextField
                                                disabled={isDisabled}
                                                fullWidth
                                                label={t("miscellaneous.contactEmail")}
                                                name="clientEmail"
                                                onChange={handleChangeFact}
                                                value={valuesFact.clientEmail}
                                                variant="outlined"
                                                required
                                                error={
                                                    isError3 && valuesFact.clientEmail === ""
                                                        ? true
                                                        : isError3 && !/.+@.+\..+/.test(valuesFact.clientEmail)
                                                        ? true
                                                        : false
                                                }
                                            />
                                        </Grid>
                                        <Grid item lg={4} xs={12}>
                                            <FormControl fullWidth disabled={isDisabled}>
                                                <InputLabel htmlFor="invoicing-phone-number-mask-input">
                                                    {t("miscellaneous.phoneNumber")}
                                                </InputLabel>
                                                <OutlinedInput
                                                    id="invoicing-phone-number-mask-input"
                                                    name="phoneNumber"
                                                    label={t("miscellaneous.phoneNumber")}
                                                    value={valuesFact.phoneNumber}
                                                    onChange={handleChangePhone}
                                                    placeholder="e.g. +99 (999) 999-9999"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </>
                    )}
                    {activeStep === 3 && (
                        <>
                            <Card elevation={0}>
                                <CardHeader title={t("miscellaneous.clientStaff")} sx={{ color: "#ff5968" }} />
                                <Divider />
                                <CardContent sx={{ pb: 5 }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={6}>
                                            <Card
                                                sx={{
                                                    borderRadius: "20px",
                                                    backgroundColor: "#F8F9F8",
                                                    boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                }}
                                            >
                                                <CardContent sx={{ p: "24px 24px 16px !important" }}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} xl={4}>
                                                            <AccountCircle className={classes.avatarImage} />
                                                            <Typography
                                                                variant="h2"
                                                                color="primary"
                                                                align="center"
                                                                sx={{ pt: 1 }}
                                                            >
                                                                {`${t("miscellaneous.contact")} 1`}
                                                            </Typography>
                                                            <LightTooltip title={t("recurrent.clear")}>
                                                                <IconButton
                                                                    aria-label="clear"
                                                                    color="primary"
                                                                    onClick={(event) =>
                                                                        clearTeamStaff(event, "teamCompany1")
                                                                    }
                                                                    sx={{ display: "block", m: "auto" }}
                                                                >
                                                                    <ClearAllIcon />
                                                                </IconButton>
                                                            </LightTooltip>
                                                        </Grid>
                                                        <Grid item xs={12} xl={8}>
                                                            <TextField
                                                                fullWidth
                                                                required
                                                                label={t("miscellaneous.name")}
                                                                name="contact_name"
                                                                onChange={(event) =>
                                                                    handleChangeTeamStaff(event, "teamCompany1")
                                                                }
                                                                value={valueStaff.teamCompany1.contact_name}
                                                                variant="outlined"
                                                                error={
                                                                    isError4 &&
                                                                    valueStaff.teamCompany1.contact_name.trim() === ""
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                            <TextField
                                                                fullWidth
                                                                required
                                                                label={t("miscellaneous.email")}
                                                                name="contact_email"
                                                                onChange={(event) =>
                                                                    handleChangeTeamStaff(event, "teamCompany1")
                                                                }
                                                                value={valueStaff.teamCompany1.contact_email}
                                                                variant="outlined"
                                                                error={
                                                                    isError4 &&
                                                                    valueStaff.teamCompany1.contact_email.trim() === ""
                                                                        ? true
                                                                        : isError4 &&
                                                                          !/.+@.+\..+/.test(
                                                                              valueStaff.teamCompany1.contact_email.trim()
                                                                          )
                                                                        ? true
                                                                        : false
                                                                }
                                                                sx={{ mt: 2 }}
                                                            />
                                                            <TextField
                                                                fullWidth
                                                                required
                                                                label={t("miscellaneous.role")}
                                                                name="contact_role"
                                                                onChange={(event) =>
                                                                    handleChangeTeamStaff(event, "teamCompany1")
                                                                }
                                                                value={valueStaff.teamCompany1.contact_role}
                                                                variant="outlined"
                                                                error={
                                                                    isError4 &&
                                                                    valueStaff.teamCompany1.contact_role.trim() === ""
                                                                        ? true
                                                                        : false
                                                                }
                                                                sx={{ mt: 2 }}
                                                            />
                                                            <FormGroup sx={{ mt: 2, display: "block" }}>
                                                                <FormLabel id="permissions-checkboxes-group-label">
                                                                    <Typography
                                                                        variant="h4"
                                                                        color="primary"
                                                                        sx={{ pb: 1 }}
                                                                    >
                                                                        {t("miscellaneous.permissions")}:
                                                                    </Typography>
                                                                </FormLabel>
                                                                <LightTooltip
                                                                    title={t("services.toolRequirements")}
                                                                    aria-label="req"
                                                                >
                                                                    <Checkbox
                                                                        icon={<AssignmentTurnedInOutlinedIcon />}
                                                                        checkedIcon={<AssignmentTurnedInIcon />}
                                                                        checked={
                                                                            valueStaff.teamCompany1.contact_requirements
                                                                        }
                                                                        onChange={(event) =>
                                                                            handleChangeCheck(event, 3, "teamCompany1")
                                                                        }
                                                                        sx={{
                                                                            ml: { xs: 0, lg: 0, xl: 0 },
                                                                            mr: { xs: 1, lg: 0, xl: 1 },
                                                                        }}
                                                                    />
                                                                </LightTooltip>
                                                                <LightTooltip
                                                                    title={t("services.toolAccountingClosures")}
                                                                    aria-label="cierres"
                                                                >
                                                                    <Checkbox
                                                                        icon={<LockOutlinedIcon />}
                                                                        checkedIcon={<LockIcon />}
                                                                        checked={
                                                                            valueStaff.teamCompany1.contact_closure
                                                                        }
                                                                        onChange={(event) =>
                                                                            handleChangeCheck(event, 0, "teamCompany1")
                                                                        }
                                                                        sx={{
                                                                            ml: { xs: 1, lg: 0, xl: 1 },
                                                                            mr: { xs: 1, lg: 0, xl: 1 },
                                                                        }}
                                                                    />
                                                                </LightTooltip>
                                                                <LightTooltip
                                                                    title={t("services.toolStatus")}
                                                                    aria-label="status"
                                                                >
                                                                    <Checkbox
                                                                        icon={<InsertChartOutlinedIcon />}
                                                                        checkedIcon={<InsertChartIcon />}
                                                                        checked={valueStaff.teamCompany1.contact_status}
                                                                        onChange={(event) =>
                                                                            handleChangeCheck(event, 1, "teamCompany1")
                                                                        }
                                                                        sx={{
                                                                            ml: { xs: 1, lg: 0, xl: 1 },
                                                                            mr: { xs: 1, lg: 0, xl: 1 },
                                                                        }}
                                                                    />
                                                                </LightTooltip>
                                                                <LightTooltip
                                                                    title={t("services.toolInvoicing")}
                                                                    aria-label="invoicing"
                                                                >
                                                                    <Checkbox
                                                                        icon={<CreditCardOutlinedIcon />}
                                                                        checkedIcon={
                                                                            <CreditCardIcon className="icon-normal-size" />
                                                                        }
                                                                        checked={
                                                                            valueStaff.teamCompany1.contact_invoices
                                                                        }
                                                                        onChange={(event) =>
                                                                            handleChangeCheck(event, 4, "teamCompany1")
                                                                        }
                                                                        sx={{
                                                                            ml: { xs: 1, lg: 0, xl: 1 },
                                                                            mr: { xs: 1, lg: 0, xl: 1 },
                                                                        }}
                                                                    />
                                                                </LightTooltip>
                                                            </FormGroup>
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Card
                                                sx={{
                                                    borderRadius: "20px",
                                                    backgroundColor: "#F8F9F8",
                                                    boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                }}
                                            >
                                                <CardContent sx={{ p: "24px 24px 16px !important" }}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} xl={4}>
                                                            <AccountCircle className={classes.avatarImage} />
                                                            <Typography
                                                                variant="h2"
                                                                color="primary"
                                                                align="center"
                                                                sx={{ pt: 1 }}
                                                            >
                                                                {`${t("miscellaneous.contact")} 2`}
                                                            </Typography>
                                                            <LightTooltip title={t("recurrent.clear")}>
                                                                <IconButton
                                                                    aria-label="clear"
                                                                    color="primary"
                                                                    onClick={(event) =>
                                                                        clearTeamStaff(event, "teamCompany2")
                                                                    }
                                                                    sx={{ display: "block", m: "auto" }}
                                                                >
                                                                    <ClearAllIcon />
                                                                </IconButton>
                                                            </LightTooltip>
                                                        </Grid>
                                                        <Grid item xs={12} xl={8}>
                                                            <TextField
                                                                fullWidth
                                                                required={isRequired2}
                                                                label={t("miscellaneous.name")}
                                                                name="contact_name"
                                                                onChange={(event) =>
                                                                    handleChangeTeamStaff(event, "teamCompany2")
                                                                }
                                                                value={valueStaff.teamCompany2.contact_name}
                                                                variant="outlined"
                                                                error={
                                                                    isRequired2 &&
                                                                    isError4 &&
                                                                    valueStaff.teamCompany2.contact_name.trim() === ""
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                            <TextField
                                                                fullWidth
                                                                required={isRequired2}
                                                                label={t("miscellaneous.email")}
                                                                name="contact_email"
                                                                onChange={(event) =>
                                                                    handleChangeTeamStaff(event, "teamCompany2")
                                                                }
                                                                value={valueStaff.teamCompany2.contact_email}
                                                                variant="outlined"
                                                                error={
                                                                    isRequired2 &&
                                                                    isError4 &&
                                                                    valueStaff.teamCompany2.contact_email.trim() === ""
                                                                        ? true
                                                                        : isRequired2 &&
                                                                          isError4 &&
                                                                          !/.+@.+\..+/.test(
                                                                              valueStaff.teamCompany2.contact_email.trim()
                                                                          )
                                                                        ? true
                                                                        : false
                                                                }
                                                                sx={{ mt: 2 }}
                                                            />
                                                            <TextField
                                                                fullWidth
                                                                required={isRequired2}
                                                                label={t("miscellaneous.role")}
                                                                name="contact_role"
                                                                onChange={(event) =>
                                                                    handleChangeTeamStaff(event, "teamCompany2")
                                                                }
                                                                value={valueStaff.teamCompany2.contact_role}
                                                                variant="outlined"
                                                                error={
                                                                    isRequired2 &&
                                                                    isError4 &&
                                                                    valueStaff.teamCompany2.contact_role.trim() === ""
                                                                        ? true
                                                                        : false
                                                                }
                                                                sx={{ mt: 2 }}
                                                            />
                                                            <FormGroup sx={{ mt: 2, display: "block" }}>
                                                                <FormLabel id="permissions-checkboxes-group-label">
                                                                    <Typography
                                                                        variant="h4"
                                                                        color="primary"
                                                                        sx={{ pb: 1 }}
                                                                    >
                                                                        {t("miscellaneous.permissions")}:
                                                                    </Typography>
                                                                </FormLabel>
                                                                <LightTooltip
                                                                    title={t("services.toolRequirements")}
                                                                    aria-label="req"
                                                                >
                                                                    <Checkbox
                                                                        icon={<AssignmentTurnedInOutlinedIcon />}
                                                                        checkedIcon={<AssignmentTurnedInIcon />}
                                                                        checked={
                                                                            valueStaff.teamCompany2.contact_requirements
                                                                        }
                                                                        onChange={(event) =>
                                                                            handleChangeCheck(event, 3, "teamCompany2")
                                                                        }
                                                                        sx={{
                                                                            ml: { xs: 0, lg: 0, xl: 0 },
                                                                            mr: { xs: 1, lg: 0, xl: 1 },
                                                                        }}
                                                                    />
                                                                </LightTooltip>
                                                                <LightTooltip
                                                                    title={t("services.toolAccountingClosures")}
                                                                    aria-label="cierres"
                                                                >
                                                                    <Checkbox
                                                                        icon={<LockOutlinedIcon />}
                                                                        checkedIcon={<LockIcon />}
                                                                        checked={
                                                                            valueStaff.teamCompany2.contact_closure
                                                                        }
                                                                        onChange={(event) =>
                                                                            handleChangeCheck(event, 0, "teamCompany2")
                                                                        }
                                                                        sx={{
                                                                            ml: { xs: 1, lg: 0, xl: 1 },
                                                                            mr: { xs: 1, lg: 0, xl: 1 },
                                                                        }}
                                                                    />
                                                                </LightTooltip>
                                                                <LightTooltip
                                                                    title={t("services.toolStatus")}
                                                                    aria-label="status"
                                                                >
                                                                    <Checkbox
                                                                        icon={<InsertChartOutlinedIcon />}
                                                                        checkedIcon={<InsertChartIcon />}
                                                                        checked={valueStaff.teamCompany2.contact_status}
                                                                        onChange={(event) =>
                                                                            handleChangeCheck(event, 1, "teamCompany2")
                                                                        }
                                                                        sx={{
                                                                            ml: { xs: 1, lg: 0, xl: 1 },
                                                                            mr: { xs: 1, lg: 0, xl: 1 },
                                                                        }}
                                                                    />
                                                                </LightTooltip>
                                                                <LightTooltip
                                                                    title={t("services.toolInvoicing")}
                                                                    aria-label="invoicing"
                                                                >
                                                                    <Checkbox
                                                                        icon={<CreditCardOutlinedIcon />}
                                                                        checkedIcon={
                                                                            <CreditCardIcon className="icon-normal-size" />
                                                                        }
                                                                        checked={
                                                                            valueStaff.teamCompany2.contact_invoices
                                                                        }
                                                                        onChange={(event) =>
                                                                            handleChangeCheck(event, 4, "teamCompany2")
                                                                        }
                                                                        sx={{
                                                                            ml: { xs: 1, lg: 0, xl: 1 },
                                                                            mr: { xs: 1, lg: 0, xl: 1 },
                                                                        }}
                                                                    />
                                                                </LightTooltip>
                                                            </FormGroup>
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Card
                                                sx={{
                                                    borderRadius: "20px",
                                                    backgroundColor: "#F8F9F8",
                                                    boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                }}
                                            >
                                                <CardContent sx={{ p: "24px 24px 16px !important" }}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} xl={4}>
                                                            <AccountCircle className={classes.avatarImage} />
                                                            <Typography
                                                                variant="h2"
                                                                color="primary"
                                                                align="center"
                                                                sx={{ pt: 1 }}
                                                            >
                                                                {`${t("miscellaneous.contact")} 3`}
                                                            </Typography>
                                                            <LightTooltip title={t("recurrent.clear")}>
                                                                <IconButton
                                                                    aria-label="clear"
                                                                    color="primary"
                                                                    onClick={(event) =>
                                                                        clearTeamStaff(event, "teamCompany3")
                                                                    }
                                                                    sx={{ display: "block", m: "auto" }}
                                                                >
                                                                    <ClearAllIcon />
                                                                </IconButton>
                                                            </LightTooltip>
                                                        </Grid>
                                                        <Grid item xs={12} xl={8}>
                                                            <TextField
                                                                fullWidth
                                                                required={isRequired3}
                                                                label={t("miscellaneous.name")}
                                                                name="contact_name"
                                                                onChange={(event) =>
                                                                    handleChangeTeamStaff(event, "teamCompany3")
                                                                }
                                                                value={valueStaff.teamCompany3.contact_name}
                                                                variant="outlined"
                                                                error={
                                                                    isRequired3 &&
                                                                    isError4 &&
                                                                    valueStaff.teamCompany3.contact_name.trim() === ""
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                            <TextField
                                                                fullWidth
                                                                required={isRequired3}
                                                                label={t("miscellaneous.email")}
                                                                name="contact_email"
                                                                onChange={(event) =>
                                                                    handleChangeTeamStaff(event, "teamCompany3")
                                                                }
                                                                value={valueStaff.teamCompany3.contact_email}
                                                                variant="outlined"
                                                                error={
                                                                    isRequired3 &&
                                                                    isError4 &&
                                                                    valueStaff.teamCompany3.contact_email.trim() === ""
                                                                        ? true
                                                                        : isRequired3 &&
                                                                          isError4 &&
                                                                          !/.+@.+\..+/.test(
                                                                              valueStaff.teamCompany3.contact_email.trim()
                                                                          )
                                                                        ? true
                                                                        : false
                                                                }
                                                                sx={{ mt: 2 }}
                                                            />
                                                            <TextField
                                                                fullWidth
                                                                required={isRequired3}
                                                                label={t("miscellaneous.role")}
                                                                name="contact_role"
                                                                onChange={(event) =>
                                                                    handleChangeTeamStaff(event, "teamCompany3")
                                                                }
                                                                value={valueStaff.teamCompany3.contact_role}
                                                                variant="outlined"
                                                                error={
                                                                    isRequired3 &&
                                                                    isError4 &&
                                                                    valueStaff.teamCompany3.contact_role.trim() === ""
                                                                        ? true
                                                                        : false
                                                                }
                                                                sx={{ mt: 2 }}
                                                            />
                                                            <FormGroup sx={{ mt: 2, display: "block" }}>
                                                                <FormLabel id="permissions-checkboxes-group-label">
                                                                    <Typography
                                                                        variant="h4"
                                                                        color="primary"
                                                                        sx={{ pb: 1 }}
                                                                    >
                                                                        {t("miscellaneous.permissions")}:
                                                                    </Typography>
                                                                </FormLabel>
                                                                <LightTooltip
                                                                    title={t("services.toolRequirements")}
                                                                    aria-label="req"
                                                                >
                                                                    <Checkbox
                                                                        icon={<AssignmentTurnedInOutlinedIcon />}
                                                                        checkedIcon={<AssignmentTurnedInIcon />}
                                                                        checked={
                                                                            valueStaff.teamCompany3.contact_requirements
                                                                        }
                                                                        onChange={(event) =>
                                                                            handleChangeCheck(event, 3, "teamCompany3")
                                                                        }
                                                                        sx={{
                                                                            ml: { xs: 0, lg: 0, xl: 0 },
                                                                            mr: { xs: 1, lg: 0, xl: 1 },
                                                                        }}
                                                                    />
                                                                </LightTooltip>
                                                                <LightTooltip
                                                                    title={t("services.toolAccountingClosures")}
                                                                    aria-label="cierres"
                                                                >
                                                                    <Checkbox
                                                                        icon={<LockOutlinedIcon />}
                                                                        checkedIcon={<LockIcon />}
                                                                        checked={
                                                                            valueStaff.teamCompany3.contact_closure
                                                                        }
                                                                        onChange={(event) =>
                                                                            handleChangeCheck(event, 0, "teamCompany3")
                                                                        }
                                                                        sx={{
                                                                            ml: { xs: 1, lg: 0, xl: 1 },
                                                                            mr: { xs: 1, lg: 0, xl: 1 },
                                                                        }}
                                                                    />
                                                                </LightTooltip>
                                                                <LightTooltip
                                                                    title={t("services.toolStatus")}
                                                                    aria-label="status"
                                                                >
                                                                    <Checkbox
                                                                        icon={<InsertChartOutlinedIcon />}
                                                                        checkedIcon={<InsertChartIcon />}
                                                                        checked={valueStaff.teamCompany3.contact_status}
                                                                        onChange={(event) =>
                                                                            handleChangeCheck(event, 1, "teamCompany3")
                                                                        }
                                                                        sx={{
                                                                            ml: { xs: 1, lg: 0, xl: 1 },
                                                                            mr: { xs: 1, lg: 0, xl: 1 },
                                                                        }}
                                                                    />
                                                                </LightTooltip>
                                                                <LightTooltip
                                                                    title={t("services.toolInvoicing")}
                                                                    aria-label="invoicing"
                                                                >
                                                                    <Checkbox
                                                                        icon={<CreditCardOutlinedIcon />}
                                                                        checkedIcon={
                                                                            <CreditCardIcon className="icon-normal-size" />
                                                                        }
                                                                        checked={
                                                                            valueStaff.teamCompany3.contact_invoices
                                                                        }
                                                                        onChange={(event) =>
                                                                            handleChangeCheck(event, 4, "teamCompany3")
                                                                        }
                                                                        sx={{
                                                                            ml: { xs: 1, lg: 0, xl: 1 },
                                                                            mr: { xs: 1, lg: 0, xl: 1 },
                                                                        }}
                                                                    />
                                                                </LightTooltip>
                                                            </FormGroup>
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Card
                                                sx={{
                                                    borderRadius: "20px",
                                                    backgroundColor: "#F8F9F8",
                                                    boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                }}
                                            >
                                                <CardContent sx={{ p: "24px 24px 16px !important" }}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} xl={4}>
                                                            <AccountCircle className={classes.avatarImage} />
                                                            <Typography
                                                                variant="h2"
                                                                color="primary"
                                                                align="center"
                                                                sx={{ pt: 1 }}
                                                            >
                                                                {`${t("miscellaneous.contact")} 4`}
                                                            </Typography>
                                                            <LightTooltip title={t("recurrent.clear")}>
                                                                <IconButton
                                                                    aria-label="clear"
                                                                    color="primary"
                                                                    onClick={(event) =>
                                                                        clearTeamStaff(event, "teamCompany4")
                                                                    }
                                                                    sx={{ display: "block", m: "auto" }}
                                                                >
                                                                    <ClearAllIcon />
                                                                </IconButton>
                                                            </LightTooltip>
                                                        </Grid>
                                                        <Grid item xs={12} xl={8}>
                                                            <TextField
                                                                fullWidth
                                                                required={isRequired4}
                                                                label={t("miscellaneous.name")}
                                                                name="contact_name"
                                                                onChange={(event) =>
                                                                    handleChangeTeamStaff(event, "teamCompany4")
                                                                }
                                                                value={valueStaff.teamCompany4.contact_name}
                                                                variant="outlined"
                                                                error={
                                                                    isRequired4 &&
                                                                    isError4 &&
                                                                    valueStaff.teamCompany4.contact_name.trim() === ""
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                            <TextField
                                                                fullWidth
                                                                required={isRequired4}
                                                                label={t("miscellaneous.email")}
                                                                name="contact_email"
                                                                onChange={(event) =>
                                                                    handleChangeTeamStaff(event, "teamCompany4")
                                                                }
                                                                value={valueStaff.teamCompany4.contact_email}
                                                                variant="outlined"
                                                                error={
                                                                    isRequired4 &&
                                                                    isError4 &&
                                                                    valueStaff.teamCompany4.contact_email.trim() === ""
                                                                        ? true
                                                                        : isRequired4 &&
                                                                          isError4 &&
                                                                          !/.+@.+\..+/.test(
                                                                              valueStaff.teamCompany4.contact_email.trim()
                                                                          )
                                                                        ? true
                                                                        : false
                                                                }
                                                                sx={{ mt: 2 }}
                                                            />
                                                            <TextField
                                                                fullWidth
                                                                required={isRequired4}
                                                                label={t("miscellaneous.role")}
                                                                name="contact_role"
                                                                onChange={(event) =>
                                                                    handleChangeTeamStaff(event, "teamCompany4")
                                                                }
                                                                value={valueStaff.teamCompany4.contact_role}
                                                                variant="outlined"
                                                                error={
                                                                    isRequired4 &&
                                                                    isError4 &&
                                                                    valueStaff.teamCompany4.contact_role.trim() === ""
                                                                        ? true
                                                                        : false
                                                                }
                                                                sx={{ mt: 2 }}
                                                            />
                                                            <FormGroup sx={{ mt: 2, display: "block" }}>
                                                                <FormLabel id="permissions-checkboxes-group-label">
                                                                    <Typography
                                                                        variant="h4"
                                                                        color="primary"
                                                                        sx={{ pb: 1 }}
                                                                    >
                                                                        {t("miscellaneous.permissions")}:
                                                                    </Typography>
                                                                </FormLabel>
                                                                <LightTooltip
                                                                    title={t("services.toolRequirements")}
                                                                    aria-label="req"
                                                                >
                                                                    <Checkbox
                                                                        icon={<AssignmentTurnedInOutlinedIcon />}
                                                                        checkedIcon={<AssignmentTurnedInIcon />}
                                                                        checked={
                                                                            valueStaff.teamCompany4.contact_requirements
                                                                        }
                                                                        onChange={(event) =>
                                                                            handleChangeCheck(event, 3, "teamCompany4")
                                                                        }
                                                                        sx={{
                                                                            ml: { xs: 0, lg: 0, xl: 0 },
                                                                            mr: { xs: 1, lg: 0, xl: 1 },
                                                                        }}
                                                                    />
                                                                </LightTooltip>
                                                                <LightTooltip
                                                                    title={t("services.toolAccountingClosures")}
                                                                    aria-label="cierres"
                                                                >
                                                                    <Checkbox
                                                                        icon={<LockOutlinedIcon />}
                                                                        checkedIcon={<LockIcon />}
                                                                        checked={
                                                                            valueStaff.teamCompany4.contact_closure
                                                                        }
                                                                        onChange={(event) =>
                                                                            handleChangeCheck(event, 0, "teamCompany4")
                                                                        }
                                                                        sx={{
                                                                            ml: { xs: 1, lg: 0, xl: 1 },
                                                                            mr: { xs: 1, lg: 0, xl: 1 },
                                                                        }}
                                                                    />
                                                                </LightTooltip>
                                                                <LightTooltip
                                                                    title={t("services.toolStatus")}
                                                                    aria-label="status"
                                                                >
                                                                    <Checkbox
                                                                        icon={<InsertChartOutlinedIcon />}
                                                                        checkedIcon={<InsertChartIcon />}
                                                                        checked={valueStaff.teamCompany4.contact_status}
                                                                        onChange={(event) =>
                                                                            handleChangeCheck(event, 1, "teamCompany4")
                                                                        }
                                                                        sx={{
                                                                            ml: { xs: 1, lg: 0, xl: 1 },
                                                                            mr: { xs: 1, lg: 0, xl: 1 },
                                                                        }}
                                                                    />
                                                                </LightTooltip>
                                                                <LightTooltip
                                                                    title={t("services.toolInvoicing")}
                                                                    aria-label="invoicing"
                                                                >
                                                                    <Checkbox
                                                                        icon={<CreditCardOutlinedIcon />}
                                                                        checkedIcon={
                                                                            <CreditCardIcon className="icon-normal-size" />
                                                                        }
                                                                        checked={
                                                                            valueStaff.teamCompany4.contact_invoices
                                                                        }
                                                                        onChange={(event) =>
                                                                            handleChangeCheck(event, 4, "teamCompany4")
                                                                        }
                                                                        sx={{
                                                                            ml: { xs: 1, lg: 0, xl: 1 },
                                                                            mr: { xs: 1, lg: 0, xl: 1 },
                                                                        }}
                                                                    />
                                                                </LightTooltip>
                                                            </FormGroup>
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                            <Card elevation={0}>
                                <CardHeader title={t("miscellaneous.gcaStaff")} sx={{ color: "#ff5968" }} />
                                <Divider />
                                <CardContent sx={{ pb: 5 }}>
                                    <Grid container spacing={2}>
                                        <Grid item lg={4} sm={6} xs={12}>
                                            <FormControl
                                                fullWidth
                                                required
                                                error={isError && valueStaff.manager === "" ? true : false}
                                            >
                                                <InputLabel id="manager-simple-select-label">
                                                    {t("miscellaneous.manager")}
                                                </InputLabel>
                                                <Select
                                                    name="manager"
                                                    labelId="manager-simple-select-label"
                                                    label={t("miscellaneous.manager")}
                                                    IconComponent={KeyboardArrowDownIcon}
                                                    value={valueStaff.manager}
                                                    onChange={handleChangeStaff}
                                                    MenuProps={{
                                                        PaperProps: {
                                                            sx: {
                                                                mt: 0.7,
                                                                borderRadius: 2,
                                                                boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                            },
                                                        },
                                                    }}
                                                >
                                                    {Staff.manager.map((option) => (
                                                        <MenuItem value={option.name_es}>
                                                            {moment.locale() === "en" ? option.name_en : option.name_es}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item lg={4} sm={6} xs={12}>
                                            <FormControl
                                                fullWidth
                                                required
                                                error={isError && valueStaff.supervisor === "" ? true : false}
                                            >
                                                <InputLabel id="supervisor-simple-select-label">
                                                    {t("miscellaneous.supervisor")}
                                                </InputLabel>
                                                <Select
                                                    name="supervisor"
                                                    labelId="supervisor-simple-select-label"
                                                    label={t("miscellaneous.supervisor")}
                                                    IconComponent={KeyboardArrowDownIcon}
                                                    value={valueStaff.supervisor}
                                                    onChange={handleChangeStaff}
                                                    MenuProps={{
                                                        PaperProps: {
                                                            sx: {
                                                                mt: 0.7,
                                                                borderRadius: 2,
                                                                boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                            },
                                                        },
                                                    }}
                                                >
                                                    {Staff.supervisor.map((option) => (
                                                        <MenuItem value={option.name_es}>
                                                            {moment.locale() === "en" ? option.name_en : option.name_es}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item lg={4} sm={6} xs={12}>
                                            <FormControl
                                                fullWidth
                                                required
                                                error={isError && valueStaff.advisor === "" ? true : false}
                                            >
                                                <InputLabel id="advisor-simple-select-label">
                                                    {t("miscellaneous.advisor")}
                                                </InputLabel>
                                                <Select
                                                    name="advisor"
                                                    labelId="advisor-simple-select-label"
                                                    label={t("miscellaneous.advisor")}
                                                    IconComponent={KeyboardArrowDownIcon}
                                                    value={valueStaff.advisor}
                                                    onChange={handleChangeStaff}
                                                    MenuProps={{
                                                        PaperProps: {
                                                            sx: {
                                                                mt: 0.7,
                                                                borderRadius: 2,
                                                                boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                            },
                                                        },
                                                    }}
                                                >
                                                    {Staff.advisor.map((option) => (
                                                        <MenuItem value={option.name_es}>
                                                            {moment.locale() === "en" ? option.name_en : option.name_es}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item lg={4} sm={6} xs={12}>
                                            <FormControl
                                                fullWidth
                                                required
                                                error={isError && valueStaff.analyst === "" ? true : false}
                                            >
                                                <InputLabel id="analyst-simple-select-label">
                                                    {t("miscellaneous.analyst")}
                                                </InputLabel>
                                                <Select
                                                    name="analyst"
                                                    labelId="analyst-simple-select-label"
                                                    label={t("miscellaneous.analyst")}
                                                    IconComponent={KeyboardArrowDownIcon}
                                                    value={valueStaff.analyst}
                                                    onChange={handleChangeStaff}
                                                    MenuProps={{
                                                        PaperProps: {
                                                            sx: {
                                                                mt: 0.7,
                                                                borderRadius: 2,
                                                                boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                            },
                                                        },
                                                    }}
                                                >
                                                    {Staff.analyst.map((option) => (
                                                        <MenuItem value={option.name_es}>
                                                            {moment.locale() === "en" ? option.name_en : option.name_es}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item lg={4} sm={6} xs={12}>
                                            <FormControl
                                                fullWidth
                                                required
                                                error={isError && valueStaff.backupAnalyst === "" ? true : false}
                                            >
                                                <InputLabel id="backup-analyst-simple-select-label">
                                                    {t("miscellaneous.backupAnalyst")}
                                                </InputLabel>
                                                <Select
                                                    name="backupAnalyst"
                                                    labelId="backup-analyst-simple-select-label"
                                                    label={t("miscellaneous.backupAnalyst")}
                                                    IconComponent={KeyboardArrowDownIcon}
                                                    value={valueStaff.backupAnalyst}
                                                    onChange={handleChangeStaff}
                                                    MenuProps={{
                                                        PaperProps: {
                                                            sx: {
                                                                mt: 0.7,
                                                                borderRadius: 2,
                                                                boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                            },
                                                        },
                                                    }}
                                                >
                                                    {Staff.backupAnalyst.map((option) => (
                                                        <MenuItem value={option.name_es}>
                                                            {moment.locale() === "en" ? option.name_en : option.name_es}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </>
                    )}
                </Box>
            </DialogContent>
            <DialogActions sx={{ p: "24px 32px !important" }}>
                <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
                    <ColorButton
                        onClick={handleBack}
                        sx={{
                            mr: 1,
                            "&:hover": {
                                backgroundColor: "white",
                                color: "#2f3190",
                                border: "1px solid #2f3190",
                            },
                        }}
                        color="secondary"
                        disableElevation
                        variant="outlined"
                    >
                        {activeStep === 0 ? t("miscellaneous.cancel") : t("miscellaneous.back")}
                    </ColorButton>
                    <Box sx={{ flex: "1 1 auto" }} />
                    <ColorButton
                        onClick={handleNext}
                        sx={{
                            "&:hover": {
                                backgroundColor: "#2f3190",
                                border: "1px solid #2f3190",
                            },
                        }}
                        color="secondary"
                        disableElevation
                        variant="contained"
                    >
                        {activeStep === steps.length - 1 ? t("miscellaneous.save") : t("miscellaneous.next")}
                    </ColorButton>
                </Box>
            </DialogActions>
            <AlertDialog
                open={dialogForm}
                onClose={closeDialog}
                message={dialogBody}
                title={t("miscellaneous.newSupplier")}
                button={dialogButtons}
                maxWidth="xs"
            />
            <Backdrop open={showBackdrop} />
        </CustomDialog>
    );
}
