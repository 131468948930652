/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';

/** MUI icons */
import {ReactComponent as ErrorIcon} from '../../../assets/images/error-icon.svg';

/** MUI imports */
import { styled } from '@mui/material/styles';
import {
    Box,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Typography,
    Chip,
    Paper,
    Alert
} from '@mui/material';
import GridUI from "@mui/material/Grid";
import { Grid, Table, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
// import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

/** Components imports */
import Backdrop from '../Backdrop';

const PREFIX = 'FileMatch';

const classes = {
    formRounded: `${PREFIX}-formRounded`,
    btnRoot: `${PREFIX}-btnRoot`,
    tableStriped: `${PREFIX}-tableStriped`,
    tableHeader: `${PREFIX}-tableHeader`,
    tableBody: `${PREFIX}-tableBody`,
    btnRoot2: `${PREFIX}-btnRoot2`,
    alertRoot: `${PREFIX}-alertRoot`,
    icon: `${PREFIX}-icon`,
};

const CustomBox = styled(Box)(({ theme }) => ({
    [`& .${classes.formRounded}`]: {
        backgroundColor: '#E9EAE8 !important',
        borderRadius: 40,
        verticalAlign: 'middle !important',
        "&:focus-within": {
            backgroundColor: "#E9EAE8",
            borderRadius: 40,
        },
        "& .MuiOutlinedInput-notchedOutline": {
            border: '2px solid #031851',
            borderRadius: 40,
        },
    },
    [`& .${classes.btnRoot}`]: {
        border: '1px solid #ff5968',
        borderRadius: 40,
        '&:hover': {
            backgroundColor: '#2f3190',
            border: '1px solid #2f3190',
        },
    },
    [`& .${classes.btnRoot2}`]: {
        border: '1px solid #ff5968',
        borderRadius: 40,
        '&:hover': {
            backgroundColor: 'white',
            color: '#2f3190',
            border: '1px solid #2f3190',
        },
    },
    [`& .${classes.tableStriped}`]: {
        '& tbody tr:nth-of-type(odd)': {
            backgroundColor: 'rgba(47,49,144,0.1)',
        },
    },
    [`& .${classes.tableHeader}`]: {
        border: 'none',
        backgroundColor: 'rgba(47,49,144,0.3)',
        fontSize: '15px',
        fontWeight: 600,
        color: '#031851',
        '&:first-of-type': {
            borderRadius: '20px 0 0 0',
        },
        '&:last-child': {
            borderRadius: '0 20px 0 0',
            paddingRight: '24px'
        }
    },

    [`& .${classes.tableBody}`]: {
        border: 'none',
        fontSize: '14px',
        fontWeight: 400,
        color: '#031851',
    },

    [`& .${classes.alertRoot}`]: {
        borderRadius: 40,
        padding: '6px 22px',
    },

    [`& .${classes.icon}`]: {
        height: 20,
        fill: 'red',
        paddingRight: 22,
    },
}));

// const LightTooltip = styled(({ className, ...props }) => (
//     <Tooltip {...props} classes={{ popper: className }} />
// ))({
//     [`& .${tooltipClasses.tooltip}`]: {
//         backgroundColor: 'rgba(19, 31, 62, 0.80)',
//         fontSize: 12,
//         maxWidth: 320,
//         borderRadius: 6,
//         fontWeight: 'normal'
//     },
// });

const tableHeaderCell = ({ ...props }) => (
    <TableHeaderRow.Cell
        {...props}
        className={classes.tableHeader}
    />
);

const TableComponent = ({ ...props }) => (
    <Table.Table
        {...props}
        className={classes.tableStriped}
    />
);

const TableCellComponent = ({ ...props }) => (
    <Table.Cell
        {...props}
        className={classes.tableBody}
    />
);

const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5),
}));

const FileMatch = (props) => {
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    const tableMessages = {
        noData: t("miscellaneous.noData")
    };

    const [showBackdrop, setBackDrop] = useState(false);
    const [rowsData, setRowsData] = useState([]);
    const [columnDefs, setColumnsDefs] = useState([]);
    const [isValid, setIsValid] = useState(false);
    const [xeroFields, setXeroFields] = useState(["", "", "", "", "", ""]);
    const [chipData, setChipData] = useState([
        { key: "contact", label: 'Contacto' },
        { key: "document", label: 'Documento' },
        { key: "date", label: 'Fecha' },
        { key: "field", label: 'Campo' },
    ]);

    const handleChangexeroField = (event, index) => {
        let items = [...xeroFields];

        items[index] = event.target.value;

        setXeroFields(items);

        chipData.forEach((data) => {
            if (event.target.value === data.key) {
                handleDelete(data);
            }
        })
    }

    const handleDelete = (chipToDelete) => {
        setChipData((chips) => chips.filter((chip) => chip.key !== chipToDelete.key));
    };

    const matchDoc = () => {
        if (chipData.length === 0) {
            props.handleNext();
        }
        else {
            setIsValid(true)
        }
    }

    /** Component events */
    useEffect(() => {
        setBackDrop(true);

        let recordsdata = [];
        const columnsTemplate = [
            { name: 'source_header', title: "Encabezado de la fuente" },
            { name: 'first_reg', title: "Primer registro" },
            { name: 'xero_field', title: "Campo en Xero" },
        ];

        setColumnsDefs(columnsTemplate);

        props.matchData.records[0].forEach((source, index) => {
            props.matchData.records[1].forEach((reg, secondIndex) => {
                if (index === secondIndex) {
                    recordsdata.push({
                        source_header: source,
                        first_reg: reg,
                        xero_field: <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                            <InputLabel id="demo-simple-select-standard-label">Seleccione</InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={xeroFields[secondIndex]}
                                onChange={(event) => handleChangexeroField(event, secondIndex)}
                                label="Seleccione"
                            >
                                <MenuItem value="contact">Contacto</MenuItem>
                                <MenuItem value="document">Documento</MenuItem>
                                <MenuItem value="date">Fecha</MenuItem>
                                <MenuItem value="field">Campo</MenuItem>
                                <MenuItem value="na">Por Asignar</MenuItem>
                            </Select>
                        </FormControl>,
                    });
                }
            });
        });

        setRowsData(recordsdata);

        setBackDrop(false);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.matchData.records, t, xeroFields]);

    return (
        <CustomBox component="main">
            <GridUI container justifyContent="center" spacing={2} sx={{ pt: 6 }}>
                {isValid &&
                    <GridUI item>
                        <Alert severity="error"
                            classes={{
                                root: classes.alertRoot
                            }}
                            iconMapping={{
                                error: <ErrorIcon className={classes.icon} />
                            }}>Para continuar debe seleccionar todos los campos obligatorios</Alert>
                    </GridUI>
                }
                <GridUI item xs={12}>
                    <Paper
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            listStyle: 'none',
                            boxShadow: 'none',
                            p: 0,
                            m: 0,
                            backgroundColor: "transparent"
                        }}
                        component="ul"
                    >
                        <ListItem>
                            <Typography sx={{ fontSize: 20, fontWeight: 700, pr: 2 }}>
                                Campos obligatorios por seleccionar:
                            </Typography>
                        </ListItem>
                        {chipData.map((data) =>
                            <ListItem key={data.key}>
                                <Chip
                                    label={data.label}
                                    sx={{ backgroundColor: isValid ? "rgb(253, 237, 237)" : "rgba(0, 0, 0, 0.08)" }}
                                />
                            </ListItem>
                        )}
                    </Paper>
                </GridUI>
                <GridUI item xs={12}>
                    <Grid
                        rows={rowsData}
                        columns={columnDefs}
                    >
                        <Table
                            tableComponent={TableComponent}
                            cellComponent={TableCellComponent}
                            messages={tableMessages}
                        />
                        <TableHeaderRow
                            cellComponent={tableHeaderCell}
                        />
                    </Grid>
                    <Box sx={{ textAlign: "right", pt: 2 }}>
                        <Button
                            sx={{
                                mr: 2,
                                borderRadius: 40
                            }}
                            disableElevation
                            onClick={props.handleReset}
                        >Cancelar</Button>
                        <Button
                            sx={{
                                mr: 2,
                            }}
                            color="secondary"
                            disableElevation
                            variant="outlined"
                            classes={{ root: classes.btnRoot2 }}
                            onClick={props.handleBack}
                        >Volver</Button>
                        <Button
                            color="secondary"
                            variant="contained"
                            disableElevation
                            classes={{ root: classes.btnRoot }}
                            onClick={matchDoc}
                        >Siguiente</Button>
                    </Box>
                </GridUI>
            </GridUI>
            <Backdrop
                open={showBackdrop}
            />
        </CustomBox>
    );
};

export default FileMatch;