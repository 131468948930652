import { useState } from "react";
import { useTranslation } from "react-i18next";

/** MUI imports */
import { Box, Button, CardHeader, Typography } from "@mui/material";

/** MUI icons */
import Visibility from "@mui/icons-material/Visibility";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import classes from "./CardHeaderGCA.module.css";

const CardHeaderGCA = (props) => {
    const { setValueStaff, valueStaff, isReq } = props;
    const [t] = useTranslation("global");

    const [buttonShowChanges, setButtonShowChanges] = useState(false);

    const seeChanges = () => {
        setButtonShowChanges(!buttonShowChanges);

        setValueStaff({
            ...valueStaff,
            supervisor: !buttonShowChanges ? valueStaff.supervisorReq : valueStaff.supervisorOG,
            manager: !buttonShowChanges ? valueStaff.managerReq : valueStaff.managerOG,
            advisor: !buttonShowChanges ? valueStaff.advisorReq : valueStaff.advisorOG,
            analyst: !buttonShowChanges ? valueStaff.analystReq : valueStaff.analystOG,
            backupAnalyst: !buttonShowChanges ? valueStaff.backupAnalystReq : valueStaff.backupAnalystOG,
        });
    };

    return (
        <CardHeader
            title={
                <Box className={classes.container}>
                    {t("miscellaneous.gcaStaff")}{" "}
                    {isReq && (
                        <>
                            <Typography className={classes.changesText}>
                                {t("principal.changeswaiting")} <AccessTimeIcon className={classes.icon} />
                            </Typography>
                            <Button
                                variant={buttonShowChanges ? "contained" : "outlined"}
                                className={buttonShowChanges ? classes.buttonActive : classes.buttonShowChanges}
                                endIcon={buttonShowChanges ? <VisibilityOff /> : <Visibility />}
                                onClick={seeChanges}
                            >
                                {t("principal.seeChanges")}
                            </Button>
                        </>
                    )}
                </Box>
            }
            sx={{ color: "#ff5968" }}
        />
    );
};

export default CardHeaderGCA;
