/** React imports */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

/** MUI icons */
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

/** MUI imports */
import { styled } from "@mui/material/styles";
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormHelperText,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Paper,
    Select,
    Step,
    StepButton,
    StepLabel,
    Stepper,
    TextField,
    Typography,
} from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
/** Component styles */
const CustomDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(4),
    },
    "& .MuiDialogTitle-root": {
        backgroundColor: "#031851",
        fontSize: "18px",
        color: "#fff",
    },
    "& .MuiDialog-paper": {
        borderRadius: 8,
        boxShadow: "10px 10px 15px rgba(3,24,81,0.15)",
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(2),
    },
}));
const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "rgba(19, 31, 62, 0.80)",
        fontSize: 12,
        maxWidth: 320,
        borderRadius: 6,
        fontWeight: "normal",
    },
});
let companies = [];
const latam = [
    "Argentina",
    "Bolivia",
    "Brasil",
    "Chile",
    "Colombia",
    "Costa Rica",
    "Cuba",
    "Ecuador",
    "El Salvador",
    "Guatemala",
    "Haití",
    "Honduras",
    "México",
    "Nicaragua",
    "Panamá",
    "Paraguay",
    "Perú",
    "República Dominicana",
    "Uruguay",
];
const notGlobal = [
    "Argentina",
    "Bolivia",
    "Brasil",
    "Chile",
    "Colombia",
    "Costa Rica",
    "Cuba",
    "Ecuador",
    "El Salvador",
    "Estados Unidos",
    "Guatemala",
    "Haití",
    "Honduras",
    "México",
    "Nicaragua",
    "Panamá",
    "Paraguay",
    "Perú",
    "República Dominicana",
    "Uruguay",
    "Venezuela",
];
const filter = createFilterOptions();

const ModProspectDetails = (props) => {
    // Properties
    const { editMode, loadInitData, onClose, open, set, setAutoAlertType, setBackDrop, setShow, setTexto, values } =
        props;

    /** Internationalization i18n */
    const [t] = useTranslation("global");

    /** Component states */
    const [activeStep, setActiveStep] = useState(0);
    const [selectedChip, setSelectedChip] = useState({});
    const [isError, setIsError] = useState(false);
    const [isError1, setIsError1] = useState(false);
    const [isErrorCompany, setIsErrorCompany] = useState(false);
    const [viewMessage, setViewMessage] = useState(false);
    const [invalidEmail, setInvalidEmail] = useState(false);
    const [invalidEmailMsg, setInvalidEmailMsg] = useState("");
    const [invalidEmailColor, setInvalidEmailColor] = useState("primary");
    const [invalidEmailLoading, setInvalidEmailLoading] = useState(true);
    const [valueEmail, setValueEmail] = useState("");
    /** Component variables */
    const steps = [t("proposals.prospect"), t("proposals.companies")];

    /** Component functions */
    useEffect(() => {
        (async () => {
            setValueEmail(values.email);
            setInvalidEmail(false);
            setInvalidEmailColor("primary");
            setInvalidEmailLoading(true);
            await fetch(`/getAllCompaniesData`, {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
                .then((res) => res.json())
                .then((data) => {
                    companies = data;
                });
        })();

        if (editMode && values.relatedCompanies.length) {
            setSelectedChip(values.relatedCompanies[0]);
            set({
                ...values,
                relatedCompanies: values.relatedCompanies,
                market: values.relatedCompanies[0].country_name,
                activity: values.relatedCompanies[0].social_activity,
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const onUpdateProspect = async () => {
        setBackDrop(true);

        if (values.name && values.lastName && values.email && values.type && !invalidEmail) {
            if (/.+@.+\..+/.test(values.email)) {
                if (values.relatedCompanies.length > 0) {
                    try {
                        values.relatedCompanies.forEach((element, index) => {
                            if (!(element.country_name && element.social_activity)) {
                                setIsErrorCompany(true);
                                setBackDrop(false);
                                setTexto(t("proposals.completeRelatedCompaniesData"));
                                setAutoAlertType("warning");
                                setShow(true);
                                throw new Error(t("proposals.completeRelatedCompaniesData"));
                            }
                        });
                    } catch (error) {
                        console.log(error);
                        return;
                    }

                    const params = {
                        prospect_id: values.id,
                        prospect_source: 1,
                        prospect_name: values.name,
                        prospect_last_name: values.lastName,
                        prospect_email: values.email,
                        prospect_phone: values.phoneNumber,
                        client_type: values.type,
                        ambassador_name: values.ambassador,
                        companies: values.relatedCompanies,
                    };

                    await fetch(`/updateProspect`, {
                        method: "POST",
                        headers: {
                            "Content-type": "application/json; charset=UTF-8",
                            "Access-Control-Allow-Origin": "*",
                        },
                        body: JSON.stringify(params),
                    })
                        .then((res) => {
                            if (res.ok) {
                                handleReset();
                                onClose();
                                loadInitData();
                                setTexto(t("proposals.prospectUpdateSuccessfully"));
                                setAutoAlertType("success");
                                setShow(true);
                            } else {
                                setTexto(t("proposals.prospectUpdateError"));
                                setAutoAlertType("error");
                                setShow(true);
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                } else {
                    setIsError1(true);
                    setIsError(false);
                    setTexto(t("miscellaneous.requiredData"));
                    setAutoAlertType("warning");
                    setShow(true);
                }
            } else {
                setIsError(true);
                setTexto(t("login.invalidEmailFormat"));
                setAutoAlertType("warning");
                setShow(true);
            }
        } else {
            setIsError(true);
            setTexto(t("miscellaneous.requiredData"));
            setAutoAlertType("warning");
            setShow(true);
        }

        setBackDrop(false);
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleReset = () => {
        set({
            id: "",
            name: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            type: "",
            ambassador: "",
            creationDate: "",
            relatedCompanies: [],
            market: "",
            activity: "",
        });
        setSelectedChip({});
        setActiveStep(0);
        setIsError(false);
        setIsError1(false);
        setIsErrorCompany(false);
        setViewMessage(false);
    };

    const handleStep = (step) => {
        setActiveStep(step);
    };

    const handleBack = () => {
        if (activeStep === 0) {
            handleReset();
            onClose();
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }
    };

    const handleChangeProspect = (event) => {
        event.target.name === "email" && setInvalidEmailLoading(true);
        if (event.target.name === "activity" || event.target.name === "market") {
            let findCompany = values.relatedCompanies.find((company) => company.company_legal_name === selectedChip?.company_legal_name);

            let filterCompanies = values.relatedCompanies.filter((org) => org.company_legal_name !== selectedChip?.company_legal_name);

            let key = event.target.name === "activity" ? "social_activity" : "country_name";
            findCompany[key] = event.target.value;

            set({
                ...values,
                [event.target.name]: event.target.value,
                relatedCompanies: [...filterCompanies, findCompany],
            });
        } else {
            set({
                ...values,
                [event.target.name]: event.target.value,
            });
        };
    };

    const handleChangePhone = (event) => {
        const regex = /^\+[0-9\s.()-]{0,20}$/;
        if (event.target.value === "" || regex.test(event.target.value)) {
            set({
                ...values,
                [event.target.name]: event.target.value,
            });
        }
    };

    const handleChangeClientType = (event) => {
        set({
            ...values,
            [event.target.name]: event.target.value,
            market: "",
            activity: "",
            relatedCompanies: [],
        });
        setSelectedChip({});
        setIsError1(false);
        setIsErrorCompany(false);
        setViewMessage(true);
    };

    const handleChangeCompanies = (event, items, reason) => {
        if (reason === "removeOption") {
            return;
        } else if (reason === "clear") {
            setSelectedChip({});
            setIsErrorCompany(false);
            set({
                ...values,
                relatedCompanies: [],
                market: "",
                activity: "",
            });
            return;
        } else {
            if (items && items[items.length - 1].inputValue) {
                // Create a new value from the user input
                items.forEach((element, index) => {
                    if (element.inputValue === items[items.length - 1].inputValue) {
                        if (
                            values.relatedCompanies.some(
                                (elem) =>
                                    elem.company_legal_name.toLowerCase().trim() ===
                                    element.inputValue.toLowerCase().trim()
                            )
                        ) {
                            setTexto(t("proposals.companyAlreadyExists"));
                            setAutoAlertType("error");
                            setShow(true);
                            return;
                        } else {
                            items[index] = {
                                company_legal_name: element.inputValue,
                                country_name: "",
                                social_activity: "",
                                company_prospect: true,
                            };

                            set({
                                ...values,
                                relatedCompanies: items,
                                market: "",
                                activity: "",
                            });
                            setSelectedChip(items[items.length - 1]);
                        }
                    }
                });
            } else {
                set({
                    ...values,
                    relatedCompanies: items,
                    market: items[items.length - 1].company_prospect
                        ? items[items.length - 1].country_name
                        : latam.includes(items[items.length - 1].country_name)
                            ? "LATAM"
                            : !notGlobal.includes(items[items.length - 1].country_name)
                                ? "Global"
                                : items[items.length - 1].country_name,
                    activity: items[items.length - 1].social_activity,
                });
                setSelectedChip(items[items.length - 1]);
            }
        }
    };

    const onSelectedChip = (item) => {
        setSelectedChip(item);
        set({
            ...values,
            market: item.company_prospect
                ? item.country_name
                : latam.includes(item.country_name)
                    ? "LATAM"
                    : !notGlobal.includes(item.country_name)
                        ? "Global"
                        : item.country_name,
            activity: item.social_activity,
        });
    };

    const onSaveCompanyDetails = () => {
        if (values.market && values.activity) {
            values.relatedCompanies.forEach((element, index) => {
                if (element.company_legal_name === selectedChip.company_legal_name)
                    values.relatedCompanies[index] = {
                        _id: element._id ?? undefined,
                        company_legal_name: element.company_legal_name,
                        country_name: values.market,
                        social_activity: values.activity,
                        company_prospect: true,
                    };
            });

            setIsErrorCompany(false);
        } else {
            setIsErrorCompany(true);
            setTexto(t("miscellaneous.requiredData"));
            setAutoAlertType("warning");
            setShow(true);
        }
    };

    const onDeleteChip = (item) => {
        if (item.company_legal_name === selectedChip.company_legal_name) {
            setIsErrorCompany(false);
            setSelectedChip({});
            set({
                ...values,
                relatedCompanies: values.relatedCompanies.filter((entry) => entry !== item),
                market: "",
                activity: "",
            });
        } else {
            set({
                ...values,
                relatedCompanies: values.relatedCompanies.filter((entry) => entry !== item),
            });
        }
    };
    const verifyEmail = async (e) => {
        setInvalidEmailLoading(true);
        if (values.email.trim() !== "") {
            if (/.+@.+\..+/.test(values.email)) {
                if (values.email.toLowerCase() !== valueEmail.toLowerCase()) {
                    const params = {
                        email: values.email,
                    };

                    await fetch(`/verifyEmailProspect`, {
                        method: "POST",
                        headers: {
                            "Content-type": "application/json; charset=UTF-8",
                            "Access-Control-Allow-Origin": "*",
                        },
                        body: JSON.stringify(params),
                    })
                        .then((res) => res.json())
                        .then((data) => {
                            setInvalidEmailLoading(false);
                            if (data) {
                                //setIsError(true)
                                setInvalidEmail(true);
                                setInvalidEmailMsg(t("proposals.invalidEmailAdd"));
                                // setTexto(t("proposals.invalidEmailAdd"));
                                // setAutoAlertType("error");
                                // setShow(true);
                                setInvalidEmailColor("error");
                            } else {
                                //setIsError(false)
                                setInvalidEmail(false);
                                setInvalidEmailColor("green");
                                setInvalidEmailMsg(t("proposals.validEmailAdd"));
                            }
                            setInvalidEmailLoading(false);
                        })
                        .catch((error) => {
                            setInvalidEmail(true);
                            setInvalidEmailColor("error");
                            setInvalidEmailLoading(false);
                        });
                } else {
                    setInvalidEmail(false);
                    setInvalidEmailColor("green");
                    setInvalidEmailMsg(t("proposals.validEmailAdd"));
                    setInvalidEmailLoading(false);
                }
            } else {
                setTexto(t("login.invalidEmailFormat"));
                setAutoAlertType("error");
                setShow(true);

                setInvalidEmail(true);
                setInvalidEmailMsg(t("login.invalidEmailFormat"));
                setInvalidEmailColor("error");
                setInvalidEmailLoading(false);
            }
        } else {
            setTexto(t("login.invalidEmailFormat"));
            setAutoAlertType("error");
            setShow(true);

            setInvalidEmail(true);
            setInvalidEmailMsg(t("login.invalidEmailFormat"));
            setInvalidEmailColor("error");
            setInvalidEmailLoading(false);
        }
    };

    return (
        <CustomDialog
            open={open}
            onClose={() => {
                handleReset();
                onClose();
            }}
            aria-labelledby="prospect-details-dialog-title"
            aria-describedby="prospect-details-dialog-description"
            fullWidth={true}
            maxWidth="md"
        >
            <DialogTitle id="prospect-details-dialog-title">{t("proposals.prospectDetail")}</DialogTitle>
            <DialogContent dividers>
                <Box
                    sx={{
                        display: "block",
                        ml: "auto",
                        mr: "auto",
                        p: 2,
                        width: "70%",
                    }}
                >
                    <Stepper
                        nonLinear
                        activeStep={activeStep}
                        orientation={window.innerWidth < 600 ? "vertical" : "horizontal"}
                    >
                        {steps.map((label, index) => {
                            const labelProps = {};
                            if (
                                (isError && index === 0) ||
                                (invalidEmail && index === 0) ||
                                (isError1 && index === 1)
                            ) {
                                labelProps.optional = (
                                    <Typography variant="caption" color="error">
                                        {t("miscellaneous.required")}
                                    </Typography>
                                );

                                labelProps.error = true;
                            }
                            return (
                                <Step key={label}>
                                    <StepButton color="inherit" onClick={() => handleStep(index)}>
                                        <StepLabel {...labelProps}>{label}</StepLabel>
                                    </StepButton>
                                </Step>
                            );
                        })}
                    </Stepper>
                </Box>
                {activeStep === 0 && (
                    <Card elevation={0}>
                        <CardHeader title={t("miscellaneous.generalData")} sx={{ color: "#ff5968" }} />
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        name="name"
                                        required
                                        disabled={!editMode}
                                        label={t("proposals.name")}
                                        value={values.name}
                                        onChange={handleChangeProspect}
                                        variant="outlined"
                                        error={isError && values.name === "" ? true : false}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        name="lastName"
                                        required
                                        disabled={!editMode}
                                        label={t("proposals.lastName")}
                                        value={values.lastName}
                                        onChange={handleChangeProspect}
                                        variant="outlined"
                                        error={isError && values.lastName === "" ? true : false}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        sx={{
                                            fieldset: {
                                                borderColor: invalidEmailColor,
                                            },
                                            label: {
                                                color: invalidEmailColor,
                                            },
                                        }}
                                        name="email"
                                        required
                                        disabled={!editMode}
                                        label={t("proposals.email")}
                                        value={values.email}
                                        onChange={handleChangeProspect}
                                        onBlur={verifyEmail}
                                        variant="outlined"
                                        error={invalidEmail}
                                        InputProps={{
                                            endAdornment:
                                                (invalidEmail && !invalidEmailLoading && (
                                                    <InputAdornment position="end">
                                                        <LightTooltip
                                                            title={invalidEmailMsg}
                                                            aria-label="validatedMonth"
                                                        >
                                                            <ErrorIcon color={invalidEmailColor} />
                                                        </LightTooltip>
                                                    </InputAdornment>
                                                )) ||
                                                (values.email && !invalidEmail && !invalidEmailLoading && (
                                                    <InputAdornment position="end">
                                                        <LightTooltip
                                                            title={invalidEmailMsg}
                                                            aria-label="validatedMonth"
                                                        >
                                                            <CheckCircleIcon style={{ color: invalidEmailColor }} />
                                                        </LightTooltip>
                                                    </InputAdornment>
                                                )),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth disabled={!editMode}>
                                        <InputLabel htmlFor="prospect-phone-number-mask-input">
                                            {t("miscellaneous.phoneNumber")}
                                        </InputLabel>
                                        <OutlinedInput
                                            id="prospect-phone-number-mask-input"
                                            name="phoneNumber"
                                            label={t("miscellaneous.phoneNumber")}
                                            value={values.phoneNumber}
                                            onChange={handleChangePhone}
                                            placeholder="e.g. +99 (999) 999-9999"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl
                                        fullWidth
                                        required
                                        disabled={!editMode}
                                        error={isError && values.type === "" ? true : false}
                                    >
                                        <InputLabel id="type-simple-select-label">{t("proposals.type")}</InputLabel>
                                        <Select
                                            name="type"
                                            labelId="type-simple-select-label"
                                            IconComponent={KeyboardArrowDownIcon}
                                            value={values.type}
                                            label={t("proposals.type")}
                                            onChange={handleChangeClientType}
                                            MenuProps={{
                                                PaperProps: {
                                                    sx: {
                                                        mt: 0.7,
                                                        borderRadius: 2,
                                                        boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                    },
                                                },
                                            }}
                                        >
                                            <MenuItem value={1}>{t("miscellaneous.firm")}</MenuItem>
                                            <MenuItem value={2}>SMB</MenuItem>
                                        </Select>
                                        {viewMessage && (
                                            <FormHelperText
                                                sx={{
                                                    color: "#ff5968",
                                                    pt: 0.5,
                                                }}
                                            >
                                                {t("proposals.unlinkCompanies")}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        name="ambassador"
                                        required
                                        disabled
                                        label={t("proposals.ambassador")}
                                        value={values.ambassador}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1">
                                        <span style={{ fontWeight: 600 }}>{t("proposals.createDate")}: </span>
                                        {values.creationDate} GMT
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                )}
                {activeStep === 1 && (
                    <Grid container spacing={2} sx={{ p: "24px 16px 24px" }}>
                        <Grid item xs={12}>
                            <Autocomplete
                                multiple
                                id="tags-company"
                                options={companies
                                    .filter((company) => company.client_type === values.type)
                                    .filter(
                                        ({ _id: id1 }) => !values.relatedCompanies.some(({ _id: id2 }) => id2 === id1)
                                    )}
                                disabled={!editMode}
                                value={values.relatedCompanies}
                                onChange={(event, items, reason) => handleChangeCompanies(event, items, reason)}
                                filterOptions={(options, params) => {
                                    const filtered = filter(options, params);

                                    const { inputValue } = params;
                                    // Suggest the creation of a new value
                                    const isExisting = options.some((option) => inputValue === option.title);
                                    if (inputValue !== "" && !isExisting) {
                                        filtered.push({
                                            inputValue,
                                            company_legal_name: `${t("proposals.plusAdd")} "${inputValue}"`,
                                        });
                                    }

                                    return filtered;
                                }}
                                getOptionLabel={(option) => {
                                    // Value selected with enter, right from the input
                                    if (typeof option === "string") {
                                        return option;
                                    }
                                    // Add "xxx" option created dynamically
                                    if (option.inputValue) {
                                        return option.inputValue;
                                    }
                                    // Regular option
                                    return option.company_legal_name;
                                }}
                                renderTags={(relatedCompanies) =>
                                    relatedCompanies.map((value, index) => (
                                        <Chip
                                            disabled={!editMode}
                                            variant={
                                                selectedChip &&
                                                    selectedChip.company_legal_name === value.company_legal_name
                                                    ? "filled"
                                                    : "outlined"
                                            }
                                            label={value.inputValue ? value.inputValue : value.company_legal_name}
                                            color={
                                                isErrorCompany && !value.country_name && !value.social_activity
                                                    ? "error"
                                                    : "default"
                                            }
                                            sx={{
                                                "&:hover": {
                                                    backgroundColor:
                                                        selectedChip &&
                                                            selectedChip.company_legal_name === value.company_legal_name
                                                            ? "rgba(47, 49, 144, 0.8)"
                                                            : "transparent",
                                                },
                                                backgroundColor:
                                                    selectedChip &&
                                                        selectedChip.company_legal_name === value.company_legal_name
                                                        ? "#2f3190"
                                                        : "transparent",
                                                color:
                                                    selectedChip &&
                                                        selectedChip.company_legal_name === value.company_legal_name
                                                        ? "white"
                                                        : "#031851",
                                                m: "3px",
                                            }}
                                            deleteIcon={
                                                <CancelRoundedIcon
                                                    sx={{
                                                        color:
                                                            selectedChip &&
                                                                selectedChip.company_legal_name === value.company_legal_name
                                                                ? "white !important"
                                                                : "transparent",
                                                    }}
                                                />
                                            }
                                            onClick={() => onSelectedChip(value)}
                                            onDelete={() => onDeleteChip(value)}
                                        />
                                    ))
                                }
                                renderOption={(props, option) => <li {...props}>{option.company_legal_name}</li>}
                                renderInput={(params) => (
                                    <TextField
                                        required
                                        {...params}
                                        error={isError1 && values.relatedCompanies.length === 0 ? true : false}
                                        label={t("proposals.relatedCompanies")}
                                    />
                                )}
                                popupIcon={<KeyboardArrowDownIcon color="primary" />}
                                PaperComponent={({ children }) => (
                                    <Paper
                                        sx={{
                                            mt: 0.7,
                                            borderRadius: 2,
                                            boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                        }}
                                    >
                                        {children}
                                    </Paper>
                                )}
                            />
                        </Grid>
                        {editMode && (
                            <Grid item xs={12} md={6}>
                                <FormControl
                                    fullWidth
                                    required={selectedChip && selectedChip.company_prospect}
                                    disabled={selectedChip && !selectedChip.company_prospect}
                                    error={isErrorCompany && values.market === "" ? true : false}
                                >
                                    <InputLabel id="market-simple-select-label">{t("proposals.market")}</InputLabel>
                                    <Select
                                        name="market"
                                        labelId="market-simple-select-label"
                                        IconComponent={KeyboardArrowDownIcon}
                                        value={values.market}
                                        label={t("proposals.market")}
                                        onChange={handleChangeProspect}
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    mt: 0.7,
                                                    borderRadius: 2,
                                                    boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                },
                                            },
                                        }}
                                    >
                                        <MenuItem value="Global">
                                            <Typography
                                                variant="body1"
                                                component="span"
                                                align="center"
                                                color="textPrimary"
                                            >
                                                Global
                                            </Typography>
                                        </MenuItem>
                                        <MenuItem value="LATAM">
                                            <Typography
                                                variant="body1"
                                                component="span"
                                                align="center"
                                                color="textPrimary"
                                            >
                                                LATAM
                                            </Typography>
                                        </MenuItem>
                                        <MenuItem value="Estados Unidos">
                                            <Typography
                                                variant="body1"
                                                component="span"
                                                align="center"
                                                color="textPrimary"
                                            >
                                                USA
                                            </Typography>
                                        </MenuItem>
                                        <MenuItem value="Venezuela">
                                            <Typography
                                                variant="body1"
                                                component="span"
                                                align="center"
                                                color="textPrimary"
                                            >
                                                Venezuela
                                            </Typography>
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        )}
                        {editMode && (
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    disabled={selectedChip && !selectedChip.company_prospect}
                                    error={isErrorCompany && values.activity === "" ? true : false}
                                    label={t("proposals.economicActivity")}
                                    name="activity"
                                    onChange={handleChangeProspect}
                                    onBlur={onSaveCompanyDetails}
                                    required={selectedChip && selectedChip.company_prospect}
                                    value={values.activity}
                                    variant="outlined"
                                />
                            </Grid>
                        )}
                    </Grid>
                )}
            </DialogContent>
            <DialogActions>
                <Grid
                    container
                    justifyContent={editMode ? "space-between" : "flex-end"}
                    alignItems="center"
                    spacing={2}
                >
                    <Grid item>
                        <Button
                            variant="outlined"
                            color="secondary"
                            disableElevation
                            onClick={handleBack}
                            sx={{
                                pl: 3,
                                pr: 3,
                                borderRadius: 40,
                                "&:hover": {
                                    border: "1px solid #2f3190",
                                    color: "#2f3190",
                                },
                            }}
                        >
                            {activeStep === 0 ? t("miscellaneous.cancel") : t("miscellaneous.back")}
                        </Button>
                    </Grid>
                    {editMode && (
                        <Grid item>
                            <Button
                                disabled={invalidEmail}
                                disableElevation
                                variant="contained"
                                color="secondary"
                                sx={{
                                    pl: 3,
                                    pr: 3,
                                    borderRadius: 40,
                                    "&:hover": {
                                        backgroundColor: "#2f3190",
                                        color: "#fff",
                                    },
                                }}
                                onClick={activeStep === steps.length - 1 ? onUpdateProspect : handleNext}
                            >
                                {activeStep === steps.length - 1 ? t("miscellaneous.save") : t("miscellaneous.next")}
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </DialogActions>
        </CustomDialog>
    );
};

export default ModProspectDetails;
