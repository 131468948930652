/** React imports */
import React from 'react';

/** MUI imports */
import { styled } from '@mui/material/styles';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions
} from '@mui/material';

const CustomDialog = styled(Dialog)(({ theme }) => ({
    zIndex: 9999,
    '& .MuiDialogContent-root': {
        [theme.breakpoints.down("sm")]: {
            padding: 30,
        },
        padding: "30px 80px 30px 80px",
    },
    '& .MuiDialogTitle-root': {
        fontSize: "18px",
        fontWeight: 700,
        color: "#FF2B5D",
        padding: "50px 30px 15px 30px"
    },
    '& .MuiDialog-paper': {
        borderRadius: 8
    },
    '& .MuiDialogActions-root': {
        padding: "10px 65px 50px 20px",
    },
}));

export default function DeclineDialog(props) {
    return (
        <CustomDialog
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={props.maxWidth}
            fullWidth={true}
        >
            <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
            <DialogContent>
                {props.message}
            </DialogContent>
            <DialogActions>
                {props.button}
            </DialogActions>
        </CustomDialog>
    );
}