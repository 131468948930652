/** React imports */
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment from "moment";

/** MUI icons */
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';

/** MUI imports */
import { Grid, Table, TableHeaderRow, PagingPanel } from '@devexpress/dx-react-grid-material-ui';
import { IntegratedFiltering, PagingState, IntegratedPaging } from '@devexpress/dx-react-grid';
import { styled } from '@mui/material/styles';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Skeleton,
} from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

/** Component imports */
import SnackBar from './SnackBar';

const PREFIX = 'DialogConversions';

const classes = {
    tableStriped: `${PREFIX}-tableStriped`,
    tableHeader: `${PREFIX}-tableHeader`,
    tableBody: `${PREFIX}-tableBody`,
    pager: `${PREFIX}-pager`,
};

const CustomDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(3.5),
    },
    '& .MuiDialogTitle-root': {
        backgroundColor: "#031851",
        fontSize: "18px",
        color: "#fff"
    },
    '& .MuiDialog-paper': {
        borderRadius: 8,
        boxShadow: '10px 10px 15px rgba(3,24,81,0.15)',
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(2),
    },

    [`& .${classes.tableStriped}`]: {
        '& tbody tr:nth-of-type(odd)': {
            backgroundColor: 'rgba(47,49,144,0.1)',
        },
    },
    [`& .${classes.tableHeader}`]: {
        border: 'none',
        backgroundColor: 'rgba(47,49,144,0.3)',
        fontSize: '15px',
        fontWeight: 600,
        color: '#031851',
        '&:first-of-type': {
            borderRadius: '20px 0 0 0',
        },
        '&:last-child': {
            borderRadius: '0 20px 0 0',
            paddingRight: '24px'
        }
    },

    [`& .${classes.tableBody}`]: {
        border: 'none',
        fontSize: '14px',
        fontWeight: 400,
        color: '#031851',
    },

    [`& .${classes.pager}`]: {
        "& .Pagination-button": {
            paddingTop: 0,
            paddingBottom: 0,
            color: '#031851',
            fontWeight: 600
        },
        "& .Pagination-activeButton": {
            backgroundColor: 'rgba(47,49,144,0.3)',
        }
    },
}));

const CustomDialogTitle = ((props) => {
    const { children, onClose, ...other } = props;
    return (
        <DialogTitle {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 16,
                        top: 10,
                        color: '#fff',
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
});

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: 'rgba(19, 31, 62, 0.80)',
        fontSize: 12,
        maxWidth: 320,
        borderRadius: 6,
        fontWeight: 'normal'
    },
});

const tableHeaderCell = ({ ...props }) => (
    <TableHeaderRow.Cell
        {...props}
        className={classes.tableHeader}
    />
);

const TableComponent = ({ ...props }) => (
    <Table.Table
        {...props}
        className={classes.tableStriped}
    />
);

const TableCellComponent = ({ ...props }) => (
    <Table.Cell
        {...props}
        className={classes.tableBody}
    />
);

const PagingPanelContainer = ({ ...props }) => (
    <PagingPanel.Container
        {...props}
        className={classes.pager}
    />
);

const tableColumnExtensions = [
    { columnName: 'date', align: 'center' },
    { columnName: 'period', align: 'center' },
    { columnName: 'uploadedFile', width: '25%', align: 'center' },
    { columnName: 'initialDate', align: 'center' },
    { columnName: 'endDate', align: 'center' },
    { columnName: 'balance', width: '10%', align: 'right' },
    { columnName: 'download', align: 'center' },
];

const rowsSkeleton = []

for (let i = 0; i < 5; i++) {
    rowsSkeleton.push({
        date: <Skeleton variant="text" animation="wave" />,
        period: <Skeleton variant="text" animation="wave" />,
        uploadedFile: <Skeleton variant="text" animation="wave" />,
        initialDate: <Skeleton variant="text" animation="wave" />,
        endDate: <Skeleton variant="text" animation="wave" />,
        balance: <Skeleton variant="text" animation="wave" />,
        download: <Skeleton variant="circular" animation="wave" width={30} height={30} sx={{ ml: 'auto', mr: 'auto' }} />,
    });
}

const DialogConversions = (props) => {
    /** Internationalization i18n */
    const [t] = useTranslation("global");


    const tableMessages = {
        noData: t("miscellaneous.noData")
    };

    const pagingMessages = {
        info: ({ from, to, count }) => `${from}${from < to ? `-${to}` : ''} ${t("miscellaneous.pagingOf")} ${count}`,
    };

    /** React redux */
    const todos = useSelector(state => state.todos);

    /** Component states */
    const [show, setShow] = useState(false);
    const [text, setText] = useState("");
    const [alertType, setAlertType] = useState("");
    const [rowsData, setRowsData] = useState([]);
    const [columnDefs, setColumnsDefs] = useState([]);

    /** Component functions */
    const setAccountConversions = async () => {
        let historyRows = await props.conversionsData.map((historyData) => {
            return {
                date: moment(historyData.date, "DD/MM/YYYY").format(todos.dateFormat),
                period: historyData.period === undefined ? "-" : (moment(historyData.period, "YYYY-MM-DD").locale(t("language.locale")).format('MMM-YYYY').charAt(0).toUpperCase()
                    + moment(historyData.period, "YYYY-MM-DD").locale(t("language.locale")).format('MMM-YYYY').slice(1)).replace('.', ''),
                uploadedFile: historyData.uploadedFile,
                format: historyData.default_format,
                initialDate: historyData.initialDate === "" ? "-" : moment(historyData.initialDate).format(todos.dateFormat),
                endDate: historyData.endDate === "" ? "-" : moment(historyData.endDate).format(todos.dateFormat),
                balance: parseFloat(historyData.balance).toLocaleString(todos.amountFormat, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                download: (historyData.lastTransactionDate !== "Sin transacciones") ?
                    <div id={`down_${historyData.id_conversion}`}>
                        <LightTooltip title={t("dialogConversions.download")} aria-label="donwloadDoc">
                            <IconButton aria-label="download" size="small" onClick={() => onDownloadFile(historyData.id_conversion, historyData.default_format)}>
                                <DownloadIcon
                                    color="primary"
                                />
                            </IconButton>
                        </LightTooltip>
                    </div> : (historyData.status === 2)
                        ? t("bankingDashboard.statusReview")
                        : (historyData.status === 4)
                            ? t("miscellaneous.withoutTransactions")
                            : (historyData.status === 5)
                                ? t("miscellaneous.removed")
                                : t("bankingDashboard.statusInvalid")
            }
        });
        setRowsData(historyRows);
    }

    const onDownloadFile = (id_conversion, default_format) => {
        let resp = `/getBankStatementsByConversion?conversionId=${id_conversion}&defaultFormat=${default_format}`;

        if (resp !== false) {
            let element = document.createElement('a');
            element.setAttribute('href', resp);
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);

            setShow(true);
            setText(t('manageAccount.dnldSuccess'));
            setAlertType("success");
        }
        else {
            setShow(true);
            setText(t('manageAccount.dnldError'));
            setAlertType("error");
        }
    }

    /** Component events */
    useEffect(() => {
        (async () => {
            const columnsTemplate = [
                { name: 'date', title: t("bankingDashboard.conversionDate") },
                { name: 'period', title: t("bankingDashboard.month") },
                { name: 'uploadedFile', title: t("bankingDashboard.uploadedFile") },
                { name: 'initialDate', title: t("bankingDashboard.initialDate") },
                { name: 'endDate', title: t("bankingDashboard.endDate") },
                { name: 'balance', title: t("bankingDashboard.balance") },
                { name: 'download', title: t("dialogConversions.download") },
            ];
            setColumnsDefs(columnsTemplate);
            setRowsData(rowsSkeleton);

            await setAccountConversions();
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t, todos.dateFormat, todos.amountFormat, props.conversionsData]);

    return (
        <CustomDialog
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="history-dialog-title"
            aria-describedby="history-dialog-description"
            maxWidth="xl"
            fullWidth={true}
        >
            <CustomDialogTitle id="history-dialog-title" onClose={props.onClose}>{t('manageAccount.convHistoryTitle')}</CustomDialogTitle>
            <DialogContent dividers>
                <Grid
                    rows={rowsData}
                    columns={columnDefs}
                >
                    <IntegratedFiltering />
                    <PagingState
                        defaultCurrentPage={0}
                        pageSize={10}
                    />
                    <IntegratedPaging />
                    <Table
                        tableComponent={TableComponent}
                        columnExtensions={tableColumnExtensions}
                        cellComponent={TableCellComponent}
                        messages={tableMessages}
                    />
                    <TableHeaderRow
                        cellComponent={tableHeaderCell}
                    />
                    <PagingPanel
                        containerComponent={PagingPanelContainer}
                        messages={pagingMessages}
                    />
                </Grid>
            </DialogContent>
            <SnackBar
                type={alertType}
                show={show}
                message={text}
                onClose={() => setShow(false)}
            />
        </CustomDialog>
    );
}

export default DialogConversions;