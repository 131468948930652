/** Imports ---------------------------------------------------------------- */

/** React imports */
import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import axios from "axios";

/** MUI icons */
import CommentIcon from "@mui/icons-material/Comment";
import CheckIcon from "@mui/icons-material/Check";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockIcon from "@mui/icons-material/Lock";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import WarningAmberIcon from "@mui/icons-material/Warning";
import CircleIcon from "@mui/icons-material/Circle";
import SettingsIcon from "@mui/icons-material/Settings";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

/** MUI imports */
import { Grid, Table, TableHeaderRow, PagingPanel, TableTreeColumn } from "@devexpress/dx-react-grid-material-ui";
import { PagingState, IntegratedPaging, TreeDataState, CustomTreeData } from "@devexpress/dx-react-grid";
import { styled } from "@mui/material/styles";
import GridUI from "@mui/material/Grid";
import {
    Badge,
    Box,
    Breadcrumbs,
    Button,
    Card,
    CardContent,
    Chip,
    FormControl,
    FormControlLabel,
    IconButton,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Skeleton,
    Stack,
    Switch,
    Typography,
    Checkbox,
    ListItemText,
    ToggleButtonGroup,
    ToggleButton,
    TextField,
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

/** Components imports */
import CommentDialog from "../CommentDialog";
import DialogAlerts from "../DialogAlerts";
import Backdrop from "../Backdrop";
import SnackBar from "../SnackBar";
import UploadAsync from "./UploadAsync";
import UploadAsyncCustom from "./UploadAsyncCustom";
import BankDialog from "./BankDialog";
import ConfigDial from "../Dialog";
import RecurrentConfig from "./RecurrentConfig";
import moment from "moment";
import OccasionalConfig from "./OccasionalConfig";

/** Global variables */
var params = new URL(document.location).searchParams;
var status = params.get("unassigned");

const PREFIX = "Requirements";

const classes = {
    paper: `${PREFIX}-paper`,
    cardHeaderRoot: `${PREFIX}-cardHeaderRoot`,
    cardContentRoot: `${PREFIX}-cardContentRoot`,
    tableHeader: `${PREFIX}-tableHeader`,
    tableBody: `${PREFIX}-tableBody`,
    tableCellTree: `${PREFIX}-tableCellTree`,
    pager: `${PREFIX}-pager`,
    formRounded: `${PREFIX}-formRounded`,
    spacing: `${PREFIX}-spacing`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.paper}`]: {
        border: "2px solid #ff5968",
        borderRadius: 40,
        boxShadow: "none",
    },

    [`& .${classes.cardHeaderRoot}`]: {
        padding: 30,
    },

    [`& .${classes.cardContentRoot}`]: {
        "&:last-child": {
            paddingBottom: "18px",
        },
        padding: 30,
    },

    [`& .${classes.tableHeader}`]: {
        border: "none",
        backgroundColor: "rgba(47,49,144,0.3)",
        fontSize: "15px",
        fontWeight: 600,
        color: "#031851",
        "&:first-of-type": {
            borderRadius: "20px 0 0 0",
        },
        "&:last-child": {
            borderRadius: "0 20px 0 0",
        },
    },

    [`& .${classes.tableBody}`]: {
        borderBottom: "2px solid #031851",
        fontSize: "14px",
        fontWeight: 400,
        color: "#031851",
    },

    [`& .${classes.tableCellTree}`]: {
        borderBottom: "2px solid #031851",
        fontSize: "14px",
        fontWeight: 400,
        color: "#031851",
        cursor: "pointer",
    },

    [`& .${classes.pager}`]: {
        "& .Pagination-button": {
            paddingTop: 0,
            paddingBottom: 0,
            color: "#031851",
            fontWeight: 600,
        },
        "& .Pagination-activeButton": {
            backgroundColor: "rgba(47,49,144,0.3)",
        },
    },

    [`& .${classes.formRounded}`]: {
        backgroundColor: "#FFFFFF !important",
        borderRadius: 40,
        verticalAlign: "middle !important",
        "&:focus-within": {
            backgroundColor: "#FFFFFF",
            borderRadius: 40,
        },
        "& .MuiOutlinedInput-notchedOutline": {
            border: "2px solid #031851",
            borderRadius: 40,
        },
    },

    [`& .${classes.spacing}`]: {
        padding: "20px 0 15px 0",
    },
}));

const TableRow = ({ row, ...restProps }) => (
    <Table.Row
        {...restProps}
        style={{
            backgroundColor: row.parentId === null ? "rgba(47,49,144,0.1)" : "none",
        }}
    />
);

const tableContentComponent = (props) => <TableHeaderRow.Content {...props} />;

const PagingPanelContainer = ({ ...props }) => <PagingPanel.Container {...props} className={classes.pager} />;

const tableHeaderCell = ({ ...props }) => <TableHeaderRow.Cell {...props} className={classes.tableHeader} />;

const TableCellComponent = ({ ...props }) => <Table.Cell {...props} className={classes.tableBody} />;

const getChildRows = (row, rootRows) => {
    const childRows = rootRows.filter((r) => r.parentId === (row ? row.id : null));
    return childRows.length ? childRows : null;
};

const ColorButton = styled(Button)(({ theme }) => ({
    border: "1px solid #ff5968",
    borderRadius: 40,
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
        backgroundColor: "#2f3190",
        color: "white",
    },
}));

const StyledBadgeChecks = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
        transform: "translate(0px, -2px)",
        width: "10px",
        height: "10px",
    },
}));

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "rgba(19, 31, 62, 0.80)",
        fontSize: 12,
        maxWidth: 320,
        borderRadius: 6,
        fontWeight: "normal",
    },
});

const allMonths = [
    { num: "01", key: "jan" },
    { num: "02", key: "feb" },
    { num: "03", key: "mar" },
    { num: "04", key: "apr" },
    { num: "05", key: "may" },
    { num: "06", key: "jun" },
    { num: "07", key: "jul" },
    { num: "08", key: "aug" },
    { num: "09", key: "sep" },
    { num: "10", key: "oct" },
    { num: "11", key: "nov" },
    { num: "12", key: "dec" },
];

let tableColumnExtensions = [
    { columnName: "settings", align: "center", width: "5%" },
    { columnName: "comments", align: "center", width: "5%" },
    { columnName: "status", align: "left", width: "10%" },
];

allMonths.forEach((item) => {
    tableColumnExtensions.push({ columnName: item.key, align: "center", width: "5%" });
});

const rowsSkeleton = [];

for (let i = 0; i < 15; i++) {
    rowsSkeleton.push({
        id: i,
        parentId: null,
        client: <Skeleton variant="text" animation="wave" />,
        settings: (
            <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />
        ),
        comments: (
            <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />
        ),
        jan: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        feb: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        mar: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        apr: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        may: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        jun: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        jul: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        aug: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        sep: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        oct: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        nov: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        dec: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
    });
}

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 48 * 4.5 + 8,
            boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
        },
        sx: {
            borderRadius: 2,
            mt: 0.7,
        },
    },
    variant: "menu",
    getContentAnchorEl: null,
};

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    width: "100%",
    "& .MuiToggleButtonGroup-grouped": {
        border: 0,
        borderRadius: 30,
        height: 35,
        width: "50%",
        backgroundColor: "rgba(47,49,144,0.1)",
        color: "#031851",
        "&:hover": {
            backgroundColor: "rgba(47, 49, 144, 0.05)",
        },
    },
    "& .Mui-selected": {
        backgroundColor: "#2f3190 !important",
        color: "white !important",
        "&:hover": {
            backgroundColor: "rgba(47, 49, 144, 0.8) !important",
        },
    },
}));

const defaultStateConfig = {
    idOrganisation: "",
    dependency: 3,
    manageReq: false,
    checkedNom: false,
    periodNom: "",
    recepDaysNom: "",
    initDateNom: null,
    endDateNom: null,
    checkedCont: false,
    periodCont: "",
    recepDaysCont: "",
    initDateCont: null,
    endDateCont: null,
    checkedSells: false,
    periodSells: "",
    recepDaysSells: "",
    initDateSells: null,
    endDateSells: null,
    checkedISLR: false,
    periodISLR: "",
    recepDaysISLR: "",
    initDateISLR: null,
    endDateISLR: null,
    checkedOther4: false,
    nameOther4: "",
    periodOther4: "",
    recepDaysOther4: "",
    initDateOther4: null,
    endDateOther4: null,
    additionalInfo: "",
};

const defaultConfigOccasionalRequirements = {
    requirementType: "", // requirement_type --> 6: Ocasional - Compra de activos fijos, 7: Ocasional - Préstamos, 8: Otros
    requirementName: "",
    initialDate: "",
};

// Requerimientos recurrentes
const rowsRecu = ["Nom", "Cont", "Sells", "ISLR", "Other4"];

// Dependencia
const selectDepen = [1, 2, 3];

const Requirements = (props) => {
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    const markets = [t("miscellaneous.all"), "Global", "LATAM", "USA", "Venezuela"];

    const tableMessages = {
        noData: t("miscellaneous.noData"),
    };
    const pagingMessages = {
        info: ({ from, to, count }) => `${from}${from < to ? `-${to}` : ""} ${t("miscellaneous.pagingOf")} ${count}`,
    };

    /** React redux */
    const todos = useSelector((state) => state.todos);

    const franqDataProps = props.franqData;
    const groupDataProps = props.groupData;

    /** Component states */
    const [open, setOpen] = useState(false);
    const [openCustom, setOpenCustom] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [dialogText, setDialogText] = useState("");
    const [dialogAction, setDialogAction] = useState("");
    const [dialogForm, setDialogForm] = useState(false);
    const [isError, setIsError] = useState(false);
    const [show, setShow] = useState(false);
    const [texto, setTexto] = useState("");
    const [alertType, setAlertType] = useState("");
    const [showBackdrop, setBackDrop] = useState(false);
    const [clientsData, setClients] = useState([]);
    const [columnDefs, setColumnsDefs] = useState([]);
    const [expandedRowIds, setExpandenRowIds] = useState([]);
    const [yearsToNoti, setyearsToNoti] = useState([]);
    const [selectedYear, setselectedYear] = useState(new Date().getFullYear());
    const [clientT, setTClient] = useState("");
    const [allData, setAllData] = useState([]);
    const [franqF, setfranqF] = useState(["all"].concat(franqDataProps.map((item) => item.id)));
    const [groupF, setgroupF] = useState(["all"].concat(groupDataProps.map((item) => item.id)));
    const [clientsF, setClientsF] = useState(["all"].concat(allData?.map((item) => item.id_organisation)));
    const [selectedClients, setSelectedClients] = useState([]);
    const [clientMarket, setclientMarket] = useState(markets);
    const [clientStatus, setClientStatus] = useState("");
    const [idStatus, setIdStatus] = useState(status ? 0 : "");
    const [service, setService] = useState(0);
    const [groupData, setGroupData] = useState(groupDataProps);
    const [franqData, setFranqData] = useState(franqDataProps);
    const [filesData, setFilesData] = useState([]);
    const [filesRejected, setFilesRejected] = useState([]);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [fileFormat, setFileFormat] = useState([]);
    const [fileExtensions, setFileExtensions] = useState([]);
    const [dialogComment, setDialogComment] = useState(false);
    const [clientID, setclientID] = useState("");
    const [comments, setComments] = useState([]);
    const [commentsP, setCommentsPend] = useState([]);
    const [active, setActive] = useState(false);
    const [activeBanks, setActiveBanks] = useState(false);
    const [selectedBank, setSelectedBank] = useState({
        idOrganisation: "",
        idXero: "",
        idAccount: "",
        mode: "",
        month: "",
        showCheck: 0,
        company: "",
        account: "",
        NotApply: false,
        NotApplyChecks: false,
        NotApplyPetty: false,
        FullLoadChecks: false,
    });
    const [valuesBank, setValuesBank] = useState({
        idAccount: "",
        accountName: "",
        orgName: "",
        orgID: "",
        monitoringFreq: "",
        receptionDays: 2,
        mode: "",
        bankName: "",
        checkedNA: false,
        software: "",
        initDate: null,
        endDate: null,
        checksInc: false,
        requirements: [],
        user: "",
        pass: "",
        pin: "",
        description: "",
        isResquested: false,
        hasAccess: false,
        showPassword: false,
        showPin: false,
    });
    const [checkStatus, setCheckStatus] = useState(false);
    const [checkStatusChecks, setCheckStatusChecks] = useState(false);
    const [checkStatusPetty, setCheckStatusPetty] = useState(false);
    const [customStatus, setCustomStatus] = useState(false);
    const [fullLoadChecks, setFullLoadChecks] = useState(false);
    const controllerRef = useRef(null);
    const [showTabs, setShowTabs] = useState({
        requirement_bank_statements: false,
        requirement_checks: false,
        requirement_petty_cash: false,
        initial_date: "",
        included_checks: false,
        current_date: "",
    });
    const [configType, setConfigType] = useState("recu");
    const [configDialog, setConfigDialog] = useState(false);
    const [recurrentVal, setRecurrentVal] = useState(defaultStateConfig);
    const [buttonTabs, setButtomTabs] = useState("");
    const [filesRejectedChecks, setFilesRejectedChecks] = useState([]);
    const [filesRejectedPetty, setFilesRejectedPetty] = useState([]);
    const [filesDataChecks, setFilesDataChecks] = useState([]);
    const [filesDataPetty, setFilesDataPetty] = useState([]);
    const [customRequirementInfo, setCustomRequirementInfo] = useState({
        idOrganisation: "",
        month: "",
        company: "",
        current_date: "",
        req_type: "",
        req_name: "",
        custom_requirement_id: "",
    });

    const [filesDataCustom, setFilesDataCustom] = useState([]);
    const [uploadedFilesCustom, setUploadedFilesCustom] = useState([]);
    const [uploadedFilesCustomSizes, setUploadedFilesCustomSizes] = useState([]);
    const [uploadedFilesCustomUploadDate, setUploadedFilesCustomUploadDate] = useState([]);
    const [filesRejectedCustom, setFilesRejectedCustom] = useState([]);
    const [occasionalRequirement, setOccasionalRequirement] = useState(defaultConfigOccasionalRequirements);
    const [dataRequirementOccasional, setDataRequirementOccasional] = useState([]);
    const [companyNameReq, setCompanyNameReq] = useState("");
    const [rowsFilter, setRowsFilter] = useState([]);
    const [page, setPage] = useState(0);
    const [recuConfig, setRecuConfig] = useState(rowsRecu);
    const [anyFilter, setAnyFilter] = useState(true);

    /** Global variables */
    const breadcrumbs = [
        <Typography
            key="1"
            variant="h1"
            color="primary"
            sx={{
                fontSize: 24,
                fontWeight: 700,
            }}
        >
            {t("principal.requirements")}
        </Typography>,
    ];

    useEffect(() => {
        if (clientsData.length) {
            const rowsCurrent = [];
            expandedRowIds?.forEach((row) => {
                rowsCurrent.push(clientsData[row]);
            });
            setRowsFilter(rowsCurrent);
        }
    }, [expandedRowIds, clientsData]);

    const cleanFilters = () => {
        setAnyFilter(true);
        setTClient("");
        setfranqF(["all"].concat(franqDataProps.map((item) => item.id)));
        setgroupF(["all"].concat(groupDataProps.map((item) => item.id)));
        setClientsF(["all"].concat(allData.map((item) => item.id_organisation)));
        setSelectedClients([]);
        setGroupData(groupDataProps);
        setIdStatus(status ? 0 : "");
        setClientStatus("");
        setService(0);
        setclientMarket(markets);
        setselectedYear(new Date().getFullYear());
        setActiveBanks(false);
        setActive(false);
    };

    const closeConfigDialog = () => {
        setConfigDialog(false);
        setIsError(false);
        setRecurrentVal(defaultStateConfig);
        setDataRequirementOccasional([]);
        handleChangeConfigType(null, "recu");
        setOccasionalRequirement(defaultConfigOccasionalRequirements);
        setRecuConfig(rowsRecu);
    };

    const handleChangeConfigType = (event, newAlignment) => {
        if (newAlignment !== null) {
            setConfigType(newAlignment);
        }
    };

    const saveConfig = async (type) => {
        setBackDrop(true);

        let params = {
            company_id: recurrentVal.idOrganisation,
            dependency: recurrentVal.dependency,
            manage_requirements: recurrentVal.manageReq,
            additional_info: recurrentVal.additionalInfo,
        };

        // Pestaña Recurrentes
        if (type === 1) {
            let array = [];
            let isError = false;

            // Recorremos, validamos cada parametro y guardamos los requerimientos chequeados
            recuConfig.forEach((item, index) => {
                if (recurrentVal["checked" + item]) {
                    if (
                        recurrentVal["period" + item] &&
                        recurrentVal["recepDays" + item] &&
                        recurrentVal["initDate" + item]
                    ) {
                        const newObj = {
                            id: recurrentVal[`id${item}`],
                            requirement_type: index < 5 ? index + 1 : 5,
                            periodicity: recurrentVal[`period${item}`],
                            reception_days: parseInt(recurrentVal[`recepDays${item}`]),
                            initial_date: moment(recurrentVal[`initDate${item}`]).format("YYYY-MM-DD"),
                            end_date: recurrentVal[`endDate${item}`]
                                ? moment(recurrentVal[`endDate${item}`]).format("YYYY-MM-DD")
                                : null,
                        };

                        if (item.includes("Other")) {
                            if (recurrentVal[`nameOther${index}`]) {
                                newObj.requirement_name = recurrentVal[`nameOther${index}`];
                            } else {
                                isError = true;
                            }
                        }
                        array.push(newObj);
                    } else {
                        isError = true;
                    }
                }
            });

            if (isError) {
                setTexto(t("miscellaneous.requiredData"));
                setAlertType("warning");
                setShow(true);
                setBackDrop(false);
                setIsError(true);
                return;
            }

            params.recurring_requirements = array;
        }
        // Pestaña Ocasionales
        else {
            let isError = false;
            params.occasional_requirement = {
                requirement_type: occasionalRequirement.requirementType,
                requirement_name: occasionalRequirement.requirementName,
                initial_date: occasionalRequirement.initialDate,
            };

            if (
                !occasionalRequirement.requirementType ||
                !occasionalRequirement.requirementName ||
                !occasionalRequirement.initialDate
            ) {
                isError = true;
            }

            if (isError) {
                setTexto(t("miscellaneous.requiredData"));
                setAlertType("warning");
                setShow(true);
                setBackDrop(false);
                setIsError(true);
                return;
            }
        }

        await fetch(`/addRequirementsConfig`, {
            method: "POST",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
            body: JSON.stringify(params),
        })
            .then(async (response) => {
                if (response.ok) {
                    await loadInitData();
                    setTexto(t("miscellaneous.successfulUpdate"));
                    setAlertType("success");
                    setShow(true);
                    closeConfigDialog();
                } else {
                    setTexto(t("miscellaneous.updatingError"));
                    setAlertType("error");
                    setShow(true);
                }
            })
            .catch((error) => {
                console.log(error);
            });

        setBackDrop(false);
    };

    const handleChangeStatus = (event, tab) => {
        if (tab.toString() === "1") {
            setCheckStatus(event.target.checked);
        } else if (tab.toString() === "2") {
            setCheckStatusChecks(event.target.checked);
        } else {
            setCheckStatusPetty(event.target.checked);
        }

        if (tab.toString() === "1") {
            if (event.target.checked) {
                setDialogAction("Guardar");
                setDialogText(t("bank.saveWithNoBankStatement"));
            } else if (selectedBank.mode !== undefined && selectedBank.mode !== 0) {
                setDialogAction("Convertir");
                setDialogText(t("bank.convertBankStatement"));
            } else {
                setDialogAction("Guardar");
                setDialogText(t("bank.saveBankStatement"));
            }
        } else {
            setDialogAction("Guardar");
            setDialogText(t("miscellaneous.saveSure"));
        }
    };

    const handleChangeFullLoadChecks = () => {
        setFullLoadChecks((checked) => !checked);
    };

    const handleChangeCustomStatus = (event) => {
        setCustomStatus(event.target.checked);
    };

    /** Component functions */
    const TableCellTreeComponent = ({ ...props }) => (
        <TableTreeColumn.Cell
            {...props}
            className={props.children[1].props.visible === true ? classes.tableCellTree : classes.tableBody}
            onClick={() => {
                if (props.children[1].props.visible === true) {
                    if (!expandedRowIds.includes(props.tableRow.rowId)) {
                        setExpandenRowIds((prevArray) => [...prevArray, props.tableRow.rowId]);
                    } else {
                        const newExpandedRows = [...expandedRowIds];
                        newExpandedRows.splice(newExpandedRows.indexOf(props.tableRow.rowId), 1);
                        setExpandenRowIds(newExpandedRows);
                    }
                } else {
                    return;
                }
            }}
        />
    );

    const handleOpenDialogForm = async (id_account) => {
        setBackDrop(true);

        await fetch(`/getAccountDetails?account_id=${id_account}&token_set_id=${todos.userInfo._id}`, {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then((res) => res.json())
            .then((data) => {
                let requirements = [];

                if (data.requirement_bank_statements) {
                    requirements.push(t("clientsTable.accStatements"));
                }
                if (data.requirement_checks) {
                    requirements.push(t("services.checks"));
                }
                if (data.requirement_petty_cash) {
                    requirements.push(t("services.smallReport"));
                }

                setValuesBank({
                    idAccount: id_account,
                    accountName: data.account_name,
                    orgName: data.company_name,
                    orgID: data.company_id,
                    monitoringFreq: data.frequency !== undefined && data.frequency !== null ? data.frequency : "",
                    receptionDays: data.reception_days ?? 2,
                    mode: data.mode !== undefined && data.mode !== null ? data.mode : "",
                    bankName: data.bank !== undefined && data.bank !== null ? props.formatBankInfo(data.bank) : "",
                    checkedNA: data.cash !== undefined ? data.cash : false,
                    software: data.software_provider,
                    requirements: requirements,
                    checksInc: data.included_checks,
                    initDate: data.initial_date ? moment(data.initial_date, "YYYY-MM-DD") : null,
                    endDate: data.end_date ? moment(data.end_date, "YYYY-MM-DD") : null,
                    user: data.user,
                    password: data.password,
                    pin: data.pin,
                    description: data.description,
                    isResquested: data.is_requested,
                    hasAccess: data.has_access,
                    showPassword: false,
                    showPin: false,
                });
                setDialogForm(true);
            });

        setBackDrop(false);
    };

    const handleOpenDialog = async (bnkDatta, accDatta, month, showCheck) => {
        setBackDrop(true);

        await fetch(
            `/getUploadedFile?organisationId=${bnkDatta.id_organisation}&account_id=${accDatta._id}&date=${moment()
                .set({ year: selectedYear, month: parseInt(month) - 1 })
                .startOf("month")
                .format("YYYY-MM-DD")}`,
            {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            }
        )
            .then((res) => res.json())
            .then((data) => {
                setShowTabs({
                    requirement_bank_statements: data.tabs.requirement_bank_statements,
                    requirement_checks: data.tabs.requirement_checks,
                    requirement_petty_cash: data.tabs.requirement_petty_cash,
                    initial_date: data.initial_date,
                    included_checks: data.included_checks,
                    current_date: moment()
                        .set({ year: selectedYear, month: parseInt(month) - 1 })
                        .startOf("month")
                        .format("YYYY-MM-DD"),
                });
                setButtomTabs(data.tabs.requirement_bank_statements ? "1" : data.tabs.requirement_checks ? "2" : "3");

                let finalFiles = [];
                data.requirements.forEach((item) => {
                    finalFiles.push({
                        req_type: item.requirement_type,
                        files: item.files,
                        sizes: item.sizes,
                        upload_date: item.upload_date,
                    });
                });
                if (
                    data.tabs.requirement_bank_statements &&
                    finalFiles.filter((item) => item.req_type.toString() === "1").length === 0
                ) {
                    finalFiles.push({
                        req_type: 1,
                        files: [],
                        sizes: [],
                        upload_date: [],
                    });
                }
                if (
                    data.tabs.requirement_checks &&
                    finalFiles.filter((item) => item.req_type.toString() === "2").length === 0
                ) {
                    finalFiles.push({
                        req_type: 2,
                        files: [],
                        sizes: [],
                        upload_date: [],
                    });
                }
                if (
                    data.tabs.requirement_petty_cash &&
                    finalFiles.filter((item) => item.req_type.toString() === "3").length === 0
                ) {
                    finalFiles.push({
                        req_type: 3,
                        files: [],
                        sizes: [],
                        upload_date: [],
                    });
                }
                setUploadedFiles(finalFiles);
                let finalCheck1 = false;
                let finalCheck2 = false;
                let finalCheck3 = false;
                let finalCheck4 = false; // Carga completa de cheques.
                if (
                    data.requirements.length > 0 &&
                    data.requirements.filter((item) => item.requirement_type === 1).length > 0 &&
                    data.requirements.filter((item) => item.requirement_type === 1)[0].not_applicable
                ) {
                    finalCheck1 = true;
                    setCheckStatus(true);
                } else {
                    setCheckStatus(false);
                }
                if (
                    data.requirements.length > 0 &&
                    data.requirements.filter((item) => item.requirement_type === 2).length > 0 &&
                    data.requirements.filter((item) => item.requirement_type === 2)[0].not_applicable
                ) {
                    finalCheck2 = true;
                    setCheckStatusChecks(true);
                } else {
                    setCheckStatusChecks(false);
                }
                if (
                    data.requirements.length > 0 &&
                    data.requirements.filter((item) => item.requirement_type === 3).length > 0 &&
                    data.requirements.filter((item) => item.requirement_type === 3)[0].not_applicable
                ) {
                    finalCheck3 = true;
                    setCheckStatusPetty(true);
                } else {
                    setCheckStatusPetty(false);
                }

                if (
                    data.requirements.length > 0 &&
                    data.requirements.filter((item) => item.requirement_type === 2).length > 0 &&
                    data.requirements.filter((item) => item.requirement_type === 2)[0].full_load
                ) {
                    setFullLoadChecks(true);
                    finalCheck4 = true;
                } else {
                    setFullLoadChecks(false);
                }

                setSelectedBank({
                    idOrganisation: bnkDatta.id_organisation,
                    idXero: accDatta.account_id_xero,
                    idAccount: accDatta._id,
                    mode: accDatta.mode,
                    month: month,
                    showCheck: showCheck,
                    company: bnkDatta.company_name,
                    account: accDatta.account_name,
                    NotApply: finalCheck1,
                    NotApplyChecks: finalCheck2,
                    NotApplyPetty: finalCheck3,
                    FullLoadChecks: finalCheck4,
                });
                if (data.tabs.requirement_bank_statements) {
                    if (finalCheck1) {
                        setDialogAction("Guardar");
                        setDialogText(t("bank.saveWithNoBankStatement"));
                    } else if (accDatta.mode !== undefined && accDatta.mode !== 0) {
                        setDialogAction("Convertir");
                        setDialogText(t("bank.convertBankStatement"));
                    } else {
                        setDialogAction("Guardar");
                        setDialogText(t("bank.saveBankStatement"));
                    }
                } else {
                    setDialogAction("Guardar");
                    setDialogText(t("miscellaneous.saveSure"));
                }

                setFileFormat(accDatta.file_format);
                setFileExtensions(accDatta.file_format.map((file) => `.${file}`).join("|"));
            });

        setOpen(true);

        setBackDrop(false);
    };

    const handleOpenDialogCustom = async (bnkDatta, reqData, month, showCheck) => {
        setBackDrop(true);

        await fetch(
            `/getUploadedFileCustom?organisationId=${bnkDatta.id_organisation}&type=${
                reqData.requirement_type
            }&date=${moment()
                .set({ year: selectedYear, month: parseInt(month) - 1 })
                .startOf("month")
                .format("YYYY-MM-DD")}&requirementId=${reqData._id}`,
            {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            }
        )
            .then((res) => res.json())
            .then((data) => {
                setCustomRequirementInfo({
                    idOrganisation: bnkDatta.id_organisation,
                    month: month,
                    company: bnkDatta.company_name,
                    current_date: moment()
                        .set({ year: selectedYear, month: parseInt(month) - 1 })
                        .startOf("month")
                        .format("YYYY-MM-DD"),
                    req_type: data.requirements.requirement_type,
                    req_name: data.requirements.requirement_name,
                    custom_requirement_id: data.requirements.custom_requirement_id,
                });
                setCustomStatus(data.requirements.not_applicable);
                setUploadedFilesCustom(data.requirements.files);
                setUploadedFilesCustomSizes(data.requirements.sizes);
                setUploadedFilesCustomUploadDate(data.requirements.upload_date);
            });

        setOpenCustom(true);

        setBackDrop(false);
    };

    const handleChangeTabs = (event, newAlignment) => {
        if (newAlignment !== null) {
            setPage(0);
            if (newAlignment.toString() === "1") {
                if (checkStatus) {
                    setDialogAction("Guardar");
                    setDialogText(t("bank.saveWithNoBankStatement"));
                } else if (selectedBank.mode !== undefined && selectedBank.mode !== 0) {
                    setDialogAction("Convertir");
                    setDialogText(t("bank.convertBankStatement"));
                } else {
                    setDialogAction("Guardar");
                    setDialogText(t("bank.saveBankStatement"));
                }
            } else {
                setDialogAction("Guardar");
                setDialogText(t("miscellaneous.saveSure"));
            }
            setButtomTabs(newAlignment);
        }
    };

    const handleCloseDialog = async () => {
        setSelectedBank({
            idOrganisation: "",
            idXero: "",
            idAccount: "",
            mode: "",
            month: "",
            showCheck: 0,
            company: "",
            account: "",
            NotApply: false,
            NotApplyChecks: false,
            NotApplyPetty: false,
            FullLoadChecks: false,
        });
        setCheckStatus([]);
        setFilesData([]);
        setFilesRejected([]);
        setUploadedFiles([]);
        setFileFormat([]);
        setFileExtensions([]);
        setDialogAction("");
        setDialogText("");
        setOpen(false);
        setShowTabs({
            requirement_bank_statements: false,
            requirement_checks: false,
            requirement_petty_cash: false,
            initial_date: "",
            included_checks: false,
            current_date: "",
        });
        setButtomTabs("");
        setFilesRejectedChecks([]);
        setFilesRejectedPetty([]);
        setFilesDataChecks([]);
        setFilesDataPetty([]);
    };

    const handleCloseDialogDiscard = async () => {
        setBackDrop(true);
        let discard = {};
        if (filesData.length > 0) {
            discard["Bancos"] = filesData.map((item) => item.name);
        }
        if (filesDataChecks.length > 0) {
            discard["Cheques"] = filesDataChecks.map((item) => item.name);
        }
        if (filesDataPetty.length > 0) {
            discard["CajasChicas"] = filesDataPetty.map((item) => item.name);
        }

        if (Object.keys(discard).length > 0) {
            let params = {
                id_organisation: selectedBank.idOrganisation,
                bank_id: selectedBank.idAccount,
                month: selectedBank.month,
                year: selectedYear,
                discard: discard,
            };
            await axios.post("/deleteFileS3multiple", params).catch((err) => {
                console.log(err);
            });
        }
        setSelectedBank({
            idOrganisation: "",
            idXero: "",
            idAccount: "",
            mode: "",
            month: "",
            showCheck: 0,
            company: "",
            account: "",
            NotApply: false,
            NotApplyChecks: false,
            NotApplyPetty: false,
            FullLoadChecks: false,
        });
        setCheckStatus([]);
        setFilesData([]);
        setFilesRejected([]);
        setUploadedFiles([]);
        setFileFormat([]);
        setFileExtensions([]);
        setDialogAction("");
        setDialogText("");
        setOpen(false);
        setShowTabs({
            requirement_bank_statements: false,
            requirement_checks: false,
            requirement_petty_cash: false,
            initial_date: "",
            included_checks: false,
            current_date: "",
        });
        setButtomTabs("");
        setFilesRejectedChecks([]);
        setFilesRejectedPetty([]);
        setFilesDataChecks([]);
        setFilesDataPetty([]);
        setBackDrop(false);
    };

    const handleCloseDialogCustom = async () => {
        setBackDrop(true);
        let params = {
            id_organisation: customRequirementInfo.idOrganisation,
            month: customRequirementInfo.month,
            year: selectedYear,
            CustomRequirement: true,
            custom_req_type: customRequirementInfo.req_type,
            custom_requirement_id: customRequirementInfo.custom_requirement_id,
            not_applicable: customStatus,
        };

        await fetch(`/updateCustomRequirement`, {
            method: "POST",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
            body: JSON.stringify(params),
        })
            .then((response) => {
                if (response.ok) {
                    setOpenCustom(false);
                    setFilesDataCustom([]);
                    setUploadedFilesCustom([]);
                    setUploadedFilesCustomSizes([]);
                    setUploadedFilesCustomUploadDate([]);
                    setFilesRejectedCustom([]);
                    setCustomRequirementInfo({
                        idOrganisation: "",
                        month: "",
                        company: "",
                        current_date: "",
                        req_type: "",
                        req_name: "",
                        custom_requirement_id: "",
                    });

                    loadInitData();
                    setTexto(t("miscellaneous.successfulUpdate"));
                    setAlertType("success");
                    setShow(true);
                    //setExpandenRowIds([]);
                } else {
                    setTexto(t("miscellaneous.updatingError"));
                    setAlertType("error");
                    setShow(true);
                }
            })
            .catch((error) => {
                console.log(error);
            });
        setBackDrop(false);
    };

    const handleCloseDialogCustomDiscard = async () => {
        setBackDrop(true);
        let discard = {};
        if (filesDataCustom.length > 0) {
            let folder_name =
                customRequirementInfo.req_type.toString() === "1"
                    ? "Reporte de nómina"
                    : customRequirementInfo.req_type.toString() === "2"
                    ? "Reporte de contratistas"
                    : customRequirementInfo.req_type.toString() === "3"
                    ? "Reporte de ventas"
                    : customRequirementInfo.req_type.toString() === "4"
                    ? "Declaración de ISLR"
                    : customRequirementInfo.req_name;

            discard[folder_name] = filesDataCustom.map((item) => item.name);
        }

        if (Object.keys(discard).length > 0) {
            let params = {
                id_organisation: customRequirementInfo.idOrganisation,
                month: customRequirementInfo.month,
                year: selectedYear,
                CustomRequirement: true,
                custom_req_type: customRequirementInfo.req_type,
                custom_requirement_id: customRequirementInfo.custom_requirement_id,
                discard: discard,
            };
            await axios.post("/deleteFileS3multiple", params).catch((err) => {
                console.log(err);
            });
        }
        setOpenCustom(false);
        setFilesDataCustom([]);
        setUploadedFilesCustom([]);
        setUploadedFilesCustomSizes([]);
        setUploadedFilesCustomUploadDate([]);
        setFilesRejectedCustom([]);
        setCustomRequirementInfo({
            idOrganisation: "",
            month: "",
            company: "",
            current_date: "",
            req_type: "",
            req_name: "",
            custom_requirement_id: "",
        });
        setBackDrop(false);
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setShow(false);
    };

    const handleClick = (event) => {
        setselectedYear(event.target.value);
        setAnyFilter(false);
    };

    const handleActive = (event) => {
        setActive(event.target.checked);
        setAnyFilter(false);
    };

    const handleDependency = (event) => {
        setRecurrentVal({
            ...recurrentVal,
            dependency: event.target.value,
        });
    };

    const handleActiveBanks = (event) => {
        setActiveBanks(event.target.checked);
        setAnyFilter(false);
    };

    const handleChangeReceptionDays = (event) => {
        if (event.target.value >= 1 && event.target.value <= 15) {
            setValuesBank({
                ...valuesBank,
                [event.target.name]: parseInt(event.target.value),
            });
        } else {
            setValuesBank({
                ...valuesBank,
                [event.target.name]: "",
            });
            setTexto(t("services.receptionDaysMsg"));
            setAlertType("warning");
            setShow(true);
        }
    };

    const handleChangeIdStatus = (event) => {
        setIdStatus(event.target.value);
        setAnyFilter(false);
    };

    const handleChangeTClient = async (event) => {
        setBackDrop(true);
        setTClient(event.target.value);
        setAnyFilter(false);

        let finalFranq = [];

        await fetch(`/getClientFranchiseFilter?client_type=${event.target.value}`, {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then((res) => res.json())
            .then((data) => {
                setFranqData(data);
                if (event.target.value === "") {
                    finalFranq = [];
                } else {
                    finalFranq = data.map((item) => item.id);
                }
                setfranqF(["all"].concat(data.map((item) => item.id)));
            });

        await fetch(`/getFranchiseGroups?id_franchise=${finalFranq}`, {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then((res) => res.json())
            .then((data) => {
                setGroupData(data);
                setgroupF(["all"].concat(data.map((item) => item.id)));
            });
        setBackDrop(false);
    };

    const handleChangeService = (event) => {
        setService(event.target.value);
        setAnyFilter(false);
    };

    const handleChangeFranqF = async (event) => {
        setBackDrop(true);

        const {
            target: { value },
        } = event;

        let final = [];

        if (value.includes("all") && !franqF.includes("all")) {
            final = ["all"].concat(franqData.map((item) => item.id));
        } else if (!value.includes("all") && franqF.includes("all")) {
            final = [];
        } else {
            if (!value.includes("all") && value.length === franqData.length) {
                final = ["all"].concat(franqData.map((item) => item.id));
            } else {
                final =
                    typeof value === "string"
                        ? value.split(",").filter((item) => item !== "all")
                        : value.filter((item) => item !== "all");
            }
        }
        setfranqF(final);
        setAnyFilter(false);

        let finalFranq = [];
        if (final.includes("all")) {
            finalFranq = [];
        } else if (final.length === 0) {
            finalFranq = ["none"];
        } else {
            finalFranq = final.filter((item) => item !== "all");
        }

        await fetch(`/getFranchiseGroups?id_franchise=${finalFranq}`, {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then((res) => res.json())
            .then((data) => {
                setgroupF(["all"].concat(data.map((item) => item.id)));
                setGroupData(data);
            });

        setBackDrop(false);
    };

    const handleChangeGroupF = (event) => {
        const {
            target: { value },
        } = event;

        let final = [];

        if (value.includes("all") && !groupF.includes("all")) {
            final = ["all"].concat(groupDataProps.map((item) => item.id));
        } else if (!value.includes("all") && groupF.includes("all")) {
            final = [];
        } else {
            if (!value.includes("all") && value.length === groupData.length) {
                final = ["all"].concat(groupData.map((item) => item.id));
            } else {
                final = event.target.value.filter((item) => item !== "all");
            }
        }
        setgroupF(final);
        setAnyFilter(false);
    };

    const handleChangeCompanyFilter = (event) => {
        const {
            target: { value },
        } = event;

        let final = [];

        if (value.includes("all") && !clientsF.includes("all")) {
            final = ["all"].concat(allData.map((item) => item.id));
        } else if (!value.includes("all") && clientsF.includes("all")) {
            final = [];
        } else {
            if (!value.includes("all") && value.length === allData.length) {
                final = ["all"].concat(allData.map((item) => item.id));
            } else {
                final = event.target.value.filter((item) => item !== "all");
            }
        }
        setClientsF(final);
        setSelectedClients(final.join(","));
        setAnyFilter(false);
    };

    const handleChangeclientMarket = (event) => {
        const {
            target: { value },
        } = event;

        let final = [];

        if (value.includes(t("miscellaneous.all")) && !clientMarket.includes(t("miscellaneous.all"))) {
            final = markets;
        } else if (!value.includes(t("miscellaneous.all")) && clientMarket.includes(t("miscellaneous.all"))) {
            final = [];
        } else {
            if (
                !value.includes(t("miscellaneous.all")) &&
                value.length === markets.filter((item) => item !== t("miscellaneous.all")).length
            ) {
                final = markets;
            } else {
                final =
                    typeof value === "string"
                        ? value.split(",").filter((item) => item !== t("miscellaneous.all"))
                        : value.filter((item) => item !== t("miscellaneous.all"));
            }
        }
        setclientMarket(final);
        setAnyFilter(false);
    };

    const handleChangeClasif = (event) => {
        setClientStatus(event.target.value);
        setAnyFilter(false);
    };

    const handleChangeCheckedNA = (event) => {
        setValuesBank({
            ...valuesBank,
            checkedNA: event.target.checked,
            endDate: null,
        });
        setIsError(false);
    };

    const closeDialog = () => {
        setDialogForm(false);
        setValuesBank({
            idAccount: "",
            accountName: "",
            orgName: "",
            orgID: "",
            monitoringFreq: "",
            receptionDays: 2,
            mode: "",
            bankName: "",
            checkedNA: false,
            software: "",
            initDate: null,
            checksInc: false,
            requirements: [],
            user: "",
            pass: "",
            pin: "",
            description: "",
            isResquested: false,
            hasAccess: false,
            showPassword: false,
            showPin: false,
        });
        setIsError(false);
    };

    const closeCommentsDialog = () => {
        setDialogComment(false);
    };

    const openComments = async (clientId) => {
        setBackDrop(true);

        setclientID(clientId);

        await getComments(clientId);

        setDialogComment(true);

        setBackDrop(false);
    };

    const getComments = async (clientId) => {
        await fetch(`/getComments?company_id=${clientId}&period=${selectedYear}&comment_type=1`, {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then((res) => res.json())
            .then((data) => {
                setComments(data.history);
                setCommentsPend(data.pendings);
            });
    };

    const updateBankAccount = async () => {
        setBackDrop(true);

        if (
            !valuesBank.checkedNA &&
            (!valuesBank.monitoringFreq ||
                !valuesBank.receptionDays ||
                valuesBank.mode === "" ||
                !valuesBank.bankName ||
                !valuesBank.initDate ||
                valuesBank.requirements.length === 0)
        ) {
            setTexto(t("miscellaneous.requiredData"));
            setAlertType("warning");
            setShow(true);
            setBackDrop(false);
            setIsError(true);
            return;
        } else if (valuesBank.checkedNA && !valuesBank.endDate) {
            setTexto(t("miscellaneous.requiredData"));
            setAlertType("warning");
            setShow(true);
            setBackDrop(false);
            setIsError(true);
            return;
        }

        let params = {
            account_id: valuesBank.idAccount,
            frequency: parseFloat(valuesBank.monitoringFreq),
            reception_days: parseFloat(valuesBank.receptionDays),
            mode_number: parseInt(valuesBank.mode),
            bank_name: valuesBank.bankName,
            cash: valuesBank.checkedNA,
            requirement_bank_statements: valuesBank.requirements.includes(t("clientsTable.accStatements")),
            requirement_checks: valuesBank.requirements.includes(t("services.checks")),
            requirement_petty_cash: valuesBank.requirements.includes(t("services.smallReport")),
            included_checks: valuesBank.checksInc,
            initial_date: valuesBank.initDate ? valuesBank.initDate.format("YYYY-MM-DD") : null,
            end_date: valuesBank.endDate ? valuesBank.endDate.format("YYYY-MM-DD") : null,
            user: valuesBank.user,
            password: valuesBank.password,
            pin: valuesBank.pin,
            description: valuesBank.description,
        };

        await fetch(`/updateBankAccounts`, {
            method: "POST",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
            body: JSON.stringify(params),
        })
            .then((response) => {
                if (response.ok) {
                    setTexto(t("miscellaneous.successfulUpdate"));
                    setAlertType("success");
                    setShow(true);
                    loadInitData();
                    closeDialog();
                    //setExpandenRowIds([]);
                } else {
                    setTexto(t("miscellaneous.updatingError"));
                    setAlertType("error");
                    setShow(true);
                }
            })
            .catch((error) => {
                console.log(error);
            });

        setBackDrop(false);
    };

    const convertBankStatement = async () => {
        setBackDrop(true);

        //Si existe algun archivo cargado en Estados de cuenta
        if (filesData.length > 0) {
            let finalFolder = [];
            let finalChecked = [];
            let changeFullLoad = false;
            if (
                showTabs.requirement_checks &&
                !showTabs.included_checks &&
                selectedBank.NotApplyChecks !== checkStatusChecks
            ) {
                finalFolder.push("Cheques");
                finalChecked.push(checkStatusChecks);
            }
            if (showTabs.requirement_petty_cash && selectedBank.NotApplyPetty !== checkStatusPetty) {
                finalFolder.push("CajasChicas");
                finalChecked.push(checkStatusPetty);
            }

            if (showTabs.requirement_checks && fullLoadChecks !== selectedBank.FullLoadChecks) {
                changeFullLoad = true;
            }

            if (changeFullLoad) {
                let params = {
                    id_organisation: selectedBank.idOrganisation,
                    bank_id: selectedBank.idAccount,
                    month: selectedBank.month,
                    year: selectedYear,
                    full_load: fullLoadChecks,
                };

                await fetch(`/changeFullLoadChecks`, {
                    method: "POST",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                    },
                    body: JSON.stringify(params),
                })
                    .then((response) => {
                        if (response.ok) {
                            setOpenAlert(false);
                            handleCloseDialog();
                            setTexto(t("miscellaneous.successfulUpdate"));
                            setAlertType("success");
                            setShow(true);
                            //setExpandenRowIds([]);
                        } else {
                            setTexto(t("miscellaneous.updatingError"));
                            setAlertType("error");
                            setShow(true);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }

            if (finalFolder.length > 0) {
                let params = {
                    id_organisation: selectedBank.idOrganisation,
                    bank_id: selectedBank.idAccount,
                    month: selectedBank.month,
                    year: selectedYear,
                    folder: finalFolder,
                    checked: finalChecked,
                };

                await fetch(`/changeNotAppRequirement`, {
                    method: "POST",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                    },
                    body: JSON.stringify(params),
                }).catch((error) => {
                    console.log(error);
                });
            }

            let fileName = [];
            let allowedExtensions = new RegExp(`(${fileExtensions})`, "i");
            let valid = false;

            const params = {
                conversion: dialogAction === "Convertir" ? true : false,
                account_id: selectedBank.idAccount,
                organisationId: selectedBank.idOrganisation,
                date: moment()
                    .set({ year: selectedYear, month: parseInt(selectedBank.month) - 1 })
                    .startOf("month")
                    .format("YYYY-MM-DD"),
                not_applicable: checkStatus,
            };

            if (checkStatus) {
                valid = true;
            } else {
                fileName = filesData[0].name.toLowerCase();

                if (allowedExtensions.exec(fileName)) {
                    valid = true;
                } else {
                    setOpenAlert(false);
                    setTexto(`${t("manageAccount.invalidFormat")} ${fileFormat.map((file) => `.${file}`).join(" ")}`);
                    setAlertType("error");
                    setShow(true);
                }
            }
            if (valid) {
                await fetch(`/convertBankStatement`, {
                    method: "POST",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                    },
                    body: JSON.stringify(params),
                })
                    .then((res) => res.json())
                    .then((response) => {
                        setOpenAlert(false);
                        handleCloseDialog();
                        if (response.message === "El estado de cuenta se ha guardado exitosamente") {
                            loadInitData();
                            setTexto(t("dialogConversions.statementSaved"));
                            setAlertType("success");
                            setShow(true);
                        } else {
                            switch (response.message) {
                                case "No existe la organización asociada a esta cuenta bancaria":
                                    setTexto(t("manageAccount.error002"));
                                    break;
                                case "No existe un banco asociado a esta cuenta bancaria":
                                    setTexto(t("manageAccount.error003"));
                                    break;
                                case "Ha ocurrido un error al transformar el archivo":
                                    setTexto(t("manageAccount.error204"));
                                    break;
                                case "El archivo está vacío o no es editable":
                                    setTexto(t("manageAccount.error205"));
                                    break;
                                case "El archivo ya está transformado":
                                    setTexto(t("manageAccount.error206"));
                                    break;
                                case "El archivo no posee transacciones":
                                    setTexto(t("manageAccount.error207"));
                                    break;
                                default:
                                    setTexto(t("manageAccount.error001"));
                                    break;
                            }
                            setAlertType("error");
                            setShow(true);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        } else {
            let finalFolder = [];
            let finalChecked = [];
            let changeFullLoad = false;
            if (showTabs.requirement_bank_statements && selectedBank.NotApply !== checkStatus) {
                finalFolder.push("Bancos");
                finalChecked.push(checkStatus);
            }
            if (
                showTabs.requirement_checks &&
                !showTabs.included_checks &&
                selectedBank.NotApplyChecks !== checkStatusChecks
            ) {
                finalFolder.push("Cheques");
                finalChecked.push(checkStatusChecks);
            }
            if (showTabs.requirement_petty_cash && selectedBank.NotApplyPetty !== checkStatusPetty) {
                finalFolder.push("CajasChicas");
                finalChecked.push(checkStatusPetty);
            }
            if (fullLoadChecks !== selectedBank.FullLoadChecks && !checkStatusChecks && showTabs.requirement_checks) {
                changeFullLoad = true;
            }

            if (changeFullLoad && finalFolder.length === 0) {
                let params = {
                    id_organisation: selectedBank.idOrganisation,
                    bank_id: selectedBank.idAccount,
                    month: selectedBank.month,
                    year: selectedYear,
                    full_load: fullLoadChecks,
                };

                await fetch(`/changeFullLoadChecks`, {
                    method: "POST",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                    },
                    body: JSON.stringify(params),
                })
                    .then((response) => {
                        if (response.ok) {
                            setOpenAlert(false);
                            handleCloseDialog();
                            setTexto(t("miscellaneous.successfulUpdate"));
                            setAlertType("success");
                            setShow(true);
                        } else {
                            setTexto(t("miscellaneous.updatingError"));
                            setAlertType("error");
                            setShow(true);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }

            if (finalFolder.length > 0) {
                let params = {
                    id_organisation: selectedBank.idOrganisation,
                    bank_id: selectedBank.idAccount,
                    month: selectedBank.month,
                    year: selectedYear,
                    folder: finalFolder,
                    checked: finalChecked,
                };

                await fetch(`/changeNotAppRequirement`, {
                    method: "POST",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                    },
                    body: JSON.stringify(params),
                })
                    .then((response) => {
                        if (response.ok) {
                            setOpenAlert(false);
                            handleCloseDialog();
                            setTexto(t("miscellaneous.successfulUpdate"));
                            setAlertType("success");
                            setShow(true);
                        } else {
                            setTexto(t("miscellaneous.updatingError"));
                            setAlertType("error");
                            setShow(true);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } else {
                setOpenAlert(false);
                handleCloseDialog();
                setTexto(t("miscellaneous.successfulUpdate"));
                setAlertType("success");
                setShow(true);
            }
        }
        loadInitData();
        setBackDrop(false);
    };

    const countNoti = (accDatta, notifyNum, type, checks = false) => {
        let name = type === "bank" ? "notify" : "requirement";
        allMonths.forEach((item) => {
            if (accDatta[name + "_" + item.key] === 2) {
                let variable = "contNoti" + item.key.charAt(0).toUpperCase() + item.key.slice(1);
                notifyNum[variable]++;
            }
        });
        if (checks) {
            let initial = moment.utc(accDatta["initial_date"]).format("YYYY-MM-DD");

            allMonths.forEach((item) => {
                if (
                    accDatta["checks_" + item.key] !== undefined &&
                    accDatta["checks_" + item.key] !== null &&
                    (accDatta["checks_" + item.key] === 0 || !accDatta["checks_full_load_" + item.key]) &&
                    accDatta[name + "_" + item.key] !== 3 &&
                    moment(selectedYear + "-" + item.num + "-01").format("YYYY-MM-DD") >= initial &&
                    moment().add(-1, "months").isAfter(moment(item.num, "MM").set("year", selectedYear))
                ) {
                    let variable = "contNoti" + item.key.charAt(0).toUpperCase() + item.key.slice(1);
                    notifyNum[variable]++;
                }
            });
        }
        return notifyNum;
    };

    const boolCheck = (accDatta, checks, value, type) => {
        let name = type === "bank" ? "notify" : "requirement";

        allMonths.forEach((item) => {
            let variable = "contNoti" + item.key.charAt(0).toUpperCase() + item.key.slice(1);

            if (accDatta[name + "_" + item.key] === value) {
                checks[variable] = true;
            }
        });
        return checks;
    };

    const loadInitData = async () => {
        if (controllerRef.current) {
            controllerRef.current.abort();
        }
        const controller = new AbortController();
        controllerRef.current = controller;

        let finalClientMarket = [];
        if (clientMarket.includes(t("miscellaneous.all"))) {
            finalClientMarket = [];
        } else if (clientMarket.length === 0) {
            finalClientMarket = ["none"];
        } else {
            finalClientMarket = clientMarket.filter((item) => item !== t("miscellaneous.all"));
        }

        let finalFranq = [];
        if (franqF.includes("all")) {
            finalFranq = [];
        } else if (franqF.length === 0) {
            finalFranq = ["none"];
        } else {
            finalFranq = franqF.filter((item) => item !== "all");
        }

        let finalGroup = [];
        if (groupF.includes("all")) {
            finalGroup = [];
        } else if (groupF.length === 0) {
            finalGroup = ["none"];
        } else {
            finalGroup = groupF.filter((item) => item !== "all");
        }

        await fetch(
            `/getRequirementsInfo?client_type=${clientT}&practice_id=${finalGroup}&franchise_id=${finalFranq}&client_status=${clientStatus}&year=${selectedYear}&status=${idStatus}&service=${service}&client_market=${finalClientMarket}&inactive_clients=${active}&disable_banks=${activeBanks}&companies=${selectedClients}`,
            {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
                signal: controllerRef.current.signal,
            }
        )
            .then((res) => res.json())
            .then((data) => {
                let originalRows = [];

                data.forEach((bnkDatta, index) => {
                    let notifyNum = {};
                    let notifyCheck = {};
                    let unlockCheck = {};
                    let warningCheck = {};

                    allMonths.forEach((item) => {
                        let variable = "contNoti" + item.key.charAt(0).toUpperCase() + item.key.slice(1);
                        notifyNum[variable] = 0;
                        notifyCheck[variable] = false;
                        unlockCheck[variable] = false;
                        warningCheck[variable] = false;
                    });

                    if (bnkDatta.accounts.length > 0) {
                        bnkDatta.accounts.forEach((accDatta) => {
                            if (!accDatta.cash) {
                                let checks = accDatta.requirement_checks && !accDatta.included_checks;

                                // Check verde
                                notifyCheck = boolCheck(accDatta, notifyCheck, 1, "bank");

                                // Alerta roja
                                notifyNum = countNoti(accDatta, notifyNum, "bank", checks);

                                // Unlock morado
                                unlockCheck = boolCheck(accDatta, unlockCheck, 3, "bank");
                            } else {
                                // Alerta amarilla
                                allMonths.forEach((item) => {
                                    if (accDatta["notify_" + item.key] !== 0) {
                                        let variable =
                                            "contNoti" + item.key.charAt(0).toUpperCase() + item.key.slice(1);
                                        warningCheck[variable] = true;
                                    }
                                });
                            }

                            let objMonths = {};
                            allMonths.forEach((item) => {
                                objMonths[item.key] = (
                                    <LightTooltip title={t("bills.uploadDoc")} aria-label="toolUpload">
                                        <span>
                                            <IconButton
                                                aria-label="upload"
                                                onClick={() =>
                                                    handleOpenDialog(
                                                        bnkDatta,
                                                        accDatta,
                                                        item.num,
                                                        accDatta["notify_" + item.key]
                                                    )
                                                }
                                                disabled={accDatta["notify_" + item.key] === 0}
                                            >
                                                {accDatta.requirement_checks &&
                                                !accDatta.included_checks &&
                                                moment(selectedYear + "-" + item.num + "-01").format("YYYY-MM-DD") >=
                                                    moment.utc(accDatta.initial_date).format("YYYY-MM-DD") ? (
                                                    <StyledBadgeChecks
                                                        variant="dot"
                                                        sx={{
                                                            "& .MuiBadge-badge": {
                                                                backgroundColor:
                                                                    accDatta["notify_" + item.key] === 3 &&
                                                                    accDatta["checks_" + item.key] === 0
                                                                        ? "#2f3190" //Morado
                                                                        : ((accDatta["checks_" + item.key] > 0 &&
                                                                              accDatta[
                                                                                  "checks_full_load_" + item.key
                                                                              ]) ||
                                                                              accDatta["checks_not_app_" + item.key]) &&
                                                                          moment()
                                                                              .add(-1, "months")
                                                                              .isAfter(
                                                                                  moment(item.num, "MM").set(
                                                                                      "year",
                                                                                      selectedYear
                                                                                  )
                                                                              )
                                                                        ? "green"
                                                                        : accDatta["checks_" + item.key] > 0 &&
                                                                          !accDatta["checks_full_load_" + item.key]
                                                                        ? "#ffb23e"
                                                                        : accDatta["checks_" + item.key] === 0 &&
                                                                          moment()
                                                                              .add(-1, "months")
                                                                              .isAfter(
                                                                                  moment(item.num, "MM").set(
                                                                                      "year",
                                                                                      selectedYear
                                                                                  )
                                                                              )
                                                                        ? "#ff5968"
                                                                        : "rgba(0, 0, 0, 0.26)",
                                                            },
                                                        }}
                                                    >
                                                        <UploadFileIcon
                                                            sx={{
                                                                color:
                                                                    accDatta["notify_" + item.key] === 2
                                                                        ? "#ff5968"
                                                                        : accDatta["notify_" + item.key] === 1
                                                                        ? "green"
                                                                        : accDatta["notify_" + item.key] === 3
                                                                        ? "#2f3190"
                                                                        : "rgba(0, 0, 0, 0.26)",
                                                            }}
                                                        />
                                                    </StyledBadgeChecks>
                                                ) : (
                                                    <UploadFileIcon
                                                        sx={{
                                                            color:
                                                                accDatta["notify_" + item.key] === 2
                                                                    ? "#ff5968"
                                                                    : accDatta["notify_" + item.key] === 1
                                                                    ? "green"
                                                                    : accDatta["notify_" + item.key] === 3
                                                                    ? "#2f3190"
                                                                    : "rgba(0, 0, 0, 0.26)",
                                                        }}
                                                    />
                                                )}
                                            </IconButton>
                                        </span>
                                    </LightTooltip>
                                );
                            });

                            originalRows.push({
                                id: accDatta._id,
                                parentId: index + 0.1,
                                isNotAccount: accDatta.cash,
                                client: (
                                    <>
                                        <CircleIcon
                                            sx={{
                                                fontSize: 10,
                                                color: accDatta.cash ? "#ff5968" : "#77CC7D",
                                            }}
                                        />
                                        <LightTooltip title={t("clientsTable.editBank")} aria-label="edit">
                                            <Button
                                                variant="text"
                                                onClick={() => handleOpenDialogForm(accDatta._id)}
                                                sx={{ color: "#2f3190" }}
                                            >
                                                {accDatta.account_name}
                                            </Button>
                                        </LightTooltip>
                                    </>
                                ),
                                status: (
                                    <Chip
                                        label={
                                            accDatta.mode === 0
                                                ? "Bank Feed"
                                                : accDatta.mode === 1
                                                ? "Manual"
                                                : t("miscellaneous.pendingMode")
                                        }
                                        sx={{
                                            color:
                                                accDatta.mode === 0
                                                    ? "green"
                                                    : accDatta.mode === 1
                                                    ? "#031851"
                                                    : "#2f3190",
                                        }}
                                        variant="outlined"
                                    />
                                ),
                                jan: objMonths.jan,
                                feb: objMonths.feb,
                                mar: objMonths.mar,
                                apr: objMonths.apr,
                                may: objMonths.may,
                                jun: objMonths.jun,
                                jul: objMonths.jul,
                                aug: objMonths.aug,
                                sep: objMonths.sep,
                                oct: objMonths.oct,
                                nov: objMonths.nov,
                                dec: objMonths.dec,
                            });
                        });

                        let objMonthsNoti = {};
                        allMonths.forEach((item) => {
                            let variable = "contNoti" + item.key.charAt(0).toUpperCase() + item.key.slice(1);
                            objMonthsNoti[item.key] =
                                notifyNum[variable] > 0 ? (
                                    <Badge badgeContent={notifyNum[variable]} color="secondary">
                                        <ErrorOutlineIcon color="secondary" />
                                    </Badge>
                                ) : unlockCheck[variable] ? (
                                    <LockOpenIcon sx={{ color: "#2f3190" }} />
                                ) : notifyCheck[variable] ? (
                                    <CheckIcon sx={{ color: "green" }} />
                                ) : warningCheck[variable] ? (
                                    <WarningAmberIcon sx={{ color: "#FFB039" }} />
                                ) : (
                                    <LockIcon color="disabled" />
                                );
                        });

                        originalRows.push({
                            id: index + 0.1,
                            parentId: bnkDatta.id_organisation,
                            client: (
                                <Typography sx={{ color: "#2F3190" }}>
                                    {t("miscellaneous.banks")} ({bnkDatta.accounts.length})
                                </Typography>
                            ),
                            jan: objMonthsNoti.jan,
                            feb: objMonthsNoti.feb,
                            mar: objMonthsNoti.mar,
                            apr: objMonthsNoti.apr,
                            may: objMonthsNoti.may,
                            jun: objMonthsNoti.jun,
                            jul: objMonthsNoti.jul,
                            aug: objMonthsNoti.aug,
                            sep: objMonthsNoti.sep,
                            oct: objMonthsNoti.oct,
                            nov: objMonthsNoti.nov,
                            dec: objMonthsNoti.dec,
                        });
                    }

                    if (bnkDatta.custom_requirements.length > 0) {
                        let recurringRequirements = bnkDatta.custom_requirements.filter((x) =>
                            [1, 2, 3, 4, 5].includes(x.requirement_type)
                        );
                        let occasionalRequirementsFixedAct = bnkDatta.custom_requirements.filter(
                            (x) => x.requirement_type === 6
                        );
                        let occasionalRequirementsPrest = bnkDatta.custom_requirements.filter(
                            (x) => x.requirement_type === 7
                        );

                        let occasionalRequirementsOther = bnkDatta.custom_requirements.filter(
                            (x) => x.requirement_type === 8
                        );

                        if (recurringRequirements.length > 0) {
                            let notifyNumRecu = {};
                            let notifyCheckRecu = {};
                            let unlockCheckRecu = {};

                            allMonths.forEach((item) => {
                                let variable = "contNoti" + item.key.charAt(0).toUpperCase() + item.key.slice(1);
                                notifyNumRecu[variable] = 0;
                                notifyCheckRecu[variable] = false;
                                unlockCheckRecu[variable] = false;
                            });

                            recurringRequirements.forEach((reqData) => {
                                // Check verde
                                notifyCheck = boolCheck(reqData, notifyCheck, 1, "req");
                                notifyCheckRecu = boolCheck(reqData, notifyCheckRecu, 1, "req");

                                // Alerta roja
                                notifyNum = countNoti(reqData, notifyNum, "req");
                                notifyNumRecu = countNoti(reqData, notifyNumRecu, "req");

                                // Unlock morado
                                unlockCheck = boolCheck(reqData, unlockCheck, 3, "req");
                                unlockCheckRecu = boolCheck(reqData, unlockCheckRecu, 3, "req");

                                let objMonthReq = {};
                                allMonths.forEach((item) => {
                                    objMonthReq[item.key] = (
                                        <LightTooltip title={t("bills.uploadDoc")} aria-label="toolUpload">
                                            <span>
                                                <IconButton
                                                    aria-label="upload"
                                                    onClick={() =>
                                                        handleOpenDialogCustom(
                                                            bnkDatta,
                                                            reqData,
                                                            item.num,
                                                            reqData["requirement_" + item.key]
                                                        )
                                                    }
                                                    disabled={reqData["requirement_" + item.key] === 0}
                                                >
                                                    <UploadFileIcon
                                                        sx={{
                                                            color:
                                                                reqData["requirement_" + item.key] === 2
                                                                    ? "#ff5968"
                                                                    : reqData["requirement_" + item.key] === 1
                                                                    ? "green"
                                                                    : reqData["requirement_" + item.key] === 3
                                                                    ? "#2f3190"
                                                                    : "rgba(0, 0, 0, 0.26)",
                                                        }}
                                                    />
                                                </IconButton>
                                            </span>
                                        </LightTooltip>
                                    );
                                });

                                originalRows.push({
                                    id: reqData._id,
                                    parentId: "REC" + index,
                                    client: (
                                        <Typography sx={{ color: "#2F3190", fontSize: 15, fontWeight: 600 }}>
                                            {reqData.requirement_type === 1
                                                ? t("proposals.payroll")
                                                : reqData.requirement_type === 2
                                                ? t("language.locale") === "en"
                                                    ? "Contractors"
                                                    : "Contratistas"
                                                : reqData.requirement_type === 3
                                                ? t("language.locale") === "en"
                                                    ? "Sales"
                                                    : "Ventas"
                                                : reqData.requirement_type === 4
                                                ? t("miscellaneous.reportISLR")
                                                : reqData.requirement_name}
                                        </Typography>
                                    ),
                                    jan: objMonthReq.jan,
                                    feb: objMonthReq.feb,
                                    mar: objMonthReq.mar,
                                    apr: objMonthReq.apr,
                                    may: objMonthReq.may,
                                    jun: objMonthReq.jun,
                                    jul: objMonthReq.jul,
                                    aug: objMonthReq.aug,
                                    sep: objMonthReq.sep,
                                    oct: objMonthReq.oct,
                                    nov: objMonthReq.nov,
                                    dec: objMonthReq.dec,
                                });
                            });

                            let objMonthOccNot = {};
                            allMonths.forEach((item) => {
                                let variable = "contNoti" + item.key.charAt(0).toUpperCase() + item.key.slice(1);
                                objMonthOccNot[item.key] =
                                    notifyNumRecu[variable] > 0 ? (
                                        <Badge badgeContent={notifyNumRecu[variable]} color="secondary">
                                            <ErrorOutlineIcon color="secondary" />
                                        </Badge>
                                    ) : unlockCheckRecu[variable] ? (
                                        <LockOpenIcon sx={{ color: "#2f3190" }} />
                                    ) : notifyCheckRecu[variable] ? (
                                        <CheckIcon sx={{ color: "green" }} />
                                    ) : warningCheck[variable] ? (
                                        <WarningAmberIcon sx={{ color: "#FFB039" }} />
                                    ) : (
                                        <LockIcon color="disabled" />
                                    );
                            });

                            originalRows.push({
                                id: "REC" + index,
                                parentId: bnkDatta.id_organisation,
                                client: (
                                    <Typography sx={{ color: "#2F3190" }}>
                                        {t("bank.otherRecu")} ({recurringRequirements.length})
                                    </Typography>
                                ),
                                jan: objMonthOccNot.jan,
                                feb: objMonthOccNot.feb,
                                mar: objMonthOccNot.mar,
                                apr: objMonthOccNot.apr,
                                may: objMonthOccNot.may,
                                jun: objMonthOccNot.jun,
                                jul: objMonthOccNot.jul,
                                aug: objMonthOccNot.aug,
                                sep: objMonthOccNot.sep,
                                oct: objMonthOccNot.oct,
                                nov: objMonthOccNot.nov,
                                dec: objMonthOccNot.dec,
                            });
                        }

                        if (occasionalRequirementsFixedAct.length > 0) {
                            let notifyNumFA = {};
                            let notifyCheckFA = {};
                            let unlockCheckFA = {};

                            allMonths.forEach((item) => {
                                let variable = "contNoti" + item.key.charAt(0).toUpperCase() + item.key.slice(1);
                                notifyNumFA[variable] = 0;
                                notifyCheckFA[variable] = false;
                                unlockCheckFA[variable] = false;
                            });

                            occasionalRequirementsFixedAct.forEach((fixedAct) => {
                                // Check verde
                                notifyCheck = boolCheck(fixedAct, notifyCheck, 1, "req");
                                notifyCheckFA = boolCheck(fixedAct, notifyCheckFA, 1, "req");

                                // Alerta roja
                                notifyNum = countNoti(fixedAct, notifyNum, "req");
                                notifyNumFA = countNoti(fixedAct, notifyNumFA, "req");

                                // Unlock morado
                                unlockCheck = boolCheck(fixedAct, unlockCheck, 3, "req");
                                unlockCheckFA = boolCheck(fixedAct, unlockCheckFA, 3, "req");

                                let objMonthOcc = {};
                                allMonths.forEach((item) => {
                                    objMonthOcc[item.key] = (
                                        <LightTooltip title={t("bills.uploadDoc")} aria-label="toolUpload">
                                            <span>
                                                <IconButton
                                                    aria-label="upload"
                                                    onClick={() =>
                                                        handleOpenDialogCustom(
                                                            bnkDatta,
                                                            fixedAct,
                                                            item.num,
                                                            fixedAct["requirement_" + item.key]
                                                        )
                                                    }
                                                    disabled={fixedAct["requirement_" + item.key] === 0}
                                                >
                                                    <UploadFileIcon
                                                        sx={{
                                                            color:
                                                                fixedAct["requirement_" + item.key] === 2
                                                                    ? "#ff5968"
                                                                    : fixedAct["requirement_" + item.key] === 1
                                                                    ? "green"
                                                                    : fixedAct["requirement_" + item.key] === 3
                                                                    ? "#2f3190"
                                                                    : "rgba(0, 0, 0, 0.26)",
                                                        }}
                                                    />
                                                </IconButton>
                                            </span>
                                        </LightTooltip>
                                    );
                                });

                                originalRows.push({
                                    id: fixedAct._id,
                                    parentId: "CAF" + index,
                                    isNotAccount: fixedAct.cash,
                                    client: (
                                        <Typography sx={{ color: "#2F3190", fontSize: 15, fontWeight: 600 }}>
                                            {fixedAct.requirement_name}
                                        </Typography>
                                    ),
                                    jan: objMonthOcc.jan,
                                    feb: objMonthOcc.feb,
                                    mar: objMonthOcc.mar,
                                    apr: objMonthOcc.apr,
                                    may: objMonthOcc.may,
                                    jun: objMonthOcc.jun,
                                    jul: objMonthOcc.jul,
                                    aug: objMonthOcc.aug,
                                    sep: objMonthOcc.sep,
                                    oct: objMonthOcc.oct,
                                    nov: objMonthOcc.nov,
                                    dec: objMonthOcc.dec,
                                });
                            });

                            let objMonthOccNot = {};
                            allMonths.forEach((item) => {
                                let variable = "contNoti" + item.key.charAt(0).toUpperCase() + item.key.slice(1);
                                objMonthOccNot[item.key] =
                                    notifyNumFA[variable] > 0 ? (
                                        <Badge badgeContent={notifyNumFA[variable]} color="secondary">
                                            <ErrorOutlineIcon color="secondary" />
                                        </Badge>
                                    ) : unlockCheckFA[variable] ? (
                                        <LockOpenIcon sx={{ color: "#2f3190" }} />
                                    ) : notifyCheckFA[variable] ? (
                                        <CheckIcon sx={{ color: "green" }} />
                                    ) : warningCheck[variable] ? (
                                        <WarningAmberIcon sx={{ color: "#FFB039" }} />
                                    ) : (
                                        <LockIcon color="disabled" />
                                    );
                            });

                            originalRows.push({
                                id: "CAF" + index,
                                parentId: bnkDatta.id_organisation,
                                client: (
                                    <Typography sx={{ color: "#2F3190" }}>
                                        {t("miscellaneous.fixAct")} ({occasionalRequirementsFixedAct.length})
                                    </Typography>
                                ),
                                jan: objMonthOccNot.jan,
                                feb: objMonthOccNot.feb,
                                mar: objMonthOccNot.mar,
                                apr: objMonthOccNot.apr,
                                may: objMonthOccNot.may,
                                jun: objMonthOccNot.jun,
                                jul: objMonthOccNot.jul,
                                aug: objMonthOccNot.aug,
                                sep: objMonthOccNot.sep,
                                oct: objMonthOccNot.oct,
                                nov: objMonthOccNot.nov,
                                dec: objMonthOccNot.dec,
                            });
                        }

                        if (occasionalRequirementsPrest.length > 0) {
                            let notifyNumPrest = {};
                            let notifyCheckPrest = {};
                            let unlockCheckPrest = {};

                            allMonths.forEach((item) => {
                                let variable = "contNoti" + item.key.charAt(0).toUpperCase() + item.key.slice(1);
                                notifyNumPrest[variable] = 0;
                                notifyCheckPrest[variable] = false;
                                unlockCheckPrest[variable] = false;
                            });

                            occasionalRequirementsPrest.forEach((prest) => {
                                // Check verde
                                notifyCheck = boolCheck(prest, notifyCheck, 1, "req");
                                notifyCheckPrest = boolCheck(prest, notifyCheckPrest, 1, "req");

                                // Alerta roja
                                notifyNum = countNoti(prest, notifyNum, "req");
                                notifyNumPrest = countNoti(prest, notifyNumPrest, "req");

                                // Unlock morado
                                unlockCheck = boolCheck(prest, unlockCheck, 3, "req");
                                unlockCheckPrest = boolCheck(prest, unlockCheckPrest, 3, "req");

                                let objMonthPrest = {};
                                allMonths.forEach((item) => {
                                    objMonthPrest[item.key] = (
                                        <LightTooltip title={t("bills.uploadDoc")} aria-label="toolUpload">
                                            <span>
                                                <IconButton
                                                    aria-label="upload"
                                                    onClick={() =>
                                                        handleOpenDialogCustom(
                                                            bnkDatta,
                                                            prest,
                                                            item.num,
                                                            prest["requirement_" + item.key]
                                                        )
                                                    }
                                                    disabled={prest["requirement_" + item.key] === 0}
                                                >
                                                    <UploadFileIcon
                                                        sx={{
                                                            color:
                                                                prest["requirement_" + item.key] === 2
                                                                    ? "#ff5968"
                                                                    : prest["requirement_" + item.key] === 1
                                                                    ? "green"
                                                                    : prest["requirement_" + item.key] === 3
                                                                    ? "#2f3190"
                                                                    : "rgba(0, 0, 0, 0.26)",
                                                        }}
                                                    />
                                                </IconButton>
                                            </span>
                                        </LightTooltip>
                                    );
                                });

                                originalRows.push({
                                    id: prest._id,
                                    parentId: "PRE" + index,
                                    isNotAccount: prest.cash,
                                    client: (
                                        <Typography sx={{ color: "#2F3190", fontSize: 15, fontWeight: 600 }}>
                                            {prest.requirement_name}
                                        </Typography>
                                    ),
                                    jan: objMonthPrest.jan,
                                    feb: objMonthPrest.feb,
                                    mar: objMonthPrest.mar,
                                    apr: objMonthPrest.apr,
                                    may: objMonthPrest.may,
                                    jun: objMonthPrest.jun,
                                    jul: objMonthPrest.jul,
                                    aug: objMonthPrest.aug,
                                    sep: objMonthPrest.sep,
                                    oct: objMonthPrest.oct,
                                    nov: objMonthPrest.nov,
                                    dec: objMonthPrest.dec,
                                });
                            });

                            let objMonthPrestNoti = {};
                            allMonths.forEach((item) => {
                                let variable = "contNoti" + item.key.charAt(0).toUpperCase() + item.key.slice(1);
                                objMonthPrestNoti[item.key] =
                                    notifyNumPrest[variable] > 0 ? (
                                        <Badge badgeContent={notifyNumPrest[variable]} color="secondary">
                                            <ErrorOutlineIcon color="secondary" />
                                        </Badge>
                                    ) : unlockCheckPrest[variable] ? (
                                        <LockOpenIcon sx={{ color: "#2f3190" }} />
                                    ) : notifyCheckPrest[variable] ? (
                                        <CheckIcon sx={{ color: "green" }} />
                                    ) : warningCheck[variable] ? (
                                        <WarningAmberIcon sx={{ color: "#FFB039" }} />
                                    ) : (
                                        <LockIcon color="disabled" />
                                    );
                            });

                            originalRows.push({
                                id: "PRE" + index,
                                parentId: bnkDatta.id_organisation,
                                client: (
                                    <Typography sx={{ color: "#2F3190" }}>
                                        {t("proposals.loans")} ({occasionalRequirementsPrest.length})
                                    </Typography>
                                ),
                                jan: objMonthPrestNoti.jan,
                                feb: objMonthPrestNoti.feb,
                                mar: objMonthPrestNoti.mar,
                                apr: objMonthPrestNoti.apr,
                                may: objMonthPrestNoti.may,
                                jun: objMonthPrestNoti.jun,
                                jul: objMonthPrestNoti.jul,
                                aug: objMonthPrestNoti.aug,
                                sep: objMonthPrestNoti.sep,
                                oct: objMonthPrestNoti.oct,
                                nov: objMonthPrestNoti.nov,
                                dec: objMonthPrestNoti.dec,
                            });
                        }

                        if (occasionalRequirementsOther.length > 0) {
                            let notifyNumOther = {};
                            let notifyCheckOther = {};
                            let unlockCheckOther = {};

                            allMonths.forEach((item) => {
                                let variable = "contNoti" + item.key.charAt(0).toUpperCase() + item.key.slice(1);
                                notifyNumOther[variable] = 0;
                                notifyCheckOther[variable] = false;
                                unlockCheckOther[variable] = false;
                            });

                            occasionalRequirementsOther.forEach((other) => {
                                // Check verde
                                notifyCheck = boolCheck(other, notifyCheck, 1, "req");
                                notifyCheckOther = boolCheck(other, notifyCheckOther, 1, "req");

                                // Alerta roja
                                notifyNum = countNoti(other, notifyNum, "req");
                                notifyNumOther = countNoti(other, notifyNumOther, "req");

                                // Unlock morado
                                unlockCheck = boolCheck(other, unlockCheck, 3, "req");
                                unlockCheckOther = boolCheck(other, unlockCheckOther, 3, "req");

                                let objMonthOther = {};
                                allMonths.forEach((item) => {
                                    objMonthOther[item.key] = (
                                        <LightTooltip title={t("bills.uploadDoc")} aria-label="toolUpload">
                                            <span>
                                                <IconButton
                                                    aria-label="upload"
                                                    onClick={() =>
                                                        handleOpenDialogCustom(
                                                            bnkDatta,
                                                            other,
                                                            item.num,
                                                            other["requirement_" + item.key]
                                                        )
                                                    }
                                                    disabled={other["requirement_" + item.key] === 0}
                                                >
                                                    <UploadFileIcon
                                                        sx={{
                                                            color:
                                                                other["requirement_" + item.key] === 2
                                                                    ? "#ff5968"
                                                                    : other["requirement_" + item.key] === 1
                                                                    ? "green"
                                                                    : other["requirement_" + item.key] === 3
                                                                    ? "#2f3190"
                                                                    : "rgba(0, 0, 0, 0.26)",
                                                        }}
                                                    />
                                                </IconButton>
                                            </span>
                                        </LightTooltip>
                                    );
                                });

                                originalRows.push({
                                    id: other._id,
                                    parentId: "OTR" + index,
                                    isNotAccount: other.cash,
                                    client: (
                                        <Typography sx={{ color: "#2F3190", fontSize: 15, fontWeight: 600 }}>
                                            {other.requirement_name}
                                        </Typography>
                                    ),
                                    jan: objMonthOther.jan,
                                    feb: objMonthOther.feb,
                                    mar: objMonthOther.mar,
                                    apr: objMonthOther.apr,
                                    may: objMonthOther.may,
                                    jun: objMonthOther.jun,
                                    jul: objMonthOther.jul,
                                    aug: objMonthOther.aug,
                                    sep: objMonthOther.sep,
                                    oct: objMonthOther.oct,
                                    nov: objMonthOther.nov,
                                    dec: objMonthOther.dec,
                                });
                            });

                            let objMonthOtherNoti = {};
                            allMonths.forEach((item) => {
                                let variable = "contNoti" + item.key.charAt(0).toUpperCase() + item.key.slice(1);
                                objMonthOtherNoti[item.key] =
                                    notifyNumOther[variable] > 0 ? (
                                        <Badge badgeContent={notifyNumOther[variable]} color="secondary">
                                            <ErrorOutlineIcon color="secondary" />
                                        </Badge>
                                    ) : unlockCheckOther[variable] ? (
                                        <LockOpenIcon sx={{ color: "#2f3190" }} />
                                    ) : notifyCheckOther[variable] ? (
                                        <CheckIcon sx={{ color: "green" }} />
                                    ) : warningCheck[variable] ? (
                                        <WarningAmberIcon sx={{ color: "#FFB039" }} />
                                    ) : (
                                        <LockIcon color="disabled" />
                                    );
                            });

                            originalRows.push({
                                id: "OTR" + index,
                                parentId: bnkDatta.id_organisation,
                                client: (
                                    <Typography sx={{ color: "#2F3190" }}>
                                        {t("invoicing.other")} ({occasionalRequirementsOther.length})
                                    </Typography>
                                ),
                                jan: objMonthOtherNoti.jan,
                                feb: objMonthOtherNoti.feb,
                                mar: objMonthOtherNoti.mar,
                                apr: objMonthOtherNoti.apr,
                                may: objMonthOtherNoti.may,
                                jun: objMonthOtherNoti.jun,
                                jul: objMonthOtherNoti.jul,
                                aug: objMonthOtherNoti.aug,
                                sep: objMonthOtherNoti.sep,
                                oct: objMonthOtherNoti.oct,
                                nov: objMonthOtherNoti.nov,
                                dec: objMonthOtherNoti.dec,
                            });
                        }
                    }

                    if (bnkDatta.accounts.length === 0 && bnkDatta.custom_requirements.length === 0) {
                        let cont = 0;
                        bnkDatta.comments.forEach((data) => {
                            if (data.status === 0) {
                                cont++;
                            }
                        });

                        let objMonth0 = {};
                        allMonths.forEach((item) => {
                            objMonth0[item.key] = moment()
                                .add(-1, "months")
                                .isAfter(moment(item.num, "MM").set("year", selectedYear)) ? (
                                <WarningAmberIcon sx={{ color: "#FFB039" }} />
                            ) : (
                                <LockIcon color="disabled" />
                            );
                        });

                        originalRows.push({
                            id: bnkDatta.id_organisation,
                            parentId: null,
                            client: bnkDatta.company_name,
                            settings: (
                                <LightTooltip title={t("miscellaneous.settings")}>
                                    <IconButton
                                        aria-label="clear"
                                        sx={{ color: "#2f3190" }}
                                        onClick={() => handleOpenConfig(bnkDatta.id_organisation)}
                                    >
                                        <SettingsIcon />
                                    </IconButton>
                                </LightTooltip>
                            ),
                            comments: (
                                <LightTooltip title={t("miscellaneous.comments")}>
                                    <IconButton
                                        aria-label="clear"
                                        sx={
                                            bnkDatta.comments.length === 0
                                                ? { color: "rgba(0, 0, 0, 0.54)" }
                                                : { color: "#2f3190" }
                                        }
                                        onClick={() => openComments(bnkDatta.id_organisation)}
                                    >
                                        <StyledBadge badgeContent={cont}>
                                            <CommentIcon />
                                        </StyledBadge>
                                    </IconButton>
                                </LightTooltip>
                            ),
                            jan: objMonth0.jan,
                            feb: objMonth0.feb,
                            mar: objMonth0.mar,
                            apr: objMonth0.apr,
                            may: objMonth0.may,
                            jun: objMonth0.jun,
                            jul: objMonth0.jul,
                            aug: objMonth0.aug,
                            sep: objMonth0.sep,
                            oct: objMonth0.oct,
                            nov: objMonth0.nov,
                            dec: objMonth0.dec,
                        });

                        originalRows.push({
                            id: index + 0.1,
                            parentId: bnkDatta.id_organisation,
                            client: `${t("miscellaneous.banks")} (${bnkDatta.accounts.length})`,
                            jan: objMonth0.jan,
                            feb: objMonth0.feb,
                            mar: objMonth0.mar,
                            apr: objMonth0.apr,
                            may: objMonth0.may,
                            jun: objMonth0.jun,
                            jul: objMonth0.jul,
                            aug: objMonth0.aug,
                            sep: objMonth0.sep,
                            oct: objMonth0.oct,
                            nov: objMonth0.nov,
                            dec: objMonth0.dec,
                        });
                    } else {
                        let cont = 0;
                        bnkDatta.comments.forEach((data) => {
                            if (data.status === 0) {
                                cont++;
                            }
                        });

                        let objMonthPrestNoti0 = {};
                        allMonths.forEach((item) => {
                            let variable = "contNoti" + item.key.charAt(0).toUpperCase() + item.key.slice(1);
                            objMonthPrestNoti0[item.key] =
                                notifyNum[variable] > 0 ? (
                                    <Badge badgeContent={notifyNum[variable]} color="secondary">
                                        <ErrorOutlineIcon color="secondary" />
                                    </Badge>
                                ) : unlockCheck[variable] ? (
                                    <LockOpenIcon sx={{ color: "#2f3190" }} />
                                ) : notifyCheck[variable] ? (
                                    <CheckIcon sx={{ color: "green" }} />
                                ) : warningCheck[variable] ? (
                                    <WarningAmberIcon sx={{ color: "#FFB039" }} />
                                ) : (
                                    <LockIcon color="disabled" />
                                );
                        });

                        originalRows.push({
                            id: bnkDatta.id_organisation,
                            parentId: null,
                            client: bnkDatta.company_name,
                            settings: (
                                <LightTooltip title={t("miscellaneous.settings")}>
                                    <IconButton
                                        aria-label="clear"
                                        sx={{ color: "#2f3190" }}
                                        onClick={() => handleOpenConfig(bnkDatta.id_organisation)}
                                    >
                                        <SettingsIcon />
                                    </IconButton>
                                </LightTooltip>
                            ),
                            comments: (
                                <LightTooltip title={t("miscellaneous.comments")}>
                                    <IconButton
                                        aria-label="clear"
                                        sx={
                                            bnkDatta.comments.length === 0
                                                ? { color: "rgba(0, 0, 0, 0.54)" }
                                                : { color: "#2f3190" }
                                        }
                                        onClick={() => openComments(bnkDatta.id_organisation)}
                                    >
                                        <StyledBadge badgeContent={cont}>
                                            <CommentIcon />
                                        </StyledBadge>
                                    </IconButton>
                                </LightTooltip>
                            ),
                            jan: objMonthPrestNoti0.jan,
                            feb: objMonthPrestNoti0.feb,
                            mar: objMonthPrestNoti0.mar,
                            apr: objMonthPrestNoti0.apr,
                            may: objMonthPrestNoti0.may,
                            jun: objMonthPrestNoti0.jun,
                            jul: objMonthPrestNoti0.jul,
                            aug: objMonthPrestNoti0.aug,
                            sep: objMonthPrestNoti0.sep,
                            oct: objMonthPrestNoti0.oct,
                            nov: objMonthPrestNoti0.nov,
                            dec: objMonthPrestNoti0.dec,
                        });
                    }
                });
                setClients(originalRows);
                controllerRef.current = null;

                if (rowsFilter.length) {
                    const newsIds = [];
                    rowsFilter?.forEach((row) => {
                        let rowFilter = originalRows.findIndex((element) => {
                            return row.id === element.id;
                        });
                        rowFilter !== -1 && newsIds.push(rowFilter);
                    });
                    setExpandenRowIds(newsIds);
                }
            });
    };

    const handleOpenConfig = async (company_id) => {
        setBackDrop(true);

        await fetch(`/getRequirementsConfig?company_id=${company_id}&year=${selectedYear}`, {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then((res) => res.json())
            .then((data) => {
                setCompanyNameReq(data.company_legal_name);

                let objRecu = {
                    ...defaultStateConfig,
                    idOrganisation: company_id,
                    manageReq: data.manage_requirements ?? false,
                    dependency: data.dependency ?? 3,
                    additionalInfo: data.additional_info ?? "",
                };
                let cont = 0;

                if (data.recurring_requirements.length !== 0) {
                    data.recurring_requirements.forEach((item) => {
                        let name = "";

                        switch (item.requirement_type) {
                            case 1:
                                name = "Nom";
                                break;
                            case 2:
                                name = "Cont";
                                break;
                            case 3:
                                name = "Sells";
                                break;
                            case 4:
                                name = "ISLR";
                                break;
                            default:
                                name = `Other${cont + 4}`;
                                break;
                        }

                        objRecu["id" + name] = item._id;
                        objRecu["checked" + name] = true;
                        objRecu["period" + name] = item.periodicity;
                        objRecu["recepDays" + name] = item.reception_days;
                        objRecu["initDate" + name] = moment(item.initial_date, "YYYY-MM-DD");
                        objRecu["endDate" + name] = item.end_date ? moment(item.end_date, "YYYY-MM-DD") : null;
                        objRecu["uploaded" + name] = item.uploaded;

                        if (name.includes("Other")) {
                            objRecu[`nameOther${cont + 4}`] = item.requirement_name;
                            cont++;
                        }
                    });
                }

                setRecurrentVal(objRecu);

                if (cont > 1) {
                    const newArray = [...rowsRecu];

                    for (let i = 5; i < cont + 4; i++) {
                        newArray.push(`Other${i}`);
                    }

                    setRecuConfig(newArray);
                }

                if (data.occasional_requirements.length !== 0) {
                    setDataRequirementOccasional(data.occasional_requirements);
                }
                setConfigDialog(true);
            })
            .catch((error) => {
                console.log(error);
            });

        setBackDrop(false);
    };

    const handleChangeSwitch = (event) => {
        setRecurrentVal({
            ...recurrentVal,
            manageReq: event.target.checked,
        });
    };

    const handleChangeTextArea = (event) => {
        setRecurrentVal({
            ...recurrentVal,
            additionalInfo: event.target.value,
        });
    };

    const bodyConfig = (
        <GridUI container alignItems="center" spacing={2}>
            <GridUI item xs={12}>
                <Typography variant="h5" color="secondary">
                    {t("miscellaneous.reqResp")}
                </Typography>
            </GridUI>
            <GridUI item xs={12} md={3}>
                <FormControl fullWidth>
                    <InputLabel id="dep-simple-select-label">{t("miscellaneous.dependency")}*</InputLabel>
                    <Select
                        value={recurrentVal.dependency}
                        labelId="dep-simple-select-label"
                        onChange={handleDependency}
                        IconComponent={KeyboardArrowDownIcon}
                        label={t("miscellaneous.dependency")}
                        MenuProps={{
                            PaperProps: {
                                sx: {
                                    mt: 0.7,
                                    borderRadius: 2,
                                    boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                },
                            },
                        }}
                    >
                        {selectDepen.map((value, index) => (
                            <MenuItem key={index} value={value}>
                                <Typography variant="body1" component="span" align="center" color="textPrimary">
                                    {value === 1
                                        ? "GCA"
                                        : value === 2
                                        ? t("miscellaneous.hybrid")
                                        : t("miscellaneous.client")}
                                </Typography>
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </GridUI>
            <GridUI item xs={12} md={3}>
                <LightTooltip title={t("miscellaneous.manageReqTool")}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={recurrentVal.manageReq}
                                inputProps={{
                                    "aria-label": "controlled",
                                }}
                                color="secondary"
                                onChange={handleChangeSwitch}
                            />
                        }
                        label={
                            <Typography variant="body1" color="textPrimary">
                                {t("miscellaneous.manageReq")}
                            </Typography>
                        }
                    />
                </LightTooltip>
            </GridUI>
            <GridUI item xs={12}>
                <TextField
                    id="standard-multiline-flexible"
                    placeholder={t("miscellaneous.additionalInfo")}
                    fullWidth
                    multiline
                    rows={3}
                    value={recurrentVal.additionalInfo}
                    onChange={handleChangeTextArea}
                    inputProps={{
                        maxlength: 2000,
                    }}
                />
            </GridUI>
            <GridUI item xs={12}>
                <StyledToggleButtonGroup color="primary" value={configType} exclusive onChange={handleChangeConfigType}>
                    <ToggleButton value="recu">{t("principal.recurring")}</ToggleButton>
                    <ToggleButton value="ocas">{t("principal.ocasional")}</ToggleButton>
                </StyledToggleButtonGroup>
                {configType === "recu" && (
                    <RecurrentConfig
                        recurrentVal={recurrentVal}
                        setRecurrentVal={setRecurrentVal}
                        isError={isError}
                        setShow={setShow}
                        setTexto={setTexto}
                        setAlertType={setAlertType}
                        rowsRecu={recuConfig}
                        setRecuConfig={setRecuConfig}
                    />
                )}
                {configType === "ocas" && (
                    <OccasionalConfig
                        occasionalRequirement={occasionalRequirement}
                        setOccasionalRequirement={setOccasionalRequirement}
                        data={dataRequirementOccasional}
                        setShow={setShow}
                        setTexto={setTexto}
                        setAlertType={setAlertType}
                        isError={isError}
                        loadInitData={loadInitData}
                        setBackDrop={setBackDrop}
                    />
                )}
            </GridUI>
        </GridUI>
    );

    const buttonsConfig = (
        <GridUI container direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
            <GridUI item>
                <ColorButton
                    onClick={closeConfigDialog}
                    sx={{
                        mr: 1,
                        "&:hover": {
                            backgroundColor: "white",
                            color: "#2f3190",
                            border: "1px solid #2f3190",
                        },
                    }}
                    color="secondary"
                    disableElevation
                    variant="outlined"
                >
                    {t("miscellaneous.cancel")}
                </ColorButton>
            </GridUI>
            <GridUI item>
                <ColorButton
                    onClick={() => saveConfig(configType === "recu" ? 1 : 2)}
                    sx={{
                        "&:hover": {
                            backgroundColor: "#2f3190",
                            border: "1px solid #2f3190",
                        },
                    }}
                    color="secondary"
                    disableElevation
                    variant="contained"
                >
                    {t("miscellaneous.save")}
                </ColorButton>
            </GridUI>
        </GridUI>
    );

    const uploadAsyncButtons = (
        <GridUI container justifyContent="space-between" alignItems="center" spacing={2}>
            <GridUI item>
                <ColorButton
                    sx={{
                        mr: 1,
                        "&:hover": {
                            backgroundColor: "white",
                            color: "#2f3190",
                            border: "1px solid #2f3190",
                        },
                    }}
                    color="secondary"
                    disableElevation
                    variant="outlined"
                    onClick={handleCloseDialogDiscard}
                >
                    {t("miscellaneous.cancel")}
                </ColorButton>
            </GridUI>
            {buttonTabs.toString() === "1" && (
                <GridUI item>
                    <ColorButton
                        disableElevation
                        variant="contained"
                        color="secondary"
                        onClick={() => setOpenAlert(true)}
                        sx={{
                            "&:hover": {
                                backgroundColor: "#2f3190",
                                border: "1px solid #2f3190",
                                color: "#fff",
                            },
                            "&.MuiButton-root.Mui-disabled": {
                                backgroundColor: "#ffcdd2 !important",
                                border: "none",
                                color: "#fff",
                            },
                        }}
                    >
                        {dialogAction === "Convertir" ? t("bank.convert") : t("miscellaneous.save")}
                    </ColorButton>
                </GridUI>
            )}
            {buttonTabs.toString() !== "1" && (
                <GridUI item>
                    <ColorButton
                        disableElevation
                        variant="contained"
                        color="secondary"
                        onClick={() => setOpenAlert(true)}
                        sx={{
                            "&:hover": {
                                backgroundColor: "#2f3190",
                                border: "1px solid #2f3190",
                                color: "#fff",
                            },
                            "&.MuiButton-root.Mui-disabled": {
                                backgroundColor: "#ffcdd2 !important",
                                border: "none",
                                color: "#fff",
                            },
                        }}
                    >
                        {t("miscellaneous.save")}
                    </ColorButton>
                </GridUI>
            )}
        </GridUI>
    );

    useEffect(() => {
        (async () => {
            let finalFranq = [];
            if (franqF.includes("all")) {
                finalFranq = [];
            } else {
                finalFranq = franqF.filter((item) => item !== "all");
            }

            let finalGroup = [];
            if (groupF.includes("all")) {
                finalGroup = [];
            } else {
                finalGroup = groupF.filter((item) => item !== "all");
            }

            await fetch(
                `/getNotificationYears?client_type=${clientT}&practice_id=${finalGroup}&franchise_id=${finalFranq}`,
                {
                    method: "GET",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                    },
                }
            )
                .then((res) => res.json())
                .then((data) => {
                    setyearsToNoti(data);
                });
        })();
    }, [clientT, franqF, groupF]);

    useEffect(() => {
        (async () => {
            await fetch(`/getRequirementsInfo`, {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
                .then((res) => res.json())
                .then((data) => {
                    setAllData(data);
                    setClientsF(["all"].concat(data?.map((item) => item.id_organisation)));
                });
        })();
    }, []);

    useEffect(() => {
        (async () => {
            const columnsTemplate = [
                { name: "client", title: t("miscellaneous.client") },
                { name: "settings", title: " " },
                { name: "comments", title: " " },
                { name: "status", title: " " },
                { name: "jan", title: t("monthNamesShort.jan") },
                { name: "feb", title: t("monthNamesShort.feb") },
                { name: "mar", title: t("monthNamesShort.mar") },
                { name: "apr", title: t("monthNamesShort.apr") },
                { name: "may", title: t("monthNamesShort.may") },
                { name: "jun", title: t("monthNamesShort.jun") },
                { name: "jul", title: t("monthNamesShort.jul") },
                { name: "aug", title: t("monthNamesShort.aug") },
                { name: "sep", title: t("monthNamesShort.sep") },
                { name: "oct", title: t("monthNamesShort.oct") },
                { name: "nov", title: t("monthNamesShort.nov") },
                { name: "dec", title: t("monthNamesShort.dec") },
            ];
            setColumnsDefs(columnsTemplate);
            setClients(rowsSkeleton);
            // setExpandenRowIds([]);

            await loadInitData();
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        clientStatus,
        clientT,
        franqF,
        groupF,
        active,
        clientMarket,
        selectedYear,
        idStatus,
        service,
        activeBanks,
        selectedClients,
        t,
    ]);

    return (
        <StyledContainer component="main" sx={{ flexGrow: 1, p: 4, width: "95%", pt: 10 }}>
            <Stack sx={{ pb: 2 }}>
                <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small" sx={{ color: "#031851" }} />}
                    aria-label="breadcrumb"
                >
                    {breadcrumbs}
                </Breadcrumbs>
            </Stack>
            <GridUI container justifyContent="flex-end" spacing={2}>
                <GridUI item>
                    <LightTooltip title={t("dynamicLabels.disableBanksTool")} aria-label="banks">
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={activeBanks}
                                    onChange={handleActiveBanks}
                                    inputProps={{ "aria-label": "controlled" }}
                                    color="secondary"
                                    sx={{
                                        "& .MuiSwitch-switchBase .MuiSwitch-thumb": {
                                            color: "#FFF",
                                        },
                                        "& .MuiSwitch-switchBase + .MuiSwitch-track": {
                                            backgroundColor: "#031851",
                                        },
                                        "& .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb": {
                                            color: "#FF5968",
                                        },
                                        "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                                            backgroundColor: "#FFA8B1",
                                        },
                                    }}
                                />
                            }
                            label={
                                <Typography variant="body1" color="textPrimary">
                                    {t("dynamicLabels.disableBanks")}
                                </Typography>
                            }
                        />
                    </LightTooltip>
                </GridUI>
                <GridUI item>
                    <LightTooltip title={t("dynamicLabels.disableClientsTool")} aria-label="banks">
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={active}
                                    onChange={handleActive}
                                    inputProps={{ "aria-label": "controlled" }}
                                    color="secondary"
                                    sx={{
                                        "& .MuiSwitch-switchBase .MuiSwitch-thumb": {
                                            color: "#FFF",
                                        },
                                        "& .MuiSwitch-switchBase + .MuiSwitch-track": {
                                            backgroundColor: "#031851",
                                        },
                                        "& .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb": {
                                            color: "#FF5968",
                                        },
                                        "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                                            backgroundColor: "#FFA8B1",
                                        },
                                    }}
                                />
                            }
                            label={
                                <Typography variant="body1" color="textPrimary">
                                    {t("filtersServ.inactiveClients")}
                                </Typography>
                            }
                        />
                    </LightTooltip>
                </GridUI>
                <GridUI item xs={12}>
                    <Card
                        elevation={0}
                        sx={{
                            borderRadius: 8,
                            boxShadow: "10px 10px 15px rgba(3,24,81,0.15)",
                        }}
                    >
                        <CardContent classes={{ root: classes.cardContentRoot }}>
                            <GridUI container spacing={2} className={classes.spacing} alignItems="center">
                                <GridUI item xs={6} md={1}>
                                    <FormControl fullWidth classes={{ root: classes.formRounded }}>
                                        <InputLabel id="year-simple-select-label">{t("miscellaneous.year")}</InputLabel>
                                        <Select
                                            value={selectedYear}
                                            labelId="year-simple-select-label"
                                            onChange={handleClick}
                                            IconComponent={KeyboardArrowDownIcon}
                                            className="ddlGeneral"
                                            size="small"
                                            label={t("miscellaneous.year")}
                                            MenuProps={{
                                                PaperProps: {
                                                    sx: {
                                                        mt: 0.7,
                                                        borderRadius: 2,
                                                        boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                    },
                                                },
                                            }}
                                        >
                                            {yearsToNoti.map((year, index) => (
                                                <MenuItem key={index} value={year}>
                                                    <Typography
                                                        variant="body1"
                                                        component="span"
                                                        align="center"
                                                        color="textPrimary"
                                                    >
                                                        {year}
                                                    </Typography>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </GridUI>
                                <GridUI item xs={6} md>
                                    <FormControl fullWidth classes={{ root: classes.formRounded }}>
                                        <InputLabel shrink id="type-simple-select-label">
                                            {t("miscellaneous.type")}
                                        </InputLabel>
                                        <Select
                                            size="small"
                                            IconComponent={KeyboardArrowDownIcon}
                                            value={clientT}
                                            displayEmpty
                                            onChange={handleChangeTClient}
                                            className="ddlGeneral"
                                            input={<OutlinedInput notched label={t("recurrent.typeCli")} />}
                                            MenuProps={{
                                                PaperProps: {
                                                    sx: {
                                                        mt: 0.7,
                                                        borderRadius: 2,
                                                        boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                    },
                                                },
                                            }}
                                        >
                                            <MenuItem value="">
                                                <Typography
                                                    variant="body1"
                                                    component="span"
                                                    align="center"
                                                    color="textPrimary"
                                                >
                                                    {t("miscellaneous.all")}
                                                </Typography>
                                            </MenuItem>
                                            {props.typeData.map((value) => (
                                                <MenuItem value={value.id}>
                                                    <Typography
                                                        variant="body1"
                                                        component="span"
                                                        align="center"
                                                        color="textPrimary"
                                                    >
                                                        {value.id === 1 ? t("miscellaneous.firm") : value.client_type}
                                                    </Typography>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </GridUI>
                                <GridUI item xs={6} md={1.8}>
                                    <FormControl fullWidth classes={{ root: classes.formRounded }}>
                                        <InputLabel shrink id="franq-simple-select-label">
                                            {t("miscellaneous.franchise")}
                                        </InputLabel>
                                        <Select
                                            size="small"
                                            labelId="franq-multiple-select-label"
                                            IconComponent={KeyboardArrowDownIcon}
                                            value={franqF}
                                            displayEmpty
                                            onChange={handleChangeFranqF}
                                            className="ddlGeneral"
                                            multiple
                                            input={
                                                <OutlinedInput
                                                    notched
                                                    label={t("miscellaneous.franchise")}
                                                    sx={{
                                                        fontSize: 16,
                                                        fontWeight: 600,
                                                    }}
                                                />
                                            }
                                            renderValue={(selected) => {
                                                if (selected.length === 0) {
                                                    return <span>{t("miscellaneous.none")}</span>;
                                                }
                                                return selected.includes("all")
                                                    ? t("miscellaneous.all")
                                                    : selected
                                                          .map((item) => {
                                                              return franqDataProps.filter(
                                                                  (item2) => item2.id === item
                                                              )[0].franchise_name;
                                                          })
                                                          .join(", ");
                                            }}
                                            MenuProps={MenuProps}
                                        >
                                            {[
                                                {
                                                    id: "all",
                                                    franchise_name: t("miscellaneous.all"),
                                                },
                                            ]
                                                .concat(franqData)
                                                .map((value) => (
                                                    <MenuItem value={value.id}>
                                                        <Checkbox
                                                            defaultChecked
                                                            checked={franqF.indexOf(value.id) > -1}
                                                            icon={
                                                                value.id === "all" &&
                                                                !franqF.includes("all") &&
                                                                franqF.length > 0 ? (
                                                                    <IndeterminateCheckBoxIcon
                                                                        style={{ fill: "#031851" }}
                                                                    />
                                                                ) : (
                                                                    <CheckBoxOutlineBlankIcon />
                                                                )
                                                            }
                                                        />
                                                        <ListItemText primary={value.franchise_name} />
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                </GridUI>
                                <GridUI item xs={6} md={1.5}>
                                    <FormControl fullWidth classes={{ root: classes.formRounded }}>
                                        <InputLabel shrink id="group-simple-select-label">
                                            {t("miscellaneous.group")}
                                        </InputLabel>
                                        <Select
                                            size="small"
                                            labelId="group-simple-select-label"
                                            IconComponent={KeyboardArrowDownIcon}
                                            value={groupF}
                                            displayEmpty
                                            onChange={handleChangeGroupF}
                                            className="ddlGeneral"
                                            multiple
                                            input={
                                                <OutlinedInput
                                                    notched
                                                    label={t("miscellaneous.group")}
                                                    sx={{
                                                        fontSize: 16,
                                                        fontWeight: 600,
                                                    }}
                                                />
                                            }
                                            renderValue={(selected) => {
                                                if (selected.length === 0) {
                                                    return <span>{t("miscellaneous.none")}</span>;
                                                }
                                                return selected.includes("all")
                                                    ? t("miscellaneous.all")
                                                    : selected
                                                          .map((item) => {
                                                              return groupDataProps.filter(
                                                                  (item2) => item2.id === item
                                                              )[0].group_name;
                                                          })
                                                          .join(", ");
                                            }}
                                            MenuProps={MenuProps}
                                        >
                                            {[
                                                {
                                                    id: "all",
                                                    group_name: t("miscellaneous.all"),
                                                },
                                            ]
                                                .concat(groupData)
                                                .map((value) => (
                                                    <MenuItem value={value.id}>
                                                        <Checkbox
                                                            defaultChecked
                                                            checked={groupF.indexOf(value.id) > -1}
                                                            icon={
                                                                value.id === "all" &&
                                                                !groupF.includes("all") &&
                                                                groupF.length > 0 ? (
                                                                    <IndeterminateCheckBoxIcon
                                                                        style={{ fill: "#031851" }}
                                                                    />
                                                                ) : (
                                                                    <CheckBoxOutlineBlankIcon />
                                                                )
                                                            }
                                                        />
                                                        <ListItemText primary={value.group_name} />
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                </GridUI>
                                <GridUI item xs={6} md={1.5}>
                                    <FormControl fullWidth classes={{ root: classes.formRounded }}>
                                        <InputLabel shrink id="company-simple-select-label">
                                            {t("miscellaneous.company")}
                                        </InputLabel>
                                        <Select
                                            size="small"
                                            labelId="company-simple-select-label"
                                            IconComponent={KeyboardArrowDownIcon}
                                            value={clientsF}
                                            displayEmpty
                                            onChange={handleChangeCompanyFilter}
                                            className="ddlGeneral"
                                            multiple
                                            input={
                                                <OutlinedInput
                                                    notched
                                                    label={t("miscellaneous.company")}
                                                    sx={{
                                                        fontSize: 16,
                                                        fontWeight: 600,
                                                    }}
                                                />
                                            }
                                            renderValue={(selected) => {
                                                if (selected.length === 0) {
                                                    return <span>{t("miscellaneous.none")}</span>;
                                                }
                                                return selected.includes("all")
                                                    ? t("miscellaneous.all")
                                                    : selected
                                                          .map((item) => {
                                                              return allData?.filter(
                                                                  (item2) => item2?.id_organisation === item
                                                              )[0]?.company_name;
                                                          })
                                                          ?.join(", ");
                                            }}
                                            MenuProps={MenuProps}
                                        >
                                            {[
                                                {
                                                    id_organisation: "all",
                                                    company_name: t("miscellaneous.all"),
                                                },
                                            ]
                                                .concat(allData)
                                                .map((value) => (
                                                    <MenuItem value={value.id_organisation}>
                                                        <Checkbox
                                                            defaultChecked
                                                            checked={clientsF.indexOf(value.id_organisation) > -1}
                                                            icon={
                                                                value.id_organisation === "all" &&
                                                                !clientsF.includes("all") &&
                                                                clientsF.length > 0 ? (
                                                                    <IndeterminateCheckBoxIcon
                                                                        style={{ fill: "#031851" }}
                                                                    />
                                                                ) : (
                                                                    <CheckBoxOutlineBlankIcon />
                                                                )
                                                            }
                                                        />
                                                        <ListItemText primary={value.company_name} />
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                </GridUI>
                                <GridUI item xs={4} md={1.5}>
                                    <FormControl fullWidth classes={{ root: classes.formRounded }}>
                                        <InputLabel shrink id="idStatus-simple-select-label">
                                            {t("generalDashboard.market")}
                                        </InputLabel>
                                        <Select
                                            size="small"
                                            labelId="market-multiple-select-label"
                                            IconComponent={KeyboardArrowDownIcon}
                                            displayEmpty
                                            value={clientMarket}
                                            className="ddlGeneral"
                                            onChange={handleChangeclientMarket}
                                            multiple
                                            input={
                                                <OutlinedInput
                                                    notched
                                                    label={t("generalDashboard.market")}
                                                    sx={{
                                                        fontSize: 16,
                                                        fontWeight: 600,
                                                    }}
                                                />
                                            }
                                            renderValue={(selected) => {
                                                if (selected.length === 0) {
                                                    return <span>{t("miscellaneous.none")}</span>;
                                                }

                                                return selected.includes(t("miscellaneous.all"))
                                                    ? t("miscellaneous.all")
                                                    : selected.join(", ");
                                            }}
                                            MenuProps={MenuProps}
                                        >
                                            {markets.map((name) => (
                                                <MenuItem value={name}>
                                                    <Checkbox
                                                        checked={clientMarket.indexOf(name) > -1}
                                                        icon={
                                                            name === t("miscellaneous.all") &&
                                                            !clientMarket.includes(t("miscellaneous.all")) &&
                                                            clientMarket.length > 0 ? (
                                                                <IndeterminateCheckBoxIcon
                                                                    style={{ fill: "#031851" }}
                                                                />
                                                            ) : (
                                                                <CheckBoxOutlineBlankIcon />
                                                            )
                                                        }
                                                    />
                                                    <ListItemText primary={name} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </GridUI>
                                <GridUI item xs={6} md={1.5}>
                                    <FormControl fullWidth classes={{ root: classes.formRounded }}>
                                        <InputLabel shrink id="clientStatus-simple-select-label">
                                            {t("miscellaneous.classification")}
                                        </InputLabel>
                                        <Select
                                            size="small"
                                            IconComponent={KeyboardArrowDownIcon}
                                            value={clientStatus}
                                            displayEmpty
                                            onChange={handleChangeClasif}
                                            className="ddlGeneral"
                                            input={<OutlinedInput notched label={t("miscellaneous.classification")} />}
                                            MenuProps={{
                                                PaperProps: {
                                                    sx: {
                                                        mt: 0.7,
                                                        borderRadius: 2,
                                                        boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                    },
                                                },
                                            }}
                                        >
                                            <MenuItem value="">
                                                <Typography
                                                    variant="body1"
                                                    component="span"
                                                    align="center"
                                                    color="textPrimary"
                                                >
                                                    {t("miscellaneous.all")}
                                                </Typography>
                                            </MenuItem>
                                            <MenuItem value={1}>
                                                <Typography
                                                    variant="body1"
                                                    component="span"
                                                    align="center"
                                                    color="textPrimary"
                                                    sx={{
                                                        fontStyle: "italic",
                                                        ...(moment.locale() === "en" && { fontStyle: "normal" }),
                                                    }}
                                                >
                                                    Standard
                                                </Typography>
                                            </MenuItem>
                                            <MenuItem value={2}>
                                                <Typography
                                                    variant="body1"
                                                    component="span"
                                                    align="center"
                                                    color="textPrimary"
                                                    sx={{
                                                        fontStyle: "italic",
                                                        ...(moment.locale() === "en" && { fontStyle: "normal" }),
                                                    }}
                                                >
                                                    VIP
                                                </Typography>
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </GridUI>
                                <GridUI item xs={6} md={1}>
                                    <FormControl fullWidth classes={{ root: classes.formRounded }}>
                                        <InputLabel shrink id="idStatus-simple-select-label">
                                            {t("miscellaneous.status")}
                                        </InputLabel>
                                        <Select
                                            size="small"
                                            IconComponent={KeyboardArrowDownIcon}
                                            value={idStatus}
                                            displayEmpty
                                            onChange={handleChangeIdStatus}
                                            className="ddlGeneral"
                                            input={<OutlinedInput notched label={t("miscellaneous.status")} />}
                                            MenuProps={{
                                                PaperProps: {
                                                    sx: {
                                                        mt: 0.7,
                                                        borderRadius: 2,
                                                        boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                    },
                                                },
                                            }}
                                        >
                                            <MenuItem value="">
                                                <Typography
                                                    variant="body1"
                                                    component="span"
                                                    align="center"
                                                    color="textPrimary"
                                                >
                                                    {t("miscellaneous.all")}
                                                </Typography>
                                            </MenuItem>
                                            <MenuItem value={0}>
                                                <Typography
                                                    variant="body1"
                                                    component="span"
                                                    align="center"
                                                    color="textPrimary"
                                                >
                                                    {t("miscellaneous.pendingConfig")}
                                                </Typography>
                                            </MenuItem>
                                            <MenuItem value={1}>
                                                <Typography
                                                    variant="body1"
                                                    component="span"
                                                    align="center"
                                                    color="textPrimary"
                                                >
                                                    {t("miscellaneous.pending")}
                                                </Typography>
                                            </MenuItem>
                                            <MenuItem value={2}>
                                                <Typography
                                                    variant="body1"
                                                    component="span"
                                                    align="center"
                                                    color="textPrimary"
                                                >
                                                    {t("miscellaneous.allComplete")}
                                                </Typography>
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </GridUI>
                                <GridUI item xs={6} md={1}>
                                    <FormControl fullWidth classes={{ root: classes.formRounded }}>
                                        <InputLabel shrink id="idStatus-simple-select-label">
                                            {t("miscellaneous.service")}
                                        </InputLabel>
                                        <Select
                                            size="small"
                                            IconComponent={KeyboardArrowDownIcon}
                                            value={service}
                                            displayEmpty
                                            onChange={handleChangeService}
                                            className="ddlGeneral"
                                            input={<OutlinedInput notched label={t("miscellaneous.service")} />}
                                            MenuProps={{
                                                PaperProps: {
                                                    sx: {
                                                        mt: 0.7,
                                                        borderRadius: 2,
                                                        boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                    },
                                                },
                                            }}
                                        >
                                            <MenuItem value={0}>
                                                <Typography
                                                    variant="body1"
                                                    component="span"
                                                    align="center"
                                                    color="textPrimary"
                                                >
                                                    {t("miscellaneous.all")}
                                                </Typography>
                                            </MenuItem>
                                            <MenuItem value={1}>
                                                <Typography
                                                    variant="body1"
                                                    component="span"
                                                    align="center"
                                                    color="textPrimary"
                                                    sx={{
                                                        fontStyle: "italic",
                                                        ...(moment.locale() === "en" && { fontStyle: "normal" }),
                                                    }}
                                                >
                                                    Accountant
                                                </Typography>
                                            </MenuItem>
                                            <MenuItem value={2}>
                                                <Typography
                                                    variant="body1"
                                                    component="span"
                                                    align="center"
                                                    color="textPrimary"
                                                    sx={{
                                                        fontStyle: "italic",
                                                        ...(moment.locale() === "en" && { fontStyle: "normal" }),
                                                    }}
                                                >
                                                    Bookkeeper
                                                </Typography>
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </GridUI>
                                <GridUI item xs={6} md>
                                    <Button
                                        variant="text"
                                        color="primary"
                                        disabled={anyFilter}
                                        onClick={cleanFilters}
                                        sx={{
                                            "&:hover": {
                                                color: "#2f3190",
                                            },
                                        }}
                                    >
                                        {t("miscellaneous.cleanFilters")}
                                    </Button>
                                </GridUI>
                            </GridUI>
                            <Grid rows={clientsData} columns={columnDefs}>
                                <TreeDataState
                                    expandedRowIds={expandedRowIds}
                                    onExpandedRowIdsChange={setExpandenRowIds}
                                />
                                <CustomTreeData getChildRows={getChildRows} />
                                <PagingState defaultCurrentPage={0} pageSize={15} />
                                <IntegratedPaging />
                                <Table
                                    columnExtensions={tableColumnExtensions}
                                    cellComponent={TableCellComponent}
                                    rowComponent={TableRow}
                                    messages={tableMessages}
                                />
                                <TableHeaderRow
                                    cellComponent={tableHeaderCell}
                                    contentComponent={tableContentComponent}
                                />
                                <TableTreeColumn cellComponent={TableCellTreeComponent} for="client" />
                                <PagingPanel containerComponent={PagingPanelContainer} messages={pagingMessages} />
                            </Grid>
                        </CardContent>
                    </Card>
                </GridUI>
            </GridUI>
            <Backdrop open={showBackdrop} />
            <SnackBar type={alertType} show={show} message={texto} onClose={handleClose} />
            <BankDialog
                open={dialogForm}
                onClose={closeDialog}
                maxWidth="md"
                valuesBank={valuesBank}
                isError={isError}
                setIsError={setIsError}
                handleChangeReceptionDays={handleChangeReceptionDays}
                banks={props.banks}
                setValuesBank={setValuesBank}
                updateBankAccount={updateBankAccount}
                handleChangeCheckedNA={handleChangeCheckedNA}
                selectedYear={selectedYear}
                setTexto={setTexto}
                setAlertType={setAlertType}
                setBackDrop={setBackDrop}
                setShow={setShow}
            />
            <ConfigDial
                open={configDialog}
                onClose={closeConfigDialog}
                maxWidth="lg"
                title={t("miscellaneous.smbConfig")}
                message={bodyConfig}
                button={buttonsConfig}
                companyName={companyNameReq}
            />
            <DialogAlerts
                open={openAlert}
                onClose={() => setOpenAlert(false)}
                agreeBtnLabel={t("miscellaneous.accept")}
                disagreeBtnLabel={t("miscellaneous.cancel")}
                type={"info"}
                title={dialogText}
                agreeAction={convertBankStatement}
            />
            <CommentDialog
                open={dialogComment}
                onClose={closeCommentsDialog}
                maxWidth="xs"
                clientID={clientID}
                setTexto={setTexto}
                seAutoAlertType={setAlertType}
                setBackDrop={setBackDrop}
                setShow={setShow}
                comments={comments}
                commentsP={commentsP}
                getComments={getComments}
                setInitData={loadInitData}
                year={selectedYear}
                commentType={1}
            />
            <UploadAsync
                component="requirements"
                open={open}
                onClose={handleCloseDialog}
                filesData={filesData}
                setFilesData={setFilesData}
                filesRejected={filesRejected}
                setFilesRejected={setFilesRejected}
                fileFormat={fileFormat}
                selectedBank={selectedBank}
                selectedYear={selectedYear}
                uploadedFiles={uploadedFiles}
                loadInitData={loadInitData}
                actions={uploadAsyncButtons}
                setShow={setShow}
                setTexto={setTexto}
                setAlertType={setAlertType}
                handleChangeStatus={handleChangeStatus}
                checkStatus={checkStatus}
                showTabs={showTabs}
                buttonTabs={buttonTabs}
                handleChangeTabs={handleChangeTabs}
                filesRejectedChecks={filesRejectedChecks}
                setFilesRejectedChecks={setFilesRejectedChecks}
                filesRejectedPetty={filesRejectedPetty}
                setFilesRejectedPetty={setFilesRejectedPetty}
                filesDataChecks={filesDataChecks}
                setFilesDataChecks={setFilesDataChecks}
                filesDataPetty={filesDataPetty}
                setFilesDataPetty={setFilesDataPetty}
                checkStatusChecks={checkStatusChecks}
                checkStatusPetty={checkStatusPetty}
                page={page}
                setPage={setPage}
                setDialogAction={setDialogAction}
                setDialogText={setDialogText}
                fullLoadChecks={fullLoadChecks}
                handleChangeFullLoadChecks={handleChangeFullLoadChecks}
            />
            <UploadAsyncCustom
                component="requirements"
                open={openCustom}
                onClose={handleCloseDialogCustom}
                filesDataCustom={filesDataCustom}
                setFilesDataCustom={setFilesDataCustom}
                uploadedFilesCustom={uploadedFilesCustom}
                setUploadedFilesCustom={setUploadedFilesCustom}
                filesRejectedCustom={filesRejectedCustom}
                setFilesRejectedCustom={setFilesRejectedCustom}
                selectedYear={selectedYear}
                loadInitData={loadInitData}
                setShow={setShow}
                setTexto={setTexto}
                setAlertType={setAlertType}
                customRequirementInfo={customRequirementInfo}
                handleCloseDialogCustomDiscard={handleCloseDialogCustomDiscard}
                uploadedFilesCustomSizes={uploadedFilesCustomSizes}
                uploadedFilesCustomUploadDate={uploadedFilesCustomUploadDate}
                setCustomStatus={setCustomStatus}
                customStatus={customStatus}
                handleChangeCustomStatus={handleChangeCustomStatus}
            />
        </StyledContainer>
    );
};

export default Requirements;
