/** React imports */
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

/** Images */

/** MUI icons */
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

/** MUI imports */
import { Box, Typography, Grid, Divider } from "@mui/material";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

/** Global variables */
const PREFIX = "ServiceLevelsSoft";

const classes = {
    body2: `${PREFIX}-body2`,
    serviceName: `${PREFIX}-serviceName`,
    serviceBody: `${PREFIX}-serviceBody`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.serviceName}`]: {
        fontSize: 22,
    },

    [`& .${classes.body2}`]: {
        backgroundColor: "white",
        marginTop: 15,
        minHeight: 850,
        [theme.breakpoints.down("md")]: {
            padding: 30,
        },
        [theme.breakpoints.up("md")]: {
            padding: 60,
        },
    },

    [`& .${classes.serviceBody}`]: {
        backgroundColor: "#F8F9F8",
        marginTop: 30,
        padding: 30,
        borderRadius: 30,
    },
}));

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)({
    boxShadow: "none",
    "&:before": {
        display: "none",
    },
    backgroundColor: "#F8F9F8",
});

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: "#031851" }} />} {...props} />
))({
    padding: 0,
    "& .MuiAccordionSummary-content": {
        margin: "12px 0",
    },
});

const AccordionDetails = styled(MuiAccordionDetails)({
    padding: "0px 0px 16px 0px",
    "& .MuiCard-root": {
        boxShadow: "none",
    },
});

export const ServiceLevelsSoft = (props) => {
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    const { softInfo, isBook, proposalDetails } = props;

    const [expandedSub, setExpandedSub] = useState(false);
    const handleChangeAccSub = (panel) => (event, isExpanded) => {
        setExpandedSub(isExpanded ? panel : false);
    };

    return (
        <StyledContainer>
            {softInfo.name === "Software setup" && (
                <>
                    {softInfo.xero.isActive && (
                        <Accordion
                            defaultExpanded
                            TransitionProps={{ unmountOnExit: true }}
                            sx={{
                                borderBottom: "3px solid #ff5968",
                            }}
                        >
                            <AccordionSummary aria-controls="panelAuxbh-content" id="panelAuxbh-header">
                                <Typography variant="h4">
                                    {softInfo.xero.package === 1
                                        ? t(isBook ? "proposals.soWXeroBasicB" : "proposals.soWXeroBasic")
                                        : softInfo.xero.package === 2
                                            ? t(isBook ? "proposals.soWXeroProB" : "proposals.soWXeroPro")
                                            : softInfo.xero.package === 3
                                                ? t(isBook ? "proposals.soWXeroAdvB" : "proposals.soWXeroAdv")
                                                : t(isBook ? "proposals.soWXeroCustomB" : "proposals.soWXeroCustom")}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Divider sx={{ mb: 2 }} />
                                <Grid container spacing={3} alignItems="center">
                                    <Grid item xs={12} md={4}>
                                        <Typography variant="body1">{isBook ? t("proposals.soWXeroExpB") : t("proposals.soWXeroExp")}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <Typography variant="body1">{isBook ? t("proposals.soWXeroConsulB") : t("proposals.soWXeroConsul")}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={2} alignItems="center">
                                            {(props.includeAccModuleSet && !props.accountantOnboarding) &&
                                                (<Grid item xs={12}>
                                                    <Accordion
                                                        expanded={expandedSub === "Config contable"}
                                                        onChange={handleChangeAccSub("Config contable")}
                                                        TransitionProps={{
                                                            unmountOnExit: true,
                                                        }}
                                                        sx={{ pt: 1 }}
                                                    >
                                                        <AccordionSummary
                                                            aria-controls="panelAuxbh-content"
                                                            id="panelAuxbh-header"
                                                        >
                                                            <Grid container spacing={2} alignItems="center">
                                                                <Grid item xs={12} md={4}>
                                                                    <Typography variant="body1">
                                                                        {isBook ? t("proposals.accModelSetB") : t("proposals.accModelSet")}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={12} md={8}>
                                                                    <Typography variant="body1" sx={{ pl: "10px" }}>
                                                                        {t(isBook ? "proposals.impleProcBookB" : "proposals.impleProcBook") + " "}
                                                                        {softInfo.xero.package === 3
                                                                            ? t(isBook ? "proposals.until18B" : "proposals.until18")
                                                                            : softInfo.xero.package === 1
                                                                                ? ""
                                                                                : t(isBook ? "proposals.until14B" : "proposals.until14")}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <Divider sx={{ mb: 2 }} />
                                                            <Box sx={{ p: "0 30px" }}>
                                                                <Grid container spacing={2} alignItems="center">
                                                                    <Grid item xs={12} md={4}>
                                                                        <Typography variant="body1">
                                                                            {isBook ? t("proposals.planAccB") : t("proposals.planAcc")}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={12} md={8}>
                                                                        <Typography variant="body1">
                                                                            {isBook ? t("proposals.planAccDetB") : t("proposals.planAccDet")}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={12} md={4}>
                                                                        <Typography variant="body1">
                                                                            {isBook ? t("proposals.converSalB") : t("proposals.converSal")}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={12} md={8}>
                                                                        <Typography variant="body1">
                                                                            {isBook ? t("proposals.regSaldB") : t("proposals.regSald")}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={12} md={4}>
                                                                        <Typography variant="body1">
                                                                            {isBook ? t("proposals.banksB") : t("miscellaneous.banks")}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={12} md={8}>
                                                                        <Typography variant="body1">
                                                                            {isBook ? t("proposals.banksRegB") : t("proposals.banksReg")}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={12} md={4}>
                                                                        <Typography variant="body1">
                                                                            {isBook ? t("proposals.actFijB") : t("proposals.actFij")}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={12} md={8}>
                                                                        <Typography variant="body1">
                                                                            {isBook ? t("proposals.fixedAssetsB") : t("proposals.fixedAssets")}
                                                                        </Typography>
                                                                    </Grid>
                                                                    {softInfo.xero.package <= 2 && (
                                                                        <Grid item xs={12} md={4}>
                                                                            <Typography variant="body1">
                                                                                {isBook ? t("proposals.multiCoinB") : t("proposals.multiCoin")}
                                                                            </Typography>
                                                                        </Grid>
                                                                    )}
                                                                    {softInfo.xero.package <= 2 && (
                                                                        <Grid item xs={12} md={8}>
                                                                            <Typography variant="body1">
                                                                                {isBook ? t("proposals.currenSetB") : t("proposals.currenSet")}
                                                                            </Typography>
                                                                        </Grid>
                                                                    )}
                                                                </Grid>
                                                                <Divider
                                                                    sx={{
                                                                        mt: 2,
                                                                        backgroundColor: "#ff5968",
                                                                    }}
                                                                />
                                                            </Box>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </Grid>
                                                )}
                                            {
                                                softInfo.name === "Software setup" && softInfo.xero.package === 1 && props.includeAdmModuleSet && (
                                                    <Grid item xs={12}>
                                                        <Grid container spacing={2} alignItems="center">
                                                            <Grid item xs={12} md={4}>
                                                                <Typography variant="body1">
                                                                    {isBook ? t("proposals.configAdminB") : t("proposals.configAdmin")}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12} md={8}>
                                                                <Typography variant="body1" sx={{ pl: "3px" }}>
                                                                    {isBook ? t("proposals.configAdminDetB") : t("proposals.configAdminDet")}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                )
                                            }
                                            {(softInfo.xero.package === 2 ||
                                                softInfo.xero.package === 3 ||
                                                softInfo.xero.package === 4) &&
                                                props.includeAdmModuleSet && (
                                                    <Grid item xs={12}>
                                                        <Accordion
                                                            expanded={expandedSub === "Config admin"}
                                                            onChange={handleChangeAccSub("Config admin")}
                                                            TransitionProps={{
                                                                unmountOnExit: true,
                                                            }}
                                                            sx={{ pt: 1 }}
                                                        >
                                                            <AccordionSummary
                                                                aria-controls="panelAuxbh-content"
                                                                id="panelAuxbh-header"
                                                            >
                                                                <Grid container spacing={2} alignItems="center">
                                                                    <Grid item xs={12} md={4}>
                                                                        <Typography variant="body1">
                                                                            {isBook ? t("proposals.configAdminB") : t("proposals.configAdmin")}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={12} md={8}>
                                                                        <Typography variant="body1" sx={{ pl: "10px" }}>
                                                                            {t(isBook ? "proposals.configAdminDetB" : "proposals.configAdminDet") + " "}
                                                                            {softInfo.xero.package === 3
                                                                                ? t(isBook ? t("proposals.upTo24B") : "proposals.upTo24")
                                                                                : (softInfo.xero.package === 4)
                                                                                    ? ""
                                                                                    : t(isBook ? t("proposals.upTo14B") : "proposals.upTo14")}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </AccordionSummary>
                                                            <AccordionDetails>
                                                                <Divider sx={{ mb: 2 }} />
                                                                <Box sx={{ p: "0 30px" }}>
                                                                    <Grid container spacing={2} alignItems="center">
                                                                        <Grid item xs={12} md={4}>
                                                                            <Typography variant="body1">
                                                                                {isBook ? t("proposals.arAdd2B") : t("proposals.arAdd2")}
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item xs={12} md={8}>
                                                                            <Typography variant="body1">
                                                                                {isBook ? t("proposals.ARDetB") : t("proposals.ARDet")}
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item xs={12} md={4}>
                                                                            <Typography variant="body1">
                                                                                {isBook ? t("proposals.apAdd2B") : t("proposals.apAdd2")}
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item xs={12} md={8}>
                                                                            <Typography variant="body1">
                                                                                {isBook ? t("proposals.APDetB") : t("proposals.APDet")}
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item xs={12} md={4}>
                                                                            <Typography variant="body1">
                                                                                {isBook ? t("proposals.inventB") : t("proposals.invent")}
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item xs={12} md={8}>
                                                                            <Typography variant="body1">
                                                                                {isBook ? t("proposals.inventDetB") : t("proposals.inventDet")}
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item xs={12} md={4}>
                                                                            <Typography variant="body1">
                                                                                {isBook ? t("proposals.appIntegB") : t("proposals.appInteg")}
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item xs={12} md={8}>
                                                                            <Typography variant="body1">
                                                                                {isBook ? t("proposals.appIntegDetB") : t("proposals.appIntegDet")}
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Divider
                                                                        sx={{
                                                                            mt: 2,
                                                                            backgroundColor: "#ff5968",
                                                                        }}
                                                                    />
                                                                </Box>
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    </Grid>
                                                )}
                                            <Grid item xs={12}>
                                                <Accordion
                                                    expanded={expandedSub === "Support xero"}
                                                    onChange={handleChangeAccSub("Support xero")}
                                                    TransitionProps={{
                                                        unmountOnExit: true,
                                                    }}
                                                    sx={{ pt: 1 }}
                                                >
                                                    <AccordionSummary
                                                        aria-controls="panelAuxbh-content"
                                                        id="panelAuxbh-header"
                                                    >
                                                        <Grid container spacing={2} alignItems="center">
                                                            <Grid item xs={12} md={4}>
                                                                <Typography variant="body1">
                                                                    {isBook ? t("proposals.supportB") : t("proposals.support")}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Divider sx={{ mb: 2 }} />
                                                        <Box sx={{ p: "0 30px" }}>
                                                            <Grid container spacing={2} alignItems="center">
                                                                <Grid item xs={12} md={4}>
                                                                    <Typography variant="body1">
                                                                        {isBook ? t("proposals.capaB") : t("proposals.capa")}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={12} md={8}>
                                                                    <Typography variant="body1">
                                                                        {t(isBook ? "proposals.trainingDetB" : "proposals.trainingDet") + " "}
                                                                        {softInfo.xero.package === 1
                                                                            ? t(isBook ? "proposals.user4hoursB" : "proposals.user4hours")
                                                                            : softInfo.xero.package === 2
                                                                                ? t(isBook ? "proposals.user8hoursB" : "proposals.user8hours")
                                                                                : softInfo.xero.package === 3
                                                                                    ? t(isBook ? "proposals.user12hoursB" : "proposals.user12hours")
                                                                                    : ""}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={12} md={4}>
                                                                    <Typography variant="body1">
                                                                        {isBook ? t("proposals.followB") : t("proposals.follow")}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={12} md={8}>
                                                                    <Typography variant="body1">
                                                                        {t(isBook ? "proposals.followDetB" : "proposals.followDet") + " "}
                                                                        {softInfo.xero.package === 1
                                                                            ? t(isBook ? "proposals.2hours1monthB" : "proposals.2hours1month")
                                                                            : softInfo.xero.package === 2
                                                                                ? t(isBook ? "proposals.4hours1monthB" : "proposals.4hours1month")
                                                                                : softInfo.xero.package === 3
                                                                                    ? t(isBook ? "proposals.6hours2monthB" : "proposals.6hours2month")
                                                                                    : ""}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    )}
                    {softInfo.banking.isActive && (
                        <Accordion
                            defaultExpanded
                            TransitionProps={{ unmountOnExit: true }}
                            sx={{
                                borderBottom: "3px solid #ff5968",
                            }}
                        >
                            <AccordionSummary aria-controls="panelAuxbh-content" id="panelAuxbh-header">
                                <Typography variant="h4">
                                    {" "}
                                    {softInfo.banking.package === 1
                                        ? t(isBook ? "proposals.kiiperBankBasicB" : "proposals.kiiperBankBasic")
                                        : softInfo.banking.package === 2
                                            ? t(isBook ? "proposals.kiiperBankProB" : "proposals.kiiperBankPro")
                                            : softInfo.banking.package === 3
                                                ? t(isBook ? "proposals.kiiperBankAdvB" : "proposals.kiiperBankAdv")
                                                : t(isBook ? "proposals.kiiperBankCustomB" : "proposals.kiiperBankCustom")}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Divider sx={{ mb: 2 }} />
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={12} md={4}>
                                        <Typography variant="body1">{isBook ? t("proposals.kiiperDedExpB") : t("proposals.kiiperDedExp")}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <Typography variant="body1">
                                            {softInfo.banking.package === 1
                                                ? t(isBook ? "proposals.kiiperBankConsBasicB" : "proposals.kiiperBankConsBasic")
                                                : t(isBook ? "proposals.kiiperTaxExpertDetB" : "proposals.kiiperTaxExpertDet")}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Typography variant="body1">{isBook ? t("proposals.bankingAccSetB") : t("proposals.bankingAccSet")}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <Typography variant="body1">
                                            {t(isBook ? "proposals.bankingAccSetDetB" : "proposals.bankingAccSetDet") +
                                                " " +
                                                (softInfo.banking.package === 1
                                                    ? t(isBook ? "proposals.upToAcc10B" : "proposals.upToAcc10")
                                                    : softInfo.banking.package === 2
                                                        ? t(isBook ? "proposals.upToAcc30B" : "proposals.upToAcc30")
                                                        : softInfo.banking.package === 3
                                                            ? t(isBook ? "proposals.upToAcc50B" : "proposals.upToAcc50")
                                                            : "")}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Typography variant="body1">{isBook ? t("proposals.bankingStatDevB") : t("proposals.bankingStatDev")}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <Typography variant="body1">
                                            {t(isBook ? "proposals.bankingStatDevDetB" : "proposals.bankingStatDevDet") +
                                                " " +
                                                (softInfo.banking.package === 1
                                                    ? t(isBook ? "proposals.bankStatDevDetBasicB" : "proposals.bankStatDevDetBasic")
                                                    : softInfo.banking.package === 2
                                                        ? t(isBook ? "proposals.bankStatDevDetProf2B" : "proposals.bankStatDevDetProf2")
                                                        : softInfo.banking.package === 3
                                                            ? t(isBook ? "proposals.bankStatDevDetAdv2B" : "proposals.bankStatDevDetAdv2")
                                                            : "")}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Accordion
                                            expanded={expandedSub === "Support banking"}
                                            onChange={handleChangeAccSub("Support banking")}
                                            TransitionProps={{
                                                unmountOnExit: true,
                                            }}
                                        >
                                            <AccordionSummary aria-controls="panelAuxbh-content" id="panelAuxbh-header">
                                                <Grid container spacing={2} alignItems="center">
                                                    <Grid item xs={12} md={4}>
                                                        <Typography variant="body1">
                                                            {isBook ? t("proposals.supportB") : t("proposals.support")}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Divider sx={{ mb: 2 }} />
                                                <Box sx={{ p: "0 30px" }}>
                                                    <Grid container spacing={2} alignItems="center">
                                                        <Grid item xs={12} md={4}>
                                                            <Typography variant="body1">
                                                                {isBook ? t("proposals.capaB") : t("proposals.capa")}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} md={8}>
                                                            <Typography variant="body1">
                                                                {t(isBook ? "proposals.trainingDetB" : "proposals.trainingDet") + " "}
                                                                {softInfo.banking.package === 1
                                                                    ? t(isBook ? "proposals.user1hoursB" : "proposals.user1hours")
                                                                    : softInfo.banking.package === 2
                                                                        ? t(isBook ? "proposals.2user2hoursB" : "proposals.2user2hours")
                                                                        : softInfo.banking.package === 3
                                                                            ? t(isBook ? "proposals.3user3hoursB" : "proposals.3user3hours")
                                                                            : ""}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} md={4}>
                                                            <Typography variant="body1">
                                                                {isBook ? t("proposals.followB") : t("proposals.follow")}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} md={8}>
                                                            <Typography variant="body1">
                                                                {t(isBook ? "proposals.followDetB" : "proposals.followDet") + " "}
                                                                {softInfo.banking.package === 1
                                                                    ? t(isBook ? "proposals.1hours1monthB" : "proposals.1hours1month")
                                                                    : softInfo.banking.package === 2
                                                                        ? t(isBook ? "proposals.2hours1monthB" : "proposals.2hours1month")
                                                                        : softInfo.banking.package === 3
                                                                            ? t(isBook ? "proposals.3hours1monthB" : "proposals.3hours1month")
                                                                            : ""}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    )}
                    {softInfo.taxes.isActive && (
                        <Accordion
                            defaultExpanded
                            TransitionProps={{ unmountOnExit: true }}
                            sx={{
                                borderBottom: "3px solid #ff5968",
                            }}
                        >
                            <AccordionSummary aria-controls="panelAuxbh-content" id="panelAuxbh-header">
                                <Typography variant="h4">
                                    {softInfo.taxes.package === 1
                                        ? t(isBook ? "proposals.setupTaxesBasicB" : "proposals.setupTaxesBasic")
                                        : softInfo.taxes.package === 2
                                            ? t(isBook ? "proposals.setupTaxesProB" : "proposals.setupTaxesPro")
                                            : t(isBook ? "proposals.setupTaxesCustomB" : "proposals.setupTaxesCustom")}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Divider sx={{ mb: 2 }} />
                                <Grid container spacing={3} alignItems="center">
                                    <Grid item xs={12} md={4}>
                                        <Typography variant="body1">{isBook ? t("proposals.kiiperDedExpB") : t("proposals.kiiperDedExp")}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <Typography variant="body1">{isBook ? t("proposals.kiiperTaxExpertDetB") : t("proposals.kiiperTaxExpertDet")}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Typography variant="body1">{isBook ? t("proposals.orgSetupKiiperB") : t("proposals.orgSetupKiiper")}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <Typography variant="body1">{isBook ? t("proposals.orgSetupKiiperDetB") : t("proposals.orgSetupKiiperDet")}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Typography variant="body1">{isBook ? t("proposals.taxAuthorityB") : t("proposals.taxAuthority")}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <Typography variant="body1">{isBook ? t("proposals.taxAuthorityDetB") : t("proposals.taxAuthorityDet")}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Typography variant="body1">{isBook ? t("proposals.taxCodesB") : t("proposals.taxCodes")}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <Typography variant="body1">{isBook ? t("proposals.taxCodesDetB") : t("proposals.taxCodesDet")}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Accordion
                                            expanded={expandedSub === "Support taxes"}
                                            onChange={handleChangeAccSub("Support taxes")}
                                            TransitionProps={{
                                                unmountOnExit: true,
                                            }}
                                        >
                                            <AccordionSummary aria-controls="panelAuxbh-content" id="panelAuxbh-header">
                                                <Grid container spacing={2} alignItems="center">
                                                    <Grid item xs={12} md={4}>
                                                        <Typography variant="body1">
                                                            {isBook ? t("proposals.supportB") : t("proposals.support")}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Divider sx={{ mb: 2 }} />
                                                <Box sx={{ p: "0 30px" }}>
                                                    <Grid container spacing={2} alignItems="center">
                                                        <Grid item xs={12} md={4}>
                                                            <Typography variant="body1">
                                                                {isBook ? t("proposals.capaB") : t("proposals.capa")}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} md={8}>
                                                            <Typography variant="body1">
                                                                {t(isBook ? "proposals.trainingDetB" : "proposals.trainingDet") + " "}
                                                                {softInfo.taxes.package === 1
                                                                    ? t(isBook ? "proposals.user1hoursB" : "proposals.user1hours")
                                                                    : softInfo.taxes.package === 2
                                                                        ? t(isBook ? "proposals.user2hoursB" : "proposals.user2hours")
                                                                        : ""}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} md={4}>
                                                            <Typography variant="body1">
                                                                {isBook ? t("proposals.followB") : t("proposals.follow")}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} md={8}>
                                                            <Typography variant="body1">
                                                                {t(isBook ? "proposals.followDetB" : "proposals.followDet") + " "}
                                                                {softInfo.taxes.package === 1
                                                                    ? t(isBook ? "proposals.2hours1monthB" : "proposals.2hours1month")
                                                                    : softInfo.taxes.package === 2
                                                                        ? t(isBook ? "proposals.3hours1monthB" : "proposals.3hours1month")
                                                                        : ""}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    )}
                </>
            )}
            {softInfo.name === "Software subscription" && (
                <>
                    {softInfo.xero.isActive && (
                        <Accordion
                            defaultExpanded
                            TransitionProps={{ unmountOnExit: true }}
                            sx={{
                                borderBottom: "3px solid #ff5968",
                            }}
                        >
                            <AccordionSummary aria-controls="panelAuxbh-content" id="panelAuxbh-header">
                                <Typography variant="h4">{"Xero " + softInfo.xero.version.split(" ")[0]}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Divider sx={{ mb: 2 }} />
                                <Grid container spacing={3} alignItems="center">
                                    <Grid item xs={12} md={4}>
                                        <Typography variant="body1">{isBook ? t("proposals.soWUnlimB") : t("proposals.soWUnlim")}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <Typography variant="body1">{isBook ? t("proposals.soWUnlimDetB") : t("proposals.soWUnlimDet")}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Typography variant="body1">{isBook ? t("proposals.bankRecB") : t("proposals.bankRec")}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <Typography variant="body1">{isBook ? t("proposals.bankRecDetB") : t("proposals.bankRecDet")}</Typography>
                                    </Grid>
                                    {softInfo.xero.version !== "Ledger" && (
                                        <Grid item xs={12} md={4}>
                                            <Typography variant="body1">{isBook ? t("proposals.dailyBankFeedsB") : t("proposals.dailyBankFeeds")}</Typography>
                                        </Grid>
                                    )}
                                    {softInfo.xero.version !== "Ledger" && (
                                        <Grid item xs={12} md={8}>
                                            <Typography variant="body1">{isBook ? t("proposals.dailBankFeedDetB") : t("proposals.dailBankFeedDet")}</Typography>
                                        </Grid>
                                    )}
                                    {softInfo.xero.version !== "Ledger" && softInfo.xero.version !== "Cashbook" && (
                                        <Grid item xs={12} md={4}>
                                            <Typography variant="body1">{isBook ? t("proposals.unlimInvoB") : t("proposals.unlimInvo")}</Typography>
                                        </Grid>
                                    )}
                                    {softInfo.xero.version !== "Ledger" && softInfo.xero.version !== "Cashbook" && (
                                        <Grid item xs={12} md={8}>
                                            <Typography variant="body1">{isBook ? t("proposals.unlimInvoDetB") : t("proposals.unlimInvoDet")}</Typography>
                                        </Grid>
                                    )}
                                    {softInfo.xero.version !== "Ledger" && softInfo.xero.version !== "Cashbook" && (
                                        <Grid item xs={12} md={4}>
                                            <Typography variant="body1">{isBook ? t("proposals.unlimBillB") : t("proposals.unlimBill")}</Typography>
                                        </Grid>
                                    )}
                                    {softInfo.xero.version !== "Ledger" && softInfo.xero.version !== "Cashbook" && (
                                        <Grid item xs={12} md={8}>
                                            <Typography variant="body1">{isBook ? t("proposals.unlimBillDetB") : t("proposals.unlimBillDet")}</Typography>
                                        </Grid>
                                    )}
                                    {(softInfo.xero.version === "Established US" ||
                                        softInfo.xero.version === "Premium GB") && (
                                            <Grid item xs={12} md={4}>
                                                <Typography variant="body1">{isBook ? t("proposals.multiCoinB") : t("proposals.multiCoin")}</Typography>
                                            </Grid>
                                        )}
                                    {(softInfo.xero.version === "Established US" ||
                                        softInfo.xero.version === "Premium GB") && (
                                            <Grid item xs={12} md={8}>
                                                <Typography variant="body1">{isBook ? t("proposals.multiCoinDetB") : t("proposals.multiCoinDet")}</Typography>
                                            </Grid>
                                        )}
                                    <Grid item xs={12} md={4}>
                                        <Typography variant="body1">{isBook ? t("proposals.fixedAssB") : t("proposals.fixedAss")}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <Typography variant="body1">{isBook ? t("proposals.fixedAssDetB") : t("proposals.fixedAssDet")}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Typography variant="body1">{isBook ? t("proposals.manualJourB") : t("proposals.manualJour")}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <Typography variant="body1">{isBook ? t("proposals.manualJourDetB") : t("proposals.manualJourDet")}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Typography variant="body1">{isBook ? t("proposals.userAccProB") : t("proposals.userAccPro")}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <Typography variant="body1">
                                            {softInfo.xero.version === "Ledger" || softInfo.xero.version === "Cashbook"
                                                ? t(isBook ? "proposals.userAccProDetB" : "proposals.userAccProDet") + " " + t(isBook ? "proposals.1userB" : "proposals.1user")
                                                : t(isBook ? "proposals.usersAccessProfiles2DetB" : "proposals.usersAccessProfiles2Det") +
                                                " " +
                                                t(isBook ? "proposals.unlimitedUserB" : "proposals.unlimitedUser")}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    )}
                    {softInfo.banking.isActive && (
                        <Accordion
                            defaultExpanded
                            TransitionProps={{ unmountOnExit: true }}
                            sx={{
                                borderBottom: "3px solid #ff5968",
                            }}
                        >
                            <AccordionSummary aria-controls="panelAuxbh-content" id="panelAuxbh-header">
                                <Typography variant="h4">{"Kiiper Banking " + softInfo.banking.version}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Divider sx={{ mb: 2 }} />
                                <Grid container spacing={3} alignItems="center">
                                    <Grid item xs={12} md={4}>
                                        <Typography variant="body1">{isBook ? t("proposals.bankStateConB") : t("proposals.bankStateCon")}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <Typography variant="body1">
                                            {t(isBook ? "proposals.bankStateConDetB" : "proposals.bankStateConDet") +
                                                (softInfo.banking.version === "Basic"
                                                    ? t(isBook ? "proposals.upToAcc10B" : "proposals.upToAcc10")
                                                    : softInfo.banking.version === "Professional"
                                                        ? t(isBook ? "proposals.upToAcc30B" : "proposals.upToAcc30")
                                                        : t(isBook ? "proposals.upToAcc50B" : "proposals.upToAcc50"))}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Typography variant="body1">{isBook ? t("proposals.bankRecMonB") : t("proposals.bankRecMon")}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <Typography variant="body1">{isBook ? t("proposals.bankRecMonDetB") : t("proposals.bankRecMonDet")}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Typography variant="body1">{isBook ? t("proposals.userAccProB") : t("proposals.userAccPro")}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <Typography variant="body1">
                                            {t(isBook ? "proposals.userAccProDet2B" : "proposals.userAccProDet2") +
                                                (softInfo.banking.version === "Basic"
                                                    ? t(isBook ? "proposals.1userB" : "proposals.1user")
                                                    : softInfo.banking.version === "Professional"
                                                        ? t(isBook ? "proposals.2userB" : "proposals.2user")
                                                        : t(isBook ? "proposals.3userB" : "proposals.3user"))}
                                        </Typography>
                                    </Grid>
                                    {softInfo.banking.version === "Advanced" && (
                                        <Grid item xs={12} md={4}>
                                            <Typography variant="body1">{isBook ? t("proposals.writtenSupB") : t("proposals.writtenSup")}</Typography>
                                        </Grid>
                                    )}
                                    {softInfo.banking.version === "Advanced" && (
                                        <Grid item xs={12} md={8}>
                                            <Typography variant="body1">{isBook ? t("proposals.UnlimSupB") : t("proposals.UnlimSup")}</Typography>
                                        </Grid>
                                    )}
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    )}
                    {softInfo.taxes.isActive && (
                        <Accordion
                            defaultExpanded
                            TransitionProps={{ unmountOnExit: true }}
                            sx={{
                                borderBottom: "3px solid #ff5968",
                            }}
                        >
                            <AccordionSummary aria-controls="panelAuxbh-content" id="panelAuxbh-header">
                                <Typography variant="h4">{"Kiiper Taxes " + softInfo.taxes.version}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Divider sx={{ mb: 2 }} />
                                <Grid container spacing={3} alignItems="center">
                                    <Grid item xs={12} md={4}>
                                        <Typography variant="body1">{isBook ? t("proposals.withIncTaxB") : t("proposals.withIncTax")}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <Typography variant="body1">{isBook ? t("proposals.withIncTaxDetB") : t("proposals.withIncTaxDet")}</Typography>
                                    </Grid>
                                    {softInfo.taxes.version === "Professional" && (
                                        <>
                                            <Grid item xs={12} md={4}>
                                                <Typography variant="body1">{isBook ? t("proposals.vatReceiptsB") : t("proposals.vatReceipts")}</Typography>
                                            </Grid>
                                            <Grid item xs={12} md={8}>
                                                <Typography variant="body1">{isBook ? t("proposals.withIncTaxDetB") : t("proposals.withIncTaxDet")}</Typography>
                                            </Grid>
                                        </>
                                    )}
                                    <Grid item xs={12} md={4}>
                                        <Typography variant="body1">{isBook ? t("proposals.withIncTaxFillB") : t("proposals.withIncTaxFill")}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <Typography variant="body1">{isBook ? t("proposals.withIncTaxFillDetB") : t("proposals.withIncTaxFillDet")}</Typography>
                                    </Grid>
                                    {softInfo.taxes.version === "Professional" && (
                                        <>
                                            <Grid item xs={12} md={4}>
                                                <Typography variant="body1">{isBook ? t("proposals.varFillingsB") : t("proposals.varFillings")}</Typography>
                                            </Grid>
                                            <Grid item xs={12} md={8}>
                                                <Typography variant="body1">
                                                    {isBook ? t("proposals.withIncTaxFillDetB") : t("proposals.withIncTaxFillDet")}
                                                </Typography>
                                            </Grid>
                                        </>
                                    )}
                                    <Grid item xs={12} md={4}>
                                        <Typography variant="body1">{isBook ? t("proposals.vatFillB") : proposalDetails[0]?.prospect?.country_name == "LATAM" || proposalDetails?.reference?.includes("LA-") ? t("proposals.vatFillLATAM") : t("proposals.vatFill")}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <Typography variant="body1">{isBook ? t("proposals.withIncTaxFillDetB") : t("proposals.withIncTaxFillDet")}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Typography variant="body1">{isBook ? t("proposals.taxBooksB") : t("proposals.taxBooks")}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <Typography variant="body1">{isBook ? t("proposals.taxBooksDetB") : t("proposals.taxBooksDet")}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Typography variant="body1">{isBook ? t("proposals.legalBookB") : t("proposals.legalBook")}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <Typography variant="body1">{isBook ? t("proposals.legalBookDetB") : t("proposals.legalBookDet")}</Typography>
                                    </Grid>
                                    {softInfo.taxes.version === "Professional" && (
                                        <>
                                            <Grid item xs={12} md={4}>
                                                <Typography variant="body1">{isBook ? t("proposals.writtenSupB") : t("proposals.writtenSup")}</Typography>
                                            </Grid>
                                            <Grid item xs={12} md={8}>
                                                <Typography variant="body1">{isBook ? t("proposals.UnlimSupB") : t("proposals.UnlimSup")}</Typography>
                                            </Grid>
                                        </>
                                    )}
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    )}
                </>
            )}
        </StyledContainer>
    );
};
