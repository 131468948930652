/* React imports */
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

/* MUI Imports */
import { styled } from "@mui/material/styles";
import {
    Dialog,
    DialogTitle,
    DialogActions,
    Grid,
    DialogContent,
    List,
    ListItemButton,
    Divider,
    ListItemText,
    Button,
    Typography
} from "@mui/material";

/* Import components */
import UserDataContent from "./UserDataContent";

const CustomDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(4),
    },
    "& .MuiDialogTitle-root": {
        backgroundColor: "#031851",
        fontSize: "18px",
        color: "#fff",
    },
    "& .MuiDialog-paper": {
        borderRadius: 8,
        boxShadow: "10px 10px 15px rgba(3,24,81,0.15)",
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(2),
    },
}));

const ColorButton = styled(Button)(({ theme }) => ({
    border: "1px solid #2f3190",
    color: "#2f3190",
    borderRadius: 40,
}));

export default function DialogUserProfile({ open, onClose, setAlertType, setTexto, setShow, setBackDrop, usersAvatar }) {
    /* Internationalization i18n */
    const [t] = useTranslation("global");

    /* React redux */
    const todos = useSelector((state) => state.todos);

    const optionsMenuList = [
        { id: 0, data: t("principal.userData") },
    ];

    /* React States */
    const [menuOptions] = useState(optionsMenuList);
    const [menuOptionSelected, setMenuOptionSelected] = useState(0);

    /* Component functions */
    const handleChangeMenuOption = (event, index) => {
        setMenuOptionSelected(index);
    };

    const handleCloseDialog = () => {
        onClose();
        setMenuOptionSelected(0);
    };

    return (
        <CustomDialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="md"
        >
            <DialogTitle id="alert-dialog-title">
                <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item>
                        {t("principal.profile")}
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <div style={{ display: 'flex', marginLeft: -20, marginBottom: -32 }}>
                    <Grid sx={{ width: '20%' }}>
                        <Grid item spacing={5} >

                            <List id="split-button-menu" autoFocusItem sx={{
                                padding: 0,
                                margin: 0,
                                top: 5,
                                ml: 0,
                                alignItems: "left"
                            }}>
                                {menuOptions.map(({ id, data }, index) => (
                                    <ListItemButton
                                        key={id + data}
                                        selected={index === menuOptionSelected}
                                        onClick={(event) =>
                                            handleChangeMenuOption(event, index)
                                        }
                                        sx={{
                                            padding: 0,
                                            marginTop: 0,
                                            marginLeft: 0,
                                            "&.Mui-selected": {
                                                backgroundColor: "transparent",
                                            },
                                        }}
                                    >
                                        <ListItemText
                                            primary={
                                                <Typography
                                                    variant={"body1"}
                                                    sx={{
                                                        color: "#2f3190",
                                                        borderRight: menuOptionSelected === index ? "3px solid #ff5968" : "",
                                                    }}
                                                >
                                                    {data}
                                                </Typography>
                                            }
                                            sx={{
                                                "& .MuiTypography-root": {
                                                    fontWeight: menuOptionSelected === index ? "bold" : "inherit",
                                                },
                                            }}

                                        />
                                    </ListItemButton>
                                ))}
                            </List>
                        </Grid>
                    </Grid>
                    <Grid>
                        <Divider orientation="vertical" sx={{ display: "flex" }} />
                    </Grid>
                    <Grid sx={{ width: '80%' }}>

                        <Grid xs={8} spacing={1} ml={5}>
                            {menuOptionSelected === 0 &&
                                <UserDataContent
                                    userEmail={todos.userInfo.email}
                                    userName={todos.userInfo.user_name}
                                    position={todos.userInfo.position}
                                    role={todos.userInfo.role}
                                    setTexto={setTexto}
                                    setBackDrop={setBackDrop}
                                    setAlertType={setAlertType}
                                    usersAvatar={usersAvatar}
                                    setShow={setShow}
                                />
                            }
                        </Grid>
                    </Grid>
                </div>
            </DialogContent>
            <Divider />
            <DialogActions>
                <Grid container justifyContent="space-between" alignItems="start" spacing={2}>
                    <Grid item>
                        <ColorButton
                            sx={{
                                mr: 1,
                                "&:hover": {
                                    backgroundColor: "white",
                                    color: "#2f3190",
                                    border: "1px solid #2f3190",
                                },
                            }}
                            color="secondary"
                            disableElevation
                            variant="outlined"
                            onClick={handleCloseDialog}
                        >
                            {t("principal.return")}
                        </ColorButton>
                    </Grid>
                </Grid>
            </DialogActions>
        </CustomDialog>
    );
};
