/** React imports */
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

/** Images */

/** MUI icons */

/** MUI imports */
import { Box, Typography, Grid, Divider } from "@mui/material";
import { styled } from "@mui/material/styles";

/** Global variables */
const PREFIX = "PricingCont";

const classes = {
    body2: `${PREFIX}-body2`,
    serviceName: `${PREFIX}-serviceName`,
    subName: `${PREFIX}-subName`,
    serviceBody: `${PREFIX}-serviceBody`,
    subInfo: `${PREFIX}-subInfo`,
    roundBack: `${PREFIX}-roundBack`,
    roundBack2: `${PREFIX}-roundBack2`,
    roundBack3: `${PREFIX}-roundBack3`,
    months: `${PREFIX}-months`,
    monthsText1: `${PREFIX}-monthsText1`,
    monthsText2: `${PREFIX}-monthsText2`,
    body3: `${PREFIX}-body3`,
    textFoot: `${PREFIX}-textFoot`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.serviceName}`]: {
        fontSize: 22,
        marginBottom: 10,
    },

    [`& .${classes.subName}`]: {
        fontSize: 12,
        marginBottom: 10,
    },

    [`& .${classes.body2}`]: {
        backgroundColor: "white",
        marginTop: 15,
        padding: 30,
        minHeight: 850,
    },

    [`& .${classes.body3}`]: {
        padding: 30,
    },

    [`& .${classes.textFoot}`]: {
        padding: "15px 30px",
    },

    [`& .${classes.serviceBody}`]: {
        backgroundColor: "#F8F9F8",
        marginTop: 30,
        padding: 30,
        borderRadius: 30,
        overflow: "auto",
    },

    [`& .${classes.subInfo}`]: {
        marginTop: 30,
        fontSize: 12,
    },

    [`& .${classes.roundBack}`]: {
        backgroundColor: "#6D70AD",
        minWidth: "125px",
        marginRight: "-27px",
        justifyContent: "center",
        display: "flex",
    },

    [`& .${classes.roundBack2}`]: {
        backgroundColor: "#2D348B",
        minWidth: "130px",
        justifyContent: "center",
        display: "flex",
    },

    [`& .${classes.roundBack3}`]: {
        color: "#031851",
        minWidth: "115px",
        marginRight: "-25px",
        justifyContent: "center",
        display: "flex",
    },

    [`& .${classes.months}`]: {
        fontSize: 16,
    },
    [`& .${classes.monthsText1}`]: {
        fontSize: 16,
        justifyContent: "center",
        display: "flex",
    },
    [`& .${classes.monthsText2}`]: {
        fontSize: 16,
        justifyContent: "center",
        display: "flex",
        marginRight: "-25px",
    },
}));

// Función para convertir primera letra en mayúscula
function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const PricingCont = (props) => {
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    /** React redux */
    const todos = useSelector((state) => state.todos);

    

    const { pricingCont } = props;

    const getNamesAdd = (values) => {
        let name = "";
        if (values.additional_name === "Ejecución de planificación fiscal") {
            name = t("services.executionTaxPlan");
        } else if (values.additional_name === "Planificación de administración y contabilidad") {
            name = t("services.consolidationStatements");
        } else if (values.additional_name === "Reportes para auditores externos") {
            name = t("services.reportsAuditors");
        } else if (values.additional_name === "Others") {
            name = values.other_name;
        } else {
            name = values.additional_name;
        }

        return name;
    };

    return (
        <StyledContainer>
            {pricingCont.onboarding.isActive && (
                <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={2}
                    sx={{ paddingBottom: 1.5, paddingTop: 1.5 }}
                >
                    <Grid item xs={3}>
                        <Typography variant="h4">{t("proposals.onboard")}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant="body1" sx={{ textAlign: "center" }}>
                            {t(`proposals.oneTime2`)}
                        </Typography>
                    </Grid>
                    <Grid item xs={1} sx={{ textAlign: "center" }}>
                        <Typography variant="body1">1</Typography>
                    </Grid>
                    <Grid item xs={1} sx={{ textAlign: "right" }}>
                        <Typography variant="body1">
                            $
                            {parseFloat(pricingCont.onboarding.fee).toLocaleString(todos.amountFormat, {
                                maximumFractionDigits: 2,
                            })}
                        </Typography>
                    </Grid>
                    <Grid item xs={2} sx={{ textAlign: "right" }}>
                        <Typography variant="body1">
                            $
                            {parseFloat(pricingCont.onboarding.fee).toLocaleString(todos.amountFormat, {
                                maximumFractionDigits: 2,
                            })}
                        </Typography>
                    </Grid>
                    <Grid item xs={1} sx={{ textAlign: "center" }}>
                        <Typography variant="body1">
                            {parseFloat(pricingCont.onboarding.discount).toLocaleString(todos.amountFormat, {
                                maximumFractionDigits: 2,
                            })}
                            %
                        </Typography>
                    </Grid>
                    <Grid item xs={2} sx={{ textAlign: "right" }}>
                        <Typography variant="body1">
                            $
                            {parseFloat(
                                pricingCont.onboarding.fee * (1 - pricingCont.onboarding.discount / 100)
                            ).toLocaleString(todos.amountFormat, {
                                maximumFractionDigits: 2,
                            })}
                        </Typography>
                    </Grid>
                </Grid>
            )}
            {pricingCont.recurring.isActive && (
                <>
                    <Divider
                        sx={{
                            backgroundColor: "#031851",
                        }}
                    />
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={2}
                        sx={{ paddingBottom: 1.5, paddingTop: 1.5 }}
                    >
                        <Grid item xs={3}>
                            <Typography variant="h4">
                                {t("proposals.recurring") +
                                    ` (${capitalize(pricingCont.recurring.initDate.format("MMM YYYY")).replace(
                                        /\./g,
                                        ""
                                    )})`}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="body1" sx={{ textAlign: "center" }}>
                                {pricingCont.recurring.payAnnually
                                    ? t(`proposals.oneTime2`)
                                    : t("miscellaneous.monthly")}
                            </Typography>
                        </Grid>
                        <Grid item xs={1} sx={{ textAlign: "center" }}>
                            <Typography variant="body1">
                                {pricingCont.recurring.additionals.length > 0 && !pricingCont.recurring.onlyAdd
                                    ? "-"
                                    : pricingCont.recurring.payAnnually
                                    ? 12
                                    : pricingCont.recurring.qty}
                            </Typography>
                        </Grid>
                        <Grid item xs={1} sx={{ textAlign: "right" }}>
                            <Typography variant="body1">
                                {pricingCont.recurring.additionals.length > 0 && !pricingCont.recurring.onlyAdd
                                    ? "-"
                                    : "$" +
                                      parseFloat(pricingCont.recurring.fee).toLocaleString(todos.amountFormat, {
                                          maximumFractionDigits: 2,
                                      })}
                            </Typography>
                        </Grid>
                        <Grid item xs={2} sx={{ textAlign: "right" }}>
                            <Typography variant="body1">
                                {pricingCont.recurring.additionals.length > 0 && !pricingCont.recurring.onlyAdd
                                    ? "-"
                                    : "$" +
                                      parseFloat(
                                          pricingCont.recurring.payAnnually
                                              ? pricingCont.recurring.fee * 12
                                              : pricingCont.recurring.fee * pricingCont.recurring.qty
                                      ).toLocaleString(todos.amountFormat, {
                                          maximumFractionDigits: 2,
                                      })}
                            </Typography>
                        </Grid>
                        <Grid item xs={1} sx={{ textAlign: "center" }}>
                            <Typography variant="body1">
                                {pricingCont.recurring.additionals.length > 0 && !pricingCont.recurring.onlyAdd
                                    ? "-"
                                    : parseFloat(pricingCont.recurring.discount).toLocaleString(todos.amountFormat, {
                                          maximumFractionDigits: 2,
                                      }) + "%"}
                            </Typography>
                        </Grid>
                        <Grid item xs={2} sx={{ textAlign: "right" }}>
                            <Typography variant="body1">
                                $
                                {parseFloat(
                                    (pricingCont.recurring.payAnnually
                                        ? pricingCont.recurring.fee * 12 +
                                          pricingCont.recurring.recuHours * pricingCont.recurring.addFee
                                        : pricingCont.recurring.fee * pricingCont.recurring.qty +
                                          pricingCont.recurring.recuHours * pricingCont.recurring.addFee) *
                                        (1 - pricingCont.recurring.discount / 100)
                                ).toLocaleString(todos.amountFormat, {
                                    maximumFractionDigits: 2,
                                })}
                            </Typography>
                        </Grid>
                    </Grid>
                    {pricingCont.recurring.additionals.length > 0 && !pricingCont.recurring.onlyAdd && (
                        <>
                            <Divider
                                sx={{
                                    backgroundColor: "#031851",
                                }}
                            />
                            <Grid
                                container
                                alignItems="center"
                                justifyContent="space-between"
                                spacing={2}
                                sx={{ paddingBottom: 1.5, paddingTop: 1.5 }}
                            >
                                <Grid item xs={3}>
                                    <Typography variant="body1" sx={{ paddingLeft: 6, wordBreak: "break-word" }}>
                                        {t("proposals.coreServ")}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}></Grid>
                                <Grid item xs={1} sx={{ textAlign: "center" }}>
                                    <Typography variant="body1">
                                        {pricingCont.recurring.payAnnually ? 12 : pricingCont.recurring.qty}
                                    </Typography>
                                </Grid>
                                <Grid item xs={1} sx={{ textAlign: "right" }}>
                                    <Typography variant="body1">
                                        $
                                        {parseFloat(pricingCont.recurring.fee).toLocaleString(todos.amountFormat, {
                                            maximumFractionDigits: 2,
                                        })}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2} sx={{ textAlign: "right" }}>
                                    <Typography variant="body1">
                                        $
                                        {parseFloat(
                                            pricingCont.recurring.payAnnually
                                                ? pricingCont.recurring.fee * 12
                                                : pricingCont.recurring.fee * pricingCont.recurring.qty
                                        ).toLocaleString(todos.amountFormat, {
                                            maximumFractionDigits: 2,
                                        })}
                                    </Typography>
                                </Grid>
                                <Grid item xs={1} sx={{ textAlign: "center" }}>
                                    <Typography variant="body1">
                                        {parseFloat(pricingCont.recurring.discount).toLocaleString(todos.amountFormat, {
                                            maximumFractionDigits: 2,
                                        })}
                                        %
                                    </Typography>
                                </Grid>
                                <Grid item xs={2} sx={{ textAlign: "right" }}>
                                    <Typography variant="body1">
                                        $
                                        {parseFloat(
                                            (pricingCont.recurring.payAnnually
                                                ? pricingCont.recurring.fee * 12
                                                : pricingCont.recurring.fee * pricingCont.recurring.qty) *
                                                (1 - pricingCont.recurring.discount / 100)
                                        ).toLocaleString(todos.amountFormat, {
                                            maximumFractionDigits: 2,
                                        })}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider
                                sx={{
                                    backgroundColor: "#031851",
                                }}
                            />
                            <Grid
                                container
                                alignItems="center"
                                justifyContent="space-between"
                                spacing={2}
                                sx={{ paddingBottom: 1.5, paddingTop: 1.5 }}
                            >
                                <Grid item xs={3}>
                                    <Typography variant="body1" sx={{ paddingLeft: 6, wordBreak: "break-word" }}>
                                        {t("proposals.addons")}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}></Grid>
                                <Grid item xs={1} sx={{ textAlign: "center" }}>
                                    <Typography variant="body1">{pricingCont.recurring.recuHours}</Typography>
                                </Grid>
                                <Grid item xs={1} sx={{ textAlign: "right" }}>
                                    <Typography variant="body1">
                                        $
                                        {parseFloat(pricingCont.recurring.addFee).toLocaleString(todos.amountFormat, {
                                            maximumFractionDigits: 2,
                                        })}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2} sx={{ textAlign: "right" }}>
                                    <Typography variant="body1">
                                        $
                                        {parseFloat(
                                            pricingCont.recurring.recuHours * pricingCont.recurring.addFee
                                        ).toLocaleString(todos.amountFormat, {
                                            maximumFractionDigits: 2,
                                        })}
                                    </Typography>
                                </Grid>
                                <Grid item xs={1} sx={{ textAlign: "center" }}>
                                    <Typography variant="body1">
                                        {parseFloat(pricingCont.recurring.discount).toLocaleString(todos.amountFormat, {
                                            maximumFractionDigits: 2,
                                        })}
                                        %
                                    </Typography>
                                </Grid>
                                <Grid item xs={2} sx={{ textAlign: "right" }}>
                                    <Typography variant="body1">
                                        $
                                        {parseFloat(
                                            pricingCont.recurring.recuHours *
                                                pricingCont.recurring.addFee *
                                                (1 - pricingCont.recurring.discount / 100)
                                        ).toLocaleString(todos.amountFormat, {
                                            maximumFractionDigits: 2,
                                        })}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </>
                    )}
                    {pricingCont.recurring.additionals.map(
                        (value) =>
                            value.additional_name !== "Controller" && (
                                <>
                                    <Divider
                                        sx={{
                                            backgroundColor: "#031851",
                                        }}
                                    />
                                    <Grid
                                        container
                                        alignItems="center"
                                        justifyContent="space-between"
                                        spacing={2}
                                        sx={{ paddingBottom: 1, paddingTop: 1 }}
                                    >
                                        <Grid item xs={3}>
                                            <Typography
                                                variant="body1"
                                                sx={{ paddingLeft: 8, wordBreak: "break-word" }}
                                            >
                                                {value.other_name ?? getNamesAdd(value)}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2}></Grid>
                                        <Grid item xs={1} sx={{ textAlign: "center" }}>
                                            <Typography variant="body1">{value.hours}</Typography>
                                        </Grid>
                                        <Grid item xs={1} sx={{ textAlign: "right" }}>
                                            <Typography variant="body1">
                                                $
                                                {parseFloat(pricingCont.recurring.addFee).toLocaleString(
                                                    todos.amountFormat,
                                                    {
                                                        maximumFractionDigits: 2,
                                                    }
                                                )}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2} sx={{ textAlign: "right" }}>
                                            <Typography variant="body1">
                                                $
                                                {parseFloat(pricingCont.recurring.addFee * value.hours).toLocaleString(
                                                    todos.amountFormat,
                                                    {
                                                        maximumFractionDigits: 2,
                                                    }
                                                )}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={1} sx={{ textAlign: "center" }}>
                                            <Typography variant="body1">
                                                {parseFloat(pricingCont.recurring.discount).toLocaleString(
                                                    todos.amountFormat,
                                                    {
                                                        maximumFractionDigits: 2,
                                                    }
                                                )}
                                                %
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2} sx={{ textAlign: "right" }}>
                                            <Typography variant="body1">
                                                $
                                                {parseFloat(
                                                    pricingCont.recurring.addFee *
                                                        value.hours *
                                                        (1 - pricingCont.recurring.discount / 100)
                                                ).toLocaleString(todos.amountFormat, {
                                                    maximumFractionDigits: 2,
                                                })}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </>
                            )
                    )}
                </>
            )}

            {pricingCont.catchUp.isActive && (
                <>
                    <Divider
                        sx={{
                            backgroundColor: "#031851",
                        }}
                    />
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={2}
                        sx={{ paddingBottom: 1.5, paddingTop: 1.5 }}
                    >
                        <Grid item xs={3}>
                            <Typography variant="h4">
                                {t("invoicing.catchUp") +
                                    ` (${
                                        capitalize(pricingCont.catchUp.initDate.format("MMM")).replace(/\./g, "") +
                                        "-" +
                                        capitalize(pricingCont.catchUp.untilDate.format("MMM YYYY")).replace(/\./g, "")
                                    })`}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="body1" sx={{ textAlign: "center" }}>
                                {t("invoicing.project")}
                            </Typography>
                        </Grid>
                        <Grid item xs={1} sx={{ textAlign: "center" }}>
                            <Typography variant="body1">
                                {pricingCont.catchUp.additionals.length > 0 && !pricingCont.catchUp.onlyAdd
                                    ? "-"
                                    : pricingCont.catchUp.monthQty}
                            </Typography>
                        </Grid>
                        <Grid item xs={1} sx={{ textAlign: "right" }}>
                            <Typography variant="body1">
                                {pricingCont.catchUp.additionals.length > 0 && !pricingCont.catchUp.onlyAdd
                                    ? "-"
                                    : "$" +
                                      parseFloat(pricingCont.catchUp.fee).toLocaleString(todos.amountFormat, {
                                          maximumFractionDigits: 2,
                                      })}
                            </Typography>
                        </Grid>
                        <Grid item xs={2} sx={{ textAlign: "right" }}>
                            <Typography variant="body1">
                                {pricingCont.catchUp.additionals.length > 0 && !pricingCont.catchUp.onlyAdd
                                    ? "-"
                                    : "$" +
                                      parseFloat(pricingCont.catchUp.fee * pricingCont.catchUp.monthQty).toLocaleString(
                                          todos.amountFormat,
                                          {
                                              maximumFractionDigits: 2,
                                          }
                                      )}
                            </Typography>
                        </Grid>
                        <Grid item xs={1} sx={{ textAlign: "center" }}>
                            <Typography variant="body1">
                                {pricingCont.catchUp.additionals.length > 0 && !pricingCont.catchUp.onlyAdd
                                    ? "-"
                                    : parseFloat(pricingCont.catchUp.discount).toLocaleString(todos.amountFormat, {
                                          maximumFractionDigits: 2,
                                      }) + "%"}
                            </Typography>
                        </Grid>
                        <Grid item xs={2} sx={{ textAlign: "right" }}>
                            <Typography variant="body1">
                                $
                                {parseFloat(
                                    (pricingCont.catchUp.fee * pricingCont.catchUp.monthQty +
                                        pricingCont.catchUp.totalHours * pricingCont.catchUp.addFee) *
                                        (1 - pricingCont.catchUp.discount / 100)
                                ).toLocaleString(todos.amountFormat, {
                                    maximumFractionDigits: 2,
                                })}
                            </Typography>
                        </Grid>
                    </Grid>
                    {pricingCont.catchUp.additionals.length > 0 && !pricingCont.catchUp.onlyAdd && (
                        <>
                            <Divider
                                sx={{
                                    backgroundColor: "#031851",
                                }}
                            />
                            <Grid
                                container
                                alignItems="center"
                                justifyContent="space-between"
                                spacing={2}
                                sx={{ paddingBottom: 1.5, paddingTop: 1.5 }}
                            >
                                <Grid item xs={3}>
                                    <Typography variant="body1" sx={{ paddingLeft: 6, wordBreak: "break-word" }}>
                                        {t("proposals.coreServ")}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}></Grid>
                                <Grid item xs={1} sx={{ textAlign: "center" }}>
                                    <Typography variant="body1">{pricingCont.catchUp.monthQty}</Typography>
                                </Grid>
                                <Grid item xs={1} sx={{ textAlign: "right" }}>
                                    <Typography variant="body1">
                                        $
                                        {parseFloat(pricingCont.catchUp.fee).toLocaleString(todos.amountFormat, {
                                            maximumFractionDigits: 2,
                                        })}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2} sx={{ textAlign: "right" }}>
                                    <Typography variant="body1">
                                        $
                                        {parseFloat(
                                            pricingCont.catchUp.fee * pricingCont.catchUp.monthQty
                                        ).toLocaleString(todos.amountFormat, {
                                            maximumFractionDigits: 2,
                                        })}
                                    </Typography>
                                </Grid>
                                <Grid item xs={1} sx={{ textAlign: "center" }}>
                                    <Typography variant="body1">
                                        {parseFloat(pricingCont.catchUp.discount).toLocaleString(todos.amountFormat, {
                                            maximumFractionDigits: 2,
                                        })}
                                        %
                                    </Typography>
                                </Grid>
                                <Grid item xs={2} sx={{ textAlign: "right" }}>
                                    <Typography variant="body1">
                                        $
                                        {parseFloat(
                                            pricingCont.catchUp.fee *
                                                pricingCont.catchUp.monthQty *
                                                (1 - pricingCont.catchUp.discount / 100)
                                        ).toLocaleString(todos.amountFormat, {
                                            maximumFractionDigits: 2,
                                        })}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider
                                sx={{
                                    backgroundColor: "#031851",
                                }}
                            />
                            <Grid
                                container
                                alignItems="center"
                                justifyContent="space-between"
                                spacing={2}
                                sx={{ paddingBottom: 1.5, paddingTop: 1.5 }}
                            >
                                <Grid item xs={3}>
                                    <Typography variant="body1" sx={{ paddingLeft: 6, wordBreak: "break-word" }}>
                                        {t("proposals.addons")}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}></Grid>
                                <Grid item xs={1} sx={{ textAlign: "center" }}>
                                    <Typography variant="body1">{pricingCont.catchUp.totalHours}</Typography>
                                </Grid>
                                <Grid item xs={1} sx={{ textAlign: "right" }}>
                                    <Typography variant="body1">
                                        $
                                        {parseFloat(pricingCont.catchUp.addFee).toLocaleString(todos.amountFormat, {
                                            maximumFractionDigits: 2,
                                        })}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2} sx={{ textAlign: "right" }}>
                                    <Typography variant="body1">
                                        $
                                        {parseFloat(
                                            pricingCont.catchUp.totalHours * pricingCont.catchUp.addFee
                                        ).toLocaleString(todos.amountFormat, {
                                            maximumFractionDigits: 2,
                                        })}
                                    </Typography>
                                </Grid>
                                <Grid item xs={1} sx={{ textAlign: "center" }}>
                                    <Typography variant="body1">
                                        {parseFloat(pricingCont.catchUp.discount).toLocaleString(todos.amountFormat, {
                                            maximumFractionDigits: 2,
                                        })}
                                        %
                                    </Typography>
                                </Grid>
                                <Grid item xs={2} sx={{ textAlign: "right" }}>
                                    <Typography variant="body1">
                                        $
                                        {parseFloat(
                                            pricingCont.catchUp.totalHours *
                                                pricingCont.catchUp.addFee *
                                                (1 - pricingCont.catchUp.discount / 100)
                                        ).toLocaleString(todos.amountFormat, {
                                            maximumFractionDigits: 2,
                                        })}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </>
                    )}
                    {pricingCont.catchUp.additionals.map(
                        (value) =>
                            value.additional_name !== "Controller" && (
                                <>
                                    <Divider
                                        sx={{
                                            backgroundColor: "#031851",
                                        }}
                                    />
                                    <Grid
                                        container
                                        alignItems="center"
                                        justifyContent="space-between"
                                        spacing={2}
                                        sx={{ paddingBottom: 1, paddingTop: 1 }}
                                    >
                                        <Grid item xs={3}>
                                            <Typography
                                                variant="body1"
                                                sx={{ paddingLeft: 8, wordBreak: "break-word" }}
                                            >
                                                {value.other_name ?? getNamesAdd(value)}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2}></Grid>
                                        <Grid item xs={1} sx={{ textAlign: "center" }}>
                                            <Typography variant="body1">{value.hours}</Typography>
                                        </Grid>
                                        <Grid item xs={1} sx={{ textAlign: "right" }}>
                                            <Typography variant="body1">
                                                $
                                                {parseFloat(pricingCont.catchUp.addFee).toLocaleString(
                                                    todos.amountFormat,
                                                    {
                                                        maximumFractionDigits: 2,
                                                    }
                                                )}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2} sx={{ textAlign: "right" }}>
                                            <Typography variant="body1">
                                                $
                                                {parseFloat(pricingCont.catchUp.addFee * value.hours).toLocaleString(
                                                    todos.amountFormat,
                                                    {
                                                        maximumFractionDigits: 2,
                                                    }
                                                )}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={1} sx={{ textAlign: "center" }}>
                                            <Typography variant="body1">
                                                {parseFloat(pricingCont.catchUp.discount).toLocaleString(
                                                    todos.amountFormat,
                                                    {
                                                        maximumFractionDigits: 2,
                                                    }
                                                )}
                                                %
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2} sx={{ textAlign: "right" }}>
                                            <Typography variant="body1">
                                                $
                                                {parseFloat(
                                                    pricingCont.catchUp.addFee *
                                                        value.hours *
                                                        (1 - pricingCont.catchUp.discount / 100)
                                                ).toLocaleString(todos.amountFormat, {
                                                    maximumFractionDigits: 2,
                                                })}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </>
                            )
                    )}
                </>
            )}

            {pricingCont.consulting.isActive &&
                pricingCont.consulting.array.map((consult) => (
                    <>
                        <Divider
                            sx={{
                                backgroundColor: "#031851",
                            }}
                        />
                        <Grid
                            container
                            alignItems="center"
                            justifyContent="space-between"
                            spacing={2}
                            sx={{ paddingBottom: 1.5, paddingTop: 1.5 }}
                        >
                            <Grid item xs={3}>
                                <Typography variant="h4">{consult.description}</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography variant="body1" sx={{ textAlign: "center" }}>
                                    {t(`proposals.oneTime2`)}
                                </Typography>
                            </Grid>
                            <Grid item xs={1} sx={{ textAlign: "center" }}>
                                <Typography variant="body1">{consult.hours}</Typography>
                            </Grid>
                            <Grid item xs={1} sx={{ textAlign: "right" }}>
                                <Typography variant="body1">
                                    $
                                    {parseFloat(pricingCont.consulting.fee).toLocaleString(todos.amountFormat, {
                                        maximumFractionDigits: 2,
                                    })}
                                </Typography>
                            </Grid>
                            <Grid item xs={2} sx={{ textAlign: "right" }}>
                                <Typography variant="body1">
                                    $
                                    {parseFloat(pricingCont.consulting.fee * consult.hours).toLocaleString(
                                        todos.amountFormat,
                                        {
                                            maximumFractionDigits: 2,
                                        }
                                    )}
                                </Typography>
                            </Grid>
                            <Grid item xs={1} sx={{ textAlign: "center" }}>
                                <Typography variant="body1">
                                    {parseFloat(consult.discount).toLocaleString(todos.amountFormat, {
                                        maximumFractionDigits: 2,
                                    })}
                                    %
                                </Typography>
                            </Grid>
                            <Grid item xs={2} sx={{ textAlign: "right" }}>
                                <Typography variant="body1">
                                    $
                                    {parseFloat(
                                        pricingCont.consulting.fee * consult.hours * (1 - consult.discount / 100)
                                    ).toLocaleString(todos.amountFormat, {
                                        maximumFractionDigits: 2,
                                    })}
                                </Typography>
                            </Grid>
                        </Grid>
                    </>
                ))}
        </StyledContainer>
    );
};
