// React
import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";
import moment from "moment";

// MUI
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

const PREFIX = "Additionals";

const classes = {
    btnRoot: `${PREFIX}-btnRoot`,
    buttonContainer: `${PREFIX}-buttonContainer`,
    switch: `${PREFIX}-switch`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.btnRoot}`]: {
        border: "1px solid #ff5968",
        borderRadius: 40,
        "&:hover": {
            backgroundColor: "#2f3190",
            border: "1px solid #2f3190",
        },
        "&.MuiButton-root.Mui-disabled": {
            border: "1px solid #ffcdd2",
            backgroundColor: "#ffcdd2 !important",
            color: "#fff",
        },
    },
    [`& .${classes.buttonContainer}`]: {
        display: "flex",
        justifyContent: "flex-end",
        padding: 15,
    },
    [`& .${classes.switch}`]: {
        "& .MuiSwitch-switchBase .MuiSwitch-thumb": {
            color: "#FFF",
        },
        "& .MuiSwitch-switchBase + .MuiSwitch-track": {
            backgroundColor: "#031851",
        },
        "& .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb": {
            color: "#FF5968",
        },
        "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
            backgroundColor: "#FFA8B1",
        },
    },
}));

const NumberFormatCustom = (props) => {
    const todos = useSelector((state) => state.todos);

    const { inputRef, value, onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            value={value}
            getInputRef={inputRef}
            onValueChange={({ value: v }) => onChange({ target: { value: v } })}
            thousandSeparator={todos.amountFormat === "de-DE" ? "." : ","}
            decimalSeparator={todos.amountFormat === "de-DE" ? "," : "."}
            fixedDecimalScale={true}
            decimalScale={2}
            valueIsNumericString
        />
    );
};

export const Additionals = (props) => {
    // Properties
    const {
        actives,
        isError,
        set,
        setalertType,
        setShow,
        setTexto,
        sumTotal,
        updateProject,
        values,
        bookkeeperSystem,
        expenseEst,
        showConfirm,
        sumTotalAddHours
    } = props;

    /** Internationalization i18n */
    const [t] = useTranslation("global");

    /** React redux */
    const todos = useSelector((state) => state.todos);

    /** Component functions */
    const handleChange = (event) => {
        set({
            ...values,
            [event.target.name]: event.target.value,
        });
    };

    const handleChangeHoursBook = (event) => {
        set({
            ...values,
            [event.target.name]: event.target.value,
            projectFee: event.target.value * values.addHourFee,
            projectTotal: event.target.value * values.addHourFee * (1 - values.discount / 100),
        });
    };

    const handleChangeCheck = (event, value) => {
        switch (value) {
            case 0:
                if (values.checkedAROG && !event.target.checked && !todos.remind) {
                    showConfirm({
                        check: "checkedAR",
                        toth: "tothAR",
                        startDate: "startDateAR",
                        finishDate: "finishDateAR",
                    });
                } else {
                    set({
                        ...values,
                        checkedAR: event.target.checked,
                        tothAR: 0,
                        startDateAR: event.target.checked ? values.initDate : null,
                        finishDateAR: event.target.checked ? values.endDate : null,
                    });
                }
                break;
            case 1:
                if (values.checkedAPOG && !event.target.checked && !todos.remind) {
                    showConfirm({
                        check: "checkedAP",
                        toth: "tothAP",
                        startDate: "startDateAP",
                        finishDate: "finishDateAP",
                    });
                } else {
                    set({
                        ...values,
                        checkedAP: event.target.checked,
                        tothAP: 0,
                        startDateAP: event.target.checked ? values.initDate : null,
                        finishDateAP: event.target.checked ? values.endDate : null,
                    });
                }
                break;
            case 2:
                if (values.checkedAccrualOG && !event.target.checked && !todos.remind) {
                    showConfirm({
                        check: "checkedAccrual",
                        toth: "tothAccrual",
                        startDate: "startDateAccrual",
                        finishDate: "finishDateAccrual",
                    });
                } else {
                    set({
                        ...values,
                        checkedAccrual: event.target.checked,
                        tothAccrual: 0,
                        startDateAccrual: event.target.checked ? values.initDate : null,
                        finishDateAccrual: event.target.checked ? values.endDate : null,
                    });
                }
                break;
            case 3:
                if (values.checkedClassOG && !event.target.checked && !todos.remind) {
                    showConfirm({
                        check: "checkedClass",
                        toth: "tothClass",
                        startDate: "startDateClass",
                        finishDate: "finishDateClass",
                    });
                } else {
                    set({
                        ...values,
                        checkedClass: event.target.checked,
                        tothClass: 0,
                        startDateClass: event.target.checked ? values.initDate : null,
                        finishDateClass: event.target.checked ? values.endDate : null,
                    });
                }
                break;
            case 4:
                if (values.checkedMultiOG && !event.target.checked && !todos.remind) {
                    showConfirm({
                        check: "checkedMulti",
                        toth: "tothMulti",
                        startDate: "startDateMulti",
                        finishDate: "finishDateMulti",
                    });
                } else {
                    set({
                        ...values,
                        checkedMulti: event.target.checked,
                        tothMulti: 0,
                        startDateMulti: event.target.checked ? values.initDate : null,
                        finishDateMulti: event.target.checked ? values.endDate : null,
                    });
                }
                break;
            case 5:
                if (values.checkedPerOG && !event.target.checked && !todos.remind) {
                    showConfirm({
                        check: "checkedPer",
                        toth: "tothPer",
                        startDate: "startDatePer",
                        finishDate: "finishDatePer",
                    });
                } else {
                    set({
                        ...values,
                        checkedPer: event.target.checked,
                        tothPer: 0,
                        startDatePer: event.target.checked ? values.initDate : null,
                        finishDatePer: event.target.checked ? values.endDate : null,
                    });
                }
                break;
            case 6:
                if (values.checkedOthersOG && !event.target.checked && !todos.remind) {
                    showConfirm({
                        check: "checkedOthers",
                        nameOthers: "nameOthers",
                        tothOthers: "tothPer",
                        startDateOthers: "startDatePer",
                        finishDateOthers: "finishDatePer",
                    });
                } else {
                    set({
                        ...values,
                        checkedOthers: event.target.checked,
                        nameOthers: "",
                        tothOthers: 0,
                        startDateOthers: event.target.checked ? values.initDate : null,
                        finishDateOthers: event.target.checked ? values.endDate : null,
                    });
                }
                break;
            case 7:
                if (values.checkedChecksOG && !event.target.checked && !todos.remind) {
                    showConfirm({
                        check: "checkedChecks",
                        tothOthers: "tothChecks",
                        startDateOthers: "startDateChecks",
                        finishDateOthers: "finishDateChecks",
                    });
                } else {
                    set({
                        ...values,
                        checkedChecks: event.target.checked,
                        tothChecks: 0,
                        startDateChecks: event.target.checked ? values.initDate : null,
                        finishDateChecks: event.target.checked ? values.endDate : null,
                    });
                }
                break;
            default:
                set({
                    ...values,
                    projectFee: 0,
                    projectTotal: 0,
                    checkedBook: event.target.checked,
                    tothBook: 0,
                    startDateBook: event.target.checked ? values.initDate : null,
                    finishDateBook: event.target.checked ? values.endDate : null,
                });
                break;
        }
    };

    const handleChangeStartDateAdd = (date, val) => {
        let startDate = moment(date).startOf("month");
        switch (val) {
            case 0:
                if (!date || !values.finishDateAR) {
                    set({
                        ...values,
                        startDateAR: date ? startDate : date,
                    });
                } else {
                    if (startDate.isAfter(moment(values.finishDateAR))) {
                        setTexto(t("reports.initDateMess"));
                        setalertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            startDateAR: startDate,
                        });
                    }
                }
                break;
            case 1:
                if (!date || !values.finishDateAP) {
                    set({
                        ...values,
                        startDateAP: date ? startDate : date,
                    });
                } else {
                    if (startDate.isAfter(moment(values.finishDateAP))) {
                        setTexto(t("reports.initDateMess"));
                        setalertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            startDateAP: startDate,
                        });
                    }
                }
                break;
            case 2:
                if (!date || !values.finishDateAccrual) {
                    set({
                        ...values,
                        startDateAccrual: date ? startDate : date,
                    });
                } else {
                    if (startDate.isAfter(moment(values.finishDateAccrual))) {
                        setTexto(t("reports.initDateMess"));
                        setalertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            startDateAccrual: startDate,
                        });
                    }
                }
                break;
            case 3:
                if (!date || !values.finishDateClass) {
                    set({
                        ...values,
                        startDateClass: date ? startDate : date,
                    });
                } else {
                    if (startDate.isAfter(moment(values.finishDateClass))) {
                        setTexto(t("reports.initDateMess"));
                        setalertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            startDateClass: startDate,
                        });
                    }
                }
                break;
            case 4:
                if (!date || !values.finishDateMulti) {
                    set({
                        ...values,
                        startDateMulti: date ? startDate : date,
                    });
                } else {
                    if (startDate.isAfter(moment(values.finishDateMulti))) {
                        setTexto(t("reports.initDateMess"));
                        setalertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            startDateMulti: startDate,
                        });
                    }
                }
                break;
            case 5:
                if (!date || !values.finishDatePer) {
                    set({
                        ...values,
                        startDatePer: date ? startDate : date,
                    });
                } else {
                    if (startDate.isAfter(moment(values.finishDatePer))) {
                        setTexto(t("reports.initDateMess"));
                        setalertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            startDatePer: startDate,
                        });
                    }
                }
                break;
            case 6:
                if (!date || !values.finishDateOthers) {
                    set({
                        ...values,
                        startDateOthers: date ? startDate : date,
                    });
                } else {
                    if (startDate.isAfter(moment(values.finishDateOthers))) {
                        setTexto(t("reports.initDateMess"));
                        setalertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            startDateOthers: startDate,
                        });
                    }
                }
                break;
            case 7:
                if (!date || !values.finishDateChecks) {
                    set({
                        ...values,
                        startDateChecks: date ? startDate : date,
                    });
                } else {
                    if (startDate.isAfter(moment(values.finishDateChecks))) {
                        setTexto(t("reports.initDateMess"));
                        setalertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            startDateChecks: startDate,
                        });
                    }
                }
                break;
            default:
                if (!date || !values.finishDateBook) {
                    set({
                        ...values,
                        startDateBook: date ? startDate : date,
                    });
                } else {
                    if (startDate.isAfter(moment(values.finishDateBook))) {
                        setTexto(t("reports.initDateMess"));
                        setalertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            startDateBook: startDate,
                        });
                    }
                }
                break;
        }
    };

    const handleChangeEndDateAdd = (date, val) => {
        let endDate = moment(date).endOf("month").startOf("day");
        switch (val) {
            case 0:
                if (!date || !values.startDateAR) {
                    set({
                        ...values,
                        finishDateAR: date ? endDate : date,
                    });
                } else {
                    if (endDate.isBefore(moment(values.startDateAR))) {
                        setTexto(t("reports.endDateMess"));
                        setalertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            finishDateAR: endDate,
                        });
                    }
                }
                break;
            case 1:
                if (!date || !values.startDateAP) {
                    set({
                        ...values,
                        finishDateAP: date ? endDate : date,
                    });
                } else {
                    if (endDate.isBefore(moment(values.startDateAP))) {
                        setTexto(t("reports.endDateMess"));
                        setalertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            finishDateAP: endDate,
                        });
                    }
                }
                break;
            case 2:
                if (!date || !values.startDateAccrual) {
                    set({
                        ...values,
                        finishDateAccrual: date ? endDate : date,
                    });
                } else {
                    if (endDate.isBefore(moment(values.startDateAccrual))) {
                        setTexto(t("reports.endDateMess"));
                        setalertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            finishDateAccrual: endDate,
                        });
                    }
                }
                break;
            case 3:
                if (!date || !values.startDateClass) {
                    set({
                        ...values,
                        finishDateClass: date ? endDate : date,
                    });
                } else {
                    if (endDate.isBefore(moment(values.startDateClass))) {
                        setTexto(t("reports.endDateMess"));
                        setalertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            finishDateClass: endDate,
                        });
                    }
                }
                break;
            case 4:
                if (!date || !values.startDateMulti) {
                    set({
                        ...values,
                        finishDateMulti: date ? endDate : date,
                    });
                } else {
                    if (endDate.isBefore(moment(values.startDateMulti))) {
                        setTexto(t("reports.endDateMess"));
                        setalertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            finishDateMulti: endDate,
                        });
                    }
                }
                break;
            case 5:
                if (!date || !values.startDatePer) {
                    set({
                        ...values,
                        finishDatePer: date ? endDate : date,
                    });
                } else {
                    if (endDate.isBefore(moment(values.startDatePer))) {
                        setTexto(t("reports.endDateMess"));
                        setalertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            finishDatePer: endDate,
                        });
                    }
                }
                break;
            case 6:
                if (!date || !values.startDateOthers) {
                    set({
                        ...values,
                        finishDateOthers: date ? endDate : date,
                    });
                } else {
                    if (endDate.isBefore(moment(values.startDateOthers))) {
                        setTexto(t("reports.endDateMess"));
                        setalertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            finishDateOthers: endDate,
                        });
                    }
                }
                break;
            case 7:
                if (!date || !values.startDateChecks) {
                    set({
                        ...values,
                        finishDateChecks: date ? endDate : date,
                    });
                } else {
                    if (endDate.isBefore(moment(values.startDateChecks))) {
                        setTexto(t("reports.endDateMess"));
                        setalertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            finishDateChecks: endDate,
                        });
                    }
                }
                break;
            default:
                if (!date || !values.startDateBook) {
                    set({
                        ...values,
                        finishDateBook: date ? endDate : date,
                    });
                } else {
                    if (endDate.isBefore(moment(values.startDateBook))) {
                        setTexto(t("reports.endDateMess"));
                        setalertType("warning");
                        setShow(true);
                    } else {
                        set({
                            ...values,
                            finishDateBook: endDate,
                        });
                    }
                }
                break;
        }
    };

    return (
        <StyledContainer>
            <LocalizationProvider
                dateAdapter={AdapterMoment}
                adapterLocale={t("language.locale")}
                localeText={{ clearButtonLabel: t("miscellaneous.clear") }}
            >
                <Card elevation={0}>
                    <CardHeader title={t("services.additionals")} sx={{ color: "#ff5968" }} />
                    <Divider />
                    <CardContent sx={{ pb: 5 }}>
                        <Grid container spacing={2} alignItems="center">
                            {values.additionalsOnly && (
                                <>
                                    <Grid item xs={12} md={3}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    name="checkedBook"
                                                    checked={values.checkedBook}
                                                    onChange={() => {
                                                        return false;
                                                    }}
                                                    inputProps={{
                                                        "aria-label": "controlled",
                                                    }}
                                                />
                                            }
                                            label="Bookkeeper"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            fullWidth
                                            disabled={!values.checkedBook}
                                            label={t("miscellaneous.hoursMonth")}
                                            name="tothBook"
                                            onChange={handleChangeHoursBook}
                                            value={values.tothBook}
                                            variant="outlined"
                                            type="number"
                                            inputProps={{
                                                min: 0,
                                                step: 0.5,
                                                onKeyDown: (event) => {
                                                    event.preventDefault();
                                                },
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            error={values.checkedBook && isError && values.tothBook === 0}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <DatePicker
                                            componentsProps={{
                                                actionBar: {
                                                    actions: ["clear"],
                                                },
                                            }}
                                            disabled={!values.checkedBook}
                                            value={values.startDateBook}
                                            minDate={moment(values.initDate).startOf("month")}
                                            maxDate={moment(values.endDate).endOf("month")}
                                            onChange={(newValue) => handleChangeStartDateAdd(newValue, 8)}
                                            inputFormat={todos.dateFormat}
                                            renderInput={(params) => (
                                                <TextField
                                                    fullWidth
                                                    required={values.checkedBook}
                                                    {...params}
                                                    error={values.checkedBook && isError && !values.startDateBook}
                                                />
                                            )}
                                            inputProps={{ readOnly: true }}
                                            label={t("reports.initDate")}
                                            views={["year", "month"]}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <DatePicker
                                            componentsProps={{
                                                actionBar: {
                                                    actions: ["clear"],
                                                },
                                            }}
                                            disabled={!values.checkedBook}
                                            value={values.finishDateBook}
                                            minDate={moment(values.initDate).startOf("month")}
                                            maxDate={moment(values.endDate).endOf("month")}
                                            onChange={(newValue) => handleChangeEndDateAdd(newValue, 8)}
                                            inputFormat={todos.dateFormat}
                                            renderInput={(params) => (
                                                <TextField
                                                    fullWidth
                                                    required={values.checkedBook && values.endDate}
                                                    {...params}
                                                    error={
                                                        values.checkedBook &&
                                                        values.endDate &&
                                                        isError &&
                                                        !values.finishDateBook
                                                    }
                                                />
                                            )}
                                            inputProps={{ readOnly: true }}
                                            label={t("reports.endDate")}
                                            views={["year", "month"]}
                                        />
                                    </Grid>
                                </>
                            )}
                            <Grid item xs={12} md={3}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="checkedChecks"
                                            checked={values.checkedChecks}
                                            onChange={(value) => handleChangeCheck(value, 7)}
                                            inputProps={{
                                                "aria-label": "controlled",
                                            }}
                                            disabled={!values.initDate}
                                        />
                                    }
                                    label="Checks"
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    fullWidth
                                    disabled={!values.checkedChecks}
                                    label={t("miscellaneous.hoursMonth")}
                                    name="tothChecks"
                                    onChange={handleChange}
                                    value={values.tothChecks}
                                    variant="outlined"
                                    type="number"
                                    inputProps={{
                                        min: 0,
                                        step: 0.5,
                                        onKeyDown: (event) => {
                                            event.preventDefault();
                                        },
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    error={values.checkedChecks && isError && values.tothChecks === 0}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: {
                                            actions: ["clear"],
                                        },
                                    }}
                                    disabled={!values.checkedChecks}
                                    value={values.startDateChecks}
                                    onChange={(newValue) => handleChangeStartDateAdd(newValue, 7)}
                                    minDate={moment(values.initDate).startOf("month")}
                                    maxDate={moment(values.endDate).endOf("month")}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            required={values.checkedChecks}
                                            {...params}
                                            error={values.checkedChecks && isError && !values.startDateChecks}
                                        />
                                    )}
                                    inputProps={{ readOnly: true }}
                                    label={t("reports.initDate")}
                                    views={["year", "month"]}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: {
                                            actions: ["clear"],
                                        },
                                    }}
                                    disabled={!values.checkedChecks}
                                    value={values.finishDateChecks}
                                    onChange={(newValue) => handleChangeEndDateAdd(newValue, 7)}
                                    minDate={moment(values.initDate).startOf("month")}
                                    maxDate={moment(values.endDate).endOf("month")}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            required={values.checkedChecks && values.endDate}
                                            {...params}
                                            error={
                                                values.checkedChecks &&
                                                values.endDate &&
                                                isError &&
                                                !values.finishDateChecks
                                            }
                                        />
                                    )}
                                    inputProps={{ readOnly: true }}
                                    label={t("reports.endDate")}
                                    views={["year", "month"]}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="checkedAR"
                                            checked={values.checkedAR}
                                            onChange={(value) => handleChangeCheck(value, 0)}
                                            inputProps={{
                                                "aria-label": "controlled",
                                            }}
                                            disabled={!values.initDate}
                                        />
                                    }
                                    label="A/R"
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    fullWidth
                                    disabled={!values.checkedAR}
                                    label={t("miscellaneous.hoursMonth")}
                                    name="tothAR"
                                    onChange={handleChange}
                                    value={values.tothAR}
                                    variant="outlined"
                                    type="number"
                                    inputProps={{
                                        min: 0,
                                        step: 0.5,
                                        onKeyDown: (event) => {
                                            event.preventDefault();
                                        },
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    error={values.checkedAR && isError && values.tothAR === 0}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    disabled={!values.checkedAR}
                                    value={values.startDateAR}
                                    onChange={(newValue) => handleChangeStartDateAdd(newValue, 0)}
                                    minDate={moment(values.initDate).startOf("month")}
                                    maxDate={moment(values.endDate).endOf("month")}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            required={values.checkedAR}
                                            {...params}
                                            error={values.checkedAR && isError && !values.startDateAR}
                                        />
                                    )}
                                    inputProps={{ readOnly: true }}
                                    label={t("reports.initDate")}
                                    views={["year", "month"]}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    disabled={!values.checkedAR}
                                    value={values.finishDateAR}
                                    onChange={(newValue) => handleChangeEndDateAdd(newValue, 0)}
                                    minDate={moment(values.initDate).startOf("month")}
                                    maxDate={moment(values.endDate).endOf("month")}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            required={values.checkedAR && values.endDate}
                                            {...params}
                                            error={
                                                values.checkedAR && values.endDate && isError && !values.finishDateAR
                                            }
                                        />
                                    )}
                                    inputProps={{ readOnly: true }}
                                    label={t("reports.endDate")}
                                    views={["year", "month"]}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="checkedAP"
                                            checked={values.checkedAP}
                                            onChange={(value) => handleChangeCheck(value, 1)}
                                            inputProps={{
                                                "aria-label": "controlled",
                                            }}
                                            disabled={!values.initDate}
                                        />
                                    }
                                    label="A/P"
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    fullWidth
                                    disabled={!values.checkedAP}
                                    label={t("miscellaneous.hoursMonth")}
                                    name="tothAP"
                                    onChange={handleChange}
                                    value={values.tothAP}
                                    variant="outlined"
                                    type="number"
                                    inputProps={{
                                        min: 0,
                                        step: 0.5,
                                        onKeyDown: (event) => {
                                            event.preventDefault();
                                        },
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    error={values.checkedAP && isError && values.tothAP === 0}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    disabled={!values.checkedAP}
                                    value={values.startDateAP}
                                    onChange={(newValue) => handleChangeStartDateAdd(newValue, 1)}
                                    minDate={moment(values.initDate).startOf("month")}
                                    maxDate={moment(values.endDate).endOf("month")}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            required={values.checkedAP}
                                            {...params}
                                            error={values.checkedAP && isError && !values.startDateAP}
                                        />
                                    )}
                                    inputProps={{ readOnly: true }}
                                    label={t("reports.initDate")}
                                    views={["year", "month"]}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    disabled={!values.checkedAP}
                                    value={values.finishDateAP}
                                    onChange={(newValue) => handleChangeEndDateAdd(newValue, 1)}
                                    minDate={moment(values.initDate).startOf("month")}
                                    maxDate={moment(values.endDate).endOf("month")}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            required={values.checkedAP && values.endDate}
                                            {...params}
                                            error={
                                                values.checkedAP && values.endDate && isError && !values.finishDateAP
                                            }
                                        />
                                    )}
                                    inputProps={{ readOnly: true }}
                                    label={t("reports.endDate")}
                                    views={["year", "month"]}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="checkedAccrual"
                                            checked={values.checkedAccrual}
                                            onChange={(value) => handleChangeCheck(value, 2)}
                                            inputProps={{
                                                "aria-label": "controlled",
                                            }}
                                            disabled={!values.initDate}
                                        />
                                    }
                                    label={t("services.accrualAccounting")}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    fullWidth
                                    disabled={!values.checkedAccrual}
                                    label={t("miscellaneous.hoursMonth")}
                                    name="tothAccrual"
                                    onChange={handleChange}
                                    value={values.tothAccrual}
                                    variant="outlined"
                                    type="number"
                                    inputProps={{
                                        min: 0,
                                        step: 0.5,
                                        onKeyDown: (event) => {
                                            event.preventDefault();
                                        },
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    error={values.checkedAccrual && isError && values.tothAccrual === 0}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    disabled={!values.checkedAccrual}
                                    value={values.startDateAccrual}
                                    onChange={(newValue) => handleChangeStartDateAdd(newValue, 2)}
                                    minDate={moment(values.initDate).startOf("month")}
                                    maxDate={moment(values.endDate).endOf("month")}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            required={values.checkedAccrual}
                                            {...params}
                                            error={values.checkedAccrual && isError && !values.startDateAccrual}
                                        />
                                    )}
                                    inputProps={{ readOnly: true }}
                                    label={t("reports.initDate")}
                                    views={["year", "month"]}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    disabled={!values.checkedAccrual}
                                    value={values.finishDateAccrual}
                                    onChange={(newValue) => handleChangeEndDateAdd(newValue, 2)}
                                    minDate={moment(values.initDate).startOf("month")}
                                    maxDate={moment(values.endDate).endOf("month")}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            required={values.checkedAccrual && values.endDate}
                                            {...params}
                                            error={
                                                values.checkedAccrual &&
                                                values.endDate &&
                                                isError &&
                                                !values.finishDateAccrual
                                            }
                                        />
                                    )}
                                    inputProps={{ readOnly: true }}
                                    label={t("reports.endDate")}
                                    views={["year", "month"]}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="checkedClass"
                                            checked={values.checkedClass}
                                            onChange={(value) => handleChangeCheck(value, 3)}
                                            inputProps={{
                                                "aria-label": "controlled",
                                            }}
                                            disabled={!values.initDate}
                                        />
                                    }
                                    label={t("services.classesCategories")}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    fullWidth
                                    disabled={!values.checkedClass}
                                    label={t("miscellaneous.hoursMonth")}
                                    name="tothClass"
                                    onChange={handleChange}
                                    value={values.tothClass}
                                    variant="outlined"
                                    type="number"
                                    inputProps={{
                                        min: 0,
                                        step: 0.5,
                                        onKeyDown: (event) => {
                                            event.preventDefault();
                                        },
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    error={values.checkedClass && isError && values.tothClass === 0}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    disabled={!values.checkedClass}
                                    value={values.startDateClass}
                                    onChange={(newValue) => handleChangeStartDateAdd(newValue, 3)}
                                    minDate={moment(values.initDate).startOf("month")}
                                    maxDate={moment(values.endDate).endOf("month")}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            required={values.checkedClass}
                                            {...params}
                                            error={values.checkedClass && isError && !values.startDateClass}
                                        />
                                    )}
                                    inputProps={{ readOnly: true }}
                                    label={t("reports.initDate")}
                                    views={["year", "month"]}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    disabled={!values.checkedClass}
                                    value={values.finishDateClass}
                                    onChange={(newValue) => handleChangeEndDateAdd(newValue, 3)}
                                    minDate={moment(values.initDate).startOf("month")}
                                    maxDate={moment(values.endDate).endOf("month")}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            required={values.checkedClass && values.endDate}
                                            {...params}
                                            error={
                                                values.checkedClass &&
                                                values.endDate &&
                                                isError &&
                                                !values.finishDateClass
                                            }
                                        />
                                    )}
                                    inputProps={{ readOnly: true }}
                                    label={t("reports.endDate")}
                                    views={["year", "month"]}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="checkedMulti"
                                            checked={values.checkedMulti}
                                            onChange={(value) => handleChangeCheck(value, 4)}
                                            inputProps={{
                                                "aria-label": "controlled",
                                            }}
                                            disabled={!values.initDate}
                                        />
                                    }
                                    label={t("services.multiEntity")}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    fullWidth
                                    disabled={!values.checkedMulti}
                                    label={t("miscellaneous.hoursMonth")}
                                    name="tothMulti"
                                    onChange={handleChange}
                                    value={values.tothMulti}
                                    variant="outlined"
                                    type="number"
                                    inputProps={{
                                        min: 0,
                                        step: 0.5,
                                        onKeyDown: (event) => {
                                            event.preventDefault();
                                        },
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    error={values.checkedMulti && isError && values.tothMulti === 0}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    disabled={!values.checkedMulti}
                                    value={values.startDateMulti}
                                    onChange={(newValue) => handleChangeStartDateAdd(newValue, 4)}
                                    minDate={moment(values.initDate).startOf("month")}
                                    maxDate={moment(values.endDate).endOf("month")}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            required={values.checkedMulti}
                                            {...params}
                                            error={values.checkedMulti && isError && !values.startDateMulti}
                                        />
                                    )}
                                    inputProps={{ readOnly: true }}
                                    label={t("reports.initDate")}
                                    views={["year", "month"]}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    disabled={!values.checkedMulti}
                                    value={values.finishDateMulti}
                                    onChange={(newValue) => handleChangeEndDateAdd(newValue, 4)}
                                    minDate={moment(values.initDate).startOf("month")}
                                    maxDate={moment(values.endDate).endOf("month")}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            required={values.checkedMulti && values.endDate}
                                            {...params}
                                            error={
                                                values.checkedMulti &&
                                                values.endDate &&
                                                isError &&
                                                !values.finishDateMulti
                                            }
                                        />
                                    )}
                                    inputProps={{ readOnly: true }}
                                    label={t("reports.endDate")}
                                    views={["year", "month"]}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="checkedPer"
                                            checked={values.checkedPer}
                                            onChange={(value) => handleChangeCheck(value, 5)}
                                            inputProps={{
                                                "aria-label": "controlled",
                                            }}
                                            disabled={!values.initDate}
                                        />
                                    }
                                    label={t("services.personalizedReporting")}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    fullWidth
                                    disabled={!values.checkedPer}
                                    label={t("miscellaneous.hoursMonth")}
                                    name="tothPer"
                                    onChange={handleChange}
                                    value={values.tothPer}
                                    variant="outlined"
                                    type="number"
                                    inputProps={{
                                        min: 0,
                                        step: 0.5,
                                        onKeyDown: (event) => {
                                            event.preventDefault();
                                        },
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    error={values.checkedPer && isError && values.tothPer === 0}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    disabled={!values.checkedPer}
                                    value={values.startDatePer}
                                    onChange={(newValue) => handleChangeStartDateAdd(newValue, 5)}
                                    minDate={moment(values.initDate).startOf("month")}
                                    maxDate={moment(values.endDate).endOf("month")}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            required={values.checkedPer}
                                            {...params}
                                            error={values.checkedPer && isError && !values.startDatePer}
                                        />
                                    )}
                                    inputProps={{ readOnly: true }}
                                    label={t("reports.initDate")}
                                    views={["year", "month"]}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    disabled={!values.checkedPer}
                                    value={values.finishDatePer}
                                    onChange={(newValue) => handleChangeEndDateAdd(newValue, 5)}
                                    minDate={moment(values.initDate).startOf("month")}
                                    maxDate={moment(values.endDate).endOf("month")}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            required={values.checkedPer && values.endDate}
                                            {...params}
                                            error={
                                                values.checkedPer && values.endDate && isError && !values.finishDatePer
                                            }
                                        />
                                    )}
                                    inputProps={{ readOnly: true }}
                                    label={t("reports.endDate")}
                                    views={["year", "month"]}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={12} md={3} xl={2} sx={{ paddingLeft: "5px !important" }}>
                                        <Checkbox
                                            name="checkedOthers"
                                            checked={values.checkedOthers}
                                            onChange={(value) => handleChangeCheck(value, 6)}
                                            inputProps={{
                                                "aria-label": "controlled",
                                            }}
                                            disabled={!values.initDate}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={9} xl={10}>
                                        <TextField
                                            fullWidth
                                            disabled={!values.checkedOthers}
                                            label={t("services.others")}
                                            name="nameOthers"
                                            onChange={handleChange}
                                            value={values.nameOthers}
                                            variant="outlined"
                                            error={values.checkedOthers && isError && !values.nameOthers}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    fullWidth
                                    disabled={!values.checkedOthers}
                                    label={t("miscellaneous.hoursMonth")}
                                    name="tothOthers"
                                    onChange={handleChange}
                                    value={values.tothOthers}
                                    variant="outlined"
                                    type="number"
                                    inputProps={{
                                        min: 0,
                                        step: 0.5,
                                        onKeyDown: (event) => {
                                            event.preventDefault();
                                        },
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    error={values.checkedOthers && isError && values.tothOthers === 0}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    disabled={!values.checkedOthers}
                                    value={values.startDateOthers}
                                    onChange={(newValue) => handleChangeStartDateAdd(newValue, 6)}
                                    minDate={moment(values.initDate).startOf("month")}
                                    maxDate={moment(values.endDate).endOf("month")}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            required={values.checkedOthers}
                                            {...params}
                                            error={values.checkedOthers && isError && !values.startDateOthers}
                                        />
                                    )}
                                    inputProps={{ readOnly: true }}
                                    label={t("reports.initDate")}
                                    views={["year", "month"]}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    disabled={!values.checkedOthers}
                                    value={values.finishDateOthers}
                                    onChange={(newValue) => handleChangeEndDateAdd(newValue, 6)}
                                    minDate={moment(values.initDate).startOf("month")}
                                    maxDate={moment(values.endDate).endOf("month")}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            required={values.checkedOthers && values.endDate}
                                            {...params}
                                            error={
                                                values.checkedOthers &&
                                                values.endDate &&
                                                isError &&
                                                !values.finishDateOthers
                                            }
                                        />
                                    )}
                                    inputProps={{ readOnly: true }}
                                    label={t("reports.endDate")}
                                    views={["year", "month"]}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}></Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    fullWidth
                                    disabled
                                    label={t("services.hoursTotal")}
                                    value={sumTotalAddHours}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormControl fullWidth variant="outlined" disabled>
                                    <InputLabel htmlFor="outlined-adornment-hourlyRate">
                                        {t("services.hourlyRate")}
                                    </InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-hourlyRate"
                                        value={values.addHourFee}
                                        inputComponent={NumberFormatCustom}
                                        startAdornment={
                                            <InputAdornment disableTypography position="start">
                                                $
                                            </InputAdornment>
                                        }
                                        label={t("services.hourlyRate")}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormControl fullWidth variant="outlined" disabled>
                                    <InputLabel htmlFor="outlined-adornment-hourlyRateAdd">
                                        {t("services.hourlyRateAdd")}
                                    </InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-hourlyRateAdd"
                                        value={values.addHourFee * sumTotal}
                                        inputComponent={NumberFormatCustom}
                                        startAdornment={
                                            <InputAdornment disableTypography position="start">
                                                $
                                            </InputAdornment>
                                        }
                                        label={t("services.hourlyRateAdd")}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <Divider />
                    <Box className={classes.buttonContainer}>
                        <Button
                            color="secondary"
                            variant="contained"
                            disableElevation
                            disabled={
                                actives.includes("Accountant") ||
                                expenseEst === -1 ||
                                (bookkeeperSystem.disabledData === true &&
                                    (bookkeeperSystem.prov === "Xero" || bookkeeperSystem.prov === "Quickbooks Online"))
                            }
                            classes={{ root: classes.btnRoot }}
                            onClick={updateProject}
                        >
                            {t("miscellaneous.save")}
                        </Button>
                    </Box>
                </Card>
            </LocalizationProvider>
        </StyledContainer>
    );
};
