/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import moment from "moment";
import { NumericFormat } from "react-number-format";

/** MUI imports */
import { styled } from "@mui/material/styles";
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Skeleton,
    Select,
    Switch,
    TextField,
    Typography,
    IconButton,
} from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

/** MUI icons */
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import InfoIcon from "@mui/icons-material/Info";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";

/** Components imports */
import Countries from "../../Js/countries";
import MainCountries from "../../Js/mainCountries";
import ExpenseHist from "./ExpenseHistory";

const PREFIX = "ClientGen";

const classes = {
    btnRoot: `${PREFIX}-btnRoot`,
};

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 48 * 4.5 + 8,
            boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
        },
        sx: {
            borderRadius: 2,
            mt: 0.7,
        },
    },
    variant: "menu",
    getContentAnchorEl: null,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.btnRoot}`]: {
        border: "1px solid #ff5968",
        borderRadius: 40,
        "&:hover": {
            backgroundColor: "#2f3190",
            border: "1px solid #2f3190",
        },
    },
}));

const NumberFormatCustom = (props) => {
    const todos = useSelector((state) => state.todos);

    const { inputRef, value, onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            getInputRef={inputRef}
            value={value}
            onValueChange={(values) => {
                const { value } = values;

                onChange(value);
            }}
            isAllowed={(values) => {
                const { floatValue } = values;

                return floatValue > 0 && floatValue <= 1000000;
            }}
            thousandSeparator={todos.amountFormat === "de-DE" ? "." : ","}
            decimalSeparator={todos.amountFormat === "de-DE" ? "," : "."}
            valueIsNumericString
        />
    );
};

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "rgba(19, 31, 62, 0.80)",
        fontSize: 12,
        maxWidth: 320,
        borderRadius: 6,
        fontWeight: "normal",
    },
});

const ClientGen = ({
    loadingCard,
    setLoadingCard,
    setBackDrop,
    setTexto,
    setAlertType,
    setShow,
    client,
    updateClients,
    franqData,
    groupData,
    setClient,
    setfranqName,
    setgroupName,
}) => {
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    /** React redux */
    const todos = useSelector((state) => state.todos);

    const meses = [
        { num: "01", key: "jan", name: t("monthNames.january") },
        { num: "02", key: "feb", name: t("monthNames.february") },
        { num: "03", key: "mar", name: t("monthNames.march") },
        { num: "04", key: "apr", name: t("monthNames.april") },
        { num: "05", key: "may", name: t("monthNames.may") },
        { num: "06", key: "jun", name: t("monthNames.june") },
        { num: "07", key: "jul", name: t("monthNames.july") },
        { num: "08", key: "aug", name: t("monthNames.august") },
        { num: "09", key: "sep", name: t("monthNames.september") },
        { num: "10", key: "oct", name: t("monthNames.october") },
        { num: "11", key: "nov", name: t("monthNames.november") },
        { num: "12", key: "dec", name: t("monthNames.december") },
    ];

    /** Component states */
    const [isError, setIsError] = useState(false);
    const [changeFranqGroup, setChangeFranqGroup] = useState(false);
    const [groups, setGroups] = useState([]);
    const [valuesGen, setValuesGen] = useState({
        country: "",
        disabledData: false,
        disableCountry: false,
        disabledFranq: false,
        clientRS: "",
        clientNC: "",
        clientActivity: "",
        type: "",
        clasif: "",
        franq: "",
        group: "",
        active: true,
        firmaDate: null,
        startDate: null,
        finishDate: null,
        fiscalDate: null,
        langEsc: "",
        langVer: "",
        expenseEstim: "",
        expenseEstim2: "",
        expenseAverage: "",
        expenseScale: "",
    });
    const [expenseHist, setExpenseHist] = useState([]);
    const [openExpenseHist, setOpenExpenseHist] = useState(false);
    const [valueTab, setValueTab] = useState(0);
    const [isDisabled, setIsDisabled] = useState(false);

    const handleChangeEstim = (value) => {
        setValuesGen({
            ...valuesGen,
            expenseEstim2: value,
        });
    };

    /** Component functions */
    const handleChange = (event) => {
        setValuesGen({
            ...valuesGen,
            [event.target.name]: event.target.value,
        });
    };

    const handleChangeFranq = async (event) => {
        setBackDrop(true);
        if (event.target.value.trim() !== "") {
            setChangeFranqGroup(true);

            await fetch(`/getFranchiseDetails?franchise_id=${event.target.value}`, {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
                .then((res) => res.json())
                .then((data) => {
                    setValuesGen({
                        ...valuesGen,
                        franq: event.target.value,
                        group: " ",
                        firmaDate: data.bsa_date !== undefined ? moment(data.bsa_date, "YYYY-MM-DD") : null,
                        disabledData: true,
                        disableCountry: true,
                        type: 1,
                        country: data.country_name !== undefined ? data.country_name : "",
                        langEsc: data.typing_communication !== undefined ? data.typing_communication : "",
                        langVer: data.verbal_communication !== undefined ? data.verbal_communication : "",
                    });
                });
        } else {
            if (valuesGen.group.trim() !== "") {
                setChangeFranqGroup(true);

                await fetch(`/getPracticeDetails?practice_id=${valuesGen.group}`, {
                    method: "GET",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                    },
                })
                    .then((res) => res.json())
                    .then((data) => {
                        setValuesGen({
                            ...valuesGen,
                            franq:
                                data.franchise_id !== undefined && data.franchise_id !== null
                                    ? data.franchise_id
                                    : event.target.value,
                            disabledFranq: data.franchise_id !== undefined && data.franchise_id !== null ? true : false,
                            firmaDate: data.bsa_date !== undefined ? moment(data.bsa_date, "YYYY-MM-DD") : null,
                            disabledData: true,
                            disableCountry:
                                data.franchise_id !== undefined && data.franchise_id !== null ? true : false,
                            type: 1,
                            langEsc: data.typing_communication !== undefined ? data.typing_communication : "",
                            langVer: data.verbal_communication !== undefined ? data.verbal_communication : "",
                        });
                    });
            } else {
                setChangeFranqGroup(false);
                setValuesGen({
                    ...valuesGen,
                    franq: event.target.value,
                    firmaDate: null,
                    type: "",
                    country: "",
                    langEsc: "",
                    langVer: "",
                    disabledData: false,
                    disableCountry: false,
                });
            }
        }
        await fetch(`/getFranchiseGroups?id_franchise=${event.target.value}`, {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then((res) => res.json())
            .then((data) => {
                setGroups(data);
            });

        setBackDrop(false);
    };

    const handleChangeGroup = async (event) => {
        setBackDrop(true);
        if (event.target.value.trim() !== "") {
            setChangeFranqGroup(true);

            await fetch(`/getPracticeDetails?practice_id=${event.target.value}`, {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
                .then((res) => res.json())
                .then((data) => {
                    if (
                        valuesGen.franq.trim() === "" ||
                        (data.franchise_id !== undefined && data.franchise_id !== null)
                    ) {
                        setValuesGen({
                            ...valuesGen,
                            group: event.target.value,
                            franq:
                                data.franchise_id !== undefined && data.franchise_id !== null ? data.franchise_id : " ",
                            disabledFranq: data.franchise_id !== undefined && data.franchise_id !== null ? true : false,
                            firmaDate: data.bsa_date !== undefined ? moment(data.bsa_date, "YYYY-MM-DD") : null,
                            disabledData: data.franchise_id !== undefined && data.franchise_id !== null ? true : false,
                            disableCountry:
                                data.franchise_id !== undefined && data.franchise_id !== null ? true : false,
                            type: data.franchise_id ? 1 : 2,
                            country: data.country_name !== undefined ? data.country_name : valuesGen.country,
                            langEsc: data.typing_communication !== undefined ? data.typing_communication : "",
                            langVer: data.verbal_communication !== undefined ? data.verbal_communication : "",
                        });
                    } else {
                        setValuesGen({
                            ...valuesGen,
                            group: event.target.value,
                            franq:
                                data.franchise_id !== undefined && data.franchise_id !== null ? data.franchise_id : " ",
                            disabledFranq: false,
                            disabledData: data.franchise_id !== undefined && data.franchise_id !== null ? true : false,
                            type: data.franchise_id ? 1 : 2,
                            country:
                                data.franchise_id !== undefined && data.franchise_id !== null
                                    ? data.country_name
                                    : valuesGen.country,
                            disableCountry:
                                data.franchise_id !== undefined && data.franchise_id !== null ? true : false,
                        });
                    }
                });
        } else {
            if (valuesGen.franq.trim() === "") {
                setChangeFranqGroup(false);
                setValuesGen({
                    ...valuesGen,
                    group: event.target.value,
                    firmaDate: null,
                    type: "",
                    country: "",
                    langEsc: "",
                    langVer: "",
                    disabledData: false,
                    disableCountry: false,
                    disabledFranq: false,
                });
            } else {
                setValuesGen({
                    ...valuesGen,
                    group: event.target.value,
                    disabledFranq: false,
                });
            }
        }
        setBackDrop(false);
    };

    const handleChangeFirmaDate = (date) => {
        if (date === null) {
            setValuesGen({
                ...valuesGen,
                firmaDate: date,
            });
        } else {
            setValuesGen({
                ...valuesGen,
                firmaDate: moment(date).format("YYYY-MM-DD"),
            });
        }
    };

    const handleChangeStartDate = (date) => {
        if (date === null) {
            setValuesGen({
                ...valuesGen,
                startDate: date,
            });
        } else if (valuesGen.finishDate === null) {
            setValuesGen({
                ...valuesGen,
                startDate: moment(date).format("YYYY-MM-DD"),
                active: true,
            });
        } else {
            if (moment(date).isAfter(moment(valuesGen.finishDate))) {
                setTexto(t("reports.initDateMess"));
                setAlertType("warning");
                setShow(true);
            } else {
                setValuesGen({
                    ...valuesGen,
                    startDate: moment(date).format("YYYY-MM-DD"),
                });
            }
        }
    };

    const handleChangeEndDate = (date) => {
        if (date === null) {
            setValuesGen({
                ...valuesGen,
                finishDate: date,
                active: true,
            });
        } else if (valuesGen.startDate !== null) {
            if (moment(date).isBefore(moment(valuesGen.startDate))) {
                setTexto(t("reports.endDateMess"));
                setAlertType("warning");
                setShow(true);
            } else {
                if (moment(date).isBefore(moment())) {
                    setValuesGen({
                        ...valuesGen,
                        finishDate: moment(date).format("YYYY-MM-DD"),
                        active: false,
                    });
                } else {
                    setValuesGen({
                        ...valuesGen,
                        finishDate: moment(date).format("YYYY-MM-DD"),
                        active: true,
                    });
                }
            }
        } else {
            setTexto(t("reports.selectStartDate"));
            setAlertType("warning");
            setShow(true);
        }
    };

    const handleChangeFiscalDate = (date) => {
        setValuesGen({
            ...valuesGen,
            fiscalDate: date,
        });
    };

    const sortCountries = () => {
        if (moment.locale() === "en") {
            Countries.sort((a, b) => {
                if (a.name_en > b.name_en) return 1;

                if (a.name_en < b.name_en) return -1;

                return 0;
            });
        } else {
            Countries.sort((a, b) => {
                if (a.name_es > b.name_es) return 1;

                if (a.name_es < b.name_es) return -1;

                return 0;
            });
        }
    };

    const updateClientData = async () => {
        setBackDrop(true);
        let groupExist = false;
        let franqExist = false;
        let expenseValid = false;

        if (valuesGen.type === 1 && valuesGen.expenseEstim2) {
            expenseValid = true;
        } else if (valuesGen.type === 2 && valuesGen.expenseEstim) {
            expenseValid = true;
        }

        if (
            valuesGen.clientRS.trim() !== "" &&
            valuesGen.type !== "" &&
            valuesGen.clasif !== "" &&
            valuesGen.country !== "" &&
            valuesGen.clientActivity.trim() !== "" &&
            valuesGen.fiscalDate !== null &&
            valuesGen.langVer !== "" &&
            valuesGen.langEsc !== "" &&
            expenseValid
        ) {
            groupData.forEach((val) => {
                if (valuesGen.group === val.id) {
                    setgroupName(val.group_name);
                    groupExist = true;
                }
            });

            franqData.forEach((val) => {
                if (valuesGen.franq === val.id) {
                    setfranqName(val.franchise_name);
                    franqExist = true;
                }
            });

            if (!groupExist) {
                setgroupName("");
            }
            if (!franqExist) {
                setfranqName("");
            }

            const params = {
                _id: client._id,
                country_name: valuesGen.country,
                company_name: valuesGen.clientNC === "" ? undefined : valuesGen.clientNC,
                company_legal_name: valuesGen.clientRS,
                social_activity: valuesGen.clientActivity,
                client_type: valuesGen.type,
                client_status: valuesGen.clasif,
                franchise_id: valuesGen.franq.trim() === "" ? undefined : valuesGen.franq,
                practice_id: valuesGen.group.trim() === "" ? undefined : valuesGen.group,
                bsa_date: valuesGen.firmaDate,
                since_date: valuesGen.startDate,
                end_date: valuesGen.finishDate,
                financial_end_date: valuesGen.fiscalDate,
                typing_communication: valuesGen.langEsc,
                verbal_communication: valuesGen.langVer,
                expense_estimation: valuesGen.type === 1 ? valuesGen.expenseEstim2 : valuesGen.expenseEstim,
                expense_average: valuesGen.expenseAverage,
                expense_scale: valuesGen.expenseScale,
            };

            await fetch(`/updateClientInfo`, {
                method: "POST",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
                body: JSON.stringify(params),
            })
                .then((response) => {
                    if (response.ok) {
                        updateClients();
                        setClient(params);
                        setChangeFranqGroup(false);
                        setTexto(t("miscellaneous.successfulUpdate"));
                        setAlertType("success");
                        setShow(true);
                    } else {
                        setTexto(t("miscellaneous.updatingError"));
                        setAlertType("error");
                        setShow(true);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            setTexto(t("miscellaneous.requiredData"));
            setAlertType("warning");
            setShow(true);
            setIsError(true);
        }

        setBackDrop(false);
    };

    useEffect(() => {
        (async () => {
            // Showing loading
            setLoadingCard(true);

            if (client.franchise_id !== undefined && client.franchise_id !== null && client.franchise_id.length > 0) {
                await fetch(`/getFranchiseGroups?id_franchise=${client.franchise_id}`, {
                    method: "GET",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                    },
                })
                    .then((res) => res.json())
                    .then((data) => {
                        setGroups(data);
                    });
            } else {
                setGroups(groupData);
            }

            sortCountries();
            setValuesGen({
                country: client.country_name === undefined ? "" : client.country_name,
                clientNC: client.company_name === undefined || client.company_name === null ? "" : client.company_name,
                clientRS: client.company_legal_name === undefined ? "" : client.company_legal_name,
                clientActivity:
                    client.social_activity === undefined || client.social_activity === null
                        ? ""
                        : client.social_activity,
                type: client.client_type === undefined ? "" : client.client_type,
                clasif: client.client_status === undefined ? "" : client.client_status,
                franq: client.franchise_id === undefined || client.franchise_id === null ? " " : client.franchise_id,
                group: client.practice_id === undefined || client.practice_id === null ? " " : client.practice_id,
                firmaDate:
                    client.bsa_date === undefined || client.bsa_date === null
                        ? null
                        : moment(client.bsa_date, "YYYY-MM-DD"),
                disabledData: client.franchise_id === undefined || client.franchise_id === null ? false : true,
                startDate:
                    client.since_date === undefined || client.since_date === null
                        ? null
                        : moment(client.since_date, "YYYY-MM-DD"),
                finishDate:
                    client.end_date === undefined || client.end_date === null
                        ? null
                        : moment(client.end_date, "YYYY-MM-DD"),
                fiscalDate:
                    client.financial_end_date === undefined || client.financial_end_date === null
                        ? null
                        : moment(client.financial_end_date, "YYYY-MM-DD"),
                langEsc: client.typing_communication === undefined ? "" : client.typing_communication,
                langVer: client.verbal_communication === undefined ? "" : client.verbal_communication,
                active:
                    client.end_date !== undefined && client.end_date !== null
                        ? moment(client.end_date).isBefore(moment())
                            ? false
                            : true
                        : client.since_date !== undefined && client.since_date !== null
                        ? true
                        : false,
                disableCountry: client.franchise_id === undefined || client.franchise_id === null ? false : true,
                expenseEstim:
                    client.expense_estimation === undefined ||
                    client.expense_estimation === null ||
                    client.client_type === 1
                        ? ""
                        : client.expense_estimation,
                expenseEstim2: !client.expense_estimation || client.client_type === 2 ? "" : client.expense_estimation,
                expenseAverage:
                    client.expense_average === undefined || client.expense_average === null
                        ? ""
                        : client.expense_average,
                expenseScale:
                    client.expense_scale === undefined || client.expense_scale === null ? "" : client.expense_scale,
            });

            setLoadingCard(false);
        })();
    }, [t, todos.dateFormat, todos.amountFormat, client, setLoadingCard, setBackDrop, groupData]);

    const handleOpenExpenseHistory = async () => {
        setBackDrop(true);
        await fetch(`/getHistoricalExpenses?company_id=${client._id}`, {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then((res) => res.json())
            .then((data) => {
                // Validación para mostrar realExpenses de un mes o - dependiendo de la fecha actual.
                const currentDate = moment();
                const currentMonth = currentDate.month() + 1;

                //Para el mes anterior, se valida que abra 3 días hábiles antes del primero de cada mes
                let nextMonthDate = moment().add(1, "months").startOf("month");
                let finalDate = nextMonthDate.clone().subtract(3, "days").startOf("day");
                while ([6, 7].includes(finalDate.isoWeekday())) {
                    finalDate.subtract(1, "days");
                }

                const lastDayMonth = currentDate.daysInMonth();
                let expensesData;

                if (currentDate.date() !== lastDayMonth) {
                    expensesData = data.map((obj) => {
                        const year = Object.keys(obj)[0];
                        const months = obj[year];
                        if (year === currentDate.year().toString()) {
                            const updatedMonths = Object.keys(months).reduce((acc, month) => {
                                const monthData = months[month];
                                return {
                                    ...acc,
                                    [month]:
                                        parseInt(month) === currentMonth ||
                                        (parseInt(month) === currentMonth - 1 && currentDate.isBefore(finalDate))
                                            ? { ...monthData, realExpense: "-" }
                                            : monthData,
                                };
                            }, {});
                            return { [year]: updatedMonths };
                        }
                        return obj;
                    });
                } else {
                    expensesData = data;
                }

                setExpenseHist(expensesData);
                let isDisabled = false;

                for (let i = 0; i < data.length; i++) {
                    for (let j = 0; j < meses.length; j++) {
                        if (data[i][Object.keys(data[i])[0]][meses[j].num].status === 2) {
                            isDisabled = true;
                            break;
                        }
                    }
                }

                setIsDisabled(isDisabled);
                setValueTab(data.length - 1);
                setOpenExpenseHist(true);
            });
        setBackDrop(false);
    };

    const handleUpdateMonthExpenses = async (client_id, month, year) => {
        let newValues = expenseHist;

        for (let i = 0; i < newValues.length; i++) {
            if (Object.keys(newValues[i])[0] === year) {
                newValues[i][year][month].status = 2;
            }
        }

        // Validación para mostrar realExpenses de un mes o - dependiendo de la fecha actual.
        const currentDate = moment();
        const currentMonth = currentDate.month() + 1;

        //Para el mes anterior, se valida que abra 3 días hábiles antes del primero de cada mes
        let nextMonthDate = moment().add(1, "months").startOf("month");
        let finalDate = nextMonthDate.clone().subtract(3, "days").startOf("day");
        while ([6, 7].includes(finalDate.isoWeekday())) {
            finalDate.subtract(1, "days");
        }

        const lastDayMonth = currentDate.daysInMonth();
        let expensesData;

        if (currentDate.date() !== lastDayMonth) {
            expensesData = newValues.map((obj) => {
                const year = Object.keys(obj)[0];
                const months = obj[year];
                if (year === currentDate.year().toString()) {
                    const updatedMonths = Object.keys(months).reduce((acc, month) => {
                        const monthData = months[month];
                        return {
                            ...acc,
                            [month]:
                                parseInt(month) === currentMonth ||
                                (parseInt(month) === currentMonth - 1 && currentDate.isBefore(finalDate))
                                    ? { ...monthData, realExpense: "-" }
                                    : monthData,
                        };
                    }, {});
                    return { [year]: updatedMonths };
                }
                return obj;
            });
        } else {
            expensesData = newValues;
        }

        setExpenseHist(expensesData);
        setIsDisabled(true);

        const params = {
            id_organisation: client_id,
            expenses_date: year + "-" + month + "-01",
        };

        await fetch(`/updateCompanyExpenses`, {
            method: "POST",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
            body: JSON.stringify(params),
        });
    };

    const handleCloseHistory = () => {
        setOpenExpenseHist(false);
        setExpenseHist([]);
    };

    return (
        <StyledContainer>
            <Card elevation={0}>
                <CardHeader
                    title={t("miscellaneous.data")}
                    action={
                        loadingCard ? (
                            <Skeleton variant="rounded" animation="wave" height={50} />
                        ) : (
                            <FormControlLabel
                                disabled
                                control={
                                    <Switch
                                        checked={valuesGen.active}
                                        inputProps={{ "aria-label": "controlled" }}
                                        color="secondary"
                                        sx={{
                                            "& .MuiSwitch-switchBase .MuiSwitch-thumb": {
                                                color: "#FFF",
                                            },
                                            "& .MuiSwitch-switchBase + .MuiSwitch-track": {
                                                backgroundColor: "#031851",
                                            },
                                            "& .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb": {
                                                color: "#FF5968",
                                                opacity: 0.5,
                                            },
                                            "& .MuiSwitch-switchBase.Mui-checked.Mui-disabled + .MuiSwitch-track": {
                                                backgroundColor: "#FF5968",
                                            },
                                        }}
                                    />
                                }
                                label={
                                    valuesGen.active ? (
                                        <Typography variant="body1" color="textPrimary">
                                            {t("miscellaneous.active")}
                                        </Typography>
                                    ) : (
                                        <Typography variant="body1" color="textPrimary">
                                            {t("miscellaneous.inactive")}
                                        </Typography>
                                    )
                                }
                            />
                        )
                    }
                    sx={{ color: "#ff5968" }}
                />
                <Divider />
                <CardContent>
                    <Grid container spacing={2}>
                        {changeFranqGroup ? (
                            <Grid item xs={12}>
                                <Typography variant="h4" color="secondary">
                                    {t("miscellaneous.inheritData")}
                                </Typography>
                            </Grid>
                        ) : null}
                        <Grid item xl={3} sm={6} xs={12}>
                            {loadingCard ? (
                                <Skeleton variant="rounded" animation="wave" height={50} />
                            ) : (
                                <FormControl fullWidth disabled={valuesGen.disabledFranq}>
                                    <InputLabel id="franq-simple-select-label">
                                        {t("miscellaneous.franchise")}
                                    </InputLabel>
                                    <Select
                                        name="franq"
                                        labelId="franq-simple-select-label"
                                        IconComponent={KeyboardArrowDownIcon}
                                        value={valuesGen.franq}
                                        label={t("miscellaneous.franchise")}
                                        onChange={handleChangeFranq}
                                        MenuProps={MenuProps}
                                    >
                                        <MenuItem value=" ">
                                            <Typography
                                                variant="body1"
                                                component="span"
                                                align="center"
                                                color="textPrimary"
                                            >
                                                N/A
                                            </Typography>
                                        </MenuItem>
                                        {franqData.map((value) => (
                                            <MenuItem value={value.id}>
                                                <Typography
                                                    variant="body1"
                                                    component="span"
                                                    align="center"
                                                    color="textPrimary"
                                                >
                                                    {value.franchise_name}
                                                </Typography>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )}
                        </Grid>
                        <Grid item xl={3} sm={6} xs={12}>
                            {loadingCard ? (
                                <Skeleton variant="rounded" animation="wave" height={50} />
                            ) : (
                                <FormControl fullWidth>
                                    <InputLabel id="group-simple-select-label">{t("miscellaneous.group")}</InputLabel>
                                    <Select
                                        name="group"
                                        labelId="group-simple-select-label"
                                        IconComponent={KeyboardArrowDownIcon}
                                        value={valuesGen.group}
                                        label={t("miscellaneous.group")}
                                        onChange={handleChangeGroup}
                                        MenuProps={MenuProps}
                                    >
                                        <MenuItem value=" ">
                                            <Typography
                                                variant="body1"
                                                component="span"
                                                align="center"
                                                color="textPrimary"
                                            >
                                                N/A
                                            </Typography>
                                        </MenuItem>
                                        {groups.map((value) => (
                                            <MenuItem value={value.id}>
                                                <Typography
                                                    variant="body1"
                                                    component="span"
                                                    align="center"
                                                    color="textPrimary"
                                                >
                                                    {value.group_name}
                                                </Typography>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )}
                        </Grid>
                        <Grid item xl={6} sm={6} xs={12}>
                            {loadingCard ? (
                                <Skeleton variant="rounded" animation="wave" height={50} />
                            ) : (
                                <TextField
                                    fullWidth
                                    required
                                    label={t("miscellaneous.businessName")}
                                    name="clientRS"
                                    onChange={handleChange}
                                    value={valuesGen.clientRS}
                                    variant="outlined"
                                    error={isError && valuesGen.clientRS === "" ? true : false}
                                />
                            )}
                        </Grid>
                        <Grid item xl={6} sm={6} xs={12}>
                            {loadingCard ? (
                                <Skeleton variant="rounded" animation="wave" height={50} />
                            ) : (
                                <TextField
                                    fullWidth
                                    label={t("miscellaneous.commercialName")}
                                    name="clientNC"
                                    onChange={handleChange}
                                    value={valuesGen.clientNC}
                                    variant="outlined"
                                />
                            )}
                        </Grid>
                        <Grid item xl={3} sm={6} xs={12}>
                            {loadingCard ? (
                                <Skeleton variant="rounded" animation="wave" height={50} />
                            ) : (
                                <FormControl
                                    fullWidth
                                    required
                                    disabled={valuesGen.disabledData}
                                    error={isError && valuesGen.type === "" ? true : false}
                                >
                                    <InputLabel id="type-simple-select-label">{t("miscellaneous.type")}</InputLabel>
                                    <Select
                                        name="type"
                                        labelId="type-simple-select-label"
                                        IconComponent={KeyboardArrowDownIcon}
                                        value={valuesGen.type}
                                        label={t("miscellaneous.type")}
                                        onChange={handleChange}
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    mt: 0.7,
                                                    borderRadius: 2,
                                                    boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                },
                                            },
                                        }}
                                    >
                                        <MenuItem value={1}>{t("miscellaneous.firm")}</MenuItem>
                                        <MenuItem value={2}>SMB</MenuItem>
                                    </Select>
                                </FormControl>
                            )}
                        </Grid>
                        <Grid item xl={3} sm={6} xs={12}>
                            {loadingCard ? (
                                <Skeleton variant="rounded" animation="wave" height={50} />
                            ) : (
                                <FormControl
                                    fullWidth
                                    required
                                    error={isError && valuesGen.clasif === "" ? true : false}
                                >
                                    <InputLabel id="clasi-simple-select-label">
                                        {t("miscellaneous.classification")}
                                    </InputLabel>
                                    <Select
                                        name="clasif"
                                        labelId="clasi-simple-select-label"
                                        IconComponent={KeyboardArrowDownIcon}
                                        value={valuesGen.clasif}
                                        label={t("miscellaneous.classification")}
                                        onChange={handleChange}
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    mt: 0.7,
                                                    borderRadius: 2,
                                                    boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                },
                                            },
                                        }}
                                    >
                                        <MenuItem
                                            value={1}
                                            sx={{
                                                fontStyle: "italic",
                                                ...(moment.locale() === "en" && { fontStyle: "normal" }),
                                            }}
                                        >
                                            Standard
                                        </MenuItem>
                                        <MenuItem
                                            value={2}
                                            sx={{
                                                fontStyle: "italic",
                                                ...(moment.locale() === "en" && { fontStyle: "normal" }),
                                            }}
                                        >
                                            VIP
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            )}
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            {loadingCard ? (
                                <Skeleton variant="rounded" animation="wave" height={50} />
                            ) : (
                                <TextField
                                    fullWidth
                                    label={t("miscellaneous.economicActivity")}
                                    name="clientActivity"
                                    required
                                    onChange={handleChange}
                                    value={valuesGen.clientActivity}
                                    variant="outlined"
                                    error={isError && valuesGen.clientActivity.trim() === "" ? true : false}
                                />
                            )}
                        </Grid>
                        <LocalizationProvider
                            dateAdapter={AdapterMoment}
                            adapterLocale={t("language.locale")}
                            localeText={{ clearButtonLabel: t("miscellaneous.clear") }}
                        >
                            <Grid item xl={2} sm={6} xs={12}>
                                {loadingCard ? (
                                    <Skeleton variant="rounded" animation="wave" height={50} />
                                ) : (
                                    <DatePicker
                                        componentsProps={{
                                            actionBar: { actions: ["clear"] },
                                        }}
                                        value={valuesGen.firmaDate}
                                        onChange={(newValue) => handleChangeFirmaDate(newValue)}
                                        inputFormat={todos.dateFormat}
                                        disabled={
                                            (valuesGen.franq.trim() !== null &&
                                                valuesGen.franq !== undefined &&
                                                valuesGen.franq.trim() !== "") ||
                                            (valuesGen.group.trim() !== null &&
                                                valuesGen.group !== undefined &&
                                                valuesGen.group.trim() !== "")
                                        }
                                        renderInput={(params) => <TextField fullWidth {...params} />}
                                        inputProps={{ readOnly: true }}
                                        label={t("miscellaneous.firmDate")}
                                    />
                                )}
                            </Grid>
                            <Grid item xl={2} sm={6} xs={12}>
                                {loadingCard ? (
                                    <Skeleton variant="rounded" animation="wave" height={50} />
                                ) : (
                                    <DatePicker
                                        componentsProps={{
                                            actionBar: { actions: ["clear"] },
                                        }}
                                        value={valuesGen.startDate}
                                        onChange={handleChangeStartDate}
                                        inputFormat={todos.dateFormat}
                                        disabled
                                        renderInput={(params) => <TextField fullWidth {...params} />}
                                        inputProps={{ readOnly: true }}
                                        label={t("principal.initDate")}
                                    />
                                )}
                            </Grid>
                            <Grid item xl={2} sm={6} xs={12}>
                                {loadingCard ? (
                                    <Skeleton variant="rounded" animation="wave" height={50} />
                                ) : (
                                    <DatePicker
                                        componentsProps={{
                                            actionBar: { actions: ["clear"] },
                                        }}
                                        value={valuesGen.finishDate}
                                        onChange={handleChangeEndDate}
                                        inputFormat={todos.dateFormat}
                                        disabled
                                        renderInput={(params) => <TextField fullWidth {...params} />}
                                        inputProps={{ readOnly: true }}
                                        label={t("principal.endDate")}
                                    />
                                )}
                            </Grid>
                            <Grid item xl={3} sm={6} xs={12}>
                                {loadingCard ? (
                                    <Skeleton variant="rounded" animation="wave" height={50} />
                                ) : (
                                    <FormControl
                                        fullWidth
                                        required
                                        disabled={valuesGen.disableCountry}
                                        error={isError && valuesGen.country === "" ? true : false}
                                    >
                                        <InputLabel id="country-simple-select-label">
                                            {t("miscellaneous.country")}
                                        </InputLabel>
                                        <Select
                                            name="country"
                                            labelId="country-simple-select-label"
                                            id="demo-simple-select"
                                            IconComponent={KeyboardArrowDownIcon}
                                            value={valuesGen.country}
                                            label={t("miscellaneous.country")}
                                            onChange={handleChange}
                                            MenuProps={MenuProps}
                                        >
                                            {MainCountries.map((option) => (
                                                <MenuItem value={option.code}>
                                                    {moment.locale() === "en" ? option.name_en : option.name_es}
                                                </MenuItem>
                                            ))}
                                            <Divider />
                                            {Countries.map((option) => (
                                                <MenuItem value={option.code}>
                                                    {moment.locale() === "en" ? option.name_en : option.name_es}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                )}
                            </Grid>
                            <Grid item xl={3} sm={6} xs={12}>
                                {loadingCard ? (
                                    <Skeleton variant="rounded" animation="wave" height={50} />
                                ) : (
                                    <DatePicker
                                        views={["month"]}
                                        componentsProps={{
                                            actionBar: { actions: ["clear"] },
                                        }}
                                        value={valuesGen.fiscalDate}
                                        onChange={(newValue) => {
                                            handleChangeFiscalDate(newValue);
                                        }}
                                        inputFormat={"MMMM"}
                                        renderInput={(params) => (
                                            <TextField
                                                fullWidth
                                                required
                                                {...params}
                                                error={isError && valuesGen.fiscalDate === null ? true : false}
                                            />
                                        )}
                                        inputProps={{ readOnly: true }}
                                        label={t("miscellaneous.endDateF")}
                                    />
                                )}
                            </Grid>
                            <Grid item xl={3} sm={6} xs={12}>
                                {loadingCard ? (
                                    <Skeleton variant="rounded" animation="wave" height={50} />
                                ) : (
                                    <FormControl
                                        fullWidth
                                        required
                                        disabled={valuesGen.disabledData}
                                        error={isError && valuesGen.langVer === "" ? true : false}
                                    >
                                        <InputLabel id="ver-simple-select-label">
                                            {t("miscellaneous.verbalCommunication")}
                                        </InputLabel>
                                        <Select
                                            name="langVer"
                                            labelId="ver-simple-select-label"
                                            IconComponent={KeyboardArrowDownIcon}
                                            value={valuesGen.langVer}
                                            label={t("miscellaneous.verbalCommunication")}
                                            onChange={handleChange}
                                            MenuProps={{
                                                PaperProps: {
                                                    sx: {
                                                        mt: 0.7,
                                                        borderRadius: 2,
                                                        boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                    },
                                                },
                                            }}
                                        >
                                            <MenuItem value={1}>{t("language.english")}</MenuItem>
                                            <MenuItem value={2}>{t("language.spanish")}</MenuItem>
                                        </Select>
                                    </FormControl>
                                )}
                            </Grid>
                            <Grid item xl={3} sm={6} xs={12}>
                                {loadingCard ? (
                                    <Skeleton variant="rounded" animation="wave" height={50} />
                                ) : (
                                    <FormControl
                                        fullWidth
                                        required
                                        disabled={valuesGen.disabledData}
                                        error={isError && valuesGen.langEsc === "" ? true : false}
                                    >
                                        <InputLabel id="esc-simple-select-label">
                                            {t("miscellaneous.writtenCommunication")}
                                        </InputLabel>
                                        <Select
                                            name="langEsc"
                                            labelId="esc-simple-select-label"
                                            IconComponent={KeyboardArrowDownIcon}
                                            value={valuesGen.langEsc}
                                            label={t("miscellaneous.writtenCommunication")}
                                            onChange={handleChange}
                                            MenuProps={{
                                                PaperProps: {
                                                    sx: {
                                                        mt: 0.7,
                                                        borderRadius: 2,
                                                        boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                    },
                                                },
                                            }}
                                        >
                                            <MenuItem value={1}>{t("language.english")}</MenuItem>
                                            <MenuItem value={2}>{t("language.spanish")}</MenuItem>
                                        </Select>
                                    </FormControl>
                                )}
                            </Grid>
                        </LocalizationProvider>
                    </Grid>
                </CardContent>
            </Card>
            <Card elevation={0}>
                <CardHeader title={t("miscellaneous.expenses")} sx={{ color: "#ff5968" }} />
                <Divider />
                <CardContent sx={{ pb: 5 }}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} sm={6} md>
                            {loadingCard ? (
                                <Skeleton variant="rounded" animation="wave" height={50} />
                            ) : valuesGen.type === 1 ? (
                                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                                    <LightTooltip title={t("currentView.amountx10")} aria-label="validatedMonth">
                                        <InfoIcon sx={{ mr: 1, mb: 2 }} />
                                    </LightTooltip>
                                    <FormControl
                                        fullWidth
                                        required
                                        error={isError && !valuesGen.expenseEstim2}
                                        variant="outlined"
                                    >
                                        <InputLabel htmlFor="outlined-adornment-expenseEstim2">
                                            {t("miscellaneous.expenseEstimate")}
                                        </InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-expenseEstim2"
                                            variant="standard"
                                            name="expenseEstim2"
                                            inputComponent={NumberFormatCustom}
                                            onChange={handleChangeEstim}
                                            inputProps={{
                                                onBlur: () =>
                                                    handleChangeEstim(
                                                        Math.ceil(valuesGen.expenseEstim2 / 10000) * 10000
                                                    ),
                                            }}
                                            value={valuesGen.expenseEstim2}
                                            startAdornment={
                                                <InputAdornment disableTypography position="start">
                                                    $
                                                </InputAdornment>
                                            }
                                            label={t("miscellaneous.expenseEstimate")}
                                        />
                                    </FormControl>
                                </Box>
                            ) : (
                                <FormControl fullWidth required error={isError && !valuesGen.expenseEstim}>
                                    <InputLabel id="estim-simple-select-label">
                                        {t("miscellaneous.expenseEstimate")}
                                    </InputLabel>
                                    <Select
                                        name="expenseEstim"
                                        labelId="estim-simple-select-label"
                                        IconComponent={KeyboardArrowDownIcon}
                                        value={valuesGen.expenseEstim}
                                        label={t("miscellaneous.expenseEstimate")}
                                        onChange={handleChange}
                                        MenuProps={MenuProps}
                                    >
                                        <MenuItem value={-1}>N/A</MenuItem>
                                        <MenuItem value={5000}>
                                            ${parseFloat(5000).toLocaleString(todos.amountFormat)}
                                        </MenuItem>
                                        <MenuItem value={10000}>
                                            ${parseFloat(10000).toLocaleString(todos.amountFormat)}
                                        </MenuItem>
                                        <MenuItem value={25000}>
                                            ${parseFloat(25000).toLocaleString(todos.amountFormat)}
                                        </MenuItem>
                                        <MenuItem value={50000}>
                                            ${parseFloat(50000).toLocaleString(todos.amountFormat)}
                                        </MenuItem>
                                        <MenuItem value={75000}>
                                            ${parseFloat(75000).toLocaleString(todos.amountFormat)}
                                        </MenuItem>
                                        <MenuItem value={100000}>
                                            ${parseFloat(100000).toLocaleString(todos.amountFormat)}
                                        </MenuItem>
                                        <MenuItem value={150000}>
                                            ${parseFloat(150000).toLocaleString(todos.amountFormat)}
                                        </MenuItem>
                                        <MenuItem value={200000}>
                                            ${parseFloat(200000).toLocaleString(todos.amountFormat)}
                                        </MenuItem>
                                        <MenuItem value={250000}>
                                            ${parseFloat(250000).toLocaleString(todos.amountFormat)}
                                        </MenuItem>
                                        <MenuItem value={300000}>
                                            ${parseFloat(300000).toLocaleString(todos.amountFormat)}
                                        </MenuItem>
                                        <MenuItem value={350000}>
                                            ${parseFloat(350000).toLocaleString(todos.amountFormat)}
                                        </MenuItem>
                                        <MenuItem value={400000}>
                                            ${parseFloat(400000).toLocaleString(todos.amountFormat)}
                                        </MenuItem>
                                        <MenuItem value={450000}>
                                            ${parseFloat(450000).toLocaleString(todos.amountFormat)}
                                        </MenuItem>
                                        <MenuItem value={500000}>
                                            ${parseFloat(500000).toLocaleString(todos.amountFormat)}
                                        </MenuItem>
                                        <MenuItem value={550000}>
                                            ${parseFloat(550000).toLocaleString(todos.amountFormat)}
                                        </MenuItem>
                                        <MenuItem value={600000}>
                                            ${parseFloat(600000).toLocaleString(todos.amountFormat)}
                                        </MenuItem>
                                        <MenuItem value={650000}>
                                            ${parseFloat(650000).toLocaleString(todos.amountFormat)}
                                        </MenuItem>
                                        <MenuItem value={700000}>
                                            ${parseFloat(700000).toLocaleString(todos.amountFormat)}
                                        </MenuItem>
                                        <MenuItem value={750000}>
                                            ${parseFloat(750000).toLocaleString(todos.amountFormat)}
                                        </MenuItem>
                                        <MenuItem value={800000}>
                                            ${parseFloat(800000).toLocaleString(todos.amountFormat)}
                                        </MenuItem>
                                        <MenuItem value={850000}>
                                            ${parseFloat(850000).toLocaleString(todos.amountFormat)}
                                        </MenuItem>
                                        <MenuItem value={900000}>
                                            ${parseFloat(900000).toLocaleString(todos.amountFormat)}
                                        </MenuItem>
                                        <MenuItem value={950000}>
                                            ${parseFloat(950000).toLocaleString(todos.amountFormat)}
                                        </MenuItem>
                                        <MenuItem value={1000000}>
                                            ${parseFloat(1000000).toLocaleString(todos.amountFormat)}
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6} md>
                            {loadingCard ? (
                                <Skeleton variant="rounded" animation="wave" height={50} />
                            ) : (
                                <FormControl fullWidth variant="outlined" disabled>
                                    <InputLabel htmlFor="outlined-adornment-amount">
                                        {t("miscellaneous.averageExpenses")}
                                    </InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-amount"
                                        name="expenseAverage"
                                        onChange={handleChange}
                                        value={
                                            valuesGen.expenseAverage
                                                ? parseFloat(valuesGen.expenseAverage).toLocaleString(
                                                      todos.amountFormat,
                                                      { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                                                  )
                                                : ""
                                        }
                                        startAdornment={
                                            <InputAdornment disableTypography position="start">
                                                $
                                            </InputAdornment>
                                        }
                                        label={t("miscellaneous.averageExpenses")}
                                    />
                                </FormControl>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6} md>
                            {loadingCard ? (
                                <Skeleton variant="rounded" animation="wave" height={50} />
                            ) : (
                                <FormControl fullWidth variant="outlined" disabled>
                                    <InputLabel htmlFor="outlined-adornment-scale">
                                        {t("miscellaneous.expenseScale")}
                                    </InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-scale"
                                        startAdornment={
                                            <InputAdornment disableTypography position="start">
                                                $
                                            </InputAdornment>
                                        }
                                        label={t("miscellaneous.expenseScale")}
                                        name="expenseScale"
                                        onChange={handleChange}
                                        value={
                                            valuesGen.expenseScale
                                                ? parseFloat(valuesGen.expenseScale).toLocaleString(todos.amountFormat)
                                                : ""
                                        }
                                    />
                                </FormControl>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6} md>
                            {loadingCard ? (
                                <Skeleton variant="circular" width={50} height={50} animation="wave" />
                            ) : (
                                <LightTooltip title={t("currentView.expenseHist")}>
                                    <IconButton
                                        onClick={() => handleOpenExpenseHistory(client._id)}
                                        sx={{ color: "#2f3190" }}
                                    >
                                        <WorkHistoryIcon />
                                    </IconButton>
                                </LightTooltip>
                            )}
                        </Grid>
                    </Grid>
                </CardContent>
                <Divider />
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        p: 2,
                        pb: 0,
                    }}
                >
                    <Button
                        color="secondary"
                        variant="contained"
                        disableElevation
                        classes={{ root: classes.btnRoot }}
                        onClick={updateClientData}
                    >
                        {t("miscellaneous.save")}
                    </Button>
                </Box>
            </Card>
            <ExpenseHist
                open={openExpenseHist}
                onClose={handleCloseHistory}
                expenseHist={expenseHist}
                valueTab={valueTab}
                setValueTab={setValueTab}
                client={client}
                handleUpdateMonthExpenses={handleUpdateMonthExpenses}
                setExpenseHist={setExpenseHist}
                meses={meses}
                isDisabled={isDisabled}
                setIsDisabled={setIsDisabled}
                expenses={valuesGen.expenseEstim}
            />
        </StyledContainer>
    );
};

export default ClientGen;
