const services = [
    {
        code: 1,
        name: "accountant",
        country: ["US", "GB", "LA", "VE"]
    },
    {
        code: 5,
        name: "bookkeeper",
        country: ["US", "GB", "LA", "VE"]
    },
    {
        code: 3,
        name: "cfo",
        country: ["US", "GB", "LA", "VE"]
    },
    {
        code: 2,
        name: "controller",
        country: ["US", "GB", "LA", "VE"]
    },
    {
        code: 8,
        name: "softSetup",
        country: ["US", "GB", "LA", "VE"]
    },
    {
        code: 9,
        name: "softSubs",
        country: ["US", "GB", "LA", "VE"]
    },
    {
        code: 4,
        name: "taxExpert",
        country: ["US", "GB", "LA", "VE"]
    },
    {
        code: 6,
        name: "compilation",
        country: ["US", "GB", "LA", "VE"]
    },
    {
        code: 7,
        name: "review",
        country: ["US", "GB", "LA", "VE"]
    },
    {
        code: 10,
        name: "processesAuto",
        country: ["US", "GB", "LA", "VE"]
    },
    {
        code: 11,
        name: "consulting",
        country: ["US", "GB", "LA", "VE"]
    },
    {
        code: 12,
        name: "otherTax",
        country: ["VE"]
    },
    {
        code: 13,
        name: "otherServ",
        country: ["US", "GB", "LA", "VE"]
    },
]

export default services;