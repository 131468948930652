/* React imports */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

/* Components */
import Backdrop from "./internos/Backdrop";
import authService from "../services/auth";

const Loading = () => {
    /** Internationalization i18n */
    const [t, i18n] = useTranslation("global");

    /* React redux */
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const todos = useSelector((state) => state.todos);

    /** Component states */
    const [showBackdrop, setBackdrop] = useState(false);

    /** Component events */
    useEffect(() => {
        const fetchdata = async () => {
            setBackdrop(true);
            await authService
                .checkSession()
                .then(async (isActive) => {
                    if (!isActive) {
                        sessionStorage.removeItem("redirect");
                        sessionStorage.removeItem("option");
                        dispatch({
                            type: "clearInfo",
                        });
                        navigate("/");
                    } else {
                        dispatch({
                            type: "addAuth",
                            item: true,
                        });
                        dispatch({
                            type: "addSelected",
                            item: "ddlPrincipal",
                        });

                        await authService
                            .getUserConfig(todos.userInfo._id)
                            .then((res) => {
                                if (res.status === 204) {
                                    sessionStorage.setItem("lng", "es");
                                    i18n.changeLanguage("es");
                                    document.title = t("principal.gcaPlatform");

                                    dispatch({
                                        type: "addDateFormat",
                                        item: "DD/MM/YYYY",
                                    });
                                    dispatch({
                                        type: "addAmountFormat",
                                        item: "de-DE",
                                    });
                                    dispatch({
                                        type: "addTutorial",
                                        item: false,
                                    });
                                } else {
                                    sessionStorage.setItem("lng", res.data.language);
                                    i18n.changeLanguage(res.data.language);
                                    document.title = t("principal.gcaPlatform");

                                    dispatch({
                                        type: "addDateFormat",
                                        item: res.data.date_format,
                                    });
                                    dispatch({
                                        type: "addAmountFormat",
                                        item: res.data.amount_format,
                                    });
                                    dispatch({
                                        type: "addTutorial",
                                        item: res.data.tutorial,
                                    });
                                }
                            })
                            .catch((err) => {
                                console.log(err.response);
                            });

                        if (sessionStorage.getItem("redirect") === "true") {
                            dispatch({
                                type: "addSelected",
                                item: "ddlRequirements",
                            });
                            navigate("/requirements?unassigned=true");
                        } else if (todos.userInfo.role === "External") {
                            dispatch({
                                type: "addAuth",
                                item: false,
                            });

                            await fetch(`/getClientsByEmail?contact_email=${todos.userInfo.email}`, {
                                method: "GET",
                                headers: {
                                    "Content-type": "application/json; charset=UTF-8",
                                    "Access-Control-Allow-Origin": "*",
                                },
                            })
                                .then((res) => res.json())
                                .then((data) => {
                                    if (data.companies.length !== 0) {
                                        dispatch({
                                            type: "addAuthEx",
                                            item: data.contact_closure,
                                        });
                                        dispatch({
                                            type: "addAuthReq",
                                            item: data.contact_requirements,
                                        });
                                        dispatch({
                                            type: "addAuthStatus",
                                            item: data.contact_status,
                                        });
                                        dispatch({
                                            type: "addAuthInvoice",
                                            item: data.contact_invoices,
                                        });
                                        dispatch({
                                            type: "addSelected",
                                            item: "ddlPrincipal",
                                        });
                                        if (data.contact_closure) {
                                            navigate(`/dashboard/banks`);
                                        } else if (data.contact_requirements) {
                                            navigate(`/clientRequirements`);
                                        } else if (data.contact_status) {
                                            navigate(`/clientGlobalReport`);
                                        } else if (data.contact_invoices) {
                                            navigate(`/clientInvoicing`);
                                        } else {
                                            navigate(`/comebackSoon`);
                                        }
                                    } else {
                                        navigate(`/comebackSoon`);
                                    }
                                });
                        } else {
                            navigate("/dashboard/general");
                        }
                    }
                })
                .catch((error) => {
                    console.log(error.response);
                });
            setBackdrop(false);
        };
        fetchdata();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, navigate]);

    return <Backdrop open={showBackdrop} />;
};

export default Loading;
