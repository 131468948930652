/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

/** Icons/images */
import Error404 from "../assets/images/404.png";

/** MUI imports */
import { styled } from "@mui/material/styles";
import { Box, Button, Grid, Typography } from "@mui/material";

const PREFIX = "Error";

const classes = {
    root: `${PREFIX}-root`,
    commonWhite: `${PREFIX}-commonWhite`,
    errorImg: `${PREFIX}-errorImg`,
    oopsText: `${PREFIX}-oopsText`,
    notFoundText: `${PREFIX}-notFoundText`,
    backBtn: `${PREFIX}-backBtn`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`&.${classes.root}`]: {
        flexGrow: 1,
        width: "100%",
        height: "100%",
        backgroundColor: "#031851",
        [theme.breakpoints.up("xs")]: {
            padding: "60px 30px",
        },
        [theme.breakpoints.up("md")]: {
            padding: 70,
        },
        [theme.breakpoints.up("xl")]: {
            padding: 115,
        },
    },

    [`& .${classes.errorImg}`]: {
        marginLeft: "auto",
        marginRight: "auto",
        display: "block",
        [theme.breakpoints.up("xs")]: {
            height: 130,
        },
        [theme.breakpoints.up("sm")]: {
            height: 198,
        },
        [theme.breakpoints.up("md")]: {
            height: 280,
        },
        [theme.breakpoints.up("xl")]: {
            height: 380,
        },
    },

    [`& .${classes.oopsText}`]: {
        [theme.breakpoints.up("xs")]: {
            fontSize: 40,
        },
        [theme.breakpoints.up("md")]: {
            fontSize: 50,
        },
    },

    [`& .${classes.notFoundText}`]: {
        color: "#FFF",
        [theme.breakpoints.up("xs")]: {
            fontSize: 20,
        },
        [theme.breakpoints.up("md")]: {
            fontSize: 25,
        },
    },

    [`& .${classes.backBtn}`]: {
        marginTop: 60,
        borderRadius: 40,
        paddingLeft: 40,
        paddingRight: 40,
        fontSize: 18,
        "&:hover": {
            backgroundColor: "white",
            color: theme.palette.text.primary,
        },
    },
}));

const Error = () => {
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    /* React redux */
    const dispatch = useDispatch();

    useEffect(() => {}, [t]);

    return (
        <StyledContainer component="main" className={classes.root}>
            <Grid container justifyContent="center">
                <Grid item xs={12}>
                    <img src={Error404} alt="404" className={classes.errorImg} />
                </Grid>
            </Grid>
            <Grid container justifyContent="center" spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h5" color="secondary" align="center" className={classes.oopsText}>
                        Oops
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h5" color="secondary" align="center" className={classes.notFoundText}>
                        {t("error.notFound")}
                    </Typography>
                </Grid>
                <Grid item container xs={12} justifyContent="center">
                    <Button
                        component={Link}
                        to="/dashboard/general"
                        disableElevation
                        variant="contained"
                        color="secondary"
                        className={classes.backBtn}
                        onClick={() => {
                            sessionStorage.setItem("option", 1);
                            dispatch({
                                type: "addSelected",
                                item: "ddlPrincipal",
                            });
                        }}
                    >
                        {t("miscellaneous.goBack")}
                    </Button>
                </Grid>
            </Grid>
        </StyledContainer>
    );
};

export default Error;
