/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";

/** MUI icons */
import CircleIcon from "@mui/icons-material/Circle";
import CommentIcon from "@mui/icons-material/Comment";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

/** MUI imports */
import { Grid, Table, TableHeaderRow, PagingPanel, Toolbar, SearchPanel } from "@devexpress/dx-react-grid-material-ui";
import { SearchState, IntegratedFiltering, PagingState, IntegratedPaging } from "@devexpress/dx-react-grid";
import { styled } from "@mui/material/styles";
import GridUI from "@mui/material/Grid";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import {
    Badge,
    Box,
    Button,
    Breadcrumbs,
    Card,
    CardHeader,
    CardContent,
    IconButton,
    Stack,
    Skeleton,
    Typography,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from "@mui/material";

/** Components imports */
import Backdrop from "../../Backdrop";
import CommentDialog from "../../CommentDialog";
import SnackBar from "../../SnackBar";

const PREFIX = "ClientGlobalReport";

const classes = {
    paper: `${PREFIX}-paper`,
    toolbar: `${PREFIX}-toolbar`,
    navbarLogo: `${PREFIX}-navbarLogo`,
    commonWhite: `${PREFIX}-commonWhite`,
    cardContentRoot: `${PREFIX}-cardContentRoot`,
    tableStriped: `${PREFIX}-tableStriped`,
    tableHeader: `${PREFIX}-tableHeader`,
    tableBody: `${PREFIX}-tableBody`,
    pager: `${PREFIX}-pager`,
    toolbarSearch: `${PREFIX}-toolbarSearch`,
    searchInput: `${PREFIX}-searchInput`,
    button: `${PREFIX}-button`,
    formRounded: `${PREFIX}-formRounded`,
    statusRow: `${PREFIX}-statusRow`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.formRounded}`]: {
        backgroundColor: "#FFFFFF !important",
        borderRadius: 40,
        verticalAlign: "middle !important",
        "&:focus-within": {
            backgroundColor: "#FFFFFF",
            borderRadius: 40,
        },
        "& .MuiOutlinedInput-notchedOutline": {
            border: "2px solid #031851",
            borderRadius: 40,
        },
    },

    [`& .${classes.paper}`]: {
        border: "2px solid #ff5968",
        borderRadius: 40,
        boxShadow: "none",
    },

    [`& .${classes.statusRow}`]: {
        "& svg, span": {
            display: "inline-block",
            verticalAlign: "middle",
        },
        "& span": {
            paddingLeft: 10,
        },
    },

    [`& .${classes.toolbar}`]: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "#031851",
    },

    [`& .${classes.navbarLogo}`]: {
        display: "block",
        height: 50,
        [theme.breakpoints.down("sm")]: {
            width: "165px",
        },
    },

    [`& .${classes.commonWhite}`]: {
        color: "#fff",
    },

    [`& .${classes.cardContentRoot}`]: {
        "&:last-child": {
            paddingBottom: "18px",
        },
        padding: 30,
    },

    [`& .${classes.tableStriped}`]: {
        "& tbody tr:nth-of-type(odd)": {
            backgroundColor: "rgba(47,49,144,0.1)",
        },
    },

    [`& .${classes.tableHeader}`]: {
        border: "none",
        backgroundColor: "rgba(47,49,144,0.3)",
        fontSize: "15px",
        fontWeight: 600,
        color: "#031851",
        "&:first-of-type": {
            borderRadius: "20px 0 0 0",
        },
        "&:last-child": {
            borderRadius: "0 20px 0 0",
        },
    },

    [`& .${classes.tableBody}`]: {
        border: "none",
        fontSize: "14px",
        fontWeight: 400,
        color: "#031851",
    },

    [`& .${classes.pager}`]: {
        "& .Pagination-button": {
            paddingTop: 0,
            paddingBottom: 0,
            color: "#031851",
            fontWeight: 600,
        },
        "& .Pagination-activeButton": {
            backgroundColor: "rgba(47,49,144,0.3)",
        },
    },

    [`& .${classes.toolbarSearch}`]: {
        border: "none !important",
        padding: "0 0 5px !important",
    },

    [`& .${classes.searchInput}`]: {
        color: "#031851",
        "&:before": {
            borderBottomColor: "#031851",
        },
        "&:hover:before": {
            borderBottomColor: "#031851 !important",
        },
    },
}));

const tableHeaderCell = ({ ...props }) => <TableHeaderRow.Cell {...props} className={classes.tableHeader} />;

const TableComponent = ({ ...props }) => <Table.Table {...props} className={classes.tableStriped} />;

const TableCellComponent = ({ ...props }) => <Table.Cell {...props} className={classes.tableBody} />;

const tableContentComponent = (props) => <TableHeaderRow.Content {...props} />;

const PagingPanelContainer = ({ ...props }) => <PagingPanel.Container {...props} className={classes.pager} />;

const inputComponent = ({ ...props }) => <SearchPanel.Input {...props} className={classes.searchInput} />;

const toolRootComponent = ({ ...props }) => <Toolbar.Root {...props} className={classes.toolbarSearch} />;

var resultTable = [];

const tableColumnExtensions = [
    { columnName: "client", align: "left" },
    { columnName: "comments", align: "center" },
    { columnName: "jan", align: "center", width: "5%" },
    { columnName: "feb", align: "center", width: "5%" },
    { columnName: "mar", align: "center", width: "5%" },
    { columnName: "apr", align: "center", width: "5%" },
    { columnName: "may", align: "center", width: "5%" },
    { columnName: "jun", align: "center", width: "5%" },
    { columnName: "jul", align: "center", width: "5%" },
    { columnName: "aug", align: "center", width: "5%" },
    { columnName: "sep", align: "center", width: "5%" },
    { columnName: "oct", align: "center", width: "5%" },
    { columnName: "nov", align: "center", width: "5%" },
    { columnName: "dec", align: "center", width: "5%" },
];

const rowsSkeleton = [];

for (let i = 0; i < 10; i++) {
    rowsSkeleton.push({
        id: i,
        client: <Skeleton variant="text" animation="wave" />,
        comments: (
            <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />
        ),
        jan: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        feb: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        mar: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        apr: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        may: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        jun: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        jul: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        aug: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        sep: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        oct: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        nov: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
        dec: <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />,
    });
}

const ColorButton = styled(Button)(({ theme }) => ({
    borderRadius: 40,
    paddingLeft: 40,
    paddingRight: 40,
    "&:hover": {
        backgroundColor: theme.palette.text.secondary,
        color: "#fff",
    },
}));

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "rgba(19, 31, 62, 0.80)",
        fontSize: 12,
        maxWidth: 320,
        borderRadius: 6,
        fontWeight: "normal",
    },
});

const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
        backgroundColor: "#2f3190",
        color: "white",
    },
}));

const ClientGlobalReport = () => {
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    const tableMessages = {
        noData: t("miscellaneous.noData"),
    };
    const pagingMessages = {
        info: ({ from, to, count }) => `${from}${from < to ? `-${to}` : ""} ${t("miscellaneous.pagingOf")} ${count}`,
    };

    const searchMessages = {
        searchPlaceholder: t("miscellaneous.search"),
    };

    /* React redux */
    const todos = useSelector((state) => state.todos);

    /** Component states */
    const [showBackdrop, setBackDrop] = useState(false);
    const [show, setShow] = useState(false);
    const [texto, setTexto] = useState("");
    const [alertType, seAutoAlertType] = useState("");
    const [searchValue, setSearchState] = useState("");
    const [clientsData, setClients] = useState([]);
    const [columnDefs, setColumnsDefs] = useState([]);
    const [dialogComment, setDialogComment] = useState(false);
    const [clientID, setclientID] = useState("");
    const [comments, setComments] = useState([]);
    const [commentsP, setCommentsPend] = useState([]);
    const [yearsToNoti, setyearsToNoti] = useState([]);
    const [selectedYear, setselectedYear] = useState(moment().year());
    const [loading, showLoading] = useState(false);

    /** Component variables */
    const breadcrumbs = [
        <Typography
            key="1"
            variant="h1"
            color="primary"
            sx={{
                fontSize: 24,
                fontWeight: 700,
            }}
        >
            {t("miscellaneous.smbStatus")}
        </Typography>,
    ];

    /** Component functions */
    const handleSearch = (value) => {
        let itemS = value.replace(/ +(?= )/g, "");
        setSearchState(itemS);
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setShow(false);
    };

    const openComments = async (clientId) => {
        setclientID(clientId);

        await getComments(clientId);

        setDialogComment(true);
    };

    const closeDialog = () => {
        setDialogComment(false);
    };

    const getComments = async (clientId) => {
        await fetch(`/getComments?company_id=${clientId}&period=${selectedYear}&comment_type=1`, {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then((res) => res.json())
            .then((data) => {
                setComments(data.history);
                setCommentsPend(data.pendings);
            });
    };

    const handleClick = (event) => {
        setselectedYear(event.target.value);
    };
    
    const getColorStatus = (id_status) => {
        const colors = ["#F8F9F8", "#f1919e", "#ffb23e", "#9ad2dd", "#FFB23E", "#8bc985", "#318a16"];

        return colors[id_status];
    };

    const loadInitData = async () => {
        resultTable = await fetch(
            `/getMonitoring?client_type=&practice_id=&franchise_id=&client_status=&contact_email=${todos.userInfo.email}&id_status=&year=${selectedYear}&service_type=`,
            {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            }
        )
            .then((res) => res.json())
            .then((data) => {
                return data;
            });

        let clientsdata = resultTable.map((valItem, index) => {
            let cont = 0;
            valItem.comments.forEach((data) => {
                if (data.status === 0) {
                    cont++;
                }
            });
            return {
                ID: valItem.id_organisation,
                client: valItem.invalid ? (
                    <Typography variant="h4" color="secondary">
                        {valItem.company_name}
                    </Typography>
                ) : (
                    valItem.company_name
                ),
                comments: (
                    <LightTooltip title={t("miscellaneous.comments")}>
                        <IconButton
                            aria-label="comments"
                            sx={valItem.comments.length === 0 ? { color: "rgba(0, 0, 0, 0.54)" } : { color: "#2f3190" }}
                            onClick={() => openComments(valItem.id_organisation)}
                        >
                            <StyledBadge badgeContent={cont}>
                                <CommentIcon />
                            </StyledBadge>
                        </IconButton>
                    </LightTooltip>
                ),
                jan: (
                    <CircleIcon
                        fontSize="large"
                        sx={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            color: getColorStatus(valItem.notify_jan),
                        }}
                    />
                ),
                feb: (
                    <CircleIcon
                        fontSize="large"
                        sx={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            color: getColorStatus(valItem.notify_feb),
                        }}
                    />
                ),
                mar: (
                    <CircleIcon
                        fontSize="large"
                        sx={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            color: getColorStatus(valItem.notify_mar),
                        }}
                    />
                ),
                apr: (
                    <CircleIcon
                        fontSize="large"
                        sx={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            color: getColorStatus(valItem.notify_apr),
                        }}
                    />
                ),
                may: (
                    <CircleIcon
                        fontSize="large"
                        sx={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            color: getColorStatus(valItem.notify_may),
                        }}
                    />
                ),
                jun: (
                    <CircleIcon
                        fontSize="large"
                        sx={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            color: getColorStatus(valItem.notify_jun),
                        }}
                    />
                ),
                jul: (
                    <CircleIcon
                        fontSize="large"
                        sx={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            color: getColorStatus(valItem.notify_jul),
                        }}
                    />
                ),
                aug: (
                    <CircleIcon
                        fontSize="large"
                        sx={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            color: getColorStatus(valItem.notify_aug),
                        }}
                    />
                ),
                sep: (
                    <CircleIcon
                        fontSize="large"
                        sx={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            color: getColorStatus(valItem.notify_sep),
                        }}
                    />
                ),
                oct: (
                    <CircleIcon
                        fontSize="large"
                        sx={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            color: getColorStatus(valItem.notify_oct),
                        }}
                    />
                ),
                nov: (
                    <CircleIcon
                        fontSize="large"
                        sx={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            color: getColorStatus(valItem.notify_nov),
                        }}
                    />
                ),
                dec: (
                    <CircleIcon
                        fontSize="large"
                        sx={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            color: getColorStatus(valItem.notify_dec),
                        }}
                    />
                ),
            };
        });

        setClients(clientsdata);
    };

    const downloadReport = async () => {
        setBackDrop(true);

        let filename = "Status report.pdf";

        await fetch(
            `/generateMonitoringReport?client_type=&practice_id=&franchise_id=&client_status=&contact_email=${
                todos.userInfo.email
            }&id_status=&year=${selectedYear}&service_type=&lang=${t("language.locale")}&portal=external`,
            {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            }
        )
            .then((res) => res.blob())
            .then((blob) => {
                const link = document.createElement("a");
                link.href = URL.createObjectURL(blob);
                link.setAttribute("download", filename);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch((err) => {
                setTexto(t("manageAccount.dnldError"));
                seAutoAlertType("warning");
                setShow(true);
            });

        setBackDrop(false);
    };

    /* Component events */
    useEffect(() => {
        (async () => {
            showLoading(true);

            const columnsTemplate = [
                { name: "client", title: "SMB" },
                { name: "comments", title: " " },
                { name: "jan", title: t("monthNamesShort.jan") },
                { name: "feb", title: t("monthNamesShort.feb") },
                { name: "mar", title: t("monthNamesShort.mar") },
                { name: "apr", title: t("monthNamesShort.apr") },
                { name: "may", title: t("monthNamesShort.may") },
                { name: "jun", title: t("monthNamesShort.jun") },
                { name: "jul", title: t("monthNamesShort.jul") },
                { name: "aug", title: t("monthNamesShort.aug") },
                { name: "sep", title: t("monthNamesShort.sep") },
                { name: "oct", title: t("monthNamesShort.oct") },
                { name: "nov", title: t("monthNamesShort.nov") },
                { name: "dec", title: t("monthNamesShort.dec") },
            ];
            setColumnsDefs(columnsTemplate);

            await fetch(`/getNotificationYears?client_type=&practice_id=&franchise_id=`, {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
                .then((res) => res.json())
                .then((data) => {
                    setyearsToNoti(data);
                });

            showLoading(false);
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t]);

    useEffect(() => {
        setClients(rowsSkeleton);

        loadInitData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedYear]);

    return (
        <StyledContainer component="main" sx={{ flexGrow: 1, p: 4, width: "100%", pt: 10 }}>
            <Stack sx={{ pb: 4 }}>
                <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small" sx={{ color: "#031851" }} />}
                    aria-label="breadcrumb"
                >
                    {breadcrumbs}
                </Breadcrumbs>
            </Stack>
            <GridUI container spacing={2} justifyContent="center" alignItems="center">
                <GridUI item xs={12}>
                    <GridUI container spacing={2}>
                        <GridUI item xs={12} lg={12}>
                            <Card
                                elevation={0}
                                sx={{
                                    borderRadius: 8,
                                    boxShadow: "10px 10px 15px rgba(3,24,81,0.15)",
                                }}
                            >
                                <CardHeader
                                    title={
                                        <GridUI container alignItems="center" justifyContent="space-between">
                                            <LightTooltip title={t("clientsTable.awaitingRequirementsTool")}>
                                                <GridUI item className={classes.statusRow}>
                                                    <CircleIcon fontSize="small" sx={{ color: "#F1919E" }} />
                                                    <Typography component="span">
                                                        {t("clientsTable.awaitingRequirements")}
                                                    </Typography>
                                                </GridUI>
                                            </LightTooltip>
                                            <LightTooltip title={t("miscellaneous.progressParTool")}>
                                                <GridUI item className={classes.statusRow}>
                                                    <CircleIcon fontSize="small" sx={{ color: "#FFB23E" }} />
                                                    <Typography component="span">
                                                        {t("miscellaneous.partilPro")}
                                                    </Typography>
                                                </GridUI>
                                            </LightTooltip>
                                            <LightTooltip title={t("miscellaneous.progressTool")}>
                                                <GridUI item className={classes.statusRow}>
                                                    <CircleIcon fontSize="small" sx={{ color: "#9AD2DD" }} />
                                                    <Typography component="span">
                                                        {t("clientsTable.inProgress")}
                                                    </Typography>
                                                </GridUI>
                                            </LightTooltip>
                                            <LightTooltip title={t("recurrent.partReportTool")}>
                                                <GridUI item className={classes.statusRow}>
                                                    <CircleIcon fontSize="small" sx={{ color: "#8BC985" }} />
                                                    <Typography component="span">
                                                        {t("recurrent.partReport")}
                                                    </Typography>
                                                </GridUI>
                                            </LightTooltip>
                                            <LightTooltip title={t("miscellaneous.closedTool")}>
                                                <GridUI item className={classes.statusRow}>
                                                    <CircleIcon fontSize="small" sx={{ color: "#318A16" }} />
                                                    <Typography component="span">
                                                        {t("miscellaneous.closed")}
                                                    </Typography>
                                                </GridUI>
                                            </LightTooltip>
                                            <GridUI item xs={12} md={3} lg={4}>
                                                <GridUI container alignItems="center" justifyContent="flex-end">
                                                    <GridUI item>
                                                        <ColorButton
                                                            variant="contained"
                                                            color="secondary"
                                                            disableElevation
                                                            onClick={downloadReport}
                                                        >
                                                            {t("miscellaneous.downloadPdf")}
                                                        </ColorButton>
                                                    </GridUI>
                                                </GridUI>
                                            </GridUI>
                                        </GridUI>
                                    }
                                    sx={{
                                        p: "30px 0px 24px",
                                        m: "0px 30px",
                                        borderBottom: "2px solid #031851",
                                    }}
                                />
                                <CardContent classes={{ root: classes.cardContentRoot }}>
                                    {loading ? (
                                        <GridUI container spacing={2} className={classes.spacing} alignItems="center">
                                            <GridUI item xs={12} md={3} xl={1}>
                                                <Skeleton
                                                    variant="rectangular"
                                                    animation="wave"
                                                    width="100%"
                                                    height={40}
                                                    sx={{ borderRadius: 8 }}
                                                />
                                            </GridUI>
                                        </GridUI>
                                    ) : (
                                        <GridUI container alignItems="center">
                                            <GridUI item xs={12} md={3} xl={1}>
                                                <FormControl fullWidth classes={{ root: classes.formRounded }}>
                                                    <InputLabel id="year-simple-select-label">
                                                        {t("miscellaneous.year")}
                                                    </InputLabel>
                                                    <Select
                                                        value={selectedYear}
                                                        labelId="year-simple-select-label"
                                                        onChange={handleClick}
                                                        IconComponent={KeyboardArrowDownIcon}
                                                        className="ddlGeneral"
                                                        size="small"
                                                        label={t("miscellaneous.year")}
                                                        MenuProps={{
                                                            PaperProps: {
                                                                sx: {
                                                                    mt: 0.7,
                                                                    borderRadius: 2,
                                                                    boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                                },
                                                            },
                                                        }}
                                                    >
                                                        {yearsToNoti.map((year, index) => (
                                                            <MenuItem key={index} value={year}>
                                                                <Typography
                                                                    variant="body1"
                                                                    component="span"
                                                                    align="center"
                                                                    color="textPrimary"
                                                                >
                                                                    {year}
                                                                </Typography>
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </GridUI>
                                        </GridUI>
                                    )}
                                    <Grid rows={clientsData} columns={columnDefs}>
                                        <SearchState value={searchValue} onValueChange={handleSearch} />
                                        <IntegratedFiltering />
                                        <PagingState defaultCurrentPage={0} pageSize={10} />
                                        <IntegratedPaging />
                                        <Table
                                            tableComponent={TableComponent}
                                            columnExtensions={tableColumnExtensions}
                                            cellComponent={TableCellComponent}
                                            messages={tableMessages}
                                        />
                                        <TableHeaderRow
                                            cellComponent={tableHeaderCell}
                                            contentComponent={tableContentComponent}
                                        />
                                        <Toolbar rootComponent={toolRootComponent} />
                                        <SearchPanel messages={searchMessages} inputComponent={inputComponent} />
                                        <PagingPanel
                                            containerComponent={PagingPanelContainer}
                                            messages={pagingMessages}
                                        />
                                    </Grid>
                                </CardContent>
                            </Card>
                        </GridUI>
                    </GridUI>
                </GridUI>
            </GridUI>
            <CommentDialog
                open={dialogComment}
                onClose={closeDialog}
                maxWidth="xs"
                clientID={clientID}
                setTexto={setTexto}
                seAutoAlertType={seAutoAlertType}
                setBackDrop={setBackDrop}
                setShow={setShow}
                comments={comments}
                commentsP={commentsP}
                getComments={getComments}
                setInitData={loadInitData}
                year={moment().year()}
                commentType={1}
            />
            <Backdrop open={showBackdrop} />
            <SnackBar type={alertType} show={show} message={texto} onClose={handleClose} />
        </StyledContainer>
    );
};

export default ClientGlobalReport;
