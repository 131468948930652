// React
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";
import moment from "moment";
import PropTypes from "prop-types";

/** MUI icons */
import EditIcon from "@mui/icons-material/Edit";
import EditOffIcon from "@mui/icons-material/EditOff";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AddIcon from "@mui/icons-material/Add";

// MUI
import {
    Alert,
    Box,
    Card,
    CardContent,
    CardHeader,
    Divider,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    TextField,
    Select,
    MenuItem,
    FormControlLabel,
    Switch,
    Typography,
    Tab,
    Tabs,
    Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

/** Components imports */
import { Additionals } from "./";
import DialogAlerts from "../../../DialogAlerts";
import NewPeriodDialog from "../../../Dialog";

/** Component styles */
const PREFIX = "Recurring";

const classes = {
    btnRoot: `${PREFIX}-btnRoot`,
    switch: `${PREFIX}-switch`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.btnRoot}`]: {
        border: "1px solid #ff5968",
        borderRadius: 40,
        "&:hover": {
            backgroundColor: "#2f3190",
            border: "1px solid #2f3190",
        },
    },
    [`& .${classes.switch}`]: {
        "& .MuiSwitch-switchBase .MuiSwitch-thumb": {
            color: "#FFF",
        },
        "& .MuiSwitch-switchBase + .MuiSwitch-track": {
            backgroundColor: "#031851",
        },
        "& .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb": {
            color: "#FF5968",
        },
        "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
            backgroundColor: "#FFA8B1",
        },
    },
}));

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "rgba(19, 31, 62, 0.80)",
        fontSize: 12,
        maxWidth: 320,
        borderRadius: 6,
        fontWeight: "normal",
    },
});

const CustomTab = styled(Tab)(({ theme }) => ({
    padding: 10,
    marginLeft: 8,
    borderRadius: "20px 20px 0 0",
    backgroundColor: "#E9EAE8",
    color: theme.palette.primary.main,
    fontWeight: 700,
    "&.Mui-selected": {
        backgroundColor: "#2f3190 !important",
        color: "#fff",
    },
}));

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
        </div>
    );
};

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const ColorButton = styled(Button)(({ theme }) => ({
    border: "1px solid #ff5968",
    borderRadius: 40,
}));

const NumberFormatCustom = (props) => {
    const todos = useSelector((state) => state.todos);

    const { id, inputRef, value, onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            value={value}
            getInputRef={inputRef}
            onValueChange={({ value: v }) => onChange({ target: { value: v } })}
            thousandSeparator={todos.amountFormat === "de-DE" ? "." : ","}
            decimalSeparator={todos.amountFormat === "de-DE" ? "," : "."}
            fixedDecimalScale={true}
            decimalScale={id !== "outlined-adornment-expenses" ? 2 : 0}
            valueIsNumericString
        />
    );
};

const defaultState = {
    originalExpenses: 0,
    expenses: 0,
    originalFee: 0,
    projectFee: 0,
    projectDiscount: 0,
    projectTotal: 0,
    additionalsDiscount: 0,
    // Service Responsibility
    paymentType: 2,
    respIniDate: null,
    initDate: null,
    endDate: null,
    estDeliDate: null,
    deliDate: null,
    // Additionals
    additionalsOnly: false,
    checkedController: false,
    tothController: 0,
    startDateController: null,
    finishDateController: null,
    checkedExe: false,
    tothExe: 0,
    startDateExe: null,
    finishDateExe: null,
    checkedCon: false,
    tothCon: 0,
    startDateCon: null,
    finishDateCon: null,
    checkedRep: false,
    tothRep: 0,
    startDateRep: null,
    finishDateRep: null,
    // Others
    checkedOthers: false,
    nameOthers: "",
    tothOthers: 0,
    startDateOthers: null,
    finishDateOthers: null,
    // Total
    addHourFee: 0,
};

const latam = [
    "Argentina",
    "Bolivia",
    "Brasil",
    "Chile",
    "Colombia",
    "Costa Rica",
    "Cuba",
    "Ecuador",
    "El Salvador",
    "Guatemala",
    "Haití",
    "Honduras",
    "México",
    "Nicaragua",
    "Panamá",
    "Paraguay",
    "Perú",
    "República Dominicana",
    "Uruguay",
];

const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
};

export const Recurring = (props) => {
    /** Properties */
    const {
        controlSystem,
        clientMarket,
        companyID,
        data,
        expenseEst,
        reloadProjects,
        serviceID,
        setalertType,
        setBackDrop,
        setShow,
        setTexto,
        handleCloseDialog,
        infoAdd,
        checkRemind,
        open,
        bodyMess,
        showConfirm,
    } = props;

    /** Internationalization i18n */
    const [t] = useTranslation("global");

    /** React redux */
    const todos = useSelector((state) => state.todos);
    const dispatch = useDispatch();

    /** Component states */
    const [valuesRecurring, setValuesRecurring] = useState(defaultState);
    const [editMode, setEditMode] = useState(true);
    const [editMode2, setEditMode2] = useState(true);
    const [isError, setIsError] = useState(false);
    const [valueTab, setValueTab] = useState(0);
    const [showTabs, setshowTabs] = useState(false);
    const [periods, setPeriods] = useState([]);
    const [isErrorNewPeriod, setIsErrorNewPeriod] = useState(false);
    const [dialogNewPeriod, setDialogNewPeriod] = useState(false);
    const [newPeriod, setNewPeriod] = useState(null);

    /** Component events */
    const validateDate = () => {
        let isSameYear = valuesRecurring.endDate?.format("YYYY") === moment().format("YYYY");
        return isSameYear ? moment() : valuesRecurring.endDate;
    };

    const sumTotal =
        (valuesRecurring.finishDateExe?.isBefore(validateDate) ? 0 : parseFloat(valuesRecurring.tothExe)) +
        (valuesRecurring.finishDateCon?.isBefore(validateDate) ? 0 : parseFloat(valuesRecurring.tothCon)) +
        (valuesRecurring.finishDateRep?.isBefore(validateDate) ? 0 : parseFloat(valuesRecurring.tothRep)) +
        (valuesRecurring.finishDateOthers?.isBefore(validateDate)
            ? 0
            : parseFloat(valuesRecurring.tothOthers));

    const getTotalHours = () => {
        let sumTotal =
            parseFloat(valuesRecurring.tothExe) +
            parseFloat(valuesRecurring.tothCon) +
            parseFloat(valuesRecurring.tothRep) +
            parseFloat(valuesRecurring.tothOthers)
        return sumTotal;
    };

    useEffect(() => {
        const addHourFee = clientMarket === "Venezuela" ? 45 : latam.includes(clientMarket) ? 105 : 150;

        if (data.projects_array && data.projects_array.length !== 0) {
            let controllerAdditionalData = {};
            let exeAdditionalData = {};
            let conAdditionalData = {};
            let repAdditionalData = {};
            let othersAdditionalData = {};
            let aux = [];

            data.projects_array.forEach((item, index) => {
                let obj = {
                    project_company_id: item.project_company_id,
                    year: moment(item.since_date).utc().year(),
                    fee: item.fee ?? 0,
                    discount: item.discount ?? 0,
                    additional_discount: item.additional_discount ?? 0,
                    originalExpenses: item.expenses ?? 0,
                    expenses: item.expenses ?? 0,
                    since_date: item.since_date ? moment(item.since_date).utc() : null,
                    end_date: item.end_date ? moment(item.end_date).utc() : moment().year(moment(item.since_date).utc().year()).endOf("year"),
                    client_commitment_date: item.client_commitment_date
                        ? moment(item.client_commitment_date).utc()
                        : null,
                    client_delivery_date: item.client_delivery_date ? moment(item.client_delivery_date).utc() : null,
                    payment_type: item.payment_type ?? "",
                    additionals_only: item.additionals_only,
                    additionals: item.additionals,
                    add_hour_fee: clientMarket === "Venezuela" ? 90 : latam.includes(clientMarket) ? 0 : 150,
                };
                aux[index] = obj;
            });

            data.projects_array.sort((a, b) => moment(a.since_date).utc().year() - moment(b.since_date).utc().year());
            aux.sort((a, b) => a.year - b.year);

            let indexActive = valueTab;

            aux.forEach((item, index) => {
                if (item.client_commitment_date) indexActive = index;
            });

            if (aux[indexActive].additionals) {
                aux[indexActive].additionals.forEach((element) => {
                    if (element.additional_name === "Controller") {
                        controllerAdditionalData = {
                            since_date: element.since_date,
                            end_date: element.end_date,
                            hours: element.hours,
                        };
                    }
                    if (element.additional_name === "Ejecución de planificación fiscal") {
                        exeAdditionalData = {
                            since_date: element.since_date,
                            end_date: element.end_date,
                            hours: element.hours,
                        };
                    }
                    if (element.additional_name === "Consolidación de estados financieros") {
                        conAdditionalData = {
                            since_date: element.since_date,
                            end_date: element.end_date,
                            hours: element.hours,
                        };
                    }
                    if (element.additional_name === "Reportes para auditores externos") {
                        repAdditionalData = {
                            since_date: element.since_date,
                            end_date: element.end_date,
                            hours: element.hours,
                        };
                    }
                    if (element.additional_name === "Others") {
                        othersAdditionalData = {
                            since_date: element.since_date,
                            end_date: element.end_date,
                            hours: element.hours,
                            other_name: element.other_name,
                        };
                    }
                });
            }

            setshowTabs(true);
            setPeriods(aux);
            setValueTab(indexActive);

            setValuesRecurring({
                projectCompanyId: aux[indexActive].project_company_id,
                originalExpenses: aux[indexActive].additionals_only ? "N/A" : aux[indexActive].expenses,
                expenses: aux[indexActive].additionals_only ? "N/A" : aux[indexActive].expenses,
                originalFee: aux[indexActive].fee,
                projectFee: aux[indexActive].additionals_only
                    ? addHourFee * controllerAdditionalData.hours
                    : aux[indexActive].fee * (aux[indexActive].payment_type === 1 ? 12 : 1),
                projectDiscount: aux[indexActive].discount ?? 0,
                projectTotal: aux[indexActive].additionals_only
                    ? addHourFee * controllerAdditionalData.hours * (1 - (aux[indexActive].discount ?? 0) / 100)
                    : aux[indexActive].fee *
                    (aux[indexActive].payment_type === 1 ? 12 : 1) *
                    (1 - (aux[indexActive].discount ?? 0) / 100),
                additionalsDiscount: aux[indexActive].additional_discount ?? 0,
                // Responsabilidad del servicio
                paymentType: aux[indexActive].payment_type ?? 2,
                initDate: aux[indexActive].since_date ? moment(aux[indexActive].since_date).utc() : null,
                endDate: aux[indexActive].end_date ? moment(aux[indexActive].end_date).utc() : null,
                estDeliDate: aux[indexActive].client_commitment_date
                    ? moment(aux[indexActive].client_commitment_date).utc()
                    : null,
                deliDate: aux[indexActive].client_delivery_date
                    ? moment(aux[indexActive].client_delivery_date).utc()
                    : null,
                // Adicionales
                additionalsOnly: aux[indexActive].additionals_only,
                checkedController: Object.keys(controllerAdditionalData).length !== 0,
                tothController: controllerAdditionalData.hours ?? 0,
                startDateController: !controllerAdditionalData.since_date
                    ? null
                    : moment(controllerAdditionalData.since_date).utc(),
                finishDateController: !controllerAdditionalData.end_date
                    ? null
                    : moment(controllerAdditionalData.end_date).utc(),
                finishDateControllerOG: !controllerAdditionalData.end_date
                    ? null
                    : moment(controllerAdditionalData.end_date).utc(),
                checkedExe: Object.keys(exeAdditionalData).length !== 0,
                checkedExeOG: Object.keys(exeAdditionalData).length !== 0,
                tothExe: exeAdditionalData.hours ?? 0,
                startDateExe: !exeAdditionalData.since_date ? null : moment(exeAdditionalData.since_date).utc(),
                finishDateExe: !exeAdditionalData.end_date ? null : moment(exeAdditionalData.end_date).utc(),
                finishDateExeOG: !exeAdditionalData.end_date ? null : moment(exeAdditionalData.end_date).utc(),
                checkedCon: Object.keys(conAdditionalData).length !== 0,
                checkedConOG: Object.keys(conAdditionalData).length !== 0,
                tothCon: conAdditionalData.hours ?? 0,
                startDateCon: !conAdditionalData.since_date ? null : moment(conAdditionalData.since_date).utc(),
                finishDateCon: !conAdditionalData.end_date ? null : moment(conAdditionalData.end_date).utc(),
                finishDateConOG: !conAdditionalData.end_date ? null : moment(conAdditionalData.end_date).utc(),
                checkedRep: Object.keys(repAdditionalData).length !== 0,
                checkedRepOG: Object.keys(repAdditionalData).length !== 0,
                tothRep: repAdditionalData.hours ?? 0,
                startDateRep: !repAdditionalData.since_date ? null : moment(repAdditionalData.since_date).utc(),
                finishDateRep: !repAdditionalData.end_date ? null : moment(repAdditionalData.end_date).utc(),
                finishDateRepOG: !repAdditionalData.end_date ? null : moment(repAdditionalData.end_date).utc(),
                // Otros
                checkedOthers: Object.keys(othersAdditionalData).length !== 0,
                checkedOthersOG: Object.keys(othersAdditionalData).length !== 0,
                nameOthers: othersAdditionalData.other_name ?? "",
                tothOthers: othersAdditionalData.hours ?? 0,
                startDateOthers: !othersAdditionalData.since_date
                    ? null
                    : moment(othersAdditionalData.since_date).utc(),
                finishDateOthers: !othersAdditionalData.end_date ? null : moment(othersAdditionalData.end_date).utc(),
                finishDateOthersOG: !othersAdditionalData.end_date ? null : moment(othersAdditionalData.end_date).utc(),
                // Total
                addHourFee: addHourFee,
            });
        } else {
            setValuesRecurring(defaultState);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    /** Component functions */
    const removeCheck = () => {
        setValuesRecurring({
            ...valuesRecurring,
            [infoAdd.check]: false,
            [infoAdd.toth]: 0,
            nameOthers: infoAdd.nameOthers ? "" : valuesRecurring.nameOthers,
            [infoAdd.startDate]: null,
            [infoAdd.finishDate]: null,
        });
        if (checkRemind) {
            dispatch({
                type: "addRemind",
                item: true,
            });
        }
        handleCloseDialog();
    };

    const updateProject = async () => {
        setBackDrop(true);

        if (
            (valuesRecurring.respIniDate && !showTabs) ||
            (showTabs &&
                valuesRecurring.initDate &&
                valuesRecurring.endDate &&
                data.projects_array[valueTab].client_commitment_date &&
                !valuesRecurring.estDeliDate &&
                valuesRecurring.paymentType) ||
            (showTabs &&
                valuesRecurring.initDate &&
                valuesRecurring.endDate &&
                valuesRecurring.estDeliDate &&
                valuesRecurring.paymentType)
        ) {
            let additionalsF = [];
            let params = {};

            if (valuesRecurring.checkedController) {
                if (
                    valuesRecurring.startDateController &&
                    ((valuesRecurring.endDate && valuesRecurring.finishDateController) ||
                        (!valuesRecurring.endDate && valuesRecurring.finishDateController) ||
                        (!valuesRecurring.endDate && !valuesRecurring.finishDateController))
                ) {
                    if (valuesRecurring.tothController > 0) {
                        additionalsF.push({
                            additional_name: "Controller",
                            hours: valuesRecurring.tothController,
                            since_date: moment(valuesRecurring.startDateController).startOf("day").format(),
                            end_date: valuesRecurring.finishDateController
                                ? moment(valuesRecurring.finishDateController).startOf("day").format()
                                : null,
                        });
                    } else {
                        setTexto(t("services.hoursValidationError"));
                        setalertType("warning");
                        setShow(true);
                        setBackDrop(false);
                        setIsError(true);
                        return;
                    }
                } else {
                    setTexto(t("miscellaneous.requiredData"));
                    setalertType("warning");
                    setShow(true);
                    setBackDrop(false);
                    setIsError(true);
                    return;
                }
            }
            if (valuesRecurring.checkedExe) {
                if (
                    valuesRecurring.startDateExe &&
                    ((valuesRecurring.endDate && valuesRecurring.finishDateExe) ||
                        (!valuesRecurring.endDate && valuesRecurring.finishDateExe) ||
                        (!valuesRecurring.endDate && !valuesRecurring.finishDateExe))
                ) {
                    if (valuesRecurring.tothExe > 0) {
                        additionalsF.push({
                            additional_name: "Ejecución de planificación fiscal",
                            hours: valuesRecurring.tothExe,
                            since_date: moment(valuesRecurring.startDateExe).startOf("day").format(),
                            end_date: valuesRecurring.finishDateExe
                                ? moment(valuesRecurring.finishDateExe).startOf("day").format()
                                : null,
                        });
                    } else {
                        setTexto(t("services.hoursValidationError"));
                        setalertType("warning");
                        setShow(true);
                        setBackDrop(false);
                        setIsError(true);
                        return;
                    }
                } else {
                    setTexto(t("miscellaneous.requiredData"));
                    setalertType("warning");
                    setShow(true);
                    setBackDrop(false);
                    setIsError(true);
                    return;
                }
            }
            if (valuesRecurring.checkedCon) {
                if (
                    valuesRecurring.startDateCon &&
                    ((valuesRecurring.endDate && valuesRecurring.finishDateCon) ||
                        (!valuesRecurring.endDate && valuesRecurring.finishDateCon) ||
                        (!valuesRecurring.endDate && !valuesRecurring.finishDateCon))
                ) {
                    if (valuesRecurring.tothCon > 0) {
                        additionalsF.push({
                            additional_name: "Consolidación de estados financieros",
                            hours: valuesRecurring.tothCon,
                            since_date: moment(valuesRecurring.startDateCon).startOf("day").format(),
                            end_date: valuesRecurring.finishDateCon
                                ? moment(valuesRecurring.finishDateCon).startOf("day").format()
                                : null,
                        });
                    } else {
                        setTexto(t("services.hoursValidationError"));
                        setalertType("warning");
                        setShow(true);
                        setBackDrop(false);
                        setIsError(true);
                        return;
                    }
                } else {
                    setTexto(t("miscellaneous.requiredData"));
                    setalertType("warning");
                    setShow(true);
                    setBackDrop(false);
                    setIsError(true);
                    return;
                }
            }
            if (valuesRecurring.checkedRep) {
                if (
                    valuesRecurring.startDateRep &&
                    ((valuesRecurring.endDate && valuesRecurring.finishDateRep) ||
                        (!valuesRecurring.endDate && valuesRecurring.finishDateRep) ||
                        (!valuesRecurring.endDate && !valuesRecurring.finishDateRep))
                ) {
                    if (valuesRecurring.tothRep > 0) {
                        additionalsF.push({
                            additional_name: "Reportes para auditores externos",
                            hours: valuesRecurring.tothRep,
                            since_date: moment(valuesRecurring.startDateRep).startOf("day").format(),
                            end_date: valuesRecurring.finishDateRep
                                ? moment(valuesRecurring.finishDateRep).startOf("day").format()
                                : null,
                        });
                    } else {
                        setTexto(t("services.hoursValidationError"));
                        setalertType("warning");
                        setShow(true);
                        setBackDrop(false);
                        setIsError(true);
                        return;
                    }
                } else {
                    setTexto(t("miscellaneous.requiredData"));
                    setalertType("warning");
                    setShow(true);
                    setBackDrop(false);
                    setIsError(true);
                    return;
                }
            }
            if (valuesRecurring.checkedOthers) {
                if (
                    valuesRecurring.startDateOthers &&
                    valuesRecurring.nameOthers &&
                    ((valuesRecurring.endDate && valuesRecurring.finishDateOthers) ||
                        (!valuesRecurring.endDate && valuesRecurring.finishDateOthers) ||
                        (!valuesRecurring.endDate && !valuesRecurring.finishDateOthers))
                ) {
                    if (valuesRecurring.tothOthers > 0) {
                        additionalsF.push({
                            additional_name: "Others",
                            hours: valuesRecurring.tothOthers,
                            since_date: moment(valuesRecurring.startDateOthers).startOf("day").format(),
                            end_date: valuesRecurring.finishDateOthers
                                ? moment(valuesRecurring.finishDateOthers).startOf("day").format()
                                : null,
                            other_name: valuesRecurring.nameOthers,
                        });
                    } else {
                        setTexto(t("services.hoursValidationError"));
                        setalertType("warning");
                        setShow(true);
                        setBackDrop(false);
                        setIsError(true);
                        return;
                    }
                } else {
                    setTexto(t("miscellaneous.requiredData"));
                    setalertType("warning");
                    setShow(true);
                    setBackDrop(false);
                    setIsError(true);
                    return;
                }
            }

            if (showTabs) {
                params = {
                    company_id: companyID,
                    service_id: serviceID,
                    project_id: data.project_id,
                    project_company_id: valuesRecurring.projectCompanyId,
                    total_fee:
                        valuesRecurring.projectTotal +
                        valuesRecurring.addHourFee * (sumTotal * (1 - valuesRecurring.additionalsDiscount / 100)),
                    discount: parseFloat(valuesRecurring.projectDiscount),
                    additional_discount: parseFloat(valuesRecurring.additionalsDiscount),
                    payment_type: valuesRecurring.paymentType,
                    since_date: moment(valuesRecurring.initDate).startOf("day").format(),
                    end_date: valuesRecurring.endDate ? moment(valuesRecurring.endDate).startOf("day").format() : null,
                    client_commitment_date: valuesRecurring.estDeliDate
                        ? moment(valuesRecurring.estDeliDate).startOf("day").format()
                        : null,
                    client_delivery_date: valuesRecurring.deliDate
                        ? moment(valuesRecurring.deliDate).startOf("day").format()
                        : null,
                    additionals_only: valuesRecurring.additionalsOnly,
                    additionals: additionalsF,
                };
            } else {
                params = {
                    company_id: companyID,
                    service_id: serviceID,
                    project_id: data.project_id,
                    project_company_id: data.project_company_id,
                    client_commitment_date: null,
                    client_delivery_date: null,
                    since_date: moment(valuesRecurring.respIniDate).startOf("day").format(),
                    additionals: [],
                    additionals_only: valuesRecurring?.additionalsOnly ?? false,
                };
            }

            await fetch(`/addServicesAndProjects`, {
                method: "POST",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
                body: JSON.stringify(params),
            })
                .then((response) => {
                    if (!response.ok) {
                        // get error message from body or default to response status
                        const error = response.status;
                        throw new Error(error);
                    }

                    if (response.status === 200) {
                        setTexto(t("miscellaneous.successfulUpdate"));
                        setalertType("success");
                        setShow(true);
                        reloadProjects(true);
                    } else if (response.status === 201) {
                        setTexto(t("services.notPossibleGenProject"));
                        setalertType("error");
                        setShow(true);
                        reloadProjects(true);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            setTexto(t("miscellaneous.requiredData"));
            setalertType("warning");
            setShow(true);
            setIsError(true);
        }
        setBackDrop(false);
    };

    const handleChangePaymentType = (event) => {
        setValuesRecurring({
            ...valuesRecurring,
            paymentType: event.target.value,
            projectFee: valuesRecurring.additionalsOnly
                ? valuesRecurring.projectFee
                : event.target.value === 1
                    ? valuesRecurring.originalFee * 12
                    : valuesRecurring.originalFee,
            projectDiscount:
                //Si esta cheked el switch de 'Pagos por hora' el descuento es 0
                valuesRecurring.additionalsOnly
                    ? 0
                    : //Si el tipo de pago es 'Anual anticipado' el descuento es 15%
                    event.target.value === 1
                        ? 15
                        : 0,
            projectTotal: valuesRecurring.additionalsOnly
                ? valuesRecurring.projectTotal
                : event.target.value === 1
                    ? valuesRecurring.originalFee * 12 * 0.85
                    : valuesRecurring.originalFee,
            additionalsDiscount:
                //Si esta cheked el switch de 'Pagos por hora' el descuento es 0
                valuesRecurring.additionalsOnly
                    ? 0
                    : //Si el tipo de pago es 'Anual anticipado' el descuento es 15%
                    event.target.value === 1
                        ? 15
                        : 0,
        });
    };

    const handleMouseDownDiscount = (event) => {
        event.preventDefault();
    };

    const handleClickShowDiscount = () => {
        setEditMode(!editMode);
    };

    const handleClickShowDiscount2 = () => {
        setEditMode2(!editMode2);
    };

    const handleChangeDiscount = (event) => {
        let discountAux = 0;

        if (event.target.name === "projectDiscount") {
            discountAux =
                event.target.value >= 0 && event.target.value <= 100
                    ? event.target.value
                    : valuesRecurring.projectDiscount;
            setValuesRecurring({
                ...valuesRecurring,
                projectDiscount: discountAux,
                projectTotal: valuesRecurring.projectFee * (1 - discountAux / 100),
            });
        } else {
            discountAux =
                event.target.value >= 0 && event.target.value <= 100
                    ? event.target.value
                    : valuesRecurring.additionalsDiscount;
            setValuesRecurring({
                ...valuesRecurring,
                additionalsDiscount: discountAux,
            });
        }
    };

    const handleChangeInitDate = (date) => {
        let startDate = moment(date).startOf("month");

        if (!date || !valuesRecurring.endDate) {
            setValuesRecurring({
                ...valuesRecurring,
                initDate: date ? startDate : date,
            });
        } else {
            if (startDate.isAfter(moment(valuesRecurring.endDate).format("YYYY-MM-DD"))) {
                setValuesRecurring({
                    ...valuesRecurring,
                    initDate: null,
                });

                setTexto(t("reports.initDateMess"));
                setalertType("warning");
                setShow(true);
            } else {
                setValuesRecurring({
                    ...valuesRecurring,
                    initDate: startDate,
                    // Adicionales
                    startDateController: valuesRecurring.checkedController ? startDate : null,
                    startDateExe: valuesRecurring.checkedExe ? startDate : null,
                    startDateCon: valuesRecurring.checkedCon ? startDate : null,
                    startDateRep: valuesRecurring.checkedRep ? startDate : null,
                    // Otros
                    startDateOthers:
                        valuesRecurring.checkedOthers && valuesRecurring.startDateOthers ? startDate : null,
                });
            }
        }
    };

    const handleChangeEndDate = (date) => {
        let endDate = moment(date).endOf("month").startOf("day");

        if (!date || !valuesRecurring.initDate) {
            setValuesRecurring({
                ...valuesRecurring,
                endDate: date ? endDate : date,
                estDeliDate: null,
            });
        } else {
            if (endDate.isBefore(moment(valuesRecurring.initDate).format("YYYY-MM-DD"))) {
                setValuesRecurring({
                    ...valuesRecurring,
                    endDate: null,
                    estDeliDate: null,
                });

                setTexto(t("reports.endDateMess"));
                setalertType("warning");
                setShow(true);
            } else {
                setValuesRecurring({
                    ...valuesRecurring,
                    endDate: endDate,
                    // Adicionales
                    finishDateController: valuesRecurring.checkedController
                        ? valuesRecurring.finishDateControllerOG &&
                            valuesRecurring.finishDateControllerOG.isBefore(endDate)
                            ? valuesRecurring.finishDateControllerOG
                            : endDate
                        : null,
                    finishDateExe: valuesRecurring.checkedExe
                        ? valuesRecurring.finishDateExeOG && valuesRecurring.finishDateExeOG.isBefore(endDate)
                            ? valuesRecurring.finishDateExeOG
                            : endDate
                        : null,
                    finishDateCon: valuesRecurring.checkedCon
                        ? valuesRecurring.finishDateConOG && valuesRecurring.finishDateConOG.isBefore(endDate)
                            ? valuesRecurring.finishDateConOG
                            : endDate
                        : null,
                    finishDateRep: valuesRecurring.checkedRep
                        ? valuesRecurring.finishDateRepOG && valuesRecurring.finishDateRepOG.isBefore(endDate)
                            ? valuesRecurring.finishDateRepOG
                            : endDate
                        : null,
                    // Otros
                    finishDateOthers: valuesRecurring.checkedOthers
                        ? valuesRecurring.finishDateOthersOG && valuesRecurring.finishDateOthersOG.isBefore(endDate)
                            ? valuesRecurring.finishDateOthersOG
                            : endDate
                        : null,
                });
            }
        }
    };

    const handleChangeEstDeliDate = (date) => {
        if (!date) {
            setValuesRecurring({
                ...valuesRecurring,
                estDeliDate: date,
            });
        } else {
            if (date.isSameOrBefore(moment(valuesRecurring.endDate).format("YYYY-MM-DD"))) {
                setValuesRecurring({
                    ...valuesRecurring,
                    estDeliDate: null,
                });

                setTexto(t("reports.selectEntDate"));
                setalertType("warning");
                setShow(true);
            } else {
                setValuesRecurring({
                    ...valuesRecurring,
                    estDeliDate: date,
                });
            }
        }
    };

    const handleChangeDeliDate = (date) => {
        if (!date) {
            setValuesRecurring({
                ...valuesRecurring,
                deliDate: date,
            });
        } else {
            if (date.isSameOrBefore(moment(valuesRecurring.endDate).format("YYYY-MM-DD"))) {
                setValuesRecurring({
                    ...valuesRecurring,
                    deliDate: null,
                });

                setTexto(t("reports.selectEntDate"));
                setalertType("warning");
                setShow(true);
            } else {
                setValuesRecurring({
                    ...valuesRecurring,
                    deliDate: date,
                });
            }
        }
    };

    const handleChangeSwitch = (event) => {
        setValuesRecurring({
            ...valuesRecurring,
            expenses: event.target.checked ? "N/A" : valuesRecurring.originalExpenses,
            additionalsOnly: event.target.checked,
            projectFee: event.target.checked
                ? 0
                : valuesRecurring.paymentType === 1
                    ? valuesRecurring.originalFee * 12
                    : valuesRecurring.originalFee,
            projectTotal: event.target.checked
                ? 0
                : valuesRecurring.paymentType === 1
                    ? valuesRecurring.originalFee * 12 * 0.85
                    : valuesRecurring.originalFee *
                    (1 -
                        (data.projects_array.length > 0 && data.projects_array[0].discount
                            ? data.projects_array[0].discount
                            : 0) /
                        100),
            checkedController: event.target.checked,
            tothController: 0,
            startDateController: event.target.checked ? valuesRecurring.initDate : null,
            finishDateController: event.target.checked ? valuesRecurring.endDate : null,
            projectDiscount:
                //Si esta cheked el switch de 'Pagos por hora' el descuento es 0
                event.target.checked
                    ? 0
                    : //Si el tipo de pago es 'Anual anticipado' el descuento es 15%
                    valuesRecurring.paymentType === 1
                        ? 15
                        : //Si existe un descuento almacenado en BD
                        data.projects_array.length > 0 && data.projects_array[0].discount
                            ? data.projects_array[0].discount
                            : 0,
            additionalsDiscount:
                //Si esta cheked el switch de 'Pagos por hora' el descuento es 0
                event.target.checked
                    ? 0
                    : //Si el tipo de pago es 'Anual anticipado' el descuento es 15%
                    valuesRecurring.paymentType === 1
                        ? 15
                        : //Si existe un descuento almacenado en BD
                        data.projects_array.length > 0 && data.projects_array[0].additional_discount
                            ? data.projects_array[0].additional_discount
                            : 0,
        });
    };

    const handleChangeStartDate = (date) => {
        if (!date) {
            setValuesRecurring({
                ...valuesRecurring,
                respIniDate: date,
            });
        } else {
            if (moment(date).year() > new Date().getFullYear() - 10) {
                setValuesRecurring({
                    ...valuesRecurring,
                    respIniDate: moment(date).startOf("year"),
                });
            } else {
                setTexto(t("services.cannotEnterLessTenPeriod"));
                setalertType("warning");
                setShow(true);
            }
        }
    };

    const handleChangeTab = (event, newValue) => {
        if (periods.length !== newValue) {
            setValueTab(newValue);

            let controllerAdditionalData = {};
            let exeAdditionalData = {};
            let conAdditionalData = {};
            let repAdditionalData = {};
            let othersAdditionalData = {};

            if (periods[newValue].additionals) {
                periods[newValue].additionals.forEach((element) => {
                    if (element.additional_name === "Controller") {
                        controllerAdditionalData = {
                            since_date: element.since_date,
                            end_date: element.end_date,
                            hours: element.hours,
                        };
                    }
                    if (element.additional_name === "Ejecución de planificación fiscal") {
                        exeAdditionalData = {
                            since_date: element.since_date,
                            end_date: element.end_date,
                            hours: element.hours,
                        };
                    }
                    if (element.additional_name === "Consolidación de estados financieros") {
                        conAdditionalData = {
                            since_date: element.since_date,
                            end_date: element.end_date,
                            hours: element.hours,
                        };
                    }
                    if (element.additional_name === "Reportes para auditores externos") {
                        repAdditionalData = {
                            since_date: element.since_date,
                            end_date: element.end_date,
                            hours: element.hours,
                        };
                    }
                    if (element.additional_name === "Others") {
                        othersAdditionalData = {
                            since_date: element.since_date,
                            end_date: element.end_date,
                            hours: element.hours,
                            other_name: element.other_name,
                        };
                    }
                });
            }

            let initObj = {
                projectCompanyId: periods[newValue].project_company_id,
                originalExpenses: periods[newValue].expenses,
                expenses: periods[newValue].additionals_only ? "N/A" : periods[newValue].expenses,
                originalFee: periods[newValue].fee,
                projectFee: periods[newValue].additionals_only
                    ? periods[newValue].add_hour_fee * controllerAdditionalData.hours
                    : periods[newValue].fee,
                projectDiscount: periods[newValue].discount,
                projectTotal: periods[newValue].additionals_only
                    ? periods[newValue].add_hour_fee *
                    controllerAdditionalData.hours *
                    (1 - periods[newValue].discount / 100)
                    : periods[newValue].fee * (1 - periods[newValue].discount / 100),
                additionalsDiscount: periods[newValue].additional_discount,
                // Service Responsibility
                paymentType: periods[newValue].payment_type,
                initDate: periods[newValue].since_date,
                endDate: periods[newValue].end_date,
                deliDate: periods[newValue].client_delivery_date,
                estDeliDate: periods[newValue].client_commitment_date,
                // Additionals
                additionalsOnly: periods[newValue].additionals_only,
                checkedController: Object.keys(controllerAdditionalData).length !== 0,
                tothController: controllerAdditionalData.hours ?? 0,
                startDateController: !controllerAdditionalData.since_date
                    ? null
                    : moment(controllerAdditionalData.since_date).utc(),
                finishDateController: !controllerAdditionalData.end_date
                    ? null
                    : moment(controllerAdditionalData.end_date).utc(),
                finishDateControllerOG: !controllerAdditionalData.end_date
                    ? null
                    : moment(controllerAdditionalData.end_date).utc(),
                checkedExe: Object.keys(exeAdditionalData).length !== 0,
                checkedExeOG: Object.keys(exeAdditionalData).length !== 0,
                tothExe: exeAdditionalData.hours ?? 0,
                startDateExe: !exeAdditionalData.since_date ? null : moment(exeAdditionalData.since_date).utc(),
                finishDateExe: !exeAdditionalData.end_date ? null : moment(exeAdditionalData.end_date).utc(),
                finishDateExeOG: !exeAdditionalData.end_date ? null : moment(exeAdditionalData.end_date).utc(),
                checkedCon: Object.keys(conAdditionalData).length !== 0,
                checkedConOG: Object.keys(conAdditionalData).length !== 0,
                tothCon: conAdditionalData.hours ?? 0,
                startDateCon: !conAdditionalData.since_date ? null : moment(conAdditionalData.since_date).utc(),
                finishDateCon: !conAdditionalData.end_date ? null : moment(conAdditionalData.end_date).utc(),
                finishDateConOG: !conAdditionalData.end_date ? null : moment(conAdditionalData.end_date).utc(),
                checkedRep: Object.keys(repAdditionalData).length !== 0,
                checkedRepOG: Object.keys(repAdditionalData).length !== 0,
                tothRep: repAdditionalData.hours ?? 0,
                startDateRep: !repAdditionalData.since_date ? null : moment(repAdditionalData.since_date).utc(),
                finishDateRep: !repAdditionalData.end_date ? null : moment(repAdditionalData.end_date).utc(),
                finishDateRepOG: !repAdditionalData.end_date ? null : moment(repAdditionalData.end_date).utc(),
                // Otros
                checkedOthers: Object.keys(othersAdditionalData).length !== 0,
                checkedOthersOG: Object.keys(othersAdditionalData).length !== 0,
                nameOthers: othersAdditionalData.other_name ?? "",
                tothOthers: othersAdditionalData.hours ?? 0,
                startDateOthers: !othersAdditionalData.since_date
                    ? null
                    : moment(othersAdditionalData.since_date).utc(),
                finishDateOthers: !othersAdditionalData.end_date ? null : moment(othersAdditionalData.end_date).utc(),
                finishDateOthersOG: !othersAdditionalData.end_date ? null : moment(othersAdditionalData.end_date).utc(),
                // Total
                addHourFee: periods[newValue].add_hour_fee,
            };

            setValuesRecurring(initObj);
        }
    };

    const closeDialog = () => {
        setDialogNewPeriod(false);
        setNewPeriod(null);
        setIsErrorNewPeriod(false);
    };

    const handleChangeNew = (date) => {
        setNewPeriod(date);
    };

    const addTab = async () => {
        setBackDrop(true);

        if (newPeriod !== null) {
            let FinalIndex = periods.length;
            let MinDate = moment(newPeriod).year();
            let items = [...periods];
            let exits = false;
            let ready = false;
            let error = false;

            items.forEach((value, index) => {
                if (MinDate < value.year && !ready) {
                    FinalIndex = index;
                    ready = true;
                }

                if (MinDate === value.year) {
                    exits = true;
                }
            });

            if (MinDate > moment().year()) {
                error = true;
                setTexto(t("services.cannotEnterFuturePeriod"));
                setalertType("warning");
                setShow(true);
                setBackDrop(false);
                return;
            }

            if (MinDate > moment().year() - 10) {
                if (!error) {
                    if (!exits) {
                        if (periods.length === 10) {
                            items.splice(0, 1);
                        }

                        let params = {
                            company_id: companyID,
                            service_id: serviceID,
                            project_id: data.project_id,
                            year: moment(newPeriod).year(),
                        };

                        await fetch(`/addNewProjectTab`, {
                            method: "POST",
                            headers: {
                                "Content-type": "application/json; charset=UTF-8",
                                "Access-Control-Allow-Origin": "*",
                            },
                            body: JSON.stringify(params),
                        })
                            .then((res) => {
                                if (res.ok) {
                                    items.splice(FinalIndex, 0, {
                                        year: moment(newPeriod).year(),
                                        since_date: moment(newPeriod).startOf("year"),
                                        end_date: moment(newPeriod).endOf("year"),
                                        client_commitment_date: null,
                                        client_delivery_date: null,
                                        paymentType: "",
                                        additionals_only: false,
                                        additionals: [],
                                        add_hour_fee:
                                            clientMarket === "Venezuela" ? 90 : latam.includes(clientMarket) ? 0 : 150,
                                    });

                                    setPeriods(items);
                                    setValueTab(0);
                                    reloadProjects(true);
                                } else {
                                    switch (res.status) {
                                        case 406:
                                            setTexto(t("services.cannotEnterYear"));
                                            setalertType("error");
                                            setShow(true);
                                            break;
                                        default:
                                            setTexto(t("services.noServiceInCompany"));
                                            setalertType("error");
                                            setShow(true);
                                            break;
                                    }
                                }
                            })
                            .catch((error) => {
                                console.log(error);
                            });

                        closeDialog();
                    } else {
                        setTexto(t("services.cannotEnterYear"));
                        setalertType("warning");
                        setShow(true);
                    }
                }
            } else {
                setTexto(t("services.cannotEnterLessTenPeriod"));
                setalertType("warning");
                setShow(true);
            }
        } else {
            setTexto(t("miscellaneous.requiredData"));
            setalertType("warning");
            setShow(true);
            setIsErrorNewPeriod(true);
        }

        setBackDrop(false);
    };

    const dialogBody = (
        <Grid container>
            <Grid item xs={12}>
                <LocalizationProvider
                    dateAdapter={AdapterMoment}
                    adapterLocale={t("language.locale")}
                    localeText={{ clearButtonLabel: t("miscellaneous.clear") }}
                >
                    <DatePicker
                        componentsProps={{
                            actionBar: { actions: ["clear"] },
                        }}
                        value={newPeriod}
                        views={["year"]}
                        mask="____"
                        inputFormat="YYYY"
                        disableFuture
                        onChange={(newValue) => handleChangeNew(newValue)}
                        renderInput={(params) => (
                            <TextField
                                fullWidth
                                required
                                {...params}
                                error={isErrorNewPeriod && newPeriod === null ? true : false}
                            />
                        )}
                        inputProps={{ readOnly: true }}
                        label={t("miscellaneous.year")}
                    />
                </LocalizationProvider>
            </Grid>
        </Grid>
    );

    const dialogButtons = (
        <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
            <Grid item>
                <ColorButton
                    onClick={closeDialog}
                    sx={{
                        mr: 1,
                        "&:hover": {
                            backgroundColor: "white",
                            color: "#2f3190",
                            border: "1px solid #2f3190",
                        },
                    }}
                    color="secondary"
                    disableElevation
                    variant="outlined"
                >
                    {t("miscellaneous.cancel")}
                </ColorButton>
            </Grid>
            <Grid item>
                <ColorButton
                    onClick={addTab}
                    sx={{
                        "&:hover": {
                            backgroundColor: "#2f3190",
                            border: "1px solid #2f3190",
                        },
                    }}
                    color="secondary"
                    disableElevation
                    variant="contained"
                >
                    {t("miscellaneous.accept")}
                </ColorButton>
            </Grid>
        </Grid>
    );

    return (
        <StyledContainer>
            <LocalizationProvider
                dateAdapter={AdapterMoment}
                adapterLocale={t("language.locale")}
                localeText={{ clearButtonLabel: t("miscellaneous.clear") }}
            >
                <Card elevation={0}>
                    <CardHeader
                        title={
                            <Box display="flex" sx={{ alignItems: "center" }}>
                                Controller recurring
                                <FormControlLabel
                                    sx={{ ml: 1 }}
                                    control={
                                        <Switch
                                            checked={valuesRecurring.additionalsOnly}
                                            inputProps={{
                                                "aria-label": "controlled",
                                            }}
                                            color="secondary"
                                            onChange={handleChangeSwitch}
                                            className={classes.switch}
                                        />
                                    }
                                    label={
                                        <Typography variant="body1" color="textPrimary">
                                            {t("services.hourlyInvoicing")}
                                        </Typography>
                                    }
                                />
                            </Box>
                        }
                    />
                    <Divider />
                    <CardContent>
                        {controlSystem.disabledData === true &&
                            (controlSystem.prov === "Xero" || controlSystem.prov === "Quickbooks Online") && (
                                <Grid container spacing={2} justifyContent="center" sx={{ paddingBottom: 2 }}>
                                    <Grid item>
                                        <Alert
                                            severity="warning"
                                            sx={{
                                                borderRadius: 8,
                                                fontSize: 12,
                                                fontWeight: 600,
                                            }}
                                        >
                                            {t("services.enterValidCredentials")}
                                        </Alert>
                                    </Grid>
                                </Grid>
                            )}
                        {expenseEst === -1 && (
                            <Grid container spacing={2} justifyContent="center" sx={{ paddingBottom: 4 }}>
                                <Grid item>
                                    <Alert
                                        severity="warning"
                                        sx={{
                                            borderRadius: 8,
                                            fontSize: 12,
                                            fontWeight: 600,
                                        }}
                                    >
                                        {t("services.ableToSaveInfo")}
                                    </Alert>
                                </Grid>
                            </Grid>
                        )}
                        <Grid container alignItems="center" spacing={2}>
                            <Grid item container spacing={2}>
                                <Grid item xs={12} sm={6} lg={3}>
                                    {valuesRecurring.expenses !== "N/A" ? (
                                        <FormControl fullWidth variant="outlined" disabled>
                                            <InputLabel htmlFor="outlined-adornment-expenses">
                                                {t("miscellaneous.expenseScale")}
                                            </InputLabel>
                                            <OutlinedInput
                                                id="outlined-adornment-expenses"
                                                value={valuesRecurring.expenses}
                                                inputComponent={NumberFormatCustom}
                                                startAdornment={
                                                    <InputAdornment disableTypography position="start">
                                                        $
                                                    </InputAdornment>
                                                }
                                                label={t("miscellaneous.expenseScale")}
                                            />
                                        </FormControl>
                                    ) : (
                                        <TextField
                                            fullWidth
                                            disabled
                                            label={t("miscellaneous.expenseScale")}
                                            value={valuesRecurring.expenses}
                                            variant="outlined"
                                        />
                                    )}
                                </Grid>
                                <Grid item xs={12} sm={6} lg={3}>
                                    <FormControl fullWidth variant="outlined" disabled>
                                        <InputLabel htmlFor="outlined-adornment-projectFee">
                                            {t("services.projectFee")}
                                        </InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-projectFee"
                                            value={valuesRecurring.projectFee}
                                            inputComponent={NumberFormatCustom}
                                            startAdornment={
                                                <InputAdornment disableTypography position="start">
                                                    $
                                                </InputAdornment>
                                            }
                                            label={t("services.projectFee")}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6} lg={3}>
                                    <FormControl variant="outlined" disabled={editMode} fullWidth>
                                        <InputLabel htmlFor="outlined-adornment-discount">
                                            {t("miscellaneous.discount")}
                                        </InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-discount"
                                            type="number"
                                            name="projectDiscount"
                                            value={valuesRecurring.projectDiscount}
                                            onChange={handleChangeDiscount}
                                            onBlur={() =>
                                                setValuesRecurring({
                                                    ...valuesRecurring,
                                                    projectDiscount: parseFloat(
                                                        valuesRecurring.projectDiscount === ""
                                                            ? 0
                                                            : valuesRecurring.projectDiscount
                                                    ).toFixed(2),
                                                })
                                            }
                                            inputProps={{
                                                min: 0,
                                                max: 100,
                                            }}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    %
                                                    <LightTooltip title={t("miscellaneous.edit")} aria-label="edit">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowDiscount}
                                                            onMouseDown={handleMouseDownDiscount}
                                                            edge="end"
                                                        >
                                                            {!editMode ? <EditOffIcon /> : <EditIcon />}
                                                        </IconButton>
                                                    </LightTooltip>
                                                </InputAdornment>
                                            }
                                            label={t("miscellaneous.discount")}
                                            sx={{
                                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                                {
                                                    display: "none",
                                                },
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6} lg={3}>
                                    <FormControl fullWidth variant="outlined" disabled>
                                        <InputLabel htmlFor="outlined-adornment-projectTotal">
                                            {t("services.projectTot")}
                                        </InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-projectTotal"
                                            value={valuesRecurring.projectTotal}
                                            inputComponent={NumberFormatCustom}
                                            startAdornment={
                                                <InputAdornment disableTypography position="start">
                                                    $
                                                </InputAdornment>
                                            }
                                            label={t("services.projectTot")}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid item container spacing={2}>
                                <Grid item xs={12} sm={6} lg={3}></Grid>
                                <Grid item xs={12} sm={6} lg={3}>
                                    <FormControl fullWidth variant="outlined" disabled>
                                        <InputLabel htmlFor="outlined-adornment-additionalsFee">
                                            {t("services.hourlyRateAdd")}
                                        </InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-additionalsFee"
                                            value={valuesRecurring.addHourFee * sumTotal}
                                            inputComponent={NumberFormatCustom}
                                            startAdornment={
                                                <InputAdornment disableTypography position="start">
                                                    $
                                                </InputAdornment>
                                            }
                                            label={t("services.hourlyRateAdd")}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6} lg={3}>
                                    <FormControl variant="outlined" disabled={editMode2} fullWidth>
                                        <InputLabel htmlFor="outlined-adornment-discount">
                                            {t("miscellaneous.discount")}
                                        </InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-discount"
                                            type="number"
                                            name="additionalsDiscount"
                                            value={valuesRecurring.additionalsDiscount}
                                            onChange={handleChangeDiscount}
                                            onBlur={() =>
                                                setValuesRecurring({
                                                    ...valuesRecurring,
                                                    additionalsDiscount: parseFloat(
                                                        valuesRecurring.additionalsDiscount === ""
                                                            ? 0
                                                            : valuesRecurring.additionalsDiscount
                                                    ).toFixed(2),
                                                })
                                            }
                                            inputProps={{
                                                min: 0,
                                                max: 100,
                                            }}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    %
                                                    <LightTooltip title={t("miscellaneous.edit")} aria-label="edit">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowDiscount2}
                                                            onMouseDown={handleMouseDownDiscount}
                                                            edge="end"
                                                        >
                                                            {!editMode2 ? <EditOffIcon /> : <EditIcon />}
                                                        </IconButton>
                                                    </LightTooltip>
                                                </InputAdornment>
                                            }
                                            label={t("miscellaneous.discount")}
                                            sx={{
                                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                                {
                                                    display: "none",
                                                },
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6} lg={3}>
                                    <FormControl fullWidth variant="outlined" disabled>
                                        <InputLabel htmlFor="outlined-adornment-additionalsTotal">
                                            {t("clientsTable.totalAdd")}
                                        </InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-additionalsTotal"
                                            value={
                                                valuesRecurring.addHourFee *
                                                sumTotal *
                                                (1 - valuesRecurring.additionalsDiscount / 100)
                                            }
                                            inputComponent={NumberFormatCustom}
                                            startAdornment={
                                                <InputAdornment disableTypography position="start">
                                                    $
                                                </InputAdornment>
                                            }
                                            label={t("clientsTable.totalAdd")}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid item container spacing={2}>
                                <Grid item xs={12} sm={6} lg={9}></Grid>
                                <Grid item xs={12} sm={6} lg={3}>
                                    <FormControl fullWidth variant="outlined" disabled>
                                        <InputLabel htmlFor="outlined-adornment-totalFee">
                                            {t("proposals.feeTotal")}
                                        </InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-totalFee"
                                            value={
                                                valuesRecurring.projectTotal +
                                                valuesRecurring.addHourFee *
                                                sumTotal *
                                                (1 - valuesRecurring.additionalsDiscount / 100)
                                            }
                                            inputComponent={NumberFormatCustom}
                                            startAdornment={
                                                <InputAdornment disableTypography position="start">
                                                    $
                                                </InputAdornment>
                                            }
                                            label={t("proposals.feeTotal")}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                <Card elevation={0}>
                    <CardHeader title={t("services.serviceResponsibility")} sx={{ color: "#ff5968" }} />
                    <Divider />
                    <CardContent>
                        {showTabs ? (
                            <>
                                <Box
                                    sx={{
                                        borderBottom: 2,
                                        borderColor: "#031851",
                                        mb: 2,
                                    }}
                                >
                                    <Tabs
                                        value={valueTab}
                                        onChange={handleChangeTab}
                                        variant="scrollable"
                                        scrollButtons="auto"
                                        aria-label="years tabs"
                                        TabIndicatorProps={{
                                            sx: {
                                                display: "none",
                                            },
                                        }}
                                    >
                                        {periods.map((item, index) => (
                                            <CustomTab
                                                label={item.year}
                                                {...a11yProps(index)}
                                                sx={{
                                                    borderTop: item.client_commitment_date && "2px solid #ff5968",
                                                    borderRight: item.client_commitment_date && "2px solid #ff5968",
                                                    borderLeft: item.client_commitment_date && "2px solid #ff5968",
                                                    backgroundColor: item.client_commitment_date
                                                        ? "#ff5968"
                                                        : "#E9EAE8",
                                                    color: item.client_commitment_date ? "#fff" : "#031851",
                                                }}
                                            />
                                        ))}
                                        {!valuesRecurring.endProject && (
                                            <CustomTab
                                                icon={<AddIcon fontSize="small" />}
                                                aria-label="add"
                                                onClick={() => setDialogNewPeriod(true)}
                                            />
                                        )}
                                    </Tabs>
                                </Box>
                                {periods.map((values, i) => (
                                    <TabPanel value={valueTab} index={i}>
                                        <Grid container alignItems="center" spacing={2}>
                                            <Grid item xs={12} sm={6} lg={3}>
                                                <FormControl
                                                    fullWidth
                                                    required
                                                    error={isError && valuesRecurring.paymentType === ""}
                                                >
                                                    <InputLabel id="payment-type-simple-select-label">
                                                        {t("services.paymentType")}
                                                    </InputLabel>
                                                    <Select
                                                        name="paymentType"
                                                        labelId="payment-type-simple-select-label"
                                                        IconComponent={KeyboardArrowDownIcon}
                                                        value={valuesRecurring.paymentType}
                                                        label={t("services.paymentType")}
                                                        onChange={handleChangePaymentType}
                                                        MenuProps={{
                                                            PaperProps: {
                                                                sx: {
                                                                    mt: 0.7,
                                                                    borderRadius: 2,
                                                                    boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                                },
                                                            },
                                                        }}
                                                    >
                                                        <MenuItem value={1}>
                                                            {t("miscellaneous.anticipatedAnnual")}
                                                        </MenuItem>
                                                        <MenuItem value={2}>{t("miscellaneous.monthly")}</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={6} lg={9}></Grid>
                                            <Grid item xs={12} sm={6} lg={3}>
                                                <DatePicker
                                                    componentsProps={{
                                                        actionBar: { actions: ["clear"] },
                                                    }}
                                                    value={valuesRecurring.initDate}
                                                    onChange={(newValue) => handleChangeInitDate(newValue)}
                                                    inputFormat={todos.dateFormat}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            fullWidth
                                                            required
                                                            {...params}
                                                            error={isError && valuesRecurring.initDate === null}
                                                        />
                                                    )}
                                                    inputProps={{ readOnly: true }}
                                                    label={t("reports.initDate")}
                                                    views={["year", "month"]}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} lg={3}>
                                                <DatePicker
                                                    componentsProps={{
                                                        actionBar: { actions: ["clear"] },
                                                    }}
                                                    value={valuesRecurring.endDate}
                                                    onChange={(newValue) => handleChangeEndDate(newValue)}
                                                    inputFormat={todos.dateFormat}
                                                    renderInput={(params) => <TextField fullWidth {...params} />}
                                                    inputProps={{ readOnly: true }}
                                                    label={t("reports.endDate")}
                                                    views={["year", "month"]}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} lg={3}>
                                                <DatePicker
                                                    componentsProps={{
                                                        actionBar: { actions: ["clear"] },
                                                    }}
                                                    value={valuesRecurring.estDeliDate}
                                                    onChange={(newValue) => handleChangeEstDeliDate(newValue)}
                                                    inputFormat={todos.dateFormat}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            fullWidth
                                                            required={valuesRecurring.endDate}
                                                            {...params}
                                                            error={
                                                                isError &&
                                                                valuesRecurring.endDate &&
                                                                valuesRecurring.estDeliDate === null
                                                            }
                                                        />
                                                    )}
                                                    inputProps={{ readOnly: true }}
                                                    label={t("services.estimDeliveryDate")}
                                                    views={["year", "month", "day"]}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} lg={3}>
                                                <DatePicker
                                                    componentsProps={{
                                                        actionBar: { actions: ["clear"] },
                                                    }}
                                                    disableFuture
                                                    value={valuesRecurring.deliDate}
                                                    onChange={(newValue) => handleChangeDeliDate(newValue)}
                                                    inputFormat={todos.dateFormat}
                                                    renderInput={(params) => <TextField fullWidth {...params} />}
                                                    inputProps={{ readOnly: true }}
                                                    label={t("services.deliveryDate")}
                                                    views={["year", "month", "day"]}
                                                />
                                            </Grid>
                                        </Grid>
                                    </TabPanel>
                                ))}
                            </>
                        ) : (
                            <Grid container spacing={2}>
                                <Grid item lg={3} sm={6} xs={12}>
                                    <DatePicker
                                        componentsProps={{
                                            actionBar: { actions: ["clear"] },
                                        }}
                                        value={valuesRecurring.respIniDate}
                                        onChange={(newValue) => handleChangeStartDate(newValue)}
                                        inputFormat={todos.dateFormat}
                                        mask="__/__/____"
                                        disableFuture
                                        renderInput={(params) => (
                                            <TextField
                                                fullWidth
                                                required
                                                {...params}
                                                error={isError && !valuesRecurring.respIniDate}
                                            />
                                        )}
                                        inputProps={{ readOnly: true }}
                                        label={t("reports.initDate")}
                                        views={["year"]}
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </CardContent>
                </Card>
                {showTabs ? (
                    <Additionals
                        values={valuesRecurring}
                        set={setValuesRecurring}
                        setTexto={setTexto}
                        setalertType={setalertType}
                        setShow={setShow}
                        isError={isError}
                        updateProject={updateProject}
                        sumTotal={sumTotal}
                        originalData={data}
                        controlSystem={controlSystem}
                        expenseEst={expenseEst}
                        showConfirm={showConfirm}
                        sumTotalAddHours={getTotalHours()}
                    />
                ) : (
                    <>
                        <Divider />
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                p: 2,
                            }}
                        >
                            <ColorButton
                                color="secondary"
                                variant="contained"
                                disableElevation
                                className="Button"
                                onClick={updateProject}
                                sx={{
                                    "&:hover": {
                                        backgroundColor: "#2f3190",
                                        border: "1px solid #2f3190",
                                    },
                                }}
                                disabled={
                                    expenseEst === -1 ||
                                    (controlSystem.disabledData === true &&
                                        (controlSystem.prov === "Xero" || controlSystem.prov === "Quickbooks Online"))
                                }
                            >
                                {t("reports.generate")}
                            </ColorButton>
                        </Box>
                    </>
                )}
            </LocalizationProvider>
            <DialogAlerts
                open={open}
                onClose={handleCloseDialog}
                agreeBtnLabel={t("miscellaneous.delete")}
                disagreeBtnLabel="No"
                type={"info"}
                title={t("miscellaneous.confRemoveCheck")}
                message={bodyMess}
                agreeAction={removeCheck}
            />
            <NewPeriodDialog
                open={dialogNewPeriod}
                onClose={closeDialog}
                message={dialogBody}
                title={t("services.newPeriod")}
                button={dialogButtons}
                maxWidth="xs"
            />
        </StyledContainer>
    );
};
