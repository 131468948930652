/** React imports */
import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";

/** MUI icons */
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

/** MUI imports */
import { styled } from "@mui/material/styles";
import {
    Avatar,
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    FormControlLabel,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

/** Images */
import AndreaTorrealba from "../../assets/images/our-team-andrea-torrealba.jpg";
import BrianTorres from "../../assets/images/our-team-brian-torres.jpg";
import DanielaLeon from "../../assets/images/our-team-daniela-leon.jpg";
import DavidLoyo from "../../assets/images/our-team-david-loyo.jpg";
import DeyaniraGoncalves from "../../assets/images/our-team-deyanira-goncalvez.jpg";
import FeliceCalo from "../../assets/images/our-team-felice-calo.jpg";
import GabrielJimenez from "../../assets/images/our-team-gabriel-jimenez.jpg";
import GeraldineCalles from "../../assets/images/our-team-geraldine-calles.jpg";
import IraixyGuevara from "../../assets/images/our-team-iraixy-guevara.jpg";
import JoseAlvarez from "../../assets/images/our-team-jose-manuel-alvarez.jpg";
import GiancarloAlbani from "../../assets/images/our-team-giancarlo-albani.jpg";
import KeniaGarcia from "../../assets/images/our-team-kenia-garcia.jpg";
import PedroHerrera from "../../assets/images/our-team-pedro-herrera.jpg";
import RodrigoQuintero from "../../assets/images/our-team-rodrigo-quintero.jpg";
import TrinidadAguilar from "../../assets/images/our-team-trinidad-aguilar.jpg";
import YaritzaRosas from "../../assets/images/our-team-yaritza-rosas.jpg";
import MicaelaMedina from "../../assets/images/our-team-micaela-medina.jpg";
import MariaBarrientos from "../../assets/images/our-team-maria-barrientos.jpg";
import MariaCruz from "../../assets/images/our-team-maria-cruz.jpg";

const usersAvatar = {
    atorrealba: AndreaTorrealba,
    btorres: BrianTorres,
    mcruz: MariaCruz,
    dleon: DanielaLeon,
    dloyo: DavidLoyo,
    dgoncalves: DeyaniraGoncalves,
    fcalo: FeliceCalo,
    gjimenez: GabrielJimenez,
    gcalles: GeraldineCalles,
    iguevara: IraixyGuevara,
    jalvarez: JoseAlvarez,
    galbani: GiancarloAlbani,
    kgarcia: KeniaGarcia,
    pherrera: PedroHerrera,
    rquintero: RodrigoQuintero,
    taguilar: TrinidadAguilar,
    yrosas: YaritzaRosas,
    mmedina: MicaelaMedina,
    mbarrientos: MariaBarrientos
};

const PREFIX = "CommentDialog";

const classes = {
    button: `${PREFIX}-button`,
    buttonC: `${PREFIX}-buttonC`,
};

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    width: "100%",
    "& .MuiToggleButtonGroup-grouped": {
        border: 0,
        borderRadius: 30,
        height: 35,
        width: "50%",
        backgroundColor: "rgba(47,49,144,0.1)",
        color: "#031851",
        "&:hover": {
            backgroundColor: "rgba(47, 49, 144, 0.05)",
        },
    },
    "& .Mui-selected": {
        backgroundColor: "#2f3190 !important",
        color: "white !important",
        "&:hover": {
            backgroundColor: "rgba(47, 49, 144, 0.8) !important",
        },
    },
}));

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "rgba(19, 31, 62, 0.80)",
        fontSize: 12,
        maxWidth: 320,
        borderRadius: 6,
        fontWeight: "normal",
    },
});

const CustomDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialog-paper": {
        borderRadius: 8,
        boxShadow: "10px 10px 15px rgba(3,24,81,0.15)",
    },
    [`& .${classes.button}`]: {
        borderRadius: 40,
        paddingLeft: 40,
        paddingRight: 40,
        "&:hover": {
            backgroundColor: theme.palette.text.secondary,
            color: "#fff",
        },
    },
    [`& .${classes.buttonC}`]: {
        borderRadius: 40,
        paddingLeft: 40,
        paddingRight: 40,
        "&:hover": {
            backgroundColor: "white",
            color: "#2f3190",
            border: "1px solid #2f3190",
        },
    },
}));

const closeComUsers = ["dloyo@gca.digital", "dleon@gca.digital", "atorrealba@gca.digital"];

export default function AlertDialog(props) {
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    const {
        setBackDrop,
        prospectID,
        commentType,
        getComments,
        setTexto,
        setAutoAlertType,
        setShow,
        onClose,
        open,
        comments,
        commentsP,
        loadInitData,
        proposalInfo,
    } = props;

    /* React redux */
    const todos = useSelector((state) => state.todos);

    /** Component states */
    const [value, setValue] = useState("");
    const [error, setError] = useState(false);
    const [idComment, setidComment] = useState("");
    const [editMode, setEditMode] = useState(false);
    const [chatT, setChatType] = useState("pend");
    const chatContainerRef = useRef(null);

    const dateFormat = sessionStorage.getItem("lng") === "es" ? "DD/MM/YYYY" : "MM/DD/YYYY";

    /** Component functions */
    useEffect(() => {
        if (open) {
            const lastChildElement = chatContainerRef.current?.lastElementChild;
            lastChildElement?.scrollIntoView();
        }
    });

    const handleChangeChat = (event, newAlignment) => {
        if (newAlignment !== null) {
            setChatType(newAlignment);
        }
    };

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const addComment = async () => {
        setBackDrop(true);

        let params = {};

        params = {
            prospect_id: prospectID,
            comment_description: value,
            user_name: todos.userInfo.user_name,
            email: todos.userInfo.email,
            comment_type: commentType,
        };

        if (value.trim() !== "") {
            await fetch("/addComment", {
                method: "POST",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
                body: JSON.stringify(params),
            })
                .then(() => {
                    setValue("");
                    setError(false);
                    getComments(prospectID);
                    loadInitData();
                    setTexto(t("commentDialog.commentCreated"));
                    setAutoAlertType("success");
                    setShow(true);
                })
                .catch((err) => {
                    setTexto(err.error);
                    setAutoAlertType("error");
                    setShow(true);
                });
        } else {
            setTexto(t("commentDialog.mustEnterComment"));
            setAutoAlertType("warning");
            setShow(true);
            setError(true);
        }

        setBackDrop(false);
    };

    const deleteC = async (id) => {
        setBackDrop(true);

        const params = {
            comment_id: id,
        };

        await fetch("/deleteComment", {
            method: "POST",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
            body: JSON.stringify(params),
        })
            .then(() => {
                setValue("");
                setEditMode(false);
                getComments(prospectID);
                loadInitData();
                setTexto(t("commentDialog.commentDeleted"));
                setAutoAlertType("success");
                setShow(true);
            })
            .catch((err) => {
                setTexto(err.error);
                setAutoAlertType("error");
                setShow(true);
            });

        setBackDrop(false);
    };

    const editC = async () => {
        setBackDrop(true);

        const params = {
            comment_id: idComment,
            comment_description: value,
        };

        if (value.trim() !== "") {
            await fetch("/updateComment", {
                method: "POST",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
                body: JSON.stringify(params),
            })
                .then(() => {
                    setValue("");
                    setError(false);
                    setEditMode(false);
                    getComments(prospectID);
                    setTexto(t("commentDialog.commentUpdated"));
                    setAutoAlertType("success");
                    setShow(true);
                })
                .catch((err) => {
                    setTexto(err.error);
                    setAutoAlertType("error");
                    setShow(true);
                });
        } else {
            setTexto(t("commentDialog.mustEnterComment"));
            setAutoAlertType("warning");
            setShow(true);
            setError(true);
        }

        setBackDrop(false);
    };

    const showeditMode = (id, comment) => {
        setidComment(id);
        setEditMode(true);
        setValue(comment);
    };

    const closeEditMode = () => {
        setEditMode(false);
        setValue("");
    };

    const closing = () => {
        setValue("");
        setError(false);
        setEditMode(false);
        setChatType("pend");
        onClose();
    };

    const handleChangeStatus = async (event, value) => {
        setBackDrop(true);

        const params = {
            comment_id: value,
            status: event.target.checked ? 1 : 0,
        };

        await fetch("/updateComment", {
            method: "POST",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
            body: JSON.stringify(params),
        })
            .then(() => {
                setValue("");
                setError(false);
                setEditMode(false);
                getComments(prospectID);
                loadInitData();
                setTexto(t("commentDialog.commentUpdated"));
                setAutoAlertType("success");
                setShow(true);
            })
            .catch((err) => {
                setTexto(err.error);
                setAutoAlertType("error");
                setShow(true);
            });

        setBackDrop(false);
    };

    return (
        <CustomDialog
            open={open}
            onClose={closing}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="xs"
            fullWidth={true}
        >
            <DialogContent>
                <StyledToggleButtonGroup color="primary" value={chatT} exclusive onChange={handleChangeChat}>
                    <ToggleButton value="pend">{t("miscellaneous.pendings")}</ToggleButton>
                    <ToggleButton value="hist">{t("miscellaneous.historical")}</ToggleButton>
                </StyledToggleButtonGroup>
                <List ref={chatContainerRef} sx={{ width: "100%", maxHeight: 300, bgcolor: "background.paper" }}>
                    {((comments.length === 0 && chatT === "hist") || (commentsP.length === 0 && chatT === "pend")) && (
                        <ListItem sx={{ float: "right" }}>
                            <ListItemText
                                primary={t("commentDialog.noComments")}
                                sx={{ textAlign: "center" }}
                            ></ListItemText>
                        </ListItem>
                    )}
                    {chatT === "pend" &&
                        commentsP.map((option, index) => (
                            <>
                                <ListItem alignItems="flex-start" key={index}>
                                    <ListItemAvatar>
                                        <Avatar alt="User" src={usersAvatar[option.email.split("@")[0]]} />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <>
                                                <Typography
                                                    sx={{ fontWeight: 600 }}
                                                    component="span"
                                                    variant="body2"
                                                    color="text.secondary"
                                                >
                                                    {`${option.user_name} ${t("commentDialog.commented")}:`}
                                                </Typography>
                                                <Box sx={{ float: "right" }}>
                                                    {((todos.userInfo !== undefined &&
                                                        option.email === todos.userInfo.email) ||
                                                        (proposalInfo &&
                                                            option.email === proposalInfo.prospect_email)) && (
                                                        <>
                                                            <LightTooltip
                                                                title={t("miscellaneous.edit")}
                                                                aria-label="edit"
                                                            >
                                                                <IconButton
                                                                    edge="end"
                                                                    aria-label="edit"
                                                                    size="small"
                                                                    sx={{ color: "#2f3190" }}
                                                                    onClick={() =>
                                                                        showeditMode(
                                                                            option._id,
                                                                            option.comment_description
                                                                        )
                                                                    }
                                                                >
                                                                    <EditIcon fontSize="inherit" />
                                                                </IconButton>
                                                            </LightTooltip>
                                                            <LightTooltip
                                                                title={t("miscellaneous.delete")}
                                                                aria-label="delete"
                                                            >
                                                                <IconButton
                                                                    edge="end"
                                                                    aria-label="delete"
                                                                    size="small"
                                                                    sx={{ color: "#2f3190" }}
                                                                    onClick={() => deleteC(option._id)}
                                                                >
                                                                    <DeleteIcon fontSize="inherit" />
                                                                </IconButton>
                                                            </LightTooltip>
                                                        </>
                                                    )}
                                                </Box>
                                            </>
                                        }
                                        secondary={
                                            <>
                                                <Typography
                                                    sx={{ display: "inline" }}
                                                    component="span"
                                                    variant="body1"
                                                    color="text.primary"
                                                    dangerouslySetInnerHTML={{
                                                        __html:
                                                            option.comment_description.replace(/\n/g, "<br>") + " — ",
                                                    }}
                                                ></Typography>
                                                <Typography component="span" sx={{ fontSize: 11, fontWeight: 600 }}>
                                                    {moment(option.date).format(
                                                        `${todos.dateFormat ?? dateFormat} HH:mm`
                                                    )}
                                                </Typography>
                                            </>
                                        }
                                    />
                                </ListItem>
                                {todos.userInfo !== undefined &&
                                    (option.email === todos.userInfo.email ||
                                        closeComUsers.includes(todos.userInfo.email)) && (
                                        <Box sx={{ marginTop: -2, paddingLeft: 9 }}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={false}
                                                        onChange={(event) => handleChangeStatus(event, option._id)}
                                                    />
                                                }
                                                label={t("miscellaneous.closed")}
                                            />
                                        </Box>
                                    )}
                                {index !== commentsP.length - 1 && <Divider variant="inset" component="li" />}
                            </>
                        ))}
                    {chatT === "hist" &&
                        comments.map((option, index) => (
                            <>
                                <ListItem alignItems="flex-start" key={index}>
                                    <ListItemAvatar>
                                        <Avatar alt="User" src={usersAvatar[option.email.split("@")[0]]} />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <>
                                                <Typography
                                                    sx={{ fontWeight: 600 }}
                                                    component="span"
                                                    variant="body2"
                                                    color="text.secondary"
                                                >
                                                    {`${option.user_name} ${t("commentDialog.commented")}:`}
                                                </Typography>
                                                <Box sx={{ float: "right" }}>
                                                    {((todos.userInfo !== undefined &&
                                                        option.email === todos.userInfo.email) ||
                                                        (proposalInfo &&
                                                            option.email === proposalInfo.prospect_email)) && (
                                                        <>
                                                            <LightTooltip
                                                                title={t("miscellaneous.edit")}
                                                                aria-label="edit"
                                                            >
                                                                <IconButton
                                                                    edge="end"
                                                                    aria-label="edit"
                                                                    size="small"
                                                                    sx={{ color: "#2f3190" }}
                                                                    onClick={() =>
                                                                        showeditMode(
                                                                            option._id,
                                                                            option.comment_description
                                                                        )
                                                                    }
                                                                >
                                                                    <EditIcon fontSize="inherit" />
                                                                </IconButton>
                                                            </LightTooltip>
                                                            <LightTooltip
                                                                title={t("miscellaneous.delete")}
                                                                aria-label="delete"
                                                            >
                                                                <IconButton
                                                                    edge="end"
                                                                    aria-label="delete"
                                                                    size="small"
                                                                    sx={{ color: "#2f3190" }}
                                                                    onClick={() => deleteC(option._id)}
                                                                >
                                                                    <DeleteIcon fontSize="inherit" />
                                                                </IconButton>
                                                            </LightTooltip>
                                                        </>
                                                    )}
                                                </Box>
                                            </>
                                        }
                                        secondary={
                                            <>
                                                <Typography
                                                    sx={{ display: "inline" }}
                                                    component="span"
                                                    variant="body1"
                                                    color="text.primary"
                                                    dangerouslySetInnerHTML={{
                                                        __html:
                                                            option.comment_description.replace(/\n/g, "<br>") + " — ",
                                                    }}
                                                ></Typography>
                                                <Typography component="span" sx={{ fontSize: 11, fontWeight: 600 }}>
                                                    {moment(option.date).format(`${todos.dateFormat} HH:mm`)}
                                                </Typography>
                                            </>
                                        }
                                    />
                                </ListItem>
                                {todos.userInfo !== undefined &&
                                    (option.email === todos.userInfo.email ||
                                        closeComUsers.includes(todos.userInfo.email)) && (
                                        <Box sx={{ marginTop: -2, paddingLeft: 9 }}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        defaultChecked
                                                        onChange={(event) => handleChangeStatus(event, option._id)}
                                                    />
                                                }
                                                label={t("miscellaneous.closed")}
                                            />
                                        </Box>
                                    )}
                                {index !== comments.length - 1 && <Divider variant="inset" component="li" />}
                            </>
                        ))}
                </List>
            </DialogContent>
            <DialogActions sx={{ p: 2 }}>
                <Grid container spacing={2} justifyContent={editMode ? "space-between" : "center"}>
                    <Grid item xs={12}>
                        <TextField
                            id="standard-multiline-flexible"
                            placeholder={t("commentDialog.writingHere")}
                            fullWidth
                            multiline
                            rows={4}
                            value={value}
                            onChange={handleChange}
                            error={error && value.trim() === ""}
                        />
                    </Grid>
                    {editMode && (
                        <Grid item>
                            <Button
                                variant="outlined"
                                color="secondary"
                                className={classes.buttonC}
                                disableElevation
                                onClick={closeEditMode}
                            >
                                Cancelar
                            </Button>
                        </Grid>
                    )}
                    <Grid item>
                        <Button
                            variant="contained"
                            color="secondary"
                            className={classes.button}
                            disableElevation
                            onClick={editMode ? editC : addComment}
                        >
                            {editMode ? t("miscellaneous.edit") : t("miscellaneous.comment")}
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </CustomDialog>
    );
}
