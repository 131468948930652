/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

/** Icons/Images */
import { ReactComponent as AccountCircle } from "../../../assets/images/avatar-image.svg";

/** MUI icons */
import ClearAllIcon from "@mui/icons-material/ClearAll";
import { ReactComponent as CreditCardIcon } from "../../../assets/images/credit_card.svg";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import LockIcon from "@mui/icons-material/Lock";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import InsertChartOutlinedIcon from "@mui/icons-material/InsertChartOutlined";

/** MUI imports */
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    Divider,
    FormControl,
    FormGroup,
    FormLabel,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Skeleton,
    Select,
    TextField,
    Typography,
} from "@mui/material";

/** Components imports */
import AlertDialog from "../Dialog";
import Staff from "../../Js/staff";
import moment from "moment";
import CardHeaderGCA from "../../ui/CardHeaderGCA";

const PREFIX = "ClientCont";

const classes = {
    btnRoot: `${PREFIX}-btnRoot`,
    icons: `${PREFIX}-icons`,
    avatarImage: `${PREFIX}-avatarImage`,
};

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "rgba(19, 31, 62, 0.80)",
        fontSize: 12,
        maxWidth: 320,
        borderRadius: 6,
        fontWeight: "normal",
    },
});

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.btnRoot}`]: {
        border: "1px solid #ff5968",
        borderRadius: 40,
        "&:hover": {
            backgroundColor: "#2f3190",
            border: "1px solid #2f3190",
        },
    },
    [`& .${classes.icons}`]: {
        border: "1px solid #ff5968",
        borderRadius: 40,
        "&:hover": {
            backgroundColor: "#2f3190",
            border: "1px solid #2f3190",
        },
    },

    [`& .${classes.avatarImage}`]: {
        display: "block",
        width: 65,
        height: 65,
        marginLeft: "auto",
        marginRight: "auto",
    },
}));

const teamCompanyDefault = {
    contact_name: "",
    contact_email: "",
    contact_role: "",
    contact_closure: false,
    contact_status: false,
    contact_requirements: false,
    contact_invoices: false,
};

const defaultStaff = {
    teamCompany1: teamCompanyDefault,
    teamCompany2: teamCompanyDefault,
    teamCompany3: teamCompanyDefault,
    teamCompany4: teamCompanyDefault,
    supervisorOG: "Por asignar",
    managerOG: "Por asignar",
    advisorOG: "Por asignar",
    analystOG: "Por asignar",
    backupAnalystOG: "Por asignar",
    supervisor: "Por asignar",
    manager: "Por asignar",
    advisor: "Por asignar",
    analyst: "Por asignar",
    backupAnalyst: "Por asignar",
    supervisorReq: "Por asignar",
    managerReq: "Por asignar",
    advisorReq: "Por asignar",
    analystReq: "Por asignar",
    backupAnalystReq: "Por asignar",
};

const ClientStaff = ({ setBackDrop, data, setTexto, setAlertType, setShow, loadingCard, setLoadingCard }) => {
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    /** Component states */
    const [dialogForm, setDialogForm] = useState(false);
    const [isError, setisError] = useState(false);
    const [valueStaff, setValueStaff] = useState(defaultStaff);
    const [isReq, setIsReq] = useState(false);
    const [isReload, setIsReload] = useState(false);

    /** Component variables */
    let isRequired2 =
        valueStaff.teamCompany2.contact_name.trim() !== "" ||
        valueStaff.teamCompany2.contact_email.trim() !== "" ||
        valueStaff.teamCompany2.contact_role.trim() !== "" ||
        valueStaff.teamCompany2.contact_closure ||
        valueStaff.teamCompany2.contact_status ||
        valueStaff.teamCompany2.contact_requirements ||
        valueStaff.teamCompany2.contact_invoices;
    let isRequired3 =
        valueStaff.teamCompany3.contact_name.trim() !== "" ||
        valueStaff.teamCompany3.contact_email.trim() !== "" ||
        valueStaff.teamCompany3.contact_role.trim() !== "" ||
        valueStaff.teamCompany3.contact_closure ||
        valueStaff.teamCompany3.contact_status ||
        valueStaff.teamCompany3.contact_requirements ||
        valueStaff.teamCompany3.contact_invoices;
    let isRequired4 =
        valueStaff.teamCompany4.contact_name.trim() !== "" ||
        valueStaff.teamCompany4.contact_email.trim() !== "" ||
        valueStaff.teamCompany4.contact_role.trim() !== "" ||
        valueStaff.teamCompany4.contact_closure ||
        valueStaff.teamCompany4.contact_status ||
        valueStaff.teamCompany4.contact_requirements ||
        valueStaff.teamCompany4.contact_invoices;

    /** Component functions */
    const handleChangeCont = (event) => {
        setValueStaff({
            ...valueStaff,
            [event.target.name]: event.target.value,
        });
    };

    const handleChangeTeamStaff = (event, team) => {
        setValueStaff({
            ...valueStaff,
            [team]: {
                ...valueStaff[team],
                [event.target.name]: event.target.value,
            },
        });
    };

    const handleChangeCheck = (event, value, team) => {
        switch (value) {
            case 0:
                setValueStaff({
                    ...valueStaff,
                    [team]: {
                        ...valueStaff[team],
                        contact_closure: event.target.checked,
                    },
                });
                break;
            case 1:
                setValueStaff({
                    ...valueStaff,
                    [team]: {
                        ...valueStaff[team],
                        contact_status: event.target.checked,
                    },
                });
                break;
            case 4:
                setValueStaff({
                    ...valueStaff,
                    [team]: {
                        ...valueStaff[team],
                        contact_invoices: event.target.checked,
                    },
                });
                break;
            default:
                setValueStaff({
                    ...valueStaff,
                    [team]: {
                        ...valueStaff[team],
                        contact_requirements: event.target.checked,
                    },
                });
                break;
        }
    };

    const clearTeamStaff = (event, team) => {
        setValueStaff({
            ...valueStaff,
            [team]: teamCompanyDefault,
        });
    };

    const updateClientDataTeam = async () => {
        setBackDrop(true);

        if (
            valueStaff.teamCompany1.contact_name.trim() &&
            valueStaff.teamCompany1.contact_email.trim() &&
            /.+@.+\..+/.test(valueStaff.teamCompany1.contact_email) &&
            valueStaff.teamCompany1.contact_role.trim()
        ) {
            if (
                valueStaff.teamCompany1.contact_closure ||
                valueStaff.teamCompany1.contact_status ||
                valueStaff.teamCompany1.contact_requirements ||
                valueStaff.teamCompany1.contact_invoices
            ) {
                let teamCompany = [];
                teamCompany.push(valueStaff.teamCompany1);

                if (isRequired2) {
                    if (
                        valueStaff.teamCompany2.contact_name.trim() &&
                        valueStaff.teamCompany2.contact_email.trim() &&
                        /.+@.+\..+/.test(valueStaff.teamCompany2.contact_email) &&
                        valueStaff.teamCompany2.contact_role.trim()
                    ) {
                        if (
                            valueStaff.teamCompany2.contact_closure ||
                            valueStaff.teamCompany2.contact_status ||
                            valueStaff.teamCompany2.contact_requirements ||
                            valueStaff.teamCompany2.contact_invoices
                        ) {
                            teamCompany.push(valueStaff.teamCompany2);
                        } else {
                            setTexto(t("miscellaneous.mustSelectIcon"));
                            setAlertType("warning");
                            setShow(true);
                            setBackDrop(false);
                            setisError(true);
                            return;
                        }
                    } else {
                        setTexto(t("miscellaneous.requiredData"));
                        setAlertType("warning");
                        setShow(true);
                        setBackDrop(false);
                        setisError(true);
                        return;
                    }
                }
                if (isRequired3) {
                    if (
                        valueStaff.teamCompany3.contact_name.trim() &&
                        valueStaff.teamCompany3.contact_email.trim() &&
                        /.+@.+\..+/.test(valueStaff.teamCompany3.contact_email) &&
                        valueStaff.teamCompany3.contact_role.trim()
                    ) {
                        if (
                            valueStaff.teamCompany3.contact_closure ||
                            valueStaff.teamCompany3.contact_status ||
                            valueStaff.teamCompany3.contact_requirements ||
                            valueStaff.teamCompany3.contact_invoices
                        ) {
                            teamCompany.push(valueStaff.teamCompany3);
                        } else {
                            setTexto(t("miscellaneous.mustSelectIcon"));
                            setAlertType("warning");
                            setShow(true);
                            setBackDrop(false);
                            setisError(true);
                            return;
                        }
                    } else {
                        setTexto(t("miscellaneous.requiredData"));
                        setAlertType("warning");
                        setShow(true);
                        setBackDrop(false);
                        setisError(true);
                        return;
                    }
                }
                if (isRequired4) {
                    if (
                        valueStaff.teamCompany4.contact_name.trim() &&
                        valueStaff.teamCompany4.contact_email.trim() &&
                        /.+@.+\..+/.test(valueStaff.teamCompany4.contact_email) &&
                        valueStaff.teamCompany4.contact_role.trim()
                    ) {
                        if (
                            valueStaff.teamCompany4.contact_closure ||
                            valueStaff.teamCompany4.contact_status ||
                            valueStaff.teamCompany4.contact_requirements ||
                            valueStaff.teamCompany4.contact_invoices
                        ) {
                            teamCompany.push(valueStaff.teamCompany4);
                        } else {
                            setTexto(t("miscellaneous.mustSelectIcon"));
                            setAlertType("warning");
                            setShow(true);
                            setBackDrop(false);
                            setisError(true);
                            return;
                        }
                    } else {
                        setTexto(t("miscellaneous.requiredData"));
                        setAlertType("warning");
                        setShow(true);
                        setBackDrop(false);
                        setisError(true);
                        return;
                    }
                }

                const params = {
                    team_company: teamCompany,
                    id_organisation: data._id,
                };

                await fetch(`/updateTeamCompany`, {
                    method: "POST",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                    },
                    body: JSON.stringify(params),
                })
                    .then((response) => {
                        if (response.ok) {
                            setTexto(t("miscellaneous.successfulUpdate"));
                            setAlertType("success");
                            setShow(true);
                        } else {
                            setTexto(t("miscellaneous.updatingError"));
                            setAlertType("error");
                            setShow(true);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } else {
                setTexto(t("miscellaneous.mustSelectIcon"));
                setAlertType("warning");
                setShow(true);
                setBackDrop(false);
                setisError(true);
                return;
            }
        } else {
            setTexto(t("miscellaneous.requiredData"));
            setAlertType("warning");
            setShow(true);
            setisError(true);
        }

        setBackDrop(false);
    };

    const updateClientData = async () => {
        setBackDrop(true);

        if (
            valueStaff.supervisor &&
            valueStaff.manager &&
            valueStaff.advisor &&
            valueStaff.analyst &&
            valueStaff.backupAnalyst
        ) {
            const params = {
                id_organisation: data._id,
                supervisor_name: !valueStaff.supervisor ? "Por asignar" : valueStaff.supervisor,
                manager_name: !valueStaff.manager ? "Por asignar" : valueStaff.manager,
                analyst_name: !valueStaff.analyst ? "Por asignar" : valueStaff.analyst,
                backup_analyst_name: !valueStaff.backupAnalyst ? "Por asignar" : valueStaff.backupAnalyst,
                advisor_name: !valueStaff.advisor ? "Por asignar" : valueStaff.advisor,
            };

            await fetch(`/updateOperationsInfo`, {
                method: "POST",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
                body: JSON.stringify(params),
            })
                .then((response) => {
                    if (response.ok) {
                        setTexto(t("miscellaneous.successfulUpdate"));
                        setAlertType("success");
                        setShow(true);
                        setIsReload(!isReload);
                    } else {
                        setTexto(t("miscellaneous.updatingError"));
                        setAlertType("error");
                        setShow(true);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            setTexto(t("miscellaneous.requiredData"));
            setAlertType("warning");
            setShow(true);
            setisError(true);
        }

        setBackDrop(false);
    };

    useEffect(() => {
        (async () => {
            setLoadingCard(true);

            let teamCompany1 = teamCompanyDefault;
            let teamCompany2 = teamCompanyDefault;
            let teamCompany3 = teamCompanyDefault;
            let teamCompany4 = teamCompanyDefault;

            await fetch(`/getOperationsInfo?id_organisation=${data._id}`, {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
                .then((res) => res.json())
                .then((data) => {
                    if (data.team_company !== undefined) {
                        if (data.team_company.length !== 0) {
                            data.team_company.forEach((element, index) => {
                                if (index === 0) {
                                    teamCompany1 = {
                                        contact_name:
                                            element.contact_name !== undefined && element.contact_name !== null
                                                ? element.contact_name
                                                : "",
                                        contact_email:
                                            element.contact_email !== undefined && element.contact_email !== null
                                                ? element.contact_email
                                                : "",
                                        contact_role:
                                            element.contact_role !== undefined && element.contact_role !== null
                                                ? element.contact_role
                                                : "",
                                        contact_closure: element.contact_closure,
                                        contact_status: element.contact_status,
                                        contact_requirements: element.contact_requirements,
                                        contact_invoices: element.contact_invoices,
                                    };
                                }
                                if (index === 1) {
                                    teamCompany2 = {
                                        contact_name:
                                            element.contact_name !== undefined && element.contact_name !== null
                                                ? element.contact_name
                                                : "",
                                        contact_email:
                                            element.contact_email !== undefined && element.contact_email !== null
                                                ? element.contact_email
                                                : "",
                                        contact_role:
                                            element.contact_role !== undefined && element.contact_role !== null
                                                ? element.contact_role
                                                : "",
                                        contact_closure: element.contact_closure,
                                        contact_status: element.contact_status,
                                        contact_requirements: element.contact_requirements,
                                        contact_invoices: element.contact_invoices,
                                    };
                                }
                                if (index === 2) {
                                    teamCompany3 = {
                                        contact_name:
                                            element.contact_name !== undefined && element.contact_name !== null
                                                ? element.contact_name
                                                : "",
                                        contact_email:
                                            element.contact_email !== undefined && element.contact_email !== null
                                                ? element.contact_email
                                                : "",
                                        contact_role:
                                            element.contact_role !== undefined && element.contact_role !== null
                                                ? element.contact_role
                                                : "",
                                        contact_closure: element.contact_closure,
                                        contact_status: element.contact_status,
                                        contact_requirements: element.contact_requirements,
                                        contact_invoices: element.contact_invoices,
                                    };
                                }
                                if (index === 3) {
                                    teamCompany4 = {
                                        contact_name:
                                            element.contact_name !== undefined && element.contact_name !== null
                                                ? element.contact_name
                                                : "",
                                        contact_email:
                                            element.contact_email !== undefined && element.contact_email !== null
                                                ? element.contact_email
                                                : "",
                                        contact_role:
                                            element.contact_role !== undefined && element.contact_role !== null
                                                ? element.contact_role
                                                : "",
                                        contact_closure: element.contact_closure,
                                        contact_status: element.contact_status,
                                        contact_requirements: element.contact_requirements,
                                        contact_invoices: element.contact_invoices,
                                    };
                                }
                            });
                        }
                    }

                    if (
                        data.supervisor_name_request ||
                        data.manager_name_request ||
                        data.analyst_name_request ||
                        data.backup_analyst_name_request ||
                        data.advisor_name_request
                    ) {
                        setIsReq(true);
                    }

                    setValueStaff({
                        teamCompany1: teamCompany1,
                        teamCompany2: teamCompany2,
                        teamCompany3: teamCompany3,
                        teamCompany4: teamCompany4,
                        supervisorOG: data.supervisor_name ?? "Por asignar",
                        managerOG: data.manager_name ?? "Por asignar",
                        advisorOG: data.advisor_name ?? "Por asignar",
                        analystOG: data.analyst_name ?? "Por asignar",
                        backupAnalystOG: data.backup_analyst_name ?? "Por asignar",
                        supervisor: data.supervisor_name ?? "Por asignar",
                        manager: data.manager_name ?? "Por asignar",
                        analyst: data.analyst_name ?? "Por asignar",
                        backupAnalyst: data.backup_analyst_name ?? "Por asignar",
                        advisor: data.advisor_name ?? "Por asignar",
                        supervisorReq: data.supervisor_name_request,
                        managerReq: data.manager_name_request,
                        advisorReq: data.advisor_name_request,
                        analystReq: data.analyst_name_request,
                        backupAnalystReq: data.backup_analyst_name_request,
                    });
                });
            setLoadingCard(false);
        })();
    }, [data, setBackDrop, setLoadingCard, isReload]);

    return (
        <StyledContainer>
            <Card elevation={0}>
                <CardHeader title={t("miscellaneous.clientStaff")} sx={{ color: "#ff5968" }} />
                <Divider />
                <CardContent sx={{ pb: 5 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            {loadingCard ? (
                                <Skeleton variant="rounded" animation="wave" height="315px" sx={{ borderRadius: 4 }} />
                            ) : (
                                <Card
                                    sx={{
                                        borderRadius: "20px",
                                        backgroundColor: "#F8F9F8",
                                        boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                    }}
                                >
                                    <CardContent sx={{ p: "24px 24px 16px !important" }}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} lg={4}>
                                                <AccountCircle className={classes.avatarImage} />
                                                <Typography variant="h2" color="primary" align="center" sx={{ pt: 1 }}>
                                                    {`${t("miscellaneous.contact")} 1`}
                                                </Typography>
                                                <LightTooltip title={t("recurrent.clear")}>
                                                    <IconButton
                                                        aria-label="clear"
                                                        color="primary"
                                                        onClick={(event) => clearTeamStaff(event, "teamCompany1")}
                                                        sx={{ display: "block", m: "auto" }}
                                                    >
                                                        <ClearAllIcon />
                                                    </IconButton>
                                                </LightTooltip>
                                            </Grid>
                                            <Grid item xs={12} lg={8}>
                                                <TextField
                                                    fullWidth
                                                    required
                                                    label={t("miscellaneous.name")}
                                                    name="contact_name"
                                                    onChange={(event) => handleChangeTeamStaff(event, "teamCompany1")}
                                                    value={valueStaff.teamCompany1.contact_name}
                                                    variant="outlined"
                                                    error={
                                                        isError && valueStaff.teamCompany1.contact_name.trim() === ""
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                <TextField
                                                    fullWidth
                                                    required
                                                    label={t("miscellaneous.email")}
                                                    name="contact_email"
                                                    onChange={(event) => handleChangeTeamStaff(event, "teamCompany1")}
                                                    value={valueStaff.teamCompany1.contact_email}
                                                    variant="outlined"
                                                    error={
                                                        isError && valueStaff.teamCompany1.contact_email.trim() === ""
                                                            ? true
                                                            : isError &&
                                                              !/.+@.+\..+/.test(
                                                                  valueStaff.teamCompany1.contact_email.trim()
                                                              )
                                                            ? true
                                                            : false
                                                    }
                                                    sx={{ mt: 2 }}
                                                />
                                                <TextField
                                                    fullWidth
                                                    required
                                                    label={t("miscellaneous.role")}
                                                    name="contact_role"
                                                    onChange={(event) => handleChangeTeamStaff(event, "teamCompany1")}
                                                    value={valueStaff.teamCompany1.contact_role}
                                                    variant="outlined"
                                                    error={
                                                        isError && valueStaff.teamCompany1.contact_role.trim() === ""
                                                            ? true
                                                            : false
                                                    }
                                                    sx={{ mt: 2 }}
                                                />
                                                <FormGroup sx={{ mt: 2, display: "block" }}>
                                                    <FormLabel id="permissions-checkboxes-group-label">
                                                        <Typography variant="h4" color="primary" sx={{ pb: 1 }}>
                                                            {t("miscellaneous.permissions")}:
                                                        </Typography>
                                                    </FormLabel>
                                                    <LightTooltip
                                                        title={t("services.toolRequirements")}
                                                        aria-label="req"
                                                    >
                                                        <Checkbox
                                                            icon={<AssignmentTurnedInOutlinedIcon />}
                                                            checkedIcon={<AssignmentTurnedInIcon />}
                                                            checked={valueStaff.teamCompany1.contact_requirements}
                                                            onChange={(event) =>
                                                                handleChangeCheck(event, 3, "teamCompany1")
                                                            }
                                                            sx={{
                                                                ml: { xs: 0, lg: 0, xl: 0 },
                                                                mr: { xs: 1, lg: 0, xl: 1 },
                                                            }}
                                                        />
                                                    </LightTooltip>
                                                    <LightTooltip
                                                        title={t("services.toolAccountingClosures")}
                                                        aria-label="cierres"
                                                    >
                                                        <Checkbox
                                                            icon={<LockOutlinedIcon />}
                                                            checkedIcon={<LockIcon />}
                                                            checked={valueStaff.teamCompany1.contact_closure}
                                                            onChange={(event) =>
                                                                handleChangeCheck(event, 0, "teamCompany1")
                                                            }
                                                            sx={{
                                                                ml: { xs: 1, lg: 0, xl: 1 },
                                                                mr: { xs: 1, lg: 0, xl: 1 },
                                                            }}
                                                        />
                                                    </LightTooltip>
                                                    <LightTooltip title={t("services.toolStatus")} aria-label="status">
                                                        <Checkbox
                                                            icon={<InsertChartOutlinedIcon />}
                                                            checkedIcon={<InsertChartIcon />}
                                                            checked={valueStaff.teamCompany1.contact_status}
                                                            onChange={(event) =>
                                                                handleChangeCheck(event, 1, "teamCompany1")
                                                            }
                                                            sx={{
                                                                ml: { xs: 1, lg: 0, xl: 1 },
                                                                mr: { xs: 1, lg: 0, xl: 1 },
                                                            }}
                                                        />
                                                    </LightTooltip>
                                                    <LightTooltip
                                                        title={t("services.toolInvoicing")}
                                                        aria-label="invoicing"
                                                    >
                                                        <Checkbox
                                                            icon={<CreditCardOutlinedIcon />}
                                                            checkedIcon={
                                                                <CreditCardIcon className="icon-normal-size" />
                                                            }
                                                            checked={valueStaff.teamCompany1.contact_invoices}
                                                            onChange={(event) =>
                                                                handleChangeCheck(event, 4, "teamCompany1")
                                                            }
                                                            sx={{
                                                                ml: { xs: 1, lg: 0, xl: 1 },
                                                                mr: { xs: 1, lg: 0, xl: 1 },
                                                            }}
                                                        />
                                                    </LightTooltip>
                                                </FormGroup>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            )}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {loadingCard ? (
                                <Skeleton variant="rounded" animation="wave" height="315px" sx={{ borderRadius: 4 }} />
                            ) : (
                                <Card
                                    sx={{
                                        borderRadius: "20px",
                                        backgroundColor: "#F8F9F8",
                                        boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                    }}
                                >
                                    <CardContent sx={{ p: "24px 24px 16px !important" }}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} lg={4}>
                                                <AccountCircle className={classes.avatarImage} />
                                                <Typography variant="h2" color="primary" align="center" sx={{ pt: 1 }}>
                                                    {`${t("miscellaneous.contact")} 2`}
                                                </Typography>
                                                <LightTooltip title={t("recurrent.clear")}>
                                                    <IconButton
                                                        aria-label="clear"
                                                        color="primary"
                                                        onClick={(event) => clearTeamStaff(event, "teamCompany2")}
                                                        sx={{ display: "block", m: "auto" }}
                                                    >
                                                        <ClearAllIcon />
                                                    </IconButton>
                                                </LightTooltip>
                                            </Grid>
                                            <Grid item xs={12} lg={8}>
                                                <TextField
                                                    fullWidth
                                                    required={isRequired2}
                                                    label={t("miscellaneous.name")}
                                                    name="contact_name"
                                                    onChange={(event) => handleChangeTeamStaff(event, "teamCompany2")}
                                                    value={valueStaff.teamCompany2.contact_name}
                                                    variant="outlined"
                                                    error={
                                                        isRequired2 &&
                                                        isError &&
                                                        valueStaff.teamCompany2.contact_name.trim() === ""
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                <TextField
                                                    fullWidth
                                                    required={isRequired2}
                                                    label={t("miscellaneous.email")}
                                                    name="contact_email"
                                                    onChange={(event) => handleChangeTeamStaff(event, "teamCompany2")}
                                                    value={valueStaff.teamCompany2.contact_email}
                                                    variant="outlined"
                                                    error={
                                                        isRequired2 &&
                                                        isError &&
                                                        valueStaff.teamCompany2.contact_email.trim() === ""
                                                            ? true
                                                            : isRequired2 &&
                                                              isError &&
                                                              !/.+@.+\..+/.test(
                                                                  valueStaff.teamCompany2.contact_email.trim()
                                                              )
                                                            ? true
                                                            : false
                                                    }
                                                    sx={{ mt: 2 }}
                                                />
                                                <TextField
                                                    fullWidth
                                                    required={isRequired2}
                                                    label={t("miscellaneous.role")}
                                                    name="contact_role"
                                                    onChange={(event) => handleChangeTeamStaff(event, "teamCompany2")}
                                                    value={valueStaff.teamCompany2.contact_role}
                                                    variant="outlined"
                                                    error={
                                                        isRequired2 &&
                                                        isError &&
                                                        valueStaff.teamCompany2.contact_role.trim() === ""
                                                            ? true
                                                            : false
                                                    }
                                                    sx={{ mt: 2 }}
                                                />
                                                <FormGroup sx={{ mt: 2, display: "block" }}>
                                                    <FormLabel id="permissions-checkboxes-group-label">
                                                        <Typography variant="h4" color="primary" sx={{ pb: 1 }}>
                                                            {t("miscellaneous.permissions")}:
                                                        </Typography>
                                                    </FormLabel>
                                                    <LightTooltip
                                                        title={t("services.toolRequirements")}
                                                        aria-label="req"
                                                    >
                                                        <Checkbox
                                                            icon={<AssignmentTurnedInOutlinedIcon />}
                                                            checkedIcon={<AssignmentTurnedInIcon />}
                                                            checked={valueStaff.teamCompany2.contact_requirements}
                                                            onChange={(event) =>
                                                                handleChangeCheck(event, 3, "teamCompany2")
                                                            }
                                                            sx={{
                                                                ml: { xs: 0, lg: 0, xl: 0 },
                                                                mr: { xs: 1, lg: 0, xl: 1 },
                                                            }}
                                                        />
                                                    </LightTooltip>
                                                    <LightTooltip
                                                        title={t("services.toolAccountingClosures")}
                                                        aria-label="cierres"
                                                    >
                                                        <Checkbox
                                                            icon={<LockOutlinedIcon />}
                                                            checkedIcon={<LockIcon />}
                                                            checked={valueStaff.teamCompany2.contact_closure}
                                                            onChange={(event) =>
                                                                handleChangeCheck(event, 0, "teamCompany2")
                                                            }
                                                            sx={{
                                                                ml: { xs: 1, lg: 0, xl: 1 },
                                                                mr: { xs: 1, lg: 0, xl: 1 },
                                                            }}
                                                        />
                                                    </LightTooltip>
                                                    <LightTooltip title={t("services.toolStatus")} aria-label="status">
                                                        <Checkbox
                                                            icon={<InsertChartOutlinedIcon />}
                                                            checkedIcon={<InsertChartIcon />}
                                                            checked={valueStaff.teamCompany2.contact_status}
                                                            onChange={(event) =>
                                                                handleChangeCheck(event, 1, "teamCompany2")
                                                            }
                                                            sx={{
                                                                ml: { xs: 1, lg: 0, xl: 1 },
                                                                mr: { xs: 1, lg: 0, xl: 1 },
                                                            }}
                                                        />
                                                    </LightTooltip>
                                                    <LightTooltip
                                                        title={t("services.toolInvoicing")}
                                                        aria-label="invoicing"
                                                    >
                                                        <Checkbox
                                                            icon={<CreditCardOutlinedIcon />}
                                                            checkedIcon={
                                                                <CreditCardIcon className="icon-normal-size" />
                                                            }
                                                            checked={valueStaff.teamCompany2.contact_invoices}
                                                            onChange={(event) =>
                                                                handleChangeCheck(event, 4, "teamCompany2")
                                                            }
                                                            sx={{
                                                                ml: { xs: 1, lg: 0, xl: 1 },
                                                                mr: { xs: 1, lg: 0, xl: 1 },
                                                            }}
                                                        />
                                                    </LightTooltip>
                                                </FormGroup>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            )}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {loadingCard ? (
                                <Skeleton variant="rounded" animation="wave" height="315px" sx={{ borderRadius: 4 }} />
                            ) : (
                                <Card
                                    sx={{
                                        borderRadius: "20px",
                                        backgroundColor: "#F8F9F8",
                                        boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                    }}
                                >
                                    <CardContent sx={{ p: "24px 24px 16px !important" }}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} lg={4}>
                                                <AccountCircle className={classes.avatarImage} />
                                                <Typography variant="h2" color="primary" align="center" sx={{ pt: 1 }}>
                                                    {`${t("miscellaneous.contact")} 3`}
                                                </Typography>
                                                <LightTooltip title={t("recurrent.clear")}>
                                                    <IconButton
                                                        aria-label="clear"
                                                        color="primary"
                                                        onClick={(event) => clearTeamStaff(event, "teamCompany3")}
                                                        sx={{ display: "block", m: "auto" }}
                                                    >
                                                        <ClearAllIcon />
                                                    </IconButton>
                                                </LightTooltip>
                                            </Grid>
                                            <Grid item xs={12} lg={8}>
                                                <TextField
                                                    fullWidth
                                                    required={isRequired3}
                                                    label={t("miscellaneous.name")}
                                                    name="contact_name"
                                                    onChange={(event) => handleChangeTeamStaff(event, "teamCompany3")}
                                                    value={valueStaff.teamCompany3.contact_name}
                                                    variant="outlined"
                                                    error={
                                                        isRequired3 &&
                                                        isError &&
                                                        valueStaff.teamCompany3.contact_name.trim() === ""
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                <TextField
                                                    fullWidth
                                                    required={isRequired3}
                                                    label={t("miscellaneous.email")}
                                                    name="contact_email"
                                                    onChange={(event) => handleChangeTeamStaff(event, "teamCompany3")}
                                                    value={valueStaff.teamCompany3.contact_email}
                                                    variant="outlined"
                                                    error={
                                                        isRequired3 &&
                                                        isError &&
                                                        valueStaff.teamCompany3.contact_email.trim() === ""
                                                            ? true
                                                            : isRequired3 &&
                                                              isError &&
                                                              !/.+@.+\..+/.test(
                                                                  valueStaff.teamCompany3.contact_email.trim()
                                                              )
                                                            ? true
                                                            : false
                                                    }
                                                    sx={{ mt: 2 }}
                                                />
                                                <TextField
                                                    fullWidth
                                                    required={isRequired3}
                                                    label={t("miscellaneous.role")}
                                                    name="contact_role"
                                                    onChange={(event) => handleChangeTeamStaff(event, "teamCompany3")}
                                                    value={valueStaff.teamCompany3.contact_role}
                                                    variant="outlined"
                                                    error={
                                                        isRequired3 &&
                                                        isError &&
                                                        valueStaff.teamCompany3.contact_role.trim() === ""
                                                            ? true
                                                            : false
                                                    }
                                                    sx={{ mt: 2 }}
                                                />
                                                <FormGroup sx={{ mt: 2, display: "block" }}>
                                                    <FormLabel id="permissions-checkboxes-group-label">
                                                        <Typography variant="h4" color="primary" sx={{ pb: 1 }}>
                                                            {t("miscellaneous.permissions")}:
                                                        </Typography>
                                                    </FormLabel>
                                                    <LightTooltip
                                                        title={t("services.toolRequirements")}
                                                        aria-label="req"
                                                    >
                                                        <Checkbox
                                                            icon={<AssignmentTurnedInOutlinedIcon />}
                                                            checkedIcon={<AssignmentTurnedInIcon />}
                                                            checked={valueStaff.teamCompany3.contact_requirements}
                                                            onChange={(event) =>
                                                                handleChangeCheck(event, 3, "teamCompany3")
                                                            }
                                                            sx={{
                                                                ml: { xs: 0, lg: 0, xl: 0 },
                                                                mr: { xs: 1, lg: 0, xl: 1 },
                                                            }}
                                                        />
                                                    </LightTooltip>
                                                    <LightTooltip
                                                        title={t("services.toolAccountingClosures")}
                                                        aria-label="cierres"
                                                    >
                                                        <Checkbox
                                                            icon={<LockOutlinedIcon />}
                                                            checkedIcon={<LockIcon />}
                                                            checked={valueStaff.teamCompany3.contact_closure}
                                                            onChange={(event) =>
                                                                handleChangeCheck(event, 0, "teamCompany3")
                                                            }
                                                            sx={{
                                                                ml: { xs: 1, lg: 0, xl: 1 },
                                                                mr: { xs: 1, lg: 0, xl: 1 },
                                                            }}
                                                        />
                                                    </LightTooltip>
                                                    <LightTooltip title={t("services.toolStatus")} aria-label="status">
                                                        <Checkbox
                                                            icon={<InsertChartOutlinedIcon />}
                                                            checkedIcon={<InsertChartIcon />}
                                                            checked={valueStaff.teamCompany3.contact_status}
                                                            onChange={(event) =>
                                                                handleChangeCheck(event, 1, "teamCompany3")
                                                            }
                                                            sx={{
                                                                ml: { xs: 1, lg: 0, xl: 1 },
                                                                mr: { xs: 1, lg: 0, xl: 1 },
                                                            }}
                                                        />
                                                    </LightTooltip>
                                                    <LightTooltip
                                                        title={t("services.toolInvoicing")}
                                                        aria-label="invoicing"
                                                    >
                                                        <Checkbox
                                                            icon={<CreditCardOutlinedIcon />}
                                                            checkedIcon={
                                                                <CreditCardIcon className="icon-normal-size" />
                                                            }
                                                            checked={valueStaff.teamCompany3.contact_invoices}
                                                            onChange={(event) =>
                                                                handleChangeCheck(event, 4, "teamCompany3")
                                                            }
                                                            sx={{
                                                                ml: { xs: 1, lg: 0, xl: 1 },
                                                                mr: { xs: 1, lg: 0, xl: 1 },
                                                            }}
                                                        />
                                                    </LightTooltip>
                                                </FormGroup>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            )}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {loadingCard ? (
                                <Skeleton variant="rounded" animation="wave" height="315px" sx={{ borderRadius: 4 }} />
                            ) : (
                                <Card
                                    sx={{
                                        borderRadius: "20px",
                                        backgroundColor: "#F8F9F8",
                                        boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                    }}
                                >
                                    <CardContent sx={{ p: "24px 24px 16px !important" }}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} lg={4}>
                                                <AccountCircle className={classes.avatarImage} />
                                                <Typography variant="h2" color="primary" align="center" sx={{ pt: 1 }}>
                                                    {`${t("miscellaneous.contact")} 4`}
                                                </Typography>
                                                <LightTooltip title={t("recurrent.clear")}>
                                                    <IconButton
                                                        aria-label="clear"
                                                        color="primary"
                                                        onClick={(event) => clearTeamStaff(event, "teamCompany4")}
                                                        sx={{ display: "block", m: "auto" }}
                                                    >
                                                        <ClearAllIcon />
                                                    </IconButton>
                                                </LightTooltip>
                                            </Grid>
                                            <Grid item xs={12} lg={8}>
                                                <TextField
                                                    fullWidth
                                                    required={isRequired4}
                                                    label={t("miscellaneous.name")}
                                                    name="contact_name"
                                                    onChange={(event) => handleChangeTeamStaff(event, "teamCompany4")}
                                                    value={valueStaff.teamCompany4.contact_name}
                                                    variant="outlined"
                                                    error={
                                                        isRequired4 &&
                                                        isError &&
                                                        valueStaff.teamCompany4.contact_name.trim() === ""
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                <TextField
                                                    fullWidth
                                                    required={isRequired4}
                                                    label={t("miscellaneous.email")}
                                                    name="contact_email"
                                                    onChange={(event) => handleChangeTeamStaff(event, "teamCompany4")}
                                                    value={valueStaff.teamCompany4.contact_email}
                                                    variant="outlined"
                                                    error={
                                                        isRequired4 &&
                                                        isError &&
                                                        valueStaff.teamCompany4.contact_email.trim() === ""
                                                            ? true
                                                            : isRequired4 &&
                                                              isError &&
                                                              !/.+@.+\..+/.test(
                                                                  valueStaff.teamCompany4.contact_email.trim()
                                                              )
                                                            ? true
                                                            : false
                                                    }
                                                    sx={{ mt: 2 }}
                                                />
                                                <TextField
                                                    fullWidth
                                                    required={isRequired4}
                                                    label={t("miscellaneous.role")}
                                                    name="contact_role"
                                                    onChange={(event) => handleChangeTeamStaff(event, "teamCompany4")}
                                                    value={valueStaff.teamCompany4.contact_role}
                                                    variant="outlined"
                                                    error={
                                                        isRequired4 &&
                                                        isError &&
                                                        valueStaff.teamCompany4.contact_role.trim() === ""
                                                            ? true
                                                            : false
                                                    }
                                                    sx={{ mt: 2 }}
                                                />
                                                <FormGroup sx={{ mt: 2, display: "block" }}>
                                                    <FormLabel id="permissions-checkboxes-group-label">
                                                        <Typography variant="h4" color="primary" sx={{ pb: 1 }}>
                                                            {t("miscellaneous.permissions")}:
                                                        </Typography>
                                                    </FormLabel>
                                                    <LightTooltip
                                                        title={t("services.toolRequirements")}
                                                        aria-label="req"
                                                    >
                                                        <Checkbox
                                                            icon={<AssignmentTurnedInOutlinedIcon />}
                                                            checkedIcon={<AssignmentTurnedInIcon />}
                                                            checked={valueStaff.teamCompany4.contact_requirements}
                                                            onChange={(event) =>
                                                                handleChangeCheck(event, 3, "teamCompany4")
                                                            }
                                                            sx={{
                                                                ml: { xs: 0, lg: 0, xl: 0 },
                                                                mr: { xs: 1, lg: 0, xl: 1 },
                                                            }}
                                                        />
                                                    </LightTooltip>
                                                    <LightTooltip
                                                        title={t("services.toolAccountingClosures")}
                                                        aria-label="cierres"
                                                    >
                                                        <Checkbox
                                                            icon={<LockOutlinedIcon />}
                                                            checkedIcon={<LockIcon />}
                                                            checked={valueStaff.teamCompany4.contact_closure}
                                                            onChange={(event) =>
                                                                handleChangeCheck(event, 0, "teamCompany4")
                                                            }
                                                            sx={{
                                                                ml: { xs: 1, lg: 0, xl: 1 },
                                                                mr: { xs: 1, lg: 0, xl: 1 },
                                                            }}
                                                        />
                                                    </LightTooltip>
                                                    <LightTooltip title={t("services.toolStatus")} aria-label="status">
                                                        <Checkbox
                                                            icon={<InsertChartOutlinedIcon />}
                                                            checkedIcon={<InsertChartIcon />}
                                                            checked={valueStaff.teamCompany4.contact_status}
                                                            onChange={(event) =>
                                                                handleChangeCheck(event, 1, "teamCompany4")
                                                            }
                                                            sx={{
                                                                ml: { xs: 1, lg: 0, xl: 1 },
                                                                mr: { xs: 1, lg: 0, xl: 1 },
                                                            }}
                                                        />
                                                    </LightTooltip>
                                                    <LightTooltip
                                                        title={t("services.toolInvoicing")}
                                                        aria-label="invoicing"
                                                    >
                                                        <Checkbox
                                                            icon={<CreditCardOutlinedIcon />}
                                                            checkedIcon={
                                                                <CreditCardIcon className="icon-normal-size" />
                                                            }
                                                            checked={valueStaff.teamCompany4.contact_invoices}
                                                            onChange={(event) =>
                                                                handleChangeCheck(event, 4, "teamCompany4")
                                                            }
                                                            sx={{
                                                                ml: { xs: 1, lg: 0, xl: 1 },
                                                                mr: { xs: 1, lg: 0, xl: 1 },
                                                            }}
                                                        />
                                                    </LightTooltip>
                                                </FormGroup>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            )}
                        </Grid>
                    </Grid>
                </CardContent>
                <Divider />
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        p: 2,
                        pb: 0,
                    }}
                >
                    <Button
                        color="secondary"
                        variant="contained"
                        disableElevation
                        classes={{ root: classes.btnRoot }}
                        onClick={updateClientDataTeam}
                        className="Button"
                    >
                        {t("miscellaneous.save")}
                    </Button>
                </Box>
            </Card>
            <Card elevation={0}>
                <CardHeaderGCA setValueStaff={setValueStaff} valueStaff={valueStaff} isReq={isReq} />
                <Divider />
                <CardContent sx={{ pb: 5 }}>
                    <Grid container spacing={2}>
                        <Grid item lg={4} sm={6} xs={12}>
                            {loadingCard ? (
                                <Skeleton variant="rounded" animation="wave" height={50} />
                            ) : (
                                <FormControl
                                    fullWidth
                                    required
                                    error={isError && valueStaff.manager === "" ? true : false}
                                >
                                    <InputLabel id="manager-simple-select-label">
                                        {t("miscellaneous.manager")}
                                    </InputLabel>
                                    <Select
                                        name="manager"
                                        labelId="manager-simple-select-label"
                                        label={t("miscellaneous.manager")}
                                        IconComponent={KeyboardArrowDownIcon}
                                        value={valueStaff.manager}
                                        onChange={handleChangeCont}
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    mt: 0.7,
                                                    borderRadius: 2,
                                                    boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                },
                                            },
                                        }}
                                    >
                                        {Staff.manager.map((option) => (
                                            <MenuItem value={option.name_es}>
                                                {moment.locale() === "en" ? option.name_en : option.name_es}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )}
                        </Grid>
                        <Grid item lg={4} sm={6} xs={12}>
                            {loadingCard ? (
                                <Skeleton variant="rounded" animation="wave" height={50} />
                            ) : (
                                <FormControl
                                    fullWidth
                                    required
                                    error={isError && valueStaff.supervisor === "" ? true : false}
                                >
                                    <InputLabel id="supervisor-simple-select-label">
                                        {t("miscellaneous.supervisor")}
                                    </InputLabel>
                                    <Select
                                        name="supervisor"
                                        labelId="supervisor-simple-select-label"
                                        label={t("miscellaneous.supervisor")}
                                        IconComponent={KeyboardArrowDownIcon}
                                        value={valueStaff.supervisor}
                                        onChange={handleChangeCont}
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    mt: 0.7,
                                                    borderRadius: 2,
                                                    boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                },
                                            },
                                        }}
                                    >
                                        {Staff.supervisor.map((option) => (
                                            <MenuItem value={option.name_es}>
                                                {moment.locale() === "en" ? option.name_en : option.name_es}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )}
                        </Grid>
                        <Grid item lg={4} sm={6} xs={12}>
                            {loadingCard ? (
                                <Skeleton variant="rounded" animation="wave" height={50} />
                            ) : (
                                <FormControl
                                    fullWidth
                                    required
                                    error={isError && valueStaff.advisor === "" ? true : false}
                                >
                                    <InputLabel
                                        id="advisor-simple-select-label"
                                        sx={{
                                            fontStyle: "italic",
                                            ...(moment.locale() === "en" && { fontStyle: "normal" }),
                                        }}
                                    >
                                        {t("miscellaneous.advisor")}
                                    </InputLabel>
                                    <Select
                                        name="advisor"
                                        labelId="advisor-simple-select-label"
                                        label={t("miscellaneous.advisor")}
                                        IconComponent={KeyboardArrowDownIcon}
                                        value={valueStaff.advisor}
                                        onChange={handleChangeCont}
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    mt: 0.7,
                                                    borderRadius: 2,
                                                    boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                },
                                            },
                                        }}
                                    >
                                        {Staff.advisor.map((option) => (
                                            <MenuItem value={option.name_es}>
                                                {moment.locale() === "en" ? option.name_en : option.name_es}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )}
                        </Grid>
                        <Grid item lg={4} sm={6} xs={12}>
                            {loadingCard ? (
                                <Skeleton variant="rounded" animation="wave" height={50} />
                            ) : (
                                <FormControl
                                    fullWidth
                                    required
                                    error={isError && valueStaff.analyst === "" ? true : false}
                                >
                                    <InputLabel id="analyst-simple-select-label">
                                        {t("miscellaneous.analyst")}
                                    </InputLabel>
                                    <Select
                                        name="analyst"
                                        labelId="analyst-simple-select-label"
                                        label={t("miscellaneous.analyst")}
                                        IconComponent={KeyboardArrowDownIcon}
                                        value={valueStaff.analyst}
                                        onChange={handleChangeCont}
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    mt: 0.7,
                                                    borderRadius: 2,
                                                    boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                },
                                            },
                                        }}
                                    >
                                        {Staff.analyst.map((option) => (
                                            <MenuItem value={option.name_es}>
                                                {moment.locale() === "en" ? option.name_en : option.name_es}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )}
                        </Grid>
                        <Grid item lg={4} sm={6} xs={12}>
                            {loadingCard ? (
                                <Skeleton variant="rounded" animation="wave" height={50} />
                            ) : (
                                <FormControl
                                    fullWidth
                                    required
                                    error={isError && valueStaff.backupAnalyst === "" ? true : false}
                                >
                                    <InputLabel id="backup-analyst-simple-select-label">
                                        {t("miscellaneous.backupAnalyst")}
                                    </InputLabel>
                                    <Select
                                        name="backupAnalyst"
                                        labelId="backup-analyst-simple-select-label"
                                        label={t("miscellaneous.backupAnalyst")}
                                        IconComponent={KeyboardArrowDownIcon}
                                        value={valueStaff.backupAnalyst}
                                        onChange={handleChangeCont}
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    mt: 0.7,
                                                    borderRadius: 2,
                                                    boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                },
                                            },
                                        }}
                                    >
                                        {Staff.backupAnalyst.map((option) => (
                                            <MenuItem value={option.name_es}>
                                                {moment.locale() === "en" ? option.name_en : option.name_es}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )}
                        </Grid>
                    </Grid>
                </CardContent>
                <Divider />
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        p: 2,
                        pb: 0,
                    }}
                >
                    <Button
                        color="secondary"
                        variant="contained"
                        disableElevation
                        classes={{ root: classes.btnRoot }}
                        onClick={updateClientData}
                        disabled={isReq}
                        className="Button"
                    >
                        {t("miscellaneous.save")}
                    </Button>
                </Box>
            </Card>
            <AlertDialog
                open={dialogForm}
                onClose={() => {
                    setDialogForm(false);
                }}
                message={t("miscellaneous.noAccess")}
                title={t("miscellaneous.attention")}
                maxWidth="xs"
            />
        </StyledContainer>
    );
};

export default ClientStaff;
