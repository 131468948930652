/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

/** MUI imports */
import { Grid, Table, TableHeaderRow, PagingPanel, Toolbar, SearchPanel } from "@devexpress/dx-react-grid-material-ui";
import {
    SearchState,
    IntegratedFiltering,
    PagingState,
    IntegratedPaging,
    SelectionState,
    IntegratedSelection,
} from "@devexpress/dx-react-grid";
import { styled } from "@mui/material/styles";
import GridUI from "@mui/material/Grid";
import { Box, Hidden, Skeleton } from "@mui/material";

/** Component styles */
const PREFIX = "InPendings";

const classes = {
    paper: `${PREFIX}-paper`,
    tableStriped: `${PREFIX}-tableStriped`,
    tableHeader: `${PREFIX}-tableHeader`,
    tableBody: `${PREFIX}-tableBody`,
    pager: `${PREFIX}-pager`,
    cardContentRoot: `${PREFIX}-cardContentRoot`,
    cardContentRoot2: `${PREFIX}-cardContentRoot2`,
    cardHeaderRoot: `${PREFIX}-cardHeaderRoot`,
    formRounded: `${PREFIX}-formRounded`,
    formControlRounded: `${PREFIX}-formControlRounded`,
    spacing: `${PREFIX}-spacing`,
    toolbarSearch: `${PREFIX}-toolbarSearch`,
    searchInput: `${PREFIX}-searchInput`,
    totalTable: `${PREFIX}-totalTable`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.totalTable}`]: {
        background: "#EBE9F4",
        borderRadius: "20px",
        padding: "7px 18px",
        width: 350,
        fontWeight: 600,
        textAlign: "center",
    },

    [`& .${classes.paper}`]: {
        border: "2px solid #ff5968",
        borderRadius: 40,
        boxShadow: "none",
    },

    [`& .${classes.cardContentRoot}`]: {
        "&:last-child": {
            paddingBottom: "5px",
        },
        padding: "10px",
    },

    [`& .${classes.cardContentRoot2}`]: {
        "&:last-child": {
            paddingBottom: "18px",
        },
        padding: 30,
    },

    [`& .${classes.cardHeaderRoot}`]: {
        padding: 30,
    },

    [`& .${classes.formRounded}`]: {
        backgroundColor: "#FFFFFF !important",
        borderRadius: 40,
        "&:focus-within": {
            backgroundColor: "#FFFFFF",
            borderRadius: 40,
        },
        "& .MuiOutlinedInput-notchedOutline": {
            border: "2px solid #031851",
            borderRadius: 40,
        },
    },

    [`& .${classes.formControlRounded}`]: {
        backgroundColor: "#FFFFFF !important",
        borderRadius: 40,
        border: "2px solid #031851",
        verticalAlign: "middle !important",
    },

    [`& .${classes.spacing}`]: {
        padding: "30px 0 20px 0",
    },

    [`& .${classes.tableStriped}`]: {
        "& tbody tr:nth-of-type(odd)": {
            backgroundColor: "rgba(47,49,144,0.1)",
        },
    },
    [`& .${classes.tableHeader}`]: {
        border: "none ",
        backgroundColor: "rgba(47,49,144,0.3)",
        fontSize: "15px",
        fontWeight: 600,
        color: "#031851",
        "&:first-of-type": {
            borderRadius: "20px 0 0 0",
        },
        "&:last-child": {
            borderRadius: "0 20px 0 0",
            paddingRight: "24px",
        },
    },

    [`& .${classes.tableBody}`]: {
        border: "none",
        fontSize: "14px",
        fontWeight: 400,
        color: "#031851",
    },

    [`& .${classes.pager}`]: {
        "& .Pagination-button": {
            paddingTop: 0,
            paddingBottom: 0,
            color: "#031851",
            fontWeight: 600,
        },
        "& .Pagination-activeButton": {
            backgroundColor: "rgba(47,49,144,0.3)",
        },
    },

    [`& .${classes.toolbarSearch}`]: {
        border: "none !important",
        padding: "0 0 5px !important",
    },

    [`& .${classes.searchInput}`]: {
        color: "#031851",
        "&:before": {
            borderBottomColor: "#031851",
        },
        "&:hover:before": {
            borderBottomColor: "#031851 !important",
        },
    },
}));

const tableHeaderCell = ({ ...props }) => <TableHeaderRow.Cell {...props} className={classes.tableHeader} />;

const TableComponent = ({ ...props }) => <Table.Table {...props} className={classes.tableStriped} />;

const TableCellComponent = ({ ...props }) => <Table.Cell {...props} className={classes.tableBody} />;

const tableContentComponent = (props) => <TableHeaderRow.Content {...props} />;

const PagingPanelContainer = ({ ...props }) => <PagingPanel.Container {...props} className={classes.pager} />;

const inputComponent = ({ ...props }) => <SearchPanel.Input {...props} className={classes.searchInput} />;

const toolRootComponent = ({ ...props }) => <Toolbar.Root {...props} className={classes.toolbarSearch} />;

const tableColumnExtensions = [
    { columnName: "contact", width: "20%" },
    { columnName: "smb" },
    { columnName: "service", align: "center" },
    { columnName: "project", align: "center" },
    { columnName: "period", align: "center" },
    { columnName: "initial", align: "center" },
    { columnName: "estimatedDeliveryDate", align: "center" },
    { columnName: "amount", align: "right" },
    { columnName: "documentType", align: "center" },
];

const rowsSkeleton = [];

for (let i = 0; i < 10; i++) {
    rowsSkeleton.push({
        id: i,
        contact: <Skeleton variant="text" animation="wave" />,
        smb: <Skeleton variant="text" animation="wave" />,
        service: <Skeleton variant="text" animation="wave" />,
        project: <Skeleton variant="text" animation="wave" />,
        period: <Skeleton variant="text" animation="wave" />,
        initial: <Skeleton variant="text" animation="wave" />,
        estimatedDeliveryDate: <Skeleton variant="text" animation="wave" />,
        amount: <Skeleton variant="text" animation="wave" />,
        documentType: (
            <Skeleton variant="circular" width={30} height={30} animation="wave" sx={{ ml: "auto", mr: "auto" }} />
        ),
    });
}

const InPendingsSkeleton = (props) => {
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    const tableMessages = {
        noData: t("miscellaneous.noData"),
    };
    const pagingMessages = {
        info: ({ from, to, count }) => `${from}${from < to ? `-${to}` : ""} ${t("miscellaneous.pagingOf")} ${count}`,
    };

    const searchMessages = {
        searchPlaceholder: t("miscellaneous.search"),
    };

    /** Component states */
    const [columnDefs, setColumnsDefs] = useState([]);

    useEffect(() => {
        (async () => {
            const columnsTemplate = [
                { name: "contact", title: t("invoicing.invoicingContact") },
                { name: "smb", title: t("miscellaneous.smb") },
                { name: "service", title: t("miscellaneous.service") },
                { name: "project", title: t("invoicing.project") },
                { name: "period", title: t("invoicing.period") },
                { name: "initial", title: t("invoicing.creationDate") },
                {
                    name: "estimatedDeliveryDate",
                    title: t("invoicing.scheduledShipDate"),
                },
                { name: "amount", title: t("invoicing.amount") },
                { name: "documentType", title: " " },
            ];
            setColumnsDefs(columnsTemplate);
            props.setPendingsData(rowsSkeleton);
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <StyledContainer component="main" sx={{ flexGrow: 1, width: "100%" }}>
            <GridUI container spacing={2} className={classes.spacing} alignItems="center">
                <GridUI item xs={12} md={4} xl={2}>
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width="100%"
                        height={40}
                        sx={{ borderRadius: 8 }}
                    />
                </GridUI>
                <GridUI item xs={12} md={4} xl={2}>
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width="100%"
                        height={40}
                        sx={{ borderRadius: 8 }}
                    />
                </GridUI>
                <GridUI item xs={12} md={4} xl={2}>
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width="100%"
                        height={40}
                        sx={{ borderRadius: 8 }}
                    />
                </GridUI>
                <GridUI item xs={12} md={4} xl={1}>
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width="100%"
                        height={40}
                        sx={{ borderRadius: 8 }}
                    />
                </GridUI>
                <GridUI item xs={12} md={4} xl={1}>
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width="100%"
                        height={40}
                        sx={{ borderRadius: 8 }}
                    />
                </GridUI>
                <GridUI item xs={12} md={4} xl={1}>
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width="100%"
                        height={40}
                        sx={{ borderRadius: 8 }}
                    />
                </GridUI>
                <GridUI item xs={12} md={4} xl={3}>
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width="100%"
                        height={40}
                        sx={{ borderRadius: 8 }}
                    />
                </GridUI>
                <Hidden only={["md", "lg", "xl"]}>
                    <GridUI item>
                        <Skeleton
                            variant="rectangular"
                            animation="wave"
                            width={112}
                            height={40}
                            sx={{ borderRadius: 8 }}
                        />
                    </GridUI>
                </Hidden>
            </GridUI>
            <GridUI container>
                <GridUI item xs={12} sm={6} md={4} lg={2} sx={{ mb: { sm: -5 }, zIndex: 1 }}>
                    <Skeleton variant="rectangular" animation="wave" width={170} height={30} sx={{ borderRadius: 8 }} />
                </GridUI>
                <GridUI item xs={12} sm={6} md={4} lg={2} sx={{ mb: { sm: -5 }, zIndex: 1 }}>
                    <Skeleton variant="rectangular" animation="wave" width={170} height={30} sx={{ borderRadius: 8 }} />
                </GridUI>
                <GridUI item xs={12}>
                    <Grid rows={props.pendingsData} columns={columnDefs}>
                        <SelectionState />
                        <SearchState />
                        <IntegratedFiltering />
                        <PagingState defaultCurrentPage={0} pageSize={10} />
                        <IntegratedPaging />
                        <IntegratedSelection />
                        <Table
                            tableComponent={TableComponent}
                            columnExtensions={tableColumnExtensions}
                            cellComponent={TableCellComponent}
                            messages={tableMessages}
                        />
                        <TableHeaderRow cellComponent={tableHeaderCell} contentComponent={tableContentComponent} />
                        <Toolbar rootComponent={toolRootComponent} />
                        <SearchPanel messages={searchMessages} inputComponent={inputComponent} />
                        <PagingPanel containerComponent={PagingPanelContainer} messages={pagingMessages} />
                    </Grid>
                </GridUI>
            </GridUI>
        </StyledContainer>
    );
};

export default InPendingsSkeleton;
