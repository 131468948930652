/** React */
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";

/** MUI icons */
import CheckIcon from "@mui/icons-material/Check";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

/** MUI imports */
import { styled } from "@mui/material/styles";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import {
    Box,
    FormControl,
    FormControlLabel,
    Grid,
    InputAdornment,
    InputLabel,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Select,
    Switch,
    TextField,
    Typography,
} from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

/** Component imports */
import { Additionals } from "./";

/** Component styles */
const PREFIX = "Recurring";

const classes = {
    switch: `${PREFIX}-switch`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    backgroundColor: "#F8F9F8",
    marginTop: 16,
    padding: 30,
    borderRadius: 30,

    [`& .${classes.switch}`]: {
        "& .MuiSwitch-switchBase .MuiSwitch-thumb": {
            color: "#FFF",
        },
        "& .MuiSwitch-switchBase + .MuiSwitch-track": {
            backgroundColor: "#031851",
        },
        "& .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb": {
            color: "#FF5968",
        },
        "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
            backgroundColor: "#FFA8B1",
        },
    },
}));

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)({
    backgroundColor: "#F8F9F8",
    boxShadow: "none",
    "&:before": {
        display: "none",
    },
});

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: "#031851" }} />} {...props} />
))({
    "& .MuiAccordionSummary-content": {
        margin: "12px 0",
    },
});

const AccordionDetails = styled(MuiAccordionDetails)({
    padding: "0px 0px 20px 0px",
    "& .MuiCard-root": {
        boxShadow: "none",
    },
});

export const Recurring = (props) => {
    // Properties
    const { clientMarket, valuesAccRecu, setValuesAccRecu, setShow, setTexto, setAutoAlertType, isError, isErrorFocus, setIsErrorFocus } =
        props;

    /** Internationalization i18n */
    const [t] = useTranslation("global");

    /** React redux */
    const todos = useSelector((state) => state.todos);

    /** Global variables */
    const sumTotal =
        parseFloat(valuesAccRecu.tothAR) *
        (valuesAccRecu.recuServFreq === 1
            ? 1
            : valuesAccRecu.finishDateAR
                ? isNaN(moment(valuesAccRecu.finishDateAR).diff(moment(valuesAccRecu.startDateAR)))
                    ? 0
                    : Math.ceil(
                        moment(valuesAccRecu.finishDateAR).diff(moment(valuesAccRecu.startDateAR), "months", true)
                    )
                : 12) +
        parseFloat(valuesAccRecu.tothAP) *
        (valuesAccRecu.recuServFreq === 1
            ? 1
            : valuesAccRecu.finishDateAP
                ? isNaN(moment(valuesAccRecu.finishDateAP).diff(moment(valuesAccRecu.startDateAP)))
                    ? 0
                    : Math.ceil(
                        moment(valuesAccRecu.finishDateAP).diff(moment(valuesAccRecu.startDateAP), "months", true)
                    )
                : 12) +
        parseFloat(valuesAccRecu.tothClass) *
        (valuesAccRecu.recuServFreq === 1
            ? 1
            : valuesAccRecu.finishDateClass
                ? isNaN(moment(valuesAccRecu.finishDateClass).diff(moment(valuesAccRecu.startDateClass)))
                    ? 0
                    : Math.ceil(
                        moment(valuesAccRecu.finishDateClass).diff(
                            moment(valuesAccRecu.startDateClass),
                            "months",
                            true
                        )
                    )
                : 12) +
        parseFloat(valuesAccRecu.tothMulti) *
        (valuesAccRecu.recuServFreq === 1
            ? 1
            : valuesAccRecu.finishDateMulti
                ? isNaN(moment(valuesAccRecu.finishDateMulti).diff(moment(valuesAccRecu.startDateMulti)))
                    ? 0
                    : Math.ceil(
                        moment(valuesAccRecu.finishDateMulti).diff(
                            moment(valuesAccRecu.startDateMulti),
                            "months",
                            true
                        )
                    )
                : 12) +
        parseFloat(valuesAccRecu.tothPer) *
        (valuesAccRecu.recuServFreq === 1
            ? 1
            : valuesAccRecu.finishDatePer
                ? isNaN(moment(valuesAccRecu.finishDatePer).diff(moment(valuesAccRecu.startDatePer)))
                    ? 0
                    : Math.ceil(
                        moment(valuesAccRecu.finishDatePer).diff(moment(valuesAccRecu.startDatePer), "months", true)
                    )
                : 12) +
        (clientMarket === "Estados Unidos"
            ? parseFloat(valuesAccRecu.tothSales) *
            (valuesAccRecu.recuServFreq === 1
                ? 1
                : valuesAccRecu.finishDateSales
                    ? isNaN(moment(valuesAccRecu.finishDateSales).diff(moment(valuesAccRecu.startDateSales)))
                        ? 0
                        : Math.ceil(
                            moment(valuesAccRecu.finishDateSales).diff(
                                moment(valuesAccRecu.startDateSales),
                                "months",
                                true
                            )
                        )
                    : 12) +
            parseFloat(valuesAccRecu.tothChecks) *
            (valuesAccRecu.recuServFreq === 1
                ? 1
                : valuesAccRecu.finishDateChecks
                    ? isNaN(moment(valuesAccRecu.finishDateChecks).diff(moment(valuesAccRecu.startDateChecks)))
                        ? 0
                        : Math.ceil(
                            moment(valuesAccRecu.finishDateChecks).diff(
                                moment(valuesAccRecu.startDateChecks),
                                "months",
                                true
                            )
                        )
                    : 12)
            : clientMarket === "Venezuela"
                ? parseFloat(valuesAccRecu.tothOrdConTax) *
                (valuesAccRecu.recuServFreq === 1
                    ? 1
                    : valuesAccRecu.finishDateOrd
                        ? isNaN(moment(valuesAccRecu.finishDateOrd).diff(moment(valuesAccRecu.startDateOrd)))
                            ? 0
                            : Math.ceil(
                                moment(valuesAccRecu.finishDateOrd).diff(
                                    moment(valuesAccRecu.startDateOrd),
                                    "months",
                                    true
                                )
                            )
                        : 12) +
                parseFloat(valuesAccRecu.tothSpeConTax) *
                (valuesAccRecu.recuServFreq === 1
                    ? 1
                    : valuesAccRecu.finishDateEsp
                        ? isNaN(moment(valuesAccRecu.finishDateEsp).diff(moment(valuesAccRecu.startDateEsp)))
                            ? 0
                            : Math.ceil(
                                moment(valuesAccRecu.finishDateEsp).diff(
                                    moment(valuesAccRecu.startDateEsp),
                                    "months",
                                    true
                                )
                            )
                        : 12) +
                parseFloat(valuesAccRecu.tothMuni) *
                (valuesAccRecu.recuServFreq === 1
                    ? 1
                    : valuesAccRecu.finishDateMuni
                        ? isNaN(moment(valuesAccRecu.finishDateMuni).diff(moment(valuesAccRecu.startDateMuni)))
                            ? 0
                            : Math.ceil(
                                moment(valuesAccRecu.finishDateMuni).diff(
                                    moment(valuesAccRecu.startDateMuni),
                                    "months",
                                    true
                                )
                            )
                        : 12)
                : 0) +
        parseFloat(valuesAccRecu.tothOthers) *
        (valuesAccRecu.recuServFreq === 1
            ? 1
            : valuesAccRecu.finishDate
                ? isNaN(moment(valuesAccRecu.finishDateOthers).diff(moment(valuesAccRecu.startDateOthers)))
                    ? 0
                    : Math.ceil(
                        moment(valuesAccRecu.finishDateOthers).diff(
                            moment(valuesAccRecu.startDateOthers),
                            "months",
                            true
                        )
                    )
                : 12);

    const getTotalHours = () => {
        let sumTotal =
            parseFloat(valuesAccRecu.tothAR) +
            parseFloat(valuesAccRecu.tothAP) +
            parseFloat(valuesAccRecu.tothClass) +
            parseFloat(valuesAccRecu.tothMulti) +
            parseFloat(valuesAccRecu.tothPer) +
            (clientMarket === "Estados Unidos"
                ? parseFloat(valuesAccRecu.tothSales) +
                parseFloat(valuesAccRecu.tothChecks)
                : clientMarket === "Venezuela"
                    ? parseFloat(valuesAccRecu.tothOrdConTax) +
                    parseFloat(valuesAccRecu.tothSpeConTax) +
                    parseFloat(valuesAccRecu.tothMuni) : 0) +
            parseFloat(valuesAccRecu.tothOthers)
        return sumTotal;
    };

    const checks =
        valuesAccRecu.checkedAR ||
        valuesAccRecu.checkedAP ||
        valuesAccRecu.checkedClass ||
        valuesAccRecu.checkedMulti ||
        valuesAccRecu.checkedPer ||
        valuesAccRecu.checkedChecks ||
        valuesAccRecu.checkedSales ||
        valuesAccRecu.checkedOrd ||
        valuesAccRecu.checkedEsp ||
        valuesAccRecu.checkedMuni ||
        valuesAccRecu.checkedOthers;

    /** Component functions */
    useEffect(() => {
        setValuesAccRecu({
            ...valuesAccRecu,
            recuChecks: checks,
            recuHours: sumTotal,
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clientMarket, sumTotal, checks]);

    const handleChangeSwitchHourly = (event) => {
        setValuesAccRecu({
            ...valuesAccRecu,
            recuBaseRate: event.target.checked
                ? 0
                : valuesAccRecu.payAnnually
                    ? valuesAccRecu.recuOriginalFee * 12
                    : valuesAccRecu.recuOriginalFee,
            recuDiscount: !event.target.checked && valuesAccRecu.payAnnually ? 15 : 0,
            recuProjectTotal: event.target.checked
                ? 0
                : valuesAccRecu.payAnnually
                    ? valuesAccRecu.recuOriginalFee * 12 * 0.85
                    : valuesAccRecu.recuOriginalFee,
            recuAdditionalsDiscount: !event.target.checked && valuesAccRecu.payAnnually ? 15 : 0,
            recuAdditionalsOnly: event.target.checked,
            checkedAcc: event.target.checked,
            tothAcc: 0,
            startDateAcc: event.target.checked ? valuesAccRecu.initDate : null,
            finishDateAcc: null,
            isError: false
        });
        setIsErrorFocus(false)
    };

    const handleChangeSwitchPay = (event) => {
        setValuesAccRecu({
            ...valuesAccRecu,
            recuBaseRate: valuesAccRecu.recuAdditionalsOnly
                ? valuesAccRecu.recuBaseRate
                : event.target.checked
                    ? valuesAccRecu.recuOriginalFee * 12
                    : valuesAccRecu.recuOriginalFee,
            recuDiscount: valuesAccRecu.recuAdditionalsOnly ? 0 : event.target.checked ? 15 : 0,
            recuProjectTotal: valuesAccRecu.recuAdditionalsOnly
                ? valuesAccRecu.recuBaseRate
                : event.target.checked
                    ? valuesAccRecu.recuOriginalFee * 12 * 0.85
                    : valuesAccRecu.recuOriginalFee,
            recuAdditionalsDiscount: valuesAccRecu.recuAdditionalsOnly ? 0 : event.target.checked ? 15 : 0,
            payAnnually: event.target.checked,
            recuServFreq: event.target.checked ? valuesAccRecu.recuServFreq : 1,
            isError: false
        });
        setIsErrorFocus(false)
    };

    const handleChangeSinceDate = (date) => {
        if (date === null) {
            setValuesAccRecu({
                ...valuesAccRecu,
                initDate: date,
                recuAdditionalsOnly: false,
                checkedAcc: false,
                tothAcc: 0,
                startDateAcc: null,
                finishDateAcc: null,
            });
        } else {
            setValuesAccRecu({
                ...valuesAccRecu,
                initDate: moment(date).startOf("month"),
                isError: false
            });
            setIsErrorFocus(false)
        }
    };

    const handleChangeFreq = (event) => {
        setValuesAccRecu({
            ...valuesAccRecu,
            recuServFreq: event.target.value,
        });
    };

    const handleChangeDiscount = (event) => {
        let discountAux =
            event.target.value >= 0 && event.target.value <= 100 ? event.target.value : valuesAccRecu.recuDiscount;

        setValuesAccRecu({
            ...valuesAccRecu,
            recuDiscount: discountAux,
            recuAdditionalsDiscount: discountAux,
            recuProjectTotal: valuesAccRecu.recuAdditionalsOnly
                ? (valuesAccRecu.tothAcc ?? 0) * valuesAccRecu.recuHourRate * (1 - discountAux / 100)
                : valuesAccRecu.recuBaseRate * (1 - discountAux / 100),
        });
    };

    return (
        <StyledContainer>
            <LocalizationProvider
                dateAdapter={AdapterMoment}
                adapterLocale={t("language.locale")}
                localeText={{ clearButtonLabel: t("miscellaneous.clear") }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography
                            variant="h1"
                            color="primary"
                            sx={{
                                display: "inline-block",
                                fontWeight: 700,
                                pr: 6,
                            }}
                        >
                            {t(`invoicing.recurring`)}
                        </Typography>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={valuesAccRecu.recuAdditionalsOnly}
                                    className={classes.switch}
                                    color="secondary"
                                    disabled={!valuesAccRecu.initDate}
                                    inputProps={{ "aria-label": "controlled" }}
                                    name="payAnnuallyRecu"
                                    onChange={handleChangeSwitchHourly}
                                />
                            }
                            label={
                                <Typography variant="body2" align="center" sx={{ lineHeight: 0 }}>
                                    {t("proposals.hourly")}
                                </Typography>
                            }
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={valuesAccRecu.payAnnually}
                                    className={classes.switch}
                                    color="secondary"
                                    inputProps={{ "aria-label": "controlled" }}
                                    name="payAnnuallyRecu"
                                    onChange={handleChangeSwitchPay}
                                />
                            }
                            label={
                                <Typography variant="body2" align="center" sx={{ lineHeight: 0 }}>
                                    {t("proposals.payAnnually")}
                                </Typography>
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Accordion
                            TransitionProps={{ unmountOnExit: true }}
                            sx={{
                                borderBottom: "3px solid #ff5968",
                            }}
                        >
                            <AccordionSummary aria-controls="panel-content" id="panel-header">
                                <Typography
                                    variant="h2"
                                    align="center"
                                    sx={{
                                        fontWeight: 700,
                                    }}
                                >
                                    {t("invoicing.description")}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <List>
                                    <ListItem disableGutters alignItems="center">
                                        <ListItemIcon sx={{ minWidth: 35 }}>
                                            <CheckIcon fontSize="small" sx={{ color: "#031851" }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={
                                                <Typography variant="body1">
                                                    <span style={{ fontWeight: 600 }}>{t("proposals.dediAcc")} </span>-{" "}
                                                    {t("proposals.aPubAcc")}
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                    <ListItem disableGutters alignItems="center">
                                        <ListItemIcon sx={{ minWidth: 35 }}>
                                            <CheckIcon fontSize="small" sx={{ color: "#031851" }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={
                                                <Typography variant="body1">
                                                    <span style={{ fontWeight: 600 }}>{t("proposals.bankRec")} </span>-{" "}
                                                    {t("proposals.bankRecDes")}
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                    <ListItem disableGutters alignItems="center">
                                        <ListItemIcon sx={{ minWidth: 35 }}>
                                            <CheckIcon fontSize="small" sx={{ color: "#031851" }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={
                                                <Typography variant="body1">
                                                    <span style={{ fontWeight: 600 }}>{t("proposals.cashAccru")} </span>
                                                    - {t("proposals.tranReCash2")}
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                    <ListItem disableGutters alignItems="center">
                                        <ListItemIcon sx={{ minWidth: 35 }}>
                                            <CheckIcon fontSize="small" sx={{ color: "#031851" }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={
                                                <Typography variant="body1">
                                                    <span style={{ fontWeight: 600 }}>
                                                        {t("proposals.finannState")}{" "}
                                                    </span>
                                                    - {t("proposals.balanceGen")}
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                    <ListItem disableGutters alignItems="center">
                                        <ListItemIcon sx={{ minWidth: 35 }}>
                                            <CheckIcon fontSize="small" sx={{ color: "#031851" }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={
                                                <Typography variant="body1">
                                                    <span style={{ fontWeight: 600 }}>
                                                        {t("proposals.virtualMeet")}{" "}
                                                    </span>
                                                    - {t("proposals.virtualMeetDet")}
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                    <ListItem disableGutters alignItems="center">
                                        <ListItemIcon sx={{ minWidth: 35 }}>
                                            <CheckIcon fontSize="small" sx={{ color: "#031851" }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={
                                                <Typography variant="body1">
                                                    <span style={{ fontWeight: 600 }}>
                                                        {t("proposals.writtenSup")}{" "}
                                                    </span>
                                                    - {t("proposals.UnlimSup")}
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                </List>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                    <Grid item container xs={12} spacing={2} sx={{ mt: 0.5 }}>
                        <Grid item xs={12} sm={6} lg={3}>
                            <DatePicker
                                componentsProps={{
                                    actionBar: {
                                        actions: ["clear"],
                                    },
                                }}
                                value={valuesAccRecu.initDate}
                                onChange={(newValue) => handleChangeSinceDate(newValue)}
                                inputFormat={todos.dateFormat}
                                renderInput={(params) => (
                                    <TextField
                                        fullWidth
                                        required
                                        {...params}
                                        error={isError && !valuesAccRecu.initDate}
                                        onClick={(e) => { setIsErrorFocus(false) }}
                                    />
                                )}
                                // inputProps={{
                                //     readOnly: true,
                                // }}
                                inputProps={{
                                    onKeyDown: (event) => {
                                        event.preventDefault();
                                    },
                                }}
                                label={t("reports.initDate")}
                                views={["year", "month"]}
                                onClick={(e) => { setIsErrorFocus(false) }}
                                inputRef={(input) => { (input && isErrorFocus && isError && !valuesAccRecu.initDate) && input.focus() }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3}>
                            <FormControl fullWidth disabled={!valuesAccRecu.payAnnually}>
                                <InputLabel id="serv-freq-simple-select-label">{t("services.serviceFreq")}</InputLabel>
                                <Select
                                    name="servFreq"
                                    labelId="serv-freq-simple-select-label"
                                    IconComponent={KeyboardArrowDownIcon}
                                    value={valuesAccRecu.recuServFreq}
                                    label={t("services.serviceFreq")}
                                    onChange={handleChangeFreq}
                                    MenuProps={{
                                        PaperProps: {
                                            sx: {
                                                mt: 0.7,
                                                borderRadius: 2,
                                                boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                            },
                                        },
                                    }}
                                >
                                    <MenuItem value={1}>{t("miscellaneous.monthly")}</MenuItem>
                                    {valuesAccRecu.payAnnually && (
                                        <MenuItem value={2}>{t("miscellaneous.annual")}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3}>
                            <TextField
                                fullWidth
                                name="projectDiscount"
                                label={t("miscellaneous.discount")}
                                value={valuesAccRecu.recuDiscount}
                                onChange={handleChangeDiscount}
                                onBlur={() =>
                                    setValuesAccRecu({
                                        ...valuesAccRecu,
                                        recuDiscount: parseFloat(
                                            valuesAccRecu.recuDiscount === "" ? 0 : valuesAccRecu.recuDiscount
                                        ).toFixed(2),
                                        recuAdditionalsDiscount: parseFloat(
                                            valuesAccRecu.recuDiscount === ""
                                                ? 0
                                                : valuesAccRecu.recuAdditionalsDiscount
                                        ).toFixed(2),
                                    })
                                }
                                variant="outlined"
                                type="number"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                    inputProps: {
                                        min: 0,
                                        max: 100,
                                    },
                                }}
                                sx={{
                                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                        display: "none",
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3}>
                            <TextField
                                fullWidth
                                name="additionalsDiscount"
                                label={t("proposals.additionalsDiscount")}
                                value={valuesAccRecu.recuAdditionalsDiscount}
                                onChange={handleChangeDiscount}
                                onBlur={() =>
                                    setValuesAccRecu({
                                        ...valuesAccRecu,
                                        recuDiscount: parseFloat(
                                            valuesAccRecu.recuAdditionalsDiscount === ""
                                                ? 0
                                                : valuesAccRecu.recuDiscount
                                        ).toFixed(2),
                                        recuAdditionalsDiscount: parseFloat(
                                            valuesAccRecu.recuAdditionalsDiscount === ""
                                                ? 0
                                                : valuesAccRecu.recuAdditionalsDiscount
                                        ).toFixed(2),
                                    })
                                }
                                variant="outlined"
                                type="number"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                    inputProps: {
                                        min: 0,
                                        max: 100,
                                    },
                                }}
                                sx={{
                                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                        display: "none",
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Additionals
                                addHourFee={valuesAccRecu.recuHourRate}
                                values={valuesAccRecu}
                                set={setValuesAccRecu}
                                setTexto={setTexto}
                                setAutoAlertType={setAutoAlertType}
                                setShow={setShow}
                                clientMarket={clientMarket}
                                sumTotal={sumTotal}
                                isError={isError}
                                isErrorFocus={isErrorFocus}
                                setIsErrorFocus={setIsErrorFocus}
                                sumTotalAddHours={getTotalHours()}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </LocalizationProvider>
        </StyledContainer>
    );
};
