/** React imports */
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

/** Images */

/** MUI icons */
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

/** MUI imports */
import { Box, Typography, Grid, Divider } from "@mui/material";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

import { Footer } from "../Core";

/** Global variables */
const PREFIX = "ServiceLevelsTaxExpert";

const classes = {
    body2: `${PREFIX}-body2`,
    serviceName: `${PREFIX}-serviceName`,
    serviceBody: `${PREFIX}-serviceBody`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.serviceName}`]: {
        fontSize: 22,
    },

    [`& .${classes.body2}`]: {
        backgroundColor: "white",
        marginTop: 15,
        minHeight: 850,
        [theme.breakpoints.down("md")]: {
            padding: 30,
        },
        [theme.breakpoints.up("md")]: {
            padding: 60,
        },
    },

    [`& .${classes.serviceBody}`]: {
        backgroundColor: "#F8F9F8",
        marginTop: 30,
        padding: 30,
        borderRadius: 30,
    },
}));

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)({
    boxShadow: "none",
    "&:before": {
        display: "none",
    },
    backgroundColor: "#F8F9F8",
});

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: "#031851" }} />} {...props} />
))({
    padding: 0,
    "& .MuiAccordionSummary-content": {
        margin: "12px 0",
    },
});

const AccordionDetails = styled(MuiAccordionDetails)({
    padding: "0px 0px 16px 0px",
    "& .MuiCard-root": {
        boxShadow: "none",
    },
});

export const ServiceLevelsTaxExpert = (props) => {
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    const { bookInfo } = props;

    const [expandedSub, setExpandedSub] = useState(false);
    const [expandedSubAdd, setExpandedSubAdd] = useState(false);

    const handleChangeAccSub = (panel) => (event, isExpanded) => {
        setExpandedSub(isExpanded ? panel : false);
    };

    const handleChangeAccSubAdd = (panel) => (event, isExpanded) => {
        setExpandedSubAdd(isExpanded ? panel : false);
    };

    return (
        <StyledContainer>
            <Box className={classes.body2}>
                <Typography variant="h5" className={classes.serviceName}>
                    {t("invoicing.taxExpert")}
                </Typography>
                <Typography variant="h5" sx={{ pt: 2 }}>
                    {t("proposals.scopeWorkSMBB")}
                </Typography>
                <Box className={classes.serviceBody}>
                    {(bookInfo.recurring.isActive || bookInfo.cleanUp.isActive || bookInfo.catchUp.isActive) && (
                        <Accordion
                            defaultExpanded
                            TransitionProps={{ unmountOnExit: true }}
                            sx={{
                                borderBottom: "3px solid #ff5968",
                            }}
                        >
                            <AccordionSummary aria-controls="panelAuxbh-content" id="panelAuxbh-header">
                                <Typography variant="h4">SMBs Tax Expert (Recurring & Catch-ups)</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Divider sx={{ mb: 2 }} />
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={12} md={4}>
                                        <Typography variant="body1">Dedicated Tax expert</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <Typography variant="body1">A Certified Public Accountant with Tax expertise.</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Typography variant="body1">Corporate income tax filling</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <Typography variant="body1">Federal income tax filing.</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Typography variant="body1">Written support</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <Typography variant="body1">Unlimited customer support (8.00 am to 5.00 pm EST).</Typography>
                                    </Grid>
                                </Grid>
                                {/* {((bookInfo.recurring.additionals.length !== 0 && !bookInfo.recurring.onlyAdd) ||
                                    (bookInfo.catchUp.additionals.length !== 0 && !bookInfo.catchUp.onlyAdd) ||
                                    (bookInfo.cleanUp.additionals.length !== 0 && !bookInfo.cleanUp.onlyAdd)) && ( */}
                                    <Grid container spacing={2} alignItems="center" sx={{ p: "15px 64px 0px 64px" }}>
                                        <Grid item xs={12}>
                                            <Typography variant="h4">{t("proposals.addonsB")}</Typography>
                                            <Divider
                                                sx={{
                                                    mt: 1,
                                                    backgroundColor: "#CCCCCC",
                                                }}
                                            />
                                        </Grid>
                                        {bookInfo.genAdditionals.exe && (
                                            <>
                                                <Grid item xs={12} md={4}>
                                                    <Typography variant="body1">
                                                        {t("services.executionTaxPlanB")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} md={8}>
                                                    <Typography variant="body1">
                                                        {t("services.executionTaxPlanDetB")}
                                                    </Typography>
                                                </Grid>
                                            </>
                                        )}
                                        <>
                                            <Grid item xs={12} md={4}>
                                                <Typography variant="body1">International</Typography>
                                            </Grid>
                                            <Grid item xs={12} md={8}>
                                                <Typography variant="body1">Foreign entities, members and stockholders.</Typography>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <Typography variant="body1">Intercompany</Typography>
                                            </Grid>
                                            <Grid item xs={12} md={8}>
                                                <Typography variant="body1">Transactions with related companies.</Typography>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <Typography variant="body1">Tax planning</Typography>
                                            </Grid>
                                            <Grid item xs={12} md={8}>
                                                <Typography variant="body1">Strategies to remain tax efficient.</Typography>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <Typography variant="body1">Other</Typography>
                                            </Grid>
                                            <Grid item xs={12} md={8}>
                                                <Typography variant="body1">Virtual meetings and other tax services.</Typography>
                                            </Grid>
                                        </>
                                    </Grid>
                                {/* // )} */}
                            </AccordionDetails>
                        </Accordion>
                    )}
                </Box>
            </Box>
            <Footer />
        </StyledContainer>
    );
};
