// React
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { NumericFormat } from "react-number-format";

// MUI
import {
    Box,
    TextField,
    Grid,
    Divider,
    Card,
    CardHeader,
    CardContent,
    Button,
    FormControl,
    FormControlLabel,
    InputLabel,
    OutlinedInput,
    InputAdornment,
    Switch,
    Typography,
    Checkbox,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

/** MUI icons */

const PREFIX = "CustomerSupp";

const classes = {
    btnRoot: `${PREFIX}-btnRoot`,
    buttonContainer: `${PREFIX}-buttonContainer`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.btnRoot}`]: {
        border: "1px solid #ff5968",
        borderRadius: 40,
        "&:hover": {
            backgroundColor: "#2f3190",
            border: "1px solid #2f3190",
        },
    },
    [`& .${classes.buttonContainer}`]: {
        display: "flex",
        justifyContent: "flex-end",
        padding: 15,
    },
}));

const NumberFormatCustom = (props) => {
    const todos = useSelector((state) => state.todos);

    const { inputRef, value, onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            value={value}
            getInputRef={inputRef}
            onValueChange={({ value: v }) => onChange({ target: { value: v } })}
            thousandSeparator={todos.amountFormat === "de-DE" ? "." : ","}
            decimalSeparator={todos.amountFormat === "de-DE" ? "," : "."}
            fixedDecimalScale={true}
            decimalScale={2}
            valueIsNumericString
        />
    );
};

const latam = [
    "Argentina",
    "Bolivia",
    "Brasil",
    "Chile",
    "Colombia",
    "Costa Rica",
    "Cuba",
    "Ecuador",
    "El Salvador",
    "Guatemala",
    "Haití",
    "Honduras",
    "México",
    "Nicaragua",
    "Panamá",
    "Paraguay",
    "Perú",
    "República Dominicana",
    "Uruguay",
];

const defaultState = {
    ogFee: 0,
    fee: 0,
    discount: 0,
    totalFee: 0,
    hours: 1,
    sinceDate: null,
    finishDate: null,
    endProject: false,
    invoiceGroup: false,
};

export const CustomerSupp = (props) => {
    // Properties
    const { setTexto, setalertType, setShow, companyID, setBackDrop, data, reloadProjects, serviceID, clientMarket } =
        props;

    /** Internationalization i18n */
    const [t] = useTranslation("global");

    /** React redux */
    const todos = useSelector((state) => state.todos);

    /** Component states */
    const [support, setSupport] = useState(defaultState);
    const [isError, setIsError] = useState(false);

    /** Component functions */
    useEffect(() => {
        setSupport({
            hours: data.consulting_hours ? data.consulting_hours : 1,
            ogFee: clientMarket === "Venezuela" ? 25 : latam.includes(clientMarket) ? 30 : 35,
            fee:
                (clientMarket === "Venezuela" ? 25 : latam.includes(clientMarket) ? 30 : 35) *
                (data.consulting_hours ? data.consulting_hours : 1),
            discount: data.discount ?? 0,
            totalFee:
                (clientMarket === "Venezuela" ? 25 : latam.includes(clientMarket) ? 30 : 35) *
                (data.consulting_hours ? data.consulting_hours : 1) *
                (1 - (data.discount ?? 0) / 100),
            sinceDate: data.since_date
                ? moment(data.since_date).utc()
                : null,
            finishDate: data.end_date ? moment(data.end_date).utc() : null,
            endProject: data.finished_service ?? false,
            invoiceGroup: data.grouped_support ?? false,
        });
    }, [clientMarket, data]);

    const handleChangeEndDate = (date) => {
        let endDate = moment(date).endOf("month").startOf("day");

        if (!date) {
            setSupport({
                ...support,
                finishDate: date,
            });
        } else if (support.sinceDate) {
            if (endDate.isBefore(moment(support.sinceDate))) {
                setSupport({
                    ...support,
                    finishDate: null,
                });
                setTexto(t("reports.endDateMess"));
                setalertType("warning");
                setShow(true);
            } else {
                setSupport({
                    ...support,
                    finishDate: endDate,
                });
            }
        } else {
            setTexto(t("reports.selectStartDate"));
            setalertType("warning");
            setShow(true);
        }
    };

    const handleChange = (event) => {
        setSupport({
            ...support,
            [event.target.name]: event.target.value,
            fee: event.target.value * support.ogFee,
            totalFee: event.target.value * support.ogFee * (1 - support.discount / 100),
        });
    };

    const handleChangeDiscount = (event) => {
        let discountAux = event.target.value >= 0 && event.target.value <= 100 ? event.target.value : support.discount;

        setSupport({
            ...support,
            discount: discountAux,
            totalFee: support.fee * (1 - discountAux / 100),
        });
    };

    const handleChangeCheck = async (event) => {
        if (event.target.checked === true) {
            await fetch(`/getOriginalPracticeDetails?organisation_id=${companyID}&project=customer`, {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
                .then((res) => res.json())
                .then((response) => {
                    setSupport({
                        ...support,
                        hours: response.grouped_support_hours ? response.grouped_support_hours : 1,
                        fee:
                            (clientMarket === "Venezuela" ? 25 : latam.includes(clientMarket) ? 30 : 35) *
                            (response.grouped_support_hours ? response.grouped_support_hours : 1),
                        discount: response.grouped_support_discount ? response.grouped_support_discount : 0,
                        totalFee:
                            (clientMarket === "Venezuela" ? 25 : latam.includes(clientMarket) ? 30 : 35) *
                            (response.grouped_support_hours ? response.grouped_support_hours : 1) *
                            (1 - (response.grouped_support_discount ? response.grouped_support_discount : 0) / 100),
                        sinceDate: response.initial_date_customer ? moment(response.initial_date_customer).utc() : null,
                        finishDate:
                            response.end_date_customer && !data.end_date
                                ? moment(response.end_date_customer).utc()
                                : null,
                        invoiceGroup: !support.invoiceGroup,
                    });
                });
        } else {
            setSupport({
                ...support,
                hours: data.consulting_hours ?? 1,
                fee:
                    (clientMarket === "Venezuela" ? 25 : latam.includes(clientMarket) ? 30 : 35) *
                    (data.consulting_hours ? data.consulting_hours : 1),
                discount: data.discount ?? 0,
                totalFee:
                    (clientMarket === "Venezuela" ? 25 : latam.includes(clientMarket) ? 30 : 35) *
                    (data.consulting_hours ? data.consulting_hours : 1) *
                    (1 - (data.discount ? data.discount : 0) / 100),
                sinceDate: data.since_date ? moment(data.since_date).utc() : null,
                finishDate: data.end_date ? moment(data.end_date).utc() : null,
                invoiceGroup: !support.invoiceGroup,
            });
        }
    };

    const updateProject = async () => {
        setBackDrop(true);

        if (support.sinceDate) {
            if (support.endProject && !support.finishDate) {
                setTexto(t("miscellaneous.requiredData"));
                setalertType("warning");
                setShow(true);
                setIsError(true);
                setBackDrop(false);
                return;
            }

            let params = {
                company_id: companyID,
                service_id: serviceID,
                project_id: data.project_id,
                project_company_id: data.project_company_id,
                since_date: moment(support.sinceDate).startOf("day").format(),
                end_date: support.finishDate ? moment(support.finishDate).startOf("day").format() : null,
                discount: support.discount ? parseFloat(support.discount) : 0,
                consulting_hours: parseFloat(support.hours),
                fee: support.totalFee,
                finished_service: support.endProject,
                grouped_support: support.invoiceGroup,
            };

            await fetch(`/addServicesAndProjects`, {
                method: "POST",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
                body: JSON.stringify(params),
            })
                .then((response) => {
                    if (response.ok) {
                        setTexto(t("miscellaneous.successfulUpdate"));
                        setalertType("success");
                        setShow(true);
                        reloadProjects(true, support.endProject);
                    } else {
                        setTexto(t("miscellaneous.updatingError"));
                        setalertType("error");
                        setShow(true);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            setTexto(t("miscellaneous.requiredData"));
            setalertType("warning");
            setShow(true);
            setIsError(true);
        }

        setBackDrop(false);
    };

    const handleChangeSinceDate = (date) => {
        setSupport({
            ...support,
            sinceDate: date,
        });
    };

    const handleChangeSwitch = (event) => {
        setSupport({
            ...support,
            endProject: event.target.checked,
            finishDate: null,
        });
    };

    return (
        <StyledContainer>
            <LocalizationProvider
                dateAdapter={AdapterMoment}
                adapterLocale={t("language.locale")}
                localeText={{ clearButtonLabel: t("miscellaneous.clear") }}
            >
                <Card elevation={0}>
                    <CardHeader
                        title="Customer Support"
                        action={
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={support.endProject}
                                        inputProps={{
                                            "aria-label": "controlled",
                                        }}
                                        color="secondary"
                                        onChange={handleChangeSwitch}
                                        className={classes.switch}
                                    />
                                }
                                label={
                                    <Typography variant="body1" color="textPrimary">
                                        {t("services.finishProject")}
                                    </Typography>
                                }
                            />
                        }
                    />
                    <Divider />
                    <CardContent>
                        <Grid container alignItems="flex-start" spacing={2}>
                            <Grid item xs={12} sm={6} lg={2}>
                                <FormControl fullWidth variant="outlined" disabled>
                                    <InputLabel htmlFor="outlined-adornment-ogFee">
                                        {t("services.hourlyRate")}
                                    </InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-ogFee"
                                        value={support.ogFee}
                                        inputComponent={NumberFormatCustom}
                                        startAdornment={
                                            <InputAdornment disableTypography position="start">
                                                $
                                            </InputAdornment>
                                        }
                                        label={t("services.hourlyRate")}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={1}>
                                <TextField
                                    fullWidth
                                    label={t("miscellaneous.hours")}
                                    name="hours"
                                    onChange={handleChange}
                                    value={support.hours}
                                    variant="outlined"
                                    type="number"
                                    disabled={support.invoiceGroup}
                                    inputProps={{
                                        min: 1,
                                        step: 0.5,
                                        onKeyDown: (event) => {
                                            event.preventDefault();
                                        },
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} lg={3}>
                                <FormControl fullWidth variant="outlined" disabled>
                                    <InputLabel htmlFor="outlined-adornment-fee">{t("services.projectFee")}</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-fee"
                                        value={support.fee}
                                        inputComponent={NumberFormatCustom}
                                        startAdornment={
                                            <InputAdornment disableTypography position="start">
                                                $
                                            </InputAdornment>
                                        }
                                        label={t("services.projectFee")}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={3}>
                                <TextField
                                    fullWidth
                                    label={t("miscellaneous.discount")}
                                    value={support.discount}
                                    name="discount"
                                    onChange={handleChangeDiscount}
                                    disabled={support.invoiceGroup}
                                    onBlur={() =>
                                        setSupport({
                                            ...support,
                                            discount: parseFloat(
                                                support.discount === "" ? 0 : support.discount
                                            ).toFixed(2),
                                        })
                                    }
                                    variant="outlined"
                                    type="number"
                                    InputProps={{
                                        inputProps: {
                                            min: 0,
                                            max: 100,
                                        },
                                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                    }}
                                    sx={{
                                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                            display: "none",
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} lg={3}>
                                <FormControl fullWidth variant="outlined" disabled>
                                    <InputLabel htmlFor="outlined-adornment-totalFee">
                                        {t("proposals.feeTotal")}
                                    </InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-totalFee"
                                        value={support.totalFee}
                                        inputComponent={NumberFormatCustom}
                                        startAdornment={
                                            <InputAdornment disableTypography position="start">
                                                $
                                            </InputAdornment>
                                        }
                                        label={t("proposals.feeTotal")}
                                    />
                                </FormControl>
                            </Grid>
                            {data.customer_group && (
                                <Grid item lg={3} sm={6} xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name="invoiceGroup"
                                                onChange={handleChangeCheck}
                                                checked={support.invoiceGroup}
                                                inputProps={{ "aria-label": "controlled" }}
                                            />
                                        }
                                        label={t("services.invGroup")}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </CardContent>
                </Card>
                <Card elevation={0}>
                    <CardHeader title={t("services.serviceResponsibility")} sx={{ color: "#ff5968" }} />
                    <Divider />
                    <CardContent>
                        <Grid container alignItems="center" spacing={2}>
                            <Grid item xs={12} sm={6} lg={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    value={support.sinceDate}
                                    onChange={(newValue) => handleChangeSinceDate(newValue)}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            required
                                            {...params}
                                            error={isError && support.sinceDate === null ? true : false}
                                        />
                                    )}
                                    inputProps={{ readOnly: true }}
                                    label={t("reports.initDate")}
                                    views={["year", "month", "day"]}
                                />
                            </Grid>
                            {support.endProject && (
                                <Grid item lg={3} sm={6} xs={12}>
                                    <DatePicker
                                        componentsProps={{
                                            actionBar: { actions: ["clear"] },
                                        }}
                                        value={support.finishDate}
                                        onChange={(newValue) => handleChangeEndDate(newValue)}
                                        inputFormat={todos.dateFormat}
                                        renderInput={(params) => (
                                            <TextField
                                                fullWidth
                                                required
                                                {...params}
                                                error={isError && !support.finishDate}
                                            />
                                        )}
                                        views={["year", "month"]}
                                        inputProps={{ readOnly: true }}
                                        label={t("reports.endDate")}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </CardContent>
                    <Divider />
                    <Box className={classes.buttonContainer}>
                        <Button
                            color="secondary"
                            variant="contained"
                            disableElevation
                            classes={{ root: classes.btnRoot }}
                            onClick={updateProject}
                        >
                            {t("miscellaneous.save")}
                        </Button>
                    </Box>
                </Card>
            </LocalizationProvider>
        </StyledContainer>
    );
};
