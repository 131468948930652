const mainCountries = [
  {
    name_es: "Estados Unidos",
    name_en: "United States",
    code: "Estados Unidos"
  },
  {
    name_es: "Venezuela",
    name_en: "Venezuela",
    code: "Venezuela"
  },
  {
    name_es: "Panamá",
    name_en: "Panama",
    code: "Panamá"
  },
  {
    name_es: "República Dominicana",
    name_en: "Dominican Republic",
    code: "República Dominicana"
  },
  {
    name_es: "Colombia",
    name_en: "Colombia",
    code: "Colombia"
  }
]

export default mainCountries;