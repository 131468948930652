/** React imports */
import React from "react";
import { useTranslation } from "react-i18next";

/** Images */

/** MUI icons */
import DescriptionIcon from "@mui/icons-material/Description";

/** MUI imports */
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

import { Footer } from "../Core";
import { DescriptionIconContainer } from "../AccountantProposal/DescriptionIconContainer";

/** Global variables */
const PREFIX = "ScheduleBook2";

const classes = {
    body2: `${PREFIX}-body2`,
    scheduleTitle: `${PREFIX}-scheduleTitle`,
    scheduleBox: `${PREFIX}-scheduleBox`,
    fieldset: `${PREFIX}-fieldset`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.scheduleTitle}`]: {
        fontSize: 22,
    },

    [`& .${classes.body2}`]: {
        backgroundColor: "white",
        marginTop: 15,
        minHeight: 850,
        [theme.breakpoints.down("md")]: {
            padding: 30,
        },
        [theme.breakpoints.up("md")]: {
            padding: 60,
        },
    },

    [`& .${classes.scheduleBox}`]: {
        backgroundColor: "#F8F9F8",
        marginTop: 16,
        padding: 20,
        borderRadius: 30,
    },

    [`& .${classes.fieldset}`]: {
        border: "2px solid #031851",
        borderStyle: "solid solid none solid",
        lineHeight: 0,
    },
}));

const CustomBox = styled(Box)(({ theme }) => ({
    borderRadius: "24px",
    color: "#fff",
    height: 30,
    //paddingLeft: "20px",
}));

const CustomBoxBar = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 6,
    height: 12,
    width: "100%",
}));

const CustomTableCell = styled(TableCell)(({ theme }) => ({
    borderBottom: 0,
    paddingRight: 0,
    [`& h2`]: {
        fontSize: 12,
    },
    [`& span`]: {
        fontSize: 12,
    },
}));

export const ScheduleBook2 = (props) => {
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    const { bookInfo } = props;

    /** Global variables */
    const weeksOnboardingSub = [
        { color: "#6d6fb1", label: t("proposals.week1B") },
        { color: "#595aa6", label: t("proposals.week2B") },
        { color: "#44469b", label: t("proposals.week3B") },
        { color: "#2f3190", label: t("proposals.week4B") },
    ];

    const weeksRecurring = [
        { color: "#9798c8", label: t("proposals.week1B") },
        { color: "#8283bc", label: t("proposals.week2B") },
        { color: "#6d6fb1", label: t("proposals.week3B") },
        { color: "#595aa6", label: t("proposals.week4B") },
        { color: "#44469b", label: t("proposals.week1B") },
        { color: "#2f3190", label: t("proposals.week2B") },
    ];

    return (
        <StyledContainer>
            <Box className={classes.body2}>
                <Typography variant="h5" className={classes.scheduleTitle} sx={{ pb: 4 }}>
                    {t("proposals.scheduleDeliverablesB")}
                </Typography>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    sx={{
                        pt: 0,
                    }}
                >
                    <Typography variant="h5">Bookkeeper</Typography>
                    {bookInfo.recurring.isActive && (
                        <Typography
                            variant="h5"
                            sx={{ fontSize: 14, display: "flex", alignItems: "center" }}
                        >
                            {t("proposals.deliverablesB")}
                            <DescriptionIcon sx={{ ml: 1 }} />
                        </Typography>
                    )}
                </Box>
                {
                    props.includeToolbox && (
                        <Box className={classes.scheduleBox}>
                            <TableContainer sx={{ overflowX: "auto" }}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow sx={{ "& th": { border: 0 } }}>
                                            <TableCell />
                                            <TableCell colSpan={4} sx={{ pt: 0, pb: 0, pr: 0 }}>
                                                <fieldset className={classes.fieldset}>
                                                    <legend align="center" style={{ paddingLeft: 8, paddingRight: 8 }}>
                                                        {t("proposals.eachMonthB")}
                                                    </legend>
                                                </fieldset>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow sx={{ "& th": { border: 0, pt: 1 } }}>
                                            <TableCell>
                                                <Typography variant="h2" sx={{ fontWeight: 700, fontSize: 12 }}>
                                                    {t("invoicing.gcaPlatSubs")}
                                                </Typography>
                                            </TableCell>
                                            {weeksOnboardingSub.map((week, index) => (
                                                <TableCell align="left">
                                                    <CustomBox
                                                        sx={{
                                                            backgroundColor: week.color,
                                                            width: sessionStorage.getItem("lng") === "en" ? "220%" : "230%",
                                                            ...(index === 3 && {
                                                                pr: "12px",
                                                                pl: "12px",
                                                                width: "112% !important",
                                                            }),
                                                            display: "flex",
                                                            justifyContent: "space-evenly",
                                                        }}
                                                    >
                                                        <Typography
                                                            variant="span"
                                                            sx={{ display: "flex", alignItems: "center", fontSize: 12 }}
                                                            noWrap
                                                        >
                                                            {week.label}
                                                        </Typography>
                                                        {index !== 3 && (
                                                            <Typography
                                                                variant="span"
                                                                sx={{ display: "flex", alignItems: "center", fontSize: 12 }}
                                                            >
                                                                &nbsp;
                                                            </Typography>
                                                        )}
                                                    </CustomBox>
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <CustomTableCell component="th" scope="row">
                                                <Typography variant="h2">{t("proposals.gcaPlatB")}</Typography>
                                            </CustomTableCell>
                                            <CustomTableCell align="right" colSpan={6}>
                                                <CustomBoxBar />
                                            </CustomTableCell>
                                        </TableRow>
                                        <TableRow>
                                            <CustomTableCell component="th" scope="row">
                                                <Typography variant="h2">{t("proposals.virtMeetB")}</Typography>
                                            </CustomTableCell>
                                            <CustomTableCell colSpan={3} />
                                            <CustomTableCell align="right">
                                                <CustomBoxBar />
                                            </CustomTableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Typography variant="body1" sx={{ pt: 2, pl: 2, fontSize: 13 }}>
                                *{t("proposals.scheduleTextBottomB")}
                            </Typography>
                        </Box>
                    )
                }
                {bookInfo.recurring.isActive && (
                    <Box className={classes.scheduleBox}>
                        <TableContainer sx={{ overflowX: "auto" }}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow sx={{ "& th": { border: 0 } }}>
                                        <TableCell />
                                        <TableCell colSpan={4} sx={{ pt: 0, pb: 0, pr: 0 }}>
                                            <fieldset className={classes.fieldset}>
                                                <legend align="center" style={{ paddingLeft: 8, paddingRight: 8 }}>
                                                    {t("proposals.eachMonthB")}
                                                </legend>
                                            </fieldset>
                                        </TableCell>
                                        <TableCell colSpan={6} sx={{ pt: 0, pb: 0, pr: 0 }}>
                                            <fieldset className={classes.fieldset}>
                                                <legend align="center" style={{ paddingLeft: 8, paddingRight: 8 }}>
                                                    {t("proposals.nextMonthB")}
                                                </legend>
                                            </fieldset>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ "& th": { border: 0, pt: 1 } }}>
                                        <TableCell>
                                            <Typography variant="h2" sx={{ fontWeight: 700, fontSize: 12 }}>
                                                SMBs Recurring
                                            </Typography>
                                        </TableCell>
                                        {weeksRecurring.map((week, index) => (
                                            <TableCell align="left">
                                                <CustomBox
                                                    sx={{
                                                        backgroundColor: week.color,
                                                        width: sessionStorage.getItem("lng") === "en" ? "225%" : "270%",
                                                        ...(index === 5 && {
                                                            pr:
                                                                sessionStorage.getItem("lng") === "en"
                                                                    ? "17px"
                                                                    : "21px",
                                                            pl:
                                                                sessionStorage.getItem("lng") === "en"
                                                                    ? "17px"
                                                                    : "21px",
                                                            width: "114% !important",
                                                        }),
                                                        display: "flex",
                                                        justifyContent: "space-evenly",
                                                    }}
                                                >
                                                    <Typography
                                                        variant="span"
                                                        sx={{ display: "flex", alignItems: "center", fontSize: 12 }}
                                                        noWrap
                                                    >
                                                        {week.label}
                                                    </Typography>
                                                    {index !== 5 && (
                                                        <Typography
                                                            variant="span"
                                                            sx={{ display: "flex", alignItems: "center", fontSize: 12 }}
                                                        >
                                                            &nbsp;
                                                        </Typography>
                                                    )}
                                                </CustomBox>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <CustomTableCell component="th" scope="row">
                                            <Typography variant="h2">{t("proposals.bankRecB")}</Typography>
                                        </CustomTableCell>
                                        <CustomTableCell />
                                        <CustomTableCell align="right" colSpan={3}>
                                            <CustomBoxBar />
                                        </CustomTableCell>
                                    </TableRow>
                                    <TableRow>
                                        <CustomTableCell component="th" scope="row">
                                            <Typography variant="h2">{t("proposals.accountingB")}</Typography>
                                        </CustomTableCell>
                                        <CustomTableCell colSpan={3} />
                                        <CustomTableCell align="right" colSpan={2}>
                                            <CustomBoxBar />
                                        </CustomTableCell>
                                    </TableRow>
                                    <DescriptionIconContainer key={"proposals.finannStateB"} />
                                    <TableRow>
                                        <CustomTableCell component="th" scope="row">
                                            <Typography component="span" variant="h2">
                                                {t("proposals.finannStateB")}
                                            </Typography>
                                        </CustomTableCell>
                                        <CustomTableCell colSpan={5} />
                                        <CustomTableCell align="right">
                                            <CustomBoxBar />
                                        </CustomTableCell>
                                    </TableRow>
                                    {/* <TableRow>
                                        <CustomTableCell component="th" scope="row">
                                            <Typography variant="h2">{t("proposals.virtualMeetB")}</Typography>
                                        </CustomTableCell>
                                        <CustomTableCell colSpan={5} />
                                        <CustomTableCell align="right">
                                            <CustomBoxBar />
                                        </CustomTableCell>
                                    </TableRow> */}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                )}
            </Box>
            <Footer />
        </StyledContainer>
    );
};
