/** React imports */
import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NumericFormat } from 'react-number-format';
import moment from "moment";

/** MUI icons */
import AddIcon from '@mui/icons-material/Add';

/** MUI imports */
import { styled } from '@mui/material/styles';
import {
    Alert,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Switch,
    Tab,
    Tabs,
    TextField,
    Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

/** Components imports */
import AlertDialog from "../../Dialog"

const PREFIX = 'ServiceComp';

const classes = {
    btnRoot: `${PREFIX}-btnRoot`,
}

const StyledContainer = styled(Box)((
    {
        theme
    }
) => ({
    [`& .${classes.btnRoot}`]: {
        borderRadius: 40,
        '&:hover': {
            backgroundColor: '#2f3190'
        },
        '&.MuiButton-root.Mui-disabled': {
            backgroundColor: '#ffcdd2 !important',
            color: '#fff',
        }
    },
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 1 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

const NumberFormatCustom = (props) => {
    const todos = useSelector(state => state.todos);

    const { inputRef, value, onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            value={value}
            getInputRef={inputRef}
            onValueChange={({ value: v }) =>
                onChange({ target: { value: v } })
            }
            thousandSeparator={todos.amountFormat === 'de-DE' ? '.' : ','}
            decimalSeparator={todos.amountFormat === 'de-DE' ? ',' : '.'}
            fixedDecimalScale={true}
            decimalScale={2}
            valueIsNumericString
        />
    );
};

const ColorButton = styled(Button)(({ theme }) => ({
    border: '1px solid #ff5968',
    borderRadius: 40
}));

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: 'rgba(19, 31, 62, 0.80)',
        fontSize: 12,
        maxWidth: 320,
        borderRadius: 6,
        fontWeight: 'normal'
    },
});

const CustomTab = styled(Tab)(({ theme }) => ({
    padding: 10,
    marginLeft: 8,
    borderRadius: '20px 20px 0 0',
    backgroundColor: '#E9EAE8',
    color: theme.palette.primary.main,
    fontWeight: 700,
    '&.Mui-selected': {
        backgroundColor: "#2f3190 !important",
        color: "#fff",
    }
}));

const ServiceComp = (props) => {
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    /** React redux */
    const todos = useSelector(state => state.todos);

    /** Component states */
    const [isError, setIsError] = useState(false);
    const [isErrorMod, setIsErrorMod] = useState(false);
    const [valueTab, setValueTab] = useState(0);
    const [showTabs, setshowTabs] = useState(false);
    const [dialogForm, setDialogForm] = useState(false);
    const [periods, setPeriods] = useState([]);
    const [newPeriodInit, setNewPeriodInit] = useState(null);
    const [newPeriodEnd, setNewPeriodEnd] = useState(null);
    const [initYear, setInitYear] = useState(false);
    const [isDisabled, setDisabled] = useState(false);
    const [endService, setEndService] = useState(false);
    const [valuesComp, setValuesComp] = useState({
        firmaDate: null,
        startDate: null,
        deliveryDate: null,
        commitmentDate: null,
        invoiceStill: false,
        totalInvoice: 0,
        monthlyFee: 0,
    });

    /** Component functions */
    const handleChangeDate = (field) => (date) => {
        setValuesComp({
            ...valuesComp,
            [field]: date
        });
    };

    const handleChangeTab = (event, newValue) => {
        if (periods.length !== newValue) {
            setValuesComp({
                ...valuesComp,
                invoiceStill: periods[newValue].fixed_invoice,
                totalInvoice: periods[newValue].total_invoice
            })

            setValueTab(newValue);
        }
    }

    const closeDialog = () => {
        setDialogForm(false);
        setNewPeriodInit(null);
        setNewPeriodEnd(null);
        setIsErrorMod(false);
        setInitYear(false);
    }

    const handleChangeNewInit = (date) => {
        setNewPeriodInit(date);
        setNewPeriodEnd(moment(date).add(1, 'years'));
    }

    const handleChangeCheckInitYear = (event) => {
        setInitYear(event.target.checked);
    }

    const handleChangeSwitch = (event) => {
        setEndService(event.target.checked);
    };

    const handleChangeFact = (event) => {
        setValuesComp({
            ...valuesComp,
            invoiceStill: event.target.checked,
            totalInvoice: 0
        });
    };

    const handleChangeTot = (event) => {
        setValuesComp({
            ...valuesComp,
            totalInvoice: event.target.value
        });
    };

    const addTab = async () => {
        props.setBackDrop(true);

        if (newPeriodInit !== null) {
            let FinalIndex = periods.length;
            let MinDate = moment(newPeriodInit).year();
            let items = [...periods];
            let ready = false;
            let error = false;

            items.forEach((value, index) => {
                if (!initYear) {
                    if (MinDate < value.year && !ready) {
                        FinalIndex = index;
                        ready = true;
                    }

                    if (MinDate === value.year && value.initial_year !== true) {
                        error = true;
                        props.setTexto(t('services.cannotEnterPeriod'));
                        props.setalertType("warning");
                        props.setShow(true);
                        return
                    }
                    if (MinDate < value.year && value.initial_year === true) {
                        error = true;
                        props.setTexto(t('services.cannorEnterLessPeriod'));
                        props.setalertType("warning");
                        props.setShow(true);
                        return
                    }
                }
                else {
                    if (MinDate <= value.year && !ready) {
                        FinalIndex = index;
                        ready = true;
                    }
                    if (MinDate > value.year) {
                        error = true;
                        props.setTexto(t('services.cannotEnterGreaterInital'));
                        props.setalertType("warning");
                        props.setShow(true);
                        return
                    }
                }
            })

            if (MinDate > moment().year()) {
                error = true;
                props.setTexto(t('services.cannotEnterFuturePeriod'));
                props.setalertType("warning");
                props.setShow(true);
                props.setBackDrop(false);
                return
            }

            if (MinDate > (moment().year() - 10)) {
                if (!error) {
                    if (periods.length === 10) {
                        items.splice(0, 1);
                    }

                    if (initYear) {
                        setDisabled(initYear);
                    }

                    let params = {
                        bsa_date: moment(periods[0].bsa_date).format("YYYY-MM-DD"),
                        service_id: props.data.id,
                        company_id: props.companyID,
                        year: moment(newPeriodInit).year(),
                        initial_year: initYear
                    }
                    let serCompId = "";

                    await fetch(`/addNewTab`, {
                        method: "POST",
                        headers: {
                            "Content-type": "application/json; charset=UTF-8",
                            "Access-Control-Allow-Origin": "*",
                        },
                        body: JSON.stringify(params),
                    }
                    ).then((res) => res.json()).then((data) => {
                        serCompId = data._id;
                    }).catch((error) => {
                        console.log(error);
                    });

                    items.splice(FinalIndex, 0, {
                        bsa_date: moment(periods[0].bsa_date, "YYYY-MM-DD"),
                        monthly_fee: periods[0].monthly_fee,
                        service_company_id: serCompId,
                        year: moment(newPeriodInit).year(),
                        since_date: moment(newPeriodInit).startOf('year'),
                        end_date: initYear ? moment(newPeriodInit).endOf('year') : moment(newPeriodInit).add(1, 'years').endOf('year'),
                        fixed_invoice: false,
                        total_invoice: 0,
                        client_commitment_date: null,
                        client_delivery_date: null,
                        initial_year: initYear
                    });

                    setPeriods(items)
                    setValueTab(0);
                    closeDialog();
                    props.reloadServices();
                }
            }
            else {
                props.setTexto(t('services.cannotEnterLessTenPeriod'));
                props.setalertType("warning");
                props.setShow(true);
            }
        }
        else {
            props.setTexto(t('miscellaneous.requiredData'));
            props.setalertType("warning");
            props.setShow(true);
            setIsErrorMod(true);
        }

        props.setBackDrop(false);
    };

    const handleChangeStartDate = (date) => {
        if (date === null) {
            setValuesComp({
                ...valuesComp,
                startDate: date,
            });
        } else {
            setValuesComp({
                ...valuesComp,
                startDate: moment(date).startOf('month'),
            });
        }
    };

    const updateService = async () => {
        props.setBackDrop(true);

        let isValid = true;

        if (valuesComp.invoiceStill && (valuesComp.totalInvoice === 0 || valuesComp.totalInvoice === "")) {
            isValid = false;
        }

        if ((valuesComp.firmaDate !== null && valuesComp.startDate !== null && isValid) ||
            (valuesComp.firmaDate !== null && showTabs && periods[valueTab].since_date !== null && isValid)) {
            let params = {};

            if (showTabs) {
                params = {
                    service_id: props.data.id,
                    service_company_id: periods[valueTab].service_company_id,
                    company_id: props.companyID,
                    bsa_date: moment(valuesComp.firmaDate).format("YYYY-MM-DD"),
                    since_date: moment(periods[valueTab].since_date).format("YYYY-MM-DD"),
                    end_date: periods[valueTab].end_date !== null ? moment(periods[valueTab].end_date).format("YYYY-MM-DD") : null,
                    client_commitment_date: periods[valueTab].client_commitment_date !== null ? moment(periods[valueTab].client_commitment_date).format("YYYY-MM-DD") : null,
                    client_delivery_date: periods[valueTab].client_delivery_date !== null ? moment(periods[valueTab].client_delivery_date).format("YYYY-MM-DD") : null,
                    fixed_invoice: valuesComp.invoiceStill,
                    total_invoice: valuesComp.totalInvoice,
                    additionals: [],
                    finished_service: endService
                };
            } else {
                params = {
                    service_id: props.data.id,
                    company_id: props.companyID,
                    bsa_date: moment(valuesComp.firmaDate).format("YYYY-MM-DD"),
                    since_date: moment(valuesComp.startDate).format("YYYY-MM-DD"),
                    additionals: []
                };
            }

            await fetch(`/addServicesAdditionals`, {
                method: "POST",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
                body: JSON.stringify(params),
            })
                .then((response) => {
                    if (response.ok) {
                        props.setTexto(t('miscellaneous.successfulUpdate'));
                        props.setalertType("success");
                        props.setShow(true);
                        props.reloadServices();
                        setValueTab(0);
                    } else {
                        props.setTexto(t('miscellaneous.updatingError'));
                        props.setalertType("error");
                        props.setShow(true);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            props.setTexto(t('miscellaneous.requiredData'));
            props.setalertType("warning");
            props.setShow(true);
            setIsError(true);
        }

        props.setBackDrop(false);
    }

    const handleChangeStartDatePeriods = (date, index) => {
        let startDate = moment(date);
        let items = [...periods];

        if (date === null) {
            items[index].since_date = date;

            setPeriods(items);
        } else if (items[index].end_date === null) {
            items[index].since_date = date;

            setPeriods(items);
        } else {
            if (startDate.isAfter(moment(items[index].end_date))) {
                items[index].since_date = null;

                setPeriods(items);
                props.setTexto(t("reports.initDateMess"));
                props.setalertType("warning");
                props.setShow(true);
            } else {
                items[index].since_date = date;

                setPeriods(items);
            }
        }
    }

    const handleChangeEndDatePeriods = (date, index) => {
        let endDate = moment(date);
        let items = [...periods];

        if (date === null) {
            items[index].end_date = date;
            items[index].client_delivery_date = null;

            setPeriods(items);
        } else if (items[index].since_date !== null) {
            if (endDate.isBefore(moment(items[index].since_date))) {
                items[index].end_date = null;
                items[index].client_delivery_date = null;

                setPeriods(items);
                props.setTexto(t("reports.endDateMess"));
                props.setalertType("warning");
                props.setShow(true);
            } else {
                items[index].end_date = date;
                items[index].client_delivery_date = null;

                setPeriods(items);
            }
        } else {
            props.setTexto(t("reports.selectStartDate"));
            props.setalertType("warning");
            props.setShow(true);
        }
    }

    const handleChangeCompDatePeriods = (date, index) => {
        let items = [...periods];

        if (date === null) {
            items[index].client_commitment_date = date;

            setPeriods(items);
        } else {
            if (moment(date).isAfter(moment(items[index].end_date))) {
                items[index].client_commitment_date = date;

                setPeriods(items);
            } else {
                props.setTexto(t("reports.selectEntDate"));
                props.setalertType("warning");
                props.setShow(true);
                items[index].client_commitment_date = null;

                setPeriods(items);
            }
        }
    }

    const handleChangeDeliverDatePeriods = (date, index) => {
        let items = [...periods];

        if (date === null) {
            items[index].client_delivery_date = date;

            setPeriods(items);
        } else {
            if (moment(date).isAfter(moment(items[index].end_date))) {
                items[index].client_delivery_date = date;

                setPeriods(items);
            } else {
                props.setTexto(t("reports.selectEntDate"));
                props.setalertType("warning");
                props.setShow(true);
                items[index].client_delivery_date = null;

                setPeriods(items);
            }
        }

        if ( periods.filter( (period) => period.client_commitment_date !== null ).some( (element) => element.client_delivery_date === null ) && endService ) {
            setEndService(false);
        }
    }

    const handleChangeCheckInitYearPeriod = (event, index) => {
        let items = [...periods];

        items[index].initYear = event.target.checked;

        setPeriods(items);
    }

    const dialogBody = (
        <Grid container spacing={2}>
            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={t("language.locale")} localeText={{ clearButtonLabel: t('miscellaneous.clear') }}>
                <Grid item xs={12} md={6}>
                    <DatePicker
                        componentsProps={{
                            actionBar: { actions: ['clear'] }
                        }}
                        value={newPeriodInit}
                        mask="____"
                        inputFormat="YYYY"
                        disableFuture
                        onChange={(newValue) => handleChangeNewInit(newValue)}
                        renderInput={(params) =>
                            <TextField
                                fullWidth
                                required
                                {...params}
                                error={isErrorMod && newPeriodInit === null ? true : false}
                            />
                        }
                        inputProps={
                            { readOnly: true, }
                        }
                        label={t("reports.initDate")}
                        views={['year']}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <DatePicker
                        componentsProps={{
                            actionBar: { actions: ['clear'] }
                        }}
                        value={newPeriodEnd}
                        mask="____"
                        inputFormat="YYYY"
                        disabled
                        renderInput={(params) =>
                            <TextField
                                fullWidth
                                required
                                {...params}
                            />
                        }
                        inputProps={
                            { readOnly: true, }
                        }
                        label={t("reports.endDate")}
                        views={['year']}
                    />
                </Grid>
                {!isDisabled ?
                    <Grid item xs={12}>
                        <FormControlLabel control={
                            <Checkbox name="checkedInitYear" checked={initYear} onChange={value => handleChangeCheckInitYear(value)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } label={t('services.startingYear')} />
                    </Grid>
                    : null
                }
            </LocalizationProvider>
        </Grid>
    )

    const dialogButtons = (
        <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
            <Grid item>
                <ColorButton onClick={closeDialog}
                    sx={{
                        mr: 1,
                        '&:hover': {
                            backgroundColor: 'white',
                            color: '#2f3190',
                            border: '1px solid #2f3190',
                        },
                    }}
                    color="secondary"
                    disableElevation
                    variant="outlined">
                    {t("miscellaneous.cancel")}
                </ColorButton>
            </Grid>
            <Grid item>
                <ColorButton onClick={addTab}
                    sx={{
                        '&:hover': {
                            backgroundColor: '#2f3190',
                            border: '1px solid #2f3190'
                        },
                    }}
                    color="secondary"
                    disableElevation
                    variant="contained">
                    {t("miscellaneous.accept")}
                </ColorButton>
            </Grid>
        </Grid>
    );

    useEffect(() => {
        if (props.data.periods !== undefined && props.data.periods.length !== 0) {
            props.data.finished_service !== undefined && props.data.finished_service !== null 
                ? setEndService(props.data.finished_service )
                : setEndService(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (props.data.periods !== undefined && props.data.periods.length !== 0) {
            let aux = [];
            let disabled = false;

            props.data.periods.forEach((item, index) => {
                let obj = {
                    bsa_date: moment(item.bsa_date, "YYYY-MM-DD"),
                    monthly_fee: item.monthly_fee,
                    service_company_id: item.service_company_id,
                    year: moment(item.since_date, "YYYY-MM-DD").year(),
                    since_date: moment(item.since_date, "YYYY-MM-DD"),
                    end_date: moment(item.end_date, "YYYY-MM-DD"),
                    client_commitment_date: item.client_commitment_date !== undefined ? moment(item.client_commitment_date, "YYYY-MM-DD") : null,
                    client_delivery_date: item.client_delivery_date !== undefined ? moment(item.client_delivery_date, "YYYY-MM-DD") : null,
                    fixed_invoice: item.fixed_invoice,
                    total_invoice: item.total_invoice,
                    initial_year: item.initial_year
                }
                if (item.initial_year) {
                    disabled = true;
                }

                aux[index] = obj;
            })

            setDisabled(disabled);
            setshowTabs(true);
            setPeriods(aux);

            setValuesComp({
                firmaDate: props.isDisabled ? moment(props.bsaFirm, "YYYY-MM-DD") : aux[valueTab].bsa_date === undefined || aux[valueTab].bsa_date === null ? null : moment(aux[valueTab].bsa_date, "YYYY-MM-DD"),
                monthlyFee: aux[valueTab].monthly_fee === undefined || aux[valueTab].monthly_fee === null ? 0 : aux[valueTab].monthly_fee,
                totalInvoice: aux[valueTab].total_invoice !== undefined ? aux[valueTab].total_invoice : 0,
                invoiceStill: aux[valueTab].fixed_invoice !== undefined ? aux[valueTab].fixed_invoice : false
            });
        }
        else {
            setValuesComp({
                firmaDate: props.isDisabled ? moment(props.bsaFirm, "YYYY-MM-DD") : null,
                monthlyFee: props.data.monthly_fee === undefined || props.data.monthly_fee === null ? 0 : props.data.monthly_fee,
                startDate: null,
                totalInvoice: props.data.total_invoice !== undefined ? props.data.total_invoice : 0,
                invoiceStill: props.data.fixed_invoice !== undefined ? props.data.fixed_invoice : false
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.bsaFirm, props.data, props.isDisabled, valueTab]);

    return (
        <StyledContainer>
            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={t("language.locale")} localeText={{ clearButtonLabel: t('miscellaneous.clear') }}>
                <Card elevation={0}>
                    <Divider />
                    <CardContent>
                        {props.expenseEst === -1 &&
                            <Grid
                                container
                                spacing={2}
                                justifyContent="center"
                                sx={{ paddingBottom: 4 }}
                            >
                                <Grid item>
                                    <Alert severity="warning" sx={{ borderRadius: 8, fontSize: 12, fontWeight: 600 }}>{t('services.ableToSaveInfo')}</Alert>
                                </Grid>
                            </Grid>
                        }
                        {props.clientMarket !== 'Estados Unidos' &&
                            <Grid
                                container
                                spacing={2}
                                justifyContent="center"
                                sx={{ paddingBottom: 4 }}
                            >
                                <Grid item>
                                    <Alert severity="warning" sx={{ borderRadius: 8, fontSize: 12, fontWeight: 600 }}>{t('services.availableUS')}</Alert>
                                </Grid>
                            </Grid>
                        }
                        <Grid
                            container
                            spacing={2}
                            alignItems="center"
                        >
                            <Grid
                                item
                                lg={3}
                                sm={6}
                                xs={12}
                            >
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ['clear'] }
                                    }}
                                    value={valuesComp.firmaDate}
                                    onChange={handleChangeDate("firmaDate")}
                                    inputFormat={todos.dateFormat}
                                    disabled={props.isDisabled}
                                    renderInput={(params) =>
                                        <TextField fullWidth required {...params}
                                            error={isError && valuesComp.firmaDate === null ? true : false} />}
                                    inputProps={
                                        { readOnly: true, }
                                    }
                                    label={t("miscellaneous.firmDate")}
                                />
                            </Grid>
                            <Grid
                                item
                                lg={3}
                                sm={6}
                                xs={12}
                            >
                                <FormControl fullWidth variant="outlined" disabled>
                                    <InputLabel htmlFor="outlined-adornment-monthlyFee">{t('services.fee')}</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-monthlyFee"
                                        value={valuesComp.monthlyFee}
                                        inputComponent={NumberFormatCustom}
                                        startAdornment={<InputAdornment disableTypography position="start">$</InputAdornment>}
                                        label={t('services.fee')}
                                    />
                                </FormControl>
                            </Grid>
                            {valuesComp.invoiceStill &&
                                <Grid
                                    item
                                    lg={3}
                                    sm={6}
                                    xs={12}
                                >
                                    <FormControl fullWidth variant="outlined" required
                                        error={isError && (valuesComp.totalInvoice === 0 || valuesComp.totalInvoice === "") ? true : false}>
                                        <InputLabel htmlFor="outlined-adornment-monthlyFee">{t('clientsTable.totalInvoice')}</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-monthlyFee"
                                            name="totalInvoice"
                                            value={valuesComp.totalInvoice}
                                            onChange={handleChangeTot}
                                            inputComponent={NumberFormatCustom}
                                            startAdornment={<InputAdornment disableTypography position="start">$</InputAdornment>}
                                            label={t('clientsTable.totalInvoice')}
                                        />
                                    </FormControl>
                                </Grid>
                            }
                            {props.data.periods !== undefined &&
                                <>
                                    <Grid
                                        item
                                        lg={3}
                                        sm={6}
                                        xs={12}
                                    >
                                        <LightTooltip title={t('services.toolPreventCreation')} aria-label="endService-tooltip">
                                            <FormControlLabel 
                                                disabled={
                                                    periods.filter( (period) => period.client_commitment_date !== null ).length === 0 ? true :
                                                    periods.filter( (period) => period.client_commitment_date !== null ).some( (element) => element.client_delivery_date === null)
                                                }
                                                control={
                                                    <Switch
                                                        checked={endService}
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                        color={"secondary"}
                                                        onChange={handleChangeSwitch}
                                                        sx={{
                                                            '& .MuiSwitch-switchBase .MuiSwitch-thumb': {
                                                                color: '#FFF',
                                                            },
                                                            '& .MuiSwitch-switchBase + .MuiSwitch-track': {
                                                                backgroundColor: '#031851',
                                                            },
                                                            '& .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb': {
                                                                color: '#FF5968'
                                                            },
                                                            '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                                                backgroundColor: '#FFA8B1',
                                                            },
                                                        }}
                                                    />
                                                } 
                                                label={<Typography variant="body1" color="textPrimary">{t('services.finishService')}</Typography>}
                                            />
                                        </LightTooltip>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                    >
                                        <FormControlLabel control={<Checkbox checked={valuesComp.invoiceStill} onChange={handleChangeFact} />} label={t('clientsTable.invoiceStill')} />
                                    </Grid>
                                    
                                </>}
                        </Grid>
                    </CardContent>
                </Card>
                <Card elevation={0}>
                    <CardHeader
                        title={t('services.serviceResponsibility')}
                        sx={{ color: '#ff5968' }}
                    />
                    <Divider />
                    <CardContent sx={{ pb: 5 }}>
                        {showTabs ?
                            <>
                                <Box sx={{ borderBottom: 2, borderColor: '#031851', mb: 2 }}>
                                    <Tabs value={valueTab} onChange={handleChangeTab} variant="scrollable"
                                        scrollButtons="auto" aria-label="years tabs"
                                        TabIndicatorProps={{
                                            sx: {
                                                display: 'none'
                                            }
                                        }}
                                    >
                                        {
                                            periods.map((item, index) => (
                                                <CustomTab label={item.initial_year ? item.year : item.year + "-" + (item.year + 1)} {...a11yProps(index)}
                                                    sx={{
                                                        borderTop:
                                                            item.client_commitment_date &&
                                                            "2px solid #ff5968",
                                                        borderRight:
                                                            item.client_commitment_date &&
                                                            "2px solid #ff5968",
                                                        borderLeft:
                                                            item.client_commitment_date &&
                                                            "2px solid #ff5968",
                                                        backgroundColor:
                                                            item.client_commitment_date
                                                                ? '#ff5968'
                                                                : '#E9EAE8',
                                                        color:
                                                            item.client_commitment_date
                                                                ? '#fff' 
                                                                : '#031851',
                                                    }}
                                                />
                                            ))
                                        }
                                        {!endService && <CustomTab icon={<AddIcon fontSize="small" />} aria-label="add" onClick={() => setDialogForm(true)} />}
                                    </Tabs>
                                </Box>
                                {
                                    periods.map((values, i) => (
                                        <TabPanel value={valueTab} index={i}>
                                            <Grid
                                                container
                                                spacing={2}
                                            >
                                                <Grid
                                                    item
                                                    lg={3}
                                                    sm={6}
                                                    xs={12}
                                                >
                                                    <DatePicker
                                                        componentsProps={{
                                                            actionBar: { actions: ['clear'] }
                                                        }}
                                                        value={values.since_date}
                                                        onChange={(newValue) => handleChangeStartDatePeriods(newValue, i)}
                                                        inputFormat={todos.dateFormat}
                                                        disabled
                                                        renderInput={(params) =>
                                                            <TextField
                                                                fullWidth
                                                                required
                                                                {...params}
                                                                error={isError && values.since_date === null ? true : false}
                                                            />
                                                        }
                                                        inputProps={
                                                            { readOnly: true, }
                                                        }
                                                        label={t("reports.initDate")}
                                                        views={['year', 'month']}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    lg={3}
                                                    sm={6}
                                                    xs={12}
                                                >
                                                    <DatePicker
                                                        componentsProps={{
                                                            actionBar: { actions: ['clear'] }
                                                        }}
                                                        value={values.end_date}
                                                        onChange={(newValue) => handleChangeEndDatePeriods(newValue, i)}
                                                        inputFormat={todos.dateFormat}
                                                        disabled
                                                        renderInput={(params) => <TextField fullWidth {...params} />}
                                                        inputProps={
                                                            { readOnly: true, }
                                                        }
                                                        label={t("reports.endDate")}
                                                        views={['year', 'month']}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    lg={3}
                                                    sm={6}
                                                    xs={12}
                                                >
                                                    <DatePicker
                                                        componentsProps={{
                                                            actionBar: { actions: ['clear'] }
                                                        }}
                                                        disabled={endService}
                                                        value={values.client_commitment_date}
                                                        minDate={moment(values.since_date, "YYYY").startOf('year')}
                                                        onChange={(newValue) => handleChangeCompDatePeriods(newValue, i)}
                                                        inputFormat={todos.dateFormat}
                                                        renderInput={(params) => <TextField fullWidth {...params} />}
                                                        inputProps={
                                                            { readOnly: true, }
                                                        }
                                                        label={t('services.estimDeliveryDate')}
                                                        views={["year", "month", "day"]}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    lg={3}
                                                    sm={6}
                                                    xs={12}
                                                >
                                                    <DatePicker
                                                        componentsProps={{
                                                            actionBar: { actions: ['clear'] }
                                                        }}
                                                        disableFuture
                                                        value={values.client_delivery_date}
                                                        minDate={moment(values.end_date, "YYYY").startOf('year')}
                                                        onChange={(newValue) => handleChangeDeliverDatePeriods(newValue, i)}
                                                        disabled={values.end_date === null}
                                                        inputFormat={todos.dateFormat}
                                                        renderInput={(params) => <TextField fullWidth {...params} />}
                                                        inputProps={
                                                            { readOnly: true, }
                                                        }
                                                        label={t('services.deliveryDate')}
                                                        views={["year", "month", "day"]}
                                                    />
                                                </Grid>
                                                {values.initial_year ?
                                                    <Grid item xs={12}>
                                                        <FormControlLabel control={
                                                            <Checkbox name="checkedOrd" disabled checked={values.initial_year} onChange={value => handleChangeCheckInitYearPeriod(value, i)}
                                                                inputProps={{ 'aria-label': 'controlled' }}
                                                            />
                                                        } label={t('services.startingYear')} />
                                                    </Grid>
                                                    : null}
                                            </Grid>
                                        </TabPanel>
                                    ))
                                }
                            </>
                            :
                            <Grid
                                container
                                spacing={2}
                            >
                                <Grid
                                    item
                                    lg={3}
                                    sm={6}
                                    xs={12}
                                >
                                    <DatePicker
                                        componentsProps={{
                                            actionBar: { actions: ['clear'] }
                                        }}
                                        value={valuesComp.startDate}
                                        onChange={(newValue) => handleChangeStartDate(newValue)}
                                        inputFormat={todos.dateFormat}
                                        mask="__/__/____"
                                        disableFuture
                                        renderInput={(params) =>
                                            <TextField fullWidth required {...params}
                                                error={isError && valuesComp.startDate === null ? true : false}
                                            />
                                        }
                                        inputProps={
                                            { readOnly: true, }
                                        }
                                        label={t("reports.initDate")}
                                        views={['year']}
                                    />
                                </Grid>
                            </Grid>
                        }
                    </CardContent>
                    <Divider />
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            p: 2
                        }}
                    >
                        <Button
                            color="secondary"
                            variant="contained"
                            disableElevation
                            classes={{ root: classes.btnRoot }}
                            onClick={updateService}
                            disabled={(props.expenseEst === -1) || (props.clientMarket !== 'Estados Unidos')}
                        >
                            {showTabs ? t("miscellaneous.save") : t('reports.generate')}
                        </Button>
                    </Box>
                </Card>
            </LocalizationProvider>
            <AlertDialog
                open={dialogForm}
                onClose={closeDialog}
                message={dialogBody}
                title={t('services.newPeriod')}
                button={dialogButtons}
                maxWidth="xs"
            />
        </StyledContainer>
    )
}

export default ServiceComp;