/** React imports */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useSelector } from "react-redux";

/** MUI icons */
import AttachFileIcon from "@mui/icons-material/AttachFile";
import DeleteIcon from "@mui/icons-material/Delete";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { NumericFormat } from "react-number-format";

/** MUI imports */
import {
    Grid,
    Table,
    TableHeaderRow,
    PagingPanel,
} from "@devexpress/dx-react-grid-material-ui";
import {
    IntegratedFiltering,
    PagingState,
    IntegratedPaging,
} from "@devexpress/dx-react-grid";
import { styled } from "@mui/material/styles";
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Typography,
    IconButton,
    Box,
    Button,
    FormControl,
    InputLabel,
    OutlinedInput,
    InputAdornment
} from "@mui/material";
import GridUI from "@mui/material/Grid";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

/** Component imports */
import Services from "../../Js/servicesInvoicing";
import Projects from "../../Js/projects";
import DialogAlerts from "../DialogAlerts";
import ExchangeRateDialog from "../Dialog";

const PREFIX = "DialogConversions";

const classes = {
    tableStriped: `${PREFIX}-tableStriped`,
    tableHeader: `${PREFIX}-tableHeader`,
    tableBody: `${PREFIX}-tableBody`,
    pager: `${PREFIX}-pager`,
};

const CustomDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(3.5),
    },
    "& .MuiDialogTitle-root": {
        backgroundColor: "#031851",
        fontSize: "18px",
        color: "#fff",
    },
    "& .MuiDialog-paper": {
        borderRadius: 8,
        boxShadow: "10px 10px 15px rgba(3,24,81,0.15)",
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(2),
    },

    [`& .${classes.tableStriped}`]: {
        "& tbody tr:nth-of-type(odd)": {
            backgroundColor: "rgba(47,49,144,0.1)",
        },
    },
    [`& .${classes.tableHeader}`]: {
        border: "none",
        backgroundColor: "rgba(47,49,144,0.3)",
        fontSize: "15px",
        fontWeight: 600,
        color: "#031851",
        "&:first-of-type": {
            borderRadius: "20px 0 0 0",
        },
        "&:last-child": {
            borderRadius: "0 20px 0 0",
            paddingRight: "24px",
        },
    },

    [`& .${classes.tableBody}`]: {
        border: "none",
        fontSize: "14px",
        fontWeight: 400,
        color: "#031851",
    },

    [`& .${classes.pager}`]: {
        "& .Pagination-button": {
            paddingTop: 0,
            paddingBottom: 0,
            color: "#031851",
            fontWeight: 600,
        },
        "& .Pagination-activeButton": {
            backgroundColor: "rgba(47,49,144,0.3)",
        },
    },
}));

const ColorButton = styled(Button)(({ theme }) => ({
    border: "1px solid #ff5968",
    borderRadius: 40,
}));

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "rgba(19, 31, 62, 0.80)",
        fontSize: 12,
        maxWidth: 320,
        borderRadius: 6,
        fontWeight: "normal",
    },
});

const tableHeaderCell = ({ ...props }) => (
    <TableHeaderRow.Cell {...props} className={classes.tableHeader} />
);

const TableComponent = ({ ...props }) => (
    <Table.Table {...props} className={classes.tableStriped} />
);

const TableCellComponent = ({ ...props }) => (
    <Table.Cell {...props} className={classes.tableBody} />
);

const PagingPanelContainer = ({ ...props }) => (
    <PagingPanel.Container {...props} className={classes.pager} />
);

const tableColumnExtensions = [
    { columnName: "smb", align: "left" },
    { columnName: "serviceT", align: "center" },
    { columnName: "projectT", align: "center" },
    { columnName: "period", align: "center" },
    { columnName: "amountT", align: "right" },
];

const NumberFormatCustom = (props) => {
    const todos = useSelector((state) => state.todos);

    const { inputRef, value, onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            value={value}
            getInputRef={inputRef}
            onValueChange={({ value: v }) => onChange({ target: { value: v } })}
            thousandSeparator={todos.amountFormat === "de-DE" ? "." : ","}
            decimalSeparator={todos.amountFormat === "de-DE" ? "," : "."}
            fixedDecimalScale={true}
            decimalScale={2}
            valueIsNumericString
        />
    );
};

const ModInvoice = (props) => {
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    const tableMessages = {
        noData: t("miscellaneous.noData"),
    };

    const pagingMessages = {
        info: ({ from, to, count }) =>
            `${from}${from < to ? `-${to}` : ""} ${t(
                "miscellaneous.pagingOf"
            )} ${count}`,
    };

    /** React redux */
    const todos = useSelector((state) => state.todos);

    /** Component states */
    const [rowsData, setRowsData] = useState([]);
    const [columnDefs, setColumnsDefs] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);
    const [documentType, setDocumentType] = useState(0);
    const [open, setOpen] = useState(false);
    const [file, setFile] = useState(null);
    const [dialogText, setDialogText] = useState("");
    const [dialogType, setDialogType] = useState("info");
    const [currencyRate, setCurrencyRate] = useState("");
    const [openExc, setOpenExc] = useState(false);
    const [isError, setIsError] = useState(false);

    const handleCloseAlert = () => {
        setDialogText("");
        setDialogType("");
        setOpen(false);
    };

    const handleOpenAlert = () => {
        if(rowsData[0].market === "Venezuela" && documentType !== 1){
            handleOpenCurrencyRateDialog();
        }
        else{
            setDialogType("info");
            let text = ""
            if (rowsData.length === 1){
                text= t("invoicing.messageModal4single")
            }else{
                text = t("invoicing.messageModal4") + rowsData.length +t("invoicing.messageModal4part2")
            }
            setDialogText(text);
            setOpen(true);
        }
    };

    const handleCloseCurrencyRateDialog = () => {
        setCurrencyRate("");
        setIsError(false);
        setOpenExc(false);
    };

    const handleChangeCurrencyRate = (event) => {
        setCurrencyRate(event.target.value);
    };

    const handleOpenCurrencyRateDialog = () => {
        setOpenExc(true);
    };

    const saveInvoice = async () => {
        props.setBackDrop(true);

        const formData = new FormData();
        formData.append("file", file);
        formData.append("invoice_method", 1);
        formData.append("lineItems", JSON.stringify(rowsData));

        if(rowsData[0].market === "Venezuela" && documentType !== 1){
            formData.append("currency_rate", parseFloat(currencyRate));
        }

        await fetch("/generateInvoicesXero", {
            signal: AbortSignal.timeout(29000),
            method: "POST",
            body: formData,
        })
            .then((response) => response.text())
            .then((data) => {
                handleCloseCurrencyRateDialog();
                if (data) {
                    documentType === 1
                    ? props.setTexto(t("reports.successInvNorm") + data)
                    : props.setTexto(t("reports.successNcNorm") + data);
                    props.setAutoAlertType("success");
                    props.setShow(true);
                    props.loadInitData();
                } else {
                    props.setTexto(t("invoicing.errorInvoice"));
                    props.setAutoAlertType("error");
                    props.setShow(true);
                }
            })
            .catch((error) => {
                if (error.name === "AbortError") {
                    props.setTexto(t("invoicing.errorInvoice"));
                    props.setAutoAlertType("error");
                    props.setShow(true);
                } else {
                    throw error;
                }
            });

        handleCloseAlert();
        resetModal();
        props.setBackDrop(false);
    };

    const saveFile = (event) => {
        if (event.target.files[0]) {
            if (event.target.files[0].type !== "application/x-msdownload") {
                setFile(event.target.files[0]);
            } else {
                setFile(null);
                props.setTexto(t("reports.invalidExt"));
                props.setAutoAlertType("warning");
                props.setShow(true);
            }
        } else {
            setFile(null);
        }
        event.target.value = null;
    };

    const resetModal = () => {
        setColumnsDefs([]);
        setRowsData([]);
        setTotalAmount(0);
        setDocumentType(0);
        setFile(null);
        props.onClose();
    };

    const dialogBody = (
        <GridUI container spacing={2}>
            <GridUI item xs={12}>
                <FormControl fullWidth required variant="outlined" error={isError && !currencyRate ? true : false}>
                    <InputLabel htmlFor="outlined-adornment-currencyRate">{t("invoicing.currencyRate")}</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-currencyRate"
                        value={currencyRate}
                        onChange={handleChangeCurrencyRate}
                        inputComponent={NumberFormatCustom}
                        startAdornment={
                            <InputAdornment disableTypography position="start">
                                1 USD =
                            </InputAdornment>
                        }
                        label={t("invoicing.currencyRate")}
                    />
                </FormControl>
            </GridUI>
        </GridUI>
    );

    const dialogButtons = (
        <GridUI container justifyContent="space-between" alignItems="center" spacing={2}>
            <GridUI item>
                <Button
                    onClick={handleCloseCurrencyRateDialog}
                    sx={{
                        pl: 3,
                        pr: 3,
                        border: "1px solid #ff5968",
                        borderRadius: 40,
                        "&:hover": {
                            border: "1px solid #2f3190",
                            color: "#2f3190",
                        },
                        "&.Mui-disabled": {
                            color: "#ffcdd2",
                            borderColor: "#ffcdd2",
                            background: "transparent",
                        },
                    }}
                    color="secondary"
                    disableElevation
                    variant="outlined"
                >
                    {t("miscellaneous.cancel")}
                </Button>
            </GridUI>
            <GridUI item>
                <Button
                    onClick={saveInvoice}
                    sx={{
                        pl: 3,
                        pr: 3,
                        borderRadius: 40,
                        "&:hover": {
                            backgroundColor: "#2f3190",
                            color: "#fff",
                        },
                        "&.Mui-disabled": {
                            color: "#fff",
                            borderColor: "#ffcdd2",
                            background: "#ffcdd2",
                        },
                    }}
                    color="secondary"
                    disableElevation
                    variant="contained"
                >
                    {t("miscellaneous.invoice2")}
                </Button>
            </GridUI>
        </GridUI>
    );

    /** Component events */
    useEffect(() => {
        const columnsTemplate = [
            { name: "smb", title: "SMB" },
            { name: "serviceT", title: "Servicio" },
            { name: "projectT", title: t("invoicing.project") },
            { name: "period", title: "Período" },
            { name: "amountT", title: "Monto" },
        ];
        let totalAmount = 0;
        let docType = 0;
        let lineItems = [];

        if (props.isEditMode) {
            lineItems = props.lineItemsSelected.map((item) => {
                totalAmount += item.amount;
                docType = item.documentType;
                return {
                    _id: item.id,
                    franchise_id: item.franchiseId,
                    practice_id: item.practiceId,
                    company_id: item.companyId,
                    description: item.description,
                    initial_date: item.initDate,
                    end_date: item.endDate,
                    service: item.project_type,
                    amount: item.amount,
                    smb: item.smb,
                    invoicing_subscription: item.invoicing_subscription,
                    serviceT: (
                        <Typography
                            variant="body1"
                            component="span"
                            align="center"
                            color="textPrimary"
                        >
                            {t(`invoicing.${item.service}`)}
                        </Typography>
                    ),
                    projectT: (
                        <Typography
                            variant="body1"
                            component="span"
                            align="center"
                            color="textPrimary"
                        >
                            {t(`invoicing.${item.project}`)}
                        </Typography>
                    ),
                    period: item.period,
                    amountT: parseFloat(item.amount).toLocaleString(
                        todos.amountFormat,
                        { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                    ),
                    market: item.market
                };
            });
            setTotalAmount(totalAmount);
            setDocumentType(docType);
        } else {
            setTotalAmount(props.lineItemsSelected.totalAmount);
            lineItems = props.lineItemsSelected.lineItems.map((item) => {
                return {
                    _id: item._id,
                    smb: item.smb_name,
                    description: item.description,
                    invoicing_subscription: item.invoicing_subscription,
                    serviceT: (
                        <Typography
                            variant="body1"
                            component="span"
                            align="center"
                            color="textPrimary"
                        >
                            {t(
                                `invoicing.${
                                    Services.find(
                                        (service) =>
                                            service.code === item.service_type
                                    ).name
                                }`
                            )}
                        </Typography>
                    ),
                    projectT: (
                        <Typography
                            variant="body1"
                            component="span"
                            align="center"
                            color="textPrimary"
                        >
                            {t(
                                `invoicing.${
                                    Projects.find(
                                        (project) =>
                                            project.code === item.project_type
                                    ).name
                                }`
                            )}
                        </Typography>
                    ),
                    period:
                        item.end_date !== undefined &&
                        item.end_date !== null &&
                        moment(item.end_date, "YYYY-MM-DD")
                            .locale(t("language.locale"))
                            .format("MMM YY") !==
                            moment(item.initial_date, "YYYY-MM-DD")
                                .locale(t("language.locale"))
                                .format("MMM YY")
                            ? `${(
                                  moment(item.initial_date, "YYYY-MM-DD")
                                      .locale(t("language.locale"))
                                      .format("MMM")
                                      .charAt(0)
                                      .toUpperCase() +
                                  moment(item.initial_date, "YYYY-MM-DD")
                                      .locale(t("language.locale"))
                                      .format("MMM YY")
                                      .slice(1)
                              ).replace(".", "")} - ${(
                                  moment(item.end_date, "YYYY-MM-DD")
                                      .locale(t("language.locale"))
                                      .format("MMM")
                                      .charAt(0)
                                      .toUpperCase() +
                                  moment(item.end_date, "YYYY-MM-DD")
                                      .locale(t("language.locale"))
                                      .format("MMM YY")
                                      .slice(1)
                              ).replace(".", "")}`
                            : item.initial_date !== undefined &&
                              item.initial_date !== null
                            ? (
                                  moment(item.initial_date, "YYYY-MM-DD")
                                      .locale(t("language.locale"))
                                      .format("MMM")
                                      .charAt(0)
                                      .toUpperCase() +
                                  moment(item.initial_date, "YYYY-MM-DD")
                                      .locale(t("language.locale"))
                                      .format("MMM YY")
                                      .slice(1)
                              ).replace(".", "")
                            : "-",
                    amountT: parseFloat(item.amount).toLocaleString(
                        todos.amountFormat,
                        { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                    ),
                };
            });
        }

        setColumnsDefs(columnsTemplate);
        setRowsData(lineItems);
    }, [props.isEditMode, props.lineItemsSelected, t, todos.amountFormat]);

    return (
        <CustomDialog
            open={props.open}
            onClose={resetModal}
            aria-labelledby="history-dialog-title"
            aria-describedby="history-dialog-description"
            maxWidth="lg"
            fullWidth={true}
        >
            <DialogTitle id="history-dialog-title">
                {props.isEditMode ? (
                    t("currentView.invoiceSetting")
                ) : (
                    <>
                        {t("currentView.invoiceDetail")} -{" "}
                        <span style={{ color: "#ff5968" }}>
                            {props.invoiceNumber}
                        </span>
                    </>
                )}
            </DialogTitle>
            <DialogContent dividers>
                <GridUI
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ pb: 2 }}
                >
                    {props.isEditMode ? (
                        <>
                            <GridUI item>
                                <Typography variant="h1" component="span">
                                    {props.lineItemsSelected.length !== 0
                                        ? props.lineItemsSelected[0].contact
                                        : ""}
                                </Typography>
                                <IconButton
                                    aria-label="upload"
                                    sx={{ ml: 1 }}
                                    component="label"
                                >
                                    <input
                                        hidden
                                        type="file"
                                        onChange={saveFile}
                                    />
                                    <AttachFileIcon
                                        sx={{
                                            color: file
                                                ? "#031851"
                                                : "rgba(0, 0, 0, 0.26)",
                                        }}
                                    />
                                </IconButton>
                            </GridUI>
                            {file && (
                                <GridUI item>
                                    <DescriptionOutlinedIcon
                                        color="primary"
                                        sx={{ verticalAlign: "middle" }}
                                    />
                                    <Typography
                                        variant="body1"
                                        component="span"
                                        sx={{ ml: 2, verticalAlign: "middle" }}
                                    >
                                        {file.name}
                                    </Typography>
                                    <LightTooltip
                                        title={t("dialogConversions.remove")}
                                        aria-label="removeUploadedFile"
                                    >
                                        <IconButton
                                            aria-label="delete"
                                            onClick={() => setFile(null)}
                                            sx={{ ml: 2 }}
                                        >
                                            <DeleteIcon
                                                fontSize="medium"
                                                color="primary"
                                            />
                                        </IconButton>
                                    </LightTooltip>
                                </GridUI>
                            )}
                        </>
                    ) : (
                        <Typography variant="h1" component="span">
                            {props.lineItemsSelected.contactName}
                        </Typography>
                    )}
                </GridUI>
                <Grid rows={rowsData} columns={columnDefs}>
                    <IntegratedFiltering />
                    <PagingState defaultCurrentPage={0} pageSize={10} />
                    <IntegratedPaging />
                    <Table
                        tableComponent={TableComponent}
                        columnExtensions={tableColumnExtensions}
                        cellComponent={TableCellComponent}
                        messages={tableMessages}
                    />
                    <TableHeaderRow cellComponent={tableHeaderCell} />
                    <PagingPanel
                        containerComponent={PagingPanelContainer}
                        messages={pagingMessages}
                    />
                </Grid>
                <Box
                    sx={{
                        borderRadius: 4,
                        backgroundColor: "rgba(47,49,144,0.1)",
                        p: "10px 25px 10px 25px",
                    }}
                >
                    <GridUI
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={2}
                    >
                        <GridUI item>
                            <Typography variant="h2">
                                {t("clientsTable.totalInvoice")}: $
                                {parseFloat(totalAmount).toLocaleString(
                                    todos.amountFormat,
                                    {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    }
                                )}
                            </Typography>
                        </GridUI>
                        {props.isEditMode ? (
                            <GridUI item>
                                <ColorButton
                                    sx={{
                                        mr: 1,
                                        "&:hover": {
                                            backgroundColor: "white",
                                            color: "#2f3190",
                                            border: "1px solid #2f3190",
                                        },
                                    }}
                                    color="secondary"
                                    disableElevation
                                    variant="outlined"
                                    onClick={resetModal}
                                >
                                    {t("miscellaneous.cancel")}
                                </ColorButton>
                                <ColorButton
                                    disableElevation
                                    variant="contained"
                                    color="secondary"
                                    onClick={handleOpenAlert}
                                    sx={{
                                        "&:hover": {
                                            backgroundColor: "#2f3190",
                                            border: "1px solid #2f3190",
                                            color: "#fff",
                                        },
                                        "&.MuiButton-root.Mui-disabled": {
                                            backgroundColor:
                                                "#ffcdd2 !important",
                                            border: "none",
                                            color: "#fff",
                                        },
                                    }}
                                >
                                    {t("miscellaneous.invoice2")}
                                </ColorButton>
                            </GridUI>
                        ) : (
                            <GridUI item>
                                <ColorButton
                                    disableElevation
                                    variant="contained"
                                    color="secondary"
                                    onClick={resetModal}
                                    sx={{
                                        "&:hover": {
                                            backgroundColor: "#2f3190",
                                            border: "1px solid #2f3190",
                                            color: "#fff",
                                        },
                                        "&.MuiButton-root.Mui-disabled": {
                                            backgroundColor:
                                                "#ffcdd2 !important",
                                            border: "none",
                                            color: "#fff",
                                        },
                                    }}
                                >
                                    {t("miscellaneous.accept")}
                                </ColorButton>
                            </GridUI>
                        )}
                    </GridUI>
                </Box>
            </DialogContent>
            <DialogAlerts
                open={open}
                onClose={handleCloseAlert}
                agreeBtnLabel={t("miscellaneous.accept")}
                disagreeBtnLabel={t("miscellaneous.cancel")}
                type={dialogType}
                title={dialogText}
                agreeAction={saveInvoice}
            />
            <ExchangeRateDialog
                open={openExc}
                onClose={handleCloseCurrencyRateDialog}
                message={dialogBody}
                title={t("invoicing.specifyCurrencyRate")}
                button={dialogButtons}
                maxWidth="xs"
            />
        </CustomDialog>
    );
};

export default ModInvoice;
