/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

/** MUI icons */
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

/** MUI imports */
import { styled } from "@mui/material/styles";
import { Box, Breadcrumbs, Button, Card, CardContent, Grid, Tabs, Tab, Typography, Stack } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

/** Components imports */
import Backdrop from "../Backdrop";
import MainData from "./MainData";
import SnackBar from "../SnackBar";
import Invoicing from "./Invoicing";
import Proposals from "./Proposals";
import Monitoring from "./Monitoring";
import Requirements from "./Requirements";
import moment from "moment";

const PREFIX = "AnalytReports";

const classes = {
    cardContentRoot: `${PREFIX}-cardContentRoot`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.cardContentRoot}`]: {
        "&:last-child": {
            paddingBottom: "18px",
        },
        padding: 30,
    },
}));

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "rgba(19, 31, 62, 0.80)",
        fontSize: 12,
        maxWidth: 320,
        borderRadius: 6,
        fontWeight: "normal",
    },
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 1, pt: 4 }}>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const CustomTab = styled(Tab)(({ theme }) => ({
    padding: 20,
    marginLeft: 8,
    borderRadius: "20px 20px 0 0",
    backgroundColor: "#E9EAE8",
    color: theme.palette.primary.main,
    fontWeight: 700,
    "&.Mui-selected": {
        borderTop: "2px solid #ff5968",
        borderRight: "2px solid #ff5968",
        borderLeft: "2px solid #ff5968",
        backgroundColor: "#fff !important",
    },
}));

const clientPassAccess = [
    "atorrealba@gca.digital",
    "btorres@gca.digital",
    "cserrano@gca.digital",
    "galbani@gca.digital",
    "dloyo@gca.digital",
    "murbaneja@gca.digital",
    "yrosas@gca.digital",
    "jalvarez@gca.digital",
    "kgarcia@gca.digital",
    "jrojas@gca.digital",
    "mmedina@gca.digital",
    "dgoncalves@gca.digital",
];

const team = [
    "ac@gca.digital",
    "atorrealba@gca.digital",
    "btorres@gca.digital",
    "cgil@gca.digital",
    "galbani@gca.digital",
    "dloyo@gca.digital",
    "dgoncalves@gca.digital",
    "murbaneja@gca.digital",
    "yrosas@gca.digital",
    "gcalles@gca.digital",
    "jalvarez@gca.digital",
    "kgarcia@gca.digital",
    "jrojas@gca.digital",
    "mmedina@gca.digital",
];

const AnalytReports = () => {
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    /* React redux */
    const todos = useSelector((state) => state.todos);

    /** Component states */
    const [valueTab, setValueTab] = useState(0);
    const [showBackdrop, setBackDrop] = useState(false);
    const [show, setShow] = useState(false);
    const [texto, setTexto] = useState("");
    const [alertType, seAutoAlertType] = useState("");
    // Reporte seleccionado.

    const [reportSelectedProp, setReportSelectedProp] = useState(0); //Propuestas
    const [reportSelectedClient, setReportSelectedClient] = useState(0); //Gestión de clientes
    const [reportSelected, setReportSelected] = useState(1); //Facturación
    const [reportSelectedReq, setReportSelectedReq] = useState(0); //Requerimientos
    const [reportSelectedClosures, setReportSelectedClosures] = useState(0); //Cierres contables
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear()); //Año seleccionado para Validaciones bancarias
    const [selectedYearExpenses, setSelectedYearExpenses] = useState(new Date().getFullYear()); // Año seleccionado para Egresos.
    const [franqData, setFranqData] = useState([]);
    const [franqValue, setFranqValue] = useState("");
    const [groupData, setGroupData] = useState([]);
    const [groupValue, setGroupValue] = useState("");
    const [disabledDownloadReport, setDisabledDownloadReport] = useState(false);

    /** Global variables */
    const breadcrumbs = [
        <Typography
            key="1"
            variant="h1"
            color="primary"
            sx={{
                fontSize: 24,
                fontWeight: 700,
            }}
        >
            {t("principal.analytics")}
        </Typography>,
        <Typography
            key="2"
            variant="h1"
            color="secondary"
            sx={{
                fontSize: 24,
                fontWeight: 700,
            }}
        >
            {t("principal.reports")}
        </Typography>,
    ];

    /** Components functions */
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setShow(false);
    };

    /** Component functions */
    const handleChangeTab = async (event, newValue) => {
        setBackDrop(true);
        setValueTab(newValue);

        switch (newValue) {
            case 0: //Tab de Propuestas
                setReportSelectedProp(0);
                break;
            case 1: //Tab de Gestión de clientes
                setReportSelectedClient(0);
                break;
            case 2: //Tab de Facturación
                setReportSelected(1);
                setSelectedYearExpenses(new Date().getFullYear());
                break;
            case 3: //Tab de Requerimientos
                setReportSelectedReq(0);
                break;
            case 4: //Tab de Cierres contables
                setReportSelectedClosures(0);
                break;
            default:
                break;
        }
        setBackDrop(false);
    };

    const downloadReport = async (index) => {
        index += !team.includes(todos.userInfo.email) && index > 1 ? 1 : 0;
        setBackDrop(true);

        let filename = "";
        let dateFileName = moment().format("DD-MM-YYYY HH_mm_ss");
        let option = "";

        switch (index) {
            case 0:
                option =
                    reportSelectedProp === 0
                        ? "Propuestas resumido"
                        : reportSelectedProp === 1
                        ? "Propuestas detallado"
                        : "Prospectos";
                filename = `Plataforma GCA - ${option} ${dateFileName}.xlsx`;

                switch (reportSelectedProp) {
                    case 0:
                        await fetch("/generateProposalReport", {
                            method: "GET",
                            headers: {
                                "Content-type": "application/json; charset=UTF-8",
                                "Access-Control-Allow-Origin": "*",
                            },
                        })
                            .then((res) => res.blob())
                            .then((blob) => {
                                const link = document.createElement("a");
                                link.href = URL.createObjectURL(blob);
                                link.setAttribute("download", filename);
                                document.body.appendChild(link);
                                link.click();
                                document.body.removeChild(link);
                            })
                            .catch((err) => {
                                setTexto(t("reports.donwloadingReptError"));
                                seAutoAlertType("warning");
                                setShow(true);
                            });
                        break;
                    case 1:
                        await fetch("/generateProposalDetailReport", {
                            method: "GET",
                            headers: {
                                "Content-type": "application/json; charset=UTF-8",
                                "Access-Control-Allow-Origin": "*",
                            },
                        })
                            .then((res) => res.blob())
                            .then((blob) => {
                                const link = document.createElement("a");
                                link.href = URL.createObjectURL(blob);
                                link.setAttribute("download", filename);
                                document.body.appendChild(link);
                                link.click();
                                document.body.removeChild(link);
                            })
                            .catch((err) => {
                                setTexto(t("reports.donwloadingReptError"));
                                seAutoAlertType("warning");
                                setShow(true);
                            });
                        break;
                    default:
                        await fetch("/getProspectReportInfo?report=true", {
                            method: "GET",
                            headers: {
                                "Content-type": "application/json; charset=UTF-8",
                                "Access-Control-Allow-Origin": "*",
                            },
                        })
                            .then((res) => res.blob())
                            .then((blob) => {
                                const link = document.createElement("a");
                                link.href = URL.createObjectURL(blob);
                                link.setAttribute("download", filename);
                                document.body.appendChild(link);
                                link.click();
                                document.body.removeChild(link);
                            })
                            .catch((err) => {
                                setTexto(t("reports.donwloadingReptError"));
                                seAutoAlertType("warning");
                                setShow(true);
                            });
                        break;
                }

                break;
            case 1:
                option =
                    reportSelectedClient === 0
                        ? "Datos generales"
                        : reportSelectedClient === 1
                        ? "Servicios"
                        : "Contactos";
                filename = `Plataforma GCA - ${option} ${dateFileName}.xlsx`;

                if (reportSelectedClient === 0) {
                    await fetch(
                        `/generateClientsReport?hasAccess=${clientPassAccess.includes(
                            todos.userInfo.email
                        )}&token_set_id=${todos.userInfo._id}`,
                        {
                            method: "GET",
                            headers: {
                                "Content-type": "application/json; charset=UTF-8",
                                "Access-Control-Allow-Origin": "*",
                            },
                        }
                    )
                        .then((res) => res.blob())
                        .then((blob) => {
                            const link = document.createElement("a");
                            link.href = URL.createObjectURL(blob);
                            link.setAttribute("download", filename);
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                        })
                        .catch((err) => {
                            setTexto(t("reports.donwloadingReptError"));
                            seAutoAlertType("warning");
                            setShow(true);
                        });
                } else if (reportSelectedClient === 1) {
                    await fetch("/generateServiceReport", {
                        method: "GET",
                        headers: {
                            "Content-type": "application/json; charset=UTF-8",
                            "Access-Control-Allow-Origin": "*",
                        },
                    })
                        .then((res) => res.blob())
                        .then((blob) => {
                            const link = document.createElement("a");
                            link.href = URL.createObjectURL(blob);
                            link.setAttribute("download", filename);
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                        })
                        .catch((err) => {
                            setTexto(t("reports.donwloadingReptError"));
                            seAutoAlertType("warning");
                            setShow(true);
                        });
                } else {
                    await fetch("/generateContactsReport", {
                        method: "GET",
                        headers: {
                            "Content-type": "application/json; charset=UTF-8",
                            "Access-Control-Allow-Origin": "*",
                        },
                    })
                        .then((res) => res.blob())
                        .then((blob) => {
                            const link = document.createElement("a");
                            link.href = URL.createObjectURL(blob);
                            link.setAttribute("download", filename);
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                        })
                        .catch((err) => {
                            setTexto(t("reports.donwloadingReptError"));
                            seAutoAlertType("warning");
                            setShow(true);
                        });
                }
                break;
            case 2:
                option =
                    reportSelected === 1
                        ? "resumido"
                        : reportSelected === 0
                        ? "detallado"
                        : reportSelected === 2
                        ? "resumidos"
                        : reportSelected === 3
                        ? "detallados"
                        : "";
                filename = `Plataforma GCA - ${
                    reportSelected === 0 || reportSelected === 1 ? "Facturación" : "Egresos"
                } ${option} (${dateFileName}).xlsx`;
                if (reportSelected === 0 || reportSelected === 1) {
                    await fetch(`/generateInvoicesReport?reportType=${reportSelected}`, {
                        method: "GET",
                        headers: {
                            "Content-type": "application/json; charset=UTF-8",
                            "Access-Control-Allow-Origin": "*",
                        },
                    })
                        .then((res) => res.blob())
                        .then((blob) => {
                            const link = document.createElement("a");
                            link.href = URL.createObjectURL(blob);
                            link.setAttribute("download", filename);
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                        })
                        .catch((err) => {
                            setTexto(t("reports.donwloadingReptError"));
                            seAutoAlertType("warning");
                            setShow(true);
                        });
                    break;
                } else {
                    await fetch(`/generateExpensesReport?year=${selectedYearExpenses}&reportType=${reportSelected}`, {
                        method: "GET",
                        headers: {
                            "Content-type": "application/json; charset=UTF-8",
                            "Access-Control-Allow-Origin": "*",
                        },
                    })
                        .then((res) => res.blob())
                        .then((blob) => {
                            const link = document.createElement("a");
                            link.href = URL.createObjectURL(blob);
                            link.setAttribute("download", filename);
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                        })
                        .catch((err) => {
                            setTexto(t("reports.donwloadingReptError"));
                            seAutoAlertType("warning");
                            setShow(true);
                        });
                    break;
                }
            case 3:
                option = reportSelectedReq === 0 ? "resumido" : reportSelectedReq === 1 ? "detallado" : "configuración";
                filename = `Plataforma GCA - Requerimientos ${option} (${dateFileName}).xlsx`;

                if (reportSelectedReq === 0) {
                    await fetch(`/getRequirementsResume?year=${moment().year()}&report=true`, {
                        method: "GET",
                        headers: {
                            "Content-type": "application/json; charset=UTF-8",
                            "Access-Control-Allow-Origin": "*",
                        },
                    })
                        .then((res) => res.blob())
                        .then((blob) => {
                            const link = document.createElement("a");
                            link.href = URL.createObjectURL(blob);
                            link.setAttribute("download", filename);
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                        })
                        .catch((err) => {
                            setTexto(t("reports.donwloadingReptError"));
                            seAutoAlertType("warning");
                            setShow(true);
                        });
                } else if (reportSelectedReq === 1) {
                    await fetch(`/getRequirementsDetailed?year=${moment().year()}&report=true`, {
                        method: "GET",
                        headers: {
                            "Content-type": "application/json; charset=UTF-8",
                            "Access-Control-Allow-Origin": "*",
                        },
                    })
                        .then((res) => res.blob())
                        .then((blob) => {
                            const link = document.createElement("a");
                            link.href = URL.createObjectURL(blob);
                            link.setAttribute("download", filename);
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                        })
                        .catch((err) => {
                            setTexto(t("reports.donwloadingReptError"));
                            seAutoAlertType("warning");
                            setShow(true);
                        });
                } else {
                    await fetch(`/generateReportRequirementConfig`, {
                        method: "GET",
                        headers: {
                            "Content-type": "application/json; charset=UTF-8",
                            "Access-Control-Allow-Origin": "*",
                        },
                    })
                        .then((res) => res.blob())
                        .then((blob) => {
                            const link = document.createElement("a");
                            link.href = URL.createObjectURL(blob);
                            link.setAttribute("download", filename);
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                        })
                        .catch((err) => {
                            setTexto(t("reports.donwloadingReptError"));
                            seAutoAlertType("warning");
                            setShow(true);
                        });
                }
                break;
            case 4:
                switch (reportSelectedClosures) {
                    case 0:
                        await fetch("/generateAnalyticsMonitoringReport", {
                            method: "GET",
                            headers: {
                                "Content-type": "application/json; charset=UTF-8",
                                "Access-Control-Allow-Origin": "*",
                            },
                        })
                            .then(() => {
                                setTexto(t("clientsTable.generatingReport"));
                                seAutoAlertType("success");
                                setShow(true);
                            })
                            .catch((err) => {
                                setTexto(err.error);
                                seAutoAlertType("error");
                                setShow(true);
                            });
                        break;
                    case 1:
                        filename = `Plataforma GCA - Validaciones bancarias resumido (${new Date().getFullYear()}) ${dateFileName}.xlsx`;

                        await fetch(`/reportBankAccountsValidations`, {
                            method: "GET",
                            headers: {
                                "Content-type": "application/json; charset=UTF-8",
                                "Access-Control-Allow-Origin": "*",
                            },
                        })
                            .then((res) => res.blob())
                            .then((blob) => {
                                const link = document.createElement("a");
                                link.href = URL.createObjectURL(blob);
                                link.setAttribute("download", filename);
                                document.body.appendChild(link);
                                link.click();
                                document.body.removeChild(link);
                            })
                            .catch((err) => {
                                setTexto(t("reports.donwloadingReptError"));
                                seAutoAlertType("warning");
                                setShow(true);
                            });
                        break;
                    case 2:
                        filename = `Plataforma GCA - Validaciones bancarias detallado (${selectedYear}) ${dateFileName}.xlsx`;

                        await fetch(`/getBankAccountsValidationsReport?year=${selectedYear}&report=true`, {
                            method: "GET",
                            headers: {
                                "Content-type": "application/json; charset=UTF-8",
                                "Access-Control-Allow-Origin": "*",
                            },
                        })
                            .then((res) => res.blob())
                            .then((blob) => {
                                const link = document.createElement("a");
                                link.href = URL.createObjectURL(blob);
                                link.setAttribute("download", filename);
                                document.body.appendChild(link);
                                link.click();
                                document.body.removeChild(link);
                            })
                            .catch((err) => {
                                setTexto(t("reports.donwloadingReptError"));
                                seAutoAlertType("warning");
                                setShow(true);
                            });
                        break;

                    case 3:
                        filename = `Plataforma GCA - Histórico de cierres (${selectedYear}) ${dateFileName}.xlsx`;

                        await fetch(`/generateClosuresReport?year=${selectedYear}&report=true`, {
                            method: "GET",
                            headers: {
                                "Content-type": "application/json; charset=UTF-8",
                                "Access-Control-Allow-Origin": "*",
                            },
                        })
                            .then((res) => res.blob())
                            .then((blob) => {
                                const link = document.createElement("a");
                                link.href = URL.createObjectURL(blob);
                                link.setAttribute("download", filename);
                                document.body.appendChild(link);
                                link.click();
                                document.body.removeChild(link);
                            })
                            .catch((err) => {
                                setTexto(t("reports.donwloadingReptError"));
                                seAutoAlertType("warning");
                                setShow(true);
                            });
                        break;
                    case 4:
                        const franqName = franqData.find((item) => item.id === franqValue).franchise_name;
                        filename = `Plataforma GCA - Estatus firmas (${franqName}) ${dateFileName}.pdf`;

                        await fetch(
                            `/generateMonitoringReport?practice_id=&franchise_id=${franqValue}&year=${selectedYear}&portal=external&service_type=Bookkeeper&client_status=&contact_email=&client_type=&lang=${t(
                                "language.locale"
                            )}`,
                            {
                                method: "GET",
                                headers: {
                                    "Content-type": "application/json; charset=UTF-8",
                                    "Access-Control-Allow-Origin": "*",
                                },
                            }
                        )
                            .then((res) => res.blob())
                            .then((blob) => {
                                const link = document.createElement("a");
                                link.href = URL.createObjectURL(blob);
                                link.setAttribute("download", filename);
                                document.body.appendChild(link);
                                link.click();
                                document.body.removeChild(link);
                            })
                            .catch((err) => {
                                setTexto(t("reports.donwloadingReptError"));
                                seAutoAlertType("warning");
                                setShow(true);
                            });
                        break;
                    default:
                        if (groupValue || (franqValue && franqValue !== "N/A")) {
                            filename = `Plataforma GCA - Transacciones sin cateoría ${dateFileName}.pdf`;

                            await fetch(
                                `/uncategorizedTransReport?practice_id=${groupValue}&franchise_id=${franqValue}`,
                                {
                                    method: "GET",
                                    headers: {
                                        "Content-type": "application/json; charset=UTF-8",
                                        "Access-Control-Allow-Origin": "*",
                                    },
                                }
                            )
                                .then(() => {
                                    setTexto(t("clientsTable.generatingReport"));
                                    seAutoAlertType("success");
                                    setShow(true);
                                })
                                .catch((err) => {
                                    setTexto(err.error);
                                    seAutoAlertType("error");
                                    setShow(true);
                                });
                        } else {
                            setTexto(t("miscellaneous.mustSelectOne"));
                            seAutoAlertType("warning");
                            setShow(true);
                        }
                        break;
                }
                break;
            default:
                break;
        }

        setBackDrop(false);
    };

    useEffect(() => {
        const validateDisabled = () => {
            let tab = team.includes(todos.userInfo.email) ? 4 : 3;
            if(valueTab === tab && reportSelectedClosures === 4 && !franqValue) {
                setDisabledDownloadReport(true);
            } else {
                setDisabledDownloadReport(false);
            };
        };
        validateDisabled();
    }, [valueTab, franqValue, todos.userInfo.email, reportSelectedClosures]);

    return (
        <StyledContainer component="main" sx={{ flexGrow: 1, p: 4, width: "80%", pt: 10 }}>
            <Stack sx={{ pb: 4 }}>
                <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small" sx={{ color: "#031851" }} />}
                    aria-label="breadcrumb"
                >
                    {breadcrumbs}
                </Breadcrumbs>
            </Stack>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card
                        elevation={0}
                        sx={{
                            borderRadius: 8,
                            boxShadow: "10px 10px 15px rgba(3,24,81,0.15)",
                        }}
                    >
                        <CardContent classes={{ root: classes.cardContentRoot }}>
                            <Box sx={{ width: "100%" }}>
                                <Box sx={{ borderBottom: 2, borderColor: "#031851", pb: { xs: 2, sm: 0 } }}>
                                    <Grid container alignItems="center" spacing={2}>
                                        <Grid item xs={10}>
                                            <Tabs
                                                value={valueTab}
                                                onChange={handleChangeTab}
                                                variant="scrollable"
                                                scrollButtons="auto"
                                                aria-label="bookkeper tabs"
                                                TabIndicatorProps={{
                                                    sx: {
                                                        display: "none",
                                                    },
                                                }}
                                            >
                                                <CustomTab label={t("proposals.proposals")} {...a11yProps(0)} />
                                                <CustomTab label={t("principal.admCustom")} {...a11yProps(1)} />
                                                {team.includes(todos.userInfo.email) && (
                                                    <CustomTab label={t("miscellaneous.invoicing")} {...a11yProps(2)} />
                                                )}
                                                <CustomTab
                                                    label={t("miscellaneous.requirements")}
                                                    {...a11yProps(team.includes(todos.userInfo.email) ? 3 : 2)}
                                                />
                                                <CustomTab
                                                    label={t("generalDashboard.accountingClosures")}
                                                    {...a11yProps(team.includes(todos.userInfo.email) ? 4 : 3)}
                                                />
                                            </Tabs>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Grid container justifyContent="flex-end">
                                                <Grid item>
                                                    <LightTooltip title={t("reports.downReport")} aria-label="down">
                                                        <Button
                                                            variant="contained"
                                                            color="secondary"
                                                            disableElevation
                                                            disabled={disabledDownloadReport}
                                                            onClick={() => downloadReport(valueTab)}
                                                            sx={{
                                                                pl: 3,
                                                                pr: 3,
                                                                borderRadius: 40,
                                                                "&:hover": {
                                                                    backgroundColor: "#2f3190",
                                                                    color: "#fff",
                                                                },
                                                                "&.Mui-disabled": {
                                                                    color: "#fff",
                                                                    borderColor: "#ffcdd2",
                                                                    background: "#ffcdd2",
                                                                },
                                                            }}
                                                        >
                                                            {t("dialogConversions.download")}
                                                        </Button>
                                                    </LightTooltip>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <TabPanel value={valueTab} index={0}>
                                    <Proposals
                                        reportSelected={reportSelectedProp}
                                        setReportSelected={setReportSelectedProp}
                                    />
                                </TabPanel>
                                <TabPanel value={valueTab} index={1}>
                                    <MainData
                                        reportSelected={reportSelectedClient}
                                        setReportSelected={setReportSelectedClient}
                                    />
                                </TabPanel>
                                {team.includes(todos.userInfo.email) && (
                                    <TabPanel value={valueTab} index={2}>
                                        <Invoicing
                                            reportSelected={reportSelected}
                                            setReportSelected={setReportSelected}
                                            selectedYear={selectedYearExpenses}
                                            setSelectedYear={setSelectedYearExpenses}
                                        />
                                    </TabPanel>
                                )}
                                <TabPanel value={valueTab} index={team.includes(todos.userInfo.email) ? 3 : 2}>
                                    <Requirements
                                        reportSelected={reportSelectedReq}
                                        setReportSelected={setReportSelectedReq}
                                    />
                                </TabPanel>
                                <TabPanel value={valueTab} index={team.includes(todos.userInfo.email) ? 4 : 3}>
                                    <Monitoring
                                        reportSelected={reportSelectedClosures}
                                        setReportSelected={setReportSelectedClosures}
                                        selectedYear={selectedYear}
                                        setSelectedYear={setSelectedYear}
                                        franqValue={franqValue}
                                        setFranqValue={setFranqValue}
                                        franqData={franqData}
                                        setFranqData={setFranqData}
                                        groupValue={groupValue}
                                        setGroupValue={setGroupValue}
                                        groupData={groupData}
                                        setGroupData={setGroupData}
                                    />
                                </TabPanel>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <SnackBar type={alertType} show={show} message={texto} onClose={handleClose} />
            <Backdrop open={showBackdrop} />
        </StyledContainer>
    );
};

export default AnalytReports;
