/** React imports */
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

/** Images */

/** MUI icons */
/** MUI imports */
import { Box, Typography, Grid, Divider } from "@mui/material";
import { styled } from "@mui/material/styles";

/** Global variables */
const PREFIX = "PricingBook";

const classes = {
    body2: `${PREFIX}-body2`,
    serviceName: `${PREFIX}-serviceName`,
    subName: `${PREFIX}-subName`,
    nameSMB: `${PREFIX}-nameSMB`,
    serviceBody: `${PREFIX}-serviceBody`,
    subInfo: `${PREFIX}-subInfo`,
    roundBack: `${PREFIX}-roundBack`,
    roundBack2: `${PREFIX}-roundBack2`,
    roundBack3: `${PREFIX}-roundBack3`,
    roundBackMain: `${PREFIX}-roundBackMain`,
    roundBackMain2: `${PREFIX}-roundBackMain2`,
    months: `${PREFIX}-months`,
    monthsText1: `${PREFIX}-monthsText1`,
    monthsText2: `${PREFIX}-monthsText2`,
    body3: `${PREFIX}-body3`,
    textFoot: `${PREFIX}-textFoot`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.serviceName}`]: {
        fontSize: 22,
        marginBottom: 20,
    },

    [`& .${classes.subName}`]: {
        fontSize: 18,
        marginBottom: 20,
    },

    [`& .${classes.nameSMB}`]: {
        fontSize: 10,
    },

    [`& .${classes.body2}`]: {
        backgroundColor: "white",
        marginTop: 15,
        padding: 30,
        minHeight: 850,
    },

    [`& .${classes.body3}`]: {
        padding: 30,
    },

    [`& .${classes.textFoot}`]: {
        padding: "15px 30px",
    },

    [`& .${classes.serviceBody}`]: {
        backgroundColor: "#F8F9F8",
        marginTop: 30,
        padding: 30,
        borderRadius: 30,
        overflow: "auto",
    },

    [`& .${classes.subInfo}`]: {
        marginTop: 30,
        fontSize: 12,
    },

    [`& .${classes.roundBack}`]: {
        backgroundColor: "#6D70AD",
        minWidth: "125px",
        marginRight: "-27px",
        justifyContent: "center",
        display: "flex",
    },

    [`& .${classes.roundBack2}`]: {
        backgroundColor: "#2D348B",
        minWidth: "130px",
        justifyContent: "center",
        display: "flex",
    },

    [`& .${classes.roundBack3}`]: {
        color: "#031851",
        minWidth: "115px",
        marginRight: "-25px",
        justifyContent: "center",
        display: "flex",
    },

    [`& .${classes.roundBackMain}`]: {
        backgroundColor: "#6D70AD",
        fontSize: 10,
        width: 200,
        paddingLeft: 60,
        paddingRight: "35px",
        marginRight: "-25px",
        [theme.breakpoints.down("md")]: {
            width: 170,
        },
    },
    [`& .${classes.roundBackMain2}`]: {
        backgroundColor: "#2D348B",
        fontSize: 10,
        width: 180,
        paddingRight: 25,
        paddingLeft: 60,
        marginRight: 15,
        [theme.breakpoints.down("md")]: {
            width: 150,
        },
    },

    [`& .${classes.months}`]: {
        fontSize: 14,
    },
    [`& .${classes.monthsText1}`]: {
        fontSize: 16,
        justifyContent: "center",
        display: "flex",
    },
    [`& .${classes.monthsText2}`]: {
        fontSize: 16,
        justifyContent: "center",
        display: "flex",
        marginRight: "-25px",
    },
}));

// Función para convertir primera letra en mayúscula
function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const PricingBook = (props) => {
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    /** React redux */
    const todos = useSelector((state) => state.todos);

    const { pricingBook } = props;

    const getNamesAdd = (values) => {
        let name = "";
        if (values === "Checks") {
            name = t("proposals.checksB");
        } else if (values.additional_name === "A/R") {
            name = t("proposals.addARB");
        } else if (values.additional_name === "A/P") {
            name = t("proposals.addAPB");
        } else if (values.additional_name === "Classes/Categories accounting") {
            name = t("services.classesCategoriesB");
        } else if (values.additional_name === "Multi-entity accounting") {
            name = t("proposals.multiEntityB");
        } else if (values.additional_name === "Accrual") {
            name = t("services.accrualAccountingB");
        } else if (values === "Personalized reporting") {
            name = t("proposals.addPerRepB");
        } else if (values.additional_name === "Others") {
            name = values.other_name;
        } else {
            name = values.additional_name;
        }

        return name;
    };

    return (
        <StyledContainer>
            {pricingBook.onboarding.isActive && (
                <>
                    <Divider
                        sx={{
                            backgroundColor: "#031851",
                        }}
                    />
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={2}
                        sx={{ paddingBottom: 1.5, paddingTop: 1.5 }}
                    >
                        <Grid item xs={3}>
                            <Typography variant="h4">Onboarding</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="body1" sx={{ textAlign: "center" }}>
                                {t(`proposals.oneTime2B`)}
                            </Typography>
                        </Grid>
                        <Grid item xs={1} sx={{ textAlign: "center" }}>
                            <Typography variant="body1">1</Typography>
                        </Grid>
                        <Grid item xs={1} sx={{ textAlign: "right" }}>
                            <Typography variant="body1">
                                $
                                {parseFloat((pricingBook?.prospect?.company_legal_name === "Nava School of Business" ? 95 : pricingBook.onboarding.fee)).toLocaleString(todos.amountFormat, {
                                    maximumFractionDigits: 2,
                                })}
                            </Typography>
                        </Grid>
                        <Grid item xs={2} sx={{ textAlign: "right" }}>
                            <Typography variant="body1">
                                $
                                {parseFloat((pricingBook?.prospect?.company_legal_name === "Nava School of Business" ? 95 : pricingBook.onboarding.fee)).toLocaleString(todos.amountFormat, {
                                    maximumFractionDigits: 2,
                                })}
                            </Typography>
                        </Grid>
                        <Grid item xs={1} sx={{ textAlign: "center" }}>
                            <Typography variant="body1">
                                {parseFloat(pricingBook.onboarding.discount).toLocaleString(todos.amountFormat, {
                                    maximumFractionDigits: 2,
                                })}
                                %
                            </Typography>
                        </Grid>
                        <Grid item xs={2} sx={{ textAlign: "right" }}>
                            <Typography variant="body1">
                                $
                                {parseFloat(
                                    (pricingBook?.prospect?.company_legal_name === "Nava School of Business" ? 95 : pricingBook.onboarding.fee) * (1 - pricingBook.onboarding.discount / 100)
                                ).toLocaleString(todos.amountFormat, {
                                    maximumFractionDigits: 2,
                                })}
                            </Typography>
                        </Grid>
                    </Grid>
                </>
            )}
            {pricingBook.recurring.isActive && (
                <>
                    <Divider
                        sx={{
                            backgroundColor: "#031851",
                        }}
                    />
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={2}
                        sx={{ paddingBottom: 1.5, paddingTop: 1.5 }}
                    >
                        <Grid item xs={3}>
                            <Typography variant="h4">
                                {t("proposals.recurringB") +
                                    ` (${capitalize(
                                        pricingBook.recurring.initDate.lang(t("language.localeB")).format("MMM YYYY")
                                    ).replace(/\./g, "")})`}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="body1" sx={{ textAlign: "center" }}>
                                {t("miscellaneous.monthlyB")}
                            </Typography>
                        </Grid>
                        <Grid item xs={1} sx={{ textAlign: "center" }}>
                            <Typography variant="body1">
                                {pricingBook.recurring.additionals.length > 0 && !pricingBook.recurring.onlyAdd
                                    ? "-"
                                    : pricingBook.recurring.qty}
                            </Typography>
                        </Grid>
                        <Grid item xs={1} sx={{ textAlign: "right" }}>
                            <Typography variant="body1">
                                {pricingBook.recurring.additionals.length > 0 && !pricingBook.recurring.onlyAdd
                                    ? "-"
                                    : "$" + (
                                        pricingBook?.prospect?.company_legal_name === "Nava School of Business" ?
                                        parseFloat(95).toLocaleString(
                                            todos.amountFormat,
                                            {
                                                maximumFractionDigits: 2,
                                            }
                                        )
                                        : 
                                        parseFloat(pricingBook.recurring.fee).toLocaleString(
                                            todos.amountFormat,
                                            {
                                                maximumFractionDigits: 2,
                                            }
                                        )
                                    )
                                }
                            </Typography>
                        </Grid>
                        <Grid item xs={2} sx={{ textAlign: "right" }}>
                            <Typography variant="body1">
                                {"$" +
                                    parseFloat(
                                        (pricingBook?.prospect?.company_legal_name === "Nava School of Business" ? 95 : pricingBook.recurring.fee) * pricingBook.recurring.qty +
                                        pricingBook.recurring.recuHours * pricingBook.recurring.addFee
                                    ).toLocaleString(todos.amountFormat, {
                                        maximumFractionDigits: 2,
                                    })}
                            </Typography>
                        </Grid>
                        <Grid item xs={1} sx={{ textAlign: "center" }}>
                            <Typography variant="body1">
                                {parseFloat(pricingBook.recurring.discount).toLocaleString(todos.amountFormat, {
                                    maximumFractionDigits: 2,
                                }) + "%"}
                            </Typography>
                        </Grid>
                        <Grid item xs={2} sx={{ textAlign: "right" }}>
                            <Typography variant="body1">
                                $
                                {parseFloat(
                                    ((pricingBook?.prospect?.company_legal_name === "Nava School of Business" ? 95 : pricingBook.recurring.fee) * pricingBook.recurring.qty +
                                        pricingBook.recurring.recuHours * pricingBook.recurring.addFee) *
                                    (1 - pricingBook.recurring.discount / 100)
                                ).toLocaleString(todos.amountFormat, {
                                    maximumFractionDigits: 2,
                                })}
                            </Typography>
                        </Grid>
                    </Grid>
                    {pricingBook.recurring.additionals.length > 0 && !pricingBook.recurring.onlyAdd && (
                        <>
                            <Divider
                                sx={{
                                    backgroundColor: "#031851",
                                }}
                            />
                            <Grid
                                container
                                alignItems="center"
                                justifyContent="space-between"
                                spacing={2}
                                sx={{ paddingBottom: 1.5, paddingTop: 1.5 }}
                            >
                                <Grid item xs={3}>
                                    <Typography variant="body1" sx={{ paddingLeft: 6, wordBreak: "break-word" }}>
                                        {t("proposals.coreServB")}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}></Grid>
                                <Grid item xs={1} sx={{ textAlign: "center" }}>
                                    <Typography variant="body1">{pricingBook.recurring.qty}</Typography>
                                </Grid>
                                <Grid item xs={1} sx={{ textAlign: "right" }}>
                                    <Typography variant="body1">
                                        $
                                        {parseFloat(pricingBook.recurring.fee).toLocaleString(todos.amountFormat, {
                                            maximumFractionDigits: 2,
                                        })}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2} sx={{ textAlign: "right" }}>
                                    <Typography variant="body1">
                                        $
                                        {parseFloat(
                                            pricingBook.recurring.fee * pricingBook.recurring.qty
                                        ).toLocaleString(todos.amountFormat, {
                                            maximumFractionDigits: 2,
                                        })}
                                    </Typography>
                                </Grid>
                                <Grid item xs={1} sx={{ textAlign: "center" }}>
                                    <Typography variant="body1">
                                        {parseFloat(pricingBook.recurring.discount).toLocaleString(todos.amountFormat, {
                                            maximumFractionDigits: 2,
                                        })}
                                        %
                                    </Typography>
                                </Grid>
                                <Grid item xs={2} sx={{ textAlign: "right" }}>
                                    <Typography variant="body1">
                                        $
                                        {parseFloat(
                                            pricingBook.recurring.fee *
                                            pricingBook.recurring.qty *
                                            (1 - pricingBook.recurring.discount / 100)
                                        ).toLocaleString(todos.amountFormat, {
                                            maximumFractionDigits: 2,
                                        })}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider
                                sx={{
                                    backgroundColor: "#031851",
                                }}
                            />
                            <Grid
                                container
                                alignItems="center"
                                justifyContent="space-between"
                                spacing={2}
                                sx={{ paddingBottom: 1.5, paddingTop: 1.5 }}
                            >
                                <Grid item xs={3}>
                                    <Typography variant="body1" sx={{ paddingLeft: 6, wordBreak: "break-word" }}>
                                        {t("proposals.addonsB")}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}></Grid>
                                <Grid item xs={1} sx={{ textAlign: "center" }}>
                                    <Typography variant="body1">{pricingBook.recurring.recuHours}</Typography>
                                </Grid>
                                <Grid item xs={1} sx={{ textAlign: "right" }}>
                                    <Typography variant="body1">
                                        $
                                        {parseFloat(pricingBook.recurring.addFee).toLocaleString(todos.amountFormat, {
                                            maximumFractionDigits: 2,
                                        })}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2} sx={{ textAlign: "right" }}>
                                    <Typography variant="body1">
                                        $
                                        {parseFloat(
                                            pricingBook.recurring.recuHours * pricingBook.recurring.addFee
                                        ).toLocaleString(todos.amountFormat, {
                                            maximumFractionDigits: 2,
                                        })}
                                    </Typography>
                                </Grid>
                                <Grid item xs={1} sx={{ textAlign: "center" }}>
                                    <Typography variant="body1">
                                        {parseFloat(pricingBook.recurring.discount).toLocaleString(todos.amountFormat, {
                                            maximumFractionDigits: 2,
                                        })}
                                        %
                                    </Typography>
                                </Grid>
                                <Grid item xs={2} sx={{ textAlign: "right" }}>
                                    <Typography variant="body1">
                                        $
                                        {parseFloat(
                                            pricingBook.recurring.recuHours *
                                            pricingBook.recurring.addFee *
                                            (1 - pricingBook.recurring.discount / 100)
                                        ).toLocaleString(todos.amountFormat, {
                                            maximumFractionDigits: 2,
                                        })}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </>
                    )}
                    {pricingBook.recurring.additionals.map(
                        (value) =>
                            value.additional_name !== "Bookkeeper" && (
                                <>
                                    <Divider
                                        sx={{
                                            backgroundColor: "#031851",
                                        }}
                                    />
                                    <Grid
                                        container
                                        alignItems="center"
                                        justifyContent="space-between"
                                        spacing={2}
                                        sx={{ paddingBottom: 1.5, paddingTop: 1.5 }}
                                    >
                                        <Grid item xs={3}>
                                            <Typography
                                                variant="body1"
                                                sx={{ paddingLeft: 8, wordBreak: "break-word" }}
                                            >
                                                {value.other_name ?? getNamesAdd(value)}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2}></Grid>
                                        <Grid item xs={1} sx={{ textAlign: "center" }}>
                                            <Typography variant="body1">{value.hours}</Typography>
                                        </Grid>
                                        <Grid item xs={1} sx={{ textAlign: "right" }}>
                                            <Typography variant="body1">
                                                $
                                                {parseFloat(pricingBook.recurring.addFee).toLocaleString(
                                                    todos.amountFormat,
                                                    {
                                                        maximumFractionDigits: 2,
                                                    }
                                                )}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2} sx={{ textAlign: "right" }}>
                                            <Typography variant="body1">
                                                $
                                                {parseFloat(pricingBook.recurring.addFee * value.hours).toLocaleString(
                                                    todos.amountFormat,
                                                    {
                                                        maximumFractionDigits: 2,
                                                    }
                                                )}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={1} sx={{ textAlign: "center" }}>
                                            <Typography variant="body1">
                                                {parseFloat(pricingBook.recurring.discount).toLocaleString(
                                                    todos.amountFormat,
                                                    {
                                                        maximumFractionDigits: 2,
                                                    }
                                                )}
                                                %
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2} sx={{ textAlign: "right" }}>
                                            <Typography variant="body1">
                                                $
                                                {parseFloat(
                                                    pricingBook.recurring.addFee *
                                                    value.hours *
                                                    (1 - pricingBook.recurring.discount / 100)
                                                ).toLocaleString(todos.amountFormat, {
                                                    maximumFractionDigits: 2,
                                                })}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </>
                            )
                    )}
                </>
            )}
            {pricingBook.cleanUp.isActive && (
                <>
                    <Divider
                        sx={{
                            backgroundColor: "#031851",
                        }}
                    />
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={2}
                        sx={{ paddingBottom: 1.5, paddingTop: 1.5 }}
                    >
                        <Grid item xs={3}>
                            <Typography variant="h4">
                                {t("invoicing.cleanUp") +
                                    ` (${capitalize(
                                        pricingBook.cleanUp.initDate.lang(t("language.localeB")).format("MMM")
                                    ).replace(/\./g, "") +
                                    "-" +
                                    capitalize(
                                        pricingBook.cleanUp.untilDate.lang(t("language.localeB")).format("MMM YYYY")
                                    ).replace(/\./g, "")
                                    })`}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="body1" sx={{ textAlign: "center" }}>
                                {t("proposals.oneTime2B")}
                            </Typography>
                        </Grid>
                        <Grid item xs={1} sx={{ textAlign: "center" }}>
                            <Typography variant="body1">
                                {pricingBook.cleanUp.additionals.length > 0 && !pricingBook.cleanUp.onlyAdd
                                    ? "-"
                                    : pricingBook.cleanUp.monthQty}
                            </Typography>
                        </Grid>
                        <Grid item xs={1} sx={{ textAlign: "right" }}>
                            <Typography variant="body1">
                                {pricingBook.cleanUp.additionals.length > 0 && !pricingBook.cleanUp.onlyAdd
                                    ? "-"
                                    : "$" +
                                    parseFloat(pricingBook.cleanUp.fee).toLocaleString(todos.amountFormat, {
                                        maximumFractionDigits: 2,
                                    })}
                            </Typography>
                        </Grid>
                        <Grid item xs={2} sx={{ textAlign: "right" }}>
                            <Typography variant="body1">
                                {pricingBook.cleanUp.additionals.length > 0 && !pricingBook.cleanUp.onlyAdd
                                    ? "-"
                                    : "$" +
                                    parseFloat(pricingBook.cleanUp.fee * pricingBook.cleanUp.monthQty).toLocaleString(
                                        todos.amountFormat,
                                        {
                                            maximumFractionDigits: 2,
                                        }
                                    )}
                            </Typography>
                        </Grid>
                        <Grid item xs={1} sx={{ textAlign: "center" }}>
                            <Typography variant="body1">
                                {pricingBook.cleanUp.additionals.length > 0 && !pricingBook.cleanUp.onlyAdd
                                    ? "-"
                                    : parseFloat(pricingBook.cleanUp.discount).toLocaleString(todos.amountFormat, {
                                        maximumFractionDigits: 2,
                                    }) + "%"}
                            </Typography>
                        </Grid>
                        <Grid item xs={2} sx={{ textAlign: "right" }}>
                            <Typography variant="body1">
                                $
                                {parseFloat(
                                    (pricingBook.cleanUp.fee * pricingBook.cleanUp.monthQty +
                                        pricingBook.cleanUp.totalHours * pricingBook.cleanUp.addFee) *
                                    (1 - pricingBook.cleanUp.discount / 100)
                                ).toLocaleString(todos.amountFormat, {
                                    maximumFractionDigits: 2,
                                })}
                            </Typography>
                        </Grid>
                    </Grid>
                    {pricingBook.cleanUp.additionals.length > 0 && !pricingBook.cleanUp.onlyAdd && (
                        <>
                            <Divider
                                sx={{
                                    backgroundColor: "#031851",
                                }}
                            />
                            <Grid
                                container
                                alignItems="center"
                                justifyContent="space-between"
                                spacing={2}
                                sx={{ paddingBottom: 1.5, paddingTop: 1.5 }}
                            >
                                <Grid item xs={3}>
                                    <Typography variant="body1" sx={{ paddingLeft: 6, wordBreak: "break-word" }}>
                                        {t("proposals.coreServB")}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}></Grid>
                                <Grid item xs={1} sx={{ textAlign: "center" }}>
                                    <Typography variant="body1">{pricingBook.cleanUp.monthQty}</Typography>
                                </Grid>
                                <Grid item xs={1} sx={{ textAlign: "right" }}>
                                    <Typography variant="body1">
                                        $
                                        {parseFloat(pricingBook.cleanUp.fee).toLocaleString(todos.amountFormat, {
                                            maximumFractionDigits: 2,
                                        })}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2} sx={{ textAlign: "right" }}>
                                    <Typography variant="body1">
                                        $
                                        {parseFloat(
                                            pricingBook.cleanUp.fee * pricingBook.cleanUp.monthQty
                                        ).toLocaleString(todos.amountFormat, {
                                            maximumFractionDigits: 2,
                                        })}
                                    </Typography>
                                </Grid>
                                <Grid item xs={1} sx={{ textAlign: "center" }}>
                                    <Typography variant="body1">
                                        {parseFloat(pricingBook.cleanUp.discount).toLocaleString(todos.amountFormat, {
                                            maximumFractionDigits: 2,
                                        })}
                                        %
                                    </Typography>
                                </Grid>
                                <Grid item xs={2} sx={{ textAlign: "right" }}>
                                    <Typography variant="body1">
                                        $
                                        {parseFloat(
                                            pricingBook.cleanUp.fee *
                                            pricingBook.cleanUp.monthQty *
                                            (1 - pricingBook.cleanUp.discount / 100)
                                        ).toLocaleString(todos.amountFormat, {
                                            maximumFractionDigits: 2,
                                        })}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider
                                sx={{
                                    backgroundColor: "#031851",
                                }}
                            />
                            <Grid
                                container
                                alignItems="center"
                                justifyContent="space-between"
                                spacing={2}
                                sx={{ paddingBottom: 1.5, paddingTop: 1.5 }}
                            >
                                <Grid item xs={3}>
                                    <Typography variant="body1" sx={{ paddingLeft: 6, wordBreak: "break-word" }}>
                                        {t("proposals.addonsB")}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}></Grid>
                                <Grid item xs={1} sx={{ textAlign: "center" }}>
                                    <Typography variant="body1">{pricingBook.cleanUp.totalHours}</Typography>
                                </Grid>
                                <Grid item xs={1} sx={{ textAlign: "right" }}>
                                    <Typography variant="body1">
                                        $
                                        {parseFloat(pricingBook.cleanUp.addFee).toLocaleString(todos.amountFormat, {
                                            maximumFractionDigits: 2,
                                        })}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2} sx={{ textAlign: "right" }}>
                                    <Typography variant="body1">
                                        $
                                        {parseFloat(
                                            pricingBook.cleanUp.totalHours * pricingBook.cleanUp.addFee
                                        ).toLocaleString(todos.amountFormat, {
                                            maximumFractionDigits: 2,
                                        })}
                                    </Typography>
                                </Grid>
                                <Grid item xs={1} sx={{ textAlign: "center" }}>
                                    <Typography variant="body1">
                                        {parseFloat(pricingBook.cleanUp.discount).toLocaleString(todos.amountFormat, {
                                            maximumFractionDigits: 2,
                                        })}
                                        %
                                    </Typography>
                                </Grid>
                                <Grid item xs={2} sx={{ textAlign: "right" }}>
                                    <Typography variant="body1">
                                        $
                                        {parseFloat(
                                            pricingBook.cleanUp.totalHours *
                                            pricingBook.cleanUp.addFee *
                                            (1 - pricingBook.cleanUp.discount / 100)
                                        ).toLocaleString(todos.amountFormat, {
                                            maximumFractionDigits: 2,
                                        })}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </>
                    )}
                    {pricingBook.cleanUp.additionals.map(
                        (value) =>
                            value.additional_name !== "Bookkeeper" && (
                                <>
                                    <Divider
                                        sx={{
                                            backgroundColor: "#031851",
                                        }}
                                    />
                                    <Grid
                                        container
                                        alignItems="center"
                                        justifyContent="space-between"
                                        spacing={2}
                                        sx={{ paddingBottom: 1.5, paddingTop: 1.5 }}
                                    >
                                        <Grid item xs={3}>
                                            <Typography
                                                variant="body1"
                                                sx={{ paddingLeft: 8, wordBreak: "break-word" }}
                                            >
                                                {value.other_name ?? getNamesAdd(value)}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2}></Grid>
                                        <Grid item xs={1} sx={{ textAlign: "center" }}>
                                            <Typography variant="body1">{value.hours}</Typography>
                                        </Grid>
                                        <Grid item xs={1} sx={{ textAlign: "right" }}>
                                            <Typography variant="body1">
                                                $
                                                {parseFloat(pricingBook.cleanUp.addFee).toLocaleString(
                                                    todos.amountFormat,
                                                    {
                                                        maximumFractionDigits: 2,
                                                    }
                                                )}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2} sx={{ textAlign: "right" }}>
                                            <Typography variant="body1">
                                                $
                                                {parseFloat(pricingBook.cleanUp.addFee * value.hours).toLocaleString(
                                                    todos.amountFormat,
                                                    {
                                                        maximumFractionDigits: 2,
                                                    }
                                                )}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={1} sx={{ textAlign: "center" }}>
                                            <Typography variant="body1">
                                                {parseFloat(pricingBook.cleanUp.discount).toLocaleString(
                                                    todos.amountFormat,
                                                    {
                                                        maximumFractionDigits: 2,
                                                    }
                                                )}
                                                %
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2} sx={{ textAlign: "right" }}>
                                            <Typography variant="body1">
                                                $
                                                {parseFloat(
                                                    pricingBook.cleanUp.addFee *
                                                    value.hours *
                                                    (1 - pricingBook.cleanUp.discount / 100)
                                                ).toLocaleString(todos.amountFormat, {
                                                    maximumFractionDigits: 2,
                                                })}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </>
                            )
                    )}
                </>
            )}
            {pricingBook.catchUp.isActive && (
                <>
                    <Divider
                        sx={{
                            backgroundColor: "#031851",
                        }}
                    />
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={2}
                        sx={{ paddingBottom: 1.5, paddingTop: 1.5 }}
                    >
                        <Grid item xs={3}>
                            <Typography variant="h4">
                                {t("invoicing.catchUp") +
                                    ` (${capitalize(
                                        pricingBook.catchUp.initDate.lang(t("language.localeB")).format("MMM")
                                    ).replace(/\./g, "") +
                                    "-" +
                                    capitalize(
                                        pricingBook.catchUp.untilDate.lang(t("language.localeB")).format("MMM YYYY")
                                    ).replace(/\./g, "")
                                    })`}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="body1" sx={{ textAlign: "center" }}>
                                {t("proposals.oneTime2B")}
                            </Typography>
                        </Grid>
                        <Grid item xs={1} sx={{ textAlign: "center" }}>
                            <Typography variant="body1">
                                {pricingBook.catchUp.additionals.length > 0 && !pricingBook.catchUp.onlyAdd
                                    ? "-"
                                    : pricingBook.catchUp.monthQty}
                            </Typography>
                        </Grid>
                        <Grid item xs={1} sx={{ textAlign: "right" }}>
                            <Typography variant="body1">
                                {pricingBook.catchUp.additionals.length > 0 && !pricingBook.catchUp.onlyAdd
                                    ? "-"
                                    : "$" +
                                    parseFloat(pricingBook.catchUp.fee).toLocaleString(todos.amountFormat, {
                                        maximumFractionDigits: 2,
                                    })}
                            </Typography>
                        </Grid>
                        <Grid item xs={2} sx={{ textAlign: "right" }}>
                            <Typography variant="body1">
                                {pricingBook.catchUp.additionals.length > 0 && !pricingBook.catchUp.onlyAdd
                                    ? "-"
                                    : "$" +
                                    parseFloat(
                                        pricingBook.catchUp.fee * pricingBook.catchUp.monthQty +
                                        pricingBook.catchUp.totalHours * pricingBook.catchUp.addFee
                                    ).toLocaleString(todos.amountFormat, {
                                        maximumFractionDigits: 2,
                                    })}
                            </Typography>
                        </Grid>
                        <Grid item xs={1} sx={{ textAlign: "center" }}>
                            <Typography variant="body1">
                                {pricingBook.catchUp.additionals.length > 0 && !pricingBook.catchUp.onlyAdd
                                    ? "-"
                                    : parseFloat(pricingBook.catchUp.discount).toLocaleString(todos.amountFormat, {
                                        maximumFractionDigits: 2,
                                    }) + "%"}
                            </Typography>
                        </Grid>
                        <Grid item xs={2} sx={{ textAlign: "right" }}>
                            <Typography variant="body1">
                                $
                                {parseFloat(
                                    (pricingBook.catchUp.fee * pricingBook.catchUp.monthQty +
                                        pricingBook.catchUp.totalHours * pricingBook.catchUp.addFee) *
                                    (1 - pricingBook.catchUp.discount / 100)
                                ).toLocaleString(todos.amountFormat, {
                                    maximumFractionDigits: 2,
                                })}
                            </Typography>
                        </Grid>
                    </Grid>
                    {pricingBook.catchUp.additionals.length > 0 && !pricingBook.catchUp.onlyAdd && (
                        <>
                            <Divider
                                sx={{
                                    backgroundColor: "#031851",
                                }}
                            />
                            <Grid
                                container
                                alignItems="center"
                                justifyContent="space-between"
                                spacing={2}
                                sx={{ paddingBottom: 1.5, paddingTop: 1.5 }}
                            >
                                <Grid item xs={3}>
                                    <Typography variant="body1" sx={{ paddingLeft: 6, wordBreak: "break-word" }}>
                                        {t("proposals.coreServB")}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}></Grid>
                                <Grid item xs={1} sx={{ textAlign: "center" }}>
                                    <Typography variant="body1">{pricingBook.catchUp.monthQty}</Typography>
                                </Grid>
                                <Grid item xs={1} sx={{ textAlign: "right" }}>
                                    <Typography variant="body1">
                                        $
                                        {parseFloat(pricingBook.catchUp.fee).toLocaleString(todos.amountFormat, {
                                            maximumFractionDigits: 2,
                                        })}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2} sx={{ textAlign: "right" }}>
                                    <Typography variant="body1">
                                        $
                                        {parseFloat(
                                            pricingBook.catchUp.fee * pricingBook.catchUp.monthQty
                                        ).toLocaleString(todos.amountFormat, {
                                            maximumFractionDigits: 2,
                                        })}
                                    </Typography>
                                </Grid>
                                <Grid item xs={1} sx={{ textAlign: "center" }}>
                                    <Typography variant="body1">
                                        {parseFloat(pricingBook.catchUp.discount).toLocaleString(todos.amountFormat, {
                                            maximumFractionDigits: 2,
                                        })}
                                        %
                                    </Typography>
                                </Grid>
                                <Grid item xs={2} sx={{ textAlign: "right" }}>
                                    <Typography variant="body1">
                                        $
                                        {parseFloat(
                                            pricingBook.catchUp.fee *
                                            pricingBook.catchUp.monthQty *
                                            (1 - pricingBook.catchUp.discount / 100)
                                        ).toLocaleString(todos.amountFormat, {
                                            maximumFractionDigits: 2,
                                        })}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider
                                sx={{
                                    backgroundColor: "#031851",
                                }}
                            />
                            <Grid
                                container
                                alignItems="center"
                                justifyContent="space-between"
                                spacing={2}
                                sx={{ paddingBottom: 1.5, paddingTop: 1.5 }}
                            >
                                <Grid item xs={3}>
                                    <Typography variant="body1" sx={{ paddingLeft: 6, wordBreak: "break-word" }}>
                                        {t("proposals.addonsB")}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}></Grid>
                                <Grid item xs={1} sx={{ textAlign: "center" }}>
                                    <Typography variant="body1">{pricingBook.catchUp.totalHours}</Typography>
                                </Grid>
                                <Grid item xs={1} sx={{ textAlign: "right" }}>
                                    <Typography variant="body1">
                                        $
                                        {parseFloat(pricingBook.catchUp.addFee).toLocaleString(todos.amountFormat, {
                                            maximumFractionDigits: 2,
                                        })}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2} sx={{ textAlign: "right" }}>
                                    <Typography variant="body1">
                                        $
                                        {parseFloat(
                                            pricingBook.catchUp.totalHours * pricingBook.catchUp.addFee
                                        ).toLocaleString(todos.amountFormat, {
                                            maximumFractionDigits: 2,
                                        })}
                                    </Typography>
                                </Grid>
                                <Grid item xs={1} sx={{ textAlign: "center" }}>
                                    <Typography variant="body1">
                                        {parseFloat(pricingBook.catchUp.discount).toLocaleString(todos.amountFormat, {
                                            maximumFractionDigits: 2,
                                        })}
                                        %
                                    </Typography>
                                </Grid>
                                <Grid item xs={2} sx={{ textAlign: "right" }}>
                                    <Typography variant="body1">
                                        $
                                        {parseFloat(
                                            pricingBook.catchUp.totalHours *
                                            pricingBook.catchUp.addFee *
                                            (1 - pricingBook.catchUp.discount / 100)
                                        ).toLocaleString(todos.amountFormat, {
                                            maximumFractionDigits: 2,
                                        })}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </>
                    )}
                    {pricingBook.catchUp.additionals.map(
                        (value) =>
                            value.additional_name !== "Bookkeeper" && (
                                <>
                                    <Divider
                                        sx={{
                                            backgroundColor: "#031851",
                                        }}
                                    />
                                    <Grid
                                        container
                                        alignItems="center"
                                        justifyContent="space-between"
                                        spacing={2}
                                        sx={{ paddingBottom: 1.5, paddingTop: 1.5 }}
                                    >
                                        <Grid item xs={3}>
                                            <Typography
                                                variant="body1"
                                                sx={{ paddingLeft: 8, wordBreak: "break-word" }}
                                            >
                                                {value.other_name ?? getNamesAdd(value)}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2}></Grid>
                                        <Grid item xs={1} sx={{ textAlign: "center" }}>
                                            <Typography variant="body1">{value.hours}</Typography>
                                        </Grid>
                                        <Grid item xs={1} sx={{ textAlign: "right" }}>
                                            <Typography variant="body1">
                                                $
                                                {parseFloat(pricingBook.catchUp.addFee).toLocaleString(
                                                    todos.amountFormat,
                                                    {
                                                        maximumFractionDigits: 2,
                                                    }
                                                )}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2} sx={{ textAlign: "right" }}>
                                            <Typography variant="body1">
                                                $
                                                {parseFloat(pricingBook.catchUp.addFee * value.hours).toLocaleString(
                                                    todos.amountFormat,
                                                    {
                                                        maximumFractionDigits: 2,
                                                    }
                                                )}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={1} sx={{ textAlign: "center" }}>
                                            <Typography variant="body1">
                                                {parseFloat(pricingBook.catchUp.discount).toLocaleString(
                                                    todos.amountFormat,
                                                    {
                                                        maximumFractionDigits: 2,
                                                    }
                                                )}
                                                %
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2} sx={{ textAlign: "right" }}>
                                            <Typography variant="body1">
                                                $
                                                {parseFloat(
                                                    pricingBook.catchUp.addFee *
                                                    value.hours *
                                                    (1 - pricingBook.catchUp.discount / 100)
                                                ).toLocaleString(todos.amountFormat, {
                                                    maximumFractionDigits: 2,
                                                })}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </>
                            )
                    )}
                </>
            )}
            {pricingBook.consulting.isActive &&
                pricingBook.consulting.array.map((consult) => (
                    <>
                        <Divider
                            sx={{
                                backgroundColor: "#031851",
                            }}
                        />
                        <Grid
                            container
                            alignItems="center"
                            justifyContent="space-between"
                            spacing={2}
                            sx={{ paddingBottom: 1.5, paddingTop: 1.5 }}
                        >
                            <Grid item xs={3}>
                                <Typography variant="h4">{consult.description}</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography variant="body1" sx={{ textAlign: "center" }}>
                                    {pricingBook?.prospect?.prospect_id === "669580b61461f7cc543681dd" || pricingBook?.prospect?.prospect_id === "66b18d9f1a7779f7a5be1fee" ? "Annually" : t(`proposals.oneTime2B`)}
                                </Typography>
                            </Grid>
                            <Grid item xs={1} sx={{ textAlign: "center" }}>
                                <Typography variant="body1">{consult.hours}</Typography>
                            </Grid>
                            <Grid item xs={1} sx={{ textAlign: "right" }}>
                                <Typography variant="body1">
                                    {pricingBook?.prospect?.prospect_id === "669580b61461f7cc543681dd" || pricingBook?.prospect?.prospect_id === "66b18d9f1a7779f7a5be1fee" ? "From" : "$" + parseFloat(pricingBook.consulting.fee).toLocaleString(todos.amountFormat, {
                                        maximumFractionDigits: 2,
                                    })}
                                </Typography>
                            </Grid>
                            <Grid item xs={2} sx={{ textAlign: "right" }}>
                                <Typography variant="body1">
                                    {pricingBook?.prospect?.prospect_id === "669580b61461f7cc543681dd" || pricingBook?.prospect?.prospect_id === "66b18d9f1a7779f7a5be1fee" ? "$899" : "$" + parseFloat(pricingBook.consulting.fee * consult.hours).toLocaleString(
                                        todos.amountFormat,
                                        {
                                            maximumFractionDigits: 2,
                                        }
                                    )}
                                </Typography>
                            </Grid>
                            <Grid item xs={1} sx={{ textAlign: "center" }}>
                                <Typography variant="body1">
                                    {parseFloat(consult.discount).toLocaleString(todos.amountFormat, {
                                        maximumFractionDigits: 2,
                                    })}
                                    %
                                </Typography>
                            </Grid>
                            <Grid item xs={2} sx={{ textAlign: "right" }}>
                                <Typography variant="body1">
                                    {pricingBook?.prospect?.prospect_id === "669580b61461f7cc543681dd" || pricingBook?.prospect?.prospect_id === "66b18d9f1a7779f7a5be1fee" ? "From $899" : "$" + parseFloat(
                                        pricingBook.consulting.fee * consult.hours * (1 - consult.discount / 100)
                                    ).toLocaleString(todos.amountFormat, {
                                        maximumFractionDigits: 2,
                                    })}
                                </Typography>
                            </Grid>
                        </Grid>
                    </>
                ))}
        </StyledContainer>
    );
};
