/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

/** Icons/images */
import ComebackSoonImg from "../assets/images/comeback-soon.png";

/** MUI imports */
import { styled } from "@mui/material/styles";
import { Box, Button, Grid, Typography } from "@mui/material";

/** Components imports */
import Backdrop from "./internos/Backdrop";
import authService from "../services/auth";

const PREFIX = "ComebackSoon";

const classes = {
    root: `${PREFIX}-root`,
    commonWhite: `${PREFIX}-commonWhite`,
    comebackImg: `${PREFIX}-comebackImg`,
    contactUsText: `${PREFIX}-contactUsText`,
    comebackText: `${PREFIX}-comebackText`,
    backBtn: `${PREFIX}-backBtn`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`&.${classes.root}`]: {
        flexGrow: 1,
        width: "100%",
        height: "auto",
        backgroundColor: "#031851",
        [theme.breakpoints.up("xs")]: {
            padding: "60px 30px",
        },
        [theme.breakpoints.up("md")]: {
            padding: 70,
        },
        [theme.breakpoints.up("xl")]: {
            padding: 115,
        },
    },

    [`& .${classes.comebackImg}`]: {
        marginLeft: "auto",
        marginRight: "auto",
        display: "block",
        [theme.breakpoints.up("xs")]: {
            height: 130,
        },
        [theme.breakpoints.up("sm")]: {
            height: 198,
        },
        [theme.breakpoints.up("md")]: {
            height: 280,
        },
        [theme.breakpoints.up("xl")]: {
            height: 380,
        },
    },

    [`& .${classes.contactUsText}`]: {
        marginTop: 40,
        [theme.breakpoints.up("xs")]: {
            fontSize: 40,
        },
        [theme.breakpoints.up("md")]: {
            fontSize: 50,
        },
    },

    [`& .${classes.comebackText}`]: {
        color: "#FFF",
        [theme.breakpoints.up("xs")]: {
            fontSize: 20,
        },
        [theme.breakpoints.up("md")]: {
            fontSize: 25,
        },
    },

    [`& .${classes.backBtn}`]: {
        marginTop: 60,
        borderRadius: 40,
        paddingLeft: 40,
        paddingRight: 40,
        fontSize: 18,
        "&:hover": {
            backgroundColor: "white",
            color: theme.palette.text.primary,
        },
    },
}));

const ComebackSoon = () => {
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    const navigate = useNavigate();

    /* React redux */
    const dispatch = useDispatch();

    /** Component states */
    const [showBackdrop, setBackdrop] = useState(false);

    /** Component functions */
    const handleClickLogout = () => {
        setBackdrop(true);
        authService.logoutFunction().then(() => {
            sessionStorage.removeItem("option");
            dispatch({
                type: "clearInfo",
            });
            navigate("/");
        });
    };

    useEffect(() => {}, [t]);

    return (
        <StyledContainer component="main" className={classes.root}>
            <Grid container justifyContent="center">
                <Grid item xs={12}>
                    <img src={ComebackSoonImg} alt="404" className={classes.comebackImg} />
                </Grid>
            </Grid>
            <Grid container justifyContent="center" spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h5" color="secondary" align="center" className={classes.contactUsText}>
                        {t("miscellaneous.pleaseContactUs")}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h5" color="secondary" align="center" className={classes.comebackText}>
                        {t("miscellaneous.comebackSoon")}
                    </Typography>
                </Grid>
                <Grid item xs={12} sx={{ textAlign: "center"}}>
                    <Button
                        disableElevation
                        variant="contained"
                        color="secondary"
                        className={classes.backBtn}
                        onClick={() => handleClickLogout()}
                    >
                        {t("miscellaneous.goBack")}
                    </Button>
                </Grid>
            </Grid>
            <Backdrop open={showBackdrop} />
        </StyledContainer>
    );
};

export default ComebackSoon;
