/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

/** Components imports */
import calls from "../../Js/calls";
import RequirementsMana from "./RequirementsMana";
import RequirementsManaSkeletons from "./RequirementsManaSkeletons";

const Requirements = () => {
    /** Internationalization i18n */
    const [t] = useTranslation("global");
    
    /** Component states */
    const [loading, showLoading] = useState(false);
    const [banks, setBanks] = useState([]);
    const [typeData, setTypeData] = useState([]);
    const [groupData, setGroupData] = useState([]);
    const [franqData, setFranqData] = useState([]);

    const formatBankInfo = (bankInfo) => {
        let bankName = bankInfo.split(" - ");
        switch (bankName[0]) {
            case "Estados Unidos":
                bankName[0] = t("countries.EstadosUnidos");
                break;

            case "Panamá":
                bankName[0] = t("countries.Panamá");
                break;

            case "Europa":
                bankName[0] = t("countries.Europa");
                break;

            case "España":
                bankName[0] = t("countries.España");
                break;

            case "Suiza":
                bankName[0] = t("countries.Suiza");
                break;

            case "Emiratos Árabes Unidos":
                bankName[0] = t("countries.EmiratosÁrabesUnidos");
                break;

            case "República Dominicana":
                bankName[0] = t("countries.RepúblicaDominicana");
                break;

            case "Francia":
                bankName[0] = t("countries.Francia");
                break;

            case "Rusia":
                bankName[0] = t("countries.Rusia");
                break;

            default:
                break;
        }

        return bankName.join(" - ");
    };

    /* Component events */
    useEffect(() => {
        (async () => {
            showLoading(true);

            calls.getBanks().then((result) => {
                const banksTranslate = result.map((bankInfo) => formatBankInfo(bankInfo));
                setBanks(banksTranslate);
            });

            await fetch(`/getClientTypeFilter`, {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
                .then((res) => res.json())
                .then((data) => {
                    setTypeData(data);
                });

            await fetch(`/getClientFranchiseFilter`, {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
                .then((res) => res.json())
                .then((data) => {
                    setFranqData(data);
                });

            await fetch(`/getClientGroupsFilter`, {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
                .then((res) => res.json())
                .then((data) => {
                    setGroupData(data);
                });

            showLoading(false);
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t]);

    return (
        <>
            {franqData.length > 0 && groupData.length > 0 && typeData.length > 0 && (
                <RequirementsMana
                    loading={loading}
                    banks={banks}
                    typeData={typeData}
                    groupData={groupData}
                    franqData={franqData}
                    formatBankInfo={formatBankInfo}
                />
            )}
            {(franqData.length === 0 || groupData.length === 0 || typeData.length === 0) && (
                <RequirementsManaSkeletons />
            )}
        </>
    );
};

export default Requirements;
