/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

/** MUI imports */
import { styled } from "@mui/material/styles";
import { Box, Chip, Grid, Typography } from "@mui/material";

/** Components */
import { Xero, KiiperTaxes, KiiperReporting, Other, Consulting } from "./";

/** Component styles */
const PREFIX = "PropSetupDetails";

const classes = {
    cardContentRoot: `${PREFIX}-cardContentRoot`,
    chip: `${PREFIX}-chip`,
    selectedChip: `${PREFIX}-selectedChip`,
    projectBox: `${PREFIX}-projectBox`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.cardContentRoot}`]: {
        padding: "24px 48px 48px",
    },

    [`& .${classes.chip}`]: {
        padding: 24,
        width: 200,
        backgroundColor: "#EAEAF4",
        color: "#031851",
        fontWeight: "700 !important",
        "&:hover": {
            backgroundColor: "#EAEAF4",
            color: "#031851",
        },
    },

    [`& .${classes.selectedChip}`]: {
        padding: 24,
        width: 200,
        backgroundColor: "#2f3190",
        color: "#fff",
        fontWeight: "700 !important",
        "&:hover": {
            backgroundColor: "#2f3190",
            color: "#fff",
        },
    },

    [`& .${classes.projectBox}`]: {
        backgroundColor: "#F8F9F8",
        marginTop: 16,
        padding: 30,
        borderRadius: 30,
    },
}));

/** Global variables */

const latam = [
    "Argentina",
    "Bolivia",
    "Brasil",
    "Chile",
    "Colombia",
    "Costa Rica",
    "Cuba",
    "Ecuador",
    "El Salvador",
    "Guatemala",
    "Haití",
    "Honduras",
    "México",
    "Nicaragua",
    "Panamá",
    "Paraguay",
    "Perú",
    "República Dominicana",
    "Uruguay",
];

export const PropSetupDetails = (props) => {
    // Properties
    const {
        services,
        setServices,
        setValuesSetupXero,
        valuesSetupXero,
        valuesSetupBanking,
        valuesSetupTaxes,
        setValuesSetupTaxes,
        valuesSetupReport,
        setValuesSetupReport,
        valuesSetupOther,
        setValuesSetupOther,
        valuesSetupCon,
        setValuesSetupCon,
        isError,
        isErrorFocus,
        setIsErrorFocus,
        clientMarket,
        showtutorial,
        showFinalTutorial,
        includeAccModuleSet,
        handleChangeCheckAccSet,
        includeAdmModuleSet,
        handleChangeCheckAdmSet,
        accountantOnboarding,
    } = props;

    /** Internationalization i18n */
    const [t] = useTranslation("global");

    /** Component functions */
    useEffect(() => {
        if (
            !valuesSetupXero.chip &&
            !valuesSetupBanking.chip &&
            !valuesSetupReport.chip &&
            !valuesSetupTaxes.chip &&
            !valuesSetupOther.chip &&
            !valuesSetupCon.chip
        ) {
            const updateServices = services.map((service) => {
                if (service.name === "softSetup") {
                    return {
                        ...service,
                        isActive: false,
                    };
                } else {
                    return {
                        ...service,
                    };
                }
            });
            setServices(updateServices);
        } else {
            const updateServices = services.map((service) => {
                if (service.name === "softSetup") {
                    return {
                        ...service,
                        isActive: true,
                    };
                } else {
                    return {
                        ...service,
                    };
                }
            });
            setServices(updateServices);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        valuesSetupXero.chip,
        valuesSetupBanking.chip,
        valuesSetupReport.chip,
        valuesSetupTaxes.chip,
        valuesSetupOther.chip,
        valuesSetupCon.chip,
    ]);

    const handleClickChip = (value, option) => {
        let fee = 0;

        fee = clientMarket === "Venezuela" ? 36 : latam.includes(clientMarket) ? 42 : 60;

        if (option === "xero") {
            setValuesSetupXero({
                ...valuesSetupXero,
                chip: !value,
                originalFee: fee,
                hours: 1,
                discount: 0,
                package: "",
                version: "",
                projectTotal: !value ? fee : 0,
                isError: false,
                commitmentDateProposal: null,
            });
        }
        // if (option === "banking") {
        //     setValuesSetupBanking({
        //         ...valuesSetupBanking,
        //         chip: !value,
        //         originalFee: fee,
        //         hours: 1,
        //         discount: 0,
        //         package: "",
        //         version: "",
        //         projectTotal: !value ? fee : 0,
        //         isError: false,
        //         commitmentDateProposal: null,
        //     });
        // }
        if (option === "taxes") {
            setValuesSetupTaxes({
                ...valuesSetupTaxes,
                chip: !value,
                originalFee: fee,
                hours: 1,
                discount: 0,
                package: "",
                version: "",
                projectTotal: !value ? fee : 0,
                isError: false,
                commitmentDateProposal: null,
            });
        }
        if (option === "report") {
            setValuesSetupReport({
                ...valuesSetupReport,
                chip: !value,
                originalFee: fee,
                hours: 1,
                discount: 0,
                package: 4,
                version: "",
                projectTotal: !value ? fee : 0,
                isError: false,
                commitmentDateProposal: null,
            });
        }
        if (option === "other") {
            setValuesSetupOther({
                ...valuesSetupOther,
                chip: !value,
                originalFee: fee,
                hours: 1,
                discount: 0,
                package: 4,
                version: "",
                projectTotal: !value ? fee : 0,
                isError: false,
                commitmentDateProposal: null,
            });
        }
        if (option === "consulting") {
            setValuesSetupCon({
                ...valuesSetupCon,
                chip: !value,
                consulOriginalFee: fee,
                consulProjectTotal: 0,
                consulHours: 0,
                consultingArray: [
                    {
                        hours: 0,
                        discount: 0,
                        description: "",
                        total: 0,
                    },
                ],
                isError: false,
            });
        }
        if (showtutorial && !value) {
            setTimeout(() => showFinalTutorial("projectsSetup"), 500);
        }
    };

    return (
        <StyledContainer>
            <Grid id="softSetup" container justifyContent="space-around" alignItems="center" spacing={2}>
                <Grid item>
                    <Chip
                        id="xero-chip"
                        label={
                            <Typography variant="h2" align="center" sx={{ fontWeight: 700 }}>
                                Xero
                            </Typography>
                        }
                        className={valuesSetupXero.chip ? classes.selectedChip : classes.chip}
                        onClick={() => handleClickChip(valuesSetupXero.chip, "xero")}
                    />
                </Grid>
                {/* <Grid item>
                    <Chip
                        id="banking-chip"
                        label={
                            <Typography variant="h2" align="center" sx={{ fontWeight: 700 }}>
                                Kiiper Banking
                            </Typography>
                        }
                        className={valuesSetupBanking.chip ? classes.selectedChip : classes.chip}
                        onClick={() => handleClickChip(valuesSetupBanking.chip, "banking")}
                    />
                </Grid> */}
                {(clientMarket === "Venezuela" || clientMarket === "LATAM") && (
                    <Grid item>
                        <Chip
                            id="taxes-chip"
                            label={
                                <Typography variant="h2" align="center" sx={{ fontWeight: 700 }}>
                                    Kiiper Taxes
                                </Typography>
                            }
                            className={valuesSetupTaxes.chip ? classes.selectedChip : classes.chip}
                            onClick={() => handleClickChip(valuesSetupTaxes.chip, "taxes")}
                        />
                    </Grid>
                )}
                <Grid item>
                    <Chip
                        id="report-chip"
                        label={
                            <Typography variant="h2" align="center" sx={{ fontWeight: 700 }}>
                                Kiiper Reporting
                            </Typography>
                        }
                        className={valuesSetupReport.chip ? classes.selectedChip : classes.chip}
                        onClick={() => handleClickChip(valuesSetupReport.chip, "report")}
                    />
                </Grid>
                <Grid item>
                    <Chip
                        id="other-chip"
                        label={
                            <Typography variant="h2" align="center" sx={{ fontWeight: 700 }}>
                                {t("services.other")}
                            </Typography>
                        }
                        className={valuesSetupOther.chip ? classes.selectedChip : classes.chip}
                        onClick={() => handleClickChip(valuesSetupOther.chip, "other")}
                    />
                </Grid>
                <Grid item>
                    <Chip
                        id="consulting-chip"
                        label={
                            <Typography variant="h2" align="center" sx={{ fontWeight: 700 }}>
                                {t("invoicing.consulting")}
                            </Typography>
                        }
                        className={valuesSetupCon.chip ? classes.selectedChip : classes.chip}
                        onClick={() => handleClickChip(valuesSetupCon.chip, "consulting")}
                    />
                </Grid>
            </Grid>
            <Box id="projectsSetup">
                {valuesSetupXero.chip && (
                    <Xero
                        xero={valuesSetupXero}
                        setXero={setValuesSetupXero}
                        isError={isError}
                        isErrorFocus={isErrorFocus}
                        setIsErrorFocus={setIsErrorFocus}
                        includeAccModuleSet={includeAccModuleSet}
                        handleChangeCheckAccSet={handleChangeCheckAccSet}
                        includeAdmModuleSet={includeAdmModuleSet}
                        handleChangeCheckAdmSet={handleChangeCheckAdmSet}
                        accountantOnboarding={accountantOnboarding}
                    />
                )}
                {/* {valuesSetupBanking.chip && (
                    <KiiperBanking
                        banking={valuesSetupBanking}
                        setBanking={setValuesSetupBanking}
                        isError={isError}
                        isErrorFocus={isErrorFocus}
                        setIsErrorFocus={setIsErrorFocus}
                    />
                )} */}
                {valuesSetupTaxes.chip && (
                    <KiiperTaxes
                        taxes={valuesSetupTaxes}
                        setTaxes={setValuesSetupTaxes}
                        isError={isError}
                        isErrorFocus={isErrorFocus}
                        clientMarket={clientMarket}
                        setIsErrorFocus={setIsErrorFocus}
                    />
                )}
                {valuesSetupReport.chip && (
                    <KiiperReporting
                        report={valuesSetupReport}
                        setReport={setValuesSetupReport}
                        isError={isError}
                        isErrorFocus={isErrorFocus}
                        setIsErrorFocus={setIsErrorFocus}
                    />
                )}
                {valuesSetupOther.chip && (
                    <Other
                        other={valuesSetupOther}
                        setOther={setValuesSetupOther}
                        isError={isError}
                        isErrorFocus={isErrorFocus}
                        setIsErrorFocus={setIsErrorFocus}
                    />
                )}
                {valuesSetupCon.chip && (
                    <Consulting
                        consulting={valuesSetupCon}
                        setConsulting={setValuesSetupCon}
                        isError={isError}
                        isErrorFocus={isErrorFocus}
                        setIsErrorFocus={setIsErrorFocus}
                    />
                )}
            </Box>
        </StyledContainer>
    );
};
