/** React imports */
import React from "react";
import { useTranslation } from "react-i18next";
import Confetti from "react-confetti";

/** MUI imports */
import { styled } from "@mui/material/styles";
import { Dialog, DialogContent, DialogTitle, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const CustomDialog = styled(Dialog)(({ theme }) => ({
    zIndex: 9999,
    '& .MuiDialogContent-root': {
        [theme.breakpoints.down("sm")]: {
            padding: 30,
        },
        textAlign: "center",
        padding: "30px 80px 30px 80px",
    },
    "& .MuiDialogTitle-root": {
        fontSize: 30,
        fontWeight: 700,
        textAlign: "center",
        marginTop: 5,
    },
    "& .MuiDialog-paper": {
        borderRadius: 20,
        overflow: "hidden"
    },
}));

export default function AcceptDialog(props) {
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    return (
        <CustomDialog
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={props.maxWidth}
            fullWidth={true}
        >
            <Confetti width={900} height={500} />
            <DialogTitle id="alert-dialog-title">
                {t("proposals.congrats")}
                <span style={{ color: "#ff5968" }}>{t("proposals.gcaFam")}</span>
            </DialogTitle>
            <DialogContent>
                <Typography variant="h5" sx={{ pb: 4 }}>
                    {t("proposals.messageCongrats")}
                </Typography>
                <CheckCircleIcon color="success" sx={{ fontSize: 150 }} />
            </DialogContent>
        </CustomDialog>
    );
}
