/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

/** MUI imports */
import { styled } from "@mui/material/styles";
import {
    Box,
    Breadcrumbs,
    Card,
    CardHeader,
    CardContent,
    Divider,
    FormControl,
    Grid,
    InputLabel,
    List,
    ListItem,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    Skeleton,
    Stack,
    SpeedDial,
    SpeedDialIcon,
    SpeedDialAction,
    Tabs,
    Tab,
    Typography,
    Checkbox,
    IconButton,
    Button
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

/** MUI icons */
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import GroupsIcon from "@mui/icons-material/Groups";
import BusinessIcon from "@mui/icons-material/Business";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import PendingActionsIcon from "@mui/icons-material/PendingActions";

/** Components imports */
import AccountSystem from "./ClientCont";
import Backdrop from "../Backdrop";
import Billing from "./ClientFact";
import ClientsList from "./ClientsList";
import GeneralData from "./ClientGen";
import NewFranq from "../Franchise/NewFranq";
import NewGroup from "../Group/NewGroup";
import NewClient from "./NewClient";
import Services from "./ClientServ";
import SnackBar from "../SnackBar";
import Staff from "./ClientStaff";
import ProposalHistory from "./ProposalHistory";
import projectService from "../../../services/projects";

const PREFIX = "ClientMana";

const classes = {
    btnRoot: `${PREFIX}-btnRoot`,
    formRounded: `${PREFIX}-formRounded`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.btnRoot}`]: {
        border: "1px solid #ff5968",
        borderRadius: 40,
        "&:hover": {
            backgroundColor: "#2f3190",
            border: "1px solid #2f3190",
        },
    },

    [`& .${classes.formRounded}`]: {
        backgroundColor: "#E9EAE8 !important",
        borderRadius: 40,
        verticalAlign: "middle !important",
        "&:focus-within": {
            backgroundColor: "#E9EAE8",
            borderRadius: 40,
        },
        "& .MuiOutlinedInput-notchedOutline": {
            border: "2px solid #031851",
            borderRadius: 40,
        },
    },
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const CustomTab = styled(Tab)(({ theme }) => ({
    padding: 20,
    marginLeft: 8,
    borderRadius: "20px 20px 0 0",
    backgroundColor: "#E9EAE8",
    color: theme.palette.primary.main,
    fontWeight: 700,
    "&.Mui-selected": {
        borderTop: "2px solid #ff5968",
        borderRight: "2px solid #ff5968",
        borderLeft: "2px solid #ff5968",
        backgroundColor: "#fff !important",
    },
}));

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 48 * 4.5 + 8,
            boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
        },
        sx: {
            borderRadius: 2,
            mt: 0.7,
        },
    },
    variant: "menu",
    getContentAnchorEl: null,
};

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "rgba(19, 31, 62, 0.80)",
        fontSize: 12,
        maxWidth: 320,
        borderRadius: 6,
        fontWeight: "normal",
    },
});

const ClientMana = (props) => {
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    const markets = [t("miscellaneous.all"), "Global", "LATAM", "USA", "Venezuela"];

    const franqDataProps = props.franqData;
    const groupDataProps = props.groupData;

    /** Component states */
    const [groupData, setGroupData] = useState(groupDataProps);
    const [franqData, setFranqData] = useState(franqDataProps);
    const [clientSearch, setClientSearch] = useState([]);
    const [clientsF, setClientsF] = useState([]);
    const [clientType, setTypeClient] = useState("");
    const [franqF, setfranqF] = useState(["all"].concat(franqDataProps.map((item) => item.id)));
    const [groupF, setgroupF] = useState(["all"].concat(groupDataProps.map((item) => item.id)));
    const [clientMarket, setClientMarket] = useState(markets);
    const [status, setStatus] = useState(1);
    const [groupName, setgroupName] = useState("");
    const [franqName, setfranqName] = useState("");
    const [client, setClient] = useState("");
    const [valueTab, setValueTab] = useState(0);
    const [showBackdrop, setBackDrop] = useState(false);
    const [loadingCard, setLoadingCard] = useState(false);
    const [loadingCardHeader, setLoadingCardHeader] = useState(false);
    const [showCard, setshowCard] = useState(false);
    const [services, setServices] = useState([]);
    const [dialogFranq, setDialogFranq] = useState(false);
    const [dialogGroup, setDialogGroup] = useState(false);
    const [dialogClient, setDialogClient] = useState(false);
    const [texto, setTexto] = useState("");
    const [alertType, setalertType] = useState("");
    const [show, setShow] = useState(false);
    const [totalClients, setTotalClients] = useState(0);
    const [searchValue, setSearchValue] = useState("");
    const [selectedIndex, setSelectedIndex] = useState("");
    const [groupDataF, setgroupDataF] = useState(groupDataProps);
    const [proveedores, setProveedores] = useState(props.proveedores);
    const [serviceData, setServiceData] = useState([]);
    const [contValues, setContValues] = useState({
        prov: "",
        disabledData: false,
    });
    const controllerRef = useRef(null);
    const firstRender = useRef(true);
    const [loading, setLoading] = useState(false);
    const [modalPropHist, showModalPropHist] = useState(false);
    const [propContData, setPropContData] = useState([]);
    const [anyFilter, setAnyFilter] = useState(true);
    const [compInfo, setCompInfo] = useState({});

    /** Component variables */
    const actions = [
        {
            icon: <BusinessIcon />,
            name: t("miscellaneous.franchiseManagement"),
        },
        { icon: <GroupsIcon />, name: t("miscellaneous.groupManagement") },
        { icon: <PersonAddIcon />, name: t("miscellaneous.newClient") },
    ];

    const breadcrumbs = [
        franqName !== "" ? (
            <Typography key="1" variant="h5" color="primary">
                {franqName}
            </Typography>
        ) : null,
        groupName !== "" ? (
            <Typography key="2" variant="h5" color="primary">
                {groupName}
            </Typography>
        ) : null,
        <Typography key="3" variant="h5" color="secondary">
            {client.company_legal_name}
        </Typography>,
    ];

    /** Component functions */
    const cleanFilters = () => {
        setAnyFilter(true);
        setfranqF(["all"].concat(franqDataProps.map((item) => item.id)));
        setgroupF(["all"].concat(groupDataProps.map((item) => item.id)));
        setGroupData(groupDataProps);
        setTypeClient("");
        setClientMarket(markets);
        setStatus(1);
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setShow(false);
    };

    const handleChangeClient = async (value, index) => {
        if (showCard) {
            setLoadingCard(true);
            setLoadingCardHeader(true);
        } else {
            setBackDrop(true);
        }

        let groupExist = false;
        let franqExist = false;

        await projectService.getCompanyInfo(value._id).then((data) => {
            setClient(data);
        });

        setValueTab(0);
        setSelectedIndex(index);
        setshowCard(true);

        groupData.forEach((val) => {
            if (value.practice_id === val.id) {
                setgroupName(val.group_name);
                groupExist = true;
            }
        });

        franqData.forEach((val) => {
            if (value.franchise_id === val.id) {
                setfranqName(val.franchise_name);
                franqExist = true;
            }
        });

        if (!groupExist) {
            setgroupName("");
        }
        if (!franqExist) {
            setfranqName("");
        }

        setLoadingCard(false);
        setLoadingCardHeader(false);
        setBackDrop(false);
    };

    const getClients = async () => {
        if (!firstRender.current) {
            setBackDrop(true);
        } else {
            setLoading(true);
        }
        if (controllerRef.current) {
            controllerRef.current.abort();
        }
        const controller = new AbortController();
        controllerRef.current = controller;

        let finalFranq = [];
        if (franqF.includes("all")) {
            finalFranq = [];
        } else if (franqF.length === 0) {
            finalFranq = ["none"];
        } else {
            finalFranq = franqF.filter((item) => item !== "all");
        }

        let finalGroup = [];
        if (groupF.includes("all")) {
            finalGroup = [];
        } else if (groupF.length === 0) {
            finalGroup = ["none"];
        } else {
            finalGroup = groupF.filter((item) => item !== "all");
        }

        let finalMarket = [];
        if (clientMarket.includes(t("miscellaneous.all"))) {
            finalMarket = [];
        } else if (clientMarket.length === 0) {
            finalMarket = ["none"];
        } else {
            finalMarket = clientMarket.filter((item) => item !== "all");
        }

        await fetch(
            `/getClientsCompanies?client_type=${clientType}&group=${finalGroup}&franchise=${finalFranq}&client_market=${finalMarket}&status=${status}`,
            {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
                signal: controllerRef.current.signal,
            }
        )
            .then((res) => res.json())
            .then((data) => {
                setClientSearch(data);
                setClientsF(data);
                setTotalClients(data.length);

                if (!firstRender.current) {
                    if (data.length !== 0) {
                        handleChangeClient(data[0], 0);
                    } else {
                        setshowCard(false);
                    }
                }
                controllerRef.current = null;
            });
        if (!firstRender.current) {
            setBackDrop(false);
        } else {
            firstRender.current = false;
            setLoading(false);
        }
    };

    const handleChangeTClient = async (event) => {
        setBackDrop(true);
        setTypeClient(event.target.value);
        setAnyFilter(false);

        let finalFranq = [];

        await fetch(`/getClientFranchiseFilter?client_type=${event.target.value}`, {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then((res) => res.json())
            .then((data) => {
                setFranqData(data);
                if (event.target.value === "") {
                    finalFranq = [];
                } else {
                    finalFranq = data.map((item) => item.id);
                }
                setfranqF(["all"].concat(data.map((item) => item.id)));
            });

        await fetch(`/getFranchiseGroups?id_franchise=${finalFranq}`, {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then((res) => res.json())
            .then((data) => {
                setGroupData(data);
                setgroupF(["all"].concat(data.map((item) => item.id)));
            });
    };

    const handleChangeGroupF = (event) => {
        const {
            target: { value },
        } = event;

        let final = [];

        if (value.includes("all") && !groupF.includes("all")) {
            final = ["all"].concat(groupData.map((item) => item.id));
        } else if (!value.includes("all") && groupF.includes("all")) {
            final = [];
        } else {
            if (!value.includes("all") && value.length === groupData.length) {
                final = ["all"].concat(groupData.map((item) => item.id));
            } else {
                final = event.target.value.filter((item) => item !== "all");
            }
        }

        setgroupF(final);
        setAnyFilter(false);
    };

    const handleChangeFranqF = async (event) => {
        setBackDrop(true);

        const {
            target: { value },
        } = event;

        let final = [];

        if (value.includes("all") && !franqF.includes("all")) {
            final = ["all"].concat(franqData.map((item) => item.id));
        } else if (!value.includes("all") && franqF.includes("all")) {
            final = [];
        } else {
            if (!value.includes("all") && value.length === franqData.length) {
                final = ["all"].concat(franqData.map((item) => item.id));
            } else {
                final =
                    typeof value === "string"
                        ? value.split(",").filter((item) => item !== "all")
                        : value.filter((item) => item !== "all");
            }
        }

        setfranqF(final);
        setAnyFilter(false);

        let finalGroup = [];
        if (clientType !== "") {
            if (value.includes("all") && !franqF.includes("all")) {
                finalGroup = franqData.map((item) => item.id);
            } else if (!value.includes("all") && franqF.includes("all")) {
                finalGroup = franqData.map((item) => item.id);
            } else {
                if (!value.includes("all") && value.length === franqData.length) {
                    finalGroup = franqData.map((item) => item.id);
                } else {
                    finalGroup =
                        typeof value === "string"
                            ? value.split(",").filter((item) => item !== "all")
                            : value.filter((item) => item !== "all");
                }
            }
        } else {
            if (value.includes("all") && !franqF.includes("all")) {
                finalGroup = [];
            } else if (!value.includes("all") && franqF.includes("all")) {
                finalGroup = ["none"];
            } else {
                if (!value.includes("all") && value.length === franqData.length) {
                    finalGroup = [];
                } else {
                    finalGroup =
                        typeof value === "string"
                            ? value.split(",").filter((item) => item !== "all")
                            : value.filter((item) => item !== "all");
                }
            }
        }

        await fetch(`/getFranchiseGroups?id_franchise=${finalGroup}`, {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then((res) => res.json())
            .then((data) => {
                setGroupData(data);
                setgroupF(["all"].concat(data.map((item) => item.id)));
            });
    };

    const handleChangeMarketF = (event) => {
        const {
            target: { value },
        } = event;

        let final = [];

        if (value.includes(t("miscellaneous.all")) && !clientMarket.includes(t("miscellaneous.all"))) {
            final = markets;
        } else if (!value.includes(t("miscellaneous.all")) && clientMarket.includes(t("miscellaneous.all"))) {
            final = [];
        } else {
            if (
                !value.includes(t("miscellaneous.all")) &&
                value.length === markets.filter((item) => item !== t("miscellaneous.all")).length
            ) {
                final = markets;
            } else {
                final =
                    typeof value === "string"
                        ? value.split(",").filter((item) => item !== t("miscellaneous.all"))
                        : value.filter((item) => item !== t("miscellaneous.all"));
            }
        }
        setClientMarket(final);
        setAnyFilter(false);
    };

    const handleChangeStatus = (event) => {
        setStatus(event.target.value);
        setAnyFilter(false);
    };

    const handleChangeTab = async (event, newValue) => {
        setLoadingCard(true);

        if (newValue !== 2) {
            setValueTab(newValue);
        }

        if (newValue === 0) {
            await projectService.getCompanyInfo(client._id).then((data) => {
                setClient(data);
            });
        }

        if (newValue === 2) {
            if (
                client.company_legal_name &&
                client.client_type &&
                client.client_status &&
                client.social_activity &&
                client.financial_end_date &&
                client.verbal_communication &&
                client.typing_communication &&
                client.expense_estimation
            ) {
                setValueTab(newValue);
                await fetch(`/getAccountingServices?id_organisation=${client._id}`, {
                    method: "GET",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                    },
                })
                    .then((res) => res.json())
                    .then((data) => {
                        setContValues({
                            prov: data.software_provider !== undefined ? data.software_provider : "",
                            disabledData: data.disabled_software !== undefined ? data.disabled_software : false,
                        });
                    });

                await fetch(`/getNewServicesCompany?company_id=${client._id}`, {
                    method: "GET",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                    },
                })
                    .then((res) => res.json())
                    .then((data) => {
                        setServiceData(data);
                    });

                await fetch(`/getServicesCompany?id_organisation=${client._id}`, {
                    method: "GET",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                    },
                })
                    .then((res) => res.json())
                    .then((data) => {
                        setServices(data);
                    });
            } else {
                setTexto("Debe llenar los campos requeridos de Datos Generales");
                setalertType("warning");
                setShow(true);
            }
        }

        setLoadingCard(false);
    };

    const filterItems = (query) => {
        return clientsF.filter((el) => el.company_legal_name.toLowerCase().indexOf(query.toLowerCase()) > -1);
    };

    const handleSearch = (event) => {
        let filterArr = filterItems(event.target.value.trim());
        setSearchValue(event.target.value);
        setClientSearch(filterArr);
        setTotalClients(filterArr.length);
    };

    const setFranqDataNew = async () => {
        await fetch(`/getClientFranchiseFilter`, {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then((res) => res.json())
            .then((data) => {
                setFranqData(data);
            });
    };

    const setGroupDataNew = async () => {
        await fetch(`/getClientGroupsFilter`, {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then((res) => res.json())
            .then((data) => {
                setGroupData(data);
                setgroupDataF(data);
            });
    };

    const setClientDataNew = async () => {
        let finalFranq = [];
        if (franqF.includes("all")) {
            finalFranq = [];
        } else if (franqF.length === 0) {
            finalFranq = ["none"];
        } else {
            finalFranq = franqF.filter((item) => item !== "all");
        }

        let finalGroup = [];
        if (groupF.includes("all")) {
            finalGroup = [];
        } else if (groupF.length === 0) {
            finalGroup = ["none"];
        } else {
            finalGroup = groupF.filter((item) => item !== "all");
        }

        let finalMarket = [];
        if (clientMarket.includes("all")) {
            finalMarket = [];
        } else if (clientMarket.length === 0) {
            finalMarket = ["none"];
        } else {
            finalMarket = clientMarket.filter((item) => item !== "all");
        }

        await fetch(
            `/getClientsCompanies?client_type=${clientType}&group=${finalGroup}&franchise=${finalFranq}&client_market=${finalMarket}&status=${status}`,
            {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            }
        )
            .then((res) => res.json())
            .then((data) => {
                setClientSearch(data);
                setClientsF(data);
                setTotalClients(data.length);
                setSearchValue("");
                setSelectedIndex("");
                setshowCard(false);
            });
    };

    const updateClientsCompanies = async () => {
        setLoading(true);

        let finalFranq = [];
        if (franqF.includes("all")) {
            finalFranq = [];
        } else if (franqF.length === 0) {
            finalFranq = ["none"];
        } else {
            finalFranq = franqF.filter((item) => item !== "all");
        }

        let finalGroup = [];
        if (groupF.includes("all")) {
            finalGroup = [];
        } else if (groupF.length === 0) {
            finalGroup = ["none"];
        } else {
            finalGroup = groupF.filter((item) => item !== "all");
        }

        let finalMarket = [];
        if (clientMarket.includes("all")) {
            finalMarket = [];
        } else if (clientMarket.length === 0) {
            finalMarket = ["none"];
        } else {
            finalMarket = clientMarket.filter((item) => item !== "all");
        }

        await fetch(
            `/getClientsCompanies?client_type=${clientType}&group=${finalGroup}&franchise=${finalFranq}&client_market=${finalMarket}&status=${status}`,
            {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            }
        )
            .then((res) => res.json())
            .then((data) => {
                setClientSearch(data);
                setClientsF(data);
                setTotalClients(data.length);
                setSearchValue("");
                setSelectedIndex("");
            });

        setLoading(false);
    };

    const showProposalHist = async () => {
        setBackDrop(true);

        await fetch(`/getProposalsByClient?company_id=${client._id}`, {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.proposals.length > 0) {
                    setPropContData(data.proposals);
                } else {
                    setPropContData([])
                }
                setCompInfo(data.company)
                showModalPropHist(true);
            });

        setBackDrop(false);
    };

    const handleCloseHistory = () => {
        showModalPropHist(false);
        setPropContData([]);
    };

    useEffect(() => {
        (async () => {
            await getClients();
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, clientType, franqF, groupF, clientMarket]);

    useEffect(() => {
        (async () => {
            // Showing loading

            let params = new URL(document.location).searchParams;
            let id = params.get("clientID");

            if (id) {
                await fetch(`/getClientsCompanies?client_type=&group=&franchise=&client_market=&status=${status}`, {
                    method: "GET",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                    },
                })
                    .then((res) => res.json())
                    .then((data) => {
                        setClientSearch(data);
                        setClientsF(data);
                        setTotalClients(data.length);

                        data.forEach((value, index) => {
                            if (value._id === id) {
                                setClient(value);
                                setSelectedIndex(index);
                            }
                        });
                        setgroupName(params.get("groupName") === "N/A" ? "" : params.get("groupName"));
                        setfranqName(params.get("franqName") === "N/A" ? "" : params.get("franqName"));
                        setValueTab(0);
                        setshowCard(true);
                    });
            }
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <StyledContainer component="main" sx={{ flexGrow: 1, p: 4, pt: 10, width: "95%" }}>
            <Typography
                variant="h1"
                sx={{
                    pb: 4,
                    fontSize: 24,
                    fontWeight: 700,
                }}
            >
                {t("principal.admCustom")}
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} md>
                            <FormControl fullWidth classes={{ root: classes.formRounded }}>
                                <InputLabel shrink id="type-simple-select-label">
                                    {t("miscellaneous.type")}
                                </InputLabel>
                                <Select
                                    size="small"
                                    IconComponent={KeyboardArrowDownIcon}
                                    value={clientType}
                                    displayEmpty
                                    onChange={handleChangeTClient}
                                    className="ddlGeneral"
                                    input={<OutlinedInput notched label={t("miscellaneous.type")} />}
                                    MenuProps={{
                                        PaperProps: {
                                            sx: {
                                                mt: 0.7,
                                                borderRadius: 2,
                                                boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                            },
                                        },
                                    }}
                                >
                                    <MenuItem value="">
                                        <Typography variant="body1" component="span" align="center" color="textPrimary">
                                            {t("miscellaneous.all")}
                                        </Typography>
                                    </MenuItem>
                                    {props.typeData.map((value) => (
                                        <MenuItem value={value.id}>
                                            <Typography
                                                variant="body1"
                                                component="span"
                                                align="center"
                                                color="textPrimary"
                                            >
                                                {value.id === 1 ? t("miscellaneous.firm") : value.client_type}
                                            </Typography>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <FormControl fullWidth classes={{ root: classes.formRounded }}>
                                <InputLabel shrink id="franq-simple-select-label">
                                    {t("miscellaneous.franchise")}
                                </InputLabel>
                                <Select
                                    size="small"
                                    name="franq"
                                    labelId="franq-multiple-select-label"
                                    IconComponent={KeyboardArrowDownIcon}
                                    value={franqF}
                                    displayEmpty
                                    onChange={handleChangeFranqF}
                                    className="ddlGeneral"
                                    multiple
                                    input={
                                        <OutlinedInput
                                            notched
                                            label={t("miscellaneous.franchise")}
                                            sx={{
                                                fontSize: 16,
                                                fontWeight: 600,
                                            }}
                                        />
                                    }
                                    renderValue={(selected) => {
                                        if (selected.length === 0) {
                                            return <span>{t("miscellaneous.none")}</span>;
                                        }
                                        return selected.includes("all")
                                            ? t("miscellaneous.all")
                                            : selected
                                                  .map((item) => {
                                                      return franqDataProps.filter((item2) => item2.id === item)[0]
                                                          .franchise_name;
                                                  })
                                                  .join(", ");
                                    }}
                                    MenuProps={MenuProps}
                                >
                                    {[
                                        {
                                            id: "all",
                                            franchise_name: t("miscellaneous.all"),
                                        },
                                    ]
                                        .concat(franqData)
                                        .map((value) => (
                                            <MenuItem value={value.id}>
                                                <Checkbox
                                                    defaultChecked
                                                    checked={franqF.indexOf(value.id) > -1}
                                                    icon={
                                                        value.id === "all" &&
                                                        !franqF.includes("all") &&
                                                        franqF.length > 0 ? (
                                                            <IndeterminateCheckBoxIcon style={{ fill: "#031851" }} />
                                                        ) : (
                                                            <CheckBoxOutlineBlankIcon />
                                                        )
                                                    }
                                                />
                                                <ListItemText primary={value.franchise_name} />
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <FormControl fullWidth classes={{ root: classes.formRounded }}>
                                <InputLabel shrink id="group-simple-select-label">
                                    {t("miscellaneous.group")}
                                </InputLabel>
                                <Select
                                    size="small"
                                    labelId="group-simple-select-label"
                                    IconComponent={KeyboardArrowDownIcon}
                                    value={groupF}
                                    displayEmpty
                                    onChange={handleChangeGroupF}
                                    className="ddlGeneral"
                                    multiple
                                    input={
                                        <OutlinedInput
                                            notched
                                            label={t("miscellaneous.group")}
                                            sx={{
                                                fontSize: 16,
                                                fontWeight: 600,
                                            }}
                                        />
                                    }
                                    renderValue={(selected) => {
                                        if (selected.length === 0) {
                                            return <span>{t("miscellaneous.none")}</span>;
                                        }
                                        return selected.includes("all")
                                            ? t("miscellaneous.all")
                                            : selected
                                                  .map((item) => {
                                                      return groupDataProps.filter((item2) => item2.id === item)[0]
                                                          .group_name;
                                                  })
                                                  .join(", ");
                                    }}
                                    MenuProps={MenuProps}
                                >
                                    {[
                                        {
                                            id: "all",
                                            group_name: t("miscellaneous.all"),
                                        },
                                    ]
                                        .concat(groupData)
                                        .map((value) => (
                                            <MenuItem value={value.id}>
                                                <Checkbox
                                                    defaultChecked
                                                    checked={groupF.indexOf(value.id) > -1}
                                                    icon={
                                                        value.id === "all" &&
                                                        !groupF.includes("all") &&
                                                        groupF.length > 0 ? (
                                                            <IndeterminateCheckBoxIcon style={{ fill: "#031851" }} />
                                                        ) : (
                                                            <CheckBoxOutlineBlankIcon />
                                                        )
                                                    }
                                                />
                                                <ListItemText primary={value.group_name} />
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <FormControl fullWidth classes={{ root: classes.formRounded }}>
                                <InputLabel shrink id="idStatus-simple-select-label">
                                    {t("generalDashboard.market")}
                                </InputLabel>
                                <Select
                                    size="small"
                                    labelId="market-multiple-select-label"
                                    IconComponent={KeyboardArrowDownIcon}
                                    displayEmpty
                                    value={clientMarket}
                                    onChange={handleChangeMarketF}
                                    multiple
                                    input={
                                        <OutlinedInput
                                            notched
                                            label={t("generalDashboard.market")}
                                            sx={{
                                                fontSize: 16,
                                                fontWeight: 600,
                                            }}
                                        />
                                    }
                                    renderValue={(selected) => {
                                        if (selected.length === 0) {
                                            return <span>{t("miscellaneous.none")}</span>;
                                        }

                                        return selected.includes(t("miscellaneous.all"))
                                            ? t("miscellaneous.all")
                                            : selected.join(", ");
                                    }}
                                    MenuProps={MenuProps}
                                >
                                    {markets.map((name) => (
                                        <MenuItem value={name}>
                                            <Checkbox
                                                checked={clientMarket.indexOf(name) > -1}
                                                icon={
                                                    name === t("miscellaneous.all") &&
                                                    !clientMarket.includes(t("miscellaneous.all")) &&
                                                    clientMarket.length > 0 ? (
                                                        <IndeterminateCheckBoxIcon style={{ fill: "#031851" }} />
                                                    ) : (
                                                        <CheckBoxOutlineBlankIcon />
                                                    )
                                                }
                                            />
                                            <ListItemText primary={name} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md>
                            <FormControl fullWidth classes={{ root: classes.formRounded }}>
                                <InputLabel shrink id="status-simple-select-label">
                                    {t("miscellaneous.status")}
                                </InputLabel>
                                <Select
                                    size="small"
                                    IconComponent={KeyboardArrowDownIcon}
                                    value={status}
                                    displayEmpty
                                    onChange={handleChangeStatus}
                                    className="ddlGeneral"
                                    input={<OutlinedInput notched label={t("miscellaneous.status")} />}
                                    MenuProps={{
                                        PaperProps: {
                                            sx: {
                                                mt: 0.7,
                                                borderRadius: 2,
                                                boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                            },
                                        },
                                    }}
                                >
                                    <MenuItem value="">
                                        <Typography variant="body1" component="span" align="center" color="textPrimary">
                                            {t("miscellaneous.all")}
                                        </Typography>
                                    </MenuItem>
                                    <MenuItem value={1}>
                                        <Typography variant="body1" component="span" align="center" color="textPrimary">
                                            {t("proposals.active")}
                                        </Typography>
                                    </MenuItem>
                                    <MenuItem value={2}>
                                        <Typography variant="body1" component="span" align="center" color="textPrimary">
                                            {t("proposals.inactive")}
                                        </Typography>
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md>
                            <SpeedDial ariaLabel="SpeedDial basic" icon={<SpeedDialIcon />} direction="right">
                                {actions.map((action) => (
                                    <SpeedDialAction
                                        key={action.name}
                                        icon={action.icon}
                                        tooltipTitle={action.name}
                                        onClick={() =>
                                            action.name === t("miscellaneous.franchiseManagement")
                                                ? setDialogFranq(true)
                                                : action.name === t("miscellaneous.groupManagement")
                                                ? setDialogGroup(true)
                                                : setDialogClient(true)
                                        }
                                    />
                                ))}
                            </SpeedDial>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant="text"
                                color="primary"
                                disabled={anyFilter}
                                onClick={cleanFilters}
                                sx={{
                                    "&:hover": {
                                        color: "#2f3190",
                                    },
                                }}
                            >
                                {t("miscellaneous.cleanFilters")}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={4}>
                    {props.loading || loading ? (
                        <Card
                            elevation={0}
                            sx={{
                                borderRadius: 8,
                                boxShadow: "10px 10px 15px rgba(3,24,81,0.15)",
                            }}
                        >
                            <CardHeader
                                title={
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Box>
                                            <Typography variant="h3" color="primary">
                                                {t("miscellaneous.clients")}
                                            </Typography>
                                        </Box>
                                        <Box
                                            sx={{
                                                width: "200px",
                                                position: "relative",
                                            }}
                                        >
                                            {
                                                <Skeleton
                                                    variant="text"
                                                    animation="wave"
                                                    height={15}
                                                    width="55%"
                                                    sx={{
                                                        position: "absolute",
                                                        right: 0,
                                                    }}
                                                />
                                            }
                                        </Box>
                                    </Box>
                                }
                                sx={{
                                    m: 2,
                                    borderBottom: "2px solid #FF5968",
                                }}
                            />
                            <CardContent sx={{ pt: 0 }}>
                                <Skeleton
                                    variant="rectangular"
                                    animation="wave"
                                    height={52}
                                    sx={{ borderRadius: 25, ml: 0, mb: "10px" }}
                                />
                                <List component="nav">
                                    {Array(5).fill(
                                        <>
                                            <ListItem
                                                sx={{
                                                    p: "12px 16px !important",
                                                }}
                                            >
                                                <ListItemText
                                                    primary={
                                                        <Skeleton
                                                            variant="text"
                                                            animation="wave"
                                                            height={15}
                                                            width="50%"
                                                        />
                                                    }
                                                />
                                            </ListItem>
                                            <Divider />
                                            <ListItem
                                                sx={{
                                                    p: "12px 16px !important",
                                                }}
                                            >
                                                <ListItemText
                                                    primary={
                                                        <Skeleton
                                                            variant="text"
                                                            animation="wave"
                                                            height={15}
                                                            width="35%"
                                                        />
                                                    }
                                                />
                                            </ListItem>
                                            <Divider />
                                        </>
                                    )}
                                </List>
                            </CardContent>
                        </Card>
                    ) : (
                        <ClientsList
                            totalClients={totalClients}
                            searchValue={searchValue}
                            handleSearch={handleSearch}
                            clientSearch={clientSearch}
                            selectedIndex={selectedIndex}
                            handleChangeClient={handleChangeClient}
                        />
                    )}
                </Grid>
                {showCard && (
                    <Grid
                        item
                        xs={12}
                        lg={8}
                        sx={{
                            pl: { xs: 0, md: 4 },
                        }}
                    >
                        <Card
                            elevation={0}
                            sx={{
                                borderRadius: 8,
                                boxShadow: "10px 10px 15px rgba(3,24,81,0.15)",
                            }}
                        >
                            <CardHeader
                                title={
                                    <Stack>
                                        <Breadcrumbs
                                            separator={<NavigateNextIcon fontSize="small" sx={{ color: "#031851" }} />}
                                            aria-label="breadcrumb"
                                        >
                                            {loadingCardHeader
                                                ? [
                                                      <Skeleton
                                                          variant="text"
                                                          animation="wave"
                                                          height={25}
                                                          width={200}
                                                      />,
                                                      <Skeleton
                                                          variant="text"
                                                          animation="wave"
                                                          height={25}
                                                          width={200}
                                                      />,
                                                      <Skeleton
                                                          variant="text"
                                                          animation="wave"
                                                          height={25}
                                                          width={200}
                                                      />,
                                                  ]
                                                : breadcrumbs}
                                        </Breadcrumbs>
                                    </Stack>
                                }
                                sx={{
                                    m: 2,
                                    borderBottom: "2px solid #FF5968",
                                }}
                            />
                            <CardContent>
                                <Box sx={{ width: "100%" }}>
                                    <Box sx={{ borderBottom: 2, borderColor: "#031851" }}>
                                        <Grid container alignItems="center">
                                            <Grid item xs={12} md={11}>
                                                <Tabs
                                                    value={valueTab}
                                                    onChange={handleChangeTab}
                                                    variant="scrollable"
                                                    scrollButtons="auto"
                                                    aria-label="clients tabs"
                                                    TabIndicatorProps={{
                                                        sx: {
                                                            display: "none",
                                                        },
                                                    }}
                                                >
                                                    <CustomTab
                                                        label={t("miscellaneous.generalData")}
                                                        {...a11yProps(0)}
                                                    />
                                                    <CustomTab
                                                        label={t("miscellaneous.accountingSystem")}
                                                        {...a11yProps(1)}
                                                    />
                                                    <CustomTab label={t("principal.services")} {...a11yProps(2)} />
                                                    <CustomTab label={t("miscellaneous.invoicing")} {...a11yProps(3)} />
                                                    <CustomTab label={t("miscellaneous.staff")} {...a11yProps(4)} />
                                                </Tabs>
                                            </Grid>
                                            <Grid item xs={12} md={1} sx={{ textAlign: "center" }}>
                                                <LightTooltip title={t("principal.historyPropCont")}>
                                                    <IconButton sx={{ color: "#2f3190" }} onClick={showProposalHist}>
                                                        <PendingActionsIcon />
                                                    </IconButton>
                                                </LightTooltip>
                                            </Grid>
                                        </Grid>
                                    </Box>

                                    <TabPanel value={valueTab} index={0}>
                                        <GeneralData
                                            franqData={franqDataProps}
                                            groupData={groupDataF}
                                            client={client}
                                            updateClients={updateClientsCompanies}
                                            setClient={setClient}
                                            setTexto={setTexto}
                                            setAlertType={setalertType}
                                            setShow={setShow}
                                            setBackDrop={setBackDrop}
                                            loadingCard={loadingCard}
                                            setLoadingCard={setLoadingCard}
                                            setfranqName={setfranqName}
                                            setgroupName={setgroupName}
                                        />
                                    </TabPanel>
                                    <TabPanel value={valueTab} index={1}>
                                        <AccountSystem
                                            data={client}
                                            setTexto={setTexto}
                                            prove={proveedores}
                                            setProveedores={setProveedores}
                                            setAlertType={setalertType}
                                            setShow={setShow}
                                            setBackDrop={setBackDrop}
                                            loadingCard={loadingCard}
                                            setLoadingCard={setLoadingCard}
                                        />
                                    </TabPanel>
                                    <TabPanel value={valueTab} index={2}>
                                        <Services
                                            services={services}
                                            serviceData={serviceData}
                                            data={client}
                                            setServices={setServices}
                                            loadingCard={loadingCard}
                                            setLoadingCard={setLoadingCard}
                                            accountSystem={contValues}
                                            setServiceData={setServiceData}
                                        />
                                    </TabPanel>
                                    <TabPanel value={valueTab} index={3}>
                                        <Billing
                                            data={client}
                                            setTexto={setTexto}
                                            setAlertType={setalertType}
                                            setShow={setShow}
                                            setBackDrop={setBackDrop}
                                            loadingCard={loadingCard}
                                            setLoadingCard={setLoadingCard}
                                        />
                                    </TabPanel>
                                    <TabPanel value={valueTab} index={4}>
                                        <Staff
                                            data={client}
                                            setTexto={setTexto}
                                            setAlertType={setalertType}
                                            setShow={setShow}
                                            setBackDrop={setBackDrop}
                                            loadingCard={loadingCard}
                                            setLoadingCard={setLoadingCard}
                                        />
                                    </TabPanel>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                )}
            </Grid>
            <Backdrop open={showBackdrop} />
            <NewFranq
                open={dialogFranq}
                setFranqData={setFranqDataNew}
                setClientData={setClientDataNew}
                onClose={() => setDialogFranq(false)}
                setTexto={setTexto}
                setalertType={setalertType}
                setShow={setShow}
                setBackDrop={setBackDrop}
                franqData={franqData}
            />
            <NewGroup
                open={dialogGroup}
                setGroupData={setGroupDataNew}
                setClientData={setClientDataNew}
                onClose={() => setDialogGroup(false)}
                setTexto={setTexto}
                setalertType={setalertType}
                setShow={setShow}
                setBackDrop={setBackDrop}
                franqData={franqData}
                groupData={groupDataF}
            />
            <NewClient
                open={dialogClient}
                setClientData={setClientDataNew}
                onClose={() => setDialogClient(false)}
                setTexto={setTexto}
                setAlertType={setalertType}
                setShow={setShow}
                franqData={franqData}
                groupData={groupDataF}
                prove={proveedores}
                setProveedores={setProveedores}
            />
            <ProposalHistory open={modalPropHist} onClose={handleCloseHistory} propContData={propContData} showProposalHist={showProposalHist} setTexto={setTexto} setAlertType={setalertType} setShow={setShow} setBackDrop={setBackDrop} compInfo={compInfo} />
            <SnackBar type={alertType} show={show} message={texto} onClose={handleClose} />
        </StyledContainer>
    );
};

export default ClientMana;
