/** React imports */
import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import moment from "moment";
import PropTypes from 'prop-types';
import { NumericFormat } from 'react-number-format';

/** MUI icons */
import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

/** MUI imports */
import { styled } from '@mui/material/styles';
import {
    Box,
    Button,
    Card,
    CardHeader,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    LinearProgress,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';

/** Images */
import { ReactComponent as CloudUploadIcon } from '../../../assets/images/CloudUploadIcon.svg';
import { ReactComponent as InfoIcon } from '../../../assets/images/info-icon.svg';

const PREFIX = 'RegModal';

const classes = {
    button: `${PREFIX}-button`,
    buttonC: `${PREFIX}-buttonC`,
    dropzone: `${PREFIX}-dropzone`,
    dropzoneDisabled: `${PREFIX}-dropzoneDisabled`,
};

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    width: "100%",
    '& .MuiToggleButtonGroup-grouped': {
        border: 0,
        borderRadius: 30,
        height: 35,
        width: "50%",
        backgroundColor: "rgba(47,49,144,0.1)",
        color: "#031851",
        '&:hover': {
            backgroundColor: "rgba(47, 49, 144, 0.05)"
        },
    },
    '& .Mui-selected': {
        backgroundColor: "#2f3190 !important",
        color: "white !important",
        '&:hover': {
            backgroundColor: "rgba(47, 49, 144, 0.8) !important"
        },
    },
}));

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: 'rgba(19, 31, 62, 0.80)',
        fontSize: 12,
        maxWidth: 320,
        borderRadius: 6,
        fontWeight: 'normal'
    },
});

const CustomDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialog-paper': {
        borderRadius: 8,
        boxShadow: '10px 10px 15px rgba(3,24,81,0.15)',
    },
    [`& .${classes.button}`]: {
        borderRadius: 40,
        paddingLeft: 40,
        paddingRight: 40,
        '&:hover': {
            backgroundColor: theme.palette.text.secondary,
            color: '#fff',
        },
    },
    [`& .${classes.buttonC}`]: {
        borderRadius: 40,
        paddingLeft: 40,
        paddingRight: 40,
        '&:hover': {
            backgroundColor: 'white',
            color: '#2f3190',
            border: '1px solid #2f3190'
        },
    },
    [`& .${classes.dropzone}`]: {
        flex: 1,
        display: "flex",
        padding: "64px 24px",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: 'rgba(47,49,144,0.1)',
        border: '2px dashed #C1C1DE',
        borderRadius: '16px',
        '&:hover': {
            border: '2px dashed #2f3190',
        },
    },
    [`& .${classes.dropzoneDisabled}`]: {
        flex: 1,
        display: "flex",
        padding: "64px 24px",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: 'rgba(47,49,144,0.1)',
        border: '2px dashed #C1C1DE',
        borderRadius: '16px',
    },
}));

/** Global variables */
const team = ['kgarcia@gca.digital', 'dloyo@gca.digital', 'dleon@gca.digital', 'jalvarez@gca.digital', 
            'btorres@gca.digital', 'pherrera@gca.digital', 'mmedina@gca.digital'];

function LinearProgressWithLabel(props) {
    return (
        <Box sx={{ display: 'flex', mt: 2, alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props}
                    sx={{
                        height: 10,
                        borderRadius: 5
                    }}
                />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
};

const NumberFormatCustom = (props) => {
    const todos = useSelector(state => state.todos);

    const { inputRef, value, onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            getInputRef={inputRef}
            value={value}
            onValueChange={(values) => {
                // eslint-disable-next-line no-unused-vars
                const { formattedValue, value } = values;
                onChange(value);
            }}
            thousandSeparator={todos.amountFormat === 'de-DE' ? '.' : ','}
            decimalSeparator={todos.amountFormat === 'de-DE' ? ',' : '.'}
            fixedDecimalScale={true}
            decimalScale={2}
            valueIsNumericString
        />
    );
}

export default function RegModal(props) {
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    const meses = [
        t('monthNames.january'),
        t('monthNames.february'),
        t('monthNames.march'),
        t('monthNames.april'),
        t('monthNames.may'),
        t('monthNames.june'),
        t('monthNames.july'),
        t('monthNames.august'),
        t('monthNames.september'),
        t('monthNames.october'),
        t('monthNames.november'),
        t('monthNames.december')
    ];

    /* React redux */
    const todos = useSelector(state => state.todos);

    /** Component states */
    const [chatT, setChatType] = useState('auto');
    const [progress, setProgress] = useState(0);
    const [valuesGen, setValuesGen] = useState({
        initDate: null,
        endDate: null,
        amount: "",
    });

    const handleChangeAmount = (value) => {
        setValuesGen({
            ...valuesGen,
            amount: value
        });
    };

    /** Component functions */
    const handleChangeChat = (event, newAlignment) => {
        if (newAlignment !== null) {
            setChatType(newAlignment);
        }
    };

    const onDrop = useCallback(
        async (acceptedFiles) => {
            if (acceptedFiles[0].size < 5000000) {
                let data = new FormData();

                data.append("bank_id", props.accountIdSelected);
                data.append("month", ('0' + (props.selectedMonth + 1)).slice(-2));
                data.append("year", props.selectedYear);
                data.append("file", acceptedFiles[0]);
                data.append("folder", "Bancos");

                const options = {
                    onUploadProgress: (progressEvent) => {
                        const { loaded, total } = progressEvent;
                        let percent = Math.floor((loaded * 100) / total)

                        if (percent < 100) {
                            setProgress(percent);
                        }
                    }
                }

                await axios.post("/uploadFileS3", data, options)
                    .then(() => {
                        setProgress(0);
                        props.setFilesData([...acceptedFiles]);
                        props.setFilesRejected([]);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        [props]
    );

    const onDropRejected = useCallback(
        fileRejections => {
            props.setFilesRejected([...props.filesRejected, ...fileRejections]);
        },
        [props]
    );

    const { getRootProps, getInputProps } = useDropzone({
        disabled: props.filesData.length > 0 || props.uploadedFiles.length > 0 || props.fileFormat.length === 0,
        noKeyboard: true,
        maxSize: 5000000,
        maxFiles: 1,
        multiple: false,
        onDrop,
        onDropRejected,
        // accept: 'image/jpeg,image/png',
    });

    const closing = () => {
        setChatType("auto");
        setValuesGen({
            ...valuesGen,
            amount: ""
        });
        props.onClose();
    }

    const downloadFile = file => async () => {
        let params = {};
        if (props.uploadedFiles.length === 0) {
            params = {
                bank_id: props.accountIdSelected,
                month: ('0' + (props.selectedMonth + 1)).slice(-2),
                year: props.selectedYear,
                file_name: file.name,
                folder: "Bancos",
            }
        } else {
            params = {
                bank_id: props.accountIdSelected,
                month: ('0' + (props.selectedMonth + 1)).slice(-2),
                year: props.selectedYear,
                file_name: file,
                folder: "Bancos",
            }
        }

        await axios.get(`/getFileS3`, { params, responseType: 'blob' },)
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', response.config.params.file_name);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const removeFile = file => async () => {
        props.setBackDrop(true);
        let params = {};

        if (props.uploadedFiles.length === 0) {
            params = {
                bank_id: props.accountIdSelected,
                month: ('0' + (props.selectedMonth + 1)).slice(-2),
                year: props.selectedYear,
                file_name: file.name,
                folder: "Bancos"
            }

            await axios.post("/deleteFileS3", params)
                .then(() => {
                    const newFiles = [...props.filesData];
                    newFiles.splice(newFiles.indexOf(file), 1);
                    props.setFilesData(newFiles);
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            params = {
                bank_id: props.accountIdSelected,
                month: ('0' + (props.selectedMonth + 1)).slice(-2),
                year: props.selectedYear,
                file_name: file,
                folder: "Bancos"
            }

            await axios.post("/deleteFileS3", params)
                .then(() => {
                    props.onClose();
                    props.setTexto(t('dialogConversions.filesRemoved'));
                    props.setAlertType("success");
                    props.setShow(true);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
        props.setBackDrop(false);
    };

    const formatBytes = (bytes, decimals = 0) => {
        if (bytes === 0) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    };

    const validateAmount = async () => {
        props.setBackDrop(true);

        const params = {
            accountId: props.accountIdSelected,
            balance: parseFloat(valuesGen.amount).toFixed(2),
            initialDate: moment().set({ 'year': props.selectedYear, 'month': props.selectedMonth }).startOf('month').format("YYYY-MM-DD"),
            endDate: moment().set({ 'year': props.selectedYear, 'month': props.selectedMonth }).endOf('month').format("YYYY-MM-DD"),
            statementInitialDate: moment(valuesGen.initDate).format("YYYY-MM-DD"),
            statementEndDate: moment(valuesGen.endDate).format("YYYY-MM-DD")
        }

        if (valuesGen.amount) {
            await fetch(`/validateBalance`, {
                method: "POST",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
                body: JSON.stringify(params),
            })
                .then((response) => {
                    // check for error response
                    if (!response.ok) {
                        // get error message from body or default to response status
                        const error = response.status;
                        throw new Error(error);
                    }

                    if (response.status === 200) {
                        props.setMainAmount({
                            amount: parseFloat(valuesGen.amount).toLocaleString(todos.amountFormat,
                                { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                            bankBalanceStatus: 0,
                            initDate: valuesGen.initDate,
                            endDate: valuesGen.endDate
                        });
                        props.setTexto(t('manageAccount.verifiedBalance'));
                        props.setAlertType("success");
                        props.loadInitData();
                    }
                    else if (response.status === 201) {
                        props.setMainAmount({
                            amount: parseFloat(valuesGen.amount).toLocaleString(todos.amountFormat,
                                { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                            bankBalanceStatus: 2,
                            initDate: valuesGen.initDate,
                            endDate: valuesGen.endDate
                        });
                        props.setTexto(t('manageAccount.noXeroAmount'));
                        props.setAlertType("warning");
                        props.loadInitData();
                    }
                    props.setShow(true);
                })
                .catch((error) => {
                    props.setTexto(t('manageAccount.errverifiedBalance'));
                    props.setAlertType("error");
                    props.setShow(true);
                });
        }
        else {
            props.setTexto(t('miscellaneous.requiredData'));
            props.setAlertType("warning");
            props.setShow(true);
        }

        props.setBackDrop(false);
    }

    const handleChangeStartDate = date => {
        if (date === null || valuesGen.endDate === null) {
            setValuesGen({
                ...valuesGen,
                initDate: date,
            });
        } else {
            if (moment(date).isAfter(valuesGen.endDate)) {
                props.setTexto(t("reports.initDateMess"));
                props.setAlertType("warning");
                props.setShow(true);
            } else {
                setValuesGen({
                    ...valuesGen,
                    initDate: date,
                });
            }
        }
    };

    const handleChangeEndDate = date => {
        if (date === null || valuesGen.initDate === null) {
            setValuesGen({
                ...valuesGen,
                endDate: date,
            });
        } else {
            if (moment(date).isBefore(valuesGen.initDate)) {
                props.setTexto(t("reports.endDateMess"));
                props.setAlertType("warning");
                props.setShow(true);
            } else {
                setValuesGen({
                    ...valuesGen,
                    endDate: date,
                });
            }
        }
    };

    useEffect(() => {
        setValuesGen({
            initDate: moment(props.mainAmount.initDate, "YYYY-MM-DD"),
            endDate: moment(props.mainAmount.endDate, "YYYY-MM-DD"),
            amount: "",
        });
    }, [props.mainAmount.endDate, props.mainAmount.initDate, t]);

    const acceptedFileItems = props.filesData.map((file) => (
        <ListItem
            disablePadding
            secondaryAction={
                <Box>
                    <LightTooltip title={t('dialogConversions.download')} aria-label="downloadFile">
                        <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={downloadFile(file)}
                            sx={{ mr: 1 }}
                        >
                            <FileDownloadIcon fontSize='medium' color='primary' />
                        </IconButton>
                    </LightTooltip>
                    <LightTooltip title={t('dialogConversions.remove')} aria-label="removeFile">
                        <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={removeFile(file)}
                        >
                            <DeleteIcon fontSize='medium' color='primary' />
                        </IconButton>
                    </LightTooltip>
                </Box>
            }
            sx={{ height: 50 }}
        >
            <ListItemIcon sx={{ minWidth: '20px' }}>
                <DescriptionOutlinedIcon fontSize='large' color='primary' sx={{ mr: 1 }} />
            </ListItemIcon>
            <ListItemText
                key={file.path}
                primary={file.name}
                secondary={formatBytes(file.size)}
                secondaryTypographyProps={{ fontSize: '12px' }}
            />
        </ListItem>
    ));

    const fileRejectionItems = useMemo(() =>
        props.filesRejected.map(({ file }) => (
            <ListItem disablePadding>
                <ListItemIcon sx={{ minWidth: '20px' }}>
                    <DescriptionOutlinedIcon fontSize='large' color='primary' sx={{ mr: 1 }} />
                </ListItemIcon>
                <ListItemText
                    key={file.path}
                    primary={file.name}
                    secondary={formatBytes(file.size)}
                    secondaryTypographyProps={{ fontSize: '12px' }}
                    sx={{ color: '#ff5968' }}
                />
            </ListItem>
        )),
        [props.filesRejected]
    );

    const uploadedFilesItems = props.uploadedFiles.map((file) => (
        <ListItem
            disablePadding
            secondaryAction={
                <Box>
                    <LightTooltip title={t('dialogConversions.download')} aria-label="downloadFile">
                        <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={downloadFile(file)}
                            sx={{
                                mr: '-12px',
                                ...(team.includes(todos.userInfo.email) && { mr: 1 }),
                            }}
                        >
                            <FileDownloadIcon fontSize='medium' color='primary' />
                        </IconButton>
                    </LightTooltip>
                    {(team.includes(todos.userInfo.email)) ?
                        <LightTooltip title={t('dialogConversions.remove')} aria-label="removeUploadedFile">
                            <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={removeFile(file)}
                            >
                                <DeleteIcon fontSize='medium' color='primary' />
                            </IconButton>
                        </LightTooltip>
                        : null}
                </Box>
            }
            sx={{ height: 50 }}
        >
            <ListItemIcon sx={{ minWidth: '20px' }}>
                <DescriptionOutlinedIcon fontSize='large' color='primary' sx={{ mr: 1 }} />
            </ListItemIcon>
            <ListItemText
                key={file}
                primary={file}
            />
        </ListItem>
    ));

    return (
        <CustomDialog
            open={props.open}
            onClose={closing}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="md"
            fullWidth={true}
        >
            <DialogContent>
                <StyledToggleButtonGroup
                    color="primary"
                    value={chatT}
                    exclusive
                    onChange={handleChangeChat}
                    sx={{ p: 2 }}
                >
                    <ToggleButton value="auto">{t('services.automatic')}</ToggleButton>
                    <ToggleButton value="manual">Manual</ToggleButton>
                </StyledToggleButtonGroup>
                {chatT === "auto" &&
                    <Grid container spacing={2} sx={{ p: 2 }}>
                        <Grid item xs={12}>
                            <div {...getRootProps({ className: (props.filesData.length > 0 || props.uploadedFiles.length > 0 || props.fileFormat.length === 0) ? classes.dropzoneDisabled : classes.dropzone })}>
                                <input {...getInputProps()} />
                                {props.fileFormat.length > 0 ? (
                                    <CloudUploadIcon fill={(props.filesData.length > 0 || props.uploadedFiles.length > 0) ? "#C1C1DE" : "#031851"} />
                                ) : (
                                    <InfoIcon height={100} fill={"#C1C1DE"} />
                                )}
                                {props.fileFormat.length > 0 ? (
                                    <Typography variant="h1" color={(props.filesData.length > 0 || props.uploadedFiles.length > 0) ? "#C1C1DE" : "#031851"} sx={{ cursor: 'default', pt: 3 }}>
                                        {t('dialogConversions.selectFile')}
                                    </Typography>
                                ) : (
                                    <Typography variant="h1" color={"#C1C1DE"} sx={{ cursor: 'default', pt: 3 }}>
                                        {t('dialogConversions.noBanks')}
                                    </Typography>
                                )}
                            </div>
                            {props.fileFormat.length > 0 ? (
                                <Typography variant="body1" color="primary" sx={{ pt: 1 }}>
                                    {t('dialogConversions.validFormats')} <Typography component="span" variant="h4" color="primary">{`${(props.fileFormat).map(file => `.${file}`).join(' ')}`}</Typography>
                                    <Typography variant="body1" color="primary">
                                        {t('dialogConversions.maximunUploadSize')}
                                    </Typography>
                                </Typography>
                            ) : null}
                            {progress > 0 && <LinearProgressWithLabel value={progress} />}
                            {(acceptedFileItems.length > 0) ? (
                                <List
                                    component="nav"
                                    aria-labelledby="acceptedFiles-list-subheader"
                                    subheader={
                                        <ListSubheader component="div" id="acceptedFiles-list-subheader" sx={{ p: 0 }}>
                                            <Typography variant="h4" color="primary" sx={{ pt: 3, pb: 1 }}>
                                                {`${t('dialogConversions.uploadedFiles')}:`}
                                            </Typography>
                                        </ListSubheader>
                                    }
                                >
                                    {acceptedFileItems}
                                </List>
                            ) : null}
                            {(fileRejectionItems.length > 0) ? (
                                <List
                                    component="nav"
                                    aria-labelledby="fileRejections-list-subheader"
                                    subheader={
                                        <ListSubheader component="div" id="fileRejections-list-subheader" sx={{ p: 0 }}>
                                            <Typography variant="h4" color="primary" sx={{ pt: 3, pb: 1 }}>
                                                {`${t('dialogConversions.rejectedFiles')}:`}
                                            </Typography>
                                        </ListSubheader>
                                    }
                                >
                                    {fileRejectionItems}
                                </List>
                            ) : null}
                            {(props.uploadedFiles.length > 0) ? (
                                <List
                                    component="nav"
                                    aria-labelledby="uploadedFiles-list-subheader"
                                    subheader={
                                        <ListSubheader component="div" id="uploadedFiles-list-subheader" sx={{ p: 0 }}>
                                            <Typography variant="h4" color="primary" sx={{ pt: 3, pb: 1 }}>
                                                {`${t('dialogConversions.repositoryFiles')}:`}
                                            </Typography>
                                        </ListSubheader>
                                    }
                                >
                                    {uploadedFilesItems}
                                </List>
                            ) : null}
                        </Grid>
                    </Grid>
                }
                {chatT === "manual" &&
                    <Grid container spacing={2} sx={{ p: 2 }} alignItems="center">
                        <Grid item xs={12} md={6}>
                            <Typography variant="h4" color="textSecondary">
                                {t('services.actualClose')}
                            </Typography>
                            <ListItem alignItems="center" sx={{ p: 0 }}>
                                <ListItemText
                                    primary={
                                        <Typography variant="h1" color="primary">
                                            {meses[props.selectedMonth] + " - " + props.selectedYear}
                                        </Typography>
                                    }
                                    primaryTypographyProps={{ textAlign: 'left' }}
                                />
                            </ListItem>

                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h4" color="textSecondary">
                                {t('services.amountVal')}
                            </Typography>
                            <ListItem alignItems="center"
                                secondaryAction={
                                    props.mainAmount.bankBalanceStatus === 0 ?
                                        <LightTooltip title={t("bankingDashboard.verifiedBalance")} aria-label="check">
                                            <CheckIcon sx={{ display: 'block', m: 'auto', color: '#4caf50' }} />
                                        </LightTooltip>
                                        : props.mainAmount.bankBalanceStatus === 1 ?
                                            <LightTooltip title={t("bankingDashboard.noMovementsLoaded")} aria-label="warning">
                                                <WarningAmberIcon sx={{ display: 'block', m: 'auto', color: '#FFB039' }} />
                                            </LightTooltip>
                                            :
                                            <LightTooltip title={t("bankingDashboard.noXeroAmount")} aria-label="cancel">
                                                <CancelIcon color="secondary" sx={{ display: 'block', m: 'auto' }} />
                                            </LightTooltip>
                                }
                                sx={{ width: '65%', p: 0 }}
                            >
                                <ListItemText
                                    primary={
                                        <Typography variant="h1" color="primary">
                                            {props.mainAmount.amount === "Sin registro" ? parseFloat("0.00").toLocaleString(todos.amountFormat,
                                                { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : props.mainAmount.amount}
                                        </Typography>
                                    }
                                    primaryTypographyProps={{ textAlign: 'left' }}
                                />
                            </ListItem>
                        </Grid>
                        <Grid item xs={12}>
                            <Card
                                elevation={0}
                                sx={{
                                    borderRadius: 4,
                                    backgroundColor: 'rgba(47,49,144,0.1)'
                                }}
                            >
                                <CardHeader
                                    title={
                                        <Typography variant="h4" color="primary">
                                            {t('services.valBal')}
                                        </Typography>
                                    }
                                />
                                <CardContent sx={{ p: 3, pt: 2 }}>
                                    <Grid container alignItems="center" spacing={2}>
                                        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={t("language.locale")} localeText={{ clearButtonLabel: t('miscellaneous.clear') }}>
                                            <Grid item xs={12} md={3}>
                                                <DatePicker
                                                    componentsProps={{
                                                        actionBar: { actions: ['clear'] }
                                                    }}
                                                    disableFuture
                                                    minDate={moment().set({ 'year': props.selectedYear, 'month': props.selectedMonth - 1 }).startOf('month')}
                                                    maxDate={moment().set({ 'year': props.selectedYear, 'month': props.selectedMonth + 1 }).endOf('month')}
                                                    value={valuesGen.initDate}
                                                    onChange={handleChangeStartDate}
                                                    inputFormat={todos.dateFormat}
                                                    renderInput={(params) =>
                                                        <TextField
                                                            fullWidth
                                                            {...params}
                                                        />
                                                    }
                                                    inputProps={
                                                        { readOnly: true, }
                                                    }
                                                    label={t('bank.startDate')}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={3}>
                                                <DatePicker
                                                    componentsProps={{
                                                        actionBar: { actions: ['clear'] }
                                                    }}
                                                    disableFuture
                                                    minDate={moment().set({ 'year': props.selectedYear, 'month': props.selectedMonth - 1 }).startOf('month')}
                                                    maxDate={moment().set({ 'year': props.selectedYear, 'month': props.selectedMonth + 1 }).endOf('month')}
                                                    value={valuesGen.endDate}
                                                    onChange={handleChangeEndDate}
                                                    inputFormat={todos.dateFormat}
                                                    renderInput={(params) =>
                                                        <TextField
                                                            fullWidth
                                                            {...params}
                                                        />
                                                    }
                                                    inputProps={
                                                        { readOnly: true, }
                                                    }
                                                    label={t('bank.finalDate')}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={3}>
                                                <TextField variant="outlined"
                                                    placeholder={parseFloat("0.00").toLocaleString(todos.amountFormat,
                                                        { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                    InputProps={{
                                                        inputComponent: NumberFormatCustom
                                                    }}
                                                    label={t('bank.balance')} value={valuesGen.amount} onChange={handleChangeAmount} fullWidth />
                                            </Grid>
                                            <Grid item xs={12} md={3}>
                                                <Button variant="contained" color="secondary" className={classes.button}
                                                    disableElevation onClick={validateAmount} sx={{ display: 'block', m: 'auto' }}
                                                >
                                                    {t('miscellaneous.validate')}
                                                </Button>
                                            </Grid>
                                        </LocalizationProvider>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                }
            </DialogContent>
            {chatT === "auto" &&
                <DialogActions sx={{ p: '16px 32px 32px 32px !important' }}>
                    {props.actions}
                </DialogActions>
            }
        </CustomDialog>
    );
}